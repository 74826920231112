import { APIConfiguration } from '@/constants'
import { getRequestService } from '@/utils/services'

export abstract class BaseUSM<T> {
  protected constructor(protected readonly data: T) {
    this.data = data
  }

  protected USMRequestService = getRequestService(APIConfiguration.USM_API_BASE_URL)
  protected BaseURLRequestService = getRequestService(APIConfiguration.API_BASE_URL)
  protected RequestServiceUSMV2 = getRequestService(APIConfiguration.API_BASE_URL_V2)
}
