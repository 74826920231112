import { AdmAppSetting } from '@/api/cm/swaggerGeneratedApi'
import { DynamicFieldType } from '@/constants/cm/dynamicFieldTypes'

import { FormValue } from '../types'

export const getInitialFormValues = (fields: AdmAppSetting[]): FormValue => {
  const initialValues: FormValue = {}

  fields.forEach((field: AdmAppSetting) => {
    const parsedFieldKey = field.id ? `${field.id}` : ''

    switch (field.valueType) {
      case DynamicFieldType.Boolean:
        {
          const fieldValue = field.value ? JSON.parse(field.value) : false
          initialValues[parsedFieldKey] = fieldValue
        }
        break

      case DynamicFieldType.Integer:
        initialValues[parsedFieldKey] = Number(field.value)
        break

      case DynamicFieldType.Radio:
        {
          const selectedRadio = (field.groupInfo || []).find(radio => radio.isSelected)?.id
          initialValues[parsedFieldKey] = selectedRadio
        }
        break

      default:
        initialValues[parsedFieldKey] = ''
    }
  })

  return initialValues
}
