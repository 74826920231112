import { ItemsReportMenu, ReportMenuListType } from '@/api/sd/reportsApi/types'
import { AuthorizationActions } from '@/constants'
import { ResponseError } from '@/types'
import { Parameters, ReportSaveRequest, SubscribeItems } from '@/types/sd/reports/reports'

export const actions = {
  getReportsLazyMenuItemsRequest: () =>
    ({
      type: '@/GET_REPORTS_LAZY_MENU_ITEMS_REQUEST',
    } as const),
  getReportsLazyMenuItemsResponse: (reports: Array<ItemsReportMenu>) =>
    ({
      type: '@GET_REPORTS_LAZY_MENU_ITEMS_RESPONSE',
      payload: reports,
    } as const),
  getDeleteLazyReportRequest: (id: string | number) =>
    ({
      type: '@GET_DELETE_LAZY_REPORT_REQUEST',
      payload: { id },
    } as const),
  getDeleteLazyReportResponse: (id: string) =>
    ({
      type: '@GET_DELETE_LAZY_REPORT_RESPONSE',
      payload: id,
    } as const),
  getReportsMenuItemsRequest: (type: ReportMenuListType) =>
    ({
      type: '@/GET_REPORTS_MENU_ITEMS_REQUEST',
      payload: type,
    } as const),
  getMyReportsMenuItemsRequest: (payload: { type: ReportMenuListType; status: string }) =>
    ({
      type: '@/GET_REPORTS_MY_MENU_ITEMS_REQUEST',
      payload: payload,
    } as const),
  getReportsMenuItemsResponse: (reports: Array<ItemsReportMenu>) =>
    ({
      type: '@GET_REPORTS_MENU_ITEMS_RESPONSE',
      payload: reports,
    } as const),
  getReportsMenuItemsResponseFall: (error: ResponseError) =>
    ({
      type: '@/GET_REPORTS_MENU_ITEMS_RESPONSE_FAIL',
      payload: error,
    } as const),
  getMyReportsMenuResponse: (myMenuReports: Array<ItemsReportMenu>) =>
    ({
      type: '@GET_REPORTS_MY_MENU_ITEMS_RESPONSE',
      payload: myMenuReports,
    } as const),
  getSubscribeReportsRequest: (status: string) =>
    ({
      type: '@/GET_SUBSCRIBE_REPORTS_REQUEST',
      payload: status,
    } as const),
  getSubscribeReportsFall: (error: ResponseError) =>
    ({
      type: '@/GET_SUBSCRIBE_REPORTS_RESPONSE_FAIL',
      payload: error,
    } as const),
  getSubscribeReportsResponse: (subscribeReports: Array<SubscribeItems>) =>
    ({
      type: '@GET_SUBSCRIBE_REPORTS_RESPONSE',
      payload: subscribeReports,
    } as const),
  getReportByIdRequest: (subscriptionId?: string | number) =>
    ({
      type: '@GET_REPORT_REQUEST_BY_ID',
      payload: subscriptionId,
    } as const),
  getReportByIdResponse: (report: any) =>
    ({
      type: '@GET_REPORT_BY_ID_RESPONSE',
      payload: report,
    } as const),
  getReportByIdResponseFall: (error: ResponseError) =>
    ({
      type: 'GET_REPORT_BY_ID_RESPONSE_FALL',
      payload: error,
    } as const),
  getReportLazyRequest: (data: any, translate: any) =>
    ({
      type: '@SAVE_REPORT_LAZY_REQUEST',
      payload: data,
    } as const),
  getReportLazyResponse: (res: any) =>
    ({
      type: '@SAVE_REPORT_LAZY_RESPONSE',
      payload: res,
    } as const),
  getReportDataLazyRequest: (data: any, translate: any) =>
    ({
      type: '@SAVE_REPORT_DATA_LAZY_REQUEST',
      payload: data,
    } as const),
  getReportDataLazyResponse: (res: any) =>
    ({
      type: '@SAVE_REPORT_DATA_LAZY_RESPONSE',
      payload: res,
    } as const),
  saveReportRequest: (data: any, translate: any) =>
    ({
      type: '@SAVE_REPORT_REQUEST',
      payload: data,
      translate,
    } as const),
  saveReportResponse: (res: any) =>
    ({
      type: '@SAVE_REPORT_RESPONSE',
      payload: res,
    } as const),
  saveDataReportResponse: (res: any) =>
    ({
      type: '@SAVE_DATA_REPORT_RESPONSE',
      payload: res,
    } as const),
  saveReportResponseFall: (error: ResponseError) =>
    ({
      type: '@SAVE_REPORT_RESPONSE_FALL',
      payload: error,
    } as const),
  setSearchFilter: (value: string) =>
    ({
      type: '@SET_SEARCH_FILTER',
      payload: value,
    } as const),
  getDeleteReportRequest: (
    id: string | number | undefined,
    reportsName: string | null,
    translate: Function,
  ) =>
    ({
      type: '@GET_DELETE_REPORT_REQUEST',
      payload: { id, reportsName, translate },
    } as const),
  getDeleteReportResponse: (id: string | number) =>
    ({
      type: '@GET_DELETE_REPORT_RESPONSE',
      payload: id,
    } as const),
  getDeleteReportResponseFall: (error: ResponseError) =>
    ({
      type: '@GET_DELETE_REPORT_RESPONSE_FALL',
      payload: error,
    } as const),
  getReportsConfigRequest: (uid: string) =>
    ({
      type: '@GET_REPORTS_CONFIG_REQUEST',
      payload: uid,
    } as const),

  setInitReportsConfig: () =>
    ({
      type: '@SET_INIT_REPORTS_CONFIG',
    } as const),

  getReportsSubscriptionConfigResponse: (config: Parameters) =>
    ({
      type: '@GET_REPORTS_CONFIG_RESPONSE',
      payload: config,
    } as const),

  getReportsSubscriptionConfigResponseFall: (error: ResponseError) =>
    ({
      type: '@GET_REPORTS_CONFIG_RESPONSE_FALL',
      payload: error,
    } as const),
  setReportIdAndReportUid: (
    id: string,
    uId?: string,
    subscriptionId = 0,
    subName?: string | null | undefined,
  ) =>
    ({
      type: '@SET_REPORT_ID_UID',
      payload: { id, uId, subscriptionId, subName },
    } as const),
  getHTMLReportRequest: (subscriptionId: string) =>
    ({
      type: '@GET_HTML_REPORT_REQUEST',
      payload: subscriptionId,
    } as const),
  setParametersReportForDownloadFile: (report: ReportSaveRequest) =>
    ({
      type: '@SET_DOWNLOAD_REPORT_PARAMETERS_FILE',
      payload: report,
    } as const),
  setInitialParametersReportForDownloadFile: () =>
    ({
      type: '@@SET_INITIAL_DOWNLOAD_FILE_REPORT_PARAMETERS',
    } as const),
  // signOut: () =>
  //   ({
  //     type: AuthorizationActions.SignOut,
  //   } as const),
}
