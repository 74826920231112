import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// types
import { Props } from './types'
import { SecurityNetworksObjectsMergeValues } from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/MergeSecurityNetworksModal/types'
// actions
import {
  actionSecurityNetworksMergeUSM,
  getCommonSecurityObjectsMerge,
  getGlobalErrorSecurityObjectsMerge,
  getIsFetchingCommonSecurityObjectsMerge,
  getIsFetchingCommonSecurityObjectsSummaryMerge,
  getSecurityObjectsSummaryMerge,
} from '@/store/usm/securityNetworksObjectMerge'
import { SecurityNetworksObjectMergeUSMAPI } from '@/api/usm/securityNetworksMerge'
import {
  AllUsersListTypes,
  GetSummaryObjectsRequestTypes,
} from '@/types/usm/securityObjectsMerge/securityObjectsMerge'
import { TypesForSelect } from '@/components/controls/Select/types'
import { WARNING_NO_OBJECTS_FOR_MERGE } from '@/constants/usm/usmModule'
// hooks
import { useSelectUsers } from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/MergeSecurityNetworksModal/hooks/selectUsersHook'
import { useUserInfoTable } from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/AddUserModal/hooks/table'
import { useErrorMessage } from '@/utils/hooks/useErrrorMessage'

export const useMergeSecurityNetworksModal = ({ handleClose }: Props) => {
  const dispatch = useDispatch()
  const error = useSelector(getGlobalErrorSecurityObjectsMerge)
  const isFetching = useSelector(getIsFetchingCommonSecurityObjectsMerge)
  const isLoadingSecurityObjectsSummary = useSelector(
    getIsFetchingCommonSecurityObjectsSummaryMerge,
  )
  const commonMergeObjectsList = useSelector(getCommonSecurityObjectsMerge)
  const securityObjectsMergeList = useSelector(getSecurityObjectsSummaryMerge)
  const [usersCopyFrom, setUserCopyFrom] = useState<Array<TypesForSelect>>([])
  const [usersCopyTo, setUserCopyTo] = useState<Array<TypesForSelect>>([])
  const [idUsersForMerge, setIdUsersForMerge] = useState<null | {
    fromUserId: number
    toUserId: number
  }>(null)

  const currentUserFrom = useSelectUsers()
  const currentUserTo = useSelectUsers()

  const handleFilterSelectedSecurityObjectsMerge = (id: string): void => {
    dispatch(actionSecurityNetworksMergeUSM.filterSecurityObjectsSummaryMerge(id))
  }

  const {
    handleAddNewRow,
    handleChangeRow,
    handleDeleteRows,
    handleSelectRow,
    selectedRows,
    deletedRows,
    changedRows,
    newRows,
    setSelectedRows,
  } = useUserInfoTable(handleFilterSelectedSecurityObjectsMerge, true)

  const [success, setSuccess] = useState<boolean>(false)

  const getAllUsersUsers = async () => {
    const getUsers = new SecurityNetworksObjectMergeUSMAPI(null)
    try {
      const users: Array<AllUsersListTypes> = await getUsers.getAllUsersListForCurrentUser()
      setUserCopyFrom(users.map(({ name, userId }) => ({ name, value: String(userId) })))
    } catch (e) {
      dispatch(actionSecurityNetworksMergeUSM.setSecurityObjectsForUserResponseFall(e))
    }
  }

  useEffect(() => {
    dispatch(actionSecurityNetworksMergeUSM.getSecurityObjectsForUserAsync())
    getAllUsersUsers()
  }, [dispatch])

  const handleCloseAfterSuccessSubmitForm = () => {
    setSuccess(true)
  }

  const handleMergeObjects = useCallback((): void => {
    dispatch(
      actionSecurityNetworksMergeUSM.updateSecurityObjectsSummaryForUserAsync<null>(
        {
          fromUserId: +currentUserFrom.user,
          toUserId: [+currentUserTo.user],
          objectId: securityObjectsMergeList.map(o => +o.typeId),
        },
        { handleClose: handleCloseAfterSuccessSubmitForm },
      ),
    )
  }, [currentUserFrom.user, currentUserTo.user, securityObjectsMergeList])

  useEffect(() => {
    setUserCopyTo(usersCopyFrom.filter(el => el.value !== currentUserFrom.user))

    if (currentUserTo.user === currentUserFrom.user || !currentUserFrom.user.length) {
      currentUserTo.setUsers('')
      setSelectedRows(rows => [])
    }
  }, [currentUserFrom.user])

  const { errorMessages, setErrorMessages } = useErrorMessage<SecurityNetworksObjectsMergeValues>({
    error,
  })

  useEffect(() => {
    let timer: number

    if (errorMessages.includes(WARNING_NO_OBJECTS_FOR_MERGE)) {
      timer = window.setTimeout(
        () => dispatch(actionSecurityNetworksMergeUSM.setClearWarningMessageMerge()),
        4000,
      )
    }
    return (): void => clearTimeout(timer)
  }, [errorMessages])

  const handleCloseForm = useCallback(() => {
    handleClose()
    dispatch(actionSecurityNetworksMergeUSM.setClearErrorSecurityNetworksObjectsMerge())
    setErrorMessages([])
  }, [handleClose, error])

  const handleClickSelectRequest = useCallback(() => {
    const payload: GetSummaryObjectsRequestTypes = {
      typeId: selectedRows.map(el => +el.typeId),
      fromUserId: +currentUserFrom.user,
      toUserId: +currentUserTo.user,
    }

    dispatch(actionSecurityNetworksMergeUSM.getSecurityObjectsSummaryForUserAsync(payload))

    setIdUsersForMerge({ fromUserId: +currentUserFrom.user, toUserId: +currentUserTo.user })
  }, [currentUserFrom.user, currentUserTo.user, selectedRows, idUsersForMerge])

  useEffect(() => {
    if (idUsersForMerge !== null && idUsersForMerge?.toUserId !== +currentUserTo.user) {
      dispatch(actionSecurityNetworksMergeUSM.changeSelectedUsers())
    }

    if (idUsersForMerge !== null && idUsersForMerge?.fromUserId !== +currentUserFrom.user) {
      dispatch(actionSecurityNetworksMergeUSM.changeSelectedUsers())
    }
  }, [currentUserFrom.user, currentUserTo.user, idUsersForMerge])

  return {
    handleCloseForm,
    isFetching,
    errorMessages,
    commonMergeObjectsList,
    usersCopyTo,
    usersCopyFrom,
    handleClickSelectRequest,
    isLoadingSecurityObjectsSummary,
    securityObjectsMergeList,
    handleFilterSelectedSecurityObjectsMerge,
    success,
    currentUserFrom,
    currentUserTo,
    handleAddNewRow,
    handleChangeRow,
    handleDeleteRows,
    handleSelectRow,
    selectedRows,
    deletedRows,
    changedRows,
    newRows,
    setSelectedRows,
    handleMergeObjects,
  }
}
