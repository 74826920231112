import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import MuiTableRow from '@material-ui/core/TableRow'
import { isEmpty } from 'lodash-es'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Loader from '@/components/blocks/Loader'
import {
  TableBodyCellContent,
  TableFilterDate,
  TableFilterTypes,
} from '@/components/controls/AppTable/types'
import { HeaderColumn } from '@/components/pages/sd/TicketsPage/types'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { getCurrentAccount } from '@/store/auth/selectors'
import { Order } from '@/types'
import { GroupedTicket } from '@/types/sd/common'
import { useTranslate } from '@/utils/internalization'
import { getTableSettings } from '@/utils/localStorage'

import FolderRow from './components/FolderRow'
import TableToolbar from './components/TableToolbar'
import { useClasses } from './styles'
import { OpenedFolder, Props } from './types'

const TreeTable = ({
  isLoading,
  tableId,
  columnsOrderTemplate,
  columns,
  columnsOrder,
  incomingHeaders,
  data,
  ticketsTotalCount,
  filter,
  tableHeader,
  setTableSettingsToLocalStorage,
  setColumnsOrderTemplate,
  setColumnsOrder,
  setColumns,
  onFilterChange,
  handleTableParamsChange,
  ticketClick,
  posTicketClick,
}: Props): ReactElement => {
  const translate = useTranslate()
  const account = useSelector(getCurrentAccount)

  const [headers, setHeaders] = useState<HeaderColumn[]>([])
  const [order, setOrder] = useState<Order>(undefined)
  const [orderBy, setOrderBy] = useState<string>('No')
  const [openedFolder, setOpenedFolder] = useState<OpenedFolder>({
    value: '',
    nesting: 0,
  })
  const [filterColumns, setFilterColumns] = useState<{
    [key: string]: 'text' | TableFilterDate | 'minmax' | TableBodyCellContent[]
  }>({})
  const [headersNames, setHeadersNames] = useState<string[]>([])

  const classes = useClasses()

  const handleSetOpenedFolder = useCallback((value: string, nesting: number) => {
    setOpenedFolder({
      value,
      nesting,
    })
  }, [])

  const handleSetFilter = useCallback(
    (key: string, values: TableFilterTypes) => {
      onFilterChange({ ...filter, [key]: values })
    },
    [filter, onFilterChange],
  )

  const handleSetSorting = useCallback(
    (key: string, sort: 'asc' | 'desc' | null) => {
      if (!sort) return handleTableParamsChange('sort', null)

      handleTableParamsChange('sort', { [key]: sort })
    },
    [handleTableParamsChange],
  )

  useEffect(() => {
    if (!isEmpty(filter)) {
      onFilterChange({})
    }

    handleTableParamsChange('sort', null)
    setOrder(undefined)
  }, [openedFolder, handleTableParamsChange, onFilterChange])

  useEffect(() => {
    let columns = {}

    headers.forEach(({ name }: HeaderColumn) => {
      columns = { ...columns, [name]: 'text' }
    })

    setFilterColumns(columns)
  }, [headers])

  useEffect(() => {
    setHeadersNames(headers.map(header => header.name))
  }, [headers])

  useEffect(() => {
    if (incomingHeaders.length) {
      const template = incomingHeaders.map(header => header.fieldLabel)

      setColumnsOrderTemplate(template)
    }
  }, [incomingHeaders])

  useEffect(() => {
    if (account) {
      const settings: any = getTableSettings(
        LocalStorageItems.UserSettings,
        account.userId.toString(),
        tableId,
      )

      if (
        settings &&
        settings.columnsTemplate &&
        settings.columnsTemplate.length &&
        incomingHeaders.length
      ) {
        setColumnsOrderTemplate(
          settings.columnsTemplate.map(
            (columnIndex: number) => incomingHeaders[columnIndex]?.fieldLabel,
          ),
        )
        setColumns(
          settings.columns.map((columnIndex: number) => incomingHeaders[columnIndex]?.fieldLabel),
        )
      } else {
        setColumnsOrderTemplate(incomingHeaders.map(header => header?.fieldLabel))
        setColumns([])
      }
    }
  }, [tableId, account, incomingHeaders])

  useEffect(() => {
    if (!columns.length && columnsOrderTemplate.length) {
      return setColumns(columnsOrderTemplate)
    }
  }, [columnsOrderTemplate])

  useEffect(() => {
    if (!columnsOrder.length && columnsOrderTemplate.length) {
      setColumnsOrder(columnsOrderTemplate)
    }
  }, [columnsOrderTemplate])

  useEffect(() => {
    if (columns.length && columnsOrderTemplate.length) {
      return setColumnsOrder(columnsOrderTemplate.filter(column => columns.includes(column)))
    }
  }, [columnsOrderTemplate, columns])

  useEffect(() => {
    if (columnsOrder.length) {
      setHeaders(
        columnsOrder.map(column => {
          const fieldData = incomingHeaders.find(header => header.fieldLabel === column)

          return {
            label: fieldData?.fieldLabel || '',
            name: fieldData?.fieldName || '',
          }
        }),
      )
    }
  }, [columnsOrder, incomingHeaders])

  return (
    <div className={classes.root}>
      {tableHeader && <TableToolbar>{tableHeader}</TableToolbar>}
      <TableContainer component={Paper} className={classes.table}>
        <Table>
          {!isLoading ? (
            <TableBody>
              {data.map((row: GroupedTicket) => (
                <FolderRow
                  key={row.group.groupValue}
                  data={row}
                  headers={headersNames}
                  order={order}
                  orderBy={orderBy}
                  filter={filter}
                  paddingLeft={15}
                  openedFolder={openedFolder}
                  incomingHeaders={incomingHeaders}
                  nesting={row.groupLevelNo}
                  onSetOpenedFolder={handleSetOpenedFolder}
                  posTicketClick={posTicketClick}
                  ticketClick={ticketClick}
                  tableHeaderOptions={{
                    columnsOrderTemplate: columnsOrderTemplate,
                    columnsOrder: columnsOrder,
                    headers: headers,
                    filter: filter,
                    filterColumns: filterColumns,
                    setTableSettingsToLocalStorage,
                    setColumnsOrderTemplate: setColumnsOrderTemplate,
                    handleSetFilter: handleSetFilter,
                    handleSetSorting: handleSetSorting,
                    setOrder: setOrder,
                    setOrderBy: setOrderBy,
                  }}
                />
              ))}
              {data && data.length === 0 && ticketsTotalCount === 0 && (
                <MuiTableRow>
                  <TableCell className={classes.noDataCell} colSpan={incomingHeaders.length}>
                    {translate('translate#title.noData')}
                  </TableCell>
                </MuiTableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              <MuiTableRow className={classes.loaderWrapper}>
                <TableCell className={classes.cell}>
                  <Loader />
                </TableCell>
              </MuiTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  )
}

export default TreeTable
