import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { RealTimeMonitoringApi } from '@/api/cm/adminApi'
import { CMAppConfigActions } from '@/constants'
import { setComCMError } from '@/store/cm/common'

import {
  getRealTimeMonitoringFail,
  getRealTimeMonitoringRequest,
  getRealTimeMonitoringResponse,
} from './actions'

function* getRealTimeMonitoring(): SagaIterator {
  try {
    const response = yield call(RealTimeMonitoringApi.get)

    yield put(getRealTimeMonitoringResponse(response))
  } catch (error) {
    yield put(getRealTimeMonitoringFail())
  }
}

function* saveRealTimeMonitoring({ payload }: AnyAction): SagaIterator {
  try {
    yield call(RealTimeMonitoringApi.save, payload)
    yield put(getRealTimeMonitoringRequest())
  } catch (error) {
    yield put(setComCMError(error))

    yield put(getRealTimeMonitoringRequest())
  }
}

export default function*(): Generator {
  yield takeLatest(CMAppConfigActions.AdmGetRealTimeMonitoringRequest, getRealTimeMonitoring)
  yield takeLatest(CMAppConfigActions.AdmSaveRealTimeMonitoringRequest, saveRealTimeMonitoring)
}
