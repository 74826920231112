import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'
import { TypeOfTickets } from '@/types'

import * as fromDevices from './reducer'

export const getTicketsState = (state: AppState): fromDevices.State => state.atemeyeReports

export const getSelectedGroupingTemplate = createSelector(
  getTicketsState,
  state => state.selectedGroupingTemplate,
)

export const getCurrentFilterTemplate = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.filter[state.typeOfTickets as TypeOfTickets].selectedTemplate
    : fromDevices.initialState.filter.allTickets.selectedTemplate,
)

export const getFilterTemplateFields = createSelector(
  getTicketsState,
  state => state.filterTemplateFields,
)

export const getGroupedTicketsData = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.data[state.typeOfTickets as TypeOfTickets].groups
    : fromDevices.initialState.data.allTickets.groups,
)

export const getSelectedFilters = createSelector(getTicketsState, state => {
  return state.typeOfTickets
    ? state.filter[state.typeOfTickets as TypeOfTickets].selectedFilters
    : fromDevices.initialState.filter.allTickets.selectedFilters
})

export const getFiltersTemplates = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.filter[state.typeOfTickets as TypeOfTickets].filterTemplates
    : fromDevices.initialState.filter.allTickets.filterTemplates,
)

export const isFetchingFilterTemplates = createSelector(
  getTicketsState,
  state => state.isFetchingFilterTemplates,
)

export const isFetchingTickets = createSelector(getTicketsState, state => state.isFetchingTickets)

export const getGroupingTemplates = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.grouping[state.typeOfTickets as TypeOfTickets].groupingsTemplates
    : fromDevices.initialState.grouping.allTickets.groupingsTemplates,
)

export const getGrouping = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.grouping[state.typeOfTickets as TypeOfTickets].selectedGroupings
    : fromDevices.initialState.grouping.allTickets.selectedGroupings,
)

export const isFetchingGroupTemplates = createSelector(
  getTicketsState,
  state => state.isFetchingGroupTemplates,
)

export const getTicketsConfigData = createSelector(
  getTicketsState,
  state => state.config[state.typeOfTickets],
)

export const getIsFetchingTicketsConfig = createSelector(
  getTicketsState,
  state => state.isFetchingTicketsConfig,
)

export const hasGroupedTickets = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.grouping[state.typeOfTickets as TypeOfTickets].selectedGroupings?.length !== 0
    : false,
)

export const getInProgressReports = createSelector(
  getTicketsState,
  state => state.inProgressReports,
)
