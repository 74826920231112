import { makeStyles } from '@material-ui/core'

export const useClasses = makeStyles(() => ({
  filed: {
    margin: '16px 0 0',
  },
  loader: {
    width: `20px !important`,
    height: `20px !important`,
    marginRight: '6px',

    '&:after': {
      top: 0,
      left: 0,
      borderWidth: '3px',
      width: '100%',
      height: '100%',
    },
  },
}))
