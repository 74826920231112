import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  modalFooter: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 20,

    '& button': {
      margin: 0,
    },
  },
  cancelBtn: {
    color: '#929A9B',
  },

  dialogWrapper: {
    height: '100%',
  },

  wrapper: {
    padding: spacing(2, 4),
    display: 'grid',
    gridTemplateRows: 'max-content 1fr max-content',
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '90vh',
  },

  mapWrapper: {
    width: '100%',
    height: '100%',
  },

  gridContainer: {
    margin: spacing(0, -1),
    height: '100%',
  },

  closeButton: {
    top: spacing(1),
    right: spacing(1),

    '& [class*="makeStyles-icon"]': {
      color: '#041517',
    },
  },

  header: {
    marginTop: 20,
    marginBottom: 0,
    fontWeight: 'normal',
  },
  modalHeader: {
    marginBottom: 0,
    fontWeight: 'normal',
  },

  buttonWrapper: {
    marginTop: spacing(0.5),
  },

  tableWrapper: {
    overflowX: 'hidden',
    boxShadow: 'none',
    width: 840,
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
  },

  field: {
    margin: 0,
  },
}))
