import makeStyles from '@material-ui/core/styles/makeStyles'

import { styledComponentTheme } from '@/theme'
import colors from '@/theme/colors'

export const useClasses = makeStyles(({ spacing }) => ({
  cell: {
    height: spacing(5),
    minHeight: spacing(5),
    maxHeight: spacing(5),
    padding: 0,
    borderBottom: 0,
    whiteSpace: 'nowrap',
  },

  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  withBlueColor: {
    color: colors.primary,
  },

  iconSize: {
    fontSize: styledComponentTheme.fontSizes.big,
  },
  loader: {
    width: '25px',
    height: '25px',
  },
}))
