import React, { useCallback } from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import InputLabel from '@material-ui/core/InputLabel'
import YoutubeSearchedFor from '@material-ui/icons/YoutubeSearchedFor'
import { Props } from './types'
import { useClasses } from './styles'
import Button from '@/components/controls/Button/index'
import { FormattedMessage } from 'react-intl'
import { translate } from '@/utils/internalization'

export const UnitsRadioButtons = ({
  value,
  onChange,
  handleSearchOpen,
}: Props): React.ReactElement => {
  const classes = useClasses()

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: string): void => {
      onChange(value)
    },
    [onChange],
  )

  return (
    <>
      <InputLabel className={classes.label} />
      <div className={classes.wrapper}>
        <RadioGroup
          className={classes.radios}
          row
          value={value}
          onChange={handleChange}
          defaultValue="1"
        >
          <FormControlLabel
            value="1"
            control={<Radio size="small" classes={{ root: classes.radio }} />}
            label={translate('translate#title.byLUNO')}
            labelPlacement="end"
          />
          <FormControlLabel
            value="0"
            control={<Radio size="small" classes={{ root: classes.radio }} />}
            label={translate('translate#title.byOther')}
            labelPlacement="end"
          />
        </RadioGroup>
        <Button
          disableRipple
          onClick={handleSearchOpen}
          borderless
          startIcon={<YoutubeSearchedFor />}
          variant="outlined"
        >
          <FormattedMessage id="title.advancedSearch" defaultMessage="Advanced Search" />
        </Button>
      </div>
    </>
  )
}
