import React, { useMemo } from 'react'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import Test from 'react-test-attributes'
import { Field } from 'formik'
import { CreatePOSRequestFormFields } from '@/constants/forms/createPosRequestForms/CreatePOSRequestForm'
import { createTextInputField } from '@/components/controls/TextInput/component'
import { createDropDownInputField } from '@/components/controls/DropDownInput/component'
import { useTranslate } from '@/utils/internalization'
import { isIE } from 'react-device-detect'
import { useClasses } from './styles'
import { getTestId } from '@/utils'
import { useSelector } from 'react-redux'
import {
  getIsLookingMerchants,
  getMerchants,
  getServiceCompanies,
  isLookingForCreatingNewTicketFormConfig,
} from '@/store/sd/drawerActions/selectors/newPOS'
import { useSearch } from '@/utils/hooks/hooks'
import { getExistingMerchantsRequest } from '@/store/sd/drawerActions/actions'
import { Props } from './types'
import { isEmpty } from 'lodash-es'

const OldMerchantForm = ({
  testIdPrefix,
  openNewMerchantForm,
  isEditingPOSTicket,
  editingPosTicketInfo,
  hasServices,
}: Props): React.ReactElement => {
  const companies = useSelector(getServiceCompanies)
  const merchants = useSelector(getMerchants)
  const isLoadingExistingMerchants = useSelector(getIsLookingMerchants)
  const isLoadingCreateNewPOSTicketFormConfig = useSelector(isLookingForCreatingNewTicketFormConfig)
  const [handleChange] = useSearch(getExistingMerchantsRequest)

  const translate = useTranslate()
  const props = { display: isIE ? 'block' : 'flex' }
  const classes = useClasses(props)
  const idPrefix = `${testIdPrefix}-form`
  const testId = getTestId(idPrefix)

  const CustomerNumberInput = useMemo(
    () =>
      createTextInputField({
        required: true,
        placeholder: translate('translate#form.placeholder.enterCustomerReqNum'),
        label: translate('translate#form.label.customerReqNum'),
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
        },
      }),
    [],
  )

  const AcquirerBankInput = useMemo(
    () =>
      createTextInputField({
        disabled: true,
        label: translate('translate#form.label.acqirerBank'),
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
        },
      }),
    [],
  )

  const DateInput = useMemo(
    () =>
      createTextInputField({
        label: translate('translate#form.label.date'),
        disabled: true,
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
        },
      }),
    [],
  )

  const CompanySelect = useMemo(() => {
    return createDropDownInputField({
      label: translate('translate#form.label.serviceCompany'),
      placeholder: translate('translate#form.placeholder.selectCompany'),
      neverDisable: false,
      isLoading: isLoadingCreateNewPOSTicketFormConfig,
      options: companies,
      className: classes.disabled,
    })
  }, [companies, isLoadingCreateNewPOSTicketFormConfig, openNewMerchantForm])

  const preselectedMerchantForEditingPOSTicket = useMemo((): Array<{
    value: string
    name: string
  }> => {
    if (isEditingPOSTicket && !isEmpty(editingPosTicketInfo)) {
      const { targetcompanyid, merchantcompany } = editingPosTicketInfo

      if (targetcompanyid && merchantcompany) {
        return [{ name: merchantcompany as string, value: `${targetcompanyid}` }]
      }
    }

    return []
  }, [isEditingPOSTicket, editingPosTicketInfo])

  const MerchantDropDown = useMemo(() => {
    const isDisable = openNewMerchantForm || isEditingPOSTicket || hasServices

    return createDropDownInputField({
      label: translate('translate#form.label.selectExistingMerchant'),
      placeholder: translate('translate#form.placeholder.enterMerchant'),
      isLoading: isLoadingExistingMerchants,
      disabled: isDisable,
      required: !isDisable,
      neverDisable: false,
      withoutSelectArrow: true,
      withoutSingleOption: true,
      onTextChange: handleChange,
      options: isEditingPOSTicket ? preselectedMerchantForEditingPOSTicket : merchants,
    })
  }, [merchants, openNewMerchantForm, hasServices, isEditingPOSTicket])

  return (
    <Grid className={clsx(classes.wrapper, classes.wrapperWithBottom)} container>
      <Grid className={classes.field} item md={6}>
        <Test id={testId(4)}>
          <Field name={CreatePOSRequestFormFields.AcquirerBank}>{AcquirerBankInput}</Field>
        </Test>
      </Grid>
      <Grid className={classes.field} item md={6}>
        <Test id={testId(5)}>
          <Field name={CreatePOSRequestFormFields.CustomerRequestNumber}>
            {CustomerNumberInput}
          </Field>
        </Test>
      </Grid>
      <Grid className={classes.field} item md={6}>
        <Test id={testId(6)}>
          <Field name={CreatePOSRequestFormFields.ServiceCompany}>{CompanySelect}</Field>
        </Test>
      </Grid>
      <Grid className={classes.field} item md={6}>
        <Test id={testId(7)}>
          <Field name={CreatePOSRequestFormFields.Date}>{DateInput}</Field>
        </Test>
      </Grid>
      <Grid className={classes.field} item md={6}>
        <Test id={testId(8)}>
          <Field name={CreatePOSRequestFormFields.Merchant}>{MerchantDropDown}</Field>
        </Test>
      </Grid>
    </Grid>
  )
}

export default OldMerchantForm
