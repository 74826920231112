import React, { ReactElement } from 'react'
import DocumentInformationModal from './components/DocumenInformationModal'

import { Props } from './types'

const PSRTicketDetails = ({
  open,
  idPrefix,
  handleClose,
  ticketId,
  handleOpenCreatePosRequestModal,
}: Props): ReactElement => {
  const detailsIdPrefix = `${idPrefix}-psr-details`

  return (
    <>
      {/* this syntax need for rerender dialog */}
      {open && (
        <DocumentInformationModal
          open
          ticketId={ticketId}
          testIdPrefix={detailsIdPrefix}
          handleClose={handleClose}
          handleOpenCreatePosRequestModal={handleOpenCreatePosRequestModal}
        />
      )}
    </>
  )
}

export default PSRTicketDetails
