import { MonVaultDetails } from '@/api/vault-v1/swaggerGeneratedApi'

export type CashPointDetailsHeader = {
  field: string
  title: string
}

export type CashPointDetailsData = {
  [key: string]: string | number
}

export type CashPointDetails = {
  headers: CashPointDetailsHeader[]
  data: CashPointDetailsData[]
}

export const mapForecast = (forecast: MonVaultDetails): CashPointDetails => {
  if (!forecast) {
    return { data: [], headers: [] }
  }

  const { data, headers } = forecast

  const formattedHeaders: CashPointDetailsHeader[] = (headers || []).map(header => ({
    field: header.fieldId as string,
    title: header.displayName as string,
  }))

  const chartData: CashPointDetailsData[] = []

  if (data) {
    data.forEach(({ columnsValues }) => {
      const localChartData = {} as CashPointDetailsData

      if (columnsValues && headers) {
        for (let i = 0; i < columnsValues.length; i++) {
          const { fieldId } = headers[i] || {}

          if (fieldId) {
            localChartData[fieldId] = columnsValues[i]
          }
        }
      }
      chartData.push(localChartData)
    })
  }

  return { data: chartData || [], headers: formattedHeaders || [] }
}
