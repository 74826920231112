import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import clsx from 'clsx'
import React, { useCallback, useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'
import { useDispatch } from 'react-redux'

import Loader from '@/components/blocks/Loader'
import { TableBodyCell, TableFilterTypes } from '@/components/controls/AppTable/types'
import MyReportsContextMenu from '@/components/pages/sd/reports/components/MyReportsPage/components/MyreportsContextMenu/component'
import { setTableColumnsOrder } from '@/store/common/tables'
import { dummyFunction, reorderArray, stopPropagation } from '@/utils/functions'
import { useTranslate } from '@/utils/internalization'

import TableBodyCellWrapped from '../TableBodyCellWrapped/component'
import TableHeaderCell from '../TableHeaderCell'
import { useClasses } from './styles'
import { Props } from './types'

export const SELECT_FIELD_KEY = 'select'

const ManageableTable = ({
  data,
  filterColumns,
  onRowClick,
  columnDisplayOrder,
  setColumnOrder,
  highlightRows,
  isDataLoading = false,
  onFilterChange,
  onOrderChange,
  order,
  orderBy,
  filter,
  groupBy,
  totalRow,
  subHeaderRow,
  actionColumnElement,
  actionColumnTitle,
  tableContainerClassName,
  tableClasses,
  withSelectingRows,
  onSelectAllRows,
  rowCount,
  doubleColumn,
  slaStyles,
  numSelected,
  actionDotMenu,
  withTooltip,
  stickyHeader,
  withoutTranslate,
  columnsWithTranslate,
  areDatesInTable,
  tableId,
  prefix,
}: Props): React.ReactElement => {
  const keys = columnDisplayOrder

  const dispatch = useDispatch()

  // const dataKeys = Object.keys(data)
  const dataKeys: string[] = []
  for (const [key] of data) {
    dataKeys.push(key)
  }
  const [localKeys, setLocalKeys] = useState([...keys])
  const [hiddenGroup, setHiddenGroup] = useState<string[]>([])
  const [selectedRowId, setSelectedRowId] = useState<string>()
  const [dragStarted, setDragStarted] = useState<boolean>(false)
  const translate = useTranslate()
  const classes = useClasses()
  let closedGroup = false

  useEffect((): void => {
    const k = [...keys]

    if (doubleColumn) {
      doubleColumn.map(item => {
        const elementToDeKeys: any = data.get(dataKeys[0])
          ? (data.get(dataKeys[0]) as TableBodyCell[])[0]
          : {}
        const itemKeys = Object.keys(elementToDeKeys)

        const firstElement = itemKeys.find((key, i, arr) => {
          return doubleColumn?.includes(key) && !k.includes(arr[i + 1])
        })

        if (firstElement) {
          const secondElement = itemKeys.find((key, i, arr) => {
            return doubleColumn?.includes(arr[i - 1]) && !k.includes(key)
          })
          const index = k.findIndex(key => {
            return key === firstElement
          })

          k.splice(index + 1, 0, String(secondElement))
        }
      })
    }

    setLocalKeys(k)
  }, [keys, data])

  useEffect((): void => {
    setHiddenGroup([])
  }, [groupBy])

  const handleSort = useCallback(
    (key: string): (() => void) => (): void => {
      if (orderBy === key) {
        switch (order) {
          case undefined:
            return onOrderChange('asc', key)
          case 'asc':
            return onOrderChange('desc', key)
          case 'desc':
            return onOrderChange(undefined, key)
        }
      } else {
        onOrderChange('asc', key)
      }
    },
    [orderBy, order, onOrderChange],
  )

  const handleSetFilter = useCallback(
    (key: string, values: TableFilterTypes) => {
      onFilterChange({ ...filter, [key]: values })
    },
    [filter, onFilterChange],
  )

  const handleRowClick = useCallback(
    (key: string): (() => void) => (): void => {
      if (onRowClick) {
        if (!selectedRowId) {
          setSelectedRowId(key)
        } else {
          setSelectedRowId('')
        }
        onRowClick(data.get(key) || [])
      }
    },
    [onRowClick, data],
  )
  const handleChangeHiddenGroups = useCallback(
    (newGroup: string): (() => void) => (): void => {
      setHiddenGroup(prevGroups => {
        if (prevGroups.includes(newGroup)) {
          return prevGroups.filter(group => group !== newGroup)
        } else {
          return [...prevGroups, newGroup]
        }
      })
    },
    [],
  )

  const handleDragEnd = useCallback<(result: DropResult, provided: ResponderProvided) => void>(
    (result): void => {
      setDragStarted(false)
      if (!result.destination) {
        return
      }
      if (tableId) {
        dispatch(
          setTableColumnsOrder({
            tableId,
            columns: reorderArray(
              columnDisplayOrder,
              result.source.index,
              result.destination.index,
            ),
          }),
        )
      }
      setColumnOrder(
        reorderArray(columnDisplayOrder, result.source.index, result.destination.index),
      )
    },
    [tableId, dispatch, setTableColumnsOrder, setColumnOrder, columnDisplayOrder],
  )

  return (
    <>
      <TableContainer
        className={tableContainerClassName}
        classes={{ root: tableClasses?.container }}
      >
        <Table
          stickyHeader={stickyHeader || false}
          size="small"
          className={clsx(classes.table, tableClasses?.table, {
            [classes.tableNonFixed]: prefix === 'PSR',
          })}
        >
          <TableHead classes={{ root: tableClasses?.tableHead }}>
            <DragDropContext
              onBeforeDragStart={(): void => setDragStarted(true)}
              onDragEnd={handleDragEnd}
            >
              <Droppable droppableId="tableHeader" direction="horizontal">
                {(provided: DroppableProvided): React.ReactElement => (
                  <TableRow
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ verticalAlign: 'baseline' }}
                  >
                    {actionColumnElement && (
                      <TableCell className={clsx(classes.actionCell, tableClasses?.actions)}>
                        <Box fontWeight="fontWeightBold">{actionColumnTitle || 'Actions'}</Box>
                      </TableCell>
                    )}
                    {keys.map((key, i) => (
                      <Draggable key={key} draggableId={`tableHeader${key}`} index={i}>
                        {(
                          draggableProvided: DraggableProvided,
                          { isDragging }: DraggableStateSnapshot,
                        ): React.ReactElement => {
                          // Portal active elements above everything
                          return (isDragging ? createPortal : dummyFunction)(
                            <TableHeaderCell
                              data={data}
                              key={`tableHeaderCell${key}${i}`}
                              isDragOccurring={dragStarted}
                              draggableProvided={draggableProvided}
                              groupBy={i === 0 ? groupBy : undefined}
                              value={key}
                              filterValues={filterColumns ? filterColumns[key] : undefined}
                              filter={filter}
                              order={order}
                              orderBy={orderBy}
                              handleSetFilter={handleSetFilter}
                              handleSort={handleSort}
                              headClasses={{
                                root: tableClasses?.headColumn,
                                headColumnCell: tableClasses?.headColumnCell,
                              }}
                              withCheckBox={withSelectingRows && key === SELECT_FIELD_KEY}
                              onSelectAllRows={onSelectAllRows}
                              rowCount={rowCount}
                              slaStyles={slaStyles}
                              colSpan={doubleColumn?.includes(key) ? 2 : 1}
                              numSelected={numSelected}
                              withoutTranslate={withoutTranslate}
                              prefix={prefix}
                            />,
                            document.body,
                          )
                        }}
                      </Draggable>
                    ))}
                    {actionDotMenu && (
                      <TableCell className={clsx(classes.dotMenu, tableClasses?.actions)} />
                    )}
                  </TableRow>
                )}
              </Droppable>
            </DragDropContext>
          </TableHead>
          <TableBody>
            {!!dataKeys.length &&
              !isDataLoading &&
              subHeaderRow &&
              Object.keys(subHeaderRow).length && (
                <TableRow className={classes.subHeaderRow}>
                  {localKeys.map(localKey => {
                    return (
                      <TableBodyCellWrapped
                        key={`tableSubHeaderCell${localKey}${subHeaderRow[localKey]}`}
                        value={subHeaderRow[localKey]}
                        groupBy={groupBy}
                        closed={closedGroup}
                        areDatesInTable={areDatesInTable}
                        cellClasses={tableClasses?.headColumn}
                      />
                    )
                  })}
                </TableRow>
              )}
            {!!dataKeys.length &&
              !isDataLoading &&
              dataKeys.reduce((accum: React.ReactElement[], rowKey: string) => {
                const dataForRender = data.get(rowKey) || []
                return [
                  ...accum,
                  ...dataForRender.map((row: TableBodyCell, i: number) => {
                    const firstCell = row[localKeys[0]]
                    const isGroupHeader =
                      firstCell !== null && typeof firstCell === 'object'
                        ? firstCell.isGroupHeader
                        : undefined
                    if (isGroupHeader) {
                      closedGroup = hiddenGroup.includes(rowKey)
                    }
                    const isEmpty = localKeys.every(key => {
                      const rowKeyValue = row[key] || ''
                      return !(typeof rowKeyValue === 'object' ? rowKeyValue.value : rowKeyValue)
                    })
                    return !isEmpty ? (
                      isGroupHeader || !closedGroup ? (
                        <TableRow
                          key={`tableRow${i}${rowKey}`}
                          className={clsx(
                            tableClasses?.row,
                            selectedRowId === rowKey && classes.activeRow,
                            {
                              [classes.tableRowHighlight]:
                                highlightRows && highlightRows.includes(rowKey),
                              [classes.tableRowClickable]: onRowClick || isGroupHeader,
                            },
                          )}
                          onClick={
                            onRowClick && !isGroupHeader
                              ? handleRowClick(rowKey)
                              : isGroupHeader
                              ? handleChangeHiddenGroups(rowKey)
                              : undefined
                          }
                        >
                          {actionColumnElement && (
                            <TableCell onClick={stopPropagation}>
                              {actionColumnElement(rowKey, dataForRender)}
                            </TableCell>
                          )}

                          {localKeys.map((cellKey, cellIndex) => {
                            return (
                              <TableBodyCellWrapped
                                key={`tableCell${cellIndex}${i}`}
                                value={
                                  columnsWithTranslate?.includes(cellKey)
                                    ? translate(`translate#title.${row[cellKey]}`)
                                    : row[cellKey]
                                }
                                groupBy={groupBy}
                                closed={closedGroup}
                                cellClasses={tableClasses?.cell}
                                withTooltip={withTooltip}
                                prefix={prefix}
                              />
                            )
                          })}

                          {actionDotMenu && (
                            <TableCell onClick={stopPropagation}>
                              <MyReportsContextMenu id={rowKey} reportsName={rowKey} uid="test" />
                            </TableCell>
                          )}
                        </TableRow>
                      ) : (
                        <></>
                      )
                    ) : dataForRender.length === i + 1 && i + 1 < localKeys.length ? (
                      <TableRow key={`tableRow${i}${rowKey}`}>
                        <TableCell
                          key={`tableRow${i}${rowKey}`}
                          className={classes.emptyRowDivider}
                          colSpan={localKeys.length}
                        />
                      </TableRow>
                    ) : (
                      <></>
                    )
                  }),
                ]
              }, [])}
            {!!dataKeys.length && !isDataLoading && totalRow && Object.keys(totalRow).length && (
              <TableRow className={classes.root}>
                {localKeys.map(localKey => {
                  return (
                    <TableBodyCellWrapped
                      key={`tableCell${localKey}${totalRow[localKey]}`}
                      value={totalRow[localKey]}
                      groupBy={groupBy}
                      closed={closedGroup}
                      areDatesInTable={areDatesInTable}
                      cellClasses={tableClasses?.headColumn}
                    />
                  )
                })}
              </TableRow>
            )}
          </TableBody>
        </Table>
        {isDataLoading && (
          <div className={classes.loaderWrapper}>
            <Loader />
          </div>
        )}
      </TableContainer>
    </>
  )
}

export default ManageableTable
