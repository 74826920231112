import { Box, Card, Link, Popover, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useNotification } from '@/components/blocks/ATMeye/notifications/hooks/useNotification'
import { NotificationItem } from '@/components/blocks/ATMeye/notifications/NotificationItem'
import { notificationConfig } from '@/constants/atmeye/notificationConfig'
import {
  actionsNotifications,
  getAlarmCount,
  getAlarms,
  getBlockAlarms,
  getBlockEvents,
  getEvents,
  getEventsCount,
} from '@/store/notifications'
import { formatCount } from '@/utils/functions'
import { useTranslate } from '@/utils/internalization'

import { useStyles } from './styles'
import { NotificationModalProps } from './types'

export const NotificationModal = ({ open, id, handleClose, anchorEl }: NotificationModalProps) => {
  const classes = useStyles()
  const translate = useTranslate()

  const eventsCount = useSelector(getEventsCount)
  const alarmCount = useSelector(getAlarmCount)
  const events = useSelector(getEvents)
  const alarms = useSelector(getAlarms)
  const blockAlarms = useSelector(getBlockAlarms)
  const blockEvents = useSelector(getBlockEvents)

  const history = useHistory()
  const dispatch = useDispatch()

  const technicalEventIds = useMemo(() => {
    return events.map(({ eventId }) => eventId).filter(x => !blockEvents.includes(x))
  }, [events, blockEvents])

  const securityAlarmIds = useMemo(() => {
    return alarms.map(({ eventId }) => eventId).filter(x => !blockAlarms.includes(x))
  }, [alarms, blockAlarms])

  const { onTitleClick, onEventNameClick, handleOnArrowClick, onViewAllClick } = useNotification()

  const onCloseEventClick = (eventId: number) => {
    if (!blockEvents.includes(eventId)) {
      dispatch(actionsNotifications.deleteEvents({ eventIds: [eventId] }))
    }
  }

  const onCloseAlarmClick = (eventId: number): void => {
    if (!blockEvents.includes(eventId)) {
      dispatch(actionsNotifications.deleteAlarms({ eventIds: [eventId] }))
    }
  }

  const onCloseAllTechnicalEvent = (): void => {
    dispatch(actionsNotifications.deleteEvents({ eventIds: technicalEventIds }))
  }

  const onCloseAllSecurityAlarms = (): void => {
    dispatch(actionsNotifications.deleteAlarms({ eventIds: securityAlarmIds }))
  }

  const onOptionPage = (): void => {
    history.push('/atmeye/atmeye-administration/options')
    handleClose()
  }

  return (
    <Popover
      id={id}
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <Card className={classes.card}>
        <Box className={classes.notifications}>
          <Box className={classes.headerContainer}>
            <Typography className={classes.header}>
              {translate('translate#atmeye.title.technicalEvents')}
              {!!eventsCount && (
                <CloseIcon onClick={onCloseAllTechnicalEvent} className={classes.closeIcon} />
              )}
            </Typography>
            {!!eventsCount && (
              <Link
                className={classes.link}
                onClick={onViewAllClick(notificationConfig.TECHNICAL_EVENT)}
              >
                {`${translate('translate#atmeye.notifications.modal.viewAll')} (${formatCount({
                  count: eventsCount,
                })})`}
              </Link>
            )}
          </Box>
          <Box className={classes.content}>
            {!eventsCount && (
              <Box className={classes.linkContainer}>
                <Typography className={classes.noEventText}>
                  {translate('translate#atmeye.notifications.modal.noTechEvents')}
                </Typography>
              </Box>
            )}

            {events
              .slice(0, 5)
              .map(({ deviceName, deviceId, eventName, eventDate, eventId, eventTypeId }) => (
                <NotificationItem
                  key={`${eventId}-${Math.random()}`}
                  eventName={eventName}
                  deviceName={deviceName}
                  deviceId={deviceId}
                  eventDate={eventDate}
                  onCloseClick={() => onCloseEventClick(eventId)}
                  onEventNameClick={onEventNameClick}
                  onTitleClick={onTitleClick}
                  eventTypeId={eventTypeId}
                  eventId={eventId}
                />
              ))}
          </Box>
        </Box>
        <Box className={classes.notifications}>
          <Box className={classes.headerContainer}>
            <Typography className={classes.header}>
              {translate('translate#atmeye.title.securityAlarms')}
              {!!alarmCount && (
                <CloseIcon onClick={onCloseAllSecurityAlarms} className={classes.closeIcon} />
              )}
            </Typography>
            {!!alarmCount && (
              <Link
                className={classes.link}
                onClick={onViewAllClick(notificationConfig.ALARM_EVENT)}
              >
                {`${translate('translate#atmeye.notifications.modal.viewAll')} (${formatCount({
                  count: alarmCount,
                })})`}
              </Link>
            )}
          </Box>
          <Box className={classes.content}>
            {!alarmCount && (
              <Box className={classes.linkContainer}>
                <Typography className={classes.noEventText}>
                  {translate('translate#atmeye.title.noSecurityAlarms')}
                </Typography>
              </Box>
            )}
            {alarms
              .slice(0, 5)
              .map(({ deviceName, eventName, eventDate, eventId, eventTypeId, deviceId }) => (
                <NotificationItem
                  key={`${eventId}-${Math.random()}`}
                  isAlarm
                  eventName={eventName}
                  deviceName={deviceName}
                  deviceId={deviceId}
                  eventDate={eventDate}
                  onCloseClick={() => onCloseAlarmClick(eventId)}
                  onArrowClick={handleOnArrowClick(deviceName, eventId, deviceId)}
                  onTitleClick={onTitleClick}
                  onEventNameClick={onEventNameClick}
                  eventTypeId={eventTypeId}
                  eventId={eventId}
                />
              ))}
          </Box>
        </Box>
        <Box className={classes.linkContainer} onClick={onOptionPage}>
          <Link className={classes.link}>
            {translate('translate#atmeye.title.notificationsSettings')}
          </Link>
        </Box>
      </Card>
    </Popover>
  )
}
