import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  iconButton: {
    minWidth: 0,
    padding: spacing(2),
    border: '1px solid rgba(80, 168, 174, 0.5)',
    borderRadius: 3,
    height: 40,
    '&:disabled': {
      filter: 'grayscale(1)',
    },
  },
  button: {
    height: 40,
    fontWeight: 'normal',
    padding: spacing(0, 2),
    margin: 0,
    whiteSpace: 'nowrap',
  },
}))
