import { AdmPasswordPolicySettings } from '@/api/cm/swaggerGeneratedApi'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class PasswordPolicyApi {
  private static requestService = getRequestServiceCM()

  public static async get(): Promise<AdmPasswordPolicySettings> {
    try {
      return await PasswordPolicyApi.requestService.get('/admin/passwordPolicySettings')
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async update(settings: AdmPasswordPolicySettings): Promise<void> {
    try {
      return await PasswordPolicyApi.requestService.put(
        '/admin/passwordPolicySettings/save',
        settings,
      )
    } catch (error) {
      throw Error(String(error as Error))
    }
  }
}
