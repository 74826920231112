import React from 'react'

import {
  AtmeyeSelectContextProps,
  AtmeyeSelectContextPropsWithoutConfig,
  AtmeyeSnapshotsContextProps,
  CreatePOSModalContextProps,
  NavigationContextProps,
  SnapshotContextType,
  SocketContextType,
  TicketOverviewContextProps,
} from '@/types/common/context'
import { PaginationDataContextTypes } from '@/types/common/context/PaginationDataContext'

import { CMSocketProps } from './api/cm/socket/types'

export const NavigationContext = React.createContext<Partial<NavigationContextProps>>({})

export const CreatePOSModalContext = React.createContext<Partial<CreatePOSModalContextProps>>({})

export const TicketOverviewContext = React.createContext<Partial<TicketOverviewContextProps>>({})

export const AtmeyeContext = React.createContext<Partial<AtmeyeSelectContextProps>>({})

export const AtmeyeContextWithoutConfig = React.createContext<
  Partial<AtmeyeSelectContextPropsWithoutConfig>
>({})

export const AtmeyeSnapshotsContext = React.createContext<Partial<AtmeyeSnapshotsContextProps>>({})

export const SocketContext = React.createContext<Partial<SocketContextType>>({})

export const SnapshotContext = React.createContext<Partial<SnapshotContextType>>({ snapshotId: 0 })
export const TablePaginationSearchFormContext = React.createContext<
  Partial<PaginationDataContextTypes>
>({})

export const CMSocketContext = React.createContext<CMSocketProps>({
  socket: null,
  isConnected: false,
})
