import SearchConfigurationIcon from '@/assets/adm/icons/advanced_search.svg'
import DevicesListIcon from '@/assets/adm/icons/devices_list.svg'
import GroupActionsIcon from '@/assets/adm/icons/group_actions.svg'
import ImportConfigurationIcon from '@/assets/adm/icons/import_devices.svg'
import { ADM_BLOCK_PREFIX, AppNavigationRoutes } from '@/constants'

export default [
  // {
  //   path: `${ADM_BLOCK_PREFIX}${AppNavigationRoutes.ADMDevicesList}`,
  //   label: 'translate#adm.devicesList',
  //   Icon: DevicesListIcon,
  // },
  {
    path: `${ADM_BLOCK_PREFIX}${AppNavigationRoutes.ADMDevices}`,
    label: 'translate#adm.devicesList',
    Icon: DevicesListIcon,
  },
  {
    label: 'translate#adm.import',
    Icon: ImportConfigurationIcon,
    subItems: [
      {
        path: `${ADM_BLOCK_PREFIX}${AppNavigationRoutes.ADMImportDevices}`,
        label: 'translate#adm.importDevices',
      },
      {
        path: `${ADM_BLOCK_PREFIX}${AppNavigationRoutes.ADMImportDeviceConfiguration}`,
        label: 'translate#adm.importDeviceConfiguration',
      },
    ],
  },
  {
    label: 'translate#adm.groupActions',
    Icon: GroupActionsIcon,
    subItems: [
      {
        path: `${ADM_BLOCK_PREFIX}${AppNavigationRoutes.ADMGroupActionChangeStatus}`,
        label: 'translate#adm.groupActionChangeState',
      },
      {
        path: `${ADM_BLOCK_PREFIX}${AppNavigationRoutes.ADMGroupActionChangeOwner}`,
        label: 'translate#adm.groupActionChangeOwner',
      },
      {
        path: `${ADM_BLOCK_PREFIX}${AppNavigationRoutes.ADMGroupActionChangeServiceDates}`,
        label: 'translate#adm.groupActionChangeServiceDates',
      },
    ],
  },
  {
    path: `${ADM_BLOCK_PREFIX}${AppNavigationRoutes.ADMSearchConfiguration}`,
    label: 'translate#adm.searchConfiguration',
    Icon: SearchConfigurationIcon,
  },
]
