import moment from 'moment'
import { TableFilterTypes } from '@/components/controls/AppTable/types'
import { ReactElement } from 'react'

export const checkValueAgainstFilter = (
  value: string | ReactElement,
  filter: TableFilterTypes,
): boolean => {
  if (typeof value === 'object') {
    return false
  }
  return true

  // not sure we need this
  // if (Array.isArray(filter)) {
  //   return filter.includes(value)
  // } else if (typeof filter === 'object') {
  //   const momentMin = moment(filter.start)
  //   const momentMax = moment(filter.end)
  //   const momentValue = moment(value)
  //   return momentMin.isValid() && momentMax && momentValue.isValid()
  //     ? momentMin < momentValue && momentValue < momentMax
  //     : !Number.isNaN(+filter.start) && !Number.isNaN(+filter.end) && !Number.isNaN(+value)
  //     ? +filter.start < +value && +value < +filter.end
  //     : filter.start < value && value < filter.end
  // } else {
  //   return value.includes(filter)
  // }
}
