import queryString from 'query-string'

import { AdmCassetteLimit, AdmCommonBranchObjectRequest } from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

interface GetProps {
  sort: Sort
  filter: AdmCommonBranchObjectRequest
}

export class CassetteLimitsApi {
  private static requestService = getRequestServiceCM()

  public static async get({ sort, filter }: GetProps): Promise<AdmCassetteLimit[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/cassettes/limits',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await CassetteLimitsApi.requestService.post(url, filter)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(payload: AdmCassetteLimit): Promise<void> {
    try {
      return await CassetteLimitsApi.requestService.put('/admin/cassettes/limits/update', payload)
    } catch (error) {
      throw Error(error)
    }
  }
}
