export const CM_CIT_PLANNING_WIDTH = 'citPlanningWidth'

export const CM_PLASTIC_CARD_WIDTH = 'plasticCardsWidth'

export const CM_ROUTES_WIDTH = 'routesWidth'
export const CM_ROUTES_HEIGHT = 'routesHeight'

export const CM_MONITORING_HEIGHT = 'monCashPointsCharts'
export const CM_MONITORING_WIDTH = 'monCashPoints'

export const CM_CASH_ORDERS_HEIGHT = 'ordCashPoints'
export const CM_CASH_ORDERS_WIDTH = 'ordCashPointsDetails'

export const CM_PLANNING_HEIGHT = 'plnCashPoints'
export const CM_PLANNING_WIDTH = 'plnCashPointsDetails'

export const CM_REPLENISHMENTS_HEIGHT = 'icsCashPointsPane'
export const CM_REPLENISHMENTS_WIDTH = 'icsReplenishmentsPane'

export const CM_OBJECT_TREE = 'objectTree'
