import { makeStyles } from '@material-ui/core/styles'

import { styledComponentTheme } from '@/theme/styledComponentTheme'

export const useClasses = makeStyles(({ spacing }) => ({
  tabContentWrapper: {
    overflow: 'hidden',
    background: '#f8f8f8',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  wrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },

  tab: {
    color: styledComponentTheme.colors.baseGreen,
    textTransform: 'none',
    zIndex: 2,
    minWidth: 'auto',
    minHeight: '28px',
    padding: '2px 12px',
  },

  tabBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 24px',
  },

  indicator: {
    backgroundColor: styledComponentTheme.colors.baseGreen,
    height: spacing(3.5),
    borderRadius: '4px',
  },

  selectedTab: {
    color: '#FFFFFF',
    backgroundColor: styledComponentTheme.colors.baseGreen,
    height: spacing(3.5),
    borderRadius: '4px',
  },

  tabsWrap: {
    minHeight: 'initial',
  },

  deviceName: {
    fontWeight: styledComponentTheme.fontWeights.medium,
    fontSize: styledComponentTheme.fontSizes.medium,
  },

  deviceNameWrap: {
    display: 'flex',
    alignItems: 'center',
  },

  toolTip: {
    background: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    color: 'black',
    padding: spacing(1),
    fontSize: '14px',
    top: spacing(1),
  },

  refreshButton: {
    border: '1px solid',
    borderColor: styledComponentTheme.colors.baseGreen,
    width: '40px',
    height: '40px',
    padding: '0',
    minWidth: 'auto',
    marginLeft: '16px',
    '& .MuiButton-startIcon': {
      margin: '0',
    },
  },
}))
