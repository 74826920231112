import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import React, { SyntheticEvent, useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import GlobalConfirmModal from '@/components/blocks/LogoutConfirmModal/component'
import Button from '@/components/controls/Button/index'
import ContextMenu from '@/components/controls/ContextMenu/component'
import ReportsModal from '@/components/pages/sd/reports/components/ReportsModal'
import { actions } from '@/store/sd/reports/actions'
import { getReportsConfig } from '@/store/sd/reports/selectors'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import { Props } from './types'

const MyReportsContextMenu: React.FC<Props> = ({
  id,
  reportsName,
  subscriptionId,
  uid,
}): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<null | (EventTarget & HTMLButtonElement)>(null)
  const [isShowGlobalConfirmModal, setIsShowGlobalConfirmModal] = useState<boolean>(false)
  const [openReportModal, setOpenReportModal] = useState<string | null>(null)
  // const reportById = useSelector(getReport)

  const reportByIdConfig = useSelector(getReportsConfig)

  const handleClose = useCallback((): void => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const handleOpenCloseConfirmModal = useCallback((): void => {
    setIsShowGlobalConfirmModal(isShowGlobalConfirmModal => !isShowGlobalConfirmModal)
  }, [isShowGlobalConfirmModal])

  const removeReportRequest = useCallback((): void => {
    dispatch(actions.getDeleteReportRequest(subscriptionId, reportsName, translate))
    setIsShowGlobalConfirmModal(false)
  }, [isShowGlobalConfirmModal])

  const handleRemoveReportOpenModal = useCallback((): void => {
    handleOpenCloseConfirmModal()
    setAnchorEl(null)
  }, [])

  const editReport = useCallback((): void => {
    dispatch(actions.getReportByIdRequest(subscriptionId))
    dispatch(actions.setReportIdAndReportUid(id, uid, subscriptionId, reportsName))
    setOpenReportModal(reportsName)
    setAnchorEl(null)
  }, [subscriptionId])

  const handleClick = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl],
  )

  const handleCloseReport = useCallback((): void => {
    setOpenReportModal(null)
  }, [])

  const handleSubmit = useCallback((values: any): void => {
    dispatch(actions.saveReportRequest(values, translate))
    setOpenReportModal(null)
  }, [])

  return (
    <>
      <Button width="xs" onClick={handleClick} indentation="0">
        <MoreVertIcon className={classes.labelIcon} fontSize="large" />
      </Button>
      <ContextMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        classes={{
          list: classes.list,
        }}
        options={[
          {
            content: (
              <>
                <EditIcon color="primary" />
                <Typography className={classes.label}>
                  <FormattedMessage id="action.edit" defaultMessage="Edit" />
                </Typography>
              </>
            ),
            handleClick: () => editReport(),
          },
          {
            content: (
              <>
                <DeleteIcon color="error" />
                <Typography className={classes.label}>
                  <FormattedMessage id="action.remove" defaultMessage="Remove" />
                </Typography>
              </>
            ),
            handleClick: () => handleRemoveReportOpenModal(),
          },
        ]}
      />
      <ReportsModal
        handleClose={handleCloseReport}
        open={!!openReportModal}
        idReport={openReportModal}
        nameReport={reportsName}
        handleSubmit={handleSubmit}
        uidReport={uid || ''}
        config={reportByIdConfig}
        hideClearButton
      />
      <GlobalConfirmModal
        open={isShowGlobalConfirmModal}
        onClose={handleOpenCloseConfirmModal}
        onConfirm={removeReportRequest}
        message={translate(`translate#title.areYouSureYouWantToDelete`) + '?'}
        nameItem={reportsName}
        disableBackdropClick
      />
    </>
  )
}

export default MyReportsContextMenu
