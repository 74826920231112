import { isEmpty } from 'lodash-es'

import { ResponseError } from '@/types'
import { LookupFieldConfig } from '@/types/common/lookupConfig'
import { requestService } from '@/utils/services/request'

import {
  Address,
  AddressResponse,
  BusinessEntityKey,
  CompanyRequest,
  CompanyTreeElement,
  CreateCompanyResponse,
  CreateDepartmentResponse,
  CreatePersonnelResponse,
  DayOffItem,
  DepartmentRequest,
  DutySchedule,
  DutyScheduleRequestItem,
  FieldLookupConfigInfo,
  ImportCompanyRequestData,
  LookupResult,
  PersonnelRequest,
  PersonnelRoleHistoryResponse,
  QualificationLevel,
  QualificationLevelRequest,
  Role,
  SalaryItem,
  ServiceCompaniesResponse,
  TimePeriod,
  WorkingHour,
  WorkingHourRequest,
} from './types'
import {
  getDeletedDayoffsIds,
  getDeletedDutyScheduleIds,
  getDeletedQualificationIds,
  getDeletedSalaryIds,
} from './utils'

export class CompaniesApi {
  public static async getCompanyTypes(
    deviceId: number,
  ): Promise<ServiceCompaniesResponse | ResponseError> {
    try {
      const response = await requestService.get(
        `/tickets/api/v1/service-company?deviceId=${deviceId}`,
      )

      return response.serviceCompanies
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCompany(id: string): Promise<CreateCompanyResponse | ResponseError> {
    try {
      const company = await requestService.get(`/companies/api/v1/company/${id}`)
      const address = await requestService.get(
        `/companies/api/v1/address/${BusinessEntityKey.COMPANY}/${id}`,
      )

      const workingHours = await requestService.get(
        `/companies/api/v1/working-hour/${BusinessEntityKey.COMPANY}/${id}`,
      )

      const comp: CreateCompanyResponse = {
        companyGeneralInfo: company.companyGeneralInfo,
        roles: company.roles,
        workingHours: workingHours,
        address: address,
      }

      return comp // company
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async importCompanies(
    data: ImportCompanyRequestData[],
  ): Promise<any | ResponseError> {
    try {
      const importCompaniesResponse = await requestService.post(
        '/companies/api/v1/company/import',
        data,
      )

      return importCompaniesResponse.importResult
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async createCompany(data: any): Promise<CreateCompanyResponse | ResponseError> {
    const { isEditForm, address, workingHours, ...restData } = data

    const companyData: CompanyRequest = {
      companyGeneralInfo: {
        companyName: restData.companyGeneralInfo.companyName,
        companyShortName: restData.companyGeneralInfo.companyShortName,
        companyTypeId: Number(restData.companyGeneralInfo.companyTypeId),
        countryId: Number(restData.companyGeneralInfo.countryId),
        email: restData.companyGeneralInfo.email,
        homePageUrl: restData.companyGeneralInfo.homePageUrl,
        notes: restData.companyGeneralInfo.notes,
        parentCompanyId: restData.companyGeneralInfo.parentCompanyId
          ? Number(restData.companyGeneralInfo.parentCompanyId)
          : null,
        phoneNumber: restData.companyGeneralInfo.phoneNumber,
        registrationNumber: restData.companyGeneralInfo.registrationNumber,
        timeZoneCode: !isEmpty(restData.companyGeneralInfo.timeZoneCode)
          ? restData.companyGeneralInfo.timeZoneCode
          : null,
        vatCode: restData.companyGeneralInfo.vatCode,
      },
      roles: restData.roles,
      userIds: restData.userIds,
    }

    let response: any = { errors: '' }
    let createCompanyResponse: any = ''

    createCompanyResponse = await requestService.post('/companies/api/v1/company', companyData)
    response = { ...response, ...createCompanyResponse }

    const companyAddress: Address = {
      address: address.address,
      cityId: address.cityId,
      cityRegionId: address.cityRegionId,
      countryId: address.countryId,
      flatNumber: address.flatNumber,
      houseNumber: address.houseNumber,
      location: address.location,
      regionId: address.regionId,
      stateId: address.stateId,
      streetId: address.streetId,
    }
    try {
      const createCompanyAddressResponse = await requestService.post(
        `/companies/api/v1/address/${BusinessEntityKey.COMPANY}/${createCompanyResponse.companyGeneralInfo.companyId}`,
        companyAddress,
      )
      response = { ...response, address: createCompanyAddressResponse }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    const workingHoursRequest: WorkingHourRequest[] = []
    workingHours.forEach((wh: WorkingHour) => {
      workingHoursRequest.push(
        ...wh.timePeriods.map((whp: TimePeriod) => ({
          dayOfWeek: wh.dayOfWeek,
          fromTime: whp.fromTime,
          toTime: whp.toTime,
          workingHourId: whp.workingHourId,
        })),
      )
    })

    try {
      const createCompanyWorkingHoursResponse = await requestService.put(
        `/companies/api/v1/working-hour/${BusinessEntityKey.COMPANY}/${createCompanyResponse.companyGeneralInfo.companyId}`,
        workingHoursRequest,
      )
      response = { ...response, workingHours: createCompanyWorkingHoursResponse }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    return response
  }

  public static async updateCompany(data: any): Promise<{ [key: string]: string }> {
    const { itemId, isEditForm, address, workingHours, initialWorkingHours, ...restData } = data

    const companyData: CompanyRequest = {
      companyGeneralInfo: {
        companyName: restData.companyGeneralInfo.companyName,
        companyShortName: restData.companyGeneralInfo.companyShortName,
        companyTypeId: Number(restData.companyGeneralInfo.companyTypeId),
        countryId: Number(restData.companyGeneralInfo.countryId),
        email: restData.companyGeneralInfo.email,
        homePageUrl: restData.companyGeneralInfo.homePageUrl,
        notes: restData.companyGeneralInfo.notes,
        parentCompanyId: restData.companyGeneralInfo.parentCompanyId
          ? Number(restData.companyGeneralInfo.parentCompanyId)
          : null,
        phoneNumber: restData.companyGeneralInfo.phoneNumber,
        registrationNumber: restData.companyGeneralInfo.registrationNumber,
        timeZoneCode: !isEmpty(restData.companyGeneralInfo.timeZoneCode)
          ? restData.companyGeneralInfo.timeZoneCode
          : null,
        vatCode: restData.companyGeneralInfo.vatCode,
      },
      roles: restData.roles,
      userIds: restData.userIds,
    }

    let response: any = { errors: '' }

    try {
      const updateCompanyResponse = await requestService.put(
        `/companies/api/v1/company/${itemId}`,
        companyData,
      )
      response = { ...response, ...updateCompanyResponse }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    const companyAddress: Address = {
      address: address.address,
      cityId: address.cityId,
      cityRegionId: address.cityRegionId,
      countryId: address.countryId,
      flatNumber: address.flatNumber,
      houseNumber: address.houseNumber,
      location: address.location,
      regionId: address.regionId,
      stateId: address.stateId,
      streetId: address.streetId,
    }

    try {
      const updateCompanyAddressResponse = await requestService.put(
        `/companies/api/v1/address/${BusinessEntityKey.COMPANY}/${itemId}`,
        companyAddress,
      )
      response = { ...response, address: updateCompanyAddressResponse }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    const workingHoursRequest: WorkingHourRequest[] = []
    workingHours.forEach((wh: WorkingHour) => {
      workingHoursRequest.push(
        ...wh.timePeriods.map((whp: TimePeriod) => ({
          dayOfWeek: wh.dayOfWeek,
          fromTime: whp.fromTime,
          toTime: whp.toTime,
          workingHourId: whp.workingHourId,
        })),
      )
    })

    try {
      const updateCompanyWorkingHoursResponse = await requestService.put(
        `/companies/api/v1/working-hour/${BusinessEntityKey.COMPANY}/${itemId}`,
        workingHoursRequest,
      )
      response = { ...response, workingHours: updateCompanyWorkingHoursResponse }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    return response
  }

  public static async deleteCompany(companyID: any): Promise<{ [key: string]: string }> {
    try {
      const companyResponse = await requestService.delete(`/companies/api/v1/company/${companyID}`)
      return companyResponse
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getDepartment(id: string): Promise<CreateDepartmentResponse | ResponseError> {
    try {
      const department = await requestService.get(`/companies/api/v1/company/department/${id}`)
      const address = await requestService.get(
        `/companies/api/v1/address/${BusinessEntityKey.DEPARTMENT}/${id}`,
      )

      const workingHours = await requestService.get(
        `/companies/api/v1/working-hour/${BusinessEntityKey.DEPARTMENT}/${id}`,
      )

      const d: CreateDepartmentResponse = {
        departmentGeneralInfo: department.departmentGeneralInfo,
        roles: department.roles,
        workingHours: workingHours,
        address: address,
      }

      return d // department
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async createDepartment(
    data: any, // data: CreateDepartmentRequestBody,
  ): Promise<CreateDepartmentResponse | ResponseError> {
    const { isEditForm, address, addressTypeSelection, workingHours, ...restData } = data

    const departmentData: DepartmentRequest = {
      departmentGeneralInfo: {
        companyId: restData.departmentGeneralInfo.companyId,
        departmentCode: restData.departmentGeneralInfo.departmentCode,
        departmentName: restData.departmentGeneralInfo.departmentName,
        parentDepartmentId: restData.departmentGeneralInfo.parentDepartmentId,
        timeZoneCode: !isEmpty(restData.departmentGeneralInfo.timeZoneCode)
          ? restData.departmentGeneralInfo.timeZoneCode
          : null,
      },
      roles: restData.roles,
    }

    let response: any = { errors: '' }
    let createDepartmentResponse: any = ''

    createDepartmentResponse = await requestService.post(
      '/companies/api/v1/company/department',
      departmentData,
    )
    response = { ...response, ...createDepartmentResponse }

    const departmentAddress: Address = {
      address: address.address,
      cityId: address.cityId,
      cityRegionId: address.cityRegionId,
      countryId: address.countryId,
      flatNumber: address.flatNumber,
      houseNumber: address.houseNumber,
      location: address.location,
      regionId: address.regionId,
      stateId: address.stateId,
      streetId: address.streetId,
    }
    try {
      const createDepartmentAddressResponse = await requestService.post(
        `/companies/api/v1/address/${BusinessEntityKey.DEPARTMENT}/${createDepartmentResponse.departmentGeneralInfo.departmentId}`,
        departmentAddress,
      )
      response = { ...response, address: createDepartmentAddressResponse }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    const workingHoursRequest: WorkingHourRequest[] = []
    workingHours.forEach((wh: WorkingHour) => {
      workingHoursRequest.push(
        ...wh.timePeriods.map((whp: TimePeriod) => ({
          dayOfWeek: wh.dayOfWeek,
          fromTime: whp.fromTime,
          toTime: whp.toTime,
          workingHourId: whp.workingHourId,
        })),
      )
    })

    try {
      const createDepartmentWorkingHoursResponse = await requestService.put(
        `/companies/api/v1/working-hour/${BusinessEntityKey.DEPARTMENT}/${createDepartmentResponse.departmentGeneralInfo.departmentId}`,
        workingHoursRequest,
      )
      response = { ...response, workingHours: createDepartmentWorkingHoursResponse }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    return response
  }

  public static async updateDepartment(data: any): Promise<{ [key: string]: string }> {
    const { itemId, isEditForm, address, workingHours, ...restData } = data

    const departmentData: DepartmentRequest = {
      departmentGeneralInfo: {
        companyId: restData.departmentGeneralInfo.companyId,
        departmentCode: restData.departmentGeneralInfo.departmentCode,
        departmentName: restData.departmentGeneralInfo.departmentName,
        parentDepartmentId: restData.departmentGeneralInfo.parentDepartmentId,
        timeZoneCode: !isEmpty(restData.departmentGeneralInfo.timeZoneCode)
          ? restData.departmentGeneralInfo.timeZoneCode
          : null,
      },
      roles: restData.roles,
    }

    let response: any = { errors: '' }

    try {
      const updateDepartmentResponse = await requestService.put(
        `/companies/api/v1/company/department/${itemId}`,
        departmentData,
      )
      response = { ...response, ...updateDepartmentResponse }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    const departmentAddress: Address = {
      address: address.address,
      cityId: address.cityId,
      cityRegionId: address.cityRegionId,
      countryId: address.countryId,
      flatNumber: address.flatNumber,
      houseNumber: address.houseNumber,
      location: address.location,
      regionId: address.regionId,
      stateId: address.stateId,
      streetId: address.streetId,
    }
    try {
      const updateDepartmentAddressResponse = await requestService.put(
        `/companies/api/v1/address/${BusinessEntityKey.DEPARTMENT}/${itemId}`,
        departmentAddress,
      )
      response = { ...response, address: updateDepartmentAddressResponse }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    const workingHoursRequest: WorkingHourRequest[] = []
    workingHours.forEach((wh: WorkingHour) => {
      workingHoursRequest.push(
        ...wh.timePeriods.map((whp: TimePeriod) => ({
          dayOfWeek: wh.dayOfWeek,
          fromTime: whp.fromTime,
          toTime: whp.toTime,
          workingHourId: whp.workingHourId,
        })),
      )
    })
    try {
      const updateDepartmentWorkingHoursResponse = await requestService.put(
        `/companies/api/v1/working-hour/${BusinessEntityKey.DEPARTMENT}/${itemId}`,
        workingHoursRequest,
      )
      response = { ...response, workingHours: updateDepartmentWorkingHoursResponse }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    return response
  }

  public static async deleteDepartment(departmentID: any): Promise<{ [key: string]: string }> {
    try {
      const departmentResponse = await requestService.delete(
        `/companies/api/v1/company/department/${departmentID}`,
      )
      return departmentResponse
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getPersonnel(id: string): Promise<CreatePersonnelResponse | ResponseError> {
    try {
      const personnel = await requestService.get(`/companies/api/v1/company/personnel/${id}`)
      const address = await requestService.get(
        `/companies/api/v1/address/${BusinessEntityKey.COMPANY_PERSONNEL}/${id}`,
      )
      const workingHours = await requestService.get(
        `/companies/api/v1/working-hour/${BusinessEntityKey.COMPANY_PERSONNEL}/${id}`,
      )

      const dayoffs = await requestService.get(
        `/companies/api/v1/company/company-personnel/day-off?personnelId=${id}`,
      )

      const dutySchedule = await requestService.get(
        `/companies/api/v1/company/personnel/${id}/duty-schedule`,
      )

      const p: CreatePersonnelResponse = {
        personnelGeneralInfo: personnel.personnelGeneralInfo,
        roles: personnel.roles,
        person: personnel.person,
        address: address,
        workingHours: workingHours,
        dayoffs: dayoffs.sort((a: DayOffItem, b: DayOffItem) =>
          a.fromDate.localeCompare(b.fromDate),
        ),
        dutySchedule: dutySchedule,
      }

      return p // personnel
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async createPersonnel(data: any): Promise<CreatePersonnelResponse | ResponseError> {
    const {
      itemId,
      isEditForm,
      address,
      salary,
      salaryInitial,
      qualifications,
      qualificationsInitial,
      personnelRolesHistory,
      workingHours,
      addressTypeSelection,
      addPhoto,
      dayoffs,
      dayoffsInitial,
      dutySchedule,
      dutyScheduleInitial,
      ...restData
    } = data

    const personnelData: PersonnelRequest = {
      person: {
        firstName: restData.person.firstName,
        gender: restData.person.gender,
        lastName: restData.person.lastName,
        middleName: restData.person.middleName ?? '',
        personId: restData.person.personId,
        photo: restData.person.photo,
      },
      personnelGeneralInfo: {
        companyId: restData.personnelGeneralInfo.companyId,
        departmentId: restData.personnelGeneralInfo.departmentId,
        email: restData.personnelGeneralInfo.email,
        employeeId: restData.personnelGeneralInfo.employeeId,
        jobPostId: restData.personnelGeneralInfo.jobPostId,
        phoneNumber: restData.personnelGeneralInfo.phoneNumber,
        timeZoneCode: !isEmpty(restData.personnelGeneralInfo.timeZoneCode)
          ? restData.personnelGeneralInfo.timeZoneCode
          : null,
      },
      roles: restData.roles,
    }

    let response: any = { errors: '' }
    let createPersonnelResponse: any = ''

    createPersonnelResponse = await requestService.post(
      '/companies/api/v1/company/personnel',
      personnelData,
    )
    response = { ...response, ...createPersonnelResponse }

    const personnelAddress: Address = {
      address: address.address,
      cityId: address.cityId,
      cityRegionId: address.cityRegionId,
      countryId: address.countryId,
      flatNumber: address.flatNumber,
      houseNumber: address.houseNumber,
      location: address.location,
      regionId: address.regionId,
      stateId: address.stateId,
      streetId: address.streetId,
    }

    try {
      const createCompanyAddressResponse = await requestService.post(
        `/companies/api/v1/address/${BusinessEntityKey.COMPANY_PERSONNEL}/${createPersonnelResponse.personnelGeneralInfo.personnelId}`,
        personnelAddress,
      )
      response = {
        ...response,
        address: createCompanyAddressResponse,
      }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    try {
      const createPersonnelSalaryResponse = await requestService.put(
        `/companies/api/v1/company/personnel/${createPersonnelResponse.personnelGeneralInfo.personnelId}/salary`,
        salary,
      )
      response = {
        ...response,
        salary: createPersonnelSalaryResponse,
      }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    const workingHoursRequest: WorkingHourRequest[] = []
    workingHours.forEach((wh: WorkingHour) => {
      workingHoursRequest.push(
        ...wh.timePeriods.map((whp: TimePeriod) => ({
          dayOfWeek: wh.dayOfWeek,
          fromTime: whp.fromTime,
          toTime: whp.toTime,
          workingHourId: whp.workingHourId,
        })),
      )
    })

    try {
      const createPersonnelWorkingHoursResponse = await requestService.put(
        `/companies/api/v1/working-hour/${BusinessEntityKey.COMPANY_PERSONNEL}/${createPersonnelResponse.personnelGeneralInfo.personnelId}`,
        workingHoursRequest,
      )

      response = {
        ...response,
        workingHours: createPersonnelWorkingHoursResponse,
      }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    const personnelQualifications: QualificationLevelRequest[] = qualifications.map(
      (item: QualificationLevel) => ({
        companyPersonnelQLevId: item.companyPersonnelQLevId,
        workAreaId: item.workAreaId,
        qualificationLevelId: item.qualificationLevelId,
      }),
    )

    try {
      const createPersonnelQualificationsResponse = await requestService.put(
        `/companies/api/v1/company/personnel/${createPersonnelResponse.personnelGeneralInfo.personnelId}/qualification-level/`,
        personnelQualifications,
      )
      response = {
        ...response,
        qualifications: createPersonnelQualificationsResponse,
      }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    dayoffs.forEach(async (dayoff: DayOffItem) => {
      try {
        dayoff.companyPersonnelId = createPersonnelResponse.personnelGeneralInfo.personnelId
        delete dayoff.dayOffTypeName

        await requestService.post(`/companies/api/v1/company/company-personnel/day-off/`, dayoff)
      } catch (e) {
        response.errors += e.errorMessage ?? e.message + '\n'
      }
    })

    try {
      const createPersonnelDutyScheduleResponse = await requestService.post(
        `/companies/api/v1/company/personnel/${createPersonnelResponse.personnelGeneralInfo.personnelId}/duty-schedule`,
        dutySchedule,
      )

      response = {
        ...response,
        dutySchedule: createPersonnelDutyScheduleResponse,
      }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    return response
  }

  public static async updatePersonnel(data: any): Promise<{ [key: string]: string }> {
    const {
      itemId,
      isEditForm,
      address,
      salary,
      salaryInitial,
      qualifications,
      qualificationsInitial,
      personnelRolesHistory,
      workingHours,
      addPhoto,
      dayoffs,
      dayoffsInitial,
      dutySchedule,
      dutyScheduleInitial,
      ...restData
    } = data

    const personnelData: PersonnelRequest = {
      person: {
        firstName: restData.person.firstName,
        gender: restData.person.gender,
        lastName: restData.person.lastName,
        middleName: restData.person.middleName ?? '',
        personId: restData.person.personId,
        photo: restData.person.photo,
      },
      personnelGeneralInfo: {
        companyId: restData.personnelGeneralInfo.companyId,
        departmentId: restData.personnelGeneralInfo.departmentId,
        email: restData.personnelGeneralInfo.email,
        employeeId: restData.personnelGeneralInfo.employeeId,
        jobPostId: restData.personnelGeneralInfo.jobPostId,
        phoneNumber: restData.personnelGeneralInfo.phoneNumber,
        timeZoneCode: !isEmpty(restData.personnelGeneralInfo.timeZoneCode)
          ? restData.personnelGeneralInfo.timeZoneCode
          : null,
      },
      roles: restData.roles,
    }

    let response: any = { errors: '' }

    try {
      const updatePersonnelResponse = await requestService.put(
        `/companies/api/v1/company/personnel/${itemId}`,
        personnelData,
      )
      response = {
        ...response,
        ...updatePersonnelResponse,
      }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    const personnelAddress: Address = {
      address: address.address,
      cityId: address.cityId,
      cityRegionId: address.cityRegionId,
      countryId: address.countryId,
      flatNumber: address.flatNumber,
      houseNumber: address.houseNumber,
      location: address.location,
      regionId: address.regionId,
      stateId: address.stateId,
      streetId: address.streetId,
    }

    try {
      const updatePersonnelAddressResponse = await requestService.put(
        `/companies/api/v1/address/${BusinessEntityKey.COMPANY_PERSONNEL}/${itemId}`,
        personnelAddress,
      )
      response = {
        ...response,
        address: updatePersonnelAddressResponse,
      }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    try {
      const updatePersonnelSalaryResponse = await requestService.put(
        `/companies/api/v1/company/personnel/${itemId}/salary`,
        salary,
      )

      response = {
        ...response,
        salary: updatePersonnelSalaryResponse,
      }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    try {
      const deletedSalaries = getDeletedSalaryIds(salaryInitial, salary)
      await requestService.delete(`/companies/api/v1/company/personnel/${itemId}/salary`, true, {
        data: deletedSalaries,
      })
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    const workingHoursRequest: WorkingHourRequest[] = []
    workingHours.forEach((wh: WorkingHour) => {
      workingHoursRequest.push(
        ...wh.timePeriods.map((whp: TimePeriod) => ({
          dayOfWeek: wh.dayOfWeek,
          fromTime: whp.fromTime,
          toTime: whp.toTime,
          workingHourId: whp.workingHourId,
        })),
      )
    })

    try {
      const updatePersonnelWorkingHoursResponse = await requestService.put(
        `/companies/api/v1/working-hour/${BusinessEntityKey.COMPANY_PERSONNEL}/${itemId}`,
        workingHoursRequest,
      )
      response = {
        ...response,
        workingHours: updatePersonnelWorkingHoursResponse,
      }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    const personnelQualifications: QualificationLevelRequest[] = qualifications.map(
      (item: QualificationLevel) => ({
        companyPersonnelQLevId: item.companyPersonnelQLevId,
        workAreaId: item.workAreaId,
        qualificationLevelId: item.qualificationLevelId,
      }),
    )

    try {
      const updatePersonnelQualificationsResponse = await requestService.put(
        `/companies/api/v1/company/personnel/${itemId}/qualification-level/`,
        personnelQualifications,
      )

      response = {
        ...response,
        qualifications: updatePersonnelQualificationsResponse,
      }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    try {
      const deletedQualifications = getDeletedQualificationIds(
        qualificationsInitial,
        qualifications,
      )

      await requestService.delete(
        `/companies/api/v1/company/personnel/${itemId}/qualification-level/`,
        true,
        { data: deletedQualifications },
      )
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    dayoffs
      .filter((item: DayOffItem) => item.companyPersonnelDayOffId === null)
      .forEach(async (dayoff: DayOffItem) => {
        try {
          dayoff.companyPersonnelId = itemId
          const { dayOffTypeName, ...newDayOff } = dayoff
          const createPersonnelDayoffsResponse = await requestService.post(
            `/companies/api/v1/company/company-personnel/day-off/`,
            newDayOff,
          )

          dayoff.companyPersonnelDayOffId = createPersonnelDayoffsResponse.companyPersonnelDayOffId
        } catch (e) {
          response.errors += e.errorMessage ?? e.message + '\n'
        }
      })

    dayoffs
      .filter((item: DayOffItem) => item.companyPersonnelDayOffId !== null)
      .forEach(async (dayoff: DayOffItem) => {
        try {
          const { dayOffTypeName, ...updateDayOff } = dayoff
          await requestService.put(
            `/companies/api/v1/company/company-personnel/day-off/${dayoff.companyPersonnelDayOffId}`,
            updateDayOff,
          )
        } catch (e) {
          response.errors += e.errorMessage ?? e.message + '\n'
        }
      })

    try {
      const deletedDayoffs = getDeletedDayoffsIds(dayoffsInitial, dayoffs)
      await requestService.delete(`/companies/api/v1/company/company-personnel/day-off/`, true, {
        data: deletedDayoffs,
      })
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    response.dayoffs = dayoffs

    const dutyScheduleRequest: DutyScheduleRequestItem[] = []
    dutySchedule
      .filter((item: DutySchedule) => item.companyPersonnelDutyScheduleId1 === null)
      .forEach((dutyScheduleItem: DutySchedule) => {
        const {
          companyPersonnelDutyScheduleId1,
          companyPersonnelDutyScheduleId2,
          totWorkTimeH,
          totWorkTimeM,
          companyWorkShiftName,
          needToAdd,
          checked,
          ...dutySchedule
        } = dutyScheduleItem
        dutyScheduleRequest.push(dutySchedule)
      })

    try {
      const updateDutyScheduleResponse = await requestService.post(
        `/companies/api/v1/company/personnel/${itemId}/duty-schedule`,
        dutyScheduleRequest,
      )
      response = {
        ...response,
        dutySchedule: updateDutyScheduleResponse,
      }
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    try {
      const deletedDutySchedules = getDeletedDutyScheduleIds(dutyScheduleInitial, dutySchedule)
      await requestService.delete(
        `/companies/api/v1/company/personnel/${itemId}/duty-schedule`,
        true,
        {
          data: deletedDutySchedules,
        },
      )
    } catch (e) {
      response.errors += e.errorMessage ?? e.message + '\n'
    }

    return response
  }

  public static async deletePersonnel(personnelID: any): Promise<{ [key: string]: string }> {
    try {
      const personnelResponse = await requestService.delete(
        `/companies/api/v1/company/personnel/${personnelID}`,
      )
      return personnelResponse
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getPersonnelSalaries(id: string): Promise<SalaryItem[] | ResponseError> {
    try {
      const salaries = await requestService.get(`/companies/api/v1/company/personnel/${id}/salary`)
      return salaries.sort((a: SalaryItem, b: SalaryItem) => a.fromDate.localeCompare(b.fromDate))
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getPersonnelRolesHistory(
    id: string,
  ): Promise<PersonnelRoleHistoryResponse[] | ResponseError> {
    try {
      const rolesHistories = await requestService.get(
        `/companies/api/v1/company/personnel/${id}/role-history`,
      )
      return rolesHistories
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getPersonnelAddress(id: string): Promise<AddressResponse[] | ResponseError> {
    try {
      const address = await requestService.get(
        `/companies/api/v1/address/${BusinessEntityKey.COMPANY_PERSONNEL}/${id}`,
      )
      return address
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getPersonnelQualificationLevels(
    id: string,
  ): Promise<QualificationLevel[] | ResponseError> {
    try {
      const qualificationLevels = await requestService.get(
        `/companies/api/v1/company/personnel/${id}/qualification-level`,
      )
      return qualificationLevels
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCompanyTreeBySearchFragment(
    searchFragment: string,
    isActual: number,
  ): Promise<CompanyTreeElement[] | ResponseError> {
    try {
      const companyTree = await requestService.get(`/companies/api/v1/company-tree/`, {
        searchFragment,
        isActual,
      })
      return companyTree?.treeElements.sort((a: CompanyTreeElement, b: CompanyTreeElement) =>
        a.itemDisplayName.localeCompare(b.itemDisplayName),
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCompanyTreeByCompanyId(
    companyId: number,
    isActual: number,
    searchFragment?: string,
  ): Promise<CompanyTreeElement[] | ResponseError> {
    try {
      const companyTree = await requestService.get(`/companies/api/v1/company-tree/company`, {
        companyId,
        isActual,
        searchFragment,
      })
      return companyTree?.treeElements.sort((a: CompanyTreeElement, b: CompanyTreeElement) =>
        a.itemDisplayName.localeCompare(b.itemDisplayName),
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupConfigForCreateCompany(): Promise<
    LookupFieldConfig[] | ResponseError
  > {
    try {
      return await requestService.get(`/companies/api/v1/company/lookup-config/create-form/`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupConfigForEditCompany(): Promise<
    FieldLookupConfigInfo[] | ResponseError
  > {
    try {
      const editFormConfig = await requestService.get(
        `/companies/api/v1/company/lookup-config/edit-form/`,
      )
      return editFormConfig
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupConfigForCreateDepartment(): Promise<
    LookupFieldConfig[] | ResponseError
  > {
    try {
      return await requestService.get(`/companies/api/v1/department/lookup-config/create-form/`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupConfigForEditDepartment(): Promise<
    FieldLookupConfigInfo[] | ResponseError
  > {
    try {
      const editFormConfig = await requestService.get(
        `/companies/api/v1/department/lookup-config/edit-form/`,
      )
      return editFormConfig
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getInitialSystemRoles(
    businessEntityKey: BusinessEntityKey,
  ): Promise<Role[] | ResponseError> {
    try {
      const roles = await requestService.get(`/companies/api/v1/roles/${businessEntityKey}`)
      return roles
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getWorkAreasLookup(): Promise<LookupResult[] | ResponseError> {
    try {
      const workAreas = await requestService.get(
        `/api/v1/lookups/lookups?lookupKey=WORK_AREAS_DICT&module=COM`,
      )
      return (
        workAreas?.values.map(
          (i: any): LookupResult => ({
            id: Number(i.id),
            name: i.name,
          }),
        ) || []
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getQualificationLevelsLookup(): Promise<LookupResult[] | ResponseError> {
    try {
      const qualificationLevels = await requestService.get(
        `/api/v1/lookups/lookups?lookupKey=QUALIFICATION_LEVELS_DICT&module=COM`,
      )
      return (
        qualificationLevels?.values.map(
          (i: any): LookupResult => ({
            id: Number(i.id),
            name: i.name,
          }),
        ) || []
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCompanyAddress(id: number): Promise<AddressResponse | ResponseError> {
    try {
      const address = await requestService.get(
        `/companies/api/v1/address/${BusinessEntityKey.COMPANY}/${id}`,
      )
      return address
    } catch (e) {
      throw new Error(e)
    }
  }
}
