import { Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Form, Formik, FormikProps, FormikValues } from 'formik'
import { isEmpty, isEqual } from 'lodash-es'
import moment from 'moment'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Test from 'react-test-attributes'

import { TicketsApi } from '@/api/sd/ticketsApi/index'
import { GetTicketsBodyFilterItem } from '@/api/sd/ticketsApi/types'
import Edit from '@/assets/sd/edit.svg'
import Save from '@/assets/sd/save.svg'
import CloseButton from '@/components/blocks/CloseButton/index'
import Loader from '@/components/blocks/Loader'
import DynamicField from '@/components/controls/DynamicField/component'
import { getInitialFormValues } from '@/components/controls/DynamicField/initialValues'
import { Select } from '@/components/controls/Select/component'
import { detectTicketTabLocation } from '@/components/pages/sd/helpers/sdHelpers'
import { gettingTickets } from '@/helpers/tickets'
import { getHistoryFiltersModalState, setHistoryTicketsFiltersModalState } from '@/store/sd/modals'
import { updateSdFilterTemplateRequest } from '@/store/sd/tickets/actions'
import {
  changeSelectedFilters,
  collectFilterTemplateFields,
  deleteSdFilterTemplateRequest,
  getCurrentFilterTemplate,
  getFiltersTemplates,
  getFilterTemplateFields,
  getSdFilterTemplatesRequest,
  getTicketsRequest,
  isFetchingFilterTemplates,
  setCurrentFilterTemplate,
  setTicketsPage,
} from '@/store/sd/tickets/index'
import {
  getIsFetchingTicketsConfig,
  getSelectedFilters,
  getTicketsConfigData,
} from '@/store/sd/tickets/selectors'
import { TicketsConfigData, TypeOfTicket, WindowType } from '@/types'
import { FilterParameter } from '@/types/common/filterConfig'
import { usePreviousValue } from '@/utils/hooks/hooks'
import { getTestId } from '@/utils/index'
import { useTranslate } from '@/utils/internalization'
import { PopUpService } from '@/utils/services/popUpService'

import EditFilterModal from './components/EditFilterModal/index'
import SaveFilterModal from './components/SaveFilterModal/index'
import { useClasses } from './styles'
import { Option, Props } from './types'

const getOptions = (filters: GetTicketsBodyFilterItem[]) => {
  const newOptions = filters.filter(
    (filter: any) => filter.parameterName === 'merchant' || filter.parameterName === 'devcity',
  )
  return newOptions.map((option: any) => ({
    parameterName: option.parameterName,
    parameterValue: [
      {
        value: option.parameterValue[0].id.toString(),
        name: option.parameterValue[0].name,
      },
    ],
  }))
}

const getValueFromRemoteFilterItem = (
  f: GetTicketsBodyFilterItem,
  config: FilterParameter[],
): string | null | string[] | undefined => {
  if (f.rangeValues && f.rangeValues.from && f.rangeValues.to) {
    const from: string = moment(new Date(f.rangeValues.from * 1000)).toISOString()
    const to: string = moment(new Date(f.rangeValues.to * 1000)).toISOString()
    return [from, to]
  }
  if (Array.isArray(f.parameterValue) && f.valueType === 'INTEGER') {
    // displayType === LIST
    const fConfigLook = config.find(
      (element: FilterParameter) => element.parameterName === f.parameterName,
    )
    const parsedResult = f.parameterValue.map(
      (value: { id: number | null; value: string | null }) => {
        return value && value.id ? value.id.toString() : ''
      },
    )

    if (fConfigLook?.displayType === 'LIST') {
      return fConfigLook.labelKey === 'lblk_devicetype' || fConfigLook.labelKey === 'lblk_docstatus'
        ? parsedResult
        : parsedResult[0]
    }
    return parsedResult[0] // this line return value for 'TEXT'
  }
  if (Array.isArray(f.parameterValue) && f.valueType === 'STRING') {
    return f.parameterValue.map((value: { id: number | null; value: string | null }) => {
      return value && value.value ? value.value : ''
    })[0]
  }
}

const FiltersModal = ({
  open,
  handleClose,
  testIdPrefix,
  typeOfTickets,
}: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const location = useLocation()
  const classes = useClasses()
  const { filter: filtersFields }: TicketsConfigData = useSelector(getTicketsConfigData)
  const historyWasOpened = useSelector(getHistoryFiltersModalState)
  const selectedFilters = useSelector(getSelectedFilters)
  const templates = useSelector(getFiltersTemplates)
  const isLoadingTemplates = useSelector(isFetchingFilterTemplates)
  const isFetchingTicketsConfig = useSelector(getIsFetchingTicketsConfig)
  const filterFields = useSelector(getFilterTemplateFields)
  const currentTemplate = useSelector(getCurrentFilterTemplate)
  const [emptyFormValues, setEmptyFormValues] = useState({})
  const [showModal, setShowModal] = useState<'main' | 'save' | 'edit'>('main')
  const [filterFormValues, setFilterFormValues] = useState({})
  const prevFilterFormValues = usePreviousValue(filterFormValues)
  const prevOpen: any = usePreviousValue(open)
  const [initialFormValues, setInitialFormValues] = useState({})
  const [options, setOptions] = useState<Option[]>([])
  const [savedTemplate, setSavedTemplate] = useState<string>('')

  const formRef = useRef<FormikProps<FormikValues> | null>(null)

  const idPrefix = `${testIdPrefix}-filters-modal`
  const testId = getTestId(idPrefix)

  useEffect(() => {
    if (prevOpen && !open && !Object.keys(selectedFilters).length) {
      dispatch(setCurrentFilterTemplate(''))

      if (formRef.current) {
        if (typeOfTickets === TypeOfTicket.HistoryTickets) {
          formRef.current.setValues(
            {
              ...emptyFormValues,
              receivedate: [
                moment()
                  .subtract(1, 'months')
                  .format(),
                null,
              ],
            },
            false,
          )
        } else {
          formRef.current.setValues(emptyFormValues, false)
        }
      }
    }
  }, [prevOpen, open, selectedFilters, dispatch])

  useEffect(() => {
    setEmptyFormValues(getInitialFormValues(filtersFields))
  }, [filtersFields])

  useEffect(() => {
    if (Object.keys(selectedFilters).length) {
      return setInitialFormValues(selectedFilters)
    }

    setInitialFormValues(getInitialFormValues(filtersFields))
  }, [selectedFilters, filtersFields])

  const nullifyForm = useCallback(() => setInitialFormValues(getInitialFormValues(filtersFields)), [
    filtersFields,
  ])

  useEffect((): void => {
    dispatch(
      getSdFilterTemplatesRequest({ windowType: detectTicketTabLocation(location), translate }),
    )
  }, [typeOfTickets])

  const getFiltersTemplatesById = useCallback(
    (id: string, windowType: WindowType): Promise<any> =>
      TicketsApi.getSdFilterTemplateById(id, windowType),
    [templates],
  )

  const handleCurrentTemplateChange = useCallback(
    (setValues: (values: {}, shouldValidate?: boolean | undefined) => void) => (
      e: ChangeEvent<{ value: any }> | string,
    ): void => {
      if (typeof e === 'object' && !e.target.value) {
        dispatch(setCurrentFilterTemplate(''))
        return setValues(emptyFormValues, false)
      }

      getFiltersTemplatesById(
        typeof e === 'object' ? e.target.value : e,
        detectTicketTabLocation(location),
      ).then(({ filters }) => {
        const result = { ...getInitialFormValues(filtersFields) }

        setOptions(getOptions(filters))

        filters.forEach((f: GetTicketsBodyFilterItem) => {
          result[f.parameterName] = getValueFromRemoteFilterItem(f, filtersFields)
        })

        setValues(result)
      })

      dispatch(setCurrentFilterTemplate(typeof e === 'object' ? e.target.value : e))
    },
    [templates, emptyFormValues, dispatch],
  )

  useEffect(() => {
    if (savedTemplate) {
      const template = templates.find(({ name }: { name: string }) => name === savedTemplate)

      if (formRef.current && template) {
        handleCurrentTemplateChange(formRef.current.setValues)(template.id)
      }
    }
  }, [savedTemplate, handleCurrentTemplateChange])

  const handleGoToModal = useCallback(
    (screen: 'main' | 'save' | 'edit', values?: FormikValues): (() => void) => (): void => {
      setFilterFormValues(values || {})

      setShowModal(screen)
    },
    [],
  )

  const handleSubmit = useCallback(
    (values: FormikValues): void => {
      if (typeOfTickets === TypeOfTicket.HistoryTickets && !values.receivedate[0]) {
        PopUpService.showGlobalPopUp(translate('translate#title.receiveDateRequired'), 'error')
      } else {
        dispatch(setTicketsPage(1))

        const isEmpty = !Object.values(values).some(value => {
          if (Array.isArray(value)) {
            return value.some(item => item)
          }

          return value
        })

        if (isEmpty) {
          dispatch(changeSelectedFilters({}))
        } else {
          dispatch(changeSelectedFilters(values))
        }
        dispatch(getTicketsRequest())
        dispatch(setHistoryTicketsFiltersModalState())
        handleClose()
      }
    },
    [dispatch],
  )

  const handleClear = useCallback(
    (setValues: (values: {}, shouldValidate?: boolean | undefined) => void) => (): void => {
      setValues(emptyFormValues, false)
      dispatch(setCurrentFilterTemplate(''))
      setOptions([])
    },
    [emptyFormValues, dispatch],
  )

  const handleEditTemplate = useCallback(
    (name: string) => (): void => {
      if (
        templates.find((temp: any) => {
          if (temp.name === name) {
            return temp.id === currentTemplate ? undefined : temp
          }
        })
      ) {
        PopUpService.showGlobalPopUp(translate('translate#title.sameTemplateName'), 'warning')
        return
      }

      let filtersBody = gettingTickets.getFilters(filterFormValues, filtersFields)

      filtersBody = filtersBody.map((f: GetTicketsBodyFilterItem) => {
        if (filterFields[f.parameterName] && f.parameterValue) {
          f.parameterValue[0].name = filterFields[f.parameterName].displayName
          return f
        }
        return f
      })

      dispatch(
        updateSdFilterTemplateRequest({
          filters: filtersBody,
          name,
          windowType: detectTicketTabLocation(location),
          id: currentTemplate,
          translate,
        }),
      )

      setSavedTemplate(name)
      setShowModal('main')
    },
    [dispatch, filterFormValues, currentTemplate, filtersFields],
  )

  const handleDeleteTemplate = useCallback((): void => {
    dispatch(
      deleteSdFilterTemplateRequest({
        id: currentTemplate,
        windowType: detectTicketTabLocation(location),
        translate,
      }),
    )

    dispatch(setCurrentFilterTemplate(''))
    setFilterFormValues({})
    setShowModal('main')
    nullifyForm()
  }, [currentTemplate, dispatch])

  const initialValues: any = useMemo(() => {
    const filters = filterFormValues ? Object.keys(filterFormValues) : []
    const prevFilters = (prevFilterFormValues as any)
      ? Object.keys(prevFilterFormValues as any)
      : []

    if (!filters.length && prevFilters.length) {
      return prevFilterFormValues
    }

    if (
      typeOfTickets === TypeOfTicket.HistoryTickets &&
      !Object.values(selectedFilters).some(filter => !!filter)
    ) {
      return {
        ...initialFormValues,
        receivedate: [
          moment()
            .subtract(1, 'months')
            .format(),
          null,
        ],
      }
    }

    return initialFormValues
  }, [initialFormValues, selectedFilters, typeOfTickets, filterFormValues, prevFilterFormValues])

  const validateFormValues = useCallback(
    (values: FormikValues) => {
      if (typeOfTickets === TypeOfTicket.HistoryTickets && historyWasOpened) {
        return isEqual(initialFormValues, values)
      }
    },
    [initialFormValues, typeOfTickets, historyWasOpened],
  )

  return (
    <>
      <Test id={testId(0)}>
        <Dialog
          maxWidth={showModal === 'main' ? 'md' : 'xs'}
          fullWidth
          open={open}
          keepMounted
          onClose={handleClose}
          disableBackdropClick
        >
          <Test id={testId(1)}>
            <div className={classes.wrapper}>
              <Test id={testId(2)}>
                <CloseButton absolute className={classes.closeButton} onClick={handleClose} />
              </Test>
              {showModal === 'main' && (
                <>
                  <Grid
                    className={classes.buttonWrapper}
                    container
                    spacing={2}
                    justifyContent="flex-end"
                  >
                    <Grid container>
                      <Grid item md={6}>
                        <Typography className={classes.header} variant="h5">
                          <FormattedMessage id="title.filters" defaultMessage="Filters" />
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item md={12}>
                        <Typography className={classes.subheader} variant="body1">
                          <FormattedMessage
                            id="title.pleaseEnterRequiredParameters"
                            defaultMessage="Enter the required parameters or choose a saved filter."
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                    {isFetchingTicketsConfig || isEmpty(initialFormValues) ? (
                      <Loader />
                    ) : (
                      <Formik
                        validateOnMount
                        onSubmit={handleSubmit}
                        enableReinitialize
                        initialValues={initialValues}
                        innerRef={formRef}
                      >
                        {({ values, setFieldValue, setValues }): React.ReactElement => {
                          return (
                            <>
                              <Form className={classes.form}>
                                <Grid container>
                                  <Grid item className={classes.fieldsWrapper}>
                                    {filtersFields.map(field => (
                                      <Grid
                                        item
                                        md={5}
                                        key={field.parameterName}
                                        className={classes.field}
                                      >
                                        <DynamicField
                                          params={field}
                                          formikValues={values}
                                          options={options}
                                          setFieldValue={setFieldValue}
                                          filterModalFieldWatcher={(option: {
                                            fieldName: string
                                            value: { id: string; displayName: string }
                                          }): void => {
                                            dispatch(collectFilterTemplateFields(option))
                                          }}
                                        />
                                      </Grid>
                                    ))}
                                  </Grid>
                                  <Grid container alignItems="flex-end">
                                    <Grid item md={6}>
                                      <Typography variant="h6" className={classes.savedFilters}>
                                        <FormattedMessage
                                          id="title.savedFilters"
                                          defaultMessage="Saved Filters"
                                        />
                                      </Typography>
                                      <Test id={testId(11)}>
                                        <Select
                                          label={translate('translate#title.filter')}
                                          placeholder={translate('translate#title.chooseFilter')}
                                          value={currentTemplate}
                                          isLoading={isLoadingTemplates}
                                          onChange={handleCurrentTemplateChange(setValues)}
                                          options={templates.map((template: any) => ({
                                            name: template.name,
                                            value: template.id,
                                          }))}
                                          withAutofillForSingleOption={false}
                                          neverDisable
                                          className={classes.templateSelect}
                                        />
                                      </Test>
                                    </Grid>
                                    <Grid item md={6}>
                                      <Grid container justifyContent="flex-end">
                                        <Grid item className={classes.actionButton}>
                                          <Test id={testId(12)}>
                                            <Button
                                              className={classes.iconButton}
                                              onClick={handleGoToModal('edit', values)}
                                              variant="outlined"
                                              disabled={
                                                !currentTemplate || isEqual(values, emptyFormValues)
                                              }
                                            >
                                              <img src={Edit} />
                                            </Button>
                                          </Test>
                                        </Grid>
                                        <Grid item className={classes.actionButton}>
                                          <Test id={testId(13)}>
                                            <Button
                                              className={classes.iconButton}
                                              onClick={handleGoToModal('save', values)}
                                              variant="outlined"
                                              disabled={
                                                isEqual(values, emptyFormValues) ||
                                                !!currentTemplate
                                              }
                                            >
                                              <img src={Save} />
                                            </Button>
                                          </Test>
                                        </Grid>
                                        <Grid item className={classes.actionButton}>
                                          <Test id={testId(14)}>
                                            <Button
                                              className={classes.button}
                                              variant="outlined"
                                              disabled={isEqual(emptyFormValues, values)}
                                              onClick={handleClear(setValues)}
                                            >
                                              <FormattedMessage
                                                id="title.clearFilters"
                                                defaultMessage="Clear filters"
                                              />
                                            </Button>
                                          </Test>
                                        </Grid>
                                        <Grid item className={classes.actionButton}>
                                          <Test id={testId(15)}>
                                            <Button
                                              className={classes.button}
                                              variant="contained"
                                              type="submit"
                                              disabled={validateFormValues(values)}
                                            >
                                              <FormattedMessage
                                                id="title.search"
                                                defaultMessage="Search"
                                              />
                                            </Button>
                                          </Test>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Form>
                            </>
                          )
                        }}
                      </Formik>
                    )}
                  </Grid>
                </>
              )}
              {showModal === 'save' && (
                <SaveFilterModal
                  classes={classes}
                  currentFilters={filterFormValues}
                  testIdPrefix={idPrefix}
                  handleClose={handleGoToModal('main', filterFormValues)}
                  setSavedTemplate={setSavedTemplate}
                />
              )}
              {showModal === 'edit' && (
                <EditFilterModal
                  classes={classes}
                  templateName={
                    (
                      templates.find((template: any) => template.id === currentTemplate) || {
                        name: '',
                      }
                    ).name
                  }
                  testIdPrefix={idPrefix}
                  onEditTemplate={handleEditTemplate}
                  onDeleteTemplate={handleDeleteTemplate}
                  handleClose={handleGoToModal('main')}
                />
              )}
            </div>
          </Test>
        </Dialog>
      </Test>
    </>
  )
}

export default FiltersModal
