import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import DotIcon from '@material-ui/icons/FiberManualRecord'
import clsx from 'clsx'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'

import { Device } from '@/api/atmeye/devicesApi/types'
import { TeltonikaApi } from '@/api/atmeye/teltonikaApi'
import { TeltonikaDeviceInfo } from '@/api/atmeye/teltonikaApi/types'
import Refresh from '@/assets/sd/refresh.svg'
import Loader from '@/components/blocks/Loader'
import Button from '@/components/controls/Button'
import { DEFAULT_DATE_TIME_SEC_FORMAT } from '@/constants/time'
import { useTranslate } from '@/utils/internalization'
import { PopUpService } from '@/utils/services/popUpService'

import { ContentWrapper, useClasses } from './styles'

type Props = {
  currentDevice: Device | null
}

const InfoRow = ({
  label,
  value = '-',
}: {
  label: string
  value?: string | number | React.ReactNode
}): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  return (
    <TableRow>
      <TableCell variant="head" className={clsx(classes.tableCell, classes.tableCellTitle)}>
        {translate(`translate#atmeye.device.teltonika.position.${label}`)}
      </TableCell>

      <TableCell className={classes.tableCell}>
        <div className={clsx(classes.cellWrapper)}>{value}</div>
      </TableCell>
    </TableRow>
  )
}

export const StatusCell = ({ isOk, message = '-' }: { isOk: boolean; message?: string }) => {
  const classes = useClasses()

  return (
    <div className={clsx(classes.cellWrapper, isOk ? classes.folderOk : classes.folderWarning)}>
      <DotIcon
        className={clsx(
          classes.cellIcon,
          classes.iconSize,
          isOk ? classes.dotOk : classes.dotWarning,
        )}
      />

      {message}
    </div>
  )
}

const StatusRow = ({
  title,
  isOk,
  message,
}: {
  title: string
  isOk: boolean
  message?: string
}) => {
  const classes = useClasses()

  return (
    <TableRow>
      <TableCell variant="head" className={clsx(classes.tableCell, classes.tableCellTitle)}>
        {title}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <StatusCell isOk={isOk} message={message} />
      </TableCell>
    </TableRow>
  )
}

const TeltonikaTab: FC<Props> = ({ currentDevice }: Props): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  const [isLoading, setIsLoading] = useState(true)
  const [info, setInfo] = useState<Partial<TeltonikaDeviceInfo>>({})

  const getInfo = async () => {
    try {
      setIsLoading(true)
      if (currentDevice) {
        const response = await TeltonikaApi.getTeltonikaDeviceInfo(currentDevice?.deviceId)

        setInfo(response)
      }
    } catch (e) {
      setInfo({})
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getInfo()
  }, [currentDevice?.deviceId])

  return (
    <>
      <ContentWrapper>
        {!isLoading ? (
          <div className={classes.deviceHeader}>
            <div className={classes.tableHeader}>
              <Tooltip
                placement="top"
                classes={{ tooltip: classes.toolTip }}
                title={translate('translate#atmeye.table.header.button.refresh')}
              >
                <div>
                  <Button
                    className={classes.tableHeaderButton}
                    onClick={getInfo}
                    startIcon={<img src={Refresh} />}
                  />
                </div>
              </Tooltip>
            </div>

            <TableContainer className={classes.tableWrapper} component={Paper}>
              <div className={classes.tableWrap}>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2} className={classes.titleCell}>
                        <Typography variant="h5" className={classes.tableTitle}>
                          {translate(`translate#atmeye.healthMonitor.tabs.teltonika.deviceInfo`)}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <InfoRow label="imei" value={info.uniqueId} />
                    <StatusRow
                      title={translate(`translate#atmeye.device.status.teltonika`)}
                      isOk={info.status === 'ONLINE'}
                      message={translate(
                        `translate#atmeye.devicesTable.teltonikaStatus.${info.status || 'OFFLINE'}`,
                      )}
                    />

                    <InfoRow
                      label="fixTime"
                      value={
                        info?.position?.fixTime
                          ? moment
                              .utc(info?.position?.fixTime as string)
                              .local()
                              .format(DEFAULT_DATE_TIME_SEC_FORMAT)
                          : '-'
                      }
                    />

                    <StatusRow
                      title={translate(`translate#atmeye.device.teltonika.power`)}
                      isOk={+(info.position?.attributes?.power || 0) !== 0}
                      message={translate(
                        `translate#atmeye.devicesTable.teltonika.power.${
                          info.position?.attributes?.power ? 'pluggedIn' : 'onBattery'
                        }`,
                      )}
                    />

                    <TableRow>
                      <TableCell colSpan={2} className={classes.titleCell}>
                        <Typography variant="h5" className={classes.tableTitle}>
                          {translate(`translate#atmeye.healthMonitor.tabs.teltonika.gpsInfo`)}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <StatusRow
                      title={translate(`translate#atmeye.device.teltonika.position.gpsStatus`)}
                      isOk={+(info.position?.sat || 0) > 0}
                      message={translate(
                        `translate#atmeye.devicesTable.teltonikaStatus.${
                          +(info.position?.sat || 0) > 0 ? 'ONLINE' : 'OFFLINE'
                        }`,
                      )}
                    />

                    <InfoRow
                      label="satFixTime"
                      value={
                        info?.position?.satFixTime
                          ? moment
                              .utc(info?.position?.satFixTime)
                              .local()
                              .format(DEFAULT_DATE_TIME_SEC_FORMAT)
                          : '-'
                      }
                    />
                    <InfoRow
                      label="latitude"
                      value={
                        <div>
                          {info?.position?.latitude ?? '-'}

                          {navigator.clipboard &&
                            !!info?.position?.latitude &&
                            !!info?.position?.longitude && (
                              <Button
                                style={{ marginLeft: '20px' }}
                                variant="outlined"
                                onClick={async () => {
                                  navigator.clipboard.writeText(
                                    `${info?.position?.latitude}, ${info?.position?.longitude}`,
                                  )

                                  const text = await navigator.clipboard.readText()
                                  if (
                                    text ===
                                    `${info?.position?.latitude}, ${info?.position?.longitude}`
                                  ) {
                                    PopUpService.showGlobalPopUp(
                                      `translate#title.copiedPopup`,
                                      'info',
                                    )
                                  }
                                }}
                              >
                                {translate('translate#title.copyLatLonBtn')}
                              </Button>
                            )}
                        </div>
                      }
                    />
                    <InfoRow label="longitude" value={info?.position?.longitude} />
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </div>
        ) : (
          <Loader />
        )}
      </ContentWrapper>
    </>
  )
}

export default TeltonikaTab
