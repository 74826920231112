import { TableCell } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import colors from '@/theme/colors'

export const useClasses = makeStyles(() => ({
  twiceEmptyCell: {
    width: '100%',
    padding: 0,
  },

  lastElementBorder: {
    '&:nth-last-of-type(2)': {
      borderRight: `1px solid ${colors.cmTable.border}`,
    },
  },

  firstEmptyCell: {
    border: 'none',
    borderRight: 'none!important',
  },
}))

export const TwiceHeaderCell = withStyles(() => ({
  stickyHeader: {
    color: colors.cmTable.text,
    backgroundColor: colors.cmTable.lightGray,
    fontWeight: 600,

    padding: '2px 16px',
    top: '32px',
    textAlign: 'center' as const,
  },
}))(TableCell)
