import { makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles(({ spacing }) => ({
  collapseIcon: {
    width: spacing(2),
    padding: '0!important',
    textAlign: 'center',
  },

  disabled: {
    opacity: 0.3,
    cursor: 'default',
  },
}))
