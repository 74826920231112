export enum DelaysField {
  Id = 'id',
  ResponsibilityLevel = 'responsibilityLevel',
  DelayReason = 'delayReason',
  Description = 'description',
  ResponsibilityZone = 'responsibilityZone',
  ResponsiblePerson = 'responsiblePerson',
  Date = 'date',
}

export interface DelaysValues {
  [DelaysField.Id]: string | number
  [DelaysField.ResponsibilityLevel]: string
  [DelaysField.DelayReason]: string
  [DelaysField.Description]: string
  [DelaysField.ResponsibilityZone]: string
  [DelaysField.ResponsiblePerson]: string
  [DelaysField.Date]: string
}
