import Pagination from '@material-ui/lab/Pagination'
import React, { useCallback } from 'react'

import { useClasses } from '../../styles'
import { Props } from './types'

const TablePaginationActions = ({
  totalPage,
  page,
  countPageWithEmptyData,
  onChangePage,
}: Props): React.ReactElement => {
  const classes = useClasses()

  const handlerChangePage = useCallback(
    (event: unknown, page: number) => {
      onChangePage(event, page)
    },
    [onChangePage],
  )

  return (
    <Pagination
      className={classes.pagination}
      page={page}
      count={countPageWithEmptyData || totalPage}
      onChange={handlerChangePage}
    />
  )
}

export default TablePaginationActions
