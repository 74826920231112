import { WorkingHour, Role } from '@/api/companies/companiesApi/types'

export enum AddNewCompanyFormFields {
  CompanyTypeId = 'companyTypeId',
  CompanyTypeName = 'companyTypeName',
  ParentCompanyId = 'parentCompanyId',
  ParentCompanyName = 'parentCompanyName',
  TimeZoneCode = 'timeZoneCode',
  TimeZoneName = 'timeZoneName',
  CompanyName = 'companyName',
  CompanyShortName = 'companyShortName',
  VATCode = 'vatCode',
  RegistrationNumber = 'registrationNumber',
  HomePageUrl = 'homePageUrl',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  Notes = 'notes',
  CountryId = 'countryId',
  CountryName = 'countryName',
  CityId = 'cityId',
  CityName = 'cityName',
  StreetId = 'streetId',
  StreetName = 'streetName',
  StateId = 'stateId',
  StateName = 'stateName',
  RegionId = 'regionId',
  RegionName = 'regionName',
  CityRegionId = 'cityRegionId',
  CityRegionName = 'cityRegionName',
  Address = 'address',
  HouseNumber = 'houseNumber',
  FlatNumber = 'flatNumber',
  Location = 'location',

  Roles = 'roles',
  WorkingHours = 'workingHours',
  // InitialWorkingHours = 'initialWorkingHours',

  ServiceCompanyId = 'serviceCompanyId',
  UnitId = 'unit',

  IsEditForm = 'isEditForm',
}

export interface AddNewCompanyFormValues {
  address: {
    [AddNewCompanyFormFields.CountryId]: number | null
    [AddNewCompanyFormFields.CountryName]: string | null
    [AddNewCompanyFormFields.CityId]: number | null
    [AddNewCompanyFormFields.CityName]: string | null
    [AddNewCompanyFormFields.StreetId]: number | null
    [AddNewCompanyFormFields.StreetName]: string | null
    [AddNewCompanyFormFields.StateId]: number | null
    [AddNewCompanyFormFields.StateName]: string | null
    [AddNewCompanyFormFields.RegionId]: number | null
    [AddNewCompanyFormFields.RegionName]: string | null
    [AddNewCompanyFormFields.CityRegionId]: number | null
    [AddNewCompanyFormFields.CityRegionName]: string | null
    [AddNewCompanyFormFields.Address]: string | null
    [AddNewCompanyFormFields.HouseNumber]: string | null
    [AddNewCompanyFormFields.FlatNumber]: string | null
    [AddNewCompanyFormFields.Location]: string | null
  }
  companyGeneralInfo: {
    [AddNewCompanyFormFields.CompanyTypeId]: number | null
    [AddNewCompanyFormFields.CompanyTypeName]: string | null
    [AddNewCompanyFormFields.TimeZoneCode]: string | null
    [AddNewCompanyFormFields.TimeZoneName]: string | null
    [AddNewCompanyFormFields.ParentCompanyId]: number | null
    [AddNewCompanyFormFields.ParentCompanyName]: string | null
    [AddNewCompanyFormFields.CompanyName]: string | null
    [AddNewCompanyFormFields.CompanyShortName]: string | null
    [AddNewCompanyFormFields.VATCode]: string | null
    [AddNewCompanyFormFields.RegistrationNumber]: string | null
    [AddNewCompanyFormFields.HomePageUrl]: string | null
    [AddNewCompanyFormFields.Email]: string | null
    [AddNewCompanyFormFields.PhoneNumber]: string | null
    [AddNewCompanyFormFields.Notes]: string | null
    [AddNewCompanyFormFields.CountryId]: number | null
  }

  [AddNewCompanyFormFields.Roles]: Role[]
  [AddNewCompanyFormFields.WorkingHours]: WorkingHour[]

  [AddNewCompanyFormFields.IsEditForm]?: boolean
}

export interface InitialFormValues {
  companyGeneralInfo: {
    [AddNewCompanyFormFields.CompanyTypeId]: number | null
    [AddNewCompanyFormFields.CompanyTypeName]: string | null
    [AddNewCompanyFormFields.TimeZoneCode]: string | null
    [AddNewCompanyFormFields.TimeZoneName]: string | null
    [AddNewCompanyFormFields.ParentCompanyId]: number | null
    [AddNewCompanyFormFields.ParentCompanyName]: string | null
    [AddNewCompanyFormFields.CompanyName]: string | null
    [AddNewCompanyFormFields.CompanyShortName]: string | null
    [AddNewCompanyFormFields.VATCode]: string | null
    [AddNewCompanyFormFields.RegistrationNumber]: string | null
    [AddNewCompanyFormFields.HomePageUrl]: string | null
    [AddNewCompanyFormFields.Email]: string | null
    [AddNewCompanyFormFields.PhoneNumber]: string | null
    [AddNewCompanyFormFields.Notes]: string | null
    [AddNewCompanyFormFields.CountryId]: number | null
  }
  address: {
    [AddNewCompanyFormFields.CountryId]: number | null
    [AddNewCompanyFormFields.CountryName]: string | null
    [AddNewCompanyFormFields.CityId]: number | null
    [AddNewCompanyFormFields.CityName]: string | null
    [AddNewCompanyFormFields.StreetId]: number | null
    [AddNewCompanyFormFields.StreetName]: string | null
    [AddNewCompanyFormFields.StateId]: number | null
    [AddNewCompanyFormFields.StateName]: string | null
    [AddNewCompanyFormFields.RegionId]: number | null
    [AddNewCompanyFormFields.RegionName]: string | null
    [AddNewCompanyFormFields.CityRegionId]: number | null
    [AddNewCompanyFormFields.CityRegionName]: string | null
    [AddNewCompanyFormFields.Address]: string | null
    [AddNewCompanyFormFields.HouseNumber]: string | null
    [AddNewCompanyFormFields.FlatNumber]: string | null
    [AddNewCompanyFormFields.Location]: string | null
  }
  [AddNewCompanyFormFields.Roles]: Role[]
  [AddNewCompanyFormFields.WorkingHours]: WorkingHour[]

  [AddNewCompanyFormFields.IsEditForm]?: boolean
}
