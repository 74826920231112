import Dialog from '@material-ui/core/Dialog'
import { FormikProps } from 'formik'
import moment from 'moment'
import React, { ReactElement, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import Loader from '@/components/blocks/Loader'
import { ADM_BLOCK_PREFIX, AppNavigationRoutes } from '@/constants/paths'
import { DATE_TIME_FORMAT } from '@/constants/time'
import { NavigationContext } from '@/context'
import { createNewDeviceRequest } from '@/store/adm/device/actions'
import { getDevice, getError, getIsCreatingNewDevice } from '@/store/adm/device/selectors'
import {
  CreateDeviceFormValues,
  CreateHardwareFormValues,
  CreateRelatedCompaniesFormValues,
  ManualAddressFormValues,
  ScheduleFormValues,
} from '@/types/adm/device/local'

import DeviceAddress from './components/DeviceAddress'
import DeviceInformation from './components/DeviceInformation'
import HardwareInformation from './components/HardwareInformation'
import MultiStepHookForm from './components/MultiStepHookForm'
import { Step } from './components/MultiStepHookForm/types'
import RelatedCompanies from './components/RelatedCompanies'
import ScheduleForm from './components/ScheduleForm'
import { initialValues } from './multistep-options'
import { useClasses } from './styles'
import { Props } from './types'
import {
  validateFinish,
  validateNextAddress,
  validateNextCompanies,
  validateNextDevice,
  validateNextHardware,
} from './validation'

const steps: Step[] = [
  {
    render: (props: FormikProps<CreateDeviceFormValues>): ReactElement => (
      <DeviceInformation {...props} />
    ),
    validateNext: validateNextDevice,
  },
  {
    render: (props: FormikProps<CreateHardwareFormValues>): ReactElement => (
      <HardwareInformation {...props} />
    ),
    validateNext: validateNextHardware,
  },
  {
    render: (props: FormikProps<ManualAddressFormValues>): ReactElement => (
      <DeviceAddress {...props} />
    ),
    validateNext: validateNextAddress,
  },
  {
    render: (props: FormikProps<CreateRelatedCompaniesFormValues>): ReactElement => (
      <RelatedCompanies {...props} />
    ),
    validateNext: validateNextCompanies,
  },
  {
    render: (formik: FormikProps<ScheduleFormValues>): ReactElement => (
      <ScheduleForm formik={formik} fullWidth parentName="schedule" />
    ),
  },
]

const CreateNewDevice = ({ open, onClose }: Props): ReactElement => {
  const isLoading = useSelector(getIsCreatingNewDevice)
  const error = useSelector(getError)
  const device = useSelector(getDevice)

  const { handleAddTab } = useContext(NavigationContext)

  const classes = useClasses()
  const dispatch = useDispatch()
  const history = useHistory()

  const handleSuccess = (): void => {
    onClose()

    if (device) {
      if (handleAddTab) {
        handleAddTab(
          `${ADM_BLOCK_PREFIX}${AppNavigationRoutes.ADMDeviceCard}/${device.deviceId}`,
          `Device: ${device.deviceId}`,
          false,
        )()
      }

      history.push(`${ADM_BLOCK_PREFIX}${AppNavigationRoutes.ADMDeviceCard}/${device.deviceId}`)
    }
  }

  const handleSubmit = useCallback((values: any) => {
    dispatch(
      createNewDeviceRequest({
        device: {
          deviceUID: values.terminalUid,
          deviceStateId: Number(values.deviceStateId),
          deviceTypeId: Number(values.deviceTypeId),
          deviceName: values.deviceName,
          description: values.deviceDescription,
        },
        hardware: {
          hardwareId: values.hardwareId ? Number(values.hardwareId) : null,
          serialNumber: values.serialNumber,
          deviceTypeId: Number(values.hardwareTypeId),
          deviceStateId: Number(values.deviceStateId),
          vendorId: Number(values.vendorId),
          modelId: Number(values.modelId),
          description: values.hardwareDescription,
        },
        address: {
          addressId: null,
          countryId: Number(values.countryId),
          stateId: Number(values.stateId),
          regionId: Number(values.regionId),
          cityId: Number(values.cityId),
          cityRegionId: Number(values.cityRegionId),
          streetId: Number(values.streetId),
          houseNumber: values.houseNumber,
          flatNumber: values.flatNumber,
          location: values.location,
          address: values.address
            ? values.address
            : values.streetId
            ? `${values.streetName} ${values.houseNumber}-${values.flatNumber}`
            : '',
          fromDate: values.fromDate ? moment(values.fromDate).format(DATE_TIME_FORMAT) : '',
        },
        relatedCompanies: {
          createForms: values.companies.map(
            (company: {
              companyRoleId: any
              companyId: any
              luno: any
              agreementNo: any
              saFrom: any
              saTo: any
            }) => ({
              companyRoleId: Number(company.companyRoleId),
              companyId: Number(company.companyId),
              luno: company.luno,
              agreementNo: company.agreementNo,
              saFrom: company.saFrom,
              saTo: company.saTo,
            }),
          ),
        },
        schedule: {
          schedulesCreateFormList: [
            {
              dayOfWeek: 0,
              timeFrom1: values.schedule.monday.timeFrom1,
              timeTo1: values.schedule.monday.timeTo1,
              timeFrom2: values.schedule.monday.timeFrom2,
              timeTo2: values.schedule.monday.timeTo2,
            },
            {
              dayOfWeek: 1,
              timeFrom1: values.schedule.tuesday.timeFrom1,
              timeTo1: values.schedule.tuesday.timeTo1,
              timeFrom2: values.schedule.tuesday.timeFrom2,
              timeTo2: values.schedule.tuesday.timeTo2,
            },
            {
              dayOfWeek: 2,
              timeFrom1: values.schedule.wednesday.timeFrom1,
              timeTo1: values.schedule.wednesday.timeTo1,
              timeFrom2: values.schedule.wednesday.timeFrom2,
              timeTo2: values.schedule.wednesday.timeTo2,
            },
            {
              dayOfWeek: 3,
              timeFrom1: values.schedule.thursday.timeFrom1,
              timeTo1: values.schedule.thursday.timeTo1,
              timeFrom2: values.schedule.thursday.timeFrom2,
              timeTo2: values.schedule.thursday.timeTo2,
            },
            {
              dayOfWeek: 4,
              timeFrom1: values.schedule.friday.timeFrom1,
              timeTo1: values.schedule.friday.timeTo1,
              timeFrom2: values.schedule.friday.timeFrom2,
              timeTo2: values.schedule.friday.timeTo2,
            },
            {
              dayOfWeek: 5,
              timeFrom1: values.schedule.saturday.timeFrom1,
              timeTo1: values.schedule.saturday.timeTo1,
              timeFrom2: values.schedule.saturday.timeFrom2,
              timeTo2: values.schedule.saturday.timeTo2,
            },
            {
              dayOfWeek: 6,
              timeFrom1: values.schedule.sunday.timeFrom1,
              timeTo1: values.schedule.sunday.timeTo1,
              timeFrom2: values.schedule.sunday.timeFrom2,
              timeTo2: values.schedule.sunday.timeTo2,
            },
          ],
        },
      }),
    )
  }, [])

  return (
    <Dialog open={open} classes={{ paper: classes.paper }}>
      <MultiStepHookForm
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        steps={steps}
        onClose={onClose}
        validateFinish={validateFinish}
        response={{
          isLoading,
          error,
          onSuccess: handleSuccess,
        }}
      />
      {isLoading && (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      )}
    </Dialog>
  )
}

export default CreateNewDevice
