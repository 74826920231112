import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { useClasses } from './styles'
import { Props } from './types'

const FilterContextMenuItem = ({ name, checked, color }: Props): React.ReactElement => {
  const classes = useClasses()
  return (
    <>
      <Checkbox style={color ? { color } : {}} checked={checked} className={classes.checkbox} />
      <span className={classes.label}>{name}</span>
    </>
  )
}

export default FilterContextMenuItem
