import { Grid } from '@material-ui/core'
import { Field } from 'formik'
import React, { FC } from 'react'

import { SelectUSM } from '@/components/pages/usm/UsersPage/components/PersonalInformation/helpers/gnericSelect'
import { genderData } from '@/components/pages/usm/UsersPage/components/PersonalInformation/helpers/testData/testData'
import { PersonalInformationField } from '@/components/pages/usm/UsersPage/components/PersonalInformation/types'
// hooks
import { useTranslate } from '@/utils/internalization'

import { useGenericInput } from '../hooks'
// types
import { Props } from './types'

const PersonalInfo: FC<Props> = ({ size, disabled, isUsersPage }: Props) => {
  const translate = useTranslate()
  const { GenericInput } = useGenericInput()

  return (
    <>
      <Grid item xs={size}>
        <Field name={PersonalInformationField.FirstName}>
          {GenericInput({
            label: translate('translate#usm.title.add.user.modal.label.first.name'),
            required: true,
            placeholder: translate('translate#usm.title.add.user.modal.placeholder.first.name'),
            disabled: disabled,
          })}
        </Field>
      </Grid>
      <Grid item xs={size}>
        <Field name={PersonalInformationField.MiddleName}>
          {GenericInput({
            label: translate('translate#usm.title.add.user.modal.label.middle.name'),
            placeholder: translate('translate#usm.title.add.user.modal.placeholder.middle.name'),
            disabled: disabled,
          })}
        </Field>
      </Grid>
      <Grid item xs={size}>
        <Field name={PersonalInformationField.LastName}>
          {GenericInput({
            label: translate('translate#usm.title.add.user.modal.label.last.name'),
            required: true,
            placeholder: translate('translate#usm.title.add.user.modal.placeholder.last.name'),
            disabled: disabled,
          })}
        </Field>
      </Grid>

      {isUsersPage && (
        <Grid item xs={size}>
          <Field name={PersonalInformationField.Gender}>
            {SelectUSM({
              label: translate('translate#usm.title.add.user.modal.label.gender'),
              placeholder: translate('translate#usm.title.add.user.modal.placeholder.gender'),
              disabled: disabled,
              options: genderData.map(({ value, name }) => ({
                value,
                name: translate(name),
              })),
            })}
          </Field>
        </Grid>
      )}
    </>
  )
}

const PersonalInSalutationAndGender: FC<Props> = ({ size, disabled }: Props) => {
  const translate = useTranslate()
  // const { GenericInput } = useGenericInput()

  return (
    <Grid item xs={size}>
      {/* not delete */}
      {/* <Grid item xs={size}> */}
      {/*  <Field name={PersonalInformationField.Salutation}> */}
      {/*    {GenericInput({ */}
      {/*      label: translate('translate#usm.title.add.user.modal.label.salutation'), */}
      {/*      placeholder: translate('translate#usm.title.add.user.modal.placeholder.salutation'), */}
      {/*      disabled: disabled, */}
      {/*    })} */}
      {/*  </Field> */}
      {/* </Grid> */}
      <Grid item xs={size}>
        <Field name={PersonalInformationField.Gender}>
          {SelectUSM({
            label: translate('translate#usm.title.add.user.modal.label.gender'),
            placeholder: translate('translate#usm.title.add.user.modal.placeholder.gender'),
            disabled: disabled,
            options: genderData.map(({ value, name }) => ({
              value,
              name: translate(name),
            })),
          })}
        </Field>
      </Grid>
    </Grid>
  )
}

export { PersonalInfo, PersonalInSalutationAndGender }
