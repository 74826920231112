import { AnyAction } from 'redux'

import {
  ComCMReport,
  PlnCashPoint,
  PlnCashPointsFilter,
  PlnModifyCalculateResponse,
  PlnSettingsResponse,
} from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants'
import { plnCashPointsTableId as tableId } from '@/constants/cm/cmTableIds'
import { getTableConfig } from '@/helpers/tableLocalStorage'

export type State = {
  isFiltersModalOpen: boolean
  isDocumentModalOpen: boolean
  isModifyModalOpen: boolean
  isSettingsModalOpen: boolean
  isCalculateModalOpen: boolean
  isLoadingModalOpen: boolean
  isDeclineModalOpen: boolean
  isModifyDisabled: boolean
  modifyDialog: {
    isModifyApplyDisabled: boolean
    expenses: string
    areExpensesLoading: boolean
    expensesError?: string
  }
  formsSettings: {
    settings: PlnSettingsResponse | null
    isLoading: boolean
    errorMessage: string | null
  }
  cashPoints: {
    selectedCashPoint: PlnCashPoint | null
    checkedCashPoints: { [key: string]: PlnCashPoint }
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined
    data: PlnCashPoint[]
    dataCountToUpdate: number
    total: number
    isLoading: boolean
    isLoaded: boolean
    error: string | null
    filter: PlnCashPointsFilter
    calculateExtResponse: PlnModifyCalculateResponse | null
    paging: {
      page: number
      pageSize: number
    }
    reports: {
      errorMessage?: string
      isLoading: boolean
      list: ComCMReport[]
      selectedReport: {
        isLoading: boolean
        errorMessage?: string
        bytes?: Blob | null
      }
    }
  }
}

const initialState: State = {
  isFiltersModalOpen: false,
  isDocumentModalOpen: false,
  isModifyModalOpen: false,
  isSettingsModalOpen: false,
  isCalculateModalOpen: false,
  isLoadingModalOpen: false,
  isDeclineModalOpen: false,
  isModifyDisabled: true,
  formsSettings: {
    settings: null,
    isLoading: false,
    errorMessage: '',
  },
  modifyDialog: {
    isModifyApplyDisabled: false,
    expenses: '',
    areExpensesLoading: false,
    expensesError: undefined,
  },
  cashPoints: {
    selectedCashPoint: null,
    checkedCashPoints: {},
    sortColumn: undefined,
    sortOrder: undefined,
    data: [],
    dataCountToUpdate: 0,
    total: 0,
    isLoading: false,
    isLoaded: false,
    error: null,
    calculateExtResponse: null,
    filter: {
      period: undefined,
      planStatus: undefined,
      replenType: undefined,
      plannedByUid: undefined,
      acceptedByUid: undefined,
    },
    paging: {
      page: 1,
      pageSize: getTableConfig({ tableId })?.rowPerPage || 50,
    },
    reports: {
      errorMessage: '',
      isLoading: false,
      list: [],
      selectedReport: {
        isLoading: false,
        errorMessage: '',
        bytes: undefined,
      },
    },
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case CMActions.SetGlobalFilter:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          paging: {
            ...state.cashPoints.paging,
            page: 1,
          },
        },
      }
    case CMActions.PlnSetModifyDisabledState:
      return {
        ...state,
        isModifyDisabled: payload,
      }
    case CMActions.PlnGetSettingsRequest:
      return {
        ...state,
        formsSettings: {
          ...state.formsSettings,
          errorMessage: '',
          settings: null,
          isLoading: true,
        },
      }
    case CMActions.PlnGetSettingsResponse:
      return {
        ...state,
        formsSettings: {
          ...state.formsSettings,
          errorMessage: '',
          settings: payload,
          isLoading: false,
        },
      }
    case CMActions.PlnGetSettingsResponseFail:
      return {
        ...state,
        formsSettings: {
          ...state.formsSettings,
          errorMessage: payload,
          settings: null,
          isLoading: false,
        },
      }
    case CMActions.PlnGetModifyExpensesRequest:
      return {
        ...state,
        modifyDialog: {
          ...state.modifyDialog,
          areExpensesLoading: true,
          expensesError: '',
        },
      }
    case CMActions.PlnGetModifyExpensesResponse:
      return {
        ...state,
        modifyDialog: {
          ...state.modifyDialog,
          expenses: payload,
          areExpensesLoading: false,
          expensesError: '',
        },
      }
    case CMActions.PlnGetModifyExpensesResponseFail:
      return {
        ...state,
        modifyDialog: {
          ...state.modifyDialog,
          expenses: '',
          areExpensesLoading: false,
          expensesError: payload,
        },
      }
    case CMActions.PlnSetModifyApplyDisabled:
      return {
        ...state,
        modifyDialog: {
          ...state.modifyDialog,
          isModifyApplyDisabled: payload,
        },
      }
    case CMActions.PlnCalculateExtResponse: {
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          calculateExtResponse: payload,
        },
      }
    }
    case CMActions.PlnCheckAllCashPoints: {
      const res: State['cashPoints']['checkedCashPoints'] = {}
      const allCashPoints = payload.length > 0 ? payload : state.cashPoints.data
      for (const cashPoint of allCashPoints) {
        if (cashPoint?.id) {
          res[cashPoint.id] = cashPoint
        }
      }
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          checkedCashPoints: res,
        },
      }
    }
    case CMActions.PlnClearAllCashPoints: {
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          checkedCashPoints: {},
        },
      }
    }
    case CMActions.PlnToggleCashPoint: {
      const newCheckedCashPoints = { ...state.cashPoints.checkedCashPoints }
      const soughtCP = newCheckedCashPoints[payload?.id]
      if (!soughtCP) {
        newCheckedCashPoints[payload?.id] = payload
      } else {
        delete newCheckedCashPoints[payload?.id]
      }
      return {
        ...state,
        cashPoints: {
          ...state?.cashPoints,
          checkedCashPoints: newCheckedCashPoints,
        },
      }
    }

    case CMActions.PlnSetDataCountToUpdate:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          dataCountToUpdate: payload,
        },
      }

    case CMActions.PlnRefreshCashPointResponse: {
      const newData = [...state.cashPoints.data]
      const { dataCountToUpdate } = state.cashPoints
      const refreshedCPIndex = newData.findIndex(el => el?.id === payload?.id)

      if (~refreshedCPIndex) {
        newData[refreshedCPIndex] = { ...payload }
        return {
          ...state,
          cashPoints: {
            ...state.cashPoints,
            data: newData,
            dataCountToUpdate: dataCountToUpdate !== 0 ? dataCountToUpdate - 1 : 0,
            isLoading: dataCountToUpdate !== 0,
          },
        }
      }
      return state
    }
    case CMActions.PlnRefreshCashPointResponseFail: {
      const { dataCountToUpdate } = state.cashPoints

      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          dataCountToUpdate: dataCountToUpdate !== 0 ? dataCountToUpdate - 1 : 0,
          isLoading: dataCountToUpdate !== 0,
        },
      }
    }

    case CMActions.PlnSetSelectedCashPoint:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          selectedCashPoint: payload,
        },
      }
    case CMActions.GetPlnReportRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            selectedReport: {
              ...state.cashPoints.reports.selectedReport,
              isLoading: true,
              errorMessage: '',
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.GetPlnReportResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            selectedReport: {
              ...state.cashPoints.reports.selectedReport,
              isLoading: false,
              errorMessage: '',
              bytes: payload,
            },
          },
        },
      }
    case CMActions.GetPlnReportResponseFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            selectedReport: {
              ...state.cashPoints.reports.selectedReport,
              isLoading: false,
              errorMessage: payload,
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.GetPlnReportsRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            isLoading: true,
            list: [],
            errorMessage: '',
            selectedReport: initialState.cashPoints.reports.selectedReport,
          },
        },
      }
    case CMActions.GetPlnReportsResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            isLoading: false,
            list: payload,
            errorMessage: '',
          },
        },
      }
    case CMActions.GetPlnReportsResponseFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            isLoading: false,
            list: [],
            errorMessage: payload,
          },
        },
      }
    case CMActions.PlnSetLoadingModalOpen:
      return {
        ...state,
        isLoadingModalOpen: payload,
      }
    case CMActions.PlnSetCalculateModalOpen:
      return {
        ...state,
        isCalculateModalOpen: payload,
      }
    case CMActions.PlnSetDocumentModalOpen:
      return {
        ...state,
        isDocumentModalOpen: payload,
      }

    case CMActions.PlnSetSettingsModalOpen:
      return {
        ...state,
        isSettingsModalOpen: payload,
      }
    case CMActions.PlnResetCashPointsPage:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          paging: {
            ...state.cashPoints.paging,
            page: initialState?.cashPoints?.paging?.page || 1,
          },
        },
      }
    case CMActions.SetPlnCashPointsPagingResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          paging: {
            ...state.cashPoints.paging,
            ...payload,
          },
        },
      }
    case CMActions.PlnSetCashPointsFilter:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          filter: {
            ...state?.cashPoints?.filter,
            ...payload,
          },
        },
      }
    case CMActions.PlnSetModifyModalOpen:
      return {
        ...state,
        isModifyModalOpen: payload,
      }
    case CMActions.PlnSetFiltersModalOpen:
      return {
        ...state,
        isFiltersModalOpen: payload,
      }
    case CMActions.RefreshPlanning:
    case CMActions.PlnGetCashPointsRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: true,
          error: null,
          dataCountToUpdate: 0,
        },
      }
    case CMActions.PlnGetCashPointsResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          data: payload?.data,
          total: payload?.total,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      }
    case CMActions.PlnGetCashPointsResponseFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          data: [],
          total: 0,
          isLoading: false,
          isLoaded: true,
          error: payload,
        },
      }

    case CMActions.SortPlnTable:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          ...payload,
          isLoading: true,
        },
      }
    case CMActions.PlnSetCashPointPriority:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          data: state.cashPoints.data.map(item =>
            item.id === payload.id
              ? { ...item, priority: payload.priority, module: 'Planning' }
              : item,
          ),
        },
      }

    case CMActions.PlnRefreshCashPointRequest:
    case CMActions.PlnAcceptRequest:
    case CMActions.PlnSendToExecuteRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: true,
        },
      }
    case CMActions.SignOut:
      return initialState
    default:
      return state
  }
}
