import queryString from 'query-string'

import { AdmVehicleType, AdmVehicleTypeBase } from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

export class VehicleTypesAdminApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getAll(): Promise<AdmVehicleType[]> {
    try {
      return await VehicleTypesAdminApi.requestService.get('/admin/vehicleTypes')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getSingle(): Promise<AdmVehicleTypeBase> {
    try {
      return await VehicleTypesAdminApi.requestService.get('/admin/vehicleTypes/new')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(payload: AdmVehicleTypeBase): Promise<void> {
    try {
      return await VehicleTypesAdminApi.requestService.post('/admin/vehicleTypes/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(payload: AdmVehicleType): Promise<void> {
    try {
      return await VehicleTypesAdminApi.requestService.put('/admin/vehicleTypes/update', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(id: number): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/vehicleTypes/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await VehicleTypesAdminApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
