export enum CashBagActions {
  AssignBagForCashier = '@/ASSIGN_BAG_FOR_CASHIER',
  AssignBagForStorage = '@/ASSIGN_BAG_FOR_STORAGE',
  AssignBagSuccess = '@/ASSIGN_BAG_SUCCESS',
  BagCreated = '@/BAG_CREATED',
  BalanceBag = '@/BALANCE_BAG',
  ChangeBagLocation = '@/CHANGE_BAG_LOCATION',
  ChangePagingRequest = '@/CHANGE_CASH_BAGS_PAGING_REQUEST',
  ChangePagingResponse = '@/CHANGE_CASH_BAGS_PAGING_RESPONSE',
  ChangeSelectedFilters = '@/CHANGE_CASH_BAGS_SELECTED_FILTERS',
  ClearSelectedFilters = '@/CLEAR_CASH_BAGS_SELECTED_FILTERS',
  CreateBagRequest = '@/CREATE_BAG_REQUEST',
  CreateBagResponse = '@/CREATE_BAG_RESPONSE',
  DeleteCashBag = '@/DELETE_CASH_BAG',
  GetCashBagCurrenciesRequest = '@/GET_CASH_BAG_CURRENCIES_REQUEST',
  GetCashBagCurrenciesResponse = '@/GET_CASH_BAG_CURRENCIES_RESPONSE',
  GetCashBagCurrenciesResponseFail = '@/GET_CASH_BAG_CURRENCIES_RESPONSE_FAIL',
  GetCashBagDetailsRequest = '@/GET_CASH_BAG_DETAILS_REQUEST',
  GetCashBagDetailsResponse = '@/GET_CASH_BAG_DETAILS_RESPONSE',
  GetCashBagsRequest = '@/GET_CASH_BAGS_REQUEST',
  GetCashBagsResponse = '@/CASH_BAGS',
  GetCashBagsResponseFail = '@/GET_CASH_BAGS_RESPONSE_FAIL',
  GetCashBagStagesRequest = '@/GET_CASH_BAG_STAGES_REQUEST',
  GetCashBagStagesResponse = '@/GET_CASH_BAG_STAGES_RESPONSE',
  GetCashBagTransportHistory = '@/GET_CASH_BAG_TRANSPORT_HISTORY',
  GetCashBagTransportHistoryResponse = '@/GET_CASH_BAG_TRANSPORT_HISTORY_RESPONSE',
  GetCurrenciesRequest = '@/GET_CASH_BAG_CURRENCIES_REQUEST',
  GetCurrenciesResponse = '@/GET_CASH_BAG_CURRENCIES_RESPONSE',
  GetCurrenciesResponseFail = '@/GET_CASH_BAG_CURRENCIES_RESPONSE_FAIL',
  GetRecieverVaultsRequest = '@/GET_RECIEVER_VAULTS_REQUEST',
  GetRecieverVaultsResponse = '@/GET_RECIEVER_VAULTS_RESPONSE',
  GetTransmitterVaultsRequest = '@/GET_TRANSMITTER_VAULTS_REQUEST',
  GetTransmitterVaultsResponse = '@/GET_TRANSMITTER_VAULTS_RESPONSE',
  SetPanelLayout = '@/SET_PANEL_LAYOUT',
  SetSelectedCashBag = '@/SET_SELECTED_CASH_BAG',
  SetSelectedCashBagBalance = '@/SET_SELECTED_CASH_BAG_BALANCE',
  SetSelectedCashBagHistoryNode = '@/SET_SELECTED_CASH_BAG_HISTORY_NODE',
}
