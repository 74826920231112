import makeStyles from '@material-ui/core/styles/makeStyles'
import { styledComponentTheme } from '@/theme'
import { Theme } from '@material-ui/core'

interface StylesProps {
  emptyValue: string
  inputValue: boolean
  maxItemSymbolsCount?: number
}

export const useClasses = makeStyles<Theme, StylesProps>(({ palette, spacing }) => ({
  dropDown: {
    margin: spacing(2, 0),
  },
  errorLabel: {
    height: spacing(2),
    fontSize: styledComponentTheme.fontSizes.verySmall,
    paddingTop: spacing(0.5),
    paddingLeft: spacing(2),
    color: palette.error.main,
  },
  inputWithError: {
    marginBottom: 0,
  },
  icon: {
    color: palette.grey[200],
  },
  disabled: {
    color: palette.grey[200],
  },
  iconButton: {
    width: spacing(2.5),
    height: spacing(2.5),
  },
  labelDisabled: {
    display: 'inline',
    opacity: 0.6,
  },
  option: ({ emptyValue, inputValue }: StylesProps) => ({
    '&:first-child': {
      color: emptyValue && !inputValue ? palette.grey[200] : 'black',
    },
  }),
  menuPaper: ({ maxItemSymbolsCount }) => ({
    minWidth: '100%',
    width: `${maxItemSymbolsCount}ch`,
  }),
}))
