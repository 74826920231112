import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { EventNotificationsApi } from '@/api/cm/adminApi'
import { CMAppConfigActions } from '@/constants'

import {
  addEventNotificationsFail,
  deleteEventNotificationsFail,
  getEventNotificationsFail,
  getEventNotificationsRequest,
  getEventNotificationsResponse,
  updateEventNotificationsFail,
} from './actions'
import { getEventNotificationsSort } from './selectors'

function* getEventNotifications(): SagaIterator {
  try {
    const sort = yield select(getEventNotificationsSort)

    const response = yield call(EventNotificationsApi.get, sort)

    yield put(getEventNotificationsResponse(response))
  } catch (error) {
    yield put(getEventNotificationsFail())
  }
}

function* updateEventNotification({ payload }: AnyAction): SagaIterator {
  try {
    yield call(EventNotificationsApi.update, payload)

    yield put(getEventNotificationsRequest())
  } catch (error) {
    yield put(updateEventNotificationsFail(error))
  }
}

function* addEventNotification({ payload }: AnyAction): SagaIterator {
  try {
    yield call(EventNotificationsApi.add, payload)

    yield put(getEventNotificationsRequest())
  } catch (error) {
    yield put(addEventNotificationsFail(error))
  }
}

function* deleteEventNotification({ payload }: AnyAction): SagaIterator {
  try {
    yield call(EventNotificationsApi.delete, payload)

    yield put(getEventNotificationsRequest())
  } catch (error) {
    yield put(deleteEventNotificationsFail(error))
  }
}

export default function*(): Generator {
  yield takeLatest(
    [CMAppConfigActions.AdmEventNotificationsRequest, CMAppConfigActions.AdmSortEventNotifications],
    getEventNotifications,
  )

  yield takeLatest(CMAppConfigActions.AdmEventNotificationsUpdateRequest, updateEventNotification)
  yield takeLatest(CMAppConfigActions.AdmEventNotificationsAddRequest, addEventNotification)
  yield takeLatest(CMAppConfigActions.AdmEventNotificationsDeleteRequest, deleteEventNotification)
}
