import { createMultipleSelectField } from '@/components/controls/MultipleSelect/component'
import { Props } from './types'

export const MultipleSelect = ({
  label,
  placeholder,
  disabled,
  options,
  required,
  parameterName,
}: Props) =>
  createMultipleSelectField({
    disabled,
    required,
    fullWidth: true,
    label,
    shrink: true,
    parameterName,
    placeholder,
    options: options,
  })
