import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  wrapper: {
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
  },
  editButton: {
    padding: spacing(0.5),
    marginRight: spacing(1),
  },
  deleteButton: {
    padding: spacing(0.5),
    color: palette.error.main,
    backgroundColor: palette.error.light,
    '&:hover': {
      backgroundColor: palette.error.light,
    },
  },
  loader: {
    width: spacing(3),
    height: spacing(3),
  },
}))
