export enum CashBagFields {
  BagNumber = 'bagNumber',
  Balance = 'balance',
  Cahier = 'friendlyName',
  CashOrderID = 'coid',
  CurrentLocation = 'currentVault',
  CurrentStorage = 'currentStorage',
  Stage = 'currentCashBagStatus',
  Stamp = 'stamp',
}

export enum CashBagStageFields {
  ID = 'stageID',
  Name = 'stage',
}

export interface CashBag {
  [CashBagFields.BagNumber]: string | null | undefined
  [CashBagFields.Balance]: string | null | undefined
  [CashBagFields.Cahier]: string | null | undefined
  [CashBagFields.CashOrderID]: string | null | undefined
  [CashBagFields.CurrentLocation]: string | null | undefined
  [CashBagFields.CurrentStorage]: string | null | undefined
  [CashBagFields.Stage]: string | null | undefined
  [CashBagFields.Stamp]: string | null | undefined
}
