import React from 'react'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { Props } from './types'
import { useClasses } from './styles'
import clsx from 'clsx'

const SubTitle = ({ text, isRequired, wrapperClasses }: Props): React.ReactElement => {
  const classes = useClasses()

  return (
    <Typography className={clsx(classes.subTitle, wrapperClasses)} variant="h6">
      <div>{text}&nbsp;</div>
      {isRequired && <span className={classes.asterisk}>*</span>}
      <Box flex={1}>
        <Divider />
      </Box>
    </Typography>
  )
}

export default SubTitle
