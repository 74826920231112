export enum CreatePOSRequestFormFields {
  AcquirerBank = 'acquirerBank',
  CustomerRequestNumber = 'customerRequestNumber',
  ServiceCompany = 'serviceCompany',
  Merchant = 'merchantId',
  Date = 'date',
  ServiceType = 'serviceType',
  MerchantFullName = 'merchantfullname',
  PosServiceStartDate = 'posservicestartdate',
  Email = 'merchantemail',
  CompanyCode = 'merchantidentificationnr',
}

export interface CreatePOSRequestFormValues {
  [CreatePOSRequestFormFields.AcquirerBank]: string
  [CreatePOSRequestFormFields.CustomerRequestNumber]: string
  [CreatePOSRequestFormFields.ServiceCompany]: string
  [CreatePOSRequestFormFields.Merchant]: string
  [CreatePOSRequestFormFields.Date]: string
  [CreatePOSRequestFormFields.ServiceType]: string
}

export interface CreatePOSRequestFormServicesTableRow {
  name: string
  service: string
  priority: string
  terminalId: string
  fullAddress: string
}
