import { AnyAction } from 'redux'

import { AuthorizationActions, ModalsActions } from '@/constants'

export interface State {
  historyTicketsFiltersModalWasOpen: boolean
}

export const initialState: State = {
  historyTicketsFiltersModalWasOpen: false,
}

export const reducer = (state: State = initialState, { type }: AnyAction): State => {
  switch (type) {
    case ModalsActions.SetHistoryTicketsFiltersModalState:
      return {
        ...state,
        historyTicketsFiltersModalWasOpen: true,
      }
    default:
      return state
  }
}
