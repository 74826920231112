import makeStyles from '@material-ui/core/styles/makeStyles'
import { styledComponentTheme } from '@/theme'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  table: {
    height: `calc(100% - ${spacing(9)}px)`,
  },
  fullHeight: {
    height: '100%',
  },
  tablePaper: {
    marginBottom: spacing(3),
  },
  headColumn: {
    width: spacing(24),

    '&:nth-child(2)': {
      width: spacing(34),
    },
  },
  actions: {
    width: spacing(8),
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    width: spacing(21),
    backgroundColor: palette.primary.main,
    color: '#ffffff',
    paddingLeft: spacing(1),
    paddingRight: spacing(1),

    '&:hover': {
      backgroundColor: palette.primary.main,
      filter: 'none',
    },
  },
  tableControlButton: {
    height: spacing(5),
    backgroundColor: '#f2fdfe',
    marginLeft: spacing(1),
  },
  showGroupsButton: {
    minWidth: spacing(5),
    width: spacing(5),
  },
  buttonIcon: {
    fontSize: styledComponentTheme.fontSizes.big,
  },
  tableHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  tableTitle: {
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
    fontSize: '20px',
    marginBottom: '0',
  },
  tableButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  indeterminate: {
    color: palette.primary.main,
  },
}))
