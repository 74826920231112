import { TablePagination, TablePaginationProps } from '@material-ui/core'
import React from 'react'

const MaterialTablePatchedPagination = (props: TablePaginationProps): JSX.Element => {
  const { ActionsComponent, onChangePage, onChangeRowsPerPage, ...tablePaginationProps } = props

  if (onChangePage && ActionsComponent) {
    return (
      <TablePagination
        {...tablePaginationProps}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        ActionsComponent={subprops => {
          const { onPageChange, ...actionsComponentProps } = subprops
          return <ActionsComponent {...actionsComponentProps} onPageChange={onPageChange} />
        }}
      />
    )
  }
  return <></>
}

export default MaterialTablePatchedPagination
