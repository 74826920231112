import { Box, Popover, Tooltip } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Alert } from '@material-ui/lab'
import clsx from 'clsx'
import { Form, FormikProvider, useFormik } from 'formik'
import { isEqual } from 'lodash-es'
import React, { ReactElement, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { adminOptionsApi } from '@/api/atmeye/adminOptionsApi'
import { AdminOptions } from '@/api/atmeye/adminOptionsApi/types'
import Edit from '@/assets/sd/edit.svg'
import Save from '@/assets/sd/save.svg'
import CloseButton from '@/components/blocks/CloseButton'
import Button from '@/components/controls/Button'
import { Select } from '@/components/controls/Select/component'
import TemplateModal from '@/components/NPMPakage/components/modals/TemplateModal'
import { transactionFilterFieldsValidations } from '@/helpers/atmeye/filtersValidations'
import { useTranslate } from '@/utils/internalization'

import { GenerateFormFields } from './GenerateFormFields/GenerateFormFields'
import { useFilterTemplates } from './hooks/useFiltersTempaltes'
import { useClasses } from './styles'
import { FiltersModalProps, MODAL_TYPE } from './types'

const FiltersModalTransactions = ({
  formData,
  defaultValues,
  open,
  handleClose,
  filterFields,
  selectedFilters,
  selectedFiltersTemplate: appliedFilterTemplate,
  handleApplyFilterTemplate,
  requiredFilters,
  filterTemplatesType,
  formikValidate,
}: FiltersModalProps): ReactElement => {
  const translate = useTranslate()
  const classes = useClasses()
  const [options, setOptions] = useState<AdminOptions | null>(null)
  const formik = useFormik<Record<string, any>>({ ...formData, validate: formikValidate })

  const {
    selectedTemplate,
    templatesOptions,
    isLoadingTemplates,
    handleSaveTemplate,
    handleEditCurrentTemplate,
    handleDeleteTemplate,
    onChangeSelectedTemplate,
    modalType,
    anchorEl,
    showModal,
    handleOpenPopover,
    handleClosePopover,
    templates,
  } = useFilterTemplates({
    filters: formik.values,
    setFilters: formik.setValues,
    appliedFilterTemplate,
    defaultFilter: defaultValues,
    filterTemplatesType,
  })

  const getOptions = async () => {
    try {
      const options = await adminOptionsApi.getOptions()
      setOptions(options)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    getOptions()
  }, [])

  const isTemplateUnsavedChanges = !isEqual(formik.values, selectedTemplate?.json)

  const handleFiltersApply = (e: any): void => {
    e.stopPropagation()
    e.preventDefault()
    const template = isTemplateUnsavedChanges ? null : selectedTemplate
    handleApplyFilterTemplate(template)
    formData.onSubmit(formik.values)
    handleClose()
  }

  const onModalClose = (): void => {
    const appliedTplUpdatedFilter = templates.find(({ id }) => id === appliedFilterTemplate?.id)
    const shouldResetTemplate =
      appliedTplUpdatedFilter && !isEqual(appliedFilterTemplate, appliedTplUpdatedFilter)

    if (shouldResetTemplate) {
      handleApplyFilterTemplate(null)
    }

    handleClose()
  }

  const handleSetDefaultFilters = (): void => {
    formik.setValues(defaultValues)
  }

  const isEmptyFilter =
    isEqual(formik.values, defaultValues) ||
    Object.values(formik.values).every(
      value => value?.length === 0 || (value?.[0] === null && value?.[1] === null),
    )

  return (
    <Dialog maxWidth="md" open={open} keepMounted onClose={onModalClose} disableBackdropClick>
      {modalType === MODAL_TYPE.MAIN && (
        <div className={classes.wrapper}>
          <CloseButton absolute className={classes.closeButton} onClick={onModalClose} />
          <Grid className={classes.buttonWrapper} container spacing={2} justify="flex-end">
            <Grid container>
              <Grid item md={6}>
                <Typography className={classes.header} variant="h5">
                  <FormattedMessage id="title.filters" defaultMessage="Filters" />
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={5}>
                <Typography variant="body1">
                  <FormattedMessage
                    id="title.pleaseEnterRequiredFiltersParameters"
                    defaultMessage="Enter the required parameters."
                  />
                </Typography>
              </Grid>
            </Grid>
            <FormikProvider value={formik}>
              <Form className={classes.form}>
                <div className={classes.formRow}>
                  {filterFields.map(filter => {
                    const isDateTimeType = filter.valueType === 'DATETIME'

                    const validationFunc =
                      filter.allowValidation &&
                      transactionFilterFieldsValidations?.[filter.fieldName]
                    const fieldValidationStatus = validationFunc
                      ? validationFunc(
                          formik.values[filter.fieldName],
                          formik.values,
                          options?.limits,
                        )
                      : null

                    return (
                      <div
                        className={
                          (isDateTimeType && filter.allowValidation) || filter.fullWidth
                            ? classes.rowElemFullWidth
                            : classes.rowElem
                        }
                        key={filter.fieldName}
                      >
                        <GenerateFormFields
                          adminSettings={options?.limits}
                          field={{
                            ...filter,
                            disabled: !!requiredFilters?.[filter.fieldName] || filter.disabled,
                          }}
                          formik={formik}
                          isAtemeyFilterModal
                        />
                        {(fieldValidationStatus || isDateTimeType) && (
                          <div
                            className={clsx(classes.fieldValidationWrapper, {
                              [classes.fieldDateTimeValidationWrapper]: isDateTimeType,
                            })}
                          >
                            {fieldValidationStatus?.isError && (
                              <div>
                                <Box>
                                  <Alert severity="warning" color="warning" variant="standard">
                                    {translate(
                                      `translate#title.fieldValidationWarning.${fieldValidationStatus?.reason}`,
                                    )}
                                  </Alert>
                                </Box>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>

                <div className={classes.errorWrapper}>
                  <Typography variant="h6" className={classes.savedFilters}>
                    <FormattedMessage id="title.savedFilters" defaultMessage="Saved Filters" />
                  </Typography>
                </div>

                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <div style={{ display: 'flex', alignItems: 'center', columnGap: '16px' }}>
                      <Select
                        fullWidth
                        isLoading={isLoadingTemplates}
                        label={translate('translate#title.template')}
                        value={selectedTemplate?.id || ''}
                        options={templatesOptions}
                        onChange={e => {
                          onChangeSelectedTemplate(e.target.value)
                        }}
                        neverDisable
                        withoutCheckerForSingleOption
                      />
                      <Tooltip
                        placement="top"
                        classes={{
                          tooltip: classes.toolTip,
                        }}
                        title={translate('translate#atmeye.button.edit.tooltip')}
                      >
                        <div>
                          <Button
                            width="xs"
                            disabled={!selectedTemplate || isEmptyFilter}
                            className={classes.iconButton}
                            onClick={(): void => showModal(MODAL_TYPE.EDIT)}
                            variant="outlined"
                          >
                            <img src={Edit} />
                          </Button>
                        </div>
                      </Tooltip>

                      {/* <span> */}

                      <Tooltip
                        placement="top"
                        classes={{
                          tooltip: classes.toolTip,
                        }}
                        title={translate('translate#atmeye.button.save.tooltip')}
                      >
                        <div>
                          <Button
                            disabled={isEmptyFilter || !formik.isValid}
                            width="xs"
                            className={classes.iconButton}
                            style={{
                              backgroundColor:
                                !selectedTemplate || !isTemplateUnsavedChanges ? '' : 'moccasin',
                            }}
                            onClick={handleOpenPopover}
                            variant="outlined"
                          >
                            <img src={Save} />
                          </Button>
                        </div>
                      </Tooltip>

                      <Popover
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        classes={{
                          paper: classes.popoverPaper,
                        }}
                        onClose={handleClosePopover}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <Button
                          className={classes.popoverButton}
                          onClick={(): void => {
                            selectedTemplate
                              ? handleEditCurrentTemplate()()
                              : showModal(MODAL_TYPE.SAVE)
                          }}
                        >
                          {translate('translate#table.header.button.save')}
                        </Button>

                        <Button
                          className={classes.popoverButton}
                          onClick={(): void => showModal(MODAL_TYPE.SAVE)}
                        >
                          {translate('translate#table.header.button.saveAs')}
                        </Button>
                      </Popover>
                      {/* </span> */}
                    </div>
                  </Grid>

                  <Grid item md={6}>
                    <Grid container justify="flex-end">
                      <Grid item className={classes.actionButtons}>
                        {defaultValues && (
                          <Tooltip
                            placement="top"
                            classes={{
                              tooltip: classes.toolTip,
                            }}
                            title={translate('translate#atmeye.button.defaultFilters')}
                          >
                            <div>
                              <Button
                                disabled={isEqual(formik.values, defaultValues)}
                                className={classes.button}
                                variant="outlined"
                                onClick={handleSetDefaultFilters}
                              >
                                {translate('translate#atmeye.button.defaultFilters')}
                              </Button>
                            </div>
                          </Tooltip>
                        )}
                        {/* {isWithClearBtn && (
                        <Tooltip
                          placement="top"
                          classes={{ tooltip: classes.toolTip }}
                          title={translate(
                            selectableTable
                              ? 'translate#action.clear'
                              : 'translate#table.header.button.clearFilters',
                          )}
                        >
                          <div>
                            <Button
                              className={classes.button}
                              variant="outlined"
                              disabled={isEqual(emptyFormValues, values)}
                              onClick={handleClear(setValues)}
                            >
                              <FormattedMessage
                                id={selectableTable ? 'action.clear' : 'title.clearFilters'}
                                defaultMessage={selectableTable ? 'Clear' : 'Clear filters'}
                              />
                            </Button>
                          </div>
                        </Tooltip>
                      )} */}
                        <Button
                          disabled={!formik.isValid}
                          className={classes.button}
                          variant="contained"
                          type="button"
                          onClick={handleFiltersApply}
                        >
                          <FormattedMessage id="title.search" defaultMessage="Search" />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </Grid>
        </div>
      )}

      {(modalType === MODAL_TYPE.EDIT || modalType === MODAL_TYPE.SAVE) && (
        <div style={{ padding: '24px' }}>
          <TemplateModal
            templateName={selectedTemplate?.name || ''}
            modalType={modalType}
            onEditTemplate={
              modalType === MODAL_TYPE.EDIT ? handleEditCurrentTemplate : handleSaveTemplate
            }
            onClose={(): void => showModal(MODAL_TYPE.MAIN)}
            onDeleteTemplate={handleDeleteTemplate}
          />
        </div>
      )}
    </Dialog>
  )
}

export { FiltersModalTransactions }
