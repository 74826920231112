import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { isIE } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import Test from 'react-test-attributes'

import PSRTicketDetails from '@/components/blocks/PSRTicketDetails'
import CreatePOSRequestModal from '@/components/blocks/PSRTicketDetails/components/CreatePOSRequestModal/component'
import TicketDetailModal from '@/components/blocks/TicketDetailsModal'
import GroupingModal from '@/components/pages/sd/TicketsPage/components/GroupingModal/component'
import { TICKET_PAGE_TICKET_TABLE } from '@/components/pages/sd/TicketsPage/components/TicketsTable/component'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { getCurrentAccount } from '@/store/auth/selectors'
import { activeLocale } from '@/store/internalization'
import { setTicketId } from '@/store/sd/drawerActions'
import {
  changeSorting,
  clearGroupedTicketDetailsData,
  getGroupedTicketsData,
  getTicketsConfigData,
  getTicketsConfigRequest,
  getTicketsData,
  getTicketsRequest,
  hasGroupedTickets,
  isFetchingTickets,
  setTicketsPage,
  setTypeOfTickets,
} from '@/store/sd/tickets/index'
import { TicketsConfigData, TypeOfTicket } from '@/types'
import { getTestId } from '@/utils'
import { usePreviousValue } from '@/utils/hooks/hooks'
import { getTableSettings } from '@/utils/localStorage'

import FiltersModal from './components/FiltersModal/index'
import GroupedTicketsTable from './components/GroupedTicketsTable'
import TicketsTable from './components/TicketsTable'
import { useClasses } from './styles'
import { Props } from './types'

const TicketsPage = ({ typeOfTickets }: Props): React.ReactElement => {
  const ticketsConfig: TicketsConfigData = useSelector(getTicketsConfigData)
  const data = useSelector(getTicketsData)
  const groupedData = useSelector(getGroupedTicketsData)
  const isLoading = useSelector(isFetchingTickets)
  const hasGroups = useSelector(hasGroupedTickets)
  const account = useSelector(getCurrentAccount)
  const stateLang = useSelector(activeLocale)
  const prevLang = usePreviousValue(stateLang)

  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [openDetails, setOpenDetails] = useState<boolean>(false)
  const [psrDetailsIsOpen, setPsrDetailsIsOpen] = useState<boolean>(false)
  const [showGroupingModal, setShowGroupingModal] = useState(false)
  const [currentTicketId, setCurrentTicketId] = useState('')
  const [openPOSModal, setOpenPOSModal] = React.useState(false)

  const idPrefix = 'sd-tickets-page'
  const testId = getTestId(idPrefix)
  const props = { display: isIE ? 'block' : 'flex' }
  const classes = useClasses(props)
  const dispatch = useDispatch()

  const handleTicketClick = useCallback((ticketInfo: any) => {
    setCurrentTicketId(ticketInfo.documentid)
    setOpenDetails(true)
  }, [])

  const handleSetCurrentTicketId = useCallback(
    (ticketId: number): void => {
      setCurrentTicketId(`${ticketId}`)
    },
    [setCurrentTicketId],
  )

  const handlePosTicketClick = useCallback(
    (ticketInfo: any) => {
      if (ticketInfo.item) {
        dispatch(setTicketId(ticketInfo.item.documentid))
      } else {
        dispatch(setTicketId(ticketInfo.documentid))
      }

      setPsrDetailsIsOpen(true)
    },
    [dispatch],
  )

  const handleClosePsrDetails = useCallback(() => {
    setPsrDetailsIsOpen(false)
  }, [])

  const handleCloseDetails = useCallback((): void => {
    setCurrentTicketId('')
    setOpenDetails(false)
  }, [])

  const handleSetFiltersModal = useCallback(
    (val: boolean): (() => void) => (): void => {
      setShowFiltersModal(val)
    },
    [dispatch],
  )

  const handleSetGrouping = useCallback(
    (val: boolean): (() => void) => (): void => {
      setShowGroupingModal(val)
      dispatch(changeSorting(null))
    },
    [dispatch],
  )

  const handleOpenPOS = useCallback((): void => {
    setOpenPOSModal(true)
  }, [setOpenPOSModal])

  const handleClosePOS = useCallback((): void => {
    setOpenPOSModal(false)
  }, [setOpenPOSModal])

  const handleOpenPsrDetails = useCallback((): void => {
    setPsrDetailsIsOpen(true)
  }, [setPsrDetailsIsOpen])

  /**
   * Define has table settings in local storage for history tickets
   */
  const hasHistoryTicketsPredefinedTickets = useMemo((): boolean => {
    if (account && typeOfTickets === TypeOfTicket.HistoryTickets) {
      const savedSettings = getTableSettings(
        LocalStorageItems.UserSettings,
        account.userId.toString(),
        `${TICKET_PAGE_TICKET_TABLE}/${TypeOfTicket.HistoryTickets}`,
      )
      if (savedSettings && savedSettings.ticketsConfig) {
        return true
      }
    }
    return false
  }, [account, typeOfTickets])

  useEffect(() => {
    dispatch(clearGroupedTicketDetailsData())
    dispatch(setTypeOfTickets(typeOfTickets))
  }, [typeOfTickets, dispatch])

  useEffect(() => {
    if (!ticketsConfig.formUID || prevLang === undefined) {
      dispatch(getTicketsConfigRequest())
    }
  }, [ticketsConfig, prevLang, dispatch])

  useEffect(() => {
    if (
      (!data &&
        (typeOfTickets !== TypeOfTicket.HistoryTickets ||
          (typeOfTickets === TypeOfTicket.HistoryTickets && hasHistoryTicketsPredefinedTickets))) ||
      prevLang === undefined
    ) {
      dispatch(getTicketsRequest(true))
    }
  }, [data, typeOfTickets, dispatch, prevLang])

  useEffect(() => {
    if (typeOfTickets === TypeOfTicket.HistoryTickets) {
      setShowFiltersModal(true)
    }
  }, [typeOfTickets])

  useEffect(() => {
    if (!hasGroups) {
      dispatch(setTicketsPage(1))
    }
  }, [hasGroups, dispatch])

  return (
    <>
      <Test id={testId(0)}>
        <div className={classes.root}>
          {hasGroups ? (
            <GroupedTicketsTable
              isLoading={isLoading}
              data={groupedData || []}
              ticketsConfig={ticketsConfig}
              typeOfTickets={typeOfTickets}
              idPrefix={idPrefix}
              selectedFilters={null}
              testId={testId}
              handleSetFiltersModal={handleSetFiltersModal}
              handleSetGrouping={handleSetGrouping}
              ticketClick={handleTicketClick}
              posTicketClick={handlePosTicketClick}
              lazyLoadingPage={hasGroups}
            />
          ) : (
            <TicketsTable
              ticketsConfig={ticketsConfig}
              testId={testId}
              idPrefix={idPrefix}
              data={data || []}
              typeOfTickets={typeOfTickets}
              isLoading={isLoading}
              handleSetFiltersModal={handleSetFiltersModal}
              handleSetGrouping={handleSetGrouping}
              ticketClick={handleTicketClick}
              posTicketClick={handlePosTicketClick}
            />
          )}
          <GroupingModal
            testIdPrefix={idPrefix}
            open={showGroupingModal}
            handleClose={handleSetGrouping(false)}
          />
          <FiltersModal
            testIdPrefix={idPrefix}
            open={showFiltersModal}
            typeOfTickets={typeOfTickets}
            handleClose={handleSetFiltersModal(false)}
          />
          {openDetails && (
            <TicketDetailModal
              open={openDetails}
              ticketId={currentTicketId}
              handleClose={handleCloseDetails}
            />
          )}
        </div>
      </Test>
      <CreatePOSRequestModal
        open={openPOSModal}
        handleClose={handleClosePOS}
        handleOpenPsrDetails={handleOpenPsrDetails}
        handleSetCurrentTicketId={handleSetCurrentTicketId}
        disableBackdropClick
      />
      <PSRTicketDetails
        open={psrDetailsIsOpen}
        idPrefix={idPrefix}
        ticketId={parseInt(currentTicketId)}
        handleClose={handleClosePsrDetails}
        handleOpenCreatePosRequestModal={handleOpenPOS}
      />
    </>
  )
}

export default TicketsPage
