import { AxiosError } from 'axios'
import { FormikValues } from 'formik'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { SyntheticEvent } from 'react'

import { TableFilterDate } from '@/components/controls/AppTable/types'
import { ColumnsDeviceTypes } from '@/components/NPMPakage/components/tableComponents/UITable/types'
import { SortingTypes } from '@/components/NPMPakage/hooks/useSorting/types'
import { httpErrorDefaultMessages } from '@/constants'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { quequeSize } from '@/store/notifications/reducer'

export const dummyFunction = (fn: any): any => fn

export const preventDefault = <T = any>(e: SyntheticEvent<T>) => e.preventDefault()
export const stopPropagation = <T = any>(e: SyntheticEvent<T>) => e.stopPropagation()

export const turnKeysIntoWords = (value: string): string => {
  const lowerCaseValue = value
    .replace(/[A-Z/]+/g, substring => ` ${substring}`)
    .replace('  ', '  ')
    .trim()
    .toLowerCase()

  return lowerCaseValue.charAt(0).toUpperCase() + lowerCaseValue.slice(1)
}

export function reorderArray<T = any>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const turnObjectIntoURLSchema = (obj: Record<string, any>, urlKey = ''): string =>
  Object.keys(obj)
    .map(key => {
      return encodeURIComponent(urlKey || key) + '=' + encodeURIComponent(obj[key])
    })
    .join('&')

export const setInitialDateForCM = (): TableFilterDate => {
  const storedDaysAhead = localStorage.getItem(LocalStorageItems.MonitoringLookAheadPeriodDays)
  const daysAhead = storedDaysAhead ? storedDaysAhead! : 15
  return {
    start: moment()
      .subtract(2, 'week')
      .startOf('day')
      .format(),
    end: moment()
      .add(daysAhead, 'day')
      .startOf('day')
      .format(),
  }
}

export const getErrorMessage = (error: AxiosError, errorMessage?: string): string => {
  return (
    (error.response
      ? `${error.response.status}: ${errorMessage || error.response.data}` ||
        httpErrorDefaultMessages[error.response.status]
      : error.message) || 'translate#cm.ErrorWindowGenericError'
  )
}

export const getFormData = (object: Record<string, any>): FormData => {
  const formData: FormData = new FormData()

  Object.keys(object).forEach(key => {
    if (Array.isArray(object[key])) {
      object[key].forEach((i: string | Blob) => formData.append(key, i))
    } else {
      formData.append(key, object[key])
    }
  })

  return formData
}

export const removeEmptyFieldAtObject = (object: { [key: string]: any }) => {
  const isFormFieldEmpty = (value: any) =>
    value === null || value === undefined || value === '' || value === -1

  const tmp = { ...object }
  for (const field in object) {
    if (isFormFieldEmpty(object[field])) {
      delete tmp[field]
    }
  }
  return tmp
}

export const removeEmptyFieldAtObjectDeep = (object: unknown): unknown => {
  if (typeof object === 'object' && object !== null) {
    const isFormFieldEmpty = (value: unknown): boolean =>
      value === null || value === undefined || value === ''

    const clonedObject = cloneDeep(object)

    const getProp = (o: Record<string, any>): void => {
      for (const prop in o) {
        if (typeof o[prop] === 'object' && o[prop] !== null) {
          getProp(o[prop])
        } else {
          if (isFormFieldEmpty(o[prop])) {
            delete o[prop]
          }
        }
      }
    }

    getProp(clonedObject)

    return clonedObject
  }
  return object
}

export const getCurrentDateString = (): string => {
  return moment()
    .startOf('day')
    .format()
}

export const sortAlphabeticallyStringArray = ({
  array,
  sortProperty,
}: {
  array: Array<any>
  sortProperty: string
}): Array<any> => {
  if (array.length === 0) {
    return array
  }

  return array.sort((a: any, b: any) => a[sortProperty].localeCompare(b[sortProperty]))
}

export const reverseArray = <T>(array: Array<T>): Array<T> => {
  const reverseArray = [] as Array<T>

  for (let i = array.length - 1; i >= 0; i--) {
    reverseArray.push(array[i])
  }

  return reverseArray
}

export const sortTable = <T>(tableData: Array<T>, sorting: SortingTypes): Array<T> => {
  const newTableData = [...tableData].sort((a: any, b: any) =>
    String(a[sorting.fieldName]).localeCompare(String(b[sorting.fieldName])),
  )

  return sorting.sortOrder === 'asc' ? [...newTableData] : [...reverseArray(newTableData)]
}

export const translateColumns = (
  columns: Array<ColumnsDeviceTypes>,
  translate: (string: string) => string,
): Array<ColumnsDeviceTypes> =>
  columns.map(column => ({
    ...column,
    fieldLabel: translate(`translate#${column.fieldLabel}`),
  }))

export const addTranslates = (
  data: Array<any>,
  key: string,
  translate: (string: string) => string,
): Array<any> => {
  return data.map(item => ({ ...item, [key]: translate(item[key]) }))
}

export const getAverage = (numbers: Array<number>) => {
  const sum = numbers.reduce((acc, number) => acc + number, 0)
  const { length } = numbers
  return sum / length
}

export const getDefaultFilters = (defaultFilters: FormikValues, savedFilters: FormikValues) => {
  // deepCopy (lodash) does not work with an object whose key is Moment
  const filters: FormikValues = JSON.parse(JSON.stringify(defaultFilters))

  Object.keys(defaultFilters).map(filterKey => {
    const currentFilter = defaultFilters?.[filterKey]

    if (Array.isArray(currentFilter)) {
      /* eslint-disable no-unused-expressions */
      currentFilter.forEach((filter, index) => {
        const savedFilter = savedFilters?.[filterKey]?.[index]

        if (!filter && savedFilter) {
          filters[filterKey][index] = savedFilter
        }
      })
    }
  })

  return filters
}

export const formatCount = ({
  count,
  maxCount = quequeSize,
}: {
  count: number
  maxCount?: number
}): string | number => {
  return count >= maxCount ? `${maxCount - 1}+` : count
}

export const createStringWithoutSpaces = (string?: string): string => {
  return string?.replace(/\s+/g, ' ')?.trim() || ''
}

export const safeJsonParse = (
  data: string,
  { fallbackValue, onError }: { fallbackValue?: any; onError?: () => void },
): any => {
  try {
    return JSON.parse(data)
  } catch (e) {
    onError && onError()
    return fallbackValue || null
  }
}
