import { ItemsReportMenu } from '@/api/sd/reportsApi/types'
import { actions } from '@/store/sd/reports/actions'
import { ResponseError } from '@/types'
import { Parameters, ReportSaveRequest, SubscribeItems } from '@/types/sd/reports/reports'
import { filterTree } from '@/utils/filterTreeNested'

export const initialState = {
  lazyReportMenu: [] as Array<ItemsReportMenu> | [],
  allReportMenu: [] as Array<ItemsReportMenu> | [] | any,
  error: null as ResponseError | null,
  isFetchingReports: false,
  myReportsMenu: [] as Array<ItemsReportMenu> | [] | any,
  isFetchingSubscribeReports: false,
  report: null as null | any,
  isFetchingReportById: false,
  searchFilterReportByName: '' as string,
  subscriptions: [] as Array<SubscribeItems>,
  ReportsConfig: null as Parameters | null,
  isFetchingReportsConfig: false,
  reportId: '',
  reportUid: '' as string | undefined,
  subscriptionId: '' as string | undefined | number,
  subscriptionName: '' as string | undefined | null,
  isLoadingHTMLReport: false,
  reportHTML: '' as string | any,
  reportLazyHTML: '' as string | any,
  reportData: [] as any,
  isLoadingDeleteReport: false,
  parametersReportForDownloadFile: {} as ReportSaveRequest | {},
}

export type State = typeof initialState

type PropActions<T> = T extends { [key: string]: infer R } ? R : never
export type ReturnAnyActions<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<
  PropActions<T>
>

export type ReportAAnyActions = ReturnAnyActions<typeof actions>
export const reducer = (state: State = initialState, action: ReportAAnyActions): State => {
  switch (action.type) {
    case '@/GET_REPORTS_LAZY_MENU_ITEMS_REQUEST':
      return {
        ...state,
        isFetchingReports: true,
      }
    case '@/GET_REPORTS_MENU_ITEMS_REQUEST':
      return {
        ...state,
        isFetchingReports: true,
      }
    case '@GET_REPORTS_MENU_ITEMS_RESPONSE':
      return {
        ...state,
        isFetchingReports: false,
        allReportMenu: action.payload,
      }
    case '@GET_REPORTS_LAZY_MENU_ITEMS_RESPONSE':
      return {
        ...state,
        isFetchingReports: false,
        lazyReportMenu: action.payload,
      }
    case '@GET_DELETE_LAZY_REPORT_REQUEST':
      return {
        ...state,
        isLoadingDeleteReport: true,
      }
    case '@GET_DELETE_LAZY_REPORT_RESPONSE':
      return {
        ...state,
        lazyReportMenu: state.lazyReportMenu.filter(r => r.id !== action.payload),
      }
    case '@/GET_REPORTS_MENU_ITEMS_RESPONSE_FAIL':
      return {
        ...state,
        isFetchingReports: false,
        error: action.payload,
      }
    case '@/GET_REPORTS_MY_MENU_ITEMS_REQUEST':
      return {
        ...state,
        isFetchingReports: true,
      }
    case '@GET_REPORTS_MY_MENU_ITEMS_RESPONSE':
      return {
        ...state,
        isFetchingReports: false,
        myReportsMenu: action.payload,
      }
    case '@GET_SUBSCRIBE_REPORTS_RESPONSE':
      return {
        ...state,
        isFetchingSubscribeReports: false,
        subscriptions: action.payload,
      }
    case '@/GET_SUBSCRIBE_REPORTS_REQUEST':
      return {
        ...state,
        isFetchingSubscribeReports: true,
      }
    case '@/GET_SUBSCRIBE_REPORTS_RESPONSE_FAIL':
      return {
        ...state,
        isFetchingSubscribeReports: false,
        error: action.payload,
      }
    case '@GET_REPORT_REQUEST_BY_ID':
      return {
        ...state,
        isFetchingReportById: true,
      }
    case '@GET_REPORT_BY_ID_RESPONSE':
      return {
        ...state,
        isFetchingReportById: false,
        ReportsConfig: action.payload,
      }
    case 'GET_REPORT_BY_ID_RESPONSE_FALL':
      return {
        ...state,
        isFetchingReportById: false,
        error: action.payload,
      }
    case '@SET_SEARCH_FILTER':
      return {
        ...state,
        searchFilterReportByName: action.payload,
      }
    case '@GET_REPORTS_CONFIG_REQUEST':
      return {
        ...state,
        isFetchingReportsConfig: true,
      }
    case '@SET_INIT_REPORTS_CONFIG':
      return {
        ...state,
        ReportsConfig: null,
        // reportId: '',
        // reportUid: '',
        // subscriptionId: '',
        // subscriptionName: '',
      }
    case '@GET_REPORTS_CONFIG_RESPONSE':
      return {
        ...state,
        isFetchingReportsConfig: false,
        ReportsConfig: action.payload,
      }
    case '@GET_REPORTS_CONFIG_RESPONSE_FALL':
      return {
        ...state,
        isFetchingReportsConfig: false,
        error: action.payload,
      }
    case '@SET_REPORT_ID_UID':
      return {
        ...state,
        reportId: action.payload.id,
        reportUid: action.payload.uId,
        subscriptionId: action.payload.subscriptionId,
        subscriptionName: action.payload.subName,
      }
    case '@SAVE_REPORT_REQUEST':
      return {
        ...state,
        isLoadingHTMLReport: true,
      }
    case '@SAVE_REPORT_RESPONSE': {
      return {
        ...state,
        isLoadingHTMLReport: false,
        reportHTML: action.payload,
      }
    }
    case '@SAVE_REPORT_LAZY_REQUEST':
      return {
        ...state,
        isLoadingHTMLReport: true,
      }
    case '@SAVE_REPORT_LAZY_RESPONSE': {
      return {
        ...state,
        isLoadingHTMLReport: false,
        reportLazyHTML: action.payload,
      }
    }
    case '@SAVE_REPORT_DATA_LAZY_REQUEST':
      return {
        ...state,
        isLoadingHTMLReport: true,
      }
    case '@SAVE_DATA_REPORT_RESPONSE': {
      return {
        ...state,
        isLoadingHTMLReport: false,
        reportData: action.payload,
      }
    }
    case '@SAVE_REPORT_RESPONSE_FALL':
      return {
        ...state,
        isLoadingHTMLReport: false,
        error: action.payload,
      }
    case '@GET_HTML_REPORT_REQUEST':
      return {
        ...state,
        isLoadingHTMLReport: true,
      }
    case '@GET_DELETE_REPORT_REQUEST':
      return {
        ...state,
        isLoadingDeleteReport: true,
      }
    case '@GET_DELETE_REPORT_RESPONSE':
      return {
        ...state,
        subscriptions: state.subscriptions.filter(r => r.id !== action.payload),
        myReportsMenu: filterTree(state.myReportsMenu, action.payload),
      }
    case '@SET_DOWNLOAD_REPORT_PARAMETERS_FILE':
      return {
        ...state,
        parametersReportForDownloadFile: action.payload,
      }
    case '@@SET_INITIAL_DOWNLOAD_FILE_REPORT_PARAMETERS':
      return {
        ...state,
        parametersReportForDownloadFile: {},
      }
    // case '@/SIGN_OUT':
    //   return initialState
    default:
      return state
  }
}
