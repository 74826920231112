import { translate } from '@/utils/internalization'

export enum TableColumn {
  Product = 'app',
  Module = 'module',
  Form = 'form',
  Parent = 'parent',
  Date = 'date',
  Operation = 'operation',
  Object = 'object',
  Field = 'field',
  OldValue = 'oldValue',
  NewValue = 'newValue',
  Approve = 'approve',
  Reject = 'reject',
}
export const headerConfig = {
  [TableColumn.Product]: translate('translate#cm.Application'),
  [TableColumn.Module]: translate('translate#cm.Module'),
  [TableColumn.Form]: translate('translate#cm.Form'),
  [TableColumn.Parent]: translate('translate#cm.Parent'),
  [TableColumn.Date]: translate('translate#cm.Date'),
  [TableColumn.Operation]: translate('translate#cm.Operation'),
  [TableColumn.Object]: translate('translate#cm.Object'),
  [TableColumn.Field]: translate('translate#cm.FieldName'),
  [TableColumn.OldValue]: translate('translate#cm.OldValue'),
  [TableColumn.NewValue]: translate('translate#cm.NewValue'),
  [TableColumn.Approve]: translate('translate#cm.Approve'),
  [TableColumn.Reject]: translate('translate#cm.Reject'),
}
