import { Dispatch, SetStateAction } from 'react'

import { GroupingTemplate } from '@/api/atmeye/blackListApi/types'

import { TableGroup } from './components/SortBlock/types'

export interface Props {
  open: boolean
  defaultGrouping?: TableGroup[]
  appliedGrouping: TableGroup[]
  appliedGroupingTemplate: GroupingTemplate | null
  groupingTemplatesType: string
  groupsList: string[]
  groupingLevel?: number
  handleApplyGroupingTemplate: Dispatch<any>
  onApply: (groups: TableGroup[]) => void
  onClose: () => void
  onClear?: () => void
}

export enum MODAL_TYPE {
  MAIN = 'MAIN',
  SAVE = 'SAVE',
  EDIT = 'EDIT',
}
