import { AnyAction } from 'redux'

import { ComVaultReport, STeam, TeamGroup } from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants'

export interface State {
  teams: {
    data: TeamGroup[]
    isLoading: boolean
    isLoaded: boolean
    sortColumn?: string
    sortOrder?: string

    checkedTeams: {
      [key: string]: {
        name?: string
      }
    }
  }

  selectedTeam: STeam | null

  teamModal: {
    isOpen: boolean
    isEdit: boolean
  }

  isApplyingTeam: boolean

  reports: {
    errorMessage?: string
    isLoading: boolean
    list: ComVaultReport[]
    selectedReport: {
      isLoading: boolean
      errorMessage?: string
      bytes?: Blob | null
    }
  }
}

const initialState: State = {
  teams: {
    data: [],
    isLoading: false,
    isLoaded: false,
    sortColumn: undefined,
    sortOrder: undefined,
    checkedTeams: {},
  },
  selectedTeam: null,

  teamModal: {
    isOpen: false,
    isEdit: false,
  },

  isApplyingTeam: false,

  reports: {
    errorMessage: '',
    isLoading: false,
    list: [],
    selectedReport: {
      isLoading: false,
      errorMessage: '',
      bytes: undefined,
    },
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case VaultV1Actions.TmsDeleteTeamRequest:
    case VaultV1Actions.TmsGetTeamsRequest:
      return {
        ...state,
        teams: {
          ...state.teams,
          isLoading: true,
        },
      }
    case VaultV1Actions.TmsGetTeamsResponse:
      return {
        ...state,
        teams: {
          ...state.teams,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      }
    case VaultV1Actions.TmsGetTeamsFail:
      return {
        ...state,
        teams: {
          ...state.teams,
          isLoading: false,
          isLoaded: true,
        },
      }
    case VaultV1Actions.TmsSortTeams:
      return {
        ...state,
        teams: {
          ...state.teams,
          isLoading: true,
          sortColumn: payload.sortColumn,
          sortOrder: payload.sortOrder,
        },
      }

    case VaultV1Actions.TmsDeleteTeamResponse:
      return {
        ...state,
        teams: {
          ...state.teams,
          isLoading: false,
        },
        selectedTeam: null,
      }
    case VaultV1Actions.TmsDeleteTeamFail:
      return {
        ...state,
        teams: {
          ...state.teams,
          isLoading: false,
        },
      }
    case VaultV1Actions.TmsSetSelectedTeam:
      return {
        ...state,
        selectedTeam: payload,
      }

    case VaultV1Actions.TmsSetTeamModalOpen:
      return {
        ...state,
        teamModal: payload,
      }

    case VaultV1Actions.TmsEditTeamRequest:
    case VaultV1Actions.TmsAddTeamRequest:
      return {
        ...state,
        isApplyingTeam: true,
      }

    case VaultV1Actions.TmsAddTeamResponse:
    case VaultV1Actions.TmsEditTeamResponse:
    case VaultV1Actions.TmsEditTeamFail:
    case VaultV1Actions.TmsAddTeamFail:
      return {
        ...state,
        isApplyingTeam: false,
        teamModal: {
          ...state.teamModal,
          isOpen: false,
          isEdit: false,
        },
      }

    // [START] Reports ----------------------------------------------
    case VaultV1Actions.TmsGetReportsRequest:
      return {
        ...state,

        reports: {
          ...state.reports,
          isLoading: true,
          list: [],
          errorMessage: '',
          selectedReport: initialState.reports.selectedReport,
        },
      }
    case VaultV1Actions.TmsGetReportsResponse:
      return {
        ...state,
        reports: {
          ...state.reports,
          isLoading: false,
          list: payload,
          errorMessage: '',
        },
      }
    case VaultV1Actions.TmsGetReportsFail:
      return {
        ...state,
        reports: {
          ...state.reports,
          isLoading: false,
          list: [],
          errorMessage: payload,
        },
      }

    case VaultV1Actions.TmsGetReportRequest:
      return {
        ...state,
        reports: {
          ...state.reports,
          selectedReport: {
            ...state.reports.selectedReport,
            isLoading: true,
            errorMessage: '',
            bytes: undefined,
          },
        },
      }
    case VaultV1Actions.TmsGetReportResponse:
      return {
        ...state,
        reports: {
          ...state.reports,
          selectedReport: {
            ...state.reports.selectedReport,
            isLoading: false,
            errorMessage: '',
            bytes: payload,
          },
        },
      }
    case VaultV1Actions.TmsGetReportFail:
      return {
        ...state,
        reports: {
          ...state.reports,
          selectedReport: {
            ...state.reports.selectedReport,
            isLoading: false,
            errorMessage: payload,
            bytes: undefined,
          },
        },
      }
    // [END] Reports ----------------------------------------------

    case VaultV1Actions.SignOut:
      return initialState
    default:
      return state
  }
}
