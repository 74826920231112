import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromVaultV1AppConfig from './reducer'

export const getState = (state: AppState): fromVaultV1AppConfig.State => {
  return state.vaultV1Admin.appConfig.appConfig
}

export const getVaultsAdmAppConfig = createSelector(getState, state => state.appConfig)
export const getVaultsAdmLogo = createSelector(getState, state => state.logo)
