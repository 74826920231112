import { Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import CloseButton from '@/components/blocks/CloseButton/index'
import Loader from '@/components/blocks/Loader'

import { useClasses } from './styles'
import { Props } from './types'

const GlobalConfirmModal = ({
  open,
  isLoading,
  onClose,
  onConfirm,
  message,
  errorMessage,
  nameItem,
  subMessage,
  content,
  buttonsOptions,
  maxWidth = 'xs',
  classes: customClasses,
  disableBackdropClick,
  disableEscapeKeyDown,
  isButtonDisabled,
}: Props): React.ReactElement => {
  const classes = useClasses()

  const handleClose = (event: any, reason: 'backdropClick' | 'escapeKeyDown'): void | boolean => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return false
    }

    if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false
    }

    if (typeof onClose === 'function') {
      onClose()
    }
  }

  const loaderJSX = (isButtonDisabled || isLoading) && (
    <div className={classes.loaderWrapper}>
      <Loader />
    </div>
  )

  return (
    <Dialog maxWidth={maxWidth} open={open} onClose={handleClose}>
      <div className={clsx(classes.wrapper, customClasses?.contentWrapper)}>
        {loaderJSX}
        <CloseButton absolute className={classes.closeButton} onClick={onClose} />

        <DialogTitle className={clsx(customClasses?.message, classes.title)}>{message}</DialogTitle>

        {subMessage && (
          <Typography className={customClasses?.subMessage} variant="body2">
            {subMessage}
          </Typography>
        )}

        {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}

        {nameItem && <DialogTitle>{nameItem}</DialogTitle>}

        {content}

        {!buttonsOptions?.isHideButtons && (
          <DialogActions className={clsx(classes.buttonWrapper, customClasses?.buttonsWrapper)}>
            <Button
              onClick={onClose}
              variant={buttonsOptions?.no?.variant || 'contained'}
              className={clsx(classes.noButton, customClasses?.noButton)}
              disabled={isButtonDisabled}
            >
              <FormattedMessage
                id={`action.${buttonsOptions?.no?.text?.toLowerCase() || 'no'}`}
                defaultMessage={buttonsOptions?.no?.text || 'No'}
              />
            </Button>

            <Button
              onClick={onConfirm}
              variant={buttonsOptions?.yes?.variant || 'contained'}
              className={clsx(classes.yesButton, customClasses?.yesButton)}
              autoFocus
              disabled={isButtonDisabled}
            >
              <FormattedMessage
                id={`action.${buttonsOptions?.yes?.text?.toLowerCase() || 'yes'}`}
                defaultMessage={buttonsOptions?.yes?.text || 'Yes'}
              />
            </Button>
          </DialogActions>
        )}
      </div>
    </Dialog>
  )
}
export default GlobalConfirmModal
