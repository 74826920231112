import { alpha, makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  modal: {
    padding: spacing(2, 4, 4),
    minWidth: spacing(79),
    height: spacing(105),
  },
  form: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  closeButton: {
    cursor: 'pointer',
  },
  button: {
    minWidth: spacing(17),
    marginLeft: spacing(1),
    margin: spacing(0),

    '&.MuiButton-root.Mui-disabled': {
      color: '#ffffff',
      backgroundColor: alpha(palette.primary.main, 0.5),
    },
  },
  buttonsContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    bottom: spacing(0),
  },
  buttonCancel: {
    marginLeft: 0,
  },
  filter: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '380px',
    position: 'absolute',
    left: 0,
    '& div:first-child': {
      width: '280px',
      margin: 0,
    },
  },
  templateSelect: {
    width: '30%',

    '& [class*="makeStyles-icon"]': {
      color: '#041517',
      fontSize: '1.4rem',
    },
    '& .MuiSelect-select': {
      fontSize: '14px',
    },
  },
  iconButton: {
    width: '40px',
    height: '40px',
    padding: '0',
    minWidth: 'auto',
    '&[disabled]': {
      filter: 'grayscale(100%)',
      borderColor: '#929A9B',
    },
  },
  buttonBack: {
    paddingRight: spacing(4),
    '&.MuiButton-root.Mui-disabled': {
      color: alpha(palette.primary.main, 0.4),
      backgroundColor: 'transparent',
      border: `1px solid ${alpha(palette.primary.main, 0.4)}`,
    },
  },
  buttonNext: {
    paddingLeft: spacing(4),
  },
  buttonLeftIcon: {
    marginRight: spacing(1),
  },
  buttonRightIcon: {
    marginLeft: spacing(1),
  },
  headerMenu: {
    display: 'flex',
    margin: '10px 0 10px 0',
  },
  stylesButton: {
    display: 'flex',
    alignItems: 'center',
    background: '#F9F9F9',
    borderRadius: '3px',
    marginRight: '16px',
    padding: '6px 8px',
    fontSize: '14px',
    fontStyle: 'normal',
    color: '#041517',
    cursor: 'pointer',
  },
  activeButton: {
    color: '#FFFFFF',
    backgroundColor: '#25ADBF',
  },
  disabledButton: {
    color: '#CAD5D7',
    backgroundColor: '#ECF2F2',
  },
}))
