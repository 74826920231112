import { AdmUserNetworkTreeNode } from '@/api/vault-v1/swaggerGeneratedApi'

export const countSubLeafs = (root: AdmUserNetworkTreeNode): number => {
  let count = 0
  if (root.folders?.length) {
    count += root.folders.length

    root.folders.forEach(node => {
      count += countSubLeafs(node)
    })
  }
  return count
}

export const getAllChildIds = (folders: AdmUserNetworkTreeNode[]): number[] => {
  if (Array.isArray(folders)) {
    return folders.reduce((done: number[], current) => {
      if (current?.id) {
        const elem = getAllChildIds(current?.folders || [])
        return done.concat([current.id, ...elem])
      }
      return []
    }, [])
  } else {
    return folders
  }
}

export const getOneLevel = (root: AdmUserNetworkTreeNode[]): AdmUserNetworkTreeNode[] => {
  return (root || []).reduce((done: AdmUserNetworkTreeNode[], current) => {
    const { id, isSelected, folders, name } = current || {}

    if (id) {
      const elem = getOneLevel(folders || [])

      const body = { id, isSelected, name }
      return done.concat([body || [] || '', ...elem])
    }
    return []
  }, [])
}

export const getAllCheckedIds = (folders: AdmUserNetworkTreeNode[]): number[] => {
  if (Array.isArray(folders)) {
    return folders.reduce((done: number[], current) => {
      if (current?.id) {
        const checkedIds = getAllCheckedIds(current?.folders || [])

        if (current.isSelected) {
          return done.concat([current.id, ...checkedIds])
        } else {
          return done.concat([...checkedIds])
        }
      }
      return []
    }, [])
  } else {
    return folders
  }
}
