import { AppNavigationRoutes, PRODUCTS_BLOCK_PREFIX } from '@/constants'
import ProductsListIcon from '@/assets/products/icons/products-list.svg'
import ImportIcon from '@/assets/products/icons/import.svg'

export default [
  {
    path: `${PRODUCTS_BLOCK_PREFIX}${AppNavigationRoutes.ProductsListPage}`,
    label: 'translate#prod.productsList',
    Icon: ProductsListIcon,
  },
  {
    path: `${PRODUCTS_BLOCK_PREFIX}${AppNavigationRoutes.ProductsImport}`,
    label: 'translate#title.importProducts',
    Icon: ImportIcon,
  },
]
