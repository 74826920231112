import { LocalStorageItems } from '@/constants'

import { Strategy } from './types'

class StrategySS implements Strategy {
  get(key: string): any {
    const savedData = window.sessionStorage.getItem(key)
    return savedData ? JSON.parse(savedData) : ''
  }

  set(key: string, value: any): any {
    const newData = JSON.stringify(value)
    window.sessionStorage.setItem(key, newData)
  }

  remove(key: LocalStorageItems): any {
    window.sessionStorage.removeItem(key)
  }
}

export default new StrategySS()
