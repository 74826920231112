import { Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Test from 'react-test-attributes'

import CloseButton from '@/components/blocks/CloseButton/index'
import { TextInput } from '@/components/controls/TextInput/component'
import { detectTicketTabLocation } from '@/components/pages/sd/helpers/sdHelpers'
import { TableGroup } from '@/components/pages/sd/TicketsPage/components/GroupingModal/types'
import { useDebounceInput } from '@/components/pages/sd/TicketsPage/helpers/hooks/useDebounceInput'
import { getGroupingTemplates, saveSdGroupTemplateRequest } from '@/store/sd/tickets/index'
import { ApiGroupTemplate, ApiOrderBy, ApiOrderType, GroupingTemplate } from '@/types'
import { getTestId } from '@/utils'
import { useTranslate } from '@/utils/internalization'
import { PopUpService } from '@/utils/services/popUpService'

import { Props } from './types'

const SaveGroupModal = ({
  classes,
  currentGrouping,
  testIdPrefix,
  handleClose,
  setSavedTemplate,
}: Props): React.ReactElement => {
  const location = useLocation()
  const dispatch = useDispatch()
  const templates = useSelector(getGroupingTemplates)
  const translate = useTranslate()
  const idPrefix = `${testIdPrefix}-save-group-modal`
  const testId = getTestId(idPrefix)
  const { inputValue, inputErrors, handleChangeInputValue } = useDebounceInput('')
  const setWindowType = useCallback(() => {
    return detectTicketTabLocation(location)
  }, [location])

  const handleSaveTemplate = useCallback((): void => {
    if (templates.find((temp: GroupingTemplate) => temp.name === inputValue)) {
      PopUpService.showGlobalPopUp(`translate#title.sameTemplateName`, 'warning')
    } else {
      const formattedGroups: ApiGroupTemplate[] = currentGrouping.map((gr: TableGroup) => ({
        groupingKey: gr.key,
        orderBy: gr.orderBy.toUpperCase() as ApiOrderBy,
        orderType: gr.order.toUpperCase() as ApiOrderType,
      }))
      dispatch(
        saveSdGroupTemplateRequest(
          {
            windowType: setWindowType(),
            name: inputValue,
            groupingLevels: formattedGroups,
          },
          translate,
        ),
      )

      setSavedTemplate(inputValue)
      handleClose()
    }
  }, [dispatch, handleClose, currentGrouping, inputValue])

  return (
    <>
      <Test id={testId(0)}>
        <CloseButton absolute className={classes.closeButton} onClick={handleClose} />
      </Test>
      <Test id={testId(1)}>
        <Typography className={classes.subtitle}>
          <FormattedMessage
            id="title.saveCustomGroupTemplate"
            defaultMessage="Save custom group template"
          />
        </Typography>
      </Test>
      <Test id={testId(2)}>
        <TextInput
          value={inputValue}
          className={classes.input}
          onChange={handleChangeInputValue}
          autoFocus
          label={translate('translate#title.enterTemplateName')}
          errorText={inputErrors}
        />
      </Test>
      <Test id={testId(3)}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Test id={testId(4)}>
            <Grid item>
              <Test id={testId(5)}>
                <Button
                  onClick={handleSaveTemplate}
                  className={classes.button}
                  variant="contained"
                  disabled={!!inputErrors || !inputValue.length}
                >
                  <FormattedMessage id="action.save" defaultMessage="Save" />
                </Button>
              </Test>
            </Grid>
          </Test>
        </Grid>
      </Test>
    </>
  )
}

export default SaveGroupModal
