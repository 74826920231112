import { makeStyles } from '@material-ui/core'

import colors from '@/theme/colors'

export const useClasses = makeStyles(({ palette }) => ({
  orderRow: {
    backgroundColor: '#25adbf85 !important',
  },
  tableRow: {
    cursor: 'pointer',
    '&:nth-child(even)': {
      backgroundColor: colors.backgroundHeaderMenu,
    },
  },
  tableCell: {
    whiteSpace: 'nowrap',
    paddingLeft: '10px',
    paddingRight: '10px',
    '&:first-of-type': {
      paddingLeft: '45px',
      minWidth: '120px',
    },
    '&:nth-child(2)': {
      minWidth: '70px',
    },
    '&:nth-child(3)': {
      minWidth: '140px',
    },
    '&:nth-child(4)': {
      minWidth: '120px',
    },
  },
  rowTitle: {
    paddingBottom: 0,
    paddingTop: 0,
    '&:first-of-type': {
      paddingLeft: '10px',
    },
  },
  title: {
    fontSize: '14px',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    fontWeight: 500,
    width: 'calc(100% - 30px)',
  },
  icon: {
    color: palette.primary.dark,
  },
  emptyCell: {
    width: '100%',
  },
}))
