import { ValidationService } from '@/utils/services/validationService'
import { ValidationErrors } from '@/constants/forms/validationErrors'
import {
  AddUserGroupCardValues,
  AddNewUserGroupCardField,
} from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/AddUserGroupModal/types'
import { VALID_USER_GROUP_NAME } from '@/constants/usm/usmModule'

export const validate = (values: AddUserGroupCardValues): Partial<AddUserGroupCardValues> => {
  const errors: Partial<AddUserGroupCardValues> = {}
  if (!ValidationService.isRequired(values[AddNewUserGroupCardField.UserGroupName])) {
    errors[AddNewUserGroupCardField.UserGroupName] = ValidationErrors.Required
  }

  if (
    ValidationService.isCertainSpecialSymbols(
      values[AddNewUserGroupCardField.UserGroupName],
      VALID_USER_GROUP_NAME,
    )
  ) {
    errors[AddNewUserGroupCardField.UserGroupName] = ValidationErrors.UnacceptableSymbols
  }

  if (
    !ValidationService.isRequired(values[AddNewUserGroupCardField.UserGroup]) &&
    values[AddNewUserGroupCardField.ClonePermission]
  ) {
    errors[AddNewUserGroupCardField.UserGroup] = ValidationErrors.Required
  }

  if (ValidationService.isMaxLength(values[AddNewUserGroupCardField.UserGroupName], 80)) {
    errors[AddNewUserGroupCardField.UserGroupName] = ValidationErrors.MaxLengthOfFields
  }

  if (ValidationService.isMaxLength(values[AddNewUserGroupCardField.Description], 200)) {
    errors[AddNewUserGroupCardField.UserGroupName] = ValidationErrors.MaxLengthOfDescriptionFields
  }

  if (
    ValidationService.required(
      values[AddNewUserGroupCardField.UserGroup],
      values[AddNewUserGroupCardField.ClonePermission],
    )
  ) {
    errors[AddNewUserGroupCardField.UserGroup] = ValidationErrors.Required
  }

  return errors
}
