import React from 'react'
import { useSelector } from 'react-redux'
import AppTable from '@/components/controls/AppTable'
import { getCommonTicketInfo } from '@/store/sd/drawerActions/selectors'
import { DocumentInformationFields } from '@/components/blocks/PSRTicketDetails/components/DocumenInformationModal/components/DocumenInformationTable/types'

import { useClasses } from './styles'

const CommonInformationTable = (): React.ReactElement => {
  const classes = useClasses()

  const commonTicketInfo = useSelector(getCommonTicketInfo)

  return (
    <AppTable
      columnDisplayOrder={[
        DocumentInformationFields.CustomerRequestNumber,
        DocumentInformationFields.ServiceCompany,
        DocumentInformationFields.Date,
      ]}
      isLoading={false}
      data={commonTicketInfo}
      tableClasses={{
        paper: classes.tableWrap,
      }}
    />
  )
}

export default CommonInformationTable
