import queryString from 'query-string'

import {
  AdmCollectionDayBase,
  AdmCollectionDaysResponse,
  ComAdminBranchAndObjectFilter,
} from '@/api/cm/swaggerGeneratedApi'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class CollectionDaysApi {
  private static requestService = getRequestServiceCM()

  public static async get(
    filter: ComAdminBranchAndObjectFilter,
  ): Promise<AdmCollectionDaysResponse> {
    try {
      return await CollectionDaysApi.requestService.post('/admin/collectionDays', filter)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async update(days: AdmCollectionDayBase[], guid: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/collectionDays/update',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )

      return await CollectionDaysApi.requestService.put(url, days)
    } catch (error) {
      throw new Error(error)
    }
  }
}
