import { CurrentDeviceTypes } from '@/components/pages/atmeye/Devices/Components/MapPageWrapper/types'

export enum FilterTypeDisplayName {
  Text = 'TEXT',
  List = 'LIST',
  Range = 'RANGE',
  YesNo = 'YESNO',
  YesNoAll = 'YESNOALL',
  Checkbox = 'CHECKBOX',
  RadioGroup = 'RADIOGROUP',
  Date = 'DATE',
  ZonedDate = 'ZONED_DATETIME',
}

export enum FilterValueTypes {
  String = 'STRING',
  Number = 'NUMBER',
  Datetime = 'DATETIME',
  Boolean = 'BOOLEAN',
  Integer = 'INTEGER',
  Date = 'DATE',
  Time = 'TIME',
  Checkbox = 'CHECKBOX',
  ZonedDate = 'ZONED_DATETIME',
}

export enum EnableModes {
  Always = 'ALWAYS',
  SomeParentIsSet = 'SOME_PARENT_IS_SET',
  AllParentIsSet = 'ALL_PARENT_IS_SET',
}

export type FilterParameterDispalyType =
  | FilterTypeDisplayName.Text
  | FilterTypeDisplayName.List
  | FilterTypeDisplayName.Range
  | FilterTypeDisplayName.YesNo
  | FilterTypeDisplayName.YesNoAll
  | FilterTypeDisplayName.Checkbox
  | FilterTypeDisplayName.RadioGroup
  | FilterTypeDisplayName.Date
  | FilterTypeDisplayName.ZonedDate

export type FilterValueType =
  | FilterValueTypes.String
  | FilterValueTypes.Number
  | FilterValueTypes.Date
  | FilterValueTypes.Datetime
  | FilterValueTypes.Boolean
  | FilterValueTypes.Integer
  | FilterValueTypes.Time
  | FilterValueTypes.Checkbox
  | FilterValueTypes.ZonedDate

export type EnableModeType =
  | EnableModes.Always
  | EnableModes.AllParentIsSet
  | EnableModes.SomeParentIsSet

export interface FilterConfig {
  config: {
    filter: FilterParameter[]
  }
}

export interface LookupValue {
  display: string
  value: string
}

export interface FilterFieldLookupInfo {
  allowRecursiveCallsBySearchFragment: boolean
  dependedOn: string[]
  displayFieldName: string
  emptyValue?: LookupValue
  enableMode: EnableModeType
  entityName: string
  getValuesURL: string
  lookupValues: LookupValue[]
  valueFieldName: string
  valueFieldType: FilterValueType
  defaultValue?: string
  resetDependencies?: string[]
  loadLookupValuesOnClick?: boolean
}

export interface FilterParameter {
  allowMultipleSelect?: boolean
  allowValidation?: boolean
  displayType: FilterParameterDispalyType
  dropdownListEntityType?: string
  label: string
  labelKey: string
  hideEmptyItem?: boolean
  clearButton?: boolean
  lookupInfo?: FilterFieldLookupInfo | null
  parameterName: string
  toolTip: string
  toolTipKey: string
  valueType: FilterValueType
  isVisible?: boolean
  additionalProperties?: { transactionsSearchDays?: number } | null
}

export type FiltersFromURLTypes<T> = {
  filterObject: T
}

export type CurrentDeviceParamFromURL = {
  currentDevice: CurrentDeviceTypes
}
