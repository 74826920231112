import { ReactElement, useMemo } from 'react'

interface Props {
  tableId?: string
  rowId?: string
  typeKey?: string
  entriesKey?: string
  rowGroupKey?: string

  columnsToSort?: string[]
  columnsToCollapse?: string[]
  cellWithNumberValue?: string[]
  headerConfig: { [key: string]: string | ReactElement }
  defaultColumns?: string[]
  fixedColumns?: string[]

  fitTableContent?: boolean
  withShadow?: boolean
}

export const usePrepareTableConfig = (props: Props): Props => {
  const {
    headerConfig,
    columnsToSort,
    columnsToCollapse,
    cellWithNumberValue,
    defaultColumns,
    fixedColumns,

    ...rest
  } = props

  const columnsToSortMemo = useMemo(() => columnsToSort, [columnsToSort])
  const columnsToCollapseMemo = useMemo(() => columnsToCollapse, [columnsToCollapse])
  const cellWithNumberValueMemo = useMemo(() => cellWithNumberValue, [cellWithNumberValue])
  const headerConfigMemo = useMemo(() => headerConfig, [headerConfig])
  const defaultColumnsMemo = useMemo(() => defaultColumns, [defaultColumns])
  const fixedColumnsMemo = useMemo(() => fixedColumns, [fixedColumns])

  return {
    ...rest,

    columnsToSort: columnsToSortMemo,
    columnsToCollapse: columnsToCollapseMemo,
    cellWithNumberValue: cellWithNumberValueMemo,
    headerConfig: headerConfigMemo,
    defaultColumns: defaultColumnsMemo,
    fixedColumns: fixedColumnsMemo,
  }
}
