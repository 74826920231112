// core
/* eslint-disable react/jsx-handler-names */
import { Grid } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { Alert } from '@material-ui/lab'
import React, { FC, ReactElement } from 'react'

// components
import CloseButton from '@/components/blocks/CloseButton/index'
import { AutocompleteCM, DraggableDialog } from '@/components/blocks/cm'
import SuccessDialog from '@/components/blocks/SuccessDialog'
import { createDropDownInputField, DropDownInput } from '@/components/controls/DropDownInput'
import { Select } from '@/components/controls/Select'
import UserInformationTable from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/components/UserInformationTable'
import { useUserInfoTable } from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/AddUserModal/hooks/table'
import {
  CreateButton,
  CreatedCancelButton,
} from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/helpers/createButton'
import { useMergeSecurityNetworksModal } from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/MergeSecurityNetworksModal/hooks'
import { WARNING_NO_OBJECTS_FOR_MERGE } from '@/constants/usm/usmModule'
// hooks
import { useTranslate } from '@/utils/internalization'

// styles
import { useClasses } from './styles'
// types
import {
  headersSecurityNetworksObjectsMergeSummaryTable,
  headersSecurityNetworksObjectsMergeTable,
  Props,
} from './types'

const MergeSecurityNetworksModalUSM: FC<Props> = ({
  open,
  handleClose,
  disableBackdropClick,
  disableEscapeKeyDown,
}: Props): ReactElement => {
  const {
    handleCloseForm,
    commonMergeObjectsList,
    isFetching,
    usersCopyTo,
    errorMessages,
    usersCopyFrom,
    handleClickSelectRequest,
    isLoadingSecurityObjectsSummary,
    securityObjectsMergeList,
    success,
    currentUserFrom,
    currentUserTo,
    selectedRows,
    changedRows,
    newRows,
    handleAddNewRow,
    handleDeleteRows,
    handleSelectRow,
    handleChangeRow,
    deletedRows,
    handleMergeObjects,
  } = useMergeSecurityNetworksModal({ handleClose })

  const onClose = (event: any, reason: 'backdropClick' | 'escapeKeyDown'): void | boolean => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return false
    }

    if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false
    }

    if (typeof handleClose === 'function') {
      handleClose()
    }
  }

  const classes = useClasses({
    isUsersValues: !!currentUserFrom.user && currentUserTo.user,
  })
  const translate = useTranslate()
  const objectWillBeAddedTable = useUserInfoTable(undefined, true)

  const errorJSX =
    !!errorMessages.length &&
    !errorMessages.includes(WARNING_NO_OBJECTS_FOR_MERGE) &&
    errorMessages.map(e => (
      <Alert severity="error" key={e}>
        {e}
      </Alert>
    ))

  const warningJSX =
    !!errorMessages.length &&
    errorMessages.includes(WARNING_NO_OBJECTS_FOR_MERGE) &&
    errorMessages.map(e => (
      <Alert severity="info" key={e}>
        {translate(e)}
      </Alert>
    ))

  return (
    <>
      <Dialog maxWidth="md" fullWidth open={open} keepMounted onClose={handleClose}>
        <div className={classes.wrapper}>
          <p className={classes.headerTitle}>
            {translate('translate#usm.side.menu.title.merge.users.security.networks')}
          </p>
          <CloseButton onClick={handleCloseForm} className={classes.closingWindow} />
          {errorJSX}
          {warningJSX}

          <div className={classes.form}>
            <div className={classes.mergeObjectsInputsWrapper}>
              <Grid item md={12}>
                <DropDownInput
                  options={usersCopyFrom.map(({ name, value }) => ({
                    name: name,
                    value: value,
                  }))}
                  value={currentUserFrom.user}
                  onChange={currentUserFrom.handleChange}
                  label={translate(
                    'translate#usm.title.merge.security.networks.modal.label.from.user',
                  )}
                  placeholder={translate(
                    'translate#usm.title.merge.security.networks.modal.placeholder.from.user',
                  )}
                />
                {/* <Select
                  options={usersCopyFrom}
                  label={translate(
                    'translate#usm.title.merge.security.networks.modal.label.from.user',
                  )}
                  placeholder={translate(
                    'translate#usm.title.merge.security.networks.modal.placeholder.from.user',
                  )}
                  value={currentUserFrom.user}
                  onChange={currentUserFrom.handleChange}
                /> */}
              </Grid>
              <Grid item md={12}>
                <DropDownInput
                  options={usersCopyTo.map(({ name, value }) => ({
                    name: name,
                    value: value,
                  }))}
                  disabled={!currentUserFrom.user}
                  onChange={currentUserTo.handleChange}
                  value={currentUserTo.user}
                  placeholder={translate(
                    'translate#usm.title.merge.security.networks.modal.placeholder.from.user',
                  )}
                  label={translate(
                    'translate#usm.title.merge.security.networks.modal.label.to.user',
                  )}
                />
                {/* <Select
                  options={usersCopyTo}
                  placeholder={translate(
                    'translate#usm.title.merge.security.networks.modal.placeholder.from.user',
                  )}
                  label={translate(
                    'translate#usm.title.merge.security.networks.modal.label.to.user',
                  )}
                  disabled={!currentUserFrom.user}
                  onChange={currentUserTo.handleChange}
                  value={currentUserTo.user}
                /> */}
              </Grid>
            </div>
            {currentUserFrom.user && currentUserTo.user && (
              <p>
                {translate('translate#usm.side.menu.title.please.select.object.types.to.be.added')}
              </p>
            )}
            <div className={classes.mergeObjectsWrapper}>
              <Grid item md={12}>
                {currentUserFrom.user && currentUserTo.user && (
                  <div className={classes.mergeObjectsTableWrapper}>
                    <UserInformationTable
                      isLoading={isFetching}
                      newRows={newRows}
                      deletedRows={deletedRows}
                      changedRows={changedRows}
                      selectedRows={selectedRows}
                      handleAddNewRow={handleAddNewRow}
                      handleChangeRow={handleChangeRow}
                      handleDeleteRows={handleDeleteRows}
                      handleSelectRow={handleSelectRow}
                      headers={headersSecurityNetworksObjectsMergeTable}
                      data={commonMergeObjectsList || []}
                      checkboxColumnsNames={['checkbox']}
                    />
                  </div>
                )}
              </Grid>

              {currentUserFrom.user && currentUserTo.user && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    onClick={handleClickSelectRequest}
                    disabled={
                      !selectedRows.length || (isFetching && isLoadingSecurityObjectsSummary)
                    }
                    size="small"
                  >
                    <ArrowForwardIosIcon classes={{ root: classes.iconWidth }} />
                    <ArrowForwardIosIcon classes={{ root: classes.iconWidth }} />
                  </IconButton>
                </div>
              )}

              <Grid item md={12}>
                {currentUserFrom.user && currentUserTo.user && (
                  <div className={classes.mergeObjectsTableWrapper}>
                    <UserInformationTable
                      isLoading={isLoadingSecurityObjectsSummary}
                      newRows={objectWillBeAddedTable.newRows}
                      deletedRows={objectWillBeAddedTable.deletedRows}
                      changedRows={objectWillBeAddedTable.changedRows}
                      selectedRows={objectWillBeAddedTable.selectedRows}
                      handleAddNewRow={objectWillBeAddedTable.handleAddNewRow}
                      handleChangeRow={objectWillBeAddedTable.handleChangeRow}
                      handleDeleteRows={objectWillBeAddedTable.handleDeleteRows}
                      handleSelectRow={objectWillBeAddedTable.handleSelectRow}
                      headers={headersSecurityNetworksObjectsMergeSummaryTable}
                      data={securityObjectsMergeList || []}
                    />
                  </div>
                )}
              </Grid>
            </div>
            <div className={classes.buttonsStyle}>
              <CreatedCancelButton style={classes.cancelButton} handleClose={handleCloseForm} />
              <CreateButton
                translate={{ id: 'usm.title.add.user.modal.action.merge', message: 'Merge' }}
                value=""
                styleForButtons={classes.saveButton}
                isDisabled={
                  !(
                    currentUserFrom.user &&
                    currentUserTo.user &&
                    securityObjectsMergeList.length
                  ) ||
                  (isLoadingSecurityObjectsSummary && isFetching)
                }
                callBack={() => handleMergeObjects}
              />
            </div>
          </div>
        </div>
      </Dialog>
      <SuccessDialog
        onClose={handleCloseForm}
        open={success}
        title={translate('translate#usm.title.merge.is.successful')}
      />
    </>
  )
}

export default MergeSecurityNetworksModalUSM
