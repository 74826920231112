import { useCallback, useEffect, useState } from 'react'

import { debounceUpdateFrames, getFrameSizeLocalStorage } from '@/helpers/framesLocalStorage'

interface Props {
  heightKey?: string
  widthKey?: string
}

type TSplitter = HTMLDivElement | undefined

interface UseSplitter {
  panelHeight?: number
  panelWight?: number

  setSplitPane: (panelId: string) => (value: number) => void

  collapsedRow: boolean
  collapsedColumn: boolean

  rowDoubleClickRef: (panel?: any) => void
  columnDoubleClickRef: (panel?: any) => void
}

export const useSplitter = ({ heightKey, widthKey }: Props): UseSplitter => {
  const { pathname } = window.location
  const [, baseUrl] = pathname.split('/')

  const localStorageHeight = heightKey ? getFrameSizeLocalStorage(baseUrl)(heightKey) : null
  const localStorageWidth = widthKey ? getFrameSizeLocalStorage(baseUrl)(widthKey) : null

  const [collapsedColumn, setCollapsedColumn] = useState(false)
  const [collapsedRow, setCollapsedRow] = useState(false)

  // Splitter Ref --------------------------------------------
  const [verticalSplitter, setVerticalSplitter] = useState<TSplitter>(undefined)
  const [horizontalSplitter, setHorizontalSplitter] = useState<TSplitter>(undefined)

  // Handle double click on the splitter --------------------------------------------
  const handleToggleHeight = useCallback(() => {
    setCollapsedRow(!collapsedRow)
  }, [collapsedRow])

  const handleToggleWidth = useCallback(() => {
    setCollapsedColumn(!collapsedColumn)
  }, [collapsedColumn])

  // Handle resize --------------------------------------------
  const setSplitPane = useCallback(
    (panelId: string) => (value: number): void => {
      if (value) {
        collapsedColumn && panelId === widthKey && setCollapsedColumn(false)
        collapsedRow && panelId === heightKey && setCollapsedRow(false)
        debounceUpdateFrames(baseUrl)(panelId, value)
      }
    },
    [collapsedColumn, collapsedRow, baseUrl],
  )
  // Subscribe to the double click --------------------------------------------
  useEffect(() => {
    horizontalSplitter && horizontalSplitter.addEventListener('dblclick', handleToggleHeight)
    return (): void => horizontalSplitter?.removeEventListener('dblclick', handleToggleHeight)
  }, [horizontalSplitter, collapsedRow])

  useEffect(() => {
    verticalSplitter && verticalSplitter.addEventListener('dblclick', handleToggleWidth)
    return (): void => verticalSplitter?.removeEventListener('dblclick', handleToggleWidth)
  }, [verticalSplitter, collapsedColumn])

  // Add splitter Ref to the state --------------------------------------------
  const addRowDoubleClick = useCallback(
    (panel: any) => {
      if (panel?.splitter && !horizontalSplitter) {
        setHorizontalSplitter(panel.splitter)
      }
    },
    [horizontalSplitter],
  )

  const addColumnDoubleClick = useCallback(
    (panel: any) => {
      if (panel?.splitter && !verticalSplitter) {
        setVerticalSplitter(panel.splitter)
      }
    },
    [verticalSplitter],
  )

  return {
    panelHeight: localStorageHeight || undefined,
    panelWight: localStorageWidth || undefined,
    setSplitPane,
    collapsedRow,
    collapsedColumn,
    columnDoubleClickRef: addColumnDoubleClick,
    rowDoubleClickRef: addRowDoubleClick,
  }
}

interface PanelsProps {
  panelIds: string[]
}

interface Result {
  [key: string]: {
    size?: number
    isCollapsed: boolean
    handleSize: (value: number) => void
    ref: (panel: any) => void
  }
}

// =============================================================================================

export const useCreateSplitters = ({ panelIds }: PanelsProps): Result => {
  const { pathname } = window.location
  const [, baseUrl] = pathname.split('/')

  const createdSplitters: Result = {}

  const [collapsed, setCollapsed] = useState<{ [key: string]: boolean }>({})

  const [splitterRefs, setSplitterRefs] = useState<{ [key: string]: TSplitter }>({})

  const handleCollapse = useCallback(
    panelId => (): void => {
      setCollapsed(prev => ({ ...prev, [panelId]: !prev[panelId] }))
    },
    [],
  )

  useEffect(() => {
    Object.keys(splitterRefs).forEach(panelId =>
      splitterRefs[panelId]?.addEventListener('dblclick', handleCollapse(panelId)),
    )
    return (): void =>
      Object.keys(splitterRefs).forEach(panelId =>
        splitterRefs[panelId]?.removeEventListener('dblclick', handleCollapse(panelId)),
      )
  }, [splitterRefs])

  const setSplitPane = useCallback(
    (panelId: string) => (value: number): void => {
      if (panelId && value) {
        setCollapsed(prev => ({ ...prev, [panelId]: false }))
        debounceUpdateFrames(baseUrl)(panelId, value)
      }
    },
    [baseUrl],
  )

  const createRef = useCallback(
    (panelId: string) => (panel: any): void => {
      const { splitter } = panel || {}

      if (panelId && splitter && !splitterRefs[panelId]) {
        setCollapsed(prev => ({ ...prev, [panelId]: false }))
        setSplitterRefs(prevRefs => ({ ...prevRefs, [panelId]: splitter }))
      }
    },
    [splitterRefs],
  )

  panelIds.forEach(
    panelId =>
      (createdSplitters[panelId] = {
        size: getFrameSizeLocalStorage(baseUrl)(panelId) || undefined,
        isCollapsed: collapsed[panelId],
        handleSize: setSplitPane(panelId),
        ref: createRef(panelId),
      }),
  )

  return createdSplitters
}
