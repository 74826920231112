import queryString from 'query-string'

import {
  AdmAccessRulesAppModulePermissions,
  AdmAccessRulesAppModulePermissionsResponse,
  AdmAccessRulesRolePermissions,
  AdmCashCassetteType,
} from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class RolesPermissionsApi {
  private static requestService = getRequestServiceCM()

  public static async get(sort: Sort): Promise<AdmCashCassetteType[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/accessRules',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )
      return await RolesPermissionsApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  // modules
  public static async getModules(
    guid: string,
  ): Promise<AdmAccessRulesAppModulePermissionsResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/accessRules/appModules',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )
      return await RolesPermissionsApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setModules(payload: string): Promise<AdmAccessRulesAppModulePermissions> {
    try {
      return await RolesPermissionsApi.requestService.put(
        '/admin/accessRules/appModules/update',
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  // permissions
  public static async getPermissions(guid: string): Promise<AdmAccessRulesRolePermissions> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/accessRules/permissions',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )
      return await RolesPermissionsApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setPermissions(payload: string): Promise<AdmAccessRulesRolePermissions> {
    try {
      return await RolesPermissionsApi.requestService.put(
        '/admin/accessRules/permissions/update',
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  // charts
  public static async getCharts(guid: string): Promise<AdmAccessRulesRolePermissions> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/accessRules/charts',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )
      return await RolesPermissionsApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setCharts(payload: string): Promise<void> {
    try {
      return await RolesPermissionsApi.requestService.put(
        '/admin/accessRules/charts/update',
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }
}
