import { useColumnsMenu } from '@/components/NPMPakage/components/tableComponents/UITable/Components/ColumnsMenu/hooks/useColumnsMenu'
import { useUITablePagination } from '@/components/NPMPakage/components/tableComponents/UITable/hooks/useUITablePagination'
import { useColumnsWithoutConfig } from '@/components/NPMPakage/hooks/useColumnsWithoutConfig/useColumnsWithoutConfig'
import { useSorting } from '@/components/NPMPakage/hooks/useSorting/useSorting'
import { useTablePageWithoutConfigTypes } from '@/components/NPMPakage/hooks/useTablePageWithoutConfig/types'
import { useSelectedFilters } from '@/components/pages/atmeye/Administration/LicenseList/ATMCoreLicenses/components/FiltersModalLicence/hooks/useSelectedFilters'

const useTablePageWithoutConfig = ({
  tableColumns,
  lsKeyColumns,
  lsKeyFilters,
  initialFiltersValues,
  initialSorting,
  initialHiddenColumns,
  lsKeyPagination,
  lsKeySorting,
}: useTablePageWithoutConfigTypes) => {
  const { columnsMenuAnchorEl, setColumnsMenuAnchorEl } = useColumnsMenu()

  const {
    columns,
    setColumns,
    handleChangeColumns,
    currentFields,
    allFields,
  } = useColumnsWithoutConfig({ tableColumns, initialHiddenColumns, lsKeyColumns })

  const pagination = useUITablePagination(lsKeyPagination)

  const filters = useSelectedFilters(lsKeyFilters, initialFiltersValues, pagination.changePage)

  const sorting = useSorting(initialSorting, lsKeySorting)

  return {
    headerColumns: {
      columns,
      setColumns,
      handleChangeColumns,
      currentFields,
      allFields,
      columnsMenuAnchorEl,
      setColumnsMenuAnchorEl,
    },
    filters,
    sorting,
    pagination,
  } as const
}

export { useTablePageWithoutConfig }
