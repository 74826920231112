import { ReactChild } from 'react'

export interface Props {
  children: ReactChild
}

export enum ThemeName {
  MaterialTheme = 'materialTheme',
  ProductsTheme = 'productsTheme',
}
