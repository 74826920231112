import queryString from 'query-string'

import { APIConfiguration } from '@/constants'
import { Sort } from '@/types/cm'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

import { AdmAccessRulesRole, AdmModules, AdmUserPermissions } from '../swaggerGeneratedApi'

export class AccessRulesAdminApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async get(sort: Sort): Promise<AdmAccessRulesRole[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/accessRules/roles',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )
      return await AccessRulesAdminApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  // modules
  public static async getModules(guid: string): Promise<AdmModules> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/accessRules/modules',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )
      return await AccessRulesAdminApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setModules(modules: AdmModules): Promise<void> {
    try {
      return await AccessRulesAdminApi.requestService.put(
        '/admin/accessRules/modules/update',
        modules,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  // Permission
  public static async getPermissions(guid: string): Promise<AdmUserPermissions> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/accessRules/permissions',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )
      return await AccessRulesAdminApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setPermissions(permissions: AdmUserPermissions): Promise<void> {
    try {
      return await AccessRulesAdminApi.requestService.put(
        '/admin/accessRules/permissions/update',
        permissions,
      )
    } catch (e) {
      throw new Error(e)
    }
  }
}
