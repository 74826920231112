import { createSelector } from 'reselect'

import { Account } from '@/api/common/authorizationApi/types'
import { AppState } from '@/store/reducers'

import * as fromAuth from './reducer'

export const getAuthState = (state: AppState): fromAuth.State => state.auth

export const getAuthError = createSelector(getAuthState, state => state.error)

export const getCurrentAccount = createSelector(getAuthState, state => state.currentAccount)

export const getCurrentUserId = createSelector(
  getAuthState,
  state => state.currentAccount && state.currentAccount.userId,
)

export const getCurrentCompany = createSelector(
  getAuthState,
  state => state.currentAccount && state.currentAccount.companyName,
)

export const getPersonName = createSelector(
  getAuthState,
  state => state.currentAccount && state.currentAccount.personName,
)
export const getCurrentUserName = createSelector(
  getAuthState,
  state => state.currentAccount && state.currentAccount.userName,
)

export const getUserPhoto = createSelector(getAuthState, state => state.userPhoto)

export const getIsUserHasPhoto = createSelector(
  getAuthState,
  state => state.currentAccount?.hasPhoto,
)

export const getCurrentUserCompanyCountryId = createSelector(
  getAuthState,
  state => state.currentAccount && state.currentAccount.companyCountryId,
)

export const getAvailableAccountsOptions = createSelector(getAuthState, state =>
  Object.keys(state.accounts).reduce((acc, product) => {
    const accs = state.accounts[product]
      .map((account: Account) => ({
        value: account.userId.toString(),
        name: account.companyName, // account.userName,
        login: account.login,
      }))
      .sort((a: any, b: any) => a.name.localeCompare(b.name))
    return [...acc, ...accs]
  }, [] as any),
)

export const getAvailableProducts = createSelector(getAuthState, state => state.availableProducts)
export const getCurrentProduct = createSelector(getAuthState, state => state.currentProducts)

export const getIsLoggingIn = createSelector(getAuthState, state => state.isLoggingIn)

export const getTimezone = createSelector(getAuthState, state => state.timezone)

export const getPermissions = createSelector(getAuthState, state => state.permissions)

export const getUserPermissions = createSelector(getAuthState, state => state.userPermissions)

export const getIsNeedChangePassword = createSelector(getAuthState, state => {
  if (state.accounts && state.accounts[Object.keys(state.accounts)[0]]) {
    return state.accounts[Object.keys(state.accounts)[0]][0].changePassword
  }
  return false
})

export const getPermissionsLoading = createSelector(
  getAuthState,
  state => state.isPermissionsLoading,
)

export const getIsInitialisationProcess = createSelector(
  getAuthState,
  state => state.isInitialisingAuthProcess,
)

export const getChangePasswordState = createSelector(
  getAuthState,
  state => state.changePasswordState,
)
