import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { adminOptionsApi } from '@/api/atmeye/adminOptionsApi'
import { Device } from '@/api/atmeye/devicesApi/types'
import { TABS } from '@/components/pages/atmeye/Devices/Components/CurrentDevice/Tabs/TabsPanel/types'
import { LocalStorageItems } from '@/constants'
import { DATE_TIME_SEC_FORMAT } from '@/constants/time'
import { cancelablePromise } from '@/utils/cancelablePromise'
import { requestService } from '@/utils/services/request'

import { getIntervalByType, INTERVALS } from '../component'
import { ChartElementData, INTERVAL_TYPE } from '../types'

export const useChart = ({
  currentDevice,
  setTab,
  setSegmentFilters,
  isUpdateHistogram,
  setIsUpdateHistogram,
}: {
  currentDevice?: Device | null
  setTab: (tab: TABS) => void
  setSegmentFilters: (filterObject: any) => void
  isUpdateHistogram?: boolean
  setIsUpdateHistogram?: (isUpdate: boolean) => void
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isShowTooltip, setIsShowTooltip] = React.useState(false)

  const [chartData, setChartData] = useState<Array<ChartElementData>>([])

  const [transactions, setTransactions] = useState(0)
  const [techEvents, setTechEvents] = useState(0)
  const [alarms, setAlarms] = useState(0)

  const [tooltipPayload, setTooltipPayload] = React.useState<ChartElementData | null>(null)
  const [tooltipPosition, setTooltipPosition] = React.useState({ x: 0, y: 0 })

  const histogramSelectedInterval = localStorage.getItem(
    LocalStorageItems.HistogramSelectedInterval,
  )

  const [chartInterval, setChartInterval] = useState(JSON.parse(histogramSelectedInterval || '0'))
  const [histogramMaxInterval, setHistMaxInterval] = useState(20)

  const getMaxInterval = useCallback(async () => {
    try {
      const {
        limits: { histogramMaxInterval: maxIntervalType },
      } = await adminOptionsApi.getOptions()

      setHistMaxInterval(getIntervalByType(maxIntervalType)?.value || 20)
    } catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    getMaxInterval()
  }, [])

  useEffect(() => {
    if (+chartInterval > histogramMaxInterval) {
      setChartInterval(histogramMaxInterval)
    }
  }, [histogramMaxInterval])

  const dispatch = useDispatch()

  const ref = useRef<any | null>()

  useEffect(() => {
    localStorage.setItem(LocalStorageItems.HistogramSelectedInterval, JSON.stringify(chartInterval))
  }, [chartInterval])

  const getChartData = useCallback(
    async (intervalType: INTERVAL_TYPE) => {
      setIsLoading(true)
      try {
        if (ref?.current) {
          ref.current.cancel()
          ref.current = null
        }

        if (currentDevice) {
          const { deviceId } = currentDevice

          const payload = {
            intervalType,
            deviceId,
          }

          const chartData = cancelablePromise((resolve: (params: any) => any) => {
            requestService.get(`/atmeye/api/v1/histogram/data`, payload).then(data => resolve(data))
          })

          ref.current = {
            cancel: (chartData as any).cancel,
          }
          chartData.then(data => {
            setChartData(data as ChartElementData[])
            setIsLoading(false)
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    [currentDevice, ref],
  )

  const getSelectedInterval = (value: number | number[]) => {
    return INTERVALS.find(
      (interval: { value: number; label: string; type: string }) => interval.value === value,
    )
  }

  useEffect(() => {
    const selectedIntervalType = getSelectedInterval(chartInterval)?.type
    if (selectedIntervalType || isUpdateHistogram) {
      getChartData(selectedIntervalType || INTERVAL_TYPE.LAST_DAY)
    }
    setIsUpdateHistogram && setIsUpdateHistogram(false)
  }, [getChartData, isUpdateHistogram])

  const handleIntervalChange = useCallback(
    (event: React.ChangeEvent<{}>, value: number | number[]) => {
      const selectedInterval = getSelectedInterval(value)

      if (selectedInterval) {
        const selectedIntervalType = selectedInterval?.type
        getChartData(selectedIntervalType)
      }

      setChartInterval(Array.isArray(value) ? value[0] : value)
    },
    [getChartData],
  )

  const handleCloseTooltip = useCallback((): void => {
    setIsShowTooltip(false)
  }, [])

  useEffect(() => {
    handleCloseTooltip()
  }, [currentDevice])

  const handleChartClick = useCallback(
    ({
      activePayload,
      chartX,
      chartY,
    }: {
      activePayload: Array<any>
      chartX: number
      chartY: number
    }): void => {
      const tooltipPayload = activePayload[0].payload

      setTooltipPayload(tooltipPayload)
      setIsShowTooltip(prevState => !prevState)
      setTooltipPosition({
        x: chartX,
        y: chartY,
      })
    },
    [],
  )

  const getAtmTime = (payloadData: ChartElementData) => {
    const { atmIntervalStartTimestamp, atmIntervalEndTimestamp } = payloadData

    return [
      moment.utc(atmIntervalStartTimestamp * 1000).format(DATE_TIME_SEC_FORMAT),
      moment.utc(atmIntervalEndTimestamp * 1000).format(DATE_TIME_SEC_FORMAT),
    ]
  }

  const handleTooltipClick = useCallback(
    (page: number) => (event: any): void => {
      event.stopPropagation()

      if (tooltipPayload) {
        let filterObject

        if (page === TABS.TRANSACTIONS) {
          filterObject = {
            transactions: {
              clientId: '',
              eventMediaTypeId: [],
              eventTypeId: [],
              note: '',
              atmDate: getAtmTime(tooltipPayload),
            },
          }
        }

        if (page === TABS.SECURITY_ALARMS) {
          filterObject = {
            alarms: {
              eventMediaTypeId: [],
              eventTypeId: [],
              note: '',
              reactionDateTime: [null, null],
              atmDateTime: getAtmTime(tooltipPayload),
            },
          }
        }

        if (page === TABS.TECHNICAL_EVENTS) {
          filterObject = {
            events: {
              eventMediaTypeId: [],
              eventTypeId: [],
              note: '',
              atmDateTime: getAtmTime(tooltipPayload),
            },
          }
        }

        setSegmentFilters(filterObject)

        setTab(page)
      }
    },
    [setSegmentFilters, tooltipPayload],
  )

  const getSegmentsPercentage = useCallback(
    ({
      transactions,
      techEvents,
      alarms,
    }: {
      transactions: number
      techEvents: number
      alarms: number
    }) => {
      let transactionPercentage = Math.floor(
        (transactions * 100) / (transactions + techEvents + alarms),
      )
      let techEventsPercentage = Math.floor(
        (techEvents * 100) / (transactions + techEvents + alarms),
      )
      let alarmsPercentage = Math.floor((alarms * 100) / (transactions + techEvents + alarms))

      const remainder = 100 - (transactionPercentage + techEventsPercentage + alarmsPercentage)

      if (remainder !== 0) {
        const maxValue = Math.max(transactions, techEvents, alarms)

        switch (maxValue) {
          case transactions:
            transactionPercentage += remainder
            break

          case techEvents:
            techEventsPercentage += remainder
            break

          case alarms:
            alarmsPercentage += remainder
            break

          default:
            break
        }
      }

      return {
        transactionPercentage,
        techEventsPercentage,
        alarmsPercentage,
      }
    },
    [],
  )

  const getCustomizedTickValue = useCallback(
    value => {
      if (chartInterval === 20) {
        const [date, time, amPm = ''] = value.split(' ')

        return {
          primaryTick: `${time}${amPm}`,
          secondaryTick: date,
        }
      }

      return {
        primaryTick: value,
        secondaryTick: null,
      }
    },
    [chartInterval],
  )

  const { transactionPercentage, techEventsPercentage, alarmsPercentage } = useMemo(
    () =>
      getSegmentsPercentage({
        transactions,
        techEvents,
        alarms,
      }),
    [alarms, getSegmentsPercentage, techEvents, transactions],
  )

  useEffect(() => {
    if (chartData && Array.isArray(chartData)) {
      let transactions = 0
      let techEvents = 0
      let alarms = 0

      chartData.forEach((chartElementData: ChartElementData) => {
        if (chartElementData.transactionsCount) {
          transactions = transactions + chartElementData.transactionsCount
        }

        if (chartElementData.technicalEventsCount) {
          techEvents = techEvents + chartElementData.technicalEventsCount
        }

        if (chartElementData.alarmsCount) {
          alarms = alarms + chartElementData.alarmsCount
        }
      })

      setTransactions(transactions)
      setTechEvents(techEvents)
      setAlarms(alarms)
    }
  }, [chartData])

  return {
    isLoading,
    isShowTooltip,

    transactions,
    techEvents,
    alarms,

    transactionPercentage,
    techEventsPercentage,
    alarmsPercentage,

    chartInterval,
    histogramMaxInterval,

    tooltipPayload,
    tooltipPosition,

    chartData,

    handleIntervalChange,
    handleCloseTooltip,
    handleChartClick,
    handleTooltipClick,
    getCustomizedTickValue,
    getSegmentsPercentage,
  }
}
