import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { TextFieldProps } from '@material-ui/core/TextField/TextField'
import clsx from 'clsx'
import { FieldProps } from 'formik'
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import CounterBlock from '@/components/controls/NumberInput/counter'
import { generateErrorText } from '@/helpers/generateErrorText'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import { Field, FormikDatePickerRangeProps, Props, PropsNumber } from './types'

const helperTextStyles = makeStyles({
  root: {},
  error: {
    '&.MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      bottom: '-19px',
      margin: '0',
      fontSize: '9px',
    },
  },
})

export const FormikNumberInput = ({
  displayError = true,
  errorText,
  margin,
  className,
  label,
  shrink = true,
  name,
  setFieldValue,
  index,
  setValid,
  valid,
  setGlobalError,
  ...props
}: TextFieldProps & PropsNumber): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const helperTestClasses = helperTextStyles()

  const handleTextField = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      if (setFieldValue && name && index !== undefined) {
        const newValue = [...props.value]
        const numberValue = parseInt(e.target.value)
        if (numberValue >= 0 && numberValue <= 1000) {
          newValue[index] = parseInt(e.target.value)
        } else if (!numberValue) {
          newValue[index] = null
        }
        if ((newValue[1] || newValue[1] === 0) && newValue[1] <= newValue[0]) {
          setGlobalError((prevState: any) => ({ ...prevState, [name]: 'error' }))
          setValid([true, false])
        } else {
          setGlobalError((prevState: any) => {
            return Object.keys(prevState).reduce((object: any, key: string) => {
              if (key !== name) {
                object[key] = prevState[key]
              }
              return object
            }, {})
          })
          setValid([true, true])
        }
        setFieldValue(name, newValue, true)
      }
    },
    [setFieldValue, props.value],
  )

  const value = useMemo(() => {
    if (index !== undefined) {
      return parseInt(props.value[index])
    }
  }, [props.value])
  return (
    <>
      <TextField
        {...props}
        value={value}
        type="number"
        defaultValue="foo"
        autoComplete="off"
        className={clsx(className, classes.input, classes.inputNumber, {
          [classes.noMargin]: margin === 'none' && !label,
          [classes.noMarginWithLabel]: margin === 'none' && label,
          [classes.inputWithError]: displayError && errorText,
        })}
        label={
          label && index === 0
            ? label + ' ' + translate('translate#title.minimum')
            : label + ' ' + translate('translate#title.maximum')
        }
        InputProps={{
          inputProps: {
            min: 0,
          },
        }}
        error={!valid?.[index]}
        helperText={!valid?.[index] ? `Max shouldn't be less or equal than min` : null}
        FormHelperTextProps={{ classes: helperTestClasses }}
        name={name}
        variant={'outlined' as Field<'outlined' | 'standard'>}
        InputLabelProps={{ shrink }}
        onChange={handleTextField}
      />
      {displayError && !!errorText && (
        <div className={classes.errorLabel}>
          <FormattedMessage id={`form.validationWarning.${errorText}`} />
        </div>
      )}
    </>
  )
}

export const createNumberRangeField = (props: TextFieldProps & Props) => ({
  field,
  form: { errors, setFieldValue },
  meta: { touched },
  wrapperDateClass,
  titleClass,
  title,
}: FormikDatePickerRangeProps & FieldProps): React.ReactElement => {
  const classes = useClasses()
  const [valid, setValid] = useState<boolean[]>([true, true])
  return (
    <Grid container spacing={2}>
      <Grid item className={classes.fixedWidth}>
        <div className={classes.range}>
          {title && <span className={titleClass}>{title}</span>}
          <div className={wrapperDateClass}>
            <div className={classes.rangeNumberWrapper}>
              <FormikNumberInput
                {...field}
                index={0}
                errorText={generateErrorText(errors, field.name, touched)}
                error={!!errors[field.name] && touched}
                shrink={props.shrink}
                setFieldValue={setFieldValue}
                setGlobalError={props.setFieldError}
                setValid={setValid}
                valid={valid}
                {...props}
              />
              <CounterBlock
                index={0}
                disabled={false}
                setFieldValue={setFieldValue}
                value={field.value}
                name={field.name}
                setGlobalError={props.setFieldError}
                setValid={setValid}
                valid={valid}
              />
            </div>
            <div className={classes.rangeNumberWrapper}>
              <FormikNumberInput
                {...field}
                index={1}
                errorText={generateErrorText(errors, field.name, touched)}
                error={!!errors[field.name] && touched}
                shrink={props.shrink}
                setFieldValue={setFieldValue}
                setGlobalError={props.setFieldError}
                setValid={setValid}
                valid={valid}
                {...props}
              />
              <CounterBlock
                setFieldValue={setFieldValue}
                index={1}
                disabled={false}
                value={field.value}
                name={field.name}
                setGlobalError={props.setFieldError}
                setValid={setValid}
                valid={valid}
              />
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}
