import { createSelector } from 'reselect'
import { AppState } from '@/store/reducers'
import * as fromModalsActions from './reducer'
import { initialState } from './reducer'

export const getModalsActionsState = (state: AppState): fromModalsActions.State =>
  state.modals || initialState

export const getHistoryFiltersModalState = createSelector(
  getModalsActionsState,
  state => state.historyTicketsFiltersModalWasOpen,
)
