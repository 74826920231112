import colors from './colors'
import units from './units'

export const styledComponentTheme = {
  unit: Object.keys(units).reduce(
    (accum, key) => ({ ...accum, [key]: `${(units as { [key: string]: number })[key]}px` }),
    {},
  ),
  colors: {
    background: colors.background,
    dangerBackground: colors.dangerLight,
    backgroundDark: '#f9f9f9',
    backgroundLight: '#F2F2F2',
    disabledGrey: '#a7acae',
    primaryLight: colors.primaryLight,
    primary: colors.primary,
    primaryDark: colors.primaryDark,
    secondary: colors.secondary,
    secondaryLight: colors.secondaryLight,
    heading: '#fe4433',
    font: '#222222',
    link: '#fe4433',
    success: '#52c41a',
    warning: '#faad14',
    error: colors.danger,
    disabled: 'rgba(0, 0, 0, 0.25)',
    border: colors.grey100,
    scrollbar: colors.grey200,
    boxShadow: 'rgba(0, 0, 0, 0.15)',
    paleGrey: '#818a8b',
    lightGrey: '#929A9B',
    grassGreen: '#00C562',
    errorRed: '#E45B4A',
    baseGreen: '#25ADBF',
  },
  fontSizes: {
    verySmall: '12px',
    small: '14px',
    normal: '16px',
    medium: '18px',
    big: '20px',
    veryBig: '24px',
  },
  letterSpacing: {
    normal: 'normal',
    caps: '0.25em',
  },
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    bold: 700,
  },
  fontFamily: ['Lato, "Helvetica Neue", Arial'],
}
