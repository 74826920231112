import moment from 'moment'
import { createSelector } from 'reselect'

import {
  CommonTicketInfo,
  DocumentInformationFields,
  FormattedRequestItems,
  FormattedTicketInfo,
} from '@/components/blocks/PSRTicketDetails/components/DocumenInformationModal/components/DocumenInformationTable/types'
import { AppState } from '@/store/reducers'
import { RequestItems } from '@/types/sd/posTicket'

import { initialState } from '../reducer'
import * as fromDrawerActions from '../reducer'

const gettingKeyForCity = (city: string | null) => {
  let key = ''

  return city ? (key = 'newCity') : (key = 'city')
}

export const getDrawerActionsState = (state: AppState): fromDrawerActions.State =>
  state.drawerActions || initialState

export const getTicketId = createSelector(
  getDrawerActionsState,
  (state): number => state.saveTicketResponse.saveTicketData.documentId || 0,
)

export const getIsFetchingPSRTicketDetails = createSelector(
  getDrawerActionsState,
  state => state.isFetchingPSRTicketDetails,
)

export const getTicketInfo = createSelector(
  getDrawerActionsState,
  (state): FormattedTicketInfo | null => {
    const { ticketInfo } = state.ticketInfoFormData

    if (ticketInfo) {
      const keyLegalCity = gettingKeyForCity(ticketInfo.merchantlegcity)
      const keyActualCity = gettingKeyForCity(ticketInfo.merchantcity)

      return {
        merchantInformation: {
          legalName: ticketInfo.merchantfullname || ticketInfo.merchantcompany,
          companyCode: ticketInfo.merchantidentificationnr,
          VATCode: ticketInfo.merchantvatcode,
        },
        merchantLegalAddress: {
          country: ticketInfo.merchantlegcountryname,
          [keyLegalCity]: ticketInfo.merchantlegcityname,
          streetHouseNo: ticketInfo.merchantlegstreet,
          postalCode: ticketInfo.merchantlegpostalcode,
        },
        merchantActualAddress: {
          country: ticketInfo.merchantcountryname,
          [keyActualCity]: ticketInfo.merchantcityname,
          streetHouseNo: ticketInfo.merchantstreet,
          postalCode: ticketInfo.merchantpostalcode,
        },
        contacts: {
          contactPerson: ticketInfo.merchantcontactperson,
          phoneNumber: ticketInfo.merchantphone,
          email: ticketInfo.merchantemail,
        },
      }
    } else {
      return null
    }
  },
)

export const getTicketInfoGrantedOperations = createSelector(
  getDrawerActionsState,
  state => state.ticketInfoFormData.grantedOperations,
)

export const getCommonTicketInfo = createSelector(getDrawerActionsState, (state):
  | []
  | CommonTicketInfo[] => {
  const { ticketInfo } = state.ticketInfoFormData

  if (ticketInfo) {
    return [
      {
        [DocumentInformationFields.CustomerRequestNumber]: ticketInfo.originaldocnr,
        [DocumentInformationFields.ServiceCompany]: ticketInfo.recipientcompanyname,
        [DocumentInformationFields.Date]: moment(ticketInfo.docdate).format('YYYY.MM.DD'),
      },
    ]
  } else {
    return []
  }
})

export const getRequestItems = createSelector(getDrawerActionsState, state => {
  const { requestItems } = state.ticketInfoFormData

  if (requestItems) {
    return requestItems.map((requestItem: RequestItems) => ({
      [DocumentInformationFields.ItemNo]: requestItem.itemno,
      [DocumentInformationFields.ShopBrandName]: requestItem.devicename,
      [DocumentInformationFields.Status]: requestItem.gensrdocstatusid,
      [DocumentInformationFields.Service]: requestItem.posserviceid,
      [DocumentInformationFields.Priority]: requestItem.pospriorityid,
      [DocumentInformationFields.TerminalID]: requestItem.posaquirerluno,
      [DocumentInformationFields.POSAddress]: requestItem.posfulladdress,
      [DocumentInformationFields.MerchantID]: requestItem.posmerchantid,
      [DocumentInformationFields.MCCCode]: requestItem.posmcccode,
      [DocumentInformationFields.TerminalType]: requestItem.posterminaltypeid,
      [DocumentInformationFields.ConnectionType]: requestItem.posconnectiontypeid,
      [DocumentInformationFields.ContactPerson]: requestItem.poscontactperson,
      [DocumentInformationFields.Phone]: requestItem.posphone,
      [DocumentInformationFields.Notes]: requestItem.notes,
    }))
  } else {
    return []
  }
})
