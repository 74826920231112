import React, { ReactElement, useMemo } from 'react'
import { useSelector } from 'react-redux'
import Test from 'react-test-attributes'

import { Restricted } from '@/components/NPMPakage/components/wrappers/PermissionProvider/Restricted'
import DrawerMenuItem from '@/components/wrappers/AppNavigation/components/DrawerMenuItem'
import menuItemsATMeye from '@/components/wrappers/AppNavigation/menuItemsATMeye'
import { getAvailableModules } from '@/store/atmeye/common'
import { getUserPermissions } from '@/store/auth'
import { getAlarmCount, getEventsCount } from '@/store/notifications'

import { GenerateMenuItemsProps } from './types'

export const GenerateMenuItems = ({
  testId,
  pathname,
  classes,
  hideSubItems,
  handleAddTab,
}: GenerateMenuItemsProps): ReactElement => {
  const eventCount = useSelector(getEventsCount)
  const alarmCount = useSelector(getAlarmCount)
  const permissions = useSelector(getUserPermissions)
  const availableModules = useSelector(getAvailableModules)

  const menuGenerator = useMemo(
    () =>
      menuItemsATMeye({
        alarmsCount: alarmCount,
        technicalEventsCount: eventCount,
        availableModules,
      }).map((item: any) => {
        if (item.subItems?.length) {
          return {
            ...item,
            subItems: item.subItems.filter((subItem: any) =>
              subItem?.permission ? permissions?.[subItem?.permission ?? ''] : true,
            ),
          }
        }

        return item
      }),
    [eventCount, alarmCount, permissions, availableModules],
  )

  return (
    <>
      {menuGenerator.map(
        ({ label, Icon, path, subItems = [], badgeCounter, permission }, index) => (
          <Test id={testId(34, `${index}`)} key={`Tab${label}`}>
            <Restricted permissionKey={permission}>
              <DrawerMenuItem
                label={label}
                Icon={Icon}
                path={path}
                innerPages={subItems}
                pathname={pathname}
                classes={classes}
                handleAddTab={handleAddTab}
                hideSubItems={hideSubItems}
                subItems={subItems}
                badgeCounter={badgeCounter}
              />
            </Restricted>
          </Test>
        ),
      )}
    </>
  )
}
