import React from 'react'
import { store } from '@/App'

export const VaultRoutes = React.lazy(async () => {
  const {
    default: { reducer, saga },
  } = await import('@/store/vault/cashOrders')

  store.injectReducer('vaultCashOrders', reducer)
  store.injectSaga('vaultCashOrders', saga)

  const {
    default: { reducer: bagsReducer, saga: bagsSaga },
  } = await import('@/store/vault/cashBags')

  store.injectReducer('vaultCashBags', bagsReducer)
  store.injectSaga('vaultCashBags', bagsSaga)

  const {
    default: { reducer: teamsReducer, saga: teamsSaga },
  } = await import('@/store/vault/teams')

  store.injectReducer('vaultTeams', teamsReducer)
  store.injectSaga('vaultTeams', teamsSaga)

  const {
    default: { reducer: usersReducer, saga: usersSaga },
  } = await import('@/store/vault/users')

  store.injectReducer('vaultUsers', usersReducer)
  store.injectSaga('vaultUsers', usersSaga)

  return import('@/components/routers/vault')
})
