import React, { FC, ReactElement, useState, useCallback } from 'react'
import clsx from 'clsx'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { useClasses } from './styles'
import { Props } from './types'

const Hider: FC<Props> = ({ title, withPaper, classes: customClasses, children }): ReactElement => {
  const [expanded, setExpanded] = useState<'opened' | null>('opened')

  const classes = useClasses()

  const handleChange = useCallback((event, isExpanded) => {
    setExpanded(isExpanded ? 'opened' : null)
  }, [])

  return (
    <div className={clsx(classes.wrapper, customClasses?.wrapper)}>
      <Accordion
        expanded={expanded === 'opened'}
        onChange={handleChange}
        className={clsx({ [classes.paper]: !withPaper })}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={customClasses?.title}>
          {title}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  )
}

export default Hider
