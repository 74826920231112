import moment from 'moment'
import { AnyAction } from 'redux'

import { AnlChartsFilter, AnlChartsResponse, ComCMReport } from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants'
import { ChartsGroupType } from '@/types/cm/analysis'

export type State = {
  isFiltersModalOpen: boolean
  isDocumentModalOpen: boolean
  selectedChartsType: ChartsGroupType
  filter: AnlChartsFilter
  reports: {
    errorMessage?: string
    isLoading: boolean
    list: ComCMReport[]
    selectedReport: {
      isLoading: boolean
      errorMessage?: string
      bytes?: Blob | null
    }
  }
  charts: AnlChartsResponse
}

const initialState: State = {
  isFiltersModalOpen: false,
  isDocumentModalOpen: false,
  selectedChartsType: ChartsGroupType.RealTimeCharts,
  filter: {
    from: moment()
      .subtract(1, 'month')
      .startOf('day')
      .format(),
    until: moment()
      .add(1, 'month')
      .startOf('day')
      .format(),
    period: 'Day',
  },
  reports: {
    errorMessage: '',
    isLoading: false,
    list: [],
    selectedReport: {
      isLoading: false,
      errorMessage: '',
      bytes: undefined,
    },
  },
  charts: {},
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case CMActions.AnalysisSetSelectedChartsType:
      return {
        ...state,
        selectedChartsType: payload,
      }
    case CMActions.AnalysisGetChartsResponse:
      return {
        ...state,
        charts: payload,
      }

    case CMActions.AnalysisSetFiltersModalOpen:
      return {
        ...state,
        isFiltersModalOpen: payload,
      }
    case CMActions.AnalysisSetDocumentModalOpen:
      return {
        ...state,
        isDocumentModalOpen: payload,
      }
    case CMActions.AnalysisSetFilter:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload,
        },
      }

    case CMActions.AnalysisGetReportRequest:
      return {
        ...state,
        reports: {
          ...state.reports,
          selectedReport: {
            ...state.reports.selectedReport,
            isLoading: true,
            errorMessage: '',
            bytes: undefined,
          },
        },
      }
    case CMActions.AnalysisGetReportResponse:
      return {
        ...state,
        reports: {
          ...state.reports,
          selectedReport: {
            ...state.reports.selectedReport,
            isLoading: false,
            errorMessage: '',
            bytes: payload,
          },
        },
      }
    case CMActions.AnalysisGetReportResponseFail:
      return {
        ...state,
        reports: {
          ...state.reports,
          selectedReport: {
            ...state.reports.selectedReport,
            isLoading: false,
            errorMessage: payload,
            bytes: undefined,
          },
        },
      }
    case CMActions.AnalysisGetReportsRequest:
      return {
        ...state,
        reports: {
          ...state.reports,
          isLoading: true,
          list: [],
          errorMessage: '',
          selectedReport: initialState.reports.selectedReport,
        },
      }
    case CMActions.AnalysisGetReportsResponse:
      return {
        ...state,
        reports: {
          ...state.reports,
          isLoading: false,
          list: payload,
          errorMessage: '',
        },
      }
    case CMActions.AnalysisGetReportsResponseFail:
      return {
        ...state,
        reports: {
          ...state.reports,
          isLoading: false,
          list: [],
          errorMessage: payload,
        },
      }
    case CMActions.SignOut:
      return initialState

    default:
      return state
  }
}
