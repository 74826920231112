import { Checkbox, TableCell } from '@material-ui/core'
import MuiTableRow from '@material-ui/core/TableRow'
import clsx from 'clsx'
import React, { FC, ReactElement } from 'react'

import { useClasses } from './styles'
import { TableItemRowProps } from './types'

const TableItemRow: FC<TableItemRowProps> = ({
  isCheckboxTable,
  isCutRow,

  selectedRowId,
  index,
  parentIndex,
  collapse,

  row,
  headers,
  multiSelectedRows,
  rowIdKey = 'id',
  iconCellFields,
  CellJSX,

  handleRowClick,
  handleDoubleClickRow,
  setCollapse,
  getUpdateRequest,
  updateData,
  sorting,
}: TableItemRowProps): ReactElement => {
  const classes = useClasses({ collapse: false, isCutRow })
  const isRowCheckboxSelected = row?.isCheckBoxSelected || false

  return (
    <MuiTableRow
      onClick={(event: any) => {
        handleRowClick && handleRowClick(row, event.ctrlKey || event.metaKey, index)()
      }}
      onDoubleClick={(): void => {
        handleDoubleClickRow && handleDoubleClickRow(row)
      }}
      className={clsx(classes.rowWrap, {
        [classes.dangerRow]:
          row.reactionDateTime === null || (row.isTrackerLinked && !row.isTrackerGpsValid),
        [classes.selectedRow]:
          String(selectedRowId) === String(row[rowIdKey]) ||
          multiSelectedRows?.includes(row[rowIdKey]),
        [classes.rowWrapWithBorder]: true,
        [classes.clickableCell]: !!handleRowClick,
      })}
    >
      {isCheckboxTable && <Checkbox checked={isRowCheckboxSelected} />}

      {headers?.map(({ fieldName }, indexColumn) => (
        <TableCell key={fieldName} align="left" className={classes.cell}>
          <CellJSX
            iconCellFields={iconCellFields}
            fieldName={fieldName}
            item={row.item ?? row}
            row={row}
            index={index}
            parentIndex={parentIndex}
            indexColumn={indexColumn}
            collapse={collapse}
            setCollapse={setCollapse}
            getUpdateRequest={getUpdateRequest}
            updateData={updateData}
            sorting={sorting}
          />
        </TableCell>
      ))}
    </MuiTableRow>
  )
}

export { TableItemRow }
