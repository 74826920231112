import { AnyAction } from 'redux'

import { BalUnloadedCassettesSession } from '@/api/cm/swaggerGeneratedApi'

export type SetBalUnloadedCassettes = (unloadedCassettes: BalUnloadedCassettesSession) => AnyAction

export interface Props {
  isSorters: boolean
  setBalUnloadedCassettes: SetBalUnloadedCassettes
}

export enum EditableColumns {
  Deposit = 'deposit',
  Dispensed = 'dispensed',
  Remaining = 'remaining',
  Reject = 'reject',
  Retract = 'retract',
  CashIn = 'cashIn',

  KioskCashInSlip = 'kioskCashInSlip',
  KioskCashInReal = 'kioskCashInReal',
}
