import IconButton from '@material-ui/core/IconButton'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import AddIcon from '@material-ui/icons/Add'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import DeleteIcon from '@material-ui/icons/Delete'
import _, { cloneDeep } from 'lodash'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'

import { TimePeriod, WorkingHour } from '@/api/companies/companiesApi/types'
import { useTranslate } from '@/utils/internalization'

import TimeInput from './components/TimeInput'
import { useClasses } from './styles'
import { Props, WeekDays } from './types'
import { checkIsValidWorkingHour, isWorks } from './utils/validate'

const WorkingHours = ({ data, setFieldValue }: Props): ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const [indexRow, setIndex] = useState('')
  const [workingHours, setWorkingHours] = useState<WorkingHour[]>(data)
  let rows = 0

  useEffect(() => {
    setFieldValue('workingHours', workingHours)
  }, [workingHours])

  useEffect(() => {
    const dataCopy = cloneDeep(data)

    if (dataCopy === undefined || dataCopy.length === 0) {
      for (const day in WeekDays) {
        if (data.filter(e => e.dayOfWeek === day).length === 0) {
          const emptyPeriod: TimePeriod[] = [
            { workingHourId: null, fromTime: '00:00', toTime: '23:59' },
          ]
          const workingDay: WorkingHour = {
            dayOfWeek: day,
            // isWorks: true,
            timePeriods: emptyPeriod,
          }
          data.push(workingDay)
        }
      }
    } else {
      for (const day in WeekDays) {
        if (data.filter(e => e.dayOfWeek === day).length === 0) {
          const emptyPeriod: TimePeriod[] = [
            { workingHourId: null, fromTime: '00:00', toTime: '00:00' },
          ]
          const workingDay: WorkingHour = {
            dayOfWeek: day,
            // isWorks: false,
            timePeriods: emptyPeriod,
          }
          data.push(workingDay)
        }
      }
    }

    const weekdaysOrder = Object.values(WeekDays)

    const result = data.sort(
      (a, b) =>
        weekdaysOrder.indexOf(a.dayOfWeek as WeekDays) -
        weekdaysOrder.indexOf(b.dayOfWeek as WeekDays),
    )

    setWorkingHours(result)
  }, [data])

  const handleChangeIsWork = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWorkingHours(prevData =>
      prevData.map(item => {
        if (item.dayOfWeek === event.target.name) {
          const isWorks = event.target.checked

          if (!isWorks) {
            const emptyPeriod: TimePeriod[] = [
              { workingHourId: null, fromTime: '00:00', toTime: '00:00' },
            ]
            item.timePeriods = emptyPeriod
          } else {
            const fullDayPeriod: TimePeriod[] = [
              { workingHourId: null, fromTime: '00:00', toTime: '23:59' },
            ]

            item.timePeriods = fullDayPeriod
          }
        }
        return item
      }),
    )
  }

  const handleCopyWorkingHoursClick = (id: string) => () => {
    const [dayOffWeek] = id.split('-')

    let weekDayIndex = 0
    const copyDayIndex = Object.keys(WeekDays).indexOf(dayOffWeek)

    setWorkingHours(prevData =>
      prevData.map(item => {
        if (weekDayIndex > copyDayIndex) {
          // item.isWorks = workingHours[copyDayIndex].isWorks
          const timePeriods = cloneDeep(workingHours[copyDayIndex].timePeriods)
          timePeriods.map(item => (item.workingHourId = null))
          item.timePeriods = cloneDeep(timePeriods)
        }
        weekDayIndex++
        return item
      }),
    )
  }

  const handleChangeTimeFrom = useCallback(
    (id: string) => (time: string): void => {
      const [dayOffWeek, periodIndex] = id.split('-')

      setWorkingHours(prevData =>
        prevData.map(item => {
          if (item.dayOfWeek === dayOffWeek) {
            item.timePeriods[+periodIndex].fromTime = time
          }
          return item
        }),
      )
    },
    [],
  )

  const handleChangeTimeTo = useCallback(
    (id: string) => (time: string): void => {
      const [dayOffWeek, periodIndex] = id.split('-')

      setWorkingHours(prevData =>
        prevData.map(item => {
          if (item.dayOfWeek === dayOffWeek) {
            item.timePeriods[+periodIndex].toTime = time
          }
          return item
        }),
      )
    },
    [],
  )

  const handleAddWorkingHoursClick = (id: string) => () => {
    const [dayOffWeek] = id.split('-')

    setWorkingHours(prevData =>
      prevData.map(item => {
        if (item.dayOfWeek === dayOffWeek) {
          const lastWorkPeriod = item.timePeriods[item.timePeriods.length - 1]

          const lastWorkPeriodArr = lastWorkPeriod.toTime.split(':').map(item => +item)

          if (!(lastWorkPeriodArr[0] === 23 && lastWorkPeriodArr[1] === 59)) {
            let [newH, newM] = lastWorkPeriodArr

            if (newM === 59) {
              newH++
              newM = 0
            } else {
              newM++
            }

            const emptyPeriod: TimePeriod = {
              workingHourId: null,
              fromTime: `${newH < 10 ? '0' + newH : newH}:${newM.toString().padStart(2, '0')}`,
              toTime: '23:59',
            }

            item.timePeriods.push(emptyPeriod)
          }
        }
        return item
      }),
    )
  }

  const handleDeleteWorkingHoursClick = useCallback(
    (id: string) => () => {
      const [dayOffWeek, periodIndex] = id.split('-')

      setWorkingHours(prevData =>
        prevData.map(item => {
          if (item.dayOfWeek === dayOffWeek) {
            item.timePeriods.splice(+periodIndex, 1)
          }
          return item
        }),
      )
    },
    [],
  )

  const getStripedStyle = (index: number): any => {
    return { background: index % 2 ? '#fafafa' : 'white' }
  }

  return (
    <Table className={classes.table} size="small" aria-label="working hours">
      <TableHead>
        <TableRow style={{ background: '#fafafa' }}>
          <TableCell className={classes.bold}>{translate('translate#title.dayOfWeek')}</TableCell>
          <TableCell className={classes.bold}>{translate('translate#title.works')}</TableCell>
          <TableCell className={classes.bold}>{translate('translate#title.from')}</TableCell>
          <TableCell className={classes.bold}>{translate('translate#title.to')}</TableCell>
          <TableCell className={classes.bold} />
        </TableRow>
      </TableHead>
      <TableBody>
        {workingHours &&
          workingHours.map((row, index) => {
            return row.timePeriods.map((period, periodIndex) => {
              rows++
              const rowKey = `${row.dayOfWeek}-${periodIndex}`
              return (
                <TableRow
                  key={rowKey}
                  style={{ ...getStripedStyle(rows) }}
                  onMouseEnter={() => setIndex(row.dayOfWeek)}
                  onMouseLeave={() => setIndex('')}
                >
                  <TableCell component="th" scope="row">
                    {periodIndex === 0 &&
                      _.startCase(translate(`translate#title.${_.toLower(row.dayOfWeek)}`))}
                  </TableCell>
                  <TableCell>
                    {periodIndex === 0 && (
                      <Switch
                        checked={isWorks(row)}
                        onChange={handleChangeIsWork}
                        color="primary"
                        name={row.dayOfWeek}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {isWorks(row) && (
                      <TimeInput
                        time={`${period.fromTime}`}
                        changeTime={handleChangeTimeFrom(rowKey)}
                        isValid={checkIsValidWorkingHour(row)}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {isWorks(row) && (
                      <TimeInput
                        time={`${period.toTime}`}
                        changeTime={handleChangeTimeTo(rowKey)}
                        isValid={checkIsValidWorkingHour(row)}
                      />
                    )}
                  </TableCell>
                  <TableCell align="left" width="25%">
                    {periodIndex === 0 && indexRow === row.dayOfWeek && isWorks(row) && (
                      <>
                        <IconButton
                          color="primary"
                          aria-label="Add working hours"
                          component="span"
                          style={{ padding: 8 }}
                          onClick={handleAddWorkingHoursClick(rowKey)}
                        >
                          <AddIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          color="primary"
                          aria-label="Copy working hours"
                          component="span"
                          style={{ padding: 8 }}
                          onClick={handleCopyWorkingHoursClick(rowKey)}
                        >
                          <ArrowDownwardIcon fontSize="small" />
                        </IconButton>
                      </>
                    )}
                    {periodIndex !== 0 && (
                      <IconButton
                        color="primary"
                        aria-label="Delete working hours"
                        component="span"
                        style={{ padding: 8 }}
                        onClick={handleDeleteWorkingHoursClick(rowKey)}
                      >
                        <DeleteIcon fontSize="small" style={{ color: 'red' }} />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              )
            })
          })}
      </TableBody>
    </Table>
  )
}

export default WorkingHours
