import { Box } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import NotificationIcon from '@/assets/atmeye/icons/header/notification_icon.svg'
import { NotificationModal } from '@/components/blocks/ATMeye/notifications/NotificationModal'
import { getAlarmCount, getEventsCount } from '@/store/notifications'
import { formatCount } from '@/utils/functions'

import { useClasses } from './styles'

export const NotificationBar = (): ReactElement => {
  const classes = useClasses()
  const eventCount = useSelector(getEventsCount)
  const alarmCount = useSelector(getAlarmCount)

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Grid item>
      <Box className={classes.buttonContainer} onClick={handleClick} aria-describedby={id}>
        <Box className={clsx(classes.button, classes.eventButton)}>
          {formatCount({ count: eventCount })}
        </Box>

        <Box className={classes.notificationButton}>
          <img src={NotificationIcon} alt="notification icon" />
        </Box>

        <Box className={clsx(classes.button, classes.alarmButton)}>
          {formatCount({ count: alarmCount })}
        </Box>
      </Box>

      <NotificationModal id={id} open={open} handleClose={handleClose} anchorEl={anchorEl} />
    </Grid>
  )
}
