import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import clsx from 'clsx'
import React, { useState } from 'react'
import Test from 'react-test-attributes'

import CloseButton from '@/components/blocks/CloseButton'
import CreatePOSRequestForm from '@/components/blocks/POSRequestForm'
import { CreatePOSModalContext } from '@/context'
import { getTestId } from '@/utils'
import { preventDefault } from '@/utils/functions'

import { useClasses } from './styles'
import { Props } from './types'

const CreatePOSRequestModal = ({
  open,
  handleClose,
  handleOpenPsrDetails,
  handleSetCurrentTicketId,
  disableBackdropClick,
  disableEscapeKeyDown,
}: Props): React.ReactElement => {
  const classes = useClasses()
  const idPrefix = 'create-pos-request'
  const testId = getTestId(idPrefix)
  const [isHideCreatePOSRequestModal, setIsHideCreatePOSRequestModal] = useState(false)

  const onClose = (event: any, reason: 'backdropClick' | 'escapeKeyDown'): void | boolean => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return false
    }

    if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false
    }

    if (typeof handleClose === 'function') {
      handleClose()
    }
  }

  return (
    <CreatePOSModalContext.Provider value={{ setIsHideCreatePOSRequestModal }}>
      <Test id={testId(0)}>
        <Dialog
          onDragOver={preventDefault}
          onDrop={preventDefault}
          maxWidth="lg"
          fullWidth
          open={open}
          transitionDuration={0}
          onClose={onClose}
          className={clsx({
            [classes.hide]: isHideCreatePOSRequestModal,
          })}
        >
          <Test id={testId(1)}>
            <CloseButton onClick={handleClose} absolute />
          </Test>
          <Test id={testId(2)}>
            <Box display="flex" flexDirection="column" overflow="auto">
              <CreatePOSRequestForm
                handleClose={handleClose}
                open={open}
                testIdPrefix={idPrefix}
                handleOpenPsrDetails={handleOpenPsrDetails}
                handleSetCurrentTicketId={handleSetCurrentTicketId}
              />
            </Box>
          </Test>
        </Dialog>
      </Test>
    </CreatePOSModalContext.Provider>
  )
}

export default CreatePOSRequestModal
