import { Menu } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import clsx from 'clsx'
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { isIE } from 'react-device-detect'

import AccessControl from '@/components/controls/auth/AccessControl'
import { TextInput } from '@/components/controls/TextInput/component'

import { useClasses } from './styles'
import { Props } from './types'

const ContextMenu = ({
  anchorEl,
  options,
  disableRipple = false,
  limitMenuSize = true,
  handleClose,
  header,
  children,
  classStyles,
  className,
  useItemDivider,
  ...menuProps
}: Props): React.ReactElement => {
  const [filter, setFilter] = useState('')
  const props = { display: isIE ? 'block' : 'flex' }
  const classes = useClasses(props)

  useEffect((): void => {
    if (!anchorEl) {
      setFilter('')
    }
  }, [anchorEl])

  const handleFilterChange = useCallback(
    (
      e: SyntheticEvent<(EventTarget & HTMLInputElement) | (EventTarget & HTMLTextAreaElement)>,
    ): void => {
      setFilter(e.currentTarget.value)
    },
    [],
  )

  return (
    <Menu
      anchorEl={anchorEl}
      classes={{
        paper: classes.menuPaper,
        list: clsx(classes.menuList, classStyles?.menuListStyle),
      }}
      getContentAnchorEl={null}
      disableAutoFocusItem
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      keepMounted
      elevation={3}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      {...menuProps}
    >
      <div className={clsx(classes.menuSpacing, classStyles?.menuSpacingStyle)}>
        {header && <Container>{header}</Container>}
        {limitMenuSize && options.length > 5 && (
          <Container>
            <TextInput value={filter} onChange={handleFilterChange} placeholder="Search" />
          </Container>
        )}
        <div
          className={clsx(classes.scrollAble, className, {
            [classes.limitedHeight]: limitMenuSize,
          })}
        >
          {options
            .filter(option => (option.value ? option.value.toString().indexOf(filter) >= 0 : true))
            .map((option, i) => (
              <AccessControl
                key={`${option.content}${i}`}
                allowedPermissions={option.allowedPermissions}
                renderNoAccess={(): React.ReactElement => <></>}
              >
                {useItemDivider && i > 0 ? (
                  <Divider className={classStyles?.driverStyle || classes.divider} key={i} />
                ) : (
                  <></>
                )}
                <MenuItem
                  disabled={option.disabled}
                  disableRipple={disableRipple}
                  onClick={option.handleClick}
                >
                  {option.content}
                </MenuItem>
              </AccessControl>
            ))}
        </div>
        <div>{children}</div>
      </div>
    </Menu>
  )
}

export default ContextMenu
