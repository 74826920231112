import { ObjectPermissionsResponse, ObjectPermissionsTree } from '@/types/usm/userGroup/userGroup'

// const sortObjectPermission = (array: Array<ObjectPermissionsTree>) => {
//   return array.sort((a, b) => {
//     if (a.children && a.children.length && (!b.children || !b.children.length)) return 1
//     if (a.objectFriendlyName > b.objectFriendlyName && !b.children.length) return 1
//     else {
//       if (a.children && a.children.length) a.children = sortObjectPermission(a.children)
//       return -1
//     }
//   })
// }

function zeroPad(num: number, places: number) {
  const zero = places - num.toString().length + 1
  return Array(+(zero > 0 && zero)).join('0') + num
}

const sortChildrenObjects = (array: Array<ObjectPermissionsTree>) => {
  array.forEach(function(d) {
    if (d.children !== null && d.children.length > 0) {
      d.children = d.children.sort((a, b) => {
        const left =
          (a.children.length > 0 ? '0' : '1') + zeroPad(a.objectTypeId, 8) + a.objectFriendlyName
        const right =
          (b.children.length > 0 ? '0' : '1') + zeroPad(b.objectTypeId, 8) + b.objectFriendlyName
        return left !== right ? (left < right ? -1 : 1) : 0
      })
      sortChildrenObjects(d.children)
    }
  })
}

const sortObjectPermission = (array: Array<ObjectPermissionsTree>) => {
  // return array.sort((a, b) => {
  //   if (a.children && a.children.length && (!b.children || !b.children.length)) return -1
  //   if (a.objectFriendlyName < b.objectFriendlyName && !b.children.length) return -1
  //   else {
  //     if (a.children && a.children.length) a.children = sortObjectPermission(a.children)
  //     return 1
  //   }
  // })

  return array.sort((a, b) => {
    const left = zeroPad(a.objectTypeId, 8) + a.objectFriendlyName
    const right = zeroPad(b.objectTypeId, 8) + b.objectFriendlyName
    return left !== right ? (left < right ? -1 : 1) : 0
  })
}

export const getTreeData = (items: Array<ObjectPermissionsResponse>) => {
  if (items && items.length > 0) {
    const data = [] as Array<ObjectPermissionsTree>
    const map: any = {} as ObjectPermissionsResponse
    items.map(item => {
      const id = item.objectId // custom id selector !!!
      // eslint-disable-next-line no-prototype-builtins
      if (!map.hasOwnProperty(id)) {
        // in case of duplicates
        map[id] = {
          ...item,
          children: [],
        }
      }
    })
    for (const id in map) {
      // eslint-disable-next-line no-prototype-builtins
      if (map.hasOwnProperty(id)) {
        let mappedElem = []
        mappedElem = map[id]
        /// parentId : use custom id selector for parent
        if (mappedElem.parentObjectId && typeof map[mappedElem.parentObjectId] !== 'undefined') {
          map[mappedElem.parentObjectId].children.push(mappedElem)
        } else {
          if (
            mappedElem.rootObjectId === mappedElem.parentObjectId &&
            mappedElem.rootObjectId === 0
          ) {
            data.push(mappedElem)
          }
        }
      }
    }

    sortChildrenObjects(data)
    return sortObjectPermission(data).map(
      (tree: ObjectPermissionsTree): ObjectPermissionsTree => ({
        ...tree,
        rootObjectId: null,
      }),
    )
    // .sort((a: any, b: any) => a.objectFriendlyName.localeCompare(b.objectFriendlyName))
  }

  return []
}
