import { TableGroup } from '@/components/pages/common/TableComponents/GroupingModal/types'

export const defaultGrouping: { [key: string]: TableGroup[] } = {
  devices: [{ key: 'connection_state', orderBy: 'name', order: 'asc' }],
}

export const defaultConfigGroups = {
  devices: [
    {
      allowedMaxGroupLevel: 4,
      excludeChildGroupUID: [],
      groupName: 'Connectivity',
      groupNameKey: 'grpk_connection_state',
      groupUID: 'connection_state',
      label: 'Connectivity',
      labelKey: 'lblk_connection_state',
      outGroupFieldDisplayType: 'STRING',
      outGroupFieldValueType: 'INTEGER',
      valueType: 'INTEGER',
    },
  ],
}
