import { AnyAction } from 'redux'

import { AvailableModule } from '@/api/atmeye/common/types'
import { CommonActions } from '@/constants/atmeye/actions/actionsCommon'

export const commonAtmEyeActions = {
  setCurrentMedia: (payload: any) =>
    ({
      type: CommonActions.SetCurrentMedia,
      payload,
    } as const),

  setMediaRequest: (payload: any) =>
    ({
      type: CommonActions.SetMediaRequest,
      payload,
    } as const),

  getMediaRequest: (payload: any) =>
    ({
      type: CommonActions.GetMediaRequest,
      payload,
    } as const),

  setMediaErrorMessage: (payload: any) =>
    ({
      type: CommonActions.SetMediaRequestErrorMessage,
      payload,
    } as const),

  clearCurrentMediaData: () =>
    ({
      type: CommonActions.ClearCurrentMedia,
    } as const),
}

export const setAckWarning = (
  payload: {
    errorMessage: string
    messageParameters: { [key: string]: string }
  } | null,
): AnyAction => ({
  type: CommonActions.SetAckWarning,
  payload,
})

export const getAvailableModulesRequest = (): AnyAction => ({
  type: CommonActions.getAvailableModulesRequest,
})

export const getAvailableModulesResponse = (
  payload: {
    [key: string]: AvailableModule
  } | null,
): AnyAction => ({
  type: CommonActions.getAvailableModulesResponse,
  payload,
})
