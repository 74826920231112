export enum DictionariesActions {
  // Dictionary information
  GetDictionaryConfigRequest = '@/DICTIONARIES_GET_DICTIONARY_CONFIG_REQUEST',
  GetDictionaryConfigResponse = '@/DICTIONARIES_GET_DICTIONARY_CONFIG_RESPONSE',
  GetDictionaryConfigResponseFail = '@/DICTIONARIES_GET_DICTIONARY_CONFIG_RESPONSE_FAIL',

  GetDictionaryDataRequest = '@/DICTIONARIES_GET_DICTIONARY_DATA_REQUEST',
  GetDictionaryDataResponse = '@/DICTIONARIES_GET_DICTIONARY_DATA_RESPONSE',
  GetDictionaryDataResponseFail = '@/DICTIONARIES_GET_DICTIONARY_DATA_RESPONSE_FAIL',

  SaveDictionaryDataRequest = '@/DICTIONARIES_SAVE_DICTIONARY_DATA_REQUEST',
  SaveDictionaryDataResponse = '@/DICTIONARIES_SAVE_DICTIONARY_DATA_RESPONSE',
  SaveDictionaryDataResponseFail = '@/DICTIONARIES_SAVE_DICTIONARY_DATA_RESPONSE_FAIL',

  SaveVisibilityDataRequest = '@/DICTIONARIES_SAVE_VISIBILITY_DATA_REQUEST',
  SaveVisibilityDataResponse = '@/DICTIONARIES_SAVE_VISIBILITY_DATA_RESPONSE',
  SaveVisibilityDataResponseFail = '@/DICTIONARIES_SAVE_VISIBILITY_DATA_RESPONSE_FAIL',

  SetNewRows = '@/DICTIONARIES_SET_NEW_ROW',
  SetChangedRows = '@/DICTIONARIES_SET_CHANGED_ROWS',
  SetDeletedRows = '@/DICTIONARIES_SET_DELETED_ROWS',
  SetChangedVisibilityModules = '@/DICTIONARIES_SET_CHANGED_VISIBILITY_MODULES',
  SetDictionaryErrors = '@/DICTIONARIES_SET_DICTIONARY_ERRORS',

  // Dictionaries list
  GetDictionariesListDataRequest = '@/DICTIONARIES_GET_DICTIONARIES_LIST_DATA_REQUEST',
  GetDictionariesListDataResponse = '@/DICTIONARIES_GET_DICTIONARIES_LIST_DATA_RESPONSE',
  GetDictionariesListDataResponseFail = '@/DICTIONARIES_GET_DICTIONARIES_LIST_DATA_RESPONSE_FAIL',

  // Selected rows
  SetSelectedDictionary = '@/DICTIONARIES_SET_SELECTED_DICTIONARY',

  // Folding state dictionaries list
  SetIsFolded = '@?/DICTIONARIES_SET_IS_FOLDED',

  // Languages
  GetLanguagesRequest = '@/DICTIONARIES_GET_LANGUAGES_REQUEST',
  GetLanguagesResponse = '@/DICTIONARIES_GET_LANGUAGES_RESPONSE',
  GetLanguagesResponseFail = '@/DICTIONARIES_GET_LANGUAGES_RESPONSE_FAIL',

  SetLanguages = '@/DICTIONARIES_SET_LANGUAGES',
}
