import makeStyles from '@material-ui/core/styles/makeStyles'
import { styledComponentTheme } from '@/theme'

export const useClasses = makeStyles(({ spacing }) => ({
  modal: {
    padding: spacing(2, 4, 4),
    minWidth: spacing(80),
    maxWidth: spacing(152.5),
    height: spacing(107.5),
  },
  closeButton: {
    cursor: 'pointer',
  },
  header: {
    width: '100%',
    paddingTop: spacing(2),
  },
  title: {
    whiteSpace: 'nowrap',
    fontSize: styledComponentTheme.fontSizes.big,
    marginBottom: spacing(0),
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: spacing(1),
  },
  tableWrapper: {
    marginBottom: spacing(5),
  },
  table: {
    width: spacing(144.5),
    height: spacing(79),

    '& table': {
      tableLayout: 'auto',
    },
  },
  container: {
    height: '100%',
  },
  paper: {
    height: '100%',
    marginBottom: `${spacing(0.5)}px !important`,
  },
  headColumn: {
    width: 250,
  },
  column: {
    whiteSpace: 'nowrap',
    height: spacing(5),
  },
}))
