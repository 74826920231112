import { Template } from '@/api/atmeye/commonTypes'

// import { Filter, FiltersTemplate } from '@/api/atmeye/translactionsApi/types'
import { FieldTypes } from '../../../../pages/atmeye/Administration/LicenseList/ATMCoreLicenses/components/FiltersModalLicence/GenerateFormFields/types'

export type FiltersModalProps<F = Record<string, any>> = {
  formData: {
    initialValues: F
    onSubmit: (filter: F) => void
    enableReinitialize: boolean
  }
  defaultValues: F
  open: boolean
  handleClose: () => void
  filterFields: FieldTypes[]
  selectedFilters: F
  selectedFiltersTemplate: Template<F> | null
  handleApplyFilterTemplate: (tpl: Template<F> | null) => void
  requiredFilters?: Record<string, any>
  filterTemplatesType: string
  formikValidate?: (values?: any) => any
}

export enum MODAL_TYPE {
  MAIN = 'MAIN',
  SAVE = 'SAVE',
  EDIT = 'EDIT',
}
