import {
  TableBodyCell,
  TableBodyCellContent,
  TableBodyCellPristine,
} from '@/components/controls/AppTable/types'

export const replaceMultipleValuesWithExtraRow = (
  object: TableBodyCellPristine | TableBodyCellPristine[],
): TableBodyCell[] => {
  const obj = Array.isArray(object) ? [...object] : { ...object }
  let shouldCallAgain = false
  const lastRow = (Array.isArray(obj) ? obj.pop() : obj) || {}
  const keys = Object.keys(lastRow)
  const nextRow: { [key: string]: TableBodyCellContent | null } = {}
  const newStuff = keys.reduce((accum: TableBodyCell, key) => {
    if (Array.isArray(lastRow[key])) {
      lastRow[key] = [...lastRow[key]]
      shouldCallAgain = true
      accum[key] = lastRow[key].shift()
      nextRow[key] = lastRow[key].length > 1 ? lastRow[key] : lastRow[key][0]
      return accum
    } else {
      nextRow[key] = null
      const uncheckLastRowKey = lastRow[key]
      if (Array.isArray(obj) && !uncheckLastRowKey) {
        const prevRowValue = obj[obj.length - 1][key]
        obj[obj.length - 1][key] =
          typeof prevRowValue !== 'object' || prevRowValue === null
            ? { value: prevRowValue || '', styles: { borderBottom: 'none' } }
            : { ...prevRowValue, styles: { ...prevRowValue.styles, borderBottom: 'none' } }
        accum[key] =
          typeof prevRowValue === 'object' && prevRowValue && prevRowValue.forwardStylesToCopies
            ? {
                value: '',
                styles: { ...prevRowValue.styles },
                forwardStylesToCopies: true,
              }
            : uncheckLastRowKey
        return accum
      }
      accum[key] = uncheckLastRowKey
      return accum
    }
  }, {})
  return shouldCallAgain
    ? replaceMultipleValuesWithExtraRow([...(Array.isArray(obj) ? obj : []), newStuff, nextRow])
    : Array.isArray(obj)
    ? [...obj, keys.reduce((accum, key) => ({ ...accum, [key]: newStuff[key] || '' }), {})]
    : [newStuff]
}
