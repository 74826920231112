import { createTextInputField } from '@/components/controls/TextInput'

// types
import { GenericInputTypes } from './types'

export const useGenericInput = () => {
  const GenericInput = ({
    disabled,
    fieldName,
    label,
    required,
    type,
    placeholder,
    autoComplete,
    className,
  }: GenericInputTypes) =>
    createTextInputField({
      autoComplete: autoComplete || 'off',
      required: required || false,
      disabled,
      label: label,
      fullWidth: true,
      InputLabelProps: {
        shrink: true,
      },
      fieldName,
      type,
      placeholder,
      className,
    })

  return { GenericInput }
}
