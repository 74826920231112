import ButtonBase from '@material-ui/core/ButtonBase'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { ExitToApp, ExpandLess, ExpandMore, Lock } from '@material-ui/icons'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { isIE } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'

import { NewCommonApi } from '@/api/atmeye/common/newCommonAPI'
import Photo from '@/assets/usm/no-photo.svg'
import { AboutModal } from '@/components/blocks/cm'
import ChangePasswordModal from '@/components/blocks/cm/ChangePasswordModal'
import GlobalConfirmModal from '@/components/blocks/LogoutConfirmModal/component'
import ContextMenu from '@/components/controls/ContextMenu/component'
import { useUserMenu } from '@/components/wrappers/AppNavigation/components/UserMenu/useUserMenu'
import { REACT_APP_CM_STANDALONE, REACT_APP_VAULT_V1_STANDALONE } from '@/constants/moduleMode'
import {
  ATMEYE_BLOCK_PREFIX,
  COMPANIES_BLOCK_PREFIX,
  DICTIONARY_BLOCK_PREFIX,
  PRODUCTS_BLOCK_PREFIX,
  USM_BLOCK_PREFIX,
} from '@/constants/paths'
import useAppVersion from '@/utils/hooks/atmeye/useAppVersion'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
// types
import { Props } from './types'

const UserMenu: FC<Props> = ({ patch }: Props): React.ReactElement => {
  const isAtmeye = patch.includes(ATMEYE_BLOCK_PREFIX)

  const classes = useClasses()
  const {
    handleOpenCloseLogoutConfirmModal,
    handleChangePasswordModalClose,
    handleChangePassword,
    handleChangePasswordModalOpen,
    handleClick,
    isChangePasswordModalOpen,
    isLoading,
    error,
    handleSignOut,
    userPhotoBase64,
    userName,
    currentCompany,
    personName,
    anchorEl,
    handleClose,
    isShowLogoutConfirmModal,
    isLogoutLoading,
  } = useUserMenu({ isAtmeye })

  const isCM = REACT_APP_CM_STANDALONE === 'true'
  const isVaultV1 = REACT_APP_VAULT_V1_STANDALONE === 'true'
  const isServerBuildInfoAvailable = !isCM && !isVaultV1

  const buildInfo = useAppVersion(isServerBuildInfoAvailable)
  const [isOpenAboutModal, setIsOpenAboutModal] = useState(false)

  const translate = useTranslate()

  const userMenu = [
    {
      id: '1',
      content: (
        <>
          <Lock className={classes.icon} />
          <span className={classes.label}>
            <FormattedMessage id="title.changePassword" defaultMessage="Change password" />
          </span>
        </>
      ),
      handleClick: handleChangePasswordModalOpen,
    },
    {
      id: '2',
      content: (
        <>
          <ExitToApp className={classes.icon} />
          <span className={classes.label}>
            <FormattedMessage id="title.logout" defaultMessage="Logout" />
          </span>
        </>
      ),
      handleClick: handleOpenCloseLogoutConfirmModal,
    },
  ]

  if (isAtmeye) {
    userMenu.push({
      id: '3',
      content: (
        <>
          <ExitToApp style={{ opacity: 0 }} className={classes.icon} />
          <span className={classes.label}>
            <FormattedMessage id="title.about" defaultMessage="About" />
          </span>
        </>
      ),
      handleClick: () => {
        setIsOpenAboutModal(true)
      },
    })
  }

  return (
    <div className={classes.userMenuWrapper}>
      {/* @ todo change when back end will be ready */}
      {(patch.includes(USM_BLOCK_PREFIX) ||
        isAtmeye ||
        patch.includes(DICTIONARY_BLOCK_PREFIX) ||
        patch.includes(PRODUCTS_BLOCK_PREFIX) ||
        patch.includes(COMPANIES_BLOCK_PREFIX)) &&
      userPhotoBase64 ? (
        <img src={`data:image;base64,${userPhotoBase64}`} className={classes.personPhoto} />
      ) : (
        <img src={Photo} />
      )}
      <ButtonBase className={classes.button} onClick={handleClick}>
        <Typography className={classes.userName}>
          {`${personName || 'Person name'}`}
          <span className={classes.userCompany}>{` | ${currentCompany || 'Company name'}`}</span>
        </Typography>
        {!anchorEl ? (
          <ExpandMore className={classes.icon} />
        ) : (
          <ExpandLess className={classes.icon} />
        )}
      </ButtonBase>
      <ContextMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        classes={
          isIE
            ? {
                list: classes.list,
                paper: classes.menuPaper,
              }
            : undefined
        }
        options={!isIE ? userMenu : []}
      >
        {isIE ? (
          <>
            {userMenu.map(item => (
              <MenuItem
                key={item.id}
                disableRipple
                className={classes.item}
                onClick={item.handleClick}
              >
                {item.content}
              </MenuItem>
            ))}
          </>
        ) : (
          undefined
        )}
      </ContextMenu>
      <GlobalConfirmModal
        open={isShowLogoutConfirmModal}
        onClose={handleOpenCloseLogoutConfirmModal}
        onConfirm={handleSignOut}
        message={translate('translate#title.areYouSureYouWantLogout')}
        disableBackdropClick
        isButtonDisabled={isLogoutLoading}
      />
      {isChangePasswordModalOpen && (
        <ChangePasswordModal
          open={isChangePasswordModalOpen}
          handleClose={handleChangePasswordModalClose}
          handleChangePassword={handleChangePassword}
          isLoading={isLoading}
          error={error}
        />
      )}

      <AboutModal
        open={isOpenAboutModal}
        handleClose={() => {
          setIsOpenAboutModal(false)
        }}
        buildInfo={buildInfo}
      />
    </div>
  )
}

export default UserMenu
