import { isArray } from 'lodash-es'

export const getNestedId = (item: any, rowId: string): string | number => {
  const slittedRowId: string[] = rowId?.replace(/ /g, '').split('.')

  if (slittedRowId.length > 1) {
    let itemCopy = { ...item }

    slittedRowId.forEach((key: string) => {
      if (key && itemCopy) {
        if (isArray(itemCopy)) {
          itemCopy = itemCopy[0][key]
          return
        }
        if (itemCopy[key]) {
          itemCopy = itemCopy[key]
        }
      }
    })

    return itemCopy
  } else {
    return item[rowId]
  }
}
