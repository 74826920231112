import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles(({ spacing }) =>
  createStyles({
    legendWrapper: {
      display: 'flex',
    },
    legendItem: {
      display: 'flex',
      alignItems: 'center',
      padding: spacing(0.25, 1),
      borderRadius: spacing(1.5),
      marginBottom: spacing(2),
      marginRight: 30,
    },
    legendItemCircle: {
      display: 'inline',
      width: spacing(1),
      height: spacing(1),
      borderRadius: '50%',
      marginRight: spacing(1),
    },
    grabCursor: {
      cursor: 'grab!important',
    },
    pointerCursor: {
      cursor: 'pointer!important',
    },
    customTooltip: {
      color: 'black',
      background: '#e3e3e385',
      padding: '3px',
      borderRadius: '5px',
    },
    loaderWrapper: {
      position: 'absolute',
      zIndex: 100,
      height: '100%',
      width: '100%',
    },
  }),
)
