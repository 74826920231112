import { Button, Grid, Paper, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { ReactElement, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'

import CloseButton from '@/components/blocks/CloseButton'
import { getActErrorModal, setActErrorInitial } from '@/store/common/errors'
import { useTranslate } from '@/utils/internalization'

import { DraggableDialog } from '../DraggableDialog'
import { useClasses } from './styles'

const ActErrorModal = (): ReactElement => {
  const classes = useClasses()
  const dispatch = useDispatch()
  const translate = useTranslate()

  const { isVisible, errorMessage, actionPayload, actionType, type } = useSelector(getActErrorModal)

  const handleUseForce = useCallback(() => {
    const action = (): AnyAction => ({
      type: actionType,
      payload: actionPayload,
    })

    dispatch(action())
    dispatch(setActErrorInitial())
  }, [])

  const handleClose = useCallback(() => {
    dispatch(setActErrorInitial())
  }, [])

  return (
    <DraggableDialog
      id="actErrorModal"
      classes={{ paper: classes.dialog }}
      maxWidth="md"
      fullWidth
      open={isVisible}
      keepMounted
      onClose={handleClose}
    >
      <CloseButton onClick={handleClose} absolute />
      <Paper className={classes.paper}>
        <Grid item md={12}>
          <Typography variant="h3" className={classes.modalHeader}>
            {translate('translate#cm.Error')}
          </Typography>
        </Grid>
        <Typography className={classes.errorText}>
          {errorMessage || translate('translate#cm.ErrorWindowGenericError')}
        </Typography>
        <Grid className={classes.buttonContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={type === 'use_force' ? handleUseForce : handleClose}
            className={classes.button}
          >
            {translate('translate#cm.ButtonOK')}
          </Button>
          {type === 'use_force' && (
            <Button
              color="primary"
              variant="outlined"
              onClick={handleClose}
              className={clsx(classes.button, classes.buttonClose)}
            >
              {translate('translate#cm.ButtonCancel')}
            </Button>
          )}
        </Grid>
      </Paper>
    </DraggableDialog>
  )
}

export default ActErrorModal
