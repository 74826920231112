import makeStyles from '@material-ui/core/styles/makeStyles'
import { styledComponentTheme } from '@/theme'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  headAction: {
    minWidth: spacing(4),
    padding: 0,
    paddingLeft: spacing(1),
    '&.MuiTableCell-head.MuiTableCell-root': {},
  },
  tableCell: {
    whiteSpace: 'nowrap',
    '&.MuiTableCell-head.MuiTableCell-root': {},
  },
  thead: {
    background: '#f8f8f8',
  },
  withoutAction: {
    paddingTop: spacing(0.5),
    paddingBottom: spacing(0.5),
  },
  title: {
    fontSize: styledComponentTheme.fontSizes.small,
  },
  activeFilterIcon: {
    '&::after': {
      content: '""',
      position: 'absolute',
      right: spacing(0.5),
      top: spacing(1),
      width: '20%',
      height: '20%',
      borderRadius: '50%',
      backgroundColor: palette.primary.main,
    },
  },
  tableHeader: {
    display: 'table-row',
  },
}))
