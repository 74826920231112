import makeStyles from '@material-ui/core/styles/makeStyles'

import { styledComponentTheme } from '@/theme'
import colors from '@/theme/colors'

type Props = {
  display?: string
}

export const useClasses = makeStyles(({ spacing, palette }) => ({
  closeButton: {
    position: 'absolute',
    top: spacing(1),
    right: spacing(1),
    cursor: 'pointer',
    zIndex: 100,
  },
  input: {
    width: '100%',
  },
  button: {
    minWidth: spacing(20),
  },
  container: {
    display: (props: Props) => props.display,
    flexDirection: 'column',
    overflow: 'auto',
    padding: spacing(3, 3),
    paddingRight: spacing(1),
  },
  clearButton: {
    color: palette.error.main,
    fontWeight: 'bold',
    backgroundColor: palette.error.light,
    marginRight: spacing(1),
    '&:hover': {
      backgroundColor: palette.error.light,
    },
  },
  fileButton: {
    display: 'flex',
    flexBasis: '50%',
    maxWidth: '50%',
    backgroundColor: 'white',
    padding: spacing(0, 2),
    margin: spacing(2, 0),
  },
  extension: {
    position: 'absolute',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  fileSize: {
    color: palette.grey[200],
  },
  addFileButton: {
    overflow: 'hidden',
  },
  fileInput: {
    position: 'absolute',
    opacity: 0,
    width: '100%',
    height: '100%',
    padding: '100%',
    cursor: 'pointer',

    '&:focus': {
      outline: 'none',
    },
  },
  closeIconButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  closeIcon: {
    fontSize: spacing(2),
  },
  form: {
    display: (props: Props) => props.display,
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    marginTop: spacing(2),
  },
  fields: {
    flex: 1,
    paddingRight: spacing(2),
    overflow: 'auto',
  },
  buttons: {
    paddingRight: spacing(2),
    marginTop: spacing(2),
  },

  cancelButton: {
    background: 'transparent',
    marginRight: spacing(1),
    color: colors.primary,
    border: '1px solid',
    borderColor: colors.primary,
    '&:hover': {
      borderColor: palette.grey[200],
      backgroundColor: colors.grey50,
    },
  },
  attachFileText: {
    marginBottom: spacing(0.5),
  },
  // styles only for IE
  inputFileForIE: {
    display: 'none',
  },
  customFileUploadForIE: {
    borderRadius: '4px',
    fontWeight: 'bold',
    border: '1px solid #ccc',
    display: 'inline-block',
    padding: '6px 12px',
    cursor: 'pointer',
    backgroundColor: colors.primary,
    color: 'white',
    '&:hover': {
      backgroundColor: palette.primary.dark,
    },
  },
  unitOption: {
    display: 'flex',
    flexDirection: 'column',
  },
  unitOptionName: {
    marginBottom: spacing(0.5),
  },
  unitAddInfo: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: styledComponentTheme.fontSizes.small,
  },
}))
