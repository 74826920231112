import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  subTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'normal',
    marginBottom: spacing(1),
  },
  asterisk: {
    color: 'red',
    display: 'inline',
  },
}))
