import { makeStyles } from '@material-ui/core'

export const useClasses = makeStyles(() => ({
  draggableHandle: {
    cursor: 'move',
    position: 'absolute',
    width: '100%',
    height: '20px',
    zIndex: 1,
    transition: 'all 2s all 0.2s cubic-bezier(0.45, 0.05, 0.55, 0.95)',

    '&:hover': {
      background: '#F9F9F9',
    },
  },
}))
