import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  checkbox: {
    color: palette.grey[200],
    padding: spacing(0.5),
  },
  label: {
    padding: spacing(0, 0.5),
    textTransform: 'capitalize',
  },
}))
