import Grid from '@material-ui/core/Grid'
import { Add, Delete } from '@material-ui/icons'
import clsx from 'clsx'
import { FieldProps } from 'formik'
import React, { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import Button from '@/components/controls/Button'
import { TextInput } from '@/components/controls/TextInput'
import { getPOSServiceFormConfigParameters } from '@/store/sd/drawerActions/selectors/newPOS'

import {
  FIELD_NAME_TERMINAL_IDS,
  POS_AQUIRER_LUNO,
  POS_MERCHANT_ID,
  POS_MERCHANT_ID_AMEX,
  POS_TERMINAL_ID_AMEX,
} from '../ServiceTypeForm/types'
import { useClasses } from './styles'

export interface IdsFields {
  [POS_AQUIRER_LUNO]: string
  [POS_TERMINAL_ID_AMEX]: string
  [POS_MERCHANT_ID]: string
  [POS_MERCHANT_ID_AMEX]: string
}

const initialIds: IdsFields = {
  [POS_AQUIRER_LUNO]: '',
  [POS_TERMINAL_ID_AMEX]: '',
  [POS_MERCHANT_ID]: '',
  [POS_MERCHANT_ID_AMEX]: '',
}

export const ServiceTerminals = ({ form: { getFieldHelpers } }: FieldProps): React.ReactElement => {
  const classes = useClasses()
  const fieldParameters = useSelector(getPOSServiceFormConfigParameters)

  const [IdsFields, setIds] = useState<Array<IdsFields>>([initialIds])

  const onChangeIds = useMemo(() => getFieldHelpers(FIELD_NAME_TERMINAL_IDS).setValue, [])

  const hasAmexField = useMemo((): boolean => fieldParameters[POS_TERMINAL_ID_AMEX]?.visible, [
    fieldParameters[POS_TERMINAL_ID_AMEX],
  ])

  useEffect((): void => {
    onChangeIds(IdsFields)
  }, [IdsFields])

  const handleIdsChange = useCallback(
    (
      idx: number,
      kindOfField: string,
    ): ((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void) => (e): void => {
      const { value } = e.currentTarget

      const newIds = IdsFields.map((terminal, index) =>
        idx !== index
          ? terminal
          : {
              ...terminal,
              ...(kindOfField === POS_TERMINAL_ID_AMEX && { [POS_TERMINAL_ID_AMEX]: value }),
              ...(kindOfField === POS_AQUIRER_LUNO && { [POS_AQUIRER_LUNO]: value }),
              ...(kindOfField === POS_MERCHANT_ID && { [POS_MERCHANT_ID]: value }),
              ...(kindOfField === POS_MERCHANT_ID_AMEX && { [POS_MERCHANT_ID_AMEX]: value }),
            },
      )
      setIds(newIds)
    },
    [IdsFields],
  )

  const handleAddTerminal = useCallback((): void => {
    setIds(currentIds => [
      ...currentIds,
      {
        [POS_TERMINAL_ID_AMEX]: '',
        [POS_AQUIRER_LUNO]: '',
        [POS_MERCHANT_ID]: '',
        [POS_MERCHANT_ID_AMEX]: '',
      },
    ])
  }, [])

  const handleRemoveTerminal = useCallback(
    (idx: number): (() => void) => (): void => {
      if (IdsFields.length === 1) {
        return setIds([initialIds])
      }
      const newIds = [...IdsFields]
      newIds.splice(idx, 1)

      setIds(newIds)
    },
    [IdsFields],
  )

  return (
    <Grid container spacing={1} justifyContent="flex-start">
      {IdsFields.map(
        (
          {
            [POS_AQUIRER_LUNO]: posaquirerluno,
            [POS_TERMINAL_ID_AMEX]: posterminalidAmex,
            [POS_MERCHANT_ID]: posmerchantid,
            [POS_MERCHANT_ID_AMEX]: posmerchantidAmex,
          },
          idx: number,
        ) => (
          <Fragment key={idx}>
            <Grid container item wrap="nowrap" spacing={2}>
              <Grid item>{idx + 1}</Grid>
              <Grid item md={2} className={classes.input}>
                <TextInput
                  margin="none"
                  value={posaquirerluno}
                  onChange={handleIdsChange(idx, POS_AQUIRER_LUNO)}
                  required={
                    (fieldParameters &&
                      fieldParameters[POS_AQUIRER_LUNO] &&
                      fieldParameters[POS_AQUIRER_LUNO].required) ||
                    false
                  }
                  placeholder={
                    (fieldParameters &&
                      fieldParameters[POS_AQUIRER_LUNO] &&
                      fieldParameters[POS_AQUIRER_LUNO].description) ||
                    ''
                  }
                  label={
                    (fieldParameters &&
                      fieldParameters[POS_AQUIRER_LUNO] &&
                      fieldParameters[POS_AQUIRER_LUNO].displayName) ||
                    ''
                  }
                  className={classes.textField}
                  pattern={/(^\S(.*)\S$)/}
                />
              </Grid>
              <Grid item md={2} className={classes.input}>
                <TextInput
                  margin="none"
                  value={posmerchantid}
                  onChange={handleIdsChange(idx, POS_MERCHANT_ID)}
                  required={
                    (fieldParameters &&
                      fieldParameters[POS_MERCHANT_ID] &&
                      fieldParameters[POS_MERCHANT_ID].required) ||
                    false
                  }
                  placeholder={
                    (fieldParameters &&
                      fieldParameters[POS_MERCHANT_ID] &&
                      fieldParameters[POS_MERCHANT_ID].description) ||
                    ''
                  }
                  label={
                    (fieldParameters &&
                      fieldParameters[POS_MERCHANT_ID] &&
                      fieldParameters[POS_MERCHANT_ID].displayName) ||
                    ''
                  }
                  className={classes.textField}
                  pattern={/(^\S(.*)\S$)/}
                />
              </Grid>
              {hasAmexField && (
                <Grid item md={3} className={classes.input}>
                  <TextInput
                    margin="none"
                    value={posterminalidAmex}
                    onChange={handleIdsChange(idx, POS_TERMINAL_ID_AMEX)}
                    required={
                      (fieldParameters &&
                        fieldParameters[POS_TERMINAL_ID_AMEX] &&
                        fieldParameters[POS_TERMINAL_ID_AMEX].required) ||
                      false
                    }
                    placeholder={
                      (fieldParameters &&
                        fieldParameters[POS_TERMINAL_ID_AMEX] &&
                        fieldParameters[POS_TERMINAL_ID_AMEX].description) ||
                      ''
                    }
                    label={
                      (fieldParameters &&
                        fieldParameters[POS_TERMINAL_ID_AMEX] &&
                        fieldParameters[POS_TERMINAL_ID_AMEX].displayName) ||
                      ''
                    }
                    className={classes.textField}
                  />
                </Grid>
              )}
              {hasAmexField && (
                <Grid item md={3} className={classes.input}>
                  <TextInput
                    margin="none"
                    value={posmerchantidAmex}
                    onChange={handleIdsChange(idx, POS_MERCHANT_ID_AMEX)}
                    required={
                      (fieldParameters &&
                        fieldParameters[POS_MERCHANT_ID_AMEX] &&
                        fieldParameters[POS_MERCHANT_ID_AMEX].required) ||
                      false
                    }
                    placeholder={
                      (fieldParameters &&
                        fieldParameters[POS_MERCHANT_ID_AMEX] &&
                        fieldParameters[POS_MERCHANT_ID_AMEX].description) ||
                      ''
                    }
                    label={
                      (fieldParameters &&
                        fieldParameters[POS_MERCHANT_ID_AMEX] &&
                        fieldParameters[POS_MERCHANT_ID_AMEX].displayName) ||
                      ''
                    }
                    className={classes.textField}
                  />
                </Grid>
              )}

              <Grid md="auto" item>
                <Button
                  onClick={handleRemoveTerminal(idx)}
                  width="xs"
                  variant="contained"
                  className={clsx(classes.deleteButton, {
                    [classes.hidden]: idx === 0 && IdsFields.length === 1,
                  })}
                >
                  <Delete />
                </Button>
              </Grid>
              <Grid item md={2}>
                <Button onClick={handleAddTerminal} variant="contained" light startIcon={<Add />}>
                  <FormattedMessage id="action.addTerminal" defaultMessage="Add Terminal" />
                </Button>
              </Grid>
            </Grid>
          </Fragment>
        ),
      )}
    </Grid>
  )
}
