import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles(({ mixins, spacing }) =>
  createStyles({
    buttonWrapper: {
      display: 'flex',
      ...mixins.gutters(),
    },
    button: {
      flex: 1,
    },
    cancelButton: {
      color: '#50a8ae',
      fontWeight: 'bold',
      backgroundColor: 'transparent',
      border: '1px solid rgba(80, 168, 174, 0.5)',
      marginRight: spacing(1),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
)
