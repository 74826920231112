import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(() => ({
  tableHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  tableTitle: {
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
    fontSize: '20px',
    marginBottom: '0',
  },
  tableTitleExplanation: {
    fontSize: '16px',
    color: 'silver',
  },
}))
