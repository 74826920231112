// core
import { useFormik } from 'formik'
// types
import { FormikHelpers } from 'formik/dist/types'
import moment from 'moment'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { useBackupValues } from '@/components/controls/GeneratedField'
import { BackupValues } from '@/components/controls/GeneratedField/types'
import {
  LoginField,
  PersonalInformationField,
} from '@/components/pages/usm/UsersPage/components/PersonalInformation/types'
import { UserInformationField } from '@/components/pages/usm/UsersPage/components/UserInformation/components/modals/PersonModal/types'
import { useBackupValuesCompany } from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/AddUserModal/shortNameLookup'
import {
  AddNewUserPersonalCardField,
  AddUserPersonalCardValues,
  Menu,
} from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/AddUserModal/types'
import { AppNavigationRoutes, USM_BLOCK_PREFIX } from '@/constants'
import {
  actionsUsersUSM,
  getCreateUserLookupConfig,
  getErrorUsersUsm,
  getIsFetchingAddNewUserModal,
} from '@/store/usm/users'
import { useErrorMessage } from '@/utils/hooks/useErrrorMessage'

import { validate } from '../helpers/validate/validate'
import { Props, UserInfoTableTypes } from './types'

const MAX_SIZE = 2

export const useAddNewUserModal = ({ handleClose, selectedRows }: Props) => {
  const getValueFromBackupValues = <K extends keyof BackupValues>(
    obj: BackupValues,
    key: K,
    tableValues: string,
    backupValuesProp: 'value' | 'name',
  ): string => {
    if (key in obj) {
      return obj[key][backupValuesProp]
    } else {
      return tableValues
    }
  }

  const dispatch = useDispatch()
  const history = useHistory()
  const globalError = useSelector(getErrorUsersUsm)
  const lookupConfig = useSelector(getCreateUserLookupConfig)
  const isLoadingConfig = useSelector(getIsFetchingAddNewUserModal)

  const menuButton: Menu = {
    personalInformation: 'translate#usm.title.add.user.modal.menu.button.personal.information',
    loginInformation: 'translate#usm.title.add.user.modal.menu.button.login.information',
    userInformation: 'translate#usm.title.add.user.modal.menu.button.user.information',
  }
  // form =====================================================
  const formInitialValues: AddUserPersonalCardValues = {
    [AddNewUserPersonalCardField.ExistingPerson]: '',
    [PersonalInformationField.FirstName]: '',
    [PersonalInformationField.LastName]: '',
    [PersonalInformationField.MiddleName]: '',
    [PersonalInformationField.Salutation]: '',
    [PersonalInformationField.Gender]: '',
    [LoginField.Login]: '',
    [LoginField.Password]: '',
    [LoginField.RepeatPassword]: '',
    [LoginField.TryCount]: '5',
    [AddNewUserPersonalCardField.ChangePassword]: '',
    [UserInformationField.Company]: '',
    [UserInformationField.UserName]: '',
    [UserInformationField.UserGroup]: '',
    [UserInformationField.EMail]: '',
    [UserInformationField.ExpiryDate]: '',
    [UserInformationField.NeverExpires]: false,
    [UserInformationField.AllowAutoProlong]: false,
    [UserInformationField.RoleId]: '',
    [UserInformationField.RoleName]: '',
    [AddNewUserPersonalCardField.AddPhoto]: '',
  }

  const formatTypeFiles = ['image/png', 'image/jpeg']

  const [errorsLocalState, setErrorsLocalState] = useState({
    errorsPhoto: '',
    alreadyExistUserName: '',
  })
  const [handleFile, setFieldValuePhoto] = useState<string | ArrayBuffer | null | undefined>(
    undefined,
  )

  const [activeButton, setActiveButton] = useState(menuButton.personalInformation)
  const [userInfoTable, setUserInfoTable] = useState<Array<UserInfoTableTypes>>([])

  // active-tabs-form
  const handleSetActiveButton = useCallback(
    (value: string): (() => void) => (): void => {
      setActiveButton(value)
    },
    [],
  )

  const formik = useFormik({
    validate,
    onSubmit: () => {
      console.log()
    },
    initialValues: formInitialValues,
  })
  const {
    values,
    resetForm,
    handleSubmit,
    isValid,
    dirty,
    errors,
    setValues,
    setFieldValue,
    setErrors,
    setFieldError,
  } = formik

  const { setErrorMessages, errorMessages } = useErrorMessage({
    error: globalError,
    formActions: { setErrors, setFieldError },
  })

  const useBackupValuesCompanyAndShortName = useBackupValuesCompany()
  const generatedFieldBackupOptions = useBackupValues()
  const generateCompanyName = useBackupValues()

  useEffect(() => {
    !values[AddNewUserPersonalCardField.ExistingPerson] &&
      setValues({
        ...values,
        [AddNewUserPersonalCardField.UserName]: values[AddNewUserPersonalCardField.FirstName]
          ? `${values[AddNewUserPersonalCardField.FirstName]}${
              values[AddNewUserPersonalCardField.MiddleName]
                ? ' ' + values[AddNewUserPersonalCardField.MiddleName]
                : ''
            } ${values[AddNewUserPersonalCardField.LastName]} ${useBackupValuesCompanyAndShortName
              .backupValues?.companyshortname || ''} `
          : '',
      }) &&
      setUserInfoTable(
        userInfoTable.map(u => ({
          ...u,
          userName: values[AddNewUserPersonalCardField.FirstName]
            ? `${values[AddNewUserPersonalCardField.FirstName]}${
                values[AddNewUserPersonalCardField.MiddleName]
                  ? ' ' + values[AddNewUserPersonalCardField.MiddleName]
                  : ''
              } ${values[AddNewUserPersonalCardField.LastName]} ${u.companyShortName}`
            : '',
        })),
      )
  }, [values.firstName, values.lastName, values.middleName, values[UserInformationField.Company]])

  useEffect(() => {
    values[AddNewUserPersonalCardField.ExistingPerson] &&
      setValues({
        ...values,
        [AddNewUserPersonalCardField.FirstName]: '',
        [AddNewUserPersonalCardField.MiddleName]: '',
        [AddNewUserPersonalCardField.LastName]: '',
        [AddNewUserPersonalCardField.Salutation]: '',
        [AddNewUserPersonalCardField.Gender]: '',
        [AddNewUserPersonalCardField.UserName]: `${getValueFromBackupValues<'personId'>(
          generatedFieldBackupOptions.backupValues,
          'personId',
          values[AddNewUserPersonalCardField.ExistingPerson],
          'name',
        )} ${useBackupValuesCompanyAndShortName.backupValues?.companyshortname || ''}`,
      }) &&
      setUserInfoTable(
        userInfoTable.map(u => ({
          ...u,
          userName: `${getValueFromBackupValues<'personId'>(
            generatedFieldBackupOptions.backupValues,
            'personId',
            values[AddNewUserPersonalCardField.ExistingPerson],
            'name',
          )} ${u.companyShortName}`,
        })),
      )

    setFieldValuePhoto(undefined)
  }, [values[AddNewUserPersonalCardField.ExistingPerson], values[UserInformationField.Company]])

  const handleCloseForm = useCallback(() => {
    handleClose()
    resetForm({ values: formInitialValues })
    setErrorsLocalState({ errorsPhoto: '', alreadyExistUserName: '' })
    setActiveButton(menuButton.personalInformation)
    setUserInfoTable([])
    useBackupValuesCompanyAndShortName.setBackupValues(null)
    generatedFieldBackupOptions.setBackupValues({})
    generateCompanyName.setBackupValues({})
    setErrorMessages([])

    dispatch(actionsUsersUSM.cleanErrors())
  }, [handleClose, resetForm, activeButton, userInfoTable, history])

  const handleAfterSuccessCreatedNewUser = (): void => {
    handleCloseForm()
    if (history.location.pathname !== `${USM_BLOCK_PREFIX}${AppNavigationRoutes.USmUsersPage}`) {
      history.push(`${USM_BLOCK_PREFIX}${AppNavigationRoutes.USmUsersPage}`)
    }
  }

  // submit Form

  const handleSubmitForm = (
    values: AddUserPersonalCardValues,
    formActions: FormikHelpers<AddUserPersonalCardValues>,
  ): void => {
    const data = {
      loginInfo: {
        loginId: 0,
        loginName: values[LoginField.Login],
        password: values[LoginField.Password],
        repeatPassword: values[LoginField.RepeatPassword],
        sysFlag: 0,
        tryCount: Number(values[LoginField.TryCount]),
      },
      personalInfo: {
        firstName: values[PersonalInformationField.FirstName],
        gender: values[PersonalInformationField.Gender]
          ? values[PersonalInformationField.Gender]
          : null,
        lastName: values[PersonalInformationField.LastName],
        middleName: values[PersonalInformationField.MiddleName],
        personId: values[AddNewUserPersonalCardField.ExistingPerson]
          ? Number(values[AddNewUserPersonalCardField.ExistingPerson])
          : 0,
        photo: '',
        salutationId: 0,
      },
      userInfo: userInfoTable.map(u => ({
        accountDisabled: u.disabled,
        accountExpirationDate: u.expireDate.length
          ? moment.utc(u.expireDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
          : '',
        companyId: u.companyId,
        companyName: u.company,
        emailAddress: u.email,
        userGroupId: u.idUserGroup,
        userGroupName: u.userGroup,
        userId: 0,
        userName: u.userName,
        allowAutoProlong: u.allowAutoProlong,
        roleId: Number(u.roleId),
        roleName: u.roleName,
      })),
    }

    dispatch(
      actionsUsersUSM.addNewUserAsync<AddUserPersonalCardValues>(data, {
        formActions,
        handleClose: handleAfterSuccessCreatedNewUser,
      }),
    )
  }

  // =================================================================

  // select-photo

  const validatePhoto = (data: FileList | null): void | boolean => {
    if (data?.length === 1) {
      const size = data[0].size / 1024 / 1024
      if (size > MAX_SIZE) {
        setErrorsLocalState({
          alreadyExistUserName: '',
          errorsPhoto: `file is ${size} mb,max size ${MAX_SIZE} mb`,
        })
        return true
      }
      if (!formatTypeFiles.includes(data[0].type)) {
        setErrorsLocalState({
          alreadyExistUserName: '',
          errorsPhoto: `format ${data[0].type} is not valid, only .png, .jpeg`,
        })
        return true
      } else {
        return false
      }
    }
  }

  const handleFileInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault()
      const { files } = event.target
      if (!validatePhoto(files)) {
        const reader = new FileReader()
        reader.onloadend = (): void => {
          setFieldValuePhoto(reader.result)
          setErrorsLocalState({ errorsPhoto: '', alreadyExistUserName: '' })
        }
        if (files && files.length) {
          reader.readAsDataURL(files[0])
        }
      }
    },
    [setFieldValuePhoto, errorsLocalState],
  )

  // add-new-user-to-table

  const handleAddNewUserToTable = useCallback(
    (tableValues: AddUserPersonalCardValues): (() => void) => (): void => {
      const candidate = userInfoTable.find(
        u =>
          u.userName.toLocaleLowerCase() ===
            tableValues[UserInformationField.UserName].toLocaleLowerCase() ||
          u.companyId.toLocaleLowerCase() ===
            tableValues[UserInformationField.Company].toLocaleLowerCase(),
      )

      if (candidate) {
        errors[UserInformationField.Company] = 'addUserCompanyDuplicate'

        return setErrorsLocalState({
          errorsPhoto: '',
          alreadyExistUserName: `user ${tableValues[UserInformationField.UserName]} ${
            tableValues[UserInformationField.Company]
          } already exist`,
        })
      }

      const user = {
        id: Date.now(),
        company: useBackupValuesCompanyAndShortName.backupValues?.name || '',
        companyShortName: useBackupValuesCompanyAndShortName.backupValues?.companyshortname || '',
        companyId: tableValues[UserInformationField.Company],
        userName: tableValues[UserInformationField.UserName],
        userGroup: getValueFromBackupValues<'userGroupId'>(
          generateCompanyName.backupValues,
          'userGroupId',
          tableValues[UserInformationField.UserGroup],
          'name',
        ),
        idUserGroup: tableValues[UserInformationField.UserGroup],
        email: tableValues[UserInformationField.EMail],
        expireDate:
          tableValues[UserInformationField.ExpiryDate] &&
          moment(tableValues[UserInformationField.ExpiryDate])
            .utc()
            .format('YYYY.MM.DD'),
        disabled: false,
        allowAutoProlong: tableValues[UserInformationField.AllowAutoProlong],
        roleId: tableValues[UserInformationField.RoleId],
        roleName: tableValues[UserInformationField.RoleName],
      }
      setErrorsLocalState({ errorsPhoto: '', alreadyExistUserName: '' })
      setUserInfoTable(table => [...table, user])
      resetForm({
        values: {
          ...tableValues,
          [UserInformationField.Company]: '',
          [UserInformationField.UserName]: '',
          [UserInformationField.UserGroup]: '',
          [UserInformationField.EMail]: '',
          [UserInformationField.ExpiryDate]: '',
          [UserInformationField.NeverExpires]: false,
          [UserInformationField.AllowAutoProlong]: false,
          [UserInformationField.RoleId]: '',
          [UserInformationField.RoleName]: '',
        },
      })
    },
    [userInfoTable, useBackupValuesCompanyAndShortName],
  )

  const handleDeleteTableRows = useCallback((): void => {
    const id = selectedRows.map(el => el.id)
    setUserInfoTable(user => user.filter(el => !id.includes(el.id)))
  }, [userInfoTable, selectedRows])

  return {
    setErrorsLocalState,
    errorsLocalState,
    handleFile,
    handleFileInputChange,
    setFieldValuePhoto,
    formInitialValues,
    menuButton,
    handleCloseForm,
    values,
    resetForm,
    handleSubmit,
    isValid,
    dirty,
    formik,
    errors,
    activeButton,
    handleSetActiveButton,
    userInfoTable,
    handleAddNewUserToTable,
    handleSubmitForm,
    globalError,
    handleDeleteTableRows,
    lookupConfig,
    setFieldValue,
    isLoadingConfig,
    useBackupValuesCompanyAndShortName,
    errorMessages,
    generatedFieldBackupOptions,
    generateCompanyName,
  }
}
