import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  wrapper: {
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
  },
  editButton: {
    padding: spacing(0.5),
    marginRight: spacing(1),
  },
  deleteButton: {
    padding: spacing(0.5),
    color: palette.error.main,
    backgroundColor: palette.error.light,
    '&:hover': {
      backgroundColor: palette.error.light,
    },
  },
  asterisk: {
    color: 'red',
    display: 'inline',
  },
  buttons: {
    marginTop: spacing(2),
  },
  loaderWrapper: {
    height: spacing(4),
    width: spacing(4),
    margin: 'auto',
    marginRight: spacing(1),
  },
  saveWrap: {
    display: 'flex',
    alignItems: 'center',
  },
}))
