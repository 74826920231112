import { FormikValues } from 'formik'
import { AnyAction } from 'redux'

import { GetGroupDetailsResponse, GetTicketsBodyFilterItem } from '@/api/sd/ticketsApi/types'
import { TableFilter } from '@/components/controls/AppTable/types'
import { TableGroup } from '@/components/pages/sd/TicketsPage/components/GroupingModal/types'
import { AtmeyeDeviceActions, TicketActions } from '@/constants/index'
import {
  ApiGroupTemplate,
  DevicesConfigResponse,
  ParentGroup,
  ResponseError,
  TypeOfTickets,
  UIGroupTemplate,
} from '@/types'
import { ActualCountParentGroupTypes } from '@/types/atmeye/deviceTypes'
import { MultiSelectConfigValuesTypes } from '@/types/atmeye/MultiSelectConfigValues'
import { GetTicketsResponse } from '@/types/sd/common'
import { WindowType } from '@/types/sd/tickets/ticket'
import { TicketDetailsResponse } from '@/types/sd/tickets/ticketDetails'

export const setSelectedGroupingTemplate = (template: string): AnyAction => ({
  type: AtmeyeDeviceActions.SetSelectedGroupingTemplate,
  payload: template,
})

export const getDevicesRequest = (isInitRequest = false): AnyAction => ({
  type: AtmeyeDeviceActions.GetDevicesRequest,
  payload: { isInitRequest },
})

export const getTicketsResponse = (tickets: GetTicketsResponse): AnyAction => ({
  type: TicketActions.GetTicketResponse,
  payload: tickets,
})

export const getTicketsResponseFail = (error: ResponseError): AnyAction => ({
  type: TicketActions.GetTicketResponseFail,
  payload: { error },
})

export const getDevicesExportRequest = (outputFields: any, ext: string): AnyAction => ({
  type: AtmeyeDeviceActions.GetDevicesExport,
  payload: {
    outputFields: outputFields,
    format: ext,
  },
})

export const getExportResponse = (file: any): AnyAction => ({
  type: AtmeyeDeviceActions.GetExportResponse,
  payload: file,
})

export const getExportResponseFail = (error: ResponseError): AnyAction => ({
  type: AtmeyeDeviceActions.GetExportResponseFail,
  payload: { error },
})

export const getDevicesExportGroupedRequest = (outputFields: any, ext: string): AnyAction => ({
  type: AtmeyeDeviceActions.GetDevicesExportGrouped,
  payload: {
    outputFields: outputFields,
    format: ext,
  },
})

export const getExportGroupedResponse = (file: any): AnyAction => ({
  type: AtmeyeDeviceActions.GetExportGroupedResponse,
  payload: file,
})

export const getExportGroupedResponseFail = (error: ResponseError): AnyAction => ({
  type: AtmeyeDeviceActions.GetExportGroupedResponseFail,
  payload: { error },
})

export const getGroupDetailsRequest = (groupValue: string): AnyAction => ({
  type: AtmeyeDeviceActions.GetGroupDetailsRequest,
  groupValue,
})

export const getGroupDetailsResponse = (details: GetGroupDetailsResponse): AnyAction => ({
  type: TicketActions.GetGroupDetailsResponse,
  payload: details,
})

export const getGroupDetailsResponseFail = (error: ResponseError): AnyAction => ({
  type: TicketActions.GetGroupDetailsResponseFail,
  payload: { error },
})

export const setParentGroups = (parentGroups: ParentGroup[]): AnyAction => ({
  type: AtmeyeDeviceActions.SetParentGroups,
  payload: parentGroups,
})

export const changeSelectedFilters = (filters: FormikValues): AnyAction => ({
  type: TicketActions.ChangeSelectedFilters,
  payload: filters,
})

export const getDevicesFilterTemplatesRequest = (): AnyAction => ({
  type: AtmeyeDeviceActions.GetFiltersTemplatesRequest,
})

export const setFilterTemplatesResponse = (payload: { id: string; name: string }[]): AnyAction => ({
  type: AtmeyeDeviceActions.SetFilterTemplatesResponse,
  payload,
})

export const createFilterTemplateRequest = (payload: {
  filters: GetTicketsBodyFilterItem[]
  name: string
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: AtmeyeDeviceActions.CreateFilterTemplateRequest,
  payload,
})

export const createSdFilterTemplateResponse = (payload: any): AnyAction => ({
  type: TicketActions.CreateSdFilterTemplateResponse,
  payload,
})

export const updateFilterTemplateRequest = (payload: {
  filters: any
  name: string
  windowType: WindowType
  id: string
  translate: any
}): AnyAction => ({
  type: AtmeyeDeviceActions.UpdateFilterTemplateRequest,
  payload,
})

export const collectFilterTemplateFields = (filterTemplateFields: {
  fieldName: string
  value: { id: string; displayName: string }
}): AnyAction => ({
  type: TicketActions.CollectFilterTemplateFields,
  payload: filterTemplateFields,
})

export const updateSdFilterTemplateResponse = (payload: {
  filters: any
  name: string
  windowType: WindowType
  id: string
  translate: any
}): AnyAction => ({
  type: TicketActions.UpdateSdFilterTemplateResponse,
  payload,
})

export const deleteFilterTemplateRequest = (payload: {
  id: string
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: AtmeyeDeviceActions.DeleteFilterTemplateRequest,
  payload,
})

export const deleteSdFilterTemplateResponse = (payload: any): AnyAction => ({
  type: TicketActions.DeleteSdFilterTemplateResponse,
  payload,
})

export const getGroupTemplatesRequest = (payload: string): AnyAction => ({
  type: AtmeyeDeviceActions.GetGroupsTemplatesRequest,
  payload,
})

export const editGroupTemplateRequest = (payload: {
  template: UIGroupTemplate
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: AtmeyeDeviceActions.EditGroupTemplateRequest,
  payload,
})

export const editGroupTemplateFromResponse = (payload: UIGroupTemplate): AnyAction => ({
  type: TicketActions.EditSdGroupTemplateFromResponse,
  payload,
})

export const deleteGroupTemplateRequest = (payload: {
  template: UIGroupTemplate
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: AtmeyeDeviceActions.DeleteGroupTemplateRequest,
  payload,
})

export const deleteSdGroupTemplateResponseSuccess = (payload: {
  template: UIGroupTemplate
}): AnyAction => ({
  type: TicketActions.DeleteSdGroupTemplateResponseSuccess,
  payload,
})

export const saveGroupTemplateRequest = (
  template: {
    windowType: WindowType
    name: string
    groupingLevels: ApiGroupTemplate[]
  },
  translate: any,
): AnyAction => ({
  type: AtmeyeDeviceActions.SaveGroupingTemplateRequest,
  payload: { template, translate },
})

export const setGroupTemplates = (
  templates: UIGroupTemplate[],
  withAppendNewTemplate = false,
): AnyAction => ({
  type: TicketActions.SetGroupTemplates,
  payload: { templates, withAppendNewTemplate },
})

export const setCurrentFilterTemplate = (value: string): AnyAction => ({
  type: TicketActions.SetCurrentFilterTemplate,
  payload: value,
})

export const getDevicesConfigRequest = (): AnyAction => ({
  type: AtmeyeDeviceActions.GetDevicesConfigRequest,
})

export const getDevicesConfigResponse = (
  typeOfTickets: TypeOfTickets,
  ticketsConfigData: DevicesConfigResponse,
): AnyAction => ({
  type: AtmeyeDeviceActions.GetDevicesConfigResponse,
  payload: { typeOfTickets, config: ticketsConfigData.formConfig },
})

export const getTicketsConfigResponseFail = (error: ResponseError): AnyAction => ({
  type: TicketActions.GetTicketsConfigResponseFail,
  payload: error,
})

export const getTicketDetailsResponse = ({ ticketDetails }: TicketDetailsResponse): AnyAction => ({
  type: TicketActions.GetTicketDetailsResponse,
  payload: ticketDetails,
})

export const getTicketDetailsResponseFail = (error: ResponseError): AnyAction => ({
  type: TicketActions.GetTicketDetailsResponseFail,
  payload: { error },
})

export const changeSorting = (sorting: { [key: string]: 'asc' | 'desc' } | null): AnyAction => ({
  type: TicketActions.ChangeSorting,
  payload: sorting,
})

export const changeGrouping = (groups: TableGroup[]): AnyAction => ({
  type: TicketActions.ChangeGrouping,
  payload: groups,
})

export const setTicketsRowsPerPage = (rowsCount: number): AnyAction => ({
  type: TicketActions.SetRowPerPage,
  payload: rowsCount,
})

export const setTicketsPage = (page: number): AnyAction => ({
  type: TicketActions.SetPage,
  payload: page,
})

export const setActualCountParentGroup = (payload: ActualCountParentGroupTypes): AnyAction => ({
  type: AtmeyeDeviceActions.SetAtmeyeActualCountParentGroup,
  payload,
})

export const clearGroupedTicketDetailsData = (): AnyAction => ({
  type: TicketActions.ClearGroupedTicketDetailsData,
})

export const setStringFilter = (filter: TableFilter): AnyAction => ({
  type: TicketActions.SetStringFilter,
  payload: filter,
})

export const setDataPagination = (data: any): AnyAction => ({
  type: AtmeyeDeviceActions.SetDataPagination,
  payload: data,
})

export const setScrollMultiSelectConfigValues = (
  payload: MultiSelectConfigValuesTypes,
): AnyAction => ({
  type: AtmeyeDeviceActions.SetScrollMultiSelectConfigValues,
  payload,
})
