import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getState = (state: AppState): fromReducer.State => {
  return state.vaultV1Admin.appConfig.valuables
}

export const getVaultsAdmValuables = createSelector(getState, state => state)
export const getVaultsAdmValuablesData = createSelector(getState, state => state.data)
export const getVaultsAdmValuablesTotal = createSelector(getState, state => state.total)
export const getVaultsAdmSelectedValuable = createSelector(getState, state => state.selected)
export const getVaultsAdmValuablesLanguages = createSelector(getState, state => state.languages)

export const getVaultsAdmValuablesSort = createSelector(getState, state => ({
  sortColumn: state.sortColumn,
  sortOrder: state.sortOrder,
}))
