import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(() => ({
  linkTableCell: {
    textDecorationLine: 'underline',
    color: ' #25ADBF',
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.7,
    },
  },

  disabled: {
    pointerEvents: 'none',
    color: ' #9b9b9b',
  },
}))
