import makeStyles from '@material-ui/core/styles/makeStyles'

import colors from '@/theme/colors'

export const useClasses = makeStyles(({ spacing, palette, breakpoints }) => ({
  chooseCompanyTitle: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '20px',
    paddingBottom: spacing(3),
  },

  //  selectCompanyWrapper: {
  //    padding: '1px',
  //  },
  //  companyItem: {
  //    // height: spacing(6),
  //    padding: spacing(2),
  //    textAlign: 'center',
  //    cursor: 'pointer',
  //    '&:not(:last-child)': {
  //      borderBottom: '1px solid #e8e9e9',
  //    },

  //    '&:hover': {
  //      background: 'rgba(0, 0, 0, 0.08)',
  //    },
  //  },

  selectCompanyWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  selectCompanyContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },

  companyItem: {
    flex: '0 1 auto',
    // height: spacing(9),
    padding: spacing(2),
    width: '100%',
    cursor: 'pointer',
    '&:not(:last-child)': {
      borderBottom: '1px solid #e8e9e9',
    },

    '&:hover': {
      background: 'rgba(0, 0, 0, 0.02)',
    },
  },
}))
