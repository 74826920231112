import { isEqual } from 'lodash-es'
import React, { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react'

import { Device } from '@/api/atmeye/devicesApi/types'
import Loader from '@/components/blocks/Loader'
import TablePagination from '@/components/controls/TableComponents/TablePagination'
import { ATMeyeMap } from '@/components/pages/atmeye/Devices/Components/ATMeyeMap'
import { TABS } from '@/components/pages/atmeye/Devices/Components/MapPageWrapper/types'
import { Pagination } from '@/types'
import { Row } from '@/types/atmeye/TableTypes'

import { useClasses } from './styles'
import { useGoogleMapWrapper } from './useGoogleMapWrapper'

type Props = {
  isDataRefreshed: boolean
  tab: TABS
  handleSetCurrentDeviceId: (device: Device) => () => void
  currentDevice: null | Device
  isFetching: boolean
  data: Array<Row>
  pagination: Pagination
  changeCurrentPage: (page: number) => void
  changePageSize: (size: number) => void
  controls?: ReactElement | null
  setIsDataRefreshed: (isRefresh: boolean) => void
}

const GoogleMapWrapper: FC<Props> = ({
  isDataRefreshed,
  tab,
  handleSetCurrentDeviceId,
  currentDevice,
  isFetching,
  data,
  pagination,
  changeCurrentPage,
  changePageSize,
  controls,
  setIsDataRefreshed,
}: Props): ReactElement => {
  const classes = useClasses()
  const { mapPoints, mapPointsInfo } = useGoogleMapWrapper(data)
  const [points, setPoints] = useState<any>([])

  useEffect(() => {
    if (!isEqual(mapPointsInfo, points) || isDataRefreshed) {
      setPoints(mapPointsInfo || [])
      setIsDataRefreshed(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapPointsInfo])

  const changePage = useCallback(
    (page: number) => {
      changeCurrentPage(page)
    },
    [changeCurrentPage],
  )

  const changeRowsPerPage = useCallback(
    (size: number) => {
      changePageSize(size)
    },
    [changePageSize],
  )

  return (
    <div className={classes.root}>
      {isFetching && (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      )}

      <ATMeyeMap
        tab={tab}
        currentDevice={currentDevice}
        mapPoints={mapPoints || []}
        mapPointsInfo={points || []}
        handleSetCurrentDeviceId={handleSetCurrentDeviceId}
      />

      {!!data?.length && (
        <TablePagination
          totalPage={pagination.totalPages}
          selectableTable
          page={pagination.pageNumber}
          rowsPerPage={pagination.pageSize}
          ticketsTotalCount={pagination.totalElements}
          changePage={changePage}
          changeRowsPerPage={changeRowsPerPage}
          rowsPerPageOptions={[5, 10, 15, 25, 50, 100, 300]}
          countPageWithEmptyData={!data.length ? 1 : undefined}
          controls={controls}
        />
      )}
    </div>
  )
}

export { GoogleMapWrapper }
