import { Box, Grid } from '@material-ui/core'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { Field } from 'formik'
import React, { ReactElement, useCallback, useState } from 'react'

import { createTextInputField } from '@/components/controls/TextInput'
import { MenuItemIcon } from '@/components/wrappers/AppNavigation/components/DrawerMenuItem/styles'
import { mergeSecurityNetworksIcons } from '@/constants/usm/mergeSecurityNetworksIcons'

import { useClasses } from './styles'
import { ColumnType, Props } from './types'

const EditableColumn = ({ name, values, onChangeRow, iconKey }: Props): ReactElement => {
  const [type, setType] = useState<ColumnType>('string')
  const classes = useClasses()

  const handleChangeType = useCallback(() => {
    if (type === 'string') {
      setType('input')
    }
    if (type === 'input') {
      setType('string')
    }
  }, [type])

  return (
    // <TableCell className={classes.column} onDoubleClick={handleChangeType}>
    <TableCell className={classes.column}>
      {type === 'string' && (
        <Grid container>
          {values[name] && (
            <ListItemIcon className={classes.listItemIcon}>
              <Box display="flex" alignItems="center">
                <MenuItemIcon src={mergeSecurityNetworksIcons[iconKey]} />
              </Box>
            </ListItemIcon>
          )}
          <Typography
            variant="body2"
            className={clsx({
              [classes.expiryDate]: name === 'expireDate',
              [classes.expiredDate]:
                name === 'expireDate' && values[name] && new Date(values[name]) < new Date(),
            })}
          >
            {values[name]}
            {'count' in values && `(${values.count})`}
          </Typography>
        </Grid>
      )}
      {/* <Typography variant="body2">{values[name]}</Typography>} */}
      {type === 'input' && (
        <Field name={name}>
          {createTextInputField({
            value: values[name],
            autoFocus: true,
            className: classes.input,
            onBlur: () => {
              handleChangeType()
              onChangeRow(values)
            },
          })}
        </Field>
      )}
    </TableCell>
  )
}

export default EditableColumn
