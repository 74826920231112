import MaterialCheckbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { FieldProps } from 'formik'
import React from 'react'

import { useClasses } from './styles'
import { Props } from './types'

export const Checkbox = ({
  label,
  withLabel = true,
  error,
  width,
  styles,
  disabled,
  onChange,
  onClickLabel,
  onClickCheckbox,
  ...props
}: Props): React.ReactElement => {
  const propsStyle = { width }
  const classes = useClasses(propsStyle)

  return withLabel ? (
    <label
      className={clsx(classes.sameAddress, styles?.label, { [classes.disabled]: disabled })}
      onClick={onClickLabel}
    >
      <MaterialCheckbox
        {...props}
        checked={typeof props.value === 'boolean' ? !!props.value : !!props.checked}
        disabled={disabled}
        onChange={onChange}
        onClick={onClickCheckbox}
      />
      <Typography
        className={clsx({
          [classes.errorText]: error,
        })}
        variant="body2"
      >
        {label}
      </Typography>
    </label>
  ) : (
    <MaterialCheckbox
      checked={typeof props.value === 'boolean' ? !!props.value : !!props.checked}
      disabled={disabled}
      {...props}
      onChange={onChange}
      onClick={onClickCheckbox}
    />
  )
}

export const createCheckboxField = (props: Props): ((field: FieldProps) => React.ReactElement) => ({
  field: { name, value, onChange },
  form: { errors },
  meta: { touched },
}): React.ReactElement => {
  return (
    <Checkbox
      name={name}
      value={value}
      onChange={onChange}
      error={!!errors[name] && touched}
      {...props}
    />
  )
}
