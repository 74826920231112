import { Grid } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import { Form, Formik, FormikValues } from 'formik'
import { isEmpty } from 'lodash-es'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Test from 'react-test-attributes'

import CloseButton from '@/components/blocks/CloseButton/component'
import Hider from '@/components/blocks/Hider'
import Loader from '@/components/blocks/Loader/component'
import AppTable from '@/components/controls/AppTable/component'
import { TableBodyCell } from '@/components/controls/AppTable/types'
import { getValueOfCell } from '@/components/controls/AppTable/utils/getValueOfCell'
import Button from '@/components/controls/Button'
import DynamicField from '@/components/controls/DynamicField/component'
import { getInitialFormValues } from '@/components/controls/DynamicField/initialValues'
import { AdvancedSearchFields } from '@/constants/forms/AdvancedSearchForm'
import { getCurrentUserCompanyCountryId } from '@/store/auth/selectors'
import {
  findUnitsAdvancedRequest,
  findUnitsResponse,
  getAdvancedUnitSearchConfigurationRequest,
} from '@/store/sd/drawerActions/actions'
import {
  getIsLookingUnitsAdvancedSearchConfig,
  getMappedUnitsAdvanced,
  isLookingForUnits,
  isShoeEmptyAdvancedSearchResults,
} from '@/store/sd/drawerActions/selectors'
import {
  getUnitsAdvanced,
  getUnitsAdvancedSearchFormParameters,
} from '@/store/sd/drawerActions/selectors/newTicket'
import { TicketsConfigData } from '@/types'
import { getTestId } from '@/utils/getTestId'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import { Props } from './types'

export const AdvancedSearchModal = ({
  handleClose,
  open,
  handleSave,
}: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const [selectedRow, setSelectedRow] = useState('')
  const testId = getTestId('sd-tickets-advanced-search-modal')
  const classes = useClasses()
  const isLoadingUnits = useSelector(isLookingForUnits)
  const mappedUnits = useSelector(getMappedUnitsAdvanced)
  const units = useSelector(getUnitsAdvanced)
  const currentUserCompanyCountryId = useSelector(getCurrentUserCompanyCountryId)
  const { filter: filtersFields }: TicketsConfigData = useSelector(
    getUnitsAdvancedSearchFormParameters,
  )
  const isLookingForFormConfig = useSelector(getIsLookingUnitsAdvancedSearchConfig)
  const shoeEmptyResultSearch = useSelector(isShoeEmptyAdvancedSearchResults)

  useEffect(() => {
    dispatch(getAdvancedUnitSearchConfigurationRequest())
  }, [])

  const initialFormValues = useMemo(
    () => getInitialFormValues(filtersFields, { countryid: `${currentUserCompanyCountryId}` }),
    [filtersFields],
  )

  const handleRowClick = useCallback((row: TableBodyCell[]): void => {
    setSelectedRow(getValueOfCell(row[0][AdvancedSearchFields.TerminalId]))
  }, [])

  const handleSubmit = useCallback(
    (values: FormikValues): void => {
      dispatch(findUnitsAdvancedRequest({ ...values }))
    },
    [dispatch],
  )

  const handleOkPress = useCallback((): void => {
    if (selectedRow) {
      const row = units.find(unit => unit.terminalUid === selectedRow)

      if (row) {
        const { deviceId, luno, productName, deviceState, merchant } = row

        handleSave(`${deviceId}`)

        dispatch(
          findUnitsResponse([
            {
              id: +deviceId,
              name: `${luno} ${productName || ''} ${merchant || ''} ${deviceState}`,
            },
          ]),
        )
      }
    }
    handleClose()
  }, [handleSave, handleClose, selectedRow, dispatch, units])

  return (
    <Test id={testId(0)}>
      <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose} disableBackdropClick>
        <Test id={testId(1)}>
          <CloseButton absolute onClick={handleClose} />
        </Test>
        <div className={classes.wrapper}>
          {isLookingForFormConfig || isEmpty(initialFormValues) ? (
            <Loader />
          ) : (
            <Formik onSubmit={handleSubmit} initialValues={initialFormValues} dirty>
              {({ values, setFieldValue, dirty }): React.ReactElement => (
                <Hider
                  title={
                    <Typography variant="h5">
                      <FormattedMessage
                        id="title.advancedSearch"
                        defaultMessage="Advanced Search"
                      />
                    </Typography>
                  }
                >
                  <Form>
                    <Grid container>
                      <Grid item className={classes.fieldsWrapper}>
                        {filtersFields.map(field => (
                          <Grid item md={6} key={field.parameterName} className={classes.field}>
                            <DynamicField
                              params={field}
                              formikValues={values}
                              setFieldValue={setFieldValue}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} justifyContent="flex-end">
                      <Grid item>
                        <Test id={testId(8)}>
                          <Button
                            width="md"
                            variant="outlined"
                            type="reset"
                            className={classes.clearButton}
                            disabled={!dirty}
                          >
                            <FormattedMessage id="action.clear" defaultMessage="Clear" />
                          </Button>
                        </Test>
                      </Grid>
                      <Grid item>
                        <Button type="submit" variant="contained" width="md">
                          <Test id={testId(9)}>
                            <FormattedMessage id="action.search" defaultMessage="Search" />
                          </Test>
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                </Hider>
              )}
            </Formik>
          )}
          {mappedUnits.length ? (
            <div className={classes.searchResults}>
              <div className={classes.tableWrapper}>
                <AppTable
                  highlightRows={[selectedRow]}
                  onRowClick={handleRowClick}
                  data={mappedUnits}
                  idColumnKey={AdvancedSearchFields.TerminalId}
                  isLoading={isLoadingUnits}
                  tableClasses={{
                    table: classes.table,
                  }}
                />
              </div>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Test id={testId(10)}>
                    <Button
                      variant="outlined"
                      width="md"
                      onClick={handleClose}
                      className={classes.clearButton}
                    >
                      <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
                    </Button>
                  </Test>
                </Grid>
                <Grid item>
                  <Test id={testId(11)}>
                    <Button onClick={handleOkPress} variant="contained" width="md">
                      <FormattedMessage id="action.ok" defaultMessage="Ok" />
                    </Button>
                  </Test>
                </Grid>
              </Grid>
            </div>
          ) : (
            isLoadingUnits && (
              <div className={classes.loader}>
                <Loader />
              </div>
            )
          )}
          {!mappedUnits.length && !isLoadingUnits && shoeEmptyResultSearch && (
            <Typography className={classes.resultTitle} variant="body1">
              No results were found by your search
            </Typography>
          )}
        </div>
      </Dialog>
    </Test>
  )
}
