import { AnyAction } from 'redux'

import { AuthorizationActions, TableColumnsActions } from '@/constants'
import { TablesColumnsTypes } from '@/types'

export type State = {
  columnOrder: TablesColumnsTypes[]
}

export const initialState: State = {
  columnOrder: [],
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case TableColumnsActions.SetTableColumnsOrder: {
      const founded = state.columnOrder.some(item => item.tableId === payload.tableId)

      if (founded) {
        return {
          ...state,
          columnOrder: state.columnOrder.map(item =>
            item.tableId === payload.tableId ? { ...item, columns: payload.columns } : item,
          ),
        }
      }

      return { ...state, columnOrder: [...state.columnOrder, payload] }
    }
    default:
      return state
  }
}
