import 'moment/min/locales.min'

import moment from 'moment'
import { createSelector } from 'reselect'

import { INITIAL_LOCALE } from '@/constants/internalization'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { DATE_FORMAT } from '@/constants/time'
import { AppState } from '@/store/reducers'
import { SubscribeItems } from '@/types/sd/reports/reports'
import { getFilteredTreeView } from '@/utils/treeSearch'

import * as fromReports from './reducer'
import { initialState } from './reducer'

export const getReportsState = (state: AppState): fromReports.State => state.reports || initialState

export const getLazyReportMenuItems = createSelector(getReportsState, state =>
  getFilteredTreeView(state.lazyReportMenu, state.searchFilterReportByName),
)

export const getAllReportMenuItems = createSelector(getReportsState, state =>
  getFilteredTreeView(state.allReportMenu, state.searchFilterReportByName),
)

export const getMyReportsMenu = createSelector(getReportsState, state =>
  getFilteredTreeView(state.myReportsMenu, state.searchFilterReportByName),
)

export const getIsFetchingAllReportMenuItems = createSelector(
  getReportsState,
  state => state.isFetchingReports,
)

export const getSubScribeReports = createSelector(getReportsState, state =>
  state.subscriptions.map(
    (el): SubscribeItems =>
      'validTo' in el
        ? {
            ...el,
            validTo: moment.utc(el.validTo).format(DATE_FORMAT),
          }
        : el,
  ),
)

export const isFetchingSubScribeReports = createSelector(
  getReportsState,
  state => state.isFetchingSubscribeReports,
)
export const getReportsConfig = createSelector(getReportsState, state => state.ReportsConfig)

export const getIsFetchingReportsConfig = createSelector(
  getReportsState,
  state => state.isFetchingReportsConfig,
)

export const getReportHTML = createSelector(getReportsState, state => state.reportHTML)
export const getReportLazyHTML = createSelector(getReportsState, state => state.reportLazyHTML)
export const getReportData = createSelector(getReportsState, state => state.reportData)

export const getIsLoadingHTMLReport = createSelector(
  getReportsState,
  state => state.isLoadingHTMLReport,
)

export const getParametersReportForDownloadFile = createSelector(
  getReportsState,
  state => state.parametersReportForDownloadFile,
)

export const getReportName = createSelector(getReportsState, state => state.subscriptionName)

// TODO CHECKING
export const getReport = createSelector(getReportsState, state => state.report)

export const getIsFetchingReport = createSelector(
  getReportsState,
  state => state.isFetchingReportById,
)
