import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Field, FormikProps } from 'formik'
import _ from 'lodash'
import { debounce } from 'lodash-es'
import React, { useState } from 'react'
import { isIE } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Test from 'react-test-attributes'

import { CommonApi } from '@/api'
import { CityStateRegion, StateRegion } from '@/api/common/commonApi/types'
import { BackupOptions } from '@/components/controls/GeneratedField/types'
import LookupsList from '@/components/controls/LookupsList/component'
import LookupsSearch from '@/components/controls/LookupsSearch/component'
import { createTextInputField } from '@/components/controls/TextInput/component'
import {
  AddNewCompanyFormFields,
  AddNewCompanyFormValues,
} from '@/constants/forms/AddNewCompanyForm'
import { getCompanyTypes } from '@/store/companies/drawerActions'
import { getTestId } from '@/utils/getTestId'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from '../styles'

const AddNewCompanyFormStepAddress = ({
  values,
  setFieldValue,
  ...props
}: FormikProps<AddNewCompanyFormValues> & { backupOptions: BackupOptions }): any => {
  // const [searchByLUNO, setSearchByLUNO] = useState('1')
  // const [requestType, setRequestType] = useState('1')
  const propsi = { display: isIE ? 'block' : 'flex' }
  const classes = useClasses(propsi)
  const testId = getTestId('companies-add-new-company-form-step-2')
  // const serviceCompanies = useSelector(getServiceCompanies)
  const companyTypes = useSelector(getCompanyTypes)
  const dispatch = useDispatch()
  const translate = useTranslate()
  const location = useLocation()

  const [isDependsEnabled, setIsDependsEnabled] = useState<boolean>(true)
  const enableDepends = debounce(() => {
    setIsDependsEnabled(true)
  }, 1000)

  const AddressInput = createTextInputField({
    // required: true,
    disabled: !!values.address[AddNewCompanyFormFields.StreetId],
    label: translate('translate#title.address'),
    placeholder: translate('translate#title.address'),
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    maxSymbols: 128,
  })

  const HouseNumberInput = createTextInputField({
    disabled: !values.address[AddNewCompanyFormFields.StreetId],
    required: !!values.address[AddNewCompanyFormFields.StreetId],
    label: translate('translate#title.houseNumber'),
    placeholder: translate('translate#title.houseNumber'),
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    maxSymbols: 20,
  })

  const FlatNumberInput = createTextInputField({
    disabled: !values.address[AddNewCompanyFormFields.StreetId],
    label: translate('translate#title.flatNumber'),
    placeholder: translate('translate#title.flatNumber'),
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    maxSymbols: 20,
  })

  const LocationInput = createTextInputField({
    label: translate('translate#title.location'),
    placeholder: translate('translate#title.location'),
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    maxSymbols: 128,
  })

  return (
    <Test id={testId(0)}>
      <Grid className={classes.container} container>
        <Typography variant="h6" className={classes.title}>
          {translate('translate#title.address')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Grid container spacing={2}>
              <Test id={testId(1)}>
                <Grid item md={12}>
                  <LookupsSearch
                    isDisabled={false}
                    defaultValue={{
                      value: values.address.countryId,
                      name: values.address.countryName,
                    }}
                    setFormikFieldValue={setFieldValue}
                    field={{
                      options: {
                        label: translate('translate#title.country'),
                        placeholder: translate('translate#title.country'),
                        required: true,
                      },
                    }}
                    isDependsOn
                    fieldName={`address[${AddNewCompanyFormFields.CountryId}]`}
                    fieldTitle={`address[${AddNewCompanyFormFields.CountryName}]`}
                    onCall={async (
                      value: string,
                    ): Promise<{ item: any; value: string; name: string }[]> => {
                      const response = await CommonApi.getLookupsValues({
                        lookupKey: 'COUNTRY_DICT',
                        module: 'COM',
                        fragment: value,
                        pageNo: 0,
                        pageSize: 100,
                      })
                      return response.values.map(item => ({
                        item: item,
                        value: item.id,
                        name: item.name,
                      }))
                    }}
                    onSelect={(item: { value: string; name: string }): void => {
                      setFieldValue(`address`, {
                        ...values.address,
                        countryId: item ? Number(item.value) : null,
                        countryName: item ? item.name : '',
                        houseNumber: '',
                        flatNumber: '',
                        address: '',
                        location: '',
                      })
                      setFieldValue(
                        `companyGeneralInfo.countryId`,
                        item ? Number(item.value) : null,
                      )
                    }}
                  />
                </Grid>
              </Test>
              <Test id={testId(2)}>
                <Grid item md={12}>
                  <LookupsSearch
                    isDisabled={!values.address.countryId}
                    defaultValue={{
                      value: values.address.cityId,
                      name: values.address.cityName,
                    }}
                    field={{
                      options: {
                        label: translate('translate#title.city'),
                        placeholder: translate('translate#title.selectCity'),
                      },
                    }}
                    setFormikFieldValue={setFieldValue}
                    fieldName={`address[${AddNewCompanyFormFields.CityId}]`}
                    fieldTitle={`address[${AddNewCompanyFormFields.CityName}]`}
                    dependsOn={[
                      values.address.countryId,
                      values.address.stateId,
                      values.address.regionId,
                    ]}
                    onCall={async (
                      value: string,
                    ): Promise<{ item: any; value: string; name: string }[]> => {
                      const response = await CommonApi.getLookupsCityStateRegion({
                        countryId: values.address.countryId ? values.address.countryId : -1,
                        stateId: values.address.stateId ? values.address.stateId : -1,
                        selectId: -1,
                        regionId: values.address.regionId ? values.address.regionId : -1,
                        fragment: value,
                        pageNo: 0,
                        pageSize: 100,
                      })
                      return response.values.map(item => ({
                        item: item,
                        value: String(item.cityId),
                        name: `${item.city}${item.region ? ', ' + item.region : ''}${
                          item.state ? ', ' + item.state : ''
                        }`,
                      }))
                    }}
                    isDependsOn={isDependsEnabled}
                    onSelect={(item: { item: CityStateRegion }): void => {
                      setIsDependsEnabled(false)

                      setFieldValue(`address`, {
                        ...values.address,
                        regionId: item ? item.item.regionId : values.address.regionId,
                        regionName: item ? item.item.region : values.address.regionName,
                        stateId: item ? item.item.stateId : values.address.stateId,
                        stateName: item ? item.item.state : values.address.stateName,
                        cityId: item ? item.item.cityId : null,
                        cityName: item ? item.item.city : '',
                        cityRegionId: null,
                        cityRegionName: '',
                        houseNumber: '',
                        flatNumber: '',
                        address: '',
                        location: '',
                      })

                      enableDepends()
                    }}
                  />
                </Grid>
              </Test>
              <Test id={testId(3)}>
                <Grid item md={12}>
                  <LookupsSearch
                    isDisabled={!values.address.cityId}
                    defaultValue={{
                      value: values.address.streetId,
                      name: values.address.streetName,
                    }}
                    setFormikFieldValue={setFieldValue}
                    field={{
                      options: {
                        label: translate('translate#title.street'),
                        placeholder: translate('translate#title.street'),
                      },
                    }}
                    isDependsOn
                    fieldTitle={`address[${AddNewCompanyFormFields.StreetName}]`}
                    fieldName={`address[${AddNewCompanyFormFields.StreetId}]`}
                    dependsOn={[
                      values.address.cityId,
                      values.address.countryId,
                      values.address.stateId,
                      values.address.regionId,
                      values.address.cityRegionId,
                    ]}
                    onCall={async (
                      value: string,
                    ): Promise<{ item: any; value: string; name: string }[]> => {
                      const response = await CommonApi.getLookupsStreet({
                        cityId: values.address.cityId ? values.address.cityId : -1,
                        selectId: -1,
                        fragment: value,
                        pageNo: 0,
                        pageSize: 100,
                      })
                      return response.values.map(item => ({
                        item: item,
                        value: item.id,
                        name: item.name,
                      }))
                    }}
                    onSelect={(item: { value: string; name: string }): void => {
                      setFieldValue(`address`, {
                        ...values.address,
                        streetId: item ? Number(item.value) : null,
                        streetName: item ? item.name : '',
                        houseNumber: '',
                        flatNumber: '',
                        address: '',
                      })
                    }}
                  />
                </Grid>
              </Test>
              <Test id={testId(4)}>
                <Grid item md={12}>
                  <Field name={`address[${AddNewCompanyFormFields.Address}]`}>{AddressInput}</Field>
                </Grid>
              </Test>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={2}>
              <Test id={testId(5)}>
                <Grid item md={6}>
                  <LookupsList
                    isDisabled={!values.address.countryId}
                    defaultValue={{
                      value: values.address.stateId,
                      name: values.address.stateName,
                    }}
                    setFormikFieldValue={setFieldValue}
                    field={{
                      options: {
                        label: translate('translate#title.stateName'),
                        placeholder: translate('translate#title.stateName'),
                        isNumber: true,
                      },
                    }}
                    isDependsOn={isDependsEnabled}
                    fieldTitle={`address[${AddNewCompanyFormFields.StateName}]`}
                    fieldName={`address[${AddNewCompanyFormFields.StateId}]`}
                    dependsOn={[values.address.countryId]}
                    onCall={async (
                      value: string,
                    ): Promise<{ item: any; value: string; name: string }[]> => {
                      const response = await CommonApi.getLookupsState({
                        countryId: values.address.countryId ? values.address.countryId : -1,
                        stateId: -1,
                        selectId: -1,
                        regionId: values.address.regionId ? values.address.regionId : -1,
                        fragment: value,
                        pageNo: 0,
                        pageSize: 100,
                      })
                      return response.values.map(item => ({
                        item: item,
                        value: item.id,
                        name: item.name,
                      }))
                    }}
                    onSelect={(item: { value: string; name: string }): void => {
                      setFieldValue(`address`, {
                        ...values.address,
                        stateId: item ? Number(item.value) : null,
                        stateName: item ? item.name : '',
                        houseNumber: '',
                        flatNumber: '',
                        address: '',
                        location: '',
                      })
                    }}
                  />
                </Grid>
              </Test>
              <Test id={testId(6)}>
                <Grid item md={6}>
                  <LookupsList
                    isDisabled={!values.address.countryId}
                    defaultValue={{
                      value: values.address.regionId,
                      name: values.address.regionName,
                    }}
                    setFormikFieldValue={setFieldValue}
                    field={{
                      options: {
                        label: translate('translate#title.region'),
                        placeholder: translate('translate#title.region'),
                        isNumber: true,
                      },
                    }}
                    fieldTitle={`address[${AddNewCompanyFormFields.RegionName}]`}
                    fieldName={`address[${AddNewCompanyFormFields.RegionId}]`}
                    isDependsOn={isDependsEnabled}
                    dependsOn={[values.address.countryId, values.address.stateId]}
                    onCall={async (
                      value: string,
                    ): Promise<{ item: any; value: string; name: string }[]> => {
                      const response = await CommonApi.getLookupsRegion({
                        countryId: values.address.countryId ? values.address.countryId : -1,
                        stateId: values.address.stateId ? values.address.stateId : -1,
                        selectId: -1,
                        regionId: -1,
                        fragment: value,
                        pageNo: 0,
                        pageSize: 100,
                      })
                      return response.values.map(item => ({
                        item: item,
                        value: String(item.regionId),
                        name: `${item.region}${item.state ? ', ' + item.state : ''}`,
                      }))
                    }}
                    onSelect={(item: { item: StateRegion }): void => {
                      setIsDependsEnabled(false)

                      setFieldValue(`address`, {
                        ...values.address,
                        regionId: item ? item.item.regionId : null,
                        regionName: item ? item.item.region : '',
                        stateId: item ? item.item.stateId : values.address.stateId,
                        stateName: item ? item.item.state : values.address.stateName,
                        cityId: null,
                        cityName: '',
                        cityRegionId: null,
                        cityRegionName: '',
                        houseNumber: '',
                        flatNumber: '',
                        address: '',
                        location: '',
                      })

                      enableDepends()
                    }}
                  />
                </Grid>
              </Test>
              <Test id={testId(7)}>
                <Grid item md={12}>
                  <LookupsList
                    isDisabled={!values.address.cityId}
                    defaultValue={{
                      value: values.address.cityRegionId,
                      name: values.address.cityRegionName,
                    }}
                    setFormikFieldValue={setFieldValue}
                    field={{
                      options: {
                        label: translate('translate#title.cityRegion'),
                        placeholder: translate('translate#title.cityRegion'),
                        isNumber: true,
                      },
                    }}
                    fieldName={`address[${AddNewCompanyFormFields.CityRegionId}]`}
                    fieldTitle={`address[${AddNewCompanyFormFields.CityRegionName}]`}
                    isDependsOn
                    dependsOn={[
                      values.address.countryId,
                      values.address.stateId,
                      values.address.regionId,
                      values.address.cityId,
                    ]}
                    onCall={async (
                      value: string,
                    ): Promise<{ item: any; value: string; name: string }[]> => {
                      const response = await CommonApi.getLookupsCityRegion({
                        cityId: values.address.cityId ? values.address.cityId : -1,
                        selectId: -1,
                        fragment: value,
                        pageNo: 0,
                        pageSize: 100,
                      })
                      return response.values.map(item => ({
                        item: item,
                        value: item.id,
                        name: item.name,
                      }))
                    }}
                    onSelect={(item: { value: string; name: string }): void => {
                      setFieldValue(`address`, {
                        ...values.address,
                        cityRegionId: item ? Number(item.value) : null,
                        cityRegionName: item ? item.name : '',
                        houseNumber: '',
                        flatNumber: '',
                        address: '',
                        location: '',
                      })
                    }}
                  />
                </Grid>
              </Test>
              <Test id={testId(8)}>
                <Grid item md={6}>
                  <Field name={`address[${AddNewCompanyFormFields.HouseNumber}]`}>
                    {HouseNumberInput}
                  </Field>
                </Grid>
              </Test>
              <Test id={testId(9)}>
                <Grid item md={6}>
                  <Field name={`address[${AddNewCompanyFormFields.FlatNumber}]`}>
                    {FlatNumberInput}
                  </Field>
                </Grid>
              </Test>
              <Test id={testId(10)}>
                <Grid item md={12}>
                  <Field name={`address[${AddNewCompanyFormFields.Location}]`}>
                    {LocationInput}
                  </Field>
                </Grid>
              </Test>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Test>
  )
}

export { AddNewCompanyFormStepAddress }
