import { BaseATMeye } from '@/api/atmeye/baseAPIATMeye'
import { AddNewSubscriptionPageTypes, AllSubpagesResponseType } from '@/types/atmeye/dashboardTypes'

export class DashBoardAPI<T> extends BaseATMeye<T> {
  constructor(protected data?: T, protected fetchParam?: string) {
    super(data)
    this.data = data
  }

  async toggleWidgetViewType(widgetId: number) {
    try {
      return this.ATMeyeRequestService.patch(
        `/dashboard/api/v1/atmeye-dashboard/widgets/${widgetId}/table-view-flag`,
        {},
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getWidgetHeader() {
    try {
      return this.ATMeyeRequestService.get(
        `/dashboard/api/v1/atmeye-dashboard/views-data/header-widget`,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getAllSubpages() {
    try {
      return this.ATMeyeRequestService.get(`/dashboard/api/v1/atmeye-dashboard/subpages`)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async addNewSubpageSubscription(payload: AddNewSubscriptionPageTypes) {
    try {
      return this.ATMeyeRequestService.post(
        `/dashboard/api/v1/atmeye-dashboard/subpages/${payload.subpageId}/subscription?isMain=${payload.isMain}`,
        {},
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getAllPublishedSubpages() {
    try {
      return this.ATMeyeRequestService.get(
        `/dashboard/api/v1/atmeye-dashboard/subpages/published?namePart=${this.data}`,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async addNewSubPage() {
    try {
      return this.ATMeyeRequestService.post(
        `/dashboard/api/v1/atmeye-dashboard/subpages`,
        this.data,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async deleteSubPage() {
    try {
      return this.ATMeyeRequestService.delete(
        `/dashboard/api/v1/atmeye-dashboard/subpages/${this.data}`,
        true,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async unSubscribeSubPage() {
    try {
      return this.ATMeyeRequestService.delete(
        `/dashboard/api/v1/atmeye-dashboard/subpages/${this.data}/subscription`,
        true,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async updateSubPage() {
    try {
      return this.ATMeyeRequestService.patch(
        `/dashboard/api/v1/atmeye-dashboard/subpages/${this.fetchParam}/subscription/main`,
        this.data,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async updateMySubPage() {
    try {
      return this.ATMeyeRequestService.put(
        `/dashboard/api/v1/atmeye-dashboard/subpages/${this.fetchParam}`,
        this.data,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getFullSubPagesData() {
    try {
      return this.ATMeyeRequestService.get(
        `/dashboard/api/v1/atmeye-dashboard/subpages/${this.data}`,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getUserMainPage(): Promise<AllSubpagesResponseType> {
    try {
      return this.ATMeyeRequestService.get(`/dashboard/api/v1/atmeye-dashboard/subpages/main`)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async createSubPageClone() {
    try {
      return this.ATMeyeRequestService.post(
        `/dashboard/api/v1/atmeye-dashboard/subpages/${this.data}/clone`,
        {},
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getSubPagesWidgets() {
    try {
      return this.ATMeyeRequestService.get(
        `/dashboard/api/v1/atmeye-dashboard/widgets?subpageId=${this.data}`,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getWidgetEventInfo(widgetId: string | undefined) {
    try {
      return await this.ATMeyeRequestService.get(
        `/dashboard/api/v1/atmeye-dashboard/events-count-widgets/${widgetId || this.data}`,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getWidgetDeviceInfo(widgetId: string | undefined) {
    try {
      return await this.ATMeyeRequestService.get(
        `/dashboard/api/v1/atmeye-dashboard/devices-count-widgets/${widgetId || this.data}`,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async deleteWidgetId() {
    try {
      return await this.ATMeyeRequestService.delete(
        `/dashboard/api/v1/atmeye-dashboard/widgets/${this.data}`,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async createDevicesCountWidget() {
    try {
      const data = await this.ATMeyeRequestService.post(
        '/dashboard/api/v1/atmeye-dashboard/devices-count-widgets',
        this.data,
      )

      return data
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async createEventsCountWidget() {
    try {
      const data = await this.ATMeyeRequestService.post(
        '/dashboard/api/v1/atmeye-dashboard/events-count-widgets',
        this.data,
      )

      return data
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async editEventsCountWidget(widgetId: number) {
    try {
      return await this.ATMeyeRequestService.put(
        `/dashboard/api/v1/atmeye-dashboard/events-count-widgets/${widgetId}`,
        this.data,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async editDevicesCountWidget(widgetId: number) {
    try {
      return await this.ATMeyeRequestService.put(
        `/dashboard/api/v1/atmeye-dashboard/devices-count-widgets/${widgetId}`,
        this.data,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getCountViewData() {
    try {
      return await this.ATMeyeRequestService.get(
        `/dashboard/api/v1/atmeye-dashboard/views-data?${this.data}`,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
