import clsx from 'clsx'
import React, { FC, ReactElement } from 'react'

import { translate, useFormatNumber } from '@/utils/internalization'

import { useClasses } from './styles'
import { GenStatusProps, Props } from './types'

export const Status: FC<Props> = React.memo((props: Props) => {
  const {
    background,
    color,
    value,
    styles,
    handleClick,
    isGradient = true,
    isNumber = false,
  } = props

  const classes = useClasses({ isGradient })
  const { formatNumber } = useFormatNumber()

  return (
    <div
      onClick={handleClick}
      className={clsx(classes.status, styles?.status, { [classes.clickable]: !!handleClick })}
      style={{ backgroundColor: background }}
    >
      {color && <div className={classes.statusCircle} style={{ backgroundColor: color }} />}
      <span className={clsx(classes.statusTitle, styles?.statusTitle)}>
        {isNumber
          ? formatNumber(typeof value === 'string' || typeof value === 'number' ? value : 0)
          : value}
      </span>
    </div>
  )
})

export const generateStatus = (props: GenStatusProps): ReactElement => {
  const { value, statuses, statusName } = props

  const statusObject = statuses[value]

  return (
    <Status
      {...statusObject}
      value={
        statusName ||
        (statusObject?.translateId
          ? translate(`translate#${statusObject.translateId}`)
          : statusObject?.value || value)
      }
    />
  )
}
