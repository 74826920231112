import { has } from 'lodash-es'

import { BalUnloadedCassetteEntry, BalUnloadedCassettesSession } from '@/api/cm/swaggerGeneratedApi'
import { EditableColumns } from '@/components/blocks/cm/CashBalancingModal/components/UnloadedCassettes/types'
import { GREEN_STATUS, RED_STATUS, YELLOW_STATUS } from '@/constants'

import { TableColumns as CashInTableColumns } from '../CashInTable/types'
import { CassetteValueChangeProps, EntryType, Keys, SortersKeyType, TableColumns } from './types'

interface BalanceCalculateProps {
  unloadedCassette: BalUnloadedCassettesSession
}

export const balanceCalculate = ({
  unloadedCassette,
}: BalanceCalculateProps): BalUnloadedCassettesSession => {
  const unloadedCassettes = unloadedCassette.unloadedCassettes || []

  const cassettesWithNewBalance = unloadedCassettes.map(cassette => {
    let systemBalance = cassette?.loadQuantity || 0
    let realBalance = cassette?.loadQuantity || 0

    Object.keys(cassette).forEach(key => {
      const property = cassette[key as Keys] as any

      if (
        property &&
        has(property, 'real') &&
        has(property, 'system') &&
        key !== TableColumns.Balance &&
        key !== EditableColumns.CashIn
      ) {
        if (key === EditableColumns.Deposit) {
          systemBalance += property.system?.value
          realBalance += property.real?.value
        } else {
          systemBalance -= property.system?.value
          realBalance -= property.real?.value
        }
      }
    })

    const realBalanceColor = realBalance !== 0 ? RED_STATUS : GREEN_STATUS
    const systemBalanceColor = systemBalance !== 0 ? RED_STATUS : GREEN_STATUS

    return {
      ...cassette,
      balance: {
        ...cassette.balance,
        real: {
          value: realBalance * -1,
          ...realBalanceColor,
        },
        system: {
          value: systemBalance * -1,
          ...systemBalanceColor,
        },
      },
    }
  })

  return {
    ...unloadedCassette,
    unloadedCassettes: cassettesWithNewBalance,
  }
}

// -------------------------------------------------------------------------------

interface UpdateCassettesProps {
  unloadedCassette: BalUnloadedCassettesSession
  newValue: string | number
  defaultCassetteId?: 'cassetteNr' | 'cassetteId'
}

interface ColorProps {
  bkgColor?: string
  color?: string
}

export const updateCassettes = ({
  unloadedCassette,
  cassette,
  key,
  newValue,
  defaultCassetteId = 'cassetteNr',
}: UpdateCassettesProps & CassetteValueChangeProps): BalUnloadedCassettesSession => {
  const unloadedCassettes = unloadedCassette?.unloadedCassettes

  const updatedCassettes = unloadedCassettes?.map(unloadedCassette => {
    if (unloadedCassette[defaultCassetteId] === cassette[defaultCassetteId]) {
      const property = unloadedCassette[key] as any

      if (has(property, 'real') && has(property, 'system')) {
        const systemValue = property.system?.value || 0

        let cellColor = { bkgColor: undefined, color: undefined } as ColorProps

        if (systemValue !== +newValue) {
          cellColor = YELLOW_STATUS
        }

        return {
          ...unloadedCassette,
          [key]: {
            ...unloadedCassette[key],
            system: {
              ...property.system,
              ...cellColor,
            },
            real: {
              ...property.real,
              value: newValue,
              ...cellColor,
            },
          },
        }
      } else {
        if (EditableColumns.KioskCashInSlip || EditableColumns.KioskCashInReal) {
          const property = unloadedCassette[key] as any
          const systemValue = unloadedCassette[CashInTableColumns.KioskCashInSystem]?.value || 0

          let cellColor = { bkgColor: undefined, color: undefined } as ColorProps

          if (systemValue !== +newValue) {
            cellColor = YELLOW_STATUS
          }

          return {
            ...unloadedCassette,
            [key]: {
              ...property,
              value: newValue,
              ...cellColor,
            },
          }
        }
      }
    }
    return unloadedCassette
  })

  return {
    ...unloadedCassette,
    unloadedCassettes: updatedCassettes,
  }
}

interface MixSortersProps {
  entries: EntryType[]
  unloadedCassettes: BalUnloadedCassetteEntry[]
  sortersKey: SortersKeyType
}

export const calcMixSorters = ({
  entries,
  sortersKey,
  unloadedCassettes,
}: MixSortersProps): BalUnloadedCassetteEntry[] => {
  entries.forEach(entry => {
    const unloadedIndex = unloadedCassettes.findIndex(
      ({ denomination, currency, cassetteType }) =>
        denomination === entry.Denomination &&
        currency?.name === entry.Currency &&
        (sortersKey === EditableColumns.CashIn ? cassetteType === 'CashIn' : true),
    )

    if (~unloadedIndex) {
      const property = unloadedCassettes[unloadedIndex][sortersKey]
      if (has(property, 'real') && has(property, 'system')) {
        unloadedCassettes[unloadedIndex] = {
          ...unloadedCassettes[unloadedIndex],
          [sortersKey]: {
            ...property,
            real: {
              value: (property?.real?.value || 0) + entry.Quantity,
              ...YELLOW_STATUS,
            },
            system: {
              ...property?.system,
              ...YELLOW_STATUS,
            },
          },
        }
      }
    }
  })
  return unloadedCassettes
}
