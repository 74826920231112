import { TableSortLabel } from '@material-ui/core'
import clsx from 'clsx'
import React, { FC, ReactElement, useMemo } from 'react'
import { Draggable, DraggableProvided } from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'

import { dummyFunction } from '@/utils/functions'

import { useTableWithBorder } from '../../../../hooks'
import { HeaderCell, useCommonClasses } from '../../../commonStyles'
import { useClasses } from './styles'
import { Props } from './types'

export const DraggableHeadCell: FC<Props> = React.memo((props: Props) => {
  const {
    _key,
    index,
    isFixed = false,
    fixedLength,
    tableClasses,
    borderCellClasses,
    value,
    isNumberCell,
    colSpan,
    sortDirection,
    withSort,
    isSortersColumn,
    children,
    handleSort,
  } = props

  const commonClasses = useCommonClasses()

  const cellClasses = useMemo(
    () =>
      clsx(
        borderCellClasses,
        {
          [commonClasses.fixed]: isFixed,
          [commonClasses.fixedLast]: fixedLength === index,
          [commonClasses.alignCenter]: !!borderCellClasses,
        },
        tableClasses?.fixed,
        tableClasses?.tableHeadCell,
      ),
    [isFixed, fixedLength, borderCellClasses, index, tableClasses],
  )

  return (
    <Draggable key={_key} draggableId={`tableHeader-${_key}`} index={index}>
      {(provided, { isDragging }): React.ReactElement => (
        <TableHeaderCell
          key={`tableHeaderCell-${_key}-${index}`}
          value={value}
          cellClasses={cellClasses}
          valueClasses={tableClasses?.tableHeadCellText}
          draggableProvided={provided}
          isDragging={isDragging}
          isNumberCell={isNumberCell}
          colSpan={colSpan}
          sortDirection={sortDirection}
          withSort={withSort}
          isCellWithBorder={!!borderCellClasses}
          isSortersColumn={isSortersColumn}
          handleSort={(): void => handleSort(_key)}
          isFixed={isFixed}
        >
          {children}
        </TableHeaderCell>
      )}
    </Draggable>
  )
})

interface TableHeaderCellProps {
  value: string | ReactElement

  cellClasses: string
  valueClasses?: string

  draggableProvided: DraggableProvided
  isDragging: boolean
  isNumberCell: boolean
  children?: ReactElement | null
  colSpan?: number
  sortDirection?: false | 'desc' | 'asc'
  isSortersColumn: boolean
  withSort: boolean

  isCellWithBorder?: boolean

  handleSort?: () => void

  isFixed: boolean
}

const TableHeaderCell: FC<TableHeaderCellProps> = React.memo((props: TableHeaderCellProps) => {
  const {
    value,
    cellClasses,
    valueClasses,
    draggableProvided: { innerRef, draggableProps, dragHandleProps },
    isDragging,
    isNumberCell,
    children,
    colSpan,
    sortDirection,
    isSortersColumn = false,
    withSort,
    isCellWithBorder = false,
    handleSort,
    isFixed,
  } = props

  const classes = useClasses()
  const commonClasses = useCommonClasses()

  const isBorder = useTableWithBorder()

  const localCellClasses = useMemo(
    () =>
      clsx(classes.headerCell, cellClasses, {
        [commonClasses.alignRight]: isNumberCell && !isBorder,
        [classes.columnCell]: !!children,
        [classes.sortersColumns]: isSortersColumn,
        [commonClasses.fixedHeader]: isFixed,
      }),
    [cellClasses, isNumberCell, children, isSortersColumn, isBorder, isFixed],
  )

  const valueRender = useMemo(() => {
    const sortWithBorder = withSort && isCellWithBorder

    return (
      <>
        <div
          className={clsx(classes.cellText, valueClasses, {
            [classes.withoutPadding]: sortWithBorder,
          })}
        >
          {withSort ? (
            <TableSortLabel
              active={!!sortDirection}
              direction={sortDirection || undefined}
              className={clsx({ [classes.sortWithCenterAlign]: sortWithBorder })}
              classes={{ icon: classes.sortIcon }}
              onClick={handleSort}
            >
              {value}
            </TableSortLabel>
          ) : (
            <span>{value}</span>
          )}
        </div>
        {children}
      </>
    )
  }, [valueClasses, withSort, isCellWithBorder, children, sortDirection, value])

  return (
    <>
      {(isDragging ? createPortal : dummyFunction)(
        <HeaderCell
          innerRef={innerRef}
          className={localCellClasses}
          {...draggableProps}
          {...dragHandleProps}
          style={draggableProps.style}
          colSpan={colSpan}
          sortDirection={sortDirection}
        >
          {valueRender}
        </HeaderCell>,
        document.body,
      )}
    </>
  )
})
