import { FormikValues } from 'formik'

import { InitialValuesDateAndIntervals } from '@/components/pages/sd/reports/components/ReportsModal/components/ReportModalForm/types'
import {
  RadioPeriod,
  RadioValue,
  ReportFormField,
  ReportInstallationValues,
} from '@/constants/forms/ReportForm'
import { ValidationErrors } from '@/constants/forms/validationErrors'
import { ParametersItems } from '@/types/sd/reports/reports'
import { ValidationService } from '@/utils/services/validationService'

class AdapterValidateCheckbox {
  public static required(
    method: (...args: any) => boolean,
    values: ReportInstallationValues & FormikValues,
    required: boolean,
    mandatory: boolean,
    type: number,
  ): boolean {
    if (type === 3) {
      return false
    }
    return method(values, required, mandatory)
  }
}

export const validateReportForm = (
  param: Array<ParametersItems>,
  values: ReportInstallationValues & FormikValues,
  initialValuesDateAndIntervals: InitialValuesDateAndIntervals,
) => {
  const errors: Partial<ReportInstallationValues & FormikValues> = {}
  const dynamicError = param.reduce((acc: FormikValues, item) => {
    if (
      AdapterValidateCheckbox.required(
        ValidationService.required,
        values[item.name],
        item.__required,
        item.mandatory,
        item.__controltype,
      )
    ) {
      acc[item.name] = ValidationErrors.Required
    }

    return acc
  }, {})

  if (ValidationService.isMaxLength(values[ReportFormField.ReportName], 256)) {
    errors[ReportFormField.ReportName] = ValidationErrors.MaxLengthReportName
  }
  if (ValidationService.isSpecialSymbols(values[ReportFormField.ReportName])) {
    errors[ReportFormField.ReportName] = ValidationErrors.SpecialSymbols
  }
  if (
    ValidationService.required(
      values[ReportFormField.ReportName],
      values[ReportFormField.SaveReport],
    )
  ) {
    errors[ReportFormField.ReportName] = ValidationErrors.Required
  }

  if (ValidationService.required(values[RadioPeriod.Period], values[ReportFormField.SubScribe])) {
    errors[RadioPeriod.Period] = ValidationErrors.Required
  }

  if (
    values[initialValuesDateAndIntervals.start] &&
    values[initialValuesDateAndIntervals.end] &&
    ValidationService.isInvalidDateInterval(
      new Date(values[initialValuesDateAndIntervals.start]),
      new Date(values[initialValuesDateAndIntervals.end]),
    )
  ) {
    errors[initialValuesDateAndIntervals.end] = ValidationErrors.EndDateCannotBeLessThanStart
  }

  switch (values[RadioPeriod.Period]) {
    case RadioValue.period:
      if (!values[ReportFormField.PeriodDays]) {
        errors[ReportFormField.PeriodDays] = ValidationErrors.Required
      }
      if (!ValidationService.isValidNumber(values[ReportFormField.PeriodDays])) {
        errors[ReportFormField.PeriodDays] = ValidationErrors.AllowedOnlyNumbers
      }
      if (ValidationService.isSpace(values[ReportFormField.PeriodDays])) {
        errors[ReportFormField.PeriodDays] = ValidationErrors.AllowedOnlyNumbers
      }
      break
    case RadioValue.week:
      if (ValidationService.isEmpty(values[ReportFormField.PeriodWeek])) {
        errors[ReportFormField.PeriodWeek as ReportFormField] = ValidationErrors.Required
      }
      break
    case RadioValue.month:
      if (!values[ReportFormField.PeriodMonth]) {
        errors[ReportFormField.PeriodMonth] = ValidationErrors.Required
      }
      break
    default:
      break
  }

  if (values[ReportFormField.SubScribe] && !values[ReportFormField.Interval]) {
    errors[ReportFormField.Interval] = ValidationErrors.Required
  }
  return { ...errors, ...dynamicError }
}
