import { Field as FormikField } from 'formik'
import _ from 'lodash'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { TicketsApi } from '@/api/sd/ticketsApi'
import { createCheckboxField } from '@/components/controls/Checkbox'
import {
  createDatePickerField,
  createDatePickerRangeField,
} from '@/components/controls/DateInput/component'
import { createDateTimePickerRangeFieldTransactions } from '@/components/controls/DateTimePickerTransactions/component'
import { createDropDownInputField } from '@/components/controls/DropDownInput'
import { createMultipleSelectAtmeye } from '@/components/controls/MultipleSelectAtemeye/component'
import { createMultipleSelectFieldWithConfig } from '@/components/controls/MultipleSelectWithConfig/component'
import { createRadioGroupFormikField } from '@/components/controls/RadioGroup/component'
import { createSelectField } from '@/components/controls/Select/component'
import { createTextInputField } from '@/components/controls/TextInput/component'
import { onlyNumbersAndDots } from '@/constants/disabledSymbols'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { setScrollMultiSelectConfigValues } from '@/store/atmeye/devices'
import {
  EnableModes,
  FilterFieldLookupInfo,
  FilterTypeDisplayName,
  FilterValueTypes,
} from '@/types/common/filterConfig'
import { usePreviousValue } from '@/utils/hooks/hooks'

import { createNumberRangeField } from '../NumberInput/component'
import { DependentParam, Props } from './types'

const DynamicField = ({
  isAtemeyFilterModal = false,
  defaultValues = [],
  params: {
    displayType,
    dropdownListEntityType,
    label,
    lookupInfo,
    allowMultipleSelect,
    parameterName,
    toolTip,
    hideEmptyItem,
    clearButton,
    valueType,
    additionalProperties,
  },
  formikValues,
  pattern,
  timer,
  options: receiveOptions,
  setFieldValue,
  filterModalFieldWatcher,
  fieldsOptions,
  setFieldError,
  validField,
  displayError,
  errorText,
  required,
  disableFutureDates,
  withoutArrayDate,
  modalType,
  edit,
  dateLimit,
  selectableTable,
  disabledKeys,
  isDisabled,
}: Props): ReactElement => {
  const dispatch = useDispatch()
  const [options, setOptions] = useState<{ name: string; value: string }[]>([])
  const [disabled, setDisabled] = useState<boolean>(true)
  const [dependentParams, setDependentParams] = useState<DependentParam[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const prevFormikValues: any = usePreviousValue(formikValues)

  const clearOptions = useCallback(() => {
    setOptions([])
  }, [setOptions])

  const onCall = useCallback(
    async ({
      value = '',
      pageNumber = 1,
      pageSize = 100,
    }: {
      value?: string
      pageNumber?: number
      pageSize?: number
    }): Promise<{ values: { name: string; value: string }[]; totalPages: number }> => {
      try {
        if (!lookupInfo?.getValuesURL) {
          setOptions([])
          return {
            values: [],
            totalPages: 1,
          }
        }

        setIsLoading(true)
        const response = await TicketsApi.getLookupValues({
          dependedOnFilter: dependentParams.length ? dependentParams : [],
          entityName: lookupInfo?.entityName,
          getValuesURL: lookupInfo?.getValuesURL,
          searchFragment: value,
          pagination: {
            direction: 'ASC',
            pageNumber: pageNumber,
            pageSize: pageSize,
          },
        })

        const values = {
          values: response.values.map(item => ({
            value: item.value,
            name: item.display,
          })),
          totalPages: response.pagination.totalPages,
        }
        dispatch(setScrollMultiSelectConfigValues({ parameterName, values: response.values }))
        return values
      } catch (e) {
        setIsLoading(false)
        console.error(e)
        return Promise.reject(e)
        // throw new Error(e)
      }
    },
    [dependentParams],
  )

  const getOptions = useCallback(
    async (
      info: FilterFieldLookupInfo,
      str?: string,
      pageNumber?: number,
      pageSize?: number,
    ): Promise<void> => {
      try {
        if (!info.getValuesURL) return setOptions([])

        setIsLoading(true)

        const response = await TicketsApi.getLookupValues({
          dependedOnFilter: dependentParams.length ? dependentParams : [],
          entityName: info.entityName,
          getValuesURL: info.getValuesURL,
          searchFragment: str || '',
          pagination: {
            direction: 'ASC',
            pageNumber: pageNumber || 1,
            pageSize: pageSize || 100,
          },
        })

        if (Array.isArray(response.values)) {
          setOptions(
            response.values.map(({ display, value }: { display: string; value: string }) => ({
              name: display,
              value,
            })),
          )
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        console.error(e)
      }
    },
    [dependentParams],
  )

  const handleSearch = useCallback(
    _.debounce((value: string) => {
      if (value.length >= 3 && lookupInfo) {
        return getOptions(lookupInfo, value)
      }
    }, 1000),
    [getOptions, lookupInfo, valueType],
  )

  useEffect(() => {
    const stored = sessionStorage.getItem(LocalStorageItems.LookupData)
    if (stored) {
      const response = JSON.parse(stored!)
      if (!edit) {
        setOptions(
          response.values.map(({ display, value }: { display: string; value: string }) => ({
            name: display,
            value,
          })),
        )
      } else {
        if (receiveOptions) {
          response.values.push({
            display: receiveOptions[0].parameterValue[0].name,
            value: receiveOptions[0].parameterValue[0].value,
          })
          setOptions(
            response.values.map(({ display, value }: { display: string; value: string }) => ({
              name: display,
              value,
            })),
          )
        }
      }
    }
  }, [receiveOptions])

  useEffect(() => {
    if (receiveOptions && receiveOptions.length && !edit) {
      const currentOptions = receiveOptions.find(option => option.parameterName === parameterName)
      if (currentOptions) {
        setOptions(currentOptions.parameterValue)
      }
    }
  }, [receiveOptions, parameterName])

  useEffect(() => {
    if (lookupInfo && lookupInfo.dependedOn.length) {
      const result = lookupInfo.dependedOn.map(item => formikValues[item]?.toString())

      // for usm because every method on empty array always true need check for all modules
      if (lookupInfo?.getValuesURL === 'usm|search-form|/lookup|FUNCTION|{companyid:$p_company}') {
        if (
          lookupInfo.enableMode === EnableModes.AllParentIsSet &&
          result &&
          result[0] &&
          !result[0].length
        ) {
          return setDisabled(true)
        }
      }

      if (lookupInfo.enableMode === EnableModes.AllParentIsSet && result.every(item => item)) {
        return setDisabled(false)
      }

      if (lookupInfo.enableMode === EnableModes.SomeParentIsSet && result.some(item => item)) {
        return setDisabled(false)
      }

      setDisabled(true)
    }
  }, [lookupInfo, formikValues, disabled, prevFormikValues, receiveOptions, setFieldValue])

  useEffect(() => {
    if (lookupInfo?.dependedOn.length && !lookupInfo?.resetDependencies) {
      const parentIsFilled = lookupInfo.dependedOn.some(item => formikValues[item])

      if (!parentIsFilled && formikValues[parameterName]) {
        setFieldValue(parameterName, '')
      }
    }
  }, [formikValues])

  useEffect(() => {
    if (
      lookupInfo &&
      !formikValues[parameterName] &&
      !lookupInfo.allowRecursiveCallsBySearchFragment
    ) {
      if (!lookupInfo.loadLookupValuesOnClick) {
        getOptions(lookupInfo)
      }
    }
  }, [lookupInfo, formikValues, parameterName, getOptions, valueType])

  useEffect(() => {
    if (lookupInfo && lookupInfo.dependedOn.length) {
      const result: DependentParam[] = lookupInfo.dependedOn.map(item => {
        const isIntegerValue = 'INTEGER'

        if (Array.isArray(formikValues[item])) {
          return {
            parameterName: item,
            parameterValue: formikValues[item].map((value: string) => ({
              id: isIntegerValue ? value : null,
              value: isIntegerValue ? null : value,
            })),
            valueType,
          }
        }

        // todo: think how to refactor it if needed
        if (item === 'startDate' || item === 'endDate') {
          if (formikValues && formikValues.eventsDate && formikValues.eventsDate.length === 2) {
            return {
              parameterName: item,
              parameterValue: [
                {
                  id:
                    item === 'startDate' ? formikValues.eventsDate[0] : formikValues.eventsDate[1],
                  value: null,
                },
              ],
              valueType,
            }
          }
        }

        return {
          parameterName: item,
          parameterValue: [
            {
              id: isIntegerValue ? formikValues[item] : null,
              value: isIntegerValue ? null : formikValues[item],
            },
          ],
          valueType,
        }
      })

      if (!_.isEqual(result, dependentParams)) {
        setDependentParams(result)
      }
    }
  }, [formikValues, lookupInfo, dependentParams, valueType])

  const valueTypeValidator = (valType: any) => {
    switch (valType) {
      case FilterValueTypes.Integer:
        return createNumberRangeField({
          label,
          fullWidth: true,
          placeholder: '',
          clearButton: true,
          setFieldError,
          InputLabelProps: {
            shrink: true,
          },
        })
      case FilterValueTypes.Date:
        return createDatePickerRangeField({
          isAtemeyFilterModal,
          title: label,
          dateLimitForReports: dateLimit,
          disableFutureDates,
          selectableTable,
        })
      case FilterValueTypes.Datetime:
        return createDateTimePickerRangeFieldTransactions({
          required,
          isAtemeyFilterModal,
          title: label,
          disableFutureDates,
          disabled: isDisabled,
          params: additionalProperties,
        })
      case FilterValueTypes.ZonedDate:
        return createDateTimePickerRangeFieldTransactions({
          isAtemeyFilterModal,
          title: label,
          disableFutureDates,
        })
      default:
    }
  }

  const Field = useMemo(() => {
    switch (displayType) {
      case FilterTypeDisplayName.Text: {
        return createTextInputField({
          disabled: isDisabled,
          label,
          fullWidth: true,
          pattern: pattern,
          placeholder: toolTip,
          clearButton: true,
          InputLabelProps: {
            shrink: true,
          },
          disabledKeys: valueType === FilterValueTypes.Number ? onlyNumbersAndDots : disabledKeys,
          displayError: displayError,
          errorText: errorText,
          required: required,
          error: displayError,
        })
      }
      case FilterTypeDisplayName.List:
        if (lookupInfo) {
          const { allowRecursiveCallsBySearchFragment, lookupValues } = lookupInfo

          if (allowRecursiveCallsBySearchFragment) {
            return createDropDownInputField({
              isLoading,
              label,
              placeholder: toolTip,
              required,
              // required:
              //   required &&
              //   (lookupInfo.valueFieldName === 'issueCompanyId' ||
              //     lookupInfo.valueFieldName === 'cardactionid' ||
              //     lookupInfo.valueFieldName === 'internalType'),
              pattern,
              shrink: true,
              timer,
              options: lookupValues.length
                ? lookupValues.map(({ display, value }) => ({
                    name: display,
                    value,
                  }))
                : options,
              filtering: false,
              onTextChange: handleSearch,
              disabled: lookupInfo.enableMode === EnableModes.Always ? false : disabled,
              neverDisable: false,
              withoutSingleOption: true,
              defaultValue: { value: formikValues[parameterName], name: '' },
              filterModalFieldWatcher,
              // loadData:
              //   lookupInfo.valueFieldName === 'issueCompanyId'
              //     ? (value: string) => getOptions(lookupInfo, value)
              //     : null,
              modalType: modalType,
              clearOptions,
              // ...(lookupInfo.valueFieldName === 'issueCompanyId' ? {} : clearOptions),
              getOptions: async () => getOptions(lookupInfo),
              resetDependencies: lookupInfo.resetDependencies,
            })
          }

          if (allowMultipleSelect && isAtemeyFilterModal) {
            return createMultipleSelectAtmeye({
              label,
              required,
              fieldName: parameterName,
              placeholder: toolTip,
              dropdownListEntityType,
              defaultValues,
            })
          }

          const itemsIds = new Set(lookupValues?.map(item => item.value))
          const mergedLookupOptions = [
            ...lookupValues?.map(({ display, value }) => ({ name: display, value })),
            ...options?.filter(item => !itemsIds.has(item.value)),
          ]

          if (allowMultipleSelect) {
            return createMultipleSelectFieldWithConfig({
              required,
              isLoading,
              label,
              shrink: true,
              parameterName,
              placeholder: toolTip,
              SearchInputBlock: true,
              totalPages: 2,
              // options: lookupValues.map(({ display, value }) => ({
              //   name: display,
              //   value,
              // })),
              // options: lookupValues.length
              //   ? lookupValues.map(({ display, value }) => ({ name: display, value }))
              //   : options,
              options: mergedLookupOptions,
              disabled: lookupInfo.enableMode === EnableModes.Always ? false : disabled,
              onCall,
              onOpen: (event: React.ChangeEvent<{}>) => {
                if (lookupInfo && lookupInfo.loadLookupValuesOnClick) {
                  getOptions(lookupInfo)
                }
              },
              resetDependencies: lookupInfo.resetDependencies,
            })
          }

          if (lookupValues.length > 10) {
            return createDropDownInputField({
              label,
              placeholder: toolTip,
              required,
              pattern,
              shrink: true,
              options: lookupValues.map(({ display, value }) => ({
                name: display,
                value,
              })),
              onTextChange: handleSearch,
              disabled:
                lookupInfo.enableMode === EnableModes.Always ? false : disabled || isDisabled,
              neverDisable: false,
              resetDependencies: lookupInfo.resetDependencies,
            })
          }

          if (lookupValues.length <= 1) {
            return createDropDownInputField({
              label,
              placeholder: toolTip,
              required,
              pattern,
              shrink: true,
              // options: lookupValues.length
              //   ? lookupValues.map(({ display, value }) => ({ name: display, value }))
              //   : options,
              options: mergedLookupOptions,
              onTextChange: handleSearch,
              disabled:
                lookupInfo.enableMode === EnableModes.Always ? false : disabled || isDisabled,
              neverDisable: false,
              // onOpen: lookupValues.length ? null : (value: string) => getOptions(lookupInfo),
              onOpen: (value: string) => getOptions(lookupInfo),
              withoutSingleOption: true,
              resetDependencies: lookupInfo.resetDependencies,
            })
          }

          return createSelectField({
            isLoading,
            required,
            label,
            placeholder: toolTip,
            shrink: true,
            hideEmptyItem: hideEmptyItem,
            // options: lookupValues.length
            //   ? lookupValues.map(({ display, value }) => ({ name: display, value }))
            //   : options,
            options: mergedLookupOptions,
            clearButton: clearButton,
            parameterName: parameterName,
            disabled:
              isDisabled || (lookupInfo.enableMode === EnableModes.Always ? false : disabled),
            neverDisable: false,
            // onOpen: lookupValues.length ? null : (value: string) => getOptions(lookupInfo),
            onOpen: (value: string) => getOptions(lookupInfo),
            withAutofillForSingleOption: false,
            withoutCheckerForSingleOption: true,
            resetDependencies: lookupInfo.resetDependencies,
          })
        }

        break
      case FilterTypeDisplayName.Range:
        return valueTypeValidator(valueType)
      case FilterTypeDisplayName.YesNo:
        break
      case FilterTypeDisplayName.YesNoAll:
        return createRadioGroupFormikField({
          options:
            lookupInfo?.lookupValues.map(({ value, display }) => ({
              value: value,
              label: display,
            })) || [],
          className: fieldsOptions?.radioGroup?.className,
          label,
        })
      case FilterTypeDisplayName.RadioGroup:
        return createRadioGroupFormikField({
          options:
            lookupInfo?.lookupValues.map(({ value, display }) => ({
              value: value,
              label: display,
            })) || [],
          className: fieldsOptions?.radioGroup?.className,
        })
      case FilterTypeDisplayName.Checkbox:
        return createCheckboxField({ label })
      case FilterTypeDisplayName.Date:
        return createDatePickerField({
          label,
          required: required,
          Icon: fieldsOptions?.datePicker?.DateInputIcon,
          disableFutureDates: disableFutureDates,
          withoutArrayDate: withoutArrayDate,
        })
      default:
    }
  }, [
    isLoading,
    isDisabled,
    allowMultipleSelect,
    displayType,
    label,
    lookupInfo,
    options,
    parameterName,
    pattern,
    timer,
    disabled,
    handleSearch,
    required,
    toolTip,
    validField,
    displayError,
    modalType,
  ])

  return <FormikField name={parameterName}>{Field}</FormikField>
}

export default DynamicField
