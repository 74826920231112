import { Theme } from '@material-ui/core/styles'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { styledComponentTheme } from '@/theme'

interface StylesProps {
  collapse: boolean
}

export const useClasses = makeStyles<Theme, StylesProps>(({ spacing }) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  cellIcon: {
    width: spacing(1.5),
    marginRight: spacing(1),
  },

  iconSize: {
    fontSize: styledComponentTheme.fontSizes.big,
  },
}))
