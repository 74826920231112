import { AnyAction } from 'redux'

import { ErrorActions } from '@/constants'

export type ActError = {
  type?: 'ack_error' | 'use_force'
  isVisible: boolean
  errorMessage?: string | null | React.ReactElement
  actionType?: string | null
  actionPayload?: unknown | null
}
export type MultipleErrors = {
  isVisible: boolean
  errorMessage?: string | null | React.ReactElement
  messageArray: string[]
}

export interface State {
  actError: ActError

  multipleErrors: MultipleErrors
}

const initialState: State = {
  actError: {
    type: undefined,
    isVisible: false,
    errorMessage: null,
    actionType: null,
    actionPayload: null,
  },

  multipleErrors: {
    isVisible: false,
    errorMessage: null,
    messageArray: [],
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case ErrorActions.SetActErrorVisible:
      return {
        ...state,
        actError: {
          ...state.actError,
          ...payload,
        },
      }
    case ErrorActions.SetActErrorInitial:
      return {
        ...state,
        actError: {
          ...initialState.actError,
        },
      }
    case ErrorActions.SetMultipleErrorsVisible:
      return {
        ...state,
        multipleErrors: {
          ...state.multipleErrors,
          ...payload,
        },
      }
    case ErrorActions.SetMultipleErrorsInitial:
      return {
        ...state,
        multipleErrors: {
          ...initialState.multipleErrors,
        },
      }
    default:
      return state
  }
}
