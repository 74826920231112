import queryString from 'query-string'

import {
  AdmDenomination,
  AdmDenominationBase,
  AdmDenominationCurrency,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { Sort } from '@/types/cm'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

export class DenominationsAdminApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getAll(sort: Sort): Promise<AdmDenomination[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/denominations',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )
      return await DenominationsAdminApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(currency: AdmDenominationBase): Promise<void> {
    try {
      return await DenominationsAdminApi.requestService.post('/admin/denominations/add', currency)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(currency: AdmDenomination): Promise<void> {
    try {
      return await DenominationsAdminApi.requestService.put('/admin/denominations/update', currency)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(id: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/denominations/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await DenominationsAdminApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getCurrencies(): Promise<AdmDenominationCurrency[]> {
    try {
      return await DenominationsAdminApi.requestService.get('/admin/denominations/currencies')
    } catch (error) {
      throw Error(error)
    }
  }
}
