import { isEqual } from 'lodash-es'
import { Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useState } from 'react'

import { sortTablesColumnByPattern } from '@/helpers/sortTableColumns'
import { getTableConfig, setTableConfig } from '@/helpers/tableLocalStorage'

import { TableData } from '../types'

export interface ColumnsProps {
  tableId?: string
  exceptColumns?: string[]
  defaultColumns?: string[]
  headerConfig?: { [key: string]: string | ReactElement }
}

interface ColumnsHookProps extends ColumnsProps {
  data: TableData
  settingsModalOpened: boolean
}

interface ResultProps {
  keys: string[]
  setKeys: Dispatch<SetStateAction<string[]>>
}

export const useColumns = (props: ColumnsHookProps): ResultProps => {
  const {
    tableId,
    headerConfig = {},
    exceptColumns = [],
    defaultColumns = [],
    data,
    settingsModalOpened,
  } = props

  const [keys, setKeys] = useState<string[]>([])

  const tableConfigByLocalStorage = tableId ? getTableConfig({ tableId }) : null

  const headerConfigKeys = useMemo(() => Object.keys(headerConfig || {}), [headerConfig])
  const keysByData = Object.keys(data || {})
  const keysToSet = headerConfigKeys.length ? headerConfigKeys : keysByData

  useEffect(() => {
    if (!settingsModalOpened) {
      if (tableConfigByLocalStorage) {
        const { columns, columnsOrder } = tableConfigByLocalStorage

        const parsedTableColumns = sortTablesColumnByPattern({
          columns,
          pattern: columnsOrder,
        })
        const filteredKeys = parsedTableColumns.filter(
          tableColumn => !exceptColumns.includes(tableColumn),
        )

        if (!isEqual(filteredKeys, keys)) {
          setKeys(filteredKeys)
        }
        return
      }
      const localKeysToSet = !exceptColumns.length
        ? keysToSet
        : keysToSet.filter(tableColumn => !exceptColumns.includes(tableColumn))
      !isEqual(keys, localKeysToSet) && setKeys(localKeysToSet)

      if (tableId) {
        const filteredColumns = defaultColumns.length
          ? keysToSet.filter(header => defaultColumns.includes(header))
          : keysToSet

        setTableConfig({
          tableId,
          tableData: { columns: filteredColumns, columnsOrder: keysToSet },
        })
      }
    }
  }, [tableConfigByLocalStorage, keysToSet, keys, settingsModalOpened])

  return {
    keys,
    setKeys,
  }
}
