import makeStyles from '@material-ui/core/styles/makeStyles'
import styled from 'styled-components'

import { styledComponentTheme } from '@/theme/styledComponentTheme'

export const useClasses = makeStyles(({ spacing }) => ({
  wrapper: {
    height: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    padding: '20px 0',
  },

  deviceTitle: {
    fontSize: '20px',
    marginBottom: 0,
    padding: '0 30px',
  },

  message: {
    fontSize: '32px',
    padding: '0 30px',
  },

  img: {
    width: '100%',
  },

  imgContainer: {
    maxHeight: '370px',
    overflow: 'auto',
  },

  button: {
    fontWeight: 'normal',
    background: styledComponentTheme.colors.baseGreen,
    color: 'white',
    border: 0,
    marginTop: 0,
    marginRight: '10px',
    marginBottom: 0,
    padding: '10px 20px',

    '&:hover': {
      background: styledComponentTheme.colors.baseGreen,
      color: 'white',
      border: 0,
    },
  },

  loaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    background: 'rgba(255, 255, 255, 0.7)',
  },

  closeButton: {
    top: spacing(1),
    right: spacing(1),
    '& [class*="makeStyles-icon"]': {
      color: '#041517',
    },
  },
}))

export const ContentWrapper = styled.div`
  background-color: #ffffff;
  padding: 30px;
`
