import { CancelToken } from 'axios'

import { store } from '@/App'
import { DeleteFolderPayload } from '@/components/pages/atmeye/rfm/SingleDevice/components/folderActions/types'
import { Schedule } from '@/components/pages/atmeye/rfm/SingleDevice/components/scheduleModal/types'
import { APIConfiguration } from '@/constants'
import { CommonActions } from '@/constants/atmeye/actions/actionsCommon'
import { UPLOAD_SIZE_LIMIT } from '@/constants/endpoints'
import { ResponseError } from '@/types'
import { getRequestService } from '@/utils/services/request'

const MULTIPLE_DEVICES_JOB_URL = '/rfm/api/v1/multiple-devices-jobs'
const SINGLE_DEVICES_JOB_URL = '/rfm/api/v1/single-device-jobs'

export class RfmApi {
  private static requestService = getRequestService(APIConfiguration.API_BASE_URL)

  public static async logSingleDeviceJobError(payload: {
    deviceName: string
    jobId: number
    errorMessage: string
  }): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.patch(`/rfm/api/v1/single-device-jobs/error-info`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async logMultipleDevicesJobError(payload: {
    devicesIds: (string | number)[]
    jobId: number
    errorMessage: string
  }): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.patch(
        `/rfm/api/v1/multiple-devices-jobs/error-info`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async uploadFile(
    file: File,
    changeJob: (progress: number) => void,
    onError: (e: any) => void,
  ): Promise<string | ResponseError> {
    try {
      if (+UPLOAD_SIZE_LIMIT < file.size) {
        store.dispatch({
          type: CommonActions.SetAckWarning,
          payload: {
            errorMessage: 'atmeye.fileSizeLimit',
            messageParameters: {
              limit: UPLOAD_SIZE_LIMIT,
              size: file.size,
              fileName: file.name,
            },
          },
        })

        throw new Error(`${file.name} File size limit`)
      }

      return await RfmApi.requestService.postFormData(
        `/storage/api/v1/files/upload?fileName=${file.name}`,
        {
          file,
        },
        {
          onUploadProgress: (progressEvent: any) => {
            const totalLength = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader('content-length') ||
                progressEvent.target.getResponseHeader('x-decompressed-content-length')
            if (totalLength) {
              const progress = Math.round((progressEvent.loaded * 100) / totalLength)
              changeJob && changeJob(progress)
            }
          },
        },
      )
    } catch (e) {
      onError && onError(e)
      throw new Error(e)
    }
  }

  public static async downloadFile(fileId: string): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.get(
        `/storage/api/v1/file/${fileId}`,
        {},
        { responseType: 'blob' },
        true,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getFileURL(fileId: string): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.get(`/api/v1/files/url?fileId=${fileId}`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getSyncedFolderFiles(payload: any): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.post(`/storage/api/v1/folders/files`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async deleteSyncFile(payload: any): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.delete(
        `/storage/api/v1/folders/${payload.folderId}/files?filesIds=${payload.fileId}`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async deleteSyncFolder(payload: DeleteFolderPayload): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.post(`/storage/api/v1/folders/delete`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async addDirectoryToSync(payload: {
    deviceName?: string
    directoryPath?: string
    isRecursive?: boolean
  }): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.post(
        `/rfm/api/v1/directories-synchronization/single-device`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async editDirectorySync(payload: {
    deviceName: string
    directoryPath: string
    isRecursive: boolean
  }): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.put(
        `/rfm/api/v1/directories-synchronization/single-device`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async pauseOrResumeDirectorySync(payload: {
    deviceName: string
    directoryPath: string
  }): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.patch(
        `/rfm/api/v1/directories-synchronization/single-device`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async removeDirectoryFromSync(payload: {
    deviceName: string
    directoryPath: string
  }): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.post(
        `/rfm/api/v1/directories-synchronization/single-device-removal`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async multipleDevicesAddDirectoryToSync(payload: {
    devicesIds?: (string | number)[]
    directoryPath?: string
    isRecursive?: boolean
  }): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.post(
        `/rfm/api/v1/directories-synchronization/multiple-devices`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async multipleDevicesEditDirectorySync(payload: {
    devicesIds: (string | number)[]
    directoryPath: string
    isRecursive: boolean
  }): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.put(
        `/rfm/api/v1/directories-synchronization/multiple-devices`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async multipleDevicesPauseOrResumeDirectorySync(payload: {
    devicesIds: (string | number)[]
    directoryPath: string
    active?: boolean
  }): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.patch(
        `/rfm/api/v1/directories-synchronization/multiple-devices`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async multipleDevicesRemoveDirectoryFromSync(payload: {
    devicesIds: (string | number)[]
    directoryPath: string
  }): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.post(
        `/rfm/api/v1/directories-synchronization/multiple-devices-removal`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getJobs(payload: any): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.post(`/rfm/api/v1/jobs/list`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async jobsSingleDevicesInitializing(
    deviceName: string | null,
    jobType: string,
    scheduleJobRequest: Schedule,
    affectedDirectoriesIds: (string | null)[],
  ): Promise<any | ResponseError> {
    try {
      const payload = {
        deviceName,
        jobType,
        scheduleJobRequest,
        affectedDirectoriesIds,
      }
      return await RfmApi.requestService.post(
        `/rfm/api/v1/single-device-jobs/initializing`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async jobsMultipleDevicesInitializing(
    jobType: string,
    scheduleJobRequest: Schedule,
    devicesIds: (string | number)[],
    affectedDirectoriesNames: (string | null)[],
  ): Promise<any | ResponseError> {
    try {
      const payload = {
        devicesIds,
        jobType,
        scheduleJobRequest,
        affectedDirectoriesNames,
      }
      return await RfmApi.requestService.post(
        `/rfm/api/v1/multiple-devices-jobs/initializing`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async deleteJobs(jobsIds: string): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.delete(`/rfm/api/v1/jobs${jobsIds}`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async downloadFolderJob(payload: any): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.post(
        `${SINGLE_DEVICES_JOB_URL}/download-archived-directory-job`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async uploadFolderJob(payload: any): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.post(
        `${SINGLE_DEVICES_JOB_URL}/upload-archived-directory-job`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async purgeFolderJob(payload: any): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.post(
        `${SINGLE_DEVICES_JOB_URL}/purge-directory-job`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getRootFoldersNames(): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.get(`/rfm/api/v1/folders`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async downloadFileJob(payload: any): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.post(
        `${SINGLE_DEVICES_JOB_URL}/download-files-job`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async runFileJob(payload: any): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.post(`${SINGLE_DEVICES_JOB_URL}/run-file-job`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async uploadFileJob(payload: any): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.post(`${SINGLE_DEVICES_JOB_URL}/upload-files-job`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  // Multiple devices
  public static async purgeFolderJobMultiDevices(payload: any): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.post(
        `${MULTIPLE_DEVICES_JOB_URL}/purge-directory-job`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async uploadFilesJobMultiDevices(payload: any): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.post(
        `${MULTIPLE_DEVICES_JOB_URL}/upload-files-job`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async uploadFileAndRunJobMultiDevices(payload: any): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.post(
        `${MULTIPLE_DEVICES_JOB_URL}/upload-and-run-file-job`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async uploadFolderJobMultiDevices(payload: any): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.post(
        `${MULTIPLE_DEVICES_JOB_URL}/upload-archived-directory-job`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async downloadFolderJobMultiDevices(payload: any): Promise<any | ResponseError> {
    try {
      return await RfmApi.requestService.post(
        `${MULTIPLE_DEVICES_JOB_URL}/download-archived-directory-job`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async transactionHistoryLogList(payload: any, cancelToken?: CancelToken) {
    try {
      return await RfmApi.requestService.post(
        '/atmeye/api/v1/task-event-file-download/list',
        payload,
        false,
        {
          cancelToken,
        },
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async synchronizationList(payload: any, cancelToken?: CancelToken) {
    try {
      return await RfmApi.requestService.post('/atmeye/api/v1/sync-file/list', payload, false, {
        cancelToken,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async transactionHistoryStopTask() {
    try {
      return await RfmApi.requestService.post(
        '/atmeye/api/v1/task-event-file-download/shutdown-task',
        {},
        false,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async syncHistoryStopTask() {
    try {
      return await RfmApi.requestService.post('/atmeye/api/v1/sync-file/shutdown-task', {}, false)
    } catch (e) {
      throw new Error(e)
    }
  }
}
