import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getVaultsCashFlow = (state: AppState): fromReducer.State => {
  return state.vaultV1CashFlow
}

export const getCashFlow = createSelector(getVaultsCashFlow, state => state.cashFlow)
export const getSelectedCashFlow = createSelector(getCashFlow, state => state.selected)
export const getSelectedCashFlowCurrency = createSelector(
  getCashFlow,
  state => state.selectedCurrency,
)
export const getCashFlowFilter = createSelector(getCashFlow, state => state.filter)
export const getCashFlowModals = createSelector(getVaultsCashFlow, state => state.modals)
