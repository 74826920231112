import makeStyles from '@material-ui/core/styles/makeStyles'
import styled from 'styled-components'

import { styledComponentTheme } from '@/theme/styledComponentTheme'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  deviceTitle: {
    fontSize: '20px',
    marginBottom: spacing(2),
  },

  actionContext: {
    display: 'flex',
  },

  root: {
    boxShadow: '0px 0px 15px rgba(37, 173, 191, 0.05)',
    borderRadius: '3px',
    padding: 0,
    marginTop: spacing(2.5),
  },
  tabWrap: {
    background: '#FFFFFF',
    borderRadius: '3px',
    marginRight: 'auto',
    '& button': {
      margin: '10px',
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  deviceName: {
    color: '#929A9B',
  },
  deviceHeader: {
    marginBottom: spacing(1),
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    background: 'transparent',
    fontWeight: 'normal',
    color: styledComponentTheme.colors.baseGreen,
    border: '1px solid',
    marginRight: spacing(2.5),
    '&:hover': {
      background: styledComponentTheme.colors.baseGreen,
      color: 'white',
      borderColor: styledComponentTheme.colors.baseGreen,
      '& .MuiButton-startIcon': {
        filter: 'brightness(0) invert(1)',
      },
    },
    '& .MuiButton-startIcon': {
      marginLeft: '-16px',
      marginRight: '0',
      width: '50px',
      justifyContent: 'center',
    },
  },
  tableTitle: {
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
    fontSize: '20px',
    marginBottom: '12px',
  },
  deleteButton: {
    background: '#FDEFED',
    color: '#E45B4A',
    border: '0',
    '&:hover': {
      background: '#FDEFED',
      color: '#E45B4A',
      '& .MuiButton-startIcon': {
        filter: 'none',
      },
    },
  },
  switch: {
    color: '#929A9B',
    marginLeft: '0',
    '& .MuiSwitch-root': {
      padding: '10px',
    },
    '& .MuiSwitch-track': {
      borderRadius: spacing(2),
    },
    '& .MuiFormControlLabel-label': {
      minWidth: '76px',
    },
  },
  switchOff: {
    '& .MuiSwitch-thumb': {
      background: '#929A9B',
    },
    '& .MuiSwitch-track': {
      background: '#ECF2F2',
      opacity: '1',
    },
  },
  switchOn: {
    color: '#25ADBF',
    '& .MuiSwitch-thumb': {
      background: '#25ADBF',
    },
    '& .MuiSwitch-track': {
      '&:nth-of-type(n)': {
        background: '#EAF7F9',
        opacity: '1',
      },
    },
  },
}))

export const ContentWrapper = styled.div`
  background-color: #ffffff;
`
