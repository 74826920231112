import { Box, Grid, Typography } from '@material-ui/core'
import React, { ReactElement } from 'react'

import { Badge } from '@/components/blocks/ATMeye/Badge'
import Button from '@/components/controls/Button'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import { Props } from './types'

export const SingleMarker = ({
  technicalEventCount,
  city,
  address,
  country,
  alarmCount,
  deviceName,
  deviceType,
  deviceId,
  handleSetCurrentDeviceId,
  transactionsCount,
}: Props): ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  return (
    <Grid container direction="column" alignItems="flex-start">
      <Box className={classes.mainContainer}>
        <Box>
          <Typography className={classes.subhead}>{deviceName}</Typography>
        </Box>
        <Box className={classes.rowContainer}>
          <Typography className={classes.description}>
            {translate('translate#atmeye.title.transactions')}(
            {translate('translate#atmeye.properties.1h')})
          </Typography>
          <Typography className={classes.content}>
            <Badge variant="transactions" title={transactionsCount} />
          </Typography>
        </Box>
        <Box className={classes.rowContainer}>
          <Typography className={classes.description}>
            {translate('translate#atmeye.title.devices.techEvents')}(
            {translate('translate#atmeye.properties.1h')})
          </Typography>
          <Typography className={classes.content}>
            <Badge variant="event" title={technicalEventCount} />
          </Typography>
        </Box>
        <Box className={classes.rowContainer}>
          <Typography className={classes.description}>
            {translate('translate#atmeye.alarms')}({translate('translate#atmeye.properties.1h')})
          </Typography>
          <Typography className={classes.content}>
            <Badge variant="alarm" title={alarmCount} />
          </Typography>
        </Box>
        <Box className={classes.rowContainer}>
          <Typography className={classes.description}>
            {translate('translate#atmeye.dashboard.widgets.filters.deviceTypes')}
          </Typography>
          <Typography className={classes.content}>{deviceType}</Typography>
        </Box>
        <Box className={classes.rowContainer}>
          <Typography className={classes.description}>
            {translate('translate#atmeye.properties.country')}
          </Typography>
          <Typography className={classes.content}>{country}</Typography>
        </Box>
        {city && (
          <Box className={classes.rowContainer}>
            <Typography className={classes.description}>
              {translate('translate#atmeye.properties.city')}
            </Typography>
            <Typography className={classes.content}>{city}</Typography>
          </Box>
        )}
        <Box className={classes.rowContainer}>
          <Typography className={classes.description}>
            {translate('translate#atmeye.devices.title.address')}
          </Typography>
          <Typography className={classes.content}>{address}</Typography>
        </Box>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={handleSetCurrentDeviceId({ deviceId: +deviceId, deviceName })}
        >
          {translate('translate#title.details')}
        </Button>
      </Box>
    </Grid>
  )
}
