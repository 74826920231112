import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import clsx from 'clsx'
import React, { ReactElement } from 'react'

import Loader from '@/components/blocks/Loader'

import { CollapseCellTypes } from '../types/types'
import { useClasses } from './styles'

const CollapseCell = ({
  withBlueColor,
  isHasChildren,
  isCollapse,
  text,
  key,
  nesting,
  handleCollapse,
  isLoading,
}: CollapseCellTypes): ReactElement => {
  const classes = useClasses()

  return (
    <TableCell
      key={key}
      align="left"
      className={classes.cell}
      style={{ paddingLeft: `${20 * nesting + 12}px` }}
    >
      <div
        className={clsx(classes.wrapper, {
          [classes.withBlueColor]: withBlueColor,
        })}
      >
        {isHasChildren && (
          <IconButton disabled={isLoading} size="small" onClick={handleCollapse}>
            {isCollapse ? (
              <KeyboardArrowDownIcon className={classes.iconSize} />
            ) : (
              <KeyboardArrowRightIcon className={classes.iconSize} />
            )}
            {isLoading && <Loader className={classes.loader} />}
          </IconButton>
        )}
        <Typography variant="body2">{text}</Typography>
      </div>
    </TableCell>
  )
}

export { CollapseCell }
