import { FilterField, GroupInfo } from '@/api/atmeye/commonTypes'
import { exportFile } from '@/utils/exportFile'

import { TableGroup } from '../components/modals/GroupingModal/components/SortBlock/types'
import { GroupNodes } from '../components/ObjectsTree/types'
import { FilterView } from './useFilterView'

type useExportTableTypes = {
  hasGrouping?: boolean
  selectedGrouping: TableGroup[]
  selectedGroupingNodes: GroupNodes
  columns: any[]
  sort?: string
  filterView: FilterView[]
  getFilters: () => Record<string, FilterField>
  API: { getExportGroupedData: any; getExportData: any }
}

const useExportTable = ({
  hasGrouping,
  selectedGrouping,
  selectedGroupingNodes,
  columns,
  sort,
  filterView,
  getFilters,
  API,
}: useExportTableTypes) => {
  const exportTableData = async (format: string) => {
    const filter = getFilters()

    const commonPayload = {
      filter,

      config: {
        format,
        appliedFilters: filterView,
        selectedOutputFields: columns.reduce((acc, { fieldLabel, fieldName }) => {
          acc[fieldName] = fieldLabel
          return acc
        }, {}),
      },

      sort,
    }

    const groupingPayload = {
      config: commonPayload.config,
      groupsTreeRequest: {
        filter,
        groupingFilters: selectedGroupingNodes.reduce((acc, { nodeFilter }) => {
          acc.push(nodeFilter)

          return acc
        }, [] as Record<string, FilterField>[]),
        group: selectedGrouping.reduce(
          (acc, { groupingField, groupingSortDirection, groupingSortType }, i) => {
            acc[groupingField] = {
              levelNumber: i,
              sortType: groupingSortType,
              sortDirection: groupingSortDirection,
            }

            return acc
          },
          {} as Record<string, GroupInfo>,
        ),
      },
      sort,
    }

    try {
      const data = hasGrouping
        ? await API.getExportGroupedData(groupingPayload, true)
        : await API.getExportData(commonPayload, true)
      exportFile(data)
    } catch (e) {}
  }

  return { exportTableData }
}

export { useExportTable }
