import { ComEnumValue } from '@/api/vault-v1/swaggerGeneratedApi'
import { SelectableItem } from '@/types'

const noneFilter = (options: SelectableItem[]): SelectableItem[] =>
  options.filter(option => !option.value.toLowerCase().includes('none'))

export const mapEnumsToOptions = (enums: ComEnumValue[]): SelectableItem[] => {
  return noneFilter(
    enums.map(item => ({
      value: item.enumValue || '',
      name: item.enumName || '',
    })),
  )
}
