import { createStyles, makeStyles } from '@material-ui/core/styles'
import { isIE } from 'react-device-detect'

import { styledComponentTheme } from '@/theme/styledComponentTheme'

export const useClasses = makeStyles(() =>
  createStyles({
    arrowButton: {
      border: '1px solid',
      borderColor: styledComponentTheme.colors.baseGreen,
      width: '40px',
      height: '40px',
      padding: '0',
      minWidth: 'auto',
      marginRight: '16px',

      '& .MuiButton-startIcon': {
        margin: '0',
      },
    },

    prevNextPaginationWrapper: {
      minHeight: '70px',
      padding: '0 20px',
      display: 'flex',
      alignItems: 'center',
      columnGap: '20px',
    },
    prevNextButtonsGroup: {
      display: 'flex',
      alignItems: 'center',
    },

    pagination: {
      '& .MuiPagination-ul': {
        flexWrap: 'nowrap',
      },
    },

    paginationWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
      background: 'white',
      flexWrap: 'wrap',
      padding: '0 20px',
      // marginTop: 'auto',
    },
    positionStart: {
      justifyContent: 'flex-start',
    },
    paginationItemsWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    wrapperFullWidth: {
      width: '100%',
    },
    buttonsWrapper: {
      marginRight: 'auto',
      '& button': {
        margin: '0 10px',
        '&:first-child': {
          marginLeft: '20px',
        },
        '&:last-child': {
          marginRight: '20px',
        },
      },
    },
    elements: {
      bottom: isIE ? '10px' : '',
      // marginRight: '16px',
    },
    spacer: {
      display: 'none',
    },
    select: {
      color: styledComponentTheme.colors.baseGreen,
      borderBottom: '1px solid #ECF2F2',
      '&:last-child': {
        marginBottom: '-8px',
      },
      '&:first-child': {
        marginTop: '-8px',
      },
    },
    positionInit: {
      position: 'initial',
    },
    input: {
      backgroundColor: 'white',
      marginLeft: '4px',
      marginRight: '20px',
      '& .MuiTablePagination-select:focus': {
        backgroundColor: 'white',
      },
    },
    selectIcon: {
      transform: 'scale(0.8)',
      '& path': {
        d: 'path("M 16.59 8.59 L 12 13.17 L 7.41 8.59 L 6 10 l 6 6 l 6 -6 Z")',
      },
    },
    toolbar: {
      minHeight: '70px',
      paddingRight: '20px',
    },
    caption: {
      '& .MuiPaginationItem-page.Mui-selected': {
        background: 'transparent',
        color: 'black',
      },
      '& .MuiPaginationItem-page': {
        color: styledComponentTheme.colors.baseGreen,
        margin: 0,
      },
      '& .MuiPaginationItem-page.Mui-disabled': {
        color: 'black',
      },
    },
    selects: {
      paddingLeft: 0,
    },

    root: {
      maxHeight: '300px',
      height: '80px',
      padding: '20px',
      width: '820px',
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'space-between',
    },
    skeletonHead: {
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      height: '40px',
      background: '#f6f7f8',
      marginBottom: '10px',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(90deg, #f6f7f8, #edeef1, #f6f7f8)',
        animation: '$progress 1s ease-in-out infinite',
      },
    },

    '@keyframes progress': {
      '0%': {
        transform: 'translate3d(-100%, 0, 0)',
      },
      '100%': {
        transform: 'translate3d(100%, 0, 0)',
      },
    },
  }),
)
