import styled from 'styled-components'
import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ palette, spacing }) => ({
  wrapper: {
    padding: spacing(4),
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
  },
  bodyWrapper: {
    alignSelf: 'flex-start',
    display: 'grid',
    alignItems: 'flex-start',
    gridTemplateColumns: '5fr 1px 2fr',
    gridColumnGap: 10,
  },
  closeButton: {
    top: spacing(2),
    right: spacing(2),
  },
  verticalDivider: {
    width: 1,
    height: 'calc(100% - 40px)',
    alignSelf: 'flex-end',
  },
  button: {
    height: 40,
    borderRadius: 3,
    fontWeight: 'normal',
    paddingLeft: 20,
    paddingRight: 20,
    margin: 0,
  },
  resetButton: {
    border: `1px solid ${palette.grey[200]}`,
    color: palette.grey[200],
  },
  dateInputWrapper: {
    height: 72,
  },
  dateInput: {
    width: '100%',
  },
  paddingLeft: {
    paddingLeft: '8px',
  },
  paddingRight: {
    paddingRight: '8px',
  },
  title: {
    color: '#000',
    fontSize: 20,
    marginBottom: 30,
  },
  modalPlaceholder: {
    fontSize: 14,
    marginBottom: 16,
  },
  subTitle: {
    color: palette.grey[200],
    fontSize: 16,
  },
  objectsTree: {
    height: `calc(100% - 35.75px)`,
    overflow: 'auto',
  },
  objectsTreeSubtitleWrapper: {
    marginBottom: 12,
  },
  objectsTreeWrapper: {
    alignSelf: 'flex-start',
  },
  limitControlsContainer: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    justifyContent: 'flex-start',
    gridColumnGap: 10,
    marginTop: 10,
  },
  footerWrapper: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    justifyContent: 'flex-end',
    gridColumnGap: 10,
    marginTop: 10,
  },
  checkboxWrapper: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gridColumnGap: 10,
    alignItems: 'center',
  },
  checkbox: { padding: 0 },

  tid: {
    display: 'flex',
    alignItems: 'center',
  },

  addButton: {
    width: spacing(6),
    minWidth: 'auto',
    marginLeft: spacing(1),
    height: spacing(5),
  },

  count: {
    position: 'absolute',
    right: '-4px',
    top: '12px',
    width: '10px',
    height: '10px',
    background: palette.primary.main,
    borderRadius: '50%',
  },
}))

export const TitleWithDivider = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 13px;
  align-items: center;
`
