export enum MultipleSnapshotsDeviceSelector {
  GetDevicesRequest = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/DEVICES/GET_DEVICES_REQUEST',
  GetTicketResponse = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/GET_TICKET_RESPONSE',
  GetTicketResponseFail = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/GET_TICKET_RESPONSE_FAIL',
  ChangeSelectedFilters = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/CHANGE_SELECTED_FILTERS',
  GetFiltersTemplatesRequest = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/DEVICES/GET_FILTERS_TEMPLATES_REQUEST',
  SetFilterTemplatesResponse = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/DEVICES/SET_FILTER_TEMPLATE_RESPONSE',
  CreateFilterTemplateRequest = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/DEVICES/CREATE_FILTER_TEMPLATE_REQUEST',
  CreateSdFilterTemplateResponse = '@/CREATE_SD_FILTER_TEMPLATE_RESPONSE',
  UpdateFilterTemplateRequest = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/DEVICES/UPDATE_FILTER_TEMPLATE_REQUEST',
  CollectFilterTemplateFields = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/COLLECT_FILTER_TEMPLATE_FIELDS',
  UpdateSdFilterTemplateResponse = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/UPDATE_SD_FILTER_TEMPLATE_RESPONSE',
  DeleteFilterTemplateRequest = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/DEVICES/DELETE_FILTER_TEMPLATE_REQUEST',
  GetGroupsTemplatesRequest = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/DEVICES/GET_GROUPS_TEMPLATE_REQUEST',
  EditGroupTemplateRequest = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/DEVICES/EDIT_GROUP_TEMPLATE_REQUEST',
  DeleteGroupTemplateRequest = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/DEVICES/DELETE_GROUP_TEMPLATE_REQUEST',
  SaveGroupingTemplateRequest = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/DEVICES/SAVE_GROUPING_TEMPLATE_REQUEST',
  SetCurrentFilterTemplate = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/SET_CURRENT_FITLER_TEMPLATE',
  GetDevicesConfigRequest = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/DEVICES/GET_DEVICES_CONFIG_REQUEST',
  GetDevicesConfigResponse = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/DEVICES/GET_DEVICES_CONFIG_RESPONSE',
  GetTicketsConfigResponseFail = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/GET_TICKETS_CONFIG_RESPONSE_FAIL',
  ChangeGrouping = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/CHANGE_GROUPING',
  SetDataPagination = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/DEVICES/SET_DATA_PAGINATION',
  GetTicketDetailsRequest = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/GET_TICKET_DETAILS_REQUEST',
  SetPage = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/SET_PAGE',
  SetSelectedGroupingTemplate = '@/MULTIPLE_SNAPSHOTS_DEVICE_SELECTOR/SET_SELECTED_GROUPING_TEMPLATE',
}
