import { Checkbox } from '@material-ui/core'
import clsx from 'clsx'
import React, { FC, MouseEvent, useCallback } from 'react'

import { useClasses } from './styles'
import { Props } from './types'

export const TableCheckbox: FC<Props> = React.memo((props: Props) => {
  const { disabled, checked, center = false, onClick, ...rest } = props

  const classes = useClasses()

  const handleCheckbox = useCallback(
    (event: MouseEvent<HTMLButtonElement>): void => {
      if (!disabled) {
        onClick && onClick(event)
      }
    },
    [disabled, onClick],
  )

  return (
    <Checkbox
      className={clsx(classes.checkbox, { [classes.disabled]: disabled, [classes.center]: center })}
      checked={checked}
      onClick={handleCheckbox}
      disabled={disabled}
      {...rest}
    />
  )
})
