import { AnyAction } from 'redux'

import { AdmCashCassetteType } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants/actions'

export type State = {
  data: AdmCashCassetteType[]

  selected: AdmCashCassetteType | null

  isLoading: boolean
  isLoaded: boolean

  isDeleting: boolean
  error: string | null

  sortColumn?: string
  sortOrder?: 'asc' | 'desc'
}

export const initialState: State = {
  data: [],
  selected: null,

  isLoading: false,
  isLoaded: false,

  isDeleting: false,

  error: null,

  sortColumn: undefined,
  sortOrder: undefined,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    // get
    case CMAppConfigActions.AdmUpdateCassetteTypeRequest:
    case CMAppConfigActions.AdmGetCassetteTypesRequest:
    case CMAppConfigActions.AdmAddCassetteTypeRequest:
      return {
        ...state,
        isLoading: true,
      }
    case CMAppConfigActions.AdmGetCassetteTypesResponse:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: payload,
      }
    case CMAppConfigActions.AdmUpdateCassetteTypeFail:
    case CMAppConfigActions.AdmGetCassetteTypesFail:
    case CMAppConfigActions.AdmAddCassetteTypeFail:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: payload,
      }

    // sort
    case CMAppConfigActions.AdmSortCassetteTypes:
      return {
        ...state,

        isLoading: true,
        ...payload,
      }

    // selected
    case CMAppConfigActions.AdmSetSelectedCassetteType:
      return {
        ...state,
        selected: payload,
      }

    // delete
    case CMAppConfigActions.AdmDeleteCassetteTypeRequest:
      return {
        ...state,
        isDeleting: true,
      }
    case CMAppConfigActions.AdmDeleteCassetteTypeResponse:
      return {
        ...state,
        isDeleting: false,
        isLoading: true,
      }
    case CMAppConfigActions.AdmDeleteCassetteTypeFail:
      return {
        ...state,
        isDeleting: false,
        error: payload,
      }
    default:
      return state
  }
}
