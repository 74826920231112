import { Checkbox } from '@material-ui/core'
import moment from 'moment'
import React, { ChangeEvent, ReactElement, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CellLink } from '@/components/blocks/cm'
import { ConditionallyCell, ConditionallyCellResult } from '@/components/blocks/cm//CMTable/types'
import { getApprovalItem, updateLocalApproveItems } from '@/store/cm/common'

import { useClasses } from './styles'
import { TableColumn } from './tableConfig'
import { AdmApprovalItemWithCheckbox, RenderCellProps } from './types'

const RowCheckbox = React.memo(
  ({
    checked,
    row,
    cellKey,
  }: {
    checked: boolean
    row: AdmApprovalItemWithCheckbox
    cellKey: string
  }): ReactElement => {
    const classes = useClasses()
    const dispatch = useDispatch()

    const { data } = useSelector(getApprovalItem)

    const handleCheckbox = useCallback(
      (row: AdmApprovalItemWithCheckbox, key: string) => (
        event: ChangeEvent<HTMLInputElement>,
        localChecked: boolean,
      ): void => {
        const newApproveRejectData = data.map(localRow => {
          if (localRow.entryId === row.entryId) {
            const approve = key === TableColumn.Approve ? localChecked : false
            const reject = key === TableColumn.Reject ? localChecked : false

            return {
              ...localRow,
              approve: approve,
              reject: reject,
            }
          }

          return localRow
        })

        dispatch(updateLocalApproveItems(newApproveRejectData))
      },
      [data],
    )

    return (
      <Checkbox
        checked={checked}
        className={classes.checkbox}
        onChange={handleCheckbox(row, cellKey)}
      />
    )
  },
)

export const conditionallyRenderCell = ({ handleOpenDetails }: RenderCellProps) => ({
  key,
  value,
  row,
}: ConditionallyCell<AdmApprovalItemWithCheckbox>): ConditionallyCellResult => {
  switch (key) {
    case TableColumn.Date:
      return moment(value).format('LLL')

    case TableColumn.Field:
      return value

    case TableColumn.Object:
      return value ? (
        value.props ? (
          <CellLink value={value.name} onClick={handleOpenDetails(row)} />
        ) : (
          value.name
        )
      ) : null

    case TableColumn.Approve:
    case TableColumn.Reject:
      return <RowCheckbox checked={value || false} row={row} cellKey={key} />

    default:
      return value
  }
}
