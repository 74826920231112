import clsx from 'clsx'
import { Field as FormikField } from 'formik'
import React, { ChangeEvent, FC, useCallback, useMemo } from 'react'

import { createCheckboxField } from '@/components/controls/Checkbox'
import { createRadioGroupFormikField } from '@/components/controls/RadioGroup/component'
import { createTextInputField } from '@/components/controls/TextInput/component'
import { numberExp } from '@/constants'
import { DynamicFieldType } from '@/constants/cm/dynamicFieldTypes'

import { useClasses } from './styles'
import { Props } from './types'

export const DynamicField: FC<Props> = React.memo((props: Props) => {
  const { field, setFieldValue, currentValue, disabled } = props
  const { id, name, valueType, maxValue, minValue, indent, step, groupInfo } = field

  const classes = useClasses({ indent: indent })

  const parsedFieldKey = useMemo(() => `${id}` || '', [id])

  const handleTimeFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (setFieldValue && parsedFieldKey) {
        setFieldValue(parsedFieldKey, event.target.value)
      }
    },
    [parsedFieldKey, setFieldValue],
  )

  const Field = useMemo(() => {
    switch (valueType) {
      case DynamicFieldType.Boolean:
        return createCheckboxField({
          value: JSON.parse(currentValue),
          label: name,
          classes: { root: classes.checkbox },
        })

      case DynamicFieldType.Integer:
        return createTextInputField({
          label: name,
          type: 'number',
          className: clsx(classes.field, classes.indent),
          forbiddenCharactersRegExp: numberExp,
          inputProps: { min: minValue, max: maxValue, step: step },
          onChange: handleTimeFieldChange,
          setFieldValue: undefined,
          displayError: false,
          disabled,
        })

      case DynamicFieldType.Radio:
        return createRadioGroupFormikField({
          label: name,
          options:
            (groupInfo || []).map(({ id, name }) => ({
              value: id?.toString() || '',
              label: name,
            })) || [],
          className: classes.radioWrapper,
          classPadding: classes.radioItem,
          styles: {
            wrapper: clsx(classes.indent, classes.radioWrapperPadding),
          },

          disabled,
        })

      default:
        return null
    }
  }, [name, classes, step, minValue, maxValue, currentValue, groupInfo, disabled])

  return <FormikField name={parsedFieldKey}>{Field}</FormikField>
})
