import React from 'react'

import { MapMarkerATMeye } from '@/components/pages/atmeye/Devices/Components/Markers/MapMarkerATMeye'
import { SingleMarker } from '@/components/pages/atmeye/Devices/Components/Markers/SingleMarker'

import { Props } from './types'

export const Marker = ({
  isCanOpenModalMarker,
  pointInfo,
  handleSetCurrentDeviceId,
  onMouseOver,
}: Props): React.ReactElement => {
  const {
    deviceId,
    deviceName,
    deviceModel,
    country,
    technicalEventCount,
    alarmCount,
    city,
    address,
    deviceType,
    transactionsCount,
  } = pointInfo

  return (
    <MapMarkerATMeye
      text="1"
      clusterMarkerInfo={[pointInfo]}
      onMouseOver={onMouseOver}
      markerDetails={
        isCanOpenModalMarker ? (
          <SingleMarker
            deviceType={deviceType}
            country={country}
            deviceId={deviceId}
            technicalEventCount={technicalEventCount}
            alarmCount={alarmCount}
            address={address}
            deviceModel={deviceModel}
            city={city}
            deviceName={deviceName}
            handleSetCurrentDeviceId={handleSetCurrentDeviceId}
            transactionsCount={transactionsCount}
          />
        ) : null
      }
    />
  )
}
