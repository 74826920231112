import {
  ShrCassetesInfo,
  ShrDeclineNote,
  ShrDeclineRequest,
  ShrModCassetes,
  ShrPriorityRequest,
  ShrSettingsResponse,
} from '@/api/cm/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class SharedApi {
  private static requestService = getRequestServiceCM()

  public static async getDeclineNote(params: {
    cashPointId: string
    sessionId: string
  }): Promise<ShrDeclineNote> {
    try {
      return await SharedApi.requestService.get('/shared/declineNote', params)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setPriority(requestBody: ShrPriorityRequest): Promise<void> {
    try {
      return await SharedApi.requestService.post('/shared/setPriority', requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async declineOrder(payload: ShrDeclineRequest): Promise<void> {
    try {
      return await SharedApi.requestService.post('/shared/decline', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCassettesProps(guid: string): Promise<ShrCassetesInfo> {
    try {
      return await SharedApi.requestService.get('/shared/cassetesProps', { guid })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getSettings(): Promise<ShrSettingsResponse> {
    try {
      return await SharedApi.requestService.get('/shared/settings')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setCassettesProps(
    guid: string,
    requestBody: ShrModCassetes,
  ): Promise<ShrCassetesInfo> {
    try {
      return await SharedApi.requestService.post(
        `/shared/setCassetesProps?guid=${guid}`,
        requestBody,
      )
    } catch (e) {
      throw new Error(e)
    }
  }
}
