import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { shallowEqual, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Command } from '@/api/rsocket/command'
import CloseButton from '@/components/blocks/CloseButton'
import Loader from '@/components/blocks/Loader'
import { SocketContext } from '@/context'
import { getSocketData } from '@/store/dashboard'
import { useTranslate } from '@/utils/internalization'
import { PopUpService } from '@/utils/services/popUpService'

import { ContentWrapper, useClasses } from './styles'
import { Props } from './types'

const ConfirmModal = ({ open, handleClose, selectedFolders }: Props): React.ReactElement => {
  const classes = useClasses()
  const { id } = useParams()
  const { _service, connected } = useContext(SocketContext)
  const data: any = useSelector(getSocketData(Command.CONTROL_DELETE_DIR), shallowEqual)
  const translate = useTranslate()
  const [isLoading, setIsloading] = useState(false)

  useEffect(() => {
    if (open) {
      if (data) {
        handleClose()
        if (data.status === 'OK') {
          PopUpService.showGlobalPopUp('translate#title.deleteSuccess', 'success')
        } else {
          PopUpService.showGlobalPopUp('translate#title.deleteError', 'error')
        }
        setIsloading(false)
      }
    }
  }, [connected, data, handleClose, open])

  const handleDelete = useCallback((): void => {
    setIsloading(true)

    _service.send(
      {
        folders: selectedFolders,
      },
      id,
      Command.CONTROL_DELETE_DIR,
    )
  }, [_service, id, selectedFolders])

  return (
    <>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        keepMounted
        onClose={handleClose}
        disableBackdropClick
      >
        <ContentWrapper>
          <CloseButton absolute className={classes.closeButton} onClick={handleClose} />
          <div className={classes.deviceTitle}>
            <FormattedMessage id="title.confirm" defaultMessage="Confirm" />
          </div>
          <div className={classes.deviceSubTitle}>
            <FormattedMessage
              id="title.doYouReallyWantToDeleteSelectedFolders"
              defaultMessage="Do you really want to delete selected folders?"
            />
          </div>
          {!isLoading ? (
            <Grid container justify="flex-end">
              <Button
                onClick={handleClose}
                className={classes.cancelButton}
                color="primary"
                variant="contained"
              >
                <FormattedMessage id="action.no" defaultMessage="No" />
              </Button>
              <Button
                onClick={handleDelete}
                className={classes.button}
                color="primary"
                variant="contained"
              >
                <FormattedMessage id="action.yes" defaultMessage="Yes" />
              </Button>
            </Grid>
          ) : (
            <Grid container justify="center">
              {' '}
              <Loader className={classes.loader} />{' '}
            </Grid>
          )}
        </ContentWrapper>
      </Dialog>
    </>
  )
}

export default ConfirmModal
