import React, { ReactElement, useState, useMemo, useEffect } from 'react'
import { Field as FormikField } from 'formik'
import { createSelectField } from '@/components/controls/Select'
import { Props } from './types'
import { useDidUpdateEffect } from '@/utils/hooks/hooks'
import { createRadioGroupFormikField } from '@/components/controls/RadioGroup/component'

const LookupsRadio = ({
  fieldName,
  fieldTitle,
  field,
  isDisabled,
  onCall,
  onSelect,
  setFormikFieldValue,
  defaultValue,
  isDependsOn,
  dependsOn = [],
  className,
}: Props): ReactElement => {
  const initialValue =
    defaultValue && defaultValue.name && defaultValue.value
      ? {
          name: String(defaultValue.name),
          value: String(defaultValue.value),
        }
      : null

  const [dataIsLoading, setDataIsLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<{ item: any; value: string; name: string }[]>([])

  const getOptions = async (fragment: string): Promise<any> => {
    try {
      setDataIsLoading(true)

      const options: { item: any; value: string; name: string }[] = await onCall(fragment)

      setOptions(options)

      setDataIsLoading(false)
    } catch (error) {
      throw new Error(error)
    }
  }

  useEffect(() => {
    getOptions('')
  }, [])

  useEffect(() => {
    if (!options.find(item => String(item.value) === initialValue?.value)) setOptions([])
  }, [defaultValue?.value])

  const Field = useMemo(() => {
    return createRadioGroupFormikField({
      options: options.length
        ? options.map(({ name, value }) => ({
            label: name,
            value,
          }))
        : initialValue
        ? [initialValue].map(({ name, value }) => ({
            label: name,
            value,
          }))
        : [{ label: ' ', value: '' }],
      defaultValue: '0',
      label: field.options?.label,
      isRequired: field.options?.required,
      className: className,
    })
  }, [...dependsOn, defaultValue, initialValue, options, dataIsLoading, isDisabled])

  return <FormikField name={fieldName}>{Field}</FormikField>
}

export default LookupsRadio
