import React, { ReactElement } from 'react'
import clsx from 'clsx'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
// import AddBoxIcon from '@material-ui/icons/AddBox'

import { useClasses } from './styles'
import { Props } from './types'
import { useTranslate } from '@/utils/internalization'

const TableHeader = ({ headers, collapsible, onCollapseAll }: Props): ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  return (
    <TableHead>
      <TableRow className={classes.row}>
        {collapsible && (
          <TableCell key="collapse" className={clsx(classes.column, classes.collapsibleColumn)}>
            {onCollapseAll && (
              <IconButton
                className={classes.collapseAllButton}
                disableRipple
                onClick={onCollapseAll}
              />
            )}
          </TableCell>
        )}
        {headers.map(({ name, label }) => (
          <TableCell key={name} className={classes.column}>
            {label && (
              <Typography variant="body2" className={classes.title}>
                {translate(`translate#title.${label}`)}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default TableHeader
