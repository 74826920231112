import { AnyAction } from 'redux'

import { AnlChartsResponse, ComCMReport } from '@/api/cm/swaggerGeneratedApi'
import { FormFieldValues } from '@/components/pages/cm/AnalysisPage/components/modals/FiltersModal/types'
import { CMActions } from '@/constants/actions/cmActions'
import { ChartsGroupType, FilterFormProps } from '@/types/cm/analysis'

export const setAnalysisFiltersModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.AnalysisSetFiltersModalOpen,
  payload: isOpen,
})

export const setAnalysisDocumentModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.AnalysisSetDocumentModalOpen,
  payload: isOpen,
})

export const filterSubmitRequest = (payload: FilterFormProps): AnyAction => ({
  type: CMActions.AnalysisFilterSubmitRequest,
  payload,
})

export const setAnalysisFilter = (payload: Partial<FormFieldValues>): AnyAction => ({
  type: CMActions.AnalysisSetFilter,
  payload,
})

export const setSelectedChartsType = (payload: ChartsGroupType): AnyAction => ({
  type: CMActions.AnalysisSetSelectedChartsType,
  payload,
})

export const getAnalysisChartsRequest = (): AnyAction => ({
  type: CMActions.AnalysisGetChartsRequest,
})

export const getAnalysisChartsResponse = (payload: AnlChartsResponse): AnyAction => ({
  type: CMActions.AnalysisGetChartsResponse,
  payload,
})

export const getAnalysisReportsRequest = (): AnyAction => ({
  type: CMActions.AnalysisGetReportsRequest,
})

export const getAnalysisReportsResponse = (payload: any): AnyAction => ({
  type: CMActions.AnalysisGetReportsResponse,
  payload,
})

export const getAnalysisReportsResponseFail = (message?: string): AnyAction => ({
  type: CMActions.AnalysisGetReportsResponseFail,
  payload: message,
})

export const getAnalysisReportRequest = (payload?: ComCMReport): AnyAction => ({
  type: CMActions.AnalysisGetReportRequest,
  payload,
})

export const getAnalysisReportResponse = (payload: any | null): AnyAction => ({
  type: CMActions.AnalysisGetReportResponse,
  payload,
})

export const getAnalysisReportResponseFail = (message?: string): AnyAction => ({
  type: CMActions.AnalysisGetReportResponseFail,
  payload: message,
})
