import {
  CreateNewTicketFormFields,
  CreateNewTicketFormValues,
} from '@/constants/forms/CreateNewTicketForm'
import { ValidationErrors } from '@/constants/forms/validationErrors'
import { ValidationService } from '@/utils/services/validationService'

export const validate = (values: CreateNewTicketFormValues): Partial<CreateNewTicketFormValues> => {
  const errors: Partial<CreateNewTicketFormValues> = {}
  if (!ValidationService.isRequired(values[CreateNewTicketFormFields.ServiceCompanyId])) {
    errors[CreateNewTicketFormFields.ServiceCompanyId] = ValidationErrors.Required
  }
  if (!ValidationService.isRequired(values[CreateNewTicketFormFields.UnitId])) {
    errors[CreateNewTicketFormFields.UnitId] = ValidationErrors.Required
  }
  if (ValidationService.isMoreLengthEditNotes(values[CreateNewTicketFormFields.Notes])) {
    errors[CreateNewTicketFormFields.Notes] = ValidationErrors.MaxLength2048symbolsOfEDitNotes
  }
  if (ValidationService.isSpecialSymbols(values[CreateNewTicketFormFields.UnitId])) {
    errors[CreateNewTicketFormFields.UnitId] = ValidationErrors.SpecialSymbols
  }
  if (ValidationService.isMoreThan50Symbols(values[CreateNewTicketFormFields.RequestNumber])) {
    errors[CreateNewTicketFormFields.RequestNumber] =
      ValidationErrors.TheFieldCannotExceed50Characters
  }

  return errors
}
