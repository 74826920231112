import { IconButton } from '@material-ui/core'
import { KeyboardArrowRight, KeyboardArrowUp } from '@material-ui/icons'
import clsx from 'clsx'
import React, { FC, useMemo } from 'react'

import { CMTableCell, useCommonClasses } from '../../../commonStyles'
import { useClasses } from './styles'
import { Props } from './types'

export const CollapseRowCell: FC<Props> = React.memo((props: Props) => {
  const { isFixed, isRenderCollapse, isCollapseOpen, cellClasses, handleToggleOpen } = props

  const classes = useClasses()
  const commonClasses = useCommonClasses()

  const className = useMemo(
    () => clsx(cellClasses, classes.collapseIcon, { [commonClasses.fixed]: isFixed }),
    [classes, commonClasses, cellClasses, isFixed],
  )

  return isRenderCollapse ? (
    <CMTableCell className={clsx(className)}>
      <IconButton aria-label="expand row" size="small" onClick={handleToggleOpen}>
        {isCollapseOpen ? (
          <KeyboardArrowUp className={commonClasses.icon} />
        ) : (
          <KeyboardArrowRight className={commonClasses.icon} />
        )}
      </IconButton>
    </CMTableCell>
  ) : (
    <CMTableCell className={clsx(className)} />
  )
})
