import { find, isEmpty, isEqual } from 'lodash-es'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Filter } from '@/api/atmeye/blackListApi/types'
import { TicketsApi } from '@/api/sd/ticketsApi'
import { TypesForSelect } from '@/components/controls/Select/types'
import { ValueTypes } from '@/components/pages/atmeye/Administration/LicenseList/ATMCoreLicenses/components/FiltersModalLicence/GenerateFormFields/types'
import { createDateRange } from '@/utils'
import { useTranslate } from '@/utils/internalization'
import { isFiltersSelected } from '@/utils/isFiltersEmpty'

type useExportTableTypes = {
  selectedFilters: Filter | any
  config?: any
}

export type FilterView = { filterLabel: string; filterValue: string; key: string; valueType: any }

const useFilterView = ({
  selectedFilters,
  config,
}: useExportTableTypes): { filterView: FilterView[] } => {
  const translate = useTranslate()

  const [defaultFieldsValue, setDefaultFieldsValue] = useState<{ [key: string]: string }>({})
  const isHasFilters = useMemo(() => {
    return isFiltersSelected(selectedFilters)
  }, [selectedFilters])

  const loadFilterModalData = useCallback(
    async (value: Array<any>, entityType: string) => {
      try {
        const payload = [
          {
            entityType: entityType,
            pageNumber: 1,
            pageSize: 1,
            searchFragment: '',
            selectedIds: value.map((value: any) => Number(value?.valueId)),
          },
        ]

        const fieldsData = await TicketsApi.getDropdownListsData(payload)
        setDefaultFieldsValue(prevState => ({
          ...prevState,
          [entityType]: fieldsData[0].dropdownListElements
            .filter(el => el.isSelected)
            .map(el => el.valueLabels)
            .join(', '),
        }))
      } catch (error) {
        console.log(error)
      }
    },
    [defaultFieldsValue],
  )

  useEffect(() => {
    Object.keys(selectedFilters).forEach((filter: any) => {
      const filterConfig = find(config, { fieldName: filter })
      const currentFilter = selectedFilters[filter]

      if (
        Array.isArray(currentFilter) &&
        !!currentFilter.length &&
        filterConfig?.options?.multiselect?.dropdownListEntityType
      ) {
        loadFilterModalData(
          selectedFilters[filter],
          filterConfig?.options?.multiselect?.dropdownListEntityType,
        )
      }
    })
  }, [selectedFilters, translate])

  const renderMultiSelectFilters = (
    multiSelectValues: Array<any>,
    key: string,
    options?: any,
  ): string => {
    return defaultFieldsValue[options?.multiselect?.dropdownListEntityType] || ''
  }

  const getTitle = ({
    value,
    valueType,
    key,
    options,
  }: {
    value: any
    key: string
    valueType: any
    options?: any
  }): string => {
    if (valueType === ValueTypes.Select) {
      const title =
        options?.optionsSelect?.find(({ value: val }: TypesForSelect) => val === value)?.name ||
        'atmeye.activeFilter.unknownOption'

      return translate(`translate#${title}`)
    }

    if (!Array.isArray(value)) {
      return value
    }

    if (valueType === ValueTypes.Date || valueType === ValueTypes.ZonedDate) {
      return createDateRange(value, 'DD.MM.YYYY', translate)
    }

    if (valueType === ValueTypes.Datetime) {
      return createDateRange(value, 'DD.MM.YYYY HH:mm', translate)
    }

    return renderMultiSelectFilters(value, key, options)
  }

  const renderItem = (value: any, key: string, valueType: any, options?: any) => {
    if (valueType === ValueTypes.Integer) {
      return value
    }

    const title = getTitle({
      value,
      valueType,
      key,
      options,
    })

    return title
  }

  const filterView = isHasFilters
    ? Object.entries(selectedFilters).reduce((acc, [key, value]) => {
        if (!isEmpty(value) && !isEqual(value, [null, null])) {
          const filterConfig = find(config, { fieldName: key })
          const valueType = filterConfig?.valueType

          acc.push({
            key,
            valueType,
            filterLabel: filterConfig?.label,
            filterValue: renderItem(value, key, valueType, filterConfig?.options),
          })
        }

        return acc
      }, [] as FilterView[])
    : []

  return { filterView }
}

export { useFilterView }
