import queryString from 'query-string'

import {
  ComVaultReport,
  Currency,
  EventJournalBagDetails,
  EventJournalCashDeskDetails,
  EventJournalCassetteDetails,
  EventJournalEntryStatuses,
  EventType,
  JournalEventCurrencyEntry,
  JournalEventDetailsResponse,
  JournalEventsRequest,
  JournalEventsResponse,
  JournalEventValuableEntry,
  SelectorItem,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

import { GetEventsProps } from './types'

export class EventJournalApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getAll({
    paging,
    body,
    sort,
  }: GetEventsProps): Promise<JournalEventsResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/eventJournal/events',
          query: {
            pageSize: `${paging.pageSize}`,
            page: `${paging.page}`,
            ...sort,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await EventJournalApi.requestService.post(url, body)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCurrencies(id: number): Promise<JournalEventCurrencyEntry[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/eventJournal/eventCurrencies',
          query: { id },
        },
        { skipNull: true, encode: false },
      )
      return await EventJournalApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getDenominations(id: number): Promise<JournalEventDetailsResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/eventJournal/details',
          query: { id },
        },
        { skipNull: true, encode: false },
      )
      return await EventJournalApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getValuables(id: number): Promise<JournalEventValuableEntry[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/eventJournal/eventValuables',
          query: { id },
        },
        { skipNull: true, encode: false },
      )
      return await EventJournalApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getBags(id: number): Promise<EventJournalBagDetails> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/eventJournal/bagDetails',
          query: { id },
        },
        { skipNull: true, encode: false },
      )
      return await EventJournalApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCassettes(id: number): Promise<EventJournalCassetteDetails> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/eventJournal/cassetteDetails',
          query: { id },
        },
        { skipNull: true, encode: false },
      )
      return await EventJournalApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getReports(payload: JournalEventsRequest): Promise<ComVaultReport[]> {
    try {
      return await EventJournalApi.requestService.post('/eventJournal/reports', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  // Filter Options

  public static async getFilterCurrencies(): Promise<Currency[]> {
    try {
      return await EventJournalApi.requestService.get('/eventJournal/currencies')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getFilterEventTypes(): Promise<EventType[]> {
    try {
      return await EventJournalApi.requestService.get('/eventJournal/eventTypes')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getFilterExecutors(): Promise<SelectorItem[]> {
    try {
      return await EventJournalApi.requestService.get('/eventJournal/executors')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async revertTransaction(eventId: number): Promise<void> {
    try {
      return await EventJournalApi.requestService.post('/eventJournal/revertTransaction', eventId)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getEntryStatuses(entryId: number): Promise<EventJournalEntryStatuses> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/eventJournal/entryStatuses',
          query: { entryId },
        },
        { skipNull: true, encode: false },
      )

      return await EventJournalApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashDeskDetails(id: number): Promise<EventJournalCashDeskDetails> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/eventJournal/cashDeskDetails',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await EventJournalApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }
}
