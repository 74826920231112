import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { AppConfigAdminApi } from '@/api/vault-v1/adminApi'
import { VaultV1Actions } from '@/constants'

import {
  getAdmAppConfigFail,
  getAdmAppConfigResponse,
  getAdmLogoFail,
  getAdmLogoResponse,
  setAdmAppConfigFail,
  setAdmAppConfigResponse,
} from '.'

// [START] AppConfig --------------------------------------
function* getAppConfig(): SagaIterator {
  try {
    const appSettings = yield call(AppConfigAdminApi.get)

    yield put(getAdmAppConfigResponse(appSettings))
  } catch (error) {
    yield put(getAdmAppConfigFail())
  }
}

function* setAppConfig({ payload }: AnyAction): SagaIterator {
  try {
    yield call(AppConfigAdminApi.set, payload)

    yield put(setAdmAppConfigResponse())
  } catch (error) {
    yield put(setAdmAppConfigFail())
  }
}

// Logo
function* getLogo(): SagaIterator {
  try {
    const image = yield call(AppConfigAdminApi.getLogo)

    yield put(getAdmLogoResponse(image))
  } catch (error) {
    yield put(getAdmLogoFail())
  }
}
// [END] AppConfig --------------------------------------

export default function*(): Generator {
  yield takeLatest(VaultV1Actions.AdmGetAppConfigRequest, getAppConfig)
  yield takeLatest(VaultV1Actions.AdmSetAppConfigRequest, setAppConfig)

  yield takeLatest(VaultV1Actions.AdmGetLogoRequest, getLogo)
}
