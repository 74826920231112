import { SagaIterator } from '@redux-saga/core'
import { call, put } from '@redux-saga/core/effects'
import { ActionCreator, AnyAction } from 'redux'
import { takeEvery, takeLatest } from 'redux-saga/effects'

import { NotificationApi } from '@/api/atmeye/notification'
import { Notifications } from '@/constants/actions/settings'
import { CloseNotification, Notification } from '@/store/notifications/types'
import { ResponseErrorTypes } from '@/types/common/ErrorResponseTypes'
import { ReturnAction } from '@/types/usm/userGroup/userGroup'

import { actionsNotifications } from './actions'

type OptionsType<T> = {
  fetcher: (...arg: any) => Promise<T | ResponseErrorTypes>
  startFetching: ActionCreator<AnyAction>
  stopFetching: ActionCreator<AnyAction>
  onSuccess: ActionCreator<AnyAction>
}

function* makePutRequest<T>(options: OptionsType<T>): SagaIterator {
  const { fetcher, startFetching, stopFetching, onSuccess } = options
  try {
    yield put(startFetching())
    const result = yield call(fetcher)

    if (result.status === 204 || result.status === 200 || result === true) {
      yield put(onSuccess())
    }
  } catch (e) {
    console.log(e)
  } finally {
    yield put(stopFetching())
  }
}

function* closeSecurityAlarm({ payload }: ReturnAction<CloseNotification, never>): Generator {
  const fetcher = new NotificationApi<CloseNotification>(payload)
  const options: OptionsType<CloseNotification> = {
    fetcher: fetcher.closeSecurityAlarm.bind(fetcher),
    startFetching: actionsNotifications.blockAlarms.bind(undefined, payload),
    stopFetching: actionsNotifications.unblockAlarms.bind(undefined, payload),
    onSuccess: actionsNotifications.deleteAlarms.bind(undefined, payload),
  }

  yield makePutRequest<CloseNotification>(options)
}

function* closeTechnicalEvent({ payload }: ReturnAction<CloseNotification, never>): Generator {
  const fetcher = new NotificationApi<CloseNotification>(payload)

  const options: OptionsType<number> = {
    fetcher: fetcher.closeTechnicalEvent.bind(fetcher),
    startFetching: actionsNotifications.blockEvents.bind(undefined, payload),
    stopFetching: actionsNotifications.unblockEvents.bind(undefined, payload),
    onSuccess: actionsNotifications.deleteEvents.bind(undefined, payload),
  }

  yield makePutRequest<number>(options)
}

function* setNotification({ payload }: ReturnAction<Notification, never>): Generator {
  yield put(actionsNotifications.enqueueSnackbar(payload))
}

export default function*(): Generator {
  yield takeEvery(Notifications.closeSecurityAlarmAsync, closeSecurityAlarm)
  yield takeEvery(Notifications.closeTechnicalEventAsync, closeTechnicalEvent)
  yield takeLatest(Notifications.setATMeyeNotification, setNotification)
}
