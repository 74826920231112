import { AnyAction } from 'redux'

import { Account } from '@/api/common/authorizationApi/types'
import {
  AuthorizationActions,
  CMActions,
  PermissionsResponseType,
  UserPermissionsResponseType,
  VaultV1Actions,
} from '@/constants'
import { patchForProducts } from '@/constants/auth'
import { LocalStorageItems } from '@/constants/localStorageItems'

export interface State {
  accounts: any
  timezone: string | null
  currentAccount: Account | null
  isLoggingIn: boolean
  isRequestForEntranceDetectionRun: boolean
  entranceMethodIntervalId: undefined | string | number
  permissions: PermissionsResponseType
  // todo this is a duplicate functionality
  isPermissionsLoading: boolean
  isUserPermissionsLoading: boolean
  // =======================
  userPermissions: UserPermissionsResponseType
  // todo getting photo can to make while process auth
  isUserPhotoLoading: boolean
  userPhoto: string | null

  currentProducts: string
  availableProducts: Array<string>
  isInitialisingAuthProcess: boolean
  error: string | null

  changePasswordState: {
    isModalOpen: boolean
    isLoading: boolean
    error: string | null
  }
}

// todo why keeping it in browser storage ?
const userPermissions = sessionStorage.getItem(LocalStorageItems.UserPermissions)
const rolePermissions = sessionStorage.getItem(LocalStorageItems.RolePermissions)
const userPhoto = sessionStorage.getItem(LocalStorageItems.UserPhoto)

const initialState: State = {
  accounts: {},
  timezone: sessionStorage.getItem(LocalStorageItems.Timezone) || null,
  currentAccount: null,
  isLoggingIn: false,
  isRequestForEntranceDetectionRun: false,
  entranceMethodIntervalId: undefined,
  permissions: {},
  isPermissionsLoading: true,

  userPermissions:
    userPermissions != null ? JSON.parse(userPermissions) : ({} as UserPermissionsResponseType),
  isUserPermissionsLoading: true,

  isUserPhotoLoading: true,
  userPhoto: userPhoto,

  currentProducts: '',
  availableProducts: [],
  isInitialisingAuthProcess: false,
  error: null,

  changePasswordState: {
    isModalOpen: false,
    isLoading: false,
    error: null,
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case AuthorizationActions.StartIsInitialisationAuthProcess:
      return {
        ...state,
        isInitialisingAuthProcess: true,
      }
    case AuthorizationActions.StopIsInitialisationAuthProcess:
      return {
        ...state,
        isInitialisingAuthProcess: false,
      }
    case AuthorizationActions.SignInRequest:
      return {
        ...state,
        timezone: payload.timezone.match(/\(.+\)/),
        isLoggingIn: true,
        error: null,
      }
    case AuthorizationActions.SignInResponse:
      return {
        ...state,
        isLoggingIn: false,
        accounts: payload.accounts,
      }

    case AuthorizationActions.SetAvailableProducts:
      return {
        ...state,
        availableProducts: payload,
      }

    case AuthorizationActions.SetCurrentProducts: {
      const selectedProduct: any = Object.keys(patchForProducts).find(productName => {
        return patchForProducts[productName].find(product => product.value === payload)
      })
      return {
        ...state,
        currentProducts: payload,
        availableProducts: [],
        accounts: { [selectedProduct]: state.accounts[selectedProduct] },
      }
    }
    case AuthorizationActions.SignInResponseFail:
      return {
        ...state,
        isLoggingIn: false,
        error: payload,
      }
    case AuthorizationActions.PutCurrentAccount: {
      return {
        ...state,
        currentAccount: payload,
      }
    }

    case AuthorizationActions.ChangePasswordRequest:
      return {
        ...state,
        changePasswordState: {
          ...state.changePasswordState,
          isLoading: true,
          error: null,
        },
      }
    case AuthorizationActions.ChangePasswordResponse:
      return {
        ...state,
        changePasswordState: {
          ...state.changePasswordState,
          isModalOpen: false,
          isLoading: false,
          error: null,
        },
      }
    case AuthorizationActions.ChangePasswordResponseFail:
      return {
        ...state,
        changePasswordState: {
          ...state.changePasswordState,
          isLoading: false,
          error: payload,
        },
      }
    case AuthorizationActions.SetChangePasswordModalOpen:
      return {
        ...state,
        changePasswordState: {
          ...initialState.changePasswordState,
          isModalOpen: payload,
        },
      }
    case AuthorizationActions.SignOut:
      return initialState
    case AuthorizationActions.GetAllPermissionsRequest:
      return {
        ...state,
        isPermissionsLoading: true,
      }
    case AuthorizationActions.GetAllPermissionsResponse:
      return {
        ...state,
        permissions: payload,
        isPermissionsLoading: false,
      }
    case AuthorizationActions.GetAllPermissionsResponseFail:
      return {
        ...state,
        error: payload,
        isPermissionsLoading: false,
      }
    case AuthorizationActions.GetAllUserPermissionsRequest:
      return {
        ...state,
        isUserPermissionsLoading: true,
      }
    case AuthorizationActions.GetAllUserPermissionsResponse:
      return {
        ...state,
        userPermissions: { ...payload },
        isUserPermissionsLoading: false,
      }
    case AuthorizationActions.GetAllUserPermissionsResponseFail:
      return {
        ...state,
        error: payload,
        isUserPermissionsLoading: false,
      }
    case AuthorizationActions.GetUserPhotoRequest:
      return {
        ...state,
        isUserPhotoLoading: true,
      }
    case AuthorizationActions.GetUserPhotoResponse:
      return {
        ...state,
        userPhoto: payload,
        isUserPhotoLoading: false,
      }
    case AuthorizationActions.GetUserPhotoResponseFail:
      return {
        ...state,
        error: payload,
        isUserPhotoLoading: false,
      }
    case CMActions.SignInRequest:
    case VaultV1Actions.SignInRequest:
      return {
        ...state,
        isLoggingIn: true,
        error: null,
      }
    case CMActions.SignInResponse:
    case VaultV1Actions.SignInResponse:
      return {
        ...state,
        isLoggingIn: false,
      }
    default:
      return state
  }
}
