import { Tooltip } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import DotIcon from '@material-ui/icons/FiberManualRecord'
import clsx from 'clsx'
import moment from 'moment'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { Device } from '@/api/atmeye/devicesApi/types'
import { Command } from '@/api/rsocket/command'
import Refresh from '@/assets/sd/refresh.svg'
import Loader from '@/components/blocks/Loader'
import { Restricted } from '@/components/NPMPakage/components/wrappers/PermissionProvider/Restricted'
import { Permission } from '@/components/NPMPakage/components/wrappers/types'
import { DATE_TIME_MIN_FORMAT } from '@/constants/time'
import { SocketContext } from '@/context'
import { getSocketData, setSocketItemEmpty } from '@/store/dashboard'
import { convertingFileSize } from '@/utils/convertingFileSize'
import { useTranslate } from '@/utils/internalization'

import PropertiesModal from './PropertiesModal'
import { ContentWrapper, useClasses } from './styles'

type Props = {
  currentDevice: Device | null
}

const PropertiesTab: FC<Props> = ({ currentDevice }: Props): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const dispatch = useDispatch()

  const [showPropertiesModal, setShowPropertiesModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingModal, setIsLoadingModal] = useState(false)

  const { _service, data, error, connected } = useContext(SocketContext)
  const atmProperty: any = useSelector(getSocketData(Command.GET_ATM_PROPERTY), shallowEqual)
  const atmFullProperty: any = useSelector(
    getSocketData(Command.GET_ATM_FULL_PROPERTY),
    shallowEqual,
  )
  const [oredredAtmProperty, setOredredAtmProperty] = useState<any>()

  const getProperties = useCallback(() => {
    if (connected) {
      dispatch(setSocketItemEmpty(Command.GET_ATM_PROPERTY))
      setIsLoading(true)
      _service.send({}, currentDevice?.deviceName, Command.GET_ATM_PROPERTY)
    }
  }, [_service, connected, currentDevice])

  useEffect((): void => {
    getProperties()
  }, [getProperties])

  useEffect(() => {
    if (atmProperty) {
      const {
        connectionStatus,
        grabberDll,
        coreStatus,
        agentVersion,
        coreVersion,
        licenseStatus,
        type,
        technicalEventsCount,
        vendor,
        alarmsEventsCount,
        model,
        lastActivity,
        country,
        lastAlarmEventDate,
        city,
        lastTechnicalEventDate,
        street,
        usedSpace,
        serialNumber,
        freeSpace,
        updaterVersion,
      } = atmProperty

      const ordered = {
        connectionStatus,
        grabberDll,
        coreStatus,
        agentVersion,
        licenseStatus,
        type,
        technicalEventsCount,
        vendor,
        alarmsEventsCount,
        model,
        lastActivity,
        country,
        lastAlarmEventDate,
        city,
        lastTechnicalEventDate,
        street,
        usedSpace,
        serialNumber,
        freeSpace,
        updaterVersion,
        coreVersion,
        // grabberDll,
      }

      setOredredAtmProperty(ordered)
      setIsLoading(false)
    }
  }, [atmProperty])

  useEffect(() => {
    return () => {
      dispatch(setSocketItemEmpty(Command.GET_ATM_PROPERTY))
      dispatch(setSocketItemEmpty(Command.GET_ATM_FULL_PROPERTY))
    }
  }, [dispatch])

  const handleSetProperties = (val: boolean): void => {
    if (connected && val) {
      setIsLoadingModal(true)
      _service.send({}, currentDevice?.deviceName, Command.GET_ATM_FULL_PROPERTY)
    }
    setShowPropertiesModal(val)
  }

  return (
    <>
      <ContentWrapper>
        {oredredAtmProperty && !isLoading ? (
          <div className={classes.deviceHeader}>
            <div className={classes.tableHeader}>
              <Tooltip
                placement="top"
                classes={{ tooltip: classes.toolTip }}
                title={translate('translate#atmeye.table.header.button.refresh')}
              >
                <div>
                  <Button
                    className={classes.tableHeaderButton}
                    onClick={getProperties}
                    startIcon={<img src={Refresh} />}
                  />
                </div>
              </Tooltip>
            </div>
            <div className={classes.columnsWrapper}>
              <Grid item className={classes.itemWrapper}>
                <div className={classes.headerName}>
                  <span>
                    <FormattedMessage id="atmeye.title.name" defaultMessage="Name" />
                  </span>
                </div>
                <div>
                  <span>
                    <FormattedMessage id="title.value" defaultMessage="Value" />
                  </span>
                </div>
              </Grid>
              <Grid item className={classes.itemWrapper}>
                <div className={classes.headerName}>
                  <span>
                    <FormattedMessage id="atmeye.title.name" defaultMessage="Name" />
                  </span>
                </div>
                <div>
                  <span>
                    <FormattedMessage id="title.value" defaultMessage="Value" />
                  </span>
                </div>
              </Grid>
            </div>

            <div className={classes.columnsWrapper}>
              {Object.keys(oredredAtmProperty).map((el: any, index: number) => {
                if (oredredAtmProperty[el] !== null) {
                  if (typeof oredredAtmProperty[el] === 'boolean') {
                    return (
                      <Grid item key={index} className={classes.itemWrapper}>
                        <div className={classes.deviceName}>
                          <span>{translate(`translate#atmeye.properties.${el}`)}</span>
                        </div>
                        <div>
                          <div
                            className={clsx(
                              classes.cellWrapper,
                              oredredAtmProperty[el]
                                ? classes.okFolderWrapper
                                : classes.suspendedFolderWrapper,
                            )}
                          >
                            <DotIcon
                              className={clsx(
                                classes.cellIcon,
                                oredredAtmProperty[el]
                                  ? classes.deadlineGreen
                                  : classes.deadlineRed,
                                classes.iconSize,
                              )}
                            />
                            <span>
                              {oredredAtmProperty[el] ? (
                                <FormattedMessage id="title.ok" defaultMessage="OK" />
                              ) : (
                                <FormattedMessage id="title.off" defaultMessage="Off" />
                              )}
                            </span>
                          </div>
                        </div>
                      </Grid>
                    )
                  } else if (el === 'freeSpace' || el === 'usedSpace') {
                    return (
                      <Grid item key={index} className={classes.itemWrapper}>
                        <div className={classes.deviceName}>
                          <span>{translate(`translate#atmeye.properties.${el}`)}</span>
                        </div>
                        <div>
                          <span>
                            {typeof oredredAtmProperty[el] === 'number'
                              ? convertingFileSize(oredredAtmProperty[el], 2, 1024)
                              : ''}
                          </span>
                        </div>
                      </Grid>
                    )
                  } else if (
                    el === 'lastAlarmEventDate' ||
                    el === 'lastTechnicalEventDate' ||
                    el === 'lastActivity'
                  ) {
                    return (
                      <Grid item key={index} className={classes.itemWrapper}>
                        <div className={classes.deviceName}>
                          <span>{translate(`translate#atmeye.properties.${el}`)}</span>
                        </div>
                        <div>
                          <span>
                            {moment
                              .utc(oredredAtmProperty[el])
                              .local()
                              .format(DATE_TIME_MIN_FORMAT)}
                          </span>
                        </div>
                      </Grid>
                    )
                  } else if (el === 'alarmsEventsCount') {
                    return (
                      <Grid item key={index} className={classes.itemWrapper}>
                        <div className={classes.deviceName}>
                          <span>
                            {translate(`translate#atmeye.properties.${el}`)} (
                            {translate(`translate#atmeye.properties.1h`)})
                          </span>
                        </div>
                        <div>
                          <div
                            className={clsx(
                              classes.cellWrapper,
                              oredredAtmProperty[el] === 0
                                ? classes.okFolderWrapper
                                : classes.suspendedFolderWrapper,
                            )}
                          >
                            <DotIcon
                              className={clsx(
                                classes.cellIcon,
                                oredredAtmProperty[el] === 0
                                  ? classes.deadlineGreen
                                  : classes.deadlineRed,
                                classes.iconSize,
                              )}
                            />
                            <span>{oredredAtmProperty[el]}</span>
                          </div>
                        </div>
                      </Grid>
                    )
                  } else if (el === 'technicalEventsCount') {
                    return (
                      <Grid item key={index} className={classes.itemWrapper}>
                        <div className={classes.deviceName}>
                          <span>
                            {translate(`translate#atmeye.properties.${el}`)} (
                            {translate(`translate#atmeye.properties.1h`)})
                          </span>
                        </div>
                        <div>
                          <div
                            className={clsx(
                              classes.cellWrapper,
                              oredredAtmProperty[el] === 0
                                ? classes.okFolderWrapper
                                : classes.preparedFolderWrapper,
                            )}
                          >
                            <DotIcon
                              className={clsx(
                                classes.cellIcon,
                                oredredAtmProperty[el] === 0
                                  ? classes.deadlineGreen
                                  : classes.preparedIcon,
                                classes.iconSize,
                              )}
                            />
                            <span>{oredredAtmProperty[el]}</span>
                          </div>
                        </div>
                      </Grid>
                    )
                  } else {
                    return (
                      <Grid item key={index} className={classes.itemWrapper}>
                        <div className={classes.deviceName}>
                          <span>{translate(`translate#atmeye.properties.${el}`)}</span>
                        </div>
                        <div>
                          <span>{oredredAtmProperty[el]?.toString()}</span>
                        </div>
                      </Grid>
                    )
                  }
                } else {
                  return (
                    <Grid item key={index} className={classes.itemWrapper}>
                      <div className={classes.deviceName}>
                        <span>{translate(`translate#atmeye.properties.${el}`)}</span>
                      </div>
                      <div>
                        <span>-</span>
                      </div>
                    </Grid>
                  )
                }
              })}
            </div>
          </div>
        ) : (
          <Loader />
        )}

        <Restricted permissionKey={Permission.Get_Full_Status}>
          {!isLoading && (
            <Grid>
              <Grid item md={3}>
                <Button
                  onClick={() => handleSetProperties(true)}
                  className={classes.button}
                  color="primary"
                  variant="contained"
                >
                  {translate('translate#title.getFullStatus')}
                </Button>
              </Grid>
            </Grid>
          )}
        </Restricted>
      </ContentWrapper>
      <PropertiesModal
        isLoading={isLoadingModal}
        setIsLoading={setIsLoadingModal}
        open={showPropertiesModal}
        handleClose={() => handleSetProperties(false)}
        data={atmFullProperty}
      />
    </>
  )
}

export default PropertiesTab
