import { APIConfiguration } from '@/constants'
import { RequestHTTPServiceTypes } from '@/types/common/services'
import { getRequestService } from '@/utils/services/request'

import { ActionLogRequest } from './types'

class ActionLogAPIService {
  private requestService: RequestHTTPServiceTypes
  constructor(httpService: RequestHTTPServiceTypes) {
    this.requestService = httpService
  }

  async logSuccessOperaion(payload: ActionLogRequest) {
    try {
      return await this.requestService.post(
        `/api/v1/atmeye-action-log/success-operation`,
        payload,
        false,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async logErrorOperaion(payload: ActionLogRequest) {
    try {
      return await this.requestService.post(
        `/api/v1/atmeye-action-log/error-operation`,
        payload,
        false,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

export const actionLogApi = new ActionLogAPIService(
  getRequestService(APIConfiguration.API_BASE_URL),
)
