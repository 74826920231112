import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'

import { styledComponentTheme } from '@/theme'

export const useClasses = makeStyles(({ spacing }) => ({
  pageLayout: {
    display: 'flex',
  },

  content: {
    height: '100%',
    border: ' 10px solid #ebebebc9',
    borderLeft: 0,
  },

  collapsedColumn: {
    '& > div:first-child': {
      width: '20px!important',
      overflow: 'hidden',
    },
  },
  objectsTree: {
    overflow: 'auto',
    height: 'calc(100% - 20px)',
  },

  objectTreeWrap: {
    height: '100%',
    background: '#ebebebc9',
    padding: '10px',
    paddingRight: 0,
  },
  withoutObjectTree: {
    borderLeft: '10px solid #ebebebc9',
  },

  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    padding: 0,
    marginLeft: 4,
    marginBottom: 15,
    whiteSpace: 'nowrap',
  },

  objectsTreeWithTitle: {
    padding: '20px 10px',
    background: '#fff',
    height: '100%',
  },

  columnHide: {
    '& > div:first-child': {
      width: '0px!important',
      overflow: 'hidden',
      pointerEvents: 'none',
    },
    '& > .layout-splitter': {
      display: 'none',
    },
  },

  confirmTitle: {
    padding: 0,
    marginBottom: spacing(1),

    '& > h2': {
      fontSize: styledComponentTheme.fontSizes.big,
      lineHeight: '23px',
    },
  },

  confirmBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 0,
    marginTop: '30px',

    '& button': {
      margin: 0,
    },
  },

  confirmWrapper: {
    height: 'auto',
    padding: spacing(4),
  },
}))

interface Props {
  isAppBarVisible: boolean
}

export const PageWrapper = styled.div<Props>`
  width: 100%;
  outline: none;
  height: ${({ isAppBarVisible }): string => (isAppBarVisible ? 'calc(100% - 65px)' : '100%')};

  :focus {
    outline: none;
  }
`
