//
import { useFormik } from 'formik'
// actions
import { FormikHelpers } from 'formik/dist/types'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { validate } from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/AddUserGroupModal/helpers/validate/validate'
import {
  AddNewUserGroupCardField,
  AddUserGroupCardValues,
} from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/AddUserGroupModal/types'
import { getGlobalErrorUserGroup, getIsAddedUserGroup } from '@/store/usm/userGroup'
import { actionUserGroupUSM } from '@/store/usm/userGroup/actions'
import { getCreateUserLookupConfig, getIsFetchingAddNewUserModal } from '@/store/usm/users'
import { useErrorMessage } from '@/utils/hooks/useErrrorMessage'

// types
import { Props } from './types'

export const useUserGroupModal = ({ handleClose }: Props) => {
  const formInitialValues: AddUserGroupCardValues = {
    [AddNewUserGroupCardField.UserGroupName]: '',
    [AddNewUserGroupCardField.Description]: '',
    [AddNewUserGroupCardField.ClonePermission]: false,
    [AddNewUserGroupCardField.UserGroup]: '',
  }

  const dispatch = useDispatch()
  const error = useSelector(getGlobalErrorUserGroup)
  // from user-state
  const lookupConfig = useSelector(getCreateUserLookupConfig)
  const isLoading = useSelector(getIsFetchingAddNewUserModal)
  const isAddedUserGroup = useSelector(getIsAddedUserGroup)

  const handleSubmitForm = (
    values: AddUserGroupCardValues,
    formActions: FormikHelpers<AddUserGroupCardValues>,
  ) => {
    dispatch(
      actionUserGroupUSM.addGroupAsync<AddUserGroupCardValues>(
        {
          userGroupId: values[AddNewUserGroupCardField.ClonePermission]
            ? Number(values[AddNewUserGroupCardField.UserGroup])
            : 0,
          description: values[AddNewUserGroupCardField.Description],
          userGroupName: values[AddNewUserGroupCardField.UserGroupName],
        },
        { handleClose, formActions },
      ),
    )
  }

  const formik = useFormik({
    validate: validate,
    onSubmit: handleSubmitForm,
    initialValues: formInitialValues,
  })
  const { values, resetForm, handleSubmit, isValid, dirty, setFieldValue } = formik

  const { errorMessages, setErrorMessages } = useErrorMessage<AddUserGroupCardValues>({
    error,
    formActions: {
      setErrors: formik.setErrors,
      setFieldError: formik.setFieldError,
    },
  })

  const handleCloseForm = useCallback(() => {
    handleClose()
    resetForm()
    dispatch(actionUserGroupUSM.setClearErrorUserGroup())
    setErrorMessages([])
  }, [handleClose, resetForm, error])

  return {
    handleSubmit,
    isValid,
    dirty,
    formik,
    values,
    setFieldValue,
    handleCloseForm,
    lookupConfig,
    isLoading,
    errorMessages,
    isAddedUserGroup,
  }
}
