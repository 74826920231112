import React from 'react'
import { isIE } from 'react-device-detect'

import CMLogoTransparent from '@/assets/cm-transparent-logo.png'
import ATMeyeProducts from '@/assets/logosModules/atmeye.svg'
import SDProduct from '@/assets/logosModules/servicedesk.svg'
// import SDTransparent from './logo-transparent.png'
import USMLogo from '@/assets/usm/USM-Logo.svg'
import VaultV1LogoTransparent from '@/assets/vault-v1-transparent-logo.png'

import BS2Logo from './BS2-logo.png'
// import LogoWhite from './logo-white.png'
import LogoTransparent from './IQ-core1-3.svg'
import { AppLogo } from './styles'
import { Props } from './types'

const logos = {
  white: SDProduct,
  transparent: LogoTransparent,
  bs: BS2Logo,
  cm: CMLogoTransparent,
  'vault-v1': VaultV1LogoTransparent,
  'sd-white': SDProduct,
  USM: USMLogo,
  '/sd': SDProduct,
  '/atmeye': ATMeyeProducts,
}

const Logo = ({ type }: Props): React.ReactElement => {
  return <AppLogo src={logos[type] || SDProduct} alt="logo" isIE={isIE} />
}

export default Logo
