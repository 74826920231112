import makeStyles from '@material-ui/core/styles/makeStyles'

import { styledComponentTheme } from '@/theme'

export const useClasses = makeStyles(({ spacing }) => ({
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'white',
    paddingBottom: '10px',
    paddingTop: '10px',
  },

  emptyTabBlock: {
    justifyContent: 'flex-end',
  },

  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  switchColor: {
    color: '#ffffff',
  },

  tableHeaderButton: {
    border: '1px solid',
    borderColor: styledComponentTheme.colors.baseGreen,
    width: '40px',
    height: '40px',
    padding: '0',
    minWidth: 'auto',
    marginRight: '16px',

    '& .MuiButton-startIcon': {
      margin: '0',
    },
  },

  tableHeaderButtonDisabled: {
    opacity: 0.4,
  },

  toolTip: {
    background: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    color: 'black',
    padding: spacing(1),
    fontSize: '14px',
    top: spacing(1),
  },

  switch: {
    marginRight: spacing(2),
  },

  popoverPaper: {
    marginLeft: spacing(1),
    marginTop: spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
  },

  popoverButton: {
    justifyContent: 'flex-start',
    fontWeight: 'normal',
    padding: '6px 20px',
    borderBottom: '1px solid #ecf2f2',
  },
}))
