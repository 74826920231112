import { AnyAction } from 'redux'
import { DictionariesActions } from '@/constants/actions/dictionaries'

import { DictionariesTreeItem } from '@/types/dictionaries/dictionariesList'
import { ResponseError } from '@/types'

export const getDictionariesTreeDataRequest = (): AnyAction => ({
  type: DictionariesActions.GetDictionariesListDataRequest,
})
export const getDictionariesTreeDataResponse = (data: DictionariesTreeItem[]): AnyAction => ({
  type: DictionariesActions.GetDictionariesListDataResponse,
  payload: data,
})
export const getDictionariesTreeDataResponseFail = (error: ResponseError): AnyAction => ({
  type: DictionariesActions.GetDictionariesListDataResponseFail,
  payload: { error },
})

export const setSelectedDictionary = (dictionary: DictionariesTreeItem): AnyAction => ({
  type: DictionariesActions.SetSelectedDictionary,
  payload: dictionary,
})

export const foldDictionariesList = (): AnyAction => ({
  type: DictionariesActions.SetIsFolded,
})
