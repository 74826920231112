import { RefObject, useEffect } from 'react'
import scrollIntoView from 'scroll-into-view-if-needed'

export interface ScrollToSelectedProps {
  ref: RefObject<HTMLDivElement> | null
  idList: (string | number)[]
  selectedId?: string | number
}

export const useScrollToSelected = ({
  ref,
  idList,
  selectedId,
}: ScrollToSelectedProps): RefObject<HTMLDivElement> | null => {
  useEffect(() => {
    if (selectedId) {
      const index = (idList || []).findIndex(id => id === selectedId) + 1

      const elem = ref?.current?.querySelector(`.MuiTableBody-root > tr:nth-child(${index})`)

      elem &&
        scrollIntoView(elem, {
          scrollMode: 'if-needed',
          block: 'center',
        })
    }
  }, [idList])

  return ref
}
