import { styledComponentTheme } from '@/theme/styledComponentTheme'
import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  wrapper: {
    padding: spacing(4),
  },
  closeButton: {
    top: spacing(2),
    right: spacing(2),
    '& [class*="makeStyles-icon"]': {
      color: '#041517',
    },
  },
  header: {
    marginBottom: spacing(2.5),
    fontWeight: 'normal',
  },
  iconButton: {
    width: '40px',
    height: '40px',
    marginRight: '10px',
    '&[disabled]': {
      filter: 'grayscale(100%)',
      borderColor: '#929A9B',
    },
  },
  applyButton: {
    background: styledComponentTheme.colors.baseGreen,
    padding: '7px 20px',
    marginLeft: spacing(1),
    fontWeight: 'normal',
    '&[disabled]': {
      background: 'transparent',
      border: '1px solid #929A9B',
      color: '#929A9B',
    },
  },
  clearButton: {
    background: 'white',
    border: '1px solid',
    borderColor: styledComponentTheme.colors.baseGreen,
    color: styledComponentTheme.colors.baseGreen,
    padding: '7px 20px',
    marginLeft: spacing(1),
    fontWeight: 'normal',
    '&[disabled]': {
      background: 'transparent',
      border: '1px solid #929A9B',
      color: '#929A9B',
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  input: {
    width: '100%',
  },
  subtitle: {
    fontSize: '22px',
    fontWeight: 'bold',
  },
  selectButton: {
    '& [class*="makeStyles-icon"]': {
      color: '#041517',
      fontSize: '1.4rem',
    },
    '& .MuiSelect-select': {
      paddingTop: '14px',
      paddingBottom: '14px',
      fontSize: '14px',
    },
  },
  savedGroupings: {
    marginTop: spacing(2.5),
    fontWeight: 'normal',
  },
}))
