import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { BlackListApi } from '@/api/atmeye/blackListApi'
import { Filter, FiltersTemplate } from '@/api/atmeye/translactionsApi/types'
import { MODAL_TYPE } from '@/components/NPMPakage/components/modals/TemplateModal/component'
import { useTranslate } from '@/utils/internalization'
import { PopUpService } from '@/utils/services/popUpService'

type useFilterTemplatesProps = {
  appliedFilterTemplate: FiltersTemplate | null
  filters: Filter
  setFilters: (filters: Filter) => void
  defaultFilter: Filter
  filterTemplatesType: string
}

const useFilterTemplates = ({
  appliedFilterTemplate,
  filters,
  setFilters,
  defaultFilter,
  filterTemplatesType,
}: useFilterTemplatesProps) => {
  const translate = useTranslate()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [modalType, setModalType] = useState<MODAL_TYPE>(MODAL_TYPE.MAIN)

  const showModal = useCallback((modalType: MODAL_TYPE): void => {
    setModalType(modalType)
    setAnchorEl(null)
  }, [])

  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false)
  const [templates, setTempaltes] = useState<FiltersTemplate[]>([])

  const [selectedTemplate, setSelectedTemplate] = useState<FiltersTemplate | null>(
    appliedFilterTemplate,
  )

  const getFilterTemplatesRequest = useCallback(async () => {
    setIsLoadingTemplates(true)
    try {
      const response = await BlackListApi.getListFromJsonStorage<FiltersTemplate>({
        type: filterTemplatesType,
      })
      setTempaltes(response.content)
    } catch (e) {
      console.log(e)
    }

    setIsLoadingTemplates(false)
  }, [])

  useEffect((): void => {
    getFilterTemplatesRequest()
  }, [getFilterTemplatesRequest])

  const onChangeSelectedTemplate = useCallback(
    async newTemplateId => {
      const template = templates.find(template => template.id === +newTemplateId)

      if (!newTemplateId || !template) {
        setFilters(defaultFilter)
        setSelectedTemplate(null)
        return
      }

      setSelectedTemplate(template)
      try {
        setFilters(template.json)
      } catch (e) {
        console.log(e)
      }
    },
    [templates],
  )

  useEffect(() => {
    if (selectedTemplate && templates.length) {
      const template = templates.find(template => template.id === selectedTemplate.id) || null

      setSelectedTemplate(template)
    }
  }, [templates])

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = (): void => {
    setAnchorEl(null)
  }

  const handleSaveTemplate = useCallback(
    (inputValue: string) => async () => {
      const isUniqName = !templates.find(temp => temp.name === inputValue)

      if (!isUniqName) {
        PopUpService.showGlobalPopUp(`translate#title.sameTemplateName`, 'warning')
      } else {
        try {
          const addedTpl = await BlackListApi.createJsonStorageItem<Filter>({
            json: filters,
            name: inputValue,
            type: filterTemplatesType,
          })

          setSelectedTemplate(addedTpl)
          await getFilterTemplatesRequest()
          PopUpService.showGlobalPopUp('translate#title.templateSaved', 'success')
          showModal(MODAL_TYPE.MAIN)
        } catch (e) {
          return Promise.reject(e)
        }
      }
    },
    [filters, translate, showModal, templates],
  )

  const handleEditCurrentTemplate = useCallback(
    (inputValue?: string) => async () => {
      if (selectedTemplate) {
        const isNotUniqName = templates?.some(
          el => el.name === inputValue && el.id !== selectedTemplate.id,
        )

        if (isNotUniqName) {
          PopUpService.showGlobalPopUp('translate#atmeye.message.templateAlreadyExist', 'info')
        } else {
          try {
            await BlackListApi.editJsonStorageItem<Filter>({
              json: inputValue ? selectedTemplate.json : filters,
              name: inputValue || selectedTemplate.name,
              id: selectedTemplate.id,
              type: selectedTemplate.type,
            })
            await getFilterTemplatesRequest()
            PopUpService.showGlobalPopUp('translate#title.templateEdited', 'success')
          } catch (error) {
            PopUpService.showGlobalPopUp(
              `${translate('translate#title.templateEditError')} ${error}`,
              'error',
            )
            console.log(error)
            return
          }

          setModalType(MODAL_TYPE.MAIN)
        }
      }
    },
    [templates, selectedTemplate, filters],
  )

  const handleDeleteTemplate = useCallback(async () => {
    try {
      if (selectedTemplate) {
        await BlackListApi.deleteJsonStorageItem(selectedTemplate.id)
        PopUpService.showGlobalPopUp('translate#title.templateRemoved', 'success')
        await getFilterTemplatesRequest()

        onChangeSelectedTemplate(null)
        // clearFilter or setInitial filters
        setModalType(MODAL_TYPE.MAIN)
      }
    } catch (error) {
      PopUpService.showGlobalPopUp('translate#title.templateDeletionError', 'error')
    }
  }, [translate, selectedTemplate, getFilterTemplatesRequest, onChangeSelectedTemplate])

  const templatesOptions = useMemo(
    () =>
      templates.map(template => ({
        name: template.name,
        value: `${template.id}`,
      })),
    [templates],
  )

  return {
    selectedTemplate,
    templatesOptions,
    isLoadingTemplates,
    handleSaveTemplate,
    handleEditCurrentTemplate,
    handleDeleteTemplate,
    onChangeSelectedTemplate,
    handleOpenPopover,
    handleClosePopover,
    anchorEl,

    modalType,
    showModal,

    translate,
    templates,
  }
}

export { useFilterTemplates }
