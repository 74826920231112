import { Currency, Valuable } from '@/types'
import { Vault } from '@/api/vault/cashOrdersApi/types'

export enum CreateCashOrderFormFields {
  Reciever = 'reciever',
  Transmitter = 'transmitter',
  OrderDate = 'orderDate',
  OrderTime = 'orderTime',
  Transport = 'transport',
  Bags = 'bags',
  PassData = 'passData',
  Comment = 'comment',
  Currencies = 'currencies',
  Valuables = 'valuables',
  OrderType = 'orderType',
  OrderContentType = 'orderContentType',
  Stamp = 'stamp',
}

export enum OrderTypes {
  CashOrder = 'cashOrder',
  ExportOrder = 'exportOrder',
}

export enum OrderContentTypes {
  Cash = 'cash',
  Valuables = 'valuables',
}

export interface CreateCashOrderFormValues {
  [CreateCashOrderFormFields.Bags]: CreateOrderBag[]
  [CreateCashOrderFormFields.Comment]: string
  [CreateCashOrderFormFields.Currencies]: Currency[]
  [CreateCashOrderFormFields.OrderContentType]: OrderContentTypes
  [CreateCashOrderFormFields.OrderDate]: string
  [CreateCashOrderFormFields.OrderTime]: string
  [CreateCashOrderFormFields.OrderType]: OrderTypes
  [CreateCashOrderFormFields.PassData]: string
  [CreateCashOrderFormFields.Reciever]: Vault | null
  [CreateCashOrderFormFields.Stamp]: number
  [CreateCashOrderFormFields.Transmitter]: Vault | null
  [CreateCashOrderFormFields.Transport]: string
  [CreateCashOrderFormFields.Valuables]: Valuable[]
}

export interface CreateOrderBag {
  bagID?: string | number
  bagNumber: string
  cashBagId?: string | number
  comment: string
  currencies: Currency[]
  isValidBagNumber?: boolean
  localBagId: number | string
  stamp: string
  valuables: Valuable[]
}

export interface CreateOrderRequestBody {
  cashOrderCurrencies?: Array<{
    currencyDenominationId: number
    quantity: number
  }>
  cashOrderValuables?: Array<{
    comment: string
    quantity: number
    valuableNumber: number
    valuableTypeId: number
  }>
  comment: string
  currencyId?: number
  orderTime: number
  reciever: string
  sender: string
  userId: string
}
