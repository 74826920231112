import React, { ReactElement } from 'react'
import { Typography } from '@material-ui/core'
import Switch from './components/Switch'

import { useClasses } from './styles'
import { Props } from './types'
import clsx from 'clsx'

const AppSwitch = ({
  active,
  handleChange,
  withText,
  SwitchComponent,
  className,
  textSwitch,
}: Props): ReactElement => {
  const classes = useClasses()

  return (
    <div className={classes.wrapper}>
      {withText && active ? (
        <Typography variant="caption" className={classes.textOn}>
          {textSwitch?.leftText || 'on'}
        </Typography>
      ) : null}
      {SwitchComponent ? (
        <SwitchComponent checked={active} onChange={handleChange} />
      ) : (
        <Switch checked={active} onChange={handleChange} />
      )}
      {withText && !active ? (
        <Typography variant="caption" className={clsx(classes.textOff, className)}>
          {textSwitch?.rightText || 'off'}
        </Typography>
      ) : null}
    </div>
  )
}

export default AppSwitch
