import React, { FC, useEffect, useState } from 'react'

import { MultipleSelectOptions } from '@/api/atmeye/commonTypes'
import { TicketsApi } from '@/api/sd/ticketsApi'
import Loader from '@/components/blocks/Loader'
import { DropDownEntityType } from '@/components/pages/atmeye/Administration/LicenseList/ATMCoreLicenses/components/FiltersModalLicence/GenerateFormFields/types'
import AlarmsPage from '@/components/pages/atmeye/Alarms'
import { LocalStorageItems } from '@/constants'

import { DeviceTabPanelTypes } from '../TransactionsTab/types'

const lsKeys = {
  lsKeyGrouping: LocalStorageItems.AlarmsByDeviceGrouping,
  lsKeyPagination: LocalStorageItems.AlarmsByDevicePagination,
  lsKeyColumns: LocalStorageItems.AlarmsByDeviceColumns,
  lsKeyFilters: LocalStorageItems.AlarmsByDeviceFilters,
  lsKeyFiltersTemplates: LocalStorageItems.AlarmsByDeviceFilterTemplate,
  lsKeySorting: LocalStorageItems.AlarmsByDeviceSort,
}

const AlarmsTabPanel: FC<DeviceTabPanelTypes> = ({
  currentDevice,
  segmentFilters,
  setSegmentFilters,
}: DeviceTabPanelTypes) => {
  const [requiredFilter, setRequiredFilter] = useState<
    { deviceId: MultipleSelectOptions } | undefined
  >()
  const [isLoading, setIsLoading] = useState()

  const getRequiredFilterOption = async (): Promise<void> => {
    if (currentDevice) {
      setIsLoading(true)
      const [{ dropdownListElements }] = await TicketsApi.getDropdownListsData([
        {
          entityType: DropDownEntityType.DEVICES_IDS_AND_NAMES,
          pageNumber: 1,
          pageSize: 1,
          searchFragment: '',
          selectedIds: [currentDevice.deviceId],
        },
      ])

      setRequiredFilter({ deviceId: dropdownListElements.filter(({ isSelected }) => isSelected) })
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getRequiredFilterOption()
  }, [currentDevice])

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 999,
            background: 'rgba(255, 255, 255, 1)',
          }}
        >
          <Loader />
        </div>
      )}
      {requiredFilter && (
        <AlarmsPage
          title=""
          filtersConfig={{
            staticDefaultFilters: segmentFilters || {
              deviceId: requiredFilter?.deviceId,
              atmDateTime: [null, null],
              reactionDateTime: [null, null],
              eventTypeId: [],
              eventMediaTypeId: [],
              note: '',
              reaction: '',
              operator: '',
            },
            shouldIngoreStorage: !!segmentFilters,
            cleanFiltersAfterApplied: () => {
              setSegmentFilters && setSegmentFilters(null)
            },
          }}
          requiredFilters={requiredFilter}
          lsKeys={lsKeys}
        />
      )}
    </>
  )
}

export { AlarmsTabPanel }
