import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getVaultsEvjState = (state: AppState): fromReducer.State => {
  return state.vaultV1EventJournal
}

export const getEvjEvents = createSelector(getVaultsEvjState, state => state.events)
export const getEvjEventsData = createSelector(getEvjEvents, state => state.data)
export const getEvjEventsFilter = createSelector(getEvjEvents, state => state.filter)
export const getEvjEventsPaging = createSelector(getEvjEvents, state => state.paging)
export const getEvjEventsTotal = createSelector(getEvjEvents, state => state.total)
export const getEvjSelected = createSelector(getEvjEvents, state => state.selected)
export const getEvjSelectedEntry = createSelector(getEvjEvents, state => state.selectedEntry)
export const getMonEventsModals = createSelector(getVaultsEvjState, state => state.modals)

export const getEvjEventsSort = createSelector(getEvjEvents, state => ({
  sortColumn: state.sortColumn,
  sortOrder: state.sortOrder,
}))

export const getMonEventsFilterOptions = createSelector(
  getVaultsEvjState,
  state => state.filterOptions,
)

export const getEvjFormattedEventsPaging = createSelector(getEvjEvents, state => {
  const { page, pageSize } = state.paging || {}

  return {
    page,
    pageSize: pageSize === -1 ? 'All' : pageSize,
  }
})
