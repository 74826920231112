import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  tableContainer: {
    position: 'relative',
    maxHeight: `calc(100% - ${spacing(14)}px)`,
    overflowX: 'visible',
  },
  loaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    background: 'rgba(255, 255, 255, 0.7)',
  },
}))
