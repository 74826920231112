import React, { ChangeEvent, useCallback, useState } from 'react'
import { setPageSizeForGroupingTickets } from '@/store/sd/tickets/actions'
import { useDispatch } from 'react-redux'

export const useSelectDownloadPageCount = (initialValue: string) => {
  const [currentPage, setCurrentPage] = useState(initialValue)

  const dispatch = useDispatch()
  const handlePageSizeChange = useCallback(
    (e: ChangeEvent<{ value: any }>): void => {
      setCurrentPage(e.target.value)
      dispatch(setPageSizeForGroupingTickets(e.target.value))
    },
    [currentPage],
  )

  return { currentPage, handlePageSizeChange } as const
}
