import React, { FC, PropsWithChildren, ReactElement, ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { AtmeyeModules } from '@/api/atmeye/common/types'
import Loader from '@/components/blocks/Loader'
import { Permission } from '@/components/NPMPakage/components/wrappers/types'
import { getAvailableModules, getIsAvailableModulesLoading } from '@/store/atmeye/common'

import { usePermission } from './usePermission'

type Props = {
  permissionKey?: Permission
  fallback?: JSX.Element | string
  loadingComponent?: JSX.Element | string
  children: PropsWithChildren<ReactNode>
  licenseKey?: AtmeyeModules
}

const Restricted: FC<Props> = ({
  permissionKey,
  licenseKey,
  fallback,
  loadingComponent,
  children,
}: Props): ReactElement => {
  const availableModules = useSelector(getAvailableModules)
  const isModulesLoading = useSelector(getIsAvailableModulesLoading)
  const { loading, allowed } = usePermission(permissionKey)

  if (loading || isModulesLoading) {
    return <>{loadingComponent || <Loader />}</>
  }

  if (allowed && (licenseKey ? availableModules?.[licenseKey] : true)) {
    return <>{children}</>
  }

  return <>{fallback}</>
}

export { Restricted }
