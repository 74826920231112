import { useCallback, useMemo, useState } from 'react'

import { StrategyLocalStorage } from '@/components/NPMPakage/components/tableComponents/UITable/strategy'
import { ColumnsDeviceTypes } from '@/components/NPMPakage/components/tableComponents/UITable/types'
import { LocalStorageItems } from '@/constants'
import { translateColumns } from '@/utils/functions'
import { useTranslate } from '@/utils/internalization'

const useColumnsWithoutConfig = ({
  tableColumns,
  initialHiddenColumns,
  lsKeyColumns,
}: {
  tableColumns: ColumnsDeviceTypes[]
  initialHiddenColumns?: string[]
  lsKeyColumns?: LocalStorageItems
}) => {
  const translate = useTranslate()

  const translatedColumns: Array<ColumnsDeviceTypes> = useMemo(() => {
    return translateColumns(tableColumns, translate)
  }, [tableColumns, translate])

  const [columns, setColumns] = useState<Array<ColumnsDeviceTypes>>(() => {
    const columnsArrLS = (lsKeyColumns && StrategyLocalStorage.getData(lsKeyColumns)) || []

    if (columnsArrLS.length) {
      const selectedColumns = translatedColumns.filter(column =>
        columnsArrLS.includes(column.fieldName),
      )

      return selectedColumns
    }

    return initialHiddenColumns
      ? translatedColumns.filter(column => !initialHiddenColumns.includes(column.fieldName))
      : translatedColumns
  })

  const allFields = useMemo(() => {
    return translatedColumns.map(column => column.fieldLabel.toString())
  }, [translatedColumns])

  const currentFields = useMemo(() => {
    return columns.map(column => column.fieldLabel.toString())
  }, [columns])

  const handleChangeColumns = useCallback(
    (newColumns: Array<string>) => {
      const newTranslatedColumns = translatedColumns.filter(column =>
        newColumns.includes(column.fieldLabel.toString()),
      )
      setColumns(newTranslatedColumns)
      lsKeyColumns &&
        StrategyLocalStorage.setData(
          lsKeyColumns,
          newTranslatedColumns.map(({ fieldName }) => fieldName),
        )
    },
    [lsKeyColumns, translatedColumns],
  )

  return { columns, allFields, currentFields, handleChangeColumns, setColumns }
}

export { useColumnsWithoutConfig }
