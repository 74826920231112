import moment from 'moment'
import { ReactElement } from 'react'

export const formatIfDate = (value: string | ReactElement): string => {
  if (typeof value === 'object') {
    return ''
  }

  return moment(value, moment.ISO_8601, true).isValid()
    ? moment(value).format('YYYY.MM.DD hh:mm')
    : value
}
