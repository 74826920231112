import faker from 'faker'
import { FilterTemplate, TicketFields } from '@/types'

const initialMinDate = new Date()
const initialMaxDate = new Date()
initialMinDate.setFullYear(initialMinDate.getFullYear() - 1)
initialMaxDate.setFullYear(initialMaxDate.getFullYear() + 1)

const generateFilterTemplate = (): FilterTemplate => ({
  id: faker.random.uuid(),
  name: faker.random.word(),
  filter: {
    [TicketFields.ReceiveDate]: {
      end: initialMaxDate.toISOString(),
      start: initialMinDate.toISOString(),
    },
    [TicketFields.Status]: faker.helpers.randomize([['Created'], ['Prepared']]),
    [TicketFields.CustomerRequestNumber]: `${faker.random.number()}`,
    [TicketFields.Merchant]: `Merchant-${faker.random.number()}`,
    [TicketFields.ServiceType]: faker.helpers.randomize([['Service Type 1'], ['Service Type 2']]),
    [TicketFields.DeviceType]: faker.helpers.randomize([['ATM'], ['POS']]),
    [TicketFields.LUNO]: `LUNO-${faker.random.number()}`,
  },
})

export default {
  filters: [
    generateFilterTemplate(),
    generateFilterTemplate(),
    generateFilterTemplate(),
    generateFilterTemplate(),
    generateFilterTemplate(),
    generateFilterTemplate(),
  ],
}
