import queryString from 'query-string'

import {
  AdmVault,
  AdmVaultBase,
  AdmVaultCreateResponse,
  AdmVaultsFilter,
  AdmVaultsResponse,
  SelectorItem,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { Paging, Sort } from '@/types/cm'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

interface GetAllProps {
  sort: Sort
  paging: Paging
  filter: AdmVaultsFilter
}

export class ObjectsAdminApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getAll({ sort, paging, filter }: GetAllProps): Promise<AdmVaultsResponse[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/vaults',
          query: { ...paging, ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await ObjectsAdminApi.requestService.post(url, filter)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getSingle(guid: string): Promise<AdmVault[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/vault',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )

      return await ObjectsAdminApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getVaultsToAssign(): Promise<SelectorItem[]> {
    try {
      return await ObjectsAdminApi.requestService.get('/admin/vaults/vaultsToAssign')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(payload: AdmVaultBase): Promise<AdmVaultCreateResponse> {
    try {
      return await ObjectsAdminApi.requestService.post('/admin/vaults/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(payload: AdmVault): Promise<void> {
    try {
      return await ObjectsAdminApi.requestService.put('/admin/vaults/update', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(guid: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/vaults/delete',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )

      return await ObjectsAdminApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
