import { AnyAction } from 'redux'

import {
  AdmPlnTeamGroup,
  ComCMReport,
  PlasticCard,
  PlasticCardFilter,
  PlasticCardOrder,
  PlasticCardsWorkOrder,
  PlasticCardsWorkOrdersRequest,
} from '@/api/cm/swaggerGeneratedApi'
import { isItemEnable } from '@/components/pages/cm/PlasticCardPage/components/panels/PlasticCards/components/MainTable/component'
import { CMActions } from '@/constants'
import { plasticCardTableId as tableId } from '@/constants/cm/cmTableIds'
import { getTableConfig } from '@/helpers/tableLocalStorage'
import { getCurrentDateString } from '@/utils/functions'

type Team = { value: string; name: string }

export type State = {
  modals: {
    isColumnModalOpen: boolean
    isFiltersModalOpen: boolean
    loadingModal: boolean
  }
  plasticCard: {
    isLoading: boolean
    isLoaded: boolean
    data: PlasticCard[]
    total: number
    errorMessage?: string
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined
    filter: PlasticCardFilter
    paging: {
      page: number
      pageSize: number
    }

    selected: PlasticCard | null
    checked: { [key: string]: PlasticCard }
  }

  // TODO Remove 'any' from the workOrder type
  workOrder: {
    data: PlasticCardsWorkOrder[]
    isLoading: boolean
    isLoaded: boolean
    filter: PlasticCardsWorkOrdersRequest
    total: number
    selected: PlasticCardOrder | null
    sortColumn?: string
    sortOrder?: 'asc' | 'desc'

    reports: {
      errorMessage?: string
      isLoading: boolean
      list: ComCMReport[]
      selectedReport: {
        isLoading: boolean
        errorMessage?: string
        bytes?: Blob | null
      }
    }
  }

  teams: Team[]
}

export const initialState: State = {
  modals: {
    isColumnModalOpen: false,
    isFiltersModalOpen: false,
    loadingModal: false,
  },
  plasticCard: {
    isLoading: false,
    isLoaded: false,
    errorMessage: '',
    data: [],
    total: 0,
    sortColumn: undefined,
    sortOrder: undefined,
    filter: {
      orderStatuses: undefined,
      workOrder: undefined,
    },
    paging: {
      page: 1,
      pageSize: getTableConfig({ tableId })?.rowPerPage || 50,
    },

    selected: null,
    checked: {},
  },

  workOrder: {
    data: [],
    isLoading: false,
    isLoaded: false,
    sortColumn: undefined,
    sortOrder: undefined,
    filter: {
      dateFrom: getCurrentDateString(),
      dateTo: '',
    },
    total: 0,
    selected: null,

    reports: {
      errorMessage: '',
      isLoading: false,
      list: [],
      selectedReport: {
        isLoading: false,
        errorMessage: '',
        bytes: undefined,
      },
    },
  },

  teams: [],
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    // Loading Modal
    case CMActions.PlcCreateWorkOrderRequest:
    case CMActions.PlcDeclineWorkOrderRequest:
    case CMActions.PlcCompleteWorkOrderRequest:
    case CMActions.PlcDeleteWorkOrderRequest:
      return {
        ...state,
        modals: {
          ...state.modals,
          loadingModal: true,
        },
      }
    case CMActions.PlcCreateWorkOrderResponse:
      return {
        ...state,
        modals: {
          ...state.modals,
          loadingModal: false,
        },
        plasticCard: {
          ...state.plasticCard,
          checked: initialState.plasticCard.checked,
        },
      }
    case CMActions.PlcCreateWorkOrderFail:
    case CMActions.PlcDeclineWorkOrderResponse:
    case CMActions.PlcDeclineWorkOrderFail:
    case CMActions.PlcCompleteWorkOrderResponse:
    case CMActions.PlcCompleteWorkOrderFail:
    case CMActions.PlcDeleteWorkOrderResponse:
    case CMActions.PlcDeleteWorkOrderFail:
      return {
        ...state,
        modals: {
          ...state.modals,
          loadingModal: false,
        },
      }

    // [START] Plastic Card -------------------------------------------
    case CMActions.PlcGetPlasticCardsRequest:
      return {
        ...state,
        plasticCard: {
          ...state.plasticCard,
          isLoading: true,
        },
      }
    case CMActions.PlcGetPlasticCardsResponse:
      return {
        ...state,
        plasticCard: {
          ...state.plasticCard,
          isLoading: false,
          isLoaded: true,
          data: payload.data,
          total: payload.total,
        },
      }
    case CMActions.PlcGetPlasticCardsFail:
      return {
        ...state,
        plasticCard: {
          ...state.plasticCard,
          isLoading: false,
          isLoaded: true,
        },
      }

    case CMActions.PlcGetSinglePlasticCardRequest:
      return {
        ...state,
        plasticCard: {
          ...state.plasticCard,
          isLoading: true,
        },
      }
    case CMActions.PlcGetSinglePlasticCardResponse: {
      const { selected } = state.plasticCard
      const plasticCardCopy = [...state.plasticCard.data]
      const refreshedOrderIndex = plasticCardCopy.findIndex(card => card?.cpId === payload?.cpId)

      if (refreshedOrderIndex !== undefined && refreshedOrderIndex !== null) {
        plasticCardCopy[refreshedOrderIndex] = { ...payload }
      }

      return {
        ...state,
        plasticCard: {
          ...state.plasticCard,
          isLoading: false,
          data: plasticCardCopy,
          selected: payload?.cpId === selected?.cpId ? payload : selected,
        },
      }
    }
    case CMActions.PlcGetSinglePlasticCardFail:
      return {
        ...state,
        plasticCard: {
          ...state.plasticCard,
          isLoading: false,
        },
      }

    // Modals
    case CMActions.PlcOpenSettingsModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          isColumnModalOpen: payload,
        },
      }
    case CMActions.PlcOpenFilterModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          isFiltersModalOpen: payload,
        },
      }

    // Paging
    case CMActions.PlcSetPlasticCardPaging:
      return {
        ...state,
        plasticCard: {
          ...state.plasticCard,
          isLoading: true,
          paging: {
            ...state.plasticCard.paging,
            ...payload,
          },
        },
      }
    case CMActions.PlcResetPaging:
      return {
        ...state,
        plasticCard: {
          ...state.plasticCard,
          paging: {
            ...state.plasticCard.paging,
            page: 1,
          },
        },
      }

    // Filter
    case CMActions.PlcSetPlasticCardFilter:
      return {
        ...state,
        plasticCard: {
          ...state.plasticCard,
          filter: payload,
        },
      }
    // Selected
    case CMActions.PlcSetSelectedPlasticCard:
      return {
        ...state,
        plasticCard: {
          ...state.plasticCard,
          selected: payload,
        },
      }

    // Sort
    case CMActions.PlcSetPlasticCardSort:
      return {
        ...state,
        plasticCard: {
          ...state.plasticCard,
          isLoading: true,
          ...payload,
        },
      }

    case CMActions.PlcCheckPlasticCard: {
      const checkedCopy = { ...state.plasticCard.checked }

      const soughtChecked = checkedCopy[payload?.cpId]

      if (!soughtChecked) {
        checkedCopy[payload?.cpId] = payload
      } else {
        delete checkedCopy[payload?.cpId]
      }
      return {
        ...state,
        plasticCard: {
          ...state.plasticCard,
          checked: checkedCopy,
        },
      }
    }

    case CMActions.PlcCheckAllPlasticCard: {
      const newChecked: State['plasticCard']['checked'] = {}
      const allPlasticCards = [...state.plasticCard.data]

      for (const card of allPlasticCards) {
        if (card?.cpId && isItemEnable(card)) {
          newChecked[card.cpId] = card
        }
      }

      return {
        ...state,
        plasticCard: {
          ...state.plasticCard,
          checked: newChecked,
        },
      }
    }

    case CMActions.PlcClearAllPlasticCard:
      return {
        ...state,
        plasticCard: {
          ...state.plasticCard,
          checked: {},
        },
      }

    // Teams
    case CMActions.PlcSetTeamRequest:
    case CMActions.PlcResetTeamRequest:
      return {
        ...state,
        plasticCard: {
          ...state.plasticCard,
          isLoading: true,
        },
      }
    case CMActions.PlcSetTeamFail:
    case CMActions.PlcResetTeamFail:
      return {
        ...state,
        plasticCard: {
          ...state.plasticCard,
          isLoading: false,
        },
      }
    // [END] Plastic Card ---------------------------------------------

    // Work Orders
    case CMActions.PlcGetWorkOrdersRequest:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          isLoading: true,
        },
      }
    case CMActions.PlcGetWorkOrdersResponse:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          isLoading: false,
          isLoaded: true,
          data: payload,
          total: (payload || []).length,
        },
      }
    case CMActions.PlcGetWorkOrdersFail:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          isLoading: false,
          isLoaded: true,
        },
      }

    case CMActions.PlcSetWorkOrdersFilter:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          filter: {
            ...state.workOrder.filter,
            ...payload,
          },
          isLoading: true,
        },
      }
    case CMActions.PlcSetSelectedWorkOrder:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          selected: payload,
        },
      }
    case CMActions.PlcSetWorkOrderSort:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          isLoading: true,
          ...payload,
        },
      }

    // [START] Work Order Reports ----------------------------------------------
    case CMActions.GetPlcWorkOrderReportsRequest:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          reports: {
            ...state.workOrder.reports,
            isLoading: true,
            list: [],
            errorMessage: '',
            selectedReport: initialState.workOrder.reports.selectedReport,
          },
        },
      }
    case CMActions.GetPlcWorkOrderReportsResponse:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          reports: {
            ...state.workOrder.reports,
            isLoading: false,
            list: payload,
            errorMessage: '',
          },
        },
      }
    case CMActions.GetPlcWorkOrderReportsFail:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          reports: {
            ...state.workOrder.reports,
            isLoading: false,
            list: [],
            errorMessage: payload,
          },
        },
      }

    case CMActions.GetPlcWorkOrderReportRequest:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          reports: {
            ...state.workOrder.reports,
            selectedReport: {
              ...state.workOrder.reports.selectedReport,
              isLoading: true,
              errorMessage: '',
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.GetPlcWorkOrderReportResponse:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          reports: {
            ...state.workOrder.reports,
            selectedReport: {
              ...state.workOrder.reports.selectedReport,
              isLoading: false,
              errorMessage: '',
              bytes: payload,
            },
          },
        },
      }
    case CMActions.GetPlcWorkOrderReportFail:
      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          reports: {
            ...state.workOrder.reports,
            selectedReport: {
              ...state.workOrder.reports.selectedReport,
              isLoading: false,
              errorMessage: payload,
              bytes: undefined,
            },
          },
        },
      }
    // [END] Work Order Reports ----------------------------------------------

    // [Start] Teams ----------------------------------------------
    case CMActions.GetPlcTeamsResponse: {
      const teams = payload as AdmPlnTeamGroup[]

      const parsedTeams = teams.reduce((acc, { teams }) => {
        return [
          ...acc,
          ...((teams || []).reduce(
            (acc, { id, name }) => [...acc, { value: id, name }],
            [] as Team[],
          ) || []),
        ]
      }, [] as Team[])

      return {
        ...state,
        teams: parsedTeams || [],
      }
    }
    // [END] Teams ------------------------------------------------
    default:
      return state
  }
}
