import React from 'react'
import { Props } from './types'
import SimpleBarChart from '@/components/blocks/Charts/SimpleBarChart/component'
import { CHART_IDS } from '@/constants/charts'
import LineChartWithXAxisPading from '@/components/blocks/Charts/LineChartWithXAxisPading/component'
import StackedAreaChart from '@/components/blocks/Charts/StackedAreaChart/component'
import LineBarAreaComposedChart from '@/components/blocks/Charts/LineBarAreaComposedChart/component'
import CustomPieChart from '@/components/blocks/Charts/CustomPieChart/component'

const Chart = ({ chartId, height, isEditing }: Props): React.ReactElement => {
  switch (chartId) {
    case CHART_IDS.LineChartWithXAxisPading:
      return <LineChartWithXAxisPading height={height} isEditing={isEditing} />
    case CHART_IDS.StackedAreaChart:
      return <StackedAreaChart height={height} isEditing={isEditing} />
    case CHART_IDS.SimpleBarChart:
      return <SimpleBarChart height={height} isEditing={isEditing} />
    case CHART_IDS.LineBarAreaComposedChart:
      return <LineBarAreaComposedChart height={height} isEditing={isEditing} />
    case CHART_IDS.CustomPieChart:
      return <CustomPieChart height={height} isEditing={isEditing} />
    default:
      return <SimpleBarChart height={height} isEditing={isEditing} />
  }
}

export default Chart
