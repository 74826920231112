import { createSelector } from 'reselect'
import { AppState } from '@/store/reducers'
import * as fromReducer from './reducer'

export const getProductsState = (state: AppState): fromReducer.State => state.products

export const getConfig = createSelector(getProductsState, state => state.config)
export const getIsFetchingConfig = createSelector(getProductsState, state => state.isFetchingConfig)

export const getProducts = createSelector(getProductsState, state =>
  state.products.details ? state.products.details.items : [],
)
export const getIsFetchingProducts = createSelector(
  getProductsState,
  state => state.isFetchingProducts,
)

export const getGroupedProducts = createSelector(getProductsState, state => state.groupedProducts)
export const getIsFetchingGroupedProducts = createSelector(
  getProductsState,
  state => state.isFetchingGroupedProducts,
)

export const getCurrentProduct = createSelector(getProductsState, state => state.currentProduct)
export const getIsFetchingCurrentProduct = createSelector(
  getProductsState,
  state => state.isFetchingCurrentProduct,
)

export const getHasGroups = createSelector(getProductsState, state => state.products.hasGroups)

export const getIsFetchingAddedProduct = createSelector(
  getProductsState,
  state => state.isFetchingAddedProduct,
)

export const getIsFetchingEditedProduct = createSelector(
  getProductsState,
  state => state.isFetchingEditedProduct,
)

export const getAlternativeProducts = createSelector(getProductsState, state => {
  return state.alternativeProducts
})

export const getIsFetchingAlternativeProducts = createSelector(
  getProductsState,
  state => state.isFetchingAlternativeProducts,
)

export const getAllowedToAddAlternativeProducts = createSelector(getProductsState, state => {
  return state.allowedToAddAlternativeProducts
})

export const getIsFetchingAllowedToAddAlternativeProducts = createSelector(
  getProductsState,
  state => state.isFetchingAllowedToAddAlternativeProducts,
)

export const getProductSpecification = createSelector(getProductsState, state => {
  return state.productSpecification
})

export const getIsFetchingProductSpecifications = createSelector(
  getProductsState,
  state => state.isFetchingProductSpecification,
)

export const getAllowedToAddProductSpecifications = createSelector(getProductsState, state => {
  return state.allowedToAddProductSpecification
})

export const getIsFetchingAllowedToAddProductSpecifications = createSelector(
  getProductsState,
  state => state.isFetchingAllowedToAddProductSpecification,
)

export const getProductAttachedFiles = createSelector(getProductsState, state => {
  return state.attachedFiles
})

export const getIsFetchingProductAttachedFiles = createSelector(
  getProductsState,
  state => state.isFetchingProductAttachedFiles,
)

export const getError = createSelector(getProductsState, state => state.error)
