import { FormikValues } from 'formik'

export const getDisabledFieldNames = (formValues: FormikValues): string[] | undefined => {
  const resultArray: string[] = []
  if (formValues.sameaslegaladdress) {
    return resultArray.concat([
      'merchantcountryid',
      'merchantpostalcode',
      'merchantcityid',
      'merchantcity',
      'merchantstreet',
    ])
  }
  // other if+concat statements
  return resultArray
}
