import { AnyAction } from 'redux'

import { AdmAppSetting } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants/actions/cmActions'

// get
export const getApplicationConfigRequest = (): AnyAction => ({
  type: CMAppConfigActions.AdmGetApplicationConfigRequest,
})
export const getApplicationConfigResponse = (appConfig: AdmAppSetting[]): AnyAction => ({
  type: CMAppConfigActions.AdmGetApplicationConfigResponse,
  payload: appConfig,
})
export const getApplicationConfigFail = (error: string | unknown): AnyAction => ({
  type: CMAppConfigActions.AdmGetApplicationConfigFail,
  payload: error,
})

// update
export const updateApplicationConfigRequest = (appConfig: AdmAppSetting[]): AnyAction => ({
  type: CMAppConfigActions.AdmUpdateApplicationConfigRequest,
  payload: appConfig,
})
export const updateApplicationConfigFail = (error: string | unknown): AnyAction => ({
  type: CMAppConfigActions.AdmUpdateApplicationConfigFail,
  payload: error,
})
