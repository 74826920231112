import { useMemo } from 'react'

import { Row } from '@/types/atmeye/TableTypes'

import { PointInfosItem } from '../types'

const useGoogleMapWrapper = (data: Array<Row>) => {
  const mapPoints: Array<{ id: string; lat: number; lng: number }> | null = useMemo(() => {
    return data.length
      ? data.map(({ deviceId, latitude, longitude }) => {
          return {
            id: deviceId,
            lat: +latitude,
            lng: +longitude,
          }
        })
      : null
  }, [data])

  const mapPointsInfo: Array<PointInfosItem> | null = useMemo(() => {
    return data
      ? data.map(
          ({
            deviceId,
            latitude,
            longitude,
            deviceTypeId,
            deviceModel,
            lastHourSecurityAlarmsCount: alarmCount,
            lastHourTechnicalEventsCount: technicalEventCount,
            city,
            address,
            country,
            deviceName,
            deviceType,
            lastHourTransactionsCount: transactionsCount,
          }) => {
            return {
              latitude,
              longitude,
              deviceId,
              deviceName,
              deviceTypeId: +deviceTypeId,
              deviceModel,
              alarmCount: +alarmCount,
              technicalEventCount: +technicalEventCount,
              city,
              address,
              country,
              deviceType,
              transactionsCount: +transactionsCount,
            }
          },
        )
      : null
  }, [data])

  return {
    mapPoints,
    mapPointsInfo,
  }
}

export { useGoogleMapWrapper }
