import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ palette, spacing }) => ({
  icon: {
    color: palette.grey[200],
  },
  disableTimerTitle: {
    fontSize: spacing(2),
    color: palette.grey[200],
    fontWeight: 'bold',
  },
  CreateIcon: {
    margin: '0px 10px',
    height: '14px',
    width: '14px',
    cursor: 'pointer',
    color: palette.primary.main,
  },
  CreateIconActive: {
    margin: '0px 10px',
    height: '14px',
    width: '14px',
    cursor: 'pointer',
    color: '#50a8ae',
  },
  activeTimerTitle: {
    fontSize: spacing(2),
    fontWeight: 'bold',
    color: palette.primary.main,
  },
}))
