import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { CMCommonApi } from '@/api/cm/commonApi'
import { PlasticCardApi, PlasticCardWOApi } from '@/api/cm/plasticCardApi'
import {
  PlasticCard,
  PlasticCardResponse,
  PlasticCardsWorkOrdersResponse,
} from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants'
import { hotKeysBinder } from '@/utils/hotKeysBinder'

import { getGlobalFilter, setComCMError, setGlobalFilter } from '../common'
import {
  checkPlasticCard,
  completePlcWorkOrderFail,
  completePlcWorkOrderResponse,
  createPlcWorkOrderFail,
  createPlcWorkOrderResponse,
  declinePlcWorkOrderFail,
  declinePlcWorkOrderResponse,
  deletePlcWorkOrderFail,
  deletePlcWorkOrderResponse,
  getPlasticCardFail,
  getPlasticCardRequest,
  getPlasticCardResponse,
  getPlasticCardsFail,
  getPlasticCardsRequest,
  getPlasticCardsResponse,
  getPlasticCardState,
  getPlcCardChecked,
  getPlcCardFilter,
  getPlcCardPaging,
  getPlcCardSelected,
  getPlcCardSort,
  getPlcTeamsResponse,
  getPlcWorkOrderFilter,
  getPlcWorkOrderReportFail,
  getPlcWorkOrderReportResponse,
  getPlcWorkOrderReportsFail,
  getPlcWorkOrderReportsResponse,
  getPlcWorkOrderSelected,
  getPlcWorkOrdersFail,
  getPlcWorkOrdersRequest,
  getPlcWorkOrdersResponse,
  getWorkOrderSort,
  openPlcFilterModal,
  resetPlcOrdersPaging,
  resetPlcTeamFail,
  setPlasticCardFilter,
  setPlcTeamFail,
  setSelectedPlasticCard,
} from '.'

function* getPlasticCard(): SagaIterator {
  try {
    const globalFilter = yield select(getGlobalFilter)
    const filter = yield select(getPlcCardFilter)
    const selected = yield select(getPlcCardSelected)
    const sort = yield select(getPlcCardSort)
    const { page, pageSize } = yield select(getPlcCardPaging)

    const response: PlasticCardResponse = yield call(PlasticCardApi.getAll, {
      paging: {
        page,
        pageSize: pageSize === -1 ? 'All' : pageSize,
      },
      requestBody: {
        globalFilter,
        filter: { ...filter, orderStatuses: filter.orderStatuses ? [filter.orderStatuses] : [] },
      },
      sort,
    })

    yield put(getPlasticCardsResponse(response))

    if (response && selected) {
      const foundedEntry = (response.data || []).find(card => card.cpId === selected.cpId)

      if (foundedEntry) {
        yield put(setSelectedPlasticCard(foundedEntry))
      } else {
        yield put(setSelectedPlasticCard(null))
      }
    }
  } catch (error) {
    yield put(getPlasticCardsFail(error))
  }
}

function* getSinglePlasticCard({ payload: id }: AnyAction): SagaIterator {
  try {
    const selected = yield select(getPlcCardSelected)

    if (selected?.cpId || id) {
      const plasticCard: PlasticCard = yield call(PlasticCardApi.getSingle, id || selected.cpId)

      yield put(getPlasticCardResponse(plasticCard))
    }
  } catch (error) {
    yield put(getPlasticCardFail(error))
  }
}

function* setTeam({ payload: { cpId, teamId } }: AnyAction): SagaIterator {
  try {
    if (cpId && teamId) {
      yield call(PlasticCardApi.assignTeam, { cpId, teamId })

      yield put(getPlasticCardRequest(cpId))
    } else {
      yield put(setPlcTeamFail())
    }
  } catch (error) {
    yield put(setPlcTeamFail())
  }
}

function* resetTeam({ payload: { cpId, cardOrderId } }: AnyAction): SagaIterator {
  try {
    if (cpId) {
      yield call(PlasticCardApi.resetTeam, cardOrderId)

      yield put(getPlasticCardRequest(cpId))
    } else {
      yield put(resetPlcTeamFail())
    }
  } catch (error) {
    yield put(resetPlcTeamFail())
  }
}

function* handleFilterSubmit({ payload }: AnyAction): SagaIterator {
  yield put(setGlobalFilter(payload.globalFilter))
  yield put(setPlasticCardFilter(payload.filter))

  yield put(resetPlcOrdersPaging())
  yield put(setSelectedPlasticCard(null))

  yield put(getPlasticCardsRequest())
}

// Work orders
function* getWorkOrders(): SagaIterator {
  try {
    const filter = yield select(getPlcWorkOrderFilter)
    const globalFilter = yield select(getGlobalFilter)
    const sort = yield select(getWorkOrderSort)

    const response: PlasticCardsWorkOrdersResponse = yield call(PlasticCardWOApi.getAll, {
      requestBody: {
        ...filter,
        branchIds: globalFilter.filterIds || [],
      },
      sort,
    })

    yield put(getPlcWorkOrdersResponse(response.workOrders || []))
  } catch (error) {
    yield put(getPlcWorkOrdersFail(error))
  }
}
function* createWorkOrder(): SagaIterator {
  try {
    const selected = yield select(getPlcCardSelected)
    const checked = yield select(getPlcCardChecked)

    if (Object.keys(checked).length) {
      let ids = [] as string[]

      for (const key in checked) {
        if (checked[key]?.cardOrderId) {
          ids = [...ids, checked[key].cardOrderId]
        }
      }

      yield call(PlasticCardWOApi.create, ids)
    } else if (selected?.cardOrderId) {
      yield call(PlasticCardWOApi.create, [selected.cardOrderId])

      yield put(getPlasticCardRequest(selected.cpId))
    }

    yield put(createPlcWorkOrderResponse())
    yield put(getPlcWorkOrdersRequest())

    for (const plasticCardId in checked) {
      yield put(getPlasticCardRequest(plasticCardId))
    }
  } catch (error) {
    yield put(createPlcWorkOrderFail())
  }
}

function* declineWorkOrder({ payload }: AnyAction): SagaIterator {
  try {
    const selected = yield select(getPlcCardSelected)

    if (selected?.cardOrderId) {
      yield call(PlasticCardWOApi.decline, selected.cardOrderId, payload || '')
    }

    yield put(declinePlcWorkOrderResponse())
    yield put(getPlcWorkOrdersRequest())
    yield put(getPlasticCardsRequest())
  } catch (error) {
    yield put(declinePlcWorkOrderFail())
  }
}

function* deleteWorkOrder(): SagaIterator {
  try {
    const selected = yield select(getPlcCardSelected)

    if (selected?.cardOrderId) {
      yield call(PlasticCardWOApi.delete, selected.cardOrderId)
    }

    yield put(deletePlcWorkOrderResponse())
    yield put(getPlcWorkOrdersRequest())
    yield put(getPlasticCardsRequest())
  } catch (error) {
    yield put(deletePlcWorkOrderFail())
  }
}

function* completeWorkOrder({ payload }: AnyAction): SagaIterator {
  try {
    const selectedWo = yield select(getPlcWorkOrderSelected)

    if (selectedWo.id) {
      yield call(PlasticCardWOApi.complete, selectedWo.id, payload || false)
    }

    yield put(completePlcWorkOrderResponse())
    yield put(getPlcWorkOrdersRequest())
    yield put(getPlasticCardsRequest())
  } catch (error) {
    yield put(completePlcWorkOrderFail())
  }
}

function* getWoReports(): SagaIterator {
  try {
    const filter = yield select(getPlcWorkOrderFilter)

    const response = yield call(PlasticCardWOApi.getReports, { workOrdersFilter: filter })
    yield put(getPlcWorkOrderReportsResponse(response))
  } catch (e) {
    yield put(getPlcWorkOrderReportsFail(e?.message))
  }
}

function* getWoReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(CMCommonApi.getReport, payload)
      yield put(getPlcWorkOrderReportResponse(response))
    } else {
      yield put(getPlcWorkOrderReportResponse(null))
    }
  } catch (e) {
    yield put(getPlcWorkOrderReportFail(e?.message))
  }
}

function* getTeams(): SagaIterator {
  try {
    const response = yield call(PlasticCardApi.getTeam)

    yield put(getPlcTeamsResponse(response))
  } catch (error) {
    yield put(setComCMError(error))
  }
}

function* handleKeyPress({ payload }: AnyAction): SagaIterator {
  try {
    const {
      modals: { isFiltersModalOpen, isColumnModalOpen },
      plasticCard: { selected, data },
    } = yield select(getPlasticCardState)

    hotKeysBinder<PlasticCard>({
      key: payload,
      data,
      idName: 'cpId',
      selected,
      isOpenModalsList: [isFiltersModalOpen, isColumnModalOpen],
      setSelectedAction: setSelectedPlasticCard,
      onToggleAction: checkPlasticCard,
      openFilterAction: openPlcFilterModal,
      onRefreshAction: getPlasticCardsRequest,
    })
  } catch (error) {
    yield put(setComCMError(error))
  }
}

export default function*(): Generator {
  yield takeLatest(
    [
      CMActions.PlcGetPlasticCardsRequest,
      CMActions.PlcSetPlasticCardPaging,
      CMActions.PlcSetPlasticCardSort,
    ],
    getPlasticCard,
  )
  yield takeLatest(CMActions.PlcSubmitPlasticCardFilter, handleFilterSubmit)
  yield takeEvery(CMActions.PlcGetSinglePlasticCardRequest, getSinglePlasticCard)

  yield takeLatest(CMActions.PlcSetTeamRequest, setTeam)
  yield takeLatest(CMActions.PlcResetTeamRequest, resetTeam)

  yield takeLatest(CMActions.PlcHandleKeyPress, handleKeyPress)

  // WorkOrders
  yield takeLatest(
    [
      CMActions.PlcGetWorkOrdersRequest,
      CMActions.PlcSetWorkOrdersFilter,
      CMActions.PlcSetWorkOrderSort,
    ],
    getWorkOrders,
  )
  yield takeLatest(CMActions.PlcCreateWorkOrderRequest, createWorkOrder)
  yield takeLatest(CMActions.PlcDeclineWorkOrderRequest, declineWorkOrder)
  yield takeLatest(CMActions.PlcDeleteWorkOrderRequest, deleteWorkOrder)
  yield takeLatest(CMActions.PlcCompleteWorkOrderRequest, completeWorkOrder)

  // Reports
  yield takeLatest(CMActions.GetPlcWorkOrderReportsRequest, getWoReports)
  yield takeLatest(CMActions.GetPlcWorkOrderReportRequest, getWoReport)

  yield takeLatest(CMActions.GetPlcTeamsRequest, getTeams)
}
