import makeStyles from '@material-ui/core/styles/makeStyles'
import styled from 'styled-components'

import { styledComponentTheme } from '@/theme/styledComponentTheme'

export const useClasses = makeStyles(({ spacing }) => ({
  columnsWrapper: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
  },
  deviceTitle: {
    fontSize: '20px',
    marginBottom: spacing(2),
  },
  deviceName: {
    color: '#929A9B',
    marginRight: '15px',
    minWidth: '180px',
    wordBreak: 'break-word',
    lineHeight: '14px',
    maxWidth: '180px',
  },
  headerName: {
    marginRight: '15px',
    width: '180px',
  },
  deviceHeader: {
    height: '100%',
    overflowY: 'auto',
  },
  itemWrapper: {
    display: 'flex',
    minHeight: '36px',
    wordBreak: 'break-all',
    margin: '4px 0',
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
    padding: 0,
  },
  button: {
    background: 'transparent',
    fontWeight: 'normal',
    color: styledComponentTheme.colors.baseGreen,
    border: '1px solid',
    marginTop: spacing(3),
    '&:hover': {
      background: styledComponentTheme.colors.baseGreen,
      color: 'white',
      border: 0,
    },
  },
  finishedIcon: {
    color: '#F2994A',
  },
  preparedIcon: {
    color: '#F2C94C',
  },
  runningIcon: {
    color: '#2F80ED',
  },
  submittedIcon: {
    color: '#99BE54',
  },
  suspendedIcon: {
    color: '#EB5757',
  },
  deadlineGreen: {
    color: '#1AD36F',
  },
  deadlineRed: {
    color: '#CB362D',
  },
  cellWrapper: {
    padding: spacing(0.25, 1),
    marginRight: spacing(4),
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: spacing(1.5),
  },
  cellIcon: {
    width: spacing(1.5),
    marginRight: spacing(1),
  },
  iconSize: {
    fontSize: styledComponentTheme.fontSizes.big,
  },
  // 00c563
  finishedFolderWrapper: {
    background: '#feefe7',
  },
  okFolderWrapper: {
    background: '#e6faf0',
  },
  preparedFolderWrapper: {
    background: '#fefaee',
  },
  runningFolderWrapper: {
    background: '#eaf3ff',
  },
  submittedFolderWrapper: {
    background: '#f5f8ee',
  },
  suspendedFolderWrapper: {
    background: '#ffe9ed',
  },

  tableHeader: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },

  tableHeaderButton: {
    border: '1px solid',
    borderColor: styledComponentTheme.colors.baseGreen,
    width: '40px',
    height: '40px',
    padding: '0',
    minWidth: 'auto',
    marginRight: '16px',

    '& .MuiButton-startIcon': {
      margin: '0',
    },
  },

  tableHeaderButtonDisabled: {
    opacity: 0.4,
  },

  toolTip: {
    background: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    color: 'black',
    padding: spacing(1),
    fontSize: '14px',
    top: spacing(1),
  },
}))

export const ContentWrapper = styled.div`
  background-color: #ffffff;
  flex: 1;
  height: calc(100% - 20px);
  width: 100%;
  box-shadow: 0 0 15px rgba(37, 173, 191, 0.05);
  border-radius: 3px;
  padding: 10px 24px;
  margin-top: 20px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
