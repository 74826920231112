import { AnyAction } from 'redux'
import { WindowsEditActions } from '@/constants/actions'

export const toggleMoveSplitPaneable = (splitPane: boolean): AnyAction => ({
  type: WindowsEditActions.ToggleMoveSplitPaneable,
  payload: splitPane,
})

export const setContentWrapperWidthProvider = (width: number): AnyAction => ({
  type: WindowsEditActions.SetContentWrapperWidth,
  payload: width,
})

export const setSplitPaneHeightPosition = (height: number): AnyAction => ({
  type: WindowsEditActions.SetSplitPaneHeightPosition,
  payload: height,
})

export const setSplitPaneWidhtPosition = (width: number): AnyAction => ({
  type: WindowsEditActions.SetSplitPaneWidhtPosition,
  payload: width,
})

export const closePropertiesWindow = (): AnyAction => ({
  type: WindowsEditActions.ClosePropertiesWindow,
})

export const openPropertiesWindow = (): AnyAction => ({
  type: WindowsEditActions.OpenPropertiesWindow,
})

export const toggleDrawer = (): AnyAction => ({
  type: WindowsEditActions.ToggleDrawer,
})
