import { isEqual } from 'lodash-es'

import DashboardIcon from '@/assets/atmeye/icons/dashboard.svg'
import AddIcon from '@/assets/atmeye/icons/dashboardHeader/add_circle.svg'
import { AppNavigationRoutes, ATMEYE_BLOCK_PREFIX } from '@/constants'
import { ActionsDashboard } from '@/constants/atmeye/actions/actionsDashboard'
import { actionsATMEyeDashboard } from '@/store/atmeye/dashboard/actions'
import { ReturnAnyActionsUSM } from '@/store/usm/types/types'
import {
  AllSubpagesResponseType,
  CurrentSubPagesTypes,
  DevicesViewData,
  EventsViewData,
  ItemsWidgetTypes,
  ResponseWidgetDataInfo,
  SubItemsTypes,
  WidgetHeaderTypesResponse,
} from '@/types/atmeye/dashboardTypes'
import { ResponseErrorTypes } from '@/types/common/ErrorResponseTypes'
const SUB_PAGES_BASE_URL = `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.dashboard.root}/`

const updateSubpagesState = (
  subItems: SubItemsTypes,
  payload: AllSubpagesResponseType,
): SubItemsTypes => {
  const { subpageId, subpageName } = payload

  const index = subItems.findIndex(
    item => item.path.split('/').pop() === payload.subpageId.toString(),
  )
  return [
    ...subItems.map((item, id) =>
      id === index ? { path: `${SUB_PAGES_BASE_URL}${subpageId}`, label: subpageName } : item,
    ),
  ]
}

type SubpagesType = {
  label: string
  Icon: string
  path: string
  subItems: SubItemsTypes
}

const initialState = {
  isAllowCreateNewPage: false,
  headerWidgetData: null as WidgetHeaderTypesResponse | null,
  subPages: {
    label: 'translate#atmeye.title.dashboard',
    path: '',
    Icon: DashboardIcon,
    subItems: [],
  } as SubpagesType,
  currentSubPagesData: null as CurrentSubPagesTypes | null,
  clonedPageName: '',
  currentFilterData: null as ResponseWidgetDataInfo | null,
  subPageWidgets: [] as Array<ItemsWidgetTypes>,
  devicesViewData: [] as DevicesViewData,
  eventsViewData: [] as EventsViewData,
  isFetchingDeleteSubPagesWidgets: false,
  isFetchingCurrentSubPagesData: false,
  isFetchingHeaderData: false,
  isFetchingSubpages: false,
  isFetchingFilterWidgetData: false,
  isFetchingPageWidgetData: true,
  triggerResetForm: false,
  isFetchingWidgetPageData: false,
  isFetchingWidgetViewData: [] as number[],
  viewDataIdsToFetch: [] as number[],
  error: null as ResponseErrorTypes | null,
}

export type State = typeof initialState
export type DashboardAnyAction = ReturnAnyActionsUSM<typeof actionsATMEyeDashboard>

export const reducer = (state: State = initialState, action: DashboardAnyAction): State => {
  switch (action.type) {
    case ActionsDashboard.StartFetchingHeaderWidgetData:
      return {
        ...state,
        isFetchingHeaderData: true,
      }
    case ActionsDashboard.StopFetchingHeaderWidgetData:
      return {
        ...state,
        isFetchingHeaderData: false,
      }
    case ActionsDashboard.FillHeaderWidgetDataSuccess:
      return {
        ...state,
        error: null,
        headerWidgetData: action.payload,
      }
    case ActionsDashboard.StartFetchingAllSubpages:
      return {
        ...state,
        isFetchingSubpages: true,
      }
    case ActionsDashboard.StopFetchingAllSubpages:
      return {
        ...state,
        isFetchingSubpages: false,
      }
    case ActionsDashboard.FillAllSubPagesError:
      return {
        ...state,
        error: action.payload,
      }
    case ActionsDashboard.FillHeaderWidgetDataError:
      return {
        ...state,
        error: action.payload,
      }
    case ActionsDashboard.FillAllSubPagesSuccess:
      // eslint-disable-next-line no-case-declarations
      const subItems: SubItemsTypes = [
        ...action.payload.map(el => ({
          path: `${SUB_PAGES_BASE_URL}${el.subpageId}`,
          label: el.subpageName,
        })),
      ]

      if (state.isAllowCreateNewPage) {
        subItems.push({
          path: ``,
          label: 'translate#atmeye.title.add.sub.page',
          icon: AddIcon,
        })
      }

      return {
        ...state,
        error: null,
        subPages: {
          ...state.subPages,
          subItems,
        },
      }
    case ActionsDashboard.AllowCreationOfNewPages:
      return {
        ...state,
        isAllowCreateNewPage: true,
        error: null,
        subPages: !state.isAllowCreateNewPage
          ? {
              ...state.subPages,
              subItems: [
                ...state.subPages.subItems,
                {
                  path: ``,
                  label: 'translate#atmeye.title.add.sub.page',
                  icon: AddIcon,
                },
              ],
            }
          : state.subPages,
      }
    case ActionsDashboard.FillAddNewSubPageSuccess: {
      if (!action.payload) {
        return state
      }
      const { subpageName, subpageId } = action.payload
      const subPages = [...state.subPages.subItems]
      subPages.splice(-1, 0, {
        label: subpageName,
        path: `${SUB_PAGES_BASE_URL}${subpageId}`,
      })

      return {
        ...state,
        error: null,
        subPages: {
          ...state.subPages,
          subItems: subPages,
        },
      }
    }
    case ActionsDashboard.FillEditSubPageSuccess: {
      // temp
      if (!action.payload || !action.payload?.subpageName) {
        return state
      }

      const { subpageName } = action.payload
      return {
        ...state,
        error: null,
        subPages: {
          ...state.subPages,
          subItems: updateSubpagesState(state.subPages.subItems, action.payload),
        },
        currentSubPagesData: state.currentSubPagesData
          ? { ...state.currentSubPagesData, name: subpageName }
          : state.currentSubPagesData,
      }
    }
    case ActionsDashboard.FillEditMySubpageSuccess: {
      // temp
      if (!action.payload || !action.payload?.subpageName) {
        return state
      }

      const { subpageName } = action.payload
      return {
        ...state,
        error: null,
        subPages: {
          ...state.subPages,
          subItems: updateSubpagesState(state.subPages.subItems, action.payload),
        },
        currentSubPagesData: state.currentSubPagesData
          ? { ...state.currentSubPagesData, name: subpageName }
          : state.currentSubPagesData,
      }
    }

    case ActionsDashboard.FillSubPageCloneSuccess: {
      if (!action.payload || !action.payload?.subpageName) {
        return state
      }
      const { subpageName, subpageId } = action.payload
      const subPages = [...state.subPages.subItems]
      subPages.splice(-1, 0, {
        label: subpageName,
        path: `${SUB_PAGES_BASE_URL}${subpageId}`,
      })

      return {
        ...state,
        error: null,
        subPages: {
          ...state.subPages,
          subItems: subPages,
        },
        clonedPageName: action.payload.subpageName,
      }
    }
    case ActionsDashboard.CloseClonedPageModal: {
      return {
        ...state,
        clonedPageName: '',
      }
    }
    case ActionsDashboard.FillDeleteSubPageSuccess: {
      return {
        ...state,
        error: null,
        subPages: {
          ...state.subPages,
          subItems: state.subPages.subItems.filter(
            el => el.path.split('/').pop() !== action.payload.toString(),
          ),
        },
      }
    }
    case ActionsDashboard.StartFetchingFullSubPagesData:
      return {
        ...state,
        isFetchingCurrentSubPagesData: true,
      }
    case ActionsDashboard.StopFetchingFullSubPagesData:
      return {
        ...state,
        isFetchingCurrentSubPagesData: false,
      }
    case ActionsDashboard.FillFullSubPagesDataSuccess: {
      // todo isEqual need to be fixed by ATIQ2-1921
      const isEqualData = isEqual(state.currentSubPagesData, action.payload)
      if (!isEqualData) {
        return {
          ...state,
          error: null,
          currentSubPagesData: action.payload,
        }
      } else {
        return state
      }
    }

    case ActionsDashboard.ClearSubPageErrors:
      return {
        ...state,
        error: null,
      }

    case ActionsDashboard.FillFullSubPagesWidgetsSuccess: {
      const isEqualData = isEqual(state.subPageWidgets, action.payload.widgets)

      if (!isEqualData) {
        return {
          ...state,
          error: null,
          subPageWidgets: action.payload.widgets,
        }
      } else {
        return state
      }
    }

    case ActionsDashboard.FillAllSuccessViewData: {
      const filterByUniqueId = (value: { widgetId: number }, index: number, self: any[]) => {
        return self.findIndex((v: { widgetId: number }) => v.widgetId === value.widgetId) === index
      }
      return {
        ...state,
        eventsViewData: [...action.payload.eventsCountWidgets, ...state.eventsViewData].filter(
          filterByUniqueId,
        ),
        devicesViewData: [...action.payload.devicesCountWidgets, ...state.devicesViewData].filter(
          filterByUniqueId,
        ),
        viewDataIdsToFetch: [],
      }
    }

    case ActionsDashboard.StartFetchingWidgetFilterData:
      return {
        ...state,
        isFetchingFilterWidgetData: true,
        triggerResetForm: false,
      }
    case ActionsDashboard.StopFetchingWidgetFilterData:
      return {
        ...state,
        isFetchingFilterWidgetData: false,
        triggerResetForm: false,
      }
    case ActionsDashboard.FillAllSuccessWidgetFilterData:
      return {
        ...state,
        error: null,
        currentFilterData: action.payload,
      }

    case ActionsDashboard.FillAllSuccessDeleteDashboardWidget:
      return {
        ...state,
        subPageWidgets: state.subPageWidgets.filter(({ widgetId }) => widgetId !== action.payload),
      }

    case ActionsDashboard.ClearStateFilterDataWidget:
      return {
        ...state,
        error: null,
        currentFilterData: null,
      }
    case ActionsDashboard.StartFetchingDeleteDashboardWidget:
      return {
        ...state,
        isFetchingDeleteSubPagesWidgets: true,
      }

    case ActionsDashboard.StopFetchingDeleteDashboardWidget:
      return {
        ...state,
        isFetchingDeleteSubPagesWidgets: false,
      }

    case ActionsDashboard.FillAllSuccessEditFilterData: {
      return {
        ...state,
        viewDataIdsToFetch: [...state.viewDataIdsToFetch, action.payload.widgetId],
        triggerResetForm: true,
        subPageWidgets: state.subPageWidgets.map(item =>
          item.widgetId === action.payload.widgetId
            ? {
                ...item,
                widgetTitle: action.payload.widgetTitle,
              }
            : item,
        ),
      }
    }

    case ActionsDashboard.FillAllSuccessCreateFilterData: {
      return {
        ...state,
        viewDataIdsToFetch: [...state.viewDataIdsToFetch, action.payload.widgetId],
        triggerResetForm: true,
        subPageWidgets: [...state.subPageWidgets, action.payload],
      }
    }

    case ActionsDashboard.startFetchingPageWidgets: {
      return {
        ...state,
        isFetchingPageWidgetData: true,
      }
    }

    case ActionsDashboard.stopFetchingPageWidgets: {
      return {
        ...state,
        isFetchingPageWidgetData: false,
      }
    }

    case ActionsDashboard.StartFetchingViewFilterData: {
      return {
        ...state,
        isFetchingWidgetViewData: action.payload,
      }
    }

    case ActionsDashboard.StopFetchingViewFilterData: {
      return {
        ...state,
        isFetchingWidgetViewData: [],
      }
    }

    case ActionsDashboard.resetWidgetConfig: {
      if (!state.currentFilterData) {
        return { ...state }
      }

      return {
        ...state,
        currentFilterData: {
          ...state.currentFilterData,
          filtersData: {
            ...state.currentFilterData?.filtersData,
            countries: [],
            cities: [],
            vendor: null,
            vendors: [],
            deviceModels: [],
            deviceTypes: [],
            active: false,
            lastActivityDateTimeInterval: null,
            lastStateDateTimeInterval: null,
            events: [],
            eventDate: '',
            deviceName: '',
          },
        },
      }
    }

    default:
      return state
  }
}
