// core
import React, { FC } from 'react'
import { Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
// types
import { CancelButtonPropsTypes, Props } from './types'
import Button from '@/components/controls/Button'

const CreatedCancelButton: FC<CancelButtonPropsTypes> = ({
  style,
  handleClose,
}: CancelButtonPropsTypes) => {
  return (
    <Button className={style} onClick={handleClose}>
      <Typography>
        <FormattedMessage id="usm.title.add.user.modal.action.сancel" defaultMessage="Cancel" />
      </Typography>
    </Button>
  )
}

const CreateButton: FC<Props> = ({
  isDisabled,
  translate,
  callBack,
  styleForButtons,
  value,
}: Props) => {
  return (
    <Button
      type={!value ? 'submit' : undefined}
      onClick={callBack ? callBack(value) : undefined}
      className={styleForButtons}
      disabled={isDisabled}
      variant={!isDisabled ? 'outlined' : 'contained'}
      palette="primary"
    >
      <Typography>
        <FormattedMessage id={translate.id} defaultMessage={translate.message} />
      </Typography>
    </Button>
  )
}

export { CreateButton, CreatedCancelButton }
