import React, { useMemo } from 'react'
import { PieChart, Pie, Cell, Legend, LegendProps, ResponsiveContainer, Tooltip } from 'recharts'
import { useClasses } from './styles'
import clsx from 'clsx'
import { TICKET_STATUSES } from '@/constants/ticketStatuses'
import { Props } from './types'
import Label from './components/Label/component'
import { useSelector } from 'react-redux'
import {
  getPieChartData,
  getPieChartDataTotal,
  getIsLookingForChartData,
} from '@/store/dashboard/selectors'
import { useTranslate } from '@/utils/internalization'
import Loader from '@/components/blocks/Loader'

const OUTER_RADIUS = 90
const WIDTH_OF_PIE = 30

const CustomPieChart = ({ height, isEditing }: Props): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const data = useSelector(getPieChartData)
  const totalCount = useSelector(getPieChartDataTotal)
  const isLookingForChartData = useSelector(getIsLookingForChartData)

  const renderLegend = (props: LegendProps): React.ReactElement => {
    const { payload } = props

    return (
      <div className={classes.legendWrapper}>
        <div>
          {payload?.slice(0, 6).map(({ value }, index) => {
            const status = TICKET_STATUSES[value]

            return (
              <div
                key={`item-${index}`}
                className={classes.legendItem}
                style={{ backgroundColor: status.backgroundColor }}
              >
                <div
                  className={classes.legendItemCircle}
                  style={{ backgroundColor: status.color }}
                />
                <span>
                  {translate(`translate#title.${value}`)} (
                  {data.find(item => item.name === value)?.value})
                </span>
              </div>
            )
          })}
        </div>
        <div>
          {payload?.slice(6).map(({ value }, index) => {
            const status = TICKET_STATUSES[value]

            return (
              <div
                key={`item-${index}`}
                className={classes.legendItem}
                style={{ backgroundColor: status.backgroundColor }}
              >
                <div
                  className={classes.legendItemCircle}
                  style={{ backgroundColor: status.color }}
                />
                <span>
                  {translate(`translate#title.${value}`)} (
                  {data.find(item => item.name === value)?.value})
                </span>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const filteredData = useMemo(
    () =>
      data
        .filter(item => {
          return !!item.value
        })
        .sort((i, o) => i.value - o.value),
    [data],
  )

  const CustomTooltip = ({ payload }: any): any => {
    return (
      <div className={classes.customTooltip}>
        {translate(`translate#title.${payload?.[0]?.payload?.name}`)}:{payload?.[0]?.payload?.value}
      </div>
    )
  }

  return (
    <ResponsiveContainer height={height} width={isLookingForChartData ? '100%' : '65%'}>
      {!isLookingForChartData ? (
        <PieChart
          className={clsx({
            [classes.grabCursor]: isEditing,
            [classes.pointerCursor]: !isEditing,
          })}
        >
          <Pie
            isAnimationActive={false}
            data={filteredData}
            innerRadius={OUTER_RADIUS - WIDTH_OF_PIE}
            outerRadius={OUTER_RADIUS}
            fill="#8884d8"
            paddingAngle={filteredData.length > 1 ? 5 : 0}
            dataKey="value"
            label={props => <Label {...props} totalCount={totalCount} />}
            labelLine={false}
            startAngle={90}
            endAngle={450}
          >
            {filteredData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={TICKET_STATUSES[entry.status].color} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend layout="vertical" verticalAlign="middle" align="left" content={renderLegend} />
        </PieChart>
      ) : !isLookingForChartData && filteredData.length === 0 ? (
        <></>
      ) : (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      )}
    </ResponsiveContainer>
  )
}

export default React.memo(CustomPieChart)
