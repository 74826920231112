import { LookupsResponse } from '@/api/common/commonApi/types'

export type BackupValue = { value: string; name: string }
export type BackupValues = { [key: string]: BackupValue }

export enum FieldTypeName {
  Text = 'TEXT',
  List = 'LIST',
  MultiSelect = 'MULTISELECT',
  Search = 'SEARCH_LIST',
  Checkbox = 'CHECKBOX',
  Date = 'DATE',
  RadioGroup = 'RADIO_GROUP',
}

type FieldType =
  | FieldTypeName.Text
  | FieldTypeName.List
  | FieldTypeName.MultiSelect
  | FieldTypeName.Search
  | FieldTypeName.Checkbox
  | FieldTypeName.Date
  | FieldTypeName.RadioGroup

export interface Props {
  field: {
    type: FieldType
    options: {
      label: string
      placeholder: string
      required?: boolean
      requestDelay?: number
      isNumber: boolean
    }
  }
  fieldName: string
  fieldTitle: string
  defaultValue?: { value: number | string | null; name: string | null }
  setFormikFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  isDisabled: boolean
  isDependsOn: boolean
  dependsOn?: any[]
  onCall: (value: string) => Promise<LookupsResponse>
  onSelect?: (value: any) => void
  className?: string
}
