import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { difference, isEqual, remove } from 'lodash-es'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Test from 'react-test-attributes'

import { TicketsApi } from '@/api/sd/ticketsApi/index'
import Edit from '@/assets/sd/edit.svg'
// import { Create, Save } from '@material-ui/icons'
import Save from '@/assets/sd/save.svg'
import CloseButton from '@/components/blocks/CloseButton/index'
import Button from '@/components/controls/Button/index'
import { Select } from '@/components/controls/Select/component'
import {
  detectTicketTabLocation,
  groupTemplateApiResponseToUi,
} from '@/components/pages/sd/helpers/sdHelpers'
import EditGroupModal from '@/components/pages/sd/TicketsPage/components/GroupingModal/components/EditGroupModal/component'
import { GroupRow } from '@/components/pages/sd/TicketsPage/components/GroupingModal/components/GroupRow/component'
import SaveGroupModal from '@/components/pages/sd/TicketsPage/components/GroupingModal/components/SaveGroupModal/component'
import {
  changeGrouping,
  getGrouping,
  getGroupingTemplates,
  getSdGroupTemplatesRequest,
  getTicketsConfigData,
  getTicketsRequest,
  isFetchingGroupTemplates,
  setTicketsPage,
} from '@/store/sd/tickets/index'
import { TicketsConfigData } from '@/types'
import { Group } from '@/types/sd/tickets/config'
import { getTestId } from '@/utils'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import { Props, TableGroup } from './types'

const groupLevel = 4

const initialGroups = new Array(groupLevel).fill(undefined)

const GroupingModal = ({ testIdPrefix, open, handleClose }: Props): React.ReactElement => {
  const idPrefix = `${testIdPrefix}-grouping-modal`
  const testId = getTestId(idPrefix)
  const translate = useTranslate()
  const dispatch = useDispatch()
  const location = useLocation()
  const grouping = useSelector(getGrouping)
  const isLoadingTemplates = useSelector(isFetchingGroupTemplates)
  const templates = useSelector(getGroupingTemplates)
  const ticketsConfig: TicketsConfigData = useSelector(getTicketsConfigData)
  const [initialFormValues, setInitialFormValues] = useState<Array<TableGroup | undefined>>(
    initialGroups,
  )
  const [groups, setGroups] = useState<Array<TableGroup | undefined>>(initialGroups)
  const [fields, setFields] = useState<string[]>([])
  const [showModal, setShowModal] = useState<'main' | 'save' | 'edit'>('main')
  const [currentTemplate, setCurrentTemplate] = useState('')
  const [applyIsDisabled, setApplyIsDisabled] = useState<boolean>(true)
  const [clearIsDisabled, setClearIsDisabled] = useState<boolean>(true)
  const [savedTemplate, setSavedTemplate] = useState<string>('')
  const classes = useClasses()

  const getWindowType = useCallback(() => detectTicketTabLocation(location), [location])

  useEffect(() => {
    setInitialFormValues([...grouping, ...new Array(groupLevel - grouping.length).fill(undefined)])
  }, [grouping, groupLevel])

  useEffect(() => {
    if (open) {
      setGroups(initialFormValues)
    }
  }, [open, initialFormValues])

  useEffect((): void => {
    const windowType = getWindowType()
    dispatch(getSdGroupTemplatesRequest(windowType))
  }, [dispatch])

  useEffect(() => {
    if (ticketsConfig.groups.length) {
      setFields(ticketsConfig.groups.map(group => group.groupUID))
    }
  }, [ticketsConfig.groups.length, ticketsConfig.groups])

  const getGroupTemplateConfig = useCallback(
    (id: string) => TicketsApi.getSdGroupTemplateConfigById(id, getWindowType()),
    [templates],
  )

  useEffect(() => {
    setApplyIsDisabled(isEqual(initialFormValues, groups))
  }, [initialFormValues, groups])

  useEffect(() => {
    setClearIsDisabled(
      groups.every(function(item) {
        return item === undefined
      }),
    )
  }, [groups])

  useEffect(() => {
    if (groups[0] === undefined) setCurrentTemplate('')
  }, [groups])

  const handleCurrentTemplateChange = useCallback(
    (e: ChangeEvent<{ value: any }> | string): void => {
      if (typeof e === 'object' && !e.target.value) {
        setGroups(initialGroups)
        return setCurrentTemplate('')
      }

      const foundTemplate = templates.find(
        (template: any) => template.id === (typeof e === 'object' ? e.target.value : e),
      )
      if (foundTemplate) {
        getGroupTemplateConfig(foundTemplate.id).then((value: any) => {
          const parsedGroups = groupTemplateApiResponseToUi(value.groupingLevels)
          if (parsedGroups.length === groupLevel) {
            setGroups(parsedGroups)
            setCurrentTemplate(typeof e === 'object' ? e.target.value : e)
          } else {
            const requiredAmount = groupLevel - parsedGroups.length
            setGroups([...parsedGroups, ...initialGroups.slice(0, requiredAmount)])
            setCurrentTemplate(typeof e === 'object' ? e.target.value : e)
          }
        })
      }
    },
    [templates],
  )

  useEffect(() => {
    if (savedTemplate) {
      const template = templates.find(({ name }: { name: string }) => name === savedTemplate)

      if (template) {
        handleCurrentTemplateChange(template.id)
      }
    }
  }, [savedTemplate, templates, handleCurrentTemplateChange])

  const handleGoToModal = useCallback(
    (screen: 'main' | 'save' | 'edit'): (() => void) => (): void => {
      setShowModal(screen)
    },
    [],
  )

  const handleGroupChange = useCallback(
    (index: number): ((group?: TableGroup) => void) => (group): void => {
      setGroups([...groups.slice(0, index), group, ...groups.slice(index + 1, groupLevel)])
    },
    [groups, groupLevel],
  )

  const handleGroupApply = useCallback((): void => {
    dispatch(setTicketsPage(1))
    dispatch(changeGrouping(groups.filter((group): group is TableGroup => !!group)))
    dispatch(getTicketsRequest())

    handleClose()
  }, [handleClose, groups])

  const handleGroupClear = useCallback((): void => {
    setGroups([...initialGroups])
  }, [initialGroups])

  const getUniqueFields = useCallback(
    (index: number): (() => string[]) => (): string[] => {
      let excludedOptions: string[] = []
      if (ticketsConfig.groups.length) {
        ticketsConfig.groups.forEach((gr: Group) => {
          const groupPreviouslySelected = groups.find((tGr: TableGroup | undefined) =>
            tGr && tGr.key === gr.groupUID ? tGr : undefined,
          )
          if (gr.excludeChildGroupUID.length && groupPreviouslySelected) {
            excludedOptions = excludedOptions.concat(gr.excludeChildGroupUID)
          }
        })
      }
      const fieldsUsesByAllGroups = groups.filter(g => g).map(group => group?.key)
      const fieldUsedByCurrentGroup: string | undefined = groups[index]?.key
      const uniqueFields: string[] = difference(
        fields as string[],
        fieldsUsesByAllGroups as string[],
      )

      if (fieldUsedByCurrentGroup) {
        return [fieldUsedByCurrentGroup, ...uniqueFields]
      }
      return remove(uniqueFields, (element: any) => !excludedOptions.includes(element))
    },
    [groups, fields],
  )

  const hasGroupingChanges = groups.filter(group => group).length > 0

  return (
    <Test id={testId(0)}>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        keepMounted
        onClose={handleClose}
        disableBackdropClick
      >
        <Test id={testId(1)}>
          <div className={classes.wrapper}>
            <Test id={testId(2)}>
              <CloseButton absolute className={classes.closeButton} onClick={handleClose} />
            </Test>
            {showModal === 'main' && (
              <>
                <Test id={testId(3)}>
                  <Typography className={classes.header} variant="h5">
                    <FormattedMessage id="table.header.button.grouping" defaultMessage="Grouping" />
                  </Typography>
                </Test>
                {groups.map((group, i) => (
                  <GroupRow
                    key={i}
                    testIdPrefix={`${idPrefix}-${i}`}
                    disabled={i !== 0 && !groups[i - 1]}
                    fields={getUniqueFields(i)()}
                    group={group}
                    label={
                      i > 0
                        ? translate('translate#title.thenGroupingBy')
                        : translate('translate#title.groupingBy')
                    }
                    setGroup={handleGroupChange(i)}
                  />
                ))}
                <Test id={testId(4)}>
                  <Typography variant="h5" className={classes.savedGroupings}>
                    <FormattedMessage id="title.savedGroupings" defaultMessage="Saved Groupings" />
                  </Typography>
                </Test>
                <Test id={testId(5)}>
                  <Select
                    className={classes.selectButton}
                    label={translate('translate#title.template')}
                    value={currentTemplate}
                    isLoading={isLoadingTemplates}
                    onChange={handleCurrentTemplateChange}
                    options={templates.map((template: any) => ({
                      name: template.name,
                      value: template.id,
                    }))}
                    neverDisable
                  />
                </Test>
                <Test id={testId(6)}>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Test id={testId(7)}>
                      <Grid item>
                        <Test id={testId(8)}>
                          <Button
                            width="xs"
                            className={classes.iconButton}
                            onClick={handleGoToModal('edit')}
                            variant="outlined"
                            disabled={!currentTemplate}
                          >
                            <img src={Edit} />
                          </Button>
                        </Test>
                        <Test id={testId(10)}>
                          <Button
                            width="xs"
                            className={classes.iconButton}
                            onClick={handleGoToModal('save')}
                            variant="outlined"
                            disabled={!hasGroupingChanges || !!currentTemplate}
                          >
                            <img src={Save} />
                          </Button>
                        </Test>
                      </Grid>
                    </Test>
                    <Test id={testId(11)}>
                      <Grid item>
                        <Button
                          className={classes.clearButton}
                          onClick={handleGroupClear}
                          variant="contained"
                          disabled={clearIsDisabled}
                        >
                          <FormattedMessage id="action.clear" defaultMessage="Clear" />
                        </Button>
                        <Button
                          className={classes.applyButton}
                          onClick={handleGroupApply}
                          variant="contained"
                          disabled={applyIsDisabled}
                        >
                          <FormattedMessage id="action.apply" defaultMessage="Apply" />
                        </Button>
                      </Grid>
                    </Test>
                  </Grid>
                </Test>
              </>
            )}
            {showModal === 'save' && (
              <SaveGroupModal
                classes={classes}
                currentGrouping={groups.filter((group): group is TableGroup => !!group)}
                testIdPrefix={idPrefix}
                handleClose={handleGoToModal('main')}
                setSavedTemplate={setSavedTemplate}
              />
            )}
            {showModal === 'edit' && (
              <EditGroupModal
                classes={classes}
                currentGrouping={groups.filter((group): group is TableGroup => !!group)}
                templateId={currentTemplate}
                templateName={
                  (
                    templates.find((template: any) => template.id === currentTemplate) || {
                      name: '',
                    }
                  ).name
                }
                testIdPrefix={idPrefix}
                setGroups={setGroups}
                initialGroups={initialGroups}
                handleClose={handleGoToModal('main')}
                setSavedTemplate={setSavedTemplate}
              />
            )}
          </div>
        </Test>
      </Dialog>
    </Test>
  )
}

export default GroupingModal
