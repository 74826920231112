export const monCashPointsTableId = 'monCashPointsTable'
export const monCashPointCassettesTable = 'monCashPointCassettesTable'

export const balCashPointsTableId = 'balCashPointsTable'
export const citBalCashPointsTableId = 'citBalCashPointsTable'
export const citPlnOrdersPanelId = 'citPlnOrdersPanel'
export const ordOrdersTableId = 'ordOrdersTable'
export const ordOrdersCassettesTableId = 'ordOrdersCassettesTableId'
export const plnCashPointsTableId = 'plnCashPointsTable'
export const icsOrdersPanelId = 'icsOrdersPanel'
export const plasticCardTableId = 'plcCardTable'

export const routesTableId = 'rtsRoutesTable'
