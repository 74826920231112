export enum MapActions {
  GetMapFilterConfigRequest = '@/GET_MAP_FILTER_CONFIG_REQUEST',
  GetMapFilterConfigResponse = '@/GET_MAP_FILTER_CONFIG_RESPONSE',
  GetMapFilterConfigResponseFail = '@/GET_MAP_FILTER_CONFIG_FAIL',

  GetMapTicketsRequest = '@/GET_MAP_TICKETS_REQUEST',
  GetMapTicketsResponse = '@/GET_MAP_TICKETS_RESPONSE',
  GetMapTicketsResponseFail = '@/GET_MAP_TICKETS_FAIL',

  ChangeSelectedFilters = '@/SDMAP_SEARCH_FORM/CHANGE_SELECTED_FILTERS',
  ClearSelectedFilters = '@/SDMAP_SEARCH_FORM/CLEAR_SELECTED_FILTERS',

  SetAutoRefreshTimer = '@/SET_AUTO_REFRESH_TIMER',

  GetMapFiltersTemplatesRequest = '@/GET_MAP_FILTERS_TEMPLATES_REQUEST',
  GetMapFiltersTemplatesResponse = '@/GET_MAP_FILTERS_TEMPLATES_RESPONSE',
  GetMapFiltersTemplatesResponseFail = '@/GET_MAP_FILTERS_TEMPLATES_RESPONSE_FAIL',
}
