import { Box, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import React, { Component, ErrorInfo } from 'react'

import Button from '@/components/controls/Button'
import { stylesErrorBoundary } from '@/components/wrappers/ErrorBoundary/ErrorBoundaryStyle'
import { history } from '@/Router'
import { translate } from '@/utils/internalization'

import { ErrorBoundaryProps, State } from './types'
import { ErrorBoundaryContext } from './useErrorHandler'

const isDev = process.env.NODE_ENV === 'development'

class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { error: null, errorInfo: null }

    this.handleError = this.handleError.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  handleReload = (): void => {
    const { fallbackRedirect, withRedirectToBase = false } = this.props

    if (withRedirectToBase) {
      const { pathname } = window.location
      const pathArray = pathname.split('/')
      const [, baseUrl] = pathArray

      history.push(fallbackRedirect || `/${baseUrl}`)
    }

    window.location.reload()
  }

  handleReset = (): void => {
    const { beforeReset } = this.props

    if (beforeReset) {
      beforeReset()
    }

    this.setState({ error: null, errorInfo: null })
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const { onError } = this.props

    if (onError) {
      onError(error)
    }

    this.setState({ error, errorInfo })
  }

  handleError = (error?: Error | unknown): void => {
    const { onError } = this.props

    const parsedError = (error as Error) || Error('Something wrong')
    const errorInfo = { componentStack: parsedError.stack || '' }

    if (onError) {
      onError(error)
    }

    this.setState({
      error: parsedError,
      errorInfo,
    })
  }

  render(): React.ReactElement {
    const {
      state: { error, errorInfo },
      props: { classes, children, FallbackComponent, handleReload },
    } = this

    if (error && FallbackComponent) {
      return (
        <FallbackComponent
          error={error}
          handleReload={handleReload || this.handleReload}
          onResetErrorBoundary={this.handleReset}
        />
      )
    }

    if (errorInfo) {
      return (
        <div className={classes.root}>
          <Alert severity="error" variant="outlined">
            {translate('translate#title.SomethingWentWrong')}
            &nbsp;&#129301; {/* confused smile 🤕 */}
          </Alert>
          <div className={classes.buttons}>
            <Button onClick={handleReload || this.handleReload} palette="danger" variant="outlined">
              {translate('translate#title.ReloadPage')}
            </Button>
            <Button onClick={this.handleReset} palette="danger" variant="outlined">
              {translate('translate#title.reset')}
            </Button>
          </div>
          {isDev && (
            <Box className={classes.description}>
              <Typography>{error?.stack}</Typography>
            </Box>
          )}
        </div>
      )
    }

    return (
      <ErrorBoundaryContext.Provider value={{ handleError: this.handleError }}>
        {children}
      </ErrorBoundaryContext.Provider>
    )
  }
}

export default withStyles(stylesErrorBoundary)(ErrorBoundary)
