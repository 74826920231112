import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import { Moment } from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { TableFilterDate } from '@/components/controls/AppTable/types'
import Button from '@/components/controls/Button'
import ContextMenu from '@/components/controls/ContextMenu'
import { DateInput } from '@/components/controls/DateInput/component'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import { Props } from './types'

const DateFilter = ({
  id,
  currentSelected,
  anchorEl,
  handleClose,
  handleSave,
}: Props): React.ReactElement => {
  const [selectedDate, setSelectedDate] = useState<TableFilterDate>(currentSelected)
  const classes = useClasses()
  const translate = useTranslate()

  useEffect((): void => {
    setSelectedDate(currentSelected)
  }, [currentSelected])

  const handleSaveClick = useCallback((): void => {
    handleSave(id, selectedDate)
    handleClose()
  }, [selectedDate, id, handleSave, handleClose])

  const handleCancelClick = useCallback((): void => {
    setSelectedDate(currentSelected)
    handleClose()
  }, [currentSelected, handleClose])

  const handleDateChange = useCallback(
    (type: 'start' | 'end'): ((momentDate: Moment, date: string | null | undefined) => void) => (
      momentDate,
      date,
    ): void => {
      if (date && !date.includes('_')) {
        setSelectedDate({
          start:
            type === 'start' && date && momentDate.isValid()
              ? momentDate.toDate().toISOString()
              : selectedDate.start,
          end:
            type === 'end' && date && momentDate.isValid()
              ? momentDate.toDate().toISOString()
              : selectedDate.end,
        })
      }
    },
    [setSelectedDate, selectedDate],
  )

  return (
    <ContextMenu
      transitionDuration={0}
      anchorEl={anchorEl}
      disableRipple
      options={[]}
      handleClose={handleCancelClick}
    >
      <Container>
        {anchorEl ? ( // mount to trigger autofocus
          <>
            <Grid container spacing={1}>
              <Grid item>
                <DateInput
                  label={translate('translate#title.from')}
                  onChange={handleDateChange('start')}
                  value={selectedDate.start ? new Date(selectedDate.start) : null}
                  style={{ height: '60px' }}
                />
              </Grid>
              <Grid item>
                <DateInput
                  label={translate('translate#title.to')}
                  onChange={handleDateChange('end')}
                  value={selectedDate.end ? new Date(selectedDate.end) : null}
                  style={{ height: '60px' }}
                />
              </Grid>
            </Grid>
            <div className={classes.buttonWrapper}>
              <Button
                className={clsx(classes.button, classes.cancelButton)}
                variant="contained"
                size="large"
                onClick={handleCancelClick}
              >
                <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                size="large"
                onClick={handleSaveClick}
              >
                <FormattedMessage id="action.apply" defaultMessage="Apply" />
              </Button>
            </div>
          </>
        ) : (
          <></>
        )}
      </Container>
    </ContextMenu>
  )
}

export default DateFilter
