import { AnyAction } from 'redux'

import { AdmLimitsObject } from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants/actions'

export type State = {
  selectedPanel: string
  limits: {
    branches: AdmLimitsObject[]
    vaults: AdmLimitsObject[]
    currencies: AdmLimitsObject[]
  }
}

export const initialState: State = {
  selectedPanel: 'Admin_Objects',

  limits: {
    branches: [],
    vaults: [],
    currencies: [],
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case VaultV1Actions.AdmSetSelectedPanel:
      return {
        ...state,
        selectedPanel: payload,
      }

    case VaultV1Actions.AdmGetLimitsObjectsResponse:
      return {
        ...state,
        limits: {
          ...state.limits,
          branches: payload.branches || [],
          vaults: payload.vaults || [],
          currencies: payload.currencies || [],
        },
      }

    case VaultV1Actions.SignOut:
      return initialState
    default:
      return state
  }
}
