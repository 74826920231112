import { Field as FormikField } from 'formik'
import { debounce, get } from 'lodash-es'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { USMApi } from '@/api/usm/users'
import Loader from '@/components/blocks/Loader'
import { createDropDownInputField } from '@/components/controls/DropDownInput'
import { getUserInformation } from '@/store/usm/users/selectors'
import { EnableModes } from '@/types/common/filterConfig'

import { useClasses } from './styles'
import { DependentField, Props, ResponseTypes } from './types'

const GeneratedCompanyField = React.memo(
  ({
    field,
    fieldName,
    formikValues,
    setFormikFieldValue,
    lookupFieldConfig,
    isLoading: configIsLoading,
    defaultValue,
    formikPath,
    backupOptions,
  }: Props): ReactElement => {
    const [dataIsLoading, setDataIsLoading] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(false)
    const [options, setOptions] = useState<
      { value: string; name: string; companyshortname?: string }[]
    >([])
    const [dependentFields, setDependentFields] = useState<DependentField[]>([])

    const classes = useClasses()
    const userInformation =
      formikValues.personId === undefined ? useSelector(getUserInformation) : undefined

    const getOptions = useCallback(
      async (searchFragment = ''): Promise<Array<ResponseTypes> | void> => {
        try {
          setDataIsLoading(true)

          const response = await USMApi.getCompanyNameAndShortName(searchFragment)

          let options = null
          if (userInformation?.length) {
            const removedCompanies = userInformation.map(it => +it.companyId)
            options = response
              .filter(it => !removedCompanies.includes(it.companyid))
              .map(({ companyid, companyname, companyshortname }) => ({
                value: String(companyid),
                name: companyname,
                companyshortname,
              }))
          } else {
            options = response.map(({ companyid, companyname, companyshortname }) => ({
              value: String(companyid),
              name: companyname,
              companyshortname,
            }))
          }

          if (defaultValue && !options.some(option => option.value === defaultValue.value)) {
            setOptions([defaultValue, ...options])
          } else {
            setOptions(options)
          }

          setDataIsLoading(false)
        } catch (error) {
          throw new Error(error)
        }
      },
      [dependentFields, formikValues],
    )

    const handleSearch = useCallback(
      debounce((value: string) => {
        if (value.length >= 3) {
          getOptions(value)
        }
      }, 1000),
      [lookupFieldConfig, dependentFields],
    )

    // useEffect(() => {
    //   if (lookupFieldConfig && lookupFieldConfig.fieldLookupInfo) {
    //     const { dependedOn, enableMode } = lookupFieldConfig.fieldLookupInfo
    //
    //     if (dependedOn && dependedOn.length) {
    //       const fields = dependedOn.map(key =>
    //         formikPath ? get(formikValues, `${formikPath}.${key}`) : formikValues[key],
    //       )
    //
    //       if (enableMode === EnableModes.AllParentIsSet && fields.every(field => field)) {
    //         return setDisabled(false)
    //       }
    //       if (enableMode === EnableModes.SomeParentIsSet && fields.some(field => field)) {
    //         return setDisabled(false)
    //       }
    //       setDisabled(true)
    //     }
    //   }
    // }, [formikValues])
    //
    // useEffect(() => {
    //   if (lookupFieldConfig && lookupFieldConfig.fieldLookupInfo) {
    //     const { dependedOn } = lookupFieldConfig.fieldLookupInfo
    //
    //     if (dependedOn && dependedOn.length) {
    //       const parentIsEmpty = dependedOn.some(key =>
    //         formikPath ? get(formikValues, `${formikPath}.${key}`) : formikValues[key],
    //       )
    //
    //       if (!parentIsEmpty) {
    //         setFormikFieldValue(fieldName, '')
    //       }
    //     }
    //   }
    // }, [formikValues])
    //
    // useEffect(() => {
    //   if (
    //     lookupFieldConfig &&
    //     lookupFieldConfig.fieldLookupInfo.dependedOn &&
    //     lookupFieldConfig.fieldLookupInfo.dependedOn.length
    //   ) {
    //     const fields: DependentField[] = []
    //
    //     lookupFieldConfig.fieldLookupInfo.dependedOn.forEach(name => {
    //       if (Array.isArray(formikValues[name])) {
    //         return {
    //           parameterName: name,
    //           parameterValue: formikValues[name].map((value: string) => ({
    //             id: value,
    //             value: null,
    //           })),
    //           valueType: lookupFieldConfig.fieldLookupInfo.valueFieldType,
    //         }
    //       }
    //
    //       fields.push({
    //         parameterName: name,
    //         parameterValue: [
    //           {
    //             id: get(formikValues, `${formikPath}.${name}`),
    //             value: null,
    //           },
    //         ],
    //         valueType: lookupFieldConfig.fieldLookupInfo.valueFieldType,
    //       })
    //     })
    //
    //     setDependentFields(fields)
    //   }
    // }, [formikValues])

    const Field = useMemo(() => {
      return createDropDownInputField({
        isLoading: dataIsLoading,
        label: field.options?.selectAndDropDownInput?.label,
        placeholder: field.options?.selectAndDropDownInput?.placeholder,
        options: options.length
          ? options
          : backupOptions && backupOptions.backupValues
          ? [backupOptions.backupValues]
          : defaultValue
          ? [defaultValue]
          : [],
        shrink: true,
        timer: field.options?.selectAndDropDownInput?.requestDelay,
        filtering: false,
        neverDisable: false,
        required: field.options?.selectAndDropDownInput?.required,
        onTextChange: handleSearch,
        withoutSingleOption: true,
        onChange: value => {
          if (backupOptions) {
            const name = options.find(item => item.value === value)?.name
            const companyshortname = options.find(item => item.value === value)?.companyshortname

            backupOptions.setBackupValue({
              name: name || '',
              value: value,
              companyshortname,
            })
          }

          setFormikFieldValue(fieldName, value)
        },
      })
    }, [defaultValue, lookupFieldConfig, options, dataIsLoading, disabled])

    return !configIsLoading ? (
      <FormikField name={fieldName}>{Field}</FormikField>
    ) : (
      <Loader className={classes.loader} />
    )
  },
  (prev, next) => {
    if (prev.formikValues !== next.formikValues) {
      return false
    }
    if (prev.isLoading !== next.isLoading) {
      return false
    }

    return false
  },
)

export default GeneratedCompanyField
