import { AnyAction } from 'redux'

import { AnlChartsResponse } from '@/api/vault-v1/swaggerGeneratedApi'
import { FormFieldValues } from '@/components/pages/cm/AnalysisPage/components/modals/FiltersModal/types'
import { VaultV1AnalysisActions } from '@/constants'
import { FilterFormProps, VaultChartsGroupType } from '@/types/vault-v1/analysis'

export const setAnalysisFiltersModalOpen = (isOpen: boolean): AnyAction => ({
  type: VaultV1AnalysisActions.AnalysisSetFiltersModalOpen,
  payload: isOpen,
})

export const filterSubmitRequest = (payload: FilterFormProps): AnyAction => ({
  type: VaultV1AnalysisActions.AnalysisFilterSubmitRequest,
  payload,
})

export const setAnalysisFilter = (payload: Partial<FormFieldValues>): AnyAction => ({
  type: VaultV1AnalysisActions.AnalysisSetFilter,
  payload,
})

export const setSelectedChartsType = (payload: VaultChartsGroupType): AnyAction => ({
  type: VaultV1AnalysisActions.AnalysisSetSelectedChartsType,
  payload,
})

export const getAnalysisChartsRequest = (): AnyAction => ({
  type: VaultV1AnalysisActions.AnalysisGetChartsRequest,
})

export const getAnalysisChartsResponse = (payload: AnlChartsResponse): AnyAction => ({
  type: VaultV1AnalysisActions.AnalysisGetChartsResponse,
  payload,
})
export const getAnalysisChartsFail = (): AnyAction => ({
  type: VaultV1AnalysisActions.AnalysisGetChartsFail,
})
