import { makeStyles, Theme } from '@material-ui/core/styles'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'
import styled, { css } from 'styled-components'

import { styledComponentTheme } from '@/theme'

interface Props {
  position?: 'absolute' | 'relative'
  background?: string
  borderColor?: string
  inputWith: string
}

export const useClasses = makeStyles<Theme, Props>(() => {
  return {
    fontInput: {
      margin: 0,
      borderRadius: '4px',
      minWidth: '70px',

      '& input': {
        height: '24px',
        fontSize: styledComponentTheme.fontSizes.small,

        padding: '0 6px',
      },

      '& .MuiTextField-root': {
        top: 'auto',
        transform: 'none',
        position: 'relative',
        left: 'auto',
      },
    },

    cont: {
      display: 'block',
      zIndex: -1,
      pointerEvents: 'none',
      position: 'relative',
      margin: '0 8px',
      marginTop: '-20px',
    },

    borderColor: ({ background, borderColor }): CreateCSSProperties<Props> => ({
      borderColor: background || borderColor || 'auto',
    }),
  }
})

interface SpanProps {
  disabled: boolean
  isPreventDoubleClick: boolean
}

export const StyledText = styled.span<SpanProps>`
  ${({ disabled, isPreventDoubleClick }) => {
    const disabledStyles = disabled ? ` pointer-events: none; opacity:  0.3;` : ''

    return css`
      cursor: ${!isPreventDoubleClick ? 'pointer' : 'text'};
      width: 100%;
      display: grid;
      font-weight: ${isPreventDoubleClick ? 'normal' : 'bold'};
      min-height: 24px;
      padding: 0 6px;
      align-items: center;

      min-width: 70px;

      ${disabledStyles}
    `
  }}
`
