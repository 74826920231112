import queryString from 'query-string'

import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

import {
  AdmPlnCasSet,
  AdmPlnCasSetBase,
  AdmPlnCasSetsResponse,
  AdmPlnCassetteType,
} from '../swaggerGeneratedApi'

export class CassetteSetsApi {
  private static requestService = getRequestServiceCM()

  public static async get(sort: Sort): Promise<AdmPlnCasSetsResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'admin/planning/casSets',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )
      return await CassetteSetsApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async add(cassetteSet: AdmPlnCasSetBase): Promise<void> {
    try {
      return await CassetteSetsApi.requestService.post('admin/planning/casSets/add', cassetteSet)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async update(cassetteSet: AdmPlnCasSet): Promise<void> {
    try {
      return await CassetteSetsApi.requestService.put('admin/planning/casSets/update', cassetteSet)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async delete(id: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'admin/planning/casSets/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )
      return await CassetteSetsApi.requestService.delete(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCassetteTypes(): Promise<AdmPlnCassetteType[]> {
    try {
      return await CassetteSetsApi.requestService.get('admin/planning/cassetteTypes')
    } catch (e) {
      throw new Error(e)
    }
  }
}
