import { FilterParameter } from '@/types'

export interface FormConfigData {
  formUID: string
  getConfigURL: string
}

export interface Group {
  groupUID: string
  groupNameKey: string
  groupName: string
  valueType: string
  labelKey: string
  label: string
  excludeChildGroupUID: string[]
  allowedMaxGroupLevel: number
  outGroupFieldValueType: string
  outGroupFieldDisplayType: string
}

export interface DevicesGroup {
  parameterUid: string
  parameterValueType: Type
  outParameterValueType: Type
  outParameterDisplayType: Type
  excludeChildGroupingParameterNames: string[]
  parameterLabelKey: string
  parameterLabel: string
  groupLabelKey: string
  groupLabel: string
  allowedMaxGroupingLevel: number
}

export enum Type {
  Date = 'DATE',
  Integer = 'INTEGER',
  String = 'STRING',
}

export interface OutputField {
  fieldLabelKey: string
  fieldLabel: string
  orderNo: number
  fieldName: string
  fieldType: string
  fieldValue: any | null
  isVisibleByDefault: boolean
  sortDisabled: boolean
}

export interface TicketsConfigResponse {
  formConfig: TicketsConfigData
}

export interface DevicesConfigResponse {
  formConfig: TicketsConfigData
}

export interface TicketsConfigData {
  formUID: string
  getConfigURL: string
  getOutTreeURL: string
  getOutDetailsURL: string
  filter: FilterParameter[]
  groups: Group[]
  outputFields: OutputField[]
}
