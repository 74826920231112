import { AnyAction } from 'redux'
import { NotificationsActions } from '@/constants/actions'
import { Notification } from '@/types/srm/notificaitons'

export interface State {
  notificationsIsActive: boolean
  newNotifications: Notification[]
  notifications: Notification[]
}

export const initialState: State = {
  notificationsIsActive: false,
  newNotifications: [],
  notifications: [],
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case NotificationsActions.ActivateNotifications:
      return { ...state, notificationsIsActive: true }
    case NotificationsActions.DisableNotifications:
      return { ...state, notificationsIsActive: false }
    case NotificationsActions.ToggleNotifications:
      return { ...state, notificationsIsActive: !state.notificationsIsActive }
    case NotificationsActions.SetNewNotifications:
      return { ...state, newNotifications: [...state.newNotifications, ...payload] }
    case NotificationsActions.ReadNotifications:
      return {
        ...state,
        notifications: [...state.notifications, ...state.newNotifications],
        newNotifications: [],
      }
    default:
      return state
  }
}
