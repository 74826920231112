export enum WindowsEditActions {
  SetPage = '@/SPLITTER_SET_PAGE',

  ToggleMoveSplitPaneable = '@/TOGGLE_MOVE_SPLIT_PANE',
  SetContentWrapperWidth = '@/SET_CONTENT_WRAPPER_WIDTH',
  SetSplitPaneHeightPosition = '@/SET_SPLIT_PANE_HEIGHT_POSITION',
  SetSplitPaneWidhtPosition = '@/SET_SPLIT_PANE_WIDTH_POSITION',

  ClosePropertiesWindow = '@/CLOSE_PROPERTIES_WINDOW',
  OpenPropertiesWindow = '@/OPEN_PROPERTIES_WINDOW',

  ToggleDrawer = '@/TOGGLE_DRAWER',
}
