import { AnyAction } from 'redux'

import {
  ComVaultReport,
  Currency,
  EventType,
  JournalEvent,
  JournalEventCurrencyEntry,
  JournalEventsFilter,
  JournalEventsRequest,
  JournalEventsResponse,
  SelectorItem,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants/actions'
import { KeyPressActionName, Paging, Sort } from '@/types/cm'

// Events
export const getEventsRequest = (): AnyAction => ({
  type: VaultV1Actions.EvjGetEventsRequest,
})
export const getEventsResponse = (payload: JournalEventsResponse): AnyAction => ({
  type: VaultV1Actions.EvjGetEventsResponse,
  payload,
})
export const getEventsFail = (error?: string): AnyAction => ({
  type: VaultV1Actions.EvjGetEventsFail,
  payload: error,
})

export const revertTransactionRequest = (payload: { eventId: number }): AnyAction => ({
  type: VaultV1Actions.EvjRevertTransactionRequest,
  payload,
})

export const setEvjSelectedEvent = (payload: JournalEvent | null): AnyAction => ({
  type: VaultV1Actions.EvjSetSelectedEvent,
  payload: payload,
})

export const setEvjSelectedEntry = (payload: JournalEventCurrencyEntry | null): AnyAction => ({
  type: VaultV1Actions.EvjSetSelectedEntry,
  payload: payload,
})

// Sort
export const sortEvjEventsTable = (payload: Sort): AnyAction => ({
  type: VaultV1Actions.EvjSortEventsTable,
  payload,
})

// Paging
export const setEvjEventsPaging = (payload: Paging): AnyAction => ({
  type: VaultV1Actions.EvjSetEventsPaging,
  payload: payload,
})
export const resetEvjEventsPaging = (): AnyAction => ({
  type: VaultV1Actions.EvjResetEventsPaging,
})

// [START] Modals ---------------------------------------------------------------------
export const openEvjColumnsSettingsModal = (isOpen: boolean): AnyAction => ({
  type: VaultV1Actions.EvjOpenColumnsSettingsModal,
  payload: isOpen,
})
export const openEvjDocumentsModal = (isOpen: boolean): AnyAction => ({
  type: VaultV1Actions.EvjOpenDocumentsModal,
  payload: isOpen,
})
export const openEvjFiltersModal = (isOpen: boolean): AnyAction => ({
  type: VaultV1Actions.EvjOpenFilterModal,
  payload: isOpen,
})
// [END] Modals ---------------------------------------------------------------------

// Filter
export const setEvjFilter = (payload: JournalEventsFilter): AnyAction => ({
  type: VaultV1Actions.EvjSetEventsFilter,
  payload,
})
export const submitEvjFilter = (payload: JournalEventsRequest): AnyAction => ({
  type: VaultV1Actions.EvjSubmitFilterRequest,
  payload,
})

// Reports
export const getEvjReportsRequest = (): AnyAction => ({
  type: VaultV1Actions.EvjGetReportsRequest,
})
export const getEvjReportsResponse = (payload: ComVaultReport[]): AnyAction => ({
  type: VaultV1Actions.EvjGetReportsResponse,
  payload,
})
export const getEvjReportsFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.EvjGetReportsFail,
  payload: message,
})

export const getEvjReportRequest = (payload?: ComVaultReport): AnyAction => ({
  type: VaultV1Actions.EvjGetReportRequest,
  payload,
})
export const getEvjReportResponse = (payload: Blob | null): AnyAction => ({
  type: VaultV1Actions.EvjGetReportResponse,
  payload,
})
export const getEvjReportFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.EvjGetReportFail,
  payload: message,
})

// [START] Filter Options ----------------------------------------------------------
// vaults
export const getEjvVaultsRequest = (): AnyAction => ({
  type: VaultV1Actions.EvjGetVaultsRequest,
})
export const getEjvVaultsResponse = (payload: SelectorItem[]): AnyAction => ({
  type: VaultV1Actions.EvjGetVaultsResponse,
  payload,
})

// currencies
export const getEjvCurrenciesRequest = (): AnyAction => ({
  type: VaultV1Actions.EvjGetCurrenciesRequest,
})
export const getEjvCurrenciesResponse = (payload: Currency[]): AnyAction => ({
  type: VaultV1Actions.EvjGetCurrenciesResponse,
  payload,
})

// eventTypes
export const getEjvEventTypesRequest = (): AnyAction => ({
  type: VaultV1Actions.EvjGetEventTypesRequest,
})
export const getEjvEventTypesResponse = (payload: EventType[]): AnyAction => ({
  type: VaultV1Actions.EvjGetEventTypesResponse,
  payload,
})

// executors
export const getEjvExecutorsRequest = (): AnyAction => ({
  type: VaultV1Actions.EvjGetExecutorsRequest,
})
export const getEjvExecutorsResponse = (payload: SelectorItem[]): AnyAction => ({
  type: VaultV1Actions.EvjGetExecutorsResponse,
  payload,
})
// [END] Filter Options ----------------------------------------------------------

export const handleEvjKeyPress = (actionName: KeyPressActionName): AnyAction => ({
  type: VaultV1Actions.EvjHandleKeyPress,
  payload: actionName,
})
