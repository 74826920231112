import { useCallback, useEffect, useRef, useState } from 'react'

import { FilterField, GroupInfo } from '@/api/atmeye/commonTypes'
import { SecurityAlarmsApi } from '@/api/atmeye/securityAlarms'
import { Alarm, AlarmsFilter, Filter, FiltersTemplate } from '@/api/atmeye/securityAlarms/types'
import { StrategyLocalStorage } from '@/components/NPMPakage/components/tableComponents/UITable/strategy'
import { useTablePageWithoutConfig } from '@/components/NPMPakage/hooks/useTablePageWithoutConfig/useTablePageWithoutConfig'
import {
  DropDownEntityType,
  FieldTypes,
  FilterDisplayName,
  ValueTypes,
} from '@/components/pages/atmeye/Administration/LicenseList/ATMCoreLicenses/components/FiltersModalLicence/GenerateFormFields/types'
import {
  dateTimeRangeConfig,
  multiSelectConfig,
  transformFilters,
} from '@/helpers/atmeye/filtersConfig'
import { useTranslate } from '@/utils/internalization'

import { TableGroup } from '../../../../NPMPakage/components/modals/GroupingModal/components/SortBlock/types'
import {
  addParentNodes,
  createNodesMap,
  selectAllChild,
  updateParentSelectState,
} from '../../../../NPMPakage/components/ObjectsTree/components/TreeElement/utils'
import { GroupNodes } from '../../../../NPMPakage/components/ObjectsTree/types'
import { useExportTable } from '../../../../NPMPakage/hooks/useExportTable'
import { useFilterView } from '../../../../NPMPakage/hooks/useFilterView'
import { useSelectedFilters } from '../../../../NPMPakage/hooks/useSelectedFilters'
import { COLUMNS, LsPageTableKeys } from './types'

export enum AlarmReactionFilterOptions {
  All = 'ALL',
  WithReaction = 'WITH',
  WithoutReaction = 'WITHOUT',
}

export const filterConfig = {
  atmDateTime: dateTimeRangeConfig,
  deviceId: multiSelectConfig,
  eventTypeId: multiSelectConfig,
  eventMediaTypeId: multiSelectConfig,
  reaction: (selected: string): FilterField =>
    selected === AlarmReactionFilterOptions.All
      ? undefined
      : {
          specified: selected === AlarmReactionFilterOptions.WithReaction,
        },
  reactionDateTime: dateTimeRangeConfig,
}

const useAlarms = ({
  selectedGrouping,
  selectedGroupingNodes,
  setSelectedGroupingNodes,
  initialHiddenColumns,
  requiredFilters,
  lsKeys,
  defaultFilters,
  handleResetDefaultFilters,
  filtersConfig,
}: {
  selectedGrouping: TableGroup[]
  selectedGroupingNodes: GroupNodes
  setSelectedGroupingNodes: any
  initialHiddenColumns?: string[]
  requiredFilters?: Record<string, any>
  lsKeys: LsPageTableKeys
  defaultFilters: Filter
  handleResetDefaultFilters?: () => any
  filtersConfig: any
}) => {
  const filterTplLs = StrategyLocalStorage.getData(lsKeys.lsKeyFiltersTemplates)

  const translate = useTranslate()
  const isInitialMount = useRef(true)

  const [isLoading, setIsLoading] = useState(false)
  const [isGroupsLoading, setIsGroupsLoading] = useState(false)
  const [tableData, setTableData] = useState<Array<Alarm>>([])
  const [groupedData, setGroupedData] = useState<GroupNodes>([])

  const [
    selectedFiltersTemplate,
    handleSetSelectedFilterTemplate,
  ] = useState<FiltersTemplate | null>(filterTplLs?.selectedFiltersTemplate || null)
  const filters = useSelectedFilters<Filter>({
    localStorageKey: lsKeys.lsKeyFilters,
    initialValues: defaultFilters,
    requiredFilters,
    handleResetDefaultFilters,
    filtersConfig,
  })

  const { sorting, headerColumns, pagination } = useTablePageWithoutConfig({
    lsKeyPagination: lsKeys.lsKeyPagination,
    lsKeyColumns: lsKeys.lsKeyColumns,
    lsKeyFilters: lsKeys.lsKeyFilters,
    lsKeySorting: lsKeys.lsKeySorting,
    initialSorting: { fieldName: 'atmDateTime', sortOrder: 'desc' },
    initialHiddenColumns,
    tableColumns: COLUMNS,
    initialFiltersValues: {},
  })

  const hasGrouping = !!selectedGrouping.length

  const getFilters = (): AlarmsFilter => {
    return transformFilters(filters.selectedFilters, filterConfig)
  }

  const getGroupedTableData = useCallback(async () => {
    if (hasGrouping && !selectedGroupingNodes.length) {
      setTableData([])
      return
    }

    setIsLoading(true)

    const res = await SecurityAlarmsApi.getAlarmsList({
      filter: getFilters(),
      sort: `${sorting.sorting.fieldName},${sorting.sorting.sortOrder}`,
      size: pagination.paginationData.pageSize,
      page: pagination.paginationData.pageNumber,
      groupingFilters: hasGrouping
        ? selectedGroupingNodes.reduce((acc, { nodeFilter }) => {
            acc.push(nodeFilter)

            return acc
          }, [] as AlarmsFilter[])
        : undefined,
    })

    const { totalElements, totalPages } = res

    pagination.setPaginationData({
      ...pagination.paginationData,
      totalElements,
      totalPages,
    })

    setTableData(res.content)
    setIsLoading(false)
  }, [
    hasGrouping,
    selectedGroupingNodes,
    filters.selectedFilters,
    sorting.sorting,
    pagination.paginationData.pageNumber,
    pagination.paginationData.pageSize,
    setTableData,
  ])

  const getGroupData = async (): Promise<void> => {
    setIsGroupsLoading(true)

    const res = await SecurityAlarmsApi.getAlarmsGroups({
      filter: getFilters(),
      group: selectedGrouping.reduce(
        (acc, { groupingField, groupingSortDirection, groupingSortType }, i) => {
          acc[groupingField] = {
            levelNumber: i,
            sortType: groupingSortType,
            sortDirection: groupingSortDirection,
          }

          return acc
        },
        {} as Record<string, GroupInfo>,
      ),
    })

    const groupedDataWithParents = res.groupNodes.map(node => {
      return addParentNodes(node)
    })

    if (selectedGroupingNodes.length) {
      const nodesMap = createNodesMap(groupedDataWithParents)

      const newSelectedNodes = selectedGroupingNodes.reduce((acc, node) => {
        const updatedNode = nodesMap[node.nodeId]

        if (updatedNode && !acc.find(({ nodeId }) => nodeId === updatedNode.nodeId)) {
          const selectedChilds = selectAllChild(updatedNode)
          return updateParentSelectState(updatedNode, [...acc, ...selectedChilds])
        }

        return acc
      }, [] as GroupNodes)

      setSelectedGroupingNodes(newSelectedNodes)
    } else {
      getGroupedTableData()
    }

    setGroupedData(groupedDataWithParents)

    setIsGroupsLoading(false)
  }

  const refreshData = useCallback(async () => {
    if (hasGrouping) {
      getGroupData()
    } else {
      getGroupedTableData()
    }
  }, [getGroupData, getGroupedTableData, hasGrouping])

  useEffect(() => {
    if (!isInitialMount.current) {
      getGroupedTableData()
    }
  }, [sorting.sorting, pagination.paginationData.pageNumber, pagination.paginationData.pageSize])

  useEffect(() => {
    if (hasGrouping) {
      getGroupData()
    } else {
      pagination.paginationData.pageNumber !== 1
        ? pagination.setPaginationData({ ...pagination.paginationData, pageNumber: 1 })
        : getGroupedTableData()
    }
  }, [filters.selectedFilters, selectedGrouping])

  useEffect(() => {
    if (!isInitialMount.current && hasGrouping) {
      pagination.paginationData.pageNumber !== 1
        ? pagination.setPaginationData({ ...pagination.paginationData, pageNumber: 1 })
        : getGroupedTableData()
    }
  }, [selectedGroupingNodes])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    }
  }, [])

  useEffect(() => {
    StrategyLocalStorage.setData(lsKeys.lsKeyFiltersTemplates, {
      selectedFiltersTemplate: selectedFiltersTemplate,
    })
  }, [selectedFiltersTemplate])

  const fields: Array<FieldTypes> = [
    {
      fieldType: FilterDisplayName.MULTISELECT,
      label: translate('translate#atmeye.filter.devices'),
      fieldName: 'deviceId',
      placeholder: translate('translate#atmeye.filter.devices'),
      options: {
        multiselect: {
          dropdownListEntityType: DropDownEntityType.DEVICES_IDS_AND_NAMES,
        },
      },
    },
    {
      fieldType: FilterDisplayName.MULTISELECT,
      label: translate('translate#atmeye.filter.events'),
      fieldName: 'eventTypeId',
      placeholder: translate('translate#atmeye.filter.events'),
      options: {
        multiselect: {
          dropdownListEntityType: DropDownEntityType.ALARM_EVENTS_TYPES,
        },
      },
    },
    {
      fieldType: FilterDisplayName.MULTISELECT,
      label: translate('translate#atmeye.filter.eventMedia'),
      fieldName: 'eventMediaTypeId',
      placeholder: translate('translate#atmeye.filter.eventMedia'),
      options: {
        multiselect: {
          dropdownListEntityType: DropDownEntityType.EVENTS_MEDIA_TYPES,
        },
      },
    },
    {
      fieldType: FilterDisplayName.Text,
      label: translate('translate#atmeye.filter.operator'),
      fieldName: 'operator',
      placeholder: translate('translate#atmeye.filter.operator'),
    },
    {
      fieldType: FilterDisplayName.Text,
      label: translate('translate#atmeye.filter.note'),
      fieldName: 'note',
      placeholder: translate('translate#atmeye.filter.note'),
    },
    {
      fieldType: FilterDisplayName.Select,
      label: translate('translate#atmeye.filter.reaction'),
      fieldName: 'reaction',
      placeholder: translate('translate#atmeye.filter.reaction'),
      valueType: ValueTypes.Select,
      options: {
        optionsSelect: [
          {
            name: translate('translate#atmeye.filter.reaction.options.all'),
            value: AlarmReactionFilterOptions.All,
          },
          {
            name: translate('translate#atmeye.filter.reaction.options.withReaction'),
            value: AlarmReactionFilterOptions.WithReaction,
          },
          {
            name: translate('translate#atmeye.filter.reaction.options.withoutReaction'),
            value: AlarmReactionFilterOptions.WithoutReaction,
          },
        ],
        hideEmptyItem: true,
      },
    },
    {
      fieldType: FilterDisplayName.Range,
      disableFutureDates: true,
      label: translate('translate#atmeye.filter.reactionDateTime'),
      fieldName: 'reactionDateTime',
      placeholder: '',
      valueType: ValueTypes.Datetime,
    },
    {
      fieldType: FilterDisplayName.Range,
      disableFutureDates: true,
      label: translate('translate#atmeye.filter.atmDate'),
      fieldName: 'atmDateTime',
      placeholder: '',
      valueType: ValueTypes.Datetime,
    },
  ]

  const { filterView } = useFilterView({ selectedFilters: filters.selectedFilters, config: fields })

  const { exportTableData } = useExportTable({
    hasGrouping,
    selectedGrouping,
    selectedGroupingNodes,
    columns: headerColumns.columns,
    sort: `${sorting.sorting.fieldName},${sorting.sorting.sortOrder}`,
    filterView,

    getFilters,
    API: SecurityAlarmsApi,
  })

  return {
    isLoading,
    isGroupsLoading,
    hasGrouping,
    sorting,
    headerColumns,
    pagination,
    tableData,
    refreshData,
    exportTableData,
    fields,
    filterView,
    filters: {
      ...filters,
      handleSubmit: filters.handleSelectedFilters,
      selectedFiltersTemplate,
      handleSetSelectedFilterTemplate,
    },
    groupedData,
    getGroupedTableData,

    selectedFiltersTemplate,
    handleSetSelectedFilterTemplate,
    setGroupedData,
    setTableData,
  }
}

export { useAlarms }
