export const VaultDetailsColumn = {
  MorningReminder: 'morningReminder',
  CurrentReminder: 'currentReminder',
  EveningReminder: 'eveningReminder',
  NightDeposit: 'nightDepo',
  CashRemovalPlan: 'cashRemovalPlan',
  CashRemoval: 'cashRemoval',
  CashReceivePlan: 'cashReceivePlan',
  CashReceive: 'cashReceive',
  WornOutCash: 'wornOutCash',
  UpperLimit: 'upperLimit',
}
