import { FormikValues } from 'formik'
import moment from 'moment'

import { YesNoAllRadioGroupValues } from '@/components/controls/RadioGroup/component'
import { FilterParameter, FilterTypeDisplayName } from '@/types/common/filterConfig'

export const getInitialFormValues = (
  filtersFields: FilterParameter[],
  additionalParameters?: FormikValues,
): { [key: string]: any } => {
  const initialValues: { [key: string]: any } = {
    ...(additionalParameters && additionalParameters),
  }
  filtersFields &&
    filtersFields.forEach((field: FilterParameter) => {
      const { parameterName, displayType, allowMultipleSelect, lookupInfo } = field

      switch (displayType) {
        case FilterTypeDisplayName.Text:
          initialValues[parameterName] = ''
          break
        case FilterTypeDisplayName.List:
          if (allowMultipleSelect) {
            initialValues[parameterName] = []
          } else {
            initialValues[parameterName] = ''
          }
          break
        case FilterTypeDisplayName.Range:
          if (parameterName === 'p_date') {
            initialValues[parameterName] = [moment().toISOString(), moment().toISOString()]
          } else {
            initialValues[parameterName] = [null, null]
          }
          break
        case FilterTypeDisplayName.YesNo:
        case FilterTypeDisplayName.YesNoAll:
          if (lookupInfo) {
            if (lookupInfo.defaultValue === '-1') {
              initialValues[parameterName] = YesNoAllRadioGroupValues.All
            } else {
              initialValues[parameterName] = lookupInfo.defaultValue
            }
          } else {
            initialValues[parameterName] = YesNoAllRadioGroupValues.All
          }
          break
        case FilterTypeDisplayName.Checkbox:
          initialValues[parameterName] = false
          break
        case FilterTypeDisplayName.RadioGroup:
          initialValues[parameterName] = lookupInfo?.lookupValues[0].value || ''
          break
        case FilterTypeDisplayName.Date:
          initialValues[parameterName] = moment().toISOString()
          break
        default:
          initialValues[parameterName] = ''
      }
    })
  return initialValues
}
