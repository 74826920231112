import styled from 'styled-components'

export const StyledTableWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: #fff;
  display: grid;
  grid-auto-rows: max-content minmax(max-content, auto) 1fr;
  z-index: 100;
`

export const WithoutDeviceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 100%;
  height: 100%;
  font-style: italic;
  color: gray;
`
