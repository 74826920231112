import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles({
  wrapper: {
    margin: '30px 30px 34px 30px',
  },
  form: {},

  headerTitle: {
    margin: '0',
    fontSize: '20px',
    fontStyle: 'normal',
    color: '#041517',
  },

  closingWindow: {
    position: 'absolute',
    right: '-20px',
    top: '-20px',
    cursor: 'pointer',
  },

  headerMenu: {
    display: 'flex',
    margin: '20px 0 30px 0',
  },

  stylesButton: {
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    background: '#F9F9F9',
    borderRadius: '3px',
    marginRight: '16px',
    padding: '0 12px',
    fontSize: '14px',
    fontStyle: 'normal',
    color: '#041517',
    cursor: 'pointer',
  },

  activeButton: {
    color: '#FFFFFF',
    backgroundColor: '#25ADBF',
  },

  addPhotoButton: {
    backgroundColor: '#25ADBF',
    width: '40px',
    height: '40px',
    borderRadius: '20px',
  },

  addIcon: {
    position: 'absolute',
    top: '11px',
    left: '11px',
  },

  textButton: {
    marginLeft: '12px',
    color: '#25ADBF',
    opacity: '0.9',
    fontSize: '14px',
    fontStyle: 'normal',
  },

  addingButton: {
    display: 'inline-flex',
    alignItems: 'center',
    marginBottom: '8px',
    cursor: 'pointer',
    position: 'relative',
  },

  disablePhoto: {
    opacity: 0.4,
  },

  nextButton: {
    height: '40px',
    background: '#25ADBF',
    color: 'white',
    fontSize: '14px',
    marginLeft: '20px',
    '&:hover': {
      background: '#1a7c89',
    },
  },

  cancelButton: {
    height: '40px',
    color: '#929A9B',
    backgroundColor: '#FFFFFF',
    border: '1px solid #929A9B',
    borderRadius: '3px',
  },

  backButton: {
    height: '40px',
    color: '#25ADBF',
    backgroundColor: '#FFFFFF',
    border: '1px solid #25ADBF',
    borderRadius: '3px',
    marginLeft: '20px',
  },

  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '44px',
  },

  labelCheckBox: {
    color: '#25ADBF',
    fontSize: '14px',
  },

  footerLoginInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  addPhoto: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
  },

  personPhoto: {
    maxWidth: '200px',
    maxHeight: '200px',
  },

  closingPhoto: {
    position: 'absolute',
    right: '0',
    top: '0',
    cursor: 'pointer',
    display: 'none',
  },

  editingPhoto: {
    position: 'absolute',
    cursor: 'pointer',
    right: '30px',
    top: '0',
    display: 'none',
  },

  addButton: {
    height: '40px',
    color: '#25ADBF',
    background: 'white',
    border: '1px solid #25ADBF',
    fontSize: '14px',
    fontStyle: 'normal',
    padding: '0',
    marginRight: '12px',
  },

  addButtonLocation: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px',
  },

  wrapperPersonPhoto: {
    position: 'relative',
    display: 'inline-block',
    '&:hover $editingPhoto, &:hover $closingPhoto': {
      display: 'block',
    },
    '&:hover $personPhoto': {
      opacity: '0.5',
    },
  },

  buttonText: {
    fontSize: '14px',
    marginRight: '12px',
  },

  confirmation: {
    display: 'flex',
    alignItems: 'center',
  },

  deleteButton: {
    flex: '0.56 0.56 110px',
    maxWidth: '110px',
    height: '40px',
    background: '#FDEFED',
    color: '#e45b4a',
    fontSize: '14px',
    fontStyle: 'normal',
    '&:hover': {
      background: '#e0d3d1',
    },
  },

  deleteButtonText: {
    fontSize: '14px',
    marginRight: '20px',
  },

  footerUserInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
  },

  editingPhotoButtons: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
  },
})
