import makeStyles from '@material-ui/core/styles/makeStyles'

type Props = {
  isFlatTable?: boolean
}

export const useClasses = makeStyles(({ spacing, mixins }) => ({
  wrap: {
    height: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    ...mixins.gutters(),
  },

  root: {
    height: '100%',
    boxShadow: '0px 0px 15px rgba(37, 173, 191, 0.05)',
    borderRadius: '3px',
    padding: 0,
    marginTop: spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },

  table: {
    height: 'max-content',
    boxShadow: 'none',
    contain: 'content',
  },

  loaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 999,
    background: 'rgba(255, 255, 255, 0.7)',
  },

  controls: {
    padding: '16px 20px',
  },
}))
