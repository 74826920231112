import React from 'react'
import { Props } from './types'
import TextFilter from '@/components/controls/AppTable/components/FilterContextMenu/components/TextFilter/component'
import SelectFilter from '@/components/controls/AppTable/components/FilterContextMenu/components/SelectFilter/component'
import DateFilter from '@/components/controls/AppTable/components/FilterContextMenu/components/DateFilter/component'
import { TableFilterDate } from '@/components/controls/AppTable/types'
import { isPlainObject } from 'lodash-es'

const FilterContextMenu = ({
  id,
  values,
  currentSelected,
  anchorEl,
  handleClose,
  handleSave,
}: Props): React.ReactElement | null => {
  // multiple select
  if (Array.isArray(values)) {
    return (
      <SelectFilter
        id={id}
        values={values}
        currentSelected={currentSelected}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleSave={handleSave}
      />
    )
  }

  // text
  if (values === 'text') {
    return (
      <TextFilter
        id={id}
        currentSelected={currentSelected as string}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleSave={handleSave}
      />
    )
  }
  // date
  if (isPlainObject(values)) {
    return (
      <DateFilter
        id={id}
        currentSelected={currentSelected as TableFilterDate}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleSave={handleSave}
      />
    )
  }
  return null
}

export default FilterContextMenu
