import clsx from 'clsx'
import React, { ReactElement } from 'react'

import Loader from '@/components/blocks/Loader'

import { useClasses } from './styles'
import { Props } from './types'

const LoadingOverlay = ({ loaded, active, spinner, children, className }: Props): ReactElement => {
  const classes = useClasses()

  const spinnerElement = spinner || <Loader />

  if (!loaded && active) {
    return spinnerElement
  }

  return (
    <>
      {active && <div className={clsx(classes.loaderWrapper, className)}>{spinnerElement}</div>}

      {children}
    </>
  )
}

export default React.memo(LoadingOverlay)
