import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  getChartDataResponse,
  getChartDataResponseFail,
  getChartExtendedDataResponse,
  getChartExtendedDataResponseFail,
  getAllDevicesResponse,
  getAllDevicesResponseFail,
  getTicketsResponse,
  getTicketsResponseFail,
  getTicketsConfigReponse,
  getTicketsConfigResponseFail,
} from './actions'
import { Device, TicketsConfigResponse } from '@/types'
import { DashboardActions } from '@/constants/actions/dashboardActions'
import { DashboardApi } from '@/api/common/dashboardApi'
import { SagaIterator } from 'redux-saga'
import { TicketsApi } from '@/api/sd/ticketsApi'

function* getChartData(): SagaIterator {
  try {
    const { company, devices } = yield select(state => state.dashboard.filter)

    const response = yield call(DashboardApi.getChartData, company, devices)

    yield put(getChartDataResponse(response))
  } catch (error) {
    yield put(getChartDataResponseFail(error.message))
  }
}

function* getChartExtendedData(): SagaIterator {
  try {
    const { company, devices } = yield select(state => state.dashboard.filter)

    const response = yield call(DashboardApi.getChartExtendedData, company, devices)

    yield put(getChartExtendedDataResponse(response))
  } catch (error) {
    yield put(getChartExtendedDataResponseFail(error.message))
  }
}

function* getAllDevices(): Generator<unknown, void, Device[]> {
  try {
    const response = yield call(DashboardApi.getAllDevices)

    yield put(getAllDevicesResponse(response))
  } catch (error) {
    yield put(getAllDevicesResponseFail(error.message))
  }
}

function* getTicketsConfig(): Generator {
  try {
    const response = yield call(TicketsApi.getFormConfig, {
      formUID: 'SRV_ClWEB_SearchRequests_Open_MY',
      getConfigURL: '/search-form/config',
    })

    yield put(getTicketsConfigReponse(response as TicketsConfigResponse))
  } catch (error) {
    yield put(getTicketsConfigResponseFail(error.message))
  }
}

function* getTickets(): SagaIterator {
  try {
    const {
      filter: { devices, sla, status },
      pageNo,
      pageSize,
      sorting,
    } = yield select(state => state.dashboard)

    const requestBody = {
      filter: {
        devices,
        sla,
        status: [status],
      },
      pageNo,
      pageSize,
      sortDetails: sorting
        ? [
            {
              fieldName: Object.keys(sorting)[0],
              sortOrder: (Object as any).values(sorting)[0].toUpperCase(),
            },
          ]
        : [],
    }

    const response = yield call(DashboardApi.getTickets, requestBody)

    yield put(getTicketsResponse(response))
  } catch (error) {
    yield put(getTicketsResponseFail(error.message))
  }
}

export default function*(): Generator {
  yield takeLatest(DashboardActions.GetChartDataRequest, getChartData)
  yield takeLatest(DashboardActions.GetChartExtendedDataRequest, getChartExtendedData)
  yield takeLatest(DashboardActions.GetAllDevicesRequest, getAllDevices)
  yield takeLatest(DashboardActions.GetTicketsConfigRequest, getTicketsConfig)
  yield takeLatest(DashboardActions.GetTicketsRequest, getTickets)
}
