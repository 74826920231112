import {
  ApiGroupTemplateResponse,
  GetTicketsBodyFilterItem,
  ReportLookupValuesResponse,
} from '@/api/sd/ticketsApi/types'
import { groupTemplateUiToApiView } from '@/components/pages/sd/helpers/sdHelpers'
import { TYPE_OF_TICKETS } from '@/components/routers/sd/TicketsRouter/component'
import filterTemplates from '@/mocks/tickets/filterTemplates'
import groupingsTemplates from '@/mocks/tickets/groupingTemplates'
import { serviceItemsTableData, workItemsTableData } from '@/mocks/tickets/workItemsAndServiceItems'
import {
  ApiGroupTemplate,
  FilterTemplate,
  GroupingTemplate,
  ResponseError,
  TicketsConfigResponse,
  UIGroupTemplate,
} from '@/types'
import { Hardware } from '@/types/adm/device/api'
import { GetTicketsResponse } from '@/types/sd/common'
import { GroupTemplate, WindowType } from '@/types/sd/tickets/ticket'
import { TicketDetailsResponse } from '@/types/sd/tickets/ticketDetails'
import { requestService } from '@/utils/services/request'

import {
  AdvancedSearchFiltersConfig,
  AttachFilesResponse,
  CreateTicketRequestBody,
  CreateTicketResponse,
  DropdownListsPayload,
  DropdownListsResponse,
  GetGroupDetailsBody,
  GetTicketsBody,
  LookupDictionaryRequest,
  LookupValuesRequest,
  LookupValuesResponse,
  ServiceCompaniesResponse,
  ServiceItemstableData,
  WorkItemsTableData,
} from './types'

export class TicketsApi {
  public static async getTickets(
    typeOfTickets: string,
    getTicketsBody: GetTicketsBody,
  ): Promise<GetTicketsResponse | ResponseError> {
    try {
      const URL_POSTFIXES = {
        [TYPE_OF_TICKETS.MY]: '/open/my',
        [TYPE_OF_TICKETS.ALL]: '/open/all',
        [TYPE_OF_TICKETS.HISTORY]: '/history',
      }
      return await requestService.post(
        `/api/v1/search-form/client/srtree${URL_POSTFIXES[typeOfTickets]}`,
        getTicketsBody,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getGroupedDetails(
    typeOfTickets: string,
    getTicketsBody: GetGroupDetailsBody,
  ): Promise<any | ResponseError> {
    try {
      const URL_POSTFIXES = {
        [TYPE_OF_TICKETS.MY]: '/open/my',
        [TYPE_OF_TICKETS.ALL]: '/open/all',
        [TYPE_OF_TICKETS.HISTORY]: '/history',
      }

      return await requestService.post(
        `/api/v1/search-form/client/srgroup-details${URL_POSTFIXES[typeOfTickets]}`,
        getTicketsBody,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static getSdFilterTemplates = async (
    windowType: WindowType,
  ): Promise<{ id: string; name: string }[] | ResponseError> =>
    await requestService.get('/api/v1/filter-templates', { windowType })

  public static getSdFilterTemplateById = async (
    id: string,
    windowType: WindowType | string,
  ): Promise<GetTicketsBodyFilterItem[] | ResponseError> =>
    await requestService.get(`/api/v1/filter-templates/${id}/filters?windowType=${windowType}`)

  public static createFitlerTemplate = async (payload: {
    filters: GetTicketsBodyFilterItem[]
    name: string
    windowType: WindowType
  }): Promise<{ availableTemplates: { id: string; name: string } } | ResponseError> =>
    await requestService.post('/api/v1/filter-templates', payload)

  public static updateSdFilterTemplate = async (payload: {
    filters: any
    name: string
    windowType: WindowType
    id: string
    translate: any
  }): Promise<{ availableTemplates: { id: string; name: string }[] } | ResponseError> =>
    await requestService.put(`/api/v1/filter-templates/${payload.id}`, {
      filters: payload.filters,
      name: payload.name,
      windowType: payload.windowType,
    })

  public static deleteSdFilterTemplate = async (payload: {
    id: string
    windowType: WindowType
  }): Promise<any> =>
    await requestService.delete(
      `/api/v1/filter-templates/${payload.id}?windowType=${payload.windowType}`,
      true,
    )

  public static async getFiltersTemplates(): Promise<FilterTemplate[] | ResponseError> {
    try {
      return new Promise(resolve =>
        window.setTimeout(() => {
          resolve(filterTemplates.filters)
        }, 3000),
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getServiceCompanies(
    deviceId: number,
  ): Promise<ServiceCompaniesResponse | ResponseError> {
    try {
      const response = await requestService.get(
        `/tickets/api/v1/service-company?deviceId=${deviceId}`,
      )

      return response.serviceCompanies
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async createTicket(
    data: CreateTicketRequestBody,
  ): Promise<CreateTicketResponse | ResponseError> {
    try {
      return await requestService.post('/tickets/api/v1/sd-tickets', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async attachFiles(data: {
    ticketId: number
    files: File[]
  }): Promise<AttachFilesResponse | ResponseError> {
    try {
      return await requestService.postFormData(
        `/tickets/api/v1/sd-tickets/${data.ticketId}/files`,
        {
          files: data.files,
        },
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async createGroupTemplate(payload: GroupTemplate): Promise<{} | ResponseError> {
    try {
      return await requestService.post('/api/v1/grouping-templates', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async editSdGroupTemplate(payload: {
    template: UIGroupTemplate
    windowType: WindowType
  }): Promise<ApiGroupTemplateResponse | ResponseError> {
    const {
      template: { id, name, config },
      windowType,
    } = payload
    return await requestService.put(`/api/v1/grouping-templates/${id}`, {
      groupingLevels: groupTemplateUiToApiView(config),
      name: name,
      windowType: windowType,
    })
  }

  public static deleteSdGroupTemplate = async (payload: {
    template: UIGroupTemplate
    windowType: WindowType
  }): Promise<{} | ResponseError> =>
    await requestService.delete(
      `/api/v1/grouping-templates/${payload.template.id}?windowType=${payload.windowType}`,
    )

  public static async getSdGroupTemplates(
    windowType: string,
  ): Promise<{ availableTemplates: { id: string; name: string }[] } | ResponseError> {
    try {
      return await requestService.get('/api/v1/grouping-templates', { windowType })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getSdGroupTemplateConfigById(
    id: string,
    windowType: WindowType,
  ): Promise<ApiGroupTemplate[] | ResponseError> {
    try {
      return await requestService.get(`/api/v1/grouping-templates/${id}/grouping-levels`, {
        windowType,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getGroupingTemplates(): Promise<GroupingTemplate[] | ResponseError> {
    try {
      return new Promise(resolve =>
        window.setTimeout(() => {
          resolve(groupingsTemplates.groupings)
        }, 3000),
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getAdvancedSearchFiltersConfig(): Promise<
    AdvancedSearchFiltersConfig | ResponseError
  > {
    try {
      return await requestService.get('/api/v1/search-form/advanced/config')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getFormConfig(data: {
    formUID: string
    getConfigURL: string
  }): Promise<TicketsConfigResponse | ResponseError> {
    try {
      return requestService.post('/api/v1/search-form/config', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupValues(
    data: Partial<LookupValuesRequest | LookupDictionaryRequest>,
  ): Promise<LookupValuesResponse> {
    try {
      if (data.getValuesURL && data.getValuesURL === 'jasperReportsParams') {
        return requestService.post('/reports/api/v1/lookups/report/lookups', {
          lookupKey: data.entityName,
          parameters: data.dependedOnFilter,
          searchFragment: data.searchFragment,
        })
      } else {
        return requestService.post('/api/v1/search-form/lookup', data)
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getDropdownListsData(
    data: Array<DropdownListsPayload>,
  ): Promise<DropdownListsResponse> {
    try {
      return requestService.post('/atmeye/api/v1/atmeye-dropdown-lists/data', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getReportsLookupsData(
    data: Array<DropdownListsPayload>,
  ): Promise<Array<ReportLookupValuesResponse>> {
    try {
      return requestService.post('/reports/api/v1/report-parameter-values', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getWorkItemsTableData(): Promise<WorkItemsTableData[] | ResponseError> {
    try {
      return new Promise(resolve =>
        window.setTimeout(() => {
          resolve(workItemsTableData)
        }, 1000),
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getServiceItemsTableData(): Promise<ServiceItemstableData[] | ResponseError> {
    try {
      return new Promise(resolve =>
        window.setTimeout(() => {
          resolve(serviceItemsTableData)
        }, 1000),
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getTicketDetails(id: string): Promise<TicketDetailsResponse | ResponseError> {
    try {
      return await requestService.get(`/tickets/api/v1/sd-tickets/${id}/details`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getTicketDetailsFile(
    fileId: string,
    ticketId: string,
  ): Promise<any | ResponseError> {
    try {
      return await requestService.get(
        `/tickets/api/v1/sd-tickets/${ticketId}/files/${fileId}`,
        {},
        { responseType: 'blob' },
      )
    } catch (e) {
      throw new Error(e)
    }
  }
}
