import { Checkbox, TableCell, TableRow } from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'
import TableHead from '@material-ui/core/TableHead'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import React, { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'

import { dummyFunction } from '@/utils/functions'

import { useClasses } from './styles'
import { Props } from './types'

const TableHeader: FC<Props> = ({
  isCheckboxTable,
  isHeaderCheckboxDisabled,
  isHeaderCheckboxSelected,
  isHideSortIcon,

  keyName,
  keyLabel,
  headers,

  sorting,

  setColumns,
  handleSort,
  onHeaderCheckboxClick,
}: Props): ReactElement => {
  const classes = useClasses()

  const { spacing } = useTheme()
  const [forceWidth, setForceWidth] = useState<number | null>(null)
  const [dragStarted, setDragStarted] = useState<boolean>(false)
  const [initialWidth, setInitialWidth] = useState<number>()

  const cellRef = useRef<HTMLElement>()

  const cellMinWidth = spacing(20)

  const handleDragEnd = useCallback<(result: DropResult, provided: ResponderProvided) => void>(
    (result): void => {
      setDragStarted(false)
      if (!result.destination) {
        return
      }

      const movedItem = headers[result.source.index]
      const movedItemIndex = headers.findIndex(
        (value: any) => value === headers[result.source.index],
      )
      const supportItemIndex = headers.findIndex(
        (value: any) => result.destination && value === headers[result.destination.index],
      )

      const newHeaders = headers.slice()

      newHeaders.splice(
        movedItemIndex < supportItemIndex ? supportItemIndex + 1 : supportItemIndex,
        0,
        movedItem,
      )
      newHeaders.splice(movedItemIndex > supportItemIndex ? movedItemIndex + 1 : movedItemIndex, 1)

      setColumns && setColumns(newHeaders)
    },
    [headers, setColumns],
  )

  useEffect((): void => {
    if (cellRef.current) {
      if (dragStarted) {
        setForceWidth(cellRef.current.offsetWidth || cellRef.current.scrollWidth)
      } else {
        setForceWidth(null)
      }
    }
  }, [dragStarted, cellRef])

  useEffect((): void => {
    if (cellRef && cellRef.current && !initialWidth) {
      setInitialWidth(cellRef.current.offsetWidth || cellRef.current.scrollWidth)
    }
  }, [cellRef, initialWidth])

  return (
    <TableHead className={classes.thead}>
      <DragDropContext
        onBeforeDragStart={(): void => setDragStarted(true)}
        onDragEnd={handleDragEnd}
      >
        <Droppable droppableId="tableHeader" direction="horizontal">
          {(provided: DroppableProvided): ReactElement => (
            <TableRow
              className={classes.tableHeader}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {isCheckboxTable && (
                <Checkbox
                  disabled={isHeaderCheckboxDisabled}
                  checked={isHeaderCheckboxSelected}
                  onClick={e => {
                    e.stopPropagation()
                    onHeaderCheckboxClick && onHeaderCheckboxClick()
                  }}
                />
              )}

              {!!headers.length &&
                headers.map((el: any, index: number) => (
                  <Draggable
                    key={`${el.fieldName} ${index}`}
                    draggableId={el[keyLabel]}
                    index={index}
                  >
                    {(
                      { innerRef, draggableProps, dragHandleProps }: DraggableProvided,
                      { isDragging }: DraggableStateSnapshot,
                    ): ReactElement => {
                      return (isDragging ? createPortal : dummyFunction)(
                        <TableCell
                          key={el.fieldLabel}
                          align="left"
                          className={clsx(classes.tableCell, classes.headerCell)}
                          {...draggableProps}
                          {...dragHandleProps}
                          ref={(ref): void => {
                            innerRef(ref as HTMLElement)
                            cellRef.current = ref as HTMLElement
                          }}
                          style={{
                            ...draggableProps.style,
                            ...(forceWidth
                              ? {
                                  width: forceWidth,
                                }
                              : {}),
                            ...(initialWidth
                              ? initialWidth <= cellMinWidth
                                ? { width: cellMinWidth }
                                : {}
                              : {}),
                          }}
                        >
                          <TableSortLabel
                            active={sorting?.fieldName === el[keyLabel]}
                            direction={sorting?.sortOrder || 'asc'}
                            hideSortIcon={isHideSortIcon?.(el[keyLabel])}
                            onClick={(): void => handleSort(el[keyLabel])}
                          >
                            <Typography variant="h6" className={classes.title}>
                              {el[keyName]}
                            </Typography>
                          </TableSortLabel>
                        </TableCell>,
                        document.body,
                      )
                    }}
                  </Draggable>
                ))}
            </TableRow>
          )}
        </Droppable>
      </DragDropContext>
    </TableHead>
  )
}

export default TableHeader
