import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { BlackListApi } from '@/api/atmeye/blackListApi'
import { GroupingInfo, GroupingTemplate } from '@/api/atmeye/blackListApi/types'
import { MODAL_TYPE } from '@/components/NPMPakage/components/modals/TemplateModal/component'
import { useTranslate } from '@/utils/internalization'
import { PopUpService } from '@/utils/services/popUpService'

type useGroupingTemplatesProps = {
  appliedGroupingTemplate: GroupingTemplate | null
  groups: GroupingInfo
  setGroups: any
  groupingTemplatesType: string
  defaultGrouping?: GroupingInfo
}

const useGroupingTemplates = ({
  appliedGroupingTemplate,
  groups,
  setGroups,
  groupingTemplatesType,
  defaultGrouping,
}: useGroupingTemplatesProps) => {
  const translate = useTranslate()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [modalType, setModalType] = useState<MODAL_TYPE>(MODAL_TYPE.MAIN)

  const showModal = useCallback((modalType: MODAL_TYPE): void => {
    setModalType(modalType)
    setAnchorEl(null)
  }, [])

  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false)
  const [templates, setTempaltes] = useState<GroupingTemplate[]>([])

  const [selectedTemplate, setSelectedTemplate] = useState<GroupingTemplate | null>(
    appliedGroupingTemplate,
  )

  const getGroupTemplatesRequest = useCallback(async () => {
    setIsLoadingTemplates(true)
    try {
      const response = await BlackListApi.getListFromJsonStorage<GroupingTemplate>({
        type: groupingTemplatesType,
      })

      setTempaltes(response.content)
    } catch (e) {
      console.log('Error')
    } finally {
      setIsLoadingTemplates(false)
    }
  }, [])

  useEffect((): void => {
    getGroupTemplatesRequest()
  }, [getGroupTemplatesRequest])

  const onChangeSelectedTemplate = useCallback(
    async newTemplateId => {
      const template = templates.find(template => template.id === +newTemplateId)

      if (!newTemplateId || !template) {
        setGroups(defaultGrouping || [])
        setSelectedTemplate(null)
        return
      }

      setSelectedTemplate(template)
      setGroups(template.json)
    },
    [templates],
  )

  useEffect(() => {
    if (selectedTemplate && templates.length) {
      const template = templates.find(template => template.id === selectedTemplate.id) || null
      setSelectedTemplate(template)
    }
  }, [templates])

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = (): void => {
    setAnchorEl(null)
  }

  const handleSaveTemplate = useCallback(
    (inputValue: string) => async () => {
      const isUniqName = !templates.find(temp => temp.name === inputValue)

      if (!isUniqName) {
        PopUpService.showGlobalPopUp(`translate#title.sameTemplateName`, 'warning')
      } else {
        try {
          const addedTpl = await BlackListApi.createJsonStorageItem<GroupingInfo>({
            type: groupingTemplatesType,
            json: groups,
            name: inputValue,
          })

          setSelectedTemplate(addedTpl)
          await getGroupTemplatesRequest()
          PopUpService.showGlobalPopUp('translate#title.templateSaved', 'success')
          showModal(MODAL_TYPE.MAIN)
        } catch (e) {
          return Promise.reject(e)
        }
      }
    },
    [groups, translate, showModal, templates],
  )

  const handleEditCurrentTemplate = useCallback(
    (inputValue: string) => async () => {
      if (selectedTemplate) {
        const isNotUniqName = templates?.some(
          el => el.name === inputValue && el.id !== selectedTemplate.id,
        )

        if (isNotUniqName) {
          PopUpService.showGlobalPopUp('translate#atmeye.message.templateAlreadyExist', 'info')
        } else {
          try {
            await BlackListApi.editJsonStorageItem<GroupingInfo>({
              json: groups,
              name: inputValue,
              id: selectedTemplate.id,
              type: selectedTemplate.type,
            })
            await getGroupTemplatesRequest()
            PopUpService.showGlobalPopUp('translate#title.templateEdited', 'success')
          } catch (error) {
            PopUpService.showGlobalPopUp(
              `${translate('translate#title.templateEditError')} ${error}`,
              'error',
            )
            console.log(error)
            return
          }

          setModalType(MODAL_TYPE.MAIN)
        }
      }
    },
    [templates, selectedTemplate, groups],
  )

  const handleDeleteTemplate = useCallback(async () => {
    try {
      if (selectedTemplate) {
        await BlackListApi.deleteJsonStorageItem(selectedTemplate.id)
        PopUpService.showGlobalPopUp('translate#title.templateRemoved', 'success')
        await getGroupTemplatesRequest()

        onChangeSelectedTemplate(null)
        // clearGrouping or setInitial groups
        setModalType(MODAL_TYPE.MAIN)
      }
    } catch (error) {
      PopUpService.showGlobalPopUp('translate#title.templateDeletionError', 'error')
    }
  }, [translate, selectedTemplate, getGroupTemplatesRequest, onChangeSelectedTemplate])

  const templatesOptions = useMemo(
    () =>
      templates.map(template => ({
        name: template.name,
        value: `${template.id}`,
      })),
    [templates],
  )

  return {
    selectedTemplate,
    templatesOptions,
    isLoadingTemplates,
    handleSaveTemplate,
    handleEditCurrentTemplate,
    handleDeleteTemplate,
    onChangeSelectedTemplate,
    handleOpenPopover,
    handleClosePopover,
    anchorEl,

    modalType,
    showModal,

    translate,
    templates,
  }
}

export { useGroupingTemplates }
