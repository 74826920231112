import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(() => ({
  loaderWrapper: {
    position: 'absolute',
    zIndex: 1000,
    height: '100%',
    width: '100%',
    opacity: 0.6,
    background: '#fff',
  },
}))
