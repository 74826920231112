import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles(({ spacing }) =>
  createStyles({
    noPermissions: {
      padding: spacing(1),
    },
    denied: {
      color: 'red',
    },
  }),
)
