import { AnyAction } from 'redux'

import {
  ComVaultReport,
  VaultBag,
  VaultBagLoadRequest,
  VaultBagsResponse,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { BagModalNames, ModalsNames } from '@/components/pages/vault-v1/Bags/types'
import { VaultV1BagsActions } from '@/constants/actions'
import { KeyPressActionName, Paging, Sort } from '@/types/cm'

import { AddEditProps, VaultBagsFilter } from './types'

// get All
interface GetBagsProps {
  initialPage?: boolean
}
export const getBagsRequest = (payload?: GetBagsProps): AnyAction => ({
  type: VaultV1BagsActions.BgsGetBagsRequest,
  payload,
})
export const getBagsResponse = (payload: VaultBagsResponse): AnyAction => ({
  type: VaultV1BagsActions.BgsGetBagsResponse,
  payload,
})
export const getBagsFail = (payload: string): AnyAction => ({
  type: VaultV1BagsActions.BgsGetBagsFail,
  payload,
})

// get Single
interface GeBagRequest {
  id?: string
  isDetailsRequest?: boolean
}
export const getBagRequest = (payload?: GeBagRequest): AnyAction => ({
  type: VaultV1BagsActions.BgsGetBagRequest,
  payload,
})
export const getBagResponse = (payload: VaultBag): AnyAction => ({
  type: VaultV1BagsActions.BgsGetBagResponse,
  payload,
})
export const getBagFail = (payload: string): AnyAction => ({
  type: VaultV1BagsActions.BgsGetBagFail,
  payload,
})

// selected
export const setSelectedBag = (payload: VaultBag | null): AnyAction => ({
  type: VaultV1BagsActions.BgsSetSelectedBag,
  payload,
})

// sort & paging
export const sortBags = (payload: Sort): AnyAction => ({
  type: VaultV1BagsActions.BgsSortBags,
  payload,
})
export const setBagPaging = (payload: Paging): AnyAction => ({
  type: VaultV1BagsActions.BgsPagingBags,
  payload,
})

// add & edit
export const addEditBagsRequest = (payload: AddEditProps): AnyAction => ({
  type: VaultV1BagsActions.BgsAddEditBagsRequest,
  payload,
})
export const addEditBagsResponse = (): AnyAction => ({
  type: VaultV1BagsActions.BgsAddEditBagsResponse,
})
export const addEditBagsFail = (): AnyAction => ({
  type: VaultV1BagsActions.BgsAddEditBagsFail,
})

// delete
export const deleteBagRequest = (payload: string): AnyAction => ({
  type: VaultV1BagsActions.BgsDeleteBagRequest,
  payload,
})
export const deleteBagFail = (): AnyAction => ({
  type: VaultV1BagsActions.BgsDeleteBagFail,
})

// filter
export const setBagFilter = (payload: VaultBagsFilter): AnyAction => ({
  type: VaultV1BagsActions.BgsSetFilter,
  payload,
})

// modals
export const setTableModal = (payload: { type: ModalsNames; isOpen: boolean }): AnyAction => ({
  type: VaultV1BagsActions.BgsSetModal,
  payload,
})
export const setBagModalType = (payload?: BagModalNames): AnyAction => ({
  type: VaultV1BagsActions.BgsSetBagModalType,
  payload,
})

// Load
export const loadBagRequest = (payload: VaultBagLoadRequest): AnyAction => ({
  type: VaultV1BagsActions.BgsLoadBagRequest,
  payload,
})
export const loadBagResponse = (): AnyAction => ({
  type: VaultV1BagsActions.BgsLoadBagResponse,
})
export const loadBagFail = (): AnyAction => ({
  type: VaultV1BagsActions.BgsLoadBagFail,
})

// Unload
export const unloadBagRequest = (payload: string): AnyAction => ({
  type: VaultV1BagsActions.BgsUnloadBagRequest,
  payload,
})
export const unloadBagResponse = (): AnyAction => ({
  type: VaultV1BagsActions.BgsUnloadBagResponse,
})
export const unloadBagFail = (): AnyAction => ({
  type: VaultV1BagsActions.BgsUnloadBagFail,
})

export const editBagRequest = (payload: VaultBagLoadRequest): AnyAction => ({
  type: VaultV1BagsActions.BgsEditBagRequest,
  payload,
})
export const editBagResponse = (): AnyAction => ({
  type: VaultV1BagsActions.BgsEditBagResponse,
})
export const editBagFail = (): AnyAction => ({
  type: VaultV1BagsActions.BgsEditBagFail,
})

export const setDetailsIsChanged = (payload: boolean): AnyAction => ({
  type: VaultV1BagsActions.BgsSetDetailsIsChanged,
  payload,
})

export const setSearchingFields = (payload: { [key: string]: string }): AnyAction => ({
  type: VaultV1BagsActions.BgsSetSearchingFields,
  payload,
})

export const handleBgsKeyPress = (actionName: KeyPressActionName): AnyAction => ({
  type: VaultV1BagsActions.BgsHandleKeyPress,
  payload: actionName,
})

// Reports -----------------------------
export const getBgsReportsRequest = (): AnyAction => ({
  type: VaultV1BagsActions.BgsGetReportsRequest,
})
export const getBgsReportsResponse = (payload: ComVaultReport[]): AnyAction => ({
  type: VaultV1BagsActions.BgsGetReportsResponse,
  payload,
})
export const getBgsReportsFail = (): AnyAction => ({
  type: VaultV1BagsActions.BgsGetReportsFail,
})

export const getBgsReportRequest = (payload?: ComVaultReport): AnyAction => ({
  type: VaultV1BagsActions.BgsGetReportRequest,
  payload,
})
export const getBgsReportResponse = (payload: Blob | null): AnyAction => ({
  type: VaultV1BagsActions.BgsGetReportResponse,
  payload,
})
export const getBgsReportFail = (): AnyAction => ({
  type: VaultV1BagsActions.BgsGetReportFail,
})
