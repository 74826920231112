import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { ApproveItemsCMApi, CMCommonApi } from '@/api/cm/commonApi'
import { IcsMissedReplenishmentResponse } from '@/api/cm/swaggerGeneratedApi'
import { AppNavigationRoutes, CMActions, CMAppConfigActions } from '@/constants'
import { getAnalysisChartsRequest } from '@/store/cm/analysis'
import { getBalCashPointsRequest } from '@/store/cm/balancing'
import { getCitBalCashPointsRequest } from '@/store/cm/citBalancing'
import { getCitPlnCashPointsRequest } from '@/store/cm/citPlanning'
import { getCashPointsRequest } from '@/store/cm/maps'
import { getCashPointsForMainRequest } from '@/store/cm/monitoring'
import { getOrdersRequest } from '@/store/cm/orders'
import { getPlnCashPointsRequest } from '@/store/cm/planning'
import { getPlasticCardsRequest, getPlcWorkOrdersRequest } from '@/store/cm/plasticCard'
import { getIcsOrdersRequest } from '@/store/cm/replenishments'
import { AppState } from '@/store/reducers'

import { getComAvailableCassetteNumbersResponse } from '.'
import {
  applyMissedReplenishmentEventFail,
  applyMissedReplenishmentEventResponse,
  calcMissedReplenishmentCassettesTotalFail,
  calcMissedReplenishmentCassettesTotalRequest,
  calcMissedReplenishmentCassettesTotalResponse,
  createMissedReplenishmentEventFail,
  createMissedReplenishmentEventResponse,
  getApproveItemsFail,
  getApproveItemsRequest,
  getApproveItemsResponse,
  getBuildInfoResponseCM,
  getBuildInfoResponseFailCM,
  getComCassetteCurrenciesFail,
  getComCassetteCurrenciesResponse,
  getComCurrenciesAndDenominationsResponse,
  getComIntegrationsResponse,
  getCompaniesRequestCM,
  getCompaniesResponseCM,
  getComTeamsResponse,
  getComVaultsWithLocationResponse,
  getCurrenciesResponseCM,
  getCurrenciesResponseFailCM,
  getFilterCashPointsFailCM,
  getFilterCashPointsResponseCM,
  getFilterCurrenciesResponseCM,
  getFilterCurrenciesResponseFailCM,
  getFilterNodesResponseCM,
  getFilterNodesResponseFailCM,
  getIcsMissedReplenishmentCartResponse,
  getIcsMissedReplenishmentEventsRequest,
  getIcsMissedReplenishmentEventsResponse,
  getIcsMissedReplenishmentFail,
  getMissedReplenishmentCassettesResponse,
  getSessionDocumentsReportFailCM,
  getSessionDocumentsReportResponseCM,
  getSessionDocumentsReportsFailCM,
  getSessionDocumentsReportsResponseCM,
  getUsersOptionsResponseCM,
  getUsersResponseCM,
  getUsersResponseFailCM,
  IcsGetMissedReplenishmentCassettesFail,
  setApproveItemsFail,
  setApproveItemsResponse,
  setComCMError,
  // ---------------------------
  setIcsMissedReplenishmentPagingResponse,
  setNewCompanyFail,
  setNewCompanyResponse,
} from './actions'
import { getApprovalItem, getBalancingCassettes } from './selectors'

function* setObjectTreeFilter(): SagaIterator {
  try {
    const { pathname } = window.location
    const {
      MonitoringPage,
      PlanningPage,
      AnalysisPage,
      BalancingPage,
      CashOrdersPage,
      CitBalancingPage,
      CitPlanningPage,
      MapsPage,
      ReplenishmentsPage,
      PlasticCardPage,
    } = AppNavigationRoutes.CashManagement

    if (pathname.includes(MonitoringPage)) {
      yield put(getCashPointsForMainRequest())
    }
    if (pathname.includes(PlanningPage)) {
      yield put(getPlnCashPointsRequest())
    }
    if (pathname.includes(AnalysisPage)) {
      yield put(getAnalysisChartsRequest())
    }
    if (pathname.includes(BalancingPage)) {
      yield put(getBalCashPointsRequest())
    }
    if (pathname.includes(CashOrdersPage)) {
      yield put(getOrdersRequest())
    }
    if (pathname.includes(CitBalancingPage)) {
      yield put(getCitBalCashPointsRequest())
    }
    if (pathname.includes(CitPlanningPage)) {
      yield put(getCitPlnCashPointsRequest())
    }
    if (pathname.includes(MapsPage)) {
      yield put(getCashPointsRequest())
    }
    if (pathname.includes(ReplenishmentsPage)) {
      yield put(getIcsOrdersRequest())
    }
    if (pathname.includes(PlasticCardPage)) {
      yield put(getPlasticCardsRequest())
      yield put(getPlcWorkOrdersRequest())
    }
  } catch (error) {
    yield put(setComCMError(error))
  }
}

function* getCompanies(): SagaIterator {
  try {
    const companies = yield call(CMCommonApi.getCompanies)
    yield put(getCompaniesResponseCM(companies))
  } catch (error) {
    yield put(setComCMError(error.message))
  }
}

function* setCompanies({ payload }: AnyAction): SagaIterator {
  try {
    yield call(CMCommonApi.setCompanies, payload)
    yield put(setNewCompanyResponse())
    yield put(getCompaniesRequestCM())
  } catch (error) {
    yield put(setNewCompanyFail(error.message))
  }
}

function* getAvailableCassetteNumbers(): SagaIterator {
  try {
    const { selectedCycleEndSessionId } = yield select(getBalancingCassettes)

    if (selectedCycleEndSessionId) {
      const response = yield call(
        CMCommonApi.getAvailableCassetteNumbers,
        selectedCycleEndSessionId,
      )
      yield put(getComAvailableCassetteNumbersResponse(response))
    }
  } catch (error) {
    yield put(setComCMError(error.message))
  }
}

function* getCassetteCurrencies(): SagaIterator {
  try {
    const currencies = yield call(CMCommonApi.getCassetteCurrencies)
    yield put(getComCassetteCurrenciesResponse(currencies))
  } catch (e) {
    yield put(getComCassetteCurrenciesFail(e))
  }
}

function* getCurrencies(): SagaIterator {
  try {
    const currencies = yield call(CMCommonApi.getCurrencies)
    yield put(getCurrenciesResponseCM(currencies))
  } catch (error) {
    yield put(getCurrenciesResponseFailCM(error.message))
  }
}

function* getDenominations(): SagaIterator {
  try {
    const denominations = yield call(CMCommonApi.getDenominations)
    yield put(getComCurrenciesAndDenominationsResponse(denominations))
  } catch (error) {
    yield put(setComCMError(error.message))
  }
}

function* getFilterCurrencies(): SagaIterator {
  try {
    const currencies = yield call(CMCommonApi.getFilterCurrencies)
    yield put(getFilterCurrenciesResponseCM(currencies))
  } catch (error) {
    yield put(getFilterCurrenciesResponseFailCM(error.message))
  }
}

function* getFilterNodes(): SagaIterator {
  try {
    const filterNodes = yield call(CMCommonApi.getFilterNodes)
    yield put(getFilterNodesResponseCM(filterNodes))
  } catch (error) {
    yield put(getFilterNodesResponseFailCM(error.message))
  }
}

function* getUsers({ payload }: AnyAction): SagaIterator {
  try {
    const users = yield call(CMCommonApi.getUsers, payload)

    yield put(getUsersResponseCM(users))
  } catch (error) {
    yield put(getUsersResponseFailCM(error))
  }
}

function* getUsersOperations(): SagaIterator {
  try {
    const usersOperations = yield call(CMCommonApi.getUsersOperations)
    yield put(getUsersOptionsResponseCM(usersOperations))
  } catch (error) {
    yield put(setComCMError(error))
  }
}

function* getSessionDocumentsReportsRequest({ payload }: any): SagaIterator {
  try {
    const reports = yield call(CMCommonApi.getReports, payload)

    yield put(getSessionDocumentsReportsResponseCM(reports.data))
  } catch (error) {
    yield put(getSessionDocumentsReportsFailCM(error))
  }
}

function* getSessionDocumentsReportRequest({ payload }: any): SagaIterator {
  try {
    if (payload) {
      const report = yield call(CMCommonApi.getReport, payload)

      yield put(getSessionDocumentsReportResponseCM(report))
    } else {
      yield put(getSessionDocumentsReportResponseCM(null))
    }
  } catch (error) {
    yield put(getSessionDocumentsReportFailCM(error))
  }
}

function* getFilterCashPointsRequest(): SagaIterator {
  try {
    const comCashPoints = yield call(CMCommonApi.getFilterCashPoints, { branchIds: [] })

    yield put(getFilterCashPointsResponseCM(comCashPoints))
  } catch (error) {
    yield put(getFilterCashPointsFailCM(error))
  }
}

function* getBuildInfo(): SagaIterator {
  try {
    const serverBuildInfo = yield call(CMCommonApi.getServerBuildInfo)
    const clientBuildInfo = yield call(CMCommonApi.getClientBuildInfo)

    yield put(getBuildInfoResponseCM({ clientBuildInfo, serverBuildInfo: serverBuildInfo?.info }))
  } catch (error) {
    yield put(getBuildInfoResponseFailCM())
  }
}

// ------------------------------------------------------------------------------------------

function* getMissedReplenishment(): any {
  try {
    yield put(getIcsMissedReplenishmentEventsRequest())

    const cashOrderId = yield select(
      (state: AppState) => state.cmCommon.missedReplenishment.selectedOrder.cpId,
    )
    const dateFrom = yield select((state: AppState) => state.cmCommon.missedReplenishment.dateFrom)
    const sort = yield select(state => ({
      sortColumn: state.cmCommon.missedReplenishment.events.sortColumn,
      sortOrder: state.cmCommon.missedReplenishment.events.sortOrder,
    }))
    const paging = yield select((state: AppState) => {
      const { page, pageSize } = state.cmCommon.missedReplenishment.events
      return { page, pageSize }
    })

    const response: IcsMissedReplenishmentResponse = yield call(
      CMCommonApi.getMissedReplenishment,
      {
        requestBody: { cashPointId: cashOrderId, dateFrom },
        paging,
        sort,
      },
    )

    if (response.chart) {
      yield put(getIcsMissedReplenishmentCartResponse(response.chart))
    }

    yield put(
      getIcsMissedReplenishmentEventsResponse({
        cassettesNames: response.cassettesNames || [],
        totalEvents: response.totalEvents || 0,
        events: response.events || [],
        lastEventDate: response.lastEventDate || '',
      }),
    )
  } catch (error) {
    yield put(getIcsMissedReplenishmentFail(error))
  }
}

function* handleMissedReplenishmentPagingChange({ payload }: AnyAction): SagaIterator {
  yield put(getIcsMissedReplenishmentEventsRequest())
  yield put(setIcsMissedReplenishmentPagingResponse(payload))
  yield getMissedReplenishment()
}

function* updateMissedIcsCassettes(): any {
  try {
    yield put(calcMissedReplenishmentCassettesTotalRequest())

    const { data: icsCassettes, eventType } = yield select(
      (state: AppState) => state.cmCommon.missedReplenishment.cassettes,
    )

    const response = yield call(CMCommonApi.updateMissedIcsCassettes, {
      cassettes: icsCassettes,
      eventType,
    })

    yield put(calcMissedReplenishmentCassettesTotalResponse(response))
  } catch (error) {
    yield put(calcMissedReplenishmentCassettesTotalFail(error))
  }
}

function* getMissedReplenishmentCassettes({ payload }: AnyAction): SagaIterator {
  try {
    const response = yield call(CMCommonApi.getMissedReplenishmentCassettes, payload)

    if (response) {
      yield put(getMissedReplenishmentCassettesResponse(response.cassettes))
      yield updateMissedIcsCassettes()
    }
  } catch (error) {
    yield put(IcsGetMissedReplenishmentCassettesFail(error))
  }
}

function* createMissedReplenishmentEvent({ payload }: AnyAction): SagaIterator {
  try {
    const cashOrderId = yield select(
      (state: AppState) => state.cmCommon.missedReplenishment.selectedOrder.id,
    )

    yield call(CMCommonApi.createMissedReplenishmentEvent, {
      cashOrderId,
      ...payload,
    })

    yield put(getIcsMissedReplenishmentEventsRequest())
    yield getMissedReplenishment()
    yield put(createMissedReplenishmentEventResponse())
  } catch (error) {
    yield put(createMissedReplenishmentEventFail(error))
  }
}

function* applyMissedReplenishmentEvent({ payload }: AnyAction): SagaIterator {
  try {
    const cashOrderId = yield select(
      (state: AppState) => state.cmCommon.missedReplenishment.selectedOrder.id,
    )

    yield call(CMCommonApi.applyMissedReplenishmentEvent, {
      event: payload,
      cashOrderId,
    })
    yield put(applyMissedReplenishmentEventResponse())
  } catch (error) {
    yield put(applyMissedReplenishmentEventFail())
  }
}

function* getIntegrations(): SagaIterator {
  try {
    const integrations = yield call(CMCommonApi.getIntegration)

    yield put(getComIntegrationsResponse(integrations))
  } catch (error) {
    yield put(setComCMError(error))
  }
}

function* getVaultsWithLocation(): SagaIterator {
  try {
    const vaultsWithLocation = yield call(CMCommonApi.getVaultsWithLocation)

    yield put(getComVaultsWithLocationResponse(vaultsWithLocation))
  } catch (error) {
    yield put(setComCMError(error))
  }
}

function* getTeams(): SagaIterator {
  try {
    const teams = yield call(CMCommonApi.getTeams)

    yield put(getComTeamsResponse(teams))
  } catch (error) {
    yield put(setComCMError(error))
  }
}

// Approve Items ----------------------------------------------------
function* getApproveItems(): SagaIterator {
  try {
    const { module } = yield select(getApprovalItem)

    const response = yield call(ApproveItemsCMApi.get, module)

    yield put(getApproveItemsResponse(response))
  } catch (error) {
    yield put(getApproveItemsFail(error))
  }
}

function* setApproveItems({ payload }: AnyAction): SagaIterator {
  try {
    const { approvedItems, hasApproveItem } = payload

    yield call(ApproveItemsCMApi.set, approvedItems)

    if (hasApproveItem) yield put(setApproveItemsResponse())
    yield put(getApproveItemsRequest())
  } catch (error) {
    yield put(setApproveItemsFail(error))
  }
}

export default function*(): Generator {
  yield takeLatest(CMActions.SetObjectTreeFilter, setObjectTreeFilter)
  yield takeLatest(CMActions.ComGetCompaniesRequest, getCompanies)
  yield takeLatest(CMActions.ComSetNewCompanyRequest, setCompanies)

  yield takeLatest(CMActions.GetCurrenciesRequest, getCurrencies)
  yield takeLatest(CMActions.ComGetCurrenciesAndDenominationsRequest, getDenominations)

  yield takeLatest(CMActions.ComGetAvailableCassetteNumbersRequest, getAvailableCassetteNumbers)

  yield takeLatest(CMActions.GetFilterCurrenciesRequest, getFilterCurrencies)
  yield takeLatest(
    [
      CMActions.GetFilterNodesRequest,
      CMActions.GetCashPointsForMainRequest,
      CMActions.PlnGetCashPointsRequest,
      CMActions.CitPlnGetCashPointsRequest,
      CMActions.OrdGetOrdersRequest,
      CMActions.IcsGetOrdersRequest,
      CMActions.BalGetCashPointsRequest,
      CMActions.CitBalGetCashPointsRequest,
      CMActions.PlcGetPlasticCardsRequest,
    ],
    getFilterNodes,
  )
  yield takeLatest(CMActions.ComGetUsersRequest, getUsers)
  yield takeLatest(CMActions.ComGetUsersOptionsRequest, getUsersOperations)

  yield takeLatest(CMActions.ComGetCassetteCurrenciesRequest, getCassetteCurrencies)

  yield takeLatest(
    CMActions.ComGetSessionDocumentsReportsRequest,
    getSessionDocumentsReportsRequest,
  )
  yield takeLatest(CMActions.ComGetSessionDocumentsReportRequest, getSessionDocumentsReportRequest)
  yield takeLatest(CMActions.ComGetFilterTIDCashPointsRequest, getFilterCashPointsRequest)
  yield takeLatest(CMActions.ComGetBuildInfoRequestCM, getBuildInfo)

  // --------------------------------------------
  yield takeLatest(CMActions.IcsGetMissedReplenishmentRequest, getMissedReplenishment)
  yield takeLatest(CMActions.IcsSortMissedReplenishmentEventsTable, getMissedReplenishment)
  yield takeLatest(
    CMActions.IcsSetMissedReplenishmentPagingRequest,
    handleMissedReplenishmentPagingChange,
  )
  yield takeLatest(
    CMActions.IcsGetMissedReplenishmentCassettesRequest,
    getMissedReplenishmentCassettes,
  )
  yield takeLatest(
    CMActions.IcsCreateMissedReplenishmentEventRequest,
    createMissedReplenishmentEvent,
  )
  yield takeLatest(CMActions.IcsApplyMissedReplenishmentEventRequest, applyMissedReplenishmentEvent)
  yield takeLatest(CMActions.IcsGetMissedReplenishmentCassettesUpdate, updateMissedIcsCassettes)

  yield takeLatest(CMActions.IcsGetMissedReplenishmentCassettesUpdate, updateMissedIcsCassettes)
  // -------------------------------------------------------------

  yield takeLatest(
    [CMAppConfigActions.AdmGetApplicationConfigRequest, CMActions.ComGetIntegrationsRequest],
    getIntegrations,
  )

  yield takeLatest(CMActions.ComGetVaultsWithLocationRequest, getVaultsWithLocation)

  yield takeLatest(CMActions.ComGetTeamsRequest, getTeams)

  // Approve Items --------------------------------------------
  yield takeLatest(CMActions.ComGetApproveItemsRequest, getApproveItems)
  yield takeLatest(CMActions.ComSetApproveItemsRequest, setApproveItems)
}
