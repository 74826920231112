import { Theme } from '@material-ui/core/styles'
import makeStyles from '@material-ui/core/styles/makeStyles'

type Props = {
  isUsersValues?: boolean
}

export const useClasses = makeStyles<Theme & Props>(({ spacing }) => ({
  wrapper: {
    margin: '30px 30px 34px 30px',
  },

  headerTitle: {
    margin: '0',
    fontSize: '20px',
    fontStyle: 'normal',
    color: '#041517',
    marginBottom: '20px',
  },

  closingWindow: {
    position: 'absolute',
    right: '-20px',
    top: '-20px',
    cursor: 'pointer',
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  mergeObjectsInputsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: spacing(3),
  },
  mergeObjectsWrapper: {
    display: 'grid',
    gridTemplateColumns: ({ isUsersValues }: Props): string =>
      isUsersValues ? '1fr 1px 1fr' : 'repeat(2, 1fr)',
    // gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: spacing(3),
  },
  mergeObjectsTableWrapper: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },

  saveButton: {
    height: '40px',
    background: '#25ADBF',
    color: 'white',
    fontSize: '14px',
    marginLeft: '20px',
    '&:hover': {
      background: '#1a7c89',
    },
  },

  cancelButton: {
    height: '40px',
    color: '#929A9B',
    backgroundColor: '#FFFFFF',
    border: '1px solid #929A9B',
    borderRadius: '3px',
  },

  buttonsStyle: {
    paddingTop: spacing(2.5),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  CheckBox: {
    position: 'relative',
    right: '12px',
  },

  iconWidth: {
    margin: '-18px !important',
  },
}))
