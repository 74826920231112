import makeStyles from '@material-ui/core/styles/makeStyles'

import colors from '@/theme/colors'

export const useClasses = makeStyles(() => ({
  closeButton: {
    marginLeft: 'auto',

    '& [class*="makeStyles-icon"]': {
      color: colors.products.black,
    },
  },

  dialogActions: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    fontSize: '20px',
    fontWeight: 500,
    position: 'absolute',
    left: '48px',
    top: '16px',
  },
}))
