import { AnyAction } from 'redux'

import { DashboardActions } from '@/constants/actions'
import {
  ChartData,
  ChartExtendedData,
  Company,
  Device,
  GetTicketsResponse,
  ResponseError,
  TicketsConfigResponse,
} from '@/types'
import { RefreshTimout } from '@/types/sd/dashboard'

export const getChartDataRequest = (): AnyAction => ({
  type: DashboardActions.GetChartDataRequest,
})
export const getChartDataResponse = (chartData: ChartData): AnyAction => ({
  type: DashboardActions.GetChartDataResponse,
  payload: chartData,
})
export const getChartDataResponseFail = (error: ResponseError): AnyAction => ({
  type: DashboardActions.GetChartDataResponseFail,
  payload: error,
})

export const getChartExtendedDataRequest = (): AnyAction => ({
  type: DashboardActions.GetChartExtendedDataRequest,
})
export const getChartExtendedDataResponse = (chartData: ChartExtendedData): AnyAction => ({
  type: DashboardActions.GetChartExtendedDataResponse,
  payload: chartData,
})
export const getChartExtendedDataResponseFail = (error: ResponseError): AnyAction => ({
  type: DashboardActions.GetChartExtendedDataResponseFail,
  payload: error,
})
export const setRefreshTimout = (timeout: RefreshTimout): AnyAction => ({
  type: DashboardActions.SetRefreshTimout,
  payload: timeout,
})
export const setCurrentWidgetId = (id: string): AnyAction => ({
  type: DashboardActions.SetCurrentWidgetId,
  payload: id,
})
export const deleteCompletedTimer = (id: string): AnyAction => ({
  type: DashboardActions.DeleteCompletedTimer,
  payload: id,
})

export const getAllDevices = (): AnyAction => ({
  type: DashboardActions.GetAllDevicesRequest,
})
export const getAllDevicesResponse = (devices: Device[]): AnyAction => ({
  type: DashboardActions.GetAllDevicesResponse,
  payload: devices,
})
export const getAllDevicesResponseFail = (error: ResponseError): AnyAction => ({
  type: DashboardActions.GetAllDevicesResponseFail,
  payload: error,
})

export const getAllCompanies = (): AnyAction => ({
  type: DashboardActions.GetAllCompaniesRequest,
})
export const getAllCompaniesResponse = (companies: Company[]): AnyAction => ({
  type: DashboardActions.GetAllCompaniesResponse,
  payload: companies,
})
export const getAllCompaniesResponseFail = (error: ResponseError): AnyAction => ({
  type: DashboardActions.GetAllCompaniesResponseFail,
  payload: error,
})

export const setDevices = (device: string[]): AnyAction => ({
  type: DashboardActions.SetDevice,
  payload: device,
})
export const setInitialSelectedDevice = () => ({
  type: DashboardActions.SetInitialDevice,
})
export const setStatus = (status: string): AnyAction => ({
  type: DashboardActions.SetStatus,
  payload: status,
})
export const setTicketsRowsPerPage = (rowsCount: number): AnyAction => ({
  type: DashboardActions.SetRowPerPage,
  payload: rowsCount,
})
export const setTicketsPage = (page: number): AnyAction => ({
  type: DashboardActions.SetPage,
  payload: page,
})
export const setSorting = (sorting: { [key: string]: 'asc' | 'desc' } | null): AnyAction => ({
  type: DashboardActions.SetSorting,
  payload: sorting,
})

export const getTicketsConfigRequest = (): AnyAction => ({
  type: DashboardActions.GetTicketsConfigRequest,
})
export const getTicketsConfigReponse = (formConfigData: TicketsConfigResponse): AnyAction => ({
  type: DashboardActions.GetTicketsConfigResponse,
  payload: formConfigData.formConfig,
})
export const getTicketsConfigResponseFail = (error: ResponseError): AnyAction => ({
  type: DashboardActions.GetTicketsConfigResponseFail,
  payload: error,
})

export const getTicketsRequest = (): AnyAction => ({
  type: DashboardActions.GetTicketsRequest,
})
export const getTicketsResponse = (tickets: GetTicketsResponse): AnyAction => ({
  type: DashboardActions.GetTicketsResponse,
  payload: tickets,
})
export const getTicketsResponseFail = (error: ResponseError): AnyAction => ({
  type: DashboardActions.GetTicketsResponseFail,
  payload: error,
})
export const setSocketData = (data: any): AnyAction => ({
  type: DashboardActions.SetSocketData,
  payload: data,
})
export const setSocketItemEmpty = (command: string): AnyAction => ({
  type: DashboardActions.SetSocketItemEmpty,
  payload: command,
})
export const setSocketDataError = (message: string): AnyAction => ({
  type: DashboardActions.SetSocketDataError,
  payload: message,
})

export const setSocketMessageError = (payload: { [command: string]: string }): AnyAction => ({
  type: DashboardActions.SetSocketMessageError,
  payload,
})

export const clearSocketDataError = (): AnyAction => ({
  type: DashboardActions.ClearSocketDataError,
})

export const clearSocketData = (): AnyAction => ({ type: DashboardActions.ClearSocketData })
export const clearSocketDataByMessage = (payload: string): AnyAction => ({
  type: DashboardActions.ClearSocketDataByMessage,
  payload,
})
