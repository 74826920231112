const GLOBAL = window.CM_MODE

export const REACT_APP_CM_STANDALONE =
  GLOBAL?.REACT_APP_CM_STANDALONE || process?.env?.REACT_APP_CM_STANDALONE || 'false'

export const REACT_APP_VAULT_V1_STANDALONE =
  GLOBAL?.REACT_APP_VAULT_V1_STANDALONE || process?.env?.REACT_APP_VAULT_V1_STANDALONE || 'false'

export const REACT_APP_SD_STANDALONE =
  GLOBAL?.REACT_APP_SD_STANDALONE || process?.env?.REACT_APP_SD_STANDALONE || 'false'
