export enum AtmeyeReportsActions {
  GetDevicesRequest = '@/REPORTS/GET_DEVICES_REQUEST1',
  ResetGroups = '@/REPORTS/RESET_GROUPS',
  GetTicketResponse = '@/REPORTS/GET_TICKET_RESPONSE',
  GetTicketResponseFail = '@/REPORTS/GET_TICKET_RESPONSE_FAIL',
  GetFiltersTemplatesRequest = '@/REPORTS/GET_FILTERS_TEMPLATES_REQUEST',
  SetFilterTemplatesResponse = '@/REPORTS/SET_FILTER_TEMPLATE_RESPONSE',
  CreateFilterTemplateRequest = '@/REPORTS/CREATE_FILTER_TEMPLATE_REQUEST',
  UpdateFilterTemplateRequest = '@/REPORTS/UPDATE_FILTER_TEMPLATE_REQUEST',
  DeleteFilterTemplateRequest = '@/REPORTS/DELETE_FILTER_TEMPLATE_REQUEST',
  GetGroupsTemplatesRequest = '@/REPORTS/GET_GROUPS_TEMPLATE_REQUEST',
  EditGroupTemplateRequest = '@/REPORTS/EDIT_GROUP_TEMPLATE_REQUEST',
  DeleteGroupTemplateRequest = '@/REPORTS/DELETE_GROUP_TEMPLATE_REQUEST',
  SaveGroupingTemplateRequest = '@/REPORTS/SAVE_GROUPING_TEMPLATE_REQUEST',
  GetDevicesConfigRequest = '@/REPORTS/GET_DEVICES_CONFIG_REQUEST',
  GetDevicesConfigResponse = '@/REPORTS/GET_DEVICES_CONFIG_RESPONSE',
  ChangeGrouping = '@/REPORTS/CHANGE_GROUPING',
  SetDataPagination = '@/REPORTS/SET_DATA_PAGINATION',
  ChangeSelectedFilters = '@/REPORTS/CHANGE_SELECTED_FILTERS',
  SetSelectedGroupingTemplate = '@/REPORTS/SET_SELECTED_GROUPING_TEMPLATE',
}
