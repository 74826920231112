import { FilterTypeDisplayName } from '@/components/pages/sd/reports/helpers/dynamicField/types'
import { ParametersItems } from '@/types/sd/reports/reports'

export const getInitialFormValuesForReportForm = (
  filtersFields: Array<ParametersItems>,
): { [key: string]: any } => {
  const initialValues: { [key: string]: any } = {}

  filtersFields.forEach((field: ParametersItems) => {
    const {
      __controltype,
      __id,
      allowArrayOfFilterValues,
      value,
      values,
      parameterDataValues,
    } = field
    const hasSingleOption = parameterDataValues && parameterDataValues.length === 0

    switch (__controltype) {
      case FilterTypeDisplayName.TEXT:
        initialValues[__id] = value || ''
        break
      case FilterTypeDisplayName.LIST:
        if (allowArrayOfFilterValues) {
          initialValues[__id] = hasSingleOption
            ? parameterDataValues[0] !== undefined
              ? parameterDataValues[0].__value
              : ''
            : values || []
          // initialValues[__id] =  values || []
        } else {
          initialValues[__id] = hasSingleOption
            ? parameterDataValues[0] !== undefined
              ? parameterDataValues[0].__value
              : ''
            : value || ''
          // initialValues[__id] = value || ''
        }
        break
      case FilterTypeDisplayName.RADIOBUTTON:
        initialValues[__id] = [null, null]
        break
      case FilterTypeDisplayName.CHECKBOX:
        initialValues[__id] = value ?? false
        break
      default:
        initialValues[__id] = ''
    }
  })

  return initialValues
}
