import { makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles(({ spacing }) => ({
  headerCell: {
    padding: spacing(0.5, 0),
  },
  columnCell: {
    verticalAlign: 'baseline',
  },

  sortersColumns: {
    background: 'rgb(98 189 195 / 25%)!important',
    borderLeft: '2px solid #F9F9F9!important',
    borderRight: '2px solid #F9F9F9!important',
  },

  cellText: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    display: 'block',
    width: '100%',
    padding: spacing(0, 2),
  },

  withoutPadding: {
    padding: 0,
  },
  sortWithCenterAlign: {
    marginLeft: '18px',
  },

  sortIcon: {
    marginRight: 0,
  },
}))
