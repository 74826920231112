import { ReactElement } from 'react'

import { BalUnloadedCassetteEntry } from '@/api/cm/swaggerGeneratedApi'
import {
  EditableColumns,
  SetBalUnloadedCassettes,
} from '@/components/blocks/cm/CashBalancingModal/components/UnloadedCassettes/types'

export interface Props {
  isSorters: boolean
  setBalUnloadedCassettes: SetBalUnloadedCassettes
}

export enum TableColumns {
  Denomination = 'denomination',
  CashIn = 'cashIn',
  BalanceDate = 'balanceDate',

  KioskCashInSystem = 'kioskCashInSystem',
  KioskCashInSlip = 'kioskCashInSlip',
  KioskCashInReal = 'kioskCashInReal',
}

export interface HeaderConfig {
  [key: string]: string | ReactElement
}

export interface CassetteValueChangeProps {
  cassette: Partial<BalUnloadedCassetteEntry>
  key: EditableColumns.CashIn | EditableColumns.KioskCashInSlip | EditableColumns.KioskCashInReal
}

export interface ConditionalCellProps {
  classes: {
    [key: string]: string
  }
  isSorters: boolean
  handleValueChange: ({
    cassette,
    key,
  }: CassetteValueChangeProps) => (newValue: string | number) => void
}
