import makeStyles from '@material-ui/core/styles/makeStyles'
import { isIE } from 'react-device-detect'
import { Theme } from '@material-ui/core/styles'

interface Props {
  filtersIsVisible: boolean
  modalHeight: number | null
}

export const useClasses = makeStyles<Theme, Props>(({ spacing }) => ({
  input: {
    width: '100%',
  },
  loadingWrapper: {
    height: '20vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    paddingTop: spacing(4),
    paddingBottom: spacing(4),
    height: ({ filtersIsVisible, modalHeight }: Props) =>
      (!filtersIsVisible && modalHeight) || '100%',
  },
  wrapper: {
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  collapseBtn: {
    color: '#000000',

    '&:hover': {
      background: 'none',
    },
  },
  fieldsWrapper: ({ filtersIsVisible }: Props) => ({
    marginTop: filtersIsVisible ? spacing(3) : 0,
  }),
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: isIE ? 'auto' : 1,
    overflow: 'auto',
    marginTop: spacing(2),
  },
}))
