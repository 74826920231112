import { useCallback, useEffect, useState } from 'react'

import { LocalStorageItems } from '@/constants'

const useTabModalWrapper = (isOnlyFullScreen?: boolean) => {
  const [isModalDetailsOpen, setIsModalDetailsOpen] = useState<boolean>(false)
  const [isExitConfirmModal, setIsExitConfirmModal] = useState<boolean>(false)
  const [isDefaultFormValue, setIsDefaultFormValue] = useState<boolean>(true)

  const isFullScreenLocalStorage = localStorage.getItem(LocalStorageItems.IsFullScreenMap)

  const [isFullScreen, setFullscreen] = useState<boolean>(
    JSON.parse(isFullScreenLocalStorage || 'false'),
  )

  const handleFullScreen = useCallback(() => {
    setFullscreen(value => !value)
  }, [])

  useEffect(() => {
    localStorage.setItem(LocalStorageItems.IsFullScreenMap, JSON.stringify(isFullScreen))
  }, [isFullScreen])

  useEffect(() => {
    isOnlyFullScreen && setFullscreen(true)
  }, [isOnlyFullScreen])

  return {
    isFullScreen,
    isExitConfirmModal,
    isModalDetailsOpen,
    isDefaultFormValue,

    handleFullScreen,
    setIsModalDetailsOpen,
    setIsExitConfirmModal,
    setIsDefaultFormValue,
  } as const
}

export { useTabModalWrapper }
