import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Done } from '@material-ui/icons'
import clsx from 'clsx'
import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import GlobalConfirmModal from '@/components/blocks/LogoutConfirmModal/component'
import Button from '@/components/controls/Button/index'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import { Props } from './types'

const FormFooter = ({ onCancel, isValid }: Props): React.ReactElement => {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const classes = useClasses()
  const translate = useTranslate()

  const handleToggleModal = useCallback(() => {
    setIsOpenModal(currentState => !currentState)
  }, [setIsOpenModal])

  const handleConfirm = useCallback(() => {
    onCancel()
    handleToggleModal()
  }, [handleToggleModal, onCancel])

  return (
    <div className={clsx(classes.wrapper, classes.buttons)}>
      <Box overflow="hidden">
        <Grid justifyContent="space-between" container>
          <Grid item>
            <Typography variant="body2">
              <FormattedMessage id="title.fieldsWith" defaultMessage="Fields with" />
              &nbsp;
              <span className={classes.asterisk}>*</span>
              &nbsp;
              <FormattedMessage id="title.areRequired" defaultMessage="are required" />!
            </Typography>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button onClick={handleToggleModal} width="md" variant="outlined">
                  <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  startIcon={<Done />}
                  variant="contained"
                  type="submit"
                  width="md"
                  disabled={!isValid}
                >
                  <FormattedMessage id="action.save" defaultMessage="Save" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <GlobalConfirmModal
        open={isOpenModal}
        onClose={handleToggleModal}
        onConfirm={handleConfirm}
        message={translate('translate#title.currentRequestWillBeClosedWithoutSaving')}
        disableBackdropClick
      />
    </div>
  )
}

export default FormFooter
