import { Typography } from '@material-ui/core'
import React, { useMemo } from 'react'
import { Cell, Pie, PieChart } from 'recharts'

import { LightTooltip, useClasses } from './styles'
import { Props } from './types'

export const MapMarkerATMeye = ({
  text,
  markerDetails,
  isClusterMarker,
  clusterMarkerInfo,
  onMouseOver,
}: Props): React.ReactElement => {
  const classes = useClasses({ isClusterMarker })

  const countIndicatorColors = useMemo(() => {
    const colors = clusterMarkerInfo.reduce(
      (colors, el) => {
        if (el.alarmCount > 0) {
          const red = colors.red + 1
          return { ...colors, red }
        }

        if (el.technicalEventCount > 0) {
          const yellow = colors.yellow + 1
          return { ...colors, yellow }
        }

        return { ...colors, green: colors.green + 1 }
      },
      { green: 0, red: 0, yellow: 0 },
    )

    return [
      { value: colors.green, color: '#00C562' },
      { value: colors.red, color: '#FF7362' },
      { value: colors.yellow, color: '#FEDA59' },
    ]
  }, [clusterMarkerInfo])

  const marker = (
    <div onMouseOver={onMouseOver} className={classes.wrapper}>
      <PieChart width={130} height={130}>
        <Pie data={countIndicatorColors} dataKey="value" outerRadius={23}>
          {countIndicatorColors.map((entry, index) => (
            <Cell key={index} fill={entry.color} />
          ))}
        </Pie>

        <Pie data={countIndicatorColors} dataKey="value" innerRadius={23} outerRadius={40}>
          {countIndicatorColors.map((entry, index) => (
            <Cell key={index} fill={entry.color} opacity={0.4} />
          ))}
        </Pie>

        <Pie data={countIndicatorColors} dataKey="value" innerRadius={40} outerRadius={62}>
          {countIndicatorColors.map((entry, index) => (
            <Cell key={index} fill={entry.color} opacity={0.2} />
          ))}
        </Pie>
      </PieChart>

      <div className={classes.textWrapper}>
        <Typography className={classes.text} variant="caption">
          {text}
        </Typography>
      </div>
    </div>
  )

  if (markerDetails) {
    return (
      <LightTooltip title={markerDetails} interactive arrow>
        {marker}
      </LightTooltip>
    )
  }

  return marker
}
