import { AnyAction } from 'redux'

import {
  AdmNetworkNode,
  AdmNetworkTransceiver,
  AdmNetworkVault,
  FilterTreeNode,
} from '@/api/vault-v1/swaggerGeneratedApi'
import {
  appFoldersWithCollapse,
  parseElementPath,
} from '@/components/pages/cm/AdminPage/components/panels/Objects/components/Networks/helpers'
import { VaultV1Actions } from '@/constants/actions'

export type SelectedNode = {
  id: string
  parentId: string | null
  isFolder: boolean
  isScrolling?: boolean
  name?: string
} | null

export type State = {
  node: { node: AdmNetworkNode | null; isLoaded: boolean; isLoading: boolean }
  nodes: {
    selectedNode: SelectedNode | null
    data: FilterTreeNode
    isLoaded: boolean
    isLoading: boolean

    openedElements: string[]
  }

  vaults: {
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined
    total: number
    data: AdmNetworkVault[]
    isLoaded: boolean
    isLoading: boolean

    vaultName: string

    paging: {
      page: number
      pageSize: number
    }
  }

  transceivers: {
    isModalOpen: boolean
    type?: 'ReceiveFrom' | 'TransferTo'

    data: AdmNetworkTransceiver[]
    isLoading: boolean
    isLoaded: boolean

    isUpdating: boolean
  }
}

const initialState: State = {
  node: { node: null, isLoaded: false, isLoading: false },
  nodes: {
    selectedNode: null,
    data: {},
    isLoaded: false,
    isLoading: false,

    openedElements: [],
  },

  vaults: {
    sortColumn: undefined,
    sortOrder: undefined,
    total: 0,
    data: [],
    isLoaded: false,
    isLoading: false,

    vaultName: '',

    paging: {
      page: 1,
      pageSize: 50,
    },
  },

  transceivers: {
    isModalOpen: false,
    type: undefined,

    data: [],
    isLoading: false,
    isLoaded: false,

    isUpdating: false,
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    // [START] NETWORKS -----------------------------------------------------
    case VaultV1Actions.AdmGetNetworksRequest:
      return {
        ...state,

        nodes: {
          ...state.nodes,
          isLoading: true,
        },
      }
    case VaultV1Actions.AdmGetNetworksResponse:
      return {
        ...state,
        nodes: {
          ...state.nodes,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      }
    case VaultV1Actions.AdmGetNetworksFail:
      return {
        ...state,

        nodes: {
          ...state.nodes,
          isLoading: false,
          isLoaded: true,
          data: {},
        },
      }

    case VaultV1Actions.AdmGetNetworkRequest:
      return {
        ...state,
        node: {
          ...state.node,
          isLoading: true,
        },
      }

    case VaultV1Actions.AdmGetNetworkResponse:
      return {
        ...state,
        node: {
          ...state.node,
          isLoading: false,
          isLoaded: true,
          node: payload,
        },
      }
    case VaultV1Actions.AdmGetNetworkFail:
      return {
        ...state,
        node: {
          ...state.node,
          isLoading: false,
          isLoaded: true,
          node: null,
        },
      }

    case VaultV1Actions.AdmSetSelectedNetwork:
      return {
        ...state,
        nodes: {
          ...state.nodes,
          selectedNode: payload,
        },
      }

    case VaultV1Actions.AdmAddNetworkRequest:
    case VaultV1Actions.AdmEditNetworkRequest:
      return {
        ...state,
        nodes: {
          ...state.nodes,
          isLoading: true,
        },
      }

    case VaultV1Actions.AdmDeleteNetworkRequest:
      return {
        ...state,
        nodes: {
          ...state.nodes,
          isLoading: true,
          selectedNode: initialState.nodes.selectedNode,
        },
      }

    case VaultV1Actions.AdmOperationWithNetworkFail:
      return {
        ...state,
        node: {
          ...state.node,
          isLoading: false,
        },
        nodes: {
          ...state.nodes,
          isLoading: false,
        },
      }

    case VaultV1Actions.AdmSetNetworkOpenedElements:
      return {
        ...state,
        nodes: {
          ...state.nodes,
          openedElements: state.nodes.openedElements.includes(payload)
            ? state.nodes.openedElements.filter(element => element !== payload)
            : [...state.nodes.openedElements, payload],
        },
      }
    case VaultV1Actions.AdmSetAllNetworkOpenedElements:
      return {
        ...state,
        nodes: {
          ...state.nodes,
          openedElements: state.nodes.openedElements.length
            ? []
            : appFoldersWithCollapse({ folders: state.nodes.data.folders || [] }),
        },
      }

    case VaultV1Actions.AdmSearchNetworkByPath: {
      const opened = state.nodes.openedElements
      const networks = state.nodes.data

      const pathIds = parseElementPath({
        folders: networks.folders || [],
        path: payload,
      })

      const openedFolders = pathIds.reduce((done: string[], current: string) => {
        if (opened.includes(current)) {
          return done
        } else {
          return [...done, current]
        }
      }, opened)

      return {
        ...state,
        nodes: {
          ...state.nodes,
          openedElements: openedFolders,
        },
      }
    }

    case VaultV1Actions.AdmGetNetworkVaultsRequest:
    case VaultV1Actions.AdmAddNetworkVaultRequest:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          isLoading: true,
          paging: {
            ...state.vaults.paging,
            page: 1,
          },
        },
      }

    case VaultV1Actions.AdmGetNetworkVaultsResponse:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          isLoading: false,
          isLoaded: true,
          data: payload.vaults,
          total: payload.total,
        },
      }
    case VaultV1Actions.AdmGetNetworkVaultsFail:
    case VaultV1Actions.AdmAddNetworkVaultFail:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          isLoading: false,
          isLoaded: true,
        },
      }

    case VaultV1Actions.AdmSortNetworkVaults:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          ...payload,
          isLoading: true,
        },
      }

    case VaultV1Actions.AdmSetNetworkVaultsPaging:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          isLoading: true,
          paging: {
            ...state.vaults.paging,
            ...payload,
          },
        },
      }

    case VaultV1Actions.AdmSetNetworkVaultName:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          vaultName: payload,
        },
      }

    case VaultV1Actions.AdmGetTransceiverVaultsRequest:
      return {
        ...state,
        transceivers: {
          ...state.transceivers,
          isLoading: true,
        },
      }

    case VaultV1Actions.AdmGetTransceiverVaultsResponse:
      return {
        ...state,
        transceivers: {
          ...state.transceivers,
          data: payload,
          isLoading: false,
          isLoaded: true,
        },
      }
    case VaultV1Actions.AdmGetTransceiverVaultsFail:
      return {
        ...state,
        transceivers: {
          ...state.transceivers,
          isLoading: false,
          isLoaded: true,
        },
      }

    case VaultV1Actions.AdmSetTransceiverVaultsRequest:
      return {
        ...state,
        transceivers: {
          ...state.transceivers,
          isUpdating: true,
        },
      }

    case VaultV1Actions.AdmSetTransceiverVaultsResponse:
    case VaultV1Actions.AdmSetTransceiverVaultsFail:
      return {
        ...state,
        transceivers: {
          ...state.transceivers,
          isUpdating: false,

          isModalOpen: false,
          type: undefined,
        },
      }

    case VaultV1Actions.AdmHandleTransceiversModal:
      return {
        ...state,
        transceivers: {
          ...state.transceivers,
          isModalOpen: payload.isOpen,
          type: payload.type,
        },
      }
    // [END] NETWORKS ------------------------------------------------------

    case VaultV1Actions.SignOut:
      return initialState
    default:
      return state
  }
}
