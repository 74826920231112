import { Device } from '@/api/atmeye/devicesApi/types'
import { TABS } from '@/components/pages/atmeye/Devices/Components/CurrentDevice/Tabs/TabsPanel/types'

export type Props = {
  height: number | string

  currentDevice?: Device | null

  setTab: (tab: TABS) => void
  setSegmentFilters: (filterObject: any) => void

  isUpdateHistogram?: boolean
  setIsUpdateHistogram?: (iaUpdate: boolean) => void
}

export enum INTERVAL_TYPE {
  LAST_TWELVE_HOURS = 'LAST_TWELVE_HOURS',
  LAST_DAY = 'LAST_DAY',
  LAST_THREE_DAYS = 'LAST_THREE_DAYS',
  LAST_WEEK = 'LAST_WEEK',
  LAST_TWO_WEEKS = 'LAST_TWO_WEEKS',
  LAST_MONTH = 'LAST_MONTH',
  LAST_YEAR = 'LAST_YEAR',
}

export type ChartElementData = {
  alarmsCount: number
  technicalEventsCount: number
  transactionsCount: number

  columnLabel: string

  intervalEndTimestamp: number
  intervalStartTimestamp: number

  atmIntervalEndTimestamp: number
  atmIntervalStartTimestamp: number

  intervalStartLabel: string
  intervalEndLabel: string
}
