import queryString from 'query-string'

import {
  AdmVaultSumCalculationMethod,
  AdmVaultSumCalculationMethodBase,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

export class CalculationMethodsApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getAll(guid: string): Promise<AdmVaultSumCalculationMethod[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'admin/calculationMethods',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )

      return await CalculationMethodsApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(payload: AdmVaultSumCalculationMethodBase): Promise<void> {
    try {
      return await CalculationMethodsApi.requestService.post(
        'admin/calculationMethods/add',
        payload,
      )
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(payload: { guid: string; entryId: number }): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'admin/calculationMethods/delete',
          query: { ...payload },
        },
        { skipNull: true, encode: false },
      )

      return await CalculationMethodsApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
