import { BaseATMeye } from '@/api/atmeye/baseAPIATMeye'
import { ResponseError } from '@/types'
import { requestService } from '@/utils/services'

import { EventNotificationsSettings } from './types'

export class NotificationApi<T> extends BaseATMeye<T> {
  constructor(protected data?: T, protected fetchParam?: string) {
    super(data)
    this.data = data
  }

  async getNotificationsList(): Promise<any | ResponseError> {
    try {
      return this.ATMeyeRequestService.get('/atmeye/api/v1/notifications')
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async closeSecurityAlarm(): Promise<any | ResponseError> {
    try {
      return await this.ATMeyeRequestService.post('/atmeye/api/v1/alarms/reaction', this.data, true)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async closeTechnicalEvent(): Promise<any | ResponseError> {
    try {
      return await this.ATMeyeRequestService.post(
        '/atmeye/api/v1/technical-events/reaction',
        this.data,
        true,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async getNotificationsSettings(): Promise<EventNotificationsSettings[]> {
    try {
      return await requestService.get('/atmeye/api/v1/notifications-sending-settings')
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async setNotificationSettings(
    payload: EventNotificationsSettings,
  ): Promise<any | ResponseError> {
    try {
      return await requestService.post('/atmeye/api/v1/notifications-sending-settings', payload)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async removeNotificationSettings(eventCode: number): Promise<any | ResponseError> {
    try {
      return await requestService.delete(
        `/atmeye/api/v1/notifications-sending-settings?eventCode=${eventCode}`,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
