import makeStyles from '@material-ui/core/styles/makeStyles'

type Props = {
  display?: string
}

export const useClasses = makeStyles(({ spacing, palette, shape: { borderRadius } }) => ({
  menuPaper: {
    display: `${(props: Props) => props.display}`,
    flexDirection: 'row',
  },
  menuList: {
    flex: 1,
    display: `${(props: Props) => props.display}`,
    flexDirection: 'column',
    overflow: 'auto',
  },
  menuSpacing: {
    padding: spacing(1, 0),
    flex: 1,
    display: `${(props: Props) => props.display}`,
    flexDirection: 'column',
    overflow: 'auto',
    '&:focus': {
      outline: 'none',
    },
  },
  limitedHeight: {
    maxHeight: 200,
  },
  scrollAble: {
    flex: 1,
    overflow: 'auto',
  },
  divider: {
    margin: spacing(1, 0),
  },
  bookmarkIcon: {
    display: 'none',
    width: spacing(3),
    height: spacing(3),
  },
  bookmarkText: {
    display: 'inline',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: spacing(0.5, 0),
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },

  bookmark: {
    minWidth: spacing(15),
    width: spacing(15),
    maxWidth: spacing(15),
    padding: spacing(0, 1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '12px 0px 12px 8px ',
    backgroundColor: palette.grey[50],
    borderRadius,
    '&:hover > $bookmarkIcon': {
      display: 'flex',
      alignItems: 'center',
    },
    '& + $bookmark': {
      marginLeft: spacing(1),
      marginRight: spacing(1),
    },
  },
}))
