import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  cell: {
    height: spacing(5),
    minHeight: spacing(5),
    maxHeight: spacing(5),
    padding: 0,
    borderBottom: 0,
    whiteSpace: 'nowrap',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  dropdownButton: {
    marginBottom: '5px',
  },
}))
