import React from 'react'

import { store } from '@/App'

export const SdRoutes = React.lazy(async () => {
  const {
    default: { reducer: reducerDrawerAction, saga: sagaDrawerAction },
  } = await import('@/store/sd/drawerActions')
  store.injectReducer('drawerActions', reducerDrawerAction)
  store.injectSaga('drawerActions', sagaDrawerAction)

  const {
    default: { reducer: reducerTickets, saga: sagaTickets },
  } = await import('@/store/sd/tickets')
  store.injectReducer('tickets', reducerTickets)
  store.injectSaga('tickets', sagaTickets)

  const {
    default: { reducer: reducerReports, saga: sagaReports },
  } = await import('@/store/sd/reports')
  store.injectReducer('reports', reducerReports)
  store.injectSaga('reports', sagaReports)

  const {
    default: { reducer: reducerMap, saga: sagaMap },
  } = await import('@/store/map')
  store.injectReducer('mapPage', reducerMap)
  store.injectSaga('mapPage', sagaMap)

  const {
    default: { reducer: reducerDictionary, saga: sagaDictionary },
  } = await import('@/store/sd/dictionary')
  store.injectReducer('dictionary', reducerDictionary)
  store.injectSaga('dictionary', sagaDictionary)

  const {
    default: { reducer: reducerModals },
  } = await import('@/store/sd/modals')
  store.injectReducer('modals', reducerModals)

  const {
    default: { reducer: reducerCommonSearchForm, saga: sagaCommonSearchForm },
  } = await import('@/store/common/searchForm')

  store.injectReducer('commonSearchForm', reducerCommonSearchForm)
  store.injectSaga('commonSearchForm', sagaCommonSearchForm)

  const {
    default: { reducer: admDevice, saga: admSagaDevice },
  } = await import('@/store/adm/device')
  store.injectReducer('admDevice', admDevice)
  store.injectSaga('admDevice', admSagaDevice)

  const {
    default: { reducer: reducerAtmeyeReports, saga: sagaAtmeyeReports },
  } = await import('@/store/atmeye/reports')

  store.injectReducer('atemeyeReports', reducerAtmeyeReports)
  store.injectSaga('atemeyeReports', sagaAtmeyeReports)

  const {
    default: { reducer: reducerRfm, saga: sagaRfm },
  } = await import('@/store/atmeye/rfm')

  store.injectReducer('rfm', reducerRfm)
  store.injectSaga('rfm', sagaRfm)

  return import('@/components/routers/sd')
})
