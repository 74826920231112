import { LoginField } from '@/components/pages/usm/UsersPage/components/PersonalInformation/types'
import { UserInformationField } from '@/components/pages/usm/UsersPage/components/UserInformation/components/modals/PersonModal/types'
import {
  AddNewUserPersonalCardField,
  AddUserPersonalCardValues,
} from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/AddUserModal/types'
import { ValidationErrors } from '@/constants/forms/validationErrors'
import { ValidationService } from '@/utils/services/validationService'

export const validate = (values: AddUserPersonalCardValues): Partial<AddUserPersonalCardValues> => {
  const errors: Partial<AddUserPersonalCardValues> = {}
  if (!ValidationService.isRequired(values[AddNewUserPersonalCardField.Company])) {
    errors[AddNewUserPersonalCardField.Company] = ValidationErrors.Required
  }

  if (!values[AddNewUserPersonalCardField.ExistingPerson]) {
    if (!ValidationService.isRequired(values[AddNewUserPersonalCardField.FirstName])) {
      errors[AddNewUserPersonalCardField.FirstName] = ValidationErrors.Required
    }
  }

  if (!values[AddNewUserPersonalCardField.ExistingPerson]) {
    if (!ValidationService.isRequired(values[AddNewUserPersonalCardField.LastName])) {
      errors[AddNewUserPersonalCardField.LastName] = ValidationErrors.Required
    }
  }

  if (!ValidationService.isRequired(values[LoginField.Login])) {
    errors[LoginField.Login] = ValidationErrors.Required
  }

  if (!ValidationService.isRequired(values[LoginField.Password])) {
    errors[LoginField.Password] = ValidationErrors.Required
  }

  if (!ValidationService.isRequired(values[LoginField.RepeatPassword])) {
    errors[LoginField.RepeatPassword] = ValidationErrors.Required
  }

  if (!ValidationService.isRequired(values[LoginField.TryCount])) {
    errors[LoginField.TryCount] = ValidationErrors.Required
  }

  if (values[LoginField.Password] !== values[LoginField.RepeatPassword]) {
    errors[LoginField.RepeatPassword] = ValidationErrors.PasswordsDoNotMatch
  }

  if (!ValidationService.isRequirementsForChangePassword(values[LoginField.Password])) {
    errors[LoginField.Password] = ValidationErrors.RequirementsForChangePassword
  }

  if (ValidationService.isSpace(values[LoginField.Password])) {
    errors[LoginField.Password] = ValidationErrors.UnacceptablePasswordSymbols
  }

  if (ValidationService.isSpace(values[LoginField.RepeatPassword])) {
    errors[LoginField.RepeatPassword] = ValidationErrors.UnacceptablePasswordSymbols
  }

  if (!ValidationService.isRequired(values[UserInformationField.UserName])) {
    errors[UserInformationField.UserName] = ValidationErrors.Required
  }

  if (ValidationService.isMaxLength(values[UserInformationField.UserName], 50)) {
    errors[UserInformationField.UserName] = ValidationErrors.MoreThan50Symbols
  }

  if (!ValidationService.isRequired(values[UserInformationField.UserGroup])) {
    errors[UserInformationField.UserGroup] = ValidationErrors.Required
  }

  if (!ValidationService.isRequired(values[UserInformationField.EMail])) {
    errors[UserInformationField.EMail] = ValidationErrors.Required
  }

  if (!ValidationService.isValidEmail(values[UserInformationField.EMail])) {
    errors[UserInformationField.EMail] = ValidationErrors.EmailIsNotValid
  }

  if (ValidationService.isMaxLength(values[UserInformationField.EMail], 50)) {
    errors[UserInformationField.EMail] = ValidationErrors.MoreThan50Symbols
  }

  if (ValidationService.isValidName(values[AddNewUserPersonalCardField.FirstName])) {
    errors[AddNewUserPersonalCardField.FirstName] = ValidationErrors.UnacceptableSymbols
  }

  if (ValidationService.isValidName(values[AddNewUserPersonalCardField.LastName])) {
    errors[AddNewUserPersonalCardField.LastName] = ValidationErrors.UnacceptableSymbols
  }

  if (ValidationService.isValidName(values[AddNewUserPersonalCardField.MiddleName])) {
    errors[AddNewUserPersonalCardField.MiddleName] = ValidationErrors.UnacceptableSymbols
  }

  if (ValidationService.isMaxLength(values[LoginField.Login], 20)) {
    errors[LoginField.Login] = ValidationErrors.MaxLengthOfLogin
  }

  if (ValidationService.isMinLength(values[LoginField.Login], 3)) {
    errors[LoginField.Login] = ValidationErrors.MinLengthOfLogin
  }

  return errors
}
