import React, { useCallback, useEffect, useState, Fragment } from 'react'
import clsx from 'clsx'
import { Field, FormikValues, FormikProps } from 'formik'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router'
import { useTranslate } from '@/utils/internalization'
import { Typography, Grid, Box, IconButton } from '@material-ui/core'
import { Add, ChevronRight, ExpandMore } from '@material-ui/icons'
import { NewMerchantFormFields } from '@/constants/forms/NewMerchantForm'
import Button from '@/components/controls/Button'
import { createSelectField } from '@/components/controls/Select/component'
import { Props } from './types'
import { useClasses } from './styles'
import { useSelector } from 'react-redux'
import {
  isDeletingPosRequestItem,
  hasRequestError,
  getPOSMerchantFormFieldsGroups,
} from '@/store/sd/drawerActions/selectors/newPOS'
import DynamicFieldsGroupWrapper from '@/components/blocks/DynamicFieldsGroupWrapper/component'
import { isEmpty } from 'lodash-es'
import { Alert } from '@material-ui/lab'

const NewMerchantForm = ({
  showContent,
  setShowContent,
  isValid,
  values,
  setValues,
  isDisableCollapseForm,
  handleOpenTerminalInstallationModal,
  merchantFullName,
  setFieldValue,
  setFieldTouched,
  errors: formErrors,
}: FormikProps<any> & Props): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const [deletingServiceId, setDeletingServiceId] = useState<number | null>(null)
  const [services, setServices] = useState<FormikValues[]>([])
  const isDeletingServiceItem = useSelector(isDeletingPosRequestItem)
  const hasPOSRequestError = useSelector(hasRequestError)
  const { displayObjectList: POSMerchantFormGroups } = useSelector(getPOSMerchantFormFieldsGroups)

  useEffect(() => {
    if (deletingServiceId && !isDeletingServiceItem && !hasPOSRequestError) {
      setServices([...services.filter((val, i) => i !== deletingServiceId)])

      setDeletingServiceId(null)
    }
  }, [deletingServiceId, isDeletingServiceItem, hasPOSRequestError, services])

  const handleToggleContent = useCallback((): void => {
    setShowContent(prevState => !prevState)
  }, [])

  const ServiceTypeSelect = createSelectField({
    disabled: true,
    label: translate('translate#title.serviceType'),
    fullWidth: true,
    shrink: true,
    value: translate('translate#title.terminalInstallation'),
    options: [
      {
        name: translate('translate#title.terminalInstallation'),
        value: translate('translate#title.terminalInstallation'),
      },
    ],
    withAutofillForSingleOption: false,
  })

  const errArray = JSON.stringify(hasPOSRequestError).split(';')

  const [first] = errArray

  const error = first.split(':')

  const [, , err] = error

  // @todo add real errors for DynamicFieldsGroupWrapper
  return (
    <>
      {hasPOSRequestError !== null && <Alert severity="error">{err}</Alert>}
      <div
        className={clsx(classes.wrapper, classes.header, {
          [classes.inactiveHeader]: showContent,
          [classes.defaultCursor]: isDisableCollapseForm,
          [classes.disabledHeader]: !showContent && isDisableCollapseForm,
        })}
        onClick={isDisableCollapseForm ? undefined : handleToggleContent}
      >
        <Box display="flex" alignItems="center">
          <span hidden={showContent}>
            <Add />
            &nbsp;
          </span>
          <Typography className={classes.title} variant="h6">
            <FormattedMessage id="action.newMerchant" defaultMessage="New Merchant" />
          </Typography>
        </Box>
        {!isDisableCollapseForm && (
          <IconButton color="inherit">
            <ExpandMore transform={`rotate(${showContent ? 180 : 0})`} />
          </IconButton>
        )}
      </div>
      {showContent && !isEmpty(POSMerchantFormGroups) && (
        <>
          <Grid className={classes.wrapper} container>
            <Grid container alignItems="flex-start" spacing={4} alignContent="flex-start">
              {POSMerchantFormGroups.map((group: any) => (
                <Fragment key={group.outOrderNo}>
                  {group.type === 'GROUP' && (
                    <DynamicFieldsGroupWrapper
                      item={group.outputElement}
                      formValues={values}
                      formErrors={formErrors}
                      setFormikValues={setValues}
                      setFieldTouched={setFieldTouched}
                      setFormikFieldValue={setFieldValue}
                      parentColCount={2}
                      merchantFullName={merchantFullName}
                    />
                  )}
                </Fragment>
              ))}
            </Grid>

            <Grid item md={6} className={classes.serviceTypeSelect}>
              <Field name={NewMerchantFormFields.ServiceType}>{ServiceTypeSelect}</Field>
            </Grid>
            <Grid item>
              <Button
                className={classes.nextButton}
                disabled={!isValid}
                onClick={handleOpenTerminalInstallationModal}
                light
                width="md"
                endIcon={<ChevronRight />}
              >
                <FormattedMessage id="action.next" defaultMessage="Next" />
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default withRouter(NewMerchantForm)
