import { Product, ProductFieldName } from '@/types/products/products'
import moment from 'moment'

export const initialValues: Product = {
  [ProductFieldName.ActualTillDate]: null,
  [ProductFieldName.ComplexityLevelId]: null,
  [ProductFieldName.Functionality]: null,
  [ProductFieldName.Height]: null,
  [ProductFieldName.IsRepairable]: false,
  [ProductFieldName.Length]: null,
  [ProductFieldName.ListPrice]: null,
  [ProductFieldName.NettoWeightKg]: null,
  [ProductFieldName.ProductAccountingGroupId]: null,
  [ProductFieldName.ProductCategoryId]: null,
  [ProductFieldName.ProductCode]: '',
  [ProductFieldName.ProductGroupId]: null,
  [ProductFieldName.ProductId]: null,
  [ProductFieldName.ProductName]: '',
  [ProductFieldName.ProductSparePartGroupId]: null,
  [ProductFieldName.QuantityKeyId]: null,
  [ProductFieldName.StatWnr]: '',
  [ProductFieldName.VendorArticleNo]: '',
  [ProductFieldName.VendorCompanyId]: null,
  [ProductFieldName.VendorCountryId]: null,
  [ProductFieldName.VendorRepairPrice]: null,
  [ProductFieldName.Width]: null,
}
