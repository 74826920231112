import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { ChevronRight } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import clsx from 'clsx'
import { Field, Form, Formik, FormikValues } from 'formik'
import { flatten, isEmpty, isNull } from 'lodash-es'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { isIE } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Test from 'react-test-attributes'

import Loader from '@/components/blocks/Loader'
import FooterWithActionButtons from '@/components/blocks/POSRequestForm/components/FooterWithActionButtons/component'
import OldMerchantForm from '@/components/blocks/POSRequestForm/components/OldMerchantForm/component'
import ServicesSummaryTable from '@/components/blocks/POSRequestForm/components/ServicesSummaryTable/component'
import { FormattedRequestItems } from '@/components/blocks/PSRTicketDetails/components/DocumenInformationModal/components/DocumenInformationTable/types'
import Button from '@/components/controls/Button/index'
import { createSelectField } from '@/components/controls/Select/component'
import ServiceTypeModal from '@/components/wrappers/AppNavigation/components/DrawerActions/components/modals/ServiceTypeModal/component'
import { IdsFields } from '@/components/wrappers/AppNavigation/components/DrawerActions/components/modals/ServiceTypeModal/components/ServiceTerminals'
import {
  FIELD_NAME_TERMINAL_IDS,
  POS_AQUIRER_LUNO,
  POS_INTEGRATION_WITH_ERC,
  POS_MERCHANT_ID,
  POS_MERCHANT_ID_AMEX,
  POS_SIM_OF_CLIENT,
  POS_TERMINAL_ID_AMEX,
} from '@/components/wrappers/AppNavigation/components/DrawerActions/components/modals/ServiceTypeModal/components/ServiceTypeForm/types'
import { CreatePOSRequestFormFields } from '@/constants/forms/createPosRequestForms/CreatePOSRequestForm'
import { CreatePOSModalContext } from '@/context'
import { getCurrentCompany } from '@/store/auth/selectors'
import {
  addPOSServiceTypeAction,
  clearEditingPOSTicketFormConfig,
  clearNotSavedPOSServiceRequest,
  deletePosRequestItemRequest,
  editNotSavedPOSServiceRequestByIndex,
  editPOSServiceTypeAction,
  editPosTicketRequestRequest,
  getCreatingPOSTicketFormConfigRequest,
  getMerchantFormConfigRequest,
  putNotSavedPOSServiceRequest,
  putNotSavedPOSServicesArray,
  resetNotSavedPOSServicesFromUI,
  savePosTicketInitial,
  savePosTicketRequest,
  setIsTicketHasSentSuccessful,
  setNotSavedPOSServiceRequestEditIndex,
  setTicketId,
} from '@/store/sd/drawerActions/actions'
import { getMCCCodeRequestFragmentData, getTicketId } from '@/store/sd/drawerActions/selectors'
import {
  getCreatePOSTicketFormEditingGrantedOperations,
  getCreatePOSTicketFormInitGrantedOperations,
  getCustomerNumber,
  getDocumentId,
  getIsLookingForCreatePOSEditTicketFormConfig,
  getIsSavedSuccessful,
  getIsSavingPOSTicket,
  getIsSendingPosTicket,
  getIsTicketHasSentSuccessful,
  getMerchants,
  getNotSavedServiceTypeEditIndex,
  getNotSavedServiceTypeItems,
  getPOSError,
  getPOSMerchantFormFieldsGroups,
  getPOSServiceFormConfigParameters,
  getPosTicketInfo,
  getSaveRequestInitialParameters,
  getServiceCompanies,
  getServiceTypeItems,
  getServiceTypes,
  hasRequestError,
  isDeletingPosRequestItem,
  isLookingForCreatingNewTicketEditFormConfig,
  isLookingForCreatingNewTicketFormConfig,
} from '@/store/sd/drawerActions/selectors/newPOS'
import { GrantedOperations } from '@/types'
import { getTestId } from '@/utils'
import { useTranslate } from '@/utils/internalization'
import { PopUpService } from '@/utils/services/popUpService'

import NewMerchantForm from './components/NewMerchantForm'
import { useClasses } from './styles'
import { POSServiceTypeItem, Props, SERVICE_TYPES } from './types'
import {
  converLocalServicesForTable,
  convertRemoteServicesForTable,
  geEditPOSTicketRequestFormData,
  getFormInitialValues,
  getSavePOSTicketRequestFormData,
  getServiceTypeFormData,
} from './utils'
import { validate } from './validate'

const CreatePOSRequestForm = ({
  handleClose,
  open,
  testIdPrefix,
  handleOpenPsrDetails,
  handleSetCurrentTicketId,
}: Props): React.ReactElement => {
  const props = { display: isIE ? 'block' : 'flex' }
  const classes = useClasses(props)
  const dispatch = useDispatch()
  const companies = useSelector(getServiceCompanies)
  const merchants = useSelector(getMerchants)
  const customerNumber = useSelector(getCustomerNumber)
  const serviceTypes = useSelector(getServiceTypes)
  const currentCompanyName = useSelector(getCurrentCompany)
  const serviceTypeItems = useSelector(getServiceTypeItems)
  const errorMessage = useSelector(getPOSError)
  const editingPosTicketInfo = useSelector(getPosTicketInfo)
  const documentId = useSelector(getDocumentId)
  const ticketId = useSelector(getTicketId)
  const isSavingPOSTicket = useSelector(getIsSavingPOSTicket)
  const isSavedSuccessful = useSelector(getIsSavedSuccessful)
  const isLookingForCreatePOSEditTicketFormConfig = useSelector(
    getIsLookingForCreatePOSEditTicketFormConfig,
  )
  const isSendingPosTicket = useSelector(getIsSendingPosTicket)
  const isTicketHasSentSuccessful = useSelector(getIsTicketHasSentSuccessful)
  const isDeletingServiceItem = useSelector(isDeletingPosRequestItem)
  const hasPOSRequestError = useSelector(hasRequestError)
  const saveRequestInitialParameters = useSelector(getSaveRequestInitialParameters)
  const posServiceFormParameters = useSelector(getPOSServiceFormConfigParameters)
  const notSavedPosServiceEditIndex = useSelector(getNotSavedServiceTypeEditIndex)
  const notSavedPOSServiceRequests = useSelector(getNotSavedServiceTypeItems)
  const isLoadingCreateNewPOSTicketFormConfig = useSelector(isLookingForCreatingNewTicketFormConfig)
  const isLoadingCreateNewPOSEditTicketFormConfig = useSelector(
    isLookingForCreatingNewTicketEditFormConfig,
  )
  const { fieldParameters } = useSelector(getPOSMerchantFormFieldsGroups)
  const initGrantedOperations = useSelector(getCreatePOSTicketFormInitGrantedOperations)
  const editingGrantedOperations = useSelector(getCreatePOSTicketFormEditingGrantedOperations)
  const mccCodeRequest = useSelector(getMCCCodeRequestFragmentData)
  const idPrefix = `${testIdPrefix}-form`
  const testId = getTestId(idPrefix)
  const [openNewMerchantForm, setOpenNewMerchantForm] = useState(false)
  const [serviceTypeModalId, setServiceTypeModalId] = useState<string | null>(null)
  const [services, setServices] = useState<POSServiceTypeItem[]>([])
  const [deletingServiceId, setDeletingServiceId] = useState<number | null>(null)
  const [editRow, setEditRow] = useState<FormikValues>()
  const { setIsHideCreatePOSRequestModal } = useContext(CreatePOSModalContext)
  const translate = useTranslate()
  const isEditingPOSTicket = !isEmpty(editingPosTicketInfo)
  const [servicesTableData, setServicesTableData] = useState<Partial<FormattedRequestItems>[]>([])
  const [remoteServices, setRemoteServices] = useState<Partial<FormattedRequestItems>[]>([])
  const [localServices, setLocalServices] = useState<Partial<FormattedRequestItems>[]>([])

  const formRef = useRef(null) as any

  const closeCreatePOSTicketModal = useCallback(() => {
    setIsHideCreatePOSRequestModal && setIsHideCreatePOSRequestModal(false)
    handleClose()
  }, [setIsHideCreatePOSRequestModal, handleClose])

  const formInitialValues = useMemo(
    () =>
      getFormInitialValues({
        currentCompanyName,
        customerNumber,
        companies,
        editingPosTicketInfo,
        isEditingPOSTicket,
        merchants,
        fieldParameters,
      }),
    [
      currentCompanyName,
      customerNumber,
      companies,
      editingPosTicketInfo,
      isEditingPOSTicket,
      merchants,
      fieldParameters,
    ],
  )

  useEffect(() => {
    dispatch(getCreatingPOSTicketFormConfigRequest())
  }, [])

  useEffect(() => {
    setServices(serviceTypeItems)
  }, [serviceTypeItems])

  /**
   * Auto open new merchant form for editing saved ticket with new merchant
   */
  useEffect(() => {
    if (isEditingPOSTicket && editingPosTicketInfo.merchantfullname) {
      setOpenNewMerchantForm(true)
    }
  }, [isEditingPOSTicket, editingPosTicketInfo.merchantfullname])

  /**
   * clear all data for create POS request form after closing
   */
  useEffect(() => {
    if (!open) {
      setServices([])
      dispatch(savePosTicketInitial())
      dispatch(clearNotSavedPOSServiceRequest())
      dispatch(clearEditingPOSTicketFormConfig())

      const { current } = formRef

      if (current) {
        current.setValues(formInitialValues)
        current.resetForm()
      }
    }
  }, [open])

  /**
   * setting merchant field to initial state when new merchant form is open
   */
  useEffect(() => {
    const { current } = formRef
    current &&
      openNewMerchantForm &&
      current.setFieldValue(
        [CreatePOSRequestFormFields.Merchant],
        formInitialValues[CreatePOSRequestFormFields.Merchant],
      )
  }, [openNewMerchantForm])

  /**
   * going to document info modal when ticket is saved successfully
   */
  useEffect(() => {
    if (isSavedSuccessful) {
      handleSetCurrentTicketId && handleSetCurrentTicketId(ticketId)
      handleOpenPsrDetails()
      handleClose()
    }
  }, [
    isSavedSuccessful,
    handleClose,
    handleOpenPsrDetails,
    ticketId,
    handleSetCurrentTicketId,
    dispatch,
    setTicketId,
  ])

  /**
   * auto closing create POS modal when ticket was sent successfully
   */
  useEffect(() => {
    if (!isSendingPosTicket && isTicketHasSentSuccessful) {
      // set isTicketHasSentSuccessful to initial state
      dispatch(setIsTicketHasSentSuccessful(false))

      closeCreatePOSTicketModal()
    }
  }, [isSendingPosTicket, isTicketHasSentSuccessful])

  /**
   * add loader when deleting service type from service type summary table
   */
  useEffect(() => {
    if (!isNull(deletingServiceId) && !isDeletingServiceItem && !hasPOSRequestError) {
      setServices([...services.filter((val, i) => i !== deletingServiceId)])

      setDeletingServiceId(null)
    }
  }, [deletingServiceId, isDeletingServiceItem, hasPOSRequestError, services])

  /**
   * parse to local services array
   */
  useEffect(() => {
    let localServices: any = notSavedPOSServiceRequests.map(
      converLocalServicesForTable(serviceTypes),
    )
    localServices = flatten(localServices)
    setLocalServices(localServices)
  }, [notSavedPOSServiceRequests, serviceTypes])

  /**
   * parse to remote services array
   */
  useEffect(() => {
    const rms = services.map(convertRemoteServicesForTable(serviceTypes))
    setRemoteServices(rms)
  }, [services, serviceTypes])

  /**
   * This memo combines local services and remote services into single
   */
  useEffect(() => {
    setServicesTableData(localServices.concat(remoteServices))
  }, [localServices, remoteServices])

  /**
   * Getting config for new merchant form
   */
  useEffect(() => {
    if (saveRequestInitialParameters.issuercompanyid) {
      dispatch(getMerchantFormConfigRequest(saveRequestInitialParameters.issuercompanyid))
    }
  }, [saveRequestInitialParameters.issuercompanyid])

  const canView = useMemo((): boolean => {
    if (isEditingPOSTicket && editingGrantedOperations && Array.isArray(editingGrantedOperations)) {
      return editingGrantedOperations.includes(GrantedOperations.REQUEST_VIEW)
    } else {
      return initGrantedOperations.includes(GrantedOperations.REQUEST_VIEW)
    }
  }, [isEditingPOSTicket, initGrantedOperations, editingGrantedOperations])

  const canInsertRequest = useMemo((): boolean => {
    if (isEditingPOSTicket && editingGrantedOperations && Array.isArray(editingGrantedOperations)) {
      return editingGrantedOperations.includes(GrantedOperations.REQUEST_INSERT)
    } else {
      return initGrantedOperations.includes(GrantedOperations.REQUEST_INSERT)
    }
  }, [isEditingPOSTicket, initGrantedOperations, editingGrantedOperations])

  const handleOpenServiceModal = useCallback(
    (serviceType: string): (() => void) => () => {
      setIsHideCreatePOSRequestModal && setIsHideCreatePOSRequestModal(true)

      setServiceTypeModalId(serviceType)
    },
    [setServiceTypeModalId],
  )

  const showNotPermissionPopup = useCallback(() => {
    PopUpService.showGlobalPopUp(`translate#title.notEnoughPermissionsCreateRequest`, 'warning')
  }, [])

  const handleCloseServiceModal = useCallback(() => {
    setEditRow(undefined)
    setIsHideCreatePOSRequestModal && setIsHideCreatePOSRequestModal(false)

    setServiceTypeModalId(null)
  }, [setServiceTypeModalId])

  const handleServiceModalSubmit = useCallback(
    (values: FormikValues): void => {
      if (isEditingPOSTicket) {
        if (!documentId) {
          throw new Error('documentId required')
        }

        if (editRow) {
          const {
            posFormDataParameters: {
              documentitemid,
              posaquirerluno,
              merchantid,
              posserviceid,
              deviceid,
            },
          } = editRow
          dispatch(
            editPOSServiceTypeAction(documentitemid, +documentId, {
              ...getServiceTypeFormData(values, posServiceFormParameters),
              [POS_SIM_OF_CLIENT]: values.possimofclient,
              [POS_INTEGRATION_WITH_ERC]: values.posintegrationwitherc,
              posaquirerluno: posaquirerluno,
              posmerchantid: merchantid,
              ...(`${posserviceid}` !== SERVICE_TYPES.installation && {
                deviceid: +deviceid,
              }),
            }),
          )
        } else {
          dispatch(addPOSServiceTypeAction(values, +documentId, posServiceFormParameters, true))
        }
      } else {
        if (editRow) {
          dispatch(
            editNotSavedPOSServiceRequestByIndex({
              index: notSavedPosServiceEditIndex as number,
              formValues: {
                ...values,
                ...(Object.prototype.hasOwnProperty.call(editRow, 'posmcccodeLookupRequest') && {
                  posmcccodeLookupRequest: mccCodeRequest,
                }),
                ...{ terminals: editRow.terminals ? editRow.terminals : editRow.terminalIds },
              },
              formParameters: posServiceFormParameters,
            }),
          )
        } else {
          if (values.terminals) {
            const terminals = values.terminals.map((ter: any) => {
              const oneTerminal = {
                ...values,
                ...ter,
                terminals: [ter],
              }
              return oneTerminal
            })
            dispatch(putNotSavedPOSServicesArray(terminals, posServiceFormParameters))
          } else if (values[FIELD_NAME_TERMINAL_IDS]) {
            const terminals = values[FIELD_NAME_TERMINAL_IDS].map((ter: IdsFields) => {
              const oneTerminal = {
                ...values,
                acquirerLuno: ter[POS_AQUIRER_LUNO],
                merchantid: ter[POS_MERCHANT_ID],
                [POS_AQUIRER_LUNO]: ter[POS_AQUIRER_LUNO],
                [POS_MERCHANT_ID]: ter[POS_MERCHANT_ID],
                [POS_TERMINAL_ID_AMEX]: ter[POS_TERMINAL_ID_AMEX] || '',
                [POS_MERCHANT_ID_AMEX]: ter[POS_MERCHANT_ID_AMEX] || '',
                terminalIds: [ter[POS_AQUIRER_LUNO]],
                posmcccodeLookupRequest: mccCodeRequest,
              }
              return oneTerminal
            })
            dispatch(putNotSavedPOSServicesArray(terminals, posServiceFormParameters))
          }
        }
      }

      handleCloseServiceModal()
    },
    [
      services,
      setServices,
      editRow,
      setEditRow,
      putNotSavedPOSServiceRequest,
      posServiceFormParameters,
      handleCloseServiceModal,
      getServiceTypeFormData,
      documentId,
      isEditingPOSTicket,
      notSavedPosServiceEditIndex,
      mccCodeRequest,
    ],
  )

  const ServiceTypeSelect = useMemo(() => {
    return createSelectField({
      label: translate('translate#title.serviceType'),
      placeholder: translate('translate#title.selectServiceType'),
      fullWidth: true,
      shrink: true,
      isLoading: isLoadingCreateNewPOSTicketFormConfig,
      options: serviceTypes,
    })
  }, [merchants, isLoadingCreateNewPOSTicketFormConfig])

  const handleSubmit = useCallback((values: FormikValues): void => {
    // console.log('values', values)
  }, [])

  const handleSave = useCallback(
    (values: FormikValues, withSendingTicket = false): (() => void) => (): void => {
      if (isEditingPOSTicket) {
        if (documentId) {
          dispatch(
            editPosTicketRequestRequest(
              documentId,
              geEditPOSTicketRequestFormData({
                openNewMerchantForm,
                values,
                hasServiceItems: !!services.length,
                receiveDate: editingPosTicketInfo.receivedate as string,
              }),
              withSendingTicket,
            ),
          )
        }
      } else {
        dispatch(
          savePosTicketRequest(
            getSavePOSTicketRequestFormData({
              openNewMerchantForm,
              values,
              saveRequestInitialParameters,
            }),
            withSendingTicket,
          ),
        )
      }
    },
    [
      dispatch,
      openNewMerchantForm,
      saveRequestInitialParameters,
      isEditingPOSTicket,
      editingPosTicketInfo,
      services,
      documentId,
    ],
  )

  const handleRemoveService = useCallback(
    (idx: string): (() => void) => (): void => {
      if (+idx <= notSavedPOSServiceRequests.length - 1) {
        const UpdatedServices = notSavedPOSServiceRequests.filter(
          (serv: any, index: number) => index !== +idx,
        )
        dispatch(resetNotSavedPOSServicesFromUI(UpdatedServices))
        return
      }
      const documentItemId = services[+idx].posFormDataParameters.documentitemid
      setDeletingServiceId(+idx)
      dispatch(deletePosRequestItemRequest(documentItemId as number))
      const updatedRemoteServices = remoteServices.filter(
        (serv: any, index: number) => index !== +idx,
      )
      setRemoteServices(updatedRemoteServices)
    },
    [services, localServices, remoteServices, servicesTableData],
  )

  const handleEditService = useCallback(
    (id: string): (() => void) => (): void => {
      const idForLocalServices = parseInt(id)
      dispatch(setNotSavedPOSServiceRequestEditIndex(idForLocalServices))
      if (idForLocalServices <= notSavedPOSServiceRequests.length - 1) {
        handleOpenServiceModal(
          `${notSavedPOSServiceRequests[idForLocalServices].formValues.posserviceid}`,
        )()
        setEditRow(notSavedPOSServiceRequests[idForLocalServices].formValues)
        return
      }
      setEditRow(services[+id])
      handleOpenServiceModal(`${services[+id].posFormDataParameters.posserviceid}`)()
    },
    [
      services,
      handleOpenServiceModal,
      servicesTableData,
      localServices,
      notSavedPOSServiceRequests,
    ],
  )

  /**
   * Define witch services and terminals already selected
   */
  const selectedServicesWithTerminals = useMemo(
    () =>
      servicesTableData.reduce((accum, item) => {
        const serviceId = serviceTypes.find(ser => ser.name === item.service)?.value

        if (serviceId && item.terminalId) {
          if (!accum[serviceId]) {
            accum[serviceId] = []
          }

          return {
            ...accum,
            [serviceId]: [...accum[serviceId], item.terminalId],
          }
        }
        return { ...accum }
      }, {} as { [key: string]: string[] }),
    [servicesTableData, serviceTypes],
  )

  const isLoading = useMemo(
    (): boolean =>
      isLoadingCreateNewPOSTicketFormConfig ||
      isLookingForCreatePOSEditTicketFormConfig ||
      isEmpty(fieldParameters),
    [
      isLoadingCreateNewPOSTicketFormConfig,
      isLookingForCreatePOSEditTicketFormConfig,
      fieldParameters,
    ],
  )

  if (formInitialValues.merchantId !== '' && formInitialValues.merchantId !== 'null') {
    if (formRef.current !== null && formRef.current !== undefined) {
      const sbj =
        notSavedPosServiceEditIndex !== null && notSavedPosServiceEditIndex !== undefined
          ? serviceTypeItems[notSavedPosServiceEditIndex].posFormDataParameters.subject
          : ''
      formRef.current.initialValues.merchantfullname = sbj + ''
    }
  }

  const errorJSX = errorMessage && (
    <div className={classes.alertWrapper}>
      <Alert severity="error">{errorMessage}</Alert>
    </div>
  )

  return (
    <>
      {errorJSX}
      {!errorJSX && isLoading && (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      )}
      {!isLoading && !canView && (
        <div className={classes.wrapper}>
          <Typography variant="h5">
            <FormattedMessage id="title.accessDenied" defaultMessage="Access denied" />
          </Typography>
        </div>
      )}
      {!isLoading && canView && (
        <Formik
          onSubmit={handleSubmit}
          validate={validate(openNewMerchantForm, fieldParameters)}
          enableReinitialize={false}
          initialValues={formInitialValues}
          innerRef={formRef}
        >
          {({ isValid, values, setValues, ...formProps }): React.ReactElement => {
            const isShownNewMerchantForm: boolean = isEditingPOSTicket || !values.merchantId

            return (
              <Test id={testId(0)}>
                <div className={classes.container}>
                  <Test id={testId(1)}>
                    <div className={classes.wrapper}>
                      <Test id={testId(2)}>
                        <Typography variant="h5">
                          <FormattedMessage
                            id="title.createPOSRequest"
                            defaultMessage="Create POS service request"
                          />
                        </Typography>
                      </Test>
                    </div>
                  </Test>
                  <Test id={testId(3)}>
                    <Form className={classes.form} autoComplete="off">
                      <OldMerchantForm
                        hasServices={!!servicesTableData.length}
                        isEditingPOSTicket={isEditingPOSTicket}
                        editingPosTicketInfo={editingPosTicketInfo}
                        openNewMerchantForm={openNewMerchantForm}
                        testIdPrefix={testIdPrefix || ''}
                      />
                      {isShownNewMerchantForm && (
                        <NewMerchantForm
                          isValid={isValid}
                          setValues={setValues}
                          values={values}
                          showContent={openNewMerchantForm}
                          setShowContent={setOpenNewMerchantForm}
                          isDisableCollapseForm={isEditingPOSTicket || !!servicesTableData.length}
                          handleClose={closeCreatePOSTicketModal}
                          handleOpenTerminalInstallationModal={handleOpenServiceModal(
                            SERVICE_TYPES.installation,
                          )}
                          merchantFullName={values.merchantfullname}
                          {...formProps}
                        />
                      )}
                      {isValid && !openNewMerchantForm && (
                        <Grid
                          className={clsx(classes.wrapper, classes.serviceSelectWrapper)}
                          container
                        >
                          <Grid item md={12}>
                            <Divider />
                          </Grid>
                          <Grid />
                          <Grid className={classes.field} item md={6}>
                            <Test id={testId(9)}>
                              <Field name={CreatePOSRequestFormFields.ServiceType}>
                                {ServiceTypeSelect}
                              </Field>
                            </Test>
                          </Grid>
                          <Grid item className={classes.field}>
                            <Test id={testId(10)}>
                              <Button
                                disabled={
                                  !values.serviceType ||
                                  (values.serviceType === SERVICE_TYPES.staffTraining &&
                                    !!selectedServicesWithTerminals[SERVICE_TYPES.staffTraining])
                                }
                                onClick={
                                  canInsertRequest
                                    ? handleOpenServiceModal(values.serviceType)
                                    : showNotPermissionPopup
                                }
                                light
                                width="md"
                                endIcon={<ChevronRight />}
                              >
                                <FormattedMessage id="action.next" defaultMessage="Next" />
                              </Button>
                            </Test>
                          </Grid>
                        </Grid>
                      )}
                      {isLoadingCreateNewPOSEditTicketFormConfig ? (
                        <Loader />
                      ) : (
                        !!servicesTableData.length && (
                          <ServicesSummaryTable
                            handleEditService={handleEditService}
                            handleRemoveService={handleRemoveService}
                            data={servicesTableData}
                            testIdPrefix={idPrefix}
                            isEditingPOSTicket={isEditingPOSTicket}
                            isDeletingServiceItem={isDeletingServiceItem}
                            deletingServiceId={deletingServiceId}
                          />
                        )
                      )}
                    </Form>
                  </Test>
                  <FooterWithActionButtons
                    isValid={isValid}
                    isOpenNewMerchantForm={openNewMerchantForm}
                    isSavingPOSTicket={isSavingPOSTicket}
                    handleSave={handleSave}
                    hasServices={!!servicesTableData.length}
                    values={values}
                  />
                  {serviceTypes.map(({ value, name }) => {
                    return (
                      <ServiceTypeModal
                        key={value}
                        title={name}
                        withTerminalIdsField={value === SERVICE_TYPES.installation}
                        withSingleTerminalSelecting={value === SERVICE_TYPES.staffTraining}
                        testIdPrefix={idPrefix}
                        handleSubmit={handleServiceModalSubmit}
                        editRow={editRow}
                        open={serviceTypeModalId === value}
                        handleClose={handleCloseServiceModal}
                        merchantId={+values[CreatePOSRequestFormFields.Merchant]}
                        serviceTypeId={value}
                        merchantFullName={values.merchantfullname || ''}
                        selectedTerminalIds={selectedServicesWithTerminals[value]}
                      />
                    )
                  })}
                </div>
              </Test>
            )
          }}
        </Formik>
      )}
    </>
  )
}

export default withRouter(CreatePOSRequestForm)
