export enum DeviceFieldName {
  DeviceId = 'deviceId',
  DeviceUID = 'terminalUid',
  InputUID = 'inputUid',
  DeviceType = 'deviceType',
  DeviceTypeId = 'deviceTypeId',
  DeviceTypeName = 'deviceTypeName',
  DeviceState = 'deviceState',
  DeviceStateId = 'deviceStateId',
  DeviceStateName = 'deviceStateName',
  DeviceName = 'deviceName',
  DeviceDescription = 'deviceDescription',
  InstallationDate = 'installationDate',
  WarrantyEndDate = 'warrantyEndDate',
}

export enum HardwareFieldName {
  HardwareId = 'hardwareId',
  HardwareIdForInstall = 'hardwareIdforInstall',
  HardwareName = 'hardwareName',
  SerialNumber = 'serialNumber',
  VendorId = 'vendorId',
  VendorName = 'vendorName',
  ModelId = 'modelId',
  ModelName = 'modelName',
  HardwareType = 'type',
  HardwareTypeId = 'hardwareTypeId',
  HardwareTypeName = 'hardwareTypeName',
  HardwareDescription = 'hardwareDescription',
}

export enum RelatedCompaniesFieldName {
  EntryId = 'entryId',
  CompanyRole = 'companyRole',
  Company = 'company',

  CompanyRoleId = 'companyRoleId',
  CompanyRoleName = 'companyRoleName',
  CompanyId = 'companyId',
  CompanyName = 'companyName',
  LUNO = 'luno',
  AgreementNo = 'agreementNo',
  SaFrom = 'saFrom',
  SaTo = 'saTo',
}

export enum AddressFieldName {
  AddressId = 'addressId',
  CountryId = 'countryId',
  CountryName = 'countryName',
  StateId = 'stateId',
  StateName = 'stateName',
  RegionId = 'regionId',
  RegionName = 'regionName',
  CityId = 'cityId',
  CityName = 'cityName',
  CityRegionId = 'cityRegionId',
  CityRegionName = 'cityRegionName',
  StreetId = 'streetId',
  StreetName = 'streetName',
  HouseNr = 'houseNumber',
  Flat = 'flatNumber',
  Location = 'location',
  Address = 'address',
  ActualFrom = 'fromDate',
  Latitude = 'latitude',
  Longitude = 'longitude',
  TimeZoneCode = 'timeZoneCode',
  TimeZoneName = 'timeZoneName',
  CoordinatesUpdated = 'updated',
  Source = 'source',
  FullAddress = 'fullAddress',
  GeoId = 'id',
  IsLocated = 'isLocated',
}

export enum ServiceInfoFieldName {
  Id = 'id',
  ServiceRegion = 'serviceRegion',
  TimeZoneCode = 'timeZoneCode',
  TimeZoneName = 'timeZoneName',
  DistanceFromServiceRegionCenter = 'distanceFromServiceRegionCenter',
  WarrantyEndDate = 'warrantyEndDate',
  InstallationDate = 'installationDate',
  InstallationPlace = 'installationPlace',
  Functionality = 'functionality',
  Notes = 'notes',

  MerchantId = 'merchantCompanyId',
  MerchantName = 'merchantCompanyName',
  AcquirerId = 'acquirerCompanyId',
  AcquirerName = 'acquirerCompanyName',
  LUNO = 'luno',
}

export enum AttachedFilesFieldName {
  DeviceAttachedFileId = 'deviceAttachedFileId',
  File = 'file',
  FileGroupName = 'fileGroupName',
  FileGroupId = 'fileGroupId',
  FileName = 'fileName',
  FileDate = 'fileDate',
  FileType = 'fileType',
  RelDocNo1 = 'relDocNo1',
  RelDocNo2 = 'relDocNo2',
  RelDocNo3 = 'relDocNo3',
  Description = 'description',
  Thumbnail = 'thumbnail',
}

export enum ConfigurationFieldName {
  Code = 'code',
  Name = 'name',
  ItemNo = 'itemno',
  ParentDetailId = 'parentDetailId',
  BySPR = 'bySPR',
  DetailProductId = 'detailProductId',
  DetailSerialNumber = 'detailSerialNumber',
  SerialNumber = 'serialNumber',
  InstallationDate = 'fromDate',
  Guarantee = 'guarantee',
  RevisionLevel = 'revisionLevel',
  Notes = 'description',
  IsManual = 'isManual',
  GuaranteeFrom = 'guarantyFromDate',
  GuaranteeTo = 'guarantyToDate',
  ImpossibleRepair = 'impossibleRepair',
  ImpossiblePreventiveMaintenance = 'impossiblePreventiveMaintenance',
  SellGroup = 'sellGroup',
  ShowOnly = 'showOnly',
}

export enum ScheduleDayName {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

export enum ScheduleServerDayName {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum ScheduleFieldName {
  IsActive = 'isActive',
  TimeFrom1 = 'timeFrom1',
  TimeTo1 = 'timeTo1',
  TimeFrom2 = 'timeFrom2',
  TimeTo2 = 'timeTo2',
}
