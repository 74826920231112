import { combineReducers } from 'redux'

import * as accessRules from './accessRules/reducer'
import * as appConfig from './appConfig/reducers'
import * as cit from './CIT/reducer'
import * as common from './common/reducer'
import * as license from './license/reducer'
import * as objects from './objects/reducers'
import * as usersAndRoles from './usersAndRoles/reducer'

export interface AppState {
  common: common.State
  accessRules: accessRules.State
  usersAndRoles: usersAndRoles.State
  cit: cit.State
  objects: objects.State
  license: license.State
  appConfig: appConfig.State
}

export const reducer = combineReducers({
  accessRules: accessRules.reducer,
  common: common.reducer,
  usersAndRoles: usersAndRoles.reducer,
  cit: cit.reducer,
  objects: objects.reducer,
  license: license.reducer,
  appConfig: appConfig.reducer,
})
