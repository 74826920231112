import React, { ReactElement } from 'react'

import { useClasses } from './styles'

const TableFooter: React.FC = ({ children }): ReactElement => {
  const classes = useClasses()

  return <div className={classes.footer}>{children}</div>
}

export default TableFooter
