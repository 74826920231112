import { CashOrdersPaging } from '@/types/vault/cashOrders/paging'
import { CashOrdersFilters } from '@/types/vault/cashOrders/filters'
import { CashOrderActions, CreateCashOrderFormValues } from '@/constants/index'
import { AnyAction } from 'redux'
import {
  GetCashOrdersResponse,
  CashOrderStage,
  CashOrderDetail,
  ValuableOrderDetail,
  GetCashOrdersRequestPayload,
  Vault,
} from '@/api/vault/cashOrdersApi/types'
import { CashOrder, Currency, Valuable } from '@/types'

export const applyCashOrderStageRequest = (stage: string): AnyAction => ({
  type: CashOrderActions.ApplyStage,
  payload: stage,
})

export const getCashOrderDocumentRequest = (docType: string): AnyAction => ({
  type: CashOrderActions.GetCashOrderDocumentRequest,
  payload: docType,
})

export const getCashOrderDocumentResponse = (bytes: any): AnyAction => ({
  type: CashOrderActions.GetCashOrderDocumentResponse,
  payload: bytes,
})

export const getCashOrderDocumentTypesRequest = (): AnyAction => ({
  type: CashOrderActions.GetCashOrderDocumentTypesRequest,
  payload: null,
})

export const getCashOrderDocumentTypesResponse = (docTypesResponse: any): AnyAction => ({
  type: CashOrderActions.GetCashOrderDocumentTypesResponse,
  payload: docTypesResponse,
})

export const validateCashBagNumberRequest = (payload: any): AnyAction => ({
  type: CashOrderActions.ValidateCashBagNumberRequest,
  payload: payload,
})

export const validateCashBagNumberResponse = (response: any): AnyAction => ({
  type: CashOrderActions.ValidateCashBagNumberResponse,
  payload: response,
})

export const getExistingCashBagsRequest = (payload: any): AnyAction => ({
  type: CashOrderActions.GetExistingCashBagsRequest,
  payload: payload,
})

export const getExistingCashBagsResponse = (fragment: any): AnyAction => ({
  type: CashOrderActions.GetExistingCashBagsResponse,
  payload: fragment,
})

export const assingTeamForOrder = (teamID: string): AnyAction => ({
  type: CashOrderActions.AssingTeamForOrder,
  payload: teamID,
})
export const createWorkOrder = (teamIDs: string[]): AnyAction => ({
  type: CashOrderActions.CreateWorkOrder,
  payload: teamIDs,
})

export const stagePassed = (): AnyAction => ({
  type: CashOrderActions.StagePassed,
  payload: null,
})

export const getCashOrdersRequest = (payload?: GetCashOrdersRequestPayload): AnyAction => ({
  type: CashOrderActions.GetCashOrdersRequest,
  payload: payload,
})

export const editCashOrderRequest = (cashOrder: any): AnyAction => ({
  type: CashOrderActions.EditCashOrderRequest,
  payload: cashOrder,
})

export const setOrderEditStatus = (status: boolean | null): AnyAction => ({
  type: CashOrderActions.SetOrderEditStatus,
  payload: status,
})

export const setSelectedCashOrder = (rowData: any): AnyAction => ({
  type: CashOrderActions.SetSelectedCashOrder,
  payload: rowData,
})

export const setSelectedCashOrderBag = (bag: any, type?: string | null): AnyAction => ({
  type: CashOrderActions.SetSelectedCashOrderBag,
  payload: bag || null,
})

export const getCashOrdersResponse = (cashOrders: CashOrder[]): AnyAction => ({
  type: CashOrderActions.GetCashOrdersResponse,
  payload: cashOrders,
})

export const getCashOrdersResponseFail = (errorMessage: string): AnyAction => ({
  type: CashOrderActions.GetCashOrdersResponseFail,
  payload: errorMessage,
})

export const setPanelLayout = (layoutType: 'horizontal' | 'vertical'): AnyAction => ({
  type: CashOrderActions.SetPanelLayout,
  payload: layoutType,
})

export const changeSelectedFilters = (filters: CashOrdersFilters): AnyAction => ({
  type: CashOrderActions.ChangeSelectedFilters,
  payload: filters,
})

export const changePagingRequest = (pagingRequest: {
  page?: number
  pageSize?: number
}): AnyAction => ({
  type: CashOrderActions.ChangePagingRequest,
  payload: pagingRequest,
})

export const changePagingResponse = (pagingResponse: CashOrdersPaging): AnyAction => ({
  type: CashOrderActions.ChangePagingResponse,
  payload: pagingResponse,
})

export const clearSelectedFilters = (): AnyAction => ({
  type: CashOrderActions.ClearSelectedFilters,
})

export const getCashOrderBagsRequest = (): AnyAction => ({
  type: CashOrderActions.GetCashOrderBagsRequest,
  payload: null,
})

export const getCashOrderBagsResponse = (cashOrderBagsResponse: any): AnyAction => ({
  type: CashOrderActions.GetCashOrderBagsResponse,
  payload: cashOrderBagsResponse,
})

export const getCashOrderStatusesRequest = (): AnyAction => ({
  type: CashOrderActions.GetCashOrderStatusesRequest,
  payload: null,
})

export const getCashOrderStatusesResponse = (cashOrderStatusesResponse: any): AnyAction => ({
  type: CashOrderActions.GetCashOrderStatusesResponse,
  payload: cashOrderStatusesResponse,
})

export const getCashOrderTypesRequest = (): AnyAction => ({
  type: CashOrderActions.GetCashOrderTypesRequest,
  payload: null,
})

export const getCashOrderTypesResponse = (cashOrderTypesResponse: any): AnyAction => ({
  type: CashOrderActions.GetCashOrderTypesResponse,
  payload: cashOrderTypesResponse,
})

export const getCashOrderCommentsRequest = (): AnyAction => ({
  type: CashOrderActions.GetCashOrderCommentsRequest,
  payload: null,
})

export const getCashOrderCommentsResponse = (cashOrderCommentsResponse: any): AnyAction => ({
  type: CashOrderActions.GetCashOrderCommentsResponse,
  payload: cashOrderCommentsResponse,
})

export const getCashOrderStagesRequest = (): AnyAction => ({
  type: CashOrderActions.GetCashOrderStagesRequest,
  payload: null,
})

export const getCashOrderStagesResponse = (
  cashOrderStagesResponse: Array<CashOrderStage>,
): AnyAction => ({
  type: CashOrderActions.GetCashOrderStagesResponse,
  payload: cashOrderStagesResponse,
})

export const getCashOrderDetailsRequest = (): AnyAction => ({
  type: CashOrderActions.GetCashOrderDetailsRequest,
  payload: null,
})

export const getCashOrderDetailsResponse = (
  cashOrderDetailsResponse: Array<CashOrderDetail | ValuableOrderDetail>,
): AnyAction => ({
  type: CashOrderActions.GetCashOrderDetailsResponse,
  payload: cashOrderDetailsResponse,
})

export const getCashOrderCurrenciesRequest = (): AnyAction => ({
  type: CashOrderActions.GetCurrenciesRequest,
  payload: null,
})

export const getCashOrderCurrenciesResponse = (payload: Currency[]): AnyAction => ({
  type: CashOrderActions.GetCurrenciesResponse,
  payload: payload,
})

export const getCashOrderCurrenciesResponseFail = (): AnyAction => ({
  type: CashOrderActions.GetCurrenciesResponse,
  payload: null,
})

export const getCreateOrderFormValuablesRequest = (): AnyAction => ({
  type: CashOrderActions.GetCreateOrderFormValuablesRequest,
  payload: null,
})

export const getCreateOrderFormValuablesResponse = (valuables: Valuable[]): AnyAction => ({
  type: CashOrderActions.GetCreateOrderFormValuablesResponse,
  payload: valuables,
})

export const getTransmitterVaultsRequest = (): AnyAction => ({
  type: CashOrderActions.GetTransmitterVaultsRequest,
  payload: null,
})

export const getTransmitterVaultsResponse = (transmitters: Vault[]): AnyAction => ({
  type: CashOrderActions.GetTransmitterVaultsResponse,
  payload: transmitters,
})

export const getRecieverVaultsRequest = (): AnyAction => ({
  type: CashOrderActions.GetRecieverVaultsRequest,
  payload: null,
})

export const getRecieverVaultsResponse = (recievers: Vault[]): AnyAction => ({
  type: CashOrderActions.GetRecieverVaultsResponse,
  payload: recievers,
})

export const getTransmitterVaultsFromRecieverRequest = (recieverVaultId: string): AnyAction => ({
  type: CashOrderActions.GetTransmitterVaultsFromRecieverRequest,
  payload: recieverVaultId,
})

export const getRecieverVaultsFromTransmitterRequest = (transmitterVaultId: string): AnyAction => ({
  type: CashOrderActions.GetRecieverVaultsFromTransmitterRequest,
  payload: transmitterVaultId,
})

export const getOrderLoadsRequest = (orderID: string): AnyAction => ({
  type: CashOrderActions.GetOrderLoadsRequest,
  payload: orderID,
})

export const getOrderLoadsResponse = (loads: any): AnyAction => ({
  type: CashOrderActions.GetOrderLoadsResponse,
  payload: loads,
})

export const createOrderRequest = (formValues: CreateCashOrderFormValues): AnyAction => ({
  type: CashOrderActions.CreateOrderRequest,
  payload: formValues,
})

export const editCashDeliveryOrderRequest = (formValues: CreateCashOrderFormValues): AnyAction => ({
  type: CashOrderActions.EditCashDeliveryOrderRequest,
  payload: formValues,
})

export const createCashDeliveryOrderRequest = (
  formValues: CreateCashOrderFormValues,
): AnyAction => ({
  type: CashOrderActions.CreateCashDeliveryOrderRequest,
  payload: formValues,
})

export const assignBagForStorage = (payload: any): AnyAction => ({
  type: CashOrderActions.AssignBagForStorage,
  payload: payload,
})

export const assignBagForCashier = (values: any): AnyAction => ({
  type: CashOrderActions.AssignBagForCashier,
  payload: values,
})

export const assignBagSuccess = (): AnyAction => ({
  type: CashOrderActions.AssignBagSuccess,
  payload: null,
})

export const getAvailableVaultsRequest = (bagId: number): AnyAction => ({
  type: CashOrderActions.GetAvailableVaultsRequest,
  payload: bagId,
})

export const getAvailableVaultsResponse = (response: any): AnyAction => ({
  type: CashOrderActions.GetAvailableVaultsResponse,
  payload: response,
})
