import queryString from 'query-string'

import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

import {
  ComCMReport,
  ComModifyCassetteConfigRequest,
  ComObjectCassetteBase,
  PlnAcceptRequest,
  PlnCalculateRequest,
  PlnCashPoint,
  PlnCashPointsRequest,
  PlnCashPointsResponse,
  PlnModifyCalculateRequest,
  PlnModifyCalculateResponse,
  PlnModifyRequest,
  PlnRecalculateExpensesResponse,
  PlnReportsRequest,
  PlnSendToExecuteRequest,
  PlnSendToExecuteResponse,
  PlnSettingsResponse,
} from '../swaggerGeneratedApi'

export class PlanningApi {
  private static requestService = getRequestServiceCM()

  public static async getCashPoints(
    paging: { page: number; pageSize: number },
    requestBody: PlnCashPointsRequest,
    sortColumn?: string | undefined,
    sortOrder?: 'asc' | 'desc' | undefined,
  ): Promise<PlnCashPointsResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/planning/points',
          query: {
            pageSize: `${paging.pageSize}`,
            page: `${paging.page}`,
            sortColumn,
            sortOrder,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await PlanningApi.requestService.post(url, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashPoint(guid: string): Promise<PlnCashPoint> {
    try {
      return await PlanningApi.requestService.get(`/planning/point?guid=${guid}`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getReports(payload: PlnReportsRequest): Promise<ComCMReport[]> {
    try {
      return await PlanningApi.requestService.post('/planning/reports', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCassetteTypes(): Promise<string[]> {
    try {
      return await PlanningApi.requestService.get('/planning/cassetesTypes')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getConfig(guid: string): Promise<ComObjectCassetteBase[]> {
    try {
      return await PlanningApi.requestService.get(`/planning/config`, { guid })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getSettings(): Promise<PlnSettingsResponse> {
    try {
      return await PlanningApi.requestService.get(`/planning/settings`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async modify(requestBody: PlnModifyRequest): Promise<void> {
    try {
      return await PlanningApi.requestService.post(`/planning/modify`, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getModifyExpenses(
    requestBody: PlnModifyRequest,
  ): Promise<PlnRecalculateExpensesResponse> {
    try {
      return await PlanningApi.requestService.post(`/planning/expenses`, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async modifyConfig(
    guid: string,
    cassettes: ComModifyCassetteConfigRequest,
  ): Promise<void> {
    try {
      return await PlanningApi.requestService.post(`/planning/modifyConfig?guid=${guid}`, {
        cassetes: cassettes,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async calculateExt(
    requestBody: PlnModifyCalculateRequest,
  ): Promise<PlnModifyCalculateResponse> {
    try {
      return await PlanningApi.requestService.post('/planning/calculateExt', requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async calculate(requestBody: PlnCalculateRequest): Promise<void> {
    try {
      return await PlanningApi.requestService.post('/planning/calculate', requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async accept(requestBody: PlnAcceptRequest): Promise<void> {
    try {
      return await PlanningApi.requestService.post('/planning/accept', requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async sendToExecute(
    requestBody: PlnSendToExecuteRequest,
  ): Promise<PlnSendToExecuteResponse> {
    try {
      return await PlanningApi.requestService.post('/planning/send', requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }
}
