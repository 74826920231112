// core
import { Grid } from '@material-ui/core'
import { Field } from 'formik'
import React, { FC } from 'react'

import { TicketsApi } from '@/api/sd/ticketsApi'
import { createDatePickerFormField } from '@/components/blocks/USM/Date'
// hooks
import { useGenericInput } from '@/components/blocks/USM/hooks'
// components
import { createCheckboxField } from '@/components/controls/Checkbox'
import GeneratedField from '@/components/controls/GeneratedField'
import { FieldTypeName } from '@/components/controls/GeneratedField/types'
import LookupsList from '@/components/controls/LookupsList'
import { UserInformationField } from '@/components/pages/usm/UsersPage/components/UserInformation/components/modals/PersonModal/types'
import { UserInformationColumns } from '@/components/pages/usm/UsersPage/components/UserInformation/types'
import GeneratedCompanyField from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/AddUserModal/shortNameLookup'
import { useTranslate } from '@/utils/internalization'

// types
import { FieldPropsTypesUserInfo, Props } from './types'

const UserInfoField: FC<FieldPropsTypesUserInfo> = ({
  formikValues,
  lookupFieldConfig,
  setFormikFieldValue,
  selectedLastRow,
  isLoading,
  useBackupValues,
  generateCompanyName,
}: FieldPropsTypesUserInfo) => {
  const translate = useTranslate()
  const { GenericInput } = useGenericInput()
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs>
          <GeneratedCompanyField
            formikValues={formikValues}
            fieldName={UserInformationField.Company}
            field={{
              options: {
                selectAndDropDownInput: {
                  label: translate('translate#usm.title.add.user.modal.label.company'),
                  placeholder: translate('translate#usm.title.add.user.modal.placeholder.company'),
                  required: true,
                },
              },
            }}
            defaultValue={{
              name: selectedLastRow?.company,
              value: selectedLastRow?.companyId,
            }}
            setFormikFieldValue={setFormikFieldValue}
            backupOptions={useBackupValues}
          />
        </Grid>
        {/* <Grid item xs>
          <Field name={UserInformationField.UserName}>
            {GenericInput({
              label: translate('translate#usm.title.add.user.modal.label.user.name'),
              placeholder: translate('translate#usm.title.add.user.modal.label.user.name'),
              required: true,
            })}
          </Field>
        </Grid> */}
        <Grid item xs>
          <GeneratedField
            field={{
              type: FieldTypeName.List,
              options: {
                selectAndDropDownInput: {
                  label: translate('translate#usm.title.drawer.action.user.group'),
                  placeholder: translate('translate#usm.title.drawer.action.user.group'),
                  required: true,
                },
              },
            }}
            fieldName={UserInformationField.UserGroup}
            formikValues={formikValues}
            setFormikFieldValue={setFormikFieldValue}
            lookupFieldConfig={lookupFieldConfig?.find(
              field => field.fieldName === UserInformationField.UserGroup,
            )}
            defaultValue={{
              name: selectedLastRow?.[UserInformationColumns.UserGroup],
              value: selectedLastRow?.userGroupId,
            }}
            backupOptions={generateCompanyName}
            isLoading={isLoading}
          />
        </Grid>
        {/* <Grid item xs>
          <LookupsList
            isDisabled={false}
            defaultValue={{
              value: selectedLastRow?.roleId,
              name: selectedLastRow?.roleName
                ? translate(`translate#${selectedLastRow?.roleName}`)
                : '',
            }}
            setFormikFieldValue={setFormikFieldValue}
            field={{
              options: {
                label: translate('translate#bl.userRole'),
                placeholder: translate('translate#title.select'),
                required: false,
                isNumber: true,
              },
            }}
            isDependsOn={false}
            fieldName={UserInformationField.RoleId}
            fieldTitle={UserInformationField.RoleName}
            dependsOn={[]}
            onCall={async (
              value: string,
            ): Promise<{ item: unknown; value: string; name: string }[]> => {
              const response = await TicketsApi.getLookupsValues({
                lookupKey: 'BL_USER_ROLE',
                module: 'USM',
                fragment: value,
                pageNo: 0,
                pageSize: 100,
              })
              return response.values.map(item => ({
                item: item,
                value: String(item.id),
                name: translate(`translate#${item.name}`),
              }))
            }}
          />
        </Grid> */}
      </Grid>
    </>
  )
}

const UserInfoDateFields: FC<Props> = ({
  values,
  size,
  style,
  sizeDateField,
  sizeCheckBoxField,
  defaultDate,
  currentDate,
}: Props) => {
  const translate = useTranslate()
  const { GenericInput } = useGenericInput()
  return (
    <>
      <Grid item xs={size}>
        <Field name={UserInformationField.EMail}>
          {GenericInput({
            label: translate('translate#usm.title.add.user.modal.label.email'),
            placeholder: 'e@mail.com',
            required: true,
          })}
        </Field>
      </Grid>
      <Grid item xs={sizeDateField}>
        <Field name={UserInformationField.ExpiryDate}>
          {createDatePickerFormField({
            label: translate('translate#usm.title.add.user.modal.label.expiry.date'),
            format: 'MMMM DD, YYYY',
            disabled: values[UserInformationField.NeverExpires],
            disabledPast: true,
            disableFutureDates: false,
            dependDate: values[UserInformationField.NeverExpires],
            defaultDate: defaultDate,
            currentDate: currentDate,
          })}
        </Field>
      </Grid>
      <Grid item xs={sizeCheckBoxField}>
        <Field name={UserInformationField.NeverExpires}>
          {createCheckboxField({
            label: translate('translate#usm.title.add.user.modal.label.never.expires'),
            styles: {
              label: style,
            },
            width: 'fit-content',
          })}
        </Field>
      </Grid>
      <Grid item xs={sizeCheckBoxField}>
        <Field name={UserInformationField.AllowAutoProlong}>
          {createCheckboxField({
            label: translate('translate#usm.title.add.user.modal.label.allowAutoProlong'),
            styles: {
              label: style,
            },
            width: 'fit-content',
            disabled: values[UserInformationField.NeverExpires],
          })}
        </Field>
      </Grid>
    </>
  )
}

export { UserInfoDateFields, UserInfoField }
