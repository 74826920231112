import { createSelectField } from '@/components/controls/Select'
import * as React from 'react'

interface Args {
  label: string
  placeholder: string
  disabled?: boolean
  options: {
    name: string
    value: string
  }[]
  required?: boolean
  parameterName?: string
  className?: string
  onChange?: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: React.ReactNode,
  ) => void
}

export const Select = ({
  label,
  placeholder,
  disabled = false,
  options,
  required = false,
  parameterName = '',
  className,
  onChange,
}: Args) =>
  createSelectField({
    disabled,
    required,
    label,
    fullWidth: true,
    shrink: true,
    options,
    placeholder,
    parameterName,
    className,
    onChange,
  })

type Interface = Omit<Args, 'onChange'>

export const SelectMonth = ({
  label,
  placeholder,
  disabled = false,
  options,
  required = false,
  parameterName = '',
}: Interface) =>
  createSelectField({
    disabled,
    required,
    label,
    fullWidth: true,
    shrink: true,
    options,
    placeholder,
    parameterName,
  })
