import { LoginFormFields, LoginFormValues } from '@/constants/forms/LoginForm'
import { ValidationErrors } from '@/constants/forms/validationErrors'
import { ValidationService } from '@/utils/services/validationService'

export const validate = (values: LoginFormValues): Partial<LoginFormValues> => {
  const errors: Partial<LoginFormValues> = {}
  if (!ValidationService.isRequired(values[LoginFormFields.Identifier])) {
    errors[LoginFormFields.Identifier] = ValidationErrors.Required
  }
  if (!ValidationService.isRequired(values[LoginFormFields.Password])) {
    errors[LoginFormFields.Password] = ValidationErrors.Required
  }

  // not delete
  // if (!ValidationService.isRequired(values[LoginFormFields.Timezone])) {
  //   errors[LoginFormFields.Timezone] = ValidationErrors.Required
  // }

  // if (!ValidationService.isMinLengthPassword(values[LoginFormFields.Password])) {
  //   errors[LoginFormFields.Password] = ValidationErrors.MinLengthOfPassword
  // }

  // if (!ValidationService.isMinLengthLogin(values[LoginFormFields.Identifier])) {
  //   errors[LoginFormFields.Identifier] = ValidationErrors.MinLengthOfLogin
  // }

  // if (ValidationService.isMoreLengthUsername(values[LoginFormFields.Identifier])) {
  //   errors[LoginFormFields.Identifier] = ValidationErrors.MaxLengthOfLogin
  // }

  // if (ValidationService.isMoreLengthPassword(values[LoginFormFields.Password]))
  //   errors[LoginFormFields.Password] = ValidationErrors.MaxLengthOfPassword

  // if (ValidationService.isSpace(values[LoginFormFields.Identifier])) {
  //   errors[LoginFormFields.Identifier] = ValidationErrors.UnacceptableSymbols
  // }

  // if (ValidationService.isSpace(values[LoginFormFields.Password])) {
  //   errors[LoginFormFields.Password] = ValidationErrors.UnacceptablePasswordSymbols
  // }

  // if (ValidationService.isSpecialSymbols(values[LoginFormFields.Identifier])) {
  //   errors[LoginFormFields.Identifier] = ValidationErrors.UnacceptableSymbols
  // }
  return errors
}
