import Slider from '@material-ui/core/Slider'
import CloseIcon from '@material-ui/icons/Close'
import React, { memo, ReactElement, useCallback, useRef } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'

import Loader from '@/components/blocks/Loader'
import { TABS } from '@/components/pages/atmeye/Devices/Components/CurrentDevice/Tabs/TabsPanel/types'
import colors from '@/theme/colors'
import { useClickOutside } from '@/utils/hooks/hooks'
import { useTranslate } from '@/utils/internalization'

import { CustomTooltip } from './components/customTooltip/customTooltip'
import { useChart } from './customHooks/useChart'
import { useClasses } from './styles'
import { INTERVAL_TYPE, Props } from './types'

const INTERVAL_LABELS = {
  LAST_TWELVE_HOURS: '12h',
  LAST_DAY: '1d',
  LAST_THREE_DAYS: '3d',
  LAST_WEEK: '1w',
  LAST_TWO_WEEKS: '2w',
  LAST_MONTH: '1m',
  LAST_YEAR: '1y',
}

export const INTERVALS = [
  {
    value: 0,
    label: INTERVAL_LABELS[INTERVAL_TYPE.LAST_TWELVE_HOURS],
    type: INTERVAL_TYPE.LAST_TWELVE_HOURS,
  },
  {
    value: 10,
    label: INTERVAL_LABELS[INTERVAL_TYPE.LAST_DAY],
    type: INTERVAL_TYPE.LAST_DAY,
  },
  {
    value: 20,
    label: INTERVAL_LABELS[INTERVAL_TYPE.LAST_THREE_DAYS],
    type: INTERVAL_TYPE.LAST_THREE_DAYS,
  },
  {
    value: 30,
    label: INTERVAL_LABELS[INTERVAL_TYPE.LAST_WEEK],
    type: INTERVAL_TYPE.LAST_WEEK,
  },
  {
    value: 40,
    label: INTERVAL_LABELS[INTERVAL_TYPE.LAST_TWO_WEEKS],
    type: INTERVAL_TYPE.LAST_TWO_WEEKS,
  },
  {
    value: 50,
    label: INTERVAL_LABELS[INTERVAL_TYPE.LAST_MONTH],
    type: INTERVAL_TYPE.LAST_MONTH,
  },
  {
    value: 60,
    label: INTERVAL_LABELS[INTERVAL_TYPE.LAST_YEAR],
    type: INTERVAL_TYPE.LAST_YEAR,
  },
]

export const getIntervalByType = (intervalType?: string) => {
  return INTERVALS.find(({ type }) => type === intervalType)
}

const COLORS = {
  TRANSACTIONS: colors.products.primary,
  TECH_EVENTS: colors.products.warning,
  ALARMS: colors.products.danger,
}

export const ATMeyeBarChart = memo(
  ({
    height,
    currentDevice,
    setTab,
    setSegmentFilters,
    isUpdateHistogram,
    setIsUpdateHistogram,
  }: Props): ReactElement => {
    const {
      isLoading,
      isShowTooltip,

      transactions,
      techEvents,
      alarms,
      chartInterval,
      histogramMaxInterval,

      transactionPercentage,
      techEventsPercentage,
      alarmsPercentage,

      tooltipPayload,
      tooltipPosition,

      chartData,

      handleIntervalChange,
      handleCloseTooltip,
      handleChartClick,
      handleTooltipClick,
      getCustomizedTickValue,
      getSegmentsPercentage,
    } = useChart({
      currentDevice,
      setTab,
      setSegmentFilters,
      isUpdateHistogram,
      setIsUpdateHistogram,
    })

    const classes = useClasses()
    const translate = useTranslate()

    const modalRef = useRef<HTMLDivElement | null>(null)

    useClickOutside(modalRef, () => handleCloseTooltip())

    const renderSegment = useCallback(
      ({ label, segmentValue, color, percentage, allSegmentsCount }) => {
        const data = [
          {
            value: segmentValue,
          },
          {
            value: allSegmentsCount - segmentValue,
          },
        ]

        return (
          <>
            <PieChart width={50} height={50}>
              <Pie
                startAngle={-270}
                data={data}
                cx="50%"
                cy="50%"
                dataKey="value"
                innerRadius={20}
                outerRadius={25}
              >
                <Cell key="main-cell" fill={color} />
                <Cell key="secondary-cell" fill={colors.grey100} />

                <Label
                  value={`${percentage || 0}%`}
                  position="center"
                  fill={color}
                  style={{
                    fontSize: '12px',
                    cursor: 'pointer',
                  }}
                />
              </Pie>
            </PieChart>

            <div className={classes.segmentInfo}>
              <div>{label}</div>
              <div
                style={{
                  color: color,
                }}
              >
                {segmentValue}
              </div>
            </div>
          </>
        )
      },
      [classes.segmentInfo],
    )

    const renderTooltip = useCallback((): ReactElement | null => {
      if (tooltipPayload) {
        const transactions = tooltipPayload.transactionsCount
        const techEvents = tooltipPayload.technicalEventsCount
        const alarms = tooltipPayload.alarmsCount

        const label = `${tooltipPayload.intervalStartLabel} - ${tooltipPayload.intervalEndLabel}`

        const allSegmentsCount = transactions + techEvents + alarms

        const {
          transactionPercentage,
          techEventsPercentage,
          alarmsPercentage,
        } = getSegmentsPercentage({ transactions, techEvents, alarms })

        return (
          <div className={classes.tooltipWrapper}>
            <div className={classes.tooltipLabelBlock}>
              <div>{label}</div>

              <CloseIcon className={classes.closeButton} onClick={handleCloseTooltip} />
            </div>

            <div className={classes.segmentsBlock}>
              <div
                className={classes.segmentWrapper}
                onClick={handleTooltipClick(TABS.TRANSACTIONS)}
              >
                {renderSegment({
                  label: translate('translate#atmeye.title.transactions'),
                  segmentValue: transactions,
                  color: COLORS.TRANSACTIONS,
                  percentage: transactionPercentage,
                  allSegmentsCount,
                })}
              </div>

              <div
                className={classes.segmentWrapper}
                onClick={handleTooltipClick(TABS.TECHNICAL_EVENTS)}
              >
                {renderSegment({
                  label: translate('translate#atmeye.title.devices.techEvents'),
                  segmentValue: techEvents,
                  color: COLORS.TECH_EVENTS,
                  percentage: techEventsPercentage,
                  allSegmentsCount,
                })}
              </div>

              <div
                className={classes.segmentWrapper}
                onClick={handleTooltipClick(TABS.SECURITY_ALARMS)}
              >
                {renderSegment({
                  label: translate('translate#atmeye.alarms'),
                  segmentValue: alarms,
                  color: COLORS.ALARMS,
                  percentage: alarmsPercentage,
                  allSegmentsCount,
                })}
              </div>
            </div>
          </div>
        )
      }

      return null
    }, [
      classes.closeButton,
      classes.segmentWrapper,
      classes.segmentsBlock,
      classes.tooltipLabelBlock,
      classes.tooltipWrapper,
      getSegmentsPercentage,
      handleCloseTooltip,
      handleTooltipClick,
      renderSegment,
      tooltipPayload,
    ])

    const CustomizedAxisTick = useCallback(
      ({ x, y, payload }) => {
        const { primaryTick, secondaryTick } = getCustomizedTickValue(payload.value)

        return (
          <g transform={`translate(${x},${y})`}>
            <text x={-10} y={0} dy={10} fill="#666" fontSize="10px">
              {primaryTick}
            </text>

            {secondaryTick && (
              <text x={-10} y={15} dy={10} fill="#666" fontSize="10px">
                {secondaryTick}
              </text>
            )}
          </g>
        )
      },
      [getCustomizedTickValue],
    )

    return (
      <>
        {currentDevice ? (
          <>
            <div ref={modalRef} className={classes.chartWrapper}>
              <div className={classes.chart}>
                {!isLoading ? (
                  <ResponsiveContainer height={height} width="100%">
                    <BarChart
                      data={chartData}
                      className={classes.pointerCursor}
                      barSize={10}
                      onClick={handleChartClick}
                    >
                      <CartesianGrid strokeDasharray="2 2" vertical={false} stroke="#ECF2F2" />

                      <XAxis
                        scale="point"
                        dataKey="intervalStartLabel"
                        padding={{ left: 30, right: 30 }}
                        axisLine={false}
                        tickLine={false}
                        tick={<CustomizedAxisTick />}
                      />

                      <YAxis
                        axisLine={false}
                        tickLine={false}
                        tick={{
                          color: '#929A9B',
                          fontSize: '10px',
                        }}
                      />

                      <Bar dataKey="transactionsCount" stackId="a" fill={COLORS.TRANSACTIONS}>
                        {chartData.map(({ columnLabel, technicalEventsCount, alarmsCount }) => {
                          let clipPath
                          if (technicalEventsCount === 0 && alarmsCount === 0) {
                            clipPath = 'inset(0% 0% 0% 0% round 5px 5px 0 0)'
                          }
                          return (
                            <Cell
                              key={columnLabel}
                              fill={COLORS.TRANSACTIONS}
                              clipPath={clipPath}
                            />
                          )
                        })}
                      </Bar>

                      <Bar dataKey="technicalEventsCount" stackId="a">
                        {chartData.map(({ columnLabel, alarmsCount }) => {
                          let clipPath
                          if (alarmsCount === 0) {
                            clipPath = 'inset(0% 0% 0% 0% round 5px 5px 0 0)'
                          }
                          return (
                            <Cell key={columnLabel} fill={COLORS.TECH_EVENTS} clipPath={clipPath} />
                          )
                        })}
                      </Bar>

                      <Bar
                        dataKey="alarmsCount"
                        stackId="a"
                        fill={COLORS.ALARMS}
                        radius={[5, 5, 0, 0]}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <Loader />
                )}
              </div>

              <div className={classes.chartInfo}>
                <div className={classes.sliderWrapper}>
                  <Slider
                    track={false}
                    aria-label="Chart Intervals"
                    valueLabelDisplay="off"
                    defaultValue={chartInterval}
                    step={10}
                    max={histogramMaxInterval}
                    classes={{
                      markLabel: classes.markLabel,
                      markLabelActive: classes.markLabelActive,
                    }}
                    marks={INTERVALS}
                    onChangeCommitted={handleIntervalChange}
                  />
                </div>

                <div className={classes.segmentsBlock}>
                  <div className={classes.segmentWrapper}>
                    {renderSegment({
                      label: translate('translate#atmeye.title.transactions'),
                      segmentValue: transactions,
                      color: COLORS.TRANSACTIONS,
                      percentage: transactionPercentage,
                      allSegmentsCount: transactions + techEvents + alarms,
                    })}
                  </div>

                  <div className={classes.segmentWrapper}>
                    {renderSegment({
                      label: translate('translate#atmeye.title.devices.techEvents'),
                      segmentValue: techEvents,
                      color: COLORS.TECH_EVENTS,
                      percentage: techEventsPercentage,
                      allSegmentsCount: transactions + techEvents + alarms,
                    })}
                  </div>

                  <div className={classes.segmentWrapper}>
                    {renderSegment({
                      label: translate('translate#atmeye.alarms'),
                      segmentValue: alarms,
                      color: COLORS.ALARMS,
                      percentage: alarmsPercentage,
                      allSegmentsCount: transactions + techEvents + alarms,
                    })}
                  </div>
                </div>
              </div>
            </div>

            <CustomTooltip
              isOpen={isShowTooltip}
              onClose={handleCloseTooltip}
              top={tooltipPosition.y}
              left={tooltipPosition.x}
              content={renderTooltip()}
            />
          </>
        ) : (
          <div className={classes.emptyCurrentDevice}>
            {translate('translate#atmeye.title.deviceNotSelect')}
          </div>
        )}
      </>
    )
  },
)
