import { AnyAction } from 'redux'

import { Filter, GetGroupDetailsResponse, Grouping, Sorting } from '@/api/sd/ticketsApi/types'
import { TableFilter } from '@/components/controls/AppTable/types'
import { AtmeyeDeviceActions, TicketActions } from '@/constants'
import { defaultConfigGroups, defaultGrouping } from '@/constants/atmeye/tables/defaultGrouping'
import { WINDOWS_TYPES } from '@/constants/atmeye/tables/windowTypes'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { DEFAULT_TICKET_DETAILS, ticketConfig } from '@/store/sd/tickets/reducer'
import {
  GroupingTemplate,
  Pagination,
  ParentGroup,
  TicketDetailsData,
  TicketsConfigData,
  TypeOfTickets,
} from '@/types'
import { GetTicketsResponse } from '@/types/sd/common'
import { deepCopy } from '@/utils/deepCopy'
import { sortAlphabeticallyStringArray } from '@/utils/functions'
import { getAtmeyeSavedGrouping, getLocalStorageItem } from '@/utils/localStorage'

export interface State {
  selectedGroupingTemplate: string
  data: {
    allTickets: GetTicketsResponse
    myTickets: GetTicketsResponse
    historyTickets: GetTicketsResponse
  }
  groupDetails: GetGroupDetailsResponse

  pageSizeForGroupingTickets: string

  pageCount: number
  filter: {
    allTickets: Filter
    myTickets: Filter
    historyTickets: Filter
  }
  filterTemplateFields: {
    [key: string]: {
      id: string
      displayName: string
    }
  }
  filterFields: {
    allTickets: {
      id: string
      displayName: string
    }
    myTickets: {
      id: string
      displayName: string
    }
    historyTickets: {
      id: string
      displayName: string
    }
  }
  grouping: {
    allTickets: Grouping
    myTickets: Grouping
    historyTickets: Grouping
  }
  ticketDetails: TicketDetailsData
  config: {
    allTickets: TicketsConfigData
    myTickets: TicketsConfigData
    historyTickets: TicketsConfigData
  }
  pagination: Pagination
  sorting: {
    allTickets: Sorting
    myTickets: Sorting
    historyTickets: Sorting
  }
  stringFitler: {
    allTickets: TableFilter | {}
    myTickets: TableFilter | {}
    historyTickets: TableFilter | {}
  }
  typeOfTickets: TypeOfTickets
  rowsPerPage: {
    allTickets: number
    myTickets: number
    historyTickets: number
  }
  page: {
    allTickets: number
    myTickets: number
    historyTickets: number
  }
  downloadFile: string
  parentGroups: ParentGroup[]
  isFetchingTickets: boolean
  isFetchingGroupDetails: boolean
  isSavingSettings: boolean
  isDeletingTicket: boolean
  isFetchingTicket: boolean
  isFetchingGroupTemplates: boolean
  isFetchingFilterTemplates: boolean
  isSavingFilterTemplates: boolean
  isEditingFilterTemplate: boolean
  isSavingGroupTemplate: boolean
  isEditingGroupTemplate: boolean
  isDeletingFilterTemplate: boolean
  isDeletingGroupingTemplate: boolean
  isFetchingTicketsConfig: boolean
  isFetchingTicketDetails: boolean
  isDownloadingFile: boolean
  error: string | null
}

const initialTicketConfig = {
  ...deepCopy(ticketConfig),
  groups: defaultConfigGroups.devices,
}
const savedGrouping = getAtmeyeSavedGrouping(WINDOWS_TYPES.DEVICES)

const lsConfig = getLocalStorageItem(LocalStorageItems.Devices)

export const initialState: State = {
  data: {
    allTickets: {
      details: undefined,
      groups: null,
      hasGroups: false,
      totalCount: 0,
    },
    myTickets: { details: undefined, groups: null, hasGroups: false, totalCount: 0 },
    historyTickets: {
      details: undefined,
      groups: null,
      hasGroups: false,
      totalCount: 0,
    },
  },
  groupDetails: null,
  pageCount: 0,
  filter: {
    allTickets: {
      predefinedFilters: {
        availableSortingFields: [],
        deviceTypeItems: [],
        receiveDateItems: {
          end: null,
          start: null,
        },
        statusItems: [],
        serviceTypeItems: [],
      },
      selectedTemplate: lsConfig?.config?.selectedFiltersTemplate || '',
      selectedFilters: lsConfig?.config?.selectedFilters || {},
      filterTemplates: [],
    },
    myTickets: {
      predefinedFilters: {
        availableSortingFields: [],
        deviceTypeItems: [],
        receiveDateItems: {
          end: null,
          start: null,
        },
        statusItems: [],
        serviceTypeItems: [],
      },
      selectedTemplate: '',
      selectedFilters: {},
      filterTemplates: [],
    },
    historyTickets: {
      predefinedFilters: {
        availableSortingFields: [],
        deviceTypeItems: [],
        receiveDateItems: {
          end: null,
          start: null,
        },
        statusItems: [],
        serviceTypeItems: [],
      },
      selectedTemplate: '',
      selectedFilters: {},
      filterTemplates: [],
    },
  },
  filterTemplateFields: {},
  filterFields: {
    allTickets: {
      id: '',
      displayName: '',
    },
    myTickets: {
      id: '',
      displayName: '',
    },
    historyTickets: {
      id: '',
      displayName: '',
    },
  },
  selectedGroupingTemplate: savedGrouping?.templateId || '',
  grouping: {
    allTickets: {
      predefinedGroupings: {
        items: [],
      },
      selectedGroupings: savedGrouping?.groups || defaultGrouping.devices,
      groupingsTemplates: [],
    },
    myTickets: {
      predefinedGroupings: {
        items: [],
      },
      selectedGroupings: defaultGrouping.devices,
      groupingsTemplates: [],
    },
    historyTickets: {
      predefinedGroupings: {
        items: [],
      },
      selectedGroupings: defaultGrouping.devices,
      groupingsTemplates: [],
    },
  },
  sorting: {
    allTickets: { last_activity_date: 'desc' },
    myTickets: { last_activity_date: 'desc' },
    historyTickets: { last_activity_date: 'desc' },
  },
  stringFitler: {
    allTickets: {},
    myTickets: {},
    historyTickets: {},
  },
  typeOfTickets: 'allTickets',
  rowsPerPage: {
    allTickets: 5,
    myTickets: 5,
    historyTickets: 5,
  },
  page: {
    allTickets: 1,
    myTickets: 1,
    historyTickets: 1,
  },
  parentGroups: [],
  ticketDetails: deepCopy(DEFAULT_TICKET_DETAILS),
  pagination: {
    pageNumber: 1,
    pageSize: 1,
    totalElements: 1,
    totalPages: 1,
  },
  config: {
    allTickets: initialTicketConfig,
    myTickets: initialTicketConfig,
    historyTickets: initialTicketConfig,
  },

  pageSizeForGroupingTickets: '',
  downloadFile: '',

  isFetchingTickets: false,
  isFetchingGroupDetails: false,
  isFetchingTicket: false,
  isSavingSettings: false,
  isFetchingGroupTemplates: false,
  isSavingGroupTemplate: false,
  isEditingGroupTemplate: false,
  isFetchingFilterTemplates: false,
  isSavingFilterTemplates: false,
  isEditingFilterTemplate: false,
  isDeletingTicket: false,
  isDeletingFilterTemplate: false,
  isDeletingGroupingTemplate: false,
  isFetchingTicketsConfig: false,
  isFetchingTicketDetails: false,
  isDownloadingFile: false,
  error: null,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case AtmeyeDeviceActions.GetDevicesRequest:
      return {
        ...state,
        isFetchingTickets: true,
      }
    case AtmeyeDeviceActions.GetDevicesExport:
      return {
        ...state,
        isDownloadingFile: true,
      }
    case AtmeyeDeviceActions.GetExportResponse:
      return {
        ...state,
        downloadFile: payload,
        isDownloadingFile: false,
      }
    case AtmeyeDeviceActions.GetExportResponseFail:
      return {
        ...state,
        error: payload,
        isDownloadingFile: false,
      }
    case AtmeyeDeviceActions.GetDevicesExportGrouped:
      return {
        ...state,
        isDownloadingFile: true,
      }
    case AtmeyeDeviceActions.GetExportGroupedResponse:
      return {
        ...state,
        downloadFile: payload,
        isDownloadingFile: false,
      }
    case AtmeyeDeviceActions.GetExportGroupedResponseFail:
      return {
        ...state,
        error: payload,
        isDownloadingFile: false,
      }
    case TicketActions.GetTicketResponse:
      return {
        ...state,
        data: {
          ...state.data,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.data[state.typeOfTickets],
            hasGroups: payload.hasGroups
              ? payload.hasGroups
              : state.data[state.typeOfTickets].hasGroups,
            groups: payload.groups ? payload.groups : state.data[state.typeOfTickets].groups,
            details: payload.details
              ? {
                  ...state.data[state.typeOfTickets].details,
                  items: payload.details ? payload.details.items : null,
                }
              : state.data[state.typeOfTickets].details,
            totalCount:
              payload.totalCount != null
                ? payload.totalCount
                : state.data[state.typeOfTickets] != null &&
                  state.data[state.typeOfTickets].totalCount != null
                ? state.data[state.typeOfTickets].totalCount
                : null,
          },
        },
        isFetchingTickets: false,
        // ...state,
        // data: { ...state.data, [state.typeOfTickets as TypeOfTickets]: payload },
        // isFetchingTickets: false,
      }
    case TicketActions.GetTicketResponseFail:
      return {
        ...state,
        error: payload,
        isFetchingTickets: false,
      }
    case AtmeyeDeviceActions.GetGroupDetailsRequest:
      return {
        ...state,
        isFetchingGroupDetails: true,
      }
    case TicketActions.GetGroupDetailsResponse:
      return {
        ...state,
        groupDetails: Array.isArray(state.groupDetails)
          ? [...state.groupDetails, ...payload.details.items]
          : [...payload.details.items],
        isFetchingGroupDetails: false,
      }

    case AtmeyeDeviceActions.SetAtmeyeActualCountParentGroup: {
      const {
        totalCount,
        parentGroups: { groupValue },
      } = payload
      return {
        ...state,
        data: {
          ...state.data,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.data[state.typeOfTickets],
            groups: state.data[state.typeOfTickets as TypeOfTickets].groups?.some(
              el => el.group.groupValue === groupValue && el.totalDetailsCount !== totalCount,
            )
              ? state.data[state.typeOfTickets as TypeOfTickets].groups?.map(el => {
                  return el.group.groupValue === groupValue
                    ? { ...el, totalDetailsCount: totalCount }
                    : el
                })
              : state.data[state.typeOfTickets as TypeOfTickets].groups,
          },
        },
      }
    }

    case TicketActions.GetGroupDetailsResponseFail:
      return {
        ...state,
        error: payload,
        isFetchingGroupDetails: false,
      }
    case AtmeyeDeviceActions.SetParentGroups:
      return {
        ...state,
        parentGroups: payload,
      }
    case TicketActions.CollectFilterTemplateFields: {
      const updatedFields = {
        ...state.filterTemplateFields,
        [payload.fieldName]: payload.value,
      }
      return {
        ...state,
        filterTemplateFields: { ...updatedFields },
        filterFields: {
          ...state.filterFields,
          [state.typeOfTickets as TypeOfTickets]: {
            filterFields: { ...updatedFields },
          },
        },
      }
    }
    case TicketActions.ChangeSelectedFilters:
      return {
        ...state,
        filter: {
          ...state.filter,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.filter[state.typeOfTickets as TypeOfTickets],
            selectedFilters: payload,
          },
        },
        isFetchingTickets: true,
      }

    case AtmeyeDeviceActions.SetFilterTemplatesResponse: {
      return {
        ...state,
        isFetchingFilterTemplates: false,
        filter: {
          ...state.filter,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.filter[state.typeOfTickets as TypeOfTickets],
            filterTemplates: payload,
          },
        },
      }
    }

    case TicketActions.CreateSdFilterTemplateResponse: {
      const updatedFilterTemplatesArray = [
        ...state.filter[state.typeOfTickets as TypeOfTickets].filterTemplates,
        payload,
      ]
      return {
        ...state,
        filter: {
          ...state.filter,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.filter[state.typeOfTickets as TypeOfTickets],
            filterTemplates: updatedFilterTemplatesArray,
          },
        },
      }
    }

    case TicketActions.UpdateSdFilterTemplateResponse: {
      const updatedTemplateArray = state.filter[
        state.typeOfTickets as TypeOfTickets
      ].filterTemplates.map((template: any) =>
        template.id === payload.id ? { ...template, name: payload.name } : template,
      )
      return {
        ...state,
        filter: {
          ...state.filter,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.filter[state.typeOfTickets as TypeOfTickets],
            filterTemplates: updatedTemplateArray,
          },
        },
      }
    }

    case TicketActions.DeleteSdFilterTemplateResponse: {
      const updatedTemplateArray = state.filter[
        state.typeOfTickets as TypeOfTickets
      ].filterTemplates.filter((template: any) => template.id !== payload.id)
      return {
        ...state,
        filter: {
          ...state.filter,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.filter[state.typeOfTickets as TypeOfTickets],
            filterTemplates: updatedTemplateArray,
          },
        },
      }
    }

    case TicketActions.GetGroupingTemplatesResponse:
      return {
        ...state,
        grouping: {
          ...state.grouping,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.grouping[state.typeOfTickets as TypeOfTickets],
            groupingsTemplates: payload,
          },
        },
        isFetchingGroupTemplates: false,
      }
    case TicketActions.SetGroupTemplates: {
      const { templates, withAppendNewTemplate } = payload
      const arrayWithNewAppendedTemplate = [
        ...(state.grouping[state.typeOfTickets as TypeOfTickets].groupingsTemplates
          ? state.grouping[state.typeOfTickets as TypeOfTickets].groupingsTemplates
          : []),
        ...templates,
      ]
      return {
        ...state,
        grouping: {
          ...state.grouping,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.grouping[state.typeOfTickets as TypeOfTickets],
            groupingsTemplates: withAppendNewTemplate ? arrayWithNewAppendedTemplate : templates,
          },
        },
      }
    }
    case TicketActions.EditSdGroupTemplateFromResponse: {
      const resultArray = state.grouping[
        state.typeOfTickets as TypeOfTickets
      ].groupingsTemplates.map((tem: GroupingTemplate) => (tem.id !== payload.id ? tem : payload))
      return {
        ...state,
        grouping: {
          ...state.grouping,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.grouping[state.typeOfTickets as TypeOfTickets],
            groupingsTemplates: resultArray,
          },
        },
      }
    }
    case TicketActions.DeleteSdGroupTemplateResponseSuccess: {
      const resultArray = state.grouping[
        state.typeOfTickets as TypeOfTickets
      ].groupingsTemplates.filter((tem: GroupingTemplate) => tem.id !== payload.id)

      return {
        ...state,
        grouping: {
          ...state.grouping,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.grouping[state.typeOfTickets as TypeOfTickets],
            groupingsTemplates: resultArray,
          },
        },
      }
    }
    case TicketActions.GetGroupingTemplatesResponseFail:
      return {
        ...state,
        error: payload,
        isFetchingGroupTemplates: false,
      }

    case TicketActions.SetCurrentFilterTemplate:
      return {
        ...state,
        filter: {
          ...state.filter,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.filter[state.typeOfTickets as TypeOfTickets],
            selectedTemplate: payload,
          },
        },
      }
    case TicketActions.GetTicketsConfigRequest:
      return { ...state, isFetchingTicketsConfig: true, error: null }
    case AtmeyeDeviceActions.GetDevicesConfigResponse:
      return {
        ...state,
        isFetchingTicketsConfig: false,
        config: {
          ...state.config,
          [payload.typeOfTickets]: payload.config,
        },
      }
    case TicketActions.GetTicketsConfigResponseFail:
      return { ...state, isFetchingTicketsConfig: false, error: payload }

    case AtmeyeDeviceActions.SetScrollMultiSelectConfigValues: {
      return {
        ...state,
        config: {
          ...state.config,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.config[state.typeOfTickets as TypeOfTickets],
            filter: state.config[state.typeOfTickets as TypeOfTickets].filter.map(el => {
              if (el.parameterName === payload.parameterName) {
                const newLookupValues = [
                  ...el.lookupInfo?.lookupValues,
                  ...payload?.values.filter(
                    (item: any) => !el.lookupInfo?.lookupValues.find(x => x.value === item.value),
                  ),
                ]
                const sortedLookupValues = sortAlphabeticallyStringArray({
                  array: newLookupValues,
                  sortProperty: 'display',
                })

                return {
                  ...el,
                  lookupInfo: {
                    ...el.lookupInfo,
                    lookupValues: sortedLookupValues,
                  },
                }
              } else {
                return el
              }
            }),
          },
        },
      }
    }

    case TicketActions.GetTicketDetailsRequest:
      return {
        ...state,
        isFetchingTicketDetails: true,
        error: null,
        ticketDetails: initialState.ticketDetails,
      }
    case TicketActions.GetTicketDetailsResponse:
      return { ...state, isFetchingTicketDetails: false, ticketDetails: payload }
    case TicketActions.GetTicketDetailsResponseFail:
      return {
        ...state,
        isFetchingTicketDetails: false,
        error: payload,
        ticketDetails: initialState.ticketDetails,
      }
    case TicketActions.ChangeSorting:
      return {
        ...state,
        sorting: { ...state.sorting, [state.typeOfTickets as TypeOfTickets]: payload },
      }
    case TicketActions.SetTypeOfTickets:
      return { ...state, typeOfTickets: payload }
    case TicketActions.SetRowPerPage:
      return {
        ...state,
        rowsPerPage: { ...state.rowsPerPage, [state.typeOfTickets as TypeOfTickets]: payload },
      }
    case TicketActions.SetPage:
      return { ...state, page: { ...state.page, [state.typeOfTickets as TypeOfTickets]: payload } }
    case TicketActions.ChangeGrouping:
      return {
        ...state,
        isFetchingTickets: true,
        grouping: {
          ...state.grouping,
          [state.typeOfTickets as TypeOfTickets]: {
            ...state.grouping[state.typeOfTickets as TypeOfTickets],
            selectedGroupings: payload,
          },
        },
      }
    case TicketActions.SetStringFilter:
      return {
        ...state,
        stringFitler: { ...state.stringFitler, [state.typeOfTickets as TypeOfTickets]: payload },
      }
    case TicketActions.ClearGroupedTicketDetailsData:
      return {
        ...state,
        page: initialState.page,
        groupDetails: initialState.groupDetails,
      }

    case AtmeyeDeviceActions.SetDataPagination: {
      return {
        ...state,
        data: {
          ...state.data,
          [state.typeOfTickets as TypeOfTickets]: {
            totalCount: payload.totalCount,
            details: {
              ...state.data[state.typeOfTickets].details,
              pageCount: payload.pageCount,
              outputPageNo: payload.outputPageNo,
            },
          },
        },
      }
    }

    case AtmeyeDeviceActions.SetSelectedGroupingTemplate: {
      return {
        ...state,
        selectedGroupingTemplate: payload,
      }
    }
    default:
      return state
  }
}
