import { isEqual } from 'lodash'
import { AnyAction } from 'redux'

import { TableBodyCell } from '@/components/controls/AppTable/types'
import { AdditionalCostsValues } from '@/components/pages/srm/TicketsPage/WorkCostTab/components/AdditionalCosts/types'
import { SearchServiceRequestActions, TicketActions } from '@/constants'
import {
  DelaysValues,
  FilterTemplate,
  GroupingTemplate,
  PredefinedFilters,
  SelectedFilters,
  TicketFields,
  TicketOverview,
} from '@/types'
import { SearchRequestItem } from '@/types/srm/SearchServiceRequest'
import {
  CustomerRequestItemsTable,
  CustomerRequestItemsTableField,
  ServiceItems,
  ServiceItemsTable,
} from '@/types/srm/tickets'

export interface State {
  ticketOverview: Partial<TicketOverview>
  filter: {
    predefinedFilters: PredefinedFilters
    selectedFilters: SelectedFilters
    filterTemplates: FilterTemplate[]
  }
  grouping: {
    groupingsTemplates: GroupingTemplate[]
  }
  searchServiceRequest: {
    serviceRequest: SearchRequestItem
    serviceWorks: SearchRequestItem
    serviceDevice: SearchRequestItem
    plannedWorks: SearchRequestItem
    configuration: SearchRequestItem
    deviceDefectsStatistics: SearchRequestItem
    serviceWorksStatistics: SearchRequestItem
  }
  isFetchingGroupTemplates: boolean
  isDeletingGroupingTemplate: boolean
  error: string | null
  workItemsAndServiceItems: {
    workItemsData: TableBodyCell[]
    serviceItemsData: TableBodyCell[]
    selectedWorkTableRow: {}
    selectedServiceTableRow: {}
  }

  newOrder: {
    isNewOrderTab: boolean
    workOrderStatuses: {
      isOpened: boolean
      isAccepted: boolean
      isTripStarted: boolean
      isCanceled: boolean
      isFinished: boolean
    }
  }

  ticketOverviewRequest: {
    customerRequestItems: CustomerRequestItemsTable[]
    serviceItems: ServiceItemsTable[]
  }

  analyze: {
    delaysTableData: DelaysValues[]
  }
  workCost: {
    additionalCosts: AdditionalCostsValues[]
  }
}

export const initialSelectedFilters = {
  [TicketFields.ReceiveDate]: {
    end: null,
    start: null,
  },
  [TicketFields.Status]: [],
  [TicketFields.CustomerRequestNumber]: '',
  [TicketFields.Merchant]: '',
  [TicketFields.ServiceType]: [],
  [TicketFields.DeviceType]: [],
  [TicketFields.LUNO]: '',
}

export const initialState: State = {
  ticketOverview: {},
  filter: {
    predefinedFilters: {
      availableSortingFields: [],
      deviceTypeItems: [],
      receiveDateItems: {
        end: null,
        start: null,
      },
      statusItems: [],
      serviceTypeItems: [],
    },
    selectedFilters: initialSelectedFilters,
    filterTemplates: [],
  },
  grouping: {
    groupingsTemplates: [],
  },
  searchServiceRequest: {
    serviceRequest: {
      data: [],
      closed: true,
    },
    serviceWorks: {
      data: [],
      closed: true,
    },
    serviceDevice: {
      data: [],
      closed: true,
    },
    plannedWorks: {
      data: [],
      closed: true,
    },
    configuration: {
      data: [],
      closed: true,
    },
    deviceDefectsStatistics: {
      data: [],
      closed: true,
    },
    serviceWorksStatistics: {
      data: [],
      closed: true,
    },
  },
  isFetchingGroupTemplates: false,
  isDeletingGroupingTemplate: false,
  error: null,
  workItemsAndServiceItems: {
    workItemsData: [],
    serviceItemsData: [],
    selectedWorkTableRow: {},
    selectedServiceTableRow: {},
  },

  newOrder: {
    isNewOrderTab: false,
    workOrderStatuses: {
      isOpened: false,
      isAccepted: false,
      isTripStarted: false,
      isCanceled: false,
      isFinished: false,
    },
  },

  ticketOverviewRequest: {
    customerRequestItems: [],
    serviceItems: [],
  },

  analyze: {
    delaysTableData: [],
  },
  workCost: {
    additionalCosts: [],
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case TicketActions.SetTicketOverviewData:
      return { ...state, ticketOverview: { ...state.ticketOverview, ...payload } }

    case TicketActions.SetIsNewOrderTab:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          isNewOrderTab: true,
        },
      }

    case TicketActions.SetWorkOrderStatus:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          workOrderStatuses: {
            ...state.newOrder.workOrderStatuses,
            ...payload,
          },
        },
      }

    case TicketActions.AddCustomerRequestItem:
      return {
        ...state,
        ticketOverviewRequest: {
          customerRequestItems: [...state.ticketOverviewRequest.customerRequestItems, payload],
          serviceItems: [
            ...state.ticketOverviewRequest.serviceItems,
            {
              ...payload,
              [ServiceItems.Closed]: 'No',
              [ServiceItems.SLADeadline]: '',
              [ServiceItems.CloseTime]: '',
              [ServiceItems.Reason]: '',
            },
          ],
        },
      }

    case TicketActions.AddCustomerServiceItem:
      return {
        ...state,
        ticketOverviewRequest: {
          ...state.ticketOverviewRequest,
          serviceItems: [...state.ticketOverviewRequest.serviceItems, payload],
        },
      }

    case TicketActions.DeleteCustomerServiceItem:
      return {
        ...state,
        ticketOverviewRequest: {
          ...state.ticketOverviewRequest,
          serviceItems: state.ticketOverviewRequest.serviceItems.filter(
            item => item.id !== payload.id,
          ),
        },
      }
    case TicketActions.DeleteCustomerRequestItem:
      return {
        ...state,
        ticketOverviewRequest: {
          customerRequestItems: state.ticketOverviewRequest.customerRequestItems.filter(
            item => item.id !== payload.id,
          ),
          serviceItems: state.ticketOverviewRequest.serviceItems.filter(
            item => item.id !== payload.id,
          ),
        },
      }

    case TicketActions.EditCustomerRequestItem:
      return {
        ...state,
        ticketOverviewRequest: {
          ...state.ticketOverviewRequest,
          customerRequestItems: state.ticketOverviewRequest.customerRequestItems.map(item => {
            if (item.id === payload.id) {
              return {
                ...item,
                [CustomerRequestItemsTableField.ProblemArea]: payload.problemArea,
              }
            }
            return item
          }),
          serviceItems: state.ticketOverviewRequest.serviceItems.map(item => {
            if (item.id === payload.id) {
              return {
                ...item,
                [ServiceItems.ProblemArea]: payload.problemArea,
              }
            }
            return item
          }),
        },
      }

    case TicketActions.EditCustomerServiceItem:
      return {
        ...state,
        ticketOverviewRequest: {
          ...state.ticketOverviewRequest,
          serviceItems: state.ticketOverviewRequest.serviceItems.map(item => {
            if (item.id === payload.id) {
              return {
                ...item,
                ...item,
                [ServiceItems.ProblemArea]: payload.problemArea,
              }
            }
            return item
          }),
        },
      }

    case TicketActions.SetCustomerServiceItemClosed:
      return {
        ...state,
        ticketOverviewRequest: {
          ...state.ticketOverviewRequest,
          serviceItems: state.ticketOverviewRequest.serviceItems.map(serviceItem => {
            if (serviceItem.id === payload.id) {
              return {
                ...serviceItem,
                [ServiceItems.Closed]: 'Yes',
              }
            }
            return serviceItem
          }),
        },
      }

    case TicketActions.GetGroupingTemplatesResponse:
      return {
        ...state,
        grouping: {
          ...state.grouping,
          groupingsTemplates: payload,
        },
        isFetchingGroupTemplates: false,
      }
    case TicketActions.GetGroupingTemplatesResponseFail:
      return {
        ...state,
        error: payload,
        isFetchingGroupTemplates: false,
      }
    case TicketActions.ChangeSelectedFilters:
      return {
        ...state,
        filter: {
          ...state.filter,
          selectedFilters: payload,
        },
      }
    case TicketActions.GetGroupingTemplatesRequest:
      return {
        ...state,
        isFetchingGroupTemplates: true,
      }
    case TicketActions.DeleteGroupingTemplateRequest:
      return {
        ...state,
        isDeletingGroupingTemplate: true,
      }
    case SearchServiceRequestActions.SetSearchServiceRequestsData:
      return {
        ...state,
        searchServiceRequest: {
          ...state.searchServiceRequest,
          serviceRequest: { ...state.searchServiceRequest.serviceRequest, data: payload },
        },
      }
    case SearchServiceRequestActions.SetSearchServiceRequestClosed:
      return {
        ...state,
        searchServiceRequest: {
          ...state.searchServiceRequest,
          serviceRequest: { ...state.searchServiceRequest.serviceRequest, closed: payload },
        },
      }
    case SearchServiceRequestActions.SetSearchServiceWorksData:
      return {
        ...state,
        searchServiceRequest: {
          ...state.searchServiceRequest,
          serviceWorks: { ...state.searchServiceRequest.serviceWorks, data: payload },
        },
      }
    case SearchServiceRequestActions.SetSearchServiceWorksClosed:
      return {
        ...state,
        searchServiceRequest: {
          ...state.searchServiceRequest,
          serviceWorks: { ...state.searchServiceRequest.serviceWorks, closed: payload },
        },
      }
    case SearchServiceRequestActions.SetSearchServiceDeviceData:
      return {
        ...state,
        searchServiceRequest: {
          ...state.searchServiceRequest,
          serviceDevice: { ...state.searchServiceRequest.serviceDevice, data: payload },
        },
      }
    case SearchServiceRequestActions.SetSearchServiceDeviceClosed:
      return {
        ...state,
        searchServiceRequest: {
          ...state.searchServiceRequest,
          serviceDevice: { ...state.searchServiceRequest.serviceDevice, closed: payload },
        },
      }
    case SearchServiceRequestActions.SetSearchPlannedWorksData:
      return {
        ...state,
        searchServiceRequest: {
          ...state.searchServiceRequest,
          plannedWorks: { ...state.searchServiceRequest.plannedWorks, data: payload },
        },
      }
    case SearchServiceRequestActions.SetSearchPlannedWorksClosed:
      return {
        ...state,
        searchServiceRequest: {
          ...state.searchServiceRequest,
          plannedWorks: { ...state.searchServiceRequest.plannedWorks, closed: payload },
        },
      }
    case SearchServiceRequestActions.SetSearchConfigurationData:
      return {
        ...state,
        searchServiceRequest: {
          ...state.searchServiceRequest,
          configuration: { ...state.searchServiceRequest.configuration, data: payload },
        },
      }
    case SearchServiceRequestActions.SetSearchConfigurationClosed:
      return {
        ...state,
        searchServiceRequest: {
          ...state.searchServiceRequest,
          configuration: { ...state.searchServiceRequest.configuration, closed: payload },
        },
      }
    case SearchServiceRequestActions.SetDeviceDefectsStatisticsData:
      return {
        ...state,
        searchServiceRequest: {
          ...state.searchServiceRequest,
          deviceDefectsStatistics: {
            ...state.searchServiceRequest.deviceDefectsStatistics,
            data: payload,
          },
        },
      }
    case SearchServiceRequestActions.SetDeviceDefectsStatisticsClosed:
      return {
        ...state,
        searchServiceRequest: {
          ...state.searchServiceRequest,
          deviceDefectsStatistics: {
            ...state.searchServiceRequest.deviceDefectsStatistics,
            closed: payload,
          },
        },
      }
    case SearchServiceRequestActions.SetServiceWorksStatisticsData:
      return {
        ...state,
        searchServiceRequest: {
          ...state.searchServiceRequest,
          serviceWorksStatistics: {
            ...state.searchServiceRequest.serviceWorksStatistics,
            data: payload,
          },
        },
      }
    case SearchServiceRequestActions.SetServiceWorksStatisticsClosed:
      return {
        ...state,
        searchServiceRequest: {
          ...state.searchServiceRequest,
          serviceWorksStatistics: {
            ...state.searchServiceRequest.serviceWorksStatistics,
            closed: payload,
          },
        },
      }
    case TicketActions.GetWorkItemsTableDataResponse:
      return {
        ...state,
        workItemsAndServiceItems: {
          ...state.workItemsAndServiceItems,
          workItemsData: payload,
        },
      }
    case TicketActions.GetWorkItemsTableDataResponseFail:
      return {
        ...state,
        error: payload,
      }
    case TicketActions.GetServiceItemsTableDataResponse:
      return {
        ...state,
        workItemsAndServiceItems: {
          ...state.workItemsAndServiceItems,
          serviceItemsData: payload,
        },
      }
    case TicketActions.GetServiceItemsTableDataResponseFail:
      return {
        ...state,
        error: payload,
      }
    case TicketActions.SetSelectedWorkTableRow:
      return {
        ...state,
        workItemsAndServiceItems: {
          ...state.workItemsAndServiceItems,
          selectedWorkTableRow: payload,
        },
      }
    case TicketActions.SetSelectedServiceTableRow:
      return {
        ...state,
        workItemsAndServiceItems: {
          ...state.workItemsAndServiceItems,
          selectedServiceTableRow: payload,
        },
      }
    case TicketActions.EditServiceItemsTableRow:
      return {
        ...state,
        workItemsAndServiceItems: {
          ...state.workItemsAndServiceItems,
          serviceItemsData: state.workItemsAndServiceItems.serviceItemsData.map(item =>
            isEqual(item, state.workItemsAndServiceItems.selectedServiceTableRow) ? payload : item,
          ),
        },
      }
    case TicketActions.DeleteWorkItemsTableRow:
      return {
        ...state,
        workItemsAndServiceItems: {
          ...state.workItemsAndServiceItems,
          workItemsData: state.workItemsAndServiceItems.workItemsData.filter(item => {
            return !isEqual(item, payload)
          }),
        },
      }
    case TicketActions.DeleteServiceItemsTableRow:
      return {
        ...state,
        workItemsAndServiceItems: {
          ...state.workItemsAndServiceItems,
          serviceItemsData: state.workItemsAndServiceItems.serviceItemsData.filter(item => {
            return !isEqual(item, payload)
          }),
        },
      }
    case TicketActions.AddWorkItemsTableRow:
      return {
        ...state,
        workItemsAndServiceItems: {
          ...state.workItemsAndServiceItems,
          workItemsData: [...state.workItemsAndServiceItems.workItemsData, payload],
        },
      }
    case TicketActions.AddServiceItemsTableRow:
      return {
        ...state,
        workItemsAndServiceItems: {
          ...state.workItemsAndServiceItems,
          serviceItemsData: [...state.workItemsAndServiceItems.serviceItemsData, payload],
        },
      }

    case TicketActions.DeleteAnalyzeDelays:
      return {
        ...state,
        analyze: {
          delaysTableData: state.analyze.delaysTableData.filter(
            item => !payload.some((payloadItem: DelaysValues) => item.id === payloadItem.id),
          ),
        },
      }
    case TicketActions.AddAnalyzeDelays:
      return {
        ...state,
        analyze: {
          delaysTableData: [...state.analyze.delaysTableData, payload],
        },
      }

    case TicketActions.EditAnalyzeDelays:
      return {
        ...state,
        analyze: {
          delaysTableData: state.analyze.delaysTableData.map(item =>
            item.id === payload.id ? payload : item,
          ),
        },
      }

    case TicketActions.AddAdditionalCostsWorkCost:
      return {
        ...state,
        workCost: {
          additionalCosts: [...state.workCost.additionalCosts, payload],
        },
      }
    case TicketActions.DeleteAdditionalCostsWorkCost:
      return {
        ...state,
        workCost: {
          additionalCosts: state.workCost.additionalCosts.filter(
            (additionalCost: AdditionalCostsValues) => additionalCost.id !== payload.id,
          ),
        },
      }
    default:
      return state
  }
}
