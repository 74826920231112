import 'moment/min/locales.min'

import moment from 'moment'
import { createSelector } from 'reselect'

import {
  UserInformationColumns,
  UserInformationTableTypes,
} from '@/components/pages/usm/UsersPage/components/UserInformation/types'
import { AppState } from '@/store/reducers'

import * as fromUsers from './reducer'
import { initialState } from './reducer'

export const getUsersState = (state: AppState): fromUsers.State => state.usmUsers || initialState

export const getPersonalInformation = createSelector(
  getUsersState,
  state => state.personalCard.personalInfo,
)

export const getLoginInformation = createSelector(
  getUsersState,
  state => state.personalCard.loginInfo,
)

export const getFetchingPersonalInformation = createSelector(
  getUsersState,
  state => state.isFetchingPersonalCard,
)

export const getUserInformation = createSelector(getUsersState, state =>
  state?.personalCard?.userInfo?.map(
    (el): UserInformationTableTypes => ({
      [UserInformationColumns.Id]: String(el.userId),
      [UserInformationColumns.Company]: el.companyName,
      [UserInformationColumns.CompanyId]: String(el.companyId),
      [UserInformationColumns.UserName]: el.userName,
      [UserInformationColumns.UserGroup]: el.userGroupName,
      [UserInformationColumns.UserGroupId]: String(el.userGroupId),
      [UserInformationColumns.Email]: el.emailAddress,
      [UserInformationColumns.ExpireDate]: !el.accountExpirationDate
        ? ''
        : moment.utc(el.accountExpirationDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      [UserInformationColumns.Disabled]: el.accountDisabled,
      [UserInformationColumns.CompanyPersonnelId]: el.companyPersonnelId,
      [UserInformationColumns.AllowAutoProlong]: el.allowAutoProlong,
      [UserInformationColumns.RoleId]: el.roleId ? String(el.roleId) : '',
      [UserInformationColumns.RoleName]: el.roleName ? String(el.roleName) : '',
    }),
  ),
)

export const getFetchingUserInformationTable = createSelector(
  getUsersState,
  state => state.isFetchingAdduserInfo,
)

export const getIsFetchingAddNewUserModal = createSelector(
  getUsersState,
  state => state.isFetchingAddNewUser,
)

export const getCreateUserLookupConfig = createSelector(getUsersState, state => state.lookupConfig)

export const getErrorUsersUsm = createSelector(getUsersState, state => state.error)
