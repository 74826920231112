import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles<Theme>(({ spacing }) => ({
  tableTitle: {
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
    fontSize: '20px',
    marginBottom: '5px',
  },

  noResults: {
    fontStyle: 'italic',
  },

  loader: {
    height: spacing(60),
  },
}))
