import React from 'react'

import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import { Props } from './types'

const NoPageAccess = ({ message, children }: Props): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  return (
    <div className={classes.noPermissions}>
      <p>{translate(message ? `translate#${message}` : `translate#title.noPermissions`)}</p>
      {children}
    </div>
  )
}

export default NoPageAccess
