import { Checkbox } from '@material-ui/core'
import clsx from 'clsx'
import React, { FC, useMemo } from 'react'

import { HeaderCell, useCommonClasses } from '../../../commonStyles'
import { useClasses } from './styles'
import { Props } from './types'

export const SelectAllCell: FC<Props> = React.memo((props: Props) => {
  const {
    isFixed,
    isFixedLast,
    borderCellClasses,
    tableClasses,
    checkedLength,
    dataWithCheckboxLength,
    onSelectAll,
  } = props

  const classes = useClasses()
  const commonClasses = useCommonClasses()

  const cellClasses = useMemo(
    () =>
      clsx(
        classes.checkAll,
        borderCellClasses,
        {
          [commonClasses.fixed]: isFixed,
          [commonClasses.fixedLast]: isFixedLast,
          [commonClasses.fixedHeader]: isFixed,
        },
        tableClasses?.fixed,
        tableClasses?.tableHeadCell,
      ),
    [isFixed, isFixedLast, borderCellClasses],
  )

  const indeterminate = useMemo(() => checkedLength > 0 && checkedLength < dataWithCheckboxLength, [
    checkedLength,
    dataWithCheckboxLength,
  ])
  const checked = useMemo(() => !!checkedLength && checkedLength === dataWithCheckboxLength, [
    checkedLength,
    dataWithCheckboxLength,
  ])

  return (
    <HeaderCell className={cellClasses}>
      <Checkbox
        indeterminate={indeterminate}
        checked={checked}
        onChange={onSelectAll}
        className={commonClasses.tableCheckbox}
      />
    </HeaderCell>
  )
})
