import { ActionsDashboard } from '@/constants/atmeye/actions/actionsDashboard'
import {
  AddNewSubPageTypes,
  AddNewSubscriptionPageTypes,
  AllSubpagesResponseType,
  CurrentSubPagesTypes,
  DevicesCountWidget,
  EditDeviceWidget,
  EditEventsWidget,
  EditSubpageWidget,
  EventsCountWidget,
  ItemsWidgetTypes,
  ResponseWidgetDataInfo,
  SubPagesWidgetTypes,
  UpdatePage,
  ViewData,
  WidgetHeaderTypesResponse,
} from '@/types/atmeye/dashboardTypes'
import { ResponseErrorTypes } from '@/types/common/ErrorResponseTypes'
import { ActionFormTypes } from '@/types/usm/commoTypes/actionFormTypes'

export const actionsATMEyeDashboard = {
  startFetchingWidgetHeaderData: () =>
    ({
      type: ActionsDashboard.StartFetchingHeaderWidgetData,
    } as const),

  stopFetchingWidgetHeaderData: () =>
    ({
      type: ActionsDashboard.StopFetchingHeaderWidgetData,
    } as const),
  fillWidgetHeaderDataSuccess: (payload: WidgetHeaderTypesResponse) =>
    ({
      type: ActionsDashboard.FillHeaderWidgetDataSuccess,
      payload,
    } as const),

  fillWidgetHeaderDataError: (payload: ResponseErrorTypes) =>
    ({
      type: ActionsDashboard.FillHeaderWidgetDataError,
      payload,
    } as const),

  getWidgetHeaderDataSync: () =>
    ({
      type: ActionsDashboard.GetHeaderWidgetDataAsync,
    } as const),

  startFetchingAllSubPages: () =>
    ({
      type: ActionsDashboard.StartFetchingAllSubpages,
    } as const),

  stopFetchingAllSubPages: () =>
    ({
      type: ActionsDashboard.StopFetchingAllSubpages,
    } as const),

  fillAllSubPagesSuccess: (payload: Array<AllSubpagesResponseType>) =>
    ({
      type: ActionsDashboard.FillAllSubPagesSuccess,
      payload,
    } as const),

  fillAllSubPagesError: (payload: ResponseErrorTypes) =>
    ({
      type: ActionsDashboard.FillAllSubPagesError,
      payload,
    } as const),

  allowCreationOfNewPages: () =>
    ({
      type: ActionsDashboard.AllowCreationOfNewPages,
    } as const),

  getAllSubPagesAsync: () =>
    ({
      type: ActionsDashboard.GetAllSubpagesAsync,
    } as const),

  fillAddNewSubPagesSuccess: (payload: AllSubpagesResponseType) =>
    ({
      type: ActionsDashboard.FillAddNewSubPageSuccess,
      payload,
    } as const),

  getAddNewSubPagesAsync: <T>(
    payload: AddNewSubPageTypes | AddNewSubscriptionPageTypes,
    actions: ActionFormTypes<T>,
  ) =>
    ({
      type: ActionsDashboard.GetAddNewSubpagesAsync,
      payload,
      actions,
    } as const),

  fillDeleteSubPagesSuccess: (payload: number) =>
    ({
      type: ActionsDashboard.FillDeleteSubPageSuccess,
      payload,
    } as const),

  getDeleteSubPagesAsync: (payload: number) =>
    ({
      type: ActionsDashboard.GetDeleteSubpagesAsync,
      payload,
    } as const),

  fillUpdateSubPagesSuccess: (payload: AllSubpagesResponseType) =>
    ({
      type: ActionsDashboard.FillEditSubPageSuccess,
      payload,
    } as const),

  fillUpdateMySubPageSuccess: (payload: AllSubpagesResponseType) =>
    ({
      type: ActionsDashboard.FillEditMySubpageSuccess,
      payload,
    } as const),

  resetWidgetConfig: () =>
    ({
      type: ActionsDashboard.resetWidgetConfig,
    } as const),

  getUpdateSubPagesAsync: <T>(
    payload: AddNewSubPageTypes & UpdatePage,
    actions: ActionFormTypes<T>,
  ) =>
    ({
      type: ActionsDashboard.GetEditSubpagesAsync,
      payload,
      actions,
    } as const),

  getUpdateMySubPagesAsync: <T>(
    payload: AddNewSubPageTypes & UpdatePage,
    actions: ActionFormTypes<T>,
  ) =>
    ({
      type: ActionsDashboard.GetEditMySubpageAsync,
      payload,
      actions,
    } as const),

  getCreateSubPageCloneAsync: (payload: string, actions: ActionFormTypes<number>) =>
    ({
      type: ActionsDashboard.GetCreateSubPageCloneAsync,
      payload,
      actions,
    } as const),

  fillCreateSubPagesCloneSuccess: (payload: AllSubpagesResponseType) =>
    ({
      type: ActionsDashboard.FillSubPageCloneSuccess,
      payload,
    } as const),

  clearClonePageName: () =>
    ({
      type: ActionsDashboard.CloseClonedPageModal,
    } as const),

  startFetchingFullSubpagesData: () =>
    ({
      type: ActionsDashboard.StartFetchingFullSubPagesData,
    } as const),

  stopFetchingFullSubpagesData: () =>
    ({
      type: ActionsDashboard.StopFetchingFullSubPagesData,
    } as const),

  startFetchingPageWidgets: () =>
    ({
      type: ActionsDashboard.startFetchingPageWidgets,
    } as const),

  stopFetchingPageWidgets: () =>
    ({
      type: ActionsDashboard.stopFetchingPageWidgets,
    } as const),

  startFetchingViewData: (payload: number[]) =>
    ({
      type: ActionsDashboard.StartFetchingViewFilterData,
      payload,
    } as const),

  stopFetchingViewData: () =>
    ({
      type: ActionsDashboard.StopFetchingViewFilterData,
    } as const),

  fillFullSubPagesDataSuccess: (payload: CurrentSubPagesTypes) =>
    ({
      type: ActionsDashboard.FillFullSubPagesDataSuccess,
      payload,
    } as const),

  fillFullSubPagesDataError: (payload: ResponseErrorTypes) =>
    ({
      type: ActionsDashboard.FillFullSubPagesDataError,
      payload,
    } as const),

  getFullSubPagesDataAsync: (payload: string) =>
    ({
      type: ActionsDashboard.GetFullSubPagesDataAsync,
      payload,
    } as const),

  clearSubPageErrors: () =>
    ({
      type: ActionsDashboard.ClearSubPageErrors,
    } as const),

  fillFullSubPagesWidgetSuccess: (payload: SubPagesWidgetTypes) =>
    ({
      type: ActionsDashboard.FillFullSubPagesWidgetsSuccess,
      payload,
    } as const),

  getSubPageWidgetsDataAsync: (payload: { subpageId: string; isWithLoader: boolean }) =>
    ({
      type: ActionsDashboard.GetCurrentSubPageDataAsync,
      payload,
    } as const),

  getWidgetFilterDataAsync: (payload: {
    widgetId: string
    widgetType: 'EVENTS_COUNT' | 'DEVICES_COUNT'
  }) =>
    ({
      type: ActionsDashboard.GetWidgetFilterDataAsync,
      payload,
    } as const),

  startFetchingWidgetFilterData: () =>
    ({
      type: ActionsDashboard.StartFetchingWidgetFilterData,
    } as const),

  stopFetchingWidgetFilterData: () =>
    ({
      type: ActionsDashboard.StopFetchingWidgetFilterData,
    } as const),

  fillAllSuccessWidgetFilterData: (payload: ResponseWidgetDataInfo) =>
    ({
      type: ActionsDashboard.FillAllSuccessWidgetFilterData,
      payload,
    } as const),

  getUnSubscribeSubPageAsync: (payload: number) =>
    ({
      type: ActionsDashboard.GetUnSubScribeSubPageAsync,
      payload,
    } as const),

  deleteDashboardWidget: (payload: number) =>
    ({
      type: ActionsDashboard.FillAllSuccessDeleteDashboardWidget,
      payload,
    } as const),
  startFetchingDeleteDashboardWidget: (payload: number) =>
    ({
      type: ActionsDashboard.StartFetchingDeleteDashboardWidget,
      payload,
    } as const),
  stopFetchingDeleteDashboardWidget: (payload: number) =>
    ({
      type: ActionsDashboard.StopFetchingDeleteDashboardWidget,
      payload,
    } as const),

  getDeleteDashboardWidget: ({ payload }: { payload: number }) =>
    ({
      type: ActionsDashboard.GetDeleteDashboardWidget,
      payload,
    } as const),

  clearCurrentFilterData: () =>
    ({
      type: ActionsDashboard.ClearStateFilterDataWidget,
    } as const),

  getDevicesCountWidget: (payload: DevicesCountWidget) =>
    ({
      type: ActionsDashboard.getDevicesCountWidget,
      payload,
    } as const),

  getEventsCountWidget: (payload: EventsCountWidget) =>
    ({
      type: ActionsDashboard.getEventsCountWidget,
      payload,
    } as const),

  fillAllSuccessEditFilterData: (payload: EditSubpageWidget) =>
    ({
      type: ActionsDashboard.FillAllSuccessEditFilterData,
      payload,
    } as const),

  fillAllSuccessViewData: (payload: ViewData) =>
    ({
      type: ActionsDashboard.FillAllSuccessViewData,
      payload,
    } as const),

  fillAllSuccessCreateFilterData: (payload: ItemsWidgetTypes) =>
    ({
      type: ActionsDashboard.FillAllSuccessCreateFilterData,
      payload,
    } as const),

  editDevicesCountWidget: (payload: EditDeviceWidget) =>
    ({
      type: ActionsDashboard.editDevicesCountWidget,
      payload,
    } as const),

  editEventsCountWidget: (payload: EditEventsWidget) =>
    ({
      type: ActionsDashboard.editEventsCountWidget,
      payload,
    } as const),
}
