import { AnyAction } from 'redux'

import { AddAttachedFile, AttachedFile } from '@/api/common/commonApi/types'
import { ProductsActions } from '@/constants/actions/products'
import { ResponseError } from '@/types'
import {
  AddUpdateProductSpecification,
  AlternativeProduct,
  Product,
  Products,
  ProductsConfig,
  ProductSpecification,
} from '@/types/products'

export const getConfigRequest = (): AnyAction => ({
  type: ProductsActions.GetConfigRequest,
})
export const getConfigResponse = (config: { formConfig: ProductsConfig }): AnyAction => ({
  type: ProductsActions.GetConfigResponse,
  payload: config.formConfig,
})
export const getConfigResposeFail = (error: ResponseError): AnyAction => ({
  type: ProductsActions.GetConfigResponseFail,
  payload: error,
})

export const getProductsRequest = (): AnyAction => ({
  type: ProductsActions.GetProductsRequest,
})
export const getProductsResponse = (data: Products): AnyAction => ({
  type: ProductsActions.GetProductsResponse,
  payload: data,
})
export const getProductsResponseFail = (error: ResponseError): AnyAction => ({
  type: ProductsActions.GetProductsResponseFail,
  payload: { error },
})

export const getProductByIdRequest = (productId: string): AnyAction => ({
  type: ProductsActions.GetProductByIdRequest,
  payload: productId,
})
export const getProductByIdResponse = (data: Product): AnyAction => ({
  type: ProductsActions.GetProductByIdResponse,
  payload: data,
})
export const getProductByIdResponseFail = (error: ResponseError): AnyAction => ({
  type: ProductsActions.GetProductByIdResponseFail,
  payload: { error },
})

export const addNewProductRequest = (product: Product): AnyAction => ({
  type: ProductsActions.AddNewProductRequest,
  payload: product,
})
export const addNewProductResponse = (product: Product): AnyAction => ({
  type: ProductsActions.AddNewProductResponse,
  payload: product,
})
export const addNewProductResponseFail = (error: ResponseError): AnyAction => ({
  type: ProductsActions.AddNewProductResponseFail,
  payload: error,
})

export const editProductRequest = (productId: string, data: Product): AnyAction => ({
  type: ProductsActions.EditProductRequest,
  payload: { productId, data },
})
export const editProductResponse = (data: Product): AnyAction => ({
  type: ProductsActions.EditProductResponse,
  payload: data,
})
export const editProductResponseFail = (error: ResponseError): AnyAction => ({
  type: ProductsActions.EditProductResponseFail,
  payload: error,
})

export const deleteProductRequest = (productId: string): AnyAction => ({
  type: ProductsActions.DeleteProductRequest,
  payload: { productId },
})
export const deleteProductResponse = (): AnyAction => ({
  type: ProductsActions.DeleteProductResponse,
})
export const deleteProductResponseFail = (error: ResponseError): AnyAction => ({
  type: ProductsActions.DeleteProductResponseFail,
  payload: { error },
})

export const getAlternativeProductsRequest = (mainProductId: number): AnyAction => ({
  type: ProductsActions.GetAlternativeProductsRequest,
  payload: { mainProductId },
})

export const getAlternativeProductsResponse = (alternativeProducts: any): AnyAction => ({
  type: ProductsActions.GetAlternativeProductsResponse,
  payload: alternativeProducts,
})

export const getAlternativeProductsResponseFail = (error: ResponseError): AnyAction => ({
  type: ProductsActions.GetAlternativeProductsResponseFail,
  payload: { error },
})

export const getAllowedToAddAlternativeProductsRequest = (
  excludeProductIds: number[],
  searchFragment: string,
): AnyAction => ({
  type: ProductsActions.GetAllowedToAddAlternativeProductsRequest,
  payload: { excludeProductIds, searchFragment },
})

export const getAllowedToAddAlternativeProductsResponse = (
  alternativeProducts: any,
): AnyAction => ({
  type: ProductsActions.GetAllowedToAddAlternativeProductsResponse,
  payload: alternativeProducts,
})

export const getAllowedToAddAlternativeProductsResponseFail = (
  error: ResponseError,
): AnyAction => ({
  type: ProductsActions.GetAllowedToAddAlternativeProductsResponseFail,
  payload: { error },
})

export const saveAlternativeProductsRequest = (
  mainProductId: number,
  addProducts: number[],
  deleteProducts: number[],
): AnyAction => ({
  type: ProductsActions.SaveAlternativeProductsRequest,
  payload: { mainProductId, addProducts, deleteProducts },
})
export const saveAlternativeProductsResponse = (
  addedProducts: AlternativeProduct[],
  deletedProducts: number[],
): AnyAction => ({
  type: ProductsActions.SaveAlternativeProductsResponse,
  payload: { addedProducts, deletedProducts },
})
export const saveAlternativeProductsResponseFail = (error: ResponseError): AnyAction => ({
  type: ProductsActions.SaveAlternativeProductsResponseFail,
  payload: { error },
})

export const getProductSpecificationRequest = (mainProductId: number): AnyAction => ({
  type: ProductsActions.GetProductSpecificationRequest,
  payload: { mainProductId },
})

export const getProductSpecificationResponse = (productSpecifications: any): AnyAction => ({
  type: ProductsActions.GetProductSpecificationResponse,
  payload: productSpecifications,
})

export const getProductSpecificationResponseFail = (error: ResponseError): AnyAction => ({
  type: ProductsActions.GetProductSpecificationResponseFail,
  payload: { error },
})

export const getAllowedToAddProductSpecificationRequest = (
  excludeSpecificationIds: number[],
  searchFragment: string,
): AnyAction => ({
  type: ProductsActions.GetAllowedToAddProductSpecificationRequest,
  payload: { excludeSpecificationIds, searchFragment },
})

export const getAllowedToAddProductSpecificationResponse = (
  productSpecifications: any,
): AnyAction => ({
  type: ProductsActions.GetAllowedToAddProductSpecificationResponse,
  payload: productSpecifications,
})

export const getAllowedToAddProductSpecificationResponseFail = (
  error: ResponseError,
): AnyAction => ({
  type: ProductsActions.GetAllowedToAddProductSpecificationResponseFail,
  payload: { error },
})

export const saveProductSpecificationRequest = (
  mainProductId: number,
  addProductSpecifications: AddUpdateProductSpecification[], // AddUpdateProductSpecification
  deleteProductSpecifications: number[],
): AnyAction => ({
  type: ProductsActions.SaveProductSpecificationRequest,
  payload: { mainProductId, addProductSpecifications, deleteProductSpecifications },
})
export const saveProductSpecificationResponse = (
  addedProductSpecifications: ProductSpecification[],
  deletedProductSpecifications: number[],
): AnyAction => ({
  type: ProductsActions.SaveProductSpecificationResponse,
  payload: { addedProductSpecifications, deletedProductSpecifications },
})
export const saveProductSpecificationResponseFail = (error: ResponseError): AnyAction => ({
  type: ProductsActions.SaveProductSpecificationResponseFail,
  payload: { error },
})

export const getProductAttachedFilesRequest = (productId: number): AnyAction => ({
  type: ProductsActions.GetProductAttachedFilesRequest,
  payload: { productId },
})

export const getProductAttachedFilesResponse = (attachedFiles: any): AnyAction => ({
  type: ProductsActions.GetProductAttachedFilesResponse,
  payload: attachedFiles,
})

export const getProductAttachedFilesResponseFail = (error: ResponseError): AnyAction => ({
  type: ProductsActions.GetProductAttachedFilesResponseFail,
  payload: { error },
})

export const addProductAttachedFileRequest = (file: AddAttachedFile): AnyAction => {
  return {
    type: ProductsActions.AddProductAttachedFileRequest,
    payload: file,
  }
}
export const addProductAttachedFileResponse = (file: AttachedFile): AnyAction => ({
  type: ProductsActions.AddProductAttachedFileResponse,
  payload: file,
})

export const removeProductAttachedFileRequest = (fileId: number): AnyAction => ({
  type: ProductsActions.RemoveProductAttachedFileRequest,
  payload: fileId,
})
export const removeProductAttachedFileResponse = (fileId: number): AnyAction => ({
  type: ProductsActions.RemoveProductAttachedFileResponse,
  payload: fileId,
})

export const setClearErrors = () => ({
  type: ProductsActions.SetClearErrors,
})
