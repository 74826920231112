import { takeLatest } from 'redux-saga/effects'
import { makeRequestWithSpinner, OptionsType } from '@/store/usm/sagaWorkers/saga'
import { ReturnAction } from '@/types/usm/userGroup/userGroup'
import { SecurityNetworksObjectMergeUSMAPI } from '@/api/usm/securityNetworksMerge'
import { actionSecurityNetworksMergeUSM } from '@/store/usm/securityNetworksObjectMerge/actions'
import {
  GetSummaryObjectsRequestTypes,
  RequestMergeUpdateSecurityObjects,
  SecurityNetworksObjectMergeTypes,
  SecurityNetworksObjectsSummary,
} from '@/types/usm/securityObjectsMerge/securityObjectsMerge'
import { SecurityNetworksObjectsMergeValues } from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/MergeSecurityNetworksModal/types'

function* getSecurityNetworksObjectsForUser({
  payload,
  actions,
}: ReturnAction<Array<SecurityNetworksObjectMergeTypes>, never>): Generator {
  const fetcher = new SecurityNetworksObjectMergeUSMAPI<Array<SecurityNetworksObjectMergeTypes>>(
    payload,
  )
  const options: OptionsType<Array<SecurityNetworksObjectMergeTypes>, never> = {
    fetcher: fetcher.getAllSecurityNetworksObjectsMerge.bind(fetcher),
    startFetching: actionSecurityNetworksMergeUSM.startFetchingSecurityObjectsForUsers,
    stopFetching: actionSecurityNetworksMergeUSM.stopFetchingSecurityObjectsForUsers,
    fill: actionSecurityNetworksMergeUSM.fillSecurityObjectsForUserResponse,
    setErrorAction: actionSecurityNetworksMergeUSM.setSecurityObjectsForUserResponseFall,
    fetcherParam: payload,
    formActions: actions,
  }

  yield makeRequestWithSpinner<Array<SecurityNetworksObjectMergeTypes>, never>(options)
}

function* getSecurityNetworksObjectsSummary({
  payload,
  actions,
}: ReturnAction<SecurityNetworksObjectsMergeValues, GetSummaryObjectsRequestTypes>): Generator {
  const fetcher = new SecurityNetworksObjectMergeUSMAPI<GetSummaryObjectsRequestTypes>(payload)
  const options: OptionsType<
    Array<SecurityNetworksObjectsSummary>,
    GetSummaryObjectsRequestTypes
  > = {
    fetcher: fetcher.getSummaryObjectsMerge.bind(fetcher),
    startFetching: actionSecurityNetworksMergeUSM.startFetchingSummaryObjects,
    stopFetching: actionSecurityNetworksMergeUSM.stopetchingSummaryObjects,
    fill: actionSecurityNetworksMergeUSM.fillSecurityObjectsSummaryResponse,
    setErrorAction: actionSecurityNetworksMergeUSM.setSecurityObjectsForUserResponseFall,
    fetcherParam: payload,
    formActions: actions,
  }

  yield makeRequestWithSpinner<
    Array<SecurityNetworksObjectsSummary>,
    GetSummaryObjectsRequestTypes
  >(options)
}

function* updateSecurityNetworksObjectsSummaryMerge({
  payload,
  actions,
}: ReturnAction<null, RequestMergeUpdateSecurityObjects>): Generator {
  const fetcher = new SecurityNetworksObjectMergeUSMAPI<RequestMergeUpdateSecurityObjects>(payload)
  const options: OptionsType<void, RequestMergeUpdateSecurityObjects> = {
    fetcher: fetcher.updateSummaryObjectsMerge.bind(fetcher),
    startFetching: actionSecurityNetworksMergeUSM.startFetchingUpdateSummaryObjects,
    stopFetching: actionSecurityNetworksMergeUSM.stopFetchingUpdateSummaryObjects,
    fill: actionSecurityNetworksMergeUSM.fillUpdateSecurityObjectsSummaryResponse,
    setErrorAction: actionSecurityNetworksMergeUSM.setSecurityObjectsForUserResponseFall,
    fetcherParam: payload,
    formActions: actions,
  }

  yield makeRequestWithSpinner<void, RequestMergeUpdateSecurityObjects>(options)
}

export default function*(): Generator {
  yield takeLatest('@/GET_SECURITY_OBJECTS_FOR_USER_ASYNC_USM', getSecurityNetworksObjectsForUser)
  yield takeLatest(
    '@/GET_SECURITY_OBJECTS_SUMMARY_FOR_USER_ASYNC_USM',
    getSecurityNetworksObjectsSummary,
  )
  yield takeLatest(
    '@/UPDATE_SECURITY_OBJECTS_SUMMARY_TO_USER_ASYNC_USM',
    updateSecurityNetworksObjectsSummaryMerge,
  )
}
