import React, { FC } from 'react'
import { Dialog, Paper, CircularProgress, Typography } from '@material-ui/core'
import { useTranslate } from '@/utils/internalization'
import { useClasses } from './styles'
import { Props } from './types'

export const LoadingModal: FC<Props> = React.memo((props: Props) => {
  const { placeholder, styles } = props

  const classes = useClasses()
  const translate = useTranslate()

  return (
    <Dialog maxWidth="xs" open disableEscapeKeyDown style={{ zIndex: styles?.zIndex || 2000 }}>
      <Paper className={classes.paper}>
        <Typography variant="h6" className={classes.title}>
          {placeholder || translate('translate#cm.BusyIndicatorText')}
        </Typography>
        <CircularProgress />
      </Paper>
    </Dialog>
  )
})
