import { Menu } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import React, { FC } from 'react'

import { useClasses } from './styles'
import { Props } from './types'

export const DropDownMenuWithDots: FC<Props> = React.memo(({ children }: Props) => {
  const classes = useClasses()

  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const [open, setOpen] = React.useState(false)

  const handleToggle = (): void => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>): void => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <IconButton
        className={classes.icon}
        aria-label="list-with-dots"
        aria-controls="dropdown-list-with-dots"
        aria-haspopup="true"
        disableRipple
        ref={anchorRef}
        onClick={handleToggle}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="dropdown-list-with-dots"
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {children && children({ open, onClose: handleClose })}
      </Menu>
    </>
  )
})
