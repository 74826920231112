import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { CMCommonApi } from '@/api/cm/commonApi'
import { OrdersApi } from '@/api/cm/ordersApi'
import { SharedApi } from '@/api/cm/sharedApi'
import { ComVerboseError, OrdOrder } from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants'
import { setGlobalFilter } from '@/store/cm/common/actions'
import { getOrders as getOrdersState } from '@/store/cm/orders/selectors'
import { getShrCassettesPropsRequest } from '@/store/cm/shared'
import { AppState } from '@/store/reducers'
import { hotKeysBinder } from '@/utils/hotKeysBinder'

import { setComCMError } from '../common'
import {
  approveOrdersFail,
  cashPointOperationsFail,
  cashPointOperationsResponse,
  checkOrdSelectedOrders,
  declineOrdersFail,
  getCashPointDriversFail,
  getCashPointDriversResponse,
  getCashPointSentOpsStatusesFail,
  getCashPointSentOpsStatusesRequest,
  getCashPointSentOpsStatusesResponse,
  getOrdDeclineNoteResponse,
  getOrdDeclineNoteResponseFail,
  getOrdersRequest,
  getOrderStagesDocRequest,
  getOrderStagesDocResponse,
  getOrdGetOrdersResponse,
  getOrdGetOrdersResponseFail,
  getOrdReportResponse,
  getOrdReportResponseFail,
  getOrdReportsResponse,
  getOrdReportsResponseFail,
  groupOrdOrdersResponseFail,
  refreshOrderRequest,
  refreshOrderResponse,
  refreshOrderResponseFail,
  refreshOrders,
  resetOrdersPage,
  sendToExecuteOrdersFail,
  setOrdOrdersFilter,
  setOrdSelectedOrder,
  setOrdSetDocumentsModalOpen,
  setOrdSetFiltersModalOpen,
  toggleOrdAllOrders,
  toggleOrdOrder,
  ungroupOrdOrdersResponseFail,
} from './actions'

function* getOrders(): any {
  try {
    const paging = yield select(state => {
      const { page, pageSize } = state?.cmOrders?.orders?.paging
      return {
        page,
        pageSize: pageSize === -1 ? 'All' : pageSize,
      }
    })
    const sort = yield select(state => ({
      sortColumn: state.cmOrders.orders.sortColumn,
      sortOrder: state.cmOrders.orders.sortOrder,
    }))
    const storeFilter = yield select(state => ({
      globalFilter: state?.cmCommon?.globalFilter,
      filter: state?.cmOrders?.orders?.filter,
    }))
    const { globalFilter, filter } = storeFilter
    const response = yield call(
      OrdersApi.getOrders,
      paging,
      { globalFilter, filter },
      sort.sortColumn,
      sort.sortOrder,
    )

    const { selectedOrder, checkedOrders, isPoliceIntegrationEnabled } = yield select(
      getOrdersState,
    )

    if (response) {
      if (selectedOrder) {
        const updatedSelectedOrder = response.data.find((cashPoint: OrdOrder) => {
          return cashPoint.sessionGuid === selectedOrder.sessionGuid
        })

        if (updatedSelectedOrder) {
          yield put(setOrdSelectedOrder(updatedSelectedOrder))

          yield put(getShrCassettesPropsRequest(updatedSelectedOrder.id))
          yield put(getOrderStagesDocRequest(updatedSelectedOrder.id))
        } else {
          yield put(setOrdSelectedOrder(null))
        }
      }
      if (Object.keys(checkedOrders).length) {
        const newCheckedOrders = {} as { [key: string]: OrdOrder }

        Object.keys(checkedOrders).map(key => {
          const elementInResponse = response.data.find(
            (cashPoint: OrdOrder) => cashPoint.sessionGuid === checkedOrders[key].sessionGuid,
          )
          if (elementInResponse) {
            newCheckedOrders[elementInResponse.id] = elementInResponse
          }
        })

        yield put(
          checkOrdSelectedOrders(Object.keys(newCheckedOrders).map(key => newCheckedOrders[key])),
        )
      }
    }

    yield put(getOrdGetOrdersResponse(response))
    if (isPoliceIntegrationEnabled) yield put(getCashPointSentOpsStatusesRequest())

    return response
  } catch (error) {
    yield put(getOrdGetOrdersResponseFail(error))
  }
}

function* handleFilterSubmit({ payload }: AnyAction): SagaIterator {
  yield put(setGlobalFilter(payload.globalFilter))
  yield put(setOrdOrdersFilter(payload.ordersFilter))
  yield put(resetOrdersPage())
  yield put(setOrdSelectedOrder(null))
  yield put(toggleOrdAllOrders(false))
  yield put(getOrdersRequest())
}

function* getReports(): SagaIterator {
  try {
    const { checkedOrders, filter, selectedOrder } = yield select(state => state?.cmOrders?.orders)
    const { globalFilter } = yield select(state => state?.cmCommon)
    const request = {
      globalFilter: globalFilter,
      ordersFilter: filter,
      ids: Object.keys(checkedOrders) || [],
      selectedId: selectedOrder?.id,
    }
    const response = yield call(OrdersApi.getReports, request)
    yield put(getOrdReportsResponse(response))
  } catch (e) {
    yield put(getOrdReportsResponseFail(e?.message))
  }
}

function* getReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(CMCommonApi.getReport, payload)
      yield put(getOrdReportResponse(response))
    } else {
      yield put(getOrdReportResponse(null))
    }
  } catch (e) {
    yield put(getOrdReportResponseFail(e?.message))
  }
}

function* handleRefreshGroup(groupId: string): any {
  if (groupId) {
    const { data }: { data: OrdOrder[] } = yield select(
      (state: AppState) => state?.cmOrders?.orders,
    )
    for (const order of data) {
      if (order?.groupId === groupId) {
        yield put(refreshOrderRequest(order.id))
      }
    }
  }
}

function* handleApprove({ payload }: AnyAction): SagaIterator {
  try {
    yield call(OrdersApi.approveOrder, payload)

    if (payload.group) {
      yield handleRefreshGroup(payload.ids[0])
    } else {
      for (const id of payload?.ids) {
        yield put(refreshOrderRequest(id))
      }
    }
  } catch (e) {
    yield put(approveOrdersFail(e))
  }
}

function* handleDecline({ payload }: AnyAction): SagaIterator {
  try {
    yield call(SharedApi.declineOrder, payload)

    if (payload.group) {
      yield handleRefreshGroup(payload.ids[0])
    } else {
      for (const id of payload?.ids) {
        yield put(refreshOrderRequest(id))
      }
    }
  } catch (e) {
    yield put(declineOrdersFail(e))
  }
}

function* handleSendToExecute({ payload }: AnyAction): SagaIterator {
  try {
    yield call(OrdersApi.sendToExecuteOrder, payload)

    if (payload.group) {
      yield handleRefreshGroup(payload.ids[0])
    } else {
      for (const id of payload?.ids) {
        yield put(refreshOrderRequest(id))
      }
    }
  } catch (e) {
    yield put(sendToExecuteOrdersFail(e))
  }
}

function* handleGroupOrders({ payload }: AnyAction): SagaIterator {
  try {
    yield call(OrdersApi.groupOrders, payload)

    for (const id of payload?.ids) {
      yield put(refreshOrderRequest(id))
    }
  } catch (e) {
    yield put(groupOrdOrdersResponseFail(e))
  }
}

function* handleUngroupOrders({ payload }: AnyAction): SagaIterator {
  try {
    yield call(OrdersApi.ungroupOrders, payload)

    yield put(refreshOrders())
  } catch (e) {
    yield put(ungroupOrdOrdersResponseFail(e))
  }
}

function* handleAssignCashierAndCollector({ payload }: AnyAction): SagaIterator {
  try {
    yield call(OrdersApi.assignCashierAndCollector, payload)

    for (const id of payload?.ids) {
      yield put(refreshOrderRequest(id))
    }
    yield put(toggleOrdAllOrders(false))
  } catch (e) {
    yield put(setComCMError(e))
  }
}

function* refreshOrder({ guid }: AnyAction): SagaIterator {
  try {
    const order = yield call(OrdersApi.getOrder, guid)
    yield put(refreshOrderResponse(order))
  } catch (e) {
    yield put(refreshOrderResponseFail(e))
  }
}

function* getOrderStagesDoc({ id }: AnyAction): SagaIterator {
  try {
    const orderStagesDoc = yield call(OrdersApi.getOrderStagesDoc, id)

    yield put(getOrderStagesDocResponse(orderStagesDoc.data))
  } catch (e) {
    yield put(setComCMError(e))
  }
}

function* handleOrdersPagingChange(): SagaIterator {
  yield put(getOrdersRequest())
}

function* getDeclineNote({ payload }: AnyAction): SagaIterator {
  try {
    const response = yield call(SharedApi.getDeclineNote, payload)
    yield put(getOrdDeclineNoteResponse(response))
  } catch (error) {
    const parsedError: ComVerboseError = JSON.parse(error.message)

    yield put(getOrdDeclineNoteResponseFail(parsedError.message || error.message))
  }
}

function* handleKeyPress({ payload }: AnyAction): SagaIterator {
  try {
    const {
      isFiltersModalOpen,
      isModifyModalOpen,
      isSettingsModalOpen,
      isLoadingModalOpen,
      isDocumentsModalOpen,
      isDeclineModalOpen,
      orders: { selectedOrder: selected, data },
    } = yield select((state: AppState) => state.cmOrders)
    const {
      isEditCassettesModalOpen,
      declineNoteState: { isModalOpen: isDeclineNoteModalOpen },
    } = yield select((state: AppState) => state.cmShared)

    hotKeysBinder<OrdOrder>({
      key: payload,
      data,
      selected,
      isOpenModalsList: [
        isFiltersModalOpen,
        isModifyModalOpen,
        isSettingsModalOpen,
        isLoadingModalOpen,
        isDocumentsModalOpen,
        isDeclineModalOpen,
        isEditCassettesModalOpen,
        isDeclineNoteModalOpen,
      ],
      setSelectedAction: setOrdSelectedOrder,
      onToggleAction: toggleOrdOrder,
      openFilterAction: setOrdSetFiltersModalOpen,
      openPrintAction: setOrdSetDocumentsModalOpen,
      onRefreshAction: refreshOrders,
    })
  } catch (error) {
    yield put(setComCMError(error))
  }
}

function* sendPoliceOperations({ payload }: AnyAction): SagaIterator {
  try {
    yield call(OrdersApi.sendPoliceOperations, payload)
    yield put(cashPointOperationsResponse())
    yield put(getCashPointSentOpsStatusesRequest())
  } catch (error) {
    const parsedError: ComVerboseError = JSON.parse(error.message)
    yield put(cashPointOperationsFail(parsedError.message || error.message))
    yield put(getCashPointSentOpsStatusesRequest())
  }
}

function* getPoliceDrivers(): SagaIterator {
  try {
    const response = yield call(OrdersApi.getPoliceDrivers)

    yield put(getCashPointDriversResponse(response))
  } catch (error) {
    const parsedError: ComVerboseError = JSON.parse(error.message)

    yield put(getCashPointDriversFail(parsedError.message || error.message))
  }
}

function* getPoliceStatuses(): SagaIterator {
  try {
    const {
      orders: { data },
    } = yield select((state: AppState) => state.cmOrders)
    const ids = data.map((x: OrdOrder) => x.id)
    const response = yield call(OrdersApi.getPoliceStatuses, { ids: ids })
    yield put(getCashPointSentOpsStatusesResponse(response))
  } catch (error) {
    const parsedError: ComVerboseError = JSON.parse(error.message)
    yield put(getCashPointSentOpsStatusesFail(parsedError.message || error.message))
  }
}

export default function*(): Generator {
  yield takeLatest(
    [CMActions.OrdGetOrdersRequest, CMActions.RefreshOrders, CMActions.SortOrdTable],
    getOrders,
  )
  yield takeLatest(CMActions.OrdFilterSubmitRequest, handleFilterSubmit)
  yield takeLatest(CMActions.OrdGetReportsRequest, getReports)
  yield takeLatest(CMActions.OrdGetReportRequest, getReport)
  yield takeLatest(CMActions.OrdApproveOrders, handleApprove)
  yield takeLatest(CMActions.OrdDeclineOrders, handleDecline)
  yield takeLatest(CMActions.OrdSendToExecuteOrders, handleSendToExecute)
  yield takeEvery(CMActions.OrdRefreshOrderRequest, refreshOrder)
  yield takeLatest(CMActions.OrdSetOrdersPaging, handleOrdersPagingChange)
  yield takeLatest(CMActions.OrdGetStagesDocRequest, getOrderStagesDoc)
  yield takeEvery(CMActions.OrdGroupOrdersRequest, handleGroupOrders)
  yield takeEvery(CMActions.OrdUngroupOrdersRequest, handleUngroupOrders)
  yield takeLatest(CMActions.OrdGetDeclineNoteRequest, getDeclineNote)
  yield takeLatest(CMActions.OrdHandleKeyPress, handleKeyPress)
  yield takeEvery(CMActions.OrdAssignCashierAndCollectorRequest, handleAssignCashierAndCollector)
  yield takeEvery(CMActions.OrdOperationsRequest, sendPoliceOperations)
  yield takeEvery(CMActions.OrdGetCashPointDriversRequest, getPoliceDrivers)
  yield takeEvery(CMActions.OrdGetSentOpsStatusesRequest, getPoliceStatuses)
}
