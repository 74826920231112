import { styledComponentTheme } from '@/theme/styledComponentTheme'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Height } from '@material-ui/icons'

export const useClasses = makeStyles(({ spacing }) => ({
  quantityRoot: {
    color: '#303039',
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
    '&:focus-within': {
      opacity: 1,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .Mui-disabled': {
      color: '#303039',
      opacity: 0.6,
    },
  },
  iconSelect: {
    color: '#303039',
  },
  toolTip: {
    background: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    color: 'black',
    padding: spacing(1),
    fontSize: '14px',
    top: spacing(1),
  },
  initialWidth: {
    width: 'initial',
  },
  tableHeaderButton: {
    border: '1px solid',
    borderColor: styledComponentTheme.colors.baseGreen,
    width: '40px',
    height: '40px',
    padding: '0',
    minWidth: 'auto',
    '& .MuiButton-startIcon': {
      margin: '0',
    },
  },
}))
