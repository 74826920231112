import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { VaultV1CommonApi } from '@/api/vault-v1/commonApi'
import { TeamGroup } from '@/api/vault-v1/swaggerGeneratedApi'
import { TeamsApi } from '@/api/vault-v1/teamsApi'
import { LocalStorageItems, VaultV1Actions } from '@/constants'

import { getSelectedTeam, getTeamsSort, setSelectedTeam } from '.'
import {
  addTeamFail,
  addTeamResponse,
  deleteTeamFail,
  deleteTeamResponse,
  editTeamFail,
  editTeamResponse,
  getTeamReportFail,
  getTeamReportResponse,
  getTeamReportsFail,
  getTeamReportsResponse,
  getTeamsFail,
  getTeamsRequest,
  getTeamsResponse,
} from './actions'

function* getTeams({ payload }: AnyAction): SagaIterator {
  try {
    const { isAdmin } = payload
    const sort = yield select(getTeamsSort)
    const selected = yield select(getSelectedTeam)

    const response: TeamGroup[] = yield call(
      TeamsApi.getTeams,
      isAdmin,
      sort.sortColumn,
      sort.sortOrder,
    )

    if (response) {
      const isTeams = localStorage.getItem(LocalStorageItems.ActiveTeamsVaultV1)

      if (!isTeams) {
        const newCheckedTeams = JSON.parse(
          localStorage.getItem(LocalStorageItems.ActiveTeamsVaultV1) || '{}',
        )
        response.forEach(team => {
          newCheckedTeams[team.groupId || ''] = { name: team.groupName }
        })
        localStorage.setItem(LocalStorageItems.ActiveTeamsVaultV1, JSON.stringify(newCheckedTeams))
      }
      if (selected) {
        let newSelected = null

        response.forEach(group => {
          const updatedTeam = group.teams?.find(team => team.id === selected.id)
          if (updatedTeam) {
            newSelected = updatedTeam
          }
        })

        yield put(setSelectedTeam(newSelected))
      }
      yield put(getTeamsResponse(response))
    }
  } catch (error) {
    yield put(getTeamsFail(error.message))
  }
}

function* editTeam({ payload }: AnyAction): SagaIterator {
  try {
    const { isAdmin, team } = payload

    yield call(TeamsApi.editTeam, isAdmin, team)

    yield put(editTeamResponse())
    yield put(getTeamsRequest({ isAdmin }))
  } catch (error) {
    yield put(editTeamFail(error.message))
  }
}

function* addTeam({ payload }: AnyAction): SagaIterator {
  try {
    const { isAdmin, team } = payload

    yield call(TeamsApi.addTeam, isAdmin, team)

    yield put(addTeamResponse())
    yield put(getTeamsRequest({ isAdmin }))
  } catch (error) {
    yield put(addTeamFail(error.message))
  }
}

function* deleteTeam({ payload }: AnyAction): SagaIterator {
  try {
    const { isAdmin, id } = payload

    yield call(TeamsApi.deleteTeam, isAdmin, id)

    yield put(deleteTeamResponse())
    yield put(getTeamsRequest({ isAdmin }))
  } catch (error) {
    yield put(deleteTeamFail(error.message))
  }
}

// reports
function* getReports(): SagaIterator {
  try {
    const response = yield call(TeamsApi.getReports)

    yield put(getTeamReportsResponse(response))
  } catch (error) {
    yield put(getTeamReportsFail(error?.message))
  }
}

function* getReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(VaultV1CommonApi.getReport, payload)
      yield put(getTeamReportResponse(response))
    } else {
      yield put(getTeamReportResponse(null))
    }
  } catch (error) {
    yield put(getTeamReportFail(error?.message))
  }
}

export default function*(): Generator {
  yield takeLatest([VaultV1Actions.TmsGetTeamsRequest, VaultV1Actions.TmsSortTeams], getTeams)
  yield takeLatest(VaultV1Actions.TmsEditTeamRequest, editTeam)
  yield takeLatest(VaultV1Actions.TmsAddTeamRequest, addTeam)
  yield takeLatest(VaultV1Actions.TmsDeleteTeamRequest, deleteTeam)

  yield takeLatest(VaultV1Actions.TmsGetReportsRequest, getReports)
  yield takeLatest(VaultV1Actions.TmsGetReportRequest, getReport)
}
