import { Box, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { withResizeDetector } from 'react-resize-detector'

import { NotificationApi } from '@/api/atmeye/notification'
import { SecurityAlarmsApi } from '@/api/atmeye/securityAlarms'
import { SecurityAlarmsTableCell } from '@/components/NPMPakage/components/tableComponents/rows/SecurityAlarmsTableCell/SecurityAlarmsTableCell'
import { TableHeader } from '@/components/NPMPakage/components/tableComponents/TableHeader'
import { UITable } from '@/components/NPMPakage/components/tableComponents/UITable/UITable'
import { defaultFiltersConfig } from '@/components/NPMPakage/hooks/defaultFilters/types'
import { LocalStorageItems } from '@/constants'
import { useDefaultFilters } from '@/utils/hooks/atmeye/useDefaultFilters'
import { useTranslate } from '@/utils/internalization'

import ActiveFilters from '../../../NPMPakage/components/ActiveFilters/component'
import { FiltersModalTransactions } from '../../../NPMPakage/components/modals/FiltersModal/FiltersModal'
import { GroupingModal } from '../../../NPMPakage/components/modals/GroupingModal'
import { ObjectsTree } from '../../../NPMPakage/components/ObjectsTree'
import { useSelectedGrouping } from '../../../NPMPakage/hooks/useSelectedGrouping'
import { StyledTableWrapper } from '../Devices/Components/MapPageWrapper/style'
import { LsPageTableKeys } from './hooks/types'
import { useAlarms } from './hooks/useAlarms'
import { PageWrapper, StyledPane, StyledSplitPane, useClasses } from './styles'

const TRANSACTION_GROUPS = [
  'deviceName',
  'eventTypeName',
  'eventMediaTypeName',
  'atmDate',
  'localDate',
  'operator',
  'reactionDate',
]

export const defaultLsKeys = {
  lsKeyGrouping: LocalStorageItems.AlarmsGrouping,
  lsKeyPagination: LocalStorageItems.AlarmsPagination,
  lsKeyColumns: LocalStorageItems.AlarmsColumns,
  lsKeySorting: LocalStorageItems.AlarmsSort,
  lsKeyFilters: LocalStorageItems.AlarmsFilters,
  lsKeyFiltersTemplates: LocalStorageItems.AlarmsFilterTemplate,
}

const AlarmsPage = ({
  initialHiddenColumns,
  title,
  requiredFilters,
  lsKeys = defaultLsKeys,
  filtersConfig = defaultFiltersConfig.alarms,
  width,
}: {
  title?: string
  initialHiddenColumns?: string[]
  requiredFilters?: Record<string, any>
  lsKeys?: LsPageTableKeys
  filtersConfig?: Record<string, any>
  width: number
}): React.ReactElement => {
  const { defaultFilters } = useDefaultFilters(filtersConfig)

  const [alarmCount, setAlarmsCount] = useState(0)

  const getCount = async () => {
    const fetcher = new NotificationApi()
    const getNotificationsList = fetcher.getNotificationsList.bind(fetcher)
    const notifications = await getNotificationsList()

    setAlarmsCount(notifications?.alarms?.count || 0)
  }

  useEffect(() => {
    getCount()
  }, [])

  const {
    showGroupingModal,
    selectedGrouping,
    selectedGroupingTemplate,

    handleSetSelectedGrouping,
    handleClearGrouping,
    handleSetSelectedGroupingTemplate,

    handleSetShowGroupingModal,

    selectedGroupingNodes,
    setSelectedGroupingNodes,
  } = useSelectedGrouping({ localStorageKey: lsKeys.lsKeyGrouping })

  const {
    isLoading,
    isGroupsLoading,
    hasGrouping,

    sorting: { changeSorting, sorting },

    tableData,
    groupedData,
    pagination,
    refreshData,
    setTableData,

    fields,
    filters: {
      selectedFilters,
      handleSubmit,
      handleSetFiltersModal,
      showFiltersModal,
      handleClearFilters,
      handleDeleteFilter,
      selectedFiltersTemplate,
      handleSetSelectedFilterTemplate,
    },
    headerColumns: {
      setColumns,
      columnsMenuAnchorEl,
      columns,
      setColumnsMenuAnchorEl,
      handleChangeColumns,
      allFields,
      currentFields,
    },
    exportTableData,
    filterView,
  } = useAlarms({
    selectedGrouping,
    selectedGroupingNodes,
    setSelectedGroupingNodes,
    initialHiddenColumns,
    requiredFilters,
    lsKeys,
    defaultFilters,
    filtersConfig,
  })

  const classes = useClasses()
  const translate = useTranslate()

  const HeaderTitleJSX = (
    <div className={classes.headerWrapper}>
      <Typography className={classes.title} variant="h5">
        {title ?? translate('translate#atmeye.title.securityAlarms')}
      </Typography>

      {alarmCount > 0 && (
        <div className={classes.countWrapper}>
          <Box className={classes.boxWrapper}>
            <span style={{ fontSize: '15px' }}>
              {translate('translate#atmeye.title.without.reaction')}
            </span>

            <Box className={classes.reactionCount}>
              <span> {alarmCount} </span>
            </Box>
          </Box>
        </div>
      )}
    </div>
  )

  return (
    <div className={classes.root}>
      <PageWrapper>
        <StyledSplitPane
          minSize={width / 8}
          maxSize={width / 1.5}
          split="vertical"
          defaultSize="15%"
          paneStyle={!hasGrouping ? { width: '100%' } : {}}
        >
          {hasGrouping && (
            <StyledPane>
              <div className={classes.treeWrapper}>
                {groupedData?.length ? (
                  <div style={{ width: '200px', height: '500px' }}>
                    <ObjectsTree
                      tree={groupedData || []}
                      isFetching={isGroupsLoading}
                      onChange={setSelectedGroupingNodes}
                      selectedGroupingNodes={selectedGroupingNodes}
                      title={translate('translate#atmeye.title.securityAlarms')}
                    />
                  </div>
                ) : (
                  <Box className={classes.noTreeData}>
                    <Typography>{translate('translate#atmeye.title.emptyGroupsData')}</Typography>
                  </Box>
                )}
              </div>
            </StyledPane>
          )}

          <StyledPane>
            <StyledTableWrapper>
              <TableHeader
                columnsData={{
                  columnsMenuAnchorEl,
                  columns,
                  currentFields,
                  allFields,
                  onChangeColumns: handleChangeColumns,
                  setColumnsMenuAnchorEl,
                }}
                refreshData={refreshData}
                onClickFiltersButton={handleSetFiltersModal}
                tabsHeaderJSX={HeaderTitleJSX}
                selectedFilters={selectedFilters}
                onClickGroupingButton={handleSetShowGroupingModal}
                hasGroups={hasGrouping}
                exportTableData={exportTableData}
              />

              <ActiveFilters
                filterView={filterView}
                onDeleteFilter={handleDeleteFilter}
                onClearFilters={handleClearFilters}
                requiredFilters={requiredFilters}
              />

              <UITable
                // hiddenSortIcons={['eventTypeName', 'cameraNumber']}
                rowIdKey="eventId"
                className={classes.tableRoot}
                isFetching={isLoading}
                keyLabel="sortField"
                columns={columns}
                data={tableData}
                CellJSX={SecurityAlarmsTableCell}
                setColumns={setColumns}
                sorting={sorting}
                onSort={changeSorting}
                changePageSize={pagination.changeSize}
                changeCurrentPage={pagination.changePage}
                selectedRowId={null}
                pagination={pagination.paginationData}
                getUpdateRequest={{
                  mutateQueryTable: setTableData,
                  reactionToAlarms: async alarmId => {
                    const { handleDate } = await SecurityAlarmsApi.reactToAlarm({ alarmId })
                    setAlarmsCount(count => count - 1)
                    return handleDate
                  },
                }}
              />
            </StyledTableWrapper>
          </StyledPane>
        </StyledSplitPane>
      </PageWrapper>

      {showFiltersModal && (
        <FiltersModalTransactions
          filterTemplatesType="alarmsFilterTemplates"
          formData={{
            initialValues: selectedFilters,
            onSubmit: handleSubmit,
            enableReinitialize: true,
          }}
          defaultValues={defaultFilters}
          open={showFiltersModal}
          handleClose={handleSetFiltersModal}
          filterFields={fields}
          selectedFilters={selectedFilters}
          selectedFiltersTemplate={selectedFiltersTemplate}
          handleApplyFilterTemplate={handleSetSelectedFilterTemplate}
          requiredFilters={requiredFilters}
        />
      )}

      {showGroupingModal && (
        <GroupingModal
          groupingTemplatesType="alarmsGroupingTemplates"
          open={showGroupingModal}
          appliedGrouping={selectedGrouping}
          appliedGroupingTemplate={selectedGroupingTemplate}
          // defaultGrouping={[]}
          onApply={handleSetSelectedGrouping}
          onClose={handleSetShowGroupingModal}
          handleApplyGroupingTemplate={handleSetSelectedGroupingTemplate}
          groupsList={TRANSACTION_GROUPS}
          groupingLevel={4}
        />
      )}
    </div>
  )
}

export default withResizeDetector(AlarmsPage)
