import { reducer } from './reducer'
import saga from './saga'

export * from './actions'
export * from './selectors'

export default {
  saga,
  reducer,
}
