import { styledComponentTheme } from '@/theme'
import { makeStyles } from '@material-ui/core'

export const useClasses = makeStyles(({ spacing }) => ({
  arrowWrap: {
    cursor: 'pointer',
    height: spacing(3),
    marginLeft: '-4px',
  },
  arrow: {
    color: '#1D2C2E',
    opacity: 0.4,
  },
  arrowDown: {
    color: styledComponentTheme.colors.primary,
    opacity: 1,
  },
  checkbox: {
    padding: spacing(0),
    marginRight: spacing(1),
  },

  folderIcon: {
    marginRight: spacing(1),
    fontSize: '20px',
    fill: '#bdbdbd',
  },
  selectedFolder: {
    fill: '#50a8ae',
  },
}))
