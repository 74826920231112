import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'

import NoPageAccess from '@/components/controls/Access/NoPageAccess'
import AccessControl from '@/components/controls/auth/AccessControl'
import TicketsPage from '@/components/pages/sd/TicketsPage'
import { isMenuItemDisabled } from '@/constants'
import { AppNavigationRoutes, SD_BLOCK_PREFIX } from '@/constants/paths'
import { getPermissions } from '@/store/auth/selectors'
import { PopUpService } from '@/utils/services/popUpService'

export const TYPE_OF_TICKETS = {
  MY: 'myTickets',
  ALL: 'allTickets',
  HISTORY: 'historyTickets',
}

interface Props extends RouteComponentProps {
  typeOfTickets?: string
  path: string
  component: any
}

export const WithPermissionsRender = ({ path, component: Component, ...restProps }: Props) => {
  const permissions = useSelector(getPermissions)

  useEffect(() => {
    if (isMenuItemDisabled(path, permissions)) {
      PopUpService.showGlobalPopUp(`translate#title.notEnoughPermissionsCreateRequest`, 'warning')
    }
  }, [permissions])

  if (isMenuItemDisabled(path, permissions)) {
    return null
  }

  return <Component {...restProps} />
}

const TicketsRouter = (): React.ReactElement => {
  return (
    <Switch>
      <Route
        exact
        path={`${SD_BLOCK_PREFIX}${AppNavigationRoutes.TicketsPage}`}
        render={props => (
          <AccessControl
            allowedPermissions={['SRV_ClWEB_SearchRequests_Open_MY']}
            renderNoAccess={(): React.ReactElement => <NoPageAccess />}
          >
            <TicketsPage {...props} typeOfTickets={TYPE_OF_TICKETS.MY} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${SD_BLOCK_PREFIX}${AppNavigationRoutes.AllTicketsPage}`}
        render={props => (
          <AccessControl
            allowedPermissions={['SRV_ClWEB_SearchRequests_Open_ALL']}
            renderNoAccess={(): React.ReactElement => <NoPageAccess />}
          >
            <TicketsPage {...props} typeOfTickets={TYPE_OF_TICKETS.ALL} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${SD_BLOCK_PREFIX}${AppNavigationRoutes.HistoryTicketsPage}`}
        render={props => (
          <AccessControl
            allowedPermissions={['SRV_ClWEB_SearchRequests']}
            renderNoAccess={(): React.ReactElement => <NoPageAccess />}
          >
            <TicketsPage {...props} typeOfTickets={TYPE_OF_TICKETS.HISTORY} />
          </AccessControl>
        )}
      />
    </Switch>
  )
}

export default TicketsRouter
