import { Button, Dialog } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { NewCommonApi } from '@/api/atmeye/common/newCommonAPI'
import { Command } from '@/api/rsocket/command'
import CloseButton from '@/components/blocks/CloseButton'
import Loader from '@/components/blocks/Loader'
import { APIConfiguration } from '@/constants/endpoints'
import { DATE_FORMAT, DEFAULT_DATE_TIME_SEC_FORMAT } from '@/constants/time'
import { SocketContext } from '@/context'
import { clearSocketDataByMessage, getSocketData } from '@/store/dashboard'
import { useTranslate } from '@/utils/internalization'

import { ContentWrapper, useClasses } from './styles'
import { Media, Props } from './types'

const CameraModal = ({
  open,
  handleClose,
  row,
  eventType,
  getUpdateRequest,
}: Props): ReactElement => {
  const classes = useClasses()
  const dispatch = useDispatch()
  const translate = useTranslate()
  const [media, setMedia] = useState<Media>(null)
  const [isLoadingFiles, setIsLoadingFiles] = useState(false)
  const [wsError, setWsError] = useState('')

  const { eventId, atmDate, localDate, contentType, mediaStoreType } = row

  const fileData: any = useSelector(getSocketData(Command.START_EVENT_FILE_DOWNLOAD), shallowEqual)
  const { _service, connected } = useContext(SocketContext)

  const downloadImage = async (eventDate?: string) => {
    const response = await NewCommonApi.getMedia({
      eventId,
      eventType: eventType || 'TRANSACTION',
      eventDate,
    })

    if (response.errorMessage) {
      throw new Error(response)
    }

    setMedia({
      eventId,
      imageUrl: response.url,
      type: contentType,
    })

    if (getUpdateRequest?.mutateQueryTable) {
      getUpdateRequest.mutateQueryTable()
    }

    if (!row?.reactionDateTime && getUpdateRequest?.reactionToAlarms) {
      getUpdateRequest.reactionToAlarms(row?.eventId)
    }
  }

  useEffect(() => {
    const eventDate =
      atmDate || localDate ? moment(atmDate || localDate).format(DATE_FORMAT) : undefined

    if (mediaStoreType === 'DATABASE_FILE') {
      downloadImage(eventDate)
    }

    if (connected && mediaStoreType === 'ATM_FILE') {
      _service.send(
        {
          eventType,
          eventId,
          eventDate,
        },
        '',
        Command.START_EVENT_FILE_DOWNLOAD,
      )
    }
  }, [])

  useEffect(() => {
    if (fileData?.status === 'OK' && fileData.eventId === +eventId) {
      const eventDate =
        atmDate || localDate ? moment(atmDate || localDate).format(DATE_FORMAT) : undefined

      downloadImage(eventDate)
    }

    if (fileData?.status === 'ERROR') {
      setWsError(translate(`translate#atmeye.cameraModal.unknownError`))
    }

    if (fileData?.status === 'OFFLINE') {
      setWsError(translate(`translate#atmeye.cameraModal.deviceOffline`))
    }

    return () => {
      dispatch(clearSocketDataByMessage(Command.START_EVENT_FILE_DOWNLOAD))
    }
  }, [fileData])

  const modalContent = useMemo(() => {
    if (wsError) {
      return <div className={classes.message}>{wsError}</div>
    }

    if (!media?.imageUrl) {
      return <Loader />
    }

    const { imageUrl, type } = media

    if (type === 'JPG') {
      return imageUrl ? (
        <div className={classes.imgContainer}>
          <img
            alt="camera"
            className={classes.img}
            src={`${APIConfiguration.API_BASE_URL}${imageUrl}`}
            onLoad={e => console.log(e)}
          />
        </div>
      ) : (
        'something got wrong'
      )
    }

    if (type === 'AVI' || type === 'MKV') {
      return (
        <video controls height="400px" className={classes.img}>
          <source src={`${APIConfiguration.API_BASE_URL}${media.imageUrl}`} />
        </video>
      )
    }
  }, [classes.img, classes.imgContainer, classes.message, media, translate, wsError])

  const toDataURL = (url: string) => {
    return fetch(url)
      .then(response => {
        return response.blob()
      })
      .then(blob => {
        return {
          localUrl: URL.createObjectURL(blob),
          extent: blob.type.slice((Math.max(0, blob.type.lastIndexOf('/')) || Infinity) + 1),
        }
      })
  }

  const handleDownload = async () => {
    setIsLoadingFiles(true)
    const url = `${APIConfiguration.API_BASE_URL}${media?.imageUrl}`

    const a = document.createElement('a')
    const { localUrl, extent } = await toDataURL(url)

    const data = [
      row?.deviceName,
      row?.clientId,
      row?.eventTypeName,
      moment.utc(row?.atmDateTime || row?.atmDate).format(DEFAULT_DATE_TIME_SEC_FORMAT),
    ]

    const fileName = `${data.filter(el => !!el).join('_')}.${extent}`

    a.href = localUrl
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    setIsLoadingFiles(false)
    document.body.removeChild(a)
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      keepMounted
      onClose={handleClose}
      disableBackdropClick
    >
      <ContentWrapper className={classes.wrapper}>
        {isLoadingFiles && (
          <div className={classes.loaderWrapper}>
            <Loader />
          </div>
        )}
        <CloseButton absolute className={classes.closeButton} onClick={handleClose} />

        <div className={classes.deviceTitle}>
          <FormattedMessage id="title.camera" defaultMessage="Camera" />
        </div>

        <div>{modalContent}</div>

        <Grid container justify="flex-end">
          <Button
            onClick={handleClose}
            className={classes.button}
            color="primary"
            variant="contained"
          >
            <FormattedMessage id="dict.cancel" defaultMessage="Cancel" />
          </Button>

          {media?.imageUrl && (
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={handleDownload}
              disabled={isLoadingFiles}
            >
              <FormattedMessage id="dict.save" defaultMessage="Save" />
            </Button>
          )}
        </Grid>
      </ContentWrapper>
    </Dialog>
  )
}

export default CameraModal
