import { IconButton } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'

import ExcelImage from '@/assets/excel.png'
import FileImage from '@/assets/file1.png'
import PdfImage from '@/assets/pdf1.png'
import DocImage from '@/assets/word1.png'
import {
  CreateNewTicketFormFields,
  CreateNewTicketFormValues,
} from '@/constants/forms/CreateNewTicketForm'

const getSourceByName = (name: string): string => {
  const [ext] = name.match(/\.[^.]+$/) || ['']
  if (ext === '.pdf') {
    return PdfImage
  }
  if (ext === '.xls' || ext === '.xlsx') {
    return ExcelImage
  }
  if (ext === '.doc' || ext === '.docm' || ext === '.docx' || ext === '.dot' || ext === '.dotx') {
    return DocImage
  }
  return FileImage
}

export const renderFiles = (
  values: CreateNewTicketFormValues[CreateNewTicketFormFields.Files],
  classes: ClassNameMap,
  handleRemoveFile: (index: number) => void,
): React.ReactElement => {
  const handleClick = (i: number): (() => void) => (): void => handleRemoveFile(i)

  return (
    <Box display="flex" flexWrap="wrap">
      {values.map(({ name, size }, i) => {
        return (
          <div key={`${name}${i}`} className={classes.fileButton}>
            <Box flexBasis="25%">
              <img className={classes.extension} alt="file extension" src={getSourceByName(name)} />
            </Box>
            <Box flexBasis="75%" maxWidth="75%">
              <Box
                fontWeight="fontWeightBold"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {name}
              </Box>
              <div className={classes.fileSize}>
                {Math.round((size * 100) / 1024 / 1024) / 100} mb
              </div>
            </Box>
            <IconButton onClick={handleClick(i)} size="small" className={classes.closeIconButton}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </div>
        )
      })}
    </Box>
  )
}
