import React from 'react'

import { store } from '@/App'

export const CompaniesRoutes = React.lazy(async () => {
  const {
    default: { reducer: companiesDrawerActionsReducer, saga: companiesDrawerActionsSaga },
  } = await import('@/store/companies/drawerActions')

  store.injectReducer('companiesDrawerActions', companiesDrawerActionsReducer)
  store.injectSaga('companiesDrawerActions', companiesDrawerActionsSaga)

  const {
    default: { reducer: companiesReducer, saga: companiesSaga },
  } = await import('@/store/companies/companies')

  store.injectReducer('companiesCompanies', companiesReducer)
  store.injectSaga('companiesCompanies', companiesSaga)

  const {
    default: { reducer: companiesFormsConfigsReducer, saga: companiesFormsConfigsSaga },
  } = await import('@/store/companies/forms-configs')
  store.injectReducer('companiesFormsConfigs', companiesFormsConfigsReducer)
  store.injectSaga('companiesFormsConfigs', companiesFormsConfigsSaga)

  const {
    default: { reducer: companiesImportReducer, saga: companiesImportSaga },
  } = await import('@/store/companies/companiesImport')
  store.injectReducer('companiesImport', companiesImportReducer)
  store.injectSaga('companiesImport', companiesImportSaga)

  const {
    default: { reducer: reducerCommonSearchForm, saga: sagaCommonSearchForm },
  } = await import('@/store/common/searchForm')

  store.injectReducer('commonSearchForm', reducerCommonSearchForm)
  store.injectSaga('commonSearchForm', sagaCommonSearchForm)

  return import('@/components/routers/companies')
})
