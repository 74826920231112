import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { BagsApi } from '@/api/vault-v1/bagsApi'
import { refetchBagButtonsStatuses } from '@/api/vault-v1/bagsApi/queries/buttons'
import { refetchBagDetails } from '@/api/vault-v1/bagsApi/queries/details'
import { VaultV1CommonApi } from '@/api/vault-v1/commonApi'
import { VaultBag, VaultBagsResponse } from '@/api/vault-v1/swaggerGeneratedApi'
import { BagModalNames, ModalsNames } from '@/components/pages/vault-v1/Bags/types'
import { VaultV1BagsActions } from '@/constants'
import { hotKeysBinder } from '@/utils/hotKeysBinder'

import { getGlobalFilterVaultV1, setComVaultV1Error } from '../common'
import {
  addEditBagsFail,
  addEditBagsResponse,
  deleteBagFail,
  editBagFail,
  editBagResponse,
  getBagFail,
  getBagRequest,
  getBagResponse,
  getBagsFail,
  getBagsRequest,
  getBagsResponse,
  getBgsReportFail,
  getBgsReportResponse,
  getBgsReportsFail,
  getBgsReportsResponse,
  loadBagFail,
  loadBagResponse,
  setSelectedBag,
  setTableModal,
  unloadBagFail,
  unloadBagResponse,
} from './actions'
import {
  getBags as getBagsSelector,
  getBagsFilter,
  getBagsModals,
  getBagsPaging,
  getBagsSort,
  getSelectedBag,
} from './selectors'

function* getBags(): SagaIterator {
  try {
    const filter = yield select(getBagsFilter)
    const globalFilter = yield select(getGlobalFilterVaultV1)
    const paging = yield select(getBagsPaging)
    const sort = yield select(getBagsSort)
    const selectedBag: VaultBag = yield select(getSelectedBag)

    const response: VaultBagsResponse = yield call(BagsApi.getAll, {
      paging,
      filter: { globalFilter, ...filter },
      sort,
    })

    if (response) {
      yield put(getBagsResponse(response))

      if (selectedBag) {
        const foundedEntry = response.data?.find(bag => bag.id === selectedBag.id)

        if (foundedEntry) {
          refetchBagButtonsStatuses(foundedEntry.id)
          yield put(setSelectedBag(foundedEntry))
        } else {
          yield put(setSelectedBag(null))
        }
      }
    }
  } catch (error) {
    yield put(getBagsFail(String(error as Error)))
  }
}

function* getSingleCassette({ payload }: AnyAction): SagaIterator {
  try {
    const { id, isDetailsRequest } = payload || {}
    const selected = yield select(getSelectedBag)

    if (id || selected.id) {
      const cassette = yield call(BagsApi.getSingle, id || selected.id)

      yield put(getBagResponse(cassette))
      refetchBagButtonsStatuses(id || selected.id)
      isDetailsRequest && refetchBagDetails(id || selected.id)
    }
  } catch (error) {
    yield put(getBagFail(String(error as Error)))
  }
}

function* addEditBags({ payload }: AnyAction): SagaIterator {
  const { type, id, ...rest } = payload

  try {
    if (type === BagModalNames.AddBag) {
      yield call(BagsApi.add, { ...rest })
    } else {
      yield call(BagsApi.edit, { ...rest, id })
    }

    yield put(addEditBagsResponse())
    yield put(getBagsRequest())
  } catch (error) {
    yield put(addEditBagsFail())
  }
}

function* deleteBag({ payload }: AnyAction): SagaIterator {
  try {
    yield call(BagsApi.delete, payload)

    yield put(setSelectedBag(null))
    yield put(getBagsRequest())
  } catch (error) {
    yield put(deleteBagFail())
  }
}

function* loadBag({ payload }: AnyAction): SagaIterator {
  try {
    yield call(BagsApi.load, payload)

    yield put(loadBagResponse())
    yield put(getBagRequest())
  } catch (error) {
    yield put(loadBagFail())
  }
}

function* unloadBag({ payload }: AnyAction): SagaIterator {
  try {
    yield call(BagsApi.unload, payload)

    yield put(unloadBagResponse())
    yield put(getBagRequest())
  } catch (error) {
    yield put(unloadBagFail())
  }
}

function* editBag({ payload }: AnyAction): SagaIterator {
  try {
    yield call(BagsApi.editDetails, payload)

    yield put(editBagResponse())
    yield put(getBagRequest({ isDetailsRequest: true }))
  } catch (error) {
    yield put(editBagFail())
  }
}

function* getReports(): SagaIterator {
  try {
    const response = yield call(BagsApi.getReports)

    yield put(getBgsReportsResponse(response))
  } catch (error) {
    yield put(getBgsReportsFail())
  }
}

function* getReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(VaultV1CommonApi.getReport, payload)
      yield put(getBgsReportResponse(response))
    } else {
      yield put(getBgsReportResponse(null))
    }
  } catch (error) {
    yield put(getBgsReportFail())
  }
}

function* handleKeyPress({ payload }: AnyAction): SagaIterator {
  try {
    const { data, selected } = yield select(getBagsSelector)
    const { settingsModal, documentModal, filtersModal, bagModalLoading } = yield select(
      getBagsModals,
    )

    hotKeysBinder<VaultBag>({
      key: payload,
      data,
      selected,
      isOpenModalsList: [settingsModal, documentModal, filtersModal, bagModalLoading],
      setSelectedAction: setSelectedBag,
      onRefreshAction: getBagsRequest,
      openFilterAction: isOpen => setTableModal({ type: ModalsNames.FiltersModal, isOpen }),
    })
  } catch (error) {
    yield put(setComVaultV1Error(error))
  }
}

export default function*(): Generator {
  yield takeLatest(
    [
      VaultV1BagsActions.BgsGetBagsRequest,
      VaultV1BagsActions.BgsSortBags,
      VaultV1BagsActions.BgsPagingBags,
      VaultV1BagsActions.BgsSetFilter,
    ],
    getBags,
  )

  yield takeLatest(VaultV1BagsActions.BgsGetBagRequest, getSingleCassette)

  yield takeLatest(VaultV1BagsActions.BgsAddEditBagsRequest, addEditBags)
  yield takeLatest(VaultV1BagsActions.BgsDeleteBagRequest, deleteBag)

  yield takeLatest(VaultV1BagsActions.BgsLoadBagRequest, loadBag)
  yield takeLatest(VaultV1BagsActions.BgsUnloadBagRequest, unloadBag)
  yield takeLatest(VaultV1BagsActions.BgsEditBagRequest, editBag)

  yield takeLatest(VaultV1BagsActions.BgsGetReportsRequest, getReports)
  yield takeLatest(VaultV1BagsActions.BgsGetReportRequest, getReport)

  yield takeLatest(VaultV1BagsActions.BgsHandleKeyPress, handleKeyPress)
}
