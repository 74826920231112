/* eslint-disable @typescript-eslint/camelcase */
import MomentUtils from '@date-io/moment'
import { common } from '@material-ui/core/colors'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import clsx from 'clsx'
import { FieldProps } from 'formik'
import { delay } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import CalendarIcon from '@/assets/atmeye/icons/calendar.svg'
import ClockIcon from '@/assets/atmeye/icons/clock.svg'
import {
  DATE_FORMAT,
  DATE_TIME_SEC_FORMAT,
  DEFAULT_DATE_FORMAT,
  TIME_FORMAT,
} from '@/constants/time'
import { generateErrorText } from '@/helpers/generateErrorText'
import colors from '@/theme/colors'
import { getDate } from '@/utils'
import { useTranslate } from '@/utils/internalization'
import { ValidationService } from '@/utils/services/validationService'

import { useClasses } from './styles'
import {
  DateInputProps,
  FormikDatePickerFieldProps,
  FormikDatePickerProps,
  FormikDatePickerRangeProps,
  TimeInputProps,
} from './types'

const toolbarTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      dark: colors.primaryDark,
      contrastText: common.white,
    },
    secondary: {
      main: colors.secondary,
      contrastText: common.white,
    },
    // contrastThreshold: 0,
  },
})

export const DateInput = ({
  disableFutureDates,
  disablePast,
  error,
  helperText,
  className,
  maxDate,
  minDate,
  Icon,
  label,
  tabIndex,
  format,
  autoOpenTimePiker = true,
  onTimepickerClose,
  ...props
}: DateInputProps & TimeInputProps): React.ReactElement => {
  const [isOpen, toogleDatePopover] = useState<boolean>(false)
  const [isOpenTime, toogleTimePopover] = useState<boolean>(false)
  const classes = useClasses()
  const translate = useTranslate()

  const formatForDateMask = useMemo(() => moment().format(format || DEFAULT_DATE_FORMAT), [format])
  const defaultDateFormat = useMemo(() => format || DEFAULT_DATE_FORMAT, [format])

  const dateMask = useMemo(() => {
    return formatForDateMask && formatForDateMask.replace(/[A-Za-z]/g, '_').replace(/[0-9]/g, '_')
  }, [formatForDateMask])

  const handleOpenDatePicker = useCallback(() => {
    toogleDatePopover(true)
  }, [toogleDatePopover, toogleTimePopover])

  const handleCloseDatePicker = useCallback(() => {
    toogleDatePopover(false)
  }, [toogleDatePopover, toogleTimePopover])

  const handleOpenTimePicker = useCallback(() => {
    toogleTimePopover(true)
  }, [toogleDatePopover, toogleTimePopover])

  const handleCloseTimePicker = useCallback(() => {
    onTimepickerClose && onTimepickerClose()
    toogleTimePopover(false)
  }, [onTimepickerClose, toogleDatePopover, toogleTimePopover])

  return (
    <ThemeProvider theme={toolbarTheme}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <KeyboardDatePicker
          {...props}
          onChange={(momentDate, inputValue): void => {
            props.onChange(momentDate, inputValue, false)
          }}
          value={props.value}
          label={
            tabIndex ? translate(`translate#title.dateTo`) : translate(`translate#title.dateFrom`)
          }
          size="small"
          variant="inline"
          margin="normal"
          onOpen={handleOpenDatePicker}
          onClose={handleCloseDatePicker}
          open={isOpen}
          onAccept={() => {
            handleCloseDatePicker()
            autoOpenTimePiker && delay(handleOpenTimePicker, 0)
          }}
          maxDate={maxDate || disableFutureDates ? maxDate : undefined}
          minDate={minDate || disablePast ? minDate : undefined}
          inputVariant="outlined"
          className={clsx(classes.input, className, {
            [classes.inputWithError]: error,
          })}
          InputLabelProps={{
            className: clsx(classes.label, { [classes.errorLabel]: error }),
            shrink: true,
          }}
          InputAdornmentProps={{
            className: classes.icon,
          }}
          keyboardIcon={<img src={CalendarIcon} />}
          mask={dateMask}
          format={defaultDateFormat}
          disabled={props.disabled}
          error={error}
          helperText={
            helperText ? (
              <div className={classes.errorHelperText}>
                <FormattedMessage id={`form.validationWarning.${helperText}`} />
              </div>
            ) : null
          }
          autoOk={false}
        />
        <KeyboardTimePicker
          {...props}
          value={props.value}
          onChange={(momentDate, inputValue): void => {
            props.onChange(momentDate, inputValue, true)
          }}
          label={
            tabIndex ? translate(`translate#title.timeTo`) : translate(`translate#title.timeFrom`)
          }
          size="small"
          variant="inline"
          margin="normal"
          onOpen={handleOpenTimePicker}
          onClose={handleCloseTimePicker}
          open={isOpenTime}
          ampm={false}
          inputVariant="outlined"
          className={clsx(classes.input, className, {
            [classes.inputWithError]: error,
          })}
          InputLabelProps={{
            className: clsx(classes.label, { [classes.errorLabel]: error }),
            shrink: true,
          }}
          InputAdornmentProps={{
            className: classes.icon,
          }}
          keyboardIcon={<img src={ClockIcon} />}
          format="HH:mm"
          disabled={props.disabled}
          error={error}
          helperText={
            helperText ? (
              <div className={classes.errorHelperText}>
                <FormattedMessage id={`form.validationWarning.${helperText}`} />
              </div>
            ) : null
          }
          autoOk
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export const FormikDatePicker = ({
  field,
  form: { errors, setFieldValue, getFieldHelpers },
  meta: { touched },
  label,
  className,
  format,
  isRange,
  placeholder,
  index,
  withoutCurrentDate,
  disabled,
  disabledPast = false,
  disableFutureDates,
  withoutArrayDate,
  dynamicDateValue,
  Icon,
  setDynamicDate,
  minTomorrowDate,
  ...other
}: FormikDatePickerProps): React.ReactElement => {
  const [toDateIsHidden, setToDateIsHidden] = useState<boolean>(false)

  const maxDate =
    dynamicDateValue && dynamicDateValue.end ? new Date(dynamicDateValue.end) : new Date()

  const minDate =
    dynamicDateValue && dynamicDateValue.start ? new Date(dynamicDateValue.start) : new Date()

  const classes = useClasses()

  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)

  const updateDynamicDate = (date: string) => {
    if (setDynamicDate && disableFutureDates) {
      setDynamicDate(prevState => ({
        end: prevState.end,
        start: date,
      }))
    }

    if (setDynamicDate && disabledPast) {
      setDynamicDate(prevState => ({
        start: prevState.start,
        end: date,
      }))
    }
  }

  const handleDateChange = useCallback(
    (momentDate, date, isTimePicker): void => {
      if (!date) {
        if (isRange && index !== undefined) {
          const newValue = [...field.value]
          newValue[index] = null
          setFieldValue(field.name, newValue, true)
        } else {
          setFieldValue(field.name, null, true)
          updateDynamicDate('')
        }
      }

      if (date && !date.includes('_')) {
        if (isRange && index !== undefined) {
          if (index === 1) setToDateIsHidden(false)
          const newValue = [...field.value]

          if (isTimePicker) {
            newValue[index] = moment(newValue[index]).isValid()
              ? moment(newValue[index])
                  .set({ hour: momentDate.hours(), minute: momentDate.minutes() })
                  .format(DATE_TIME_SEC_FORMAT)
              : moment()
                  .set({ hour: momentDate.hours(), minute: momentDate.minutes() })
                  .format(DATE_TIME_SEC_FORMAT)
          } else {
            newValue[index] = momentDate.isValid()
              ? momentDate
                  .set({
                    hour: moment(newValue[index]).hours(),
                    minute: moment(newValue[index]).minutes(),
                  })
                  .format(DATE_TIME_SEC_FORMAT)
              : moment()
                  .set({
                    hour: moment(newValue[index]).hours(),
                    minute: moment(newValue[index]).minutes(),
                  })
                  .format(DATE_TIME_SEC_FORMAT)
          }

          setFieldValue(field.name, newValue, true)
        } else {
          const defaultDate = (minTomorrowDate
            ? moment()
                .clone()
                .add(1, 'days')
            : moment()
          ).format(DATE_TIME_SEC_FORMAT)

          const fieldValue = momentDate.isValid()
            ? momentDate.format(DATE_TIME_SEC_FORMAT)
            : defaultDate

          getFieldHelpers(field.name).setTouched(true)
          setFieldValue(field.name, fieldValue, true)
          updateDynamicDate(fieldValue)
        }
      }
    },
    [setFieldValue, field.value],
  )

  const value = useMemo(() => {
    if (toDateIsHidden) {
      return null
    }

    if (withoutCurrentDate && index !== undefined && !field.value) {
      return null
    }

    if (isRange && index !== undefined) {
      const startDate = new Date(field.value[0])
      const endDate = new Date(field.value[1])

      const validateDate =
        disableFutureDates && ValidationService.isInvalidDateInterval(startDate, endDate)

      return field.value[index] && !validateDate
        ? startDate > endDate
          ? field.value[0]
          : field.value[index]
        : validateDate
        ? moment(new Date()).format(DATE_TIME_SEC_FORMAT)
        : null
      // return field.value[index]
      //   ? field.value[index] : null
    }

    return field.value ? field.value : null
  }, [field.value, toDateIsHidden])

  useEffect(() => {
    if (index === 1 && disabled) {
      setFieldValue(field.name, [null, null], false)
    }
  }, [index, disabled])

  useEffect(() => {
    if (
      (withoutArrayDate ? field.value && !field.value[1] : field.value[0] && !field.value[1]) &&
      index === 1
    ) {
      const date = moment(new Date()).format(DATE_TIME_SEC_FORMAT)

      setToDateIsHidden(false)
      setFieldValue(field.name, [field.value[0], date], false)
    }
  }, [field, index])

  return (
    <DateInput
      label={label}
      className={className}
      disablePast={disabledPast}
      name={field.name}
      value={value}
      onChange={handleDateChange}
      format={format}
      placeholder={placeholder}
      onBlur={(): void => {
        getFieldHelpers(field.name).setTouched(true)
      }}
      maxDate={disableFutureDates ? maxDate : undefined}
      // minDate={disabledPast ? minDate : undefined}
      // temp
      minDate={disabledPast && minTomorrowDate ? tomorrow : disabledPast ? minDate : undefined}
      disableFutureDates={disableFutureDates}
      helperText={generateErrorText(errors, field.name, touched)}
      error={!!errors[field.name] && touched}
      {...other}
      InputLabelProps={{
        shrink: true,
        className: classes.asterisk,
      }}
      disabled={disabled}
      Icon={Icon}
      tabIndex={index}
    />
  )
}

export const FormikDatePickerAtmeye = ({
  withoutCurrentDate,
  isRange,
  disabled,
  disabledPast = false,
  disableFutureDates,
  withoutArrayDate,
  minTomorrowDate,

  format,
  placeholder,
  label,
  index,
  className,

  Icon,
  field,
  form: { errors, setFieldValue, getFieldHelpers },
  meta: { touched },
  dynamicDateValue,

  setDynamicDate,
  ...other
}: FormikDatePickerProps): React.ReactElement => {
  const [toDateIsHidden, setToDateIsHidden] = useState(false)

  const maxDate = dynamicDateValue?.end ? new Date(dynamicDateValue.end) : new Date()

  const minDate = dynamicDateValue?.start ? new Date(dynamicDateValue.start) : new Date()

  const classes = useClasses()

  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)

  const updateDynamicDate = (date: string) => {
    if (setDynamicDate && disableFutureDates) {
      setDynamicDate(prevState => ({
        end: prevState.end,
        start: date,
      }))
    }

    if (setDynamicDate && disabledPast) {
      setDynamicDate(prevState => ({
        start: prevState.start,
        end: date,
      }))
    }
  }

  const [inputValue, setInputValue] = useState(
    index && field.value?.[index] ? field.value[index] : '',
  )

  const handleDateChange = useCallback(
    (momentDate, date): void => {
      setInputValue(date)

      if (date && !date.includes('_')) {
        if (isRange && index !== undefined) {
          if (index === 1) setToDateIsHidden(false)
          const isTimeChanges = date.length === 5
          const isDateChanges = date.length === 10
          const newValue = [...field.value]

          if (newValue[index]) {
            if (isTimeChanges) {
              const hour = date.substring(0, 2)
              const minute = date.substring(3, 5)

              const newDate = moment(newValue[index])
                .set('hour', hour)
                .set('minute', minute)
              const maxDate = moment(other.maxDate)

              if (maxDate.isBefore(newDate) && disableFutureDates) {
                newValue[index] = maxDate.format(DATE_TIME_SEC_FORMAT)
              } else {
                newValue[index] = newDate.format(DATE_TIME_SEC_FORMAT)
              }
            }

            if (isDateChanges) {
              const time = moment(newValue[index]).format(TIME_FORMAT)
              const newDate = moment(momentDate).format(DATE_FORMAT)

              newValue[index] = moment(`${newDate} ${time}`)
            }
          } else {
            newValue[index] = momentDate.isValid()
              ? momentDate.local()
              : getDate().format(DATE_TIME_SEC_FORMAT)
          }

          moment(newValue[index]).isValid() && setFieldValue(field.name, newValue, true)
          return
        } else {
          const defaultDate = (minTomorrowDate
            ? moment()
                .clone()
                .add(1, 'days')
            : moment()
          ).format(DATE_TIME_SEC_FORMAT)

          const fieldValue = momentDate.isValid()
            ? momentDate.format(DATE_TIME_SEC_FORMAT)
            : defaultDate

          getFieldHelpers(field.name).setTouched(true)
          setFieldValue(field.name, fieldValue, true)
          updateDynamicDate(fieldValue)
        }
      }

      if (momentDate?.isValid()) {
        const newState = field.value
        newState[index || 0] = momentDate.format(DATE_TIME_SEC_FORMAT)

        setFieldValue(field.name, newState, true)
      }
    },
    [
      setFieldValue,
      field.value,
      field.name,
      getFieldHelpers,
      index,
      isRange,
      minTomorrowDate,
      updateDynamicDate,
      other.maxDate,
    ],
  )

  const value = useMemo(() => {
    if (toDateIsHidden) {
      return null
    }

    if (withoutCurrentDate && index !== undefined && !field.value) {
      return null
    }

    if (isRange && index !== undefined) {
      return field.value?.[index] ? field.value?.[index] : null
    }

    return index && field.value?.[index] ? field.value[index] : null
  }, [field, toDateIsHidden, index, disableFutureDates, isRange, withoutCurrentDate])

  useEffect(() => {
    /* eslint-disable prefer-destructuring */
    const dateFrom = field.value[0]
    const dateTo = field.value[1]

    if (index && dateTo) {
      const newValue = [...field.value]

      if (dateTo && moment(dateTo).isBefore(moment(dateFrom))) {
        if (moment(other.minDate).isBefore(moment())) {
          newValue[index] = moment(dateFrom)
        } else {
          newValue[index] = moment()
        }
        setFieldValue(field.name, newValue, true)
      }
    }
  }, [field.value[0]])

  useEffect(() => {
    if (index === 1 && disabled) {
      setFieldValue(field.name, [null, null], true)
    }
  }, [index, disabled, field.name, setFieldValue])

  const handleBlur = useCallback((): void => {
    if (inputValue === null) {
      if (isRange && index !== undefined) {
        const newValue = [...field.value]
        newValue[index] = null
        setFieldValue(field.name, newValue, true)
      } else {
        setFieldValue(field.name, null, true)
        updateDynamicDate('')
      }
    } else {
      if (isRange && index !== undefined) {
        const currentDate = moment(field.value[index]).unix()
        const dateNow = getDate().unix()

        if (field.value[index] && disableFutureDates && dateNow < currentDate) {
          const newValue = [...field.value]
          newValue[index] = moment()

          setFieldValue(field.name, newValue, true)
        }

        if (other.minDate && moment(other.minDate).unix() > moment(field.value[index]).unix()) {
          const newValue = [...field.value]
          newValue[index] = moment(other.minDate)

          setFieldValue(field.name, newValue, true)
        }
      }
    }

    getFieldHelpers(field.name).setTouched(true)
  }, [
    getFieldHelpers,
    field.name,
    field.value,
    inputValue,
    isRange,
    index,
    setFieldValue,
    updateDynamicDate,
    disableFutureDates,
    other.minDate,
  ])

  return (
    <DateInput
      disabled={disabled}
      disableFutureDates={disableFutureDates}
      disablePast={disabledPast}
      name={field.name}
      label={label}
      format={format}
      placeholder={placeholder}
      className={className}
      tabIndex={index}
      value={value}
      helperText={generateErrorText(errors, field.name, touched)}
      error={!!errors[field.name] && touched}
      Icon={Icon}
      maxDate={disableFutureDates ? maxDate : undefined}
      minDate={disabledPast && minTomorrowDate ? tomorrow : disabledPast ? minDate : undefined}
      InputLabelProps={{
        shrink: true,
        className: classes.asterisk,
      }}
      onBlur={handleBlur}
      onChange={handleDateChange}
      onTimepickerClose={handleBlur}
      {...other}
    />
  )
}

export const createDateTimePickerRangeFieldTransactions = ({
  required = false,
  isAtemeyFilterModal,
  title,
  label,
  labelDate,
  format,
  titleClass,
  wrapperDateClass,
  widthClass,
  withoutCurrentDate,
  disabled,
  disableFutureDates = true,
  dateLimitForReports,
  params,
}: FormikDatePickerRangeProps) => (formikProps: FieldProps): React.ReactElement => {
  const classes = useClasses()

  const maxDateLimit = useMemo(() => {
    if (disableFutureDates) return new Date()

    if (formikProps.field.value[1] && disableFutureDates) {
      return new Date(formikProps.field.value[1])
    }

    return undefined
  }, [disableFutureDates, formikProps])

  const [dateFrom, dateTo] = formikProps.field.value
  const { clientId, deviceId } = formikProps.form.values
  const { transactionsSearchDays } = params || {}

  const maxDateTo = useMemo(() => {
    return transactionsSearchDays && !clientId?.length && !deviceId?.length
      ? new Date(
          moment.min([moment(dateFrom).add(transactionsSearchDays, 'd'), moment()]).toString(),
        )
      : null
  }, [dateFrom, transactionsSearchDays, clientId, deviceId?.length])

  useEffect(() => {
    const momentMaxDateTo = moment(maxDateTo)
    const momentDateTo = moment(dateTo || undefined)

    if (
      maxDateTo &&
      transactionsSearchDays &&
      momentMaxDateTo.isBefore(momentDateTo) &&
      Math.abs(momentDateTo.diff(moment(dateFrom), 'd')) > transactionsSearchDays
    ) {
      formikProps.form.setFieldValue(formikProps.field.name, [
        dateFrom,
        momentMaxDateTo.format(DATE_TIME_SEC_FORMAT),
      ])
    }
  }, [maxDateTo, dateTo])

  return (
    <div className={classes.range}>
      {title && <span className={titleClass}>{title}</span>}
      <div className={wrapperDateClass}>
        {isAtemeyFilterModal ? (
          <>
            <FormikDatePickerAtmeye
              {...formikProps}
              required={required}
              index={0}
              isRange
              format={format}
              className={clsx(classes.mr, widthClass)}
              maxDate={maxDateLimit}
              withoutCurrentDate={withoutCurrentDate}
              disabled={disabled}
              disableFutureDates={disableFutureDates}
            />

            <FormikDatePickerAtmeye
              {...formikProps}
              index={1}
              required={required}
              isRange
              format={format}
              className={clsx(classes.mr, widthClass)}
              minDate={formikProps.field.value && new Date(formikProps.field.value[0])}
              maxDate={maxDateTo || (!dateLimitForReports ? maxDateLimit : undefined)}
              withoutCurrentDate={withoutCurrentDate}
              initialFocusedDate={
                formikProps.field.value[0] && new Date(formikProps.field.value[0])
              }
              disabled={disabled || !formikProps.field.value[0]}
              disableFutureDates={disableFutureDates}
            />
          </>
        ) : (
          <>
            <FormikDatePicker
              {...formikProps}
              required={required}
              index={0}
              isRange
              format={format}
              className={clsx(classes.mr, widthClass)}
              maxDate={maxDateLimit}
              withoutCurrentDate={withoutCurrentDate}
              disabled={disabled}
              disableFutureDates={disableFutureDates}
            />

            <FormikDatePicker
              {...formikProps}
              index={1}
              required={required}
              isRange
              format={format}
              className={clsx(classes.mr, widthClass)}
              minDate={formikProps.field.value && new Date(formikProps.field.value[0])}
              maxDate={!dateLimitForReports ? maxDateLimit : undefined}
              withoutCurrentDate={withoutCurrentDate}
              initialFocusedDate={
                formikProps.field.value[0] && new Date(formikProps.field.value[0])
              }
              disabled={disabled || !formikProps.field.value[0]}
              disableFutureDates={disableFutureDates}
            />
          </>
        )}
      </div>
    </div>
  )
}

export const createDateTimePickerRangeField = ({
  required = false,
  isAtemeyFilterModal,
  title,
  label,
  labelDate,
  format,
  titleClass,
  wrapperDateClass,
  widthClass,
  withoutCurrentDate,
  disabled,
  disableFutureDates = true,
  dateLimitForReports,
}: FormikDatePickerRangeProps) => (formikProps: FieldProps): React.ReactElement => {
  const classes = useClasses()

  const maxDateLimit = useMemo(() => {
    if (disableFutureDates) return new Date()

    if (formikProps.field.value[1] && disableFutureDates) {
      return new Date(formikProps.field.value[1])
    }

    return undefined
  }, [disableFutureDates, formikProps])

  return (
    <div className={classes.range}>
      {title && <span className={titleClass}>{title}</span>}
      <div className={wrapperDateClass}>
        {isAtemeyFilterModal ? (
          <>
            <FormikDatePickerAtmeye
              {...formikProps}
              required={required}
              index={0}
              isRange
              format={format}
              className={clsx(classes.mr, widthClass)}
              maxDate={maxDateLimit}
              withoutCurrentDate={withoutCurrentDate}
              disabled={disabled}
              disableFutureDates={disableFutureDates}
            />

            <FormikDatePickerAtmeye
              {...formikProps}
              index={1}
              required={required}
              isRange
              format={format}
              className={clsx(classes.mr, widthClass)}
              minDate={formikProps.field.value && new Date(formikProps.field.value[0])}
              maxDate={!dateLimitForReports ? maxDateLimit : undefined}
              withoutCurrentDate={withoutCurrentDate}
              initialFocusedDate={
                formikProps.field.value[0] && new Date(formikProps.field.value[0])
              }
              disabled={disabled || !formikProps.field.value[0]}
              disableFutureDates={disableFutureDates}
            />
          </>
        ) : (
          <>
            <FormikDatePicker
              {...formikProps}
              required={required}
              index={0}
              isRange
              format={format}
              className={clsx(classes.mr, widthClass)}
              maxDate={maxDateLimit}
              withoutCurrentDate={withoutCurrentDate}
              disabled={disabled}
              disableFutureDates={disableFutureDates}
            />

            <FormikDatePicker
              {...formikProps}
              index={1}
              required={required}
              isRange
              format={format}
              className={clsx(classes.mr, widthClass)}
              minDate={formikProps.field.value && new Date(formikProps.field.value[0])}
              maxDate={!dateLimitForReports ? maxDateLimit : undefined}
              withoutCurrentDate={withoutCurrentDate}
              initialFocusedDate={
                formikProps.field.value[0] && new Date(formikProps.field.value[0])
              }
              disabled={disabled || !formikProps.field.value[0]}
              disableFutureDates={disableFutureDates}
            />
          </>
        )}
      </div>
    </div>
  )
}

export const createDatePickerField = ({
  label,
  format,
  widthClass,
  wrapperDateClass,
  disabled,
  required,
  withoutCurrentDate,
  placeholder,
  disabledPast,
  disableFutureDates = true,
  withoutArrayDate,
  dynamicDateValue,
  Icon,
  setDynamicDate,
  minTomorrowDate,
  index,
  autoOpenTimePiker,
}: FormikDatePickerFieldProps) => (formikProps: FieldProps): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  return (
    <div className={wrapperDateClass}>
      <FormikDatePicker
        {...formikProps}
        index={index || 0}
        format={format}
        label={label || translate('translate#title.from')}
        className={clsx(classes.mr, widthClass)}
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        withoutCurrentDate={withoutCurrentDate}
        disableFutureDates={disableFutureDates}
        disabledPast={disabledPast}
        withoutArrayDate={withoutArrayDate}
        dynamicDateValue={dynamicDateValue}
        Icon={Icon}
        setDynamicDate={setDynamicDate}
        minTomorrowDate={minTomorrowDate}
        autoOpenTimePiker={autoOpenTimePiker}
      />
    </div>
  )
}
