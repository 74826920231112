import React, { ChangeEvent, ReactElement, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PlasticCard } from '@/api/cm/swaggerGeneratedApi'
import { CMTable, usePrepareTableConfig } from '@/components/blocks/cm/CMTable'
import {
  checkAllPlasticCard,
  checkPlasticCard,
  clearAllPlasticCard,
  getPlcCardChecked,
  getPlcCardData,
  getPlcCardSelected,
  setSelectedPlasticCard,
  sortPlasticCard,
} from '@/store/cm/plasticCard'

import { conditionallyRenderCell } from './columnRenderConfig'
import { columnsToSort, headerConfig } from './tableConfig'
import { Props } from './types'

export const isItemEnable = (item: PlasticCard): boolean => !!item.teamId && !item.workOrderId

const MainTable = ({ tableId }: Props): ReactElement => {
  const dispatch = useDispatch()

  const tableConfig = usePrepareTableConfig({
    rowId: 'cpId',
    tableId,
    headerConfig,
    columnsToSort,
    fitTableContent: true,
  })

  const { data, isLoading, isLoaded } = useSelector(getPlcCardData)
  const selected = useSelector(getPlcCardSelected)
  const checked = useSelector(getPlcCardChecked)

  const enabledData = useMemo(() => data.filter(item => isItemEnable(item)), [data])

  const handleSort = useCallback(
    (sortColumn, sortOrder) => {
      dispatch(sortPlasticCard({ sortColumn, sortOrder }))
    },
    [dispatch, sortPlasticCard],
  )

  const handleSelectAllItems = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatch(checkAllPlasticCard())
    } else {
      dispatch(clearAllPlasticCard())
    }
  }, [])

  const handleSetSelectedItemId = useCallback(
    (rowInfo: PlasticCard): void => {
      dispatch(checkPlasticCard(rowInfo))
    },
    [dispatch, checkPlasticCard],
  )

  const handleRowClick = useCallback(
    (row: PlasticCard) => {
      dispatch(setSelectedPlasticCard(row))
    },
    [dispatch, setSelectedPlasticCard],
  )

  const checkboxCondition = useCallback((item: PlasticCard) => {
    return isItemEnable(item)
  }, [])

  const conditionallyRenderCellMemo = useMemo(() => conditionallyRenderCell, [])

  return (
    <CMTable
      {...tableConfig}
      data={data || []}
      isLoaded={isLoaded}
      isLoading={isLoading}
      dataWithCheckbox={enabledData}
      conditionallyRenderCell={conditionallyRenderCellMemo}
      selectedRowId={selected?.cpId}
      checkedRows={checked}
      handleSort={handleSort}
      onRowClick={handleRowClick}
      checkboxCondition={checkboxCondition}
      onSelectAll={handleSelectAllItems}
      handleCheckRow={handleSetSelectedItemId}
    />
  )
}

export default MainTable
