import { Dispatch, SetStateAction } from 'react'

import { UserInformationTableTypes } from '@/components/pages/usm/UsersPage/components/UserInformation/types'
import { DataItem } from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/components/EditableTable/types'

export type Props = {
  open: boolean
  handleClose: () => void
  selectedLastRow: UserInformationTableTypes | null | DataItem
  title: string
  setSelectedRows: Dispatch<SetStateAction<DataItem[]>>
  isFetching?: boolean
}

export enum UserInformationField {
  Company = 'companyId',
  UserName = 'userName',
  UserGroup = 'userGroupId',
  EMail = 'email',
  ExpiryDate = 'expireDate',
  NeverExpires = 'neverExpires',
  Disabled = 'disabled',
  AllowAutoProlong = 'allowAutoProlong',
  RoleId = 'roleId',
  RoleName = 'roleName',
}

export type UserInfoInstallationValues = {
  [UserInformationField.Company]: string
  [UserInformationField.UserName]: string
  [UserInformationField.UserGroup]: string
  [UserInformationField.EMail]: string
  [UserInformationField.ExpiryDate]: string
  [UserInformationField.NeverExpires]: boolean
  [UserInformationField.Disabled]: boolean
  [UserInformationField.AllowAutoProlong]: boolean
  [UserInformationField.RoleId]: string
  [UserInformationField.RoleName]: string
}
