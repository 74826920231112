export const DEFAULT_BASE_URL = 'https://phoenix.iq-dev.k8.bs2.lt'
const DEFAULT_BASE_URL_FOR_LOGIN = 'https://phoenix.iq-dev.k8.bs2.lt/api/v1/account'
const DEFAULT_VAULT_BASE_URL = 'https://vault.iq-dev.k8.bs2.lt'
const DEFAULT_CM_BASE_URL = ' https://develop-cm.bs2.lt:8054/rest'
// const DEFAULT_CM_BASE_URL = ' https://develop-cm.bs2.lt:8073/rest'
// const DEFAULT_CM_BASE_URL = 'https://213.159.63.12:8050/rest'
// const DEFAULT_CM_BASE_URL = 'https://uat-cm.bs2.lt:8051/rest'
const DEFAULT_USM_BASE_URL = 'https://phoenix.iq-dev.k8.bs2.lt'
const DEFAULT_ADM_BASE_URL = 'https://adm.iq-dev.k8.bs2.lt'
const DEFAULT_VAULT_V1_BASE_URL = 'https://develop-cm.bs2.lt:8061/rest'
// const DEFAULT_VAULT_V1_BASE_URL = ' https://develop-cm.bs2.lt:8072/rest'
const DEFAULT_TRANSLATIONS_URL = '/core-common/api/v1/translations'

const DEFAULT_VAULT_V2_BASE_URL = 'https://develop-cm.bs2.lt:8061/rest'

const DEFAULT_SOCKET_URL = 'wss://phoenix.iq-dev.k8.bs2.lt/wss'

const DEFAULT_CM_SOCKET_URL = 'wss://develop-cm.bs2.lt:8065/socket'

const GLOBAL = window.GLOBAL_URLS

const MissedKeys = function() {
  return {}
}
window.missedKeys = new MissedKeys()
window.missedKeys.toString = function() {
  return JSON.stringify(this)
}

export const { missedKeys } = window

export const isAtmeyeScreenMonitoringEnabled =
  GLOBAL?.REACT_APP_IS_ATMEYE_SCREEN_MONITORING_ENABLED ||
  process?.env?.REACT_APP_IS_ATMEYE_SCREEN_MONITORING_ENABLED

export const UPLOAD_SIZE_LIMIT =
  GLOBAL?.REACT_APP_UPLOAD_SIZE_LIMIT || process?.env?.REACT_APP_UPLOAD_SIZE_LIMIT

export class APIConfiguration {
  static API_BASE_URL = `${GLOBAL?.REACT_APP_SD_EDGE_URL ||
    process?.env?.REACT_APP_SD_EDGE_URL ||
    DEFAULT_BASE_URL}`

  static API_BASE_URL_V2 = `${GLOBAL?.REACT_APP_SD_EDGE_URL ||
    process?.env?.REACT_APP_SD_EDGE_URL ||
    DEFAULT_BASE_URL}/api/v2`

  static API_MS_REPORT_URL = `${GLOBAL?.REACT_APP_SD_EDGE_URL ||
    process?.env?.REACT_APP_SD_EDGE_URL ||
    DEFAULT_BASE_URL}/tickets/api/v1/ReportServer/ms-reports`

  static VAULT_API_BASE_URL = `${process?.env?.REACT_APP_VAULT_URL ||
    GLOBAL?.REACT_APP_VAULT_URL ||
    DEFAULT_VAULT_BASE_URL}/api/v1`

  static CM_API_BASE_URL = `${process?.env?.REACT_APP_CM_REST_SERVER ||
    process?.env?.REACT_APP_CM_URL ||
    GLOBAL?.REACT_APP_CM_REST_SERVER ||
    DEFAULT_CM_BASE_URL}/v2`

  static API_BSE_URL_USM_LOGIN = `${GLOBAL?.REACT_APP_USM_SERVER_URL ||
    process?.env?.REACT_APP_USM_SERVER_URL ||
    DEFAULT_BASE_URL_FOR_LOGIN}`

  static USM_API_BASE_URL = `${GLOBAL?.REACT_APP_USM_EDGE_URL ||
    process?.env?.REACT_APP_USM_EDGE_URL ||
    DEFAULT_USM_BASE_URL}/usm/api/v1`

  static VAULT_V1_API_BASE_URL = `${process?.env?.REACT_APP_VAULT_V1_REST_SERVER ||
    process?.env?.REACT_APP_VAULT_V1_URL ||
    GLOBAL?.REACT_APP_VAULT_V1_REST_SERVER ||
    DEFAULT_VAULT_V1_BASE_URL}/v2`

  static VAULT_V2_API_BASE_URL = `${process?.env?.REACT_APP_VAULT_V2_REST_SERVER ||
    process?.env?.REACT_APP_VAULT_V2_URL ||
    GLOBAL?.REACT_APP_VAULT_V2_REST_SERVER ||
    DEFAULT_VAULT_V2_BASE_URL}/v2`

  static WSS_SOCKET_API_BASE_URL = `${GLOBAL?.REACT_APP_WSS_SOCKET_EDGE_URL ||
    process?.env?.REACT_APP_WSS_SOCKET_EDGE_URL ||
    DEFAULT_SOCKET_URL}`

  static DICTIONARY_API_BASE_URL = `${GLOBAL?.REACT_APP_DICTIONARY_URL ||
    process?.env?.REACT_APP_DICTIONARY_URL ||
    DEFAULT_BASE_URL}`

  static CM_WSS_SOCKET_API_URL = `${GLOBAL?.REACT_APP_CM_WSS_SOCKET_URL ||
    process?.env?.REACT_APP_CM_WSS_SOCKET_URL ||
    DEFAULT_CM_SOCKET_URL}`

  static DEFAULT_TRANSLATIONS_URL = `${GLOBAL?.REACT_APP_DEFAULT_TRANSLATIONS_URL ||
    process?.env?.REACT_APP_DEFAULT_TRANSLATIONS_URL ||
    DEFAULT_TRANSLATIONS_URL}`
}
