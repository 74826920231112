import makeStyles from '@material-ui/core/styles/makeStyles'
import styled from 'styled-components'

import { styledComponentTheme } from '@/theme/styledComponentTheme'

export const useClasses = makeStyles(({ spacing }) => ({
  titleCell: {
    padding: 0,
    border: 'none',
  },
  tableTitle: {
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
    fontSize: '20px',
    marginBottom: '0',
  },
  deviceHeader: {
    height: '100%',
    overflowY: 'auto',
  },
  cellWrapper: {
    padding: spacing(0.25, 1),
    marginRight: spacing(1),
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: spacing(1.5),
  },
  cellIcon: {
    width: spacing(1.5),
    marginRight: spacing(1),
  },
  iconSize: {
    fontSize: styledComponentTheme.fontSizes.big,
  },

  dotOk: {
    color: '#1AD36F',
  },
  dotWarning: {
    color: 'orange',
  },
  dotError: {
    color: '#CB362D',
  },
  folderOk: {
    background: '#e6faf0',
  },
  folderWarning: {
    background: 'antiquewhite',
  },
  folderError: {
    background: '#ffe9ed',
  },

  tableCell: {
    borderBottom: 'none',
    padding: '8px 16px',
    '&:first-child': {
      paddingLeft: '20px',
    },
  },
  tableCellTitle: {
    fontWeight: 600,
  },
  tableWrapper: {
    boxShadow: 'none',
  },
  tableWrap: {},
  table: {
    width: 'auto',
    height: '100%',
    boxShadow: 'none',
  },

  tableHeader: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },

  tableHeaderButton: {
    border: '1px solid',
    borderColor: styledComponentTheme.colors.baseGreen,
    width: '40px',
    height: '40px',
    padding: '0',
    minWidth: 'auto',
    marginRight: '16px',

    '& .MuiButton-startIcon': {
      margin: '0',
    },
  },

  tableHeaderButtonDisabled: {
    opacity: 0.4,
  },

  toolTip: {
    background: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    color: 'black',
    padding: spacing(1),
    fontSize: '14px',
    top: spacing(1),
  },
}))

export const ContentWrapper = styled.div`
  background-color: #ffffff;
  flex: 1;
  height: calc(100% - 20px);
  width: 100%;
  box-shadow: 0 0 15px rgba(37, 173, 191, 0.05);
  border-radius: 3px;
  padding: 10px 24px;
  margin-top: 20px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
