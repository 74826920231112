import React, { useCallback, useEffect, useState } from 'react'
import ContextMenu from '@/components/controls/ContextMenu/index'
import { Props } from './types'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@/components/controls/Button/index'

import { useClasses } from './styles'
import Typography from '@material-ui/core/Typography'
import FilterContextMenuItem from '@/components/controls/AppTable/components/FilterContextMenuItem/component'
import Test from 'react-test-attributes'
import { getTestId } from '@/utils'
import { FormattedMessage } from 'react-intl'
import { useTranslate } from '@/utils/internalization'
import CloseButton from '@/components/blocks/CloseButton'

const ColumnsMenu = ({
  testIdPrefix,
  fields,
  currentFields,
  anchorEl,
  setTableSettingsToLocalStorage,
  handleSave,
  handleClose,
}: Props): React.ReactElement => {
  const [selected, setSelected] = useState<string[]>(fields)
  const classes = useClasses()
  const idPrefix = `${testIdPrefix}-columns-menu`
  const testId = getTestId(idPrefix)
  const translate = useTranslate()

  useEffect((): void => {
    setSelected(currentFields)
  }, [currentFields])

  const handleChange = useCallback(
    (key: string): (() => void) => (): void => {
      setSelected(prevSelected => {
        if (prevSelected.includes(key)) {
          return prevSelected.filter(val => val !== key)
        }
        return [...prevSelected, key]
      })
    },
    [],
  )
  const handleSaveClick = useCallback((): void => {
    const filteredFields = [...fields].filter(field => selected.includes(field))

    handleSave(filteredFields)

    if (setTableSettingsToLocalStorage) {
      setTableSettingsToLocalStorage(filteredFields, fields)
    }

    handleClose()
  }, [fields, currentFields, selected, handleSave, setTableSettingsToLocalStorage, handleClose])

  const handleCancelClick = useCallback((): void => {
    setSelected(currentFields)
    handleClose()
  }, [currentFields, handleClose])

  const handleSetAll = useCallback((): void => {
    setSelected(prevSelected => {
      return prevSelected.length === fields.length ? [] : [...fields.map(val => val)]
    })
  }, [fields])

  return (
    <Test id={testId(0)}>
      <ContextMenu
        anchorEl={anchorEl}
        limitMenuSize={false}
        disableRipple
        anchorOrigin={undefined}
        transformOrigin={undefined}
        options={[
          ...fields.map(value => {
            return {
              content: <FilterContextMenuItem name={value} checked={selected.includes(value)} />,
              handleClick: handleChange(value),
            }
          }),
        ]}
        className={classes.menu}
        handleClose={handleCancelClick}
        header={
          <>
            <Test id={testId(1)}>
              <Typography className={classes.menuSizeAnchor} variant="h6">
                <FormattedMessage id="table.header.button.columns" defaultMessage="Columns" />
              </Typography>
            </Test>
            <Test id={testId(2)}>
              <CloseButton absolute className={classes.closeButton} onClick={handleCancelClick} />
            </Test>
          </>
        }
      >
        <>
          <Divider className={classes.divider} variant="middle" />
          <Test id={testId(3)}>
            <MenuItem disableRipple onClick={handleSetAll}>
              <FilterContextMenuItem
                name={translate('translate#title.selectAll')}
                checked={selected.length === fields.length}
              />
            </MenuItem>
          </Test>
          <Test id={testId(4)}>
            <div className={classes.buttonWrapper}>
              <Test id={testId(5)}>
                <Button
                  className={classes.buttonDisabled}
                  variant="contained"
                  size="large"
                  onClick={handleCancelClick}
                >
                  <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
                </Button>
              </Test>
              <Test id={testId(5)}>
                <Button
                  className={classes.button}
                  variant="contained"
                  disabled={!selected.length}
                  size="large"
                  onClick={handleSaveClick}
                >
                  <FormattedMessage id="action.apply" defaultMessage="Apply" />
                </Button>
              </Test>
            </div>
          </Test>
        </>
      </ContextMenu>
    </Test>
  )
}

export default ColumnsMenu
