import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getOrdersState = (state: AppState): fromReducer.State => state.cmOrders

export const getOrders = createSelector(getOrdersState, state => state.orders)
export const getSelectedOrder = createSelector(getOrdersState, state => state.orders.selectedOrder)

export const getOrdersFilter = createSelector(getOrdersState, state => state.orders.filter)

export const getOrdersStages = createSelector(getOrdersState, state => state.orders.stages)

export const getPoliceDrivers = createSelector(getOrdersState, state => state.drivers)
export const getPoliceOperations = createSelector(getOrdersState, state => state.operations)
export const getPoliceStatuses = createSelector(getOrdersState, state => state.opsStatuses)

export const getModalOpenStateSelector = createSelector(
  getOrdersState,
  ({
    isFiltersModalOpen,
    isLoadingModalOpen,
    isSettingsModalOpen,
    isDocumentsModalOpen,
    isDeclineModalOpen,
  }) => ({
    isFiltersModalOpen,
    isLoadingModalOpen,
    isSettingsModalOpen,
    isDocumentsModalOpen,
    isDeclineModalOpen,
  }),
)
