import queryString from 'query-string'

import { AdmLimitsObjects, AdmVaultLimit } from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { Sort } from '@/types/cm'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

interface Ids {
  branchId?: string
  vaultId?: string
}

interface GetAllProps extends Ids {
  sort: Sort
}

interface AddProps extends Ids {
  limit: AdmVaultLimit
}

interface UpdateProps extends Ids {
  limit: AdmVaultLimit
}

export class LimitsAdminApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getAll({ sort, branchId, vaultId }: GetAllProps): Promise<AdmVaultLimit[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/vaultLimits',
          query: { branchId, vaultId, ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await LimitsAdminApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add({ limit, vaultId, branchId }: AddProps): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/vaultLimits/update',
          query: { branchId, vaultId },
        },
        { skipNull: true, encode: false },
      )

      return await LimitsAdminApi.requestService.post(url, limit)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update({ limit, vaultId, branchId }: UpdateProps): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/vaultLimits/update',
          query: { branchId, vaultId },
        },
        { skipNull: true, encode: false },
      )

      return await LimitsAdminApi.requestService.post(url, limit)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getOptions(): Promise<AdmLimitsObjects> {
    try {
      return await LimitsAdminApi.requestService.get('/admin/vaultLimits/objects')
    } catch (error) {
      throw Error(error)
    }
  }
}
