import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, putResolve, select, takeLatest } from 'redux-saga/effects'

import { DenominationsAdminApi } from '@/api/vault-v1/adminApi'
import { AdmDenomination } from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants'
import { setComVaultV1Error } from '@/store/vault-v1/common'

import {
  addAdmDenominationFail,
  deleteAdmDenominationFail,
  getAdmDenominationCurrenciesResponse,
  getAdmDenominationsFail,
  getAdmDenominationsRequest,
  getAdmDenominationsResponse,
  getVaultsAdmDenominationsSort,
  getVaultsAdmSelectedDenomination,
  setAdmDenominationSelected,
  updateAdmDenominationFail,
} from '.'

// [START] Denominations --------------------------------------
function* getDenominations(): SagaIterator {
  try {
    const sort = yield select(getVaultsAdmDenominationsSort)
    const selected = yield select(getVaultsAdmSelectedDenomination)

    const denominations: AdmDenomination[] = yield call(DenominationsAdminApi.getAll, sort)

    yield put(getAdmDenominationsResponse(denominations))

    if (denominations && selected) {
      const updatedDenomination = denominations.find(
        (denomination: AdmDenomination) => denomination.id === selected.id,
      )

      if (updatedDenomination) {
        yield putResolve(setAdmDenominationSelected(updatedDenomination))
      } else {
        yield putResolve(setAdmDenominationSelected(null))
      }
    }
  } catch (error) {
    yield put(getAdmDenominationsFail())
  }
}

function* addDenomination({ payload }: AnyAction): SagaIterator {
  try {
    yield call(DenominationsAdminApi.add, payload)

    yield put(getAdmDenominationsRequest())
  } catch (error) {
    yield put(addAdmDenominationFail())
  }
}

function* updateDenomination({ payload }: AnyAction): SagaIterator {
  try {
    yield call(DenominationsAdminApi.update, payload)

    yield put(getAdmDenominationsRequest())
  } catch (error) {
    yield put(updateAdmDenominationFail())
  }
}

function* deleteDenomination(): SagaIterator {
  try {
    const selected = yield select(getVaultsAdmSelectedDenomination)

    yield call(DenominationsAdminApi.delete, selected.id)

    yield put(getAdmDenominationsRequest())
  } catch (error) {
    yield put(deleteAdmDenominationFail())
  }
}

function* getCurrencies(): SagaIterator {
  try {
    const currencies = yield call(DenominationsAdminApi.getCurrencies)

    yield put(getAdmDenominationCurrenciesResponse(currencies))
  } catch (error) {
    yield put(setComVaultV1Error(error))
  }
}
// [END] Denominations --------------------------------------

export default function*(): Generator {
  yield takeLatest(
    [VaultV1Actions.AdmGetDenominationsRequest, VaultV1Actions.AdmSortDenominations],
    getDenominations,
  )

  yield takeLatest(VaultV1Actions.AdmAddDenominationRequest, addDenomination)
  yield takeLatest(VaultV1Actions.AdmUpdateDenominationRequest, updateDenomination)
  yield takeLatest(VaultV1Actions.AdmDeleteDenominationRequest, deleteDenomination)
  yield takeLatest(VaultV1Actions.AdmGetDenominationCurrenciesRequest, getCurrencies)
}
