import { ApiGroupTemplateResponse, GetTicketsBodyFilterItem } from '@/api/sd/ticketsApi/types'
import { groupTemplateUiToApiView } from '@/components/pages/sd/helpers/sdHelpers'
import { APIConfiguration } from '@/constants'
import { ResponseError, TicketsConfigResponse, UIGroupTemplate } from '@/types'
import { GetTicketsResponse } from '@/types/sd/common'
import { getRequestService } from '@/utils/services/request'

import {
  AvailableModule,
  GetExportBody,
  GetExportGroupedBody,
  GetGroupDetailsBody,
  GetTicketsBody,
  GroupTemplate,
} from './types'

export const deepClone = (obj: any) => {
  if (obj === null) return null
  const clone = Object.assign({}, obj)
  Object.keys(clone).forEach(key => {
    clone[key] = typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key]
    if (key === 'childGroups' && !Array.isArray(clone[key])) {
      clone[key] = []
    }
  })
  if (Array.isArray(obj)) {
    clone.length = obj.length
    return Array.from(clone)
  }
  return clone
}

export class NewCommonApi {
  private static requestServiceV1 = getRequestService(APIConfiguration.API_BASE_URL)
  private static requestServiceV2 = getRequestService(APIConfiguration.API_BASE_URL_V2)

  public static async getAvailableLicences(): Promise<{ [key: string]: AvailableModule }> {
    try {
      const { licenseModules } = await NewCommonApi.requestServiceV1.get(
        `/licensor/api/v1/license-modules/available`,
      )

      return licenseModules.reduce(
        (acc: { [key: string]: AvailableModule }, module: AvailableModule) => {
          acc[module.moduleType] = module
          return acc
        },
        {} as { [key: string]: AvailableModule },
      )
    } catch (e) {
      throw new Error(e.message)
    }
  }

  // V2

  public static async getData(
    getTicketsBody: GetTicketsBody,
  ): Promise<GetTicketsResponse | ResponseError> {
    try {
      const response = await NewCommonApi.requestServiceV2.post(`search-form/list`, getTicketsBody)

      return deepClone(response)
    } catch (e) {
      throw new Error(e.message)
    }
  }

  public static async getPagination(
    getTicketsBody: GetTicketsBody,
  ): Promise<GetTicketsResponse | ResponseError> {
    try {
      return await NewCommonApi.requestServiceV2.post(`search-form/list/pagination`, getTicketsBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getGroupedDetails(
    getTicketsBody: GetGroupDetailsBody | any,
  ): Promise<any | ResponseError> {
    try {
      return await NewCommonApi.requestServiceV2.post(`/search-form/group-details`, getTicketsBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getGroupedDetailsPagination(
    getTicketsBody: GetGroupDetailsBody,
  ): Promise<any | ResponseError> {
    try {
      return await NewCommonApi.requestServiceV2.post(
        `/search-form/group-details/pagination`,
        getTicketsBody,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getExportData(
    getTicketsBody: GetExportBody,
    buffer?: boolean,
  ): Promise<GetTicketsResponse | ResponseError> {
    try {
      return await NewCommonApi.requestServiceV2.post(
        `search-form/export`,
        getTicketsBody,
        true,
        buffer && { responseType: 'arraybuffer' },
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getExportGroupedData(
    getTicketsBody: GetExportGroupedBody,
    buffer?: boolean,
  ): Promise<GetTicketsResponse | ResponseError> {
    try {
      return await NewCommonApi.requestServiceV2.post(
        `search-form/grouped-export`,
        getTicketsBody,
        true,
        buffer && { responseType: 'arraybuffer' },
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getFormConfig(data: {
    formUID: string
    getConfigURL: string
  }): Promise<TicketsConfigResponse | ResponseError> {
    try {
      return NewCommonApi.requestServiceV2.post('/search-form/config', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async editNotes(data: {
    fieldId: string
    fieldValue: string
    formType: string
    rowId: string
  }): Promise<any> {
    try {
      const { fieldId, fieldValue, formType, rowId } = data

      return NewCommonApi.requestServiceV2.put('/search-form/edit-field', {
        fieldId,
        fieldValue,
        formType,
        rowId,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  // V1

  public static createFilterTemplate = async (payload: {
    filters: GetTicketsBodyFilterItem[]
    name: string
    windowType: string
  }): Promise<{ availableTemplates: { id: string; name: string } } | ResponseError> =>
    await NewCommonApi.requestServiceV1.post('/api/v1/filter-templates', payload)

  public static getFilterTemplates = async (
    windowType: string,
  ): Promise<{ id: string; name: string }[] | ResponseError> =>
    await NewCommonApi.requestServiceV1.get('/api/v1/filter-templates', { windowType })

  public static updateFilterTemplate = async (payload: {
    id: string
    windowType: string
    name: string
    filters: any
  }): Promise<{ availableTemplates: { id: string; name: string }[] } | ResponseError> => {
    const { filters, name, windowType, id } = payload

    return await NewCommonApi.requestServiceV1.put(`/api/v1/filter-templates/${id}`, {
      filters,
      name,
      windowType,
    })
  }

  public static deleteFilterTemplate = async (payload: {
    id: string
    windowType: string
  }): Promise<any> => {
    const { id, windowType } = payload

    return await NewCommonApi.requestServiceV1.delete(
      `/api/v1/filter-templates/${id}?windowType=${windowType}`,
      true,
    )
  }

  public static async createGroupTemplate(payload: GroupTemplate): Promise<{} | ResponseError> {
    try {
      return await NewCommonApi.requestServiceV1.post('/api/v1/grouping-templates', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getGroupTemplates(
    windowType: string,
  ): Promise<{ availableTemplates: { id: string; name: string }[] } | ResponseError> {
    try {
      return await NewCommonApi.requestServiceV1.get('/api/v1/grouping-templates', {
        windowType,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async editGroupTemplate(payload: {
    template: UIGroupTemplate
    windowType: string
  }): Promise<ApiGroupTemplateResponse | ResponseError> {
    const {
      template: { id, name, config },
      windowType,
    } = payload

    return await NewCommonApi.requestServiceV1.put(`/api/v1/grouping-templates/${id}`, {
      groupingLevels: groupTemplateUiToApiView(config),
      name,
      windowType,
    })
  }

  public static deleteGroupTemplate = async (payload: {
    id: string
    windowType: string
  }): Promise<{} | ResponseError> => {
    const { id, windowType } = payload

    return await NewCommonApi.requestServiceV1.delete(
      `/api/v1/grouping-templates/${id}?windowType=${windowType}`,
    )
  }

  public static async getMedia(data: {
    eventId: number
    eventType: string
    eventDate?: string
  }): Promise<any> {
    try {
      const { eventId, eventType, eventDate } = data

      return NewCommonApi.requestServiceV1.get(
        '/atmeye/api/v1/events/media',
        { eventId, eventType, eventDate },
        {},
      )
    } catch (e) {
      throw new Error(e)
    }
  }
}
