import { Fade } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import React from 'react'

import { translate } from '@/utils/internalization'

const Alert = (props: AlertProps): React.ReactElement => {
  return <MuiAlert elevation={6} {...props} />
}

interface State {
  queue: Array<{ text: string; type: 'success' | 'info' | 'warning' | 'error'; data?: string[] }>
  text: string
  type: 'success' | 'info' | 'warning' | 'error'
  open: boolean
  data?: string[]
}

const initialState: State = {
  queue: [],
  text: '',
  type: 'success',
  open: false,
  data: [],
}

class GlobalPopUp extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props)
    this.state = initialState

    this.show = this.show.bind(this)
  }

  private handleClose = (): void => {
    this.setState({ open: false })
  }

  private shiftQueue = (): void => {
    this.setState(prevState => {
      const queue = [...prevState.queue]
      const nextInLine = queue.shift()
      if (nextInLine) {
        return {
          text: nextInLine.text,
          type: nextInLine.type,
          data: nextInLine.data,
          open: true,
          queue,
        }
      } else {
        return prevState
      }
    })
  }

  public show(
    text: string,
    type?: 'success' | 'info' | 'warning' | 'error',
    data?: string[],
  ): void {
    this.setState(prevState => ({
      queue: [...prevState.queue, { text, type: type || 'success', data }],
    }))
  }

  public render(): React.ReactElement {
    const { open, text, type, queue, data } = this.state
    if (!open && queue.length) {
      window.setTimeout(this.shiftQueue, 300)
    }
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        TransitionComponent={Fade}
        transitionDuration={300}
        autoHideDuration={6000}
        onClose={this.handleClose}
      >
        <Alert onClose={this.handleClose} severity={type} style={{ whiteSpace: 'pre' }}>
          {translate(text, data)}
        </Alert>
      </Snackbar>
    )
  }
}

export default GlobalPopUp
