import clsx from 'clsx'
import { Field } from 'formik'
import _ from 'lodash'
import { isEqual } from 'lodash-es'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PosApi } from '@/api/sd/posApi'
import { NumberValueClasses } from '@/components/blocks/POSRequestForm/utils'
import { createCheckboxField } from '@/components/controls/Checkbox/component'
import { createCheckboxGroupField } from '@/components/controls/CheckboxGroup/component'
import { createDatePickerField } from '@/components/controls/DateInput/component'
import { createDropDownInputField } from '@/components/controls/DropDownInput/component'
import { createTextInputField } from '@/components/controls/TextInput/component'
import { IMPOSSIBLE_TO_GET_TRANSLATE } from '@/constants/internalization'
import { getCurrentCompany } from '@/store/auth/selectors'
import {
  getNotSavedServiceTypeEditIndex,
  getNotSavedServiceTypeItems,
  getRequestItems,
  setMCCCodeInitialRequestDataFragment,
} from '@/store/sd/drawerActions'
import { EnableModes } from '@/types'
import { useTranslate } from '@/utils/internalization'

import { MultipleSelect } from './components/multiSelect'
import { useClasses } from './styles'
import { POSServiceFieldTypes, Props } from './types'

const DynamicField = ({
  param,
  formValues,
  disabledFieldNames,
  setFormikFieldValue,
  setFieldTouched,
}: Props): React.ReactElement => {
  const {
    displayTypeId,
    displayName: initDisplayName,
    required,
    description,
    existsLookupValues,
    attributeId,
    attributeName,
    enabled,
    valueClass,
    dependedOn,
    enableMode,
    multiple,
    dispRowCount,
    dependedToSetOnlyOneFrom,
    allowRecursiveCallBySearchFragment,
  } = param
  const [options, setOptions] = useState<{ name: string; value: string }[]>([])
  const [prevDepsValues, setPrevDepsValues] = useState<Array<string | number | null>>([])
  const classes = useClasses()
  const userCompanyName = useSelector(getCurrentCompany)
  const requestItems = useSelector(getRequestItems)
  const notSaveItemsPosItems = useSelector(getNotSavedServiceTypeItems)
  const notSaveItemsPosItemsIndex = useSelector(getNotSavedServiceTypeEditIndex)

  const translate = useTranslate()

  const dispatch = useDispatch()

  const prepareDisplayName = (initDisplayName: string): string => {
    const displayNameProps = IMPOSSIBLE_TO_GET_TRANSLATE.find(({ displayName }) => {
      return displayName === initDisplayName
    })

    if (displayNameProps) {
      return translate(`translate#${displayNameProps.localise}`)
    }

    return initDisplayName
  }

  const displayName = prepareDisplayName(initDisplayName)

  const getOptions = async (
    dependencies: string[],
    isText = false,
    dependenciesAdditional: string[] = [],
  ) => {
    try {
      const response: any = await PosApi.lookupAttribute({
        attributeId,
        dependencies,
        isText,
        dependenciesAdditional,
      })
      if (displayTypeId === POSServiceFieldTypes.CHECKBOX && Array.isArray(response)) {
        if (response.length === 1) {
          setFormikFieldValue(attributeName, response[0].id)
        }
      }

      if (Array.isArray(response)) {
        setOptions(
          response.map(({ id, name }: { id: number; name: string }) => ({
            name,
            value: `${id}`,
          })),
        )

        if (param.attributeName === 'posmcccode') {
          dispatch(setMCCCodeInitialRequestDataFragment(dependencies[0]))
        }
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  const isSomeDepHasValue = useMemo((): boolean => {
    if (dependedOn.length) {
      return dependedOn.some((dep: any) => {
        if (Array.isArray(formValues[dep])) {
          return !!formValues[dep].length
        }
        return !!formValues[dep]
      })
    }
    return false
  }, [dependedOn, enableMode, formValues])

  const isAllDepsHaveValues = useMemo((): boolean => {
    if (dependedOn.length) {
      return dependedOn.every((dep: any) => {
        if (Array.isArray(formValues[dep])) {
          return !!formValues[dep].length
        }
        return !!formValues[dep]
      })
    }
    return false
  }, [dependedOn, enableMode, formValues])

  const currentDepsValues = useMemo(() => dependedOn.map(dep => formValues[dep]), [
    ...dependedOn.map(dep => formValues[dep]),
  ])

  useEffect(() => {
    if (existsLookupValues && !allowRecursiveCallBySearchFragment) {
      if (dependedOn.length) {
        if (enableMode === EnableModes.SomeParentIsSet) {
          if (isSomeDepHasValue) {
            getOptions(dependedOn.map(dep => formValues[dep] || ''))
          }
        }

        if (enableMode === EnableModes.AllParentIsSet) {
          if (isAllDepsHaveValues) {
            getOptions(dependedOn.map(dep => formValues[dep] || ''))
          }
        }
      } else {
        getOptions([])
      }
    }
  }, [
    ...dependedOn.map(dep => formValues[dep]),
    isSomeDepHasValue,
    isAllDepsHaveValues,
    existsLookupValues,
    dependedOn,
    enableMode,
  ])

  /**
   * Set field value to initial when deps are changed
   */
  useEffect(() => {
    if (dependedOn.length) {
      if (!prevDepsValues.length) {
        return setPrevDepsValues(currentDepsValues)
      }

      if (!isEqual(prevDepsValues, currentDepsValues)) {
        setPrevDepsValues(currentDepsValues)
        setOptions([])

        let initValue: string | boolean | [] = ''
        switch (displayTypeId) {
          case POSServiceFieldTypes.CHECKBOXLIST:
          case POSServiceFieldTypes.COMBOMULTISELECT:
            if (multiple) {
              initValue = []
            }
            break
          case POSServiceFieldTypes.CHECKBOX:
            initValue = false
            break
          default:
        }
        setFormikFieldValue(attributeName, initValue)
        setFieldTouched(attributeName, false)
      }
    }
  }, [prevDepsValues, currentDepsValues])

  /**
   * get options for DropDownInput when has only attributeName and selected value
   */
  useEffect(() => {
    if (!options.length && allowRecursiveCallBySearchFragment && formValues[attributeName]) {
      if (param.attributeName === 'posmcccode') {
        if (!!notSaveItemsPosItems.length && notSaveItemsPosItemsIndex !== null) {
          getOptions(
            [notSaveItemsPosItems[notSaveItemsPosItemsIndex].formValues.posmcccodeLookupRequest],
            false,
          )
          return
        }
        if (requestItems.length) {
          getOptions(
            [
              requestItems.reduce((acc: any, item: any) => {
                if (item.mccCode) {
                  acc = item.mccCode
                }

                return acc
              }, ''),
            ],
            false,
          )
          return
        }
      }

      getOptions([], false, [`${formValues[attributeName]}`])
    }
  }, [
    allowRecursiveCallBySearchFragment,
    formValues[attributeName],
    options,
    requestItems,
    notSaveItemsPosItemsIndex,
  ])

  const hasValueInSiblingField = useMemo((): boolean => {
    if (dependedToSetOnlyOneFrom && dependedToSetOnlyOneFrom.length) {
      return dependedToSetOnlyOneFrom.some(dep => formValues[dep])
    }

    return false
  }, [dependedToSetOnlyOneFrom, formValues])

  const hasThisFieldInDisablingList = useMemo(
    (): boolean => !!(disabledFieldNames && disabledFieldNames.includes(attributeName)),
    [formValues, attributeName],
  )

  const handleSearch = useCallback(
    _.debounce((value: string) => {
      if (value.length >= 3) {
        return getOptions([...dependedOn.map(dep => formValues[dep] || ''), value], true)
      }
    }, 1000),
    [dependedOn, formValues],
  )

  const isDisabledDropdownInput = useMemo((): boolean => {
    const hasOptions = !!options.length

    if (dependedOn.length) {
      if (enableMode === EnableModes.SomeParentIsSet) {
        if (isSomeDepHasValue) {
          return hasThisFieldInDisablingList
        }
      }
      if (enableMode === EnableModes.AllParentIsSet) {
        if (isAllDepsHaveValues) {
          return hasThisFieldInDisablingList
        }
      }
    } else if (allowRecursiveCallBySearchFragment) {
      return hasThisFieldInDisablingList
    }

    return !hasOptions || !enabled || hasThisFieldInDisablingList
  }, [
    dependedOn,
    enableMode,
    options,
    enabled,
    allowRecursiveCallBySearchFragment,
    isSomeDepHasValue,
    isAllDepsHaveValues,
    hasThisFieldInDisablingList,
  ])

  const FieldByParameterConfig = useMemo(() => {
    const hasOptions = !!options.length

    const renderDropDownInputField = createDropDownInputField({
      label: displayName,
      placeholder: description,
      required: required && !isDisabledDropdownInput,
      options,
      disabled: isDisabledDropdownInput,
      neverDisable: false,
      onTextChange: allowRecursiveCallBySearchFragment ? handleSearch : undefined,
      withoutSingleOption: true,
    })

    // Temporary translate. Must be fixed in future.
    // displayName
    const temporaryDisplayNameProp =
      attributeId === 4
        ? translate(`translate#label.name`)
        : displayName && attributeId === 5
        ? translate(`translate#label.remark`)
        : displayName

    // Temporary solution for "Phone Number" field, make"required"
    const temporaryRequiredProp = hasThisFieldInDisablingList ? false : required

    switch (displayTypeId) {
      case POSServiceFieldTypes.TEXT:
        return createTextInputField({
          label: temporaryDisplayNameProp,
          placeholder: description,
          required: attributeId === 3241 ? true : temporaryRequiredProp,
          multiline: dispRowCount > 1,
          rows: dispRowCount,
          type: NumberValueClasses.includes(valueClass) ? 'number' : 'text',
          shrink: true,
          fullWidth: true,
          disabled: hasThisFieldInDisablingList || !enabled,
        })
      case POSServiceFieldTypes.CHECKBOXLIST:
        return createCheckboxGroupField({
          label: displayName,
          options,
          isRequired: required,
          width: '30%',
        })
      case POSServiceFieldTypes.COMBO:
      case POSServiceFieldTypes.CONTENT_PROPOSAL:
        return renderDropDownInputField
      case POSServiceFieldTypes.COMBOMULTISELECT:
        if (multiple) {
          return MultipleSelect({
            label: displayName,
            placeholder: description,
            disabled: !hasOptions || !enabled,
            parameterName: attributeName,
            options,
          })
        }
        return renderDropDownInputField
      case POSServiceFieldTypes.Date:
        return createDatePickerField({
          label: displayName,
          widthClass: clsx(classes.dateField),
          disableFutureDates: false,
          disabledPast: true,
          required,
          withoutArrayDate: true,
          minTomorrowDate: param.attributeName === 'posservicestartdate',
        })

      case POSServiceFieldTypes.CHECKBOX:
        return createCheckboxField({
          label: displayName,
          disabled:
            param.attributeName === 'sameaslegaladdress'
              ? false
              : !hasOptions || !enabled || options.length === 1,
          checked: formValues[attributeName],
        })
      default:
        return null
    }
  }, [
    options,
    displayTypeId,
    attributeId,
    userCompanyName,
    formValues,
    dependedOn,
    allowRecursiveCallBySearchFragment,
  ])

  if (hasValueInSiblingField) {
    return <></>
  }

  // for blanc field (tmp solution)
  if (attributeName === 'blank') {
    return (
      <div
        style={{
          height: '42px',
          visibility: 'hidden',
        }}
      >
        blank
      </div>
    )
  }
  return <Field name={param.attributeName}>{FieldByParameterConfig}</Field>
}

export default DynamicField
