import queryString from 'query-string'

import {
  AdmObjectsFilter,
  AdmObjectsImportRequest,
  AdmObjectsResponse,
} from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

interface GetProps {
  sort: Sort
  paging: { page: number; pageSize: number }
  requestBody: AdmObjectsFilter
}

export class NotRegisteredApi {
  private static requestService = getRequestServiceCM()

  public static async get({ paging, sort, requestBody }: GetProps): Promise<AdmObjectsResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/objects',
          query: { ...paging, ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await NotRegisteredApi.requestService.post(url, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async importAtm(requestBody: AdmObjectsImportRequest): Promise<void> {
    try {
      return await NotRegisteredApi.requestService.post('/admin/objects/import', requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }
}
