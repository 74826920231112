import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'

import { styledComponentTheme } from '@/theme'

export const useClasses = makeStyles(({ spacing }) => ({
  dialog: {
    position: 'relative',
    maxWidth: 'fit-content',
    minWidth: spacing(200),
    width: spacing(200),
  },
  paper: {
    padding: spacing(4),
    maxHeight: '420px',
    height: '420px',
    display: 'grid',
    gridTemplateRows: '50px 1fr',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
  modalHeader: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginRight: spacing(1),
    padding: 0,
    whiteSpace: 'nowrap',
  },

  subTitle: {
    color: '#929A9B',
    fontSize: styledComponentTheme.fontSizes.normal,
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },

  titleWrap: {
    width: '100%',
    paddingRight: '20px',
  },

  button: {
    height: 40,
    fontWeight: 'normal',
    padding: '0 10px',
    margin: 0,
    whiteSpace: 'nowrap',
    fontSize: '13px',
  },

  btnContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: '8px',
  },
  checkbox: {
    padding: 0,
  },

  tableCell: {
    '&:nth-last-of-type(-n+2)': {
      textAlign: 'center',
    },
  },
}))

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
`

export const ActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, max-content);
  align-items: center;
  grid-column-gap: 8px;
  justify-content: flex-end;
  padding: 0px;
  margin-right: 10px;
`
