import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import clsx from 'clsx'
import React, { ReactElement } from 'react'

import { useClasses } from '@/components/blocks/TreeViews/styles'
import { TextInput } from '@/components/controls/TextInput/component'
import { useTranslate } from '@/utils/internalization'

import { Props } from './types'

const SearchInput: React.FC<Props> = ({
  indentation = false,
  clearButton,
  val,
  value,
  typeValue,
  pattern,
  placeholder,
  positionIconEnd,
  ...props
}: Props): ReactElement => {
  const translate = useTranslate()
  const classes = useClasses()

  const searchIcon = (): ReactElement => (
    <InputAdornment position="start">
      <SearchIcon className={classes.icon} />
    </InputAdornment>
  )

  return (
    <div
      className={clsx(classes.searchWrapper, {
        [classes.indentationInputBlock]: indentation,
      })}
    >
      <TextInput
        {...props}
        value={typeValue ? value : val}
        pattern={pattern}
        placeholder={placeholder || translate('translate#title.search')}
        fullWidth
        InputProps={{
          startAdornment: !positionIconEnd && searchIcon(),
          endAdornment: positionIconEnd && searchIcon(),
        }}
        clearButton={clearButton}
        className={classes.search}
      />
    </div>
  )
}

export default SearchInput
