import Grid from '@material-ui/core/Grid'
import { Alert } from '@material-ui/lab'
import clsx from 'clsx'
import { Form, Formik, FormikErrors, FormikProps } from 'formik'
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CommonApi } from '@/api'
import { LookupsResponse } from '@/api/common/commonApi/types'
import { OnCallResponse } from '@/api/sd/ticketsApi/types'
import { ReactComponent as CalendarIcon } from '@/assets/products/icons/calendar.svg'
import { ReactComponent as DeleteIcon } from '@/assets/products/icons/delete.svg'
import Loader from '@/components/blocks/Loader'
import Button from '@/components/controls/Button'
import GeneratedField from '@/components/controls/GeneratedField'
import { FieldTypeName } from '@/components/controls/GeneratedField/types'
import Lookups from '@/components/controls/Lookups'
import LookupsMultiSelect from '@/components/controls/LookupsMultiSelect'
import LookupsRadio from '@/components/controls/LookupsRadio'
import { onlyNumbersAndDots } from '@/constants/disabledSymbols'
import { getError } from '@/store/products/'
import { Product, ProductFieldName } from '@/types/products/products'
import { useErrorMessage } from '@/utils/hooks/useErrrorMessage'
import { useTranslate } from '@/utils/internalization'

import { initialValues } from './initialValues'
import { useClasses } from './styles'
import { Props } from './types'
import { validate } from './validation'

const ProductInformation = ({
  onSubmit,
  onCancel,
  isLoading,
  defaultValues,
  onDelete,
}: Props): ReactElement => {
  const formRef = useRef<FormikProps<Product> | null>(null)
  const [baseCurrency, setBaseCurrency] = useState<any>(null)
  const classes = useClasses()
  const translate = useTranslate()
  const dispatch = useDispatch()

  const error = useSelector(getError)

  let formActions

  if (formRef && formRef.current) {
    formActions = {
      setErrors: formRef.current.setErrors,
      setFieldError: formRef.current.setFieldError,
    }
  }

  const { errorMessages, setErrorMessages } = useErrorMessage({
    error,
    formActions: formActions,
  })

  useEffect(() => {
    CommonApi.getBaseCurrency().then(result => {
      setBaseCurrency(result)
    })
  }, [])

  const handleCancel = useCallback(
    (
      setValues?: (
        values: React.SetStateAction<Product>,
        shouldValidate?: boolean | undefined,
      ) => void,
    ) => (): void => {
      onCancel(setValues)
      setErrorMessages([])
    },
    [onCancel, error, setErrorMessages],
  )

  useEffect(() => {
    if (defaultValues && Object.keys(defaultValues).length && formRef.current) {
      const values: any = {}

      Object.keys(initialValues).forEach(
        key => (values[key] = (defaultValues as Record<string, any>)[key]),
      )

      formRef.current.setValues(values as Product)
    }
  }, [defaultValues])

  const errorJSX =
    !!errorMessages.length &&
    errorMessages.map(e => (
      <Alert severity="error" key={e}>
        {e}
      </Alert>
    ))

  return (
    <Grid>
      {errorJSX}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate(defaultValues)}
        innerRef={formRef}
      >
        {({ values, isValid, setFieldValue, setValues }) => (
          <Form>
            <Grid className={classes.fieldsRow}>
              <Grid className={classes.field}>
                {/* <GeneratedField
                  field={{
                    type: FieldTypeName.RadioGroup,
                  }}
                  fieldName={ProductFieldName.ComplexityLevelId}
                  formikValues={values}
                  setFormikFieldValue={setFieldValue}
                  lookupFieldConfig={config.find(
                    field => field.fieldName === ProductFieldName.ComplexityLevelId,
                  )}
                /> */}
                <LookupsRadio
                  isDisabled={false}
                  setFormikFieldValue={setFieldValue}
                  field={{
                    options: {
                      label: '',
                      placeholder: '',
                      isNumber: true,
                    },
                  }}
                  defaultValue={{ value: '0', name: 'Detail' }}
                  isDependsOn={false}
                  fieldTitle={ProductFieldName.ComplexityLevelName}
                  fieldName={ProductFieldName.ComplexityLevelId}
                  onCall={async (
                    value: string,
                  ): Promise<{ item: any; value: string; name: string }[]> => {
                    const response = await CommonApi.getLookupsValues({
                      lookupKey: 'PRODUCT_COMPLEXITY_LEVEL',
                      module: 'COM',
                      fragment: value,
                      pageNo: 0,
                      pageSize: 100,
                    })
                    return response.values.map(item => ({
                      item: item,
                      value: item.id,
                      name: item.name,
                    }))
                  }}
                  onSelect={(item: { value: string; name: string }): void => {
                    setFieldValue(ProductFieldName.ComplexityLevelName, item.name)
                    setFieldValue(ProductFieldName.ComplexityLevelId, item.value)
                  }}
                  className={classes.radioGroup}
                />
              </Grid>
            </Grid>

            <Grid className={classes.fieldsRow}>
              <Grid className={classes.field}>
                <GeneratedField
                  field={{
                    type: FieldTypeName.Text,
                    options: {
                      textInput: {
                        label: translate('translate#prod.productCode'),
                        placeholder: translate('translate#prod.enterProductCode'),
                        required: true,
                      },
                    },
                  }}
                  fieldName={ProductFieldName.ProductCode}
                  formikValues={values}
                  setFormikFieldValue={setFieldValue}
                />
              </Grid>
              <Grid className={classes.field}>
                <Lookups
                  field={{
                    type: FieldTypeName.Search,
                    options: {
                      label: translate('translate#prod.vendorCompany'),
                      placeholder: translate('translate#prod.chooseVendorCompany'),
                      required: false,
                      isNumber: true,
                    },
                  }}
                  fieldName={ProductFieldName.VendorCompanyId}
                  fieldTitle={ProductFieldName.VendorCompanyName}
                  isDependsOn={false}
                  setFormikFieldValue={setFieldValue}
                  isDisabled={false}
                  defaultValue={
                    defaultValues
                      ? {
                          value: defaultValues[ProductFieldName.VendorCompanyId],
                          name: defaultValues[ProductFieldName.VendorCompanyName],
                        }
                      : undefined
                  }
                  onCall={(value: string): Promise<LookupsResponse> => {
                    return CommonApi.getLookupsValues({
                      lookupKey: 'ALLOWED_COMPANY_VENDOR',
                      module: 'PROD',
                      fragment: value,
                      pageNo: 0,
                      pageSize: 100,
                    })
                  }}
                />
              </Grid>
            </Grid>

            <Grid className={classes.fieldsRow}>
              <Grid className={classes.field}>
                <GeneratedField
                  field={{
                    type: FieldTypeName.Text,
                    options: {
                      textInput: {
                        label: translate('translate#prod.productName'),
                        placeholder: translate('translate#prod.enterProductName'),
                        required: true,
                      },
                    },
                  }}
                  fieldName={ProductFieldName.ProductName}
                  formikValues={values}
                  setFormikFieldValue={setFieldValue}
                />
              </Grid>
              <Grid className={classes.field}>
                <Lookups
                  field={{
                    type: FieldTypeName.Search,
                    options: {
                      label: translate('translate#prod.vendorCountry'),
                      placeholder: translate('translate#prod.chooseVendorCountry'),
                      required: false,
                      isNumber: true,
                    },
                  }}
                  fieldName={ProductFieldName.VendorCountryId}
                  fieldTitle={ProductFieldName.VendorCountryName}
                  setFormikFieldValue={setFieldValue}
                  isDisabled={false}
                  isDependsOn={false}
                  defaultValue={
                    defaultValues
                      ? {
                          value: defaultValues[ProductFieldName.VendorCountryId],
                          name: defaultValues[ProductFieldName.VendorCountryName],
                        }
                      : undefined
                  }
                  onCall={(value: string): Promise<LookupsResponse> => {
                    return CommonApi.getLookupsValues({
                      lookupKey: 'COUNTRY_DICT',
                      module: 'PROD',
                      fragment: value,
                      pageNo: 0,
                      pageSize: 100,
                    })
                  }}
                />
              </Grid>
            </Grid>

            <Grid className={classes.fieldsRow}>
              <Grid className={classes.field}>
                <Lookups
                  field={{
                    type: FieldTypeName.List,
                    options: {
                      label: translate('translate#prod.quantityKey'),
                      placeholder: translate('translate#prod.chooseQuantityKey'),
                      required: false,
                      isNumber: true,
                    },
                  }}
                  fieldName={ProductFieldName.QuantityKeyId}
                  fieldTitle={ProductFieldName.QuantityKeyName}
                  setFormikFieldValue={setFieldValue}
                  isDisabled={false}
                  isDependsOn={false}
                  defaultValue={
                    defaultValues
                      ? {
                          value: defaultValues[ProductFieldName.QuantityKeyId],
                          name: defaultValues[ProductFieldName.QuantityKeyName],
                        }
                      : undefined
                  }
                  onCall={(value: string): Promise<LookupsResponse> => {
                    return CommonApi.getLookupsValues({
                      lookupKey: 'QUANTITY_KEYS_DICT',
                      module: 'PROD',
                      fragment: value,
                      pageNo: 0,
                      pageSize: 100,
                    })
                  }}
                />
              </Grid>
              <Grid className={clsx(classes.field, classes.fieldsRow)}>
                <Grid className={classes.subField}>
                  <GeneratedField
                    field={{
                      type: FieldTypeName.Text,
                      options: {
                        textInput: {
                          label: translate('translate#prod.articleNo'),
                          placeholder: translate('translate#prod.enterArticleNo'),
                        },
                      },
                    }}
                    fieldName={ProductFieldName.VendorArticleNo}
                    formikValues={values}
                    setFormikFieldValue={setFieldValue}
                  />
                </Grid>
                <Grid className={classes.subField}>
                  <GeneratedField
                    field={{
                      type: FieldTypeName.Text,
                      options: {
                        textInput: {
                          label: translate('translate#prod.statwnr'),
                          placeholder: translate('translate#prod.enterStatwnr'),
                        },
                      },
                    }}
                    fieldName={ProductFieldName.StatWnr}
                    formikValues={values}
                    setFormikFieldValue={setFieldValue}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid className={classes.fieldsRow}>
              <Grid className={classes.field}>
                <GeneratedField
                  field={{
                    type: FieldTypeName.Text,
                    options: {
                      textInput: {
                        label: `${translate('translate#prod.listPrice')} (${baseCurrency &&
                          baseCurrency.currencycode})`,
                        placeholder: `${translate(
                          'translate#prod.enterListPrice',
                        )} (${baseCurrency && baseCurrency.currencycode})`,
                        disableKeys: onlyNumbersAndDots,
                      },
                    },
                  }}
                  fieldName={ProductFieldName.ListPrice}
                  formikValues={values}
                  setFormikFieldValue={setFieldValue}
                />
              </Grid>
              <Grid className={classes.field}>
                {/* <GeneratedField
                  field={{
                    type: FieldTypeName.List,
                    options: {
                      selectAndDropDownInput: {
                        label: translate('translate#prod.sellGroup'),
                        placeholder: translate('translate#prod.chooseSellGroup'),
                      },
                    },
                  }}
                  fieldName={ProductFieldName.ProductCategoryId}
                  formikValues={values}
                  setFormikFieldValue={setFieldValue}
                  lookupFieldConfig={config.find(
                    field => field.fieldName === ProductFieldName.ProductCategoryId,
                  )}
                /> */}
                {/* productCategoryId */}
                <Lookups
                  field={{
                    type: FieldTypeName.List,
                    options: {
                      label: translate('translate#prod.sellGroup'),
                      placeholder: translate('translate#prod.chooseSellGroup'),
                      required: false,
                      isNumber: true,
                    },
                  }}
                  fieldName={ProductFieldName.ProductCategoryId}
                  fieldTitle={ProductFieldName.ProductCategoryName}
                  setFormikFieldValue={setFieldValue}
                  isDisabled={false}
                  isDependsOn={false}
                  defaultValue={
                    defaultValues
                      ? {
                          value: defaultValues[ProductFieldName.ProductCategoryId],
                          name: defaultValues[ProductFieldName.ProductCategoryName],
                        }
                      : undefined
                  }
                  onCall={(value: string): Promise<LookupsResponse> => {
                    return CommonApi.getLookupsValues({
                      lookupKey: 'PRODUCT_SELL_GROUPS_ALL',
                      module: 'PROD',
                      fragment: value,
                      pageNo: 0,
                      pageSize: 100,
                    })
                  }}
                />
              </Grid>
            </Grid>

            <Grid className={classes.fieldsRow}>
              <Grid className={classes.field}>
                <GeneratedField
                  field={{
                    type: FieldTypeName.Checkbox,
                    options: {
                      checkbox: {
                        label: translate('translate#prod.isRepairable'),
                      },
                    },
                  }}
                  fieldName={ProductFieldName.IsRepairable}
                  formikValues={values}
                  setFormikFieldValue={setFieldValue}
                />
              </Grid>
              <Grid className={classes.field}>
                {/* <GeneratedField
                  field={{
                    type: FieldTypeName.List,
                    options: {
                      selectAndDropDownInput: {
                        label: translate('translate#prod.group'),
                        placeholder: translate('translate#prod.chooseGroup'),
                      },
                    },
                  }}
                  fieldName={ProductFieldName.ProductGroupId}
                  formikValues={values}
                  setFormikFieldValue={setFieldValue}
                  lookupFieldConfig={config.find(
                    field => field.fieldName === ProductFieldName.ProductGroupId,
                  )}
                /> */}
                <Lookups
                  field={{
                    type: FieldTypeName.List,
                    options: {
                      label: translate('translate#prod.group'),
                      placeholder: translate('translate#prod.chooseGroup'),
                      required: false,
                      isNumber: true,
                    },
                  }}
                  fieldName={ProductFieldName.ProductGroupId}
                  fieldTitle={ProductFieldName.ProductGroupName}
                  isDependsOn={false}
                  setFormikFieldValue={setFieldValue}
                  isDisabled={false}
                  defaultValue={
                    defaultValues
                      ? {
                          value: defaultValues[ProductFieldName.ProductGroupId],
                          name: defaultValues[ProductFieldName.ProductGroupName],
                        }
                      : undefined
                  }
                  onCall={(value: string): Promise<any> => {
                    return CommonApi.getLookupsValues({
                      lookupKey: 'PRODUCT_GROUPS',
                      module: 'PROD',
                      fragment: value,
                      productCategoryId: values.productCategoryId ? values.productCategoryId : -1,
                      pageNo: 0,
                      pageSize: 100,
                    })
                  }}
                />
              </Grid>
            </Grid>

            <Grid className={classes.fieldsRow}>
              <Grid className={classes.field}>
                <Lookups
                  field={{
                    type: FieldTypeName.List,
                    options: {
                      label: translate('translate#prod.sparePartGroup'),
                      placeholder: translate('translate#prod.chooseSparePartGroup'),
                      required: false,
                      isNumber: true,
                    },
                  }}
                  fieldName={ProductFieldName.ProductSparePartGroupId}
                  fieldTitle={ProductFieldName.ProductSparePartGroupName}
                  isDependsOn={false}
                  setFormikFieldValue={setFieldValue}
                  isDisabled={false}
                  defaultValue={
                    defaultValues
                      ? {
                          value: defaultValues[ProductFieldName.ProductSparePartGroupId],
                          name: defaultValues[ProductFieldName.ProductSparePartGroupName],
                        }
                      : undefined
                  }
                  onCall={(value: string): Promise<LookupsResponse> => {
                    return CommonApi.getLookupsValues({
                      lookupKey: 'PRODUCT_SP_GROUPS',
                      module: 'PROD',
                      fragment: value,
                      pageNo: 0,
                      pageSize: 100,
                    })
                  }}
                />
              </Grid>
              <Grid className={classes.field}>
                <Lookups
                  field={{
                    type: FieldTypeName.List,
                    options: {
                      label: translate('translate#prod.accountingGroup'),
                      placeholder: translate('translate#prod.chooseAccountingGroup'),
                      required: false,
                      isNumber: true,
                    },
                  }}
                  fieldName={ProductFieldName.ProductAccountingGroupId}
                  fieldTitle={ProductFieldName.ProductAccountingGroupName}
                  isDependsOn={false}
                  setFormikFieldValue={setFieldValue}
                  isDisabled={false}
                  defaultValue={
                    defaultValues
                      ? {
                          value: defaultValues[ProductFieldName.ProductAccountingGroupId],
                          name: defaultValues[ProductFieldName.ProductAccountingGroupName],
                        }
                      : undefined
                  }
                  onCall={(value: string): Promise<LookupsResponse> => {
                    return CommonApi.getLookupsValues({
                      lookupKey: 'PRODUCT_ACCOUNTING_GROUP',
                      module: 'PROD',
                      fragment: value,
                      pageNo: 0,
                      pageSize: 100,
                    })
                  }}
                />
                {/* <GeneratedField
                  field={{
                    type: FieldTypeName.List,
                    options: {
                      selectAndDropDownInput: {
                        label: translate('translate#prod.accountingGroup'),
                        placeholder: translate('translate#prod.chooseAccountingGroup'),
                      },
                    },
                  }}
                  fieldName={ProductFieldName.ProductAccountingGroupId}
                  formikValues={values}
                  setFormikFieldValue={setFieldValue}
                  lookupFieldConfig={config.find(
                    field => field.fieldName === ProductFieldName.ProductAccountingGroupId,
                  )}
                /> */}
              </Grid>
            </Grid>

            <Grid className={classes.fieldsRow}>
              <Grid className={classes.field}>
                <GeneratedField
                  field={{
                    type: FieldTypeName.Text,
                    options: {
                      textInput: {
                        label: `${translate('translate#prod.vendorRepairPrice')} (${baseCurrency &&
                          baseCurrency.currencycode})`,
                        placeholder: `${translate(
                          'translate#prod.enterVendorRepairPrice',
                        )} (${baseCurrency && baseCurrency.currencycode})`,
                        disableKeys: onlyNumbersAndDots,
                      },
                    },
                  }}
                  fieldName={ProductFieldName.VendorRepairPrice}
                  formikValues={values}
                  setFormikFieldValue={setFieldValue}
                />
              </Grid>
              <Grid className={clsx(classes.field, classes.fieldsRow)}>
                <Grid className={classes.subField}>
                  <GeneratedField
                    field={{
                      type: FieldTypeName.Text,
                      options: {
                        textInput: {
                          label: translate('translate#prod.heightM'),
                          placeholder: translate('translate#prod.heightM'),
                          disableKeys: onlyNumbersAndDots,
                        },
                      },
                    }}
                    fieldName={ProductFieldName.Height}
                    formikValues={values}
                    setFormikFieldValue={setFieldValue}
                  />
                </Grid>
                <Grid className={classes.subField}>
                  <GeneratedField
                    field={{
                      type: FieldTypeName.Text,
                      options: {
                        textInput: {
                          label: translate('translate#prod.widthM'),
                          placeholder: translate('translate#prod.widthM'),
                          disableKeys: onlyNumbersAndDots,
                        },
                      },
                    }}
                    fieldName={ProductFieldName.Width}
                    formikValues={values}
                    setFormikFieldValue={setFieldValue}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid className={classes.fieldsRow}>
              <Grid className={classes.field}>
                <GeneratedField
                  field={{
                    type: FieldTypeName.Date,
                    options: {
                      datePicker: {
                        label: translate('translate#prod.actualTillDate'),
                        Icon: <CalendarIcon />,
                        disableFutureDates: false,
                      },
                    },
                  }}
                  fieldName={ProductFieldName.ActualTillDate}
                  formikValues={values}
                  setFormikFieldValue={setFieldValue}
                />
              </Grid>
              <Grid className={clsx(classes.field, classes.fieldsRow)}>
                <Grid className={classes.subField}>
                  <GeneratedField
                    field={{
                      type: FieldTypeName.Text,
                      options: {
                        textInput: {
                          label: translate('translate#prod.lengthM'),
                          placeholder: translate('translate#prod.lengthM'),
                          disableKeys: onlyNumbersAndDots,
                        },
                      },
                    }}
                    fieldName={ProductFieldName.Length}
                    formikValues={values}
                    setFormikFieldValue={setFieldValue}
                  />
                </Grid>
                <Grid className={classes.subField}>
                  <GeneratedField
                    field={{
                      type: FieldTypeName.Text,
                      options: {
                        textInput: {
                          label: translate('translate#prod.nettoWeightKG'),
                          placeholder: translate('translate#prod.nettoWeightKG'),
                          disableKeys: onlyNumbersAndDots,
                        },
                      },
                    }}
                    fieldName={ProductFieldName.NettoWeightKg}
                    formikValues={values}
                    setFormikFieldValue={setFieldValue}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.fieldsRow}>
              <Grid className={classes.field}>
                <LookupsMultiSelect
                  field={{
                    options: {
                      label: translate('translate#prod.functionality'),
                      placeholder: translate('translate#prod.chooseFunctionality'),
                      required: false,
                      isNumber: true,
                    },
                  }}
                  fieldName={ProductFieldName.Functionality}
                  isDisabled={false}
                  defaultValue={
                    values && values[ProductFieldName.Functionality] !== null
                      ? (values[ProductFieldName.Functionality] as {
                          value: string
                          name: string
                        }[])
                      : undefined
                  }
                  onCall={async ({ value = '' }): Promise<OnCallResponse> => {
                    const response = await CommonApi.getLookupsValues({
                      lookupKey: 'PRODUCT_FUNCTION',
                      module: 'PROD',
                      fragment: value,
                      pageNo: 0,
                      pageSize: 100,
                    })
                    return {
                      values: response.values.map(item => ({
                        value: item.id,
                        name: item.name,
                      })),
                      totalPages: 1,
                    }
                  }}
                  onSelect={(item: { value: string; name: string }[]): void => {
                    setFieldValue(ProductFieldName.Functionality, item)
                    // defaultValues[ProductFieldName.Functionality] = item
                  }}
                />
              </Grid>
            </Grid>
            <Grid className={classes.actionButtons}>
              {onDelete && (
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={onDelete}
                >
                  {translate('translate#prod.delete')}
                </Button>
              )}
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel(setValues)}
                disabled={defaultValues ? !isValid : undefined}
              >
                {translate('translate#prod.cancel')}
              </Button>
              <Button variant="contained" color="primary" type="submit" disabled={!isValid}>
                {translate('translate#prod.save')}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
      {isLoading && (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      )}
    </Grid>
  )
}

export default ProductInformation
