import { CashOrdersFilters } from '@/types/vault/cashOrders/filters'
import { getRequestService } from '@/utils/services/request'
import {
  ResponseError,
  CashOrderFields,
  CashOrder,
  CashOrdersPaging,
  Currency,
  Valuable,
} from '@/types'

import {
  GetCashOrdersResponse,
  GetCashOrdersResult,
  CashOrderStage,
  CashOrderDetail,
  ValuableOrderDetail,
  CurrencyResponse,
  CreateOrderFormValuableResponse,
  Vault,
  VaultDataResponse,
} from './types'
import moment from 'moment'
import { CreateOrderRequestBody, APIConfiguration } from '@/constants'

import { PDFDocument } from 'pdf-lib'

const convertDataURIToBinary = (pdfData: string) => {
  const raw = window.atob(pdfData)
  const rawLength = raw.length
  const array = new Uint8Array(new ArrayBuffer(rawLength))

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i)
  }
  return array
}

const formatDate = (milliseconds: number | string) =>
  moment(milliseconds).format('DD/MM/YYYY HH:mm')

export class CashOrdersApi {
  public static requestService = getRequestService(APIConfiguration.VAULT_API_BASE_URL)

  public static async assingTeamForOrder(
    orderID: string,
    teamId: string,
  ): Promise<{ [key: string]: string | null }> {
    try {
      const orderBags = await CashOrdersApi.requestService.post(`/cash-order-stage/assignteam`, {
        orderID,
        teamId,
      })
      return orderBags
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async createWorkOrder(
    orderIDs: string[],
  ): Promise<{ [key: string]: string | null }> {
    try {
      const orderBags = await CashOrdersApi.requestService.post(
        `/cash-order-stage/createworkorder`,
        {
          orderIDs,
        },
      )
      return orderBags
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async applyStage(
    orderID: string,
    stage: string,
  ): Promise<{ [key: string]: string | null }> {
    try {
      const orderBags = await CashOrdersApi.requestService.post(`/cash-order-stage/${stage}`, {
        orderID,
      })
      return orderBags
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashOrderTypes(): Promise<{ [key: string]: string }> {
    try {
      const orderTypesResponse = await CashOrdersApi.requestService.get('/cashorders/types')

      return orderTypesResponse
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashOrderStatuses(): Promise<{ [key: string]: string }> {
    try {
      const orderStatusesResponse = await CashOrdersApi.requestService.get('/cashorders/statuses')

      return orderStatusesResponse
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashOrderStages(orderId: string): Promise<Array<CashOrderStage>> {
    try {
      const orderStages = await CashOrdersApi.requestService.get(
        `/cash-order-stage/${orderId}/stages`,
      )
      return orderStages
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashOrderDetails(
    orderId: string,
    orderType: string,
  ): Promise<Array<CashOrderDetail | ValuableOrderDetail>> {
    try {
      const orderDetails = await CashOrdersApi.requestService.get(
        `/cashorders/${orderId}/details/${
          orderType === 'Valuables Order' ? 'valuables' : 'currencies'
        }`,
      )
      return orderDetails
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashOrderBags(orderId: string): Promise<{ [key: string]: string | null }> {
    try {
      const orderBags = await CashOrdersApi.requestService.get(
        `/cashorders/bagswithload/${orderId}`,
      )

      orderBags.forEach((bag: any) => {
        bag.currencies.forEach((currency: any) => {
          currency.currencyDetails.sort((a: any, b: any) => b.denomination - a.denomination)
        })
      })

      return orderBags
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashOrderComments(
    orderId: string,
  ): Promise<{ [key: string]: string | null }> {
    try {
      const orderComments = await CashOrdersApi.requestService.get(
        `/cashorders/${orderId}/comments`,
      )
      return orderComments?.comment
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCurrencies(): Promise<Currency[] | ResponseError> {
    try {
      const currenciesResponse: CurrencyResponse[] = await CashOrdersApi.requestService.get(
        '/currencies/',
      )
      const currencies = currenciesResponse.map(
        (el: CurrencyResponse): Currency => ({
          currencyID: el.currencyID,
          currencyName: el.currencyName,
          currencyDetails: el.currencyDenominations
            .map(denomination => ({
              quantity: 0,
              denomination,
              sum: 0,
            }))
            .sort((a, b) => b.denomination - a.denomination),
        }),
      )
      return currencies
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashOrders(
    filters: CashOrdersFilters,
    pagingParams: CashOrdersPaging,
  ): Promise<GetCashOrdersResult> {
    try {
      const response: GetCashOrdersResponse = await CashOrdersApi.requestService.post(
        '/cashorders/',
        { ...filters, page: pagingParams.currentPage, pageSize: pagingParams.pageSize },
      )
      const { cashOrders, ...paging } = response
      const resultResponse = {
        cashOrders: cashOrders.map(
          (el, index): CashOrder => ({
            [CashOrderFields.CashOrderDate]: el.cashOrderDate ? formatDate(el.cashOrderDate) : '',
            [CashOrderFields.CashOrderID]: el.coid || '',
            [CashOrderFields.Comment]: el.comment || '',
            [CashOrderFields.CurrencyOrValuables]: el.vaultCurrencyCode || '',
            [CashOrderFields.Date]: el.createdDate ? formatDate(el.createdDate) : '',
            [CashOrderFields.FromVaultOrCorporateClient]: el.fromVaultName || '',
            [CashOrderFields.Identifier]: el.coid || '',
            [CashOrderFields.OrderStatusId]: el.vaultCashOrderStatusId || 0,
            [CashOrderFields.NumberOfBags]: el.numberOfBags || 0,
            [CashOrderFields.NameSurname]: el.nameSurname || '',
            [CashOrderFields.OrderedBy]: el.orderedByVault || '',
            [CashOrderFields.OrderStatus]: el.vaultCashOrderStatus || '',
            [CashOrderFields.OrderType]: el.vaultCashOrderType || '',
            [CashOrderFields.PassData]: el.passData || '',
            [CashOrderFields.Stamp]: el.cashBagSeals || '',
            [CashOrderFields.Team]: el.teamName || '',
            [CashOrderFields.TotalSumOrComment]: '',
            [CashOrderFields.ToVaultOrCorporateClient]: el.toVaultName || '',
            [CashOrderFields.Transport]: el.transport || '',
            [CashOrderFields.VaultCashOrderId]: el.vaultCashOrderId,
            [CashOrderFields.WorkOrderID]: el.vaultWorkOrder || '',
          }),
        ),
        paging,
      }
      return resultResponse
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCreateOrderFormValuables(): Promise<Valuable[]> {
    try {
      const response: Array<CreateOrderFormValuableResponse> = await CashOrdersApi.requestService.get(
        '/valuables/',
      )
      const valuables = response.map(
        (el): Valuable => ({
          valuableTypeID: el.valuableTypeID,
          valuableType: el.valuableType || 'None',
          quantity: 0,
        }),
      )
      return valuables
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async getOrderLoads(orderID: string): Promise<any> {
    try {
      const orderLoadsResponse: any = await CashOrdersApi.requestService.get(
        `/cashorders/${orderID}/load`,
      )

      return orderLoadsResponse
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getRecieverVaults(): Promise<Array<Vault>> {
    try {
      const recieverVaultsResponse: Array<VaultDataResponse> = await CashOrdersApi.requestService.get(
        `/ordervaults/order/destination`,
      )
      const recieverVaults = recieverVaultsResponse.map(
        (el): Vault => ({
          id: el.vaultId,
          name: el.vaultName,
        }),
      )

      return recieverVaults
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getTransmitterVaults(): Promise<Array<Vault>> {
    try {
      const transmitterVaultsResponse: Array<VaultDataResponse> = await CashOrdersApi.requestService.get(
        `/ordervaults/export/source/`,
      )
      const transmitterVaults = transmitterVaultsResponse.map(
        (el): Vault => ({
          id: el.vaultId,
          name: el.vaultName,
        }),
      )

      return transmitterVaults
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getRecieverVaultsFromTransmitter(
    transmitterVaultId: string,
  ): Promise<Array<Vault>> {
    try {
      const recieverVaultsResponse: Array<VaultDataResponse> = await CashOrdersApi.requestService.get(
        `/ordervaults/export/destination/${transmitterVaultId}`,
      )
      const recieverVaults = recieverVaultsResponse.map(
        (el): Vault => ({
          id: el.vaultId,
          name: el.vaultName,
        }),
      )

      return recieverVaults
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getTransmitterVaultsFromReceiver(
    recieverVaultId: string,
  ): Promise<Array<Vault>> {
    try {
      const transmitterVaultsResponse: Array<VaultDataResponse> = await CashOrdersApi.requestService.get(
        `/ordervaults/order/source/${recieverVaultId}`,
      )

      const transmitterVaults = transmitterVaultsResponse.map(
        (el): Vault => ({
          id: el.vaultId,
          name: el.vaultName,
        }),
      )

      return transmitterVaults
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getOrderDocumentTypes(orderId: string): Promise<any> {
    try {
      const documentsTypes = await CashOrdersApi.requestService.get(`orderdocuments/${orderId}`)
      return documentsTypes
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async getDocument(requestBody: any): Promise<any> {
    try {
      const createOrderResult = await CashOrdersApi.requestService.post(
        'orderdocuments/PDF',
        requestBody,
      )

      const mergedPdf = await PDFDocument.create()

      for (const page of createOrderResult?.pages) {
        const existingPdfBytes = convertDataURIToBinary(page)
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const copiedPages = await mergedPdf.copyPages(pdfDoc, [0])

        mergedPdf.addPage(copiedPages[0])
      }

      const documentBytes = await mergedPdf.save()

      return documentBytes
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async editCashOrder(requestBody: CreateOrderRequestBody): Promise<any> {
    try {
      const createOrderResult = await CashOrdersApi.requestService.put(
        '/cashorders/orderwithbags',
        requestBody,
      )
      return createOrderResult
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async editCashDeliveryOrder(requestBody: CreateOrderRequestBody): Promise<any> {
    try {
      const editOrderResult = await CashOrdersApi.requestService.put(
        '/cashorders/cashdelivery',
        requestBody,
      )
      return editOrderResult
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async createCashDeliveryOrder(requestBody: CreateOrderRequestBody): Promise<any> {
    try {
      const createOrderResult = await CashOrdersApi.requestService.post(
        '/cashorders/cashdelivery',
        requestBody,
      )
      return createOrderResult
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async createOrder(requestBody: CreateOrderRequestBody): Promise<any> {
    try {
      const createOrderResult = await CashOrdersApi.requestService.post(
        '/cashorders/orderwithbags',
        requestBody,
      )
      return createOrderResult
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async assignBagForStorage(requestBody: any): Promise<any> {
    try {
      const assignResult = await CashOrdersApi.requestService.post(
        '/cashbags/assignforspecifiedstorage',
        requestBody,
      )
      return assignResult
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async assignBagForCashier(requestBody: any): Promise<any> {
    try {
      const assignResult = await CashOrdersApi.requestService.post(
        '/cashbags/assigntocashier',
        requestBody,
      )
      return assignResult
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async getCashBagsList(partOfBagNumber: string): Promise<any> {
    try {
      const result = await CashOrdersApi.requestService.get(`/cashbags/find/${partOfBagNumber}`)
      return result
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async getCashBagsListForVault(payload: any): Promise<any> {
    try {
      const result = await CashOrdersApi.requestService.get(
        `/cashbags/find/${payload.vaultId}/${payload.fragment}`,
      )
      return result
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async getAvailableVaults(payload: any): Promise<any> {
    try {
      const result = await CashOrdersApi.requestService.get(`/storage/${payload}`)
      return result
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async validateCashBagNumber({
    cashBagNumber,
    cashBagId,
    cashOrderId,
  }: any): Promise<any> {
    try {
      const result = await CashOrdersApi.requestService.get(
        `/cashbags/validate/${cashBagNumber}${
          cashBagId && cashBagId ? `?cashBagId=${cashBagId}&cashOrderId=${cashOrderId}` : ''
        }`,
      )
      return result
    } catch (error) {
      return false
    }
  }
}
