import { combineReducers } from 'redux'

import * as automatedOperations from './automatedOperations/reducer'
import * as banks from './banks/reducer'
import * as cassetteTypes from './cassetteTypes/reducer'
import * as common from './common/reducer'
import * as config from './config/reducer'
import * as currencies from './currencies/reducer'
import * as eventNotifications from './eventNotifications/reducer'
import * as forecast from './forecast/reducer'
import * as realTimeMonitoring from './realTimeMonitoring/reducer'
import * as roundBanknotes from './roundBanknotes/reducer'
import * as uploadLogo from './uploadLogo/reducer'

export interface State {
  common: common.State
  uploadLogo: uploadLogo.State
  realTimeMonitoring: realTimeMonitoring.State
  roundBanknotes: roundBanknotes.State
  eventNotifications: eventNotifications.State
  automatedOperations: automatedOperations.State
  banks: banks.State
  cassetteTypes: cassetteTypes.State
  currencies: currencies.State
  forecast: forecast.State
  config: config.State
}

export const reducer = combineReducers({
  common: common.reducer,
  uploadLogo: uploadLogo.reducer,
  realTimeMonitoring: realTimeMonitoring.reducer,
  roundBanknotes: roundBanknotes.reducer,
  eventNotifications: eventNotifications.reducer,
  automatedOperations: automatedOperations.reducer,
  banks: banks.reducer,
  cassetteTypes: cassetteTypes.reducer,
  currencies: currencies.reducer,
  forecast: forecast.reducer,
  config: config.reducer,
})
