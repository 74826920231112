import makeStyles from '@material-ui/core/styles/makeStyles'
export type Props = {
  display?: string
}

export const useClasses = makeStyles(({ palette, spacing }) => ({
  wrapper: {
    padding: spacing(3),
    display: `${(props: Props) => props.display}`,
    flexDirection: 'column',
    overflow: 'auto',
  },
  title: {
    marginBottom: spacing(4),
    paddingRight: spacing(4),
  },
  grid: {
    flex: 1,
    overflow: 'auto',
  },
  keyText: {
    textTransform: 'capitalize',
    color: palette.grey[400],
  },
  closeButton: {
    position: 'absolute',
    top: spacing(1),
    right: spacing(1),
    cursor: 'pointer',
    zIndex: 100,
  },
  file: {
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    padding: spacing(0, 2),
    margin: spacing(2, 0),
  },
  fileName: {
    fontSize: spacing(1.5),
    color: palette.grey[400],
    width: spacing(20),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  extension: {
    width: spacing(2.5),
    height: spacing(3),
    marginRight: spacing(2),
  },
  fileSave: {
    margin: 0,
    padding: spacing(1.5),
  },
  cell: {
    borderBottom: 'none',
  },
  sectionTitle: {
    fontSize: '18px',
  },
  tableWrap: {
    marginBottom: spacing(3),
  },
  ticketNumber: {
    color: palette.primary.main,
  },
}))
