import { FieldProps } from 'formik'

export interface Props extends FieldProps {
  isSingleSelectingRow?: boolean
  merchantId: number
  serviceTypeId: number
  filtersIsVisible: boolean
  modalHeight: number | null
  selectedTerminalIds?: string[]
}

export enum SearchTerminalByMerchantAndServiceFields {
  TerminalId = 'id',
  AcquirerLuno = 'acquirerLuno',
  DeviceName = 'deviceName',
  Address = 'address',
  City = 'city',
}

export interface SearchTerminalByMerchantAndServiceTableRow {
  [SearchTerminalByMerchantAndServiceFields.AcquirerLuno]: string
  [SearchTerminalByMerchantAndServiceFields.DeviceName]: string
  [SearchTerminalByMerchantAndServiceFields.Address]: string
  [SearchTerminalByMerchantAndServiceFields.City]: string
}

export interface SearchTerminalsByMerchantAndServiceTableFilters {
  [SearchTerminalByMerchantAndServiceFields.AcquirerLuno]: string
  [SearchTerminalByMerchantAndServiceFields.DeviceName]: string
  [SearchTerminalByMerchantAndServiceFields.Address]: string
  [SearchTerminalByMerchantAndServiceFields.City]: string
}
