import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { ComVerboseError } from '@/api/cm/swaggerGeneratedApi'
import { VaultV1AuthApi } from '@/api/vault-v1/authApi'
import { VaultV1CommonApi } from '@/api/vault-v1/commonApi'
import {
  AppNavigationRoutes,
  DEFAULT_CULTURES,
  INITIAL_LOCALE,
  VAULT_V1_BLOCK_PREFIX,
  VaultV1Actions,
  vaultV1BaseUrl,
} from '@/constants'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { setUserToLS } from '@/helpers/cm/auth'
import { clearLocalStorage } from '@/helpers/localStorage'
import { history } from '@/Router'
import { signInResponseFail } from '@/store/auth'
import { PopUpService } from '@/utils/services/popUpService'

import { setComVaultV1Error } from '../common'
import {
  changePasswordFail,
  changePasswordResponse,
  setChangePasswordModalOpen,
  setDataToLS,
  setUserVaultV1,
  signInFailVaultV1,
  signInResponseVaultV1,
  signOutVaultV1,
} from './actions'
import { getDataToLS } from './selectors'

function* signIn({ payload: { username, password } }: AnyAction): SagaIterator {
  try {
    const culture = sessionStorage.getItem(LocalStorageItems.Locale) || INITIAL_LOCALE

    const response = yield call(VaultV1AuthApi.signIn, { username, password, culture })

    if (response) {
      yield put(setUserVaultV1(response))
    }
  } catch (error) {
    const parsedError: ComVerboseError = JSON.parse(error.message)

    yield put(signInFailVaultV1(parsedError.message || error.message))
    yield put(signInResponseFail(parsedError.message || error.message))
  }
}

function* setUser({ payload }: AnyAction): SagaIterator {
  try {
    const { token, userDispName, permissions, modules, login, uid, isPasswordExpired } = payload

    const userData = { id: uid, displayName: userDispName, login: login }

    const dataToLS = {
      baseUrl: vaultV1BaseUrl,
      userName: login,
      userData,
      modules,
      permissions,
    }

    localStorage.setItem(LocalStorageItems.AccessTokenVaultV1, token)
    localStorage.setItem(LocalStorageItems.IsChangePassword, 'true')

    if (isPasswordExpired) {
      yield put(setChangePasswordModalOpen(true))
      yield put(setDataToLS(dataToLS))
    } else {
      setUserToLS(dataToLS)

      history.push(`${VAULT_V1_BLOCK_PREFIX}${AppNavigationRoutes.VaultV1.OrdersPage}`)
      yield put(signInResponseVaultV1(userDispName))
    }
  } catch (error) {
    const parsedError: ComVerboseError = JSON.parse(error.message)

    yield put(signInFailVaultV1(parsedError.message || error.message))
    yield put(signInResponseFail(parsedError.message || error.message))
  }
}

function* getCultures(): SagaIterator {
  try {
    const cultures = yield call(VaultV1CommonApi.getCultures)
    sessionStorage.setItem(LocalStorageItems.AllLocales, JSON.stringify(cultures))
  } catch (error) {
    sessionStorage.setItem(LocalStorageItems.AllLocales, JSON.stringify(DEFAULT_CULTURES))
  }
}

function* setCulture({ culture }: AnyAction): SagaIterator {
  try {
    yield call(VaultV1AuthApi.setCulture, { cultureId: culture })
  } catch (error) {
    yield put(setComVaultV1Error(error))
  }
}

function* handleChangePassword({ payload }: AnyAction): SagaIterator {
  try {
    const dataToLS = yield select(getDataToLS)

    yield call(VaultV1CommonApi.changePassword, payload)

    yield put(changePasswordResponse())
    yield put(setChangePasswordModalOpen(false))

    PopUpService.showGlobalPopUp('translate#cm.ChangePasswordSuccess', 'success')

    if (dataToLS) {
      setUserToLS(dataToLS)

      history.push(`${VAULT_V1_BLOCK_PREFIX}${AppNavigationRoutes.VaultV1.OrdersPage}`)
      yield put(signInResponseVaultV1(dataToLS.displayName))
    }
  } catch (error) {
    const parsedError: ComVerboseError = JSON.parse(error)

    yield put(changePasswordFail(parsedError.message || error.message))
  }
}

function* checkIsLogin(): SagaIterator {
  try {
    yield call(VaultV1AuthApi.checkIsLogin)
  } catch (error) {
    yield put(signOutVaultV1())
  }
}

function* signOut({ payload }: AnyAction): SagaIterator {
  if (payload) {
    yield call(VaultV1AuthApi.logout)
  }
  clearLocalStorage()

  history.push(AppNavigationRoutes.SignInPage)
}

export default function*(): Generator {
  yield takeLatest(VaultV1Actions.SignInRequest, signIn)
  yield takeLatest(VaultV1Actions.SetUser, setUser)
  yield takeLatest(VaultV1Actions.SignOut, signOut)
  yield takeLatest(VaultV1Actions.GetCultures, getCultures)
  yield takeLatest(VaultV1Actions.SetCulture, setCulture)
  yield takeLatest(VaultV1Actions.CheckIsLogin, checkIsLogin)
  yield takeLatest(VaultV1Actions.ChangePasswordRequest, handleChangePassword)
}
