import TableCell from '@material-ui/core/TableCell'
import moment from 'moment'
import React, { ReactElement } from 'react'

import { DEFAULT_DATE_TIME_SEC_FORMAT } from '@/constants/time'

import { DateCellTypes } from '../types/types'
import { useClasses } from './styles'

const DateCell = ({ isUTCTime, key, date, dateFormat, onClick }: DateCellTypes): ReactElement => {
  const classes = useClasses()

  const timezoneDate = isUTCTime ? moment.utc(date) : moment.utc(date).local()

  return (
    <TableCell key={key} className={classes.cell} onClick={onClick}>
      {date ? timezoneDate.format(dateFormat || DEFAULT_DATE_TIME_SEC_FORMAT) : ''}
    </TableCell>
  )
}

export { DateCell }
