import { State } from '@/store/cm/citPlanning/reducer'

interface CassetteValidProps {
  workOrderId: string
  cassetteValidation: State['workOrder']['cassetteValidation']
  data: State['workOrder']['data']
}

export const getInvalidedCassetteIds = ({
  workOrderId,
  cassetteValidation,
  data,
}: CassetteValidProps): string[] => {
  const cassettes = cassetteValidation[workOrderId] || {}
  const invalidedCassetteIds = Object.keys(cassettes).filter(cassetteId => {
    const { cassetteEntries } =
      data.cassettes?.find(cassette => cassette.sessionId === workOrderId) || {}

    const isCassetteCodeFilled = !!cassetteEntries?.find(
      entry => entry.cassetteNr === Number(cassetteId),
    )?.stamp

    return isCassetteCodeFilled && !cassettes[Number(cassetteId)].isValid
  })

  return invalidedCassetteIds
}
