import TablePagination from '@material-ui/core/TablePagination'
import clsx from 'clsx'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import InputPagination from '@/components/controls/AppTable/components/Pagination/components/InputPagination'
import TablePaginationActions from '@/components/controls/AppTable/components/Pagination/components/TablePaginationActions'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import { Props } from './types'

const Pagination = ({
  count,
  page,
  rowsPerPage,
  positionInit = false,
  onChangeRowsPerPage,
  handleChangePage,
}: Props): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  return (
    <div className={clsx(classes.paginationWrapper, { [classes.positionInit]: positionInit })}>
      <div className={classes.elements}>
        <span>
          {count} <FormattedMessage id="title.elements" defaultMessage="Elements" />
        </span>
      </div>

      <TablePagination
        ActionsComponent={({ onPageChange }): React.ReactElement => (
          <InputPagination onChangePage={onPageChange} />
        )}
        component="div"
        count={count}
        classes={{
          spacer: classes.spacer,
        }}
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={translate('translate#title.rowsPerPage')}
        page={page}
        onRowsPerPageChange={onChangeRowsPerPage}
        onPageChange={handleChangePage}
        SelectProps={{
          native: false,
        }}
        colSpan={1}
        labelDisplayedRows={(): React.ReactElement => {
          return (
            <TablePaginationActions
              count={count}
              onChangePage={handleChangePage}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          )
        }}
      />
    </div>
  )
}

export default Pagination
