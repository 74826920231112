import Box from '@material-ui/core/Box'
import { Delete, Edit } from '@material-ui/icons'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Test from 'react-test-attributes'

import { PosApi } from '@/api/sd/posApi'
import { Option } from '@/api/sd/posApi/types'
import Loader from '@/components/blocks/Loader/component'
import {
  DocumentInformationFields,
  FormattedRequestItems,
} from '@/components/blocks/PSRTicketDetails/components/DocumenInformationModal/components/DocumenInformationTable/types'
import AppTable from '@/components/controls/AppTable'
import Button from '@/components/controls/Button/index'
import { getRequestItems } from '@/store/sd/drawerActions'
import {
  getCreatePOSTicketFormEditingGrantedOperations,
  getMCCCodeRequestFragmentData,
} from '@/store/sd/drawerActions/selectors/newPOS'
import { GrantedOperations } from '@/types'
import { getTestId } from '@/utils'

import { useClasses } from './styles'
import { Props } from './types'

const POS_PRIORITY_ATTR_ID = 3393
const POS_TERMINAL_TYPE_ID = 3431
const POS_CONNECTION_TYPE_ID = 3430
const POS_MCCCode_TYPE_ID = 3282

const isMCCCodeRequest = (data: Array<any>, value: string): string => {
  const requestItemsMCC = data.reduce((acc: any, item: any) => {
    if (item.mccCode) {
      acc = item.mccCode
    }
    return acc
  }, '')

  if (!value?.length) {
    return requestItemsMCC
  }
  return requestItemsMCC !== value ? value : requestItemsMCC
}

const ServicesSummaryTable = ({
  handleEditService,
  handleRemoveService,
  data,
  testIdPrefix,
  isDeletingServiceItem,
  deletingServiceId,
  isEditingPOSTicket,
}: Props): ReactElement => {
  const classes = useClasses()
  const dispatch = useDispatch()
  const idPrefix = `${testIdPrefix}-services-summary-table`
  const testId = getTestId(idPrefix)
  const editingGrantedOperations = useSelector(getCreatePOSTicketFormEditingGrantedOperations)
  const mccCodeRequestFragment = useSelector(getMCCCodeRequestFragmentData)
  const requestItems = useSelector(getRequestItems)

  const canEditRequest = useMemo(() => {
    if (isEditingPOSTicket && editingGrantedOperations && Array.isArray(editingGrantedOperations)) {
      return editingGrantedOperations.includes(GrantedOperations.REQUEST_UPDATE_ITEM)
    }
    return true
  }, [isEditingPOSTicket, editingGrantedOperations])

  const canDeleteRequest = useMemo(() => {
    if (isEditingPOSTicket && editingGrantedOperations && Array.isArray(editingGrantedOperations)) {
      return editingGrantedOperations.includes(GrantedOperations.REQUEST_DELETE_ITEM)
    }
    return true
  }, [isEditingPOSTicket, editingGrantedOperations])

  const [tableData, setTableData] = useState<Partial<FormattedRequestItems>[]>([])
  const [dictionaries, setDictionaries] = useState<{
    [DocumentInformationFields.Priority]: Option[]
    [DocumentInformationFields.TerminalType]: Option[]
    [DocumentInformationFields.ConnectionType]: Option[]
    [DocumentInformationFields.MCCCode]: Option[]
  }>({
    [DocumentInformationFields.Priority]: [],
    [DocumentInformationFields.TerminalType]: [],
    [DocumentInformationFields.ConnectionType]: [],
    [DocumentInformationFields.MCCCode]: [],
  })

  const getOptions = async (
    fieldName:
      | DocumentInformationFields.Priority
      | DocumentInformationFields.TerminalType
      | DocumentInformationFields.MCCCode
      | DocumentInformationFields.ConnectionType,
    attributeId: number,
    dependencies: string[] = [],
    dependenciesAdditional: string[] = [],
  ) => {
    try {
      const response = await PosApi.lookupAttribute({
        attributeId,
        isText: false,
        dependencies,
        dependenciesAdditional,
      })

      if (Array.isArray(response)) {
        setDictionaries(currentDictionaries => ({
          ...currentDictionaries,
          [fieldName]: response,
        }))
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  useEffect(() => {
    getOptions(DocumentInformationFields.Priority, POS_PRIORITY_ATTR_ID)
    getOptions(DocumentInformationFields.TerminalType, POS_TERMINAL_TYPE_ID)
    getOptions(DocumentInformationFields.MCCCode, POS_MCCCode_TYPE_ID, [
      requestItems
        ? isMCCCodeRequest(requestItems, mccCodeRequestFragment)
        : mccCodeRequestFragment,
    ])
    // @todo add real terminal id
    getOptions(DocumentInformationFields.ConnectionType, POS_CONNECTION_TYPE_ID, ['1'])
  }, [data])

  useEffect(() => {
    if (data && data.length) {
      const addAttributeNameForRow = (
        resultRow: Partial<FormattedRequestItems>,
        fieldName:
          | DocumentInformationFields.Priority
          | DocumentInformationFields.TerminalType
          | DocumentInformationFields.MCCCode
          | DocumentInformationFields.ConnectionType,
      ) => {
        if (resultRow[fieldName] && dictionaries[fieldName].length) {
          const priorityName = dictionaries[fieldName].find(
            (item: Option) => `${item.id}` === `${resultRow[fieldName]}`,
          )?.name
          if (priorityName) {
            resultRow[fieldName] = priorityName
          }
        }
      }

      const result = data.map(row => {
        const resultRow = { ...row }
        addAttributeNameForRow(resultRow, DocumentInformationFields.Priority)
        addAttributeNameForRow(resultRow, DocumentInformationFields.TerminalType)
        addAttributeNameForRow(resultRow, DocumentInformationFields.ConnectionType)
        addAttributeNameForRow(resultRow, DocumentInformationFields.MCCCode)

        return resultRow
      })
      return setTableData(result as Partial<FormattedRequestItems>[])
    }

    setTableData(data)
  }, [data, dictionaries])

  return (
    <Test id={testId(0)}>
      <div className={classes.wrapper}>
        <AppTable
          isLoading={false}
          actionColumnElement={(id): React.ReactElement => (
            <Box display="flex" alignItems="center">
              <Test id={testId(1)}>
                <Button
                  disabled={!canEditRequest}
                  onClick={handleEditService(id)}
                  width="xs"
                  className={classes.editButton}
                  variant="contained"
                >
                  <Edit />
                </Button>
              </Test>
              <Test id={testId(2)}>
                <Button
                  disabled={!canDeleteRequest}
                  onClick={handleRemoveService(id)}
                  width="xs"
                  variant="contained"
                  className={classes.deleteButton}
                >
                  {isDeletingServiceItem && deletingServiceId === +id ? (
                    <Loader inline className={classes.loader} />
                  ) : (
                    <Delete />
                  )}
                </Button>
              </Test>
            </Box>
          )}
          data={tableData}
        />
      </div>
    </Test>
  )
}

export default ServicesSummaryTable
