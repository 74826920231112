import { makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles(({ spacing }) => ({
  dialog: {
    width: spacing(80),
  },
  paper: {
    padding: spacing(4, 3),
  },
  modalHeader: {
    fontSize: '24px',
    lineHeight: '23px',
    marginBottom: spacing(2),
  },

  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: spacing(2),
  },
  button: {
    height: 40,
    borderRadius: 3,
    fontWeight: 'normal',
    margin: 0,
  },
  errorText: {
    color: '#5c6465',
    marginBottom: spacing(1),
  },

  buttonClose: {
    marginLeft: spacing(1),
  },
}))
