export interface TicketOverview {
  name?: string
  status?: string
}

/// for CustomerRequestItems table
export enum CustomerRequestItemsTableField {
  Id = 'id',
  Type = 'type',
  ProblemArea = 'problemArea',
}

export interface CustomerRequestItemsTable {
  [CustomerRequestItemsTableField.Id]: string
  [CustomerRequestItemsTableField.Type]: string
  [CustomerRequestItemsTableField.ProblemArea]: string
}

/// for ServiceItems table
export enum ServiceItems {
  Id = 'id',
  Type = 'type',
  Closed = 'closed',
  ProblemArea = 'problemArea',
  SLADeadline = 'slaDeadline',
  CloseTime = 'closeTime',
  Reason = 'reason',
}

export interface ServiceItemsTable {
  [ServiceItems.Id]: string
  [ServiceItems.Type]: string
  [ServiceItems.Closed]: string
  [ServiceItems.ProblemArea]: string
  [ServiceItems.SLADeadline]: string
  [ServiceItems.CloseTime]: string
  [ServiceItems.Reason]: string
}
