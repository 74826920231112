// actions
import { actionSecurityNetworksMergeUSM } from '@/store/usm/securityNetworksObjectMerge/actions'
// types
import { ReturnAnyActionsUSM } from '@/store/usm/types/types'
import {
  SecurityNetworksObjectMergeTypes,
  SecurityNetworksObjectsSummary,
} from '@/types/usm/securityObjectsMerge/securityObjectsMerge'
import { ResponseErrorTypes } from '@/types/common/ErrorResponseTypes'
import { WARNING_NO_OBJECTS_FOR_MERGE } from '@/constants/usm/usmModule'

export const initialState = {
  isFetchingSecurityNetworksObjects: false,
  isFetchingGetSummaryObjects: false,
  summaryObjectsList: [] as Array<SecurityNetworksObjectsSummary>,
  commonSecurityNetworksObjectsForUserList: [] as Array<SecurityNetworksObjectMergeTypes>,
  error: null as ResponseErrorTypes | null,
}

export type State = typeof initialState

export type UserGroupAnyActions = ReturnAnyActionsUSM<typeof actionSecurityNetworksMergeUSM>
export const reducer = (state: State = initialState, action: UserGroupAnyActions): State => {
  switch (action.type) {
    case '@/START_FETCHING_SECURITY_OBJECTS_FOR_USERS_USM':
      return {
        ...state,
        isFetchingSecurityNetworksObjects: true,
      }
    case '@/STOP_FETCHING_SECURITY_OBJECTS_FOR_USERS_USM':
      return {
        ...state,
        isFetchingSecurityNetworksObjects: false,
      }
    case '@/FILL_SECURITY_OBJECTS_FOR_USERS_RESPONSE_USM':
      return {
        ...state,
        error: null,
        commonSecurityNetworksObjectsForUserList: action.payload,
      }
    case '@/SET_SECURITY_OBJECTS_FOR_USERS_RESPONSE_FALL_USM':
      return {
        ...state,
        error: action.payload,
      }
    case '@/GET_SECURITY_OBJECTS_FOR_USER_ASYNC_USM':
      return state
    case '@/START_FETCHING_SECURITY_OBJECTS_SUMMARY_USM':
      return {
        ...state,
        isFetchingGetSummaryObjects: true,
      }
    case '@/STOP_FETCHING_SECURITY_OBJECTS_SUMMARY_USM':
      return {
        ...state,
        isFetchingGetSummaryObjects: false,
      }
    case '@/FILL_SECURITY_OBJECTS_SUMMARY_FOR_USERS_RESPONSE_USM': {
      const typeId = state.summaryObjectsList.map(i => i.typeId)
      return {
        ...state,
        error: !action.payload.length
          ? {
              errorMessage: WARNING_NO_OBJECTS_FOR_MERGE,
              fieldErrors: null,
              globalErrors: null,
              timestamp: '',
            }
          : null,
        summaryObjectsList: [
          ...state.summaryObjectsList,
          ...action.payload.filter(o => !typeId.includes(o.typeId)),
        ],
      }
    }
    case '@/GET_SECURITY_OBJECTS_SUMMARY_FOR_USER_ASYNC_USM':
      return state
    case '@/FILTER_SECURITY_OBJECTS_SUMMARY_MERGE_TO_USERS_USM':
      return {
        ...state,
        error: null,
        summaryObjectsList: state.summaryObjectsList.filter(o => o.typeId !== action.payload),
      }

    case '@/START_FETCHING_UPDATE_SECURITY_OBJECTS_SUMMARY_MERGE_USM':
      return {
        ...state,
        isFetchingGetSummaryObjects: true,
        isFetchingSecurityNetworksObjects: true,
      }
    case '@/STOP_FETCHING_UPDATE_SECURITY_OBJECTS_SUMMARY_MERGE_USM':
      return {
        ...state,
        isFetchingSecurityNetworksObjects: false,
        isFetchingGetSummaryObjects: false,
      }
    case '@/FILL_UPDATE_SECURITY_OBJECTS_SUMMARY_TO_USER_RESPONSE_USM':
      return {
        ...state,
        error: null,
        summaryObjectsList: [],
      }
    case '@/UPDATE_SECURITY_OBJECTS_SUMMARY_TO_USER_ASYNC_USM':
      return state
    case '@/CHANGE_SELECTED_USERS_UPDATE_SECURITY_OBJECTS_USM':
      return {
        ...state,
        summaryObjectsList: [],
      }
    case '@/SET_CLEAR_WARNING_MESSAGE_SECURITY_OBJECTS_MERGE_USM':
      return {
        ...state,
        error: null,
      }
    case '@/SET_CLEAR_ERROR_SECURITY_OBJECTS_MERGE_USM':
      return {
        ...state,
        error: null,
        summaryObjectsList: [],
      }
    default:
      // eslint-disable-next-line no-case-declarations,@typescript-eslint/no-unused-vars
      const neverCase: never = action
  }
  return state
}
