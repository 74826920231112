import { AnyAction } from 'redux'

import { AdmRealTimeMonitoring } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants/actions'

export type State = {
  data: AdmRealTimeMonitoring | null
  isLoading: boolean
  isLoaded: boolean
}

export const initialState: State = {
  data: null,
  isLoading: false,
  isLoaded: false,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case CMAppConfigActions.AdmSaveRealTimeMonitoringRequest:
    case CMAppConfigActions.AdmGetRealTimeMonitoringRequest:
      return {
        ...state,
        isLoading: true,
      }
    case CMAppConfigActions.AdmGetRealTimeMonitoringResponse:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: payload,
      }
    case CMAppConfigActions.AdmGetRealTimeMonitoringFail:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: null,
      }
    default:
      return state
  }
}
