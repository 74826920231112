import React, { FC } from 'react'

import { useTranslate } from '@/utils/internalization'

import { EmptyPanelWrapper } from './styles'
import { Props } from './types'

export const EmptyPanel: FC<Props> = React.memo((props: Props) => {
  const { placeholder } = props

  const translate = useTranslate()

  return (
    <EmptyPanelWrapper>
      {placeholder || translate('translate#vault.NothingToShow')}
    </EmptyPanelWrapper>
  )
})
