import { ReactElement } from 'react'
import { AnyAction } from 'redux'

import { BalUnloadedCassetteEntry, BalUnloadedCassettesSession } from '@/api/cm/swaggerGeneratedApi'
import {
  EditableColumns,
  SetBalUnloadedCassettes,
} from '@/components/blocks/cm/CashBalancingModal/components/UnloadedCassettes/types'

export interface Props {
  isSorters: boolean
  setBalUnloadedCassettes: SetBalUnloadedCassettes
}

export enum TableColumns {
  CassetteType = 'cassetteType',
  CassetteCode = 'cassetteCode',
  Stamp = 'stamp',
  CassetteNr = 'cassetteNr',
  Denomination = 'denomination',
  LoadSum = 'loadSum',
  Loaded = 'loaded',
  Deposit = 'deposit',
  Dispensed = 'dispensed',
  Remaining = 'remaining',
  Reject = 'reject',
  Retract = 'retract',
  Balance = 'balance',
  BalanceDate = 'balanceDate',
}

export enum Keys {
  Id = 'id',
  CassetteType = 'cassetteType',
  CassetteCode = 'cassetteCode',
  Stamp = 'stamp',
  CassetteNr = 'cassetteNr',
  Denomination = 'denomination',
  LoadSum = 'loadSum',
  Loaded = 'loadQuantity',
  Deposit = 'deposit',
  Dispensed = 'dispensed',
  Remaining = 'remaining',
  Reject = 'reject',
  Retract = 'retract',
  Balance = 'balance',
  BalanceDate = 'balanceDate',
}

export interface HeaderConfig {
  [key: string]: string | ReactElement
}

export interface CassetteValueChangeProps {
  cassette: BalUnloadedCassetteEntry
  key:
    | EditableColumns.Dispensed
    | EditableColumns.Deposit
    | EditableColumns.Remaining
    | EditableColumns.Reject
    | EditableColumns.Retract
    | EditableColumns.CashIn
    | EditableColumns.KioskCashInReal
    | EditableColumns.KioskCashInSlip
}

export interface ConditionalCellProps {
  classes: Record<string, string>
  isSorters: boolean
  handleValueChange: ({
    cassette,
    key,
  }: CassetteValueChangeProps) => (newValue: string | number) => void
}

export type EntryType = { Currency: string; Denomination: number; Quantity: number }

export type SortersKeyType =
  | EditableColumns.Remaining
  | EditableColumns.Reject
  | EditableColumns.Retract
  | EditableColumns.CashIn

export interface SortersEntriesProps {
  isSorters: boolean
  entries?: EntryType[]
  unloadedCassettes: BalUnloadedCassettesSession | null
  setBalUnloadedCassettes: (unloadedCassettes: BalUnloadedCassettesSession) => AnyAction
}
