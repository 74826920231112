import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  button: {
    padding: spacing(0.5),
  },
  counterBtn: {
    height: 40,
    fontWeight: 'normal',
    padding: '0 10px',
    margin: 0,
    marginRight: '50px',
    whiteSpace: 'nowrap',
    fontSize: '14px',

    '& svg': {
      height: '17px',
      width: '20px',
    },
  },
  icon: {
    color: palette.grey[200],
  },
  label: {
    padding: spacing(0, 1),
  },
  userName: {
    padding: spacing(0, 1),
  },

  menuWrapper: {
    color: palette.grey[50],
    marginLeft: spacing(20),
  },

  item: {
    padding: spacing(1, 2),
    paddingLeft: spacing(0),
  },
  menuItems: {
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
  },

  // style for IE11
  list: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    paddingBottom: spacing(1),
    paddingTop: spacing(1),
  },
  menuPaper: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkbox: {
    padding: 0,
  },
}))
