import React, { ReactElement, useCallback, useMemo, useContext, useEffect } from 'react'

import clsx from 'clsx'

import { Checkbox } from '@material-ui/core'
import FolderIcon from '@material-ui/icons/FolderOpen'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import { getDeppNodes } from '../helpers'

import { ObjectsTreeContext } from '../component'

import { CheckboxProps } from './types'
import { useClasses } from './styles'

const CheckBoxWrapper = ({ open, setOpen, node }: CheckboxProps): ReactElement => {
  const classes = useClasses()

  const { setCheckedIds, checkedIds, checkboxesTree } = useContext(ObjectsTreeContext)

  const localCheckedIds = checkedIds || []

  const isChecked = useMemo(() => {
    return localCheckedIds.some(id => id === node.value)
  }, [node, checkedIds])

  const childIds = useMemo(() => {
    return getDeppNodes(node.children || [])
  }, [node])

  const isLeafsExist = useMemo(() => {
    return (node?.children || []).some(child => !child.showCheckbox)
  }, [node])

  const { isSomeChildChecked, isEveryChildChecked, isEveryChildNotChecked } = useMemo(() => {
    let isEveryChildChecked = false
    let isEveryChildNotChecked = false
    const isSomeChildChecked = childIds.some(nodeId => localCheckedIds.some(id => id === nodeId))

    if (childIds.length) {
      isEveryChildChecked = childIds.every(nodeId => localCheckedIds.some(id => id === nodeId))
      isEveryChildNotChecked = childIds.every(nodeId => !localCheckedIds.some(id => id === nodeId))
    }

    return {
      isSomeChildChecked,
      isEveryChildChecked,
      isEveryChildNotChecked,
    }
  }, [localCheckedIds, childIds])

  useEffect(() => {
    if (
      isEveryChildNotChecked &&
      !isLeafsExist &&
      setCheckedIds &&
      localCheckedIds.includes(node.value || '')
    ) {
      setCheckedIds(localCheckedIds.filter(id => id !== node.value))
    }
  }, [isEveryChildNotChecked])

  const handleClick = useCallback(() => {
    setOpen(!open)
  }, [open])

  const handleCheckbox = useCallback(
    event => {
      if (setCheckedIds) {
        const { checked } = event.target
        const allChildIds = getDeppNodes(node.children || [])

        if (checked) {
          setCheckedIds([...localCheckedIds, node.value || '', ...(allChildIds || [])])
        } else {
          const filterCheckedIds = localCheckedIds
            .filter(checkedId => checkedId !== node.value)
            .filter(checkedId => !allChildIds.includes(checkedId))

          setCheckedIds(filterCheckedIds)
        }
      }
    },
    [node, localCheckedIds, setCheckedIds],
  )

  return (
    <>
      <span onClick={handleClick} className={classes.arrowWrap}>
        {open ? (
          <KeyboardArrowDownIcon className={clsx(classes.arrow, classes.arrowDown)} />
        ) : (
          <KeyboardArrowRightIcon className={classes.arrow} />
        )}
      </span>
      {setCheckedIds && node.showCheckbox && (
        <Checkbox
          onClick={handleCheckbox}
          checked={isChecked || false}
          className={classes.checkbox}
          indeterminate={
            (isEveryChildChecked && !isChecked) ||
            (isChecked && isEveryChildNotChecked) ||
            (isSomeChildChecked && !isEveryChildChecked) ||
            false
          }
        />
      )}
      {!checkboxesTree && (
        <FolderIcon
          classes={{ root: clsx(classes.folderIcon, { [classes.selectedFolder]: open }) }}
        />
      )}
    </>
  )
}

export default React.memo(CheckBoxWrapper)
