import Box from '@material-ui/core/Box'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import NoteAdd from '@material-ui/icons/NoteAdd'
import clsx from 'clsx'
import React, { SyntheticEvent, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Test from 'react-test-attributes'

import Button from '@/components/controls/Button'
import AddNewCompanyModal from '@/components/wrappers/AppNavigation/components/DrawerActions/components/modals/AddNewCompanyModal'
import { setInitialUnitsAdvanced } from '@/store/companies/companies/actions'
import { getTestId } from '@/utils'
import { translate } from '@/utils/internalization'

import { useClasses } from './styles'
import { Props } from './types'

const DrawerActionsCompanies = ({ showClosed = false }: Props): React.ReactElement => {
  const classes = useClasses()
  const testId = getTestId('companies-drawer-actions')
  const [openNewCompanyModal, setOpenNewCompanyModal] = React.useState(false)
  const dispatch = useDispatch()

  // const handleCloseMenu = useCallback((): void => {
  //   setAnchorEl(null)
  // }, [])

  // const addCompany = () => {
  //   console.log('addCompany Companies')
  // }

  const handleCloseNewCompanyModal = useCallback(() => {
    // setAnchorEl(event.currentTarget)
    setOpenNewCompanyModal(false)
    dispatch(setInitialUnitsAdvanced())
    // addCompany()
  }, [])

  const handleOpenNewCompanyModal = useCallback(() => {
    // setAnchorEl(null)
    setOpenNewCompanyModal(true)
    // handleCloseMenu()
    // addCompany()
  }, [])

  return (
    <Test id={testId(0)}>
      <div>
        <ListItem style={{ padding: '8px 0' }}>
          <Test id={testId(1)}>
            <Button
              fullWidth
              width="xs"
              variant="contained"
              onClick={handleOpenNewCompanyModal}
              className={classes.createButton}
            >
              <Box display="flex" alignItems="center" justifySelf="flex-start">
                <NoteAdd className={clsx(classes.icon, { [classes.iconClosed]: showClosed })} />
              </Box>
              {!showClosed && (
                <ListItemText>
                  <Box textAlign="left" className={classes.text}>
                    {translate('translate#companies.action.add.newCompany')}
                  </Box>
                </ListItemText>
              )}
            </Button>
          </Test>
        </ListItem>
        <AddNewCompanyModal
          open={openNewCompanyModal}
          handleClose={handleCloseNewCompanyModal}
          disableBackdropClick
        />
      </div>
    </Test>
  )
}

export default DrawerActionsCompanies
