import moment, { Moment } from 'moment'

import { TIME_FORMAT } from '@/constants/time'
import { Day, ScheduleFormValues } from '@/types/adm/device/local'

export const formatTime = (time: string): Moment => {
  return moment(time, TIME_FORMAT)
}

export const validateTimeTo1 = (day: Day): boolean => {
  return (
    day === undefined || !day.isActive || formatTime(day.timeTo1).isAfter(formatTime(day.timeFrom1))
  )
}

export const validateTimeFrom2 = (day: Day): boolean => {
  return (
    day === undefined ||
    !day.isActive ||
    (day.timeTo2 === '00:00' && day.timeFrom2 === '00:00') ||
    formatTime(day.timeFrom2).isAfter(formatTime(day.timeTo1))
  )
}

export const validateTimeTo2 = (day: Day): boolean => {
  return (
    day === undefined ||
    !day.isActive ||
    (day.timeTo2 === '00:00' && day.timeFrom2 === '00:00') ||
    formatTime(day.timeTo2).isAfter(formatTime(day.timeFrom2))
  )
}

export const validateTimeOrder = (day: Day): boolean => {
  return validateTimeTo1(day) && validateTimeFrom2(day) && validateTimeTo2(day)
}

const validatePattern = (day: Day, timePattern: RegExp): boolean => {
  return (
    !day.isActive ||
    (timePattern.test(day.timeFrom1) &&
      timePattern.test(day.timeFrom2) &&
      timePattern.test(day.timeTo1) &&
      timePattern.test(day.timeTo2))
  )
}

export const validateShedulePattern = (
  schedule: ScheduleFormValues,
  timePattern: RegExp,
): boolean => {
  return Object.keys(schedule)
    .map(key => validatePattern(schedule[key as keyof ScheduleFormValues], timePattern))
    .reduce((a, b) => a && b)
}

export const validateSheduleTimeOrder = (schedule: ScheduleFormValues): boolean => {
  return Object.keys(schedule)
    .map(key => validateTimeOrder(schedule[key as keyof ScheduleFormValues]))
    .reduce((a, b) => a && b)
}
