import { FormikValues } from 'formik'
import { POSServiceTypeItem } from '@/components/blocks/POSRequestForm/types'

export interface Props {
  handleSubmit: (values: FormikValues) => void
  merchantFullName: string
  editRow?: POSServiceTypeItem | FormikValues
  handleClose: () => void
  testIdPrefix?: string
  merchantId: number
  merchantValue: string
  serviceTypeId: string
  withTerminalIdsField?: boolean
  withSingleTerminalSelecting?: boolean
  title?: string
  selectedTerminalIds?: string[]
}

export type WorkingHoursBlockError = { key: string; fromValue?: string; toValue?: string }

export const FIELD_NAME_TERMINAL_IDS = 'terminalIds'
export const FIELD_NAME_TERMINALS = 'terminals'
export const POS_AQUIRER_LUNO = 'posaquirerluno'
export const POS_TERMINAL_ID_AMEX = 'posterminalid_amex'
export const POS_MERCHANT_ID = 'posmerchantid'
export const POS_MERCHANT_ID_AMEX = 'posmerchantid_amex'
export const POS_SIM_OF_CLIENT = 'possimofclient'
export const POS_INTEGRATION_WITH_ERC = 'posintegrationwitherc'
