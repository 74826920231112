import { AnyAction } from 'redux'

import { AdmLicenseInfo } from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants/actions'

export type State = {
  selectedPanel: string

  license: {
    data: AdmLicenseInfo
    isLoading: boolean
    isLoaded: boolean
    error: string | null
  }
}

export const initialState: State = {
  selectedPanel: 'License',
  license: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case VaultV1Actions.AdmSetSelectedLicensePanel:
      return {
        ...state,
        selectedPanel: payload,
      }

    case VaultV1Actions.AdmGetLicenseRequest:
      return {
        ...state,
        license: {
          ...state.license,
          isLoading: true,
        },
      }
    case VaultV1Actions.AdmGetLicenseResponse:
      return {
        ...state,
        license: {
          ...state.license,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      }
    case VaultV1Actions.AdmGetLicenseFail:
      return {
        ...state,
        license: {
          ...state.license,
          isLoading: false,
          isLoaded: true,
          error: payload,
        },
      }

    case VaultV1Actions.SignOut:
      return initialState
    default:
      return state
  }
}
