import { Box } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Alert } from '@material-ui/lab'
import clsx from 'clsx'
import { Field, FieldProps, Form, FormikProps } from 'formik'
import _ from 'lodash'
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { isIE } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Test from 'react-test-attributes'

import { Role } from '@/api/companies/companiesApi/types'
import Button from '@/components/controls/Button'
import { createCheckboxField } from '@/components/controls/Checkbox'
import { createCheckboxGroupField } from '@/components/controls/CheckboxGroup/component'
import { createDropDownInputField } from '@/components/controls/DropDownInput/component'
import DynamicField from '@/components/controls/DynamicField/component'
import GeneratedField from '@/components/controls/GeneratedField'
import { BackupOptions, FieldTypeName } from '@/components/controls/GeneratedField/types'
import { createSelectField } from '@/components/controls/Select/component'
import { createTextInputField } from '@/components/controls/TextInput/component'
import WorkingHours from '@/components/controls/WorkingHours/component'
import {
  AddNewCompanyFormFields,
  AddNewCompanyFormValues,
} from '@/constants/forms/AddNewCompanyForm'
import { COMPANIES_BLOCK_PREFIX } from '@/constants/paths'
import { getGlobalErrorCompanies } from '@/store/companies/companies'
import {
  findUnitsRequest,
  getCompanyTypes,
  getUnits,
  isLookingForUnits,
  setInitialServiceCompanies,
} from '@/store/companies/drawerActions'
import { getTestId } from '@/utils/getTestId'
import { useTranslate } from '@/utils/internalization'

// import { timezones } from '@/constants/timezones'
import { useClasses } from '../styles'
import { Props } from '../types'

const AddNewCompanyFormStepRoles = ({
  values,
  setFieldValue,
  ...props
}: FormikProps<AddNewCompanyFormValues>): any => {
  const propsi = { display: isIE ? 'block' : 'flex' }
  const classes = useClasses(propsi)
  const testId = getTestId('companies-add-new-company-form-step-4')
  const dispatch = useDispatch()
  const translate = useTranslate()
  const [options, setOptions] = useState<{ name: string; value: string }[]>([])
  // const roles = values[AddNewCompanyFormFields.Roles]
  //   .sort((a: Role, b: Role) => a.value.localeCompare(b.value))

  const roles = values[AddNewCompanyFormFields.Roles].sort((a: Role, b: Role) => {
    if (a.orderNr === null) return 1
    if (b.orderNr === null) return -1
    if (a.orderNr === b.orderNr) return 0
    return a.orderNr < b.orderNr ? -1 : 1
  })

  // const halfway = Math.ceil(roles.length / 2)
  // const rolesLeftSide = roles.slice(0, halfway)
  // const rolesRightSide = roles.slice(halfway, roles.length)

  const rolesFirstColumn = roles.filter(
    (role: Role) => role.orderNr != null && role.orderNr >= 100 && role.orderNr < 200,
  )
  const rolesSecondColumn = roles.filter(
    (role: Role) => role.orderNr != null && role.orderNr >= 200 && role.orderNr < 300,
  )
  const rolesThirdColumn = roles.filter(
    (role: Role) => role.orderNr == null || (role.orderNr >= 300 && role.orderNr < 400),
  )

  // useEffect(() => {
  //   try {
  //     if (Array.isArray(roles)) {
  //       const aa = roles.map(role => ({
  //         name: role.value,
  //         value: `${role.id}`,
  //       }))
  //       setOptions(aa)
  //     }

  //     // const dependencies: string[] = []

  //     // const lookup = {
  //     //   attributeId: 3219,
  //     //   dependencies: [],
  //     //   isText: false,
  //     //   dependenciesAdditional: [],
  //     // }

  //     // CompaniesApi.lookupAttribute(lookup).then(values => {

  //     //   if (Array.isArray(values)) {
  //     //     const aa = values.map(({ id, name }: { id: number; name: string }) => ({
  //     //       name,
  //     //       value: `${id}`,
  //     //     }))
  //     //     setOptions(aa)
  //     //   }
  //     // })
  //   } catch (e) {
  //     throw new Error(e)
  //   }
  // }, [])

  // const RolesCheckBoxList = createCheckboxGroupField({
  //   label: translate('translate#companies.title.addNewCompany.roles'),
  //   options,
  //   isRequired: true,
  //   width: '30%',
  //   // withClasses: {
  //   //   label: classes.checkboxGroupLabel,
  //   // },
  // })
  let rolesIndex = 0
  return (
    <Test id={testId(0)}>
      <Grid className={classes.container} container>
        <Typography variant="h6" className={classes.title}>
          {translate('translate#companies.title.addNewCompany.roles')}
        </Typography>
        <Grid container alignItems="flex-start">
          <Grid item md={4}>
            {rolesFirstColumn.map(({ id, value, isChecked }, index) => {
              const name = `roles[${rolesIndex}].isChecked`
              rolesIndex++
              return (
                <Grid key={name} item md={12}>
                  <Field name={name}>
                    {createCheckboxField({
                      label: value,
                      checked: isChecked, // values[name],
                      // disabled: disableAction,
                      onClick: event => event.stopPropagation(),
                      onChange: (event, checked) => {
                        setFieldValue(name, checked)
                        // handleChange({ ...values, [name]: checked })
                      },
                      onClickLabel: event => {
                        event.stopPropagation()
                      },
                    })}
                  </Field>
                </Grid>
              )
            })}
            {/* <Field name={AddNewCompanyFormFields.Roles}>{RolesCheckBoxList}</Field> */}
          </Grid>
          <Grid item md={4}>
            {rolesSecondColumn.map(({ id, value, isChecked }, index) => {
              const name = `roles[${rolesIndex}].isChecked`
              rolesIndex++
              return (
                <Grid key={name} item md={12}>
                  <Field name={name}>
                    {createCheckboxField({
                      label: value,
                      checked: isChecked, // values[name],
                      // disabled: disableAction,
                      onClick: event => event.stopPropagation(),
                      onChange: (event, checked) => {
                        setFieldValue(name, checked)
                        // handleChange({ ...values, [name]: checked })
                      },
                      onClickLabel: event => {
                        event.stopPropagation()
                      },
                    })}
                  </Field>
                </Grid>
              )
            })}
            {/* <Field name={AddNewCompanyFormFields.Roles}>{RolesCheckBoxList}</Field> */}
          </Grid>
          <Grid item md={4}>
            {rolesThirdColumn.map(({ id, value, isChecked }, index) => {
              const name = `roles[${rolesIndex}].isChecked`
              rolesIndex++
              return (
                <Grid key={name} item md={12}>
                  <Field name={name}>
                    {createCheckboxField({
                      label: value,
                      checked: isChecked, // values[name],
                      // disabled: disableAction,
                      onClick: event => event.stopPropagation(),
                      onChange: (event, checked) => {
                        setFieldValue(name, checked)
                        // handleChange({ ...values, [name]: checked })
                      },
                      onClickLabel: event => {
                        event.stopPropagation()
                      },
                    })}
                  </Field>
                </Grid>
              )
            })}
            {/* <Field name={AddNewCompanyFormFields.Roles}>{RolesCheckBoxList}</Field> */}
          </Grid>
        </Grid>
      </Grid>
    </Test>
  )
}

export { AddNewCompanyFormStepRoles }
