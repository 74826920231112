import { useQuery, UseQueryResult } from 'react-query'

import { VaultBagCassette } from '@/api/vault-v1/swaggerGeneratedApi'
import { queryClient } from '@/constants/queryClient'

import { BagsApi } from '..'

const queryOptions = {
  retry: 0,
  refetchOnWindowFocus: false,
}

export const useQueryBagDetails = (guid?: string): UseQueryResult<VaultBagCassette[], Error> =>
  useQuery(['bagDetails', guid], async () => guid && (await BagsApi.details(guid)), {
    ...queryOptions,
    enabled: !!guid,
  })

export const refetchBagDetails = (guid?: string): unknown =>
  queryClient.fetchQuery(['bagDetails', guid])
