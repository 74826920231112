// core
import { Typography } from '@material-ui/core'
import React, { FC, ReactElement } from 'react'

import Logo from '@/components/blocks/Logo/component'
import { Select } from '@/components/controls/Select/component'
// hooks
import { useChooseProduct } from '@/components/pages/common/SignInPage/components/ChooseProduct/hooks/useChooseProduct'
import { ButtonActionSignIn } from '@/components/pages/common/SignInPage/hooks/useSignInAction'
// components
import { LogoLocalBlock } from '@/components/wrappers/SignInPage/components/component'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'

type Product = {
  name: string
  value: string
}

const ChooseProduct: FC = (): ReactElement => {
  const translate = useTranslate()
  const classes = useClasses()

  const {
    handleSignOut,
    handleSetProduct,
    handleChange,
    patchForProducts,
    product,
    availableProducts,
    setProductAndLogin,
  } = useChooseProduct()

  const productsJSX = availableProducts.reduce((acc, availableProduct: string): Product[] => {
    return [...acc, ...patchForProducts[availableProduct]]
  }, [] as Product[])

  return (
    <>
      <div className={classes.chooseProductTitle}>
        <Typography variant="inherit">
          {translate(`translate#login.form.title.select.product`)}
        </Typography>
      </div>
      <div className={classes.selectProductWrapper}>
        <div className={classes.selectProductContainer}>
          {productsJSX?.map((product, index) => {
            return (
              <div
                key={`select-product-${index}`}
                className={classes.productItem}
                onClick={(e: any) => setProductAndLogin(product.value)}
              >
                <Logo type={product.value as '/sd' | '/atmeye'} />
              </div>
            )
          })}
        </div>
      </div>
      {/* <Select
        label={translate(`translate#login.form.title.select.product`)}
        hideEmptyItem
        value={product}
        onChange={handleChange}
        options={productsJSX}
      /> */}
      <ButtonActionSignIn
        translate={{
          idSubmit: 'action.next',
          messageSubmit: 'Next',
          idCancel: 'action.cancel',
          messageCancel: 'Cancel',
        }}
        showButtonCancel
        showButtonSubmit={false}
        styleForButtons="center"
        onCliCkCancelAction={handleSignOut}
        onclickAction={handleSetProduct}
      />
    </>
  )
}

export { ChooseProduct }
