import queryString from 'query-string'

import { AdmBank, AdmBankBase } from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class BanksApi {
  private static requestService = getRequestServiceCM()

  public static async getAll(sort: Sort): Promise<AdmBank[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/banks',
          query: {
            ...sort,
          },
        },
        { skipNull: true, encode: false },
      )
      return await BanksApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getNew(): Promise<void> {
    try {
      return await BanksApi.requestService.get('/admin/banks/new')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(payload: AdmBankBase): Promise<void> {
    try {
      return await BanksApi.requestService.post('/admin/banks/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(payload: AdmBank): Promise<void> {
    try {
      return await BanksApi.requestService.put('/admin/banks/update', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(payload: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'admin/banks/delete',
          query: {
            guid: payload,
          },
        },
        { skipNull: true, encode: false },
      )
      return await BanksApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
