import {
  Device,
  DevicesFilter,
  DeviceStatus,
  GetDevicesGroupsRequest,
} from '@/api/atmeye/devicesApi/types'
import { GroupNode } from '@/components/NPMPakage/components/ObjectsTree/types'
import { ResponseError } from '@/types'
import { requestService } from '@/utils/services/request'

import { TableListReponse } from '../commonTypes'

export class DevicesApi {
  public static async getDevicesList({
    sort,
    size,
    page,
    ...payload
  }: {
    filter: DevicesFilter
    groupingFilters?: DevicesFilter[]

    size: number
    page: number
    sort: string
  }): Promise<TableListReponse<Device>> {
    try {
      return await requestService.post(
        `/atmeye/api/v1/devices-table/list?sort=${sort}&size=${size}&page=${page - 1}`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getDevicesGroups(
    payload: GetDevicesGroupsRequest,
  ): Promise<{ groupNodes: GroupNode[] }> {
    try {
      return await requestService.post(`/atmeye/api/v1/devices-table/groups`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getActionsState(deviceId: number): Promise<DeviceStatus | undefined> {
    try {
      return await requestService.get('/device-adapter/api/v1/actions/state', {
        deviceId,
      })
    } catch (e) {
      console.log(e)
    }
  }

  public static async getHealthInfo(deviceName: string): Promise<any> {
    try {
      return await requestService.get(`/atmeye/api/v1/devices/health-info?deviceName=${deviceName}`)
    } catch (e) {
      console.log(e)
      // throw new Error(e)
    }
  }

  public static async getExportData(
    {
      sort,
      ...payload
    }: {
      filter: DevicesFilter
      config: {
        format: string
        appliedFilters: {
          filterLabel: string
          filterValue: string
        }[]
        selectedOutputFields: any
      }
      sort?: string
    },
    buffer?: boolean,
  ): Promise<any | ResponseError> {
    try {
      return await requestService.post(
        `/atmeye/api/v1/devices-table/ungrouped-export?sort=${sort}`,
        payload,
        true,
        buffer && { responseType: 'arraybuffer' },
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getExportGroupedData(
    {
      sort,
      ...payload
    }: {
      exportedGroupsData: {
        groupsInfo: any
        nodeFilter: any
      }
      config: {
        format: string
        appliedFilters: {
          filterLabel: string
          filterValue: string
        }[]
        selectedOutputFields: any
      }
      sort?: string
    },
    buffer?: boolean,
  ): Promise<any | ResponseError> {
    try {
      return await requestService.post(
        `/atmeye/api/v1/devices-table/grouped-export?sort=${sort}`,
        payload,
        true,
        buffer && { responseType: 'arraybuffer' },
      )
    } catch (e) {
      throw new Error(e)
    }
  }
}
