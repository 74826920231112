import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { LogoAdminApi } from '@/api/cm/adminApi'
import { CMAppConfigActions } from '@/constants'
import { setComCMError } from '@/store/cm/common'

import {
  getAdmApprovingLogoFail,
  getAdmApprovingLogoResponse,
  getAdmLogoFail,
  getAdmLogoRequest,
  getAdmLogoResponse,
} from './actions'

function* getLogo(): SagaIterator {
  try {
    const image = yield call(LogoAdminApi.get)

    yield put(getAdmLogoResponse(image))
  } catch (error) {
    yield put(getAdmLogoFail())
  }
}

function* getApprovingLogo(): SagaIterator {
  try {
    const image = yield call(LogoAdminApi.getApproving)

    yield put(getAdmApprovingLogoResponse(image))
  } catch (error) {
    if (error instanceof Error) {
      const { message } = JSON.parse(error.message) || {}

      yield put(getAdmApprovingLogoFail(message || ''))
    }
  }
}

function* setApprovingLogo({ payload }: AnyAction): SagaIterator {
  try {
    yield call(LogoAdminApi.setApproving, payload)

    yield put(getAdmLogoRequest())
  } catch (error) {
    yield put(setComCMError(error))
  }
}

export default function*(): Generator {
  yield takeLatest(CMAppConfigActions.AdmGetLogoRequest, getLogo)

  yield takeLatest(CMAppConfigActions.AdmGetApprovingLogoRequest, getApprovingLogo)
  yield takeLatest(CMAppConfigActions.AdmSetApprovingLogoRequest, setApprovingLogo)
}
