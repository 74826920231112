import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { Typography, Grid } from '@material-ui/core'
import { useTranslate } from '@/utils/internalization'
import Tooltip from '@/components/blocks/Tooltip'
import { useClasses } from './styles'

interface Props {
  item: string
  value: string | number | null
}

const cellPaddingSum = 32

const MerchantTableRow = ({ item, value }: Props): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  const [tooltip, setTooltip] = useState<boolean>(false)

  const textWrapperRef = useRef<HTMLParagraphElement>(null)
  const containerWrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const containerWrapWith = containerWrapperRef.current
      ? containerWrapperRef.current.offsetWidth
      : 0
    const textWrapperWidth = textWrapperRef.current ? textWrapperRef.current.offsetWidth : 0

    if (containerWrapWith / 2 - cellPaddingSum < textWrapperWidth) {
      setTooltip(true)
    }
  }, [containerWrapperRef, containerWrapperRef.current])
  return (
    <>
      {value && (
        <Grid ref={containerWrapperRef} className={classes.contentItem}>
          <Typography className={classes.contentItemTitle}>
            {item ? translate(`translate#title.${item}`) : ''}
          </Typography>
          <Typography
            className={clsx(classes.contentItemText, {
              [classes.contentItemTextWithTooltip]: tooltip,
            })}
          >
            {tooltip ? (
              <Tooltip className={classes.tooltip} title={value || ''}>
                <span
                  className={clsx({
                    [classes.withTooltip]: tooltip,
                    [classes.contentItemTextWithTooltip]: tooltip,
                  })}
                >
                  {value}
                </span>
              </Tooltip>
            ) : (
              <span ref={textWrapperRef}>{value}</span>
            )}
          </Typography>
        </Grid>
      )}
    </>
  )
}

export default MerchantTableRow
