import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { CMCommonApi } from '@/api/cm/commonApi'
import { PlanningApi } from '@/api/cm/planningApi/index'
import { SharedApi } from '@/api/cm/sharedApi'
import { ComVerboseError, PlnCashPoint } from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants'
import {
  getComCassetteTypesFail,
  getComCassetteTypesResponse,
  setGlobalFilter,
} from '@/store/cm/common/actions'
import { AppState } from '@/store/reducers'
import { hotKeysBinder } from '@/utils/hotKeysBinder'

import { setComCMError } from '../common'
import {
  checkPlnAllCashPoints,
  getPlnCashPointsRequest,
  getPlnCashPointsResponse,
  getPlnCashPointsResponseFail,
  getPlnConfigResponse,
  getPlnConfigResponseFail,
  getPlnDeclineNoteResponse,
  getPlnDeclineNoteResponseFail,
  getPlnModifyExpensesResponse,
  getPlnModifyExpensesResponseFail,
  getPlnReportResponse,
  getPlnReportResponseFail,
  getPlnReportsResponse,
  getPlnReportsResponseFail,
  getPlnSettingsResponse,
  getPlnSettingsResponseFail,
  modifyPlnConfigFail,
  modifyPlnConfigResponse,
  plnCalculateExtResponse,
  plnSetDataCountToUpdate,
  refreshPlanning,
  refreshPlnCashPointRequest,
  refreshPlnCashPointResponse,
  refreshPlnCashPointResponseFail,
  resetPlnCashPointsPage,
  setPlnCashPointsFilter,
  setPlnCashPointsPagingResponse,
  setPlnDocumentModalOpen,
  setPlnFiltersModalOpen,
  setPlnLoadingModalOpen,
  setPlnModifyApplyDisabled,
  setPlnSelectedCashPoint,
  togglePlnCashPoint,
} from './actions'
import { getPlnCashPoints } from './selectors'

function* getCashPoints(): SagaIterator {
  try {
    const paging = yield select(state => {
      const { page, pageSize } = state?.cmPlanning?.cashPoints?.paging
      return {
        page,
        pageSize: pageSize === -1 ? 'All' : pageSize,
      }
    })
    const sort = yield select(state => ({
      sortColumn: state.cmPlanning.cashPoints.sortColumn,
      sortOrder: state.cmPlanning.cashPoints.sortOrder,
    }))
    const storeFilter = yield select(state => ({
      globalFilter: state?.cmCommon?.globalFilter,
      filter: state?.cmPlanning?.cashPoints?.filter,
    }))
    const { globalFilter, filter } = storeFilter
    const response = yield call(
      PlanningApi.getCashPoints,
      paging,
      { globalFilter, filter },
      sort.sortColumn,
      sort.sortOrder,
    )

    const { selectedCashPoint } = yield select(getPlnCashPoints)

    if (response && selectedCashPoint) {
      const updatedSelectedOrder = response.data.find((cashPoint: PlnCashPoint) => {
        return cashPoint.id === selectedCashPoint.id
      })

      if (updatedSelectedOrder) {
        yield put(setPlnSelectedCashPoint(updatedSelectedOrder))
      }
    }

    yield put(getPlnCashPointsResponse(response))
    return response
  } catch (error) {
    yield put(getPlnCashPointsResponseFail(error))
  }
}

function* setCashPointsFilter({ payload }: AnyAction): any {
  try {
    yield put(setGlobalFilter(payload.globalFilter))
    yield put(setPlnCashPointsFilter(payload.plnFilter))
  } catch (e) {}
}

function* handleFilterSubmit(action: AnyAction): SagaIterator {
  yield setCashPointsFilter(action)
  yield put(resetPlnCashPointsPage())
  yield put(setPlnSelectedCashPoint(null))
  // yield put(clearPlnAllCashPoints())
  yield put(getPlnCashPointsRequest())
}

function* handleCashPointsPagingChange({ payload }: AnyAction): SagaIterator {
  yield put(setPlnCashPointsPagingResponse(payload))
  yield put(getPlnCashPointsRequest())
}

function* getReports(): SagaIterator {
  try {
    const { checkedCashPoints, selectedCashPoint, filter } = yield select(
      state => state?.cmPlanning?.cashPoints,
    )
    const globalFilter = yield select(state => state?.cmCommon?.globalFilter)
    const pointsFilter = {
      checkedIds: Object.keys(checkedCashPoints) || [],
      selectedId: selectedCashPoint?.id || '',
      globalFilter: globalFilter,
      filter: filter,
    }
    const response = yield call(PlanningApi.getReports, pointsFilter)
    yield put(getPlnReportsResponse(response))
  } catch (e) {
    yield put(getPlnReportsResponseFail(e?.message))
  }
}

function* getReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(CMCommonApi.getReport, payload)
      yield put(getPlnReportResponse(response))
    } else {
      yield put(getPlnReportResponse(null))
    }
  } catch (e) {
    yield put(getPlnReportResponseFail(e?.message))
  }
}

function* getConfig({ payload }: AnyAction): SagaIterator {
  try {
    let guid = payload
    if (!payload) {
      guid = yield select(state => state?.cmPlanning?.cashPoints?.selectedCashPoint?.id)
    }
    const config = yield call(PlanningApi.getConfig, guid)
    yield put(getPlnConfigResponse(config))
  } catch (e) {
    yield put(getPlnConfigResponseFail(e))
  }
}

function* handleCalculateExt({ payload }: AnyAction): SagaIterator {
  try {
    yield put(setPlnLoadingModalOpen(true))
    const response = yield call(PlanningApi.calculateExt, payload)
    yield put(plnCalculateExtResponse(response))
    yield put(setPlnModifyApplyDisabled(false))
  } catch (e) {
    yield put(setComCMError(e))
  } finally {
    yield put(setPlnLoadingModalOpen(false))
  }
}

function* refreshCashPoint({ payload }: AnyAction): SagaIterator {
  try {
    const cashPoint = yield call(PlanningApi.getCashPoint, payload)
    yield put(refreshPlnCashPointResponse(cashPoint))
    const { selectedCashPoint, checkedCashPoints } = yield select(
      state => state?.cmPlanning?.cashPoints,
    )
    if (selectedCashPoint?.id === cashPoint?.id) {
      yield put(setPlnSelectedCashPoint(cashPoint))
    }
    if (checkedCashPoints[cashPoint?.id]) {
      const newCheckedCashPoints = { ...checkedCashPoints }
      newCheckedCashPoints[cashPoint.id] = cashPoint
      yield put(
        checkPlnAllCashPoints(
          Object.keys(newCheckedCashPoints).map(key => newCheckedCashPoints[key]),
        ),
      )
    }
  } catch (e) {
    yield put(refreshPlnCashPointResponseFail(e))
  }
}

function* handleModify({ payload }: AnyAction): SagaIterator {
  try {
    yield call(PlanningApi.modify, payload)

    yield put(refreshPlnCashPointRequest(payload?.id))
  } catch (e) {
    yield put(setComCMError(e))
  }
}

function* getModifyExpenses({ payload }: AnyAction): SagaIterator {
  try {
    const { expenses } = yield call(PlanningApi.getModifyExpenses, payload)
    yield put(getPlnModifyExpensesResponse(expenses))
  } catch (e) {
    yield put(getPlnModifyExpensesResponseFail(e))
  }
}

function* getModifySettings(): SagaIterator {
  try {
    const settings = yield call(PlanningApi.getSettings)
    yield put(getPlnSettingsResponse(settings))
  } catch (e) {
    yield put(getPlnSettingsResponseFail(e))
  }
}

function* handleCalculate({ payload }: AnyAction): SagaIterator {
  try {
    const { ids, date }: { ids: string[]; date: string } = payload
    const {
      paging: { pageSize },
    } = yield select(getPlnCashPoints)

    yield put(setPlnLoadingModalOpen(true))
    yield call(PlanningApi.calculate, { ids, date })

    if (ids.length > pageSize / 2) {
      yield put(getPlnCashPointsRequest())
      return
    }

    yield put(plnSetDataCountToUpdate(ids.length - 1))

    for (const id of payload?.ids) {
      yield put(refreshPlnCashPointRequest(id))
    }
  } catch (e) {
    for (const id of payload?.ids) {
      yield put(refreshPlnCashPointRequest(id))
    }
    yield put(setComCMError(e))
  } finally {
    yield put(setPlnLoadingModalOpen(false))
  }
}

function* handleAccept({ payload }: AnyAction): SagaIterator {
  try {
    const { ids }: { ids: string[] } = payload
    const {
      paging: { pageSize },
    } = yield select(getPlnCashPoints)

    yield call(PlanningApi.accept, { ids })

    if (ids.length > pageSize / 2) {
      yield put(getPlnCashPointsRequest())
      return
    }

    yield put(plnSetDataCountToUpdate(ids.length - 1))

    for (const id of ids) {
      yield put(refreshPlnCashPointRequest(id))
    }
  } catch (e) {
    yield put(setComCMError(e))
  }
}

function* handleSendToExecute({ payload }: AnyAction): SagaIterator {
  try {
    const { ids }: { ids: string[] } = payload
    const {
      paging: { pageSize },
    } = yield select(getPlnCashPoints)

    yield call(PlanningApi.sendToExecute, { ids })

    if (ids.length > pageSize / 2) {
      yield put(getPlnCashPointsRequest())
      return
    }

    yield put(plnSetDataCountToUpdate(ids.length - 1))

    for (const id of ids) {
      yield put(refreshPlnCashPointRequest(id))
    }
  } catch (e) {
    yield put(setComCMError(e))
  }
}

function* handleCashPointPriority({ payload }: AnyAction): SagaIterator {
  yield call(SharedApi.setPriority, payload)
}
function* getCassetteTypes(): SagaIterator {
  try {
    const types = yield call(PlanningApi.getCassetteTypes)
    yield put(getComCassetteTypesResponse(types))
  } catch (e) {
    yield put(getComCassetteTypesFail(e))
  }
}

function* modifyConfig({ payload: { guid, config } }: AnyAction): SagaIterator {
  try {
    yield call(PlanningApi.modifyConfig, guid, config)

    yield put(modifyPlnConfigResponse())
    yield put(refreshPlnCashPointRequest(guid))
  } catch (e) {
    yield put(modifyPlnConfigFail())
  }
}

function* getDeclineNote({ payload }: AnyAction): SagaIterator {
  try {
    const response = yield call(SharedApi.getDeclineNote, payload)
    yield put(getPlnDeclineNoteResponse(response))
  } catch (error) {
    const parsedError: ComVerboseError = JSON.parse(error.message)

    yield put(getPlnDeclineNoteResponseFail(parsedError.message || error.message))
  }
}

function* handleKeyPress({ payload }: AnyAction): SagaIterator {
  try {
    const {
      cashPoints: { selectedCashPoint: selected, data },
      isFiltersModalOpen,
      isDocumentModalOpen,
      isModifyModalOpen,
      isSettingsModalOpen,
      isCalculateModalOpen,
      isLoadingModalOpen,
      isDeclineModalOpen,
    } = yield select((state: AppState) => state.cmPlanning)
    const {
      isEditCassettesModalOpen,
      declineNoteState: { isModalOpen: isDeclineNoteModalOpen },
    } = yield select((state: AppState) => state.cmShared)

    hotKeysBinder<PlnCashPoint>({
      key: payload,
      data,
      selected,
      isOpenModalsList: [
        isFiltersModalOpen,
        isDocumentModalOpen,
        isModifyModalOpen,
        isSettingsModalOpen,
        isCalculateModalOpen,
        isLoadingModalOpen,
        isDeclineModalOpen,
        isEditCassettesModalOpen,
        isDeclineNoteModalOpen,
      ],
      setSelectedAction: setPlnSelectedCashPoint,
      onToggleAction: togglePlnCashPoint,
      openFilterAction: setPlnFiltersModalOpen,
      openPrintAction: setPlnDocumentModalOpen,
      onRefreshAction: refreshPlanning,
    })
  } catch (error) {
    yield put(setComCMError(error))
  }
}

export default function*(): Generator {
  yield takeLatest([CMActions.RefreshPlanning, CMActions.PlnGetCashPointsRequest], getCashPoints)
  yield takeLatest(CMActions.PlnFilterSubmitRequest, handleFilterSubmit)
  yield takeLatest(CMActions.SetPlnCashPointsPagingRequest, handleCashPointsPagingChange)
  yield takeLatest(CMActions.GetPlnReportsRequest, getReports)
  yield takeLatest(CMActions.GetPlnReportRequest, getReport)
  yield takeLatest(CMActions.PlnGetConfigRequest, getConfig)
  yield takeLatest(CMActions.PlnCalculateExtRequest, handleCalculateExt)
  yield takeEvery(CMActions.PlnRefreshCashPointRequest, refreshCashPoint)
  yield takeLatest(CMActions.PlnModifyRequest, handleModify)
  yield takeLatest(CMActions.SortPlnTable, getCashPoints)
  yield takeLatest(CMActions.PlnCalculateRequest, handleCalculate)
  yield takeLatest(CMActions.PlnAcceptRequest, handleAccept)
  yield takeLatest(CMActions.PlnSendToExecuteRequest, handleSendToExecute)
  yield takeLatest(CMActions.PlnSetCashPointPriority, handleCashPointPriority)
  yield takeLatest(CMActions.ComGetCassetteTypesRequest, getCassetteTypes)
  yield takeLatest(CMActions.PlnModifyConfigRequest, modifyConfig)
  yield takeLatest(CMActions.PlnGetModifyExpensesRequest, getModifyExpenses)
  yield takeLatest(CMActions.PlnGetSettingsRequest, getModifySettings)
  yield takeLatest(CMActions.PlnHandleKeyPress, handleKeyPress)
  yield takeLatest(CMActions.PlnGetDeclineNoteRequest, getDeclineNote)
}
