import { Button, Divider, Grid, Paper, Typography } from '@material-ui/core'
import React, { ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AdmApprovedItem, AdmApprovedObject } from '@/api/cm/swaggerGeneratedApi'
import CloseButton from '@/components/blocks/CloseButton'
import { DraggableDialog, RefreshButton } from '@/components/blocks/cm'
import { TitleWithDivider } from '@/components/blocks/cm/FiltersModal/styles'
import {
  getApprovalItem,
  getApproveItemsRequest,
  setApproveItemsRequest,
  updateLocalApproveItems,
} from '@/store/cm/common'
import { useTranslate } from '@/utils/internalization'

import { CMTable, usePrepareTableConfig } from '../../CMTable'
import { conditionallyRenderCell } from './columnRenderConfig'
import { headerCellChild } from './headerCellChild'
import DifferencesOfFields from './modals/DifferencesOfFields'
import { ActionsWrapper, ButtonsContainer, useClasses } from './styles'
import { headerConfig, TableColumn } from './tableConfig'
import { AdmApprovalItemWithCheckbox, ApproveRejectCount, Props } from './types'

const ApproveItemModal = ({ isOpen, onClose }: Props): ReactElement => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const classes = useClasses()

  const tableConfig = usePrepareTableConfig({
    rowId: 'entryId',
    headerConfig,
  })

  const [isModalOfChangesOpen, setIsModalOfChangesOpen] = useState(false)
  const [changes, setChanges] = useState<AdmApprovedObject | null>(null)
  const [actionItemLength, setActionItemLength] = useState<ApproveRejectCount>({
    [TableColumn.Approve]: 0,
    [TableColumn.Reject]: 0,
  })

  const { data, isLoading, isLoaded, moduleName } = useSelector(getApprovalItem)

  const tableClasses = useMemo(() => {
    return {
      cell: classes.tableCell,
      tableHeadCell: classes.tableCell,
    }
  }, [classes])

  useEffect(() => {
    dispatch(getApproveItemsRequest())
  }, [])

  useEffect(() => {
    let approvedCounter = 0
    let rejectedCounter = 0

    for (const obj of data) {
      obj.reject && rejectedCounter++
      obj.approve && approvedCounter++
    }
    setActionItemLength({
      [TableColumn.Approve]: approvedCounter,
      [TableColumn.Reject]: rejectedCounter,
    })
  }, [data])

  const handleRefresh = useCallback(() => {
    dispatch(getApproveItemsRequest())
  }, [])

  const handleHeaderCheckbox = useCallback(
    (key: string) => (event: ChangeEvent<HTMLInputElement>): void => {
      const approve = key === TableColumn.Approve ? event.target.checked : false
      const reject = key === TableColumn.Reject ? event.target.checked : false

      dispatch(
        updateLocalApproveItems(
          data.map(localRow => ({
            ...localRow,
            approve: approve,
            reject: reject,
          })),
        ),
      )
    },
    [data],
  )

  const handleOpenModalOfChanges = useCallback(() => {
    setIsModalOfChangesOpen(true)
  }, [])
  const handleCloseModalOfChanges = useCallback(() => {
    setIsModalOfChangesOpen(false)
    setChanges(null)
  }, [])

  const handleOpenDetails = useCallback(
    (row: AdmApprovalItemWithCheckbox) => (): void => {
      if (row && row?.object) {
        setChanges(row.object)
        handleOpenModalOfChanges()
      }
    },
    [],
  )

  const conditionallyRenderCellMemo = useMemo(
    () =>
      conditionallyRenderCell({
        handleOpenDetails,
      }),
    [handleOpenDetails],
  )

  const headerCellChildMemo = useMemo(
    () =>
      headerCellChild({
        classes,
        handleHeaderCheckbox,
        actionItemLength,
        dataLength: data.length,
      }),
    [classes, actionItemLength, data.length, handleHeaderCheckbox],
  )

  const handleApply = useCallback(() => {
    const approvedItems = data
      .filter(changedData => changedData.approve || changedData.reject)
      .map(item => ({
        entryId: item.entryId,
        doApprove: !!item.approve,
      })) as AdmApprovedItem[]

    const hasApproveItem = approvedItems.some(item => item.doApprove)

    dispatch(setApproveItemsRequest({ approvedItems, hasApproveItem }))
  }, [data])

  return (
    <>
      <DraggableDialog
        id="approveItemModal"
        maxWidth="sm"
        fullWidth
        open={isOpen}
        keepMounted
        onClose={onClose}
        classes={{ paper: classes.dialog }}
      >
        <CloseButton onClick={onClose} absolute />
        <Paper className={classes.paper}>
          <Grid item md={12} className={classes.titleWrapper}>
            <Typography variant="h3" className={classes.modalHeader}>
              {translate('translate#cm.ApproveRejectChanges')}
            </Typography>
            <div className={classes.header}>
              <TitleWithDivider className={classes.titleWrap}>
                <Typography className={classes.subTitle}>
                  {moduleName} {data.length ? ` (${data.length})` : null}
                </Typography>
                <Divider />
              </TitleWithDivider>
              <ActionsWrapper>
                <RefreshButton onClick={handleRefresh} />
              </ActionsWrapper>
            </div>
          </Grid>
          <CMTable
            {...tableConfig}
            data={data}
            isLoaded={isLoaded}
            isLoading={isLoading}
            tableClasses={tableClasses}
            conditionallyRenderCell={conditionallyRenderCellMemo}
            headerCellChild={headerCellChildMemo}
          />
          <ButtonsContainer>
            <div className={classes.btnContainer}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleApply}
                disabled={
                  isLoading || !data.length || !data.some(item => item.approve || item.reject)
                }
              >
                {translate('translate#cm.ButtonApply')}
              </Button>
              <Button
                className={classes.button}
                color="primary"
                variant="outlined"
                onClick={onClose}
              >
                {translate('translate#cm.ButtonClose')}
              </Button>
            </div>
          </ButtonsContainer>
        </Paper>
      </DraggableDialog>

      {isModalOfChangesOpen && (
        <DifferencesOfFields
          isOpen={isModalOfChangesOpen}
          onClose={handleCloseModalOfChanges}
          changes={changes}
        />
      )}
    </>
  )
}

export default ApproveItemModal
