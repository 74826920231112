export enum ReportsField {
  UserDefinedName = 'userDefinedName',
  Status = 'status',
  Published = 'published',
  ValidTo = 'validTo',
  Type = 'type',
  Id = 'id',
}

export interface SubscribeItems {
  id: string
  reportId: string
  reportUid: string
  userDefinedName: string
  status: string
  published: string
  validTo: string
  type: string
}

export interface SubscribeReportPanel {
  subscriptions: Array<SubscribeItems>
}

export interface ParametersItems {
  attributeId: number
  flag: number
  frendlyName: string
  id: null | number | string
  interval: null | number | string
  linkedAttributeId: null | number | string
  mandatory: boolean
  name: string
  order: number
  typeClassId: number
  typeClassName: string
  value: any
  values: Array<ValuesReport>
  __name: string
  __existslookup: boolean
  __hidden: boolean
  __defaultvalue: null | number | string
  __id: string
  __type: number
  __required: boolean
  __controltype: number
  allowArrayOfFilterValues: boolean
  allowPredefinedDatePeriod: boolean
  startOfRangeParameter: boolean
  endOfRangeParameter: boolean
  parameterDataValues: Array<ValuesReport>
}

interface ValuesReport {
  __value: string
  __label: string
}

export interface IntervalsItems {
  id: string
  name: string
  from: string
  to: string
}

export interface Parameters {
  parameters: Array<ParametersItems>
  intervals: Array<IntervalsItems>
  // @todo check
  reportId: string
  reportUid: string
  period?: any
  saveReport?: boolean
  validFrom?: string | null
  validTo?: string | null
  fileFormat?: string
  reportName?: string
  subscribe?: boolean
  subscriptionName?: string
  dataReportExist?: boolean
}

export interface ReportsConfig {
  subscriptionsConfig: Parameters
}

export interface ReportSaveRequest extends Omit<Parameters, 'intervals'> {
  reportId: string
  reportUid: string
  period?: any
  saveReport?: boolean
  validFrom: string | undefined | null
  validTo: string | undefined | null
  fileFormat?: string
  reportName?: string
  subscribe?: boolean
  reportSubscriptionDateInterval?: number
  subscriptionId?: string
}

export enum Language {
  en = 1,
  ru,
  lt,
}

export interface DownloadReport {
  valueFormat: string
  parametersReport?: ReportSaveRequest | {}
  subscriptionID?: string
}
