import { IconButton } from '@material-ui/core'
import { KeyboardArrowRight, KeyboardArrowUp } from '@material-ui/icons'
import clsx from 'clsx'
import React, { FC, useMemo } from 'react'

import { HeaderCell, useCommonClasses } from '../../../commonStyles'
import { useClasses } from './styles'
import { Props } from './types'

export const CollapseHeadCell: FC<Props> = React.memo((props: Props) => {
  const { isFixed, isAllRowsOpened, borderCellClasses, tableClasses, handleCollapseAll } = props

  const classes = useClasses()
  const commonClasses = useCommonClasses()

  const cellClasses = useMemo(
    () =>
      clsx(
        classes.collapseIcon,
        borderCellClasses,
        { [commonClasses.fixed]: isFixed, [commonClasses.fixedHeader]: isFixed },
        tableClasses?.fixed,
        tableClasses?.tableHeadCell,
      ),
    [classes, tableClasses, commonClasses, borderCellClasses],
  )

  return (
    <HeaderCell className={cellClasses}>
      <IconButton aria-label="expand-row" size="small" onClick={handleCollapseAll}>
        {isAllRowsOpened ? (
          <KeyboardArrowUp className={commonClasses.icon} />
        ) : (
          <KeyboardArrowRight className={commonClasses.icon} />
        )}
      </IconButton>
    </HeaderCell>
  )
})
