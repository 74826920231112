import makeStyles from '@material-ui/core/styles/makeStyles'

import colors from '@/theme/colors'

export const useClasses = makeStyles(({ palette, spacing }) => ({
  test: {
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },

  input: {
    margin: spacing(2, 0),
    width: '100%',
  },
  icon: {
    '& > *': {
      padding: 0,
    },
  },
  asterisk: {
    '& span': {
      color: 'red',
    },
  },
  label: {
    color: colors.textLight,

    '& > *': {
      color: 'red',
    },
  },

  inputWithError: {
    marginBottom: 0,
  },

  errorLabel: {
    color: `${palette.error.main}!important`,
  },

  errorHelperText: {
    height: spacing(2),
    fontSize: spacing(1.5),
    color: palette.error.main,
    marginTop: `-${spacing(0.5)}px`,
  },

  range: {
    width: '100%',
    '& > span': {
      margin: spacing(1, 0),
      display: 'block',
    },
    '& > div': {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  mr: {
    marginRight: '20px',
    flex: '1 1 calc(50% - 20px)',
    '&:nth-child(even)': {
      marginRight: '0',
    },
  },
}))
