import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import React, {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FormattedMessage } from 'react-intl'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { Command } from '@/api/rsocket/command'
import CloseButton from '@/components/blocks/CloseButton'
import Loader from '@/components/blocks/Loader'
import { SocketContext } from '@/context'
import { getSocketData, setSocketItemEmpty } from '@/store/dashboard'
import { useTranslate } from '@/utils/internalization'
import { PopUpService } from '@/utils/services/popUpService'

import { LOGGING_MODAL_TYPE } from '../types'
import { ContentWrapper, useClasses } from './styles'
import { Props, SocketResponse } from './types'

const LoggingModal = ({
  isCoreEnabled,

  loggingModalType,
  currentDevice,

  onClose,
  setIsCoreEnabled,
  setIsLoggingEnabled,
}: Props): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const dispatch = useDispatch()

  const isOnModal = loggingModalType === LOGGING_MODAL_TYPE.ON
  const isOpen =
    loggingModalType === LOGGING_MODAL_TYPE.ON || loggingModalType === LOGGING_MODAL_TYPE.OFF

  const { _service } = useContext(SocketContext)
  const data: SocketResponse = useSelector(getSocketData(Command.CONTROL_LOG_ON_OFF), shallowEqual)
  const coreData: SocketResponse = useSelector(
    getSocketData(Command.CONTROL_CORE_START),
    shallowEqual,
  )

  const [isLoading, setIsLoading] = useState(false)
  const [noResponse, setNoResponse] = useState(false)
  const [restartCore, setRestartCore] = useState(false)

  const timer: MutableRefObject<NodeJS.Timeout | undefined> = useRef()

  const handleClearTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current)
      timer.current = undefined
    }
    setNoResponse(false)
  }, [])

  useEffect(() => {
    return handleClearTimer()
  }, [handleClearTimer])

  const handleClose = useCallback(() => {
    handleClearTimer()
    onClose()
  }, [handleClearTimer, onClose])

  useEffect(() => {
    if (isOpen) {
      if (data) {
        if (data.status === 'OK') {
          const title = isOnModal ? 'startLoggingSuccess' : 'stopLoggingSuccess'

          PopUpService.showGlobalPopUp(`translate#title.${title}`, 'success')
        } else {
          const title = isOnModal ? 'startLoggingFail' : 'stopLoggingFail'

          PopUpService.showGlobalPopUp(
            translate(`translate#title.${title}`) + ': ' + data.status,
            'error',
          )
        }
      }

      handleClearTimer()
    } else {
      dispatch(setSocketItemEmpty(Command.CONTROL_LOG_ON_OFF))
      dispatch(setSocketItemEmpty(Command.CONTROL_CORE_START))
      setIsLoading(false)
    }
  }, [data, dispatch, isOnModal, isOpen, loggingModalType, translate, handleClearTimer])

  useEffect(() => {
    if (coreData && isOpen) {
      if (coreData.status === 'OK') {
        PopUpService.showGlobalPopUp('translate#title.startCoreSuccess', 'success')
      } else {
        PopUpService.showGlobalPopUp(
          translate('translate#title.startCoreFail') + ': ' + coreData.status,
          'error',
        )
      }

      handleClearTimer()
    }
  }, [coreData, isOpen, translate, handleClearTimer])

  useEffect(() => {
    if (isOpen) {
      if (restartCore && !isCoreEnabled) {
        if (coreData) {
          setIsCoreEnabled(true)
          setIsLoggingEnabled(isOnModal)

          handleClose()
        }
      } else {
        if (data) {
          setIsLoggingEnabled(isOnModal)

          handleClose()
        }
      }
    }
  }, [
    coreData,
    data,
    isCoreEnabled,
    isOnModal,
    isOpen,
    handleClose,
    restartCore,
    setIsCoreEnabled,
    setIsLoggingEnabled,
  ])

  const sendCommand = useCallback(
    ({ restartCore }: { restartCore: boolean }) => (): void => {
      if (_service) {
        _service.send(
          {
            loggingOn: isOnModal,
            restartCore: restartCore,
          },
          currentDevice?.deviceName,
          Command.CONTROL_LOG_ON_OFF,
        )

        setRestartCore(restartCore)

        if (restartCore && !isCoreEnabled) {
          _service.send({}, currentDevice, Command.CONTROL_CORE_START)
        }

        setIsLoading(true)
        timer.current = setTimeout(() => {
          setNoResponse(true)
        }, 60000)
      }
    },
    [_service, currentDevice, isCoreEnabled, isOnModal],
  )

  const modalTitle = isOnModal
    ? translate('translate#atmeye.devices.enableLogging')
    : translate('translate#atmeye.devices.disableLogging')

  const modalSubTitle = translate(
    isCoreEnabled
      ? 'translate#title.confirmLoggingAction'
      : 'translate#atmeye.title.confirmLoggingActionCoreOff',
  )

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      keepMounted
      onClose={handleClose}
      classes={{ paper: classes.modalWrapper }}
      disableBackdropClick
    >
      <ContentWrapper>
        <CloseButton absolute className={classes.closeButton} onClick={handleClose} />

        <div className={classes.modalTitle}>{modalTitle}</div>

        <div className={classes.modalSubTitle}>{modalSubTitle}</div>

        {isLoading ? (
          noResponse ? (
            <Grid container>
              <FormattedMessage
                id="atmeye.message.exceededTimeout"
                defaultMessage="Exceeded timeout"
              />
            </Grid>
          ) : (
            <Grid container justify="center">
              <Loader className={classes.loader} />
            </Grid>
          )
        ) : (
          <Grid container justify="flex-end">
            <Button
              onClick={handleClose}
              className={classes.cancelButton}
              color="primary"
              variant="contained"
            >
              <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
            </Button>

            <Grid item>
              <Button
                onClick={sendCommand({ restartCore: false })}
                className={classes.buttonOutlined}
                color="primary"
                variant="outlined"
              >
                <FormattedMessage id="action.restartLater" defaultMessage="Restart later" />
              </Button>
            </Grid>

            {isCoreEnabled && (
              <Button
                onClick={sendCommand({ restartCore: true })}
                className={classes.button}
                color="primary"
                variant="contained"
              >
                <FormattedMessage id="action.restartNow" defaultMessage="Restart now" />
              </Button>
            )}
          </Grid>
        )}
      </ContentWrapper>
    </Dialog>
  )
}

export default LoggingModal
