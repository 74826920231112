import { AnyAction } from 'redux'

import { SET_LOCALE } from '@/constants'

export { updateIntl } from 'react-intl-redux'

export const setLocale = (payload: { locale: string; isLoginModal?: boolean }): AnyAction => ({
  type: SET_LOCALE,
  payload,
})
