import { AnyAction } from 'redux'

import {
  AdmDenomination,
  AdmDenominationBase,
  AdmDenominationCurrency,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants/actions'
import { Sort } from '@/types/cm'

// get
export const getAdmDenominationsRequest = (): AnyAction => ({
  type: VaultV1Actions.AdmGetDenominationsRequest,
})
export const getAdmDenominationsResponse = (payload: AdmDenomination[]): AnyAction => ({
  type: VaultV1Actions.AdmGetDenominationsResponse,
  payload,
})
export const getAdmDenominationsFail = (): AnyAction => ({
  type: VaultV1Actions.AdmGetDenominationsFail,
})

// add
export const addAdmDenominationRequest = (payload: AdmDenominationBase): AnyAction => ({
  type: VaultV1Actions.AdmAddDenominationRequest,
  payload,
})
export const addAdmDenominationFail = (): AnyAction => ({
  type: VaultV1Actions.AdmAddDenominationFail,
})

// update
export const updateAdmDenominationRequest = (payload: AdmDenomination): AnyAction => ({
  type: VaultV1Actions.AdmUpdateDenominationRequest,
  payload,
})
export const updateAdmDenominationFail = (): AnyAction => ({
  type: VaultV1Actions.AdmUpdateDenominationFail,
})

// delete
export const deleteAdmDenominationRequest = (): AnyAction => ({
  type: VaultV1Actions.AdmDeleteDenominationRequest,
})
export const deleteAdmDenominationFail = (): AnyAction => ({
  type: VaultV1Actions.AdmDeleteDenominationFail,
})

export const setAdmDenominationSelected = (payload: AdmDenomination | null): AnyAction => ({
  type: VaultV1Actions.AdmSetSelectedDenomination,
  payload,
})

export const sortAdmDenominations = (payload: Sort): AnyAction => ({
  type: VaultV1Actions.AdmSortDenominations,
  payload,
})

// get Denomination Currencies
export const getAdmDenominationCurrenciesRequest = (): AnyAction => ({
  type: VaultV1Actions.AdmGetDenominationCurrenciesRequest,
})
export const getAdmDenominationCurrenciesResponse = (
  payload: AdmDenominationCurrency[],
): AnyAction => ({
  type: VaultV1Actions.AdmGetDenominationCurrenciesResponse,
  payload,
})
