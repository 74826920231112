import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'normalize.css'
import React from 'react'
import ReactDOM from 'react-dom'
import App from '@/App'
;(async () => {
  ReactDOM.render(<App />, document.getElementById('root'))
})()
