import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import { debounce, isEmpty } from 'lodash-es'
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'

import { GetGroupDetailsResponse } from '@/api/sd/ticketsApi/types'
import Loader from '@/components/blocks/Loader'
import TableHeader from '@/components/controls/TableComponents/TableHeader'

import ItemRow from './components/ItemRow'
import { useClasses } from './styles'
import { Props } from './types'

const InfinityScrollTable = ({
  items,
  nesting,
  order,
  orderBy,
  isLoading,
  isFetchingSorting,
  headers,
  filter,
  tableHeaderOptions,
  totalItemsCount,
  currentItemsCount,
  loadMore,
  ticketClick,
  onItemEditClick,
  posTicketClick,
  disableRowClick,
  editTableCellRequest,
  incomingHeaders,
  selectableTable,
  checkboxColumnsNames,
  handleCheckedRows,
  handleCheckedObjectRows,
  selectedSecurityObjectRows,
  selectedSecurityNetworksRows,
  setCheckedGroup,
  isSelectAll,
  setIsIndeterminate,
}: Props): ReactElement => {
  const [filteredItems, setFilteredItems] = useState<GetGroupDetailsResponse>([])
  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true)
  const [stop, setStop] = useState<boolean>(false)

  const tableRef = useRef<HTMLDivElement>(null)
  const loaderRef = useRef<HTMLDivElement>(null)

  const classes = useClasses({ nesting: nesting + 2 })

  const loadData = useCallback(
    debounce(() => loadMore(), 100),
    [loadMore],
  )

  const countItemsWithChilds = (items: any[]): number => {
    let result = 0
    if (items) {
      const countChildren = (obj: any): number => {
        return obj.childItems ? obj.childItems.length : 0
      }
      result = items?.length + items.reduce((acc, c) => acc + countChildren(c), 0)
    }

    return result
  }

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      if (
        (currentItemsCount && currentItemsCount < totalItemsCount) ||
        (items && countItemsWithChilds(items) < totalItemsCount)
      ) {
        const { scrollHeight, scrollTop, clientHeight } = event.currentTarget

        if (
          (Math.floor(scrollHeight - scrollTop) !== clientHeight && !isLoading) ||
          (Math.ceil(scrollHeight - scrollTop) !== clientHeight && !isLoading)
        ) {
          setStop(false)
        }

        if (
          (Math.floor(scrollHeight - scrollTop) === clientHeight && !isLoading && !stop) ||
          (Math.ceil(scrollHeight - scrollTop) === clientHeight && !isLoading && !stop)
        ) {
          setStop(true)
          loadData()
        }

        setIsFirstLoading(false)
      }
    },
    [isLoading, stop, items, currentItemsCount, totalItemsCount],
  )

  useEffect(() => {
    if (isEmpty(filter) && !isEmpty(items)) {
      setFilteredItems(items)
    }
  }, [items, filter])

  useEffect(() => {
    if (!isEmpty(filter) && items && !isEmpty(items)) {
      let result: any[] = items

      for (const key in filter) {
        result = result.filter(({ item }) => {
          const str: string = item[key] || ''

          return str.toLowerCase().includes(filter[key].toString().toLowerCase())
        })
      }

      setStop(true)
      setFilteredItems(result)
    }
  }, [items, filter])

  useEffect(() => {
    if (
      (currentItemsCount && currentItemsCount < totalItemsCount) ||
      (items && countItemsWithChilds(items) < totalItemsCount)
    ) {
      if (tableRef.current && !isLoading && !isFirstLoading) {
        const { scrollHeight, scrollTop, clientHeight } = tableRef.current

        if (
          Math.floor(scrollHeight - scrollTop) === clientHeight ||
          Math.ceil(scrollHeight - scrollTop) === clientHeight
        ) {
          loadData()
        }
      }
    }
  }, [isLoading])

  const TableLoaderJSX = (isLoading || isFetchingSorting) && (
    <div className={classes.loaderWrapper} ref={loaderRef}>
      <Loader />
    </div>
  )
  return (
    <TableContainer className={classes.tableContainer} onScroll={handleScroll} innerRef={tableRef}>
      <Table className={classes.table} stickyHeader>
        <TableHeader
          order={order}
          orderBy={orderBy}
          classes={{ cell: classes.headerCell }}
          {...tableHeaderOptions}
        />
        {filteredItems && !isEmpty(filteredItems) && !isFetchingSorting && (
          <TableBody>
            {filteredItems.map((row, index) => (
              <ItemRow
                key={`${row.item.documentid}${index}`}
                row={row}
                rows={filteredItems}
                headers={headers}
                rowIndex={index}
                parentIndex={index}
                nesting={nesting}
                order={order}
                orderBy={orderBy}
                posTicketClick={posTicketClick}
                onItemEditClick={onItemEditClick}
                ticketClick={ticketClick}
                disableRowClick={disableRowClick}
                editTableCellRequest={editTableCellRequest}
                incomingHeaders={incomingHeaders}
                selectableTable={selectableTable}
                checkboxColumnsNames={checkboxColumnsNames}
                handleCheckedRows={handleCheckedRows}
                handleCheckedObjectRows={handleCheckedObjectRows}
                selectedSecurityObjectRows={selectedSecurityObjectRows}
                selectedSecurityNetworksRows={selectedSecurityNetworksRows}
                setCheckedGroup={setCheckedGroup}
                isSelectAll={isSelectAll}
                setIsIndeterminate={setIsIndeterminate}
              />
            ))}
          </TableBody>
        )}
        {TableLoaderJSX}
      </Table>
    </TableContainer>
  )
}

export default InfinityScrollTable
