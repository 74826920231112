import queryString from 'query-string'

import {
  ComCMReport,
  DocStagesResponse,
  IcsAssignCollectorRequest,
  IcsChangeReplenishmentRequest,
  IcsOrder,
  IcsOrdersRequest,
  IcsOrdersResponse,
  IcsOrderStagesInfo,
  IcsReportsRequest,
  IcsSetStageRequest,
} from '@/api/cm/swaggerGeneratedApi'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class ReplenishmentsApi {
  private static requestService = getRequestServiceCM()

  public static async getStages(orderId: string): Promise<IcsOrderStagesInfo> {
    try {
      return await ReplenishmentsApi.requestService.get('/ics/getStages', { orderId })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setStage(payload: IcsSetStageRequest): Promise<IcsOrderStagesInfo> {
    try {
      return await ReplenishmentsApi.requestService.post('/ics/setStage', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async deleteLastDocument(orderId: string): Promise<IcsOrderStagesInfo> {
    try {
      return await ReplenishmentsApi.requestService.post(
        `/ics/deleteLastDocument?orderId=${orderId}`,
        {},
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getReports(payload: IcsReportsRequest): Promise<ComCMReport[]> {
    try {
      return await ReplenishmentsApi.requestService.post('/ics/reports', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async assignCollector(payload: IcsAssignCollectorRequest): Promise<void> {
    try {
      return await ReplenishmentsApi.requestService.post('/ics/assignCollector', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async changeReplenishmentType(
    payload: IcsChangeReplenishmentRequest,
  ): Promise<void> {
    try {
      return await ReplenishmentsApi.requestService.post('/ics/changeReplenishmentType', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getOrders(
    paging: { page: number; pageSize: number },
    requestBody: IcsOrdersRequest,
    sortColumn?: string | undefined,
    sortOrder?: 'asc' | 'desc' | undefined,
  ): Promise<IcsOrdersResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/ics/orders',
          query: {
            pageSize: `${paging.pageSize}`,
            page: `${paging.page}`,
            sortColumn,
            sortOrder,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await ReplenishmentsApi.requestService.post(url, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getOrder(id: string): Promise<IcsOrder> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/ics/order',
          query: { guid: `${id}` },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await ReplenishmentsApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getIcsStagesDoc(id: string): Promise<DocStagesResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/doc/stages',
          query: { id },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await ReplenishmentsApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }
}
