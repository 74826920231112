import TableCell from '@material-ui/core/TableCell'
import React, { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { SecurityAlarmsApi } from '@/api/atmeye/securityAlarms'
import { CellJSXPropsComponent } from '@/components/NPMPakage/components/tableComponents/UITable/types'
import { Permission } from '@/components/NPMPakage/components/wrappers/types'
import { getCurrentUserName } from '@/store/auth'

import { DateCell } from '../TableCell/DateCell/DateCell'
import { InputCell } from '../TableCell/InputCell/InputCell'
import { MediaCell } from '../TableCell/MediaCell/MediaCell'
import { useClasses } from './styles'

const SecurityAlarmsTableCell: FC<CellJSXPropsComponent> = ({
  fieldName,
  index,

  row,
  getUpdateRequest,
}: CellJSXPropsComponent): React.ReactElement => {
  const classes = useClasses()

  const userName = useSelector(getCurrentUserName)

  const updateItem = ({ field, value }: { field: string; value: string }) => {
    getUpdateRequest?.mutateQueryTable &&
      getUpdateRequest.mutateQueryTable((prevTable: any[]) => {
        return prevTable.map(prevRow => {
          if (prevRow.eventId === row.eventId) return { ...prevRow, [field]: value }
          return prevRow
        })
      })
  }

  const saveCellHandler = async (
    event: any,
    fieldId: string,
    fieldValue: string,
  ): Promise<void> => {
    const { value } = event.target

    try {
      updateItem({ field: 'note', value })

      await SecurityAlarmsApi.updateAlarmNote({
        value,
        eventId: row.eventId,
      })
    } catch (e) {
      updateItem({ field: 'note', value: fieldValue })
    }
  }

  const reactionToAlarms = async (alarmId: string) => {
    if (getUpdateRequest?.reactionToAlarms) {
      const handleDate = await getUpdateRequest.reactionToAlarms(alarmId)
      updateItem({ field: 'reactionDateTime', value: handleDate })
      updateItem({ field: 'operator', value: userName || '' })
    }
  }

  const mutateQueryTableWithScreenshot = () => {
    updateItem({ field: 'mediaStoreType', value: 'DATABASE_FILE' })
  }

  const renderComponents = useCallback(() => {
    return {
      note: (
        <InputCell
          headerName={fieldName || ''}
          index={index}
          row={row}
          onBlur={saveCellHandler}
          note={row.note}
        />
      ),
      cameraNumber: (
        <MediaCell
          text={row.cameraNumber}
          permission={Permission.Alarms_View_Media}
          row={row}
          requestFileType="ALARM"
          getUpdateRequest={{
            ...getUpdateRequest,
            mutateQueryTable: getUpdateRequest?.mutateQueryTable
              ? mutateQueryTableWithScreenshot
              : undefined,
            reactionToAlarms,
          }}
        />
      ),
      atmDateTime: <DateCell isUTCTime key={fieldName || ''} date={row?.[fieldName || '']} />,
      reactionDateTime: <DateCell key={fieldName || ''} date={row?.[fieldName || '']} />,
      local_date: <DateCell key={fieldName || ''} date={row?.[fieldName || '']} />,
    }
  }, [row])

  return (
    <>
      {renderComponents()?.[fieldName as keyof typeof renderComponents] || (
        <TableCell key={fieldName} className={classes.cell}>
          {row?.[fieldName || '']}
        </TableCell>
      )}
    </>
  )
}

export { SecurityAlarmsTableCell }
