export enum FiscalRegistrationActions {
  GetCashRegisterGetInfoRequest = '@/SRM_FISCAL_REGISTRATION_CASH_REGISTER_GET_INFO_REQUEST',
  GetCashRegisterGetInfoResponse = '@/SRM_FISCAL_REGISTRATION_CASH_REGISTER_GET_INFO_RESPONSE',
  GetCashRegisterGetInfoResponseFail = '@/SRM_FISCAL_REGISTRATION_CASH_REGISTER_GET_INFO_RESPONSE_FAIL',

  GetCashRegisterGetRegistrationsRequest = '@/SRM_FISCAL_REGISTRATION_CASH_REGISTER_GET_REGISTRATIONS_REQUEST',
  GetCashRegisterGetRegistrationsResponse = '@/SRM_FISCAL_REGISTRATION_CASH_REGISTER_GET_REGISTRATIONS_RESPONSE',
  GetCashRegisterGetRegistrationsResponseFail = '@/SRM_FISCAL_REGISTRATION_CASH_REGISTER_GET_REGISTRATIONS_RESPONSE_FAIL',

  GetCashRegisterTerminalsHistoryRequest = '@/SRM_FISCAL_REGISTRATION_CASH_REGISTER_TERMINALS_HISTORY_REQUEST',
  GetCashRegisterTerminalsHistoryResponse = '@/SRM_FISCAL_REGISTRATION_CASH_REGISTER_TERMINALS_HISTORY_RESPONSE',
  GetCashRegisterTerminalsHistoryResponseFail = '@/SRM_FISCAL_REGISTRATION_CASH_REGISTER_TERMINALS_HISTORY_RESPONSE_FAIL',
  CleanCashRegisterTerminalsHistory = '@/SRM_FISCAL_REGISTRATION_CLEAN_CASH_REGISTER_TERMINALS_HISTORY',

  CashRegisterRegistrationRequest = '@/SRM_FISCAL_REGISTRATION_CASHREGISTER_REGISTRATION_REQUEST',
  CashRegisterRegistrationResponse = '@/SRM_FISCAL_REGISTRATION_CASHREGISTER_REGISTRATION_RESPONSE',
  CashRegisterRegistrationResponseFail = '@/SRM_FISCAL_REGISTRATION_CASHREGISTER_REGISTRATION_RESPONSE_FAIL',

  CashRegisterUnRegistrationRequest = '@/SRM_FISCAL_REGISTRATION_CASHREGISTER_UNREGISTRATION_REQUEST',
  CashRegisterUnRegistrationResponse = '@/SRM_FISCAL_REGISTRATION_CASHREGISTER_UNREGISTRATION_RESPONSE',
  CashRegisterUnRegistrationResponseFail = '@/SRM_FISCAL_REGISTRATION_CASHREGISTER_UNREGISTRATION_RESPONSE_FAIL',

  CashRegisterDeleteRequest = '@/SRM_FISCAL_REGISTRATION_CASHREGISTER_DELETE_REQUEST',
  CashRegisterDeleteResponse = '@/SRM_FISCAL_REGISTRATION_CASHREGISTER_DELETE_RESPONSE',
  CashRegisterDeleteResponseFail = '@/SRM_FISCAL_REGISTRATION_CASHREGISTER_DELETE_RESPONSE_FAIL',
  CashRegisterSetRegisterModalIsOpen = '@/SRM_FISCAL_REGISTRATION_SET_REGISTRATION_MODAL_ISOPEN',
  CashRegisterSetUnRegisterModalIsOpen = '@/SRM_FISCAL_REGISTRATION_SET_UNREGISTRATION_MODAL_ISOPEN',
  CashRegisterSetDeleteModalIsOpen = '@/SRM_FISCAL_REGISTRATION_SET_DELETE_MODAL_ISOPEN',
}
