import React, { ReactElement, useMemo, useState } from 'react'

import { List, ListItem, ListItemText, Collapse } from '@material-ui/core'

import { icons } from '@/constants/networksIcons'

import CheckBoxWrapper from '../CheckBoxWrapper'
import { ObjectNodeProps } from './types'
import { useClasses } from './styles'

const ObjectNode = ({
  node,
  level,
  nodeType,
  openedByDefault = false,
  checkboxTree,
}: ObjectNodeProps): ReactElement => {
  const classes = useClasses()

  const [open, setOpen] = useState(openedByDefault || false)

  const { isChildren, showCheckbox } = useMemo(
    () => ({
      isChildren: !!node.children,
      showCheckbox: node.showCheckbox,
    }),
    [node],
  )

  const itemText = useMemo(() => {
    return (
      <span className={classes.networksListItem}>
        {nodeType && icons[nodeType] ? (
          <span className={classes.icon}>{icons[nodeType]}</span>
        ) : null}
        {node.label}
      </span>
    )
  }, [node.label, nodeType])

  return (
    <>
      <ListItem
        id={`${node.value}-${node.parentId}`}
        className={classes.listItem}
        style={{ marginLeft: !isChildren ? (checkboxTree ? 24 : 22) : 0 }}
      >
        {isChildren && <CheckBoxWrapper open={open} setOpen={setOpen} node={node} />}
        <ListItemText primary={itemText} />
      </ListItem>
      {isChildren && (
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit={false}
          style={{ marginLeft: showCheckbox ? 28 : checkboxTree ? 18 : 24 }}
        >
          <List component="div" disablePadding dense>
            {node.children?.map(childNode => (
              <ObjectNode
                key={childNode.value}
                level={level + 1}
                node={childNode}
                nodeType={childNode.nodeType}
                openedByDefault={openedByDefault}
                checkboxTree={checkboxTree}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}

export default React.memo(ObjectNode)
