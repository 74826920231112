import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromNotifications from './reducer'

export const getNotificationsSettings = (state: AppState): fromNotifications.State =>
  state.notificationsSetting

export const getNotifications = createSelector(
  getNotificationsSettings,
  state => state.notifications,
)

export const getAlarmCount = createSelector(
  getNotificationsSettings,
  state => state.alarmNotifications.length,
)

export const getEventsCount = createSelector(
  getNotificationsSettings,
  state => state.eventNotifications.length,
)

export const getAlarms = createSelector(getNotificationsSettings, state => state.alarmNotifications)

export const getEvents = createSelector(getNotificationsSettings, state => state.eventNotifications)

export const getBlockAlarms = createSelector(getNotificationsSettings, state => state.blockAlarms)

export const getBlockEvents = createSelector(getNotificationsSettings, state => state.blockEvents)

export const notificationSettings = createSelector(
  getNotificationsSettings,
  state => state.notificationSettings,
)

export const notificationAlarmSettings = createSelector(
  getNotificationsSettings,
  state => state.notificationSettings.alarmSettings,
)

export const notificationEventSettings = createSelector(
  getNotificationsSettings,
  state => state.notificationSettings.eventSettings,
)

export const getAlarmDelay = createSelector(notificationAlarmSettings, state => state.delay)

export const getAlarmSound = createSelector(notificationAlarmSettings, state => state.sound)

export const getAlarmDisplayNotification = createSelector(
  notificationAlarmSettings,
  state => state.displayNotification,
)

export const getEventDelay = createSelector(notificationEventSettings, state => state.delay)

export const getEventSound = createSelector(notificationEventSettings, state => state.sound)

export const getEventDisplayNotification = createSelector(
  notificationEventSettings,
  state => state.displayNotification,
)

export const getAlarmsCount = createSelector(
  getNotificationsSettings,
  state => state.alarmsEventsCount,
)
