export enum AuthorizationActions {
  SignInRequest = '@/SIGN_IN_REQUEST',
  SignInResponse = '@/SIGN_IN_RESPONSE',
  SignInResponseFail = '@/SIGN_IN_RESPONSE_FAIL',
  ChangePasswordRequest = '@/CHANGE_PASSWORD_REQUEST',
  ChangePasswordResponse = '@/CHANGE_PASSWORD_RESPONSE',
  ChangePasswordResponseFail = '@/CHANGE_PASSWORD_RESPONSE_FAIL',
  SetChangePasswordModalOpen = '@/SET_CHANGE_PASSWORD_MODAL_OPEN',
  SignInSelectAccount = '@/SING_IN_SELECT_ACCOUNT',
  PutCurrentAccount = '@/PUT_CURRENT_ACCOUNT',
  DefineUser = '@/DEFINE_USER',
  AuthorizeUser = '@/AUTHORIZE_USER',
  SignOut = '@/SIGN_OUT',
  ResetReducers = '@/RESET_REDUCERS',
  CheckIsLogin = '@/CHECK_IS_LOGIN',
  GetAllPermissionsRequest = '@/GET_ALL_PERMISSIONS_REQUEST',
  GetAllPermissionsResponse = '@/GET_ALL_PERMISSIONS_RESPONSE',
  GetAllPermissionsResponseFail = '@/GET_ALL_PERMISSIONS_RESPONSE_FAIL',

  GetAllUserPermissionsRequest = '@/GET_ALL_USER_PERMISSIONS_REQUEST',
  GetAllUserPermissionsResponse = '@/GET_ALL_USER_PERMISSIONS_RESPONSE',
  GetAllUserPermissionsResponseFail = '@/GET_ALL_USER_PERMISSIONS_RESPONSE_FAIL',

  GetUserPhotoRequest = '@/GET_USER_PHOTO_REQUEST',
  GetUserPhotoResponse = '@/GET_USER_PHOTO_RESPONSE',
  GetUserPhotoResponseFail = '@/GET_USER_PHOTO_RESPONSE_FAIL',

  SetAvailableProducts = '@/SET_AVAILABLE_PRODUCTS',
  SetCurrentProducts = '@/SET_CURRENT_PRODUCTS',

  SetCurrentUserFromAvailable = '@/SET_CURRENT_USER_FROM_AVAILABLE_ACCOUNT',
  StartIsInitialisationAuthProcess = '@/START_IS_INITIALISATION_AUTH_PROCESS',
  StopIsInitialisationAuthProcess = '@/STOP_IS_INITIALISATION_AUTH_PROCESS',
}
