import AdminIcon from '@/assets/cashManagement/icons/admin_icon.svg'
import AnalyticsIcon from '@/assets/cashManagement/icons/analytics_icon.svg'
import BalancingIcon from '@/assets/cashManagement/icons/balancing_icon.svg'
import CashOrdersIcon from '@/assets/cashManagement/icons/cm_cash_orders_icon.svg'
import MapsIcon from '@/assets/cashManagement/icons/maps_icon.svg'
import MonitoringIcon from '@/assets/cashManagement/icons/monitoring_icon.svg'
import PlanningIcon from '@/assets/cashManagement/icons/planning_icon.svg'
import PlasticCardIcon from '@/assets/cashManagement/icons/plastic_card.svg'
import ReplenishmentsIcon from '@/assets/cashManagement/icons/replenishments_icon.svg'
import RouteIcon from '@/assets/cashManagement/icons/route_icon.svg'
import { AppNavigationRoutes, CASH_MANAGEMENT_BLOCK_PREFIX } from '@/constants'

export default [
  {
    path: `${CASH_MANAGEMENT_BLOCK_PREFIX}${AppNavigationRoutes.CashManagement.AnalysisPage}`,
    label: 'translate#title.analysis',
    Icon: AnalyticsIcon,
    moduleId: 'Summary_Analytics',
  },
  {
    path: `${CASH_MANAGEMENT_BLOCK_PREFIX}${AppNavigationRoutes.CashManagement.MonitoringPage}`,
    label: 'translate#title.monitoring',
    Icon: MonitoringIcon,
    moduleId: 'Monitoring',
  },
  {
    path: `${CASH_MANAGEMENT_BLOCK_PREFIX}${AppNavigationRoutes.CashManagement.PlanningPage}`,
    label: 'translate#title.planning',
    Icon: PlanningIcon,
    moduleId: 'Planning',
  },
  {
    path: `${CASH_MANAGEMENT_BLOCK_PREFIX}${AppNavigationRoutes.CashManagement.CitPlanningPage}`,
    label: 'translate#title.CitPlanning',
    Icon: PlanningIcon,
    moduleId: 'CitPlanning',
  },
  {
    path: `${CASH_MANAGEMENT_BLOCK_PREFIX}${AppNavigationRoutes.CashManagement.CashOrdersPage}`,
    label: 'translate#title.cashOrders',
    Icon: CashOrdersIcon,
    moduleId: 'Orders',
  },
  {
    path: `${CASH_MANAGEMENT_BLOCK_PREFIX}${AppNavigationRoutes.CashManagement.PlasticCardPage}`,
    label: 'translate#cm.Plastic_Cards',
    Icon: PlasticCardIcon,
    moduleId: 'Plastic_Cards',
  },
  {
    path: `${CASH_MANAGEMENT_BLOCK_PREFIX}${AppNavigationRoutes.CashManagement.ReplenishmentsPage}`,
    label: 'translate#title.replenishments',
    Icon: ReplenishmentsIcon,
    moduleId: 'Replenishments',
  },
  {
    path: `${CASH_MANAGEMENT_BLOCK_PREFIX}${AppNavigationRoutes.CashManagement.BalancingPage}`,
    label: 'translate#title.balancing',
    Icon: BalancingIcon,
    moduleId: 'Balancing',
  },
  {
    path: `${CASH_MANAGEMENT_BLOCK_PREFIX}${AppNavigationRoutes.CashManagement.CitBalancingPage}`,
    label: 'translate#title.CitBalancing',
    Icon: BalancingIcon,
    moduleId: 'CitBalancing',
  },
  {
    path: `${CASH_MANAGEMENT_BLOCK_PREFIX}${AppNavigationRoutes.CashManagement.MapsPage}`,
    label: 'translate#title.maps',
    Icon: MapsIcon,
    moduleId: 'Maps',
  },
  {
    path: `${CASH_MANAGEMENT_BLOCK_PREFIX}${AppNavigationRoutes.CashManagement.RoutesPage}`,
    label: 'translate#cm.Routes',
    Icon: RouteIcon,
    moduleId: 'OrdersRoutes',
  },
  {
    path: `${CASH_MANAGEMENT_BLOCK_PREFIX}${AppNavigationRoutes.CashManagement.AdminPage}`,
    label: 'translate#title.adminPanel',
    Icon: AdminIcon,
    moduleId: 'Admin',
  },
]
