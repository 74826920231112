import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      marginLeft: spacing(2.5),
    },
  },
  fieldsRow: {
    height: spacing(8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  field: {
    width: '49%',
  },
  subField: {
    width: '48%',
  },
  loaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    background: 'rgba(255, 255, 255, 0.7)',
  },
  radioGroup: {
    marginBottom: spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',

    '& > label': {
      marginRight: spacing(2.5),
    },
  },
}))
