export enum cashOrderStages {
  Created = 'CREATED',
  Approved = 'APPROVED',
  TeamAssigned = 'TEAM_ASSIGNED',
  WorkOrderCreated = 'WORK_ORDER_CREATED',
  RecalculatedBySender = 'RECALCULATED_BY_SENDER',
  Declined = 'DECLINED',
  DocumentsCreated = 'DOCUMENTS_CREATED',
  SentToExecute = 'SENT_TO_EXECUTE',
  SentToCollectors = 'SENT_TO_COLLECTORS',
  AcceptedByRecipient = 'ACCEPTED_AS_RECIPIENT',
  RecalculatedByRecipient = 'RECALCULATED_BY_RECIPIENT',
  Completed = 'COMPLETED',
}

export const VAULT_ORDER_STATUSES: OrderStatuses = {
  New: { color: '#25ADBF', backgroundColor: '#EAF7F9' },
  Approved: { color: '#3154AD', backgroundColor: '#EAEDF5' },
  Accepted: { color: '#3154AD', backgroundColor: '#EAEDF5' },
  'Recalculated By Sender': {
    color: '#41b77f',
    backgroundColor: '#ecf8f2',
  },
  'Work Order Created': {
    color: '#DDCF00',
    backgroundColor: '#FCF9CC',
  },
  Declined: { color: '#E45B4A', backgroundColor: '#FDEFED' },
  'Documents Created': { color: '#41b77f', backgroundColor: '#ecf8f2' },
  'Sent To Execute': { color: '#FEDA59', backgroundColor: '#FFFCEF' },
  'Sent To Collectors': { color: '#FF9737', backgroundColor: '#FFF5EB' },
  'Accepted By Recipient': {
    color: '#41b77f',
    backgroundColor: '#ecf8f2',
  },
  'Recalculated By Recipient': {
    color: '#41b77f',
    backgroundColor: '#ecf8f2',
  },
  Completed: { color: '#00C562', backgroundColor: '#E6FAF0' },
  Overdue: { color: '#41b77f', backgroundColor: '#ecf8f2' },
}

export const BAG_STATUSES: OrderStatuses = {
  1: { color: '#25ADBF', backgroundColor: '#EAF7F9' },
  2: { color: '#842EDA', backgroundColor: '#F5EAFF' },
  3: { color: '#3154AD', backgroundColor: '#EAEDF5' },
  4: { color: '#FF9737', backgroundColor: '#FFF5EB' }, // dark
  5: { color: '#FEDA59', backgroundColor: '#FFFCEF' }, // light
  6: { color: '#00C562', backgroundColor: '#E6FAF0' },
}

export const BAG_BALANCE: OrderStatuses = {
  0: { color: '#00C562', backgroundColor: '#E6FAF0' },
}

interface OrderStatuses {
  [key: string]: {
    color: string
    backgroundColor: string
  }
}
