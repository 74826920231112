import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'

import { styledComponentTheme } from '@/theme'

export const useClasses = makeStyles(({ spacing }) => ({
  dialog: {
    width: '1000px',
    height: '400px',
  },
  paper: {
    height: '100%',
    padding: spacing(3),
    paddingTop: spacing(2),
    display: 'grid',
    gridTemplateRows: 'max-content 1fr max-content',
  },
  title: {
    color: '#000',
    fontSize: styledComponentTheme.fontSizes.big,
    whiteSpace: 'nowrap',
  },
  subTitle: {
    color: '#929A9B',
    fontSize: styledComponentTheme.fontSizes.normal,
    paddingLeft: spacing(2),
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(1),
  },
  filed: {
    margin: '16px 0 0',
  },
  button: {
    height: 40,
    fontWeight: 'normal',
    padding: '0 20px',
    margin: 0,
  },

  field: {
    margin: '16px 0 0',
  },
}))

export const ActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  align-items: center;
  grid-column-gap: 8px;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
`
