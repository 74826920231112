export const cancelablePromise = (executor: any) => {
  let canceled = false

  const promise = new Promise((resolve, reject) => {
    executor((val: any) => {
      if (canceled) {
        return
      }
      resolve(val)
    }, reject)
  })
  ;(promise as any).cancel = () => {
    canceled = true
  }

  return promise
}
