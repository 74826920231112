import { createTheme } from '@material-ui/core/styles'

import colors from './colors'
import units from './units'

export const productsTheme = createTheme({
  palette: {
    primary: {
      light: colors.products.primaryLight,
      main: colors.products.primary,
      dark: colors.products.primary,
    },
    secondary: {
      main: colors.products.secondary,
      light: colors.products.greyLight,
    },
    text: {
      primary: colors.products.black,
      secondary: colors.products.black,
      disabled: colors.products.secondaryFont,
      hint: colors.textLight,
    },
    grey: {
      50: colors.grey50,
      100: colors.grey100,
      200: colors.grey200,
      300: colors.grey300,
      400: colors.grey400,
    },
    error: {
      main: colors.products.danger,
      light: colors.products.dangerLight,
    },
    background: {
      default: colors.background,
    },
  },
  typography: {
    h5: {
      fontWeight: 'bold',
      marginBottom: units.single,
    },
    h6: {
      fontWeight: 'bold',
      fontSize: units.single * 2.25,
    },
    // button: {
    //   fontSize: units.single * 1.75,
    // },
  },
  props: {
    MuiButton: {
      color: 'primary',
      disableElevation: true,
    },
    MuiRadio: {
      color: 'primary',
    },
    MuiCheckbox: {
      color: 'primary',
    },
    MuiGrid: {
      alignItems: 'center',
    },
    MuiTextField: {
      size: 'small',
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-child(even)': {
          backgroundColor: colors.products.greyLight,
        },
      },
    },
    MuiButton: {
      root: {
        transition: '0.2s',

        '&:disabled': {
          backgroundColor: `${colors.products.secondaryBackground} !important`,
          borderColor: `${colors.products.secondaryBackground} !important`,

          '& path': {
            fill: '#CAD5D7',
          },
        },
      },
      containedPrimary: {
        color: '#ffffff',
        backgroundColor: colors.products.primary,

        '& path': {
          fill: '#ffffff',
        },
        '&:hover': {
          backgroundColor: '#0a94a6',
        },
      },
      outlinedPrimary: {
        color: colors.products.primary,
        borderColor: colors.products.primary,
        backgroundColor: '#ffffff',

        '& path': {
          fill: '#25ADBF',
        },
        '&:hover': {
          backgroundColor: colors.products.primaryLight,
        },
      },
      containedSecondary: {
        color: colors.products.danger,
        backgroundColor: colors.products.dangerLight,

        '& path': {
          fill: '#E45B4A',
        },
        '&:hover': {
          backgroundColor: '#ffded9',
        },
      },
      outlinedSecondary: {
        color: colors.products.secondary,
        backgroundColor: colors.background,

        '& path': {
          fill: '#929A9B',
        },
        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
      },
      textSecondary: {
        color: '#041517',
        backgroundColor: '#F9F9F9',

        '& path': {
          fill: '#041517 !important',
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.textLight,
      },
      asterisk: {
        color: 'red',
      },
    },
    MuiCheckbox: {
      root: {
        color: '#c4c4c4',
      },
    },
    MuiGrid: {
      item: {
        maxWidth: '100%',
      },
    },
    MuiIcon: {
      root: {
        display: 'inline-flex',
        alignItems: 'center',
      },
    },
    MuiTableCell: {
      stickyHeader: {
        left: 'auto',
      },
    },
  },
  spacing: units.single,
})
