/* eslint-disable @typescript-eslint/camelcase */
import queryString from 'query-string'

import {
  AdmCompanyBase,
  AdmUserOperationInfo,
  BS2CashPointEvent,
  ComBasicCashPoint,
  ComBuildInfo,
  ComCassetteCurrency,
  ComCMReport,
  ComCulture,
  ComCurrency,
  ComFilterTreeNode,
  ComPosition,
  ComServiceCompany,
  ComTeam,
  ComTotals,
  ComUser,
  ComVaultsWithLocationsRequest,
  DocDocumentsResponse,
  eBS2AvailableIntegration,
  eVL2VaultType,
  IcsCashPointCassette,
  IcsCashPointCassettesDetails,
  IcsEventBase,
  IcsMissedReplenishmentResponse,
  VaultRemainderUpdateAvailabilityEntry,
  VaultsResponse,
} from '@/api/cm/swaggerGeneratedApi/index'
import { store } from '@/App'
import { APIConfiguration, LocalStorageItems } from '@/constants'
import { signOutCM } from '@/store/cm/auth/actions'
import { setActErrorVisible } from '@/store/common/errors'
import { ComUsersProps } from '@/types/cm/common'
import { PopUpService } from '@/utils/services/popUpService'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class CMCommonApi {
  public static requestService = getRequestServiceCM()

  public static async getServerBuildInfo(): Promise<ComBuildInfo> {
    try {
      return await CMCommonApi.requestService.get('/common/buildInfo')
    } catch (e) {
      PopUpService.showGlobalPopUp(`translate#response.error.cmFailedToGetServerBuildInfo`, 'error')
      throw new Error(e)
    }
  }

  public static async getClientBuildInfo(): Promise<string> {
    try {
      if (process.env.NODE_ENV !== 'production') return 'Development build'

      const response = await fetch(`${process.env.PUBLIC_URL}/buildinfo.txt`)

      if (response?.ok) {
        return await response.text()
      }

      throw new Error()
    } catch (e) {
      PopUpService.showGlobalPopUp(`translate#response.error.cmFailedToGetClientBuildInfo`, 'error')
      throw new Error(e)
    }
  }

  public static async getCompanies(): Promise<Array<ComServiceCompany>> {
    try {
      return await CMCommonApi.requestService.get('/common/companies')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setCompanies(company: AdmCompanyBase): Promise<Array<ComServiceCompany>> {
    try {
      return await CMCommonApi.requestService.post('/admin/companies/add', company)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getAvailableCassetteNumbers(guid: string): Promise<number[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/common/availableCassetteNumbers',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )
      return await CMCommonApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCurrencies(): Promise<Array<ComCurrency>> {
    try {
      return await CMCommonApi.requestService.get('/common/currencies')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getDenominations(): Promise<Array<ComCurrency>> {
    try {
      return await CMCommonApi.requestService.get('/shared/currenciesAndDenominations')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getFilterCurrencies(): Promise<Array<ComCurrency>> {
    try {
      return await CMCommonApi.requestService.get('/common/filterCurrencies')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getFilterNodes(): Promise<ComFilterTreeNode> {
    try {
      return await CMCommonApi.requestService.get('/common/filternodes')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCultures(): Promise<ComCulture> {
    try {
      return await CMCommonApi.requestService.get('/common/culture')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getUsers(payload: ComUsersProps): Promise<ComUser> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/common/users',
          query: { ...payload },
        },
        { skipNull: true, encode: false },
      )

      return await CMCommonApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getUsersOperations(): Promise<AdmUserOperationInfo[]> {
    try {
      return await CMCommonApi.requestService.get('/admin/accesssRules/userActivity/userOps')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCassetteCurrencies(): Promise<ComCassetteCurrency[]> {
    try {
      return await CMCommonApi.requestService.get('/common/casseteCurrencies')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getReports(payload: {
    id: string | number
    stageId?: string | number
  }): Promise<DocDocumentsResponse> {
    try {
      return await CMCommonApi.requestService.get('/doc/documents', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getReport(
    payload: ComCMReport,
    reportFormat = 'PDF',
  ): Promise<Blob | undefined> {
    try {
      const { url, postBody, reportMethod } = payload
      const tokenCM = localStorage.getItem(LocalStorageItems.AccessTokenCM) || ''

      const methods = {
        report_get: 'GET',
        report_post: 'POST',
      }

      const fullUrl = `${APIConfiguration.CM_API_BASE_URL}/${url}?reportFormat=${
        reportFormat === 'Zip' ? 'None&isZip=true' : reportFormat
      }`

      const response = await fetch(fullUrl, {
        body: postBody,
        headers: { Authorization: `Bearer ${tokenCM}` },
        method: methods[reportMethod] || 'POST',
      })

      if (!response?.ok) {
        response.text().then(text => {
          const parsedError = JSON.parse(JSON.parse(text).message)
          const errorMessage = parsedError.message

          if (errorMessage && parsedError.errorType === 'silent_error') {
            PopUpService.showGlobalPopUp(errorMessage, 'error')
          }

          if (errorMessage && parsedError.errorType === 'ack_error') {
            store.dispatch(setActErrorVisible({ isVisible: true, errorMessage }))
          }
        })
      }

      if (response?.ok) {
        return await response.blob()
      } else if (response.status === 401) {
        store.dispatch(signOutCM())

        PopUpService.showGlobalPopUp(`translate#response.error.unauthorized`, 'error')
      }
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async getFilterCashPoints(payload: {
    branchIds: string[]
  }): Promise<ComBasicCashPoint> {
    try {
      return await CMCommonApi.requestService.post('/common/cashpoints', payload)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async getMissedReplenishment({
    requestBody,
    paging,
    sort,
  }: {
    requestBody: { dateFrom: string; cashPointId: string }
    paging: { page: number; pageSize: number }
    sort?: { sortColumn?: string | undefined; sortOrder?: 'asc' | 'desc' | undefined }
  }): Promise<IcsMissedReplenishmentResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/ics/missedReplen',
          query: { ...paging, ...sort },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await CMCommonApi.requestService.post(url, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getMissedReplenishmentCassettes({
    eventType,
    orderId,
  }: {
    orderId: string
    eventType: string
  }): Promise<IcsCashPointCassettesDetails> {
    try {
      return await CMCommonApi.requestService.post('/ics/missedReplen/newEventCassettes', {
        eventType,
        orderId,
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async createMissedReplenishmentEvent(requestBody: {
    cassettes: IcsCashPointCassette[]
    date: string
    eventType: BS2CashPointEvent
    cashOrderId: string
  }): Promise<void> {
    try {
      return await CMCommonApi.requestService.post('/ics/missedReplen/createEvent', requestBody)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async applyMissedReplenishmentEvent(requestBody: {
    event: IcsEventBase
    cashOrderId: string
  }): Promise<void> {
    try {
      return await CMCommonApi.requestService.post('/ics/missedReplen/apply', requestBody)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async updateMissedIcsCassettes(requestBody: {
    cassettes: IcsCashPointCassette[]
    eventType: BS2CashPointEvent
  }): Promise<ComTotals> {
    try {
      return await CMCommonApi.requestService.post('/ics/missedReplen/totals', requestBody)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async getTeams(): Promise<ComTeam[]> {
    try {
      return await CMCommonApi.requestService.get('/common/teams')
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async getPositions(): Promise<ComPosition[]> {
    try {
      return await CMCommonApi.requestService.get('/common/positions')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getIntegration(): Promise<eBS2AvailableIntegration[]> {
    try {
      return await CMCommonApi.requestService.get('/common/integrations')
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async getVaultsWithLocation(payload?: eVL2VaultType[]): Promise<VaultsResponse> {
    try {
      const requestBody: ComVaultsWithLocationsRequest = {
        vaultTypes: payload || [
          // TODO: получать список с бэка, пока на бэке не реализовано
          'Vault',
          'CashProvider',
          'CorporateClient',
          'BankBranch',
          'Cashier',
          'CentralBank',
          'CommercialBank',
          'SeniorCashier',
        ],
      }

      return await CMCommonApi.requestService.post('/common/vaultsWithLocation', requestBody)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async getTransactionButtonStatus(
    ids: string[],
  ): Promise<VaultRemainderUpdateAvailabilityEntry[]> {
    try {
      return await CMCommonApi.requestService.post('monitoring/vault/transactions/isUpdatable', ids)
    } catch (e) {
      throw new Error(e)
    }
  }
}
