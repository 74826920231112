import { AnyAction } from 'redux'

import { AdmCurrency, AdmCurrencyBase } from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants/actions'
import { Sort } from '@/types/cm'

// get App
export const getAdmCurrenciesRequest = (): AnyAction => ({
  type: VaultV1Actions.AdmGetCurrenciesRequest,
})
export const getAdmCurrenciesResponse = (payload: AdmCurrency[]): AnyAction => ({
  type: VaultV1Actions.AdmGetCurrenciesResponse,
  payload,
})
export const getAdmCurrenciesFail = (): AnyAction => ({
  type: VaultV1Actions.AdmGetCurrenciesFail,
})

// add
export const addAdmCurrencyRequest = (payload: AdmCurrencyBase): AnyAction => ({
  type: VaultV1Actions.AdmAddCurrencyRequest,
  payload,
})
export const addAdmCurrencyResponse = (): AnyAction => ({
  type: VaultV1Actions.AdmAddCurrencyResponse,
})
export const addAdmCurrencyFail = (): AnyAction => ({
  type: VaultV1Actions.AdmAddCurrencyFail,
})

// update
export const updateAdmCurrencyRequest = (payload: AdmCurrency): AnyAction => ({
  type: VaultV1Actions.AdmUpdateCurrencyRequest,
  payload,
})
export const updateAdmCurrencyResponse = (): AnyAction => ({
  type: VaultV1Actions.AdmUpdateCurrencyResponse,
})
export const updateAdmCurrencyFail = (): AnyAction => ({
  type: VaultV1Actions.AdmUpdateCurrencyFail,
})

// delete
export const deleteAdmCurrencyRequest = (): AnyAction => ({
  type: VaultV1Actions.AdmDeleteCurrencyRequest,
})
export const deleteAdmCurrencyFail = (): AnyAction => ({
  type: VaultV1Actions.AdmDeleteCurrencyFail,
})

// set base
export const setAdmCurrencyBaseRequest = (): AnyAction => ({
  type: VaultV1Actions.AdmSetCurrencyBaseRequest,
})
export const setAdmCurrencyBaseResponse = (): AnyAction => ({
  type: VaultV1Actions.AdmSetCurrencyBaseResponse,
})
export const setAdmCurrencyBaseFail = (): AnyAction => ({
  type: VaultV1Actions.AdmSetCurrencyBaseFail,
})

export const setAdmCurrencySelected = (payload: AdmCurrency | null): AnyAction => ({
  type: VaultV1Actions.AdmSetSelectedCurrency,
  payload,
})

export const sortAdmCurrencies = (payload: Sort): AnyAction => ({
  type: VaultV1Actions.AdmSortCurrencies,
  payload,
})

export const setAdmCurrenciesOnlyBase = (payload: boolean): AnyAction => ({
  type: VaultV1Actions.AdmSetCurrenciesOnlyBase,
  payload,
})
