import { FormikValues } from 'formik'
import { ValidationErrors } from '@/constants/forms/validationErrors'
import { ProductFieldName } from '@/types/products/products'

export const validate = (initialValues?: FormikValues) => (values: FormikValues): FormikValues => {
  const errors: FormikValues = {}

  if (initialValues && Object.keys(values).every(key => initialValues[key] === values[key])) {
    errors.error = ''
  }
  if (!values[ProductFieldName.ProductCode]) {
    errors[ProductFieldName.ProductCode] = ValidationErrors.Required
  }
  if (!values[ProductFieldName.ProductName]) {
    errors[ProductFieldName.ProductName] = ValidationErrors.Required
  }

  return errors
}
