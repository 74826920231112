import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import qs from 'qs'

import { ComVerboseError } from '@/api/cm/swaggerGeneratedApi'
import { store } from '@/App'
import {
  APIConfiguration,
  CASH_MANAGEMENT_BLOCK_PREFIX,
  INITIAL_LOCALE,
  ISOLanguagesCodes,
  LocalStorageItems,
} from '@/constants'
import { signOutCM } from '@/store/cm/auth'
// tmp
import { setActErrorVisible } from '@/store/common/errors'
import { removeEmptyFieldAtObjectDeep } from '@/utils/functions'
import { PopUpService } from '@/utils/services/popUpService'

export const LOGIN_URL = '/account/login'

const getFormData = (object: Record<string, any>): FormData => {
  const formData: FormData = new FormData()

  Object.keys(object).forEach(key => {
    if (Array.isArray(object[key])) {
      object[key].forEach((i: string | Blob) => formData.append(key, i))
    } else {
      formData.append(key, object[key])
    }
  })

  return formData
}

export const httpErrorDefaultMessages: { [key: number]: string } = {
  400: 'translate#response.error.badRequest',
  401: 'translate#response.error.unauthorized',
  403: 'translate#response.error.forbidden',
  404: 'translate#response.error.notFound',
  422: 'translate#response.error.validationsErrors',
  500: 'translate#response.error.internalServerError',
  502: 'translate#response.error.badGateway',
  503: 'translate#response.error.serviceUnavailable',
  504: 'translate#response.error.badRequest',
}

const getErrorMessage = (error: AxiosError, errorMessage?: string): string => {
  return (
    (error.response
      ? `${error.response.status}: ${errorMessage || error.response.data}` ||
        httpErrorDefaultMessages[error.response.status]
      : error.message) || 'translate#cm.ErrorWindowGenericError'
  )
}

class RequestServiceCM {
  private service: AxiosInstance

  constructor(baseURL: string | undefined = APIConfiguration.CM_API_BASE_URL) {
    const service = axios.create({
      baseURL,
    })

    service.interceptors.request.use(config => {
      const token = localStorage.getItem(LocalStorageItems.AccessTokenCM) || ''
      const language = sessionStorage.getItem(LocalStorageItems.Locale) || INITIAL_LOCALE

      config.headers['Content-type'] = 'application/json; charset=UTF-8'

      if (config.url !== `${CASH_MANAGEMENT_BLOCK_PREFIX}/sign-in`) {
        config.headers.Authorization = `Bearer ${token}`
      }

      config.headers['accept-language'] = ISOLanguagesCodes[language]

      if (config.data instanceof FormData) {
        Object.assign(config.headers, { 'Content-Type': 'application/x-www-form-urlencoded' })
      }

      return config
    })

    service.interceptors.response.use(this.handleSuccess, this.handleError)

    this.service = service
  }

  private handleSuccess = (response: AxiosResponse): AxiosResponse => {
    return response
  }

  private handleError = (error: AxiosError): void => {
    if (error.response) {
      const { data: errorResponse } = error.response

      const parsedError: ComVerboseError = JSON.parse(errorResponse || '')
      const errorMessage = getErrorMessage(error, parsedError?.message)

      if (errorMessage && parsedError?.errorType === 'silent_error') {
        PopUpService.showGlobalPopUp(errorMessage, 'error')
      }

      if (errorMessage && parsedError?.errorType === 'ack_error') {
        store.dispatch(setActErrorVisible({ isVisible: true, errorMessage }))
      }

      switch (error.response.status) {
        // Unauthorized
        case 401:
          store.dispatch(signOutCM())
          break
        // Forbidden
        case 403:
          PopUpService.showGlobalPopUp(errorMessage, 'error')
          break
        default:
          break
      }

      throw errorResponse
    } else {
      PopUpService.showGlobalPopUp(getErrorMessage(error), 'error')
      throw error.message
    }
  }

  public get(path: string, params?: unknown): Promise<AxiosResponse['data']> {
    return this.service
      .get(path, {
        params,
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      })
      .then(response => response.data)
  }

  public getWithFullResponse(path: string, params?: unknown): Promise<AxiosResponse['data']> {
    return this.service.get(path, {
      params,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
  }

  public postWithFullResponse = (path: string, data: unknown): Promise<AxiosResponse['data']> =>
    this.service.post(path, data)

  public patch = (path: string, data?: unknown): Promise<AxiosResponse['data']> => {
    return this.service.patch(path, data).then(response => response.data)
  }

  public post = (
    path: string,
    data: unknown,
    withHeaders?: boolean,
  ): Promise<AxiosResponse['data']> => {
    return this.service.post(path, removeEmptyFieldAtObjectDeep(data)).then(response => {
      return withHeaders ? { data: response.data, headers: response.headers } : response.data
    })
  }

  public postComplete = (path: string, data: unknown): Promise<AxiosResponse['data']> => {
    return this.service.post(path, data)
  }

  public getComplete(path: string, params?: unknown): Promise<AxiosResponse['data']> {
    return this.service.get(path, {
      params,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
  }

  public postFormData = (
    path: string,
    data: Record<string, any>,
  ): Promise<AxiosResponse['data']> => {
    return this.service.post(path, getFormData(data)).then(response => response.data)
  }

  public put = (path: string, data: unknown): Promise<AxiosResponse['data']> => {
    return this.service
      .put(path, removeEmptyFieldAtObjectDeep(data))
      .then(response => response.data)
  }

  public delete = (path: string, data?: unknown): Promise<AxiosResponse['data']> => {
    return this.service.delete(path, { data }).then(response => response.data)
  }
}

export const requestService = new RequestServiceCM()

export const getRequestServiceCM = (
  baseURL: string | undefined = APIConfiguration.CM_API_BASE_URL,
): RequestServiceCM => {
  return new RequestServiceCM(baseURL)
}
