export * from './cassettes'
export * from './frames'
export * from './monitoring/modalsName'
export * from './tables'

export enum VaultStuff {
  Currency = 'Currency',
  Valuable = 'Valuable',
}

export enum MonVaultEntryType {
  Currency = 'Currency',
  Valuable = 'Valuables',
  Bag = 'Bags',
  Cassette = 'Cassettes',
}
