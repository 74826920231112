import { AppNavigationRoutes, SD_BLOCK_PREFIX } from '@/constants/paths'

export const PERMISSIONS = {
  // POPUPS
  createNewTicket: 'SRVTicket_insert',
  createPOSServiceRequest: 'POSCustomerSR_insert',
  createClameTicket: 'SRVComplaintTicket_create',

  // MODULES
  sdModule: 'SRV_ClWEB_Perspective',
  srmModule: 'Service',
  // PAGES
  [`${SD_BLOCK_PREFIX}${AppNavigationRoutes.AllTicketsPage}`]: 'SRV_ClWEB_SearchRequests_Open_ALL',
  [`${SD_BLOCK_PREFIX}${AppNavigationRoutes.TicketsPage}`]: 'SRV_ClWEB_SearchRequests_Open_MY',
  [`${SD_BLOCK_PREFIX}${AppNavigationRoutes.HistoryTicketsPage}`]: 'SRV_ClWEB_SearchRequests',

  [`${SD_BLOCK_PREFIX}${AppNavigationRoutes.DashboardPage}`]: 'SRV_ClWEB_SearchRequests_Open_ALL',
}

export const PERMISSION_TYPES = {
  FULL_ACCESS: 'FULL_ACCESS',
  NO_ACCESS: 'NO_ACCESS',
}

export const isMenuItemDisabled = (
  path: string | undefined,
  permissions: PermissionsResponseType,
): boolean => {
  const responseKey = PERMISSIONS?.[path || '']

  return permissions[responseKey] === PERMISSION_TYPES.NO_ACCESS
}

export type PermissionsResponseType = Record<typeof PERMISSIONS[keyof typeof PERMISSIONS], string>

export type UserPermissionsResponseType = Record<string, boolean>
