import { AnyAction } from 'redux'

import { AdmCashCassetteType } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants/actions/cmActions'
import { Sort } from '@/types/cm'

// get
export const getAdmCassetteTypesRequest = (): AnyAction => ({
  type: CMAppConfigActions.AdmGetCassetteTypesRequest,
})
export const getAdmCassetteTypesResponse = (response: AdmCashCassetteType[]): AnyAction => ({
  type: CMAppConfigActions.AdmGetCassetteTypesResponse,
  payload: response,
})
export const getAdmCassetteTypesFail = (error: string | unknown): AnyAction => ({
  type: CMAppConfigActions.AdmGetCassetteTypesFail,
  payload: error,
})

// add
export const addAdmCassetteTypeRequest = (cassetteType: AdmCashCassetteType): AnyAction => ({
  type: CMAppConfigActions.AdmAddCassetteTypeRequest,
  payload: cassetteType,
})
export const addAdmCassetteTypeFail = (error: string | unknown): AnyAction => ({
  type: CMAppConfigActions.AdmAddCassetteTypeFail,
  payload: error,
})

// update
export const updateAdmCassetteTypeRequest = (cassetteType: AdmCashCassetteType): AnyAction => ({
  type: CMAppConfigActions.AdmUpdateCassetteTypeRequest,
  payload: cassetteType,
})
export const updateAdmCassetteTypeFail = (error: string | unknown): AnyAction => ({
  type: CMAppConfigActions.AdmUpdateCassetteTypeFail,
  payload: error,
})

// delete
export const deleteAdmCassetteTypeRequest = (): AnyAction => ({
  type: CMAppConfigActions.AdmDeleteCassetteTypeRequest,
})
export const deleteAdmCassetteTypeResponse = (): AnyAction => ({
  type: CMAppConfigActions.AdmDeleteCassetteTypeResponse,
})
export const deleteAdmCassetteTypeFail = (error: string | unknown): AnyAction => ({
  type: CMAppConfigActions.AdmDeleteCassetteTypeFail,
  payload: error,
})

// selected
export const setAdmSelectedCassetteType = (payload: AdmCashCassetteType): AnyAction => ({
  type: CMAppConfigActions.AdmSetSelectedCassetteType,
  payload,
})

// sort
export const sortAdmCassetteTypes = (payload: Sort): AnyAction => ({
  type: CMAppConfigActions.AdmSortCassetteTypes,
  payload,
})
