import { debounce, DebouncedFunc } from 'lodash'
import {
  getParsedLocalStorageItem,
  getUsersSettings,
  setDataToLocalStorage,
} from '@/helpers/localStorage'
import { LocalStorageItems } from '@/constants'

const getFrameSizeLocalStorage = (baseUrl: string) => (panelId: string): number | null => {
  const { allUsersSettings, userData } = getUsersSettings(baseUrl)

  if (userData?.login) {
    const parsedFramesSizes = allUsersSettings[userData.login]?.frames || {}

    return parsedFramesSizes[panelId]
  }

  return null
}

const setFrameSizeLocalStorage = (baseUrl: string) => ({
  panelId,
  size,
}: {
  panelId: string
  size: number
}): void => {
  const userSettings = getParsedLocalStorageItem(baseUrl)(LocalStorageItems.UsersSettings) || {}

  const { userData } = getUsersSettings(baseUrl)

  if (userData?.login && userSettings[userData.login]) {
    if (!userSettings[userData.login].frames) {
      userSettings[userData.login].frames = {}
    }

    userSettings[userData.login].frames[panelId] = size

    setDataToLocalStorage(baseUrl)(LocalStorageItems.UsersSettings, JSON.stringify(userSettings))
  }
}

const debounceUpdateFrames = (
  baseUrl: string,
): DebouncedFunc<(panelId: string, size: number) => void> =>
  debounce((panelId: string, size: number) => {
    setFrameSizeLocalStorage(baseUrl)({ panelId, size })
  }, 500)

export { getFrameSizeLocalStorage, setFrameSizeLocalStorage, debounceUpdateFrames }
