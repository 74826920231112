import moment from 'moment'
import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { Account } from '@/api/common/authorizationApi/types'
import { TicketsApi } from '@/api/sd/ticketsApi'
import { GetGroupDetailsBody, GetTicketsBody } from '@/api/sd/ticketsApi/types'
import { TICKET_PAGE_TICKET_TABLE } from '@/components/pages/sd/TicketsPage/components/TicketsTable/component'
import { TYPE_OF_TICKETS } from '@/components/routers/sd/TicketsRouter/component'
import { TicketActions } from '@/constants'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { gettingTickets } from '@/helpers/tickets'
import { State } from '@/store/sd/tickets/reducer'
import { TableSettingsData, TypeOfTicket, TypeOfTickets } from '@/types'
import { TicketDetailsResponse } from '@/types/sd/tickets/ticketDetails'
import { getTableSettings, setTableSettings } from '@/utils/localStorage'
import { PopUpService } from '@/utils/services/popUpService'

import {
  changeGrouping,
  changeSelectedFilters,
  changeSorting,
  clearGroupedTicketDetailsData,
  collectFilterTemplateFields,
  createSdFilterTemplateResponse,
  deleteSdFilterTemplateResponse,
  deleteSdGroupTemplateResponseSuccess,
  editGroupTemplateFromResponse,
  // changeSelectedFilters,
  // clearSelectedFilters,
  getFiltersTemplatesResponse,
  getGroupDetailsResponse,
  getGroupDetailsResponseFail,
  getGroupingTemplatesResponse,
  getGroupingTemplatesResponseFail,
  getTicketDetailsResponse,
  getTicketDetailsResponseFail,
  getTicketsConfigReponse,
  getTicketsConfigResponseFail,
  // getFiltersTemplatesResponseFail,
  getTicketsResponse,
  getTicketsResponseFail,
  setGroupDetailsResponse,
  setGroupDetailsResponseEmpty,
  // saveFiltersTemplateResponse,
  // saveFiltersTemplateResponseFail,
  // editFiltersTemplateResponseFail,
  // editFiltersTemplateResponse,
  setGroupTemplates,
  setSdFilterTemplatesResponse,
  setTicketsPage,
  setTicketsRowsPerPage,
  updateSdFilterTemplateResponse,
} from './actions'

function* getTickets({ payload: { isInitRequest } }: AnyAction): SagaIterator {
  try {
    const accountState: Account | null = yield select(state => state.auth.currentAccount)
    const ticketsState: State = yield select(state => state.tickets)
    const {
      typeOfTickets,
      config: {
        [typeOfTickets as TypeOfTickets]: { formUID, getOutTreeURL, filter, groups },
      },
      rowsPerPage: { [typeOfTickets as TypeOfTickets]: rowsPerPage },
      page: { [typeOfTickets as TypeOfTickets]: page },
      grouping: {
        [typeOfTickets as TypeOfTickets]: { selectedGroupings },
      },
      sorting: { [typeOfTickets as TypeOfTickets]: sorting },
      filterFields: { [typeOfTickets as TypeOfTickets]: filterTemplateFields },
      filter: {
        [typeOfTickets as TypeOfTickets]: { selectedFilters },
      },
    } = ticketsState

    const tableId = TICKET_PAGE_TICKET_TABLE
    const REQUEST_BODY_LS_KEY = 'ticketsConfig'
    let savedSettings: TableSettingsData | null = null
    let requestBody
    if (accountState) {
      savedSettings = getTableSettings(
        LocalStorageItems.UserSettings,
        accountState.userId.toString(),
        `${tableId}/${typeOfTickets}`,
      )
    }

    if (isInitRequest && savedSettings && savedSettings[REQUEST_BODY_LS_KEY]) {
      const {
        requestBodyData,
        selectedFilters,
        selectedGroupings,
        sorting,
        filterTemplateFields,
        page,
        rowsPerPage,
      } = savedSettings[REQUEST_BODY_LS_KEY]

      requestBody = requestBodyData

      yield put(changeSelectedFilters(selectedFilters))
      yield put(changeGrouping(selectedGroupings))
      yield put(changeSorting(sorting))
      yield put(collectFilterTemplateFields(filterTemplateFields))
      yield put(setTicketsRowsPerPage(rowsPerPage))
      yield put(setTicketsPage(page))
    } else {
      requestBody = {
        filter: gettingTickets.getFilters(selectedFilters, filter),
        formUID,
        getOutTreeURL,
        groups: gettingTickets.getGroups(selectedGroupings, groups),
        pageNo: page,
        pageSize: rowsPerPage,
        sortDetails: gettingTickets.getSortDetails(sorting),
      }
      if (accountState) {
        setTableSettings(LocalStorageItems.UserSettings, accountState.userId.toString(), {
          [`${tableId}/${typeOfTickets}`]: {
            ...(savedSettings && { ...savedSettings }),
            ticketsConfig: {
              requestBodyData: requestBody,
              selectedFilters,
              selectedGroupings,
              sorting,
              page,
              rowsPerPage,
              filterTemplateFields,
            },
          },
        })
      }
    }

    if (typeOfTickets === TypeOfTicket.HistoryTickets) {
      if (requestBody.filter != null && requestBody.filter.length <= 0) {
        const val = {
          parameterName: 'receivedate',
          parameterValue: null,
          rangeValues: {
            from: moment()
              .subtract(1, 'months')
              .unix(),
            to: null,
          },
          valueType: 'DATE',
        }
        requestBody.filter.push(val)
      }
    }

    const response = yield call(
      TicketsApi.getTickets,
      typeOfTickets as string,
      requestBody as GetTicketsBody,
    )

    yield put(getTicketsResponse(response))
  } catch (error) {
    yield put(getTicketsResponseFail(error.message))
  }
}

function* getGroupDetails(): SagaIterator {
  try {
    const ticketsState: State = yield select(state => state.tickets)
    const {
      typeOfTickets,
      config: {
        [typeOfTickets as TypeOfTickets]: { formUID, filter },
      },
      page: { [typeOfTickets as TypeOfTickets]: page },
      parentGroups,
      groupDetailsTest,
      sorting: { [typeOfTickets as TypeOfTickets]: sorting },
      filter: {
        [typeOfTickets as TypeOfTickets]: { selectedFilters },
      },
      pageSizeForGroupingTickets,
    } = ticketsState
    const requestBody: GetGroupDetailsBody = {
      filter: gettingTickets.getFilters(selectedFilters, filter),
      formUID,
      getOutDetailsURL: 'tickets|/search-form/client/srgroup-details/open/all',
      parentGroups: parentGroups,
      pageNo: page,
      pageSize: Number(pageSizeForGroupingTickets) || 10,
      //        page === 1
      //          ? Number(pageSizeForGroupingTickets) * 2 || 10 * 2
      //          : Number(pageSizeForGroupingTickets) || 10,
      sortDetails: gettingTickets.getSortDetails(sorting),
    }

    const response = yield call(TicketsApi.getGroupedDetails, typeOfTickets as string, requestBody)

    if (!response.details) {
      yield put(setTicketsPage(page - 1))
    }

    yield put(getGroupDetailsResponse(response))
    if (groupDetailsTest && Object.keys(groupDetailsTest).includes(parentGroups[0]?.groupValue)) {
      yield put(setGroupDetailsResponse(response, parentGroups[0]?.groupValue))
    } else {
      yield put(setGroupDetailsResponseEmpty(response, parentGroups[0]?.groupValue))
    }
  } catch (error) {
    yield put(getGroupDetailsResponseFail(error.message))
  }
}

function* getSdFilterTemplates({ payload }: AnyAction): Generator {
  try {
    const response: any = yield call(TicketsApi.getSdFilterTemplates, payload.windowType)
    yield put(setSdFilterTemplatesResponse(response.availableTemplates))
  } catch (error) {
    throw new Error(error)
  }
}

function* createFilterTemplate({ payload }: AnyAction): Generator {
  try {
    const response: any = yield call(TicketsApi.createFitlerTemplate, payload)
    const newTemplateId = response.availableTemplates.find(
      (template: { id: string; name: string }) => template.name === payload.name,
    )
    yield put(
      createSdFilterTemplateResponse({
        name: payload.name,
        id: newTemplateId.id,
      }),
    )
    PopUpService.showGlobalPopUp(`${payload.translate('translate#title.templateSaved')}`)
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('title.templateCreateError')}.\n${error}`,
      'error',
    )
  }
}

function* updateSdFilterTemplate({ payload }: AnyAction): Generator {
  try {
    const response = yield call(TicketsApi.updateSdFilterTemplate, payload)
    if (response) {
      yield put(updateSdFilterTemplateResponse(payload))
      PopUpService.showGlobalPopUp(
        `${payload.translate('translate#title.templateEdited')}`,
        'success',
      )
    }
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#title.templateEditError')}.\n${error}`,
      'error',
    )
  }
}

function* deleteFilterTemplate({ payload }: AnyAction): Generator {
  try {
    const response: any = yield call(TicketsApi.deleteSdFilterTemplate, payload)
    if (response.status === 200) {
      yield put(deleteSdFilterTemplateResponse(payload))
      PopUpService.showGlobalPopUp(
        `${payload.translate('translate#title.templateRemoved')}`,
        'success',
      )
    }
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#title.templateDeletionError')}.\n${error}`,
      'error',
    )
  }
}

function* getFiltersTemplates(): Generator {
  try {
    const response = yield call(TicketsApi.getFiltersTemplates)

    yield put(getFiltersTemplatesResponse(response))
  } catch (error) {
    // yield put(getFiltersTemplatesResponseFail(error.message))
  }
}

function* saveFiltersTemplate({ payload: { template } }: AnyAction): Generator {
  try {
    // const response = yield call(TicketsApi.saveFiltersTemplate, template)
    // yield put(saveFiltersTemplateResponse({ id: '3', ...template }))
  } catch (error) {
    // yield put(saveFiltersTemplateResponseFail(error.message))
  }
}

function* editFiltersTemplate({ payload: { template } }: AnyAction): Generator {
  try {
    /* const response = */
    // yield call(TicketsApi.editFiltersTemplate, template)
    // yield put(editFiltersTemplateResponse(template))
  } catch (error) {
    // yield put(editFiltersTemplateResponseFail(error.message))
  }
}

function* deleteFiltersTemplate({ payload: { template } }: AnyAction): Generator {
  try {
    /* const response = */
    // yield call(TicketsApi.deleteFiltersTemplate, template)
    // yield put(editFiltersTemplateResponse(template))
  } catch (error) {
    // yield put(editFiltersTemplateResponseFail(error.message))
  }
}

function* getGroupingTemplates(): Generator {
  try {
    const response = yield call(TicketsApi.getGroupingTemplates)

    yield put(getGroupingTemplatesResponse(response))
  } catch (error) {
    yield put(getGroupingTemplatesResponseFail(error.message))
  }
}

function* getSdGroupTemplatesRequest({ payload }: AnyAction): Generator {
  try {
    const response: any = yield call(TicketsApi.getSdGroupTemplates, payload)
    const templateViewUpdated = response.availableTemplates.map((temp: any) => ({
      ...temp,
      config: [],
    }))
    yield put(setGroupTemplates(templateViewUpdated))
    // let response: any = yield call(TicketsApi.getGroupingTemplates)
    // yield put(setGroupTemplates(response))
  } catch (e) {
    // call error
  }
}

function* saveSdGroupTemplate({ payload }: AnyAction): Generator {
  try {
    const response: any = yield call(TicketsApi.createGroupTemplate, payload.template)
    PopUpService.showGlobalPopUp(payload.translate(`translate#title.templateSaved`), 'success')
    yield put(
      setGroupTemplates(
        [
          {
            id:
              response.availableTemplates.find((temp: any) => temp.name === payload.template.name)
                .id || '',
            name: payload.template.name,
            config: payload.template.config,
          },
        ],
        true,
      ),
    )
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate(`translate#title.templateCreateError`)} ${error}`,
      'error',
    )
  }
}

function* editSdGroupTemplate({ payload }: AnyAction): Generator {
  try {
    const response: any = yield call(TicketsApi.editSdGroupTemplate, payload)
    if (response.availableTemplates) {
      PopUpService.showGlobalPopUp(payload.translate(`translate#title.templateEdited`), 'success')
      yield put(editGroupTemplateFromResponse(payload.template))
    }
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#title.templateEditError')} ${error}`,
      'error',
    )
  }
}

function* deleteSdGroupTemplate({ payload }: AnyAction): Generator {
  try {
    const response: any = yield call(TicketsApi.deleteSdGroupTemplate, payload)
    if (response === '') {
      yield put(deleteSdGroupTemplateResponseSuccess(payload.template))
      PopUpService.showGlobalPopUp(
        `${payload.translate('translate#title.templateRemoved')}`,
        'success',
      )
    }
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#title.templateDeletionError')} ${error}`,
      'error',
    )
  }
}

function* editGroupingTemplate({ payload: { template } }: AnyAction): Generator {
  try {
  } catch (error) {
    // yield put(editGroupingTemplateResponseFail(error.message))
  }
}

function* deleteGroupingTemplate({ payload: { template } }: AnyAction): Generator {
  try {
    /* const response = */
    // yield call(TicketsApi.deleteGroupingTemplate, template)
    // yield put(editGroupingTemplateResponse(template))
  } catch (error) {
    // yield put(editGroupingTemplateResponseFail(error.message))
  }
}

function* getTicketsConfig(): SagaIterator {
  try {
    const FORMUIDS = {
      [TYPE_OF_TICKETS.MY]: 'SRV_ClWEB_SearchRequests_Open_MY',
      [TYPE_OF_TICKETS.ALL]: 'SRV_ClWEB_SearchRequests_Open_ALL',
      [TYPE_OF_TICKETS.HISTORY]: 'SRV_ClWEB_SearchRequests',
    }

    const typeOfTickets: TypeOfTickets = yield select(state => state.tickets.typeOfTickets)

    const response = yield call(TicketsApi.getFormConfig, {
      formUID: FORMUIDS[typeOfTickets as string],
      getConfigURL: '/search-form/config',
    })

    yield put(getTicketsConfigReponse(typeOfTickets, response))
  } catch (error) {
    yield put(getTicketsConfigResponseFail(error.message))
  }
}

function* getTicketDetails({ payload: { id } }: AnyAction): Generator {
  try {
    const response = yield call(TicketsApi.getTicketDetails, id)

    yield put(getTicketDetailsResponse(response as TicketDetailsResponse))
  } catch (error) {
    yield put(getTicketDetailsResponseFail(error.message))
  }
}

export default function*(): Generator {
  yield takeLatest(TicketActions.GetTicketRequest, getTickets)
  yield takeLatest(TicketActions.GetGroupDetailsRequest, getGroupDetails)
  yield takeLatest(TicketActions.EditFiltersTemplateRequest, editFiltersTemplate)
  yield takeLatest(TicketActions.UpdateSdFilterTemplateRequest, updateSdFilterTemplate)
  yield takeLatest(TicketActions.DeleteFilterTemplateRequest, deleteFiltersTemplate)
  yield takeLatest(TicketActions.DeleteSdFilterTemplateRequest, deleteFilterTemplate)
  yield takeLatest(TicketActions.DeleteGroupingTemplateRequest, deleteGroupingTemplate)
  yield takeLatest(TicketActions.DeleteSdGroupTemplateRequest, deleteSdGroupTemplate)
  yield takeLatest(TicketActions.GetSdFilterTemplatesRequest, getSdFilterTemplates)
  yield takeLatest(TicketActions.GetFiltersTemplatesRequest, getFiltersTemplates)
  yield takeLatest(TicketActions.CreateSdFilterTemplateRequest, createFilterTemplate)
  yield takeLatest(TicketActions.SaveFiltersTemplateRequest, saveFiltersTemplate)
  yield takeLatest(TicketActions.GetGroupingTemplatesRequest, getGroupingTemplates)
  yield takeLatest(TicketActions.GetSdGroupTemplatesRequest, getSdGroupTemplatesRequest)
  yield takeLatest(TicketActions.SaveSdGroupingTemplateRequest, saveSdGroupTemplate)
  yield takeLatest(TicketActions.EditSdGroupTemplateRequest, editSdGroupTemplate)
  yield takeLatest(TicketActions.EditGroupingTemplateRequest, editGroupingTemplate)
  yield takeLatest(TicketActions.GetTicketsConfigRequest, getTicketsConfig)
  yield takeLatest(TicketActions.GetTicketDetailsRequest, getTicketDetails)
}
