import { AdmAppSetting } from '@/api/cm/swaggerGeneratedApi'
import { DynamicFieldType } from '@/constants/cm/dynamicFieldTypes'

import { TouchedProps } from '../types'

export const getInitialTouched = (fields: AdmAppSetting[]): TouchedProps => {
  const initialTouched: TouchedProps = {}

  fields.forEach((field: AdmAppSetting) => {
    const parsedFieldKey = field.id ? `${field.id}` : ''

    switch (field.valueType) {
      case DynamicFieldType.Integer:
        initialTouched[parsedFieldKey] = true
        break

      default:
        initialTouched[parsedFieldKey] = false
    }
  })

  return initialTouched
}
