export enum DashboardActions {
  GetChartDataRequest = '@/GET_CHART_DATA_REQUEST',
  GetChartDataResponse = '@/GET_CHART_DATA_RESPONSE',
  GetChartDataResponseFail = '@/GET_CHART_DATA_RESPONSE_FAIL',

  GetChartExtendedDataRequest = '@/GET_CHART_EXTENDED_DATA_REQUEST',
  GetChartExtendedDataResponse = '@/GET_CHART_EXTENDED_DATA_RESPONSE',
  GetChartExtendedDataResponseFail = '@/GET_CHART_EXTENDED_DATA_RESPONSE_FAIL',

  SetRefreshTimout = '@/SET_REFRESH_TIMEOUT',
  SetCurrentWidgetId = '@/SET_CURRENT_WIDGET_ID',
  DeleteCompletedTimer = '@/DELETE_COMPLETED_TIMER',

  GetAllDevicesRequest = '@/GET_ALL_DEVICES_REQUEST',
  GetAllDevicesResponse = '@/GET_ALL_DEVICES_RESPONSE',
  GetAllDevicesResponseFail = '@/GET_ALL_DEVICES_RESPONSE_FAIL',

  GetAllCompaniesRequest = '@/GET_ALL_COMPANIES_REQUEST',
  GetAllCompaniesResponse = '@/GET_ALL_COMPANIES_RESPONSE',
  GetAllCompaniesResponseFail = '@/GET_ALL_COMPANIES_RESPONSE_FAIL',

  SetCompany = '@/SET_DASHBOARD_COMPANY',
  SetStatus = '@/SET_DASHBOARD_STATUS',
  SetDevice = '@/SET_DASHBOARD_DEVICE',
  SetInitialDevice = '@/SET_DASHBOARD_INITIAL_DEVICE',
  SetRowPerPage = '@/SET_DASHBOARD_ROWS_PER_PAGE',
  SetPage = '@/SET_DASHBOARD_PAGE',
  SetSorting = '@/SET_DASHBOARD_SORTING',

  GetTicketsConfigRequest = '@/GET_DASHBOARD_TICKETS_CONFIG_REQUEST',
  GetTicketsConfigResponse = '@/GET_DASHBOARD_TICKETS_CONFIG_RESPONSE',
  GetTicketsConfigResponseFail = '@/GET_DASHBOARD_TICKETS_CONFIG_RESPONSE_FAIL',

  GetTicketsRequest = '@/GET_DASHBOARD_TICKETS_REQUEST',
  GetTicketsResponse = '@/GET_DASHBOARD_TICKETS_RESPONSE',
  GetTicketsResponseFail = '@/GET_DASHBOARD_TICKETS_RESPONSE_FAIL',
  SetSocketData = '@/SET_DASHBOARD_SOCKET_DATA',
  SetSocketItemEmpty = '@/SET_DASHBOARD_SOCKET_DATA_EMPTY',

  SetSocketDataError = '@/SET_DASHBOARD_SOCKET_DATA_ERROR',
  SetSocketMessageError = '@/SET_DASHBOARD_SOCKET_MESSAGE_ERROR',

  ClearSocketDataError = '@/CLEAR_DASHBOARD_SOCKET_DATA_ERROR',
  ClearSocketData = '@/CLEAR_DASHBOARD_SOCKET_DATA',
  ClearSocketDataByMessage = '@/CLEAR_DASHBOARD_SOCKET_DATA_BY_MESSAGE',
}
