import { AnyAction } from 'redux'
import { LogActions } from '@/constants/actions'

export interface State {
  isLogSet: boolean
  recordingStatus: 'none' | 'inProgress' | 'sentSuccessfully'
}

export const initialState: State = {
  isLogSet: false,
  recordingStatus: 'none',
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case LogActions.SetLog:
      return { ...state, isLogSet: payload }
    case LogActions.SetRecordingStatus:
      return { ...state, recordingStatus: payload }
    default:
      return state
  }
}
