import { Search } from '@material-ui/icons'
import { differenceWith } from 'lodash-es'
// import MaterialTable from '@/components/wrappers/MaterialTable'
import MaterialTable, { MaterialTableProps, MTableBody } from 'material-table'
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AppTable from '@/components/controls/AppTable/component'
import { TableBodyCell, TableFilter } from '@/components/controls/AppTable/types'
import MaterialTableCustomGroupRow from '@/components/helpers/MaterialTableCustomGroupRow/component'
import MaterialTablePatchedPagination from '@/components/helpers/MaterialTablePatchedPagination/component'
import { TableGroup } from '@/components/pages/sd/TicketsPage/components/GroupingModal/types'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { findUnitsByMerchantAndServiceRequest } from '@/store/sd/drawerActions/actions'
import { isLookingForUnits } from '@/store/sd/drawerActions/selectors/newTicket'
import { getTerminalSearchTable } from '@/store/sd/drawerActions/selectors/terminaleSearchTable'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import {
  Props,
  SearchTerminalByMerchantAndServiceFields,
  SearchTerminalByMerchantAndServiceTableRow,
  SearchTerminalsByMerchantAndServiceTableFilters,
} from './types'

const columnDisplayOrder = [
  SearchTerminalByMerchantAndServiceFields.AcquirerLuno,
  SearchTerminalByMerchantAndServiceFields.DeviceName,
  SearchTerminalByMerchantAndServiceFields.Address,
  SearchTerminalByMerchantAndServiceFields.City,
]

const TerminalSearchTable = ({
  field: { name },
  form: { setFieldValue },
  isSingleSelectingRow,
  merchantId,
  serviceTypeId,
  selectedTerminalIds,
  filtersIsVisible,
  modalHeight,
}: Props): React.ReactElement => {
  const [data, setData] = useState<SearchTerminalByMerchantAndServiceTableRow[]>([])
  const [displayedData, setDisplayedData] = useState<SearchTerminalByMerchantAndServiceTableRow[]>(
    [],
  )
  const [selectedRows, setSelectedRows] = useState<SearchTerminalByMerchantAndServiceTableRow[]>([])
  const [selectedFilters, setSelectedFilters] = useState<
    SearchTerminalsByMerchantAndServiceTableFilters
  >({
    [SearchTerminalByMerchantAndServiceFields.AcquirerLuno]: '',
    [SearchTerminalByMerchantAndServiceFields.Address]: '',
    [SearchTerminalByMerchantAndServiceFields.City]: '',
    [SearchTerminalByMerchantAndServiceFields.DeviceName]: '',
  })
  const [orderKey, setOrderKey] = useState<string>(
    SearchTerminalByMerchantAndServiceFields.AcquirerLuno,
  )
  const defaultSortColum = SearchTerminalByMerchantAndServiceFields.AcquirerLuno
  const tableRef = useRef(null)
  const [order, setOrder] = useState<'asc' | 'desc' | undefined>(undefined)
  const terminalSearch = useSelector(getTerminalSearchTable)
  const isLoading = useSelector(isLookingForUnits)
  const dispatch = useDispatch()
  const classes = useClasses({ filtersIsVisible, modalHeight })
  const translate = useTranslate()

  useEffect(() => {
    setData(
      differenceWith(
        terminalSearch,
        selectedTerminalIds as string[],
        (a: SearchTerminalByMerchantAndServiceTableRow, b: string) => a.acquirerLuno === b,
      ),
    )
  }, [terminalSearch, selectedTerminalIds])

  useEffect((): void => {
    dispatch(findUnitsByMerchantAndServiceRequest(merchantId, serviceTypeId, selectedFilters))
  }, [])

  useEffect((): void => {
    setFieldValue(name, selectedRows)
  }, [selectedRows, name, setFieldValue])

  useEffect(() => {
    setDisplayedData(data)
  }, [data])

  const handleRowClick = useCallback(
    (
      rows: SearchTerminalByMerchantAndServiceTableRow[],
      row: SearchTerminalByMerchantAndServiceTableRow | any,
    ) => {
      const selectedRowId = row[SearchTerminalByMerchantAndServiceFields.AcquirerLuno]

      if (isSingleSelectingRow) {
        const newData = displayedData.map((i: any) => {
          if (i[SearchTerminalByMerchantAndServiceFields.AcquirerLuno] === selectedRowId) {
            return {
              ...i,
              tableData: {
                ...i.tableData,
                checked: row.tableData.checked,
              },
            }
          }
          return {
            ...i,
            tableData: {
              ...i.tableData,
              checked: false,
            },
          }
        })

        if (row && row.tableData.checked) {
          setSelectedRows([row])
        } else {
          setSelectedRows([])
        }

        setDisplayedData(newData)
      } else {
        setSelectedRows(rows)
      }
    },
    [displayedData, setData],
  )

  const handleSelectAllRows = useCallback(
    (rows: any): void => {
      if (isSingleSelectingRow) {
        return
      }

      setSelectedRows(rows)
    },
    [displayedData, setData],
  )

  const [columns, setColumns] = useState<{ title: string; field: string }[]>([])

  useEffect((): void => {
    const cols = columnDisplayOrder.map(col => {
      if (col === defaultSortColum) {
        return {
          title: translate(`translate#title.${col}`),
          field: col,
          defaultSort: 'asc',
        }
      } else {
        return {
          title: translate(`translate#title.${col}`),
          field: col,
        }
      }
    })
    setColumns(cols)
  }, [])

  return (
    <div>
      <MaterialTable
        tableRef={tableRef}
        components={{
          Pagination: MaterialTablePatchedPagination,
          GroupRow: MaterialTableCustomGroupRow,
        }}
        columns={columns}
        isLoading={isLoading}
        data={displayedData}
        title=""
        icons={{
          Filter: forwardRef((props, ref) => (
            <Search {...props} ref={ref} style={{ opacity: '0.3' }} fontSize="small" />
          )),
        }}
        onSelectionChange={(data: any[], rowData?: any) => {
          if (rowData) {
            handleRowClick(data, rowData)
          } else {
            handleSelectAllRows(data)
          }
        }}
        options={{
          toolbar: false,
          search: true,
          showEmptyDataSourceMessage: false,
          showSelectAllCheckbox: !isSingleSelectingRow,
          exportButton: false,
          exportAllData: false,
          paging: true,
          grouping: false,
          filtering: true,
          sorting: true,
          selection: true,
          headerStyle: {
            whiteSpace: 'nowrap',
          },
          rowStyle: {
            whiteSpace: 'nowrap',
          },
        }}
      />
    </div>
  )
}

export default TerminalSearchTable
