export enum NewMerchantFormFields {
  LegalName = 'merchantfullname',
  CompanyCode = 'merchantidentificationnr',
  VATCode = 'merchantvatcode',
  Merchant = 'merchantId',
  LegalAddressCountry = 'merchantlegcountryid',
  LegalAddressCity = 'merchantlegcityid',
  LegalAddressNewCity = 'merchantlegcity',
  LegalAddressStreet = 'merchantlegstreet',
  LegalAddressPostal = 'merchantlegpostalcode',
  ActualAddressCountry = 'merchantcountryid',
  ActualAddressCity = 'merchantcityid',
  ActualAddressNewCity = 'merchantcity',
  ActualAddressStreet = 'merchantstreet',
  ActualAddressPostal = 'merchantpostalcode',
  ContactPerson = 'merchantcontactperson',
  PhoneNumber = 'merchantphone',
  Email = 'merchantemail',
  Remarks = 'notes',
  ServiceType = 'serviceType',
  SameAddress = 'sameaslegaladdress',
}

export interface NewMerchantFormValues {
  [NewMerchantFormFields.LegalName]: string
  [NewMerchantFormFields.CompanyCode]: string
  [NewMerchantFormFields.VATCode]: string
  [NewMerchantFormFields.Merchant]: string
  [NewMerchantFormFields.LegalAddressCountry]: string
  [NewMerchantFormFields.LegalAddressCity]: number | string
  [NewMerchantFormFields.LegalAddressNewCity]: string
  [NewMerchantFormFields.LegalAddressStreet]: string
  [NewMerchantFormFields.LegalAddressPostal]: string
  [NewMerchantFormFields.ActualAddressCountry]: string
  [NewMerchantFormFields.ActualAddressCity]: number | string
  [NewMerchantFormFields.ActualAddressNewCity]: string
  [NewMerchantFormFields.ActualAddressStreet]: string
  [NewMerchantFormFields.ActualAddressPostal]: string
  [NewMerchantFormFields.ContactPerson]: string
  [NewMerchantFormFields.PhoneNumber]: string
  [NewMerchantFormFields.Email]: string
  [NewMerchantFormFields.Remarks]: string
  [NewMerchantFormFields.ServiceType]: string
  [NewMerchantFormFields.SameAddress]: string
}
