import { CreateNewTicketFormValues } from '@/constants/forms/CreateNewTicketForm'
import { AnyAction } from 'redux'
import { FormikValues } from 'formik'
import { ResponseError, Unit, SavePosTicketRequest, TicketsConfigData } from '@/types'
import { DrawerActionsActions } from '@/constants/actions'
import { DeviceAdvancedRequest } from '@/constants/forms/AdvancedSearchForm'
import { ServiceCompany } from '@/api/sd/ticketsApi/types'
import {
  Device,
  GettingFilteredDevicesByMerchantIdAndServiceIdRequestData,
  Terminal,
} from '@/api/common/deviceApi/types'
import {
  CreatingPOSTicketFormConfig,
  EditingPOSTicketFormConfig,
  IexistingMerchants,
  POSServiceFormParameter,
  SaveTicketData,
  SaveTicketResponse,
  TicketInfoFormData,
} from '@/api/sd/posApi/types'
import { ModalsActions } from '@/constants/actions/modals'

export const openCreatePOSTicketsModal = (): AnyAction => ({
  type: ModalsActions.OpenCreatePOSTicketsModal,
})
export const closeCreatePOSTicketsModal = (): AnyAction => ({
  type: ModalsActions.CloseCreatePOSTicketsModal,
})
export const openDocumentInformationModal = (): AnyAction => ({
  type: ModalsActions.OpenDocumentInformationModal,
})
export const closeDocumentInformationModal = (): AnyAction => ({
  type: ModalsActions.CloseDocumentInformationModal,
})

export const setTicketId = (ticketId: number): AnyAction => ({
  type: DrawerActionsActions.SetTicketId,
  payload: ticketId,
})
export const setIsTicketHasDeletedSuccessful = (value: boolean): AnyAction => ({
  type: DrawerActionsActions.SetIsTicketHasDeletedSuccessful,
  paylaod: value,
})

export const addTicketRequest = (values: CreateNewTicketFormValues): AnyAction => ({
  type: DrawerActionsActions.AddTicketRequest,
  payload: { values },
})
export const addTicketResponse = (): AnyAction => ({
  type: DrawerActionsActions.AddTicketResponse,
})
export const addTicketResponseFail = (error: ResponseError): AnyAction => ({
  type: DrawerActionsActions.AddTicketResponseFail,
  payload: error,
})

export const findUnitsRequest = (str: string, byLuno: boolean): AnyAction => ({
  type: DrawerActionsActions.FindUnitsRequest,
  payload: { str, byLuno },
})
export const findUnitsResponse = (units: Partial<Unit>[]): AnyAction => ({
  type: DrawerActionsActions.FindUnitsResponse,
  payload: { units },
})
export const findUnitsResponseFail = (error: ResponseError): AnyAction => ({
  type: DrawerActionsActions.FindUnitsResponseFail,
  payload: error,
})

export const findUnitsAdvancedRequest = (values: Partial<DeviceAdvancedRequest>): AnyAction => ({
  type: DrawerActionsActions.FindUnitsAdvancedRequest,
  payload: { values },
})
export const findUnitsAdvancedOrTerminalSearchTableRequest = (rows: Device[]): AnyAction => ({
  type: DrawerActionsActions.FindUnitsAdvancedResponse,
  payload: rows,
})
export const findUnitsAdvancedOrTerminalSearchTableRequestFail = (
  error: ResponseError,
): AnyAction => ({
  type: DrawerActionsActions.FindUnitsAdvancedResponseFail,
  payload: error,
})

export const findUnitsByMerchantAndServiceRequest = (
  merchantId: number,
  serviceId: number,
  data: GettingFilteredDevicesByMerchantIdAndServiceIdRequestData,
): AnyAction => ({
  type: DrawerActionsActions.FindUnitsByMerchandAndServiceRequest,
  payload: { merchantId, serviceId, data },
})

export const findUnitsByMerchantAndServiceResponse = (rows: Terminal[]): AnyAction => ({
  type: DrawerActionsActions.FindUnitsByMerchandAndServiceResponse,
  payload: rows,
})
export const findUnitsByMerchantAndServicetFail = (error: ResponseError): AnyAction => ({
  type: DrawerActionsActions.FindUnitsByMerchandAndServiceResponseFail,
  payload: error,
})

export const getServiceCompanyRequest = (deviceId: number): AnyAction => ({
  type: DrawerActionsActions.GetServiceCompanyRequest,
  payload: { deviceId },
})
export const getServiceCompanyResponse = (serviceCompanies: ServiceCompany[]): AnyAction => ({
  type: DrawerActionsActions.GetServiceCompanyResponse,
  payload: serviceCompanies,
})
export const getServiceCompanyFail = (error: ResponseError): AnyAction => ({
  type: DrawerActionsActions.GetServiceCompanyeFail,
  payload: error,
})

export const getAdvancedUnitSearchConfigurationRequest = (): AnyAction => ({
  type: DrawerActionsActions.GetAdvancedUnitSearchConfigurationRequest,
})
export const getAdvancedUnitSearchConfigurationResponse = (
  filtersConfig: TicketsConfigData,
): AnyAction => ({
  type: DrawerActionsActions.GetAdvancedUnitSearchConfigurationResponce,
  payload: filtersConfig,
})
export const getAdvancedUnitSearchConfigurationFail = (error: ResponseError): AnyAction => ({
  type: DrawerActionsActions.GetAdvancedUnitSearchConfigurationFail,
  payload: error,
})

export const getCreatingPOSTicketFormConfigRequest = (): AnyAction => ({
  type: DrawerActionsActions.GetCreatingPOSFormConfigRequest,
})
export const getCreatingPOSTicketFormConfigResponse = (
  formConfig: CreatingPOSTicketFormConfig,
): AnyAction => ({
  type: DrawerActionsActions.GetCreatingPOSFormConfigResponse,
  payload: formConfig,
})
export const getCreatingPOSTicketFormConfigFail = (error: ResponseError): AnyAction => ({
  type: DrawerActionsActions.GetCreatingPOSFormConfigFail,
  payload: error,
})

export const deletePOSTicketRequestRequest = (ticketId: number): AnyAction => ({
  type: DrawerActionsActions.DeletePosTicketRequest,
  payload: { ticketId },
})
export const deletePOSTicketRequestResponse = (): AnyAction => ({
  type: DrawerActionsActions.DeletePosTicketResponse,
})
export const deletePOSTicketRequestFail = (error: ResponseError): AnyAction => ({
  type: DrawerActionsActions.DeletePosTicketFail,
  payload: error,
})

export const getCreatingPOSTicketEditFormConfigRequest = (ticketId: number): AnyAction => ({
  type: DrawerActionsActions.GetCreatingPOSEditFormConfigRequest,
  payload: ticketId,
})
export const getCreatingPOSTicketEditFormConfigResponse = (
  formConfig: EditingPOSTicketFormConfig,
): AnyAction => ({
  type: DrawerActionsActions.GetCreatingPOSEditFormConfigResponse,
  payload: formConfig,
})
export const getCreatingPOSTicketEditFormConfigFail = (error: ResponseError): AnyAction => ({
  type: DrawerActionsActions.GetCreatingPOSEditFormConfigFail,
  payload: error,
})

export const clearEditingPOSTicketFormConfig = (): AnyAction => ({
  type: DrawerActionsActions.ClearPOSEditFormConfig,
})

export const getPOSServiceFormConfigRequest = (
  serviceTypeId: string,
  issuerCompanyId: number,
): AnyAction => ({
  type: DrawerActionsActions.GetPOSServiceFormConfigRequest,
  payload: { serviceTypeId, issuerCompanyId },
})
export const getPOSServiceFormConfigResponse = (formConfig: any): AnyAction => ({
  type: DrawerActionsActions.GetPOSServiceFormConfigResponse,
  payload: formConfig,
})
export const getPOSServiceFormConfigFail = (error: ResponseError): AnyAction => ({
  type: DrawerActionsActions.GetPOSServiceFormConfigFail,
  payload: error,
})

export const getMerchantFormConfigRequest = (issuerCompanyId: number): AnyAction => ({
  type: DrawerActionsActions.GetMerchantConfigRequest,
  payload: { issuerCompanyId },
})
export const getMerchantFormConfigResponse = (formConfig: any): AnyAction => ({
  type: DrawerActionsActions.GetMerchantConfigResponse,
  payload: formConfig,
})
export const getMerchantFormConfigFail = (error: ResponseError): AnyAction => ({
  type: DrawerActionsActions.GetMerchantConfigFail,
  payload: error,
})

export const addPOSServiceTypeAction = (
  formValues: FormikValues,
  documentId: number,
  posServiceFormParameters: POSServiceFormParameter,
  withGettingActualTicketInfo: boolean,
): AnyAction => ({
  type: DrawerActionsActions.AddPOSServiceTypeActionRequest,
  payload: { formValues, documentId, posServiceFormParameters, withGettingActualTicketInfo },
})
export const addPOSServiceTypeActionResponse = (): AnyAction => ({
  type: DrawerActionsActions.AddPOSServiceTypeActionResponse,
})
export const addPOSServiceTypeActionFail = (error: ResponseError): AnyAction => ({
  type: DrawerActionsActions.AddPOSServiceTypeActionFail,
  payload: error,
})

export const editPOSServiceTypeAction = (
  requestItemId: number,
  documentId: number,
  serviceTypeConfig: FormikValues,
): AnyAction => ({
  type: DrawerActionsActions.EditPOSServiceTypeActionRequest,
  payload: { requestItemId, documentId, serviceTypeConfig },
})
export const editPOSServiceTypeActionResponse = (): AnyAction => ({
  type: DrawerActionsActions.EditPOSServiceTypeActionResponse,
})
export const editPOSServiceTypeActionFail = (error: ResponseError): AnyAction => ({
  type: DrawerActionsActions.EditPOSServiceTypeActionFail,
  payload: error,
})

export const setInitialUnitsAdvanced = () => ({
  type: DrawerActionsActions.setInitialAdvancedUnits,
})

export const setInitialServiceCompanies = () => ({
  type: DrawerActionsActions.setInitialServiceCompanies,
})

export const savePosTicketRequest = (
  formData: SavePosTicketRequest,
  withSendingTicket: boolean,
): AnyAction => ({
  type: DrawerActionsActions.SavePosTicketRequest,
  payload: { formData, withSendingTicket },
})
export const savePosTicketResponse = ({
  grantedOperations,
  saveTicketData,
}: SaveTicketResponse): AnyAction => ({
  type: DrawerActionsActions.SavePosTicketResponse,
  payload: { saveTicketData, grantedOperations },
})
export const setIsSavePOSTicketSuccessfull = (isSuccessfull: boolean): AnyAction => ({
  type: DrawerActionsActions.SetIsSavePOSTicketSuccessfull,
  payload: isSuccessfull,
})
export const savePosTicketFail = (error: ResponseError): AnyAction => ({
  type: DrawerActionsActions.SavePosTicketFail,
  payload: error,
})
export const savePosTicketInitial = (): AnyAction => ({
  type: DrawerActionsActions.SavePosTicketSetInitial,
})

export const getTicketInfoFormDataRequest = (ticketId: number): AnyAction => ({
  type: DrawerActionsActions.GetTicketInfoFormDataRequest,
  payload: { ticketId },
})
export const getTicketInfoFormDataResponse = (ticketInfoData: TicketInfoFormData): AnyAction => ({
  type: DrawerActionsActions.GetTicketInfoFormDataResponse,
  payload: ticketInfoData,
})
export const getTicketInfoFormDataFail = (error: ResponseError): AnyAction => ({
  type: DrawerActionsActions.GetTicketInfoFormDataFail,
  payload: error,
})

export const deletePosRequestItemRequest = (documentItemId: number): AnyAction => ({
  type: DrawerActionsActions.DeletePosRequestItemRequest,
  payload: documentItemId,
})
export const deletePosRequestItemResponse = (): AnyAction => ({
  type: DrawerActionsActions.DeletePosRequestItemResponse,
})
export const deletePosRequestItemFail = (error: ResponseError): AnyAction => ({
  type: DrawerActionsActions.DeletePosRequestItemFail,
  payload: error,
})

export const editPosTicketRequestRequest = (
  documentId: number,
  data: FormikValues,
  withSendingTicket: boolean,
): AnyAction => {
  return {
    type: DrawerActionsActions.EditPosTicketRequestRequest,
    payload: { documentId, data, withSendingTicket },
  }
}
export const editPosTicketRequestResponse = (saveTicketData: SaveTicketData): AnyAction => ({
  type: DrawerActionsActions.EditPosTicketRequestResponse,
  payload: saveTicketData,
})
export const editPosTicketRequestFail = (error: ResponseError): AnyAction => ({
  type: DrawerActionsActions.EditPosTicketRequestFail,
  payload: error,
})
export const getExistingMerchantsRequest = (fragment: string): AnyAction => ({
  type: DrawerActionsActions.GetExistingMerchantsRequest,
  payload: fragment,
  requestFunction: addPOSServiceTypeAction,
})
export const getExistingMerchantsResponse = (merchants: IexistingMerchants): AnyAction => ({
  type: DrawerActionsActions.GetExistingMerchantsResponse,
  payload: merchants,
})
export const getExistingMerchantsResponseFall = (error: ResponseError): AnyAction => ({
  type: DrawerActionsActions.GetExistingMerchantsResponseFall,
  payload: error,
})

export const setNotSavedPOSServiceRequestEditIndex = (payload: number): AnyAction => ({
  type: DrawerActionsActions.SetNotSavedPOSServiceRequestEditIndex,
  payload,
})

export const editNotSavedPOSServiceRequestByIndex = (payload: {
  index: number
  formValues: FormikValues
  formParameters: POSServiceFormParameter
}): AnyAction => ({
  type: DrawerActionsActions.EditNotSavedPOSServiceRequestByIndex,
  payload,
})

export const putNotSavedPOSServiceRequest = (
  formValues: FormikValues,
  formParameters: POSServiceFormParameter,
): AnyAction => ({
  type: DrawerActionsActions.PutNotSavedPOSServiceRequest,
  payload: { formValues, formParameters },
})

export const putNotSavedPOSServicesArray = (
  terminals: FormikValues[],
  formParameters: POSServiceFormParameter,
): AnyAction => ({
  type: DrawerActionsActions.PutNotSavedPosServicesArray,
  payload: { terminals, formParameters },
})

export const clearNotSavedPOSServiceRequest = (): AnyAction => ({
  type: DrawerActionsActions.ClearNotSavedPOSServiceRequest,
})

export const resetNotSavedPOSServicesFromUI = (
  payload: { formValues: FormikValues; formParameters: POSServiceFormParameter }[],
): AnyAction => ({
  type: DrawerActionsActions.ResetPOSServicesFromUI,
  payload,
})

export const sendPosTicketRequest = (documentId: number): AnyAction => {
  return {
    type: DrawerActionsActions.SendPosTicketRequestRequest,
    payload: { documentId },
  }
}
export const sendPosTicketResponse = (): AnyAction => ({
  type: DrawerActionsActions.SendPosTicketRequestResponse,
})
export const sendPosTicketFail = (error: ResponseError): AnyAction => ({
  type: DrawerActionsActions.SendPosTicketRequestFail,
  payload: error,
})

export const setIsTicketHasSentSuccessful = (isSent: boolean): AnyAction => ({
  type: DrawerActionsActions.SetIsTicketSentSuccessfull,
  payload: isSent,
})

export const setIsTicketHasSentError = (isError: boolean): AnyAction => ({
  type: DrawerActionsActions.SetIsTicketSentFailed,
  payload: isError,
})

export const setMCCCodeInitialRequestDataFragment = (payload: string): AnyAction => ({
  type: DrawerActionsActions.SetMCCCodePostTerminalRequest,
  payload,
})
