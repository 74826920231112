import { List, Popover } from '@material-ui/core'
import React, { FC, memo, useState } from 'react'

import { StyledListItem } from '@/components/blocks/cm/DocumentModal/styles'

import { LoadingButton } from '../LoadingButton'
import { useClasses } from './styles'
import { PopoverPosition, Props } from './types'

const popoverPosition: PopoverPosition = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
}

export const DropDownButton: FC<Props> = memo((props: Props) => {
  const { popoverList, isLoading = false, name, ...rest } = props

  const classes = useClasses()

  const [anchorElement, setAnchorEl] = useState<null | HTMLElement>(null)

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const closePopover = (): void => {
    setAnchorEl(null)
  }

  return (
    <>
      <LoadingButton
        className={classes.button}
        isLoading={isLoading}
        name={name || 'Button'}
        onClick={openPopover}
        {...rest}
      />

      <Popover
        open={!!anchorElement}
        anchorEl={anchorElement}
        onClose={closePopover}
        {...popoverPosition}
      >
        <List>
          {popoverList.map(({ title, disabled, onClick }, index) => {
            const handleClick = (): void => {
              onClick()
              closePopover()
            }

            return (
              <StyledListItem
                key={`${title}-${index}`}
                button
                disabled={disabled}
                onClick={handleClick}
              >
                {title}
              </StyledListItem>
            )
          })}
        </List>
      </Popover>
    </>
  )
})
