import { QualificationAndTimeNorm } from './qualificationAndNorm'

export enum RepairNormsField {
  Company = 'company',
  DeviceType = 'devicetype',
  SpecifyProblemArea = 'specifyProblemArea',
  SelectDefectOrWork = 'selectDefectOrWork',
  Reason = 'reason',
  Date = 'date',
  TableItems = 'tableItems',
}

export interface QualificationLevelAndTimeNorm {
  qualificationLevel: string
  norm: string
  fromDate: Date
  toDate: Date
}

export interface RepairNormsFormValues {
  [RepairNormsField.Company]: string
  [RepairNormsField.DeviceType]: string
  [RepairNormsField.SpecifyProblemArea]: string
  [RepairNormsField.SelectDefectOrWork]: string
  [RepairNormsField.Reason]: string
  [RepairNormsField.Date]: string[]
  [RepairNormsField.TableItems]: QualificationAndTimeNorm[]
}
