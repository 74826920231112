import { Checkbox } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import TreeItem from '@material-ui/lab/TreeItem'
import React, { useEffect, useState } from 'react'

import { GroupNode } from '../../types'
import { useClasses } from './styles'
import { checkIsAnyChildSelected, selectAllChild, updateParentSelectState } from './utils'

type Props = {
  children: JSX.Element[]
  labelText: string
  labelInfo?: string
  node: GroupNode
  selected: GroupNode[]
  setSelected: React.Dispatch<React.SetStateAction<GroupNode[]>>
}

const TreeElement: React.FC<Props> = React.memo(
  ({ labelText, labelInfo, node, setSelected, selected, children }: Props): React.ReactElement => {
    const classes = useClasses()

    const [indeterminate, setIndeterminate] = useState(false)

    const currentNodeId = node.nodeId
    const isSelected = !!selected.find(({ nodeId }) => nodeId === currentNodeId)

    useEffect(() => {
      if (node.childNodes.length) {
        const isAnyChildSelected = checkIsAnyChildSelected(node, selected)
        const isAllDirectChildsSelected =
          isAnyChildSelected &&
          node.childNodes.every(({ nodeId }) => {
            return !!selected.find(group => {
              return group.nodeId === nodeId
            })
          })

        if (!isAllDirectChildsSelected && isAnyChildSelected) {
          setIndeterminate(true)
        } else {
          setIndeterminate(false)
        }

        if (isAllDirectChildsSelected && !isSelected) {
          setSelected(prevSelected => [...prevSelected, node])
        }
      }
    }, [selected])

    return (
      <TreeItem
        label={
          <div className={classes.labelRoot}>
            <div>
              <Checkbox
                checked={isSelected}
                onClick={(e): void => {
                  e.stopPropagation()
                }}
                style={{ padding: '0px' }}
                indeterminate={indeterminate}
                onChange={(event: any): void => {
                  setSelected(prevState => {
                    const { checked } = event.currentTarget
                    const allChildNodes = selectAllChild(node)

                    const updatedSelected = checked
                      ? [...prevState, ...allChildNodes]
                      : prevState.filter(({ nodeId }) => {
                          return !allChildNodes.find(childNode => childNode.nodeId === nodeId)
                        })

                    const updatedSelectedWithParents = node.parentNode
                      ? updateParentSelectState(node.parentNode, updatedSelected)
                      : updatedSelected

                    return updatedSelectedWithParents
                  })
                }}
              />
            </div>
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          </div>
        }
        classes={{
          root: classes.root,
          content: classes.content,
          group: classes.group,
          label: classes.label,
        }}
        children={children}
        nodeId={currentNodeId}
      />
    )
  },
)

export { TreeElement }
