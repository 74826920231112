const setTranslateName = (data, translate) => {
  return data.map(el => ({
    name: translate(`translate#title.${el.name}`),
    value: el.value,
  }))
}

export const getPeriodWeeksData = translate => {
  const periodWeekData = [
    { name: 'monday', value: '1' },
    { name: 'tuesday', value: '2' },
    { name: 'wednesday', value: '3' },
    { name: 'thursday', value: '4' },
    { name: 'friday', value: '5' },
    { name: 'saturday', value: '6' },
    { name: 'sunday', value: '7' },
  ]
  return setTranslateName(periodWeekData, translate)
}

export const getPeriodMonthDate = translate => {
  const periodMonthDate = []
  for (let i = 1; i <= 31; i++) {
    periodMonthDate.push({
      name: String(`${i} ${translate(`translate#title.day`)}`),
      value: String(i),
    })
  }

  return periodMonthDate
}

export const getReportStatus = translate => {
  const statusOfReports = [
    { name: 'All', value: 'All' },
    { name: 'Active', value: 'Active' },
    { name: 'Inactive', value: 'Inactive' },
  ]
  return setTranslateName(statusOfReports, translate)
}
