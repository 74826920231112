import clsx from 'clsx'
import React from 'react'

import { useClasses } from './styles'
import { Props } from './types'

const Loader = ({ className, inline = false }: Props): React.ReactElement => {
  const classes = useClasses()
  return <div className={clsx(!inline ? classes.loader : classes.inlineLoader, className)} />
}

export default Loader
