import makeStyles from '@material-ui/core/styles/makeStyles'
import { styledComponentTheme } from '@/theme/styledComponentTheme'

export const useClasses = makeStyles(({ palette, spacing }) => ({
  sortButton: {
    padding: 0,
    margin: spacing(0.25, 0),
    minWidth: 0,
    color: '#cad5d7',
    fontSize: '1rem',
  },
  smallButton: {
    '& svg': {
      fontSize: '1rem',
    },
  },
  sortButtonActive: {
    color: 'white',
    backgroundColor: styledComponentTheme.colors.baseGreen,
    '&:hover': {
      backgroundColor: styledComponentTheme.colors.baseGreen,
    },
    '&:active': {
      backgroundColor: styledComponentTheme.colors.baseGreen,
    },
  },
  disabled: {
    opacity: 0.6,
    '& $sortButton': {
      backgroundColor: '#f9f9f9',
    },
  },
  box: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    margin: '0 8px',
  },
  mr: {
    marginRight: spacing(1),
  },
}))
