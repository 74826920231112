import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  cell: {
    height: spacing(5),
    minHeight: spacing(5),
    maxHeight: spacing(5),
    padding: 0,
    borderBottom: 0,
    whiteSpace: 'nowrap',
  },

  clickableCell: {
    cursor: 'pointer',
  },

  cameraCell: {
    display: 'flex',
    alignItems: 'center',
    color: '#25ADBF',
  },

  cameraIcon: {
    marginRight: '5px',
  },

  mediaStoreTypeColor: {
    color: '#929A9B',
  },
}))
