import { Box } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { Field, FieldProps, Form, FormikProps } from 'formik'
import _ from 'lodash'
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { isIE } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Test from 'react-test-attributes'

import Button from '@/components/controls/Button/index'
import { createCheckboxField } from '@/components/controls/Checkbox/component'
import { createDropDownInputField } from '@/components/controls/DropDownInput/component'
import { Option } from '@/components/controls/DropDownInput/types'
import { createSelectField } from '@/components/controls/Select/component'
import { createTextInputField } from '@/components/controls/TextInput/component'
import { disabledSpecialChars, PERMISSIONS } from '@/constants'
import {
  CreateNewTicketFormFields,
  CreateNewTicketFormValues,
} from '@/constants/forms/CreateNewTicketForm'
import { SRM_BLOCK_PREFIX } from '@/constants/paths'
import { checkPermission, getUserPermissions } from '@/helpers/methods'
import { getCurrentCompany, getCurrentUserName } from '@/store/auth/selectors'
import {
  findUnitsRequest,
  getUnits,
  isLookingForUnits,
  setInitialServiceCompanies,
} from '@/store/sd/drawerActions'
import { getServiceCompanyRequest } from '@/store/sd/drawerActions/actions'
import { getServiceCompanies } from '@/store/sd/drawerActions/selectors'
import { getTestId } from '@/utils/getTestId'
import { useSearch } from '@/utils/hooks/hooks'
import { useTranslate } from '@/utils/internalization'

import { renderFiles } from '../../utils/renderFiles'
import { RequestRadioButtons } from '../RequestRadioButtons'
import { UnitsRadioButtons } from '../UnitsRadioButtons'
import { useClasses } from './styles'
import { Props } from './types'

const CreateNewTicketForm = ({
  setFieldValue,
  values,
  isValid,
  setTouched,
  handleSearchOpen,
  handleClose,
}: FormikProps<CreateNewTicketFormValues> & Props): React.ReactElement => {
  const [searchByLUNO, setSearchByLUNO] = useState('1')
  const [requestType, setRequestType] = useState('1')
  const props = { display: isIE ? 'block' : 'flex' }
  const classes = useClasses(props)
  const testId = getTestId('sd-tickets-create-new-ticket-form')
  const serviceCompanies = useSelector(getServiceCompanies)
  const isLoadingUnits = useSelector(isLookingForUnits)
  const userName = useSelector(getCurrentUserName)
  const userCompanyName = useSelector(getCurrentCompany)
  const units = useSelector(getUnits)
  const dispatch = useDispatch()
  const translate = useTranslate()
  const location = useLocation()

  const unitId = values[CreateNewTicketFormFields.UnitId]

  useEffect(() => {
    if (unitId.length) {
      dispatch(getServiceCompanyRequest(+unitId))
    }
    if (!unitId.length) {
      setFieldValue(CreateNewTicketFormFields.ServiceCompanyId, '')
    }
  }, [unitId, setFieldValue])

  useEffect(() => {
    if (!values.unit) {
      setTouched({})
      dispatch(setInitialServiceCompanies())
    }
  }, [values.unit])

  const ServiceCompanySelect = useMemo(
    () =>
      createSelectField({
        required: !!values.unit,
        label: translate('translate#title.executantCompany'),
        placeholder: translate('translate#title.selectCompany'),
        disabled: !values.unit,
        shrink: true,
        options: serviceCompanies,
      }),
    [serviceCompanies, values.unit],
  )

  const [handleChange] = useSearch(findUnitsRequest, searchByLUNO)

  const renderUnitDropDownOption = useCallback(
    (option: Option): React.ReactElement => (
      <div className={classes.unitOption}>
        <p className={classes.unitOptionName}>{option.name}</p>
        <div className={classes.unitAddInfo}>
          {option.address && (
            <span>
              <FormattedMessage id="title.address" defaultMessage="Address" />: {option.address}
            </span>
          )}
        </div>
      </div>
    ),
    [],
  )

  const UnitDropDown = useMemo(() => {
    return createDropDownInputField({
      label: translate('translate#title.unit'),
      placeholder: translate('translate#title.enterUnit'),
      isLoading: isLoadingUnits,
      onTextChange: handleChange,
      required: true,
      options: units,
      neverDisable: true,
      renderOption: renderUnitDropDownOption,
      filtering: false,
    })
  }, [values, unitId, units, handleChange, isLoadingUnits])

  const CustomerInput = createTextInputField({
    disabled: true,
    label: translate('translate#title.customer'),
    fullWidth: true,
    value: userCompanyName,
    InputLabelProps: {
      shrink: true,
    },
  })

  const InformationSenderInput = createDropDownInputField({
    label: translate('translate#title.informationSender'),
    required: true,
    shrink: true,
    options: [],
  })

  const ContactPersonInput = createTextInputField({
    disabled: true,
    label: translate('translate#title.ContactPerson'),
    value: userName,
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
  })

  const RequestNumberInput = createTextInputField({
    placeholder: translate('translate#title.editCustomerReqNum'),
    label: translate('translate#title.customerRequestNumber'),
    fullWidth: true,
    disabledKeys: [...disabledSpecialChars, ',', '"', "'"],
    InputLabelProps: {
      shrink: true,
    },
    withoutFirstSpace: true,
  })

  const NotesInput = createTextInputField({
    multiline: true,
    rows: 4,
    fullWidth: true,
    label: translate('translate#title.Notes'),
    placeholder: translate('translate#title.EditNotes'),
    InputLabelProps: {
      shrink: true,
    },
  })

  const isClaim = checkPermission(PERMISSIONS.createClameTicket)

  const ClaimInput = createCheckboxField({
    label: 'Claim',
    checked: false,
  })

  const handleFileInputChange = useCallback(
    (
      onChange: (name: string, value: File[]) => void,
      prevValue: File[],
    ): ((e: SyntheticEvent<HTMLInputElement>) => void) => (e): void => {
      const isDuplicate = prevValue.some(file => {
        if (e.currentTarget.files) {
          return Array.from(e.currentTarget.files).some(item => item.name === file.name)
        }
      })
      if (!isDuplicate) {
        onChange(e.currentTarget.name, [...prevValue, ...Array.from(e.currentTarget.files || [])])
        e.currentTarget.value = ''
      }
    },
    [],
  )

  const handleRemoveFile = useCallback(
    (
      setFieldValue: (name: string, value: File[]) => void,
      files: File[],
    ): ((index: number) => void) => (index): void => {
      setFieldValue(
        CreateNewTicketFormFields.Files,
        files.filter((val, i) => i !== index),
      )
    },
    [setFieldValue],
  )

  const handleUnitTypeChange = useCallback(
    (value: string): void => {
      setFieldValue(CreateNewTicketFormFields.UnitId, '')
      setSearchByLUNO(value)
    },
    [setFieldValue],
  )

  const handleRequstTypeChange = useCallback(
    (value: string): void => {
      setRequestType(value)
    },
    [requestType],
  )

  const GenericInputTypeUpload = useCallback(
    (classStyle: string, id?: string): React.ReactElement => {
      return (
        <Field name={CreateNewTicketFormFields.Files}>
          {({ field: { value, ...field } }: FieldProps): React.ReactElement => (
            <input
              title=""
              id={id}
              className={classStyle}
              accept=".doc,.docx,.pdf,.xlsx,.xls"
              type="file"
              {...field}
              onChange={handleFileInputChange(setFieldValue, value)}
            />
          )}
        </Field>
      )
    },
    [isIE, setFieldValue],
  )

  return (
    <Test id={testId(0)}>
      <Container className={classes.container}>
        <Test id={testId(1)}>
          <Typography variant="h5">
            <FormattedMessage id="title.createNewTicket" defaultMessage=" Create New Ticket" />
          </Typography>
        </Test>
        <Form className={classes.form}>
          <Grid className={classes.fields} container>
            {location.pathname.includes(SRM_BLOCK_PREFIX) && (
              <Test id={testId(2)}>
                <Grid item md={12}>
                  <RequestRadioButtons value={requestType} onChange={handleRequstTypeChange} />
                </Grid>
              </Test>
            )}
            {location.pathname.includes(SRM_BLOCK_PREFIX) ? (
              <Test id={testId(3)}>
                <Grid item md={12}>
                  <Field name={CreateNewTicketFormFields.Customer}>{InformationSenderInput}</Field>
                </Grid>
              </Test>
            ) : (
              <Test id={testId(3)}>
                <Grid item md={12}>
                  <Field name={CreateNewTicketFormFields.Customer}>{CustomerInput}</Field>
                </Grid>
              </Test>
            )}
            <Test id={testId(4)}>
              <Grid item md={12}>
                <Test id={testId(1)}>
                  <Field name={CreateNewTicketFormFields.ContactPerson}>{ContactPersonInput}</Field>
                </Test>
              </Grid>
            </Test>
            <Test id={testId(15)}>
              <Grid item md={12}>
                <Field name={CreateNewTicketFormFields.RequestNumber}>{RequestNumberInput}</Field>
              </Grid>
            </Test>
            <Test id={testId(5)}>
              <Grid item md={12}>
                <UnitsRadioButtons
                  value={searchByLUNO}
                  onChange={handleUnitTypeChange}
                  handleSearchOpen={handleSearchOpen}
                />
              </Grid>
            </Test>
            <Test id={testId(6)}>
              <Grid item md={12}>
                <Field name={CreateNewTicketFormFields.UnitId}>{UnitDropDown}</Field>
              </Grid>
            </Test>
            <Test id={testId(7)}>
              <Grid item md={12}>
                <Field name={CreateNewTicketFormFields.ServiceCompanyId}>
                  {ServiceCompanySelect}
                </Field>
              </Grid>
            </Test>
            <Test id={testId(8)}>
              <Grid item md={12}>
                {isClaim && <Field name={CreateNewTicketFormFields.Claim}>{ClaimInput}</Field>}
              </Grid>
            </Test>
            <Test id={testId(11)}>
              <Grid item md={12}>
                <Field name={CreateNewTicketFormFields.Notes}>{NotesInput}</Field>
              </Grid>
            </Test>
            <Test id={testId(9)}>
              <Grid item md={12}>
                <Typography variant="body1" className={classes.attachFileText}>
                  {translate('translate#action.attachDragAndDrop')}
                </Typography>
              </Grid>
            </Test>
            <Test id={testId(10)}>
              <Grid item md={12}>
                {isIE ? (
                  <Box>
                    <label htmlFor="file-upload" className={classes.customFileUploadForIE}>
                      <FormattedMessage id="title.ChooseFile" defaultMessage="Choose File" />
                    </label>
                    {GenericInputTypeUpload(classes.inputFileForIE, 'file-upload')}
                  </Box>
                ) : (
                  <Box>
                    <Button className={classes.addFileButton} variant="contained">
                      <FormattedMessage id="title.ChooseFile" defaultMessage="Choose File" />
                      {GenericInputTypeUpload(classes.fileInput)}
                    </Button>
                  </Box>
                )}
              </Grid>
            </Test>
            <Grid item md={12}>
              {renderFiles(
                values[CreateNewTicketFormFields.Files],
                classes,
                handleRemoveFile(setFieldValue, values[CreateNewTicketFormFields.Files]),
              )}
            </Grid>
          </Grid>
          <Grid className={classes.buttons} container>
            <Grid item md={12}>
              <Box alignItems="flex-end" justifyContent="flex-end" display="flex">
                <Test id={testId(11)}>
                  <Button
                    width="md"
                    variant="contained"
                    onClick={handleClose}
                    className={clsx(classes.button, classes.cancelButton)}
                  >
                    <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
                  </Button>
                </Test>
                <Test id={testId(12)}>
                  <Button variant="contained" type="submit" width="md" disabled={!isValid}>
                    <FormattedMessage id="title.create" defaultMessage="Create" />
                  </Button>
                </Test>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </Container>
    </Test>
  )
}

export default CreateNewTicketForm
