import React from 'react'
import { store } from '@/App'

export const DictionaryRoutes = React.lazy(async () => {
  const {
    default: { reducer: dictionaryDictionariesList, saga: dictionarySagaDictionariesList },
  } = await import('@/store/dictionaries/dictionariesList')
  store.injectReducer('dictionaryDictionariesList', dictionaryDictionariesList)
  store.injectSaga('dictionarySagaDictionariesList', dictionarySagaDictionariesList)

  const {
    default: { reducer: dictionaryLanguages, saga: dictionarySagaLanguages },
  } = await import('@/store/dictionaries/languages')
  store.injectReducer('dictionaryLanguages', dictionaryLanguages)
  store.injectSaga('dictionarySagaLanguages', dictionarySagaLanguages)

  const {
    default: { reducer: dictionaryDictionaryInfo, saga: dictionarySagaDictionaryInfo },
  } = await import('@/store/dictionaries/dictionary')
  store.injectReducer('dictionaryDictionaryInfo', dictionaryDictionaryInfo)
  store.injectSaga('dictionarySagaDictionaryInfo', dictionarySagaDictionaryInfo)

  const {
    default: { reducer: dictionaryUnsavedChanges },
  } = await import('@/store/dictionaries/unsavedChanges')
  store.injectReducer('dictionaryUnsavedChanges', dictionaryUnsavedChanges)

  return import('@/components/routers/dictionary')
})
