export enum LocalStorageItems {
  IsChangePassword = '@LocalStorage/IsChangePassword',

  AccessToken = '@LocalStorage/AccessToken',
  UserID = '@LocalStorage/UserID',
  CurrentAccount = '@LocalStorage/CurrentAccount',
  ActiveTabs = '@LocalStorage/ActiveTabs',
  Bookmarks = '@LocalStorage/Bookmarks',
  Timezone = '@LocalStorage/Timezone',
  TicketColumns = '@LocalStorage/TicketColumns',
  GroupingTicketColumns = '@LocalStorage/GroupingTicketColumns',
  IsVault = '@LocalStorage/IsVault',
  AllLocales = '@LocalStorage/AllLocales',
  AccessTokenCM = '@LocalStorage/AccessTokenCM',
  UserPermissionsCM = '@LocalStorage/UserPermissionsCM',
  UserModulesCM = '@LocalStorage/UserModulesCM',
  UserDataCM = '@LocalStorage/UserDataCM',
  ActiveTeamsCM = '@LocalStorage/ActiveTeamsCM',
  UsersSettingsCM = '@LocalStorage/UsersSettingsCM',
  IsOpenMenuCM = '@LocalStorage/IsOpenMenuCM',

  // Atmeye
  AtmeyeLsVersion = '@LocalStorage/AtmeyeLsVersion',

  TrxHistoryLogFilterTemplate = '@LocalStorage/AtmeyeTrxHistoryLogFilterTemplateFilterTemplate',
  RfmSynchronizationFilterTemplate = '@LocalStorage/AtmeyeRfmSynchronizationFilterTemplate',

  BackupsPagination = '@LocalStorage/AtmeyeBackupsPagination',
  BackupsFilters = '@LocalStorage/AtmeyeBackupsFilters',
  BackupsColumns = '@LocalStorage/AtmeyeBackupsColumns',
  BackupsFilterTemplate = '@LocalStorage/AtmeyeBackupsFilterTemplate',
  BackupsSort = '@LocalStorage/BackupsSort',

  BackupsFromServerColumns = '@LocalStorage/AtmeyeBackupsFromServerColumns',

  BlacklistPagination = '@LocalStorage/AtmeyeBlacklistPagination',
  BlacklistFilters = '@LocalStorage/AtmeyeBlacklistFilters',
  BlacklistColumns = '@LocalStorage/AtmeyeBlacklistColumns',
  BlackListGrouping = '@LocalStorage/AtmeyeBlackListGrouping',
  BlackListFilterTemplate = '@LocalStorage/AtmeyeBlackListFilterTemplate',
  BlackListSort = '@LocalStorage/BlackListSort',

  OperationLogPagination = '@LocalStorage/AtmeyeOperationLogPagination',
  OperationLogColumns = '@LocalStorage/AtmeyeOperationLogColumns',
  OperationLogSort = '@LocalStorage/OperationLogSort',
  OperationLogFilterTemplate = '@LocalStorage/AtmeyeOperationLogFilterTemplate',
  OperationLogFilters = '@LocalStorage/AtmeyeStub',

  DevicesGrouping = '@LocalStorage/AtmeyeDevicesGrouping',
  DevicesFilterTemplate = '@LocalStorage/AtmeyeDevicesFilterTemplate',
  DevicesPagination = '@LocalStorage/AtmeyeDevicesPagination',
  DevicesColumns = '@LocalStorage/AtmeyeDevicesColumns',
  DevicesFilters = '@LocalStorage/DevicesFilters',
  DevicesSort = '@LocalStorage/DevicesSort',

  DeviceSettingsGrouping = '@LocalStorage/AtmeyeDeviceSettingsGrouping',
  DeviceSettingsFilterTemplate = '@LocalStorage/AtmeyeDeviceSettingsFilterTemplate',
  DeviceSettingsPagination = '@LocalStorage/AtmeyeDeviceSettingsPagination',
  DeviceSettingsColumns = '@LocalStorage/AtmeyeDeviceSettingsColumns',
  DeviceSettingsFilters = '@LocalStorage/DeviceSettingsFilters',
  DeviceSettingsSort = '@LocalStorage/DeviceSettingsSort',

  DeviceModalGrouping = '@LocalStorage/AtmeyeDeviceModalGrouping',
  DeviceModalFilterTemplate = '@LocalStorage/AtmeyeDeviceModalFilterTemplate',
  DeviceModalPagination = '@LocalStorage/AtmeyeDeviceModalPagination',
  DeviceModalColumns = '@LocalStorage/AtmeyeDeviceModalColumns',
  DeviceModalFilters = '@LocalStorage/DeviceModalFilters',
  DeviceModalSort = '@LocalStorage/DeviceModalSort',

  AlarmsByDeviceGrouping = '@LocalStorage/AtmeyeAlarmsByDeviceGrouping',
  AlarmsByDeviceFilterTemplate = '@LocalStorage/AtmeyeAlarmsByDeviceFilterTemplate',
  AlarmsByDevicePagination = '@LocalStorage/AtmeyeAlarmsByDevicePagination',
  AlarmsByDeviceColumns = '@LocalStorage/AtmeyeAlarmsByDeviceColumns',
  AlarmsByDeviceFilters = '@LocalStorage/AlarmsByDeviceFilters',
  AlarmsByDeviceSort = '@LocalStorage/AlarmsByDeviceSort',

  AlarmsGrouping = '@LocalStorage/AtmeyeAlarmsGrouping',
  AlarmsFilterTemplate = '@LocalStorage/AtmeyeAlarmsFilterTemplate',
  AlarmsPagination = '@LocalStorage/AtmeyeAlarmsPagination',
  AlarmsColumns = '@LocalStorage/AtmeyeAlarmsColumns',
  AlarmsFilters = '@LocalStorage/AlarmsFilters',
  AlarmsSort = '@LocalStorage/AlarmsSort',

  MultipleSnapshotsGrouping = '@LocalStorage/AtmeyeMultipleSnapshotsGrouping',
  MultipleSnapshotsFilterTemplate = '@LocalStorage/AtmeyeMultipleSnapshotsFilterTemplate',
  MultipleSnapshotsPagination = '@LocalStorage/AtmeyeMultipleSnapshotsPagination',
  MultipleSnapshotsColumns = '@LocalStorage/AtmeyeMultipleSnapshotsColumns',
  MultipleSnapshotsFilters = '@LocalStorage/MultipleSnapshotsFilters',
  MultipleSnapshotsSort = '@LocalStorage/MultipleSnapshotsSort',

  TechEventsByDeviceGrouping = '@LocalStorage/AtmeyeTechEventsByDeviceGrouping',
  TechEventsByDeviceFilterTemplate = '@LocalStorage/AtmeyeTechEventsByDeviceFilterTemplate',
  TechEventsByDevicePagination = '@LocalStorage/AtmeyeTechEventsByDevicePagination',
  TechEventsByDeviceColumns = '@LocalStorage/AtmeyeTechEventsByDeviceColumns',
  TechEventsByDeviceFilters = '@LocalStorage/TechEventsByDeviceFilters',
  TechEventsByDeviceSort = '@LocalStorage/TechEventsByDeviceSort',

  TechEventsGrouping = '@LocalStorage/AtmeyeTechEventsGrouping',
  TechEventsFilterTemplate = '@LocalStorage/AtmeyeTechEventsFilterTemplate',
  TechEventsPagination = '@LocalStorage/AtmeyeTechEventsPagination',
  TechEventsColumns = '@LocalStorage/AtmeyeTechEventsColumns',
  TechEventsFilters = '@LocalStorage/TechEventsFilters',
  TechEventsSort = '@LocalStorage/TechEventsSort',

  TransactionsGrouping = '@LocalStorage/AtmeyeTransactionsGrouping',
  TransactionsFilterTemplate = '@LocalStorage/AtmeyeTransactionsFilterTemplate',
  TransactionsPagination = '@LocalStorage/AtmeyeTransactionsPagination',
  TransactionsColumns = '@LocalStorage/AtmeyeTransactionsColumns',
  TransactionsFilters = '@LocalStorage/TransactionsFilters',
  TransactionsSort = '@LocalStorage/TransactionsSort',

  TransactionsByDeviceGrouping = '@LocalStorage/AtmeyeTransactionsByDeviceGrouping',
  TransactionsByDeviceFilterTemplate = '@LocalStorage/AtmeyeTransactionsByDeviceFilterTemplate',
  TransactionsByDevicePagination = '@LocalStorage/AtmeyeTransactionsByDevicePagination',
  TransactionsByDeviceColumns = '@LocalStorage/AtmeyeTransactionsByDeviceColumns',
  TransactionsByDeviceFilters = '@LocalStorage/TransactionsByDeviceFilters',
  TransactionsByDeviceSort = '@LocalStorage/TransactionsByDeviceSort',

  HMVersionsFilters = '@LocalStorage/HMVersionsFilters',
  HMVersionsColumns = '@LocalStorage/HMVersionsCOLUMNS',
  HMVersionsPagination = '@LocalStorage/HMVersionsPagination',

  HMServicesFilters = '@LocalStorage/HMServicesFilters',
  HMServicesColumns = '@LocalStorage/HMServicesCOLUMNS',
  HMServicesPagination = '@LocalStorage/HMServicesPagination',
  HMServicesSort = '@LocalStorage/HMServicesSort',

  ErrorsLogFilters = '@LocalStorage/ErrorsLogFilters',
  ErrorsLogColumns = '@LocalStorage/ErrorsLog_COLUMNS',
  ErrorsLogPagination = '@LocalStorage/ErrorsLogPagination',
  ErrorsLogSort = '@LocalStorage/ErrorsLogSort',

  LicenceListFilterTemplate = '@LocalStorage/AtmeyeLicenceListFilterTemplate',
  LicenceListFilters = '@LocalStorage/LicenceListFilters',
  LicenceListColumns = '@LocalStorage/LicenceList_COLUMNS',
  LicenceListSort = '@LocalStorage/LicenceListSort',

  LicenceListServerColumns = '@LocalStorage/LicenceListServer_Columns',

  LogInspectorColumns = '@LocalStorage/LogInspectorColumns',
  LogInspectorFilters = '@LocalStorage/LogInspectorFilters',

  ExternalNotificationsColumns = '@LocalStorage/ExternalNotifications/Columns',
  ExternalNotificationsPagination = '@LocalStorage/AtmeyeExternalNotificationsPagination',
  ExternalNotificationsFilters = '@LocalStorage/AtmeyeExternalNotificationsFilters',
  ExternalNotificationsFilterTemplate = '@LocalStorage/AtmeyeExternalNotificationsFilterTemplate',
  ExternalNotificationsSort = '@LocalStorage/ExternalNotificationsSort',

  ShouldHideExpireLicenceBanner = '@LocalStorage/ShouldHideExpireLicenceBanner',

  AtmeyeSelectedDevice = '@LocalStorage/AtmeyeSelectedDevice',

  EventDictFilterTemplate = '@LocalStorage/AtmeyeEventDictFilterTemplate',
  EventsDictionaryColumns = '@LocalStorage/EventsDictionaryColumns',

  Locale = '@LocalStorage/Locale',
  LogInLocale = '@LocalStorage/LogInLocale',
  LookupData = '@LocalStorage/LookupData',

  SubscribeReportPanel = '@LocalStorage/SubscribeReportPanel',
  ColumnMappingTemplate = '@LocalStorage/ColumnMappingTemplate',
  FirstColumnWidgets = '@LocalStorage/FirstColumnWidgets',
  SecondColumnWidgets = '@LocalStorage/SecondColumnWidgets',
  UserSettings = '@LocalStorage/UserSettings',
  ReportsSettings = '@LocalStorage/ReportsSettings',
  DeviceSettings = '@LocalStorage/DeviceSettings',

  NotificationsSettingsColumns = '@LocalStorage/NotificationsSettingsColumns',

  // RFM

  Rfm = '@LocalStorage/Rfm',
  RfmTransactionHistoryLogColumns = '@LocalStorage/Rfm/transactionHistoryLogColumns',
  RfmTransactionHistoryLogSorting = '@LocalStorage/Rfm/transactionHistoryLogSorting',

  RfmTransactionHistoryLogFilters = '@LocalStorage/Rfm/transactionHistoryLogFilters',
  RfmSynchronizationColumns = '@LocalStorage/Rfm/SynchronizationColumns',
  RfmSynchronizationSorting = '@LocalStorage/Rfm/SynchronizationSorting',
  RfmSynchronizationFilters = '@LocalStorage/Rfm/SynchronizationFilters',
  CentralSettings = '@LocalStorage/CentralSettings',
  CentralSettingsDeviceSelector = '@LocalStorage/CentralSettingsDeviceSelector',
  RfmMultipleDevice = '@LocalStorage/RfmMultipleDevice',
  RfmSingleDeviceSelector = '@LocalStorage/RfmSingleDeviceSelector',
  RfmSingleDeviceTree = '@LocalStorage/RfmSingleDeviceTree',
  RfmMultiDeviceSelector = '@LocalStorage/RfmMultiDeviceSelector',
  RfmMultiDeviceTree = '@LocalStorage/RfmMultiDeviceTree',
  RfmDeviceCounter = '@LocalStorage/RfmDeviceCounter',
  RfmSingleDeviceSorting = '@LocalStorage/RfmSingleDeviceSorting',
  MultipleDevicesFoldersTree = '@LocalStorage/MultipleDevicesFoldersTree',
  MultipleSnapshots = '@LocalStorage/MultipleSnapshots',
  MultipleSnapshotsDeviceSelector = '@LocalStorage/MultipleSnapshotsDeviceSelector',
  IsFullScreenMap = '@LocalStorage/IsFullScreenMap',
  HistogramSelectedInterval = '@LocalStorage/HistogramSelectedInterval',
  BlackList = '@LocalStorage/BlackList',
  SecurityAlarms = '@LocalStorage/SecurityAlarms',
  Devices = '@LocalStorage/Devices',
  TechnicalEvents = '@LocalStorage/TechnicalEvents',
  Transactions = '@LocalStorage/Transactions',

  ObjectsTreeDataMap = '@LocalStorage/ObjectsTreeDataMap',
  ObjectsTreeDataDevices = '@LocalStorage/ObjectsTreeDataDevices',
  ObjectsTreeDataAlarmsByDevice = '@LocalStorage/ObjectsTreeDataAlarmsByDevice',
  ObjectsTreeDataAlarms = '@LocalStorage/ObjectsTreeDataAlarms',
  ObjectsTreeDataEventsByDevice = '@LocalStorage/ObjectsTreeDataEventsByDevice',
  ObjectsTreeDataEvents = '@LocalStorage/ObjectsTreeDataEvents',
  ObjectsTreeDataTransactions = '@LocalStorage/ObjectsTreeDataTransactions',
  ObjectsTreeDataTransactionsByDevice = '@LocalStorage/ObjectsTreeDataTransactionsByDevice',
  ObjectsTreeDataBlackList = '@LocalStorage/ObjectsTreeDataBlackList',
  ObjectsTreeDataSnapshots = '@LocalStorage/ObjectsTreeDataSnapshots',

  SelectedFiltersAlarms = '@LocalStorage/SelectedFiltersAlarms',
  SelectedFiltersAlarmsByDevice = '@LocalStorage/SelectedFiltersAlarmsByDevice',
  SelectedFiltersEvents = '@LocalStorage/SelectedFiltersEvents',
  SelectedFiltersEventsByDevice = '@LocalStorage/SelectedFiltersEventsByDevice',
  SelectedFiltersTransactions = '@LocalStorage/SelectedFiltersTransactions',
  SelectedFiltersTransactionsByDevice = '@LocalStorage/SelectedFiltersTransactionsByDevice',
  SelectedFiltersOperationsLog = '@LocalStorage/SelectedFiltersOperationsLog',
  SelectedFiltersBlackList = '@LocalStorage/SelectedFiltersBlackList',
  SelectedFiltersSnapshots = '@LocalStorage/SelectedFiltersSnapshots',
  SelectedFiltersEventsDictionary = '@LocalStorage/SelectedFiltersEventsDictionary',

  MonitoringLookAheadPeriodDays = '@LocalStorage/MonitoringLookAheadPeriosDays',
  UserSettingsDictionaries = '@LocalStorage/UserSettingsDictionaries',

  // SD
  SDOpenedTicketsByStatusesColumns = '@LocalStorage/SDOpenedTicketsByStatusesColumns',

  // Vault V1
  AccessTokenVaultV1 = '@LocalStorage/AccessTokenVaultV1',
  UsersSettingsVaultV1 = '@LocalStorage/UsersSettingsVaultV1',
  UserPermissionsVaultV1 = '@LocalStorage/UserPermissionsVaultV1',
  UserModulesVaultV1 = '@LocalStorage/UserModulesVaultV1',
  UserDataVaultV1 = '@LocalStorage/UserDataVaultV1',
  ActiveTeamsVaultV1 = '@LocalStorage/ActiveTeamsVaultV1',
  IsOpenMenuVaultV1 = '@LocalStorage/IsOpenMenuVaultV1',

  // Vault V2
  AccessTokenVaultV2 = '@LocalStorage/AccessTokenVaultV2',
  UsersSettingsVaultV2 = '@LocalStorage/UsersSettingsVaultV2',
  UserPermissionsVaultV2 = '@LocalStorage/UserPermissionsVaultV2',
  UserModulesVaultV2 = '@LocalStorage/UserModulesVaultV2',
  UserDataVaultV2 = '@LocalStorage/UserDataVaultV2',
  ActiveTeamsVaultV2 = '@LocalStorage/ActiveTeamsVaultV2',
  IsOpenMenuVaultV2 = '@LocalStorage/IsOpenMenuVaultV2',

  // Global
  UsersSettings = '@LocalStorage/UsersSettings',
  UserPermissions = '@LocalStorage/UserPermissions',
  RolePermissions = '@LocalStorage/RolePermissions',
  UserModules = '@LocalStorage/UserModules',
  UserData = '@LocalStorage/UserData',
  UserPhoto = '@LocalStorage/UserPhoto',

  WebSocket = '@LocalStorage/WebSocket',

  // Notification
  AlarmSettings = '@LocalStorage/AlarmSettings',
  EventSettings = '@LocalStorage/EventSettings',
  EventNotifications = '@LocalStorage/EventNotifications',
  AlarmNotifications = '@LocalStorage/AlarmNotifications',
}

export const generateAtmeyeGroupingKey = (windowType: string): string =>
  `@LocalStorage/${windowType}/grouping`
