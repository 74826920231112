import { AdmPasswordPolicySettings } from '@/api/vault-v1/swaggerGeneratedApi'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

export class PasswordPolicyApi {
  private static requestService = getRequestServiceVaultV1()

  public static async get(): Promise<AdmPasswordPolicySettings> {
    try {
      return await PasswordPolicyApi.requestService.get('/admin/appsettings/passwordPolicy')
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async update(settings: AdmPasswordPolicySettings): Promise<void> {
    try {
      return await PasswordPolicyApi.requestService.put(
        '/admin/appsettings/passwordPolicy/save',
        settings,
      )
    } catch (error) {
      throw Error(String(error as Error))
    }
  }
}
