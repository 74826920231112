import MuiTooltip, { TooltipProps } from '@material-ui/core/Tooltip'
import clsx from 'clsx'
import React, { ReactElement } from 'react'

import { useClasses, useClassesTooltip } from './styles'
import { Props } from './types'

const Tooltip = ({ className, ...props }: TooltipProps & Props): ReactElement => {
  const classesTooltip = useClassesTooltip()
  const classes = useClasses()

  return (
    <MuiTooltip
      arrow
      placement="top"
      classes={{
        tooltip: props?.classes?.tooltip || classesTooltip.tooltip,
        arrow: classesTooltip.arrow,
      }}
      {...props}
      title={<div className={clsx(classes.titleWrapper, className)}>{props.title}</div>}
    />
  )
}

export default Tooltip
