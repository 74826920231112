import { AnyAction } from 'redux'

import { AvailableModule } from '@/api/atmeye/common/types'
import { CommonActions } from '@/constants/atmeye/actions/actionsCommon'

export interface State {
  media: {
    currentEvent: number | null
    fetchingEnabled: boolean
    data: {
      [key: string]: {
        image: any
        type: string
      }
    }
  }
  ackWarning: null | {
    errorMessage: string
    messageParameters: { [key: string]: string }
  }
  availableModules: { [key: string]: AvailableModule } | null
  isAvailableModulesLoading: boolean
}

export const initialState: State = {
  media: {
    currentEvent: null,
    data: {},
    fetchingEnabled: true,
  },
  ackWarning: null,
  availableModules: null,
  isAvailableModulesLoading: true,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case CommonActions.getAvailableModulesRequest:
      return {
        ...state,
        isAvailableModulesLoading: true,
      }

    case CommonActions.getAvailableModulesResponse:
      return {
        ...state,
        availableModules: payload,
        isAvailableModulesLoading: false,
      }

    case CommonActions.SetAckWarning:
      return {
        ...state,
        ackWarning: payload,
      }

    case CommonActions.SetMediaRequest:
      return {
        ...state,
        media: {
          currentEvent: payload.eventId,
          data: {
            ...state.media.data,
            [payload.eventId]: {
              image: payload.image,
              type: payload.type,
            },
          },
          fetchingEnabled: payload.fetchingEnabled,
        },
      }

    case CommonActions.ClearCurrentMedia:
      return {
        ...state,
        media: {
          currentEvent: null,
          data: {},
          fetchingEnabled: true,
        },
      }
    case CommonActions.SetCurrentMedia:
      return {
        ...state,
        media: {
          currentEvent: payload.eventId,
          data: {
            ...state.media.data,
          },
          fetchingEnabled: state.media.fetchingEnabled,
        },
      }
    case CommonActions.SetMediaRequestErrorMessage:
      return {
        ...state,
        media: {
          currentEvent: payload.eventId,
          data: {
            ...state.media.data,
            [payload.eventId]: {
              image: {
                ...state.media.data[payload.eventId]?.image,
                errorMessage: payload.message,
              },
            },
          },
          fetchingEnabled: false,
        },
      }

    default:
      return state
  }
}
