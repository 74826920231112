import { Button, ButtonBase, Checkbox, MenuItem, Typography } from '@material-ui/core'
import { Cached, ExitToApp, ExpandLess, ExpandMore, Lock } from '@material-ui/icons'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { isIE } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import Test from 'react-test-attributes'

import { ReactComponent as DeviceIcon } from '@/assets/adm/icons/device.svg'
import { AboutModal } from '@/components/blocks/cm'
import ChangePasswordModal from '@/components/blocks/cm/ChangePasswordModal'
import GlobalConfirmModal from '@/components/blocks/LogoutConfirmModal/component'
import ContextMenu from '@/components/controls/ContextMenu/component'
import { CASH_MANAGEMENT_BLOCK_PREFIX, emptyUserSettingsConfig } from '@/constants'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { AppNavigationRoutes, cmBaseUrl } from '@/constants/paths'
import {
  getParsedLocalStorageItem,
  getUserDataLocalStorage,
  setDataToLocalStorage,
} from '@/helpers/localStorage'
import { getChangePasswordState } from '@/store/cm/auth'
import {
  changePasswordRequest,
  setChangePasswordModalOpen,
  signOutCM,
} from '@/store/cm/auth/actions'
import {
  getAboutModalOpen,
  getBuildInfoCM,
  getSorterId,
  getSorterStatus,
  setAboutModalOpen,
  setCashSortersModal,
  setCloseCashSorterModal,
} from '@/store/cm/common'
import { ChangePasswordFormValuesCM } from '@/types'
import { getTestId } from '@/utils'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'

const setLocalStorageWithBaseUrl = setDataToLocalStorage(cmBaseUrl)
const getParsedLocalStorageItemBaseUrl = getParsedLocalStorageItem(cmBaseUrl)

const UserMenu = (): React.ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | (EventTarget & HTMLButtonElement)>(null)
  const [isShowLogoutConfirmModal, setIsShowLogoutConfirmModal] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()
  const translate = useTranslate()

  const classes = useClasses()
  const testId = getTestId('user-menu-cm')
  const { displayName, login, isSortersEnabled } = getUserDataLocalStorage(cmBaseUrl) || {}

  const { pathname } = window.location

  const buildInfo = useSelector(getBuildInfoCM)

  const { isModalOpen: isChangePasswordModalOpen, isLoading, error } = useSelector(
    getChangePasswordState,
  )
  const { isModalOpen: isAboutModalOpen } = useSelector(getAboutModalOpen)

  const isOnline = useSelector(getSorterStatus)
  const sorter = useSelector(getSorterId)

  const handleChangePasswordModal = useCallback(() => {
    dispatch(setChangePasswordModalOpen(!isChangePasswordModalOpen))
  }, [isChangePasswordModalOpen])

  const handleAboutModal = useCallback(() => {
    dispatch(setAboutModalOpen(!isAboutModalOpen))
  }, [isAboutModalOpen])

  const handleClick = useCallback((event: SyntheticEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback((): void => {
    setAnchorEl(null)
  }, [])

  const handleSignOut = useCallback((): void => {
    handleClose()
    dispatch(signOutCM(true))
    history.push(AppNavigationRoutes.SignInPage)
  }, [dispatch])

  const handleOpenCloseLogoutConfirmModal = useCallback((): void => {
    setIsShowLogoutConfirmModal(isShowLogoutConfirmModal => !isShowLogoutConfirmModal)
  }, [setIsShowLogoutConfirmModal])

  const handleClearUserSettings = useCallback((): void => {
    const initialUserSettings = {
      [login]: emptyUserSettingsConfig,
    }

    setLocalStorageWithBaseUrl(LocalStorageItems.UsersSettings, JSON.stringify(initialUserSettings))

    localStorage.removeItem(LocalStorageItems.ActiveTeamsCM)

    window.location.reload()
  }, [getParsedLocalStorageItem, login])

  const handleChangePassword = useCallback<(values: ChangePasswordFormValuesCM) => void>(
    (values): void => {
      dispatch(changePasswordRequest(values))
    },
    [dispatch],
  )

  const updateLocalStorageByKey = (key: string): void => {
    const allUsersSettings = getParsedLocalStorageItemBaseUrl(LocalStorageItems.UsersSettings) || {}
    const currentUserSettings = allUsersSettings[login]

    if (currentUserSettings) {
      const dataToChange = {
        [login]: {
          ...currentUserSettings,
          [key]: !currentUserSettings[key],
        },
      }

      setLocalStorageWithBaseUrl(LocalStorageItems.UsersSettings, JSON.stringify(dataToChange))
    }
  }

  const handleToggleAppBar = useCallback((): void => {
    updateLocalStorageByKey('isAppBarVisible')
    window.location.reload()
  }, [])

  const handleToggleTablesBorder = useCallback((): void => {
    updateLocalStorageByKey('isTablesWithBorder')
    window.location.reload()
  }, [])

  const handleToggleObjectsTree = useCallback((): void => {
    updateLocalStorageByKey('isObjectsTreeVisible')
    window.location.reload()
  }, [])

  const handleOpenObjectsTree = useCallback((): void => {
    updateLocalStorageByKey('isOpenedTreeByDefault')
    window.location.reload()
  }, [])

  const handleReportPreview = useCallback((): void => {
    updateLocalStorageByKey('isReportsPreview')

    handleClose()
  }, [])

  const allUsersSettings = getParsedLocalStorageItemBaseUrl(LocalStorageItems.UsersSettings) || {}
  const {
    isAppBarVisible,
    isObjectsTreeVisible,
    isOpenedTreeByDefault,
    isTablesWithBorder,
    isReportsPreview,
  } = allUsersSettings[login] || {}

  const userMenu = [
    {
      id: '1',
      content: (
        <>
          <ExitToApp className={classes.icon} />
          <span className={classes.label}>
            <FormattedMessage id="title.logout" defaultMessage="Logout" />
          </span>
        </>
      ),
      handleClick: handleOpenCloseLogoutConfirmModal,
    },
    {
      id: '2',
      content: (
        <>
          <Lock className={classes.icon} />
          <span className={classes.label}>
            <FormattedMessage id="cm.ChangePassword" defaultMessage="Change password" />
          </span>
        </>
      ),
      handleClick: handleChangePasswordModal,
    },
    {
      id: '3',
      content: (
        <>
          <Cached className={classes.icon} />
          <span className={classes.label}>
            <FormattedMessage id="cm.ClearSettings" defaultMessage="Clear Settings" />
          </span>
        </>
      ),
      handleClick: handleClearUserSettings,
    },
    {
      id: '3',
      content: (
        <>
          <Checkbox className={classes.checkbox} checked={isAppBarVisible} />
          <span className={classes.label}>
            <FormattedMessage id="cm.ShowTabs" defaultMessage="Show Tabs" />
          </span>
        </>
      ),
      handleClick: handleToggleAppBar,
    },
    {
      id: '4',
      content: (
        <>
          <Checkbox className={classes.checkbox} checked={isObjectsTreeVisible} />
          <span className={classes.label}>
            <FormattedMessage id="cm.ObjectsTree" defaultMessage="Objects Tree" />
          </span>
        </>
      ),
      handleClick: handleToggleObjectsTree,
    },
    {
      id: '5',
      content: (
        <>
          <Checkbox className={classes.checkbox} checked={isOpenedTreeByDefault} />
          <span className={classes.label}>
            <FormattedMessage id="cm.ObjectsTreeOpen" defaultMessage="Open Objects Tree" />
          </span>
        </>
      ),
      handleClick: handleOpenObjectsTree,
    },
    {
      id: '6',
      content: (
        <>
          <Checkbox className={classes.checkbox} checked={isReportsPreview} />
          <span className={classes.label}>
            <FormattedMessage id="cm.ReportsPreview" defaultMessage="Reports Preview" />
          </span>
        </>
      ),
      handleClick: handleReportPreview,
    },
    {
      id: '7',
      content: (
        <>
          <Checkbox className={classes.checkbox} checked={isTablesWithBorder} />
          <span className={classes.label}>
            <FormattedMessage id="title.TableGrid" defaultMessage="Grid for tables" />
          </span>
        </>
      ),
      handleClick: handleToggleTablesBorder,
    },
    {
      id: '8',
      content: (
        <>
          <HelpOutlineIcon className={classes.icon} />
          <span className={classes.label}>
            <FormattedMessage id="cm.AboutPageTitle" defaultMessage="About" />
          </span>
        </>
      ),
      handleClick: handleAboutModal,
    },
  ]

  const hasSorter = useMemo(() => isOnline && !!sorter, [isOnline, sorter])

  const handleCashSortersModal = useCallback(() => {
    if (!hasSorter) {
      dispatch(setCashSortersModal(true))
    } else {
      dispatch(setCloseCashSorterModal(true))
    }
  }, [hasSorter])

  return (
    <>
      <Test id={testId(0)}>
        <div>
          {pathname.includes(CASH_MANAGEMENT_BLOCK_PREFIX) && isSortersEnabled && (
            <Button
              className={classes.counterBtn}
              variant={hasSorter ? 'contained' : 'outlined'}
              startIcon={!hasSorter ? <DeviceIcon /> : undefined}
              onClick={handleCashSortersModal}
            >
              {hasSorter
                ? `${translate('translate#cm.Counter')} ${sorter}`
                : translate('translate#cm.CounterNotConnected')}
            </Button>
          )}
          <Test id={testId(1)}>
            <ButtonBase className={classes.button} onClick={handleClick}>
              <Typography className={classes.userName}>
                {displayName || 'DevicePage User'}
              </Typography>
              {!anchorEl ? (
                <ExpandMore className={classes.icon} />
              ) : (
                <ExpandLess className={classes.icon} />
              )}
            </ButtonBase>
          </Test>
          <Test id={testId(1)}>
            <ContextMenu
              anchorEl={anchorEl}
              handleClose={handleClose}
              limitMenuSize={false}
              classes={
                isIE
                  ? {
                      list: classes.list,
                      paper: classes.menuPaper,
                    }
                  : undefined
              }
              options={!isIE ? userMenu : []}
            >
              {isIE ? (
                <>
                  {userMenu.map(item => (
                    <MenuItem
                      key={item.id}
                      disableRipple
                      className={classes.item}
                      onClick={item.handleClick}
                    >
                      {item.content}
                    </MenuItem>
                  ))}
                </>
              ) : (
                undefined
              )}
            </ContextMenu>
          </Test>
          <GlobalConfirmModal
            open={isShowLogoutConfirmModal}
            onClose={handleOpenCloseLogoutConfirmModal}
            onConfirm={handleSignOut}
            message={translate('translate#title.areYouSureYouWantLogout')}
            disableBackdropClick
          />
        </div>
      </Test>
      {isChangePasswordModalOpen && (
        <ChangePasswordModal
          open={isChangePasswordModalOpen}
          handleClose={handleChangePasswordModal}
          handleChangePassword={handleChangePassword}
          isLoading={isLoading}
          error={error}
        />
      )}
      {isAboutModalOpen && (
        <AboutModal
          open={isAboutModalOpen}
          handleClose={handleAboutModal}
          buildInfo={buildInfo || {}}
        />
      )}
    </>
  )
}

export default UserMenu
