import { AnyAction } from 'redux'

import {
  AdmApprovalItem,
  AdmApprovedItem,
  ComCurrency,
  ComTeam,
  eBS2AvailableIntegration,
  VaultsResponse,
} from '@/api/cm/swaggerGeneratedApi'
import {
  AdmCompanyBase,
  AdmUserOperationInfo,
  BS2CashPointEvent,
  ComBasicCashPoint,
  ComCassetteCurrency,
  ComCMReport,
  ComFilterTreeNode,
  ComGlobalFilter,
  ComServiceCompany,
  ComTotals,
  ComUser,
  DocDocument,
  IcsCashPointCassette,
  IcsEventBase,
  IcsMissedReplenishmentChart,
  IcsMissedReplenishmentEvent,
  ParsedCitBalCashPointAccount,
} from '@/api/cm/swaggerGeneratedApi/index'
import { AdmApprovalItemWithCheckbox } from '@/components/blocks/cm/Admin/ApproveItemModal/types'
import { CMActions } from '@/constants/actions/index'
import { ResponseError } from '@/types'
import { CurrencyProps } from '@/types/cm/citBalancing'
import { BuildInfo, ComUsersProps } from '@/types/cm/common'

export const setComCMError = (error: string | unknown): AnyAction => ({
  type: CMActions.ComSetError,
  payload: error,
})

export const setIsCompanyModalCM = (isOpen: boolean): AnyAction => ({
  type: CMActions.ComSetIsCompanyModal,
  payload: isOpen,
})

export const getCompaniesRequestCM = (): AnyAction => ({
  type: CMActions.ComGetCompaniesRequest,
})

export const getCompaniesResponseCM = (payload: ComServiceCompany[]): AnyAction => ({
  type: CMActions.ComGetCompaniesResponse,
  payload,
})

export const setNewCompanyRequest = (newCompany: AdmCompanyBase): AnyAction => ({
  type: CMActions.ComSetNewCompanyRequest,
  payload: newCompany,
})

export const setNewCompanyResponse = (): AnyAction => ({
  type: CMActions.ComSetNewCompanyResponse,
})
export const setNewCompanyFail = (error: string): AnyAction => ({
  type: CMActions.ComSetNewCompanyFail,
  payload: error,
})

export const getCurrenciesRequestCM = (): AnyAction => ({
  type: CMActions.GetCurrenciesRequest,
})

export const getCurrenciesResponseCM = (payload: ComCurrency[]): AnyAction => ({
  type: CMActions.GetCurrenciesResponse,
  payload,
})

export const getCurrenciesResponseFailCM = (error: ResponseError): AnyAction => ({
  type: CMActions.GetCurrenciesResponseFail,
  payload: error,
})

export const getFilterCurrenciesRequestCM = (): AnyAction => ({
  type: CMActions.GetFilterCurrenciesRequest,
})

export const getFilterCurrenciesResponseCM = (payload: ComCurrency[]): AnyAction => ({
  type: CMActions.GetFilterCurrenciesResponse,
  payload,
})

export const getFilterCurrenciesResponseFailCM = (error: ResponseError): AnyAction => ({
  type: CMActions.GetFilterCurrenciesResponseFail,
  payload: error,
})

export const getFilterNodesRequestCM = (): AnyAction => ({
  type: CMActions.GetFilterNodesRequest,
})

export const getFilterNodesResponseCM = (payload: ComFilterTreeNode): AnyAction => ({
  type: CMActions.GetFilterNodesResponse,
  payload,
})

export const getFilterNodesResponseFailCM = (error: ResponseError): AnyAction => ({
  type: CMActions.GetFilterNodesResponseFail,
  payload: error,
})

export const getFilterCashPointsRequestCM = (): AnyAction => ({
  type: CMActions.ComGetFilterTIDCashPointsRequest,
})

export const getFilterCashPointsResponseCM = (payload: ComBasicCashPoint[]): AnyAction => ({
  type: CMActions.ComGetFilterTIDCashPointsResponse,
  payload,
})
export const getFilterCashPointsFailCM = (error: ResponseError): AnyAction => ({
  type: CMActions.ComGetFilterTIDCashPointsFail,
  payload: error,
})

export const setSelectedFilterCashPointCM = (payload: string[]): AnyAction => ({
  type: CMActions.ComSetSelectedFilterTIDCashPoints,
  payload,
})

export const setGlobalFilter = (payload: ComGlobalFilter): AnyAction => ({
  type: CMActions.SetGlobalFilter,
  payload,
})

export const setObjectTreeFilter = (): AnyAction => ({
  type: CMActions.SetObjectTreeFilter,
})

// Users Option
export const getUsersOptionsRequestCM = (): AnyAction => ({
  type: CMActions.ComGetUsersOptionsRequest,
})
export const getUsersOptionsResponseCM = (payload: AdmUserOperationInfo[]): AnyAction => ({
  type: CMActions.ComGetUsersOptionsResponse,
  payload,
})

// Users
export const getUsersRequestCM = (payload: ComUsersProps | undefined = {}): AnyAction => ({
  type: CMActions.ComGetUsersRequest,
  payload,
})
export const getUsersResponseCM = (payload: ComUser): AnyAction => ({
  type: CMActions.ComGetUsersResponse,
  payload,
})
export const getUsersResponseFailCM = (error: ResponseError): AnyAction => ({
  type: CMActions.ComGetUsersResponseFail,
  payload: error,
})

export const getSessionDocumentsReportsRequestCM = (payload: {
  id: string | number
  stageId?: string | number
}): AnyAction => ({
  type: CMActions.ComGetSessionDocumentsReportsRequest,
  payload,
})

export const getSessionDocumentsReportsResponseCM = (payload: DocDocument[]): AnyAction => ({
  type: CMActions.ComGetSessionDocumentsReportsResponse,
  payload,
})

export const getSessionDocumentsReportsFailCM = (message?: string): AnyAction => ({
  type: CMActions.ComGetSessionDocumentsReportsFail,
  payload: message,
})

export const getSessionDocumentsReportRequestCM = (payload?: ComCMReport): AnyAction => ({
  type: CMActions.ComGetSessionDocumentsReportRequest,
  payload,
})

export const getSessionDocumentsReportResponseCM = (payload: any): AnyAction => ({
  type: CMActions.ComGetSessionDocumentsReportResponse,
  payload,
})

export const getSessionDocumentsReportFailCM = (message?: string): AnyAction => ({
  type: CMActions.ComGetSessionDocumentsReportFail,
  payload: message,
})

export const setInitialReportsCM = (): AnyAction => ({
  type: CMActions.ComSetInitialReportsCM,
})

export const getBuildInfoRequestCM = (): AnyAction => ({
  type: CMActions.ComGetBuildInfoRequestCM,
})

export const getBuildInfoResponseCM = (payload: BuildInfo): AnyAction => ({
  type: CMActions.ComGetBuildInfoResponseCM,
  payload,
})

export const getBuildInfoResponseFailCM = (): AnyAction => ({
  type: CMActions.ComGetBuildInfoResponseFailCM,
})

export const getComCurrenciesAndDenominationsRequest = (): AnyAction => ({
  type: CMActions.ComGetCurrenciesAndDenominationsRequest,
})
export const getComCurrenciesAndDenominationsResponse = (
  denominations: ComCurrency[],
): AnyAction => ({
  type: CMActions.ComGetCurrenciesAndDenominationsResponse,
  payload: denominations,
})

// ------------------------------------------------------------------------------

export const getIcsMissedReplenishmentRequest = (payload: {
  id: string
  dateFrom: string
  cashPointId: string
}): AnyAction => ({
  type: CMActions.IcsGetMissedReplenishmentRequest,
  payload,
})

export const getIcsMissedReplenishmentFail = (error: string): AnyAction => ({
  type: CMActions.IcsGetMissedReplenishmentFail,
  payload: error,
})

export const setIcsMissedReplenishmentFromDate = (dateFrom: string): AnyAction => ({
  type: CMActions.IcsSetMissedReplenishmentFromDate,
  payload: dateFrom,
})

export const getIcsMissedReplenishmentCartResponse = (
  payload: IcsMissedReplenishmentChart,
): AnyAction => ({
  type: CMActions.IcsGetMissedReplenishmentCartResponse,
  payload,
})

export const getIcsMissedReplenishmentEventsRequest = (): AnyAction => ({
  type: CMActions.IcsGetMissedReplenishmentEventsRequest,
})

export const getIcsMissedReplenishmentEventsResponse = (payload: {
  totalEvents: number
  events: IcsMissedReplenishmentEvent[]
  cassettesNames: string[]
  lastEventDate: string
}): AnyAction => ({
  type: CMActions.IcsGetMissedReplenishmentEventsResponse,
  payload,
})

export const setIcsMissedReplenishmentPagingRequest = (payload: {
  page?: number
  pageSize?: number
}): AnyAction => ({
  type: CMActions.IcsSetMissedReplenishmentPagingRequest,
  payload,
})

export const setIcsMissedReplenishmentPagingResponse = (payload: {
  page?: number
  pageSize?: number
}): AnyAction => ({
  type: CMActions.IcsSetMissedReplenishmentPagingResponse,
  payload,
})

export const sortIcsMissedReplenishmentEventsTable = (payload: {
  sortColumn: string | undefined
  sortOrder: 'asc' | 'desc' | undefined
}): AnyAction => ({
  type: CMActions.IcsSortMissedReplenishmentEventsTable,
  payload,
})

export const getMissedReplenishmentCassettesRequest = (payload: {
  orderId: string
  eventType: string
}): AnyAction => ({
  type: CMActions.IcsGetMissedReplenishmentCassettesRequest,
  payload,
})

export const getMissedReplenishmentCassettesResponse = (
  payload: IcsCashPointCassette[],
): AnyAction => ({
  type: CMActions.IcsGetMissedReplenishmentCassettesResponse,
  payload,
})

export const IcsGetMissedReplenishmentCassettesFail = (error: string): AnyAction => ({
  type: CMActions.IcsGetMissedReplenishmentCassettesFail,
  payload: error,
})

export const IcsGetMissedReplenishmentCassettesUpdate = (
  cassette: IcsCashPointCassette[],
): AnyAction => ({
  type: CMActions.IcsGetMissedReplenishmentCassettesUpdate,
  payload: cassette,
})

export const createMissedReplenishmentEventRequest = (payload: {
  cassettes: IcsCashPointCassette[]
  date: string
  eventType: BS2CashPointEvent
}): AnyAction => ({
  type: CMActions.IcsCreateMissedReplenishmentEventRequest,
  payload,
})
export const createMissedReplenishmentEventResponse = (): AnyAction => ({
  type: CMActions.IcsCreateMissedReplenishmentEventResponse,
})
export const createMissedReplenishmentEventFail = (error: string): AnyAction => ({
  type: CMActions.IcsCreateMissedReplenishmentEventFail,
  payload: error,
})

export const applyMissedReplenishmentEventRequest = (selectedEvent: IcsEventBase): AnyAction => ({
  type: CMActions.IcsApplyMissedReplenishmentEventRequest,
  payload: selectedEvent,
})
export const applyMissedReplenishmentEventResponse = (): AnyAction => ({
  type: CMActions.IcsApplyMissedReplenishmentEventResponse,
  payload: { withRefreshStages: true, withRefreshDocs: true },
})

export const applyMissedReplenishmentEventFail = (): AnyAction => ({
  type: CMActions.IcsApplyMissedReplenishmentEventFail,
})

export const setInitialMissedReplenishment = (): AnyAction => ({
  type: CMActions.IcsSetInitialMissedReplenishment,
})

export const setMissedReplenishmentEventType = (eventType: BS2CashPointEvent): AnyAction => ({
  type: CMActions.IcsSetMissedReplenishmentEventType,
  payload: eventType,
})

export const setInitialMissedReplenishmentCassettes = (): AnyAction => ({
  type: CMActions.IcsSetInitialMissedReplenishmentCassettes,
})

export const calcMissedReplenishmentCassettesTotalRequest = (): AnyAction => ({
  type: CMActions.IcsCalcMissedReplenishmentCassettesTotalRequest,
})

export const calcMissedReplenishmentCassettesTotalResponse = (totals: ComTotals[]): AnyAction => ({
  type: CMActions.IcsCalcMissedReplenishmentCassettesTotalResponse,
  payload: totals,
})

export const calcMissedReplenishmentCassettesTotalFail = (error: string): AnyAction => ({
  type: CMActions.IcsCalcMissedReplenishmentCassettesTotalFail,
  payload: error,
})

export const handleBalCashBalanceModalKeyPress = (
  actionName: 'NEXT_ROW' | 'PREV_ROW',
): AnyAction => ({
  type: CMActions.BalHandleCashBalanceModalKeyPress,
  payload: actionName,
})

export const setBalSelectedEndCycle = (sessionId: string): AnyAction => ({
  type: CMActions.BalSetSelectedEndCycle,
  payload: sessionId,
})
export const setBalOnlySelectedEndCycle = (sessionId: string): AnyAction => ({
  type: CMActions.BalSetOnlySelectedEndCycle,
  payload: sessionId,
})

export const setUnloadedTotalSessionId = (totalSessionId: string | undefined): AnyAction => ({
  type: CMActions.BalSetUnloadedTotalSessionId,
  payload: totalSessionId,
})

export const setBalancingDialogInitial = (): AnyAction => ({
  type: CMActions.ComSetBalancingDialogInitial,
})

export const setUnloadedCassettesApplied = (payload: boolean): AnyAction => ({
  type: CMActions.CitBalSetUnloadedCassettesApplied,
  payload,
})

export const openBalCasetteModal = (payload: string): AnyAction => ({
  type: CMActions.BalOpenCassettesModal,
  payload,
})

// Accounts ---------------------------------------------------------

export const setComParsedCurrencies = (currencies: CurrencyProps[]): AnyAction => ({
  type: CMActions.ComSetParsedCurrencies,
  payload: currencies,
})

export const setComAccountsInitial = (): AnyAction => ({
  type: CMActions.ComSetAccountsInitial,
})

export const updateComAccountRow = (account: ParsedCitBalCashPointAccount): AnyAction => ({
  type: CMActions.ComUpdateAccountRow,
  payload: account,
})

export const setComSelectedAccountRow = (account: ParsedCitBalCashPointAccount): AnyAction => ({
  type: CMActions.ComSetSelectedAccountRow,
  payload: account,
})

export const addComNewAccount = (account: ParsedCitBalCashPointAccount): AnyAction => ({
  type: CMActions.ComAddNewAccount,
  payload: account,
})

export const removeComAccountRow = (): AnyAction => ({
  type: CMActions.ComRemoveAccountRow,
})

// Cassettes ---------------------------------------------------------

export const getComCassetteCurrenciesRequest = (): AnyAction => ({
  type: CMActions.ComGetCassetteCurrenciesRequest,
})
export const getComCassetteCurrenciesResponse = (payload: ComCassetteCurrency[]): AnyAction => ({
  type: CMActions.ComGetCassetteCurrenciesResponse,
  payload,
})
export const getComCassetteCurrenciesFail = (error: string): AnyAction => ({
  type: CMActions.ComGetCassetteCurrenciesFail,
  payload: error,
})

export const getComCassetteTypesRequest = (): AnyAction => ({
  type: CMActions.ComGetCassetteTypesRequest,
})
export const getComCassetteTypesResponse = (payload: string[]): AnyAction => ({
  type: CMActions.ComGetCassetteTypesResponse,
  payload,
})
export const getComCassetteTypesFail = (error: string): AnyAction => ({
  type: CMActions.ComGetCassetteTypesFail,
  payload: error,
})

export const getComAvailableCassetteNumbersRequest = (): AnyAction => ({
  type: CMActions.ComGetAvailableCassetteNumbersRequest,
})
export const getComAvailableCassetteNumbersResponse = (payload: number[]): AnyAction => ({
  type: CMActions.ComGetAvailableCassetteNumbersResponse,
  payload,
})

// Methods ------------------------------------------------------------

export const setIsWorkingWithTeam = (payload: boolean): AnyAction => ({
  type: CMActions.ComSetIsWorkingWithTeam,
  payload,
})

export const setCashSortersModal = (payload: boolean): AnyAction => ({
  type: CMActions.ComSetCashSortersModal,
  payload,
})

export const setCloseCashSorterModal = (payload: boolean): AnyAction => ({
  type: CMActions.ComSetCloseCashSorterModal,
  payload,
})

export const setAboutModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.SetAboutModalOpen,
  payload: isOpen,
})

export const setCMSocketData = (payload: { [key: string]: any }): AnyAction => ({
  type: CMActions.ComSetSocketData,
  payload,
})

export const setSortersStatus = (payload: {
  isOnline: boolean
  sorter: string | null
}): AnyAction => ({
  type: CMActions.ComSetSorterStatus,
  payload,
})

export const getComIntegrationsRequest = (): AnyAction => ({
  type: CMActions.ComGetIntegrationsRequest,
})
export const getComIntegrationsResponse = (payload: eBS2AvailableIntegration[]): AnyAction => ({
  type: CMActions.ComGetIntegrationsResponse,
  payload,
})

export const getComVaultsWithLocationRequest = (): AnyAction => ({
  type: CMActions.ComGetVaultsWithLocationRequest,
})
export const getComVaultsWithLocationResponse = (payload: VaultsResponse): AnyAction => ({
  type: CMActions.ComGetVaultsWithLocationResponse,
  payload,
})

export const getComTeamsRequest = (): AnyAction => ({
  type: CMActions.ComGetTeamsRequest,
})
export const getComTeamsResponse = (payload: ComTeam[]): AnyAction => ({
  type: CMActions.ComGetTeamsResponse,
  payload,
})

interface OpenApproveItemModal {
  isModalOpen: boolean
  module?: string
  moduleName?: string
}

// Approve Items ----------------------------------------------------
export const openApproveItemsModal = ({
  isModalOpen,
  module = '',
  moduleName = '',
}: OpenApproveItemModal): AnyAction => ({
  type: CMActions.ComOpenApproveItemsModal,
  payload: { isModalOpen, module, moduleName },
})

export const getApproveItemsRequest = (): AnyAction => ({
  type: CMActions.ComGetApproveItemsRequest,
})
export const getApproveItemsResponse = (approvalItem: AdmApprovalItem[]): AnyAction => ({
  type: CMActions.ComGetApproveItemsResponse,
  payload: approvalItem,
})
export const getApproveItemsFail = (error: string | unknown): AnyAction => ({
  type: CMActions.ComGetApproveItemsFail,
  payload: error,
})

export const updateLocalApproveItems = (
  approvalItems: AdmApprovalItemWithCheckbox[],
): AnyAction => ({
  type: CMActions.ComUpdateLocalApproveItems,
  payload: approvalItems,
})

interface SetApproveItems {
  approvedItems: AdmApprovedItem[]
  hasApproveItem?: boolean
}

export const setApproveItemsRequest = ({
  approvedItems,
  hasApproveItem = false,
}: SetApproveItems): AnyAction => ({
  type: CMActions.ComSetApproveItemsRequest,
  payload: { approvedItems, hasApproveItem },
})
export const setApproveItemsResponse = (): AnyAction => ({
  type: CMActions.ComSetApproveItemsResponse,
})
export const setApproveItemsFail = (error: string | unknown): AnyAction => ({
  type: CMActions.ComSetApproveItemsFail,
  payload: error,
})
