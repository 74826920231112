import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromTeamsVaultV1 from './reducer'

export const getTeamsStateVaultV1 = (state: AppState): fromTeamsVaultV1.State => state.vaultV1Teams

export const getTeams = createSelector(getTeamsStateVaultV1, state => state.teams)
export const getTeamsSort = createSelector(getTeams, state => ({
  sortColumn: state.sortColumn,
  sortOrder: state.sortOrder,
}))
export const getSelectedTeam = createSelector(getTeamsStateVaultV1, state => state.selectedTeam)

export const getIsSelectedTeam = (teamId: string) =>
  createSelector(getTeamsStateVaultV1, ({ selectedTeam }) => teamId === selectedTeam?.id)

export const getTeamModal = createSelector(getTeamsStateVaultV1, state => state.teamModal)
export const getIsApplyingTeam = createSelector(getTeamsStateVaultV1, state => state.isApplyingTeam)
export const getTeamReports = createSelector(getTeamsStateVaultV1, state => state.reports)
