import { ReturnAnyActionsUSM } from '@/store/usm/types/types'
import { actionsUsersUSM } from '@/store/usm/users/actions'
import { ResponseErrorTypes } from '@/types/common/ErrorResponseTypes'
import { LookupConfigCreateUserTypes } from '@/types/usm/lookupTypesConfigUSM/lookupTYpesUSM'
import { PersonalCardTypes } from '@/types/usm/personalInformation/personalInformationTypes'

export const initialState = {
  // personal Card
  personalCard: {} as PersonalCardTypes,
  isFetchingPersonalCard: false,
  // add new user
  isFetchingAddNewUser: false,
  // info-block
  isFetchingAdduserInfo: false,
  lookupConfig: [] as Array<LookupConfigCreateUserTypes>,
  // error
  error: null as ResponseErrorTypes | null,
}

export type State = typeof initialState

export type UsersAnyActions = ReturnAnyActionsUSM<typeof actionsUsersUSM>
export const reducer = (state: State = initialState, action: UsersAnyActions): State => {
  switch (action.type) {
    // personal card
    case '@/START_FETCHING_PERSONAL_CARD':
      return {
        ...state,
        isFetchingPersonalCard: true,
      }
    case '@/STOP_FETCHING_PERSONAL_CARD':
      return {
        ...state,
        isFetchingPersonalCard: false,
      }
    case '@/GET_PERSONAL_CARD_RESPONSE':
      return {
        ...state,
        personalCard: action.payload,
        error: null,
      }
    case '@/GET_PERSONAL_CARD_RESPONSE_FALL':
      return {
        ...state,
        error: action.payload,
      }
    // update-personal-card
    case '@/START_FETCHING_UPDATE_PERSONAL_CARD_USM':
      return {
        ...state,
        isFetchingPersonalCard: true,
      }
    case '@/STOP_FETCHING_UPDATE_PERSONAL_CARD_USM':
      return {
        ...state,
        isFetchingPersonalCard: false,
      }

    case '@/SET_UPDATE_PERSONAL_CARD_RESPONSE_USM':
      return {
        ...state,
        error: null,
        personalCard: {
          userInfo: [...state.personalCard.userInfo],
          loginInfo: action.payload.loginInfo,
          personalInfo: action.payload.personalInfo,
        },
      }

    case '@/SET_UPDATE_PERSONAL_CARD_RESPONSE_FALL_USM':
      return {
        ...state,
        error: action.payload,
      }
    // add new user
    case '@/START_FETCHING_LOOKUP_CONFIG_CREATE_USER_USM':
      return {
        ...state,
        isFetchingAddNewUser: true,
      }
    case '@/STOP_FETCHING_LOOKUP_CONFIG_CREATE_USER_USM':
      return {
        ...state,
        isFetchingAddNewUser: false,
      }
    case '@/START_ADD_NEW_USER_USM':
      return {
        ...state,
        isFetchingAddNewUser: true,
      }
    case '@/STOP_ADD_NEW_USER_USM':
      return {
        ...state,
        isFetchingAddNewUser: false,
      }
    case '@/GET_ADD_USER_RESPONSE_USM':
      return {
        ...state,
        personalCard: action.payload,
        error: null,
      }
    case '@/GET_ADD_USER_RESPONSE_FALL_USM':
      return {
        ...state,
        error: action.payload,
      }

    case '@/SET_LOOKUP_CONFIG_CREATE_USER__RESPONSE_USM':
      return {
        ...state,
        error: null,
        lookupConfig: action.payload,
      }

    case '@/SET_LOOKUP_CONFIG_CREATE_USER__RESPONSE_FALL_USM':
      return {
        ...state,
        error: action.payload,
      }
    // change user
    case '@/START_CHANGE_USER_USM':
      return {
        ...state,
        isFetchingAdduserInfo: true,
      }
    case '@/STOP_CHANGE_USER_USM':
      return {
        ...state,
        isFetchingAdduserInfo: false,
      }
    case '@/GET_CHANGE_USER_RESPONSE_USM': {
      const index = state.personalCard.userInfo.findIndex(
        item => item.userId === action.payload.userId,
      )
      // temp
      if (!action.payload) {
        return state
      }
      return {
        ...state,
        error: null,
        personalCard: {
          loginInfo: { ...state.personalCard.loginInfo },
          personalInfo: { ...state.personalCard.personalInfo },
          userInfo: [
            ...state.personalCard.userInfo.slice(0, index),
            action.payload,
            ...state.personalCard.userInfo.slice(index + 1),
          ],
        },
      }
    }
    case '@/GET_CHANGE_USER_RESPONSE_FALL_USM':
      return {
        ...state,
        error: action.payload,
      }
    // add-user-to-user-info-block
    case '@/START_FETCHING_ADD_USER_USM':
      return {
        ...state,
        isFetchingAdduserInfo: true,
      }
    case '@/STOP_FETCHING_ADD_USER_USM':
      return {
        ...state,
        isFetchingAdduserInfo: false,
      }
    case '@/SET_ADD_USER_RESPONSE_USM':
      return {
        ...state,
        error: null,
        personalCard: {
          loginInfo: { ...state.personalCard.loginInfo },
          personalInfo: { ...state.personalCard.personalInfo },
          userInfo: [...state.personalCard.userInfo, action.payload],
        },
      }
    case '@/SET_ADD_USER_RESPONSE_FAIL_USM':
      return {
        ...state,
        error: action.payload,
      }

    // delete-user-info-block
    case '@/START_FETCHING_DELETE_USER_USER_INFO_USM':
      return {
        ...state,
        isFetchingAdduserInfo: true,
      }
    case '@/STOP_FETCHING_DELETE_USER_USER_INFO_USM':
      return {
        ...state,
        isFetchingAdduserInfo: false,
      }

    case '@/SET_DELETE_USER_INFO_RESPONSE_USM':
      return {
        ...state,
        error: null,
        personalCard:
          action.payload.length === state.personalCard.userInfo.length
            ? ({} as PersonalCardTypes)
            : {
                loginInfo: { ...state.personalCard.loginInfo },
                personalInfo: { ...state.personalCard.personalInfo },
                userInfo: state.personalCard.userInfo.filter(
                  u => !action.payload.includes(u.userId),
                ),
              },
      }
    case '@/CLEAN_ERRORS_USER_ASYNC_USM':
      return {
        ...state,
        error: null,
      }
    case '@/SET_DELETE_USER_INFO_RESPONSE_FAIL_USM':
      return {
        ...state,
        error: action.payload,
      }
    case '@/SET_CLEAR_ERROR_USERS_USM':
      return {
        ...state,
        error: null,
      }
    default:
      return state
  }
}
