export const SD_BLOCK_PREFIX = '/sd'
export const SRM_BLOCK_PREFIX = '/srm'
export const VAULT_BLOCK_PREFIX = '/vault'
export const VAULT_V1_BLOCK_PREFIX = '/vault-v1'
export const VAULT_V2_BLOCK_PREFIX = '/vault-v2'
export const CASH_MANAGEMENT_BLOCK_PREFIX = '/cash-management'
export const ATMEYE_BLOCK_PREFIX = '/atmeye'
export const ADM_BLOCK_PREFIX = '/adm'
export const USM_BLOCK_PREFIX = '/usm'
export const DICTIONARY_BLOCK_PREFIX = '/dictionaries'
export const COMPANIES_BLOCK_PREFIX = '/companies'
export const PRODUCTS_BLOCK_PREFIX = '/products'

export const [, cmBaseUrl] = CASH_MANAGEMENT_BLOCK_PREFIX.split('/')
export const [, vaultV1BaseUrl] = VAULT_V1_BLOCK_PREFIX.split('/')
export const [, vaultV2BaseUrl] = VAULT_V2_BLOCK_PREFIX.split('/')

// Place here Paths constants (router paths)
export const AppNavigationRoutes = {
  SignInPage: '/sign-in',
  ChangePassword: '/change-password',

  // Common routes
  MapPage: '/map',
  MapDetailsPage: '/map/details',

  // SD-BASE routes
  SDMainPage: `/web`,
  // SD-WEB routes
  TicketsPage: '/web/tickets',
  AllTicketsPage: '/web/tickets/all',
  HistoryTicketsPage: '/web/tickets/history',
  ReportsPage: '/web/reports',
  JasperReportsPage: '/web/jasper-reports',
  AllReportsPage: '/web/reports/all',
  ReportsLazyPage: '/web/reports/lazy',
  DashboardPage: '/web/dashboard',
  TicketPage: '/web/dashboard/id',
  HelpPage: '/web/help',

  // SRM routes
  FiscalRegistration: '/fiscal-registration',
  FiscalActionHistory: '/fiscal-action-history',
  TicketOverviewPage: '/tickets',
  SearchServicePage: '/search',
  DepartmentPage: '/department',
  GroupRequests: '/group',
  RepairNormsPage: '/norms',
  ImportDevicesPage: '/devices',
  CriticTerms: '/terms',
  NotifyService: '/notify',
  SelectScriptPage: '/script',
  Log: '/log',
  WorkSchedule: '/schedule',
  additionalCosts: '/costs',

  // SRM SearchServicePage sub routes
  ServiceRequestPage: '/servicerequest',
  PlannedWorksPage: '/plannedworks',
  ServiceWorksPage: '/serviceworks',
  ServiceDevice: '/servicedevice',
  Configuration: '/configuration',
  DeviceDefects: '/devicedefects',
  DeviceDefectsDetails: '/defectsdetails',
  WorksStatistics: '/worksstatisticks',

  // Vault routes
  VaultsManagementPage: '/vaults-monitoring',
  MonitoringPage: '/vaults-monitoring/monitoring',
  CashOrdersPage: '/vaults-monitoring/cash-orders',
  NightDepositoryPage: '/vaults-monitoring/night-depository',
  EventJournalPage: '/vaults-monitoring/event-journal',
  TeamsPage: '/vaults-monitoring/teams',
  BagsPage: '/vaults-monitoring/bags',

  // Cash Management
  CashManagement: {
    AnalysisPage: '/analysis',
    BalancingPage: '/balancing',
    CashOrdersPage: '/cash-orders',
    PlasticCardPage: '/plastic-cards',
    CitPlanningPage: '/cit-planning',
    CitBalancingPage: '/cit-balancing',
    MapsPage: '/maps',
    MonitoringPage: '/monitoring',
    PlanningPage: '/planning',
    ReplenishmentsPage: '/replenishments',
    AdminPage: '/control-panel',

    RoutesPage: '/routes',
  },

  // ATMEYE routes
  ATMeye: {
    dashboard: {
      root: '/dashboard',
    },
    devices: {
      root: '/devices',
      // tabs
      transactionsByDevice: '/0',
      alarms: '/1',
      events: '/2',
      properties: '/3',
      settings: '/4',
      actions: '/5',
      eventsSettings: '/4/1',
      atmeyeSettings: '/4/2',
      cameraDayNight: '/4/3',
    },
    transactions: {
      root: '/transactions',
    },
    securityAlarms: {
      root: '/alarms',
    },
    technicalEvents: {
      root: '/events',
    },
    map: {
      root: '/map',
    },
    reports: {
      root: '/reports',
    },
    rfm: {
      root: '/rfm',
      singleDevice: '/single-device',
      multipleDevices: '/multiple-devices',
      operationsLog: '/operations-log',
      synchronization: '/synchronization',
    },
    administration: {
      root: '/atmeye-administration',
      licenceList: '/licence-list',
      healthMonitor: '/health-monitor',
      logsInspector: '/logs-inspector',
      blackList: '/black-list',
      centralSettings: '/central-settings',
      multipleSnapshots: '/snapshots',
      eventDictionary: '/event-dictionary',
      externalNotifications: '/external-notifications',
      options: '/options',
      deviceSettings: '/device-settings',
    },
    help: {
      root: '/help',
    },
  },

  // USM routes
  USmUsersPage: '/users',
  USmGroupPermissions: '/group-permissions',
  USmSecurityNetworks: '/security-networks',
  USmActionLog: '/action-log',

  // ADM routes
  ADMDevicesList: '/devices',
  ADMDevices: '/devicesList',
  ADMImportDevices: '/import-devices',
  ADMImportDeviceConfiguration: '/import-configuration',
  ADMSearchConfiguration: '/configuration',
  ADMDeviceCard: '/device',
  ADMGroupActionChangeStatus: '/change-status',
  ADMGroupActionChangeOwner: '/change-owners',
  ADMGroupActionChangeServiceDates: '/change-service-dates',

  // Companies routes
  CompanyListPage: '/companylist',
  AdvancedSearchPage: '/advancedSearch',
  ImportCompaniesPage: '/import',
  CompaniesCompaniesPage: '/companiesPage',

  // Products routes
  ProductsListPage: '/products-list',
  ProductPage: '/product',
  ProductsImport: '/import',

  // Dictionaries routes
  DictionaryId: 'dictionaryId',

  // Vault V1

  VaultV1: {
    AnalysisPage: '/analysis',
    MonitoringPage: '/monitoring',
    OrdersPage: '/orders',
    TeamsPage: '/teams',
    Bags: '/bags',
    Cassettes: '/cassettes',
    EventJournal: '/event-journal',
    CashDesk: '/cash-desk',
    CashFlow: '/cash-flow',
    AdminPage: '/control-panel',
  },
}
