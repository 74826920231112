import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ palette, spacing }) => ({
  clearButton: {
    color: palette.grey[200],
  },
  errorLabel: {
    color: palette.error.main,
    display: 'flex',
    alignItems: 'center',

    '& > span': {
      paddingLeft: spacing(1),
    },
  },
}))
