import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { MapsApi } from '@/api/cm/mapsApi'
import { MapCashPoint } from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants'
import { setComCMError, setGlobalFilter } from '@/store/cm/common/actions'
import { AppState } from '@/store/reducers'
import { hotKeysBinder } from '@/utils/hotKeysBinder'

import {
  getCashPointsFail,
  getCashPointsRequest,
  getCashPointsResponse,
  getMapSelectedCashPointInformationResponse,
  handleDetailedInformationPanel,
  handleFilterModal,
  refreshMap,
  setMapCashPointsFilter,
  setMapInitialDetailedInformation,
} from './actions'

function* getCashPoints(): any {
  try {
    const storeFilter = yield select((state: AppState) => {
      return {
        globalFilter: state.cmCommon.globalFilter,
        filter: {
          ...state.cmMaps.cashPoints.filter,
          daysUntilEmpty: state.cmMaps.cashPoints.filter.daysUntilEmpty || -1,
        },
      }
    })

    const { globalFilter, filter } = storeFilter

    const response = yield call(MapsApi.getCashPoints, { globalFilter, filter })

    yield put(getCashPointsResponse(response))
    return response
  } catch (error) {
    yield put(getCashPointsFail(error))
  }
}

function* handleFilterSubmit({ payload }: AnyAction): SagaIterator {
  yield put(setGlobalFilter(payload.globalFilter))
  yield put(setMapCashPointsFilter(payload.mapFilter))
  yield put(setMapInitialDetailedInformation())

  yield put(getCashPointsRequest())
}

function* getDetailedInformation({ payload }: AnyAction): SagaIterator {
  yield put(handleDetailedInformationPanel(true))

  const response = yield call(MapsApi.getCashPointsDetailedInformation, payload)

  if (response) {
    yield put(
      getMapSelectedCashPointInformationResponse({ data: response.records, selectedId: payload }),
    )
  }
}

function* handleRefresh(): SagaIterator {
  try {
    yield put(getCashPointsRequest())

    const selectedCashPointData = yield select(
      (state: AppState) => state.cmMaps.selectedCashPoint.data,
    )
    const selectedCashPointId = yield select((state: AppState) => state.cmMaps.selectedCashPoint.id)
    const { data } = yield getCashPoints()

    if (selectedCashPointId && selectedCashPointData) {
      const isOldSelectedCashPoint = data.some(
        (cashPoint: MapCashPoint) => cashPoint.id === selectedCashPointId,
      )
      if (isOldSelectedCashPoint) {
        yield put(
          getMapSelectedCashPointInformationResponse({
            data: selectedCashPointData,
            selectedId: selectedCashPointId,
          }),
        )
      } else {
        yield put(setMapInitialDetailedInformation())
      }
    }
  } catch (e) {}
}

function* handleKeyPress({ payload }: AnyAction): SagaIterator {
  try {
    const { isMapFiltersModalOpen } = yield select((state: AppState) => state.cmMaps)
    const {
      isEditCassettesModalOpen,
      declineNoteState: { isModalOpen: isDeclineNoteModalOpen },
    } = yield select((state: AppState) => state.cmShared)

    hotKeysBinder({
      key: payload,
      isOpenModalsList: [isMapFiltersModalOpen, isEditCassettesModalOpen, isDeclineNoteModalOpen],
      openFilterAction: handleFilterModal,
      onRefreshAction: refreshMap,
    })
  } catch (error) {
    yield put(setComCMError(error))
  }
}

export default function*(): Generator {
  yield takeLatest(CMActions.MapCashPointsRequest, getCashPoints)
  yield takeLatest(CMActions.MapFilterSubmitRequest, handleFilterSubmit)
  yield takeLatest(CMActions.MapGetSelectedCashPointInformationRequest, getDetailedInformation)
  yield takeLatest(CMActions.MapHandleKeyPress, handleKeyPress)
  yield takeLatest(CMActions.MapRefresh, handleRefresh)
}
