import { AnyAction } from 'redux'

import { AppConfigTabs } from '@/constants'
import { CMAppConfigActions } from '@/constants/actions'

export type State = {
  selectedPanel: string
}

export const initialState: State = {
  selectedPanel: AppConfigTabs.AppConfiguration,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case CMAppConfigActions.AdmSetSelectedAppConfigPanel:
      return {
        ...state,
        selectedPanel: payload,
      }
    default:
      return state
  }
}
