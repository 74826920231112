import makeStyles from '@material-ui/core/styles/makeStyles'

import { styledComponentTheme } from '@/theme'

export const useClasses = makeStyles(({ spacing }) => ({
  markerDetailsItem: {
    padding: 0,
    margin: 0,
    width: '100%',
  },
  legendContainer: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '7px',
  },
  deviceContainer: {
    display: 'flex',
    gap: '20px',
    cursor: 'pointer',
  },
  devicesContainer: {
    maxHeight: '200px',
    minWidth: '200px',
    overflow: 'auto',
  },
  markerDetailsDivider: {
    margin: spacing(1, 0),
    width: '100%',
  },
  subhead: {
    fontSize: styledComponentTheme.fontSizes.small,
    fontWeight: 500,
    lineHeight: '16px',
    padding: 0,
  },
  device: {
    fontSize: styledComponentTheme.fontSizes.small,
  },
  currentDevice: {
    fontWeight: styledComponentTheme.fontWeights.bold,
  },
  button: {
    width: '100%',
  },
  legendItem: {
    userSelect: 'none',
    cursor: 'pointer',
    display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(0.25, 1),
    borderRadius: spacing(1.5),
  },
  legendItemCircle: {
    display: 'block',
    width: spacing(1),
    height: spacing(1),
    borderRadius: '50%',
    marginRight: spacing(1),
  },
  legendText: {
    marginRight: spacing(0.5),
    height: '1.5em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  badgeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  badge: {
    userSelect: 'none',
    display: 'flex',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    padding: spacing(0.25, 1),
    borderRadius: spacing(1.5),
  },
  badgeItem: {
    display: 'block',
    width: spacing(1),
    height: spacing(1),
    borderRadius: '50%',
  },
}))
