import { createSelector } from 'reselect'
import { AppState } from '@/store/reducers'
import { State, initialState } from './reducer'

export const getNotificationServiceRequestEventState = (state: AppState): State =>
  state.notificationsServiceRequestEvent || initialState

export const getNotificationServiceRequestEvent = createSelector(
  getNotificationServiceRequestEventState,
  state => state.isOpenModal,
)
