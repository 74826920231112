import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route } from 'react-router-dom'

import Loader from '@/components/blocks/Loader'
import SignInPage from '@/components/pages/common/SignInPage'
import { availableModules, UserRoles } from '@/constants/auth'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { AppNavigationRoutes } from '@/constants/paths'
import { history } from '@/Router'
import { signOut } from '@/store/auth/actions'
import { getCurrentAccount } from '@/store/auth/selectors'

import { Props } from './types'

const logoutAndRedirect = (
  redirectRoute: string = AppNavigationRoutes.SignInPage,
  dispatch: any,
): void => {
  dispatch(signOut())
  history.push(redirectRoute)
  window.location.reload()
}

const SecuredRoute = ({
  component,
  strategy,
  shouldWaitWithCheck,
  role,
  redirectComponent = SignInPage,
  redirectRoute = AppNavigationRoutes.SignInPage,
  path,
  location,
  ...rest
}: Props): React.ReactElement => {
  const [isAuth, setIsAuth] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()

  let account
  const currentAccount = useSelector(getCurrentAccount)
  const currentAccountFromLocalStorage = localStorage.getItem(LocalStorageItems.CurrentAccount)
  if (currentAccount) {
    account = currentAccount
  } else {
    account = currentAccountFromLocalStorage && JSON.parse(currentAccountFromLocalStorage)
  }

  useEffect((): void => {
    ;(async (): Promise<void> => {
      if (!shouldWaitWithCheck) {
        try {
          const strategyResult = await strategy(rest)
          setIsAuth(strategyResult)
        } catch (e) {
          setIsAuth(false)
        } finally {
          setIsLoading(false)
        }
      }
    })()
  }, [shouldWaitWithCheck, strategy, rest])

  const isAllowByRole = role ? account?.roleName === role : true
  const TargetComponent = isAuth && isAllowByRole ? component : redirectComponent
  const RouteComponent = isLoading ? Loader : TargetComponent

  if (location && account) {
    const moduleNames = Object.keys(availableModules)
    const moduleBeginPrefix = location.pathname.split('/')
    const availableModulesByPathPrefix = moduleNames.filter((moduleName: string) => {
      return availableModules[moduleName].path.startsWith(`/${moduleBeginPrefix[1]}`)
    })
    // .map((moduleName: string) => {
    //   return availableModules[moduleName]
    // })

    if (
      availableModulesByPathPrefix.length <= 0 ||
      !account.modules.includes(availableModulesByPathPrefix[0])
    ) {
      logoutAndRedirect(redirectRoute, dispatch)
    }
  }

  if (!isAllowByRole) {
    logoutAndRedirect(redirectRoute, dispatch)
  }

  return <Route {...rest} component={RouteComponent} />
}

export default SecuredRoute
