import MomentUtils from '@date-io/moment'
import { common } from '@material-ui/core/colors'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import clsx from 'clsx'
import { FieldProps } from 'formik'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Checkbox } from '@/components/controls/Checkbox'
import { DATE_FORMAT, DATE_TIME_SEC_FORMAT, DEFAULT_DATE_FORMAT } from '@/constants/time'
import { generateErrorText } from '@/helpers/generateErrorText'
import colors from '@/theme/colors'
import { getDate } from '@/utils'
import { useTranslate } from '@/utils/internalization'
import { ValidationService } from '@/utils/services/validationService'

import { useClasses } from './styles'
import {
  DateInputProps,
  FormikDatePickerFieldProps,
  FormikDatePickerProps,
  FormikDatePickerRangeProps,
} from './types'

const toolbarTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      dark: colors.primaryDark,
      contrastText: common.white,
    },
    secondary: {
      main: colors.secondary,
      contrastText: common.white,
    },
    // contrastThreshold: 0,
  },
})

export const DateInput = ({
  disableFutureDates,
  disablePast,
  error,
  helperText,
  className,
  maxDate,
  minDate,
  Icon,
  selectableTable,
  customFormat,
  classes: customClasses,
  format,
  ...props
}: DateInputProps): React.ReactElement => {
  const [isOpen, toogleDatePopover] = useState<boolean>(false)
  const classes = useClasses()

  const formatForMask = useMemo(() => moment().format(format || DEFAULT_DATE_FORMAT), [format])
  const defaultFormat = useMemo(() => format || customFormat?.format || DEFAULT_DATE_FORMAT, [
    format,
    customFormat,
  ])

  const mask = useMemo(() => {
    return (
      customFormat?.mask ||
      (formatForMask && formatForMask.replace(/[A-Za-z]/g, '_').replace(/[0-9]/g, '_'))
    )
  }, [customFormat, formatForMask])

  const handleOpenDatePicker = useCallback(() => {
    toogleDatePopover(true)
  }, [toogleDatePopover])

  const handleCloseDatePicker = useCallback(() => {
    toogleDatePopover(false)
  }, [toogleDatePopover])

  return (
    <ThemeProvider theme={toolbarTheme}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <KeyboardDatePicker
          {...props}
          size="small"
          variant="inline"
          margin="normal"
          onOpen={handleOpenDatePicker}
          onClose={handleCloseDatePicker}
          open={isOpen}
          onAccept={handleCloseDatePicker}
          maxDate={
            props.name === 'expire_date_filter' && selectableTable
              ? undefined
              : maxDate || disableFutureDates
              ? maxDate
              : undefined
          }
          minDate={minDate || disablePast ? minDate : undefined}
          inputVariant="outlined"
          className={clsx(classes.input, className, {
            [classes.inputWithError]: error,
          })}
          InputLabelProps={{
            className: clsx(classes.label, { [classes.errorLabel]: error }),
            shrink: true,
          }}
          InputProps={{
            className: customClasses?.input,
          }}
          InputAdornmentProps={{
            className: clsx(classes.icon, customClasses?.icon),
          }}
          keyboardIcon={Icon}
          mask={customFormat?.mask || mask}
          format={defaultFormat}
          disabled={props.disabled}
          error={error}
          helperText={
            helperText ? (
              <div className={classes.errorHelperText}>
                <FormattedMessage id={`form.validationWarning.${helperText}`} />
              </div>
            ) : null
          }
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export const FormikDatePicker = ({
  isWithoutFormatSecondDate,
  field,
  form: { errors, setFieldValue, getFieldHelpers },
  meta: { touched },
  label,
  className,
  format,
  isRange,
  placeholder,
  index,
  withoutCurrentDate,
  disabled,
  disabledPast = false,
  disableFutureDates,
  withoutArrayDate,
  dynamicDateValue,
  Icon,
  setDynamicDate,
  minTomorrowDate,
  selectableTable,
  ...other
}: FormikDatePickerProps): React.ReactElement => {
  const [toDateIsHidden, setToDateIsHidden] = useState<boolean>(false)
  const maxDate =
    dynamicDateValue && dynamicDateValue.end ? new Date(dynamicDateValue.end) : new Date()

  const minDate =
    dynamicDateValue && dynamicDateValue.start ? new Date(dynamicDateValue.start) : new Date()
  const classes = useClasses()

  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)

  const updateDynamicDate = (date: string) => {
    if (setDynamicDate && disableFutureDates) {
      setDynamicDate(prevState => ({
        end: prevState.end,
        start: date,
      }))
    }

    if (setDynamicDate && disabledPast) {
      setDynamicDate(prevState => ({
        start: prevState.start,
        end: date,
      }))
    }
  }

  const handleDateChange = useCallback(
    (momentDate, date): void => {
      if (!date) {
        if (isRange && index !== undefined) {
          const newValue = [...field.value]
          newValue[index] = null
          setFieldValue(field.name, newValue, true)
        } else {
          setFieldValue(field.name, null, true)
          updateDynamicDate('')
        }
      }

      if (date && !date.includes('_')) {
        if (isRange && index !== undefined) {
          if (index === 1) setToDateIsHidden(false)
          const newValue = [...field.value]
          newValue[index] = momentDate.isValid()
            ? momentDate.format(DATE_FORMAT)
            : moment().format(DATE_FORMAT)
          setFieldValue(field.name, newValue, true)
        } else {
          const defaultDate = (minTomorrowDate
            ? moment()
                .clone()
                .add(1, 'days')
            : moment()
          ).format(DATE_FORMAT)

          const fieldValue = momentDate.isValid() ? momentDate.format(DATE_FORMAT) : defaultDate

          getFieldHelpers(field.name).setTouched(true)
          setFieldValue(field.name, fieldValue, true)
          updateDynamicDate(fieldValue)
        }
      }
    },
    [setFieldValue, field.value],
  )

  const value = useMemo(() => {
    if (isWithoutFormatSecondDate && index !== undefined) {
      return field.value?.[index] ? field.value[index] : null
    }

    if (toDateIsHidden) {
      return null
    }

    if (withoutCurrentDate && index !== undefined && !field.value) {
      return null
    }

    if (isRange && index !== undefined) {
      const startDate = new Date(field.value && field.value[0])
      const endDate = new Date(field.value && field.value[1])

      const validateDate = ValidationService.isInvalidDateInterval(startDate, endDate)

      return field.value && field.value[index] && !validateDate
        ? field.value[index]
        : validateDate
        ? field.name === 'expire_date_filter' && selectableTable
          ? field.value[index]
          : field.value[index]
        : null
      // return field.value[index]
      //   ? field.value[index] : null
    }
    return field.value ? field.value : null
  }, [field.value, toDateIsHidden])

  useEffect(() => {
    if (index === 1 && disabled) {
      setFieldValue(field.name, [null, null], false)
    }
  }, [index, disabled])

  useEffect(() => {
    if (
      field.value &&
      (withoutArrayDate ? field.value && !field.value[1] : field.value[0] && !field.value[1]) &&
      index === 1
    ) {
      const date = moment(new Date()).format(DATE_TIME_SEC_FORMAT)
      setToDateIsHidden(true)
      !isWithoutFormatSecondDate && setFieldValue(field.name, [field.value[0], date], false)
    }
  }, [field, index])

  const handleBlur = useCallback((): void => {
    if (index !== undefined && field.value[index]) {
      const currentDate = moment(field.value[index]).unix()
      const dateNow = getDate().unix()

      if (disableFutureDates && dateNow < currentDate) {
        const newValue = [...field.value]
        newValue[index] = moment()

        setFieldValue(field.name, newValue, true)
      }
    }

    getFieldHelpers(field.name).setTouched(true)
  }, [getFieldHelpers, field.name, field.value, index, setFieldValue, disableFutureDates])

  return (
    <DateInput
      label={label}
      className={className}
      disablePast={disabledPast}
      name={field.name}
      value={value}
      onChange={handleDateChange}
      format={format}
      placeholder={placeholder}
      onBlur={handleBlur}
      maxDate={disableFutureDates ? maxDate : null}
      // minDate={disabledPast ? minDate : undefined}
      // temp
      minDate={disabledPast && minTomorrowDate ? tomorrow : disabledPast ? minDate : undefined}
      disableFutureDates={disableFutureDates}
      helperText={generateErrorText(errors, field.name, true)}
      error={!!errors[field.name] && touched}
      {...other}
      InputLabelProps={{
        shrink: true,
        className: classes.asterisk,
      }}
      disabled={disabled}
      Icon={Icon}
      selectableTable={selectableTable}
    />
  )
}

export const createDatePickerRangeField = ({
  isWithCheckbox,
  isWithoutFormatSecondDate,
  isAtemeyFilterModal,
  checkboxValue,
  title,
  labelDate,
  format,
  titleClass,
  wrapperDateClass,
  widthClass,
  withoutCurrentDate,
  disabled,
  disableFutureDates = true,
  dateLimitForReports,
  selectableTable,
  onChangeCheckbox,
  maxDateTo,
}: FormikDatePickerRangeProps) => (formikProps: FieldProps): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  const maxDateLimit = useMemo(() => {
    if (disableFutureDates) return new Date()

    if (formikProps.field.value && formikProps.field.value[1]) {
      return new Date(formikProps.field.value[1])
    }
    return undefined
  }, [disableFutureDates, formikProps])

  return (
    <div
      className={clsx(classes.range, {
        [classes.rangeFullWidth]: selectableTable || isAtemeyFilterModal,
      })}
    >
      {title && <span className={titleClass}>{title}</span>}
      <div
        className={clsx(wrapperDateClass, isWithCheckbox ? classes.rangeColumn : classes.rangeRow)}
      >
        <FormikDatePicker
          {...formikProps}
          index={0}
          isRange
          format={format}
          label={labelDate ? labelDate.begin : translate('translate#title.from')}
          className={clsx(classes.mr, widthClass, isWithCheckbox && classes.dateFrom)}
          maxDate={maxDateLimit}
          withoutCurrentDate={withoutCurrentDate}
          disabled={disabled}
          disableFutureDates={disableFutureDates}
          selectableTable={selectableTable}
        />

        <div
          className={clsx(classes.range, {
            [classes.rangeRow]: isWithCheckbox,
          })}
        >
          {isWithCheckbox && onChangeCheckbox && (
            <Checkbox
              className={classes.checkbox}
              value={checkboxValue}
              onChange={() => onChangeCheckbox(!checkboxValue)}
            />
          )}

          <FormikDatePicker
            {...formikProps}
            isWithoutFormatSecondDate={isWithoutFormatSecondDate}
            index={1}
            isRange
            format={format}
            label={labelDate ? labelDate.end : translate('translate#title.to')}
            className={clsx(widthClass, isWithCheckbox && !checkboxValue && classes.disabled)}
            minDate={
              formikProps.field.value &&
              formikProps.field.value[0] &&
              new Date(formikProps.field.value[0])
            }
            maxDate={maxDateTo || (!dateLimitForReports ? maxDateLimit : undefined)}
            withoutCurrentDate={withoutCurrentDate}
            initialFocusedDate={
              formikProps.field.value &&
              formikProps.field.value[0] &&
              new Date(formikProps.field.value[0])
            }
            disabled={disabled || (formikProps.field.value && !formikProps.field.value[0])}
            disableFutureDates={disableFutureDates}
            selectableTable={selectableTable}
          />
        </div>
      </div>
    </div>
  )
}

export const createDatePickerField = ({
  label,
  format,
  widthClass,
  wrapperDateClass,
  disabled,
  required,
  withoutCurrentDate,
  placeholder,
  disabledPast,
  disableFutureDates = true,
  withoutArrayDate,
  dynamicDateValue,
  Icon,
  setDynamicDate,
  minTomorrowDate,
  ...restProps
}: FormikDatePickerFieldProps) => (formikProps: FieldProps): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  return (
    <div className={wrapperDateClass}>
      <FormikDatePicker
        {...formikProps}
        {...restProps}
        index={0}
        format={format}
        label={label || translate('translate#title.from')}
        className={clsx(classes.mr, widthClass)}
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        withoutCurrentDate={withoutCurrentDate}
        disableFutureDates={disableFutureDates}
        disabledPast={disabledPast}
        withoutArrayDate={withoutArrayDate}
        dynamicDateValue={dynamicDateValue}
        Icon={Icon}
        setDynamicDate={setDynamicDate}
        minTomorrowDate={minTomorrowDate}
      />
    </div>
  )
}
