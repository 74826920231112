import { Box, Grid, Typography } from '@material-ui/core'
import React, { ReactElement, useState } from 'react'

import { Badge } from '@/components/blocks/ATMeye/Badge'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import { Props } from './types'

export const MarkerContent = ({
  techEventsCount,
  securityAlarmsCount,
  devices,
  total,
  onSetCurrentDeviceId,
  transactionsCount,
  currentDevice,
}: Props): ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  const [eventClick, setEventClick] = useState(false)
  const [alarmClick, setAlarmClick] = useState(false)
  return (
    <Grid container direction="column" alignItems="flex-start">
      <Box className={classes.mainContainer}>
        <Box>
          <Typography className={classes.subhead}>
            {translate('translate#atmeye.devices.title.totalDevices')}: {total}
          </Typography>
        </Box>

        <div className={classes.legendItem} style={{ backgroundColor: '#E6FAF0' }}>
          <Box>
            <div className={classes.legendItemCircle} style={{ backgroundColor: '#00C562' }} />
          </Box>
          <div className={classes.legendText}>
            {translate('translate#atmeye.title.transactions')}
          </div>
          <div>({transactionsCount})</div>
        </div>

        <Box className={classes.legendContainer}>
          {!!techEventsCount && (
            <div
              className={classes.legendItem}
              style={{ backgroundColor: !eventClick ? '#FFFCEF' : '#FEDA59' }}
              onClick={(): void => {
                setEventClick(value => !value)
              }}
            >
              <Box>
                <div
                  className={classes.legendItemCircle}
                  style={{ backgroundColor: !eventClick ? '#FEDA59' : '#FFFCEF' }}
                />
              </Box>

              <div className={classes.legendText}>
                {translate('translate#atmeye.title.devices.techEvents')}
              </div>

              <div>({techEventsCount})</div>
            </div>
          )}

          {!!securityAlarmsCount && (
            <div
              className={classes.legendItem}
              style={{ backgroundColor: !alarmClick ? '#FDEFED' : '#FF7362' }}
              onClick={() => {
                setAlarmClick(value => !value)
              }}
            >
              <Box>
                <div
                  className={classes.legendItemCircle}
                  style={{ backgroundColor: !alarmClick ? '#FF7362' : '#FDEFED' }}
                />
              </Box>

              <div className={classes.legendText}>
                {translate('translate#atmeye.title.devices.secAlarms')}
              </div>

              <div>({securityAlarmsCount})</div>
            </div>
          )}
        </Box>
        {devices && (
          <>
            <Box>
              <Typography className={classes.subhead}>
                {translate('translate#atmeye.title.devices')}
              </Typography>
            </Box>
            <Box className={classes.devicesContainer}>
              {devices
                .filter(({ type }) => {
                  if (!eventClick && !alarmClick) return true
                  if (type === 'ALL_EVENTS') return true
                  if (eventClick && type === 'TECHNICAL_EVENT') return true
                  if (alarmClick && type === 'SECURITY_ALARM') return true

                  return false
                })
                .map(({ name, id, type }) => (
                  <Box
                    className={classes.deviceContainer}
                    key={id}
                    onClick={onSetCurrentDeviceId({ deviceId: +id, deviceName: name })}
                  >
                    <Box>
                      <Typography
                        className={
                          id === currentDevice?.deviceId.toString() ? classes.currentDevice : ''
                        }
                      >
                        {name}
                      </Typography>
                    </Box>
                    <Box className={classes.badgeContainer}>
                      {(type === 'TECHNICAL_EVENT' || type === 'ALL_EVENTS') && (
                        <Badge variant="event" />
                      )}
                      {(type === 'SECURITY_ALARM' || type === 'ALL_EVENTS') && (
                        <Badge variant="alarm" />
                      )}
                    </Box>
                  </Box>
                ))}
            </Box>
          </>
        )}
      </Box>
    </Grid>
  )
}
