import { IconButton, MenuItem, Select } from '@material-ui/core'
import TablePagination from '@material-ui/core/TablePagination'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import clsx from 'clsx'
import React, { ChangeEvent, ReactElement, useCallback, useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import { TablePaginationSearchFormContext } from '@/context'
import { useTranslate } from '@/utils/internalization'

import InputPagination from './components/InputPagination'
import TablePaginationActions from './components/TablePaginationActions'
import { useClasses } from './styles'
import { Props } from './types'

const Pagination = ({
  isLoadingPagination,
  isCheckboxTable,
  totalPage,
  page,
  rowsPerPage,
  ticketsTotalCount,
  changePage,
  changeRowsPerPage,
  rowsPerPageOptions,
  positionInit = false,
  countPageWithEmptyData,
  selectableTable,
  controls,
  showPagination = true,
  isOnlyDirectionPagination,
  elementsCount,
}: Props): ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const { loadingPaginationData } = useContext(TablePaginationSearchFormContext)
  const handleChangePage = useCallback((event: unknown, page: number) => changePage(page), [
    changePage,
  ])

  const spinner = (): ReactElement => {
    return (
      <div className={classes.root}>
        <div className={classes.skeletonHead} />
      </div>
    )
  }

  const handleChangeRowsPerPage = useCallback(
    (event: any | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      changeRowsPerPage(parseInt(event.target.value)),
    [changeRowsPerPage],
  )

  return (
    <div
      className={clsx(classes.paginationWrapper, {
        [classes.positionInit]: positionInit,
        [classes.positionStart]: selectableTable,
      })}
    >
      {selectableTable && controls}
      <div
        className={clsx(classes.paginationItemsWrapper, {
          [classes.wrapperFullWidth]: isCheckboxTable,
        })}
      >
        {loadingPaginationData || isLoadingPagination ? (
          spinner()
        ) : isOnlyDirectionPagination ? (
          <div className={classes.prevNextPaginationWrapper}>
            <div>
              {translate('translate#title.rowsPerPage')}{' '}
              <Select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                {(rowsPerPageOptions || [5, 10, 15]).map(val => {
                  return (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  )
                })}
              </Select>
            </div>
            <div className={classes.prevNextButtonsGroup}>
              <IconButton
                disabled={page === 1}
                onClick={() => {
                  handleChangePage('', page > 1 ? page - 1 : 1)
                }}
              >
                <KeyboardArrowLeftIcon fontSize="medium" />
              </IconButton>
              <IconButton
                disabled={elementsCount ? elementsCount < rowsPerPage : true}
                onClick={() => {
                  handleChangePage('', page + 1)
                }}
              >
                <KeyboardArrowRightIcon fontSize="medium" />
              </IconButton>
            </div>
          </div>
        ) : (
          <>
            <div className={classes.elements}>
              <span>
                <FormattedMessage id="title.total" defaultMessage="Total" />: {ticketsTotalCount}
              </span>
            </div>

            {showPagination && ticketsTotalCount >= 0 && (
              <TablePagination
                ActionsComponent={({ onPageChange }): ReactElement => (
                  <>
                    <TablePaginationActions
                      totalPage={totalPage}
                      onChangePage={handleChangePage}
                      page={page}
                      countPageWithEmptyData={countPageWithEmptyData}
                    />
                    <InputPagination totalPages={totalPage} onChangePage={onPageChange} />
                  </>
                )}
                component="div"
                count={ticketsTotalCount}
                classes={{
                  spacer: classes.spacer,
                  menuItem: classes.select,
                  input: classes.input,
                  selectIcon: classes.selectIcon,
                  toolbar: classes.toolbar,
                  caption: classes.caption,
                  select: classes.selects,
                }}
                rowsPerPageOptions={rowsPerPageOptions || [5, 10, 15]}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={translate('translate#title.rowsPerPage')}
                page={page}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
                SelectProps={{
                  native: false,
                }}
                colSpan={1}
                labelDisplayedRows={(): ReactElement => <></>}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Pagination
