import { MouseEvent, useCallback, useState } from 'react'
import { DataItem } from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/components/EditableTable/types'

export const useUserInfoTable = (filterItemsInBLL?: (id: string) => void, notSelected = false) => {
  const [selectedRows, setSelectedRows] = useState<DataItem[]>([])
  const [deletedRows, setDeletedRows] = useState<DataItem[]>([])
  const [newRows, setNewRows] = useState<DataItem[]>([])
  const [changedRows, setChangedRows] = useState<DataItem[]>([])
  const handleSelectRow = useCallback(
    (row: DataItem) => (event: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>): void => {
      if (!notSelected) {
        if (selectedRows.includes(row)) {
          return setSelectedRows(rows => rows.filter(selectedRow => selectedRow !== row))
        }

        setSelectedRows(rows => [...rows, row])
      }
    },
    [selectedRows],
  )

  const handleDeleteRows = useCallback(() => {
    if (newRows.some(newRow => selectedRows.includes(newRow))) {
      setNewRows(rows => rows.filter(row => !selectedRows.includes(row)))
    }

    const filteredSelectedRows: DataItem[] = []
    let newDeletedRows = [...deletedRows]

    newDeletedRows = newDeletedRows.filter(deleted => {
      if (selectedRows.includes(deleted)) {
        filteredSelectedRows.push(deleted)
        return false
      }

      return true
    })

    setDeletedRows([
      ...newDeletedRows,
      ...selectedRows.filter(selected => !filteredSelectedRows.includes(selected)),
    ])
    setSelectedRows([])
  }, [selectedRows, deletedRows, newRows])

  // Rewrite with API
  const handleAddNewRow = useCallback((rowTemplate: any): void => {
    setNewRows(rows => [...rows, rowTemplate])
  }, [])

  const handleChangeRow = useCallback(
    (newRow?: boolean) => (row: DataItem, changed: boolean): void => {
      if (selectedRows.find(el => el.id === row.id)) {
        filterItemsInBLL && filterItemsInBLL(row.id)
        return setSelectedRows(rows => rows.filter(selectedRow => selectedRow.id !== row.id))
      }

      setSelectedRows(rows => [...rows, row])
    },
    [changedRows, newRows, selectedRows],
  )

  return {
    handleSelectRow,
    handleDeleteRows,
    handleAddNewRow,
    handleChangeRow,
    selectedRows,
    deletedRows,
    newRows,
    changedRows,
    setSelectedRows,
  }
}
