import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography, Paper } from '@material-ui/core'
import { Props, TableBodyCell, TableBodyCellPristine, TableFilter } from './types'
import { useClasses } from './styles'
import TableFooter from './components/TableFooter'
import { replaceMultipleValuesWithExtraRow } from './utils/replaceMultipleValuesWithExtraRow'
import { getUniqueValuesFromRow } from './utils/getUniqueValuesFromRow'
import { groupRowsByField } from './utils/groupRowsByField'
import ManageableTable from './components/ManageableTable'
import { getTableColumnsOrder } from '@/store/common/tables'
import { getValueOfCell } from '@/components/controls/AppTable/utils/getValueOfCell'
import Pagination from '@/components/controls/AppTable/components/Pagination'
import { Order } from '@/types'

const rowsPerPageInitial = 5

const AppTable = ({
  data,
  title,
  headerLeft,
  footer,
  enablePaging = false,
  idColumnKey,
  columnDisplayOrder,
  initialFilter,
  initialGroupBy,
  initialOrderKey,
  initialOrder,
  initialRowsPerPage,
  totalRow,
  subHeaderRow,
  columnsWithTranslate,
  onParamChange,
  setColumnDisplayOrder,
  isLoading,
  tableContainerClassName,
  tableClasses,
  doubleColumn,
  slaStyles,
  positionInit,
  withTooltip,
  stickyHeader = true,
  withoutTranslate,
  areDatesInTable = true,
  tableId,
  prefix,
  ...tableProps
}: Props): React.ReactElement => {
  const classes = useClasses()

  const [keys, setKeys] = useState(columnDisplayOrder || Object.keys(data[0] || []))
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage || rowsPerPageInitial)
  const [order, setOrder] = useState<Order>(initialOrder || undefined)
  const [orderBy, setOrderBy] = useState<string | undefined>(initialOrderKey)
  const [page, setPage] = useState(0)
  const [filter, setFilter] = useState<TableFilter>(initialFilter || {})

  const [groupBy] = useState<null | string>(initialGroupBy || null)

  const tableColumnsOrder = useSelector(getTableColumnsOrder)

  useEffect(() => {
    const columnOrder = tableColumnsOrder.filter(tableData => tableData.tableId === tableId)

    if (columnOrder.length) {
      setKeys(columnOrder[0].columns)
      return
    }

    if (columnDisplayOrder || data.length) {
      setKeys(columnDisplayOrder || Object.keys(data[0]))
    }
  }, [columnDisplayOrder, data, tableId, tableColumnsOrder, setKeys])

  useEffect((): void => {
    if (initialFilter) {
      setFilter(initialFilter)
    }
  }, [initialFilter])

  const rowsUniqueValues = useMemo(
    () => getUniqueValuesFromRow(groupBy ? [groupBy, ...keys] : keys, data),
    [groupBy, keys, data],
  )

  const sortedData = useMemo(() => {
    const newData = [...data]

    if (orderBy && !order) {
      return data
    }

    if (orderBy) {
      newData.sort((a: TableBodyCell, b: TableBodyCell) => {
        const aValue = getValueOfCell(a[orderBy])
        const bValue = getValueOfCell(b[orderBy])
        if (!+aValue && !+bValue) {
          if (aValue === bValue) {
            return 0
          }

          return order === 'asc' ? (aValue > bValue ? 1 : -1) : aValue > bValue ? -1 : 1
        }
        return order === 'asc' ? +aValue - +bValue : +bValue - +aValue
      })
    }
    return newData
  }, [data, orderBy, order])

  const sortedAndGroupedData = useMemo((): TableBodyCell[] => {
    if (groupBy) {
      return groupRowsByField(
        rowsUniqueValues[groupBy],
        sortedData,
        groupBy,
        keys[0],
        filter[groupBy],
      )
    } else {
      return sortedData
    }
  }, [sortedData, groupBy, rowsUniqueValues, keys, filter])

  const sortedGroupedAndPagedData = useMemo(() => {
    let newData = [...sortedAndGroupedData]
    if (enablePaging) {
      newData = newData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }
    return newData
  }, [sortedAndGroupedData, enablePaging, page, rowsPerPage])

  const sortedGroupedPagedAndFormattedData = useMemo((): Map<string, TableBodyCell[]> => {
    const forReturn = new Map<string, TableBodyCell[]>()
    sortedGroupedAndPagedData.forEach((row: TableBodyCellPristine, i) => {
      const fieldName = idColumnKey ? row[idColumnKey] : i
      const computedValue = replaceMultipleValuesWithExtraRow({ ...row })
      forReturn.set(fieldName, computedValue)
    }, {})
    return forReturn
  }, [sortedGroupedAndPagedData, idColumnKey])

  const onFilterChange = useCallback(
    (filter: TableFilter) => {
      setFilter(filter)
      setPage(0)
      if (onParamChange) {
        onParamChange('filter', filter)
      }
    },
    [onParamChange],
  )

  const onOrderChange = useCallback(
    (order: 'asc' | 'desc' | undefined, orderBy: string) => {
      setOrder(order)
      setOrderBy(orderBy)
      if (onParamChange && orderBy) {
        onParamChange('sort', { [orderBy]: order })
      }
    },
    [onParamChange],
  )

  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      setPage(newPage)
      if (onParamChange) {
        onParamChange('pagination', { rowsAmount: rowsPerPage, position: newPage * rowsPerPage })
      }
    },
    [rowsPerPage, onParamChange],
  )

  const onChangeRowsPerPage = useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >(
    (e): void => {
      setRowsPerPage(+e.target.value)
      if (onParamChange) {
        onParamChange('pagination', { rowsAmount: +e.target.value, position: 0 })
      }
    },
    [onParamChange],
  )

  return (
    <>
      {(title || headerLeft) && (
        <div className={classes.header}>
          <Box fontWeight="fontWeightBold">
            <Typography variant="h5" className={classes.title}>
              {title}
            </Typography>
          </Box>
          {headerLeft}
        </div>
      )}
      <Box
        display="flex"
        flex="1"
        overflow="auto"
        flexDirection="column"
        justifyContent="space-between"
        className={tableClasses?.root}
      >
        <Paper
          className={classes.tableWrapper}
          classes={{ root: tableClasses?.paper }}
          elevation={2}
        >
          <ManageableTable
            tableId={tableId}
            data={sortedGroupedPagedAndFormattedData}
            columnDisplayOrder={keys}
            setColumnOrder={setColumnDisplayOrder || setKeys}
            onFilterChange={onFilterChange}
            onOrderChange={onOrderChange}
            order={order}
            orderBy={orderBy}
            filter={filter}
            groupBy={groupBy}
            totalRow={totalRow}
            subHeaderRow={subHeaderRow}
            {...tableProps}
            isDataLoading={isLoading}
            doubleColumn={doubleColumn}
            slaStyles={slaStyles}
            tableContainerClassName={tableContainerClassName}
            tableClasses={{
              table: tableClasses?.table,
              actions: tableClasses?.actions,
              container: tableClasses?.container,
              headColumn: tableClasses?.headColumn,
              headColumnCell: tableClasses?.headColumnCell,
              row: tableClasses?.row,
              cell: tableClasses?.cell,
            }}
            withTooltip={withTooltip}
            stickyHeader={stickyHeader}
            withoutTranslate={withoutTranslate}
            columnsWithTranslate={columnsWithTranslate}
            areDatesInTable={areDatesInTable}
            prefix={prefix}
          />
        </Paper>
        {footer && <TableFooter>{footer}</TableFooter>}
        {enablePaging && (
          <Pagination
            count={data.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            handleChangePage={handleChangePage}
            positionInit={positionInit}
          />
        )}
      </Box>
    </>
  )
}

export default AppTable
