import React, { FC, useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { Field } from 'formik'
import { LoginField } from '@/components/pages/usm/UsersPage/components/PersonalInformation/types'
import { SelectUSM } from '@/components/pages/usm/UsersPage/components/PersonalInformation/helpers/gnericSelect'
// types
import { Props } from './types'
// import { tryCountData } from '@/components/pages/usm/UsersPage/components/PersonalInformation/helpers/testData/testData'
import { useTranslate } from '@/utils/internalization'

const getTryCountData = (size: number) => {
  return Array.from(Array(size + 1).keys()).map(i => ({
    value: String(i),
    name: String(i),
  }))
}

const TryCountField: FC<Props> = ({ tryCountDefault, style, size }: Props) => {
  const translate = useTranslate()

  const [tryCountData, setTryCountData] = useState(getTryCountData(tryCountDefault))

  useEffect(() => {
    setTryCountData(getTryCountData(tryCountDefault))
  }, [tryCountDefault])

  return (
    <Grid item xs={size} classes={{ root: style }}>
      <Field name={LoginField.TryCount}>
        {SelectUSM({
          disabled: true,
          required: true,
          label: translate('translate#usm.title.add.user.modal.label.try.count'),
          placeholder: 'Select Try Count',
          options: tryCountData,
        })}
      </Field>
    </Grid>
  )
}
export { TryCountField }
