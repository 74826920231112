import queryString from 'query-string'

import {
  AdmAtmLimit,
  AdmAtmLimitBase,
  AdmKioskLimit,
  AdmKioskLimitBase,
  AdmLimitsRequest,
} from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

interface GetProps {
  sort: Sort
  filter: AdmLimitsRequest
}

export class LimitsApi {
  private static requestService = getRequestServiceCM()

  public static async getAtm({ filter, sort }: GetProps): Promise<AdmAtmLimit[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/atm/limits',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await LimitsApi.requestService.post(url, filter)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async addAtm(payload: AdmAtmLimitBase): Promise<void> {
    try {
      return await LimitsApi.requestService.post('/admin/atm/limits/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getKiosk({ filter, sort }: GetProps): Promise<AdmKioskLimit[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/kiosk/limits',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await LimitsApi.requestService.post(url, filter)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async addKiosk(payload: AdmKioskLimitBase): Promise<void> {
    try {
      return await LimitsApi.requestService.post('/admin/kiosk/limits/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }
}
