export enum TicketActions {
  GetTicketRequest = '@/GET_TICKETS_REQUEST',
  GetTicketResponse = '@/GET_TICKETS_RESPONSE',
  GetTicketResponseFail = '@/GET_TICKETS_RESPONSE_FAIL',

  GetGroupDetailsRequest = '@/GET_GROUP_DETAILS_REQUEST',
  GetGroupDetailsResponse = '@/GET_GROUP_DETAILS_RESPONSE',
  GetGroupDetailsResponseFail = '@/GET_GROUP_DETAILS_RESPONSE_FAIL',
  SetGroupDetailsResponse = '@/SET_GROUP_DETAILS_RESPONSE',
  SetGroupDetailsResponseEmpty = '@/SET_GROUP_DETAILS_RESPONSE_EMPTY',

  SetParentGroups = '@/SET_PARENT_GROUPS',

  SetSelectedTicketId = '@/SET_SELECTED_TICKET_ID',
  GetTicketDetailsRequest = '@/GET_TICKET_DETAILS_REQUEST',
  GetTicketDetailsResponse = '@/GET_TICKET_DETAILS_RESPONSE',
  GetTicketDetailsResponseFail = '@/GET_TICKET_DETAILS_RESPONSE_FAIL',

  ChangeSelectedFilters = '@/CHANGE_SELECTED_FILTERS',
  ClearSelectedFilters = '@/CLEAR_SELECTED_FILTERS',

  GetTicketByIdRequest = '@/GET_TICKET_BY_ID_REQUEST',
  GetTicketByIdResponse = '@/GET_TICKETS_BY_ID_RESPONSE',
  GetTicketByIdResponseFail = '@/GET_TICKETS_BY_ID_RESPONSE_FAIL',

  DeleteTicketRequest = '@/DELETE_TICKET_REQUEST',
  DeleteTicketResponse = '@/DELETE_TICKET_RESPONSE',
  DeleteTicketResponseFail = '@/DELETE_TICKET_RESPONSE',

  DeleteFilterTemplateRequest = '@/DELETE_FILTER_TEMPLATE_REQUEST',
  DeleteFilterTemplateResponse = '@/DELETE_FILTER_TEMPLATE_RESPONSE',
  DeleteFilterTemplateResponseFail = '@/DELETE_FILTER_TEMPLATE_RESPONSE',

  SaveTicketViewSettingsRequest = '@/SAVE_TICKET_VIEW_SETTINGS_REQUEST',
  SaveTicketViewSettingsResponse = '@/SAVE_TICKET_VIEW_SETTINGS_RESPONSE',
  SaveTicketViewSettingsResponseFail = '@/SAVE_TICKET_VIEW_SETTINGS_RESPONSE_FAIL',

  GetSdGroupTemplatesRequest = '@/GET_SD_GROUP_TEMPLATES_REQUEST',
  GetSdGroupTemplatesResponse = '@/GET_SD_GROUP_TEMPLATES_RESPONSE',

  EditSdGroupTemplateRequest = '@/GET_SD_GROUP_TEMPLATE_REQUEST',
  EditSdGroupTemplateFromResponse = '@/EDIT_SD_GROUP_TEMPLATE_FROM_RESPONSE',

  DeleteSdGroupTemplateRequest = '@/DELETE_SD_GROUP_TEMPLATE_REQUEST',
  DeleteSdGroupTemplateResponseSuccess = '@/DELETE_SD_GROUP_TEMPLATE_RESPONSE_SUCCESS',

  GetGroupingTemplatesRequest = '@/GET_GROUPING_TEMPLATES_REQUEST',
  GetGroupingTemplatesResponse = '@/GET_GROUPING_TEMPLATES_RESPONSE',
  GetGroupingTemplatesResponseFail = '@/GET_GROUPING_TEMPLATES_RESPONSE_FAIL',

  SetGroupingPageSize = '@/SET_GROPING_PAGE_SIZE',

  CollectFilterTemplateFields = '@/COLLECT_FILTER_TEMPLATE_FIELDS',

  GetSdFilterTemplatesRequest = '@/GET_SD_FILTER_TEMPLATE_REQUEST',
  SetSdFilterTemplateResponse = '@/SET_SD_FILTER_TEMPLATE_RESPONSE',

  CreateSdFilterTemplateRequest = '@/CREATE_SD_FILTER_TEMPLATE_REQUEST',
  CreateSdFilterTemplateResponse = '@/CREATE_SD_FILTER_TEMPLATE_RESPONSE',
  UpdateSdSelectedFilters = '@/UPDATE_SD_SELECTED_FILTERS',

  UpdateSdFilterTemplateRequest = '@/UPDATE_SD_FILTER_TEMPLATE_REQUEST',
  UpdateSdFilterTemplateResponse = '@/UPDATE_SD_FILTER_TEMPLATE_RESPONSE',

  DeleteSdFilterTemplateRequest = '@/DELETE_SD_FILTER_TEMPLATE_REQUEST',
  DeleteSdFilterTemplateResponse = '@/DELETE_SD_FILTER_TEMPLATE_RESPONSE',

  GetFiltersTemplatesRequest = '@/GET_FILTERS_TEMPLATES_REQUEST',
  GetFiltersTemplatesResponse = '@/GET_FILTERS_TEMPLATES_RESPONSE',
  GetFiltersTemplatesResponseFail = '@/GET_FILTERS_TEMPLATES_RESPONSE_FAIL',

  EditGroupingTemplateRequest = '@/EDIT_GROUPING_TEMPLATE_REQUEST',
  EditGroupingTemplateResponse = '@/EDIT_GROUPING_TEMPLATE_RESPONSE',
  EditGroupingTemplateResponseFail = '@/EDIT_GROUPING_TEMPLATE_RESPONSE_FAIL',

  EditFiltersTemplateRequest = '@/EDIT_Filters_TEMPLATE_REQUEST',
  EditFiltersTemplateResponse = '@/EDIT_Filters_TEMPLATE_RESPONSE',
  EditFiltersTemplateResponseFail = '@/EDIT_Filters_TEMPLATE_RESPONSE_FAIL',

  SaveSdGroupingTemplateRequest = '@/SAVE_SD_GROUPING_TEMPLATE_REQUEST',
  SetGroupTemplates = '@/SET_SD_GROUP_TEMPLATES',

  SaveGroupingTemplateRequest = '@/SAVE_GROUPING_TEMPLATE_REQUEST',
  SaveGroupingTemplateResponse = '@/SAVE_GROUPING_TEMPLATE_RESPONSE',
  SaveGroupingTemplateResponseFail = '@/SAVE_GROUPING_TEMPLATE_RESPONSE_FAIL',

  SaveFiltersTemplateRequest = '@/SAVE_Filters_TEMPLATE_REQUEST',
  SaveFiltersTemplateResponse = '@/SAVE_Filters_TEMPLATE_RESPONSE',
  SaveFiltersTemplateResponseFail = '@/SAVE_Filters_TEMPLATE_RESPONSE_FAIL',

  DeleteGroupingTemplateRequest = '@/DELETE_GROUPING_TEMPLATE_REQUEST',
  DeleteGroupingTemplateResponse = '@/DELETE_GROUPING_TEMPLATE_RESPONSE',
  DeleteGroupingTemplateResponseFail = '@/DELETE_GROUPING_TEMPLATE_RESPONSE',

  SetCurrentFilterTemplate = '@/SET_CURRENT_FITLER_TEMPLATE',

  SetTicketOverviewData = '@/SET_TICKET_OVERVIEW_DATA',

  GetTicketsConfigRequest = '@/GET_TICKETS_CONFIG_REQUEST',
  GetTicketsConfigResponse = '@/GET_TICKETS_CONFIG_RESPONSE',
  GetTicketsConfigResponseFail = '@/GET_TICKETS_CONFIG_RESPONSE_FAIL',

  SetIsNewOrderTab = '@/SET_IS_NEW_ORDER_TAB',

  SetWorkOrderStatus = '@/SET_WORK_ORDER_STATUS',

  SetStringFilter = '@/SET_STRING_FILTER',

  GetWorkItemsTableDataRequest = '@/GET_WORK_ITEMS_TABLE_DATA_REQUEST',
  GetWorkItemsTableDataResponse = '@/GET_WORK_ITEMS_TABLE_DATA_RESPONSE',
  GetWorkItemsTableDataResponseFail = '@/GET_WORK_ITEMS_TABLE_DATA_RESPONSE_FAIL',

  GetServiceItemsTableDataRequest = '@/GET_SERVICE_ITEMS_TABLE_DATA_REQUEST',
  GetServiceItemsTableDataResponse = '@/GET_SERVICE_ITEMS_TABLE_DATA_RESPONSE',
  GetServiceItemsTableDataResponseFail = '@/GET_SERVICE_ITEMS_TABLE_DATA_RESPONSE_FAIL',

  SetSelectedWorkTableRow = '@/SET_SELECTED_WORK_TABLE_ROW',
  SetSelectedServiceTableRow = '@/SET_SELECTED_SERVICE_TABLE_ROW',
  DeleteWorkItemsTableRow = '@/DELETE_WORK_ITEMS_TABLE_ROW',
  DeleteServiceItemsTableRow = '@/DELETE_SERVICE_ITEMS_TABLE_ROW',
  AddWorkItemsTableRow = '@/ADD_WORK_ITEMS_TABLE_ROW',
  AddServiceItemsTableRow = '@/ADD_SERVICE_ITEMS_TABLE_ROW',
  EditServiceItemsTableRow = '@/EDIT_SERVICE_ITEMS_TABLE_ROW',

  AddAnalyzeDelays = '@/ADD_ANALYZE_DELAYS',
  EditAnalyzeDelays = '@/EDIT_ANALYZE_DELAYS',
  DeleteAnalyzeDelays = '@/DELETE_ANALYZE_DELAYS',

  AddAdditionalCostsWorkCost = '@/ADD_ADDITIONAL_COSTS_WORK_COST',
  DeleteAdditionalCostsWorkCost = '@/DELETE_ADDITIONAL_COSTS_WORK_COST',

  AddCustomerRequestItem = '@/ADD_CUSTOMER_REQUEST_ITEM',
  EditCustomerRequestItem = ' @/EDIT_CUSTOMER_REQUEST_ITEM',
  DeleteCustomerRequestItem = '@/DELETE_CUSTOMER_REQUEST_ITEM',

  AddCustomerServiceItem = '@/ADD_CUSTOMER_SERVICE_ITEM',
  DeleteCustomerServiceItem = '@/DELETE_CUSTOMER_SERVICE_ITEM',
  EditCustomerServiceItem = '@/EDIT_CUSTOMER_SERVICE_ITEM',
  SetCustomerServiceItemClosed = '@/SET_CUSTOMER_SERVICE_ITEM_CLOSED',

  ChangeSorting = '@/CHANGE_SORTING',

  ChangeGrouping = '@/CHANGE_GROUPING',

  ClearGroupedTicketDetailsData = '@/CLEAR_GROUPED_TICKET_DETAILS_DATA',

  SetTypeOfTickets = '@/SET_TYPE_OF_TICKETS',

  SetRowPerPage = '@/SET_ROWS_PER_PAGE',
  SetPage = '@/SET_PAGE',
}
