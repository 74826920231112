import { AnyAction } from 'redux'

import { ParsedCashFlowResponse } from '@/api/vault-v1/cashFlowApi'
import { CashFlowEntry, CashFlowFilter } from '@/api/vault-v1/swaggerGeneratedApi'
import { ModalsNames } from '@/components/pages/vault-v1/CashFlow/types'
import { VaultV1CashFlowActions } from '@/constants/actions'

// get All
export const getCashFlowsRequest = (): AnyAction => ({
  type: VaultV1CashFlowActions.GetCashFlowsRequest,
})
export const getCashFlowsResponse = (payload: ParsedCashFlowResponse[]): AnyAction => ({
  type: VaultV1CashFlowActions.GetCashFlowsResponse,
  payload,
})
export const getCashFlowsFail = (payload: string): AnyAction => ({
  type: VaultV1CashFlowActions.GetCashFlowsFail,
  payload,
})

// selected
export const setSelectedCashFlow = (payload: CashFlowEntry | null): AnyAction => ({
  type: VaultV1CashFlowActions.SetSelectedCashFlow,
  payload,
})

// modals
export const setFlwTableModal = (payload: { type: ModalsNames; isOpen: boolean }): AnyAction => ({
  type: VaultV1CashFlowActions.SetModal,
  payload,
})

// filter
export const setCashFlowFilter = (payload: CashFlowFilter): AnyAction => ({
  type: VaultV1CashFlowActions.FlwSetFilter,
  payload,
})
