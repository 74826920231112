import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(() => ({
  icon: {
    color: 'rgba(0, 0, 0, 0.3)',
    padding: '0',

    position: 'absolute',
    right: '-10px',
    top: '50%',
    transform: 'translateY(-50%)',
  },

  menuWrapper: {
    minWidth: '125px',
    display: 'flex',
    justifyContent: 'center',
  },

  loader: {
    width: '22px',
    height: '22px',
    display: 'block',

    '&:after': {
      content: '',
      borderWidth: '3px',
    },
  },
}))
