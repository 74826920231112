import makeStyles from '@material-ui/core/styles/makeStyles'

import colors from '@/theme/colors'

export type Props = {
  overflow?: string
  fieldWidth?: number
}

export const useClasses = makeStyles(({ spacing, mixins }) => ({
  select: {
    width: '100%',
    margin: spacing(2, 0),
  },

  headerText: {
    opacity: 1,
    padding: spacing(1.3, 2),
  },

  disabled: {
    opacity: 0.6,
    pointerEvents: 'none',
  },

  checkboxesBlock: {
    display: 'flex',
    flexDirection: 'row',
  },

  checkboxWrapper: {
    padding: spacing(0, 2),
    fontSize: spacing(2),
    cursor: 'pointer',
  },

  menuList: {
    maxHeight: spacing(40),
    overflow: 'auto',
  },

  noOptions: {
    padding: spacing(2, 3),
  },

  primaryLabel: {
    color: 'black',
    textTransform: 'inherit',
  },

  secondaryLabel: {
    color: colors.grey200,
    textTransform: 'inherit',
  },

  placeholderOption: {
    color: '#999',
  },

  selectFooter: {
    display: 'flex',
    ...mixins.gutters(),
  },

  button: {
    flex: 1,
  },

  cancelButton: {
    color: colors.primary,
    backgroundColor: 'transparent',
    border: '1px solid rgba(80, 168, 174, 0.5)',
    marginRight: spacing(1),

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))
