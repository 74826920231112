import makeStyles from '@material-ui/core/styles/makeStyles'
import styled from 'styled-components'

import { styledComponentTheme } from '@/theme/styledComponentTheme'

export const useClasses = makeStyles(({ spacing }) => ({
  deviceTitle: {
    fontSize: '20px',
    marginBottom: spacing(2.5),
  },
  deviceSubTitle: {
    marginBottom: spacing(2.5),
  },
  deviceName: {
    color: '#929A9B',
  },
  deviceHeader: {
    marginBottom: spacing(1),
    fontWeight: 'bold',
    background: '#F8F8F8',
    padding: '10px 20px',
  },

  button: {
    background: 'white',
    fontWeight: 'normal',
    color: styledComponentTheme.colors.baseGreen,
    border: '1px solid',
    marginBottom: '0',
    whiteSpace: 'nowrap',
    marginTop: '0',
    '&:hover': {
      background: styledComponentTheme.colors.baseGreen,
      color: 'white',
      borderColor: styledComponentTheme.colors.baseGreen,
      '& .MuiButton-startIcon': {
        filter: 'brightness(0) invert(1)',
      },
    },
    '& .MuiButton-startIcon': {
      marginLeft: '-16px',
      marginRight: '0',
      width: '50px',
      justifyContent: 'center',
    },
  },
  fullHeight: {
    minHeight: 'calc(100% - 64px)',
  },
  cancelButton: {
    fontWeight: 'normal',
    background: 'transparent',
    color: '#929A9B',
    border: '1px solid',
    marginLeft: spacing(2.5),
    marginBottom: 0,
    padding: '10px 20px',
    '&:hover': {
      background: 'transparent',
      color: '#929A9B',
      border: '1px solid',
    },
  },
  closeButton: {
    position: 'fixed',
    top: 'auto',
    right: 'auto',
    '& [class*="makeStyles-icon"]': {
      color: '#041517',
    },
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  radioGroup: {
    flexDirection: 'column',
    border: '1px solid #ECF2F2',
    marginBottom: spacing(3),
    maxHeight: '600px',
    overflow: 'auto',
    flexWrap: 'nowrap',
  },
  radioButton: {
    color: '#929A9B',
    marginRight: '0',
    marginLeft: '0',
    paddingLeft: spacing(2),
    '&:nth-child(even)': {
      background: '#F8F8F8',
    },
    '& .Mui-checked + .MuiFormControlLabel-label': {
      color: styledComponentTheme.colors.baseGreen,
    },
  },
  radioTitle: {
    fontWeight: 'bold',
    background: '#F8F8F8',
    padding: '12px 0px 12px 24px',
    border: '1px solid #ECF2F2',
    borderBottom: '0',
    display: 'block',
    textAlign: 'left',
  },
  loader: {
    top: 'auto',
    left: 'auto',
    position: 'unset',
    transform: 'none',
    margin: '20px',
  },
  preLine: {
    whiteSpace: 'pre-line',
  },
  buttonWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '32px',
    padding: '20px',
  },
}))

export const ContentWrapper = styled.div`
  background-color: #ffffff;
  padding: 30px;
  overflow: auto;
`
