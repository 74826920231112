export enum WINDOWS_TYPES {
  DEVICES = 'DEVICES',
  SECURITY_ALARMS = 'SECURITY_ALARMS',
  TECHNICAL_EVENTS = 'TECHNICAL_EVENTS',
  TRANSACTIONS = 'TRANSACTIONS',
  SECURITY_ALARMS_BY_DEVICE = 'SECURITY_ALARMS_BY_DEVICE',
  TECHNICAL_EVENTS_BY_DEVICE = 'TECHNICAL_EVENTS_BY_DEVICE',
  TRANSACTIONS_BY_DEVICE = 'TRANSACTIONS_BY_DEVICE',
  BLACK_LIST = 'BLACK_LIST',
  MULTIPLE_SNAPSHOTS_HISTORY = 'MULTIPLE_SNAPSHOTS_HISTORY',

  DEVICES_SETTINGS = 'DEVICES_SETTINGS',
  DEVICES_SELECTOR_REPORTS = 'DEVICES_SELECTOR_REPORTS',
  DEVICES_SELECTOR_MULTIPLE_SNAPSHOTS = 'DEVICES_SELECTOR_MULTIPLE_SNAPSHOTS',
  DEVICES_SELECTOR_CENTRAL_SETTINGS = 'DEVICES_SELECTOR_CENTRAL_SETTINGS',
  DEVICES_SELECTOR_RFM_SINGLE = 'DEVICES_SELECTOR_RFM_SINGLE',
  DEVICES_SELECTOR_RFM_MULTIPLE = 'DEVICES_SELECTOR_RFM_MULTIPLE',
}
