import TableCell from '@material-ui/core/TableCell'
import React, { FC, useCallback } from 'react'

import { TechEventsApi } from '@/api/atmeye/techEvents'
import { CellJSXPropsComponent } from '@/components/NPMPakage/components/tableComponents/UITable/types'
import { Permission } from '@/components/NPMPakage/components/wrappers/types'
import { useTranslate } from '@/utils/internalization'

import { DateCell } from '../TableCell/DateCell/DateCell'
import { InputCell } from '../TableCell/InputCell/InputCell'
import { MediaCell } from '../TableCell/MediaCell/MediaCell'
import { useClasses } from './styles'

const TechnicalEventsTableCell: FC<CellJSXPropsComponent> = ({
  fieldName,
  index,
  getUpdateRequest,
  row,
}: CellJSXPropsComponent): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  const updateItem = ({ field, value }: { field: string; value: string }) => {
    getUpdateRequest?.mutateQueryTable &&
      getUpdateRequest.mutateQueryTable((prevTable: any[]) => {
        return prevTable.map(prevRow => {
          if (prevRow.eventId === row.eventId) return { ...prevRow, [field]: value }
          return prevRow
        })
      })
  }

  const saveCellHandler = async (
    event: any,
    fieldId: string,
    fieldValue: string,
  ): Promise<void> => {
    const { value } = event.target

    try {
      updateItem({ field: 'note', value })

      await TechEventsApi.updateTechEventNote({
        value,
        eventId: row.eventId,
      })
    } catch (e) {
      updateItem({ field: 'note', value: fieldValue })
    }
  }

  const mutateQueryTableWithScreenshot = () => {
    updateItem({ field: 'mediaStoreType', value: 'DATABASE_FILE' })
  }

  const renderComponents = useCallback(() => {
    return {
      note: (
        <InputCell
          headerName={fieldName || ''}
          index={index}
          row={row}
          onBlur={saveCellHandler}
          note={row?.note || ''}
        />
      ),
      cameraNumber: (
        <MediaCell
          text={row.cameraNumber}
          permission={Permission.Technical_Events_View_Media}
          row={row}
          requestFileType="TECHNICAL_EVENT"
          getUpdateRequest={{
            ...getUpdateRequest,
            mutateQueryTable: getUpdateRequest?.mutateQueryTable
              ? mutateQueryTableWithScreenshot
              : undefined,
          }}
        />
      ),
      atmDateTime: <DateCell isUTCTime key={fieldName || ''} date={row?.[fieldName || '']} />,
    }
  }, [row, translate])

  return (
    <>
      {renderComponents()?.[fieldName as keyof typeof renderComponents] || (
        <TableCell key={fieldName} className={classes.cell}>
          {row?.[fieldName || '']}
        </TableCell>
      )}
    </>
  )
}

export { TechnicalEventsTableCell }
