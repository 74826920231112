import { alpha } from '@material-ui/core/styles'
import makeStyles from '@material-ui/core/styles/makeStyles'

const notificationDrawer = 287

export const useClasses = makeStyles(({ palette, spacing }) => ({
  notificationHeader: {
    minWidth: notificationDrawer,
    display: 'flex',
    justifyContent: 'space-around',
    padding: spacing(1.75, 0),
    borderBottom: `1px solid ${palette.grey[100]}`,
  },
  notificationHeaderTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  notificationHeaderBtnDisable: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonOffNot: {
    width: '35px',
    height: '35px',
    opacity: '0.5',
    margin: 0,

    '&:hover': {
      filter: 'none',
      borderColor: palette.primary.main,
      backgroundColor: alpha(palette.primary.main, 0.2),
    },
  },
}))
