import makeStyles from '@material-ui/core/styles/makeStyles'
import styled from 'styled-components'

import { styledComponentTheme } from '@/theme/styledComponentTheme'

export const useClasses = makeStyles(({ spacing }) => ({
  deviceTitle: {
    fontSize: '20px',
    marginBottom: spacing(2.5),
  },
  deviceSubTitle: {
    marginBottom: spacing(2.5),
  },
  deviceName: {
    color: '#929A9B',
  },
  deviceHeader: {
    marginBottom: spacing(1),
    fontWeight: 'bold',
    background: '#F8F8F8',
    padding: '10px 20px',
  },
  button: {
    fontWeight: 'normal',
    background: styledComponentTheme.colors.baseGreen,
    color: 'white',
    border: 0,
    marginLeft: spacing(2.5),
    marginBottom: 0,
    padding: '10px 20px',
    '&:hover': {
      background: styledComponentTheme.colors.baseGreen,
      color: 'white',
      border: 0,
    },
  },
  cancelButton: {
    fontWeight: 'normal',
    background: 'transparent',
    color: '#929A9B',
    border: '1px solid',
    marginLeft: spacing(2.5),
    marginBottom: 0,
    padding: '10px 20px',
    '&:hover': {
      background: 'transparent',
      color: '#929A9B',
      border: '1px solid',
    },
  },
  deleteButton: {
    background: '#FDEFED',
    color: '#E45B4A',
    border: '0',
    '&:hover': {
      background: '#FDEFED',
      color: '#E45B4A',
      '& .MuiButton-startIcon': {
        filter: 'none',
      },
    },
    '&.Mui-disabled img': {
      filter: 'grayscale(100%)',
    },
  },
  closeButton: {
    top: spacing(1),
    right: spacing(1),
    '& [class*="makeStyles-icon"]': {
      color: '#041517',
    },
  },

  radioGroup: {
    flexDirection: 'column',
    border: '1px solid #ECF2F2',
    marginBottom: spacing(3),
    maxHeight: '600px',
    overflow: 'auto',
    flexWrap: 'nowrap',
  },
  radioButton: {
    color: '#929A9B',
    marginRight: '0',
    marginLeft: '0',
    paddingLeft: spacing(2),
    '&:nth-child(even)': {
      background: '#F8F8F8',
    },
    '& .Mui-checked + .MuiFormControlLabel-label': {
      color: styledComponentTheme.colors.baseGreen,
    },
  },
  radioTitle: {
    fontWeight: 'bold',
    background: '#F8F8F8',
    padding: '12px 0px 12px 16px',
    border: '1px solid #ECF2F2',
    borderBottom: '0',
    display: 'block',
    textAlign: 'left',
  },
  selectAll: {
    float: 'right',
    marginRight: '20px',
  },
  checkboxLabel: {
    color: '#929A9B',
    display: 'block',
    width: '100%',
    margin: '0',
    paddingLeft: '8px',
    '& .Mui-checked + .MuiFormControlLabel-label': {
      color: styledComponentTheme.colors.baseGreen,
    },
    '&:nth-child(even)': {
      background: '#F8F8F8',
    },
  },
  checkboxWrapper: {
    marginBottom: '20px',
    width: '100%',
    border: '1px solid #ECF2F2',
    maxHeight: '400px',
    overflow: 'auto',
  },
  checkboxRoot: {
    padding: '0',
    paddingRight: '6px',
    marginBottom: '4px',
  },
  input: {
    marginLeft: spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  paperRoot: {
    padding: '2px 4px',
    alignItems: 'center',
    width: '100%',
    display: 'flex',
    marginBottom: '24px',
    boxShadow: 'none',
    border: '1px solid #CAD5D7',
  },
  loader: {
    top: 'auto',
    left: 'auto',
    position: 'unset',
    transform: 'none',
    margin: '16px auto',
  },
}))

export const ContentWrapper = styled.div`
  background-color: #ffffff;
  padding: 30px;
`
