import { AppNavigationRoutes, COMPANIES_BLOCK_PREFIX } from '@/constants'
import CompanyListIcon from '@/assets/companies/icons/company_list.svg'
import AdvancedSearchIcon from '@/assets/companies/icons/advanced_search.svg'
import ImportCompaniesIcon from '@/assets/companies/icons/import_companies.svg'

// import { Search, GetAppSharp, Apartment, List } from '@material-ui/icons'

export default [
  {
    path: `${COMPANIES_BLOCK_PREFIX}${AppNavigationRoutes.CompanyListPage}`,
    label: 'translate#title.companies',
    Icon: CompanyListIcon,
    subItems: [],
    allowedPermissions: ['CompaniesTree'],
  },
  {
    path: `${COMPANIES_BLOCK_PREFIX}${AppNavigationRoutes.AdvancedSearchPage}`,
    label: 'translate#title.advancedSearch',
    Icon: AdvancedSearchIcon,
    subItems: [],
    allowedPermissions: ['COM_Companies_SearchForm'],
  },
  {
    path: `${COMPANIES_BLOCK_PREFIX}${AppNavigationRoutes.ImportCompaniesPage}`,
    label: 'translate#title.importCompanies',
    activeFor: [`${COMPANIES_BLOCK_PREFIX}${AppNavigationRoutes.ImportCompaniesPage}`],
    Icon: ImportCompaniesIcon,
    subItems: [],
    allowedPermissions: ['Companies_Import'],
  },
]
