export enum TechnicalEventsByDeviceActions {
  ChangeSelectedFilters = '@/TECHNICAL_EVENTS_BY_DEVICE/CHANGE_SELECTED_FILTERS',
  ChangeGrouping = '@/TECHNICAL_EVENTS_BY_DEVICE/CHANGE_GROUPING',
  ChangeSorting = '@/TECHNICAL_EVENTS_BY_DEVICE/CHANGE_SORTING',
  SetRowPerPage = '@/TECHNICAL_EVENTS_BY_DEVICE/SET_ROW_PER_PAGE',
  SetPage = '@/TECHNICAL_EVENTS_BY_DEVICE/SET_PAGE',

  DeleteFilterTemplateRequest = '@/TECHNICAL_EVENTS_BY_DEVICE/DELETE_FILTER_TEMPLATE_REQUEST',
  DeleteFilterTemplateResponse = '@/TECHNICAL_EVENTS_BY_DEVICE/DELETE_FILTER_TEMPLATE_RESPONSE',

  GetGroupingTemplatesRequest = '@/TECHNICAL_EVENTS_BY_DEVICE/GET_GROUPING_TEMPLATES_REQUEST',
  SetSelectedGroupingTemplate = '@/TECHNICAL_EVENTS_BY_DEVICE/SET_SELECTED_GROUPING_TEMPLATE',

  GetFiltersTemplatesRequest = '@/TECHNICAL_EVENTS_BY_DEVICE/GET_FILTERS_TEMPLATES_REQUEST',

  SaveGroupingTemplateRequest = '@/TECHNICAL_EVENTS_BY_DEVICE/SAVE_GROUPING_TEMPLATE_REQUEST',

  CreateFilterTemplateRequest = '@/TECHNICAL_EVENTS_BY_DEVICE/CREATE_FILTER_TEMPLATE_REQUEST',
  EditGroupTemplateRequest = '@/TECHNICAL_EVENTS_BY_DEVICE/EDIT_GROUP_TEMPLATE_REQUEST',
  DeleteGroupTemplateRequest = '@/TECHNICAL_EVENTS_BY_DEVICE/DELETE_GROUP_TEMPLATE_REQUEST',

  UpdateFilterTemplateRequest = '@/TECHNICAL_EVENTS_BY_DEVICE/UPDATE_FILTER_TEMPLATE_REQUEST',
  SetGroupTemplates = '@/TECHNICAL_EVENTS_BY_DEVICE/SET_GROUP_TEMPLATES',

  GetTransactionsConfigRequest = '@/TECHNICAL_EVENTS_BY_DEVICE/GET_TRANSACTIONS_CONFIG_REQUEST',
  GetTransactionsRequest = '@/TECHNICAL_EVENTS_BY_DEVICE/GET_TRANSACTIONS_REQUEST',
  GetTransactionsResponse = '@/TECHNICAL_EVENTS_BY_DEVICE/GET_TRANSACTIONS_RESPONSE',
  GetGroupDetailsRequest = '@/TECHNICAL_EVENTS_BY_DEVICE/GET_GROUP_DETAILS_REQUEST',
  SetParentGroups = '@/TECHNICAL_EVENTS_BY_DEVICE/SET_PARENT_GROUPS',
  SetCurrentFilterTemplate = '@/TECHNICAL_EVENTS_BY_DEVICE/SET_CURRENT_FILTER_TEMPLATE',
  SetSdFilterTemplateResponse = '@/TECHNICAL_EVENTS_BY_DEVICE/SET_FILTER_TEMPLATE_RESPONSE',
  CreateSdFilterTemplateResponse = '@/TECHNICAL_EVENTS_BY_DEVICE/CREATE_SD_FILTER_TEMPLATE_RESPONSE',
  EditSdGroupTemplateFromResponse = '@/TECHNICAL_EVENTS_BY_DEVICE/EDIT_SD_GROUP_TEMPLATE_FROM_RESPONSE',
  UpdateSdFilterTemplateResponse = '@/TECHNICAL_EVENTS_BY_DEVICE/UPDATE_SD_FILTER_TEMPLATE_RESPONSE',
  GetMedia = '@/TECHNICAL_EVENTS_BY_DEVICE/GET_MEDIA',
  SetMedia = '@/TECHNICAL_EVENTS_BY_DEVICE/SET_MEDIA',
  ClearCurrentMedia = '@/TECHNICAL_EVENTS_BY_DEVICE/CLEAR_CURRENT_MEDIA',
  SetCurrentMedia = '@/TECHNICAL_EVENTS_BY_DEVICE/SET_CURRENT_MEDIA',
  SetMediaErrorMessage = '@/TECHNICAL_EVENTS_BY_DEVICE/SET_MEDIA_ERROR_MESSAGE',
  CancelRequest = '@/TECHNICAL_EVENTS_BY_DEVICE/CANCEL_REQUEST',
  EditTableCellRequest = '@/TECHNICAL_EVENTS_BY_DEVICE/EDIT_TABLE_CELL_REQUEST',
  EditTableCellGrouping = '@/TECHNICAL_EVENTS_BY_DEVICE/EDIT_TABLE_CELL_REQUEST_GROUPING',
  SaveTableCell = '@/TECHNICAL_EVENTS_BY_DEVICE/SAVE_TABLE_CELL',
  SaveTableCellGrouping = '@/TECHNICAL_EVENTS_BY_DEVICE/SAVE_TABLE_CELL_GROUPING',
  SetDataPagination = '@/TECHNICAL_EVENTS_BY_DEVICE/SET_DATA_PAGINATION',
  GetDataPagination = '@/TECHNICAL_EVENTS_BY_DEVICE/GET_DATA_PAGINATION',
  CollectFilterTemplateFields = '@/TECHNICAL_EVENTS_BY_DEVICE/COLLECT_FILTER_TEMPLATE_FIELDS',
  GetTicketsConfigResponse = '@/TECHNICAL_EVENTS_BY_DEVICE/GET_TICKETS_CONFIG_RESPONSE',

  ClearTechnicalEventsFlatDataByDevice = '@/TRANSACTIONS_BY_DEVICE/CLEAR_TECHNICAL_EVENTS_FLAT_DATA_BY_DEVICE',

  SetFilterModalState = '@/TECHNICAL_EVENTS_BY_DEVICE/SET_FILTER_MODAL_STATE',
  GetTicketResponseFail = '@/TECHNICAL_EVENTS_BY_DEVICE/GET_TICKETS_RESPONSE_FAIL',
  IsSubmitFiltersButtonClicked = '@/TECHNICAL_EVENTS_BY_DEVICES/IS_SUBMIT_FILTERS_BUTTON_CLICKED',
}
