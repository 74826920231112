import { AppState } from '@/store/reducers'
import { createSelector } from 'reselect'

import * as fromReducer from './reducer'

export const getMapsState = (state: AppState): fromReducer.State => state.cmMaps

export const getMapCashPoints = createSelector(getMapsState, state => state.cashPoints)
export const getMapCashPointsFilter = createSelector(getMapsState, state => state.cashPoints.filter)
export const getMapSelectedCashPoints = createSelector(
  getMapsState,
  state => state.selectedCashPoint,
)
