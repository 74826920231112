import { alpha } from '@material-ui/core/styles/colorManipulator'
import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  headerWrapper: {
    padding: spacing(0.5, 0),
  },
  fullWidth: {
    width: '100%',
  },
  selectWrapper: {
    margin: spacing(0.5, 0),
  },
  select: {
    marginTop: `${spacing(0)}px!important`,
    marginBottom: spacing(1),
  },
  selectButton: {
    marginBottom: spacing(1),
    paddingLeft: spacing(0),
    paddingRight: spacing(0),
    minWidth: '40px',
  },
  control: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '100%',
    gridGap: spacing(1),
  },
  searchWrapper: {
    width: '100%',
  },
  indentationInputBlock: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
  },
  search: {
    margin: spacing(0),
  },
  icon: {
    color: palette.grey['200'],
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    width: spacing(5),
    margin: spacing(1, 0),
    marginLeft: spacing(1),
    borderColor: alpha(palette.primary.main, 0.3),

    '&:hover': {
      filter: 'none',
      borderColor: alpha(palette.primary.main, 0.1),
      backgroundColor: alpha(palette.primary.main, 0.1),
    },
  },
  buttonActive: {
    color: 'white',
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.main,
    },
    '&:active': {
      backgroundColor: palette.primary.main,
    },
  },
  btnWidthTitle: {
    width: spacing(20),
  },
  btnIcon: {
    marginRight: spacing(0.5),
  },
  tableWrapper: {
    height: `calc(100% - ${spacing(18)}px)`,
  },

  contentWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },

  reportsWrapper: {
    overflowX: 'scroll',
    width: '100%',
    height: '100%',
    scrollBehavior: 'smooth',
    background: '#FFFFFF',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08), 0px 0px 4px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    border: `1px solid ${palette.primary.main}`,
  },
  wrapper: {
    border: 'none',
    boxShadow: 'none',
    borderBottom: `2px solid ${palette.grey[100]}`,
  },
  iconButton: {
    position: 'absolute',
    right: 9,
  },
  hidden: {
    display: 'none',
  },
}))
