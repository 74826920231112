import 'moment/min/locales.min'

import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromUsers from './reducer'
import { initialState } from './reducer'

const getUserGroupState = (state: AppState): fromUsers.State => state.usmUserGroup || initialState

// user-group-list
export const getUserGroupList = createSelector(getUserGroupState, state =>
  [...state.listAllUserGroups].sort((a, b) => a.userGroup.localeCompare(b.userGroup)),
)

export const getIsFetchingUserGroup = createSelector(
  getUserGroupState,
  state => state.isFetchingAllUserGroups,
)

// current-user-group

export const getIsFetchingCurrentUserGroup = createSelector(
  getUserGroupState,
  state => state.isFetchingGetCurrentUserGroup,
)
export const getCurrentUserGroupAndPermissions = createSelector(
  getUserGroupState,
  state => state.currentUserGroup,
)

// object-tree
export const getIsFetchingObjectTree = createSelector(
  getUserGroupState,
  state => state.isFetchingObjectTree,
)

export const geObjectTreePermissions = createSelector(
  getUserGroupState,
  state => state.objectTreePermissions,
)

export const getIsAddedUserGroup = createSelector(
  getUserGroupState,
  state => state.isAddedUserGroup,
)

export const getGlobalErrorUserGroup = createSelector(getUserGroupState, state => state.error)
