import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { CassetteTypesApi } from '@/api/cm/adminApi'
import { AdmCashCassetteType } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants'

import {
  addAdmCassetteTypeFail,
  deleteAdmCassetteTypeFail,
  deleteAdmCassetteTypeResponse,
  getAdmCassetteTypesFail,
  getAdmCassetteTypesRequest,
  getAdmCassetteTypesResponse,
  setAdmSelectedCassetteType,
  updateAdmCassetteTypeFail,
} from './actions'
import { getCassetteTypesSelected, getCassetteTypesSort } from './selectors'

function* getCassetteTypes(): SagaIterator {
  try {
    const sort = yield select(getCassetteTypesSort)
    const selected = yield select(getCassetteTypesSelected)

    const response = yield call(CassetteTypesApi.get, sort)

    if (selected) {
      const existingElement = response.find(
        (cassetteType: AdmCashCassetteType) => cassetteType.id === selected.id,
      )
      yield put(setAdmSelectedCassetteType(existingElement || null))
    }

    yield put(getAdmCassetteTypesResponse(response))
  } catch (error) {
    yield put(getAdmCassetteTypesFail(error))
  }
}

function* addCashCassetteType({ payload }: AnyAction): SagaIterator {
  try {
    yield call(CassetteTypesApi.add, payload)

    yield put(getAdmCassetteTypesRequest())
  } catch (error) {
    yield put(addAdmCassetteTypeFail(error))
  }
}

function* updateCashCassetteType({ payload }: AnyAction): SagaIterator {
  try {
    yield call(CassetteTypesApi.update, payload)

    yield put(getAdmCassetteTypesRequest())
  } catch (error) {
    yield put(updateAdmCassetteTypeFail(error))
  }
}

function* deleteCashCassetteType(): SagaIterator {
  try {
    const selected = yield select(getCassetteTypesSelected)

    if (selected) {
      yield call(CassetteTypesApi.delete, selected.id)

      yield put(deleteAdmCassetteTypeResponse())
      yield put(getAdmCassetteTypesRequest())
    }
  } catch (error) {
    yield put(deleteAdmCassetteTypeFail(error))
  }
}

export default function*(): Generator {
  yield takeLatest(
    [CMAppConfigActions.AdmGetCassetteTypesRequest, CMAppConfigActions.AdmSortCassetteTypes],
    getCassetteTypes,
  )

  yield takeLatest(CMAppConfigActions.AdmDeleteCassetteTypeRequest, deleteCashCassetteType)
  yield takeLatest(CMAppConfigActions.AdmAddCassetteTypeRequest, addCashCassetteType)
  yield takeLatest(CMAppConfigActions.AdmUpdateCassetteTypeRequest, updateCashCassetteType)
}
