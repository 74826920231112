import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import { TicketsApi } from '@/api/sd/ticketsApi'
import { TicketActions } from '@/constants'

import {
  getGroupingTemplatesResponse,
  getGroupingTemplatesResponseFail,
  getServiceItemsTableDataResponse,
  getServiceItemsTableDataResponseFail,
  getWorkItemsTableDataResponse,
  getWorkItemsTableDataResponseFail,
} from './actions'

function* getGroupingTemplates(): Generator {
  try {
    const response = yield call(TicketsApi.getGroupingTemplates)

    yield put(getGroupingTemplatesResponse(response))
  } catch (error) {
    yield put(getGroupingTemplatesResponseFail(error.message))
  }
}

function* saveGroupingTemplate({ payload: { template } }: AnyAction): Generator {
  try {
    // yield put(saveGroupingTemplateResponse({ id: '3', ...template }))
  } catch (error) {
    // yield put(saveGroupingTemplateResponseFail(error.message))
  }
}

function* editGroupingTemplate({ payload: { template } }: AnyAction): Generator {
  try {
    // yield put(editGroupingTemplateResponse(template))
  } catch (error) {
    // yield put(editGroupingTemplateResponseFail(error.message))
  }
}

function* deleteGroupingTemplate({ payload: { template } }: AnyAction): Generator {
  try {
    /* const response = */
    // yield call(TicketsApi.deleteGroupingTemplate, template)
    // yield put(editGroupingTemplateResponse(template))
  } catch (error) {
    // yield put(editGroupingTemplateResponseFail(error.message))
  }
}

function* getWorkItemsTableData(): Generator {
  try {
    const response = yield call(TicketsApi.getWorkItemsTableData)

    yield put(getWorkItemsTableDataResponse(response))
  } catch (error) {
    yield put(getWorkItemsTableDataResponseFail(error.message))
  }
}

function* getServiceItemsTableData(): Generator {
  try {
    const response = yield call(TicketsApi.getServiceItemsTableData)

    yield put(getServiceItemsTableDataResponse(response))
  } catch (error) {
    yield put(getServiceItemsTableDataResponseFail(error.message))
  }
}

export default function*(): Generator {
  yield takeLatest(TicketActions.DeleteGroupingTemplateRequest, deleteGroupingTemplate)
  yield takeLatest(TicketActions.SaveGroupingTemplateRequest, saveGroupingTemplate)
  yield takeLatest(TicketActions.EditGroupingTemplateRequest, editGroupingTemplate)
  yield takeLatest(TicketActions.GetGroupingTemplatesRequest, getGroupingTemplates)
  yield takeLatest(TicketActions.GetWorkItemsTableDataRequest, getWorkItemsTableData)
  yield takeLatest(TicketActions.GetServiceItemsTableDataRequest, getServiceItemsTableData)
}
