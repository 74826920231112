import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getState = (state: AppState): fromReducer.State => {
  return state.cmAdmin?.appConfig.banks
}

export const getBanks = createSelector(getState, state => state)
export const getBanksData = createSelector(getState, state => state.data)

export const getBanksSelected = createSelector(getState, state => state.selected)

export const getBanksSort = createSelector(getState, state => ({
  sortColumn: state.sortColumn,
  sortOrder: state.sortOrder,
}))
