import { IconButton, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import clsx from 'clsx'
import React, { ReactElement, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import Tooltip from '@/components/blocks/Tooltip'
import { ValueTypes } from '@/components/pages/atmeye/Administration/LicenseList/ATMCoreLicenses/components/FiltersModalLicence/GenerateFormFields/types'

import { useClasses } from './styles'
import { Props } from './types'

const ActiveFilters = ({
  filterView,
  requiredFilters,
  onDeleteFilter,
  onClearFilters,
}: Props): ReactElement => {
  const classes = useClasses()

  const generateFiltersRow = useMemo(() => {
    return filterView.map(({ filterLabel, filterValue, key, valueType }) => {
      // todo check valueType === ValueTypes.Integer
      return (
        <div key={key} className={classes.bookmark}>
          <Typography variant="caption">
            {filterLabel} {': '}
            {valueType === ValueTypes.Integer ? (
              <span key={filterValue} className={classes.bookmarkDate}>
                {filterValue}
              </span>
            ) : (
              <Tooltip title={filterValue} className={classes.withTooltip} interactive>
                <span className={classes.bookmarkMultiple}>{filterValue}</span>
              </Tooltip>
            )}
          </Typography>

          {!requiredFilters?.[key] && (
            <IconButton
              size="small"
              className={classes.bookmarkIcon}
              onClick={() => onDeleteFilter(key)}
            >
              <Close fontSize="inherit" />
            </IconButton>
          )}
        </div>
      )
    })
  }, [classes.bookmark, classes.bookmarkIcon, onDeleteFilter])

  return (
    <div className={classes.root}>
      {generateFiltersRow}

      {!!filterView.length && !requiredFilters && (
        <div className={clsx(classes.bookmark, classes.bookmarkClear)}>
          <Typography variant="caption">
            <FormattedMessage id="title.clearAllFilters" defaultMessage="Clear all filters" />
          </Typography>

          <IconButton size="small" className={classes.bookmarkIcon} onClick={onClearFilters}>
            <Close fontSize="inherit" />
          </IconButton>
        </div>
      )}
    </div>
  )
}

export default ActiveFilters
