import { Theme } from '@material-ui/core/styles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { isIE } from 'react-device-detect'

interface Props {
  isProducts: boolean
  isDictionaries: boolean
}

export const useClasses = makeStyles<Theme, Props>(({ palette, mixins, spacing }) => ({
  wrapper: ({ isProducts, isDictionaries }: Props) => ({
    height: '100%',
    backgroundColor: isProducts || isDictionaries ? '#f9f9f9' : '#ffffff',
    display: 'contents',
  }),
  appBarHidden: {
    gridTemplateRows: '100%',
  },
  appBar: ({ isProducts }: Props) => ({
    display: 'flex',
    // height: '65px',
    // maxHeight: '65px',
    padding: !isProducts ? 'auto' : `${spacing(0, 2.5)} !important`,
    backgroundColor: !isProducts ? 'rgb(249, 249, 249)' : '#f9f9f9',
    borderBottom: !isProducts ? `1px solid ${palette.grey[100]}` : 'none',
    boxShadow: 'none',
    overflowX: 'auto',
    overflowY: 'hidden',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...mixins.gutters(),
  }),
  tabBarWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: spacing(1),
  },
  tabWrapper: {
    color: 'black',
    textDecoration: 'none',
    display: 'flex',
    overflow: 'hidden',
  },
  tabItem: ({ isProducts }: Props) => ({
    maxWidth: spacing(20),
    padding: spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    backgroundColor: !isProducts ? 'none' : '#ffffff',
    borderRadius: !isProducts ? 'none' : '4px',
    justifyContent: 'space-between',
    margin: spacing(1.5, 0),
    '& + $tabItem': {
      borderLeft: `1px solid ${palette.grey[100]}`,
    },
    '&:hover > $hiddenIcons': {
      display: 'flex',
    },
    border: '1px solid rgb(228, 228, 228)',
  }),
  tab: {
    minWidth: 0,
    minHeight: spacing(4),
    flex: isIE ? '0 0 auto' : 1,
    opacity: 1,
    overflow: 'hidden',
    textTransform: 'initial',
    padding: 0,
    fontWeight: 400,
  },
  tabActive: {
    color: palette.primary.main,
  },
  tabLabel: {
    textAlign: 'left',
    display: 'inline',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
  },
  hiddenIcons: {
    display: 'none',
    alignItems: 'center',
  },
}))
