import { LocalStorageItems } from '@/constants/localStorageItems'
import { getUsersSettings, setDataToLocalStorage } from '@/helpers/localStorage'
import { UserSetting } from '@/types'

export interface TableConfig {
  columns: string[]
  rowPerPage?: number
  columnsOrder: string[]
  isShowTableGrid?: boolean
  isAutoRefresh?: boolean
  refreshTime?: number
}

interface SetTableConfigProps {
  tableId: string | number
  tableData: Partial<TableConfig>
}

interface GetTableConfigProps {
  tableId: string | number
}

const getTableConfig = ({ tableId }: GetTableConfigProps): TableConfig | null => {
  const { pathname } = window.location
  const [, baseUrl] = pathname.split('/')
  const { allUsersSettings, userData } = getUsersSettings(baseUrl)

  if (userData) {
    const parsedTablesConfig = allUsersSettings[userData?.login]?.tables || {}
    return parsedTablesConfig[tableId] || null
  }

  return null
}

const setTableConfig = ({ tableId, tableData }: SetTableConfigProps): void => {
  const { pathname } = window.location
  const [, baseUrl] = pathname.split('/')

  const { allUsersSettings, userData } = getUsersSettings(baseUrl)

  if (!allUsersSettings[userData.login]) {
    allUsersSettings[userData.login] = {} as UserSetting
  }

  if (!allUsersSettings[userData.login].tables) {
    allUsersSettings[userData.login].tables = {}
  }

  allUsersSettings[userData.login].tables[tableId] = {
    ...(allUsersSettings[userData.login]?.tables[tableId] || {}),
    ...tableData,
  }

  setDataToLocalStorage(baseUrl)(LocalStorageItems.UsersSettings, JSON.stringify(allUsersSettings))
}

export { getTableConfig, setTableConfig }
