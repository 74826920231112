// core
import { Grid, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import { Alert } from '@material-ui/lab'
import clsx from 'clsx'
import { Field, FieldProps, Form, FormikProvider } from 'formik'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

import { ReactComponent as AddButton } from '@/assets/usm/add-button.svg'
// other temp
import AddIcon from '@/assets/usm/add-icon.svg'
import DeleteIcon from '@/assets/usm/delete-icon.svg'
import EditIcon from '@/assets/usm/edit-button.svg'
import CloseButton from '@/components/blocks/CloseButton/index'
import { LoginFields } from '@/components/blocks/USM/Login'
import { TryCountField } from '@/components/blocks/USM/Login/components'
import { PersonalInfo, PersonalInSalutationAndGender } from '@/components/blocks/USM/PersonalInfo'
import { UserInfoDateFields, UserInfoField } from '@/components/blocks/USM/UserInfo'
// components
import { Button } from '@/components/controls/Button/component'
import { createCheckboxField } from '@/components/controls/Checkbox/component'
import GeneratedField from '@/components/controls/GeneratedField'
import { FieldTypeName } from '@/components/controls/GeneratedField/types'
import { headersUserInformationTable } from '@/components/pages/usm/UsersPage/components/UserInformation/types'
import SubTitle from '@/components/wrappers/AppNavigation/components/DrawerActions/components/SubTitle/index'
import { useAddNewUserModal } from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/AddUserModal/hooks'
import { useUserInfoTable } from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/AddUserModal/hooks/table'
// helpers
import { CreatedCancelButton } from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/helpers/createButton'
// hooks
import { useTranslate } from '@/utils/internalization'

import UserInformationTable from '../../components/UserInformationTable/index'
// styles
import { useClasses } from './styles'
// types
import { AddNewUserPersonalCardField, Props } from './types'

const AddUserModalUSM = ({
  open,
  handleClose,
  disableBackdropClick,
  disableEscapeKeyDown,
}: Props): React.ReactElement => {
  const {
    handleAddNewRow,
    handleChangeRow,
    handleDeleteRows,
    handleSelectRow,
    selectedRows,
    deletedRows,
    changedRows,
    newRows,
  } = useUserInfoTable()

  const {
    setErrorsLocalState,
    errorsLocalState,
    handleFile,
    handleFileInputChange,
    setFieldValuePhoto,
    formik,
    values,
    isValid,
    dirty,
    errors,
    handleCloseForm,
    userInfoTable,
    handleAddNewUserToTable,
    handleSubmitForm,
    handleDeleteTableRows,
    lookupConfig,
    setFieldValue,
    isLoadingConfig,
    useBackupValuesCompanyAndShortName,
    generatedFieldBackupOptions,
    generateCompanyName,
  } = useAddNewUserModal({ handleClose, selectedRows })

  const onClose = (event: any, reason: 'backdropClick' | 'escapeKeyDown'): void | boolean => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return false
    }

    if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false
    }

    if (typeof handleCloseForm === 'function') {
      handleCloseForm()
    }
  }

  const translate = useTranslate()
  const classes = useClasses()

  const handleDeletePersonPhoto = useCallback(() => {
    setErrorsLocalState({ alreadyExistUserName: '', errorsPhoto: '' })
    setFieldValuePhoto(undefined)
  }, [])

  const isUserInformationValid =
    errors.companyId ||
    errors.userName ||
    errors.userGroupId ||
    errors.email ||
    errors.neverExpires ||
    errors.expireDate

  const errorPhotoJSX = errorsLocalState.errorsPhoto && (
    <Alert severity="error">{errorsLocalState.errorsPhoto}</Alert>
  )

  const dateNow = new Date()
  const oneYearAhead = new Date().setFullYear(dateNow.getFullYear() + 1)

  return (
    <Dialog maxWidth="md" open={open} keepMounted onClose={onClose}>
      <div className={classes.wrapper}>
        <p className={classes.headerTitle}>
          {translate('translate#usm.title.add.user.modal.new.user')}
        </p>
        <CloseButton onClick={handleCloseForm} className={classes.closingWindow} />

        <SubTitle />

        <FormikProvider value={formik}>
          <Form className={classes.form} noValidate>
            <>
              {handleFile ? (
                <div className={classes.wrapperPersonPhoto}>
                  <img src={handleFile as string} className={classes.personPhoto} />
                  <div className={classes.editingPhotoButtons}>
                    <div>
                      <img src={EditIcon} className={classes.editingPhoto} />
                      <Field name={AddNewUserPersonalCardField.AddPhoto}>
                        {({ field: { value, ...field } }: FieldProps): React.ReactElement => {
                          return (
                            <input
                              title=""
                              className={classes.addPhoto}
                              accept=".png,.jpg,.jpeg"
                              type="file"
                              {...field}
                              onChange={handleFileInputChange}
                            />
                          )
                        }}
                      </Field>
                    </div>
                    <img
                      src={DeleteIcon}
                      className={classes.closingPhoto}
                      onClick={handleDeletePersonPhoto}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className={clsx(classes.addingButton, {
                    [classes.disablePhoto]: !!values[AddNewUserPersonalCardField.ExistingPerson],
                  })}
                >
                  <div className={classes.addPhotoButton}>
                    <img src={AddIcon} alt="add-icon" className={classes.addIcon} />
                  </div>
                  <p className={classes.textButton}>
                    <FormattedMessage
                      id="usm.title.add.user.modal.action.add.photo"
                      defaultMessage="Add photo"
                    />
                  </p>
                  <Field name={AddNewUserPersonalCardField.AddPhoto}>
                    {({ field: { value, ...field } }: FieldProps): React.ReactElement => {
                      return (
                        <input
                          title=""
                          className={classes.addPhoto}
                          accept=".png,.jpg,.jpeg"
                          type="file"
                          {...field}
                          onChange={handleFileInputChange}
                          disabled={!!values[AddNewUserPersonalCardField.ExistingPerson]}
                        />
                      )
                    }}
                  </Field>
                </div>
              )}
              {errorPhotoJSX}

              <Typography>
                {translate('translate#usm.title.add.user.modal.menu.button.personal.information')}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs>
                  <GeneratedField
                    field={{
                      type: FieldTypeName.List,
                      options: {
                        selectAndDropDownInput: {
                          label: translate(
                            'translate#usm.title.add.user.modal.label.existing.person',
                          ),
                          placeholder: translate(
                            'translate#usm.title.add.user.modal.placeholder.existing.person',
                          ),
                        },
                      },
                    }}
                    fieldName={AddNewUserPersonalCardField.ExistingPerson}
                    formikValues={values}
                    setFormikFieldValue={setFieldValue}
                    lookupFieldConfig={lookupConfig.find(
                      field => field.fieldName === AddNewUserPersonalCardField.ExistingPerson,
                    )}
                    backupOptions={generatedFieldBackupOptions}
                    isLoading={isLoadingConfig}
                  />
                </Grid>

                <PersonalInfo
                  size
                  disabled={!!values[AddNewUserPersonalCardField.ExistingPerson]}
                />

                <PersonalInSalutationAndGender
                  size
                  disabled={!!values[AddNewUserPersonalCardField.ExistingPerson]}
                />
              </Grid>

              <SubTitle />
              <Typography>
                {translate('translate#usm.title.add.user.modal.menu.button.login.information')}
              </Typography>
            </>
            <>
              <Grid container spacing={3}>
                <LoginFields size isAddNewUser />
                <TryCountField tryCountDefault={5} size />
              </Grid>
              <div className={classes.footerLoginInfo}>
                <Field name={AddNewUserPersonalCardField.ChangePassword}>
                  {createCheckboxField({
                    label: translate(
                      'translate#usm.title.add.user.modal.change.password.on.next.login',
                    ),
                    styles: {
                      label: classes.labelCheckBox,
                    },
                    width: 'fit-content',
                  })}
                </Field>
              </div>
            </>
            <>
              <SubTitle />
              <Typography>
                {translate('translate#usm.title.add.user.modal.menu.button.company.information')}
              </Typography>

              <UserInfoField
                setFormikFieldValue={setFieldValue}
                formikValues={values}
                lookupFieldConfig={lookupConfig}
                isLoading={isLoadingConfig}
                useBackupValues={useBackupValuesCompanyAndShortName}
                generateCompanyName={generateCompanyName}
              />
              <>
                <Grid container spacing={3}>
                  <UserInfoDateFields
                    values={values}
                    size={4}
                    style={classes.labelCheckBox}
                    sizeDateField={3}
                    sizeCheckBoxField
                    defaultDate={oneYearAhead}
                  />
                </Grid>
                <Grid className={classes.addButtonLocation} item xs>
                  <Button
                    className={classes.addButton}
                    startIcon={<AddButton />}
                    onClick={handleAddNewUserToTable(values)}
                    disabled={!!isUserInformationValid}
                  >
                    <Typography className={classes.buttonText}>
                      {translate('translate#usm.title.add.user.modal.action.add')}
                    </Typography>
                  </Button>

                  <div>
                    <Button
                      className={classes.deleteButton}
                      onClick={handleDeleteTableRows}
                      disabled={!userInfoTable.length}
                    >
                      <img src={DeleteIcon} alt="delete-icon" />
                      <Typography className={classes.deleteButtonText}>
                        {translate('translate#usm.title.add.user.modal.action.delete')}
                      </Typography>
                    </Button>
                  </div>
                </Grid>
              </>

              <UserInformationTable
                headers={headersUserInformationTable}
                data={userInfoTable}
                isLoading={false}
                handleAddNewRow={handleAddNewRow}
                handleChangeRow={handleChangeRow}
                handleDeleteRows={handleDeleteRows}
                handleSelectRow={handleSelectRow}
                selectedRows={selectedRows}
                changedRows={changedRows}
                deletedRows={deletedRows}
                newRows={newRows}
                checkboxColumnsNames={['disabled', 'allowAutoProlong']}
              />
              <div className={classes.footerUserInfo}>
                <div>
                  <CreatedCancelButton handleClose={handleCloseForm} style={classes.cancelButton} />
                </div>
                <div>
                  <Button
                    className={classes.nextButton}
                    // type="submit"
                    onClick={() => handleSubmitForm(values, formik)}
                    variant={!(isValid && dirty) ? 'outlined' : 'contained'}
                    palette="primary"
                    disabled={!userInfoTable.length}
                  >
                    <Typography>
                      <FormattedMessage
                        id="usm.title.add.user.modal.action.finish"
                        defaultMessage="Finish"
                      />
                    </Typography>
                  </Button>
                </div>
              </div>
            </>
          </Form>
        </FormikProvider>
      </div>
    </Dialog>
  )
}

export default AddUserModalUSM
