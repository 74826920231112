export enum CashOrderActions {
  AcceptByRecipient = '@/ACCEPT_BY_RECIPIENT',
  ApplyStage = '@/APPLY_STAGE',
  ApproveOrderRequest = '@/APPROVE_ORDER',
  AssignBagForCashier = '@/ASSIGN_BAG_FOR_CASHIER',
  AssignBagForStorage = '@/ASSIGN_BAG_FOR_STORAGE',
  AssignBagSuccess = '@/ASSIGN_BAG_SUCCESS',
  AssingTeamForOrder = '@/ASSING_TEAM_FOR_ORDER',
  ChangePagingRequest = '@/CHANGE_CASH_ORDERS_PAGING_REQUEST',
  ChangePagingResponse = '@/CHANGE_CASH_ORDERS_PAGING_RESPONSE',
  ChangeSelectedFilters = '@/CHANGE_CASH_ORDERS_SELECTED_FILTERS',
  ClearSelectedFilters = '@/CLEAR_CASH_ORDERS_SELECTED_FILTERS',
  CompleteCashOrder = '@/COMPLETE_CASH_ORDER',
  CreateCashDeliveryOrderRequest = '@/CREATE_CASH_DELIVERY_ORDER_REQUEST',
  CreateCashDeliveryOrderResponse = '@/CREATE_CASH_DELIVERY_ORDER_RESPONSE',
  CreateDocumentsRequest = '@/CREATE_DOCUMENTS',
  CreateOrderRequest = '@/CREATE_ORDER_REQUEST',
  CreateOrderResponse = '@/CREATE_ORDER_RESPONSE',
  CreateWorkOrder = '@/CREATE_WORK_ORDER',
  DeclineCashOrder = '@/DECLINE_CASH_ORDER',
  EditCashDeliveryOrderRequest = '@/EDIT_CASH_DELIVERY_ORDER_REQUEST',
  EditCashOrderRequest = '@/EDIT_CASH_ORDER_REQUEST',
  GetAvailableVaultsRequest = '@/GET_AVAILABLE_VAULTS_REQUEST',
  GetAvailableVaultsResponse = '@/GET_AVAILABLE_VAULTS_RESPONSE',
  GetCashOrderBagsRequest = '@/GET_CASH_ORDER_BAGS_REQUEST',
  GetCashOrderBagsResponse = '@/GET_CASH_ORDER_BAGS_RESPONSE',
  GetCashOrderCommentsRequest = '@/GET_CASH_ORDER_COMMENTS_REQUEST',
  GetCashOrderCommentsResponse = '@/GET_CASH_ORDER_COMMENTS_RESPONSE',
  GetCashOrderDetailsRequest = '@/GET_CASH_ORDER_DETAILS_REQUEST',
  GetCashOrderDetailsResponse = '@/GET_CASH_ORDER_DETAILS_RESPONSE',
  GetCashOrderDocumentRequest = '@/GET_CASH_ORDER_DOCUMENT_REQUEST',
  GetCashOrderDocumentResponse = '@/GET_CASH_ORDER_DOCUMENT_RESPONSE',
  GetCashOrderDocumentTypesRequest = '@/GET_CASH_ORDER_DOCUMENT_TYPES_REQUEST',
  GetCashOrderDocumentTypesResponse = '@/GET_CASH_ORDER_DOCUMENT_TYPES_RESPONSE',
  GetCashOrdersRequest = '@/GET_CASH_ORDERS_REQUEST',
  GetCashOrdersResponse = '@/CASH_ORDERS',
  GetCashOrdersResponseFail = '@/GET_CASH_ORDERS_RESPONSE_FAIL',
  GetCashOrderStagesRequest = '@/GET_CASH_ORDER_STAGES_REQUEST',
  GetCashOrderStagesResponse = '@/GET_CASH_ORDER_STAGES_RESPONSE',
  GetCashOrderStatusesRequest = '@/GET_CASH_ORDER_STATUSES_REQUEST',
  GetCashOrderStatusesResponse = '@/GET_CASH_ORDER_STATUSES_RESPONSE',
  GetCashOrderTypesRequest = '@/GET_CASH_ORDER_TYPES_REQUEST',
  GetCashOrderTypesResponse = '@/GET_CASH_ORDER_TYPES_RESPONSE',
  GetCreateOrderFormValuablesRequest = '@/GET_CREATE_ORDER_FORM_VALUABLES_REQUEST',
  GetCreateOrderFormValuablesResponse = '@/GET_CREATE_ORDER_FORM_VALUABLES_RESPONSE',
  GetCurrenciesRequest = '@/GET_CASH_ORDER_CURRENCIES_REQUEST',
  GetCurrenciesResponse = '@/GET_CASH_ORDER_CURRENCIES_RESPONSE',
  GetCurrenciesResponseFail = '@/GET_CASH_ORDER_CURRENCIES_RESPONSE_FAIL',
  GetExistingCashBagsRequest = '@/GET_EXISTING_CASH_BAGS_REQUEST',
  GetExistingCashBagsResponse = '@/GET_EXISTING_CASH_BAGS_RESPONSE',
  GetOrderLoadsRequest = '@/GET_ORDER_LOADS_REQUEST',
  GetOrderLoadsResponse = '@/GET_ORDER_LOADS_RESPONSE',
  GetRecieverVaultsFromTransmitterRequest = '@/GET_RECIEVER_VAULTS_REQUEST_FROM_TRANSMITTER',
  GetRecieverVaultsRequest = '@/GET_RECIEVER_VAULTS_REQUEST',
  GetRecieverVaultsResponse = '@/GET_RECIEVER_VAULTS_RESPONSE',
  GetTransmitterVaultsFromRecieverRequest = '@/GET_TRANSMITTER_VAULTS_REQUEST_FROM_RECEIVER',
  GetTransmitterVaultsRequest = '@/GET_TRANSMITTER_VAULTS_REQUEST',
  GetTransmitterVaultsResponse = '@/GET_TRANSMITTER_VAULTS_RESPONSE',
  RecirculateByRecipientRequest = '@/RECIRCULATE_BY_RECIPIENT',
  RecirculateBySenderRequest = '@/RECIRCULATE_BY_SENDER',
  SendToCollectorsRequest = '@/SEND_TO_COLLECTORS',
  SendToExecuteRequest = '@/SEND_TO_EXECUTE',
  SetOrderEditStatus = '@/SET_ORDER_EDIT_STATUS',
  SetPanelLayout = '@/SET_PANEL_LAYOUT',
  SetSelectedCashOrder = '@/SET_SELECTED_CASH_ORDER',
  SetSelectedCashOrderBag = '@/SET_SELECTED_CASH_ORDER_BAG',
  StagePassed = '@/STAGE_PASSED',
  ValidateCashBagNumberRequest = '@/VALIDATE_CASH_BAG_NUMBER_REQUEST',
  ValidateCashBagNumberResponse = '@/VALIDATE_CASH_BAG_NUMBER_RESPONSE',
}
