export enum Permission {
  // Dashboard
  Open_dashboard_page = 'AE_Dashboard_Open_Page',
  Create_clone_widget_pages_and_widgets = 'AE_Dashboard_Create_Or_Edit_Page',
  Publish_pages = 'AE_Dashboard_Publish_Page',
  Subscribe_to_published_pages = 'AE_Dashboard_Subscribe_To_Page',

  // Devices
  Open_devices_page = 'AE_Devices_In_Navigation',
  Devices_Open_Details = 'AE_Devices_Open_Details',
  View_Histogram = 'AE_Devices_View_Histogram',
  View_Properties = 'AE_Devices_View_Properties',
  Get_Full_Status = 'AE_Devices_Get_Full_Status',
  Live_Slideshow = 'AE_Devices_Live_Slideshow',
  Make_Snapshot = 'AE_Devices_Make_Snapshot',
  Restart_Core = 'AE_Devices_Restart_Core',
  Core_Toggle = 'AE_Devices_Core_Toggle',
  Logging_Toggle = 'AE_Devices_Logging_Toggle',
  Devices_Delete_Media = 'AE_Devices_Delete_Media',
  ATM_Logs = 'AE_Devices_ATM_Logs',

  // Alarms
  Alarms = 'AE_Security_Alarms_In_Navigation',
  Alarms_View_Media = 'AE_Security_Alarms_View_Media',

  // Transactions
  Transactions = 'AE_Transactions_In_Navigation',
  Transactions_View_Media = 'AE_Transactions_View_Media',

  // Events
  Technical_Events = 'AE_Technical_Events_In_Navigation',
  Technical_Events_View_Media = 'AE_Technical_Events_View_Media',

  // Reports
  Reports = 'AE_Reports_In_Navigation',
  Reports_create_edit = 'AE_Reports_Create_Or_Edit',
  Reports_execute = 'AE_Reports_Execute',
  Reports_edit_executed = 'AE_Reports_Edit_Executed',
  Reports_download = 'AE_Reports_Download',
  Reports_delete = 'AE_Reports_Delete',
  Reports_delete_executed = 'AE_Reports_Delete_Executed',

  // Rfm
  RFM = 'AE_RFM_In_Navigation',
  RFM_single_device = 'AE_RFM_Single_Device_In_Navigation',
  RFM_multiple_devices = 'AE_RFM_Multiple_Devices_In_Navigation',
  RFM_operation_log = 'AE_RFM_Operation_Log_In_Navigation',
  RFM_operation_log_delete = 'AE_RFM_Operation_Log_Delete',
  RFM_operation_log_details = 'AE_RFM_Operation_Log_Details',
  RFM_read_from_folder = 'AE_RFM_Read_From_Folder',
  RFM_write_To_folder = 'AE_RFM_Write_To_Folder',
  RFM_execute_in_folder = 'AE_RFM_Execute_In_Folder',
  RFM_transaction_history = 'AE_RFM_Transaction_History_Log_In_Navigation',

  // Administration
  Administration = 'AE_Administration_In_Navigation',
  Licenses = 'AE_Licenses_In_Navigation',
  License_acquire = 'AE_License_Acquire',
  License_release = 'AE_License_Release',
  Blacklist = 'AE_Blacklist_In_Navigation',
  Add_edit_blacklist = 'AE_Blacklist_Add_Or_Edit_Entry',
  Delete_blacklist = 'AE_Blacklist_Delete_Entry',
  Device_Settings = 'AE_Device_Settings_In_Navigation',
  Events_settings_view = 'AE_Events_Settings_View',
  Events_settings_save = 'AE_Events_Settings_Save',
  ATMeye_settings_view = 'AE_ATMeye_Settings_View',
  ATMeye_settings_save = 'AE_ATMeye_Settings_Save',
  Camera_settings_view = 'AE_Camera_Day_Night_Settings_View',
  Camera_settings_save = 'AE_Camera_Day_Night_Settings_Save',
  Central_settings = 'AE_Central_Settings_In_Navigation',
  Save_central_settings = 'AE_Central_Settings_Save',
  Transactions_history_settings = 'AE_Transactions_History_Settings_In_Navigation',
  Transactions_history_uploading_data_tick = 'AE_Transactions_History_Uploading_Data_Tick',
  Transactions_history_edit_download_date_time = 'AE_Transactions_History_Edit_Download_Date_Time',
  Transactions_history_delete_ATM_data_tick = 'AE_Transactions_History_Delete_ATM_Data_Tick',
  Multiple_snapshot = 'AE_Multiple_Snapshot_In_Navigation',
  New_multiple_snapshot = 'AE_Multiple_Snapshot_New',
  Save_multiple_snapshot = 'AE_Multiple_Snapshot_Save',
  Delete_multiple_snapshot = 'AE_Multiple_Snapshot_Delete',
  Events_dictionary = 'AE_Events_Dictionary_In_Navigation',
  Add_edit_events_dictionary = 'AE_Events_Dictionary_Add_Or_Edit_Entry',
  Delete_events_dictionary = 'AE_Events_Dictionary_Delete_Entry',
  Options = 'AE_Options_In_Navigation',

  // Notification
  Notifications_security_alarms = 'AE_Notifications_Security_Alarms',
  Notifications_technical_events = 'AE_Notifications_Technical_Events',

  // Help
  Help = 'AE_Help_In_Navigation',
}

export type PermissionsTypes = {
  [key in Permission]: boolean
}
