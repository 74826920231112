import { AnyAction } from 'redux'
import { call, put, race, select, take, takeLatest } from 'redux-saga/effects'

import { NewCommonApi } from '@/api/atmeye/common/newCommonAPI'
import { ATMEYE_BLOCK_PREFIX, AuthorizationActions } from '@/constants'
import { CommonActions } from '@/constants/atmeye/actions/actionsCommon'
import { MEDIA_MESSAGES } from '@/constants/messages'
import { AppState } from '@/store/reducers'

import { commonAtmEyeActions, getAvailableModulesResponse } from './actions'

function* getMedia({
  payload: { eventId, eventType, getUpdateTableRequest, setChangeTableData, requestFileType },
}: AnyAction): Generator {
  try {
    const media: any = yield select((state: AppState) => state.commonATMeye)
    if (media.media.data[eventId] && !media.media.data[eventId].image.errorMessage) {
      yield put(commonAtmEyeActions.setCurrentMedia({ eventId }))
      return
    }

    const response: any = yield call(NewCommonApi.getMedia, {
      eventId,
      eventType: requestFileType || 'TRANSACTION',
    })

    if (response.errorMessage) {
      throw new Error(response)
    }

    yield put(
      commonAtmEyeActions.setMediaRequest({
        eventId,
        image: response.url,
        type: eventType,
        fetchingEnabled: false,
      }),
    )

    if (eventType === 'JPG') {
      // todo remove
      if (getUpdateTableRequest) {
        const condition = (el: any): boolean =>
          el.item.event_id === eventId && el.media_store_type !== 'DATABASE_FILE'
        const updatedFields = { media_store_type: 'DATABASE_FILE' }

        yield getUpdateTableRequest({ condition, updatedFields })
      }
    }

    yield put(setChangeTableData(eventId))
  } catch (e) {
    yield put(
      commonAtmEyeActions.setMediaErrorMessage({
        eventId,
        message: MEDIA_MESSAGES.includes(e.errorMessage)
          ? e.errorMessage
          : 'atmeye.message.cantDownloadFileFromAtm',
      }),
    )
  }
}

function* signOutAtmeye(): Generator {
  if (window.location.pathname.includes(ATMEYE_BLOCK_PREFIX)) {
    window.location.reload()
  }
}

function* getAvailableModulesRequset(): Generator {
  try {
    const response: any = yield call(NewCommonApi.getAvailableLicences)

    yield put(getAvailableModulesResponse(response))
  } catch (e) {
    yield put(getAvailableModulesResponse(null))
  }
}

export default function*(): Generator {
  yield takeLatest(AuthorizationActions.SignOut, signOutAtmeye)
  yield takeLatest(CommonActions.getAvailableModulesRequest, getAvailableModulesRequset)
  yield takeLatest(CommonActions.GetMediaRequest, function*(...args) {
    yield race([call(getMedia, ...args), take(CommonActions.ClearCurrentMedia)])
  })
}
