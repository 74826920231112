import { Field as FormikField } from 'formik'
import { debounce } from 'lodash-es'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import { createDropDownInputField } from '@/components/controls/DropDownInput'
import { createMultipleSelectField } from '@/components/controls/MultipleSelect'
import { createSelectField } from '@/components/controls/Select'
import { useDidUpdateEffect } from '@/utils/hooks/hooks'

import { FieldTypeName, Props } from './types'

const Lookups = ({
  fieldName,
  fieldTitle,
  field,
  isDisabled,
  setFormikFieldValue,
  onCall,
  onSelect,
  defaultValue,
  isDependsOn,
  dependsOn = [],
  className,
}: Props): ReactElement => {
  const initialValue =
    defaultValue && defaultValue.name && defaultValue.value
      ? {
          name: String(defaultValue.name),
          value: String(defaultValue.value),
        }
      : null

  const [dataIsLoading, setDataIsLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<{ value: string; name: string }[]>([])

  const getOptions = async (fragment: string): Promise<any> => {
    try {
      setDataIsLoading(true)

      const response = await onCall(fragment)

      const options = response.values.map(({ id, name }) => ({ value: id, name }))

      setOptions(options)

      setDataIsLoading(false)
    } catch (error) {
      throw new Error(error)
    }
  }

  const handleSearch = useCallback(
    debounce((value: string) => {
      if (value.length >= 3) {
        getOptions(value)
      }
    }, 300),
    dependsOn,
  )

  const handleOpen = () => (): void => {
    getOptions('')
  }

  useEffect(() => {
    if (!options.find(item => String(item.value) === initialValue?.value)) setOptions([])
  }, [defaultValue?.value])

  useDidUpdateEffect(() => {
    if (isDependsOn) {
      setFormikFieldValue(fieldName, '')
      setFormikFieldValue(fieldTitle, '')
      setOptions([])
    }
  }, dependsOn)

  const Field = useMemo(() => {
    switch (field.type) {
      case FieldTypeName.Search:
        return createDropDownInputField({
          isLoading: dataIsLoading,
          label: field.options?.label,
          placeholder: field.options?.placeholder,
          options: options.length ? options : initialValue ? [initialValue] : [],
          shrink: true,
          timer: field.options?.requestDelay,
          filtering: false,
          neverDisable: false,
          disabled: isDisabled,
          required: field.options?.required,
          onTextChange: handleSearch,
          withoutSingleOption: true,
          onChange: value => {
            const name = options.find(item => item.value === value)?.name || ''
            if (field.options.isNumber) setFormikFieldValue(fieldName, Number(value))
            else setFormikFieldValue(fieldName, value)
            setFormikFieldValue(fieldTitle, name)

            if (onSelect) {
              const item = options.find(item => String(item.value) === String(value))
              onSelect(item)
            }
          },
          className: className,
        })

      case FieldTypeName.List:
        return createSelectField({
          isLoading: dataIsLoading,
          label: field.options?.label,
          placeholder: field.options?.placeholder,
          options: options.length ? options : initialValue ? [initialValue] : [],
          shrink: true,
          withoutCheckerForSingleOption: true,
          withAutofillForSingleOption: true,
          disabled: isDisabled,
          required: field.options?.required,
          onOpen: handleOpen(),
          onChange: event => {
            const name = options.find(item => item.value === event.target.value)?.name || ''
            if (field.options.isNumber && event.target.value)
              setFormikFieldValue(fieldName, Number(event.target.value))
            else setFormikFieldValue(fieldName, event.target.value)
            setFormikFieldValue(fieldTitle, name)

            if (onSelect) {
              const item = options.find(item => String(item.value) === String(event.target.value))
              onSelect(item)
            }
          },
          className: className,
        })

      case FieldTypeName.MultiSelect:
        return createMultipleSelectField({
          isLoading: dataIsLoading,
          label: field.options?.label,
          placeholder: field.options?.placeholder,
          // options: options.length ? options : initialValue ? [initialValue] : [],
          options: [
            { name: 'One', value: '5' },
            { name: 'Four', value: '4' },
          ],
          shrink: true,
          SearchInputBlock: true,
          parameterName: fieldName,
          disabled: isDisabled,
          value: options.map(it => it.value),
          required: field.options?.required,
          onOpen: handleOpen(),
          onChangeMultipleSelect: values => {
            const selected: { value: string; name: string }[] = []
            values.forEach(value => {
              const item = options.find(item => item.value === value)
              if (item) selected.push(item)
            })
            if (onSelect) {
              onSelect(selected)
            }
          },
        })
    }
  }, [...dependsOn, defaultValue, initialValue, options, dataIsLoading, isDisabled])

  return <FormikField name={fieldName}>{Field}</FormikField>
}

export default Lookups
