import { takeLatest, put, call, takeEvery } from 'redux-saga/effects'
import { SharedApi } from '@/api/cm/sharedApi'
import { CMActions } from '@/constants'
import { SagaIterator } from 'redux-saga'
import {
  getShrCassettesPropsResponse,
  getShrCassettesPropsResponseFail,
  getShrSettingsResponse,
  getShrSettingsResponseFail,
  setShrCassettesPropsResponse,
  setShrCassettesPropsResponseFail,
} from './actions'
import { AnyAction } from 'redux'

function* getCassettesProps({ payload }: AnyAction): SagaIterator {
  try {
    const response = yield call(SharedApi.getCassettesProps, payload)
    yield put(getShrCassettesPropsResponse(response))
  } catch (e) {
    yield put(getShrCassettesPropsResponseFail(e))
  }
}

function* setCassettesProps({ payload: { guid, requestBody } }: AnyAction): SagaIterator {
  try {
    const response = yield call(SharedApi.setCassettesProps, guid, requestBody)
    yield put(setShrCassettesPropsResponse(response))
  } catch (e) {
    yield put(setShrCassettesPropsResponseFail(e))
  }
}

function* handleCashPointPriority({ payload }: AnyAction): SagaIterator {
  yield call(SharedApi.setPriority, payload)
}

function* getSettings(): SagaIterator {
  try {
    const response = yield call(SharedApi.getSettings)
    yield put(getShrSettingsResponse(response))
  } catch (e) {
    yield put(getShrSettingsResponseFail(e))
  }
}

export default function*(): Generator {
  yield takeLatest(CMActions.ShrGetSettingsRequest, getSettings)
  yield takeLatest(CMActions.ShrGetCassettesPropsRequest, getCassettesProps)
  yield takeLatest(CMActions.ShrSetCassettesPropsRequest, setCassettesProps)
  yield takeLatest(CMActions.ShrSetCashPointPriority, handleCashPointPriority)
}
