// core
import { Grid } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import { Alert } from '@material-ui/lab'
import { Field, Form, FormikProvider } from 'formik'
import React, { FC, ReactElement, useEffect } from 'react'
import { useHistory } from 'react-router'

// components
import CloseButton from '@/components/blocks/CloseButton/index'
import { createCheckboxField } from '@/components/controls/Checkbox'
import GeneratedField from '@/components/controls/GeneratedField'
// other temp
import { FieldTypeName } from '@/components/controls/GeneratedField/types'
import { createTextInputField } from '@/components/controls/TextInput'
import { GenericInput } from '@/components/pages/sd/reports/components/ReportsModal/components/ReportModalForm/components/GenericInput'
import { useUserGroupModal } from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/AddUserGroupModal/hooks'
// helpers
import {
  CreateButton,
  CreatedCancelButton,
} from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/helpers/createButton'
import { AppNavigationRoutes, USM_BLOCK_PREFIX } from '@/constants'
// hooks
import { useTranslate } from '@/utils/internalization'

// styles
import { useClasses } from './styles'
// types
import { AddNewUserGroupCardField, Props } from './types'

const AddUserGroupModalUSM: FC<Props> = ({
  open,
  handleClose,
  disableBackdropClick,
  disableEscapeKeyDown,
}: Props): ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const history = useHistory()
  const {
    handleCloseForm,
    values,
    formik,
    dirty,
    isValid,
    handleSubmit,
    isLoading,
    lookupConfig,
    setFieldValue,
    errorMessages,
    isAddedUserGroup,
  } = useUserGroupModal({ handleClose })

  useEffect(() => {
    if (isAddedUserGroup)
      history.push(`${USM_BLOCK_PREFIX}${AppNavigationRoutes.USmGroupPermissions}`)
  }, [isAddedUserGroup])

  const onClose = (event: any, reason: 'backdropClick' | 'escapeKeyDown'): void | boolean => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return false
    }

    if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false
    }

    if (typeof handleClose === 'function') {
      handleClose()
    }
  }

  const DescriptionInput = createTextInputField({
    multiline: true,
    rows: 4,
    fullWidth: true,
    label: translate('translate#usm.title.add.user.group.modal.label.description'),
    placeholder: translate('translate#usm.title.add.user.group.modal.placeholder.description'),
    InputLabelProps: {
      shrink: true,
    },
  })

  const UserGroupDropDownJSX = (
    <GeneratedField
      field={{
        type: FieldTypeName.List,
        options: {
          selectAndDropDownInput: {
            label: translate('translate#usm.title.drawer.action.user.group'),
            placeholder: 'Choose existing User Group name',
            required: true,
          },
        },
      }}
      fieldName={AddNewUserGroupCardField.UserGroup}
      formikValues={values}
      setFormikFieldValue={setFieldValue}
      lookupFieldConfig={lookupConfig?.find(field => field.fieldName === 'userGroupId')}
      isLoading={isLoading}
    />
  )
  // const errorJSX = error && <Alert severity="error">{translate(`translate#${error}`)}</Alert>

  const errorJSX =
    !!errorMessages.length &&
    errorMessages.map(e => (
      <Alert severity="error" key={e}>
        {e}
      </Alert>
    ))

  return (
    <Dialog fullWidth maxWidth="xs" open={open} keepMounted onClose={onClose}>
      <div className={classes.wrapper}>
        <p className={classes.headerTitle}>
          {translate('translate#usm.title.add.user.group.modal.new.user.group')}
        </p>
        {errorJSX}
        <CloseButton onClick={handleCloseForm} className={classes.closingWindow} />

        <FormikProvider value={formik}>
          <Form className={classes.form} onSubmit={handleSubmit}>
            <Grid item md={12}>
              <Field name={AddNewUserGroupCardField.UserGroupName}>
                {GenericInput({
                  label: translate(
                    'translate#usm.title.add.user.group.modal.label.user.group.name',
                  ),
                  placeholder: translate(
                    'translate#usm.title.add.user.group.modal.placeholder.user.group.name',
                  ),
                  required: true,
                })}
              </Field>
            </Grid>
            <Grid item md={12}>
              <Field name={AddNewUserGroupCardField.Description}>{DescriptionInput}</Field>
            </Grid>
            <div className={classes.CheckBox}>
              <Field name={AddNewUserGroupCardField.ClonePermission}>
                {createCheckboxField({
                  label: translate(
                    'translate#usm.title.add.user.group.modal.label.clone.existing.group.permissions',
                  ),
                  styles: {
                    label: classes.labelCheckBox,
                  },
                  width: 'fit-content',
                })}
              </Field>
            </div>
            {values[AddNewUserGroupCardField.ClonePermission] && (
              <Grid item md={12}>
                {UserGroupDropDownJSX}
              </Grid>
            )}
            <div className={classes.buttonsStyle}>
              <CreatedCancelButton style={classes.cancelButton} handleClose={handleCloseForm} />
              <CreateButton
                translate={{ id: 'usm.title.add.user.modal.action.save', message: 'Save' }}
                value=""
                styleForButtons={classes.saveButton}
                isDisabled={!(dirty && isValid)}
              />
            </div>
          </Form>
        </FormikProvider>
      </div>
    </Dialog>
  )
}

export default AddUserGroupModalUSM
