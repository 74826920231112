import React from 'react'

import { store } from '@/App'

export const ATMeyeRoutes = React.lazy(async () => {
  const {
    default: { reducer: reducerDevices, saga: sagaDevices },
  } = await import('@/store/atmeye/devices')

  store.injectReducer('devices', reducerDevices)
  store.injectSaga('devices', sagaDevices)

  const {
    default: { reducer: reducerReports, saga: sagaReports },
  } = await import('@/store/atmeye/reports')

  store.injectReducer('atemeyeReports', reducerReports)
  store.injectSaga('atemeyeReports', sagaReports)

  const {
    default: { reducer: reducerRfm, saga: sagaRfm },
  } = await import('@/store/atmeye/rfm')

  store.injectReducer('rfm', reducerRfm)
  store.injectSaga('rfm', sagaRfm)

  const {
    default: { reducer: reducerDeviceDayNightSettings, saga: sagaDeviceDeviceDayNightSettings },
  } = await import('@/store/atmeye/cameraDayNightSettings')

  store.injectReducer('deviceDayNightSettings', reducerDeviceDayNightSettings)
  store.injectSaga('deviceDayNightSettings', sagaDeviceDeviceDayNightSettings)

  const {
    default: { reducer: multipleSnapshotsReducer, saga: multipleSnapshotsSaga },
  } = await import('@/store/atmeye/multipleSnapshots')

  store.injectReducer('multipleSnapshots', multipleSnapshotsReducer)
  store.injectSaga('multipleSnapshotsSaga', multipleSnapshotsSaga)

  const {
    default: { reducer: centralSettingsReducer, saga: centralSettingsSaga },
  } = await import('@/store/atmeye/centralSettings')

  store.injectReducer('centralSettings', centralSettingsReducer)
  store.injectSaga('centralSettingsSaga', centralSettingsSaga)

  const {
    default: { reducer: notificationReducer, saga: notificationSaga },
  } = await import('@/store/notifications')

  store.injectReducer('notifications', notificationReducer)
  store.injectSaga('notificationSaga', notificationSaga)

  const {
    default: { reducer: commonATMeyeReducer, saga: commonATMeyeSaga },
  } = await import('@/store/atmeye/common')

  store.injectReducer('commonATMeye', commonATMeyeReducer)
  store.injectSaga('commonATMeyeSaga', commonATMeyeSaga)

  return import('@/components/routers/atmeye')
})
