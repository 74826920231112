export interface Props {
  open: boolean
  handleClose: () => void
  disableBackdropClick?: boolean
  disableEscapeKeyDown?: boolean
}

enum SecurityNetworksObjectsMergeField {
  UserFromCopy = 'userCopyFrom',
  UserToCopy = 'userCopyTo',
}

export type SecurityNetworksObjectsMergeValues = {
  [SecurityNetworksObjectsMergeField.UserFromCopy]: string
  [SecurityNetworksObjectsMergeField.UserToCopy]: string
}

enum CommonSecurityNetworksObjectsMergeColumns {
  ObjectTypesToMerge = 'type',
  ObjectTypesToMergeTitle = 'Object.types.to.merge',
  checkbox = 'checkbox',
}

export const headersSecurityNetworksObjectsMergeTable = [
  {
    name: CommonSecurityNetworksObjectsMergeColumns.checkbox,
    label: '',
  },
  {
    name: CommonSecurityNetworksObjectsMergeColumns.ObjectTypesToMerge,
    label: CommonSecurityNetworksObjectsMergeColumns.ObjectTypesToMergeTitle,
  },
]

enum SecurityNetworksObjectsSummaryMergeColumns {
  ObjectWillBeAdded = 'type',
  ObjectWillBeAddedTitle = 'Object.that.will.be.added',
}

export const headersSecurityNetworksObjectsMergeSummaryTable = [
  {
    name: SecurityNetworksObjectsSummaryMergeColumns.ObjectWillBeAdded,
    label: SecurityNetworksObjectsSummaryMergeColumns.ObjectWillBeAddedTitle,
  },
]
