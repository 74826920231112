import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getVaultsBags = (state: AppState): fromReducer.State => {
  return state.vaultV1Bags
}

export const getBags = createSelector(getVaultsBags, state => state.bags)

export const getCanCreateBag = createSelector(getBags, state => state.canCreateBag)
export const getSelectedBag = createSelector(getBags, state => state.selected)

export const getBagsReports = createSelector(getBags, state => state.reports)
export const getBagsFilter = createSelector(getBags, state => state.filter)
export const getBagsPaging = createSelector(getBags, state => state.paging)
export const getBagsSort = createSelector(getBags, state => ({
  sortColumn: state.sortColumn,
  sortOrder: state.sortOrder,
}))

export const getBagsTotal = createSelector(getBags, state => state.total)

export const getBagsModals = createSelector(getVaultsBags, state => state.modals)
export const getBagsSettingsModal = createSelector(getBagsModals, state => state.settingsModal)
export const getBagModalIsLoading = createSelector(getBagsModals, state => state.bagModalLoading)

export const getDetailsIsChanged = createSelector(
  getVaultsBags,
  state => state.details.isDetailsChanged,
)
