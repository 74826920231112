import { Theme } from '@material-ui/core/styles'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { styledComponentTheme } from '@/theme'

interface StylesProps {
  collapse: boolean
}

export const useClasses = makeStyles<Theme, StylesProps>(({ spacing, palette, shadows }) => ({
  title: {
    color: palette.primary.main,
    fontWeight: 'bold',
    marginLeft: spacing(1),
  },
  icon: {
    color: palette.grey[200],
  },
  cell: {
    height: spacing(5),
    minHeight: spacing(5),
    maxHeight: spacing(5),
    padding: spacing(0, 2),
    borderBottom: 0,
    whiteSpace: 'nowrap',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    padding: '0 6px 0 6px',
  },
  withBlueColor: {
    color: '#50A8AE',
  },
  squareWrapper: {
    width: spacing(2),
    height: spacing(2),
    border: `1px solid ${palette.primary.main}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '2px',
  },
  square: ({ collapse }: StylesProps) => ({
    display: collapse ? 'block' : 'none',
    width: spacing(1.5),
    height: spacing(1.5),
    backgroundColor: palette.primary.main,
    borderRadius: '2px',
  }),
  mainColumn: {
    display: 'flex',
    alignItems: 'center',
  },
  itemColumn: {
    paddingLeft: spacing(2),
  },
  itemColumnWithCheckbox: {
    paddingLeft: 0,

    '&:nth-child(2)': {
      paddingLeft: spacing(4),
    },
  },
  columnWithCheckbox: {
    display: 'flex',
    alignItems: 'center',
  },
  withoutPaddingMargin: {
    paddingLeft: 0,
    marginLeft: 0,
  },
  withAdditionalPadding: {
    paddingLeft: spacing(0.5),
  },
  cellIcon: {
    width: spacing(1.5),
    marginRight: spacing(1),
  },
  iconSize: {
    fontSize: styledComponentTheme.fontSizes.big,
  },
  deadlineGreen: {
    color: '#1AD36F',
  },
  deadlineYellow: {
    color: '#F2C94C',
  },
  deadlineRed: {
    color: '#CB362D',
  },
  deadlineEmpty: {
    color: '#FFFFFF',
  },
  createdIcon: {
    color: '#47D08F',
  },
  cellWrapper: {
    padding: spacing(0.25, 1),
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: spacing(1.5),
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: shadows[3],
    },
    '&:nth-child(even)': {
      background: '#f8f8f8',
    },
  },
}))
