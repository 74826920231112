import { useCallback, useEffect, useState } from 'react'

interface CollapseRowProps {
  isAllRowsOpened: boolean
}

interface ResultProps {
  isCollapseOpen: boolean
  handleToggleOpen: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const useCollapseRow = (props: CollapseRowProps): ResultProps => {
  const { isAllRowsOpened } = props

  const [isCollapseOpen, setIsCollapseOpen] = useState(isAllRowsOpened || false)

  useEffect(() => {
    setIsCollapseOpen(isAllRowsOpened)
  }, [isAllRowsOpened])

  const handleToggleOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation()

      setIsCollapseOpen(prev => !prev)
    },
    [],
  )

  return {
    isCollapseOpen,
    handleToggleOpen,
  }
}
