export enum AdditionalCostsData {
  Id = 'id',
  Company = 'company',
  Department = 'department',
  CostType = 'costType',
  Amount = 'amount',
  Year = 'year',
  Month = 'month',
  IncToWorkCosts = 'incToWorkCosts',
}

export interface AdditionalCostsTableRow {
  [AdditionalCostsData.Id]: string
  [AdditionalCostsData.Company]: string
  [AdditionalCostsData.Department]: string
  [AdditionalCostsData.CostType]: string
  [AdditionalCostsData.Amount]: string
  [AdditionalCostsData.Year]: string
  [AdditionalCostsData.Month]: string
  [AdditionalCostsData.IncToWorkCosts]: boolean
}
