import { AdmAppSetting } from '@/api/cm/swaggerGeneratedApi'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class AppConfigApi {
  private static requestService = getRequestServiceCM()

  public static async get(): Promise<AdmAppSetting[]> {
    try {
      return await AppConfigApi.requestService.get('/admin/appsettings')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(appConfig: AdmAppSetting[]): Promise<void> {
    try {
      return await AppConfigApi.requestService.put('/admin/appsettings/save', appConfig)
    } catch (error) {
      throw Error(error)
    }
  }
}
