import moment from 'moment'
import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import { LocalizationApi } from '@/api/common/localizationApi'
import { INITIAL_LOCALE, SET_LOCALE } from '@/constants'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { REACT_APP_CM_STANDALONE, REACT_APP_VAULT_V1_STANDALONE } from '@/constants/moduleMode'
import { commonLanguages } from '@/internalization'
import enDefaultTranslation from '@/internalization/defaultTranslations/en.json'
import { PopUpService } from '@/utils/services/popUpService'

import { updateIntl } from './actions'

const importLocale = async (locale: string): Promise<any> =>
  await import(`@/internalization/defaultTranslations/${locale}.json`)

function* handleSetLocale({ payload }: AnyAction): Generator {
  const { locale, isLoginModal } = payload

  moment.locale(locale)

  const isCommonLang = !!commonLanguages.find(({ value }) => value === locale)

  if (isLoginModal || isCommonLang) {
    sessionStorage.setItem(LocalStorageItems.LogInLocale, locale)
    localStorage.setItem(LocalStorageItems.LogInLocale, locale)
  }

  if (!isLoginModal || isCommonLang) {
    sessionStorage.setItem(LocalStorageItems.Locale, locale)
    localStorage.setItem(LocalStorageItems.Locale, locale)
  }

  const isDefaultTranslation =
    REACT_APP_CM_STANDALONE === 'true' || REACT_APP_VAULT_V1_STANDALONE === 'true'

  try {
    const messages =
      isDefaultTranslation && process.env.NODE_ENV !== 'development'
        ? yield call(importLocale, locale)
        : yield call(LocalizationApi.getTranslations, locale)

    if (messages) {
      yield put(updateIntl({ locale, messages }))
    } else {
      yield put(
        updateIntl({
          locale: INITIAL_LOCALE,
          messages: isDefaultTranslation ? {} : enDefaultTranslation,
        }),
      )
    }
  } catch (e) {
    PopUpService.showGlobalPopUp(e.message, 'error')
    // throw new Error(e)
  }
}

export default function*(): Generator {
  yield takeLatest(SET_LOCALE, handleSetLocale)
}
