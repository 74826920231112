import React from 'react'
import { useClasses } from './styles'
import ExpandMore from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import { SvgIconProps } from '@material-ui/core'

export const SelectIcon = ({ className, ...props }: SvgIconProps): React.ReactElement => {
  const classes = useClasses()
  return <ExpandMore className={clsx(classes.icon, className)} {...props} />
}
