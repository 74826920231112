export enum DevicesActions {
  GetDevicesRequest = '@/ADM_GET_DEVICES_REQUEST',
  GetDevicesResponse = '@/GET_DEVICES_RESPONSE',
  GetDevicesResponseFail = '@/GET_DEVICES_RESPONSE_FAIL',

  SetNewDevices = '@/ADM_SET_NEW_DEVICES',

  // Pagination
  SetPage = '@/ADM_SET_PAGE',
  SetRowsPerPage = '@/ADM_SET_ROWS_PER_PAGE',

  // Selected rows
  SetFixedSelectedRows = '@/ADM_SET_FIXED_SELECTED_ROWS',
  SetSelectedRows = '@/ADM_SET_SELECTED_ROWS',

  // Create device
  CreateNewDeviceRequest = '@/ADM_CREATE_NEW_DEVICE_REQUEST',
  CreateNewDeviceResponseFail = '@/ADM_CREATE_NEW_DEVICE_RESPONSE_FAIL',
  SetIsFetchingDeviceInformation = '@/ADM_SET_IS_FETCHING_DEVICE_INFORMATION',

  // Group actions
  GroupChangeDeviceStateRequest = '@/ADM_GROUP_CHANGE_DEVICE_STATE_REQUEST',
  GroupChangeHardwareOwnerRequest = '@/ADM_GROUP_CHANGE_HARDWARE_OWNER_REQUEST',
  GroupChangeServiceDatesRequest = '@/ADM_GROUP_CHANGE_SERVICE_DATES_REQUEST',
  GroupChangeStateResponse = '@/ADM_GROUP_CHANGE_STATE_RESPONSE',

  // Device
  GetDeviceRequest = '@/ADM_GET_DEVICE_REQUEST',
  GetDeviceResponse = '@/GET_DEVICE_RESPONSE',
  GetDeviceResponseFail = '@/GET_DEVICE_RESPONSE_FAIL',

  // Device view info
  GetDeviceViewInfoRequest = '@/ADM_GET_DEVICE_VIEW_INFO_REQUEST',
  GetDeviceViewInfoResponse = '@/GET_DEVICE_VIEW_INFO_RESPONSE',
  GetDeviceViewInfoResponseFail = '@/GET_DEVICE_VIEW_INFO_RESPONSE_FAIL',

  AddDeviceRequest = '@/ADM_ADD_DEVICE_REQUEST',
  AddDeviceResponse = '@/ADM_ADD_DEVICE_RESPONSE',

  EditDeviceRequest = '@/ADM_EDIT_DEVICE_REQUEST',
  EditDeviceResponse = '@/ADM_EDIT_DEVICE_RESPONSE',

  // Hardware
  GetHardwareResponseFail = '@/ADM_GET_HARDWARE_RESPONSE_FAIL',

  GetHardwareRequest = '@/ADM_GET_HARDWARE_REQUEST',
  GetHardwareResponse = '@/GET_HARDWARE_RESPONSE',

  AddHardwareRequest = '@/ADM_ADD_HARDWARE_REQUEST',
  AddHardwareResponse = '@/ADM_ADD_HARDWARE_RESPONSE',

  EditHardwareRequest = '@/ADM_EDIT_HARDWARE_REQUEST',
  EditHardwareResponse = '@/ADM_EDIT_HARDWARE_RESPONSE',

  RemoveHardwareRequest = '@/ADM_REMOVE_HARDWARE_REQUEST',
  RemoveHardwareResponse = '@/ADM_REMOVE_HARDWARE_RESPONSE',

  // Address
  GetAddressResponseFail = '@/ADM_GET_ADDRESS_RESPONSE_FAIL',

  GetAddressRequest = '@/ADM_GET_ADDRESS_REQUEST',
  GetAddressResponse = '@/ADM_GET_ADDRESS_RESPONSE',

  GetGeoRequest = '@/ADM_GET_GEO_REQUEST',
  GetGeoResponse = '@/ADM_GET_GEO_RESPONSE',

  AddAddressRequest = '@/ADM_ADD_ADDRESS_REQUEST',
  AddAddressResponse = '@/ADM_ADD_ADDRESS_RESPONSE',

  EditAddressRequest = '@/ADM_EDIT_ADDRESS_REQUEST',
  EditAddressResponse = '@/ADM_EDIT_ADDRESS_RESPONSE',

  EditGeoRequest = '@/ADM_EDIT_GEO_REQUEST',
  EditGeoResponse = '@/ADM_EDIT_GEO_RESPONSE',

  LocateGeoRequest = '@/ADM_LOCATE_GEO_REQUEST',
  LocateGeoResponse = '@/ADM_LOCATE_GEO_RESPONSE',

  CloseAddressRequest = '@/ADM_CLOSE_ADDRESS_REQUEST',
  CloseAddressResponse = '@/ADM_CLOSE_ADDRESS_RESPONSE',

  MoveAddressRequest = '@/ADM_MOVE_ADDRESS_REQUEST',
  MoveAddressResponse = '@/ADM_MOVE_ADDRESS_RESPONSE',

  // Related company actions
  GetRelatedCompaniesResponseFail = '@/ADM_GET_RELATED_COMPANIES_RESPONSE_FAIL',

  GetRelatedCompaniesRequest = '@/ADM_GET_RELATED_COMPANIES_REQUEST',
  GetRelatedCompaniesResponse = '@/ADM_GET_RELATED_COMPANIES_RESPONSE',

  AddRelatedCompanyRequest = '@/ADM_ADD_RELATED_COMPANY_REQUEST',
  AddRelatedCompanyResponse = '@/ADM_ADD_RELATED_COMPANY_RESPONSE',

  EditRelatedCompanyRequest = '@/ADM_EDIT_RELATED_COMPANY_REQUEST',
  EditRelatedCompanyResponse = '@/ADM_EDIT_RELATED_COMPANY_RESPONSE',

  RemoveRelatedCompanyRequest = '@/ADM_REMOVE_RELATED_COMPANY_REQUEST',
  RemoveRelatedCompanyResponse = '@/ADM_REMOVE_RELATED_COMPANY_RESPONSE',

  // Service info
  GetMerchantLunoByAcquirersRequest = '@/ADM_GET_MERCHANT_LUNO_BY_ACQUIRERS_REQUEST',
  GetMerchantLunoByAcquirersResponse = '@/ADM_GET_MERCHANT_LUNO_BY_ACQUIRERS_RESPONSE',
  GetMerchantLunoByAcquirersResponseFail = '@/ADM_GET_MERCHANT_LUNO_BY_ACQUIRERS_RESPONSE_FAIL',

  AddMerchantLunoByAcquirersRequest = '@/ADM_ADD_MERCHANT_LUNO_BY_ACQUIRERS_REQUEST',
  AddMerchantLunoByAcquirersResponse = '@/ADM_ADD_MERCHANT_LUNO_BY_ACQUIRERS_RESPONSE',

  EditMerchantLunoByAcquirersRequest = '@/ADM_EDIT_MERCHANT_LUNO_BY_ACQUIRERS_REQUEST',
  EditMerchantLunoByAcquirersResponse = '@/ADM_EDIT_MERCHANT_LUNO_BY_ACQUIRERS_RESPONSE',

  RemoveMerchantLunoByAcquirersRequest = '@/ADM_REMOVE_MERCHANT_LUNO_BY_ACQUIRERS_REQUEST',
  RemoveMerchantLunoByAcquirersResponse = '@/ADM_REMOVE_MERCHANT_LUNO_BY_ACQUIRERS_RESPONSE',

  // Attached files
  GetAttachedFilesResponseFail = '@/ADM_GET_ATTACHED_FILES_RESPONSE_FAIL',

  GetAttachedFilesRequest = '@/ADM_GET_ATTACHED_FILES_REQUEST',
  GetAttachedFilesResponse = '@/ADM_GET_ATTACHED_FILES_RESPONSE',

  AddAttachedFileRequest = '@/ADM_ADD_ATTACHED_FILE_REQUEST',
  AddAttachedFileResponse = '@/ADM_ADD_ATTACHED_FILE_RESPONSE',

  RemoveAttachedFileRequest = '@/ADM_REMOVE_ATTACHED_FILE_REQUEST',
  RemoveAttachedFileResponse = '@/ADM_REMOVE_ATTACHED_FILE_RESPONSE',

  ChangeAttachedFilesPage = '@/ADM_CHANGE_ATTACHED_FILES_PAGE',
  ChangeAttachedFilesPageSize = '@/ADM_CHANGE_ATTACHED_FILES_PAGE_SIZE',

  // Configuration
  GetConfigurationResponseFail = '@/ADM_GET_CONFIGURATION_RESPONSE_FAIL',

  GetConfigurationRequest = '@/ADM_GET_CONFIGURATION_REQUEST',
  GetConfigurationResponse = '@/ADM_GET_CONFIGURATION_RESPONSE',

  AddDetailRequest = '@/ADM_ADD_DETAIL_REQUEST',
  AddDetailResponse = '@/ADM_ADD_DETAIL_RESPONSE',

  AddModuleRequest = '@/ADM_ADD_MODULE_REQUEST',
  AddModuleResponse = '@/ADM_ADD_MODULE_RESPONSE',

  EditConfigurationRequest = '@/ADM_EDIT_CONFIGURATION_REQUEST',
  EditConfigurationResponse = '@/ADM_EDIT_CONFIGURATION_RESPONSE',

  RemoveConfigurationRequest = '@/ADM_REMOVE_CONFIGURATION_REQUEST',
  RemoveConfigurationResponse = '@/ADM_REMOVE_CONFIGURATION_RESPONSE',

  ChangeConfigurationPage = '@/ADM_CHANGE_CONFIGURATION_PAGE',
  ChangeConfigurationPageSize = '@/ADM_CHANGE_CONFIGURATION_PAGE_SIZE',

  // Schedule
  GetScheduleResponseFail = '@/ADM_GET_SCHEDULE_RESPONSE_FAIL',

  GetScheduleRequest = '@/ADM_GET_SCHEDULE_REQUEST',
  GetScheduleResponse = '@/ADM_GET_SCHEDULE_RESPONSE',

  EditScheduleRequest = '@/ADM_EDIT_SCHEDULE_REQUEST',
  EditScheduleResponse = '@/ADM_EDIT_SCHEDULE_RESPONSE',
  GetGetResponse = 'GetGetResponse',
}
