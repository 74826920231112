import { createStyles, makeStyles } from '@material-ui/core/styles'
import { isIE } from 'react-device-detect'

export const useClasses = makeStyles(() =>
  createStyles({
    paginationWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      position: 'fixed',
      bottom: isIE ? '85px' : '65px',
      right: '26px',
      backgroundColor: 'white',
      width: '100%',
    },
    elements: {
      bottom: isIE ? '10px' : '',
      right: '2em',
    },
    spacer: {
      display: 'none',
    },
    positionInit: {
      position: 'initial',
    },
  }),
)
