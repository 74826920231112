import { Tooltip } from '@material-ui/core'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'

import { styledComponentTheme } from '@/theme'

interface StyleProps {
  isClusterMarker?: boolean
}

export const useClasses = makeStyles(({ palette, spacing }) =>
  createStyles({
    textWrapper: ({ isClusterMarker }: StyleProps) => ({
      backgroundColor: palette.common.white,
      position: 'absolute',
      width: spacing(isClusterMarker ? 7 : 5),
      height: spacing(isClusterMarker ? 7 : 5),
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      cursor: 'pointer',
    }),
    wrapper: () => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
    }),
    text: ({ isClusterMarker }: StyleProps) => ({
      lineHeight: 'normal',
      fontSize: isClusterMarker
        ? styledComponentTheme.fontSizes.big
        : styledComponentTheme.fontSizes.small,
      fontWeight: 500,
    }),
  }),
)

export const LightTooltip = withStyles(({ palette, spacing }) => ({
  tooltip: {
    backgroundColor: palette.common.white,
    padding: spacing(2),
    minWidth: spacing(25),
    color: palette.text.primary,
    boxShadow: '0px 12px 12px rgba(0, 0, 0, 0.12)',
  },
  arrow: {
    color: palette.common.white,
  },
}))(Tooltip)
