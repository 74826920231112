import makeStyles from '@material-ui/core/styles/makeStyles'

import { styledComponentTheme } from '@/theme/styledComponentTheme'
export const useClasses = makeStyles(({ spacing }) => ({
  errorWrapper: {},
  popoverPaper: {
    marginLeft: spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  applyButton: {
    background: styledComponentTheme.colors.baseGreen,
    padding: '7px 20px',
    marginLeft: spacing(1),
    fontWeight: 'normal',

    '&[disabled]': {
      background: 'transparent',
      border: '1px solid #929A9B',
      color: '#929A9B',
    },
  },
  clearButton: {
    background: 'white',
    border: '1px solid',
    borderColor: styledComponentTheme.colors.baseGreen,
    color: styledComponentTheme.colors.baseGreen,
    padding: '7px 20px',
    marginLeft: spacing(1),
    fontWeight: 'normal',

    '&[disabled]': {
      background: 'transparent',
      border: '1px solid #929A9B',
      color: '#929A9B',
    },

    '&:hover': {
      background: 'transparent',
    },
  },
  popoverButton: {
    justifyContent: 'flex-start',
    fontWeight: 'normal',
    padding: '6px 20px',
  },
  savedFilters: {},
  iconButton: {
    width: '40px',
    height: '40px',
    // marginRight: '10px',

    '&[disabled]': {
      filter: 'grayscale(100%)',
      borderColor: '#929A9B',
    },
  },
  wrapper: {
    padding: spacing(4),
  },

  closeButton: {
    top: spacing(2),
    right: spacing(2),
    '& [class*="makeStyles-icon"]': {
      color: '#041517',
    },
  },

  formRow: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    width: 'calc(100% + 20px)',
  },

  fieldValidationWrapper: {
    width: '100%',
  },

  fieldDateTimeValidationWrapper: {
    marginTop: '52px',
  },

  rowElem: {
    flex: '0 1 calc(50% - 20px)',
    maxWidth: 'calc(50% - 20px)',
    marginRight: '20px',
  },

  rowElemFullWidth: {
    display: 'flex',
    marginRight: '20px',
    columnGap: '20px',
  },

  header: {
    marginBottom: spacing(2.5),
    fontWeight: 'normal',
  },

  button: {
    padding: spacing(1, 2.5),
    fontWeight: 'normal',
    marginLeft: spacing(1.5),
  },

  buttonWrapper: {
    marginTop: spacing(0.5),
  },

  form: {
    marginTop: spacing(2),
    marginRight: spacing(1.5),
    width: 'calc(100% - 12px)',
    display: 'flex,',
  },

  actionButtons: {
    display: 'flex',
  },

  toolTip: {
    background: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    color: 'black',
    padding: spacing(1),
    fontSize: '14px',
    top: spacing(1),
    left: '6px',
  },
}))
