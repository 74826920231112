import { AnyAction } from 'redux'
import { TicketActions, SearchServiceRequestActions } from '@/constants/index'
import {
  SelectedFilters,
  GroupingTemplate,
  ResponseError,
  TicketOverview,
  DelaysValues,
} from '@/types'
import { TableGroup } from '@/components/pages/sd/TicketsPage/components/GroupingModal/types'
import { CustomerRequestItemsTable, ServiceItemsTable } from '@/types/srm/tickets'
import { AdditionalCostsValues } from '@/components/pages/srm/TicketsPage/WorkCostTab/components/AdditionalCosts/types'
import { TableBodyCell } from '@/components/controls/AppTable/types'

export const getGroupingTemplatesResponse = (templates: any): AnyAction => ({
  type: TicketActions.GetGroupingTemplatesResponse,
  payload: templates,
})

export const getGroupingTemplatesResponseFail = (error: ResponseError): AnyAction => ({
  type: TicketActions.GetGroupingTemplatesResponseFail,
  payload: error,
})

export const changeSelectedFilters = (filters: SelectedFilters): AnyAction => ({
  type: TicketActions.ChangeSelectedFilters,
  payload: filters,
})

export const getGroupingTemplatesRequest = (): AnyAction => ({
  type: TicketActions.GetGroupingTemplatesRequest,
})

export const editGroupingTemplateRequest = (template: GroupingTemplate): AnyAction => ({
  type: TicketActions.EditGroupingTemplateRequest,
  payload: { template },
})

export const deleteGroupingTemplateRequest = (template: GroupingTemplate): AnyAction => ({
  type: TicketActions.DeleteGroupingTemplateRequest,
  payload: { template },
})

export const saveGroupingTemplateRequest = (template: {
  name: string
  grouping: TableGroup[]
}): AnyAction => ({
  type: TicketActions.SaveGroupingTemplateRequest,
  payload: { template },
})

export const setSearchServiceRequestData = (data: any[]): AnyAction => ({
  type: SearchServiceRequestActions.SetSearchServiceRequestsData,
  payload: data,
})

export const setSearchServiceRequestClosed = (data: boolean): AnyAction => ({
  type: SearchServiceRequestActions.SetSearchServiceRequestClosed,
  payload: data,
})

export const setSearchServiceWorksData = (data: any[]): AnyAction => ({
  type: SearchServiceRequestActions.SetSearchServiceWorksData,
  payload: data,
})

export const setSearchServiceWorksClosed = (data: boolean): AnyAction => ({
  type: SearchServiceRequestActions.SetSearchServiceWorksClosed,
  payload: data,
})

export const setSearchServiceDeviceData = (data: any[]): AnyAction => ({
  type: SearchServiceRequestActions.SetSearchServiceDeviceData,
  payload: data,
})

export const setSearchServiceDeviceClosed = (data: boolean): AnyAction => ({
  type: SearchServiceRequestActions.SetSearchServiceDeviceClosed,
  payload: data,
})

export const setSearchPlannedWorksData = (data: any[]): AnyAction => ({
  type: SearchServiceRequestActions.SetSearchPlannedWorksData,
  payload: data,
})

export const setSearchPlannedWorksClosed = (data: boolean): AnyAction => ({
  type: SearchServiceRequestActions.SetSearchPlannedWorksClosed,
  payload: data,
})

export const setSearchConfigurationData = (data: any[]): AnyAction => ({
  type: SearchServiceRequestActions.SetSearchConfigurationData,
  payload: data,
})

export const setSearchConfigurationClosed = (data: boolean): AnyAction => ({
  type: SearchServiceRequestActions.SetSearchConfigurationClosed,
  payload: data,
})

export const setDeviceDefectsStatisticsData = (data: any[]): AnyAction => ({
  type: SearchServiceRequestActions.SetDeviceDefectsStatisticsData,
  payload: data,
})

export const setDeviceDefectsStatisticsClosed = (data: boolean): AnyAction => ({
  type: SearchServiceRequestActions.SetDeviceDefectsStatisticsClosed,
  payload: data,
})

export const setServiceWorksStatisticsData = (data: any[]): AnyAction => ({
  type: SearchServiceRequestActions.SetServiceWorksStatisticsData,
  payload: data,
})

export const setServiceWorksStatisticsClosed = (data: boolean): AnyAction => ({
  type: SearchServiceRequestActions.SetServiceWorksStatisticsClosed,
  payload: data,
})

export const setTicketOverviewData = (data: TicketOverview): AnyAction => ({
  type: TicketActions.SetTicketOverviewData,
  payload: data,
})

export const setIsNewOrderTab = (): AnyAction => ({
  type: TicketActions.SetIsNewOrderTab,
})

export const setWorkOrderStatus = (status: { [key: string]: boolean }): AnyAction => ({
  type: TicketActions.SetWorkOrderStatus,
  payload: status,
})

export const getWorkItemsTableDataRequest = (): AnyAction => ({
  type: TicketActions.GetWorkItemsTableDataRequest,
})

export const getWorkItemsTableDataResponse = (data: any): AnyAction => ({
  type: TicketActions.GetWorkItemsTableDataResponse,
  payload: data,
})

export const getWorkItemsTableDataResponseFail = (data: any): AnyAction => ({
  type: TicketActions.GetWorkItemsTableDataResponseFail,
  payload: data,
})

export const getServiceItemsTableDataRequest = (): AnyAction => ({
  type: TicketActions.GetServiceItemsTableDataRequest,
})

export const getServiceItemsTableDataResponse = (data: any): AnyAction => ({
  type: TicketActions.GetServiceItemsTableDataResponse,
  payload: data,
})

export const getServiceItemsTableDataResponseFail = (data: any): AnyAction => ({
  type: TicketActions.GetServiceItemsTableDataResponseFail,
  payload: data,
})

export const setSelectedWorkTableRow = (data: any): AnyAction => ({
  type: TicketActions.SetSelectedWorkTableRow,
  payload: data,
})

export const setSelectedServiceTableRow = (row: any): AnyAction => ({
  type: TicketActions.SetSelectedServiceTableRow,
  payload: row,
})

export const editServiceItemsTableRow = (data: any): AnyAction => ({
  type: TicketActions.EditServiceItemsTableRow,
  payload: data,
})

export const deleteWorkItemsTableRow = (row: TableBodyCell): AnyAction => ({
  type: TicketActions.DeleteWorkItemsTableRow,
  payload: row,
})

export const deleteServiceItemsTableRow = (row: TableBodyCell): AnyAction => ({
  type: TicketActions.DeleteServiceItemsTableRow,
  payload: row,
})

export const addWorkItemsTableRow = (row: TableBodyCell): AnyAction => ({
  type: TicketActions.AddWorkItemsTableRow,
  payload: row,
})

export const addServiceItemsTableRow = (row: TableBodyCell): AnyAction => ({
  type: TicketActions.AddServiceItemsTableRow,
  payload: row,
})

export const deleteAnalyzeDelays = (payload: DelaysValues[]): AnyAction => ({
  type: TicketActions.DeleteAnalyzeDelays,
  payload,
})

export const addAnalyzeDelays = (payload: DelaysValues): AnyAction => ({
  type: TicketActions.AddAnalyzeDelays,
  payload,
})

export const editAnalyzeDelays = (payload: DelaysValues): AnyAction => ({
  type: TicketActions.EditAnalyzeDelays,
  payload,
})

export const addAdditionalCostsWorkCost = (payload: AdditionalCostsValues): AnyAction => ({
  type: TicketActions.AddAdditionalCostsWorkCost,
  payload,
})
export const deleteAdditionalCostsWorkCost = (payload: AdditionalCostsValues): AnyAction => ({
  type: TicketActions.DeleteAdditionalCostsWorkCost,
  payload,
})

export const addCustomerRequestItem = (payload: CustomerRequestItemsTable): AnyAction => ({
  type: TicketActions.AddCustomerRequestItem,
  payload,
})

export const addCustomerServiceItem = (payload: ServiceItemsTable): AnyAction => ({
  type: TicketActions.AddCustomerServiceItem,
  payload,
})

export const deleteCustomerRequestItem = (payload: CustomerRequestItemsTable): AnyAction => ({
  type: TicketActions.DeleteCustomerRequestItem,
  payload,
})

export const deleteCustomerServiceItem = (payload: ServiceItemsTable): AnyAction => ({
  type: TicketActions.DeleteCustomerServiceItem,
  payload,
})

export const editCustomerRequestItem = (payload: {
  id: string
  problemArea: string
}): AnyAction => ({
  type: TicketActions.EditCustomerRequestItem,
  payload,
})

export const editCustomerServiceItem = (payload: {
  id: string
  problemArea: string
}): AnyAction => ({
  type: TicketActions.EditCustomerServiceItem,
  payload,
})

export const setCustomerServiceItemClosed = (payload: ServiceItemsTable): AnyAction => ({
  type: TicketActions.SetCustomerServiceItemClosed,
  payload,
})
