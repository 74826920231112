import moment from 'moment'
import { AnyAction } from 'redux'

import { CashPointDetails } from '@/api/vault-v1/monitoringApi/helpers'
import { MonVaultType } from '@/api/vault-v1/monitoringApi/types'
import {
  ComVaultReport,
  eMonChartFilterType,
  eMonRemainderType,
  MonCashLimit,
  MonCashLimitsResponse,
  MonEntryDetails,
  MonOperationsFilter,
  MonRemaindersResponse,
  MonValuableRemainder,
  MonVault,
  MonVaultCurrencyEntry,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants'
import { MonVaultEntryType } from '@/constants/vault-v1'
import { monVaultsTableId as tableId } from '@/constants/vault-v1/tableIds'
import { getTableConfig } from '@/helpers/tableLocalStorage'
import { setMonDetailsInitialDate } from '@/helpers/vault-v1/monFilter'

export type State = {
  modals: {
    isColumnModalOpen: boolean
    isDocumentModalOpen: boolean
    isFilterModalOpen: boolean

    currentRemaindersModalType: eMonRemainderType | ''
    isCurrentValuablesRemaindersModalOpen: boolean
    isStartDayModalOpen: boolean
    isWorkingTimeEndModalOpen: boolean
    isWornOutCashModalOpen: boolean
    isCloseNightDepositoryModalOpen: boolean
    isCashLimitsModalOpen: boolean
  }

  vaults: {
    isLoading: boolean
    isLoaded: boolean
    isDetailsLoading: boolean
    isDetailsLoaded: boolean
    data: MonVault[]
    total: number
    errorMessage?: string
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined
    paging: {
      page: number
      pageSize: number
    }

    selectedVault: MonVaultCurrencyEntry | null
    parentSelectedVault: {
      vaultName?: string
      vaultId?: string
      sessionDate?: string
    } | null

    filter: {
      vaults: string
      dateFrom: string
      dateTo: string
      cashierId: string
    }

    reports: {
      errorMessage?: string
      isLoading: boolean
      list: ComVaultReport[]
      selectedReport: {
        isLoading: boolean
        errorMessage?: string
        bytes?: Blob | null
      }
    }
  }

  vaultDetails: {
    selectedTab: string

    isFilterOpen: boolean

    filter: {
      dateFrom: string
      dateTo: string
      filterType: eMonChartFilterType
    }
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined

    operationsDetailsFilter: MonOperationsFilter

    data: MonEntryDetails[] | null
  }

  cashiers: {
    selectedTab: MonVaultType

    data: MonVault[]

    filter: {
      name?: string
    }

    selected: MonVaultCurrencyEntry | null

    parentSelected: Partial<{
      vaultName: string
      vaultId: string
      cashierName: string
    }> | null

    isLoading: boolean
    isLoaded: boolean
  }

  cashierDetails: {
    selectedTab: MonVaultEntryType

    data: MonEntryDetails[]
    filter: MonOperationsFilter
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined
    isLoading: boolean
    isLoaded: boolean
  }

  forecast: {
    isLoading: boolean
    data: CashPointDetails | null
  }

  monCurrentRemainders: {
    data: MonRemaindersResponse | null
    isLoading: boolean
    isLoaded: boolean

    isCurrentRemainderUpdateLoading: boolean
    updatedCurrentRemainders: MonRemaindersResponse | null
  }
  monCurrentValuablesRemainders: {
    data: MonValuableRemainder[]
    isLoading: boolean
    isLoaded: boolean

    isUpdating: boolean
    updated: MonValuableRemainder[]
  }
  monStartDay: {
    data: MonRemaindersResponse | null
    isLoading: boolean
    isLoaded: boolean

    isStartDayUpdateLoading: boolean
  }
  monWorkingTimeEnd: {
    data: MonRemaindersResponse | null
    isLoading: boolean
    isLoaded: boolean

    isWorkingTimeEndUpdateLoading: boolean
  }
  monWornOutCash: {
    data: MonRemaindersResponse | null
    isLoading: boolean
    isLoaded: boolean

    isWornOutCashUpdateLoading: boolean
  }

  monCloseNightDepository: {
    data: MonRemaindersResponse | null
    isLoading: boolean
    isLoaded: boolean

    isCloseNightDepositoryLoading: boolean
  }

  monCashLimits: {
    data: MonCashLimitsResponse | null
    isLoading: boolean
    isLoaded: boolean

    selectedCashLimit: MonCashLimit | null

    cashLimitAddEditModalType: 'add' | 'edit' | null

    isCashLimitsLoading: boolean
  }

  isAbsRemaindersUpdating: boolean
}

export const initialState: State = {
  modals: {
    isDocumentModalOpen: false,
    isFilterModalOpen: false,
    isColumnModalOpen: false,

    currentRemaindersModalType: '',
    isCurrentValuablesRemaindersModalOpen: false,
    isStartDayModalOpen: false,
    isWorkingTimeEndModalOpen: false,
    isWornOutCashModalOpen: false,
    isCloseNightDepositoryModalOpen: false,
    isCashLimitsModalOpen: false,
  },

  vaults: {
    isLoading: false,
    isLoaded: false,
    isDetailsLoading: false,
    isDetailsLoaded: false,
    errorMessage: '',
    reports: {
      errorMessage: '',
      isLoading: false,
      list: [],
      selectedReport: {
        isLoading: false,
        errorMessage: '',
        bytes: undefined,
      },
    },

    filter: {
      vaults: '',
      dateFrom: moment()
        .startOf('day')
        .format(),
      dateTo: moment()
        .endOf('day')
        .format(),
      cashierId: '',
    },

    data: [],
    total: 0,
    sortColumn: undefined,
    sortOrder: undefined,
    paging: {
      page: 1,
      pageSize: getTableConfig({ tableId })?.rowPerPage || 50,
    },

    selectedVault: null,
    parentSelectedVault: null,
  },

  vaultDetails: {
    selectedTab: 'denominationDetails',

    isFilterOpen: false,

    filter: {
      dateFrom: setMonDetailsInitialDate().dateFrom,
      dateTo: setMonDetailsInitialDate().dateTo,
      filterType: 'cash_in_amounts',
    },

    sortColumn: undefined,
    sortOrder: undefined,

    operationsDetailsFilter: {
      dateFrom: moment()
        .startOf('day')
        .format(),
      dateTo: undefined,
    },

    data: null,
  },

  cashiers: {
    selectedTab: MonVaultType.Cashier,

    data: [],

    filter: {
      name: undefined,
    },

    selected: null,
    parentSelected: null,

    isLoading: false,
    isLoaded: false,
  },

  cashierDetails: {
    selectedTab: MonVaultEntryType.Currency,

    data: [],
    filter: {
      dateFrom: moment()
        .startOf('day')
        .format(),
      dateTo: undefined,
    },
    sortColumn: undefined,
    sortOrder: undefined,

    isLoading: false,
    isLoaded: false,
  },

  forecast: {
    isLoading: false,
    data: null,
  },

  monCurrentRemainders: {
    data: null,
    isLoading: false,
    isLoaded: false,
    isCurrentRemainderUpdateLoading: false,
    updatedCurrentRemainders: null,
  },

  monCurrentValuablesRemainders: {
    data: [],
    isLoading: false,
    isLoaded: false,
    isUpdating: false,
    updated: [],
  },

  monStartDay: {
    data: null,
    isLoading: false,
    isLoaded: false,

    isStartDayUpdateLoading: false,
  },

  monWorkingTimeEnd: {
    data: null,
    isLoading: false,
    isLoaded: false,

    isWorkingTimeEndUpdateLoading: false,
  },
  monWornOutCash: {
    data: null,
    isLoading: false,
    isLoaded: false,

    isWornOutCashUpdateLoading: false,
  },
  monCloseNightDepository: {
    data: null,
    isLoading: false,
    isLoaded: false,

    isCloseNightDepositoryLoading: false,
  },

  monCashLimits: {
    data: null,
    isLoading: false,
    isLoaded: false,

    selectedCashLimit: null,

    cashLimitAddEditModalType: null,

    isCashLimitsLoading: false,
  },

  isAbsRemaindersUpdating: false,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case VaultV1Actions.SetGlobalFilter:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          paging: {
            ...state.vaults.paging,
            page: 1,
          },
        },
      }

    case VaultV1Actions.MonSortVaultsTable:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          ...payload,
          isLoading: true,
        },
      }

    case VaultV1Actions.MonGetVaultDetailsRequest:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          isDetailsLoading: true,
        },
      }

    case VaultV1Actions.MonGetVaultDetailsResponse:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          isDetailsLoading: false,
          isDetailsLoaded: true,
        },
        vaultDetails: {
          ...state.vaultDetails,
          data: payload,
        },
      }

    case VaultV1Actions.MonGetVaultDetailsFail:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          isDetailsLoading: false,
          isDetailsLoaded: true,
          errorMessage: payload,
        },
        vaultDetails: {
          ...state.vaultDetails,
          data: null,
        },
      }

    case VaultV1Actions.MonSetDetailsSelectedTab:
      return {
        ...state,
        vaultDetails: {
          ...state.vaultDetails,
          selectedTab: payload,
        },
      }

    case VaultV1Actions.MonSetDetailsFilterOpen:
      return {
        ...state,
        vaultDetails: {
          ...state.vaultDetails,
          isFilterOpen: payload,
        },
      }

    case VaultV1Actions.MonSetDetailsFilter:
      return {
        ...state,
        vaultDetails: {
          ...state.vaultDetails,
          filter: {
            ...state.vaultDetails.filter,
            ...payload,
          },
        },
        forecast: {
          ...state.forecast,
          isLoading: true,
        },
      }

    case VaultV1Actions.MonRefreshVaults:
    case VaultV1Actions.MonGetVaultsRequest:
    case VaultV1Actions.MonGetSingleVaultRequest:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          isLoading: true,
          errorMessage: initialState?.vaults?.errorMessage || '',
        },
      }
    case VaultV1Actions.MonGetVaultsResponse:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          isLoading: false,
          isLoaded: true,
          data: payload.vaults,
          total: payload.total,
          errorMessage: '',
        },
      }
    case VaultV1Actions.MonGetVaultsFail:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          isLoading: false,
          isLoaded: false,
          data: [],
          total: 0,
          errorMessage: payload,
        },
      }

    case VaultV1Actions.MonGetSingleVaultResponse: {
      const newVaults = [...state.vaults.data]
      const { selectedVault } = state.vaults
      const refreshedVaultIndex = newVaults.findIndex(el => el?.vaultId === payload?.vaultId)

      if (refreshedVaultIndex !== undefined && refreshedVaultIndex !== null) {
        newVaults[refreshedVaultIndex] = { ...payload }

        let updatedSelectedVault = null as MonVaultCurrencyEntry | null

        newVaults.forEach(vault =>
          vault.entries.forEach(entry => {
            if (entry.entryId === selectedVault?.entryId) {
              updatedSelectedVault = { ...entry }
            }
          }),
        )

        if (!updatedSelectedVault) {
          updatedSelectedVault = { ...payload.entries[0] }
        }

        return {
          ...state,
          vaults: {
            ...state.vaults,
            data: newVaults,
            isLoading: false,
            selectedVault: updatedSelectedVault,
          },
        }
      }
      return state
    }

    case VaultV1Actions.MonGetSingleVaultFail:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          isLoading: false,
        },
      }

    case VaultV1Actions.MonSetVaultsPaging:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          isLoading: true,
          paging: {
            ...state.vaults.paging,
            ...payload,
          },
        },
      }

    case VaultV1Actions.MonSubmitFilter:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          isLoading: true,
          filter: payload.filter,
          selectedVault: null,
          paging: {
            ...state.vaults.paging,
            page: initialState.vaults.paging.page || 1,
          },
        },
      }
    case VaultV1Actions.MonOpenColumnsSettingsModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          isColumnModalOpen: payload,
        },
      }
    case VaultV1Actions.MonOpenDocumentsModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          isDocumentModalOpen: payload,
        },
      }
    case VaultV1Actions.MonOpenFilterModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          isFilterModalOpen: payload,
        },
      }

    case VaultV1Actions.MonSetSelectedVault: {
      const vaults = [...state.vaults.data]

      const parentVault = vaults.find(vault =>
        vault.entries.some(entry => entry.entryId === payload?.entryId),
      )

      return {
        ...state,
        vaults: {
          ...state.vaults,
          selectedVault: payload,
          parentSelectedVault:
            {
              vaultId: parentVault?.vaultId,
              vaultName: parentVault?.vaultName,
              sessionDate: parentVault?.sessionDate,
            } || null,
        },
        cashiers:
          parentVault?.vaultId !== state.vaults.parentSelectedVault?.vaultId
            ? initialState.cashiers
            : state.cashiers,
        cashierDetails:
          parentVault?.vaultId !== state.vaults.parentSelectedVault?.vaultId
            ? initialState.cashierDetails
            : state.cashierDetails,
      }
    }

    case VaultV1Actions.MonGetReportsRequest:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          reports: {
            ...state.vaults.reports,
            isLoading: true,
            list: [],
            errorMessage: '',
            selectedReport: initialState.vaults.reports.selectedReport,
          },
        },
      }
    case VaultV1Actions.MonGetReportsResponse:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          reports: {
            ...state.vaults.reports,
            isLoading: false,
            list: payload,
            errorMessage: '',
          },
        },
      }
    case VaultV1Actions.MonGetReportsFail:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          reports: {
            ...state.vaults.reports,
            isLoading: false,
            list: [],
            errorMessage: payload,
          },
        },
      }

    case VaultV1Actions.MonGetReportRequest:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          reports: {
            ...state.vaults.reports,
            selectedReport: {
              ...state.vaults.reports.selectedReport,
              isLoading: true,
              errorMessage: '',
              bytes: undefined,
            },
          },
        },
      }
    case VaultV1Actions.MonGetReportResponse:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          reports: {
            ...state.vaults.reports,
            selectedReport: {
              ...state.vaults.reports.selectedReport,
              isLoading: false,
              errorMessage: '',
              bytes: payload,
            },
          },
        },
      }
    case VaultV1Actions.MonGetReportFail:
      return {
        ...state,
        vaults: {
          ...state.vaults,
          reports: {
            ...state.vaults.reports,
            selectedReport: {
              ...state.vaults.reports.selectedReport,
              isLoading: false,
              errorMessage: payload,
              bytes: undefined,
            },
          },
        },
      }

    // [START] Action Modals ---------------------------------------------------------------------
    case VaultV1Actions.MonOpenCurrentRemaindersModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          currentRemaindersModalType: payload,
        },
        monCurrentRemainders: {
          ...state.monCurrentRemainders,
          data: !payload ? null : state.monCurrentRemainders.data,
          updatedCurrentRemainders: !payload
            ? null
            : state.monCurrentRemainders.updatedCurrentRemainders,
        },
      }

    case VaultV1Actions.MonOpenCurrentValuablesRemaindersModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          isCurrentValuablesRemaindersModalOpen: payload,
        },
      }

    case VaultV1Actions.MonOpenStartDayModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          isStartDayModalOpen: payload,
        },
      }

    case VaultV1Actions.MonOpenWorkingTimeEndModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          isWorkingTimeEndModalOpen: payload,
        },
      }

    case VaultV1Actions.MonOpenWornOutCashModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          isWornOutCashModalOpen: payload,
        },
      }
    case VaultV1Actions.MonOpenCloseNightDepositoryModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          isCloseNightDepositoryModalOpen: payload,
        },
      }
    case VaultV1Actions.MonOpenCashLimitsModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          isCashLimitsModalOpen: payload,
        },
        monCashLimits: {
          ...state.monCashLimits,
          selectedCashLimit: !payload ? null : state.monCashLimits.selectedCashLimit,
        },
      }
    // [END] Action Modals ---------------------------------------------------------------------

    // [START] Cashiers ---------------------------------------------------------------

    case VaultV1Actions.MonSetCashierSelectedTab:
      return {
        ...state,
        cashiers: {
          ...state.cashiers,
          selectedTab: payload,
        },
      }

    case VaultV1Actions.MonGetCashiersRequest:
      return {
        ...state,
        cashiers: {
          ...state.cashiers,
          isLoading: true,
        },
      }

    case VaultV1Actions.MonGetCashiersResponse:
      return {
        ...state,
        cashiers: {
          ...state.cashiers,
          isLoading: false,
          isLoaded: true,
          data: payload || [],
        },
      }
    case VaultV1Actions.MonGetCashiersFail:
      return {
        ...state,
        cashiers: {
          ...state.cashiers,
          isLoading: false,
          isLoaded: true,
        },
      }

    case VaultV1Actions.MonSetVaultOperationDetailsFilter:
      return {
        ...state,
        vaultDetails: {
          ...state.vaultDetails,
          operationsDetailsFilter: payload,
        },
      }

    case VaultV1Actions.MonSetOperationDetailsFilter:
      return {
        ...state,
        cashierDetails: {
          ...state.cashierDetails,
          filter: payload,
        },
      }
    case VaultV1Actions.MonSortVaultCashierOperationDetails:
      return {
        ...state,
        cashierDetails: {
          ...state.cashierDetails,
          ...payload,
        },
      }
    case VaultV1Actions.MonSortVaultOperationDetails:
      return {
        ...state,
        vaultDetails: {
          ...state.vaultDetails,
          ...payload,
        },
      }

    case VaultV1Actions.MonSetCashiersFilter:
      return {
        ...state,
        cashiers: {
          ...state.cashiers,
          isLoading: true,
          filter: {
            ...state.cashiers.filter,
            ...payload,
          },
        },
      }

    case VaultV1Actions.MonSetSelectedCashier: {
      const cashiers = [...state.cashiers.data]

      const parentCashier = cashiers.find(cashier =>
        cashier.entries.some(entry => entry.entryId === payload?.entryId),
      )

      return {
        ...state,
        cashiers: {
          ...state.cashiers,
          selected: payload,
          parentSelected:
            {
              vaultId: parentCashier?.vaultId,
              vaultName: parentCashier?.vaultName,
              cashierName: parentCashier?.cashierName,
            } || null,
        },
      }
    }
    // [END] Cashiers ---------------------------------------------------------------

    // [START] Cashiers Details ---------------------------------------------------------------
    case VaultV1Actions.MonGetCashierDetailsRequest:
      return {
        ...state,
        cashierDetails: {
          ...state.cashierDetails,
          isLoading: true,
        },
      }

    case VaultV1Actions.MonGetCashierDetailsResponse:
      return {
        ...state,
        cashierDetails: {
          ...state.cashierDetails,
          isLoading: false,
          isLoaded: true,
          data: payload || [],
        },
      }
    case VaultV1Actions.MonGetCashierDetailsFail:
      return {
        ...state,
        cashierDetails: {
          ...state.cashierDetails,
          isLoading: false,
          isLoaded: true,
        },
      }
    case VaultV1Actions.MonSetCashierDetailsTab:
      return {
        ...state,
        cashierDetails: {
          ...state.cashierDetails,
          selectedTab: payload,
        },
      }

    // [END] Cashiers Details ---------------------------------------------------------------

    case VaultV1Actions.MonGetCurrentRemaindersRequest:
      return {
        ...state,
        monCurrentRemainders: {
          ...state.monCurrentRemainders,
          isLoading: true,
        },
      }

    case VaultV1Actions.MonGetCurrentRemaindersResponse:
      return {
        ...state,
        monCurrentRemainders: {
          ...state.monCurrentRemainders,
          isLoading: false,
          isLoaded: true,
          data: payload,
          updatedCurrentRemainders: payload,
        },
      }
    case VaultV1Actions.MonGetCurrentRemaindersFail:
      return {
        ...state,
        monCurrentRemainders: {
          ...state.monCurrentRemainders,
          isLoading: false,
          isLoaded: true,
        },
      }

    case VaultV1Actions.MonSetCurrentRemaindersRequest:
      return {
        ...state,
        monCurrentRemainders: {
          ...state.monCurrentRemainders,
          isCurrentRemainderUpdateLoading: true,
        },
      }

    case VaultV1Actions.MonSetCurrentRemaindersResponse:
      return {
        ...state,
        monCurrentRemainders: {
          ...state.monCurrentRemainders,
          isCurrentRemainderUpdateLoading: false,
        },
        modals: {
          ...state.modals,
          currentRemaindersModalType: '',
        },
      }
    case VaultV1Actions.MonSetCurrentRemaindersFail:
      return {
        ...state,
        monCurrentRemainders: {
          ...state.monCurrentRemainders,
          isCurrentRemainderUpdateLoading: false,
        },
      }

    case VaultV1Actions.MonUpdateCurrentRemainder: {
      const { currency, denominations, total } = payload || {}
      const { updatedCurrentRemainders } = state.monCurrentRemainders

      let dataToUpdate = {}

      if (updatedCurrentRemainders?.currencies) {
        const currencies = updatedCurrentRemainders.currencies.map(dataCurrency =>
          dataCurrency.currency === currency
            ? { ...dataCurrency, denominations, total }
            : dataCurrency,
        )

        dataToUpdate = { ...updatedCurrentRemainders, currencies }
      }

      return {
        ...state,
        monCurrentRemainders: {
          ...state.monCurrentRemainders,
          updatedCurrentRemainders: dataToUpdate,
        },
      }
    }

    case VaultV1Actions.MonGetCurrentValuablesRemaindersRequest:
      return {
        ...state,
        monCurrentValuablesRemainders: {
          ...state.monCurrentValuablesRemainders,
          isLoading: true,
        },
      }
    case VaultV1Actions.MonGetCurrentValuablesRemaindersResponse:
      return {
        ...state,
        monCurrentValuablesRemainders: {
          ...state.monCurrentValuablesRemainders,
          isLoading: false,
          isLoaded: true,
          data: payload,
          updated: payload,
        },
      }
    case VaultV1Actions.MonGetCurrentValuablesRemaindersFail:
      return {
        ...state,
        monCurrentValuablesRemainders: {
          ...state.monCurrentValuablesRemainders,
          isLoading: false,
          isLoaded: true,
        },
      }

    case VaultV1Actions.MonSetCurrentValuablesRemaindersRequest:
      return {
        ...state,
        monCurrentValuablesRemainders: {
          ...state.monCurrentValuablesRemainders,
          isUpdating: true,
        },
      }
    case VaultV1Actions.MonSetCurrentValuablesRemaindersResponse:
      return {
        ...state,
        monCurrentValuablesRemainders: {
          ...state.monCurrentValuablesRemainders,
          isUpdating: false,
        },
        modals: {
          ...state.modals,
          isCurrentValuablesRemaindersModalOpen: false,
        },
      }
    case VaultV1Actions.MonSetCurrentValuablesRemaindersFail:
      return {
        ...state,
        monCurrentValuablesRemainders: {
          ...state.monCurrentValuablesRemainders,
          isUpdating: false,
        },
      }

    case VaultV1Actions.MonUpdateCurrentValuablesRemainders:
      return {
        ...state,
        monCurrentValuablesRemainders: {
          ...state.monCurrentValuablesRemainders,
          updated: payload,
        },
      }

    // [START] Start Day ----------------------------------------------------------------
    case VaultV1Actions.MonGetStartDayRequest:
      return {
        ...state,
        monStartDay: {
          ...state.monStartDay,
          isLoading: true,
        },
      }

    case VaultV1Actions.MonGetStartDayResponse:
      return {
        ...state,
        monStartDay: {
          ...state.monStartDay,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      }
    case VaultV1Actions.MonGetStartDayFail:
      return {
        ...state,
        monStartDay: {
          ...state.monStartDay,
          isLoading: false,
          isLoaded: true,
        },
      }

    case VaultV1Actions.MonSetStartDayRequest:
      return {
        ...state,
        monStartDay: {
          ...state.monStartDay,
          isStartDayUpdateLoading: true,
        },
      }

    case VaultV1Actions.MonSetStartDayResponse:
      return {
        ...state,
        monStartDay: {
          ...state.monStartDay,
          isStartDayUpdateLoading: false,
        },
        modals: {
          ...state.modals,
          isStartDayModalOpen: false,
        },
      }
    case VaultV1Actions.MonSetStartDayFail:
      return {
        ...state,
        monStartDay: {
          ...state.monStartDay,
          isStartDayUpdateLoading: false,
        },
      }
    // [END] Start Day ----------------------------------------------------------------

    // [START] Working Time End ----------------------------------------------------------------
    case VaultV1Actions.MonGetWorkingTimeEndRequest:
      return {
        ...state,
        monWorkingTimeEnd: {
          ...state.monWorkingTimeEnd,
          isLoading: true,
        },
      }

    case VaultV1Actions.MonGetWorkingTimeEndResponse:
      return {
        ...state,
        monWorkingTimeEnd: {
          ...state.monWorkingTimeEnd,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      }
    case VaultV1Actions.MonGetWorkingTimeEndFail:
      return {
        ...state,
        monWorkingTimeEnd: {
          ...state.monWorkingTimeEnd,
          isLoading: false,
          isLoaded: true,
        },
      }

    case VaultV1Actions.MonSetWorkingTimeEndRequest:
      return {
        ...state,
        monWorkingTimeEnd: {
          ...state.monWorkingTimeEnd,
          isWorkingTimeEndUpdateLoading: true,
        },
      }

    case VaultV1Actions.MonSetWorkingTimeEndResponse:
      return {
        ...state,
        monWorkingTimeEnd: {
          ...state.monWorkingTimeEnd,
          isWorkingTimeEndUpdateLoading: false,
        },
        modals: {
          ...state.modals,
          isWorkingTimeEndModalOpen: false,
        },
      }
    case VaultV1Actions.MonSetWorkingTimeEndFail:
      return {
        ...state,
        monWorkingTimeEnd: {
          ...state.monWorkingTimeEnd,
          isWorkingTimeEndUpdateLoading: false,
        },
      }
    // [END] Working Time End ----------------------------------------------------------------

    // [START] Worn Out Cash ----------------------------------------------------------------
    case VaultV1Actions.MonGetWornOutCashRequest:
      return {
        ...state,
        monWornOutCash: {
          ...state.monWornOutCash,
          isLoading: true,
        },
      }

    case VaultV1Actions.MonGetWornOutCashResponse:
      return {
        ...state,
        monWornOutCash: {
          ...state.monWornOutCash,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      }
    case VaultV1Actions.MonGetWornOutCashFail:
      return {
        ...state,
        monWornOutCash: {
          ...state.monWornOutCash,
          isLoading: false,
          isLoaded: true,
        },
      }

    case VaultV1Actions.MonSetWornOutCashRequest:
      return {
        ...state,
        monWornOutCash: {
          ...state.monWornOutCash,
          isWornOutCashUpdateLoading: true,
        },
      }

    case VaultV1Actions.MonSetWornOutCashResponse:
      return {
        ...state,
        monWornOutCash: {
          ...state.monWornOutCash,
          isWornOutCashUpdateLoading: false,
        },
      }
    case VaultV1Actions.MonSetWornOutCashFail:
      return {
        ...state,
        monWornOutCash: {
          ...state.monWornOutCash,
          isWornOutCashUpdateLoading: false,
        },
      }
    // [END] Worn Out Cash ----------------------------------------------------------------

    // [START] Close Night Depository ----------------------------------------------------------------
    case VaultV1Actions.MonGetCloseNightDepositoryRequest:
      return {
        ...state,
        monCloseNightDepository: {
          ...state.monCloseNightDepository,
          isLoading: true,
        },
      }

    case VaultV1Actions.MonGetCloseNightDepositoryResponse:
      return {
        ...state,
        monCloseNightDepository: {
          ...state.monCloseNightDepository,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      }
    case VaultV1Actions.MonGetCloseNightDepositoryFail:
      return {
        ...state,
        monCloseNightDepository: {
          ...state.monCloseNightDepository,
          isLoading: false,
          isLoaded: true,
        },
      }

    case VaultV1Actions.MonSetCloseNightDepositoryRequest:
      return {
        ...state,
        monCloseNightDepository: {
          ...state.monCloseNightDepository,
          isCloseNightDepositoryLoading: true,
        },
      }

    case VaultV1Actions.MonSetCloseNightDepositoryResponse:
      return {
        ...state,
        monCloseNightDepository: {
          ...state.monCloseNightDepository,
          isCloseNightDepositoryLoading: false,
        },
        modals: {
          ...state.modals,
          isCloseNightDepositoryModalOpen: false,
        },
      }
    case VaultV1Actions.MonSetCloseNightDepositoryFail:
      return {
        ...state,
        monCloseNightDepository: {
          ...state.monCloseNightDepository,
          isCloseNightDepositoryLoading: false,
        },
      }
    // [END] Close Night Depository ----------------------------------------------------------------

    // [START] Cash Limits ----------------------------------------------------------------
    case VaultV1Actions.MonGetCashLimitsRequest:
      return {
        ...state,
        monCashLimits: {
          ...state.monCashLimits,
          isLoading: true,
        },
      }
    case VaultV1Actions.MonGetCashLimitsResponse: {
      const selectedLimit = state.monCashLimits.selectedCashLimit

      let selected = null as MonCashLimit | null

      const limits = (payload?.limits || []) as MonCashLimit[]

      selected = limits.find(limit => limit.id === selectedLimit?.id) || limits[0] || null

      return {
        ...state,
        monCashLimits: {
          ...state.monCashLimits,
          isLoading: false,
          isLoaded: true,
          data: payload,
          selectedCashLimit: selected,
        },
      }
    }
    case VaultV1Actions.MonGetCashLimitsFail:
      return {
        ...state,
        monCashLimits: {
          ...state.monCashLimits,
          isLoading: false,
          isLoaded: true,
        },
      }

    case VaultV1Actions.MonSetSelectedCashLimit:
      return {
        ...state,
        monCashLimits: {
          ...state.monCashLimits,
          selectedCashLimit: payload,
        },
      }
    case VaultV1Actions.MonSetAddEditModalOpen:
      return {
        ...state,
        monCashLimits: {
          ...state.monCashLimits,
          cashLimitAddEditModalType: payload,
        },
      }
    // add & edit
    case VaultV1Actions.MonAddCashLimitsRequest:
    case VaultV1Actions.MonEditCashLimitsRequest:
      return {
        ...state,
        monCashLimits: {
          ...state.monCashLimits,
          isCashLimitsLoading: true,
        },
      }
    case VaultV1Actions.MonAddCashLimitsResponse:
    case VaultV1Actions.MonEditCashLimitsResponse:
      return {
        ...state,
        monCashLimits: {
          ...state.monCashLimits,
          isCashLimitsLoading: false,
          cashLimitAddEditModalType: null,
        },
      }
    case VaultV1Actions.MonAddCashLimitsFail:
    case VaultV1Actions.MonEditCashLimitsFail:
      return {
        ...state,
        monCashLimits: {
          ...state.monCashLimits,
          isCashLimitsLoading: false,
        },
      }

    case VaultV1Actions.MonUpdateAbsRemaindersRequest:
      return {
        ...state,
        isAbsRemaindersUpdating: true,
      }
    case VaultV1Actions.MonUpdateAbsRemaindersFail:
    case VaultV1Actions.MonUpdateAbsRemaindersResponse:
      return {
        ...state,
        isAbsRemaindersUpdating: false,
      }

    // [END] Cash Limits ----------------------------------------------------------------

    // [START] Charts ------------------------------------------------------------------------
    case VaultV1Actions.MonGetChartsRequest:
      return {
        ...state,
        forecast: {
          ...state.forecast,
          isLoading: true,
        },
      }

    case VaultV1Actions.MonGetChartsResponse:
      return {
        ...state,
        forecast: {
          ...state.forecast,
          isLoading: false,
          data: payload,
        },
      }
    case VaultV1Actions.MonGetChartsFail:
      return {
        ...state,
        forecast: {
          ...state.forecast,
          isLoading: false,
        },
      }
    // [END] Charts ------------------------------------------------------------------------

    // Reverse Balance
    case VaultV1Actions.MonRevertBalanceRequest:
      return {
        ...state,
        cashiers: {
          ...state.cashiers,
          isLoading: true,
        },
      }
    case VaultV1Actions.MonRevertBalanceResponse:
    case VaultV1Actions.MonRevertBalanceFail:
      return {
        ...state,
        cashiers: {
          ...state.cashiers,
          isLoading: false,
        },
      }
    case VaultV1Actions.SignOut:
      return initialState

    default:
      return state
  }
}
