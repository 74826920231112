export enum CommonActions {
  SetCurrentMedia = '@/ATMEYE_COMMON/SET_CURRENT_MEDIA',
  SetMediaRequest = '@/ATMEYE_COMMON/SET_MEDIA_REQUEST',
  GetMediaRequest = '@/ATMEYE_COMMON/GET_MEDIA_REQUEST',
  SetMediaRequestErrorMessage = '@/ATMEYE_COMMON/SET_MEDIA_REQUEST_ERROR_MESSAGE',
  ClearCurrentMedia = '@/ATMEYE_COMMON/CLEAR_CURRENT_MEDIA',

  SetAckWarning = '@/ATMEYE_COMMON/SET_ACK_WARNING',

  getAvailableModulesRequest = '@/ATMEYE_COMMON/GET_AVAILABLE_MODULES REQUEST',
  getAvailableModulesResponse = '@/ATMEYE_COMMON/GET_AVAILABLE_MODULES_RESPONSE',
}
