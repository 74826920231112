export enum NotificationsActions {
  ActivateNotifications = '@/ACTIVATE_NOTIFICATIONS',
  DisableNotifications = '@/DISABLE_NOTIFICAITONS',

  ToggleNotifications = '@/TOGGLE_NOTIFICATIONS',

  SetNewNotifications = '@/SET_NEW_NOTIFICATIONS',
  ReadNotifications = '@/READ_NOTIFICATIONS',

  SetCurrentTicket = '@/SET_CURRENT_TICKET',
}
