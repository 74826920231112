import { Typography } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { TreeView } from '@material-ui/lab'
import React, { FC, ReactElement, useCallback } from 'react'

import Loader from '@/components/blocks/Loader'
import { useTranslate } from '@/utils/internalization'

import { TreeElement } from './components/TreeElement/TreeItem'
import { useClasses } from './styles'
import { GroupNodes, Props } from './types'

const ObjectsTree: FC<Props> = React.memo(
  ({ isFetching, title, tree, selectedGroupingNodes, getLabel, onChange }: Props): ReactElement => {
    const classes = useClasses()
    const translate = useTranslate()

    const renderTree = useCallback(
      (tree: GroupNodes) => {
        if (tree?.length > 1000) {
          tree = tree?.slice(0, 1000)
        }

        return tree?.map(el => {
          return (
            <TreeElement
              selected={selectedGroupingNodes}
              setSelected={(selected): void => {
                onChange(selected)
              }}
              key={el.nodeId}
              labelText={
                getLabel?.(el) ?? `${el.nodeInfo.displayValue}  (${el.nodeInfo.elementsCount})`
              }
              node={el}
            >
              {el.childNodes && renderTree(el.childNodes)}
            </TreeElement>
          )
        })
      },
      [selectedGroupingNodes],
    )

    return !isFetching ? (
      <>
        {title && (
          <Typography variant="h5" className={classes.tableTitle}>
            {title}
          </Typography>
        )}

        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          disableSelection
        >
          {renderTree(tree)}
          {tree?.length > 1000 && (
            <Typography color="secondary" className={classes.noResults}>
              {translate('translate#atmeye.devices.searchResult')}
            </Typography>
          )}
        </TreeView>
      </>
    ) : (
      <div className={classes.loader}>
        <Loader />
      </div>
    )
  },
)

export { ObjectsTree }
