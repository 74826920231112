import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  column: {
    height: spacing(5),
    padding: '0 0 0 10px',
  },
  input: {
    margin: spacing(1, 0),

    '& .MuiInputBase-root': {
      height: `${spacing(3.25)}px !important`,
    },
  },

  itemWrapper: {
    display: 'flex',
  },
  listItemIcon: {
    minWidth: 'auto',
  },

  expiryDate: {
    color: 'white',
    background: 'green',
    borderRadius: '5px',
    padding: '0 5px 0 5px',
  },

  expiredDate: {
    background: 'red',
  },
}))
