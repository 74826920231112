import { AppNavigationRoutes, SRM_BLOCK_PREFIX } from '@/constants'

// TODO: change path and active for after adding new pages

export default [
  {
    path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.SearchServicePage}${AppNavigationRoutes.ServiceRequestPage}`,
    label: 'translate#title.searchServiceRequests',
    activeFor: [
      `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.SearchServicePage}${AppNavigationRoutes.ServiceRequestPage}`,
    ],
  },
  {
    path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.SearchServicePage}${AppNavigationRoutes.PlannedWorksPage}`,
    label: 'translate#title.searchPlannedWorks',
    activeFor: [
      `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.SearchServicePage}${AppNavigationRoutes.PlannedWorksPage}`,
    ],
  },
  {
    path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.SearchServicePage}${AppNavigationRoutes.ServiceWorksPage}`,
    label: 'translate#title.searchServiceWorks',
    activeFor: [
      `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.SearchServicePage}${AppNavigationRoutes.ServiceWorksPage}`,
    ],
  },
  {
    path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.SearchServicePage}${AppNavigationRoutes.ServiceDevice}`,
    label: 'translate#title.searchServiceDevice',
    activeFor: [
      `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.SearchServicePage}${AppNavigationRoutes.ServiceDevice}`,
    ],
  },
  {
    path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.SearchServicePage}${AppNavigationRoutes.Configuration}`,
    label: 'translate#title.searchConfiguration',
    activeFor: [
      `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.SearchServicePage}${AppNavigationRoutes.Configuration}`,
    ],
  },
  {
    path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.SearchServicePage}${AppNavigationRoutes.DeviceDefects}`,
    label: 'translate#title.deviceDefectsStatistics',
    activeFor: [
      `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.SearchServicePage}${AppNavigationRoutes.DeviceDefects}`,
    ],
  },
  {
    path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.SearchServicePage}${AppNavigationRoutes.WorksStatistics}`,
    label: 'translate#title.serviceWorksStatistics',
    activeFor: [
      `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.SearchServicePage}${AppNavigationRoutes.WorksStatistics}`,
    ],
  },
]
