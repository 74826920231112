import { AnyAction } from 'redux'

import { AttachedFile } from '@/api/common/commonApi/types'
import { ProductsActions } from '@/constants/actions/products'
import { ResponseErrorTypes } from '@/types/common/ErrorResponseTypes'
import {
  AlternativeProduct,
  Filter,
  GroupedProduct,
  Grouping,
  Product,
  Products,
  ProductsConfig,
  ProductSpecification,
  Sorting,
  StringFilter,
} from '@/types/products'

import { initialConfig, initialFilter, initialGrouping } from './initial-values'

export interface State {
  config: ProductsConfig
  products: Products
  groupedProducts: GroupedProduct[]
  filter: Filter
  grouping: Grouping
  sorting: Sorting | null
  stringFilter: {} | StringFilter
  pagination: {
    page: number
    rowsPerPage: number
  }
  currentProduct: {} | Product
  alternativeProducts: AlternativeProduct[]
  allowedToAddAlternativeProducts: AlternativeProduct[]
  productSpecification: ProductSpecification[]
  allowedToAddProductSpecification: ProductSpecification[]
  attachedFiles: AttachedFile[]
  isFetchingConfig: boolean
  isFetchingProducts: boolean
  isFetchingGroupedProducts: boolean
  isFetchingCurrentProduct: boolean
  isFetchingAddedProduct: boolean
  isFetchingEditedProduct: boolean
  isFetchingDeletedProduct: boolean
  isFetchingAlternativeProducts: boolean
  isFetchingAllowedToAddAlternativeProducts: boolean
  isSavingAlternativeProducts: boolean
  isFetchingProductSpecification: boolean
  isFetchingAllowedToAddProductSpecification: boolean
  isSavingProductSpecification: boolean
  isFetchingProductAttachedFiles: boolean
  error: ResponseErrorTypes | null
}

const initialState: State = {
  config: initialConfig,
  products: {
    totalCount: 0,
    hasGroups: false,
    groups: null,
    details: null,
  },
  groupedProducts: [],
  filter: initialFilter,
  grouping: initialGrouping,
  sorting: {
    productCode: 'desc',
  },
  stringFilter: {},
  pagination: {
    page: 1,
    rowsPerPage: 15,
  },
  currentProduct: {},
  alternativeProducts: [],
  allowedToAddAlternativeProducts: [],
  productSpecification: [],
  allowedToAddProductSpecification: [],
  attachedFiles: [],
  isFetchingConfig: false,
  isFetchingProducts: false,
  isFetchingGroupedProducts: false,
  isFetchingCurrentProduct: false,
  isFetchingAddedProduct: false,
  isFetchingEditedProduct: false,
  isFetchingDeletedProduct: false,
  isFetchingAlternativeProducts: false,
  isFetchingAllowedToAddAlternativeProducts: false,
  isSavingAlternativeProducts: false,
  isFetchingProductSpecification: false,
  isFetchingAllowedToAddProductSpecification: false,
  isFetchingProductAttachedFiles: false,
  isSavingProductSpecification: false,
  error: null as ResponseErrorTypes | null,
}

const getAlternativeProductsChangedData = (
  state: State,
  {
    addedProducts,
    deletedProducts,
  }: { addedProducts: AlternativeProduct[]; deletedProducts: number[] },
): State => {
  let newState: State = { ...state }

  newState = {
    ...newState,
    alternativeProducts: [
      ...state.alternativeProducts.filter(
        (obj: AlternativeProduct) => !deletedProducts.includes(Number(obj.id)),
      ),
      ...addedProducts,
    ],
    isSavingAlternativeProducts: false,
  }

  return newState
}

const getProductSpecificationChangedData = (
  state: State,
  {
    addedProductSpecifications,
    deletedProductSpecifications,
  }: { addedProductSpecifications: ProductSpecification[]; deletedProductSpecifications: number[] },
): State => {
  let newState: State = { ...state }

  newState = {
    ...newState,
    productSpecification: [
      ...state.productSpecification.filter(
        (obj: ProductSpecification) =>
          !deletedProductSpecifications.includes(Number(obj.productSpecificationId)) &&
          obj.productSpecificationId === null,
      ),
      ...addedProductSpecifications,
    ],
    isSavingProductSpecification: false,
  }

  return newState
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case ProductsActions.GetConfigRequest:
      return { ...state, error: null, isFetchingConfig: true }
    case ProductsActions.GetConfigResponse:
      return { ...state, config: payload, isFetchingConfig: false }
    case ProductsActions.GetConfigResponseFail:
      return { ...state, error: payload, isFetchingConfig: false }

    case ProductsActions.GetProductsRequest:
      return { ...state, error: null, isFetchingProducts: true }
    case ProductsActions.GetProductsResponse:
      return { ...state, products: payload, isFetchingProducts: false }
    case ProductsActions.GetProductsResponseFail:
      return { ...state, error: payload, isFetchingProducts: false }

    case ProductsActions.GetGroupedProductsRequest:
      return { ...state, error: null, isFetchingProducts: true }
    case ProductsActions.GetGroupedProductsResponse:
      return { ...state, products: payload, isFetchingProducts: false }
    case ProductsActions.GetGroupedProductsResponseFail:
      return { ...state, error: payload, isFetchingProducts: false }

    case ProductsActions.GetProductByIdRequest:
      return { ...state, error: null, isFetchingCurrentProduct: true }
    case ProductsActions.GetProductByIdResponse:
      return { ...state, isFetchingCurrentProduct: false, currentProduct: payload }
    case ProductsActions.GetProductByIdResponseFail:
      return { ...state, error: payload, isFetchingProducts: false }

    case ProductsActions.AddNewProductRequest:
      return { ...state, error: null, isFetchingAddedProduct: true }
    case ProductsActions.AddNewProductResponse:
      return { ...state, isFetchingAddedProduct: false, currentProduct: payload }
    case ProductsActions.AddNewProductResponseFail:
      return { ...state, error: payload, isFetchingAddedProduct: false }

    case ProductsActions.EditProductRequest:
      return { ...state, error: null, isFetchingEditedProduct: true }
    case ProductsActions.EditProductResponse:
      return { ...state, isFetchingEditedProduct: false }
    case ProductsActions.EditProductResponseFail:
      return { ...state, error: payload, isFetchingEditedProduct: false }

    case ProductsActions.DeleteProductRequest:
      return { ...state, error: null, isFetchingDeletedProduct: true }
    case ProductsActions.DeleteProductResponse:
      return { ...state, isFetchingDeletedProduct: false }
    case ProductsActions.DeleteProductResponseFail:
      return { ...state, error: payload.error, isFetchingDeletedProduct: false }

    case ProductsActions.GetAlternativeProductsRequest:
      return { ...state, error: null, isFetchingAlternativeProducts: true }
    case ProductsActions.GetAlternativeProductsResponse:
      return { ...state, alternativeProducts: payload, isFetchingAlternativeProducts: false }
    case ProductsActions.GetAlternativeProductsResponseFail:
      return { ...state, error: payload, isFetchingAlternativeProducts: false }

    case ProductsActions.GetAllowedToAddAlternativeProductsRequest:
      return { ...state, error: null, isFetchingAllowedToAddAlternativeProducts: true }
    case ProductsActions.GetAllowedToAddAlternativeProductsResponse:
      return {
        ...state,
        allowedToAddAlternativeProducts: payload,
        isFetchingAllowedToAddAlternativeProducts: false,
      }
    case ProductsActions.GetAllowedToAddAlternativeProductsResponseFail:
      return { ...state, error: payload, isFetchingAllowedToAddAlternativeProducts: false }

    case ProductsActions.SaveAlternativeProductsRequest:
      return { ...state, error: null, isSavingAlternativeProducts: true }
    case ProductsActions.SaveAlternativeProductsResponse:
      return getAlternativeProductsChangedData(state, payload)
    // return { ...state, alternativeProducts: payload, isSavingAlternativeProducts: false }
    case ProductsActions.SaveAlternativeProductsResponseFail:
      return { ...state, error: payload, isSavingAlternativeProducts: false }

    case ProductsActions.GetProductSpecificationRequest:
      return { ...state, error: null, isFetchingProductSpecification: true }
    case ProductsActions.GetProductSpecificationResponse:
      return { ...state, productSpecification: payload, isFetchingProductSpecification: false }
    case ProductsActions.GetProductSpecificationResponseFail:
      return { ...state, error: payload, isFetchingProductSpecification: false }

    case ProductsActions.GetAllowedToAddProductSpecificationRequest:
      return { ...state, error: null, isFetchingAllowedToAddProductSpecification: true }
    case ProductsActions.GetAllowedToAddProductSpecificationResponse:
      return {
        ...state,
        allowedToAddProductSpecification: payload,
        isFetchingAllowedToAddProductSpecification: false,
      }
    case ProductsActions.GetAllowedToAddProductSpecificationResponseFail:
      return { ...state, error: payload, isFetchingAllowedToAddProductSpecification: false }

    case ProductsActions.SaveProductSpecificationRequest:
      return { ...state, error: null, isSavingProductSpecification: true }
    case ProductsActions.SaveProductSpecificationResponse:
      return getProductSpecificationChangedData(state, payload)
    case ProductsActions.SaveProductSpecificationResponseFail:
      return { ...state, error: payload, isSavingProductSpecification: false }

    case ProductsActions.GetProductAttachedFilesRequest:
      return { ...state, error: null, isFetchingProductAttachedFiles: true }
    case ProductsActions.GetProductAttachedFilesResponse:
      return { ...state, attachedFiles: payload, isFetchingProductAttachedFiles: false }
    case ProductsActions.GetProductAttachedFilesResponseFail:
      return { ...state, error: payload, isFetchingProductAttachedFiles: false }

    case ProductsActions.AddProductAttachedFileRequest:
      return { ...state, isFetchingProductAttachedFiles: true, error: null }
    case ProductsActions.AddProductAttachedFileResponse:
      return {
        ...state,
        isFetchingProductAttachedFiles: false,
        attachedFiles: [...state.attachedFiles, payload],
      }

    case ProductsActions.RemoveProductAttachedFileRequest:
      return { ...state, isFetchingProductAttachedFiles: true, error: null }
    case ProductsActions.RemoveProductAttachedFileResponse:
      return {
        ...state,
        isFetchingProductAttachedFiles: false,
        attachedFiles: state.attachedFiles.filter(file => file.objectSysFileId !== payload),
      }
    case ProductsActions.SetClearErrors:
      return {
        ...state,
        error: null,
      }
    default:
      return state
  }
}
