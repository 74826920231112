import { LocalStorageItems } from '@/constants'

import { Strategy } from './types'

class StrategyLS implements Strategy {
  get(key: string): any {
    const savedData = window.localStorage.getItem(key)
    return savedData ? JSON.parse(savedData) : ''
  }

  set(key: string, value: any): any {
    const newData = JSON.stringify(value)
    window.localStorage.setItem(key, newData)
  }

  remove(key: LocalStorageItems): any {
    window.localStorage.removeItem(key)
  }
}

export default new StrategyLS()
