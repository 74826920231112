import makeStyles from '@material-ui/core/styles/makeStyles'
import { styledComponentTheme } from '@/theme'
import { Height } from '@material-ui/icons'
import { initial } from 'lodash-es'

export const useClasses = makeStyles(({ palette, spacing, shape: { borderRadius } }) => ({
  iconSize: {
    fontSize: styledComponentTheme.fontSizes.veryBig,
  },
  menu: {
    top: `${spacing(1.93)}px !important`,
  },
  list: {
    padding: 0,
  },
  menuWrapper: {
    color: palette.grey[50],
    marginLeft: spacing(20),
  },
  item: {
    padding: spacing(1, 2),
  },

  menuItems: {
    paddingLeft: spacing(0),
    paddingRight: spacing(0),
  },
}))
