import { FormikValues } from 'formik'
import moment from 'moment'

import { GetTicketsBodyFilterItem, GetTicketsBodyGroupItem } from '@/api/sd/ticketsApi/types'
import { TableGroup } from '@/components/pages/sd/TicketsPage/components/GroupingModal/types'
import { FilterParameter, FilterTypeDisplayName, Group } from '@/types'
import { createDateFilters } from '@/utils'

export type appliedFiltersTypes = {
  filterLabel: string
  filterValue: string
}

const parameterNamesLocalDate = ['atm_date', 'parent_atm_date']

export const gettingDevices = {
  getSortDetails: (sorting: { [key: string]: 'asc' | 'desc' } | null) => [
    {
      fieldName: sorting ? Object.keys(sorting)[0] : 'device_name',
      sortOrder: sorting ? (Object.values(sorting)[0].toUpperCase() as 'ASC' | 'DESC') : 'DESC',
    },
  ],
  getDeviceSortDetails: (sorting: { [key: string]: 'asc' | 'desc' } | null) => [
    {
      fieldName: sorting ? Object.keys(sorting)[0] : 'client_id',
      sortOrder: sorting ? (Object.values(sorting)[0].toUpperCase() as 'ASC' | 'DESC') : 'DESC',
    },
  ],
  getEventSortDetails: (sorting: { [key: string]: 'asc' | 'desc' } | null) => [
    {
      fieldName: sorting ? Object.keys(sorting)[0] : 'event_name',
      sortOrder: sorting ? (Object.values(sorting)[0].toUpperCase() as 'ASC' | 'DESC') : 'DESC',
    },
  ],
  getByDeviceSortDetails: (sorting: { [key: string]: 'asc' | 'desc' } | null) => [
    {
      fieldName: sorting ? Object.keys(sorting)[0] : 'atm_date',
      sortOrder: sorting ? (Object.values(sorting)[0].toUpperCase() as 'ASC' | 'DESC') : 'DESC',
    },
  ],
  getBlackListSortDetails: (sorting: { [key: string]: 'asc' | 'desc' } | null) => [
    {
      fieldName: sorting ? Object.keys(sorting)[0] : 'expire_date',
      sortOrder: sorting ? (Object.values(sorting)[0].toUpperCase() as 'ASC' | 'DESC') : 'DESC',
    },
  ],
  getSnapshotsSortDetails: (sorting: { [key: string]: 'asc' | 'desc' } | null) => [
    {
      fieldName: sorting ? Object.keys(sorting)[0] : 'snapshot_date',
      sortOrder: sorting ? (Object.values(sorting)[0].toUpperCase() as 'ASC' | 'DESC') : 'DESC',
    },
  ],
  getEventDictionaryDetails: (sorting: { name: string; sort: string } | null) => {
    return {
      sortField:
        sorting?.name === 'internalType'
          ? 'internal_type'.toUpperCase()
          : sorting?.name.toUpperCase(),
      sortDirection: sorting?.sort.toUpperCase(),
    }
  },
  getFilters: (selectedFilters: FormikValues, filtersConfig: any[]): GetTicketsBodyFilterItem[] => {
    const filters: GetTicketsBodyFilterItem[] = []
    const INTEGER_VALUE_TYPE = 'INTEGER'
    Object.keys(selectedFilters).forEach(key => {
      const filterValue = selectedFilters[key]

      if (!filterValue || (Array.isArray(filterValue) && !filterValue.length)) {
        return
      }

      const config = filtersConfig.find(config => config.parameterName === key)

      if (config) {
        const filterItem: GetTicketsBodyFilterItem = {
          parameterName: config.parameterName,
          valueType: config.valueType,
          parameterValue: null,
          rangeValues: null,
        }

        if (config.displayType === 'RANGE') {
          if (config.valueType === 'INTEGER') {
            if (filterValue[0] === null && filterValue[1] === null) {
              return
            } else {
              filterItem.rangeValues = {
                from: filterValue[0] ? filterValue[0] : 0,
                to: filterValue[1] ? filterValue[1] : 1000,
              }
            }
          }
          if (config.valueType === 'DATE') {
            if (filterValue[0] === null || filterValue[1] === null) {
              return
            } else {
              filterItem.rangeValues = {
                from: Math.floor(
                  moment(filterValue[0])
                    .utc()
                    .valueOf() / 1000,
                ),
                to: Math.floor(
                  moment(filterValue[1])
                    .utc()
                    .valueOf() / 1000,
                ),
              }
            }
          }
          if (config.valueType === 'DATETIME' || config.valueType === 'ZONED_DATETIME') {
            const isLocalDate = parameterNamesLocalDate.includes(config.parameterName)

            if (filterValue[0] === null) {
              return
            } else {
              filterItem.rangeValues = {
                from: createDateFilters(filterValue[0], isLocalDate),
                to: filterValue[1] ? createDateFilters(filterValue[1], isLocalDate) : null,
              }
            }
          }
        } else {
          if (Array.isArray(filterValue)) {
            filterItem.parameterValue = filterValue.map((item: string) => ({
              id: config.valueType === INTEGER_VALUE_TYPE ? +item : null,
              value: config.valueType === INTEGER_VALUE_TYPE ? null : item,
            }))
          } else {
            if (config.parameterName === 'merchant' || config.parameterName === 'devcity') {
              filterItem.parameterValue = [
                {
                  id: config.valueType === INTEGER_VALUE_TYPE ? +filterValue : null,
                  value: config.valueType === INTEGER_VALUE_TYPE ? null : filterValue,
                  name: '',
                },
              ]
            } else {
              filterItem.parameterValue = [
                {
                  id: config.valueType === INTEGER_VALUE_TYPE ? +filterValue : null,
                  value: config.valueType === INTEGER_VALUE_TYPE ? null : filterValue,
                },
              ]
            }
          }
        }
        filters.push(filterItem)
      }
    })

    return filters
  },
  getGroups: (selectedGrouping: TableGroup[], groupsConfig: Group[]): GetTicketsBodyGroupItem[] => {
    const groups: GetTicketsBodyGroupItem[] = []

    selectedGrouping.forEach(({ key, order, orderBy }: TableGroup, idx) => {
      const config = groupsConfig.find(c => c.groupUID === key)

      if (config) {
        const { valueType, outGroupFieldDisplayType } = config

        const group = {
          groupLevelNo: idx + 1,
          groupUID: key,
          parentGroupUID: idx === 0 ? [] : selectedGrouping.map(group => group.key).slice(0, idx),
          sortBy: orderBy === 'count' ? 'RECORD_COUNT' : 'DISPLAY_VALUE',
          sortOrder: order.toUpperCase(),
          value: '',
          valueType,
          displayValueType: outGroupFieldDisplayType,
        }
        groups.push(group)
      }
    })
    return groups
  },
  updateSelectedItems: (
    items: any,
    cardId: string | null,
    option: string,
    fieldName: string,
  ): any => {
    return items.map((item: any) => {
      switch (option) {
        case 'single': {
          if (item.item[fieldName] === cardId) {
            return {
              ...item,
              item: {
                ...item.item,
                selected: !item.item.selected,
              },
            }
          }
          return item
        }
        case 'selectAll': {
          return {
            ...item,
            item: {
              ...item.item,
              selected: true,
            },
          }
        }
        case 'deselectAll': {
          return {
            ...item,
            item: {
              ...item.item,
              selected: false,
            },
          }
        }
        default:
          return item
      }
    })
  },

  getAppliedFilters: (
    selectedFilters: FormikValues,
    filtersConfig: Array<FilterParameter>,
    filterFields?: any,
  ): Array<appliedFiltersTypes> => {
    if (selectedFilters) {
      return Object.entries(selectedFilters).reduce((acc, [key, value]) => {
        filtersConfig.forEach(({ parameterName, displayType, label, lookupInfo }) => {
          if (parameterName === key) {
            switch (displayType) {
              case FilterTypeDisplayName.Text:
              case FilterTypeDisplayName.Checkbox:
                if (value) {
                  acc.push({ filterLabel: label, filterValue: value })
                }
                break
              case FilterTypeDisplayName.List:
              case FilterTypeDisplayName.RadioGroup:
              case FilterTypeDisplayName.YesNo:
              case FilterTypeDisplayName.YesNoAll:
                if (value.length && value[0] !== null) {
                  acc.push({
                    filterLabel: label,
                    filterValue:
                      lookupInfo?.lookupValues && lookupInfo?.lookupValues.length > 0
                        ? lookupInfo?.lookupValues
                            .filter(el => value.includes(el?.value))
                            .map(el => el.display)
                            .join(', ') || ''
                        : filterFields && filterFields[key]
                        ? filterFields[key].displayName
                        : '',
                  })
                }
                break
              case FilterTypeDisplayName.Date:
              case FilterTypeDisplayName.Range:
              case FilterTypeDisplayName.ZonedDate:
                acc.push({
                  filterLabel: label,
                  filterValue: value.join(',- '),
                })
              // eslint-disable-next-line no-fallthrough
              default:
            }
          }
        })
        return acc
      }, [] as Array<appliedFiltersTypes>)
    } else {
      return [] as Array<appliedFiltersTypes>
    }
  },
}
