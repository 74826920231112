import React, { ReactElement } from 'react'

import EditableTable from '../EditableTable'
import PaperWrapper from '../PaperWrapper'
import { useClasses } from './styles'
import { Props } from './types'

const UserInformationTable = ({
  headers,
  data,
  disableAction,
  isLoading,
  handleAddNewRow,
  handleChangeRow,
  handleDeleteRows,
  handleSelectRow,
  selectedRows,
  changedRows,
  deletedRows,
  newRows,
  checkboxColumnsNames,
}: Props): ReactElement => {
  const classes = useClasses()

  return (
    <PaperWrapper classes={{ paper: classes.paper }}>
      {!!headers.length && (
        <EditableTable
          isLoading={isLoading}
          headers={headers}
          data={data}
          checkboxColumnsNames={checkboxColumnsNames}
          selectRowsOptions={{
            selectedRows,
            onSelectRow: handleSelectRow,
          }}
          deleteRowsOptions={{
            deletedRows,
            onDeleteRow: handleDeleteRows,
          }}
          changeRow={{
            changedRows,
            onChangeRow: handleChangeRow,
          }}
          addNewRow={{
            newRows,
            onAddNewRow: handleAddNewRow,
          }}
          disableAction={disableAction}
        />
      )}
    </PaperWrapper>
  )
}

export default UserInformationTable
