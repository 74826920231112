import { IconButton, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import clsx from 'clsx'
import { FormikValues } from 'formik'
import { find, isEmpty, isEqual } from 'lodash-es'
import moment from 'moment'
import React, { ReactElement, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import Tooltip from '@/components/blocks/Tooltip'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { getCurrentAccount } from '@/store/auth/selectors'
import { TicketsConfigData } from '@/types/sd/tickets'
import { getTableSettings } from '@/utils/localStorage'

import { useClasses } from './styles'
import { Props } from './types'

const FiltersRow = ({
  tableId,
  changeSelectedFilters,
  getSelectedFilters,
  isFiltersSelected,
  getTicketsConfigData,
  getTicketsRequest,
  windowType,
  setTicketsPage,
  setHistoryTicketsFiltersModalState,
  getFilterTemplateFields,
  getRequestBodyLsKey,
  setCurrentFilterTemplate,
  dateFormat,
  groupTableId,
  typeOfTickets,
}: Props): ReactElement => {
  const account = useSelector(getCurrentAccount)
  const dispatch = useDispatch()
  const classes = useClasses()
  const { filter: filtersFields }: TicketsConfigData = getTicketsConfigData
  const selectedFilters: FormikValues = getSelectedFilters
  const filterFields = useSelector(getFilterTemplateFields)

  const handleRemove = useCallback(
    (value: string | null, key: string): (() => void) => (): void => {
      dispatch(setTicketsPage(1))

      if (Array.isArray(value)) {
        selectedFilters[key] = []
      } else {
        selectedFilters[key] = ''
      }

      const isEmpty = !Object.values(selectedFilters).some(value => {
        if (Array.isArray(value)) {
          return value.some(item => item)
        }
        return value
      })

      if (isEmpty) {
        dispatch(changeSelectedFilters({}))
      } else {
        dispatch(changeSelectedFilters(selectedFilters))
      }

      dispatch(getTicketsRequest(false, windowType, getRequestBodyLsKey, groupTableId))

      if (setHistoryTicketsFiltersModalState) {
        dispatch(setHistoryTicketsFiltersModalState())
      }
    },
    [dispatch, selectedFilters, windowType, getRequestBodyLsKey, groupTableId],
  )

  const handleClear = useCallback(
    (): (() => void) => (): void => {
      dispatch(setTicketsPage(1))
      dispatch(changeSelectedFilters({}))
      dispatch(setCurrentFilterTemplate(''))
      dispatch(getTicketsRequest(false, windowType, getRequestBodyLsKey, groupTableId))
      if (setHistoryTicketsFiltersModalState) {
        dispatch(setHistoryTicketsFiltersModalState())
      }
    },
    [dispatch, windowType, getRequestBodyLsKey, groupTableId],
  )

  const renderMultiSelectedFiltersRow = (value: Array<string>, key: string) => {
    return value
      .map(
        item =>
          find(filtersFields, {
            parameterName: key,
          })?.lookupInfo?.lookupValues.find(({ value }) => value === item)?.display,
      )
      .join(', ')
  }
  return (
    <div className={classes.root}>
      {isFiltersSelected &&
        !isEmpty(filtersFields) &&
        Object.entries(selectedFilters).map(([key, value]) => {
          if (!isEmpty(value) && !isEqual(value, [null, null])) {
            const element = find(filtersFields, { parameterName: key })
            let tableSettings = null
            if (account) {
              tableSettings = getTableSettings(
                LocalStorageItems.UserSettings,
                account.userId.toString(),
                tableId,
              )
            }

            return (
              <div key={key} className={classes.bookmark}>
                <Typography variant="caption" className={classes.bookmarkText}>
                  {`${element?.label}: `}
                  {Array.isArray(value) ? (
                    element?.valueType === 'DATE' ||
                    element?.valueType === 'DATETIME' ||
                    element?.valueType === 'ZONED_DATETIME' ? (
                      value.map((item: any, index: number) => (
                        <span key={`${item}_${index}`} className={classes.bookmarkDate}>
                          {moment(item).format(dateFormat || 'L, LT')}
                        </span>
                      ))
                    ) : element?.valueType === 'INTEGER' && element?.displayType === 'RANGE' ? (
                      value.map(item => (
                        <span key={item} className={classes.bookmarkRange}>
                          {item}
                        </span>
                      ))
                    ) : (
                      <Tooltip
                        title={renderMultiSelectedFiltersRow(value, key)}
                        className={classes.withTooltip}
                        interactive
                      >
                        <span className={classes.bookmarkMultiple}>
                          {renderMultiSelectedFiltersRow(value, key)}
                        </span>
                      </Tooltip>
                    )
                  ) : element?.lookupInfo ? (
                    element?.lookupInfo?.allowRecursiveCallsBySearchFragment ? (
                      Object.keys(filterFields).length >= 1 && account ? (
                        tableSettings?.[getRequestBodyLsKey]?.filterTemplateFields.filterFields ? (
                          tableSettings?.[getRequestBodyLsKey]?.filterTemplateFields.filterFields[
                            key
                          ]?.displayName
                        ) : (
                          ''
                        )
                      ) : (
                        find(filterFields, { id: value })?.displayName
                      )
                    ) : (
                      element?.lookupInfo?.lookupValues.find(o => o.value === value)?.display
                    )
                  ) : (
                    <Tooltip title={value} className={classes.withTooltip} interactive>
                      <span className={classes.bookmarkMultiple}>{value}</span>
                    </Tooltip>
                  )}
                </Typography>
                <IconButton
                  size="small"
                  className={classes.bookmarkIcon}
                  onClick={handleRemove(value, key)}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              </div>
            )
          }
        })}
      {isFiltersSelected && !isEmpty(filtersFields) && (
        <div className={clsx(classes.bookmark, classes.bookmarkClear)}>
          <Typography variant="caption" className={classes.bookmarkText}>
            <FormattedMessage id="title.clearAllFilters" defaultMessage="Clear all filters" />
          </Typography>
          <IconButton size="small" className={classes.bookmarkIcon} onClick={handleClear()}>
            <Close fontSize="inherit" />
          </IconButton>
        </div>
      )}
    </div>
  )
}

export default FiltersRow
