import { AnyAction } from 'redux'
import { DirectSelectScript } from '@/constants'

export interface State {
  isOpenModal: boolean
}

export const initialState: State = {
  isOpenModal: false,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case DirectSelectScript.SetDirectSelectScriptOpen:
      return {
        ...state,
        isOpenModal: payload,
      }
    default:
      return state
  }
}
