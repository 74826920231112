import { has } from 'lodash'

import { ComFilterTreeNode } from '@/api/cm/swaggerGeneratedApi'

const countSubLeafs = (root: ComFilterTreeNode): number => {
  let count = root.leafs?.length || 0
  if (root.folders) {
    root.folders.forEach(node => {
      count += countSubLeafs(node)
    })
  }
  return count
}

// Object tree [New version]
export interface MappedNodesProps {
  value?: string
  label?: string
  showCheckbox: boolean
  nodeType?: string
  parentId?: string | null
  children?: MappedNodesProps[]
}

interface MapNodeProps {
  node: ComFilterTreeNode
  level?: number
  showCheckbox?: boolean

  parentId?: string | null
}

export const mapNodes = ({
  node,
  level = 0,
  parentId,
  showCheckbox = true,
}: MapNodeProps): MappedNodesProps => {
  const totalLeafs = countSubLeafs(node)
  const isFolder = has(node, 'folder') || has(node, 'leafs')

  return {
    value: node.id,
    label: `${node.name || ''}${totalLeafs > 0 ? ' (' + totalLeafs + ')' : ''}`,
    showCheckbox: showCheckbox && isFolder,
    parentId: parentId || null,
    children: [
      ...(node.folders || [])?.map(folder =>
        mapNodes({ node: folder, level: level + 1, showCheckbox, parentId: node.id }),
      ),
      ...(node.leafs || [])?.map(leaf => ({
        value: leaf.id,
        label: leaf.name,
        parentId: node.id,
        nodeType: leaf.nodeType,
        showCheckbox: false,
      })),
    ],
  }
}

export const getDeppNodes = (folders: MappedNodesProps[]): string[] => {
  if (Array.isArray(folders)) {
    return folders.reduce((done: string[], node) => {
      const { value, label, children } = node || {}

      if (value && label && children) {
        const element = getDeppNodes(children || [])

        return done.concat([value, ...element])
      } else {
        return done
      }
    }, [])
  } else {
    return folders
  }
}
