import { FormikValues } from 'formik'
import { AnyAction } from 'redux'

import { GetTicketsBodyFilterItem } from '@/api/sd/ticketsApi/types'
import { TableGroup } from '@/components/pages/sd/TicketsPage/components/GroupingModal/types'
import { ActionsReports } from '@/constants/atmeye/actions/actionsReports'
import { AtmeyeReportsActions, TicketActions } from '@/constants/index'
import {
  ApiGroupTemplate,
  DevicesConfigResponse,
  ResponseError,
  TypeOfTickets,
  UIGroupTemplate,
} from '@/types'
import { GetTicketsResponse } from '@/types/sd/common'
import { WindowType } from '@/types/sd/tickets/ticket'
import { TicketDetailsResponse } from '@/types/sd/tickets/ticketDetails'

export const setSelectedGroupingTemplate = (template: string): AnyAction => ({
  type: AtmeyeReportsActions.SetSelectedGroupingTemplate,
  payload: template,
})

export const getDevicesRequest = (isInitRequest = false): AnyAction => ({
  type: AtmeyeReportsActions.GetDevicesRequest,
  payload: { isInitRequest },
})

export const resetGroups = (): AnyAction => ({
  type: AtmeyeReportsActions.ResetGroups,
})

export const getTicketsResponse = (tickets: GetTicketsResponse): AnyAction => ({
  type: AtmeyeReportsActions.GetTicketResponse,
  payload: tickets,
})

export const getTicketsResponseFail = (error: ResponseError): AnyAction => ({
  type: AtmeyeReportsActions.GetTicketResponseFail,
  payload: { error },
})

export const changeSelectedFilters = (filters: FormikValues): AnyAction => ({
  type: AtmeyeReportsActions.ChangeSelectedFilters,
  payload: filters,
})

export const getDevicesFilterTemplatesRequest = (): AnyAction => ({
  type: AtmeyeReportsActions.GetFiltersTemplatesRequest,
})

export const setFilterTemplatesResponse = (payload: { id: string; name: string }[]): AnyAction => ({
  type: AtmeyeReportsActions.SetFilterTemplatesResponse,
  payload,
})

export const createFilterTemplateRequest = (payload: {
  filters: GetTicketsBodyFilterItem[]
  name: string
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: AtmeyeReportsActions.CreateFilterTemplateRequest,
  payload,
})

export const createSdFilterTemplateResponse = (payload: any): AnyAction => ({
  type: TicketActions.CreateSdFilterTemplateResponse,
  payload,
})

export const updateFilterTemplateRequest = (payload: {
  filters: any
  name: string
  windowType: WindowType
  id: string
  translate: any
}): AnyAction => ({
  type: AtmeyeReportsActions.UpdateFilterTemplateRequest,
  payload,
})

export const collectFilterTemplateFields = (filterTemplateFields: {
  fieldName: string
  value: { id: string; displayName: string }
}): AnyAction => ({
  type: TicketActions.CollectFilterTemplateFields,
  payload: filterTemplateFields,
})

export const updateSdFilterTemplateResponse = (payload: {
  filters: any
  name: string
  windowType: WindowType
  id: string
  translate: any
}): AnyAction => ({
  type: TicketActions.UpdateSdFilterTemplateResponse,
  payload,
})

export const deleteFilterTemplateRequest = (payload: {
  id: string
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: AtmeyeReportsActions.DeleteFilterTemplateRequest,
  payload,
})

export const deleteSdFilterTemplateResponse = (payload: any): AnyAction => ({
  type: TicketActions.DeleteSdFilterTemplateResponse,
  payload,
})

export const getGroupTemplatesRequest = (payload: string): AnyAction => ({
  type: AtmeyeReportsActions.GetGroupsTemplatesRequest,
  payload,
})

export const editGroupTemplateRequest = (payload: {
  template: UIGroupTemplate
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: AtmeyeReportsActions.EditGroupTemplateRequest,
  payload,
})

export const editGroupTemplateFromResponse = (payload: UIGroupTemplate): AnyAction => ({
  type: TicketActions.EditSdGroupTemplateFromResponse,
  payload,
})

export const deleteGroupTemplateRequest = (payload: {
  template: UIGroupTemplate
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: AtmeyeReportsActions.DeleteGroupTemplateRequest,
  payload,
})

export const deleteSdGroupTemplateResponseSuccess = (payload: {
  template: UIGroupTemplate
}): AnyAction => ({
  type: TicketActions.DeleteSdGroupTemplateResponseSuccess,
  payload,
})

export const saveGroupTemplateRequest = (
  template: {
    windowType: WindowType
    name: string
    groupingLevels: ApiGroupTemplate[]
  },
  translate: any,
): AnyAction => ({
  type: AtmeyeReportsActions.SaveGroupingTemplateRequest,
  payload: { template, translate },
})

export const setGroupTemplates = (
  templates: UIGroupTemplate[],
  withAppendNewTemplate = false,
): AnyAction => ({
  type: TicketActions.SetGroupTemplates,
  payload: { templates, withAppendNewTemplate },
})

export const setCurrentFilterTemplate = (value: string): AnyAction => ({
  type: TicketActions.SetCurrentFilterTemplate,
  payload: value,
})

export const getDevicesConfigRequest = (): AnyAction => ({
  type: AtmeyeReportsActions.GetDevicesConfigRequest,
})

export const getDevicesConfigResponse = (
  typeOfTickets: TypeOfTickets,
  ticketsConfigData: DevicesConfigResponse,
): AnyAction => ({
  type: AtmeyeReportsActions.GetDevicesConfigResponse,
  payload: { typeOfTickets, config: ticketsConfigData.formConfig },
})

export const getTicketsConfigResponseFail = (error: ResponseError): AnyAction => ({
  type: TicketActions.GetTicketsConfigResponseFail,
  payload: error,
})

export const getTicketDetailsResponse = ({ ticketDetails }: TicketDetailsResponse): AnyAction => ({
  type: TicketActions.GetTicketDetailsResponse,
  payload: ticketDetails,
})

export const getTicketDetailsResponseFail = (error: ResponseError): AnyAction => ({
  type: TicketActions.GetTicketDetailsResponseFail,
  payload: { error },
})

export const changeSorting = (sorting: { [key: string]: 'asc' | 'desc' } | null): AnyAction => ({
  type: TicketActions.ChangeSorting,
  payload: sorting,
})

export const changeGrouping = (groups: TableGroup[]): AnyAction => ({
  type: AtmeyeReportsActions.ChangeGrouping,
  payload: groups,
})

export const setTicketsRowsPerPage = (rowsCount: number): AnyAction => ({
  type: TicketActions.SetRowPerPage,
  payload: rowsCount,
})

export const setTicketsPage = (page: number): AnyAction => ({
  type: TicketActions.SetPage,
  payload: page,
})

export const setDataPagination = (data: any): AnyAction => ({
  type: AtmeyeReportsActions.SetDataPagination,
  payload: data,
})

// ATMeye fast reports
export const setInProgressReports = (inProgressReports: string[]): AnyAction => ({
  type: ActionsReports.SetInProgressReports,
  payload: inProgressReports,
})
