import queryString from 'query-string'

import { AdmCompany, AdmCompanyBase } from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

interface UpdateProps {
  name: string
  shortName: string
  address: string
  id: string
}

export class CompaniesApi {
  private static requestService = getRequestServiceCM()

  public static async get(sort: Sort): Promise<AdmCompany[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/companies',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await CompaniesApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getNew(): Promise<AdmCompanyBase> {
    try {
      return await CompaniesApi.requestService.get('/admin/companies/new')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(payload: { name: string }): Promise<void> {
    try {
      return await CompaniesApi.requestService.post('/admin/companies/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(payload: UpdateProps): Promise<void> {
    try {
      return await CompaniesApi.requestService.put('/admin/companies/update', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(id: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/companies/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await CompaniesApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
