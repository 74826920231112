import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  modal: {
    maxWidth: '800px',
  },
  modalHeader: {
    fontSize: '20px',
    lineHeight: '23px',
    marginBottom: 20,
  },
  paper: {
    padding: spacing(3),
  },

  message: {
    maxHeight: '300px',
    overflow: 'auto',
    color: 'rgb(0 0 0 / 70%)',
  },

  navigate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '10px',
  },

  navigateButton: {
    padding: '4px',
    margin: 0,
    width: 'fit-content',
    minWidth: 'auto',
  },

  navigateIcon: {
    cursor: 'pointer',
    transition: '0.2s',
    opacity: 0.7,
    fontSize: '30px',

    '&:hover': {
      opacity: 1,
    },
  },

  disabled: {
    pointerEvent: 'none',
    cursor: 'default',
    opacity: '0.3!important',
  },

  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '20px',
  },

  closeButton: {
    height: 40,
    display: 'block',
    fontWeight: 'normal',
    marginLeft: 'auto',
  },
}))
