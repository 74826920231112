import { translate } from '@/utils/internalization'

export enum TableColumns {
  SorterId = 'SorterId',
  Name = 'Name',
  Address = 'Address',
  Location = 'Location',
  Model = 'Model',
  Vendor = 'Vendor',
  User = 'User',
}

export const headerConfig = {
  [TableColumns.SorterId]: translate('translate#cm.SorterId'),
  [TableColumns.Name]: translate('translate#cm.Name'),
  [TableColumns.Address]: translate('translate#cm.Address'),
  [TableColumns.Location]: translate('translate#cm.Location'),
  [TableColumns.Model]: translate('translate#cm.Model'),
  [TableColumns.Vendor]: translate('translate#cm.Vendor'),
  [TableColumns.User]: translate('translate#cm.AssignedCashier'),
}
