import { Theme } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { isIE } from 'react-device-detect'
import styled from 'styled-components'

import {
  ADM_BLOCK_PREFIX,
  ATMEYE_BLOCK_PREFIX,
  DICTIONARY_BLOCK_PREFIX,
  PRODUCTS_BLOCK_PREFIX,
  SRM_BLOCK_PREFIX,
} from '@/constants/paths'
import colors from '@/theme/colors'

const notificationDrawer = 288

interface DynamicProps {
  [key: string]: DynamicProps | string | number | undefined
}

interface Props {
  toggleSplitePane: boolean
  pathname: string
}

export const useClasses = makeStyles<Theme, Props>(
  ({ palette, zIndex, transitions, spacing, mixins, shadows }) => ({
    root: {
      display: 'flex',
      height: '100%',
    },
    menuItemRecordingLogDot: {
      position: 'absolute',
      top: '4px',
      width: '6px',
      height: '6px',
      background: 'red',
      borderRadius: '50%',
    },
    appBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'white',
      color: 'black',
      boxShadow: 'none',
      width: `calc(100% - ${spacing(9) + 1}px)`,
      borderBottom: `1px solid ${palette.grey[100]}`,
      zIndex: zIndex.drawer + 1,

      transition: isIE
        ? 'width, margin 0.2s ease-in'
        : transitions.create(['width', 'margin'], {
            easing: transitions.easing.sharp,
            duration: transitions.duration.leavingScreen,
          }),
    },
    appBarShift: ({ pathname }: Props) => {
      const drawerWidth = pathname.includes(ATMEYE_BLOCK_PREFIX) ? 300 : 266
      return {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,

        transition: isIE
          ? 'width 0.2s ease-in'
          : transitions.create(['width', 'margin'], {
              easing: transitions.easing.sharp,
              duration: transitions.duration.enteringScreen,
            }),
      }
    },
    modulesListAppBar: {
      width: '100%',
    },
    hide: {
      display: 'none',
    },
    disabledButton: {
      pointerEvents: 'none',
      filter: 'grayscale(100%)',
      opacity: 0.5,
    },
    drawerWrapper: {
      overflow: 'visible',
    },
    drawer: ({ pathname }: Props) => {
      const drawerWidth = pathname.includes(ATMEYE_BLOCK_PREFIX) ? 300 : 266
      return {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
      }
    },
    drawerFixed: {
      position: 'fixed',
      zIndex: zIndex.drawer,
    },
    drawerPaper: {
      background: palette.secondary.main,
      overflowX: 'hidden',
    },
    CMDrawerTitlePaper: {
      backgroundColor: '#f8f8f8 !important',
    },
    CMDrawerPaper: {
      background: colors.backgroundMenu,
      border: 'none',
    },
    USMDrawerPaper: {
      background: '#3154AD',
      border: 'none',
    },
    SDDrawerPaper: {
      background: '#ff9737',
      border: 'none',
    },
    SRMDrawerPaper: {
      background: '#7a7a7a',
      border: 'none',
    },
    dictionaryDrawerPaper: {
      background: '#FF9737',
      border: 'none',
    },
    dictionaryListItem: {
      padding: spacing(0.5, 1),
      display: 'flex',
      justifyContent: 'center',
    },
    dictionaryMenuItemIconWrapper: {
      marginLeft: '0 !important',
      display: 'flex',
      justifyContent: 'center',
    },
    atmeyeDrawerTitlePaper: {
      backgroundColor: '#f8f8f8 !important',
    },
    atmeyeDrawerPaper: {
      background: '#00C562',
      border: 'none',
    },
    companiesDrawerTitlePaper: {
      backgroundColor: '#f8f8f8 !important',
    },
    companiesDrawerPaper: {
      background: '#25ADBF',
      border: 'none',
    },
    productsDrawerTitlePaper: {
      backgroundColor: '#f8f8f8 !important',
    },
    productsDrawerPaper: {
      background: '#3154ad',
      border: 'none',
    },
    drawerOpen: ({ pathname }: Props) => {
      const drawerWidth = pathname.includes(ATMEYE_BLOCK_PREFIX) ? 300 : 266
      return {
        width: drawerWidth,

        transition: isIE
          ? 'all 0.2s ease-in-out'
          : transitions.create('width', {
              easing: transitions.easing.sharp,
              duration: transitions.duration.enteringScreen,
            }),
      }
    },
    drawerClose: {
      transition: isIE
        ? 'width 0.1s liner'
        : transitions.create('width', {
            easing: transitions.easing.sharp,
            duration: transitions.duration.leavingScreen,
          }),

      width: spacing(9) + 1,
    },
    logoContainer: {
      width: '100%',
      height: '100%',
      padding: 0,
      display: 'grid',
      justifyContent: 'center',
      gridTemplateColumns: 'max-content max-content',
      alignItems: 'center',
      color: 'black',
      gridColumnGap: spacing(1),
    },
    cashManagementLogoWrapper: {
      width: '100%',
      height: '100%',
      padding: 0,
      justifyContent: 'center',
      alignItems: 'center',
      color: 'black',
      gridColumnGap: spacing(1),
    },
    cashManagementLogo: {
      width: 'auto',
      height: '100%',
      maxHeight: '64px',
      padding: '0 8px',
      objectFit: 'contain',
    },
    cashManagementTitle: {
      marginRight: '22px',
      textAlign: 'center',
    },
    dictionaryAppBar: {
      width: '100% !important',
    },
    dictionaryLogoWrapper: {
      height: 68,
      display: 'flex',
      alignItems: 'center',
      padding: spacing(0, 8.25),
      borderBottom: '2px solid #FF9737',
    },
    vaultLogoWrapper: {
      width: '100%',
      height: '100%',
      padding: 0,
      display: 'grid',
      justifyContent: 'center',
      gridTemplateColumns: 'max-content max-content',
      alignItems: 'center',
      color: 'black',
      gridColumnGap: spacing(1),
    },
    atmeyeLogoWrapper: {
      width: '100%',
      height: '100%',
      padding: 0,
      display: 'grid',
      justifyContent: 'center',
      gridTemplateColumns: 'max-content max-content',
      alignItems: 'center',
      color: 'black',
      gridColumnGap: spacing(1),
    },
    companiesLogoWrapper: {
      width: '100%',
      height: '100%',
      padding: 0,
      display: 'grid',
      justifyContent: 'center',
      gridTemplateColumns: 'max-content max-content',
      alignItems: 'center',
      color: 'black',
      gridColumnGap: spacing(1),
    },
    productsLogoWrapper: {
      width: '100%',
      height: '100%',
      padding: 0,
      display: 'grid',
      justifyContent: 'center',
      gridTemplateColumns: 'max-content max-content',
      alignItems: 'center',
      color: 'black',
      gridColumnGap: spacing(1),
    },

    vaultV1LogoWrapper: {
      width: '100%',
      height: '100%',
      padding: 0,
      display: 'grid',
      justifyContent: 'center',
      gridTemplateColumns: 'max-content max-content',
      alignItems: 'center',
      color: 'black',
      gridColumnGap: spacing(1),
    },
    usmLogoWrapper: {
      width: '100%',
      height: '100%',
      padding: 0,
      display: 'grid',
      justifyContent: 'center',
      gridTemplateColumns: 'max-content max-content',
      alignItems: 'center',
      color: 'black',
      gridColumnGap: spacing(1),
    },
    admLogoWrapper: {
      width: '100%',
      height: '100%',
      padding: 0,
      display: 'grid',
      justifyContent: 'center',
      gridTemplateColumns: 'max-content max-content',
      alignItems: 'center',
      color: 'black',
      gridColumnGap: spacing(1),
    },
    sdLogoWrapper: {
      width: '100%',
      height: '100%',
      padding: 0,
      display: 'grid',
      justifyContent: 'center',
      gridTemplateColumns: 'max-content max-content',
      alignItems: 'center',
      color: 'black',
      gridColumnGap: spacing(1),
    },
    noMargin: {
      margin: 0,
    },
    noPadding: {
      padding: '0 !important',
    },
    headerActionsInRow: {
      display: 'flex',
    },
    buttonWithMargin: {
      marginLeft: spacing(1.25),
    },
    headerActionsButton: {
      width: spacing(4.25),
      height: spacing(4.25),
    },
    activeSplitButton: {
      backgroundColor: `${alpha(palette.primary.main, 0.3)} !important`,
    },
    vaultPaddingHeaderItemWrapper: {
      padding: `0 ${spacing(2)}px 0 0 !important`,
    },
    atmeyePaddingHeaderItemWrapper: {
      padding: `0 ${spacing(2)}px 0 0 !important`,
    },
    drawerAnchor: {
      flexShrink: 0,
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      width: spacing(9) + 1,
      zIndex: -1,
    },
    notificationDrawerPaper: {
      height: `calc(100% - ${spacing(8)}px)`,
      top: spacing(8),
      right: 0,
      boxShadow: shadows[3],
      display: 'flex',
      flexDirection: 'column',
    },
    notificationDrawerOpen: {
      width: notificationDrawer,
      transition: transitions.create('width', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
    notificationDrawerClose: {
      transition: transitions.create('width', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
      width: 0,
    },
    list: {
      minWidth: 287,

      '& div:nth-child(n+2)::before': {
        width: 0,
      },
    },
    admIconWrapper: {
      marginLeft: '-2px !important',
      display: 'flex',
      justifyContent: 'center',
    },
    admIcon: {
      height: 18,
    },
    drawerList: {
      padding: spacing(1, 2),
    },
    tabBarShift: ({ pathname }: Props) => {
      const drawerWidth = pathname.includes(ATMEYE_BLOCK_PREFIX) ? 300 : 266
      return {
        marginLeft: drawerWidth - (spacing(9) + 1),
        width: `calc(100% - ${drawerWidth - (spacing(9) + 1)}px)`,
      }
    },
    tabBar: {
      transition: isIE
        ? 'all 0.5s ease-in-out'
        : transitions.create(['width', 'margin'], {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen,
          }),
    },
    toolbar: ({ pathname }: Props) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: spacing(0, 1),
      height: 64,
      ...mixins.toolbar,
    }),
    headerToolbar: {
      flex: 1,
      paddingLeft: '40px',

      '@media (min-width:1120px)': {
        paddingLeft: '0 !important',
      },
    },
    userBarWrapper: {
      '& > *': {
        padding: spacing(2),

        '@media (min-width:1120px)': {
          padding: '4px',
        },
      },
    },
    moduleIconWrapper: {
      padding: 0,
      width: spacing(15),
      display: 'flex',
      alignItems: 'center',
    },
    drawerToolbar: props => ({
      color: 'white',
      backgroundColor: props.pathname.includes(ADM_BLOCK_PREFIX)
        ? palette.secondary.light
        : colors.backgroundHeaderMenu,
      justifyContent: 'space-between',
      borderBottom: 'none',
    }),
    logoWrapper: ({ pathname }: Props) => ({
      width: '100%',
      height: pathname.includes(DICTIONARY_BLOCK_PREFIX) ? 68 : 64,
      flex: isIE ? 'auto' : 1,
      transition: '0.2s',
    }),
    logoWrapperHide: {
      transform: isIE ? 'translateX(-400%)' : 'translateX(-200%)',
      transition: 'width 0.2s ease-in',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: '100%',
      overflow: 'auto',
    },
    menuItem: {
      textDecoration: 'none',
      color: 'white',
      backgroundColor: 'transparent',
      borderRadius: '4px',
    },
    menuItemTitle: {
      fontSize: '14px',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    menuItemBadge: {
      fontSize: '14px',
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    menuSubItemTitle: {
      fontSize: '14px',
    },
    menuSoleItem: {
      // minWidth: '48px',
      '&:hover': {
        background: 'rgba(255,255,255, 0.1)',
      },
    },
    menuSoleItemActive: {
      background: 'rgba(255,255,255, 0.1)',
    },
    menuSubItem: {
      color: '#ffffffaa',
      paddingLeft: spacing(7),
      '&:hover': {
        background: 'rgba(255,255,255, 0.1)',
      },
    },
    listItemIcon: {
      minWidth: 'auto',
    },
    menuItemActive: {
      color: 'white',
    },
    menuSubItemActive: {
      color: 'white',
      background: 'rgba(255,255,255, 0.1)',
    },
    menuItemIconWrapper: {
      paddingLeft: spacing(1.25),
      minWidth: spacing(6),
      // increasing priority to override default styles
      '&:nth-of-type(n)': {
        color: 'inherit',
      },
    },
    menuItemIconAnchor: {
      '&:nth-of-type(n)': {
        color: 'transparent',
      },
    },
    draggingItem: {
      color: isIE ? 'black' : 'white',
      mixBlendMode: 'difference',
    },
    buttonAnchor: {
      visibility: 'hidden',
    },
    contentWrapper: {
      display: 'flex',
      flex: 1,
      backgroundColor: palette.background.paper,
      overflow: 'auto',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'auto',
    },
    drawerToggleWrapper: {
      position: 'absolute',
      left: 0,
      transform: 'translateX(-50%)translateY(-50%)',
      top: '50%',
      zIndex: zIndex.drawer + 50,
    },
    foldingButton: {
      position: 'absolute',
      right: `-${spacing(3)}px`,
      zIndex: 100,
      borderRadius: '50%',
    },
    drawerToggle: {
      color: 'white',
      transition: '0.2s',
    },
    toggleIcon: ({ pathname }: Props) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: spacing(0.25),
      backgroundColor: pathname.includes(DICTIONARY_BLOCK_PREFIX)
        ? '#25ADBF'
        : palette.primary.main,
      borderRadius: '50%',
    }),
    foldedList: {
      transform: 'scaleX(-1)',
    },
    subItemsIcon: {
      fontSize: `${spacing(2)}px!important`,
      transition: '0.2s!important',
      position: 'absolute',
      top: '50%',
      right: `0px`,
      transform: 'translateY(-50%)rotate(-90deg)',
    },
    rotateSubItemsIcon: {
      transform: 'translateY(-50%)rotate(0deg)',
    },
    subItemsIconHidden: {
      visibility: 'hidden',
    },
    subItemsIconRemoved: {
      marginRight: '0!important',
    },
    button: (props): DynamicProps => ({
      width: 34,
      height: 34,
      marginRight: 10,
      borderColor: alpha(palette.primary.main, 0.3),
      background: props.toggleSplitePane ? alpha(palette.primary.main, 0.3) : '#fff',
      '&:hover': {
        filter: 'none',
        borderColor: alpha(palette.primary.main, 0.1),
        backgroundColor: alpha(palette.primary.main, 0.3),
      },
    }),
    badgesCounter: {
      color: '#fefefe',
      display: 'inline-block',
      background: '#E45B4A',
      borderRadius: '50%',
      lineHeight: '25px',
      width: '25px',
      textAlign: 'center',
      fontSize: '10px',
    },
    badgeElement: {
      right: -16,
      top: 4,
      padding: '3px 7px',
      color: 'white',
      backgroundColor: '#F16452',
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '14px',
    },
  }),
)

export const ModuleIcon = styled.img`
  height: 100%;
  max-width: 100%;
  max-height: 100%;
`
