import { TableRow } from '@material-ui/core'
import clsx from 'clsx'
import React, { FC, Fragment, useContext, useMemo } from 'react'

import { getNestedId } from '@/helpers/cm/getNestedId'

import { RowsContext } from '../../context'
import { useGroupData, useVirtualizeTable } from '../../hooks'
import { CMTableCell } from '../commonStyles'
import { CMTableRow, GroupNameRow } from './components'
import { Props } from './types'

export const CMTableRows: FC<Props> = React.memo((props: Props) => {
  const { data, rowColSpan } = props

  const {
    rowId,
    tableRef,
    typeKey,
    isAllRowsOpened,
    fitTableContent,
    selectedRowId,
    keys,
    checkedRowsKeys,
    onRowClick,
    lastRowRender,
    collapseCondition,
    getTranslateGroupName,
    ...rest
  } = useContext(RowsContext)

  const total = useMemo(() => data.length, [data.length])
  const ids = useMemo(() => data.map(item => (rowId ? item[rowId] : item.id)), [data, rowId])

  const { dataByType, defaultKey } = useGroupData({ data, typeKey })
  const visibleRows = useVirtualizeTable({ ids: ids as string[], tableRef, total })

  const renderLastEmptyCell = useMemo(
    () =>
      fitTableContent ? (
        <CMTableCell
          size="small"
          className={clsx(rest.tableClasses?.cell, rest.tableClasses?.lastEmptyCell)}
        />
      ) : null,
    [rest.tableClasses?.cell, fitTableContent],
  )

  const renderFinishRow = useMemo(
    () =>
      lastRowRender ? (
        <>
          <TableRow>
            {keys.map((key: string, index: number) => (
              <Fragment key={`${rowId}-${key}-${index}`}>{lastRowRender(key)}</Fragment>
            ))}
            {renderLastEmptyCell}
          </TableRow>
        </>
      ) : null,
    [keys, lastRowRender, renderLastEmptyCell],
  )

  return (
    <>
      {Object.keys(dataByType).map((type, index) => (
        <Fragment key={`${type}-${index}`}>
          {type.toString() !== defaultKey && (
            <GroupNameRow
              getTranslateGroupName={getTranslateGroupName}
              type={type}
              fitTableContent={fitTableContent}
              rowColSpan={rowColSpan}
            />
          )}
          {dataByType[type].map((item, indexByType) => {
            const itemId = ((rowId ? getNestedId(item, rowId) : item.id) as
              | string
              | number)?.toString()
            const isSelected =
              !!onRowClick && selectedRowId?.toString() === itemId && itemId !== undefined
            const isChecked = checkedRowsKeys.includes(itemId)

            return (
              <CMTableRow
                {...rest}
                key={`${type}-${indexByType}`}
                itemId={itemId}
                isChecked={isChecked}
                isVisible={visibleRows[itemId]}
                isSelected={isSelected}
                total={total}
                item={item}
                onRowClick={onRowClick}
                collapseCondition={collapseCondition}
                fitTableContent={fitTableContent}
                keys={keys}
                rowIndex={indexByType}
                isAllRowsOpened={
                  collapseCondition && collapseCondition(item) ? isAllRowsOpened : false
                }
              />
            )
          })}
          {renderFinishRow}
        </Fragment>
      ))}
    </>
  )
})
