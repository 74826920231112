export enum MessageType {
  LoginRequest = 'LoginRequest',

  TakeSorterRequest = 'TakeSorterRequest',
  ReleaseSorterRequest = 'ReleaseSorterRequest',

  GetSorterStatusRequest = 'GetSorterStatusRequest',
  SorterStatusResponse = 'SorterStatusResponse',

  GetAvailableSortersRequest = 'GetAvailableSortersRequest',
  // GetAvailableSortersResponse = 'GetAvailableSortersResponse',
  UpdateAvailableSortersNotification = 'UpdateAvailableSortersNotification',

  AckResponse = 'AckResponse',
  CountDataNotification = 'CountDataNotification',
}

export type MessageProps = {
  typeKey: MessageType
  payload?: string
}

export interface Message {
  MessageId: string
  OriginalMessageId: string | null
  Type: number
  Payload: string | any
}
export interface ResponseMessage {
  MessageId: string
  OriginalMessageId: string
  Type: number
  Payload: string | any
}

export interface CMSocketProps {
  socket: WebSocket | null
  isConnected: boolean
  close?: (code?: number, reason?: string) => void
}
