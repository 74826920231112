import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, putResolve, select, takeLatest } from 'redux-saga/effects'

import { CurrenciesAdminApi } from '@/api/vault-v1/adminApi'
import { AdmCurrency } from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants'

import {
  addAdmCurrencyFail,
  addAdmCurrencyResponse,
  deleteAdmCurrencyFail,
  getAdmCurrenciesFail,
  getAdmCurrenciesRequest,
  getAdmCurrenciesResponse,
  getVaultsAdmCurrenciesSort,
  getVaultsAdmSelectedCurrency,
  setAdmCurrencyBaseFail,
  setAdmCurrencyBaseResponse,
  setAdmCurrencySelected,
  updateAdmCurrencyFail,
  updateAdmCurrencyResponse,
} from '.'

// [START] Currencies --------------------------------------
function* getCurrencies(): SagaIterator {
  try {
    const sort = yield select(getVaultsAdmCurrenciesSort)
    const selected = yield select(getVaultsAdmSelectedCurrency)

    const currencies: AdmCurrency[] = yield call(CurrenciesAdminApi.getAll, sort)

    yield put(getAdmCurrenciesResponse(currencies))

    if (currencies && selected) {
      const updatedCurrency = currencies.find(
        (currency: AdmCurrency) => currency.id === selected.id,
      )

      if (updatedCurrency) {
        yield putResolve(setAdmCurrencySelected(updatedCurrency))
      } else {
        yield putResolve(setAdmCurrencySelected(null))
      }
    }
  } catch (error) {
    yield put(getAdmCurrenciesFail())
  }
}

function* addCurrency({ payload }: AnyAction): SagaIterator {
  try {
    yield call(CurrenciesAdminApi.add, payload)

    yield put(addAdmCurrencyResponse())
    yield put(getAdmCurrenciesRequest())
  } catch (error) {
    yield put(addAdmCurrencyFail())
  }
}

function* updateCurrency({ payload }: AnyAction): SagaIterator {
  try {
    yield call(CurrenciesAdminApi.update, payload)

    yield put(updateAdmCurrencyResponse())
    yield put(getAdmCurrenciesRequest())
  } catch (error) {
    yield put(updateAdmCurrencyFail())
  }
}

function* deleteCurrency(): SagaIterator {
  try {
    const selected = yield select(getVaultsAdmSelectedCurrency)

    yield call(CurrenciesAdminApi.delete, selected.id)

    yield put(getAdmCurrenciesRequest())
  } catch (error) {
    yield put(deleteAdmCurrencyFail())
  }
}

function* setBaseCurrency(): SagaIterator {
  try {
    const selected = yield select(getVaultsAdmSelectedCurrency)

    yield call(CurrenciesAdminApi.setBase, selected.id)

    yield put(setAdmCurrencyBaseResponse())
    yield put(getAdmCurrenciesRequest())
  } catch (error) {
    yield put(setAdmCurrencyBaseFail())
  }
}
// [END] Currencies --------------------------------------

export default function*(): Generator {
  yield takeLatest(
    [VaultV1Actions.AdmGetCurrenciesRequest, VaultV1Actions.AdmSortCurrencies],
    getCurrencies,
  )

  yield takeLatest(VaultV1Actions.AdmAddCurrencyRequest, addCurrency)
  yield takeLatest(VaultV1Actions.AdmUpdateCurrencyRequest, updateCurrency)
  yield takeLatest(VaultV1Actions.AdmDeleteCurrencyRequest, deleteCurrency)

  yield takeLatest(VaultV1Actions.AdmSetCurrencyBaseRequest, setBaseCurrency)
}
