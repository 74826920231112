import { useCallback, useState } from 'react'

import { StrategySessionStorage } from '@/components/NPMPakage/components/tableComponents/UITable/strategy'
import { LocalStorageItems } from '@/constants'
import { QueryKey } from '@/constants/queryKey'
import { Pagination } from '@/types'

const useUITablePagination = (queryKey: QueryKey | LocalStorageItems) => {
  const pageSizeLS = StrategySessionStorage.getData(`@LocalStorage/pageSize/${queryKey}`)

  const [paginationData, setPaginationData] = useState<Pagination>({
    pageSize: pageSizeLS || 50,
    pageNumber: 1,
    totalElements: 1,
    totalPages: 1,
  })

  const changePage = useCallback((pageNumber: number) => {
    setPaginationData(pagination => ({ ...pagination, pageNumber }))
  }, [])

  const changeSize = useCallback(
    (pageSize: number) => {
      setPaginationData(pagination => ({ ...pagination, pageSize, pageNumber: 1 }))
      StrategySessionStorage.setData(`@LocalStorage/pageSize/${queryKey}`, pageSize)
    },
    [queryKey],
  )

  return { paginationData, setPaginationData, changeSize, changePage }
}
export { useUITablePagination }
