import { FormikValues } from 'formik'
import { AnyAction } from 'redux'

import { Device, Terminal } from '@/api/common/deviceApi/types'
import {
  CreatingPOSTicketFormConfig,
  IexistingMerchants,
  POSServiceFormParameter,
  SaveTicketData,
  SaveTicketResponse,
  TicketInfoFormData,
} from '@/api/sd/posApi/types'
import { AdvancedSearchFiltersConfig, Country, ServiceCompany } from '@/api/sd/ticketsApi/types'
import { POSServiceTypeItem } from '@/components/blocks/POSRequestForm/types'
import { AuthorizationActions, DrawerActionsActions } from '@/constants'
import { ModalsActions } from '@/constants/actions/modals'
import { City, TicketsConfigData, TicketsConfigResponse, Unit } from '@/types'

export interface State {
  createPosTicketsModalIsOpen: boolean
  documentInformationModalIsOpen: boolean
  units: Unit[]
  unitsAdvanced: Device[]
  terminals: Terminal[]
  unitAdvancedSearchFormConfig: TicketsConfigData
  createPOSTicketFormConfig: CreatingPOSTicketFormConfig
  serviceCompanies: ServiceCompany[]
  POSServiceTypeFormConfig: any
  POSMerchantFormConfig: any
  saveTicketResponse: SaveTicketResponse
  isSavingPOSTicket: boolean
  isSavedSuccessful: boolean
  editingPosTicketInfo: { [key: string]: string | number | null }
  ticketInfoFormData: Partial<TicketInfoFormData>
  serviceTypeItems: POSServiceTypeItem[]
  notSavedPOSServiceRequestEditIndex: number | null
  notSavedPOSServiceRequests: {
    formValues: FormikValues
    formParameters: POSServiceFormParameter
  }[]
  mccCode: string
  isAddingTicket: boolean
  isFetchingValues: boolean
  isFetchingPSRTicketDetails: boolean
  isLookingForUnits: boolean
  isLookingForServiceCompanies: boolean
  isLookingForUnitsAdvancedSearchConfig: boolean
  isLookingForCreatePOSTicketFormConfig: boolean
  isLookingForCreatePOSEditTicketFormConfig: boolean
  isLookingForCreatePOSTicketServiceTypeFormConfig: boolean
  isLookingForCreatePOSTicketNewMerchantFormConfig: boolean
  isAddingPOSServiceTypeAction: boolean
  isEditingPOSServiceTypeAction: boolean
  isGettingTerminalsByMerchantAndService: boolean
  isDeletingPosRequestItem: boolean
  isDeletingPosTicket: boolean
  isSendingPosTicket: boolean
  isTicketHasDeletedSuccessful: boolean
  isTicketHasSentSuccessful: boolean
  isTicketHasError: boolean
  emptyAdvancedSearchShowMessage: boolean
  existingMerchants: IexistingMerchants
  isLookingForExistingMerchants: boolean
  error: string | null
}

export const initialState: State = {
  createPosTicketsModalIsOpen: false,
  documentInformationModalIsOpen: false,
  units: [],
  terminals: [],
  unitAdvancedSearchFormConfig: {
    formUID: '',
    getConfigURL: '',
    getOutTreeURL: '',
    getOutDetailsURL: '',
    filter: [],
    groups: [],
    outputFields: [],
  },
  createPOSTicketFormConfig: {
    serviceCompanies: [],
    serviceTypes: [],
    merchants: [],
    initialParameters: {},
    grantedOperations: [],
  },
  saveTicketResponse: {
    grantedOperations: [],
    saveTicketData: {},
  },
  mccCode: '',
  isSavingPOSTicket: false,
  isSavedSuccessful: false,
  ticketInfoFormData: {},
  editingPosTicketInfo: {},
  serviceTypeItems: [],
  serviceCompanies: [],
  unitsAdvanced: [],
  notSavedPOSServiceRequestEditIndex: null,
  notSavedPOSServiceRequests: [],
  POSServiceTypeFormConfig: {},
  POSMerchantFormConfig: {},
  isAddingTicket: false,
  isLookingForUnits: false,
  isFetchingValues: false,
  isFetchingPSRTicketDetails: false,
  isLookingForServiceCompanies: false,
  isLookingForUnitsAdvancedSearchConfig: false,
  isLookingForCreatePOSTicketFormConfig: false,
  isLookingForCreatePOSEditTicketFormConfig: false,
  isLookingForCreatePOSTicketServiceTypeFormConfig: false,
  isLookingForCreatePOSTicketNewMerchantFormConfig: false,
  isAddingPOSServiceTypeAction: false,
  isEditingPOSServiceTypeAction: false,
  isDeletingPosRequestItem: false,
  isDeletingPosTicket: false,
  isSendingPosTicket: false,
  isTicketHasDeletedSuccessful: false,
  isTicketHasSentSuccessful: false,
  isTicketHasError: false,
  isGettingTerminalsByMerchantAndService: false,
  emptyAdvancedSearchShowMessage: false,
  existingMerchants: {} as IexistingMerchants,
  isLookingForExistingMerchants: false,
  error: null,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case ModalsActions.OpenCreatePOSTicketsModal:
      return {
        ...state,
        createPosTicketsModalIsOpen: true,
      }
    case ModalsActions.CloseCreatePOSTicketsModal:
      return {
        ...state,
        createPosTicketsModalIsOpen: false,
      }
    case ModalsActions.OpenDocumentInformationModal:
      return {
        ...state,
        documentInformationModalIsOpen: true,
      }
    case ModalsActions.CloseDocumentInformationModal:
      return {
        ...state,
        documentInformationModalIsOpen: false,
      }
    case DrawerActionsActions.FindUnitsRequest:
      return {
        ...state,
        isLookingForUnits: true,
      }
    case DrawerActionsActions.FindUnitsResponse:
      return {
        ...state,
        units: payload.units,
        isLookingForUnits: false,
      }
    case DrawerActionsActions.FindUnitsResponseFail:
      return {
        ...state,
        error: payload,
        isLookingForUnits: false,
      }
    case DrawerActionsActions.FindUnitsAdvancedRequest:
      return {
        ...state,
        isLookingForUnits: true,
      }
    case DrawerActionsActions.FindUnitsAdvancedResponse:
      return {
        ...state,
        unitsAdvanced: payload,
        isLookingForUnits: false,
        emptyAdvancedSearchShowMessage: !payload.length,
      }
    case DrawerActionsActions.FindUnitsAdvancedResponseFail:
      return {
        ...state,
        error: payload,
        isLookingForUnits: false,
      }

    case DrawerActionsActions.AddTicketRequest:
      return {
        ...state,
        isAddingTicket: true,
      }
    case DrawerActionsActions.AddTicketResponse:
      return {
        ...state,
        isAddingTicket: false,
      }
    case DrawerActionsActions.AddTicketResponseFail:
      return {
        ...state,
        isAddingTicket: false,
        error: payload,
      }
    case DrawerActionsActions.FindUnitsByMerchandAndServiceRequest:
      return {
        ...state,
        isGettingTerminalsByMerchantAndService: true,
        isLookingForUnits: true,
      }
    case DrawerActionsActions.FindUnitsByMerchandAndServiceResponse:
      return {
        ...state,
        terminals: payload,
        isGettingTerminalsByMerchantAndService: false,
        isLookingForUnits: false,
      }
    case DrawerActionsActions.FindUnitsByMerchandAndServiceResponseFail:
      return {
        ...state,
        isGettingTerminalsByMerchantAndService: false,
        isLookingForUnits: false,
        error: payload,
      }
    case DrawerActionsActions.GetServiceCompanyRequest:
      return {
        ...state,
        isLookingForServiceCompanies: true,
      }
    case DrawerActionsActions.GetServiceCompanyResponse:
      return {
        ...state,
        isLookingForServiceCompanies: false,
        serviceCompanies: payload,
      }
    case DrawerActionsActions.GetServiceCompanyeFail:
      return {
        ...state,
        isLookingForServiceCompanies: false,
        error: payload,
      }
    case DrawerActionsActions.GetAdvancedUnitSearchConfigurationRequest:
      return {
        ...state,
        isLookingForUnitsAdvancedSearchConfig: true,
      }
    case DrawerActionsActions.GetAdvancedUnitSearchConfigurationResponce:
      return {
        ...state,
        unitAdvancedSearchFormConfig: payload,
        isLookingForUnitsAdvancedSearchConfig: false,
      }
    case DrawerActionsActions.GetAdvancedUnitSearchConfigurationFail:
      return {
        ...state,
        error: payload,
        isLookingForUnitsAdvancedSearchConfig: false,
      }
    case DrawerActionsActions.GetCreatingPOSFormConfigRequest:
      return {
        ...state,
        editingPosTicketInfo: {},
        saveTicketResponse: initialState.saveTicketResponse,
        serviceTypeItems: [],
        isLookingForCreatePOSTicketFormConfig: true,
      }
    case DrawerActionsActions.GetCreatingPOSFormConfigResponse:
      return {
        ...state,
        createPOSTicketFormConfig: payload,
        isLookingForCreatePOSTicketFormConfig: false,
      }
    case DrawerActionsActions.GetCreatingPOSFormConfigFail:
      return {
        ...state,
        error: payload,
        isLookingForCreatePOSTicketFormConfig: false,
      }
    case DrawerActionsActions.GetCreatingPOSEditFormConfigRequest:
      return {
        ...state,
        saveTicketResponse: initialState.saveTicketResponse,
        isLookingForCreatePOSEditTicketFormConfig: true,
      }
    case DrawerActionsActions.GetCreatingPOSEditFormConfigResponse:
      return {
        ...state,
        createPOSTicketFormConfig: {
          ...state.createPOSTicketFormConfig,
          serviceCompanies: payload.serviceCompanies,
          serviceTypes: payload.serviceTypes,
          merchants: payload.merchants,
        },
        editingPosTicketInfo: payload.ticketInfo,
        serviceTypeItems: payload.requestItems,
        isLookingForCreatePOSEditTicketFormConfig: false,
      }
    case DrawerActionsActions.GetCreatingPOSEditFormConfigFail:
      return {
        ...state,
        error: payload,
        isLookingForCreatePOSEditTicketFormConfig: false,
      }
    case DrawerActionsActions.ClearPOSEditFormConfig:
      return {
        ...state,
        editingPosTicketInfo: initialState.editingPosTicketInfo,
        ticketInfoFormData: initialState.ticketInfoFormData,
      }
    case DrawerActionsActions.AddPOSServiceTypeActionRequest:
      return {
        ...state,
        isAddingPOSServiceTypeAction: true,
      }
    case DrawerActionsActions.AddPOSServiceTypeActionResponse:
      return {
        ...state,
        isAddingPOSServiceTypeAction: false,
      }
    case DrawerActionsActions.AddPOSServiceTypeActionFail:
      return {
        ...state,
        error: payload,
        isAddingPOSServiceTypeAction: false,
      }
    case DrawerActionsActions.EditPOSServiceTypeActionRequest:
      return {
        ...state,
        isAddingPOSServiceTypeAction: true,
      }
    case DrawerActionsActions.EditPOSServiceTypeActionResponse:
      return {
        ...state,
        isAddingPOSServiceTypeAction: false,
      }
    case DrawerActionsActions.EditPOSServiceTypeActionFail:
      return {
        ...state,
        error: payload,
        isAddingPOSServiceTypeAction: false,
      }
    case DrawerActionsActions.GetPOSServiceFormConfigRequest:
      return {
        ...state,
        isLookingForCreatePOSTicketServiceTypeFormConfig: true,
      }
    case DrawerActionsActions.GetPOSServiceFormConfigResponse:
      return {
        ...state,
        POSServiceTypeFormConfig: payload,
        isLookingForCreatePOSTicketServiceTypeFormConfig: false,
      }
    case DrawerActionsActions.GetPOSServiceFormConfigFail:
      return {
        ...state,
        error: payload,
        isLookingForCreatePOSTicketServiceTypeFormConfig: false,
      }
    case DrawerActionsActions.GetMerchantConfigRequest:
      return {
        ...state,
        isLookingForCreatePOSTicketNewMerchantFormConfig: true,
      }
    case DrawerActionsActions.GetMerchantConfigResponse:
      return {
        ...state,
        POSMerchantFormConfig: payload,
        isLookingForCreatePOSTicketNewMerchantFormConfig: false,
      }
    case DrawerActionsActions.GetMerchantConfigFail:
      return {
        ...state,
        error: payload,
        isLookingForCreatePOSTicketNewMerchantFormConfig: false,
      }
    case DrawerActionsActions.setInitialAdvancedUnits:
      return {
        ...state,
        unitsAdvanced: [],
        units: [],
        emptyAdvancedSearchShowMessage: false,
      }
    case DrawerActionsActions.setInitialServiceCompanies:
      return {
        ...state,
        serviceCompanies: [],
      }

    case DrawerActionsActions.SavePosTicketRequest:
      return {
        ...state,
        isSavedSuccessful: false,
        isTicketHasDeletedSuccessful: false,
        isSavingPOSTicket: true,
        error: null,
      }
    case DrawerActionsActions.SavePosTicketResponse:
      return {
        ...state,
        isSavingPOSTicket: false,
        saveTicketResponse: {
          grantedOperations: payload.grantedOperations,
          saveTicketData: {
            ...payload.saveTicketData,
            documentId: payload.saveTicketData.documentid,
          },
        },
      }
    case DrawerActionsActions.SetIsSavePOSTicketSuccessfull:
      return {
        ...state,
        isSavedSuccessful: payload,
      }
    case DrawerActionsActions.SavePosTicketFail:
      return {
        ...state,
        isSavingPOSTicket: false,
        error: payload,
      }
    case DrawerActionsActions.SavePosTicketSetInitial:
      return {
        ...state,
        saveTicketResponse: initialState.saveTicketResponse,
        isSavingPOSTicket: false,
        isSavedSuccessful: false,
        error: null,
      }
    case DrawerActionsActions.GetTicketInfoFormDataRequest:
      return {
        ...state,
        isFetchingPSRTicketDetails: true,
      }
    case DrawerActionsActions.GetTicketInfoFormDataResponse:
      return {
        ...state,
        isFetchingPSRTicketDetails: false,
        ticketInfoFormData: payload,
      }
    case DrawerActionsActions.GetTicketInfoFormDataFail:
      return {
        ...state,
        isFetchingPSRTicketDetails: false,
        isLookingForCreatePOSEditTicketFormConfig: false,
        error: payload,
      }
    case DrawerActionsActions.DeletePosRequestItemRequest:
      return {
        ...state,
        isDeletingPosRequestItem: true,
      }
    case DrawerActionsActions.DeletePosRequestItemResponse:
      return {
        ...state,
        isDeletingPosRequestItem: false,
      }
    case DrawerActionsActions.DeletePosRequestItemFail:
      return {
        ...state,
        isDeletingPosRequestItem: false,
        error: payload,
      }
    case DrawerActionsActions.EditPosTicketRequestRequest:
      return {
        ...state,
        isSavedSuccessful: false,
        isSavingPOSTicket: true,
        isTicketHasDeletedSuccessful: false,
        error: null,
      }
    case DrawerActionsActions.EditPosTicketRequestResponse:
      return {
        ...state,
        isSavedSuccessful: true,
        isSavingPOSTicket: false,
        saveTicketResponse: {
          grantedOperations: payload.grantedOperations,
          saveTicketData: {
            ...payload.saveTicketData,
            documentId: payload.saveTicketData.documentid,
          },
        },
      }
    case DrawerActionsActions.EditPosTicketRequestFail:
      return {
        ...state,
        isSavingPOSTicket: false,
        error: payload,
      }
    case DrawerActionsActions.DeletePosTicketRequest:
      return {
        ...state,
        isDeletingPosTicket: true,
        isTicketHasDeletedSuccessful: false,
        error: null,
      }
    case DrawerActionsActions.DeletePosTicketResponse:
      return {
        ...state,
        isDeletingPosTicket: false,
        isTicketHasDeletedSuccessful: true,
      }
    case DrawerActionsActions.DeletePosTicketFail:
      return {
        ...state,
        isDeletingPosTicket: false,
        isTicketHasDeletedSuccessful: false,
        error: payload,
      }
    case DrawerActionsActions.SetTicketId:
      return {
        ...state,
        saveTicketResponse: {
          ...state.saveTicketResponse,
          saveTicketData: { ...state.saveTicketResponse.saveTicketData, documentId: payload },
        },
      }
    case DrawerActionsActions.SetIsTicketHasDeletedSuccessful:
      return {
        ...state,
        isTicketHasDeletedSuccessful: payload,
      }
    case DrawerActionsActions.GetExistingMerchantsRequest:
      return {
        ...state,
        isLookingForExistingMerchants: true,
      }
    case DrawerActionsActions.GetExistingMerchantsResponse:
      return {
        ...state,
        isLookingForExistingMerchants: false,
        existingMerchants: payload,
      }
    case DrawerActionsActions.GetExistingMerchantsResponseFall:
      return {
        ...state,
        isLookingForExistingMerchants: false,
        error: payload,
      }
    case DrawerActionsActions.SetNotSavedPOSServiceRequestEditIndex:
      return {
        ...state,
        notSavedPOSServiceRequestEditIndex: payload,
      }
    case DrawerActionsActions.EditNotSavedPOSServiceRequestByIndex: {
      const updatedNotSavedPOSServiceRequests = state.notSavedPOSServiceRequests.map(
        (req: any, index: number) =>
          index !== payload.index
            ? req
            : { formValues: payload.formValues, formParameters: payload.formParameters },
      )
      return {
        ...state,
        notSavedPOSServiceRequests: updatedNotSavedPOSServiceRequests,
      }
    }
    case DrawerActionsActions.PutNotSavedPOSServiceRequest:
      return {
        ...state,
        notSavedPOSServiceRequests: [...state.notSavedPOSServiceRequests, payload],
      }
    case DrawerActionsActions.PutNotSavedPosServicesArray: {
      const incomingTerminals = payload.terminals.map((t: any) => ({
        formValues: t,
        formParameters: payload.formParameters,
      }))
      return {
        ...state,
        notSavedPOSServiceRequests: [...state.notSavedPOSServiceRequests, ...incomingTerminals],
      }
    }
    case DrawerActionsActions.ResetPOSServicesFromUI:
      return {
        ...state,
        notSavedPOSServiceRequests: payload,
      }
    case DrawerActionsActions.ClearNotSavedPOSServiceRequest:
      return {
        ...state,
        notSavedPOSServiceRequests: [],
      }
    case DrawerActionsActions.SendPosTicketRequestRequest:
      return {
        ...state,
        isSendingPosTicket: true,
        isTicketHasError: false,
        isTicketHasSentSuccessful: false,
      }
    case DrawerActionsActions.SendPosTicketRequestResponse:
      return {
        ...state,
        isSendingPosTicket: false,
        isTicketHasError: false,
        isTicketHasSentSuccessful: true,
      }
    case DrawerActionsActions.SendPosTicketRequestFail:
      return {
        ...state,
        isSendingPosTicket: false,
        isTicketHasError: true,
        isTicketHasSentSuccessful: false,
        error: payload,
      }
    case DrawerActionsActions.SetIsTicketSentSuccessfull:
      return {
        ...state,
        isTicketHasSentSuccessful: payload,
      }
    case DrawerActionsActions.SetIsTicketSentFailed:
      return {
        ...state,
        isTicketHasError: payload,
      }
    case DrawerActionsActions.SetMCCCodePostTerminalRequest:
      return {
        ...state,
        mccCode: payload,
      }
    default:
      return state
  }
}
