import { translate } from '@/utils/internalization'

export enum TableColumn {
  Tid = 'tid',
  ObjectType = 'objectType',
  Functions = 'functions',
  OrderStatus = 'status',
  TeamName = 'teamName',
  WorkOrder = 'workOrderName',
  Address = 'address',
}

export const headerConfig = {
  [TableColumn.Tid]: translate('translate#cm.Object'),
  [TableColumn.ObjectType]: translate('translate#cm.ObjectType'),
  [TableColumn.Functions]: translate('translate#cm.ObjectFunctionalType'),
  [TableColumn.OrderStatus]: translate('translate#cm.OrderStatus'),
  [TableColumn.TeamName]: translate('translate#cm.Team'),
  [TableColumn.WorkOrder]: translate('translate#cm.WorkOrder'),
  [TableColumn.Address]: translate('translate#cm.Address'),
}

export const columnsToSort = [
  TableColumn.Tid,
  TableColumn.ObjectType,
  TableColumn.Functions,
  TableColumn.OrderStatus,
  TableColumn.TeamName,
  TableColumn.WorkOrder,
  TableColumn.Address,
]
