import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  subtitle: {
    marginBottom: spacing(1),
  },
  field: {
    width: '100%',
  },
}))
