import { AnyAction } from 'redux'

import { AdmAutomatedOperation } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants/actions'

export type State = {
  data: AdmAutomatedOperation[]
  selectedOperation: AdmAutomatedOperation | null
  isLoading: boolean
  isLoaded: boolean
}

export const initialState: State = {
  data: [],
  selectedOperation: null,
  isLoaded: false,
  isLoading: false,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    // get
    case CMAppConfigActions.AdmGetAutoOperationsRequest:
    case CMAppConfigActions.AdmUpdateAutoOperationsRequest:
      return {
        ...state,

        isLoading: true,
      }

    case CMAppConfigActions.AdmGetAutoOperationsResponse:
      return {
        ...state,

        isLoading: false,
        isLoaded: true,
        data: payload,
      }
    case CMAppConfigActions.AdmGetAutoOperationsFail:
    case CMAppConfigActions.AdmUpdateAutoOperationsFail:
      return {
        ...state,

        isLoading: false,
        isLoaded: true,
      }
    default:
      return state
  }
}
