import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ palette, spacing }) => ({
  headerControls: {
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    background: 'white',
  },
  input: {
    marginRight: 14,
    width: 194,
  },
  button: {
    // width: 160,
    height: spacing(5),
  },
}))
