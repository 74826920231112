import { createSelector } from 'reselect'

import { SearchTerminalByMerchantAndServiceFields } from '@/components/wrappers/AppNavigation/components/DrawerActions/components/modals/fields/TerminalSearchTable/types'
import { AppState } from '@/store/reducers'

import * as fromDrawerActions from '../reducer'
import { initialState } from '../reducer'

export const getDrawerActionsState = (state: AppState): fromDrawerActions.State =>
  state.drawerActions || initialState

export const getTerminalSearchTable = createSelector(getDrawerActionsState, state =>
  state.terminals.map(({ deviceName, city, id, fullAddress, acquirerLuno }) => ({
    [SearchTerminalByMerchantAndServiceFields.AcquirerLuno]: acquirerLuno,
    [SearchTerminalByMerchantAndServiceFields.DeviceName]: deviceName,
    [SearchTerminalByMerchantAndServiceFields.Address]: fullAddress,
    [SearchTerminalByMerchantAndServiceFields.City]: city,
    [SearchTerminalByMerchantAndServiceFields.TerminalId]: `${id}`,
  })),
)
