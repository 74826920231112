import { Theme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

import colors from '@/theme/colors'

type Props = {
  display?: string
}
export const useClasses = makeStyles<Theme, Props>(({ spacing, palette }) => ({
  closeButton: {
    position: 'absolute',
    top: spacing(1),
    right: spacing(1),
    cursor: 'pointer',
    zIndex: 100,
  },
  input: {
    width: '100%',
    marginTop: spacing(1),
  },

  select: {
    marginTop: spacing(1),
  },
  textField: {
    marginTop: spacing(1),
  },

  button: {
    minWidth: spacing(15),
  },

  checkbox: {
    padding: spacing(0, 1, 0, 0),
  },

  checkboxLabel: {
    marginBottom: spacing(1.5),
  },

  subscriptionDate: {
    marginTop: spacing(1),
  },

  subTitle: {
    color: palette.grey[400],
  },
  container: ({ display = 'flex' }: Props) => ({
    display: display,
    flexDirection: 'column',
    padding: spacing(2, 0),
    overflow: 'hidden',
  }),
  clearButton: {
    marginRight: spacing(2),
  },
  fileButton: {
    display: 'flex',
    flexBasis: '50%',
    maxWidth: '50%',
    backgroundColor: 'white',
    padding: spacing(0, 2),
    margin: spacing(2, 0),
  },
  extension: {
    position: 'absolute',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  fileSize: {
    color: palette.grey[200],
  },
  addFileButton: {
    overflow: 'hidden',
  },
  fileInput: {
    position: 'absolute',
    opacity: 0,
    width: '100%',
    height: '100%',
    padding: '100%',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
  },
  closeIconButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  closeIcon: {
    fontSize: spacing(2),
  },
  form: {
    marginTop: spacing(2),
    paddingRight: spacing(3),
    paddingLeft: spacing(3),
    overflow: 'auto',
  },
  fields: {
    flex: 1,
    paddingRight: spacing(2),
    overflow: 'auto',
  },
  buttons: {
    marginTop: spacing(0),
  },

  cancelButton: {
    background: 'transparent',
    marginRight: spacing(1),
    color: colors.primary,
    border: '1px solid',
    borderColor: colors.primary,
    '&:hover': {
      borderColor: palette.grey[200],
      backgroundColor: colors.grey50,
    },
  },

  radioButtonPeriod: {
    paddingBottom: spacing(0.5),
    width: '100%',
  },

  optionIcon: {
    height: '30px',
    paddingRight: spacing(1),
  },

  wrapperDateBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginRight: spacing(2),

    '&:last-child': {
      marginRight: spacing(0),
    },
  },

  widthClass: {
    width: '100%',
    marginRight: spacing(0),
    marginTop: spacing(1),
  },

  lastDateField: {
    marginRight: spacing(0),
  },

  paddingRadioButton: {
    paddingRight: spacing(8),
    width: '20%',
  },

  chooseIntervalOrDate: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  radio: {
    color: palette.text.primary,
    paddingRight: spacing(2),
  },
  root: {
    color: colors.primary,
  },

  chooseIntervalOrDateField: {
    width: '100%',
    display: 'flex',
  },
  radioGroup: {
    justifyContent: 'flex-start!important',
  },
  radioGroupPadding: {
    paddingRight: spacing(5),
  },
  divider: {
    width: '100%',
    marginBottom: spacing(1),
  },
  wrapperTitle: {
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
  },

  loaderWrapper: {
    height: spacing(25),
    width: spacing(5),
    margin: 'auto',
  },
}))
