import colors from '@/theme/colors'
import { CellStatusColor } from '@/types'

export const getDocStatusColor = (docStatusId?: number | string): CellStatusColor => {
  let defaultDocStatusColors: CellStatusColor = {
    backgroundColor: 'inherit',
    iconColor: 'inherit',
    textColor: 'inherit',
  }

  switch (Number(docStatusId)) {
    // created
    case 2:
      defaultDocStatusColors = {
        backgroundColor: '#d9f7e9',
        iconColor: '#47D08F',
        textColor: colors.text,
      }
      break
    // prepared
    case 3:
      defaultDocStatusColors = {
        backgroundColor: '#fefaee',
        iconColor: '#F2C94C',
        textColor: colors.text,
      }
      break
    // running
    case 4:
      defaultDocStatusColors = {
        backgroundColor: '#eaf3ff',
        iconColor: '#2F80ED',
        textColor: colors.text,
      }
      break
    // finished
    case 5:
      defaultDocStatusColors = {
        backgroundColor: '#feefe7',
        iconColor: '#F2994A',
        textColor: colors.text,
      }
      break
    // submited
    case 20:
      defaultDocStatusColors = {
        backgroundColor: '#f5f8ee',
        iconColor: '#99BE54',
        textColor: colors.text,
      }
      break
    // suspended
    case 30:
      defaultDocStatusColors = {
        backgroundColor: '#ffe9ed',
        iconColor: '#EB5757',
        textColor: colors.text,
      }
      break
    default:
      break
  }

  return defaultDocStatusColors
}

export const getDeviceStateColor = (deviceStateId?: number | string): string => {
  let defaultColor = 'inherit'

  switch (Number(deviceStateId)) {
    // installed
    case 1:
      defaultColor = '#47D08F'
      break
    // not installed
    case 2:
      defaultColor = '#EB5757'
      break
    // pending
    case 3:
      defaultColor = '#F2C94C'
      break
    // closed
    case 4:
      break
    // out off
    case 5:
      break
    // sold
    case 6:
      break
    // inrepair
    case 7:
      break
    // unknown
    case 8:
      break
    default:
      break
  }

  return defaultColor
}
