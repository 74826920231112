import { createSelector } from 'reselect'
import { AppState } from '@/store/reducers'
import { State, initialState } from './reducer'

export const getDirectSelectScriptState = (state: AppState): State =>
  state.directSelectScript || initialState

export const getDirectSelectScript = createSelector(
  getDirectSelectScriptState,
  state => state.isOpenModal,
)
