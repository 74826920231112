import React, { ReactElement } from 'react'

export const getValueOfCell = (value: string | { value: string | ReactElement }): string => {
  if (!value) {
    return ''
  }

  if (value && typeof value !== 'string') {
    if (typeof value.value !== 'string' && value.value.props && value.value.props.value) {
      return value.value.props.value
    }
  }

  return typeof value !== 'object'
    ? value
    : typeof value.value === 'object'
    ? '' || ''
    : value.value
}
