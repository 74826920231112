import React, { FC, useMemo } from 'react'
import { Menu, MenuItem } from '@material-ui/core'
import { Props } from './types'

export const ContextMenuCM: FC<Props> = React.memo((props: Props) => {
  const { open, handleClose, position, options, ...rest } = props

  const anchorPosition = useMemo(() => {
    const { top, left } = position
    return left !== null && top !== null ? { top, left } : undefined
  }, [position])

  return (
    <Menu
      keepMounted
      open={open}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      {...rest}
    >
      {options?.map(({ content, onClick, value, disabled }) => (
        <MenuItem key={value} value={value} onClick={onClick} disabled={disabled}>
          {content}
        </MenuItem>
      ))}
    </Menu>
  )
})
