import React from 'react'

import { store } from '@/App'

export const SRMRoutes = React.lazy(async () => {
  // TODO: change sd to srm after create srm redux
  const {
    default: { reducer: srmReducerDrawerAction, saga: srmSagaDrawerAction },
  } = await import('@/store/sd/drawerActions')
  store.injectReducer('drawerActions', srmReducerDrawerAction)
  store.injectSaga('drawerActions', srmSagaDrawerAction)

  const {
    default: { reducer: srmFiscalRegistrationReducer, saga: srmFiscalRegistrationSaga },
  } = await import('@/store/srm/fiscalRegistration')
  store.injectReducer('srmFiscalRegistration', srmFiscalRegistrationReducer)
  store.injectSaga('srmFiscalRegistration', srmFiscalRegistrationSaga)

  const {
    default: { reducer: srmTicketsReducer, saga: srmTicketsSaga },
  } = await import('@/store/srm/tickets')
  store.injectReducer('srmTickets', srmTicketsReducer)
  store.injectSaga('srmTickets', srmTicketsSaga)

  const {
    default: { reducer: srmMapPageReducer, saga: srmMapPageSaga },
  } = await import('@/store/map')
  store.injectReducer('mapPage', srmMapPageReducer)
  store.injectSaga('mapPage', srmMapPageSaga)

  const {
    default: { reducer: srmWindowsEditReducer },
  } = await import('@/store/srm/windowsEditActions')
  store.injectReducer('windowsEditActions', srmWindowsEditReducer)

  const {
    default: { reducer: srmNotificationsReducer },
  } = await import('@/store/srm/notifications')
  store.injectReducer('notificationsActions', srmNotificationsReducer)

  const {
    default: { reducer: srmNotifyServiceRequestsReducer },
  } = await import('@/store/srm/notifyServiceRequestsEvent')
  store.injectReducer('notificationsServiceRequestEvent', srmNotifyServiceRequestsReducer)

  const {
    default: { reducer: srmDeviceDefectsReducer },
  } = await import('@/store/srm/deviceDefectsActions')
  store.injectReducer('deviceDefects', srmDeviceDefectsReducer)

  const {
    default: { reducer: srmPropertiesWindowReducer },
  } = await import('@/store/srm/propertiesWindowActions')
  store.injectReducer('propertiesWindow', srmPropertiesWindowReducer)

  const {
    default: { reducer: srmRepairNormsReducer },
  } = await import('@/store/srm/repairNorms')
  store.injectReducer('repairNorms', srmRepairNormsReducer)

  const {
    default: { reducer: srmLogReducer },
  } = await import('@/store/srm/logActions')
  store.injectReducer('logActions', srmLogReducer)

  const {
    default: { reducer: srmCriticTermsReducer },
  } = await import('@/store/srm/criticTerms')
  store.injectReducer('criticTerms', srmCriticTermsReducer)

  const {
    default: { reducer: srmAdditionalCostsReducer },
  } = await import('@/store/srm/additionalCosts')
  store.injectReducer('additionalCosts', srmAdditionalCostsReducer)

  const {
    default: { reducer: srmDirectSelectScriptReducer },
  } = await import('@/store/srm/directSelectScript')
  store.injectReducer('directSelectScript', srmDirectSelectScriptReducer)

  const {
    default: { reducer: srmImportDevicesReducer },
  } = await import('@/store/srm/importDevices')
  store.injectReducer('importDevices', srmImportDevicesReducer)

  const {
    default: { reducer: srmTicketsWindowTreeReducer },
  } = await import('@/store/srm/ticketsWindowTree')
  store.injectReducer('ticketsWindowTree', srmTicketsWindowTreeReducer)

  const {
    default: { reducer: srmCreateGroupOfSerivceRequestsReducer },
  } = await import('@/store/srm/createGroupOfServiceRequests')
  store.injectReducer('createGroupOfServiceRequests', srmCreateGroupOfSerivceRequestsReducer)

  const {
    default: { reducer: srmTablesReducer },
  } = await import('@/store/common/tables')
  store.injectReducer('tables', srmTablesReducer)

  const {
    default: { reducer: reducerCommonSearchForm, saga: sagaCommonSearchForm },
  } = await import('@/store/common/searchForm')

  store.injectReducer('commonSearchForm', reducerCommonSearchForm)
  store.injectSaga('commonSearchForm', sagaCommonSearchForm)

  return import('@/components/routers/srm')
})
