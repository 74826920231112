import { AnyAction } from 'redux'

import {
  ComCassetteConfig,
  ComCMReport,
  MonCashPoint,
  PlnAcceptRequest,
  PlnCalculateRequest,
  PlnCashPoint,
  PlnModifyCalculateRequest,
  PlnModifyCalculateResponse,
  PlnModifyRequest,
  PlnSettingsResponse,
  ShrPriorityRequest,
} from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants/actions/cmActions'

export const getPlnCashPointsRequest = (): AnyAction => ({
  type: CMActions.PlnGetCashPointsRequest,
})

export const getPlnCashPointsResponse = (payload: any): AnyAction => ({
  type: CMActions.PlnGetCashPointsResponse,
  payload,
})

export const getPlnCashPointsResponseFail = (payload: any): AnyAction => ({
  type: CMActions.PlnGetCashPointsResponseFail,
  payload,
})

export const setPlnFiltersModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.PlnSetFiltersModalOpen,
  payload: isOpen,
})

export const setPlnDocumentModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.PlnSetDocumentModalOpen,
  payload: isOpen,
})

export const setPlnModifyModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.PlnSetModifyModalOpen,
  payload: isOpen,
})

export const setPlnSettingsModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.PlnSetSettingsModalOpen,
  payload: isOpen,
})
export const setPlnCalculateModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.PlnSetCalculateModalOpen,
  payload: isOpen,
})

export const setPlnLoadingModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.PlnSetLoadingModalOpen,
  payload: isOpen,
})

export const filterSubmitRequest = (payload: any): AnyAction => ({
  type: CMActions.PlnFilterSubmitRequest,
  payload,
})

export const setPlnCashPointsFilter = (payload: any): AnyAction => ({
  type: CMActions.PlnSetCashPointsFilter,
  payload,
})

export const setPlnCashPointsPagingRequest = (payload: {
  page?: number
  pageSize?: number
}): AnyAction => ({
  type: CMActions.SetPlnCashPointsPagingRequest,
  payload,
})

export const setPlnCashPointsPagingResponse = (payload: {
  page?: number
  pageSize?: number
}): AnyAction => ({
  type: CMActions.SetPlnCashPointsPagingResponse,
  payload,
})

export const resetPlnCashPointsPage = (): AnyAction => ({
  type: CMActions.PlnResetCashPointsPage,
})

export const getPlnReportsRequest = (): AnyAction => ({
  type: CMActions.GetPlnReportsRequest,
})

export const getPlnReportsResponse = (payload: any): AnyAction => ({
  type: CMActions.GetPlnReportsResponse,
  payload,
})

export const getPlnReportsResponseFail = (message?: string): AnyAction => ({
  type: CMActions.GetPlnReportsResponseFail,
  payload: message,
})

export const getPlnReportRequest = (payload?: ComCMReport): AnyAction => ({
  type: CMActions.GetPlnReportRequest,
  payload,
})

export const getPlnReportResponse = (payload: any): AnyAction => ({
  type: CMActions.GetPlnReportResponse,
  payload,
})

export const getPlnReportResponseFail = (message?: string): AnyAction => ({
  type: CMActions.GetPlnReportResponseFail,
  payload: message,
})

export const getPlnConfigRequest = (guid?: string): AnyAction => ({
  type: CMActions.PlnGetConfigRequest,
  payload: guid,
})

export const getPlnConfigResponse = (payload: ComCassetteConfig): AnyAction => ({
  type: CMActions.PlnGetConfigResponse,
  payload,
})

export const getPlnConfigResponseFail = (error: string): AnyAction => ({
  type: CMActions.PlnGetConfigResponseFail,
  payload: error,
})

export const setPlnSelectedCashPoint = (cashPoint: PlnCashPoint | null): AnyAction => ({
  type: CMActions.PlnSetSelectedCashPoint,
  payload: cashPoint,
})

export const plnCalculateRequest = (payload: PlnCalculateRequest): AnyAction => ({
  type: CMActions.PlnCalculateRequest,
  payload,
})

export const plnAcceptRequest = (payload: PlnAcceptRequest): AnyAction => ({
  type: CMActions.PlnAcceptRequest,
  payload,
})

export const plnSetDataCountToUpdate = (count: number): AnyAction => ({
  type: CMActions.PlnSetDataCountToUpdate,
  payload: count,
})

export const plnSendToExecuteRequest = (payload: PlnAcceptRequest): AnyAction => ({
  type: CMActions.PlnSendToExecuteRequest,
  payload,
})

export const plnCalculateExtRequest = (payload: PlnModifyCalculateRequest): AnyAction => ({
  type: CMActions.PlnCalculateExtRequest,
  payload,
})

export const plnCalculateExtResponse = (payload?: PlnModifyCalculateResponse): AnyAction => ({
  type: CMActions.PlnCalculateExtResponse,
  payload,
})

export const refreshPlnCashPointRequest = (guid: string): AnyAction => ({
  type: CMActions.PlnRefreshCashPointRequest,
  payload: guid,
})

export const refreshPlnCashPointResponse = (payload: PlnCashPoint): AnyAction => ({
  type: CMActions.PlnRefreshCashPointResponse,
  payload,
})

export const refreshPlnCashPointResponseFail = (error: string): AnyAction => ({
  type: CMActions.PlnRefreshCashPointResponseFail,
  payload: error,
})

export const modifyPlnRequest = (payload: PlnModifyRequest): AnyAction => ({
  type: CMActions.PlnModifyRequest,
  payload,
})

export const sortPlnTable = (payload: {
  sortColumn: string | undefined
  sortOrder: 'asc' | 'desc' | undefined
}): AnyAction => ({
  type: CMActions.SortPlnTable,
  payload,
})

export const togglePlnCashPoint = (cashPoint: PlnCashPoint | MonCashPoint): AnyAction => ({
  type: CMActions.PlnToggleCashPoint,
  payload: cashPoint,
})

export const checkPlnAllCashPoints = (cashPoints: PlnCashPoint[] | MonCashPoint[]): AnyAction => ({
  type: CMActions.PlnCheckAllCashPoints,
  payload: cashPoints,
})

export const clearPlnAllCashPoints = (): AnyAction => ({
  type: CMActions.PlnClearAllCashPoints,
})

export const setPlnCashPointPriority = (priority: ShrPriorityRequest): AnyAction => ({
  type: CMActions.PlnSetCashPointPriority,
  payload: priority,
})

export const modifyPlnConfigRequest = (guid: string, config: ComCassetteConfig[]): AnyAction => ({
  type: CMActions.PlnModifyConfigRequest,
  payload: { guid, config },
})

export const modifyPlnConfigResponse = (): AnyAction => ({
  type: CMActions.PlnModifyConfigResponse,
})
export const modifyPlnConfigFail = (): AnyAction => ({
  type: CMActions.PlnModifyConfigFail,
})

export const refreshPlanning = (): AnyAction => ({
  type: CMActions.RefreshPlanning,
})

export const setPlnModifyApplyDisabled = (isDisabled: boolean): AnyAction => ({
  type: CMActions.PlnSetModifyApplyDisabled,
  payload: isDisabled,
})

export const getPlnModifyExpensesRequest = (payload: PlnModifyRequest): AnyAction => ({
  type: CMActions.PlnGetModifyExpensesRequest,
  payload,
})

export const getPlnModifyExpensesResponse = (payload: string): AnyAction => ({
  type: CMActions.PlnGetModifyExpensesResponse,
  payload,
})

export const getPlnModifyExpensesResponseFail = (error: string): AnyAction => ({
  type: CMActions.PlnGetModifyExpensesResponseFail,
  payload: error,
})

export const getPlnSettingsRequest = (): AnyAction => ({
  type: CMActions.PlnGetSettingsRequest,
})

export const getPlnSettingsResponse = (settings: PlnSettingsResponse): AnyAction => ({
  type: CMActions.PlnGetSettingsResponse,
  payload: settings,
})

export const getPlnSettingsResponseFail = (error: string): AnyAction => ({
  type: CMActions.PlnGetSettingsResponseFail,
  payload: error,
})

export const getPlnDeclineNoteRequest = ({
  sessionId,
  cashPointId,
}: {
  sessionId: string
  cashPointId: string
}): AnyAction => ({
  type: CMActions.PlnGetDeclineNoteRequest,
  payload: { sessionId, cashPointId },
})

// TODO: update swagger and set PlnDeclineNote
export const getPlnDeclineNoteResponse = (payload: any): AnyAction => ({
  type: CMActions.PlnGetDeclineNoteResponse,
  payload,
})

export const getPlnDeclineNoteResponseFail = (error: string): AnyAction => ({
  type: CMActions.PlnGetDeclineNoteResponseFail,
  payload: error,
})

export const setPlnDeclineNoteModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.PlnSetDeclineNoteModalOpen,
  payload: isOpen,
})

export const handlePlnKeyPress = (
  actionName:
    | 'TOGGLE_CHECKBOX'
    | 'NEXT_ROW'
    | 'PREV_ROW'
    | 'OPEN_FILTER'
    | 'OPEN_PRINT_DIALOG'
    | 'REFRESH',
): AnyAction => ({
  type: CMActions.PlnHandleKeyPress,
  payload: actionName,
})

export const setPlnModifyDisabledState = (payload: boolean): AnyAction => ({
  type: CMActions.PlnSetModifyDisabledState,
  payload,
})
