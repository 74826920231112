import { Grid } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'

import { DocumentInformationFields } from '@/components/blocks/PSRTicketDetails/components/DocumenInformationModal/components/DocumenInformationTable/types'
import AppTable from '@/components/controls/AppTable'
import { getRequestItems } from '@/store/sd/drawerActions/selectors'

import { useClasses } from './styles'

const RequestItemsTable = (): React.ReactElement => {
  const classes = useClasses()

  const requestItems = useSelector(getRequestItems)

  return (
    <>
      {requestItems.length ? (
        <Grid className={classes.tableWrapper} item md={12}>
          <AppTable
            columnDisplayOrder={[
              DocumentInformationFields.ItemNo,
              DocumentInformationFields.ShopBrandName,
              DocumentInformationFields.Status,
              DocumentInformationFields.Service,
              DocumentInformationFields.Priority,
              DocumentInformationFields.TerminalID,
              DocumentInformationFields.POSAddress,
              DocumentInformationFields.MerchantID,
              DocumentInformationFields.MCCCode,
              DocumentInformationFields.TerminalType,
              DocumentInformationFields.ConnectionType,
              DocumentInformationFields.ContactPerson,
              DocumentInformationFields.Phone,
              DocumentInformationFields.Notes,
            ]}
            isLoading={false}
            tableClasses={{
              paper: classes.tableWrap,
              headColumn: classes.headColumn,
              row: classes.row,
              root: classes.root,
            }}
            data={requestItems}
            stickyHeader
            prefix="PSR"
          />
        </Grid>
      ) : (
        <></>
      )}
    </>
  )
}

export default RequestItemsTable
