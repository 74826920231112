import moment from 'moment'
import { AnyAction } from 'redux'

import { AnlChartsFilter, AnlChartsResponse } from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions, VaultV1AnalysisActions } from '@/constants'
import { ChartsGroupType } from '@/types/cm/analysis'

export type State = {
  isFiltersModalOpen: boolean
  selectedChartsType: ChartsGroupType
  filter: AnlChartsFilter
  charts: AnlChartsResponse
  isChartsLoading: boolean
}

export const initialState: State = {
  isFiltersModalOpen: false,
  selectedChartsType: ChartsGroupType.RealTimeCharts,
  filter: {
    from: moment()
      .subtract(1, 'month')
      .startOf('day')
      .format(),
    until: moment()
      .add(1, 'month')
      .startOf('day')
      .format(),
    vault: undefined,
  },
  charts: {},
  isChartsLoading: false,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case VaultV1AnalysisActions.AnalysisSetSelectedChartsType:
      return {
        ...state,
        selectedChartsType: payload,
      }
    case VaultV1AnalysisActions.AnalysisGetChartsRequest:
      return {
        ...state,
        isChartsLoading: true,
      }
    case VaultV1AnalysisActions.AnalysisGetChartsFail:
      return {
        ...state,
        isChartsLoading: false,
      }
    case VaultV1AnalysisActions.AnalysisGetChartsResponse:
      return {
        ...state,
        charts: payload,
        isChartsLoading: false,
      }

    case VaultV1AnalysisActions.AnalysisSetFiltersModalOpen:
      return {
        ...state,
        isFiltersModalOpen: payload,
      }
    case VaultV1AnalysisActions.AnalysisSetFilter:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload,
        },
      }
    case VaultV1Actions.SignOut:
      return initialState

    default:
      return state
  }
}
