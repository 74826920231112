// import styled from 'styled-components'
import makeStyles from '@material-ui/core/styles/makeStyles'
import colors from '@/theme/colors'

export const useClasses = makeStyles(({ spacing, palette, breakpoints }) => ({
  background: {
    height: '100%',
    display: 'flex',
    backgroundColor: colors.backgroundLogin,
  },
  wrapper: {
    display: 'flex',
  },
  form: {
    width: breakpoints.values.sm - spacing(15),
    padding: spacing(3, 4),
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
}))
