import React, { SyntheticEvent, useCallback } from 'react'
import { ExpandLess } from '@material-ui/icons'
import { useClasses } from './styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ContextMenuBookmarks from '@/components/wrappers/AppNavigation/components/HeaderBookmarks/components/HideBookmarks/component'
import { ButtonBase } from '@material-ui/core'
import Test from 'react-test-attributes'
import { getTestId } from '@/utils'
import { IProps } from '@/components/wrappers/AppNavigation/components/HeaderBookmarks/components/types'

const ShowHideBookmarks = ({
  hideBookmarks,
  handleAddTab,
  handleRemoveBookmark,
}: IProps): React.ReactElement => {
  const [bookmarkEl, setBookmarkEl] = React.useState<null | (EventTarget & HTMLButtonElement)>(null)

  const handleClick = useCallback((event: SyntheticEvent<HTMLButtonElement>): void => {
    setBookmarkEl(event.currentTarget)
  }, [])

  const handleClose = useCallback((): void => {
    setBookmarkEl(null)
  }, [])

  const classes = useClasses()
  const testId = getTestId('hidden-bookmarks')

  return (
    <Test id={testId(0)}>
      <div>
        <Test id={testId(1)}>
          <ButtonBase className={classes.button} onClick={handleClick}>
            {!bookmarkEl ? (
              <ArrowForwardIosIcon fontSize="small" className={classes.icon} />
            ) : (
              <ExpandLess className={classes.icon} />
            )}
          </ButtonBase>
        </Test>
        <ContextMenuBookmarks
          bookmarkEl={bookmarkEl}
          handleClose={handleClose}
          hideBookmarks={hideBookmarks}
          handleAddTab={handleAddTab}
          handleRemoveBookmark={handleRemoveBookmark}
        />
      </div>
    </Test>
  )
}

export default ShowHideBookmarks
