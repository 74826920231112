import styled from 'styled-components'
import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(() => ({
  wzuupx: {
    marginLeft: '-14px',
  },
}))

export const MenuItemIcon = styled.img`
  height: 100%;
  max-width: 100%;
  max-height: 100%;
`
