import { Tab, Tabs, Tooltip, Typography } from '@material-ui/core'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { AtmeyeModules } from '@/api/atmeye/common/types'
import { Device } from '@/api/atmeye/devicesApi/types'
import RestartIcon from '@/assets/atmeye/icons/restart.svg'
import { ATMeyeBarChart } from '@/components/blocks/ATMeye/BarChart'
import Button from '@/components/controls/Button'
import { Restricted } from '@/components/NPMPakage/components/wrappers/PermissionProvider/Restricted'
import { Permission } from '@/components/NPMPakage/components/wrappers/types'
import { getAvailableModules } from '@/store/atmeye/common'
import { getUserPermissions } from '@/store/auth'
import { useTranslate } from '@/utils/internalization'

import PropertiesTab from '../PropertiesTab'
import { AlarmsTabPanel } from '../SecurityAlarmsTab/SecurityAlarmsTabPanel'
import StatusTab from '../StatusTab/component'
import { TechEventsTabPanel } from '../TechnicalEventsTab/TechnicalEventsTabPanel'
import TeltonikaTab from '../TeltonikaTab/component'
import { TransactionTabPanel } from '../TransactionsTab/TransactionTabPanel'
import { useClasses } from './styles'
import { TABS, tabsData } from './types'

type Props = {
  currentDevice: null | Device
}

const CurrentDeviceTabsPanel: FC<Props> = React.memo(
  ({ currentDevice }: Props): React.ReactElement => {
    const classes = useClasses()
    const translate = useTranslate()

    const [tab, setTab] = useState<TABS | undefined>()
    const [segmentFilters, setSegmentFilters] = useState<any>(null)
    const [isUpdateHistogram, setIsUpdateHistogram] = useState<boolean>(false)

    const permissions: Record<Permission, boolean> = useSelector(getUserPermissions)
    const availableModules = useSelector(getAvailableModules)

    useEffect(() => {
      const newTab = tabsData.find(item => permissions[item.permission])
      if (!availableModules?.[AtmeyeModules.CAMERAS]) {
        setTab(TABS.PROPERTIES)
      } else {
        setTab(newTab?.tabNumber)
      }
    }, [permissions, availableModules])

    return (
      <div className={classes.tabContentWrapper}>
        <div className={classes.tabBlock}>
          <Tabs className={classes.tabsWrap}>
            {tabsData.map(tabItem => (
              <Restricted key={tabItem.tabNumber} permissionKey={tabItem.permission}>
                <Tab
                  selected={tabItem.tabNumber === tab}
                  classes={{ selected: classes.selectedTab, root: classes.tab }}
                  label={translate(tabItem.label)}
                  onClick={() => setTab(tabItem.tabNumber)}
                />
              </Restricted>
            ))}
          </Tabs>

          <div className={classes.deviceNameWrap}>
            <Typography className={classes.deviceName}>
              {`${translate('translate#atmeye.title.device')} ${currentDevice?.deviceName}`}
            </Typography>

            {tab === TABS.HISTOGRAM && (
              <Tooltip
                placement="top"
                classes={{ tooltip: classes.toolTip }}
                title={translate('translate#atmeye.table.header.button.refresh')}
              >
                <Button
                  className={classes.refreshButton}
                  startIcon={<img src={RestartIcon} />}
                  onClick={() => setIsUpdateHistogram(true)}
                />
              </Tooltip>
            )}
          </div>
        </div>

        <div className={classes.wrapper}>
          {tab === TABS.TRANSACTIONS && (
            <TransactionTabPanel
              currentDevice={currentDevice}
              segmentFilters={segmentFilters?.transactions}
              setSegmentFilters={setSegmentFilters}
            />
          )}
          {tab === TABS.SECURITY_ALARMS && (
            <AlarmsTabPanel
              currentDevice={currentDevice}
              segmentFilters={segmentFilters?.alarms}
              setSegmentFilters={setSegmentFilters}
            />
          )}

          {tab === TABS.TECHNICAL_EVENTS && (
            <TechEventsTabPanel
              currentDevice={currentDevice}
              segmentFilters={segmentFilters?.events}
              setSegmentFilters={setSegmentFilters}
            />
          )}
          {tab === TABS.PROPERTIES && <PropertiesTab currentDevice={currentDevice} />}
          {tab === TABS.STATUS && <StatusTab currentDevice={currentDevice} />}
          {tab === TABS.HISTOGRAM && (
            <ATMeyeBarChart
              height="100%"
              currentDevice={currentDevice}
              setTab={setTab}
              setSegmentFilters={setSegmentFilters}
              isUpdateHistogram={isUpdateHistogram}
              setIsUpdateHistogram={setIsUpdateHistogram}
            />
          )}
          {tab === TABS.TELTONIKA && <TeltonikaTab currentDevice={currentDevice} />}
        </div>
      </div>
    )
  },
)

export { CurrentDeviceTabsPanel }
