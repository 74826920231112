import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Permission } from '@/components/NPMPakage/components/wrappers/types'
import { getUserPermissions } from '@/store/auth'

const usePermission = (permission?: Permission) => {
  const [loading, setLoading] = useState(true)
  const [allowed, setAllowed] = useState(false)

  const permissions: Record<Permission, boolean> = useSelector(getUserPermissions)

  useEffect(() => {
    if (permission && Object.keys(permissions).includes(permission)) {
      setAllowed(permissions[permission])
    }

    if (!permission) {
      setAllowed(true)
    }

    setLoading(false)
  }, [permission, permissions])

  return { loading, allowed } as const
}

export { usePermission }
