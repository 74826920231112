import queryString from 'query-string'

import { AdmCitSorter, AdmSortersFilter } from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class AdminCashSortersApi {
  private static requestService = getRequestServiceCM()

  public static async getAll({
    sortColumn,
    sortOrder,
    ...filter
  }: AdmSortersFilter & Sort): Promise<AdmCitSorter[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/sorters',
          query: { sortColumn, sortOrder },
        },
        { skipNull: true, encode: false },
      )

      return await AdminCashSortersApi.requestService.post(url, filter)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async get(id: string): Promise<AdmCitSorter> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/sorter',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await AdminCashSortersApi.requestService.get(url)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async add(payload: AdmCitSorter): Promise<void> {
    try {
      return await AdminCashSortersApi.requestService.post('/admin/sorters/add', payload)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async update(payload: AdmCitSorter): Promise<void> {
    try {
      return await AdminCashSortersApi.requestService.put('/admin/sorters/update', payload)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async delete(id: number): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/sorters/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await AdminCashSortersApi.requestService.delete(url)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }
}
