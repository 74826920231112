import { AnyAction } from 'redux'

import { AdmBank, AdmBankBase } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants/actions/cmActions'
import { Sort } from '@/types/cm'

// get
export const getBanksRequest = (): AnyAction => ({
  type: CMAppConfigActions.AdmGetBanksRequest,
})
export const getBanksResponse = (payload: AdmBank[]): AnyAction => ({
  type: CMAppConfigActions.AdmGetBanksResponse,
  payload: payload,
})
export const getBanksFail = (): AnyAction => ({
  type: CMAppConfigActions.AdmGetBanksFail,
})

// add
export const addNewBankRequest = (payload: AdmBankBase): AnyAction => ({
  type: CMAppConfigActions.AdmAddNewBankRequest,
  payload,
})
export const addNewBankFail = (): AnyAction => ({
  type: CMAppConfigActions.AdmAddNewBankFail,
})

// update
export const updateBankRequest = (payload: AdmBank): AnyAction => ({
  type: CMAppConfigActions.AdmUpdateBankRequest,
  payload,
})
export const updateBankFail = (): AnyAction => ({
  type: CMAppConfigActions.AdmUpdateBankFail,
})

// delete
export const deleteBankRequest = (payload?: string): AnyAction => ({
  type: CMAppConfigActions.AdmDeleteBankRequest,
  payload,
})
export const deleteBankFail = (): AnyAction => ({
  type: CMAppConfigActions.AdmDeleteBankFail,
})

// selected
export const setSelectedBank = (payload: AdmBank): AnyAction => ({
  type: CMAppConfigActions.AdmSetSelectedBank,
  payload,
})

// sort
export const sortBanks = (payload: Sort): AnyAction => ({
  type: CMAppConfigActions.AdmSortBanks,
  payload,
})
