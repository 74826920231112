import makeStyles from '@material-ui/core/styles/makeStyles'

import { styledComponentTheme } from '@/theme'

export const useClasses = makeStyles(({ spacing }) => ({
  contentItem: {
    display: 'flex',
    padding: spacing(0.5, 1),
    overflow: 'hidden',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    width: 'inherit',
  },
  contentItemTitle: {
    width: '50%',
    fontWeight: 'bold',
    fontSize: styledComponentTheme.fontSizes.small,
  },
  contentItemText: {
    width: '50%',
    fontSize: styledComponentTheme.fontSizes.small,

    overflow: 'hidden',
    display: 'inline',
  },

  contentItemTextWithTooltip: {
    display: 'block',
  },

  withTooltip: {
    overflow: 'hidden',

    fontSize: styledComponentTheme.fontSizes.small,
  },

  tooltip: {
    fontSize: styledComponentTheme.fontSizes.small,
    maxWidth: spacing(40),
    padding: spacing(1),
  },
}))
