import Paper from '@material-ui/core/Paper'
import MuiTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import MuiTableRow from '@material-ui/core/TableRow'
import clsx from 'clsx'
import { isEmpty } from 'lodash-es'
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import Loader from '@/components/blocks/Loader'
import {
  TableBodyCellContent,
  TableFilterDate,
  TableFilterTypes,
} from '@/components/controls/AppTable/types'
import TableHeader from '@/components/controls/TableComponents/TableHeader'
import TablePagination from '@/components/controls/TableComponents/TablePagination'
import { HeaderColumn } from '@/components/pages/sd/TicketsPage/types'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { getCurrentAccount } from '@/store/auth/selectors'
import { Order } from '@/types'
import { useScrollPosition } from '@/utils/hooks/hooks'
import { useTranslate } from '@/utils/internalization'
import { getTableSettings } from '@/utils/localStorage'

import TableFooter from './components/TableFooter'
import TableCustomRow from './components/TableRow'
import TableToolbar from './components/TableToolbar'
import { useClasses } from './styles'
import { Props } from './types'

const Table = ({
  tableId,
  columnsOrderTemplate,
  columnsOrder,
  columns,
  incomingHeaders,
  data,
  totalPage,
  page,
  rowsPerPage,
  ticketsTotalCount,
  filter,
  sorting,
  tableHeader,
  tableFooter,
  enablePaging,
  isLoading,
  setTableSettingsToLocalStorage,
  setColumnsOrderTemplate,
  setColumnsOrder,
  setColumns,
  changePage,
  changeRowsPerPage,
  handleTableParamsChange,
  onFilterChange,
  ticketClick,
  posTicketClick,
  idPrefix,
}: Props): ReactElement => {
  const translate = useTranslate()
  const account = useSelector(getCurrentAccount)

  const [filteredData, setFilteredData] = useState<
    Array<{
      item: { [key: string]: string | null }
      childItems: Array<{ [key: string]: string | null }>
    }>
  >([])
  const [headers, setHeaders] = useState<HeaderColumn[]>([])
  const [order, setOrder] = useState<Order>(undefined)
  const [orderBy, setOrderBy] = useState<string>('')
  const [filterColumns, setFilterColumns] = useState<{
    [key: string]: 'text' | TableFilterDate | 'minmax' | TableBodyCellContent[]
  }>({})
  const [position, setPosition] = useScrollPosition()
  const tableRef = useRef<HTMLTableElement>(null)

  const nesting = 0

  const classes = useClasses()

  const handleSetFilter = useCallback(
    (key: string, values: TableFilterTypes) => {
      onFilterChange({ ...filter, [key]: values })
    },
    [filter, onFilterChange],
  )

  const handleSetSorting = useCallback(
    (key: string, sort: 'asc' | 'desc' | null) => {
      if (!sort) return handleTableParamsChange('sort', null)

      handleTableParamsChange('sort', { [key]: sort })
    },
    [handleTableParamsChange],
  )

  useEffect(() => {
    if (!isEmpty(filter)) {
      onFilterChange({})
    }
  }, [page])

  useEffect(() => {
    let columns = {}
    headers.forEach(({ name }: HeaderColumn) => {
      columns = { ...columns, [name]: 'text' }
    })
    setFilterColumns(columns)
  }, [headers])

  useEffect(() => {
    if (tableRef && tableRef.current) {
      tableRef.current.scrollTo(position, 0)
    }
  }, [tableRef, filteredData])

  useEffect(() => {
    if (sorting) {
      setOrder(Object.values(sorting)[0] as Order)
      return setOrderBy(Object.keys(sorting)[0])
    }

    setOrder(undefined)
    setOrderBy('')
  }, [sorting])

  useEffect(() => {
    if (account) {
      const settings: any = getTableSettings(
        LocalStorageItems.UserSettings,
        account.userId.toString(),
        tableId,
      )

      if (
        settings &&
        settings.columnsTemplate &&
        settings.columnsTemplate.length &&
        incomingHeaders.length
      ) {
        setColumnsOrderTemplate(
          settings.columnsTemplate.map(
            (columnIndex: number) => incomingHeaders[columnIndex]?.fieldLabel,
          ),
        )
        setColumns(
          settings.columns.map((columnIndex: number) => incomingHeaders[columnIndex]?.fieldLabel),
        )
      } else {
        setColumnsOrderTemplate(incomingHeaders.map(header => header?.fieldLabel))
        setColumns([])
      }
    }
  }, [tableId, account, incomingHeaders])

  useEffect(() => {
    if (!columns.length && columnsOrderTemplate.length) {
      return setColumns(columnsOrderTemplate)
    }
  }, [columnsOrderTemplate])

  useEffect(() => {
    if (!columnsOrder.length && columnsOrderTemplate.length) {
      setColumnsOrder(columnsOrderTemplate)
    }
  }, [columnsOrderTemplate])

  useEffect(() => {
    if (columns.length && columnsOrderTemplate.length) {
      return setColumnsOrder(columnsOrderTemplate.filter(column => columns.includes(column)))
    }
  }, [columnsOrderTemplate, columns])

  useEffect(() => {
    if (columnsOrder.length && incomingHeaders.length) {
      setHeaders(
        columnsOrder.map(column => {
          const fieldData = incomingHeaders.find(field => field.fieldLabel === column)

          return {
            label: fieldData?.fieldLabel || '',
            name: fieldData?.fieldName || '',
          }
        }),
      )
    }
  }, [columnsOrder, incomingHeaders])

  useEffect(() => {
    if (Array.isArray(data) && !data.length) {
      setFilteredData([])
    }
  }, [data])

  useEffect(() => {
    if (!Object.keys(filter).length && data.length) {
      setFilteredData(data)
    }
  }, [data, filter])

  useEffect(() => {
    if (Object.keys(filter).length && data.length) {
      let result: any[] = data

      for (const key in filter) {
        result = result.filter(({ item }) => {
          const str: string = item[key] || ''

          return str.toLowerCase().includes(filter[key].toLowerCase())
        })
      }

      setFilteredData(result)
    }
  }, [data, filter])
  return (
    <div className={classes.root}>
      {tableHeader && <TableToolbar>{tableHeader}</TableToolbar>}
      <TableContainer
        component={Paper}
        className={clsx(classes.table, {
          [classes.tableWithThreeElements]: tableHeader && tableFooter && enablePaging,
          [classes.tableWithTwoElements]:
            (tableHeader && tableFooter && !enablePaging) ||
            (tableHeader && enablePaging && !tableFooter) ||
            (tableFooter && enablePaging && !tableHeader),
          [classes.tableWithElement]:
            (tableHeader && !tableFooter && !enablePaging) ||
            (tableFooter && !tableHeader && !enablePaging) ||
            (enablePaging && !tableHeader && !tableFooter),
        })}
        innerRef={tableRef}
        onScroll={!isLoading ? setPosition : undefined}
      >
        <MuiTable stickyHeader>
          {!isLoading ? (
            <>
              <TableHeader
                columnsOrderTemplate={columnsOrderTemplate}
                columnsOrder={columnsOrder}
                classes={{ cell: classes.headerCell }}
                headers={headers}
                order={order}
                orderBy={orderBy}
                filter={filter}
                filterColumns={filterColumns}
                setTableSettingsToLocalStorage={setTableSettingsToLocalStorage}
                setColumnsOrderTemplate={setColumnsOrderTemplate}
                handleSetFilter={handleSetFilter}
                handleSetSorting={handleSetSorting}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                idPrefix={idPrefix}
              />
              <TableBody>
                {filteredData.map(({ item, childItems }: any, index: number) => (
                  <TableCustomRow
                    key={item.docnr || index}
                    row={item}
                    headers={headers}
                    children={childItems}
                    order={order}
                    orderBy={orderBy}
                    nesting={nesting}
                    posTicketClick={posTicketClick}
                    ticketClick={ticketClick}
                  />
                ))}
                {filteredData && filteredData.length === 0 && ticketsTotalCount === 0 && (
                  <MuiTableRow>
                    <TableCell className={classes.noDataCell} colSpan={incomingHeaders.length}>
                      {translate('translate#title.noData')}
                    </TableCell>
                  </MuiTableRow>
                )}
              </TableBody>
            </>
          ) : (
            <TableBody>
              <MuiTableRow className={classes.loaderWrapper}>
                <TableCell className={classes.cell}>
                  <Loader />
                </TableCell>
              </MuiTableRow>
            </TableBody>
          )}
        </MuiTable>
      </TableContainer>
      {tableFooter && <TableFooter>{tableFooter}</TableFooter>}
      <TablePagination
        totalPage={totalPage}
        page={page}
        rowsPerPage={rowsPerPage}
        ticketsTotalCount={ticketsTotalCount}
        changePage={changePage}
        changeRowsPerPage={changeRowsPerPage}
        rowsPerPageOptions={[5, 10, 15, 25, 50]}
        countPageWithEmptyData={!data.length ? page : undefined}
      />
    </div>
  )
}

export default Table
