export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY'
export const DEFAULT_DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm'
export const DEFAULT_DATE_TIME_SEC_FORMAT = 'DD.MM.YYYY HH:mm:ss'
export const DATE_TIME_SEC_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const DATE_TIME_MIN_FORMAT = 'YYYY.MM.DD HH:mm'
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'HH:mm'
export const DATE_TIME_FORMAT_STANDARD = 'MM/DD/YYYY, HH:mm'
export const FIVE_MINUTES = 300000
export const FIVE_SECONDS = 5000
export const DEFAULT_DATE_FORMAT_CM = 'L'
export const DEFAULT_DATE_TIME_FORMAT_CM = 'L LT'
export const DEFAULT_DATE_TIME_SEC_FORMAT_CM = 'L LTS'
