import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  card: {
    padding: '20px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },

  root: {
    background: 'rgba(4, 21, 23, 0.3)',
  },

  paper: {
    minWidth: '272px',
  },

  notifications: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },

  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },

  closeIcon: {
    fontSize: '12px',
    cursor: 'pointer',
  },

  header: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '19px',
  },

  link: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    color: '#25ADBF',
    cursor: 'pointer',
    textDecoration: 'none',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '8px',
  },

  linkContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  noEventText: {
    color: '#929A9B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'center',
  },
})
