import { Grid } from '@material-ui/core'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import clsx from 'clsx'
import React, { ReactElement, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

import Tooltip from '@/components/blocks/Tooltip/component'
import Button from '@/components/controls/Button'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import { Props } from './types'

export const SortBlock = ({ groupKey, group, setGroup, disabled }: Props): ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  const handleSortChange = useCallback(
    (
      groupingSortType: 'BY_NAME' | 'BY_COUNT',
      groupingSortDirection: 'ASC' | 'DESC',
    ): (() => void) => (): void => {
      if (!disabled) {
        setGroup({ groupingField: groupKey, groupingSortType, groupingSortDirection })
      }
    },
    [disabled, groupKey, setGroup],
  )

  const renderButton = useCallback(
    ({
      name,
      groupingSortDirection,
    }: {
      name: 'BY_NAME' | 'BY_COUNT'
      groupingSortDirection: 'ASC' | 'DESC'
    }): ReactElement => (
      <Button
        onClick={handleSortChange(name, groupingSortDirection)}
        className={clsx(classes.sortButton, {
          [classes.sortButtonActive]:
            !disabled &&
            group &&
            group.groupingSortType === name &&
            group.groupingSortDirection === groupingSortDirection,
        })}
        variant="outlined"
      >
        <Tooltip title={translate(`translate#title.sort.${name}.${groupingSortDirection}`)}>
          {groupingSortDirection === 'ASC' ? (
            <ExpandLess color="inherit" fontSize="small" />
          ) : (
            <ExpandMore color="inherit" fontSize="small" />
          )}
        </Tooltip>
      </Button>
    ),
    [classes.sortButton, classes.sortButtonActive, disabled, group, handleSortChange, translate],
  )

  return (
    <Grid container direction="column">
      <Grid
        container
        className={clsx({
          [classes.disabled]:
            disabled ||
            (group && (!group.groupingSortType || group.groupingSortType !== 'BY_NAME')),
        })}
      >
        <ButtonGroup disableRipple size="small">
          {renderButton({ name: 'BY_NAME', groupingSortDirection: 'ASC' })}
          {renderButton({ name: 'BY_NAME', groupingSortDirection: 'DESC' })}
        </ButtonGroup>

        <span>
          <FormattedMessage id="title.byName" defaultMessage="by name" />
        </span>
      </Grid>

      <Grid
        container
        className={clsx({
          [classes.disabled]:
            disabled ||
            (group && (!group.groupingSortType || group.groupingSortType !== 'BY_COUNT')),
        })}
      >
        <ButtonGroup disableRipple size="small">
          {renderButton({ name: 'BY_COUNT', groupingSortDirection: 'ASC' })}
          {renderButton({ name: 'BY_COUNT', groupingSortDirection: 'DESC' })}
        </ButtonGroup>

        <span>
          <FormattedMessage id="title.byCount" defaultMessage="by count" />
        </span>
      </Grid>
    </Grid>
  )
}
