export const sortTablesColumnByPattern = ({
  columns,
  pattern,
}: {
  columns: string[]
  pattern: string[]
}): string[] => {
  const columnsCopy = columns || []

  columnsCopy.sort((a, b) => {
    return pattern.indexOf(a) - pattern.indexOf(b)
  })
  return columnsCopy
}

export const sortTableColumnsOrder = ({
  columns,
  destinationKey,
  sourceKey,
}: {
  columns: string[]
  sourceKey: string
  destinationKey: string
}): string[] => {
  const startPosition = columns.indexOf(sourceKey)
  const endPosition = columns.indexOf(destinationKey)

  const columnsCopy = Array.from(columns)

  const [removed] = columnsCopy.splice(startPosition, 1)
  columnsCopy.splice(endPosition, 0, removed)

  return columnsCopy
}
