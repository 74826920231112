import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import clsx from 'clsx'
import React, { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { AtmeyeModules } from '@/api/atmeye/common/types'
import { Command } from '@/api/rsocket/command'
import DocumentIcon from '@/assets/atmeye/icons/document_icon_aqua.svg'
import RestartIcon from '@/assets/atmeye/icons/restart.svg'
import SnapshotIcon from '@/assets/atmeye/icons/snapshot.svg'
import VideoIcon from '@/assets/atmeye/icons/video.svg'
import ContextMenu from '@/components/controls/ContextMenu'
import { Restricted } from '@/components/NPMPakage/components/wrappers/PermissionProvider/Restricted'
import { Permission } from '@/components/NPMPakage/components/wrappers/types'
import { isAtmeyeScreenMonitoringEnabled } from '@/constants/endpoints'
import { getAvailableModules } from '@/store/atmeye/common'
import { clearSocketDataByMessage, getSocketData } from '@/store/dashboard'
import { CurrentDeviceParamFromURL } from '@/types'
import { useTranslate } from '@/utils/internalization'
import { requestService } from '@/utils/services/request'

import ATMLogsModal from './ATMLogsModal'
import CoreModal from './CoreModal'
import DeleteFilesOnATMeyeModal from './DeleteFilesOnATMeyeModal'
import LoggingModal from './LoggingModal'
import MakeSnapshotModal from './MakeSnapshotModal'
import RestartCoreModal from './RestartCoreModal'
import { ContentWrapper, useClasses } from './styles'
import { CORE_MODAL_TYPE, LOGGING_MODAL_TYPE, Props } from './types'

const ActionsTab: FC<Props> = React.memo(
  ({ currentDevice, data }: Props): React.ReactElement => {
    const classes = useClasses()
    const translate = useTranslate()
    const dispatch = useDispatch()
    const location = useLocation<CurrentDeviceParamFromURL>()
    const history = useHistory()
    const availableModules = useSelector(getAvailableModules)

    const [showATMLogsModal, setATMLogsModal] = useState(false)
    const [showLiveVideoModal, setShowLiveVideoModal] = useState(false)
    const [showRestartCoreModal, setShowRestartCoreModal] = useState(false)
    const [showMakeSnapshotModal, setShowMakeSnapshotModal] = useState(false)
    const [showMakeScreenshotModal, setShowMakeScreenshotModal] = useState(false)
    const [showDeleteFilesOnATMeyeModal, setShowDeleteFilesOnATMeyeModal] = useState(false)

    const [anchorEl, setAnchorEl] = useState<null | (EventTarget & HTMLButtonElement)>(null)

    const [isCoreEnabled, setIsCoreEnabled] = useState(false)
    const [coreModalType, setCoreModalType] = useState<CORE_MODAL_TYPE>(CORE_MODAL_TYPE.DEFAULT)

    const [isLoggingEnabled, setIsLoggingEnabled] = useState(false)
    const [loggingModalType, setLoggingModalType] = useState<LOGGING_MODAL_TYPE>(
      LOGGING_MODAL_TYPE.DEFAULT,
    )

    const [isScreenshotsEnabled, setIsScreeshotsEnabled] = useState(false)

    const startCoreData: any = useSelector(getSocketData(Command.CONTROL_CORE_START), shallowEqual)
    const stopCoreData: any = useSelector(getSocketData(Command.CONTROL_CORE_STOP), shallowEqual)

    const isActionTabDisabled = useMemo(() => {
      if (currentDevice && data.length !== 0) {
        const device = data.find(row => row.id === String(currentDevice.deviceId))

        if (device && device?.connection_state === 'Offline') {
          return true
        }
      }

      return false
    }, [currentDevice, data])

    const handleClick = useCallback((event: SyntheticEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget)
    }, [])

    const handleClose = useCallback((): void => {
      setAnchorEl(null)
    }, [])

    const handleSetLiveVideo = useCallback((): void => {
      setShowLiveVideoModal(prevState => !prevState)
      history.push({ state: null })
      handleClose()
    }, [handleClose, history])

    const getActionsState = useCallback(async (deviceId: number) => {
      try {
        const { coreEnabled, loggingEnabled, info } = await requestService.get(
          '/device-adapter/api/v1/actions/state',
          {
            deviceId,
          },
        )

        setIsScreeshotsEnabled(info?.screenshotAvailability?.status === 'OK')
        setIsCoreEnabled(coreEnabled)
        setIsLoggingEnabled(loggingEnabled)
        location.state?.currentDevice && handleSetLiveVideo()
      } catch (error) {
        console.log(error)
      }
    }, [])

    useEffect(() => {
      if (currentDevice) {
        getActionsState(currentDevice.deviceId)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDevice, dispatch, getActionsState, startCoreData, stopCoreData, anchorEl])

    const handleSetATMLogs = useCallback((): void => {
      setATMLogsModal(prevState => !prevState)
      handleClose()
    }, [handleClose])

    const handleSetRestartCore = useCallback((): void => {
      setShowRestartCoreModal(prevState => !prevState)
    }, [])

    const handleHideRestartCoreModal = useCallback((): void => {
      setShowRestartCoreModal(false)
    }, [])

    const handleSetMakeSnapshot = useCallback((): void => {
      setShowMakeSnapshotModal(prevState => !prevState)
    }, [])

    const handleSetMakeScreenshot = useCallback((): void => {
      setShowMakeScreenshotModal(prevState => !prevState)
    }, [])

    const handleSetDeleteFilesOnATMeye = useCallback((): void => {
      setShowDeleteFilesOnATMeyeModal(prevState => !prevState)
    }, [])

    const handleChangeCoreModalType = useCallback((): void => {
      setCoreModalType(isCoreEnabled ? CORE_MODAL_TYPE.OFF : CORE_MODAL_TYPE.ON)
    }, [isCoreEnabled])

    const handleChangeLoggingModalType = useCallback((): void => {
      setLoggingModalType(isLoggingEnabled ? LOGGING_MODAL_TYPE.OFF : LOGGING_MODAL_TYPE.ON)
    }, [isLoggingEnabled])

    const handleCloseCoreModal = useCallback((): void => {
      setCoreModalType(CORE_MODAL_TYPE.DEFAULT)
    }, [])

    const handleCloseLoggingModal = useCallback(
      (): void => setLoggingModalType(LOGGING_MODAL_TYPE.DEFAULT),
      [],
    )

    const handleCoreChange = useCallback((): void => {
      setIsCoreEnabled(prevState => !prevState)
    }, [])

    useEffect(() => {
      return (): void => {
        dispatch(clearSocketDataByMessage(Command.GET_ACTIVE_CAMS))
        dispatch(clearSocketDataByMessage(Command.START_VIDEO))
        dispatch(clearSocketDataByMessage(Command.STOP_VIDEO))
        dispatch(clearSocketDataByMessage(Command.MAKE_SNAPSHOT))
      }
    }, [dispatch])

    const options = [
      {
        content: (
          <FormControl component="fieldset">
            <FormGroup>
              <Restricted permissionKey={Permission.Core_Toggle}>
                <FormControlLabel
                  className={clsx(
                    classes.switch,
                    isCoreEnabled ? classes.switchOn : classes.switchOff,
                  )}
                  control={
                    <Switch
                      onClick={handleChangeCoreModalType}
                      checked={isCoreEnabled}
                      color="secondary"
                      name="core"
                    />
                  }
                  label={translate('translate#title.core')}
                  labelPlacement="start"
                />
              </Restricted>

              <Restricted permissionKey={Permission.Logging_Toggle}>
                <FormControlLabel
                  className={clsx(
                    classes.switch,
                    isLoggingEnabled ? classes.switchOn : classes.switchOff,
                  )}
                  control={
                    <Switch
                      onClick={handleChangeLoggingModalType}
                      checked={isLoggingEnabled}
                      color="secondary"
                      name="logging"
                    />
                  }
                  label={translate('translate#title.logging')}
                  labelPlacement="start"
                />
              </Restricted>
            </FormGroup>
          </FormControl>
        ),
      },
      {
        content: (
          <div className={classes.buttonGroup}>
            <Restricted permissionKey={Permission.ATM_Logs}>
              <Button
                onClick={handleSetATMLogs}
                className={classes.button}
                color="primary"
                variant="contained"
                startIcon={<img src={DocumentIcon} />}
              >
                <FormattedMessage id="title.atmLogs" defaultMessage="ATM Logs" />
              </Button>
            </Restricted>

            {/* <Restricted permissionKey={Permission.Devices_Delete_Media}>
              <Button
                onClick={handleSetDeleteFilesOnATMeye}
                className={clsx(classes.button, classes.deleteButton)}
                color="primary"
                variant="contained"
                startIcon={<img src={DeleteIcon} />}
              >
                <FormattedMessage
                  id="title.deleteFilesOnATM"
                  defaultMessage="Delete files from device"
                />
              </Button>
            </Restricted> */}
          </div>
        ),
      },
    ]

    return (
      <div className={classes.tabWrap}>
        <ContentWrapper>
          <Grid>
            <Restricted permissionKey={Permission.Restart_Core}>
              {isCoreEnabled && (
                <Button
                  disabled={isActionTabDisabled}
                  onClick={handleSetRestartCore}
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  startIcon={<img src={RestartIcon} />}
                >
                  <FormattedMessage id="title.restartCore" defaultMessage="Restart core" />
                </Button>
              )}
            </Restricted>

            <Restricted permissionKey={Permission.Make_Snapshot}>
              <Button
                disabled={isActionTabDisabled || !isCoreEnabled}
                onClick={handleSetMakeSnapshot}
                className={classes.button}
                color="primary"
                variant="contained"
                startIcon={<img src={SnapshotIcon} />}
              >
                <FormattedMessage id="title.makeSnapshot" defaultMessage="Make snapshot" />
              </Button>
            </Restricted>

            {isAtmeyeScreenMonitoringEnabled === 'true' &&
              availableModules?.[AtmeyeModules.REMOTE_SCREEN_MONITORING] && (
                <Restricted permissionKey={Permission.Make_Snapshot}>
                  <Button
                    disabled={isActionTabDisabled || !isCoreEnabled || !isScreenshotsEnabled}
                    onClick={handleSetMakeScreenshot}
                    className={classes.button}
                    color="primary"
                    variant="contained"
                  >
                    <FormattedMessage
                      id="title.screenMonitoring"
                      defaultMessage="Screen monitoring"
                    />
                  </Button>
                </Restricted>
              )}

            <Restricted permissionKey={Permission.Live_Slideshow}>
              <Button
                disabled={isActionTabDisabled || !isCoreEnabled}
                onClick={handleSetLiveVideo}
                className={classes.button}
                color="primary"
                variant="contained"
                startIcon={<img src={VideoIcon} />}
              >
                <FormattedMessage id="title.liveVideo" defaultMessage="Live video" />
              </Button>
            </Restricted>

            <Button
              disabled={isActionTabDisabled}
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={handleClick}
              startIcon={!anchorEl ? <ExpandMore /> : <ExpandLess />}
            >
              <FormattedMessage id="atmeye.title.more" defaultMessage="More" />
            </Button>

            <ContextMenu
              anchorEl={anchorEl}
              handleClose={handleClose}
              options={options}
              className={classes.actionContext}
            />
          </Grid>
        </ContentWrapper>

        <RestartCoreModal
          setIsCoreEnabled={setIsCoreEnabled}
          open={showRestartCoreModal}
          handleClose={handleHideRestartCoreModal}
          currentDevice={currentDevice}
        />

        {showMakeSnapshotModal && (
          <MakeSnapshotModal
            open={showMakeSnapshotModal}
            handleClose={handleSetMakeSnapshot}
            currentDevice={currentDevice}
            socketCommand={Command.MAKE_SNAPSHOT}
            id="title.makeSnapshot"
            defaultMessage="Make Snapshot"
            downloadBtnText={translate('translate#title.downloadJPGSnapshot')}
          />
        )}

        {showMakeScreenshotModal && (
          <MakeSnapshotModal
            open={showMakeScreenshotModal}
            handleClose={handleSetMakeScreenshot}
            currentDevice={currentDevice}
            socketCommand={Command.MAKE_SNAPSHOT}
            id="title.screenMonitoring"
            defaultMessage="Screen monitoring"
            downloadBtnText={translate('translate#atmeye.title.download')}
            isScreenshotModal
          />
        )}

        <CoreModal
          coreModalType={coreModalType}
          currentDevice={currentDevice}
          onClose={handleCloseCoreModal}
          onCoreChange={handleCoreChange}
        />

        <LoggingModal
          isCoreEnabled={isCoreEnabled}
          loggingModalType={loggingModalType}
          currentDevice={currentDevice}
          onClose={handleCloseLoggingModal}
          setIsCoreEnabled={setIsCoreEnabled}
          setIsLoggingEnabled={setIsLoggingEnabled}
        />

        {showLiveVideoModal && (
          <MakeSnapshotModal
            open={showLiveVideoModal}
            handleClose={handleSetLiveVideo}
            currentDevice={currentDevice}
            socketCommand={Command.START_VIDEO}
            id="title.liveVideo"
            defaultMessage="Live video"
            downloadBtnText={translate('translate#title.downloadJPGSnapshot')}
          />
        )}

        <DeleteFilesOnATMeyeModal
          open={showDeleteFilesOnATMeyeModal}
          handleClose={handleSetDeleteFilesOnATMeye}
          currentDevice={currentDevice}
        />

        <ATMLogsModal
          open={showATMLogsModal}
          handleClose={handleSetATMLogs}
          currentDevice={currentDevice}
        />
      </div>
    )
  },
)

export default ActionsTab
