import { makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles({
  buttonContainer: {
    display: 'flex',
    gap: '8px',
    cursor: 'pointer',
  },

  button: {
    borderRadius: '50%',
    color: 'white',
    padding: '3px 7px',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: '0em',
  },

  eventButton: {
    backgroundColor: '#FBC91B',
  },

  alarmButton: {
    backgroundColor: '#F16452',
  },

  notificationButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
})
