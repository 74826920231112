export enum ValidationErrors {
  Required = 'required',
  Invalid = 'invalid',
  MoreThan50Symbols = 'moreThan50Symbols',
  MinLengthOfPassword = 'minLengthOfPassword6Symbols',
  MinLengthOfLogin = 'minLengthOfUsername3Symbols',
  MaxLengthOfPassword = 'maxLengthOfPassword18Symbols',
  MaxLengthOfLogin = 'maxLengthOfPassword20Symbols',
  SpecialSymbols = 'SpecialSymbolsAreProhibited',
  MaxLength1024symbolsOfEDitNotes = 'maxLength1024symbolsOfEDitNotes',
  MaxLength2048symbolsOfEDitNotes = 'maxLength2048symbolsOfEDitNotes',
  MaxLength9symbols = 'maxLength9symbols',
  MaxLength6symbols = 'maxLength6symbols',
  MaxLength2symbols = 'maxLength2symbols',
  AllowedOnlyNumbers = 'allowedOnlyNumbers',
  PhoneNumberIsNotValid = 'phoneNumberIsNotValid',
  EmailIsNotValid = 'emailIsNotValid',
  UseValueGreaterThan0 = 'useValueGreaterThan0',
  SpaceAreProhibited = 'spaceAreProhibited',
  UnacceptableSymbols = 'unacceptableSymbols',
  UnacceptablePasswordSymbols = 'unacceptablePasswordSymbols',
  FromHourValueCannotBeMoreThanToHourValue = 'fromHourValueCannotBeMoreThanToHourValue',
  FromMinuteValueShouldBeMoreThanToMinuteValue = 'fromMinuteValueShouldBeMoreThanToMinuteValue',
  TheFieldCannotExceed50Characters = 'theFieldCannotExceed50Characters',
  HoursCountCannotBeMoreThan23 = 'hoursCountCannotBeMoreThan23',
  MinCountCannotBeMoreThan59 = 'minCountCannotBeMoreThan59',
  MaxLengthReportName = 'maxLengthReportName',
  EndDateCannotBeLessThanStart = 'endDateCannotBeLessThanStart',
  DateCannotBeLessThanTomorrow = 'dateCannotBeLessThanTomorrow',
  RequirementsForChangePassword = 'RequirementsForChangePassword',
  PasswordsDoNotMatch = 'PasswordsDoNotMatch',
  MaxLengthOfFields = 'maxlengthOfFields80Symbols',
  MaxLengthOfDescriptionFields = 'maxlengthOfFields200Symbols',
  AllowNumbersLettersDashLowDash = 'allowNumbersLettersDashLowDash',
  AllowCoordinateFormat = 'allowedCoordinateFormat',
  InvalidDataFormat = 'invalidDataFormat',
  MaxValue100Percent = 'maxValue100Percent',
  TimeRangeCrossing = 'timeRangeCrossing',
  IsUsing = 'isUsing',
}
