import { AnyAction } from 'redux'
import { call, delay, put, takeLatest } from 'redux-saga/effects'

import { CommonApi } from '@/api'
import {
  AddAttachedFile,
  AttachedFile,
  AttachedFilesObjectType,
} from '@/api/common/commonApi/types'
import { ProductsApi } from '@/api/products'
import { ProductsActions } from '@/constants/actions/products'
import { AlternativeProduct, Product, ProductSpecification } from '@/types/products/products'
import { PopUpService } from '@/utils/services/popUpService'

import {
  addNewProductResponse,
  addNewProductResponseFail,
  addProductAttachedFileResponse,
  deleteProductResponse,
  deleteProductResponseFail,
  editProductResponse,
  editProductResponseFail,
  getAllowedToAddAlternativeProductsResponse,
  getAllowedToAddAlternativeProductsResponseFail,
  getAllowedToAddProductSpecificationResponse,
  getAllowedToAddProductSpecificationResponseFail,
  getAlternativeProductsResponse,
  getAlternativeProductsResponseFail,
  getConfigResponse,
  getConfigResposeFail,
  getProductAttachedFilesResponse,
  getProductAttachedFilesResponseFail,
  getProductByIdResponse,
  getProductByIdResponseFail,
  getProductSpecificationResponse,
  getProductSpecificationResponseFail,
  getProductsResponse,
  getProductsResponseFail,
  removeProductAttachedFileResponse,
  saveAlternativeProductsResponse,
  saveAlternativeProductsResponseFail,
  saveProductSpecificationResponse,
  saveProductSpecificationResponseFail,
} from './actions'
import { config, products } from './testData'

function* getConfig(): Generator {
  try {
    yield delay(1500) // delay
    yield put(getConfigResponse(config))
  } catch (error) {
    yield put(getConfigResposeFail(error.message))
  }
}

function* getProducts(): Generator {
  try {
    yield delay(1500) // delay
    yield put(getProductsResponse(products))
  } catch (error) {
    yield put(getProductsResponseFail(error.message))
  }
}

function* getProductById({ payload }: AnyAction): Generator {
  try {
    const response = yield call(ProductsApi.getProductById, payload)

    yield put(getProductByIdResponse(response as Product))
  } catch (error) {
    yield put(getProductByIdResponseFail(error.message))
  }
}

function* addNewProduct({ payload }: AnyAction): Generator {
  try {
    const response = yield call(ProductsApi.addNewProduct, payload)

    yield put(addNewProductResponse(response as Product))
  } catch (error) {
    yield put(addNewProductResponseFail(error))
  }
}

function* editProduct({ payload: { productId, data } }: AnyAction): Generator {
  try {
    const response = yield call(ProductsApi.editProduct, productId, data)

    yield put(editProductResponse(response as Product))

    PopUpService.showGlobalPopUp('translate#prod.productHasBeenUpdated', 'success')
  } catch (error) {
    yield put(editProductResponseFail(error))
  }
}

function* deleteProduct({ payload: { productId } }: AnyAction): Generator {
  try {
    const response = yield call(ProductsApi.deleteProduct, productId)

    yield put(deleteProductResponse())

    PopUpService.showGlobalPopUp('translate#prod.productHasBeenDeleted', 'success')
  } catch (error) {
    yield put(deleteProductResponseFail(error))
  }
}

function* getAlternativeProducts({ payload: { mainProductId } }: AnyAction): Generator {
  try {
    const response = yield call(ProductsApi.getAlternativeProducts, mainProductId)

    yield put(getAlternativeProductsResponse(response as AlternativeProduct))
  } catch (error) {
    yield put(getAlternativeProductsResponseFail(error))
  }
}

function* getAllowedToAddAlternativeProducts({
  payload: { excludeProductIds, searchFragment },
}: AnyAction): Generator {
  try {
    const response = yield call(
      ProductsApi.getAlternativeProductsAllowedToAdd,
      excludeProductIds,
      searchFragment,
    )

    yield put(getAllowedToAddAlternativeProductsResponse(response as AlternativeProduct))
  } catch (error) {
    yield put(getAllowedToAddAlternativeProductsResponseFail(error))
  }
}

function* saveAlternativeProducts({
  payload: { mainProductId, addProducts, deleteProducts },
}: AnyAction): Generator {
  try {
    const response = yield call(
      ProductsApi.saveAlternativeProducts,
      mainProductId,
      addProducts,
      deleteProducts,
    )
    yield put(saveAlternativeProductsResponse(response as AlternativeProduct[], deleteProducts))
  } catch (error) {
    yield put(saveAlternativeProductsResponseFail(error.message))
  }
}

function* getProductSpecification({ payload: { mainProductId } }: AnyAction): Generator {
  try {
    const response = yield call(ProductsApi.getProductSpecification, mainProductId)

    yield put(getProductSpecificationResponse(response as ProductSpecification))
  } catch (error) {
    yield put(getProductSpecificationResponseFail(error.message))
  }
}

function* getAllowedToAddProductSpecification({
  payload: { excludeSpecificationIds, searchFragment },
}: AnyAction): Generator {
  try {
    const response = yield call(
      ProductsApi.getProductSpecificationAllowedToAdd,
      excludeSpecificationIds,
      searchFragment,
    )

    yield put(getAllowedToAddProductSpecificationResponse(response as ProductSpecification))
  } catch (error) {
    yield put(getAllowedToAddProductSpecificationResponseFail(error.message))
  }
}

function* saveProductSpecification({
  payload: { mainProductId, addProductSpecifications, deleteProductSpecifications },
}: AnyAction): Generator {
  try {
    const response = yield call(
      ProductsApi.saveProductSpecification,
      mainProductId,
      addProductSpecifications,
      deleteProductSpecifications,
    )
    yield put(
      saveProductSpecificationResponse(
        response as ProductSpecification[],
        deleteProductSpecifications,
      ),
    )
  } catch (error) {
    yield PopUpService.showGlobalPopUp(error.message, 'error')
    yield put(saveProductSpecificationResponseFail(error.message))
  }
}

function* getProductAttachedFiles({ payload: { productId } }: AnyAction): Generator {
  try {
    const response = yield call(
      CommonApi.getAttachedFiles,
      AttachedFilesObjectType.PRODUCT_FILES,
      productId,
    )

    yield put(getProductAttachedFilesResponse(response as AttachedFile[]))
  } catch (error) {
    yield put(getProductAttachedFilesResponseFail(error.message))
  }
}

function* addProductAttachedFile({ payload }: AnyAction): Generator {
  try {
    const response = yield call(CommonApi.addAttachedFile, payload)
    yield put(addProductAttachedFileResponse(response as AttachedFile))
    yield PopUpService.showGlobalPopUp('translate#prod.addedFileSuccessfully', 'success')
  } catch (error) {
    yield put(getProductAttachedFilesResponseFail(error.message))
  }
}

function* removeProductAttachedFile({ payload }: AnyAction): Generator {
  try {
    yield call(CommonApi.removeAttachedFile, payload)
    yield put(removeProductAttachedFileResponse(payload))
    yield PopUpService.showGlobalPopUp('translate#prod.removedFileSuccessfully', 'success')
  } catch (error) {
    yield put(getProductAttachedFilesResponseFail(error.message))
  }
}

export default function*(): Generator {
  yield takeLatest(ProductsActions.GetConfigRequest, getConfig)
  yield takeLatest(ProductsActions.GetProductsRequest, getProducts)
  yield takeLatest(ProductsActions.GetAlternativeProductsRequest, getAlternativeProducts)
  yield takeLatest(
    ProductsActions.GetAllowedToAddAlternativeProductsRequest,
    getAllowedToAddAlternativeProducts,
  )
  yield takeLatest(ProductsActions.SaveAlternativeProductsRequest, saveAlternativeProducts)

  yield takeLatest(ProductsActions.GetProductSpecificationRequest, getProductSpecification)
  yield takeLatest(
    ProductsActions.GetAllowedToAddProductSpecificationRequest,
    getAllowedToAddProductSpecification,
  )
  yield takeLatest(ProductsActions.SaveProductSpecificationRequest, saveProductSpecification)

  yield takeLatest(ProductsActions.GetProductAttachedFilesRequest, getProductAttachedFiles)
  yield takeLatest(ProductsActions.AddProductAttachedFileRequest, addProductAttachedFile)
  yield takeLatest(ProductsActions.RemoveProductAttachedFileRequest, removeProductAttachedFile)

  yield takeLatest(ProductsActions.GetProductByIdRequest, getProductById)
  yield takeLatest(ProductsActions.AddNewProductRequest, addNewProduct)
  yield takeLatest(ProductsActions.EditProductRequest, editProduct)
  yield takeLatest(ProductsActions.DeleteProductRequest, deleteProduct)
}
