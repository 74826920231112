import { utils, read, write } from 'xlsx'
import { saveAs } from 'file-saver'

export const readerXLSX = (file: File, sheet = ''): Promise<unknown[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString

    if (rABS) {
      reader.readAsBinaryString(file)
    } else {
      reader.readAsArrayBuffer(file)
    }

    reader.onload = (event): void => {
      /* Parse data */
      const bstr = event.target?.result
      const workbook = read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true })

      /* Get worksheet */
      let firstWorksheet

      if (sheet) {
        firstWorksheet = workbook.Sheets[sheet]
      } else {
        firstWorksheet = workbook.Sheets[workbook.SheetNames[0]]
      }

      resolve(utils.sheet_to_json(firstWorksheet))
    }

    reader.onerror = (fileError): void => reject(fileError)
  })
}

export const getSheetsXLSX = (file: File): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString

    if (rABS) {
      reader.readAsBinaryString(file)
    } else {
      reader.readAsArrayBuffer(file)
    }

    reader.onload = (event): void => {
      /* Parse data */
      const bstr = event.target?.result
      const workbook = read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true })

      resolve(workbook.SheetNames)
    }

    reader.onerror = (fileError): void => reject(fileError)
  })
}

export const exportToFile = (data: any, fileName: string): void => {
  if (fileName.includes('.pdf')) {
    saveAs(data, fileName)
  }
  if (fileName.includes('.xlsx')) {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

    const ws = utils.json_to_sheet(data)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
    const file = new Blob([excelBuffer], { type: fileType })

    saveAs(file, fileName)
  }
}

export const exportFromByteArray = (data: any, fileName: string): void => {
  const fileType = fileName.slice((Math.max(0, fileName.lastIndexOf('.')) || Infinity) + 1)
  const file = new Blob([data], { type: `application/${fileType}` })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(file)
  link.download = fileName
  link.click()
}
