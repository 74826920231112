import { AnyAction } from 'redux'

import {
  BS2ReplenishmentType,
  ComCMReport,
  IcsDocStage,
  IcsOrder,
  IcsOrdersResponse,
  IcsOrderStagesInfo,
  IcsSetStageRequest,
  ShrDeclineRequest,
} from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants'

export const getIcsOrdersRequest = (): AnyAction => ({
  type: CMActions.IcsGetOrdersRequest,
})

export const getIcsOrdersResponse = (payload: IcsOrdersResponse): AnyAction => ({
  type: CMActions.IcsGetOrdersResponse,
  payload,
})

export const getIcsOrdersResponseFail = (error: string): AnyAction => ({
  type: CMActions.IcsGetOrdersResponseFail,
  payload: error,
})

export const setIcsOrdersPagingRequest = (payload: {
  page?: number
  pageSize?: number
}): AnyAction => ({
  type: CMActions.IcsSetOrdersPagingRequest,
  payload,
})

export const setIcsOrdersPagingResponse = (payload: {
  page?: number
  pageSize?: number
}): AnyAction => ({
  type: CMActions.IcsSetOrdersPagingResponse,
  payload,
})

export const resetIcsCashPointsPage = (): AnyAction => ({
  type: CMActions.IcsResetOrdersPage,
})

export const setIcsFiltersModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.IcsSetFiltersModalOpen,
  payload: isOpen,
})

export const icsFiltersSubmitRequest = (payload: any): AnyAction => ({
  type: CMActions.IcsFiltersSubmitRequest,
  payload,
})

export const setIcsOrdersFilter = (payload: any): AnyAction => ({
  type: CMActions.IcsSetOrdersFilter,
  payload,
})

export const setIcsSelectedOrder = (order: IcsOrder | null): AnyAction => ({
  type: CMActions.IcsSetSelectedOrder,
  payload: order,
})

export const sortIcsOrdersTable = (payload: {
  sortColumn: string | undefined
  sortOrder: 'asc' | 'desc' | undefined
}): AnyAction => ({
  type: CMActions.IcsSortOrdersTable,
  payload,
})

export const toggleIcsOrder = (order: IcsOrder): AnyAction => ({
  type: CMActions.IcsToggleOrder,
  payload: order,
})

export const checkIcsAllOrders = (orders: IcsOrder[]): AnyAction => ({
  type: CMActions.IcsCheckAllOrders,
  payload: orders,
})

export const clearIcsAllOrders = (): AnyAction => ({
  type: CMActions.IcsClearAllOrders,
})

export const setIcsDocumentModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.IcsSetDocumentModalOpen,
  payload: isOpen,
})

export const setIcsSettingsModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.IcsSetSettingsModalOpen,
  payload: isOpen,
})

export const setIcsChangeReplenishmentTypeModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.IcsSetChangeReplenishmentTypeModalOpen,
  payload: isOpen,
})

export const setIcsDeclineModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.IcsSetDeclineModalOpen,
  payload: isOpen,
})

export const refreshReplenishments = (): AnyAction => ({
  type: CMActions.IcsRefreshOrdersPanel,
})

export const getIcsReportsRequest = (): AnyAction => ({
  type: CMActions.IcsGetReportsRequest,
})

export const getIcsReportsResponse = (payload: any): AnyAction => ({
  type: CMActions.IcsGetReportsResponse,
  payload,
})

export const getIcsReportsResponseFail = (message?: string): AnyAction => ({
  type: CMActions.IcsGetReportsResponseFail,
  payload: message,
})

export const getIcsReportRequest = (payload?: ComCMReport): AnyAction => ({
  type: CMActions.IcsGetReportRequest,
  payload,
})

export const getIcsReportResponse = (payload: any): AnyAction => ({
  type: CMActions.IcsGetReportResponse,
  payload,
})

export const getIcsReportResponseFail = (message?: string): AnyAction => ({
  type: CMActions.IcsGetReportResponseFail,
  payload: message,
})

export const refreshIcsOrderRequest = (
  guids: string,
  withRefreshStages = true,
  withRefreshDocs = true,
): AnyAction => ({
  type: CMActions.IcsRefreshOrderRequest,
  payload: { id: guids, withRefreshStages, withRefreshDocs },
})

export const refreshIcsOrderResponse = (payload: IcsOrder): AnyAction => ({
  type: CMActions.IcsRefreshOrderResponse,
  payload,
})

export const refreshIcsOrderResponseFail = (error: string): AnyAction => ({
  type: CMActions.IcsRefreshOrderResponseFail,
  payload: error,
})

export const getIcsStagesDocRequest = (id: string): AnyAction => ({
  type: CMActions.IcsGetStagesDocRequest,
  id,
})

export const getIcsStagesDocResponse = (payload: IcsDocStage[]): AnyAction => ({
  type: CMActions.IcsGetStagesDocResponse,
  payload,
})

export const setIcsSelectedSessionDoc = (payload: IcsDocStage): AnyAction => ({
  type: CMActions.IcsSetSelectedSessionDoc,
  payload,
})
export const declineIcsOrdersRequest = (payload: ShrDeclineRequest): AnyAction => ({
  type: CMActions.IcsDeclineOrdersRequest,
  payload,
})

export const declineIcsOrdersResponseFail = (error: string): AnyAction => ({
  type: CMActions.IcsDeclineOrdersResponseFail,
  payload: error,
})

export const changeIcsReplenTypeRequest = (payload: {
  orderIds: string[]
  type: BS2ReplenishmentType
}): AnyAction => ({
  type: CMActions.IcsChangeReplenTypeRequest,
  payload,
})

export const changeIcsReplenTypeResponseFail = (error: string): AnyAction => ({
  type: CMActions.IcsChangeReplenTypeResponseFail,
  payload: error,
})

export const assignIcsCollectorRequest = (payload: {
  collectorUid: string
  ids: string[]
}): AnyAction => ({
  type: CMActions.IcsAssignCollectorRequest,
  payload,
})

export const assignIcsCollectorResponseFail = (error: string): AnyAction => ({
  type: CMActions.IcsAssignCollectorResponseFail,
  payload: error,
})

export const getIcsOrderStagesRequest = (orderId: string): AnyAction => ({
  type: CMActions.IcsGetOrderStagesRequest,
  payload: orderId,
})

export const getIcsOrderStagesResponse = (payload: IcsOrderStagesInfo): AnyAction => ({
  type: CMActions.IcsGetOrderStagesResponse,
  payload,
})

export const getIcsOrderStagesResponseFail = (error: string): AnyAction => ({
  type: CMActions.IcsGetOrderStagesResponseFail,
  payload: error,
})

export const setIcsOrderStageRequest = (payload: IcsSetStageRequest): AnyAction => ({
  type: CMActions.IcsSetOrderStageRequest,
  payload,
})

export const setIcsOrderStageResponse = (payload: IcsOrderStagesInfo): AnyAction => ({
  type: CMActions.IcsSetOrderStageResponse,
  payload,
})

export const setIcsOrderStageResponseFail = (error: string): AnyAction => ({
  type: CMActions.IcsSetOrderStageResponseFail,
  payload: error,
})

export const deleteIcsLastDocumentRequest = (payload: string): AnyAction => ({
  type: CMActions.IcsDeleteLastDocumentRequest,
  payload,
})

export const deleteIcsLastDocumentResponse = (payload: IcsOrderStagesInfo): AnyAction => ({
  type: CMActions.IcsDeleteLastDocumentResponse,
  payload,
})

export const deleteIcsLastDocumentResponseFail = (error: string): AnyAction => ({
  type: CMActions.IcsDeleteLastDocumentResponseFail,
  payload: error,
})

export const handleIcsKeyPress = (
  actionName:
    | 'TOGGLE_CHECKBOX'
    | 'NEXT_ROW'
    | 'PREV_ROW'
    | 'OPEN_FILTER'
    | 'OPEN_PRINT_DIALOG'
    | 'REFRESH',
): AnyAction => ({
  type: CMActions.IcsHandleKeyPress,
  payload: actionName,
})
