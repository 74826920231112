import { makeStyles } from '@material-ui/core'

export const useClasses = makeStyles(({ spacing }) => ({
  listItem: {
    padding: 0,
    marginBottom: spacing(0.5),
    display: 'grid',
    gridTemplateColumns: 'repeat(3, max-content)',
    width: 'max-content',
    maxWidth: 1,
  },

  networksListItem: {
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    width: '20px',
    marginRight: '8px',
    display: 'grid',
  },
}))
