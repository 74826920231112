import React, { ReactElement } from 'react'
import { Checkbox } from '@material-ui/core'
import { TableColumn } from './tableConfig'
import { HeaderRenderCellProps } from './types'

export const headerCellChild = ({
  classes,
  actionItemLength,
  dataLength,
  handleHeaderCheckbox,
}: HeaderRenderCellProps) => (key: string): ReactElement | null => {
  switch (key) {
    case TableColumn.Approve:
    case TableColumn.Reject:
      return (
        <Checkbox
          checked={(!!actionItemLength[key] && actionItemLength[key] === dataLength) || false}
          className={classes.checkbox}
          onChange={handleHeaderCheckbox(key)}
          indeterminate={actionItemLength[key] > 0 && actionItemLength[key] < dataLength}
        />
      )
  }
  return null
}
