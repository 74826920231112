export enum AtmeyeTransactionActions {
  ChangeSelectedFilters = '@/TRANSACTIONS/CHANGE_SELECTED_FILTERS',
  ChangeGrouping = '@/TRANSACTIONS/CHANGE_GROUPING',
  ClearGrouping = '@/CLEAR_GROUPING',
  ChangeSorting = '@/TRANSACTIONS/CHANGE_SORTING',
  SetRowPerPage = '@/TRANSACTIONS/SET_ROW_PER_PAGE',
  SetPage = '@/TRANSACTIONS/SET_PAGE',

  DeleteFilterTemplateRequest = '@/TRANSACTIONS/DELETE_FILTER_TEMPLATE_REQUEST',
  SetSelectedGroupingTemplate = '@/TRANSACTIONS/SET_SELECTED_GROUPING_TEMPLATE',
  GetGroupingTemplatesRequest = '@/TRANSACTIONS/GET_GROUPING_TEMPLATES_REQUEST',

  GetFiltersTemplatesRequest = '@/TRANSACTIONS/GET_FILTERS_TEMPLATES_REQUEST',

  SaveGroupingTemplateRequest = '@/TRANSACTIONS/SAVE_GROUPING_TEMPLATE_REQUEST',

  CreateFilterTemplateRequest = '@/TRANSACTIONS/CREATE_FILTER_TEMPLATE_REQUEST',
  EditGroupTemplateRequest = '@/TRANSACTIONS/EDIT_GROUP_TEMPLATE_REQUEST',
  DeleteGroupTemplateRequest = '@/TRANSACTIONS/DELETE_GROUP_TEMPLATE_REQUEST',
  UpdateFilterTemplateRequest = '@/TRANSACTIONS/UPDATE_FILTER_TEMPLATE_REQUEST',

  GetTicketsConfigResponse = '@/TRANSACTIONS/GET_TICKETS_CONFIG_RESPONSE',
  SetGroupTemplates = '@/TRANSACTIONS/SET_SD_GROUP_TEMPLATES',
  CollectFilterTemplateFields = '@/TRANSACTIONS/COLLECT_FILTER_TEMPLATE_FIELDS',

  GetTransactionsExport = '@/TRANSACTIONS/GET_TRANSACTIONS_EXPORT',
  GetExportResponse = '@/GET_EXPORT_RESPONSE',
  GetExportResponseFail = '@/GET_EXPORT_RESPONSE_FAIL',

  GetTransactionsExportGrouped = '@/TRANSACTIONS/GET_TRANSACTIONS_EXPORT_GROUPED',
  GetExportGroupedResponse = '@/GET_EXPORT_GROUPED_RESPONSE',
  GetExportGroupedResponseFail = '@/GET_EXPORT_GROUPED_RESPONSE_FAIL',

  SetSdFilterTemplateResponse = '@/TRANSACTIONS/SET_SD_FILTER_TEMPLATE_RESPONSE',

  GetTransactionsConfigRequest = '@/TRANSACTIONS/GET_TRANSACTIONS_CONFIG_REQUEST',
  GetTransactionsRequest = '@/TRANSACTIONS/GET_TRANSACTIONS_REQUEST',
  GetTransactionsResponse = '@/TRANSACTIONS/GET_TRANSACTIONS_RESPONSE',
  GetGroupDetailsRequest = '@/TRANSACTIONS/GET_GROUP_DETAILS_REQUEST',
  SetParentGroups = '@/TRANSACTIONS/SET_PARENT_GROUPS',
  SetCurrentFilterTemplate = '@/TRANSACTIONS/SET_CURRENT_FILTER_TEMPLATE',
  GetMedia = '@/TRANSACTIONS/GET_MEDIA',
  SetMedia = '@/TRANSACTIONS/SET_MEDIA',
  ClearCurrentMedia = '@/TRANSACTIONS/CLEAR_CURRENT_MEDIA',
  SetCurrentMedia = '@/TRANSACTIONS/SET_CURRENT_MEDIA',
  SetMediaErrorMessage = '@/TRANSACTIONS/SET_MEDIA_ERROR_MESSAGE',
  EditTableCellRequest = '@/TRANSACTIONS/EDIT_TABLE_CELL_REQUEST',
  EditTableCellGrouping = '@/TRANSACTIONS/EDIT_TABLE_CELL_REQUEST_GROUPING',
  SaveTableCell = '@/TRANSACTIONS/SAVE_TABLE_CELL',
  SaveTableCellGrouping = '@/TRANSACTIONS/SAVE_TABLE_CELL_GROUPING',
  SetDataPagination = '@/TRANSACTIONS/SET_DATA_PAGINATION',
  SetDataPaginationRequest = '@/TRANSACTIONS/SET_DATA_PAGINATION_REQUEST',
  ClearGroupedTicketDetailsData = '@/TRANSACTIONS/CLEAR_GROUPED_DETAILS_DATA',

  SetChangeTableData = '@/TRANSACTIONS/SET_CHANGE_TABLE_DATA',
  SetFilterModalState = '@/TRANSACTIONS/SET_FILTER_MODAL_STATE',
  IsSubmitFiltersButtonClicked = '@/TRANSACTIONS/IS_SUBMIT_FILTERS_BUTTON_CLICKED',
  GetTicketResponseFail = '@/TRANSACTIONS/GET_TICKETS_RESPONSE_FAIL',
}
