import { createTextInputField } from '@/components/controls/TextInput/component'

interface Args {
  label: string
  placeholder: string
  required?: boolean
  disabled?: boolean
  className?: string
}

export const GenericInput = ({
  label,
  placeholder,
  required = false,
  disabled = false,
  className,
}: Args) =>
  createTextInputField({
    required,
    placeholder,
    disabled,
    label,
    fullWidth: true,
    className,
    InputLabelProps: {
      shrink: true,
    },
  })
