import { Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import CloseButton from '@/components/blocks/CloseButton'
import Button from '@/components/controls/Button'

import { useClasses } from './styles'
import { Props } from './types'

export const ConfirmModal = ({
  open,
  onClose,
  onConfirm,
  message,
  subMessage,
  classes: customClasses,
  disableBackdropClick = true,
  btnText,
  cancelButton = true,
  cancelButtonText,
  submitVariant = 'contained',
}: Props): React.ReactElement => {
  const classes = useClasses()

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose} disableBackdropClick={disableBackdropClick}>
      <div className={clsx(classes.wrapper, customClasses?.contentWrapper)}>
        <CloseButton absolute className={classes.closeButton} onClick={onClose} />
        <DialogTitle className={clsx(classes.message, customClasses?.title)}>{message}</DialogTitle>
        {subMessage}
        <DialogActions className={clsx(classes.buttonWrapper, customClasses?.buttonsContainer)}>
          {cancelButton && (
            <Button variant="outlined" width="sm" onClick={onClose}>
              {cancelButtonText || <FormattedMessage id="action.cancel" defaultMessage="Cancel" />}
            </Button>
          )}
          <Button type="submit" variant={submitVariant} width="sm" onClick={onConfirm}>
            {btnText || <FormattedMessage id="action.ok" defaultMessage="Ok" />}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}
