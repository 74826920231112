import { Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'

import Loader from '@/components/blocks/Loader'
import { getTicketInfo } from '@/store/sd/drawerActions/selectors'
import { useTranslate } from '@/utils/internalization'

import MerchantTableRow from './components/MerchantTableRow'
import { useClasses } from './style'
import { MerchantInformationFormField } from './types'

const MerchantInformationTable = (): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  const newMerchantInfo = useSelector(getTicketInfo)

  const tableHeaders: string[] = [
    MerchantInformationFormField.MerchantInformation,
    MerchantInformationFormField.MerchantLegalAddress,
    MerchantInformationFormField.MerchantActualAddress,
    MerchantInformationFormField.Contacts,
  ]

  return (
    <>
      {!newMerchantInfo ? (
        <div className={classes.loaderWrapper}>
          <Loader inline />
        </div>
      ) : (
        <Grid container component={Paper} className={classes.tableWrap}>
          {tableHeaders.map(headerTitle => (
            <Grid container key={headerTitle} className={classes.tableCell}>
              <Typography className={classes.headerTitle} variant="h6">
                {translate(`translate#title.${headerTitle}`)}
              </Typography>
              <Grid container className={classes.contentWrap}>
                {Object.keys(newMerchantInfo[headerTitle]).map((key: string, index: number) => {
                  if (Object.keys(newMerchantInfo[headerTitle]).length < 3) {
                    return (
                      <>
                        <MerchantTableRow
                          key={`${key}${index}`}
                          item={key}
                          value={newMerchantInfo[headerTitle][key]}
                        />
                        <MerchantTableRow key={`${key}${index + 1}`} item="" value="" />
                        <MerchantTableRow key={`${key}${index + 2}`} item="" value="" />
                      </>
                    )
                  }
                  return (
                    <MerchantTableRow
                      key={key}
                      item={key}
                      value={newMerchantInfo[headerTitle][key]}
                    />
                  )
                })}
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  )
}

export default MerchantInformationTable
