import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  wrapper: {
    padding: spacing(4),
  },
  closeButton: {
    top: spacing(2),
    right: spacing(2),
    '& [class*="makeStyles-icon"]': {
      color: '#041517',
    },
  },
  header: {
    marginBottom: spacing(2.5),
    fontWeight: 'normal',
  },
  subheader: {
    paddingBottom: spacing(2.5),
    marginRight: spacing(1.5),
    borderBottom: '1px solid #CAD5D7',
    fontSize: '14px',
  },
  iconButton: {
    width: '40px',
    height: '40px',
    padding: '0',
    minWidth: 'auto',
    '&[disabled]': {
      filter: 'grayscale(100%)',
      borderColor: '#929A9B',
    },
  },
  button: {
    padding: spacing(1, 2.5),
    fontWeight: 'normal',
  },
  buttonWrapper: {
    marginTop: spacing(0.5),
  },
  receiveDateText: {
    opacity: 0.8,
  },
  input: {
    width: '100%',
  },
  subtitle: {
    fontSize: '22px',
    fontWeight: 'bold',
  },
  form: {
    marginTop: spacing(2),
    marginRight: spacing(1.5),
  },
  fieldsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  field: {
    maxWidth: '48.5%',
    flexBasis: '48.5%',
    '& [class*="makeStyles-icon"]': {
      color: '#041517',
      fontSize: '1.4rem',
    },
    '& .MuiOutlinedInput-inputAdornedEnd, & .MuiSelect-select': {
      fontSize: '14px',
    },
  },
  actionButton: {
    marginLeft: spacing(1.5),
  },
  templateSelect: {
    maxWidth: 'calc(48.5% * 2)',
    flexBasis: 'calc(48.5% * 2)',
    marginBottom: '8px',
    '& [class*="makeStyles-icon"]': {
      color: '#041517',
      fontSize: '1.4rem',
    },
    '& .MuiSelect-select': {
      fontSize: '14px',
    },
  },
  savedFilters: {
    marginTop: spacing(2.5),
    fontWeight: 'normal',
  },
}))
