import { useCallback, useEffect, useState } from 'react'

import { CommonApi } from '@/api/common/commonApi'
import { BuildInfo } from '@/types/cm/common'

const useAppVersion = (isServerBuildInfoAvailable = true): BuildInfo => {
  const [buildInfo, setBuildInfo] = useState({})

  const getBuildInfo = useCallback(async () => {
    try {
      let serverBuildInfo = 'Unavailable'
      if (isServerBuildInfoAvailable) {
        serverBuildInfo = await CommonApi.getServerVersion()
      }

      const clientBuildInfo = await CommonApi.getClientVersion()

      setBuildInfo({ serverBuildInfo, clientBuildInfo })
    } catch (e) {
      console.log('Err', e)
    }
  }, [])

  useEffect(() => {
    getBuildInfo()
  }, [])

  return buildInfo
}

export default useAppVersion
