import { TableRow } from '@material-ui/core'
import clsx from 'clsx'
import React, { FC, ReactElement, useCallback, useMemo } from 'react'

import { useCollapseRow, useTableWithBorder } from '@/components/blocks/cm/CMTable/hooks'

import { CMTableCell, useCommonClasses } from '../../../commonStyles'
import { CollapseRowCell } from '../CollapseRowCell'
import { RowCheckboxCell } from '../RowCheckboxCell'
import { TableCell } from '../TableCell'
import { useClasses } from './styles'
import { Props } from './types'

export const CMTableRow: FC<Props> = React.memo((props: Props) => {
  const {
    itemId,
    isChecked,
    isFocusable = false,
    isSelected = false,
    tableClasses,
    total,
    item,
    isVisible,
    isAllRowsOpened,
    fitTableContent,
    showCollapseIcon,
    keys,
    fixedColumns,
    twiceColumns,
    cellWithNumberValue,
    columnsToSort,
    rowGroupKey,
    entriesKey,
    rowIndex,
    selectedCellId,
    nonClickableCells,
    onRowClick,
    onCellClick,
    onRowDoubleClick,
    handleCheckRow,
    collapseCondition,
    collapseRowRender,
    checkboxCondition,
    setInlineStyleForRows,
    setStylesForSpecificRows,
    conditionallyRenderCell,
  } = props

  const classes = useClasses()
  const commonClasses = useCommonClasses()

  const isBorder = useTableWithBorder()
  const { isCollapseOpen, handleToggleOpen } = useCollapseRow({ isAllRowsOpened })

  const stylesForSpecificRowsMemo = useMemo(
    () => setStylesForSpecificRows && setStylesForSpecificRows(item),
    [setStylesForSpecificRows, item],
  )

  const inlineStyleForRowsMemo = useMemo(
    () => setInlineStyleForRows && setInlineStyleForRows(item),
    [setInlineStyleForRows, item],
  )

  const isRenderCollapse = useMemo(() => (collapseCondition ? collapseCondition(item) : true), [
    collapseCondition,
    item,
  ])

  const isRenderDisabledCheckbox = useMemo(
    () => (checkboxCondition ? !checkboxCondition(item) : false),
    [checkboxCondition, item],
  )

  const borderCellClasses = useMemo(() => (isBorder ? commonClasses.borderCell : undefined), [
    isBorder,
    commonClasses,
  ])

  const rowClassesMemo = useMemo(
    () =>
      clsx(tableClasses?.row, stylesForSpecificRowsMemo || classes.tableRow, {
        [classes.row]: total > 400,
        [classes.focused]: isFocusable,
        [classes.selectedRow]: isSelected,
        [classes.clickableRow]: onRowClick || onRowDoubleClick,
      }),
    [classes, tableClasses, isFocusable, stylesForSpecificRowsMemo, total, isSelected],
  )

  const cellClassesMemo = useMemo(
    () =>
      clsx(tableClasses?.cell, tableClasses?.fixed, borderCellClasses, {
        [classes.withoutBorderBottom]: isCollapseOpen && isRenderCollapse,
        [classes.lastElementBorder]: borderCellClasses && fitTableContent,
      }),
    [classes, tableClasses, isCollapseOpen, isRenderCollapse, borderCellClasses, fitTableContent],
  )

  const rowClickMemo = useCallback(() => onRowClick && onRowClick(item), [item, onRowClick])
  const cellClickMemo = (key: string) => (event: React.MouseEvent<HTMLTableCellElement>): void => {
    event.stopPropagation()
    event.preventDefault()

    return onCellClick && onCellClick(item, key)
  }

  const rowDoubleClickMemo = useCallback(() => onRowDoubleClick && onRowDoubleClick(item), [
    item,
    onRowDoubleClick,
  ])

  const handleCheckRowMemo = useCallback(
    () => (handleCheckRow ? handleCheckRow(item) : undefined),
    [item, handleCheckRow],
  )

  const renderLastEmptyCell = useMemo(
    () =>
      fitTableContent ? <CMTableCell size="small" className={clsx(tableClasses?.cell)} /> : null,
    [tableClasses, fitTableContent],
  )

  return (
    <>
      <TableRow
        data-guid={itemId}
        id={itemId}
        tabIndex={isFocusable ? 0 : undefined}
        className={rowClassesMemo}
        style={inlineStyleForRowsMemo}
        onClick={rowClickMemo}
        onDoubleClick={rowDoubleClickMemo}
      >
        {(rowGroupKey || entriesKey) && <CMTableCell />}
        {total < 400 || isVisible ? (
          <>
            {showCollapseIcon && (
              <CollapseRowCell
                isFixed={!!fixedColumns}
                isRenderCollapse={isRenderCollapse}
                isCollapseOpen={isCollapseOpen}
                handleToggleOpen={handleToggleOpen}
                cellClasses={cellClassesMemo}
              />
            )}
            {!!handleCheckRow && (
              <RowCheckboxCell
                isChecked={isChecked}
                isFixed={!!fixedColumns}
                isFixedLast={fixedColumns?.length === 0}
                handleCheck={handleCheckRowMemo}
                cellClasses={cellClassesMemo}
                isDisabled={isRenderDisabledCheckbox}
              />
            )}
            {keys.map((key, index) => {
              const cellKey = `${itemId}-${key}`

              const inNumber = (cellWithNumberValue || []).includes(key)
              const isTwiceCell = twiceColumns.includes(key)
              const isRightSpace = (cellWithNumberValue || []).some(
                cell => cell === (columnsToSort || []).find(column => column === key),
              )
              const isFixedLast = (fixedColumns?.length || 0) - 1 === index
              const isCellNonClickable = nonClickableCells?.includes(key)

              return (
                <TableCell
                  key={cellKey}
                  isSelected={selectedCellId === cellKey}
                  isFixed={fixedColumns?.includes(key)}
                  isFixedLast={isFixedLast}
                  cellClasses={cellClassesMemo}
                  isNumber={inNumber}
                  isRightSpace={isRightSpace}
                  isTwiceCell={isTwiceCell}
                  isCellNonClickable={isCellNonClickable}
                  onClick={onCellClick && !isCellNonClickable ? cellClickMemo(key) : undefined}
                >
                  {conditionallyRenderCell
                    ? conditionallyRenderCell({
                        key,
                        value: item[key],
                        row: item,
                        index: rowIndex,
                        isExpanded: isCollapseOpen,
                      })
                    : (item[key] as ReactElement | string | number | null)}
                </TableCell>
              )
            })}
            {renderLastEmptyCell}
          </>
        ) : null}
      </TableRow>
      {isCollapseOpen && showCollapseIcon && collapseRowRender
        ? collapseRowRender({
            fixedKeys: fixedColumns,
            keys,
            row: item,
            classes: {
              collapseRow: clsx(classes.collapseRow, {
                [classes.selectedRow]: isSelected && rowClickMemo,
                stylesForSpecificRowsMemo,
              }),
              tableCell: tableClasses?.cell || '',
              fixed: commonClasses.fixed,
              fixedLast: commonClasses.fixedLast,
              customFixed: tableClasses?.fixed,
              borderCell: borderCellClasses || '',
              lastElementBorder: classes.lastElementBorder,
            },
            isCheckboxOnRow: !!handleCheckRow,
            numericCells: cellWithNumberValue || [],
            columnsToSort: columnsToSort || [],
          })
        : null}
    </>
  )
})
