import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ zIndex }) => ({
  hoverKeeper: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: zIndex.modal,
  },
}))
