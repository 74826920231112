// actions
import { ReturnAnyActionsUSM } from '@/store/usm/types/types'
import { actionUserGroupUSM } from '@/store/usm/userGroup/actions'
import { getTreeData } from '@/store/usm/userGroup/utils/getTreeData'
// types
import { ResponseError } from '@/types'
import { ResponseErrorTypes } from '@/types/common/ErrorResponseTypes'
import {
  CurrentUserGroupTypes,
  ObjectPermissionsTree,
  UserGroupType,
} from '@/types/usm/userGroup/userGroup'

export const initialState = {
  // list-all-user-group
  listAllUserGroups: [] as Array<UserGroupType>,
  isFetchingAllUserGroups: false,
  // current-user-group
  isFetchingGetCurrentUserGroup: false,

  currentUserGroup: [] as Array<CurrentUserGroupTypes>,

  // object-tree-permissions
  isFetchingObjectTree: false,
  objectTreePermissions: [] as Array<ObjectPermissionsTree>,
  // error
  // error: null as ResponseError | null | string,
  error: null as ResponseErrorTypes | null,
  isAddedUserGroup: false,
}

export type State = typeof initialState

export type UserGroupAnyActions = ReturnAnyActionsUSM<typeof actionUserGroupUSM>
export const reducer = (state: State = initialState, action: UserGroupAnyActions): State => {
  switch (action.type) {
    // set-all-user-group
    case '@/START_FETCHING_GET_ALL_USER_GROUP_USM':
      return {
        ...state,
        isFetchingAllUserGroups: true,
      }
    case '@/STOP_FETCHING_GET_ALL_USER_GROUP_USM':
      return {
        ...state,
        isFetchingAllUserGroups: false,
      }

    case '@/SET_ALL_USER_GROUP_RESPONSE_USM':
      return {
        ...state,
        error: null,
        listAllUserGroups: action.payload,
      }
    case '@/SET_ALL_USER_GROUP_RESPONSE_FALL_USM':
      return {
        ...state,
        error: action.payload,
      }
    case '@/GET_ALL_USER_GROUP_ASYNC_USM':
      return state
    // add-new-user-group
    case '@/START_FETCHING_ADD_GROUP_USM':
      return {
        ...state,
        isFetchingObjectTree: true,
      }
    case '@/STOP_FETCHING_ADD_GROUP_USM':
      return {
        ...state,
        isFetchingObjectTree: false,
      }
    case '@/SET_ADD_GROUP_RESPONSE_USM': {
      const { userGroup, permissions } = action.payload
      return {
        ...state,
        error:
          state.currentUserGroup.length === 20
            ? {
                errorMessage: 'max 20 elements',
                fieldErrors: null,
                globalErrors: null,
                timestamp: '',
              }
            : null,

        currentUserGroup: [
          ...state.currentUserGroup,
          {
            userGroupName: userGroup.userGroup,
            userGroupId: userGroup.userGroupId,
            userGroup: userGroup,
            permissions: permissions,
            description: userGroup.description,
          },
        ],

        listAllUserGroups: [...state.listAllUserGroups, action.payload.userGroup],
        isAddedUserGroup: true,
      }
    }
    case '@/SET_ADD_GROUP_RESPONSE_FALL_USM':
      return {
        ...state,
        error: action.payload,
        isAddedUserGroup: false,
      }
    case '@/ADD_GROUP_ASYNC_USM':
      return {
        ...state,
        isAddedUserGroup: false,
      }
    case '@/START_FETCHING_OBJECT_TREE_PERMISSIONS_USM':
      return {
        ...state,
        isFetchingObjectTree: true,
      }
    case '@/STOP_FETCHING_OBJECT_TREE_PERMISSIONS_USM':
      return {
        ...state,
        isFetchingObjectTree: false,
      }
    case '@/SET_OBJECT_TREE_PERMISSIONS_RESPONSE_USM':
      return {
        ...state,
        error: null,
        objectTreePermissions: getTreeData(action.payload),
      }
    case '@/SET_OBJECT_TREE_PERMISSIONS_RESPONSE_FALL_USM':
      return {
        ...state,
        error: action.payload,
      }
    case '@/OBJECT_TREE_PERMISSIONS_ASYNC_USM':
      return state
    // current-user-group
    case '@/START_FETCHING_GET_CURRENT__USER_GROUP_USM':
      return {
        ...state,
        isFetchingObjectTree: true,
      }
    case '@/STOP_FETCHING_GET_CURRENT__USER_GROUP_USM':
      return {
        ...state,
        isFetchingObjectTree: false,
      }
    case '@/SET_CURRENT_USER_GROUP_RESPONSE_USM': {
      const { userGroup, permissions } = action.payload

      return {
        ...state,
        error:
          state.currentUserGroup.length === 20
            ? {
                errorMessage: 'max 20 elements',
                fieldErrors: null,
                globalErrors: null,
                timestamp: '',
              }
            : null,
        currentUserGroup:
          state.currentUserGroup.length === 20
            ? state.currentUserGroup
            : [
                ...state.currentUserGroup.filter(
                  u => u.userGroupId !== action.payload.userGroup.userGroupId,
                ),
                {
                  userGroupName: userGroup.userGroup,
                  userGroupId: userGroup.userGroupId,
                  userGroup: userGroup,
                  permissions: permissions,
                  description: userGroup.description,
                },
              ],
      }
    }
    case '@/SET_CURRENT_USER_GROUP_RESPONSE_FALL_USM':
      return {
        ...state,
        error: action.payload,
      }
    case '@/GET_CURRENT_USER_GROUP_ASYNC_USM':
      return state
    case '@/SET_FILTER_USER_GROUP_LIST_USM':
      return {
        ...state,
        currentUserGroup: state.currentUserGroup.filter(u => u.userGroupId !== action.payload),
      }
    // update-user-group
    case '@/START_FETCHING_UPDATE_USER_GROUPS_USM':
      return {
        ...state,
        isFetchingObjectTree: true,
      }
    case '@/STOP_FETCHING_UPDATE_USER_GROUPS_USM':
      return {
        ...state,
        isFetchingObjectTree: false,
      }
    case '@/FILL_UPDATE_USER_GROUPS_RESPONSE_USM': {
      const userGroupsIdArray = action.payload.map(i => i.userGroup.userGroupId)

      action.payload.forEach(group => {
        const updatedGroup = state.currentUserGroup.find(
          i => i.userGroupId === group.userGroup.userGroupId,
        )

        if (updatedGroup) {
          updatedGroup.userGroupName = group.userGroup.userGroup
          updatedGroup.userGroupId = group.userGroup.userGroupId
          updatedGroup.userGroup = group.userGroup
          updatedGroup.permissions = group.permissions
          updatedGroup.description = group.userGroup.description
        }
      })

      return {
        ...state,
        listAllUserGroups: [
          ...state.listAllUserGroups.filter(u => !userGroupsIdArray.includes(u.userGroupId)),
          action.payload.map(el => el.userGroup),
        ].flat(),
      }
    }
    case '@/FILL_UPDATE_USER_GROUPS_RESPONSE_FALL_USM':
      return {
        ...state,
        error: action.payload,
      }
    case '@/GET_UPDATE_USER_GROUPS_ASYNC_USM':
      return state
    case '@/START_FETCHING_DELETE_USER_GROUP_BY_ID_USM':
      return {
        ...state,
        isFetchingObjectTree: true,
      }
    case '@/STOP_FETCHING_DELETE_USER_GROUP_BY_ID_USM':
      return {
        ...state,
        isFetchingObjectTree: false,
      }
    case '@/FILL_DELETE_USER_GROUP_BY_ID_RESPONSE_USM':
      return {
        ...state,
        error: null,
        currentUserGroup: state.currentUserGroup.filter(u => u.userGroupId !== action.payload),
        listAllUserGroups: state.listAllUserGroups.filter(u => u.userGroupId !== action.payload),
      }
    case '@/SET_DELETE_USER_GROUP_BY_ID_RESPONSE__FALL_USM':
      return {
        ...state,
        error: action.payload,
      }
    case '@/DELETE_USER_GROUP_BY_ID_ASYNC':
      return state
    case '@/SET_CLEAR_ERROR_USERS_GROUP_USM':
      return {
        ...state,
        error: null,
      }
    case '@/CLEAR_ALL_SELECTED_USER_GROUP_ASYNC_USM':
      return {
        ...state,
        currentUserGroup: [],
      }
    default:
      // eslint-disable-next-line no-case-declarations,@typescript-eslint/no-unused-vars
      const neverCase: never = action
  }
  return state
}
