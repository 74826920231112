import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import clsx from 'clsx'
import { get } from 'lodash-es'
import React, { ReactElement, useCallback } from 'react'
import { useSelector } from 'react-redux'

import Loader from '@/components/blocks/Loader'
import Button from '@/components/controls/Button'
import { Checkbox } from '@/components/controls/Checkbox'
import { TextInput } from '@/components/controls/TextInput'
import { timeExp } from '@/constants'
import { getIsFetchingSchedule } from '@/store/adm/device/selectors'
import { Day, ScheduleFormValues } from '@/types/adm/device/local'
import { ScheduleFieldName } from '@/types/adm/device/names'
import { useTranslate } from '@/utils/internalization'

import {
  validateTimeFrom2,
  validateTimeTo1,
  validateTimeTo2,
} from '../../../DeviceCardPage/components/ScheduleForm/validateDate'
import { getScheduleValues } from './schedule-values'
import { useClasses } from './styles'
import { Props } from './types'

const ScheduleForm = ({ formik, fullWidth, parentName }: Props): ReactElement => {
  const isLoading = useSelector(getIsFetchingSchedule)

  const translate = useTranslate()
  const classes = useClasses()

  const timePattern = new RegExp(timeExp)

  const handleClear = (): void => {
    const cleanSchedule = getScheduleValues(
      parentName ? get(formik.values, parentName) : formik.values,
      'clean',
    )

    if (parentName) formik.setFieldValue(parentName, cleanSchedule)
    else formik.setValues(cleanSchedule)
  }

  const handleSetFrom24To7 = (): void => {
    const from24To7 = getScheduleValues(
      parentName ? get(formik.values, parentName) : formik.values,
      'from24To7',
    )

    if (parentName) formik.setFieldValue(parentName, from24To7)
    else formik.setValues(from24To7)
  }

  const validateTimeLocal = useCallback(
    (day: Day, callback): boolean => {
      return callback(day)
    },
    [formik.values],
  )

  return (
    <Grid>
      <Grid className={classes.header}>
        <Typography variant="h5" className={classes.title}>
          {translate('translate#adm.schedule')}
        </Typography>
        <Grid>
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            width="xs"
            className={clsx(classes.button, classes.removeButton)}
            onClick={handleClear}
          >
            {translate('translate#adm.clearAll')}
          </Button>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            width="xs"
            className={classes.button}
            onClick={handleSetFrom24To7}
          >
            {translate('translate#adm.set24-7')}
          </Button>
        </Grid>
      </Grid>
      <Grid className={classes.grid} style={fullWidth ? { width: '100%' } : undefined}>
        {Object.keys(parentName ? get(formik.values, parentName) : formik.values).map(key => (
          <Grid key={key} className={classes.row}>
            <Grid className={classes.checkbox}>
              <Checkbox
                label={translate(`translate#adm.${key}`)}
                name={
                  parentName
                    ? `${parentName}[${key}][${ScheduleFieldName.IsActive}]`
                    : `${key}[${ScheduleFieldName.IsActive}]`
                }
                value={
                  parentName
                    ? get(formik.values, `${parentName}[${key}].isActive`)
                    : formik.values[key as keyof ScheduleFormValues].isActive
                }
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid>
              <TextInput
                label={translate('translate#adm.from')}
                pattern={timePattern}
                name={
                  parentName
                    ? `${parentName}[${key}][${ScheduleFieldName.TimeFrom1}]`
                    : `${key}[${ScheduleFieldName.TimeFrom1}]`
                }
                value={
                  parentName
                    ? get(formik.values, `${parentName}[${key}].timeFrom1`)
                    : formik.values[key as keyof ScheduleFormValues].timeFrom1
                }
                onChange={formik.handleChange}
                fullWidth
                disabled={
                  parentName
                    ? !get(formik.values, `${parentName}[${key}].isActive`)
                    : !formik.values[key as keyof ScheduleFormValues].isActive
                }
                error={
                  !!formik.errors[key as keyof ScheduleFormValues] &&
                  !!formik.errors[key as keyof ScheduleFormValues]?.timeFrom1
                }
                errorText={
                  formik.errors[key as keyof ScheduleFormValues] &&
                  formik.errors[key as keyof ScheduleFormValues]?.timeFrom1
                }
                customClasses={{
                  errorText: classes.errorText,
                }}
              />
            </Grid>
            <Grid>
              <TextInput
                label={translate('translate#adm.to')}
                pattern={timePattern}
                name={
                  parentName
                    ? `${parentName}[${key}][${ScheduleFieldName.TimeTo1}]`
                    : `${key}[${ScheduleFieldName.TimeTo1}]`
                }
                value={
                  parentName
                    ? get(formik.values, `${parentName}[${key}].timeTo1`)
                    : formik.values[key as keyof ScheduleFormValues].timeTo1
                }
                onChange={formik.handleChange}
                fullWidth
                disabled={
                  parentName
                    ? !get(formik.values, `${parentName}[${key}].isActive`)
                    : !formik.values[key as keyof ScheduleFormValues].isActive
                }
                error={
                  !validateTimeLocal(get(formik.values, `${parentName}[${key}]`), validateTimeTo1)
                }
                errorText={
                  formik.errors[key as keyof ScheduleFormValues] &&
                  formik.errors[key as keyof ScheduleFormValues]?.timeTo1
                }
                customClasses={{
                  errorText: classes.errorText,
                }}
              />
            </Grid>
            <Grid className={classes.deviderWrapper}>
              <div className={classes.devider} />
            </Grid>
            <Grid>
              <TextInput
                label={translate('translate#adm.from')}
                pattern={timePattern}
                name={
                  parentName
                    ? `${parentName}[${key}][${ScheduleFieldName.TimeFrom2}]`
                    : `${key}[${ScheduleFieldName.TimeFrom2}]`
                }
                value={
                  parentName
                    ? get(formik.values, `${parentName}[${key}].timeFrom2`)
                    : formik.values[key as keyof ScheduleFormValues].timeFrom2
                }
                onChange={formik.handleChange}
                fullWidth
                disabled={
                  parentName
                    ? !get(formik.values, `${parentName}[${key}].isActive`)
                    : !formik.values[key as keyof ScheduleFormValues].isActive
                }
                error={
                  !validateTimeLocal(get(formik.values, `${parentName}[${key}]`), validateTimeFrom2)
                }
                errorText={
                  formik.errors[key as keyof ScheduleFormValues] &&
                  formik.errors[key as keyof ScheduleFormValues]?.timeFrom2
                }
                customClasses={{
                  errorText: classes.errorText,
                }}
              />
            </Grid>
            <Grid>
              <TextInput
                label={translate('translate#adm.to')}
                pattern={timePattern}
                name={
                  parentName
                    ? `${parentName}[${key}][${ScheduleFieldName.TimeTo2}]`
                    : `${key}[${ScheduleFieldName.TimeTo2}]`
                }
                value={
                  parentName
                    ? get(formik.values, `${parentName}[${key}].timeTo2`)
                    : formik.values[key as keyof ScheduleFormValues].timeTo2
                }
                onChange={formik.handleChange}
                fullWidth
                disabled={
                  parentName
                    ? !get(formik.values, `${parentName}[${key}].isActive`)
                    : !formik.values[key as keyof ScheduleFormValues].isActive
                }
                error={
                  !validateTimeLocal(get(formik.values, `${parentName}[${key}]`), validateTimeTo2)
                }
                errorText={
                  formik.errors[key as keyof ScheduleFormValues] &&
                  formik.errors[key as keyof ScheduleFormValues]?.timeTo2
                }
                customClasses={{
                  errorText: classes.errorText,
                }}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
      {isLoading && (
        <Grid className={classes.loaderWrapper}>
          <Loader />
        </Grid>
      )}
    </Grid>
  )
}

export default ScheduleForm
