import { makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles({
  closeButton: {
    fontSize: '14px',
    position: 'absolute',
    top: 2,
    right: 2,
    color: 'white',
    cursor: 'pointer',
  },

  itemContainer: {
    display: 'flex',
    borderRadius: '6px',
    height: 30,
    padding: '0 20px 0 10px',
    marginRight: '20px',
    fontSize: '14px',
    alignItems: 'center',
    fontWeight: 400,
    color: 'white',
  },

  notificationColors: {
    backgroundColor: '#FBC91B',
  },
  warningColors: {
    backgroundColor: '#f59184',
  },
  alarmColors: {
    backgroundColor: '#F16452',
  },
})
