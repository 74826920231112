import React, { ReactElement, useMemo } from 'react'
import { Field as FormikField } from 'formik'
import { Props, FilterTypeDisplayName } from './types'
import { useClasses } from '@/components/pages/sd/reports/components/ReportsModal/components/ReportModalForm/styles'
import { createDatePickerField } from '@/components/controls/DateInput'

const DynamicDateFields = ({
  params: {
    __required,
    name,
    __controltype,
    frendlyName,
    mandatory,
    startOfRangeParameter,
    endOfRangeParameter,
  },
  dynamicDateValue,
  setDynamicDate,
  disabled = false,
}: Props): ReactElement => {
  const classes = useClasses({})

  const Field = useMemo(() => {
    switch (__controltype) {
      case FilterTypeDisplayName.DATE: {
        return createDatePickerField({
          label: frendlyName,
          wrapperDateClass: classes.wrapperDateBlock,
          widthClass: classes.widthClass,
          disabled,
          withoutCurrentDate: false,
          disableFutureDates: startOfRangeParameter,
          required: __required || mandatory,
          disabledPast: endOfRangeParameter,
          withoutArrayDate: true,
          dynamicDateValue,
          setDynamicDate,
        })
      }
      default:
        return null
    }
  }, [__controltype, __required, disabled, frendlyName, dynamicDateValue])

  return <FormikField name={name}>{Field}</FormikField>
}

export default DynamicDateFields
