import { AnyAction } from 'redux'

import { AdmAutomatedOperation } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants/actions/cmActions'

// get
export const getAutoOperationsRequest = (): AnyAction => ({
  type: CMAppConfigActions.AdmGetAutoOperationsRequest,
})
export const getAutoOperationsResponse = (payload: AdmAutomatedOperation[]): AnyAction => ({
  type: CMAppConfigActions.AdmGetAutoOperationsResponse,
  payload,
})
export const getAutoOperationsFail = (): AnyAction => ({
  type: CMAppConfigActions.AdmGetAutoOperationsFail,
})

// edit
export const updateAutoOperationsRequest = (): AnyAction => ({
  type: CMAppConfigActions.AdmUpdateAutoOperationsRequest,
})
export const updateAutoOperationsFail = (): AnyAction => ({
  type: CMAppConfigActions.AdmUpdateAutoOperationsFail,
})
