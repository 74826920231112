import React, { FC, useCallback, MouseEvent } from 'react'
import { debounce } from 'lodash-es'
import { Button, ButtonProps } from '@material-ui/core'

export const DebounceButton: FC<ButtonProps> = React.memo((props: ButtonProps) => {
  const { onClick, ...rest } = props

  const debounceOnClick = useCallback(
    (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      onClick && onClick(event)
    },
    [onClick],
  )

  return (
    <Button onClick={debounce(debounceOnClick, 1000, { leading: true, trailing: false })} {...rest}>
      {props.children}
    </Button>
  )
})
