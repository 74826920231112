import makeStyles from '@material-ui/core/styles/makeStyles'
interface Props {
  width?: string
}

export const useClasses = makeStyles(({ palette }) => ({
  sameAddress: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'fit-content',
  },
  errorText: {
    color: palette.error.main,
  },

  disabled: {
    cursor: 'default',
    opacity: '0.5',
  },
}))
