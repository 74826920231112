import RadioGroup from '@material-ui/core/RadioGroup'
import clsx from 'clsx'
import { FieldProps } from 'formik'
import React, { ChangeEvent, ReactElement, useEffect } from 'react'

import RadioButton from '@/components/controls/Radio/component'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import {
  Props,
  RadioGroupObjectValues,
  RadioGroupPropsFormikField,
  YesNoAllRadioGroupProps,
} from './types'

export enum YesNoAllRadioGroupValues {
  Yes = '1',
  No = '0',
  All = '',
}

const RadioButtonsGroup: React.FC<Props> = ({
  disabled,
  value,
  onChange,
  children,
  className,
  name,
}): ReactElement => {
  const classes = useClasses()

  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      className={clsx(disabled && classes.disabled, className)}
      name={name}
    >
      {children}
    </RadioGroup>
  )
}

export default RadioButtonsGroup

export const createRadioGroupFormikField = ({
  label,
  isRequired,
  options,
  disabled,
  checked,
  isColumn,
  classPadding,
  className,
  onChange,
  defaultValue,
  styles,
}: RadioGroupPropsFormikField): ((field: FieldProps) => React.ReactElement) => ({
  field: { value, name },
  form: { setFieldValue },
}): React.ReactElement => {
  const translate = useTranslate()
  const classes = useClasses()

  useEffect(() => {
    defaultValue && setFieldValue(name, defaultValue)
  }, [defaultValue])

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(name, e.target.value)
    onChange && onChange(e.target.value)
  }

  const val = typeof options[0].value === 'string' ? String(value) : value

  return (
    <div className={clsx(classes.wrapper, styles?.wrapper)}>
      {label && <span>{label}</span>}
      <RadioButtonsGroup
        value={val}
        onChange={handleChange}
        className={clsx(classes.radioGroup, className, {
          [classes.radioGroupVertical]: isColumn,
        })}
      >
        {options.map(
          (i: RadioGroupObjectValues, index): React.ReactElement => (
            <RadioButton
              key={i.value}
              value={i.value}
              label={i.label || translate(`translate#title.${i.value}`)}
              disabled={disabled}
              checked={checked}
              isItemChecked={i.checked}
              classPadding={classPadding}
              onChange={handleChange}
            />
          ),
        )}
      </RadioButtonsGroup>
    </div>
  )
}

export const createYesNoAllRadioGroupField = ({
  title,
  currentValue,
  disabled,
  checked,
  className,
}: YesNoAllRadioGroupProps): ((field: FieldProps) => React.ReactElement) => ({
  field: { value, name },
  form: { setFieldValue },
}): React.ReactElement => {
  const translate = useTranslate()
  const classes = useClasses()

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(name, e.target.value)
  }
  return (
    <div className={className || classes.yesNoAllWrapper}>
      <span className={classes.radioGroupTitle}>{title}</span>
      <RadioButtonsGroup value={value} onChange={onChange} className={classes.yesNoAllRadioGroup}>
        <RadioButton
          value={currentValue ? currentValue.period : YesNoAllRadioGroupValues.Yes}
          label={
            currentValue ? translate('translate#action.period') : translate(`translate#action.yes`)
          }
          disabled={disabled}
          checked={checked}
        />
        <RadioButton
          value={currentValue ? currentValue.week : YesNoAllRadioGroupValues.No}
          label={
            currentValue ? translate('translate#action.week') : translate(`translate#action.no`)
          }
          disabled={disabled}
          checked={checked}
        />
        <RadioButton
          value={currentValue ? currentValue.month : YesNoAllRadioGroupValues.All}
          label={
            currentValue ? translate('translate#action.month') : translate(`translate#title.all`)
          }
          disabled={disabled}
          checked={checked}
        />
      </RadioButtonsGroup>
    </div>
  )
}
