import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromATMEyeDashboard from './reducer'

const getATMEyeDashboardState = (state: AppState): fromATMEyeDashboard.State =>
  state.atmEyeDashboard

export const getWidgetHeader = createSelector(
  getATMEyeDashboardState,
  state => state.headerWidgetData,
)
export const getIsFetchingWidgetHeader = createSelector(
  getATMEyeDashboardState,
  state => state.isFetchingHeaderData,
)
export const getSubPageState = createSelector(getATMEyeDashboardState, state => [state.subPages])
export const getSubPage = createSelector(getATMEyeDashboardState, state => state.subPages)
export const getFetchingSubModalPage = createSelector(
  getATMEyeDashboardState,
  state => state.isFetchingSubpages,
)

export const getCurrentSubPage = createSelector(
  getATMEyeDashboardState,
  state => state.currentSubPagesData,
)
export const getError = createSelector(getATMEyeDashboardState, state => state.error)

export const getSubPageWidgets = createSelector(
  getATMEyeDashboardState,
  state => state.subPageWidgets,
)

export const getIsFetchingFilterWidgetData = createSelector(
  getATMEyeDashboardState,
  state => state.isFetchingFilterWidgetData,
)

export const getCurrentFilterData = createSelector(
  getATMEyeDashboardState,
  state => state.currentFilterData,
)

export const getIsFetchingDeleteSubPagesWidgets = createSelector(
  getATMEyeDashboardState,
  state => state.isFetchingDeleteSubPagesWidgets,
)

export const getTriggerResetForm = createSelector(
  getATMEyeDashboardState,
  state => state.triggerResetForm,
)

export const getIsFetchingPageWidgetData = createSelector(
  getATMEyeDashboardState,
  state => state.isFetchingPageWidgetData,
)

export const getDevicesViewData = createSelector(getATMEyeDashboardState, state => (id: number) =>
  state.devicesViewData.find(item => item.widgetId === id) || null,
)

export const getEventsViewData = createSelector(getATMEyeDashboardState, state => (id: number) =>
  state.eventsViewData.find(item => item.widgetId === id) || null,
)

export const getViewDataLoading = createSelector(
  getATMEyeDashboardState,
  state => state.isFetchingWidgetViewData,
)

export const getViewDataIdsToFetch = createSelector(
  getATMEyeDashboardState,
  state => state.viewDataIdsToFetch,
)

export const getClonedPageName = createSelector(
  getATMEyeDashboardState,
  state => state.clonedPageName,
)
