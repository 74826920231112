import React, { useCallback } from 'react'
import { Grid } from '@material-ui/core'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@/components/controls/Button/index'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { useClasses } from './styles'
import clsx from 'clsx'
import { Props } from './types'
import { FormattedMessage } from 'react-intl'
import Test from 'react-test-attributes'
import { getTestId } from '@/utils'
import Tooltip from '@/components/blocks/Tooltip/component'
import { useTranslate } from '@/utils/internalization'

export const SortBlock = ({
  testIdPrefix,
  groupKey,
  group,
  setGroup,
  disabled,
}: Props): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const idPrefix = `${testIdPrefix}-sort-block`
  const testId = getTestId(idPrefix)
  const onSortSelect = useCallback(
    (orderBy: 'name' | 'count', order: 'asc' | 'desc'): (() => void) => (): void => {
      if (!disabled) {
        setGroup({ key: groupKey, orderBy, order })
      }
    },
    [disabled, groupKey, setGroup],
  )

  return (
    <Grid container direction="column">
      <Grid
        container
        className={clsx({
          [classes.disabled]: disabled || (group && (!group.orderBy || group.orderBy !== 'name')),
        })}
      >
        <Test id={testId(0)}>
          <ButtonGroup disableRipple size="small">
            <Test id={testId(1)}>
              <Button
                onClick={onSortSelect('name', 'asc')}
                className={clsx(classes.sortButton, {
                  [classes.sortButtonActive]:
                    !disabled && group && group.orderBy === 'name' && group.order === 'asc',
                })}
                variant="outlined"
              >
                <Tooltip title={translate('translate#title.sort.name.asc')}>
                  <ExpandLess color="inherit" fontSize="small" />
                </Tooltip>
              </Button>
            </Test>
            <Test id={testId(2)}>
              <Button
                onClick={onSortSelect('name', 'desc')}
                className={clsx(
                  classes.sortButton,
                  {
                    [classes.sortButtonActive]:
                      !disabled && group && group.orderBy === 'name' && group.order === 'desc',
                  },
                  classes.mr,
                )}
                variant="outlined"
              >
                <Tooltip title={translate('translate#title.sort.name.desc')}>
                  <ExpandMore color="inherit" fontSize="small" />
                </Tooltip>
              </Button>
            </Test>
          </ButtonGroup>
        </Test>
        <Test id={testId(3)}>
          <span>
            <FormattedMessage id="title.byName" defaultMessage="by name" />
          </span>
        </Test>
      </Grid>
      <Grid
        container
        className={clsx({
          [classes.disabled]: disabled || (group && (!group.orderBy || group.orderBy !== 'count')),
        })}
      >
        <Test id={testId(4)}>
          <ButtonGroup disableRipple size="small">
            <Test id={testId(5)}>
              <Button
                onClick={onSortSelect('count', 'asc')}
                className={clsx(classes.sortButton, {
                  [classes.sortButtonActive]:
                    !disabled && group && group.orderBy === 'count' && group.order === 'asc',
                })}
                variant="outlined"
              >
                <Tooltip title={translate('translate#title.sort.count.asc')}>
                  <ExpandLess color="inherit" fontSize="small" />
                </Tooltip>
              </Button>
            </Test>
            <Test id={testId(6)}>
              <Button
                onClick={onSortSelect('count', 'desc')}
                className={clsx(
                  classes.sortButton,
                  {
                    [classes.sortButtonActive]:
                      !disabled && group && group.orderBy === 'count' && group.order === 'desc',
                  },
                  classes.mr,
                )}
                variant="outlined"
              >
                <Tooltip title={translate('translate#title.sort.count.desc')}>
                  <ExpandMore color="inherit" fontSize="small" />
                </Tooltip>
              </Button>
            </Test>
          </ButtonGroup>
        </Test>
        <Test id={testId(7)}>
          <span>
            <FormattedMessage id="title.byCount" defaultMessage="by count" />
          </span>
        </Test>
      </Grid>
    </Grid>
  )
}
