import Typography from '@material-ui/core/Typography'
import React, { ReactElement, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import FiltersRow from '@/components/blocks/FiltersRow'
import { TableFilter } from '@/components/controls/AppTable/types'
import TableTree from '@/components/pages/sd/controls/TableTree'
import { TableGroup } from '@/components/pages/sd/TicketsPage/components/GroupingModal/types'
import TableHeader from '@/components/pages/sd/TicketsPage/components/TableHeader'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { getCurrentAccount } from '@/store/auth/selectors'
import { activeLocale } from '@/store/internalization/selectors'
import { setHistoryTicketsFiltersModalState } from '@/store/sd/modals'
import {
  changeSelectedFilters,
  changeSorting,
  getFilterTemplateFields,
  getSelectedFilters,
  getStringFilter,
  getTicketsConfigData,
  getTicketSorting,
  getTicketsPage,
  getTicketsPageCount,
  getTicketsRequest,
  getTicketsRowsPerPage,
  getTicketsTotalCount,
  isFiltersSelected,
  setCurrentFilterTemplate,
  setStringFilter,
  setTicketsPage,
  setTicketsRowsPerPage,
} from '@/store/sd/tickets'
import { TypeOfTicket } from '@/types'
import { TableSettings } from '@/types/common/localStorageData'
import { useTranslate } from '@/utils/internalization'
import { getTableSettings, setTableSettings } from '@/utils/localStorage'

import { useClasses } from './styles'
import { Props } from './types'

export const TICKET_PAGE_TICKET_TABLE = 'TicketsTable'

const TicketsTable = ({
  isLoading,
  data,
  ticketsConfig,
  typeOfTickets,
  idPrefix,
  testId,
  handleSetGrouping,
  handleSetFiltersModal,
  ticketClick,
  posTicketClick,
}: Props): ReactElement => {
  const tableId = TICKET_PAGE_TICKET_TABLE

  const REQUEST_BODY_LS_KEY = 'ticketsConfig'
  const account = useSelector(getCurrentAccount)
  const localizing = useSelector(activeLocale)
  const totalPage = useSelector(getTicketsPageCount)
  const ticketsTotalCount = useSelector(getTicketsTotalCount)
  const page = useSelector(getTicketsPage)
  const rowsPerPage = useSelector(getTicketsRowsPerPage)
  const stringFilter: any = useSelector(getStringFilter)
  const isFilters = useSelector(isFiltersSelected)
  const sorting = useSelector(getTicketSorting)
  const [columnsOrderTemplate, setColumnsOrderTemplate] = useState<string[]>([])
  const [columnsOrder, setColumnsOrder] = useState<string[]>([])
  const [columns, setColumns] = useState<string[]>([])
  const [columnsMenuAnchorEl, setColumnsMenuAnchorEl] = useState<
    null | (EventTarget & HTMLButtonElement)
  >(null)
  const filtersSelected = useSelector(isFiltersSelected)
  const selectedFilter = useSelector(getSelectedFilters)
  const TicketsConfigData = useSelector(getTicketsConfigData)

  const classes = useClasses()
  const dispatch = useDispatch()
  const translate = useTranslate()

  const setTableSettingsToLocalStorage = useCallback(
    (newColumns: string[], newColumnsTemplate: string[]) => {
      if (account) {
        const savedSettings = getTableSettings(
          LocalStorageItems.UserSettings,
          account.userId.toString(),
          `${tableId}/${typeOfTickets}`,
        )

        const settings: TableSettings = {
          [`${tableId}/${typeOfTickets}`]: {
            ...(savedSettings && { ...savedSettings }),
            localizing,
            columns: newColumns.map(column =>
              ticketsConfig.outputFields.findIndex(({ fieldLabel }) => column === fieldLabel),
            ),
            columnsTemplate: newColumnsTemplate.map(column =>
              ticketsConfig.outputFields.findIndex(({ fieldLabel }) => column === fieldLabel),
            ),
          },
        }

        setTableSettings(LocalStorageItems.UserSettings, account.userId.toString(), settings)
      }
    },
    [tableId, typeOfTickets, account, localizing, ticketsConfig],
  )

  const handleColumnsChange = useCallback((fields: string[]): void => {
    setColumns(fields)
  }, [])

  const handleTableParamsChange = useCallback(
    (
      param: 'filter' | 'sort' | 'pagination' | 'group',
      value:
        | TableFilter
        | { [key: string]: 'asc' | 'desc' }
        | null
        | { rowsAmount: number; position: number }
        | TableGroup[],
    ): void => {
      if (param === 'sort') {
        dispatch(changeSorting(value as { [key: string]: 'asc' | 'desc' } | null))
        dispatch(setTicketsPage(1))
        dispatch(getTicketsRequest())
      }
      if (param === 'filter' && value) {
        dispatch(changeSelectedFilters(value))
        dispatch(getTicketsRequest())
      }
    },
    [dispatch],
  )

  const handleChangeTicketsPage = useCallback(
    (page: number) => {
      dispatch(setTicketsPage(page))
      dispatch(getTicketsRequest())
    },
    [dispatch],
  )

  const handleChangeTicketsRowsCount = useCallback(
    (count: number) => {
      dispatch(setTicketsRowsPerPage(count))
      dispatch(setTicketsPage(1))
      dispatch(getTicketsRequest())
    },
    [dispatch],
  )

  const onFilterChange = useCallback(
    (filter: TableFilter) => {
      dispatch(setStringFilter(filter))
    },
    [dispatch],
  )

  const handleShowColumnsMenu = useCallback((event: SyntheticEvent<HTMLButtonElement>): void => {
    setColumnsMenuAnchorEl(event.currentTarget)
  }, [])

  const handleCloseViewSettings = useCallback((): void => {
    setColumnsMenuAnchorEl(null)
  }, [])

  useEffect(() => {
    if (account) {
      const savedSettings = getTableSettings(
        LocalStorageItems.UserSettings,
        account.userId.toString(),
        `${tableId}/${typeOfTickets}`,
      )

      if (savedSettings && savedSettings.ticketsConfig) {
        dispatch(setTicketsPage(savedSettings.ticketsConfig.page))
      }
    }
  }, [account, ticketsConfig, dispatch])

  return (
    <>
      <TableTree
        tableId={`${tableId}/${typeOfTickets}`}
        columnsOrderTemplate={columnsOrderTemplate}
        columnsOrder={columnsOrder}
        columns={columns}
        incomingHeaders={ticketsConfig.outputFields}
        data={data}
        totalPage={totalPage || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        ticketsTotalCount={ticketsTotalCount}
        filter={stringFilter}
        sorting={sorting}
        tableHeader={
          <div className={classes.tableHeader}>
            <Typography variant="h5" className={classes.tableTitle}>
              <FormattedMessage id={`title.${typeOfTickets}`} />
              {typeOfTickets === TypeOfTicket.HistoryTickets && !filtersSelected ? (
                <span className={classes.tableTitleExplanation}>
                  {` `}({translate('translate#title.byDefaultShowingLastMonth')})
                </span>
              ) : (
                <></>
              )}
            </Typography>
            <TableHeader
              testId={testId}
              handleSetGrouping={handleSetGrouping}
              handleSetFiltersModal={handleSetFiltersModal}
              handleShowColumnsMenu={handleShowColumnsMenu}
              idPrefix={idPrefix}
              columns={columnsOrderTemplate}
              keys={columns}
              columnsMenuAnchorEl={columnsMenuAnchorEl}
              setTableSettingsToLocalStorage={setTableSettingsToLocalStorage}
              handleColumnsChange={handleColumnsChange}
              handleCloseViewSettings={handleCloseViewSettings}
            />
            {isFilters && (
              <FiltersRow
                tableId={`${tableId}/${typeOfTickets}`}
                typeOfTickets={typeOfTickets}
                changeSelectedFilters={changeSelectedFilters}
                getSelectedFilters={selectedFilter}
                isFiltersSelected={filtersSelected}
                getTicketsConfigData={TicketsConfigData}
                getTicketsRequest={getTicketsRequest}
                setTicketsPage={setTicketsPage}
                setHistoryTicketsFiltersModalState={setHistoryTicketsFiltersModalState}
                getFilterTemplateFields={getFilterTemplateFields}
                getRequestBodyLsKey={REQUEST_BODY_LS_KEY}
                setCurrentFilterTemplate={setCurrentFilterTemplate}
                groupTableId={tableId}
              />
            )}
          </div>
        }
        setTableSettingsToLocalStorage={setTableSettingsToLocalStorage}
        setColumnsOrderTemplate={setColumnsOrderTemplate}
        setColumnsOrder={setColumnsOrder}
        setColumns={setColumns}
        changePage={handleChangeTicketsPage}
        changeRowsPerPage={handleChangeTicketsRowsCount}
        handleTableParamsChange={handleTableParamsChange}
        onFilterChange={onFilterChange}
        ticketClick={ticketClick}
        posTicketClick={posTicketClick}
        isLoading={isLoading}
      />
    </>
  )
}

export default TicketsTable
