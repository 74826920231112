import { FormikValues } from 'formik'

import { getDateDayAgo, getDateHourAgo } from '@/utils'

export type DefaultFiltersConfig = {
  generateDefaultFilters?: () => FormikValues
  staticDefaultFilters?: Record<string, any>
}

export const defaultFiltersConfig = {
  transactions: {
    generateDefaultFilters: () => ({
      atmDate: [getDateDayAgo(), null],
      deviceId: [],
      clientId: '',
      eventTypeId: [],
      eventMediaTypeId: [],
      note: '',
    }),
  },
  alarms: {
    generateDefaultFilters: () => ({
      atmDateTime: [getDateHourAgo(), null],
      reactionDateTime: [null, null],
      deviceId: [],
      eventTypeId: [],
      eventMediaTypeId: [],
      note: '',
      reaction: '',
      operator: '',
    }),
  },
  events: {
    generateDefaultFilters: () => ({
      atmDateTime: [getDateHourAgo(), null],
      deviceId: [],
      eventTypeId: [],
      eventMediaTypeId: [],
      note: '',
    }),
  },
}
