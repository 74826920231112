import { Typography } from '@material-ui/core'
import DotIcon from '@material-ui/icons/FiberManualRecord'
import clsx from 'clsx'
import React, { FC, ReactNode, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Device } from '@/api/atmeye/devicesApi/types'
import { SecurityAlarmsApi } from '@/api/atmeye/securityAlarms'
import Button from '@/components/controls/Button'
import { DateCell } from '@/components/NPMPakage/components/tableComponents/rows/TableCell/DateCell/DateCell'
import { CellJSXPropsComponent } from '@/components/NPMPakage/components/tableComponents/UITable/types'
import { StatusCell } from '@/components/pages/atmeye/Devices/Components/CurrentDevice/Tabs/TeltonikaTab/component'
import TraceRouteModal from '@/components/pages/atmeye/Devices/Components/TraceRouteModal/TraceRouteModal'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'

const colors: Record<string, any> = {
  connectivityStatus: { 0: '#E45B4A', 1: '#00C562' },
  coreStatus: { 4: '#E45B4A', 1: '#00C562' },
  licenseStatus: { 0: '#E45B4A', 1: '#00C562' },
  //  'lastHourSecurityAlarmsCount':''
  // 'lastHourTechnicalEventsCount',
}

const TraceRouteButton = ({ currentDevice }: { currentDevice: null | Device }) => {
  const [showRouteModal, setShowRouteModal] = useState(false)

  return (
    <>
      <Button
        onClick={async () => {
          setShowRouteModal(true)

          if (currentDevice?.locationAlarmId) {
            SecurityAlarmsApi.reactToAlarm({ alarmId: currentDevice?.locationAlarmId })
          }
        }}
        variant="outlined"
        palette={currentDevice?.isLocationChanged ? 'danger' : 'primary'}
      >
        <FormattedMessage id="title.traceRoute.btn" />
      </Button>
      {showRouteModal && (
        <TraceRouteModal
          onClose={() => {
            setShowRouteModal(false)
          }}
          currentDevice={currentDevice}
        />
      )}
    </>
  )
}

const DeviceTableCell: FC<CellJSXPropsComponent> = ({
  fieldName,

  row,
  iconCellFields,
}: CellJSXPropsComponent): React.ReactElement => {
  const classes = useClasses({ collapse: false })
  const translate = useTranslate()

  const isShowIcon = useMemo(() => iconCellFields?.includes(fieldName) && row[fieldName], [
    fieldName,
    iconCellFields,
    row,
  ])

  const trackerCells = {
    trackerNetworkStatus: (
      <StatusCell
        isOk={row?.trackerNetworkStatus === 'ONLINE'}
        message={translate(
          `translate#atmeye.devicesTable.teltonikaStatus.${row?.trackerNetworkStatus || 'OFFLINE'}`,
        )}
      />
    ),
    trackerPower: (
      <StatusCell
        isOk={+(row?.trackerPower || 0) !== 0}
        message={translate(
          `translate#atmeye.devicesTable.teltonika.power.${
            row?.trackerPower ? 'pluggedIn' : 'onBattery'
          }`,
        )}
      />
    ),
    isTrackerGpsValid: (
      <StatusCell
        isOk={row?.isTrackerGpsValid}
        message={translate(
          `translate#atmeye.devicesTable.teltonikaStatus.${
            row?.isTrackerGpsValid ? 'ONLINE' : 'OFFLINE'
          }`,
        )}
      />
    ),
    coordinates: <div>{row?.latitude ? `${row?.latitude} | ${row?.longitude}` : ''}</div>,
    traceRoute: (
      <div>
        <TraceRouteButton currentDevice={row} />
      </div>
    ),
  }

  const validatedTrackerCells = row?.isTrackerLinked
    ? trackerCells
    : Object.keys(trackerCells).reduce((acc, field) => {
        acc[field as keyof typeof trackerCells] = <div />
        return acc
      }, {} as Record<keyof typeof trackerCells, ReactNode>)

  const renderComponents = () => {
    const color =
      (row?.[fieldName] === 'OK' && '#00C562') ||
      (row?.[fieldName] === 'NEED_ATTENTION' && 'orange') ||
      (row?.[fieldName] === 'BLOCKED' && '#E45B4A') ||
      (!row?.[fieldName] && 'gray')

    return {
      ...validatedTrackerCells,
      isDeviceTimeSynchronized: (
        <StatusCell
          isOk={row?.isDeviceTimeSynchronized}
          message={translate(
            `translate#atmeye.devicesTable.timeSync.${
              row?.isDeviceTimeSynchronized ? 'SYNCED' : 'NOT_SYNCED'
            }`,
          )}
        />
      ),
      lastActivityDateTime: <DateCell key={fieldName} date={row?.[fieldName]} />,
      aggregatedStatus: (
        <>
          {color && (
            <DotIcon
              className={clsx(classes.cellIcon, classes.iconSize)}
              htmlColor={color}
              style={{
                color: color,
              }}
            />
          )}

          {translate(
            `translate#atmeye.devicesTable.aggregatedStatus.${row?.aggregatedStatus || 'unknown'}`,
          )}
        </>
      ),
    }
  }

  const color = colors[fieldName]?.[row?.[`${fieldName}Id`]]

  return (
    <div className={classes.wrapper}>
      {isShowIcon && (
        <DotIcon
          className={clsx(classes.cellIcon, classes.iconSize)}
          htmlColor={color}
          style={{
            color: color,
          }}
        />
      )}

      {renderComponents()?.[fieldName as keyof typeof renderComponents] || (
        <Typography variant="body2">{row[fieldName]}</Typography>
      )}
    </div>
  )
}

export { DeviceTableCell }
