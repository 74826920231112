// core
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { patchForProducts } from '@/constants/auth'
import { AppNavigationRoutes } from '@/constants/paths'
import { setCurrentProduct, signOut } from '@/store/auth/actions'
import { getAvailableProducts } from '@/store/auth/selectors'

const useChooseProduct = () => {
  const availableProducts = useSelector(getAvailableProducts)
  const dispatch = useDispatch()
  const history = useHistory()

  const [product, setProduct] = useState<string>(patchForProducts[availableProducts[0]][0].value)

  const handleChange = useCallback(
    (e: React.ChangeEvent<{ name?: string; value: any }>): void => {
      setProduct(e.target.value)
    },
    [product],
  )

  const setProductAndLogin = (product: string): void => {
    setProduct(product)
    dispatch(setCurrentProduct(product))
  }

  const handleSetProduct = useCallback((): void => {
    dispatch(setCurrentProduct(product))
  }, [dispatch, history, product])

  const handleSignOut = useCallback((): void => {
    dispatch(signOut())
    history.push(AppNavigationRoutes.SignInPage)
  }, [dispatch, history])

  useEffect(() => {
    if (availableProducts.length === 1) {
      handleSetProduct()
    }
  }, [dispatch, history, product])

  return {
    availableProducts,
    handleChange,
    handleSetProduct,
    handleSignOut,
    patchForProducts,
    product,
    setProductAndLogin,
  }
}

export { useChooseProduct }
