import { debounce } from 'lodash-es'
import { SyntheticEvent, useCallback, useState } from 'react'

import { ValidationErrors } from '@/constants/forms/validationErrors'

export const useDebounceInput = (initialValue: string) => {
  const [inputValue, setInputValue] = useState(initialValue)
  const [inputErrors, setInputErrors] = useState('')

  const nameValidationErrorPresented = useCallback(
    (value: string) => {
      if (value.length < 1) {
        setInputErrors(ValidationErrors.Required)
      }
      if (value.length > 256) {
        setInputErrors(ValidationErrors.MaxLengthReportName)
      } else {
        setInputErrors('')
      }
    },
    [initialValue],
  )

  const inputDebounce = debounce((value: string): void => {
    nameValidationErrorPresented(value)
  }, 1000)

  const handleChangeInputValue = useCallback(
    (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      if (e.currentTarget.value === ' ') {
        e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '')
      }

      setInputValue(e.currentTarget.value)
      inputDebounce(e.currentTarget.value)
    },
    [],
  )

  return {
    inputValue,
    inputErrors,

    handleChangeInputValue,
    setInputValue,
  } as const
}
