import { AnyAction } from 'redux'

import { MapCashPoint } from '@/api/cm/swaggerGeneratedApi'
import { FormFieldValues } from '@/components/pages/cm/MapsPage/components/modals/FiltersModal/types'
import { CMActions } from '@/constants'
import { DetailedInformationRecord, FilterFormProps } from '@/types/cm/maps'

export const getCashPointsRequest = (): AnyAction => ({
  type: CMActions.MapCashPointsRequest,
})

export const getCashPointsResponse = (payload: MapCashPoint): AnyAction => ({
  type: CMActions.MapCashPointsResponse,
  payload,
})

export const getCashPointsFail = (payload: string): AnyAction => ({
  type: CMActions.MapCashPointsFail,
  payload,
})

export const handleFilterModal = (payload: boolean): AnyAction => ({
  type: CMActions.MapHandleFilterModal,
  payload,
})

export const filterSubmitRequest = (payload: FilterFormProps): AnyAction => ({
  type: CMActions.MapFilterSubmitRequest,
  payload,
})

export const setMapCashPointsFilter = (payload: Partial<FormFieldValues>): AnyAction => ({
  type: CMActions.MapSetCashPointsFilter,
  payload,
})
export const handleDetailedInformationPanel = (payload: boolean): AnyAction => ({
  type: CMActions.MapHandleDetailedInformationPanel,
  payload,
})

export const getMapSelectedCashPointInformationRequest = (id: string): AnyAction => ({
  type: CMActions.MapGetSelectedCashPointInformationRequest,
  payload: id,
})
export const getMapSelectedCashPointInformationResponse = (payload: {
  data: DetailedInformationRecord[]
  selectedId: string
}): AnyAction => ({
  type: CMActions.MapGetSelectedCashPointInformationRespose,
  payload,
})
export const getMapSelectedCashPointInformationFail = (): AnyAction => ({
  type: CMActions.MapGetSelectedCashPointInformationFail,
})

export const setMapInitialDetailedInformation = (): AnyAction => ({
  type: CMActions.MapSetInitialDetailedInformation,
})

export const refreshMap = (): AnyAction => ({
  type: CMActions.MapRefresh,
})

export const handleMapKeyPress = (actionName: string): AnyAction => ({
  type: CMActions.MapHandleKeyPress,
  payload: actionName,
})
