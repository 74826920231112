import React, { useCallback } from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import InputLabel from '@material-ui/core/InputLabel'
import { translate } from '@/utils/internalization'
import { Props } from './types'

import { useClasses } from './styles'

export const RequestRadioButtons = ({ value, onChange }: Props): React.ReactElement => {
  const classes = useClasses()

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: string): void => {
      onChange(value)
    },
    [onChange],
  )
  // TODO: change to base component
  return (
    <>
      <InputLabel className={classes.label} />
      <div className={classes.wrapper}>
        <RadioGroup
          className={classes.radios}
          row
          value={value}
          onChange={handleChange}
          defaultValue="1"
        >
          <FormControlLabel
            value="1"
            control={<Radio size="small" classes={{ root: classes.radio }} />}
            label={translate('translate#title.customerReques')}
            labelPlacement="end"
          />
          <FormControlLabel
            value=""
            control={<Radio size="small" classes={{ root: classes.radio }} />}
            label={translate('translate#title.managerRequest')}
            labelPlacement="end"
          />
        </RadioGroup>
      </div>
    </>
  )
}
