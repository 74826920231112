import { Box, TableCell, TableSortLabel } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import { saveAs } from 'file-saver'
import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { Command } from '@/api/rsocket/command'
import CloseButton from '@/components/blocks/CloseButton'
import Loader from '@/components/blocks/Loader'
import RadioButton from '@/components/controls/Radio'
import RadioButtonsGroup from '@/components/controls/RadioGroup'
import { SocketContext } from '@/context'
import { clearSocketDataByMessage, getSocketData } from '@/store/dashboard'
import { useTranslate } from '@/utils/internalization'

import LogFileModal from './LogFileModal'
import { ContentWrapper, useClasses } from './styles'
import { Props } from './types'

const ATMLogsModal = ({ open, handleClose, currentDevice }: Props): React.ReactElement => {
  const classes = useClasses()
  const dispatch = useDispatch()
  const translate = useTranslate()
  const [enabled, setEnabled] = useState<string>('')
  const [sortValue, setSortValue] = useState('FileName')
  const [prevSortValue, setPrevSortValue] = useState('')
  const [logFiles, setLogFiles] = useState<any[]>([])
  const [status, setStatus] = useState('')
  const [statusError, setStatusError] = useState<boolean>(false)
  const [logDownload, setLogDownload] = useState<boolean>(false)
  const [sortDirection, setSortDirection] = useState<boolean | undefined>(undefined)
  const { _service, connected } = useContext(SocketContext)
  const dataDirs: any = useSelector(getSocketData(Command.GET_LOG_FILE_DIRS), shallowEqual)
  const dataFile: any = useSelector(getSocketData(Command.GET_LOG_FILE), shallowEqual)
  const [showLogFileModal, setLogFileModal] = useState(false)
  let timeOut: any

  const handleCloseForm = useCallback(() => {
    handleClose()
    dispatch(clearSocketDataByMessage(Command.GET_LOG_FILE_DIRS))
    dispatch(clearSocketDataByMessage(Command.GET_LOG_FILE))
    setEnabled('')
  }, [handleClose, dispatch, currentDevice, enabled])

  const handleSetLogFileModal = useCallback(
    (val: boolean): (() => void) => (): void => {
      setLogFileModal(val)
    },
    [],
  )

  const handleChangeRadio = useCallback((event: ChangeEvent<{ value: any }>): void => {
    setEnabled(event.target.value)
  }, [])

  const handleSetSorting = useCallback(
    (val: string): (() => void) => (): void => {
      setSortValue(val)
      if (prevSortValue === sortValue) {
        logFiles.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
        setSortDirection(true)
        setPrevSortValue('')
      } else {
        logFiles.reverse()
        setSortDirection(false)
        setPrevSortValue(sortValue)
      }
    },
    [prevSortValue, sortValue, logFiles],
  )

  useEffect(() => {
    return (): void => {
      dispatch(clearSocketDataByMessage(Command.GET_LOG_FILE_DIRS))
      dispatch(clearSocketDataByMessage(Command.GET_LOG_FILE))
    }
  }, [dispatch])

  useEffect(() => {
    if (open) {
      if (!dataDirs) {
        _service.send({}, currentDevice?.deviceName, Command.GET_LOG_FILE_DIRS)
      }
    }
    if (!connected) {
      setStatusError(true)
    } else {
      setStatusError(false)
    }
  }, [connected, open])

  useEffect(() => {
    if (open) {
      if (dataDirs) {
        setLogFiles(dataDirs.logFiles)
        setStatus(dataDirs.status)
        clearInterval(timeOut)
      } else {
        timeOut = setTimeout(function() {
          setStatusError(true)
        }, 10000)
      }
    }
  }, [dataDirs, open])

  useEffect(() => {
    if (open) {
      if (dataFile && logDownload) {
        setLogDownload(false)
        const blob = new Blob([window.atob(dataFile.data)], {
          type: 'data:application/txt;base64',
        })
        saveAs(blob, enabled)
      }
    }
  }, [connected, dataFile, open])

  useEffect(() => {
    if (open) {
      if (status !== 'OK') {
        setStatusError(true)
      }
    }
  }, [status])

  return (
    <>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        keepMounted
        onClose={handleCloseForm}
        disableBackdropClick
      >
        <ContentWrapper>
          <CloseButton absolute className={classes.closeButton} onClick={handleCloseForm} />
          <div className={classes.deviceTitle}>
            {translate('translate#atmeye.devices.title.logFilesForTerminal')}{' '}
            {currentDevice?.deviceName}
          </div>
          <TableCell
            className={classes.radioTitle}
            onClick={handleSetSorting('FileName')}
            align="right"
          >
            <TableSortLabel
              active={sortDirection !== undefined}
              direction={sortDirection ? 'desc' : 'asc'}
            >
              <FormattedMessage id="atmeye.title.fileName" defaultMessage="File name" />
            </TableSortLabel>
          </TableCell>
          <RadioButtonsGroup
            value={enabled}
            onChange={handleChangeRadio}
            className={classes.radioGroup}
          >
            {logFiles && logFiles.length > 0 ? (
              logFiles.map(item => {
                return (
                  <RadioButton
                    key={item}
                    value={item}
                    label={item}
                    className={classes.radioButton}
                  />
                )
              })
            ) : statusError ? (
              <Box p={2}>
                <FormattedMessage id="title.noFilesFound" defaultMessage="No files found" />
              </Box>
            ) : (
              <Grid container justify="center">
                {' '}
                <Loader className={classes.loader} />{' '}
              </Grid>
            )}
          </RadioButtonsGroup>
          <Grid container justify="flex-end">
            <Button
              onClick={handleCloseForm}
              className={classes.cancelButton}
              color="primary"
              variant="contained"
            >
              <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              // onClick={handleGetLogFile}
              onClick={handleSetLogFileModal(true)}
              className={classes.button}
              color="primary"
              variant="contained"
              disabled={!enabled}
            >
              <FormattedMessage id="action.ok" defaultMessage="OK" />
            </Button>
          </Grid>
        </ContentWrapper>
      </Dialog>
      <LogFileModal
        open={showLogFileModal}
        selectedFile={enabled}
        onClose={handleSetLogFileModal(false)}
        currentDevice={currentDevice}
      />
    </>
  )
}

export default ATMLogsModal
