import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { getCurrentUserName } from '@/store/auth/selectors'

import { translate } from '@/utils/internalization'
import { useClasses } from './styles'

const DrawerFooter = (): ReactElement => {
  const userName = useSelector(getCurrentUserName)

  const classes = useClasses()

  // @NotApi set correct server and database
  return (
    <Grid className={classes.wrapper}>
      <Typography variant="caption" className={classes.row}>
        {translate('translate#dict.loggedAs')}: {userName}
      </Typography>
      <Typography variant="caption" className={classes.row}>
        {translate('translate#dict.server')}: sd.testgke.bs2.lt
      </Typography>
      <Typography variant="caption" className={classes.row}>
        {translate('translate#dict.database')}: atm-iq at sd-pgsql-postgresql:5432
      </Typography>
    </Grid>
  )
}

export default DrawerFooter
