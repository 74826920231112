import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import ContextMenu from '@/components/controls/ContextMenu/index'
import { Props } from './types'
import { useClasses } from './styles'
import { TextInput } from '@/components/controls/TextInput/component'
import Container from '@material-ui/core/Container'
import InputAdornment from '@material-ui/core/InputAdornment'
import Close from '@material-ui/icons/Close'
import { IconButton } from '@material-ui/core'
import { debounce } from 'lodash-es'

const TextFilter = ({
  id,
  currentSelected,
  anchorEl,
  handleClose,
  handleSave,
}: Props): React.ReactElement => {
  const [textInputValue, setTextInputValue] = useState<string>(currentSelected)
  const classes = useClasses()

  useEffect((): void => {
    setTextInputValue(currentSelected)
  }, [currentSelected])

  const handleSaveTextInputFilter = debounce((value: string): void => {
    handleSave(id, value)
  }, 1000)

  const handleTextInputValueChange = useCallback(
    (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      setTextInputValue(e.currentTarget.value)
      handleSaveTextInputFilter(e.currentTarget.value)
    },
    [handleSave],
  )

  const handleEmptySelectedText = useCallback((): void => {
    handleSave(id, '')
  }, [handleSave, id])

  const handleCancelClick = useCallback((): void => {
    setTextInputValue(currentSelected)
    handleClose()
  }, [currentSelected, handleClose])

  return (
    <ContextMenu
      transitionDuration={0}
      anchorEl={anchorEl}
      disableRipple
      options={[]}
      handleClose={handleCancelClick}
    >
      <Container>
        {anchorEl ? ( // mount to trigger autofocus
          <TextInput
            autoFocus
            value={textInputValue}
            onChange={handleTextInputValueChange}
            placeholder="Search"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className={classes.closeIcon}
                    size="small"
                    onClick={handleEmptySelectedText}
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <></>
        )}
      </Container>
    </ContextMenu>
  )
}

export default TextFilter
