import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputBase,
  Paper,
  TableCell,
  TableSortLabel,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import SearchIcon from '@material-ui/icons/Search'
import clsx from 'clsx'
import _ from 'lodash'
import { findIndex, isEmpty, mapValues } from 'lodash-es'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { shallowEqual, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Command } from '@/api/rsocket/command'
import DeleteIcon from '@/assets/atmeye/icons/delete.svg'
import CloseButton from '@/components/blocks/CloseButton'
import Loader from '@/components/blocks/Loader'
import { SocketContext } from '@/context'
import { getSocketData } from '@/store/dashboard'
import { useTranslate } from '@/utils/internalization'

import ConfirmModal from './ConfirmModal'
import { ContentWrapper, useClasses } from './styles'
import { Props } from './types'

const DeleteFilesOnATMeyeModal = ({
  open,
  handleClose,
  currentDevice,
}: Props): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const [sortValue, setSortValue] = useState('Static')
  const [prevSortValue, setPrevSortValue] = useState('')
  const [sortDirection, setSortDirection] = useState(true)
  const [state, setState] = React.useState<any>()
  const [disabled, setDisabled] = useState(true)
  const [stateAll, setStateAll] = React.useState({
    checkedAll: false,
  })
  const [searchTerm, setSearchTerm] = React.useState('')
  const { _service, connected } = useContext(SocketContext)
  const data: any = useSelector(getSocketData(Command.GET_PHOTO_DIRS), shallowEqual)
  const [photos, setPhotos] = React.useState([])
  const [label, setLabel] = React.useState<any>()
  const [folders, setFolders] = React.useState<any>()
  const [showConfirmModal, setConfirmModal] = useState(false)
  const [selectedFolders, setSelectedFolders] = useState<any[]>([])
  const [isLoading, setIsloading] = useState(true)
  const [results, setResults] = useState([])
  let timeOut: any

  useEffect(() => {
    if (open) {
      if (!data) {
        _service.send({}, currentDevice?.deviceName, Command.GET_PHOTO_DIRS)
        timeOut = setTimeout(function() {
          setIsloading(false)
        }, 10000)
      } else {
        clearInterval(timeOut)
        setPhotos(data.photos)
        setIsloading(false)
      }
    } else {
      setIsloading(true)
    }
  }, [connected, data, open])

  useEffect(() => {
    const folders = photos?.map((item: string, index: number) => ({
      id: index,
      label: item,
      state: false,
    }))

    if (folders) {
      setFolders(folders)
      setLabel(folders.map(a => a.label))

      const states = {} as any

      for (let i = 0; i < folders.length; i++) {
        states[folders[i].id] = folders[i].state
      }

      setState(states)
    }
  }, [photos])

  useEffect(() => {
    if (open && state) {
      if (Object.values(state).some(item => item)) {
        setDisabled(false)
      } else {
        setDisabled(true)
      }
    }
  }, [open, state])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  useEffect(() => {
    if (open) {
      if (!searchTerm) {
        setResults(label)
      } else {
        setResults(
          label.filter((person: string) =>
            person.toLowerCase().includes(searchTerm.toLocaleLowerCase()),
          ),
        )
      }
    }
  }, [searchTerm, label, open])

  const handleConfirm = useCallback(
    (val: boolean): (() => void) => (): void => {
      setConfirmModal(val)
      if (!val) {
        _service.send({}, currentDevice?.deviceName, Command.GET_PHOTO_DIRS)
      }
    },
    [_service, currentDevice],
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, [event.target.name]: event.target.checked })
      const newState = { ...state, [event.target.name]: event.target.checked }

      const merge = Object.fromEntries(
        label.map((_: any, i: React.ReactText) => [label[i], newState[i]]),
      )
      const filtered = Object.keys(_.pickBy(merge))

      setSelectedFolders(filtered)

      if (Object.values(newState).every(item => item)) {
        setStateAll({ checkedAll: true })
        setDisabled(false)
      } else {
        setStateAll({ checkedAll: false })
        if (Object.values(newState).some(item => item)) {
          setDisabled(false)
        } else {
          setDisabled(true)
        }
      }
    },
    [state, label],
  )

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setState(mapValues(state, () => true))
        setStateAll({ checkedAll: true })
        setDisabled(false)
        setSelectedFolders(label)
      } else {
        setState(mapValues(state, () => false))
        setStateAll({ checkedAll: false })
        setDisabled(true)
        setSelectedFolders([])
      }
    },
    [state, label],
  )

  const handleSetSorting = useCallback(
    (val: string): (() => void) => (): void => {
      setSortValue(val)
      if (prevSortValue === sortValue) {
        setSortDirection(true)
        setPrevSortValue('')
        results.sort()
      } else {
        setSortDirection(false)
        setPrevSortValue(sortValue)
        results.reverse()
      }
    },
    [prevSortValue, sortValue, results],
  )

  return (
    <>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        keepMounted
        onClose={handleClose}
        disableBackdropClick
      >
        <ContentWrapper>
          <CloseButton absolute className={classes.closeButton} onClick={handleClose} />
          <div className={classes.deviceTitle}>
            <FormattedMessage
              id="title.deleteFilesOnATM"
              defaultMessage="Delete files from device"
            />
          </div>
          <div className={classes.deviceSubTitle}>
            <FormattedMessage
              id="title.deleteThisFilesFromDevice"
              defaultMessage="Delete file folders from device"
            />{' '}
            {currentDevice?.deviceName}
          </div>
          <Paper component="form" className={classes.paperRoot}>
            <InputBase
              className={classes.input}
              placeholder={translate('translate#title.search')}
              value={searchTerm}
              onChange={handleInputChange}
            />
            <IconButton type="submit" className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          <TableCell className={classes.radioTitle} align="left">
            <TableSortLabel
              onClick={handleSetSorting('Static')}
              active={sortValue === 'Static'}
              direction={sortDirection ? 'asc' : 'desc'}
            >
              <FormattedMessage id="title.date" defaultMessage="Date" />
            </TableSortLabel>
            <div className={classes.selectAll}>
              <FormControlLabel
                className={classes.checkboxLabel}
                control={
                  <Checkbox
                    className={classes.checkboxRoot}
                    checked={stateAll.checkedAll}
                    onChange={handleSelectAll}
                    name="checkedAll"
                  />
                }
                label={translate('translate#title.selectAll')}
              />
            </div>
          </TableCell>
          <FormGroup row className={classes.checkboxWrapper}>
            {!isEmpty(results) ? (
              results.map((item: {} | null | '', index: string | number | null | '') => {
                return (
                  <FormControlLabel
                    key={index}
                    className={classes.checkboxLabel}
                    control={
                      <Checkbox
                        checked={
                          state[
                            findIndex(folders, function(o: { label: string }) {
                              return o.label === item
                            })
                          ]
                        }
                        onChange={handleChange}
                        name={findIndex(folders, function(o: { label: string }) {
                          return o.label === item
                        }).toString()}
                      />
                    }
                    label={item}
                  />
                )
              })
            ) : isLoading ? (
              <Loader className={classes.loader} />
            ) : (
              <Box p={2}>
                <FormattedMessage id="title.noFilesFound" defaultMessage="No files found" />
              </Box>
            )}
          </FormGroup>
          <Grid container justify="flex-end">
            <Button
              onClick={handleClose}
              className={classes.cancelButton}
              color="primary"
              variant="contained"
            >
              <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              onClick={handleConfirm(true)}
              className={clsx(classes.button, classes.deleteButton)}
              color="primary"
              variant="contained"
              startIcon={<img src={DeleteIcon} />}
              disabled={disabled}
            >
              <FormattedMessage id="action.delete" defaultMessage="Delete" />
            </Button>
          </Grid>
        </ContentWrapper>
      </Dialog>
      <ConfirmModal
        open={showConfirmModal}
        handleClose={handleConfirm(false)}
        selectedFolders={selectedFolders}
      />
    </>
  )
}

export default DeleteFilesOnATMeyeModal
