import { makeStyles } from '@material-ui/core'

export const useClasses = makeStyles(({ palette }) => ({
  button: {
    width: 'max-content',
    minWidth: 'auto',

    height: 40,
    fontWeight: 'normal',
    padding: '0 20px',
    margin: 0,

    whiteSpace: 'nowrap',
  },

  loader: {
    width: '22px',
    height: '22px',

    '&:after': {
      content: '',
      borderWidth: '3px',
      border: `2px solid ${palette.primary}`,
      borderColor: ` ${palette.primary} transparent  ${palette.primary}transparent`,
    },
  },

  disableBtnWrapper: {
    cursor: 'not-allowed!important',
  },
}))
