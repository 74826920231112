import { Theme } from '@material-ui/core'
import { createStyles } from '@material-ui/core/styles'

export const stylesErrorBoundary = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      gap: spacing(2),
      overflow: 'auto',
      padding: spacing(2),
    },
    buttons: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: spacing(2),
    },
    description: {
      overflow: 'scroll',
      height: 'auto',
      width: '100%',
    },
  })
