import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  badge: {
    userSelect: 'none',
    display: 'flex',
    padding: spacing(0.25, 1),
    borderRadius: spacing(1.5),
    alignItems: 'center',
    gap: '8px',
  },

  badgeItem: {
    display: 'block',
    width: spacing(1),
    height: spacing(1),
    borderRadius: '50%',
  },

  badgeCount: {
    fontSize: '14px',
  },
}))
