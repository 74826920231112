import React, { FC, useEffect, useState } from 'react'

import { MultipleSelectOptions } from '@/api/atmeye/commonTypes'
import { TicketsApi } from '@/api/sd/ticketsApi'
import Loader from '@/components/blocks/Loader'
import { DropDownEntityType } from '@/components/pages/atmeye/Administration/LicenseList/ATMCoreLicenses/components/FiltersModalLicence/GenerateFormFields/types'
import TechEventsPage from '@/components/pages/atmeye/Events'
import { LocalStorageItems } from '@/constants'

import { DeviceTabPanelTypes } from '../TransactionsTab/types'

const lsKeys = {
  lsKeyGrouping: LocalStorageItems.TechEventsByDeviceGrouping,
  lsKeyPagination: LocalStorageItems.TechEventsByDevicePagination,
  lsKeyColumns: LocalStorageItems.TechEventsByDeviceColumns,
  lsKeyFilters: LocalStorageItems.TechEventsByDeviceFilters,
  lsKeyFiltersTemplates: LocalStorageItems.TechEventsByDeviceFilterTemplate,
  lsKeySorting: LocalStorageItems.TechEventsByDeviceSort,
}

const TechEventsTabPanel: FC<DeviceTabPanelTypes> = ({
  currentDevice,
  segmentFilters,
  setSegmentFilters,
}: DeviceTabPanelTypes) => {
  const [requiredFilter, setRequiredFilter] = useState<
    { deviceId: MultipleSelectOptions } | undefined
  >()
  const [isLoading, setIsLoading] = useState()

  const getRequiredFilterOption = async (): Promise<void> => {
    if (currentDevice) {
      setIsLoading(true)
      const [{ dropdownListElements }] = await TicketsApi.getDropdownListsData([
        {
          entityType: DropDownEntityType.DEVICES_IDS_AND_NAMES,
          pageNumber: 1,
          pageSize: 1,
          searchFragment: '',
          selectedIds: [currentDevice.deviceId],
        },
      ])

      setRequiredFilter({ deviceId: dropdownListElements.filter(({ isSelected }) => isSelected) })
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getRequiredFilterOption()
  }, [currentDevice])

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 999,
            background: 'rgba(255, 255, 255, 1)',
          }}
        >
          <Loader />
        </div>
      )}
      {requiredFilter && (
        // requiredFilter?.deviceId?.[0]?.valueId === `${currentDevice?.deviceId}` &&
        <TechEventsPage
          title=""
          // initialHiddenColumns={['deviceName', 'processNumber', 'transactionId', 'localDate']}
          filtersConfig={{
            staticDefaultFilters: segmentFilters || {
              deviceId: requiredFilter?.deviceId,
              atmDateTime: [null, null],
              eventTypeId: [],
              eventMediaTypeId: [],
              note: '',
            },
            shouldIngoreStorage: !!segmentFilters,
            cleanFiltersAfterApplied: () => {
              setSegmentFilters && setSegmentFilters(null)
            },
          }}
          requiredFilters={requiredFilter}
          lsKeys={lsKeys}
        />
      )}
    </>
  )
}

export { TechEventsTabPanel }
