import { TableRow } from '@material-ui/core'
import clsx from 'clsx'
import React, { FC, Fragment, useMemo } from 'react'

import { useTableWithBorder } from '../../../../hooks'
import { useCommonClasses } from '../../../commonStyles'
import { TwiceHeaderCell, useClasses } from './styles'
import { TwiceColumnsRowProps } from './types'

export const TwiceColumnsRow: FC<TwiceColumnsRowProps> = React.memo(
  (props: TwiceColumnsRowProps) => {
    const {
      keys,
      twiceColumns,
      fitTableContent,
      getTwiceColumnTitles,
      isFirstEmptyCell,
      isCollapseIcon,
      isSelectAllCheckbox,
    } = props

    const classes = useClasses()
    const commonClasses = useCommonClasses()

    const isBorder = useTableWithBorder()

    const borderCellClasses = useMemo(() => (isBorder ? commonClasses.borderCell : undefined), [
      isBorder,
      commonClasses,
    ])

    const lastElementBorderClasses = useMemo(
      () => ({
        [classes.lastElementBorder]: isBorder && fitTableContent,
      }),
      [classes, isBorder, fitTableContent],
    )

    return (
      <TableRow>
        {isFirstEmptyCell && (
          <TwiceHeaderCell className={clsx(classes.firstEmptyCell, borderCellClasses)} />
        )}
        {isCollapseIcon && <TwiceHeaderCell className={borderCellClasses} />}
        {isSelectAllCheckbox && <TwiceHeaderCell className={borderCellClasses} />}
        {keys.map(key => {
          if (twiceColumns.includes(key)) {
            const [firstTitle, secondTitle] = getTwiceColumnTitles(key)

            return (
              <Fragment key={key}>
                <TwiceHeaderCell className={clsx(borderCellClasses)}>{firstTitle}</TwiceHeaderCell>
                <TwiceHeaderCell className={clsx(borderCellClasses, lastElementBorderClasses)}>
                  {secondTitle}
                </TwiceHeaderCell>
              </Fragment>
            )
          }

          return (
            <TwiceHeaderCell
              key={key}
              className={clsx(borderCellClasses, lastElementBorderClasses)}
            />
          )
        })}
        {fitTableContent && (
          <TwiceHeaderCell className={clsx(classes.twiceEmptyCell, borderCellClasses)} />
        )}
      </TableRow>
    )
  },
)
