export const genderData = [
  {
    value: 'MALE',
    name: 'translate#usm.title.add.user.modal.options.gender.male',
  },
  {
    value: 'FEMALE',
    name: 'translate#usm.title.add.user.modal.options.gender.female',
  },
]
