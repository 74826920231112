import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles'

interface StylesProps {
  nesting: number
}

export const useClasses = makeStyles<Theme, StylesProps>(({ spacing }) => ({
  loaderWrapper: {
    height: spacing(15),
    width: spacing(3),
    margin: 'auto',
  },
  tableContainer: {
    maxHeight: 400,
    direction: 'rtl',
    overflow: 'auto',
  },
  table: {
    direction: 'ltr',
  },
  headerCell: ({ nesting }: StylesProps) => ({
    backgroundColor: '#F9F9F9',
    '&:first-child': {
      paddingLeft: `calc(${spacing(2)}px * ${nesting})`,
    },
  }),
}))
