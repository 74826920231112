import React, { ReactElement, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CloseButton from '@/components/blocks/CloseButton'

import { useClasses } from './styles'
import { Props } from './types'

const SuccessDialog = ({ title, open, onClose }: Props): ReactElement => {
  const classes = useClasses()

  useEffect(() => {
    let timer: number

    if (open) {
      timer = window.setTimeout(() => onClose(), 2000)
    }

    return (): void => clearTimeout(timer)
  }, [open])

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <CloseButton onClick={onClose} absolute />
      <Grid>
        <svg className={classes.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle className={classes.checkmarkCircle} cx="26" cy="26" r="25" fill="none" />
          <path className={classes.checkmarkCheck} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
      </Grid>
      <Grid>
        <Typography variant="h6">{title}</Typography>
      </Grid>
    </Dialog>
  )
}

export default SuccessDialog
