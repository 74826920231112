export enum AtmeyeDeviceCameraDayNightSettings {
  GetAtmeyeCameraDayNightSettingsRequest = '@/GET_ATMEYE_DEVICE_CAMERA_DAY_NIGHT_SETTINGS_REQUEST',
  GetAtmeyeCameraDayNightSettingsResponse = '@/GET_ATMEYE_DEVICE_CAMERA_DAY_NIGHT_SETTINGS_RESPONSE',

  SetAtmeyeCameraDayNightSettingsRequest = '@/SET_ATMEYE_DEVICE_CAMERA_DAY_NIGHT_SETTINGS_REQUEST',
  SetAtmeyeCameraDayNightSettingsRequestResponse = '@/SET_ATMEYE_DEVICE_CAMERA_DAY_NIGHT_SETTINGS_REQUEST_RESPONSE',
  SetAtmeyeCameraDayNightSettingsRequestResponseFailMessage = '@/SET_ATMEYE_DEVICE_CAMERA_DAY_NIGHT_SETTINGS_REQUEST_RESPONSE_FAIL_MESSAGE',

  SwitchAtmeyeCameraDayNightSettingsRequest = '@/SWITCH_ATMEYE_DEVICE_CAMERA_DAY_NIGHT_SETTINGS_REQUEST',
  SwitchAtmeyeCameraDayNightSettingsRequestResponse = '@/SWITCH_ATMEYE_DEVICE_CAMERA_DAY_NIGHT_SETTINGS_REQUEST_RESPONSE',
  SwitchAtmeyeCameraDayNightSettingsRequestResponseFail = '@/SWITCH_ATMEYE_DEVICE_CAMERA_DAY_NIGHT_SETTINGS_REQUEST_RESPONSE_FAIL',
}
