import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { StrategyLocalStorage } from '@/components/NPMPakage/components/tableComponents/UITable/strategy'
import { LocalStorageItems } from '@/constants'

const useSelectedFilters = <FormValues>({
  localStorageKey,
  initialValues,
  changePage,
  requiredFilters,
  handleResetDefaultFilters,
  filtersConfig,
}: {
  localStorageKey: LocalStorageItems
  initialValues: FormValues
  changePage?: (page: number) => void
  requiredFilters?: Record<string, any>
  handleResetDefaultFilters?: () => any
  filtersConfig?: any
}) => {
  const isInitialMount = useRef(true)
  const location = useLocation<{ filterObject: FormValues }>()
  const filterState = location.state?.filterObject

  const [showFiltersModal, setShowFiltersModal] = useState(false)

  const [selectedFilters, setSelectedFilters] = useState<FormValues>(() => {
    const selectedFiltersLocalStorage: FormValues =
      StrategyLocalStorage.getData(localStorageKey) || ''

    const filters = filtersConfig?.shouldIngoreStorage
      ? initialValues
      : selectedFiltersLocalStorage || initialValues

    return { ...filters, ...requiredFilters }
  })

  useEffect(() => {
    if (!isInitialMount.current) {
      setSelectedFilters(filters => ({ ...filters, ...(filterState || {}), ...requiredFilters }))
      window.history.replaceState(undefined, '')
    }
  }, [requiredFilters, filterState])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    }

    if (filtersConfig?.cleanFiltersAfterApplied) {
      filtersConfig.cleanFiltersAfterApplied()
    }
  }, [])

  const handleSetFiltersModal = useCallback((): void => setShowFiltersModal(open => !open), [
    setShowFiltersModal,
  ])

  const handleSelectedFilters = useCallback(
    (values: FormValues) => {
      StrategyLocalStorage.setData(localStorageKey, values)
      changePage && changePage(1)
      setSelectedFilters(values)
    },
    [setSelectedFilters],
  )

  const handleDeleteFilter = (key: keyof typeof selectedFilters): void => {
    let newValue = initialValues[key]
    if (handleResetDefaultFilters) {
      if (initialValues[key] ?? false) {
        const newFilters = handleResetDefaultFilters()
        newValue = newFilters[key]
      }
    }

    const newFilters = {
      ...selectedFilters,
      [key]: newValue,
    }
    setSelectedFilters(newFilters)
    StrategyLocalStorage.setData(localStorageKey, newFilters)
    changePage && changePage(1)
  }

  const handleClearFilters = (): void => {
    setSelectedFilters(handleResetDefaultFilters ? handleResetDefaultFilters() : initialValues)
    StrategyLocalStorage.setData(localStorageKey, initialValues)
    changePage && changePage(1)
  }

  return {
    selectedFilters,
    setSelectedFilters,
    handleSelectedFilters,
    showFiltersModal,
    handleSetFiltersModal,
    handleDeleteFilter,
    handleClearFilters,
  }
}

export { useSelectedFilters }
