import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, delay, put, select, takeLatest } from 'redux-saga/effects'

import { CompaniesApi } from '@/api/companies/companiesApi'
import {
  CreateCompanyRequestBody,
  CreateDepartmentRequestBody,
  CreatePersonnelRequestBody,
} from '@/api/companies/companiesApi/types'
import { TeamsApi } from '@/api/vault/teamsApi'
import { companiesActions } from '@/constants/actions/companiesActions'
import { PopUpService } from '@/utils/services/popUpService'

import {
  addCompanyRequest,
  addCompanyResponse,
  addCompanyResponseFail,
  addDepartmentResponse,
  addDepartmentResponseFail,
  addPersonnelResponse,
  addPersonnelResponseFail,
  deleteCompanyRequestFail,
  deleteCompanyRequestResponse,
  deleteDepartmentRequestFail,
  deleteDepartmentRequestResponse,
  deletePersonnelRequestFail,
  deletePersonnelRequestResponse,
  getCompaniesResponse,
  getCompanyRequest,
  getCompanyResponse,
  getCompanyTreeByCompanyIdResponse,
  getCompanyTreeBySearchFragmentResponse,
  getDepartmentResponse,
  getPersonnelAddressResponse,
  getPersonnelQualificationLevelsResponse,
  getPersonnelResponse,
  getPersonnelRolesHistoryResponse,
  getPersonnelSalariesResponse,
  getQualificationLevelsLookupResponse,
  getRolesResponse,
  getWorkAreasResponse,
  unsetSelectedCompany,
  updateCompanyResponse,
  updateCompanyResponseFail,
  updatePersonnelResponse,
  updatePersonnelResponseFail,
} from './actions'

function* getCompanyTree({ payload: { searchFragment, isActual } }: AnyAction): SagaIterator {
  try {
    const response = yield call(
      CompaniesApi.getCompanyTreeBySearchFragment,
      searchFragment,
      isActual,
    )

    yield put(getCompanyTreeBySearchFragmentResponse(response))
  } catch (error) {}
}

function* getCompanyTreeByCompanyId({
  payload: { companyId, isActual, searchFragment },
}: AnyAction): SagaIterator {
  try {
    const response = yield call(
      CompaniesApi.getCompanyTreeByCompanyId,
      companyId,
      isActual,
      searchFragment,
    )

    yield put(getCompanyTreeByCompanyIdResponse(response))
  } catch (error) {}
}

// function* unsetCompany({ payload }: AnyAction): SagaIterator {
//   try {
//     yield delay(1000)
//     //yield put(unsetSelectedCompany())
//   } catch (error) {}
// }

function* getCompany({ payload }: AnyAction): SagaIterator {
  try {
    if (payload?.itemId) {
      const response = yield call(CompaniesApi.getCompany, payload?.itemId)
      yield put(getCompanyResponse(response))
    }
  } catch (error) {}
}

function* updateCompany({ payload }: AnyAction): SagaIterator {
  try {
    const itemId = yield select(state => state.companiesCompanies.selectedCompany.itemId)

    const companyData = {
      itemId,
      ...payload.companyData,
    }

    const response = yield call(CompaniesApi.updateCompany, companyData)
    yield put(updateCompanyResponse())

    if (response.errors) {
      throw new Error(response.errors)
    }

    // yield put(getCompanyRequest(itemId))
    // PopUpService.showGlobalPopUp('translate#companies.title.updateCompanySuccessfull', 'success')
    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#companies.title.updateCompanySuccessfull')}`,
    )
  } catch (error) {
    yield put(updateCompanyResponseFail(error.message))
    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#companies.title.updateCompanyError')}.\n${error}`,
      'error',
    )
  }
}

function* addCompany({ payload }: AnyAction): Generator<unknown, void, any> {
  try {
    const response = yield call(
      CompaniesApi.createCompany,
      payload.companyData as CreateCompanyRequestBody,
    )

    PopUpService.showGlobalPopUp('translate#companies.title.addCompanySuccessfull', 'success')

    yield put(addCompanyResponse(response.companyGeneralInfo.companyId))
    if (payload.handleSuccess) payload.handleSuccess()

    if (response.errors) {
      throw new Error(response.errors)
    }
  } catch (error) {
    yield put(addCompanyResponseFail(error))

    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#companies.title.addCompanySuccessError')}.\n${error}`,
      'error',
    )
  }
}

function* deleteCompany({ payload }: AnyAction): Generator {
  try {
    yield call(CompaniesApi.deleteCompany, payload.companyId)

    yield put(deleteCompanyRequestResponse())
  } catch (error) {
    yield put(deleteCompanyRequestFail(error))

    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#companies.title.deleteCompanyError')}.\n${error}`,
      'error',
    )
  }
}

function* getDepartment({ payload }: AnyAction): SagaIterator {
  try {
    if (payload?.itemId) {
      const response = yield call(CompaniesApi.getDepartment, payload?.itemId)
      yield put(getDepartmentResponse(response))
    }
  } catch (error) {}
}

function* updateDepartment({ payload }: AnyAction): SagaIterator {
  try {
    const itemId = yield select(state => state.companiesCompanies.selectedDepartment.itemId)

    const departmentData = {
      itemId,
      ...payload.departmentData,
    }

    const response = yield call(CompaniesApi.updateDepartment, departmentData)

    if (response.errors) {
      throw new Error(response.errors)
    }

    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#companies.title.updateDepartmentSuccessfull')}`,
    )
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#companies.title.updateDepartmentError')}.\n${error}`,
      'error',
    )
  }
}

function* addDepartment({ payload }: AnyAction): Generator<unknown, void, any> {
  try {
    const response = yield call(
      CompaniesApi.createDepartment,
      payload.departmentData as CreateDepartmentRequestBody,
    )

    PopUpService.showGlobalPopUp('translate#companies.title.addDepartmentSuccessfull', 'success')

    yield put(addDepartmentResponse())
    if (payload.handleSuccess) payload.handleSuccess()

    if (response.errors) {
      throw new Error(response.errors)
    }
  } catch (error) {
    yield put(addDepartmentResponseFail(error))

    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#companies.title.addDepartmentError')}.\n${error}`,
      'error',
    )
  }
}

function* deleteDepartment({ payload }: AnyAction): Generator {
  try {
    yield call(CompaniesApi.deleteDepartment, payload.departmentId)

    yield put(deleteDepartmentRequestResponse())
  } catch (error) {
    yield put(deleteDepartmentRequestFail(error.message))

    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#companies.title.deleteDepartmentError')}.\n${error}`,
      'error',
    )
  }
}

function* getPersonnel({ payload }: AnyAction): SagaIterator {
  try {
    if (payload?.itemId) {
      const response = yield call(CompaniesApi.getPersonnel, payload?.itemId)
      yield put(getPersonnelResponse(response))
    }
  } catch (error) {}
}

function* updatePersonnel({ payload }: AnyAction): SagaIterator {
  try {
    const itemId = yield select(state => state.companiesCompanies.selectedPersonnel.itemId)

    const personnelData = {
      itemId,
      ...payload.personnelData,
    }

    const response = yield call(CompaniesApi.updatePersonnel, personnelData)

    const responsePayload = {
      personnelData: {
        ...payload.personnelData,
        dayoffs: response.dayoffs,
        salary: response.salary,
        qualifications: response.qualifications,
      },
    }

    yield put(updatePersonnelResponse(responsePayload))

    if (response.errors) {
      throw new Error(response.errors)
    }

    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#companies.title.updatePersonnelSuccessfull')}`,
    )
  } catch (error) {
    yield put(updatePersonnelResponseFail(error))

    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#companies.title.updatePersonnelError')}.\n${error.message}`,
      'error',
    )
  }
}

function* addPersonnel({ payload }: AnyAction): Generator<unknown, void, any> {
  try {
    const response = yield call(
      CompaniesApi.createPersonnel,
      payload.personnelData as CreatePersonnelRequestBody,
    )

    PopUpService.showGlobalPopUp('translate#companies.title.addPersonnelSuccessfull', 'success')

    yield put(addPersonnelResponse(response.personnelGeneralInfo.personnelId))
    if (payload.handleSuccess) payload.handleSuccess()

    if (response.errors) {
      throw new Error(response.errors)
    }
  } catch (error) {
    yield put(addPersonnelResponseFail(error))

    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#companies.title.addPersonnelError')}.\n${error.message}`,
      'error',
    )
  }
}

function* deletePersonnel({ payload }: AnyAction): Generator {
  try {
    yield call(CompaniesApi.deletePersonnel, payload.personnelId)

    yield put(deletePersonnelRequestResponse())
  } catch (error) {
    yield put(deletePersonnelRequestFail(error))

    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#companies.title.deletePersonnelError')}.\n${error}`,
      'error',
    )
  }
}

function* getPersonnelSalaries({ payload: { personnelId } }: AnyAction): SagaIterator {
  try {
    const response = yield call(CompaniesApi.getPersonnelSalaries, personnelId)
    yield put(getPersonnelSalariesResponse(response))
  } catch (error) {}
}

function* getPersonnelRolesHistory({ payload: { personnelId } }: AnyAction): SagaIterator {
  try {
    const response = yield call(CompaniesApi.getPersonnelRolesHistory, personnelId)
    yield put(getPersonnelRolesHistoryResponse(response))
  } catch (error) {}
}

function* getPersonnelQualificationLevels({ payload: { personnelId } }: AnyAction): SagaIterator {
  try {
    const response = yield call(CompaniesApi.getPersonnelQualificationLevels, personnelId)
    yield put(getPersonnelQualificationLevelsResponse(response))
  } catch (error) {}
}

function* getPersonnelAddress({ payload: { personnelId } }: AnyAction): SagaIterator {
  try {
    const response = yield call(CompaniesApi.getPersonnelAddress, personnelId)
    yield put(getPersonnelAddressResponse(response))
  } catch (error) {}
}

function* getRoles({ payload }: AnyAction): SagaIterator {
  try {
    const { businessEntityKey } = payload
    const response = yield call(CompaniesApi.getInitialSystemRoles, businessEntityKey)
    yield put(getRolesResponse(response))
  } catch (error) {}
}

function* getWorkAreas({ payload }: AnyAction): SagaIterator {
  try {
    const response = yield call(CompaniesApi.getWorkAreasLookup)
    yield put(getWorkAreasResponse(response))
  } catch (error) {}
}

function* getQualificationLevelsLookup({ payload }: AnyAction): SagaIterator {
  try {
    const response = yield call(CompaniesApi.getQualificationLevelsLookup)
    yield put(getQualificationLevelsLookupResponse(response))
  } catch (error) {}
}

function* getCompanies(): SagaIterator {
  try {
    const response = yield call(TeamsApi.getCompanies)
    yield put(getCompaniesResponse(response))
  } catch (error) {}
}

export default function*(): Generator {
  yield takeLatest(companiesActions.GetCompanyRequest, getCompany)
  yield takeLatest(companiesActions.SetSelectedCompany, getCompany)

  yield takeLatest(companiesActions.SetSelectedDepartment, getDepartment)
  yield takeLatest(companiesActions.SetSelectedPersonnel, getPersonnel)

  // yield takeLatest(companiesActions.UnsetSelectedCompany, unsetCompany)

  yield takeLatest(companiesActions.AddCompanyRequest, addCompany)
  yield takeLatest(companiesActions.UpdateCompanyRequest, updateCompany)
  yield takeLatest(companiesActions.DeleteCompanyRequest, deleteCompany)

  yield takeLatest(companiesActions.AddDepartmentRequest, addDepartment)
  yield takeLatest(companiesActions.UpdateDepartmentRequest, updateDepartment)
  yield takeLatest(companiesActions.DeleteDepartmentRequest, deleteDepartment)

  yield takeLatest(companiesActions.AddPersonnelRequest, addPersonnel)
  yield takeLatest(companiesActions.UpdatePersonnelRequest, updatePersonnel)
  yield takeLatest(companiesActions.DeletePersonnelRequest, deletePersonnel)
  yield takeLatest(companiesActions.GetPersonnelSalariesRequest, getPersonnelSalaries)
  yield takeLatest(companiesActions.GetPersonnelRolesHistoryRequest, getPersonnelRolesHistory)
  yield takeLatest(
    companiesActions.GetPersonnelQualificationLevelsRequest,
    getPersonnelQualificationLevels,
  )
  yield takeLatest(companiesActions.GetPersonnelAddressRequest, getPersonnelAddress)

  yield takeLatest(companiesActions.GetCompanyTreeBySearchFragmentRequest, getCompanyTree)
  yield takeLatest(companiesActions.GetCompanyTreeByCompanyIdRequest, getCompanyTreeByCompanyId)
  yield takeLatest(companiesActions.GetRolesRequest, getRoles)

  yield takeLatest(companiesActions.GetWorkAreasRequest, getWorkAreas)
  yield takeLatest(
    companiesActions.GetQualificationLevelsLookupRequest,
    getQualificationLevelsLookup,
  )

  yield takeLatest(companiesActions.GetCompaniesRequest, getCompanies)
}
