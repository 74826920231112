import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ palette, spacing }) => ({
  file: {
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    padding: spacing(0, 2),
    margin: spacing(2, 0),
  },
  fileName: {
    fontSize: spacing(1.5),
    color: palette.grey[400],
    width: spacing(20),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  extension: {
    width: spacing(2.5),
    height: spacing(3),
    marginRight: spacing(2),
  },
  fileSave: {
    margin: 0,
    padding: spacing(1.5),
  },
}))
