import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  field: {
    height: spacing(5),
  },
  radioGroup: {
    marginBottom: spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',

    '& > label': {
      marginRight: spacing(2.5),
    },
  },
  loader: {
    width: spacing(5),
    height: spacing(5),
  },
}))
