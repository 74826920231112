import { makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles(({ spacing }) => ({
  form: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    columnGap: spacing(2.5),
  },
  buttonWrapper: {
    width: '100%',
  },
  button: {
    width: '100%',
  },
  loaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    background: 'rgba(255, 255, 255, 0.7)',
  },
}))
