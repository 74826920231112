import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  dropdownInput: {
    display: 'inline-flex',
    width: '100%',
  },
  dateField: {
    width: '100%',
    marginBottom: spacing(2),
  },
}))
