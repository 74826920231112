import moment from 'moment'
import { AnyAction } from 'redux'

import {
  ComVaultReport,
  Currency,
  EventType,
  JournalEvent,
  JournalEventCurrencyEntry,
  JournalEventsFilter,
  SelectorItem,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants'
import { evjVaultsTableId as tableId } from '@/constants/vault-v1/tableIds'
import { getTableConfig } from '@/helpers/tableLocalStorage'

export type State = {
  filterOptions: {
    vaults: SelectorItem[]
    currencies: Currency[]
    eventTypes: EventType[]
    executors: SelectorItem[]
  }

  modals: {
    isColumnModalOpen: boolean
    isDocumentModalOpen: boolean
    isFiltersModalOpen: boolean
  }

  events: {
    data: JournalEvent[]
    total: number

    isLoading: boolean
    isLoaded: boolean

    errorMessage?: string

    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined

    filter: JournalEventsFilter

    paging: {
      page: number
      pageSize: number
    }

    selected: JournalEvent | null
    selectedEntry: JournalEventCurrencyEntry | null

    reports: {
      errorMessage?: string
      isLoading: boolean
      list: ComVaultReport[]
      selectedReport: {
        isLoading: boolean
        errorMessage?: string
        bytes?: Blob | null
      }
    }
  }
}

export const initialState: State = {
  filterOptions: {
    vaults: [],
    currencies: [],
    eventTypes: [],
    executors: [],
  },

  modals: {
    isColumnModalOpen: false,
    isDocumentModalOpen: false,
    isFiltersModalOpen: false,
  },

  events: {
    data: [],
    total: 0,

    isLoading: false,
    isLoaded: false,

    errorMessage: '',

    sortColumn: undefined,
    sortOrder: undefined,

    filter: {
      objectId: undefined,
      eventType: undefined,

      currencyId: undefined,
      executorId: undefined,
      fromDate: moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format(),
      toDate: undefined,
      address: undefined,
      eventInitiator: undefined,
      coid: undefined,
      textFragment: undefined,
    },

    paging: {
      page: 1,
      pageSize: getTableConfig({ tableId })?.rowPerPage || 50,
    },

    selected: null,
    selectedEntry: null,

    reports: {
      errorMessage: '',
      isLoading: false,
      list: [],
      selectedReport: {
        isLoading: false,
        errorMessage: '',
        bytes: undefined,
      },
    },
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case VaultV1Actions.SetGlobalFilter:
      return {
        ...state,
        events: {
          ...state.events,
          paging: {
            ...state.events.paging,
            page: 1,
          },
        },
      }
    case VaultV1Actions.EvjSortEventsTable:
      return {
        ...state,
        events: {
          ...state.events,
          isLoading: true,
          ...payload,
        },
      }
    case VaultV1Actions.EvjSetEventsFilter:
      return {
        ...state,
        events: {
          ...state.events,
          filter: {
            ...state.events.filter,
            ...payload,
          },
        },
      }

    case VaultV1Actions.EvjSetEventsPaging:
      return {
        ...state,
        events: {
          ...state.events,
          isLoading: true,
          paging: {
            ...state.events.paging,
            ...payload,
          },
        },
      }
    case VaultV1Actions.EvjResetEventsPaging:
      return {
        ...state,
        events: {
          ...state.events,
          isLoading: true,
          paging: {
            ...state.events.paging,
            page: initialState.events.paging.page || 1,
          },
        },
      }

    case VaultV1Actions.OrdSortOrdersTable:
      return {
        ...state,
        events: {
          ...state.events,
          ...payload,
          isLoading: true,
        },
      }

    case VaultV1Actions.EvjGetEventsRequest:
      return {
        ...state,
        events: {
          ...state.events,
          isLoading: true,
        },
      }

    case VaultV1Actions.EvjGetEventsResponse:
      return {
        ...state,
        events: {
          ...state.events,
          isLoading: false,
          isLoaded: true,
          data: payload.events,
          total: payload.total,
          errorMessage: '',
        },
      }

    case VaultV1Actions.EvjGetEventsFail:
      return {
        ...state,
        events: {
          ...state.events,
          isLoading: false,
          isLoaded: false,
          errorMessage: payload,
        },
      }
    case VaultV1Actions.EvjSetSelectedEvent:
      return {
        ...state,
        events: {
          ...state.events,
          selected: payload,
          selectedEntry:
            payload?.eventId !== state.events.selected?.eventId ? null : state.events.selectedEntry,
        },
      }
    case VaultV1Actions.EvjSetSelectedEntry:
      return {
        ...state,
        events: {
          ...state.events,
          selectedEntry: payload,
        },
      }

    // [START] Modals ----------------------------------------------------
    case VaultV1Actions.EvjOpenColumnsSettingsModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          isColumnModalOpen: payload,
        },
      }
    case VaultV1Actions.EvjOpenDocumentsModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          isDocumentModalOpen: payload,
        },
      }
    case VaultV1Actions.EvjOpenFilterModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          isFiltersModalOpen: payload,
        },
        events: {
          ...state.events,
          reports: payload === false ? initialState.events.reports : state.events.reports,
        },
      }
    // [END] Modals ------------------------------------------------------

    // [START] Reports ----------------------------------------------
    case VaultV1Actions.EvjGetReportsRequest:
      return {
        ...state,
        events: {
          ...state.events,
          reports: {
            ...state.events.reports,
            isLoading: true,
            list: [],
            errorMessage: '',
            selectedReport: initialState.events.reports.selectedReport,
          },
        },
      }
    case VaultV1Actions.EvjGetReportsResponse:
      return {
        ...state,
        events: {
          ...state.events,
          reports: {
            ...state.events.reports,
            isLoading: false,
            list: payload,
            errorMessage: '',
          },
        },
      }
    case VaultV1Actions.EvjGetReportsFail:
      return {
        ...state,
        events: {
          ...state.events,
          reports: {
            ...state.events.reports,
            isLoading: false,
            list: [],
            errorMessage: payload,
          },
        },
      }

    case VaultV1Actions.EvjGetReportRequest:
      return {
        ...state,
        events: {
          ...state.events,
          reports: {
            ...state.events.reports,
            selectedReport: {
              ...state.events.reports.selectedReport,
              isLoading: true,
              errorMessage: '',
              bytes: undefined,
            },
          },
        },
      }
    case VaultV1Actions.EvjGetReportResponse:
      return {
        ...state,
        events: {
          ...state.events,
          reports: {
            ...state.events.reports,
            selectedReport: {
              ...state.events.reports.selectedReport,
              isLoading: false,
              errorMessage: '',
              bytes: payload,
            },
          },
        },
      }
    case VaultV1Actions.EvjGetReportFail:
      return {
        ...state,
        events: {
          ...state.events,
          reports: {
            ...state.events.reports,
            selectedReport: {
              ...state.events.reports.selectedReport,
              isLoading: false,
              errorMessage: payload,
              bytes: undefined,
            },
          },
        },
      }
    // [END] Reports ----------------------------------------------

    // [START] Filter Options --------------------------------------------
    case VaultV1Actions.EvjGetVaultsResponse:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          vaults: payload,
        },
      }
    case VaultV1Actions.EvjGetCurrenciesResponse:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          currencies: payload,
        },
      }
    case VaultV1Actions.EvjGetEventTypesResponse:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          eventTypes: payload,
        },
      }
    case VaultV1Actions.EvjGetExecutorsResponse:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          executors: payload,
        },
      }
    // [END] Filter Options ----------------------------------------------
    case VaultV1Actions.SignOut:
      return initialState
    default:
      return state
  }
}
