import makeStyles from '@material-ui/core/styles/makeStyles'
export type Props = {
  display?: string
}

export const useClasses = makeStyles(({ spacing }) => ({
  container: {
    display: (props: Props) => props.display,
    flexDirection: 'column',
    overflow: 'auto',
    paddingTop: spacing(4),
    paddingBottom: spacing(4),
  },
  wrapper: {
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
  },
  alertWrapper: {
    padding: spacing(3),
  },
  serviceSelectWrapper: {
    marginTop: spacing(2),
  },
  form: {
    display: `${(props: Props) => props.display}`,
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    marginTop: spacing(2),
  },
  field: {
    '&:nth-of-type(2n + 1)': {
      paddingRight: spacing(2),
    },
    '&:nth-of-type(2n)': {
      paddingLeft: spacing(2),
    },
  },
  loaderWrapper: {
    height: spacing(25),
    width: spacing(5),
    margin: 'auto',
  },
}))
