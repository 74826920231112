import { ValidationService } from '@/utils/services/validationService'
import { ValidationErrors } from '@/constants/forms/validationErrors'
import {
  ChangeFormValues,
  ChangeFormFields,
} from '@/components/pages/common/SignInPage/components/ChangePassword/types'

export const validate = (values: ChangeFormValues): Partial<ChangeFormValues> => {
  const errors: Partial<ChangeFormValues> = {}
  if (!ValidationService.isRequired(values[ChangeFormFields.OldPassword])) {
    errors[ChangeFormFields.OldPassword] = ValidationErrors.Required
  }

  if (values[ChangeFormFields.NewPassword] !== values[ChangeFormFields.RepeatPassword]) {
    errors[ChangeFormFields.RepeatPassword] = ValidationErrors.PasswordsDoNotMatch
  }

  if (ValidationService.isSpace(values[ChangeFormFields.NewPassword])) {
    errors[ChangeFormFields.NewPassword] = ValidationErrors.UnacceptablePasswordSymbols
  }

  if (ValidationService.isSpace(values[ChangeFormFields.OldPassword])) {
    errors[ChangeFormFields.OldPassword] = ValidationErrors.UnacceptablePasswordSymbols
  }

  if (!ValidationService.isRequirementsForChangePassword(values[ChangeFormFields.NewPassword])) {
    errors[ChangeFormFields.NewPassword] = ValidationErrors.RequirementsForChangePassword
  }

  return errors
}

// const m_strUpperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
// const m_strLowerCase = 'abcdefghijklmnopqrstuvwxyz'
// const m_strNumber = '0123456789'
// const m_strCharacters = '!@#$%^&*?_~'
//
// const countContain = (strPassword: string, strCheck: string) => {
//   // Declare variables
//   let nCount = 0
//
//   for (let i = 0; i < strPassword.length; i++) {
//     if (strCheck.indexOf(strPassword.charAt(i)) > -1) {
//       nCount++
//     }
//   }
//
//   return nCount
// }

// const checkPassword = (strPassword: string) => {
//   // Reset combination count
//   let nScore = 0
//
//   // Password length
//   // -- Less than 4 characters
//   if (strPassword.length < 8) {
//     nScore += 5
//   }
//   // -- 5 to 7 characters
//   else if (strPassword.length > 4 && strPassword.length < 8) {
//     nScore += 10
//   }
//   // -- 8 or more
//   else if (strPassword.length > 7) {
//     nScore += 25
//   }
//
//   // Letters
//   const nUpperCount = countContain(strPassword, m_strUpperCase)
//   const nLowerCount = countContain(strPassword, m_strLowerCase)
//   const nLowerUpperCount = nUpperCount + nLowerCount
//   // -- Letters are all lower case
//   if (nUpperCount === 0 && nLowerCount !== 0) {
//     nScore += 10
//   }
//   // -- Letters are upper case and lower case
//   else if (nUpperCount != 0 && nLowerCount != 0) {
//     nScore += 20
//   }
//
//   // Numbers
//   const nNumberCount = countContain(strPassword, m_strNumber)
//   // -- 1 number
//   if (nNumberCount == 1) {
//     nScore += 10
//   }
//   // -- 3 or more numbers
//   if (nNumberCount >= 3) {
//     nScore += 20
//   }
//
//   const nCharacterCount = countContain(strPassword, m_strCharacters)
//   if (nCharacterCount == 1) {
//     nScore += 10
//   }
//   if (nCharacterCount > 1) {
//     nScore += 25
//   }
//
//   if (nNumberCount != 0 && nLowerUpperCount != 0) {
//     nScore += 2
//   }
//   if (nNumberCount != 0 && nLowerUpperCount != 0 && nCharacterCount != 0) {
//     nScore += 3
//   }
//   if (nNumberCount != 0 && nUpperCount != 0 && nLowerCount != 0 && nCharacterCount != 0) {
//     nScore += 5
//   }
//
//   return nScore
// }
//
// export const runPassword = (strPassword: string) => {
//   const nScore = checkPassword(strPassword)
//
//   let strText
//
//   if (nScore >= 80) {
//     strText = 'Very Strong'
//   }
//   // -- Average
//   else if (nScore >= 40) {
//     strText = 'Average'
//   } else if (nScore >= 20) {
//     strText = 'Weak'
//   } else {
//     strText = 'Very Weak'
//   }
//
//   return strText
// }
