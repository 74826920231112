export interface Props {
  open: boolean
  handleClose: () => void
  disableBackdropClick?: boolean
  disableEscapeKeyDown?: boolean
}

export enum AddNewUserGroupCardField {
  UserGroupName = 'userGroupName',
  Description = 'description',
  ClonePermission = 'clonePermission',
  UserGroup = 'userGroup',
}

export type AddUserGroupCardValues = {
  [AddNewUserGroupCardField.UserGroupName]: string
  [AddNewUserGroupCardField.Description]: string
  [AddNewUserGroupCardField.ClonePermission]: boolean
  [AddNewUserGroupCardField.UserGroup]: string
}
