import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  onEventNameClickTypes,
  onTitleClickTypes,
} from '@/components/blocks/ATMeye/notifications/types/types'
import { StrategyLocalStorage } from '@/components/NPMPakage/components/tableComponents/UITable/strategy'
import { defaultLsKeys as defaultLsKeysAlarms } from '@/components/pages/atmeye/Alarms'
import { AlarmReactionFilterOptions } from '@/components/pages/atmeye/Alarms/hooks/useAlarms'
import { defaultLsKeys as defaultLsKeysDevices } from '@/components/pages/atmeye/Devices/hooks/useDevices'
import { defaultLsKeys as defaultLsKeysEvents } from '@/components/pages/atmeye/Events'
import { AppNavigationRoutes, ATMEYE_BLOCK_PREFIX, LocalStorageItems } from '@/constants'
import { notificationConfig } from '@/constants/atmeye/notificationConfig'
import {
  actionsNotifications,
  getAlarms,
  getBlockAlarms,
  getBlockEvents,
  getEvents,
} from '@/store/notifications'

const setPageSettingsLs = ({ sort, filtersTemplate = null, filters, lsKeys }: any) => {
  filters &&
    StrategyLocalStorage.setData(lsKeys.lsKeyFiltersTemplates, {
      selectedFiltersTemplate: filtersTemplate,
    })
  filters && StrategyLocalStorage.setData(lsKeys.lsKeyFilters, filters)
  sort && StrategyLocalStorage.setData(lsKeys.lsKeySorting, sort)
}

const getFiltersPayload = () => ({
  filterObject: {
    atmDateTime: [null, null],
    deviceId: [],
    eventMediaTypeId: [],
    eventTypeId: [],
    note: '',
    operator: '',
    reactionDateTime: [null, null],
    reaction: AlarmReactionFilterOptions.WithoutReaction,
  },
  sortingObject: {
    fieldName: 'atmDateTime',
    sortOrder: 'desc',
  },
})

const getFiltersEventPayload = () => ({
  filterObject: {
    deviceId: [],
    eventMediaTypeId: [],
    eventTypeId: [],
    note: '',
    atmDateTime: [null, null],
  },
  sortingObject: {
    fieldName: 'atmDateTime',
    sortOrder: 'desc',
  },
})

const useNotification = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const events = useSelector(getEvents)
  const alarms = useSelector(getAlarms)
  const blockAlarms = useSelector(getBlockAlarms)
  const blockEvents = useSelector(getBlockEvents)

  const technicalEventIds = useMemo(() => {
    return events.map(({ eventId }) => eventId).filter(x => !blockEvents.includes(x))
  }, [events, blockEvents])

  const securityAlarmIds = useMemo(() => {
    return alarms.map(({ eventId }) => eventId).filter(x => !blockAlarms.includes(x))
  }, [alarms, blockAlarms])

  const handleOpenSecurityAlarmsTable = (): void => {
    const state = getFiltersPayload()
    history.push({
      pathname: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.securityAlarms.root}`,
      state,
    })
    setPageSettingsLs({
      lsKeys: defaultLsKeysAlarms,
      filters: state.filterObject,
      sort: state.sortingObject,
    })
    dispatch(actionsNotifications.deleteAlarms({ eventIds: securityAlarmIds }))
  }

  const handleOpenTechnicalEventTable = (): void => {
    const state = getFiltersEventPayload()
    history.push({
      pathname: `/atmeye/events`,
      state,
    })
    setPageSettingsLs({
      lsKeys: defaultLsKeysEvents,
      filters: state.filterObject,
      sort: state.sortingObject,
    })
    dispatch(actionsNotifications.deleteEvents({ eventIds: technicalEventIds }))
  }

  const onViewAllClick = (container: notificationConfig) => () => {
    return container === notificationConfig.ALARM_EVENT
      ? handleOpenSecurityAlarmsTable()
      : handleOpenTechnicalEventTable()
  }
  const onTitleClick = ({ isAlarm, eventId, deviceId, deviceName }: onTitleClickTypes) => () => {
    if (isAlarm) {
      const state = {
        ...getFiltersPayload(),
        filterObject: {
          ...getFiltersPayload().filterObject,
          deviceId: [{ valueId: `${deviceId}`, valueLabels: deviceName }],
        },
      }
      history.push({
        pathname: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.securityAlarms.root}`,
        state,
      })
      setPageSettingsLs({
        lsKeys: defaultLsKeysAlarms,
        filters: state.filterObject,
        sort: state.sortingObject,
      })

      dispatch(actionsNotifications.deleteAlarms({ eventIds: [eventId] }))
    } else {
      const state = {
        ...getFiltersEventPayload(),
        filterObject: {
          ...getFiltersEventPayload().filterObject,
          deviceId: [{ valueId: `${deviceId}`, valueLabels: deviceName }],
        },
      }
      history.push({
        pathname: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.technicalEvents.root}`,
        state,
      })

      setPageSettingsLs({
        lsKeys: defaultLsKeysEvents,
        filters: state.filterObject,
        sort: state.sortingObject,
      })
      dispatch(actionsNotifications.deleteEvents({ eventIds: [eventId] }))
    }
  }

  const onEventNameClick = ({
    eventTypeId,
    eventId,
    isAlarm,
    deviceId,
    deviceName,
  }: onEventNameClickTypes) => (): void => {
    if (isAlarm) {
      const state = {
        ...getFiltersPayload(),
        filterObject: {
          ...getFiltersPayload().filterObject,
          deviceId: [{ valueId: `${deviceId}`, valueLabels: deviceName }],
          eventTypeId: [{ valueId: eventTypeId.toString() }],
        },
      }
      history.push({
        pathname: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.securityAlarms.root}`,
        state,
      })
      setPageSettingsLs({
        lsKeys: defaultLsKeysAlarms,
        filters: state.filterObject,
        sort: state.sortingObject,
      })

      dispatch(actionsNotifications.deleteAlarms({ eventIds: [eventId] }))
    } else {
      const state = {
        ...getFiltersEventPayload(),
        filterObject: {
          ...getFiltersEventPayload().filterObject,
          deviceId: [{ valueId: `${deviceId}`, valueLabels: deviceName }],
          eventTypeId: [{ valueId: eventTypeId.toString() }],
        },
      }
      history.push({
        pathname: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.technicalEvents.root}`,
        state: state,
      })

      setPageSettingsLs({
        lsKeys: defaultLsKeysEvents,
        filters: state.filterObject,
        sort: state.sortingObject,
      })

      dispatch(actionsNotifications.deleteEvents({ eventIds: [eventId] }))
    }
  }

  const handleOnArrowClick = (
    deviceName: string,
    eventId: number,
    deviceId: number,
  ) => (): void => {
    // dispatch(changeGrouping([{ key: 'connection_state', order: 'asc', orderBy: 'name' }]))
    const state = {
      filterObject: {
        deviceId: [{ valueId: `${deviceId}`, valueLabels: deviceName }],
        deviceName: '',
        connectivityStatusId: [],
        coreStatusId: [],

        licenseStatusId: [],
        countryId: [],
        cityId: [],
        vendorCompanyId: [],
        deviceTypeId: [],
        deviceModelId: [],
        serialNumber: '',
        address: '',
        lastActivityDateTime: [null, null],
      },
    }

    history.push({
      pathname: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.devices.root}`,
      state,
    })
    setPageSettingsLs({
      lsKeys: defaultLsKeysDevices,
      filters: state.filterObject,
    })
    dispatch(actionsNotifications.closeSecurityAlarm({ eventIds: [eventId] }))
  }

  return { onTitleClick, onEventNameClick, onViewAllClick, handleOnArrowClick }
}

export { useNotification }
