import moment from 'moment'

import { MonCashPoint } from '@/api/cm/swaggerGeneratedApi'
import { INITIAL_LOCALE, ISOLanguagesCodes } from '@/constants'
import { CashPointDetailsHeader } from '@/types/cm/monitoring'

export const parseDate = (date: string) => {
  const stopIndex = date.indexOf('T')
  return date.substring(0, stopIndex)
}

export const updateHeaders = (headers: CashPointDetailsHeader[]): CashPointDetailsHeader[] =>
  headers.filter(
    (h: CashPointDetailsHeader) =>
      String(h.field) !== 'col_date' ||
      String(h.field) !== 'col_currency' ||
      String(h.field) !== 'col_replenished',
  )

export const checkIsDateAfterToday = (date: moment.MomentInput): boolean =>
  moment(date).isSameOrAfter(moment(), 'day')

export const isCashPointAcceptable = ({ replenDate, planStatus }: MonCashPoint): boolean =>
  Boolean(
    replenDate &&
      checkIsDateAfterToday(replenDate) &&
      planStatus !== 'Accepted' &&
      planStatus !== 'SentToExecute' &&
      planStatus !== 'Executed',
  )

export const isCashPointDeclinable = ({ replenDate, planStatus }: MonCashPoint): boolean =>
  Boolean(replenDate && checkIsDateAfterToday(replenDate) && planStatus === 'Accepted')

export const formatNumber = (value: number | undefined, locale: string | null): string | number => {
  if (value) {
    return value.toLocaleString(ISOLanguagesCodes[locale || INITIAL_LOCALE])
  }
  if (value === 0) {
    return value
  }
  return ''
}

export const secondConverter = (source: string): string => {
  let totalSeconds = parseInt(source, 10)

  if (isNaN(totalSeconds)) return '00:00:00'

  const addZero = (value: number): string => (value < 10 ? `0${value}` : value.toString())
  const hours = Math.floor(totalSeconds / 3600)

  totalSeconds %= 3600

  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60

  return `${addZero(hours)}:${addZero(minutes)}:${addZero(seconds)}`
}
