import React, { ReactElement, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PlasticCard } from '@/api/cm/swaggerGeneratedApi'
import { CellSelect, generateStatus } from '@/components/blocks/cm'
import { ConditionallyCell, ConditionallyCellResult } from '@/components/blocks/cm/CMTable/types'
import { ORDER_STATUSES } from '@/constants/cashManagement'
import {
  getPlcTeams,
  getTeamIsEnable,
  resetPlcTeamRequest,
  setPlcTeamRequest,
} from '@/store/cm/plasticCard'

import { useClasses } from './styles'
import { TableColumn } from './tableConfig'

interface TeamSelectProps {
  cpId?: string
  cardOrderId?: string
  teamId?: string
  teamName?: string
  stage?: string
}

interface WorkOrderProps {
  name: string
}

const TeamSelect = React.memo(
  ({ cpId, cardOrderId, teamId, teamName, stage }: TeamSelectProps): ReactElement | null => {
    const classes = useClasses()
    const dispatch = useDispatch()

    const teams = useSelector(getPlcTeams)
    const isEnable = useSelector(getTeamIsEnable(stage))

    const handleChange = useCallback(
      (value: string): void => {
        if (cpId) {
          if (value) {
            dispatch(setPlcTeamRequest(cpId, value))
          } else if (cardOrderId) {
            dispatch(resetPlcTeamRequest(cpId, cardOrderId))
          }
        }
      },
      [cpId, cardOrderId],
    )

    return isEnable ? (
      <CellSelect
        className={classes.teamSelect}
        defaultValue={teamId || ''}
        options={teams || []}
        onChange={handleChange}
      />
    ) : (
      <>{teamName || null}</>
    )
  },
)

const WorkOrder = React.memo(
  ({ name }: WorkOrderProps): ReactElement => {
    return <>{name}</>
  },
)

export const conditionallyRenderCell = ({
  key,
  value,
  row,
}: ConditionallyCell<PlasticCard>): ConditionallyCellResult => {
  switch (key) {
    case TableColumn.OrderStatus:
      return value && value !== 'None' ? generateStatus({ value, statuses: ORDER_STATUSES }) : null

    case TableColumn.TeamName: {
      const { teamId, teamName, cardOrderId, cpId, stage } = row || {}

      return (
        <TeamSelect
          teamId={teamId}
          teamName={teamName}
          cpId={cpId}
          cardOrderId={cardOrderId}
          stage={stage}
        />
      )
    }

    case TableColumn.WorkOrder: {
      const { workOrderName } = row || {}

      return workOrderName ? <WorkOrder name={workOrderName} /> : null
    }
    default:
      return value
  }
}
