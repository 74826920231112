import { AnyAction } from 'redux'

import {
  Currency,
  DeclineNote,
  IntegrationResponse,
  Order,
  OrderBase,
  OrderCash,
  OrderEdit,
  OrderReportGeneratorEntry,
  OrdersFilter,
  OrdersRequest,
  OrdersResponse,
  OrderValuable,
  OrderVault,
  SelectorItem,
  WorkOrder,
  WorkOrderSeals,
  WorkOrdersResponse,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { StageRequestParams } from '@/components/pages/vault-v1/CashOrdersPage/components/panels/OrderStages/types'
import { VaultV1Actions } from '@/constants/actions'
import { SelectableItem } from '@/types'
import { KeyPressActionName } from '@/types/cm'

import { StageButtonModalsOpen } from './types'

export const getOrdersRequest = (): AnyAction => ({
  type: VaultV1Actions.OrdGetOrdersRequest,
})

export const getOrdersResponse = (payload: OrdersResponse): AnyAction => ({
  type: VaultV1Actions.OrdGetOrdersResponse,
  payload,
})

export const getOrdersFail = (error?: string): AnyAction => ({
  type: VaultV1Actions.OrdGetOrdersFail,
  payload: error,
})

export const refreshOrdOrders = (): AnyAction => ({
  type: VaultV1Actions.OrdRefreshOrders,
})

export const getSingleVaultRequest = (id?: string): AnyAction => ({
  type: VaultV1Actions.OrdGetSingleVaultRequest,
  payload: id,
})
export const getSingleVaultResponse = (payload: Order): AnyAction => ({
  type: VaultV1Actions.OrdGetSingleVaultResponse,
  payload,
})
export const getSingleVaultFail = (error: string): AnyAction => ({
  type: VaultV1Actions.OrdGetSingleVaultFail,
  error,
})

export const setOrdSelectedOrder = (payload: Order | null): AnyAction => ({
  type: VaultV1Actions.OrdSetSelectedOrder,
  payload: payload,
})

export const setOrdOrdersPaging = (payload: { page?: number; pageSize?: number }): AnyAction => ({
  type: VaultV1Actions.OrdSetOrdersPaging,
  payload: payload,
})

export const sortOrdOrdersTable = (payload: {
  sortColumn: string | undefined
  sortOrder: 'asc' | 'desc' | undefined
}): AnyAction => ({
  type: VaultV1Actions.OrdSortOrdersTable,
  payload,
})

export const resetOrdOrdersPaging = (): AnyAction => ({
  type: VaultV1Actions.OrdResetOrdersPaging,
})

// [START] Modals ---------------------------------------------------------------------
export const openOrdColumnsSettingsModal = (isOpen: boolean): AnyAction => ({
  type: VaultV1Actions.OrdOpenColumnsSettingsModal,
  payload: isOpen,
})
export const openOrdDocumentsModal = (isOpen: boolean): AnyAction => ({
  type: VaultV1Actions.OrdOpenDocumentsModal,
  payload: isOpen,
})

export const openOrdFiltersModal = (isOpen: boolean): AnyAction => ({
  type: VaultV1Actions.OrdOpenFilterModal,
  payload: isOpen,
})

export const openOrdStageDocumentsModal = (isOpen: boolean): AnyAction => ({
  type: VaultV1Actions.OrdOpenStageDocumentsModal,
  payload: isOpen,
})

// [END] Modals ---------------------------------------------------------------------

export const handleApproveAllOrdRequest = (): AnyAction => ({
  type: VaultV1Actions.OrdApproveAllRequest,
})
export const handleApproveAllOrdFail = (): AnyAction => ({
  type: VaultV1Actions.OrdApproveAllFail,
})

export const submitOrdFilter = (payload: OrdersRequest): AnyAction => ({
  type: VaultV1Actions.OrdSubmitFilterRequest,
  payload,
})

export const setOrdFilter = (payload: OrdersFilter): AnyAction => ({
  type: VaultV1Actions.OrdSetOrdersFilter,
  payload,
})

// [START] Filter fields options request
export const getOrdFilterCurrencyRequest = (): AnyAction => ({
  type: VaultV1Actions.OrdGetFilterCurrencyRequest,
})
export const getOrdFilterCurrencyResponse = (payload: Currency[]): AnyAction => ({
  type: VaultV1Actions.OrdGetFilterCurrencyResponse,
  payload,
})

export const getOrdFilterObjectIdRequest = (): AnyAction => ({
  type: VaultV1Actions.OrdGetFilterObjectIdRequest,
})
export const getOrdFilterObjectIdResponse = (payload: SelectorItem[]): AnyAction => ({
  type: VaultV1Actions.OrdGetFilterObjectIdResponse,
  payload,
})

export const getOrdFilterTeamRequest = (): AnyAction => ({
  type: VaultV1Actions.OrdGetFilterTeamRequest,
})
export const getOrdFilterTeamResponse = (payload: SelectorItem[]): AnyAction => ({
  type: VaultV1Actions.OrdGetFilterTeamResponse,
  payload,
})

export const getOrdFilterTypeRequest = (): AnyAction => ({
  type: VaultV1Actions.OrdGetFilterTypeRequest,
})
export const getOrdFilterTypeResponse = (payload: SelectableItem[]): AnyAction => ({
  type: VaultV1Actions.OrdGetFilterTypeResponse,
  payload,
})

export const getOrdFilterStatusRequest = (): AnyAction => ({
  type: VaultV1Actions.OrdGetFilterStatusRequest,
})
export const getOrdFilterStatusResponse = (payload: SelectableItem[]): AnyAction => ({
  type: VaultV1Actions.OrdGetFilterStatusResponse,
  payload,
})
// [END] Filter fields options request

// Cash Orders stages
export const getOrdersStagesResponse = (payload: OrdersResponse): AnyAction => ({
  type: VaultV1Actions.OrdGetOrdersStagesResponse,
  payload,
})
export const getOrdersStagesFail = (error?: string): AnyAction => ({
  type: VaultV1Actions.OrdGetOrdersStagesFail,
  payload: error,
})

export const setOrderStageRequest = (payload: StageRequestParams): AnyAction => ({
  type: VaultV1Actions.OrdSetOrdersStagesRequest,
  payload,
})
export const setOrderStageResponse = (): AnyAction => ({
  type: VaultV1Actions.OrdSetOrdersStagesResponse,
})
export const setOrderStageFail = (error?: string): AnyAction => ({
  type: VaultV1Actions.OrdSetOrdersStagesFail,
  payload: error,
})

// Reports
export const getOrdReportsRequest = (): AnyAction => ({
  type: VaultV1Actions.GetOrdReportsRequest,
})
export const getOrdReportsResponse = (payload: OrderReportGeneratorEntry[]): AnyAction => ({
  type: VaultV1Actions.GetOrdReportsResponse,
  payload,
})
export const getOrdReportsFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.GetOrdReportsFail,
  payload: message,
})

export const getOrdReportRequest = (payload?: OrderReportGeneratorEntry): AnyAction => ({
  type: VaultV1Actions.GetOrdReportRequest,
  payload,
})
export const getOrdReportResponse = (payload: string | null): AnyAction => ({
  type: VaultV1Actions.GetOrdReportResponse,
  payload,
})
export const getOrdReportFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.GetOrdReportFail,
  payload: message,
})

// Stage Reports
export const getOrdStageReportsRequest = (): AnyAction => ({
  type: VaultV1Actions.GetOrdStageReportsRequest,
})
export const getOrdStageReportsResponse = (payload: OrderReportGeneratorEntry[]): AnyAction => ({
  type: VaultV1Actions.GetOrdStageReportsResponse,
  payload,
})
export const getOrdStageReportsFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.GetOrdStageReportsFail,
  payload: message,
})

export const getOrdStageReportRequest = (payload?: OrderReportGeneratorEntry): AnyAction => ({
  type: VaultV1Actions.GetOrdStageReportRequest,
  payload,
})
export const getOrdStageReportResponse = (payload: string | null): AnyAction => ({
  type: VaultV1Actions.GetOrdStageReportResponse,
  payload,
})
export const getOrdStageReportFail = (message?: string): AnyAction => ({
  type: VaultV1Actions.GetOrdStageReportFail,
  payload: message,
})

// DeliveryOrder ------------------------------------------------------
interface OpenOrderModal {
  isOpen: boolean
  isEdit?: boolean
}
export const openOrdCashOrderModal = ({ isOpen, isEdit = false }: OpenOrderModal): AnyAction => ({
  type: VaultV1Actions.OrdOpenCashOrderModal,
  payload: { isOpen, isEdit },
})
export const openOrdExportOrderModal = ({ isOpen, isEdit = false }: OpenOrderModal): AnyAction => ({
  type: VaultV1Actions.OrdOpenExportOrderModal,
  payload: { isOpen, isEdit },
})
// Destinations
export const getOrdDestinationsRequest = (): AnyAction => ({
  type: VaultV1Actions.GetOrdDestinationsRequest,
})
export const getOrdDestinationsResponse = (payload: OrderVault[]): AnyAction => ({
  type: VaultV1Actions.GetOrdDestinationsResponse,
  payload,
})
export const getOrdDestinationsFail = (): AnyAction => ({
  type: VaultV1Actions.GetOrdDestinationsFail,
})

export const setOrdSelectedDestinations = (payload: string): AnyAction => ({
  type: VaultV1Actions.SetOrdSelectedDestination,
  payload,
})

// Sources
export const getOrdSourcesRequest = (): AnyAction => ({
  type: VaultV1Actions.GetOrdSourcesRequest,
})
export const getOrdSourcesResponse = (payload: OrderVault[]): AnyAction => ({
  type: VaultV1Actions.GetOrdSourcesResponse,
  payload,
})
export const getOrdSourcesFail = (): AnyAction => ({
  type: VaultV1Actions.GetOrdSourcesFail,
})

export const setOrdSelectedSours = (payload: string): AnyAction => ({
  type: VaultV1Actions.SetOrdSelectedSours,
  payload,
})

// Denominations
export const getOrdDenominationsRequest = (): AnyAction => ({
  type: VaultV1Actions.GetOrdDenominationsRequest,
})
export const getOrdDenominationsResponse = (payload: OrderCash[]): AnyAction => ({
  type: VaultV1Actions.GetOrdDenominationsResponse,
  payload,
})
export const getOrdDenominationsFail = (): AnyAction => ({
  type: VaultV1Actions.GetOrdDenominationsFail,
})

export const setOrdSelectedCashCurrency = (payload: string): AnyAction => ({
  type: VaultV1Actions.SetOrdSelectedCashCurrency,
  payload,
})

export const setOrdOrderDate = (payload: string): AnyAction => ({
  type: VaultV1Actions.SetOrdOrderDate,
  payload,
})

export const addOrdCashOrderRequest = (payload: OrderBase): AnyAction => ({
  type: VaultV1Actions.AddOrdCashOrderRequest,
  payload,
})
export const addOrdCashOrderResponse = (newOrderId: string): AnyAction => ({
  type: VaultV1Actions.AddOrdCashOrderResponse,
  payload: newOrderId,
})
export const addOrdCashOrderFail = (payload: string): AnyAction => ({
  type: VaultV1Actions.AddOrdCashOrderFail,
  payload,
})

export const editOrdCashOrderRequest = (payload: OrderEdit): AnyAction => ({
  type: VaultV1Actions.EditOrdCashOrderRequest,
  payload,
})
export const editOrdCashOrderResponse = (): AnyAction => ({
  type: VaultV1Actions.EditOrdCashOrderResponse,
})
export const editOrdCashOrderFail = (payload: string): AnyAction => ({
  type: VaultV1Actions.EditOrdCashOrderFail,
  payload,
})

// Cash Valuables
export const getOrdValuablesRequest = (): AnyAction => ({
  type: VaultV1Actions.GetOrdValuablesRequest,
})
export const getOrdValuablesResponse = (payload: OrderValuable[]): AnyAction => ({
  type: VaultV1Actions.GetOrdValuablesResponse,
  payload,
})
export const getOrdValuablesFail = (payload: string): AnyAction => ({
  type: VaultV1Actions.GetOrdValuablesFail,
  payload,
})

// [START] Stage modal action ---------------------------------------------------
export const setOrdDeclineModalOpen = (payload: StageButtonModalsOpen): AnyAction => ({
  type: VaultV1Actions.SetOrdDeclineModalOpen,
  payload,
})
export const setOrdTeamAssignModalOpen = (payload: StageButtonModalsOpen): AnyAction => ({
  type: VaultV1Actions.SetOrdTeamAssignModalOpen,
  payload,
})
export const setOrdBagSealsModalOpen = (payload: StageButtonModalsOpen): AnyAction => ({
  type: VaultV1Actions.SetOrdBagSealsModalOpen,
  payload,
})
export const setOrdCashierIdModalOpen = (payload: StageButtonModalsOpen): AnyAction => ({
  type: VaultV1Actions.SetOrdCashierIdModalOpen,
  payload,
})

export const setOrdCashierIdRequest = (payload: StageRequestParams): AnyAction => ({
  type: VaultV1Actions.SetOrdCashierIdRequest,
  payload,
})
export const setOrdCashierIdResponse = (payload: boolean): AnyAction => ({
  type: VaultV1Actions.SetOrdCashierIdResponse,
  payload,
})
export const setOrdCashierIdClear = (): AnyAction => ({
  type: VaultV1Actions.SetOrdCashierIdClear,
})
// [END] Stage modal action ---------------------------------------------------

export const getOrdBagSealRequest = (): AnyAction => ({
  type: VaultV1Actions.GetOrdBagSealRequest,
})
export const getOrdBagSealResponse = (payload: WorkOrderSeals[]): AnyAction => ({
  type: VaultV1Actions.GetOrdBagSealResponse,
  payload,
})
export const getOrdBagSealFail = (payload: string): AnyAction => ({
  type: VaultV1Actions.GetOrdBagSealFail,
  payload,
})

export const setOrdBagSealRequest = (payload: {
  url: string
  requestBody: WorkOrderSeals[]
}): AnyAction => ({
  type: VaultV1Actions.SetOrdBagSealRequest,
  payload,
})
export const setOrdBagSealResponse = (): AnyAction => ({
  type: VaultV1Actions.SetOrdBagSealResponse,
})
export const setOrdBagSealFail = (payload: string): AnyAction => ({
  type: VaultV1Actions.SetOrdBagSealFail,
  payload,
})

// Export Order ---------------------------------------------------------

// Destinations
export const getOrdExpDestinationsRequest = (): AnyAction => ({
  type: VaultV1Actions.GetOrdExpDestinationsRequest,
})

// Sources
export const getOrdExpSourcesRequest = (): AnyAction => ({
  type: VaultV1Actions.GetOrdExpSourcesRequest,
})

// Denominations
export const getOrdExpDenominationsRequest = (): AnyAction => ({
  type: VaultV1Actions.GetOrdExpDenominationsRequest,
})

export const setOrdDeclineNoteModalOpen = (isOpen: boolean): AnyAction => ({
  type: VaultV1Actions.OrdSetDeclineNoteModalOpen,
  payload: isOpen,
})

export const setOrdDeclineNote = (declineNote: DeclineNote): AnyAction => ({
  type: VaultV1Actions.OrdSetDeclineNote,
  payload: declineNote,
})

// [START] Work Order ---------------------------------------------------------
export const setOrdWorkOrderModalOpen = (payload: StageButtonModalsOpen): AnyAction => ({
  type: VaultV1Actions.OrdSetWorkOrderModalOpen,
  payload: payload,
})

export const setOrdSelectedWO = (payload: string): AnyAction => ({
  type: VaultV1Actions.OrdSetSelectedWorkOrder,
  payload: payload,
})

export const getOrdWorkOrderRequest = (): AnyAction => ({
  type: VaultV1Actions.GetOrdWorkOrderRequest,
})
export const getOrdWorkOrderResponse = (payload: WorkOrdersResponse): AnyAction => ({
  type: VaultV1Actions.GetOrdWorkOrderResponse,
  payload,
})
export const getOrdWorkOrderFail = (payload: string): AnyAction => ({
  type: VaultV1Actions.GetOrdWorkOrderFail,
  payload,
})
export const setOrdCheckedAllGroupedWO = (payload: boolean): AnyAction => ({
  type: VaultV1Actions.OrdSetCheckedAllGroupedWO,
  payload,
})
export const setOrdCheckedGroupedWO = (payload: WorkOrder): AnyAction => ({
  type: VaultV1Actions.OrdSetCheckedGroupedWO,
  payload,
})

export const setOrdCheckedAllExportWO = (payload: boolean): AnyAction => ({
  type: VaultV1Actions.OrdSetCheckedAllExportWO,
  payload,
})
export const setOrdCheckedExportWO = (payload: WorkOrder): AnyAction => ({
  type: VaultV1Actions.OrdSetCheckedExportWO,
  payload,
})

export const setOrdWorkOrderRequest = (payload: StageRequestParams): AnyAction => ({
  type: VaultV1Actions.SetOrdWorkOrderRequest,
  payload,
})
export const setOrdWorkOrderResponse = (): AnyAction => ({
  type: VaultV1Actions.SetOrdWorkOrderResponse,
})
export const setOrdWorkOrderFail = (payload: string): AnyAction => ({
  type: VaultV1Actions.SetOrdWorkOrderFail,
  payload,
})
// [END] Work Order ---------------------------------------------------------

// [START] Stage Integration ---------------------------------------------------------
export const getOrdStageIntegrationRequest = (): AnyAction => ({
  type: VaultV1Actions.GetOrdStageIntegrationRequest,
})
export const getOrdStageIntegrationResponse = (payload: IntegrationResponse): AnyAction => ({
  type: VaultV1Actions.GetOrdStageIntegrationResponse,
  payload,
})
export const getOrdStageIntegrationFail = (payload: string): AnyAction => ({
  type: VaultV1Actions.GetOrdStageIntegrationFail,
  payload,
})

export const retryOrdStageIntegrationRequest = (retryStageId: string): AnyAction => ({
  type: VaultV1Actions.RetryOrdStageIntegrationRequest,
  payload: retryStageId,
})
export const retryOrdStageIntegrationResponse = (): AnyAction => ({
  type: VaultV1Actions.RetryOrdStageIntegrationResponse,
})
export const retryOrdStageIntegrationFail = (payload: string): AnyAction => ({
  type: VaultV1Actions.RetryOrdStageIntegrationFail,
  payload,
})
// [END] Stage Integration ---------------------------------------------------------

export const handleOrdKeyPress = (actionName: KeyPressActionName): AnyAction => ({
  type: VaultV1Actions.OrdHandleKeyPress,
  payload: actionName,
})
