import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Select } from '@/components/controls/Select/component'
import { Props } from './types'
import { SortBlock } from '@/components/pages/sd/TicketsPage/components/GroupingModal/components/SortBlock/components'
import Test from 'react-test-attributes'
import { getTestId } from '@/utils'
import { TicketsConfigData } from '@/types'
import { useSelector } from 'react-redux'
import { getTicketsConfigData } from '@/store/sd/tickets/selectors'
import { useClasses } from './style'
import { useTranslate } from '@/utils/internalization'

export const GroupRow = ({
  testIdPrefix,
  label,
  fields,
  setGroup,
  group,
  disabled,
}: Props): React.ReactElement => {
  const [value, setValue] = useState('')
  const ticketsConfig: TicketsConfigData = useSelector(getTicketsConfigData)
  const idPrefix = `${testIdPrefix}-group-row`
  const testId = getTestId(idPrefix)
  const classes = useClasses()
  const translate = useTranslate()

  useEffect((): void => {
    setValue(group ? group.key || '' : '')
  }, [group])

  const handleChange = useCallback(
    (e: ChangeEvent<{ value: any }>): void => {
      setValue(e.target.value)
      if (e.target.value) {
        setGroup({ key: e.target.value, orderBy: 'name', order: 'asc' })
      } else {
        setGroup(undefined)
      }
    },
    [setGroup],
  )

  const gropsOptions = useMemo(() => {
    return fields.map(field => {
      const name = ticketsConfig.groups.find(group => group.groupUID === field)?.groupName || ''
      return { name, value: field }
    })
  }, [fields, ticketsConfig.groups])

  return (
    <Test id={testId(0)}>
      <Grid container spacing={2}>
        <Grid item sm={8}>
          <Test id={testId(1)}>
            <Select
              className={classes.selectButton}
              disabled={disabled}
              label={label}
              shrink
              value={value}
              onChange={handleChange}
              options={gropsOptions}
            />
          </Test>
        </Grid>
        <Grid item sm={4}>
          <Test id={testId(2)}>
            <SortBlock
              testIdPrefix={idPrefix}
              disabled={disabled || !value}
              groupKey={value}
              group={group}
              setGroup={setGroup}
            />
          </Test>
        </Grid>
      </Grid>
    </Test>
  )
}
