import { getRequestService } from '@/utils/services/request'
import { APIConfiguration } from '@/constants'

export class TeamsApi {
  public static requestService = getRequestService(APIConfiguration.VAULT_API_BASE_URL)

  public static async getTeams(): Promise<{ [key: string]: string }> {
    try {
      const teamsResponse = await TeamsApi.requestService.get('/teams/')
      return teamsResponse
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getTeam(teamID: string): Promise<{ [key: string]: string }> {
    try {
      const teamsResponse = await TeamsApi.requestService.get(`/teams/${teamID}`)
      return teamsResponse
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async createTeam(teamData: any): Promise<{ [key: string]: string }> {
    try {
      const teamsResponse = await TeamsApi.requestService.post('/teams/', teamData)
      return teamsResponse
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async editTeam(teamData: any): Promise<{ [key: string]: string }> {
    try {
      const teamsResponse = await TeamsApi.requestService.put('/teams/', teamData)
      return teamsResponse
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async deleteTeam(teamID: any): Promise<{ [key: string]: string }> {
    try {
      const teamsResponse = await TeamsApi.requestService.delete(`/teams/${teamID}`)
      return teamsResponse
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getTeamMembers(teamID: any): Promise<{ [key: string]: string }> {
    try {
      const teamsResponse = await TeamsApi.requestService.get(`/teams/members/${teamID}`)
      return teamsResponse
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getRoles(): Promise<{ [key: string]: string }> {
    try {
      const roles = await TeamsApi.requestService.get('/approles/cit')
      return roles
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getUsersForTeam(roleID: any): Promise<{ [key: string]: string }> {
    try {
      const teamsResponse = await TeamsApi.requestService.get(`/appusers/team/${roleID}`)
      return teamsResponse
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCompanies(): Promise<{ [key: string]: string }> {
    try {
      const companies = await TeamsApi.requestService.get('/comapnies/')
      return companies
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getVehicles(): Promise<{ [key: string]: string }> {
    try {
      const vehicles = await TeamsApi.requestService.get('/vehicles/')
      return vehicles
    } catch (e) {
      throw new Error(e)
    }
  }
}
