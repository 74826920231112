import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

import { useClasses } from './styles'
import { Props } from './types'

const InputPagination = ({
  onChangePage,
  rightPagin,
  paginInputCenter,
}: Props): React.ReactElement => {
  const classes = useClasses({ rightPagin, paginInputCenter })

  const handleJumpPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // onChangePage(event.container.value)
    },
    [onChangePage],
  )

  return (
    <div className={classes.wrapperInput}>
      <div className={classes.container}>
        <span>
          <FormattedMessage id="title.jumToPage" defaultMessage="Jump to page" />
        </span>
      </div>
      <div>
        <input type="text" className={classes.input} onChange={handleJumpPage} />
      </div>
    </div>
  )
}

export default InputPagination
