import { useCallback, useEffect, useRef, useState } from 'react'

type useScrollTypes = {
  parentRef: any
  childRef: any
  loading?: boolean
  callback: (page: number, limit: number) => void
  listLength: number
  limitSize?: number
  pageLimit?: number
}

const useScroll = ({
  limitSize = 10,
  childRef,
  parentRef,
  listLength,
  loading,
  callback,
  pageLimit = 10,
}: useScrollTypes): void => {
  const [pageNumber, setPageNumber] = useState(1)

  const fetchData = useCallback((): void => {
    if (!listLength && pageNumber > 1) {
      callback(1, limitSize)
      setPageNumber(1)
      return
    }
    if (listLength === 0) {
      return undefined
    }
    if (listLength && listLength < limitSize) {
      setPageNumber(1)
    } else {
      callback(pageNumber + 1, limitSize)
      setPageNumber(pageNumber => pageNumber + 1)
    }
  }, [callback, limitSize, listLength, pageNumber])

  const observer = useRef<IntersectionObserver>()

  useEffect(() => {
    if (parentRef && childRef) {
      const options = {
        root: parentRef,
        rootMargin: '0px',
        threshold: 0,
      }

      observer.current = new IntersectionObserver(([target]) => {
        if (target.isIntersecting) {
          if (!loading) {
            fetchData()
          }
        }
      }, options)

      if (!parentRef || !childRef || pageNumber >= pageLimit || loading) {
        // eslint-disable-next-line no-unused-expressions
        observer?.current?.disconnect()
        return
      }

      observer.current.observe(childRef as Element)

      return (): void => {
        if (observer?.current || pageNumber >= pageLimit) {
          // eslint-disable-next-line no-unused-expressions
          observer.current?.disconnect()
        }
      }
    }
  }, [childRef, fetchData, loading, pageLimit, pageNumber, parentRef])
}

export { useScroll }
