import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getState = (state: AppState): fromReducer.State => {
  return state.cmAdmin?.appConfig.eventNotifications
}

export const getEventNotifications = createSelector(getState, state => state)

export const getEventNotificationsSort = createSelector(getState, state => ({
  sortColumn: state.sortColumn,
  sortOrder: state.sortOrder,
}))
