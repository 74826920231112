import { Permission } from '@/components/NPMPakage/components/wrappers/types'

export enum TABS {
  HISTOGRAM = 0,
  TRANSACTIONS = 1,
  SECURITY_ALARMS = 2,
  TECHNICAL_EVENTS = 3,
  PROPERTIES = 4,
  STATUS = 5,
  TELTONIKA = 6,
}

export const tabsData = [
  {
    tabNumber: TABS.HISTOGRAM,
    label: 'translate#atmeye.title.histogram',
    permission: Permission.View_Histogram,
  },
  {
    tabNumber: TABS.TRANSACTIONS,
    label: 'translate#atmeye.title.transactions',
    permission: Permission.Transactions,
  },
  {
    tabNumber: TABS.SECURITY_ALARMS,
    label: 'translate#atmeye.title.securityAlarms',
    permission: Permission.Alarms,
  },
  {
    tabNumber: TABS.TECHNICAL_EVENTS,
    label: 'translate#atmeye.title.technicalEvents',
    permission: Permission.Technical_Events,
  },
  {
    tabNumber: TABS.PROPERTIES,
    label: 'translate#title.properties',
    permission: Permission.View_Properties,
  },
  {
    tabNumber: TABS.STATUS,
    label: 'translate#title.status',
    permission: Permission.View_Properties,
  },
  {
    tabNumber: TABS.TELTONIKA,
    label: 'translate#title.teltonikaTab',
    permission: Permission.View_Properties,
    // TODO ATMEYE fix permissions
  },
]
