import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  wrapper: {
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
  },
  buttons: {
    marginTop: spacing(2),
  },
  asterisk: {
    color: 'red',
    display: 'inline',
  },
}))
