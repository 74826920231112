import React, { ReactElement, useEffect, useRef, useState } from 'react'

import { GoogleMapATMeye } from '../GoogleMapATMeye'
import { useClasses } from './styles'
import { Props } from './types'

const useContainerDimensions = (myRef: any) => {
  const getDimensions = () => ({
    width: myRef.current.offsetWidth,
    height: myRef.current.offsetHeight,
  })

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const handleResize = (): void => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [myRef])

  return dimensions
}

export const ATMeyeMap = ({
  tab,
  currentDevice,
  mapPoints,
  mapPointsInfo,

  handleSetCurrentDeviceId,
}: Props): ReactElement => {
  const classes = useClasses()

  const componentRef = useRef(null)
  const { height, width } = useContainerDimensions(componentRef)

  return (
    <div ref={componentRef} className={classes.map}>
      <GoogleMapATMeye
        tab={tab}
        mapHeight={height}
        mapWidth={width}
        currentDevice={currentDevice}
        mapPoints={mapPoints}
        mapPointsInfo={mapPointsInfo}
        handleSetCurrentDeviceId={handleSetCurrentDeviceId}
      />
    </div>
  )
}
