export enum PersonalInformationField {
  FirstName = 'firstName',
  MiddleName = 'middleName',
  LastName = 'lastName',
  Salutation = 'salutation',
  Gender = 'gender',
}

export enum LoginField {
  ChangePassword = 'changePassword',
  Login = 'loginName',
  Password = 'password',
  RepeatPassword = 'repeatPassword',
  TryCount = 'tryCount',
}

export interface PersonalInstallationValues {
  [PersonalInformationField.FirstName]: string
  [PersonalInformationField.LastName]: string
  [PersonalInformationField.MiddleName]: string
  [PersonalInformationField.Salutation]: string
  [PersonalInformationField.Gender]: string
  [LoginField.ChangePassword]: boolean
  [LoginField.Login]: string
  [LoginField.Password]: string
  [LoginField.RepeatPassword]: string
  [LoginField.TryCount]: string
}
