import { AnyAction } from 'redux'

import { CompanyType, ServiceCompany } from '@/api/companies/companiesApi/types'
import { companiesActions } from '@/constants/actions'
import { ResponseError, Unit } from '@/types'

export const getCompanyTypesRequest = (deviceId: number): AnyAction => ({
  type: companiesActions.GetCompanyTypeRequest,
  payload: { deviceId },
})

export const getCompanyTypesResponse = (companyTypes: CompanyType[]): AnyAction => ({
  type: companiesActions.GetCompanyTypeResponse,
  payload: companyTypes,
})
export const getCompanyTypesFail = (error: ResponseError): AnyAction => ({
  type: companiesActions.GetCompanyTypeFail,
  payload: error,
})

export const findUnitsRequest = (str: string, byLuno: boolean): AnyAction => ({
  type: companiesActions.FindUnitsRequest,
  payload: { str, byLuno },
})
export const findUnitsResponse = (units: Partial<Unit>[]): AnyAction => {
  return {
    type: companiesActions.FindUnitsResponse,
    payload: { units },
  }
}
export const findUnitsResponseFail = (error: ResponseError): AnyAction => ({
  type: companiesActions.FindUnitsResponseFail,
  payload: error,
})

export const getServiceCompanyRequest = (deviceId: number): AnyAction => ({
  type: companiesActions.GetServiceCompanyRequest,
  payload: { deviceId },
})

export const getServiceCompanyResponse = (serviceCompanies: ServiceCompany[]): AnyAction => ({
  type: companiesActions.GetServiceCompanyResponse,
  payload: serviceCompanies,
})
export const getServiceCompanyFail = (error: ResponseError): AnyAction => ({
  type: companiesActions.GetServiceCompanyeFail,
  payload: error,
})

export const setInitialServiceCompanies = () => ({
  type: companiesActions.setInitialServiceCompanies,
})
