import React from 'react'

import { store } from '@/App'

export const CashManagementRoutes = React.lazy(async () => {
  const { injectReducer, injectSaga } = store

  const {
    default: { reducer: cmSharedReducer, saga: cmSharedSaga },
  } = await import('@/store/cm/shared')

  injectReducer('cmShared', cmSharedReducer)
  injectSaga('cmShared', cmSharedSaga)

  const {
    default: { reducer, saga },
  } = await import('@/store/cm/monitoring')

  injectReducer('cmMonitoring', reducer)
  injectSaga('cmMonitoring', saga)

  const {
    default: { reducer: cmPlanningReducer, saga: cmPlanningSaga },
  } = await import('@/store/cm/planning')

  injectReducer('cmPlanning', cmPlanningReducer)
  injectSaga('cmPlanning', cmPlanningSaga)

  const {
    default: { reducer: cmOrdersReducer, saga: cmOrdersSaga },
  } = await import('@/store/cm/orders')

  injectReducer('cmOrders', cmOrdersReducer)
  injectSaga('cmOrders', cmOrdersSaga)

  const {
    default: { reducer: cmReplenishmentsReducer, saga: cmReplenishmentsSaga },
  } = await import('@/store/cm/replenishments')

  injectReducer('cmReplenishments', cmReplenishmentsReducer)
  injectSaga('cmReplenishments', cmReplenishmentsSaga)

  const {
    default: { reducer: cmBalancingReducer, saga: cmBalancingSaga },
  } = await import('@/store/cm/balancing')

  injectReducer('cmBalancing', cmBalancingReducer)
  injectSaga('cmBalancing', cmBalancingSaga)

  const {
    default: { reducer: cmMapReducer, saga: cmMapSaga },
  } = await import('@/store/cm/maps')

  injectReducer('cmMaps', cmMapReducer)
  injectSaga('cmMaps', cmMapSaga)

  const {
    default: { reducer: cmAnalysisReducer, saga: cmAnalysisSaga },
  } = await import('@/store/cm/analysis')

  injectReducer('cmAnalysis', cmAnalysisReducer)
  injectSaga('cmAnalysis', cmAnalysisSaga)

  const {
    default: { reducer: cmAdminReducer, saga: cmAdminSaga },
  } = await import('@/store/cm/admin')

  injectReducer('cmAdmin', cmAdminReducer)
  injectSaga('cmAdmin', cmAdminSaga)

  const {
    default: { reducer: cmCitPlanningReducer, saga: cmCitPlanningSaga },
  } = await import('@/store/cm/citPlanning')

  injectReducer('cmCitPlanning', cmCitPlanningReducer)
  injectSaga('cmCitPlanning', cmCitPlanningSaga)

  const {
    default: { reducer: cmCitBalancingReducer, saga: cmCitBalancingSaga },
  } = await import('@/store/cm/citBalancing')

  injectReducer('cmCitBalancing', cmCitBalancingReducer)
  injectSaga('cmCitBalancing', cmCitBalancingSaga)

  const {
    default: { reducer: cmPlcCardReducer, saga: cmPlcCardSaga },
  } = await import('@/store/cm/plasticCard')

  injectReducer('cmPlasticCard', cmPlcCardReducer)
  injectSaga('cmPlasticCard', cmPlcCardSaga)

  const {
    default: { reducer: commonRtsReducer, saga: commonRtsSaga },
  } = await import('@/store/common/routes')

  injectReducer('commonRoutes', commonRtsReducer)
  injectSaga('commonRoutes', commonRtsSaga)

  return import('@/components/routers/cm')
})
