import { createStyles, makeStyles } from '@material-ui/core/styles'

import colors from '@/theme/colors'

export const useClasses = makeStyles(({ spacing }) =>
  createStyles({
    chartWrapper: {
      width: '100%',
      display: 'flex',
    },

    chart: {
      width: '100%',
    },

    chartInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '400px',
    },

    sliderWrapper: {
      width: '90%',
      marginBottom: '10px',
    },

    segmentsBlock: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      cursor: 'pointer',
    },

    segmentWrapper: {
      display: 'flex',
      marginBottom: '5px',

      '&:hover': {
        background: colors.grey50,
      },
    },

    segmentInfo: {
      width: '100%',
      marginLeft: '10px',
    },

    markLabel: {
      fontSize: '14px',
      fontWeight: 500,
      color: colors.textLight,
    },

    markLabelActive: {
      color: colors.products.primary,
    },

    tooltipWrapper: {
      overflow: 'hidden',
      height: '240px',
      width: '170px',
      background: colors.background,
      position: 'relative',
    },

    tooltipLabelBlock: {
      marginLeft: '5px',
      marginBottom: '10px',
      display: 'flex',
    },

    closeButton: {
      marginLeft: 'auto',
      cursor: 'pointer',
      color: colors.products.black,
    },

    grabCursor: {
      cursor: 'grab!important',
    },

    pointerCursor: {
      cursor: 'pointer!important',
    },

    emptyCurrentDevice: {
      width: '100%',
      height: '155px',
      margin: '15px 24px',
      padding: '20px 33px',
      fontSize: '20px',
      fontWeight: 'normal',
      background: colors.background,
    },
  }),
)
