import queryString from 'query-string'

import { AdmStaff, AdmStaffManager } from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

interface GetStaffProps extends Sort {
  id: string
}

export class StaffApi {
  private static requestService = getRequestServiceCM()

  public static async get(payload: GetStaffProps): Promise<AdmStaff[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/staff',
          query: { ...payload },
        },
        { skipNull: true, encode: false },
      )

      return await StaffApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async edit(payload: AdmStaff[]): Promise<void> {
    try {
      return await StaffApi.requestService.put('/admin/staff/edit', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getManagers(sort: Sort): Promise<AdmStaffManager[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/staff/managers',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await StaffApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
