import queryString from 'query-string'

import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

interface UploadLogo {
  name: string
  logo: string
}

export class LogoAdminApi {
  private static requestService = getRequestServiceCM()

  public static async get(): Promise<string> {
    try {
      return await LogoAdminApi.requestService.get('/admin/config/logo')
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async getApproving(): Promise<string> {
    try {
      return await LogoAdminApi.requestService.get('/admin/config/logo/approval')
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async setApproving(approved: boolean): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/config/logo/approve',
          query: { approved },
        },
        { skipNull: true, encode: false },
      )
      return await LogoAdminApi.requestService.post(url, {})
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async upload({ logo, name }: UploadLogo): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/config/logo/upload',
          query: { name },
        },
        { skipNull: true, encode: false },
      )
      return await LogoAdminApi.requestService.postComplete(url, logo)
    } catch (error) {
      throw new Error(error)
    }
  }
}
