import { AnyAction } from 'redux'

import { AdmImportForecastSetting } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants/actions/cmActions'

// get
export const getForecastSettingsRequest = (): AnyAction => ({
  type: CMAppConfigActions.AdmGetForecastSettingsRequest,
})
export const getForecastSettingsResponse = (payload: AdmImportForecastSetting[]): AnyAction => ({
  type: CMAppConfigActions.AdmGetForecastSettingsResponse,
  payload: payload,
})
export const getForecastSettingsFail = (): AnyAction => ({
  type: CMAppConfigActions.AdmGetForecastSettingsFail,
})

// edit
export const saveForecastSettingsRequest = (payload: AdmImportForecastSetting[]): AnyAction => ({
  type: CMAppConfigActions.AdmSaveForecastSettingsRequest,
  payload,
})
export const saveForecastSettingsFail = (): AnyAction => ({
  type: CMAppConfigActions.AdmSaveForecastSettingsFail,
})
