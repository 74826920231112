import React, { FC, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { Typography } from '@material-ui/core'

import Tooltip from '@/components/blocks/Tooltip'

import { Props } from './types'
import { useClasses } from './styles'

export const CellWithTooltip: FC<Props> = React.memo((props: Props) => {
  const { value, tooltipValue = value, maxWidth } = props

  const classes = useClasses()

  const [tooltip, setTooltip] = useState<boolean>(false)

  const containerWrapperRef = useRef<HTMLDivElement>(null)
  const textWrapperRef = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    const containerWrapWith = containerWrapperRef.current
      ? containerWrapperRef.current.offsetWidth
      : 0
    const textWrapperWidth = textWrapperRef.current ? textWrapperRef.current.offsetWidth : 0

    if (containerWrapWith + 5 < textWrapperWidth) {
      setTooltip(true)
    }
  }, [containerWrapperRef, containerWrapperRef.current, value])

  return (
    <div
      ref={containerWrapperRef}
      className={clsx(classes.contentItem)}
      style={{ width: maxWidth }}
    >
      <Typography
        ref={textWrapperRef}
        className={clsx(classes.contentItemText, {
          [classes.contentItemTextWithTooltip]: tooltip,
        })}
      >
        {tooltip ? (
          <Tooltip
            className={classes.tooltip}
            title={tooltipValue || ''}
            classes={{ tooltip: classes.tooltipWrap }}
            interactive
          >
            <span
              className={clsx({
                [classes.withTooltip]: tooltip,
                [classes.contentItemTextWithTooltip]: tooltip,
              })}
            >
              {value}
            </span>
          </Tooltip>
        ) : (
          value
        )}
      </Typography>
    </div>
  )
})
