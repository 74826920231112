import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import { saveAs } from 'file-saver'
import React, {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FormattedMessage } from 'react-intl'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { Command } from '@/api/rsocket/command'
import DownloadIcon from '@/assets/sd/download.svg'
import CloseButton from '@/components/blocks/CloseButton'
import Loader from '@/components/blocks/Loader'
import { handleDownload } from '@/components/pages/atmeye/rfm/SingleDevice/utils/downloadFile'
import { SocketContext } from '@/context'
import { clearSocketDataByMessage, getSocketData } from '@/store/dashboard'

import { ContentWrapper, useClasses } from './styles'
import { DataFileType, Props } from './types'

const LogFileModal = ({
  open,
  onClose,
  selectedFile,
  currentDevice,
}: Props): React.ReactElement => {
  const classes = useClasses()
  const dispatch = useDispatch()

  const [logFile, setLogFile] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const [noResponse, setNoResponse] = useState<boolean>(false)
  const [logFileId, setLogFileId] = useState('')

  const { _service, connected } = useContext(SocketContext)
  const dataFile: DataFileType | undefined = useSelector(
    getSocketData(Command.GET_LOG_FILE),
    shallowEqual,
  )

  const timer: MutableRefObject<NodeJS.Timeout | undefined> = useRef()

  const handleClearTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current)
      timer.current = undefined
    }
  }, [])

  useEffect(() => {
    if (open) {
      setLoading(true)

      if (connected) {
        _service.send(
          {
            fileName: selectedFile.toString(),
          },
          currentDevice?.deviceName,
          Command.GET_LOG_FILE,
        )
      }

      timer.current = setTimeout(() => {
        setNoResponse(true)
      }, 300000)
    }
  }, [connected, open, _service, currentDevice, selectedFile])

  useEffect(() => {
    if (open) {
      if (dataFile) {
        dataFile.logFileId
          ? setLogFileId(dataFile.logFileId)
          : setLogFile(window.atob(dataFile.data))

        setLoading(false)
        handleClearTimer()
        setNoResponse(false)
      }
    }
  }, [dataFile, open, timer, handleClearTimer])

  const handleGetLogFile = useCallback((): void => {
    if (logFileId) {
      handleDownload(logFileId)
    } else {
      const blob = new Blob([logFile], {
        type: 'data:application/txt;base64',
      })
      saveAs(blob, selectedFile)
    }
  }, [selectedFile, logFile, logFileId])

  const handleClose = useCallback(() => {
    dispatch(clearSocketDataByMessage(Command.GET_LOG_FILE))
    handleClearTimer()
    onClose()
  }, [dispatch, handleClearTimer, onClose])

  const renderDialogContent = useMemo(() => {
    if (loading && !noResponse) {
      return (
        <Grid container justify="center">
          <Loader className={classes.loader} />
        </Grid>
      )
    }

    if (noResponse) {
      return (
        <Grid container>
          <FormattedMessage id="atmeye.message.fileNotFound" defaultMessage="File not found" />
        </Grid>
      )
    }

    if (logFileId) {
      return (
        <Grid container>
          <FormattedMessage
            id="atmeye.message.fileTooBigtoDisplay"
            defaultMessage="File is too big to display. You can download it"
          />
        </Grid>
      )
    }
    return <pre className={classes.preLine}>{logFile}</pre>
  }, [classes.loader, classes.preLine, loading, logFile, noResponse, logFileId])

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      keepMounted
      onClose={handleClose}
      disableBackdropClick
      classes={{
        paper: clsx({
          [classes.fullHeight]: !loading,
        }),
      }}
    >
      <ContentWrapper>
        <div className={classes.alignRight}>
          <CloseButton absolute className={classes.closeButton} onClick={handleClose} />
        </div>
        <div className={classes.deviceTitle}>
          <FormattedMessage id="title.logFile" defaultMessage="Log file" /> {selectedFile}
        </div>
        {renderDialogContent}
      </ContentWrapper>
      {!loading && (
        <div className={classes.buttonWrap}>
          <Button
            onClick={handleGetLogFile}
            className={classes.button}
            color="primary"
            variant="contained"
            startIcon={<img src={DownloadIcon} />}
          >
            <FormattedMessage id="title.downloadLogFile" defaultMessage="Download log file" />
          </Button>
        </div>
      )}
    </Dialog>
  )
}

export default LogFileModal
