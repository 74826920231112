import { WorkingHour, TimePeriod } from '@/api/companies/companiesApi/types'
import _ from 'lodash'
import moment from 'moment'

export const isWorks = (workday: WorkingHour): boolean => {
  if (
    workday.timePeriods &&
    workday.timePeriods.length === 1 &&
    workday.timePeriods[0].fromTime === '00:00' &&
    workday.timePeriods[0].toTime === '00:00'
  ) {
    return false
  }

  return true
}

export const checkIsValidTimePeriod = (timePeriod: TimePeriod): boolean => {
  // const beginningTime = moment({
  //   h: timePeriod.fromTimeH,
  //   m: timePeriod.fromTimeM,
  // })
  // const endTime = moment({
  //   h: timePeriod.toTimeH,
  //   m: timePeriod.toTimeM,
  // })

  const format = 'hh:mm'
  const beginningTime = moment(timePeriod.fromTime, format)
  const endTime = moment(timePeriod.toTime, format)
  return beginningTime.isBefore(endTime)
}

export const checkIsValidTimeInTimePeriod = (timePeriod: TimePeriod, time: string): boolean => {
  const format = 'hh:mm'
  const checkingTime = moment(time, format)
  const beginningTime = moment(timePeriod.fromTime, format)
  const endTime = moment(timePeriod.toTime, format)

  return !checkingTime.isBetween(beginningTime, endTime)
}

export const checkIsOverlap = (timePeriods: TimePeriod[]): boolean => {
  const timeSegments = _.cloneDeep(timePeriods)
  if (timeSegments.length === 1) return false

  timeSegments.sort((timeSegment1, timeSegment2) =>
    timeSegment1.fromTime.localeCompare(timeSegment2.fromTime),
  )

  for (let i = 0; i < timeSegments.length - 1; i++) {
    const currentEndTime = timeSegments[i].toTime
    const nextStartTime = timeSegments[i + 1].fromTime

    if (currentEndTime > nextStartTime) {
      return true
    }
  }

  return false
}

export const checkIsValidWorkingHour = (workingHour: WorkingHour): boolean => {
  if (!isWorks(workingHour)) return true

  if (workingHour.timePeriods.length === 1) {
    const [timePeriod] = workingHour.timePeriods
    return checkIsValidTimePeriod(timePeriod)
  }

  let isValid = true

  workingHour.timePeriods.forEach((timePeriod: TimePeriod, index: number) => {
    if (index !== 0) {
      if (isValid) {
        isValid = checkIsValidTimePeriod(timePeriod)
      }
    }
  })

  if (isValid) {
    isValid = !checkIsOverlap(workingHour.timePeriods)
  }

  return isValid
}

export const checkIsValidAllWorkingHours = (workingHours: WorkingHour[]): boolean => {
  return workingHours.every(wh => checkIsValidWorkingHour(wh))
}
