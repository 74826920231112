import React, { useCallback, useEffect, useState } from 'react'
import FilterContextMenuItem from '../../../FilterContextMenuItem'
import ContextMenu from '@/components/controls/ContextMenu'
import { Props } from './types'
import Button from '@/components/controls/Button'
import { useClasses } from './styles'
import clsx from 'clsx'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import { getValueOfCell } from '@/components/controls/AppTable/utils/getValueOfCell'
import { TableBodyCellExtends, TableFilterTypes } from '@/components/controls/AppTable/types'
import { FormattedMessage } from 'react-intl'

const SelectFilter = ({
  id,
  isOpen,
  values,
  currentSelected,
  anchorEl,
  handleClose,
  handleSave,
}: Props): React.ReactElement => {
  const [selected, setSelected] = useState<TableFilterTypes>([])
  const classes = useClasses()

  useEffect((): void => {
    if (currentSelected) {
      setSelected(currentSelected)
    }
  }, [currentSelected])

  const handleChange = useCallback(
    (key: string): (() => void) => (): void => {
      setSelected(prevSelected => {
        if (Array.isArray(prevSelected) && prevSelected.includes(key)) {
          return prevSelected.filter(val => val !== key)
        }
        return Array.isArray(prevSelected) ? [...prevSelected, key] : prevSelected
      })
    },
    [],
  )

  const handleSaveClick = useCallback((): void => {
    handleSave(id, selected)
    handleClose()
  }, [selected, id, handleSave, handleClose])

  const handleCancelClick = useCallback((): void => {
    if (currentSelected) {
      setSelected(currentSelected)
    }
    handleClose()
  }, [currentSelected, handleClose])

  const handleSetAll = useCallback((): void => {
    if (Array.isArray(values)) {
      setSelected(prevSelected =>
        (Array.isArray(prevSelected) ? prevSelected.length : 0) === values.length
          ? []
          : [...values.map(getValueOfCell)],
      )
    }
  }, [values])

  return (
    <ContextMenu
      open={isOpen}
      anchorEl={anchorEl}
      disableRipple
      options={[
        ...values.map(({ value, name, color }: TableBodyCellExtends<typeof values>) => {
          return {
            content: (
              <FilterContextMenuItem
                name={name}
                color={color}
                checked={
                  typeof selected !== 'object' || Array.isArray(selected)
                    ? selected.includes(value)
                    : false
                }
              />
            ),
            value: value,
            handleClick: handleChange(value),
          }
        }),
      ]}
      handleClose={handleCancelClick}
    >
      <>
        <Divider variant="middle" />
        <MenuItem disableRipple onClick={handleSetAll}>
          <FilterContextMenuItem
            name="All"
            checked={
              typeof selected !== 'object' || Array.isArray(selected)
                ? selected.length === values.length
                : false
            }
          />
        </MenuItem>
        <div className={classes.buttonWrapper}>
          <Button
            className={clsx(classes.button, classes.cancelButton)}
            variant="contained"
            size="large"
            onClick={handleCancelClick}
          >
            <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="large"
            onClick={handleSaveClick}
          >
            <FormattedMessage id="action.apply" defaultMessage="Apply" />
          </Button>
        </div>
      </>
    </ContextMenu>
  )
}

export default SelectFilter
