import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing, mixins, palette }) => ({
  dialog: {
    maxWidth: spacing(60),
  },
  closeButton: {
    position: 'absolute',
    top: spacing(1),
    right: spacing(1),
    cursor: 'pointer',
    zIndex: 100,
  },
  input: {
    width: '100%',
  },
  button: {
    minWidth: spacing(20),
  },
  container: {
    padding: spacing(6, 0),
    ...mixins.gutters(),
  },
  fileInput: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    padding: '100%',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
  },
  closeIcon: {
    width: spacing(2),
    height: spacing(2),
    color: palette.grey[200],
  },
  fileDnDPopUp: {
    position: 'absolute',
    left: '0',
    top: '0',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    border: `${spacing(1)}px dashed ${palette.grey[500]}`,
    color: palette.grey[400],
  },
  DnDIcon: {
    width: spacing(10),
    height: spacing(10),
  },
  DnDText: {
    textAlign: 'center',
  },
  hideOverflow: {
    overflow: 'hidden',
  },
}))
