import { TableCell } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import colors from '@/theme/colors'

export const useCommonClasses = makeStyles(({ palette }) => ({
  icon: {
    color: palette.grey[200],
  },

  tableCheckbox: {
    padding: '0!important',
  },

  borderCell: {
    border: '1px solid rgba(224, 224, 224, 1)',
    borderTop: 'none',
    borderLeft: 'none',

    '&:last-child': {
      borderRight: 'none',
    },
  },

  fixed: {
    position: 'sticky',
    left: 0,
    minWidth: '40px',
    backgroundColor: 'inherit',

    '&.MuiTableCell-root': {
      zIndex: '4!important',
    },

    '&:nth-of-type(2)': {
      left: '40px',
      minWidth: '60px',
    },
    '&:nth-of-type(3)': {
      left: '100px',
      minWidth: '130px',
    },
    '&:nth-of-type(4)': {
      left: '230px',
      minWidth: '130px',
    },
    '&:nth-of-type(5)': {
      left: '360px',
      minWidth: '160px',
    },
    '&:nth-of-type(6)': {
      left: '520px',
      minWidth: '160px',
    },
  },

  fixedHeader: {
    '&.MuiTableCell-root': {
      zIndex: '10!important',
    },
  },

  fixedLast: {
    borderRight: '2px solid #ffffff73',
    boxShadow: '4px 0px 6px 2px rgba(0, 0, 0, 0.08)',
    clipPath: 'polygon(0 0, 110% 0, 110% 100%, 0% 100%)',
  },

  alignRight: {
    textAlign: 'right',
  },

  alignCenter: {
    textAlign: 'center',
  },

  emptyCell: {
    padding: '4px 0',
    width: '100%',
  },
  emptyBorder: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },

  rightSpace: {
    paddingRight: '38px!important',
  },
  rightAlign: {
    textAlign: 'right',
  },
  clickableCell: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${colors.cmTable.lightGray} !important`,
    },
  },
  selectedCell: {
    backgroundColor: `${colors.cmTable.primaryLight} !important`,
    '&:hover': {
      backgroundColor: `${colors.cmTable.primaryLight} !important`,
    },
  },

  isCellNonClickable: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: `transparent !important`,
    },
  },
}))

export const HeaderCell = withStyles(() => ({
  root: {
    '&.MuiTableCell-stickyHeader': {
      position: 'sticky',
      borderBottom: 'none',
      backgroundColor: `${colors.backgroundHeaderMenu}`,
    },
  },
}))(TableCell)

export const CMTableCell = withStyles(() => ({
  root: {
    '&.MuiTableCell-root': {
      padding: '2px 16px',
      whiteSpace: 'nowrap',
      zIndex: 0,
    },
  },
}))(TableCell)
