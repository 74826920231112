import { IconButton, TableCell, TableRow } from '@material-ui/core'
import { KeyboardArrowRight, KeyboardArrowUp } from '@material-ui/icons'
import clsx from 'clsx'
import React, { FC, useCallback, useMemo, useState } from 'react'

import { CMTableRows } from '../TableRows'
import { useClasses } from './styles'
import { Props } from './types'

export const ExpandRow: FC<Props> = React.memo((props: Props) => {
  const { collapseName, entries, formattedRow, rowColSpan, fitTableContent } = props

  const classes = useClasses()

  const [isCollapseOpen, setIsCollapseOpen] = useState(true)

  const handleToggleOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation()

      setIsCollapseOpen(prev => !prev)
    },
    [],
  )

  const renderExpandRow = useMemo(
    () => (
      <TableRow className={clsx(classes.tableRow, classes.orderRow)}>
        <TableCell
          colSpan={rowColSpan + Number(fitTableContent)}
          className={clsx(classes.tableCell, classes.rowTitle)}
        >
          <IconButton aria-label="expand row" size="small" onClick={handleToggleOpen}>
            {isCollapseOpen ? (
              <KeyboardArrowUp className={classes.icon} />
            ) : (
              <KeyboardArrowRight className={classes.icon} />
            )}
          </IconButton>
          <span className={classes.title}>{formattedRow || collapseName}</span>
        </TableCell>
      </TableRow>
    ),
    [classes, rowColSpan, isCollapseOpen, formattedRow, collapseName, fitTableContent],
  )

  return (
    <>
      {renderExpandRow}
      {isCollapseOpen && <CMTableRows data={entries} rowColSpan={rowColSpan} />}
    </>
  )
})
