import Axios from 'axios'

import {
  UserLoginRequestBody,
  UsrChangePasswordRequest,
  UsrSetCultureRequest,
} from '@/api/cm/swaggerGeneratedApi'
import { APIConfiguration, LocalStorageItems } from '@/constants'
import { CashPointDetails } from '@/types/cm/monitoring'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

// TODO: request service
export class CMAuthApi {
  private static requestService = getRequestServiceCM()

  public static async signIn(requestBody: UserLoginRequestBody): Promise<CashPointDetails> {
    try {
      const tokenCM = localStorage.getItem(LocalStorageItems.AccessTokenCM)
      return await Axios.post(`${APIConfiguration.CM_API_BASE_URL}/user/login`, requestBody, {
        headers: { Authorization: `Bearer ${tokenCM}` },
      })
    } catch (e) {
      throw new Error(e.response.data)
    }
  }

  public static async setCulture(payload: UsrSetCultureRequest): Promise<void> {
    try {
      return await CMAuthApi.requestService.put('/user/setUserCulture', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async changePassword(payload: UsrChangePasswordRequest): Promise<void> {
    try {
      return await CMAuthApi.requestService.post('/user/changePassword', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async checkIsLogin(): Promise<void> {
    try {
      await CMAuthApi.requestService.get('/user/status')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async logout(): Promise<void> {
    try {
      await CMAuthApi.requestService.post('/user/logout', {})
    } catch (error) {
      throw new Error(error)
    }
  }
}
