import { createSelector } from 'reselect'

import * as fromATMeyeCommon from '@/store/atmeye/common/reducer'
import { AppState } from '@/store/reducers'

export const getTCommonAtmyeyeState = (state: AppState): fromATMeyeCommon.State =>
  state.commonATMeye

export const getATMeyeMediaData = createSelector(getTCommonAtmyeyeState, state => state.media)
export const getATMeyeAckWarning = createSelector(getTCommonAtmyeyeState, state => state.ackWarning)
export const getAvailableModules = createSelector(
  getTCommonAtmyeyeState,
  state => state?.availableModules,
)
export const getIsAvailableModulesLoading = createSelector(
  getTCommonAtmyeyeState,
  state => state?.isAvailableModulesLoading,
)
