import { AnyAction } from 'redux'

import { LoadCassetteResponse, VaultCassette } from '@/api/vault-v1/swaggerGeneratedApi'
import { CassetteModalNames } from '@/components/pages/vault-v1/Cassettes/types'
import { VaultV1Actions, VaultV1CassettesActions } from '@/constants'
import { cassetteTableId as tableId } from '@/constants/vault-v1/tableIds'
import { getTableConfig } from '@/helpers/tableLocalStorage'

import { VaultCassetteFilter } from './types'

export type State = {
  modals: {
    settingsModal: boolean
    documentModal: boolean
    filtersModal: boolean

    cassetteModalType: CassetteModalNames | ''
  }

  cassetteModal: {
    isLoading: boolean
  }

  cassettes: {
    canCreateCassette: boolean
    data: VaultCassette[]
    total: number
    isLoading: boolean
    isLoaded: boolean

    errorMessage?: string
    sortColumn?: string
    sortOrder?: 'asc' | 'desc'

    selected: VaultCassette | null

    filter: VaultCassetteFilter

    paging: {
      page: number
      pageSize: number
    }
  }

  loadUnloadCassetteLoading: boolean

  cassettesErrors: { [key: string]: string }
}

export const initialState: State = {
  modals: {
    settingsModal: false,
    documentModal: false,
    filtersModal: false,

    cassetteModalType: '',
  },

  cassetteModal: {
    isLoading: false,
  },

  cassettes: {
    canCreateCassette: false,
    data: [],
    total: 0,
    isLoading: false,
    isLoaded: false,

    errorMessage: '',
    sortColumn: undefined,
    sortOrder: undefined,

    selected: null,

    filter: {
      name: undefined,
      denomination: undefined,
      quantity: undefined,
      comment: undefined,
      currentLocationId: undefined,
      statusId: undefined,
      currencyId: undefined,
      quantityMin: undefined,
      quantityMax: undefined,
      cashierCode: undefined,
      companyName: undefined,
      matchWholeWord: false,
    },

    paging: {
      page: 1,
      pageSize: getTableConfig({ tableId })?.rowPerPage || 50,
    },
  },

  loadUnloadCassetteLoading: false,

  cassettesErrors: {},
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case VaultV1CassettesActions.CstGetCassettesRequest:
    case VaultV1CassettesActions.CstGetCassetteRequest:
      return {
        ...state,
        cassettes: {
          ...state.cassettes,
          isLoading: true,
          paging: {
            ...state.cassettes.paging,
            page: payload?.initialPage
              ? initialState.cassettes.paging.page
              : state.cassettes.paging.page,
          },
        },
      }
    case VaultV1CassettesActions.CstGetCassettesResponse:
      return {
        ...state,
        cassettes: { ...state.cassettes, isLoading: false, isLoaded: true, ...payload },
      }
    case VaultV1CassettesActions.CstGetCassettesFail:
    case VaultV1CassettesActions.CstGetCassetteFail:
      return {
        ...state,
        cassettes: { ...state.cassettes, isLoading: false, errorMessage: payload },
      }

    case VaultV1CassettesActions.CstGetCassetteResponse: {
      const copyData = [...state.cassettes.data]
      const refreshedIndex = copyData.findIndex(el => el.id === payload.id)

      if (refreshedIndex !== undefined && refreshedIndex !== null) {
        copyData[refreshedIndex] = { ...payload }

        return {
          ...state,
          cassettes: {
            ...state.cassettes,
            data: copyData,
            isLoading: false,
            selected:
              payload.id === state.cassettes.selected?.id ? payload : state.cassettes.selected,
          },
        }
      }

      return state
    }

    case VaultV1CassettesActions.CstSetFilter:
      return {
        ...state,
        cassettes: {
          ...state.cassettes,
          selected: null,
          isLoading: true,
          paging: {
            ...state.cassettes.paging,
            page: initialState.cassettes.paging.page,
          },
          filter: {
            ...state.cassettes.filter,
            ...payload,
          },
        },
      }

    case VaultV1CassettesActions.CstSetSelectedCassette: {
      return {
        ...state,
        cassettes: {
          ...state.cassettes,
          selected: payload,
        },
      }
    }
    case VaultV1CassettesActions.CstSortCassettes:
      return {
        ...state,
        cassettes: {
          ...state.cassettes,
          ...payload,
          isLoading: true,
        },
      }
    case VaultV1CassettesActions.CstSetCassettesPaging:
      return {
        ...state,
        cassettes: {
          ...state.cassettes,
          isLoading: true,
          paging: {
            ...state.cassettes.paging,
            ...payload,
          },
        },
      }

    case VaultV1CassettesActions.CstSetTableModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.type]: payload.isOpen,
        },
      }
    case VaultV1CassettesActions.CstSetCassetteModalType:
      return {
        ...state,
        modals: {
          ...state.modals,
          cassetteModalType: payload || '',
        },
      }

    case VaultV1CassettesActions.CstAddEditCassetteRequest:
      return {
        ...state,
        cassetteModal: {
          ...state.cassetteModal,
          isLoading: true,
        },
      }
    case VaultV1CassettesActions.CstAddEditCassetteResponse:
      return {
        ...state,
        modals: {
          ...state.modals,
          cassetteModalType: initialState.modals.cassetteModalType,
        },
        cassetteModal: {
          ...state.cassetteModal,
          isLoading: false,
        },
      }

    case VaultV1CassettesActions.CstDeleteCassetteRequest:
      return {
        ...state,
        cassettes: {
          ...state.cassettes,
          isLoading: true,
        },
      }

    case VaultV1CassettesActions.CstDeleteCassetteFail:
      return {
        ...state,
        cassettes: {
          ...state.cassettes,
          isLoading: true,
        },
      }

    case VaultV1CassettesActions.CstLoadCassetteRequest:
    case VaultV1CassettesActions.CstUnloadCassetteRequest:
    case VaultV1CassettesActions.CstEditCassetteRequest:
      return {
        ...state,
        loadUnloadCassetteLoading: true,
      }
    case VaultV1CassettesActions.CstLoadCassetteResponse:
    case VaultV1CassettesActions.CstUnloadCassetteResponse:
    case VaultV1CassettesActions.CstEditCassetteResponse:
      return {
        ...state,
        loadUnloadCassetteLoading: false,
      }

    case VaultV1CassettesActions.CstLoadCassetteFail: {
      const cassettesErrors = (payload as LoadCassetteResponse[]).reduce(
        (acc, item) => ({ ...acc, [item.cassetteNumber || '']: item.errorMessage || '' }),
        {} as { [key: string]: string },
      )

      return {
        ...state,
        cassettesErrors,
        loadUnloadCassetteLoading: false,
      }
    }

    case VaultV1CassettesActions.CstSetSearchingCassetteNr:
      return {
        ...state,
        cassettes: {
          ...state.cassettes,
          filter: {
            ...state.cassettes.filter,
            name: payload,
          },
        },
      }

    case VaultV1CassettesActions.CstUnloadCassetteToCashierRequest:
      return {
        ...state,
        cassettes: {
          ...state.cassettes,
          isLoading: true,
        },
      }

    case VaultV1CassettesActions.CstUnloadCassetteToCashierResponse:
    case VaultV1CassettesActions.CstUnloadCassetteToCashierFail:
      return {
        ...state,
        cassettes: {
          ...state.cassettes,
          isLoading: false,
        },
      }

    case VaultV1CassettesActions.CstClearCassettesErrors:
      return {
        ...state,
        cassettesErrors: initialState.cassettesErrors,
      }

    case VaultV1Actions.SignOut:
      return initialState

    default:
      return state
  }
}
