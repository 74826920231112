const MapStyles = [
  {
    featureType: 'administrative',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  // {
  //   featureType: 'poi.school',
  //   elementType: 'all',
  //   stylers: [
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'poi.attraction',
  //   elementType: 'labels.text',
  //   stylers: [
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'poi.business',
  //   elementType: 'labels.text',
  //   stylers: [
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
]

export const MAP_CONSTANTS = {
  defaultCenter: {
    lat: 54.897956,
    lng: 23.909969,
  },
  defaultZoom: 8,
  radius: 40,
  minZoom: 4,
  maxZoom: 20,
  mapOptions: {
    fullscreenControl: false,
    styles: MapStyles,
    mapTypeControl: true,
    streetViewControl: true,
    mapTypeControlOptions: {
      style: { background: 'red' },
      mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain'],
    },
  },
}

export const MAP_TOTAL_DEVICES = 100

export const ATMEYE_MAP_CONSTANTS = {
  minZoom: 0,
  maxZoom: 20,
  defaultCenter: {
    lat: 54.69447616682717,
    lng: 25.284459330190145,
  },
}
