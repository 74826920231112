export enum VaultV1Actions {
  // Login
  SignInRequest = '@/VAULT_V1_SIGN_IN_REQUEST',
  SignInResponse = '@/VAULT_V1_SIGN_IN_RESPONSE',
  SignInFail = '@/VAULT_V1_SIGN_IN_FAIL',

  SetUser = '@/VAULT_V1_SET_USER',

  GetCultures = '@/VAULT_V1_GET_CULTURES',

  SetCulture = '@/VAULT_V1_SET_CULTURES',

  CheckIsLogin = '@/VAULT_V1_CHECK_IS_LOGIN',

  SignOut = '@/VAULT_V1_SIGN_OUT',

  SetAboutModalOpen = '@/VAULT_V1_SET_ABOUT_MODAL_OPEN',

  SetChangePasswordModalOpen = '@/VAULT_V1_SET_CHANGE_PASSWORD_MODAL_OPEN',
  ChangePasswordRequest = '@/VAULT_V1_CHANGE_PASSWORD_REQUEST',
  ChangePasswordResponse = '@/VAULT_V1_CHANGE_PASSWORD_RESPONSE',
  ChangePasswordFail = '@/VAULT_V1_CHANGE_PASSWORD_FAIL',
  SetDataToLS = '@/VAULT_V1_SET_DATA_TO_LOCAL_STORAGE',

  // Common
  setComVaultV1Error = '@/VAULT_V1_SET_COMMON_FAIL',

  SetGlobalFilter = '@/VAULT_V1_SET_GLOBAL_FILTER',
  SetObjectTreeFilter = '@/VAULT_V1_SET_OBJECT_TREE_FILTER',

  GetFilterNodesResponse = '@/VAULT_V1_GET_FILTER_NODES_RESPONSE',
  GetFilterNodesFail = '@/VAULT_V1_GET_FILTER_NODES_FAIL',

  GetIntegrationsResponse = '@/VAULT_V1_GET_INTEGRATIONS_RESPONSE',
  GetIntegrationsFail = '@/VAULT_V1_GET_INTEGRATIONS_FAIL',

  GetVaultBuildInfoRequest = '@/VAULT_V1_GET_BUILD_INFO_REQUEST',
  GetVaultBuildInfoResponse = '@/VAULT_V1_GET_BUILD_INFO_RESPONSE',
  GetVaultBuildInfoFail = '@/VAULT_V1_GET_BUILD_INFO_FAIL',

  ComGetVaultsListRequest = '@/VAULT_V1_COM_GET_VAULTS_LIST_REQUEST',
  ComGetVaultsListResponse = '@/VAULT_V1_COM_GET_VAULTS_LIST_RESPONSE',

  ComGetVaultCurrenciesRequest = '@/VAULT_V1_COM_GET_VAULT_CURRENCIES_REQUEST',
  ComGetVaultCurrenciesResponse = '@/VAULT_V1_COM_GET_VAULT_CURRENCIES_RESPONSE',

  ComGetVaultBalancesStatusRequest = '@/VAULT_V1_COM_GET_VAULT_BALANCES_STATUS_REQUEST',
  ComGetVaultBalancesStatusResponse = '@/VAULT_V1_COM_GET_VAULT_BALANCES_STATUS_RESPONSE',
  ComGetVaultBalancesStatusFail = '@/VAULT_V1_COM_GET_VAULT_BALANCES_STATUS_FAIL',
  ComGetVaultBalancesStatusClear = '@/VAULT_V1_COM_GET_VAULT_BALANCES_STATUS_CLEAR',

  // Monitoring ------------------------------------------------------------------------------
  MonGetVaultsRequest = '@/VAULT_V1_MON_GET_VAULTS_REQUEST',
  MonGetVaultsResponse = '@/VAULT_V1_MON_GET_VAULTS_RESPONSE',
  MonGetVaultsFail = '@/VAULT_V1_MON_GET_VAULTS_FAIL',

  MonGetVaultDetailsRequest = '@/VAULT_V1_MON_GET_VAULT_DETAILS_REQUEST',
  MonGetVaultDetailsResponse = '@/VAULT_V1_MON_GET_VAULT_DETAILS__RESPONSE',
  MonGetVaultDetailsFail = '@/VAULT_V1_MON_GET_VAULT_DETAILS_FAIL',

  MonGetSingleVaultRequest = '@/VAULT_V1_MON_GET_SINGLE_VAULTS_REQUEST',
  MonGetSingleVaultResponse = '@/VAULT_V1_MON_GET_SINGLE_VAULTS_RESPONSE',
  MonGetSingleVaultFail = '@/VAULT_V1_MON_GET_SINGLE_VAULTS_FAIL',

  MonSetSelectedVault = '@/VAULT_V1_SET_SELECTED_VAULT',

  MonSortVaultsTable = '@/VAULT_V1_SORT_VAULTS_TABLE',

  MonSetVaultsPaging = '@/VAULT_V1_MON_SET_VAULTS_PAGING',
  MonRefreshVaults = '@/VAULT_V1_MON_REFRESH_VAULTS',

  MonOpenColumnsSettingsModal = '@/VAULT_V1_MON_OPEN_COLUMNS_SETTINGS_MODAL',
  MonOpenDocumentsModal = '@/VAULT_V1_MON_OPEN_DOCUMENTS_MODAL',
  MonOpenFilterModal = '@/VAULT_V1_MON_OPEN_FILTER_MODAL',
  MonOpenCurrentRemaindersModal = '@/VAULT_V1_MON_OPEN_CURRENT_REMAINDERS_MODAL',
  MonOpenCurrentValuablesRemaindersModal = '@/VAULT_V1_MON_OPEN_CURRENT_VALUABLE_REMAINDERS_MODAL',
  MonOpenStartDayModal = '@/VAULT_V1_MON_OPEN_START_DAY_MODAL',
  MonOpenWorkingTimeEndModal = '@/VAULT_V1_MON_OPEN_WORKING_TIME_END_MODAL',
  MonOpenWornOutCashModal = '@/VAULT_V1_MON_OPEN_WORK_OUT_CASH_MODAL',
  MonOpenCloseNightDepositoryModal = '@/VAULT_V1_MON_OPEN_CLOSE_NIGHT_DEPOSITORY_MODAL',
  MonOpenCashLimitsModal = '@/VAULT_V1_MON_OPEN_CASH_LIMITS_MODAL',
  MonSetVaultOperationDetailsFilter = '@/VAULT_V1_MON_SET_VAULT_OPERATION_DETAILS_FILTER',

  MonSortVaultCashierOperationDetails = '@/VAULT_V1_SORT_CASHIER_OPERATION_DETAILS',
  MonSortVaultOperationDetails = '@/VAULT_V1_SORT_OPERATION_DETAILS',

  MonSetCashierSelectedTab = '@/VAULT_V1_MON_SET_CASHIER_SELECTED_TAB',

  MonGetCashiersRequest = '@/VAULT_V1_MON_GET_CASHIERS_REQUEST',
  MonGetCashiersResponse = '@/VAULT_V1_MON_GET_CASHIERS_RESPONSE',
  MonGetCashiersFail = '@/VAULT_V1_MON_GET_CASHIERS_FAIL',

  MonSetCashiersFilter = '@/VAULT_V1_MON_SET_CASHIERS_FILTER',

  MonSetSelectedCashier = '@/VAULT_V1_MON_SET_SELECTED_CASHIER',

  MonGetCashierDetailsRequest = '@/VAULT_V1_MON_GET_CASHIER_DETAILS_REQUEST',
  MonGetCashierDetailsResponse = '@/VAULT_V1_MON_GET_CASHIER_DETAILS_RESPONSE',
  MonGetCashierDetailsFail = '@/VAULT_V1_MON_GET_CASHIER_DETAILS_FAIL',

  MonSetCashierDetailsTab = '@/VAULT_V1_MON_SET_CASHIER_DETAILS_TAB',

  MonSetOperationDetailsFilter = '@/VAULT_V1_MON_SET_OPERATION_DETAILS_FILTER',

  MonSetDetailsSelectedTab = '@/VAULT_V1_MON_SET_DETAILS_SELECTED_TAB',

  MonGetChartsRequest = '@/VAULT_V1_MON_GET_CHARTS_REQUEST',
  MonGetChartsResponse = '@/VAULT_V1_MON_GET_CHARTS_RESPONSE',
  MonGetChartsFail = '@/VAULT_V1_MON_GET_CHARTS_FAIL',

  MonSetDetailsFilterOpen = '@/VAULT_V1_MON_SET_DETAILS_FILTER_OPEN',

  MonSetDetailsFilter = '@/VAULT_V1_MON_SET_DETAILS_FILTER',

  MonSubmitFilter = '@/VAULT_V1_MON_SUBMIT_FILTER',

  // Mon Reports
  MonGetReportsRequest = '@/VAULT_V1_MON_GET_REPORTS_REQUEST',
  MonGetReportsResponse = '@/VAULT_V1_MON_GET_REPORTS_RESPONSE',
  MonGetReportsFail = '@/VAULT_V1_MON_GET_REPORTS_FAIL',

  MonGetReportRequest = '@/VAULT_V1_MON_GET_REPORT_REQUEST',
  MonGetReportResponse = '@/VAULT_V1_MON_GET_REPORT_RESPONSE',
  MonGetReportFail = '@/VAULT_V1_MON_GET_REPORT_FAIL',

  MonGetCurrentRemaindersRequest = '@/VAULT_V1_MON_GET_CURRENT_REMAINDERS_REQUEST',
  MonGetCurrentRemaindersResponse = '@/VAULT_V1_MON_GET_CURRENT_REMAINDERS_RESPONSE',
  MonGetCurrentRemaindersFail = '@/VAULT_V1_MON_GET_CURRENT_REMAINDERS_FAIL',

  MonSetCurrentRemaindersRequest = '@/VAULT_V1_MON_SET_CURRENT_REMAINDERS_REQUEST',
  MonSetCurrentRemaindersResponse = '@/VAULT_V1_MON_SET_CURRENT_REMAINDERS_RESPONSE',
  MonSetCurrentRemaindersFail = '@/VAULT_V1_MON_SET_CURRENT_REMAINDERS_FAIL',

  MonUpdateCurrentRemainder = '@/VAULT_V1_MON_UPDATE_CURRENT_REMAINDER',

  MonGetCurrentValuablesRemaindersRequest = '@/VAULT_V1_MON_GET_CURRENT_VALUABLES_REMAINDERS_REQUEST',
  MonGetCurrentValuablesRemaindersResponse = '@/VAULT_V1_MON_GET_CURRENT_VALUABLES_REMAINDERS_RESPONSE',
  MonGetCurrentValuablesRemaindersFail = '@/VAULT_V1_MON_GET_CURRENT_VALUABLES_REMAINDERS_FAIL',

  MonSetCurrentValuablesRemaindersRequest = '@/VAULT_V1_MON_SET_CURRENT_VALUABLES_REMAINDERS_REQUEST',
  MonSetCurrentValuablesRemaindersResponse = '@/VAULT_V1_MON_SET_CURRENT_VALUABLES_REMAINDERS_RESPONSE',
  MonSetCurrentValuablesRemaindersFail = '@/VAULT_V1_MON_SET_CURRENT_VALUABLES_REMAINDERS_FAIL',

  MonUpdateCurrentValuablesRemainders = '@/VAULT_V1_MON_UPDATE_CURRENT_VALUABLES_REMAINDER',

  // Mon Start Day
  MonGetStartDayRequest = '@/VAULT_V1_MON_GET_START_DAY_REQUEST',
  MonGetStartDayResponse = '@/VAULT_V1_MON_GET_START_DAY_RESPONSE',
  MonGetStartDayFail = '@/VAULT_V1_MON_GET_START_DAY_FAIL',

  MonSetStartDayRequest = '@/VAULT_V1_MON_SET_START_DAY_REQUEST',
  MonSetStartDayResponse = '@/VAULT_V1_MON_SET_START_DAY_RESPONSE',
  MonSetStartDayFail = '@/VAULT_V1_MON_SET_START_DAY_FAIL',

  // Working Time End
  MonGetWorkingTimeEndRequest = '@/VAULT_V1_MON_GET_WORKING_TIME_END_REQUEST',
  MonGetWorkingTimeEndResponse = '@/VAULT_V1_MON_GET_WORKING_TIME_END_RESPONSE',
  MonGetWorkingTimeEndFail = '@/VAULT_V1_MON_GET_WORKING_TIME_END_FAIL',

  MonSetWorkingTimeEndRequest = '@/VAULT_V1_MON_SET_WORKING_TIME_END_REQUEST',
  MonSetWorkingTimeEndResponse = '@/VAULT_V1_MON_SET_WORKING_TIME_END_RESPONSE',
  MonSetWorkingTimeEndFail = '@/VAULT_V1_MON_SET_WORKING_TIME_END_FAIL',

  // Worn Out Cash
  MonGetWornOutCashRequest = '@/VAULT_V1_MON_GET_WORK_OUT_CASH_REQUEST',
  MonGetWornOutCashResponse = '@/VAULT_V1_MON_GET_WORK_OUT_CASH_RESPONSE',
  MonGetWornOutCashFail = '@/VAULT_V1_MON_GET_WORK_OUT_CASH_FAIL',

  MonSetWornOutCashRequest = '@/VAULT_V1_MON_SET_WORK_OUT_CASH_REQUEST',
  MonSetWornOutCashResponse = '@/VAULT_V1_MON_SET_WORK_OUT_CASH_RESPONSE',
  MonSetWornOutCashFail = '@/VAULT_V1_MON_SET_WORK_OUT_CASH_FAIL',

  // Close Night Depository
  MonGetCloseNightDepositoryRequest = '@/VAULT_V1_MON_GET_CLOSE_NIGHT_DEPOSITORY_REQUEST',
  MonGetCloseNightDepositoryResponse = '@/VAULT_V1_MON_GET_CLOSE_NIGHT_DEPOSITORY_RESPONSE',
  MonGetCloseNightDepositoryFail = '@/VAULT_V1_MON_GET_CLOSE_NIGHT_DEPOSITORY_FAIL',

  MonSetCloseNightDepositoryRequest = '@/VAULT_V1_MON_SET_CLOSE_NIGHT_DEPOSITORY_REQUEST',
  MonSetCloseNightDepositoryResponse = '@/VAULT_V1_MON_SET_CLOSE_NIGHT_DEPOSITORY_RESPONSE',
  MonSetCloseNightDepositoryFail = '@/VAULT_V1_MON_SET_CLOSE_NIGHT_DEPOSITORY_FAIL',

  // Cash Limits
  MonGetCashLimitsRequest = '@/VAULT_V1_MON_GET_CASH_LIMITS_REQUEST',
  MonGetCashLimitsResponse = '@/VAULT_V1_MON_GET_CASH_LIMITS_RESPONSE',
  MonGetCashLimitsFail = '@/VAULT_V1_MON_GET_CASH_LIMITS_FAIL',

  MonAddCashLimitsRequest = '@/VAULT_V1_MON_ADD_CASH_LIMITS_REQUEST',
  MonAddCashLimitsResponse = '@/VAULT_V1_MON_ADD_CASH_LIMITS_RESPONSE',
  MonAddCashLimitsFail = '@/VAULT_V1_MON_ADD_CASH_LIMITS_FAIl',

  MonEditCashLimitsRequest = '@/VAULT_V1_MON_EDIT_CASH_LIMITS_REQUEST',
  MonEditCashLimitsResponse = '@/VAULT_V1_MON_EDIT_CASH_LIMITS_RESPONSE',
  MonEditCashLimitsFail = '@/VAULT_V1_MON_EDIT_CASH_LIMITS_FAIl',

  MonSetSelectedCashLimit = '@/VAULT_V1_MON_SET_SELECTED_CASH_LIMIT',
  MonSetAddEditModalOpen = '@/VAULT_V1_MON_SET_ADD_EDIT_MODAL_OPEN',

  MonUpdateAbsRemaindersRequest = '@/VAULT_V1_MON_UPDATE_ABS_REMAINDER_REQUEST',
  MonUpdateAbsRemaindersResponse = '@/VAULT_V1_MON_UPDATE_ABS_REMAINDER_RESPONSE',
  MonUpdateAbsRemaindersFail = '@/VAULT_V1_MON_UPDATE_ABS_REMAINDER_FAIL',

  MonHandleKeyPress = '@/VAULT_V1_MON_HANDLE_KEY_PRESS', // задел на будущее

  MonRevertBalanceRequest = '@/VAULT_V1_MON_REVERT_BALANCE_REQUEST',
  MonRevertBalanceResponse = '@/VAULT_V1_MON_REVERT_BALANCE_RESPONSE',
  MonRevertBalanceFail = '@/VAULT_V1_MON_REVERT_BALANCE_FAIL',

  // CashOrders ------------------------------------------------------------------------------

  OrdGetOrdersRequest = '@/VAULT_V1_ORD_GET_ORDERS_REQUEST',
  OrdGetOrdersResponse = '@/VAULT_V1_ORD_GET_ORDERS_RESPONSE',
  OrdGetOrdersFail = '@/VAULT_V1_ORD_GET_ORDERS_FAIL',

  OrdGetSingleVaultRequest = '@/VAULT_V1_ORD_GET_SINGLE_VAULT_REQUEST',
  OrdGetSingleVaultResponse = '@/VAULT_V1_ORD_GET_SINGLE_VAULT_RESPONSE',
  OrdGetSingleVaultFail = '@/VAULT_V1_ORD_GET_SINGLE_VAULT_FAIL',

  OrdSetSelectedOrder = '@/VAULT_V1_ORD_SET_SELECTED_ORDER',

  OrdSortOrdersTable = '@/VAULT_V1_ORD_SORT_ORDERS_TABLE',
  OrdRefreshOrders = '@/VAULT_V1_ORD_REFRESH_ORDERS',
  OrdSetOrdersPaging = '@/VAULT_V1_ORD_SET_ORDERS_PAGING',
  OrdResetOrdersPaging = '@/VAULT_V1_ORD_RESET_ORDERS_PAGING',

  OrdApproveAllRequest = '@/VAULT_V1_ORD_APPROVE_ALL_REQUEST',
  OrdApproveAllFail = '@/VAULT_V1_ORD_APPROVE_ALL_FAIL',

  OrdOpenColumnsSettingsModal = '@/VAULT_V1_ORD_OPEN_COLUMNS_SETTINGS_MODAL',
  OrdOpenDocumentsModal = '@/VAULT_V1_ORD_OPEN_DOCUMENTS_MODAL',
  OrdOpenStageDocumentsModal = '@/VAULT_V1_ORD_OPEN_STAGE_DOCUMENTS_MODAL',
  OrdOpenFilterModal = '@/VAULT_V1_ORD_OPEN_FILTER_MODAL',

  OrdSubmitFilterRequest = '@/VAULT_V1_ORD_SUBMIT_FILTER_REQUEST',
  OrdSetOrdersFilter = '@/VAULT_V1_ORD_SET_ORDERS_FILTER',

  // [START] Filter fields options request
  OrdGetFilterCurrencyRequest = '@/VAULT_V1_ORD_GET_FILTER_CURRENCY_REQUEST',
  OrdGetFilterCurrencyResponse = '@/VAULT_V1_ORD_GET_FILTER_CURRENCY_RESPONSE',

  OrdGetFilterObjectIdRequest = '@/VAULT_V1_ORD_GET_FILTER_OBJECT_ID_REQUEST',
  OrdGetFilterObjectIdResponse = '@/VAULT_V1_ORD_GET_FILTER_OBJECT_ID_RESPONSE',

  OrdGetFilterTeamRequest = '@/VAULT_V1_ORD_GET_FILTER_TEAM_REQUEST',
  OrdGetFilterTeamResponse = '@/VAULT_V1_ORD_GET_FILTER_TEAM_RESPONSE',

  OrdGetFilterTypeRequest = '@/VAULT_V1_ORD_GET_FILTER_TYPE_REQUEST',
  OrdGetFilterTypeResponse = '@/VAULT_V1_ORD_GET_FILTER_TYPE_RESPONSE',

  OrdGetFilterStatusRequest = '@/VAULT_V1_ORD_GET_FILTER_STATUS_REQUEST',
  OrdGetFilterStatusResponse = '@/VAULT_V1_ORD_GET_FILTER_STATUS_RESPONSE',
  // [END] Filter fields options request

  // Orders stages
  OrdGetOrdersStagesResponse = '@/VAULT_V1_ORD_GET_ORDERS_STAGES_RESPONSE',
  OrdGetOrdersStagesFail = '@/VAULT_V1_ORD_GET_ORDERS_STAGES_FAIL',

  OrdSetOrdersStagesRequest = '@/VAULT_V1_ORD_SET_ORDERS_STAGES_REQUEST',
  OrdSetOrdersStagesResponse = '@/VAULT_V1_ORD_SET_ORDERS_STAGES_RESPONSE',
  OrdSetOrdersStagesFail = '@/VAULT_V1_ORD_SET_ORDERS_STAGES_FAIL',

  // Reports
  GetOrdReportsRequest = '@/VAULT_V1_ORD_GET_REPORTS_REQUEST',
  GetOrdReportsResponse = '@/VAULT_V1_ORD_GET_REPORTS_RESPONSE',
  GetOrdReportsFail = '@/VAULT_V1_ORD_GET_REPORTS_FAIL',

  GetOrdReportRequest = '@/VAULT_V1_ORD_GET_REPORT_REQUEST',
  GetOrdReportResponse = '@/VAULT_V1_ORD_GET_REPORT_RESPONSE',
  GetOrdReportFail = '@/VAULT_V1_ORD_GET_REPORT_FAIL',

  // Stage Reports
  GetOrdStageReportsRequest = '@/VAULT_V1_ORD_GET_STAGE_REPORTS_REQUEST',
  GetOrdStageReportsResponse = '@/VAULT_V1_ORD_GET_STAGE_REPORTS_RESPONSE',
  GetOrdStageReportsFail = '@/VAULT_V1_ORD_GET_STAGE_REPORTS_FAIL',

  GetOrdStageReportRequest = '@/VAULT_V1_ORD_GET_STAGE_REPORT_REQUEST',
  GetOrdStageReportResponse = '@/VAULT_V1_ORD_GET_STAGE_REPORT_RESPONSE',
  GetOrdStageReportFail = '@/VAULT_V1_ORD_GET_STAGE_REPORT_FAIL',

  OrdOpenCashOrderModal = '@/VAULT_V1_ORD_OPEN_CASH_ORDER_MODAL',
  OrdOpenExportOrderModal = '@/VAULT_V1_ORD_OPEN_EXPORT_ORDER_MODAL',

  GetOrdDestinationsRequest = '@/VAULT_V1_ORD_GET_DESTINATIONS_REQUEST',
  GetOrdDestinationsResponse = '@/VAULT_V1_ORD_GET_DESTINATIONS_RESPONSE',
  GetOrdDestinationsFail = '@/VAULT_V1_ORD_GET_DESTINATIONS_FAIL',

  GetOrdSourcesRequest = '@/VAULT_V1_ORD_GET_SOURCES_REQUEST',
  GetOrdSourcesResponse = '@/VAULT_V1_ORD_GET_SOURCES_RESPONSE',
  GetOrdSourcesFail = '@/VAULT_V1_ORD_GET_SOURCES_FAIL',

  GetOrdDenominationsRequest = '@/VAULT_V1_ORD_GET_DENOMINATIONS_REQUEST',
  GetOrdDenominationsResponse = '@/VAULT_V1_ORD_GET_DENOMINATIONS_RESPONSE',
  GetOrdDenominationsFail = '@/VAULT_V1_ORD_GET_DENOMINATIONS_FAIL',

  GetOrdExpDestinationsRequest = '@/VAULT_V1_ORD_GET_EXP_DESTINATIONS_REQUEST',
  GetOrdExpSourcesRequest = '@/VAULT_V1_ORD_GET_EXP_SOURCES_REQUEST',
  GetOrdExpDenominationsRequest = '@/VAULT_V1_ORD_GET_EXP_DENOMINATIONS_REQUEST',

  SetOrdSelectedDestination = '@/VAULT_V1_ORD_SET_SELECTED_DESTINATION',
  SetOrdSelectedSours = '@/VAULT_V1_ORD_SET_SELECTED_SOURS',
  SetOrdSelectedCashCurrency = '@/VAULT_V1_ORD_SET_SELECTED_CASH_CURRENCY',
  SetOrdOrderDate = '@/VAULT_V1_ORD_SET_ORDER_DATE',

  AddOrdCashOrderRequest = '@/VAULT_V1_ORD_ADD_CASH_ORDER_REQUEST',
  AddOrdCashOrderResponse = '@/VAULT_V1_ORD_ADD_CASH_ORDER_RESPONSE',
  AddOrdCashOrderFail = '@/VAULT_V1_ORD_ADD_CASH_ORDER_FAIL',

  EditOrdCashOrderRequest = '@/VAULT_V1_ORD_EDIT_CASH_ORDER_REQUEST',
  EditOrdCashOrderResponse = '@/VAULT_V1_ORD_EDIT_CASH_ORDER_RESPONSE',
  EditOrdCashOrderFail = '@/VAULT_V1_ORD_EDIT_CASH_ORDER_FAIL',

  GetOrdValuablesRequest = '@/VAULT_V1_ORD_GET_VALUABLES_REQUEST',
  GetOrdValuablesResponse = '@/VAULT_V1_ORD_GET_VALUABLES_RESPONSE',
  GetOrdValuablesFail = '@/VAULT_V1_ORD_GET_VALUABLES_FAIL',

  // [START] Stage modal action ---------------------------------------------------
  SetOrdDeclineModalOpen = '@/VAULT_V1_ORD_SET_DECLINE_MODAL_OPEN',
  SetOrdTeamAssignModalOpen = '@/VAULT_V1_ORD_SET_TEAM_ASSIGN_MODAL_OPEN',
  SetOrdBagSealsModalOpen = '@/VAULT_V1_ORD_SET_BAG_SEALS_MODAL_OPEN',
  SetOrdCashierIdModalOpen = '@/VAULT_V1_ORD_SET_CASHIER_ID_MODAL_OPEN',
  SetOrdCashierIdRequest = '@/VAULT_V1_ORD_SET_CASHIER_ID_REQUEST',
  SetOrdCashierIdResponse = '@/VAULT_V1_ORD_SET_CASHIER_ID_RESPONSE',
  SetOrdCashierIdClear = '@/VAULT_V1_ORD_SET_CASHIER_ID_CLEAR',

  GetOrdBagSealRequest = '@/VAULT_V1_ORD_GET_BAG_SEAL_REQUEST',
  GetOrdBagSealResponse = '@/VAULT_V1_ORD_GET_BAG_SEAL_RESPONSE',
  GetOrdBagSealFail = '@/VAULT_V1_ORD_GET_BAG_SEAL_FAIL',

  SetOrdBagSealRequest = '@/VAULT_V1_ORD_SET_BAG_SEAL_REQUEST',
  SetOrdBagSealResponse = '@/VAULT_V1_ORD_SET_BAG_SEAL_RESPONSE',
  SetOrdBagSealFail = '@/VAULT_V1_ORD_SET_BAG_SEAL_FAIL',
  // [END] Stage modal action ---------------------------------------------------

  OrdSetDeclineNoteModalOpen = '@/VAULT_V1_ORD_SET_DECLINE_NOTE_MODAL_OPEN',
  OrdSetDeclineNote = '@/VAULT_V1_ORD_SET_DECLINE_NOTE',

  // [START] Work Order ---------------------------------------------------------
  OrdSetWorkOrderModalOpen = '@/VAULT_V1_ORD_SET_WORK_ORDER_MODAL_OPEN',
  OrdSetSelectedWorkOrder = '@/VAULT_V1_SET_SELECTED_WORK_ORDER',

  GetOrdWorkOrderRequest = '@/VAULT_V1_ORD_GET_WORK_ORDER_REQUEST',
  GetOrdWorkOrderResponse = '@/VAULT_V1_ORD_GET_WORK_ORDER_RESPONSE',
  GetOrdWorkOrderFail = '@/VAULT_V1_ORD_GET_WORK_ORDER_FAIL',

  OrdSetCheckedAllGroupedWO = '@/VAULT_V1_ORD_SET_CHECKED_ALL_GROUPED_WO',
  OrdSetCheckedGroupedWO = '@/VAULT_V1_ORD_SET_CHECKED_GROUPED_WO',

  OrdSetCheckedAllExportWO = '@/VAULT_V1_ORD_SET_CHECKED_ALL_EXPORT_WO',
  OrdSetCheckedExportWO = '@/VAULT_V1_ORD_SET_CHECKED_EXPORT_WO',

  SetOrdWorkOrderRequest = '@/VAULT_V1_ORD_SET_WORK_ORDER_REQUEST',
  SetOrdWorkOrderResponse = '@/VAULT_V1_ORD_SET_WORK_ORDER_RESPONSE',
  SetOrdWorkOrderFail = '@/VAULT_V1_ORD_SET_WORK_ORDER_FAIL',
  // [END] Work Order ---------------------------------------------------------

  // [START] Stage Integration ---------------------------------------------------------
  GetOrdStageIntegrationRequest = '@/VAULT_V1_ORD_GET_STAGE_INTEGRATION_REQUEST',
  GetOrdStageIntegrationResponse = '@/VAULT_V1_ORD_GET_STAGE_INTEGRATION_RESPONSE',
  GetOrdStageIntegrationFail = '@/VAULT_V1_ORD_GET_STAGE_INTEGRATION_FAIL',

  RetryOrdStageIntegrationRequest = '@/VAULT_V1_ORD_RETRY_STAGE_INTEGRATION_REQUEST',
  RetryOrdStageIntegrationResponse = '@/VAULT_V1_ORD_RETRY_STAGE_INTEGRATION_RESPONSE',
  RetryOrdStageIntegrationFail = '@/VAULT_V1_ORD_RETRY_STAGE_INTEGRATION_FAIL',

  // [END] Stage Integration ---------------------------------------------------------

  OrdHandleKeyPress = '@/VAULT_V1_ORD_HANDLE_KEY_PRESS', // задел на будущее

  // Event Journal -------------------------------------------------------------------
  EvjGetEventsRequest = '@/VAULT_V1_EVJ_GET_EVENTS_REQUEST',
  EvjGetEventsResponse = '@/VAULT_V1_EVJ_GET_EVENTS_RESPONSE',
  EvjGetEventsFail = '@/VAULT_V1_EVJ_GET_EVENTS_FAIL',

  EvjSetSelectedEvent = '@/VAULT_V1_EVJ_SET_SELECTED_EVENT',
  EvjSetSelectedEntry = '@/VAULT_V1_EVJ_SET_SELECTED_ENTRY',

  EvjSortEventsTable = '@/VAULT_V1_EVJ_SORT_EVENTS_TABLE',
  EvjSetEventsPaging = '@/VAULT_V1_EVJ_SET_EVENTS_PAGING',
  EvjResetEventsPaging = '@/VAULT_V1_EVJ_RESET_EVENTS_PAGING',

  EvjOpenColumnsSettingsModal = '@/VAULT_V1_EVJ_OPEN_COLUMNS_SETTINGS_MODAL',
  EvjOpenDocumentsModal = '@/VAULT_V1_EVJ_OPEN_DOCUMENTS_MODAL',
  EvjOpenFilterModal = '@/VAULT_V1_EVJ_OPEN_FILTER_MODAL',

  EvjSubmitFilterRequest = '@/VAULT_V1_EVJ_SUBMIT_FILTER_REQUEST',
  EvjSetEventsFilter = '@/VAULT_V1_EVJ_SET_EVENTS_FILTER',

  EvjRevertTransactionRequest = '@/VAULT_V1_EVJ_REVERT_TRANSACTION_REQUEST',

  // Reports
  EvjGetReportsRequest = '@/VAULT_V1_EVJ_GET_REPORTS_REQUEST',
  EvjGetReportsResponse = '@/VAULT_V1_EVJ_GET_REPORTS_RESPONSE',
  EvjGetReportsFail = '@/VAULT_V1_EVJ_GET_REPORTS_FAIL',

  EvjGetReportRequest = '@/VAULT_V1_EVJ_GET_REPORT_REQUEST',
  EvjGetReportResponse = '@/VAULT_V1_EVJ_GET_REPORT_RESPONSE',
  EvjGetReportFail = '@/VAULT_V1_EVJ_GET_REPORT_FAIL',

  // Filter Options

  // vaults
  EvjGetVaultsRequest = '@/VAULT_V1_EVJ_GET_VAULTS_REQUEST',
  EvjGetVaultsResponse = '@/VAULT_V1_EVJ_GET_VAULTS_RESPONSE',

  // currencies
  EvjGetCurrenciesRequest = '@/VAULT_V1_EVJ_GET_CURRENCIES_REQUEST',
  EvjGetCurrenciesResponse = '@/VAULT_V1_EVJ_GET_CURRENCIES_RESPONSE',

  // eventTypes
  EvjGetEventTypesRequest = '@/VAULT_V1_EVJ_GET_EVENT_TYPES_REQUEST',
  EvjGetEventTypesResponse = '@/VAULT_V1_EVJ_GET_EVENT_TYPES_RESPONSE',

  // executors
  EvjGetExecutorsRequest = '@/VAULT_V1_EVJ_GET_EXECUTORS_REQUEST',
  EvjGetExecutorsResponse = '@/VAULT_V1_EVJ_GET_EXECUTORS_RESPONSE',

  EvjHandleKeyPress = '@/VAULT_V1_EVJ_HANDLE_KEY_PRESS', // задел на будущее

  // Teams---------------------------------------------------------
  TmsGetTeamsRequest = '@/VAULT_V1_TMS_GET_TEAMS_REQUEST',
  TmsGetTeamsResponse = '@/VAULT_V1_TMS_GET_TEAMS_RESPONSE',
  TmsGetTeamsFail = '@/VAULT_V1_TMS_GET_TEAMS_FAIL',
  TmsSortTeams = '@/VAULT_V1_TMS_SORT_TEAMS',
  TmsSetSelectedTeam = '@/VAULT_V1_TMS_SET_SELECTED_TEAM',
  TmsSetTeamModalOpen = '@/VAULT_V1_TMS_SET_TEAM_MODAL_OPEN',

  // add
  TmsAddTeamRequest = '@/VAULT_V1_TMS_ADD_TEAM_REQUEST',
  TmsAddTeamResponse = '@/VAULT_V1_TMS_ADD_TEAM_RESPONSE',
  TmsAddTeamFail = '@/VAULT_V1_TMS_ADD_TEAM_FAIL',

  // edit
  TmsEditTeamRequest = '@/VAULT_V1_TMS_EDIT_TEAM_REQUEST',
  TmsEditTeamResponse = '@/VAULT_V1_TMS_EDIT_TEAM_RESPONSE',
  TmsEditTeamFail = '@/VAULT_V1_TMS_EDIT_TEAM_FAIL',

  TmsDeleteTeamRequest = '@/VAULT_V1_TMS_DELETE_TEAM_REQUEST',
  TmsDeleteTeamResponse = '@/VAULT_V1_TMS_DELETE_TEAM_RESPONSE',
  TmsDeleteTeamFail = '@/VAULT_V1_TMS_DELETE_TEAM_FAIL',

  // reports
  TmsGetReportsRequest = '@/VAULT_V1_TMS_GET_REPORTS_REQUEST',
  TmsGetReportsResponse = '@/VAULT_V1_TMS_GET_REPORTS_RESPONSE',
  TmsGetReportsFail = '@/VAULT_V1_TMS_GET_REPORTS_FAIL',

  TmsGetReportRequest = '@/VAULT_V1_TMS_GET_REPORT_REQUEST',
  TmsGetReportResponse = '@/VAULT_V1_TMS_GET_REPORT_RESPONSE',
  TmsGetReportFail = '@/VAULT_V1_TMS_GET_REPORT_FAIL',

  // Admin---------------------------------------------------------
  // [START] Common ---------------------------------------------------------
  AdmSetSelectedPanel = '@/VAULT_V1_ADM_SET_SELECTED_PANEL',

  AdmGetLimitsObjectsRequest = '@/VAULT_V1_ADM_GET_LIMITS_OBJECTS_REQUEST',
  AdmGetLimitsObjectsResponse = '@/VAULT_V1_ADM_GET_LIMITS_OBJECTS_RESPONSE',
  AdmGetLimitsObjectsFail = '@/VAULT_V1_ADM_GET_LIMITS_OBJECTS_FAIL',
  // [END] Common ---------------------------------------------------------

  // [START] Users Roles ---------------------------------------------------------
  // USERS ----------------------------------------
  AdmSetSelectedUsersRolesPanel = '@/VAULT_V1_ADM_SET_SELECTED_USERS_ROLES_PANEL',

  AdmGetUsersRequest = '@/VAULT_V1_ADM_GET_USERS_REQUEST',
  AdmGetUsersResponse = '@/VAULT_V1_ADM_GET_USERS_RESPONSE',
  AdmGetUsersFail = '@/VAULT_V1_ADM_GET_USERS_FAIL',

  AdmSetUsersPaging = '@/VAULT_V1_ADM_GET_USERS_PAGING',

  AdmSortUsers = '@/VAULT_V1_ADM_SORT_USERS',
  AdmFilterUsersRequest = '@/VAULT_V1_ADM_FILTER_USERS_REQUEST',

  AdmSetSelectedUser = '@/VAULT_V1_ADM_SET_SELECTED_USER',

  AdmAddUserRequest = '@/VAULT_V1_ADM_ADD_USER_REQUEST',
  AdmAddUserFail = '@/VAULT_V1_ADM_ADD_USER_FAIL',

  AdmUpdateUserRequest = '@/VAULT_V1_ADM_UPDATE_USER_REQUEST',
  AdmUpdateUserFail = '@/VAULT_V1_ADM_UPDATE_USER_FAIL',

  AdmDeleteUserRequest = '@/VAULT_V1_ADM_DELETE_USER_REQUEST',
  AdmDeleteUserFail = '@/VAULT_V1_ADM_DELETE_USER_FAIL',

  AdmOpenUserBadgeModal = '@/VAULT_V1_ADM_OPEN_USER_BADGE_MODAL',

  AdmGetBadgeReportsRequest = '@/VAULT_V1_ADM_GET_BADGE_REPORTS_REQUEST',
  AdmGetBadgeReportsResponse = '@/VAULT_V1_ADM_GET_BADGE_REPORTS_RESPONSE',
  AdmGetBadgeReportsFail = '@/VAULT_V1_ADM_GET_BADGE_REPORTS_FAIL',

  AdmGetBadgeReportRequest = '@/VAULT_V1_ADM_GET_BADGE_REPORT_REQUEST',
  AdmGetBadgeReportResponse = '@/VAULT_V1_ADM_GET_BADGE_REPORT_RESPONSE',
  AdmGetBadgeReportFail = '@/VAULT_V1_ADM_GET_BADGE_REPORT_FAIL',

  // ROLES ----------------------------------------
  AdmGetRolesRequest = '@/VAULT_V1_ADM_GET_ROLES_REQUEST',
  AdmGetRolesResponse = '@/VAULT_V1_ADM_GET_ROLES_RESPONSE',
  AdmGetRolesFail = '@/VAULT_V1_ADM_GET_ROLES_FAIL',

  AdmSortRoles = '@/VAULT_V1_ADM_SORT_ROLES',

  AdmSetSelectedRole = '@/VAULT_V1_ADM_SET_SELECTED_ROLE',

  AdmAddRoleRequest = '@/VAULT_V1_ADM_ADD_ROLE_REQUEST',
  AdmAddRoleFail = '@/VAULT_V1_ADM_ADD_ROLE_FAIL',

  AdmUpdateRoleRequest = '@/VAULT_V1_ADM_UPDATE_ROLE_REQUEST',
  AdmUpdateRoleFail = '@/VAULT_V1_ADM_UPDATE_ROLE_FAIL',

  AdmDeleteRoleRequest = '@/VAULT_V1_ADM_DELETE_ROLE_REQUEST',
  AdmDeleteRoleFail = '@/VAULT_V1_ADM_DELETE_ROLE_FAIL',

  AdmGetUsersByRoleRequest = '@/VAULT_V1_ADM_GET_USERS_BY_ROLE_REQUEST',
  AdmGetUsersByRoleResponse = '@/VAULT_V1_ADM_GET_USERS_BY_ROLE_RESPONSE',
  AdmGetUsersByRoleFail = '@/VAULT_V1_ADM_GET_USERS_BY_ROLE_FAIL',

  AdmAssignUsersRoleRequest = '@/VAULT_V1_ADM_ASSIGN_USERS_ROLE_REQUEST',
  AdmUsersAssignRoleFail = '@/VAULT_V1_ADM_ASSIGN_USERS_ROLE_FAIL',

  // COMPANIES ----------------------------------------
  AdmGetCompaniesRequest = '@/VAULT_V1_ADM_GET_COMPANIES_REQUEST',
  AdmGetCompaniesResponse = '@/VAULT_V1_ADM_GET_COMPANIES_RESPONSE',
  AdmGetCompaniesFail = '@/VAULT_V1_ADM_GET_COMPANIES_FAIL',

  AdmSortCompanies = '@/VAULT_V1-ADM_SORT_COMPANIES',

  AdmSetSelectedCompany = '@/VAULT_V1_ADM_SET_SELECTED_COMPANY',

  AdmAddCompanyRequest = '@/VAULT_V1_ADM_ADD_COMPANY_REQUEST',
  AdmAddCompanyFail = '@/VAULT_V1_ADM_ADD_COMPANY_FAIL',

  AdmUpdateCompanyRequest = '@/VAULT_V1_ADM_UPDATE_COMPANY_REQUEST',
  AdmUpdateCompanyFail = '@/VAULT_V1_ADM_UPDATE_COMPANY_FAIL',

  AdmDeleteCompanyRequest = '@/VAULT_V1_ADM_DELETE_COMPANY_REQUEST',
  AdmDeleteCompanyFail = '@/VAULT_V1_ADM_DELETE_COMPANY_FAIL',
  // [END] Users Roles ---------------------------------------------------------

  // [START] CASHIER TYPE VAULT -----------------------------------------------------------
  AdmGetUserCashierTypesRequest = '@/VAULT_V1_ADM_GET_USER_CASHIER_TYPES_REQUEST',
  AdmGetUserCashierTypesResponse = '@/VAULT_V1_ADM_GET_USER_CASHIER_TYPES_RESPONSE',
  // [END] CASHIER TYPE VAULT -------------------------------------------------------------

  // [START] Access Rules ---------------------------------------------------------
  AdmSetSelectedAccessRulesPanel = '@/VAULT_V1_ADM_SET_SELECTED_ACCESS_RULES_PANEL',

  AdmGetAccessRulesRequest = '@/VAULT_V1_ADM_GET_ACCESS_RULES_REQUEST',
  AdmGetAccessRulesResponse = '@/VAULT_V1_ADM_GET_ACCESS_RULES_RESPONSE',
  AdmGetAccessRulesFail = '@/VAULT_V1_ADM_GET_ACCESS_RULES_FAIL',

  AdmSetSortAccessRules = '@/VAULT_V1_ADM_SET_SORT_ACCESS_RULES',
  AdmSetSelectedAccessRule = '@/VAULT_V1_ADM_SET_SELECTED_ACCESS_RULE',

  // Modules
  AdmGetAccessRulesModulesRequest = '@/VAULT_V1_ADM_GET_ACCESS_RULES_MODULES_REQUEST',
  AdmGetAccessRulesModulesResponse = '@/VAULT_V1_ADM_GET_ACCESS_RULES_MODULES_RESPONSE',
  AdmGetAccessRulesModulesFail = '@/VAULT_V1_ADM_GET_ACCESS_RULES_MODULES_FAIL',

  AdmSetAccessRulesModulesRequest = '@/VAULT_V1_ADM_SET_ACCESS_RULES_MODULES_REQUEST',
  AdmSetAccessRulesModulesResponse = '@/VAULT_V1_ADM_SET_ACCESS_RULES_MODULES_RESPONSE',
  AdmSetAccessRulesModulesFail = '@/VAULT_V1_ADM_SET_ACCESS_RULES_MODULES_FAIL',

  AdmSetAccessRulesPermissionsFilter = '@/VAULT_V1_ADM_SET_AR_PERMISSIONS_FILTER',
  AdmOpenAccessRulesPermissionsFilter = '@/VAULT_V1_ADM_OPEN_AR_PERMISSIONS_FILTER',

  // Vaults
  AdmGetAccessRulesVaultsRequest = '@/VAULT_V1_ADM_GET_AR_VAULTS_REQUEST',
  AdmGetAccessRulesVaultsResponse = '@/VAULT_V1_ADM_GET_AR_VAULTS_RESPONSE',
  AdmGetAccessRulesVaultsFail = '@/VAULT_V1_ADM_GET_AR_VAULTS_FAIL',

  AdmSetSortAccessRulesVaults = '@/VAULT_V1_ADM_SET_SORT_ACCESS_RULES_VAULTS',

  AdmSetAccessRulesSelectedVault = '@/VAULT_V1_ADM_SET_AR_SELECTED_VAULT',

  // Users
  AdmGetAccessRulesUsersRequest = '@/VAULT_V1_ADM_GET_AR_USERS_REQUEST',
  AdmGetAccessRulesUsersResponse = '@/VAULT_V1_ADM_GET_AR_USERS_RESPONSE',
  AdmGetAccessRulesUsersFail = '@/VAULT_V1_ADM_GET_AR_USERS_FAIL',

  AdmSetSortAccessRulesUsers = '@/VAULT_V1_ADM_SET_SORT_ACCESS_RULES_USERS',

  AdmSetAccessRulesSelectedUser = '@/VAULT_V1_ADM_SET_AR_SELECTED_USER',

  // Permissions
  AdmGetAccessRulesPermissionsRequest = '@/VAULT_V1_ADM_GET_AR_PERMISSIONS_REQUEST',
  AdmGetAccessRulesPermissionsResponse = '@/VAULT_V1_ADM_GET_AR_PERMISSIONS_RESPONSE',
  AdmGetAccessRulesPermissionsFail = '@/VAULT_V1_ADM_GET_AR_PERMISSIONS_FAIL',

  AdmUpdateAccessRulesPermissions = '@/VAULT_V1_ADM_UPDATE_AR_PERMISSIONS',

  AdmSetAccessRulesPermissionsRequest = '@/VAULT_V1_ADM_SET_AR_PERMISSIONS_REQUEST',
  AdmSetAccessRulesPermissionsFail = '@/VAULT_V1_ADM_SET_AR_PERMISSIONS_FAIL',

  AdmClearAccessRulesPermissions = '@/VAULT_V1_ADM_CLEAR_AR_PERMISSIONS',

  // [END] Access Rules ---------------------------------------------------------

  // [START] CIT ---------------------------------------------------------
  AdmSetSelectedCITPanel = '@/VAULT_V1_ADM_SET_SELECTED_CIT_PANEL',

  // Fuel
  AdmGetFuelTypesRequest = '@/VAULT_V1_ADM_GET_FUEL_TYPE_REQUEST',
  AdmGetFuelTypesResponse = '@/VAULT_V1_ADM_GET_FUEL_TYPE_RESPONSE',
  AdmGetFuelTypesFail = '@/VAULT_V1_ADM_GET_FUEL_TYPE_FAIL',

  AdmAddFuelTypeRequest = '@/VAULT_V1_ADM_ADD_FUEL_TYPE_REQUEST',
  AdmAddFuelTypeFail = '@/VAULT_V1_ADM_ADD_FUEL_TYPE_FAIL',

  AdmEditFuelTypeRequest = '@/VAULT_V1_ADM_EDIT_FUEL_TYPE_REQUEST',
  AdmEditFuelTypeFail = '@/VAULT_V1_ADM_EDIT_FUEL_TYPE_FAIL',

  AdmDeleteFuelTypeRequest = '@/VAULT_V1_ADM_DELETE_FUEL_TYPE_REQUEST',
  AdmDeleteFuelTypeFail = '@/VAULT_V1_ADM_DELETE_FUEL_TYPE_FAIL',

  AdmSetSelectedFuelType = '@/VAULT_V1_ADM_SET_SELECTED_FUEL_TYPE',

  // Vehicle Types
  AdmGetVehicleTypesRequest = '@/VAULT_V1_ADM_GET_VEHICLE_TYPES_REQUEST',
  AdmGetVehicleTypesResponse = '@/VAULT_V1_ADM_GET_VEHICLE_TYPES_RESPONSE',
  AdmGetVehicleTypesFail = '@/VAULT_V1_ADM_GET_VEHICLE_TYPES_FAIL',

  AdmAddVehicleTypeRequest = '@/VAULT_V1_ADM_ADD_VEHICLE_TYPE_REQUEST',
  AdmAddVehicleTypeFail = '@/VAULT_V1_ADM_ADD_VEHICLE_TYPE_FAIL',

  AdmEditVehicleTypeRequest = '@/VAULT_V1_ADM_EDIT_VEHICLE_TYPE_REQUEST',
  AdmEditVehicleTypeFail = '@/VAULT_V1_ADM_EDIT_VEHICLE_TYPE_FAIL',

  AdmDeleteVehicleTypeRequest = '@/VAULT_V1_ADM_DELETE_VEHICLE_TYPE_REQUEST',
  AdmDeleteVehicleTypeFail = '@/VAULT_V1_ADM_DELETE_VEHICLE_TYPE_FAIL',

  AdmSetSelectedVehicleType = '@/VAULT_V1_ADM_SET_SELECTED_VEHICLE_TYPE',

  // Vehicles
  AdmGetVehiclesRequest = '@/VAULT_V1_ADM_GET_VEHICLES_REQUEST',
  AdmGetVehiclesResponse = '@/VAULT_V1_ADM_GET_VEHICLES_RESPONSE',
  AdmGetVehiclesFail = '@/VAULT_V1_ADM_GET_VEHICLES_FAIL',

  AdmAddVehicleRequest = '@/VAULT_V1_ADM_ADD_VEHICLE_REQUEST',
  AdmAddVehicleFail = '@/VAULT_V1_ADM_ADD_VEHICLE_FAIL',

  AdmEditVehicleRequest = '@/VAULT_V1_ADM_EDIT_VEHICLE_REQUEST',
  AdmEditVehicleFail = '@/VAULT_V1_ADM_EDIT_VEHICLE_FAIL',

  AdmDeleteVehicleRequest = '@/VAULT_V1_ADM_DELETE_VEHICLE_REQUEST',
  AdmDeleteVehicleFail = '@/VAULT_V1_ADM_DELETE_VEHICLE_FAIL',

  AdmSetSelectedVehicle = '@/VAULT_V1_ADM_SET_SELECTED_VEHICLE',
  AdmSetSelectedVehiclesCompany = '@/VAULT_V1_ADM_SET_SELECTED_VEHICLES_COMPANY',
  AdmSortVehicles = '@/VAULT_V1_ADM_SORT_VEHICLES',

  // Employees
  AdmGetEmployeesRequest = '@/VAULT_V1_ADM_GET_EMPLOYEES_REQUEST',
  AdmGetEmployeesResponse = '@/VAULT_V1_ADM_GET_EMPLOYEES_RESPONSE',
  AdmGetEmployeesFail = '@/VAULT_V1_ADM_GET_EMPLOYEES_FAIL',

  AdmEditEmployeeRequest = '@/VAULT_V1_ADM_EDIT_EMPLOYEE_REQUEST',
  AdmEditEmployeeFail = '@/VAULT_V1_ADM_EDIT_EMPLOYEE_FAIL',

  AdmSetSelectedEmployee = '@/VAULT_V1_ADM_SET_SELECTED_EMPLOYEE',
  AdmSetSelectedEmployeesCompany = '@/VAULT_V1_ADM_SET_SELECTED_EMPLOYEES_COMPANY',
  AdmSortEmployees = '@/VAULT_V1_ADM_SORT_EMPLOYEES',

  // [END] CIT ---------------------------------------------------------

  // [START] OBJECTS ---------------------------------------------------------
  AdmSetSelectedObjectsPanel = '@/VAULT_V1_ADM_SET_SELECTED_OBJECTS_PANEL',

  AdmGetObjectsRequest = '@/VAULT_V1_ADM_GET_OBJECTS_REQUEST',
  AdmGetObjectsResponse = '@/VAULT_V1_ADM_GET_OBJECTS_RESPONSE',
  AdmGetObjectsFail = '@/VAULT_V1_ADM_GET_OBJECTS_FAIL',

  AdmGetObjectRequest = '@/VAULT_V1_ADM_GET_OBJECT_REQUEST',
  AdmGetObjectResponse = '@/VAULT_V1_ADM_GET_OBJECT_RESPONSE',
  AdmGetObjectFail = '@/VAULT_V1_ADM_GET_OBJECT_FAIL',

  AdmAddObjectRequest = '@/VAULT_V1_ADM_ADD_OBJECT_REQUEST',
  AdmAddObjectFail = '@/VAULT_V1_ADM_ADD_OBJECT_FAIL',

  AdmEditObjectRequest = '@/VAULT_V1_ADM_EDIT_OBJECT_REQUEST',
  AdmEditObjectFail = '@/VAULT_V1_ADM_EDIT_OBJECT_FAIL',

  AdmDeleteObjectRequest = '@/VAULT_V1_ADM_DELETE_OBJECT_REQUEST',
  AdmDeleteObjectResponse = '@/VAULT_V1_ADM_DELETE_OBJECT_RESPONSE',
  AdmDeleteObjectFail = '@/VAULT_V1_ADM_DELETE_OBJECT_FAIL',

  AdmSetSelectedObject = '@/VAULT_V1_ADM_SET_SELECTED_OBJECT',

  AdmSetObjectsFilter = '@/VAULT_V1_ADM_SET_OBJECTS_FILTER',
  AdmSetObjectsPaging = '@/VAULT_V1_ADM_SET_OBJECTS_PAGING',
  AdmResetObjectsPaging = '@/VAULT_V1_ADM_RESET_OBJECTS_PAGING',
  AdmSortObjects = '@/VAULT_V1_ADM_SORT_OBJECTS',

  AdmObjOpenLimitModal = '@/VAULT_V1_ADM_OBJ_OPEN_LIMIT_MODAL',

  AdmGetUserNetworkNodesRequest = '@/VAULT_V1_ADM_GET_USER_NETWORK_NODES_REQUEST',
  AdmGetUserNetworkNodesResponse = '@/VAULT_V1_ADM_GET_USER_NETWORK_NODES_RESPONSE',

  AdmSetUserNetworkNodesRequest = '@/VAULT_V1_ADM_SET_USER_NETWORK_NODES_REQUEST',

  AdmGetNetworkUsersRequest = '@/VAULT_V1_ADM_GET_NETWORK_USERS_REQUEST',
  AdmGetNetworkUsersResponse = '@/VAULT_V1_ADM_GET_NETWORK_USERS_RESPONSE',
  AdmGetNetworkUsersFail = '@/VAULT_V1_ADM_GET_NETWORK_USERS_FAIL',

  AdmSortNetworkUsers = '@/VAULT_V1_ADM_SORT_NETWORK_USERS',

  AdmSetNetworkUsersPaging = '@/VAULT_V1_ADM_SET_NETWORK_USERS_PAGING',
  AdmSetSelectedNetworkUser = '@/VAULT_V1_ADM_SET_SELECTED_NETWORK_USER',
  AdmSetSearchUserName = '@/VAULT_V1_ADM_SET_SEARCH_USER_NAME',

  AdmSetChangedNetworks = '@/VAULT_V1_ADM_SET_CHANGED_NETWORKS',

  AdmGetVaultsToAssignRequest = '@/VAULT_V1_ADM_GET_VAULTS_TO_ASSIGN_REQUEST',
  AdmGetVaultsToAssignResponse = '@/VAULT_V1_ADM_GET_VAULTS_TO_ASSIGN_RESPONSE',
  AdmClearVaultsToAssign = '@/VAULT_V1_ADM_CLEAR_VAULTS_TO_ASSIGN',

  AdmGetVaultsTypesRequest = '@/VAULT_V1_ADM_GET_VAULTS_TYPES_REQUEST',
  AdmGetVaultsTypesResponse = '@/VAULT_V1_ADM_GET_VAULTS_TYPES_RESPONSE',
  AdmClearVaultsTypes = '@/VAULT_V1_ADM_CLEAR_VAULTS_TYPES',

  // [END] OBJECTS ---------------------------------------------------------

  // [START] LIMITS ---------------------------------------------------------
  AdmGetLimitsRequest = '@/VAULT_V1_ADM_GET_LIMITS_REQUEST',
  AdmGetLimitsResponse = '@/VAULT_V1_ADM_GET_LIMITS_RESPONSE',
  AdmGetLimitsFail = '@/VAULT_V1_ADM_GET_LIMITS_FAIL',

  AdmAddLimitRequest = '@/VAULT_V1_ADM_ADD_LIMIT_REQUEST',
  AdmAddLimitFail = '@/VAULT_V1_ADM_ADD_LIMIT_FAIL',

  AdmEditLimitRequest = '@/VAULT_V1_ADM_EDIT_LIMIT_REQUEST',
  AdmEditLimitFail = '@/VAULT_V1_ADM_EDIT_LIMIT_FAIL',

  AdmSetSelectedLimit = '@/VAULT_V1_ADM_SET_SELECTED_LIMIT',

  AdmSortLimits = '@/VAULT_V1_ADM_SORT_LIMITS',
  // [END] LIMITS ---------------------------------------------------------

  // [START] ACCOUNTS ---------------------------------------------------------
  AdmGetAccountsRequest = '@/VAULT_V1_ADM_GET_ACCOUNTS_REQUEST',
  AdmGetAccountsResponse = '@/VAULT_V1_ADM_GET_ACCOUNTS_RESPONSE',
  AdmGetAccountsFail = '@/VAULT_V1_ADM_GET_ACCOUNTS_FAIL',

  AdmAddAccountRequest = '@/VAULT_V1_ADM_ADD_ACCOUNT_REQUEST',
  AdmAddAccountFail = '@/VAULT_V1_ADM_ADD_ACCOUNT_FAIL',

  AdmEditAccountRequest = '@/VAULT_V1_ADM_EDIT_ACCOUNT_REQUEST',
  AdmEditAccountFail = '@/VAULT_V1_ADM_EDIT_ACCOUNT_FAIL',

  AdmDeleteAccountRequest = '@/VAULT_V1_ADM_DELETE_ACCOUNT_REQUEST',
  AdmDeleteAccountFail = '@/VAULT_V1_ADM_DELETE_ACCOUNT_FAIL',

  AdmSetSelectedAccount = '@/VAULT_V1_ADM_SET_SELECTED_ACCOUNT',
  // [END] ACCOUNTS ---------------------------------------------------------

  // [START] CALCULATION METHODS ------------------------------------------------------------
  AdmGetCalculationMethodsRequest = '@/VAULT_V1_ADM_GET_CALCULATION_METHODS_REQUEST',
  AdmGetCalculationMethodsResponse = '@/VAULT_V1_ADM_GET_CALCULATION_METHODS_RESPONSE',
  AdmGetCalculationMethodsFail = '@/VAULT_V1_ADM_GET_CALCULATION_METHODS_FAIL',

  AdmAddCalculationMethodsRequest = '@/VAULT_V1_ADM_ADD_CALCULATION_METHODS_REQUEST',
  AdmAddCalculationMethodsFail = '@/VAULT_V1_ADM_ADD_CALCULATION_METHODS_FAIL',

  AdmDeleteCalculationMethodsRequest = '@/VAULT_V1_ADM_DELETE_CALCULATION_METHODS_REQUEST',
  AdmDeleteCalculationMethodsFail = '@/VAULT_V1_ADM_DELETE_CALCULATION_METHODS_FAIL',
  // [END] CALCULATION METHODS --------------------------------------------------------------

  // [START] NETWORKS ---------------------------------------------------------
  AdmGetNetworksRequest = '@/VAULT_V1_ADM_GET_NETWORKS_REQUEST',
  AdmGetNetworksResponse = '@/VAULT_V1_ADM_GET_NETWORKS_RESPONSE',
  AdmGetNetworksFail = '@/VAULT_V1_ADM_GET_NETWORKS_FAIL',

  AdmGetNetworkRequest = '@/VAULT_V1_ADM_GET_NETWORK_REQUEST',
  AdmGetNetworkResponse = '@/VAULT_V1_ADM_GET_NETWORK_RESPONSE',
  AdmGetNetworkFail = '@/VAULT_V1_ADM_GET_NETWORK_FAIL',

  AdmAddNetworkRequest = '@/VAULT_V1_ADM_ADD_NETWORK_REQUEST',

  AdmEditNetworkRequest = '@/VAULT_V1_ADM_EDIT_NETWORK_REQUEST',

  AdmDeleteNetworkRequest = '@/VAULT_V1_ADM_DELETE_NETWORK_REQUEST',

  AdmSetSelectedNetwork = '@/VAULT_V1_ADM_SET_SELECTED_NETWORK',

  AdmOperationWithNetworkFail = '@/VAULT_V1_ADM_OPERATION_WITH_NETWORK_FAIL',

  AdmSetNetworkOpenedElements = '@/VAULT_V1_ADM_SET_NETWORK_OPENED_ELEMENTS',
  AdmSetAllNetworkOpenedElements = '@/VAULT_V1_ADM_SET_ALL_NETWORK_OPENED_ELEMENTS',
  AdmSearchNetworkByPath = '@/VAULT_V1_ADM_SEARCH_NETWORK_BY_PATH',

  AdmGetNetworkVaultsRequest = '@/VAULT_V1_ADM_GET_NETWORK_VAULTS_REQUEST',
  AdmGetNetworkVaultsResponse = '@/VAULT_V1_ADM_GET_NETWORK_VAULTS_RESPONSE',
  AdmGetNetworkVaultsFail = '@/VAULT_V1_ADM_GET_NETWORK_VAULTS_FAIL',

  AdmSortNetworkVaults = '@/VAULT_V1_ADM_SORT_NETWORK_VAULTS',
  AdmSetNetworkVaultsPaging = '@/VAULT_V1_ADM_SET_NETWORK_VAULTS_PAGING',

  AdmSetNetworkVaultName = '@/VAULT_V1_ADM_SET_NETWORK_VAULT_NAME',

  AdmAddNetworkVaultRequest = '@/VAULT_V1_ADM_ADD_NETWORK_VAULT_REQUEST',
  AdmAddNetworkVaultFail = '@/VAULT_V1_ADM_ADD_NETWORK_VAULT_FAIL',

  AdmGetTransceiverVaultsRequest = '@/VAULT_V1_ADM_GET_TRANSCEIVER_VAULTS_REQUEST',
  AdmGetTransceiverVaultsResponse = '@/VAULT_V1_ADM_GET_TRANSCEIVER_VAULTS_RESPONSE',
  AdmGetTransceiverVaultsFail = '@/VAULT_V1_ADM_GET_TRANSCEIVER_VAULTS_FAIL',

  AdmSetTransceiverVaultsRequest = '@/VAULT_V1_ADM_SET_TRANSCEIVER_VAULTS_REQUEST',
  AdmSetTransceiverVaultsResponse = '@/VAULT_V1_ADM_SET_TRANSCEIVER_VAULTS_RESPONSE',
  AdmSetTransceiverVaultsFail = '@/VAULT_V1_ADM_SET_TRANSCEIVER_VAULTS_FAIL',

  AdmHandleTransceiversModal = '@/VAULT_V1_ADM_HANDLE_TRANSCEIVERS_MODAL',
  // [END] NETWORKS ---------------------------------------------------------

  // [START] LICENSE ---------------------------------------------------------
  AdmSetSelectedLicensePanel = '@/VAULT_V1_ADM_SET_SELECTED_LICENSE_PANEL',

  AdmGetLicenseRequest = '@/VAULT_V1_ADM_GET_LICENSE_REQUEST',
  AdmGetLicenseResponse = '@/VAULT_ADM_V1_GET_LICENSE_RESPONSE',
  AdmGetLicenseFail = '@/VAULT_V1_ADM_GET_LICENSE_FAIL',
  // [END] LICENSE ---------------------------------------------------------

  // [START] APP CONFIG ---------------------------------------------------------
  AdmSetSelectedAppConfigPanel = '@/VAULT_V1_ADM_SET_SELECTED_APP_CONFIG_PANEL',

  AdmGetAppConfigRequest = '@/VAULT_V1_ADM_GET_APP_CONFIG_REQUEST',
  AdmGetAppConfigResponse = '@/VAULT_V1_ADM_GET_APP_CONFIG_RESPONSE',
  AdmGetAppConfigFail = '@/VAULT_V1_ADM_GET_APP_CONFIG_FAIL',

  AdmSetAppConfigRequest = '@/VAULT_V1_ADM_SET_APP_CONFIG_REQUEST',
  AdmSetAppConfigResponse = '@/VAULT_V1_ADM_SET_APP_CONFIG_RESPONSE',
  AdmSetAppConfigFail = '@/VAULT_V1_ADM_SET_APP_CONFIG_FAIL',

  // Logo
  AdmGetLogoRequest = '@/VAULT_V1_ADM_GET_LOGO_REQUEST',
  AdmGetLogoResponse = '@/VAULT_V1_ADM_GET_LOGO_RESPONSE',
  AdmGetLogoFail = '@/VAULT_V1_ADM_GET_LOGO_FAIL',

  // Currencies
  AdmGetCurrenciesRequest = '@/VAULT_V1_ADM_GET_CURRENCIES_REQUEST',
  AdmGetCurrenciesResponse = '@/VAULT_V1_ADM_GET_CURRENCIES_RESPONSE',
  AdmGetCurrenciesFail = '@/VAULT_V1_ADM_GET_CURRENCIES_FAIL',

  AdmSetCurrencyBaseRequest = '@/VAULT_V1_ADM_GET_CURRENCY_BASE_REQUEST',
  AdmSetCurrencyBaseResponse = '@/VAULT_V1_ADM_GET_CURRENCY_BASE_RESPONSE',
  AdmSetCurrencyBaseFail = '@/VAULT_V1_ADM_GET_CURRENCY_BASE_FAIL',

  AdmUpdateCurrencyRequest = '@/VAULT_V1_ADM_UPDATE_CURRENCY_REQUEST',
  AdmUpdateCurrencyResponse = '@/VAULT_V1_ADM_UPDATE_CURRENCY_RESPONSE',
  AdmUpdateCurrencyFail = '@/VAULT_V1_ADM_UPDATE_CURRENCY_FAIL',

  AdmAddCurrencyRequest = '@/VAULT_V1_ADM_ADD_CURRENCY_REQUEST',
  AdmAddCurrencyResponse = '@/VAULT_V1_ADM_ADD_CURRENCY_RESPONSE',
  AdmAddCurrencyFail = '@/VAULT_V1_ADM_ADD_CURRENCY_FAIL',

  AdmDeleteCurrencyRequest = '@/VAULT_V1_ADM_DELETE_CURRENCY_REQUEST',
  AdmDeleteCurrencyFail = '@/VAULT_V1_ADM_DELETE_CURRENCY_FAIL',

  AdmSetSelectedCurrency = '@/VAULT_V1_ADM_SET_SELECTED_CURRENCY',
  AdmSortCurrencies = '@/VAULT_V1_ADM_SORT_CURRENCIES',

  AdmSetCurrenciesOnlyBase = '@/VAULT_V1_ADM_SET_CURRENCIES_ONLY_BASE',

  // Denominations
  AdmGetDenominationsRequest = '@/VAULT_V1_ADM_GET_DENOMINATIONS_REQUEST',
  AdmGetDenominationsResponse = '@/VAULT_V1_ADM_GET_DENOMINATIONS_RESPONSE',
  AdmGetDenominationsFail = '@/VAULT_V1_ADM_GET_DENOMINATIONS_FAIL',

  AdmUpdateDenominationRequest = '@/VAULT_V1_ADM_UPDATE_DENOMINATION_REQUEST',
  AdmUpdateDenominationFail = '@/VAULT_V1_ADM_UPDATE_DENOMINATION_FAIL',

  AdmAddDenominationRequest = '@/VAULT_V1_ADM_ADD_DENOMINATION_REQUEST',
  AdmAddDenominationFail = '@/VAULT_V1_ADM_ADD_DENOMINATION_FAIL',

  AdmDeleteDenominationRequest = '@/VAULT_V1_ADM_DELETE_DENOMINATION_REQUEST',
  AdmDeleteDenominationFail = '@/VAULT_V1_ADM_DELETE_DENOMINATION_FAIL',

  AdmSetSelectedDenomination = '@/VAULT_V1_ADM_SET_SELECTED_DENOMINATION',
  AdmSortDenominations = '@/VAULT_V1_ADM_SORT_DENOMINATIONS',

  AdmGetDenominationCurrenciesRequest = '@/VAULT_V1_ADM_GET_DENOMINATION_CURRENCIES_REQUEST',
  AdmGetDenominationCurrenciesResponse = '@/VAULT_V1_ADM_GET_DENOMINATION_CURRENCIES_RESPONSE',

  // Valuables
  AdmGetValuablesRequest = '@/VAULT_V1_ADM_GET_VALUABLES_REQUEST',
  AdmGetValuablesResponse = '@/VAULT_V1_ADM_GET_VALUABLES_RESPONSE',
  AdmGetValuablesFail = '@/VAULT_V1_ADM_GET_VALUABLES_FAIL',

  AdmUpdateValuableRequest = '@/VAULT_V1_ADM_UPDATE_VALUABLE_REQUEST',
  AdmUpdateValuableResponse = '@/VAULT_V1_ADM_UPDATE_VALUABLE_RESPONSE',
  AdmUpdateValuableFail = '@/VAULT_V1_ADM_UPDATE_VALUABLE_FAIL',

  AdmAddValuableRequest = '@/VAULT_V1_ADM_ADD_VALUABLE_REQUEST',
  AdmAddValuableResponse = '@/VAULT_V1_ADM_ADD_VALUABLE_RESPONSE',
  AdmAddValuableFail = '@/VAULT_V1_ADM_ADD_VALUABLE_FAIL',

  AdmDeleteValuableRequest = '@/VAULT_V1_ADM_DELETE_VALUABLE_REQUEST',
  AdmDeleteValuableFail = '@/VAULT_V1_ADM_DELETE_VALUABLE_FAIL',

  AdmSetSelectedValuable = '@/VAULT_V1_ADM_SET_SELECTED_VALUABLE',
  AdmSortValuables = '@/VAULT_V1_ADM_SORT_VALUABLES',

  AdmGetValuablesLanguagesRequest = '@/VAULT_V1_ADM_GET_VALUABLES_LANGUAGES_REQUEST',
  AdmGetValuablesLanguagesResponse = '@/VAULT_V1_ADM_GET_VALUABLES_LANGUAGES_RESPONSE',

  // [END] APP CONFIG ---------------------------------------------------------
}

export enum VaultV1CassettesActions {
  CstGetCassettesRequest = '@/VAULT_V1_CST_GET_CASSETTES_REQUEST',
  CstGetCassettesResponse = '@/VAULT_V1_CST_GET_CASSETTES_RESPONSE',
  CstGetCassettesFail = '@/VAULT_V1_CST_GET_CASSETTES_FAIL',

  CstGetCassetteRequest = '@/VAULT_V1_CST_GET_CASSETTE_REQUEST',
  CstGetCassetteResponse = '@/VAULT_V1_CST_GET_CASSETTE_RESPONSE',
  CstGetCassetteFail = '@/VAULT_V1_CST_GET_CASSETTE_FAIL',

  CstSetFilter = '@/VAULT_V1_CST_SET_FILTER',

  CstSetSelectedCassette = '@/VAULT_V1_CST_SET_SELECTED_CASSETTE',
  CstSortCassettes = '@/VAULT_V1_CST_SORT_CASSETTES',
  CstSetCassettesPaging = '@/VAULT_V1_CST_SET_CASSETTES_PAGING',

  CstSetTableModal = '@/VAULT_V1_CST_SET_TABLE_MODAL',
  CstSetCassetteModalType = '@/VAULT_V1_CST_SET_CASSETTE_MODAL_TYPE',

  CstAddEditCassetteRequest = '@/VAULT_V1_CST_ADD_EDIT_CASSETTE_REQUEST',
  CstAddEditCassetteResponse = '@/VAULT_V1_CST_ADD_EDIT_CASSETTE_RESPONSE',

  CstDeleteCassetteRequest = '@/VAULT_V1_CST_DELETE_CASSETTE_REQUEST',
  CstDeleteCassetteFail = '@/VAULT_V1_CST_DELETE_CASSETTE_FAIL',

  CstLoadCassetteRequest = '@/VAULT_V1_CST_LOAD_CASSETTE_REQUEST',
  CstLoadCassetteResponse = '@/VAULT_V1_CST_LOAD_CASSETTE_RESPONSE',
  CstLoadCassetteFail = '@/VAULT_V1_CST_LOAD_CASSETTE_FAIL',

  CstClearCassettesErrors = '@/VAULT_V1_CST_CLEAR_CASSETTES_ERRORS',

  CstEditCassetteRequest = '@/VAULT_V1_CST_EDIT_CASSETTE_REQUEST',
  CstEditCassetteResponse = '@/VAULT_V1_CST_EDIT_CASSETTE_RESPONSE',

  CstUnloadCassetteRequest = '@/VAULT_V1_CST_UNLOAD_CASSETTE_REQUEST',
  CstUnloadCassetteResponse = '@/VAULT_V1_CST_UNLOAD_CASSETTE_RESPONSE',

  CstSetSearchingCassetteNr = '@/VAULT_V1_CST_SET_SEARCHING_CASSETTE_NR',

  CstUnloadCassetteToCashierRequest = '@/VAULT_V1_CST_UNLOAD_CASSETTE_TO_CASHIER_REQUEST',
  CstUnloadCassetteToCashierResponse = '@/VAULT_V1_CST_UNLOAD_CASSETTE_TO_CASHIER_RESPONSE',
  CstUnloadCassetteToCashierFail = '@/VAULT_V1_CST_UNLOAD_CASSETTE_TO_CASHIER_FAIL',

  CstHandleKeyPress = '@/VAULT_V1_CST_HANDLE_KEY_PRESS',
}

export enum VaultV1BagsActions {
  BgsGetBagsRequest = '@/VAULT_V1_BGS_GET_BAGS_REQUEST',
  BgsGetBagsResponse = '@/VAULT_V1_BGS_GET_BAGS_RESPONSE',
  BgsGetBagsFail = '@/VAULT_V1_BGS_GET_BAGS_FAIL',

  BgsGetBagRequest = '@/VAULT_V1_BGS_GET_BAG_REQUEST',
  BgsGetBagResponse = '@/VAULT_V1_BGS_GET_BAG_RESPONSE',
  BgsGetBagFail = '@/VAULT_V1_BGS_GET_BAG_FAIL',

  BgsSetSelectedBag = '@/VAULT_V1_BGS_SET_SELECTED_BAG',

  BgsSortBags = '@/VAULT_V1_BGS_SORT_BAGS',
  BgsPagingBags = '@/VAULT_V1_BGS_PAGING_BAGS',
  BgsFilterBags = '@/VAULT_V1_BGS_FILTER_BAGS',

  BgsAddEditBagsRequest = '@/VAULT_V1_BGS_ADD_EDIT_BAGS_REQUEST',
  BgsAddEditBagsResponse = '@/VAULT_V1_BGS_ADD_EDIT_BAGS_RESPONSE',
  BgsAddEditBagsFail = '@/VAULT_V1_BGS_ADD_EDIT_BAGS_FAIL',

  BgsDeleteBagRequest = '@/VAULT_V1_BGS_DELETE_BAG_REQUEST',
  BgsDeleteBagFail = '@/VAULT_V1_BGS_DELETE_BAG_FAIL',

  BgsSetFilter = '@/VAULT_V1_BGS_SET_FILTER',

  // modals
  BgsSetModal = '@/VAULT_V1_BGS_SET_MODAL',
  BgsSetBagModalType = '@/VAULT_V1_BGS_SET_BAG_MODAL_TYPE',

  // Load
  BgsLoadBagRequest = '@/VAULT_V1_BGS_LOAD_BAG_REQUEST',
  BgsLoadBagResponse = '@/VAULT_V1_BGS_LOAD_BAG_RESPONSE',
  BgsLoadBagFail = '@/VAULT_V1_BGS_LOAD_BAG_FAIL',

  // Unload
  BgsUnloadBagRequest = '@/VAULT_V1_BGS_UNLOAD_BAG_REQUEST',
  BgsUnloadBagResponse = '@/VAULT_V1_BGS_UNLOAD_BAG_RESPONSE',
  BgsUnloadBagFail = '@/VAULT_V1_BGS_UNLOAD_BAG_FAIL',

  BgsEditBagRequest = '@/VAULT_V1_BGS_EDIT_BAG_REQUEST',
  BgsEditBagResponse = '@/VAULT_V1_BGS_EDIT_BAG_RESPONSE',
  BgsEditBagFail = '@/VAULT_V1_BGS_EDIT_BAG_FAIL',

  BgsSetDetailsIsChanged = '@/VAULT_V1_BGS_SET_DETAILS_IS_CHANGED',
  BgsSetSearchingFields = '@/VAULT_V1_BGS_SET_SEARCHING_FIELDS',

  // Reports
  BgsGetReportsRequest = '@/VAULT_V1_BGS_GET_REPORTS_REQUEST',
  BgsGetReportsResponse = '@/VAULT_V1_BGS_GET_REPORTS_RESPONSE',
  BgsGetReportsFail = '@/VAULT_V1_BGS_GET_REPORTS_FAIL',

  BgsGetReportRequest = '@/VAULT_V1_BGS_GET_REPORT_REQUEST',
  BgsGetReportResponse = '@/VAULT_V1_BGS_GET_REPORT_RESPONSE',
  BgsGetReportFail = '@/VAULT_V1_BGS_GET_REPORT_FAIL',

  BgsHandleKeyPress = '@/VAULT_V1_BGS_HANDLE_KEY_PRESS',
}

export enum VaultV1CashDeskActions {
  DskGetCashDesksRequest = '@/VAULT_V1_DSK_GET_CASH_DESKS_REQUEST',
  DskGetCashDesksResponse = '@/VAULT_V1_DSK_GET_CASH_DESKS_RESPONSE',
  DskGetCashDesksFail = '@/VAULT_V1_DSK_GET_CASH_DESKS_FAIL',

  DskGetCashDeskRequest = '@/VAULT_V1_DSK_GET_CASH_DESK_REQUEST',
  DskGetCashDeskResponse = '@/VAULT_V1_DSK_GET_CASH_DESK_RESPONSE',
  DskGetCashDeskFail = '@/VAULT_V1_DSK_GET_CASH_DESK_FAIL',

  DskSetSelectedCashDesk = '@/VAULT_V1_DSK_SET_SELECTED_CASH_DESK',

  DskSortBags = '@/VAULT_V1_DSK_SORT_CASH_DESKS',
  DskPagingBags = '@/VAULT_V1_DSK_PAGING_CASH_DESKS',
  DskFilterBags = '@/VAULT_V1_DSK_FILTER_CASH_DESKS',

  DskSetFilter = '@/VAULT_V1_DSK_SET_FILTER',

  // modals
  DskSetModal = '@/VAULT_V1_DSK_SET_MODAL',

  // Assign Cashier
  DskSetCashDeskCashierRequest = '@/VAULT_V1_DSK_SET_CASH_DESK_CASHIER_REQUEST',
  DskSetCashDeskCashierFail = '@/VAULT_V1_DSK_SET_CASH_DESK_CASHIER_FAIL',

  // Details
  DskGetCashDeskDetailsRequest = '@/VAULT_V1_DSK_GET_CASH_DESK_DETAILS_REQUEST',
  DskGetCashDeskDetailsResponse = '@/VAULT_V1_DSK_GET_CASH_DESK_DETAILS_RESPONSE',
  DskGetCashDeskDetailsFail = '@/VAULT_V1_DSK_GET_CASH_DESK_DETAILS_FAIL',

  DskSetSelectedCurrencyId = '@/VAULT_V1_DSK_SET_SELECTED_CURRENCY_ID',
  DskSetSelectedDenominationId = '@/VAULT_V1_DSK_SET_SELECTED_DENOMINATION_ID',

  DskSetEditedDetails = '@/VAULT_V1_DSK_SET_EDITED_DETAILS',
  DskUpdateMoneyPack = '@/VAULT_V1_DSK_UPDATE_MONEY_PACK',

  DskBalanceCashDeskRequest = '@/VAULT_V1_BALANCE_CASH_DESK_REQUEST',
  DskBalanceCashDeskResponse = '@/VAULT_V1_BALANCE_CASH_DESK_RESPONSE',
  DskBalanceCashDeskFail = '@/VAULT_V1_BALANCE_CASH_DESK_FAIL',

  DskSetBalanceCashDeskModalInitial = '@/VAULT_V1_SET_BALANCE_MODAL_INITIAL',

  DskHandleKeyPress = '@/VAULT_V1_DSK_HANDLE_KEY_PRESS',
}

export enum VaultV1CashFlowActions {
  GetCashFlowsRequest = '@/VAULT_V1_GET_CASH_FLOWS_REQUEST',
  GetCashFlowsResponse = '@/VAULT_V1_GET_CASH_FLOWS_RESPONSE',
  GetCashFlowsFail = '@/VAULT_V1_GET_CASH_FLOWS_FAIL',

  SetSelectedCashFlow = '@/VAULT_V1_SET_SELECTED_CASH_FLOW',

  SetModal = '@/VAULT_V1_SET_CASH_FLOWS_MODAL',

  FlwSetFilter = '@/VAULT_V1_FLW_SET_FILTER',
}

export enum VaultV1AnalysisActions {
  // Filter
  AnalysisSetFiltersModalOpen = '@/VAULT_V1_SET_ANALYSIS_FILTERS_MODAL_OPEN',
  AnalysisSetFilter = '@/VAULT_V1_SET_ANALYSIS_FILTER',
  AnalysisFilterSubmitRequest = '@/VAULT_V1_ANALYSIS_FILTER_SUBMIT_REQUEST',

  AnalysisSetSelectedChartsType = '@/VAULT_V1_ANALYSIS_SET_SELECTED_CHARTS_TYPE',
  AnalysisGetChartsRequest = '@/VAULT_V1_ANALYSIS_GET_CHARTS_REQUEST',
  AnalysisGetChartsResponse = '@/VAULT_V1_ANALYSIS_GET_CHART_RESPONSE',
  AnalysisGetChartsFail = '@/VAULT_V1_ANALYSIS_GET_CHART_FAIL',
}
