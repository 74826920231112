import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Alert } from '@material-ui/lab'
import { Field, FormikProps } from 'formik'
import _ from 'lodash'
import React from 'react'
import { isIE } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Test from 'react-test-attributes'

import { CommonApi } from '@/api'
import { LookupsResponse } from '@/api/common/commonApi/types'
import { BackupOptions, FieldTypeName } from '@/components/controls/GeneratedField/types'
import Lookups from '@/components/controls/Lookups'
import { createTextInputField } from '@/components/controls/TextInput/component'
import {
  AddNewCompanyFormFields,
  AddNewCompanyFormValues,
} from '@/constants/forms/AddNewCompanyForm'
import { getGlobalErrorCompanies } from '@/store/companies/companies'
import { getCompanyTypes } from '@/store/companies/drawerActions'
import { getTestId } from '@/utils/getTestId'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from '../styles'

const AddNewCompanyFormStepGeneralInformation = ({
  values,
  setFieldValue,
  ...props
}: FormikProps<AddNewCompanyFormValues> & { backupOptions: BackupOptions }): any => {
  const propsi = { display: isIE ? 'block' : 'flex' }
  const testId = getTestId('companies-add-new-company-form-step-1')
  const companyTypes = useSelector(getCompanyTypes)
  const error = useSelector(getGlobalErrorCompanies)

  const dispatch = useDispatch()
  const translate = useTranslate()
  const classes = useClasses(propsi)
  const location = useLocation()

  const CompanyTitleInput = createTextInputField({
    required: true,
    label: translate('translate#title.companyTitle'),
    placeholder: translate('translate#title.companyTitle'),
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    maxSymbols: 255,
  })

  const CompanyShortNameInput = createTextInputField({
    required: true,
    label: translate('translate#title.short'),
    placeholder: translate('translate#title.short'),
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    maxSymbols: 20,
  })

  const VATCodeInput = createTextInputField({
    label: translate('translate#title.VATCode'),
    placeholder: translate('translate#title.VATCode'),
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    maxSymbols: 20,
  })

  const RegistrationNumberInput = createTextInputField({
    label: translate('translate#title.registrationNumber'),
    placeholder: translate('translate#title.registrationNumber'),
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    maxSymbols: 50,
  })

  const HomePageUrlInput = createTextInputField({
    label: translate('translate#title.url'),
    placeholder: translate('translate#title.url'),
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    maxSymbols: 128,
  })

  const EmailInput = createTextInputField({
    label: translate('translate#title.email'),
    placeholder: translate('translate#title.email'),
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    maxSymbols: 256,
  })

  const PhoneNumberInput = createTextInputField({
    label: translate('translate#title.PhoneNumber'),
    placeholder: translate('translate#title.PhoneNumber'),
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    maxSymbols: 50,
  })

  const NotesInput = createTextInputField({
    multiline: true,
    rows: 1,
    fullWidth: true,
    label: translate('translate#title.Notes'),
    placeholder: translate('translate#title.EditNotes'),
    InputLabelProps: {
      shrink: true,
    },
    maxSymbols: 255,
  })

  const errorJSX = error && <Alert severity="error">{translate(`translate#${error}`)}</Alert>

  return (
    <Test id={testId(0)}>
      <Grid className={classes.container} container>
        <Typography variant="h6" className={classes.title}>
          {translate('translate#companies.title.addNewCompany.generalInformation')}
        </Typography>
        <Grid container>
          <Grid container spacing={2}>
            <Test id={testId(1)}>
              <Grid item md={6}>
                <Lookups
                  isDisabled={false}
                  defaultValue={{
                    value: values.companyGeneralInfo.companyTypeId,
                    name: values.companyGeneralInfo.companyTypeName,
                  }}
                  setFormikFieldValue={setFieldValue}
                  field={{
                    type: FieldTypeName.List,
                    options: {
                      label: translate('translate#title.CompanyType'),
                      placeholder: translate('translate#title.selectCompanyType'),
                      required: true,
                      isNumber: false,
                    },
                  }}
                  fieldName={`companyGeneralInfo[${AddNewCompanyFormFields.CompanyTypeId}]`}
                  fieldTitle={`companyGeneralInfo[${AddNewCompanyFormFields.CompanyTypeName}]`}
                  isDependsOn
                  onCall={(value: string): Promise<LookupsResponse> => {
                    return CommonApi.getLookupsValues({
                      lookupKey: 'COMPANY_TYPE_DICT',
                      module: 'COM',
                      fragment: value,
                      pageNo: 0,
                      pageSize: 100,
                    })
                  }}
                />
              </Grid>
            </Test>
            <Test id={testId(2)}>
              <Grid item md={6}>
                <Lookups
                  isDisabled
                  defaultValue={{
                    value: values.companyGeneralInfo.parentCompanyId,
                    name: values.companyGeneralInfo.parentCompanyName,
                  }}
                  setFormikFieldValue={setFieldValue}
                  field={{
                    type: FieldTypeName.Search,
                    options: {
                      label: translate('translate#title.ParentCompany'),
                      placeholder: translate('translate#title.selectParentCompany'),
                      required: false,
                      isNumber: false,
                    },
                  }}
                  fieldName={`companyGeneralInfo[${AddNewCompanyFormFields.ParentCompanyId}]`}
                  fieldTitle={`companyGeneralInfo[${AddNewCompanyFormFields.ParentCompanyName}]`}
                  isDependsOn
                  onCall={(value: string): Promise<LookupsResponse> => {
                    return CommonApi.getLookupsValues({
                      lookupKey: 'ALLOWED_COMPANY',
                      module: 'COM',
                      fragment: value,
                      pageNo: 0,
                      pageSize: 100,
                    })
                  }}
                />
              </Grid>
            </Test>
          </Grid>
          <Grid container spacing={2}>
            <Test id={testId(3)}>
              <Grid item md={6}>
                <Field name={`companyGeneralInfo[${AddNewCompanyFormFields.CompanyName}]`}>
                  {CompanyTitleInput}
                </Field>
              </Grid>
            </Test>
            <Test id={testId(4)}>
              <Grid item md={6}>
                <Field name={`companyGeneralInfo[${AddNewCompanyFormFields.CompanyShortName}]`}>
                  {CompanyShortNameInput}
                </Field>
              </Grid>
            </Test>
          </Grid>
          <Grid container spacing={2}>
            <Test id={testId(5)}>
              <Grid item md={6}>
                <Field name={`companyGeneralInfo[${AddNewCompanyFormFields.VATCode}]`}>
                  {VATCodeInput}
                </Field>
              </Grid>
            </Test>
            <Test id={testId(6)}>
              <Grid item md={6}>
                <Field name={`companyGeneralInfo[${AddNewCompanyFormFields.RegistrationNumber}]`}>
                  {RegistrationNumberInput}
                </Field>
              </Grid>
            </Test>
          </Grid>
          <Grid container spacing={2}>
            <Test id={testId(7)}>
              <Grid item md={6}>
                <Field name={`companyGeneralInfo[${AddNewCompanyFormFields.HomePageUrl}]`}>
                  {HomePageUrlInput}
                </Field>
              </Grid>
            </Test>
            <Test id={testId(8)}>
              <Grid item md={6}>
                <Field name={`companyGeneralInfo[${AddNewCompanyFormFields.Email}]`}>
                  {EmailInput}
                </Field>
              </Grid>
            </Test>
          </Grid>
          <Grid container spacing={2}>
            <Test id={testId(7)}>
              <Grid item md={6}>
                <Field name={`companyGeneralInfo[${AddNewCompanyFormFields.PhoneNumber}]`}>
                  {PhoneNumberInput}
                </Field>
              </Grid>
            </Test>
            <Test id={testId(8)}>
              <Grid item md={6}>
                <Lookups
                  isDisabled={false}
                  defaultValue={{
                    value: values.companyGeneralInfo.timeZoneCode,
                    name: values.companyGeneralInfo.timeZoneName,
                  }}
                  setFormikFieldValue={setFieldValue}
                  field={{
                    type: FieldTypeName.List,
                    options: {
                      label: translate('translate#login.form.title.time-zone'),
                      placeholder: translate('translate#login.form.title.time-zone'),
                      required: false,
                      isNumber: false,
                    },
                  }}
                  fieldName={`companyGeneralInfo[${AddNewCompanyFormFields.TimeZoneCode}]`}
                  fieldTitle={`companyGeneralInfo[${AddNewCompanyFormFields.TimeZoneName}]`}
                  isDependsOn
                  onCall={(value: string): Promise<LookupsResponse> => {
                    return CommonApi.getLookupsValues({
                      lookupKey: 'TIME_ZONE_DICT',
                      module: 'COM',
                      fragment: value,
                      pageNo: 0,
                      pageSize: 100,
                    })
                  }}
                />
              </Grid>
            </Test>
          </Grid>
          <Grid container spacing={2}>
            <Test id={testId(9)}>
              <Grid item md={12}>
                <Field name={`companyGeneralInfo[${AddNewCompanyFormFields.Notes}]`}>
                  {NotesInput}
                </Field>
              </Grid>
            </Test>
          </Grid>
        </Grid>
      </Grid>
    </Test>
  )
}

export { AddNewCompanyFormStepGeneralInformation }
