import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(() => ({
  grabCursor: {
    cursor: 'grab!important',
  },
  pointerCursor: {
    cursor: 'pointer!important',
  },
}))
