// core
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { AppNavigationRoutes } from '@/constants'
import { getAvailableAccountsOptions, setUserFromAvailableUsers, signOut } from '@/store/auth'

export const useSetAccountForm = () => {
  const [account, setAccount] = useState<string>('')
  const availableAccountsOptions = useSelector(getAvailableAccountsOptions)
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    const firstAccount = availableAccountsOptions.length ? availableAccountsOptions[0].value : ''
    setAccount(firstAccount)
  }, [])

  const handleSetCompany = useCallback((): void => {
    dispatch(setUserFromAvailableUsers(account))
  }, [account, dispatch])

  const setCompanyAndLogin = (account: any): void => {
    setAccount(account)
    dispatch(setUserFromAvailableUsers(account))
  }

  const handleChange = useCallback((e: React.ChangeEvent<{ name?: string; value: any }>): void => {
    setAccount(e.target.value)
  }, [])

  const handleSignOut = useCallback((): void => {
    dispatch(signOut())
    history.push(AppNavigationRoutes.SignInPage)
  }, [dispatch, history])

  return {
    account,
    handleChange,
    availableAccountsOptions,
    handleSetCompany,
    handleSignOut,
    setCompanyAndLogin,
  }
}
