import Box from '@material-ui/core/Box'
import { Alert } from '@material-ui/lab'
import { Field, Form, Formik } from 'formik'
import React, { useCallback } from 'react'
import Test from 'react-test-attributes'

import Button from '@/components/controls/Button'
import { createTextInputField } from '@/components/controls/TextInput/component'
import { ValidationErrors } from '@/constants'
import { getTestId } from '@/utils'
import { useTranslate } from '@/utils/internalization'
import { ValidationService } from '@/utils/services'

import { useClasses } from '../../styles'
import { ChangePasswordFormValuesCM, Props } from '../../types'

const validate = (values: ChangePasswordFormValuesCM): Partial<ChangePasswordFormValuesCM> => {
  const errors: Partial<ChangePasswordFormValuesCM> = {}
  if (!ValidationService.isRequired(values.oldPassword)) {
    errors.oldPassword = ValidationErrors.Required
  }

  if (values.newPassword !== values.repeatNewPassword) {
    errors.repeatNewPassword = ValidationErrors.PasswordsDoNotMatch
  }

  if (ValidationService.isSpace(values.newPassword)) {
    errors.newPassword = ValidationErrors.UnacceptablePasswordSymbols
  }

  if (ValidationService.isSpace(values.oldPassword)) {
    errors.oldPassword = ValidationErrors.UnacceptablePasswordSymbols
  }

  if (!ValidationService.isRequirementsForChangePassword(values.newPassword)) {
    errors.newPassword = ValidationErrors.RequirementsForChangePassword
  }
  return errors
}

const ChangePasswordForm = ({ onChangePassword, isLoading, error }: Props): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const testId = getTestId('cm-change-password-form')

  const handleSubmit = useCallback(
    (values: ChangePasswordFormValuesCM): void => {
      onChangePassword(values)
    },
    [onChangePassword],
  )

  const OldPasswordInput = createTextInputField({
    fullWidth: true,
    type: 'password',
    label: translate('translate#title.currentPassword'),
  })

  const NewPasswordInput = createTextInputField({
    fullWidth: true,
    type: 'password',
    label: translate('translate#title.newPassword'),
  })

  const RepeatNewPasswordInput = createTextInputField({
    fullWidth: true,
    type: 'password',
    label: translate('translate#title.repeatPassword'),
  })

  return (
    <Formik
      autoComplete="off"
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={{
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: '',
      }}
    >
      {({ isValid }): React.ReactElement => {
        return (
          <Test id={testId(0)}>
            <Form>
              {error && (
                <Test id={testId(1)}>
                  <Alert color="error">{translate(`translate#${error}`)}</Alert>
                </Test>
              )}
              <Box>
                <Test id={testId(2)}>
                  <Field name="oldPassword">{OldPasswordInput}</Field>
                </Test>
              </Box>
              <Box>
                <Test id={testId(3)}>
                  <Field name="newPassword">{NewPasswordInput}</Field>
                </Test>
              </Box>
              <Box>
                <Test id={testId(4)}>
                  <Field name="repeatNewPassword">{RepeatNewPasswordInput}</Field>
                </Test>
              </Box>

              <Test id={testId(5)}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={!isValid || isLoading}
                  className={classes.button}
                >
                  {translate('translate#cm.ButtonChangePassword')}
                </Button>
              </Test>
            </Form>
          </Test>
        )
      }}
    </Formik>
  )
}

export default ChangePasswordForm
