import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { NewCommonApi } from '@/api/atmeye/common/newCommonAPI'
import { DevicesApi } from '@/api/atmeye/devicesApi'
import { Account } from '@/api/common/authorizationApi/types'
import { TicketsApi } from '@/api/sd/ticketsApi'
import { GetTicketsBody } from '@/api/sd/ticketsApi/types'
import { StrategyLocalStorage } from '@/components/NPMPakage/components/tableComponents/UITable/strategy'
import { AtmeyeReportsActions, TicketActions } from '@/constants'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { gettingDevices } from '@/helpers/devices'
import { State } from '@/store/sd/tickets/reducer'
import { TableSettingsData, TypeOfTickets } from '@/types'
import { TicketDetailsResponse } from '@/types/sd/tickets/ticketDetails'
import { getLocalStorageItem, setLocalStorageItem } from '@/utils/localStorage'
import { PopUpService } from '@/utils/services/popUpService'

import {
  changeSelectedFilters,
  changeSorting,
  collectFilterTemplateFields,
  createSdFilterTemplateResponse,
  deleteSdFilterTemplateResponse,
  deleteSdGroupTemplateResponseSuccess,
  editGroupTemplateFromResponse,
  getDevicesConfigResponse,
  getTicketDetailsResponse,
  getTicketDetailsResponseFail,
  getTicketsConfigResponseFail,
  getTicketsResponse,
  getTicketsResponseFail,
  setDataPagination,
  setFilterTemplatesResponse,
  setGroupTemplates,
  setTicketsPage,
  setTicketsRowsPerPage,
  updateSdFilterTemplateResponse,
} from './actions'

const WINDOW_TYPE = 'DEVICES_SELECTOR_REPORTS'

function* getPagination(data: GetTicketsBody, typeOfTickets: string): SagaIterator {
  try {
    const responsePagination = yield call(NewCommonApi.getPagination, data as GetTicketsBody)
    yield put(setDataPagination(responsePagination))
  } catch (e) {
    console.error('e: ', e)
  }
}

function* getDevices({ payload: { isInitRequest } }: AnyAction): SagaIterator {
  try {
    const accountState: Account | null = yield select(state => state.auth.currentAccount)
    const ticketsState: State = yield select(state => state.atemeyeReports)
    const {
      typeOfTickets,
      config: {
        [typeOfTickets as TypeOfTickets]: { getOutTreeURL, filter, groups },
      },
      rowsPerPage: { [typeOfTickets as TypeOfTickets]: rowsPerPage },
      page: { [typeOfTickets as TypeOfTickets]: page },
      grouping: {
        [typeOfTickets as TypeOfTickets]: { selectedGroupings },
      },
      sorting: { [typeOfTickets as TypeOfTickets]: sorting },
      filterFields: { [typeOfTickets as TypeOfTickets]: filterTemplateFields },
      filter: {
        [typeOfTickets as TypeOfTickets]: { selectedFilters },
      },
    } = ticketsState

    const REQUEST_BODY_LS_KEY = 'devicesConfig'
    let savedSettings: TableSettingsData | null = null
    let requestBody
    if (accountState) {
      savedSettings = getLocalStorageItem(LocalStorageItems.ReportsSettings)
    }
    if (isInitRequest && savedSettings && savedSettings[REQUEST_BODY_LS_KEY]) {
      const {
        requestBodyData,
        selectedFilters,
        sorting,
        filterTemplateFields,
        page,
        rowsPerPage,
      } = savedSettings[REQUEST_BODY_LS_KEY]
      const { groups, ...other } = requestBodyData
      requestBody = other
      requestBody.formUID = WINDOW_TYPE
      if (groups.length > 0) requestBody.groups = groups

      // yield put(changeSelectedFilters(selectedFilters))
      yield put(changeSorting(sorting))
      yield put(collectFilterTemplateFields(filterTemplateFields))
      yield put(setTicketsRowsPerPage(rowsPerPage))
      yield put(setTicketsPage(page))
    } else {
      requestBody = {
        filter: gettingDevices.getFilters(selectedFilters, filter),
        formUID: WINDOW_TYPE,
        getOutTreeURL,
        groups: gettingDevices.getGroups(selectedGroupings, groups),
        pageNo: page,
        pageSize: rowsPerPage,
        sortDetails: gettingDevices.getSortDetails(sorting),
      }
      if (accountState) {
        setLocalStorageItem(LocalStorageItems.ReportsSettings, {
          ...(savedSettings && { ...savedSettings }),
          devicesConfig: {
            requestBodyData: requestBody,
            selectedFilters,
            sorting,
            page,
            rowsPerPage,
            filterTemplateFields,
          },
        })
      }
    }

    requestBody.groups = gettingDevices.getGroups(selectedGroupings, groups)
    requestBody.filter = gettingDevices.getFilters(selectedFilters, filter)

    if (!requestBody.groups || requestBody.groups.length <= 0) {
      yield fork(getPagination, requestBody, typeOfTickets)
    }
    const response = yield call(NewCommonApi.getData, requestBody as GetTicketsBody)
    yield put(getTicketsResponse(response))
  } catch (error) {
    yield put(getTicketsResponseFail(error.message))
  }
}

function* getDevicesFilterTemplates(): Generator {
  try {
    const response: any = yield call(NewCommonApi.getFilterTemplates, WINDOW_TYPE)
    yield put(setFilterTemplatesResponse(response.availableTemplates))
  } catch (error) {
    throw new Error(error)
  }
}

function* createFilterTemplate({ payload }: AnyAction): Generator {
  try {
    const { filters, name } = payload
    const response: any = yield call(NewCommonApi.createFilterTemplate, {
      filters,
      name,
      windowType: WINDOW_TYPE,
    })
    const newTemplateId = response.availableTemplates.find(
      (template: { id: string; name: string }) => template.name === payload.name,
    )
    yield put(
      createSdFilterTemplateResponse({
        name: payload.name,
        id: newTemplateId.id,
      }),
    )
    PopUpService.showGlobalPopUp(`${payload.translate('translate#title.templateSaved')}`)
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('title.templateCreateError')}.\n${error}`,
      'error',
    )
  }
}

function* updateFilterTemplate({ payload }: AnyAction): Generator {
  try {
    const response = yield call(NewCommonApi.updateFilterTemplate, {
      filters: payload.filters,
      name: payload.name,
      windowType: WINDOW_TYPE,
      id: payload.id,
    })

    if (response) {
      yield put(updateSdFilterTemplateResponse(payload))
      PopUpService.showGlobalPopUp(
        `${payload.translate('translate#title.templateEdited')}`,
        'success',
      )
    }
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#title.templateEditError')}.\n${error}`,
      'error',
    )
  }
}

function* deleteFilterTemplate({ payload }: AnyAction): Generator {
  try {
    const response: any = yield call(NewCommonApi.deleteFilterTemplate, {
      id: payload.id,
      windowType: WINDOW_TYPE,
    })
    if (response.status === 200) {
      yield put(deleteSdFilterTemplateResponse(payload))
      PopUpService.showGlobalPopUp(
        `${payload.translate('translate#title.templateRemoved')}`,
        'success',
      )
    }
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#title.templateDeletionError')}.\n${error}`,
      'error',
    )
  }
}

function* getGroupTemplatesRequest({ payload }: AnyAction): Generator {
  try {
    const response: any = yield call(NewCommonApi.getGroupTemplates, WINDOW_TYPE)

    const templateViewUpdated = response.availableTemplates.map((temp: any) => ({
      ...temp,
      config: [],
    }))
    yield put(setGroupTemplates(templateViewUpdated))
  } catch (e) {
    // call error
  }
}

function* saveGroupTemplate({ payload }: AnyAction): Generator {
  try {
    const { groupingLevels, name } = payload.template
    const response: any = yield call(NewCommonApi.createGroupTemplate, {
      groupingLevels,
      name,
      windowType: WINDOW_TYPE,
    })

    PopUpService.showGlobalPopUp(payload.translate(`translate#title.templateSaved`), 'success')
    const newTemplateId =
      response.availableTemplates.find((temp: any) => temp.name === payload.template.name).id || ''

    yield put(
      setGroupTemplates(
        [
          {
            id: newTemplateId,
            name: payload.template.name,
            config: payload.template.config,
          },
        ],
        true,
      ),
    )

    StrategyLocalStorage.setData(`@LocalStorage/${WINDOW_TYPE}/groupingTemplate`, newTemplateId)
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate(`translate#title.templateCreateError`)} ${error}`,
      'error',
    )
  }
}

function* editGroupTemplate({ payload }: AnyAction): Generator {
  try {
    const response: any = yield call(NewCommonApi.editGroupTemplate, {
      template: payload.template,
      windowType: WINDOW_TYPE,
    })

    if (response.availableTemplates) {
      PopUpService.showGlobalPopUp(payload.translate(`translate#title.templateEdited`), 'success')
      yield put(editGroupTemplateFromResponse(payload.template))
    }
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#title.templateEditError')} ${error}`,
      'error',
    )
  }
}

function* deleteGroupTemplate({ payload }: AnyAction): Generator {
  try {
    const response: any = yield call(NewCommonApi.deleteGroupTemplate, {
      id: payload.template.id,
      windowType: WINDOW_TYPE,
    })

    if (response === '') {
      yield put(deleteSdGroupTemplateResponseSuccess(payload.template))
      PopUpService.showGlobalPopUp(
        `${payload.translate('translate#title.templateRemoved')}`,
        'success',
      )
    }
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#title.templateDeletionError')} ${error}`,
      'error',
    )
  }
}

function* getDevicesConfig(): SagaIterator {
  try {
    const typeOfTickets: TypeOfTickets = yield select(state => state.atemeyeReports.typeOfTickets)

    const response = yield call(NewCommonApi.getFormConfig, {
      formUID: WINDOW_TYPE,
      getConfigURL: '/search-form/config',
    })

    yield put(getDevicesConfigResponse(typeOfTickets, response))
  } catch (error) {
    yield put(getTicketsConfigResponseFail(error.message))
  }
}

// function* getTicketDetails({ payload: { id } }: AnyAction): Generator {
//   try {
//     const response = yield call(TicketsApi.getTicketDetails, id)

//     yield put(getTicketDetailsResponse(response as TicketDetailsResponse))
//   } catch (error) {
//     yield put(getTicketDetailsResponseFail(error.message))
//   }
// }

export default function*(): Generator {
  yield takeLatest(AtmeyeReportsActions.GetDevicesRequest, getDevices)
  yield takeLatest(AtmeyeReportsActions.UpdateFilterTemplateRequest, updateFilterTemplate) // 2
  yield takeLatest(AtmeyeReportsActions.DeleteFilterTemplateRequest, deleteFilterTemplate) // 3
  yield takeLatest(AtmeyeReportsActions.DeleteGroupTemplateRequest, deleteGroupTemplate) // 7
  yield takeLatest(AtmeyeReportsActions.GetFiltersTemplatesRequest, getDevicesFilterTemplates)
  yield takeLatest(AtmeyeReportsActions.CreateFilterTemplateRequest, createFilterTemplate) // 1
  yield takeLatest(AtmeyeReportsActions.GetGroupsTemplatesRequest, getGroupTemplatesRequest) // 4
  yield takeLatest(AtmeyeReportsActions.SaveGroupingTemplateRequest, saveGroupTemplate) // 5
  yield takeLatest(AtmeyeReportsActions.EditGroupTemplateRequest, editGroupTemplate) // 6
  yield takeLatest(AtmeyeReportsActions.GetDevicesConfigRequest, getDevicesConfig)
  // yield takeLatest(TicketActions.GetTicketDetailsRequest, getTicketDetails)
}
