import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  loaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    background: 'rgba(255, 255, 255, 0.7)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '16px',
  },
  button: {
    marginLeft: spacing(2.5),
  },
  removeButton: {
    color: '#F1717C',
    borderColor: '#F1717C',

    '&:hover': {
      borderColor: '#F1717C',
      filter: 'drop-shadow(0 4px 4px #F8B7BD)',
      backgroundColor: '#ffffff',
    },
  },
  grid: {
    width: 650,
  },
  row: {
    display: 'grid',
    gridTemplateColumns: `${spacing(15)}px 1fr 1fr ${spacing(1)}px 1fr 1fr`,
    gap: `${spacing(1.5)}px`,
  },
  checkbox: {
    left: `-${spacing(1)}px`,
  },
  deviderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  devider: {
    width: spacing(1),
    height: 1,
    backgroundColor: '#000000',
  },
  errorText: {
    paddingLeft: 0,
  },
}))
