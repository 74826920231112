import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromAuthVaultV1 from './reducer'

export const getAuthStateVaultV1 = (state: AppState): fromAuthVaultV1.State => state.vaultV1Auth

export const getDataToLS = createSelector(getAuthStateVaultV1, state => state.dataToLS)

export const getChangePasswordState = createSelector(
  getAuthStateVaultV1,
  state => state.changePasswordState,
)
