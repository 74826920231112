import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  tableWrap: {
    marginBottom: spacing(0),
    // maxHeight: spacing(32),
  },
  headColumn: {
    lineHeight: spacing(0.2),
    width: spacing(24),
  },

  tableWrapper: {
    marginBottom: spacing(2),
    marginTop: spacing(2),
    width: '100%',
  },
  root: {
    overflow: 'initial',
  },
  row: {
    '&:nth-child(odd)': {
      backgroundColor: '#F8F8F8',
    },
  },
}))
