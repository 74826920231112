import React from 'react'
import clsx from 'clsx'
import { MenuItem, Checkbox } from '@material-ui/core'
import { Props } from './types'

const ListElement = React.memo(
  ({ name, value, selected, classes, styles, handleClickMenuItem, innerRef }: Props) => {
    return (
      <MenuItem
        ref={innerRef}
        className={clsx(classes.normalOption, classes.menuListItem)}
        key={`${name}${value}`}
        onClick={handleClickMenuItem}
      >
        <Checkbox
          className={styles?.checkbox}
          checked={Array.isArray(selected) && selected.includes(value)}
        />
        {name}
      </MenuItem>
    )
  },
  (prev, next) => {
    if (
      !prev.selected.some((value: string) => value === prev.value) &&
      next.selected.some((value: string) => value === next.value)
    )
      return false

    if (
      prev.selected.some((value: string) => value === prev.value) &&
      !next.selected.some((value: string) => value === next.value)
    )
      return false
    return true
  },
)

export default ListElement
