// core
import { Grid } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import React, { FC } from 'react'
import Test from 'react-test-attributes'

// helpers
import { getTestId } from '@/utils'

// styles
import { useClasses } from './styles'
// types
import { Props } from './types'

const SignInPageWrapper: FC<Props> = ({ children }: Props) => {
  const testId = getTestId('auth-wrapper')
  const classes = useClasses()
  return (
    <div className={classes.background}>
      <main className={classes.wrapper}>
        <Grid container justifyContent="center" alignItems="center" direction="column">
          <Test id={testId(0)}>
            <Paper className={classes.form} elevation={3}>
              {children}
            </Paper>
          </Test>
        </Grid>
      </main>
    </div>
  )
}

export { SignInPageWrapper }
