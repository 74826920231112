import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import MuiTable from '@material-ui/core/Table'
import { Alert } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { Command } from '@/api/rsocket/command'
import CloseButton from '@/components/blocks/CloseButton'
import Loader from '@/components/blocks/Loader'
import {
  clearSocketDataByMessage,
  clearSocketDataError,
  getSocketDataError,
} from '@/store/dashboard'
import { useTranslate } from '@/utils/internalization'

import { ContentWrapper, useClasses } from './styles'
import { PropertyTypes, Props } from './types'

const PropertiesModal = ({
  isLoading,
  open,
  handleClose,
  data,
  setIsLoading,
}: Props): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const error = useSelector(getSocketDataError)
  const dispatch = useDispatch()
  const [mapObject, setMapObject] = useState<PropertyTypes | null>(null)

  useEffect(() => {
    if (data) {
      setMapObject(Object.assign({}, { status: data.status }, { ...data.properties }))
      setIsLoading(false)
    }
  }, [data, setIsLoading])

  const handleCloseModal = (): void => {
    handleClose()
    dispatch(clearSocketDataError())
    dispatch(clearSocketDataByMessage(Command.GET_ATM_FULL_PROPERTY))
  }

  const errorJSX = error && <Alert severity="error">{error}</Alert>
  const dataJSX = isLoading ? (
    <Loader />
  ) : (
    <Paper className={classes.root}>
      <TableContainer component={Paper} className={classes.table}>
        <MuiTable stickyHeader className={classes.table} aria-label="caption table">
          <TableHead>
            <TableRow className={classes.headRow}>
              <TableCell className={classes.cellHeader}>
                <FormattedMessage id="label.info" defaultMessage="Info" />
              </TableCell>
              <TableCell className={classes.cellHeader}>
                <FormattedMessage id="title.value" defaultMessage="Value" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mapObject &&
              Object.keys(mapObject).map((el, index: number) => {
                return (
                  <TableRow className={classes.tableRow} key={index}>
                    <TableCell>{el}</TableCell>
                    <TableCell className={classes.tableCell}>{mapObject[el]}</TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Paper>
  )

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        keepMounted
        onClose={handleCloseModal}
        disableBackdropClick
      >
        <ContentWrapper>
          <CloseButton absolute className={classes.closeButton} onClick={handleCloseModal} />
          <div className={classes.deviceTitle}>{translate('translate#title.getFullStatus')}</div>
          {errorJSX || dataJSX}
          <Grid container justify="flex-end">
            <Button
              onClick={handleCloseModal}
              className={classes.button}
              color="primary"
              variant="contained"
            >
              <FormattedMessage id="title.ok" defaultMessage="OK" />
            </Button>
          </Grid>
        </ContentWrapper>
      </Dialog>
    </>
  )
}

export default PropertiesModal
