import makeStyles from '@material-ui/core/styles/makeStyles'
export type Props = {
  display?: string
}

export const useClasses = makeStyles(({ spacing }) => ({
  wrapper: {
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
  },
  wrapperWithBottom: {
    paddingBottom: spacing(1),
  },
  field: {
    '&:nth-of-type(2n + 1)': {
      paddingRight: spacing(2),
    },
    '&:nth-of-type(2n)': {
      paddingLeft: spacing(2),
    },
  },
  disabled: {
    color: 'currentColor',
  },
}))
