import queryString from 'query-string'

import { AdmAppSetting } from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

interface SetLogo {
  name: string
  logo: string
}

export class AppConfigAdminApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async get(): Promise<AdmAppSetting[]> {
    try {
      return await AppConfigAdminApi.requestService.get('/admin/appsettings')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async set(appSettings: AdmAppSetting[]): Promise<void> {
    try {
      return await AppConfigAdminApi.requestService.put('/admin/appsettings/save', appSettings)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getLogo(): Promise<string> {
    try {
      return await AppConfigAdminApi.requestService.get('/admin/logo')
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async setLogo({ logo, name }: SetLogo): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/logo/upload',
          query: { name },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await AppConfigAdminApi.requestService.postComplete(url, logo)
    } catch (error) {
      throw new Error(error)
    }
  }
}
