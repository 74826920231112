import faker from 'faker'
import moment from 'moment'
import { ReactElement } from 'react'
import { AnyAction } from 'redux'

import { getSocketsKey, Types } from '@/api/cm/socket/sortersIntegration'
import { MessageType } from '@/api/cm/socket/types'
import {
  AdmApprovalItem,
  AdmUserOperationInfo,
  BalGetBalancingCassettesResponse,
  BalGetBalancingInfoResponse,
  BalNotLoadedCassettesCashOrder,
  BalTotalsResponse,
  BalUnloadedCassetteEntry,
  BalUnloadedCassettesSession,
  BS2CashPointEvent,
  ComBasicCashPoint,
  ComCashPointAccountFields,
  ComCassetteCurrency,
  ComCurrency,
  ComFilterTreeNode,
  ComGlobalFilter,
  ComObjectCassetteBase,
  ComServiceCompany,
  ComTeam,
  ComTotals,
  ComUser,
  DocDocument,
  eBS2AvailableIntegration,
  IcsCashPointCassette,
  IcsEvent,
  ParsedCitBalCashPointAccount,
  VaultsResponse,
} from '@/api/cm/swaggerGeneratedApi/index'
import { components } from '@/api/cm/swaggerGeneratedApi/schema'
import { AdmApprovalItemWithCheckbox } from '@/components/blocks/cm/Admin/ApproveItemModal/types'
import { CMActions, cmBaseUrl, CMObjectsActions } from '@/constants'
import { CurrencyProps } from '@/types/cm/citBalancing'
import { BuildInfo } from '@/types/cm/common'

export interface State {
  filterNodes: ComFilterTreeNode
  filterNodesLoaded: boolean
  filterNodesLoading: boolean
  filterNodesError: string | null
  filterCurrencies: ComCurrency[]
  filterCurrenciesLoading: boolean
  filterCurrenciesError: string | null
  usersOptions: AdmUserOperationInfo[]
  users: ComUser[]
  usersLoading: boolean
  usersError: string | null
  denominations: ComCurrency[]
  currencies: ComCurrency[]
  companies: ComServiceCompany[]
  currenciesLoading: boolean
  currenciesError: string | null
  buildInfo: BuildInfo | null

  isWorkingWithTeam: boolean

  addCompany: {
    isModalOpen: boolean
    isCompanySending: boolean
  }

  globalFilterCashPoints: {
    data: ComBasicCashPoint[]
    selectedCashPointsIds: string[]
    isLoading: boolean
    isLoaded: boolean
    error: string | null
  }
  globalFilter: ComGlobalFilter
  sessionDocumentsReports: {
    errorMessage?: string
    isLoading: boolean
    list: DocDocument[]
    selectedReport: {
      isLoading: boolean
      errorMessage?: string
      bytes?: any // Blob in TS has no arrayBuffer method
    }
  }

  missedReplenishment: {
    isEventsCreated: boolean
    isEventsApplied: boolean
    isEventsNotAppliedWithError: boolean
    selectedOrder: {
      id: string
      cpId: string
    }
    dateFrom: string
    cassettes: {
      isLoading: boolean
      isLoaded: boolean
      data: IcsCashPointCassette[]
      totals: {
        data: ComTotals[]
        error: string | null
        isLoading: boolean
        isLoaded: boolean
      }
      eventType: BS2CashPointEvent
      error: string | null
    }
    events: {
      lastEventDate: string
      page: number
      pageSize: number
      sortColumn: string | undefined
      sortOrder: 'asc' | 'desc' | undefined
      totalEvents: number
      cassettesNames: string[]
      data: IcsEvent[]
      isLoading: boolean
      isLoaded: boolean
    }
    error: string | null
    isChartLoading: boolean
    chart: {
      label?: string
      datalines?: {
        name?: string
        datalineId?:
          | 'dl_remainders'
          | 'dl_remainders_forecast'
          | 'dl_lower_limit'
          | 'dl_cashouts'
          | 'dl_cashouts_forecast'
          | 'dl_replenishment'
      }[]
      data?: components['schemas']['MonCashPointCasseteDetailRow'][]
    }
  }

  // -----------------------------------------------------------------

  balancingInfo: {
    data: Partial<BalGetBalancingInfoResponse>
    isLoading: boolean
    isLoaded: boolean
    error: string | null
  }
  balancingCassettes: {
    data: Partial<BalGetBalancingCassettesResponse>
    selected: BalUnloadedCassetteEntry | null
    selectedCycleEndSessionId: string
    isLoading: boolean
    isLoaded: boolean
    error: string | null
  }
  isApplyLoading: boolean
  isUnloadedCassettesApplied: boolean
  selectedUnloadedCassettes: BalUnloadedCassettesSession | null
  selectedNotLoadedCassettes: BalNotLoadedCassettesCashOrder | null
  balancingUnloadedTotals: {
    data: BalTotalsResponse | null
    isLoading: boolean
    totalSessionId?: string
  }

  balancingNotLoadedTotals: {
    data: BalTotalsResponse | null
    isLoading: boolean
    totalSessionId?: string
  }
  cassetteModalType: string
  isBalancingLoadingModal: boolean

  // -----------------------------------------------------------------

  accounts: {
    data: ParsedCitBalCashPointAccount[]
    tableColumns: ComCashPointAccountFields[]
    initialData: ParsedCitBalCashPointAccount[]
    selectedAccount: ParsedCitBalCashPointAccount | null
    tid: string
    isLoading: boolean
    isLoaded: boolean
    error: string | null

    parsedCurrencies: CurrencyProps[]
  }

  // -----------------------------------------------------------------

  cassetteConfig: {
    config: ComObjectCassetteBase[]
    types: string[]
    currencies: ComCassetteCurrency[]
    isLoading: boolean
    errorMessage?: string
    isEditing: boolean
  }

  availableCassetteNumbers: number[]

  // -----------------------------------------------------------------

  approvalItem: {
    isModalOpen: boolean
    module: string
    moduleName: string | ReactElement
    data: AdmApprovalItemWithCheckbox[]
    isLoading: boolean
    isLoaded: boolean
    error: string | null
    hasApprovalChanges: boolean
  }

  // -----------------------------------------------------------------
  isCashSortersModalOpen: boolean
  isCloseCashSorterModalOpen: boolean
  isSortersStatus: boolean
  sorter: string | null

  socketData: {
    [key: string]: any
  }

  // -----------------------------------------------------------------
  integration: eBS2AvailableIntegration[]

  vaultsWithLocation: VaultsResponse

  about: {
    isModalOpen: boolean
  }

  // -----------------------------------------------------------------
  teams: ComTeam[]
}

const initialState: State = {
  filterNodes: {},
  filterNodesLoading: false,
  filterNodesLoaded: false,
  filterNodesError: null,
  filterCurrencies: [],
  filterCurrenciesLoading: false,
  filterCurrenciesError: null,
  denominations: [],
  currencies: [],
  companies: [],
  currenciesLoading: false,
  currenciesError: null,
  users: [],
  usersOptions: [],
  usersLoading: false,
  usersError: null,
  buildInfo: null,

  isWorkingWithTeam: false,

  addCompany: {
    isModalOpen: false,
    isCompanySending: false,
  },

  globalFilterCashPoints: {
    data: [],
    selectedCashPointsIds: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  globalFilter: {
    cashPointObjectType: undefined,
    tids: [],
    address: undefined,
    fixedTids: [],
    filterIds: [],
  },
  sessionDocumentsReports: {
    errorMessage: '',
    isLoading: false,
    list: [],
    selectedReport: {
      isLoading: false,
      errorMessage: '',
      bytes: undefined,
    },
  },

  missedReplenishment: {
    isEventsCreated: false,
    isEventsApplied: false,
    isEventsNotAppliedWithError: false,
    dateFrom: moment(new Date()).format(),
    selectedOrder: {
      id: '',
      cpId: '',
    },
    error: null,
    cassettes: {
      isLoading: false,
      isLoaded: false,
      data: [],
      eventType: 'None',
      totals: {
        data: [],
        isLoading: false,
        isLoaded: false,
        error: null,
      },
      error: null,
    },
    events: {
      lastEventDate: '',
      page: 1,
      pageSize: 50,
      sortColumn: undefined,
      sortOrder: undefined,
      totalEvents: 0,
      cassettesNames: [],
      data: [],
      isLoading: false,
      isLoaded: false,
    },
    isChartLoading: false,
    chart: {
      label: '',
      data: [],
      datalines: [],
    },
  },

  // -----------------------------------------------------------------

  balancingInfo: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  balancingCassettes: {
    data: {},
    selected: null,
    selectedCycleEndSessionId: '',
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  isApplyLoading: false,
  isUnloadedCassettesApplied: false,
  selectedUnloadedCassettes: null,
  selectedNotLoadedCassettes: null,
  balancingUnloadedTotals: {
    data: null,
    isLoading: false,
    totalSessionId: '',
  },
  balancingNotLoadedTotals: {
    data: null,
    isLoading: false,
    totalSessionId: '',
  },

  isBalancingLoadingModal: false,
  cassetteModalType: '',

  // -----------------------------------------------------------------

  accounts: {
    data: [],
    tableColumns: [],
    initialData: [],
    selectedAccount: null,
    tid: '',
    isLoading: false,
    isLoaded: false,
    error: null,

    parsedCurrencies: [],
  },

  // -----------------------------------------------------------------

  cassetteConfig: {
    config: [],
    types: [],
    currencies: [],
    isLoading: false,
    isEditing: false,
    errorMessage: '',
  },

  availableCassetteNumbers: [],

  // -----------------------------------------------------------------

  approvalItem: {
    isModalOpen: false,
    module: '',
    moduleName: '',
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
    hasApprovalChanges: false,
  },

  // -----------------------------------------------------------------
  isCashSortersModalOpen: false,
  isCloseCashSorterModalOpen: false,

  isSortersStatus: false,
  sorter: null,

  socketData: {},

  // -----------------------------------------------------------------
  integration: [],

  vaultsWithLocation: {},

  about: {
    isModalOpen: false,
  },

  // -----------------------------------------------------------------
  teams: [],
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case CMActions.BalSetUnloadedSelectedCassette:
      return {
        ...state,
        balancingCassettes: {
          ...state.balancingCassettes,
          selected: payload,
        },
      }

    case CMActions.ComGetAvailableCassetteNumbersResponse:
      return {
        ...state,
        availableCassetteNumbers: payload,
      }
    case CMActions.BalModifyCassettesRequest:
    case CMActions.BalRemoveCassetteRequest:
    case CMActions.CitBalModifyCassettesRequest:
    case CMActions.CitBalRemoveCassetteRequest:
      return {
        ...state,
        isBalancingLoadingModal: true,
      }
    case CMActions.BalModifyCassettesResponse:
    case CMActions.BalRemoveCassetteResponse:
    case CMActions.CitBalModifyCassettesResponse:
    case CMActions.CitBalRemoveCassetteResponse:
      return {
        ...state,
        isBalancingLoadingModal: false,
        cassetteModalType: '',

        balancingCassettes: {
          ...state.balancingCassettes,
          selected: null,
        },
      }
    case CMActions.BalModifyCassettesFail:
    case CMActions.BalRemoveCassetteFail:
    case CMActions.CitBalModifyCassettesFail:
    case CMActions.CitBalRemoveCassetteFail:
      return {
        ...state,
        isBalancingLoadingModal: false,
      }

    case CMActions.BalOpenCassettesModal:
      return {
        ...state,
        cassetteModalType: payload,
      }
    case CMActions.ComSetSorterStatus:
      return {
        ...state,
        isSortersStatus: payload.isOnline,
        sorter: payload.sorter,
      }
    case CMActions.ComSetCashSortersModal:
      return {
        ...state,
        isCashSortersModalOpen: payload,
      }
    case CMActions.ComSetCloseCashSorterModal:
      return {
        ...state,
        isCloseCashSorterModalOpen: payload,
        socketData: !payload ? initialState.socketData : state.socketData,
      }
    case CMActions.ComSetSocketData: {
      const command = getSocketsKey(cmBaseUrl)

      const newSocketData = { ...payload }

      const updateDataKey = Types[MessageType.UpdateAvailableSortersNotification]

      if (Object.keys(payload)[0] === updateDataKey.toString()) {
        newSocketData[command[MessageType.GetAvailableSortersRequest]] = payload[updateDataKey]
      }

      return {
        ...state,
        socketData: {
          ...state.socketData,
          ...newSocketData,
        },
      }
    }

    case CMActions.ComGetBuildInfoResponseCM:
      return {
        ...state,
        buildInfo: payload,
      }
    case CMActions.ComGetBuildInfoResponseFailCM:
      return {
        ...state,
        buildInfo: null,
      }
    case CMActions.MonApplySelectedList:
      return {
        ...state,
        globalFilter: {
          ...state.globalFilter,
          fixedTids: payload,
        },
      }
    case CMActions.MonRemoveSelectedList: {
      const newTids = [...(state?.globalFilter?.fixedTids || [])]?.filter(
        el => !payload?.some((removeTid: string) => el === removeTid),
      )
      return {
        ...state,
        globalFilter: {
          ...state.globalFilter,
          fixedTids: newTids,
        },
      }
    }
    case CMActions.ComGetUsersRequest:
      return {
        ...state,
        users: [],
        usersLoading: true,
        usersError: null,
      }
    case CMActions.ComGetUsersResponse:
      return {
        ...state,
        users: payload,
        usersLoading: false,
        usersError: null,
      }
    case CMActions.ComGetUsersResponseFail:
      return {
        ...state,
        users: [],
        usersLoading: false,
        usersError: payload,
      }
    case CMActions.GetCurrenciesRequest:
      return {
        ...state,
        currencies: [],
        currenciesLoading: true,
        currenciesError: null,
      }
    case CMActions.GetCurrenciesResponse:
      return {
        ...state,
        currencies: payload,
        currenciesLoading: false,
        currenciesError: null,
      }
    case CMActions.GetCurrenciesResponseFail:
      return {
        ...state,
        currencies: [],
        currenciesLoading: false,
        currenciesError: payload,
      }
    case CMActions.GetFilterCurrenciesRequest:
      return {
        ...state,
        filterCurrencies: [],
        filterCurrenciesLoading: true,
        filterCurrenciesError: null,
      }
    case CMActions.GetFilterCurrenciesResponse:
      return {
        ...state,
        filterCurrencies: payload,
        filterCurrenciesLoading: false,
        filterCurrenciesError: null,
      }
    case CMActions.GetFilterCurrenciesResponseFail:
      return {
        ...state,
        filterCurrencies: [],
        filterCurrenciesLoading: false,
        filterCurrenciesError: payload,
      }
    case CMActions.GetFilterNodesRequest:
      return {
        ...state,
        filterNodesLoading: true,
        filterNodesError: null,
      }
    case CMActions.GetFilterNodesResponse:
      return {
        ...state,
        filterNodes: payload,
        filterNodesLoading: false,
        filterNodesLoaded: true,
        filterNodesError: null,
      }
    case CMActions.GetFilterNodesResponseFail:
      return {
        ...state,
        filterNodes: {},
        filterNodesLoading: false,
        filterNodesError: payload,
      }
    case CMActions.SetGlobalFilter:
      return {
        ...state,
        globalFilter: {
          ...state.globalFilter,
          ...payload,
        },
      }

    case CMActions.ComGetSessionDocumentsReportsRequest:
      return {
        ...state,
        sessionDocumentsReports: {
          ...state.sessionDocumentsReports,
          isLoading: true,
          list: [],
          errorMessage: '',
          selectedReport: initialState.sessionDocumentsReports.selectedReport,
        },
      }
    case CMActions.ComGetSessionDocumentsReportsResponse:
      return {
        ...state,
        sessionDocumentsReports: {
          ...state.sessionDocumentsReports,
          isLoading: false,
          list: payload,
          errorMessage: '',
        },
      }
    case CMActions.ComGetSessionDocumentsReportsFail:
      return {
        ...state,
        sessionDocumentsReports: {
          ...state.sessionDocumentsReports,
          isLoading: false,
          list: [],
          errorMessage: payload,
        },
      }

    case CMActions.ComGetSessionDocumentsReportRequest:
      return {
        ...state,
        sessionDocumentsReports: {
          ...state.sessionDocumentsReports,
          selectedReport: {
            ...state.sessionDocumentsReports.selectedReport,
            isLoading: true,
            errorMessage: '',
            bytes: undefined,
          },
        },
      }
    case CMActions.ComGetSessionDocumentsReportResponse:
      return {
        ...state,
        sessionDocumentsReports: {
          ...state.sessionDocumentsReports,
          selectedReport: {
            ...state.sessionDocumentsReports.selectedReport,
            isLoading: false,
            errorMessage: '',
            bytes: payload,
          },
        },
      }
    case CMActions.ComGetSessionDocumentsReportFail:
      return {
        ...state,
        sessionDocumentsReports: {
          ...state.sessionDocumentsReports,
          selectedReport: {
            ...state.sessionDocumentsReports.selectedReport,
            isLoading: false,
            errorMessage: payload,
            bytes: undefined,
          },
        },
      }

    case CMActions.ComGetFilterTIDCashPointsRequest:
      return {
        ...state,
        globalFilterCashPoints: {
          ...state.globalFilterCashPoints,
          isLoading: true,
        },
      }
    case CMActions.ComGetFilterTIDCashPointsResponse:
      return {
        ...state,
        globalFilterCashPoints: {
          ...state.globalFilterCashPoints,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      }
    case CMActions.ComGetFilterTIDCashPointsFail:
      return {
        ...state,
        globalFilterCashPoints: {
          ...state.globalFilterCashPoints,
          isLoading: false,
          isLoaded: true,
          error: payload,
        },
      }
    case CMActions.ComSetSelectedFilterTIDCashPoints:
      return {
        ...state,
        globalFilterCashPoints: {
          ...state.globalFilterCashPoints,
          selectedCashPointsIds: payload,
        },
      }
    case CMActions.ComSetInitialReportsCM:
      return {
        ...state,
        sessionDocumentsReports: initialState.sessionDocumentsReports,
      }

    // -------------------------------------------------------------------------

    case CMActions.IcsSetMissedReplenishmentFromDate:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          dateFrom: payload,
        },
      }
    case CMActions.CitBalGetEventsRequest:
    case CMActions.IcsGetMissedReplenishmentRequest:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          selectedOrder: {
            ...state.missedReplenishment.selectedOrder,
            cpId: payload.cashPointId,
            id: payload.id,
          },
          isChartLoading: true,
        },
      }
    case CMActions.CitBalGetEventsFail:
    case CMActions.IcsGetMissedReplenishmentFail:
      return {
        ...state,
        missedReplenishment: {
          ...initialState.missedReplenishment,
          error: payload,
        },
      }
    case CMActions.IcsGetMissedReplenishmentCartResponse:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          isChartLoading: false,
          chart: payload,
        },
      }
    case CMActions.IcsGetMissedReplenishmentEventsRequest:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          events: {
            ...state.missedReplenishment.events,
            isLoading: true,
          },
        },
      }
    case CMActions.IcsGetMissedReplenishmentEventsResponse:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          events: {
            ...state.missedReplenishment.events,
            data: payload.events,
            totalEvents: payload.totalEvents,
            cassettesNames: payload.cassettesNames,
            lastEventDate: payload.lastEventDate,
            isLoading: false,
            isLoaded: true,
          },
        },
      }
    case CMActions.IcsSetMissedReplenishmentPagingResponse:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          events: {
            ...state.missedReplenishment.events,
            ...payload,
          },
        },
      }
    case CMActions.IcsSortMissedReplenishmentEventsTable:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          events: {
            ...state.missedReplenishment.events,
            isLoading: true,
            ...payload,
          },
        },
      }
    case CMActions.CitBalGetAddEventsRequest:
    case CMActions.IcsGetMissedReplenishmentCassettesRequest:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          cassettes: {
            ...state.missedReplenishment.cassettes,
            isLoading: true,
          },
        },
      }
    case CMActions.CitBalGetAddEventsResponse:
    case CMActions.IcsGetMissedReplenishmentCassettesResponse:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          cassettes: {
            ...state.missedReplenishment.cassettes,
            isLoading: false,
            isLoaded: true,
            data: payload,
          },
        },
      }
    case CMActions.CitBalGetAddEventsFail:
    case CMActions.IcsGetMissedReplenishmentCassettesFail:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          cassettes: {
            ...state.missedReplenishment.cassettes,
            isLoading: false,
            isLoaded: true,
            error: payload,
          },
        },
      }
    case CMActions.CitBalUpdateAddEvents:
    case CMActions.IcsGetMissedReplenishmentCassettesUpdate:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          cassettes: {
            ...state.missedReplenishment.cassettes,
            data: payload,
          },
        },
      }
    case CMActions.CitBalCreateAddEventRequest:
    case CMActions.IcsCreateMissedReplenishmentEventRequest:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          events: {
            ...state.missedReplenishment.events,
            isLoading: true,
          },
        },
      }
    case CMActions.CitBalCreateAddEventResponse:
    case CMActions.IcsCreateMissedReplenishmentEventResponse:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          isEventsCreated: true,
        },
      }
    case CMActions.CitBalCreateAddEventFail:
    case CMActions.IcsCreateMissedReplenishmentEventFail:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,

          events: {
            ...state.missedReplenishment.events,
            isLoading: false,
          },
        },
      }

    case CMActions.CitBalApplyEventRequest:
    case CMActions.IcsApplyMissedReplenishmentEventRequest:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          isEventsNotAppliedWithError: false,
        },
      }
    case CMActions.CitBalApplyEventResponse:
    case CMActions.IcsApplyMissedReplenishmentEventResponse:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          isEventsApplied: true,
        },
      }
    case CMActions.CitBalApplyEventFail:
    case CMActions.IcsApplyMissedReplenishmentEventFail:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          isEventsNotAppliedWithError: true,
        },
      }
    case CMActions.IcsSetInitialMissedReplenishment:
      return {
        ...state,
        missedReplenishment: initialState.missedReplenishment,
      }
    case CMActions.IcsSetMissedReplenishmentEventType:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          cassettes: {
            ...state.missedReplenishment.cassettes,
            eventType: payload,
          },
        },
      }

    case CMActions.IcsSetInitialMissedReplenishmentCassettes:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          cassettes: initialState.missedReplenishment.cassettes,
        },
      }
    case CMActions.CitBalCalcAddEventsTotalRequest:
    case CMActions.IcsCalcMissedReplenishmentCassettesTotalRequest:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          cassettes: {
            ...state.missedReplenishment.cassettes,
            totals: {
              ...state.missedReplenishment.cassettes.totals,
              isLoading: true,
            },
          },
        },
      }
    case CMActions.CitBalCalcAddEventsTotalResponse:
    case CMActions.IcsCalcMissedReplenishmentCassettesTotalResponse:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          cassettes: {
            ...state.missedReplenishment.cassettes,
            totals: {
              ...state.missedReplenishment.cassettes.totals,
              isLoaded: true,
              isLoading: false,
              data: payload,
            },
          },
        },
      }
    case CMActions.CitBalCalcAddEventsTotalFail:
    case CMActions.IcsCalcMissedReplenishmentCassettesTotalFail:
      return {
        ...state,
        missedReplenishment: {
          ...state.missedReplenishment,
          cassettes: {
            ...state.missedReplenishment.cassettes,
            totals: {
              ...state.missedReplenishment.cassettes.totals,
              isLoaded: true,
              isLoading: false,
              error: payload,
            },
          },
        },
      }
    case CMActions.ComSetBalancingDialogInitial:
      return {
        ...state,
        balancingCassettes: initialState.balancingCassettes,
        balancingInfo: initialState.balancingInfo,
        selectedUnloadedCassettes: initialState.selectedUnloadedCassettes,
        selectedNotLoadedCassettes: initialState.selectedNotLoadedCassettes,
        balancingUnloadedTotals: initialState.balancingUnloadedTotals,
        balancingNotLoadedTotals: initialState.balancingNotLoadedTotals,
      }

    case CMActions.BalGetInfoRequest:
    case CMActions.CitBalGetInfoRequest:
      return {
        ...state,
        balancingInfo: {
          ...state.balancingInfo,
          isLoading: true,
        },
      }
    case CMActions.BalGetInfoResponse:
    case CMActions.CitBalGetInfoResponse:
      return {
        ...state,
        balancingInfo: {
          ...state.balancingInfo,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      }
    case CMActions.BalGetInfoFail:
    case CMActions.CitBalGetInfoFail:
      return {
        ...state,
        balancingInfo: {
          ...state.balancingInfo,
          isLoading: false,
          isLoaded: true,
          error: payload,
        },
      }

    case CMActions.BalGetCassettesRequest:
    case CMActions.CitBalGetCassettesRequest:
      return {
        ...state,
        balancingCassettes: {
          ...state.balancingCassettes,
          isLoading: true,
        },
      }
    case CMActions.BalGetCassettesResponse:
    case CMActions.CitBalGetCassettesResponse:
      return {
        ...state,
        balancingCassettes: {
          ...state.balancingCassettes,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      }
    case CMActions.BalGetCassettesFail:
    case CMActions.CitBalGetCassettesFail:
      return {
        ...state,
        balancingCassettes: {
          ...state.balancingCassettes,
          isLoading: false,
          isLoaded: true,
          error: payload,
        },
      }

    case CMActions.BalSetSelectedEndCycle: {
      const [selectedUnloadedCassettes] =
        state.balancingCassettes.data.unloadedCassettes?.cassetteData?.filter(
          cassette => cassette.sessionId === payload,
        ) || []
      return {
        ...state,
        balancingCassettes: {
          ...state.balancingCassettes,
          selectedCycleEndSessionId: payload,
        },
        selectedUnloadedCassettes: selectedUnloadedCassettes,
      }
    }

    case CMActions.BalSetOnlySelectedEndCycle: {
      return {
        ...state,
        balancingCassettes: {
          ...state.balancingCassettes,
          selectedCycleEndSessionId: payload,
        },
      }
    }
    case CMActions.BalApplyBalNotLoadedCassettesRequest:
    case CMActions.BalApplyBalUnloadedCassettesRequest:
    case CMActions.CitBalApplyBalUnloadedCassettesRequest:
      return {
        ...state,
        isApplyLoading: true,
        isUnloadedCassettesApplied: false,
      }

    case CMActions.BalApplyBalNotLoadedCassettesResponse:
    case CMActions.BalApplyBalUnloadedCassettesResponse:
    case CMActions.CitBalApplyBalUnloadedCassettesResponse:
      return {
        ...state,
        isApplyLoading: false,
        isUnloadedCassettesApplied: true,
      }

    case CMActions.BalApplyBalUnloadedCassettesFail:
    case CMActions.BalApplyBalNotLoadedCassettesFail:
      return {
        ...state,
        isApplyLoading: false,
        isUnloadedCassettesApplied: true,
      }

    case CMActions.CitBalSetUnloadedCassettesApplied:
      return {
        ...state,
        isUnloadedCassettesApplied: false,
      }

    case CMActions.BalCalcUnloadedTotalsResponse:
    case CMActions.CitBalCalcUnloadedTotalsResponse:
      return {
        ...state,
        balancingUnloadedTotals: {
          ...state.balancingUnloadedTotals,
          data: payload,
          isLoading: false,
        },
      }
    case CMActions.BalCalcUnloadedTotalsFail:
      return {
        ...state,
        balancingUnloadedTotals: {
          ...state.balancingUnloadedTotals,
          isLoading: false,
        },
      }
    case CMActions.BalCalcNotLoadedTotalsResponse:
      return {
        ...state,
        balancingNotLoadedTotals: {
          ...state.balancingNotLoadedTotals,
          data: payload,
          isLoading: false,
        },
      }
    case CMActions.BalCalcNotLoadedTotalsFail:
      return {
        ...state,
        balancingNotLoadedTotals: {
          ...state.balancingNotLoadedTotals,
          isLoading: false,
        },
      }

    case CMActions.BalSetSelectedUnloadedCassettes:
    case CMActions.CitBalSetSelectedUnloadedCassettes:
      return {
        ...state,
        selectedUnloadedCassettes: payload,
        balancingUnloadedTotals: {
          ...state.balancingUnloadedTotals,
          isLoading: true,
        },
      }
    case CMActions.BalSetSelectedNotLoadedCassettes:
      return {
        ...state,
        selectedNotLoadedCassettes: payload,
        balancingNotLoadedTotals: {
          ...state.balancingNotLoadedTotals,
          isLoading: true,
        },
      }
    case CMActions.BalSetUnloadedTotalSessionId:
      return {
        ...state,
        balancingUnloadedTotals: {
          ...state.balancingUnloadedTotals,
          totalSessionId: payload,
        },
      }

    // Accounts --------------------------------------------------------------
    case CMObjectsActions.AdmGetObjectsAccountsRequest:
    case CMActions.CitBalGetAccountsRequest:
    case CMObjectsActions.AdmUpdateObjectsAccountsRequest:
    case CMActions.CitBalUpdateAccountsRequest:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          isLoading: true,
        },
      }
    case CMObjectsActions.AdmGetObjectsAccountsResponse:
    case CMActions.CitBalGetAccountsResponse: {
      const parsedData = payload.accounts.map((account: ParsedCitBalCashPointAccount) => ({
        ...account,
        id: faker.random.uuid(),
        isNew: false,
      }))

      return {
        ...state,
        accounts: {
          ...state.accounts,
          isLoading: false,
          isLoaded: true,
          data: parsedData,
          initialData: parsedData,
          tid: payload.tid,
          tableColumns: payload.tableColumns,
        },
      }
    }
    case CMObjectsActions.AdmGetObjectsAccountsFail:
    case CMActions.CitBalGetAccountsFail:
    case CMObjectsActions.AdmUpdateObjectsAccountsFail:
    case CMActions.CitBalUpdateAccountsFail:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          isLoading: false,
          isLoaded: true,
          error: payload,
        },
      }

    case CMActions.ComSetParsedCurrencies:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          parsedCurrencies: payload,
        },
      }
    case CMActions.ComSetAccountsInitial:
      return {
        ...state,
        accounts: initialState.accounts,
      }

    case CMActions.ComUpdateAccountRow:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          data: state.accounts.data.map(currency =>
            payload.id === currency.id ? payload : currency,
          ),
          selectedAccount: payload,
        },
      }
    case CMActions.ComSetSelectedAccountRow:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          selectedAccount: payload,
        },
      }
    case CMActions.ComAddNewAccount:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          data: [...state.accounts.data, payload],
        },
      }
    case CMActions.ComRemoveAccountRow:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          data: state.accounts.data.filter(
            account => account.id !== state.accounts.selectedAccount?.id,
          ),
          selectedAccount: null,
        },
      }

    // -------------------------------------------------------------------
    case CMActions.ComGetCassetteCurrenciesRequest:
    case CMActions.ComGetCassetteCurrenciesFail:
      return {
        ...state,
        cassetteConfig: {
          ...state.cassetteConfig,
          currencies: [],
        },
      }

    case CMActions.ComGetCassetteCurrenciesResponse:
      return {
        ...state,
        cassetteConfig: {
          ...state.cassetteConfig,
          currencies: payload,
        },
      }
    case CMActions.ComGetCassetteTypesRequest:
    case CMActions.ComGetCassetteTypesFail:
      return {
        ...state,
        cassetteConfig: {
          ...state.cassetteConfig,
          types: [],
        },
      }
    case CMActions.ComGetCassetteTypesResponse:
      return {
        ...state,
        cassetteConfig: {
          ...state.cassetteConfig,
          types: payload,
        },
      }

    case CMObjectsActions.AdmGetObjectsCassettesConfigRequest:
    case CMActions.PlnGetConfigRequest:
      return {
        ...state,
        cassetteConfig: {
          ...state.cassetteConfig,
          config: [],
          isLoading: true,
          errorMessage: '',
        },
      }
    case CMObjectsActions.AdmGetObjectsCassettesConfigResponse:
    case CMActions.PlnGetConfigResponse:
      return {
        ...state,
        cassetteConfig: {
          ...state.cassetteConfig,
          config: payload,
          isLoading: false,
          isEditing: false,
          errorMessage: '',
        },
      }
    case CMObjectsActions.AdmGetObjectsCassettesConfigFail:
    case CMActions.PlnGetConfigResponseFail:
      return {
        ...state,
        cassetteConfig: {
          ...state.cassetteConfig,
          config: [],
          isLoading: false,
          isEditing: false,
          errorMessage: payload,
        },
      }

    case CMActions.PlnModifyConfigRequest:
    case CMObjectsActions.AdmGetObjectsModifyConfigRequest:
      return {
        ...state,
        cassetteConfig: {
          ...state.cassetteConfig,
          isEditing: true,
        },
      }
    case CMActions.PlnModifyConfigResponse:
    case CMActions.PlnModifyConfigFail:
    case CMObjectsActions.AdmGetObjectsModifyConfigFail:
      return {
        ...state,
        cassetteConfig: {
          ...state.cassetteConfig,
          isEditing: false,
        },
      }

    case CMActions.ComGetCurrenciesAndDenominationsResponse:
      return {
        ...state,
        denominations: payload,
      }

    case CMActions.ComGetCompaniesResponse:
      return {
        ...state,
        companies: payload,
      }

    case CMActions.ComSetIsCompanyModal:
      return {
        ...state,
        addCompany: {
          ...state.addCompany,
          isModalOpen: payload,
        },
      }
    case CMActions.ComSetNewCompanyRequest:
      return {
        ...state,
        addCompany: {
          ...state.addCompany,
          isCompanySending: true,
        },
      }
    case CMActions.ComSetNewCompanyResponse:
      return {
        ...state,
        addCompany: {
          ...state.addCompany,
          isCompanySending: false,
          isModalOpen: false,
        },
      }
    case CMActions.ComSetNewCompanyFail:
      return {
        ...state,
        addCompany: {
          ...state.addCompany,
          isCompanySending: false,
        },
      }

    case CMActions.ComGetUsersOptionsResponse:
      return {
        ...state,
        usersOptions: payload,
      }

    case CMActions.ComSetApproveItemsRequest:
    case CMActions.ComGetApproveItemsRequest:
      return {
        ...state,
        approvalItem: {
          ...state.approvalItem,
          isLoading: true,
        },
      }
    case CMActions.ComSetApproveItemsResponse:
      return {
        ...state,
        approvalItem: {
          ...state.approvalItem,
          hasApprovalChanges: true,
        },
      }
    case CMActions.ComGetApproveItemsResponse:
      return {
        ...state,
        approvalItem: {
          ...state.approvalItem,
          isLoading: false,
          isLoaded: true,
          data:
            payload.map((item: AdmApprovalItem) => ({
              ...item,
              approve: false,
              reject: false,
            })) || [],
        },
      }
    case CMActions.ComSetApproveItemsFail:
    case CMActions.ComGetApproveItemsFail:
      return {
        ...state,
        approvalItem: {
          ...state.approvalItem,
          isLoading: false,
          isLoaded: true,
          error: payload,
        },
      }
    case CMActions.ComUpdateLocalApproveItems:
      return {
        ...state,
        approvalItem: {
          ...state.approvalItem,
          data: payload,
        },
      }
    case CMActions.ComOpenApproveItemsModal: {
      const { isModalOpen, ...props } = payload

      return {
        ...state,
        approvalItem: {
          ...state.approvalItem,
          ...props,
          isModalOpen: isModalOpen,
          hasApprovalChanges: isModalOpen ? false : state.approvalItem.hasApprovalChanges,
        },
      }
    }

    case CMActions.SetAboutModalOpen:
      return {
        ...state,
        about: {
          ...state.about,
          isModalOpen: payload,
        },
      }

    case CMActions.ComSetIsWorkingWithTeam:
      return {
        ...state,
        isWorkingWithTeam: payload,
      }

    case CMActions.ComGetIntegrationsResponse:
      return {
        ...state,
        integration: payload,
      }

    case CMActions.ComGetVaultsWithLocationResponse:
      return {
        ...state,
        vaultsWithLocation: payload,
      }

    case CMActions.ComGetTeamsResponse:
      return {
        ...state,
        teams: payload || [],
      }

    case CMActions.ComSetError:
      return {
        ...state,
        isApplyLoading: false,
      }

    case CMActions.SignOut:
      return initialState
    default:
      return state
  }
}
