import React from 'react'
import { useClasses, HEIGHT_OF_CHART_SECTION } from './styles'
import Chart from '@/components/pages/sd/dashboard/MainPage/components/Widget/components/Chart/component'
import { CHART_IDS } from '@/constants/charts'
import { Props } from './types'
import Test from 'react-test-attributes'
import { getTestId } from '@/utils'

const ChartSection = ({ testIdPrefix }: Props): React.ReactElement => {
  const classes = useClasses()
  const idPrefix = `${testIdPrefix}-chart-section`
  const testId = getTestId(idPrefix)

  return (
    <Test id={testId(0)}>
      <div className={classes.wrapper}>
        <Test id={testId(1)}>
          <div className={classes.chart}>
            <Chart chartId={CHART_IDS.CustomPieChart} height={HEIGHT_OF_CHART_SECTION} />
          </div>
        </Test>
      </div>
    </Test>
  )
}
export default ChartSection
