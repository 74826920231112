import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles(({ spacing }) =>
  createStyles({
    selectButton: {
      margin: spacing(1.5, 0),
      '& .makeStyles-icon-113': {
        color: '#041517',
        fontSize: '1.4rem',
      },
      '& .MuiSelect-select': {
        paddingTop: '14px',
        paddingBottom: '14px',
        fontSize: '14px',
      },
    },
  }),
)
