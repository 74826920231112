import { makeStyles } from '@material-ui/core'

export const useClasses = makeStyles(() => ({
  statusTitle: {
    width: '100%',
  },

  teamSelect: {
    minWidth: '100px',
  },
}))
