import { ProductsConfig, Products } from '@/types/products'
import {
  FilterParameter,
  FilterTypeDisplayName,
  FilterValueTypes,
  EnableModes,
} from '@/types/common/filterConfig'

export const config: { formConfig: ProductsConfig } = {
  formConfig: {
    filter: [
      {
        allowMultipleSelect: false,
        displayType: FilterTypeDisplayName.Text,
        parameterName: 'productcode',
        label: 'Product code',
        toolTip: 'Choose product code',
        labelKey: 'product_code',
        toolTipKey: 'choose_product_code',
        valueType: FilterValueTypes.String,
      },
      {
        allowMultipleSelect: false,
        displayType: FilterTypeDisplayName.Text,
        parameterName: 'productname',
        label: 'Product name',
        toolTip: 'Choose product name',
        labelKey: 'product_name',
        toolTipKey: 'choose_product_name',
        valueType: FilterValueTypes.String,
      },
    ],
    formUID: 'form_uid',
    getConfigURL: '',
    getOutDetailsURL: '',
    getOutTreeURL: '',
    groups: [],
    outputFields: [
      {
        fieldLabel: 'Product code',
        fieldLabelKey: 'product_code',
        fieldName: 'productCode',
        fieldType: 'STRING',
        fieldValue: null,
        orderNo: 1,
        isVisibleByDefault: true,
        sortDisabled: false,
      },
      {
        fieldLabel: 'Product name',
        fieldLabelKey: 'product_name',
        fieldName: 'productName',
        fieldType: 'STRING',
        fieldValue: null,
        orderNo: 1,
        isVisibleByDefault: true,
        sortDisabled: false,
      },
      {
        fieldLabel: 'Type',
        fieldLabelKey: 'type',
        fieldName: 'type',
        fieldType: 'STRING',
        fieldValue: null,
        orderNo: 1,
        isVisibleByDefault: true,
        sortDisabled: false,
      },
      {
        fieldLabel: 'Q-ty key',
        fieldLabelKey: 'q-ty_key',
        fieldName: 'qTyKey',
        fieldType: 'STRING',
        fieldValue: null,
        orderNo: 1,
        isVisibleByDefault: true,
        sortDisabled: false,
      },
      {
        fieldLabel: 'Price',
        fieldLabelKey: 'price',
        fieldName: 'price',
        fieldType: 'STRING',
        fieldValue: null,
        orderNo: 1,
        isVisibleByDefault: true,
        sortDisabled: false,
      },
      {
        fieldLabel: 'Is repairable',
        fieldLabelKey: 'is_repairable',
        fieldName: 'isRepairable',
        fieldType: 'STRING',
        fieldValue: null,
        orderNo: 1,
        isVisibleByDefault: true,
        sortDisabled: false,
      },
      {
        fieldLabel: 'Spare part group',
        fieldLabelKey: 'spare_part_group',
        fieldName: 'sparePartGroup',
        fieldType: 'STRING',
        fieldValue: null,
        orderNo: 1,
        isVisibleByDefault: true,
        sortDisabled: false,
      },
      {
        fieldLabel: 'Group',
        fieldLabelKey: 'group',
        fieldName: 'group',
        fieldType: 'STRING',
        fieldValue: null,
        orderNo: 1,
        isVisibleByDefault: true,
        sortDisabled: false,
      },
      {
        fieldLabel: 'Sell group',
        fieldLabelKey: 'sell_group',
        fieldName: 'sellGroup',
        fieldType: 'STRING',
        fieldValue: null,
        orderNo: 1,
        isVisibleByDefault: true,
        sortDisabled: false,
      },
    ],
  },
}

export const products: Products = {
  totalCount: 3,
  hasGroups: false,
  groups: null,
  details: {
    pageCount: 1,
    outputPageNo: 0,
    items: [
      {
        item: {
          productCode: '01252342423',
          productName: 'Product 1',
          type: 'Detail',
          qTyKey: 'Vilnius',
          price: '100.0',
          isRepairable: 'true',
          sparePartGroup: 'Displays',
          group: 'Automated Teller Machines',
          sellGroup: 'ATM Components',
        },
        childItems: [],
      },
      {
        item: {
          productCode: '01356345423',
          productName: 'Product 2',
          type: 'Module',
          qTyKey: 'Vilnius',
          price: '100.0',
          isRepairable: 'false',
          sparePartGroup: 'Displays',
          group: 'Automated Teller Machines',
          sellGroup: 'ATM Components',
        },
        childItems: [],
      },
      {
        item: {
          productCode: '01242345493',
          productName: 'Product 3',
          type: 'Detail',
          qTyKey: 'Vilnius',
          price: '100.0',
          isRepairable: 'false',
          sparePartGroup: 'Displays',
          group: 'Automated Teller Machines',
          sellGroup: 'ATM Components',
        },
        childItems: [],
      },
    ],
  },
}
