import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: spacing(2),
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 auto',
    flexGrow: 0,
    overflow: 'auto',
    margin: spacing(0.25), // spaces for shadows
  },
  table: {
    flex: 1,
    overflow: 'auto',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(2, 0),
  },
  title: {
    whiteSpace: 'nowrap',
  },
}))
