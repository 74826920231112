import { Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React, { useCallback, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Test from 'react-test-attributes'

import CloseButton from '@/components/blocks/CloseButton/index'
import { TextInput } from '@/components/controls/TextInput/component'
import { detectTicketTabLocation } from '@/components/pages/sd/helpers/sdHelpers'
import { useDebounceInput } from '@/components/pages/sd/TicketsPage/helpers/hooks/useDebounceInput'
import { deleteSdGroupTemplateRequest, editSdGroupTemplateRequest } from '@/store/sd/tickets/index'
import { getTestId } from '@/utils'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './style'
import { Props } from './types'

const EditGroupModal = ({
  classes,
  currentGrouping,
  templateId,
  templateName,
  testIdPrefix,
  setGroups,
  initialGroups,
  handleClose,
  setSavedTemplate,
}: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const location = useLocation()
  const translate = useTranslate()
  const idPrefix = `${testIdPrefix}-edit-group-modal`
  const testId = getTestId(idPrefix)
  const { inputValue, inputErrors, setInputValue, handleChangeInputValue } = useDebounceInput('')

  useEffect((): void => {
    setInputValue(templateName)
  }, [templateName])

  const handleEditTemplate = useCallback((): void => {
    dispatch(
      editSdGroupTemplateRequest({
        template: {
          id: templateId,
          name: inputValue,
          config: currentGrouping,
        },
        windowType: detectTicketTabLocation(location),
        translate,
      }),
    )

    setSavedTemplate(inputValue)
    handleClose()
  }, [dispatch, handleClose, currentGrouping, inputValue, templateId])

  const handleDeleteTemplate = useCallback((): void => {
    dispatch(
      deleteSdGroupTemplateRequest({
        template: {
          id: templateId,
          name: inputValue,
          config: currentGrouping,
        },
        windowType: detectTicketTabLocation(location),
        translate,
      }),
    )
    setGroups(initialGroups)
    handleClose()
  }, [dispatch, handleClose, currentGrouping, inputValue, templateId])

  const clas = useClasses()

  return (
    <>
      <Test id={testId(0)}>
        <CloseButton absolute className={classes.closeButton} onClick={handleClose} />
      </Test>
      <Test id={testId(1)}>
        <Typography className={classes.subtitle}>
          <FormattedMessage id="title.renameGroupTemplate" defaultMessage="Rename group template" />
        </Typography>
      </Test>
      <Test id={testId(2)}>
        <TextInput
          value={inputValue}
          onChange={handleChangeInputValue}
          className={classes.input}
          autoFocus
          label={translate('translate#title.enterTemplateName')}
        />
      </Test>
      <Test id={testId(3)}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Test id={testId(4)}>
            <Grid item>
              <Test id={testId(5)}>
                <Button
                  onClick={handleDeleteTemplate}
                  className={clas.deleteButton}
                  variant="outlined"
                >
                  <FormattedMessage id="action.delete" defaultMessage="Delete" />
                </Button>
              </Test>
            </Grid>
          </Test>
          <Test id={testId(6)}>
            <Grid item>
              <Test id={testId(7)}>
                <Button
                  onClick={handleEditTemplate}
                  className={classes.button}
                  variant="contained"
                  disabled={!!inputErrors || !inputValue.length}
                >
                  <FormattedMessage id="action.save" defaultMessage="Save" />
                </Button>
              </Test>
            </Grid>
          </Test>
        </Grid>
      </Test>
    </>
  )
}

export default EditGroupModal
