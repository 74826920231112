import makeStyles from '@material-ui/core/styles/makeStyles'

import { styledComponentTheme } from '@/theme'
import colors from '@/theme/colors'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  select: {
    width: '100%',
    margin: spacing(2, 0),
  },
  loader: {
    flex: 1,
  },
  placeholderOption: {
    color: '#999',
  },
  normalOption: {
    color: 'black',
    // fontSize: '90%',
  },
  optionIcon: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100%',
    minWidth: '10px',
    width: '44%',

    '& div': {
      minWidth: '10px',
    },
  },
  optionName: {
    // top: '-3px',
    display: 'flex',
    alignItems: 'center',
    color: colors.text,
  },
  headerItem: {
    opacity: 1,
  },
  headerText: {
    marginBottom: 0,
  },
  disabled: {
    opacity: 0.7,
    pointerEvents: 'none',
  },
  errorLabel: {
    height: spacing(2),
    fontSize: styledComponentTheme.fontSizes.verySmall,
    paddingTop: spacing(0.5),
    paddingLeft: spacing(2),
    color: palette.error.main,
  },
  inputWithError: {
    marginBottom: 0,
  },
  iconButton: {
    position: 'absolute',
    right: spacing(4),
    bottom: '31%',
  },
  icon: {
    width: spacing(2.5),
    height: spacing(2.5),
  },
  hidden: {
    display: 'none',
  },
  selectOption: {
    maxWidth: '500px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  label: {
    transform: 'translate(14px, -50%) scale(0.75) !important',
  },
}))
