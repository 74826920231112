import Axios from 'axios'
import queryString from 'query-string'

import {
  ComCMReport,
  MonCashPoint,
  MonCashPointCasseteResponse,
  MonCashPointCassetteDetail,
  MonCashPointDetails,
  MonCashPointsRequest,
  MonCashPointsResponse,
  MonCassetteChartsRequest,
  MonChartsRequestBody,
  MonReportsRequest,
  MonSetPlanStatusRequest,
  MonSettingsResponse,
} from '@/api/cm/swaggerGeneratedApi/index'
import { APIConfiguration, LocalStorageItems } from '@/constants'
import { ExtendedCPDetails } from '@/types/cm/monitoring'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

import { mapCashPointDetailsData } from './helpers'

export class MonitoringApi {
  private static requestService = getRequestServiceCM()

  public static async getCashPointDetails(
    requestBody: MonChartsRequestBody,
  ): Promise<ExtendedCPDetails> {
    try {
      const data: MonCashPointDetails = await MonitoringApi.requestService.post(
        `/monitoring/pointDetails`,
        requestBody,
      )
      return mapCashPointDetailsData(data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashPoints(
    paging: { page: number; pageSize: number },
    requestBody: MonCashPointsRequest,
    sortColumn?: string,
    sortOrder?: 'asc' | 'desc',
  ): Promise<MonCashPointsResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/monitoring/points',
          query: {
            pageSize: `${paging.pageSize}`,
            page: `${paging.page}`,
            sortColumn,
            sortOrder,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )

      return await MonitoringApi.requestService.post(url, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashPoint(guid: string): Promise<MonCashPoint> {
    try {
      return await MonitoringApi.requestService.get('/monitoring/point', { guid })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashPointCassettes(guid: string): Promise<MonCashPointCasseteResponse> {
    try {
      const response = await MonitoringApi.requestService.post(
        `/monitoring/pointCassetes?guid=${guid}`,
        {},
      )

      return response
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashPointCassettesForChart(
    payload: MonCassetteChartsRequest,
  ): Promise<MonCashPointCassetteDetail[]> {
    try {
      const tokenCM = localStorage.getItem(LocalStorageItems.AccessTokenCM)
      const response = await Axios.post(
        `${APIConfiguration.CM_API_BASE_URL}/monitoring/pointCasseteDetails`,
        payload,
        {
          headers: { Authorization: `Bearer ${tokenCM}` },
        },
      )
      return response.data.cassetes
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashPointCassettesForChartCIN(
    payload: MonCassetteChartsRequest,
  ): Promise<MonCashPointCassetteDetail[]> {
    try {
      const tokenCM = localStorage.getItem(LocalStorageItems.AccessTokenCM)
      const response = await Axios.post(
        `${APIConfiguration.CM_API_BASE_URL}/monitoring/pointCassetteCashInDetails`,
        payload,
        {
          headers: { Authorization: `Bearer ${tokenCM}` },
        },
      )
      return response.data.cassetes
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getReports(payload: MonReportsRequest): Promise<ComCMReport[]> {
    try {
      const data = await MonitoringApi.requestService.post('/monitoring/reports', payload)
      return data
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setPlanStatus(payload: MonSetPlanStatusRequest): Promise<void> {
    try {
      const response = await MonitoringApi.requestService.put('/monitoring/setPlanStatus', payload)
      return response
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getSettings(): Promise<MonSettingsResponse> {
    try {
      const response = await MonitoringApi.requestService.get('/monitoring/settings ')
      return response
    } catch (e) {
      throw new Error(e)
    }
  }
}
