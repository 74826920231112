import { AnyAction } from 'redux'

import { UsrChangePasswordRequest, UsrLoginResponse } from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants/actions/index'
import { LoginFormValues } from '@/types'
import { DataToLS } from '@/types/cm/localStorage'

export const signInRequestVaultV1 = (values: LoginFormValues): AnyAction => ({
  type: VaultV1Actions.SignInRequest,
  payload: values,
})

export const signInResponseVaultV1 = (displayName: string): AnyAction => ({
  type: VaultV1Actions.SignInResponse,
  payload: displayName,
})

export const signInFailVaultV1 = (error: string): AnyAction => ({
  type: VaultV1Actions.SignInFail,
  payload: error,
})

export const setUserVaultV1 = (payload: UsrLoginResponse): AnyAction => ({
  type: VaultV1Actions.SetUser,
  payload,
})

export const signOutVaultV1 = (withLogout?: boolean): AnyAction => ({
  type: VaultV1Actions.SignOut,
  payload: withLogout,
})

export const changePasswordRequest = (payload: UsrChangePasswordRequest): AnyAction => ({
  type: VaultV1Actions.ChangePasswordRequest,
  payload,
})
export const changePasswordResponse = (): AnyAction => ({
  type: VaultV1Actions.ChangePasswordResponse,
})
export const changePasswordFail = (error: string): AnyAction => ({
  type: VaultV1Actions.ChangePasswordFail,
  payload: error,
})

export const setChangePasswordModalOpen = (isOpen: boolean): AnyAction => ({
  type: VaultV1Actions.SetChangePasswordModalOpen,
  payload: isOpen,
})

export const getCulturesVaultV1 = (): AnyAction => ({
  type: VaultV1Actions.GetCultures,
})

export const setCultureVaultV1 = (culture?: string): AnyAction => ({
  type: VaultV1Actions.SetCulture,
  culture,
})

export const checkIsLoginVaultV1 = (): AnyAction => ({
  type: VaultV1Actions.CheckIsLogin,
})

export const setDataToLS = (payload: DataToLS): AnyAction => ({
  type: VaultV1Actions.SetDataToLS,
  payload,
})
