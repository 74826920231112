import React, { ReactElement, useState, useCallback, useEffect, useMemo } from 'react'
import clsx from 'clsx'
import { debounce, get } from 'lodash-es'
import { Field as FormikField } from 'formik'

import Loader from '@/components/blocks/Loader'
import { TicketsApi } from '@/api/sd/ticketsApi'
import { createTextInputField } from '@/components/controls/TextInput'
import { createSelectField } from '@/components/controls/Select'
import { createMultipleSelectField } from '@/components/controls/MultipleSelect'
import { createDropDownInputField } from '@/components/controls/DropDownInput'
import { createCheckboxField } from '@/components/controls/Checkbox'
import { createDatePickerField } from '@/components/controls/DateInput'
import { createRadioGroupFormikField } from '@/components/controls/RadioGroup/component'

import { FilterValueTypes, EnableModes } from '@/types/common/filterConfig'
import { LookupFieldConfig } from '@/types/common/lookupConfig'
import { useClasses } from './styles'
import { Props, FieldTypeName, DependentField } from './types'

const GeneratedField = React.memo(
  ({
    field,
    fieldName,
    formikValues,
    setFormikFieldValue,
    lookupFieldConfig,
    isLoading: configIsLoading,
    defaultValue,
    formikPath,
    backupOptions,
  }: Props): ReactElement => {
    const [dataIsLoading, setDataIsLoading] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(false)
    const [options, setOptions] = useState<{ value: string; name: string }[]>([])
    const [dependentFields, setDependentFields] = useState<DependentField[]>([])

    const classes = useClasses()

    const getOptions = useCallback(
      async (config: LookupFieldConfig, searchFragment = ''): Promise<any> => {
        try {
          setDataIsLoading(true)

          const response = await TicketsApi.getLookupValues({
            dependedOnFilter: dependentFields,
            displayFieldName: config.fieldLookupInfo.displayFieldName,
            entityName: config.fieldLookupInfo.entityName,
            getValuesURL: config.fieldLookupInfo.getValuesURL,
            valueFieldName: config.fieldLookupInfo.valueFieldName,
            valueFieldType: config.fieldLookupInfo.valueFieldType,
            searchFragment,
            pagination: {
              direction: 'ASC',
              pageNumber: 1,
              pageSize: 100,
            },
          })

          const options = response.values.map(({ value, display }) => ({ value, name: display }))

          if (defaultValue && !options.some(option => option.value === defaultValue.value)) {
            setOptions([defaultValue, ...options])
          } else {
            setOptions(options)
          }

          setDataIsLoading(false)
        } catch (error) {
          console.log(`error`, error)
          // throw new Error(error)
        }
      },
      [dependentFields],
    )

    const handleSearch = useCallback(
      debounce((value: string) => {
        if (value.length >= 3 && lookupFieldConfig) {
          getOptions(lookupFieldConfig, value)
        }
      }, 1000),
      [lookupFieldConfig, dependentFields],
    )

    const handleOpen = (config: LookupFieldConfig) => (): void => {
      if (!options.length) {
        getOptions(config)
      }
    }

    useEffect(() => {
      if (lookupFieldConfig && lookupFieldConfig.fieldLookupInfo) {
        const { dependedOn, enableMode } = lookupFieldConfig.fieldLookupInfo

        if (dependedOn && dependedOn.length) {
          const fields = dependedOn.map(key =>
            formikPath ? get(formikValues, `${formikPath}.${key}`) : formikValues[key],
          )

          if (enableMode === EnableModes.AllParentIsSet && fields.every(field => field)) {
            return setDisabled(false)
          }
          if (enableMode === EnableModes.SomeParentIsSet && fields.some(field => field)) {
            return setDisabled(false)
          }
          setDisabled(true)
        }
      }
    }, [formikValues])

    useEffect(
      () => {
        if (
          lookupFieldConfig &&
          lookupFieldConfig.fieldLookupInfo.dependedOn &&
          lookupFieldConfig.fieldLookupInfo.dependedOn.length
        ) {
          // Experimental
          if (!options.length && get(formikValues, fieldName)) {
            return
          }

          setFormikFieldValue(fieldName, '')
          setOptions([])
        }
      },
      lookupFieldConfig?.fieldLookupInfo.dependedOn
        ? lookupFieldConfig.fieldLookupInfo.dependedOn.map(key =>
            formikPath ? get(formikValues, `${formikPath}.${key}`) : formikValues[key],
          )
        : [],
    )

    useEffect(() => {
      if (backupOptions && backupOptions.backupValues[fieldName]) {
        setFormikFieldValue(fieldName, backupOptions.backupValues[fieldName].value)
      }
    }, [backupOptions?.backupValues[fieldName]])

    useEffect(() => {
      if (
        lookupFieldConfig &&
        lookupFieldConfig.fieldLookupInfo.dependedOn &&
        lookupFieldConfig.fieldLookupInfo.dependedOn.length
      ) {
        const fields: DependentField[] = []

        lookupFieldConfig.fieldLookupInfo.dependedOn.forEach(name => {
          if (Array.isArray(formikValues[name])) {
            return {
              parameterName: name,
              parameterValue: formikValues[name].map((value: string) => ({
                id: value,
                value: null,
              })),
              valueType: lookupFieldConfig.fieldLookupInfo.valueFieldType,
            }
          }

          fields.push({
            parameterName: name,
            parameterValue: [
              {
                id: get(formikValues, `${formikPath}.${name}`),
                value: null,
              },
            ],
            valueType: lookupFieldConfig.fieldLookupInfo.valueFieldType,
          })
        })

        setDependentFields(fields)
      }
    }, [formikValues])

    const Field = useMemo(() => {
      switch (field.type) {
        case FieldTypeName.Text:
          return createTextInputField({
            label: field.options?.textInput?.label,
            placeholder: field.options?.textInput?.placeholder,
            fullWidth: true,
            clearButton: true,
            InputLabelProps: {
              shrink: true,
            },
            disabledKeys: field.options?.textInput?.disableKeys,
            required: field.options?.textInput?.required,
          })
        case FieldTypeName.List:
          if (lookupFieldConfig) {
            const {
              allowRecursiveCallsBySearchFragment,
              lookupValues,
              enableMode,
            } = lookupFieldConfig.fieldLookupInfo

            if (allowRecursiveCallsBySearchFragment) {
              return createDropDownInputField({
                isLoading: dataIsLoading,
                label: field.options?.selectAndDropDownInput?.label,
                placeholder: field.options?.selectAndDropDownInput?.placeholder,
                options: options.length
                  ? options
                  : backupOptions && backupOptions.backupValues[fieldName]
                  ? [backupOptions.backupValues[fieldName]]
                  : defaultValue
                  ? [defaultValue]
                  : [],
                shrink: true,
                timer: field.options?.selectAndDropDownInput?.requestDelay,
                filtering: false,
                neverDisable: false,
                disabled: field.options?.selectAndDropDownInput?.disabled
                  ? true
                  : enableMode === EnableModes.Always
                  ? false
                  : disabled,
                required: field.options?.selectAndDropDownInput?.required,
                onTextChange: handleSearch,
                withoutSingleOption: true,
                onChange: value => {
                  if (backupOptions) {
                    const name = options.find(item => item.value === value)?.name

                    backupOptions.setBackupValue(fieldName, {
                      name: name || '',
                      value: value,
                    })
                  }

                  if (
                    lookupFieldConfig.fieldLookupInfo.valueFieldType === FilterValueTypes.Integer
                  ) {
                    return setFormikFieldValue(fieldName, Number(value))
                  }

                  setFormikFieldValue(fieldName, value)
                },
              })
            }

            if (lookupFieldConfig.allowMultipleSelect) {
              return createMultipleSelectField({
                isLoading: dataIsLoading,
                label: field.options?.selectAndDropDownInput?.label,
                placeholder: field.options?.selectAndDropDownInput?.placeholder,
                options: options.length
                  ? options
                  : backupOptions && backupOptions.backupValues[fieldName]
                  ? [backupOptions.backupValues[fieldName]]
                  : defaultValue
                  ? [defaultValue]
                  : [],
                shrink: true,
                SearchInputBlock: true,
                parameterName: fieldName,
                disabled: field.options?.selectAndDropDownInput?.disabled
                  ? true
                  : enableMode === EnableModes.Always
                  ? false
                  : disabled,
                required: field.options?.selectAndDropDownInput?.required,
                onOpen: handleOpen(lookupFieldConfig),
                onChange: event => {
                  if (backupOptions) {
                    const name = options.find(item => item.value === event.target.value)?.name

                    backupOptions.setBackupValue(fieldName, {
                      name: name || '',
                      value: event.target.value as string,
                    })
                  }

                  if (
                    lookupFieldConfig.fieldLookupInfo.valueFieldType === FilterValueTypes.Integer
                  ) {
                    return setFormikFieldValue(fieldName, Number(event.target.value))
                  }

                  setFormikFieldValue(fieldName, event.target.value)
                },
              })
            }

            if (lookupValues && lookupValues.length > 10) {
              return createDropDownInputField({
                isLoading: dataIsLoading,
                label: field.options?.selectAndDropDownInput?.label,
                placeholder: field.options?.selectAndDropDownInput?.placeholder,
                options: options.length
                  ? options
                  : backupOptions && backupOptions.backupValues[fieldName]
                  ? [backupOptions.backupValues[fieldName]]
                  : defaultValue
                  ? [defaultValue]
                  : [],
                neverDisable: false,
                disabled: field.options?.selectAndDropDownInput?.disabled
                  ? true
                  : enableMode === EnableModes.Always
                  ? false
                  : disabled,
                required: field.options?.selectAndDropDownInput?.required,
                onTextChange: handleSearch,
                onChange: value => {
                  if (backupOptions) {
                    const name = options.find(item => item.value === value)?.name

                    backupOptions.setBackupValue(fieldName, {
                      name: name || '',
                      value: value,
                    })
                  }

                  if (
                    lookupFieldConfig.fieldLookupInfo.valueFieldType === FilterValueTypes.Integer
                  ) {
                    return setFormikFieldValue(fieldName, Number(value))
                  }

                  setFormikFieldValue(fieldName, value)
                },
              })
            }

            return createSelectField({
              isLoading: dataIsLoading,
              label: field.options?.selectAndDropDownInput?.label,
              placeholder: field.options?.selectAndDropDownInput?.placeholder,
              options: options.length
                ? options
                : backupOptions && backupOptions.backupValues[fieldName]
                ? [backupOptions.backupValues[fieldName]]
                : defaultValue
                ? [defaultValue]
                : [],
              shrink: true,
              withoutCheckerForSingleOption: true,
              withAutofillForSingleOption: true,
              disabled: field.options?.selectAndDropDownInput?.disabled
                ? true
                : enableMode === EnableModes.Always
                ? false
                : disabled,
              required: field.options?.selectAndDropDownInput?.required,
              onOpen: handleOpen(lookupFieldConfig),
              onChange: event => {
                if (backupOptions) {
                  const name = options.find(item => item.value === event.target.value)?.name

                  backupOptions.setBackupValue(fieldName, {
                    name: name || '',
                    value: event.target.value as string,
                  })
                }

                if (lookupFieldConfig.fieldLookupInfo.valueFieldType === FilterValueTypes.Integer) {
                  return setFormikFieldValue(fieldName, Number(event.target.value))
                }

                setFormikFieldValue(fieldName, event.target.value)
              },
            })
          }
          break
        case FieldTypeName.Checkbox:
          return createCheckboxField({
            label: field.options?.checkbox?.label,
            required: field.options?.checkbox?.required,
            className: classes.field,
          })
        case FieldTypeName.Date:
          return createDatePickerField({
            label: field.options?.datePicker?.label,
            Icon: field.options?.datePicker?.Icon,
            required: field.options?.datePicker?.required,
            disableFutureDates: field.options?.datePicker?.disableFutureDates,
          })
        case FieldTypeName.RadioGroup:
          return createRadioGroupFormikField({
            options: lookupFieldConfig?.fieldLookupInfo.lookupValues
              ? lookupFieldConfig?.fieldLookupInfo.lookupValues.map(({ display, value }) => ({
                  label: display,
                  value,
                }))
              : [],
            defaultValue: lookupFieldConfig?.fieldLookupInfo.lookupValues
              ? lookupFieldConfig?.fieldLookupInfo.lookupValues[0].value
              : '',
            label: field.options?.radioGroup?.label,
            isRequired: field.options?.radioGroup?.required,
            className: clsx(classes.field, classes.radioGroup),
          })
      }
    }, [
      defaultValue,
      lookupFieldConfig,
      options,
      dataIsLoading,
      disabled,
      field.options?.textInput?.label,
      field.options?.textInput?.placeholder,
    ])

    return !configIsLoading ? (
      <FormikField name={fieldName}>{Field}</FormikField>
    ) : (
      <Loader className={classes.loader} />
    )
  },
)

export default GeneratedField
