// core
import { Typography } from '@material-ui/core'
import React, { FC } from 'react'
import Test from 'react-test-attributes'

import { Select } from '@/components/controls/Select/index'
import { useSetAccountForm } from '@/components/pages/common/SignInPage/components/SelectAccountForm/hooks'
// components
import { ButtonActionSignIn } from '@/components/pages/common/SignInPage/hooks/useSignInAction'
import { LogoLocalBlock } from '@/components/wrappers/SignInPage/components/component'
import { getTestId } from '@/utils'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'

const SelectAccountForm: FC = (): React.ReactElement => {
  const testId = getTestId('select-company-form')
  const translate = useTranslate()
  const classes = useClasses()
  const {
    account,
    handleChange,
    availableAccountsOptions,
    handleSetCompany,
    handleSignOut,
    setCompanyAndLogin,
  } = useSetAccountForm()

  return (
    <Test id={testId(0)}>
      <>
        {/* <LogoLocalBlock title="selectCompany" /> */}
        <div className={classes.chooseCompanyTitle}>
          <Typography variant="inherit">{translate(`translate#title.selectCompany`)}</Typography>
        </div>
        <Test id={testId(3)}>
          <div className={classes.selectCompanyWrapper}>
            <div className={classes.selectCompanyContainer}>
              {availableAccountsOptions?.map((acc: any, index: number) => {
                return (
                  <div
                    key={`select-company-${index}`}
                    className={classes.companyItem}
                    onClick={(e: any) => setCompanyAndLogin(acc.value)}
                  >
                    {acc.name}
                  </div>
                )
              })}
            </div>
          </div>
        </Test>
        {/* <Test id={testId(1)}>
          <Select
            hideEmptyItem
            value={account}
            onChange={handleChange}
            options={availableAccountsOptions}
          />
        </Test> */}
        <Test id={testId(2)}>
          <ButtonActionSignIn
            translate={{
              idCancel: 'action.back',
              messageCancel: 'Back',
              messageSubmit: 'Next',
              idSubmit: 'action.next',
            }}
            showButtonCancel
            showButtonSubmit={false}
            styleForButtons="center"
            onclickAction={handleSetCompany}
            onCliCkCancelAction={handleSignOut}
          />
        </Test>
      </>
    </Test>
  )
}

export default SelectAccountForm
