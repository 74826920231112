import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  createButton: {
    overflow: 'hidden',
    background: '#25ADBF',
  },
  SDcreateButton: {
    background: '#ffac5f',
    '&:hover': {
      background: '#eb8424',
    },
  },
  iconAnchor: {
    color: 'transparent',
  },
  icon: {
    color: 'white',
    margin: spacing(0.25, 0),
    marginLeft: spacing(0.5),
  },
  iconClosed: {
    marginLeft: 0,
  },
  text: {
    color: 'white',
    fontSize: '14px',
    margin: 0,
    marginLeft: spacing(1.5),
  },
  menuIcon: {
    color: palette.primary.main,
    height: spacing(2.5),
  },
  label: {
    // marginLeft: spacing(1),
    fontSize: spacing(1.75),
    paddingRight: spacing(1.75),
  },

  item: {
    padding: spacing(1, 2),
    paddingLeft: spacing(0),
  },

  // styles for IE11
  list: {
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    paddingRight: spacing(2),
    paddingLeft: spacing(2),
  },
  menuPaper: {
    display: 'flex',
    flexDirection: 'column',
  },
}))
