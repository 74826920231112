import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getCitBlnState = (state: AppState): fromReducer.State => state.cmCitBalancing

export const getCitBlnModalOpenState = createSelector(
  getCitBlnState,
  ({
    isFiltersModalOpen,
    isColumnModalOpen,
    isCashBalancingModalOpen,
    isEventsViewerModalOpen,
    isConfirmCloseModalOpen,
    isAccountsModalOpen,
    isReportsModalOpen,
    isAssetVaultModalOpen,
  }) => ({
    isFiltersModalOpen,
    isColumnModalOpen,
    isCashBalancingModalOpen,
    isEventsViewerModalOpen,
    isConfirmCloseModalOpen,
    isAccountsModalOpen,
    isReportsModalOpen,
    isAssetVaultModalOpen,
  }),
)

export const getCitBlnFilter = createSelector(getCitBlnState, state => state.cashPoints.filter)
export const getCitBlnCashPoints = createSelector(getCitBlnState, state => state.cashPoints)
export const getConfirmCloseStatuses = createSelector(getCitBlnState, state => state.confirmClose)
export const getCitBalCashPointsReports = createSelector(
  getCitBlnCashPoints,
  ({ reports, selectedCashPointReports }) => ({ reports, selectedCashPointReports }),
)
export const getCitBlnCashiers = createSelector(getCitBlnState, state => state.cashiers)

export const getCitBlnTransactionButtonStatus = createSelector(getCitBlnState, state => {
  const { selectedCashPoint, checkedCashPoints } = state.cashPoints
  const {
    data: transactionButtonStatus,
    isMixedOperation,
    isLoading,
  } = state.transactionButtonStatus

  const checkedKeys = Object.keys(checkedCashPoints)
  const { coid, vaultId } = selectedCashPoint || {}
  const { isDebitOperationAvailable, isRevertOperationAvailable } =
    transactionButtonStatus[coid || ''] || {}

  const isAssetAvailable = checkedKeys.length ? !isMixedOperation : isDebitOperationAvailable

  return {
    isLoading,
    isAssetAvailable,
    isRevertOperationAvailable: isRevertOperationAvailable && !!vaultId,
  }
})

export const getOperationStatus = createSelector(getCitBlnState, state => {
  const { isMixedOperation, operationName } = state.transactionButtonStatus

  return {
    isMixedOperation,
    operationName,
  }
})

export const getCitBlnVaultTransactionTotals = createSelector(
  getCitBlnState,
  state => state.vaultTransactionTotals,
)
