import { AdmLicenseInfo } from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration, LocalStorageItems } from '@/constants'
import { PopUpService } from '@/utils/services/popUpService'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

export class LicenseAdminApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async get(): Promise<AdmLicenseInfo> {
    try {
      return await LicenseAdminApi.requestService.get('/admin/license')
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async upload(file: File): Promise<void> {
    try {
      await LicenseAdminApi.requestService.postComplete('/admin/license/upload', file)
    } catch (error) {}
  }

  public static async export(): Promise<Blob | undefined> {
    try {
      const tokenCM = localStorage.getItem(LocalStorageItems.AccessTokenVaultV1) || ''

      const response = await fetch(
        `${APIConfiguration.VAULT_V1_API_BASE_URL}/admin/license/export`,
        {
          headers: { Authorization: `Bearer ${tokenCM}` },
          method: 'POST',
        },
      )

      if (response?.ok) {
        return await response.blob()
      } else {
        PopUpService.showGlobalPopUp('translate#error.CantExportLicense', 'error')
      }
    } catch (error) {
      PopUpService.showGlobalPopUp(JSON.parse((error as Error).message).message, 'error')
    }
  }
}
