export const CHART_IDS = {
  LineChartWithXAxisPading: 'lineChartWithXAxisPading',
  StackedAreaChart: 'stackedAreaChart',
  SimpleBarChart: 'simpleBarChart',
  LineBarAreaComposedChart: 'lineBarAreaComposedChart',
  CustomPieChart: 'customPieChart',
}

// tmp
export const CHARTS = {
  [CHART_IDS.LineChartWithXAxisPading]: {
    id: CHART_IDS.LineChartWithXAxisPading,
    name: 'Line chart with x axis pading',
  },
  [CHART_IDS.LineBarAreaComposedChart]: {
    id: CHART_IDS.LineBarAreaComposedChart,
    name: 'Line bar area composed chart',
  },
  [CHART_IDS.StackedAreaChart]: {
    id: CHART_IDS.StackedAreaChart,
    name: 'Stacked area chart',
  },
  [CHART_IDS.SimpleBarChart]: {
    id: CHART_IDS.SimpleBarChart,
    name: 'Simple bar chart',
  },
  [CHART_IDS.CustomPieChart]: {
    id: CHART_IDS.CustomPieChart,
    name: 'Pie chart',
  },
}
