import { Button, Grid, Paper, Typography } from '@material-ui/core'
import React, { FC, useState } from 'react'

import CloseButton from '@/components/blocks/CloseButton'
import { DraggableDialog } from '@/components/blocks/cm'
import { TextInput } from '@/components/controls/TextInput'
import { useTranslate } from '@/utils/internalization'

import { ActionButtons, useClasses } from './styles'
import { Props } from './types'

export const DeclineModal: FC<Props> = React.memo((props: Props) => {
  const { open, subTitle, handleClose, handleDecline } = props

  const translate = useTranslate()
  const classes = useClasses()

  const [comment, setComment] = useState('')

  const handleApply = (): void => {
    handleDecline(comment)
    handleClose()
  }

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setComment(e.target.value)
  }

  return (
    <DraggableDialog
      maxWidth={subTitle ? 'sm' : 'xs'}
      fullWidth
      open={open}
      keepMounted
      onClose={handleClose}
    >
      <CloseButton onClick={handleClose} absolute />
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography variant="h3" className={classes.modalHeaderTitle}>
            {translate('translate#cm.CashOrderDecliningReason')}
          </Typography>
          {subTitle && (
            <Typography variant="body1" className={classes.subTitle}>
              {subTitle}
            </Typography>
          )}
        </div>

        <Grid container>
          <Grid item xs={12}>
            <TextInput
              required
              autoFocus
              fullWidth
              label={translate('translate#cm.CashOrderDecliningReason')}
              rows={3}
              rowsMax={4}
              multiline
              value={comment}
              onChange={handleSelectChange}
            />
          </Grid>
          <ActionButtons>
            <Button className={classes.button} variant="outlined" onClick={handleClose}>
              {translate('translate#cm.ButtonClose')}
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              type="submit"
              disabled={!comment}
              onClick={handleApply}
            >
              {translate('translate#cm.ButtonApply')}
            </Button>
          </ActionButtons>
        </Grid>
      </Paper>
    </DraggableDialog>
  )
})
