import { LocalStorageItems } from '@/constants'

export const COLUMNS = [
  {
    fieldName: 'deviceName',
    fieldLabel: 'atmeye.columns.title.deviceName',
    sortField: 'deviceName',
  },

  {
    fieldName: 'eventTypeName',
    fieldLabel: 'atmeye.columns.title.eventTypeName',
    sortField: 'eventTypeName',
  },
  {
    fieldName: 'cameraNumber',
    fieldLabel: 'atmeye.columns.title.cameraNumber',
    sortField: 'cameraNumber',
  },

  {
    fieldName: 'atmDateTime',
    fieldLabel: 'atmeye.columns.title.atmDate',
    sortField: 'atmDateTime',
  },
  {
    fieldName: 'note',
    fieldLabel: 'atmeye.columns.title.note',
    sortField: 'note',
  },
  {
    fieldName: 'reactionDateTime',
    fieldLabel: 'atmeye.columns.title.reactionDateTime',
    sortField: 'reactionDateTime',
  },
  {
    fieldName: 'operator',
    fieldLabel: 'atmeye.columns.title.operator',
    sortField: 'operator',
  },
]

export type LsPageTableKeys = {
  lsKeyGrouping: LocalStorageItems
  lsKeyPagination: LocalStorageItems
  lsKeyColumns: LocalStorageItems
  lsKeyFilters: LocalStorageItems
  lsKeyFiltersTemplates: LocalStorageItems
  lsKeySorting: LocalStorageItems
}
