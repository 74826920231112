import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme, withStyles } from '@material-ui/core/styles'
import { styledComponentTheme } from '@/theme'
import TableRow from '@material-ui/core/TableRow'

interface StylesProps {
  nesting: number
}

export const useClasses = makeStyles<Theme, StylesProps>(({ spacing, palette }) => ({
  cell: {
    minWidth: spacing(25),
    height: spacing(5),
    minHeight: spacing(5),
    maxHeight: spacing(5),
    padding: spacing(0.5, 2),
    border: 'none',
    '&:first-child': {
      minWidth: spacing(40),
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  arrowIcon: {
    color: palette.grey[200],
  },
  folderIcon: {
    marginRight: spacing(1),
  },
  iconSize: {
    fontSize: styledComponentTheme.fontSizes.big,
  },
  loaderWrapper: {
    height: spacing(15),
    width: spacing(3),
    margin: 'auto',
  },
  tableContainer: {
    maxHeight: 400,
    direction: 'rtl',
    overflow: 'auto',
  },
  table: {
    direction: 'ltr',
  },
  headerCell: ({ nesting }: StylesProps) => ({
    '&:first-child': {
      paddingLeft: `calc(${spacing(2)}px * ${nesting})`,
    },
  }),
}))

export const StyledTableRow = withStyles(
  {
    head: {
      backgroundColor: '#F9F9F9',
    },
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: '#F9F9F9',
      },
      border: 'none',
    },
  },
  { name: 'TableRow' },
)(TableRow)
