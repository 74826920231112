import { LocalStorageItems } from '@/constants'
import { Notifications } from '@/constants/actions/settings'
import { notificationConfig } from '@/constants/atmeye/notificationConfig'
import { Notification, NotificationEventType, NotificationType } from '@/store/notifications/types'
import { ReturnAnyActionsUSM } from '@/store/usm/types/types'

import { actionsNotifications } from './actions'

export const quequeSize = 100

export const initialSettings: NotificationEventType = {
  delay: 5,
  displayNotification: true,
  sound: true,
}

const eventNotifications = JSON.parse(
  String(sessionStorage.getItem(LocalStorageItems.EventNotifications)),
) as NotificationType[]

const alarmNotifications = JSON.parse(
  String(sessionStorage.getItem(LocalStorageItems.AlarmNotifications)),
) as NotificationType[]

const initialState = {
  alarmsEventsCount: 0,
  notifications: [] as Notification[],
  eventNotifications: eventNotifications || ([] as NotificationType[]),
  alarmNotifications: alarmNotifications || ([] as NotificationType[]),
  blockAlarms: [] as number[],
  blockEvents: [] as number[],
  notificationSettings: {
    eventSettings:
      (JSON.parse(
        String(localStorage.getItem(LocalStorageItems.EventSettings)),
      ) as NotificationEventType) || initialSettings,
    alarmSettings:
      (JSON.parse(
        String(localStorage.getItem(LocalStorageItems.AlarmSettings)),
      ) as NotificationEventType) || initialSettings,
  },
}

export type State = typeof initialState

type SettingsAnyActions = ReturnAnyActionsUSM<typeof actionsNotifications>

export const reducer = (state: State = initialState, action: SettingsAnyActions): State => {
  switch (action.type) {
    case Notifications.EnqueueSnackbar: {
      const {
        eventId,
        eventName,
        deviceId,
        deviceName,
        eventDate,
        container,
        eventTypeId,
      } = action.payload

      const newNotification: Record<string, any> = {}

      const newNotificationBody: NotificationType = {
        eventId,
        eventName,
        deviceId,
        deviceName,
        eventDate,
        eventTypeId,
      }

      let notifications = [action.payload, ...state.notifications]

      if (container === notificationConfig.ALARM_EVENT) {
        newNotification.alarmNotifications = [newNotificationBody, ...state.alarmNotifications]
        if (newNotification.alarmNotifications.length > quequeSize) {
          const removedElem = newNotification.alarmNotifications.pop()
          notifications = notifications.filter(({ eventId }) => eventId !== removedElem.eventId)
        }
      } else {
        newNotification.eventNotifications = [newNotificationBody, ...state.eventNotifications]
        if (newNotification.eventNotifications.length > quequeSize) {
          const removedElem = newNotification.eventNotifications.pop()
          notifications = notifications.filter(({ eventId }) => eventId !== removedElem.eventId)
        }
      }

      return {
        ...state,
        ...newNotification,
        notifications,
        alarmsEventsCount:
          container === notificationConfig.ALARM_EVENT
            ? state.alarmsEventsCount + 1
            : state.alarmsEventsCount,
      }
    }

    case Notifications.RemoveSnackbar:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.eventId !== action.payload,
        ),
      }

    case Notifications.RemoveAllNotifications:
      return {
        ...state,
        notifications: [],
      }

    case Notifications.deleteEvents:
      return {
        ...state,
        eventNotifications: state.eventNotifications.filter(
          notification => !action.payload.eventIds.includes(notification.eventId),
        ),
      }

    case Notifications.deleteAlarms:
      return {
        ...state,
        alarmNotifications: state.alarmNotifications.filter(
          notification => !action.payload.eventIds.includes(notification.eventId),
        ),
      }

    case Notifications.closeSecurityAlarmAsync:
      return {
        ...state,
      }

    case Notifications.blockAlarms:
      return {
        ...state,
        blockAlarms: [...new Set([...state.blockAlarms, ...action.payload.eventIds])],
      }

    case Notifications.unblockAlarms:
      return {
        ...state,
        blockAlarms: state.blockAlarms.filter(item => !action.payload.eventIds.includes(item)),
      }

    case Notifications.closeTechnicalEventAsync:
      return {
        ...state,
      }

    case Notifications.blockEvents:
      return {
        ...state,
        blockEvents: [...new Set([...state.blockEvents, ...action.payload.eventIds])],
      }

    case Notifications.unblockEvents:
      return {
        ...state,
        blockEvents: state.blockEvents.filter(item => !action.payload.eventIds.includes(item)),
      }

    case Notifications.changeSettings: {
      const { settingType, eventType, data } = action.payload

      const newNotificationSettings = {
        ...state.notificationSettings,
        [settingType]: {
          ...state.notificationSettings[settingType],
          [eventType]: data,
        },
      }

      return {
        ...state,
        notificationSettings: newNotificationSettings,
      }
    }

    case Notifications.changeAllSettings:
      return {
        ...state,
        notificationSettings: action.payload,
      }

    case Notifications.FillAlarmsEventCount:
      return {
        ...state,
        alarmsEventsCount: action.payload.alarms.count,
      }

    case Notifications.ClearNotifications:
      return {
        ...state,
        eventNotifications: [],
        alarmNotifications: [],
      }

    case Notifications.DecrementAlarmsEventCount:
      return {
        ...state,
        alarmsEventsCount: state.alarmsEventsCount - 1,
      }
    case Notifications.FillInitialSettings:
      return {
        ...state,
        notificationSettings: {
          ...state.notificationSettings,
          eventSettings:
            (JSON.parse(
              String(localStorage.getItem(LocalStorageItems.EventSettings)),
            ) as NotificationEventType) || initialSettings,
          alarmSettings:
            (JSON.parse(
              String(localStorage.getItem(LocalStorageItems.AlarmSettings)),
            ) as NotificationEventType) || initialSettings,
        },
      }

    default:
      // eslint-disable-next-line no-case-declarations,@typescript-eslint/no-unused-vars
      const neverCase: never = action
  }
  return state
}
