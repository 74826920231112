import { FormikProps, FormikValues } from 'formik'
import { FormikConfig } from 'formik/dist/types'
import { ReactElement } from 'react'

import { TypesForSelect } from '@/components/controls/Select/types'

export enum FilterDisplayName {
  Text = 'TEXT',
  // List = 'LIST',
  MULTISELECT = 'MULTISELECT',
  Select = 'SELECT',
  Range = 'RANGE',
  // YesNo = 'YESNO',
  // YesNoAll = 'YESNOALL',
  Checkbox = 'CHECKBOX',
  // RadioGroup = 'RADIOGROUP',
  Date = 'DATE',
  Time = 'TIME',
  RangeTime = 'RANGE_TIME',
  // ZonedDate = 'ZONED_DATETIME',
}

export enum ValueTypes {
  // String = 'STRING',
  // Number = 'NUMBER',
  Datetime = 'DATETIME',
  // Boolean = 'BOOLEAN',
  Integer = 'INTEGER',
  Date = 'DATE',
  Time = 'TIME',
  // Checkbox = 'CHECKBOX',
  RangeTime = 'RANGE_TIME',
  ZonedDate = 'ZONED_DATETIME',
  Select = 'SELECT',
}

export enum DropDownEntityType {
  BLACK_LIST_CARD_ACTIONS = 'BLACK_LIST_CARD_ACTIONS',
  LICENSE_COMMON_STATUSES = 'LICENSE_COMMON_STATUSES',
  LICENSE_SERVICE_STATUSES = 'LICENSE_SERVICE_STATUSES',
  HEALTH_MONITOR_SERVICES_WITH_ERRORS = 'HEALTH_MONITOR_SERVICES_WITH_ERRORS',
  DEVICES_IDS_AND_NAMES = 'DEVICES_IDS_AND_NAMES',
  TRANSACTIONAL_EVENTS_TYPES = 'TRANSACTIONAL_EVENTS_TYPES',
  TECHNICAL_EVENTS_TYPES = 'TECHNICAL_EVENTS_TYPES',
  ALARM_EVENTS_TYPES = 'ALARM_EVENTS_TYPES',
  EVENTS_MEDIA_TYPES = 'EVENTS_MEDIA_TYPES',
  DEVICES_CORE_STATUSES = 'DEVICES_CORE_STATUSES',
  DEVICES_CONNECTIVITY_STATUSES = 'DEVICES_CONNECTIVITY_STATUSES',
  DEVICES_LICENSE_STATUSES = 'DEVICES_LICENSE_STATUSES',
  DEVICES_COUNTRIES = 'DEVICES_COUNTRIES',
  DEVICES_CITIES = 'DEVICES_CITIES',
  DEVICES_MODELS = 'DEVICES_MODELS',
  DEVICES_VENDORS = 'DEVICES_VENDORS',
  DEVICES_TYPES = 'DEVICES_TYPES',
}

type DefaultValue = {
  valueId: string
  isSelected: boolean
  valueLabels: Array<string>
}

export type TextInputOptions = {
  pattern?: RegExp
  disabledKeys?: Array<string>
  displayError?: boolean
  errorText?: string
}

export type MultiSelectOptions = {
  dropdownListEntityType: DropDownEntityType
  defaultValues?: DefaultValue
}

export type FieldTypes = {
  fullWidth?: boolean
  allowValidation?: boolean
  fieldType: FilterDisplayName
  disableFutureDates?: boolean
  valueType?: ValueTypes
  fieldName: string
  label: string
  placeholder: string
  required?: boolean
  disabled?: boolean
  type?: string
  options?: {
    textInput?: TextInputOptions
    multiselect?: MultiSelectOptions
    optionsSelect?: Array<TypesForSelect>
    hideEmptyItem?: boolean
  }
}

export type GenerateFormFieldsType = {
  field: FieldTypes
  disableFutureDates?: boolean
  isAtemeyFilterModal?: boolean
  formik: FormikProps<any>
  adminSettings?: Record<string, any> | null
}

export type FilterConfigField = {
  [key in FilterDisplayName]: (...arg: any) => ReactElement
}

export type DateType = {
  [key in ValueTypes]: (...arg: any) => ReactElement
}
