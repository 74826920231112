import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { Field, FieldProps, Form, FormikProps } from 'formik'
import Grid from '@material-ui/core/Grid'
import GeneratedField from '@/components/controls/GeneratedField'
import Container from '@material-ui/core/Container'
import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import {
  AddNewCompanyFormFields,
  AddNewCompanyFormValues,
} from '@/constants/forms/AddNewCompanyForm'
import DynamicField from '@/components/controls/DynamicField/component'
import WorkingHours from '@/components/controls/WorkingHours/component'
import { createSelectField } from '@/components/controls/Select/component'
import { createCheckboxGroupField } from '@/components/controls/CheckboxGroup/component'
import { COMPANIES_BLOCK_PREFIX } from '@/constants/paths'
// import { timezones } from '@/constants/timezones'
import { useClasses } from '../styles'

import { FieldTypeName, BackupOptions } from '@/components/controls/GeneratedField/types'
import {
  getUnits,
  isLookingForUnits,
  findUnitsRequest,
  setInitialServiceCompanies,
  getCompanyTypes,
} from '@/store/companies/drawerActions'
import { Props } from '../types'
import { createDropDownInputField } from '@/components/controls/DropDownInput/component'
import Button from '@/components/controls/Button'
import { useTranslate } from '@/utils/internalization'
import Test from 'react-test-attributes'
import { getTestId } from '@/utils/getTestId'
import { createTextInputField } from '@/components/controls/TextInput/component'
import _ from 'lodash'
import { isIE } from 'react-device-detect'

const AddNewCompanyFormStepWorkingHours = ({
  values,
  setFieldValue,
  ...props
}: FormikProps<AddNewCompanyFormValues>): any => {
  const propsi = { display: isIE ? 'block' : 'flex' }
  const classes = useClasses(propsi)
  const testId = getTestId('companies-add-new-company-form-step-3')
  const dispatch = useDispatch()
  const translate = useTranslate()

  const workingHours = values[AddNewCompanyFormFields.WorkingHours]

  // useEffect(() => {
  //   setFieldValue(AddNewCompanyFormFields.WorkingHours, workingHours)
  // }, [])

  return (
    <Test id={testId(0)}>
      <Grid className={classes.container} container>
        <Typography variant="h6" className={classes.title}>
          {translate('translate#title.workingHours')}
        </Typography>
        <Grid container spacing={2}>
          <Test id={testId(1)}>
            <Grid item md={12}>
              <WorkingHours
                formikFieldKey={AddNewCompanyFormFields.WorkingHours}
                data={workingHours}
                setFieldValue={setFieldValue}
              />
            </Grid>
          </Test>
        </Grid>
      </Grid>
    </Test>
  )
}

export { AddNewCompanyFormStepWorkingHours }
