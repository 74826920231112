import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { AnalysisApi } from '@/api/cm/analysisApi/index'
import { CMCommonApi } from '@/api/cm/commonApi'
import { CMActions } from '@/constants'
import { AppState } from '@/store/reducers'

import { setComCMError, setGlobalFilter } from '../common'
import {
  getAnalysisChartsRequest,
  getAnalysisChartsResponse,
  getAnalysisReportResponse,
  getAnalysisReportResponseFail,
  getAnalysisReportsResponse,
  getAnalysisReportsResponseFail,
  setAnalysisFilter,
} from './actions'

function* handleFilterSubmit({ payload }: AnyAction): SagaIterator {
  yield put(setGlobalFilter(payload.globalFilter))
  yield put(setAnalysisFilter(payload.analysisFilter))

  yield put(getAnalysisChartsRequest())
}

function* getReports(): SagaIterator {
  try {
    const {
      cmAnalysis: { filter },
      cmCommon: { globalFilter },
    } = yield select((state: AppState) => state)

    const totalFilter = {
      globalFilter: globalFilter,
      filter: filter,
    }

    const response = yield call(AnalysisApi.getReports, totalFilter)

    yield put(getAnalysisReportsResponse(response))
  } catch (e) {
    yield put(getAnalysisReportsResponseFail(e?.message))
  }
}

function* getCharts(): SagaIterator {
  try {
    const {
      cmAnalysis: { filter },
      cmCommon: { globalFilter },
    } = yield select((state: AppState) => state)

    const totalFilter = {
      globalFilter: globalFilter,
      filter: filter,
    }

    const response = yield call(AnalysisApi.getCharts, totalFilter)

    yield put(getAnalysisChartsResponse(response))
  } catch (e) {
    yield put(setComCMError(e?.message))
  }
}

function* getReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(CMCommonApi.getReport, payload)
      yield put(getAnalysisReportResponse(response))
    } else {
      yield put(getAnalysisReportResponse(null))
    }
  } catch (e) {
    yield put(getAnalysisReportResponseFail(e?.message))
  }
}

export default function*(): Generator {
  yield takeLatest(CMActions.AnalysisFilterSubmitRequest, handleFilterSubmit)
  yield takeLatest(CMActions.AnalysisGetReportsRequest, getReports)
  yield takeLatest(CMActions.AnalysisGetReportRequest, getReport)
  yield takeLatest(CMActions.AnalysisGetChartsRequest, getCharts)
}
