import makeStyles from '@material-ui/core/styles/makeStyles'
import SplitPane, { Pane } from 'react-split-pane'
import styled from 'styled-components'

import { styledComponentTheme } from '@/theme/styledComponentTheme'
type Props = {
  heightBlock?: string
}

export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #f9f9f9;
  overflow-y: hidden;
  outline: none;
  :focus {
    outline: none;
  }

  .Resizer {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    opacity: 0.2;
    z-index: 1;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;

    background: transparent;
  }

  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .Resizer.horizontal {
    height: 20px;
    min-height: 20px;
    cursor: row-resize;
    width: 100%;
  }
  .Resizer.vertical {
    width: 20px;
    min-width: 20px;
    cursor: col-resize;
  }

  .Resizer.vertical::before {
    content: '';
    position: absolute;
    top: calc(50% - 50px);
    left: 5px;
    width: 3px;
    height: 50px;
    background: #aeb8ba;
  }

  .Resizer.vertical::after {
    content: '';
    position: absolute;
    top: calc(50% - 50px);
    right: 5px;
    width: 3px;
    height: 50px;
    background: #aeb8ba;
  }

  .Pane.vertical.Pane2 {
    width: 0px;
  }
  .Pane.horizontal.Pane2 {
    height: 0px;
  }
`

export const StyledPane = styled(Pane)<Props>`
  height: ${props => (props.heightBlock ? props.heightBlock : '100%')};
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`

export const StyledSplitPane = styled(SplitPane)`
  position: initial !important;
`

export const useClasses = makeStyles(({ spacing }) => ({
  treeWrapper: {
    height: '100%',
    padding: spacing(2.5),
    overflowX: 'auto',
  },

  noTreeData: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '130px',
    fontStyle: 'italic',
    color: 'gray',
  },

  cell: {
    height: spacing(5),
    minHeight: spacing(5),
    maxHeight: spacing(5),
    minWidth: '150px',
    overflow: 'hidden',
    padding: 0,
    borderBottom: 0,
    whiteSpace: 'nowrap',
  },

  errorCell: {
    maxWidth: 'calc(100vw - 750px)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  root: {
    width: '100%',
    height: '100%',
    background: '#f8f8f8',
    outline: 'none',
  },

  selectedCell: {
    background: '#EAF7F9',
    boxShadow: 'inset 4px 0px 0px 0px #25ADBF',
  },

  tableWrapper: {
    boxShadow: 'none',
  },
  tableWrap: {
    maxHeight: 'calc(100vh - 386px)',
  },
  tableWrapFiltersSelected: {
    maxHeight: 'calc(100vh - 432px)',
  },
  table: {
    height: 'max-content',
    boxShadow: 'none',
    contain: 'content',
  },
  popoverPaper: {
    marginLeft: spacing(1),
    marginTop: spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
  },
  popoverButton: {
    justifyContent: 'flex-start',
    fontWeight: 'normal',
    padding: '6px 20px',
    borderBottom: '1px solid #ecf2f2',
  },
  tableHead: {
    background: '#f8f8f8',
    '& tr th': {
      '&:first-child': {
        paddingLeft: '20px',
      },
      fontWeight: 'bold',
      padding: '8px',
      border: '0',
      position: 'sticky',
      top: '0',
      left: 'auto',
      zIndex: 2,
      background: '#f8f8f8',
    },
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableCell: {
    border: '0',
    padding: '12px 8px',
    '&:first-child': {
      paddingLeft: '20px',
    },
  },
  tableTitle: {
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
    fontSize: '20px',
    marginBottom: '0',
    marginLeft: '24px',
  },
  tableHeaderWrapper: {
    padding: '20px',
    background: 'white',
  },
  tableHeaderFiltersWrapper: {
    padding: '0 20px 20px',
    marginTop: '-20px',
    background: 'white',
  },
  toolTip: {
    background: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    color: 'black',
    padding: spacing(1),
    fontSize: '14px',
    top: spacing(1),
  },
  tableHeaderButton: {
    border: '1px solid',
    borderColor: styledComponentTheme.colors.baseGreen,
    width: '40px',
    height: '40px',
    padding: '0',
    minWidth: 'auto',
    marginLeft: '16px',
    '& .MuiButton-startIcon': {
      margin: '0',
    },
  },
  flex: {
    display: 'flex',
  },
  cellWrapper: {
    padding: spacing(0.25, 1),
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: spacing(1.5),
    background: '#FDEFED',
  },
  cellIcon: {
    width: spacing(1.5),
    marginRight: spacing(1),
    color: '#E45B4A',
  },
  iconSize: {
    fontSize: styledComponentTheme.fontSizes.big,
  },
  statusOk: {
    color: '#1AD36F',
  },
  statusOkWrapper: {
    background: '#E6FAF0',
  },

  actionsTabWrap: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
    gap: spacing(4),
  },
  tableRoot: {
    maxHeight: 'calc(100vh - 220px)',
  },

  tabBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 24px',
    background: 'white',
    borderBottom: '1px solid #CAD5D7',
    boxShadow: '0px 0px 15px rgb(37 173 191 / 5%)',
    borderRadius: '3px',
  },
  tabsWrap: {
    minHeight: 'initial',
    overflow: 'visible',
    '& .MuiTabs-fixed': {
      overflow: 'visible !important',
    },
  },
  selectedTab: {
    color: styledComponentTheme.colors.baseGreen,
  },

  tabsWrapper: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
  },

  tab: {
    color: '#929A9B',
    textTransform: 'none',
    zIndex: 2,
    minWidth: 'auto',
    minHeight: '28px',
    paddingRight: '40px',
    paddingLeft: '40px',
  },

  indicator: {
    backgroundColor: styledComponentTheme.colors.baseGreen,
    height: '3px',
    borderRadius: '4px',
    zIndex: 1,
    bottom: '-2px',
  },

  button: {
    fontWeight: 'normal',
    border: '1px solid',
    marginRight: spacing(2.5),
    height: '40px',
    background: styledComponentTheme.colors.baseGreen,
    color: 'white',
    borderColor: styledComponentTheme.colors.baseGreen,
    '&:hover': {
      color: styledComponentTheme.colors.baseGreen,
    },
  },
}))
