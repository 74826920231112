import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromCMMonitoring from './reducer'

export const getCashPointsMonitoringState = (state: AppState): fromCMMonitoring.State =>
  state.cmMonitoring

export const getCashPointDetails = createSelector(
  getCashPointsMonitoringState,
  state => state.cashPointDetails,
)

export const getCashPoints = createSelector(getCashPointsMonitoringState, state => state.cashPoints)
export const getSelectedCashPoint = createSelector(getCashPoints, state => state.selectedCPoint)

export const getMonitoringFilter = createSelector(getCashPoints, state => state.monFilter)
export const getCashPointsSort = createSelector(getCashPoints, ({ sortColumn, sortOrder }) => ({
  sortColumn,
  sortOrder,
}))

export const getCashPointsPaging = createSelector(
  getCashPoints,
  ({ paging: { page, pageSize } }) => ({
    page,
    pageSize: pageSize === -1 ? 'All' : pageSize,
  }),
)

export const getCashPointCassettes = createSelector(
  getCashPointsMonitoringState,
  state => state.cashPointCassettes,
)

export const getCashPointCassetteChart = createSelector(
  getCashPointsMonitoringState,
  state => state.cassettesChart,
)

export const getCashPointCINCharts = createSelector(
  getCashPointsMonitoringState,
  state => state.cassetteCINData,
)
