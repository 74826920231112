import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Typography,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import MaterialSelect, { SelectProps } from '@material-ui/core/Select'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import { FieldProps } from 'formik'
import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import Loader from '@/components/blocks/Loader'
import { SelectIcon } from '@/components/blocks/SelectIcon'
import { generateErrorText } from '@/helpers/generateErrorText'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import { Props } from './types'

const headerItemStyles = { opacity: 1 } // override for prod

export const Select = ({
  label,
  options,
  placeholder,
  disabled,
  className,
  hideEmptyItem = false,
  emptyDefaultOption = false,
  isLoading = false,
  shrink = true,
  required = false,
  error,
  fontSize,
  displayError = true,
  errorText,
  clearButton,
  classes: selectClasses,
  parameterName,
  clearField,
  setFieldValue,
  neverDisable,
  withAutofillForSingleOption = true,
  IconComponent,
  withoutCheckerForSingleOption = false,
  onOpen,
  resetDependencies,
  ...props
}: SelectProps & Props): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const inputLabel = useRef() as RefObject<HTMLLabelElement>
  const [labelWidth, setLabelWidth] = useState(0)
  const [btnClearIsVisible, setBtnClearIsVisible] = useState<boolean>(false)
  const hasSingleOption = withoutCheckerForSingleOption ? false : options.length === 1

  const handleSetBtnClearVisible = useCallback(() => {
    if (props.value) setBtnClearIsVisible(true)
  }, [props.value])

  const handleSetBtnClearNotVisible = useCallback(() => {
    if (props.value) setBtnClearIsVisible(false)
  }, [props.value])

  useEffect(() => {
    if (props.value) setBtnClearIsVisible(true)
    if (!props.value) setBtnClearIsVisible(false)
  }, [props.value])

  useEffect(() => {
    if (inputLabel && inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth)
    }
  }, [inputLabel, isLoading])

  useEffect(() => {
    if (hasSingleOption && withAutofillForSingleOption) {
      if (props.name && setFieldValue) {
        if (resetDependencies && setFieldValue) {
          if (Array.isArray(resetDependencies)) {
            resetDependencies.map(item => {
              setFieldValue(item, null)
            })
          }
        }

        Promise.resolve().then(() => {
          if (props.name) {
            setFieldValue(props.name, options[0].value)
          }
        })
      }
    }
  }, [hasSingleOption, props.name, setFieldValue, options])

  const isDisabled = neverDisable ? false : disabled || hasSingleOption

  const isRequired = required && !hasSingleOption

  const handleOnOpen = () => {
    // if (onOpen && options.length <= 0) {
    if (onOpen) {
      onOpen(props.value)
    }
  }

  return (
    <>
      <FormControl
        error={error}
        size="small"
        required={isRequired}
        className={clsx(
          classes.select,
          {
            [classes.disabled]: isDisabled,
            [classes.inputWithError]: displayError && errorText,
          },
          className,
        )}
        variant="outlined"
        classes={{
          root: selectClasses?.rootFormControl,
        }}
      >
        {label && (
          <InputLabel
            classes={{ outlined: classes.label }}
            ref={inputLabel}
            shrink={shrink || undefined}
          >
            {isLoading ? (
              <Box display="flex" alignItems="center">
                {label}
                &nbsp;
                {isRequired && '*&nbsp;'}
                <Loader className={classes.loader} inline />
              </Box>
            ) : (
              label
            )}
          </InputLabel>
        )}

        <MaterialSelect
          className={
            placeholder && (props.value === '' || props.value === null)
              ? classes.placeholderOption
              : classes.normalOption
          }
          classes={{ root: selectClasses?.root, icon: selectClasses?.icon }}
          label={isLoading ? <span>{label}</span> : <span>{label}</span>}
          IconComponent={IconComponent || SelectIcon}
          input={shrink ? <OutlinedInput labelWidth={labelWidth} notched={shrink} /> : undefined}
          displayEmpty={shrink}
          MenuProps={{
            ...props.MenuProps,
            getContentAnchorEl: null,
          }}
          onFocus={handleOnOpen}
          // onOpen={handleOnOpen}
          onMouseOver={handleSetBtnClearVisible}
          onMouseOut={handleSetBtnClearNotVisible}
          {...props}
          value={props.value !== null ? props.value : ''}
        >
          {!emptyDefaultOption && (
            <MenuItem style={headerItemStyles} disabled>
              <Typography className={classes.headerText} variant="h6">
                {label} {isLoading && <CircularProgress color="inherit" size={20} />}
              </Typography>
            </MenuItem>
          )}
          {!hideEmptyItem && (
            <MenuItem value="" className={classes.placeholderOption}>
              {placeholder || translate('translate#title.none')}
            </MenuItem>
          )}
          {options.map(({ value, name, icon, disabled = false }) => (
            <MenuItem
              className={classes.normalOption}
              disabled={disabled}
              key={`${name}${value}`}
              value={value}
            >
              {icon ? (
                <div>
                  <ListItemIcon className={clsx(classes.optionIcon, selectClasses?.icon)}>
                    {icon}
                    <ListItemText primary={name} className={classes.optionName} />
                  </ListItemIcon>
                </div>
              ) : (
                <span className={classes.selectOption}>{name}</span>
              )}
            </MenuItem>
          ))}
        </MaterialSelect>
        {isRequired && displayError && !!errorText && (
          <div className={classes.errorLabel}>
            <FormattedMessage id={`form.validationWarning.${errorText}`} />
          </div>
        )}
      </FormControl>
      {clearButton && !hasSingleOption && (
        <IconButton
          size="small"
          onClick={clearField}
          className={clsx(classes.iconButton, { [classes.hidden]: !btnClearIsVisible })}
          onMouseOver={handleSetBtnClearVisible}
          onMouseOut={handleSetBtnClearNotVisible}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      )}
    </>
  )
}

export const createSelectField = (props: Props) => ({
  field,
  form: { errors, setFieldValue },
  meta: { touched },
}: FieldProps): React.ReactElement => {
  return (
    <Select
      {...field}
      errorText={generateErrorText(errors, field.name, touched)}
      error={!!errors[field.name] && touched}
      setFieldValue={setFieldValue}
      {...props}
    />
  )
}
