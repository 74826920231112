import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import MuiTableRow from '@material-ui/core/TableRow'
import ArrowRightIcon from '@material-ui/icons/ChevronRight'
import clsx from 'clsx'
import { Field, Formik, FormikValues } from 'formik'
import { FormikProps } from 'formik/dist/types'
import React, { MouseEvent, ReactElement, useCallback, useEffect, useRef, useState } from 'react'

import { createCheckboxField } from '@/components/controls/Checkbox'

import { DataItem, Header } from '../../types'
import EditableColumn from '../EditableColumn'
import { useClasses } from './styles'
import { Props } from './types'

const TableRow = ({
  headers: incomingHeaders,
  data,
  collapsible,
  extraColumns,
  checkboxColumnsNames,
  collapseOptions,
  allCollapsed,
  selectRowsOptions,
  deleteRowsOptions,
  changeRow,
  addNewRow,
  newRow,
  onChangeCountOfOpenedRows,
  onRowClick,
  disableAction,
}: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [headers, setHeaders] = useState<Header[]>([])

  const classes = useClasses()

  const handleOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      event.stopPropagation()

      if (allCollapsed !== undefined && onChangeCountOfOpenedRows) {
        onChangeCountOfOpenedRows(isOpen ? 'decrease' : 'increase')
      }

      setIsOpen(open => !open)
    },
    [allCollapsed, isOpen, onChangeCountOfOpenedRows],
  )

  const handleChange = useCallback(
    (values: FormikValues) => {
      if (changeRow) {
        if (Object.keys(values).some(key => values[key] !== data[key])) {
          return changeRow.onChangeRow(newRow)(values, true)
        }
        changeRow.onChangeRow(newRow)(data, false)
      }
    },
    [data, changeRow?.onChangeRow],
  )

  useEffect(() => {
    if (extraColumns) {
      return setHeaders([...extraColumns, ...incomingHeaders])
    }

    setHeaders(incomingHeaders)
  }, [incomingHeaders, extraColumns])

  useEffect(() => {
    if (collapsible && allCollapsed !== undefined) {
      setIsOpen(allCollapsed)
    }
  }, [allCollapsed])

  return (
    <Formik
      initialValues={data}
      onSubmit={(values: FormikValues) => console.log('values cheked', values)}
      enableReinitialize
    >
      {({ values, setFieldValue }) => (
        <>
          <MuiTableRow
            className={clsx(classes.colorize, {
              [classes.clickableRow]:
                onRowClick ||
                selectRowsOptions?.onSelectRow ||
                deleteRowsOptions?.onDeleteRow ||
                (data[collapseOptions?.childObjectName || 'children'] &&
                  data[collapseOptions?.childObjectName || 'children'].length),
              [classes.changedRow]: changeRow?.changedRows.find(item => item.id === data.id),
              [classes.deletedRow]: deleteRowsOptions?.deletedRows.includes(data),
              [classes.selectedRow]:
                selectRowsOptions?.selectedRows.includes(data) ||
                addNewRow?.newRows.includes(data) ||
                selectRowsOptions?.selectedRows.some(el => el.id && data.id === el.id),
            })}
            onClick={
              (onRowClick && onRowClick) ||
              (selectRowsOptions && selectRowsOptions.onSelectRow(data))
            }
          >
            {headers.map(({ name }) => {
              if (checkboxColumnsNames?.includes(name)) {
                return (
                  <TableCell key={name} className={classes.column}>
                    <Field name={name}>
                      {createCheckboxField({
                        checked: values[name],
                        disabled: disableAction,
                        onClick: event => event.stopPropagation(),
                        onChange: (event, checked) => {
                          setFieldValue(name, checked)
                          handleChange({ ...values, [name]: checked })
                        },
                        onClickLabel: event => {
                          event.stopPropagation()
                        },
                      })}
                    </Field>
                  </TableCell>
                )
              }

              return (
                <EditableColumn
                  key={name}
                  name={name}
                  values={values}
                  onChangeRow={handleChange}
                  iconKey={data.typeId}
                />
              )
            })}
          </MuiTableRow>
        </>
      )}
    </Formik>
  )
}

export default TableRow
