import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { AppConfigApi } from '@/api/cm/adminApi'
import { CMAppConfigActions } from '@/constants'

import {
  getApplicationConfigFail,
  getApplicationConfigRequest,
  getApplicationConfigResponse,
  updateApplicationConfigFail,
} from './actions'

function* getApplicationConfig(): SagaIterator {
  try {
    const appConfig = yield call(AppConfigApi.get)

    yield put(getApplicationConfigResponse(appConfig))
  } catch (error) {
    yield put(getApplicationConfigFail(error))
  }
}

function* updateApplicationConfig({ payload }: AnyAction): SagaIterator {
  try {
    yield call(AppConfigApi.update, payload)

    yield put(getApplicationConfigRequest())
  } catch (error) {
    yield put(updateApplicationConfigFail(error))
  }
}

export default function*(): Generator {
  yield takeLatest(CMAppConfigActions.AdmGetApplicationConfigRequest, getApplicationConfig)
  yield takeLatest(CMAppConfigActions.AdmUpdateApplicationConfigRequest, updateApplicationConfig)
}
