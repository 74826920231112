import { isEqual } from 'lodash-es'
import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, putResolve, select, takeLatest } from 'redux-saga/effects'

import { VaultV1CommonApi } from '@/api/vault-v1/commonApi'
import { EventJournalApi } from '@/api/vault-v1/eventJournalApi/index'
import { JournalEvent, JournalEventsResponse } from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants'
import { hotKeysBinder } from '@/utils/hotKeysBinder'

import { getGlobalFilterVaultV1, setComVaultV1Error, setGlobalFilter } from '../common'
import {
  getEjvCurrenciesResponse,
  getEjvEventTypesResponse,
  getEjvExecutorsResponse,
  getEjvVaultsResponse,
  getEventsFail,
  getEventsRequest,
  getEventsResponse,
  getEvjEvents,
  getEvjEventsFilter,
  getEvjEventsSort,
  getEvjFormattedEventsPaging,
  getEvjReportFail,
  getEvjReportResponse,
  getEvjReportsFail,
  getEvjReportsResponse,
  getEvjSelected,
  getMonEventsModals,
  openEvjDocumentsModal,
  openEvjFiltersModal,
  resetEvjEventsPaging,
  setEvjFilter,
  setEvjSelectedEvent,
} from '.'

function* getEvents(): SagaIterator {
  try {
    const globalFilter = yield select(getGlobalFilterVaultV1)
    const filter = yield select(getEvjEventsFilter)

    const paging = yield select(getEvjFormattedEventsPaging)
    const sort = yield select(getEvjEventsSort)

    const selectedEvent = yield select(getEvjSelected)

    const response: JournalEventsResponse = yield call(EventJournalApi.getAll, {
      paging,
      body: { globalFilter, filter },
      sort,
    })

    if (selectedEvent) {
      if (response.events.length) {
        const updatedSelectedEvent = response.events.find(
          (event: JournalEvent) => event?.eventId === selectedEvent?.eventId,
        )

        if (updatedSelectedEvent && !isEqual(updatedSelectedEvent, selectedEvent)) {
          yield putResolve(setEvjSelectedEvent(updatedSelectedEvent))
        }
      } else {
        yield putResolve(setEvjSelectedEvent(null))
      }
    } else {
      const [event] = response.events || []

      yield putResolve(setEvjSelectedEvent(event || null))
    }

    yield put(getEventsResponse(response))
  } catch (error) {
    yield put(getEventsFail(error))
  }
}

function* handleFilterSubmit({ payload }: AnyAction): SagaIterator {
  yield put(setGlobalFilter(payload.globalFilter))
  yield put(setEvjFilter(payload.filter))
  yield put(resetEvjEventsPaging())

  yield put(getEventsRequest())
}

function* getReports(): SagaIterator {
  try {
    const globalFilter = yield select(getGlobalFilterVaultV1)
    const filter = yield select(getEvjEventsFilter)

    const response = yield call(EventJournalApi.getReports, { globalFilter, filter })

    yield put(getEvjReportsResponse(response))
  } catch (error) {
    yield put(getEvjReportsFail(error?.message))
  }
}

function* getReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(VaultV1CommonApi.getReport, payload)
      yield put(getEvjReportResponse(response))
    } else {
      yield put(getEvjReportResponse(null))
    }
  } catch (error) {
    yield put(getEvjReportFail(error?.message))
  }
}

function* revertTransaction({ payload }: AnyAction): SagaIterator {
  try {
    if (payload.eventId) {
      yield call(EventJournalApi.revertTransaction, payload.eventId)
      yield put(getEventsRequest())
    }
  } catch (error) {
    yield put(setComVaultV1Error(error?.message))
  }
}

// vaults
function* getFilterVaults(): SagaIterator {
  try {
    const response = yield call(VaultV1CommonApi.getVaults)

    yield put(getEjvVaultsResponse(response))
  } catch (error) {
    yield put(setComVaultV1Error(error))
  }
}

// currencies
function* getFilterCurrencies(): SagaIterator {
  try {
    const response = yield call(EventJournalApi.getFilterCurrencies)

    yield put(getEjvCurrenciesResponse(response))
  } catch (error) {
    yield put(setComVaultV1Error(error))
  }
}

// eventTypes
function* getFilterEventTypes(): SagaIterator {
  try {
    const response = yield call(EventJournalApi.getFilterEventTypes)

    yield put(getEjvEventTypesResponse(response))
  } catch (error) {
    yield put(setComVaultV1Error(error))
  }
}

// executors
function* getFilterExecutors(): SagaIterator {
  try {
    const response = yield call(EventJournalApi.getFilterExecutors)

    yield put(getEjvExecutorsResponse(response))
  } catch (error) {
    yield put(setComVaultV1Error(error))
  }
}

function* handleKeyPress({ payload }: AnyAction): SagaIterator {
  try {
    const { selected, data } = yield select(getEvjEvents)
    const { isFiltersModalOpen, isDocumentModalOpen, isColumnModalOpen } = yield select(
      getMonEventsModals,
    )

    hotKeysBinder<JournalEvent>({
      key: payload,
      data,
      idName: 'eventId',
      selected,
      isOpenModalsList: [isFiltersModalOpen, isDocumentModalOpen, isColumnModalOpen],
      setSelectedAction: setEvjSelectedEvent,
      openFilterAction: openEvjFiltersModal,
      openPrintAction: openEvjDocumentsModal,
      onRefreshAction: getEventsRequest,
    })
  } catch (error) {
    yield put(setComVaultV1Error(error))
  }
}

export default function*(): Generator {
  yield takeLatest(
    [
      VaultV1Actions.EvjGetEventsRequest,
      VaultV1Actions.EvjSortEventsTable,
      VaultV1Actions.EvjSetEventsPaging,
    ],
    getEvents,
  )

  yield takeLatest(VaultV1Actions.EvjSubmitFilterRequest, handleFilterSubmit)

  yield takeLatest(VaultV1Actions.EvjGetReportsRequest, getReports)
  yield takeLatest(VaultV1Actions.EvjGetReportRequest, getReport)

  yield takeLatest(VaultV1Actions.EvjGetVaultsRequest, getFilterVaults)
  yield takeLatest(VaultV1Actions.EvjGetCurrenciesRequest, getFilterCurrencies)
  yield takeLatest(VaultV1Actions.EvjGetEventTypesRequest, getFilterEventTypes)
  yield takeLatest(VaultV1Actions.EvjGetExecutorsRequest, getFilterExecutors)

  yield takeLatest(VaultV1Actions.EvjRevertTransactionRequest, revertTransaction)

  yield takeLatest(VaultV1Actions.EvjHandleKeyPress, handleKeyPress)
}
