export * from './buildInfo'

export interface ComUsersProps {
  userRole?: 'Cashier' | 'Collector'
  filterByCompany?: boolean
}

export enum integrations {
  VaultVariant1 = 'VaultVariant1',
  VaultVariant2 = 'VaultVariant2',
  BagsIntegration = 'BagsIntegration',
}

export enum ButtonStatus {
  Invisible = 'Invisible',
  VisibleDisabled = 'VisibleDisabled',
  Visible = 'Visible',
}
