import TextField, { TextFieldProps } from '@material-ui/core/TextField/TextField'
import clsx from 'clsx'
import { FieldProps } from 'formik'
import React, { ChangeEvent, ReactElement, useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { generateErrorText } from '@/helpers/generateErrorText'
import { preventDefault } from '@/utils/functions'

import { useClasses } from './styles'
import { Field, Props } from './types'

// TODO Here
export const TextInput = ({
  type,
  displayError = true,
  errorText,
  error,
  margin,
  className,
  label,
  shrink = true,
  clearButton,
  fieldName,
  pattern,
  setFieldValue,
  disabledKeys,
  withoutFirstSpace,
  styles,
  maxSymbols,
  customClasses,
  placeholder,
  autoComplete = 'off',
  forbiddenCharactersRegExp,
  withoutNegative,
  ...props
}: TextFieldProps & Props): React.ReactElement => {
  const [valid, setValid] = useState<boolean>(true)

  const classes = useClasses()

  const handleTextField = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const { value } = e.target

      if (maxSymbols && value.length > maxSymbols) {
        preventDefault(e)
        return
      }

      if (withoutNegative && type === 'number' && Number(value) < 0) {
        preventDefault(e)
        return
      }

      if (forbiddenCharactersRegExp) {
        const regExp = new RegExp(forbiddenCharactersRegExp)

        if (regExp.test(value) && value !== '') {
          preventDefault(e)
          return
        }
      }

      if (withoutFirstSpace && value === ' ') {
        preventDefault(e)
        return
      }

      if (pattern) {
        if (!value.match(pattern)) {
          setValid(false)
        } else {
          setValid(true)
        }
      }

      if (setFieldValue && fieldName) {
        setFieldValue(fieldName, value)
      } else if (props.onChange) {
        props.onChange(e)
      }
    },
    [
      type,
      maxSymbols,
      withoutNegative,
      withoutFirstSpace,
      pattern,
      fieldName,
      setFieldValue,
      props.onChange,
      forbiddenCharactersRegExp,
    ],
  )

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      if (disabledKeys && disabledKeys.includes(e.key)) {
        preventDefault(e)
      }
    },
    [disabledKeys],
  )

  return (
    <>
      <TextField
        type={type}
        autoComplete={autoComplete}
        {...props}
        className={clsx(className, classes.input, {
          [classes.noMargin]: margin === 'none' && !label,
          [classes.noMarginWithLabel]: margin === 'none' && label,
          [classes.inputWithError]: displayError && errorText,
          [classes.hidden]: props.isHidden,
        })}
        label={
          label && props.disabled ? <div className={classes.labelDisabled}>{label}</div> : label
        }
        variant={'outlined' as Field<'outlined' | 'standard'>}
        error={error || !valid}
        InputLabelProps={{ shrink, className: classes.label }}
        InputProps={
          !props.InputProps
            ? { className: clsx(classes.fontInput, styles?.fontInput) }
            : props.InputProps
        }
        onChange={handleTextField}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
      />

      {displayError && !!errorText && !!error && (
        <div className={clsx(classes.errorLabel, customClasses?.errorText)}>
          <FormattedMessage id={`form.validationWarning.${errorText}`} />
        </div>
      )}
    </>
  )
}

export const createTextInputField = (props: TextFieldProps & Props) => ({
  field,
  form: { errors, setFieldValue },
  meta: { touched },
}: FieldProps): React.ReactElement => {
  return (
    <TextInput
      {...field}
      errorText={generateErrorText(
        errors,
        field.name,
        touched || (!!errors[field.name] && field.value),
      )}
      error={(!!errors[field.name] && field.value) || (!!errors[field.name] && touched)}
      shrink={props.shrink}
      fieldName={field.name}
      setFieldValue={setFieldValue}
      {...props}
    />
  )
}
