import { CashStatus, MapCashPoint, ServiceState } from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants'
import { DetailedInformationRecord } from '@/types/cm/maps'
import { AnyAction } from 'redux'

export type State = {
  isMapFiltersModalOpen: boolean
  isMapDetailedInformationPanelOpen: boolean
  cashPoints: {
    total: number
    isLoading: boolean
    isLoaded: boolean
    data: MapCashPoint[]
    error: string | null
    filter: {
      daysUntilEmpty: number
      objectServiceState?: ServiceState
      objectCashStatus?: CashStatus
    }
  }
  selectedCashPoint: {
    id: string
    data: DetailedInformationRecord[] | null
    isLoading: boolean
    isLoaded: boolean
  }
}

const initialState: State = {
  isMapFiltersModalOpen: false,
  isMapDetailedInformationPanelOpen: false,
  cashPoints: {
    total: 0,
    isLoading: false,
    isLoaded: false,
    data: [],
    error: null,
    filter: {
      daysUntilEmpty: 0,
      objectServiceState: undefined,
      objectCashStatus: undefined,
    },
  },
  selectedCashPoint: {
    id: '',
    data: null,
    isLoading: false,
    isLoaded: false,
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case CMActions.MapCashPointsRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: true,
        },
      }
    case CMActions.MapCashPointsResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: false,
          isLoaded: true,
          data: payload.data,
          total: payload.total,
        },
      }
    case CMActions.MapCashPointsFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: false,
          error: payload,
        },
      }
    case CMActions.MapHandleFilterModal:
      return {
        ...state,
        isMapFiltersModalOpen: payload,
      }
    case CMActions.MapHandleDetailedInformationPanel:
      return {
        ...state,
        isMapDetailedInformationPanelOpen: payload,
      }

    case CMActions.MapSetCashPointsFilter:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          filter: payload,
        },
      }
    case CMActions.MapGetSelectedCashPointInformationRequest:
      return {
        ...state,
        selectedCashPoint: {
          ...state.selectedCashPoint,
          isLoading: true,
        },
      }
    case CMActions.MapGetSelectedCashPointInformationRespose:
      return {
        ...state,
        selectedCashPoint: {
          ...state.selectedCashPoint,
          id: payload.selectedId,
          isLoading: false,
          isLoaded: true,
          data: payload.data,
        },
      }
    case CMActions.MapGetSelectedCashPointInformationFail:
      return {
        ...state,
        selectedCashPoint: initialState.selectedCashPoint,
      }
    case CMActions.MapSetInitialDetailedInformation:
      return {
        ...state,
        selectedCashPoint: initialState.selectedCashPoint,
        isMapDetailedInformationPanelOpen: false,
      }
    case CMActions.SignOut:
      return initialState
    default:
      return state
  }
}
