import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Typography,
} from '@material-ui/core'
import MaterialSelect from '@material-ui/core/Select'
import clsx from 'clsx'
import { FieldProps } from 'formik'
import { debounce } from 'lodash-es'
import React, {
  ChangeEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { isIE } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'

import Loader from '@/components/blocks/Loader'
import { SelectIcon } from '@/components/blocks/SelectIcon'
import Button from '@/components/controls/Button'
import { CallType } from '@/components/controls/LookupsMultiSelect/types'
import SearchInput from '@/components/controls/SearchInput/component'
import { preventDefault } from '@/utils/functions'
import { useScroll } from '@/utils/hooks/useScroll'
import { useTranslate } from '@/utils/internalization'

import ListElement from './components/ListElement'
import { useClasses } from './styles'
import { Props } from './types'

const headerItemStyles = { opacity: 1 } // override for prod

export const SELECT_ALL_MENU_ITEM_VALUE = 'all'

export const MultipleSelect = ({
  isLoading = false,
  required = false,
  disabled,
  shrink = false,
  error,
  SearchInputBlock,
  autoDropDownWidth = false,

  totalPages,
  label,
  placeholder,
  parameterName,

  styles,
  className,
  options,
  value: selectedValues,

  withoutInnerState = false,

  setFieldValue,
  onChangeMultipleSelect,
  onCall,
  onOpen,

  ...props
}: Props): React.ReactElement => {
  const inputLabel = useRef() as RefObject<HTMLLabelElement>
  const input = useRef() as RefObject<HTMLInputElement>
  const [labelWidth, setLabelWidth] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState<string[]>(
    (selectedValues as string[]) ?? ([] as string[]),
  )
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined)

  const [debouncedSearchValue, setSearch] = useState<string | undefined>(undefined)
  const debouncedSearch = useCallback(debounce(setSearch, 500), [setSearch])

  const [filteredOptions, setFilteredOptions] = useState<Array<{ name: string; value: string }>>(
    options,
  )

  const [loadSearch, setLoadSearch] = useState(false)

  useEffect(() => {
    if (Array.isArray(selectedValues) && withoutInnerState) {
      setSelected(selectedValues)
    }
  }, [selectedValues])

  const handleClearSearchValue = useCallback(() => {
    setSearchValue(undefined)
  }, [])

  const search = useCallback(
    async ({ value, pageNumber, pageSize }: CallType) => {
      try {
        if (onCall) {
          setLoadSearch(true)

          await onCall({
            value,
            pageNumber,
            pageSize,
          })
        }
      } catch (e) {
        throw new Error(e)
      } finally {
        setLoadSearch(false)
      }
    },
    [onCall],
  )

  const [parentRef, setParentRef] = useState(null)
  const [childRef, setChildRef] = useState(null)

  const handleSetParentRef = useCallback(element => {
    setParentRef(element)
  }, [])

  const handleSetChildRef = useCallback(element => {
    setChildRef(element)
  }, [])

  useScroll({
    parentRef: parentRef,
    childRef: childRef,
    loading: loadSearch,
    listLength: filteredOptions.length,
    limitSize: 100,
    pageLimit: totalPages,
    callback: (page, limit) => {
      search({ value: debouncedSearchValue, pageSize: limit, pageNumber: page })
    },
  })

  useEffect(() => {
    if (debouncedSearchValue !== undefined) {
      search({ value: debouncedSearchValue })
    }
  }, [debouncedSearchValue, search])

  useEffect(() => {
    debouncedSearch(searchValue)
  }, [debouncedSearch, searchValue])

  const propsStyle = {
    overflow: isIE ? 'scroll' : 'hidden',
    fieldWidth: autoDropDownWidth ? undefined : input.current?.offsetWidth,
  }

  const translate = useTranslate()
  const classes = useClasses(propsStyle)

  useEffect(() => {
    if (inputLabel && inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth)
    }
  }, [inputLabel])

  useEffect(() => {
    if (searchValue) {
      return setFilteredOptions(
        options.filter(option => option.name.toLowerCase().includes(searchValue.toLowerCase())),
      )
    }

    return setFilteredOptions(options)
  }, [searchValue, options])

  const handleClose = useCallback(
    (event): void => {
      event.stopPropagation()

      if (Array.isArray(selectedValues)) {
        setSelected(selectedValues)
      }

      handleClearSearchValue()
      setIsOpen(false)
    },
    [selectedValues, handleClearSearchValue],
  )

  const handleOpen = useCallback((): void => {
    setIsOpen(true)

    onOpen && onOpen()
  }, [onOpen])

  const isAllItemsSelected = useMemo(() => options.length === selected.length, [selected, options])

  const handleClickMenuItem = useCallback(
    (value: string | number): (() => void) => (): void => {
      if (value === SELECT_ALL_MENU_ITEM_VALUE) {
        setSelected(isAllItemsSelected ? [] : [...options.map(o => o.value)])

        return
      }

      setSelected(item =>
        item.includes(`${value}`) ? item.filter(val => val !== `${value}`) : [...item, `${value}`],
      )
    },
    [options, isAllItemsSelected],
  )

  const renderListElementName = useCallback(
    (name: string): any => {
      if (label === 'City') {
        const [city = '', country = ''] = name.split(', ')
        const delimiter = country ? ',' : ''

        return (
          <>
            {`${city}${delimiter}`}
            &nbsp;
            <Typography className={classes.additionalOption}>{country}</Typography>
          </>
        )
      }

      return name
    },
    [label, classes],
  )

  const handleSearch = useCallback((event: ChangeEvent<{ value: any }>) => {
    return setSearchValue(event.target.value)
  }, [])

  const handleApply = useCallback(
    (event): void => {
      event.stopPropagation()

      if (Array.isArray(selected)) {
        if (onChangeMultipleSelect) {
          onChangeMultipleSelect(selected, options)
        } else if (setFieldValue && parameterName) {
          setFieldValue(parameterName, selected)
        }

        handleClearSearchValue()
        setIsOpen(false)
      }
    },
    [
      selected,
      onChangeMultipleSelect,
      setFieldValue,
      parameterName,
      handleClearSearchValue,
      options,
    ],
  )

  const handleRenderSelectValue = useCallback(
    (value: any): React.ReactNode => {
      if (value.length) {
        const titles = value.reduce((acc: Array<string>, cur: string) => {
          const lookupValue = options.find(item => item.value === cur)

          if (label === 'City') {
            const city = lookupValue?.name.split(', ')[0]

            if (city) {
              acc.push(city)
            }
          } else if (lookupValue && lookupValue.name) {
            acc.push(lookupValue.name)
          }

          return acc
        }, [])

        return titles.length > 0 ? titles.join(', ') : ''
      } else {
        return placeholder || translate('translate#title.multi.select.placeholder')
      }
    },
    [options, label, placeholder, translate],
  )
  return (
    <>
      <FormControl
        error={error}
        size="small"
        required={required}
        className={clsx(
          classes.select,
          {
            [classes.disabled]: disabled,
          },
          className,
        )}
        variant="outlined"
      >
        {label && (
          <InputLabel ref={inputLabel} shrink={shrink || undefined}>
            {isLoading ? (
              <Box display="flex" alignItems="center">
                {label}
                &nbsp;
                {required && '*&nbsp;'}
                <Loader className={classes.loader} inline />
              </Box>
            ) : (
              label
            )}
          </InputLabel>
        )}
        <MaterialSelect
          {...props}
          ref={input}
          className={
            placeholder && selected && selected.length === 0
              ? classes.placeholderOption
              : classes.normalOption
          }
          label={isLoading ? <span>{label}</span> : label}
          IconComponent={SelectIcon}
          input={shrink ? <OutlinedInput labelWidth={labelWidth} notched={shrink} /> : undefined}
          displayEmpty={shrink}
          multiple
          value={Array.isArray(selected) ? selected : []}
          open={isOpen}
          onOpen={handleOpen}
          onClose={handleClose}
          disabled={disabled}
          onChange={preventDefault}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          }}
          renderValue={handleRenderSelectValue}
          classes={{ root: classes.root }}
        >
          <MenuItem style={headerItemStyles} disabled>
            <Typography className={classes.headerText} variant="h6">
              {label}
            </Typography>
          </MenuItem>
          {SearchInputBlock && (
            <SearchInput indentation onChange={handleSearch} val={searchValue} />
          )}
          <div
            className={clsx(classes.normalOption, classes.blockSelectAllWrapper)}
            key={`${SELECT_ALL_MENU_ITEM_VALUE}2`}
            onClick={handleClickMenuItem(SELECT_ALL_MENU_ITEM_VALUE)}
          >
            <Checkbox className={styles?.checkbox} checked={isAllItemsSelected} />
            <FormattedMessage id="title.selectAll" defaultMessage="Select All" />
          </div>
          <Divider />
          <div className={clsx(classes.menuList, styles?.list)} ref={handleSetParentRef}>
            {filteredOptions && filteredOptions.length ? (
              filteredOptions.map(({ value, name }, index) => {
                const isLastElement = index === filteredOptions.length - 1
                const innerRef = isLastElement ? handleSetChildRef : null

                return (
                  <ListElement
                    key={`${name}${value}`}
                    innerRef={innerRef}
                    classes={classes}
                    name={renderListElementName(name)}
                    value={value}
                    handleClickMenuItem={handleClickMenuItem(value)}
                    styles={styles}
                    selected={selected}
                  />
                )
              })
            ) : (
              <Typography variant="body1" className={classes.noOptions}>
                {translate('translate#title.noOptions')}
              </Typography>
            )}
            {loadSearch && (
              <Box style={{ height: '40px' }}>
                <Loader />
              </Box>
            )}
          </div>
          <Divider />
          <div className={classes.buttonWrapper}>
            <Button
              className={clsx(classes.button, classes.cancelButton)}
              variant="contained"
              size="medium"
              onClick={handleClose}
            >
              <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              size="medium"
              onClick={handleApply}
            >
              <FormattedMessage id="action.ok" defaultMessage="Ok" />
            </Button>
          </div>
        </MaterialSelect>
      </FormControl>
    </>
  )
}

export const createMultipleSelectField = (props: Props) => ({
  field,
  form: { errors, setFieldValue },
  meta: { touched },
}: FieldProps): React.ReactElement => {
  return (
    <MultipleSelect
      {...field}
      error={!!errors[field.name] && touched}
      setFieldValue={setFieldValue}
      {...props}
    />
  )
}
