import { AnyAction } from 'redux'

import {
  BalCassetteModifyRequest,
  BalCassetteRemoveRequest,
  BalGetBalancingCassettesResponse,
  BalGetBalancingInfoResponse,
  BalTotalsResponse,
  BalUnloadedCassettesSession,
  BS2CashPointEvent,
  CitBalanceReportGeneratorEntry,
  CitBalCashPoint,
  CitBalCashPointGetAccountsResponse,
  CitBalCashPointsResponse,
  CitBalTotalRemaindersResponse,
  ComCMReport,
  ComGlobalFilter,
  ComTotals,
  ComUuidValue,
  IcsCashPointCassette,
  IcsEventBase,
  VaultRemainderUpdateAvailabilityEntry,
} from '@/api/cm/swaggerGeneratedApi'
import { FormFieldValues } from '@/components/pages/cm/CitBalancingPage/components/modals/FiltersModal/types'
import { CMActions } from '@/constants/actions/cmActions'

export const setCitBlnFiltersModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.CitBalSetFiltersModalOpen,
  payload: isOpen,
})
export const setCitBlnEventsViewModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.CitBalSetEventsViewModalOpen,
  payload: isOpen,
})
export const setCitBlnAccountsModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.CitBalSetAccountsModalOpen,
  payload: isOpen,
})
export const setCitBlnReportsModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.CitBalSetReportsModalOpen,
  payload: isOpen,
})

export const setCitBlnConfirmModalOpen = ({
  isOpen,
  typeOfConfirm,
}: {
  isOpen: boolean
  typeOfConfirm: 'Closed_NotCompleted' | 'Closed_NoAgentData' | null
}): AnyAction => ({
  type: CMActions.CitBalSetConfirmModalOpen,
  payload: { isOpen, typeOfConfirm },
})

export const submitCitBalCashPointsFilter = ({
  globalFilter,
  citBlnFilter,
}: {
  globalFilter: ComGlobalFilter
  citBlnFilter: FormFieldValues
}): AnyAction => ({
  type: CMActions.CitBalFilterSubmitRequest,
  payload: { globalFilter, citBlnFilter },
})
export const setCitBalCashPointsFilter = (citBlnFilter: FormFieldValues): AnyAction => ({
  type: CMActions.CitBalSetCashPointsFilter,
  payload: citBlnFilter,
})

export const getCitBalCashPointsRequest = (): AnyAction => ({
  type: CMActions.CitBalGetCashPointsRequest,
})

export const getCitBalCashPointsResponse = (payload: CitBalCashPointsResponse): AnyAction => ({
  type: CMActions.CitBalGetCashPointsResponse,
  payload,
})

export const getCitBalCashPointsFail = (error: string): AnyAction => ({
  type: CMActions.CitBalGetCashPointsFail,
  payload: error,
})

export const resetCitBalCashPointsPage = (): AnyAction => ({
  type: CMActions.CitBalResetCashPointsPage,
})

export const setCitBalSelectedCashPoint = (cashPoint: CitBalCashPoint | null): AnyAction => ({
  type: CMActions.CitBalSetSelectedCashPoint,
  payload: cashPoint,
})

export const clearCitBalAllCashPoints = (): AnyAction => ({
  type: CMActions.CitBalClearAllCashPoints,
})

export const setCitBalCashPointsPaging = (payload: {
  page?: number
  pageSize?: number
}): AnyAction => ({
  type: CMActions.CitBalSetCashPointsPaging,
  payload,
})

export const toggleCitBalCashPoint = (cashPoint: CitBalCashPoint): AnyAction => ({
  type: CMActions.CitBalToggleCashPoint,
  payload: cashPoint,
})

export const checkCitBalAllCashPoints = (cashPoints: CitBalCashPoint[]): AnyAction => ({
  type: CMActions.CitBalCheckAllCashPoints,
  payload: cashPoints,
})

export const refreshCitBalCashPoints = (): AnyAction => ({
  type: CMActions.CitBalRefreshCashPoints,
})

export const openColumnsSettingsModal = (isOpen: boolean): AnyAction => ({
  type: CMActions.CitBalOpenColumnsSettingsModal,
  payload: isOpen,
})

export const sortCitBalTable = (payload: {
  sortColumn: string | undefined
  sortOrder: 'asc' | 'desc' | undefined
}): AnyAction => ({
  type: CMActions.CitBalSortTable,
  payload,
})

export const setCitBalCashBalancingModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.CitBalSetCashBalancingModalOpen,
  payload: isOpen,
})

export const setAssetVaultModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.CitBalSetAssetVaultModalOpen,
  payload: isOpen,
})

// -------------------------------------------------------

export const getCitBalInfoRequest = (id: string): AnyAction => ({
  type: CMActions.CitBalGetInfoRequest,
  payload: id,
})

export const getCitBalInfoResponse = (payload: BalGetBalancingInfoResponse): AnyAction => ({
  type: CMActions.CitBalGetInfoResponse,
  payload,
})

export const getCitBalInfoFail = (error: string): AnyAction => ({
  type: CMActions.CitBalGetInfoFail,
  payload: error,
})

export const getCitBalCassettesRequest = (id: string): AnyAction => ({
  type: CMActions.CitBalGetCassettesRequest,
  payload: id,
})

export const getCitBalCassettesResponse = (
  payload: BalGetBalancingCassettesResponse,
): AnyAction => ({
  type: CMActions.CitBalGetCassettesResponse,
  payload,
})

export const getCitBalCassettesFail = (error: string): AnyAction => ({
  type: CMActions.CitBalGetCassettesFail,
  payload: error,
})

export const applyCitBalUnloadedCassettesRequest = (): AnyAction => ({
  type: CMActions.CitBalApplyBalUnloadedCassettesRequest,
})
export const applyCitBalUnloadedCassettesResponse = (): AnyAction => ({
  type: CMActions.CitBalApplyBalUnloadedCassettesResponse,
})

export const setCitBalSelectedUnloadedCassettes = (
  unloadedCassettes: BalUnloadedCassettesSession,
): AnyAction => ({
  type: CMActions.CitBalSetSelectedUnloadedCassettes,
  payload: unloadedCassettes,
})

export const calcCitBalUnloadedTotalsResponse = (totals: BalTotalsResponse): AnyAction => ({
  type: CMActions.CitBalCalcUnloadedTotalsResponse,
  payload: totals,
})

export const getCitBalEventsRequest = (payload: {
  id: string
  dateFrom: string
  cashPointId: string
}): AnyAction => ({
  type: CMActions.CitBalGetEventsRequest,
  payload,
})

export const getCitBalEventsFail = (error: string): AnyAction => ({
  type: CMActions.CitBalGetEventsFail,
  payload: error,
})

export const applyCitBalEventRequest = (selectedEvent: IcsEventBase): AnyAction => ({
  type: CMActions.CitBalApplyEventRequest,
  payload: selectedEvent,
})
export const applyCitBalEventResponse = (): AnyAction => ({
  type: CMActions.CitBalApplyEventResponse,
})

export const applyCitBalEventFail = (): AnyAction => ({
  type: CMActions.CitBalApplyEventFail,
})

export const closeCitBalCashOrderRequest = (): AnyAction => ({
  type: CMActions.CitBalCloseCashOrderRequest,
})
export const closeCitBalCashOrderResponse = (): AnyAction => ({
  type: CMActions.CitBalCloseCashOrderResponse,
})

export const closeCitBalCashOrderFail = (): AnyAction => ({
  type: CMActions.CitBalCloseCashOrderFail,
})

export const getCitBalAccountsRequest = (): AnyAction => ({
  type: CMActions.CitBalGetAccountsRequest,
})
export const getCitBalAccountsResponse = (
  response: CitBalCashPointGetAccountsResponse,
): AnyAction => ({
  type: CMActions.CitBalGetAccountsResponse,
  payload: response,
})
export const getCitBalAccountsFail = (error: string): AnyAction => ({
  type: CMActions.CitBalGetAccountsFail,
  payload: error,
})

export const updateCitBalAccountsRequest = (): AnyAction => ({
  type: CMActions.CitBalUpdateAccountsRequest,
})
export const updateCitBalAccountsFail = (error: string): AnyAction => ({
  type: CMActions.CitBalUpdateAccountsFail,
  payload: error,
})

export const getVaultTransactionTotalsRequest = (): AnyAction => ({
  type: CMActions.CitBalGetVaultTransactionTotalsRequest,
})
export const getVaultTransactionTotalsResponse = (payload: {
  data: CitBalTotalRemaindersResponse
  ids: string[]
}): AnyAction => ({
  type: CMActions.CitBalGetVaultTransactionTotalsResponse,
  payload,
})
export const getVaultTransactionTotalsFail = (error: string): AnyAction => ({
  type: CMActions.CitBalGetVaultTransactionTotalsFail,
  payload: error,
})

export const setVaultTransactionRequest = (): AnyAction => ({
  type: CMActions.CitBalSetVaultTransactionRequest,
})

export const revertTransactionRequest = (): AnyAction => ({
  type: CMActions.CitBalRevertTransactionRequest,
})

// Reports -----------------------------

export const getCitBalReportsRequest = (): AnyAction => ({
  type: CMActions.CitBalGetReportsRequest,
})
export const getCitBalReportsResponse = (payload: CitBalanceReportGeneratorEntry): AnyAction => ({
  type: CMActions.CitBalGetReportsResponse,
  payload,
})
export const getCitBalReportsFail = (message?: string): AnyAction => ({
  type: CMActions.CitBalGetReportsFail,
  payload: message,
})

export const getCitBalReportRequest = (payload?: ComCMReport): AnyAction => ({
  type: CMActions.CitBalGetReportRequest,
  payload,
})
export const getCitBalReportResponse = (payload: any): AnyAction => ({
  type: CMActions.CitBalGetReportResponse,
  payload,
})
export const getCitBalReportFail = (message?: string): AnyAction => ({
  type: CMActions.CitBalGetReportFail,
  payload: message,
})

export const setInitialCitBalancingReports = (): AnyAction => ({
  type: CMActions.CitBalSetInitialReports,
})

export const getCitBalDialogReportsRequest = (): AnyAction => ({
  type: CMActions.CitBalGetDialogReportsRequest,
})
export const getCitBalDialogReportsResponse = (payload: ComCMReport[]): AnyAction => ({
  type: CMActions.CitBalGetDialogReportsResponse,
  payload,
})
export const getCitBalDialogReportsFail = (message?: string): AnyAction => ({
  type: CMActions.CitBalGetDialogReportsFail,
  payload: message,
})

export const getCitBalDialogReportRequest = (payload?: ComCMReport): AnyAction => ({
  type: CMActions.CitBalGetDialogReportRequest,
  payload,
})

export const getCitBalDialogReportResponse = (payload: any): AnyAction => ({
  type: CMActions.CitBalGetDialogReportResponse,
  payload,
})

export const getCitBalDialogReportFail = (message?: string): AnyAction => ({
  type: CMActions.CitBalGetDialogReportFail,
  payload: message,
})

export const fetchCitBalAssetItemReportRequest = (isZip: boolean): AnyAction => ({
  type: CMActions.CitBalFetchAssetItemReportRequest,
  payload: isZip,
})
export const fetchCitBalAssetItemReportResponse = (): AnyAction => ({
  type: CMActions.CitBalFetchAssetItemReportResponse,
})
export const fetchCitBalAssetItemReportFail = (): AnyAction => ({
  type: CMActions.CitBalFetchAssetItemReportFail,
})

export const getCitBlnTransactionButtonStatusRequest = (): AnyAction => ({
  type: CMActions.CitBlnTransactionButtonStatusRequest,
})
export const getCitBlnTransactionButtonStatusResponse = (
  payload: VaultRemainderUpdateAvailabilityEntry[],
): AnyAction => ({
  type: CMActions.CitBlnTransactionButtonStatusResponse,
  payload,
})
export const getCitBlnTransactionButtonStatusFail = (error: string): AnyAction => ({
  type: CMActions.CitBlnTransactionButtonStatusFail,
  payload: error,
})

export const setMixedOperation = (payload: {
  isMixedOperation: boolean
  operationName: string | undefined
}): AnyAction => ({
  type: CMActions.CitBlnSetMixedOperation,
  payload,
})

export const setCitBalIsZip = (isZip: boolean): AnyAction => ({
  type: CMActions.CitBalSetIsZip,
  payload: isZip,
})

export const getCitBalAddEventsRequest = (payload: {
  orderId: string
  eventType: string
}): AnyAction => ({
  type: CMActions.CitBalGetAddEventsRequest,
  payload,
})
export const getCiBalAddEventsResponse = (payload: IcsCashPointCassette[]): AnyAction => ({
  type: CMActions.CitBalGetAddEventsResponse,
  payload,
})
export const getCitBalAddEventsFail = (error: string): AnyAction => ({
  type: CMActions.CitBalGetAddEventsFail,
  payload: error,
})

export const calcCitBalAddEventsTotalRequest = (): AnyAction => ({
  type: CMActions.CitBalCalcAddEventsTotalRequest,
})

export const calcCitBalAddEventsTotalResponse = (totals: ComTotals[]): AnyAction => ({
  type: CMActions.CitBalCalcAddEventsTotalResponse,
  payload: totals,
})

export const calcCitBalAddEventsTotalFail = (error: string): AnyAction => ({
  type: CMActions.CitBalCalcAddEventsTotalFail,
  payload: error,
})

export const updateCitBalAddEvents = (cassette: IcsCashPointCassette[]): AnyAction => ({
  type: CMActions.CitBalUpdateAddEvents,
  payload: cassette,
})

export const createCitBalAddEventRequest = (payload: {
  cassettes: IcsCashPointCassette[]
  date: string
  eventType: BS2CashPointEvent
}): AnyAction => ({
  type: CMActions.CitBalCreateAddEventRequest,
  payload,
})
export const createCitBalAddEventResponse = (): AnyAction => ({
  type: CMActions.CitBalCreateAddEventResponse,
})
export const createCitBalAddEventFail = (error: string): AnyAction => ({
  type: CMActions.CitBalCreateAddEventFail,
  payload: error,
})

export const assignCitBalCashierRequest = (cashierId: string): AnyAction => ({
  type: CMActions.CitBalAssignCashierRequest,
  payload: cashierId,
})

export const assignCitBalCashierResponse = (): AnyAction => ({
  type: CMActions.CitBalAssignCashierResponse,
})

export const getCitBalCashierRequest = (): AnyAction => ({
  type: CMActions.CitBalGetCashierRequest,
})
export const getCitBalCashierResponse = (cashiers: ComUuidValue[]): AnyAction => ({
  type: CMActions.CitBalGetCashierResponse,
  payload: cashiers,
})

export const modifyCitBalCassettesRequest = (payload: BalCassetteModifyRequest): AnyAction => ({
  type: CMActions.CitBalModifyCassettesRequest,
  payload,
})
export const modifyCitBalCassettesResponse = (): AnyAction => ({
  type: CMActions.CitBalModifyCassettesResponse,
})
export const modifyCitBalCassettesFail = (error: string): AnyAction => ({
  type: CMActions.CitBalModifyCassettesFail,
  payload: error,
})

export const removeCitBalCassetteRequest = (payload: BalCassetteRemoveRequest): AnyAction => ({
  type: CMActions.CitBalRemoveCassetteRequest,
  payload,
})
export const removeCitBalCassetteResponse = (): AnyAction => ({
  type: CMActions.CitBalRemoveCassetteResponse,
})
export const removeCitBalCassetteFail = (error: string): AnyAction => ({
  type: CMActions.CitBalRemoveCassetteFail,
  payload: error,
})

export const handleCitBalKeyPress = (
  actionName:
    | 'TOGGLE_CHECKBOX'
    | 'NEXT_ROW'
    | 'PREV_ROW'
    | 'OPEN_FILTER'
    | 'OPEN_PRINT_DIALOG'
    | 'REFRESH'
    | 'SELECT_ROW',
): AnyAction => ({
  type: CMActions.CitBalHandleKeyPress,
  payload: actionName,
})
