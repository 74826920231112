export enum RoutesActions {
  RtsGetRoutesRequest = '@/RTS_GET_ROUTES_REQUEST',
  RtsGetRoutesResponse = '@/RTS_GET_ROUTES_RESPONSE',
  RtsGetRoutesFail = '@/RTS_GET_ROUTES_FAIL',

  RtsGetRouteRequest = '@/RTS_GET_ROUTE_REQUEST',
  RtsGetRouteResponse = '@/RTS_GET_ROUTE_RESPONSE',
  RtsGetRouteFail = '@/RTS_GET_ROUTE_FAIL',

  RtsSetSelectedRoute = '@/RTS_SET_SELECTED_ROUTE',

  RtsToggleRoute = '@/RTS_TOGGLE_ROUTE',
  RtsToggleAllRoutes = '@/RTS_TOGGLE_ALL_ROUTES',

  RtsSortRoutes = '@/RTS_SORT_ROUTES',

  RtsSetPagingRoutes = '@/RTS_SET_PAGING_ROUTES',

  RtsSetFilterRoutes = '@/RTS_SET_FILTER_ROUTES',

  RtsOpenFilterModal = '@/RTS_OPEN_FILTER_MODAL',
  RtsOpenSettingsModal = '@/RTS_OPEN_SETTINGS_MODAL',
  RtsOpenRouteModal = '@/RTS_OPEN_ROUTE_MODAL',

  // Buttons state
  RtsGetRouteButtonsRequest = '@/RTS_GET_ROUTE_BUTTONS_REQUEST',
  RtsGetRouteButtonsResponse = '@/RTS_GET_ROUTE_BUTTONS_RESPONSE',
  RtsGetRouteButtonsFail = '@/RTS_GET_ROUTE_BUTTONS_FAIL',

  RtsSetRouteStatusRequest = '@/RTS_SET_ROUTE_STATUS_REQUEST',
  RtsSetRouteStatusResponse = '@/RTS_SET_ROUTE_STATUS_RESPONSE',
  RtsSetRouteStatusFail = '@/RTS_SET_ROUTE_STATUS_FAIL',

  // Delete
  RtsDeleteRouteRequest = '@/RTS_DELETE_ROUTE_REQUEST',
  RtsDeleteRouteResponse = '@/RTS_DELETE_ROUTE_RESPONSE',
  RtsDeleteRouteFail = '@/RTS_DELETE_ROUTE_FAIL',

  // Route Objects
  RtsGetRouteObjectsRequest = '@/RTS_GET_ROUTE_OBJECTS_REQUEST',
  RtsGetRouteObjectsResponse = '@/RTS_GET_ROUTE_OBJECTS_RESPONSE',
  RtsGetRouteObjectsFail = '@/RTS_GET_ROUTE_OBJECTS_FAIL',

  RtsChangeRouteObjectsOrder = '@/RTS_CHANGE_ROUTE_OBJECTS_ORDER',

  RtsSetRouteSelectedObject = '@/RTS_SET_ROUTE_SELECTED_OBJECT',
  RtsSetRouteSelectedObjectOnMap = '@/RTS_SET_ROUTE_SELECTED_OBJECT_ON_MAP',

  RtsSortRouteObjects = '@/RTS_SORT_ROUTE_OBJECTS',

  RtsApplyObjectsRequest = '@/RTS_APPLY_OBJECTS_REQUEST',
  RtsApplyObjectsResponse = '@/RTS_APPLY_OBJECTS_RESPONSE',
  RtsApplyObjectsFail = '@/RTS_APPLY_OBJECTS_FAIL',

  // Optimise
  RtsOptimizeObjectsRequest = '@/RTS_OPTIMIZE_OBJECTS_REQUEST',
  RtsOptimizeObjectsResponse = '@/RTS_OPTIMIZE_OBJECTS_RESPONSE',
  RtsOptimizeObjectsFail = '@/RTS_OPTIMIZE_OBJECTS_FAIL',

  // Status
  RtsSetObjectStatusRequest = '@/RTS_SET_OBJECT_STATUS_REQUEST',
  RtsSetObjectStatusResponse = '@/RTS_SET_OBJECT_STATUS_RESPONSE',
  RtsSetObjectStatusFail = '@/RTS_SET_OBJECT_STATUS_FAIL',

  // Orders
  RtsGetOrdersRequest = '@/RTS_GET_ORDERS_REQUEST',
  RtsGetOrdersResponse = '@/RTS_GET_ORDERS_RESPONSE',
  RtsGetOrdersFail = '@/RTS_GET_ORDERS_FAIL',

  RtsGetVaultsRequest = '@/RTS_GET_VAULTS_REQUEST',
  RtsGetVaultsResponse = '@/RTS_GET_VAULTS_RESPONSE',
  RtsGetVaultsFail = '@/RTS_GET_VAULTS_FAIL',

  RtsSortOrders = '@/RTS_SORT_ORDERS',
  RtsSetOrdersPaging = '@/RTS_SET_ORDERS_PAGING',
  RtsSetOrdersFilter = '@/RTS_SET_ORDERS_FILTER',

  RtsToggleRouteOrder = '@/RTS_TOGGLE_ROUTE_ORDER',
  RtsToggleAllRouteOrders = '@/RTS_TOGGLE_ALL_ROUTE_ORDERS',

  RtsCreateOrderRequest = '@/RTS_CREATE_ORDER_REQUEST',
  RtsCreateOrderResponse = '@/RTS_CREATE_ORDER_RESPONSE',
  RtsCreateOrderFail = '@/RTS_CREATE_ORDER_FAIL',

  RtsEditOrderRequest = '@/RTS_EDIT_ORDER_REQUEST',
  RtsEditOrderResponse = '@/RTS_EDIT_ORDER_RESPONSE',
  RtsEditOrderFail = '@/RTS_EDIT_ORDER_FAIL',

  RtsDeleteOrderRequest = '@/RTS_DELETE_ORDER_REQUEST',
  RtsDeleteOrderFail = '@/RTS_DELETE_ORDER_FAIL',

  RtsHandleKeyPress = '@/RTS_HANDEL_KEY_PRESS',
}
