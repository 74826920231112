export enum ReportFormField {
  StartDate = 'startDate',
  EndDate = 'endDate',
  SLAType = 'slaType',
  SLANumber = 'slaNumber',
  DeviceOwner = 'deviceOwner',
  DeviceRenter = 'deviceRenter',
  SaveReport = 'saveReport',
  ReportName = 'reportName',
  SubScribe = 'subscribe',
  Interval = 'interval',
  TargetFormat = 'targetFormat',

  PeriodDays = 'period',
  PeriodWeek = 'week',
  PeriodMonth = 'month',

  ShowDataReport = 'showDataReport',
  ShowLazyReport = 'showLazyReport',

  EventsDate = 'eventsDate',
  IntervalType = 'intervalType',
}

export enum DateRangeName {
  Range = 'dateRange',
}

export enum RadioPeriod {
  Period = 'periodSubscribe',
}

export enum RadioValue {
  period = 'period',
  week = 'week',
  month = 'month',
}

interface Options {
  name: string
  value: string
}

export type IntervalsAndDate = {
  start: string
  end: string
  interval: string | number | null
}

export interface ReportInstallationValues {
  [ReportFormField.SaveReport]: boolean
  [ReportFormField.ReportName]: string
  [ReportFormField.SubScribe]: boolean
  [ReportFormField.Interval]: string | number | null
  [DateRangeName.Range]: [string | null, string | null]
  [RadioPeriod.Period]: string
  [ReportFormField.TargetFormat]: string
  [ReportFormField.PeriodDays]: string
  [ReportFormField.PeriodWeek]: Array<string>
  [ReportFormField.PeriodMonth]: string
}
