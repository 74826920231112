import { AnyAction } from 'redux'
import scrollIntoView from 'scroll-into-view-if-needed'

import { store } from '@/App'
import { HotKeysAction } from '@/constants'

interface BinderProps<T> {
  key: string
  data?: T[]
  idName?: string
  selected?: T
  isOpenModalsList?: boolean[]
  setSelectedAction?: (row: T) => AnyAction
  onToggleAction?: (selected: T) => AnyAction
  openFilterAction?: (condition: boolean) => AnyAction
  openPrintAction?: (condition: boolean) => AnyAction
  onRefreshAction?: () => AnyAction
  onEnterAction?: (condition: boolean) => AnyAction
}

export const hotKeysBinder = <T extends { [key: string]: any }>({
  key,
  data,
  idName,
  selected,
  isOpenModalsList = [],
  setSelectedAction,
  onToggleAction,
  openFilterAction,
  openPrintAction,
  onRefreshAction,
  onEnterAction,
}: BinderProps<T>): void => {
  if (!isOpenModalsList.some((modal: boolean) => modal)) {
    const internalIdName = idName || 'id'
    const curSelIdx = data
      ? data.findIndex(
          (object: T) => object[internalIdName] === (selected && selected[internalIdName]),
        )
      : 0

    switch (key) {
      case HotKeysAction.PREV_ROW:
        if (setSelectedAction && data) {
          const row = curSelIdx > 0 ? data[curSelIdx - 1] : data[0]

          if (row) {
            const nextSelIdx = data.findIndex(
              (object: T) => object[internalIdName] === (row && row[internalIdName]),
            )

            const elem = window.document.getElementById(
              nextSelIdx > 0 ? data[nextSelIdx - 1][internalIdName] : data[0][internalIdName],
            )

            elem &&
              scrollIntoView(elem, {
                scrollMode: 'if-needed',
                block: nextSelIdx > 0 ? 'nearest' : 'center',
                inline: 'nearest',
              })

            store.dispatch(setSelectedAction(row))
          }
        }
        break
      case HotKeysAction.NEXT_ROW:
        if (setSelectedAction && data) {
          const row = curSelIdx >= 0 ? data[curSelIdx + 1] : data[0]

          if (row) {
            const elem = window.document.getElementById(row[internalIdName])

            elem &&
              scrollIntoView(elem, {
                scrollMode: 'if-needed',
                block: 'end',
                inline: 'nearest',
              })

            store.dispatch(setSelectedAction(row))
          }
        }
        break
      case HotKeysAction.TOGGLE_CHECKBOX:
        if (selected && onToggleAction) {
          store.dispatch(onToggleAction(selected))
        }
        break
      case HotKeysAction.OPEN_FILTER:
        if (openFilterAction) {
          store.dispatch(openFilterAction(true))
        }
        break
      case HotKeysAction.OPEN_PRINT_DIALOG:
        if (openPrintAction) {
          store.dispatch(openPrintAction(true))
        }
        break
      case HotKeysAction.REFRESH:
        if (onRefreshAction) {
          store.dispatch(onRefreshAction())
        }
        break
      case HotKeysAction.SELECT_ROW:
        if (selected && onEnterAction) {
          store.dispatch(onEnterAction(true))
        }
        break

      default:
        break
    }
  }
}
