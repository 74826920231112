import makeStyles from '@material-ui/core/styles/makeStyles'
import { styledComponentTheme } from '@/theme/index'

export const useClasses = makeStyles(({ palette, spacing, shape: { borderRadius } }) => ({
  iconSize: {
    fontSize: styledComponentTheme.fontSizes.veryBig,
  },
  menu: {
    top: `${spacing(1.93)}px !important`,
  },
  list: {
    paddingRight: spacing(0),
    width: spacing(23),
  },
  menuWrapper: {
    color: palette.grey[50],
    marginLeft: spacing(20),
  },
  item: {
    padding: spacing(1, 2),
    paddingLeft: spacing(0),
  },

  menuItems: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
  },

  labelIcon: {
    // marginRight: spacing(1),
    fontSize: styledComponentTheme.fontSizes.big,
    // color: palette.grey[200],
  },
  label: {
    padding: spacing(0, 1),
  },
}))
