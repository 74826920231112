import GoogleMapReact, { ChangeEventValue, fitBounds } from 'google-map-react'
import supercluster from 'points-cluster'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Device } from '@/api/atmeye/devicesApi/types'
import { TABS } from '@/components/pages/atmeye/Devices/Components/MapPageWrapper/types'
import { INITIAL_LOCALE } from '@/constants'
import { ATMEYE_MAP_CONSTANTS, MAP_CONSTANTS } from '@/constants/map'
import { activeLocale } from '@/store/internalization/selectors'

import { Marker } from '../Markers/Marker'
import { MarkerCluster } from '../Markers/MarkerCluster'
import { PointInfosItem } from '../types'
import { Bounds, Cluster, ClusterState, Coords, Props } from './types'

const defaultZeroCenter = { lat: 0, lng: 0 }

const defaultZeroBounds = {
  ne: defaultZeroCenter,
  sw: defaultZeroCenter,
  se: defaultZeroCenter,
  nw: defaultZeroCenter,
}

const createBounds = (bounds: Bounds) => {
  const newBounds: any = bounds
  const scrollCircle = 360
  const halfScrollCircle = scrollCircle / 2

  Object.entries(newBounds).forEach(([bounds, boundsValue]: any) => {
    Object.entries(boundsValue).forEach(([coordinatesNames, coordinates]: any) => {
      let newCoordinates = coordinates
      while (newCoordinates < -halfScrollCircle) {
        newCoordinates += scrollCircle
        newBounds[bounds][coordinatesNames] = newCoordinates
      }

      if (coordinates > scrollCircle) {
        newBounds[bounds][coordinatesNames] = coordinates % scrollCircle
      }
    })
  })

  return newBounds
}

export const GoogleMapATMeye = ({
  tab,
  mapHeight,
  mapWidth,

  currentDevice,
  mapPoints,
  mapPointsInfo,

  handleSetCurrentDeviceId,
}: Props): React.ReactElement => {
  const [mapOptions, setMapOptions] = useState<{
    center: Coords
    zoom: number
    bounds?: Bounds
  }>({
    center: ATMEYE_MAP_CONSTANTS.defaultCenter,
    zoom: MAP_CONSTANTS.defaultZoom,
  })

  const [clusters, setClusters] = useState<ClusterState[]>([])
  const [isCanOpenModalMarker, setIsCanOpenModalMarker] = useState<boolean>(true)

  const [isFirstMapUpdate, setIsFirstMapUpdate] = useState(true)
  const locale = useSelector(activeLocale)

  const getClusters = useCallback((): Cluster[] => {
    const clusters = supercluster(mapPoints, {
      minZoom: ATMEYE_MAP_CONSTANTS.minZoom,
      maxZoom: ATMEYE_MAP_CONSTANTS.maxZoom,
      radius: MAP_CONSTANTS.radius,
    })

    return clusters(mapOptions)
  }, [mapOptions, mapPoints])

  const createClusters = useCallback(() => {
    setClusters(
      mapOptions.bounds
        ? getClusters().map(({ wx, wy, numPoints, points }) => ({
            lat: wy,
            lng: wx,
            numPoints,
            id: `${points[0].id}`,
            points,
          }))
        : [],
    )
  }, [getClusters, mapOptions.bounds])

  const handleMapChange = (data: ChangeEventValue): void => {
    if (isFirstMapUpdate || tab === TABS.DEVICES) {
      return
    }

    const { center, zoom, bounds } = data

    if (zoom > 0) {
      setMapOptions({
        center,
        zoom,
        bounds: createBounds(bounds),
      })
    }
  }

  useEffect(() => {
    createClusters()
  }, [createClusters])

  const hasClusters = clusters.length > 0

  const createMapOptions = useCallback(
    (pointsData: PointInfosItem[]) => {
      if (pointsData.length === 1) {
        setMapOptions(prevState => {
          return {
            center: {
              lat: +pointsData[0].latitude,
              lng: +pointsData[0].longitude,
            },
            zoom: prevState.zoom < 8 ? 8 : prevState.zoom,
            bounds: prevState.bounds,
          }
        })
      } else {
        const topRightMarkerLatitude = pointsData.reduce(
          (max, point) => (point.latitude > max ? point.latitude : max),
          pointsData[0].latitude,
        )

        const topRightMarkerLongitude = pointsData.reduce(
          (max, point) => (point.longitude > max ? point.longitude : max),
          pointsData[0].longitude,
        )

        const bottomLeftMarkerLatitude = pointsData.reduce(
          (min, point) => (point.latitude < min ? point.latitude : min),
          pointsData[0].latitude,
        )

        const bottomLeftMarkerLongitude = pointsData.reduce(
          (min, point) => (point.longitude < min ? point.longitude : min),
          pointsData[0].longitude,
        )

        const bounds = {
          ne: {
            lat: +topRightMarkerLatitude,
            lng: +topRightMarkerLongitude,
          },
          sw: {
            lat: +bottomLeftMarkerLatitude,
            lng: +bottomLeftMarkerLongitude,
          },
        }

        const isZeroCoordinates =
          !+topRightMarkerLatitude &&
          !+topRightMarkerLongitude &&
          !+bottomLeftMarkerLatitude &&
          !+bottomLeftMarkerLongitude

        const size = {
          width: mapWidth || 1,
          height: mapHeight || 1,
        }

        const { center, zoom, newBounds } = fitBounds(bounds, size)

        setMapOptions({
          center: isZeroCoordinates ? defaultZeroCenter : center,
          zoom: zoom > 0 ? zoom : 1,
          bounds: isZeroCoordinates ? defaultZeroBounds : newBounds,
        })
        setIsFirstMapUpdate(false)
      }
    },
    [mapHeight, mapWidth],
  )

  useEffect(() => {
    const findCurrentDevice = mapPointsInfo.find(el => +el.deviceId === currentDevice?.deviceId)

    if (findCurrentDevice) {
      createMapOptions([findCurrentDevice])
      return
    }

    if (mapPointsInfo.length) {
      createMapOptions(mapPointsInfo)
    }
  }, [mapPointsInfo, currentDevice, createMapOptions])

  const onMouseOver = () => {
    setIsCanOpenModalMarker(true)
  }

  const handleSetCurrentDevice = (device: Device) => () => {
    handleSetCurrentDeviceId(device)()
    setIsCanOpenModalMarker(false)
  }

  return (
    <GoogleMapReact
      onChange={handleMapChange}
      center={mapOptions.center}
      zoom={mapOptions.zoom}
      resetBoundsOnResize
      yesIWantToUseGoogleMapApiInternals
      bootstrapURLKeys={{
        key: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
        language: locale || INITIAL_LOCALE,
        region: locale || INITIAL_LOCALE,
      }}
      defaultZoom={MAP_CONSTANTS.defaultZoom}
      defaultCenter={ATMEYE_MAP_CONSTANTS.defaultCenter}
      options={MAP_CONSTANTS.mapOptions}
    >
      {hasClusters &&
        clusters.map((item: ClusterState, i) => {
          return (
            <MarkerCluster
              key={`${item.id}${i}`}
              lat={item.lat ? item.lat : 0}
              lng={item.lng ? item.lng : 0}
              mapPointsInfo={mapPointsInfo || []}
              points={item?.points || []}
              handleSetCurrentDeviceId={handleSetCurrentDevice}
              currentDevice={currentDevice}
              onMouseOver={onMouseOver}
              isCanOpenModalMarker={isCanOpenModalMarker}
            />
          )
        })}

      {!hasClusters &&
        mapPointsInfo.map((point: PointInfosItem) => (
          <Marker
            key={point.deviceId}
            lat={point.latitude ? +point.latitude : 0}
            lng={point.longitude ? +point.longitude : 0}
            pointInfo={point}
            handleSetCurrentDeviceId={handleSetCurrentDevice}
            onMouseOver={onMouseOver}
            isCanOpenModalMarker={isCanOpenModalMarker}
          />
        ))}
    </GoogleMapReact>
  )
}
