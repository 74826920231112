import queryString from 'query-string'

import { AdmSpecialDay, AdmSpecialDayBase } from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class SpecialDaysApi {
  private static requestService = getRequestServiceCM()

  public static async get(sort: Sort): Promise<AdmSpecialDay[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/specialDays',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )
      return await SpecialDaysApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(payload: AdmSpecialDayBase): Promise<void> {
    try {
      return await SpecialDaysApi.requestService.post('/admin/specialDays/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(payload: AdmSpecialDay): Promise<void> {
    try {
      return await SpecialDaysApi.requestService.put('/admin/specialDays/update', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(id: number): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/specialDays/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await SpecialDaysApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
