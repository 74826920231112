import { Box, Container } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Test from 'react-test-attributes'

import AddNewCompanyForm from '@/components/pages/companies/CompaniesPage/components/AddNewCompanyForm'
import { preventDefault } from '@/utils/functions'
import { getTestId } from '@/utils/getTestId'

import { useClasses } from './styles'
import { Props } from './types'

const AddNewCompanyModal = ({
  open,
  handleClose,
  parentID,
  treeElement,
  disableBackdropClick,
  disableEscapeKeyDown,
}: Props): React.ReactElement => {
  const idPrefix = 'companies-add-new-company-modal'
  const testId = getTestId(idPrefix)
  const classes = useClasses()

  const onClose = (event: any, reason: 'backdropClick' | 'escapeKeyDown'): void | boolean => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return false
    }

    if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false
    }

    if (typeof handleClose === 'function') {
      handleClose()
    }
  }

  return (
    <>
      <Test id={testId(0)}>
        <Dialog
          onDragOver={preventDefault}
          onDrop={preventDefault}
          maxWidth="lg"
          fullWidth
          open={open}
          onClose={onClose}
        >
          <Box display="flex" flexDirection="column" overflow="auto">
            <Container className={classes.container}>
              <Test id={testId(1)}>
                <Typography variant="h5">
                  <FormattedMessage
                    id="companies.action.add.newCompany"
                    defaultMessage="Add new company"
                  />
                </Typography>
              </Test>
              <AddNewCompanyForm
                open={open}
                handleClose={handleClose}
                parentID={parentID}
                treeElement={treeElement}
              />
            </Container>
          </Box>
        </Dialog>
      </Test>
    </>
  )
}

export default AddNewCompanyModal
