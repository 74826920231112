import { fork } from 'redux-saga/effects'

import appConfig from './appConfig/saga'
import currencies from './currencies/saga'
import denominations from './denominations/saga'
import valuables from './valuables/saga'

export default function*(): Generator {
  yield fork(appConfig)
  yield fork(currencies)
  yield fork(denominations)
  yield fork(valuables)
}
