import { AnyAction } from 'redux'

import { AdmCurrency, AdmCurrencyBase } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants/actions/cmActions'
import { Sort } from '@/types/cm'

// get
export const getAdmCurrenciesRequest = (): AnyAction => ({
  type: CMAppConfigActions.AdmGetCurrenciesRequest,
})
export const getAdmCurrenciesResponse = (payload: AdmCurrency[]): AnyAction => ({
  type: CMAppConfigActions.AdmGetCurrenciesResponse,
  payload: payload,
})
export const getAdmCurrenciesFail = (): AnyAction => ({
  type: CMAppConfigActions.AdmGetCurrenciesFail,
})

// add
export const addAdmCurrencyRequest = (payload: AdmCurrencyBase): AnyAction => ({
  type: CMAppConfigActions.AdmAddCurrencyRequest,
  payload,
})
export const addAdmCurrencyFail = (): AnyAction => ({
  type: CMAppConfigActions.AdmAddCurrencyFail,
})

// update
export const updateAdmCurrencyRequest = (payload: AdmCurrency): AnyAction => ({
  type: CMAppConfigActions.AdmUpdateCurrencyRequest,
  payload,
})
export const updateAdmCurrencyFail = (): AnyAction => ({
  type: CMAppConfigActions.AdmUpdateCurrencyFail,
})

// delete
export const deleteAdmCurrencyRequest = (payload?: number): AnyAction => ({
  type: CMAppConfigActions.AdmDeleteCurrencyRequest,
  payload,
})
export const deleteAdmCurrencyFail = (): AnyAction => ({
  type: CMAppConfigActions.AdmDeleteCurrencyFail,
})

// set base
export const setAdmCurrencyAsBaseRequest = (payload?: number): AnyAction => ({
  type: CMAppConfigActions.AdmSetCurrencyAsBaseRequest,
  payload,
})
export const setAdmCurrencyAsBaseFail = (): AnyAction => ({
  type: CMAppConfigActions.AdmSetCurrencyAsBaseFail,
})

// sort
export const sortAdmCurrencies = (payload: Sort): AnyAction => ({
  type: CMAppConfigActions.AdmSortCurrencies,
  payload,
})
