import { TextField } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import React, {
  FocusEvent,
  KeyboardEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { InputCellTypes } from '../types/types'
import { useClasses } from './styles'

const InputCell = ({
  isParent,
  headerName,
  note,
  onBlur,
  index,
  row,
  nesting = 0,
}: InputCellTypes): ReactElement => {
  const classes = useClasses()
  const [isInput, setIsInput] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(note)

  useEffect(() => {
    setInputValue(note)
  }, [row])

  const changeNotesInput = (event: any): void => {
    const { value } = event.target
    if (value.length < 255) {
      setInputValue(event.target.value)
    }
  }

  const callbackRef = useCallback(
    inputElement => {
      if (inputElement) {
        setTimeout(() => {
          inputElement.focus()
        }, 0)
      }
    },
    [isInput],
  )

  const handleSaveNotes = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement> | KeyboardEvent<HTMLDivElement>,
  ): void => {
    onBlur(event, headerName, row[headerName], row.event_id, index, index, nesting)
    setIsInput(false)
  }

  return (
    <TableCell
      key={headerName}
      className={classes.cell}
      style={{ minWidth: '80px' }}
      onDoubleClick={(): void => {
        setIsInput(true)
      }}
    >
      {!isParent && (!inputValue || isInput) ? (
        <TextField
          disabled={!isInput}
          value={inputValue}
          onChange={changeNotesInput}
          inputRef={callbackRef}
          variant="outlined"
          onKeyDown={event => {
            event?.keyCode === 13 && handleSaveNotes(event)
          }}
          onBlur={handleSaveNotes}
        />
      ) : (
        <Typography variant="body2">{inputValue}</Typography>
      )}
    </TableCell>
  )
}

export { InputCell }
