import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { UsrChangePasswordRequest } from '@/api/vault-v1/swaggerGeneratedApi'
import ChangePasswordModal from '@/components/blocks/cm/ChangePasswordModal'
import { changePasswordRequest, getChangePasswordState } from '@/store/vault-v1/auth'

export const ChangePassword: FC = () => {
  const dispatch = useDispatch()

  const { isModalOpen: isChangePasswordModalOpen, isLoading, error } = useSelector(
    getChangePasswordState,
  )

  const handleChangePassword = useCallback<(values: UsrChangePasswordRequest) => void>(
    (values): void => {
      dispatch(changePasswordRequest(values))
    },
    [dispatch],
  )

  if (!isChangePasswordModalOpen) return null

  return (
    <ChangePasswordModal
      open={isChangePasswordModalOpen}
      handleChangePassword={handleChangePassword}
      isLoading={isLoading}
      error={error}
    />
  )
}
