import { AdmRealTimeMonitoring } from '@/api/cm/swaggerGeneratedApi'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class RealTimeMonitoringApi {
  private static requestService = getRequestServiceCM()

  public static async get(): Promise<AdmRealTimeMonitoring> {
    try {
      return await RealTimeMonitoringApi.requestService.get('/admin/realTimeMonitoring')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async save(payload: AdmRealTimeMonitoring): Promise<void> {
    try {
      return await RealTimeMonitoringApi.requestService.put(
        '/admin/realTimeMonitoring/save',
        payload,
      )
    } catch (error) {
      throw Error(error)
    }
  }
}
