import { APIConfiguration } from '@/constants'
import { RequestHTTPServiceTypes } from '@/types/common/services'
import { getRequestService } from '@/utils/services/request'

import { AdminOptions } from './types'

class AdminOptionsAPIService {
  private requestService: RequestHTTPServiceTypes
  constructor(httpService: RequestHTTPServiceTypes) {
    this.requestService = httpService
  }

  async getOptions(): Promise<AdminOptions> {
    try {
      return await this.requestService.get(`/atmeye/api/v1/admin-options/data`)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async updateOptions(payload: AdminOptions) {
    try {
      return await this.requestService.put(`/atmeye/api/v1/admin-options/data`, payload)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

export const adminOptionsApi = new AdminOptionsAPIService(
  getRequestService(APIConfiguration.API_BASE_URL),
)
