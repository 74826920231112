import { AnyAction } from 'redux'

import { CMAppConfigActions } from '@/constants/actions'

export type State = {
  logo: {
    data: string
    isLoading: boolean
  }

  approvingLogo: {
    data: string
    isLoading: boolean
    error: string | null
  }
}

export const initialState: State = {
  logo: {
    data: '',
    isLoading: false,
  },
  approvingLogo: {
    data: '',
    isLoading: false,
    error: null,
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    // Get ----------------------------------------------
    case CMAppConfigActions.AdmSetApprovingLogoRequest:
    case CMAppConfigActions.AdmGetLogoRequest:
      return {
        ...state,
        logo: {
          ...state.logo,
          isLoading: true,
        },
      }
    case CMAppConfigActions.AdmGetLogoResponse:
      return {
        ...state,
        logo: {
          ...state.logo,
          isLoading: false,
          data: payload,
        },
      }
    case CMAppConfigActions.AdmGetLogoFail:
      return {
        ...state,
        logo: {
          ...state.logo,
          isLoading: false,
          data: '',
        },
      }

    // Approving --------------------------------------
    case CMAppConfigActions.AdmGetApprovingLogoRequest:
      return {
        ...state,
        approvingLogo: {
          ...state.approvingLogo,
          isLoading: true,
          error: null,
        },
      }
    case CMAppConfigActions.AdmGetApprovingLogoResponse:
      return {
        ...state,
        approvingLogo: {
          ...state.approvingLogo,
          isLoading: false,
          data: payload,
        },
      }
    case CMAppConfigActions.AdmGetApprovingLogoFail:
      return {
        ...state,
        approvingLogo: {
          ...state.approvingLogo,
          isLoading: false,
          data: '',
          error: payload,
        },
      }

    default:
      return state
  }
}
