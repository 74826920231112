import { Device } from '@/api/atmeye/devicesApi/types'
import { Row } from '@/types/atmeye/TableTypes'

export type Props = {
  currentDevice: null | Device
  data: Array<Row>
}

export enum CORE_MODAL_TYPE {
  ON = 'ON',
  OFF = 'OFF',

  DEFAULT = 'DEFAULT',
}

export enum LOGGING_MODAL_TYPE {
  ON = 'ON',
  OFF = 'OFF',

  DEFAULT = 'DEFAULT',
}
