import makeStyles from '@material-ui/core/styles/makeStyles'
import { styledComponentTheme } from '@/theme'

export const useClassesTooltip = makeStyles(() => ({
  arrow: {
    color: '#ffffff',
  },
  tooltip: {
    fontSize: styledComponentTheme.fontSizes.normal,
    backgroundColor: '#ffffff',
    color: '#000000',
    boxShadow: '0px 5px 5px #EBEBEB',
  },
}))

export const useClasses = makeStyles(() => ({
  titleWrapper: {
    maxWidth: '220px',
    whiteSpace: 'normal',
    textAlign: 'left',
  },
}))
