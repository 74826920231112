import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromCMPlasticCard from './reducer'

export const getPlasticCardState = (state: AppState): fromCMPlasticCard.State => {
  return state.cmPlasticCard
}

export const isLoadingModal = createSelector(
  getPlasticCardState,
  state => state.modals.loadingModal,
)

export const getPlcCard = createSelector(getPlasticCardState, state => state.plasticCard)

export const getPlcCardData = createSelector(getPlcCard, state => ({
  data: state.data,
  isLoading: state.isLoading,
  isLoaded: state.isLoaded,
}))

export const getPlcCardSelected = createSelector(
  getPlasticCardState,
  state => state.plasticCard.selected,
)

export const getPlcCardChecked = createSelector(
  getPlasticCardState,
  state => state.plasticCard.checked,
)

export const getPlcCardSort = createSelector(getPlcCard, state => ({
  sortColumn: state.sortColumn,
  sortOrder: state.sortOrder,
}))

export const getPlcCardFilter = createSelector(getPlcCard, state => {
  return state.filter
})

export const getPlcCardPaging = createSelector(getPlcCard, state => {
  return state.paging
})

export const getPlcCardTotal = createSelector(getPlcCard, state => {
  return state.total
})

export const getPlcCardModals = createSelector(getPlasticCardState, state => {
  return state.modals
})

export const getPlcCardColumnsModal = createSelector(getPlcCardModals, state => {
  return state.isColumnModalOpen
})
export const getPlcCardFilterModal = createSelector(getPlcCardModals, state => {
  return state.isFiltersModalOpen
})

export const getTeamIsEnable = (stage?: string) =>
  createSelector(getPlcCard, () => {
    return stage
      ? stage === 'TeamReset' ||
          stage === 'TeamAssigned' ||
          stage === 'Declined' ||
          stage === 'Completed'
      : false
  })

// WorkOrders
export const getPlcWorkOrder = createSelector(getPlasticCardState, state => state.workOrder)

export const getPlcWorkOrderData = createSelector(getPlcWorkOrder, state => ({
  data: state.data,
  isLoading: state.isLoading,
  isLoaded: state.isLoaded,
}))

export const getPlcWorkOrderFilter = createSelector(getPlcWorkOrder, state => state.filter)
export const getPlcWorkOrderSelected = createSelector(getPlcWorkOrder, state => state.selected)
export const getWorkOrderSort = createSelector(getPlcWorkOrder, state => ({
  sortColumn: state.sortColumn,
  sortOrder: state.sortOrder,
}))
export const getPlcWorkOrderReports = createSelector(getPlcWorkOrder, state => state.reports)

export const getPlcTeams = createSelector(getPlasticCardState, state => state.teams)
