import { SagaIterator } from '@redux-saga/core'
import { call, put } from '@redux-saga/core/effects'
import { ActionCreator, AnyAction } from 'redux'

import { ResponseErrorTypes } from '@/types/common/ErrorResponseTypes'
import { ActionFormTypes } from '@/types/usm/commoTypes/actionFormTypes'

export type ActionType<T> = (
  payload: T,
) => {
  type: string
  payload: T
}

export type ErrorActionType = (
  payload: ResponseErrorTypes,
) => {
  type: string
  payload: ResponseErrorTypes
}

export type OptionsType<T, R> = {
  fetcher: (...arg: any) => Promise<T | ResponseErrorTypes>
  // fetcher: () => Promise<T>
  fetcherParam?: R
  startFetching?: ActionCreator<AnyAction>
  stopFetching: ActionCreator<AnyAction>
  fill: ActionType<T>
  setErrorAction: ErrorActionType
  formActions?: ActionFormTypes<any>
}

export function* makeRequestWithSpinner<T, R>(options: OptionsType<T, R>): SagaIterator {
  const {
    fetcher,
    fill,
    setErrorAction,
    startFetching,
    stopFetching,
    fetcherParam,
    formActions,
  } = options
  try {
    if (startFetching) {
      yield put(startFetching())
    }

    const result = yield call(fetcher)
    yield put(
      fill(
        result.status === 204 || result.status === 200 || result === true ? fetcherParam : result,
      ),
    )

    if (formActions) {
      formActions.handleClose()
      formActions.formActions && formActions.formActions.resetForm()
    }
  } catch (e) {
    yield put(setErrorAction(e))
  } finally {
    yield put(stopFetching())
  }
}
