import { Field as FormikField } from 'formik'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import { createMultipleSelectField } from '@/components/controls/MultipleSelect'
import { sortAlphabeticallyStringArray } from '@/utils/functions'

import { Props } from './types'

const LookupsMultiSelect = ({
  isDisabled,

  fieldName,
  field,
  defaultValue,

  onCall,
  onSelect,
}: Props): ReactElement => {
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<{ value: string; name: string }[]>(defaultValue || [])
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    if (defaultValue) {
      setOptions(defaultValue)
    }
  }, [defaultValue])

  const handleCall = useCallback(
    async ({ value = '', pageNumber = 1, pageSize = 100 }) => {
      setDataIsLoading(true)

      const { values, totalPages } = await onCall({
        value,
        pageSize,
        pageNumber,
      })

      setOptions(options => {
        const newOptions = [
          ...options,
          ...values.filter(
            (item: { name: string; value: string }) =>
              !options.find(option => option.value === item.value),
          ),
        ]
        const sortedOptions = sortAlphabeticallyStringArray({
          array: newOptions,
          sortProperty: 'name',
        })

        return sortedOptions
      })

      setTotalPages(totalPages)
      setDataIsLoading(false)

      return {
        values,
        totalPages,
      }
    },
    [onCall],
  )

  const handleOpen = useCallback((): void => {
    handleCall({ value: '' })
  }, [handleCall])

  const Field = useMemo(() => {
    return createMultipleSelectField({
      isLoading: dataIsLoading,
      label: field.options?.label,
      placeholder: field.options?.placeholder,
      options: options.length ? options : defaultValue || [],
      value: defaultValue ? defaultValue.map(it => it.value) : [],
      shrink: true,
      SearchInputBlock: true,
      parameterName: fieldName,
      withoutInnerState: true,
      disabled: isDisabled,
      required: field.options?.required,
      onOpen: handleOpen,
      onCall: handleCall,
      totalPages,
      onChangeMultipleSelect: (values, options) => {
        const selected: { value: string; name: string }[] = []
        values.forEach(value => {
          const item = options.find(item => item.value === value)
          if (item) selected.push(item)
        })
        if (onSelect) {
          onSelect(selected)
        }
      },
    })
  }, [
    dataIsLoading,
    field.options,
    options,
    defaultValue,
    fieldName,
    isDisabled,
    handleOpen,
    handleCall,
    totalPages,
    onSelect,
  ])

  return <FormikField name={fieldName}>{Field}</FormikField>
}

export default LookupsMultiSelect
