import React, { useState, ReactElement, useCallback, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import Modal from '@/components/blocks/Modal'
import CloseButton from '@/components/blocks/CloseButton'
import Button from '@/components/controls/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import Table from '@/components/controls/AppTable'
import { setTicketOverviewData } from '@/store/srm/tickets/actions'
import { NavigationContext } from '@/context'
import { SRM_BLOCK_PREFIX, AppNavigationRoutes } from '@/constants'

import { STATUSES_NAME } from '@/constants/ticketOverview'
import { CreatedTicket } from '@/types/srm/createGroupOfServiceRequests'
import { useTranslate } from '@/utils/internalization'
import { exportToFile } from '@/utils/fileActions'
import { useClasses } from './styles'
import { Props } from './types'

const fileName = 'tickets.xlsx'

const TicketsModal = ({ isOpen, data, onClose }: Props): ReactElement => {
  const [keys, setKeys] = useState<string[]>([])

  const classes = useClasses()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const { handleAddTab } = useContext(NavigationContext)

  const exportTableToXLSX = useCallback(() => exportToFile(data, fileName), [])

  const handleClick = useCallback(
    (row: CreatedTicket[]) => {
      dispatch(
        setTicketOverviewData({
          name: 'TICK-2002157',
          status: STATUSES_NAME.planned,
        }),
      )

      if (handleAddTab) {
        handleAddTab(
          `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.TicketOverviewPage}/TICK-2002157`,
          'TICK-2002157',
          false,
        )()
      }

      onClose()
    },
    [handleAddTab],
  )

  useEffect(() => {
    if (data.length) {
      setKeys(Object.keys(data[0]))
    }
  }, [data, data.length])

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={classes.modal}>
      <CloseButton absolute className={classes.closeButton} onClick={onClose} />
      <Grid container>
        <Grid item className={classes.header}>
          <Typography variant="h6" className={classes.title}>
            {translate('translate#title.createdTickets')}
          </Typography>
        </Grid>
        <Grid item className={classes.buttonWrapper}>
          <Button
            variant="contained"
            width="xs"
            size="medium"
            startIcon={<GetAppIcon />}
            onClick={exportTableToXLSX}
          >
            <FormattedMessage id="action.downloadReport" defaultMessage="Download report" />
          </Button>
        </Grid>
        <Grid item className={classes.tableWrapper}>
          <Table
            data={data}
            isLoading={false}
            columnDisplayOrder={keys}
            tableClasses={{
              root: classes.table,
              container: classes.container,
              paper: classes.paper,
              headColumn: clsx(classes.column, classes.headColumn),
              cell: classes.column,
            }}
            onRowClick={handleClick}
          />
        </Grid>
        <Grid item className={classes.buttonWrapper}>
          <Button variant="outlined" width="sm" size="medium" onClick={onClose}>
            <FormattedMessage id="action.close" defaultMessage="Close" />
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default TicketsModal
