import moment from 'moment'
import { AnyAction } from 'redux'

import {
  ComCMReport,
  IcsOrder,
  IcsOrdersFilter,
  IcsOrderStagesInfo,
  OrdDocStage,
} from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants'
import { icsOrdersPanelId as tableId } from '@/constants/cm/cmTableIds'
import { getTableConfig } from '@/helpers/tableLocalStorage'

export type State = {
  isFiltersModalOpen: boolean
  isSettingsModalOpen: boolean
  isDocumentModalOpen: boolean
  isDeclineModalOpen: boolean
  isChangeReplenTypeModalOpen: boolean
  orders: {
    selectedOrder: IcsOrder | null
    checkedOrders: { [key: string]: IcsOrder }
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined
    data: IcsOrder[]
    total: number
    isLoading: boolean
    error: string | null
    filter: IcsOrdersFilter
    paging: {
      page: number
      pageSize: number
    }
    reports: {
      errorMessage?: string
      isLoading: boolean
      list: ComCMReport[]
      selectedReport: {
        isLoading: boolean
        errorMessage?: string
        bytes?: Blob | null
      }
    }
    selectedOrderStages: {
      isLoading: boolean
      error: string | null
      stagesInfo: IcsOrderStagesInfo | null
    }
    stages: {
      orderDocStages: OrdDocStage[]
      isLoading: boolean
      isLoaded: boolean
      selectedStage: OrdDocStage | null
    }
  }
}

const initialState: State = {
  isFiltersModalOpen: false,
  isSettingsModalOpen: false,
  isDocumentModalOpen: false,
  isDeclineModalOpen: false,
  isChangeReplenTypeModalOpen: false,
  orders: {
    selectedOrder: null,
    checkedOrders: {},
    sortColumn: undefined,
    sortOrder: undefined,
    data: [],
    total: 0,
    isLoading: false,
    error: null,
    filter: {
      stage: undefined,
      replenType: undefined,
      from: moment(new Date()).format(),
      to: undefined,
      collectorUid: undefined,
      cashierUid: undefined,
    },
    paging: {
      page: 1,
      pageSize: getTableConfig({ tableId })?.rowPerPage || 50,
    },
    reports: {
      errorMessage: '',
      isLoading: false,
      list: [],
      selectedReport: {
        isLoading: false,
        errorMessage: '',
        bytes: undefined,
      },
    },
    selectedOrderStages: {
      isLoading: false,
      error: null,
      stagesInfo: null,
    },
    stages: {
      orderDocStages: [],
      isLoading: false,
      isLoaded: false,
      selectedStage: null,
    },
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case CMActions.SetGlobalFilter:
      return {
        ...state,
        orders: {
          ...state.orders,
          paging: {
            ...state.orders.paging,
            page: 1,
          },
        },
      }
    case CMActions.IcsGetOrderStagesRequest:
      return {
        ...state,
        orders: {
          ...state.orders,
          selectedOrderStages: {
            ...state.orders.selectedOrderStages,
            isLoading: true,
            error: null,
          },
        },
      }
    case CMActions.IcsGetOrderStagesResponse:
      return {
        ...state,
        orders: {
          ...state.orders,
          selectedOrderStages: {
            ...state.orders.selectedOrderStages,
            isLoading: false,
            error: null,
            stagesInfo: payload,
          },
        },
      }
    case CMActions.IcsGetOrderStagesResponseFail:
      return {
        ...state,
        orders: {
          ...state.orders,
          selectedOrderStages: {
            ...state.orders.selectedOrderStages,
            isLoading: false,
            error: payload,
          },
        },
      }
    case CMActions.IcsDeleteLastDocumentRequest:
    case CMActions.IcsSetOrderStageRequest:
      return {
        ...state,
        orders: {
          ...state.orders,
          selectedOrderStages: {
            ...state.orders.selectedOrderStages,
            isLoading: true,
            error: null,
          },
        },
      }
    case CMActions.IcsDeleteLastDocumentResponse:
    case CMActions.IcsSetOrderStageResponse:
      return {
        ...state,
        orders: {
          ...state.orders,
          selectedOrderStages: {
            ...state.orders.selectedOrderStages,
            isLoading: false,
            error: null,
            stagesInfo: payload,
          },
        },
      }
    case CMActions.IcsDeleteLastDocumentResponseFail:
    case CMActions.IcsSetOrderStageResponseFail:
      return {
        ...state,
        orders: {
          ...state.orders,
          selectedOrderStages: {
            ...state.orders.selectedOrderStages,
            isLoading: false,
            error: payload,
          },
        },
      }
    case CMActions.IcsRefreshOrderResponse: {
      const newData = [...state.orders.data]
      const refreshedOrderIndex = newData.findIndex(el => el?.id === payload?.id)
      if (~refreshedOrderIndex) {
        newData[refreshedOrderIndex] = { ...payload }
        return {
          ...state,
          orders: {
            ...state.orders,
            data: newData,
            isLoading: false,
            selectedOrder:
              payload.id === state.orders.selectedOrder?.id ? payload : state.orders.selectedOrder,
          },
        }
      }
      return state
    }

    case CMActions.IcsRefreshOrderResponseFail:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
        },
      }
    case CMActions.IcsSetSettingsModalOpen:
      return {
        ...state,
        isSettingsModalOpen: payload,
      }
    case CMActions.IcsSetDeclineModalOpen:
      return {
        ...state,
        isDeclineModalOpen: payload,
      }
    case CMActions.IcsSetChangeReplenishmentTypeModalOpen:
      return {
        ...state,
        isChangeReplenTypeModalOpen: payload,
      }
    case CMActions.IcsGetReportsRequest:
      return {
        ...state,
        orders: {
          ...state.orders,
          reports: {
            ...state.orders.reports,
            isLoading: true,
            list: [],
            errorMessage: '',
            selectedReport: initialState.orders.reports.selectedReport,
          },
        },
      }
    case CMActions.IcsGetReportsResponse:
      return {
        ...state,
        orders: {
          ...state.orders,
          reports: {
            ...state.orders.reports,
            isLoading: false,
            list: payload,
            errorMessage: '',
          },
        },
      }
    case CMActions.IcsGetReportsResponseFail:
      return {
        ...state,
        orders: {
          ...state.orders,
          reports: {
            ...state.orders.reports,
            isLoading: false,
            list: [],
            errorMessage: payload,
          },
        },
      }
    case CMActions.IcsGetReportRequest:
      return {
        ...state,
        orders: {
          ...state.orders,
          reports: {
            ...state.orders.reports,
            selectedReport: {
              ...state.orders.reports.selectedReport,
              isLoading: true,
              errorMessage: '',
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.IcsGetReportResponse:
      return {
        ...state,
        orders: {
          ...state.orders,
          reports: {
            ...state.orders.reports,
            selectedReport: {
              ...state.orders.reports.selectedReport,
              isLoading: false,
              errorMessage: '',
              bytes: payload,
            },
          },
        },
      }
    case CMActions.IcsGetReportResponseFail:
      return {
        ...state,
        orders: {
          ...state.orders,
          reports: {
            ...state.orders.reports,
            selectedReport: {
              ...state.orders.reports.selectedReport,
              isLoading: false,
              errorMessage: payload,
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.IcsSetDocumentModalOpen:
      return {
        ...state,
        isDocumentModalOpen: payload,
      }
    case CMActions.IcsClearAllOrders: {
      return {
        ...state,
        orders: {
          ...state.orders,
          checkedOrders: {},
        },
      }
    }
    case CMActions.IcsCheckAllOrders: {
      const res: State['orders']['checkedOrders'] = {}
      const allOrders = payload.length > 0 ? payload : state.orders.data
      for (const order of allOrders) {
        if (order?.id) {
          res[order.id] = order
        }
      }
      return {
        ...state,
        orders: {
          ...state.orders,
          checkedOrders: res,
        },
      }
    }
    case CMActions.IcsToggleOrder: {
      const newCheckedOrders = { ...state.orders.checkedOrders }
      const soughtOrder = newCheckedOrders[payload?.id]
      if (!soughtOrder) {
        newCheckedOrders[payload?.id] = payload
      } else {
        delete newCheckedOrders[payload?.id]
      }
      return {
        ...state,
        orders: {
          ...state?.orders,
          checkedOrders: newCheckedOrders,
        },
      }
    }
    case CMActions.IcsSortOrdersTable:
      return {
        ...state,
        orders: {
          ...state.orders,
          ...payload,
          isLoading: true,
        },
      }
    case CMActions.IcsSetSelectedOrder:
      return {
        ...state,
        orders: {
          ...state.orders,
          selectedOrder: payload,
        },
      }
    case CMActions.IcsSetOrdersFilter:
      return {
        ...state,
        orders: {
          ...state.orders,
          filter: {
            ...state?.orders?.filter,
            ...payload,
          },
        },
      }
    case CMActions.IcsRefreshOrdersPanel:
    case CMActions.IcsGetOrdersRequest:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: true,
          error: null,
        },
      }
    case CMActions.IcsGetOrdersResponse:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
          data: payload?.data || [],
          total: payload?.total || 0,
          error: null,
        },
      }
    case CMActions.IcsGetOrdersResponseFail:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
          data: [],
          total: 0,
          error: payload,
        },
      }
    case CMActions.IcsSetOrdersPagingResponse:
      return {
        ...state,
        orders: {
          ...state.orders,
          paging: {
            ...state.orders.paging,
            ...payload,
          },
        },
      }
    case CMActions.IcsResetOrdersPage:
      return {
        ...state,
        orders: {
          ...state.orders,
          paging: {
            ...state.orders.paging,
            page: initialState?.orders?.paging?.page || 1,
          },
        },
      }
    case CMActions.IcsSetFiltersModalOpen:
      return {
        ...state,
        isFiltersModalOpen: payload,
      }

    case CMActions.IcsGetStagesDocRequest:
      return {
        ...state,
        orders: {
          ...state.orders,
          stages: {
            ...state.orders.stages,
            isLoading: true,
            isLoaded: true,
          },
        },
      }
    case CMActions.IcsGetStagesDocResponse:
      return {
        ...state,
        orders: {
          ...state.orders,
          stages: {
            ...state.orders.stages,
            orderDocStages: payload,
            isLoading: false,
          },
        },
      }
    case CMActions.IcsSetSelectedSessionDoc:
      return {
        ...state,
        orders: {
          ...state.orders,
          stages: {
            ...state.orders.stages,
            selectedStage: payload,
          },
        },
      }

    case CMActions.IcsDeclineOrdersRequest:
    case CMActions.IcsChangeReplenTypeRequest:
    case CMActions.IcsApplyMissedReplenishmentEventResponse:
    case CMActions.IcsRefreshOrderRequest:
    case CMActions.IcsAssignCollectorRequest:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: true,
        },
      }

    case CMActions.IcsDeclineOrdersResponseFail:
    case CMActions.IcsChangeReplenTypeResponseFail:
    case CMActions.IcsApplyMissedReplenishmentEventFail:
    case CMActions.IcsAssignCollectorResponseFail:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
        },
      }

    case CMActions.SignOut:
      return initialState
    default:
      return state
  }
}
