import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  wrapper: {
    margin: '30px 30px 34px 30px',
  },

  headerTitle: {
    margin: '0',
    fontSize: '20px',
    fontStyle: 'normal',
    color: '#041517',
    marginBottom: '20px',
  },

  closingWindow: {
    position: 'absolute',
    right: '-20px',
    top: '-20px',
    cursor: 'pointer',
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
  },

  labelCheckBox: {
    fontSize: '14px',
  },

  saveButton: {
    height: '40px',
    background: '#25ADBF',
    color: 'white',
    fontSize: '14px',
    marginLeft: '20px',
    '&:hover': {
      background: '#1a7c89',
    },
  },

  cancelButton: {
    height: '40px',
    color: '#929A9B',
    backgroundColor: '#FFFFFF',
    border: '1px solid #929A9B',
    borderRadius: '3px',
  },

  buttonsStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  CheckBox: {
    position: 'relative',
    right: '12px',
  },
}))
