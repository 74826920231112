import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(() => ({
  cellSelectWrapper: {
    '& div:first-child': {
      margin: '0 !important',
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiInput-underline:after': {
      display: 'none',
    },
    '& .MuiSelect-root span': {
      fontSize: '14px',
      fontWeight: 500,
    },
  },
}))
