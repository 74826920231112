import makeStyles from '@material-ui/core/styles/makeStyles'
import { styledComponentTheme } from '@/theme'

export const useClasses = makeStyles(({ spacing }) => ({
  toolbar: {
    background: 'white',
    padding: spacing(2.5),
  },
  title: {
    fontSize: styledComponentTheme.fontSizes.big,
  },
}))
