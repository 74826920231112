import Axios from 'axios'

import { UsrCulture, UsrLoginRequest, UsrLoginResponse } from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration, LocalStorageItems } from '@/constants'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

export class VaultV1AuthApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  // TODO CHANGE RESPONSE TYPE
  public static async signIn(requestBody: UsrLoginRequest): Promise<UsrLoginResponse> {
    try {
      const token = localStorage.getItem(LocalStorageItems.AccessTokenVaultV1)

      const response = await Axios.post(
        `${APIConfiguration.VAULT_V1_API_BASE_URL}/user/login`,
        requestBody,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )

      return response.data
    } catch (error) {
      throw error.response.data
    }
  }

  public static async setCulture(payload: UsrCulture): Promise<string> {
    try {
      return await VaultV1AuthApi.requestService.put('/user/setUserCulture', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async checkIsLogin(): Promise<void> {
    try {
      await VaultV1AuthApi.requestService.get('/user/status')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async logout(): Promise<void> {
    try {
      await VaultV1AuthApi.requestService.post('/user/logout', {})
    } catch (error) {
      throw new Error(error)
    }
  }
}
