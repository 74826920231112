import { FormikValues } from 'formik'
import { AnyAction } from 'redux'

import { GetGroupDetailsResponse, GetTicketsBodyFilterItem } from '@/api/sd/ticketsApi/types'
import { TableFilter } from '@/components/controls/AppTable/types'
import { TableGroup } from '@/components/pages/sd/TicketsPage/components/GroupingModal/types'
import { TicketActions } from '@/constants/index'
import {
  ApiGroupTemplate,
  FilterTemplate,
  GroupingTemplate,
  ParentGroup,
  ResponseError,
  TicketsConfigResponse,
  TypeOfTickets,
  UIGroupTemplate,
} from '@/types'
import { GetTicketsResponse } from '@/types/sd/common'
import { WindowType } from '@/types/sd/tickets/ticket'
import { TicketDetailsResponse } from '@/types/sd/tickets/ticketDetails'

export const getTicketsRequest = (isInitRequest = false): AnyAction => ({
  type: TicketActions.GetTicketRequest,
  payload: { isInitRequest },
})

export const getTicketsResponse = (tickets: GetTicketsResponse): AnyAction => ({
  type: TicketActions.GetTicketResponse,
  payload: tickets,
})

export const getTicketsResponseFail = (error: ResponseError): AnyAction => ({
  type: TicketActions.GetTicketResponseFail,
  payload: { error },
})

export const getGroupDetailsRequest = (): AnyAction => ({
  type: TicketActions.GetGroupDetailsRequest,
})

export const setPageSizeForGroupingTickets = (pageSize: string) => ({
  type: TicketActions.SetGroupingPageSize,
  payload: pageSize,
})

export const getGroupDetailsResponse = (details: GetGroupDetailsResponse): AnyAction => ({
  type: TicketActions.GetGroupDetailsResponse,
  payload: details,
})

export const setGroupDetailsResponse = (
  details: GetGroupDetailsResponse,
  group: string,
): AnyAction => ({
  type: TicketActions.SetGroupDetailsResponse,
  payload: { ...details, group },
})
export const setGroupDetailsResponseEmpty = (
  details: GetGroupDetailsResponse,
  group: string,
): AnyAction => ({
  type: TicketActions.SetGroupDetailsResponseEmpty,
  payload: { ...details, group },
})

export const getGroupDetailsResponseFail = (error: ResponseError): AnyAction => ({
  type: TicketActions.GetGroupDetailsResponseFail,
  payload: { error },
})

export const setParentGroups = (parentGroups: ParentGroup[]): AnyAction => ({
  type: TicketActions.SetParentGroups,
  payload: parentGroups,
})

export const changeSelectedFilters = (filters: FormikValues): AnyAction => ({
  type: TicketActions.ChangeSelectedFilters,
  payload: filters,
})

export const collectFilterTemplateFields = (filterTemplateFields: {
  fieldName: string
  value: { id: string; displayName: string }
}): AnyAction => ({
  type: TicketActions.CollectFilterTemplateFields,
  payload: filterTemplateFields,
})

export const clearSelectedFilters = (): AnyAction => ({
  type: TicketActions.ClearSelectedFilters,
})

export const getSdFilterTemplatesRequest = (payload: {
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: TicketActions.GetSdFilterTemplatesRequest,
  payload,
})

export const setSdFilterTemplatesResponse = (
  payload: { id: string; name: string }[],
): AnyAction => ({
  type: TicketActions.SetSdFilterTemplateResponse,
  payload,
})

export const createSdFilterTemplateRequest = (payload: {
  filters: GetTicketsBodyFilterItem[]
  name: string
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: TicketActions.CreateSdFilterTemplateRequest,
  payload,
})

export const createSdFilterTemplateResponse = (payload: any): AnyAction => ({
  type: TicketActions.CreateSdFilterTemplateResponse,
  payload,
})

export const updateSdFilterTemplateRequest = (payload: {
  filters: any
  name: string
  windowType: WindowType
  id: string
  translate: any
}): AnyAction => ({
  type: TicketActions.UpdateSdFilterTemplateRequest,
  payload,
})

export const updateSdFilterTemplateResponse = (payload: {
  filters: any
  name: string
  windowType: WindowType
  id: string
  translate: any
}): AnyAction => ({
  type: TicketActions.UpdateSdFilterTemplateResponse,
  payload,
})

export const deleteSdFilterTemplateRequest = (payload: {
  id: string
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: TicketActions.DeleteSdFilterTemplateRequest,
  payload,
})

export const deleteSdFilterTemplateResponse = (payload: any): AnyAction => ({
  type: TicketActions.DeleteSdFilterTemplateResponse,
  payload,
})

export const getFiltersTemplatesRequest = (): AnyAction => ({
  type: TicketActions.GetFiltersTemplatesRequest,
})

// @todo replace any to FilterTemplate[]
export const getFiltersTemplatesResponse = (templates: any): AnyAction => ({
  type: TicketActions.GetFiltersTemplatesResponse,
  payload: templates,
})

export const saveFiltersTemplateRequest = (template: {
  name: string
  filter: FormikValues
}): AnyAction => ({
  type: TicketActions.SaveFiltersTemplateRequest,
  payload: { template },
})

export const editFiltersTemplateRequest = (template: FilterTemplate): AnyAction => ({
  type: TicketActions.EditFiltersTemplateRequest,
  payload: { template },
})
//
// export const editFiltersTemplateResponse = (template: FilterTemplate): AnyAction => ({
//   type: TicketActions.EditFiltersTemplateResponse,
//   payload: template,
// })
//
// export const editFiltersTemplateResponseFail = (error: ResponseError): AnyAction => ({
//   type: TicketActions.EditFiltersTemplateResponseFail,
//   payload: error,
// })

export const deleteFiltersTemplateRequest = (template: FilterTemplate): AnyAction => ({
  type: TicketActions.DeleteFilterTemplateRequest,
  payload: { template },
})

export const getSdGroupTemplatesRequest = (payload: string): AnyAction => ({
  type: TicketActions.GetSdGroupTemplatesRequest,
  payload,
})

export const getGroupingTemplatesRequest = (): AnyAction => ({
  type: TicketActions.GetGroupingTemplatesRequest,
})

// @todo replace any to GroupingTemplate[]
export const getGroupingTemplatesResponse = (templates: any): AnyAction => ({
  type: TicketActions.GetGroupingTemplatesResponse,
  payload: templates,
})

export const getGroupingTemplatesResponseFail = (error: ResponseError): AnyAction => ({
  type: TicketActions.GetGroupingTemplatesResponseFail,
  payload: error,
})

export const editSdGroupTemplateRequest = (payload: {
  template: UIGroupTemplate
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: TicketActions.EditSdGroupTemplateRequest,
  payload,
})

export const editGroupTemplateFromResponse = (payload: UIGroupTemplate): AnyAction => ({
  type: TicketActions.EditSdGroupTemplateFromResponse,
  payload,
})

export const deleteSdGroupTemplateRequest = (payload: {
  template: UIGroupTemplate
  windowType: WindowType
  translate: any
}): AnyAction => ({
  type: TicketActions.DeleteSdGroupTemplateRequest,
  payload,
})

export const deleteSdGroupTemplateResponseSuccess = (payload: {
  template: UIGroupTemplate
}): AnyAction => ({
  type: TicketActions.DeleteSdGroupTemplateResponseSuccess,
  payload,
})

export const editGroupingTemplateRequest = (template: GroupingTemplate): AnyAction => ({
  type: TicketActions.EditGroupingTemplateRequest,
  payload: { template },
})

// export const editSdGroupTemplateResponse = (payload: any): AnyAction => ({
//   type: TicketActions.EditSdGroupTemplateResponse,
//   payload,
// })

// export const editGroupingTemplateResponse = (template: GroupingTemplate): AnyAction => ({
//   type: TicketActions.EditGroupingTemplateResponse,
//   payload: template,
// })
//
// export const editGroupingTemplateResponseFail = (error: ResponseError): AnyAction => ({
//   type: TicketActions.EditGroupingTemplateResponseFail,
//   payload: error,
// })

export const saveGroupingTemplateResponse = (template: GroupingTemplate): AnyAction => ({
  type: TicketActions.SaveGroupingTemplateResponse,
  payload: template,
})

export const saveSdGroupTemplateRequest = (
  template: {
    windowType: WindowType
    name: string
    groupingLevels: ApiGroupTemplate[]
  },
  translate: any,
): AnyAction => ({
  type: TicketActions.SaveSdGroupingTemplateRequest,
  payload: { template, translate },
})

export const setGroupTemplates = (
  templates: UIGroupTemplate[],
  withAppendNewTemplate = false,
): AnyAction => ({
  type: TicketActions.SetGroupTemplates,
  payload: { templates, withAppendNewTemplate },
})

//
// export const saveGroupingTemplateResponseFail = (error: ResponseError): AnyAction => ({
//   type: TicketActions.SaveGroupingTemplateResponseFail,
//   payload: error,
// })

export const deleteGroupingTemplateRequest = (template: GroupingTemplate): AnyAction => ({
  type: TicketActions.DeleteGroupingTemplateRequest,
  payload: { template },
})

export const setCurrentFilterTemplate = (value: string): AnyAction => ({
  type: TicketActions.SetCurrentFilterTemplate,
  payload: value,
})

export const getTicketsConfigRequest = (): AnyAction => ({
  type: TicketActions.GetTicketsConfigRequest,
})

export const getTicketsConfigReponse = (
  typeOfTickets: TypeOfTickets,
  ticketsConfigData: TicketsConfigResponse,
): AnyAction => ({
  type: TicketActions.GetTicketsConfigResponse,
  payload: { typeOfTickets, config: ticketsConfigData.formConfig },
})

export const getTicketsConfigResponseFail = (error: ResponseError): AnyAction => ({
  type: TicketActions.GetTicketsConfigResponseFail,
  payload: error,
})

export const getTicketDetailsRequest = (id: string): AnyAction => ({
  type: TicketActions.GetTicketDetailsRequest,
  payload: { id },
})

export const getTicketDetailsResponse = ({ ticketDetails }: TicketDetailsResponse): AnyAction => ({
  type: TicketActions.GetTicketDetailsResponse,
  payload: ticketDetails,
})

export const getTicketDetailsResponseFail = (error: ResponseError): AnyAction => ({
  type: TicketActions.GetTicketDetailsResponseFail,
  payload: { error },
})

export const changeSorting = (sorting: { [key: string]: 'asc' | 'desc' } | null): AnyAction => ({
  type: TicketActions.ChangeSorting,
  payload: sorting,
})

export const changeGrouping = (groups: TableGroup[]): AnyAction => ({
  type: TicketActions.ChangeGrouping,
  payload: groups,
})

export const setTypeOfTickets = (typeOfTickets: string): AnyAction => ({
  type: TicketActions.SetTypeOfTickets,
  payload: typeOfTickets,
})

export const setTicketsRowsPerPage = (rowsCount: number): AnyAction => ({
  type: TicketActions.SetRowPerPage,
  payload: rowsCount,
})

export const setTicketsPage = (page: number): AnyAction => ({
  type: TicketActions.SetPage,
  payload: page,
})

export const clearGroupedTicketDetailsData = (): AnyAction => ({
  type: TicketActions.ClearGroupedTicketDetailsData,
})

export const setStringFilter = (filter: TableFilter): AnyAction => ({
  type: TicketActions.SetStringFilter,
  payload: filter,
})
