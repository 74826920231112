import { TableBodyCell, TableBodyCellContent } from '@/components/controls/AppTable/types'

export const getUniqueValuesFromRow = (
  keys: string[],
  data: TableBodyCell[],
  disableLongListIgnore?: boolean,
): Record<string, TableBodyCellContent[]> =>
  keys.reduce((accum, key) => {
    let set = new Set<TableBodyCellContent>()
    if (data) {
      for (let i = 0; i < data.length; i++) {
        const uncheckedRowKey = data[i][key] // type check
        set = new Set([
          ...Array.from(set),
          ...(Array.isArray(uncheckedRowKey) ? uncheckedRowKey : [uncheckedRowKey]),
        ])
      }
    }

    const uniqueItems = Array.from(set).filter((val: TableBodyCellContent) => val)

    const uniquerItems = uniqueItems.reduce((accum: { [key: string]: any }, cell) => {
      const rowKeyValue = typeof cell === 'object' ? cell.value : cell
      return {
        ...accum,
        [rowKeyValue]: cell,
      }
    }, {})
    if (!disableLongListIgnore && Object.keys(uniquerItems).length > 5) {
      return {
        ...accum,
        [key]: [],
      }
    }
    return {
      ...accum,
      [key]: Object.keys(uniquerItems).map(key => uniquerItems[key]),
    }
  }, {})
