import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getState = (state: AppState): fromReducer.State => state.vaultV1Analysis

export const getAnalysisFilter = createSelector(getState, state => state.filter)

export const getAnalysisSelectedChartsType = createSelector(
  getState,
  state => state.selectedChartsType,
)

export const getAnalysisCharts = createSelector(getState, state => state.charts)
export const getAnalysisChartsLoading = createSelector(getState, state => state.isChartsLoading)

export const getAnalysisModalState = createSelector(getState, ({ isFiltersModalOpen }) => ({
  isFiltersModalOpen,
}))
