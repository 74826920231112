import moment from 'moment'
import { AnyAction } from 'redux'

import {
  CitBalCashPoint,
  CitBalCashPointsFilter,
  CitBalTotalRemainder,
  ComCMReport,
  ComUuidValue,
  VaultRemainderUpdateAvailabilityEntry,
} from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants'
import { citBalCashPointsTableId as tableId } from '@/constants/cm/cmTableIds'
import { getTableConfig } from '@/helpers/tableLocalStorage'

export type State = {
  isFiltersModalOpen: boolean
  isColumnModalOpen: boolean
  isCashBalancingModalOpen: boolean
  isEventsViewerModalOpen: boolean
  isConfirmCloseModalOpen: boolean
  isAccountsModalOpen: boolean
  isReportsModalOpen: boolean
  isAssetVaultModalOpen: boolean

  confirmClose: {
    isCashOrderConfirming: boolean
    typeOfConfirm: 'Closed_NotCompleted' | 'Closed_NoAgentData' | null
  }

  cashPoints: {
    filter: CitBalCashPointsFilter
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined
    paging: {
      page: number
      pageSize: number
    }
    isLoading: boolean
    isLoaded: boolean
    error: string | null
    total: number
    data: CitBalCashPoint[]
    selectedCashPoint: CitBalCashPoint | null
    checkedCashPoints: { [key: string]: CitBalCashPoint }

    reports: {
      errorMessage?: string
      isLoading: boolean
      list: ComCMReport[]
      selectedReport: {
        isLoading: boolean
        errorMessage?: string
        bytes?: Blob | null
      }
    }
    selectedCashPointReports: {
      errorMessage?: string
      isLoading: boolean
      list: ComCMReport[]
      selectedReport: {
        isLoading: boolean
        errorMessage?: string
        bytes?: Blob | null
      }
    }
    assetItemBytes: string
    isZip: boolean
    isAssetItemDownloading: boolean
  }

  cashiers: ComUuidValue[]

  transactionButtonStatus: {
    data: { [key: string]: VaultRemainderUpdateAvailabilityEntry }
    isLoading: boolean
    isMixedOperation: boolean
    operationName?: string
  }

  vaultTransactionTotals: {
    data: { vaultName: string; totals: CitBalTotalRemainder[] }
    ids: string[]
    isLoading: boolean
  }
}

export const initialState: State = {
  isFiltersModalOpen: false,
  isColumnModalOpen: false,
  isCashBalancingModalOpen: false,
  isEventsViewerModalOpen: false,
  isConfirmCloseModalOpen: false,
  isAccountsModalOpen: false,
  isReportsModalOpen: false,
  isAssetVaultModalOpen: false,

  confirmClose: {
    isCashOrderConfirming: false,
    typeOfConfirm: null,
  },

  cashPoints: {
    sortColumn: undefined,
    sortOrder: undefined,
    paging: {
      page: 1,
      pageSize: getTableConfig({ tableId })?.rowPerPage || 50,
    },
    filter: {
      orderStatus: undefined,
      balDateFrom: moment()
        .subtract(1, 'months')
        .startOf('day')
        .format(),
      balDateTo: '',
      startCycleDateFrom: moment()
        .subtract(5, 'weeks')
        .startOf('day')
        .format(),
      endCycleDateTo: '',
      orderName: '',
    },
    isLoading: false,
    isLoaded: false,
    error: null,
    total: 0,
    data: [],
    selectedCashPoint: null,
    checkedCashPoints: {},

    reports: {
      errorMessage: '',
      isLoading: false,
      list: [],
      selectedReport: {
        isLoading: false,
        errorMessage: '',
        bytes: undefined,
      },
    },
    selectedCashPointReports: {
      errorMessage: '',
      isLoading: false,
      list: [],
      selectedReport: {
        isLoading: false,
        errorMessage: '',
        bytes: undefined,
      },
    },
    assetItemBytes: '',
    isZip: false,
    isAssetItemDownloading: false,
  },

  cashiers: [],

  transactionButtonStatus: {
    data: {},
    isLoading: false,
    isMixedOperation: false,
    operationName: undefined,
  },

  vaultTransactionTotals: {
    data: { vaultName: '', totals: [] },
    ids: [],
    isLoading: false,
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case CMActions.CitBalSetFiltersModalOpen:
      return {
        ...state,
        isFiltersModalOpen: payload,
      }
    case CMActions.CitBalSetConfirmModalOpen:
      return {
        ...state,
        isConfirmCloseModalOpen: payload.isOpen,
        confirmClose: {
          ...state.confirmClose,
          typeOfConfirm: payload.typeOfConfirm,
        },
      }
    case CMActions.CitBalSetAccountsModalOpen:
      return {
        ...state,
        isAccountsModalOpen: payload,
      }
    case CMActions.CitBalSetReportsModalOpen:
      return {
        ...state,
        isReportsModalOpen: payload,
      }
    case CMActions.CitBalSetCashPointsFilter:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          filter: {
            ...state.cashPoints.filter,
            ...payload,
          },
        },
      }

    case CMActions.CitBalGetCashPointsRequest:
    case CMActions.CitBalSetVaultTransactionRequest:
    case CMActions.CitBalRevertTransactionRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: true,
        },
      }
    case CMActions.CitBalGetCashPointsResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: false,
          isLoaded: true,
          data: payload.data,
          total: payload.total,
        },
      }
    case CMActions.CitBalGetCashPointsFail:
    case CMActions.ComSetError:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: false,
          isLoaded: true,
          error: payload,
        },
      }
    case CMActions.CitBalSetSelectedCashPoint:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          selectedCashPoint: payload,
        },
      }
    case CMActions.CitBalResetCashPointsPage:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          paging: {
            ...state.cashPoints.paging,
            page: initialState?.cashPoints?.paging?.page || 1,
          },
        },
      }
    case CMActions.CitBalClearAllCashPoints: {
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          checkedCashPoints: {},
        },
      }
    }
    case CMActions.CitBalSetCashPointsPaging:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          paging: {
            ...state.cashPoints.paging,
            ...payload,
          },
        },
      }
    case CMActions.CitBalToggleCashPoint: {
      const newCheckedCashPoints = { ...state.cashPoints.checkedCashPoints }
      const soughtCP = newCheckedCashPoints[payload?.id]

      if (!soughtCP) {
        newCheckedCashPoints[payload?.id] = payload
      } else {
        delete newCheckedCashPoints[payload?.id]
      }
      return {
        ...state,
        cashPoints: {
          ...state?.cashPoints,
          checkedCashPoints: newCheckedCashPoints,
        },
      }
    }

    case CMActions.CitBalCheckAllCashPoints: {
      const res: State['cashPoints']['checkedCashPoints'] = {}
      const allCashPoints = payload
      for (const cashPoint of allCashPoints) {
        if (cashPoint?.id) {
          res[cashPoint.id] = cashPoint
        }
      }
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          checkedCashPoints: res,
        },
      }
    }
    case CMActions.CitBalOpenColumnsSettingsModal:
      return {
        ...state,
        isColumnModalOpen: payload,
      }
    case CMActions.CitBalSetCashBalancingModalOpen:
      return {
        ...state,
        isCashBalancingModalOpen: payload,
      }
    case CMActions.CitBalSetAssetVaultModalOpen:
      return {
        ...state,
        isAssetVaultModalOpen: payload,
      }
    case CMActions.CitBalSetEventsViewModalOpen:
      return {
        ...state,
        isEventsViewerModalOpen: payload,
      }
    case CMActions.CitBalSortTable:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          ...payload,
          isLoading: true,
        },
      }

    case CMActions.CitBalCloseCashOrderRequest:
      return {
        ...state,
        confirmClose: {
          ...state.confirmClose,
          isCashOrderConfirming: true,
        },
      }

    case CMActions.CitBalCloseCashOrderResponse:
    case CMActions.CitBalCloseCashOrderFail:
      return {
        ...state,
        confirmClose: {
          ...state.confirmClose,
          isCashOrderConfirming: false,
        },
      }

    case CMActions.CitBalGetReportsRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            isLoading: true,
            list: [],
            errorMessage: '',
            selectedReport: initialState.cashPoints.reports.selectedReport,
          },
        },
      }
    case CMActions.CitBalGetReportsResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            isLoading: false,
            list: payload,
            errorMessage: '',
          },
        },
      }
    case CMActions.CitBalGetReportsFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            isLoading: false,
            list: [],
            errorMessage: payload,
          },
        },
      }

    case CMActions.CitBalGetReportRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            selectedReport: {
              ...state.cashPoints.reports.selectedReport,
              isLoading: true,
              errorMessage: '',
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.CitBalGetReportResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            selectedReport: {
              ...state.cashPoints.reports.selectedReport,
              isLoading: false,
              errorMessage: '',
              bytes: payload,
            },
          },
        },
      }
    case CMActions.CitBalGetReportFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            selectedReport: {
              ...state.cashPoints.reports.selectedReport,
              isLoading: false,
              errorMessage: payload,
              bytes: undefined,
            },
          },
        },
      }

    case CMActions.CitBalSetInitialReports:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          selectedCashPointReports: initialState.cashPoints.selectedCashPointReports,
        },
      }

    case CMActions.CitBalGetDialogReportsRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          selectedCashPointReports: {
            ...state.cashPoints.selectedCashPointReports,
            isLoading: true,
            list: [],
            errorMessage: '',
            selectedReport: initialState.cashPoints.selectedCashPointReports.selectedReport,
          },
        },
      }
    case CMActions.CitBalGetDialogReportsResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          selectedCashPointReports: {
            ...state.cashPoints.selectedCashPointReports,
            isLoading: false,
            list: payload,
            errorMessage: '',
          },
        },
      }
    case CMActions.CitBalGetDialogReportsFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          selectedCashPointReports: {
            ...state.cashPoints.selectedCashPointReports,
            isLoading: false,
            list: [],
            errorMessage: payload,
          },
        },
      }

    case CMActions.CitBalGetDialogReportRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          selectedCashPointReports: {
            ...state.cashPoints.selectedCashPointReports,
            selectedReport: {
              ...state.cashPoints.selectedCashPointReports.selectedReport,
              isLoading: true,
              errorMessage: '',
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.CitBalGetDialogReportResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          selectedCashPointReports: {
            ...state.cashPoints.selectedCashPointReports,
            selectedReport: {
              ...state.cashPoints.selectedCashPointReports.selectedReport,
              isLoading: false,
              errorMessage: '',
              bytes: payload,
            },
          },
        },
      }
    case CMActions.CitBalGetDialogReportFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          selectedCashPointReports: {
            ...state.cashPoints.selectedCashPointReports,
            selectedReport: {
              ...state.cashPoints.selectedCashPointReports.selectedReport,
              isLoading: false,
              errorMessage: payload,
              bytes: undefined,
            },
          },
        },
      }

    case CMActions.CitBalSetIsZip:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isZip: payload,
        },
      }

    case CMActions.CitBalFetchAssetItemReportRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isAssetItemDownloading: true,
        },
      }

    case CMActions.CitBalFetchAssetItemReportResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isAssetItemDownloading: false,
        },
      }
    case CMActions.CitBalFetchAssetItemReportFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isAssetItemDownloading: false,
        },
      }
    case CMActions.CitBalGetCashierResponse:
      return {
        ...state,
        cashiers: payload,
      }

    case CMActions.CitBlnTransactionButtonStatusRequest:
      return {
        ...state,
        transactionButtonStatus: {
          ...state.transactionButtonStatus,
          isLoading: true,
        },
      }
    case CMActions.CitBlnTransactionButtonStatusResponse: {
      const data: { [key: string]: VaultRemainderUpdateAvailabilityEntry } = {}

      payload.forEach((element: VaultRemainderUpdateAvailabilityEntry) => {
        if (element?.coid) {
          data[element.coid] = element
        }
      })

      return {
        ...state,
        transactionButtonStatus: {
          ...state.transactionButtonStatus,
          data,
          isLoading: false,
        },
      }
    }
    case CMActions.CitBlnTransactionButtonStatusFail:
      return {
        ...state,
        transactionButtonStatus: {
          ...state.transactionButtonStatus,
          isLoading: false,
        },
      }

    case CMActions.CitBlnSetMixedOperation:
      return {
        ...state,
        transactionButtonStatus: {
          ...state.transactionButtonStatus,
          ...payload,
        },
      }

    case CMActions.CitBalGetVaultTransactionTotalsRequest:
      return {
        ...state,
        vaultTransactionTotals: {
          ...state.vaultTransactionTotals,
          isLoading: true,
        },
      }
    case CMActions.CitBalGetVaultTransactionTotalsResponse:
      return {
        ...state,
        vaultTransactionTotals: {
          ...state.vaultTransactionTotals,
          data: { vaultName: payload.data.vaultName || '', totals: payload.data.totals || [] },
          ids: payload.ids,
          isLoading: false,
        },
      }
    case CMActions.CitBalGetVaultTransactionTotalsFail:
      return {
        ...state,
        vaultTransactionTotals: {
          ...state.vaultTransactionTotals,
          isLoading: false,
        },
      }

    case CMActions.SignOut:
      return initialState
    default:
      return state
  }
}
