import { AnyAction } from 'redux'

import { MonCashPoint } from '@/api/cm/swaggerGeneratedApi'
import { TableFilterDate } from '@/components/controls/AppTable/types'
import { CMActions } from '@/constants/actions/index'
import { KeyPressActionName } from '@/types/cm'
import { CashPointDetails, GetCashPointDetailsRequestBody } from '@/types/cm/monitoring'

import {
  ComCMReport,
  MonCashPointCassetteDetail,
  MonCashPointsFilter,
  MonCashPointsRequest,
  MonCassetteChartsRequest,
  MonCassetteEntry,
} from './../../../api/cm/swaggerGeneratedApi/index'
import { State } from './reducer'

export const getCashPointsForMainRequest = (payload?: MonCashPointsRequest): AnyAction => ({
  type: CMActions.GetCashPointsForMainRequest,
  payload,
})

export const getCashPointsForMainResponse = (payload: any): AnyAction => ({
  type: CMActions.GetCashPointsForMainResponse,
  payload,
})

export const getCashPointsForMainResponseFail = (payload?: any): AnyAction => ({
  type: CMActions.GetCashPointsForMainResponseFail,
  payload,
})

export const setMonSelectedCashPoint = (payload: MonCashPoint | null): AnyAction => ({
  type: CMActions.MonSetSelectedCashPoint,
  payload,
})

export const setCPDetailsTabNumber = (payload: string): AnyAction => ({
  type: CMActions.SetCPDetailsTabNumber,
  payload,
})

export const getCashPointDetailsRequest = (
  payload?: GetCashPointDetailsRequestBody,
): AnyAction => ({
  type: CMActions.GetCashPointDetailsRequest,
  payload,
})

// Cassettes ------------------------------------------------------------
export const getCashPointCassettesRequest = (): AnyAction => ({
  type: CMActions.GetCashPointCassettesRequest,
})

export const getCashPointCassettesResponse = (payload?: MonCassetteEntry[]): AnyAction => ({
  type: CMActions.GetCashPointCassettesResponse,
  payload,
})

export const getCashPointCassettesFail = (): AnyAction => ({
  type: CMActions.GetCashPointCassettesFail,
})

// Cassettes Chart ------------------------------------------------------------
export const getMonCassettesChartsRequest = (payload?: MonCassetteChartsRequest): AnyAction => ({
  type: CMActions.GetMonCassettesChartsRequest,
  payload,
})
export const getMonCassettesChartsResponse = (
  cassettes: MonCashPointCassetteDetail[],
): AnyAction => ({
  type: CMActions.GetMonCassettesChartsResponse,
  payload: cassettes,
})
export const getMonCassettesChartsFail = (): AnyAction => ({
  type: CMActions.GetMonCassettesChartsFail,
})

// CIN Chart ------------------------------------------------------------
export const getMonCINChartsRequest = (payload?: MonCassetteChartsRequest): AnyAction => ({
  type: CMActions.GetMonCINChartsRequest,
  payload,
})
export const getMonCINChartsResponse = (cassettes: MonCashPointCassetteDetail[]): AnyAction => ({
  type: CMActions.GetMonCINChartsResponse,
  payload: cassettes,
})
export const getMonCINChartsFail = (): AnyAction => ({
  type: CMActions.GetMonCINChartsFail,
})

export const setMonCashPointDetailsCurrencyFilter = (
  payload: any,
  // payload: State['cashPointDetails']['filter']['currency'],
): AnyAction => ({
  type: CMActions.SetMonCashPointDetailsCurrencyFilter,
  payload,
})

export const setMonCashPointDetailsCassettesFilter = (
  payload: any,
  // payload: State['cashPointDetails']['filter'],
): AnyAction => ({
  type: CMActions.SetMonCashPointDetailsCassettesFilter,
  payload,
})

export const setMonCashPointDetailsFilter = (
  payload: any,
  // payload: State['cashPointDetails']['filter'],
): AnyAction => ({
  type: CMActions.SetMonCashPointDetailsFilter,
  payload,
})

export const setMonCPDetailsCinFilter = (
  payload: State['cashPointDetails']['cinFilter'],
): AnyAction => ({
  type: CMActions.SetMonCashPointDetailsCinFilter,
  payload,
})

export const getCashPointDetailsResponse = (payload: CashPointDetails): AnyAction => ({
  type: CMActions.GetCashPointDetailsResponse,
  payload,
})

export const getCashPointDetailsResponseFail = (message?: string): AnyAction => ({
  type: CMActions.GetCashPointDetailsResponseFail,
  payload: message,
})

export const alternateColumnModal = (value: boolean): AnyAction => ({
  type: CMActions.AlternateModal,
  payload: value,
})

export const ToggleColumns = (cols: string[]): AnyAction => ({
  type: CMActions.ToggleColumns,
  payload: cols,
})

export const setFiltersModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.SetFiltersModalOpen,
  payload: isOpen,
})

export const setDocumentModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.SetDocumentModalOpen,
  payload: isOpen,
})

export const setSelectedDate = (date: TableFilterDate): AnyAction => ({
  type: CMActions.SetSelectedDate,
  payload: date,
})

export const setMonCashPointsPagingRequest = (payload: {
  page?: number
  pageSize?: number
}): AnyAction => ({
  type: CMActions.SetMonCashPointsPagingRequest,
  payload,
})

export const setMonCashPointsPagingResponse = (payload: {
  page?: number
  pageSize?: number
}): AnyAction => ({
  type: CMActions.SetMonCashPointsPagingResponse,
  payload,
})

export const setMonCashPointsFilter = (payload: MonCashPointsFilter): AnyAction => ({
  type: CMActions.SetMonitoringFilter,
  payload,
})

export const setCashPointsFilter = (payload: MonCashPointsRequest): AnyAction => ({
  type: CMActions.SetCashPointsFilter,
  payload,
})

export const getMonitoringReportsRequest = (): AnyAction => ({
  type: CMActions.GetMonitoringReportsRequest,
})

export const getMonitoringReportsResponse = (payload: any): AnyAction => ({
  type: CMActions.GetMonitoringReportsResponse,
  payload,
})

export const getMonitoringReportsResponseFail = (message?: string): AnyAction => ({
  type: CMActions.GetMonitoringReportsResponseFail,
  payload: message,
})

export const getMonitoringReportRequest = (payload?: ComCMReport): AnyAction => ({
  type: CMActions.GetMonitoringReportRequest,
  payload,
})

export const getMonitoringReportResponse = (payload: any): AnyAction => ({
  type: CMActions.GetMonitoringReportResponse,
  payload,
})

export const getMonitoringReportResponseFail = (message?: string): AnyAction => ({
  type: CMActions.GetMonitoringReportResponseFail,
  payload: message,
})

export const getMonitoringSettingsRequest = (): AnyAction => ({
  type: CMActions.GetMonitoringSettingsRequest,
})

export const refreshMonitoring = (): AnyAction => ({
  type: CMActions.RefreshMonitoring,
})

export const setAllCurrenciesValue = (payload: boolean): AnyAction => ({
  type: CMActions.SetAllCurenciesValueAction,
  payload,
})

export const toggleCashPoint = (cashPoint: MonCashPoint): AnyAction => ({
  type: CMActions.MonToggleCashPoint,
  payload: cashPoint,
})

export const checkAllCashPoints = (cashPoints: MonCashPoint[]): AnyAction => ({
  type: CMActions.MonCheckAllCashPoints,
  payload: cashPoints,
})

export const clearCheckedCashPoints = (): AnyAction => ({
  type: CMActions.MonClearCheckedCashPoints,
})

export const setPlanStatus = (payload: {
  checkedCashPoints: State['cashPoints']['checkedCashPoints']
  status: 'accept' | 'decline'
}): AnyAction => ({
  type: CMActions.MonSetPlanStatus,
  payload,
})

export const resetMonCashPointsPage = (): AnyAction => ({
  type: CMActions.MonResetCashPointsPage,
})

export const sortMonCashPointsTable = (payload: {
  sortColumn: string | undefined
  sortOrder: 'asc' | 'desc' | undefined
}): AnyAction => ({
  type: CMActions.MonSortCashPointsTable,
  payload,
})

export const refreshMonCashPointRequest = (id: string): AnyAction => ({
  type: CMActions.MonRefreshCashPointRequest,
  payload: id,
})

export const refreshMonCashPointResponse = (payload: MonCashPoint): AnyAction => ({
  type: CMActions.MonRefreshCashPointResponse,
  payload,
})

export const handleCassettesUpperLimit = (payload: boolean): AnyAction => ({
  type: CMActions.MonHandleCassettesUpperLimit,
  payload,
})

export const applyMonSelectedList = (payload: string[]): AnyAction => ({
  type: CMActions.MonApplySelectedList,
  payload,
})

export const removeMonSelectedList = (payload: string[]): AnyAction => ({
  type: CMActions.MonRemoveSelectedList,
  payload,
})

export const addMonFilterIds = (payload: string[]): AnyAction => ({
  type: CMActions.MonAddFilterIds,
  payload,
})

export const removeMonFilterIds = (payload: string[]): AnyAction => ({
  type: CMActions.MonRemoveFilterIds,
  payload,
})

export const setMonDetailsFilterModalOpen = (payload: boolean): AnyAction => ({
  type: CMActions.MonSetDetailsFilterModalOpen,
  payload,
})

export const handleMonKeyPress = (actionName: KeyPressActionName): AnyAction => ({
  type: CMActions.MonHandleKeyPress,
  payload: actionName,
})
