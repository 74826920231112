import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  styleText: {
    color: '#25ADBF',
  },
  menuList: {
    padding: spacing(0),
    '& li': {
      padding: spacing(0),
    },
  },
  menuSpacing: {
    display: 'flex',
    padding: spacing(0, 1),
    //  width: '200px',
    flex: 1,
    flexDirection: 'column',
    overflow: 'auto',
    '&:focus': {
      outline: 'none',
    },
  },
  driver: {
    width: '100%',
  },
}))
