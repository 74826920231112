import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getDeviceState = (state: AppState): fromReducer.State => state.admDevice

export const getError = createSelector(getDeviceState, state => state.error)

// Device
export const getDevice = createSelector(getDeviceState, state => state.device)
export const getIsFetchingDevice = createSelector(getDeviceState, state => state.isFetchingDevice)

// Device view info
export const getDeviceViewInfo = createSelector(getDeviceState, state => state.deviceViewInfo)
export const getIsFetchingDeviceViewInfo = createSelector(
  getDeviceState,
  state => state.isFetchingDeviceViewInfo,
)

// Hardware
export const getHardware = createSelector(getDeviceState, state => state.hardware)
export const getIsFetchingHardware = createSelector(
  getDeviceState,
  state => state.isFetchingHardware,
)

// Group actions
export const getGroupActionsResponse = createSelector(getDeviceState, state => state.groupActions)

// Address
export const getAddress = createSelector(getDeviceState, state => state.address)
export const getGeo = createSelector(getDeviceState, state => state.geo)
export const getIsFetchingAddress = createSelector(getDeviceState, state => state.isFetchingAddress)

// Related companies
export const getRelatedCompanies = createSelector(getDeviceState, state => state.companies)
export const getIsFetchingRelatedCompanies = createSelector(
  getDeviceState,
  state => state.isFetchingRelatedCompanies,
)

export const getIsCreatingNewDevice = createSelector(
  getDeviceState,
  state => state.isCreatingNewDevice,
)

// Service info
export const getMerchantLunoByAcquirers = createSelector(
  getDeviceState,
  state => state.serviceInfo.merchantLunoByAcquirers,
)
export const getIsFetchingMerchantLunoByAcquirers = createSelector(
  getDeviceState,
  state => state.isFetchingMerchantLunoByAcquirers,
)

export const getServiceInfo = createSelector(getDeviceState, state => state.serviceInfo.serviceInfo)

// Attached files
export const getAttachedFilesData = createSelector(getDeviceState, state => state.attachedFiles)

export const getAttachedFiles = createSelector(getDeviceState, state =>
  state.attachedFiles ? state.attachedFiles.deviceAttachedFileList : [],
)
export const getAttachedFilesPagination = createSelector(getDeviceState, state =>
  state.attachedFiles
    ? state.attachedFiles.page
    : {
        pageNo: 1,
        pageCount: 1,
        pageSize: 10,
        totalRecordCount: 0,
      },
)

export const getIsFetchingAttachedFilesData = createSelector(
  getDeviceState,
  state => state.isFetchingAttachedFiles,
)

// Configuration
export const getConfiguration = createSelector(getDeviceState, state =>
  state.configuration ? state.configuration.configurationList : [],
)
export const getConfigurationPagination = createSelector(getDeviceState, state =>
  state.configuration
    ? state.configuration.page
    : {
        pageNo: 0,
        pageCount: 0,
        pageSize: 0,
        totalRecordCount: 0,
      },
)

export const getIsFetchingConfiguration = createSelector(
  getDeviceState,
  state => state.isFetchingConfiguration,
)

// Schedule
export const getSchedule = createSelector(getDeviceState, state => state.schedule)
export const getIsFetchingSchedule = createSelector(
  getDeviceState,
  state => state.isFetchingSchedule,
)
