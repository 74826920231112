import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles'
import { StylesProps } from './types'

export const useClasses = makeStyles<Theme, StylesProps>(
  ({ palette, spacing, typography }): {} => ({
    item: ({ newNotification }: any) => ({
      padding: spacing(2, 0),
      paddingLeft: spacing(2.2),
      paddingRight: spacing(1.3),
      position: 'relative',
      borderColor: `${spacing(0.5)}px solid ${newNotification ? palette.primary.main : '#ffffff'}`,
      cursor: 'pointer',

      '&::before': {
        content: '""',
        width: '100%',
        height: spacing(0.1),
        position: 'absolute',
        top: 0,
        right: 0,
        background: palette.grey[100],
        zIndex: 1,
      },
      '&::after': {
        content: '""',
        width: '100%',
        height: spacing(0.1),
        position: 'absolute',
        bottom: 0,
        right: 0,
        background: palette.grey[100],
      },
      '&:hover': {
        backgroundColor: palette.primary.light,
      },
    }),
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      padding: spacing(0.3, 0),
    },
    name: {
      fontWeight: typography.fontWeightBold,
      fontSize: spacing(1.9),
    },
    date: {
      fontSize: spacing(1.5),
      color: palette.grey[500],
    },
    description: {
      padding: spacing(0.3, 0),
      fontWeight: typography.fontWeightRegular,
    },
    active: {
      borderLeft: `${spacing(0.5)}px solid ${palette.primary.main}`,
    },
  }),
)
