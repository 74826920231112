import { RouteComponentProps } from 'react-router-dom'

export interface Props extends RouteComponentProps<any> {
  handleClose: () => void
  handleOpenPsrDetails: () => void
  handleSetCurrentTicketId?: (ticketId: number) => void
  open?: boolean
  testIdPrefix?: string
  saveWithSendHandleMode?: boolean
}

export const SERVICE_TYPES = {
  installation: '1',
  dismantling: '2',
  replace: '3',
  locationChange: '4',
  parametersUpdate: '5',
  staffTraining: '6',
}

export interface ServiceTableData {
  name: string
  service: string
  priority: string
  terminalId: string
  fullAddress: string
}

export interface POSServiceTypeItem {
  grantedOperations: string[]
  posFormDataParameters: {
    amountbc: string | null
    cardtypeid: number | null
    currencyid: number | null
    debtamountbc: string | null
    deviceid: number | null
    discountamountbc: string | null
    documentid: number | null
    documentitemid: number | null
    duration: number | null
    fineamountbc: string | null
    fromdate: string | null
    invoiceno: number | null
    itemno: number | null
    notes: string | null
    paidamountbc: string | null
    posaddfunction: string | null
    posaquirerluno: string | null
    poscarddataentryid: string | null
    poscity: string | null
    poscityid: number | null
    posconnectiontypeid: string | null
    poscontactperson: string | null
    poscountryid: number | null
    posfulladdress: string | null
    posintegrationwitherc: false
    posmcccode: string | null
    posmerchantid: string | null
    posmerchantid_amex: number | null
    posphone: string | null
    pospriorityid: string | null
    posreconcilationtime: string | null
    posregion: string | null
    posregionid: number | null
    posserviceid: number | null
    posservicestartdate: string | null
    possimofclient: boolean | null
    posstate: number | null
    posstateid: number | null
    posstreet: number | null
    posterminalid_amex: number | null
    posterminaltranstypeid: string | null
    posterminaltypeid: string | null
    posterminaluid: number | null
    posweekday1: number | null
    posweekday1fromhour: number | null
    posweekday1frommin: number | null
    posweekday1tohour: number | null
    posweekday1tomin: number | null
    posweekday2: number | null
    posweekday2fromhour: number | null
    posweekday2frommin: number | null
    posweekday2tohour: number | null
    posweekday2tomin: number | null
    posweekday3: number | null
    posweekday3fromhour: number | null
    posweekday3frommin: number | null
    posweekday3tohour: number | null
    posweekday3tomin: number | null
    posweekday4: number | null
    posweekday4fromhour: number | null
    posweekday4frommin: number | null
    posweekday4tohour: number | null
    posweekday4tomin: number | null
    posweekday5: number | null
    posweekday5fromhour: number | null
    posweekday5frommin: number | null
    posweekday5tohour: number | null
    posweekday5tomin: number | null
    posweekday6: number | null
    posweekday6fromhour: number | null
    posweekday6frommin: number | null
    posweekday6tohour: number | null
    posweekday6tomin: number | null
    posweekday7: number | null
    posweekday7fromhour: number | null
    posweekday7frommin: number | null
    posweekday7tohour: number | null
    posweekday7tomin: number | null
    prepaidamount: number | null
    pricebc: number | null
    quantity: number | null
    refdocumentitemid: number | null
    softlanguageid: number | null
    subject: string | null
    systate: number | null
    todate: string | null
    totamountbc: number | null
    vatamountbc: number | null
    vatpercent: number | null
  }
}
