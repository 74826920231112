import React, { ReactElement, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@/components/controls/Button/index'
import { Done, Send } from '@material-ui/icons'
import { useClasses } from './styles'
import { Props } from './types'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import Test from 'react-test-attributes'
import { getTestId } from '@/utils'
import Loader from '@/components/blocks/Loader'
import { useSelector } from 'react-redux'
import { getSavedPOSTicketGrantedOperations } from '@/store/sd/drawerActions/selectors/newPOS'
import { GrantedOperations } from '@/types'

const FooterWithActionButtons = ({
  isValid,
  isOpenNewMerchantForm,
  isSavingPOSTicket,
  handleSave,
  values,
  testIdPrefix,
  hasServices,
}: Props): ReactElement => {
  const classes = useClasses()
  const idPrefix = `${testIdPrefix}-footer-with-action-buttons`
  const testId = getTestId(idPrefix)
  const savedPOSTicketGrantedOperations = useSelector(getSavedPOSTicketGrantedOperations)

  const isDisabledSendButton = useMemo((): boolean => {
    if (!isValid || !hasServices) {
      return true
    }

    if (savedPOSTicketGrantedOperations.length) {
      return !savedPOSTicketGrantedOperations.includes(GrantedOperations.REQUEST_SEND)
    }

    return false
  }, [isValid, savedPOSTicketGrantedOperations, hasServices])

  const isDisabledSaveButton = useMemo((): boolean => {
    if ((!isValid || !hasServices) && !isOpenNewMerchantForm) {
      return true
    }

    if (!isValid && isOpenNewMerchantForm) {
      return true
    }

    return false
  }, [isValid, isOpenNewMerchantForm, hasServices])

  return (
    <Test id={testId(0)}>
      <div className={clsx(classes.wrapper, classes.buttons)}>
        <Test id={testId(1)}>
          <Box overflow="hidden">
            <Grid justify="space-between" container>
              <Grid item>
                <Test id={testId(2)}>
                  <Typography variant="body2">
                    <FormattedMessage id="title.fieldsWith" defaultMessage="Fields with" />
                    &nbsp;
                    <span className={classes.asterisk}>*</span>
                    &nbsp;
                    <FormattedMessage id="title.areRequired" defaultMessage="are required!" />
                  </Typography>
                </Test>
              </Grid>
              <Grid item>
                <Grid container justify="flex-end" spacing={2}>
                  <Grid className={classes.saveWrap} item>
                    {isSavingPOSTicket && (
                      <div className={classes.loaderWrapper}>
                        <Loader inline />
                      </div>
                    )}
                    <Test id={testId(3)}>
                      <Button
                        onClick={handleSave(values)}
                        disabled={isDisabledSaveButton}
                        startIcon={<Done />}
                        variant="contained"
                        width="md"
                      >
                        <FormattedMessage id="action.save" defaultMessage="Save" />
                      </Button>
                    </Test>
                  </Grid>
                  <Grid item>
                    <Test id={testId(4)}>
                      <Button
                        onClick={handleSave(values, true)}
                        disabled={isDisabledSendButton}
                        startIcon={<Send />}
                        variant="contained"
                        width="md"
                      >
                        <FormattedMessage id="action.saveAndSend" defaultMessage="Save & send" />
                      </Button>
                    </Test>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Test>
      </div>
    </Test>
  )
}

export default FooterWithActionButtons
