import queryString from 'query-string'

import {
  ComCMReport,
  DocStagesResponse,
  OrdAssignRequest,
  OrdGroupRequest,
  OrdGroupResponse,
  OrdOrder,
  OrdOrderRefRequest,
  OrdOrdersRequest,
  OrdOrdersResponse,
  OrdReportsRequest,
  OrdUnGroupRequest,
  PoliceDriver,
  PoliceOperation,
  PoliceOrderStatusesRequest,
  PoliceOrderStatusResponse,
} from '@/api/cm/swaggerGeneratedApi'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class OrdersApi {
  private static requestService = getRequestServiceCM()

  public static async getReports(payload: OrdReportsRequest): Promise<ComCMReport[]> {
    try {
      return await OrdersApi.requestService.post('/orders/reports', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async groupOrders(payload: OrdGroupRequest): Promise<OrdGroupResponse> {
    try {
      return await OrdersApi.requestService.post('/orders/group', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async ungroupOrders(payload: OrdUnGroupRequest): Promise<void> {
    try {
      return await OrdersApi.requestService.post('/orders/ungroup', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async assignCashierAndCollector(payload: OrdAssignRequest): Promise<void> {
    try {
      return await OrdersApi.requestService.post('/orders/assign', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async approveOrder(payload: OrdOrderRefRequest): Promise<void> {
    try {
      return await OrdersApi.requestService.post('/orders/approve', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async sendToExecuteOrder(payload: OrdOrderRefRequest): Promise<void> {
    try {
      return await OrdersApi.requestService.post('/orders/sendToExecute', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getOrders(
    paging: { page: number; pageSize: number },
    requestBody: OrdOrdersRequest,
    sortColumn?: string | undefined,
    sortOrder?: 'asc' | 'desc' | undefined,
  ): Promise<OrdOrdersResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/orders/orders',
          query: {
            pageSize: `${paging.pageSize}`,
            page: `${paging.page}`,
            sortColumn,
            sortOrder,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await OrdersApi.requestService.post(url, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getOrder(id: string): Promise<OrdOrder> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/orders/order',
          query: { guid: `${id}` },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await OrdersApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getOrderStagesDoc(id: string): Promise<DocStagesResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/doc/stages',
          query: { id },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await OrdersApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async sendPoliceOperations(payload: PoliceOperation): Promise<void> {
    try {
      return await OrdersApi.requestService.post('/orders/sendPoliceOperations', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getPoliceDrivers(): Promise<PoliceDriver[]> {
    try {
      return await OrdersApi.requestService.get('/orders/drivers')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getPoliceStatuses(
    payload: PoliceOrderStatusesRequest,
  ): Promise<PoliceOrderStatusResponse[]> {
    try {
      return await OrdersApi.requestService.post('/orders/policeStatuses', payload)
    } catch (e) {
      throw new Error(e)
    }
  }
}
