import { TableHead, TableRow } from '@material-ui/core'
import clsx from 'clsx'
import React, { FC, Fragment, ReactElement, useCallback, useMemo } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { useDraggable, useSort, useTableWithBorder } from '../../hooks'
import { HeaderCell, useCommonClasses } from '../commonStyles'
import { CollapseHeadCell, DraggableHeadCell, SelectAllCell, TwiceColumnsRow } from './components'
import { useClasses } from './styles'
import { CMTableHeadProps, RenderDraggableHeadCellProps } from './types'

export const CMTableHead: FC<CMTableHeadProps> = React.memo((props: CMTableHeadProps) => {
  const {
    tableClasses,
    localFixedColumns,
    localColumns,
    tableId,

    headerConfig,

    twiceColumns,

    isFirstEmptyCell = false,

    // Collapse
    isAllRowsOpened,
    handleCollapseAll,

    // SelectAll
    checkedLength,
    dataWithCheckboxLength,
    onSelectAll,

    cellWithNumberValue,

    setKeys,

    headerCellChild,
    getTwiceColumnTitles,
    // sort
    columnsToSort,
    handleSort,

    // sorter
    sortersColumns,

    fitTableContent,
  } = props

  const classes = useClasses()
  const commonClasses = useCommonClasses()

  const { handleDragEnd } = useDraggable({ setKeys, localFixedColumns, tableId })
  const { order, orderBy, handleSortColumn } = useSort({ handleSort })
  const isBorder = useTableWithBorder()

  const tableClassesMemo = useMemo(
    () => ({
      fixed: tableClasses?.fixed,
      tableHeadCell: tableClasses?.tableHeadCell,
      tableHeadCellText: tableClasses?.tableHeadCellText,
    }),
    [tableClasses],
  )

  const borderCellClasses = useMemo(() => (isBorder ? commonClasses.borderCell : undefined), [
    isBorder,
    commonClasses,
  ])

  const fixedAndBorderProps = useMemo(
    () => ({
      borderCellClasses,
      tableClasses: tableClassesMemo,
    }),
    [borderCellClasses, tableClassesMemo],
  )

  const renderDraggableHeadCell = useCallback(
    ({ key, ...rest }: RenderDraggableHeadCellProps) => {
      const localProps = {
        value: Object.keys(headerConfig).length ? headerConfig[key] : key,
        colSpan: twiceColumns.includes(key) ? 2 : undefined,
        sortDirection: orderBy === key && order,
        withSort: (columnsToSort || []).some(column => column === key),
        isSortersColumn: sortersColumns.includes(key),
        isNumberCell: cellWithNumberValue.includes(key),
      }

      return (
        <DraggableHeadCell
          {...fixedAndBorderProps}
          _key={key}
          handleSort={handleSortColumn}
          {...rest}
          {...localProps}
        >
          {headerCellChild(key)}
        </DraggableHeadCell>
      )
    },
    [
      fixedAndBorderProps,
      headerConfig,
      twiceColumns,
      orderBy,
      order,
      columnsToSort,
      sortersColumns,
      cellWithNumberValue,
      handleSortColumn,
      headerCellChild,
    ],
  )

  return (
    <TableHead classes={{ root: tableClasses?.tableHead }}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="tableHeader" direction="horizontal">
          {({ innerRef, droppableProps, placeholder }): ReactElement => (
            <TableRow ref={innerRef} {...droppableProps}>
              {isFirstEmptyCell && (
                <HeaderCell className={clsx(classes.firstEmptyCell, borderCellClasses)} />
              )}
              {!!handleCollapseAll && (
                <CollapseHeadCell
                  {...fixedAndBorderProps}
                  isFixed={!!localFixedColumns}
                  isAllRowsOpened={isAllRowsOpened}
                  handleCollapseAll={handleCollapseAll}
                />
              )}
              {!!onSelectAll && (
                <SelectAllCell
                  {...fixedAndBorderProps}
                  isFixed={!!localFixedColumns}
                  isFixedLast={localFixedColumns?.length === 0}
                  checkedLength={checkedLength}
                  dataWithCheckboxLength={dataWithCheckboxLength}
                  onSelectAll={onSelectAll}
                />
              )}

              {localFixedColumns?.map((key, index) => (
                <Fragment key={key}>
                  {renderDraggableHeadCell({
                    key,
                    index,
                    fixedLength: localFixedColumns?.length - 1,
                    isFixed: true,
                  })}
                </Fragment>
              ))}
              {localColumns.map((key, index) => (
                <Fragment key={key}>
                  {renderDraggableHeadCell({
                    key,
                    index: localFixedColumns ? index + localFixedColumns.length : index,
                  })}
                </Fragment>
              ))}
              {placeholder}
              {fitTableContent && (
                <HeaderCell
                  className={clsx(commonClasses.emptyCell, {
                    [commonClasses.emptyBorder]: isBorder,
                  })}
                />
              )}
            </TableRow>
          )}
        </Droppable>
      </DragDropContext>
      {!!twiceColumns.length && (
        <TwiceColumnsRow
          keys={localColumns}
          twiceColumns={twiceColumns}
          getTwiceColumnTitles={getTwiceColumnTitles}
          fitTableContent={fitTableContent}
          isFirstEmptyCell={isFirstEmptyCell}
          isCollapseIcon={!!handleCollapseAll}
          isSelectAllCheckbox={!!onSelectAll}
        />
      )}
    </TableHead>
  )
})
