import { reducer } from './reducers'
import saga from './sagas'

export * from './automatedOperations'
export * from './banks'
export * from './cassetteTypes'
export * from './common'
export * from './config'
export * from './currencies'
export * from './eventNotifications'
export * from './forecast'
export * from './realTimeMonitoring'
export * from './roundBanknotes'
export * from './uploadLogo'

export default {
  saga,
  reducer,
}
