import { AnyAction } from 'redux'

import { AdmRealTimeMonitoring } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants/actions/cmActions'

// get
export const getRealTimeMonitoringRequest = (): AnyAction => ({
  type: CMAppConfigActions.AdmGetRealTimeMonitoringRequest,
})
export const getRealTimeMonitoringResponse = (payload: AdmRealTimeMonitoring): AnyAction => ({
  type: CMAppConfigActions.AdmGetRealTimeMonitoringResponse,
  payload,
})
export const getRealTimeMonitoringFail = (): AnyAction => ({
  type: CMAppConfigActions.AdmGetRealTimeMonitoringFail,
})

// edit
export const saveRealTimeMonitoringRequest = (payload: AdmRealTimeMonitoring): AnyAction => ({
  type: CMAppConfigActions.AdmSaveRealTimeMonitoringRequest,
  payload,
})
