import { Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import CloseButton from '@/components/blocks/CloseButton'
import { TextInput } from '@/components/controls/TextInput/component'
import { useDebounceInput } from '@/components/pages/sd/TicketsPage/helpers/hooks/useDebounceInput'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './style'
import { Props } from './types'

export enum MODAL_TYPE {
  MAIN = 'MAIN',
  SAVE = 'SAVE',
  EDIT = 'EDIT',
}

const TemplateModal = ({
  modalType,
  templateName,

  onClose,
  onEditTemplate,
  onDeleteTemplate,
}: Props): React.ReactElement => {
  const translate = useTranslate()
  const classes = useClasses()

  const { inputValue, inputErrors, setInputValue, handleChangeInputValue } = useDebounceInput('')

  const isEditModal = modalType === MODAL_TYPE.EDIT

  useEffect((): void => {
    setInputValue(templateName)
  }, [setInputValue, templateName])

  return (
    <>
      <CloseButton absolute className={classes.closeButton} onClick={onClose} />

      <Typography className={classes.subtitle}>
        {isEditModal ? (
          <FormattedMessage id="title.editTemplate" defaultMessage="Rename template" />
        ) : (
          <FormattedMessage
            id="title.saveCustomFilterTemplate"
            defaultMessage="Save custom template"
          />
        )}
      </Typography>

      <TextInput
        value={inputValue}
        onChange={handleChangeInputValue}
        className={classes.input}
        autoFocus
        label={translate('translate#title.enterTemplateName')}
        maxSymbols={35}
      />

      <Grid container spacing={2} justify="flex-end">
        {isEditModal && onDeleteTemplate && (
          <Grid item>
            <Button
              onClick={onDeleteTemplate}
              className={clsx(classes.button, classes.deleteButton)}
              variant="outlined"
            >
              <FormattedMessage id="action.delete" defaultMessage="Delete" />
            </Button>
          </Grid>
        )}

        <Grid item>
          <Button
            onClick={onEditTemplate(inputValue)}
            className={classes.button}
            variant="contained"
            disabled={!!inputErrors || !inputValue.length || inputValue === templateName}
          >
            <FormattedMessage id="action.save" defaultMessage="Save" />
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default TemplateModal
