import React, { useState, useCallback } from 'react'
import clsx from 'clsx'
import { useTranslate } from '@/utils/internalization'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Field as FormikField, Formik, Form, FormikProps, FormikValues } from 'formik'
import { Grid, Button as ButtonUi } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CloseButton from '@/components/blocks/CloseButton'
import Button from '@/components/controls/Button'

import Save from '@/assets/sd/save.svg'
import Edit from '@/assets/sd/edit.svg'
import { useBackupValues } from '@/components/controls/GeneratedField'
import { useClasses } from './styles'
import { Props } from './types'
import { Step } from '@/types/common/multiStepForm'
import { isEmpty } from 'lodash-es'
import { Select } from '@/components/controls/Select'
import { truncateSync } from 'fs'
import { getFiltersTemplates } from '@/store/sd/tickets/index'

const MultiStepForm = ({
  steps,
  initialValues,
  validateOnMount,
  validate,
  classes: customClasses,
  onClose,
  handleSubmit,
  showHeaderButtons,
  enableReinitialize = false,
  hideCancel = false,
  finishButtonTitle,
  finishEnableValues,
  customFinishValidate,
  showIcon,
  headerButtonsActive = true,
}: Props): React.ReactElement => {
  const generatedFieldBackupOptions = useBackupValues()
  const templates = useSelector(getFiltersTemplates)

  const classes = useClasses()
  const translate = useTranslate()

  const [activeStep, setActiveStep] = useState<number>(0)

  const handleDisableFinish = useCallback(
    (values: FormikValues) => (): boolean => {
      if (customFinishValidate) {
        return customFinishValidate(values)
      }

      if (!finishEnableValues) return true

      if (finishEnableValues.length) {
        return finishEnableValues.some((item: any) => {
          if (typeof item === 'object') {
            const [...validationArray]: any = Object.values(item)

            if (Object.keys(values[Object.keys(item)[0]]).length) {
              return validationArray[0].some((key: any) => {
                if (Array.isArray(values[Object.keys(item)[0]])) {
                  return !values[Object.keys(item)[0]][0][key]
                }

                return !values[Object.keys(item)[0]][key]
              })
            }

            return validationArray[0].some((key: any) =>
              values[Object.keys(item)[0]].some((obj: { [key: string]: any }) => !obj[key]),
            )
          }

          return !values[item]
        })
      }

      if (finishEnableValues.length === 0) return false

      return true
    },
    [steps, activeStep, finishEnableValues],
  )

  const handleDisableNext = useCallback(
    (values: FormikValues) => (): boolean => {
      const validator = steps[activeStep].checkNextStepValidation
      if (validator !== undefined) return !validator(values)

      if (!steps[activeStep].nextEnableValues) return true

      if (
        values[steps[activeStep].specificNextDisableProp?.fieldName] !==
        steps[activeStep].specificNextDisableProp?.equal
      ) {
        return true
      }

      if (Array.isArray(steps[activeStep].nextEnableValues)) {
        return steps[activeStep].nextEnableValues.some((item: any) => {
          if (typeof item === 'object') {
            const [...validationArray]: any = Object.values(item)

            if (Object.keys(values[Object.keys(item)[0]]).length) {
              return validationArray[0].some((key: any) => !values[Object.keys(item)[0]][key])
            }

            return validationArray[0].some((key: any) =>
              values[Object.keys(item)[0]].some((obj: { [key: string]: any }) => !obj[key]),
            )
          }

          return !values[item]
        })
      }

      if (Object.keys(steps[activeStep].nextEnableValues).length) {
        return Object.keys(steps[activeStep].nextEnableValues).some(key => {
          if (Array.isArray(values[key])) {
            return values[key].every((item: Record<string, any>) =>
              steps[activeStep].nextEnableValues[key].some((valueKey: string) => !item[valueKey]),
            )
          }

          if (Array.isArray(steps[activeStep].nextEnableValues[key])) {
            return steps[activeStep].nextEnableValues[key].some(
              (valueKey: string) => !values[key][valueKey],
            )
          }
        })
      }

      return false
    },
    [steps, activeStep],
  )

  const handleNext = useCallback(
    (values: FormikValues) => (): void => {
      let goNext = true
      const handleNextClick = steps[activeStep].handleNextButtonClick
      if (handleNextClick !== undefined) {
        goNext = handleNextClick(values)
      }
      if (goNext) {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
      }
    },
    [steps, activeStep],
  )

  const handleBack = useCallback(() => setActiveStep(prevActiveStep => prevActiveStep - 1), [])

  const handleCancel = useCallback(() => {
    setActiveStep(0)
    onClose()
  }, [onClose])

  const renderHeader = useCallback(
    (
      activeStep: number,
      headerButtonsActive: boolean,
      isNextDisabled: boolean,
    ): React.ReactElement[] => {
      return steps.map((step: Step, index: number) => {
        let disabledStepClick = false

        if (headerButtonsActive) {
          if (index > activeStep) {
            disabledStepClick = true
            if (index === activeStep + 1 && !isNextDisabled) {
              disabledStepClick = false
            }
          }
        }

        return (
          <div
            key={step.title}
            className={clsx({
              [classes.stylesButton]: true,
              [classes.activeButton]: index === activeStep,
              [classes.disabledButton]: disabledStepClick,
            })}
            onClick={() => (!disabledStepClick ? setActiveStep(index) : '')}
          >
            {translate(step.title || '')}
          </div>
        )
      })
    },
    [],
  )

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
        validateOnMount={validateOnMount}
        enableReinitialize={enableReinitialize}
      >
        {({ errors, ...formProps }: FormikProps<any>): React.ReactElement => {
          const isErrorForCurrentStep: boolean = Array.isArray(steps[activeStep].nextEnableValues)
            ? steps[activeStep].nextEnableValues.some((enableValue: string) =>
                Object.keys(errors).includes(enableValue),
              )
            : false

          const isNextDisabled: boolean =
            isErrorForCurrentStep || handleDisableNext(formProps.values)()

          return (
            <>
              {showHeaderButtons && (
                <div className={classes.headerMenu}>
                  {renderHeader(activeStep, headerButtonsActive, isNextDisabled)}
                </div>
              )}
              <CloseButton absolute className={classes.closeButton} onClick={handleCancel} />
              <Form className={classes.form}>
                {steps[activeStep].content({ errors, ...formProps }, generatedFieldBackupOptions)}
                <Grid
                  container
                  className={clsx(classes.buttonsContainer, customClasses?.buttonsContainer)}
                >
                  {!hideCancel && !steps[activeStep].hideCancelButton && (
                    <Grid item>
                      <Button
                        variant="outlined"
                        width="xs"
                        onClick={handleCancel}
                        className={clsx(
                          classes.button,
                          customClasses?.button,
                          classes.buttonCancel,
                        )}
                      >
                        <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
                      </Button>
                    </Grid>
                  )}
                  {activeStep > 0 && !steps[activeStep].hideBackButton && (
                    <Grid item>
                      <Button
                        variant="outlined"
                        width="sm"
                        disabled={activeStep === 0 || steps[activeStep].backIsDisable}
                        startIcon={
                          showIcon ? <ChevronLeftIcon className={classes.buttonLeftIcon} /> : false
                        }
                        onClick={activeStep === 0 ? onClose : handleBack}
                        className={clsx(
                          classes.button,
                          customClasses?.button,
                          classes.buttonBack,
                          customClasses?.buttonBack,
                        )}
                      >
                        <FormattedMessage id="action.back" defaultMessage="Back" />
                      </Button>
                    </Grid>
                  )}
                  {!(steps.length - 1 === activeStep) && (
                    <Grid item>
                      <Button
                        variant="contained"
                        width="xs"
                        type={steps[activeStep].submitOnNextButton ? 'submit' : 'button'}
                        endIcon={
                          showIcon ? (
                            <ChevronRightIcon className={classes.buttonRightIcon} />
                          ) : (
                            false
                          )
                        }
                        onClick={
                          steps[activeStep].submitOnNextButton
                            ? undefined
                            : handleNext(formProps.values)
                        }
                        disabled={isNextDisabled}
                        className={clsx(
                          classes.button,
                          customClasses?.button,
                          classes.buttonNext,
                          customClasses?.buttonNext,
                        )}
                      >
                        <FormattedMessage
                          id={steps[activeStep].nextButtonTitle ?? 'action.next'}
                          defaultMessage="Next"
                        />
                      </Button>
                    </Grid>
                  )}
                  {!steps[activeStep].hideFinishButton && (
                    <Grid item>
                      <Button
                        variant="contained"
                        width="xs"
                        type={!steps[activeStep].submitOnNextButton ? 'submit' : 'button'}
                        disabled={!isEmpty(errors) || handleDisableFinish(formProps.values)()}
                        className={clsx(classes.button, customClasses?.button)}
                      >
                        <FormattedMessage
                          id={finishButtonTitle ?? 'action.finish'}
                          defaultMessage="Finish"
                        />
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Form>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default MultiStepForm
