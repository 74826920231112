import {
  BS2CasseteEditorStrategy,
  ShrCasseteExtProp,
  ShrDeclineNote,
} from '@/api/cm/swaggerGeneratedApi'

import { CMActions } from '@/constants'
import { AnyAction } from 'redux'

export interface State {
  isEditCassettesModalOpen: boolean
  cassetteEditor: {
    strategy: BS2CasseteEditorStrategy
    isLoading: boolean
    error?: string
  }
  declineNoteState: {
    isModalOpen: boolean
    isLoading: boolean
    error: string | null
    data: ShrDeclineNote | null
  }
  cassettes: {
    isEditEnabled: boolean
    data: ShrCasseteExtProp[]
    isLoading: boolean
    error?: string
  }
}

const initialState: State = {
  isEditCassettesModalOpen: false,
  declineNoteState: {
    isModalOpen: false,
    isLoading: false,
    error: null,
    data: null,
  },
  cassetteEditor: {
    strategy: 'None',
    isLoading: false,
    error: undefined,
  },
  cassettes: {
    isEditEnabled: false,
    data: [],
    isLoading: false,
    error: undefined,
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case CMActions.ShrSetDeclineNoteState:
      return {
        ...state,
        declineNoteState: {
          ...state.declineNoteState,
          ...payload,
        },
      }

    case CMActions.PlnSetDeclineNoteModalOpen:
    case CMActions.OrdSetDeclineNoteModalOpen:
      return {
        ...state,
        declineNoteState: {
          ...initialState.declineNoteState,
          isModalOpen: payload,
        },
      }
    case CMActions.OrdGetDeclineNoteRequest:
    case CMActions.PlnGetDeclineNoteRequest:
      return {
        ...state,
        declineNoteState: {
          ...state.declineNoteState,
          isLoading: true,
          error: null,
          data: null,
        },
      }
    case CMActions.OrdGetDeclineNoteResponse:
    case CMActions.PlnGetDeclineNoteResponse:
      return {
        ...state,
        declineNoteState: {
          ...state.declineNoteState,
          isLoading: false,
          error: null,
          data: payload,
        },
      }
    case CMActions.OrdGetDeclineNoteResponseFail:
    case CMActions.PlnGetDeclineNoteResponseFail:
      return {
        ...state,
        declineNoteState: {
          ...state.declineNoteState,
          isLoading: false,
          error: payload,
          data: null,
        },
      }
    case CMActions.ShrGetSettingsRequest:
      return {
        ...state,
        cassetteEditor: {
          strategy: 'None',
          isLoading: true,
          error: undefined,
        },
      }
    case CMActions.ShrGetSettingsResponse:
      return {
        ...state,
        cassetteEditor: {
          strategy: payload?.cassetteEditor,
          isLoading: false,
          error: undefined,
        },
      }
    case CMActions.ShrGetSettingsResponseFail:
      return {
        ...state,
        cassetteEditor: {
          strategy: 'None',
          isLoading: false,
          error: payload,
        },
      }
    case CMActions.ShrSetEditCassettesPropsModalOpen:
      return {
        ...state,
        isEditCassettesModalOpen: payload,
      }
    case CMActions.ShrSetCassettesPropsRequest:
      return {
        ...state,
        cassettes: {
          isEditEnabled: false,
          data: [],
          isLoading: true,
          error: undefined,
        },
      }
    case CMActions.ShrSetCassettesPropsResponse:
      return {
        ...state,
        cassettes: {
          isEditEnabled: payload.editInOrdersModuleEnabled,
          data: payload.data,
          isLoading: false,
          error: undefined,
        },
      }
    case CMActions.ShrSetCassettesPropsResponseFail:
      return {
        ...state,
        cassettes: {
          isEditEnabled: false,
          data: [],
          isLoading: false,
          error: payload,
        },
      }
    case CMActions.ShrGetCassettesPropsRequest:
      return {
        ...state,
        cassettes: {
          isEditEnabled: false,
          data: [],
          isLoading: true,
          error: undefined,
        },
      }
    case CMActions.ShrGetCassettesPropsResponse:
      return {
        ...state,
        cassettes: {
          isEditEnabled: payload.editInOrdersModuleEnabled,
          data: payload.data,
          isLoading: false,
          error: undefined,
        },
      }
    case CMActions.ShrGetCassettesPropsResponseFail:
      return {
        ...state,
        cassettes: {
          isEditEnabled: false,
          data: [],
          isLoading: false,
          error: payload,
        },
      }
    case CMActions.SignOut:
      return initialState
    default:
      return state
  }
}
