import { Box, Grid, Typography } from '@material-ui/core'
import { Delete, Edit, Send } from '@material-ui/icons'
import clsx from 'clsx'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import Test from 'react-test-attributes'

import Loader from '@/components/blocks/Loader/component'
import GlobalConfirmModal from '@/components/blocks/LogoutConfirmModal/component'
import Button from '@/components/controls/Button'
import {
  deletePOSTicketRequestRequest,
  getCreatingPOSTicketEditFormConfigRequest,
  getTicketInfoFormDataRequest,
  sendPosTicketRequest,
  setIsTicketHasDeletedSuccessful,
  setIsTicketHasSentError,
  setIsTicketHasSentSuccessful,
} from '@/store/sd/drawerActions/actions'
import {
  getIsFetchingPSRTicketDetails,
  getTicketId,
  getTicketInfoGrantedOperations,
} from '@/store/sd/drawerActions/selectors/documentInformation'
import {
  getIsDeletingPOSTicket,
  getIsSendingPosTicket,
  getIsTicketHasDeletedSuccessful,
  getIsTicketHasError,
  getIsTicketHasSentSuccessful,
  hasRequestError,
} from '@/store/sd/drawerActions/selectors/newPOS'
import { GrantedOperations } from '@/types/common/grantedOperations'
import { getTestId } from '@/utils'
import { useTranslate } from '@/utils/internalization'

import CommonInformationTable from './components/CommonInformationTable'
import MerchantInformationTable from './components/MerchantInformationTable'
import RequestItemsTable from './components/RequestItemsTable'
import { useClasses } from './styles'
import { Props } from './types'

const DocumentInformationTable = ({
  testIdPrefix,
  handleClose,
  ticketId,
  handleOpenCreatePosRequestModal,
}: Props): React.ReactElement => {
  const idPrefix = `${testIdPrefix}-table`
  const testId = getTestId(idPrefix)

  const translate = useTranslate()
  const classes = useClasses()
  const dispatch = useDispatch()

  const [isShowGlobalConfirmModal, setIsShowGlobalConfirmModal] = useState<boolean>(false)
  const [currentTicketId, setCurrentTicketId] = useState()

  const isFetchingPSRTicketDetails = useSelector(getIsFetchingPSRTicketDetails)
  const ticketIdFromReduxStore = useSelector(getTicketId)
  const hasError = useSelector(hasRequestError)
  const isDeletingPOSTicket = useSelector(getIsDeletingPOSTicket)
  const isPOSTicketHasDeletedSuccessfully = useSelector(getIsTicketHasDeletedSuccessful)
  const ticketInfoGrantedOperations = useSelector(getTicketInfoGrantedOperations)
  const isSendingPosTicket = useSelector(getIsSendingPosTicket)
  const isTicketHasSentSuccessful = useSelector(getIsTicketHasSentSuccessful)
  const isTicketHasError = useSelector(getIsTicketHasError)

  useEffect(() => {
    setCurrentTicketId(ticketIdFromReduxStore || ticketId)
  }, [ticketId, ticketIdFromReduxStore])

  useEffect(() => {
    if (currentTicketId) {
      if (isTicketHasError) {
        dispatch(getCreatingPOSTicketEditFormConfigRequest(currentTicketId))
        handleClose()
        handleOpenCreatePosRequestModal()
        dispatch(setIsTicketHasSentError(false))
      } else {
        dispatch(getTicketInfoFormDataRequest(currentTicketId))
      }
    }
  }, [currentTicketId, isTicketHasError])

  useEffect(() => {
    if (!hasError && !isDeletingPOSTicket && isPOSTicketHasDeletedSuccessfully) {
      dispatch(setIsTicketHasDeletedSuccessful(false))

      handleClose()
    }
  }, [hasError, isDeletingPOSTicket, isPOSTicketHasDeletedSuccessfully])

  /**
   * auto closing modal when ticket was sent successfully
   */
  useEffect(() => {
    if (!hasError && !isSendingPosTicket && isTicketHasSentSuccessful) {
      dispatch(setIsTicketHasSentSuccessful(false))

      handleClose()
    }
  }, [isSendingPosTicket, isTicketHasSentSuccessful, hasError])

  const canView = useMemo((): boolean => {
    return !!ticketInfoGrantedOperations?.includes(GrantedOperations.REQUEST_VIEW)
  }, [ticketInfoGrantedOperations])

  const handleDelete = useCallback(() => {
    dispatch(deletePOSTicketRequestRequest(currentTicketId))
  }, [currentTicketId, dispatch])

  const handleEdit = useCallback(() => {
    dispatch(getCreatingPOSTicketEditFormConfigRequest(currentTicketId))
    handleClose()

    handleOpenCreatePosRequestModal()
  }, [currentTicketId, handleClose, handleOpenCreatePosRequestModal])

  const handleSend = useCallback(() => {
    currentTicketId && dispatch(sendPosTicketRequest(currentTicketId))
  }, [handleClose, currentTicketId])

  const handleOpenCloseConfirmModal = useCallback((): void => {
    setIsShowGlobalConfirmModal(isShowGlobalConfirmModal => !isShowGlobalConfirmModal)
  }, [isShowGlobalConfirmModal])

  const isDisableEditButton = useMemo((): boolean => {
    if (
      ticketInfoGrantedOperations?.includes(GrantedOperations.REQUEST_UPDATE) &&
      !isFetchingPSRTicketDetails
    )
      return false
    return true
  }, [ticketInfoGrantedOperations, isFetchingPSRTicketDetails])

  const isDisableDeleteButton = useMemo((): boolean => {
    if (
      ticketInfoGrantedOperations?.includes(GrantedOperations.REQUEST_DELETE) &&
      !isFetchingPSRTicketDetails
    )
      return false
    return true
  }, [ticketInfoGrantedOperations, isFetchingPSRTicketDetails])

  const isDisableSendButton = useMemo((): boolean => {
    if (
      ticketInfoGrantedOperations?.includes(GrantedOperations.REQUEST_SEND) &&
      !isFetchingPSRTicketDetails
    ) {
      return false
    }

    return true
  }, [ticketInfoGrantedOperations, isFetchingPSRTicketDetails])

  return (
    <>
      <Test id={testId(0)}>
        <div className={classes.container}>
          <Test id={testId(1)}>
            <div className={classes.wrapper}>
              <Typography variant="h5">
                {translate('translate#title.documentInformation')}
              </Typography>
            </div>
          </Test>
          <Test id={testId(2)}>
            <form>
              {isFetchingPSRTicketDetails && (
                <div className={classes.loaderWrapper}>
                  <Loader />
                </div>
              )}
              {!isFetchingPSRTicketDetails && !canView && (
                <div className={classes.wrapper}>
                  <Typography variant="h5">
                    <FormattedMessage id="title.accessDenied" defaultMessage="Access denied" />
                  </Typography>
                </div>
              )}
              {!isFetchingPSRTicketDetails && canView && (
                <Grid className={classes.wrapper} container>
                  <Test id={testId(3)}>
                    <Grid className={classes.tableWrapper} item md={12}>
                      <CommonInformationTable />
                    </Grid>
                  </Test>
                  <Test id={testId(4)}>
                    <Grid className={classes.tableWrapper} item md={12}>
                      <MerchantInformationTable />
                    </Grid>
                  </Test>
                  <Test id={testId(5)}>
                    <RequestItemsTable />
                  </Test>
                </Grid>
              )}
              <Test id={testId(6)}>
                <div className={clsx(classes.wrapper, classes.buttons)}>
                  <Box overflow="hidden">
                    <Grid justify="space-between" container>
                      <Grid item />
                      <Grid item>
                        <Grid container justify="flex-end" spacing={2}>
                          <Grid item>
                            <Test id={testId(7)}>
                              <Button
                                width="md"
                                className={classes.deleteButton}
                                variant="outlined"
                                startIcon={<Delete />}
                                disabled={isDisableDeleteButton}
                                onClick={handleOpenCloseConfirmModal}
                              >
                                <FormattedMessage id="action.delete" defaultMessage="Delete" />{' '}
                                {isDeletingPOSTicket && (
                                  <Loader inline className={classes.loader} />
                                )}
                              </Button>
                            </Test>
                          </Grid>
                          <Grid item>
                            <Test id={testId(8)}>
                              <Button
                                onClick={handleEdit}
                                width="md"
                                light
                                startIcon={<Edit />}
                                disabled={isDisableEditButton}
                                className={classes.editButton}
                              >
                                <FormattedMessage id="action.edit" defaultMessage="Edit" />
                              </Button>
                            </Test>
                          </Grid>
                          <Grid item>
                            <Test id={testId(9)}>
                              <Button
                                startIcon={<Send />}
                                onClick={handleSend}
                                disabled={isDisableSendButton}
                                variant="contained"
                                width="md"
                              >
                                <FormattedMessage id="action.send" defaultMessage="Send" />
                              </Button>
                            </Test>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </Test>
            </form>
          </Test>
        </div>
      </Test>

      <GlobalConfirmModal
        open={isShowGlobalConfirmModal}
        onClose={handleOpenCloseConfirmModal}
        onConfirm={handleDelete}
        message={translate('translate#title.deletePosServiceRequest')}
        subMessage={translate('translate#title.areYouSureYouWantToDeleteTheCurrentRequest') + '?'}
        buttonsOptions={{
          no: {
            variant: 'outlined',
            text: 'Cancel',
          },
          yes: {
            text: 'Ok',
          },
        }}
        classes={{
          contentWrapper: classes.confirmContent,
          message: classes.confirmMessage,
          buttonsWrapper: classes.confirmButtons,
          noButton: classes.confirmNoButton,
        }}
        disableBackdropClick
      />
    </>
  )
}

export default withRouter(DocumentInformationTable)
