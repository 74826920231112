import makeStyles from '@material-ui/core/styles/makeStyles'

const color = '#25ADBF'

export const useClasses = makeStyles(({ spacing }) => ({
  row: {
    backgroundColor: '#F9F9F9',
  },
  title: {
    fontWeight: 'bold',
  },
  column: {
    height: spacing(5),
    padding: '0 0 0 10px',
  },
  collapsibleColumn: {
    textAlign: 'center',
  },
  collapseAllButton: {
    padding: spacing(0.25),
  },
  collapseAllIcon: {
    color: '#ffffff',
    backgroundColor: color,
    fontSize: 16,
  },
}))
