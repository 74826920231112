import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ palette, spacing }) => ({
  wrapper: {
    display: 'flex',
  },
  textOn: {
    position: 'absolute',
    left: spacing(0.6),
    top: spacing(0.3),
    zIndex: 1,
    color: '#ffffff',
    cursor: 'pointer',
  },
  textOff: {
    position: 'absolute',
    left: spacing(3),
    top: spacing(0.3),
    zIndex: 1,
    color: palette.grey[500],
    cursor: 'pointer',
  },
}))
