import { AnyAction } from 'redux'
import { WindowsEditActions } from '@/constants/actions'
import { Page } from './types'

export const setPage = (page: Page): AnyAction => ({
  type: WindowsEditActions.SetPage,
  payload: page,
})

export const toggleSplitMode = (): AnyAction => ({
  type: WindowsEditActions.ToggleMoveSplitPaneable,
})

export const setContentWrapperWidthProvider = (width: number): AnyAction => ({
  type: WindowsEditActions.SetContentWrapperWidth,
  payload: width,
})

export const setVerticalSplittingValue = (size: number): AnyAction => ({
  type: WindowsEditActions.SetSplitPaneHeightPosition,
  payload: size,
})

export const setHorizontalSplittingValue = (size: number): AnyAction => ({
  type: WindowsEditActions.SetSplitPaneWidhtPosition,
  payload: size,
})

export const closePropertiesWindow = (): AnyAction => ({
  type: WindowsEditActions.ClosePropertiesWindow,
})

export const openPropertiesWindow = (): AnyAction => ({
  type: WindowsEditActions.OpenPropertiesWindow,
})

export const toggleDrawer = (): AnyAction => ({
  type: WindowsEditActions.ToggleDrawer,
})
