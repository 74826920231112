import { FormikValues } from 'formik'
import moment from 'moment'

import { FilterParameter } from '@/types'

const DATE_FILTER_FIELD_NAME = 'atmDate'
const RESET_GROUPING_PERIOD_MINUTES = 24 * 60

export const transactionFilterFieldsValidations: { [key: string]: any } = {
  [DATE_FILTER_FIELD_NAME]: (dateFilter: any, filters: any = {}, params: any = {}) => {
    const [from, to] = dateFilter || []
    const { clientId, deviceId } = filters
    const { transactionsSearchDays } = params

    const dateFrom = from ? moment(from) : null
    const dateTo = moment(to || undefined)

    const result = {
      isError:
        !dateFrom || Math.abs(dateFrom.diff(dateTo, 'minute')) > RESET_GROUPING_PERIOD_MINUTES,
      reason: 'atmeye.DATE_RANGE_MORE_24H',
    }

    if (!transactionsSearchDays) {
      return result
    }

    if (!clientId?.length && !deviceId?.length && !dateFrom) {
      return { ...result, reason: 'atmeye.transactions.DATE_RANGE_REQUIRED', disableSearch: true }
    }

    const momentMaxDateTo =
      !clientId?.length && !deviceId?.length
        ? moment.min([moment(dateFrom).add(transactionsSearchDays, 'd'), moment()])
        : null

    if (
      momentMaxDateTo &&
      momentMaxDateTo.isBefore(dateTo) &&
      Math.abs(dateTo.diff(moment(dateFrom), 'd')) > transactionsSearchDays
    ) {
      return { ...result, reason: 'atmeye.transactions.DATE_RANGE_TOO_WIDE', disableSearch: true }
    }

    return result
  },
}

export const transactionFilterValidation = (filters: any, config?: FilterParameter[]) => {
  const dateFilter = filters[DATE_FILTER_FIELD_NAME]

  // if period more than 24h - reset grouping
  const dateValidation = transactionFilterFieldsValidations[DATE_FILTER_FIELD_NAME](
    dateFilter || [],
    filters,
    config?.find(({ parameterName }) => parameterName === DATE_FILTER_FIELD_NAME),
  )

  if (dateValidation?.isError) {
    return {
      shouldResetFilters: dateValidation?.shouldResetFilters,
      isGroupingDisabled: true,
      groupingTooltipTitle: 'translate#atmeye.groping.btn.tooltip.disabledByFiltersDateRange',
    }
  }

  return {
    shouldResetFilters: dateValidation?.shouldResetFilters,
    isGroupingDisabled: false,
    groupingTooltipTitle: '',
  }
}

export const formikTransactionsValidate = (values: FormikValues) => {
  const errors: { [key: string]: string } = {}

  if (values?.clientId) {
    if (!values?.clientId?.match(/([0-9]{4,})\*[0-9]{4}/)) {
      errors.clientId = 'atmeye.clientId'
    }
  }

  if (!values?.clientId?.length && !values?.deviceId?.length && !values?.atmDate[0]) {
    errors.errorStateWithoutDisplay = 'true'
  }
  console.log(errors, values)

  return errors
}
