import { AppNavigationRoutes, LocalStorageItems } from '@/constants'
import { history } from '@/Router'
import { UserData, UsersSettings } from '@/types/common/localStorageData'

const getParsedLocalStorageItem = (baseUrl: string) => (localStorageName: string): any => {
  const localStorageItem = localStorage.getItem(localStorageName) || '{}'

  const data = JSON.parse(localStorageItem)
  return data[baseUrl]
}

const getUserDataLocalStorage = (baseUrl: string): UserData => {
  const userDataString = localStorage.getItem(LocalStorageItems.UserData) || '{}'

  const userData = JSON.parse(userDataString)

  return userData[baseUrl] || {}
}

const setDataToLocalStorage = (baseUrl: string) => (key: string, data: string): void => {
  const parsedData = JSON.parse(data)
  const existingData = getParsedLocalStorageItem(baseUrl)(key)
  const allModulesData = JSON.parse(localStorage.getItem(key) || '{}')
  let dataToSet = {}

  if (Array.isArray(parsedData)) {
    dataToSet = { ...allModulesData, [baseUrl]: [...(existingData || []), ...parsedData] }
  } else {
    dataToSet = { ...allModulesData, [baseUrl]: { ...(existingData || {}), ...parsedData } }
  }

  localStorage.setItem(key, JSON.stringify(dataToSet))
}

const getUsersSettings = (
  baseUrl: string,
): {
  allUsersSettings: UsersSettings
  userData: UserData
} => {
  const allUsersSettings = getParsedLocalStorageItem(baseUrl)(LocalStorageItems.UsersSettings) || {}
  const userData = getUserDataLocalStorage(baseUrl)

  return { allUsersSettings, userData }
}

const clearLocalStorage = (): void => {
  localStorage.removeItem(LocalStorageItems.AccessTokenCM)
  localStorage.removeItem(LocalStorageItems.AccessTokenVaultV1)

  localStorage.removeItem(LocalStorageItems.UserPermissions)
  localStorage.removeItem(LocalStorageItems.UserData)
  localStorage.removeItem(LocalStorageItems.UserModules)

  history.push(AppNavigationRoutes.SignInPage)
}

export {
  clearLocalStorage,
  getParsedLocalStorageItem,
  getUserDataLocalStorage,
  getUsersSettings,
  setDataToLocalStorage,
}
