import makeStyles from '@material-ui/core/styles/makeStyles'
export type Props = {
  display?: string
}

export const useClasses = makeStyles(({ spacing }) => ({
  menuPaper: {
    display: (props: Props) => props.display,
    flexDirection: 'column',

    '& .MuiListItem-button': {
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
  },
  menuList: {
    flex: 1,
    display: (props: Props) => props.display,
    flexDirection: 'column',
    overflow: 'auto',
  },
  menuSpacing: {
    padding: spacing(1, 0),
    flex: 1,
    display: (props: Props) => props.display,
    flexDirection: 'column',
    overflow: 'auto',
    '&:focus': {
      outline: 'none',
    },
  },
  limitedHeight: {
    maxHeight: 200,
  },
  scrollAble: {
    flex: 1,
    overflow: 'auto',
  },
  divider: {
    margin: spacing(1, 0),
  },
}))
