import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { NewCommonApi } from '@/api/atmeye/common/newCommonAPI'
import {
  GetExportBody,
  GetExportGroupedBody,
  GetGroupDetailsBody,
  GetTicketsBody,
} from '@/api/atmeye/common/types'
import { Account } from '@/api/common/authorizationApi/types'
import { StrategyLocalStorage } from '@/components/NPMPakage/components/tableComponents/UITable/strategy'
import { AtmeyeDeviceActions } from '@/constants'
import { WINDOWS_TYPES } from '@/constants/atmeye/tables/windowTypes'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { gettingDevices } from '@/helpers/devices'
import { State } from '@/store/sd/tickets/reducer'
import { TableSettingsData, TypeOfTickets } from '@/types'
import { getLocalStorageItem, setLocalStorageItem } from '@/utils/localStorage'
import { PopUpService } from '@/utils/services/popUpService'

import {
  changeSelectedFilters,
  changeSorting,
  collectFilterTemplateFields,
  createSdFilterTemplateResponse,
  deleteSdFilterTemplateResponse,
  deleteSdGroupTemplateResponseSuccess,
  editGroupTemplateFromResponse,
  getDevicesConfigResponse,
  getExportGroupedResponse,
  getExportGroupedResponseFail,
  getExportResponse,
  getExportResponseFail,
  getGroupDetailsResponse,
  getGroupDetailsResponseFail,
  getTicketDetailsResponse,
  getTicketDetailsResponseFail,
  getTicketsConfigResponseFail,
  getTicketsResponse,
  getTicketsResponseFail,
  setActualCountParentGroup,
  setDataPagination,
  setFilterTemplatesResponse,
  setGroupTemplates,
  setTicketsPage,
  setTicketsRowsPerPage,
  updateSdFilterTemplateResponse,
} from './actions'

const WINDOW_TYPE = WINDOWS_TYPES.DEVICES

function* getPagination(data: GetTicketsBody, typeOfTickets: string): SagaIterator {
  try {
    const responsePagination = yield call(NewCommonApi.getPagination, data as GetTicketsBody)
    yield put(setDataPagination(responsePagination))
  } catch (e) {
    console.error('e: ', e)
  }
}

function* getPaginationGroupDetails(typeOfTickets: any, data: any): SagaIterator {
  try {
    const responsePagination = yield call(NewCommonApi.getGroupedDetailsPagination, data)
    yield put(
      setActualCountParentGroup({
        totalCount: responsePagination.totalCount,
        parentGroups: data.parentGroups[0],
        typeOfTickets,
      }),
    )
    yield put(setTicketsPage(responsePagination.outputPageNo))
  } catch (e) {
    console.error('e: ', e)
  }
}

function* getDevices({ payload: { isInitRequest } }: AnyAction): SagaIterator {
  try {
    const accountState: Account | null = yield select(state => state.auth.currentAccount)
    const ticketsState: State = yield select(state => state.devices)

    const {
      typeOfTickets,
      config: {
        [typeOfTickets as TypeOfTickets]: { getOutTreeURL, filter, groups },
      },
      rowsPerPage: { [typeOfTickets as TypeOfTickets]: rowsPerPage },
      page: { [typeOfTickets as TypeOfTickets]: page },
      grouping: {
        [typeOfTickets as TypeOfTickets]: { selectedGroupings },
      },
      sorting: { [typeOfTickets as TypeOfTickets]: sorting },
      filterFields: { [typeOfTickets as TypeOfTickets]: filterTemplateFields },
      filter: {
        [typeOfTickets as TypeOfTickets]: { selectedFilters, selectedTemplate },
      },
    } = ticketsState

    const CONFIG_LS_KEY = 'config'
    let savedSettings: TableSettingsData | null = null
    let requestBody
    if (accountState) {
      savedSettings = getLocalStorageItem(LocalStorageItems.Devices)
    }
    if (isInitRequest && savedSettings && savedSettings[CONFIG_LS_KEY]) {
      const { requestBodyData, sorting, filterTemplateFields, page, rowsPerPage } = savedSettings[
        CONFIG_LS_KEY
      ]
      const { groups, ...other } = requestBodyData
      requestBody = other
      requestBody.formUID = 'DEVICES'
      if (groups.length > 0) requestBody.groups = groups

      yield put(changeSorting(sorting))
      yield put(collectFilterTemplateFields(filterTemplateFields))
      yield put(setTicketsRowsPerPage(rowsPerPage))
      yield put(setTicketsPage(page))
    } else {
      requestBody = {
        formUID: 'DEVICES',
        getOutTreeURL,
        groups: gettingDevices.getGroups(selectedGroupings, groups),
        pageNo: page,
        pageSize: rowsPerPage,
        sortDetails: gettingDevices.getSortDetails(sorting),
      }
      if (accountState) {
        setLocalStorageItem(LocalStorageItems.Devices, {
          [CONFIG_LS_KEY]: {
            requestBodyData: requestBody,
            selectedFilters,
            sorting,
            page,
            rowsPerPage,
            filterTemplateFields,
            selectedFiltersTemplate: selectedTemplate,
          },
        })
      }
    }

    requestBody.filter = gettingDevices.getFilters(selectedFilters, filter)

    if (!requestBody.groups || requestBody.groups.length <= 0) {
      yield fork(getPagination, requestBody, typeOfTickets)
    }
    const response = yield call(NewCommonApi.getData, requestBody as GetTicketsBody)
    yield put(getTicketsResponse(response))
  } catch (error) {
    yield put(getTicketsResponseFail(error.message))
  }
}

function* getExport({ payload }: AnyAction): SagaIterator {
  try {
    const ticketsState: State = yield select(state => state.devices)
    const {
      typeOfTickets,
      config: {
        [typeOfTickets as TypeOfTickets]: { formUID, getOutTreeURL, filter },
      },
      sorting: { [typeOfTickets as TypeOfTickets]: sorting },
      filter: {
        [typeOfTickets as TypeOfTickets]: { selectedFilters },
      },
    } = ticketsState
    const requestBody = {
      filter: gettingDevices.getFilters(selectedFilters, filter),
      formUID: formUID,
      getOutTreeURL,
      outputFields: payload.outputFields,
      format: payload.format,
      sortDetails: gettingDevices.getSortDetails(sorting),
      appliedFilters: gettingDevices.getAppliedFilters(selectedFilters, filter),
    }

    const response = yield call(NewCommonApi.getExportData, requestBody as GetExportBody, true)

    yield put(getExportResponse(response))
  } catch (error) {
    yield put(getExportResponseFail(error.message))
  }
}

function* getExportGrouped({ payload }: AnyAction): SagaIterator {
  try {
    const ticketsState: State = yield select(state => state.devices)
    const {
      typeOfTickets,
      config: {
        [typeOfTickets as TypeOfTickets]: { formUID, getOutTreeURL, filter, groups },
      },
      grouping: {
        [typeOfTickets as TypeOfTickets]: { selectedGroupings },
      },
      filter: {
        [typeOfTickets as TypeOfTickets]: { selectedFilters },
      },
    } = ticketsState

    const requestBody = {
      filter: gettingDevices.getFilters(selectedFilters, filter),
      formUID: formUID,
      getOutTreeURL,
      outputFields: payload.outputFields,
      format: payload.format,
      groupBy: gettingDevices.getGroups(selectedGroupings, groups),
    }

    const response = yield call(
      NewCommonApi.getExportGroupedData,
      requestBody as GetExportGroupedBody,
      true,
    )

    yield put(getExportGroupedResponse(response))
  } catch (error) {
    yield put(getExportGroupedResponseFail(error.message))
  }
}

function* getGroupDetails(): SagaIterator {
  try {
    const ticketsState: State = yield select(state => state.devices)
    const {
      typeOfTickets,
      config: {
        [typeOfTickets as TypeOfTickets]: { filter },
      },
      page: { [typeOfTickets as TypeOfTickets]: page },
      parentGroups,
      sorting: { [typeOfTickets as TypeOfTickets]: sorting },
      filter: {
        [typeOfTickets as TypeOfTickets]: { selectedFilters },
      },
      pageSizeForGroupingTickets,
    } = ticketsState
    const requestBody: GetGroupDetailsBody = {
      filter: gettingDevices.getFilters(selectedFilters, filter),
      formUID: 'DEVICES',
      getOutDetailsURL: 'tickets|/search-form/client/srgroup-details/open/all',
      parentGroups: parentGroups,
      pageNo: page,
      pageSize: Number(pageSizeForGroupingTickets) || 10,
      sortDetails: gettingDevices.getSortDetails(sorting),
    }

    yield fork(getPaginationGroupDetails, typeOfTickets as string, requestBody)
    const response = yield call(NewCommonApi.getGroupedDetails, requestBody)
    if (!response.details) {
      yield put(setTicketsPage(page - 1))
    }

    yield put(getGroupDetailsResponse(response))
  } catch (error) {
    yield put(getGroupDetailsResponseFail(error.message))
  }
}

function* getDevicesFilterTemplates(): Generator {
  try {
    const response: any = yield call(NewCommonApi.getFilterTemplates, WINDOW_TYPE)
    yield put(setFilterTemplatesResponse(response.availableTemplates))
  } catch (error) {
    throw new Error(error)
  }
}

function* createFilterTemplate({ payload }: AnyAction): Generator {
  try {
    const { filters, name } = payload
    const response: any = yield call(NewCommonApi.createFilterTemplate, {
      filters,
      name,
      windowType: WINDOW_TYPE,
    })
    const newTemplateId = response.availableTemplates.find(
      (template: { id: string; name: string }) => template.name === payload.name,
    )
    yield put(
      createSdFilterTemplateResponse({
        name: payload.name,
        id: newTemplateId.id,
      }),
    )
    PopUpService.showGlobalPopUp(`${payload.translate('translate#title.templateSaved')}`)
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('title.templateCreateError')}.\n${error}`,
      'error',
    )
  }
}

function* updateFilterTemplate({ payload }: AnyAction): Generator {
  try {
    const response = yield call(NewCommonApi.updateFilterTemplate, {
      filters: payload.filters,
      name: payload.name,
      windowType: WINDOW_TYPE,
      id: payload.id,
    })

    if (response) {
      yield put(updateSdFilterTemplateResponse(payload))
      PopUpService.showGlobalPopUp(
        `${payload.translate('translate#title.templateEdited')}`,
        'success',
      )
    }
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#title.templateEditError')}.\n${error}`,
      'error',
    )
  }
}

function* deleteFilterTemplate({ payload }: AnyAction): Generator {
  try {
    const response: any = yield call(NewCommonApi.deleteFilterTemplate, {
      id: payload.id,
      windowType: WINDOW_TYPE,
    })
    if (response.status === 200) {
      yield put(deleteSdFilterTemplateResponse(payload))
      PopUpService.showGlobalPopUp(
        `${payload.translate('translate#title.templateRemoved')}`,
        'success',
      )
    }
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#title.templateDeletionError')}.\n${error}`,
      'error',
    )
  }
}

function* getGroupTemplatesRequest({ payload }: AnyAction): Generator {
  try {
    const response: any = yield call(NewCommonApi.getGroupTemplates, WINDOW_TYPE)

    const templateViewUpdated = response.availableTemplates.map((temp: any) => ({
      ...temp,
      config: [],
    }))
    yield put(setGroupTemplates(templateViewUpdated))
  } catch (e) {
    // call error
  }
}

function* saveGroupTemplate({ payload }: AnyAction): Generator {
  try {
    const { groupingLevels, name } = payload.template
    const response: any = yield call(NewCommonApi.createGroupTemplate, {
      groupingLevels,
      name,
      windowType: WINDOW_TYPE,
    })

    PopUpService.showGlobalPopUp(payload.translate(`translate#title.templateSaved`), 'success')

    const newTemplateId =
      response.availableTemplates.find((temp: any) => temp.name === payload.template.name).id || ''
    yield put(
      setGroupTemplates(
        [
          {
            id: newTemplateId,
            name: payload.template.name,
            config: payload.template.config,
          },
        ],
        true,
      ),
    )

    StrategyLocalStorage.setData(`@LocalStorage/${WINDOW_TYPE}/groupingTemplate`, newTemplateId)
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate(`translate#title.templateCreateError`)} ${error}`,
      'error',
    )
  }
}

function* editGroupTemplate({ payload }: AnyAction): Generator {
  try {
    const response: any = yield call(NewCommonApi.editGroupTemplate, {
      template: payload.template,
      windowType: WINDOW_TYPE,
    })

    if (response.availableTemplates) {
      PopUpService.showGlobalPopUp(payload.translate(`translate#title.templateEdited`), 'success')
      yield put(editGroupTemplateFromResponse(payload.template))
    }
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#title.templateEditError')} ${error}`,
      'error',
    )
  }
}

function* deleteGroupTemplate({ payload }: AnyAction): Generator {
  try {
    const response: any = yield call(NewCommonApi.deleteGroupTemplate, {
      id: payload.template.id,
      windowType: WINDOW_TYPE,
    })

    if (response === '') {
      yield put(deleteSdGroupTemplateResponseSuccess(payload.template))
      PopUpService.showGlobalPopUp(
        `${payload.translate('translate#title.templateRemoved')}`,
        'success',
      )
    }
  } catch (error) {
    PopUpService.showGlobalPopUp(
      `${payload.translate('translate#title.templateDeletionError')} ${error}`,
      'error',
    )
  }
}

function* getDevicesConfig(): SagaIterator {
  try {
    const typeOfTickets: TypeOfTickets = yield select(state => state.devices.typeOfTickets)

    const response = yield call(NewCommonApi.getFormConfig, {
      formUID: 'DEVICES',
      getConfigURL: '/search-form/config',
    })

    yield put(getDevicesConfigResponse(typeOfTickets, response))
  } catch (error) {
    yield put(getTicketsConfigResponseFail(error.message))
  }
}

// function* getTicketDetails({ payload: { id } }: AnyAction): Generator {
//   try {
//     const response = yield call(TicketsApi.getTicketDetails, id)

//     yield put(getTicketDetailsResponse(response as TicketDetailsResponse))
//   } catch (error) {
//     yield put(getTicketDetailsResponseFail(error.message))
//   }
// }

export default function*(): Generator {
  yield takeLatest(AtmeyeDeviceActions.GetDevicesRequest, getDevices)
  yield takeLatest(AtmeyeDeviceActions.GetDevicesExport, getExport)
  yield takeLatest(AtmeyeDeviceActions.GetDevicesExportGrouped, getExportGrouped)
  yield takeLatest(AtmeyeDeviceActions.GetGroupDetailsRequest, getGroupDetails)
  yield takeLatest(AtmeyeDeviceActions.UpdateFilterTemplateRequest, updateFilterTemplate) // 2
  yield takeLatest(AtmeyeDeviceActions.DeleteFilterTemplateRequest, deleteFilterTemplate) // 3
  yield takeLatest(AtmeyeDeviceActions.DeleteGroupTemplateRequest, deleteGroupTemplate) // 7
  yield takeLatest(AtmeyeDeviceActions.GetFiltersTemplatesRequest, getDevicesFilterTemplates)
  yield takeLatest(AtmeyeDeviceActions.CreateFilterTemplateRequest, createFilterTemplate) // 1
  yield takeLatest(AtmeyeDeviceActions.GetGroupsTemplatesRequest, getGroupTemplatesRequest) // 4
  yield takeLatest(AtmeyeDeviceActions.SaveGroupingTemplateRequest, saveGroupTemplate) // 5
  yield takeLatest(AtmeyeDeviceActions.EditGroupTemplateRequest, editGroupTemplate) // 6
  yield takeLatest(AtmeyeDeviceActions.GetDevicesConfigRequest, getDevicesConfig)
  // yield takeLatest(TicketActions.GetTicketDetailsRequest, getTicketDetails)
}
