import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Typography,
} from '@material-ui/core'
import MaterialSelect from '@material-ui/core/Select'
import ErrorIcon from '@material-ui/icons/Error'
import clsx from 'clsx'
import { FieldProps } from 'formik'
import { debounce } from 'lodash-es'
import React, {
  ChangeEvent,
  KeyboardEvent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { isIE } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'

import Loader from '@/components/blocks/Loader'
import { SelectIcon } from '@/components/blocks/SelectIcon'
import Button from '@/components/controls/Button'
import { CallType } from '@/components/controls/LookupsMultiSelect/types'
import SearchInput from '@/components/controls/SearchInput/component'
import { preventDefault } from '@/utils/functions'
import { useScroll } from '@/utils/hooks/useScroll'
import { useTranslate } from '@/utils/internalization'

import ListElement from './components/ListElement'
import { useClasses } from './styles'
import { Props, SelectableItem } from './types'

const headerItemStyles = { opacity: 1 } // override for prod

export const SELECT_ALL_MENU_ITEM_VALUE = 'all'

export const MultipleSelectWithConfig = ({
  label,
  options,
  placeholder,
  disabled,
  className,
  isLoading = false,
  shrink = false,
  required = false,
  value: selectedValues,
  error,
  fontSize,
  parameterName,
  setFieldValue,
  onChangeMultipleSelect,
  SearchInputBlock,
  withScroll,
  hideEmptyItem = false,
  placeholderSymbol,
  styles,
  filterMultipleSelect,
  onCall,
  totalPages,
  resetDependencies,
  ...props
}: Props): React.ReactElement => {
  const inputLabel = useRef() as RefObject<HTMLLabelElement>
  const input = useRef() as RefObject<HTMLInputElement>
  const [labelWidth, setLabelWidth] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState<string[]>(
    (selectedValues as string[]) ?? ([] as string[]),
  )
  const [isSelectedAllItems, setIsSelectedAllItems] = useState(false)
  const [searchValue, setSearchValue] = useState<string>('')

  const [debouncedSearchValue, setSearch] = useState<string>('')
  const debouncedSearch = useCallback(debounce(setSearch, 500), [setSearch])

  const [filteredOptions, setFilteredOptions] = useState<Array<{ name: string; value: string }>>(
    options,
  )

  const [totalCountPaged, setTotalPages] = useState(totalPages)

  const [loadSearch, setLoadSearch] = useState(false)
  const [showAllLoaded, setShowAllLoaded] = useState(false)
  const [showLoadedElementsCount, setShowLoadedElementsCount] = useState(1000)

  const search = async ({ value, pageNumber, pageSize }: CallType) => {
    try {
      if (onCall) {
        setLoadSearch(true)
        const { totalPages } = await onCall({
          value,
          pageNumber,
          pageSize,
        })

        setTotalPages(totalPages)
      }
    } catch (e) {
      setLoadSearch(true)
      console.error(e)
      // throw new Error(e)
    } finally {
      setLoadSearch(false)
    }
  }

  const parentRef = useRef<any>()
  const childRef = useRef<any>()

  useScroll({
    parentRef: parentRef.current,
    childRef: childRef.current,
    loading: loadSearch,
    listLength: filteredOptions.length,
    limitSize: 100,
    pageLimit: totalCountPaged,
    callback: (page, limit) => {
      setShowAllLoaded(true)
      search({ value: debouncedSearchValue, pageSize: limit, pageNumber: page })
    },
  })

  useEffect(() => {
    debouncedSearch(searchValue)
  }, [searchValue])

  const propsStyle = {
    overflow: isIE ? 'scroll' : 'hidden',
    fieldWidth: input.current?.offsetWidth,
  }

  const translate = useTranslate()
  const classes = useClasses(propsStyle)

  useEffect(() => {
    if (inputLabel && inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth)
    }
  }, [inputLabel])

  useEffect(() => {
    if (searchValue) {
      if (filterMultipleSelect) {
        return setFilteredOptions(filterMultipleSelect(options, searchValue))
      }
      return setFilteredOptions(
        options.filter(option => option.name.toLowerCase().includes(searchValue.toLowerCase())),
      )
    }

    return setFilteredOptions(options)
  }, [searchValue, options])

  useEffect(() => {
    if (Array.isArray(selectedValues)) {
      setSelected(selectedValues)
    }
  }, [selectedValues])

  useEffect(() => {
    setIsSelectedAllItems(options ? options.length === selected.length : false)
  }, [selected, options])

  const handleClose = useCallback((): void => {
    if (Array.isArray(selectedValues)) {
      setSelected(selectedValues)
    }
    setIsOpen(false)
  }, [setIsOpen, selectedValues])

  const handleOpen = useCallback(
    (e): void => {
      setIsOpen(true)
      props.onOpen && props.onOpen(e)
    },
    [setIsOpen],
  )

  const handleClickMenuItem = useCallback(
    (value: string | number): (() => void) => (): void => {
      if (value === SELECT_ALL_MENU_ITEM_VALUE) {
        setSelected(isSelectedAllItems ? [] : [...options.map(o => o.value)])

        setIsSelectedAllItems(current => !current)

        return
      }

      setSelected(item =>
        item.includes(`${value}`) ? item.filter(val => val !== `${value}`) : [...item, `${value}`],
      )
    },
    [options, isSelectedAllItems],
  )

  const handleSearch = useCallback((event: ChangeEvent<{ value: any }>) => {
    return setSearchValue(event.target.value)
  }, [])

  const handleDisableSelection = useCallback(
    (event: KeyboardEvent<HTMLDivElement | HTMLLIElement>) => event.stopPropagation(),
    [],
  )

  const handleCancel = useCallback(
    (event): void => {
      setSelected(selectedValues as string[])
      event.stopPropagation()
      handleClose()
    },
    [selectedValues, handleClose, setSelected],
  )

  const handleApply = useCallback(
    (event): void => {
      if (Array.isArray(selected)) {
        const filteredValues = selected.filter(item => item && item !== SELECT_ALL_MENU_ITEM_VALUE)

        if (onChangeMultipleSelect) {
          onChangeMultipleSelect(filteredValues, options)
        } else if (setFieldValue && parameterName) {
          setFieldValue(parameterName, filteredValues)
        }

        if (resetDependencies && setFieldValue) {
          if (Array.isArray(resetDependencies)) {
            resetDependencies.map(item => {
              setFieldValue(item, null)
            })
          }
        }

        event.stopPropagation()
        handleClose()
      }
    },
    [selected, handleClose, onChangeMultipleSelect, options],
  )

  const handleRenderSelectValue = useCallback(
    (lookupValues: SelectableItem[]) => (value: any): string => {
      if (value.length) {
        const titles = value.map((val: string) => {
          const lookupValue = lookupValues.find(item => item.value === val)

          return lookupValue ? lookupValue.name : ''
        })

        return titles.join(', ')
      } else {
        return placeholder || translate('translate#title.multi.select.placeholder')
      }
    },
    [placeholder],
  )
  return (
    <>
      <FormControl
        error={error}
        size="small"
        required={required}
        className={clsx(
          classes.select,
          {
            [classes.disabled]: disabled,
          },
          className,
        )}
        variant="outlined"
      >
        {label && (
          <InputLabel ref={inputLabel} shrink={shrink || undefined}>
            {isLoading ? (
              <Box display="flex" alignItems="center">
                {label}
                &nbsp;
                {required && '*&nbsp;'}
                <Loader className={classes.loader} inline />
              </Box>
            ) : (
              label
            )}
          </InputLabel>
        )}
        <MaterialSelect
          {...props}
          ref={input}
          className={
            placeholder && selected && selected.length === 0
              ? classes.placeholderOption
              : classes.normalOption
          }
          label={isLoading ? <span>{label}</span> : label}
          IconComponent={SelectIcon}
          input={shrink ? <OutlinedInput labelWidth={labelWidth} notched={shrink} /> : undefined}
          displayEmpty={shrink}
          multiple
          value={Array.isArray(selected) ? selected : []}
          open={isOpen}
          onOpen={handleOpen}
          onClose={handleClose}
          disabled={disabled}
          onChange={preventDefault}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          }}
          renderValue={handleRenderSelectValue(options)}
          classes={{ root: classes.root }}
        >
          <MenuItem style={headerItemStyles} disabled>
            <Typography className={classes.headerText} variant="h6">
              {label}
              {/* {label} {isLoading && <CircularProgress color="inherit" size={20} />} */}
            </Typography>
          </MenuItem>

          {SearchInputBlock && (
            <SearchInput
              indentation
              clearButton={!!searchValue}
              onChange={handleSearch}
              onKeyDown={handleDisableSelection}
              val={searchValue}
            />
          )}

          <>
            <div
              className={clsx(classes.normalOption, classes.blockSelectAllWrapper)}
              key={`${SELECT_ALL_MENU_ITEM_VALUE}2`}
              onClick={handleClickMenuItem(SELECT_ALL_MENU_ITEM_VALUE)}
            >
              <Checkbox className={styles?.checkbox} checked={isSelectedAllItems} />
              <FormattedMessage id="title.selectAll" defaultMessage="Select All" />{' '}
              {options && `(${options.length})`}
            </div>
            {filteredOptions && filteredOptions.length > showLoadedElementsCount && (
              <>
                <Divider />
                <Typography variant="body1" className={classes.showingOnly}>
                  <span className={classes.showingOnlyText}>
                    <ErrorIcon fontSize="small" style={{ fill: '#474747' }} />
                    {` ${translate(
                      'translate#title.showing',
                    )} (${showLoadedElementsCount} ${translate('translate#title.of')} ${
                      filteredOptions.length
                    })`}
                  </span>
                </Typography>
              </>
            )}
            <Divider />
            <div className={clsx(classes.menuList, styles?.list)} ref={parentRef}>
              {filteredOptions && filteredOptions.length
                ? filteredOptions.map(({ value, name }, index) => {
                    if (index >= showLoadedElementsCount) {
                      return
                    }
                    // if (!showAllLoaded && index >= showLoadedElementsCount) {
                    //   return
                    // }
                    return (
                      // <ListElement
                      //   key={`${name}${value}`}
                      //   classes={classes}
                      //   name={name}
                      //   value={value}
                      //   handleClickMenuItem={handleClickMenuItem(value)}
                      //   styles={styles}
                      //   selected={selected}
                      // />
                      <Typography
                        className={clsx(
                          classes.selectItem,
                          classes.normalOption,
                          classes.menuListItem,
                        )}
                        key={`${index}_${value}`}
                        onClick={handleClickMenuItem(value)}
                      >
                        <span
                          className={
                            Array.isArray(selected) && selected.includes(value)
                              ? classes?.fakeCheckboxChecked
                              : classes?.fakeCheckbox
                          }
                        />
                        {name}
                      </Typography>
                    )
                  })
                : !isLoading && (
                    <Typography variant="body1" className={classes.noOptions}>
                      {translate('translate#title.noOptions')}
                    </Typography>
                  )}
              {(isLoading || loadSearch) && (
                <Box style={{ textAlign: 'center', height: '40px' }}>
                  <Loader className={classes.dataLoader} />
                </Box>
              )}
              <div ref={childRef} style={{ height: '20px' }} />
            </div>
          </>

          <Divider />
          <div className={classes.buttonWrapper}>
            <Button
              className={clsx(classes.button, classes.cancelButton)}
              variant="contained"
              size="medium"
              onClick={handleCancel}
            >
              <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              size="medium"
              onClick={handleApply}
            >
              <FormattedMessage id="action.ok" defaultMessage="Ok" />
            </Button>
          </div>
        </MaterialSelect>
      </FormControl>
    </>
  )
}

export const createMultipleSelectFieldWithConfig = (props: Props) => ({
  field,
  form: { errors, setFieldValue },
  meta: { touched },
}: FieldProps): React.ReactElement => {
  return (
    <MultipleSelectWithConfig
      {...field}
      error={!!errors[field.name] && touched}
      setFieldValue={setFieldValue}
      {...props}
    />
  )
}
