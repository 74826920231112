import { AddNewCompanyFormFields, InitialFormValues } from '@/constants/forms/AddNewCompanyForm'
import { Role } from '@/api/companies/companiesApi/types'
import _ from 'lodash'

export const getInitialValues = (
  isEditForm: boolean,
  parentId: number | null = null,
  parentName: string | null = null,
  roles: Role[],
  defaultValues?: Record<string, any>,
): InitialFormValues => {
  const template: InitialFormValues = {
    companyGeneralInfo: {
      companyTypeId: null,
      companyTypeName: '',
      timeZoneCode: null,
      timeZoneName: '',
      parentCompanyId: parentId || null,
      parentCompanyName: parentName || null,
      companyName: '',
      companyShortName: '',
      countryId: null,
      vatCode: '',
      registrationNumber: '',
      homePageUrl: '',
      email: null,
      phoneNumber: null,
      notes: '',
    },
    address: {
      countryId: null,
      countryName: '',
      cityId: null,
      cityName: '',
      streetId: null,
      streetName: '',
      stateId: null,
      stateName: '',
      regionId: null,
      regionName: '',
      cityRegionId: null,
      cityRegionName: '',
      address: '',
      houseNumber: '',
      flatNumber: '',
      location: '',
    },
    [AddNewCompanyFormFields.Roles]: roles,
    [AddNewCompanyFormFields.WorkingHours]: [
      // {
      //   dayOfWeek: 'MONDAY',
      //   isWorks: true,
      //   timePeriods: [
      //     {
      //       fromTimeH: 10,
      //       fromTimeM: 15,
      //       toTimeH: 12,
      //       toTimeM: 59,
      //     },
      //     {
      //       fromTimeH: 12,
      //       fromTimeM: 14,
      //       toTimeH: 22,
      //       toTimeM: 12,
      //     },
      //   ],
      // },
      // {
      //   dayOfWeek: 'Tuesday',
      //   isWorks: false,
      //   timePeriods: [
      //     // {
      //     //   fromTimeH: 10,
      //     //   fromTimeM: 15,
      //     //   toTimeH: 12,
      //     //   toTimeM: 14,
      //     // },
      //     // {
      //     //   fromTimeH: 8,
      //     //   fromTimeM: 0,
      //     //   toTimeH: 22,
      //     //   toTimeM: 12,
      //     // },
      //   ],
      // },
    ],
    // [AddNewCompanyFormFields.InitialWorkingHours]: [],
    [AddNewCompanyFormFields.IsEditForm]: isEditForm,
  }

  // if (defaultValues && defaultValues.workingHours) {
  //   template.initialWorkingHours = _.cloneDeep(defaultValues.workingHours)
  // }

  const initialValues: Record<string, any> = {}

  Object.keys(template).forEach(key => {
    if (defaultValues && defaultValues[key]) {
      if (!Array.isArray(defaultValues[key]) && Object.keys(defaultValues[key]).length) {
        initialValues[key] = {}

        Object.keys((template as Record<string, any>)[key]).forEach(key2 => {
          if (defaultValues[key][key2]) {
            initialValues[key][key2] = defaultValues[key][key2]
          } else {
            initialValues[key][key2] = (template as Record<string, any>)[key][key2]
          }
        })
      } else {
        initialValues[key] = defaultValues[key]
      }
    } else {
      initialValues[key] = (template as Record<string, any>)[key]
    }
  })

  return initialValues as InitialFormValues
}
