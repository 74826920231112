import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromVaultV1CashOrders from './reducer'

export const getVaultsOrdState = (state: AppState): fromVaultV1CashOrders.State => {
  return state.vaultV1CashOrders
}

export const getOrdOrders = createSelector(getVaultsOrdState, state => {
  return state.orders
})

export const getButtonsState = createSelector(getVaultsOrdState, state => {
  return {
    canCreateWorkOrder: state.orders.data.some(order => order.canCreateWorkOrder),
    canEditOrder: state.orders.selectedOrder?.canEditOrder,
    canCreateCashOrder: state.orders.canCreateCashOrder,
    canCreateExportOrder: state.orders.canCreateExportOrder,
  }
})

export const getStageById = (id: number) =>
  createSelector(getVaultsOrdState, state => {
    return state.orderStages.data.find(stage => stage.id === id)
  })

export const getOrdSelectedOrder = createSelector(getVaultsOrdState, state => {
  return state.orders.selectedOrder
})

export const getOrdOrdersStages = createSelector(getVaultsOrdState, state => {
  return state.orderStages
})

export const getOrdOrdersCashierIdStageData = createSelector(
  getOrdOrdersStages,
  ({ cashierIdRequest }) => cashierIdRequest,
)

export const getOrdDenominations = createSelector(getVaultsOrdState, state => {
  return state.denominations
})
export const getOrdCashOrderCurrency = createSelector(getVaultsOrdState, state => {
  return state.cashOrderCurrency
})
export const getOrdSources = createSelector(getVaultsOrdState, state => {
  return state.sources
})
export const getOrdDestinations = createSelector(getVaultsOrdState, state => {
  return state.destinations
})
export const getOrdOrderDate = createSelector(getVaultsOrdState, state => {
  return state.orderDate
})

export const getFilterOptions = createSelector(getVaultsOrdState, state => {
  return state.filterOptions
})

export const getBagSeals = createSelector(getVaultsOrdState, state => {
  return state.bagSeals
})

export const getValuables = createSelector(getVaultsOrdState, state => {
  return state.valuables
})

export const getWorkOrders = createSelector(getVaultsOrdState, state => {
  return state.workOrders
})

export const getGroupedWorkOrders = createSelector(getVaultsOrdState, state => {
  return state.groupedWorkOrders
})

export const getExportWorkOrders = createSelector(getVaultsOrdState, state => {
  return state.exportWorkOrders
})

export const getStageIntegration = createSelector(getVaultsOrdState, state => {
  return state.stageIntegration
})
export const getRetryStageId = createSelector(getVaultsOrdState, state => {
  return state.stageIntegration.retryStageId
})
export const getRetryStageIdLoading = (stageId: string) =>
  createSelector(getVaultsOrdState, state => {
    const { retryStageId } = state.stageIntegration
    return retryStageId === stageId
  })
export const getStageIntegrationStages = (stageId: string) =>
  createSelector(getVaultsOrdState, state => {
    if (state.stageIntegration.statuses) {
      const status = state.stageIntegration.statuses.find(status => status.orderStatus === stageId)

      return status
    }
  })
