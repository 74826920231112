import { FormikValues } from 'formik'
import moment from 'moment'

import { InitialParameters, POSServiceFormParameter } from '@/api/sd/posApi/types'
import {
  DocumentInformationFields,
  FormattedRequestItems,
} from '@/components/blocks/PSRTicketDetails/components/DocumenInformationModal/components/DocumenInformationTable/types'
import {
  FIELD_NAME_TERMINAL_IDS,
  FIELD_NAME_TERMINALS,
} from '@/components/wrappers/AppNavigation/components/DrawerActions/components/modals/ServiceTypeModal/components/ServiceTypeForm/types'
import { CreatePOSRequestFormFields } from '@/constants/forms/createPosRequestForms/CreatePOSRequestForm'
import { NewMerchantFormFields } from '@/constants/forms/NewMerchantForm'
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT } from '@/constants/time'
import { getPOSServiceFormInitialValues } from '@/store/sd/drawerActions/selectors/newPOS'
import { SavePosTicketRequest } from '@/types'

import { POSServiceTypeItem, ServiceTableData } from './types'

export enum ValueClasses {
  String = 1,
  Float = 2,
  Decimal = 3,
  DateTime = 4,
  Boolean = 5,
  Integer = 6,
  Date = 7,
  Time = 8,
}

function isNumber(value?: string | number): boolean {
  return value != null && value !== '' && !isNaN(Number(value.toString()))
}

export const NumberValueClasses = [ValueClasses.Float, ValueClasses.Decimal, ValueClasses.Integer]

export const getServiceTypeFormData = (
  values: FormikValues,
  parameters: POSServiceFormParameter,
): { [key: string]: number | string } => {
  return Object.keys(values).reduce((accum, key) => {
    if (key === FIELD_NAME_TERMINALS || key === FIELD_NAME_TERMINAL_IDS || key === 'deviceid') {
      return { ...accum }
    }

    if (!parameters[key]) {
      return { ...accum }
    }

    const { valueClass } = parameters[key]
    let value = values[key]

    if (!value) {
      return { ...accum }
    }

    if (NumberValueClasses.includes(valueClass)) {
      if (Array.isArray(value)) {
        value = value.filter(it => isNumber(it))
        value = value.join()
      } else {
        value = value || value === 0 ? Number(value) : null
      }
    }

    if (valueClass === ValueClasses.Boolean) {
      value = value === 'true' || value === true
    }

    if (key === 'posservicestartdate') {
      value = moment(value).toISOString()
    }

    return {
      ...accum,
      [key]: value,
    }
  }, {})
}

const handleNewMerchantFormValues = (values: FormikValues, sameAddress: boolean) => {
  const {
    customerRequestNumber,
    merchantId,
    serviceCompany,

    // actual
    merchantcountryid,
    merchantpostalcode,
    merchantcityid,
    merchantcity,
    merchantstreet,
    // legal
    merchantlegcountryid,
    merchantlegpostalcode,
    merchantlegcityid,
    merchantlegcity,
    merchantlegstreet,

    remarks,
    merchantemail,
    merchantfullname,
    merchantphone,
    merchantvatcode,
    merchantcontactperson,
    merchantidentificationnr,
  } = values

  const resp = {
    customerRequestNumber: customerRequestNumber,
    merchantId: merchantId ? parseInt(merchantId) : '',
    serviceCompanyId: parseInt(serviceCompany),
    newMerchant: {
      actualAddressEqualsLegalAddress: sameAddress,
      actualAddress: {
        cityId: sameAddress ? merchantlegcityid : merchantcityid,
        countryId: sameAddress ? merchantlegcountryid : merchantcountryid,
        newCity: sameAddress ? merchantlegcity : merchantcity,
        postalCode: sameAddress ? merchantlegpostalcode : merchantpostalcode,
        street: sameAddress ? merchantlegstreet : merchantstreet,
      },
      legalAddress: {
        cityId: merchantlegcityid,
        countryId: merchantlegcountryid,
        newCity: merchantlegcity,
        postalCode: merchantlegpostalcode,
        street: merchantlegstreet,
      },
      remarks,
      email: merchantemail,
      legalName: merchantfullname,
      phoneNumber: merchantphone,
      vatCode: merchantvatcode,
      contactPerson: merchantcontactperson,
      companyCode: merchantidentificationnr,
    },
  }

  return resp
}

export const getSavePOSTicketRequestFormData = ({
  openNewMerchantForm,
  values,
  saveRequestInitialParameters,
}: {
  openNewMerchantForm: boolean
  values: FormikValues
  saveRequestInitialParameters: Partial<InitialParameters>
}): SavePosTicketRequest => {
  const {
    customerRequestNumber,
    merchantId,
    serviceCompany,
    sameaslegaladdress: sameAsLegalAddress,
  } = values

  if (openNewMerchantForm) {
    return {
      ...handleNewMerchantFormValues(values, sameAsLegalAddress),
      saveRequestParameters: {
        ...saveRequestInitialParameters,

        docnr: customerRequestNumber,
        docdate: moment(new Date()).toISOString(),
        originaldocnr: customerRequestNumber,
      },
      receiveDate: moment(new Date()).toISOString(),
      dataWayEnum: 'WEB',
    }
  } else {
    return {
      customerRequestNumber: customerRequestNumber,
      merchantId: parseInt(merchantId),
      serviceCompanyId: parseInt(serviceCompany),
      newMerchant: null,
      saveRequestParameters: {
        ...saveRequestInitialParameters,

        docnr: customerRequestNumber,
        docdate: moment(new Date()).toISOString(),
        originaldocnr: customerRequestNumber,
      },
      receiveDate: moment(new Date()).toISOString(),
      dataWayEnum: 'WEB',
    }
  }
}

export const geEditPOSTicketRequestFormData = ({
  openNewMerchantForm,
  values,
  hasServiceItems,
  receiveDate,
}: {
  openNewMerchantForm: boolean
  values: FormikValues
  hasServiceItems: boolean
  receiveDate: string
}): FormikValues => {
  const {
    customerRequestNumber,
    merchantId,
    serviceCompany,
    sameaslegaladdress: sameAsLegalAddress,
  } = values

  if (openNewMerchantForm) {
    return {
      ...handleNewMerchantFormValues(values, sameAsLegalAddress),
      receiveDate,
    }
  } else {
    return {
      receiveDate,
      customerRequestNumber,
      serviceCompanyId: parseInt(serviceCompany),
      ...(!hasServiceItems && {
        merchantId: parseInt(merchantId),
        newMerchant: null,
      }),
    }
  }
}

export const getFormInitialValues = ({
  currentCompanyName,
  customerNumber,
  companies,
  editingPosTicketInfo,
  isEditingPOSTicket,
  merchants,
  fieldParameters,
}: {
  currentCompanyName: string | null
  customerNumber?: string
  companies: Array<{ name: string; value: string }>
  editingPosTicketInfo: { [key: string]: string | number | null }
  isEditingPOSTicket: boolean
  merchants: Array<{ name: string; value: string }>
  fieldParameters: any
}): FormikValues => {
  let newMerchantFormFields
  if (fieldParameters) {
    newMerchantFormFields = getPOSServiceFormInitialValues(fieldParameters)
  }
  const date = new Date()
  date.setDate(date.getDate() + 1)
  const initValues = {
    [CreatePOSRequestFormFields.AcquirerBank]: currentCompanyName || '',
    [CreatePOSRequestFormFields.CustomerRequestNumber]: customerNumber || '',
    [CreatePOSRequestFormFields.ServiceCompany]: companies.length === 1 ? companies[0].value : '',
    [CreatePOSRequestFormFields.Merchant]: '',
    [CreatePOSRequestFormFields.Date]: moment().format(DEFAULT_DATE_TIME_FORMAT),
    [CreatePOSRequestFormFields.ServiceType]: '',
    ...(newMerchantFormFields && { ...newMerchantFormFields }),
  }
  const isEditingPosTicketWithNewMerchant =
    isEditingPOSTicket && editingPosTicketInfo.merchantfullname
  const getStringValue = (value: string | number | null): string => (value && `${value}`) || ''

  if (isEditingPOSTicket) {
    const {
      docnr,
      originaldocnr,
      recipientcompanyid,
      targetcompanyid,
      docdate,
      merchantfullname,
      merchantidentificationnr,
      merchantvatcode,
      merchantemail,
      merchantphone,
      merchantcontactperson,
      merchantlegcountryid,
      merchantlegcityid,
      merchantlegcity,
      merchantlegstreet,
      merchantlegpostalcode,
      merchantcity,
      merchantcityid,
      merchantcountryid,
      merchantstreet,
      notes,
      receivedate,
      merchantpostalcode,
      equalmerchantlegaladdr,
    } = editingPosTicketInfo
    const initialCreatingPosTicketFormValues = {
      [CreatePOSRequestFormFields.CustomerRequestNumber]: `${originaldocnr}` || '',
      [CreatePOSRequestFormFields.ServiceCompany]:
        companies.find(c => c.value === `${recipientcompanyid}`)?.value || '',
      [CreatePOSRequestFormFields.Merchant]: `${targetcompanyid}`,
      [CreatePOSRequestFormFields.Date]: receivedate
        ? moment(receivedate).format(DEFAULT_DATE_TIME_FORMAT)
        : moment(docdate).format(DEFAULT_DATE_FORMAT),
    }

    if (isEditingPosTicketWithNewMerchant) {
      return {
        ...initValues,
        ...initialCreatingPosTicketFormValues,

        [NewMerchantFormFields.LegalName]: getStringValue(merchantfullname),
        [NewMerchantFormFields.CompanyCode]: getStringValue(merchantidentificationnr),
        [NewMerchantFormFields.VATCode]: getStringValue(merchantvatcode),
        [NewMerchantFormFields.LegalAddressCountry]: getStringValue(merchantlegcountryid),
        [NewMerchantFormFields.LegalAddressCity]: getStringValue(merchantlegcityid),
        [NewMerchantFormFields.LegalAddressNewCity]: getStringValue(merchantlegcity),
        [NewMerchantFormFields.LegalAddressStreet]: getStringValue(merchantlegstreet),
        [NewMerchantFormFields.LegalAddressPostal]: getStringValue(merchantlegpostalcode),
        [NewMerchantFormFields.ActualAddressCountry]: getStringValue(merchantcountryid),
        [NewMerchantFormFields.ActualAddressCity]: getStringValue(merchantcityid),
        [NewMerchantFormFields.ActualAddressNewCity]: getStringValue(merchantcity),
        [NewMerchantFormFields.ActualAddressStreet]: getStringValue(merchantstreet),
        [NewMerchantFormFields.ActualAddressPostal]: getStringValue(merchantpostalcode),
        [NewMerchantFormFields.ContactPerson]: getStringValue(merchantcontactperson),
        [NewMerchantFormFields.PhoneNumber]: getStringValue(merchantphone),
        [NewMerchantFormFields.Email]: getStringValue(merchantemail),
        [NewMerchantFormFields.Remarks]: getStringValue(notes),
        [NewMerchantFormFields.SameAddress]: equalmerchantlegaladdr,
      }
    }

    return {
      ...initValues,
      ...initialCreatingPosTicketFormValues,
    }
  } else {
    return initValues
  }
}

export const convertRemoteServicesForTable = (
  serviceTypes: {
    name: string
    value: string
  }[],
) => ({ posFormDataParameters }: POSServiceTypeItem): Partial<FormattedRequestItems> => ({
  [DocumentInformationFields.TerminalID]: posFormDataParameters.posaquirerluno,
  [DocumentInformationFields.ShopBrandName]: posFormDataParameters.subject,
  [DocumentInformationFields.ItemNo]: posFormDataParameters.itemno,
  [DocumentInformationFields.Service]:
    serviceTypes.find(s => s.value === `${posFormDataParameters.posserviceid}`)?.name || '',
  [DocumentInformationFields.Priority]: posFormDataParameters.pospriorityid,
  [DocumentInformationFields.POSAddress]: posFormDataParameters.posfulladdress,
  [DocumentInformationFields.MerchantID]: posFormDataParameters.posmerchantid,
  [DocumentInformationFields.MCCCode]: posFormDataParameters.posmcccode,
  [DocumentInformationFields.TerminalType]: posFormDataParameters.posterminaltypeid,
  [DocumentInformationFields.ConnectionType]: posFormDataParameters.posconnectiontypeid,
  [DocumentInformationFields.ContactPerson]: posFormDataParameters.poscontactperson,
  [DocumentInformationFields.Phone]: posFormDataParameters.posphone,
  [DocumentInformationFields.Notes]: posFormDataParameters.notes,
})

export const converLocalServicesForTable = (
  serviceTypes: {
    name: string
    value: string
  }[],
) => (serv: { formValues: FormikValues }): Partial<FormattedRequestItems> => {
  const element = serv.formValues
  let terminalIds
  if (serv.formValues) {
    if (serv.formValues.terminals) {
      terminalIds = serv.formValues.terminals.map(
        (t: { acquirerLuno: string; deviceName: string }) => ({
          id: t.acquirerLuno !== undefined ? t.acquirerLuno : t,
          deviceName: t.deviceName || element.subject,
        }),
      )
    } else {
      terminalIds = serv.formValues.terminalIds.map((t: any) => ({
        id: t,
        deviceName: serv.formValues.subject,
      }))
    }
  }

  const serviceMainContent = {
    [DocumentInformationFields.Service]: serviceTypes.find(
      s => s.value === `${element.posserviceid}`,
    )?.name,
    [DocumentInformationFields.Priority]: element.pospriorityid || '',
    [DocumentInformationFields.POSAddress]: element.posstreet,
    [DocumentInformationFields.MerchantID]: element.posmerchantid,
    [DocumentInformationFields.MCCCode]: element.posmcccode,
    [DocumentInformationFields.TerminalType]: element.posterminaltypeid,
    [DocumentInformationFields.ConnectionType]: element.posconnectiontypeid,
    [DocumentInformationFields.ContactPerson]: element.poscontactperson,
    [DocumentInformationFields.Phone]: element.posphone,
    [DocumentInformationFields.Notes]: element.notes,
  }
  if (terminalIds) {
    return terminalIds.map((obj: { id: string; deviceName: string }) => ({
      [DocumentInformationFields.TerminalID]: obj.id,
      [DocumentInformationFields.ShopBrandName]: obj.deviceName || serv.formValues.subject,
      ...serviceMainContent,
    }))
  }
  return serv.formValues.terminalIds.map((id: string) => ({
    [DocumentInformationFields.TerminalID]: id,
    [DocumentInformationFields.ShopBrandName]: serv.formValues.subject,
    ...serviceMainContent,
  }))
}
