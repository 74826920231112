import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing, mixins, palette, shape: { borderRadius } }) => ({
  bookmarkWrapper: {
    flex: 1,
    overflowX: 'auto',
  },
  bookmarkDropper: {
    ...mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  bookmarkText: {
    display: 'inline',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '6px 30px 6px 12px',
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
  bookmark: {
    minWidth: spacing(15),
    width: spacing(15),
    maxWidth: spacing(15),
    height: '26px',
    // padding: spacing(0, 1),
    margin: '22px 6px 22px 6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // margin: spacing(1.5, 0),
    backgroundColor: '#ECF2F2',
    borderRadius,
    '&:hover > $bookmarkIcon': {
      display: 'flex',
      alignItems: 'center',
    },
    '& + $bookmark': {
      // marginLeft: spacing(1),
    },
  },
  bookmarkIcon: {
    display: 'none',
    width: spacing(3),
    height: spacing(3),
  },
}))
