import queryString from 'query-string'

import { AdmVehicle, AdmVehicleBase } from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

interface GetVehiclesProps extends Sort {
  guid: string
}

export class VehicleApi {
  private static requestService = getRequestServiceCM()

  public static async get(payload: GetVehiclesProps): Promise<AdmVehicle[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/vehicles',
          query: { ...payload },
        },
        { skipNull: true, encode: false },
      )

      return await VehicleApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(payload: AdmVehicleBase): Promise<void> {
    try {
      return await VehicleApi.requestService.post('/admin/vehicles/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(payload: AdmVehicle): Promise<void> {
    try {
      return await VehicleApi.requestService.put('/admin/vehicles/update', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(guid: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/vehicles/delete',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )

      return await VehicleApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
