import moment from 'moment'
import { AnyAction } from 'redux'

import { BalCashPoint, BalCashPointsFilter, ComCMReport } from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants'
import { balCashPointsTableId as tableId } from '@/constants/cm/cmTableIds'
import { getTableConfig } from '@/helpers/tableLocalStorage'

export type State = {
  isFiltersModalOpen: boolean
  isDocumentModalOpen: boolean
  isSettingsModalOpen: boolean
  isCashBalancingModalOpen: boolean

  declineNoteState: {
    isModalOpen: boolean
    isLoading: boolean
    error: string | null
    data: any // PlnDeclineNote
  }
  cashPoints: {
    selectedCashPoint: BalCashPoint | null
    checkedCashPoints: { [key: string]: BalCashPoint }
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined
    data: BalCashPoint[]
    total: number
    isLoading: boolean
    isLoaded: boolean
    error: string | null
    filter: BalCashPointsFilter
    paging: {
      page: number
      pageSize: number
    }
    reports: {
      errorMessage?: string
      isLoading: boolean
      list: ComCMReport[]
      selectedReport: {
        isLoading: boolean
        errorMessage?: string
        bytes?: Blob | null
      }
    }
    selectedCashPointReports: {
      errorMessage?: string
      isLoading: boolean
      list: ComCMReport[]
      selectedReport: {
        isLoading: boolean
        errorMessage?: string
        bytes?: Blob | null
      }
    }
  }

  sortersType: 'mix' | 'denom'
  sortersKey: string
}

export const initialState: State = {
  isFiltersModalOpen: false,
  isDocumentModalOpen: false,
  isSettingsModalOpen: false,
  isCashBalancingModalOpen: false,

  declineNoteState: {
    isModalOpen: false,
    isLoading: false,
    error: null,
    data: null,
  },
  cashPoints: {
    selectedCashPoint: null,
    checkedCashPoints: {},
    sortColumn: undefined,
    sortOrder: undefined,
    data: [],
    total: 0,
    isLoading: false,
    isLoaded: false,
    error: null,
    filter: {
      orderId: '',
      cashierUid: '',
      orderStatus: undefined,
      stamps: '',
      balDateFrom: moment()
        .subtract(1, 'months')
        .format(),
      balDateTo: '',
      startCycleDateFrom: moment()
        .subtract(2, 'months')
        .format(),
      startCycleDateTo: '',
      endCycleDateFrom: moment().format(),
      endCycleDateTo: '',
    },
    paging: {
      page: 1,
      pageSize: getTableConfig({ tableId })?.rowPerPage || 50,
    },
    reports: {
      errorMessage: '',
      isLoading: false,
      list: [],
      selectedReport: {
        isLoading: false,
        errorMessage: '',
        bytes: undefined,
      },
    },
    selectedCashPointReports: {
      errorMessage: '',
      isLoading: false,
      list: [],
      selectedReport: {
        isLoading: false,
        errorMessage: '',
        bytes: undefined,
      },
    },
  },

  sortersType: 'denom',
  sortersKey: 'remaining',
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case CMActions.BalSetSortersType:
      return {
        ...state,
        sortersType: payload.type,
        sortersKey: payload.columnKey,
      }
    case CMActions.SetGlobalFilter:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          paging: {
            ...state.cashPoints.paging,
            page: 1,
          },
        },
      }
    case CMActions.BalSortTable:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          ...payload,
          isLoading: true,
        },
      }
    case CMActions.BalSetCashPointsPagingResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          paging: {
            ...state.cashPoints.paging,
            ...payload,
          },
        },
      }
    case CMActions.BalGetReportRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            selectedReport: {
              ...state.cashPoints.reports.selectedReport,
              isLoading: true,
              errorMessage: '',
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.BalGetReportResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            selectedReport: {
              ...state.cashPoints.reports.selectedReport,
              isLoading: false,
              errorMessage: '',
              bytes: payload,
            },
          },
        },
      }
    case CMActions.BalGetReportResponseFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            selectedReport: {
              ...state.cashPoints.reports.selectedReport,
              isLoading: false,
              errorMessage: payload,
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.BalGetReportsRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            isLoading: true,
            list: [],
            errorMessage: '',
            selectedReport: initialState.cashPoints.reports.selectedReport,
          },
        },
      }
    case CMActions.BalGetReportsResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            isLoading: false,
            list: payload,
            errorMessage: '',
          },
        },
      }
    case CMActions.BalGetReportsResponseFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          reports: {
            ...state.cashPoints.reports,
            isLoading: false,
            list: [],
            errorMessage: payload,
          },
        },
      }
    case CMActions.BalGetCashPointReportRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          selectedCashPointReports: {
            ...state.cashPoints.selectedCashPointReports,
            selectedReport: {
              ...state.cashPoints.selectedCashPointReports.selectedReport,
              isLoading: true,
              errorMessage: '',
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.BalGetCashPointReportResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          selectedCashPointReports: {
            ...state.cashPoints.selectedCashPointReports,
            selectedReport: {
              ...state.cashPoints.selectedCashPointReports.selectedReport,
              isLoading: false,
              errorMessage: '',
              bytes: payload,
            },
          },
        },
      }
    case CMActions.BalGetCashPointReportResponseFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          selectedCashPointReports: {
            ...state.cashPoints.selectedCashPointReports,
            selectedReport: {
              ...state.cashPoints.selectedCashPointReports.selectedReport,
              isLoading: false,
              errorMessage: payload,
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.BalGetCashPointReportsRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          selectedCashPointReports: {
            ...state.cashPoints.selectedCashPointReports,
            isLoading: true,
            list: [],
            errorMessage: '',
            selectedReport: initialState.cashPoints.selectedCashPointReports.selectedReport,
          },
        },
      }
    case CMActions.BalGetCashPointReportsResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          selectedCashPointReports: {
            ...state.cashPoints.selectedCashPointReports,
            isLoading: false,
            list: payload,
            errorMessage: '',
          },
        },
      }
    case CMActions.BalGetCashPointReportsResponseFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          selectedCashPointReports: {
            ...state.cashPoints.selectedCashPointReports,
            isLoading: false,
            list: [],
            errorMessage: payload,
          },
        },
      }
    case CMActions.BalCheckAllCashPoints: {
      const res: State['cashPoints']['checkedCashPoints'] = {}
      const allCashPoints = payload
      for (const cashPoint of allCashPoints) {
        if (cashPoint?.id) {
          res[cashPoint.id] = cashPoint
        }
      }
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          checkedCashPoints: res,
        },
      }
    }
    case CMActions.BalClearAllCashPoints: {
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          checkedCashPoints: {},
        },
      }
    }
    case CMActions.BalToggleCashPoint: {
      const newCheckedCashPoints = { ...state.cashPoints.checkedCashPoints }
      const soughtCP = newCheckedCashPoints[payload?.id]
      if (!soughtCP) {
        newCheckedCashPoints[payload?.id] = payload
      } else {
        delete newCheckedCashPoints[payload?.id]
      }
      return {
        ...state,
        cashPoints: {
          ...state?.cashPoints,
          checkedCashPoints: newCheckedCashPoints,
        },
      }
    }
    case CMActions.BalResetCashPointsPage:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          paging: {
            ...state.cashPoints.paging,
            page: initialState?.cashPoints?.paging?.page || 1,
          },
        },
      }
    case CMActions.BalSetCashPointsFilter:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          filter: {
            ...state.cashPoints.filter,
            ...payload,
          },
        },
      }
    case CMActions.BalSetFiltersModalOpen:
      return {
        ...state,
        isFiltersModalOpen: payload,
      }
    case CMActions.BalSetDocumentModalOpen:
      return {
        ...state,
        isDocumentModalOpen: payload,
      }
    case CMActions.BalSetSettingsModalOpen:
      return {
        ...state,
        isSettingsModalOpen: payload,
      }
    case CMActions.BalSetCashBalancingModalOpen:
      return {
        ...state,
        isCashBalancingModalOpen: payload,
      }

    case CMActions.BalSetSelectedCashPoint:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          selectedCashPoint: payload,
        },
      }
    case CMActions.BalRefreshCashPointsPanel:
    case CMActions.BalGetCashPointsRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: true,
          error: null,
        },
      }
    case CMActions.BalGetCashPointsResponse:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          data: payload?.data,
          total: payload?.total,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      }
    case CMActions.BalGetCashPointsResponseFail:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          total: 0,
          isLoading: false,
          isLoaded: true,
          error: payload,
        },
      }

    case CMActions.BalAssignCashierRequest:
      return {
        ...state,
        cashPoints: {
          ...state.cashPoints,
          isLoading: true,
        },
      }
    case CMActions.SignOut:
      return initialState
    default:
      return state
  }
}
