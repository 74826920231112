import { takeLatest } from 'redux-saga/effects'
import { makeRequestWithSpinner, OptionsType } from '@/store/usm/sagaWorkers/saga'
import {
  ObjectPermissionsResponse,
  ReturnAction,
  userGroupAndPermissionsType,
  UserGroupType,
} from '@/types/usm/userGroup/userGroup'
import { actionUserGroupUSM } from '@/store/usm/userGroup/actions'
import { UserGroupUSMAPI } from '@/api/usm/userGroup'
import { AnyAction } from 'redux'
import { CurrentUserGroupInstallationValues } from '@/components/pages/usm/GroupPermissionsPage/components/CurrentUserGroup/types'

// get-all-ser-group

function* getAllUserGroup({ payload }: AnyAction): Generator {
  const fetcher = new UserGroupUSMAPI(payload)
  const options: OptionsType<Array<UserGroupType>, any> = {
    fetcher: fetcher.getAllGroups.bind(fetcher),
    startFetching: actionUserGroupUSM.startFetchingAllUserGroups,
    stopFetching: actionUserGroupUSM.stopFetchingAllUserGroups,
    fill: actionUserGroupUSM.setAllUserGroupResponse,
    setErrorAction: actionUserGroupUSM.setAllUserGroupResponseFall,
    fetcherParam: payload,
  }

  yield makeRequestWithSpinner<Array<UserGroupType>, any>(options)
}

// add-new-user-group
function* addGNewUserGroup({
  payload,
  actions,
}: ReturnAction<UserGroupType, UserGroupType>): Generator {
  const fetcher = new UserGroupUSMAPI<UserGroupType>(payload)
  const options: OptionsType<userGroupAndPermissionsType, UserGroupType> = {
    fetcher: fetcher.addGNewGroup.bind(fetcher),
    startFetching: actionUserGroupUSM.startFetchingAddGroup,
    stopFetching: actionUserGroupUSM.stopFetchingAddGroup,
    fill: actionUserGroupUSM.setAddGroupResponse,
    setErrorAction: actionUserGroupUSM.setAddGroupResponseFall,
    fetcherParam: payload,
    formActions: actions,
  }

  yield makeRequestWithSpinner<userGroupAndPermissionsType, UserGroupType>(options)
}

// update-user-group

function* updateUserGroups({
  payload,
}: ReturnAction<never, CurrentUserGroupInstallationValues>): Generator {
  const fetcher = new UserGroupUSMAPI(payload)
  const options: OptionsType<
    Array<userGroupAndPermissionsType>,
    CurrentUserGroupInstallationValues
  > = {
    fetcher: fetcher.updateUserGroup.bind(fetcher),
    startFetching: actionUserGroupUSM.startFetchingUpdateUserGroups,
    stopFetching: actionUserGroupUSM.stopFetchingUpdateUserGroups,
    fill: actionUserGroupUSM.fillUpdateUserGroupsResponse,
    setErrorAction: actionUserGroupUSM.fillUpdateUserGroupsResponseFall,
    fetcherParam: payload,
  }

  yield makeRequestWithSpinner<
    Array<userGroupAndPermissionsType>,
    CurrentUserGroupInstallationValues
  >(options)
}

// object-user-tree
function* getObjectTree({ payload }: AnyAction): Generator {
  const fetcher = new UserGroupUSMAPI(payload)
  const options: OptionsType<Array<ObjectPermissionsResponse>, any> = {
    fetcher: fetcher.getPermissionsObjectTree.bind(fetcher),
    startFetching: actionUserGroupUSM.startFetchingObjectTree,
    stopFetching: actionUserGroupUSM.stopFetchingObjectPermissionsTree,
    fill: actionUserGroupUSM.setObjectPermissionsTreeResponse,
    setErrorAction: actionUserGroupUSM.setObjectPermissionsTreeFall,
    fetcherParam: payload,
  }

  yield makeRequestWithSpinner<Array<ObjectPermissionsResponse>, any>(options)
}

// current-user-group

function* getCurrentUserGroupAndPermissions({ payload }: AnyAction): Generator {
  const fetcher = new UserGroupUSMAPI(payload)
  const options: OptionsType<userGroupAndPermissionsType, any> = {
    fetcher: fetcher.getUserGroupId.bind(fetcher),
    startFetching: actionUserGroupUSM.startFetchingGetCurrentGroup,
    stopFetching: actionUserGroupUSM.stopFetchingGetCurrentGroup,
    fill: actionUserGroupUSM.setCurrentGroupResponse,
    setErrorAction: actionUserGroupUSM.setCurrentGroupResponseFall,
    fetcherParam: payload,
  }

  yield makeRequestWithSpinner<userGroupAndPermissionsType, any>(options)
}

// delete-user-group-by-id
function* deleteUserGroupById({ payload }: AnyAction): Generator {
  const fetcher = new UserGroupUSMAPI(payload)
  const options: OptionsType<any, any> = {
    fetcher: fetcher.deleteUserGroupById.bind(fetcher),
    startFetching: actionUserGroupUSM.startFetchingDeleteUserGroup,
    stopFetching: actionUserGroupUSM.stopFetchingDeleteUserGroup,
    fill: actionUserGroupUSM.fillDeleteUserGroupResponse,
    setErrorAction: actionUserGroupUSM.setDeleteUserGroupResponseFall,
    fetcherParam: payload,
  }

  yield makeRequestWithSpinner<any, any>(options)
}

export default function*(): Generator {
  yield takeLatest('@/ADD_GROUP_ASYNC_USM', addGNewUserGroup)
  yield takeLatest('@/GET_ALL_USER_GROUP_ASYNC_USM', getAllUserGroup)
  yield takeLatest('@/OBJECT_TREE_PERMISSIONS_ASYNC_USM', getObjectTree)
  yield takeLatest('@/GET_CURRENT_USER_GROUP_ASYNC_USM', getCurrentUserGroupAndPermissions)
  yield takeLatest('@/DELETE_USER_GROUP_BY_ID_ASYNC', deleteUserGroupById)
  yield takeLatest('@/GET_UPDATE_USER_GROUPS_ASYNC_USM', updateUserGroups)
}
