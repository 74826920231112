import { useQuery, UseQueryResult } from 'react-query'

import { BagCassetteWithStamp, ComUuidValue } from '@/api/cm/swaggerGeneratedApi'
import { queryClient } from '@/constants/queryClient'

import { CitWODetailsApi } from '..'

const queryOptions = {
  retry: 0,
  refetchOnWindowFocus: false,
}

interface Props {
  orderId?: string
  cassetteSetId?: string
  vaultId?: string
  enabled: boolean
}

export const useQueryCitPlanningBags = ({
  cassetteSetId,
  vaultId,
  orderId,
  enabled,
}: Props): UseQueryResult<ComUuidValue[], Error> =>
  useQuery(
    ['citPlanningBags', orderId],
    async () =>
      cassetteSetId && vaultId && (await CitWODetailsApi.getBags({ cassetteSetId, vaultId })),
    {
      ...queryOptions,
      refetchOnWindowFocus: true,
      enabled: !!cassetteSetId && !!vaultId && enabled,
    },
  )

export const refetchCitPlanningBags = ({ orderId }: { orderId?: string }): unknown =>
  queryClient.fetchQuery(['citPlanningBags', orderId])

interface BagCassetteProps {
  bagId?: string
  enabled: boolean
}

export const useQueryBagCassettes = ({
  bagId,
  enabled,
}: BagCassetteProps): UseQueryResult<BagCassetteWithStamp[], Error> =>
  useQuery(
    ['citPlanningBagCassettes', bagId],
    async () => bagId && (await CitWODetailsApi.getBagCassettes(bagId)),
    {
      ...queryOptions,
      enabled: !!bagId && enabled,
    },
  )
