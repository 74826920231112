import { AnyAction } from 'redux'

import { TabBarItem, TabBarItemExtended } from '@/components/wrappers/AppNavigation/types'
import { PortalActions } from '@/constants'

export type State = {
  openTabs: TabBarItemExtended[]
}

export const initialState: State = {
  openTabs: [],
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case PortalActions.SetOpenTabs: {
      return { ...state, openTabs: [...payload] }
    }
    case PortalActions.SetCurrentTab: {
      const currentPage = state.openTabs.find(tab => tab.path === payload.currentPath)
      if (currentPage) {
        switch (currentPage.isInitialOpen) {
          case undefined:
            currentPage.isInitialOpen = true
            break
          case true:
            currentPage.isInitialOpen = false
            break
          case false:
            currentPage.isInitialOpen = false
            break
          default:
            break
        }
      }
      return state
    }
    case PortalActions.OpenTab: {
      return { ...state, openTabs: [...state.openTabs, payload] }
    }
    default:
      return state
  }
}
