import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { CurrenciesApi } from '@/api/cm/adminApi'
import { CMAppConfigActions } from '@/constants'

import {
  addAdmCurrencyFail,
  deleteAdmCurrencyFail,
  getAdmCurrenciesFail,
  getAdmCurrenciesRequest,
  getAdmCurrenciesResponse,
  setAdmCurrencyAsBaseFail,
  updateAdmCurrencyFail,
} from './actions'
import { getCurrenciesSort } from './selectors'

function* getCurrencies(): SagaIterator {
  try {
    const sort = yield select(getCurrenciesSort)

    const response = yield call(CurrenciesApi.get, sort)

    yield put(getAdmCurrenciesResponse(response))
  } catch (error) {
    yield put(getAdmCurrenciesFail())
  }
}

function* addCurrency({ payload }: AnyAction): SagaIterator {
  try {
    yield call(CurrenciesApi.add, payload)

    yield put(getAdmCurrenciesRequest())
  } catch (error) {
    yield put(addAdmCurrencyFail())
  }
}

function* updateCurrency({ payload }: AnyAction): SagaIterator {
  try {
    yield call(CurrenciesApi.update, payload)

    yield put(getAdmCurrenciesRequest())
  } catch (error) {
    yield put(updateAdmCurrencyFail())
  }
}

function* deleteCurrency({ payload }: AnyAction): SagaIterator {
  try {
    yield call(CurrenciesApi.delete, payload)

    yield put(getAdmCurrenciesRequest())
  } catch (error) {
    yield put(deleteAdmCurrencyFail())
  }
}

function* setBaseCurrency({ payload }: AnyAction): SagaIterator {
  try {
    yield call(CurrenciesApi.setBase, payload)

    yield put(getAdmCurrenciesRequest())
  } catch (error) {
    yield put(setAdmCurrencyAsBaseFail())
  }
}

export default function*(): Generator {
  yield takeLatest(
    [CMAppConfigActions.AdmGetCurrenciesRequest, CMAppConfigActions.AdmSortCurrencies],
    getCurrencies,
  )
  yield takeLatest(CMAppConfigActions.AdmAddCurrencyRequest, addCurrency)
  yield takeLatest(CMAppConfigActions.AdmUpdateCurrencyRequest, updateCurrency)
  yield takeLatest(CMAppConfigActions.AdmSetCurrencyAsBaseRequest, setBaseCurrency)
  yield takeLatest(CMAppConfigActions.AdmDeleteCurrencyRequest, deleteCurrency)
}
