export const deepCopy = (inObject: any): any => {
  if (typeof inObject !== 'object' || inObject === null) {
    return inObject
  }

  const outObject: typeof inObject = Array.isArray(inObject) ? [] : {}
  let key

  for (key in inObject) {
    const value = inObject[key]
    outObject[key] = deepCopy(value)
  }

  return outObject
}
