import { generateAtmeyeGroupingKey } from '@/constants/localStorageItems'
import { TableSettings, TableSettingsData } from '@/types/common/localStorageData'

import { safeJsonParse } from './functions'

export const setUserSettingsToLocalStorage = (localStorageName: string, accountId: string) => {
  const usersSettings = localStorage.getItem(localStorageName)

  if (!usersSettings) {
    const newUser = { [accountId]: { tables: [] } }

    localStorage.setItem(localStorageName, JSON.stringify(newUser))
  }

  if (usersSettings && !JSON.parse(usersSettings)[accountId]) {
    const newUsers = { ...JSON.parse(usersSettings), [accountId]: { tables: [] } }

    localStorage.setItem(localStorageName, JSON.stringify(newUsers))
  }
}

export const setTableSettings = (
  localStorageName: string,
  userId: string,
  tableSettings: TableSettings,
) => {
  const usersSettings = localStorage.getItem(localStorageName)

  if (usersSettings) {
    const parseUsers = JSON.parse(usersSettings)
    const data = {
      ...parseUsers,
      [userId]: {
        tables: [
          ...parseUsers[userId].tables.filter(
            (table: any) => Object.keys(table)[0] !== Object.keys(tableSettings)[0],
          ),
          tableSettings,
        ],
      },
    }
    localStorage.setItem(localStorageName, JSON.stringify(data))
  }
}

export const getTableSettings = (
  localStorageName: string,
  userId: string,
  tableId: string,
): TableSettingsData | null => {
  const usersSettings = localStorage.getItem(localStorageName)

  if (usersSettings) {
    const parseUsers = JSON.parse(usersSettings)

    const tableSettings = parseUsers[userId].tables.find(
      (table: any) => Object.keys(table)[0] === tableId,
    )
    if (tableSettings) {
      return Object.values(tableSettings)[0] as TableSettingsData
    }
  }
  return null
}

export const setLocalStorageItem = (localStorageKey: string, data: any): void => {
  localStorage.setItem(localStorageKey, JSON.stringify(data))
}

export const getLocalStorageItem = (localStorageName: string): any | null => {
  const reportsSettings = localStorage.getItem(localStorageName)
  return reportsSettings ? JSON.parse(reportsSettings) : null
}

export const setUserSettings = (
  localStorageKey: string,
  userId: string | number,
  data: Record<string, any>,
): void => {
  const usersSettingsJSON = localStorage.getItem(localStorageKey)
  let newSettings = {}

  if (usersSettingsJSON) {
    const usersSettings = JSON.parse(usersSettingsJSON)

    newSettings = {
      ...usersSettings,
      [userId]: {
        ...usersSettings[userId],
        ...data,
      },
    }
  } else {
    newSettings = {
      [userId]: { ...data },
    }
  }

  localStorage.setItem(localStorageKey, JSON.stringify(newSettings))
}

export const getUserSettings = (
  localStorageKey: string,
  userId: string | number,
  targetValue?: string,
): any => {
  const userSettingsJSON = localStorage.getItem(localStorageKey)

  if (userSettingsJSON) {
    const userSettings = JSON.parse(userSettingsJSON)[userId]

    if (userSettings) {
      return targetValue ? userSettings[targetValue] : userSettings
    }
  }

  return null
}

export const getAtmeyeSavedGrouping = (windowType: string) => {
  const groupingKey = generateAtmeyeGroupingKey(windowType)

  return safeJsonParse(getLocalStorageItem(groupingKey), {
    onError: () => {
      localStorage.removeItem(groupingKey)
    },
  })
}
