import { requestService } from '@/utils/services/request'

import { FilterField } from '../commonTypes'
import { DeviceSettings, Point, TeltonikaDeviceInfo } from './types'

export class TeltonikaApi {
  public static async getTeltonikaDeviceInfo(deviceId: number): Promise<TeltonikaDeviceInfo> {
    try {
      return await requestService.get(`/atmeye/api/v1/teltonika-device/${deviceId}/info`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getTeltonikaDeviceRoute(payload: {
    deviceId: number
    deviceDateTimeFilter: FilterField
  }): Promise<{ points: Point[] }> {
    try {
      return await requestService.post(`/atmeye/api/v1/teltonika-device/route`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getTeltonikaSettings(deviceId: number): Promise<DeviceSettings> {
    try {
      return await requestService.get(
        `/atmeye/api/v1/teltonika-device/settings?deviceId=${deviceId}`,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setTeltonikaSettings({
    deviceId,
    payload,
  }: {
    deviceId: number
    payload: DeviceSettings
  }): Promise<void> {
    try {
      return await requestService.put(
        `/atmeye/api/v1/teltonika-device/settings?deviceId=${deviceId}`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }
}
