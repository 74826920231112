import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles(({ mixins, spacing, palette }) =>
  createStyles({
    button: {
      flex: 1,
      width: spacing(17),
      height: spacing(4),
    },

    deleteButton: {
      color: palette.error.main,
      width: spacing(17),

      fontWeight: 'bold',
      backgroundColor: palette.error.light,
      border: 'none',
      marginRight: spacing(0),
      '&:hover': {
        backgroundColor: palette.error.light,
        border: 'none',
      },
    },

    closeButton: {
      top: spacing(2),
      right: spacing(2),
      '& [class*="makeStyles-icon"]': {
        color: '#041517',
      },
    },

    input: {
      width: '100%',
    },

    subtitle: {
      fontSize: '22px',
      fontWeight: 'bold',
      paddingRight: '30px',
    },
  }),
)
