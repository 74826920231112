import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getState = (state: AppState): fromReducer.State => {
  return state.cmAdmin?.appConfig.cassetteTypes
}

export const getCassetteTypes = createSelector(getState, state => state)
export const getCassetteTypesData = createSelector(getState, state => state.data)

export const getCassetteTypesSelected = createSelector(getState, state => state.selected)

export const getCassetteTypesSort = createSelector(getState, state => ({
  sortColumn: state.sortColumn,
  sortOrder: state.sortOrder,
}))

export const getCassetteTypesIsDeleting = createSelector(getState, state => state.isDeleting)
