import { Divider, Grid, Radio, Typography } from '@material-ui/core'
import { Field, Form, Formik, FormikValues } from 'formik'
import { get, isEmpty, keys } from 'lodash-es'
import moment from 'moment'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isIE } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import ExcelImage from '@/assets/excel.png'
import PdfImage from '@/assets/pdf1.png'
import WordImage from '@/assets/word1.png'
import Loader from '@/components/blocks/Loader/component'
import Button from '@/components/controls/Button'
import { createCheckboxField } from '@/components/controls/Checkbox/component'
import { createDatePickerRangeField } from '@/components/controls/DateInput/component'
import { createRadioGroupFormikField } from '@/components/controls/RadioGroup/component'
import { createSelectField } from '@/components/controls/Select/component'
import { MultipleSelect } from '@/components/pages/sd/reports/components/ReportsModal/components/ReportModalForm/components/MultipleSelect'
import {
  Select,
  SelectMonth,
} from '@/components/pages/sd/reports/components/ReportsModal/components/ReportModalForm/components/Select'
import DynamicDateFields from '@/components/pages/sd/reports/helpers/dynamicDateField'
import DynamicFields from '@/components/pages/sd/reports/helpers/dynamicField/component'
import { getInitialFormValuesForReportForm } from '@/components/pages/sd/reports/helpers/dynamicField/initialValues'
import { validateReportForm } from '@/components/pages/sd/reports/helpers/validate/validate'
import SubTitle from '@/components/wrappers/AppNavigation/components/DrawerActions/components/SubTitle/index'
import {
  DateRangeName,
  RadioPeriod,
  RadioValue,
  ReportFormField,
  ReportInstallationValues,
} from '@/constants/forms/ReportForm'
import { DATE_FORMAT } from '@/constants/time'
import { getIsFetchingReportsConfig } from '@/store/sd/reports'
import { IntervalsItems, ParametersItems } from '@/types/sd/reports/reports'
import { useTranslate } from '@/utils/internalization'

import { GenericInput } from './components/GenericInput'
import { useClasses } from './styles'
import { getPeriodMonthDate, getPeriodWeeksData } from './testData'
import { Props } from './types'

const ReportModalForm: React.FC<Props> = ({
  handleSubmit,
  reportName,
  config,
  hideClearButton,
}): React.ReactElement => {
  const initialValuesDateAndIntervals = config?.parameters.reduce(
    (acc: any, item) => {
      if (item.startOfRangeParameter) {
        acc.start = item.name
        acc.interval = item.interval
      }
      if (item.endOfRangeParameter) {
        acc.end = item.name
      }
      return acc
    },
    { start: '', end: '', interval: '' },
  )
  const props = { display: isIE ? 'block' : 'flex' }
  const classes = useClasses(props)
  const translate = useTranslate()
  const [saveReport, setSaveReport] = useState<boolean | undefined>(
    !!config?.subscriptionName || false,
  )
  const [disableDateInterval, setDisableDateInterval] = useState<boolean | undefined>(
    !!config?.subscribe || false,
  )

  const [showDataReport, setShowDataReport] = useState<boolean>(false)
  const [showLazyReport, setShowLazyReport] = useState<boolean>(false)
  const [isStartAndEndDate, setIsStartAndEndDate] = useState<boolean | undefined>(false)
  const [checkedSubscribe, setCheckedSubscribe] = useState<boolean | undefined>(false)
  const [intervalOrDate, setIntervalOrDate] = useState<string>('interval')
  const [param, setParam] = useState<Array<ParametersItems>>([])
  const [intervalReport, setIntervalReport] = useState<Array<IntervalsItems>>([])
  const [initialFormValues, setInitialFormValues] = useState({})
  const [dynamicDate, setDynamicDate] = useState({
    start: '',
    end: '',
  })

  const formInitialValues: ReportInstallationValues = {
    [ReportFormField.SaveReport]: config?.saveReport || false,
    [ReportFormField.ReportName]: config?.subscriptionName || '',
    [ReportFormField.SubScribe]: config?.subscribe || false,
    [ReportFormField.Interval]: initialValuesDateAndIntervals?.interval || '',
    [DateRangeName.Range]: [
      config?.validFrom ? moment.utc(config?.validFrom).format(DATE_FORMAT) : null,
      config?.validTo ? moment.utc(config?.validTo).format(DATE_FORMAT) : null,
    ],
    [RadioPeriod.Period]: get(keys(config?.period), 0) || '',
    [ReportFormField.PeriodDays]: config?.period?.period || '',
    [ReportFormField.PeriodWeek]:
      (config?.period?.week && config.period.week.map((el: number) => String(el))) || [],
    [ReportFormField.PeriodMonth]: config?.period?.month || '',
    [ReportFormField.TargetFormat]: config?.fileFormat || 'XLS',
  }

  const formRef = useRef(null) as any

  const isFetchingConfig = useSelector(getIsFetchingReportsConfig)

  useEffect(() => {
    if (config) {
      const { parameters, intervals } = config

      setIsStartAndEndDate(
        parameters.some(el => el.endOfRangeParameter || el.startOfRangeParameter),
      )
      setParam(parameters.filter(el => !el.__hidden))
      setIntervalReport(intervals)
      setSaveReport(!!config.subscriptionName)
      setCheckedSubscribe(config.subscribe)
    }
  }, [config, setIsStartAndEndDate])

  useEffect(() => {
    if (param.length) {
      const dynamicInitialValue = getInitialFormValuesForReportForm(param)

      setInitialFormValues({ ...formInitialValues, ...dynamicInitialValue })
    }
  }, [param, intervalOrDate])

  useEffect(() => {
    const { current } = formRef

    if (current) {
      current.setTouched({
        ...current.touched,
        [initialValuesDateAndIntervals.start]: false,
        [initialValuesDateAndIntervals.end]: false,
      })
      current.setFieldValue(ReportFormField.Interval, '')
    }
  }, [intervalOrDate])

  const toggleIntervalOrDate = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target
    setIntervalOrDate(value)
  }, [])

  const period = useCallback(
    (disabled: boolean) => {
      return createRadioGroupFormikField({
        disabled,
        options: [{ value: 'period' }, { value: 'week' }, { value: 'month' }],
        classPadding: classes.radioGroupPadding,
        classGroup: classes.radioGroup,
      })
    },
    [classes.radioGroup],
  )

  const SubscriptionDate = useMemo(
    () =>
      createDatePickerRangeField({
        labelDate: {
          begin: translate('translate#title.date.begin'),
          end: translate('translate#title.date.end'),
        },
        wrapperDateClass: classes.wrapperDateBlock,
        widthClass: classes.subscriptionDate,
        required: true,
        format: 'LL',
        disabled: !checkedSubscribe,
        dateLimitForReports: true,
        disableFutureDates: false,
      }),
    [translate, checkedSubscribe],
  )

  const handleResetForm = useCallback(
    (reset: () => void): (() => void) => (): void => {
      reset()

      const { current } = formRef

      current.setValues({
        ...initialFormValues,
        [initialValuesDateAndIntervals.start]: '',
        [initialValuesDateAndIntervals.end]: '',
      })
      setSaveReport(false)
      setCheckedSubscribe(false)
    },
    [initialFormValues, initialValuesDateAndIntervals],
  )

  const handleSaveReportChange = useCallback<
    (
      values: ReportInstallationValues | {},
      setValues: (values: any) => void,
    ) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  >(
    (values, setValues) => (e, checked): void => {
      if (!checked) {
        setValues({
          ...values,
          [ReportFormField.ReportName]: '',
          [ReportFormField.SaveReport]: false,
          [ReportFormField.SubScribe]: false,
          [RadioPeriod.Period]: formInitialValues[RadioPeriod.Period],
          [DateRangeName.Range]: [],

          [ReportFormField.PeriodDays]: '',
          [ReportFormField.PeriodWeek]: [],
          [ReportFormField.PeriodMonth]: '',
        })
        setCheckedSubscribe(false)
      } else {
        setValues({
          ...values,
          [ReportFormField.SaveReport]: true,
        })
        setIntervalOrDate('interval')
      }
      setSaveReport(checked)
    },
    [],
  )

  const changeInterval = (value: unknown) => {
    const { current } = formRef

    if (config) {
      const interval = intervalReport.find(interval => interval.id === value)

      if (interval) {
        current.setValues({
          ...current.values,
          [ReportFormField.Interval]: value,
          [initialValuesDateAndIntervals.start]: interval.from,
          [initialValuesDateAndIntervals.end]: interval.to,
        })
        setDynamicDate({
          start: interval.from,
          end: interval.to,
        })
      } else {
        current.setValues({
          ...current.values,
          [ReportFormField.Interval]: value,
          [initialValuesDateAndIntervals.start]: '',
          [initialValuesDateAndIntervals.end]: '',
        })
        setDynamicDate({
          start: '',
          end: '',
        })
      }
    }
  }

  const handleSubscribeChange = (values: any, setValues: any) => (e: any, checked: any): void => {
    if (!checked) {
      setValues({
        ...values,
        [RadioPeriod.Period]: formInitialValues[RadioPeriod.Period],
        [DateRangeName.Range]: [],
        [ReportFormField.SubScribe]: false,
        [ReportFormField.PeriodDays]: '',
        [ReportFormField.PeriodWeek]: [],
        [ReportFormField.PeriodMonth]: '',
      })
      setCheckedSubscribe(false)
    } else {
      setValues({
        ...values,
        [ReportFormField.SubScribe]: true,
        [DateRangeName.Range]: [
          moment().format(DATE_FORMAT),
          moment()
            .add(1, 'years')
            .format(DATE_FORMAT),
        ],
        [RadioPeriod.Period]: RadioValue.period,
      })
    }

    setCheckedSubscribe(checked)
    setDisableDateInterval(checked)
    setIntervalOrDate('interval')
  }

  const handleShowDataReportChange = useCallback<
    (
      values: ReportInstallationValues | {},
      setValues: (values: any) => void,
    ) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  >(
    (values, setValues) => (e, checked): void => {
      if (!checked) {
        setValues({
          ...values,
          [ReportFormField.ShowDataReport]: false,
        })
      } else {
        setValues({
          ...values,
          [ReportFormField.ShowDataReport]: true,
        })
      }
      setShowDataReport(checked)
    },
    [],
  )

  const handleShowLazyReportChange = useCallback<
    (
      values: ReportInstallationValues | {},
      setValues: (values: any) => void,
    ) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  >(
    (values, setValues) => (e, checked): void => {
      if (!checked) {
        setValues({
          ...values,
          [ReportFormField.ShowLazyReport]: false,
        })
      } else {
        setValues({
          ...values,
          [ReportFormField.ShowLazyReport]: true,
        })
      }
      setShowLazyReport(checked)
    },
    [],
  )

  const handleIntervalChange = useCallback(
    (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      changeInterval(event.target.value)
    },
    [config, formRef, intervalReport],
  )

  const validate = (
    values: ReportInstallationValues & FormikValues,
  ): Partial<ReportInstallationValues> & FormikValues => {
    const errors = validateReportForm(param, values, initialValuesDateAndIntervals)
    return errors
  }

  const TargetFormat = (
    label = 'Target format',
    disabled = false,
    options = [
      {
        name: 'PDF',
        value: 'PDF',
        icon: <img className={classes.optionIcon} src={PdfImage} alt="pdfImage" />,
      },
      {
        name: 'XLS',
        value: 'XLS',
        icon: <img className={classes.optionIcon} src={ExcelImage} alt="excelImage" />,
      },
      {
        name: 'DOC',
        value: 'DOC',
        icon: <img className={classes.optionIcon} src={WordImage} alt="excelImage" />,
      },
    ],
  ): any =>
    createSelectField({
      disabled,
      label,
      fullWidth: true,
      shrink: true,
      options,
    })

  return (
    <>
      {isFetchingConfig || isEmpty(initialFormValues) ? (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      ) : (
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialFormValues as any}
          validateOnMount
          enableReinitialize
          innerRef={formRef}
          validate={validate}
        >
          {({
            isValid,
            dirty,
            resetForm,
            values,
            setValues,
            setFieldValue,
          }): React.ReactElement => (
            <div className={classes.container}>
              <div className={classes.wrapperTitle}>
                <Typography variant="h5"> {reportName} </Typography>
              </div>
              <Form className={classes.form} noValidate>
                <Grid container>
                  <Grid item md={12}>
                    <Grid item md={12} className={classes.subTitle}>
                      <SubTitle text={translate('translate#title.parameters.of.report')} />
                    </Grid>
                  </Grid>
                  {isStartAndEndDate && (
                    <>
                      <Grid item md={12} className={classes.chooseIntervalOrDate}>
                        <Radio
                          color="primary"
                          value="interval"
                          name="dateOrInterval"
                          classes={{ root: classes.root }}
                          className={classes.radio}
                          checked={intervalOrDate === 'interval'}
                          onChange={toggleIntervalOrDate}
                        />
                        <Grid className={classes.chooseIntervalOrDateField}>
                          <Field name={ReportFormField.Interval}>
                            {Select({
                              label: translate('translate#title.interval'),
                              placeholder: translate('translate#form.placeholder.interval'),
                              disabled: intervalOrDate !== 'interval',
                              options: intervalReport.map(({ id, name }) => ({
                                value: id,
                                name: translate(`translate#report.intervals.title.${name}`),
                              })),
                              required: checkedSubscribe,
                              parameterName: ReportFormField.Interval,
                              className: classes.select,
                              onChange: handleIntervalChange,
                            })}
                          </Field>
                        </Grid>
                      </Grid>
                      {!checkedSubscribe && (
                        <Grid item md={12} className={classes.chooseIntervalOrDate}>
                          <Radio
                            color="primary"
                            value="date"
                            name="dateOrInterval"
                            classes={{ root: classes.root }}
                            className={classes.radio}
                            checked={intervalOrDate === 'date'}
                            disabled={disableDateInterval}
                            onChange={toggleIntervalOrDate}
                          />
                          <Grid className={classes.chooseIntervalOrDateField}>
                            {param
                              .filter(
                                filed => filed.endOfRangeParameter || filed.startOfRangeParameter,
                              )
                              .sort(
                                (a, b) =>
                                  Number(a.endOfRangeParameter) - Number(b.endOfRangeParameter),
                              )
                              .map(filed => {
                                if (filed.endOfRangeParameter || filed.startOfRangeParameter) {
                                  return (
                                    <DynamicDateFields
                                      key={filed.attributeId}
                                      params={filed}
                                      formikValues={values}
                                      setFieldValue={setFieldValue}
                                      dynamicDateValue={dynamicDate}
                                      setDynamicDate={setDynamicDate}
                                      disabled={intervalOrDate !== 'date'}
                                    />
                                  )
                                }
                              })}
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                  {param.map(filed => {
                    if (!filed.endOfRangeParameter && !filed.startOfRangeParameter) {
                      return (
                        <Grid item md={12} key={filed.attributeId}>
                          <DynamicFields
                            params={filed}
                            formikValues={values}
                            setFieldValue={setFieldValue}
                          />
                        </Grid>
                      )
                    }
                  })}
                  <Grid item md={12} className={classes.fields}>
                    <Field name={ReportFormField.SaveReport}>
                      {createCheckboxField({
                        label: translate('translate#action.saveReportToMyReports'),
                        value: saveReport || false,
                        onChange: handleSaveReportChange(values, setValues),
                        checked: saveReport || false,
                        className: classes.checkbox,
                        styles: {
                          label: classes.checkboxLabel,
                        },
                        width: 'fit-content',
                        disabled: !!config?.subscriptionName || showDataReport,
                      })}
                    </Field>
                  </Grid>
                  <Grid item md={12} className={classes.fields}>
                    <Field name={ReportFormField.ShowDataReport}>
                      {createCheckboxField({
                        label: translate('translate#action.showDataReport'),
                        value: showDataReport || false,
                        onChange: handleShowDataReportChange(values, setValues),
                        checked: showDataReport || false,
                        className: classes.checkbox,
                        styles: {
                          label: classes.checkboxLabel,
                        },
                        width: 'fit-content',
                        disabled: !config?.dataReportExist || saveReport,
                      })}
                    </Field>
                  </Grid>
                  <Grid item md={12} className={classes.fields}>
                    <Field name={ReportFormField.ShowLazyReport}>
                      {createCheckboxField({
                        label: translate('translate#action.ShowLazyReport'),
                        value: showLazyReport || false,
                        onChange: handleShowLazyReportChange(values, setValues),
                        checked: showLazyReport || false,
                        className: classes.checkbox,
                        styles: {
                          label: classes.checkboxLabel,
                        },
                        width: 'fit-content',
                        // disabled: !!config?.subscriptionName,
                      })}
                    </Field>
                  </Grid>
                  <Grid item md={12} />
                  <Grid item md={12}>
                    <Field name={ReportFormField.ReportName}>
                      {GenericInput({
                        label: translate('translate#form.label.report.name'),
                        placeholder: translate('translate#form.placeholder.report.name'),
                        required: true,
                        disabled: !!config?.subscriptionName || !saveReport,
                        className: classes.textField,
                      })}
                    </Field>
                  </Grid>
                  <Grid item md={12}>
                    <Field name={ReportFormField.SubScribe}>
                      {createCheckboxField({
                        label: translate('translate#action.subscribe'),
                        disabled: !saveReport,
                        value: checkedSubscribe || false,
                        onChange: handleSubscribeChange(values, setValues),
                        checked: checkedSubscribe || false,
                        className: classes.checkbox,
                        styles: {
                          label: classes.checkboxLabel,
                        },
                        width: '23%',
                      })}
                    </Field>
                  </Grid>
                  <Grid item md={12} />
                  <Grid item md={12}>
                    <Field name={DateRangeName.Range}>{SubscriptionDate}</Field>
                  </Grid>
                  <Grid item md={12} className={classes.radioButtonPeriod}>
                    <Field name={RadioPeriod.Period}>{period(!checkedSubscribe)}</Field>
                  </Grid>
                  <Grid item md={12}>
                    {values[RadioPeriod.Period] === RadioValue.period && (
                      <Field name={ReportFormField.PeriodDays}>
                        {GenericInput({
                          label: translate('translate#form.period.day'),
                          placeholder: translate('translate#form.placeholder.period.day'),
                          disabled: !checkedSubscribe,
                          required: true,
                          className: classes.textField,
                        })}
                      </Field>
                    )}
                    {values[RadioPeriod.Period] === RadioValue.week && (
                      <Field name={ReportFormField.PeriodWeek}>
                        {MultipleSelect({
                          label: translate('translate#form.period.week'),
                          placeholder: translate('translate#form.placeholder.period.week'),
                          disabled: !checkedSubscribe,
                          parameterName: ReportFormField.PeriodWeek,
                          options: getPeriodWeeksData(translate),
                          className: classes.select,
                          required: true,
                        })}
                      </Field>
                    )}
                    {values[RadioPeriod.Period] === RadioValue.month && (
                      <Field name={ReportFormField.PeriodMonth}>
                        {SelectMonth({
                          label: translate('translate#form.period.month'),
                          placeholder: translate('translate#form.placeholder.period.month'),
                          disabled: !checkedSubscribe,
                          options: getPeriodMonthDate(translate),
                          className: classes.select,
                          required: true,
                        })}
                      </Field>
                    )}
                  </Grid>
                  <Divider className={classes.divider} />
                  <Grid item md={12}>
                    <Field name={ReportFormField.TargetFormat}>
                      {TargetFormat(translate('translate#form.label.target.format'))}
                    </Field>
                  </Grid>
                </Grid>
                <Grid justifyContent="flex-end" container className={classes.buttons}>
                  {hideClearButton ||
                    (dirty && (
                      <Button
                        width="md"
                        variant="outlined"
                        onClick={handleResetForm(resetForm)}
                        className={classes.clearButton}
                      >
                        <FormattedMessage id="action.clear" defaultMessage="Clear" />
                      </Button>
                    ))}

                  <Button disabled={!isValid} variant="contained" type="submit" width="md">
                    <FormattedMessage id="action.ok" />
                  </Button>
                </Grid>
              </Form>
            </div>
          )}
        </Formik>
      )}
    </>
  )
}

export default ReportModalForm
