import makeStyles from '@material-ui/core/styles/makeStyles'

import { styledComponentTheme } from '@/theme'

export const useClasses = makeStyles(({ spacing, palette, shape: { borderRadius } }) => ({
  root: {
    marginLeft: '24px',
  },
  bookmarkIcon: {
    width: '12px',
    height: '12px',
    padding: '6px',
    marginLeft: '12px',
    fontSize: '14px',
  },
  bookmarkDate: {
    '&:after': {
      content: '" - "',
    },
    '&:last-child:after': {
      content: '""',
    },
  },
  bookmarkMultiple: {
    display: 'inline-block',
    verticalAlign: 'middle',
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  bookmark: {
    padding: spacing(0.5, 1.5),
    margin: '6px',
    display: 'inline-block',
    backgroundColor: '#ECF2F2',
    borderRadius,
  },
  bookmarkClear: {
    backgroundColor: '#FDEFED',
    color: '#E45B4A',
    '& $bookmarkIcon path': {
      fill: '#E45B4A',
    },
  },
  withTooltip: {
    overflow: 'scroll',
    fontSize: styledComponentTheme.fontSizes.small,
    maxHeight: '600px',
  },
}))
