import queryString from 'query-string'

import { AdmValuable, AdmValuableBase, ValuableLanguage } from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { Sort } from '@/types/cm'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

export class ValuablesAdminApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getAll(sort: Sort): Promise<AdmValuable[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/valuables',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )
      return await ValuablesAdminApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async get(id: string): Promise<AdmValuable> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/valuable',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await ValuablesAdminApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(currency: AdmValuableBase): Promise<void> {
    try {
      return await ValuablesAdminApi.requestService.post('/admin/valuables/add', currency)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(currency: AdmValuable): Promise<void> {
    try {
      return await ValuablesAdminApi.requestService.put('/admin/valuables/update', currency)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(id: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/valuables/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await ValuablesAdminApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getLanguages(): Promise<ValuableLanguage> {
    try {
      return await ValuablesAdminApi.requestService.get('/admin/valuables/languages')
    } catch (error) {
      throw Error(error)
    }
  }
}
