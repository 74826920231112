import { TextField } from '@material-ui/core'
import clsx from 'clsx'
import React, { ChangeEvent, ReactElement, useCallback, useEffect } from 'react'
import TimeField from 'react-simple-timefield'

import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import { Props } from './types'

const TimeInput = ({ time, changeTime, isValid }: Props): ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  // useEffect(() => {
  //   const formatedTime = time.split(':').map(item => item.padStart(2, '0')).join(':')
  // }, [])

  const handleChange = useCallback(
    (event: ChangeEvent<{ value: string }>) => changeTime(event.target.value),
    [],
  )

  return (
    <TimeField
      value={time
        .split(':')
        .map(item => item.padStart(2, '0'))
        .join(':')}
      // value="00:00"
      onChange={handleChange}
      input={
        <TextField
          autoComplete="off"
          variant="standard"
          placeholder={translate('translate#title.typeHere')}
          className={clsx(classes.timeInput, !isValid && classes.errorTimeInput)}
          // className={classes.timeInput}
        />
      }
    />
  )
}

export default TimeInput
