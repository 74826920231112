import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import clsx from 'clsx'
import React, { ReactElement, useState } from 'react'

import PhotoCameraConfirmed from '@/assets/atmeye/icons/photo_camera_confirmed.svg'
import { ReactComponent as PhotoCamera } from '@/assets/atmeye/icons/photo_camera_no_fill.svg'
import { ReactComponent as VideoCameraConfirmed } from '@/assets/atmeye/icons/video_camera_confirmed.svg'
import { ReactComponent as VideoCamera } from '@/assets/atmeye/icons/video_camera_no_fill.svg'
import CameraModal from '@/components/NPMPakage/components/modals/CameraModal'
import { EventMediaStoreType } from '@/components/NPMPakage/components/tableComponents/rows/TableCell/MediaCell/types'
import { usePermission } from '@/components/NPMPakage/components/wrappers/PermissionProvider/usePermission'

import { MediaCellTypes } from '../types/types'
import { useClasses } from './styles'

const MediaCell = ({
  text,
  row,
  permission,
  getUpdateRequest,
  requestFileType,
}: MediaCellTypes): ReactElement => {
  const classes = useClasses()

  const [showCameraModal, setShowCameraModal] = useState(false)
  const { allowed } = usePermission(permission)

  return (
    <TableCell
      onClick={() => {
        allowed &&
          row.mediaStoreType !== EventMediaStoreType.FILE_NOT_FOUND &&
          row.eventMediaTypeName !== 'None' &&
          (row.contentType === 'JPG' || row.contentType === 'AVI' || row.contentType === 'MKV') &&
          setShowCameraModal(true)
      }}
      key={row.eventId}
      className={clsx(classes.cell, classes.cameraCell, {
        [classes.clickableCell]: row.mediaStoreType !== EventMediaStoreType.FILE_NOT_FOUND,
      })}
    >
      {(row.contentType === 'JPG' || row.contentType === 'AVI' || row.contentType === 'MKV') &&
        row.eventMediaTypeName !== 'None' && (
          <div className={classes.cameraCell}>
            {row.mediaStoreType === EventMediaStoreType.FILE_NOT_FOUND ? (
              <NotInterestedIcon stroke="#e9b1aa" />
            ) : (
              <>
                {row.contentType === 'JPG' ? (
                  row.mediaStoreType === EventMediaStoreType.ATM_FILE ? (
                    <PhotoCamera fill="#929A9B" className={classes.cameraIcon} />
                  ) : (
                    <img src={PhotoCameraConfirmed} className={classes.cameraIcon} />
                  )
                ) : row.mediaStoreType === EventMediaStoreType.ATM_FILE ? (
                  <VideoCamera fill="#929A9B" className={classes.cameraIcon} />
                ) : (
                  <VideoCameraConfirmed className={classes.cameraIcon} />
                )}

                <Typography
                  variant="body2"
                  className={clsx(
                    row.mediaStoreType === EventMediaStoreType.ATM_FILE &&
                      classes.mediaStoreTypeColor,
                  )}
                >
                  {text}
                </Typography>
              </>
            )}
          </div>
        )}

      {showCameraModal && (
        <CameraModal
          open={showCameraModal}
          handleClose={e => {
            e.stopPropagation()
            setShowCameraModal(false)
          }}
          row={row}
          getUpdateRequest={getUpdateRequest}
          eventType={requestFileType}
        />
      )}
    </TableCell>
  )
}

export { MediaCell }
