import queryString from 'query-string'

import { AdmFuelType, AdmFuelTypeBase } from '@/api/cm/swaggerGeneratedApi'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class FuelTypesApi {
  private static requestService = getRequestServiceCM()

  public static async get(): Promise<AdmFuelType[]> {
    try {
      return await FuelTypesApi.requestService.get('/admin/fuelTypes')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(payload: AdmFuelTypeBase): Promise<void> {
    try {
      return await FuelTypesApi.requestService.post('/admin/fuelTypes/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(payload: AdmFuelType): Promise<void> {
    try {
      return await FuelTypesApi.requestService.put('/admin/fuelTypes/update', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(id: number): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/fuelTypes/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await FuelTypesApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
