import queryString from 'query-string'

import { GetAccountsResponse, SetAccountsRequest } from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

export class AccountsAdminApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getAll(id: string): Promise<GetAccountsResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/getAccounts',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await AccountsAdminApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(payload: SetAccountsRequest): Promise<void> {
    try {
      return await AccountsAdminApi.requestService.post('admin/setAccounts', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(payload: SetAccountsRequest): Promise<void> {
    try {
      return await AccountsAdminApi.requestService.post('admin/setAccounts', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(payload: SetAccountsRequest): Promise<void> {
    try {
      return await AccountsAdminApi.requestService.post('admin/setAccounts', payload)
    } catch (error) {
      throw Error(error)
    }
  }
}
