import { IconButton } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import moment from 'moment'
import React, { FC, SyntheticEvent, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { TransactionsApi } from '@/api/atmeye/translactionsApi'
import { TransactionEvent } from '@/api/atmeye/translactionsApi/types'
import Button from '@/components/controls/Button'
import ContextMenu from '@/components/controls/ContextMenu'
import { TransactionLogModal } from '@/components/NPMPakage/components/tableComponents/rows/TransactionsTableCell/TransactionLogModal/transactionLogModal'
import { CellJSXPropsComponent } from '@/components/NPMPakage/components/tableComponents/UITable/types'
import { Permission } from '@/components/NPMPakage/components/wrappers/types'
import { filterConfig } from '@/components/pages/atmeye/Transactions/hooks/useTransactions'
import { DATE_FORMAT } from '@/constants/time'
import { transformFilters } from '@/helpers/atmeye/filtersConfig'
import { getInProgressReports, setInProgressReports } from '@/store/atmeye/reports'
import { PopUpService } from '@/utils/services/popUpService'

import { CollapseCell } from '../TableCell/CollapseCell/CollapseCell'
import { DateCell } from '../TableCell/DateCell/DateCell'
import { InputCell } from '../TableCell/InputCell/InputCell'
import { MediaCell } from '../TableCell/MediaCell/MediaCell'
import { useClasses } from './styles'

const ButtonLog = ({ transaction, children, renderBtn }: any) => {
  const { deviceId, processNumber, localDate } = transaction
  const [isLogModalOpen, setIsLogModalOpen] = useState(false)
  const isBtn = deviceId && processNumber

  const openModal = () => {
    isBtn && setIsLogModalOpen(true)
  }

  return (
    <>
      {renderBtn ? (
        renderBtn({ openModal })
      ) : (
        <div
          style={isBtn && { textDecoration: 'underline', cursor: 'pointer' }}
          onClick={openModal}
          onCopy={e => {
            const text = window
              ?.getSelection?.()
              ?.toString()
              .replace(/[\n\r]+/g, '')

            const updatedValue = text?.match(/(^\d+)[^\d]+(\d+)$/)
            if (updatedValue) {
              e.clipboardData.setData('text/plain', `${updatedValue[1]}*${updatedValue[2]}`)
              e.preventDefault()
            }
          }}
        >
          {children}
        </div>
      )}
      {isLogModalOpen && (
        <TransactionLogModal
          isOpen={isLogModalOpen}
          deviceId={deviceId}
          processNumber={processNumber}
          localDate={localDate}
          onClose={() => {
            setIsLogModalOpen(false)
          }}
        />
      )}
    </>
  )
}

const TransactionsTableCell: FC<CellJSXPropsComponent> = ({
  fieldName,
  collapse,
  index,
  indexColumn,
  parentIndex: parentIndexProps,

  row,
  item,
  sorting,

  setCollapse,
  getUpdateRequest,
}: CellJSXPropsComponent): React.ReactElement => {
  const dispatch = useDispatch()
  const classes = useClasses()

  const reports = useSelector(getInProgressReports)

  const [isCollapseLoading, setIsCollapseLoading] = useState(false)
  const [savedEvents, setSavedEvents] = useState<Record<string, TransactionEvent[]>>({})

  const { processNumber, deviceId, transactionId, localDate } = row

  const localDateMoment = moment(localDate)

  const handleCollapse = async () => {
    if (
      savedEvents[transactionId] &&
      getUpdateRequest?.getUpdateTableRequest &&
      collapse !== item.transactionId
    ) {
      getUpdateRequest?.mutateQueryTable &&
        getUpdateRequest.mutateQueryTable((prevTable: any[]) => {
          return prevTable.map(row => {
            return {
              ...row,
              childItems: savedEvents[transactionId].map(
                ({ deviceName, clientId, ...visibleFields }) => visibleFields,
              ),
            }
          })
        })
    }

    if (
      collapse !== item.transactionId &&
      !savedEvents[transactionId] &&
      getUpdateRequest?.getUpdateTableRequest
    ) {
      setIsCollapseLoading(true)
      const res: TransactionEvent[] = await getUpdateRequest.getUpdateTableRequest({
        processNumber,
        deviceId,
        transactionId,
        filter: transformFilters(
          {
            localProcessDate: [
              localDateMoment.subtract(1, 'd').format(DATE_FORMAT),
              localDateMoment.add(2, 'd').format(DATE_FORMAT),
            ],
          },
          filterConfig,
        ),
      })

      getUpdateRequest?.mutateQueryTable &&
        getUpdateRequest.mutateQueryTable((prevTable: any[]) => {
          return prevTable.map(row => {
            return {
              ...row,
              childItems: res.map(({ deviceName, clientId, ...visibleFields }) => visibleFields),
            }
          })
        })

      setSavedEvents(saved => {
        return { ...saved, [transactionId]: res }
      })
      setIsCollapseLoading(false)
    }
    setCollapse && setCollapse(collapse === item.transactionId ? '' : item.transactionId)
  }

  // const updatedChildItems = (el: any) => (index: number, value: string, fieldName: string) => {
  //   const children = [...el.childItems]
  //   children[index][fieldName] = value

  //   return { childItems: children }
  // }

  const updateChildItem = ({ field, value }: { field: string; value: string }) => {
    getUpdateRequest?.mutateQueryTable &&
      getUpdateRequest.mutateQueryTable((prevTable: any[]) => {
        return prevTable.map((prevRow, i) => {
          if (i === parentIndexProps) {
            return {
              ...prevRow,
              childItems: prevRow.childItems.map((childRow: any) => {
                if (childRow.eventId === row.eventId) return { ...childRow, [field]: value }
                return childRow
              }),
            }
          }
          return prevRow
        })
      })
  }

  const saveCellHandler = async (
    event: any,
    fieldId: string,
    fieldValue: string,
  ): Promise<void> => {
    const { value } = event.target

    try {
      updateChildItem({ field: 'note', value })

      await TransactionsApi.updateTransactionNote({
        value,
        eventId: row.eventId,
      })
    } catch (e) {
      updateChildItem({ field: 'note', value: fieldValue })
    }
  }

  const mutateQueryTableWithScreenshot = () => {
    updateChildItem({ field: 'mediaStoreType', value: 'DATABASE_FILE' })
  }

  const [anchorEl, setAnchorEl] = useState<null | (EventTarget & HTMLButtonElement)>(null)

  const handleOpenMenu = (event: SyntheticEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = (): void => {
    setAnchorEl(null)
  }

  const options = [
    {
      content: (
        <div className={classes.buttonGroup}>
          <Button
            key="fileUnarchive"
            variant="outlined"
            color="primary"
            className={classes.dropdownButton}
            onClick={async () => {
              try {
                const res = await TransactionsApi.createTransactionReport({
                  processNumber: item.processNumber,
                  deviceId: item.deviceId,
                  transactionId: item.transactionId,
                })

                dispatch(setInProgressReports([...reports, res.id]))
                handleCloseMenu()
                PopUpService.showGlobalPopUp(`translate#atmeye.title.fastReportCreated`, 'info')
              } catch (e) {
                console.log(e)
              }
            }}
          >
            <FormattedMessage id="atmeye.title.fastReport" defaultMessage="Fast report" />
          </Button>

          <ButtonLog
            renderBtn={({ openModal }: any) => {
              return (
                <Button
                  key="logView"
                  variant="outlined"
                  color="primary"
                  className={classes.dropdownButton}
                  onClick={() => {
                    openModal()
                    handleCloseMenu()
                  }}
                >
                  <FormattedMessage id="atmeye.title.logView" defaultMessage="Log view" />
                </Button>
              )
            }}
            transaction={row}
          />
        </div>
      ),
    },
  ]

  const renderComponents = () => {
    return {
      note: (
        <InputCell
          headerName={fieldName || ''}
          index={index}
          row={item}
          onBlur={saveCellHandler}
          note={item?.note || ''}
          isParent={!row.eventId}
        />
      ),
      cameraNumber: (
        <MediaCell
          text={item.cameraNumber}
          permission={Permission.Transactions_View_Media}
          row={item}
          getUpdateRequest={{
            ...getUpdateRequest,
            mutateQueryTable: getUpdateRequest?.mutateQueryTable
              ? mutateQueryTableWithScreenshot
              : undefined,
          }}
          requestFileType="TRANSACTION"
        />
      ),
      snapshot_date: <DateCell key={fieldName || ''} date={item?.[fieldName || '']} />,

      localDate: <DateCell isUTCTime key={fieldName || ''} date={item?.[fieldName || '']} />,
      atmDate: <DateCell isUTCTime key={fieldName || ''} date={item?.[fieldName || '']} />,
      clientId: (
        <TableCell className={classes.cell}>
          <ButtonLog transaction={row}>{item?.[fieldName || '']}</ButtonLog>
        </TableCell>
      ),
      more: row?.deviceId ? (
        <TableCell className={classes.cell}>
          <IconButton size="small" onClick={handleOpenMenu}>
            <MoreVertIcon fontSize="inherit" />
          </IconButton>

          <ContextMenu
            limitMenuSize={false}
            anchorEl={anchorEl}
            handleClose={handleCloseMenu}
            options={options}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          />
        </TableCell>
      ) : null,
    }
  }

  return (
    <>
      {indexColumn === 0 && !row.eventId ? (
        <CollapseCell
          isLoading={isCollapseLoading}
          key={fieldName || ''}
          handleCollapse={handleCollapse}
          isCollapse={collapse === item.transactionId}
          nesting={0}
          withBlueColor={item[fieldName || ''].includes('PSR')}
          isHasChildren
          text={item[fieldName || '']}
        />
      ) : (
        <>
          {renderComponents()?.[fieldName as keyof typeof renderComponents] || (
            <TableCell key={fieldName || ''} className={classes.cell}>
              {item?.[fieldName || '']}
            </TableCell>
          )}
        </>
      )}
    </>
  )
}

export { TransactionsTableCell }
