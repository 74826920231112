import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { FormikProps } from 'formik'
import React, { ReactElement, useCallback, useEffect } from 'react'

import { CommonApi } from '@/api'
import { AdmApi } from '@/api/adm'
import { LookupsResponse } from '@/api/common/commonApi/types'
import Lookups from '@/components/controls/Lookups'
import { FieldTypeName } from '@/components/controls/Lookups/types'
import LookupsList from '@/components/controls/LookupsList'
import { TextInput } from '@/components/controls/TextInput'
import { hyphenNumbersAndLetters, onlyNumbersAndLetters } from '@/constants/disabledSymbols'
import { CreateDeviceFormValues } from '@/types/adm/device/local'
import { DeviceFieldName as FieldName, HardwareFieldName } from '@/types/adm/device/names'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'

const DeviceInformation = ({
  values,
  setFieldValue,
  ...props
}: FormikProps<CreateDeviceFormValues>): ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  const handleChangeSelectedObject = useCallback(e => {
    setFieldValue(FieldName.DeviceUID, e.target.value)
    setFieldValue(HardwareFieldName.SerialNumber, e.target.value)
  }, [])

  return (
    <>
      <Grid>
        <Typography variant="h6">{translate('translate#adm.deviceInformation')}</Typography>
      </Grid>
      <Grid className={classes.subtitle}>
        <Typography variant="body2">
          {translate('translate#adm.pleaseEnterDeviceInformation')}
        </Typography>
      </Grid>
      <Grid container direction="column">
        <Grid className={classes.field}>
          <TextInput
            name={FieldName.DeviceUID}
            value={values.terminalUid}
            onChange={handleChangeSelectedObject}
            label={translate('translate#adm.deviceUID')}
            required
            fullWidth
            disabledKeys={hyphenNumbersAndLetters}
            error={!!props.errors.terminalUid}
            errorText={props.errors.terminalUid}
          />
        </Grid>
        <Grid className={classes.field}>
          <LookupsList
            isDisabled={false}
            defaultValue={{
              value: values.deviceTypeId,
              name: values.deviceTypeName,
            }}
            setFormikFieldValue={setFieldValue}
            field={{
              options: {
                label: translate('translate#adm.deviceType'),
                placeholder: translate('translate#adm.deviceType'),
                required: true,
                isNumber: false,
              },
            }}
            fieldName={FieldName.DeviceTypeId}
            fieldTitle={FieldName.DeviceTypeName}
            isDependsOn
            onCall={async (
              value: string,
            ): Promise<{ item: unknown; value: string; name: string }[]> => {
              const response = await CommonApi.getAdmDeviceType({
                module: 'ADM',
                fragment: value,
                pageNo: 0,
                pageSize: 100,
              })
              return response.values
                .map(item => ({
                  item: item,
                  value: String(item.id),
                  name: item.name,
                }))
                .filter(r => r.item.isDevice)
            }}
            onSelect={(item: {
              value: string
              name: string
              item: { isDevice: boolean; isHardware: boolean }
            }): void => {
              setFieldValue(FieldName.DeviceTypeId, item.value)
              setFieldValue(FieldName.DeviceTypeName, item.name)
              if (item.item.isHardware) {
                setFieldValue(HardwareFieldName.HardwareTypeId, item.value)
                setFieldValue(HardwareFieldName.HardwareTypeName, item.name)
              }
            }}
          />
        </Grid>
        <Grid className={classes.field}>
          <Lookups
            isDisabled={false}
            defaultValue={{
              value: values.deviceStateId,
              name: values.deviceStateName,
            }}
            setFormikFieldValue={setFieldValue}
            field={{
              type: FieldTypeName.List,
              options: {
                label: translate('translate#adm.deviceState'),
                placeholder: translate('translate#adm.deviceState'),
                required: true,
                isNumber: false,
              },
            }}
            fieldName={FieldName.DeviceStateId}
            fieldTitle={FieldName.DeviceStateName}
            isDependsOn
            onCall={(value: string): Promise<LookupsResponse> => {
              return CommonApi.getLookupsValues({
                lookupKey: 'ADM_DEVICE_STATE',
                module: 'ADM',
                fragment: value,
                pageNo: 0,
                pageSize: 100,
              })
            }}
          />
        </Grid>
        <Grid className={classes.field}>
          <TextInput
            label={translate('translate#adm.deviceName')}
            name={FieldName.DeviceName}
            value={values.deviceName}
            onChange={props.handleChange}
            fullWidth
            disabledKeys={onlyNumbersAndLetters}
            error={!!props.errors.deviceName}
            errorText={props.errors.deviceName}
          />
        </Grid>
        <Grid className={classes.field}>
          <TextInput
            label={translate('translate#adm.description')}
            name={FieldName.DeviceDescription}
            value={values.deviceDescription}
            onChange={props.handleChange}
            fullWidth
            multiline
            maxSymbols={300}
            rows={5}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default DeviceInformation
