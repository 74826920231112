import React, { useRef } from 'react'
import { Grid } from '@material-ui/core'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@/components/controls/Button'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { useClasses } from './styles'
import clsx from 'clsx'
import { Props } from './types'
import Test from 'react-test-attributes'
import { getTestId } from '@/utils'
import Tooltip from '@/components/blocks/Tooltip/component'
import { useTranslate } from '@/utils/internalization'

const CounterBlock = ({
  testIdPrefix,
  group,
  disabled,
  index,
  value,
  setFieldValue,
  name,
  valid,
  setValid,
  setGlobalError,
}: Props): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const spanRef = useRef() as any
  const idPrefix = `${testIdPrefix}-sort-block`
  const testId = getTestId(idPrefix)

  const increment = (): (() => void) => (): void => {
    const newValue = [...value]
    if (!value[index]) {
      newValue[index] = 0
    }
    if (value[index] < 1000) {
      newValue[index] = newValue[index] + 1
    }
    if ((newValue[1] || newValue[1] === 0) && newValue[1] <= newValue[0]) {
      setGlobalError((prevState: any) => ({ ...prevState, [name]: 'error' }))
      setValid([true, false])
    } else {
      setGlobalError((prevState: any) => {
        return Object.keys(prevState).reduce((object: any, key: string) => {
          if (key !== name) {
            object[key] = prevState[key]
          }
          return object
        }, {})
      })
      setValid([true, true])
    }
    setFieldValue(name, newValue, true)
  }

  const decrement = (): (() => void) => (): void => {
    const newValue = [...value]
    if (!value[index]) {
      return
    }
    newValue[index] = newValue[index] - 1
    if ((newValue[1] || newValue[1] === 0) && newValue[1] <= newValue[0]) {
      setGlobalError((prevState: any) => ({ ...prevState, [name]: 'error' }))
      setValid([true, false])
    } else {
      setGlobalError((prevState: any) => {
        return Object.keys(prevState).reduce((object: any, key: string) => {
          if (key !== name) {
            object[key] = prevState[key]
          }
          return object
        }, {})
      })
      setValid([true, true])
    }
    setFieldValue(name, newValue, true)
  }

  return (
    <Grid
      className={clsx({
        [classes.disabled]: disabled || (group && (!group.orderBy || group.orderBy !== 'name')),
      })}
    >
      <Test id={testId(0)}>
        <ButtonGroup disableRipple size="small" className={classes.box}>
          <Test id={testId(1)}>
            <Button
              onClick={increment()}
              className={clsx(classes.sortButton, classes.smallButton, {
                [classes.sortButtonActive]:
                  !disabled && group && group.orderBy === 'name' && group.order === 'asc',
              })}
              variant="outlined"
            >
              <Tooltip title={translate('translate#atmeye.title.sort.count.increment')}>
                <ExpandLess color="inherit" fontSize="small" />
              </Tooltip>
            </Button>
          </Test>
          <Test id={testId(2)}>
            <Button
              onClick={decrement()}
              className={clsx(classes.sortButton, classes.smallButton, {
                [classes.sortButtonActive]:
                  !disabled && group && group.orderBy === 'name' && group.order === 'desc',
              })}
              variant="outlined"
            >
              <Tooltip title={translate('translate#atmeye.title.sort.count.decrement')}>
                <ExpandMore color="inherit" fontSize="small" />
              </Tooltip>
            </Button>
          </Test>
        </ButtonGroup>
      </Test>
    </Grid>
  )
}
export default CounterBlock
