import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import AppsIcon from '@material-ui/icons/Apps'
import React, { FC, memo, SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'

import Button from '@/components/controls/Button/index'
import ContextMenu from '@/components/controls/ContextMenu/component'
import { availableModules } from '@/constants/auth'

import { useClasses } from './styles'
import { Props } from './types'

const TransitionMenu: FC<Props> = memo(
  ({
    sideMenuIsOpen,
    buttonVariant,
    pathname,
    classes: customClasses,
    modules,
  }: Props): React.ReactElement => {
    const [anchorEl, setAnchorEl] = useState<null | (EventTarget & HTMLButtonElement)>(null)

    const handleClose = useCallback((): void => {
      setAnchorEl(null)
    }, [setAnchorEl])

    const handleClick = useCallback(
      (event: SyntheticEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget)
      },
      [setAnchorEl],
    )

    const classes = useClasses({ sideMenuIsOpen })

    const checkResponseModule = useMemo(() => modules.filter(item => item in availableModules), [
      modules,
    ])

    const itemsMenuJSX =
      checkResponseModule.length &&
      checkResponseModule
        .filter(el => !availableModules[el]?.path.includes(pathname))
        .sort((a, b) => availableModules[a].name.localeCompare(availableModules[b].name))
        .map(el => {
          return (
            <MenuItem key={el} disableRipple className={classes.item} onClick={handleClose}>
              {availableModules[el].icon ? (
                <NavLink to={availableModules[el].path}>
                  <img src={availableModules[el].icon} alt={availableModules[el].name} />
                </NavLink>
              ) : (
                <Typography variant="body2" className={classes.menuItems}>
                  {availableModules[el].name}
                </Typography>
              )}
            </MenuItem>
          )
        })

    return (
      <>
        <Button
          variant={buttonVariant}
          width="xs"
          onClick={handleClick}
          className={customClasses?.button}
        >
          <AppsIcon />
        </Button>
        <div>
          <ContextMenu
            anchorEl={anchorEl}
            handleClose={handleClose}
            options={[]}
            open={Boolean(anchorEl)}
            classes={{
              list: classes.list,
            }}
            className={classes.menu}
          >
            <> {itemsMenuJSX} </>
          </ContextMenu>
        </div>
      </>
    )
  },
)

export default TransitionMenu
