import { AnyAction } from 'redux'

import {
  AdmCompany,
  AdmRole,
  AdmSpecificRole,
  AdmUser,
  AdmUserFilter,
  ComVaultReport,
  SelectorItem,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants/actions'
import { usersVaultsTableId as tableId } from '@/constants/vault-v1/tableIds'
import { getTableConfig } from '@/helpers/tableLocalStorage'

export type State = {
  selectedPanel: string

  users: {
    data: AdmUser[]
    filter: AdmUserFilter
    total: number
    isLoaded: boolean
    isLoading: boolean
    sortColumn?: string
    sortOrder?: string
    selectedUser: AdmUser | null

    paging: {
      page: number
      pageSize: number
    }
  }

  roles: {
    data: AdmRole[]
    total: number
    isLoaded: boolean
    isLoading: boolean
    sortColumn?: string
    sortOrder?: string
    selectedRole: AdmRole | null
    users: {
      data: AdmSpecificRole[]
      isLoaded: boolean
      isLoading: boolean
      sortColumn?: string
      sortOrder?: 'asc' | 'desc'
    }
  }

  companies: {
    data: AdmCompany[]
    total: number
    selectedCompany: AdmCompany | null
    isLoading: boolean
    isLoaded: boolean
    sortColumn?: string
    sortOrder?: string
  }

  userBadgeModal: {
    isOpen: boolean

    reports: {
      errorMessage?: string
      isLoading: boolean
      list: ComVaultReport[]
      selectedReport: {
        isLoading: boolean
        errorMessage?: string
        bytes?: string | null // Blob in TS has no arrayBuffer method
      }
    }
  }

  userCashierTypes: SelectorItem[]
}

export const initialState: State = {
  selectedPanel: '',

  users: {
    data: [],
    total: 0,
    isLoading: false,
    isLoaded: false,
    filter: {
      userName: '',
      friendlyName: '',
      roleId: '',
      companyId: '',
      cashierCode: '',
    },
    sortColumn: undefined,
    sortOrder: undefined,

    selectedUser: null,

    paging: {
      page: 1,
      pageSize: getTableConfig({ tableId })?.rowPerPage || 50,
    },
  },

  roles: {
    data: [],
    total: 0,
    isLoaded: false,
    isLoading: false,
    sortColumn: undefined,
    sortOrder: undefined,
    selectedRole: null,
    users: {
      data: [],
      isLoaded: false,
      isLoading: false,
    },
  },

  companies: {
    data: [],
    total: 0,
    selectedCompany: null,
    isLoading: false,
    isLoaded: false,
    sortColumn: undefined,
    sortOrder: undefined,
  },

  userBadgeModal: {
    isOpen: false,

    reports: {
      errorMessage: '',
      isLoading: false,
      list: [],
      selectedReport: {
        isLoading: false,
        errorMessage: '',
        bytes: undefined,
      },
    },
  },

  userCashierTypes: [],
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    // [START] Users --------------------------------------
    case VaultV1Actions.AdmSetSelectedUsersRolesPanel:
      return {
        ...state,
        selectedPanel: payload,
      }

    case VaultV1Actions.AdmSetUsersPaging:
      return {
        ...state,
        users: {
          ...state.users,
          selectedUser: null,
          isLoading: true,
          paging: {
            ...state.users.paging,
            ...payload,
          },
        },
      }
    case VaultV1Actions.AdmSortUsers:
      return {
        ...state,
        users: {
          ...state.users,
          ...payload,
          paging: {
            ...state.users.paging,
            page: 1,
          },
          isLoading: true,
        },
      }
    case VaultV1Actions.AdmFilterUsersRequest:
      return {
        ...state,
        users: {
          ...state.users,
          filter: payload,
          isLoading: true,
          paging: {
            ...state.users.paging,
            page: 1,
          },
        },
      }

    case VaultV1Actions.AdmSetSelectedUser:
      return {
        ...state,
        users: {
          ...state.users,
          selectedUser: payload,
        },
      }

    case VaultV1Actions.AdmGetUsersRequest:
    case VaultV1Actions.AdmAddUserRequest:
    case VaultV1Actions.AdmUpdateUserRequest:
    case VaultV1Actions.AdmDeleteUserRequest:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: true,
        },
      }
    case VaultV1Actions.AdmGetUsersFail:
    case VaultV1Actions.AdmAddUserFail:
    case VaultV1Actions.AdmUpdateUserFail:
    case VaultV1Actions.AdmDeleteUserFail:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: false,
          isLoaded: true,
        },
      }

    case VaultV1Actions.AdmGetUsersResponse:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: false,
          isLoaded: true,
          data: payload.users,
          total: payload.total,
        },
      }

    // Badge
    case VaultV1Actions.AdmOpenUserBadgeModal:
      return {
        ...state,
        userBadgeModal: {
          ...state.userBadgeModal,
          isOpen: payload,
        },
      }

    case VaultV1Actions.AdmGetBadgeReportsRequest:
      return {
        ...state,
        userBadgeModal: {
          ...state.userBadgeModal,
          reports: {
            ...state.userBadgeModal.reports,
            isLoading: true,
            list: [],
            errorMessage: '',
            selectedReport: initialState.userBadgeModal.reports.selectedReport,
          },
        },
      }
    case VaultV1Actions.AdmGetBadgeReportsResponse:
      return {
        ...state,
        userBadgeModal: {
          ...state.userBadgeModal,
          reports: {
            ...state.userBadgeModal.reports,
            isLoading: false,
            list: payload,
            errorMessage: '',
          },
        },
      }
    case VaultV1Actions.AdmGetBadgeReportsFail:
      return {
        ...state,
        userBadgeModal: {
          ...state.userBadgeModal,
          reports: {
            ...state.userBadgeModal.reports,
            isLoading: false,
            list: [],
            errorMessage: payload,
          },
        },
      }

    case VaultV1Actions.AdmGetBadgeReportRequest:
      return {
        ...state,
        userBadgeModal: {
          ...state.userBadgeModal,
          reports: {
            ...state.userBadgeModal.reports,
            selectedReport: {
              ...state.userBadgeModal.reports.selectedReport,
              isLoading: true,
              errorMessage: '',
              bytes: undefined,
            },
          },
        },
      }
    case VaultV1Actions.AdmGetBadgeReportResponse:
      return {
        ...state,
        userBadgeModal: {
          ...state.userBadgeModal,
          reports: {
            ...state.userBadgeModal.reports,
            selectedReport: {
              ...state.userBadgeModal.reports.selectedReport,
              isLoading: false,
              errorMessage: '',
              bytes: payload,
            },
          },
        },
      }
    case VaultV1Actions.AdmGetBadgeReportFail:
      return {
        ...state,
        userBadgeModal: {
          ...state.userBadgeModal,
          reports: {
            ...state.userBadgeModal.reports,
            selectedReport: {
              ...state.userBadgeModal.reports.selectedReport,
              isLoading: false,
              errorMessage: payload,
              bytes: undefined,
            },
          },
        },
      }

    // [END] Users --------------------------------------

    // [START] Roles --------------------------------------
    case VaultV1Actions.AdmGetRolesRequest:
    case VaultV1Actions.AdmAddRoleRequest:
    case VaultV1Actions.AdmUpdateRoleRequest:
    case VaultV1Actions.AdmDeleteRoleRequest:
      return {
        ...state,
        roles: {
          ...state.roles,
          isLoading: true,
        },
      }
    case VaultV1Actions.AdmGetRolesResponse:
      return {
        ...state,
        roles: {
          ...state.roles,
          isLoading: false,
          isLoaded: true,
          data: payload,
          total: (payload || []).length,
        },
      }
    case VaultV1Actions.AdmGetRolesFail:
    case VaultV1Actions.AdmAddRoleFail:
    case VaultV1Actions.AdmUpdateRoleFail:
    case VaultV1Actions.AdmDeleteRoleFail:
      return {
        ...state,
        roles: {
          ...state.roles,
          isLoading: false,
          isLoaded: true,
        },
      }
    case VaultV1Actions.AdmSortRoles:
      return {
        ...state,
        roles: {
          ...state.roles,
          isLoading: true,
          ...payload,
        },
      }
    case VaultV1Actions.AdmSetSelectedRole:
      return {
        ...state,
        roles: {
          ...state.roles,
          selectedRole: payload,
        },
      }
    case VaultV1Actions.AdmGetUsersByRoleRequest:
      return {
        ...state,
        roles: {
          ...state.roles,
          users: {
            ...state.roles.users,
            isLoading: true,
          },
        },
      }
    case VaultV1Actions.AdmGetUsersByRoleResponse:
      return {
        ...state,
        roles: {
          ...state.roles,
          users: {
            ...state.roles.users,
            isLoading: false,
            isLoaded: true,
            data: payload,
          },
        },
      }
    case VaultV1Actions.AdmGetUsersByRoleFail:
      return {
        ...state,
        roles: {
          ...state.roles,
          users: {
            ...state.roles.users,
            isLoading: false,
            isLoaded: true,
            data: [],
          },
        },
      }
    case VaultV1Actions.AdmAssignUsersRoleRequest:
      return {
        ...state,
        roles: {
          ...state.roles,
          users: {
            ...state.roles.users,
            isLoading: true,
          },
        },
      }
    // [END] Roles --------------------------------------

    // [START] Companies --------------------------------------
    case VaultV1Actions.AdmGetCompaniesRequest:
    case VaultV1Actions.AdmAddCompanyRequest:
    case VaultV1Actions.AdmUpdateCompanyRequest:
    case VaultV1Actions.AdmDeleteCompanyRequest:
      return {
        ...state,
        companies: {
          ...state.companies,
          isLoading: true,
        },
      }
    case VaultV1Actions.AdmGetCompaniesResponse:
      return {
        ...state,
        companies: {
          ...state.companies,
          isLoading: false,
          isLoaded: true,
          data: payload,
          total: (payload || []).length,
        },
      }
    case VaultV1Actions.AdmGetCompaniesFail:
    case VaultV1Actions.AdmAddCompanyFail:
    case VaultV1Actions.AdmUpdateCompanyFail:
    case VaultV1Actions.AdmDeleteCompanyFail:
      return {
        ...state,
        companies: {
          ...state.companies,
          isLoading: false,
          isLoaded: true,
        },
      }
    case VaultV1Actions.AdmSetSelectedCompany:
      return {
        ...state,
        companies: {
          ...state.companies,
          selectedCompany: payload,
        },
      }
    case VaultV1Actions.AdmSortCompanies:
      return {
        ...state,
        companies: {
          ...state.companies,
          isLoading: true,
          ...payload,
        },
      }
    case VaultV1Actions.AdmGetUserCashierTypesResponse:
      return {
        ...state,
        userCashierTypes: payload || [],
      }
    // [END] Companies --------------------------------------

    case VaultV1Actions.SignOut:
      return initialState
    default:
      return state
  }
}
