import { ValidationService } from '@/utils/services/validationService'
import { ValidationErrors } from '@/constants/forms/validationErrors'
import { FormikValues } from 'formik'
import {
  FIELD_NAME_TERMINAL_IDS,
  FIELD_NAME_TERMINALS,
  POS_AQUIRER_LUNO,
  POS_MERCHANT_ID,
  WorkingHoursBlockError,
} from '@/components/wrappers/AppNavigation/components/DrawerActions/components/modals/ServiceTypeModal/components/ServiceTypeForm/types'
import {
  FROM_HOUR_POSTFIX,
  FROM_MIN_POSTFIX,
  TO_HOUR_POSTFIX,
  TO_MIN_POSTFIX,
  WEEK_DAYS,
  WEEKDAY_FIELD_NAME_PREFIX,
} from '@/components/wrappers/AppNavigation/components/DrawerActions/components/modals/fields/WorkingHoursGroup'
import { CreatePOSRequestFormFields } from '@/constants'
import { IdsFields } from '@/components/wrappers/AppNavigation/components/DrawerActions/components/modals/ServiceTypeModal/components/ServiceTerminals'

export const validateForm = (
  values: FormikValues,
  withTerminalIdsField = false,
  isEditing = false,
  fieldParameters: any,
): { [key: string]: string | WorkingHoursBlockError } => {
  const errors: { [key: string]: string | WorkingHoursBlockError } = {}
  const hasWorkingHoursBlock = Object.keys(values).some(key =>
    key.includes(WEEKDAY_FIELD_NAME_PREFIX),
  )
  const checkDependencies = (attributeName: string): boolean => {
    const result = fieldParameters[attributeName].dependedToSetOnlyOneFrom.some(
      (dep: string) => !!values[dep].length,
    )
    return result
  }

  Object.keys(fieldParameters).forEach((param: any) => {
    if (values[param] !== undefined && fieldParameters[param].required && !values[param].length) {
      errors[param] = ValidationErrors.Required
    }
    if (
      fieldParameters[param] &&
      fieldParameters[param].dependedToSetOnlyOneFrom &&
      fieldParameters[param].dependedToSetOnlyOneFrom.length &&
      checkDependencies(param)
    ) {
      delete errors[param]
    }
  })
  if (errors.posaquirerluno === 'required') delete errors.posaquirerluno
  if (errors.posmerchantid === 'required') delete errors.posmerchantid

  // if (!ValidationService.isRequired(values[TerminalInstallationFields.Name])) {
  //   errors[TerminalInstallationFields.Name] = ValidationErrors.Required
  // }

  if (!isEditing) {
    if (withTerminalIdsField) {
      if (ValidationService.isEmpty(values[FIELD_NAME_TERMINAL_IDS])) {
        errors[FIELD_NAME_TERMINAL_IDS] = ValidationErrors.Required
      } else if (
        (values.terminalIds &&
          values.terminalIds.some((t: IdsFields) => !t[POS_AQUIRER_LUNO] || !t[POS_MERCHANT_ID])) ||
        values.terminalIds.some((t: IdsFields) => /(^\s+|\s+$)/.test(t[POS_AQUIRER_LUNO])) ||
        values.terminalIds.some((t: IdsFields) => /(^\s+|\s+$)/.test(t[POS_MERCHANT_ID]))
      ) {
        errors[FIELD_NAME_TERMINAL_IDS] = ValidationErrors.Required
      }
    } else {
      if (ValidationService.isEmpty(values[FIELD_NAME_TERMINALS])) {
        errors[FIELD_NAME_TERMINALS] = ValidationErrors.Required
      }
    }
  }

  const date = values[CreatePOSRequestFormFields.PosServiceStartDate]

  if (date && !ValidationService.isDateAfterNow(date)) {
    errors[CreatePOSRequestFormFields.PosServiceStartDate] =
      ValidationErrors.DateCannotBeLessThanTomorrow
  }

  if (hasWorkingHoursBlock) {
    WEEK_DAYS.forEach((day, index) => {
      const fromHourKey = `${WEEKDAY_FIELD_NAME_PREFIX}${index + 1}${FROM_HOUR_POSTFIX}`
      const fromMinKey = `${WEEKDAY_FIELD_NAME_PREFIX}${index + 1}${FROM_MIN_POSTFIX}`
      const toHourKey = `${WEEKDAY_FIELD_NAME_PREFIX}${index + 1}${TO_HOUR_POSTFIX}`
      const toMinKey = `${WEEKDAY_FIELD_NAME_PREFIX}${index + 1}${TO_MIN_POSTFIX}`

      if (values[fromHourKey] && values[toHourKey]) {
        if (+values[fromHourKey] > +values[toHourKey]) {
          errors[fromHourKey] = {
            key: ValidationErrors.FromHourValueCannotBeMoreThanToHourValue,
            fromValue: values[fromHourKey],
            toValue: values[toHourKey],
          }
        }

        if (values[fromHourKey] === values[toHourKey]) {
          const valueFromMin = values[fromMinKey]
          const valueToMin = values[toMinKey]

          if (valueFromMin && valueToMin && +valueFromMin >= +valueToMin) {
            errors[fromHourKey] = {
              key: ValidationErrors.FromMinuteValueShouldBeMoreThanToMinuteValue,
              fromValue: valueFromMin,
              toValue: valueToMin,
            }
          }
        }
      }

      if (values[fromHourKey] && values[fromHourKey] > 23) {
        errors[fromHourKey] = ValidationErrors.HoursCountCannotBeMoreThan23
      }

      if (values[toHourKey] && values[toHourKey] > 23) {
        errors[toHourKey] = ValidationErrors.HoursCountCannotBeMoreThan23
      }

      if (values[fromMinKey] && values[fromMinKey] > 59) {
        errors[fromMinKey] = ValidationErrors.MinCountCannotBeMoreThan59
      }

      if (values[toMinKey] && values[toMinKey] > 59) {
        errors[toMinKey] = ValidationErrors.MinCountCannotBeMoreThan59
      }
    })
  }
  return errors
}
