import styled from 'styled-components'
import makeStyles from '@material-ui/core/styles/makeStyles'
import colors from '@/theme/colors'

export const Background = styled.div``

export const useClasses = makeStyles(({ spacing, palette, breakpoints }) => ({
  background: {
    height: '100%',
    display: 'flex',
    backgroundColor: colors.backgroundLogin,
  },
  wrapper: {
    display: 'flex',
  },
  form: {
    width: breakpoints.values.sm - spacing(15),
    padding: spacing(3, 4),
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    minWidth: spacing(4.68),
    marginBottom: spacing(1),
    marginTop: spacing(2),
    // height:'40px'
  },

  buttonLoginWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  cancelButton: {
    marginRight: spacing(1),
    color: colors.primary,
    borderColor: colors.primary,
    '&:hover': {
      borderColor: palette.grey[200],
      backgroundColor: colors.grey50,
    },
  },
  selectCompany: {
    margin: spacing(1, 0),
  },
  input: {
    width: '100%',
  },
  language: {
    position: 'absolute',
    top: spacing(3),
    right: spacing(4),
    zIndex: 100,
  },
  logoWrapper: {
    height: spacing(10),
    marginBottom: spacing(3),
    textAlign: 'center',
  },

  fontSize: {
    fontSize: '5px',
  },

  loginAndLocaleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '20px',
  },

  loaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    background: 'rgba(255, 255, 255, 0.7)',
  },
}))
