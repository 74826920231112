import { throttle } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

type VisibleRows = { [key: string]: boolean }

interface VirtualizeTableProps {
  tableRef: React.MutableRefObject<HTMLDivElement | null>
  ids: string[]
  total: number
}

export const useVirtualizeTable = (props: VirtualizeTableProps): VisibleRows => {
  const { tableRef, ids, total } = props

  const scrollY = useRef(0)

  const [visibleRows, setVisibleRows] = useState<VisibleRows>({})

  const handleVisible = useCallback(() => {
    const localVisibleRows: VisibleRows = ids.reduce((acc, id) => {
      const row = document?.getElementById(id)

      if (row) {
        const { top, bottom } = row.getBoundingClientRect()
        const visibility = top >= -200 && bottom <= window.innerHeight + 200

        return {
          ...acc,
          [id]: visibility,
        }
      }

      return { ...acc }
    }, {})

    setVisibleRows(localVisibleRows)
  }, [ids])

  const handleScroll = useCallback(() => {
    // Handle only Y direction
    if (tableRef.current?.scrollTop !== scrollY.current) {
      scrollY.current = tableRef.current?.scrollTop || 0

      handleVisible()
    }
  }, [handleVisible])

  const debouncedSetVisibility = useMemo(() => throttle(handleScroll, 30, { leading: false }), [
    throttle,
    handleScroll,
  ])

  useEffect(() => {
    if (total > 400) {
      handleVisible()
    }
  }, [total, handleVisible])

  useEffect(() => {
    if (total > 400 && tableRef?.current) {
      const tableContainer = tableRef.current

      if (tableContainer) {
        tableContainer.addEventListener('scroll', debouncedSetVisibility)
        return (): void => {
          tableContainer.removeEventListener('scroll', debouncedSetVisibility)
        }
      }
    }
  }, [total, tableRef, debouncedSetVisibility])

  return visibleRows
}
