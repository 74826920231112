import { AnyAction } from 'redux'

import {
  Currency,
  DeclineNote,
  FilterTreeNode,
  GlobalFilter,
  MonRemainderUpdateAvailabilityEntry,
  SelectorItem,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants'
import { BuildInfo } from '@/types/cm/common'

export interface State {
  globalFilter: GlobalFilter
  filterNodes: {
    data: FilterTreeNode
    isLoading: boolean
    isLoaded: boolean
    error: string | null
  }

  declineNoteState: {
    isModalOpen: boolean
    isLoading: boolean
    error: string | null
    data: DeclineNote | null
  }
  integrations: string[]
  buildInfo: BuildInfo | null

  vaults: SelectorItem[]

  currencies: Currency[]

  rollbackAvailable: {
    data: { [key: string]: MonRemainderUpdateAvailabilityEntry }
    isLoading: boolean
  }

  about: {
    isModalOpen: boolean
  }
}

const initialState: State = {
  filterNodes: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  globalFilter: {
    tids: [],
    branchIds: [],
  },

  declineNoteState: {
    isModalOpen: false,
    isLoading: false,
    error: null,
    data: null,
  },
  integrations: [],

  buildInfo: null,

  vaults: [],

  currencies: [],

  rollbackAvailable: {
    data: {},
    isLoading: false,
  },

  about: {
    isModalOpen: false,
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case VaultV1Actions.SetGlobalFilter:
      return {
        ...state,
        globalFilter: {
          ...state.globalFilter,
          ...payload,
        },
      }

    case VaultV1Actions.MonGetVaultsRequest:
    case VaultV1Actions.OrdGetOrdersRequest:
      return {
        ...state,
        filterNodes: {
          ...state.filterNodes,
          isLoading: true,
          error: null,
        },
      }
    case VaultV1Actions.GetFilterNodesResponse:
      return {
        ...state,
        filterNodes: {
          ...state.filterNodes,
          data: payload,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      }
    case VaultV1Actions.GetFilterNodesFail:
      return {
        ...state,
        filterNodes: {
          ...state.filterNodes,
          data: {},
          isLoading: false,
          isLoaded: true,
          error: payload,
        },
      }

    case VaultV1Actions.OrdSetDeclineNoteModalOpen: {
      const isOpen = payload
      return {
        ...state,
        declineNoteState: {
          ...(!isOpen ? initialState.declineNoteState : state.declineNoteState),
          isModalOpen: payload,
        },
      }
    }
    case VaultV1Actions.OrdSetDeclineNote:
      return {
        ...state,
        declineNoteState: {
          ...state.declineNoteState,
          isLoading: false,
          error: null,
          data: payload,
          isModalOpen: true,
        },
      }

    case VaultV1Actions.SetAboutModalOpen:
      return {
        ...state,
        about: {
          ...state.about,
          isModalOpen: payload,
        },
      }

    case VaultV1Actions.GetIntegrationsResponse:
      return {
        ...state,
        integrations: payload,
      }
    case VaultV1Actions.GetIntegrationsFail:
      return {
        ...state,
        integrations: [],
      }

    case VaultV1Actions.ComGetVaultBalancesStatusRequest:
      return {
        ...state,
        rollbackAvailable: {
          ...state.rollbackAvailable,
          isLoading: true,
        },
      }
    case VaultV1Actions.ComGetVaultBalancesStatusResponse: {
      const vaultBalancesStatus = {} as { [key: string]: MonRemainderUpdateAvailabilityEntry }

      if (payload) {
        payload.forEach(({ coid, ...rest }: MonRemainderUpdateAvailabilityEntry) => {
          if (coid) {
            vaultBalancesStatus[coid] = { coid, ...rest }
          }
        })
      }

      return {
        ...state,
        rollbackAvailable: {
          ...state.rollbackAvailable,
          data: vaultBalancesStatus || {},
          isLoading: false,
        },
      }
    }
    case VaultV1Actions.ComGetVaultBalancesStatusFail:
      return {
        ...state,
        rollbackAvailable: {
          ...state.rollbackAvailable,
          data: {},
          isLoading: false,
        },
      }
    case VaultV1Actions.ComGetVaultBalancesStatusClear:
      return {
        ...state,
        rollbackAvailable: {
          ...state.rollbackAvailable,
          data: {},
          isLoading: false,
        },
      }

    // Build Info
    case VaultV1Actions.GetVaultBuildInfoResponse:
      return {
        ...state,
        buildInfo: payload,
      }
    case VaultV1Actions.GetVaultBuildInfoFail:
      return {
        ...state,
        buildInfo: null,
      }

    // Com Vaults
    case VaultV1Actions.ComGetVaultsListResponse:
      return {
        ...state,
        vaults: payload || [],
      }

    // Com Currencies
    case VaultV1Actions.ComGetVaultCurrenciesResponse:
      return {
        ...state,
        currencies: payload || [],
      }

    case VaultV1Actions.SignOut:
      return initialState
    default:
      return state
  }
}
