import { emptyUserSettingsConfig, LocalStorageItems } from '@/constants'
import { DataToLS } from '@/types/cm/localStorage'

import { getParsedLocalStorageItem, setDataToLocalStorage } from '../localStorage'

export const setUserToLS = (props: DataToLS): void => {
  const { userName, userData, baseUrl, modules, permissions } = props

  const getLSItem = getParsedLocalStorageItem(baseUrl)
  const setLSItem = setDataToLocalStorage(baseUrl)

  const initialUserSettings = {
    [userName]: emptyUserSettingsConfig,
  }

  const allUsersSettings = getLSItem(LocalStorageItems.UsersSettings) || {}
  const newUserSettings = !allUsersSettings[userName] ? initialUserSettings : {}
  const userSettings = { ...allUsersSettings, ...newUserSettings }

  setLSItem(LocalStorageItems.UserModules, JSON.stringify(modules))
  setLSItem(LocalStorageItems.UserPermissions, JSON.stringify(permissions))
  setLSItem(LocalStorageItems.UserData, JSON.stringify(userData))
  setLSItem(LocalStorageItems.UsersSettings, JSON.stringify(userSettings))

  localStorage.removeItem(LocalStorageItems.IsChangePassword)
}
