import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  groupWrapper: {
    width: '100%',
    breakInside: 'avoid',
  },
  subtitleWrapper: {
    marginTop: spacing(2),
  },
}))
