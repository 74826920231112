import queryString from 'query-string'

import {
  AdmSpecialPeriod,
  AdmSpecialPeriodBase,
  ComAdminBranchAndObjectFilter,
} from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

interface GetProps extends Sort {
  filter: ComAdminBranchAndObjectFilter
}

export class SpecialPeriodsApi {
  private static requestService = getRequestServiceCM()

  public static async get({ filter, ...sort }: GetProps): Promise<AdmSpecialPeriod[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/specialPeriods',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await SpecialPeriodsApi.requestService.post(url, filter)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(payload: AdmSpecialPeriodBase): Promise<void> {
    try {
      return await SpecialPeriodsApi.requestService.post('/admin/specialPeriods/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(payload: AdmSpecialPeriod): Promise<void> {
    try {
      return await SpecialPeriodsApi.requestService.put('/admin/specialPeriods/update', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(id: number): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/specialPeriods/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await SpecialPeriodsApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
