import mime from 'mime-types'

import { PopUpService } from './services/popUpService'

export const checkFile = (file: File): boolean => {
  if (!file.name) {
    PopUpService.showGlobalPopUp('translate#title.fileNotSelected', 'error')
    return false
  } else if (file.size >= 8388608) {
    PopUpService.showGlobalPopUp('translate#title.fileTooBig', 'error')
    return false
  } else return true
}

export const fileToBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = (): void => resolve(reader.result)
    reader.onerror = (error): void => reject(error)
  })

export const base64toBlob = (base64Data: string, contentType = ''): Blob => {
  const type = mime.lookup(contentType) || contentType
  const byteString = atob(base64Data)
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)

  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ab], { type })
}

export const openOrDownloadBlob = (blob: Blob, fileName: string): void => {
  const blobURL = URL.createObjectURL(blob)

  if (blob.type.includes('image/') || blob.type === 'application/pdf') {
    window.open(blobURL)
  } else {
    const link = document.createElement('a')

    link.href = blobURL
    link.download = fileName

    document.body.appendChild(link)

    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    )

    document.body.removeChild(link)
  }
}
