import { AnyAction } from 'redux'

import { AdmRoundingSetting } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants/actions'

export type State = {
  data: AdmRoundingSetting | null
  isLoading: boolean
  isLoaded: boolean
  error: string | null
}

export const initialState: State = {
  data: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case CMAppConfigActions.AdmUpdateRoundingSettingsRequest:
    case CMAppConfigActions.AdmGetRoundingSettingsRequest:
      return {
        ...state,
        isLoading: true,
      }
    case CMAppConfigActions.AdmGetRoundingSettingsResponse:
      return {
        ...state,

        isLoading: false,
        isLoaded: true,
        data: payload,
      }
    case CMAppConfigActions.AdmUpdateRoundingSettingsFail:
    case CMAppConfigActions.AdmGetRoundingSettingsFail:
      return {
        ...state,

        isLoading: false,
        isLoaded: true,
        error: payload,
      }

    default:
      return state
  }
}
