import queryString from 'query-string'

import {
  AdmAccessRulesRolePermissions,
  AdmApprovalForm,
  AdmApprovalRole,
} from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

interface GetRolesProps {
  sort: Sort
  selectedFormId: string
}

export class ApprovalOfChangesApi {
  private static requestService = getRequestServiceCM()

  public static async getForms(sort: Sort): Promise<AdmAccessRulesRolePermissions> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/approval/forms',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )
      return await ApprovalOfChangesApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setForms(payload: AdmApprovalForm[]): Promise<void> {
    try {
      return await ApprovalOfChangesApi.requestService.put('/admin/approval/forms/update', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getRoles({
    sort,
    selectedFormId,
  }: GetRolesProps): Promise<AdmAccessRulesRolePermissions> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/approval/roles',
          query: {
            ...sort,
            id: selectedFormId,
          },
        },
        { skipNull: true, encode: false },
      )
      return await ApprovalOfChangesApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setRoles(payload: AdmApprovalRole[]): Promise<void> {
    try {
      return await ApprovalOfChangesApi.requestService.put('/admin/approval/roles/update', payload)
    } catch (e) {
      throw new Error(e)
    }
  }
}
