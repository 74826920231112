import { AnyAction } from 'redux'

import { ErrorActions } from '@/constants/actions/index'

import { ActError, MultipleErrors } from './reducer'

export const setActErrorVisible = ({
  type = 'ack_error',
  isVisible,
  errorMessage = null,
  actionType = '',
  actionPayload = null,
}: ActError): AnyAction => ({
  type: ErrorActions.SetActErrorVisible,
  payload: { type, isVisible, errorMessage, actionType, actionPayload },
})

export const setActErrorInitial = (): AnyAction => ({
  type: ErrorActions.SetActErrorInitial,
})

export const setMultipleErrorsVisible = ({
  isVisible,
  errorMessage = null,
  messageArray = [],
}: MultipleErrors): AnyAction => ({
  type: ErrorActions.SetMultipleErrorsVisible,
  payload: { isVisible, errorMessage, messageArray },
})

export const setMultipleErrorsInitial = (): AnyAction => ({
  type: ErrorActions.SetMultipleErrorsInitial,
})
