import clsx from 'clsx'
import React, { FC, ChangeEvent, useCallback } from 'react'

import { Select } from '@/components/controls/Select'

import { useClasses } from './styles'
import { Props } from './types'

export const CellSelect: FC<Props> = React.memo((props: Props) => {
  const { className, defaultValue, onChange, ...rest } = props

  const classes = useClasses()

  const handleChange = useCallback(
    (event: ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
      event.stopPropagation()

      onChange(event.target.value as string)
    },
    [onChange],
  )

  return (
    <div className={clsx(classes.cellSelectWrapper, className)}>
      <Select
        {...rest}
        neverDisable
        withAutofillForSingleOption={false}
        value={defaultValue}
        shrink={false}
        variant="standard"
        onChange={handleChange}
      />
    </div>
  )
})
