import {
  AdmCashReplenishmentCost,
  ComAdminBranchAndObjectFilter,
} from '@/api/cm/swaggerGeneratedApi'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class CashReplenishmentCostsApi {
  private static requestService = getRequestServiceCM()

  public static async get(
    payload: ComAdminBranchAndObjectFilter,
  ): Promise<AdmCashReplenishmentCost[]> {
    try {
      return await CashReplenishmentCostsApi.requestService.post(
        '/admin/cashReplenishmentCosts',
        payload,
      )
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(payload: AdmCashReplenishmentCost[]): Promise<void> {
    try {
      return await CashReplenishmentCostsApi.requestService.put(
        '/admin/cashReplenishmentCosts/update',
        payload,
      )
    } catch (error) {
      throw Error(error)
    }
  }
}
