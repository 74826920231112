import { createSelector } from 'reselect'
import { AppState } from '@/store/reducers'
import * as fromReducer from './reducer'

export const getDictionariesListState = (state: AppState): fromReducer.State =>
  state.dictionaryDictionariesList

export const getFoldingDictionariesListState = createSelector(getDictionariesListState, state =>
  state ? state.isFolded : false,
)

export const getDictionaries = createSelector(getDictionariesListState, state => state.dictionaries)
export const getIsFetchingDictionaries = createSelector(
  getDictionariesListState,
  state => state.isFetchingDictionaries,
)

export const getSelectedDictionary = createSelector(
  getDictionariesListState,
  state => state.selectedDictionary,
)
