import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  paper: {
    padding: spacing(0, 3),
    paddingTop: spacing(4),
    paddingBottom: spacing(3),
    minWidth: 682,
    position: 'relative',
    overflowX: 'hidden',
  },
  buttonsContainer: {
    position: 'static',
    justifyContent: 'space-between',
    marginTop: spacing(2),
  },
  loaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    background: 'rgba(255, 255, 255, 0.7)',
  },
}))
