import React from 'react'

import { store } from '@/App'

export const ADMRoutes = React.lazy(async () => {
  const {
    default: { reducer: admWindowsSplitterReducer },
  } = await import('@/store/adm/windowsSplitter')
  store.injectReducer('admWindowsSplitter', admWindowsSplitterReducer)

  const {
    default: { reducer: admDevice, saga: admSagaDevice },
  } = await import('@/store/adm/device')
  store.injectReducer('admDevice', admDevice)
  store.injectSaga('admDevice', admSagaDevice)

  const {
    default: { reducer: reducerCommonSearchForm, saga: sagaCommonSearchForm },
  } = await import('@/store/common/searchForm')

  store.injectReducer('commonSearchForm', reducerCommonSearchForm)
  store.injectSaga('commonSearchForm', sagaCommonSearchForm)

  const {
    default: { reducer: admDevicesImportReducer, saga: admDevicesImportSaga },
  } = await import('@/store/adm/devicesImport')
  store.injectReducer('admDevicesImport', admDevicesImportReducer)
  store.injectSaga('admDevicesImport', admDevicesImportSaga)

  const {
    default: {
      reducer: admDevicesConfigurationImportReducer,
      saga: admDevicesConfigurationImportSaga,
    },
  } = await import('@/store/adm/devicesConfigurationImport')
  store.injectReducer('admDevicesConfigurationImport', admDevicesConfigurationImportReducer)
  store.injectSaga('admDevicesConfigurationImport', admDevicesConfigurationImportSaga)

  return import('@/components/routers/adm')
})
