export enum RfmActions {
  GetDevicesRequest = '@/RMF/DEVICES/GET_DEVICES_REQUEST',
  GetTicketResponse = '@/RMF/GET_TICKET_RESPONSE',
  GetTicketResponseFail = '@/RMF/GET_TICKET_RESPONSE_FAIL',
  ChangeSelectedFilters = '@/RMF/CHANGE_SELECTED_FILTERS',
  GetFiltersTemplatesRequest = '@/RMF/DEVICES/GET_FILTERS_TEMPLATES_REQUEST',
  SetFilterTemplatesResponse = '@/RMF/DEVICES/SET_FILTER_TEMPLATE_RESPONSE',
  CreateFilterTemplateRequest = '@/RMF/DEVICES/CREATE_FILTER_TEMPLATE_REQUEST',
  CreateSdFilterTemplateResponse = '@/CREATE_SD_FILTER_TEMPLATE_RESPONSE',
  UpdateFilterTemplateRequest = '@/RMF/DEVICES/UPDATE_FILTER_TEMPLATE_REQUEST',
  CollectFilterTemplateFields = '@/RMF/COLLECT_FILTER_TEMPLATE_FIELDS',
  UpdateSdFilterTemplateResponse = '@/RMF/UPDATE_SD_FILTER_TEMPLATE_RESPONSE',
  DeleteFilterTemplateRequest = '@/RMF/DEVICES/DELETE_FILTER_TEMPLATE_REQUEST',
  GetGroupsTemplatesRequest = '@/RMF/DEVICES/GET_GROUPS_TEMPLATE_REQUEST',
  EditGroupTemplateRequest = '@/RMF/DEVICES/EDIT_GROUP_TEMPLATE_REQUEST',
  DeleteGroupTemplateRequest = '@/RMF/DEVICES/DELETE_GROUP_TEMPLATE_REQUEST',
  SaveGroupingTemplateRequest = '@/RMF/DEVICES/SAVE_GROUPING_TEMPLATE_REQUEST',
  SetCurrentFilterTemplate = '@/RMF/SET_CURRENT_FITLER_TEMPLATE',
  GetDevicesConfigRequest = '@/RMF/DEVICES/GET_DEVICES_CONFIG_REQUEST',
  GetDevicesConfigResponse = '@/RMF/DEVICES/GET_DEVICES_CONFIG_RESPONSE',
  GetTicketsConfigResponseFail = '@/RMF/GET_TICKETS_CONFIG_RESPONSE_FAIL',
  ChangeGrouping = '@/RMF/CHANGE_GROUPING',
  SetDataPagination = '@/RMF/DEVICES/SET_DATA_PAGINATION',
  GetTicketDetailsRequest = '@/RMF/GET_TICKET_DETAILS_REQUEST',
  SetWaitingJobsState = '@/RFM/SET_WAITING_JOBS_STATE',
  SetSelectedGroupingTemplate = '@/RFM/SET_SELECTED_GROUPING_TEMPLATE',
}
