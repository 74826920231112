import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles(({ mixins, spacing, palette }) =>
  createStyles({
    buttonWrapper: {
      display: 'flex',
      ...mixins.gutters(),
      marginTop: spacing(1),
    },
    button: {
      flex: 1,
      width: spacing(17),
      height: spacing(4),
    },
    deleteButton: {
      color: palette.error.main,
      width: spacing(17),

      fontWeight: 'bold',
      backgroundColor: palette.error.light,
      border: 'none',
      marginRight: spacing(0),
      '&:hover': {
        backgroundColor: palette.error.light,
        border: 'none',
      },
    },
    closeIcon: {
      padding: 0,
      color: palette.grey[200],
    },
  }),
)
