import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getPlanningState = (state: AppState): fromReducer.State => state.cmPlanning

export const getPlnCashPoints = createSelector(getPlanningState, state => state.cashPoints)
export const getPlnCheckedCashPoints = createSelector(
  getPlnCashPoints,
  state => state.checkedCashPoints,
)

export const getPlnCashPointsFilter = createSelector(
  getPlanningState,
  state => state.cashPoints.filter,
)

export const getModalOpenStateSelector = createSelector(
  getPlanningState,
  ({
    isFiltersModalOpen,
    isDocumentModalOpen,
    isModifyModalOpen,
    isCalculateModalOpen,
    isLoadingModalOpen,
    isSettingsModalOpen,
    ...props
  }) => ({
    isFiltersModalOpen,
    isDocumentModalOpen,
    isModifyModalOpen,
    isCalculateModalOpen,
    isLoadingModalOpen,
    isSettingsModalOpen,
  }),
)

export const getModifyDialogState = createSelector(getPlanningState, state => state.modifyDialog)

export const getPlnSettings = createSelector(getPlanningState, state => state.formsSettings)
