import { FormikValues } from 'formik'
import { useCallback, useState } from 'react'

import { StrategyLocalStorage } from '@/components/NPMPakage/components/tableComponents/UITable/strategy'
import { LocalStorageItems } from '@/constants'

const useSelectedFilters = (
  localStorageKey: LocalStorageItems,
  initialValues: FormikValues,
  changePage?: (page: number) => void,
) => {
  const [showFiltersModal, setShowFiltersModal] = useState(false)

  const [selectedFilters, setSelectedFilters] = useState<FormikValues>(() => {
    const selectedFiltersLocalStorage = StrategyLocalStorage.getData(localStorageKey) || ''

    return selectedFiltersLocalStorage || initialValues
  })

  const handleSetFiltersModal = useCallback((): void => setShowFiltersModal(open => !open), [
    setShowFiltersModal,
  ])

  const handleSelectedFilters = useCallback(
    (values: FormikValues) => {
      StrategyLocalStorage.setData(localStorageKey, values)
      changePage && changePage(1)
      setSelectedFilters(values)
    },
    [setSelectedFilters],
  )

  const handleDeleteFilter = (key: string): void => {
    const newFilters = { ...selectedFilters, [key]: initialValues[key] }
    setSelectedFilters(newFilters)
    StrategyLocalStorage.setData(localStorageKey, newFilters)
    changePage && changePage(1)
  }

  const handleClearFilters = (): void => {
    setSelectedFilters(initialValues)
    StrategyLocalStorage.setData(localStorageKey, initialValues)
    changePage && changePage(1)
  }

  return {
    selectedFilters,
    setSelectedFilters,
    handleSelectedFilters,
    showFiltersModal,
    handleSetFiltersModal,
    handleDeleteFilter,
    handleClearFilters,
  }
}

export { useSelectedFilters }
