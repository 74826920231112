import queryString from 'query-string'

import { AdmFuelType, AdmFuelTypeBase } from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

export class FuelAdminApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getFuelTypes(): Promise<AdmFuelType[]> {
    try {
      return await FuelAdminApi.requestService.get('/admin/fuelTypes')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getNewFuelType(): Promise<AdmFuelType> {
    try {
      return await FuelAdminApi.requestService.get('/admin/fuelTypes/new')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async addFuelType(payload: AdmFuelTypeBase): Promise<void> {
    try {
      return await FuelAdminApi.requestService.post('/admin/fuelTypes/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async updateFuelType(payload: AdmFuelType): Promise<void> {
    try {
      return await FuelAdminApi.requestService.put('/admin/fuelTypes/update', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async deleteFuelType(id: number): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/fuelTypes/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await FuelAdminApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
