import { AnyAction } from 'redux'

import {
  BalAssignCashierRequest,
  BalCashPoint,
  BalCassetteModifyRequest,
  BalCassetteRemoveRequest,
  BalGetBalancingCassettesResponse,
  BalGetBalancingInfoResponse,
  BalNotLoadedCassettesCashOrder,
  BalTotalsResponse,
  BalUnloadedCassetteEntry,
  BalUnloadedCassettesSession,
  ComCMReport,
} from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants/actions/cmActions'

export const getBalCashPointsRequest = (): AnyAction => ({
  type: CMActions.BalGetCashPointsRequest,
})

export const getBalCashPointsResponse = (payload: any): AnyAction => ({
  type: CMActions.BalGetCashPointsResponse,
  payload,
})

export const getBalCashPointsResponseFail = (error: string): AnyAction => ({
  type: CMActions.BalGetCashPointsResponseFail,
  payload: error,
})

export const setBalCashPointsPagingRequest = (payload: {
  page?: number
  pageSize?: number
}): AnyAction => ({
  type: CMActions.BalSetCashPointsPagingRequest,
  payload,
})

export const setBalCashPointsPagingResponse = (payload: {
  page?: number
  pageSize?: number
}): AnyAction => ({
  type: CMActions.BalSetCashPointsPagingResponse,
  payload,
})

export const resetBalCashPointsPage = (): AnyAction => ({
  type: CMActions.BalResetCashPointsPage,
})

export const setBalSelectedCashPoint = (cashPoint: BalCashPoint | null): AnyAction => ({
  type: CMActions.BalSetSelectedCashPoint,
  payload: cashPoint,
})

export const setBalFiltersModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.BalSetFiltersModalOpen,
  payload: isOpen,
})

export const setBalDocumentModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.BalSetDocumentModalOpen,
  payload: isOpen,
})

export const setBalSettingsModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.BalSetSettingsModalOpen,
  payload: isOpen,
})

export const setBalCashBalancingModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.BalSetCashBalancingModalOpen,
  payload: isOpen,
})

export const submitBalCashPointsFilter = (payload: any): AnyAction => ({
  type: CMActions.BalFilterSubmitRequest,
  payload,
})

export const setBalCashPointsFilter = (payload: any): AnyAction => ({
  type: CMActions.BalSetCashPointsFilter,
  payload,
})

export const toggleBalCashPoint = (cashPoint: BalCashPoint): AnyAction => ({
  type: CMActions.BalToggleCashPoint,
  payload: cashPoint,
})

export const checkBalAllCashPoints = (cashPoints: BalCashPoint[]): AnyAction => ({
  type: CMActions.BalCheckAllCashPoints,
  payload: cashPoints,
})

export const clearBalAllCashPoints = (): AnyAction => ({
  type: CMActions.BalClearAllCashPoints,
})

export const getBalReportsRequest = (): AnyAction => ({
  type: CMActions.BalGetReportsRequest,
})

export const getBalReportsResponse = (payload: any): AnyAction => ({
  type: CMActions.BalGetReportsResponse,
  payload,
})

export const getBalReportsResponseFail = (message?: string): AnyAction => ({
  type: CMActions.BalGetReportsResponseFail,
  payload: message,
})

export const getBalReportRequest = (payload?: ComCMReport): AnyAction => ({
  type: CMActions.BalGetReportRequest,
  payload,
})

export const getBalReportResponse = (payload: any): AnyAction => ({
  type: CMActions.BalGetReportResponse,
  payload,
})

export const getBalReportResponseFail = (message?: string): AnyAction => ({
  type: CMActions.BalGetReportResponseFail,
  payload: message,
})

export const getBalCashPointReportsRequest = (id: string): AnyAction => ({
  type: CMActions.BalGetCashPointReportsRequest,
  payload: { id },
})

export const getBalCashPointReportsResponse = (payload: ComCMReport[]): AnyAction => ({
  type: CMActions.BalGetCashPointReportsResponse,
  payload,
})

export const getBalCashPointReportsResponseFail = (message?: string): AnyAction => ({
  type: CMActions.BalGetCashPointReportsResponseFail,
  payload: message,
})

export const getBalCashPointReportRequest = (payload?: ComCMReport): AnyAction => ({
  type: CMActions.BalGetCashPointReportRequest,
  payload,
})

export const getBalCashPointReportResponse = (payload: any): AnyAction => ({
  type: CMActions.BalGetCashPointReportResponse,
  payload,
})

export const getBalCashPointReportResponseFail = (message?: string): AnyAction => ({
  type: CMActions.BalGetCashPointReportResponseFail,
  payload: message,
})

export const refreshBalancing = (): AnyAction => ({
  type: CMActions.BalRefreshCashPointsPanel,
})

export const sortBalTable = (payload: {
  sortColumn: string | undefined
  sortOrder: 'asc' | 'desc' | undefined
}): AnyAction => ({
  type: CMActions.BalSortTable,
  payload,
})

export const assignBalCashierRequest = (payload: BalAssignCashierRequest): AnyAction => ({
  type: CMActions.BalAssignCashierRequest,
  payload,
})

export const assignBalCashierResponse = (): AnyAction => ({
  type: CMActions.BalAssignCashierResponse,
})

export const handleBalKeyPress = (
  actionName:
    | 'TOGGLE_CHECKBOX'
    | 'NEXT_ROW'
    | 'PREV_ROW'
    | 'OPEN_FILTER'
    | 'OPEN_PRINT_DIALOG'
    | 'REFRESH'
    | 'SELECT_ROW',
): AnyAction => ({
  type: CMActions.BalHandleKeyPress,
  payload: actionName,
})

export const getBalInfoRequest = (id: string): AnyAction => ({
  type: CMActions.BalGetInfoRequest,
  payload: id,
})

export const getBalInfoResponse = (payload: BalGetBalancingInfoResponse): AnyAction => ({
  type: CMActions.BalGetInfoResponse,
  payload,
})

export const getBalInfoFail = (error: string): AnyAction => ({
  type: CMActions.BalGetInfoFail,
  payload: error,
})

export const getBalCassettesRequest = (id: string): AnyAction => ({
  type: CMActions.BalGetCassettesRequest,
  payload: id,
})

export const getBalCassettesResponse = (payload: BalGetBalancingCassettesResponse): AnyAction => ({
  type: CMActions.BalGetCassettesResponse,
  payload,
})

export const getBalCassettesFail = (error: string): AnyAction => ({
  type: CMActions.BalGetCassettesFail,
  payload: error,
})

export const setBalSelectedUnloadedCassettes = (
  unloadedCassettes: BalUnloadedCassettesSession,
): AnyAction => ({
  type: CMActions.BalSetSelectedUnloadedCassettes,
  payload: unloadedCassettes,
})

export const setBalSelectedNotLoadedCassettes = (
  notLoadedCassettes: BalNotLoadedCassettesCashOrder,
): AnyAction => ({
  type: CMActions.BalSetSelectedNotLoadedCassettes,
  payload: notLoadedCassettes,
})

export const setBalUnloadedSelectedCassette = (
  payload: BalUnloadedCassetteEntry | null,
): AnyAction => ({
  type: CMActions.BalSetUnloadedSelectedCassette,
  payload,
})

export const applyBalUnloadedCassettesRequest = (): AnyAction => ({
  type: CMActions.BalApplyBalUnloadedCassettesRequest,
})
export const applyBalUnloadedCassettesResponse = (): AnyAction => ({
  type: CMActions.BalApplyBalUnloadedCassettesResponse,
})
export const applyBalUnloadedCassettesFail = (error: string): AnyAction => ({
  type: CMActions.BalApplyBalUnloadedCassettesFail,
  payload: error,
})

export const applyBalNotLoadedCassettesRequest = (): AnyAction => ({
  type: CMActions.BalApplyBalNotLoadedCassettesRequest,
})
export const applyBalNotLoadedCassettesResponse = (): AnyAction => ({
  type: CMActions.BalApplyBalNotLoadedCassettesResponse,
})
export const applyBalNotLoadedCassettesFail = (error: string): AnyAction => ({
  type: CMActions.BalApplyBalNotLoadedCassettesFail,
  payload: error,
})

export const calcBalUnloadedTotalsResponse = (totals: BalTotalsResponse): AnyAction => ({
  type: CMActions.BalCalcUnloadedTotalsResponse,
  payload: totals,
})
export const calcBalUnloadedTotalsFail = (error: string): AnyAction => ({
  type: CMActions.BalCalcUnloadedTotalsFail,
  payload: error,
})

export const calcBalNotLoadedTotalsResponse = (totals: BalTotalsResponse): AnyAction => ({
  type: CMActions.BalCalcNotLoadedTotalsResponse,
  payload: totals,
})
export const calcBalNotLoadedTotalsFail = (error: string): AnyAction => ({
  type: CMActions.BalCalcNotLoadedTotalsFail,
  payload: error,
})

export const modifyBalCassettesRequest = (payload: BalCassetteModifyRequest): AnyAction => ({
  type: CMActions.BalModifyCassettesRequest,
  payload,
})
export const modifyBalCassettesResponse = (): AnyAction => ({
  type: CMActions.BalModifyCassettesResponse,
})
export const modifyBalCassettesFail = (error: string): AnyAction => ({
  type: CMActions.BalModifyCassettesFail,
  payload: error,
})

export const removeBalCassetteRequest = (payload: BalCassetteRemoveRequest): AnyAction => ({
  type: CMActions.BalRemoveCassetteRequest,
  payload,
})
export const removeBalCassetteResponse = (): AnyAction => ({
  type: CMActions.BalRemoveCassetteResponse,
})
export const removeBalCassetteFail = (error: string): AnyAction => ({
  type: CMActions.BalRemoveCassetteFail,
  payload: error,
})

export const setSortersType = (payload: {
  type: 'mix' | 'denom'
  columnKey: string
}): AnyAction => ({
  type: CMActions.BalSetSortersType,
  payload,
})
