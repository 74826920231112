import queryString from 'query-string'

import { AdmEventNotification, AdmEventNotificationBase } from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class EventNotificationsApi {
  private static requestService = getRequestServiceCM()

  public static async get(sort: Sort): Promise<AdmEventNotification[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/eventNotifications',
          query: {
            ...sort,
          },
        },
        { skipNull: true, encode: false },
      )
      return await EventNotificationsApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(payload: AdmEventNotification): Promise<void> {
    try {
      return await EventNotificationsApi.requestService.put(
        '/admin/eventNotifications/update',
        payload,
      )
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(payload: AdmEventNotificationBase): Promise<void> {
    try {
      return await EventNotificationsApi.requestService.post(
        '/admin/eventNotifications/add',
        payload,
      )
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(payload: number): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/eventNotifications/delete',
          query: {
            id: payload,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await EventNotificationsApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
