import { saveAs } from 'file-saver'
import moment from 'moment'
import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { CitBalancingApi } from '@/api/cm/citBalancingApi'
import { CMCommonApi } from '@/api/cm/commonApi'
import {
  BalGetBalancingCassettesResponse,
  BalUnloadedCassettesSession,
  CitBalCashPoint,
  CitBalCashPointsResponse,
  ComGlobalFilter,
  IcsMissedReplenishmentResponse,
  ParsedCitBalCashPointAccount,
  VaultRemainderUpdateAvailabilityEntry,
} from '@/api/cm/swaggerGeneratedApi'
import { balanceCalculate } from '@/components/blocks/cm/CashBalancingModal/components/UnloadedCassettes/components/CashOutTable/helper'
import { FormFieldValues } from '@/components/pages/cm/CitBalancingPage/components/modals/FiltersModal/types'
import { CMActions } from '@/constants'
import { DATE_TIME_FORMAT } from '@/constants/time'
import { getCMSharedState } from '@/store/cm/shared'
import { AppState } from '@/store/reducers'
import { integrations } from '@/types/cm/common'
import { hotKeysBinder } from '@/utils/hotKeysBinder'
import { translate } from '@/utils/internalization'

import { setBalUnloadedSelectedCassette } from '../balancing'
import {
  getBalSelectedCassette,
  getBalSelectedUnloadedCassettes,
  getComIntegration,
  getIcsMissedReplenishmentCartResponse,
  getIcsMissedReplenishmentEventsRequest,
  getIcsMissedReplenishmentEventsResponse,
  setComCMError,
  setGlobalFilter,
} from '../common'
import {
  modifyCitBalCassettesFail,
  modifyCitBalCassettesResponse,
  removeCitBalCassetteFail,
  removeCitBalCassetteResponse,
  setCitBalSelectedUnloadedCassettes,
} from '.'
import {
  applyCitBalEventFail,
  applyCitBalEventResponse,
  applyCitBalUnloadedCassettesResponse,
  assignCitBalCashierResponse,
  calcCitBalAddEventsTotalFail,
  calcCitBalAddEventsTotalRequest,
  calcCitBalAddEventsTotalResponse,
  calcCitBalUnloadedTotalsResponse,
  clearCitBalAllCashPoints,
  closeCitBalCashOrderFail,
  closeCitBalCashOrderResponse,
  createCitBalAddEventFail,
  createCitBalAddEventResponse,
  fetchCitBalAssetItemReportFail,
  fetchCitBalAssetItemReportResponse,
  getCiBalAddEventsResponse,
  getCitBalAccountsFail,
  getCitBalAccountsRequest,
  getCitBalAccountsResponse,
  getCitBalAddEventsFail,
  getCitBalCashierResponse,
  getCitBalCashPointsFail,
  getCitBalCashPointsRequest,
  getCitBalCashPointsResponse,
  getCitBalCassettesFail,
  getCitBalCassettesRequest,
  getCitBalCassettesResponse,
  getCitBalDialogReportFail,
  getCitBalDialogReportResponse,
  getCitBalDialogReportsFail,
  getCitBalDialogReportsResponse,
  getCitBalEventsFail,
  getCitBalInfoFail,
  getCitBalInfoRequest,
  getCitBalInfoResponse,
  getCitBalReportFail,
  getCitBalReportResponse,
  getCitBalReportsFail,
  getCitBalReportsResponse,
  getCitBlnTransactionButtonStatusFail,
  getCitBlnTransactionButtonStatusRequest,
  getCitBlnTransactionButtonStatusResponse,
  getVaultTransactionTotalsFail,
  getVaultTransactionTotalsResponse,
  refreshCitBalCashPoints,
  resetCitBalCashPointsPage,
  setCitBalCashBalancingModalOpen,
  setCitBalCashPointsFilter,
  setCitBalSelectedCashPoint,
  setCitBlnConfirmModalOpen,
  setCitBlnFiltersModalOpen,
  setCitBlnReportsModalOpen,
  setMixedOperation,
  toggleCitBalCashPoint,
  updateCitBalAccountsFail,
} from './actions'
import {
  getCitBlnCashPoints,
  getCitBlnModalOpenState,
  getCitBlnVaultTransactionTotals,
} from './selectors'

function* getCashPoints(): SagaIterator {
  try {
    const paging = yield select((state: AppState) => {
      const { page, pageSize } = state?.cmCitBalancing.cashPoints.paging
      return {
        page,
        pageSize: pageSize === -1 ? 'All' : pageSize,
      }
    })
    const { sortColumn, sortOrder, ...filters } = yield select((state: AppState) => {
      const {
        cmCitBalancing: {
          cashPoints: { sortColumn, sortOrder, filter },
        },
        cmCommon: { globalFilter },
      } = state

      return {
        sortColumn,
        sortOrder,
        filter,
        globalFilter,
      }
    })

    const { globalFilter, filter } = filters

    const response: CitBalCashPointsResponse = yield call(
      CitBalancingApi.getCashPoints,
      { globalFilter, filter },
      paging,
      sortColumn,
      sortOrder,
    )

    yield put(getCitBalCashPointsResponse(response))

    const { selectedCashPoint } = yield select(getCitBlnCashPoints)

    if (selectedCashPoint && response.data) {
      const updatedSelectedCashPoint = response.data.find(
        (cashPoint: CitBalCashPoint) => cashPoint.id === selectedCashPoint.id,
      )

      if (updatedSelectedCashPoint) {
        yield put(setCitBalSelectedCashPoint(updatedSelectedCashPoint))
      } else {
        yield put(setCitBalSelectedCashPoint(response.data.length ? response.data[0] : null))
      }
    }
  } catch (error) {
    yield put(getCitBalCashPointsFail(error))
  }
}

function* setCashPointsFilter({
  globalFilter,
  citBlnFilter,
}: {
  globalFilter: ComGlobalFilter
  citBlnFilter: FormFieldValues
}): any {
  try {
    yield put(setGlobalFilter(globalFilter))
    yield put(setCitBalCashPointsFilter(citBlnFilter))
  } catch (e) {}
}

function* handleCashPointsFilterSubmit({ payload }: AnyAction): SagaIterator {
  yield setCashPointsFilter(payload)
  yield put(resetCitBalCashPointsPage())
  yield put(clearCitBalAllCashPoints())
  yield put(getCitBalCashPointsRequest())
}

function* handleCashPointsPagingChange(): SagaIterator {
  yield put(getCitBalCashPointsRequest())
}

function* handleRefreshCashPoints(): SagaIterator {
  yield put(getCitBalCashPointsRequest())
}

function* getCitBalancingInfo({ payload }: AnyAction): any {
  try {
    const response = yield call(CitBalancingApi.getBalancingInfo, payload)

    yield put(getCitBalInfoResponse(response))
  } catch (error) {
    yield put(getCitBalInfoFail(error))
  }
}
function* getCitBalancingCassettes({ payload }: AnyAction): any {
  try {
    const unloadedCassettes = yield select(getBalSelectedUnloadedCassettes)
    const selectedCassette = yield select(getBalSelectedCassette)

    const response: BalGetBalancingCassettesResponse = yield call(
      CitBalancingApi.getBalancingCassettes,
      payload,
    )

    yield put(getCitBalCassettesResponse(response))

    if (unloadedCassettes.sessionId) {
      const cassette = response.unloadedCassettes?.cassetteData?.find(
        cassette => cassette.sessionId === unloadedCassettes.sessionId,
      )

      if (cassette) {
        const cassettesWithNewBalance = balanceCalculate({ unloadedCassette: cassette })

        yield put(setCitBalSelectedUnloadedCassettes(cassettesWithNewBalance))

        if (selectedCassette) {
          const foundedSelected = cassettesWithNewBalance.unloadedCassettes?.find(
            unloadedCassette => unloadedCassette.id === selectedCassette.id,
          )

          if (foundedSelected) {
            yield put(setBalUnloadedSelectedCassette(foundedSelected))
          }
        } else {
          yield put(setBalUnloadedSelectedCassette(null))
        }
      }
    }
  } catch (error) {
    yield put(getCitBalCassettesFail(error))
  }
}

function* applyCitBalUnloadedCassettes(): SagaIterator {
  try {
    const selectedUnloadedCassettes: BalUnloadedCassettesSession = yield select(
      getBalSelectedUnloadedCassettes,
    )

    const { selectedCashPoint } = yield select(getCitBlnCashPoints)

    const requestBody = {
      sessionId: selectedCashPoint.sessionId,
      selectedSessionId: selectedUnloadedCassettes.sessionId,
      balancedCassettes: selectedUnloadedCassettes.unloadedCassettes?.map(
        ({
          id,
          cashIn,
          deposit,
          dispensed,
          remaining,
          reject,
          retract,
          kioskCashInReal,
          kioskCashInSlip,
          kioskCashInSystem,
        }) => {
          return {
            cassetteId: id,
            cashIn,
            deposit,
            dispensed,
            remaining,
            reject,
            retract,
            kioskCashInReal,
            kioskCashInSlip,
            kioskCashInSystem,
          }
        },
      ),
    }

    yield call(CitBalancingApi.setUnloadedBalancing, requestBody)

    yield put(getCitBalInfoRequest(selectedCashPoint.id))
    yield put(getCitBalCassettesRequest(selectedCashPoint.id))
    yield put(applyCitBalUnloadedCassettesResponse())
    yield put(getCitBalCashPointsRequest())
  } catch (error) {
    yield put(setComCMError(error))
  }
}

function* calculateUnloadedTotals(): SagaIterator {
  try {
    const { selectedCashPoint }: { selectedCashPoint: CitBalCashPoint } = yield select(
      getCitBlnCashPoints,
    )
    const selectedUnloadedCassettes: BalUnloadedCassettesSession = yield select(
      getBalSelectedUnloadedCassettes,
    )
    const requestBody = {
      entryId: selectedCashPoint?.id,
      sessionId: selectedUnloadedCassettes.sessionId,
      balancedCassettes: selectedUnloadedCassettes.unloadedCassettes?.map(
        ({
          id,
          cashIn,
          deposit,
          dispensed,
          remaining,
          reject,
          retract,
          kioskCashInReal,
          kioskCashInSlip,
          kioskCashInSystem,
        }) => {
          return {
            cassetteId: id,
            cashIn,
            deposit,
            dispensed,
            remaining,
            reject,
            retract,
            kioskCashInReal,
            kioskCashInSlip,
            kioskCashInSystem,
          }
        },
      ),
    }

    const response = yield call(CitBalancingApi.calcUnloadedTotals, requestBody)

    if (response) {
      yield put(calcCitBalUnloadedTotalsResponse(response))
    }
  } catch (error) {
    yield put(setComCMError(error))
  }
}

function* getEvents(): any {
  try {
    yield put(getIcsMissedReplenishmentEventsRequest())

    const dateFrom = yield select((state: AppState) => state.cmCommon.missedReplenishment.dateFrom)
    const cashOrderId = yield select(
      (state: AppState) => state.cmCommon.missedReplenishment.selectedOrder.cpId,
    )

    const sort = yield select(state => ({
      sortColumn: state.cmCommon.missedReplenishment.events.sortColumn,
      sortOrder: state.cmCommon.missedReplenishment.events.sortOrder,
    }))
    const paging = yield select((state: AppState) => {
      const { page, pageSize } = state.cmCommon.missedReplenishment.events
      return { page, pageSize }
    })

    const response: IcsMissedReplenishmentResponse = yield call(CitBalancingApi.getEvents, {
      requestBody: { cashPointId: cashOrderId, dateFrom },
      paging,
      sort,
    })

    if (response.chart) {
      yield put(getIcsMissedReplenishmentCartResponse(response.chart))
    }

    yield put(
      getIcsMissedReplenishmentEventsResponse({
        cassettesNames: response.cassettesNames || [],
        totalEvents: response.totalEvents || 0,
        events: response.events || [],
        lastEventDate: response.lastEventDate || '',
      }),
    )
  } catch (error) {
    yield put(getCitBalEventsFail(error))
  }
}

function* applyEvent({ payload }: AnyAction): SagaIterator {
  try {
    const cashOrderId = yield select(
      (state: AppState) => state.cmCitBalancing.cashPoints.selectedCashPoint?.orderId,
    )

    yield call(CitBalancingApi.applyEvent, {
      event: payload,
      cashOrderId,
    })
    yield put(applyCitBalEventResponse())
    yield put(getCitBalCashPointsRequest())
  } catch (error) {
    yield put(applyCitBalEventFail())
  }
}

function* updateAddEvents(): any {
  try {
    yield put(calcCitBalAddEventsTotalRequest())

    const { data: icsCassettes, eventType } = yield select(
      (state: AppState) => state.cmCommon.missedReplenishment.cassettes,
    )

    const response = yield call(CitBalancingApi.updateAddEvents, {
      cassettes: icsCassettes,
      eventType,
    })

    yield put(calcCitBalAddEventsTotalResponse(response))
  } catch (error) {
    yield put(calcCitBalAddEventsTotalFail(error))
  }
}

function* getAddEvents({ payload }: AnyAction): SagaIterator {
  try {
    const response = yield call(CitBalancingApi.getAddEvents, payload)

    if (response) {
      yield put(getCiBalAddEventsResponse(response.cassettes))
      yield updateAddEvents()
    }
  } catch (error) {
    yield put(getCitBalAddEventsFail(error))
  }
}

function* createAddEvent({ payload }: AnyAction): SagaIterator {
  try {
    const cashOrderId = yield select(
      (state: AppState) => state.cmCommon.missedReplenishment.selectedOrder.id,
    )

    yield call(CitBalancingApi.createAddEvent, {
      cashOrderId,
      ...payload,
    })

    yield put(getIcsMissedReplenishmentEventsRequest())
    yield getEvents()
    yield put(createCitBalAddEventResponse())
  } catch (error) {
    yield put(createCitBalAddEventFail(error))
  }
}

function* closeCashOrder(): SagaIterator {
  try {
    const sessionId = yield select(
      (state: AppState) => state.cmCitBalancing.cashPoints.selectedCashPoint?.sessionId,
    )
    const orderStatus = yield select(
      (state: AppState) => state.cmCitBalancing.confirmClose.typeOfConfirm,
    )

    yield call(CitBalancingApi.closeCashOrder, {
      sessionId,
      orderStatus,
    })
    yield put(closeCitBalCashOrderResponse())
    yield put(setCitBlnConfirmModalOpen({ isOpen: false, typeOfConfirm: null }))
    yield put(getCitBalCashPointsRequest())
  } catch (error) {
    yield put(closeCitBalCashOrderFail())
    yield put(setCitBlnConfirmModalOpen({ isOpen: false, typeOfConfirm: null }))
  }
}

function* getAccounts(): SagaIterator {
  try {
    const id = yield select(
      (state: AppState) => state.cmCitBalancing.cashPoints.selectedCashPoint?.id,
    )

    const response = yield call(CitBalancingApi.getAccounts, id)

    yield put(getCitBalAccountsResponse(response))
  } catch (error) {
    yield put(getCitBalAccountsFail(error))
  }
}

function* updateAccounts(): SagaIterator {
  try {
    const { cpId } = yield select(
      (state: AppState) => state.cmCitBalancing.cashPoints.selectedCashPoint,
    )
    const accounts: ParsedCitBalCashPointAccount[] = yield select(
      (state: AppState) => state.cmCommon.accounts.data,
    )

    const parsedAccounts = accounts.map(({ id, isNew, ...account }) => account)

    yield call(CitBalancingApi.updateAccounts, { cpId, accounts: parsedAccounts })

    yield put(getCitBalAccountsRequest())
  } catch (error) {
    yield put(updateCitBalAccountsFail(error))
  }
}

function* getReports(): SagaIterator {
  try {
    const { checkedCashPoints, selectedCashPoint, filter } = yield select(getCitBlnCashPoints)

    const pointsFilter = {
      ids: Object.keys(checkedCashPoints) || [],
      selectedId: selectedCashPoint?.id || '',
      filter: {
        balDateFrom: filter.balDateFrom,
        balDateTo: filter.balDateTo,

        cycleDateFrom: filter.startCycleDateFrom,
        cycleDateTo: filter.endCycleDateTo,
        // TODO ADD LOGIC TO HANDLE ZIP FORMATE
        zipFiles: false,
      },
    }
    const response = yield call(CitBalancingApi.getReports, pointsFilter)

    yield put(getCitBalReportsResponse(response))
  } catch (error) {
    yield put(getCitBalReportsFail(error?.message))
  }
}

function* getReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(CMCommonApi.getReport, payload)
      yield put(getCitBalReportResponse(response))
    } else {
      yield put(getCitBalReportResponse(null))
    }
  } catch (error) {
    yield put(getCitBalReportFail(error?.message))
  }
}

function* getDialogReports(): SagaIterator {
  try {
    const selectedCashPoint: CitBalCashPoint = yield select(
      (state: AppState) => state.cmCitBalancing.cashPoints.selectedCashPoint,
    )

    const response = yield call(CitBalancingApi.getCashPointReports, {
      cashPointId: selectedCashPoint.cpId,
      sessionId: selectedCashPoint?.sessionId || '',
    })
    yield put(getCitBalDialogReportsResponse(response))
  } catch (e) {
    yield put(getCitBalDialogReportsFail(e?.message))
  }
}

function* getDialogReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(CMCommonApi.getReport, payload)
      yield put(getCitBalDialogReportResponse(response))
    } else {
      yield put(getCitBalDialogReportResponse(null))
    }
  } catch (e) {
    yield put(getCitBalDialogReportFail(e?.message))
  }
}

function* getAssetItemReport({ payload }: AnyAction): SagaIterator {
  try {
    const { selectedCashPoint, checkedCashPoints } = yield select(
      (state: AppState) => state.cmCitBalancing.cashPoints,
    )

    const requestBody = {
      selectionFilter: {
        checkedIds: Object.keys(checkedCashPoints) || [],
        selectedId: selectedCashPoint?.id || '',
      },
      isZip: payload,
    }

    const bytes = yield call(CitBalancingApi.getAssetItemReport, requestBody)

    if (bytes) {
      const url = URL.createObjectURL(bytes)

      if (url) {
        const date = moment().format(DATE_TIME_FORMAT)

        saveAs(url, `Asset item ${date}.${payload ? 'zip' : 'xls'}`)

        yield put(fetchCitBalAssetItemReportResponse())
      }
    }
  } catch (e) {
    yield put(fetchCitBalAssetItemReportFail())
  }
}

function* assignCashier({ payload }: AnyAction): SagaIterator {
  try {
    const { selectedCashPoint, checkedCashPoints } = yield select(getCitBlnCashPoints)

    const requestBody = {
      selectionFilter: {
        checkedIds: Object.keys(checkedCashPoints) || [],
        selectedId: selectedCashPoint?.id || '',
      },
      cashierId: payload,
    }

    yield call(CitBalancingApi.assignCashier, requestBody)

    yield put(assignCitBalCashierResponse())
    yield put(clearCitBalAllCashPoints())
  } catch (error) {
    yield put(setComCMError(error))
  }
}

function* getCashiers(): SagaIterator {
  try {
    const cashiers = yield call(CitBalancingApi.getCashiers)

    yield put(getCitBalCashierResponse(cashiers))
  } catch (error) {
    yield put(setComCMError(error))
  }
}

function* modifyCassette({ payload }: AnyAction): SagaIterator {
  try {
    const { selectedCashPoint } = yield select(getCitBlnCashPoints)

    if (payload?.sessionId && selectedCashPoint?.id) {
      yield call(CitBalancingApi.modifyCassette, payload)

      yield put(modifyCitBalCassettesResponse())
      yield put(getCitBalCassettesRequest(selectedCashPoint.id))
    }
  } catch (error) {
    yield put(modifyCitBalCassettesFail(error))
  }
}

function* removeCassette({ payload }: AnyAction): SagaIterator {
  try {
    const { selectedCashPoint } = yield select(getCitBlnCashPoints)

    if (payload?.sessionId && selectedCashPoint?.id) {
      yield call(CitBalancingApi.removeCassette, payload)

      yield put(removeCitBalCassetteResponse())
      yield put(getCitBalCassettesRequest(selectedCashPoint.id))
    }
  } catch (error) {
    yield put(removeCitBalCassetteFail(error))
  }
}

function* getTransactionButtonStatus({ payload }: AnyAction): SagaIterator {
  try {
    const {
      selectedCashPoint: { vaultId, vaultName },
      checkedCashPoints,
    } = yield select(getCitBlnCashPoints)

    const isV1Integration = yield select(getComIntegration(integrations.VaultVariant1))
    const isV2Integration = yield select(getComIntegration(integrations.VaultVariant2))
    const isVIntegration = isV1Integration || isV2Integration

    if (
      !Object.keys(checkedCashPoints).length &&
      payload?.coid &&
      isVIntegration &&
      vaultId &&
      vaultName
    ) {
      yield put(getCitBlnTransactionButtonStatusRequest())
      const requestBody = [payload.coid]

      const response = yield call(CMCommonApi.getTransactionButtonStatus, requestBody)

      yield put(getCitBlnTransactionButtonStatusResponse(response))
    }
  } catch (error) {
    yield put(getCitBlnTransactionButtonStatusFail(error))
  }
}

function* checkMixedOperation(): SagaIterator {
  try {
    const { selectedCashPoint, checkedCashPoints } = yield select(getCitBlnCashPoints)

    const checkedIds = Object.keys(checkedCashPoints)
    let operationName: string | undefined

    const isMixedOperation =
      !!checkedIds.length &&
      !!checkedIds.some((key, index, array) => {
        const { vaultId, vaultName } = checkedCashPoints[key] || {}
        const { vaultName: firstVaultName, vaultId: firstVaultId } =
          checkedCashPoints[array[0]] || {}

        if (firstVaultId === vaultId && vaultName === firstVaultName) {
          operationName = firstVaultName

          return false
        }
        return true
      })

    if (!checkedIds.length) {
      operationName = selectedCashPoint.vaultName
    }

    if (!operationName) {
      operationName = translate('translate#cm.Undefined') as string
    }

    yield put(setMixedOperation({ isMixedOperation, operationName }))
  } catch (error) {}
}

function* getVaultTransactionTotals(): SagaIterator {
  try {
    const { selectedCashPoint, checkedCashPoints } = yield select(getCitBlnCashPoints)
    const checkedIds = Object.keys(checkedCashPoints)
    const ids: string[] = []

    if (!checkedIds.length) {
      ids.push(selectedCashPoint.id)
    } else {
      const requestBody = [] as string[]

      checkedIds.forEach(key => {
        const { coid } = checkedCashPoints[key] || {}
        if (coid) {
          requestBody.push(coid)
        }
      })

      if (requestBody.length) {
        const response: VaultRemainderUpdateAvailabilityEntry[] = yield call(
          CMCommonApi.getTransactionButtonStatus,
          requestBody,
        )

        checkedIds.forEach(key => {
          const { coid, id } = checkedCashPoints[key] || {}
          const { isDebitOperationAvailable } = response.find(item => item.coid === coid) || {}

          if (isDebitOperationAvailable && id) {
            ids.push(id)
          }
        })
      }
    }

    if (ids.length) {
      const response = yield call(CitBalancingApi.getVaultTransactionTotals, { ids })

      yield put(
        getVaultTransactionTotalsResponse({
          data: response,
          ids,
        }),
      )
    } else {
      yield put(getVaultTransactionTotalsResponse({ data: {}, ids }))
    }
  } catch (error) {
    yield put(getVaultTransactionTotalsFail(error))
  }
}

function* setVaultTransaction(): SagaIterator {
  try {
    const { ids } = yield select(getCitBlnVaultTransactionTotals)

    if (ids.length) {
      yield call(CitBalancingApi.setVaultTransaction, { ids })

      yield put(getCitBalCashPointsRequest())
    }
  } catch (error) {
    yield put(setComCMError(error))
  }
}

function* revertTransaction(): SagaIterator {
  try {
    const { selectedCashPoint } = yield select(getCitBlnCashPoints)

    yield call(CitBalancingApi.setRevertTransaction, selectedCashPoint.coid)

    yield put(getCitBalCashPointsRequest())
  } catch (error) {
    yield put(setComCMError(error))
  }
}

function* handleKeyPress({ payload }: AnyAction): SagaIterator {
  try {
    const {
      isFiltersModalOpen,
      isColumnModalOpen,
      isCashBalancingModalOpen,
      isEventsViewerModalOpen,
      isConfirmCloseModalOpen,
      isAccountsModalOpen,
      isReportsModalOpen,
    } = yield select(getCitBlnModalOpenState)
    const { selectedCashPoint: selected, data } = yield select(getCitBlnCashPoints)
    const {
      isEditCassettesModalOpen,
      declineNoteState: { isModalOpen: isDeclineNoteModalOpen },
    } = yield select(getCMSharedState)

    hotKeysBinder<CitBalCashPoint>({
      key: payload,
      data,
      selected,
      isOpenModalsList: [
        isFiltersModalOpen,
        isColumnModalOpen,
        isCashBalancingModalOpen,
        isEventsViewerModalOpen,
        isConfirmCloseModalOpen,
        isAccountsModalOpen,
        isReportsModalOpen,
        isEditCassettesModalOpen,
        isDeclineNoteModalOpen,
      ],
      setSelectedAction: setCitBalSelectedCashPoint,
      onToggleAction: toggleCitBalCashPoint,
      openFilterAction: setCitBlnFiltersModalOpen,
      openPrintAction: setCitBlnReportsModalOpen,
      onRefreshAction: refreshCitBalCashPoints,
      onEnterAction: setCitBalCashBalancingModalOpen,
    })
  } catch (error) {
    yield put(setComCMError(error))
  }
}

export default function*(): Generator {
  yield takeLatest([CMActions.CitBalGetCashPointsRequest, CMActions.CitBalSortTable], getCashPoints)
  yield takeLatest(CMActions.CitBalFilterSubmitRequest, handleCashPointsFilterSubmit)
  yield takeLatest(CMActions.CitBalSetCashPointsPaging, handleCashPointsPagingChange)
  yield takeLatest(
    [CMActions.CitBalRefreshCashPoints, CMActions.CitBalAssignCashierResponse],
    handleRefreshCashPoints,
  )

  yield takeLatest(CMActions.CitBalGetInfoRequest, getCitBalancingInfo)
  yield takeLatest(CMActions.CitBalGetCassettesRequest, getCitBalancingCassettes)
  yield takeLatest(CMActions.CitBalApplyBalUnloadedCassettesRequest, applyCitBalUnloadedCassettes)
  yield takeLatest(CMActions.CitBalSetSelectedUnloadedCassettes, calculateUnloadedTotals)

  yield takeLatest(CMActions.CitBalGetEventsRequest, getEvents)
  yield takeLatest(CMActions.CitBalApplyEventRequest, applyEvent)
  yield takeLatest(CMActions.CitBalGetAddEventsRequest, getAddEvents)
  yield takeLatest(CMActions.CitBalUpdateAddEvents, updateAddEvents)
  yield takeLatest(CMActions.CitBalCreateAddEventRequest, createAddEvent)

  yield takeLatest(CMActions.CitBalCloseCashOrderRequest, closeCashOrder)

  yield takeLatest(CMActions.CitBalGetAccountsRequest, getAccounts)
  yield takeLatest(CMActions.CitBalUpdateAccountsRequest, updateAccounts)

  yield takeLatest(CMActions.CitBalGetReportsRequest, getReports)
  yield takeLatest(CMActions.CitBalGetReportRequest, getReport)

  yield takeLatest(CMActions.CitBalGetDialogReportsRequest, getDialogReports)
  yield takeLatest(CMActions.CitBalGetDialogReportRequest, getDialogReport)

  yield takeLatest(CMActions.CitBalFetchAssetItemReportRequest, getAssetItemReport)
  yield takeLatest(CMActions.CitBalAssignCashierRequest, assignCashier)
  yield takeLatest(CMActions.CitBalGetCashierRequest, getCashiers)

  yield takeLatest(CMActions.CitBalModifyCassettesRequest, modifyCassette)
  yield takeLatest(CMActions.CitBalRemoveCassetteRequest, removeCassette)

  yield takeEvery(CMActions.CitBalSetSelectedCashPoint, getTransactionButtonStatus)
  yield takeEvery(
    [
      CMActions.CitBalCheckAllCashPoints,
      CMActions.CitBalToggleCashPoint,
      CMActions.CitBalSetSelectedCashPoint,
    ],
    checkMixedOperation,
  )
  yield takeLatest(CMActions.CitBalGetVaultTransactionTotalsRequest, getVaultTransactionTotals)
  yield takeLatest(CMActions.CitBalSetVaultTransactionRequest, setVaultTransaction)
  yield takeLatest(CMActions.CitBalRevertTransactionRequest, revertTransaction)

  yield takeLatest(CMActions.CitBalHandleKeyPress, handleKeyPress)
}
