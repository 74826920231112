export enum ProductsActions {
  // Config
  GetConfigRequest = '@/PRODUCTS_GET_CONFIG_REQUEST',
  GetConfigResponse = '@/PRODUCTS_GET_CONFIG_RESPONSE',
  GetConfigResponseFail = '@/PRODUCTS_GET_CONFIG_RESPONSE_FAIL',

  // Products
  GetProductsRequest = '@/PRODUCTS_GET_PRODUCTS_REQUEST',
  GetProductsResponse = '@/PRODUCTS_GET_PRODUCTS_RESPONSE',
  GetProductsResponseFail = '@/PRODUCTS_GET_PRODUCTS_RESPONSE_FAIL',

  // Grouped products
  GetGroupedProductsRequest = '@/PRODUCTS_GET_GROUPED_PRODUCTS_REQUEST',
  GetGroupedProductsResponse = '@/PRODUCTS_GET_GROUPED_PRODUCTS_RESPONSE',
  GetGroupedProductsResponseFail = '@/PRODUCTS_GET_GROUPED_PRODUCTS_RESPONSE_FAIL',

  GetProductByIdRequest = '@/PRODUCTS_GET_PRODUCT_BY_ID_REQUEST',
  GetProductByIdResponse = '@/PRODUCTS_GET_PRODUCT_BY_ID_RESPONSE',
  GetProductByIdResponseFail = '@/PRODUCTS_GET_PRODUCT_BY_ID_RESPONSE_FAIL',

  AddNewProductRequest = '@/PRODUCTS_ADD_NEW_PRODUCT_REQUEST',
  AddNewProductResponse = '@/PRODUCTS_ADD_NEW_PRODUCT_RESPONSE',
  AddNewProductResponseFail = '@/PRODUCTS_ADD_NEW_PRODUCT_RESPONSE_FAIL',

  EditProductRequest = '@/PRODUCTS_EDIT_PRODUCT_REQUEST',
  EditProductResponse = '@/PRODUCTS_EDIT_PRODUCT_RESPONSE',
  EditProductResponseFail = '@/PRODUCTS_EDIT_PRODUCT_RESPONSE_FAIL',

  DeleteProductRequest = '@/PRODUCTS_DELETE_PRODUCT_REQUEST',
  DeleteProductResponse = '@/PRODUCTS_DELETE_PRODUCT_RESPONSE',
  DeleteProductResponseFail = '@/PRODUCTS_DELETE_PRODUCT_RESPONSE_FAIL',

  GetAlternativeProductsRequest = '@/PRODUCTS_GET_ALTERNATIVE_PRODUCTS_REQUEST',
  GetAlternativeProductsResponse = '@/PRODUCTS_GET_ALTERNATIVE_PRODUCTS_RESPONSE',
  GetAlternativeProductsResponseFail = '@/PRODUCTS_GET_ALTERNATIVE_PRODUCTS_RESPONSE_FAIL',

  GetAllowedToAddAlternativeProductsRequest = '@/PRODUCTS_GET_ALLOWEDTOADD_ALTERNATIVE_PRODUCTS_REQUEST',
  GetAllowedToAddAlternativeProductsResponse = '@/PRODUCTS_GET_ALLOWEDTOADD_ALTERNATIVE_PRODUCTS_RESPONSE',
  GetAllowedToAddAlternativeProductsResponseFail = '@/PRODUCTS_GET_ALLOWEDTOADD_ALTERNATIVE_PRODUCTS_RESPONSE_FAIL',

  SaveAlternativeProductsRequest = '@/PRODUCTS_SAVE_ALTERNATIVE_PRODUCTS_REQUEST',
  SaveAlternativeProductsResponse = '@/PRODUCTS_SAVE_ALTERNATIVE_PRODUCTS_RESPONSE',
  SaveAlternativeProductsResponseFail = '@/PRODUCTS_SAVE_ALTERNATIVE_PRODUCTS_RESPONSE_FAIL',

  GetProductSpecificationRequest = '@/PRODUCTS_GET_PRODUCT_SPECIFICATION_REQUEST',
  GetProductSpecificationResponse = '@/PRODUCTS_GET_PRODUCT_SPECIFICATION_RESPONSE',
  GetProductSpecificationResponseFail = '@/PRODUCTS_GET_PRODUCT_SPECIFICATION_RESPONSE_FAIL',

  GetAllowedToAddProductSpecificationRequest = '@/PRODUCTS_GET_ALLOWEDTOADD_PRODUCT_SPECIFICATION_REQUEST',
  GetAllowedToAddProductSpecificationResponse = '@/PRODUCTS_GET_ALLOWEDTOADD_PRODUCT_SPECIFICATION_RESPONSE',
  GetAllowedToAddProductSpecificationResponseFail = '@/PRODUCTS_GET_ALLOWEDTOADD_PRODUCT_SPECIFICATION_RESPONSE_FAIL',

  SaveProductSpecificationRequest = '@/PRODUCTS_SAVE_PRODUCT_SPECIFICATION_REQUEST',
  SaveProductSpecificationResponse = '@/PRODUCTS_SAVE_PRODUCT_SPECIFICATION_RESPONSE',
  SaveProductSpecificationResponseFail = '@/PRODUCTS_SAVE_PRODUCT_SPECIFICATION_RESPONSE_FAIL',

  GetProductAttachedFilesRequest = '@/PRODUCTS_GET_PRODUCT_ATTACHEDFILES_REQUEST',
  GetProductAttachedFilesResponse = '@/PRODUCTS_GET_PRODUCT_ATTACHEDFILES_RESPONSE',
  GetProductAttachedFilesResponseFail = '@/PRODUCTS_GET_PRODUCT_ATTACHEDFILES_RESPONSE_FAIL',

  AddProductAttachedFileRequest = '@/PRODUCTS_ADD_ATTACHED_FILE_REQUEST',
  AddProductAttachedFileResponse = '@/PRODUCTS_ADD_ATTACHED_FILE_RESPONSE',

  RemoveProductAttachedFileRequest = '@/PRODUCTS_REMOVE_ATTACHED_FILE_REQUEST',
  RemoveProductAttachedFileResponse = '@/PRODUCTS_REMOVE_ATTACHED_FILE_RESPONSE',

  SetClearErrors = '@/PRODUCTS_SET_CLEAR_ERRORS',

  // Import Products -> Column mapping filter actions start
  ImportRequest = '@/PRODUCTS_IMPORT_PRODUCTS_REQUEST',
  ImportResponse = '@/PRODUCTS_IMPORT_PRODUCTS_RESPONSE',
  ImportResponseFail = '@/PRODUCTS_IMPORT_PRODUCTS_RESPONSE_FAIL',

  SetCurrentFilterTemplate = '@/PRODUCTS_IMPORT_SET_CURRENT_FITLER_TEMPLATE',
  SetFilterTemplateResponse = '@/PRODUCTS_IMPORT_SET_FILTER_TEMPLATE_RESPONSE',

  CreateFilterTemplateRequest = '@/PRODUCTS_IMPORT_CREATE_FILTER_TEMPLATE_REQUEST',
  CreateFilterTemplateResponse = '@/PRODUCTS_IMPORT_CREATE_FILTER_TEMPLATE_RESPONSE',

  UpdateFilterTemplateRequest = '@/PRODUCTS_IMPORT_UPDATE_FILTER_TEMPLATE_REQUEST',
  UpdateFilterTemplateResponse = '@/PRODUCTS_IMPORT_UPDATE_FILTER_TEMPLATE_RESPONSE',

  DeleteFilterTemplateRequest = '@/PRODUCTS_IMPORT_DELETE_FILTER_TEMPLATE_REQUEST',
  DeleteFilterTemplateResponse = '@/PRODUCTS_IMPORT_DELETE_FILTER_TEMPLATE_RESPONSE',

  GetFiltersTemplatesRequest = '@/PRODUCTS_IMPORT_GET_FILTERS_TEMPLATES_REQUEST',
  GetFiltersTemplatesResponse = '@/PRODUCTS_IMPORT_GET_FILTERS_TEMPLATES_RESPONSE',
  GetFiltersTemplatesResponseFail = '@/PRODUCTS_IMPORT_GET_FILTERS_TEMPLATES_RESPONSE_FAIL',
  // Import Products -> Column mapping filter actions end

  // Import Add to Security Networks Products -> Column mapping filter actions start
  AddToSecurityNetworksRequest = '@/PRODUCTS_IMPORT_PRODUCTS_ADD_TO_SECURITY_NETWORKS_REQUEST',
  AddToSecurityNetworksResponse = '@/PRODUCTS_IMPORT_PRODUCTS_ADD_TO_SECURITY_NETWORKS_RESPONSE',
  AddToSecurityNetworksResponseFail = '@/PRODUCTS_IMPORT_PRODUCTS_ADD_TO_SECURITY_NETWORKS_RESPONSE_FAIL',
}
