import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ palette, spacing }) => ({
  label: {
    color: palette.text.hint,
    textTransform: 'capitalize',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: spacing(1),
    padding: spacing(1, 0.5),
  },
  radios: {
    flexBasis: '50%',
    justifyContent: 'space-between',
  },
  radio: {
    color: palette.grey[100],
  },
}))
