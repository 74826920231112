import { createSelector } from 'reselect'
import { AppState } from '@/store/reducers'
import * as fromLogActions from './reducer'
import { initialState } from './reducer'

export const getLogState = (state: AppState): fromLogActions.State =>
  state.logActions || initialState

export const getisLogSet = createSelector(getLogState, state => state.isLogSet)

export const getRecordingStatus = createSelector(getLogState, state => state.recordingStatus)
