import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  wrapper: {
    padding: spacing(4),
  },
  closeButton: {
    top: spacing(2),
    right: spacing(2),
  },
  yesButton: {
    minWidth: spacing(15),
  },
  buttonWrapper: {
    justifyContent: 'center',
  },
  noButton: {
    minWidth: spacing(15),
    color: palette.error.main,
    backgroundColor: palette.error.light,
    '&:hover': {
      backgroundColor: palette.error.light,
    },
  },
  loaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    background: 'rgba(255, 255, 255, 0.7)',
  },
  title: {
    paddingLeft: 0,
    paddingTop: 0,
  },
  errorMessage: {
    color: '#cb362d',
  },
}))
