// core
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { Field, Form, Formik } from 'formik'
import React, { FC, ReactElement, useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { ChangePasswordFormValuesCM } from '@/components/blocks/cm/ChangePasswordModal/types'
import { createTextInputField } from '@/components/controls/TextInput/component'
// components
import { ButtonActionSignIn } from '@/components/pages/common/SignInPage/hooks/useSignInAction'
import { SignInPageWrapper } from '@/components/wrappers/SignInPage/component'
import { changePasswordRequest, getAvailableAccountsOptions } from '@/store/auth'
// hooks
import { useTranslate } from '@/utils/internalization'

// styles
import { useClasses } from './styles'
// types
import { ChangeFormFields, ChangeFormValues, Props } from './types'
// helpers
import { validate } from './validate'

const ChangePassword: FC<Props> = (): ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const availableAccounts = useSelector(getAvailableAccountsOptions)
  // test
  const [success, setSuccess] = useState(false)

  const OldPassword = createTextInputField({
    fullWidth: true,
    type: 'password',
    // label: translate('translate#login.form.title.password'),
    label: 'Old password',
  })

  const NewPassword = createTextInputField({
    fullWidth: true,
    type: 'password',
    // label: translate('translate#login.form.title.password'),
    label: 'New password',
  })

  const RepeatPassword = createTextInputField({
    fullWidth: true,
    type: 'password',
    // label: translate('translate#login.form.title.password'),
    label: 'Repeat password',
  })
  const handleSubmit = useCallback(
    (values: ChangeFormValues): void => {
      const data: ChangePasswordFormValuesCM = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
        repeatNewPassword: values.repeatPassword,
      }

      if (availableAccounts && availableAccounts.length >= 1) {
        data.login = availableAccounts[0].login
      }

      dispatch(changePasswordRequest(data))
      // setSuccess(false)
    },
    [dispatch],
  )

  return (
    <SignInPageWrapper>
      <Typography variant="h6">Change password</Typography>
      {!success ? (
        <Formik
          autoComplete="off"
          onSubmit={handleSubmit}
          validate={validate}
          initialValues={{
            [ChangeFormFields.OldPassword]: '',
            [ChangeFormFields.NewPassword]: '',
            [ChangeFormFields.RepeatPassword]: '',
          }}
        >
          {({ isValid, dirty }): React.ReactElement => {
            return (
              <Form>
                <Box>
                  <Field name={ChangeFormFields.OldPassword}>{OldPassword}</Field>
                </Box>
                <Box>
                  <Field name={ChangeFormFields.NewPassword}>{NewPassword}</Field>
                </Box>

                <Box>
                  <Field name={ChangeFormFields.RepeatPassword}>{RepeatPassword}</Field>
                </Box>
                <ButtonActionSignIn
                  translate={{
                    idSubmit: 'action.change.password',
                    messageSubmit: 'change password',
                  }}
                  isDisabled={!(isValid && dirty)}
                />
              </Form>
            )
          }}
        </Formik>
      ) : (
        <>
          <Typography className={classes.successfulMessage}>
            Password has been successfully changed
          </Typography>
          <ButtonActionSignIn translate={{ idSubmit: 'action.ok', messageSubmit: 'ok' }} />
        </>
      )}
    </SignInPageWrapper>
  )
}
export default ChangePassword
