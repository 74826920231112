import { Box, Button, Tab, Tabs, Typography } from '@material-ui/core'
import React, { useCallback, useMemo, useState } from 'react'
import { withResizeDetector } from 'react-resize-detector'
import SplitPane from 'react-split-pane'

import { actionLogApi } from '@/api/atmeye/AtionLogApi/actionLogApi'
import { Device, Filter } from '@/api/atmeye/devicesApi/types'
import { useTabModalWrapper } from '@/components/NPMPakage/components/modals/TabModalPackage/hooks/useTabModalWrapper'
import { TabModalWrapper } from '@/components/NPMPakage/components/modals/TabModalPackage/TabModal'
import { DeviceTableCell } from '@/components/NPMPakage/components/tableComponents/rows/DeviceTableCell/DeviceTableCell'
import { TableHeader } from '@/components/NPMPakage/components/tableComponents/TableHeader'
import { defaultSortHiddenIcons } from '@/components/NPMPakage/components/tableComponents/UITable/types'
import { UITable } from '@/components/NPMPakage/components/tableComponents/UITable/UITable'
import { Restricted } from '@/components/NPMPakage/components/wrappers/PermissionProvider/Restricted'
import { usePermission } from '@/components/NPMPakage/components/wrappers/PermissionProvider/usePermission'
import { Permission } from '@/components/NPMPakage/components/wrappers/types'
import { LocalStorageItems } from '@/constants'
import { useTranslate } from '@/utils/internalization'

import ActiveFilters from '../../../NPMPakage/components/ActiveFilters/component'
import { FiltersModalTransactions } from '../../../NPMPakage/components/modals/FiltersModal/FiltersModal'
import { GroupingModal } from '../../../NPMPakage/components/modals/GroupingModal'
import { TableGroup } from '../../../NPMPakage/components/modals/GroupingModal/components/SortBlock/types'
import { ObjectsTree } from '../../../NPMPakage/components/ObjectsTree'
import { useSelectedGrouping } from '../../../NPMPakage/hooks/useSelectedGrouping'
import ActionsTab from './Components/CurrentDevice/Tabs/ActionsTab'
import { CurrentDeviceTabsPanel } from './Components/CurrentDevice/Tabs/TabsPanel'
import { GoogleMapWrapper } from './Components/GoogleMapWrapper/GoogleMapWrapper'
import { StyledTableWrapper, WithoutDeviceWrapper } from './Components/MapPageWrapper/style'
import { TABS } from './Components/MapPageWrapper/types'
import { useDevices } from './hooks/useDevices'
import { PageWrapper, StyledPane, StyledSplitPane, useClasses } from './styles'

const STATE_NAMES = ['connectivityStatus', 'coreStatus', 'licenseStatus']
export const DEVICES_GROUPS = [
  'connectivityStatus',
  'coreStatus',
  'licenseStatus',
  'country',
  'city',
  'vendorCompany',
  'deviceModel',
  'deviceType',
]

export const defaultGrouping: TableGroup[] = [
  {
    groupingField: 'connectivityStatus',
    groupingSortType: 'BY_NAME',
    groupingSortDirection: 'ASC',
  },
]

const logOpenDetailsModal = (deviceName: string): Promise<void> => {
  return actionLogApi.logSuccessOperaion({
    objectName: 'AE_DEVICE_NAVIGATION',
    operation: 'AE_DEVICES_OPEN_DETAILS',
    parameters: [deviceName],
  })
}

export const defaultFilters: Filter = {
  deviceId: [],
  deviceName: '',
  connectivityStatusId: [],
  coreStatusId: [],

  licenseStatusId: [],
  countryId: [],
  cityId: [],
  vendorCompanyId: [],
  deviceTypeId: [],
  deviceModelId: [],
  serialNumber: '',
  address: '',
  lastActivityDateTime: [null, null],
}

const DevicesPage = ({ height, width }: { height: number; width: number }): React.ReactElement => {
  const {
    showGroupingModal,
    selectedGrouping,
    selectedGroupingTemplate,

    handleSetSelectedGrouping,
    handleClearGrouping,
    handleSetSelectedGroupingTemplate,

    handleSetShowGroupingModal,

    selectedGroupingNodes,
    setSelectedGroupingNodes,
  } = useSelectedGrouping({
    localStorageKey: LocalStorageItems.DevicesGrouping,
    initialValues: [
      {
        groupingField: 'connectivityStatus',
        groupingSortType: 'BY_NAME',
        groupingSortDirection: 'ASC',
      },
    ],
  })

  const {
    isLoading,
    isGroupsLoading,
    hasGrouping,

    sorting: { changeSorting, sorting },

    tableData,
    groupedData,
    pagination,
    refreshData,
    setTableData,

    fields,
    filters: {
      selectedFilters,
      handleSubmit,
      handleSetFiltersModal,
      showFiltersModal,
      handleClearFilters,
      handleDeleteFilter,
      selectedFiltersTemplate,
      handleSetSelectedFilterTemplate,
    },
    headerColumns: {
      setColumns,
      columnsMenuAnchorEl,
      columns,
      setColumnsMenuAnchorEl,
      handleChangeColumns,
      allFields,
      currentFields,
    },
    exportTableData,
    filterView,
  } = useDevices({
    selectedGrouping,
    selectedGroupingNodes,
    setSelectedGroupingNodes,
  })

  const classes = useClasses()
  const translate = useTranslate()

  const [tab, setTab] = useState<TABS>(TABS.DEVICES)
  const handleTabChange = useCallback((_, newTab: TABS): void => {
    setTab(newTab)
  }, [])

  const [currentDevice, setCurrentDevice] = useState<null | Device>(null)

  const tabComponentJSX = useMemo(
    () => (
      <Restricted permissionKey={Permission.Devices_Open_Details}>
        {currentDevice ? (
          <CurrentDeviceTabsPanel currentDevice={currentDevice} />
        ) : (
          <WithoutDeviceWrapper>
            {translate('translate#atmeye.devices.noSelectedDevice')}
          </WithoutDeviceWrapper>
        )}
      </Restricted>
    ),
    [currentDevice],
  )

  const tabsHeaderJSX = useMemo(
    () => (
      <div className={classes.tabBlock}>
        <Tabs
          value={tab}
          className={classes.tabsWrap}
          onChange={handleTabChange}
          TabIndicatorProps={{ className: classes.indicator }}
        >
          <Tab
            classes={{ selected: classes.selectedTab, root: classes.tab }}
            label={translate('translate#atmeye.title.devices')}
          />
          <Tab
            classes={{ selected: classes.selectedTab, root: classes.tab }}
            label={translate('translate#atmeye.title.map')}
          />
        </Tabs>
      </div>
    ),
    [handleTabChange, tab, translate],
  )

  const { allowed, loading } = usePermission(Permission.Devices_Open_Details)

  const {
    isFullScreen,
    isModalDetailsOpen,

    handleFullScreen,
    setIsModalDetailsOpen,
  } = useTabModalWrapper(loading ? false : !allowed)

  const footerControls = useMemo(
    () =>
      currentDevice && (tab === TABS.DEVICES || (!isFullScreen && tab === TABS.MAP)) ? (
        <div className={classes.actionsTabWrap}>
          <Restricted permissionKey={Permission.Devices_Open_Details}>
            {isFullScreen && (
              <Button
                variant="outlined"
                className={classes.button}
                onClick={(): void => {
                  logOpenDetailsModal(currentDevice.deviceName)
                  setIsModalDetailsOpen(true)
                }}
              >
                {translate('translate#title.details')}
              </Button>
            )}
          </Restricted>

          <ActionsTab currentDevice={currentDevice} data={tableData || []} />
        </div>
      ) : (
        <div style={{ marginRight: 'auto' }} />
      ),
    [currentDevice, tab, isFullScreen, classes.button, translate, tableData, setIsModalDetailsOpen],
  )

  const handleSetCurrentDeviceId = useCallback(
    (device: Device | any): (() => void) => (): void => {
      // if (isMapData(device)) {
      // setCurrentDevice(device)
      // dispatch(actionsATMEyeMap.setCurrentDevice(device))
      // } else {
      if (currentDevice?.deviceId !== +device.deviceId) {
        setCurrentDevice(device)

        // dispatch(actionsATMEyeMap.setCurrentDevice(currentDevice))
        // }
      }
    },
    [currentDevice],
  )

  const [isDataRefreshed, setIsDataRefreshed] = useState(false)
  const handleSetCurrentDevice = (device: Device) => () => {
    handleSetCurrentDeviceId(device)()
    if (isFullScreen) {
      logOpenDetailsModal(device.deviceName)
      setIsModalDetailsOpen(true)
    }
  }

  const handleDoubleClickRow = (row: Device) => {
    handleSetCurrentDevice(row)()
  }

  return (
    <div className={classes.root}>
      <PageWrapper>
        <StyledSplitPane
          minSize={width / 8}
          maxSize={width / 1.5}
          split="vertical"
          defaultSize="15%"
          paneStyle={!hasGrouping ? { width: '100%' } : {}}
        >
          {hasGrouping && (
            <StyledPane>
              <div className={classes.treeWrapper}>
                {groupedData?.length ? (
                  <div style={{ width: '200px', height: '500px' }}>
                    <ObjectsTree
                      tree={groupedData || []}
                      isFetching={isGroupsLoading}
                      onChange={setSelectedGroupingNodes}
                      selectedGroupingNodes={selectedGroupingNodes}
                      title={translate('translate#atmeye.title.devices')}
                    />
                  </div>
                ) : (
                  <Box className={classes.noTreeData}>
                    <Typography>{translate('translate#atmeye.title.emptyGroupsData')}</Typography>
                  </Box>
                )}
              </div>
            </StyledPane>
          )}
          <SplitPane
            split="horizontal"
            minSize={height / 3.5}
            maxSize={height / 1.5}
            defaultSize={isFullScreen ? '100%' : '50%'}
            paneStyle={isFullScreen ? { height: '100%' } : {}}
          >
            <StyledPane>
              <StyledTableWrapper>
                <TableHeader
                  isFullScreen={isFullScreen}
                  handleFullScreen={handleFullScreen}
                  columnsData={{
                    columnsMenuAnchorEl,
                    columns,
                    currentFields,
                    allFields,
                    onChangeColumns: handleChangeColumns,
                    setColumnsMenuAnchorEl,
                  }}
                  refreshData={refreshData}
                  onClickFiltersButton={handleSetFiltersModal}
                  tabsHeaderJSX={tabsHeaderJSX}
                  selectedFilters={selectedFilters}
                  onClickGroupingButton={handleSetShowGroupingModal}
                  hasGroups={!!selectedGrouping.filter((group: any) => !!group)?.length}
                  exportTableData={exportTableData}
                />

                <ActiveFilters
                  filterView={filterView}
                  onDeleteFilter={handleDeleteFilter}
                  onClearFilters={handleClearFilters}
                />
                {tab === TABS.MAP && (
                  <GoogleMapWrapper
                    isDataRefreshed={isDataRefreshed}
                    tab={tab}
                    handleSetCurrentDeviceId={handleSetCurrentDevice}
                    currentDevice={currentDevice}
                    isFetching={isLoading}
                    data={tableData || []}
                    pagination={pagination.paginationData}
                    changePageSize={pagination.changeSize}
                    changeCurrentPage={pagination.changePage}
                    controls={footerControls}
                    setIsDataRefreshed={setIsDataRefreshed}
                  />
                )}
                {tab === TABS.DEVICES && (
                  <UITable
                    handleDoubleClickRow={handleDoubleClickRow}
                    selectedRowId={currentDevice?.deviceId || null}
                    onRowClick={handleSetCurrentDeviceId}
                    iconCellFields={STATE_NAMES}
                    hiddenSortIcons={defaultSortHiddenIcons.devices}
                    rowIdKey="deviceId"
                    controls={footerControls}
                    className={classes.tableRoot}
                    isFetching={isLoading}
                    keyLabel="sortField"
                    columns={columns}
                    data={tableData}
                    CellJSX={DeviceTableCell}
                    setColumns={setColumns}
                    sorting={sorting}
                    onSort={changeSorting}
                    changePageSize={pagination.changeSize}
                    changeCurrentPage={pagination.changePage}
                    pagination={pagination.paginationData}
                    getUpdateRequest={{
                      mutateQueryTable: setTableData,
                    }}
                  />
                )}
              </StyledTableWrapper>
            </StyledPane>

            {isModalDetailsOpen && (
              <TabModalWrapper
                ComponentJSX={tabComponentJSX}
                open={isModalDetailsOpen}
                onClose={(): void => setIsModalDetailsOpen(false)}
              />
            )}
            {!isFullScreen && <div className={classes.tabsWrapper}>{tabComponentJSX}</div>}
          </SplitPane>
        </StyledSplitPane>
      </PageWrapper>

      {showFiltersModal && (
        <FiltersModalTransactions
          filterTemplatesType="devicesFilterTemplates"
          formData={{
            initialValues: selectedFilters,
            onSubmit: handleSubmit,
            enableReinitialize: true,
          }}
          defaultValues={defaultFilters}
          open={showFiltersModal}
          handleClose={handleSetFiltersModal}
          filterFields={fields}
          selectedFilters={selectedFilters}
          selectedFiltersTemplate={selectedFiltersTemplate}
          handleApplyFilterTemplate={handleSetSelectedFilterTemplate}
        />
      )}

      {showGroupingModal && (
        <GroupingModal
          groupingTemplatesType="devicesGroupingTemplates"
          open={showGroupingModal}
          appliedGrouping={selectedGrouping}
          appliedGroupingTemplate={selectedGroupingTemplate}
          defaultGrouping={defaultGrouping}
          onApply={handleSetSelectedGrouping}
          onClose={handleSetShowGroupingModal}
          handleApplyGroupingTemplate={handleSetSelectedGroupingTemplate}
          groupsList={DEVICES_GROUPS}
          groupingLevel={4}
        />
      )}
    </div>
  )
}

export default withResizeDetector(DevicesPage)
