import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(() => ({
  checkbox: {
    padding: 0,
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  disabled: {
    opacity: 0.7,
    cursor: 'default',
  },
}))
