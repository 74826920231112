export enum MultipleSnapshotsActions {
  GetDevicesListScroll = '@/MULTIPLE_SNAPSHOTS/GET_DEVICES_LIST_SCROLL',
  SetDevicesList = '@/MULTIPLE_SNAPSHOTS/SET_DEVICES_LIST',
  UpdateDevicesList = '@/MULTIPLE_SNAPSHOTS/UPDATE_DEVICES_LIST',
  GetDevicesConfig = '@/MULTIPLE_SNAPSHOTS/GET_DEVICES_CONFIG',
  GetTicketResponseFail = '@/MULTIPLE_SNAPSHOTS/GET_TICKETS_RESPONSE_FAIL',
  GetGroupDetailsResponse = '@/MULTIPLE_SNAPSHOTS/GET_GROUP_DETAILS_RESPONSE',
  GetGroupDetailsResponseFail = '@/MULTIPLE_SNAPSHOTS/GET_GROUP_DETAILS_RESPONSE_FAIL',
  DeleteSdGroupTemplateResponseSuccess = '@/MULTIPLE_SNAPSHOTS/DELETE_SD_GROUP_TEMPLATE_RESPONSE_SUCCESS',
  GetTicketsConfigResponseFail = '@/MULTIPLE_SNAPSHOTS/GET_TICKETS_CONFIG_RESPONSE_FAIL',
  SetStringFilter = '@/MULTIPLE_SNAPSHOTS/SET_STRING_FILTER',
  SetModalState = '@/MULTIPLE_SNAPSHOTS/SET_MODAL_STATE',

  SetSelectedGroupingTemplate = '@/MULTIPLE_SNAPSHOTS/SET_SELECTED_GROUPING_TEMPLATE',
  ChangeSelectedFilters = '@/MULTIPLE_SNAPSHOTS/CHANGE_SELECTED_FILTERS',
  ChangeSelectedFiltersForDevice = '@/MULTIPLE_SNAPSHOTS/CHANGE_SELECTED_FILTERS_FOR_DEVICE',
  ChangeGrouping = '@/MULTIPLE_SNAPSHOTS/CHANGE_GROUPING',
  ChangeSorting = '@/MULTIPLE_SNAPSHOTS/CHANGE_SORTING',
  SetRowPerPage = '@/MULTIPLE_SNAPSHOTS/SET_ROW_PER_PAGE',
  SetPage = '@/MULTIPLE_SNAPSHOTS/SET_PAGE',
  GetSnapshotTableData = '@/MULTIPLE_SNAPSHOTS/GET_SNAPSHOT_TABLE_DATA',
  SaveMultipleSnapshotRequest = '@/MULTIPLE_SNAPSHOTS/SAVE_MULTIPLE_SNAPSHOT_REQUEST',
  RemoveSnapshotTable = '@/MULTIPLE_SNAPSHOTS/REMOVE_SNAPSHOT_TABLE',
  GetSnapshotTableDataSaved = '@/MULTIPLE_SNAPSHOTS/GET_SNAPSHOT_TABLE_DATA_SAVED',
  SetSnapshotTableData = '@/MULTIPLE_SNAPSHOTS/SET_SNAPSHOT_TABLE_DATA',
  UpdateSaveSnapshotTableData = '@/MULTIPLE_SNAPSHOTS/UPDATE_SAVE_SNAPSHOT_TABLE_DATA',
  GetPhotoData = '@/MULTIPLE_SNAPSHOTS/GET_PHOTO_DATA',
  SetPhotoData = '@/MULTIPLE_SNAPSHOTS/SET_PHOTO_DATA',
  SetFetchingCreatingSnapshot = '@/MULTIPLE_SNAPSHOTS/SET_FETCHING_CREATING_SNAPSHOT',
  SetSnapshotStatus = '@/MULTIPLE_SNAPSHOTS/SET_SNAPSHOT_STATUS',
  StopFetchingSnapshot = '@/MULTIPLE_SNAPSHOTS/STOP_FETCHING_SNAPSHOT',
  OpenModalCarousel = '@/MULTIPLE_SNAPSHOTS/OPEN_MODAL_CAROUSEL',
  CloseModalCarousel = '@/MULTIPLE_SNAPSHOTS/CLOSE_MODAL_CAROUSEL',
  DeleteFilterTemplateRequest = '@/MULTIPLE_SNAPSHOTS/DELETE_FILTER_TEMPLATE_REQUEST',
  DeleteFilterTemplateResponse = '@/MULTIPLE_SNAPSHOTS/DELETE_FILTER_TEMPLATE_RESPONSE',
  GetGroupingTemplatesRequest = '@/MULTIPLE_SNAPSHOTS/GET_GROUPING_TEMPLATES_REQUEST',

  GetFiltersTemplatesRequest = '@/MULTIPLE_SNAPSHOTS/GET_FILTERS_TEMPLATES_REQUEST',
  SaveGroupingTemplateRequest = '@/MULTIPLE_SNAPSHOTS/SAVE_GROUPING_TEMPLATE_REQUEST',

  CreateFilterTemplateRequest = '@/MULTIPLE_SNAPSHOTS/CREATE_FILTER_TEMPLATE_REQUEST',
  EditGroupTemplateRequest = '@/MULTIPLE_SNAPSHOTS/EDIT_GROUP_TEMPLATE_REQUEST',
  DeleteGroupTemplateRequest = '@/MULTIPLE_SNAPSHOTS/DELETE_GROUP_TEMPLATE_REQUEST',

  UpdateFilterTemplateRequest = '@/MULTIPLE_SNAPSHOTS/UPDATE_FILTER_TEMPLATE_REQUEST',
  SetGroupTemplates = '@/MULTIPLE_SNAPSHOTS/SET_GROUP_TEMPLATES',
  GetExportRequest = '@/MULTIPLE_SNAPSHOTS/GET_EXPORT_REQUEST',
  GetExportResponse = '@/MULTIPLE_SNAPSHOTS/GET_EXPORT_RESPONSE',
  GetExportResponseFail = '@/MULTIPLE_SNAPSHOTS/GET_EXPORT_RESPONSE_FAIL',

  GetExportGroupedRequest = '@/MULTIPLE_SNAPSHOTS/GET_EXPORT_GROUPED_REQUEST',
  GetExportGroupedResponse = '@/MULTIPLE_SNAPSHOTS/GET_EXPORT_GROUPED_RESPONSE',
  GetExportGroupedResponseFail = '@/MULTIPLE_SNAPSHOTS/GET_EXPORT_GROUPED_RESPONSE_FAIL',
  GetTransactionsConfigRequest = '@/MULTIPLE_SNAPSHOTS/GET_TRANSACTIONS_CONFIG_REQUEST',
  GetDevicesRequest = '@/MULTIPLE_SNAPSHOTS/GET_DEVICES_REQUEST',
  GetTransactionsResponse = '@/MULTIPLE_SNAPSHOTS/GET_TRANSACTIONS_RESPONSE',
  GetGroupDetailsRequest = '@/MULTIPLE_SNAPSHOTS/GET_GROUP_DETAILS_REQUEST',
  SetParentGroups = '@/MULTIPLE_SNAPSHOTS/SET_PARENT_GROUPS',
  SetCurrentFilterTemplate = '@/MULTIPLE_SNAPSHOTS/SET_CURRENT_FILTER_TEMPLATE',
  SetSdFilterTemplateResponse = '@/MULTIPLE_SNAPSHOTS/SET_FILTER_TEMPLATE_RESPONSE',
  CreateSdFilterTemplateResponse = '@/MULTIPLE_SNAPSHOTS/CREATE_SD_FILTER_TEMPLATE_RESPONSE',
  EditSdGroupTemplateFromResponse = '@/MULTIPLE_SNAPSHOTS/EDIT_SD_GROUP_TEMPLATE_FROM_RESPONSE',
  UpdateSdFilterTemplateResponse = '@/MULTIPLE_SNAPSHOTS/UPDATE_SD_FILTER_TEMPLATE_RESPONSE',
  GetMedia = '@/MULTIPLE_SNAPSHOTS/GET_MEDIA',
  SetMedia = '@/MULTIPLE_SNAPSHOTS/SET_MEDIA',
  ClearCurrentMedia = '@/MULTIPLE_SNAPSHOTS/CLEAR_CURRENT_MEDIA',
  SetCurrentMedia = '@/MULTIPLE_SNAPSHOTS/SET_CURRENT_MEDIA',
  SetMediaErrorMessage = '@/MULTIPLE_SNAPSHOTS/SET_MEDIA_ERROR_MESSAGE',
  EditTableCellRequest = '@/MULTIPLE_SNAPSHOTS/EDIT_TABLE_CELL_REQUEST',
  EditTableCellGrouping = '@/MULTIPLE_SNAPSHOTS/EDIT_TABLE_CELL_REQUEST_GROUPING',
  SaveTableCell = '@/MULTIPLE_SNAPSHOTS/SAVE_TABLE_CELL',
  SaveTableCellGrouping = '@/MULTIPLE_SNAPSHOTS/SAVE_TABLE_CELL_GROUPING',
  SetDataPagination = '@/MULTIPLE_SNAPSHOTS/SET_DATA_PAGINATION',
  SetDataPaginationRequest = '@/MULTIPLE_SNAPSHOTS/SET_DATA_PAGINATION_REQUEST',
  CollectFilterTemplateFields = '@/MULTIPLE_SNAPSHOTS/COLLECT_FILTER_TEMPLATE_FIELDS',
  GetTicketsConfigResponse = '@/MULTIPLE_SNAPSHOTS/GET_TICKETS_CONFIG_RESPONSE',
  ClearGroupedTicketDetailsData = '@/MULTIPLE_SNAPSHOTS/CLEAR_GROUPED_DETAILS_DATA',
  SelectRow = '@/MULTIPLE_SNAPSHOTS/SELECT_ROW',
  CreateCard = '@/MULTIPLE_SNAPSHOTS/CREATE_CARD',
  RemoveCard = '@/MULTIPLE_SNAPSHOTS/REMOVE_CARD',
  ClearNewShapshotId = '@/MULTIPLE_SNAPSHOTS/CLEAR_NEW_SNAPSHOT_ID',

  SetModalStateError = '@/MULTIPLE_SNAPSHOTS/SET_MODAL_STATE_ERROR',
  SetModalStateFetching = '@/MULTIPLE_SNAPSHOTS/SET_MODAL_STATE_FETCHING',

  UnmountComponent = '@/MULTIPLE_SNAPSHOTS/UNMOUNT_COMPONENT',
}
