import { createSelector } from 'reselect'
import { AppState } from '@/store/reducers'
import * as fromNotificationsActions from './reducer'
import { initialState } from './reducer'

export const getNotificationsActionsState = (state: AppState): fromNotificationsActions.State =>
  state.notificationsActions || initialState

export const getNotificationsStatus = createSelector(
  getNotificationsActionsState,
  state => state.notificationsIsActive,
)

export const getNewNotifications = createSelector(
  getNotificationsActionsState,
  state => state.newNotifications,
)

export const getNotifications = createSelector(
  getNotificationsActionsState,
  state => state.notifications,
)
