import queryString from 'query-string'

import {
  AdmUserNetworkTreeNode,
  AdmUserNetworkTreeNodeBase,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

export class UserNetworksAdminApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async get(userId: string): Promise<AdmUserNetworkTreeNode[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/user/networks/nodes',
          query: { guid: userId },
        },
        { skipNull: true, encode: false },
      )

      return await UserNetworksAdminApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async set(id: string, payload: AdmUserNetworkTreeNodeBase[]): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/user/networks/nodes/edit',
          query: { guid: id },
        },
        { skipNull: true, encode: false },
      )

      return await UserNetworksAdminApi.requestService.put(url, payload)
    } catch (error) {
      throw Error(error)
    }
  }
}
