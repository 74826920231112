import clsx from 'clsx'
import React from 'react'
import { useDispatch } from 'react-redux'

import Person from '@/assets/usm/draverActionsIcon/Person.svg'
import UserGroup from '@/assets/usm/draverActionsIcon/User-group.svg'
import { OptionContextMenu } from '@/components/controls/ContextMenu/types'
import { actionsUsersUSM } from '@/store/usm/users'
import { translate } from '@/utils/internalization'

import { useClasses } from './stylex'

export const useContextMenuUSM = (
  style: string,
  setAddUserModalUSMIsOpen: (value: boolean) => void,
  setAddUserGroupModalUSMIsOpen: (value: boolean) => void,
) => {
  const classes = useClasses()
  const dispatch = useDispatch()
  const addUser = () => {
    dispatch(actionsUsersUSM.getLookupConfigCreateUserAsync())
    setAddUserModalUSMIsOpen(true)
  }

  const addUserGroup = () => {
    setAddUserGroupModalUSMIsOpen(true)
    dispatch(actionsUsersUSM.getLookupConfigEditUserAsync())
  }

  const contextMenuUSM: OptionContextMenu = [
    {
      content: (
        <>
          <img src={Person} alt="person" />
          <span className={clsx(style, classes.styleText)}>
            {translate('translate#usm.title.drawer.action.person')}
          </span>
        </>
      ),
      handleClick: addUser,
    },
    {
      content: (
        <>
          <img src={UserGroup} alt="user-group" />
          <span className={clsx(style, classes.styleText)}>
            {translate('translate#usm.title.drawer.action.user.group')}
          </span>
        </>
      ),
      handleClick: addUserGroup,
    },
  ]

  const styleAddMenu = {
    menuList: classes.menuList,
    menuSpacing: classes.menuSpacing,
    driver: classes.driver,
  }
  return { contextMenuUSM, styleAddMenu }
}
