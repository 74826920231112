import { createSelector } from 'reselect'
import { AppState } from '@/store/reducers'
import * as fromReducer from './reducer'

export const getAnalysisState = (state: AppState): fromReducer.State => state.cmAnalysis

export const getAnalysisReports = createSelector(getAnalysisState, state => state.reports)

export const getAnalysisFilter = createSelector(getAnalysisState, state => state.filter)

export const getAnalysisSelectedChartsType = createSelector(
  getAnalysisState,
  state => state.selectedChartsType,
)

export const getAnalysisCharts = createSelector(getAnalysisState, state => state.charts)

export const getAnalysisModalState = createSelector(
  getAnalysisState,
  ({ isFiltersModalOpen, isDocumentModalOpen }) => ({
    isFiltersModalOpen,
    isDocumentModalOpen,
  }),
)
