export enum FileSelectField {
  Files = 'file',
  FileType = 'fileType',
  Separator = 'separator',
  Sheet = 'sheet',
  SkipHeaderLine = 'skipHeaderLine',
  Encoding = 'encoding',
}

export interface FileSelectFieldFormValues {
  [FileSelectField.Files]: File
  [key: string]: any
}
