import queryString from 'query-string'

import {
  AdmIncidentsLimit,
  AdmIncidentsLimitBase,
  AdmLimitsRequest,
} from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

interface GetProps {
  sort: Sort
  filter: AdmLimitsRequest
}

export class IncidentsLimitsApi {
  private static requestService = getRequestServiceCM()

  public static async get({ filter, sort }: GetProps): Promise<AdmIncidentsLimit[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/incident/limits',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )
      return await IncidentsLimitsApi.requestService.post(url, filter)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(payload: AdmIncidentsLimitBase): Promise<void> {
    try {
      return await IncidentsLimitsApi.requestService.post('/admin/incident/limits/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }
}
