import moment from 'moment'
import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { CMCommonApi } from '@/api/cm/commonApi'
import { MonitoringApi } from '@/api/cm/monitoringApi/index'
import {
  MonCashPoint,
  MonChartsRequestBody,
  MonSetPlanStatusRequest,
} from '@/api/cm/swaggerGeneratedApi'
import {
  isCashPointAcceptable,
  isCashPointDeclinable,
  parseDate,
} from '@/components/pages/cm/MonitoringPage/helpers/methods'
import { CHART_BY_CURRENCY, CHART_CIN, CMActions, LocalStorageItems } from '@/constants'
import { AppState } from '@/store/reducers'
import { hotKeysBinder } from '@/utils/hotKeysBinder'

import {
  getGlobalFilter,
  setComCMError,
  setGlobalFilter,
  setSelectedFilterCashPointCM,
} from '../common'
import {
  getMonCassettesChartsFail,
  getMonCassettesChartsResponse,
  getMonCINChartsFail,
  getMonCINChartsResponse,
} from '.'
import {
  checkAllCashPoints,
  clearCheckedCashPoints,
  getCashPointCassettesFail,
  getCashPointCassettesRequest,
  getCashPointCassettesResponse,
  getCashPointDetailsRequest,
  getCashPointDetailsResponse,
  getCashPointDetailsResponseFail,
  getCashPointsForMainRequest,
  getCashPointsForMainResponse,
  getCashPointsForMainResponseFail,
  getMonCassettesChartsRequest,
  getMonCINChartsRequest,
  getMonitoringReportResponse,
  getMonitoringReportResponseFail,
  getMonitoringReportsResponse,
  getMonitoringReportsResponseFail,
  refreshMonCashPointRequest,
  refreshMonCashPointResponse,
  refreshMonitoring,
  resetMonCashPointsPage,
  setDocumentModalOpen,
  setFiltersModalOpen,
  setMonCashPointDetailsCassettesFilter,
  setMonCashPointDetailsCurrencyFilter,
  setMonCashPointsFilter,
  setMonCashPointsPagingResponse,
  setMonSelectedCashPoint,
  setSelectedDate,
  toggleCashPoint,
} from './actions'
import { State } from './reducer'
import { getCashPointsPaging, getCashPointsSort, getMonitoringFilter } from './selectors'

function* getCashPointsForMain(): SagaIterator {
  try {
    const paging = yield select(getCashPointsPaging)
    const sort = yield select(getCashPointsSort)
    const monFilter = yield select(getMonitoringFilter)
    const globalFilter = yield select(getGlobalFilter)

    const response = yield call(
      MonitoringApi.getCashPoints,
      paging,
      {
        globalFilter,
        monFilter,
      },
      sort.sortColumn,
      sort.sortOrder,
    )

    yield put(getCashPointsForMainResponse(response))
    return response
  } catch (error) {
    yield put(getCashPointsForMainResponseFail(error))
  }
}

function* getCashPointDetails({ payload }: AnyAction): SagaIterator {
  try {
    const {
      cashPoints: { selectedCPoint },
      cashPointDetails: {
        selectedTab,
        filter: { selectedDate, currency: currencyFilter },
      },
    } = yield select(state => state?.cmMonitoring)

    let details

    let filterType

    switch (selectedTab) {
      case CHART_BY_CURRENCY:
        filterType = currencyFilter?.type
        break
      case CHART_CIN:
        filterType = 'cash_in_remainders_amounts'
        break

      default:
        filterType = currencyFilter?.type
        break
    }

    if (selectedCPoint?.id) {
      const requestBody: MonChartsRequestBody = payload || {
        monFilter: {
          cpId: selectedCPoint.id,
          dateFrom: moment(selectedDate.start)
            .startOf('day')
            .format(),
          dateTo: moment(selectedDate.end)
            .startOf('day')
            .format(),
          filterType,
          filterPeriod: currencyFilter?.period,
        },
      }
      details = yield call(MonitoringApi.getCashPointDetails, requestBody)
    } else {
      details = null
    }
    yield put(getCashPointDetailsResponse(details))
  } catch (error) {
    yield put(getCashPointDetailsResponseFail(error.message))
  }
}

function* setCashPointsFilter({ payload }: AnyAction): any {
  try {
    yield put(setGlobalFilter(payload.globalFilter))
    yield put(setMonCashPointsFilter(payload.monFilter))
  } catch (e) {}
}

function* handleCashPointsFilterChange(action: AnyAction): SagaIterator {
  yield setCashPointsFilter(action)
  yield put(resetMonCashPointsPage())
  yield put(setMonSelectedCashPoint(null))
  yield put(clearCheckedCashPoints())
  yield put(getCashPointsForMainRequest())
}

function* getCashPointCassettesForChartCIN({ payload }: AnyAction): SagaIterator {
  try {
    const {
      cashPoints: { selectedCPoint },
      cashPointDetails: {
        filter: { selectedDate },
        cinFilter: { type },
      },
    } = yield select(state => state?.cmMonitoring)

    let cassette = []

    if (selectedCPoint?.id) {
      const requestBody = payload || {
        monFilter: {
          cpId: selectedCPoint?.id || '',
          dateFrom: moment(selectedDate.start)
            .startOf('day')
            .toISOString(),
          dateTo: moment(selectedDate.end)
            .startOf('day')
            .toISOString(),
          filterType: type,
        },
      }
      cassette = yield call(MonitoringApi.getCashPointCassettesForChartCIN, requestBody)
    }

    yield put(getMonCINChartsResponse(cassette))
  } catch (e) {
    yield put(getMonCINChartsFail())
  }
}

function* getCashPointCassettesForChart({ payload }: AnyAction): SagaIterator {
  try {
    const {
      cashPoints: { selectedCPoint },
      cashPointDetails: {
        filter: { selectedDate, cassettes: cassetteFilter },
      },
    } = yield select(state => state?.cmMonitoring)
    let cassettes = []
    if (selectedCPoint?.id) {
      const requestBody = payload || {
        monFilter: {
          cpId: selectedCPoint?.id || '',
          dateFrom: moment(selectedDate.start)
            .startOf('day')
            .format(),
          dateTo: moment(selectedDate.end)
            .startOf('day')
            .format(),
          filterType: cassetteFilter?.type,
        },
      }
      cassettes = yield call(MonitoringApi.getCashPointCassettesForChart, requestBody)
    }
    yield put(getMonCassettesChartsResponse(cassettes))
  } catch (e) {
    yield put(getMonCassettesChartsFail())
  }
}

function* getReports(): SagaIterator {
  try {
    const { checkedCashPoints } = yield select(state => state?.cmPlanning?.cashPoints)
    const {
      cashPoints: { selectedCPoint, monFilter: pointsFilter },
      cashPointDetails: {
        filter: { selectedDate, currency: currencyFilter },
      },
    } = yield select(state => state?.cmMonitoring)

    const globalFilter = yield select(state => state?.cmCommon?.globalFilter)

    const monFilter = {
      cpId: selectedCPoint?.id || '',
      dateFrom: parseDate(selectedDate.start as string),
      dateTo: parseDate(selectedDate.end as string),
      filterType: currencyFilter?.type,
      filterPeriod: currencyFilter?.period,
    }

    const response = yield call(MonitoringApi.getReports, {
      globalFilter: globalFilter,
      pointsFilter: pointsFilter,
      monFilter: monFilter,
      checkedIds: Object.keys(checkedCashPoints),
      selectedId: selectedCPoint?.id,
    })
    yield put(getMonitoringReportsResponse(response))
  } catch (e) {
    yield put(getMonitoringReportsResponseFail(e?.message))
  }
}

function* getReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(CMCommonApi.getReport, payload)
      yield put(getMonitoringReportResponse(response))
    } else {
      yield put(getMonitoringReportResponse(null))
    }
  } catch (e) {
    yield put(getMonitoringReportResponseFail(e?.message))
  }
}

function* handleRefresh(): SagaIterator {
  try {
    yield put(getCashPointsForMainRequest())
    yield put(clearCheckedCashPoints())
    const { selectedCPoint } = yield select(state => state?.cmMonitoring?.cashPoints)
    if (selectedCPoint?.id) {
      yield put(refreshMonCashPointRequest(selectedCPoint.id))
    }
  } catch (e) {}
}

function* setCashPointFilter(filter: State['cashPointDetails']['filter']): any {
  yield put(setMonCashPointDetailsCurrencyFilter(filter?.currency))
  yield put(setMonCashPointDetailsCassettesFilter(filter?.cassettes))
  yield put(setSelectedDate(filter?.selectedDate))
  return filter
}

function* handleCashPointDetailsFilterChange({ payload }: AnyAction): SagaIterator {
  yield setCashPointFilter(payload)

  yield put(getCashPointCassettesRequest())
  yield put(getCashPointDetailsRequest())
  yield put(getMonCassettesChartsRequest())
  yield put(getMonCINChartsRequest())
}

function* setPlanStatus(requestBody: MonSetPlanStatusRequest): any {
  try {
    const response = yield call(MonitoringApi.setPlanStatus, requestBody)

    return response
  } catch (e) {}
}

function* handleSetPlanStatus(action: AnyAction): SagaIterator {
  const {
    payload: { checkedCashPoints, status },
  } = action

  let filterLambda
  switch (status) {
    case 'accept':
      filterLambda = (cashPoint: MonCashPoint): boolean => isCashPointAcceptable(cashPoint)
      break
    case 'decline':
      filterLambda = (cashPoint: MonCashPoint): boolean => isCashPointDeclinable(cashPoint)
      break
    default:
      return
  }

  const ids = Object.keys(checkedCashPoints)
    .map(id => checkedCashPoints[id])
    .filter(filterLambda)
    .map(el => el?.id)

  if (ids?.length) {
    const requestBody: MonSetPlanStatusRequest = {
      ids,
      isAccepted: status === 'accept',
    }

    yield setPlanStatus(requestBody)

    for (const cpId of ids) {
      yield put(refreshMonCashPointRequest(cpId))
    }
  }
}

function* handleCashPointsPagingChange({ payload }: AnyAction): SagaIterator {
  yield put(setMonCashPointsPagingResponse(payload))
  yield put(getCashPointsForMainRequest())
}

function* handleGetMonitoringSettings(): SagaIterator {
  try {
    const { defaultLookAheadPeriodDays } = yield call(MonitoringApi.getSettings)
    const days = defaultLookAheadPeriodDays >= 0 ? defaultLookAheadPeriodDays : 14
    localStorage.setItem(LocalStorageItems.MonitoringLookAheadPeriodDays, days)
  } catch (e) {}
}

function* refreshCashPoint({ payload }: AnyAction): SagaIterator {
  try {
    const cashPoint = yield call(MonitoringApi.getCashPoint, payload)

    yield put(refreshMonCashPointResponse(cashPoint))

    const { selectedCPoint, checkedCashPoints } = yield select(
      (state: AppState) => state?.cmMonitoring?.cashPoints,
    )

    if (selectedCPoint?.id === cashPoint?.id) {
      yield put(setMonSelectedCashPoint(cashPoint))
    }

    if (checkedCashPoints[cashPoint?.id]) {
      const newCheckedCashPoints = { ...checkedCashPoints }

      newCheckedCashPoints[cashPoint.id] = cashPoint

      yield put(
        checkAllCashPoints(Object.keys(newCheckedCashPoints).map(key => newCheckedCashPoints[key])),
      )
    }
  } catch (e) {
    yield put(setComCMError(e))
  }
}

function* handleApplySelectedList(): SagaIterator {
  yield put(getCashPointsForMainRequest())
}

function* handleFilterIdsChange({ type, payload }: AnyAction): SagaIterator {
  let newSelIds = payload
  if (type === CMActions.MonRemoveFilterIds) {
    const { selectedCashPointsIds } = yield select(
      (state: AppState) => state.cmCommon.globalFilterCashPoints,
    )
    newSelIds = selectedCashPointsIds.filter(
      (el: string) => !payload.some((elToRemove: string) => el === elToRemove),
    )
  }
  yield put(setSelectedFilterCashPointCM(newSelIds))
  yield put(setGlobalFilter({ fixedTids: newSelIds }))
  yield put(refreshMonitoring())
}

function* handleKeyPress({ payload }: AnyAction): SagaIterator {
  try {
    const {
      selectedCPoint: selected,
      data,
      isFiltersModalOpen,
      isDocumentModalOpen,
      isColumnModalOpen,
    } = yield select((state: AppState) => state.cmMonitoring.cashPoints)
    const {
      isEditCassettesModalOpen,
      declineNoteState: { isModalOpen: isDeclineNoteModalOpen },
    } = yield select((state: AppState) => state.cmShared)

    hotKeysBinder<MonCashPoint>({
      key: payload,
      data,
      selected,
      isOpenModalsList: [
        isFiltersModalOpen,
        isColumnModalOpen,
        isDocumentModalOpen,
        isEditCassettesModalOpen,
        isDeclineNoteModalOpen,
      ],
      setSelectedAction: setMonSelectedCashPoint,
      onToggleAction: toggleCashPoint,
      openFilterAction: setFiltersModalOpen,
      openPrintAction: setDocumentModalOpen,
      onRefreshAction: refreshMonitoring,
    })
  } catch (error) {
    yield put(setComCMError(error))
  }
}

function* getCashPointCassettes(): SagaIterator {
  try {
    const {
      cashPoints: {
        selectedCPoint: { id },
      },
    } = yield select(state => state?.cmMonitoring)

    const cashPoint = yield call(MonitoringApi.getCashPointCassettes, id)

    if (cashPoint?.cassettes) {
      yield put(getCashPointCassettesResponse(cashPoint.cassettes))
    }
  } catch (e) {
    yield put(getCashPointCassettesFail())
  }
}

export default function*(): Generator {
  yield takeLatest(CMActions.SetMonCashPointDetailsFilter, handleCashPointDetailsFilterChange)
  yield takeLatest(CMActions.GetCashPointDetailsRequest, getCashPointDetails)
  yield takeLatest(
    [CMActions.GetCashPointsForMainRequest, CMActions.MonSortCashPointsTable],
    getCashPointsForMain,
  )
  yield takeLatest(CMActions.SetMonCashPointsPagingRequest, handleCashPointsPagingChange)
  yield takeLatest(CMActions.SetCashPointsFilter, handleCashPointsFilterChange)
  yield takeLatest(CMActions.GetCashPointCassettesRequest, getCashPointCassettes)
  yield takeLatest(CMActions.GetMonCassettesChartsRequest, getCashPointCassettesForChart)
  yield takeLatest(CMActions.GetMonCINChartsRequest, getCashPointCassettesForChartCIN)
  yield takeLatest(CMActions.GetMonitoringReportsRequest, getReports)
  yield takeLatest(CMActions.GetMonitoringReportRequest, getReport)
  yield takeLatest(CMActions.GetMonitoringSettingsRequest, handleGetMonitoringSettings)
  yield takeLatest(CMActions.RefreshMonitoring, handleRefresh)
  yield takeLatest(CMActions.MonSetPlanStatus, handleSetPlanStatus)
  yield takeLatest(
    [CMActions.MonApplySelectedList, CMActions.MonRemoveSelectedList],
    handleApplySelectedList,
  )
  yield takeLatest(CMActions.MonHandleKeyPress, handleKeyPress)
  yield takeLatest([CMActions.MonAddFilterIds, CMActions.MonRemoveFilterIds], handleFilterIdsChange)
  yield takeEvery(CMActions.MonRefreshCashPointRequest, refreshCashPoint)
}
