import { Box, Dialog } from '@material-ui/core'
import React from 'react'
import Test from 'react-test-attributes'

import CloseButton from '@/components/blocks/CloseButton'
import { getTestId } from '@/utils'
import { preventDefault } from '@/utils/functions'

import DocumentInformationTable from './components/DocumenInformationTable'
import { Props } from './types'

const DocumentInformationModal = ({
  open,
  testIdPrefix,
  handleClose,
  ticketId,
  handleOpenCreatePosRequestModal,
}: Props): React.ReactElement => {
  const idPrefix = `${testIdPrefix}-doc-info`
  const testId = getTestId(idPrefix)

  return (
    <>
      <Test id={testId(0)}>
        <Dialog
          onDragOver={preventDefault}
          onDrop={preventDefault}
          maxWidth="xl"
          fullWidth
          open={open}
          transitionDuration={0}
          onClose={handleClose}
          disableBackdropClick
        >
          <Test id={testId(1)}>
            <CloseButton onClick={handleClose} absolute />
          </Test>
          <Test id={testId(2)}>
            <Box display="flex" flexDirection="column" overflow="auto">
              {open && (
                <DocumentInformationTable
                  ticketId={ticketId}
                  testIdPrefix={idPrefix}
                  handleClose={handleClose}
                  handleOpenCreatePosRequestModal={handleOpenCreatePosRequestModal}
                />
              )}
            </Box>
          </Test>
        </Dialog>
      </Test>
    </>
  )
}

export default DocumentInformationModal
