type Option = {
  name: string
  value: string
}

export const cassetteTypeOptions: Array<Option> = [
  { name: 'CashOut', value: 'CashOut' },
  { name: 'Recycler', value: 'CashRecycling' },
  { name: 'CashInCashOut', value: 'CashIn_CashOut' },
]

export const cassetteTypeByKey: Array<Option> = [
  { name: 'CashOut', value: 'CashOut' },
  { name: 'Recycler', value: 'Recycler' },
]
