export const ENGLISH = 'en'
export const RUSSIAN = 'ru'
export const LITHUANIAN = 'lt'
export const GEORGIAN = 'ka'
export const AZERBAIJAN = 'az'
export const UZBEKISTAN = 'uz'
export const LATVIAN = 'lv'
export const ESTONIAN = 'et'
export const KAZAKHSTAN = 'kk'
export const KYRGYZSTAN = 'ky'
export const MOLDOVA = 'md'

export enum UILang {
  ENGLISH = 'en',
  LITHUANIAN = 'lt',
  RUSSIAN = 'ru',
  LATVIAN = 'lv',
  ESTONIAN = 'et',

  KAZAKHSTAN = 'kk',
  KYRGYZSTAN = 'ky',
  UZBEKISTAN = 'uz',
  MOLDOVA = 'md',

  AZERBAIJAN = 'az',
}

export const languagesConfig = {
  en: 'ENGLISH',
  ru: 'RUSSIAN',
  lt: 'LITHUANIAN',
  lv: 'LATVIAN',
  ee: 'ESTONIAN',

  kk: 'KAZAKHSTAN',
  ky: 'KYRGYZSTAN',
  uz: 'UZBEKISTAN',
  md: 'MOLDOVA',
  ka: 'GEORGIAN',

  az: 'AZERBAIJAN',
}

export const ISOLanguagesCodes: { [key: string]: string } = {
  [RUSSIAN]: 'ru-RU',
  [LITHUANIAN]: 'lt-LT',
  [ENGLISH]: 'en-EN',
  [LATVIAN]: 'lv-LV',
  [ESTONIAN]: 'et-EE',

  [KAZAKHSTAN]: 'kk',
  [KYRGYZSTAN]: 'ky',
  [MOLDOVA]: 'ro_MD',

  [GEORGIAN]: 'ka-GE',
  [AZERBAIJAN]: 'az-AZ',
  [UZBEKISTAN]: 'uz-UZ',
}

export const INITIAL_LOCALE = ENGLISH

export const ALLOWED_LOCALES = [
  ENGLISH,
  RUSSIAN,
  LITHUANIAN,
  LATVIAN,
  ESTONIAN,

  GEORGIAN,
  AZERBAIJAN,
  KAZAKHSTAN,
  KYRGYZSTAN,
  UZBEKISTAN,
  MOLDOVA,
]

export const DEFAULT_CULTURES = [
  { id: 'az', displayName: 'Azeri (Latin)' },
  { id: 'en', displayName: 'English' },
  { id: 'ru', displayName: 'Russian' },
  { id: 'ka', displayName: 'Georgian' },
  { id: 'uz', displayName: 'Uzbekistan' },
  { id: 'lv', displayName: 'Latvian' },
  { id: 'et', displayName: 'Estonian' },
]

export const FlagMapping: { [key: string]: string } = {
  en: 'gb',
  ru: 'ru',
  az: 'az',
  lt: 'lt',
  ka: 'ge',
  uz: 'uz',
  lv: 'lv',
  ee: 'et',
  md: 'md',
}

interface ImpossibleToGetTranslate {
  displayName: string
  localise: string
}

export const IMPOSSIBLE_TO_GET_TRANSLATE: ImpossibleToGetTranslate[] = [
  {
    displayName: 'Same as Legal address',
    localise: 'title.sameAsLegalAddress',
  },
]
