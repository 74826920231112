import { createSelector } from 'reselect'
import { AppState } from '@/store/reducers'
import { State, initialState } from './reducer'

export const getTicketsState = (state: AppState): State => state.srmTickets || initialState

export const getTicketOverviewData = createSelector(getTicketsState, state => state.ticketOverview)

export const getGroupingTemplates = createSelector(
  getTicketsState,
  state => state.grouping.groupingsTemplates,
)

export const isFetchingGroupTemplates = createSelector(
  getTicketsState,
  state => state.isFetchingGroupTemplates,
)

export const getSearchServiceRequestData = createSelector(
  getTicketsState,
  state => state.searchServiceRequest.serviceRequest.data,
)

export const getSearchServiceRequestClosed = createSelector(
  getTicketsState,
  state => state.searchServiceRequest.serviceRequest.closed,
)

export const getSearchServiceWorksData = createSelector(
  getTicketsState,
  state => state.searchServiceRequest.serviceWorks.data,
)

export const getSearchServiceWorksClosed = createSelector(
  getTicketsState,
  state => state.searchServiceRequest.serviceWorks.closed,
)

export const getSearchServiceDeviceData = createSelector(
  getTicketsState,
  state => state.searchServiceRequest.serviceDevice.data,
)

export const getSearchServiceDeviceClosed = createSelector(
  getTicketsState,
  state => state.searchServiceRequest.serviceDevice.closed,
)

export const getSearchPlannedWorksData = createSelector(
  getTicketsState,
  state => state.searchServiceRequest.plannedWorks.data,
)

export const getSearchPlannedWorksClosed = createSelector(
  getTicketsState,
  state => state.searchServiceRequest.plannedWorks.closed,
)

export const getSearchConfigurationData = createSelector(
  getTicketsState,
  state => state.searchServiceRequest.configuration.data,
)

export const getSearchConfigurationClosed = createSelector(
  getTicketsState,
  state => state.searchServiceRequest.configuration.closed,
)

export const getDeviceDefectsStatisticsData = createSelector(
  getTicketsState,
  state => state.searchServiceRequest.deviceDefectsStatistics.data,
)

export const getDeviceDefectsStatisticsClosed = createSelector(
  getTicketsState,
  state => state.searchServiceRequest.deviceDefectsStatistics.closed,
)

export const getServiceWorksStatisticsData = createSelector(
  getTicketsState,
  state => state.searchServiceRequest.serviceWorksStatistics.data,
)

export const getServiceWorksStatisticsClosed = createSelector(
  getTicketsState,
  state => state.searchServiceRequest.serviceWorksStatistics.closed,
)

export const getWorkItemsTableData = createSelector(
  getTicketsState,
  state => state.workItemsAndServiceItems.workItemsData,
)

export const getServiceItemsTableData = createSelector(
  getTicketsState,
  state => state.workItemsAndServiceItems.serviceItemsData,
)

export const getSelectedWorkTableRow = createSelector(
  getTicketsState,
  state => state.workItemsAndServiceItems.selectedWorkTableRow,
)

export const getSelectedServiceTableRow = createSelector(
  getTicketsState,
  state => state.workItemsAndServiceItems.selectedServiceTableRow,
)

export const getIsNewOrderTab = createSelector(
  getTicketsState,
  state => state.newOrder.isNewOrderTab,
)

export const getWorkOrderStatuses = createSelector(
  getTicketsState,
  state => state.newOrder.workOrderStatuses,
)

export const getAnalyzeDelaysTableData = createSelector(
  getTicketsState,
  state => state.analyze.delaysTableData,
)

export const getAdditionalCostsTableData = createSelector(
  getTicketsState,
  state => state.workCost.additionalCosts,
)

export const getAdditionalWorkCost = createSelector(getAdditionalCostsTableData, additionalCosts =>
  additionalCosts.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue.amountEUR)
  }, 0),
)

export const getCustomerRequestItems = createSelector(
  getTicketsState,
  state => state.ticketOverviewRequest.customerRequestItems,
)
export const getServiceItems = createSelector(
  getTicketsState,
  state => state.ticketOverviewRequest.serviceItems,
)
