import moment from 'moment'
import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { CashOrdersApi } from '@/api/vault/cashOrdersApi'
import {
  CashOrderDetail,
  CashOrderStage,
  GetCashOrdersRequestAction,
  ValuableOrderDetail,
} from '@/api/vault/cashOrdersApi/types'
import { cashOrderStages, CreateCashOrderFormValues } from '@/constants'
import { CashOrderActions } from '@/constants/actions/cashOrderActions'

import {
  assignBagSuccess,
  changePagingResponse,
  getAvailableVaultsResponse,
  getCashOrderBagsRequest,
  getCashOrderBagsResponse,
  getCashOrderCommentsRequest,
  getCashOrderCommentsResponse,
  getCashOrderCurrenciesResponse,
  getCashOrderCurrenciesResponseFail,
  getCashOrderDetailsRequest,
  getCashOrderDetailsResponse,
  getCashOrderDocumentResponse,
  getCashOrderDocumentTypesResponse,
  getCashOrdersRequest,
  getCashOrdersResponse,
  getCashOrdersResponseFail,
  getCashOrderStagesRequest,
  getCashOrderStagesResponse,
  getCashOrderStatusesResponse,
  getCashOrderTypesResponse,
  getCreateOrderFormValuablesResponse,
  getExistingCashBagsResponse,
  getOrderLoadsRequest,
  getOrderLoadsResponse,
  getRecieverVaultsResponse,
  getTransmitterVaultsResponse,
  setOrderEditStatus,
  setSelectedCashOrder,
  stagePassed,
  validateCashBagNumberResponse,
} from './actions'

function* getCashOrders({ type, payload }: GetCashOrdersRequestAction): SagaIterator {
  const selectedOrderId = yield select(state => state.vaultCashOrders.selectedCashOrder?.id)
  const storeFilters = yield select(state => state.vaultCashOrders.filter.selectedFilters)
  const storePaging = yield select(state => state.vaultCashOrders.paging)

  const filters = payload?.filters || storeFilters
  const pagingParams = { ...storePaging, ...payload?.paging }

  try {
    const { cashOrders, paging } = yield call(CashOrdersApi.getCashOrders, filters, pagingParams)

    yield put(getCashOrdersResponse(cashOrders))
    yield put(changePagingResponse(paging))

    const selectedOrder = cashOrders.find(
      (order: any) => order.vaultCashOrderId === selectedOrderId,
    )

    if (selectedOrder) {
      yield put(setSelectedCashOrder(selectedOrder))
    }
  } catch (error) {
    yield put(getCashOrdersResponseFail(error.message))
  }
}

function* getCashOrderBags(): SagaIterator {
  try {
    const selectedCashOrder = yield select(state => state.vaultCashOrders.selectedCashOrder)
    if (selectedCashOrder) {
      yield put(getCashOrderBagsRequest())

      const bags = yield call(CashOrdersApi.getCashOrderBags, selectedCashOrder.id)
      yield put(
        getCashOrderBagsResponse(
          bags.map((bag: any) => ({
            ...bag,
            localBagId: bag.bagID,
            isValidBagNumber: true,
            cashBagId: bag.bagID,
          })),
        ),
      )
    }
  } catch (error) {}
}

function* getCashOrderComments(): SagaIterator {
  try {
    const selectedCashOrder = yield select(state => state.vaultCashOrders.selectedCashOrder)
    if (selectedCashOrder) {
      yield put(getCashOrderCommentsRequest())

      const comments = yield call(CashOrdersApi.getCashOrderComments, selectedCashOrder.id)
      yield put(getCashOrderCommentsResponse(comments))
    }
  } catch (error) {}
}

// TODO: UserId mapping when backend ready
function* getCashOrderStages(): SagaIterator {
  const selectedCashOrder = yield select(state => state.vaultCashOrders.selectedCashOrder)
  try {
    if (selectedCashOrder) {
      yield put(getCashOrderStagesRequest())

      const stages: CashOrderStage[] = yield call(
        CashOrdersApi.getCashOrderStages,
        selectedCashOrder.id,
      )
      yield put(getCashOrderStagesResponse(stages))
    }
  } catch (error) {}
}

function* getCashOrderDocumentTypes(): SagaIterator {
  try {
    const selectedCashOrder = yield select(state => state.vaultCashOrders.selectedCashOrder)
    if (selectedCashOrder) {
      const types = yield call(CashOrdersApi.getOrderDocumentTypes, selectedCashOrder.id)
      yield put(getCashOrderDocumentTypesResponse(types))
    }
  } catch (error) {}
}

function* getCashOrderDocument({ payload: documentType }: any): SagaIterator {
  try {
    const orderId = yield select(state => state.vaultCashOrders.selectedCashOrder.id)
    const culture = yield select(state => state.intl.locale)

    if (orderId) {
      const pages = yield call(CashOrdersApi.getDocument, {
        culture,
        documentType,
        orderId,
      })
      yield put(getCashOrderDocumentResponse(pages))
    }
  } catch (error) {}
}

function* getCashOrderDetails(): SagaIterator {
  const selectedCashOrder = yield select(state => state.vaultCashOrders.selectedCashOrder)
  try {
    if (selectedCashOrder) {
      yield put(getCashOrderDetailsRequest())

      const details: Array<CashOrderDetail | ValuableOrderDetail> = yield call(
        CashOrdersApi.getCashOrderDetails,
        selectedCashOrder.id,
        selectedCashOrder.type,
      )
      yield put(getCashOrderDetailsResponse(details))
    }
  } catch (error) {}
}

function* handlePagingChange({ payload }: AnyAction): SagaIterator {
  try {
    yield put(getCashOrdersRequest({ paging: payload }))
  } catch (error) {}
}

function* getCurrencies(): SagaIterator {
  try {
    const currencies = yield call(CashOrdersApi.getCurrencies)
    yield put(getCashOrderCurrenciesResponse(currencies))
  } catch (error) {
    yield put(getCashOrderCurrenciesResponseFail())
  }
}

function* getCreateFormValuables(): SagaIterator {
  try {
    const valuables = yield call(CashOrdersApi.getCreateOrderFormValuables)
    yield put(getCreateOrderFormValuablesResponse(valuables))
  } catch (error) {}
}

// TODO: userId mapping
function* getRecieverVaults(): SagaIterator {
  try {
    const recieverVaults = yield call(CashOrdersApi.getRecieverVaults)
    yield put(getRecieverVaultsResponse(recieverVaults))
  } catch (error) {}
}

function* getTransmitterVaults(): SagaIterator {
  try {
    const transmitterVaults = yield call(CashOrdersApi.getTransmitterVaults)
    yield put(getTransmitterVaultsResponse(transmitterVaults))
  } catch (error) {}
}

function* getRecieverVaultsFromTransmitter({ payload }: AnyAction): SagaIterator {
  try {
    const recieverVaults = yield call(CashOrdersApi.getRecieverVaultsFromTransmitter, payload)
    yield put(getRecieverVaultsResponse(recieverVaults))
  } catch (error) {}
}

function* getTransmitterVaultsFromReceiver({ payload }: AnyAction): SagaIterator {
  try {
    const transmitterVaults = yield call(CashOrdersApi.getTransmitterVaultsFromReceiver, payload)
    yield put(getTransmitterVaultsResponse(transmitterVaults))
  } catch (error) {}
}

function* getOrderLoads({ payload }: AnyAction): SagaIterator {
  try {
    const orderLoads = yield call(CashOrdersApi.getOrderLoads, payload)
    yield put(getOrderLoadsResponse(orderLoads))
  } catch (error) {}
}

function* createOrder({ payload }: AnyAction): SagaIterator {
  try {
    const formValues: CreateCashOrderFormValues = { ...payload }

    if (formValues) {
      const formOrderDate = new Date(formValues.orderDate)
      const formOrderTime = new Date(formValues.orderTime)
      const dateTime = moment(
        `${formOrderDate.getDate()}/${formOrderDate.getMonth() +
          1}/${formOrderDate.getFullYear()}` +
          `${formOrderTime.getHours()}:${formOrderTime.getMinutes()}:${formOrderTime.getSeconds()}`,
        'DD/MM/YYYY hh:mm:ss',
      ).valueOf()
      const orderRequestBody: any = {
        bags: formValues.bags,
        comment: formValues.comment,
        dateTime,
        orderType: 'EXPORT_ORDER',
        receiverID: formValues.reciever?.id || '',
        stamp: formValues.stamp,
        transmitterID: formValues.transmitter?.id || '',
      }

      yield call(CashOrdersApi.createOrder, orderRequestBody) // !!
      yield put(getCashOrdersRequest())
    }
  } catch (error) {}
}

function* editCashOrder({ payload }: AnyAction): SagaIterator {
  try {
    const selectedCashOrder = yield select(state => state.vaultCashOrders.selectedCashOrder)

    const formValues: CreateCashOrderFormValues = { ...payload }

    if (formValues) {
      const formOrderDate = new Date(formValues.orderDate)
      const formOrderTime = new Date(formValues.orderTime)
      const dateTime = moment(
        `${formOrderDate.getDate()}/${formOrderDate.getMonth() +
          1}/${formOrderDate.getFullYear()}` +
          `${formOrderTime.getHours()}:${formOrderTime.getMinutes()}:${formOrderTime.getSeconds()}`,
        'DD/MM/YYYY hh:mm:ss',
      ).valueOf()
      const orderRequestBody: any = {
        bags: formValues.bags,
        comment: formValues.comment,
        dateTime,
        orderID: selectedCashOrder.id,
        orderType: formValues.orderType === 'cashOrder' ? 'CASH_ORDER' : 'EXPORT_ORDER',
        receiverID: formValues.reciever?.id || '',
        stamp: formValues.stamp,
        transmitterID: formValues.transmitter?.id || '',
      }

      yield call(CashOrdersApi.editCashOrder, orderRequestBody) // !!
      yield put(setOrderEditStatus(true))
      yield put(getCashOrdersRequest())
      yield put(assignBagSuccess())
    }
  } catch (error) {
    yield put(setOrderEditStatus(false))
  }
}

function* editCashDeliveryOrder({ payload }: AnyAction): SagaIterator {
  try {
    const formValues: CreateCashOrderFormValues = { ...payload }
    const selectedCashOrder = yield select(state => state.vaultCashOrders.selectedCashOrder)
    // todo extract reusable parts

    if (formValues) {
      const formOrderDate = new Date(formValues.orderDate)
      const formOrderTime = new Date(formValues.orderTime)
      const dateTime = moment(
        `${formOrderDate.getDate()}/${formOrderDate.getMonth() +
          1}/${formOrderDate.getFullYear()}` +
          `${formOrderTime.getHours()}:${formOrderTime.getMinutes()}:${formOrderTime.getSeconds()}`,
        'DD/MM/YYYY hh:mm:ss',
      ).valueOf()

      const orderRequestBody: any = {
        currencies: formValues.currencies,
        valuables: formValues.valuables,
        comment: formValues.comment,
        dateTime,
        orderId: selectedCashOrder.id,
        orderType: 'CASH_ORDER',
        receiverID: formValues.reciever?.id || '',
        stamp: formValues.stamp,
        transmitterID: formValues.transmitter?.id || '',
      }

      yield call(CashOrdersApi.editCashDeliveryOrder, orderRequestBody)

      yield put(getCashOrdersRequest())
      yield put(getOrderLoadsRequest(selectedCashOrder.id))
    }
  } catch (error) {}
}

function* createCashDeliveryOrder({ payload }: AnyAction): SagaIterator {
  try {
    const formValues: CreateCashOrderFormValues = { ...payload }

    // todo extract reusable parts

    if (formValues) {
      const formOrderDate = new Date(formValues.orderDate)
      const formOrderTime = new Date(formValues.orderTime)
      const dateTime = moment(
        `${formOrderDate.getDate()}/${formOrderDate.getMonth() +
          1}/${formOrderDate.getFullYear()}` +
          `${formOrderTime.getHours()}:${formOrderTime.getMinutes()}:${formOrderTime.getSeconds()}`,
        'DD/MM/YYYY hh:mm:ss',
      ).valueOf()

      const orderRequestBody: any = {
        currencies: formValues.currencies,
        valuables: formValues.valuables,
        comment: formValues.comment,
        dateTime,
        orderType: 'CASH_ORDER',
        receiverID: formValues.reciever?.id || '',
        stamp: formValues.stamp,
        transmitterID: formValues.transmitter?.id || '',
      }

      yield call(CashOrdersApi.createCashDeliveryOrder, orderRequestBody)
      yield put(getCashOrdersRequest())
    }
  } catch (error) {}
}

function* assignBagForStorage({ payload }: AnyAction): SagaIterator {
  try {
    const requestBody = {
      cashBagId: payload.cashBagId,
      storageId: payload.storageId,
    }

    yield call(CashOrdersApi.assignBagForStorage, requestBody)
    yield put(assignBagSuccess())
  } catch (error) {}
}

function* assignBagForCashier({ payload }: AnyAction): SagaIterator {
  try {
    const requestBody = {
      cashBagId: payload.cashBagId,
      cashierID: payload.cashierID,
      userID: 'AFF3D8D3-6D79-473E-9F8F-E07EED76EA5B',
    }

    yield call(CashOrdersApi.assignBagForCashier, requestBody)
    yield put(assignBagSuccess())
  } catch (error) {}
}

function* applyStage({ payload: stage }: AnyAction): SagaIterator {
  const selectedCashOrder = yield select(state => state.vaultCashOrders.selectedCashOrder)
  try {
    if (selectedCashOrder) {
      yield call(CashOrdersApi.applyStage, selectedCashOrder.id, stage)
      yield put(stagePassed())

      if (stage === cashOrderStages.SentToCollectors) {
        yield put(assignBagSuccess())
      }
    }
  } catch (error) {}
}

function* assingTeamForOrder({ payload }: AnyAction): SagaIterator {
  const selectedCashOrder = yield select(state => state.vaultCashOrders.selectedCashOrder)
  try {
    if (selectedCashOrder) {
      yield call(CashOrdersApi.assingTeamForOrder, selectedCashOrder.id, payload)
      yield put(stagePassed())
    }
  } catch (error) {}
}

function* createWorkOrder({ payload }: AnyAction): SagaIterator {
  const selectedCashOrder = yield select(state => state.vaultCashOrders.selectedCashOrder)
  try {
    if (selectedCashOrder) {
      yield call(CashOrdersApi.createWorkOrder, payload)
      yield put(stagePassed())
    }
  } catch (error) {}
}

function* getCashOrderStatuses(): SagaIterator {
  try {
    const orderStatuses = yield call(CashOrdersApi.getCashOrderStatuses)
    yield put(getCashOrderStatusesResponse(orderStatuses))
  } catch (error) {}
}

function* getCashOrderTypes(): SagaIterator {
  try {
    const orderTypes = yield call(CashOrdersApi.getCashOrderTypes)
    yield put(getCashOrderTypesResponse(orderTypes))
  } catch (error) {}
}

function* getExistingCashBags({ payload }: AnyAction): SagaIterator {
  try {
    const response = payload.vaultId
      ? yield call(CashOrdersApi.getCashBagsListForVault, payload)
      : yield call(CashOrdersApi.getCashBagsList, payload.fragment)

    yield put(getExistingCashBagsResponse(response))
  } catch (error) {}
}

function* validateCashBagNumber({ payload }: AnyAction): SagaIterator {
  try {
    if (payload.cashBagNumber) {
      const response = yield call(CashOrdersApi.validateCashBagNumber, payload)

      yield put(validateCashBagNumberResponse(response))
    }
  } catch (error) {}
}

function* getAvailableVaults({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(CashOrdersApi.getAvailableVaults, payload)

      yield put(getAvailableVaultsResponse(response))
    }
  } catch (error) {}
}

export default function*(): Generator {
  yield takeLatest(
    CashOrderActions.GetRecieverVaultsFromTransmitterRequest,
    getRecieverVaultsFromTransmitter,
  )
  yield takeLatest(
    CashOrderActions.GetTransmitterVaultsFromRecieverRequest,
    getTransmitterVaultsFromReceiver,
  )
  yield takeLatest(CashOrderActions.GetCashOrderDocumentRequest, getCashOrderDocument)
  yield takeLatest(CashOrderActions.GetCashOrderDocumentTypesRequest, getCashOrderDocumentTypes)

  yield takeLatest(CashOrderActions.GetAvailableVaultsRequest, getAvailableVaults)
  yield takeLatest(CashOrderActions.ValidateCashBagNumberRequest, validateCashBagNumber)
  yield takeLatest(CashOrderActions.GetExistingCashBagsRequest, getExistingCashBags)
  yield takeLatest(CashOrderActions.AssignBagForCashier, assignBagForCashier)
  yield takeLatest(CashOrderActions.AssignBagForStorage, assignBagForStorage)
  yield takeLatest(CashOrderActions.AssignBagSuccess, getCashOrderBags)
  yield takeLatest(CashOrderActions.ChangePagingRequest, handlePagingChange)
  yield takeLatest(CashOrderActions.CreateCashDeliveryOrderRequest, createCashDeliveryOrder)
  yield takeLatest(CashOrderActions.EditCashDeliveryOrderRequest, editCashDeliveryOrder)
  yield takeLatest(CashOrderActions.CreateOrderRequest, createOrder)
  yield takeLatest(CashOrderActions.EditCashOrderRequest, editCashOrder)
  yield takeLatest(CashOrderActions.GetCashOrdersRequest, getCashOrders)
  yield takeLatest(CashOrderActions.GetCreateOrderFormValuablesRequest, getCreateFormValuables)
  yield takeLatest(CashOrderActions.GetCurrenciesRequest, getCurrencies)
  yield takeLatest(CashOrderActions.GetOrderLoadsRequest, getOrderLoads)
  yield takeLatest(CashOrderActions.GetRecieverVaultsRequest, getRecieverVaults)
  yield takeLatest(CashOrderActions.GetTransmitterVaultsRequest, getTransmitterVaults)
  yield takeLatest(CashOrderActions.SetSelectedCashOrder, getCashOrderBags)
  yield takeLatest(CashOrderActions.SetSelectedCashOrder, getCashOrderComments)
  yield takeLatest(CashOrderActions.SetSelectedCashOrder, getCashOrderDetails)
  yield takeLatest(CashOrderActions.SetSelectedCashOrder, getCashOrderStages)

  yield takeLatest(CashOrderActions.AssingTeamForOrder, assingTeamForOrder)
  yield takeLatest(CashOrderActions.CreateWorkOrder, createWorkOrder)
  yield takeLatest(CashOrderActions.ApplyStage, applyStage)

  yield takeLatest(CashOrderActions.StagePassed, getCashOrderStages)
  yield takeLatest(CashOrderActions.StagePassed, getCashOrders)

  yield takeLatest(CashOrderActions.GetCashOrderStatusesRequest, getCashOrderStatuses)
  yield takeLatest(CashOrderActions.GetCashOrderTypesRequest, getCashOrderTypes)
}
