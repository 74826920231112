import queryString from 'query-string'

import { AdmCurrency, AdmCurrencyBase } from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { Sort } from '@/types/cm'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

export class CurrenciesAdminApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getAll(sort: Sort): Promise<AdmCurrency[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/currencies',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )
      return await CurrenciesAdminApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async get(id: string): Promise<AdmCurrency> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/currency',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await CurrenciesAdminApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async setBase(id: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/currency/base',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await CurrenciesAdminApi.requestService.patch(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(currency: AdmCurrencyBase): Promise<void> {
    try {
      return await CurrenciesAdminApi.requestService.post('/admin/currencies/add', currency)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(currency: AdmCurrency): Promise<void> {
    try {
      return await CurrenciesAdminApi.requestService.put('/admin/currencies/update', currency)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(id: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/currencies/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await CurrenciesAdminApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
