import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ mixins, spacing, palette }) => ({
  select: {
    marginRight: '14px',
    width: '155px',
    fontSize: spacing(2),
  },
  title: {
    fontWeight: 'bold',
    fontSize: spacing(2.5),
    lineHeight: '23px',
    textTransform: 'capitalize',
  },
  wrapper: {
    padding: spacing(6.4),
    width: '100%',
    height: '100%',
  },
  box: {
    margin: '0px',
  },
  controls: {
    marginTop: spacing(1),
    marginBottom: spacing(1),
  },
  button: {
    background: '#50A8AE',
    margin: '0px',
    right: spacing(2),
    color: 'white',
    width: '160px',
    minWidth: '160px',
    height: '36px',

    '&:hover': {
      color: '#50A8AE',
    },
  },
}))
