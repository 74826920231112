import makeStyles from '@material-ui/core/styles/makeStyles'
import { styledComponentTheme } from '@/theme'

export const useClasses = makeStyles(({ spacing }) => ({
  table: {
    height: '100%',
  },
  bold: {
    fontWeight: 'bold',
  },
  // tableWithElement: {
  //   height: `calc(100% - ${spacing(9)}px)`,
  // },
  // tableWithTwoElements: {
  //   height: `calc(100% - ${spacing(16)}px)`,
  // },
  // tableWithThreeElements: {
  //   height: `calc(100% - ${spacing(23)}px)`,
  // },
  // tableCell: {
  //   minWidth: spacing(30),
  //   padding: 0,
  //   verticalAlign: 'middle',

  //   '&:nth-child(2)': {
  //     minWidth: spacing(40),
  //   },
  // },
  // withoutAction: {
  //   paddingTop: spacing(0.5),
  //   paddingBottom: spacing(0.5),
  // },
  // title: {
  //   fontSize: styledComponentTheme.fontSizes.small,
  // },
  // headAction: {
  //   minWidth: spacing(4),
  //   padding: 0,
  //   paddingLeft: spacing(1),
  // },
}))
