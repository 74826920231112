import React, { useState, useCallback, ChangeEvent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  getAllDevices,
  getChartDataRequest,
  getChartExtendedDataRequest,
  setRefreshTimout,
} from '@/store/dashboard/actions'
import { useClasses } from './styles'
import RefreshTImeOutModal from '@/components/blocks/TimerWIthRefreshModal/components/RefreshTImeOutModal'
import CreateIcon from '@material-ui/icons/Create'
import Countdown, { CountdownRenderProps, zeroPad } from 'react-countdown'
import { Props } from './types'

export const TimerWIthRefreshModal = ({
  testIdPrefix,
  autoRefreshTimer,
  timerId,
}: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const [isTimeOutModalVisible, setIsTimeOutModalVisible] = useState<boolean>(false)

  const handleSetIsTimeOutModalVisible = (bool: boolean): void => {
    setIsTimeOutModalVisible(bool)
  }

  const [time, setTime] = useState({ hours: '', minutes: '' })
  const [keyOfTimer, setKeyOfTimer] = useState(0)

  // tmp
  const [isGettingFilteredData, setIsGettingFilteredData] = useState(false)

  const classes = useClasses()
  const dateForTimer =
    ((time.hours ? +time.hours * 3600 : 0) + (time.minutes ? +time.minutes * 60 : 0)) * 1000 ||
    autoRefreshTimer.dateForTimer

  const handleSetTimer = useCallback((dateForTimer, isShowCountdown): void => {
    dispatch(
      setRefreshTimout({
        isShowCountdown,
        selectedTimeInterval: Date.now() + dateForTimer,
        dateForTimer,
        widgetId: timerId,
      }),
    )
  }, [])

  const handleSetTimerClick = useCallback(() => {
    handleSetTimer(dateForTimer, true)
    setTime({ hours: '', minutes: '' })
  }, [dateForTimer, time, setTime])

  const handleHoursChange = useCallback((event: ChangeEvent<{ value: any }>): void => {
    setTime(prevState => ({ ...prevState, hours: event.target.value }))
  }, [])

  const handleMinutesChange = useCallback((event: ChangeEvent<{ value: any }>): void => {
    setTime(prevState => ({ ...prevState, minutes: event.target.value }))
  }, [])

  const handleTimerComplete = useCallback(
    (values: any): (() => void) => (): void => {
      // tmp
      setIsGettingFilteredData(true)
      window.setTimeout(() => {
        setIsGettingFilteredData(false)
      }, 2000)
    },
    [],
  )

  const handleOnComplete = useCallback(() => {
    handleTimerComplete({})

    // restart timer
    setKeyOfTimer(keyOfTimer + 1)
    dispatch(getChartDataRequest())
    dispatch(getChartExtendedDataRequest())
    dispatch(getAllDevices())
    handleSetTimer(dateForTimer, true)
  }, [keyOfTimer, dateForTimer])

  const handleClose = useCallback(() => {
    setTime({ hours: '', minutes: '' })
    handleSetIsTimeOutModalVisible(false)
  }, [setTime])

  const countDownRenderer = ({
    hours,
    minutes,
    seconds,
  }: CountdownRenderProps): React.ReactElement => {
    return (
      <span className={classes.activeTimerTitle}>
        {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    )
  }

  return (
    <>
      {autoRefreshTimer.isShowCountdown ? (
        <Countdown
          key={`${keyOfTimer}`}
          date={autoRefreshTimer.selectedTimeInterval}
          renderer={countDownRenderer}
          onComplete={handleOnComplete}
        />
      ) : (
        <span className={classes.disableTimerTitle}>00:00:00</span>
      )}
      <CreateIcon
        className={autoRefreshTimer.isShowCountdown ? classes.CreateIconActive : classes.CreateIcon}
        onClick={(): void => handleSetIsTimeOutModalVisible(true)}
      />
      {isTimeOutModalVisible && (
        <RefreshTImeOutModal
          open={isTimeOutModalVisible}
          testIdPrefix={testIdPrefix}
          handleClose={handleClose}
          hours={time.hours}
          minutes={time.minutes}
          handleHoursChange={handleHoursChange}
          handleMinutesChange={handleMinutesChange}
          handleSetTimerClick={handleSetTimerClick}
        />
      )}
    </>
  )
}
