import { Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { FieldProps } from 'formik'
import React, { ChangeEvent, useCallback } from 'react'

import { Checkbox } from '@/components/controls/Checkbox'

import { useClasses } from './styles'
import { Props } from './types'

export const CheckboxGroup = ({
  value,
  options,
  label,
  onChange,
  isRequired,
  width,
  disableRipple,
  withClasses,
}: Props): React.ReactElement => {
  const propsStyle = { width }
  const classes = useClasses(propsStyle)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      if (onChange && value) {
        const checkboxValue = e.currentTarget.value
        if (value.includes(checkboxValue)) {
          onChange(value.filter(val => val !== checkboxValue))
        } else {
          onChange([...value, checkboxValue])
        }
      }
    },
    [onChange, value],
  )

  return (
    <Grid container className={classes.widthBlock}>
      {label && (
        <Grid item md={12}>
          <Typography className={clsx(classes.title, withClasses?.label)} variant="body1">
            {label}
            {isRequired && <span className={classes.asterisk}>*</span>}
          </Typography>
        </Grid>
      )}
      <Grid container className={clsx({ [classes.wrap]: !withClasses?.items }, withClasses?.items)}>
        {options.map(({ value: val, name }) => (
          <Grid key={val} item className={clsx(classes.row, withClasses?.row)}>
            <Checkbox
              className={classes.checkbox}
              onChange={handleChange}
              value={val}
              label={name}
              checked={value ? value.includes(val) : undefined}
              disableRipple={disableRipple}
              classes={{
                root: withClasses?.checkbox,
                checked: withClasses?.checked,
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export const createCheckboxGroupField = (
  props: Props,
): ((field: FieldProps) => React.ReactElement) => ({
  field: { value, name },
  form: { getFieldHelpers },
}): React.ReactElement => {
  const onChange = getFieldHelpers(name).setValue

  return <CheckboxGroup {...props} value={value} onChange={onChange} />
}
