import Car from '@/assets/usm/mergeSecurityNetworksModalIcons/Car.svg'
import Company from '@/assets/usm/mergeSecurityNetworksModalIcons/Company.svg'
import CompanyPers from '@/assets/usm/mergeSecurityNetworksModalIcons/Company-Personnel.svg'
import Department from '@/assets/usm/mergeSecurityNetworksModalIcons/Department.svg'
import Teminal from '@/assets/usm/mergeSecurityNetworksModalIcons/Device.svg'
import DeviceFolderGroup from '@/assets/usm/mergeSecurityNetworksModalIcons/DeviceFolderGroup.svg'
import Holder from '@/assets/usm/mergeSecurityNetworksModalIcons/Repair-Fund-Holder.svg'
import User from '@/assets/usm/mergeSecurityNetworksModalIcons/User.svg'
import UserGroup from '@/assets/usm/mergeSecurityNetworksModalIcons/User-Group.svg'

export const mergeSecurityNetworksIcons = {
  '1': Teminal,
  '2': Company,
  '3': Car,
  '4': CompanyPers,
  '5': Department,
  '7': UserGroup,
  '8': User,
  '9': Holder,
  '11': DeviceFolderGroup,
}
