import React from 'react'

import { useFormatNumber } from '@/utils/internalization'

import { CurrencySumsProps } from './types'

export const CurrencySums: React.FC<CurrencySumsProps> = React.memo((props: CurrencySumsProps) => {
  const { sums } = props
  const { formatNumber } = useFormatNumber()

  return (
    <>
      {sums.reduce(
        (acc, item, index) =>
          `${acc}${index !== 0 ? ';' : ''} ${formatNumber(item.sum)} ${item.currency}`,
        '',
      )}
    </>
  )
})
