import moment from 'moment'
import { AnyAction } from 'redux'

import {
  Currency,
  IStageStatus,
  Order,
  OrderCash,
  OrderCreationDenomination,
  OrderReportGeneratorEntry,
  OrdersFilter,
  OrderStage,
  OrderValuable,
  OrderVault,
  SelectorItem,
  WorkOrderSeals,
  WorkOrdersResponse,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { StageRequestParams } from '@/components/pages/vault-v1/CashOrdersPage/components/panels/OrderStages/types'
import { VaultV1Actions } from '@/constants'
import { ordVaultsTableId as tableId } from '@/constants/vault-v1/tableIds'
import { getTableConfig } from '@/helpers/tableLocalStorage'
import { SelectableItem } from '@/types'

import { CheckedWorkOrdersProps, GroupedWorkOrdersProps, WorkOrdersProps } from './types'

export type State = {
  orders: {
    isLoading: boolean
    isLoaded: boolean
    data: Order[]
    total: number
    errorMessage?: string
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined

    newOrderId: string

    selectedOrder: Order | null

    canCreateCashOrder: boolean
    canCreateExportOrder: boolean

    filter: OrdersFilter

    paging: {
      page: number
      pageSize: number
    }
    isColumnModalOpen: boolean
    isDocumentModalOpen: boolean
    isFiltersModalOpen: boolean

    isCashOrderModal: {
      isOpen: boolean
      isEdit: boolean
    }

    isExportOrderModal: {
      isOpen: boolean
      isEdit: boolean
    }

    reports: {
      errorMessage?: string
      isLoading: boolean
      list: OrderReportGeneratorEntry[]
      selectedReport: {
        isLoading: boolean
        errorMessage?: string
        bytes?: string | null // Blob in TS has no arrayBuffer method
      }
    }
    isWorkingWithOrder: boolean
  }
  filterOptions: {
    currencies: {
      data: Currency[]
      isLoading: boolean
    }
    objectIds: {
      data: SelectorItem[]
      isLoading: boolean
    }
    teams: {
      data: SelectorItem[]
      isLoading: boolean
    }
    types: {
      data: SelectableItem[]
      isLoading: boolean
    }
    statuses: {
      data: SelectableItem[]
      isLoading: boolean
    }
  }
  orderStages: {
    data: OrderStage[]
    isLoading: boolean
    isLoaded: boolean
    error: string | null
    isStageApplying: boolean
    isStageDocModalOpen: boolean

    reports: {
      errorMessage?: string
      isLoading: boolean
      list: OrderReportGeneratorEntry[]
      selectedReport: {
        isLoading: boolean
        errorMessage?: string
        bytes?: string | null // Blob in TS has no arrayBuffer method
      }
    }

    cashierIdRequest: {
      isLoading: boolean
      result: boolean | undefined
    }

    stageRequestParams: StageRequestParams | null
    isDecliningModalOpen: boolean
    isTeamAssignModalOpen: boolean
    isSetBagSealsModalOpen: boolean
    isWorkOrderModal: boolean
    isCashierIdModalOpen: boolean
  }

  destinations: {
    data: OrderVault[]
    selected: string
    isLoading: boolean
  }
  sources: {
    data: OrderVault[]
    selected: string
    isLoading: boolean
  }
  cashOrderCurrency: {
    data: Currency[]
    selected: string | number
    isLoading: boolean
  }
  denominations: {
    data: {
      [key: string]: OrderCreationDenomination[]
    }
    isLoading: boolean
  }
  orderDate: string

  bagSeals: {
    data: WorkOrderSeals[]
    isLoading: boolean
    isLoaded: boolean
    error: string | null
  }

  workOrders: {
    dates: string[]
    selectedDate: string
    isLoading: boolean
    isLoaded: boolean
    isApplying: boolean
  }
  groupedWorkOrders: {
    data: GroupedWorkOrdersProps | null
    checkedGropedWO: CheckedWorkOrdersProps
  }
  exportWorkOrders: {
    data: WorkOrdersProps | null
    checkedExportWO: CheckedWorkOrdersProps
  }

  valuables: OrderValuable[]

  stageIntegration: {
    statuses: IStageStatus[] | null

    error: string | null

    retryStageId: string | null
  }
}

export const initialState: State = {
  orders: {
    isLoading: false,
    isLoaded: false,
    data: [],
    total: 0,
    errorMessage: '',
    sortColumn: 'date',
    sortOrder: 'desc',

    newOrderId: '',

    selectedOrder: null,

    canCreateCashOrder: false,
    canCreateExportOrder: false,

    filter: {
      orderTypes: undefined,
      orderStatus: undefined,
      orderStatuses: undefined,
      currency: undefined,
      objectId: undefined,
      teamId: undefined,
      cashOrderId: undefined,
      workOrderId: undefined,
      fromDate: moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format(),
      toDate: undefined,
      showOnlyApprovable: false,
      senderId: undefined,
      receiverId: undefined,
      valuableTypeId: undefined,
    },

    paging: {
      page: 1,
      pageSize: getTableConfig({ tableId })?.rowPerPage || 50,
    },

    isColumnModalOpen: false,
    isDocumentModalOpen: false,
    isFiltersModalOpen: false,

    isCashOrderModal: {
      isOpen: false,
      isEdit: false,
    },

    isExportOrderModal: {
      isOpen: false,
      isEdit: false,
    },

    isWorkingWithOrder: false,

    reports: {
      errorMessage: '',
      isLoading: false,
      list: [],
      selectedReport: {
        isLoading: false,
        errorMessage: '',
        bytes: undefined,
      },
    },
  },

  filterOptions: {
    currencies: {
      data: [],
      isLoading: false,
    },
    objectIds: {
      data: [],
      isLoading: false,
    },
    teams: {
      data: [],
      isLoading: false,
    },
    types: {
      data: [],
      isLoading: false,
    },
    statuses: {
      data: [],
      isLoading: false,
    },
  },

  orderStages: {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
    isStageApplying: false,
    isStageDocModalOpen: false,

    reports: {
      errorMessage: '',
      isLoading: false,
      list: [],
      selectedReport: {
        isLoading: false,
        errorMessage: '',
        bytes: undefined,
      },
    },

    cashierIdRequest: {
      isLoading: false,
      result: undefined,
    },

    stageRequestParams: null,

    isDecliningModalOpen: false,
    isTeamAssignModalOpen: false,
    isSetBagSealsModalOpen: false,
    isWorkOrderModal: false,
    isCashierIdModalOpen: false,
  },

  destinations: {
    data: [],
    selected: '',
    isLoading: false,
  },
  sources: {
    data: [],
    selected: '',
    isLoading: false,
  },
  cashOrderCurrency: {
    data: [],
    selected: '',
    isLoading: false,
  },
  denominations: {
    data: {},
    isLoading: false,
  },
  orderDate: moment().format(),

  bagSeals: {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  workOrders: {
    dates: [],
    selectedDate: '',
    isLoading: false,
    isLoaded: false,
    isApplying: false,
  },
  groupedWorkOrders: {
    data: null,
    checkedGropedWO: {},
  },
  exportWorkOrders: {
    data: null,
    checkedExportWO: {},
  },

  valuables: [],

  stageIntegration: {
    statuses: null,

    error: null,

    retryStageId: null,
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case VaultV1Actions.SetGlobalFilter:
      return {
        ...state,
        orders: {
          ...state.orders,
          paging: {
            ...state.orders.paging,
            page: 1,
          },
        },
      }
    case VaultV1Actions.OrdSetOrdersFilter:
      return {
        ...state,
        orders: {
          ...state.orders,
          filter: {
            ...state.orders.filter,
            ...payload,
          },
        },
      }
    case VaultV1Actions.OrdRefreshOrders:
    case VaultV1Actions.OrdGetOrdersRequest:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: true,
          errorMessage: initialState?.orders?.errorMessage || '',
        },
      }
    case VaultV1Actions.OrdGetOrdersResponse:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
          isLoaded: true,
          data: payload.orders,
          canCreateCashOrder: payload.canCreateCashOrder,
          canCreateExportOrder: payload.canCreateExportOrder,
          total: payload.total,
          errorMessage: '',
        },
      }
    case VaultV1Actions.OrdGetOrdersFail:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
          isLoaded: false,
          data: [],
          total: 0,
          errorMessage: payload,
        },
      }

    case VaultV1Actions.OrdGetSingleVaultRequest:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: true,
        },
      }

    case VaultV1Actions.OrdGetSingleVaultResponse: {
      const newData = [...state.orders.data]
      const refreshedOrderIndex = newData.findIndex(el => el?.id === payload?.id)

      if (refreshedOrderIndex !== undefined && refreshedOrderIndex !== null) {
        newData[refreshedOrderIndex] = { ...payload }

        return {
          ...state,
          orders: {
            ...state.orders,
            data: newData,
            isLoading: false,
            selectedOrder:
              payload.id === state.orders.selectedOrder?.id ? payload : state.orders.selectedOrder,
          },
          orderStages: {
            ...state.orderStages,
            isLoading: true,
          },
        }
      }
      return state
    }

    case VaultV1Actions.OrdGetSingleVaultFail:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
        },
      }

    case VaultV1Actions.OrdApproveAllRequest:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: true,
        },
      }

    case VaultV1Actions.OrdApproveAllFail:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
        },
      }

    case VaultV1Actions.OrdSetOrdersPaging:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: true,
          paging: {
            ...state.orders.paging,
            ...payload,
          },
        },
      }
    case VaultV1Actions.OrdResetOrdersPaging:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: true,
          paging: {
            ...state.orders.paging,
            page: initialState.orders.paging.page || 1,
          },
        },
      }

    case VaultV1Actions.OrdSortOrdersTable:
      return {
        ...state,
        orders: {
          ...state.orders,
          ...payload,
          isLoading: true,
        },
      }

    // [START] Modals ----------------------------------------------------
    case VaultV1Actions.OrdOpenColumnsSettingsModal:
      return {
        ...state,
        orders: {
          ...state.orders,
          isColumnModalOpen: payload,
        },
      }
    case VaultV1Actions.OrdOpenDocumentsModal:
      return {
        ...state,
        orders: {
          ...state.orders,
          isDocumentModalOpen: payload,
        },
      }

    case VaultV1Actions.OrdOpenFilterModal:
      return {
        ...state,
        orders: {
          ...state.orders,
          isFiltersModalOpen: payload,
          reports: payload === false ? initialState.orders.reports : state.orders.reports,
        },
      }
    case VaultV1Actions.OrdOpenStageDocumentsModal:
      return {
        ...state,
        orderStages: {
          ...state.orderStages,
          isStageDocModalOpen: payload,
        },
      }

    // [END] Modals ----------------------------------------------------

    case VaultV1Actions.OrdSetSelectedOrder:
      return {
        ...state,
        orders: {
          ...state.orders,
          selectedOrder: payload,
          newOrderId: '',
        },
        orderStages: {
          ...state.orderStages,
          isLoading: true,
        },
      }

    case VaultV1Actions.OrdGetOrdersStagesResponse:
      return {
        ...state,
        orderStages: {
          ...state.orderStages,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      }
    case VaultV1Actions.OrdGetOrdersStagesFail:
      return {
        ...state,
        orderStages: {
          ...state.orderStages,
          isLoading: false,
          isLoaded: true,
          error: payload,
        },
      }

    case VaultV1Actions.OrdSetOrdersStagesRequest:
    case VaultV1Actions.SetOrdBagSealRequest:
      return {
        ...state,
        orderStages: {
          ...state.orderStages,
          isStageApplying: true,
        },
      }
    case VaultV1Actions.OrdSetOrdersStagesResponse:
    case VaultV1Actions.OrdSetOrdersStagesFail:
    case VaultV1Actions.SetOrdBagSealResponse:
    case VaultV1Actions.SetOrdBagSealFail:
      return {
        ...state,
        orderStages: {
          ...state.orderStages,
          isStageApplying: false,
          isSetBagSealsModalOpen: false,
        },
      }

    // [START] Reports ----------------------------------------------
    case VaultV1Actions.GetOrdReportsRequest:
      return {
        ...state,
        orders: {
          ...state.orders,
          reports: {
            ...state.orders.reports,
            isLoading: true,
            list: [],
            errorMessage: '',
            selectedReport: initialState.orders.reports.selectedReport,
          },
        },
      }
    case VaultV1Actions.GetOrdReportsResponse:
      return {
        ...state,
        orders: {
          ...state.orders,
          reports: {
            ...state.orders.reports,
            isLoading: false,
            list: payload,
            errorMessage: '',
          },
        },
      }
    case VaultV1Actions.GetOrdReportsFail:
      return {
        ...state,
        orders: {
          ...state.orders,
          reports: {
            ...state.orders.reports,
            isLoading: false,
            list: [],
            errorMessage: payload,
          },
        },
      }

    case VaultV1Actions.GetOrdReportRequest:
      return {
        ...state,
        orders: {
          ...state.orders,
          reports: {
            ...state.orders.reports,
            selectedReport: {
              ...state.orders.reports.selectedReport,
              isLoading: true,
              errorMessage: '',
              bytes: undefined,
            },
          },
        },
      }
    case VaultV1Actions.GetOrdReportResponse:
      return {
        ...state,
        orders: {
          ...state.orders,
          reports: {
            ...state.orders.reports,
            selectedReport: {
              ...state.orders.reports.selectedReport,
              isLoading: false,
              errorMessage: '',
              bytes: payload,
            },
          },
        },
      }
    case VaultV1Actions.GetOrdReportFail:
      return {
        ...state,
        orders: {
          ...state.orders,
          reports: {
            ...state.orders.reports,
            selectedReport: {
              ...state.orders.reports.selectedReport,
              isLoading: false,
              errorMessage: payload,
              bytes: undefined,
            },
          },
        },
      }
    // [END] Reports ----------------------------------------------

    // [START] Stage Reports ----------------------------------------------
    case VaultV1Actions.GetOrdStageReportsRequest:
      return {
        ...state,
        orderStages: {
          ...state.orderStages,
          reports: {
            ...state.orderStages.reports,
            isLoading: true,
            list: [],
            errorMessage: '',
            selectedReport: initialState.orderStages.reports.selectedReport,
          },
        },
      }
    case VaultV1Actions.GetOrdStageReportsResponse:
      return {
        ...state,
        orderStages: {
          ...state.orderStages,
          reports: {
            ...state.orderStages.reports,
            isLoading: false,
            list: payload,
            errorMessage: '',
          },
        },
      }
    case VaultV1Actions.GetOrdStageReportsFail:
      return {
        ...state,
        orderStages: {
          ...state.orderStages,
          reports: {
            ...state.orderStages.reports,
            isLoading: false,
            list: [],
            errorMessage: payload,
          },
        },
      }

    case VaultV1Actions.GetOrdStageReportRequest:
      return {
        ...state,
        orderStages: {
          ...state.orderStages,
          reports: {
            ...state.orderStages.reports,
            selectedReport: {
              ...state.orderStages.reports.selectedReport,
              isLoading: true,
              errorMessage: '',
              bytes: undefined,
            },
          },
        },
      }
    case VaultV1Actions.GetOrdStageReportResponse:
      return {
        ...state,
        orderStages: {
          ...state.orderStages,
          reports: {
            ...state.orderStages.reports,
            selectedReport: {
              ...state.orderStages.reports.selectedReport,
              isLoading: false,
              errorMessage: '',
              bytes: payload,
            },
          },
        },
      }
    case VaultV1Actions.GetOrdStageReportFail:
      return {
        ...state,
        orderStages: {
          ...state.orderStages,
          reports: {
            ...state.orderStages.reports,
            selectedReport: {
              ...state.orderStages.reports.selectedReport,
              isLoading: false,
              errorMessage: payload,
              bytes: undefined,
            },
          },
        },
      }
    // [END] Stage Reports ----------------------------------------------

    // [START] Delivery Order ----------------------------------------------

    // Modals
    case VaultV1Actions.OrdOpenCashOrderModal:
      return {
        ...state,
        orders: {
          ...state.orders,
          isCashOrderModal: {
            ...state.orders.isCashOrderModal,
            ...payload,
          },
        },
      }

    case VaultV1Actions.OrdOpenExportOrderModal:
      return {
        ...state,
        orders: {
          ...state.orders,
          isExportOrderModal: {
            ...state.orders.isExportOrderModal,
            ...payload,
          },
        },
      }

    // Destinations
    case VaultV1Actions.GetOrdDestinationsRequest:
    case VaultV1Actions.GetOrdExpDestinationsRequest:
      return {
        ...state,
        destinations: {
          ...state.destinations,
          isLoading: true,
        },
      }
    case VaultV1Actions.GetOrdDestinationsResponse:
      return {
        ...state,
        destinations: {
          ...state.destinations,
          isLoading: false,
          data: payload,
          selected: payload[0].vaultId,
        },
      }
    case VaultV1Actions.GetOrdDestinationsFail:
      return {
        ...state,
        destinations: {
          ...state.destinations,
          isLoading: false,
        },
      }

    // Sources
    case VaultV1Actions.GetOrdSourcesRequest:
    case VaultV1Actions.GetOrdExpSourcesRequest:
      return {
        ...state,
        sources: {
          ...state.sources,
          isLoading: true,
        },
      }
    case VaultV1Actions.GetOrdSourcesResponse:
      return {
        ...state,
        sources: {
          ...state.sources,
          isLoading: false,
          data: payload,
          selected: payload[0].vaultId,
        },
      }
    case VaultV1Actions.GetOrdSourcesFail:
      return {
        ...state,
        sources: {
          ...state.sources,
          isLoading: false,
        },
      }

    // Denominations
    case VaultV1Actions.GetOrdDenominationsRequest:
    case VaultV1Actions.GetOrdExpDenominationsRequest:
      return {
        ...state,
        cashOrderCurrency: {
          ...state.cashOrderCurrency,
          isLoading: true,
        },
        denominations: {
          ...state.denominations,
          isLoading: true,
          data: {},
        },
      }
    case VaultV1Actions.GetOrdDenominationsResponse: {
      const response: OrderCash[] = payload
      let localDenominations = {}

      const currencies = response.reduce(
        (acc: Currency[], item) => [
          ...acc,
          {
            id: item.currency.id,
            name: item.currency.name,
            isBase: item.currency.isBase || false,
          },
        ],
        [],
      )

      response.forEach(({ currency, denominations }) => {
        localDenominations = { ...localDenominations, [currency.id]: denominations }
      })

      return {
        ...state,
        cashOrderCurrency: {
          ...state.cashOrderCurrency,
          isLoading: false,
          data: currencies,
          selected: `${(currencies || []).find(currency => currency?.isBase)?.id ||
            currencies[0].id}`,
        },
        denominations: {
          ...state.denominations,
          isLoading: false,
          data: localDenominations,
        },
      }
    }
    case VaultV1Actions.GetOrdDenominationsFail:
      return {
        ...state,
        cashOrderCurrency: {
          ...state.cashOrderCurrency,
          isLoading: false,
        },
        denominations: {
          ...state.denominations,
          isLoading: false,
          data: {},
        },
      }
    // [END] Delivery Order ----------------------------------------------

    // Set selected
    case VaultV1Actions.SetOrdSelectedDestination:
      return {
        ...state,
        destinations: {
          ...state.destinations,
          selected: payload,
        },
      }
    case VaultV1Actions.SetOrdSelectedSours:
      return {
        ...state,
        sources: {
          ...state.sources,
          selected: payload,
        },
      }
    case VaultV1Actions.SetOrdSelectedCashCurrency:
      return {
        ...state,
        cashOrderCurrency: {
          ...state.cashOrderCurrency,
          selected: payload,
        },
      }
    case VaultV1Actions.SetOrdOrderDate:
      return {
        ...state,
        orderDate: payload,
      }

    case VaultV1Actions.EditOrdCashOrderRequest:
    case VaultV1Actions.AddOrdCashOrderRequest:
      return {
        ...state,
        orders: {
          ...state.orders,
          newOrderId: '',
          isWorkingWithOrder: true,
        },
      }

    case VaultV1Actions.AddOrdCashOrderResponse:
    case VaultV1Actions.EditOrdCashOrderResponse:
      return {
        ...state,
        orders: {
          ...state.orders,
          isWorkingWithOrder: false,
          newOrderId: payload,
          isCashOrderModal: {
            ...state.orders.isCashOrderModal,
            isOpen: false,
            isEdit: false,
          },
          isExportOrderModal: {
            ...state.orders.isExportOrderModal,
            isOpen: false,
            isEdit: false,
          },
        },
      }
    case VaultV1Actions.AddOrdCashOrderFail:
    case VaultV1Actions.EditOrdCashOrderFail:
      return {
        ...state,
        orders: {
          ...state.orders,
          isWorkingWithOrder: false,
          newOrderId: '',
          isCashOrderModal: {
            ...state.orders.isCashOrderModal,
            isOpen: false,
            isEdit: false,
          },
          isExportOrderModal: {
            ...state.orders.isExportOrderModal,
            isOpen: false,
            isEdit: false,
          },
        },
      }

    case VaultV1Actions.GetOrdValuablesResponse:
      return {
        ...state,
        valuables: payload,
      }
    case VaultV1Actions.GetOrdValuablesFail:
      return {
        ...state,
        valuables: [],
      }

    case VaultV1Actions.OrdGetFilterCurrencyRequest:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          currencies: {
            ...state.filterOptions.currencies,
            isLoading: true,
          },
        },
      }
    case VaultV1Actions.OrdGetFilterCurrencyResponse:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          currencies: {
            ...state.filterOptions.currencies,
            data: payload,
            isLoading: false,
          },
        },
      }

    case VaultV1Actions.OrdGetFilterObjectIdRequest:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          objectIds: {
            ...state.filterOptions.objectIds,
            isLoading: true,
          },
        },
      }
    case VaultV1Actions.OrdGetFilterObjectIdResponse:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          objectIds: {
            ...state.filterOptions.objectIds,
            data: payload,
            isLoading: false,
          },
        },
      }

    case VaultV1Actions.OrdGetFilterTeamRequest:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          teams: {
            ...state.filterOptions.teams,
            isLoading: true,
          },
        },
      }
    case VaultV1Actions.OrdGetFilterTeamResponse:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          teams: {
            ...state.filterOptions.teams,
            data: payload,
            isLoading: false,
          },
        },
      }

    case VaultV1Actions.OrdGetFilterTypeRequest:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          types: {
            ...state.filterOptions.types,
            isLoading: true,
          },
        },
      }
    case VaultV1Actions.OrdGetFilterTypeResponse:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          types: {
            ...state.filterOptions.types,
            data: payload,
            isLoading: false,
          },
        },
      }

    case VaultV1Actions.OrdGetFilterStatusRequest:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          statuses: {
            ...state.filterOptions.statuses,
            isLoading: true,
          },
        },
      }
    case VaultV1Actions.OrdGetFilterStatusResponse:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          statuses: {
            ...state.filterOptions.statuses,
            data: payload,
            isLoading: false,
          },
        },
      }

    case VaultV1Actions.SetOrdDeclineModalOpen:
      return {
        ...state,
        orderStages: {
          ...state.orderStages,
          isDecliningModalOpen: payload.isOpen,
          stageRequestParams: payload.requestParams || null,
        },
      }
    case VaultV1Actions.SetOrdTeamAssignModalOpen:
      return {
        ...state,
        orderStages: {
          ...state.orderStages,
          isTeamAssignModalOpen: payload.isOpen,
          stageRequestParams: payload.requestParams || null,
        },
      }
    case VaultV1Actions.SetOrdBagSealsModalOpen:
      return {
        ...state,
        orderStages: {
          ...state.orderStages,
          isSetBagSealsModalOpen: payload.isOpen,
          stageRequestParams: payload.requestParams || null,
        },
      }
    case VaultV1Actions.SetOrdCashierIdModalOpen:
      return {
        ...state,
        orderStages: {
          ...state.orderStages,
          isCashierIdModalOpen: payload.isOpen,
          stageRequestParams: payload.requestParams || null,
        },
      }
    case VaultV1Actions.SetOrdCashierIdRequest:
      return {
        ...state,
        orderStages: {
          ...state.orderStages,
          cashierIdRequest: {
            ...state.orderStages.cashierIdRequest,
            isLoading: true,
          },
        },
      }
    case VaultV1Actions.SetOrdCashierIdResponse:
      return {
        ...state,
        orderStages: {
          ...state.orderStages,
          cashierIdRequest: {
            ...state.orderStages.cashierIdRequest,
            isLoading: false,
            result: payload,
          },
        },
      }
    case VaultV1Actions.SetOrdCashierIdClear:
      return {
        ...state,
        orderStages: {
          ...state.orderStages,
          cashierIdRequest: initialState.orderStages.cashierIdRequest,
        },
      }

    case VaultV1Actions.GetOrdBagSealRequest:
      return {
        ...state,
        bagSeals: {
          ...state.bagSeals,
          isLoading: true,
        },
      }

    case VaultV1Actions.GetOrdBagSealResponse:
      return {
        ...state,
        bagSeals: {
          ...state.bagSeals,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      }
    case VaultV1Actions.GetOrdBagSealFail:
      return {
        ...state,
        bagSeals: {
          ...state.bagSeals,
          isLoading: false,
          isLoaded: true,
          error: payload,
        },
      }
    // [START] Work Order ---------------------------------------------------------
    case VaultV1Actions.OrdSetWorkOrderModalOpen:
      return {
        ...state,
        orderStages: {
          ...state.orderStages,
          isWorkOrderModal: payload.isOpen,
          stageRequestParams: payload.requestParams || null,
        },
      }

    case VaultV1Actions.GetOrdWorkOrderRequest:
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          isLoading: true,
        },
      }
    case VaultV1Actions.GetOrdWorkOrderResponse: {
      const { workOrders }: WorkOrdersResponse = payload

      const groupedWO = {} as GroupedWorkOrdersProps
      const exportWO = {} as WorkOrdersProps

      let checkedGropedWO = {} as CheckedWorkOrdersProps
      let checkedWO = {} as CheckedWorkOrdersProps

      const dates = (workOrders || []).map(({ date, groupedWorkOrders, exportOrders }) => {
        if (date && groupedWorkOrders) {
          groupedWO[date] = groupedWorkOrders
        }
        if (date && exportOrders) {
          exportWO[date] = exportOrders
        }
        return date || ''
      })

      if (groupedWO[dates[0]]) {
        groupedWO[dates[0]].map(groupedOrder =>
          groupedOrder.workOrders?.map(workOrder => {
            checkedGropedWO[workOrder.id] = workOrder
          }),
        )
      } else {
        checkedGropedWO = {}
      }

      if (exportWO[dates[0]]) {
        exportWO[dates[0]].map(exportOrder => (checkedWO[exportOrder.id] = exportOrder))
      } else {
        checkedWO = {}
      }

      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          dates,
          selectedDate: dates[0],
          isLoading: false,
          isLoaded: true,
        },
        groupedWorkOrders: {
          ...state.groupedWorkOrders,
          data: groupedWO,
          checkedGropedWO: checkedGropedWO,
        },
        exportWorkOrders: {
          data: exportWO,
          checkedExportWO: checkedWO,
        },
      }
    }

    case VaultV1Actions.GetOrdWorkOrderFail:
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          isLoading: false,
          isLoaded: true,
        },
      }
    case VaultV1Actions.OrdSetSelectedWorkOrder: {
      const newSelectedDate = payload as string
      const groupedWO = { ...state.groupedWorkOrders.data } as GroupedWorkOrdersProps
      const exportWO = { ...state.exportWorkOrders.data } as WorkOrdersProps

      let checkedGropedWO = {} as CheckedWorkOrdersProps
      let checkedWO = {} as CheckedWorkOrdersProps

      if (groupedWO && newSelectedDate && groupedWO[newSelectedDate]) {
        groupedWO[newSelectedDate].map(groupedOrder =>
          groupedOrder.workOrders?.map(workOrder => {
            if (workOrder.id) {
              checkedGropedWO[workOrder.id] = workOrder
            }
          }),
        )
      } else {
        checkedGropedWO = {}
      }
      if (exportWO && newSelectedDate && exportWO[newSelectedDate]) {
        exportWO[newSelectedDate].map(exportOrder => {
          if (exportOrder.id) {
            checkedWO[exportOrder.id] = exportOrder
          }
        })
      } else {
        checkedWO = {}
      }

      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          selectedDate: payload,
        },
        groupedWorkOrders: {
          ...state.groupedWorkOrders,
          checkedGropedWO: checkedGropedWO,
        },
        exportWorkOrders: {
          data: exportWO,
          checkedExportWO: checkedWO,
        },
      }
    }
    case VaultV1Actions.OrdSetCheckedAllGroupedWO: {
      const { selectedDate } = state.workOrders
      const groupedWO = { ...state.groupedWorkOrders.data } as GroupedWorkOrdersProps

      let checkedGropedWO = {} as CheckedWorkOrdersProps

      if (groupedWO && selectedDate && groupedWO[selectedDate]) {
        groupedWO[selectedDate].map(groupedOrder =>
          groupedOrder.workOrders?.map(workOrder => {
            if (workOrder.id) {
              checkedGropedWO[workOrder.id] = workOrder
            }
          }),
        )
      } else {
        checkedGropedWO = {}
      }

      return {
        ...state,
        groupedWorkOrders: {
          ...state.groupedWorkOrders,
          checkedGropedWO: payload ? checkedGropedWO : {},
        },
      }
    }
    case VaultV1Actions.OrdSetCheckedGroupedWO: {
      const checked = { ...state.groupedWorkOrders.checkedGropedWO }
      const foundOrder = checked[payload.id]

      if (!foundOrder) {
        checked[payload.id] = payload
      } else {
        delete checked[payload.id]
      }

      return {
        ...state,
        groupedWorkOrders: {
          ...state.groupedWorkOrders,
          checkedGropedWO: checked,
        },
      }
    }

    case VaultV1Actions.OrdSetCheckedAllExportWO: {
      const { selectedDate } = state.workOrders
      const exportWO = { ...state.exportWorkOrders.data } as WorkOrdersProps

      let checkedExportWO = {} as CheckedWorkOrdersProps

      if (exportWO && selectedDate && exportWO[selectedDate]) {
        exportWO[selectedDate].map(exportOrder => {
          if (exportOrder.id) {
            checkedExportWO[exportOrder.id] = exportOrder
          }
        })
      } else {
        checkedExportWO = {}
      }

      return {
        ...state,
        exportWorkOrders: {
          ...state.exportWorkOrders,
          checkedExportWO: payload ? checkedExportWO : {},
        },
      }
    }
    case VaultV1Actions.OrdSetCheckedExportWO: {
      const checked = { ...state.exportWorkOrders.checkedExportWO }
      const foundOrder = checked[payload.id]

      if (!foundOrder) {
        checked[payload.id] = payload
      } else {
        delete checked[payload.id]
      }

      return {
        ...state,
        exportWorkOrders: {
          ...state.exportWorkOrders,
          checkedExportWO: checked,
        },
      }
    }

    case VaultV1Actions.SetOrdWorkOrderRequest:
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          isApplying: true,
        },
      }
    case VaultV1Actions.SetOrdWorkOrderResponse:
    case VaultV1Actions.SetOrdWorkOrderFail:
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          isApplying: false,
        },
      }

    case VaultV1Actions.GetOrdStageIntegrationRequest:
      return {
        ...state,
        stageIntegration: {
          ...state.stageIntegration,
        },
      }

    case VaultV1Actions.GetOrdStageIntegrationResponse:
      return {
        ...state,
        stageIntegration: {
          ...state.stageIntegration,

          statuses: payload.statuses,
        },
      }

    case VaultV1Actions.GetOrdStageIntegrationFail:
      return {
        ...state,
        stageIntegration: {
          ...state.stageIntegration,
          error: payload,
        },
      }

    case VaultV1Actions.RetryOrdStageIntegrationRequest:
      return {
        ...state,
        stageIntegration: {
          ...state.stageIntegration,
          retryStageId: payload,
        },
      }

    case VaultV1Actions.RetryOrdStageIntegrationResponse:
      return {
        ...state,
        stageIntegration: {
          ...state.stageIntegration,
          retryStageId: null,
        },
      }

    case VaultV1Actions.RetryOrdStageIntegrationFail:
      return {
        ...state,
        stageIntegration: {
          ...state.stageIntegration,
          retryStageId: null,
          error: payload,
        },
      }
    // [END] Work Order ---------------------------------------------------------

    case VaultV1Actions.SignOut:
      return initialState
    default:
      return state
  }
}
