export enum AdvancedSearchFields {
  DeviceType = 'deviceType',
  TerminalId = 'terminalId',
  LUNO = 'LUNO',
  Model = 'model',
  Address = 'address',
  Merchant = 'merchant',
  Renter = 'renterCompany',
  ServiceRegion = 'serviceRegion',
  DeviceState = 'deviceState',
  City = 'city',
}

export interface AdvancedSearchValues {
  [AdvancedSearchFields.TerminalId]: string
  [AdvancedSearchFields.DeviceState]: string
  [AdvancedSearchFields.LUNO]: string
  [AdvancedSearchFields.Address]: string
  [AdvancedSearchFields.DeviceType]: string
  [AdvancedSearchFields.Renter]: string
  [AdvancedSearchFields.Merchant]: string
  [AdvancedSearchFields.Model]: string
  [AdvancedSearchFields.ServiceRegion]: string
}

export interface DeviceAdvancedRequest extends AdvancedSearchValues {
  limit: number
}
