import styled from 'styled-components'
export interface Props {
  isIE?: boolean
}

export const AppLogo = styled.img<Props>`
  height: 100%;
  max-height: 100%;
  max-width: 100%;
`
