import { FormikValues } from 'formik'
import { ParametersItems } from '@/types/sd/reports/reports'
import { Dispatch, SetStateAction } from 'react'

export interface Props {
  params: ParametersItems
  formikValues: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  disabled?: boolean
  dynamicDateValue?: { start: string; end: string }
  setDynamicDate?: Dispatch<SetStateAction<{ start: string; end: string }>>
}

export enum FilterTypeDisplayName {
  DATE = 0,
}
