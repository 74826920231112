import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import { initialState, State } from './reducer'

export const getPortalState = (state: AppState): State => state.portal || initialState

export const getOpenTabs = createSelector(getPortalState, state => state.openTabs)

export const getIsInitialTabLoad = createSelector(getPortalState, state => (path: string) => {
  const currentTab = state.openTabs.find(page => page.path === path)
  if (currentTab) {
    return currentTab.isInitialOpen
  }

  return true
})
