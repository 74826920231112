// core
import React, { FC } from 'react'
import { Grid } from '@material-ui/core'
import { Field } from 'formik'
// types
import { LoginField } from '@/components/pages/usm/UsersPage/components/PersonalInformation/types'
import { Props } from './types'
// hooks
import { useTranslate } from '@/utils/internalization'
import { useGenericInput } from '@/components/blocks/USM/hooks'

const LoginFields: FC<Props> = ({ size, isAddNewUser }: Props) => {
  const translate = useTranslate()
  const { GenericInput } = useGenericInput()

  return (
    <>
      <Grid item xs={size}>
        <Field name={LoginField.Login}>
          {GenericInput({
            required: true,
            label: translate('translate#usm.title.add.user.modal.label.login'),
            placeholder: translate('translate#usm.title.add.user.modal.placeholder.login'),
          })}
        </Field>
      </Grid>
      <Grid item xs={size}>
        <Field name={LoginField.Password}>
          {GenericInput({
            autoComplete: 'new-password',
            required: true,
            label: translate('translate#usm.title.add.user.modal.label.password'),
            type: 'password',
            placeholder:
              (isAddNewUser &&
                translate('translate#usm.title.add.user.modal.placeholder.password')) ||
              '******',
          })}
        </Field>
      </Grid>
      <Grid item xs={size}>
        <Field name={LoginField.RepeatPassword}>
          {GenericInput({
            autoComplete: 'repeat-new-password',
            required: true,
            label: translate('translate#usm.title.add.user.modal.repeat.password'),
            type: 'password',
            placeholder:
              (isAddNewUser && translate('translate#usm.title.add.user.modal.repeat.password')) ||
              '******',
          })}
        </Field>
      </Grid>
    </>
  )
}

export { LoginFields }
