import { AnyAction } from 'redux'

import {
  LoadCassetteRequest,
  LoadCassetteResponse,
  UnloadCassetteRequest,
  UnloadCassetteToCashierRequest,
  VaultCassette,
  VaultCassettesResponse,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { CassetteModalNames, TableModalsNames } from '@/components/pages/vault-v1/Cassettes/types'
import { VaultV1CassettesActions } from '@/constants/actions'
import { KeyPressActionName, Paging, Sort } from '@/types/cm'

import { AddEditProps, VaultCassetteFilter } from './types'

// get All
interface GetCassettesProps {
  initialPage?: boolean
}
export const getCassettesRequest = (payload?: GetCassettesProps): AnyAction => ({
  type: VaultV1CassettesActions.CstGetCassettesRequest,
  payload,
})
export const getCassettesResponse = (payload: VaultCassettesResponse): AnyAction => ({
  type: VaultV1CassettesActions.CstGetCassettesResponse,
  payload,
})
export const getCassettesFail = (payload: string): AnyAction => ({
  type: VaultV1CassettesActions.CstGetCassettesFail,
  payload,
})

// get Single
interface GeCassetteRequest {
  id?: string
  isDetailsRequest?: boolean
}
export const getCassetteRequest = (payload?: GeCassetteRequest): AnyAction => ({
  type: VaultV1CassettesActions.CstGetCassetteRequest,
  payload,
})
export const getCassetteResponse = (payload: VaultCassette): AnyAction => ({
  type: VaultV1CassettesActions.CstGetCassetteResponse,
  payload,
})
export const getCassetteFail = (payload: string): AnyAction => ({
  type: VaultV1CassettesActions.CstGetCassetteFail,
  payload,
})

// filter
export const setCstFilter = (payload: VaultCassetteFilter): AnyAction => ({
  type: VaultV1CassettesActions.CstSetFilter,
  payload,
})

export const setSelectedCassette = (payload: VaultCassette | null): AnyAction => ({
  type: VaultV1CassettesActions.CstSetSelectedCassette,
  payload,
})

export const sortCassettes = (payload: Sort): AnyAction => ({
  type: VaultV1CassettesActions.CstSortCassettes,
  payload,
})
export const setCassettePaging = (payload: Paging): AnyAction => ({
  type: VaultV1CassettesActions.CstSetCassettesPaging,
  payload,
})

export const setTableModal = (payload: { type: TableModalsNames; isOpen: boolean }): AnyAction => ({
  type: VaultV1CassettesActions.CstSetTableModal,
  payload,
})
export const setCassetteModalType = (payload?: CassetteModalNames): AnyAction => ({
  type: VaultV1CassettesActions.CstSetCassetteModalType,
  payload,
})

// add & edit
export const addEditCassetteRequest = (payload: AddEditProps): AnyAction => ({
  type: VaultV1CassettesActions.CstAddEditCassetteRequest,
  payload,
})
export const addEditCassetteResponse = (): AnyAction => ({
  type: VaultV1CassettesActions.CstAddEditCassetteResponse,
})

// delete
export const deleteCassetteRequest = (payload: string): AnyAction => ({
  type: VaultV1CassettesActions.CstDeleteCassetteRequest,
  payload,
})
export const deleteCassetteFail = (): AnyAction => ({
  type: VaultV1CassettesActions.CstDeleteCassetteFail,
})

// load
export const loadCassetteRequest = (payload: {
  cassettes: LoadCassetteRequest[]
  isMulti: boolean
  callback?: (isSuccess: boolean) => void
}): AnyAction => ({
  type: VaultV1CassettesActions.CstLoadCassetteRequest,
  payload,
})
export const loadCassetteResponse = (): AnyAction => ({
  type: VaultV1CassettesActions.CstLoadCassetteResponse,
})
export const loadCassetteFail = (payload: LoadCassetteResponse[]): AnyAction => ({
  type: VaultV1CassettesActions.CstLoadCassetteFail,
  payload,
})

export const clearCassettesErrors = (): AnyAction => ({
  type: VaultV1CassettesActions.CstClearCassettesErrors,
})

// unload
export const unloadCassetteRequest = (payload: UnloadCassetteRequest): AnyAction => ({
  type: VaultV1CassettesActions.CstUnloadCassetteRequest,
  payload,
})
export const unloadCassetteResponse = (): AnyAction => ({
  type: VaultV1CassettesActions.CstUnloadCassetteResponse,
})

// edit
export const editCassetteRequest = (payload: LoadCassetteRequest): AnyAction => ({
  type: VaultV1CassettesActions.CstEditCassetteRequest,
  payload,
})
export const editCassetteResponse = (): AnyAction => ({
  type: VaultV1CassettesActions.CstEditCassetteResponse,
})

export const setSearchingCassetteNr = (payload: string): AnyAction => ({
  type: VaultV1CassettesActions.CstSetSearchingCassetteNr,
  payload,
})

// assign cashier
export const unloadToCashierRequest = (payload: UnloadCassetteToCashierRequest): AnyAction => ({
  type: VaultV1CassettesActions.CstUnloadCassetteToCashierRequest,
  payload,
})
export const unloadToCashierResponse = (): AnyAction => ({
  type: VaultV1CassettesActions.CstUnloadCassetteToCashierResponse,
})
export const unloadToCashierFail = (): AnyAction => ({
  type: VaultV1CassettesActions.CstUnloadCassetteToCashierFail,
})

export const handleCstKeyPress = (actionName: KeyPressActionName): AnyAction => ({
  type: VaultV1CassettesActions.CstHandleKeyPress,
  payload: actionName,
})
