import { Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Test from 'react-test-attributes'

import CloseButton from '@/components/blocks/CloseButton/index'
import { TextInput } from '@/components/controls/TextInput/component'
import { useDebounceInput } from '@/components/pages/sd/TicketsPage/helpers/hooks/useDebounceInput'
import { getTestId } from '@/utils'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './style'
import { Props } from './types'

const EditFilterModal = ({
  handleClose,
  classes,
  templateName,
  testIdPrefix,
  onEditTemplate,
  onDeleteTemplate,
}: Props): React.ReactElement => {
  const translate = useTranslate()
  const { inputValue, inputErrors, setInputValue, handleChangeInputValue } = useDebounceInput('')

  const idPrefix = `${testIdPrefix}-edit-filter-modal`
  const testId = getTestId(idPrefix)

  useEffect((): void => {
    setInputValue(templateName)
  }, [templateName])
  const clas = useClasses()

  return (
    <>
      <Test id={testId(0)}>
        <CloseButton absolute className={classes.closeButton} onClick={handleClose} />
      </Test>
      <Test id={testId(1)}>
        <Typography className={classes.subtitle}>
          <FormattedMessage id="title.editTemplate" defaultMessage="Rename template" />
        </Typography>
      </Test>
      <Test id={testId(2)}>
        <TextInput
          value={inputValue}
          onChange={handleChangeInputValue}
          className={classes.input}
          autoFocus
          label={translate('translate#title.enterTemplateName')}
        />
      </Test>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
          <Test id={testId(3)}>
            <Button
              onClick={onDeleteTemplate}
              className={(clas.button, clas.deleteButton)}
              variant="outlined"
            >
              <FormattedMessage id="action.delete" defaultMessage="Delete" />
            </Button>
          </Test>
        </Grid>
        <Grid item>
          <Test id={testId(4)}>
            <Button
              onClick={onEditTemplate(inputValue)}
              className={(classes.button, clas.button)}
              variant="contained"
              disabled={!!inputErrors || !inputValue.length}
            >
              <FormattedMessage id="action.save" defaultMessage="Save" />
            </Button>
          </Test>
        </Grid>
      </Grid>
    </>
  )
}

export default EditFilterModal
