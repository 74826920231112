import queryString from 'query-string'

import {
  AdmNetworkNode,
  AdmNetworkNodeBase,
  AdmNetworkTransceiver,
  AdmNetworkTransceiverFilter,
  AdmNetworkTransceiverRequest,
  AdmNetworkVault,
  AdmNodeObject,
  FilterTreeNode,
  MonVaultAtmsEntry,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { Paging, Sort } from '@/types/cm'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

interface NetworkVaultsProps {
  sort: Sort
  paging: Paging
  vaultName?: string
}

export class NetworksAdminApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getAll(): Promise<FilterTreeNode[]> {
    try {
      return await NetworksAdminApi.requestService.get('/admin/networks/nodes')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getSingle(node: {
    guid: string
    parentId?: string
  }): Promise<AdmNetworkNode> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/networks/node',
          query: { ...node },
        },
        { skipNull: true, encode: false },
      )

      return await NetworksAdminApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(payload: AdmNetworkNodeBase): Promise<void> {
    try {
      return await NetworksAdminApi.requestService.post('/admin/networks/nodes/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(payload: AdmNetworkNode): Promise<void> {
    try {
      return await NetworksAdminApi.requestService.put('/admin/networks/nodes/update', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(node: { guid: string; parentId?: string }): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/networks/nodes/delete',
          query: { ...node },
        },
        { skipNull: true, encode: false },
      )

      return await NetworksAdminApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }

  // Network Vaults
  public static async getNetworkVaults({
    sort,
    paging,
    vaultName,
  }: NetworkVaultsProps): Promise<AdmNetworkVault[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/networks/vaults',
          query: { ...sort, ...paging },
        },
        { skipNull: true, encode: false },
      )

      return await NetworksAdminApi.requestService.post(url, { vaultName })
    } catch (error) {
      throw Error(error)
    }
  }

  public static async addVault(payload: AdmNodeObject): Promise<void> {
    try {
      return await NetworksAdminApi.requestService.post('/admin/networks/vaults/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  // Transceiver Vaults
  public static async getTransceiverVaults({
    filter,
    vaultName,
  }: {
    filter: AdmNetworkTransceiverFilter
    vaultName?: string
  }): Promise<AdmNetworkTransceiver[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/networks/transceiverVaults',
          query: { vaultName },
        },
        { skipNull: true, encode: false },
      )

      return await NetworksAdminApi.requestService.post(url, filter)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async setTransceiverVaults(payload: AdmNetworkTransceiverRequest): Promise<void> {
    try {
      return await NetworksAdminApi.requestService.put(
        '/admin/networks/transceiverVaults/update',
        payload,
      )
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getAssignedVaults({
    id,
    sort,
    paging,
  }: {
    id: string
    sort: Sort
    paging: { page: number; pageSize: string | number }
  }): Promise<MonVaultAtmsEntry> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/vaults/atms',
          query: { guid: id, ...paging, ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await NetworksAdminApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
