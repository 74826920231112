import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  modal: {
    maxWidth: '800px!important',
    width: '90%',
  },
  modalHeader: {
    fontfamily: 'Roboto',
    fontSize: '20px',
    lineHeight: '23px',
    marginBottom: 20,
  },
  paper: {
    padding: spacing(3),
  },
  buildColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px',
  },
  horizontalWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  margin: {
    margin: '16px 0',
  },
  group: {
    display: 'grid',
    gridTemplateColumns: 'minmax(80px, max-content) 1fr',
    gridColumnGap: '10px',
    alignItems: 'start',
    '&:not(:last-child)': {
      marginBottom: '4px',
    },
  },
  groupTitle: {
    fontSize: '14px',
    padding: 0,
  },
  mt: {
    marginTop: '4px',
  },
  bold: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '1.5',
    padding: 0,

    overflow: 'hidden',
  },
  copyright: {
    color: '#929A9B',
  },
}))
