import { TableCell, TableRow } from '@material-ui/core'
import React, { FC } from 'react'

import { useClasses } from './styles'
import { Props } from './types'

export const GroupNameRow: FC<Props> = React.memo((props: Props) => {
  const { fitTableContent, rowColSpan, type, getTranslateGroupName } = props

  const classes = useClasses()

  return (
    <TableRow className={classes.groupTitleRow}>
      <TableCell colSpan={rowColSpan} className={classes.rowTitle}>
        {getTranslateGroupName ? getTranslateGroupName(type) : type}
      </TableCell>
      {fitTableContent && <TableCell className={classes.emptyCell} />}
    </TableRow>
  )
})
