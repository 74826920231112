import { ValidationErrors } from '@/constants/forms'
import {
  CreateDeviceFormValues,
  CreateHardwareFormValues,
  CreateManualAddressFormValues,
  CreateRelatedCompaniesFormValues,
  CreateScheduleFormValues,
  Day,
  ScheduleFormValues,
} from '@/types/adm/device/local'

import { validateTimeOrder } from '../DeviceCardPage/components/ScheduleForm/validateDate'
import { InitialFormValues } from './types'

type Error = Record<string, any>

export const validate = (values: InitialFormValues): Error => {
  const errors = {} as Error

  if (values.terminalUid !== '' && !values.terminalUid.match(/^\S.*/)) {
    errors.terminalUid = ValidationErrors.SpaceAreProhibited
  }
  if (values.deviceName !== '' && !values.deviceName.match(/^\S.*/)) {
    errors.deviceName = ValidationErrors.SpaceAreProhibited
  }

  if (!values.companies.some(company => String(company.companyRoleId) === '1')) {
    errors.companies = 'hardwareOwnerIsRequired'
  }
  if (values.companies.filter(company => String(company.companyRoleId) === '1').length > 1) {
    errors.companies = 'onlyOneHardwareOwner'
  }

  const { schedule } = values as CreateScheduleFormValues

  Object.keys(schedule).forEach(key => {
    if (
      Number(
        schedule[key as keyof ScheduleFormValues].timeFrom1.split(':').join() >
          schedule[key as keyof ScheduleFormValues].timeTo1.split(':').join(),
      )
    ) {
      errors[key as keyof ScheduleFormValues] = {} as Record<keyof Day, string>
      errors[key as keyof ScheduleFormValues].timeFrom1 = ValidationErrors.TimeRangeCrossing
    }
    if (
      schedule[key as keyof ScheduleFormValues].timeFrom2 !== '00:00' &&
      Number(
        schedule[key as keyof ScheduleFormValues].timeTo1.split(':').join() >
          schedule[key as keyof ScheduleFormValues].timeFrom2.split(':').join(),
      )
    ) {
      errors[key as keyof ScheduleFormValues] = {} as Record<keyof Day, string>
      errors[key as keyof ScheduleFormValues].timeTo1 = ValidationErrors.TimeRangeCrossing
    }
    if (
      Number(
        schedule[key as keyof ScheduleFormValues].timeFrom2.split(':').join() >
          schedule[key as keyof ScheduleFormValues].timeTo2.split(':').join(),
      )
    ) {
      errors[key as keyof ScheduleFormValues] = {} as Record<keyof Day, string>
      errors[key as keyof ScheduleFormValues].timeFrom2 = ValidationErrors.TimeRangeCrossing
    }

    if (!schedule[key as keyof ScheduleFormValues].timeFrom1.match(/^[0-2][0-9][:][0-5][0-9]$/)) {
      errors[key as keyof ScheduleFormValues] = {} as Record<keyof Day, string>
      errors[key as keyof ScheduleFormValues].timeFrom1 = ValidationErrors.InvalidDataFormat
    }
    if (!schedule[key as keyof ScheduleFormValues].timeTo1.match(/^[0-2][0-9][:][0-5][0-9]$/)) {
      errors[key as keyof ScheduleFormValues] = {} as Record<keyof Day, string>
      errors[key as keyof ScheduleFormValues].timeTo1 = ValidationErrors.InvalidDataFormat
    }
    if (!schedule[key as keyof ScheduleFormValues].timeFrom2.match(/^[0-2][0-9][:][0-5][0-9]$/)) {
      errors[key as keyof ScheduleFormValues] = {} as Record<keyof Day, string>
      errors[key as keyof ScheduleFormValues].timeFrom2 = ValidationErrors.InvalidDataFormat
    }
    if (!schedule[key as keyof ScheduleFormValues].timeTo2.match(/^[0-2][0-9][:][0-5][0-9]$/)) {
      errors[key as keyof ScheduleFormValues] = {} as Record<keyof Day, string>
      errors[key as keyof ScheduleFormValues].timeTo2 = ValidationErrors.InvalidDataFormat
    }
  })

  return errors
}

export const validateNextDevice = (values: CreateDeviceFormValues): boolean => {
  if (!values.terminalUid) return false
  if (!values.deviceTypeId) return false
  if (!values.deviceStateId) return false

  return true
}

export const validateNextHardware = (values: CreateHardwareFormValues): boolean => {
  if (!values.serialNumber) return false
  if (!values.modelId) return false
  if (!values.hardwareTypeId) return false

  return true
}

export const validateNextAddress = (values: CreateManualAddressFormValues): boolean => {
  if (!values.countryId) return false
  if (values.streetId && !values.houseNumber) return false

  return true
}

export const validateNextCompanies = (values: CreateRelatedCompaniesFormValues): boolean => {
  if (!values.companies.some(company => String(company.companyRoleId) === '1')) {
    return false
  }
  if (values.companies.filter(company => String(company.companyRoleId) === '1').length > 1) {
    return false
  }
  if (values.companies.some(company => !company.companyRoleId || !company.companyId)) {
    return false
  }

  return true
}

export const validateSchedule = (values: CreateScheduleFormValues): boolean => {
  return Object.keys(values.schedule)
    .map(key => validateTimeOrder(values.schedule[key as keyof ScheduleFormValues]))
    .reduce((a, b) => a && b)
}

export const validateFinish = (values: InitialFormValues): boolean => {
  if (
    !validateNextDevice(values) ||
    !validateNextHardware(values) ||
    !validateNextAddress(values) ||
    !validateNextCompanies(values) ||
    !validateSchedule(values)
  ) {
    return false
  }

  return true
}
