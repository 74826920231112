import { FormikValues } from 'formik'
import { isEqual } from 'lodash-es'

export const isFiltersSelected = (selectedFilters: FormikValues): boolean => {
  return Object.keys(selectedFilters || {}).some(el => {
    if (selectedFilters[el]?.length === 0) {
      return false
    }

    if (Array.isArray(selectedFilters[el])) {
      return selectedFilters[el].some((item: any) => !!item)
    }

    if (!selectedFilters[el]) return false

    return true
  })
}

export const isDefaultFilterChanged = (
  defaultFilters: FormikValues,
  newValues: FormikValues,
): boolean => {
  return !Object.entries(defaultFilters).every(([name, value]) => isEqual(newValues?.[name], value))
}
