import { Box, Card, Link } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import clsx from 'clsx'
import moment from 'moment'
import React, { ReactElement, useMemo } from 'react'

import { INITIAL_LOCALE, languagesConfig, LocalStorageItems } from '@/constants'
import { DEFAULT_DATE_TIME_SEC_FORMAT } from '@/constants/time'

import { useStyles } from './styles'
import { NotificationItemProps } from './types'

export const NotificationItem = ({
  isAlarm = false,
  deviceName,
  deviceId,
  eventName,
  onCloseClick,
  onArrowClick,
  eventDate,
  onTitleClick,
  onEventNameClick,
  eventTypeId,
  eventId,
}: NotificationItemProps): ReactElement => {
  const classes = useStyles({ color: !isAlarm ? '#FBC91B' : '#F16452' })

  const language = localStorage.getItem(
    LocalStorageItems.Locale || INITIAL_LOCALE,
  ) as keyof typeof languagesConfig

  const usedLanguage = useMemo(() => {
    return languagesConfig?.[language]
  }, [language])

  return (
    <Card className={classes.card}>
      <CloseIcon className={clsx(classes.icon, classes.crossIcon)} onClick={onCloseClick} />

      {isAlarm && (
        <PlayArrowIcon className={clsx(classes.icon, classes.arrowIcon)} onClick={onArrowClick} />
      )}

      <Box className={classes.headerContainer}>
        <Link
          className={classes.cardTypo}
          onClick={onTitleClick && onTitleClick({ eventId, isAlarm, deviceId, deviceName })}
        >
          <span className={classes.deviceName}>{deviceName} </span>
          {eventDate &&
            moment
              .utc(eventDate)
              .local()
              .format(DEFAULT_DATE_TIME_SEC_FORMAT)}
        </Link>
      </Box>

      <Link
        onClick={
          onEventNameClick &&
          onEventNameClick({ eventTypeId, eventId, isAlarm, deviceId, deviceName })
        }
        className={clsx(classes.cardTypo, classes.eventName)}
      >
        {eventName?.[usedLanguage]}
      </Link>
    </Card>
  )
}
