import MuiSwitch from '@material-ui/core/Switch'
import { withStyles, createStyles } from '@material-ui/core/styles'

const Switch = withStyles(({ palette, spacing, transitions }) =>
  createStyles({
    root: {
      width: 45,
      height: 24,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
    },
    switchBase: {
      padding: 3,
      color: palette.grey[500],
      '&$checked': {
        transform: 'translateX(21px)',
        color: palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: palette.primary.main,
          borderColor: palette.primary.main,
        },
      },
    },
    thumb: {
      width: 17,
      height: 17,
      boxShadow: 'none',
    },
    track: {
      height: 22,
      border: `1px solid ${palette.grey[500]}`,
      borderRadius: 16,
      opacity: 1,
      backgroundColor: palette.common.white,
    },
    checked: {},
  }),
)(MuiSwitch)

export default Switch
