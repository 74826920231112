import makeStyles from '@material-ui/core/styles/makeStyles'
import styled from 'styled-components'

import { styledComponentTheme } from '@/theme/styledComponentTheme'

export const useClasses = makeStyles(({ spacing }) => ({
  modalWrapper: {
    maxWidth: '620px',
  },
  closeButton: {
    top: spacing(1),
    right: spacing(1),

    '& [class*="makeStyles-icon"]': {
      color: '#041517',
    },
  },

  modalTitle: {
    fontSize: '20px',
    marginBottom: spacing(2.5),
  },

  modalSubTitle: {
    marginBottom: spacing(2.5),
  },

  loader: {
    top: 'auto',
    left: 'auto',
    position: 'unset',
    transform: 'none',
  },

  button: {
    fontWeight: 'normal',
    background: styledComponentTheme.colors.baseGreen,
    color: 'white',
    border: 0,
    marginBottom: 0,
    padding: '10px 20px',

    '&:hover': {
      background: styledComponentTheme.colors.baseGreen,
      color: 'white',
      border: 0,
    },
  },

  buttonOutlined: {
    fontWeight: 'normal',
    marginBottom: 0,
    marginTop: '8px',
    padding: '10px 20px',
    marginRight: '8px',
  },

  cancelButton: {
    fontWeight: 'normal',
    background: 'transparent',
    color: '#929A9B',
    border: '1px solid',
    marginBottom: 0,
    padding: '10px 20px',
    marginRight: '8px',

    '&:hover': {
      background: 'transparent',
      color: '#929A9B',
      border: '1px solid',
    },
  },
}))

export const ContentWrapper = styled.div`
  background-color: #ffffff;
  padding: 30px;
`
