export enum LoginFormFields {
  Identifier = 'username',
  Module = 'module',
  Password = 'password',
  Timezone = 'timezone',
}

export interface LoginFormValues {
  [LoginFormFields.Identifier]: string
  [LoginFormFields.Module]: string
  [LoginFormFields.Password]: string
  [LoginFormFields.Timezone]: string
}
