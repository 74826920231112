import React from 'react'

import { store } from '@/App'

export const USMRoutes = React.lazy(async () => {
  // users
  const {
    default: { reducer, saga },
  } = await import('@/store/usm/users')

  store.injectReducer('usmUsers', reducer)
  store.injectSaga('usmUsers', saga)
  // // user-group
  const {
    default: { reducer: usmReducerUserGroup, saga: usmSagaUserGroup },
  } = await import('@/store/usm/userGroup')

  store.injectReducer('usmUserGroup', usmReducerUserGroup)
  store.injectSaga('usmUserGroup', usmSagaUserGroup)

  const {
    default: {
      reducer: reducerSecurityNetworksObjectsMergeUSM,
      saga: sagaSecurityNetworksObjectsMergeUSM,
    },
  } = await import('@/store/usm/securityNetworksObjectMerge')

  store.injectReducer('usmSecurityNetworksObjectsMerge', reducerSecurityNetworksObjectsMergeUSM)
  store.injectSaga('usmSecurityNetworksObjectsMerge', sagaSecurityNetworksObjectsMergeUSM)

  const {
    default: { reducer: reducerSecurityObjectsListUSM, saga: sagaSecurityObjectsListUSM },
  } = await import('@/store/usm/securityObjects')

  store.injectReducer('usmSecurityObjectsList', reducerSecurityObjectsListUSM)
  store.injectSaga('usmSecurityObjectsList', sagaSecurityObjectsListUSM)

  const {
    default: { reducer: reducerCommonSearchForm, saga: sagaCommonSearchForm },
  } = await import('@/store/common/searchForm')

  store.injectReducer('commonSearchForm', reducerCommonSearchForm)
  store.injectSaga('commonSearchForm', sagaCommonSearchForm)

  const {
    default: { reducer: companiesReducer, saga: companiesSaga },
  } = await import('@/store/companies/companies')

  store.injectReducer('companiesCompanies', companiesReducer)
  store.injectSaga('companiesCompanies', companiesSaga)

  return import('@/components/routers/usm')
})
