export enum CreateNewTicketFormFields {
  Customer = 'customer',
  ContactPerson = 'contactPerson',
  ServiceCompanyId = 'serviceCompanyId',
  UnitId = 'unit',
  Notes = 'description',
  Claim = 'claim',
  Files = 'attachments',
  RequestNumber = 'requestNumber',
}

export interface CreateNewTicketFormValues {
  [CreateNewTicketFormFields.Customer]: string
  [CreateNewTicketFormFields.ContactPerson]: string
  [CreateNewTicketFormFields.RequestNumber]: string
  [CreateNewTicketFormFields.ServiceCompanyId]: string
  [CreateNewTicketFormFields.Claim]: string
  [CreateNewTicketFormFields.ServiceCompanyId]: string
  [CreateNewTicketFormFields.UnitId]: string
  [CreateNewTicketFormFields.Notes]: string
  [CreateNewTicketFormFields.Files]: File[]
}
