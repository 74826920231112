import { SagaIterator } from '@redux-saga/core'
import momentTZ from 'moment-timezone'
import { AnyAction } from 'redux'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { DashBoardAPI } from '@/api/atmeye/dasboard/dashBoardAPI'
import { AuthorizationApi } from '@/api/common/authorizationApi'
import { PermissionsApi } from '@/api/sd/permissionsApi'
import {
  AppNavigationRoutes,
  ATMEYE_BLOCK_PREFIX,
  AuthorizationActions,
  CASH_MANAGEMENT_BLOCK_PREFIX,
  LoginFormFields,
  PermissionsResponseType,
  SD_BLOCK_PREFIX,
  VAULT_BLOCK_PREFIX,
} from '@/constants'
import { availableModules, Modules, patchForProducts, UserRoles } from '@/constants/auth'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { REACT_APP_SD_STANDALONE } from '@/constants/moduleMode'
import { timezones } from '@/constants/timezones'
import { history } from '@/Router'
import { actionsATMEyeDashboard } from '@/store/atmeye/dashboard'
import { AppState } from '@/store/reducers'
import { TableSettingsData } from '@/types'
import { AllSubpagesResponseType } from '@/types/atmeye/dashboardTypes'
import { AuthModuleStandAloneTypes } from '@/types/common/AuthModuke'
import { getTableSettings, setUserSettingsToLocalStorage } from '@/utils/localStorage'
import { PopUpService } from '@/utils/services/popUpService'

import { getAllSubpages } from '../atmeye/dashboard/saga'
import { setUserCM } from '../cm/auth'
import {
  authorizeUser,
  changePasswordResponse,
  changePasswordResponseFail,
  getAllPermissionsResponse,
  getAllPermissionsResponseFail,
  getAllUserPermissionsResponse,
  getAllUserPermissionsResponseFail,
  getUserPhotoResponse,
  getUserPhotoResponseFail,
  putCurrentAccount,
  resetReducers,
  setAvailableProducts,
  signInResponse,
  signInResponseFail,
  signOut,
  startIsInitializationAuthProcess,
  stopIsInitializationAuthProcess,
} from './actions'

const map = new Map<keyof AuthModuleStandAloneTypes, string>([
  ['REACT_APP_SD_STANDALONE', REACT_APP_SD_STANDALONE],
])

const mainPathForModule: AuthModuleStandAloneTypes = {
  REACT_APP_SD_STANDALONE: `${SD_BLOCK_PREFIX}/web`,
}

const delay = (time: number) => new Promise(resolve => setTimeout(resolve, time))

const checkStandalone = (): string => {
  let patch = ''
  map.forEach((val, key) => {
    if (val === 'true') {
      patch = mainPathForModule[key]
    }
  })
  return patch
}

const isCurrentURL = (
  lastModule: TableSettingsData | null,
  patch: string | unknown,
  defaultPrefix: string,
): string => {
  return checkStandalone()
    ? checkStandalone()
    : lastModule?.patch && lastModule?.product === patch
    ? lastModule.patch
    : typeof patch === 'string'
    ? patch
    : defaultPrefix
}

function* getAllPermissions(): Generator<unknown, void, PermissionsResponseType> {
  try {
    const response = yield call(PermissionsApi.getPermissionsByParamsIds)
    yield put(getAllPermissionsResponse(response))
  } catch (error) {
    yield put(getAllPermissionsResponseFail(error.message))
  }
}

function* getAllUserPermissions(): Generator<unknown, void, PermissionsResponseType> {
  try {
    const response = yield call(PermissionsApi.getAllUserPermissions)
    sessionStorage.setItem(LocalStorageItems.UserPermissions, JSON.stringify(response))
    yield put(getAllUserPermissionsResponse(response))

    if (response.AE_Dashboard_Create_Or_Edit_Page) {
      yield put(actionsATMEyeDashboard.allowCreationOfNewPages())
    }
  } catch (error) {
    yield put(getAllUserPermissionsResponseFail(error.message))
  }
}

function* getUserPhoto(): Generator<unknown, void, string> {
  try {
    const response = yield call(AuthorizationApi.getUserPhoto)
    sessionStorage.setItem(LocalStorageItems.UserPhoto, response)
    yield put(getUserPhotoResponse(response))
  } catch (error) {
    yield put(getUserPhotoResponseFail(error.message))
  }
}

function* setUser(currentAccount: any): Generator {
  yield put(startIsInitializationAuthProcess())
  sessionStorage.setItem(LocalStorageItems.AccessToken, currentAccount.authorization)
  sessionStorage.setItem(LocalStorageItems.UserID, currentAccount.userId.toString())
  sessionStorage.setItem(LocalStorageItems.CurrentAccount, JSON.stringify(currentAccount))
  setUserSettingsToLocalStorage(LocalStorageItems.UserSettings, currentAccount.userId.toString())
  yield put(putCurrentAccount(currentAccount))

  const patch = yield select((state: AppState) => state.auth.currentProducts)
  if (patch !== CASH_MANAGEMENT_BLOCK_PREFIX && patch !== VAULT_BLOCK_PREFIX) {
    yield call(getAllPermissions)
    yield call(getAllUserPermissions)

    yield call(AuthorizationApi.LogLogin)

    if (currentAccount.hasPhoto) {
      yield call(getUserPhoto)
    }
  }

  yield put(authorizeUser())
}

function* defineUser({ payload }: any): Generator {
  const selectedProduct =
    Object.keys(patchForProducts).find(productName => {
      return patchForProducts[productName].find(product => product.value === payload)
    }) || 'SD'

  const accounts: any = yield select(state => state.auth.accounts[selectedProduct])

  if (accounts && accounts.length === 1) {
    const [currentAccount] = accounts
    if (currentAccount && !currentAccount.changePassword) {
      yield setUser(currentAccount)
    }
  }
}

function* setCurrentUser({ payload }: any): Generator {
  const users: any = yield select(state => state.auth.accounts)
  const availableAccount = Object.keys(users)
  const [key] = availableAccount
  const currentAccount = users[key].find((el: any) => el.userId === payload)
  if (currentAccount && !currentAccount.changePassword) {
    yield setUser(currentAccount)
  }
}

function* syncAuthModule(
  lastModule: TableSettingsData | null,
  patch: string | unknown,
  defaultPrefix: string,
): SagaIterator {
  history.push(isCurrentURL(lastModule, patch, defaultPrefix))

  yield put(stopIsInitializationAuthProcess())
}
function* authATMEyeProduct(
  lastModule: TableSettingsData | null,
  patch: string | unknown,
  defaultPrefix: string,
): SagaIterator {
  const fetcher = new DashBoardAPI()
  try {
    const responsePermissions = yield call(PermissionsApi.getAllUserPermissions)
    yield put(getAllPermissionsResponse(responsePermissions))

    if (responsePermissions.AE_Dashboard_Open_Page) {
      yield call(getAllSubpages)
      const mainPage: AllSubpagesResponseType = yield call(fetcher.getUserMainPage.bind(fetcher))

      history.push(
        `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.dashboard.root}/${mainPage.subpageId}`,
      )
    } else {
      history.push(`${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.devices.root}`)
    }
    yield put(stopIsInitializationAuthProcess())
  } catch (e) {
    if (e.errorMessage === 'atmeye.message.mainDashboardPageNotSet') {
      const subpages: any = yield select(state => state.atmEyeDashboard?.subPages?.subItems)

      history.push(
        subpages?.[0]?.path ||
          `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.dashboard.root}/`,
      )
      yield put(stopIsInitializationAuthProcess())
    } else {
      yield call(syncAuthModule, lastModule, patch, defaultPrefix)
    }
  }
}

function* userAuthorization(): Generator {
  const currentAccount: any = yield select(state => {
    return state.auth.currentAccount
  })
  const productPath = yield select((state: AppState) => state.auth.currentProducts)
  const userId = sessionStorage.getItem(LocalStorageItems.UserID)
  const lastModule = getTableSettings(LocalStorageItems.UserSettings, userId || '', 'modules')

  if (lastModule && lastModule.patch) {
    const moduleNames = Object.keys(availableModules)
    const availableModulesByPath = moduleNames.find((moduleName: string) => {
      return availableModules[moduleName].path.startsWith(lastModule.patch)
    })

    if (availableModulesByPath && !currentAccount.modules.includes(availableModulesByPath)) {
      lastModule.patch = availableModules[currentAccount.modules[0]].path
    }
  }
  if (productPath === CASH_MANAGEMENT_BLOCK_PREFIX) {
    yield put(setUserCM(currentAccount))
    return
  }

  if (
    currentAccount.roleName === UserRoles.Administrator ||
    currentAccount.roleName.roleName === UserRoles.Cashier
  ) {
    history.push(`${VAULT_BLOCK_PREFIX}${AppNavigationRoutes.CashOrdersPage}`)
    return
  }

  if (productPath !== VAULT_BLOCK_PREFIX) {
    productPath === ATMEYE_BLOCK_PREFIX
      ? yield call(authATMEyeProduct, lastModule, productPath, SD_BLOCK_PREFIX)
      : yield call(syncAuthModule, lastModule, productPath, SD_BLOCK_PREFIX)
  }
}

function* signInUser(
  identifier: string,
  password: string,
  module: string,
  timezone: any,
): SagaIterator {
  const { locale } = yield select((state: AppState) => state.intl)

  sessionStorage.setItem(LocalStorageItems.LogInLocale, locale)
  sessionStorage.setItem(LocalStorageItems.Timezone, timezone.match(/\(.+\)/))
  localStorage.removeItem(LocalStorageItems.ShouldHideExpireLicenceBanner)

  try {
    const isVault = module === 'vault'

    if (isVault) {
      const response = yield call(AuthorizationApi.SignInVault, identifier, password)

      if (response) {
        const {
          data: { userID: userId, userName, roleName },
          headers: { authorization },
        } = response
        const accounts = [
          {
            userId,
            userName,
            companyId: 1,
            companyName: '',
            roleName,
            companyCountryId: 1,
          },
        ]

        sessionStorage.setItem(LocalStorageItems.AccessToken, authorization)
        sessionStorage.setItem(LocalStorageItems.IsVault, 'true')

        yield put(signInResponse({ accounts }))

        if (accounts.length === 1) {
          yield call(defineUser, { payload: accounts[0].userId.toString() })
          yield call(userAuthorization)

          setUserSettingsToLocalStorage(
            LocalStorageItems.UserSettings,
            accounts[0].userId.toString(),
          )
        }
      }
    } else {
      const response = yield call(AuthorizationApi.SignIn, identifier, password)

      if (response) {
        const {
          data: { products },
        } = response

        const listProducts: Array<string> = Object.keys(products)

        yield put(setAvailableProducts(listProducts))

        const accounts = listProducts.reduce((acc, product) => {
          return {
            ...acc,
            [product]: products[product].body.map((account: any) => ({
              ...account,
              authorization: products[product].headers.Authorization[0],
            })),
          }
        }, {})
        yield put(signInResponse({ accounts }))
      }
    }
  } catch (error) {
    yield put(signInResponseFail(error.message))
  }
}

function* signIn({
  payload: {
    [LoginFormFields.Identifier]: identifier,
    [LoginFormFields.Password]: password,
    [LoginFormFields.Module]: module,
    timezone,
  },
}: AnyAction): SagaIterator {
  yield call(signInUser, identifier, password, module, timezone)
}

function* checkIsLogin(): Generator {
  try {
    yield call(AuthorizationApi.checkSession)
  } catch (error) {
    yield put(signOut())
  }
}

function* handleChangePassword({ payload }: AnyAction): SagaIterator {
  try {
    const { axiosConfig } = payload
    let { login } = payload

    if (!login) {
      const currentAccount: any = yield select(state => {
        return state.auth.currentAccount
      })
      if (currentAccount) {
        login = yield select(state => state.auth.currentAccount.login)
      }
    }

    if (login) {
      const response = yield call(
        AuthorizationApi.ChangePassword,
        login,
        payload.oldPassword,
        payload.newPassword,
        axiosConfig,
      )

      if (response) {
        yield put(changePasswordResponse(response))

        const momentGuess = momentTZ.tz.guess()
        const { text: guess } = timezones.find(zone => zone.utc.includes(momentGuess)) || {
          text: '',
        }

        yield call(signInUser, login, payload.newPassword, Modules.Other, guess)

        PopUpService.showGlobalPopUp('translate#title.passwordChangedSuccessfully', 'success')
      }
    }
  } catch (error) {
    yield put(changePasswordResponseFail(error.message || error.errorMessage))
  }
}

function* signOutSaga(): Generator {
  const currentAccount: any = yield select(state => {
    return state.auth.currentAccount
  })

  if (currentAccount) {
    yield call(delay, 0)
    yield put(resetReducers())
  }
}

export default function*(): Generator {
  yield takeLatest(AuthorizationActions.SignInRequest, signIn)
  yield takeLatest(AuthorizationActions.SignOut, signOutSaga)
  yield takeLatest(AuthorizationActions.CheckIsLogin, checkIsLogin)
  yield takeLatest(AuthorizationActions.GetAllPermissionsRequest, getAllPermissions)
  yield takeLatest(AuthorizationActions.GetAllUserPermissionsRequest, getAllUserPermissions)
  yield takeLatest(AuthorizationActions.GetUserPhotoRequest, getUserPhoto)
  yield takeLatest(AuthorizationActions.AuthorizeUser, userAuthorization)
  yield takeLatest(AuthorizationActions.SetCurrentProducts, defineUser)
  yield takeLatest(AuthorizationActions.SetCurrentUserFromAvailable, setCurrentUser)
  yield takeLatest(AuthorizationActions.ChangePasswordRequest, handleChangePassword)
}
