import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useSubPageModal } from '@/components/blocks/ATMeye/modals/pageModal/hooks/usePageModal'
import { LocalStorageItems } from '@/constants'
import {
  getParsedLocalStorageItem,
  getUserDataLocalStorage,
  setDataToLocalStorage,
} from '@/helpers/localStorage'
import { actionsATMEyeDashboard, getSubPageState } from '@/store/atmeye/dashboard'
import { AddNewSubPageTypes, AddNewSubscriptionPageTypes } from '@/types/atmeye/dashboardTypes'
import { ActionFormTypes } from '@/types/usm/commoTypes/actionFormTypes'

export const useAppNavigation = () => {
  const dispatch = useDispatch()
  // usm-module
  const [openMergeSecurityNetworksModal, setOpenMergeSecurityNetworksModal] = useState(false)

  const handleOpenCloseModal = useCallback((): void => {
    setOpenMergeSecurityNetworksModal(open => !open)
  }, [openMergeSecurityNetworksModal])

  // ATMEye module
  const subpages = useSelector(getSubPageState)

  const handleAddNewSubPage = useCallback(
    (
      payload: AddNewSubPageTypes | AddNewSubscriptionPageTypes,
      actions: ActionFormTypes<AddNewSubPageTypes>,
    ): void => {
      dispatch(actionsATMEyeDashboard.getAddNewSubPagesAsync(payload, actions))
    },
    [],
  )

  const getDrawerState = (): 'relative' | '' => {
    const { pathname } = window.location
    const [, baseUrl] = pathname.split('/')

    const { login } = getUserDataLocalStorage(baseUrl) || {}

    const allUsersSettings =
      getParsedLocalStorageItem(baseUrl)(LocalStorageItems.UsersSettings) || {}
    const currentUserSettings = allUsersSettings[login]

    if (currentUserSettings?.isDrawerMenuOpen === undefined && login) {
      const dataToChange = {
        [login]: {
          ...currentUserSettings,
          isDrawerMenuOpen: true,
        },
      }

      setDataToLocalStorage(baseUrl)(LocalStorageItems.UsersSettings, JSON.stringify(dataToChange))
      return 'relative'
    }

    return currentUserSettings?.isDrawerMenuOpen ? 'relative' : ''
  }

  const toggleDrawerState = (): void => {
    const { pathname } = window.location
    const [, baseUrl] = pathname.split('/')

    const { login } = getUserDataLocalStorage(baseUrl) || {}

    const allUsersSettings =
      getParsedLocalStorageItem(baseUrl)(LocalStorageItems.UsersSettings) || {}
    const currentUserSettings = allUsersSettings[login]

    if (currentUserSettings !== undefined) {
      const dataToChange = {
        [login]: {
          ...currentUserSettings,
          isDrawerMenuOpen: !currentUserSettings.isDrawerMenuOpen,
        },
      }

      setDataToLocalStorage(baseUrl)(LocalStorageItems.UsersSettings, JSON.stringify(dataToChange))
    }
  }

  const { showSubPageModal, handleSubPageModal, handleSave } = useSubPageModal(handleAddNewSubPage)

  return {
    openMergeSecurityNetworksModal,
    handleOpenCloseModal,
    subpages,
    showSubPageModal,
    getDrawerState,
    toggleDrawerState,
    handleSubPageModal,
    handleSave,
  }
}
