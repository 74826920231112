import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ mixins, spacing, palette }) => ({
  menu: {
    width: 300,
  },
  menuSizeAnchor: {
    width: 300,
    maxWidth: '100%',
    fontWeight: 'normal',
  },
  divider: {
    margin: spacing(1.5, 2.5),
  },
  buttonWrapper: {
    ...mixins.gutters(),
    marginTop: spacing(1),
    justifyContent: 'space-between',
    display: 'flex',
  },
  button: {
    fontWeight: 'normal',
  },
  buttonDisabled: {
    fontWeight: 'normal',
    background: 'transparent',
    color: '#929A9B',
    border: '1px solid #929A9B',
    '&:hover': {
      background: 'transparent',
      color: '#929A9B',
      border: '1px solid #929A9B',
    },
  },
  cancelButton: {
    color: palette.error.main,
    fontWeight: 'bold',
    backgroundColor: palette.error.light,
    '&:hover': {
      backgroundColor: palette.error.light,
    },
  },
  closeButton: {
    top: spacing(0),
    right: spacing(2),
    '& [class*="makeStyles-icon"]': {
      color: '#041517',
    },
  },
}))
