import { Theme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'

interface Props {
  isGradient: boolean
}

export const useClasses = makeStyles<Theme, Props>(({ spacing }) => ({
  status: ({ isGradient }: Props): CreateCSSProperties<Props> => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: spacing(0.25, 1),
    borderRadius: '3px',
    position: 'relative',

    '&:before': {
      content: "''",
      position: 'absolute',

      left: 0,
      top: 0,
      height: '100%',
      width: '100%',

      background: isGradient
        ? 'linear-gradient(to right, #ffffff 20%, transparent 100%)'
        : 'transparent',
      opacity: '0.8',
    },
  }),
  statusCircle: {
    display: 'inline',
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    marginRight: spacing(1),
  },
  statusTitle: {
    textTransform: 'none',
  },
  clickable: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}))
