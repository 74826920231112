import queryString from 'query-string'

import {
  AdmUser,
  AdmUserBase,
  AdmUserFilter,
  ComOnDemandDataEntry,
} from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class UsersApi {
  private static requestService = getRequestServiceCM()

  public static async get(sort: Sort, filter: AdmUserFilter): Promise<AdmUser[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/users',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await UsersApi.requestService.post(url, filter)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(payload: AdmUserBase): Promise<void> {
    try {
      return await UsersApi.requestService.post('/admin/users/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(payload: AdmUser): Promise<void> {
    try {
      return await UsersApi.requestService.put('/admin/users/update', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(id: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/users/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await UsersApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getReports(userId: string): Promise<ComOnDemandDataEntry[]> {
    try {
      return await UsersApi.requestService.post('/admin/users/reports', { userId })
    } catch (error) {
      throw new Error(error)
    }
  }
}
