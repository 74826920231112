import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles(({ spacing }) =>
  createStyles({
    header: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      padding: spacing(1, 2, 0.5),
    },
    title: {
      whiteSpace: 'nowrap',
      marginBottom: spacing(0),
    },
    map: {
      width: '100%',
      height: '100%',
    },
    autoRefreshTimerRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: spacing(0, 2),
      marginBottom: spacing(1),
    },
  }),
)
