import { APIConfiguration } from '@/constants'
import { getRequestService } from '@/utils/services'

export abstract class BaseATMeye<T> {
  protected constructor(protected readonly data?: T) {
    this.data = data
  }

  protected ATMeyeRequestService = getRequestService(APIConfiguration.API_BASE_URL)

  protected requestServiceV2 = getRequestService(APIConfiguration.API_BASE_URL_V2)
}
