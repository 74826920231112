import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import DotIcon from '@material-ui/icons/FiberManualRecord'
import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { DevicesApi } from '@/api/atmeye/devicesApi'
import { Device } from '@/api/atmeye/devicesApi/types'
import Refresh from '@/assets/sd/refresh.svg'
import Loader from '@/components/blocks/Loader'
import Button from '@/components/controls/Button'
import { useTranslate } from '@/utils/internalization'

import { ContentWrapper, useClasses } from './styles'

type Props = {
  currentDevice: Device | null
}

const StatusTab: FC<Props> = ({ currentDevice }: Props): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  const [isLoading, setIsLoading] = useState(true)
  const [healthStatus, setHealthStatus] = useState<any>({})

  const getStatus = async () => {
    setIsLoading(true)
    const response = await DevicesApi.getHealthInfo(currentDevice?.deviceName || '')

    setHealthStatus(response)
    setIsLoading(false)
  }

  useEffect(() => {
    getStatus()
  }, [currentDevice?.deviceName])

  return (
    <>
      <ContentWrapper>
        {!isLoading ? (
          <div className={classes.deviceHeader}>
            <div className={classes.tableHeader}>
              <Tooltip
                placement="top"
                classes={{ tooltip: classes.toolTip }}
                title={translate('translate#atmeye.table.header.button.refresh')}
              >
                <div>
                  <Button
                    className={classes.tableHeaderButton}
                    onClick={getStatus}
                    startIcon={<img src={Refresh} />}
                  />
                </div>
              </Tooltip>
            </div>

            <TableContainer className={classes.tableWrapper} component={Paper}>
              <div className={classes.tableWrap}>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        variant="head"
                        className={clsx(classes.tableCell, classes.tableCellTitle)}
                      >
                        {translate(`translate#atmeye.device.status.device`)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <div
                          className={clsx(classes.cellWrapper, {
                            [classes.folderOk]: healthStatus.aggregatedStatus === 'OK',
                            [classes.folderWarning]:
                              healthStatus.aggregatedStatus === 'NEED_ATTENTION',
                          })}
                        >
                          <DotIcon
                            className={clsx(classes.cellIcon, classes.iconSize, {
                              [classes.dotOk]: healthStatus.aggregatedStatus === 'OK',
                              [classes.dotWarning]:
                                healthStatus.aggregatedStatus === 'NEED_ATTENTION',
                            })}
                          />

                          <FormattedMessage
                            id={translate(
                              `translate#atmeye.devicesTable.aggregatedStatus.${healthStatus.aggregatedStatus}`,
                            )}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    {Object.keys(healthStatus.info).map((el: any) => {
                      const data = healthStatus.info[el]

                      return (
                        <TableRow key={el}>
                          <TableCell
                            variant="head"
                            className={clsx(classes.tableCell, classes.tableCellTitle)}
                          >
                            {translate(`translate#atmeye.device.status.${el}`)}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <div
                              className={clsx(classes.cellWrapper, {
                                [classes.folderOk]: data.status === 'OK',
                                [classes.folderWarning]: data.status === 'NEED_ATTENTION',
                              })}
                            >
                              <DotIcon
                                className={clsx(classes.cellIcon, classes.iconSize, {
                                  [classes.dotOk]: data.status === 'OK',
                                  [classes.dotWarning]: data.status === 'NEED_ATTENTION',
                                })}
                              />

                              <FormattedMessage
                                id={translate(
                                  `translate#atmeye.devicesTable.aggregatedStatus.${data.status}`,
                                )}
                              />
                            </div>
                          </TableCell>
                          <TableCell variant="head" className={clsx(classes.tableCell)}>
                            {data.details?.message}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </div>
        ) : (
          <Loader />
        )}
      </ContentWrapper>
    </>
  )
}

export default StatusTab
