import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  button: {
    padding: spacing(0.5),
    marginLeft: spacing(0.5),
  },
  icon: {
    color: palette.grey[200],
  },
  label: {
    padding: spacing(0, 1),
  },
  userName: {
    padding: spacing(0, 1),
  },
}))
