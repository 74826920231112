import { AnyAction } from 'redux'
import { select, takeLatest } from 'redux-saga/effects'

import { USMApi } from '@/api/usm/users'
import { UserInfoInstallationValues } from '@/components/pages/usm/UsersPage/components/UserInformation/components/modals/PersonModal/types'
import { AddUserPersonalCardValues } from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/AddUserModal/types'
import { AppState } from '@/store/reducers'
import { makeRequestWithSpinner, OptionsType } from '@/store/usm/sagaWorkers/saga'
import { actionsUsersUSM } from '@/store/usm/users/actions'
import { LookupConfigCreateUserTypes } from '@/types/usm/lookupTypesConfigUSM/lookupTYpesUSM'
import {
  AddNewUserInfoPayload,
  EditUserInfoTypePayload,
  PersonalCardTypes,
  UpdatePersonalCardTypes,
  UserInformationTypes,
} from '@/types/usm/personalInformation/personalInformationTypes'
import { ReturnAction } from '@/types/usm/userGroup/userGroup'

// personal card
function* getPersonalCard({ payload }: AnyAction): Generator {
  const fetcher = new USMApi(payload)
  // const fetcher = Reflect.construct(USMApi, [payload])
  const options: OptionsType<PersonalCardTypes, string> = {
    fetcher: fetcher.getPersonalCard.bind(fetcher),
    startFetching: actionsUsersUSM.startFetchingPersonalCard,
    stopFetching: actionsUsersUSM.stopFetchingPersonalCard,
    fill: actionsUsersUSM.getPersonalCardResponse,
    setErrorAction: actionsUsersUSM.getPersonalCardResponseFall,
    fetcherParam: payload,
  }

  yield makeRequestWithSpinner<PersonalCardTypes, string>(options)
}

// update-personal-card

function* getUpdatePersonalCard({
  payload,
}: ReturnAction<null, UpdatePersonalCardTypes>): Generator {
  const fetcher = new USMApi(payload, payload.personalInfo.personId)
  const options: OptionsType<UpdatePersonalCardTypes, number> = {
    fetcher: fetcher.upDateUserCard.bind(fetcher),
    startFetching: actionsUsersUSM.startFetchingUpdatePersonalCard,
    stopFetching: actionsUsersUSM.stopFetchingUpdatePersonalCard,
    fill: actionsUsersUSM.setUpdatePersonalCardResponse,
    setErrorAction: actionsUsersUSM.setUpdatePersonalCardResponseFall,
    fetcherParam: payload.personalInfo.personId,
  }

  yield makeRequestWithSpinner<UpdatePersonalCardTypes, number>(options)
}

// add new user
function* addNewUser({
  payload,
  actions,
}: ReturnAction<AddUserPersonalCardValues, AddNewUserInfoPayload>): Generator {
  const fetcher = new USMApi<AddNewUserInfoPayload>(payload)
  const options: OptionsType<PersonalCardTypes, AddNewUserInfoPayload> = {
    fetcher: fetcher.addNewUser.bind(fetcher),
    startFetching: actionsUsersUSM.startAddNewUser,
    stopFetching: actionsUsersUSM.stopAddNewUser,
    fill: actionsUsersUSM.getAddUserResponse,
    setErrorAction: actionsUsersUSM.getAddUserResponseFall,
    fetcherParam: payload,
    formActions: actions,
  }

  yield makeRequestWithSpinner<PersonalCardTypes, AddNewUserInfoPayload>(options)
}

// edit-user-info-block
function* editUserInfoBlock({
  payload,
  actions,
}: ReturnAction<UserInfoInstallationValues, EditUserInfoTypePayload>): Generator {
  const fetcher = new USMApi<EditUserInfoTypePayload>(payload)
  const options: OptionsType<UserInformationTypes, EditUserInfoTypePayload> = {
    fetcher: fetcher.changeUser.bind(fetcher),
    startFetching: actionsUsersUSM.startChangeUser,
    stopFetching: actionsUsersUSM.stopChangeUser,
    fill: actionsUsersUSM.getChangeUserResponse,
    setErrorAction: actionsUsersUSM.getChangeUserResponseFall,
    fetcherParam: payload,
    formActions: actions,
  }

  yield makeRequestWithSpinner<UserInformationTypes, EditUserInfoTypePayload>(options)
}

// add-ser-user-info-block

function* AddUserInfoBlock({ payload, formActions }: AnyAction): Generator {
  const loginId = yield select((state: AppState) => state.usmUsers.personalCard.loginInfo.loginId)
  const personId = yield select(
    (state: AppState) => state.usmUsers.personalCard.personalInfo.personId,
  )

  const fetcher = new USMApi<UserInformationTypes>(payload, loginId, personId)
  const options: OptionsType<UserInformationTypes, AddNewUserInfoPayload> = {
    fetcher: fetcher.addUserInfo.bind(fetcher),
    startFetching: actionsUsersUSM.startFetchingAddUser,
    stopFetching: actionsUsersUSM.stopFetchingAddUser,
    fill: actionsUsersUSM.setAddUserResponse,
    setErrorAction: actionsUsersUSM.setAddUserResponseFall,
    fetcherParam: payload,
    formActions: formActions,
  }

  yield makeRequestWithSpinner<UserInformationTypes, AddNewUserInfoPayload>(options)
}

// delete-user-info-block

function* deleteUserInfoBlock({ payload }: AnyAction): Generator {
  const personId = yield select(
    (state: AppState) => state.usmUsers.personalCard.personalInfo.personId,
  )
  const loginId = yield select((state: AppState) => state.usmUsers.personalCard.loginInfo.loginId)

  const fetcher = new USMApi(payload, personId, loginId)
  const options: OptionsType<Array<number>, Array<number>> = {
    fetcher: fetcher.deleteUserInfo.bind(fetcher),
    startFetching: actionsUsersUSM.startFetchingDeleteUser,
    stopFetching: actionsUsersUSM.stopFetchingDeleteUser,
    fill: actionsUsersUSM.setDeleteUserResponse,
    setErrorAction: actionsUsersUSM.setDeleteUserResponseFall,
    fetcherParam: payload,
  }

  yield makeRequestWithSpinner<Array<number>, Array<number>>(options)
}

function* getLookupConfigCreateUser({ payload }: AnyAction): Generator {
  const fetcher = new USMApi(payload)
  const options: OptionsType<Array<LookupConfigCreateUserTypes>, never> = {
    fetcher: fetcher.getLookupConfigCreateUser.bind(fetcher),
    startFetching: actionsUsersUSM.startFetchingLookupConfigCreateUser,
    stopFetching: actionsUsersUSM.stopFetchingLookupConfigCreateUser,
    fill: actionsUsersUSM.setLookupConfigCreateUserResponse,
    setErrorAction: actionsUsersUSM.setLookupConfigCreateUserResponseFall,
    fetcherParam: payload,
  }

  yield makeRequestWithSpinner<Array<LookupConfigCreateUserTypes>, never>(options)
}

function* getLookupConfigEditUser({ payload }: AnyAction): Generator {
  const fetcher = new USMApi(payload)
  const options: OptionsType<Array<LookupConfigCreateUserTypes>, never> = {
    fetcher: fetcher.getLookupConfigEditUser.bind(fetcher),
    startFetching: actionsUsersUSM.startFetchingLookupConfigCreateUser,
    stopFetching: actionsUsersUSM.stopFetchingLookupConfigCreateUser,
    fill: actionsUsersUSM.setLookupConfigCreateUserResponse,
    setErrorAction: actionsUsersUSM.setLookupConfigCreateUserResponseFall,
    fetcherParam: payload,
  }

  yield makeRequestWithSpinner<Array<LookupConfigCreateUserTypes>, never>(options)
}

export default function*(): Generator {
  yield takeLatest('@/GET_PERSONAL_CARD_ASYNC', getPersonalCard)
  yield takeLatest('@/ADD_NEW_USER_ASYNC_USM', addNewUser)
  yield takeLatest('@/EDIT_USER_ASYNC_USM', editUserInfoBlock)
  yield takeLatest('@/ADD_USER_INFO_ASYNC_USM', AddUserInfoBlock)
  yield takeLatest('@/DELETE_USER_INFO_ASYNC_USM', deleteUserInfoBlock)
  yield takeLatest('@/LOOKUP_CONFIG_CREATE_USER_ASYNC_USM', getLookupConfigCreateUser)
  yield takeLatest('@/LOOKUP_CONFIG_EDIT_USER_ASYNC_USM', getLookupConfigEditUser)
  yield takeLatest('@/UPDATE_PERSONAL_CARD_ASYNC_USM', getUpdatePersonalCard)
}
