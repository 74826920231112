import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { ForecastApi } from '@/api/cm/adminApi'
import { CMAppConfigActions } from '@/constants'

import {
  getForecastSettingsFail,
  getForecastSettingsRequest,
  getForecastSettingsResponse,
  saveForecastSettingsFail,
} from './actions'

function* getImportForecastSettings(): SagaIterator {
  try {
    const response = yield call(ForecastApi.get)

    yield put(getForecastSettingsResponse(response))
  } catch (error) {
    yield put(getForecastSettingsFail())
  }
}

function* saveImportForecastSettings({ payload }: AnyAction): SagaIterator {
  try {
    yield call(ForecastApi.save, payload)

    yield put(getForecastSettingsRequest())
  } catch (error) {
    yield put(saveForecastSettingsFail())
  }
}

export default function*(): Generator {
  yield takeLatest(CMAppConfigActions.AdmGetForecastSettingsRequest, getImportForecastSettings)
  yield takeLatest(CMAppConfigActions.AdmSaveForecastSettingsRequest, saveImportForecastSettings)
}
