import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles(({ spacing }) =>
  createStyles({
    wrapperInput: {
      display: 'flex',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '1rem',
      marginLeft: '2rem',
    },
    input: {
      width: '30px',
      height: '30px',
      marginLeft: ' 1rem',
      textAlign: 'center',
    },
  }),
)
