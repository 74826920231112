import { makeStyles } from '@material-ui/core'

import { Props } from './types'

export const useClasses = makeStyles({
  paper: {
    maxHeight: '240px',
    position: 'absolute',
    top: -10,
    left: (props: Props): number => props.left - 10,
  },

  content: {
    padding: '10px',
    overflow: 'hidden',
  },
})
