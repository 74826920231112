import queryString from 'query-string'

import { APIConfiguration } from '@/constants'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

import {
  MapCashPointInfo,
  MapCashPointsRequest,
  MapCashPointsResponse,
} from '../swaggerGeneratedApi'

export class MapsApi {
  private static requestService = getRequestServiceCM()

  public static async getCashPoints(
    requestBody: MapCashPointsRequest,
  ): Promise<MapCashPointsResponse> {
    try {
      return await MapsApi.requestService.post('/maps/points', requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashPointsDetailedInformation(guid: string): Promise<MapCashPointInfo> {
    try {
      return await MapsApi.requestService.get('/maps/points/pointInfo', { guid })
    } catch (e) {
      throw new Error(e)
    }
  }
}
