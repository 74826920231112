import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles(() =>
  createStyles({
    wrapperInput: {
      display: 'flex',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '1rem',
      marginLeft: '2rem',
    },
    input: {
      textAlign: 'center',
      width: '40px',
      height: '36px',
      marginLeft: '0',
      border: '1px solid #CAD5D7',
      '&:focus': {
        outline: 0,
      },
    },
  }),
)
