import { Field as FormikField } from 'formik'
import React, { ReactElement, useMemo } from 'react'

import { createCheckboxField } from '@/components/controls/Checkbox/component'
import { createDatePickerRangeField } from '@/components/controls/DateInput/component'
import { createMultipleSelectField } from '@/components/controls/MultipleSelect/component'
import { createSelectField } from '@/components/controls/Select/component'
import { createTextInputField } from '@/components/controls/TextInput/component'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from '../../../reports/components/ReportsModal/components/ReportModalForm/styles'
import { FilterTypeDisplayName, Props } from './types'

const DynamicFields = ({
  params: {
    __required,
    __existslookup,
    values,
    name,
    frendlyName,
    __controltype,
    allowArrayOfFilterValues,
    parameterDataValues,
    mandatory,
    value,
  },
}: Props): ReactElement | null => {
  const translate = useTranslate()
  const classes = useClasses({})

  const Field = useMemo(() => {
    switch (__controltype) {
      case FilterTypeDisplayName.TEXT: {
        return createTextInputField({
          label: frendlyName,
          fullWidth: true,
          required: __required || mandatory,
          // placeholder: toolTip,
          clearButton: true,
          className: classes.textField,
          InputLabelProps: {
            shrink: true,
          },
        })
      }
      case FilterTypeDisplayName.LIST:
        if (__existslookup) {
          if (allowArrayOfFilterValues) {
            return createMultipleSelectField({
              label: frendlyName,
              shrink: true,
              parameterName: name,
              // todo redo
              placeholder: `${translate('translate#title.select')}`,
              SearchInputBlock: true,
              options: parameterDataValues.map(({ __value, __label }) => ({
                name: __label,
                value: __value,
              })),
              // disabled: !parameterDataValues.length,
              required: __required || mandatory,
            })
          }

          return createSelectField({
            label: frendlyName,
            // todo redo
            placeholder: `${translate('translate#title.select')}`,
            shrink: true,
            options: parameterDataValues.map(({ __value, __label }) => ({
              name: __label,
              value: __value,
            })),
            parameterName: name,
            neverDisable: false,
            className: classes.select,
            required: __required || mandatory,
            // disabled: !parameterDataValues.length,
          })
        }
        break
      case FilterTypeDisplayName.RADIOBUTTON:
        return createDatePickerRangeField({
          labelDate: {
            begin: translate('translate#title.start.date'),
            end: translate('translate#title.end.date'),
          },
          wrapperDateClass: classes.wrapperDateBlock,
          // widthClass: classes.widthClass,
          widthClass: classes.subscriptionDate,
          // disabled: intervalOrDate !== 'date',
          format: 'LL',
          // withoutCurrentDate: intervalOrDate === 'interval'
        })
      case FilterTypeDisplayName.CHECKBOX:
        return createCheckboxField({
          className: classes.checkbox,
          width: '45%',
          label: frendlyName,
          required: __required || mandatory,
          defaultChecked: value ?? false,
        })
      default:
        return null
    }
  }, [
    __required,
    __existslookup,
    values,
    name,
    frendlyName,
    __controltype,
    allowArrayOfFilterValues,
  ])

  return Field ? <FormikField name={name}>{Field}</FormikField> : null
}

export default DynamicFields
