import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { FormikProps } from 'formik'
import { debounce } from 'lodash-es'
import React, { ReactElement, useState } from 'react'

import { CommonApi } from '@/api'
import { CityStateRegion, StateRegion } from '@/api/common/commonApi/types'
import { DateInput } from '@/components/controls/DateInput'
import LookupsList from '@/components/controls/LookupsList'
import LookupsSearch from '@/components/controls/LookupsSearch'
import { TextInput } from '@/components/controls/TextInput'
import { hyphenNumbersAndLetters, onlyNumbers } from '@/constants/disabledSymbols'
import { ManualAddressFormValues } from '@/types/adm/device/local'
import { AddressFieldName as FieldName } from '@/types/adm/device/names'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'

const DeviceAddress = ({
  values,
  setFieldValue,
  ...props
}: FormikProps<ManualAddressFormValues>): ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  const [isDependsEnabled, setIsDependsEnabled] = useState<boolean>(true)
  const enableDepends = debounce(() => {
    setIsDependsEnabled(true)
  }, 1000)

  return (
    <>
      <Grid>
        <Typography variant="h6">{translate('translate#adm.deviceAddress')}</Typography>
      </Grid>
      <Grid className={classes.subtitle}>
        <Typography variant="body2">{translate('translate#adm.fillDeviceAddress')}</Typography>
      </Grid>
      <Grid container direction="column">
        <Grid className={classes.field}>
          <LookupsSearch
            isDisabled={false}
            defaultValue={{
              value: values.countryId,
              name: values.countryName,
            }}
            setFormikFieldValue={setFieldValue}
            field={{
              options: {
                label: translate('translate#title.country'),
                placeholder: translate('translate#title.country'),
                required: true,
              },
            }}
            isDependsOn
            fieldName={FieldName.CountryId}
            fieldTitle={FieldName.CountryName}
            onCall={async (
              value: string,
            ): Promise<{ item: any; value: string; name: string }[]> => {
              const response = await CommonApi.getLookupsValues({
                lookupKey: 'COUNTRY_DICT',
                module: 'COM',
                fragment: value,
                pageNo: 0,
                pageSize: 100,
              })
              return response.values.map(item => ({
                item: item,
                value: item.id,
                name: item.name,
              }))
            }}
            onSelect={(item: { value: string; name: string }): void => {
              setFieldValue(FieldName.CountryId, item ? Number(item.value) : null)
              setFieldValue(FieldName.CountryName, item ? item.name : '')
              setFieldValue(FieldName.HouseNr, '')
              setFieldValue(FieldName.Flat, '')
              setFieldValue(FieldName.Address, '')
              setFieldValue(FieldName.Location, '')
            }}
          />
        </Grid>
        <Grid className={classes.field}>
          <LookupsList
            isDisabled={!values.countryId}
            defaultValue={{
              value: values.stateId,
              name: values.stateName,
            }}
            setFormikFieldValue={setFieldValue}
            field={{
              options: {
                label: translate('translate#title.stateName'),
                placeholder: translate('translate#title.stateName'),
                isNumber: true,
              },
            }}
            isDependsOn={isDependsEnabled}
            fieldTitle={FieldName.StateName}
            fieldName={FieldName.StateId}
            dependsOn={[values.countryId]}
            onCall={async (
              value: string,
            ): Promise<{ item: any; value: string; name: string }[]> => {
              const response = await CommonApi.getLookupsState({
                countryId: values.countryId ? values.countryId : -1,
                stateId: -1,
                selectId: -1,
                regionId: values.regionId ? values.regionId : -1,
                fragment: value,
                pageNo: 0,
                pageSize: 100,
              })
              return response.values.map(item => ({
                item: item,
                value: item.id,
                name: item.name,
              }))
            }}
            onSelect={(item: { value: string; name: string }): void => {
              setFieldValue(FieldName.StateId, item ? Number(item.value) : null)
              setFieldValue(FieldName.StateName, item ? item.name : '')
              setFieldValue(FieldName.HouseNr, '')
              setFieldValue(FieldName.Flat, '')
              setFieldValue(FieldName.Address, '')
              setFieldValue(FieldName.Location, '')
            }}
          />
        </Grid>
        <Grid className={classes.field}>
          <LookupsList
            isDisabled={!values.countryId}
            defaultValue={{
              value: values.regionId,
              name: values.regionName,
            }}
            setFormikFieldValue={setFieldValue}
            field={{
              options: {
                label: translate('translate#title.region'),
                placeholder: translate('translate#title.region'),
                isNumber: true,
              },
            }}
            fieldTitle={FieldName.RegionName}
            fieldName={FieldName.RegionId}
            isDependsOn={isDependsEnabled}
            dependsOn={[values.countryId, values.stateId]}
            onCall={async (
              value: string,
            ): Promise<{ item: any; value: string; name: string }[]> => {
              const response = await CommonApi.getLookupsRegion({
                countryId: values.countryId ? values.countryId : -1,
                stateId: values.stateId ? values.stateId : -1,
                selectId: -1,
                regionId: -1,
                fragment: value,
                pageNo: 0,
                pageSize: 100,
              })
              return response.values.map(item => ({
                item: item,
                value: String(item.regionId),
                name: `${item.region}${item.state ? ', ' + item.state : ''}`,
              }))
            }}
            onSelect={(item: { item: StateRegion }): void => {
              setIsDependsEnabled(false)
              setFieldValue(FieldName.RegionId, item ? Number(item.item.regionId) : null)
              setFieldValue(FieldName.RegionName, item ? item.item.region : '')
              setFieldValue(FieldName.StateId, item ? Number(item.item.stateId) : null)
              setFieldValue(FieldName.StateName, item ? item.item.state : '')
              setFieldValue(FieldName.CityId, null)
              setFieldValue(FieldName.CityName, '')
              setFieldValue(FieldName.CityRegionId, null)
              setFieldValue(FieldName.CityRegionName, '')
              setFieldValue(FieldName.HouseNr, '')
              setFieldValue(FieldName.Flat, '')
              setFieldValue(FieldName.Address, '')
              setFieldValue(FieldName.Location, '')

              enableDepends()
            }}
          />
        </Grid>
        <Grid className={classes.field}>
          <LookupsSearch
            isDisabled={!values.countryId}
            defaultValue={{
              value: values.cityId,
              name: values.cityName,
            }}
            field={{
              options: {
                label: translate('translate#title.city'),
                placeholder: translate('translate#title.selectCity'),
              },
            }}
            setFormikFieldValue={setFieldValue}
            fieldName={FieldName.CityId}
            fieldTitle={FieldName.CityName}
            dependsOn={[values.countryId, values.stateId, values.regionId]}
            onCall={async (
              value: string,
            ): Promise<{ item: any; value: string; name: string }[]> => {
              const response = await CommonApi.getLookupsCityStateRegion({
                countryId: values.countryId ? values.countryId : -1,
                stateId: values.stateId ? values.stateId : -1,
                selectId: -1,
                regionId: values.regionId ? values.regionId : -1,
                fragment: value,
                pageNo: 0,
                pageSize: 100,
              })
              return response.values.map(item => ({
                item: item,
                value: String(item.cityId),
                name: `${item.city}${item.region ? ', ' + item.region : ''}${
                  item.state ? ', ' + item.state : ''
                }`,
              }))
            }}
            isDependsOn={isDependsEnabled}
            onSelect={(item: { item: CityStateRegion }): void => {
              setIsDependsEnabled(false)

              setFieldValue(FieldName.RegionId, item ? Number(item.item.regionId) : null)
              setFieldValue(FieldName.RegionName, item ? item.item.region : '')
              setFieldValue(FieldName.StateId, item ? Number(item.item.stateId) : null)
              setFieldValue(FieldName.StateName, item ? item.item.state : '')
              setFieldValue(FieldName.CityId, item ? Number(item.item.cityId) : null)
              setFieldValue(FieldName.CityName, item ? item.item.city : '')
              setFieldValue(FieldName.CityRegionId, null)
              setFieldValue(FieldName.CityRegionName, '')
              setFieldValue(FieldName.HouseNr, '')
              setFieldValue(FieldName.Flat, '')
              setFieldValue(FieldName.Address, '')
              setFieldValue(FieldName.Location, '')

              enableDepends()
            }}
          />
        </Grid>
        <Grid className={classes.field}>
          <LookupsList
            isDisabled={!values.cityId}
            defaultValue={{
              value: values.cityRegionId,
              name: values.cityRegionName,
            }}
            setFormikFieldValue={setFieldValue}
            field={{
              options: {
                label: translate('translate#title.cityRegion'),
                placeholder: translate('translate#title.cityRegion'),
                isNumber: true,
              },
            }}
            fieldName={FieldName.CityRegionId}
            fieldTitle={FieldName.CityRegionName}
            isDependsOn
            dependsOn={[values.countryId, values.stateId, values.regionId, values.cityId]}
            onCall={async (
              value: string,
            ): Promise<{ item: any; value: string; name: string }[]> => {
              const response = await CommonApi.getLookupsCityRegion({
                cityId: values.cityId ? values.cityId : -1,
                selectId: -1,
                fragment: value,
                pageNo: 0,
                pageSize: 100,
              })
              return response.values.map(item => ({
                item: item,
                value: item.id,
                name: item.name,
              }))
            }}
            onSelect={(item: { value: string; name: string }): void => {
              setFieldValue(FieldName.CityRegionId, item ? Number(item.value) : null)
              setFieldValue(FieldName.CityRegionName, item ? item.name : '')
              setFieldValue(FieldName.HouseNr, '')
              setFieldValue(FieldName.Flat, '')
              setFieldValue(FieldName.Address, '')
              setFieldValue(FieldName.Location, '')
            }}
          />
        </Grid>
        <Grid className={classes.field}>
          <LookupsSearch
            isDisabled={!values.cityId}
            defaultValue={{
              value: values.streetId,
              name: values.streetName,
            }}
            setFormikFieldValue={setFieldValue}
            field={{
              options: {
                label: translate('translate#title.street'),
                placeholder: translate('translate#title.street'),
              },
            }}
            isDependsOn
            fieldTitle={FieldName.StreetName}
            fieldName={FieldName.StreetId}
            dependsOn={[
              values.cityId,
              values.countryId,
              values.stateId,
              values.regionId,
              values.cityRegionId,
            ]}
            onCall={async (
              value: string,
            ): Promise<{ item: any; value: string; name: string }[]> => {
              const response = await CommonApi.getLookupsStreet({
                cityId: values.cityId ? values.cityId : -1,
                selectId: -1,
                fragment: value,
                pageNo: 0,
                pageSize: 100,
              })
              return response.values.map(item => ({
                item: item,
                value: item.id,
                name: item.name,
              }))
            }}
            onSelect={(item: { value: string; name: string }): void => {
              setFieldValue(FieldName.StreetId, item ? Number(item.value) : null)
              setFieldValue(FieldName.StreetName, item ? item.name : '')
              setFieldValue(FieldName.HouseNr, '')
              setFieldValue(FieldName.Flat, '')
              setFieldValue(FieldName.Address, '')
              setFieldValue(FieldName.Location, '')
            }}
          />
        </Grid>
        <Grid container justify="space-between">
          <Grid className={classes.field}>
            <TextInput
              disabled={!values.streetId}
              required={!!values.streetId}
              label={translate('translate#adm.houseNr')}
              name={FieldName.HouseNr}
              value={values.houseNumber}
              onChange={props.handleChange}
              disabledKeys={hyphenNumbersAndLetters}
              fullWidth
            />
          </Grid>
          <Grid className={classes.field}>
            <TextInput
              disabled={!values.houseNumber}
              label={translate('translate#adm.flat')}
              name={FieldName.Flat}
              value={values.flatNumber}
              onChange={props.handleChange}
              maxSymbols={3}
              disabledKeys={onlyNumbers}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid className={classes.field}>
          <TextInput
            label={translate('translate#adm.location')}
            name={FieldName.Location}
            value={values.location}
            onChange={props.handleChange}
            fullWidth
          />
        </Grid>
        <Grid className={classes.field}>
          <TextInput
            label={translate('translate#adm.address')}
            name={FieldName.Address}
            value={values[FieldName.Address]}
            onChange={props.handleChange}
            fullWidth
          />
        </Grid>
        <Grid className={classes.field}>
          <DateInput
            label={translate('translate#adm.actualFrom')}
            name={FieldName.ActualFrom}
            value={values[FieldName.ActualFrom]}
            onChange={(date): void => {
              setFieldValue(FieldName.ActualFrom, date.toString())
            }}
            maxDate={new Date()}
            // minDate={initialValues[FieldName.ActualFrom]}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  )
}

export default DeviceAddress
