import { useQuery, UseQueryResult } from 'react-query'

import { CassettesApi } from '@/api/vault-v1/cassettesApi'
import { CassettesButtonsStatuses } from '@/api/vault-v1/swaggerGeneratedApi'
import { queryClient } from '@/constants/queryClient'

const queryOptions = {
  retry: 0,
  refetchOnWindowFocus: false,
}

export const useQueryCassetteButtonsStatuses = (
  guid?: string,
): UseQueryResult<CassettesButtonsStatuses, Error> =>
  useQuery(
    ['cassetteButtonsStatuses', guid],
    async () => guid && (await CassettesApi.buttonsStatuses(guid)),
    {
      ...queryOptions,
      enabled: !!guid,
    },
  )

export const refetchCassetteButtonsStatuses = (guid?: string): unknown =>
  queryClient.fetchQuery(['cassetteButtonsStatuses', guid])
