import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles(({ mixins, spacing, palette }) =>
  createStyles({
    deleteButton: {
      color: palette.error.main,

      fontWeight: 'bold',
      backgroundColor: palette.error.light,
      marginRight: spacing(1),
      '&:hover': {
        backgroundColor: palette.error.light,
      },
    },
  }),
)
