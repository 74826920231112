import { Button, Grid, Paper, Typography } from '@material-ui/core'
import React, { ReactElement, useEffect, useState } from 'react'

import CloseButton from '@/components/blocks/CloseButton'
import { DraggableDialog } from '@/components/blocks/cm/DraggableDialog'
import { useTranslate } from '@/utils/internalization'

import { ButtonsContainer, useClasses } from './styles'
import { Props } from './types'

const DifferencesOfFields = ({ isOpen, onClose, changes }: Props): ReactElement => {
  const translate = useTranslate()
  const classes = useClasses()

  const [fields, setFields] = useState(changes?.props || [])

  useEffect(() => {
    if (changes?.props) {
      setFields(changes?.props)
    }
  }, [changes])

  return (
    <DraggableDialog
      id="differencesOfFieldsModal"
      maxWidth="sm"
      fullWidth
      open={isOpen}
      keepMounted
      onClose={onClose}
      classes={{ paper: classes.dialog }}
    >
      <CloseButton onClick={onClose} absolute />
      <Paper className={classes.paper}>
        <Grid item md={12} className={classes.titleWrapper}>
          <Typography variant="h3" className={classes.modalHeader}>
            {translate('translate#cm.ChangedField')}
          </Typography>
        </Grid>
        {fields.map(({ field: fieldName, value }) => (
          <div key={`${fieldName}-${value}`} className={classes.fieldRow}>
            <div className={classes.filedName}>{translate(`translate#cm.${fieldName}`)}:</div>
            <div className={classes.filedValue}>{value}</div>
          </div>
        ))}

        <ButtonsContainer>
          <Button className={classes.button} color="primary" variant="outlined" onClick={onClose}>
            {translate('translate#cm.ButtonClose')}
          </Button>
        </ButtonsContainer>
      </Paper>
    </DraggableDialog>
  )
}

export default DifferencesOfFields
