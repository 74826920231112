import { useQuery, UseQueryResult } from 'react-query'

import { CitWorkOrderButtonsStatuses, ComUuidValue } from '@/api/cm/swaggerGeneratedApi'
import { queryClient } from '@/constants/queryClient'

import { CitPlanningApi, CitWODetailsApi } from '..'

const queryOptions = {
  retry: 0,
  refetchOnWindowFocus: false,
}

interface Props {
  orderId?: string
  isAllOrders?: boolean
}

export const useQueryCitPlnWOButtons = ({
  orderId,
  isAllOrders = false,
}: Props): UseQueryResult<CitWorkOrderButtonsStatuses, Error> =>
  useQuery(
    ['citPlnWOButtons', orderId, isAllOrders],
    async () => orderId && (await CitWODetailsApi.getWOButtonsState({ orderId, isAllOrders })),
    {
      ...queryOptions,
      enabled: !!orderId,
    },
  )

export const refetchCitPlnWOButtons = ({ orderId, isAllOrders = false }: Props): unknown =>
  queryClient.fetchQuery(['citPlnWOButtons', orderId, isAllOrders])

// Teams

interface TeamsProps {
  workOrderId: string
}
export const useQueryCitPlnWOTeams = ({
  workOrderId,
}: TeamsProps): UseQueryResult<ComUuidValue[], Error> =>
  useQuery(
    ['citPlnWOTeams', workOrderId],
    async () => workOrderId && (await CitPlanningApi.getCashPointProperty('team', workOrderId)),
    {
      ...queryOptions,
      enabled: !!workOrderId,
    },
  )
