import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  root: {
    boxShadow: '0px 0px 15px rgba(37, 173, 191, 0.05)',
    borderRadius: '3px',
    padding: 0,
    marginTop: spacing(2.5),
  },
  table: {
    height: '100%',
    maxHeight: 'calc(100vh - 330px)',
    boxShadow: 'none',
  },
  tableWithElement: {
    height: `calc(100% - ${spacing(9)}px)`,
  },
  tableWithTwoElements: {
    height: `calc(100% - ${spacing(16)}px)`,
  },
  tableWithThreeElements: {
    height: `calc(100% - ${spacing(23)}px)`,
  },
  loaderWrapper: {
    height: spacing(25),
    width: spacing(5),
    margin: 'auto',
  },
  headerCell: {
    background: '#f8f8f8',
    padding: spacing(1, 2),
    borderBottom: 0,
    width: 'auto',
  },
  cell: {
    borderBottom: 0,
  },
  noDataCell: {
    backgroundColor: '#ecf2f2',
    borderBottom: 0,
  },
}))
