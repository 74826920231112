import { useState } from 'react'
import { BackupValues, BackupValue } from './types'

export const useBackupValuesCompany = () => {
  const [backupValues, setBackupValues] = useState<BackupValues | null>(null)

  const setBackupValue = (value: BackupValue): void => {
    setBackupValues(value)
  }

  return { backupValues, setBackupValue, setBackupValues }
}
