import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import React, { useCallback, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Test from 'react-test-attributes'

import Button from '@/components/controls/Button'
import { MultipleSelect } from '@/components/controls/MultipleSelect/component'
import { setDevices, setInitialSelectedDevice } from '@/store/dashboard/actions'
import {
  getDevices,
  getFilter,
  isLookingForCompanies,
  isLookingForDevices,
} from '@/store/dashboard/selectors'
import { getTestId } from '@/utils'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import { Props } from './types'

const WidgetHeaderControls = ({
  isEditing,
  isDisabledFilter,
  testIdPrefix,
  onApply,
}: Props): React.ReactElement => {
  const classes = useClasses()
  const idPrefix = `${testIdPrefix}-widget-header-controls`
  const testId = getTestId(idPrefix)
  const dispatch = useDispatch()
  const translate = useTranslate()
  const deviceTypes = useSelector(getDevices)
  const isLoadingDevices = useSelector(isLookingForDevices)
  const isLoadingCompanies = useSelector(isLookingForCompanies)
  const { company: selectedCompany, devices: selectedDevices } = useSelector(getFilter)

  useEffect(() => {
    return () => {
      dispatch(setInitialSelectedDevice())
    }
  }, [])

  const handleCurrentDeviceTypeChange = useCallback(
    (values: string[]): void => {
      dispatch(setDevices(values))
    },
    [deviceTypes],
  )

  const handleApply = useCallback(() => {
    onApply()
  }, [])

  return (
    <Test id={testId(0)}>
      <div className={classes.headerControls}>
        <Grid container>
          <Grid item>
            <Test id={testId(2)}>
              <MultipleSelect
                label={translate('translate#title.deviceType')}
                options={deviceTypes}
                onChangeMultipleSelect={handleCurrentDeviceTypeChange}
                shrink
                isLoading={isLoadingDevices}
                value={selectedDevices}
                className={classes.input}
                disabled={isEditing || isDisabledFilter}
              />
            </Test>
          </Grid>
          <Grid item>
            <Test id={testId(3)}>
              <Button
                variant="contained"
                disabled={isEditing || isDisabledFilter}
                className={classes.button}
                onClick={handleApply}
              >
                <FormattedMessage id="action.apply" defaultMessage="Apply" />
              </Button>
            </Test>
          </Grid>
        </Grid>
      </div>
    </Test>
  )
}

export default WidgetHeaderControls
