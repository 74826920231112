import { createBrowserHistory } from 'history'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Router, Switch } from 'react-router-dom'

import Loader from '@/components/blocks/Loader'
import AppNavigation from '@/components/wrappers/AppNavigation/component'
import SecuredRoute from '@/components/wrappers/SecuredRoute/component'
import {
  ADM_BLOCK_PREFIX,
  AppNavigationRoutes,
  ATMEYE_BLOCK_PREFIX,
  CASH_MANAGEMENT_BLOCK_PREFIX,
  COMPANIES_BLOCK_PREFIX,
  DICTIONARY_BLOCK_PREFIX,
  PRODUCTS_BLOCK_PREFIX,
  SD_BLOCK_PREFIX,
  SRM_BLOCK_PREFIX,
  USM_BLOCK_PREFIX,
  VAULT_BLOCK_PREFIX,
  VAULT_V1_BLOCK_PREFIX,
} from '@/constants'
import { LocalStorageItems } from '@/constants/localStorageItems'
import {
  REACT_APP_CM_STANDALONE,
  REACT_APP_SD_STANDALONE,
  REACT_APP_VAULT_V1_STANDALONE,
} from '@/constants/moduleMode'
import { putCurrentAccount } from '@/store/auth/actions'
import { getAuthError, getCurrentAccount } from '@/store/auth/selectors'
import { CompaniesRoutes } from '@/storeRoutes/companies'
import { DictionaryRoutes } from '@/storeRoutes/dictionaries'
import { ProductsRoutes } from '@/storeRoutes/products'
import { USMRoutes } from '@/storeRoutes/usm'
import { hasValidToken, hasValidTokenCM, hasValidTokenVaultV1 } from '@/utils/routerPermissions'

import {
  ADMRoutes,
  ATMeyeRoutes,
  CashManagementRoutes,
  SdRoutes,
  SRMRoutes,
  VaultRoutes,
  VaultV1Routes,
} from './storeRoutes'

// @todo replace with real value
const isUserInitializing = false

// setting up lazy loading for common pages/components
const SignInPage = React.lazy(() => import('@/components/pages/common/SignInPage'))
const ChangePasswordPage = React.lazy(() =>
  import('@/components/pages/common/SignInPage/components/ChangePassword/index'),
)

const FallbackRedirect = (): React.ReactElement => (
  <Redirect to={`${AppNavigationRoutes.SignInPage}`} />
)

export const history = createBrowserHistory({ basename: process.env.PUBLIC_URL })

export default (): React.ReactElement => {
  const dispatch = useDispatch()
  const currentAccount = useSelector(getCurrentAccount)
  const error = useSelector(getAuthError)

  useEffect(() => {
    const token = sessionStorage.getItem(LocalStorageItems.AccessToken)
    const tokenCM = localStorage.getItem(LocalStorageItems.AccessTokenCM)
    const isChangePassword = localStorage.getItem(LocalStorageItems.IsChangePassword)
    const tokenVaultV1 = localStorage.getItem(LocalStorageItems.AccessTokenVaultV1)

    const userId = sessionStorage.getItem(LocalStorageItems.UserID)

    if (REACT_APP_CM_STANDALONE === 'true') {
      if (isChangePassword) {
        history.push(AppNavigationRoutes.SignInPage)
        return
      }
      if (window.location.pathname.includes(CASH_MANAGEMENT_BLOCK_PREFIX)) {
        history.push(tokenCM ? window.location.pathname : AppNavigationRoutes.SignInPage)
      } else {
        history.push(tokenCM ? CASH_MANAGEMENT_BLOCK_PREFIX : AppNavigationRoutes.SignInPage)
      }

      return
    }

    if (REACT_APP_VAULT_V1_STANDALONE === 'true') {
      if (isChangePassword) {
        history.push(AppNavigationRoutes.SignInPage)
        return
      }
      if (window.location.pathname.includes(VAULT_V1_BLOCK_PREFIX)) {
        history.push(tokenVaultV1 ? window.location.pathname : AppNavigationRoutes.SignInPage)
      } else {
        history.push(tokenVaultV1 ? VAULT_V1_BLOCK_PREFIX : AppNavigationRoutes.SignInPage)
      }

      return
    }

    if (tokenCM && window.location.pathname.includes(CASH_MANAGEMENT_BLOCK_PREFIX)) {
      return
    }

    if (tokenVaultV1 && window.location.pathname.includes(VAULT_V1_BLOCK_PREFIX)) {
      return
    }

    if (error === 'Change password') {
      history.push(AppNavigationRoutes.ChangePassword)
      return
    }

    if (!token || !userId) {
      history.push(AppNavigationRoutes.SignInPage)
      return
    }

    const account = sessionStorage.getItem(LocalStorageItems.CurrentAccount)
    if (!currentAccount && account) {
      dispatch(putCurrentAccount(JSON.parse(account)))
    }

    if (REACT_APP_SD_STANDALONE === 'true') {
      if (window.location.pathname.includes(`${SD_BLOCK_PREFIX}/web`)) {
        history.push(token ? window.location.pathname : AppNavigationRoutes.SignInPage)
      } else {
        history.push(token ? `${SD_BLOCK_PREFIX}/web` : AppNavigationRoutes.SignInPage)
      }

      return
    }

    if (token && window.location.pathname.includes(`${SD_BLOCK_PREFIX}/web`)) {
    }
  }, [])
  // @todo add private routes
  return (
    <Router history={history}>
      <AppNavigation>
        <React.Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path={AppNavigationRoutes.SignInPage} component={SignInPage} />
            {/* <SecuredRoute */}
            {/* shouldWaitWithCheck={isUserInitializing} */}
            {/* strategy={hasValidToken} */}
            {/* role={UserRoles.Client} */}
            {/* path={SD_BLOCK_PREFIX} */}
            {/* component={SdRoutes} */}
            {/* /> */}

            <Route exact path={AppNavigationRoutes.ChangePassword} component={ChangePasswordPage} />

            {/* <Route path={SD_BLOCK_PREFIX} component={StartPage} exact /> */}
            <SecuredRoute
              shouldWaitWithCheck={false}
              strategy={hasValidToken}
              path={SD_BLOCK_PREFIX + '*'}
              redirectComponent={SignInPage}
              redirectRoute={AppNavigationRoutes.SignInPage}
              component={SdRoutes}
            />
            <SecuredRoute
              shouldWaitWithCheck={false}
              strategy={hasValidToken}
              path={SRM_BLOCK_PREFIX + '*'}
              redirectComponent={SignInPage}
              redirectRoute={AppNavigationRoutes.SignInPage}
              component={SRMRoutes}
            />
            <SecuredRoute
              shouldWaitWithCheck={false}
              strategy={hasValidToken}
              path={ADM_BLOCK_PREFIX}
              redirectComponent={SignInPage}
              redirectRoute={AppNavigationRoutes.SignInPage}
              component={ADMRoutes}
            />
            <Route
              path={VAULT_BLOCK_PREFIX}
              render={(routerProps): React.ReactElement => (
                <VaultRoutes {...routerProps} isUserInitializing={isUserInitializing} />
              )}
            />
            {/* <Route path={USM_BLOCK_PREFIX} component={USMRoutes} /> */}
            <SecuredRoute
              shouldWaitWithCheck={false}
              strategy={hasValidToken}
              path={USM_BLOCK_PREFIX + '*'}
              redirectComponent={SignInPage}
              redirectRoute={AppNavigationRoutes.SignInPage}
              component={USMRoutes}
            />
            <SecuredRoute
              shouldWaitWithCheck={false}
              strategy={hasValidToken}
              path={ATMEYE_BLOCK_PREFIX + '*'}
              redirectComponent={SignInPage}
              redirectRoute={AppNavigationRoutes.SignInPage}
              component={ATMeyeRoutes}
            />
            <SecuredRoute
              shouldWaitWithCheck={false}
              strategy={hasValidTokenCM}
              path={CASH_MANAGEMENT_BLOCK_PREFIX + '*'}
              redirectComponent={SignInPage}
              redirectRoute={AppNavigationRoutes.SignInPage}
              component={CashManagementRoutes}
            />
            <SecuredRoute
              shouldWaitWithCheck={false}
              strategy={hasValidToken}
              path={COMPANIES_BLOCK_PREFIX}
              redirectComponent={SignInPage}
              redirectRoute={AppNavigationRoutes.SignInPage}
              component={CompaniesRoutes}
            />
            <SecuredRoute
              shouldWaitWithCheck={false}
              strategy={hasValidToken}
              path={DICTIONARY_BLOCK_PREFIX}
              redirectComponent={SignInPage}
              redirectRoute={AppNavigationRoutes.SignInPage}
              component={DictionaryRoutes}
            />
            <SecuredRoute
              shouldWaitWithCheck={false}
              strategy={hasValidToken}
              path={PRODUCTS_BLOCK_PREFIX}
              redirectComponent={SignInPage}
              redirectRoute={AppNavigationRoutes.SignInPage}
              component={ProductsRoutes}
            />
            <SecuredRoute
              shouldWaitWithCheck={false}
              strategy={hasValidTokenVaultV1}
              path={VAULT_V1_BLOCK_PREFIX}
              redirectComponent={SignInPage}
              redirectRoute={AppNavigationRoutes.SignInPage}
              component={VaultV1Routes}
            />
            <Route path="*" component={FallbackRedirect} />
          </Switch>
        </React.Suspense>
      </AppNavigation>
    </Router>
  )
}
