import { ScheduleFormValues } from '@/types/adm/device/local'
import { ScheduleFieldName } from '@/types/adm/device/names'

export const getScheduleValues = (
  values: ScheduleFormValues,
  type: 'clean' | 'from24To7',
): ScheduleFormValues =>
  Object.keys(values).reduce((acc: any, key) => {
    if (type === 'clean') {
      acc[key] = {
        [ScheduleFieldName.IsActive]: false,
        [ScheduleFieldName.TimeFrom1]: '00:00',
        [ScheduleFieldName.TimeTo1]: '00:00',
        [ScheduleFieldName.TimeFrom2]: '00:00',
        [ScheduleFieldName.TimeTo2]: '00:00',
      }
    } else {
      acc[key] = {
        [ScheduleFieldName.IsActive]: true,
        [ScheduleFieldName.TimeFrom1]: '00:00',
        [ScheduleFieldName.TimeTo1]: '23:59',
        [ScheduleFieldName.TimeFrom2]: '00:00',
        [ScheduleFieldName.TimeTo2]: '00:00',
      }
    }

    return acc
  }, {})
