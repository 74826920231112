export interface Props {
  width: number
  height: number
}

export enum TableModalsNames {
  SettingsModal = `settingsModal`,
  DocumentModal = 'documentModal',
  FiltersModal = 'filtersModal',
}

export enum CassetteModalNames {
  addCassette = 'add',
  editCassette = 'edit',
}

export enum CassetteStatuses {
  None = 'none',
  Empty = 'empty',
  OnBalance = 'onBalance',
}
