import queryString from 'query-string'

import { AdmUser, AdmUserNetworkTreeNodeBase } from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

interface EditProps {
  guid: string
  payload: AdmUserNetworkTreeNodeBase[]
}

export class UsersNetworksApi {
  private static requestService = getRequestServiceCM()

  public static async get(guid: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/user/networks/nodes',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )

      return await UsersNetworksApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async edit({ guid, payload }: EditProps): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/user/networks/nodes/edit',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )

      return await UsersNetworksApi.requestService.put(url, payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getUsers(sort: Sort): Promise<AdmUser[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/users',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await UsersNetworksApi.requestService.post(url, {})
    } catch (error) {
      throw Error(error)
    }
  }
}
