import {
  AnlChart,
  AnlChartsRequest,
  AnlChartsResponse,
  ComOnDemandDataEntry,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { store } from '@/App'
import { APIConfiguration, AppNavigationRoutes, LocalStorageItems } from '@/constants'
import { history } from '@/Router'
import { signOutVaultV1 } from '@/store/vault-v1/auth'
import { PopUpService } from '@/utils/services/popUpService'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

export class VaultAnalysisApi {
  private static requestService = getRequestServiceVaultV1()

  public static async getCharts(payload: AnlChartsRequest): Promise<AnlChartsResponse> {
    try {
      return await VaultAnalysisApi.requestService.post('/analysis/charts', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getChart(payload: ComOnDemandDataEntry): Promise<AnlChart | null> {
    try {
      const { url, postBody, reportMethod } = payload
      const token = localStorage.getItem(LocalStorageItems.AccessTokenVaultV1) || ''

      let method
      switch (reportMethod) {
        case 'report_get':
          method = 'GET'
          break
        case 'report_post':
          method = 'POST'
          break
        default:
          throw new Error('Report method is not specified')
      }

      const response = await fetch(`${APIConfiguration.VAULT_V1_API_BASE_URL}/${url}`, {
        body: postBody,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
        method,
      })
      const parsedResponse = await response.json()

      if (response?.ok) {
        return parsedResponse
      }

      if (response.status === 401) {
        store.dispatch(signOutVaultV1())
        history.push(AppNavigationRoutes.SignInPage)
        PopUpService.showGlobalPopUp(`translate#response.error.unauthorized`, 'error')
      }

      PopUpService.showGlobalPopUp(parsedResponse.message, 'error')

      return null
    } catch (e) {
      throw new Error(e)
    }
  }
}
