import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment'
import clsx from 'clsx'
import { TableCell, Typography } from '@material-ui/core'
import { Props, CellRef } from './types'
import TableBodyCellContent from '../TableBodyCellContent'

import { useClasses } from './styles'

const cellPaddingSum = 40

const TableBodyCellWrapped = ({
  value,
  groupBy,
  closed,
  withTooltip,
  cellClasses,
  areDatesInTable,
  prefix,
}: Props): React.ReactElement => {
  const classes = useClasses()

  const [tooltip, setTooltip] = useState<boolean>(false)

  const cellWrapperRef = useRef<CellRef>({} as CellRef)
  const textWrapperRef = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    const cellWrapperWidth = cellWrapperRef.current ? cellWrapperRef.current.offsetWidth : 0
    const textWrapperWidth = textWrapperRef.current ? textWrapperRef.current.offsetWidth : 0

    if (cellWrapperWidth - cellPaddingSum < textWrapperWidth) {
      setTooltip(true)
    }
  }, [cellWrapperRef, cellWrapperRef.current, value])

  return (
    <TableCell
      style={
        typeof value !== 'object' || !value
          ? {}
          : value.styles || value.fullBackgroundColor
          ? {
              borderBottom: '1px solid rgb(224, 224, 224)',
              backgroundColor: value.fullBackgroundColor,
            }
          : {} || value.textColor
          ? { color: value.textColor }
          : {}
      }
      className={clsx(cellClasses, classes.contentItem, {
        [classes.cell]: withTooltip,
        [classes.cellPadding]: prefix === 'PSR',
      })}
      ref={cellWrapperRef}
    >
      <Typography
        ref={textWrapperRef}
        className={clsx(classes.contentItemText, {
          [classes.contentItemTextWithTooltip]: tooltip,
        })}
      >
        {value && areDatesInTable && moment.isDate(value) && (
          <TableBodyCellContent
            groupBy={groupBy}
            closed={closed}
            value={moment(value).format('MMM DD, YYYY')}
            withTooltip={tooltip}
          />
        )}
        {value && typeof value === 'object' && value.hideValue ? (
          <div />
        ) : (
          <TableBodyCellContent
            groupBy={groupBy}
            closed={closed}
            value={value}
            withTooltip={tooltip}
          />
        )}
      </Typography>
    </TableCell>
  )
}

export default TableBodyCellWrapped
