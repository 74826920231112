import { createSelector } from 'reselect'
import { AppState } from '@/store/reducers'
import * as fromDrawerActions from '../reducer'
import { initialState } from '../reducer'
import { POSServiceFormParameter, POSServiceFormParameterDetails } from '@/api/sd/posApi/types'

import { isEmpty } from 'lodash-es'
import { POSServiceFieldTypes } from '@/components/blocks/DynamicFieldsGroupWrapper/components/DynamicField/types'

interface GroupedParameters {
  [key: string]: POSServiceFormParameter
}

export const getPOSServiceFormInitialValues = (
  fieldParameters: POSServiceFormParameter,
): {
  [key: string]: string | string[] | null
} =>
  Object.values(fieldParameters).reduce((accum, value: POSServiceFormParameterDetails) => {
    const { attributeName, attributeId, defaultValue, displayTypeId, multiple } = value
    let initValue

    switch (displayTypeId) {
      case POSServiceFieldTypes.CHECKBOXLIST:
      case POSServiceFieldTypes.COMBOMULTISELECT:
        if (multiple) {
          initValue = (defaultValue && [...defaultValue]) || []
        }
        break
      case POSServiceFieldTypes.Date:
        initValue = (defaultValue && new Date(defaultValue)) || null
        break
      case POSServiceFieldTypes.CHECKBOX:
        initValue = false
        break
      default:
        initValue = defaultValue || ''
    }

    // for priority field
    if (attributeId === 3393) {
      initValue = defaultValue || '2'
    }

    return {
      ...accum,
      [attributeName]: initValue,
    }
  }, {})

export const getDrawerActionsState = (state: AppState): fromDrawerActions.State =>
  state.drawerActions || initialState

export const getServiceCompanies = createSelector(getDrawerActionsState, state => {
  return state.createPOSTicketFormConfig.serviceCompanies.map(company => ({
    name: company.name,
    value: `${company.id}`,
  }))
})

export const getMerchants = createSelector(getDrawerActionsState, state => {
  return state.existingMerchants.existingMerchants
    ? state.existingMerchants.existingMerchants.map(merchant => ({
        name: merchant.name,
        value: `${merchant.id}`,
      }))
    : []
})

export const getIsLookingMerchants = createSelector(
  getDrawerActionsState,
  state => state.isLookingForExistingMerchants,
)

export const getServiceTypes = createSelector(getDrawerActionsState, state => {
  return state.createPOSTicketFormConfig.serviceTypes.map(service => ({
    name: service.name,
    value: `${service.id}`,
  }))
})

export const isLookingForCreatingNewTicketFormConfig = createSelector(
  getDrawerActionsState,
  state => state.isLookingForCreatePOSTicketFormConfig,
)

export const isLookingForCreatingNewTicketEditFormConfig = createSelector(
  getDrawerActionsState,
  state => state.isLookingForCreatePOSEditTicketFormConfig,
)

export const getPOSServiceFormConfigInitialValues = createSelector(getDrawerActionsState, state =>
  isEmpty(state.POSServiceTypeFormConfig)
    ? {}
    : getPOSServiceFormInitialValues(state.POSServiceTypeFormConfig.fieldParameters),
)

export const getPOSServiceFormConfigParameters = createSelector(
  getDrawerActionsState,
  state => state.POSServiceTypeFormConfig.fieldParameters,
)

export const getPOSServiceFormConfigGroupedParameters = createSelector(
  getDrawerActionsState,
  state => state.POSServiceTypeFormConfig,
)

export const getPOSMerchantFormFieldsGroups = createSelector(
  getDrawerActionsState,
  state => state.POSMerchantFormConfig,
)

export const getPOSServiceFormTitle = createSelector(
  getDrawerActionsState,
  state => state.POSServiceTypeFormConfig.title,
)

export const isLookingForCreatePOSTicketServiceTypeFormConfig = createSelector(
  getDrawerActionsState,
  state => state.isLookingForCreatePOSTicketServiceTypeFormConfig,
)

export const getCustomerNumber = createSelector(
  getDrawerActionsState,
  state => state.createPOSTicketFormConfig.initialParameters.docnr,
)

export const getSaveRequestInitialParameters = createSelector(
  getDrawerActionsState,
  state => state.createPOSTicketFormConfig.initialParameters,
)

export const getIsSavingPOSTicket = createSelector(
  getDrawerActionsState,
  state => state.isSavingPOSTicket,
)

export const getIsSavedSuccessful = createSelector(
  getDrawerActionsState,
  state => state.isSavedSuccessful,
)

export const getDocumentId = createSelector(
  getDrawerActionsState,
  state =>
    state.saveTicketResponse.saveTicketData.documentId ||
    (state.editingPosTicketInfo.documentid ? +state.editingPosTicketInfo.documentid : undefined),
)

export const getSavedPOSTicketGrantedOperations = createSelector(
  getDrawerActionsState,
  state => state.saveTicketResponse.grantedOperations,
)

export const getPOSError = createSelector(getDrawerActionsState, state => state.error)

export const getServiceTypeItems = createSelector(
  getDrawerActionsState,
  state => state.serviceTypeItems,
)

export const getNotSavedServiceTypeEditIndex = createSelector(
  getDrawerActionsState,
  state => state.notSavedPOSServiceRequestEditIndex,
)

export const getNotSavedServiceTypeItems = createSelector(
  getDrawerActionsState,
  state => state.notSavedPOSServiceRequests,
)

export const getPosTicketInfo = createSelector(
  getDrawerActionsState,
  state => state.editingPosTicketInfo,
)

export const isDeletingPosRequestItem = createSelector(
  getDrawerActionsState,
  state => state.isDeletingPosRequestItem,
)

export const hasRequestError = createSelector(getDrawerActionsState, state => state.error)

export const getIsDeletingPOSTicket = createSelector(
  getDrawerActionsState,
  state => state.isDeletingPosTicket,
)

export const getIsTicketHasDeletedSuccessful = createSelector(
  getDrawerActionsState,
  state => state.isTicketHasDeletedSuccessful,
)

export const getIsLookingForCreatePOSEditTicketFormConfig = createSelector(
  getDrawerActionsState,
  state => state.isLookingForCreatePOSEditTicketFormConfig,
)

export const getCreatePOSTicketFormInitGrantedOperations = createSelector(
  getDrawerActionsState,
  state => state.createPOSTicketFormConfig.grantedOperations,
)

export const getCreatePOSTicketFormEditingGrantedOperations = createSelector(
  getDrawerActionsState,
  state => state.editingPosTicketInfo.grantedOperations,
)

export const getIsSendingPosTicket = createSelector(
  getDrawerActionsState,
  state => state.isSendingPosTicket,
)

export const getIsTicketHasSentSuccessful = createSelector(
  getDrawerActionsState,
  state => state.isTicketHasSentSuccessful,
)

export const getIsTicketHasError = createSelector(
  getDrawerActionsState,
  state => state.isTicketHasError,
)

export const getMCCCodeRequestFragmentData = createSelector(
  getDrawerActionsState,
  state => state.mccCode,
)
