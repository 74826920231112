export const TICKET_STATUSES: TicketStatuses = {
  undefined: { value: 'undefined', color: '#50A8AE', backgroundColor: '#f2f2f2' },
  created: { value: 'created', color: '#47D08F', backgroundColor: '#ecf8f2' },
  prepared: { value: 'prepared', color: '#F2C94C', backgroundColor: '#fefaee' },
  finished: { value: 'finished', color: '#F2994A', backgroundColor: '#feefe7' },
  running: { value: 'running', color: '#2F80ED', backgroundColor: '#eaf3fe' },
  submitted: { value: 'submitted', color: '#99BE54', backgroundColor: '#f5f8ee' },
  suspended: { value: 'suspended', color: '#EB5757', backgroundColor: '#ffe9ed' },
  planned: { value: 'planned', color: '#435895', backgroundColor: '#dee2ed' },
  sentToExecutant: {
    value: 'sentToExecutant',
    color: '#50A8AE',
    backgroundColor: '#f2f2f2',
  },
  generated: { value: 'generated', color: '#50A8AE', backgroundColor: '#f2f2f2' },
  partiallyGenerated: {
    value: 'partiallyGenerated',
    color: '#50A8AE',
    backgroundColor: '#f2f2f2',
  },
  negative: { value: 'negative', backgroundColor: '#FDEFED', color: '#FF7362' },
  positive: { value: 'positive', backgroundColor: '#E6FAF0', color: '#00C562' },

  suspendedDueCancelWO: {
    value: 'suspendedDueCancelWO',
    color: '#50A8AE',
    backgroundColor: '#f2f2f2',
  },
}

interface TicketStatuses {
  [key: string]: {
    value: string
    color: string
    backgroundColor: string
  }
}

export const eventColors = [
  { color: '#50A8AE', backgroundColor: '#f2f2f2' },
  { color: '#47D08F', backgroundColor: '#ecf8f2' },
  { color: '#F2C94C', backgroundColor: '#fefaee' },
  { color: '#F2994A', backgroundColor: '#feefe7' },
  { color: '#2F80ED', backgroundColor: '#eaf3fe' },
]

export const otherColor = { color: '#2F80ED', backgroundColor: '#eaf3fe' }

export const deviceColors = {
  negative: { backgroundColor: '#FDEFED', color: '#FF7362' },
  positive: { backgroundColor: '#E6FAF0', color: '#00C562' },
}
