import { InitialFormValues } from './types'

export const initialValues: InitialFormValues = {
  // Device
  terminalUid: '',
  deviceStateId: '',
  deviceTypeId: '',
  deviceStateName: '',
  deviceTypeName: '',
  deviceName: '',
  deviceDescription: '',
  // Hardware
  hardwareId: '',
  hardwareName: '',
  serialNumber: '',
  vendorId: '',
  modelId: '',
  vendorName: '',
  modelName: '',
  hardwareTypeId: '',
  hardwareTypeName: '',
  hardwareDescription: '',
  // Address
  countryId: '',
  stateId: '',
  regionId: '',
  cityId: '',
  cityRegionId: '',
  streetId: '',
  houseNumber: '',
  flatNumber: '',
  location: '',
  address: '',
  // Companies
  companies: [
    {
      companyRoleId: '1',
      companyRoleName: 'translate#adm.hardwareOwnerDefault',
      companyId: '',
      companyName: '',
      luno: '',
      agreementNo: '',
      saFrom: '',
      saTo: '',
    },
  ],
  // Schedule
  schedule: {
    monday: {
      isActive: false,
      timeFrom1: '00:00',
      timeTo1: '00:00',
      timeFrom2: '00:00',
      timeTo2: '00:00',
    },
    tuesday: {
      isActive: false,
      timeFrom1: '00:00',
      timeTo1: '00:00',
      timeFrom2: '00:00',
      timeTo2: '00:00',
    },
    wednesday: {
      isActive: false,
      timeFrom1: '00:00',
      timeTo1: '00:00',
      timeFrom2: '00:00',
      timeTo2: '00:00',
    },
    thursday: {
      isActive: false,
      timeFrom1: '00:00',
      timeTo1: '00:00',
      timeFrom2: '00:00',
      timeTo2: '00:00',
    },
    friday: {
      isActive: false,
      timeFrom1: '00:00',
      timeTo1: '00:00',
      timeFrom2: '00:00',
      timeTo2: '00:00',
    },
    saturday: {
      isActive: false,
      timeFrom1: '00:00',
      timeTo1: '00:00',
      timeFrom2: '00:00',
      timeTo2: '00:00',
    },
    sunday: {
      isActive: false,
      timeFrom1: '00:00',
      timeTo1: '00:00',
      timeFrom2: '00:00',
      timeTo2: '00:00',
    },
  },
}
