import clsx from 'clsx'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

import { TextInput } from '@/components/controls/TextInput'
import { numberWithDecimalExp } from '@/constants'
import { useFormatNumber } from '@/utils/internalization'

import { StyledText, useClasses } from './styles'
import { Props } from './types'

const useDecimal = (): string => {
  const intl = useIntl()

  return useMemo(() => intl.formatNumber(1.1).replace(/1/g, ''), [intl])
}

export const EditableContainer: FC<Props> = React.memo((props: Props) => {
  const {
    id,

    value,
    onlyNumber,
    disabled = false,
    isInput = false,
    type = 'text',
    autoFocus = !isInput,
    inputWith = isInput ? '100%' : 'auto',

    styles,

    textAlign = onlyNumber ? 'right' : 'left',
    position = 'absolute',

    withoutEmpty = false,
    maxNumberValue,

    handleEveryCharacterChange,

    onChange,
    onPast,
    onKeyDown,

    forbiddenCharactersRegExp,

    isPreventDoubleClick = false,
    error = false,

    ...rest
  } = props

  const classes = useClasses({
    inputWith,
    background: styles?.background,
    position,
    borderColor: styles?.borderColor,
  })
  const decimal = useDecimal()
  const { formatNumber, reverseFormatNumber, formatWithDecimal } = useFormatNumber()

  const [isEdit, setIsEdit] = useState(false)
  const [editableValue, setEditableValue] = useState<string | number>(value || '')

  const inputValue = useMemo(
    () =>
      onlyNumber && !editableValue ? 0 : onlyNumber ? formatNumber(editableValue) : editableValue,
    [onlyNumber, editableValue],
  )

  const forbiddenCharacters = useMemo(
    () =>
      onlyNumber && !forbiddenCharactersRegExp ? numberWithDecimalExp : forbiddenCharactersRegExp,
    [onlyNumber, forbiddenCharactersRegExp],
  )

  useEffect(() => {
    const editable = onlyNumber ? formatNumber(value || 0) : value

    setEditableValue(editable)
  }, [value])

  const handleDoubleClick = (): void => {
    !isPreventDoubleClick && setIsEdit(true)
  }

  const handleInputAction = (): void => {
    setIsEdit(false)

    if (withoutEmpty && editableValue === '') {
      setEditableValue(value)
      return
    }

    if (value !== editableValue) {
      if (onlyNumber) {
        setEditableValue(formatNumber(editableValue))
      }

      onChange &&
        onChange(
          onlyNumber && typeof editableValue === 'string'
            ? reverseFormatNumber(editableValue)
            : editableValue,
        )
    }
  }

  const handleBlur = (): void => {
    handleInputAction()
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      handleInputAction()
    }
    if (onKeyDown) {
      onKeyDown(event)
    }
  }

  const setFormatOrRegularText = (value: string): void => {
    setEditableValue(onlyNumber ? formatWithDecimal(value) : value)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const localValue = event.target.value

    if (maxNumberValue && Number(localValue) > maxNumberValue) {
      setFormatOrRegularText(maxNumberValue.toString())
      return
    }

    if (localValue[0] === '0' && localValue[1] !== decimal && onlyNumber) {
      setFormatOrRegularText(localValue.substring(1))
      return
    }

    setFormatOrRegularText(localValue)
    handleEveryCharacterChange && handleEveryCharacterChange(localValue)
  }

  const handlePast = (event: React.ClipboardEvent<HTMLDivElement>): void => {
    event.stopPropagation()

    if (onPast) {
      const value = event.clipboardData.getData('text')
      setFormatOrRegularText(value)

      onPast(value)
    }
  }

  return isInput || isEdit ? (
    <>
      <TextInput
        id={id}
        value={inputValue || ''}
        disabled={disabled}
        type={type}
        variant="outlined"
        autoFocus={autoFocus}
        style={{ width: inputWith || 'auto', background: styles?.background }}
        className={clsx(classes.fontInput, styles?.input)}
        onPaste={handlePast}
        onBlur={handleBlur}
        onKeyPress={onKeyPress}
        error={error}
        onChange={handleChange}
        inputProps={{ style: { textAlign } }}
        InputProps={{
          className: clsx(styles?.fontInput),
          classes: {
            notchedOutline: clsx({
              [classes.borderColor]: styles?.background || styles?.borderColor,
            }),
          },
        }}
        forbiddenCharactersRegExp={forbiddenCharacters}
        {...rest}
      />
      {inputWith && !!(editableValue || '').toLocaleString().length && (
        <span className={classes.cont} contentEditable suppressContentEditableWarning tabIndex={-1}>
          {editableValue}
        </span>
      )}
    </>
  ) : (
    <StyledText
      isPreventDoubleClick={isPreventDoubleClick}
      className={clsx(styles?.input)}
      disabled={disabled}
      onDoubleClick={handleDoubleClick}
    >
      {onlyNumber ? formatNumber(editableValue || 0) : editableValue}
    </StyledText>
  )
})
