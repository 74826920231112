import { AnyAction } from 'redux'
import { NotificationsActions } from '@/constants/actions'
import { Notification } from '@/types/srm/notificaitons'

export const toggleNotifications = (): AnyAction => ({
  type: NotificationsActions.ToggleNotifications,
})

export const activateNotifications = (): AnyAction => ({
  type: NotificationsActions.ActivateNotifications,
})

export const disableNotifications = (): AnyAction => ({
  type: NotificationsActions.DisableNotifications,
})

export const setNewNotifications = (payload: Notification[]): AnyAction => ({
  type: NotificationsActions.SetNewNotifications,
  payload,
})

export const readNotifications = (): AnyAction => ({
  type: NotificationsActions.ReadNotifications,
})
