// core
import React from 'react'
// patch
import { AppNavigationRoutes, USM_BLOCK_PREFIX } from '@/constants'
// icons
import Users from '@/assets/usm/Users.svg'
import SecurityNetworks from '@/assets/usm/Security-networks.svg'
import ActionLog from '@/assets/usm/Action-log.svg'
import GroupPermissions from '@/assets/usm/sideMenu/Group-Permishions.svg'
import MergeSecurityNetworks from '@/assets/usm/sideMenu/Merge-Security-Networks.svg'

export default [
  {
    path: `${USM_BLOCK_PREFIX}${AppNavigationRoutes.USmUsersPage}`,
    label: 'translate#usm.side.menu.title.users',
    Icon: Users,
  },
  {
    path: `${USM_BLOCK_PREFIX}${AppNavigationRoutes.USmSecurityNetworks}`,
    label: 'translate#usm.side.menu.title.security.networks',
    Icon: SecurityNetworks,
  },
  {
    path: ``,
    label: 'translate#usm.side.menu.title.merge.security.networks',
    Icon: MergeSecurityNetworks,
  },
  {
    path: `${USM_BLOCK_PREFIX}${AppNavigationRoutes.USmActionLog}`,
    label: 'translate#usm.side.menu.title.action.log',
    Icon: ActionLog,
  },
  {
    path: `${USM_BLOCK_PREFIX}${AppNavigationRoutes.USmGroupPermissions}`,
    label: 'translate#usm.side.menu.title.group.permissions',
    Icon: GroupPermissions,
  },
]
