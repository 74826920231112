import queryString from 'query-string'

import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

import {
  AdmPlnRoute,
  AdmPlnRouteBase,
  AdmPlnRouteCashPoint,
  CitPlnAcceptRequest,
  CitPlnApproveWorkOrderRequest,
  CitPlnCalculateRequest,
  CitPlnCashPoint,
  CitPlnCashPointsRequest,
  CitPlnCashPointsResponse,
  CitPlnCreateWorkOrderRequest,
  CitPlnDeclineCashOrderRequest,
  CitPlnDeclineRequest,
  CitPlnDeleteCashOrderRequest,
  CitPlnDeleteWorkOrderRequest,
  CitPlnLimit,
  CitPlnReportsRequest,
  CitPlnSetOrderTasksRequest,
  CitPlnSetPropertyRequest,
  CitPlnSetUnloadingRequest,
  CitPlnSetUpperLimitsRequest,
  CitPlnSetWorkOrderCassettesRequest,
  CitPlnSpecifiedWOReportGeneratorEntry,
  CitPlnSpecifiedWOReportsRequest,
  CitPlnVaultTransaction,
  CitPlnWorkOrderCassettesResponse,
  CitPlnWorkOrdersReportsRequest,
  CitPlnWorkOrdersRequest,
  ComCMReport,
  ComOnDemandDataEntry,
  ComUuidValue,
} from '../swaggerGeneratedApi'

export class CitPlanningApi {
  private static requestService = getRequestServiceCM()

  public static async getCashPoints(
    paging: { page: number; pageSize: number },
    requestBody: CitPlnCashPointsRequest,
    sortColumn?: string | undefined,
    sortOrder?: 'asc' | 'desc' | undefined,
  ): Promise<CitPlnCashPointsResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/cit/planning/points',
          query: {
            pageSize: `${paging.pageSize}`,
            page: `${paging.page}`,
            sortColumn,
            sortOrder,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await CitPlanningApi.requestService.post(url, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getWorkOrders(
    requestBody: CitPlnWorkOrdersRequest,
    paging: { page: number; pageSize: number },
    sortColumn?: string | undefined,
    sortOrder?: 'asc' | 'desc' | undefined,
  ): Promise<CitPlnCashPointsResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/cit/planning/workorders',
          query: {
            sortColumn,
            sortOrder,
            pageSize: `${paging.pageSize}`,
            page: `${paging.page}`,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await CitPlanningApi.requestService.post(url, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashPoint(guid: string): Promise<CitPlnCashPoint> {
    try {
      return await CitPlanningApi.requestService.get(`/cit/planning/point?guid=${guid}`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getUpperLimits(guid: string): Promise<CitPlnLimit[]> {
    try {
      return await CitPlanningApi.requestService.get(
        `/cit/planning/points/getUpperLimits?guid=${guid}`,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setUpperLimits(payload: CitPlnSetUpperLimitsRequest): Promise<void> {
    try {
      await CitPlanningApi.requestService.post('/cit/planning/points/setUpperLimits', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashPointProperty(
    propertyType: string,
    workOrderId?: string,
  ): Promise<ComUuidValue[]> {
    try {
      return await CitPlanningApi.requestService.post('/cit/planning/points/getProperties', {
        propertyType,
        workOrderId,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setCashPointProperty(payload: CitPlnSetPropertyRequest): Promise<void> {
    try {
      return await CitPlanningApi.requestService.post(
        '/cit/planning/points/setCashPointProperty',
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async deleteCashOrder(
    payload: CitPlnDeleteCashOrderRequest,
  ): Promise<CitPlnCashPoint> {
    try {
      return await CitPlanningApi.requestService.post('/cit/planning/deleteCashOrder', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async declineCashOrder(
    payload: CitPlnDeclineCashOrderRequest,
  ): Promise<CitPlnCashPoint> {
    try {
      return await CitPlanningApi.requestService.post('/cit/planning/declineCashOrder', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setUnloading(payload: CitPlnSetUnloadingRequest): Promise<CitPlnCashPoint> {
    try {
      return await CitPlanningApi.requestService.post('/cit/planning/setUnloading', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async declineCashPlan(payload: CitPlnDeclineRequest): Promise<CitPlnCashPoint> {
    try {
      return await CitPlanningApi.requestService.post('/cit/planning/decline', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async calculateCashPoint(
    payload: CitPlnCalculateRequest,
  ): Promise<CitPlnCashPoint> {
    try {
      return await CitPlanningApi.requestService.post('/cit/planning/calculate', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async acceptCashPoint(payload: CitPlnAcceptRequest): Promise<CitPlnCashPoint> {
    try {
      return await CitPlanningApi.requestService.post('/cit/planning/accept', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async createWorkOrder(
    payload: CitPlnCreateWorkOrderRequest,
  ): Promise<CitPlnCashPoint> {
    try {
      return await CitPlanningApi.requestService.post('/cit/planning/createWorkOrder', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async approveWorkOrder(payload: CitPlnApproveWorkOrderRequest): Promise<void> {
    try {
      return await CitPlanningApi.requestService.post('/cit/planning/approveWorkOrder', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async deleteWorkOrder(payload: CitPlnDeleteWorkOrderRequest): Promise<void> {
    try {
      return await CitPlanningApi.requestService.post('/cit/planning/deleteWorkOrder', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getWorkOrdersReports(
    payload: CitPlnWorkOrdersReportsRequest,
  ): Promise<ComCMReport[]> {
    try {
      return await CitPlanningApi.requestService.post('/cit/planning/workorders/reports', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getReports(payload: CitPlnReportsRequest): Promise<ComCMReport[]> {
    try {
      return await CitPlanningApi.requestService.post('/cit/planning/reports', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getWorkOrderReports(
    postBody: CitPlnSpecifiedWOReportsRequest,
  ): Promise<CitPlnSpecifiedWOReportGeneratorEntry> {
    try {
      return await CitPlanningApi.requestService.post(
        '/cit/planning/workorders/specified/reports',
        postBody,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getRepairOrderReports(postBody: {
    repairOrderName?: string
    sessionId?: string
  }): Promise<ComOnDemandDataEntry> {
    try {
      return await CitPlanningApi.requestService.post(
        '/cit/planning/repairOrders/reports',
        postBody,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getUnloadOrderReports(postBody: {
    repairOrderName?: string
    sessionId?: string
  }): Promise<ComOnDemandDataEntry> {
    try {
      return await CitPlanningApi.requestService.post(
        '/cit/planning/unloadOrders/reports',
        postBody,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getUnloadOrder({
    name,
    sort,
  }: {
    name: string
    sort: {
      sortColumn?: string | undefined
      sortOrder?: 'asc' | 'desc' | undefined
    }
  }): Promise<string[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'cit/planning/unloadOrders/editor/tasks',
          query: {
            name,
            ...sort,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )

      return await CitPlanningApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getRepairOrder({
    name,
    sort,
  }: {
    name: string
    sort: {
      sortColumn?: string | undefined
      sortOrder?: 'asc' | 'desc' | undefined
    }
  }): Promise<string[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'cit/planning/repairOrders/editor/tasks',
          query: {
            name,
            ...sort,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )

      return await CitPlanningApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async applyRepairOrder(payload: CitPlnSetOrderTasksRequest): Promise<string[]> {
    try {
      return await CitPlanningApi.requestService.put(
        '/cit/planning/repairOrders/editor/tasks/update',
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async applyUnloadOrder(payload: CitPlnSetOrderTasksRequest): Promise<string[]> {
    try {
      return await CitPlanningApi.requestService.put(
        '/cit/planning/unloadOrders/editor/tasks/update',
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getWorkOrderCassettes(payload: {
    name: string
    sort: {
      sortColumn?: string | undefined
      sortOrder?: 'asc' | 'desc' | undefined
    }
  }): Promise<CitPlnWorkOrderCassettesResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'cit/planning/workorders/editor/cassetteSets',
          query: {
            name: payload.name,
            ...payload.sort,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await CitPlanningApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async applyWOCassettesStamps(
    entries: CitPlnSetWorkOrderCassettesRequest,
  ): Promise<void> {
    try {
      return await CitPlanningApi.requestService.put(
        'cit/planning/workorders/editor/cassetteSets/update',
        entries,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async vaultTransaction(body: CitPlnVaultTransaction): Promise<string[]> {
    try {
      return await CitPlanningApi.requestService.post('cit/planning/vaultTransaction', body)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getRoutes(sort: Sort): Promise<AdmPlnRoute[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'cit/planning/routes',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )
      return await CitPlanningApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async addRoute(route: AdmPlnRouteBase): Promise<void> {
    try {
      return await CitPlanningApi.requestService.post('cit/planning/routes/add', route)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async updateRoute(route: AdmPlnRoute): Promise<void> {
    try {
      return await CitPlanningApi.requestService.put('cit/planning/routes/update', route)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async deleteRoute(id: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'cit/planning/routes/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )
      return await CitPlanningApi.requestService.delete(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getRoutesObjects(sort: Sort): Promise<AdmPlnRouteCashPoint[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'cit/planning/routes/points',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )
      return await CitPlanningApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }
}
