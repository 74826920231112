import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { CashFlowApi, ParsedCashFlowResponse } from '@/api/vault-v1/cashFlowApi'
import { VaultV1CashFlowActions } from '@/constants'

import { getGlobalFilterVaultV1 } from '../common'
import { getCashFlowsFail, getCashFlowsResponse } from './actions'
import { getCashFlowFilter } from './selectors'

// @TODO change types after apy implementation
function* getCashFlow(): SagaIterator {
  try {
    const filter = yield select(getCashFlowFilter)
    const globalFilter = yield select(getGlobalFilterVaultV1)

    const response: ParsedCashFlowResponse[] = yield call(CashFlowApi.getAll, {
      globalFilter,
      filter,
    })

    if (response) {
      yield put(getCashFlowsResponse(response))
    }
  } catch (error) {
    yield put(getCashFlowsFail(String(error as Error)))
  }
}

export default function*(): Generator {
  yield takeLatest(
    [VaultV1CashFlowActions.GetCashFlowsRequest, VaultV1CashFlowActions.FlwSetFilter],
    getCashFlow,
  )
}
