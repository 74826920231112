import Grid from '@material-ui/core/Grid'
import React, { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react'

import { Select } from '@/components/controls/Select/component'

import { SortBlock } from '../SortBlock/components'
import { useClasses } from './style'
import { Props } from './types'

export const GroupRow = ({
  disabled,
  label,
  group,
  groupsOptions,
  setGroup,
  hideEmptyItem,
}: Props): ReactElement => {
  const [value, setValue] = useState('')

  const classes = useClasses()

  useEffect((): void => {
    setValue(group?.groupingField || '')
  }, [group])

  const handleChange = useCallback(
    (e: ChangeEvent<{ value: any }>): void => {
      setValue(e.target.value)

      if (e.target.value) {
        setGroup({
          groupingField: e.target.value,
          groupingSortType: 'BY_NAME',
          groupingSortDirection: 'ASC',
        })
      } else {
        setGroup(undefined)
      }
    },
    [setGroup],
  )

  return (
    <Grid container spacing={2}>
      <Grid item className={classes.fixedWidth}>
        <Select
          hideEmptyItem={hideEmptyItem}
          withoutCheckerForSingleOption
          shrink
          disabled={disabled}
          className={classes.selectButton}
          label={label}
          value={value}
          options={groupsOptions}
          onChange={handleChange}
        />
      </Grid>

      <Grid item>
        <SortBlock
          disabled={disabled || !value}
          groupKey={value}
          group={group}
          setGroup={setGroup}
        />
      </Grid>
    </Grid>
  )
}
