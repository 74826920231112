import { useQuery, UseQueryResult } from 'react-query'

import { BagStatusesResponse } from '@/api/vault-v1/swaggerGeneratedApi'
import { queryClient } from '@/constants/queryClient'

import { BagsApi } from '..'

const queryOptions = {
  retry: 0,
  refetchOnWindowFocus: false,
}

export const useQueryBagButtonsStatuses = (
  guid?: string,
): UseQueryResult<BagStatusesResponse, Error> =>
  useQuery(
    ['bagButtonsStatuses', guid],
    async () => guid && (await BagsApi.buttonsStatuses(guid)),
    {
      ...queryOptions,
      enabled: !!guid,
    },
  )

export const refetchBagButtonsStatuses = (guid?: string): unknown =>
  queryClient.fetchQuery(['bagButtonsStatuses', guid])
