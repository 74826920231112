import makeStyles from '@material-ui/core/styles/makeStyles'

import colors from '@/theme/colors'

export const useClasses = makeStyles(({ palette, spacing }) => ({
  input: {
    margin: spacing(2, 0),
    width: '100%',
  },
  icon: {
    '& > *': {
      padding: 0,
    },
  },
  asterisk: {
    '& span': {
      color: 'red',
    },
  },
  label: {
    color: colors.textLight,

    '& > *': {
      color: 'red',
    },
  },

  inputWithError: {
    marginBottom: 0,
  },

  errorLabel: {
    color: `${palette.error.main}!important`,
  },

  errorHelperText: {
    height: spacing(2),
    fontSize: spacing(1.5),
    color: palette.error.main,
    marginTop: `-${spacing(0.5)}px`,
  },

  range: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > span': {
      marginRight: spacing(2),
      whiteSpace: 'nowrap',
    },

    '& > div': {
      display: 'flex',
    },
  },
  rangeFullWidth: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  rangeColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  rangeRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dateFrom: {
    width: 'calc(100% - 54px)',
    marginLeft: '54px',
  },
  mr: {
    marginRight: spacing(2),
  },
  disabled: {
    opacity: 0.7,
    pointerEvents: 'none',
  },
  checkbox: {
    marginRight: '12px',
  },
}))
