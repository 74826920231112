export enum AtmeyeEventDictionary {
  ChangeSelectedFilters = '@/EVENT_CODE_EDITOR/CHANGE_SELECTED_FILTERS',

  ChangeSorting = '@/EVENT_CODE_EDITOR/CHANGE_SORTING',
  SetRowPerPage = '@/EVENT_CODE_EDITOR/SET_ROW_PER_PAGE',
  SetPage = '@/EVENT_CODE_EDITOR/SET_PAGE',
  ClearSelectedFilters = '@/EVENT_CODE_EDITOR/CLEAR_SELECTED_FILTERS',

  GetExportRequest = '@/EVENT_CODE_EDITOR/GET_EXPORT_REQUEST',
  GetExportResponse = '@/EVENT_CODE_EDITOR/GET_EXPORT_RESPONSE',
  GetExportResponseFail = '@/EVENT_CODE_EDITOR/GET_EXPORT_RESPONSE_FAIL',
  GetDataRequest = '@/EVENT_CODE_EDITOR/GET_DATA_REQUEST',
  SetResponse = '@/EVENT_CODE_EDITOR/SET_RESPONSE',
  SetDataPagination = '@/EVENT_CODE_EDITOR/SET_DATA_PAGINATION',
  SelectRow = '@/EVENT_CODE_EDITOR/SELECT_ROW',
  CreateCard = '@/EVENT_CODE_EDITOR/CREATE_CARD',

  RemoveCard = '@/EVENT_CODE_EDITOR/REMOVE_CARD',
  EditCard = '@/EVENT_CODE_EDITOR/EDIT_CARD',

  SetModalState = '@/EVENT_CODE_EDITOR/SET_MODAL_STATE',
  SetModalStateError = '@/EVENT_CODE_EDITOR/SET_MODAL_STATE_ERROR',
  SetModalStateFetching = '@/EVENT_CODE_EDITOR/SET_MODAL_STATE_FETCHING',
  GetResponseFail = '@/EVENT_CODE_EDITOR/GET_RESPONSE_FAIL',
  SetColumns = '@/EVENT_CODE_EDITOR/SET_COLUMNS',
}
