import { Dispatch, SetStateAction, useCallback } from 'react'
import { DropResult, ResponderProvided } from 'react-beautiful-dnd'

import { sortTableColumnsOrder } from '@/helpers/sortTableColumns'
import { getTableConfig, setTableConfig } from '@/helpers/tableLocalStorage'
import { reorderArray } from '@/utils/functions'

type HandleDragEndProps = (result: DropResult, provided: ResponderProvided) => void

interface Props {
  localFixedColumns?: string[]

  tableId?: string

  setKeys: Dispatch<SetStateAction<string[]>>
}

interface ResultProps {
  handleDragEnd: HandleDragEndProps
}

export const useDraggable = (props: Props): ResultProps => {
  const { localFixedColumns, tableId, setKeys } = props

  const tableConfigByLocalStorage = tableId ? getTableConfig({ tableId }) : null

  const handleDragEnd = useCallback<HandleDragEndProps>(
    (result): void => {
      const { destination, source } = result || {}
      const fixColumnsLength = localFixedColumns?.length || 0

      if (!destination) {
        return
      }

      const fromFixedToRegular =
        source.index < fixColumnsLength && destination.index >= fixColumnsLength

      const fromRegularToFixed =
        source.index >= fixColumnsLength && destination.index < fixColumnsLength

      if (localFixedColumns && (fromFixedToRegular || fromRegularToFixed)) {
        return
      }

      setKeys(keys => {
        if (tableId && tableConfigByLocalStorage) {
          const newTableColumnsOrder = sortTableColumnsOrder({
            columns: tableConfigByLocalStorage.columnsOrder,
            sourceKey: keys[source.index],
            destinationKey: keys[destination.index],
          })

          setTableConfig({
            tableId,
            tableData: {
              columnsOrder: newTableColumnsOrder,
            },
          })
        }
        return reorderArray(keys, source.index, destination.index)
      })
    },
    [localFixedColumns, tableId, tableConfigByLocalStorage, setKeys],
  )

  return {
    handleDragEnd,
  }
}
