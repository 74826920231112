import { combineReducers } from 'redux'

import * as appConfig from './appConfig/reducer'
import * as common from './common/reducer'
import * as currencies from './currencies/reducer'
import * as denominations from './denominations/reducer'
import * as valuables from './valuables/reducer'

export interface State {
  common: common.State
  appConfig: appConfig.State
  currencies: currencies.State
  denominations: denominations.State
  valuables: valuables.State
}

export const reducer = combineReducers({
  common: common.reducer,
  appConfig: appConfig.reducer,
  currencies: currencies.reducer,
  denominations: denominations.reducer,
  valuables: valuables.reducer,
})
