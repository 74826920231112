import { Checkbox } from '@material-ui/core'
import clsx from 'clsx'
import React, { FC, useMemo } from 'react'

import { CMTableCell, useCommonClasses } from '../../../commonStyles'
import { useClasses } from './styles'
import { Props } from './types'

export const RowCheckboxCell: FC<Props> = React.memo((props: Props) => {
  const { isFixed, isFixedLast, isChecked, isDisabled, cellClasses, handleCheck } = props

  const classes = useClasses()
  const commonClasses = useCommonClasses()

  const cellClassName = useMemo(
    () =>
      clsx(cellClasses, classes.collapseIcon, {
        [commonClasses.fixed]: isFixed,
        [commonClasses.fixedLast]: isFixedLast,
      }),
    [classes, commonClasses, cellClasses, isFixed, isFixedLast],
  )

  return (
    <CMTableCell className={cellClassName}>
      <Checkbox
        checked={isChecked || isDisabled}
        indeterminate={isDisabled}
        disabled={isDisabled}
        onClick={handleCheck}
        className={clsx(commonClasses.tableCheckbox, {
          [classes.disabled]: isDisabled,
        })}
      />
    </CMTableCell>
  )
})
