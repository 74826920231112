import moment from 'moment'

import { LogRule } from '@/components/pages/atmeye/Administration/LogsInspector/hooks/types'
import { DATE_FORMAT } from '@/constants/time'
import { requestService } from '@/utils/services/request'

import { SysLogSettings } from './types'

export class LogsInspectorApi {
  public static async getLogRules(): Promise<any> {
    try {
      return await requestService.get(`/atmeye/api/v1/log-inspector-rules/list`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getDevicesDataBySelector(payload: any): Promise<any> {
    try {
      return await requestService.post(`/atmeye/api/v1/devices-selector/data`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async addLogRule(logRule: LogRule): Promise<any> {
    try {
      return await requestService.post(`/atmeye/api/v1/log-inspector-rules/item`, logRule)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async editLogRule(logRule: LogRule): Promise<any> {
    try {
      return await requestService.put(`/atmeye/api/v1/log-inspector-rules/item`, logRule)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async deleteLogRule(id: number): Promise<any> {
    try {
      return await requestService.delete(`/atmeye/api/v1/log-inspector-rules/item?id=${id}`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getSysLogSettings(): Promise<SysLogSettings> {
    try {
      return await requestService.get(`/usm/api/v1/user-actions-log-options/data`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async updateSysLogSettings(sysLogSettings: SysLogSettings): Promise<any> {
    try {
      return await requestService.put(`/usm/api/v1/user-actions-log-options/data`, sysLogSettings)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getTransactionJournal({
    deviceId,
    processNumber,
    localDate,
  }: {
    deviceId: string | number
    processNumber: string | number
    localDate: string
  }): Promise<any> {
    try {
      return await requestService.get(
        `/atmeye/api/v1/journal-transactions/lookup?deviceId=${deviceId}&processNumber=${processNumber}&processDate=${moment(
          localDate,
        ).format(DATE_FORMAT)}`,
      )
    } catch (e) {
      throw new Error(e)
    }
  }
}
