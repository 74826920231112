import { AnyAction } from 'redux'

import {
  OrdAssignRequest,
  OrdDocStage,
  OrdGroupRequest,
  OrdOrder,
  OrdOrderRefRequest,
  OrdUnGroupRequest,
  PoliceDriver,
  PoliceOperation,
  PoliceOrderStatusResponse,
  ShrDeclineNote,
  ShrDeclineRequest,
} from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants/actions/cmActions'

export const getOrdersRequest = (): AnyAction => ({
  type: CMActions.OrdGetOrdersRequest,
})

export const getOrdGetOrdersResponse = (payload: any): AnyAction => ({
  type: CMActions.OrdGetOrdersResponse,
  payload,
})

export const getOrdGetOrdersResponseFail = (payload: any): AnyAction => ({
  type: CMActions.OrdGetOrdersResponseFail,
  payload,
})

export const setOrdSetLoadingModalOpen = (payload: boolean): AnyAction => ({
  type: CMActions.OrdSetLoadingModalOpen,
  payload,
})

export const setOrdSetDocumentsModalOpen = (payload: boolean): AnyAction => ({
  type: CMActions.OrdSetDocumentsModalOpen,
  payload,
})

export const setOrdSetFiltersModalOpen = (payload: boolean): AnyAction => ({
  type: CMActions.OrdSetFiltersModalOpen,
  payload,
})

export const setOrdSetSettingsModalOpen = (payload: boolean): AnyAction => ({
  type: CMActions.OrdSetSettingsModalOpen,
  payload,
})

export const setOrdOrdersFilter = (payload: any): AnyAction => ({
  type: CMActions.OrdSetOrdersFilter,
  payload,
})

export const filterSubmitRequest = (payload: any): AnyAction => ({
  type: CMActions.OrdFilterSubmitRequest,
  payload,
})

export const resetOrdersPage = (): AnyAction => ({
  type: CMActions.OrdResetOrdersPage,
})

export const getOrdReportsRequest = (): AnyAction => ({
  type: CMActions.OrdGetReportsRequest,
})

export const getOrdReportsResponse = (payload: any): AnyAction => ({
  type: CMActions.OrdGetReportsResponse,
  payload,
})

export const getOrdReportsResponseFail = (payload: any): AnyAction => ({
  type: CMActions.OrdGetReportsResponseFail,
  payload,
})

export const getOrdReportRequest = (payload: any): AnyAction => ({
  type: CMActions.OrdGetReportRequest,
  payload,
})

export const getOrdReportResponse = (payload: any): AnyAction => ({
  type: CMActions.OrdGetReportResponse,
  payload,
})

export const getOrdReportResponseFail = (payload: any): AnyAction => ({
  type: CMActions.OrdGetReportResponseFail,
  payload,
})

export const setOrdSelectedOrder = (order: OrdOrder | null): AnyAction => ({
  type: CMActions.OrdSetSelectedOrder,
  payload: order,
})

export const toggleOrdOrder = (order: OrdOrder): AnyAction => ({
  type: CMActions.OrdToggleOrder,
  payload: order,
})

export const toggleOrdAllOrders = (payload: boolean): AnyAction => ({
  type: CMActions.OrdToggleAllOrders,
  payload,
})

export const checkOrdSelectedOrders = (orders: OrdOrder[]): AnyAction => ({
  type: CMActions.OrdCheckSelectedOrders,
  payload: orders,
})

export const sortOrdTable = (payload: {
  sortColumn: string | undefined
  sortOrder: 'asc' | 'desc' | undefined
}): AnyAction => ({
  type: CMActions.SortOrdTable,
  payload,
})

export const refreshOrders = (): AnyAction => ({
  type: CMActions.RefreshOrders,
})

export const approveOrders = (payload: OrdOrderRefRequest): AnyAction => ({
  type: CMActions.OrdApproveOrders,
  payload,
})

export const declineOrders = (payload: ShrDeclineRequest): AnyAction => ({
  type: CMActions.OrdDeclineOrders,
  payload,
})

export const sendToExecuteOrders = (payload: OrdOrderRefRequest): AnyAction => ({
  type: CMActions.OrdSendToExecuteOrders,
  payload,
})

export const approveOrdersFail = (error: string): AnyAction => ({
  type: CMActions.OrdApproveOrdersResponseFail,
  error,
})

export const declineOrdersFail = (error: string): AnyAction => ({
  type: CMActions.OrdDeclineOrdersResponseFail,
  error,
})

export const sendToExecuteOrdersFail = (error: string): AnyAction => ({
  type: CMActions.OrdSendToExecuteOrdersResponseFail,
  error,
})

export const refreshOrderRequest = (guid: string): AnyAction => ({
  type: CMActions.OrdRefreshOrderRequest,
  guid,
})

export const refreshOrderResponse = (payload: OrdOrder): AnyAction => ({
  type: CMActions.OrdRefreshOrderResponse,
  payload,
})

export const refreshOrderResponseFail = (error: string): AnyAction => ({
  type: CMActions.OrdRefreshOrderResponseFail,
  error,
})

export const getOrderStagesDocRequest = (id: string): AnyAction => ({
  type: CMActions.OrdGetStagesDocRequest,
  id,
})

export const getOrderStagesDocResponse = (payload: OrdDocStage[]): AnyAction => ({
  type: CMActions.OrdGetStagesDocResponse,
  payload,
})

export const setOrderSelectedSessionDoc = (payload: OrdDocStage): AnyAction => ({
  type: CMActions.OrdSetSelectedSessionDoc,
  payload,
})

export const setOrdDeclineModalState = (isOpen: boolean): AnyAction => ({
  type: CMActions.OrdSetDeclineModalOpen,
  payload: isOpen,
})

export const setOrdDeclineNoteModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.OrdSetDeclineNoteModalOpen,
  payload: isOpen,
})

export const getOrdDeclineNoteRequest = ({
  sessionId,
  cashPointId,
}: {
  sessionId: string
  cashPointId: string
}): AnyAction => ({
  type: CMActions.OrdGetDeclineNoteRequest,
  payload: { sessionId, cashPointId },
})

export const getOrdDeclineNoteResponse = (payload: ShrDeclineNote): AnyAction => ({
  type: CMActions.OrdGetDeclineNoteResponse,
  payload,
})

export const getOrdDeclineNoteResponseFail = (error: string): AnyAction => ({
  type: CMActions.OrdGetDeclineNoteResponseFail,
  payload: error,
})

export const groupOrdOrdersRequest = (payload: OrdGroupRequest): AnyAction => ({
  type: CMActions.OrdGroupOrdersRequest,
  payload,
})

export const groupOrdOrdersResponseFail = (error: string): AnyAction => ({
  type: CMActions.OrdGroupOrdersResponseFail,
  payload: error,
})

export const ungroupOrdOrdersRequest = (payload: OrdUnGroupRequest): AnyAction => ({
  type: CMActions.OrdUngroupOrdersRequest,
  payload,
})

export const ungroupOrdOrdersResponseFail = (error: string): AnyAction => ({
  type: CMActions.OrdUngroupOrdersResponseFail,
  payload: error,
})

export const assignOrdCashierAndCollectorRequest = (payload: OrdAssignRequest): AnyAction => ({
  type: CMActions.OrdAssignCashierAndCollectorRequest,
  payload,
})

export const setOrdOrdersPaging = (payload: { page?: number; pageSize?: number }): AnyAction => ({
  type: CMActions.OrdSetOrdersPaging,
  payload,
})

export const handleOrdKeyPress = (
  actionName:
    | 'TOGGLE_CHECKBOX'
    | 'NEXT_ROW'
    | 'PREV_ROW'
    | 'OPEN_FILTER'
    | 'OPEN_PRINT_DIALOG'
    | 'REFRESH',
): AnyAction => ({
  type: CMActions.OrdHandleKeyPress,
  payload: actionName,
})

export const cashPointOperationsRequest = (payload: PoliceOperation): AnyAction => ({
  type: CMActions.OrdOperationsRequest,
  payload,
})
export const cashPointOperationsResponse = (): AnyAction => ({
  type: CMActions.OrdOperationsResponse,
})
export const cashPointOperationsFail = (error: string): AnyAction => ({
  type: CMActions.OrdOperationsFail,
  payload: error,
})

export const getCashPointDriversRequest = (): AnyAction => ({
  type: CMActions.OrdGetCashPointDriversRequest,
})
export const getCashPointDriversResponse = (drivers: PoliceDriver[]): AnyAction => ({
  type: CMActions.OrdGetCashPointDriversResponse,
  payload: drivers,
})
export const getCashPointDriversFail = (error: string): AnyAction => ({
  type: CMActions.OrdGetCashPointDriversFail,
  payload: error,
})

export const getCashPointSentOpsStatusesRequest = (): AnyAction => ({
  type: CMActions.OrdGetSentOpsStatusesRequest,
})
export const getCashPointSentOpsStatusesResponse = (
  statuses: PoliceOrderStatusResponse[],
): AnyAction => ({
  type: CMActions.OrdGetSentOpsStatusesResponse,
  payload: statuses,
})
export const getCashPointSentOpsStatusesFail = (error: string): AnyAction => ({
  type: CMActions.OrdGetSentOpsStatusesFail,
  payload: error,
})
