import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  paper: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  totalElements: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    marginRight: spacing(2.5),
  },
}))
