import { AnyAction } from 'redux'

import {
  AdmEmployeeWithId,
  AdmFuelType,
  AdmVehicle,
  AdmVehicleType,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants/actions'

export type State = {
  selectedPanel: string

  fuelTypes: {
    total: number
    data: AdmFuelType[]
    isLoading: boolean
    isLoaded: boolean
    selectedFuelType: AdmFuelType | null
  }
  vehicleTypes: {
    total: number
    data: AdmVehicleType[]
    isLoading: boolean
    isLoaded: boolean
    selectedVehicleType: AdmVehicleType | null
  }
  vehicles: {
    data: AdmVehicle[]
    total: number
    isLoading: boolean
    isLoaded: boolean
    company: string
    selectedVehicle: AdmVehicle | null
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined

    isModalLoading: boolean
  }

  employees: {
    data: AdmEmployeeWithId[]
    total: number
    isLoading: boolean
    isLoaded: boolean
    sortColumn?: string
    sortOrder?: string
    error: string | null

    company: string
    selectedEmployee: AdmEmployeeWithId | null
  }
}

export const initialState: State = {
  selectedPanel: '',

  fuelTypes: {
    total: 0,
    data: [],
    isLoading: false,
    isLoaded: false,
    selectedFuelType: null,
  },
  vehicleTypes: {
    total: 0,
    data: [],
    isLoading: false,
    isLoaded: false,
    selectedVehicleType: null,
  },
  vehicles: {
    data: [],
    total: 0,
    isLoading: false,
    isLoaded: false,
    company: '',
    selectedVehicle: null,
    sortColumn: undefined,
    sortOrder: undefined,

    isModalLoading: false,
  },

  employees: {
    data: [],
    total: 0,
    isLoading: false,
    isLoaded: false,
    sortColumn: undefined,
    sortOrder: undefined,
    error: null,

    company: '',
    selectedEmployee: null,
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case VaultV1Actions.AdmSetSelectedCITPanel:
      return {
        ...state,
        selectedPanel: payload,
      }
    // [START] FUEL TYPES ----------------------------------------------------------------------
    case VaultV1Actions.AdmSetSelectedFuelType:
      return {
        ...state,
        fuelTypes: {
          ...state.fuelTypes,
          selectedFuelType: payload,
        },
      }

    case VaultV1Actions.AdmGetFuelTypesRequest:
    case VaultV1Actions.AdmAddFuelTypeRequest:
    case VaultV1Actions.AdmEditFuelTypeRequest:
    case VaultV1Actions.AdmDeleteFuelTypeRequest:
      return {
        ...state,
        fuelTypes: {
          ...state.fuelTypes,
          isLoading: true,
        },
      }

    case VaultV1Actions.AdmGetFuelTypesResponse:
      return {
        ...state,
        fuelTypes: {
          ...state.fuelTypes,
          isLoading: false,
          isLoaded: true,
          data: payload,
          total: (payload || []).length,
        },
      }

    case VaultV1Actions.AdmGetFuelTypesFail:
    case VaultV1Actions.AdmAddFuelTypeFail:
    case VaultV1Actions.AdmEditFuelTypeFail:
    case VaultV1Actions.AdmDeleteFuelTypeFail:
      return {
        ...state,
        fuelTypes: {
          ...state.fuelTypes,
          isLoading: false,
          isLoaded: true,
        },
      }
    // [END] FUEL TYPES ----------------------------------------------------------------------

    // [START] VEHICLE TYPES ----------------------------------------------------------------------
    case VaultV1Actions.AdmSetSelectedVehicleType:
      return {
        ...state,
        vehicleTypes: {
          ...state.vehicleTypes,
          selectedVehicleType: payload,
        },
      }

    case VaultV1Actions.AdmGetVehicleTypesRequest:
    case VaultV1Actions.AdmAddVehicleTypeRequest:
    case VaultV1Actions.AdmEditVehicleTypeRequest:
    case VaultV1Actions.AdmDeleteVehicleTypeRequest:
      return {
        ...state,
        vehicleTypes: {
          ...state.vehicleTypes,
          isLoading: true,
        },
      }

    case VaultV1Actions.AdmGetVehicleTypesResponse:
      return {
        ...state,
        vehicleTypes: {
          ...state.vehicleTypes,
          isLoading: false,
          isLoaded: true,
          data: payload,
          total: (payload || []).length,
        },
      }

    case VaultV1Actions.AdmGetVehicleTypesFail:
    case VaultV1Actions.AdmAddVehicleTypeFail:
    case VaultV1Actions.AdmEditVehicleTypeFail:
    case VaultV1Actions.AdmDeleteVehicleTypeFail:
      return {
        ...state,
        vehicleTypes: {
          ...state.vehicleTypes,
          isLoading: false,
          isLoaded: true,
        },
      }
    // [END] VEHICLE TYPES ----------------------------------------------------------------------

    // [START] VEHICLE ----------------------------------------------------------------------
    case VaultV1Actions.AdmSortVehicles:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          isLoading: true,
          ...payload,
        },
      }
    case VaultV1Actions.AdmSetSelectedVehiclesCompany:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          company: payload,
        },
      }
    case VaultV1Actions.AdmSetSelectedVehicle:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          selectedVehicle: payload,
        },
      }
    case VaultV1Actions.AdmGetVehiclesRequest:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          isLoading: true,
          isModalLoading: false,
        },
      }
    case VaultV1Actions.AdmGetVehiclesResponse:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          isLoading: false,
          isLoaded: true,
          data: payload,
          total: (payload || []).length,
        },
      }
    case VaultV1Actions.AdmGetVehiclesFail:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          isLoading: false,
          isLoaded: true,
          data: [],
        },
      }
    case VaultV1Actions.AdmAddVehicleRequest:
    case VaultV1Actions.AdmEditVehicleRequest:
    case VaultV1Actions.AdmDeleteVehicleRequest:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          isModalLoading: true,
        },
      }

    case VaultV1Actions.AdmAddVehicleFail:
    case VaultV1Actions.AdmEditVehicleFail:
    case VaultV1Actions.AdmDeleteVehicleFail:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          isModalLoading: false,
        },
      }
    // [END] VEHICLE ----------------------------------------------------------------------

    // [START] EMPLOYEES ----------------------------------------------------------------------
    case VaultV1Actions.AdmSortEmployees:
      return {
        ...state,
        employees: {
          ...state.employees,
          isLoading: true,
          ...payload,
        },
      }
    case VaultV1Actions.AdmSetSelectedEmployeesCompany:
      return {
        ...state,
        employees: {
          ...state.employees,
          company: payload,
        },
      }
    case VaultV1Actions.AdmSetSelectedEmployee:
      return {
        ...state,
        employees: {
          ...state.employees,
          selectedEmployee: payload,
        },
      }
    case VaultV1Actions.AdmGetEmployeesRequest:
      return {
        ...state,
        employees: {
          ...state.employees,
          isLoading: true,
        },
      }
    case VaultV1Actions.AdmGetEmployeesResponse:
      return {
        ...state,
        employees: {
          ...state.employees,
          isLoading: false,
          isLoaded: true,
          data: payload,
          total: (payload || []).length,
        },
      }
    case VaultV1Actions.AdmGetEmployeesFail:
      return {
        ...state,
        employees: {
          ...state.employees,
          isLoading: false,
          isLoaded: true,
          data: [],
        },
      }
    case VaultV1Actions.AdmEditEmployeeRequest:
      return {
        ...state,
        employees: {
          ...state.employees,
          isLoading: true,
        },
      }

    case VaultV1Actions.AdmEditEmployeeFail:
      return {
        ...state,
        employees: {
          ...state.employees,
          isLoading: false,
        },
      }
    // [END] EMPLOYEES ----------------------------------------------------------------------

    case VaultV1Actions.SignOut:
      return initialState
    default:
      return state
  }
}
