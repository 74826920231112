import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getState = (state: AppState): fromReducer.State => {
  return state.cmAdmin.appConfig.common
}

export const getCMAdmSelectedAppConfigPanel = createSelector(getState, state => state.selectedPanel)
