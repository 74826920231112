export enum AtmeyeAlarmsActions {
  ChangeSelectedFilters = '@/ALARMS/CHANGE_SELECTED_FILTERS',
  ChangeGrouping = '@/ALARMS/CHANGE_GROUPING',
  ChangeSorting = '@/ALARMS/CHANGE_SORTING',
  SetRowPerPage = '@/ALARMS/SET_ROW_PER_PAGE',
  SetPage = '@/ALARMS/SET_PAGE',

  SetGroupDetailsResponse = '@/ALARMS/SET_GROUP_DETAILS_RESPONSE',
  SetGroupDetailsResponseEmpty = '@/ALARMS/SET_GROUP_DETAILS_RESPONSE_EMPTY',
  SetSelectedGroupingTemplate = '@/ALARMS/SET_SELECTED_GROUPING_TEMPLATE',

  DeleteFilterTemplateRequest = '@/ALARMS/DELETE_FILTER_TEMPLATE_REQUEST',
  DeleteFilterTemplateResponse = '@/ALARMS/DELETE_FILTER_TEMPLATE_RESPONSE',

  GetGroupingTemplatesRequest = '@/ALARMS/GET_GROUPING_TEMPLATES_REQUEST',

  GetFiltersTemplatesRequest = '@/ALARMS/GET_FILTERS_TEMPLATES_REQUEST',

  SaveGroupingTemplateRequest = '@/ALARMS/SAVE_GROUPING_TEMPLATE_REQUEST',

  CreateFilterTemplateRequest = '@/ALARMS/CREATE_FILTER_TEMPLATE_REQUEST',
  EditGroupTemplateRequest = '@/ALARMS/EDIT_GROUP_TEMPLATE_REQUEST',
  DeleteGroupTemplateRequest = '@/ALARMS/DELETE_GROUP_TEMPLATE_REQUEST',

  UpdateFilterTemplateRequest = '@/ALARMS/UPDATE_FILTER_TEMPLATE_REQUEST',
  SetGroupTemplates = '@/ALARMS/SET_GROUP_TEMPLATES',

  GetAlarmsExport = '@/ALARMS/GET_ALARMS_EXPORT',
  GetExportResponse = '@/GET_EXPORT_RESPONSE',
  GetExportResponseFail = '@/GET_EXPORT_RESPONSE_FAIL',

  GetAlarmsExportGrouped = '@/ALARMS/GET_ALARMS_EXPORT_GROUPED',
  GetExportGroupedResponse = '@/GET_EXPORT_GROUPED_RESPONSE',
  GetExportGroupedResponseFail = '@/GET_EXPORT_GROUPED_RESPONSE_FAIL',
  GetTransactionsConfigRequest = '@/ALARMS/GET_TRANSACTIONS_CONFIG_REQUEST',
  GetTransactionsRequest = '@/ALARMS/GET_TRANSACTIONS_REQUEST',
  GetTransactionsResponse = '@/ALARMS/GET_TRANSACTIONS_RESPONSE',
  GetGroupDetailsRequest = '@/ALARMS/GET_GROUP_DETAILS_REQUEST',
  GetGroupDetailsResponse = '@/ALARMS/GET_GROUP_DETAILS_RESPONSE',
  SetParentGroups = '@/ALARMS/SET_PARENT_GROUPS',
  SetCurrentFilterTemplate = '@/ALARMS/SET_CURRENT_FILTER_TEMPLATE',
  SetSdFilterTemplateResponse = '@/ALARMS/SET_FILTER_TEMPLATE_RESPONSE',
  CreateSdFilterTemplateResponse = '@/ALARMS/CREATE_SD_FILTER_TEMPLATE_RESPONSE',
  EditSdGroupTemplateFromResponse = '@/ALARMS/EDIT_SD_GROUP_TEMPLATE_FROM_RESPONSE',
  UpdateSdFilterTemplateResponse = '@/ALARMS/UPDATE_SD_FILTER_TEMPLATE_RESPONSE',
  GetMedia = '@/ALARMS/GET_MEDIA',
  SetMedia = '@/ALARMS/SET_MEDIA',
  ClearCurrentMedia = '@/ALARMS/CLEAR_CURRENT_MEDIA',
  SetCurrentMedia = '@/ALARMS/SET_CURRENT_MEDIA',
  SetMediaErrorMessage = '@/ALARMS/SET_MEDIA_ERROR_MESSAGE',
  EditTableCellRequest = '@/ALARMS/EDIT_TABLE_CELL_REQUEST',
  EditTableCellGrouping = '@/ALARMS/EDIT_TABLE_CELL_REQUEST_GROUPING',
  SaveTableCell = '@/ALARMS/SAVE_TABLE_CELL',
  SaveTableCellGrouping = '@/ALARMS/SAVE_TABLE_CELL_GROUPING',
  SetDataPagination = '@/ALARMS/SET_DATA_PAGINATION',
  SetDataPaginationRequest = '@/ALARMS/SET_DATA_PAGINATION_REQUEST',
  CollectFilterTemplateFields = '@/ALARMS/COLLECT_FILTER_TEMPLATE_FIELDS',
  GetTicketsConfigResponse = '@/ALARMS/GET_TICKETS_CONFIG_RESPONSE',
  ReactionToAlarm = '@/ALARMS/REACTION_TO_ALARMS',
  SetReactionDateToAlarm = '@/ALARMS/SET_REACTION_DATE_TO_ALARM',
  SetGroupedReactionDateToAlarm = '@/ALARMS/SET_GROUPED_REACTION_DATE_TO_ALARM',
  ClearGroupedTicketDetailsData = '@/ALARMS/CLEAR_GROUPED_DETAILS_DATA',
  UpdateTableRow = '@/ALARMS/UPDATE_TABLE_ROW',
  SetFilterModalState = '@/ALARMS/SET_FILTER_MODAL_STATE',
  GetTicketResponseFail = '@/ALARMS/GET_TICKETS_RESPONSE_FAIL',
  IsSubmitFiltersButtonClicked = '@/ALARMS/IS_SUBMIT_FILTERS_BUTTON_CLICKED',
}
