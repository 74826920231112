import { FormikValues } from 'formik'

import { GetTicketsBodyFilterItem, GetTicketsBodyGroupItem } from '@/api/sd/ticketsApi/types'
import { TableGroup } from '@/components/pages/sd/TicketsPage/components/GroupingModal/types'
import { Group } from '@/types'

export const gettingTickets = {
  getSortDetails: (sorting: { [key: string]: 'asc' | 'desc' } | null) => [
    {
      fieldName: sorting ? Object.keys(sorting)[0] : 'receivedate',
      sortOrder: sorting ? (Object.values(sorting)[0].toUpperCase() as 'ASC' | 'DESC') : 'DESC',
    },
  ],
  getFilters: (selectedFilters: FormikValues, filtersConfig: any[]): GetTicketsBodyFilterItem[] => {
    const filters: GetTicketsBodyFilterItem[] = []
    const INTEGER_VALUE_TYPE = 'INTEGER'

    Object.keys(selectedFilters).forEach(key => {
      const filterValue = selectedFilters[key]

      if (!filterValue || (Array.isArray(filterValue) && !filterValue.length)) {
        return
      }

      const config = filtersConfig.find(config => config.parameterName === key)

      if (config) {
        const filterItem: GetTicketsBodyFilterItem = {
          parameterName: config.parameterName,
          valueType: config.valueType,
          parameterValue: null,
          rangeValues: null,
        }

        if (config.displayType === 'RANGE') {
          if (filterValue[0]) {
            filterItem.rangeValues = {
              from: Math.floor(new Date(filterValue[0]).getTime() / 1000),
              to: filterValue[1] ? Math.floor(new Date(filterValue[1]).getTime() / 1000) : null,
            }
          } else return
        } else {
          if (Array.isArray(filterValue)) {
            filterItem.parameterValue = filterValue.map((item: string) => ({
              id: config.valueType === INTEGER_VALUE_TYPE ? +item : null,
              value: config.valueType === INTEGER_VALUE_TYPE ? null : item,
            }))
          } else {
            if (config.parameterName === 'merchant' || config.parameterName === 'devcity') {
              filterItem.parameterValue = [
                {
                  id: config.valueType === INTEGER_VALUE_TYPE ? +filterValue : null,
                  value: config.valueType === INTEGER_VALUE_TYPE ? null : filterValue,
                  name: '',
                },
              ]
            } else {
              filterItem.parameterValue = [
                {
                  id: config.valueType === INTEGER_VALUE_TYPE ? +filterValue : null,
                  value: config.valueType === INTEGER_VALUE_TYPE ? null : filterValue,
                },
              ]
            }
          }
        }

        filters.push(filterItem)
      }
    })

    return filters
  },
  getGroups: (selectedGrouping: TableGroup[], groupsConfig: Group[]): GetTicketsBodyGroupItem[] => {
    const groups: GetTicketsBodyGroupItem[] = []

    selectedGrouping.forEach(({ key, order, orderBy }: TableGroup, idx) => {
      const config = groupsConfig.find(c => c.groupUID === key)

      if (config) {
        const { valueType, outGroupFieldDisplayType } = config

        const group = {
          groupLevelNo: idx + 1,
          groupUID: key,
          parentGroupUID: idx === 0 ? [] : selectedGrouping.map(group => group.key).slice(0, idx),
          sortBy: orderBy === 'count' ? 'RECORD_COUNT' : 'DISPLAY_VALUE',
          sortOrder: order.toUpperCase(),
          value: '',
          valueType,
          displayValueType: outGroupFieldDisplayType,
        }
        groups.push(group)
      }
    })

    return groups
  },
}
