import queryString from 'query-string'

import { AdmPlnRoute, AdmPlnRouteBase, AdmPlnRouteCashPoint } from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

interface GetRoutesProps extends Sort {
  guid: string
}

export class RoutesApi {
  private static requestService = getRequestServiceCM()

  public static async get(payload: GetRoutesProps): Promise<AdmPlnRoute[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'admin/planning/routes',
          query: { ...payload },
        },
        { skipNull: true, encode: false },
      )

      return await RoutesApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async add(route: AdmPlnRouteBase): Promise<void> {
    try {
      return await RoutesApi.requestService.post('admin/planning/routes/add', route)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async update(route: AdmPlnRoute): Promise<void> {
    try {
      return await RoutesApi.requestService.put('admin/planning/routes/update', route)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async delete(id: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'admin/planning/routes/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )
      return await RoutesApi.requestService.delete(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getObjects(sort: Sort): Promise<AdmPlnRouteCashPoint[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'admin/planning/routes/points',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )
      return await RoutesApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }
}
