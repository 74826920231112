// core
import clsx from 'clsx'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

// components
import { Button } from '@/components/controls/Button/component'

// styles
import { useClasses } from './styles'
// types
import { Props } from './types'

const ButtonActionSignIn: FC<Props> = ({
  isDisabled,
  translate,
  onclickAction,
  onCliCkCancelAction,
  showButtonCancel,
  styleForButtons,
  showButtonSubmit = true,
}: Props) => {
  const classes = useClasses({
    style: {
      justifyContent: styleForButtons,
    },
  })
  return (
    <div className={classes.buttonLoginWrapper}>
      {showButtonCancel && (
        <Button
          variant="outlined"
          onClick={onCliCkCancelAction}
          className={clsx(classes.button, classes.cancelButton)}
        >
          <FormattedMessage id={translate.idCancel} defaultMessage={translate.messageCancel} />
        </Button>
      )}

      {showButtonSubmit && (
        <Button
          variant="contained"
          type="submit"
          onClick={onclickAction}
          className={classes.button}
          disabled={isDisabled}
        >
          <FormattedMessage id={translate.idSubmit} defaultMessage={translate.messageSubmit} />
        </Button>
      )}
    </div>
  )
}

export { ButtonActionSignIn }
