import makeStyles from '@material-ui/core/styles/makeStyles'

import colors from '@/theme/colors'

type Props = {
  style?: {
    justifyContent?: string
  }
}

export const useClasses = makeStyles(({ spacing, palette, breakpoints }) => ({
  button: {
    minWidth: spacing(4.68),
    marginBottom: spacing(1),
    marginTop: spacing(2),
    fontWeight: 400,
  },

  buttonLoginWrapper: {
    display: 'flex',
    justifyContent: (props: Props) =>
      props.style?.justifyContent ? props.style?.justifyContent : 'flex-end',
  },

  cancelButton: {
    marginRight: spacing(1),
    color: colors.primary,
    borderColor: colors.primary,
    '&:hover': {
      borderColor: palette.grey[200],
      backgroundColor: colors.grey50,
    },
  },
}))
