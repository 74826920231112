import { FormikValues } from 'formik'

import { POSServiceFormParameterDetails } from '@/api/sd/posApi/types'

export interface Props {
  param: POSServiceFormParameterDetails
  formValues: FormikValues
  sameAddress?: boolean
  merchantValue?: string
  merchantFullName?: string
  disabledFieldNames?: string[]
  setFormikFieldValue: (
    field: string,
    value: string | number | boolean | Array<string | number>,
    shouldValidate?: boolean | undefined,
  ) => any
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void
}

export enum POSServiceFieldTypes {
  TEXT = 1,
  COMBO = 2,
  LIST = 3,
  DATETIME = 4,
  RADIOBUTTON = 5,
  CHECKBOX = 6,
  NUMERIC_RANGE = 7,
  YES_NO_ALL = 8,
  DATE_RANGE = 9,
  CONTENT_PROPOSAL = 10,
  MASKED_NUMERIC_RANGE = 11,
  TABLE = 12,
  Separator = 13,
  LineBreak = 14,
  EmptyField = 15,
  CHECKBOXLIST = 16,
  RADIOBUTTONLIST = 17,
  COMBOMULTISELECT = 18,
  CHECKBOX_LEFTLABLE = 19,
  Date = 20,
}
