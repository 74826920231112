import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  dialog: {
    width: 1024,
    maxWidth: 1024,
  },
  container: {
    flexDirection: 'column',
    overflow: 'auto',
    padding: spacing(3, 3),
  },
  formWrapper: {
    paddingTop: spacing(1),
  },
  radioGroup: {
    justifyContent: 'flex-start',
    marginRight: spacing(2.5),
  },
  actionButtons: {
    marginTop: spacing(1.5),
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      marginLeft: spacing(2.5),
    },
  },
  fieldsRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  field: {
    width: '48%',
  },
}))
