import { FormikErrors } from 'formik'

export const generateErrorText = (
  errors: FormikErrors<any>,
  name: string,
  touched: boolean,
): string => {
  let errorText = ''
  const error = errors[name]

  if (touched && !!error) {
    if (Array.isArray(error)) {
      errorText = error.join(', ')
    } else {
      errorText = error.toString()
    }
  }

  return errorText
}

export const cutParentheses = (value: string) => {
  const startPart = value.lastIndexOf('(')
  const endPart = value.lastIndexOf(')')
  if (startPart !== -1 && endPart !== -1) {
    const firstPart = value.slice(0, startPart)
    const lastPart = value.slice(endPart + 1)
    return `${firstPart}${lastPart}`
  }
  return value
}
