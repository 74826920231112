import { ReactElement, useCallback, useMemo } from 'react'

export interface TwiceColumnsConfigProps {
  columns: string[]
  twiceColumnsTitles: string[]
  specificColumnsTitles: {
    [key: string]: (string | ReactElement)[]
  }
}

export interface TwiceColumnsProps {
  twiceColumnsConfig?: Partial<TwiceColumnsConfigProps>
}

interface ResultProps {
  twiceColumns: string[]
  getTwiceColumnTitles: (headerKey: string) => (string | ReactElement)[]
}

export const useTwiceColumns = (props: TwiceColumnsProps): ResultProps => {
  const { twiceColumnsConfig } = props

  const { columns, twiceTitles, specificTitles } = useMemo(
    () => ({
      columns: twiceColumnsConfig?.columns || [],
      twiceTitles: twiceColumnsConfig?.twiceColumnsTitles || [],
      specificTitles: twiceColumnsConfig?.specificColumnsTitles || {},
    }),
    [twiceColumnsConfig],
  )

  const getTwiceColumnTitles = useCallback(
    (headerKey: string) => {
      return specificTitles && Object.keys(specificTitles).includes(headerKey)
        ? specificTitles[headerKey]
        : twiceTitles || ['first', 'second']
    },
    [specificTitles, twiceTitles],
  )

  return {
    twiceColumns: columns,
    getTwiceColumnTitles,
  }
}
