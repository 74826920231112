import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromAuthCM from './reducer'

export const getAuthStateCM = (state: AppState): fromAuthCM.State => state.cmAuth

export const getAuthError = createSelector(getAuthStateCM, state => state.error)

export const getIsLoggingIn = createSelector(getAuthStateCM, state => state.isLoggingIn)

export const getUserDisplayName = createSelector(getAuthStateCM, state => state.displayName)
export const getDataToLS = createSelector(getAuthStateCM, state => state.dataToLS)

export const getChangePasswordState = createSelector(
  getAuthStateCM,
  state => state.changePasswordState,
)
