import { Popover, Tooltip } from '@material-ui/core'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import clsx from 'clsx'
import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react'

import Columns from '@/assets/sd/columns.svg'
import Download from '@/assets/sd/download.svg'
import Filters from '@/assets/sd/filters.svg'
import Grouping from '@/assets/sd/grouping.svg'
import Refresh from '@/assets/sd/refresh.svg'
import Loader from '@/components/blocks/Loader'
import Button from '@/components/controls/Button'
import ColumnsMenu from '@/components/NPMPakage/components/tableComponents/UITable/Components/ColumnsMenu'
import { useTranslate } from '@/utils/internalization'
import { isFiltersSelected } from '@/utils/isFiltersEmpty'

import { useClasses } from './styles'
import { Props } from './types'

const TableHeader: FC<Props> = React.memo(
  ({
    isFullScreen,

    columnsData,

    controlsState,
    onClickFiltersButton,
    onClickGroupingButton,
    refreshData,
    hasGroups,
    tabsHeaderJSX,
    handleFullScreen,
    selectedFilters,
    exportTableData,
  }: Props): ReactElement => {
    const translate = useTranslate()
    const classes = useClasses()
    const [anchorSaveButtonEl, setAnchorSaveButtonEl] = useState<HTMLButtonElement | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onClose = useCallback(() => {
      columnsData && columnsData.setColumnsMenuAnchorEl(null)
    }, [columnsData])

    const handleColumnsButtonClick = useCallback(
      e => columnsData?.setColumnsMenuAnchorEl(e.currentTarget),
      [columnsData],
    )

    const isHasFilters = useMemo(() => {
      return isFiltersSelected(selectedFilters)
    }, [selectedFilters])

    const handleOpenSavePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorSaveButtonEl(event.currentTarget)
    }

    const handleCloseSavePopover = () => {
      setAnchorSaveButtonEl(null)
    }

    const handleDownload = (format: string) => async () => {
      handleCloseSavePopover()
      setIsLoading(true)

      exportTableData && (await exportTableData(format))
      setIsLoading(false)
    }

    return (
      <div
        className={clsx({
          [classes.headerWrapper]: true,
          [classes.emptyTabBlock]: !tabsHeaderJSX,
        })}
      >
        {tabsHeaderJSX && tabsHeaderJSX}

        <div className={classes.buttonWrapper}>
          {handleFullScreen && (
            <Tooltip
              placement="top"
              classes={{ tooltip: classes.toolTip }}
              title={translate('translate#atmeye.table.header.button.fullscreen')}
            >
              <div>
                <Button
                  className={classes.tableHeaderButton}
                  onClick={handleFullScreen}
                  startIcon={<FullscreenIcon />}
                  activeButtonMark={isFullScreen}
                />
              </div>
            </Tooltip>
          )}

          {refreshData && (
            <Tooltip
              placement="top"
              classes={{ tooltip: classes.toolTip }}
              title={translate('translate#atmeye.table.header.button.refresh')}
            >
              <div>
                <Button
                  className={classes.tableHeaderButton}
                  onClick={() => {
                    refreshData()
                  }}
                  startIcon={<img src={Refresh} />}
                />
              </div>
            </Tooltip>
          )}

          {onClickGroupingButton && (
            <Tooltip
              placement="top"
              classes={{ tooltip: classes.toolTip }}
              title={translate(
                controlsState?.groupingTooltipTitle || 'translate#table.header.button.grouping',
              )}
            >
              <div>
                <Button
                  disabled={controlsState?.isGroupingDisabled}
                  className={classes.tableHeaderButton}
                  classes={{ disabled: classes.tableHeaderButtonDisabled }}
                  onClick={onClickGroupingButton}
                  startIcon={<img src={Grouping} />}
                  activeButtonMark={!!hasGroups}
                />
              </div>
            </Tooltip>
          )}
          {onClickFiltersButton && (
            <Tooltip
              placement="top"
              classes={{ tooltip: classes.toolTip }}
              title={translate('translate#table.header.button.filter')}
            >
              <div>
                <Button
                  className={classes.tableHeaderButton}
                  startIcon={<img src={Filters} />}
                  onClick={onClickFiltersButton}
                  activeButtonMark={isHasFilters}
                />
              </div>
            </Tooltip>
          )}
          {columnsData && (
            <Tooltip
              placement="top"
              classes={{ tooltip: classes.toolTip }}
              title={translate('translate#table.header.button.columns')}
            >
              <div>
                <Button
                  className={classes.tableHeaderButton}
                  startIcon={<img src={Columns} />}
                  onClick={handleColumnsButtonClick}
                  activeButtonMark={
                    columnsData.allFields.length !== columnsData.currentFields.length
                  }
                />
              </div>
            </Tooltip>
          )}
          {columnsData && (
            <ColumnsMenu
              anchorEl={columnsData.columnsMenuAnchorEl}
              fields={columnsData.allFields}
              currentFields={columnsData.currentFields}
              handleClose={onClose}
              handleSave={columnsData.onChangeColumns}
            />
          )}

          {exportTableData && (
            <>
              <Tooltip
                disableFocusListener
                disableTouchListener
                placement="top"
                classes={{ tooltip: classes.toolTip }}
                title={translate('translate#table.header.button.save')}
              >
                <div>
                  <Button
                    className={classes.tableHeaderButton}
                    startIcon={isLoading ? <Loader inline /> : <img src={Download} />}
                    onClick={handleOpenSavePopover}
                    disabled={isLoading}
                  />
                </div>
              </Tooltip>

              <Popover
                open={!!anchorSaveButtonEl}
                anchorEl={anchorSaveButtonEl}
                classes={{
                  paper: classes.popoverPaper,
                }}
                onClose={handleCloseSavePopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Button
                  className={classes.popoverButton}
                  onClick={handleDownload('PDF')}
                  disabled={isLoading}
                >
                  .pdf
                </Button>
                {!hasGroups && (
                  <Button
                    className={classes.popoverButton}
                    onClick={handleDownload('XLS')}
                    disabled={isLoading}
                  >
                    .xls
                  </Button>
                )}
              </Popover>
            </>
          )}
        </div>
      </div>
    )
  },
)

export { TableHeader }
