import React, { useRef, useState, useCallback, useEffect } from 'react'
import { Draggable, DraggableProvided, Droppable, DroppableProvided } from 'react-beautiful-dnd'
import ReactResizeDetector from 'react-resize-detector'
import { DroppableIds } from '@/constants/droppableIds'
import Typography from '@material-ui/core/Typography'
import { translate } from '@/utils/internalization'
import IconButton from '@material-ui/core/IconButton'
import { Close } from '@material-ui/icons'
import { Props } from './types'
import { useClasses } from './styles'
import Test from 'react-test-attributes'
import { getTestId } from '@/utils'
import ShowHideBookmarks from '@/components/wrappers/AppNavigation/components/HeaderBookmarks/components/component'
import { TabBarItem } from '../../types'

const HeaderBookmarks = ({
  bookmarks,
  handleAddTab,
  handleRemoveBookmark,
}: Props): React.ReactElement => {
  const classes = useClasses()
  const testId = getTestId('header-bookmarks')

  const [bookmarkCount, setBookmarkCount] = useState(7)
  const [visibleBookmarkArray, setVisibleBookmarkArray] = useState<TabBarItem[]>([])
  const [invisibleBookmarkArray, setInvisibleBookmarkArray] = useState<TabBarItem[]>([])
  const bookmarkWrapper = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setVisibleBookmarkArray(bookmarks.slice(0, bookmarkCount - 1))
    setInvisibleBookmarkArray(bookmarks.slice(bookmarkCount - 1))
  }, [setInvisibleBookmarkArray, setVisibleBookmarkArray, bookmarkCount, bookmarks])

  const handleResize = useCallback(() => {
    if (bookmarkWrapper && bookmarkWrapper.current) {
      setBookmarkCount(Math.round(bookmarkWrapper.current.offsetWidth / 130))
    }
  }, [bookmarkWrapper.current, bookmarks])

  return (
    <Test id={testId(0)}>
      <div ref={bookmarkWrapper} className={classes.bookmarkWrapper}>
        <Droppable direction="horizontal" droppableId={DroppableIds.bookmark}>
          {(provided: DroppableProvided): React.ReactElement => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={classes.bookmarkDropper}
            >
              {visibleBookmarkArray.map(({ label, path }, i) => (
                <Draggable
                  key={`bookmark${path}`}
                  draggableId={`bookmark${JSON.stringify({ path, label })}`}
                  index={i}
                >
                  {({
                    innerRef,
                    draggableProps,
                    dragHandleProps,
                  }: DraggableProvided): React.ReactElement => (
                    <div
                      ref={innerRef}
                      {...draggableProps}
                      {...dragHandleProps}
                      className={classes.bookmark}
                    >
                      <Typography
                        onClick={handleAddTab(path, label, false)}
                        variant="caption"
                        className={classes.bookmarkText}
                      >
                        {translate(label)}
                      </Typography>
                      <Test id={testId(1)}>
                        <IconButton
                          onClick={handleRemoveBookmark(path)}
                          className={classes.bookmarkIcon}
                          size="small"
                        >
                          <Close fontSize="inherit" />
                        </IconButton>
                      </Test>
                    </div>
                  )}
                </Draggable>
              ))}
              {invisibleBookmarkArray.length > 0 && (
                <ShowHideBookmarks
                  hideBookmarks={invisibleBookmarkArray}
                  handleAddTab={handleAddTab}
                  handleRemoveBookmark={handleRemoveBookmark}
                />
              )}
              <ReactResizeDetector handleWidth onResize={handleResize} />
            </div>
          )}
        </Droppable>
      </div>
    </Test>
  )
}

export default HeaderBookmarks
