export type Props = {
  children?: never
}

export enum ChangeFormFields {
  OldPassword = 'oldPassword',
  NewPassword = 'newPassword',
  RepeatPassword = 'repeatPassword',
}

export interface ChangeFormValues {
  [ChangeFormFields.OldPassword]: string
  [ChangeFormFields.NewPassword]: string
  [ChangeFormFields.RepeatPassword]: string
}
