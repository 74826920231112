import { FormikValues } from 'formik'

import { checkIsValidAllWorkingHours } from '@/components/controls/WorkingHours/utils/validate'
import {
  AddNewCompanyFormFields,
  AddNewCompanyFormValues,
} from '@/constants/forms/AddNewCompanyForm'
import { ValidationErrors } from '@/constants/forms/validationErrors'
import { ValidationService } from '@/utils/services/validationService'

// export const validate = (values: AddNewCompanyFormValues): Partial<AddNewCompanyFormValues> => {
export const validate = (values: AddNewCompanyFormValues): FormikValues => {
  // const errors: Partial<AddNewCompanyFormValues> = { }

  // const errors: AddNewCompanyFormValues = {} as AddNewCompanyFormValues

  const errors: FormikValues = {}

  // `companyGeneralInfo[${AddNewCompanyFormFields.CompanyName}]`
  // errors[AddNewCompanyFormFields.CompanyName] = ValidationErrors.Required

  if (!ValidationService.isRequired(values.companyGeneralInfo.companyName)) {
    errors[`companyGeneralInfo[${AddNewCompanyFormFields.CompanyName}]`] = ValidationErrors.Required
    // errors[{ companyGeneralInfo: [AddNewCompanyFormFields.CompanyName] }] =ValidationErrors.Required
    // errors[AddNewCompanyFormFields.CompanyName] = ValidationErrors.Required
    // errors.companyGeneralInfo[AddNewCompanyFormFields.CompanyName] = ValidationErrors.Required
  }

  if (
    values.companyGeneralInfo.email &&
    !ValidationService.isValidMultipleEmail(values.companyGeneralInfo.email)
  ) {
    errors[`companyGeneralInfo[${AddNewCompanyFormFields.Email}]`] =
      ValidationErrors.EmailIsNotValid
  }

  if (!ValidationService.isRequired(values.companyGeneralInfo.companyShortName)) {
    errors[`companyGeneralInfo[${AddNewCompanyFormFields.CompanyShortName}]`] =
      ValidationErrors.Required
    // errors[{ companyGeneralInfo: [AddNewCompanyFormFields.CompanyName] }] =ValidationErrors.Required
    // errors[AddNewCompanyFormFields.CompanyName] = ValidationErrors.Required
    // errors.companyGeneralInfo[AddNewCompanyFormFields.CompanyName] = ValidationErrors.Required
  }

  if (!ValidationService.isRequired(values.companyGeneralInfo.companyTypeId)) {
    errors[`companyGeneralInfo[${AddNewCompanyFormFields.CompanyTypeId}]`] =
      ValidationErrors.Required
  }

  if (values.address.streetId && !ValidationService.isRequired(values.address.houseNumber)) {
    errors[`address[${AddNewCompanyFormFields.HouseNumber}]`] = ValidationErrors.Required
  }

  if (!ValidationService.isRequired(values.address.countryId)) {
    errors[`address[${AddNewCompanyFormFields.CountryId}]`] = ValidationErrors.Required
  }

  if (!checkIsValidAllWorkingHours(values.workingHours)) {
    errors[AddNewCompanyFormFields.WorkingHours] = ValidationErrors.Invalid // 'Not all working hours are valid!' // ValidationErrors.Required
  }

  // if (!ValidationService.isRequired(values[AddNewCompanyFormFields.ServiceCompanyId])) {
  //   errors[AddNewCompanyFormFields.ServiceCompanyId] = ValidationErrors.Required
  // }
  // if (!ValidationService.isRequired(values[AddNewCompanyFormFields.UnitId])) {
  //   errors[AddNewCompanyFormFields.UnitId] = ValidationErrors.Required
  // }
  // if (ValidationService.isMoreLengthEditNotes(values[AddNewCompanyFormFields.Notes])) {
  //   errors[AddNewCompanyFormFields.Notes] = ValidationErrors.MaxLength1024symbolsOfEDitNotes
  // }
  // if (ValidationService.isSpecialSymbols(values[AddNewCompanyFormFields.UnitId])) {
  //   errors[AddNewCompanyFormFields.UnitId] = ValidationErrors.SpecialSymbols
  // }
  // if (ValidationService.isMoreThan50Symbols(values[AddNewCompanyFormFields.RequestNumber])) {
  //   errors[AddNewCompanyFormFields.RequestNumber] =
  //     ValidationErrors.TheFieldCannotExceed50Characters
  // }

  return errors
}
