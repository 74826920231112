import { Notifications } from '@/constants/actions/settings'
import {
  AllNotificationResponseTypes,
  ChangeSettingsItems,
  CloseNotification,
  Notification,
  NotificationSettingsType,
} from '@/store/notifications/types'

export const actionsNotifications = {
  enqueueSnackbar: (payload: Notification) =>
    ({
      type: Notifications.EnqueueSnackbar,
      payload,
    } as const),

  removeSnackbar: (payload: number) =>
    ({
      type: Notifications.RemoveSnackbar,
      payload,
    } as const),

  removeAllNotifications: () =>
    ({
      type: Notifications.RemoveAllNotifications,
    } as const),

  deleteAlarms: (payload: CloseNotification) =>
    ({
      type: Notifications.deleteAlarms,
      payload,
    } as const),

  deleteEvents: (payload: CloseNotification) =>
    ({
      type: Notifications.deleteEvents,
      payload,
    } as const),

  closeSecurityAlarm: (payload: CloseNotification) =>
    ({
      type: Notifications.closeSecurityAlarmAsync,
      payload,
    } as const),

  blockAlarms: (payload: CloseNotification) =>
    ({
      type: Notifications.blockAlarms,
      payload,
    } as const),

  unblockAlarms: (payload: CloseNotification) =>
    ({
      type: Notifications.unblockAlarms,
      payload,
    } as const),

  closeTechnicalEvent: (payload: CloseNotification) =>
    ({
      type: Notifications.closeTechnicalEventAsync,
      payload,
    } as const),

  blockEvents: (payload: CloseNotification) =>
    ({
      type: Notifications.blockEvents,
      payload,
    } as const),

  unblockEvents: (payload: CloseNotification) =>
    ({
      type: Notifications.unblockEvents,
      payload,
    } as const),

  changeSettings: (payload: ChangeSettingsItems) =>
    ({
      type: Notifications.changeSettings,
      payload,
    } as const),

  fillAlarmsEventCount: (payload: AllNotificationResponseTypes) =>
    ({
      type: Notifications.FillAlarmsEventCount,
      payload,
    } as const),

  decrementAlarmsEventCount: () =>
    ({
      type: Notifications.DecrementAlarmsEventCount,
    } as const),

  changeAllSettings: (payload: NotificationSettingsType) =>
    ({
      type: Notifications.changeAllSettings,
      payload,
    } as const),

  clearNotifications: () =>
    ({
      type: Notifications.ClearNotifications,
    } as const),

  setInitialSettings: () =>
    ({
      type: Notifications.FillInitialSettings,
    } as const),
}

export const actionSetNotification = {
  setNotification: (payload: Notification) =>
    ({
      type: Notifications.setATMeyeNotification,
      payload,
    } as const),
}
