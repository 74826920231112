import React from 'react'
import clsx from 'clsx'
import { MenuItem, Checkbox } from '@material-ui/core'
import { Props } from './types'

const ListElement = React.memo(({ isChecked, name, value, classes, innerRef, onClick }: Props) => (
  <MenuItem
    key={`${name}${value}`}
    ref={innerRef}
    className={clsx(classes.normalOption, classes.menuListItem)}
    onClick={onClick}
  >
    <Checkbox checked={isChecked} />
    {name}
  </MenuItem>
))

export default ListElement
