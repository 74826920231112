import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import useTheme from '@material-ui/core/styles/useTheme'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { Sort } from '@material-ui/icons'
import clsx from 'clsx'
import { isPlainObject } from 'lodash-es'
import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'

import Tooltip from '@/components/blocks/Tooltip'
import { SELECT_FIELD_KEY } from '@/components/controls/AppTable/components/ManageableTable/component'
import { useWindowSize } from '@/utils/hooks/hooks'
import { translate } from '@/utils/internalization'

import FilterContextMenu from '../FilterContextMenu'
import { useClasses } from './styles'
import { Props } from './types'

const TableHeaderCell = ({
  data,
  value,
  filterValues,
  filter,
  orderBy,
  order,
  handleSort,
  handleSetFilter,
  groupBy,
  isDragOccurring,
  withCheckBox,
  onSelectAllRows,
  rowCount,
  colSpan,
  numSelected,
  slaStyles,
  draggableProvided: { innerRef, draggableProps, dragHandleProps },
  headClasses,
  withoutTranslate,
  prefix,
}: Props): React.ReactElement => {
  const { spacing } = useTheme()
  const [anchorEl, setAnchorEl] = useState<null | (EventTarget & HTMLButtonElement)>(null)
  const [forceWidth, setForceWidth] = useState<number | null>(null)
  const [initialWidth, setInitialWidth] = useState<number>()
  const [windowWidth, windowHeight] = useWindowSize() // rerender on window resize
  const cellRef = useRef<HTMLElement>()
  const classes = useClasses()
  const filterValue = filter[value]
  const cellMinWidth = spacing(withCheckBox ? 10 : 20)

  useEffect((): void => {
    setInitialWidth(0)
  }, [windowWidth, windowHeight])

  useEffect((): void => {
    if (cellRef && cellRef.current && !initialWidth) {
      setInitialWidth(cellRef.current.offsetWidth || cellRef.current.scrollWidth)
    }
  }, [cellRef, initialWidth])

  useEffect((): void => {
    if (cellRef.current) {
      if (isDragOccurring) {
        setForceWidth(cellRef.current.offsetWidth || cellRef.current.scrollWidth)
      } else {
        setForceWidth(null)
      }
    }
  }, [isDragOccurring, cellRef])

  const handleShowFilter = useCallback((event: SyntheticEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseFilter = useCallback((): void => {
    setAnchorEl(null)
  }, [])

  const isActiveFilterIcon = useCallback((): boolean => {
    if (!filterValue) {
      return false
    }

    if (typeof filterValue === 'string' || Array.isArray(filterValue)) {
      return !!filterValue.length
    }

    if (isPlainObject(filterValue)) {
      return !!(filterValue.start || filterValue.end)
    }

    return false
  }, [filterValue])()

  return (
    <TableCell
      colSpan={colSpan}
      ref={(ref): void => {
        innerRef(ref as HTMLElement)
        cellRef.current = ref as HTMLElement
      }}
      {...draggableProps}
      {...dragHandleProps}
      style={{
        ...draggableProps.style,
        ...(forceWidth
          ? {
              width: forceWidth,
            }
          : {}),
        ...(initialWidth ? (initialWidth <= cellMinWidth ? { width: cellMinWidth } : {}) : {}),
      }}
      sortDirection={orderBy === value ? order : false}
      classes={{ root: headClasses?.root }}
      className={clsx({
        [classes.widthOfCellDetailsTickets]: value === 'serviceItem',
        [classes.checkboxCell]: value === SELECT_FIELD_KEY,
        [classes.cellPsr]: prefix === 'PSR',
      })}
    >
      {withCheckBox ? (
        <Checkbox
          indeterminate={!!numSelected && !!rowCount && numSelected < rowCount}
          checked={data.size ? rowCount === numSelected : false}
          onChange={onSelectAllRows}
        />
      ) : (
        <div
          // className={classes.headerCellContent}
          className={clsx(headClasses?.headColumnCell, classes.headerCellContent)}
        >
          {slaStyles?.map(item => {
            if (item.label === value) {
              return (
                <div
                  key={item.label}
                  className={classes.slaItemLegendCircle}
                  style={{ background: item.color }}
                />
              )
            }
          })}
          <TableSortLabel
            classes={orderBy !== value ? { icon: classes.sortIcon } : undefined}
            className={clsx(classes.headerCellWrapper, {
              [classes.headerCellWrapperSort]: orderBy === value,
              [classes.disabledSortIcon]: prefix === 'PSR',
            })}
            active={orderBy === value && !!order}
            direction={orderBy === value ? order : 'desc'}
            onClick={handleSort(value)}
          >
            <div
              className={clsx(classes.cellText, {
                [classes.cellWrap]: prefix === 'PSR',
              })}
            >
              {/* {groupBy ? `${groupBy} / ${textValue}` : textValue} */}
              {groupBy
                ? `${groupBy} / ${
                    !withoutTranslate ? translate(`translate#title.${value}`) : value
                  }`
                : !withoutTranslate
                ? translate(`translate#title.${value}`)
                : value}
            </div>
          </TableSortLabel>
          {filterValues && (
            <>
              <Tooltip title={translate(`translate#title.filter`)}>
                <span
                  className={clsx({
                    [classes.withTooltip]: true,
                    [classes.contentItemTextWithTooltip]: true,
                  })}
                >
                  <IconButton
                    className={clsx({
                      [classes.activeFilterIcon]: isActiveFilterIcon,
                    })}
                    size="small"
                    onClick={handleShowFilter}
                  >
                    <Sort />
                  </IconButton>
                </span>
              </Tooltip>
              <FilterContextMenu
                id={value}
                values={filterValues}
                currentSelected={filterValue}
                anchorEl={anchorEl}
                handleClose={handleCloseFilter}
                handleSave={handleSetFilter}
              />
            </>
          )}
        </div>
      )}
    </TableCell>
  )
}

export default TableHeaderCell
