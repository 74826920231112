import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { BanksApi } from '@/api/cm/adminApi'
import { AdmBank } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants'

import {
  addNewBankFail,
  deleteBankFail,
  getBanksFail,
  getBanksRequest,
  getBanksResponse,
  setSelectedBank,
  updateBankFail,
} from './actions'
import { getBanksSelected, getBanksSort } from './selectors'

function* getBanks(): SagaIterator {
  try {
    const sort = yield select(getBanksSort)

    const response = yield call(BanksApi.getAll, sort)

    yield put(getBanksResponse(response))

    const selectedBank = yield select(getBanksSelected)

    if (selectedBank) {
      const newSelected = response.find(
        (bank: AdmBank) => bank.bankName === selectedBank.bankName || bank.id === selectedBank.id,
      )

      if (newSelected) {
        yield put(setSelectedBank(newSelected))
      } else {
        yield put(setSelectedBank(response[0]))
      }
    } else {
      yield put(setSelectedBank(response[0]))
    }
  } catch (error) {
    yield put(getBanksFail())
  }
}

function* addBank({ payload }: AnyAction): SagaIterator {
  try {
    yield call(BanksApi.add, payload)

    yield put(getBanksRequest())
  } catch (error) {
    yield put(addNewBankFail())
  }
}

function* updateBank({ payload }: AnyAction): SagaIterator {
  try {
    yield call(BanksApi.update, payload)

    yield put(getBanksRequest())
  } catch (error) {
    yield put(updateBankFail())
  }
}

function* deleteBank({ payload }: AnyAction): SagaIterator {
  try {
    yield call(BanksApi.delete, payload)

    yield put(getBanksRequest())
  } catch (error) {
    yield put(deleteBankFail())
  }
}

export default function*(): Generator {
  yield takeLatest(
    [CMAppConfigActions.AdmGetBanksRequest, CMAppConfigActions.AdmSortBanks],
    getBanks,
  )
  yield takeLatest(CMAppConfigActions.AdmUpdateBankRequest, updateBank)
  yield takeLatest(CMAppConfigActions.AdmDeleteBankRequest, deleteBank)
  yield takeLatest(CMAppConfigActions.AdmAddNewBankRequest, addBank)
}
