interface TabsProps {
  [key: string]: {
    value: string
    translate: string
  }
}

export const WORK_ORDERS = 'workOrders'
export const WORK_ORDER = 'workOrder'
export const CASSETTE_SETS = 'cassetteSets'
export const ROUTES = 'routes'
export const TEAMS = 'teams'

export const UNLOADED_CASSETTES = 'UnloadedCassettes'
export const CASSETTES_NOT_UNLOADED = 'CassettesNotUnloaded'

export const CASSETTES = 'Cassettes'
export const CHART_BY_CURRENCY = 'ChartsByCurrency'
export const CHART_BY_CASSETTES = 'ChartsByCassettes'
export const CHART_CIN = 'ChartCIN'

export const AdminTabs: TabsProps = {
  AccessRules: {
    value: 'Admin_AccessRules',
    translate: 'translate#cm.Admin_AccessRules',
  },
  UsersAndRoles: {
    value: 'Admin_UsersAndRoles',
    translate: 'translate#cm.Admin_UsersAndRoles',
  },
  Objects: {
    value: 'Admin_Objects',
    translate: 'translate#cm.Admin_Objects',
  },
  CIT: {
    value: 'Admin_CIT',
    translate: 'translate#cm.Admin_CIT',
  },
  AppConfig: {
    value: 'Admin_AppConfig',
    translate: 'translate#cm.AppConfig',
  },
  AdminLicense: {
    value: 'Admin_License',
    translate: 'translate#cm.Admin_License',
  },
}

export const AppConfigTabs = {
  AppConfiguration: 'AppConfiguration',
  DataImportAndForecast: 'DataImportAndForecast',
  Currencies: 'Currencies',
  CassetteTypes: 'CassetteTypes',
  Banks: 'Banks',
  AutomatedOperations: 'AutomatedOperations',
  EventNotifications: 'EventNotifications',
  RoundUpLoadQuantityForPlanning: 'RoundUpLoadQuantityForPlanning',
  RealTimeMonitoring: 'RealTimeMonitoring',
  UploadLogo: 'UploadLogo',
  PasswordPolicy: 'PasswordPolicy',
}

export const ObjectTabs = {
  Limits: 'Limits',
  Objects: 'Objects',
  NotRegistered: 'NotRegistered',
  IncidentsLimits: 'IncidentsLimits',
  CassettesLimits: 'CassettesLimits',
  CassettesLimitsIncident: 'CassettesLimitsIncident',
  SpecialDays: 'SpecialDays',
  SpecialPeriods: 'SpecialPeriods',
  Networks: 'Networks',
  CashReplenishmentCosts: 'CashReplenishmentCosts',
  UserNetworks: 'UserNetworks',
  WorkingHours: 'WorkingHours',
  CassetteTypes: 'CassetteTypes',
  RoundingSettings: 'RoundingSettings',
}

export const AccessRulesTabs = {
  AccessRules: 'AccessRules',
  ApprovalChanges: 'ApprovalChanges',
  UserActivity: 'UserActivity',
}

export const UserAndRoles = {
  Users: 'Users',
  Roles: 'Roles',
  Companies: 'Companies',
  Staff: 'Staff',
}

export const CitTabs = {
  Routes: 'Routes',
  Teams: 'Teams',
  FuelTypes: 'FuelTypes',
  VehicleTypes: 'VehicleTypes',
  Vehicle: 'Vehicle',
  Employees: 'Employees',
  CashSorters: 'CashSorters',
}

export const LicenseTabs = {
  License: 'Admin_License',
}
