import { LoginField } from '@/components/pages/usm/UsersPage/components/PersonalInformation/types'
import { UserInformationField } from '@/components/pages/usm/UsersPage/components/UserInformation/components/modals/PersonModal/types'

export interface Props {
  open: boolean
  handleClose: () => void
  disableBackdropClick?: boolean
  disableEscapeKeyDown?: boolean
}

export type Menu = {
  personalInformation: string
  loginInformation: string
  userInformation: string
}

export enum AddNewUserPersonalCardField {
  ExistingPerson = 'personId',
  FirstName = 'firstName',
  MiddleName = 'middleName',
  LastName = 'lastName',
  Salutation = 'salutation',
  Gender = 'gender',
  ChangePassword = 'changePassword',
  Company = 'companyId',
  UserName = 'userName',
  UserGroup = 'userGroup',
  EMail = 'eMail',
  ExpiryDate = 'expiryDate',
  NeverExpires = 'neverExpires',
  AddPhoto = 'addPhoto',
}

export type AddUserPersonalCardValues = {
  [AddNewUserPersonalCardField.ExistingPerson]: string
  [AddNewUserPersonalCardField.FirstName]: string
  [AddNewUserPersonalCardField.LastName]: string
  [AddNewUserPersonalCardField.MiddleName]: string
  [AddNewUserPersonalCardField.Salutation]: string
  [AddNewUserPersonalCardField.Gender]: string
  [LoginField.Login]: string
  [LoginField.Password]: string
  [LoginField.RepeatPassword]: string
  [LoginField.TryCount]: string
  [AddNewUserPersonalCardField.ChangePassword]: string
  [UserInformationField.Company]: string
  [UserInformationField.UserName]: string
  [UserInformationField.UserGroup]: string
  [UserInformationField.EMail]: string
  [UserInformationField.ExpiryDate]: string
  [UserInformationField.NeverExpires]: boolean
  [AddNewUserPersonalCardField.AddPhoto]: string
  [UserInformationField.AllowAutoProlong]: boolean
  [UserInformationField.RoleId]: string
  [UserInformationField.RoleName]: string
}
