import { AnyAction } from 'redux'

import { DevicesActions } from '@/constants/actions/adm/devices'
import { ResponseError } from '@/types'
import {
  AddAddressRequestData,
  AddAttachedFileRequest,
  AddDetailConfigurationRequest,
  AddDeviceRequestData,
  AddHardwareRequestData,
  AddMerchantRequestData,
  AddMerchantResponseData,
  AddModuleConfigurationRequest,
  AddRelatedCompanyRequest,
  AddressEditingData,
  AddressResponseData,
  AttachedFile,
  AttachedFilesResponseData,
  CloseAddressRequestData,
  Configuration,
  ConfigurationResponseData,
  CreateNewDeviceRequestData,
  DeviceResponseData,
  EditAddressRequestData,
  EditConfigurationRequest,
  EditDeviceRequestData,
  EditGeoRequestData,
  EditHardwareRequestData,
  EditMerchantRequestData,
  EditRelatedCompanyRequest,
  EditScheduleRequest,
  GeoResponseData,
  GetAddressRequestData,
  GetAttachedFilesRequest,
  GetConfigurationRequest,
  GetDeviceRequestData,
  GetGeoRequestData,
  GetHardwareRequestData,
  GetMerchantRequestData,
  GetMerchantResponseData,
  GetRelatedCompaniesRequestData,
  GetScheduleRequest,
  GroupChangeStateResponseData,
  GroupChangeStatusRequestData,
  GroupHardwareOwnerRequestData,
  GroupServiceDatesRequestData,
  Hardware,
  LocationResponseData,
  MoveAddressRequestData,
  RelatedCompany,
  RemoveAttachedFileRequest,
  RemoveConfigurationRequest,
  RemoveMerchantRequestData,
  RemoveRelatedCompanyRequest,
  ScheduleResponseData,
} from '@/types/adm/device/api'

// Create new device
export const createNewDeviceResponseFail = (error: ResponseError): AnyAction => ({
  type: DevicesActions.CreateNewDeviceResponseFail,
  payload: { error },
})

export const createNewDeviceRequest = (data: CreateNewDeviceRequestData): AnyAction => ({
  type: DevicesActions.CreateNewDeviceRequest,
  payload: data,
})
export const setIsFetchingDeviceInformation = (): AnyAction => ({
  type: DevicesActions.SetIsFetchingDeviceInformation,
})

// Device
export const getDeviceResponseFail = (error: ResponseError): AnyAction => ({
  type: DevicesActions.GetDeviceResponseFail,
  payload: { error },
})

export const getDeviceRequest = (data: GetDeviceRequestData): AnyAction => ({
  type: DevicesActions.GetDeviceRequest,
  payload: data,
})
export const getDeviceResponse = (device: DeviceResponseData): AnyAction => ({
  type: DevicesActions.GetDeviceResponse,
  payload: device,
})

export const getDeviceViewInfoRequest = (deviceId: number): AnyAction => ({
  type: DevicesActions.GetDeviceViewInfoRequest,
  payload: deviceId,
})
export const getDeviceViewInfoResponse = (device: DeviceResponseData): AnyAction => ({
  type: DevicesActions.GetDeviceViewInfoResponse,
  payload: device,
})

export const getDeviceViewInfoResponseFail = (error: ResponseError): AnyAction => ({
  type: DevicesActions.GetDeviceViewInfoResponseFail,
  payload: { error },
})

export const addDeviceRequest = (data: AddDeviceRequestData): AnyAction => ({
  type: DevicesActions.AddDeviceRequest,
  payload: data,
})
export const addDeviceResponse = (device: DeviceResponseData): AnyAction => ({
  type: DevicesActions.AddDeviceResponse,
  payload: device,
})

export const editDeviceRequest = (data: EditDeviceRequestData): AnyAction => ({
  type: DevicesActions.EditDeviceRequest,
  payload: data,
})
export const editDeviceResponse = (device: DeviceResponseData): AnyAction => ({
  type: DevicesActions.EditDeviceResponse,
  payload: device,
})

// Group actions
export const groupChangeDeviceStateRequest = (data: GroupChangeStatusRequestData): AnyAction => ({
  type: DevicesActions.GroupChangeDeviceStateRequest,
  payload: data,
})

export const groupChangeHardwareOwnerRequest = (
  data: GroupHardwareOwnerRequestData,
): AnyAction => ({
  type: DevicesActions.GroupChangeHardwareOwnerRequest,
  payload: data,
})

export const groupChangeServiceDatesRequest = (data: GroupServiceDatesRequestData): AnyAction => ({
  type: DevicesActions.GroupChangeServiceDatesRequest,
  payload: data,
})

export const groupChangeStateResponse = (data: GroupChangeStateResponseData[]): AnyAction => ({
  type: DevicesActions.GroupChangeStateResponse,
  payload: data,
})

// Hardware
export const getHardwareResponseFail = (error: ResponseError): AnyAction => ({
  type: DevicesActions.GetHardwareResponseFail,
  payload: { error },
})

export const getHardwareRequest = (data: GetHardwareRequestData): AnyAction => ({
  type: DevicesActions.GetHardwareRequest,
  payload: data,
})
export const getHardwareResponse = (hardware: Hardware): AnyAction => ({
  type: DevicesActions.GetHardwareResponse,
  payload: hardware,
})

export const addHardwareRequest = (data: AddHardwareRequestData): AnyAction => ({
  type: DevicesActions.AddHardwareRequest,
  payload: data,
})

export const editHardwareRequest = (data: EditHardwareRequestData): AnyAction => ({
  type: DevicesActions.EditHardwareRequest,
  payload: data,
})

export const removeHardwareRequest = (data: GetHardwareRequestData): AnyAction => ({
  type: DevicesActions.RemoveHardwareRequest,
  payload: data,
})
export const removeHardwareResponse = (): AnyAction => ({
  type: DevicesActions.RemoveHardwareResponse,
})

// Address
export const getAddressResponseFail = (error: ResponseError): AnyAction => ({
  type: DevicesActions.GetAddressResponseFail,
  payload: { error },
})

export const getAddressRequest = (data: GetAddressRequestData): AnyAction => ({
  type: DevicesActions.GetAddressRequest,
  payload: data,
})
export const getAddressResponse = (data: AddressResponseData): AnyAction => ({
  type: DevicesActions.GetAddressResponse,
  payload: data,
})

// Geo
export const getGeoRequest = (data: GetGeoRequestData): AnyAction => ({
  type: DevicesActions.GetGeoRequest,
  payload: data,
})

export const getGeoResponse = (data: GeoResponseData): AnyAction => ({
  type: DevicesActions.GetGeoResponse,
  payload: data,
})

export const editGeoRequest = (data: EditGeoRequestData): AnyAction => ({
  type: DevicesActions.EditGeoRequest,
  payload: data,
})

export const editGeoResponse = (data: GeoResponseData): AnyAction => ({
  type: DevicesActions.EditGeoResponse,
  payload: data,
})

// Locate
export const locateGeoRequest = (data: number): AnyAction => ({
  type: DevicesActions.LocateGeoRequest,
  payload: data,
})

export const locateGeoResponse = (data: LocationResponseData): AnyAction => ({
  type: DevicesActions.LocateGeoResponse,
  payload: data,
})

// Address
export const addAddressRequest = (data: AddAddressRequestData): AnyAction => ({
  type: DevicesActions.AddAddressRequest,
  payload: data,
})
export const addAddressResponse = (data: AddressResponseData): AnyAction => ({
  type: DevicesActions.AddAddressResponse,
  payload: data,
})

export const editAddressRequest = (data: EditAddressRequestData): AnyAction => ({
  type: DevicesActions.EditAddressRequest,
  payload: data,
})
export const editAddressResponse = (data: AddressResponseData): AnyAction => ({
  type: DevicesActions.EditAddressResponse,
  payload: data,
})

export const closeAddressRequest = (data: CloseAddressRequestData): AnyAction => ({
  type: DevicesActions.CloseAddressRequest,
  payload: data,
})
export const closeAddressResponse = (): AnyAction => ({
  type: DevicesActions.CloseAddressResponse,
})

export const moveAddressRequest = (data: MoveAddressRequestData): AnyAction => ({
  type: DevicesActions.MoveAddressRequest,
  payload: data,
})
export const moveAddressResponse = (data: AddressEditingData): AnyAction => ({
  type: DevicesActions.MoveAddressResponse,
  payload: data,
})

// Related company
export const getRelatedCompaniesResponseFail = (error: ResponseError): AnyAction => ({
  type: DevicesActions.GetRelatedCompaniesResponseFail,
  payload: { error },
})

export const getRelatedCompaniesRequest = (data: GetRelatedCompaniesRequestData): AnyAction => ({
  type: DevicesActions.GetRelatedCompaniesRequest,
  payload: data,
})
export const getRelatedCompaniesResponse = (companies: RelatedCompany[]): AnyAction => ({
  type: DevicesActions.GetRelatedCompaniesResponse,
  payload: companies,
})

export const addRelatedCompanyRequest = (data: AddRelatedCompanyRequest): AnyAction => ({
  type: DevicesActions.AddRelatedCompanyRequest,
  payload: data,
})
export const addRelatedCompanyResponse = (companies: RelatedCompany[]): AnyAction => ({
  type: DevicesActions.AddRelatedCompanyResponse,
  payload: companies,
})

export const editRelatedCompanyRequest = (data: EditRelatedCompanyRequest): AnyAction => ({
  type: DevicesActions.EditRelatedCompanyRequest,
  payload: data,
})
export const editRelatedCompanyResponse = (company: RelatedCompany): AnyAction => ({
  type: DevicesActions.EditRelatedCompanyResponse,
  payload: company,
})

export const removeRelatedCompanyRequest = (data: RemoveRelatedCompanyRequest): AnyAction => ({
  type: DevicesActions.RemoveRelatedCompanyRequest,
  payload: data,
})
export const removeRelatedCompanyResponse = (entryId: number): AnyAction => ({
  type: DevicesActions.RemoveRelatedCompanyResponse,
  payload: entryId,
})

// Service info
export const getMerchantLunoByAcquirersResponseFail = (error: ResponseError): AnyAction => ({
  type: DevicesActions.GetMerchantLunoByAcquirersResponseFail,
  payload: { error },
})

export const getMerchantLunoByAcquirersRequest = (data: GetMerchantRequestData): AnyAction => ({
  type: DevicesActions.GetMerchantLunoByAcquirersRequest,
  payload: data,
})
export const getMerchantLunoByAcquirersResponse = (data: GetMerchantResponseData): AnyAction => ({
  type: DevicesActions.GetMerchantLunoByAcquirersResponse,
  payload: data,
})

export const addMerchantLunoByAcquirersRequest = (data: AddMerchantRequestData): AnyAction => ({
  type: DevicesActions.AddMerchantLunoByAcquirersRequest,
  payload: data,
})
export const addMerchantLunoByAcquirersResponse = (data: AddMerchantResponseData): AnyAction => ({
  type: DevicesActions.AddMerchantLunoByAcquirersResponse,
  payload: data,
})

export const editMerchantLunoByAcquirersRequest = (data: EditMerchantRequestData): AnyAction => ({
  type: DevicesActions.EditMerchantLunoByAcquirersRequest,
  payload: data,
})
export const editMerchantLunoByAcquirersResponse = (data: AddMerchantResponseData): AnyAction => ({
  type: DevicesActions.EditMerchantLunoByAcquirersResponse,
  payload: data,
})

export const removeMerchantLunoByAcquirersRequest = (
  data: RemoveMerchantRequestData,
): AnyAction => ({
  type: DevicesActions.RemoveMerchantLunoByAcquirersRequest,
  payload: data,
})
export const removeMerchantLunoByAcquirersResponse = (
  data: RemoveMerchantRequestData,
): AnyAction => ({
  type: DevicesActions.RemoveMerchantLunoByAcquirersResponse,
  payload: data,
})

// Attached files
export const getAttachedFilesResponseFail = (error: ResponseError): AnyAction => ({
  type: DevicesActions.GetAttachedFilesResponseFail,
  payload: { error },
})

export const getAttachedFilesRequest = (data: GetAttachedFilesRequest): AnyAction => ({
  type: DevicesActions.GetAttachedFilesRequest,
  payload: data,
})
export const getAttachedFilesResponse = (files: AttachedFilesResponseData): AnyAction => ({
  type: DevicesActions.GetAttachedFilesResponse,
  payload: files,
})

export const addAttachedFileRequest = (file: AddAttachedFileRequest): AnyAction => ({
  type: DevicesActions.AddAttachedFileRequest,
  payload: file,
})
export const addAttachedFileResponse = (file: AttachedFile): AnyAction => ({
  type: DevicesActions.AddAttachedFileResponse,
  payload: file,
})

export const removeAttachedFileRequest = (data: RemoveAttachedFileRequest): AnyAction => ({
  type: DevicesActions.RemoveAttachedFileRequest,
  payload: data,
})
export const removeAttachedFileResponse = (id: number): AnyAction => ({
  type: DevicesActions.RemoveAttachedFileResponse,
  payload: id,
})

export const changeAttachedFilesPage = (page: number): AnyAction => ({
  type: DevicesActions.ChangeAttachedFilesPage,
  payload: page,
})
export const changeAttachedFilesPageSize = (pageSize: number): AnyAction => ({
  type: DevicesActions.ChangeAttachedFilesPageSize,
  payload: pageSize,
})

// Configuration
export const getConfigurationResponseFail = (error: ResponseError): AnyAction => ({
  type: DevicesActions.GetConfigurationResponseFail,
  payload: { error },
})

export const getConfigurationRequest = (data: GetConfigurationRequest): AnyAction => ({
  type: DevicesActions.GetConfigurationRequest,
  payload: data,
})
export const getConfigurationResponse = (configuration: ConfigurationResponseData): AnyAction => ({
  type: DevicesActions.GetConfigurationResponse,
  payload: configuration,
})

export const addDetailRequest = (detail: AddDetailConfigurationRequest): AnyAction => ({
  type: DevicesActions.AddDetailRequest,
  payload: detail,
})
export const addDetailResponse = (data: Configuration): AnyAction => ({
  type: DevicesActions.AddDetailResponse,
  payload: data,
})

export const addModuleRequest = (module: AddModuleConfigurationRequest): AnyAction => ({
  type: DevicesActions.AddModuleRequest,
  payload: module,
})
export const addModuleResponse = (data: Configuration): AnyAction => ({
  type: DevicesActions.AddModuleResponse,
  payload: data,
})

export const editConfigurationRequest = (item: EditConfigurationRequest): AnyAction => ({
  type: DevicesActions.EditConfigurationRequest,
  payload: item,
})
export const editConfigurationResponse = (data: ConfigurationResponseData): AnyAction => ({
  type: DevicesActions.EditConfigurationResponse,
  payload: data,
})

export const removeConfigurationRequest = (data: RemoveConfigurationRequest): AnyAction => ({
  type: DevicesActions.RemoveConfigurationRequest,
  payload: data,
})
export const removeConfigurationResponse = (data: RemoveConfigurationRequest): AnyAction => ({
  type: DevicesActions.RemoveConfigurationResponse,
  payload: data,
})

export const changeConfigurationPage = (page: number): AnyAction => ({
  type: DevicesActions.ChangeConfigurationPage,
  payload: page,
})
export const changeConfigurationPageSize = (pageSize: number): AnyAction => ({
  type: DevicesActions.ChangeConfigurationPageSize,
  payload: pageSize,
})

// Schedule
export const getScheduleResponseFail = (error: ResponseError): AnyAction => ({
  type: DevicesActions.GetScheduleResponseFail,
  payload: { error },
})

export const getScheduleRequest = (data: GetScheduleRequest): AnyAction => ({
  type: DevicesActions.GetScheduleRequest,
  payload: data,
})
export const getScheduleResponse = (schedule: ScheduleResponseData): AnyAction => ({
  type: DevicesActions.GetScheduleResponse,
  payload: schedule,
})

export const editScheduleRequest = (data: EditScheduleRequest): AnyAction => ({
  type: DevicesActions.EditScheduleRequest,
  payload: data,
})
export const editScheduleResponse = (schedule: ScheduleResponseData): AnyAction => ({
  type: DevicesActions.EditScheduleResponse,
  payload: schedule,
})
