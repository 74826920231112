import { useMemo } from 'react'

interface Props {
  keys: string[]
  fixedColumns?: string[]
}

interface ResultProps {
  localFixedColumns?: string[]
  localColumns: string[]
}

export const useFixedColumns = (props: Props): ResultProps => {
  const { keys, fixedColumns } = props

  const localFixedColumns = useMemo(
    () => (fixedColumns ? keys.filter(key => fixedColumns?.includes(key)) : undefined),
    [keys, fixedColumns],
  )
  const localColumns = useMemo(
    () => (fixedColumns ? keys.filter(key => !fixedColumns?.includes(key)) : keys),
    [keys, fixedColumns],
  )

  return {
    localFixedColumns,
    localColumns,
  }
}
