import { useMemo } from 'react'

import { TableData } from '../types'

type DataByGroup = { [key: string]: TableData[] }

interface PrepareDataProps {
  data: TableData[]

  typeKey?: string

  selectedRowId?: string
}

interface Result {
  dataByType: DataByGroup
  defaultKey: string
}

export const useGroupData = (props: PrepareDataProps): Result => {
  const { data, typeKey } = props

  const defaultKey = '0'

  const dataByType = useMemo(() => {
    const result: DataByGroup = data.reduce((acc: DataByGroup, item) => {
      const localKey = (typeKey && item[typeKey] ? item[typeKey] : defaultKey) as string

      return {
        ...acc,
        [localKey]: [...(acc[localKey] || []), item],
      }
    }, {})

    return result
  }, [data, typeKey])

  return { dataByType, defaultKey }
}
