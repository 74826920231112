import Grid from '@material-ui/core/Grid'
import React, {
  MutableRefObject,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FormattedMessage } from 'react-intl'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { Command } from '@/api/rsocket/command'
import Loader from '@/components/blocks/Loader'
import GlobalConfirmModal from '@/components/blocks/LogoutConfirmModal/component'
import { SocketContext } from '@/context'
import { clearSocketDataError, getSocketData, getSocketDataError } from '@/store/dashboard'
import { useTranslate } from '@/utils/internalization'
import { PopUpService } from '@/utils/services/popUpService'

import { CORE_MODAL_TYPE } from '../types'
import { useClasses } from './styles'
import { Props, SocketResponse } from './types'

const CoreModal = ({
  coreModalType,

  currentDevice,

  onClose,
  onCoreChange,
}: Props): ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const dispatch = useDispatch()

  const isOpen = coreModalType === CORE_MODAL_TYPE.OFF || coreModalType === CORE_MODAL_TYPE.ON
  const command =
    coreModalType === CORE_MODAL_TYPE.OFF ? Command.CONTROL_CORE_STOP : Command.CONTROL_CORE_START

  const { _service } = useContext(SocketContext)
  const data: SocketResponse = useSelector(getSocketData(command), shallowEqual)
  const errorSocket = useSelector(getSocketDataError)

  const [isCommandSended, setIsCommandSended] = useState<boolean>(false)
  const [noResponse, setNoResponse] = useState<boolean>(false)
  const timer: MutableRefObject<NodeJS.Timeout | undefined> = useRef()

  const handleClearTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current)
      timer.current = undefined
    }
    setNoResponse(false)
  }, [])

  useEffect(() => {
    return handleClearTimer()
  }, [handleClearTimer])

  const handleClose = useCallback(() => {
    setIsCommandSended(false)
    handleClearTimer()
    onClose()
  }, [handleClearTimer, onClose])

  useEffect(() => {
    if (errorSocket) {
      PopUpService.showGlobalPopUp(errorSocket, 'error')
      handleClose()
      dispatch(clearSocketDataError())
    }
  }, [errorSocket, dispatch, handleClose])

  useEffect(() => {
    if (isCommandSended && data) {
      handleClose()

      if (data.status === 'OK') {
        onCoreChange()

        const title = coreModalType === CORE_MODAL_TYPE.OFF ? 'stopCoreSuccess' : 'startCoreSuccess'

        PopUpService.showGlobalPopUp(`translate#title.${title}`, 'success')
      } else {
        const title = coreModalType === CORE_MODAL_TYPE.OFF ? 'stopCoreFail' : 'startCoreFail'

        PopUpService.showGlobalPopUp(
          translate(`translate#title.${title}`) + ': ' + data.status,
          'error',
        )
      }
    }
  }, [coreModalType, data, handleClose, isCommandSended, onCoreChange, translate])

  const sendCommand = useCallback((): void => {
    _service.send({}, currentDevice?.deviceName, command)

    timer.current = setTimeout(function() {
      setNoResponse(true)
    }, 300000)

    setIsCommandSended(true)
  }, [_service, command, currentDevice])

  const modalTitle =
    coreModalType === CORE_MODAL_TYPE.OFF
      ? translate('translate#atmeye.title.devices.stopCore')
      : translate('translate#atmeye.title.devices.startCore')

  const modalSubTitle =
    coreModalType === CORE_MODAL_TYPE.OFF
      ? translate('translate#atmeye.title.devices.confirmCoreOffAction')
      : translate('translate#atmeye.title.devices.confirmCoreOnAction')

  const contentModal = isCommandSended ? (
    noResponse ? (
      <Grid container>
        <FormattedMessage id="atmeye.message.exceededTimeout" defaultMessage="Exceeded timeout" />
      </Grid>
    ) : (
      <Grid container justify="center">
        <Loader className={classes.loader} />
      </Grid>
    )
  ) : null

  return (
    <GlobalConfirmModal
      open={isOpen}
      buttonsOptions={{ isHideButtons: isCommandSended }}
      onClose={handleClose}
      message={modalTitle}
      subMessage={modalSubTitle}
      onConfirm={sendCommand}
      content={contentModal}
    />
  )
}

export default CoreModal
