import MomentUtils from '@date-io/moment'
import { common } from '@material-ui/core/colors'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import clsx from 'clsx'
import { FieldProps } from 'formik'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { DEFAULT_DATE_FORMAT } from '@/constants/time'
import { generateErrorText } from '@/helpers/generateErrorText'
import colors from '@/theme/colors'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import { DateInputProps, FormikDatePickerFieldProps, FormikDatePickerProps } from './types'

const toolbarTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      dark: colors.primaryDark,
      contrastText: common.white,
    },
    secondary: {
      main: colors.secondary,
      contrastText: common.white,
    },
    // contrastThreshold: 0,
  },
})

export const DateInput = ({
  disableFutureDates,
  disablePast,
  error,
  helperText,
  className,
  maxDate,
  minDate,
  Icon,
  ...props
}: DateInputProps): React.ReactElement => {
  const [isOpen, toogleDatePopover] = useState<boolean>(false)
  const classes = useClasses()

  const handleOpenCloseDatePicker = useCallback(() => {
    toogleDatePopover(open => !open)
  }, [toogleDatePopover])

  return (
    <ThemeProvider theme={toolbarTheme}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <KeyboardDatePicker
          {...props}
          size="small"
          variant="inline"
          margin="normal"
          onOpen={handleOpenCloseDatePicker}
          onClose={handleOpenCloseDatePicker}
          open={isOpen}
          onAccept={handleOpenCloseDatePicker}
          maxDate={maxDate || disableFutureDates ? maxDate : undefined}
          minDate={minDate || disablePast ? minDate : undefined}
          inputVariant="outlined"
          className={clsx(classes.input, className, {
            [classes.inputWithError]: error,
          })}
          InputLabelProps={{
            className: clsx(classes.label, { [classes.errorLabel]: error }),
            shrink: true,
          }}
          InputAdornmentProps={{
            className: classes.icon,
          }}
          keyboardIcon={Icon}
          mask="__.__.____"
          format={DEFAULT_DATE_FORMAT}
          disabled={props.disabled}
          error={error}
          helperText={
            helperText ? (
              <div className={classes.errorHelperText}>
                <FormattedMessage id={`form.validationWarning.${helperText}`} />
              </div>
            ) : null
          }
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export const FormikDatePicker = ({
  field,
  form: { errors, setFieldValue, getFieldHelpers },
  meta: { touched },
  label,
  className,
  format,
  placeholder,
  disabled,
  disabledPast = false,
  disableFutureDates,
  Icon,
  dependDate,
  defaultDate,
  currentDate,
  ...other
}: FormikDatePickerProps): React.ReactElement => {
  const maxDate = new Date()
  const minDate = new Date()
  const classes = useClasses()

  const handleDateChange = useCallback(
    (momentDate, date): void => {
      if (date && !date.includes('_')) {
        const defaultDate = moment()
        const fieldValue =
          momentDate.isValid() && !moment(momentDate).isBefore(defaultDate)
            ? momentDate.toISOString(true)
            : defaultDate.toISOString(true)

        getFieldHelpers(field.name).setTouched(true)

        setFieldValue(field.name, fieldValue, true)
      }
      !date && setFieldValue(field.name, '')
    },
    [setFieldValue, field.name],
  )

  useEffect(() => {
    !dependDate && defaultDate
      ? setFieldValue(field.name, currentDate || defaultDate)
      : setFieldValue(field.name, '')
  }, [dependDate, currentDate])

  const value = useMemo(() => {
    return field.value ? field.value : null
  }, [field.value])
  return (
    <DateInput
      label={label}
      className={className}
      disablePast={disabledPast}
      name={field.name}
      value={value}
      onChange={handleDateChange}
      format={format}
      placeholder={placeholder}
      onBlur={(): void => {
        getFieldHelpers(field.name).setTouched(true)
      }}
      maxDate={disableFutureDates ? maxDate : undefined}
      minDate={disabledPast ? minDate : undefined}
      disableFutureDates={disableFutureDates}
      helperText={generateErrorText(errors, field.name, touched)}
      error={!!errors[field.name] && touched}
      {...other}
      InputLabelProps={{
        shrink: true,
        className: classes.asterisk,
      }}
      disabled={disabled}
      Icon={Icon}
    />
  )
}

export const createDatePickerFormField = ({
  label,
  format,
  widthClass,
  wrapperDateClass,
  disabled,
  required,
  withoutCurrentDate,
  placeholder,
  disabledPast,
  disableFutureDates = true,
  Icon,
  dependDate,
  defaultDate,
  currentDate,
}: FormikDatePickerFieldProps) => (formikProps: FieldProps): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  return (
    <div className={wrapperDateClass}>
      <FormikDatePicker
        {...formikProps}
        index={0}
        format={format}
        label={label || translate('translate#title.from')}
        className={clsx(classes.mr, widthClass)}
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        withoutCurrentDate={withoutCurrentDate}
        disableFutureDates={disableFutureDates}
        disabledPast={disabledPast}
        Icon={Icon}
        dependDate={dependDate}
        defaultDate={defaultDate}
        currentDate={currentDate}
      />
    </div>
  )
}
