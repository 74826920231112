import { useQuery, UseQueryResult } from 'react-query'

import { CassettesApi } from '@/api/vault-v1/cassettesApi'
import { VaultV1CommonApi } from '@/api/vault-v1/commonApi'
import {
  CassetteBarcodeResponse,
  CassetteDetails,
  ComExtUser,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { queryClient } from '@/constants/queryClient'
import { UserTypes } from '@/types/vault-v1'

const queryOptions = {
  retry: 0,
  refetchOnWindowFocus: false,
}

export const useQueryCassetteDetails = (
  guid?: string,
  isActive?: boolean,
): UseQueryResult<CassetteDetails, Error> =>
  useQuery(['cassetteDetails', guid], async () => guid && (await CassettesApi.details(guid)), {
    ...queryOptions,
    enabled: !!guid && isActive,
  })

export const refetchCassetteDetails = (guid?: string): unknown =>
  queryClient.fetchQuery(['cassetteDetails', guid])

export const useQueryUsers = (): UseQueryResult<ComExtUser[], Error> =>
  useQuery(['cashiers'], async () => await VaultV1CommonApi.getUsers(UserTypes.Cashier), {
    ...queryOptions,
  })

export const useQueryBarcode = (
  barcode?: string,
  guid?: string,
): UseQueryResult<CassetteBarcodeResponse, Error> =>
  useQuery(
    ['cassetteBarcode', barcode, guid],
    async () => barcode && (await CassettesApi.barcode(barcode)),
    {
      ...queryOptions,
      enabled: !!barcode && !!guid,
    },
  )
