import makeStyles from '@material-ui/core/styles/makeStyles'
import colors from '@/theme/colors'
import { isIE } from 'react-device-detect'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  wrapper: {
    display: isIE ? 'block' : 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: spacing(3),
  },
  resultTitle: {
    margin: spacing(3),
    textAlign: 'center',
  },
  field: {
    '&:nth-of-type(2n + 1)': {
      paddingRight: spacing(2),
    },
    '&:nth-of-type(2n)': {
      paddingLeft: spacing(2),
    },
  },
  searchResults: {
    display: isIE ? 'block' : 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    margin: spacing(3, 0, 1, 0),
  },
  tableWrapper: {
    display: isIE ? 'block' : 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    flex: isIE ? 'auto' : 1,
    marginBottom: spacing(3),
  },
  table: {
    width: 'auto',
  },
  loader: {
    height: spacing(10),
  },
  fieldsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  clearButton: {
    background: 'transparent',
    color: colors.primary,
    border: '1px solid',
    borderColor: colors.primary,
  },
}))
