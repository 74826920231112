import React, {
  ReactElement,
  useState,
  useCallback,
  useEffect,
  useRef,
  SyntheticEvent,
} from 'react'
import { createPortal } from 'react-dom'
import clsx from 'clsx'
import { isPlainObject } from 'lodash-es'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import FilterContextMenu from './components/FilterContextMenu'
import Typography from '@material-ui/core/Typography'
import useTheme from '@material-ui/core/styles/useTheme'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'

import { dummyFunction } from '@/utils/functions'
import { useClasses } from './styles'
import { Props } from './types'

const TableHeader = ({
  columnsOrderTemplate,
  columnsOrder,
  headers,
  order,
  orderBy,
  filter,
  filterColumns,
  actionColumnTitle,
  classes: customClasses,
  setTableSettingsToLocalStorage,
  setColumnsOrderTemplate,
  setOrder,
  setOrderBy,
  handleSetFilter,
  handleSetSorting,
  idPrefix,
}: Props): ReactElement => {
  const { spacing } = useTheme()
  const [openedFilterName, setOpenedFilterName] = useState<string | false>(false)
  const [anchorEl, setAnchorEl] = useState<null | (EventTarget & HTMLButtonElement)>(null)
  const [forceWidth, setForceWidth] = useState<number | null>(null)
  const [dragStarted, setDragStarted] = useState<boolean>(false)
  const cellRef = useRef<HTMLElement>()
  const [initialWidth, setInitialWidth] = useState<number>()

  const cellMinWidth = spacing(20)
  const classes = useClasses()

  const handleSort = useCallback(
    (property: string) => (): void => {
      if (orderBy === property) {
        switch (order) {
          case undefined:
            setOrder('asc')
            return handleSetSorting(property, 'asc')
          case 'asc':
            setOrder('desc')
            return handleSetSorting(property, 'desc')
          case 'desc':
            setOrder('asc')
            return handleSetSorting(property, 'asc')
        }
      } else {
        setOrder('desc')
        handleSetSorting(property, 'desc')
      }

      setOrderBy(property)
    },
    [order, orderBy],
  )

  const handleDragEnd = useCallback<(result: DropResult, provided: ResponderProvided) => void>(
    (result): void => {
      setDragStarted(false)
      if (!result.destination) {
        return
      }

      if (columnsOrder && columnsOrderTemplate && setColumnsOrderTemplate) {
        const movedItem = columnsOrder[result.source.index]
        const movedItemIndex = columnsOrderTemplate.findIndex(
          (value: string) => value === columnsOrder[result.source.index],
        )
        const supportItemIndex = columnsOrderTemplate.findIndex(
          (value: string) => result.destination && value === columnsOrder[result.destination.index],
        )

        const newColumnsOrderTemplate = columnsOrderTemplate.slice()

        newColumnsOrderTemplate.splice(
          movedItemIndex < supportItemIndex ? supportItemIndex + 1 : supportItemIndex,
          0,
          movedItem,
        )
        newColumnsOrderTemplate.splice(
          movedItemIndex > supportItemIndex ? movedItemIndex + 1 : movedItemIndex,
          1,
        )

        setColumnsOrderTemplate(newColumnsOrderTemplate)

        if (setTableSettingsToLocalStorage) {
          setTableSettingsToLocalStorage(
            newColumnsOrderTemplate.filter(column => columnsOrder.includes(column)),
            newColumnsOrderTemplate,
          )
        }
      }
    },
    [columnsOrderTemplate, columnsOrder, setColumnsOrderTemplate, setTableSettingsToLocalStorage],
  )

  const handleShowFilter = useCallback(
    (filterName: string) => (event: SyntheticEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget)
      setOpenedFilterName(filterName)
    },
    [],
  )

  const handleCloseFilter = useCallback((): void => {
    setAnchorEl(null)
    setOpenedFilterName(false)
  }, [])

  const isActiveFilterIcon = useCallback(
    (value: string) => (): boolean => {
      const filterValue = filter[value]

      if (!filterValue) {
        return false
      }

      if (typeof filterValue === 'string' || Array.isArray(filterValue)) {
        return !!filterValue.length
      }

      if (isPlainObject(filterValue)) {
        return !!(filterValue.start || filterValue.end)
      }

      return false
    },
    [filter],
  )

  useEffect((): void => {
    if (cellRef.current) {
      if (dragStarted) {
        setForceWidth(cellRef.current.offsetWidth || cellRef.current.scrollWidth)
      } else {
        setForceWidth(null)
      }
    }
  }, [dragStarted, cellRef])

  useEffect((): void => {
    if (cellRef && cellRef.current && !initialWidth) {
      setInitialWidth(cellRef.current.offsetWidth || cellRef.current.scrollWidth)
    }
  }, [cellRef, initialWidth])

  return (
    <TableHead className={classes.thead}>
      <DragDropContext
        onBeforeDragStart={(): void => setDragStarted(true)}
        onDragEnd={handleDragEnd}
      >
        <Droppable droppableId="tableHeader" direction="horizontal">
          {(provided: DroppableProvided): React.ReactElement => (
            <TableRow
              className={classes.tableHeader}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {actionColumnTitle && (
                <TableCell className={classes.headAction}>{actionColumnTitle}</TableCell>
              )}
              {headers &&
                headers.every(x => x.name !== '') &&
                headers.map(({ label, name }, i) => (
                  <Draggable key={label} draggableId={`tableHeader${label}`} index={i}>
                    {(
                      { innerRef, draggableProps, dragHandleProps }: DraggableProvided,
                      { isDragging }: DraggableStateSnapshot,
                    ): React.ReactElement => {
                      return (isDragging ? createPortal : dummyFunction)(
                        <TableCell
                          key={label}
                          align="left"
                          className={clsx(
                            classes.tableCell,
                            {
                              [classes.withoutAction]: actionColumnTitle === ' ',
                            },
                            customClasses?.cell,
                          )}
                          {...draggableProps}
                          {...dragHandleProps}
                          ref={(ref): void => {
                            innerRef(ref as HTMLElement)
                            cellRef.current = ref as HTMLElement
                          }}
                          style={{
                            ...draggableProps.style,
                            ...(forceWidth
                              ? {
                                  width: forceWidth,
                                }
                              : {}),
                            ...(initialWidth
                              ? initialWidth <= cellMinWidth
                                ? { width: cellMinWidth }
                                : {}
                              : {}),
                          }}
                          sortDirection={orderBy === name ? order : false}
                        >
                          {label !== 'checkbox' && (
                            <TableSortLabel
                              active={orderBy === name && !!order}
                              direction={orderBy === name ? order : 'asc'}
                              hideSortIcon={
                                name === 'technical_events_count' || name === 'alarms_count'
                              }
                              onClick={
                                name === 'technical_events_count' || name === 'alarms_count'
                                  ? undefined
                                  : handleSort(name)
                              }
                            >
                              <Typography variant="h6" className={classes.title}>
                                {label}
                              </Typography>
                            </TableSortLabel>
                          )}

                          {filterColumns && filterColumns[name] && (
                            <>
                              {/* {idPrefix !== 'atmeye-devices-page' && ( */}
                              {/*  <IconButton */}
                              {/*    className={clsx({ */}
                              {/*      [classes.activeFilterIcon]: isActiveFilterIcon(name)(), */}
                              {/*    })} */}
                              {/*    size="small" */}
                              {/*    onClick={handleShowFilter(name)} */}
                              {/*  > */}
                              {/*    <Sort /> */}
                              {/*  </IconButton> */}
                              {/* )} */}
                              <FilterContextMenu
                                id={name}
                                isOpen={openedFilterName === name}
                                values={filterColumns[name]}
                                currentSelected={filter[name]}
                                anchorEl={anchorEl}
                                handleClose={handleCloseFilter}
                                handleSave={handleSetFilter}
                              />
                            </>
                          )}
                        </TableCell>,
                        document.body,
                      )
                    }}
                  </Draggable>
                ))}
            </TableRow>
          )}
        </Droppable>
      </DragDropContext>
    </TableHead>
  )
}

export default TableHeader
