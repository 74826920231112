import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles'

interface Props {
  chartIsVisible: boolean
}

export const useClasses = makeStyles<Theme, Props>(({ spacing, palette }) => ({
  wrapper: {
    padding: spacing(4),
    paddingTop: spacing(2),
    paddingBottom: 0,
    alignItems: 'flex-start',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerTitle: {
    fontSize: spacing(2.5),
    lineHeight: '23px',
  },
  headerControls: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerControlsLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  timerWrapper: {
    marginLeft: spacing(6),
    marginRight: 'auto',
  },
  tableSection: ({ chartIsVisible }: Props) => ({
    marginTop: chartIsVisible ? spacing(3) : 0,
  }),
  table: ({ chartIsVisible }: Props) => ({
    maxHeight: chartIsVisible ? 280 : 580,
  }),
  tableTitle: {
    fontSize: spacing(2.5),
    lineHeight: '23px',
    paddingBottom: spacing(2),
  },
  optionIcon: {
    width: '20px',
    height: '20px',
    fill: palette.grey[200],
  },
  headColumn: {
    //  justifyContent: 'center!important',
    background: '#f7f7f7',
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  headColumnCell: {
    justifyContent: 'center!important',
  },
  row: {
    '&:nth-child(odd)': {
      background: '#f9f9f9',
    },
  },
  tableCell: {
    border: '1px solid rgba(224, 224, 224, 1)',
    // textAlign: 'center',
  },
}))
