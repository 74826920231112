import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { FormikProps } from 'formik'
import { get } from 'lodash-es'
import React, { ReactElement, useEffect, useState } from 'react'

import { CommonApi } from '@/api/common/commonApi'
import Button from '@/components/controls/Button'
import { DateInput } from '@/components/controls/DateInput'
import LookupsList from '@/components/controls/LookupsList'
import LookupsSearch from '@/components/controls/LookupsSearch'
import { TextInput } from '@/components/controls/TextInput'
import { dateFormat } from '@/constants/adm/dateFormat'
import { uniqueRoles } from '@/constants/adm/roles'
import { hyphenNumbersAndLetters } from '@/constants/disabledSymbols'
import {
  CreateFormRelatedCompany,
  CreateRelatedCompaniesFormValues,
} from '@/types/adm/device/local'
import { RelatedCompaniesFieldName as FieldName } from '@/types/adm/device/names'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'

const RelatedCompanies = ({
  values,
  setFieldValue,
  ...props
}: FormikProps<CreateRelatedCompaniesFormValues>): ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const [rolesExist, setRolesExist] = useState<string[]>([])

  const handleAddCompany = (): void => {
    setFieldValue('companies', [
      ...values.companies,
      {
        companyRoleId: '',
        companyRoleName: '',
        companyId: '',
        companyName: '',
        luno: '',
        agreementNo: '',
        saFrom: '',
        saTo: '',
      } as CreateFormRelatedCompany,
    ])
  }

  const handleRemoveCompany = (index: number) => (): void => {
    setFieldValue(
      'companies',
      values.companies.filter((item, itemIndex) => itemIndex !== index),
    )
  }

  useEffect(() => {
    if (values.companies?.length) {
      const ec = values.companies
        .filter(company => uniqueRoles.some(id => id === company.companyRoleId))
        .map(company => String(company.companyRoleId))
      setRolesExist(ec)
    }
  }, [values.companies])

  return (
    <Grid>
      <Grid>
        <Typography variant="h6">{translate('translate#adm.relatedCompanies')}</Typography>
      </Grid>
      <Grid className={classes.subButton}>
        <Button variant="outlined" startIcon={<AddIcon />} width="xs" onClick={handleAddCompany}>
          <Typography variant="body2">{translate('translate#adm.addCompany')}</Typography>
        </Button>
      </Grid>
      <div className={classes.devider} />
      {values.companies.map((company, index) =>
        index > 0 ? (
          <Grid>
            <div className={classes.companyDevider} />
            <Grid className={classes.buttonWrapper}>
              <Button
                variant="outlined"
                startIcon={<DeleteIcon />}
                width="xs"
                palette="danger"
                onClick={handleRemoveCompany(index)}
              >
                <Typography variant="body2">{translate('translate#adm.remove')}</Typography>
              </Button>
            </Grid>
            <Grid className={classes.grid}>
              <Grid>
                <LookupsList
                  isDisabled={false}
                  defaultValue={{
                    value: values.companies[index].companyRoleId,
                    name: values.companies[index].companyRoleName,
                  }}
                  setFormikFieldValue={setFieldValue}
                  field={{
                    options: {
                      label: translate(`translate#adm.${FieldName.CompanyRole}`),
                      placeholder: translate(`translate#adm.${FieldName.CompanyRole}`),
                      required: true,
                      isNumber: false,
                    },
                  }}
                  fieldName={`companies[${index}][${FieldName.CompanyRoleId}]`}
                  fieldTitle={`companies[${index}][${FieldName.CompanyRoleName}]`}
                  isDependsOn={false}
                  onCall={async (
                    value: string,
                  ): Promise<{ item: unknown; value: string; name: string }[]> => {
                    const response = await CommonApi.getLookupsValues({
                      lookupKey: 'ADM_COMPANY_ROLE',
                      module: 'ADM',
                      fragment: value,
                      pageNo: 0,
                      pageSize: 100,
                    })
                    return response.values
                      .map(item => ({
                        item: item,
                        value: String(item.id),
                        name: item.name,
                      }))
                      .filter(r => !rolesExist.some(e => e === r.value))
                  }}
                />
              </Grid>
              <Grid>
                <LookupsSearch
                  isDisabled={false}
                  defaultValue={{
                    value: values.companies[index].companyId,
                    name: values.companies[index].companyName,
                  }}
                  setFormikFieldValue={setFieldValue}
                  field={{
                    options: {
                      label: translate(`translate#adm.${FieldName.Company}`),
                      placeholder: translate(`translate#adm.${FieldName.Company}`),
                    },
                  }}
                  fieldName={`companies[${index}][${FieldName.CompanyId}]`}
                  fieldTitle={`companies[${index}][${FieldName.CompanyName}]`}
                  isDependsOn
                  dependsOn={[values.companies[index].companyRoleId]}
                  onCall={async (
                    value: string,
                  ): Promise<{ item: any; value: string; name: string }[]> => {
                    const response = await CommonApi.getAdmCompanyByRoles({
                      module: 'ADM',
                      fragment: value,
                      pageNo: 0,
                      pageSize: 100,
                      roleIds: [values.companies[index].companyRoleId],
                    })
                    return response.values
                      .filter(item => !values.companies?.some(it => it.companyId === item.id))
                      .map(item => ({
                        item: item,
                        value: String(item.id),
                        name: item.name,
                      }))
                  }}
                />
              </Grid>
              <Grid>
                <TextInput
                  label={translate(`translate#adm.${FieldName.LUNO}`)}
                  name={`companies[${index}][${FieldName.LUNO}]`}
                  value={values.companies[index].luno}
                  onChange={props.handleChange}
                  fullWidth
                  disabledKeys={hyphenNumbersAndLetters}
                />
              </Grid>
            </Grid>
            <Grid className={classes.grid}>
              <Grid>
                <TextInput
                  label={translate(`translate#adm.${FieldName.AgreementNo}`)}
                  name={`companies[${index}][${FieldName.AgreementNo}]`}
                  value={values.companies[index].agreementNo}
                  onChange={props.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid>
                <DateInput
                  label={translate(`translate#adm.${FieldName.SaFrom}`)}
                  name={`companies[${index}][${FieldName.SaFrom}]`}
                  value={values.companies[index].saFrom}
                  onChange={(date): void => {
                    setFieldValue(
                      `companies[${index}][${FieldName.SaFrom}]`,
                      date.format(dateFormat),
                    )
                  }}
                />
              </Grid>
              <Grid>
                <DateInput
                  label={translate(`translate#adm.${FieldName.SaTo}`)}
                  name={`companies[${index}][${FieldName.SaTo}]`}
                  value={values.companies[index].saTo}
                  onChange={(date): void => {
                    setFieldValue(`companies[${index}][${FieldName.SaTo}]`, date.format(dateFormat))
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Grid className={classes.grid}>
              <Grid>
                <LookupsList
                  isDisabled
                  defaultValue={{
                    value: values.companies[index].companyRoleId,
                    name: translate(values.companies[index].companyRoleName),
                  }}
                  setFormikFieldValue={setFieldValue}
                  field={{
                    options: {
                      label: translate(`translate#adm.${FieldName.CompanyRole}`),
                      placeholder: translate(`translate#adm.${FieldName.CompanyRole}`),
                      required: true,
                      isNumber: false,
                    },
                  }}
                  fieldName={`companies[${index}][${FieldName.CompanyRoleId}]`}
                  fieldTitle={`companies[${index}][${FieldName.CompanyRoleName}]`}
                  isDependsOn={false}
                  onCall={async (
                    value: string,
                  ): Promise<{ item: unknown; value: string; name: string }[]> => {
                    const response = await CommonApi.getLookupsValues({
                      lookupKey: 'ADM_COMPANY_ROLE',
                      module: 'ADM',
                      fragment: value,
                      pageNo: 0,
                      pageSize: 100,
                    })
                    return response.values
                      .map(item => ({
                        item: item,
                        value: String(item.id),
                        name: item.name,
                      }))
                      .filter(r => !rolesExist.some(e => e === r.value))
                  }}
                />
              </Grid>
              <Grid>
                <LookupsSearch
                  isDisabled={false}
                  defaultValue={{
                    value: values.companies[index].companyId,
                    name: values.companies[index].companyName,
                  }}
                  setFormikFieldValue={setFieldValue}
                  field={{
                    options: {
                      label: translate(`translate#adm.${FieldName.Company}`),
                      placeholder: translate(`translate#adm.${FieldName.Company}`),
                    },
                  }}
                  fieldName={`companies[${index}][${FieldName.CompanyId}]`}
                  fieldTitle={`companies[${index}][${FieldName.CompanyName}]`}
                  isDependsOn
                  dependsOn={[values.companies[index].companyRoleId]}
                  onCall={async (
                    value: string,
                  ): Promise<{ item: any; value: string; name: string }[]> => {
                    const response = await CommonApi.getAdmCompanyByRoles({
                      module: 'ADM',
                      fragment: value,
                      pageNo: 0,
                      pageSize: 100,
                      roleIds: [values.companies[index].companyRoleId],
                    })
                    return response.values
                      .filter(item => !values.companies?.some(it => it.companyId === item.id))
                      .map(item => ({
                        item: item,
                        value: String(item.id),
                        name: item.name,
                      }))
                  }}
                />
              </Grid>
              <Grid>
                <TextInput
                  label={translate(`translate#adm.${FieldName.LUNO}`)}
                  name={`companies[${index}][${FieldName.LUNO}]`}
                  value={values.companies[index].luno}
                  onChange={props.handleChange}
                  fullWidth
                  disabledKeys={hyphenNumbersAndLetters}
                />
              </Grid>
            </Grid>
            <Grid className={classes.grid}>
              <Grid>
                <TextInput
                  label={translate(`translate#adm.${FieldName.AgreementNo}`)}
                  name={`companies[${index}][${FieldName.AgreementNo}]`}
                  value={values.companies[index].agreementNo}
                  onChange={props.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid>
                <DateInput
                  label={translate(`translate#adm.${FieldName.SaFrom}`)}
                  name={`companies[${index}][${FieldName.SaFrom}]`}
                  value={values.companies[index].saFrom}
                  onChange={(date): void => {
                    setFieldValue(
                      `companies[${index}][${FieldName.SaFrom}]`,
                      date.format(dateFormat),
                    )
                  }}
                />
              </Grid>
              <Grid>
                <DateInput
                  label={translate(`translate#adm.${FieldName.SaTo}`)}
                  name={`companies[${index}][${FieldName.SaTo}]`}
                  value={values.companies[index].saTo}
                  onChange={(date): void => {
                    setFieldValue(`companies[${index}][${FieldName.SaTo}]`, date.format(dateFormat))
                  }}
                  minDate={get(values, `companies[${index}][${FieldName.SaFrom}]`)}
                />
              </Grid>
            </Grid>
          </Grid>
        ),
      )}
    </Grid>
  )
}

export default RelatedCompanies
