import makeStyles from '@material-ui/core/styles/makeStyles'
import styled from 'styled-components'

import { styledComponentTheme } from '@/theme/styledComponentTheme'

export const useClasses = makeStyles(({ spacing }) => ({
  deviceTitle: {
    fontSize: '20px',
    marginBottom: spacing(2.5),
  },
  deviceSubTitle: {
    marginBottom: spacing(2.5),
  },
  deviceName: {
    color: '#929A9B',
  },
  deviceHeader: {
    marginBottom: spacing(1),
    fontWeight: 'bold',
    background: '#F8F8F8',
    padding: '10px 20px',
  },
  button: {
    fontWeight: 'normal',
    background: styledComponentTheme.colors.baseGreen,
    color: 'white',
    border: 0,
    marginLeft: spacing(2.5),
    marginBottom: 0,
    padding: '10px 20px',
    '&:hover': {
      background: styledComponentTheme.colors.baseGreen,
      color: 'white',
      border: 0,
    },
  },
  cancelButton: {
    fontWeight: 'normal',
    background: 'transparent',
    color: '#929A9B',
    border: '1px solid',
    marginLeft: spacing(2.5),
    marginBottom: 0,
    padding: '10px 20px',
    '&:hover': {
      background: 'transparent',
      color: '#929A9B',
      border: '1px solid',
    },
  },
  closeButton: {
    top: spacing(1),
    right: spacing(1),
    '& [class*="makeStyles-icon"]': {
      color: '#041517',
    },
  },

  loader: {
    top: 'auto',
    left: 'auto',
    position: 'unset',
    transform: 'none',
  },
}))

export const ContentWrapper = styled.div`
  background-color: #ffffff;
  padding: 30px;
`
