import queryString from 'query-string'

import { AdmCompany, AdmCompanyBase } from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { Sort } from '@/types/cm'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

export class CompaniesAdminApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getCompanies(sort: Sort): Promise<AdmCompany[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/companies',
          query: {
            ...sort,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )

      return await CompaniesAdminApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async addCompany(payload: AdmCompanyBase): Promise<void> {
    try {
      return await CompaniesAdminApi.requestService.post('/admin/companies/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async updateCompany(payload: AdmCompanyBase): Promise<void> {
    try {
      return await CompaniesAdminApi.requestService.put('/admin/companies/update', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async deleteCompany(id: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/companies/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await CompaniesAdminApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
