import makeStyles from '@material-ui/core/styles/makeStyles'
import colors from '@/theme/colors'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  input: {
    backgroundColor: 'white',
    margin: spacing(2, 0),
    '.Mui-error': {
      position: 'absolute',
    },
  },
  inputNumber: {
    '& input::-webkit-inner-spin-button': {
      display: 'none',
    },
    '& input::-webkit-outer-spin-button': {
      display: 'none',
    },
  },
  fontInput: {
    fontFamily: 'Noto Sans, sans-serif ',
  },
  noMargin: {
    margin: 0,
  },
  noMarginWithLabel: {
    margin: 0,
    marginTop: spacing(1),
  },
  inputWithError: {
    marginBottom: 0,
  },
  errorLabel: {
    height: spacing(2),
    fontSize: spacing(1.5),
    paddingLeft: spacing(2),
    color: palette.error.main,
  },
  labelDisabled: {
    display: 'inline',
    opacity: 0.6,
  },
  iconButton: {
    position: 'absolute',
    right: 9,
  },
  icon: {
    width: spacing(2.5),
    height: spacing(2.5),
  },
  hidden: {
    display: 'none',
  },
  fixedWidth: {
    width: '100%',
  },
  rangeNumberWrapper: {
    display: 'flex',
    width: '50%',
    alignItems: 'center',
  },
  range: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > span': {
      marginRight: spacing(2),
      whiteSpace: 'nowrap',
    },

    '& > div': {
      display: 'flex',
    },
  },
}))
