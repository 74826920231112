import { Dialog, Typography } from '@material-ui/core'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import React, { ReactElement, useEffect, useState } from 'react'

import { LogsInspectorApi } from '@/api/atmeye/logsInspector'
import CloseButton from '@/components/blocks/CloseButton'
import Loader from '@/components/blocks/Loader'
import Button from '@/components/controls/Button'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import { LogData, Props } from './types'

const TransactionLogModal = ({
  isOpen,
  deviceId,
  processNumber,
  localDate,
  onClose,
}: Props): ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  const [isLoading, setIsLoading] = useState(false)
  const [logsData, setLogsData] = useState<LogData[]>([])
  const [selectedLog, setSelectedLog] = useState(0)

  const getLogsData = async (payload: any) => {
    setIsLoading(true)
    const data = await LogsInspectorApi.getTransactionJournal(payload)

    setLogsData(data)
    setIsLoading(false)
  }

  useEffect(() => {
    isOpen && getLogsData({ deviceId, processNumber, localDate })
  }, [isOpen])

  const handleChangeSelectedLog = (isBack?: boolean): void => {
    const newIndex = isBack ? selectedLog - 1 : selectedLog + 1
    setSelectedLog(newIndex)
  }

  const { cardNumber, endTime, startTime, transactionText } = logsData[selectedLog] || {}

  return (
    <Dialog open={isOpen} maxWidth="sm" onClose={onClose}>
      <div className={classes.modalWrapper}>
        <CloseButton absolute className={classes.closeButton} onClick={onClose} />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className={classes.modalHeader}>
              <div className={classes.modalTitle}>
                {translate(`translate#title.transactionLogJournal.title`)}
              </div>

              {logsData?.length > 1 && (
                <div className={classes.buttonGroup}>
                  <Button
                    onClick={() => handleChangeSelectedLog(true)}
                    className={classes.arrowButton}
                    startIcon={<ArrowBackIos />}
                    disabled={selectedLog === 0}
                    activeButtonMark
                  />

                  <Button
                    onClick={() => handleChangeSelectedLog()}
                    className={classes.arrowButton}
                    disabled={selectedLog === logsData?.length - 1}
                    startIcon={<ArrowForwardIosIcon />}
                    activeButtonMark
                  />
                </div>
              )}
            </div>
            <div className={classes.wrapper}>
              <div className={classes.contentWrapper}>
                <div>
                  <div className={classes.content}>
                    <Typography className={classes.subTitles}>
                      {translate('translate#title.transactionLogJournal.cardNumber')}:{' '}
                    </Typography>
                    <Typography className={classes.text}>{cardNumber}</Typography>
                  </div>

                  <div className={classes.content}>
                    <Typography className={classes.subTitles}>
                      {translate('translate#title.transactionLogJournal.startTime')}:{' '}
                    </Typography>
                    <Typography className={classes.text}>{startTime}</Typography>
                  </div>

                  <div className={classes.content}>
                    <Typography className={classes.subTitles}>
                      {translate('translate#title.transactionLogJournal.endTime')}:{' '}
                    </Typography>
                    <Typography className={classes.text}>{endTime}</Typography>
                  </div>

                  <div className={classes.content}>
                    <Typography className={classes.subTitles}>
                      {translate('translate#title.transactionLogJournal.transactionText')}:{' '}
                    </Typography>
                    <Typography className={classes.text}>{transactionText}</Typography>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className={classes.modalFooter}>
          <Button onClick={onClose} variant="outlined" className={classes.buttonFooter}>
            {translate('translate#atmeye.title.close')}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

TransactionLogModal.displayName = 'TransactionLogModal'

export { TransactionLogModal }
