import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@material-ui/core'
import MaterialSelect from '@material-ui/core/Select'
import clsx from 'clsx'
import { FieldProps } from 'formik'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import Loader from '@/components/blocks/Loader'
import { SelectIcon } from '@/components/blocks/SelectIcon'
import Button from '@/components/controls/Button'
import SearchInput from '@/components/controls/SearchInput/component'
import { useTranslate } from '@/utils/internalization'

import ListElement from './components/ListElement'
import { useMultiSelect } from './hooks/useMultiSelect'
import { useClasses } from './styles'
import { Props } from './types'

const CHECKBOXES = {
  SELECT_ALL: 'all',
  DISPLAY_SELECTED: 'displaySelected',
}

export const MultipleSelect = ({
  dependsOnIds,
  isDisabled = false,
  required = false,
  shrink = true,
  error,
  SearchInputBlock = true,

  label,
  placeholder,
  fieldName,
  dropdownListEntityType,
  defaultValues,

  value = [],

  setFieldValue,
  onApply,
}: Props): ReactElement => {
  const {
    isDataLoading,
    isOpen,
    isDisplaySelected,
    isShowDisplaySelectedCheckbox,
    isAllItemsSelected,

    labelWidth,
    inputLabel,

    searchValue,

    options,
    filteredOptions,
    selectedIds,

    handleOpen,
    handleClose,
    handleApply,
    handleSetParentRef,
    handleSetChildRef,
    handleClickMenuItem,
    handleSearch,
    handleSelectAll,
    handleDisplaySelected,
  } = useMultiSelect({
    fieldName,
    dropdownListEntityType,
    defaultValues,
    selectedValues: value,
    dependsOnIds,
    setFieldValue,
    onApply,
  })

  const translate = useTranslate()
  const classes = useClasses()

  const renderSelectValues = useCallback(
    (selectValues: any): string => {
      if (!selectValues.length) {
        return placeholder || translate('translate#title.multi.select.placeholder')
      }

      const delimiter = ', '
      const labels = selectValues.reduce((acc: string, cur: string) => {
        const option = options.find(item => item.valueId === cur)

        const label = option?.valueLabels[0]

        if (!label) {
          return acc
        }

        const isFirstLabel = acc === ''

        if (isFirstLabel) {
          acc = label
        } else {
          acc = acc + delimiter + label
        }

        return acc
      }, '')

      return labels
    },
    [options, placeholder, translate],
  )

  const selectHeader = useMemo(
    () => (
      <Typography tabIndex={null! as undefined} className={classes.headerText} variant="h6">
        {label}
      </Typography>
    ),
    [classes.headerText, label],
  )

  const renderSearchBlock = useCallback(
    () =>
      SearchInputBlock && (
        <SearchInput disabled={false} indentation onChange={handleSearch} val={searchValue} />
      ),
    [SearchInputBlock, handleSearch, isDisplaySelected, searchValue],
  )

  const renderCheckboxesBlock = useCallback(
    () => (
      <div className={clsx(classes.checkboxesBlock)}>
        <div
          className={clsx(classes.checkboxWrapper)}
          key={CHECKBOXES.SELECT_ALL}
          onClick={handleSelectAll}
        >
          <Checkbox checked={isAllItemsSelected} />
          <FormattedMessage id="title.selectAll" defaultMessage="Select All" />
        </div>

        {isShowDisplaySelectedCheckbox && (
          <div
            className={clsx(classes.checkboxWrapper)}
            key={CHECKBOXES.DISPLAY_SELECTED}
            onClick={handleDisplaySelected}
          >
            <Checkbox checked={isDisplaySelected} />
            <FormattedMessage
              id="atmeye.device.displaySelected"
              defaultMessage="Display Selected"
            />
          </div>
        )}
      </div>
    ),
    [
      classes.checkboxWrapper,
      classes.checkboxesBlock,
      handleDisplaySelected,
      handleSelectAll,
      isAllItemsSelected,
      isDisplaySelected,
      isShowDisplaySelectedCheckbox,
    ],
  )

  const renderListElementName = useCallback(
    (labels: string[]): ReactElement | string => {
      const [primaryLabel = '', secondaryLabel = ''] = labels

      return (
        <>
          {primaryLabel}

          {secondaryLabel && (
            <>
              , &nbsp;
              <Typography className={classes.secondaryLabel}>{secondaryLabel}</Typography>
            </>
          )}
        </>
      )
    },
    [classes.secondaryLabel],
  )

  const renderMenuList = useCallback(
    () => (
      <div className={clsx(classes.menuList)} ref={handleSetParentRef}>
        {filteredOptions?.length ? (
          filteredOptions.map(({ valueId, valueLabels }, index) => {
            const isLastElement = index === filteredOptions.length - 1
            const innerRef = isLastElement ? handleSetChildRef : null
            const isChecked = Array.isArray(selectedIds) && selectedIds.includes(valueId)

            return (
              <ListElement
                isChecked={isChecked}
                key={valueId}
                value={valueId}
                innerRef={innerRef}
                classes={classes}
                name={renderListElementName(valueLabels)}
                onClick={handleClickMenuItem(valueId)}
              />
            )
          })
        ) : (
          <Typography variant="body1" className={classes.noOptions}>
            {translate('translate#title.noOptions')}
          </Typography>
        )}

        {isDataLoading && (
          <Box style={{ height: '40px' }}>
            <Loader />
          </Box>
        )}
      </div>
    ),
    [
      classes,
      filteredOptions,
      handleClickMenuItem,
      handleSetChildRef,
      handleSetParentRef,
      isDataLoading,
      renderListElementName,
      selectedIds,
      translate,
    ],
  )

  // TODO discuss error Material-UI: The Menu component doesn't accept a Fragment as a child.
  // TODO Consider providing an array instead.
  const renderSelectContent = useCallback(() => {
    // return [renderSearchBlock(), renderCheckboxesBlock(), <Divider />, renderMenuList()]
    return (
      <>
        {renderSearchBlock()}
        {renderCheckboxesBlock()}
        <Divider />
        {renderMenuList()}
      </>
    )
  }, [renderCheckboxesBlock, renderMenuList, renderSearchBlock])

  const renderSelectFooter = useCallback(
    () => (
      <div className={classes.selectFooter}>
        <Button
          className={clsx(classes.button, classes.cancelButton)}
          variant="contained"
          size="medium"
          onClick={handleClose}
        >
          <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
        </Button>

        <Button className={classes.button} variant="contained" size="medium" onClick={handleApply}>
          <FormattedMessage id="action.ok" defaultMessage="Ok" />
        </Button>
      </div>
    ),
    [classes.button, classes.cancelButton, classes.selectFooter, handleApply, handleClose],
  )

  const materialSelectClassName =
    placeholder && selectedIds.length === 0 ? classes.placeholderOption : classes.primaryLabel

  return (
    <FormControl
      error={error}
      size="small"
      required={required}
      className={clsx(classes.select, {
        [classes.disabled]: isDisabled,
      })}
      variant="outlined"
    >
      {label && (
        <InputLabel ref={inputLabel} shrink={shrink}>
          {label}
        </InputLabel>
      )}

      <MaterialSelect
        open={isOpen}
        displayEmpty
        IconComponent={SelectIcon}
        input={shrink ? <OutlinedInput labelWidth={labelWidth} notched={shrink} /> : undefined}
        value={selectedIds}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
        renderValue={renderSelectValues}
        onOpen={handleOpen}
        onClose={handleClose}
        className={materialSelectClassName}
      >
        {selectHeader}
        {renderSelectContent()}
        <Divider />
        {renderSelectFooter()}
      </MaterialSelect>
    </FormControl>
  )
}

export const createMultipleSelectAtmeye = (props: Props) => ({
  field,
  form: { errors, setFieldValue },
  meta: { touched },
}: FieldProps): React.ReactElement => {
  return (
    <MultipleSelect
      {...field}
      error={!!errors[field.name] && touched}
      setFieldValue={setFieldValue}
      {...props}
    />
  )
}
