import clsx from 'clsx'
import React, { FC, useEffect, useRef, useState } from 'react'

import Loader from '@/components/blocks/Loader'
import Button from '@/components/controls/Button'

import { useClasses } from './styles'
import { LoadingButtonProps } from './types'

export const LoadingButton: FC<LoadingButtonProps> = React.memo((props: LoadingButtonProps) => {
  const {
    name,
    isLoading = false,
    disabled = false,
    className,
    variant = 'contained',
    ...rest
  } = props

  const classes = useClasses()

  const buttonRef = useRef<HTMLButtonElement | null>(null)

  const [minWidth, setMinWidth] = useState<number | undefined>()

  useEffect(() => {
    if (buttonRef.current) {
      if (buttonRef.current.clientWidth > 64) {
        setMinWidth(buttonRef.current.clientWidth)
      }
    }
  }, [buttonRef.current])

  return (
    <div className={clsx({ [classes.disableBtnWrapper]: isLoading })}>
      <Button
        variant={variant}
        innerRef={buttonRef}
        disabled={disabled || isLoading}
        className={clsx(classes.button, className)}
        style={{ width: minWidth }}
        {...rest}
      >
        {!isLoading ? name : <Loader className={classes.loader} inline />}
      </Button>
    </div>
  )
})
