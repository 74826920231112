import { createSelector } from 'reselect'
import { AppState } from '@/store/reducers'
import * as fromReducer from './reducer'

export const getReplenishmentsState = (state: AppState): fromReducer.State => state.cmReplenishments

export const getIcsOrders = createSelector(
  getReplenishmentsState,
  (state: fromReducer.State) => state.orders,
)

export const getIcsSelectedOrder = createSelector(getIcsOrders, state => state.selectedOrder)

export const getIcsModalOpenState = createSelector(
  getReplenishmentsState,
  ({
    isFiltersModalOpen,
    isDocumentModalOpen,
    isSettingsModalOpen,
    isDeclineModalOpen,
    isChangeReplenTypeModalOpen,
  }: fromReducer.State) => ({
    isFiltersModalOpen,
    isDocumentModalOpen,
    isSettingsModalOpen,
    isDeclineModalOpen,
    isChangeReplenTypeModalOpen,
  }),
)

export const getIcsStages = createSelector(getIcsOrders, state => state.stages)

export const getIcsSelectedOrderStages = createSelector(
  getIcsOrders,
  state => state.selectedOrderStages,
)
export const getIcsStageInfo = createSelector(getIcsSelectedOrderStages, state => state.stagesInfo)
