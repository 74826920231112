export enum TeamsActions {
  CreateTeamRequest = '@/CREATE_TEAM_REQUEST',
  EditTeamRequest = '@/EDIT_TEAM_REQUEST',
  GetCompaniesRequest = '@/GET_COMPANIES_REQUEST',
  GetCompaniesResponse = '@/GET_COMPANIES_RESPONSE',
  GetRolesRequest = '@/GET_ROLES_REQUEST',
  GetRolesResponse = '@/GET_ROLES_RESPONSE',
  GetTeamRequest = '@/GET_TEAM_REQUEST',
  GetTeamResponse = '@/GET_TEAM_RESPONSE',
  GetTeamsRequest = '@/GET_VAULT_TEAMS_REQUEST',
  GetTeamsResponse = '@/GET_VAULT_TEAMS_RESPONSE',
  GetTeamsResponseFail = '@/GET_VAULT_TEAMS_FAIL',
  GetUsersForTeamRequest = '@/GET_USERS_FOR_TEAM_REQUEST',
  GetUsersForTeamResponse = '@/GET_USERS_FOR_TEAM_RESPONSE',
  GetVehiclesRequest = '@/GET_VEHICLES_REQUEST',
  GetVehiclesResponse = '@/GET_VEHICLES_RESPONSE',
  SetSelectedTeam = '@/SET_SELECTED_TEAM',
}
