import AdditionalCostsIcon from '@/assets/srm/additional_costs.svg'
import CreateGroupOfServiceRequestsIcon from '@/assets/srm/create_group_of_service_requests.svg'
import CriticTermsIcon from '@/assets/srm/critic_terms.svg'
import DepartmentWorksIcon from '@/assets/srm/department_works.svg'
import DirectSelectIcon from '@/assets/srm/direct_select.svg'
import ImportDevicesIcon from '@/assets/srm/import_devices.svg'
import LogIcon from '@/assets/srm/log.svg'
import NotifyServiceIcon from '@/assets/srm/notify_service.svg'
import RepairNormsIcon from '@/assets/srm/repair_norms.svg'
import SearchMapIcon from '@/assets/srm/search_map.svg'
import SearchServiceIcon from '@/assets/srm/search_service.svg'
import WorkScheduleIconIcon from '@/assets/srm/work_schedule.svg'
import { AppNavigationRoutes, SRM_BLOCK_PREFIX } from '@/constants'

import SRMSelectItems from './SRMSelectItems'

export default [
  {
    path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.FiscalRegistration}`,
    label: 'translate#title.fiscalRegistration',
    activeFor: [`${SRM_BLOCK_PREFIX}${AppNavigationRoutes.FiscalRegistration}`],
    Icon: CriticTermsIcon,
    subItems: [],
    innerPages: [],
    allowedPermissions: ['SRVBS2GeFiscalCashRegistration'],
  },
  {
    path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.FiscalActionHistory}`,
    label: 'translate#title.fiscalActionHistory',
    activeFor: [`${SRM_BLOCK_PREFIX}${AppNavigationRoutes.FiscalActionHistory}`],
    Icon: CriticTermsIcon,
    subItems: [],
    innerPages: [],
    allowedPermissions: ['SRVBS2GeFiscalCashRegistrationHistory'],
  },
  // {
  //   path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.SearchServicePage}`,
  //   label: 'translate#title.searchServiceRequests',
  //   activeFor: [`${SRM_BLOCK_PREFIX}${AppNavigationRoutes.SearchServicePage}`],
  //   Icon: SearchServiceIcon,
  //   subItems: SRMSelectItems,
  // },
  // {
  //   path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.MapPage}`,
  //   label: 'translate#title.map',
  //   activeFor: [`${SRM_BLOCK_PREFIX}${AppNavigationRoutes.MapPage}`],
  //   innerPages: [
  //     {
  //       path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.MapDetailsPage}`,
  //       label: 'translate#title.details',
  //     },
  //   ],
  //   Icon: SearchMapIcon,
  // },
  // {
  //   path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.DepartmentPage}`,
  //   label: 'translate#title.myDepartmentWorks',
  //   activeFor: [`${SRM_BLOCK_PREFIX}${AppNavigationRoutes.DepartmentPage}`],
  //   Icon: DepartmentWorksIcon,
  // },
  // {
  //   path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.GroupRequests}`,
  //   label: 'translate#title.createGroupOfServiceRequests',
  //   activeFor: [`${SRM_BLOCK_PREFIX}${AppNavigationRoutes.GroupRequests}`],
  //   Icon: CreateGroupOfServiceRequestsIcon,
  // },
  // {
  //   path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.RepairNormsPage}`,
  //   label: 'translate#title.repairNorms',
  //   activeFor: [`${SRM_BLOCK_PREFIX}${AppNavigationRoutes.RepairNormsPage}`],
  //   Icon: RepairNormsIcon,
  // },
  // {
  //   path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.ImportDevicesPage}`,
  //   label: 'translate#title.importDevices',
  //   activeFor: [`${SRM_BLOCK_PREFIX}${AppNavigationRoutes.ImportDevicesPage}`],
  //   Icon: ImportDevicesIcon,
  // },
  // {
  //   path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.CriticTerms}`,
  //   label: 'translate#title.criticTerms',
  //   activeFor: [`${SRM_BLOCK_PREFIX}${AppNavigationRoutes.CriticTerms}`],
  //   Icon: CriticTermsIcon,
  // },
  // {
  //   path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.NotifyService}`,
  //   label: 'translate#title.notifyServiceRequestsEvent',
  //   activeFor: [`${SRM_BLOCK_PREFIX}${AppNavigationRoutes.NotifyService}`],
  //   Icon: NotifyServiceIcon,
  // },
  // {
  //   path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.SelectScriptPage}`,
  //   label: 'translate#title.directSelectScript',
  //   activeFor: [`${SRM_BLOCK_PREFIX}${AppNavigationRoutes.SelectScriptPage}`],
  //   Icon: DirectSelectIcon,
  // },
  // {
  //   path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.Log}`,
  //   label: 'translate#title.log',
  //   activeFor: [`${SRM_BLOCK_PREFIX}${AppNavigationRoutes.Log}`],
  //   Icon: LogIcon,
  // },
  // {
  //   path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.WorkSchedule}`,
  //   label: 'translate#title.workSchedule',
  //   activeFor: [`${SRM_BLOCK_PREFIX}${AppNavigationRoutes.WorkSchedule}`],
  //   Icon: WorkScheduleIconIcon,
  // },
  // {
  //   path: `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.additionalCosts}`,
  //   label: 'translate#title.additionalCosts',
  //   activeFor: [`${SRM_BLOCK_PREFIX}${AppNavigationRoutes.additionalCosts}`],
  //   Icon: AdditionalCostsIcon,
  // },
]
