interface GetTicketsBodyFilterItem {
  parameterName: string
  parameterValue: Array<{
    id: number | null
    value: string | null
    name?: string
  }> | null
  rangeValues: {
    from: number | null
    to: number | null
  } | null
  valueType: string
}

export type AvailableModule = {
  moduleType: string
  expirationDate: string
}

export enum AtmeyeModules {
  ATMEYE_CORE_SOFTWARE_LICENSE = 'ATMEYE_CORE_SOFTWARE_LICENSE',
  REPORTS = 'REPORTS',
  RFM = 'RFM',

  DEVICES = 'DEVICES',
  STATUSES = 'STATUSES',
  CAMERAS = 'CAMERAS',
  TRANSACTIONS = 'TRANSACTIONS',
  SECURITY_ALARMS = 'SECURITY_ALARMS',
  TECHNICAL_EVENTS = 'TECHNICAL_EVENTS',
  REMOTE_SCREEN_MONITORING = 'REMOTE_SCREEN_MONITORING',
  LOG_INSPECTOR = 'LOG_INSPECTOR',
  TRANSACTION_LOG_VIEW = 'TRANSACTION_LOG_VIEW',
  EXTERNAL_NOTIFICATIONS = 'EXTERNAL_NOTIFICATIONS',
}

export interface GetTicketsBody {
  formUID: string
  filter: GetTicketsBodyFilterItem[]
  pageNo: number
  pageSize: number
  sortDetails: Array<{
    fieldName: string
    sortOrder: 'ASC' | 'DESC'
  }> | null
}

interface GetGroupDetailsParentGroupItem {
  groupUID: string
  groupValue: string
  valueType: string
}
export interface GetGroupDetailsBody {
  formUID: string
  getOutDetailsURL: string
  filter: GetTicketsBodyFilterItem[]
  parentGroups: GetGroupDetailsParentGroupItem[]
  pageNo: number
  pageSize: number
  sortDetails: Array<{
    fieldName: string
    sortOrder: 'ASC' | 'DESC'
  }> | null
}
type appliedFiltersTypes = {
  filterLabel: string
  filterValue: string
}

export interface GetExportBody {
  formUID: string
  getOutTreeURL: string
  filter: GetTicketsBodyFilterItem[]
  outputFields: Array<string>
  format: string
  sortDetails: Array<{
    fieldName: string
    sortOrder: 'ASC' | 'DESC'
  }> | null
  appliedFilters?: Array<appliedFiltersTypes>
}

interface GetTicketsBodyGroupItem {
  displayValueType: string
  groupLevelNo: number
  groupUID: string
  parentGroupUID: string[]
  sortBy: string
  sortOrder: string
  value: string
  valueType: string
}

export interface GetExportGroupedBody {
  formUID: string
  getOutTreeURL: string
  filter: GetTicketsBodyFilterItem[]
  outputFields: Array<string>
  format: string
  groupBy: GetTicketsBodyGroupItem[]
}

export interface GroupTemplate {
  windowType: string
  name: string

  groupingLevels: Array<{
    groupingKey: string
    orderBy: string
    orderType: string
  }>
}
