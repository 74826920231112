import React from 'react'
import { Props } from './types'

const Label = (props: Props): React.ReactElement => {
  const RADIAN = Math.PI / 180
  const { cx, cy, midAngle, outerRadius, value, totalCount } = props

  if (!midAngle) {
    return <></>
  }

  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)

  const sx = Number(cx) + Number(outerRadius) * cos
  const sy = Number(cy) + Number(outerRadius) * sin
  const mx = Number(cx) + (Number(outerRadius) + 30) * cos
  const my = Number(cy) + (Number(outerRadius) + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 20
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <text x={cx} y={cy} textAnchor="middle" dominantBaseline="middle" fontSize="22px">
        {totalCount}
      </text>
      <circle cx={sx} cy={sy} r={4} stroke="#EBEBEB" strokeWidth="2" fill="none" />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke="#EBEBEB" fill="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 2} y={ey + 4} textAnchor={textAnchor} fill="#333">
        {value}
      </text>
    </g>
  )
}

export default Label
