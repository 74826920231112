import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ palette, spacing }) => ({
  terminalWrapper: {
    display: 'flex',
  },
  input: {
    marginBottom: spacing(1),
    width: '40%',
  },
  textField: {
    width: '100%',
  },
  deleteButton: {
    padding: spacing(0.5),
    color: palette.error.main,
    backgroundColor: palette.error.light,
    '&:hover': {
      backgroundColor: palette.error.light,
    },
  },
  hidden: {
    visibility: 'hidden',
    marginLeft: spacing(1),
  },
}))
