export enum RepairNormsActions {
  SetRepairNormsData = '@/SET_REPAIR_NORMS_DATA',
  UpdateRepairNormDate = '@/UPDATE_REPAIR_NORM_DATE',
  DeleteRepairNorm = '@/DELETE_REPAIR_NORM_DATE',

  ShowSetDeviceTypeModal = '@/SHOW_SET_DEVICE_TYPE_MODAL',
  ShowSpecifyProblemAreaModal = '@/SHOW_SPECIFY_PROBLEM_AREA_MODAL',
  ShowSelectDefectOrWorkModal = '@/SHOW_SELECT_DEFECT_OR_WORK_MODAL',
  ShowQualificationLevelsAndTimeNormsModal = '@/SHOW_QUALIFICATION_LEVELS_AND_TIME_NORMS_MODAL',

  AddNewElementData = '@/ADD_NEW_ELEMENT_DATA',
  ClearNewElementData = '@/CLEAR_NEW_ELEMENT_DATA',

  AddNewElement = '@/ADD_NEW_ELEMENT',
}
