import Pagination from '@material-ui/lab/Pagination'
import React, { useCallback } from 'react'

import { Props } from './types'

const TablePaginationActions = ({
  count,
  page,
  rowsPerPage,
  onChangePage,
}: Props): React.ReactElement => {
  const handlerChangePage = useCallback(
    (e: unknown, page: number) => {
      // onChangePage(e, page)
    },
    [page],
  )

  return <Pagination count={Math.ceil(count / rowsPerPage)} onChange={handlerChangePage} />
}

export default TablePaginationActions
