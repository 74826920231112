export interface QualificationAndTimeNorm {
  id: string
  qualificationLevel: string
  timeNorm: string
}

export enum TableColumn {
  QualificationLevel = 'qualificationLevel',
  TimeNorm = 'timeNorm',
}
