import React from 'react'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'
import { Props } from './types'
import { dummyFunction } from '@/utils/functions'
import { useClasses } from './styles'

const DraggableBookmark = ({
  path,
  label,
  type,
  className,
  draggingClassName,
  style,
  draggingStyle,
  children,
  disabled = false,
}: Props): React.ReactElement => {
  const classes = useClasses()

  return !disabled ? (
    <Droppable
      isDropDisabled
      direction="horizontal"
      droppableId={`singleItem${type}${path}${label}`}
    >
      {(
        provided: DroppableProvided,
        { isUsingPlaceholder }: DroppableStateSnapshot,
      ): React.ReactElement => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          <Draggable key={path} draggableId={`${type}${JSON.stringify({ path, label })}`} index={0}>
            {(
              { innerRef, draggableProps, dragHandleProps }: DraggableProvided,
              { isDragging }: DraggableStateSnapshot,
            ): React.ReactElement => {
              // Portal active elements above everything
              return (isDragging ? createPortal : dummyFunction)(
                <div
                  ref={innerRef}
                  {...draggableProps}
                  {...dragHandleProps}
                  style={{
                    ...draggableProps.style,
                    ...(isDragging ? draggingStyle : style),
                    ...(isDragging ? { maxWidth: 'max-content' } : {}),
                  }}
                  className={isDragging ? draggingClassName : className}
                >
                  {children}
                </div>,
                document.body,
              )
            }}
          </Draggable>
          {isUsingPlaceholder && (
            <div style={style} className={className}>
              {children}
            </div>
          )}
          {isUsingPlaceholder &&
            createPortal(<div className={classes.hoverKeeper} />, document.body)}
        </div>
      )}
    </Droppable>
  ) : (
    <div style={style} className={className}>
      {children}
    </div>
  )
}

export default DraggableBookmark
