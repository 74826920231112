import queryString from 'query-string'

import { AdmCitEmployee } from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

interface GetEmployeesProps extends Sort {
  id: string
}

export class EmployeesApi {
  private static requestService = getRequestServiceCM()

  public static async get(payload: GetEmployeesProps): Promise<AdmCitEmployee[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/planning/employees',
          query: { ...payload },
        },
        { skipNull: true, encode: false },
      )

      return await EmployeesApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(employee: AdmCitEmployee): Promise<void> {
    try {
      return await EmployeesApi.requestService.put('/admin/planning/employees/update', employee)
    } catch (error) {
      throw Error(error)
    }
  }
}
