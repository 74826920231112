import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { VaultAnalysisApi } from '@/api/vault-v1/analysisApi/index'
import { VaultV1AnalysisActions } from '@/constants'
import { AppState } from '@/store/reducers'

import { setGlobalFilter } from '../common'
import {
  getAnalysisChartsFail,
  getAnalysisChartsRequest,
  getAnalysisChartsResponse,
  setAnalysisFilter,
} from './actions'

function* handleFilterSubmit({ payload }: AnyAction): SagaIterator {
  yield put(setGlobalFilter(payload.globalFilter))
  yield put(setAnalysisFilter(payload.analysisFilter))

  yield put(getAnalysisChartsRequest())
}

function* getCharts(): SagaIterator {
  try {
    const {
      vaultV1Analysis: { filter },
      vaultV1Common: { globalFilter },
    } = yield select((state: AppState) => state)

    const totalFilter = {
      globalFilter,
      filter,
    }

    const response = yield call(VaultAnalysisApi.getCharts, totalFilter)

    yield put(getAnalysisChartsResponse(response))
  } catch (e) {
    yield put(getAnalysisChartsFail())
  }
}
export default function*(): Generator {
  yield takeLatest(VaultV1AnalysisActions.AnalysisFilterSubmitRequest, handleFilterSubmit)
  yield takeLatest(VaultV1AnalysisActions.AnalysisGetChartsRequest, getCharts)
}
