import { createSelectField } from '@/components/controls/Select/component'

interface Args {
  label: string
  placeholder: string
  disabled?: boolean
  options: {
    name: string
    value: string
  }[]
  required?: boolean
  parameterName?: string
  className?: string
  hideEmptyItem?: boolean
}

export const SelectUSM = ({
  label,
  placeholder,
  disabled = false,
  options,
  required = false,
  parameterName = '',
  hideEmptyItem = false,
}: Args) =>
  createSelectField({
    disabled,
    required,
    label,
    fullWidth: true,
    shrink: true,
    options,
    placeholder,
    parameterName,
    hideEmptyItem,
  })
