import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { CMCommonApi } from '@/api/cm/commonApi'
import { ReplenishmentsApi } from '@/api/cm/replenishmentsApi'
import { SharedApi } from '@/api/cm/sharedApi'
import { IcsOrder } from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants'
import { getIcsOrders } from '@/store/cm/replenishments'
import { AppState } from '@/store/reducers'
import { hotKeysBinder } from '@/utils/hotKeysBinder'

import { setComCMError, setGlobalFilter } from '../common'
import {
  assignIcsCollectorResponseFail,
  changeIcsReplenTypeResponseFail,
  checkIcsAllOrders,
  clearIcsAllOrders,
  declineIcsOrdersResponseFail,
  deleteIcsLastDocumentResponse,
  deleteIcsLastDocumentResponseFail,
  getIcsOrdersRequest,
  getIcsOrdersResponse,
  getIcsOrdersResponseFail,
  getIcsOrderStagesRequest,
  getIcsOrderStagesResponse,
  getIcsOrderStagesResponseFail,
  getIcsReportResponse,
  getIcsReportResponseFail,
  getIcsReportsResponse,
  getIcsReportsResponseFail,
  getIcsStagesDocRequest,
  getIcsStagesDocResponse,
  refreshIcsOrderRequest,
  refreshIcsOrderResponse,
  refreshIcsOrderResponseFail,
  refreshReplenishments,
  resetIcsCashPointsPage,
  setIcsDocumentModalOpen,
  setIcsFiltersModalOpen,
  setIcsOrdersFilter,
  setIcsOrdersPagingResponse,
  setIcsOrderStageResponse,
  setIcsOrderStageResponseFail,
  setIcsSelectedOrder,
  toggleIcsOrder,
} from './actions'

function* getOrders(): SagaIterator {
  try {
    yield select((state: AppState) => state.cmReplenishments)
    const paging = yield select((state: AppState) => {
      const { page, pageSize } = state.cmReplenishments.orders.paging
      return {
        page,
        pageSize: pageSize === -1 ? 'All' : pageSize,
      }
    })
    const sort = yield select((state: AppState) => ({
      sortColumn: state.cmReplenishments.orders.sortColumn,
      sortOrder: state.cmReplenishments.orders.sortOrder,
    }))
    const storeFilter = yield select((state: AppState) => ({
      globalFilter: state?.cmCommon?.globalFilter,
      filter: state?.cmReplenishments?.orders?.filter,
    }))
    const { globalFilter, filter } = storeFilter
    const response = yield call(
      ReplenishmentsApi.getOrders,
      paging,
      { globalFilter, filter },
      sort.sortColumn,
      sort.sortOrder,
    )

    const { selectedOrder, checkedOrders } = yield select(getIcsOrders)

    if (response) {
      if (selectedOrder) {
        const updatedSelectedOrder = response.data.find(
          (cashPoint: IcsOrder) => cashPoint.cpId === selectedOrder.cpId,
        )

        if (updatedSelectedOrder) {
          yield put(setIcsSelectedOrder(updatedSelectedOrder))

          yield put(getIcsStagesDocRequest(updatedSelectedOrder.id))
          yield put(getIcsOrderStagesRequest(updatedSelectedOrder.id))
        } else {
          yield put(setIcsSelectedOrder(null))
        }
      }
      if (Object.keys(checkedOrders).length) {
        const newCheckedOrders = {} as { [key: string]: IcsOrder }

        Object.keys(checkedOrders).map(key => {
          const elementInResponse = response.data.find(
            (cashPoint: IcsOrder) => cashPoint.cpId === checkedOrders[key].cpId,
          )
          if (elementInResponse) {
            newCheckedOrders[elementInResponse.id] = elementInResponse
          }
        })

        yield put(
          checkIcsAllOrders(Object.keys(newCheckedOrders).map(key => newCheckedOrders[key])),
        )
      }
    }

    yield put(getIcsOrdersResponse(response))
  } catch (error) {
    yield put(getIcsOrdersResponseFail(error))
  }
}

function* handleOrdersPagingChange({ payload }: AnyAction): SagaIterator {
  yield put(setIcsOrdersPagingResponse(payload))
  yield put(getIcsOrdersRequest())
}

function* setFilters({ payload }: AnyAction): any {
  try {
    yield put(setGlobalFilter(payload.globalFilter))
    yield put(setIcsOrdersFilter(payload.icsFilter))
  } catch (e) {}
}

function* handleFiltersSubmit(action: AnyAction): SagaIterator {
  yield setFilters(action)
  yield put(resetIcsCashPointsPage())
  yield put(setIcsSelectedOrder(null))
  yield put(clearIcsAllOrders())
  yield put(getIcsOrdersRequest())
}

function* getReports(): SagaIterator {
  try {
    const {
      cmCommon: { globalFilter },
      cmReplenishments: {
        orders: { checkedOrders, selectedOrder, filter: ordersFilter },
      },
    } = yield select((state: AppState) => state)
    const ids = Object.keys(checkedOrders) || []
    const response = yield call(ReplenishmentsApi.getReports, {
      globalFilter,
      ordersFilter,
      ids,
      selectedId: selectedOrder?.id || null,
    })
    yield put(getIcsReportsResponse(response))
  } catch (e) {
    yield put(getIcsReportsResponseFail(e?.message))
  }
}

function* getReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(CMCommonApi.getReport, payload)
      yield put(getIcsReportResponse(response))
    } else {
      yield put(getIcsReportResponse(null))
    }
  } catch (e) {
    yield put(getIcsReportResponseFail(e?.message))
  }
}

function* refreshOrder({ payload }: AnyAction): SagaIterator {
  try {
    const { id, withRefreshStages, withRefreshDocs } = payload || {}
    const { selectedOrder } = yield select(getIcsOrders)

    const order = yield call(ReplenishmentsApi.getOrder, id || selectedOrder.id)

    yield put(refreshIcsOrderResponse(order))

    if (withRefreshDocs) {
      yield put(getIcsStagesDocRequest(order.id))
    }

    if (withRefreshStages) {
      yield put(getIcsOrderStagesRequest(order.id))
    }
  } catch (e) {
    yield put(refreshIcsOrderResponseFail(e))
  }
}

function* getIcsStagesDoc({ id }: AnyAction): SagaIterator {
  try {
    const orderStagesDoc = yield call(ReplenishmentsApi.getIcsStagesDoc, id)

    yield put(getIcsStagesDocResponse(orderStagesDoc.data))
  } catch (e) {
    yield put(setComCMError(e))
  }
}
function* declineOrders({ payload }: AnyAction): SagaIterator {
  try {
    yield call(SharedApi.declineOrder, { ...payload })

    yield put(clearIcsAllOrders())
    const { ids } = payload
    for (const id of ids) {
      yield put(refreshIcsOrderRequest(id))
    }
  } catch (error) {
    yield put(declineIcsOrdersResponseFail(error))
  }
}

function* changeReplenType({ payload }: AnyAction): SagaIterator {
  try {
    const { orderIds } = payload
    const { selectedOrder } = yield select(getIcsOrders)

    yield call(ReplenishmentsApi.changeReplenishmentType, payload)

    for (const id of orderIds) {
      const isRefreshStageAndDocs = selectedOrder?.id === id

      yield put(refreshIcsOrderRequest(id, isRefreshStageAndDocs, isRefreshStageAndDocs))
    }
  } catch (error) {
    yield put(changeIcsReplenTypeResponseFail(error))
  }
}

function* assignCollector({ payload }: AnyAction): SagaIterator {
  try {
    yield call(ReplenishmentsApi.assignCollector, payload)

    yield put(clearIcsAllOrders())
    const { ids } = payload
    for (const id of ids) {
      yield put(refreshIcsOrderRequest(id))
    }
  } catch (error) {
    yield put(assignIcsCollectorResponseFail(error))
  }
}

function* getOrderStages({ payload }: AnyAction): SagaIterator {
  try {
    const response = yield call(ReplenishmentsApi.getStages, payload)
    yield put(getIcsOrderStagesResponse(response))
  } catch (error) {
    yield put(getIcsOrderStagesResponseFail(error))
  }
}

function* setOrderStage({ payload }: AnyAction): SagaIterator {
  try {
    const response = yield call(ReplenishmentsApi.setStage, payload)
    yield put(setIcsOrderStageResponse(response))
  } catch (error) {
    yield put(setIcsOrderStageResponseFail(error))
  }
}

function* deleteLastDocument({ payload }: AnyAction): SagaIterator {
  try {
    const response = yield call(ReplenishmentsApi.deleteLastDocument, payload)
    yield put(deleteIcsLastDocumentResponse(response))
  } catch (error) {
    yield put(deleteIcsLastDocumentResponseFail(error))
  }
}

function* handleOrderStageChange(): SagaIterator {
  const { id } = yield select((state: AppState) => state?.cmReplenishments?.orders?.selectedOrder)
  if (id) {
    yield put(refreshIcsOrderRequest(id, false))
  }
}

function* handleKeyPress({ payload }: AnyAction): SagaIterator {
  try {
    const {
      isFiltersModalOpen,
      isSettingsModalOpen,
      isDocumentModalOpen,
      isDeclineModalOpen,
      isChangeReplenTypeModalOpen,
      orders: { selectedOrder: selected, data },
    } = yield select((state: AppState) => state.cmReplenishments)
    const {
      isEditCassettesModalOpen,
      declineNoteState: { isModalOpen: isDeclineNoteModalOpen },
    } = yield select((state: AppState) => state.cmShared)

    hotKeysBinder<IcsOrder>({
      key: payload,
      data,
      selected,
      isOpenModalsList: [
        isFiltersModalOpen,
        isSettingsModalOpen,
        isDocumentModalOpen,
        isDeclineModalOpen,
        isChangeReplenTypeModalOpen,
        isEditCassettesModalOpen,
        isDeclineNoteModalOpen,
      ],
      setSelectedAction: setIcsSelectedOrder,
      onToggleAction: toggleIcsOrder,
      openFilterAction: setIcsFiltersModalOpen,
      openPrintAction: setIcsDocumentModalOpen,
      onRefreshAction: refreshReplenishments,
    })
  } catch (error) {
    yield put(setComCMError(error))
  }
}

export default function*(): Generator {
  yield takeLatest(
    [CMActions.IcsGetOrdersRequest, CMActions.IcsSortOrdersTable, CMActions.IcsRefreshOrdersPanel],
    getOrders,
  )
  yield takeLatest(CMActions.IcsSetOrdersPagingRequest, handleOrdersPagingChange)
  yield takeLatest(CMActions.IcsFiltersSubmitRequest, handleFiltersSubmit)
  yield takeLatest(CMActions.IcsGetReportsRequest, getReports)
  yield takeLatest(CMActions.IcsGetReportRequest, getReport)
  yield takeEvery(
    [CMActions.IcsRefreshOrderRequest, CMActions.IcsApplyMissedReplenishmentEventResponse],
    refreshOrder,
  )
  yield takeLatest(CMActions.IcsGetStagesDocRequest, getIcsStagesDoc)
  yield takeLatest(CMActions.IcsDeclineOrdersRequest, declineOrders)
  yield takeLatest(CMActions.IcsChangeReplenTypeRequest, changeReplenType)
  yield takeLatest(CMActions.IcsAssignCollectorRequest, assignCollector)
  yield takeLatest(
    [CMActions.IcsSetOrderStageResponse, CMActions.IcsDeleteLastDocumentResponse],
    handleOrderStageChange,
  )
  yield takeLatest(CMActions.IcsGetOrderStagesRequest, getOrderStages)
  yield takeLatest(CMActions.IcsSetOrderStageRequest, setOrderStage)
  yield takeLatest(CMActions.IcsDeleteLastDocumentRequest, deleteLastDocument)

  yield takeLatest(CMActions.IcsHandleKeyPress, handleKeyPress)
}
