import { red } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles(() => ({
  timeInput: {
    '& .MuiInput-underline:before': {
      content: 'none',
    },
    '& .MuiInput-underline:after': {
      content: 'none',
    },
  },
  errorTimeInput: {
    '& input': {
      color: 'red',
    },
  },
}))
