import React, { useCallback, useState } from 'react'

export const useSelectUsers = () => {
  const [user, setUsers] = useState('')

  // const handleChange = useCallback(
  //   (e: React.ChangeEvent<{ name?: string; value: any }>): void => {
  //     setUsers(e.target.value)
  //   },
  //   [user],
  // )

  const handleChange = useCallback(
    (value: string) => {
      setUsers(value)
    },
    [user],
  )

  return { user, handleChange, setUsers }
}
