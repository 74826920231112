import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(() => ({
  wrapper: {
    width: '100%',
  },
  paper: {
    boxShadow: 'none',
  },
}))
