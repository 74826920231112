import { AnyAction } from 'redux'

import { AdmRoundingSetting } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants/actions/cmActions'

// get
export const getAdmRoundingSettingRequest = (): AnyAction => ({
  type: CMAppConfigActions.AdmGetRoundingSettingsRequest,
})
export const getAdmRoundingSettingResponse = (response: AdmRoundingSetting): AnyAction => ({
  type: CMAppConfigActions.AdmGetRoundingSettingsResponse,
  payload: response,
})
export const getAdmRoundingSettingFail = (error: string | unknown): AnyAction => ({
  type: CMAppConfigActions.AdmGetRoundingSettingsFail,
  payload: error,
})

// edit
export const updateAdmRoundingSettingRequest = (payload: AdmRoundingSetting): AnyAction => ({
  type: CMAppConfigActions.AdmUpdateRoundingSettingsRequest,
  payload,
})
export const updateAdmRoundingSettingFail = (error: string | unknown): AnyAction => ({
  type: CMAppConfigActions.AdmUpdateRoundingSettingsFail,
  payload: error,
})
