import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ChangePasswordModal from '@/components/blocks/cm/ChangePasswordModal'
import { changePasswordRequest, getChangePasswordState } from '@/store/cm/auth'
import { ChangePasswordFormValuesCM } from '@/types'

export const ChangePassword: FC = () => {
  const dispatch = useDispatch()

  const { isModalOpen: isChangePasswordModalOpen, isLoading, error } = useSelector(
    getChangePasswordState,
  )

  const handleChangePassword = useCallback<(values: ChangePasswordFormValuesCM) => void>(
    (values): void => {
      dispatch(changePasswordRequest(values))
    },
    [dispatch],
  )

  if (!isChangePasswordModalOpen) return null

  return (
    <ChangePasswordModal
      open={isChangePasswordModalOpen}
      handleChangePassword={handleChangePassword}
      isLoading={isLoading}
      error={error}
    />
  )
}
