export enum AtmeyeTransactionByDeviceActions {
  ChangeSelectedFilters = '@/TRANSACTIONS_BY_DEVICE/CHANGE_SELECTED_FILTERS',
  ChangeGrouping = '@/TRANSACTIONS_BY_DEVICE/CHANGE_GROUPING',
  ClearGrouping = '@/CLEAR_GROUPING',
  ChangeSorting = '@/TRANSACTIONS_BY_DEVICE/CHANGE_SORTING',
  SetRowPerPage = '@/TRANSACTIONS_BY_DEVICE/SET_ROW_PER_PAGE',
  SetPage = '@/TRANSACTIONS_BY_DEVICE/SET_PAGE',

  DeleteFilterTemplateRequest = '@/TRANSACTIONS_BY_DEVICE/DELETE_FILTER_TEMPLATE_REQUEST',
  SetSelectedGroupingTemplate = '@/TRANSACTIONS_BY_DEVICE/SET_SELECTED_GROUPING_TEMPLATE',

  GetGroupingTemplatesRequest = '@/TRANSACTIONS_BY_DEVICE/GET_GROUPING_TEMPLATES_REQUEST',

  GetFiltersTemplatesRequest = '@/TRANSACTIONS_BY_DEVICE/GET_FILTERS_TEMPLATES_REQUEST',

  SaveGroupingTemplateRequest = '@/TRANSACTIONS_BY_DEVICE/SAVE_GROUPING_TEMPLATE_REQUEST',

  CreateFilterTemplateRequest = '@/TRANSACTIONS_BY_DEVICE/CREATE_FILTER_TEMPLATE_REQUEST',
  EditGroupTemplateRequest = '@/TRANSACTIONS_BY_DEVICE/EDIT_GROUP_TEMPLATE_REQUEST',
  DeleteGroupTemplateRequest = '@/TRANSACTIONS_BY_DEVICE/DELETE_GROUP_TEMPLATE_REQUEST',

  UpdateFilterTemplateRequest = '@/TRANSACTIONS_BY_DEVICE/UPDATE_FILTER_TEMPLATE_REQUEST',

  GetTransactionsConfigRequest = '@/TRANSACTIONS_BY_DEVICE/GET_TRANSACTIONS_CONFIG_REQUEST',
  GetTicketsConfigResponse = '@/TRANSACTIONS_BY_DEVICE/GET_TICKETS_CONFIG_RESPONSE',
  GetTicketsConfigResponseFail = '@/TRANSACTIONS_BY_DEVICE/GET_TICKETS_CONFIG_RESPONSE_FAIL',
  GetTransactionsRequest = '@/TRANSACTIONS_BY_DEVICE/GET_TRANSACTIONS_REQUEST',
  GetTransactionsResponse = '@/TRANSACTIONS_BY_DEVICE/GET_TRANSACTIONS_RESPONSE',
  GetGroupDetailsRequest = '@/TRANSACTIONS_BY_DEVICE/GET_GROUP_DETAILS_REQUEST',
  SetParentGroups = '@/TRANSACTIONS_BY_DEVICE/SET_PARENT_GROUPS',
  SetCurrentFilterTemplate = '@/TRANSACTIONS_BY_DEVICE/SET_CURRENT_FILTER_TEMPLATE',
  GetMedia = '@/TRANSACTIONS_BY_DEVICE/GET_MEDIA',
  SetMedia = '@/TRANSACTIONS_BY_DEVICE/SET_MEDIA',
  ClearCurrentMedia = '@/TRANSACTIONS_BY_DEVICE/CLEAR_CURRENT_MEDIA',
  SetCurrentMedia = '@/TRANSACTIONS_BY_DEVICE/SET_CURRENT_MEDIA',
  SetMediaErrorMessage = '@/TRANSACTIONS_BY_DEVICE/SET_MEDIA_ERROR_MESSAGE',
  EditTableCellRequest = '@/TRANSACTIONS_BY_DEVICE/EDIT_TABLE_CELL_REQUEST',
  EditTableCellGrouping = '@/TRANSACTIONS_BY_DEVICE/EDIT_TABLE_CELL_REQUEST_GROUPING',
  SaveTableCell = '@/TRANSACTIONS_BY_DEVICE/SAVE_TABLE_CELL',
  SaveTableCellGrouping = '@/TRANSACTIONS_BY_DEVICE/SAVE_TABLE_CELL_GROUPING',
  SetDataPagination = '@/TRANSACTIONS_BY_DEVICE/SET_DATA_PAGINATION',
  startFetchingDataPagination = '@/TRANSACTIONS_BY_DEVICE/START_FETCHING_DATA_PAGINATION',
  stopFetchingDataPagination = '@/TRANSACTIONS_BY_DEVICE/STOP_FETCHING_DATA_PAGINATION',
  CollectFilterTemplateFields = '@/TRANSACTIONS_BY_DEVICE/COLLECT_FILTER_TEMPLATE_FIELDS',

  SetChangeTableDataByDevice = '@/TRANSACTIONS_BY_DEVICE/SET_CHANGE_TABLE_DATA',
  ClearTransactionFlatDataByDevice = '@/TRANSACTIONS_BY_DEVICE/CLEAR_TRANSACTION_FLAT_DATA_BY_DEVICE',

  SetFilterModalState = '@/TRANSACTIONS_BY_DEVICE/SET_FILTER_MODAL_STATE',
  GetTicketResponseFail = '@/TRANSACTIONS_BY_DEVICE/GET_TICKETS_RESPONSE_FAIL',
  IsSubmitFiltersButtonClicked = '@/TRANSACTIONS_BY_DEVICE/IS_SUBMIT_FILTERS_BUTTON_CLICKED',
}
