import { APIConfiguration } from '@/constants'
import { ResponseError } from '@/types'
import { requestService } from '@/utils/services/request'

export class LocalizationApi {
  public static async getTranslations(
    locale: string,
  ): Promise<{ [key: string]: string } | ResponseError> {
    try {
      const response = await requestService.get(
        `${APIConfiguration.DEFAULT_TRANSLATIONS_URL}/${locale}`,
      )

      return response
    } catch (e) {
      throw new Error(e)
    }
  }
}
