import { difference, isEqual } from 'lodash-es'
import React, { useCallback, useMemo, useState } from 'react'

import { MODAL_TYPE } from '@/components/NPMPakage/components/modals/TemplateModal/component'
import { useTranslate } from '@/utils/internalization'

import { TableGroup } from '../components/SortBlock/types'

const useGrouping = ({
  defaultGrouping = [],
  appliedGrouping,
  // appliedTemplate,
  groupsList,
}: {
  defaultGrouping?: TableGroup[]
  appliedGrouping: TableGroup[]
  groupsList: string[]
}): any => {
  const translate = useTranslate()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const [groups, setGroups] = useState<TableGroup[]>(appliedGrouping)
  const [modalType, setModalType] = useState<MODAL_TYPE>(MODAL_TYPE.MAIN)

  const showModal = useCallback((modalType: MODAL_TYPE): void => {
    setModalType(modalType)
    setAnchorEl(null)
  }, [])

  const handleGroupChange = useCallback(
    (index: number): ((group?: TableGroup) => void) => (group): void => {
      setGroups(
        group
          ? [...groups.slice(0, index), group, ...groups.slice(index + 1, groups.length)]
          : groups.slice(0, index),
      )
    },
    [groups],
  )

  const handleGroupClear = useCallback((): void => {
    setGroups([...defaultGrouping])
  }, [defaultGrouping])

  const isClearButtonDisabled = useMemo(() => groups.every(item => item === undefined), [groups])
  const isApplyButtonDisabled = useMemo(() => isEqual(appliedGrouping, groups), [
    groups,
    appliedGrouping,
  ])

  const unselectedGroups = useMemo(() => {
    const selectedGroupsKeys = groups.map(group => group?.groupingField)

    return difference(groupsList as string[], selectedGroupsKeys as string[])
  }, [groups, groupsList])

  const getFieldOptions = useCallback(
    (index: number) => {
      const currentGroup: string | undefined = groups[index]?.groupingField

      if (currentGroup) {
        return [currentGroup, ...unselectedGroups].map(value => {
          return {
            name: translate(`translate#atmeye.groupingField.${value}`),
            value,
          }
        })
      }

      return unselectedGroups.map(value => {
        return {
          name: translate(`translate#atmeye.groupingField.${value}`),
          value,
        }
      })
    },
    [groups, unselectedGroups],
  )

  return {
    modalType,
    showModal,
    groups,

    isClearButtonDisabled,
    isApplyButtonDisabled,

    anchorEl,

    translate,

    getFieldOptions,
    handleGroupClear,
    handleGroupChange,

    handleSetGroups: setGroups,
  }
}

export { useGrouping }
