import moment, { Moment } from 'moment'

import { DATE_FORMAT, DATE_TIME_SEC_FORMAT, DEFAULT_DATE_TIME_SEC_FORMAT } from '@/constants/time'

export const formatDateForMapPage = (data: string | number): string => {
  return moment(data).format('hh:mm:ss a')
}

export const getLocalTimeZoneTime = (serverDate: any, dateFormat: string) => {
  const localDate = new Date()

  const gmtHours = -localDate.getTimezoneOffset() / 60
  return moment(serverDate)
    .add(gmtHours, 'hours')
    .format(dateFormat || DEFAULT_DATE_TIME_SEC_FORMAT)
}

export const getTimeZone = (): string => {
  const offset = new Date().getTimezoneOffset()
  const o = Math.abs(offset)
  return (
    (offset < 0 ? '+' : '-') +
    ('00' + Math.floor(o / 60)).slice(-2) +
    ':' +
    ('00' + (o % 60)).slice(-2)
  )
}

export const getBeginningOfCurrentDay = (): string =>
  moment()
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .format()

export const createUTCDate = (date: number, format: string) => {
  return moment(date)
    .utc()
    .format(format)
}

export const createDate = (date: number, format: string) => {
  return moment(date).format(format)
}

export const createDateRange = (
  dates: Array<number>,
  format: string,
  translate?: (string: string) => string,
) => {
  if (!dates[1] && translate) {
    return `${translate('translate#atmeye.dateFrom')} ${createDate(dates[0], format)}`
  }
  return `${createDate(dates[0], format)} - ${createDate(dates[1], format)}`
}

export const getDateNow = () => {
  return moment(new Date())
}

export const getDate = (date?: Moment): Moment => {
  const offset = moment(date).utcOffset()
  const hours = moment(date).hours() + offset / 60

  return moment(date).set('hour', hours)
}

export const getDateMonthAgo = (): string => {
  return moment()
    .subtract(1, 'months')
    .format(DATE_TIME_SEC_FORMAT)
}

export const getDateDayAgo = (): string => {
  return moment()
    .subtract(1, 'd')
    .format(DATE_TIME_SEC_FORMAT)
}

export const getDateHourAgo = (): string => {
  return moment()
    .subtract(1, 'hours')
    .format(DATE_TIME_SEC_FORMAT)
}

export const createDateInSeconds = (dateMilliseconds: number): string => {
  return moment(new Date(dateMilliseconds * 1000)).toISOString()
}

export const createFilterDateRange = ({
  from,
  to,
}: {
  from: number | null
  to: number | null
}): Array<string> | null => {
  return from && to ? [createDateInSeconds(from), createDateInSeconds(to)] : null
}

export const createLocalDate = (date: string, format: string) => {
  return moment
    .utc(date)
    .local()
    .format(format)
}

export const createDateFilters = (date: string, isLocalDate?: boolean) => {
  return isLocalDate
    ? Math.floor(getDate(moment(date)).valueOf() / 1000)
    : Math.floor(
        moment(date)
          .utc()
          .valueOf() / 1000,
      )
}

export const fixDateForAllBrowsers = (date: string) => {
  return date.replace(/ /g, 'T').replace(/\./g, '/')
}

export const createMomentDateFromTime = (time: string) => {
  const dateNow = moment().format(DATE_FORMAT)
  const currentDay = moment().format('D')

  return moment
    .utc(`${dateNow} ${time}`)
    .local()
    .set('date', +currentDay)
}
