import { AnyAction } from 'redux'

import { TabBarItem, TabBarItemExtended } from '@/components/wrappers/AppNavigation/types'
import { PortalActions } from '@/constants'

export const setTabs = (payload: TabBarItemExtended[]): AnyAction => ({
  type: PortalActions.SetOpenTabs,
  payload,
})

export const openTab = (payload: TabBarItemExtended): AnyAction => ({
  type: PortalActions.OpenTab,
  payload,
})

export const setCurrentTab = (prevPath: string, currentPath: string): AnyAction => ({
  type: PortalActions.SetCurrentTab,
  payload: { prevPath, currentPath },
})
