import { Checkbox, Dialog, FormControlLabel } from '@material-ui/core'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import CloseButton from '@/components/blocks/CloseButton'
import Loader from '@/components/blocks/Loader'
import { Button } from '@/components/controls/Button/component'
import RadioButton from '@/components/controls/Radio'
import RadioButtonsGroup from '@/components/controls/RadioGroup'
import { TextInput } from '@/components/controls/TextInput'
import { Restricted } from '@/components/NPMPakage/components/wrappers/PermissionProvider/Restricted'
import { Permission } from '@/components/NPMPakage/components/wrappers/types'
import { onlyNumbersAndLettersAndDots, withoutSpecialSymbolsWithDotExpAsOnBack } from '@/constants'
import { getError, getFetchingSubModalPage } from '@/store/atmeye/dashboard'
import { getUserPermissions } from '@/store/auth'
import { useErrorMessage } from '@/utils/hooks/useErrrorMessage'
import { useTranslate } from '@/utils/internalization'

import { PublishedPageSelect } from './publishedPageSelect'
import { useClasses } from './styles'
import { FormValues, Props } from './types'

const SubPageModal: FC<Props> = ({
  modalKind,

  editAction,
  translateButton: { id, defaultMessage },
  subPageFullData,

  onSave,
  onClose,
}: Props) => {
  const classes = useClasses()
  const translate = useTranslate()

  const loading = useSelector(getFetchingSubModalPage)
  const error = useSelector(getError)
  const permissions = useSelector(getUserPermissions)

  const { errorMessages, setErrorMessages } = useErrorMessage({ error })

  const [formValues, setFormValues] = useState<FormValues>(() => ({
    name: subPageFullData && modalKind === 'Edit page' ? subPageFullData.name : '',
    published:
      subPageFullData?.isPublished ?? !!permissions[Permission.Subscribe_to_published_pages],
    main: subPageFullData?.isMain || false,
    radioButton: 'true',
    select: '',
  }))

  const [isInitialState, setIsInitialState] = useState<boolean>(true)
  const initialState = useRef<FormValues | null>(null)

  useEffect(() => {
    initialState.current = formValues
  }, [])

  const handleFormChange = (
    changeValue: keyof FormValues,
    newValue: string | undefined | unknown,
  ): void => {
    if (errorMessages.length > 0) {
      setErrorMessages([])
    }

    setFormValues((prevState: FormValues) => ({ ...prevState, [changeValue]: newValue }))
  }

  useEffect(() => {
    if (initialState.current) {
      const keys = Object.keys(initialState.current) as Array<keyof FormValues>
      const initialValues = keys.every(key => initialState?.current?.[key] === formValues?.[key])

      setIsInitialState(initialValues)
    }
  }, [formValues])

  const handleClose = useCallback(() => {
    onClose()
  }, [])

  useEffect(() => {
    formValues.radioButton === 'true' && setFormValues(values => ({ ...values, select: '' }))
  }, [formValues.radioButton])

  const isDisabledAddButton = useMemo(() => {
    const isHasName = !!formValues.name.replace(/\s+/g, ' ').trim().length
    return (
      loading || !(isHasName || formValues.select) || formValues.name.length > 30 || isInitialState
    )
  }, [loading, formValues, isInitialState])

  const loadingJSX = loading && (
    <div className={classes.loaderWrapper}>
      <Loader />
    </div>
  )

  const [pageNameErrorMessage] = errorMessages

  return (
    <Dialog open={!!modalKind} className={classes.root} disableBackdropClick>
      <div className={classes.wrapper}>
        <CloseButton onClick={handleClose} className={classes.closingWindow} />
        {loadingJSX}

        <p className={classes.headerTitle}>
          <FormattedMessage
            id={`atmeye.dashboard.page.modal.${modalKind}`}
            defaultMessage={String(modalKind)}
          />
        </p>

        {modalKind === 'Add new page' && (
          <RadioButtonsGroup
            value={formValues.radioButton}
            className={classes.radioGroup}
            onChange={(e): void => {
              handleFormChange('radioButton', e.target.value)
            }}
          >
            <RadioButton value="true" label={translate('translate#atmeye.title.new')} />
            <Restricted permissionKey={Permission.Subscribe_to_published_pages}>
              <RadioButton value="false" label={translate('translate#title.published')} />
            </Restricted>
          </RadioButtonsGroup>
        )}

        {formValues.radioButton === 'true' ? (
          <TextInput
            disabled={!!subPageFullData?.isSubscription}
            fullWidth
            error={!!pageNameErrorMessage}
            disabledKeys={onlyNumbersAndLettersAndDots}
            forbiddenCharactersRegExp={withoutSpecialSymbolsWithDotExpAsOnBack}
            label={translate('translate#atmeye.dashboard.title.pageName')}
            placeholder={translate('translate#atmeye.dashboard.title.pageName')}
            value={formValues.name}
            maxSymbols={30}
            errorText={pageNameErrorMessage}
            helperText={
              !pageNameErrorMessage ? translate('translate#atmeye.pageNameMaxLength') : ''
            }
            onChange={(e): void => {
              handleFormChange('name', e.target.value)
            }}
          />
        ) : (
          <PublishedPageSelect
            value={formValues.select}
            onChange={e => {
              handleFormChange('select', e.target.value)
            }}
          />
        )}

        <div className={classes.checkBoxes}>
          <Restricted permissionKey={Permission.Publish_pages}>
            {formValues.radioButton === 'true' && (
              <FormControlLabel
                label={translate('translate#atmeye.dashboard.publish')}
                control={
                  <Checkbox
                    onChange={(e): void => {
                      handleFormChange('published', e.target.checked)
                    }}
                    checked={formValues.published}
                    disabled={!!subPageFullData?.isSubscription}
                  />
                }
              />
            )}
          </Restricted>

          <FormControlLabel
            label={translate('translate#atmeye.dashboard.title.mainPage')}
            control={
              <Checkbox
                onChange={(e): void => {
                  handleFormChange('main', e.target.checked)
                }}
                checked={formValues.main}
                disabled={formValues.main && subPageFullData?.isMain}
              />
            }
          />
        </div>

        <div className={classes.modalFooter}>
          <Button
            variant="outlined"
            color="default"
            className={classes.cancelBtn}
            onClick={handleClose}
          >
            <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
          </Button>

          {editAction}

          <Button
            variant="contained"
            color="primary"
            onClick={onSave(
              formValues.radioButton === 'true'
                ? {
                    main: formValues.main,
                    published: formValues.published,
                    name: formValues.name,
                  }
                : { isMain: formValues.main, subpageId: formValues.select },
            )}
            disabled={isDisabledAddButton}
          >
            <FormattedMessage id={id} defaultMessage={defaultMessage} />
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export { SubPageModal }
