import { AdmRoundingSetting } from '@/api/cm/swaggerGeneratedApi'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class RoundBanknotesApi {
  private static requestService = getRequestServiceCM()

  public static async get(): Promise<void> {
    try {
      return await RoundBanknotesApi.requestService.get('/admin/replenishmentRoundingSettings')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async update(roundingSetting: AdmRoundingSetting): Promise<void> {
    try {
      return await RoundBanknotesApi.requestService.put(
        '/admin/replenishmentRoundingSettings/save',
        roundingSetting,
      )
    } catch (e) {
      throw new Error(e)
    }
  }
}
