import React, { FC } from 'react'
import clsx from 'clsx'
import { useClasses } from './styles'
import { Props } from './types'

export const CellLink: FC<Props> = React.memo((props: Props) => {
  const { value, disabled = false, onClick } = props

  const classes = useClasses()

  return value ? (
    <div
      className={clsx(classes.linkTableCell, { [classes.disabled]: disabled })}
      onClick={!disabled ? onClick : undefined}
    >
      {value}
    </div>
  ) : null
})
