import { AnyAction } from 'redux'

import { AdmEventNotificationsResponse } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants/actions'

export type State = {
  data: AdmEventNotificationsResponse | null
  isLoading: boolean
  isLoaded: boolean
  sortColumn?: string
  sortOrder?: string
}

export const initialState: State = {
  data: null,
  sortColumn: undefined,
  sortOrder: undefined,
  isLoaded: false,
  isLoading: false,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    // get
    case CMAppConfigActions.AdmEventNotificationsRequest:
    case CMAppConfigActions.AdmEventNotificationsAddRequest:
    case CMAppConfigActions.AdmEventNotificationsUpdateRequest:
    case CMAppConfigActions.AdmEventNotificationsDeleteRequest:
      return {
        ...state,
        isLoading: true,
      }

    case CMAppConfigActions.AdmEventNotificationsResponse:
      return {
        ...state,

        isLoading: false,
        isLoaded: true,
        data: payload,
      }

    case CMAppConfigActions.AdmEventNotificationsFail:
    case CMAppConfigActions.AdmEventNotificationsAddFail:
    case CMAppConfigActions.AdmEventNotificationsUpdateFail:
    case CMAppConfigActions.AdmEventNotificationsDeleteFail:
      return {
        ...state,

        isLoading: false,
        isLoaded: true,
      }
    // sort
    case CMAppConfigActions.AdmSortEventNotifications:
      return {
        ...state,
        ...payload,
        isLoading: true,
      }
    default:
      return state
  }
}
