export enum AtmeyeDeviceActions {
  GetDevicesRequest = '@/DEVICES/GET_DEVICES_REQUEST',
  GetDevicesExport = '@/DEVICES/GET_DEVICES_EXPORT',
  GetExportResponse = '@/GET_EXPORT_RESPONSE',
  GetExportResponseFail = '@/GET_EXPORT_RESPONSE_FAIL',
  GetDevicesExportGrouped = '@/DEVICES/GET_DEVICES_EXPORT_GROUPED',
  GetExportGroupedResponse = '@/GET_EXPORT_GROUPED_RESPONSE',
  GetExportGroupedResponseFail = '@/GET_EXPORT_GROUPED_RESPONSE_FAIL',
  GetGroupDetailsRequest = '@/DEVICES/GET_GROUP_DETAILS_REQUEST',
  SetParentGroups = '@/DEVICES/SET_PARENT_GROUPS',
  GetFiltersTemplatesRequest = '@/DEVICES/GET_FILTERS_TEMPLATES_REQUEST',
  SetFilterTemplatesResponse = '@/DEVICES/SET_FILTER_TEMPLATE_RESPONSE',
  CreateFilterTemplateRequest = '@/DEVICES/CREATE_FILTER_TEMPLATE_REQUEST',
  UpdateFilterTemplateRequest = '@/DEVICES/UPDATE_FILTER_TEMPLATE_REQUEST',
  DeleteFilterTemplateRequest = '@/DEVICES/DELETE_FILTER_TEMPLATE_REQUEST',
  GetGroupsTemplatesRequest = '@/DEVICES/GET_GROUPS_TEMPLATE_REQUEST',
  EditGroupTemplateRequest = '@/DEVICES/EDIT_GROUP_TEMPLATE_REQUEST',
  DeleteGroupTemplateRequest = '@/DEVICES/DELETE_GROUP_TEMPLATE_REQUEST',
  SaveGroupingTemplateRequest = '@/DEVICES/SAVE_GROUPING_TEMPLATE_REQUEST',
  GetDevicesConfigRequest = '@/DEVICES/GET_DEVICES_CONFIG_REQUEST',
  GetDevicesConfigResponse = '@/DEVICES/GET_DEVICES_CONFIG_RESPONSE',
  SetAtmeyeActualCountParentGroup = '@/GET_ATMEYETICKETS_SET_ACTUAL_COUNT_PARENT_GROUP',
  SetDataPagination = '@/DEVICES/SET_DATA_PAGINATION',
  SetScrollMultiSelectConfigValues = '@/DEVICES/SET_SCROLL_MULTISELECT_CONFIG_VALUES',
  SetSelectedGroupingTemplate = '@/DEVICES/SET_SELECTED_GROUPING_TEMPLATE',
}
