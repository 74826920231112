import { RouteComponentProps } from 'react-router'

export interface Props extends RouteComponentProps<any> {
  testIdPrefix: string
  handleClose: () => void
  handleOpenCreatePosRequestModal: () => void
  ticketId?: number
}

export enum DocumentInformationFields {
  CustomerRequestNumber = 'customerRequestNumber',
  ServiceCompany = 'serviceCompany',
  Date = 'date',

  MerchantInformation = 'MerchantInformation',
  MerchantLegalAddress = 'merchantlegaladdress',
  Contacts = 'Contacts',
  ItemNo = 'itemNo',
  ShopBrandName = 'shop/BrandName',
  Status = 'status',
  Service = 'service',
  Priority = 'priority',
  TerminalID = 'terminalId',
  POSAddress = 'posAddress',
  MerchantID = 'merchantId',
  MCCCode = 'mccCode',
  TerminalType = 'terminalType',
  ConnectionType = 'connectionType',
  ContactPerson = 'contactPerson',
  Phone = 'phone',
  Notes = 'notes',
}

export interface CommonTicketInfo {
  [DocumentInformationFields.CustomerRequestNumber]: string | null
  [DocumentInformationFields.ServiceCompany]: string | null
  [DocumentInformationFields.Date]: string | null
}

type TicketInfoType = string | null

export type FormattedTicketInfo = {
  merchantInformation: {
    legalName: TicketInfoType
    companyCode: TicketInfoType
    VATCode: TicketInfoType
  }
  merchantLegalAddress: {
    country: TicketInfoType
    [key: string]: string | null
    streetHouseNo: TicketInfoType
    postalCode: TicketInfoType
  }
  merchantActualAddress: {
    country: TicketInfoType
    [key: string]: string | null
    streetHouseNo: TicketInfoType
    postalCode: TicketInfoType
  }
  contacts: {
    contactPerson: TicketInfoType
    phoneNumber: TicketInfoType
    email: TicketInfoType
  }

  [key: string]: {
    [key: string]: string | number | null
  }
}

export interface FormattedRequestItems {
  [DocumentInformationFields.ItemNo]: number | null
  [DocumentInformationFields.ShopBrandName]: string | null
  [DocumentInformationFields.Status]: number | null
  [DocumentInformationFields.Service]: string | null
  [DocumentInformationFields.Priority]: string | null
  [DocumentInformationFields.TerminalID]: string | null
  [DocumentInformationFields.POSAddress]: string | null
  [DocumentInformationFields.MerchantID]: string | null
  [DocumentInformationFields.MCCCode]: string | null
  [DocumentInformationFields.TerminalType]: string | null
  [DocumentInformationFields.ConnectionType]: string | null
  [DocumentInformationFields.ContactPerson]: string | null
  [DocumentInformationFields.Phone]: string | null
  [DocumentInformationFields.Notes]: string | null
}
