import makeStyles from '@material-ui/core/styles/makeStyles'

export const HEIGHT_OF_CHART_SECTION = 275

export const useClasses = makeStyles(({ spacing }) => ({
  wrapper: {
    height: HEIGHT_OF_CHART_SECTION,
    padding: spacing(4),
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08), 0px 0px 4px rgba(0, 0, 0, 0.12);',
  },
  chart: {
    width: '100%',
  },
  slaLegend: {
    marginLeft: 100,
  },
  select: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
  slaItemLegend: {
    width: 200,
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& > span': {
      fontSize: 16,
    },
  },
  slaItemLegendLabel: {
    display: 'flex',
    alignItems: 'center',

    '& > span': {
      color: '#545454',
    },
  },
  slaItemLegendCircle: {
    display: 'inline',
    width: spacing(1),
    height: spacing(1),
    borderRadius: '50%',
    marginRight: spacing(1),
  },
}))
