import React, { ReactElement } from 'react'

// CM
import { ReactComponent as ATMIcon } from '@/assets/cashManagement/icons/networks/atm.svg'
import { ReactComponent as KioskIcon } from '@/assets/cashManagement/icons/networks/kiosk.svg'
// Vault-v1
import { ReactComponent as BranchIcon } from '@/assets/vault-v1/icons/branch.svg'
import { ReactComponent as CashProviderIcon } from '@/assets/vault-v1/icons/cash-provider.svg'
import { ReactComponent as CentraBankIcon } from '@/assets/vault-v1/icons/central-bank.svg'
import { ReactComponent as CommercialBankIcon } from '@/assets/vault-v1/icons/commercial-bank.svg'
import { ReactComponent as CommercialClientIcon } from '@/assets/vault-v1/icons/commercial-client.svg'
import { ReactComponent as VaultIcon } from '@/assets/vault-v1/icons/vault.svg'

export const icons: { [key: string]: ReactElement } = {
  ATMEnum: <ATMIcon />,
  CashPointEnum: <ATMIcon />,
  KioskEnum: <KioskIcon />,

  // BranchEnum: <BranchIcon />,
  VaultEnum: <VaultIcon />,

  RegionEnum: <VaultIcon />,

  CashProviderEnum: <BranchIcon />,
  CashierEnum: <CashProviderIcon />,
  CorporateClientEnum: <CommercialClientIcon />,

  CommercialBankEnum: <CommercialBankIcon />,
  CentralBankEnum: <CentraBankIcon />,
}
