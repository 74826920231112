import { CircularProgress, Popper, PopperProps, TextField } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab'
import clsx from 'clsx'
import { FieldProps } from 'formik'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { generateErrorText } from '@/helpers/generateErrorText'

import { useClasses } from './styles'
import { CreateProps, Option, Props } from './types'
let timerId: number

export const DropDownInput = ({
  onTextChange,
  onChange,
  value,
  placeholder,
  label,
  options,
  isLoading,
  disabled,
  error,
  onBlur,
  required,
  timer,
  icon,
  pattern,
  className,
  errorText,
  displayError = true,
  withoutSelectArrow = false,
  classes: inputClasses,
  defaultValue,
  neverDisable = true,
  withoutSingleOption,
  renderOption,
  fieldName,
  filtering = true,
  filterModalFieldWatcher,
  onTextFieldClick,
  stopPropagationByChange,
  dynamicMenuWidth,
  onSearchValueChange,
  clearOptions,
  loadData,
  onOpen,
  setFieldValue,
  resetDependencies,
}: Props): React.ReactElement => {
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [data, setData] = useState<Option[]>([])
  const [valid, setValid] = useState<boolean>(true)
  const hasSingleOption = options.length === 1
  const classes = useClasses({
    emptyValue: !!data.length && data[0].name === placeholder ? data[0].name : '',
    inputValue: !!inputValue,
    maxItemSymbolsCount: dynamicMenuWidth
      ? Math.max(...options.map(item => item.name.length))
      : undefined,
  })

  const currentValue = useMemo(() => {
    return options.find(option => String(option.value) === String(value)) || defaultValue || null
  }, [options, value, defaultValue, hasSingleOption])

  const currentInputValue = useMemo(() => {
    if (currentValue && !inputValue) {
      return currentValue.name
    }

    return inputValue
  }, [inputValue, currentValue])

  useEffect(() => {
    if (placeholder && !currentInputValue && !inputValue) {
      return setData([{ name: placeholder, value: '' }, ...options])
    }

    setData(options)
  }, [options, placeholder, inputValue])

  useEffect(() => {
    if (onTextChange) {
      clearTimeout(timerId)
      timerId = window.setTimeout(() => onTextChange(inputValue), timer || 300)
      return (): void => clearTimeout(timerId)
    }
  }, [onTextChange, inputValue, timer])

  useEffect(() => {
    onSearchValueChange && onSearchValueChange(inputValue)
  }, [inputValue])

  useEffect(() => {
    if (!withoutSingleOption && hasSingleOption) {
      onChange(options[0].value)
    }
  }, [hasSingleOption])

  useEffect(() => {
    if (filterModalFieldWatcher && fieldName && options.length) {
      const newOption = options.find(option => option.value === value)

      if (newOption) {
        filterModalFieldWatcher({
          fieldName,
          value: {
            id: newOption.value,
            displayName: newOption.name,
          },
        })
      }
    }
  }, [value])

  const handleClear = useCallback(() => setInputValue(''), [])

  const handleGetOptionSelected = useCallback((option: Option, value: Option): boolean => {
    return option.value === value.value
  }, [])
  const handleGetOptionLabel = useCallback(({ name }: Option): string => name, [])
  const handleChange = useCallback(
    (e: ChangeEvent<{ value: string }>): void => {
      if (pattern) {
        if (!e.target.value.match(pattern)) {
          setValid(false)
        } else {
          setInputValue(e.target.value)
          setValid(true)
        }
      }

      setInputValue(e.target.value)
    },
    [pattern],
  )

  const handleOpen = (): void => {
    // if (onOpen && options.length <= 0) {
    if (onOpen) {
      onOpen(inputValue)
    }

    setOpen(true)
  }

  const handleClose = useCallback((): void => {
    setOpen(false)
  }, [])

  const handleValueChange = useCallback(
    (event: React.ChangeEvent<{}>, value: Option | null): void => {
      onChange(value ? value.value : '')
      setInputValue('')

      if (resetDependencies && setFieldValue) {
        if (Array.isArray(resetDependencies)) {
          resetDependencies.map(item => {
            setFieldValue(item, null)
          })
        }
      }
    },
    [onChange],
  )

  const CustomPopper = (props: PopperProps): React.ReactElement => {
    return (
      <Popper
        {...props}
        modifiers={{
          flip: {
            enabled: false,
          },
        }}
      />
    )
  }

  const isDisabled = useMemo(() => {
    if (neverDisable) return false
    if (!withoutSingleOption && hasSingleOption) return hasSingleOption
    if (disabled) return disabled
  }, [neverDisable, withoutSingleOption, hasSingleOption, disabled])

  const isRequired = required && !isDisabled

  useEffect(() => {
    if (loadData) {
      loadData(value)
    }
  }, [])

  return (
    <Autocomplete
      open={open}
      className={clsx(classes.dropDown, className, inputClasses?.field, {
        [classes.inputWithError]: displayError && errorText,
      })}
      onOpen={handleOpen}
      PopperComponent={CustomPopper}
      disabled={isDisabled}
      onChange={(e: React.ChangeEvent<{}>, value: { value: string; name: string } | null): void => {
        if (stopPropagationByChange) {
          e.stopPropagation()
        }

        if (filterModalFieldWatcher) {
          filterModalFieldWatcher({
            fieldName: fieldName || '',
            value: {
              id: (value && value.value) || '',
              displayName: (value && value.name) || '',
            },
          })
        }
        handleValueChange(e, value)
      }}
      onBlur={onBlur}
      onClose={handleClose}
      getOptionSelected={handleGetOptionSelected}
      getOptionLabel={handleGetOptionLabel}
      inputValue={currentInputValue}
      value={currentValue}
      options={data}
      classes={{
        option: classes.option,
        paper: dynamicMenuWidth ? classes.menuPaper : undefined,
      }}
      filterOptions={!filtering ? options => options : undefined}
      loading={open && options.length === 0 && isLoading}
      renderOption={renderOption}
      ListboxProps={{ style: { maxHeight: '250px' } }}
      popupIcon={!withoutSelectArrow ? icon || <ExpandMoreIcon className={classes.icon} /> : <></>}
      renderInput={(params: AutocompleteRenderInputParams): React.ReactElement => (
        <>
          <TextField
            autoComplete="off"
            {...params}
            label={label && disabled ? <div className={classes.labelDisabled}>{label}</div> : label}
            required={isRequired}
            error={error || !valid}
            value={inputValue || null}
            placeholder={placeholder}
            variant="outlined"
            onChange={handleChange}
            onBlur={(): void => {
              handleClear()
              clearOptions && !value?.length && clearOptions()
            }}
            className={
              !withoutSelectArrow
                ? clsx({ [classes.inputWithError]: displayError && errorText })
                : undefined
            }
            InputProps={{
              style: { fontFamily: 'Noto Sans, sans-serif ' },
              ...params.InputProps,
              endAdornment: (
                <>
                  {open && isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
              classes: {
                input: inputClasses?.text,
              },
            }}
            InputLabelProps={{ shrink: true }}
            onClick={onTextFieldClick}
          />

          {isRequired && displayError && !!errorText && (
            <div className={classes.errorLabel}>
              <FormattedMessage id={`form.validationWarning.${errorText}`} />
            </div>
          )}
        </>
      )}
    />
  )
}

export const createDropDownInputField = (
  props: CreateProps,
): ((field: FieldProps) => React.ReactElement) => ({
  field: { name, value },
  form: { getFieldHelpers, errors, setFieldValue },
  meta: { touched },
}): React.ReactElement => {
  const handleChange = getFieldHelpers(name).setValue

  return (
    <DropDownInput
      onBlur={() => {
        getFieldHelpers(name).setTouched(true)
      }}
      value={value}
      onChange={handleChange}
      errorText={generateErrorText(errors, name, touched)}
      error={!!errors[name] && touched}
      fieldName={name}
      setFieldValue={setFieldValue}
      {...props}
    />
  )
}
