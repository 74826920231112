import { AnyAction } from 'redux'

import { AdmEventNotification, AdmEventNotificationBase } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants/actions/cmActions'
import { Sort } from '@/types/cm'

// get
export const getEventNotificationsRequest = (): AnyAction => ({
  type: CMAppConfigActions.AdmEventNotificationsRequest,
})
export const getEventNotificationsResponse = (payload: AdmEventNotification[]): AnyAction => ({
  type: CMAppConfigActions.AdmEventNotificationsResponse,
  payload: payload,
})
export const getEventNotificationsFail = (): AnyAction => ({
  type: CMAppConfigActions.AdmEventNotificationsFail,
})

// add
export const addEventNotificationsRequest = (payload: AdmEventNotificationBase): AnyAction => ({
  type: CMAppConfigActions.AdmEventNotificationsAddRequest,
  payload,
})
export const addEventNotificationsFail = (payload: string | unknown): AnyAction => ({
  type: CMAppConfigActions.AdmEventNotificationsAddFail,
  payload,
})

// update
export const updateEventNotificationsRequest = (payload: AdmEventNotification): AnyAction => ({
  type: CMAppConfigActions.AdmEventNotificationsUpdateRequest,
  payload,
})
export const updateEventNotificationsFail = (payload: string | unknown): AnyAction => ({
  type: CMAppConfigActions.AdmEventNotificationsUpdateFail,
  payload,
})

// delete
export const deleteEventNotificationsRequest = (payload: number): AnyAction => ({
  type: CMAppConfigActions.AdmEventNotificationsDeleteRequest,
  payload,
})
export const deleteEventNotificationsFail = (payload: string | unknown): AnyAction => ({
  type: CMAppConfigActions.AdmEventNotificationsDeleteFail,
  payload,
})

// sort
export const sortEventNotifications = (payload: Sort): AnyAction => ({
  type: CMAppConfigActions.AdmSortEventNotifications,
  payload: payload,
})
