import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  formContainer: {
    display: 'grid',
    gridTemplateRows: '1fr 50px',
    height: '100%',
    width: '100%',
  },
  list: {
    height: '100%',
    overflowY: 'auto',

    // Ability to split appConfig to the two columns ---------------------

    // display: 'grid',
    // gridTemplateColumns: '1fr 1fr',
  },
  field: {
    paddingBottom: spacing(1),
    width: 'max-content',
  },

  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gridColumnGap: spacing(1),
  },
  button: {
    height: 40,
    fontWeight: 'normal',
    padding: '0 10px',
    margin: 0,
    whiteSpace: 'nowrap',
    fontSize: '13px',
  },
}))
