import { AnyAction } from 'redux'

import { Account } from '@/api/common/authorizationApi/types'
import { ChangePasswordFormValuesCM } from '@/components/blocks/cm/ChangePasswordModal/types'
import { AuthorizationActions, LoginFormValues, PERMISSIONS } from '@/constants'
import { ResponseError } from '@/types'

export const signInRequest = (values: LoginFormValues): AnyAction => ({
  type: AuthorizationActions.SignInRequest,
  payload: values,
})

export const signInResponse = ({ accounts }: { accounts: any }): AnyAction => ({
  type: AuthorizationActions.SignInResponse,
  payload: { accounts },
})

export const signInResponseFail = (error: string): AnyAction => ({
  type: AuthorizationActions.SignInResponseFail,
  payload: error,
})

export const changePasswordRequest = (values: ChangePasswordFormValuesCM): AnyAction => ({
  type: AuthorizationActions.ChangePasswordRequest,
  payload: values,
})

export const changePasswordResponse = (payload: any): AnyAction => ({
  type: AuthorizationActions.ChangePasswordResponse,
  payload,
})

export const changePasswordResponseFail = (error: string): AnyAction => ({
  type: AuthorizationActions.ChangePasswordResponseFail,
  payload: error,
})

export const setChangePasswordModalOpen = (isOpen: boolean): AnyAction => ({
  type: AuthorizationActions.SetChangePasswordModalOpen,
  payload: isOpen,
})

// export const defineUserRoleAndLogin = (payload: string): AnyAction => ({
//   type: AuthorizationActions.DefineUser,
//   payload,
// })

export const putCurrentAccount = (currentAccount: Account): AnyAction => ({
  type: AuthorizationActions.PutCurrentAccount,
  payload: currentAccount,
})

export const authorizeUser = () => ({ type: AuthorizationActions.AuthorizeUser })

export const signOut = (): AnyAction => ({
  type: AuthorizationActions.SignOut,
})

export const resetReducers = (): AnyAction => ({
  type: AuthorizationActions.ResetReducers,
})

export const checkIsLogin = (): AnyAction => ({
  type: AuthorizationActions.CheckIsLogin,
})

export type PermissionType = Record<typeof PERMISSIONS[keyof typeof PERMISSIONS], string>

export const getAllPermissionsRequest = (): AnyAction => ({
  type: AuthorizationActions.GetAllPermissionsRequest,
})
export const getAllPermissionsResponse = (permissions: PermissionType): AnyAction => ({
  type: AuthorizationActions.GetAllPermissionsResponse,
  payload: permissions,
})
export const getAllPermissionsResponseFail = (error: ResponseError): AnyAction => ({
  type: AuthorizationActions.GetAllPermissionsResponseFail,
  payload: error,
})

export const getAllUserPermissionsRequest = (): AnyAction => ({
  type: AuthorizationActions.GetAllUserPermissionsRequest,
})
export const getAllUserPermissionsResponse = (permissions: PermissionType): AnyAction => ({
  type: AuthorizationActions.GetAllUserPermissionsResponse,
  payload: permissions,
})
export const getAllUserPermissionsResponseFail = (error: ResponseError): AnyAction => ({
  type: AuthorizationActions.GetAllUserPermissionsResponseFail,
  payload: error,
})

export const getUserPhotoRequest = (): AnyAction => ({
  type: AuthorizationActions.GetUserPhotoRequest,
})
export const getUserPhotoResponse = (base64: string): AnyAction => ({
  type: AuthorizationActions.GetUserPhotoResponse,
  payload: base64,
})
export const getUserPhotoResponseFail = (error: ResponseError): AnyAction => ({
  type: AuthorizationActions.GetUserPhotoResponseFail,
  payload: error,
})

export const setAvailableProducts = (products: Array<string>) => ({
  type: AuthorizationActions.SetAvailableProducts,
  payload: products,
})

export const setCurrentProduct = (currentProduct: string) => ({
  type: AuthorizationActions.SetCurrentProducts,
  payload: currentProduct,
})

export const setUserFromAvailableUsers = (currentProduct: string) => ({
  type: AuthorizationActions.SetCurrentUserFromAvailable,
  payload: currentProduct,
})

export const startIsInitializationAuthProcess = () => ({
  type: AuthorizationActions.StartIsInitialisationAuthProcess,
})

export const stopIsInitializationAuthProcess = () => ({
  type: AuthorizationActions.StopIsInitialisationAuthProcess,
})
