import queryString from 'query-string'

import { AdmVehicle, AdmVehicleBase } from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { Sort } from '@/types/cm'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

export class VehicleAdminApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getAll(sort: Sort, guid: string): Promise<AdmVehicle[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/vehicles',
          query: { guid, ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await VehicleAdminApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(payload: AdmVehicleBase): Promise<void> {
    try {
      return await VehicleAdminApi.requestService.post('/admin/vehicles/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(payload: AdmVehicle): Promise<void> {
    try {
      return await VehicleAdminApi.requestService.put('/admin/vehicles/update', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(guid: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/vehicles/delete',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )

      return await VehicleAdminApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
