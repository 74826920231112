import queryString from 'query-string'

import { AdmCashCassetteType } from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class CassetteTypesApi {
  private static requestService = getRequestServiceCM()

  public static async get(sort: Sort): Promise<AdmCashCassetteType[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/cashCassetteTypes',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )
      return await CassetteTypesApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async add(cassetteTypes: AdmCashCassetteType): Promise<void> {
    try {
      return await CassetteTypesApi.requestService.post(
        '/admin/cashCassetteTypes/add',
        cassetteTypes,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async update(cassetteTypes: AdmCashCassetteType): Promise<void> {
    try {
      return await CassetteTypesApi.requestService.put(
        '/admin/cashCassetteTypes/update',
        cassetteTypes,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async delete(id: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/cashCassetteTypes/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )
      return await CassetteTypesApi.requestService.delete(url)
    } catch (e) {
      throw new Error(e)
    }
  }
}
