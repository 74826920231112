import React, { ReactElement, useState, useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import clsx from 'clsx'
import CreateTicketsModal from '@/components/wrappers/AppNavigation/components/Notifications/TicketsModal'
import { setTicketOverviewData } from '@/store/srm/tickets/actions'
import { NavigationContext } from '@/context'
import { SRM_BLOCK_PREFIX, AppNavigationRoutes } from '@/constants'

import { STATUSES_NAME } from '@/constants/ticketOverview'
import { useClasses } from './styles'
import { Props } from './types'

const NotificationsItem = ({
  name,
  date,
  description,
  children,
  newNotification = false,
}: Props): ReactElement => {
  const [isOpenTicketsModal, setIsOpenTicketsModal] = useState<boolean>(false)

  const classes = useClasses({ newNotification })
  const dispatch = useDispatch()
  const { handleAddTab } = useContext(NavigationContext)

  const toggleTicketsModal = useCallback(() => setIsOpenTicketsModal(!isOpenTicketsModal), [
    isOpenTicketsModal,
  ])

  const handleClick = useCallback(() => {
    if (children.length === 1) {
      dispatch(
        setTicketOverviewData({
          name: 'TICK-2002157',
          status: STATUSES_NAME.planned,
        }),
      )

      if (handleAddTab) {
        handleAddTab(
          `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.TicketOverviewPage}/TICK-2002157`,
          'TICK-2002157',
          false,
        )()
      }
    } else {
      toggleTicketsModal()
    }
  }, [children.length, toggleTicketsModal])

  return (
    <>
      <Grid
        onClick={handleClick}
        className={clsx(classes.item, newNotification ? classes.active : null)}
      >
        <Grid className={classes.title}>
          <span className={classes.name}>{name}</span>
          <span className={classes.date}>{moment(date).format('MMM DD hh:mm')}</span>
        </Grid>
        <Grid className={classes.description}>
          <span>{description}</span>
        </Grid>
      </Grid>
      {isOpenTicketsModal && (
        <CreateTicketsModal
          isOpen={isOpenTicketsModal}
          data={children}
          onClose={toggleTicketsModal}
        />
      )}
    </>
  )
}

export default NotificationsItem
