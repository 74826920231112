import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  wrapper: {
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    color: palette.primary.main,
    backgroundColor: palette.primary.light,
    cursor: 'pointer',
  },
  inactiveHeader: {
    borderTop: `1px solid ${palette.grey[100]}`,
    color: 'unset',
    backgroundColor: 'unset',
  },
  disabledHeader: {
    color: palette.grey['300'],
    backgroundColor: palette.primary.light,
  },
  defaultCursor: {
    cursor: 'default',
  },
  title: {
    marginBottom: 0,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
    marginTop: spacing(2),
  },
  nextButton: {
    marginLeft: spacing(2),
    marginTop: spacing(2),
  },
  serviceTypeSelect: {
    paddingRight: spacing(2),
    marginTop: spacing(2),
  },
}))
