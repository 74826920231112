import { TableContainer } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import MuiTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import clsx from 'clsx'
import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react'

import Loader from '@/components/blocks/Loader'
import TablePagination from '@/components/controls/TableComponents/TablePagination'

import TableHeader from './Components/TableHeader/TableHeader'
import { TableItemRow } from './Components/TableItemRow'
import { useClasses } from './styles'
import { defaultSortHiddenIcons, Props } from './types'

const UITable: FC<Props> = React.memo(
  ({
    isCheckboxTable = false,
    isHeaderCheckboxSelected,
    isHeaderCheckboxDisabled,
    isFetching = false,
    isCutRow,
    isFlatTable,
    isLoadingPagination,
    isAlwaysWithControls,
    isOnlyDirectionPagination,

    className,
    selectedRowId,
    hiddenSortIcons = [],
    columns,
    data,
    pagination,
    controls,
    iconCellFields,
    CellJSX,
    sorting,
    multiSelectedRows,
    rowIdKey = 'id',
    collapse,

    setCollapse,
    setColumns,
    onRowClick,
    changePageSize,
    changeCurrentPage,
    handleDoubleClickRow,
    onSort,
    onHeaderCheckboxClick,
    getUpdateRequest,
    keyLabel,
    updateData,
  }: Props): ReactElement => {
    const classes = useClasses({ isFlatTable })

    const [collapseLocal, setCollapseLocal] = useState('')

    useEffect(() => {
      setCollapseLocal(collapse || '')
    }, [collapse])

    const changePage = useCallback(
      (page: number) => {
        changeCurrentPage && changeCurrentPage(page)
      },
      [changeCurrentPage],
    )

    const changeRowsPerPage = useCallback(
      (size: number) => {
        changePageSize && changePageSize(size)
      },
      [changePageSize],
    )

    const tableBody = data?.map((row, index) => (
      <React.Fragment key={row?.[rowIdKey]}>
        <TableItemRow
          isCheckboxTable={isCheckboxTable}
          isCutRow={isCutRow}
          selectedRowId={selectedRowId}
          index={index}
          collapse={collapseLocal}
          row={row}
          headers={columns}
          multiSelectedRows={multiSelectedRows}
          rowIdKey={rowIdKey}
          iconCellFields={iconCellFields}
          CellJSX={CellJSX}
          handleRowClick={onRowClick}
          handleDoubleClickRow={handleDoubleClickRow}
          setCollapse={item => {
            setCollapse?.(item) || setCollapseLocal(item)
          }}
          getUpdateRequest={getUpdateRequest}
          updateData={updateData}
          sorting={sorting}
        />

        {collapseLocal === row?.[rowIdKey || 'id'] &&
          row?.childItems?.map((childRow: any, childIndex: number) => (
            <TableItemRow
              rowIdKey={rowIdKey}
              selectedRowId={selectedRowId}
              index={childIndex}
              parentIndex={index}
              row={childRow}
              headers={columns}
              CellJSX={CellJSX}
              handleRowClick={onRowClick}
              handleDoubleClickRow={handleDoubleClickRow}
              getUpdateRequest={getUpdateRequest}
            />
          ))}
      </React.Fragment>
    ))

    const isHideSortIcon = useCallback(
      (keyLabel: string): boolean => {
        const hiddenIcons = [...defaultSortHiddenIcons.common, ...hiddenSortIcons]
        return hiddenIcons.includes(keyLabel)
      },
      [defaultSortHiddenIcons, hiddenSortIcons],
    )

    const handleSortDevices = useCallback(
      (order: string) => {
        const isVisibleIcon = !isHideSortIcon(order)
        if (isVisibleIcon) onSort && onSort(order)
      },
      [onSort, isHideSortIcon],
    )

    return (
      <div className={classes.wrap}>
        {isFetching && (
          <div className={classes.loaderWrapper}>
            <Loader />
          </div>
        )}
        <div className={clsx(classes.root, className)}>
          <TableContainer component={Paper} className={classes.table}>
            <MuiTable stickyHeader className={classes.table} aria-label="caption table">
              <TableHeader
                isCheckboxTable={isCheckboxTable}
                isHeaderCheckboxDisabled={isHeaderCheckboxDisabled}
                isHeaderCheckboxSelected={isHeaderCheckboxSelected}
                isHideSortIcon={isHideSortIcon}
                keyName="fieldLabel"
                keyLabel={keyLabel || 'fieldName'}
                headers={columns}
                setColumns={setColumns}
                handleSort={handleSortDevices}
                sorting={sorting}
                onHeaderCheckboxClick={onHeaderCheckboxClick}
              />
              <TableBody>{tableBody}</TableBody>
            </MuiTable>
          </TableContainer>

          {(!!data?.length || (pagination?.pageNumber || 0) > 1) && pagination ? (
            <TablePagination
              isOnlyDirectionPagination={isOnlyDirectionPagination}
              isCheckboxTable={isCheckboxTable}
              isLoadingPagination={isLoadingPagination}
              totalPage={pagination.totalPages}
              elementsCount={data?.length}
              selectableTable
              page={pagination.pageNumber}
              rowsPerPage={pagination.pageSize}
              ticketsTotalCount={pagination.totalElements}
              changePage={changePage}
              changeRowsPerPage={changeRowsPerPage}
              rowsPerPageOptions={[5, 10, 15, 25, 50, 100, 300]}
              countPageWithEmptyData={!data?.length ? 1 : undefined}
              controls={controls}
            />
          ) : (
            isAlwaysWithControls && <div className={classes.controls}>{controls}</div>
          )}
        </div>
      </div>
    )
  },
)

UITable.displayName = 'UITable'

export { UITable }
