import { FormikValues } from 'formik'
import { AnyAction } from 'redux'

import { Device, Terminal } from '@/api/common/deviceApi/types'
import { CompanyType, Country, ServiceCompany } from '@/api/companies/companiesApi/types'
import {
  CreatingPOSTicketFormConfig,
  IexistingMerchants,
  POSServiceFormParameter,
  SaveTicketData,
  SaveTicketResponse,
  TicketInfoFormData,
} from '@/api/sd/posApi/types'
import { POSServiceTypeItem } from '@/components/blocks/POSRequestForm/types'
import { AuthorizationActions, companiesActions } from '@/constants'
import { ModalsActions } from '@/constants/actions/modals'
import { City, TicketsConfigData, TicketsConfigResponse, Unit } from '@/types'

export interface State {
  createPosTicketsModalIsOpen: boolean
  documentInformationModalIsOpen: boolean
  units: Unit[]
  unitsAdvanced: Device[]
  terminals: Terminal[]
  unitAdvancedSearchFormConfig: TicketsConfigData
  createPOSTicketFormConfig: CreatingPOSTicketFormConfig
  serviceCompanies: ServiceCompany[]
  companyTypes: CompanyType[]
  POSServiceTypeFormConfig: any
  POSMerchantFormConfig: any
  saveTicketResponse: SaveTicketResponse
  isSavingPOSTicket: boolean
  isSavedSuccessful: boolean
  editingPosTicketInfo: { [key: string]: string | number | null }
  ticketInfoFormData: Partial<TicketInfoFormData>
  serviceTypeItems: POSServiceTypeItem[]
  notSavedPOSServiceRequestEditIndex: number | null
  notSavedPOSServiceRequests: {
    formValues: FormikValues
    formParameters: POSServiceFormParameter
  }[]
  isAddingTicket: boolean
  isFetchingValues: boolean
  isFetchingPSRTicketDetails: boolean
  isLookingForUnits: boolean
  isLookingForServiceCompanies: boolean
  isLookingForUnitsAdvancedSearchConfig: boolean
  isLookingForCreatePOSTicketFormConfig: boolean
  isLookingForCreatePOSEditTicketFormConfig: boolean
  isLookingForCreatePOSTicketServiceTypeFormConfig: boolean
  isLookingForCreatePOSTicketNewMerchantFormConfig: boolean
  isAddingPOSServiceTypeAction: boolean
  isEditingPOSServiceTypeAction: boolean
  isGettingTerminalsByMerchantAndService: boolean
  isDeletingPosRequestItem: boolean
  isDeletingPosTicket: boolean
  isSendingPosTicket: boolean
  isTicketHasDeletedSuccessful: boolean
  isTicketHasSentSuccessful: boolean
  emptyAdvancedSearchShowMessage: boolean
  existingMerchants: IexistingMerchants
  isLookingForExistingMerchants: boolean
  error: string | null
}

export const initialState: State = {
  createPosTicketsModalIsOpen: false,
  documentInformationModalIsOpen: false,
  units: [],
  terminals: [],
  unitAdvancedSearchFormConfig: {
    formUID: '',
    getConfigURL: '',
    getOutTreeURL: '',
    getOutDetailsURL: '',
    filter: [],
    groups: [],
    outputFields: [],
  },
  createPOSTicketFormConfig: {
    serviceCompanies: [],
    serviceTypes: [],
    merchants: [],
    initialParameters: {},
    grantedOperations: [],
  },
  saveTicketResponse: {
    grantedOperations: [],
    saveTicketData: {},
  },
  isSavingPOSTicket: false,
  isSavedSuccessful: false,
  ticketInfoFormData: {},
  editingPosTicketInfo: {},
  serviceTypeItems: [],
  serviceCompanies: [],
  companyTypes: [],
  unitsAdvanced: [],
  notSavedPOSServiceRequestEditIndex: null,
  notSavedPOSServiceRequests: [],
  POSServiceTypeFormConfig: {},
  POSMerchantFormConfig: {},
  isAddingTicket: false,
  isLookingForUnits: false,
  isFetchingValues: false,
  isFetchingPSRTicketDetails: false,
  isLookingForServiceCompanies: false,
  isLookingForUnitsAdvancedSearchConfig: false,
  isLookingForCreatePOSTicketFormConfig: false,
  isLookingForCreatePOSEditTicketFormConfig: false,
  isLookingForCreatePOSTicketServiceTypeFormConfig: false,
  isLookingForCreatePOSTicketNewMerchantFormConfig: false,
  isAddingPOSServiceTypeAction: false,
  isEditingPOSServiceTypeAction: false,
  isDeletingPosRequestItem: false,
  isDeletingPosTicket: false,
  isSendingPosTicket: false,
  isTicketHasDeletedSuccessful: false,
  isTicketHasSentSuccessful: false,
  isGettingTerminalsByMerchantAndService: false,
  emptyAdvancedSearchShowMessage: false,
  existingMerchants: {} as IexistingMerchants,
  isLookingForExistingMerchants: false,
  error: null,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case ModalsActions.OpenCreatePOSTicketsModal:
      return {
        ...state,
        createPosTicketsModalIsOpen: true,
      }
    case ModalsActions.CloseCreatePOSTicketsModal:
      return {
        ...state,
        createPosTicketsModalIsOpen: false,
      }
    case ModalsActions.OpenDocumentInformationModal:
      return {
        ...state,
        documentInformationModalIsOpen: true,
      }
    case ModalsActions.CloseDocumentInformationModal:
      return {
        ...state,
        documentInformationModalIsOpen: false,
      }
    case companiesActions.FindUnitsRequest:
      return {
        ...state,
        isLookingForUnits: true,
      }
    case companiesActions.FindUnitsResponse:
      return {
        ...state,
        units: payload.units,
        isLookingForUnits: false,
      }
    case companiesActions.FindUnitsResponseFail:
      return {
        ...state,
        error: payload,
        isLookingForUnits: false,
      }
    // case companiesActions.FindUnitsAdvancedRequest:
    //   return {
    //     ...state,
    //     isLookingForUnits: true,
    //   }
    // case companiesActions.FindUnitsAdvancedResponse:
    //   return {
    //     ...state,
    //     unitsAdvanced: payload,
    //     isLookingForUnits: false,
    //     emptyAdvancedSearchShowMessage: !payload.length,
    //   }
    // case companiesActions.FindUnitsAdvancedResponseFail:
    //   return {
    //     ...state,
    //     error: payload,
    //     isLookingForUnits: false,
    //   }

    // case companiesActions.AddTicketRequest:
    //   return {
    //     ...state,
    //     isAddingTicket: true,
    //   }
    // case companiesActions.AddTicketResponse:
    //   return {
    //     ...state,
    //     isAddingTicket: false,
    //   }
    // case companiesActions.AddTicketResponseFail:
    //   return {
    //     ...state,
    //     isAddingTicket: false,
    //     error: payload,
    //   }
    // case companiesActions.FindUnitsByMerchandAndServiceRequest:
    //   return {
    //     ...state,
    //     isGettingTerminalsByMerchantAndService: true,
    //     isLookingForUnits: true,
    //   }
    // case companiesActions.FindUnitsByMerchandAndServiceResponse:
    //   return {
    //     ...state,
    //     terminals: payload,
    //     isGettingTerminalsByMerchantAndService: false,
    //     isLookingForUnits: false,
    //   }
    // case companiesActions.FindUnitsByMerchandAndServiceResponseFail:
    //   return {
    //     ...state,
    //     isGettingTerminalsByMerchantAndService: false,
    //     isLookingForUnits: false,
    //     error: payload,
    //   }
    case companiesActions.GetServiceCompanyRequest:
      return {
        ...state,
        isLookingForServiceCompanies: true,
      }
    case companiesActions.GetServiceCompanyResponse:
      return {
        ...state,
        isLookingForServiceCompanies: false,
        serviceCompanies: payload,
      }
    case companiesActions.GetServiceCompanyeFail:
      return {
        ...state,
        isLookingForServiceCompanies: false,
        error: payload,
      }
    // case companiesActions.GetAdvancedUnitSearchConfigurationRequest:
    //   return {
    //     ...state,
    //     isLookingForUnitsAdvancedSearchConfig: true,
    //   }
    // case companiesActions.GetAdvancedUnitSearchConfigurationResponce:
    //   return {
    //     ...state,
    //     unitAdvancedSearchFormConfig: payload,
    //     isLookingForUnitsAdvancedSearchConfig: false,
    //   }
    // case companiesActions.GetAdvancedUnitSearchConfigurationFail:
    //   return {
    //     ...state,
    //     error: payload,
    //     isLookingForUnitsAdvancedSearchConfig: false,
    //   }
    // case companiesActions.GetCreatingPOSFormConfigRequest:
    //   return {
    //     ...state,
    //     editingPosTicketInfo: {},
    //     saveTicketResponse: initialState.saveTicketResponse,
    //     serviceTypeItems: [],
    //     isLookingForCreatePOSTicketFormConfig: true,
    //   }
    // case companiesActions.GetCreatingPOSFormConfigResponse:
    //   return {
    //     ...state,
    //     createPOSTicketFormConfig: payload,
    //     isLookingForCreatePOSTicketFormConfig: false,
    //   }
    // case companiesActions.GetCreatingPOSFormConfigFail:
    //   return {
    //     ...state,
    //     error: payload,
    //     isLookingForCreatePOSTicketFormConfig: false,
    //   }
    // case companiesActions.GetCreatingPOSEditFormConfigRequest:
    //   return {
    //     ...state,
    //     saveTicketResponse: initialState.saveTicketResponse,
    //     isLookingForCreatePOSEditTicketFormConfig: true,
    //   }
    // case companiesActions.GetCreatingPOSEditFormConfigResponse:
    //   return {
    //     ...state,
    //     createPOSTicketFormConfig: {
    //       ...state.createPOSTicketFormConfig,
    //       serviceCompanies: payload.serviceCompanies,
    //       serviceTypes: payload.serviceTypes,
    //       merchants: payload.merchants,
    //     },
    //     editingPosTicketInfo: payload.ticketInfo,
    //     serviceTypeItems: payload.requestItems,
    //     isLookingForCreatePOSEditTicketFormConfig: false,
    //   }
    // case companiesActions.GetCreatingPOSEditFormConfigFail:
    //   return {
    //     ...state,
    //     error: payload,
    //     isLookingForCreatePOSEditTicketFormConfig: false,
    //   }
    // case companiesActions.ClearPOSEditFormConfig:
    //   return {
    //     ...state,
    //     editingPosTicketInfo: initialState.editingPosTicketInfo,
    //   }
    // case companiesActions.AddPOSServiceTypeActionRequest:
    //   return {
    //     ...state,
    //     isAddingPOSServiceTypeAction: true,
    //   }
    // case companiesActions.AddPOSServiceTypeActionResponse:
    //   return {
    //     ...state,
    //     isAddingPOSServiceTypeAction: false,
    //   }
    // case companiesActions.AddPOSServiceTypeActionFail:
    //   return {
    //     ...state,
    //     error: payload,
    //     isAddingPOSServiceTypeAction: false,
    //   }
    // case companiesActions.EditPOSServiceTypeActionRequest:
    //   return {
    //     ...state,
    //     isAddingPOSServiceTypeAction: true,
    //   }
    // case companiesActions.EditPOSServiceTypeActionResponse:
    //   return {
    //     ...state,
    //     isAddingPOSServiceTypeAction: false,
    //   }
    // case companiesActions.EditPOSServiceTypeActionFail:
    //   return {
    //     ...state,
    //     error: payload,
    //     isAddingPOSServiceTypeAction: false,
    //   }
    // case companiesActions.GetPOSServiceFormConfigRequest:
    //   return {
    //     ...state,
    //     isLookingForCreatePOSTicketServiceTypeFormConfig: true,
    //   }
    // case companiesActions.GetPOSServiceFormConfigResponse:
    //   return {
    //     ...state,
    //     POSServiceTypeFormConfig: payload,
    //     isLookingForCreatePOSTicketServiceTypeFormConfig: false,
    //   }
    // case companiesActions.GetPOSServiceFormConfigFail:
    //   return {
    //     ...state,
    //     error: payload,
    //     isLookingForCreatePOSTicketServiceTypeFormConfig: false,
    //   }
    // case companiesActions.GetMerchantConfigRequest:
    //   return {
    //     ...state,
    //     isLookingForCreatePOSTicketNewMerchantFormConfig: true,
    //   }
    // case companiesActions.GetMerchantConfigResponse:
    //   return {
    //     ...state,
    //     POSMerchantFormConfig: payload,
    //     isLookingForCreatePOSTicketNewMerchantFormConfig: false,
    //   }
    // case companiesActions.GetMerchantConfigFail:
    //   return {
    //     ...state,
    //     error: payload,
    //     isLookingForCreatePOSTicketNewMerchantFormConfig: false,
    //   }
    case companiesActions.setInitialAdvancedUnits:
      return {
        ...state,
        unitsAdvanced: [],
        units: [],
        emptyAdvancedSearchShowMessage: false,
      }
    case companiesActions.setInitialServiceCompanies:
      return {
        ...state,
        serviceCompanies: [],
      }

    // case companiesActions.SavePosTicketRequest:
    //   return {
    //     ...state,
    //     isSavedSuccessful: false,
    //     isTicketHasDeletedSuccessful: false,
    //     isSavingPOSTicket: true,
    //     error: null,
    //   }
    // case companiesActions.SavePosTicketResponse:
    //   return {
    //     ...state,
    //     isSavingPOSTicket: false,
    //     saveTicketResponse: {
    //       grantedOperations: payload.grantedOperations,
    //       saveTicketData: {
    //         ...payload.saveTicketData,
    //         documentId: payload.saveTicketData.documentid,
    //       },
    //     },
    //   }
    // case companiesActions.SetIsSavePOSTicketSuccessfull:
    //   return {
    //     ...state,
    //     isSavedSuccessful: payload,
    //   }
    // case companiesActions.SavePosTicketFail:
    //   return {
    //     ...state,
    //     isSavingPOSTicket: false,
    //     error: payload,
    //   }
    // case companiesActions.SavePosTicketSetInitial:
    //   return {
    //     ...state,
    //     saveTicketResponse: initialState.saveTicketResponse,
    //     isSavingPOSTicket: false,
    //     isSavedSuccessful: false,
    //   }
    // case companiesActions.GetTicketInfoFormDataRequest:
    //   return {
    //     ...state,
    //     isFetchingPSRTicketDetails: true,
    //   }
    // case companiesActions.GetTicketInfoFormDataResponse:
    //   return {
    //     ...state,
    //     isFetchingPSRTicketDetails: false,
    //     ticketInfoFormData: payload,
    //   }
    // case companiesActions.GetTicketInfoFormDataFail:
    //   return {
    //     ...state,
    //     isFetchingPSRTicketDetails: false,
    //     error: payload,
    //   }
    // case companiesActions.DeletePosRequestItemRequest:
    //   return {
    //     ...state,
    //     isDeletingPosRequestItem: true,
    //   }
    // case companiesActions.DeletePosRequestItemResponse:
    //   return {
    //     ...state,
    //     isDeletingPosRequestItem: false,
    //   }
    // case companiesActions.DeletePosRequestItemFail:
    //   return {
    //     ...state,
    //     isDeletingPosRequestItem: false,
    //     error: payload,
    //   }
    // case companiesActions.EditPosTicketRequestRequest:
    //   return {
    //     ...state,
    //     isSavedSuccessful: false,
    //     isSavingPOSTicket: true,
    //     isTicketHasDeletedSuccessful: false,
    //     error: null,
    //   }
    // case companiesActions.EditPosTicketRequestResponse:
    //   return {
    //     ...state,
    //     isSavedSuccessful: true,
    //     isSavingPOSTicket: false,
    //     saveTicketResponse: {
    //       grantedOperations: payload.grantedOperations,
    //       saveTicketData: {
    //         ...payload.saveTicketData,
    //         documentId: payload.saveTicketData.documentid,
    //       },
    //     },
    //   }
    // case companiesActions.EditPosTicketRequestFail:
    //   return {
    //     ...state,
    //     isSavingPOSTicket: false,
    //     error: payload,
    //   }
    // case companiesActions.DeletePosTicketRequest:
    //   return {
    //     ...state,
    //     isDeletingPosTicket: true,
    //     isTicketHasDeletedSuccessful: false,
    //     error: null,
    //   }
    // case companiesActions.DeletePosTicketResponse:
    //   return {
    //     ...state,
    //     isDeletingPosTicket: false,
    //     isTicketHasDeletedSuccessful: true,
    //   }
    // case companiesActions.DeletePosTicketFail:
    //   return {
    //     ...state,
    //     isDeletingPosTicket: false,
    //     isTicketHasDeletedSuccessful: false,
    //     error: payload,
    //   }
    // case companiesActions.SetTicketId:
    //   return {
    //     ...state,
    //     saveTicketResponse: {
    //       ...state.saveTicketResponse,
    //       saveTicketData: { ...state.saveTicketResponse.saveTicketData, documentId: payload },
    //     },
    //   }
    // case companiesActions.SetIsTicketHasDeletedSuccessful:
    //   return {
    //     ...state,
    //     isTicketHasDeletedSuccessful: payload,
    //   }
    // case companiesActions.GetExistingMerchantsRequest:
    //   return {
    //     ...state,
    //     isLookingForExistingMerchants: true,
    //   }
    // case companiesActions.GetExistingMerchantsResponse:
    //   return {
    //     ...state,
    //     isLookingForExistingMerchants: false,
    //     existingMerchants: payload,
    //   }
    // case companiesActions.GetExistingMerchantsResponseFall:
    //   return {
    //     ...state,
    //     isLookingForExistingMerchants: false,
    //     error: payload,
    //   }
    // case companiesActions.SetNotSavedPOSServiceRequestEditIndex:
    //   return {
    //     ...state,
    //     notSavedPOSServiceRequestEditIndex: payload,
    //   }
    // case companiesActions.EditNotSavedPOSServiceRequestByIndex: {
    //   const updatedNotSavedPOSServiceRequests = state.notSavedPOSServiceRequests.map(
    //     (req: any, index: number) =>
    //       index !== payload.index
    //         ? req
    //         : { formValues: payload.formValues, formParameters: payload.formParameters },
    //   )
    //   return {
    //     ...state,
    //     notSavedPOSServiceRequests: updatedNotSavedPOSServiceRequests,
    //   }
    // }
    // case companiesActions.PutNotSavedPOSServiceRequest:
    //   return {
    //     ...state,
    //     notSavedPOSServiceRequests: [...state.notSavedPOSServiceRequests, payload],
    //   }
    // case companiesActions.PutNotSavedPosServicesArray: {
    //   const incomingTerminals = payload.terminals.map((t: any) => ({
    //     formValues: t,
    //     formParameters: payload.formParameters,
    //   }))
    //   return {
    //     ...state,
    //     notSavedPOSServiceRequests: [...state.notSavedPOSServiceRequests, ...incomingTerminals],
    //   }
    // }
    // case companiesActions.ResetPOSServicesFromUI:
    //   return {
    //     ...state,
    //     notSavedPOSServiceRequests: payload,
    //   }
    // case companiesActions.ClearNotSavedPOSServiceRequest:
    //   return {
    //     ...state,
    //     notSavedPOSServiceRequests: [],
    //   }
    // case companiesActions.SendPosTicketRequestRequest:
    //   return {
    //     ...state,
    //     isSendingPosTicket: true,
    //     isTicketHasSentSuccessful: false,
    //   }
    // case companiesActions.SendPosTicketRequestResponse:
    //   return {
    //     ...state,
    //     isSendingPosTicket: false,
    //     isTicketHasSentSuccessful: true,
    //   }
    // case companiesActions.SendPosTicketRequestFail:
    //   return {
    //     ...state,
    //     isSendingPosTicket: false,
    //     isTicketHasSentSuccessful: false,
    //     error: payload,
    //   }
    // case companiesActions.SetIsTicketSentSuccessfull:
    //   return {
    //     ...state,
    //     isTicketHasSentSuccessful: payload,
    //   }
    default:
      return state
  }
}
