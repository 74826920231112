import Container from '@material-ui/core/Container'
import { debounce } from 'lodash-es'
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'

import ContextMenu from '@/components/controls/ContextMenu/index'
import { TextInput } from '@/components/controls/TextInput/component'
import { useTranslate } from '@/utils/internalization'

import { Props } from './types'

const TextFilter = ({
  id,
  isOpen,
  currentSelected,
  anchorEl,
  handleClose,
  handleSave,
}: Props): React.ReactElement => {
  const [textInputValue, setTextInputValue] = useState<string>(currentSelected)

  const translate = useTranslate()

  useEffect((): void => {
    setTextInputValue(currentSelected)
  }, [currentSelected])

  const handleSaveTextInputFilter = debounce((value: string): void => {
    handleSave(id, value)
  }, 1000)

  const handleTextInputValueChange = useCallback(
    (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      setTextInputValue(e.currentTarget.value)
      handleSaveTextInputFilter(e.currentTarget.value)
    },
    [handleSave],
  )

  const handleCancelClick = useCallback((): void => {
    setTextInputValue(currentSelected)
    handleClose()
  }, [currentSelected, handleClose])

  return (
    <ContextMenu
      transitionDuration={0}
      open={isOpen}
      anchorEl={anchorEl}
      disableRipple
      options={[]}
      handleClose={handleCancelClick}
    >
      <Container>
        {isOpen ? ( // mount to trigger autofocus
          <TextInput
            autoFocus
            value={textInputValue}
            onChange={handleTextInputValueChange}
            placeholder={translate('translate#title.search')}
            clearButton
          />
        ) : (
          <></>
        )}
      </Container>
    </ContextMenu>
  )
}

export default TextFilter
