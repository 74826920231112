import { Box } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import moment from 'moment'
import React, { ReactElement, useState } from 'react'
import { useSelector } from 'react-redux'

import { AvailableModule } from '@/api/atmeye/common/types'
import { LocalStorageItems } from '@/constants'
import { getAvailableModules } from '@/store/atmeye/common'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'

export const HeaderBanner = (): ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const availableModules = useSelector(getAvailableModules)

  const shouldHideExpireLicenceBanner = localStorage.getItem(
    LocalStorageItems.ShouldHideExpireLicenceBanner,
  )
  const [isVisible, setIsVisible] = useState(!shouldHideExpireLicenceBanner)

  let firstModuleExpire: any = null
  const now = moment()
  availableModules &&
    Object.keys(availableModules).forEach(key => {
      const modul = availableModules[key]
      const daysToExpire = moment(modul.expirationDate).diff(now, 'd')
      if (daysToExpire < 360) {
        if (!firstModuleExpire) firstModuleExpire = { ...modul, daysLeft: daysToExpire }
        if (firstModuleExpire?.daysLeft > daysToExpire) {
          firstModuleExpire = { ...modul, daysLeft: daysToExpire }
        }
      }
    })

  const daysLeft = firstModuleExpire?.daysLeft || 0

  return (
    (daysLeft && (isVisible || daysLeft < 7) && (
      <div
        className={clsx(classes.itemContainer, {
          [classes.notificationColors]: daysLeft < 180,
          [classes.warningColors]: daysLeft < 30,
          [classes.alarmColors]: daysLeft < 7,
        })}
      >
        {daysLeft >= 7 && (
          <CloseIcon
            className={classes.closeButton}
            onClick={() => {
              localStorage.setItem(LocalStorageItems.ShouldHideExpireLicenceBanner, 'true')
              setIsVisible(false)
            }}
          />
        )}

        <Box> {translate('translate#atmeye.licence.expireBanner', { daysLeft })}</Box>
      </div>
    )) || <></>
  )
}
