import Grid from '@material-ui/core/Grid'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Form, Formik, FormikProps } from 'formik'
import React, { ReactElement, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import CloseButton from '@/components/blocks/CloseButton'
import Loader from '@/components/blocks/Loader'
import Button from '@/components/controls/Button'
import { usePreviousValue } from '@/utils/hooks/hooks'

import { useClasses } from './styles'
import { Props } from './types'

const MultiStepHookForm = ({
  handleSubmit,
  initialValues,
  steps,
  onClose,
  validateFinish,
  response,
}: Props): ReactElement => {
  const prevIsLoading = usePreviousValue(response?.isLoading)

  const [step, setStep] = useState<number>(0)

  const classes = useClasses()

  const handleChangeStep = (action: 'next' | 'back') => (): void => {
    setStep(step => (action === 'next' ? ++step : --step))
  }

  useEffect(() => {
    if (response && response.onSuccess && prevIsLoading && !response.isLoading && !response.error) {
      response.onSuccess()
    }
  }, [response?.isLoading])

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formProps: FormikProps<any>): React.ReactElement => {
          return (
            <>
              <CloseButton onClick={onClose} absolute />
              <Form className={classes.form}>
                {steps.length ? steps[step].render(formProps) : null}
                <Grid className={classes.grid}>
                  <Grid className={classes.buttonWrapper}>
                    <Button
                      variant="outlined"
                      width="sm"
                      onClick={onClose}
                      className={classes.button}
                    >
                      <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
                    </Button>
                  </Grid>
                  <Grid className={classes.buttonWrapper}>
                    <Button
                      variant="outlined"
                      width="sm"
                      startIcon={<ChevronLeftIcon />}
                      onClick={handleChangeStep('back')}
                      disabled={!step}
                      className={classes.button}
                    >
                      <FormattedMessage id="action.back" defaultMessage="Back" />
                    </Button>
                  </Grid>
                  <Grid className={classes.buttonWrapper}>
                    <Button
                      variant="contained"
                      width="sm"
                      endIcon={<ChevronRightIcon />}
                      onClick={handleChangeStep('next')}
                      disabled={
                        (steps.length && steps[step].validateNext
                          ? !(steps[step].validateNext as (values: any) => boolean)(
                              formProps.values,
                            )
                          : false) || step === steps.length - 1
                      }
                      className={classes.button}
                    >
                      <FormattedMessage id="action.next" defaultMessage="Next" />
                    </Button>
                  </Grid>
                  <Grid className={classes.buttonWrapper}>
                    <Button
                      type="submit"
                      variant="contained"
                      width="sm"
                      disabled={!validateFinish(formProps.values)}
                      className={classes.button}
                    >
                      <FormattedMessage id="action.finish" defaultMessage="Finish" />
                    </Button>
                  </Grid>
                </Grid>
              </Form>
              {response?.isLoading && (
                <Grid className={classes.loaderWrapper}>
                  <Loader />
                </Grid>
              )}
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default MultiStepHookForm
