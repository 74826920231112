import makeStyles from '@material-ui/core/styles/makeStyles'

import colors from '@/theme/colors'

export const useClasses = makeStyles(({ spacing, palette, breakpoints }) => ({
  chooseProductTitle: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '20px',
    paddingBottom: spacing(3),
  },

  selectProductWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  selectProductContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },

  productItem: {
    flex: '0 1 auto',
    height: spacing(9),
    padding: spacing(1.5),
    width: '100%',
    cursor: 'pointer',
    '&:not(:last-child)': {
      borderBottom: '1px solid #e8e9e9',
    },

    '&:hover': {
      background: 'rgba(0, 0, 0, 0.02)',
    },
  },
}))
