export const TAB_NAME = {
  ticket: 'ticket',
  newOrder: 'newOrder',
  final: 'final',
  workCost: 'workCost',
  analyze: 'analyze',
  request: 'request',
  description: 'description',
  notes: 'notes',
  fact: 'fact',
  plan: 'plan',
}

export const STATUSES_NAME = {
  created: 'created',
  prepared: 'prepared',
  suspended: 'suspended',
  planned: 'planned',
  submitted: 'submitted',
  running: 'running',
}

export const STATUSES = {
  created: {
    value: 'Created',
    color: '#BDBDBD',
    backgroundColor: '#f7f7f7',
  },
  prepared: {
    value: 'Prepared',
    color: '#F1C40F',
    backgroundColor: '#fefaee',
  },
  suspended: {
    value: 'Suspended',
    color: '#FF274E',
    backgroundColor: '#ffe9ed',
  },
  planned: {
    value: 'Planned',
    color: '#F1C40F',
    backgroundColor: '#fefaee',
  },
  submitted: {
    value: 'Submitted',
    color: '#99BE54',
    backgroundColor: '#f5f9ee',
  },
  running: {
    value: 'Running',
    color: '#2F80ED',
    backgroundColor: 'rgba(47, 128, 237, 0.1)',
  },
}

export const modalsName = {
  suspendRequest: 'suspendRequest',
  closeAsForwardedTo1L: 'closeAsForwardedTo1L',
  continueRequest: 'continueRequest',
  cancelRequest: 'cancelRequest',
  toPlan: 'toPlan',
  submitRequest: 'submitRequest',
  closeRequest: 'closeRequest',
  assignedTo: 'assignedTo',
  itemList: 'itemList',
}
