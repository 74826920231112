import { Button, Grid } from '@material-ui/core'
import { Form, Formik, FormikProps } from 'formik'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import LoadingOverlay from '@/components/blocks/LoadingOverlay'
import { DynamicFieldType } from '@/constants/cm/dynamicFieldTypes'
import { useTranslate } from '@/utils/internalization'

import { DynamicField } from './DynamicField'
import { getInitialFormValues, getInitialTouched } from './helpers'
import { useClasses } from './styles'
import { FormValue, ParentDisabledProps, Props } from './types'
import { validate } from './validate'

export const AppConfig: FC<Props> = React.memo((props: Props) => {
  const {
    appConfig,
    isLoaded = false,
    isLoading = false,
    updateAppConfig,
    openApproveReject,
  } = props

  const classes = useClasses()
  const translate = useTranslate()

  const formRef = useRef<FormikProps<FormValue> | null>(null)

  const [formInitialValues, setInitialFormValues] = useState<FormValue>({})

  useEffect(() => {
    const initialValues = getInitialFormValues(appConfig)

    setInitialFormValues(initialValues)

    if (formRef.current) formRef.current.setValues({ ...initialValues })
  }, [appConfig])

  const handleSubmit = useCallback(
    (values: FormValue) => {
      const updatedAppConfig = appConfig.map(field => {
        if (field.valueType === DynamicFieldType.Radio) {
          const { id, groupInfo, parentFieldId } = field || {}
          const parentFiledValue = values[`${parentFieldId}` || ''] as string | undefined

          if (JSON.parse(parentFiledValue || 'false')) {
            const fieldValue = (values[`${id}` || ''] || '') as string

            const radioGroup = (groupInfo || []).map(radio => ({
              ...radio,
              isSelected: radio.id === Number(fieldValue),
            }))

            return {
              ...field,
              groupInfo: radioGroup,
            }
          }
        }

        return {
          ...field,
          value: values[`${field.id}` || ''] as string | undefined,
        }
      })

      updateAppConfig(updatedAppConfig)
    },
    [appConfig],
  )

  const getIsParentDisabled = useCallback(({ parentFieldId, values }: ParentDisabledProps) => {
    if (!parentFieldId) {
      return false
    }
    const parentFiledValue = values[`${parentFieldId}` || ''] as string | undefined

    return parentFiledValue ? !JSON.parse(parentFiledValue) : true
  }, [])

  //  Ability to split appConfig to the two columns ---------------------

  // const { first, second } = useMemo(() => {
  //   const appConfigCopy = [...appConfig]

  //   if (appConfigCopy.length > 20) {
  //     return {
  //       first: appConfigCopy.slice(0, Math.ceil(appConfigCopy.length / 2)),
  //       second: appConfigCopy.slice(Math.ceil(appConfigCopy.length / 2), appConfigCopy.length),
  //     }
  //   }

  //   return {
  //     first: appConfigCopy,
  //     second: [],
  //   }
  // }, [appConfig])

  return (
    <Formik
      innerRef={formRef}
      validateOnMount
      validateOnChange
      validate={validate(appConfig)}
      enableReinitialize
      initialTouched={getInitialTouched(appConfig)}
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
    >
      {({ values, isValid, setFieldValue }): React.ReactElement => (
        <LoadingOverlay active={isLoading} loaded={isLoaded}>
          <Form className={classes.formContainer} noValidate>
            <div className={classes.list}>
              {appConfig.map(field => (
                <Grid className={classes.field} item key={field.id}>
                  <DynamicField
                    field={field}
                    setFieldValue={setFieldValue}
                    currentValue={
                      values[`${field.id}`] !== undefined
                        ? `${values[`${field.id}`]}`
                        : `${field.value}`
                    }
                    disabled={getIsParentDisabled({ parentFieldId: field.parentFieldId, values })}
                  />
                </Grid>
              ))}

              {/* Ability to split appConfig to the two columns --------------------- */}

              {/* <div>
                {first.map(field => (
                  <Grid className={classes.field} item key={field.id}>
                    <DynamicField
                      field={field}
                      setFieldValue={setFieldValue}
                      currentValue={
                        values[`${field.id}`] !== undefined
                          ? `${values[`${field.id}`]}`
                          : `${field.value}`
                      }
                      disabled={getIsParentDisabled({ parentFieldId: field.parentFieldId, values })}
                    />
                  </Grid>
                ))}
              </div>
              {!!second.length && (
                <div>
                  {second.map(field => (
                    <Grid className={classes.field} item key={field.id}>
                      <DynamicField
                        field={field}
                        setFieldValue={setFieldValue}
                        currentValue={
                          values[`${field.id}`] !== undefined
                            ? `${values[`${field.id}`]}`
                            : `${field.value}`
                        }
                        disabled={getIsParentDisabled({
                          parentFieldId: field.parentFieldId,
                          values,
                        })}
                      />
                    </Grid>
                  ))}
                </div>
              )} */}
            </div>
            <div className={classes.buttonContainer}>
              {openApproveReject && (
                <Button className={classes.button} onClick={openApproveReject} variant="outlined">
                  {translate('translate#cm.ButtonApproveRejectChanges')}
                </Button>
              )}
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                type="submit"
                disabled={!isValid}
              >
                {translate('translate#cm.ButtonApply')}
              </Button>
            </div>
          </Form>
        </LoadingOverlay>
      )}
    </Formik>
  )
})
