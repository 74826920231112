import queryString from 'query-string'

import {
  AdmUserActivityReportsRequest,
  AdmUserActivityRequest,
  AdmUserActivityResponse,
  ComCMReport,
} from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

interface GetProps {
  paging: { page: number; pageSize: number }
  filter: AdmUserActivityRequest
  sort: Sort
}

export class UserActivityApi {
  private static requestService = getRequestServiceCM()

  public static async get({ paging, filter, sort }: GetProps): Promise<AdmUserActivityResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/accessRules/userActivity',
          query: { ...paging, ...sort },
        },
        { skipNull: true, encode: false },
      )
      return await UserActivityApi.requestService.post(url, filter)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async getReports(payload: AdmUserActivityReportsRequest): Promise<ComCMReport[]> {
    try {
      return await UserActivityApi.requestService.post(
        '/admin/accessRules/userActivity/reports',
        payload,
      )
    } catch (error) {
      throw Error(String(error as Error))
    }
  }
}
