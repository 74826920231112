import queryString from 'query-string'

import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

import {
  BagCassetteWithStamp,
  CassettesValidationRequest,
  CassettesValidationResponse,
  CitWorkOrderButtonsStatuses,
  ComUuidValue,
} from '../swaggerGeneratedApi'

interface GetBagsProps {
  cassetteSetId: string
  vaultId: string
}

interface GetWOButtonsState {
  orderId?: string
  isAllOrders?: boolean
}

export class CitWODetailsApi {
  private static requestService = getRequestServiceCM()

  public static async getBags(payload: GetBagsProps): Promise<ComUuidValue[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'cit/planning/bagsByCassetteSetAndVault',
          query: { ...payload },
        },
        { skipNull: true, encode: false },
      )

      return await CitWODetailsApi.requestService.get(url)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async getBagCassettes(bagId: string): Promise<BagCassetteWithStamp[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'cit/planning/bagCassettes',
          query: { bagId },
        },
        { skipNull: true, encode: false },
      )

      return await CitWODetailsApi.requestService.get(url)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async getWOButtonsState(
    payload: GetWOButtonsState,
  ): Promise<CitWorkOrderButtonsStatuses> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'cit/planning/workorders/buttonsStatuses',
          query: { ...payload },
        },
        { skipNull: true, encode: false },
      )

      return await CitWODetailsApi.requestService.get(url)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async cassetteValidation(
    payload: CassettesValidationRequest,
  ): Promise<CassettesValidationResponse> {
    try {
      return await CitWODetailsApi.requestService.post('/cit/planning/cassettes/validate', payload)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }
}
