import { AnyAction } from 'redux'

import {
  BusinessEntityKey,
  CompanyTreeElement,
  LookupResult,
  QualificationLevel,
  Role,
  SalaryItem,
} from '@/api/companies/companiesApi/types'
import { companiesActions } from '@/constants/actions/companiesActions'
import { AddNewCompanyFormValues } from '@/constants/forms/AddNewCompanyForm'
import { AddNewDepartmentFormValues } from '@/constants/forms/AddNewDepartmentForm'
import { AddNewPersonnelFormValues } from '@/constants/forms/AddNewPersonnelForm'
import { ResponseError, Unit } from '@/types'

export const getCompanyRequest = (companyID: number): AnyAction => ({
  type: companiesActions.GetCompanyRequest,
  payload: { companyID },
})

export const getCompanyResponse = (companyResponse: any): AnyAction => ({
  type: companiesActions.GetCompanyResponse,
  payload: companyResponse,
})

export const addCompanyRequest = (payload: {
  companyData: AddNewCompanyFormValues
  translate: any
  handleSuccess: () => void
}): AnyAction => ({
  type: companiesActions.AddCompanyRequest,
  payload,
})

export const addCompanyResponse = (companyId: number): AnyAction => ({
  type: companiesActions.AddCompanyResponse,
  payload: companyId,
})
export const addCompanyResponseFail = (error: ResponseError): AnyAction => ({
  type: companiesActions.AddCompanyResponseFail,
  payload: error,
})

export const updateCompanyRequest = (payload: {
  companyData: AddNewCompanyFormValues
  translate: any
}): AnyAction => ({
  type: companiesActions.UpdateCompanyRequest,
  payload,
})

export const updateCompanyResponse = (): AnyAction => ({
  type: companiesActions.UpdateCompanyResponse,
})
export const updateCompanyResponseFail = (error: ResponseError): AnyAction => ({
  type: companiesActions.UpdateCompanyResponseFail,
  payload: error,
})

export const deleteCompanyRequestRequest = (companyId: number, translate: any): AnyAction => ({
  type: companiesActions.DeleteCompanyRequest,
  payload: { companyId, translate },
})
export const deleteCompanyRequestResponse = (): AnyAction => ({
  type: companiesActions.DeleteCompanyResponse,
})
export const deleteCompanyRequestFail = (error: ResponseError): AnyAction => ({
  type: companiesActions.DeleteCompanyFail,
  payload: error,
})

export const getDepartmentRequest = (departmentID: number): AnyAction => ({
  type: companiesActions.GetDepartmentRequest,
  payload: { departmentID },
})

export const getDepartmentResponse = (departmentResponse: any): AnyAction => ({
  type: companiesActions.GetDepartmentResponse,
  payload: departmentResponse,
})

export const addDepartmentRequest = (payload: {
  departmentData: AddNewDepartmentFormValues
  translate: any
  handleSuccess?: () => void
}): AnyAction => ({
  type: companiesActions.AddDepartmentRequest,
  payload,
})

export const addDepartmentResponse = (): AnyAction => ({
  type: companiesActions.AddDepartmentResponse,
})
export const addDepartmentResponseFail = (error: ResponseError): AnyAction => ({
  type: companiesActions.AddDepartmentResponseFail,
  payload: error,
})

export const updateDepartmentRequest = (payload: {
  departmentData: AddNewDepartmentFormValues
  translate: any
}): AnyAction => ({
  type: companiesActions.UpdateDepartmentRequest,
  payload,
})

export const deleteDepartmentRequestRequest = (
  departmentId: number,
  translate: any,
): AnyAction => ({
  type: companiesActions.DeleteDepartmentRequest,
  payload: { departmentId, translate },
})
export const deleteDepartmentRequestResponse = (): AnyAction => ({
  type: companiesActions.DeleteDepartmentResponse,
})
export const deleteDepartmentRequestFail = (error: ResponseError): AnyAction => ({
  type: companiesActions.DeleteDepartmentFail,
  payload: error,
})

export const getPersonnelRequest = (personnelID: number): AnyAction => ({
  type: companiesActions.GetPersonnelRequest,
  payload: { personnelID },
})

export const getPersonnelResponse = (personnelResponse: any): AnyAction => ({
  type: companiesActions.GetPersonnelResponse,
  payload: personnelResponse,
})

export const addPersonnelRequest = (payload: {
  personnelData: AddNewPersonnelFormValues
  translate: any
  handleSuccess: () => void
}): AnyAction => ({
  type: companiesActions.AddPersonnelRequest,
  payload,
})

export const addPersonnelResponse = (personnelId: number): AnyAction => ({
  type: companiesActions.AddPersonnelResponse,
  payload: personnelId,
})
export const addPersonnelResponseFail = (error: ResponseError): AnyAction => ({
  type: companiesActions.AddPersonnelResponseFail,
  payload: error,
})

export const updatePersonnelRequest = (payload: {
  personnelData: AddNewPersonnelFormValues
  translate: any
}): AnyAction => ({
  type: companiesActions.UpdatePersonnelRequest,
  payload,
})

export const updatePersonnelResponse = (payload: {
  personnelData: AddNewPersonnelFormValues | null
}): AnyAction => ({
  type: companiesActions.UpdatePersonnelResponse,
  payload,
})
export const updatePersonnelResponseFail = (error: ResponseError): AnyAction => ({
  type: companiesActions.UpdatePersonnelResponseFail,
  payload: error,
})

export const deletePersonnelRequestRequest = (personnelId: number, translate: any): AnyAction => ({
  type: companiesActions.DeletePersonnelRequest,
  payload: { personnelId, translate },
})
export const deletePersonnelRequestResponse = (): AnyAction => ({
  type: companiesActions.DeletePersonnelResponse,
})
export const deletePersonnelRequestFail = (error: ResponseError): AnyAction => ({
  type: companiesActions.DeletePersonnelFail,
  payload: error,
})

export const getPersonnelSalariesRequest = (personnelId: number): AnyAction => ({
  type: companiesActions.GetPersonnelSalariesRequest,
  payload: { personnelId },
})

export const getPersonnelSalariesResponse = (response: SalaryItem[]): AnyAction => ({
  type: companiesActions.GetPersonnelSalariesResponse,
  payload: response,
})

export const getPersonnelRolesHistoryRequest = (personnelId: number): AnyAction => ({
  type: companiesActions.GetPersonnelRolesHistoryRequest,
  payload: { personnelId },
})

export const getPersonnelRolesHistoryResponse = (response: SalaryItem[]): AnyAction => ({
  type: companiesActions.GetPersonnelRolesHistoryResponse,
  payload: response,
})

export const getPersonnelQualificationLevelsRequest = (personnelId: number): AnyAction => ({
  type: companiesActions.GetPersonnelQualificationLevelsRequest,
  payload: { personnelId },
})

export const getPersonnelQualificationLevelsResponse = (
  response: QualificationLevel[],
): AnyAction => ({
  type: companiesActions.GetPersonnelQualificationLevelsResponse,
  payload: response,
})

export const getPersonnelAddressRequest = (personnelId: number): AnyAction => ({
  type: companiesActions.GetPersonnelAddressRequest,
  payload: { personnelId },
})

export const getPersonnelAddressResponse = (response: SalaryItem[]): AnyAction => ({
  type: companiesActions.GetPersonnelAddressResponse,
  payload: response,
})

export const getCompanyTreeBySearchFragmentRequest = (
  searchFragment: string,
  isActual: number,
): AnyAction => ({
  type: companiesActions.GetCompanyTreeBySearchFragmentRequest,
  payload: { searchFragment, isActual },
})

export const getCompanyTreeBySearchFragmentResponse = (companyTreeResponse: any): AnyAction => ({
  type: companiesActions.GetCompanyTreeBySearchFragmentResponse,
  payload: companyTreeResponse,
})

export const getCompanyTreeByCompanyIdRequest = (
  companyId: number,
  isActual: number,
  searchFragment?: string,
): AnyAction => ({
  type: companiesActions.GetCompanyTreeByCompanyIdRequest,
  payload: { companyId, isActual, searchFragment },
})

export const getCompanyTreeByCompanyIdResponse = (companyTreeResponse: any): AnyAction => ({
  type: companiesActions.GetCompanyTreeByCompanyIdResponse,
  payload: companyTreeResponse,
})

export const getRolesRequest = (businessEntityKey: BusinessEntityKey): AnyAction => ({
  type: companiesActions.GetRolesRequest,
  payload: { businessEntityKey },
})

export const getRolesResponse = (response: Role[]): AnyAction => ({
  type: companiesActions.GetRolesResponse,
  payload: response,
})

export const getWorkAreasRequest = (): AnyAction => ({
  type: companiesActions.GetWorkAreasRequest,
})

export const getWorkAreasResponse = (response: LookupResult[]): AnyAction => ({
  type: companiesActions.GetWorkAreasResponse,
  payload: response,
})

export const getQualificationLevelsLookupRequest = (): AnyAction => ({
  type: companiesActions.GetQualificationLevelsLookupRequest,
})

export const getQualificationLevelsLookupResponse = (response: LookupResult[]): AnyAction => ({
  type: companiesActions.GetQualificationLevelsLookupResponse,
  payload: response,
})

export const setSelectedCompany = (selectedCompany: CompanyTreeElement | null): AnyAction => ({
  type: companiesActions.SetSelectedCompany,
  payload: selectedCompany,
})

export const unsetSelectedCompany = (): AnyAction => ({
  type: companiesActions.UnsetSelectedCompany,
  payload: null,
})

export const unsetNewCompanyId = (): AnyAction => ({
  type: companiesActions.UnsetNewCompanyId,
  payload: null,
})

export const clearCompanyTree = (): AnyAction => ({
  type: companiesActions.ClearCompanyTree,
  payload: [],
})

export const setSelectedDepartment = (
  selectedDepartment: CompanyTreeElement | null,
): AnyAction => ({
  type: companiesActions.SetSelectedDepartment,
  payload: selectedDepartment,
})

export const unsetSelectedDepartment = (): AnyAction => ({
  type: companiesActions.UnsetSelectedDepartment,
  payload: null,
})

export const setSelectedPersonnel = (selectedPersonnel: CompanyTreeElement | null): AnyAction => ({
  type: companiesActions.SetSelectedPersonnel,
  payload: selectedPersonnel,
})

export const unsetSelectedPersonnel = (): AnyAction => ({
  type: companiesActions.UnsetSelectedPersonnel,
  payload: null,
})

export const getCompaniesRequest = (): AnyAction => ({
  type: companiesActions.GetCompaniesRequest,
  payload: null,
})

export const getCompaniesResponse = (response: any): AnyAction => ({
  type: companiesActions.GetCompaniesResponse,
  payload: response,
})

export const setInitialUnitsAdvanced = () => ({
  type: companiesActions.setInitialAdvancedUnits,
})
