import Box from '@material-ui/core/Box'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import AddCircle from '@material-ui/icons/AddCircle'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import NoteAdd from '@material-ui/icons/NoteAdd'
import clsx from 'clsx'
import AddUserGroupModalUSM from 'components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/AddUserGroupModal/component'
import React, { SyntheticEvent, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Test from 'react-test-attributes'

import PSRTicketDetails from '@/components/blocks/PSRTicketDetails/component'
import CreatePOSRequestModal from '@/components/blocks/PSRTicketDetails/components/CreatePOSRequestModal/component'
import AccessControl from '@/components/controls/auth/AccessControl'
import Button from '@/components/controls/Button'
import ContextMenu from '@/components/controls/ContextMenu'
// hooks
import { useContextMenuUSM } from '@/components/wrappers/AppNavigation/components/DrawerActions/components/hooks/useContextMenuUSM'
import CreateNewTicketModal from '@/components/wrappers/AppNavigation/components/DrawerActions/components/modals/CreateNewTicketModal/component'
import AddUserModalUSM from '@/components/wrappers/AppNavigation/components/DrawerActionsUSM/modals/AddUserModal/component'
import {
  ADM_BLOCK_PREFIX,
  PRODUCTS_BLOCK_PREFIX,
  SD_BLOCK_PREFIX,
  SRM_BLOCK_PREFIX,
  USM_BLOCK_PREFIX,
} from '@/constants/paths'
import { setInitialUnitsAdvanced } from '@/store/sd/drawerActions/actions'
import { getTestId } from '@/utils'
import { translate } from '@/utils/internalization'

import CreateNewDevice from '../../../../pages/adm/CreateNewDevice'
import AddNewProduct from '../DrawerModals/Products/AddNewProduct'
import { useClasses } from './styles'
import { Props } from './types'

const DrawerActions = ({ showClosed = false, pathname }: Props): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | (EventTarget & HTMLButtonElement)>(null)
  const [openTicketModal, setOpenTicketModal] = React.useState(false)
  const [openPOSModal, setOpenPOSModal] = React.useState(false)
  const [psrDetailsIsOpen, setPsrDetailsIsOpen] = React.useState(false)
  const [drawerActionsUSMIsOpen, setAddUserModalUSMIsOpen] = React.useState(false)
  const [addUserGroupModalUSMIsOpen, setAddUserGroupModalUSMIsOpen] = React.useState(false)
  const [currentTicketId, setCurrentTicketId] = React.useState<number | undefined>()
  const [addNewProductIsOpen, setAddNewProductIsOpen] = React.useState<boolean>(false)
  const [createNewDeviceADMIsOpen, setCreateNewDeviceADMIsOpen] = React.useState<boolean>(false)
  const classes = useClasses()
  const testId = getTestId('drawer-actions')
  const dispatch = useDispatch()

  const handleShowMenu = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>): void => {
      if (pathname.includes(PRODUCTS_BLOCK_PREFIX)) {
        return setAddNewProductIsOpen(true)
      }
      if (pathname.includes(ADM_BLOCK_PREFIX)) {
        return setCreateNewDeviceADMIsOpen(true)
      }

      setAnchorEl(event.currentTarget)
    },
    [pathname],
  )

  const handleCloseAddNewProduct = useCallback(() => {
    setAddNewProductIsOpen(false)
  }, [])

  const handleCloseCreateNewDeviceADM = useCallback(() => {
    setCreateNewDeviceADMIsOpen(false)
  }, [])

  const handleCloseMenu = useCallback((): void => {
    setAnchorEl(null)
  }, [])

  const handleCloseTicket = useCallback((): void => {
    setOpenTicketModal(false)
    dispatch(setInitialUnitsAdvanced())
  }, [])

  const handleAddUserModalUSM = useCallback(
    (val: boolean): (() => void) => (): void => {
      setAddUserModalUSMIsOpen(val)
    },
    [],
  )

  const handleAddUserGroupModalUSM = useCallback(
    (value: boolean): (() => void) => (): void => {
      setAddUserGroupModalUSMIsOpen(value)
    },
    [],
  )

  const handleOpenTicket = useCallback((): void => {
    setOpenTicketModal(true)
    handleCloseMenu()
  }, [handleCloseMenu])

  const handleOpenPOS = useCallback((): void => {
    setOpenPOSModal(true)
    handleCloseMenu()
  }, [handleCloseMenu])

  const handleClosePOS = useCallback((): void => {
    setOpenPOSModal(false)
    dispatch(setInitialUnitsAdvanced())
  }, [dispatch])

  const handleOpenPsrDetails = useCallback((): void => {
    setPsrDetailsIsOpen(true)
  }, [setPsrDetailsIsOpen])

  const handleSetCurrentTicketId = useCallback(
    (ticketId: number): void => {
      setCurrentTicketId(ticketId)
    },
    [setCurrentTicketId],
  )

  const handleClosePsrDetails = useCallback((): void => {
    setPsrDetailsIsOpen(false)
  }, [setPsrDetailsIsOpen])

  useEffect(() => {
    if (showClosed) {
      handleCloseMenu()
    }
  }, [showClosed])

  const { contextMenuUSM, styleAddMenu } = useContextMenuUSM(
    classes.label,
    setAddUserModalUSMIsOpen,
    setAddUserGroupModalUSMIsOpen,
  )

  return (
    <Test id={testId(0)}>
      <div>
        {!pathname.includes(SRM_BLOCK_PREFIX) && (
          <AccessControl
            allowedPermissions={
              pathname.includes(SD_BLOCK_PREFIX)
                ? ['SRVTicket_insert', 'POSCustomerSR_insert']
                : undefined
            }
            renderNoAccess={(): React.ReactElement => <></>}
          >
            <ListItem style={{ padding: '8px 0' }}>
              <Test id={testId(1)}>
                <Button
                  // disabled={disabledCreate()}
                  fullWidth
                  width="xs"
                  variant="contained"
                  onClick={pathname.includes(SRM_BLOCK_PREFIX) ? handleOpenTicket : handleShowMenu}
                  className={clsx(classes.createButton, {
                    [classes.SDcreateButton]: pathname.includes(SD_BLOCK_PREFIX),
                  })}
                >
                  <Box display="flex" alignItems="center" justifySelf="flex-start">
                    {pathname.includes(USM_BLOCK_PREFIX) ||
                    pathname.includes(PRODUCTS_BLOCK_PREFIX) ? (
                      <ControlPointIcon
                        className={clsx(classes.icon, { [classes.iconClosed]: showClosed })}
                      />
                    ) : (
                      <NoteAdd
                        className={clsx(classes.icon, { [classes.iconClosed]: showClosed })}
                      />
                    )}
                  </Box>
                  {!showClosed && (
                    <ListItemText>
                      <Box textAlign="left" className={classes.text}>
                        {pathname.includes(USM_BLOCK_PREFIX)
                          ? translate('translate#usm.action.add.new')
                          : pathname.includes(PRODUCTS_BLOCK_PREFIX)
                          ? translate('translate#prod.addNewProduct')
                          : pathname.includes(ADM_BLOCK_PREFIX)
                          ? translate('translate#adm.createNewDevice')
                          : translate('translate#title.create')}
                      </Box>
                    </ListItemText>
                  )}
                </Button>
              </Test>
            </ListItem>
          </AccessControl>
        )}
        {!pathname.includes(PRODUCTS_BLOCK_PREFIX) && (
          <Test id={testId(2)}>
            <ContextMenu
              style={pathname.includes(USM_BLOCK_PREFIX) ? { left: '240px' } : undefined}
              useItemDivider
              anchorEl={anchorEl}
              handleClose={handleCloseMenu}
              anchorOrigin={undefined}
              transformOrigin={undefined}
              options={
                pathname.includes(USM_BLOCK_PREFIX)
                  ? contextMenuUSM
                  : [
                      {
                        content: (
                          <>
                            <NoteAdd className={classes.menuIcon} />
                            <span className={classes.label}>
                              {translate('translate#title.createNewTicket')}
                            </span>
                          </>
                        ),
                        handleClick: handleOpenTicket,
                        allowedPermissions: ['SRVTicket_insert'],
                      },
                      {
                        content: (
                          <>
                            <AddCircle className={classes.menuIcon} />
                            <span className={classes.label}>
                              {translate('translate#title.createPOSServiceRequest')}
                            </span>
                          </>
                        ),
                        handleClick: handleOpenPOS,
                        allowedPermissions: ['POSCustomerSR_insert'],
                      },
                    ]
              }
              classStyles={
                pathname.includes(USM_BLOCK_PREFIX)
                  ? {
                      driverStyle: styleAddMenu.driver,
                      menuListStyle: styleAddMenu.menuList,
                      menuSpacingStyle: styleAddMenu.menuSpacing,
                    }
                  : undefined
              }
            />
          </Test>
        )}
        <CreateNewTicketModal
          open={openTicketModal}
          handleClose={handleCloseTicket}
          disableBackdropClick
        />
        <CreatePOSRequestModal
          open={openPOSModal}
          handleClose={handleClosePOS}
          handleOpenPsrDetails={handleOpenPsrDetails}
          handleSetCurrentTicketId={handleSetCurrentTicketId}
          disableBackdropClick
        />
        <AddUserModalUSM
          open={drawerActionsUSMIsOpen}
          handleClose={handleAddUserModalUSM(false)}
          disableBackdropClick
        />
        <AddUserGroupModalUSM
          open={addUserGroupModalUSMIsOpen}
          handleClose={handleAddUserGroupModalUSM(false)}
          disableBackdropClick
        />
        <PSRTicketDetails
          ticketId={currentTicketId}
          open={psrDetailsIsOpen}
          idPrefix=""
          handleClose={handleClosePsrDetails}
          handleOpenCreatePosRequestModal={handleOpenPOS}
        />
        {pathname.includes(PRODUCTS_BLOCK_PREFIX) && addNewProductIsOpen && (
          <AddNewProduct open={addNewProductIsOpen} onClose={handleCloseAddNewProduct} />
        )}
        {pathname.includes(ADM_BLOCK_PREFIX) && createNewDeviceADMIsOpen && (
          <CreateNewDevice
            open={createNewDeviceADMIsOpen}
            onClose={handleCloseCreateNewDeviceADM}
          />
        )}
      </div>
    </Test>
  )
}

export default DrawerActions
