import { AnyAction } from 'redux'

import { VaultV1Actions } from '@/constants/actions'

export type State = {
  selectedPanel: string
}

export const initialState: State = {
  selectedPanel: 'Objects',
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case VaultV1Actions.AdmSetSelectedObjectsPanel:
      return {
        ...state,
        selectedPanel: payload,
      }

    case VaultV1Actions.SignOut:
      return initialState
    default:
      return state
  }
}
