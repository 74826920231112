import { Box, Typography } from '@material-ui/core'
import React, { ReactElement } from 'react'

import { useClasses } from './classes'
import { Props } from './types'

export const Badge = ({ variant, title }: Props): ReactElement => {
  const classes = useClasses()

  return (
    <div
      className={classes.badge}
      style={{
        backgroundColor:
          variant === 'event' ? '#FFFCEF' : variant === 'transactions' ? '#E6FAF0' : '#FDEFED',
      }}
    >
      <Box>
        <div
          className={classes.badgeItem}
          style={{
            backgroundColor:
              variant === 'event' ? '#FEDA59' : variant === 'transactions' ? '#00C562' : '#FF7362',
          }}
        />
      </Box>
      {title && <Typography className={classes.badgeCount}>{title}</Typography>}
    </div>
  )
}
