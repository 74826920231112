import { AdmAutomatedOperation } from '@/api/cm/swaggerGeneratedApi'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class AutomatedOperationsApi {
  private static requestService = getRequestServiceCM()

  public static async get(): Promise<AdmAutomatedOperation> {
    try {
      return await AutomatedOperationsApi.requestService.get('/admin/automatedOperations')
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async update(payload: AdmAutomatedOperation[]): Promise<void> {
    try {
      return await AutomatedOperationsApi.requestService.put(
        '/admin/automatedOperations/update',
        payload,
      )
    } catch (error) {
      throw Error(error)
    }
  }
}
