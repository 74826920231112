export enum TechnicalEventsActions {
  SetSelectedGroupingTemplate = '@/TECHNICAL_EVENTS/SET_SELECTED_GROUPING_TEMPLATE',
  ChangeSelectedFilters = '@/TECHNICAL_EVENTS/CHANGE_SELECTED_FILTERS',
  ChangeGrouping = '@/TECHNICAL_EVENTS/CHANGE_GROUPING',
  ChangeSorting = '@/TECHNICAL_EVENTS/CHANGE_SORTING',
  SetRowPerPage = '@/TECHNICAL_EVENTS/SET_ROW_PER_PAGE',
  SetPage = '@/TECHNICAL_EVENTS/SET_PAGE',

  DeleteFilterTemplateRequest = '@/TECHNICAL_EVENTS/DELETE_FILTER_TEMPLATE_REQUEST',
  DeleteFilterTemplateResponse = '@/TECHNICAL_EVENTS/DELETE_FILTER_TEMPLATE_RESPONSE',

  GetGroupingTemplatesRequest = '@/TECHNICAL_EVENTS/GET_GROUPING_TEMPLATES_REQUEST',

  GetFiltersTemplatesRequest = '@/TECHNICAL_EVENTS/GET_FILTERS_TEMPLATES_REQUEST',

  SaveGroupingTemplateRequest = '@/TECHNICAL_EVENTS/SAVE_GROUPING_TEMPLATE_REQUEST',

  CreateFilterTemplateRequest = '@/TECHNICAL_EVENTS/CREATE_FILTER_TEMPLATE_REQUEST',
  EditGroupTemplateRequest = '@/TECHNICAL_EVENTS/EDIT_GROUP_TEMPLATE_REQUEST',
  DeleteGroupTemplateRequest = '@/TECHNICAL_EVENTS/DELETE_GROUP_TEMPLATE_REQUEST',

  UpdateFilterTemplateRequest = '@/TECHNICAL_EVENTS/UPDATE_FILTER_TEMPLATE_REQUEST',
  SetGroupTemplates = '@/TECHNICAL_EVENTS/SET_GROUP_TEMPLATES',

  GetTechnicalEventsExport = '@/TECHNICAL_EVENTS/GET_TECHNICAL_EVENTS_EXPORT',
  GetExportResponse = '@/GET_EXPORT_RESPONSE',
  GetExportResponseFail = '@/GET_EXPORT_RESPONSE_FAIL',

  GetTechnicalEventsExportGrouped = '@/TECHNICAL_EVENTS/GET_TECHNICAL_EVENTS_EXPORT_GROUPED',
  GetExportGroupedResponse = '@/GET_EXPORT_GROUPED_RESPONSE',
  GetExportGroupedResponseFail = '@/GET_EXPORT_GROUPED_RESPONSE_FAIL',

  GetTransactionsConfigRequest = '@/TECHNICAL_EVENTS/GET_TRANSACTIONS_CONFIG_REQUEST',
  GetTransactionsRequest = '@/TECHNICAL_EVENTS/GET_TRANSACTIONS_REQUEST',
  GetTransactionsResponse = '@/TECHNICAL_EVENTS/GET_TRANSACTIONS_RESPONSE',
  GetGroupDetailsRequest = '@/TECHNICAL_EVENTS/GET_GROUP_DETAILS_REQUEST',
  SetParentGroups = '@/TECHNICAL_EVENTS/SET_PARENT_GROUPS',
  SetCurrentFilterTemplate = '@/TECHNICAL_EVENTS/SET_CURRENT_FILTER_TEMPLATE',
  SetSdFilterTemplateResponse = '@/TECHNICAL_EVENTS/SET_FILTER_TEMPLATE_RESPONSE',

  CreateSdFilterTemplateResponse = '@/TECHNICAL_EVENTS/CREATE_SD_FILTER_TEMPLATE_RESPONSE',
  EditSdGroupTemplateFromResponse = '@/TECHNICAL_EVENTS/EDIT_SD_GROUP_TEMPLATE_FROM_RESPONSE',

  UpdateSdFilterTemplateResponse = '@/TECHNICAL_EVENTS/UPDATE_SD_FILTER_TEMPLATE_RESPONSE',
  GetMedia = '@/TECHNICAL_EVENTS/GET_MEDIA',
  SetMedia = '@/TECHNICAL_EVENTS/SET_MEDIA',
  ClearCurrentMedia = '@/TECHNICAL_EVENTS/CLEAR_CURRENT_MEDIA',
  SetCurrentMedia = '@/TECHNICAL_EVENTS/SET_CURRENT_MEDIA',
  SetMediaErrorMessage = '@/TECHNICAL_EVENTS/SET_MEDIA_ERROR_MESSAGE',

  EditTableCellRequest = '@/TECHNICAL_EVENTS/EDIT_TABLE_CELL_REQUEST',
  EditTableCellGrouping = '@/TECHNICAL_EVENTS/EDIT_TABLE_CELL_REQUEST_GROUPING',
  SaveTableCell = '@/TECHNICAL_EVENTS/SAVE_TABLE_CELL',
  SaveTableCellGrouping = '@/TECHNICAL_EVENTS/SAVE_TABLE_CELL_GROUPING',
  SetDataPagination = '@/TECHNICAL_EVENTS/SET_DATA_PAGINATION',
  SetDataPaginationRequest = '@/TECHNICAL_EVENTS/SET_DATA_PAGINATION_REQUEST',
  CollectFilterTemplateFields = '@/TECHNICAL_EVENTS/COLLECT_FILTER_TEMPLATE_FIELDS',
  GetTicketsConfigResponse = '@/TECHNICAL_EVENTS/GET_TICKETS_CONFIG_RESPONSE',
  ClearGroupedTicketDetailsData = '@/TECHNICAL_EVENTS/CLEAR_GROUPED_DETAILS_DATA',

  SetFilterModalState = '@/TECHNICAL_EVENTS/SET_FILTER_MODAL_STATE',
  GetTicketResponseFail = '@/TECHNICAL_EVENTS/GET_TICKETS_RESPONSE_FAIL',
  IsSubmitFiltersButtonClicked = '@/TECHNICAL_EVENTS/IS_SUBMIT_FILTERS_BUTTON_CLICKED',
}
