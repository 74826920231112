import { BaseUSM } from '@/api/usm'

export class SecurityNetworksObjectMergeUSMAPI<T> extends BaseUSM<T> {
  constructor(public readonly data: T) {
    super(data)
    this.data = data
  }

  public async getAllSecurityNetworksObjectsMerge() {
    try {
      return await this.USMRequestService.get(`/security-network/objects`)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  public async getAllUsersListForCurrentUser() {
    try {
      return await this.USMRequestService.get(`/security-network/users`)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  public async getSummaryObjectsMerge() {
    try {
      return await this.USMRequestService.post(
        `/security-network/objects/summary-merge-objects`,
        this.data,
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  public async updateSummaryObjectsMerge() {
    try {
      return await this.USMRequestService.put(`/security-network/objects/merge`, this.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
