import {
  GetTechEventsGroupsRequest,
  TechEvent,
  TechEventsFilter,
} from '@/api/atmeye/techEvents/types'
import { GroupNode } from '@/components/NPMPakage/components/ObjectsTree/types'
import { ResponseError } from '@/types'
import { requestService } from '@/utils/services/request'

import { TableListReponse } from '../commonTypes'

export class TechEventsApi {
  public static async getTechEventsList({
    sort,
    size,
    page,
    ...payload
  }: {
    filter: TechEventsFilter
    groupingFilters?: TechEventsFilter[]

    size: number
    page: number
    sort: string
  }): Promise<TableListReponse<TechEvent>> {
    try {
      return await requestService.post(
        `/atmeye/api/v1/events/technical/list?sort=${sort}&size=${size}&page=${page - 1}`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getTechEventsGroups(
    payload: GetTechEventsGroupsRequest,
  ): Promise<{ groupNodes: GroupNode[] }> {
    try {
      return await requestService.post(`/atmeye/api/v1/events/technical/groups`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async updateTechEventNote({
    eventId,
    value,
  }: {
    eventId: string
    value: string
  }): Promise<void | ResponseError> {
    try {
      return await requestService.patch(
        `/atmeye/api/v1/events/technical/note?eventId=${eventId}&value=${value}`,
        {},
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getExportData(
    {
      sort,
      ...payload
    }: {
      filter: TechEventsFilter
      config: {
        format: string
        appliedFilters: {
          filterLabel: string
          filterValue: string
        }[]
        selectedOutputFields: any
      }
      sort?: string
    },
    buffer?: boolean,
  ): Promise<any | ResponseError> {
    try {
      return await requestService.post(
        `/atmeye/api/v1/events/technical/ungrouped-export?sort=${sort}`,
        payload,
        true,
        buffer && { responseType: 'arraybuffer' },
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getExportGroupedData(
    {
      sort,
      ...payload
    }: {
      exportedGroupsData: {
        groupsInfo: any
        nodeFilter: any
      }
      config: {
        format: string
        appliedFilters: {
          filterLabel: string
          filterValue: string
        }[]
        selectedOutputFields: any
      }
      sort?: string
    },
    buffer?: boolean,
  ): Promise<any | ResponseError> {
    try {
      return await requestService.post(
        `/atmeye/api/v1/events/technical/grouped-export?sort=${sort}`,
        payload,
        true,
        buffer && { responseType: 'arraybuffer' },
      )
    } catch (e) {
      throw new Error(e)
    }
  }
}
