import { useCallback, useEffect, useState } from 'react'

import { DefaultFiltersConfig } from '@/components/NPMPakage/hooks/defaultFilters/types'

const useDefaultFilters = ({
  generateDefaultFilters,
  staticDefaultFilters,
}: DefaultFiltersConfig): any => {
  const [defaultFilters, setDefultFilters] = useState(
    generateDefaultFilters ? generateDefaultFilters() : staticDefaultFilters || {},
  )

  const generateAndSetDefaultFilters = useCallback(() => {
    if (generateDefaultFilters) {
      const newDefaultFilter = generateDefaultFilters()
      setDefultFilters(newDefaultFilter)
      return newDefaultFilter
    }
    return staticDefaultFilters || {}
  }, [generateDefaultFilters, staticDefaultFilters])

  useEffect(() => {
    generateAndSetDefaultFilters()
  }, [generateAndSetDefaultFilters])

  // find out can we remove it or not
  useEffect(() => {
    staticDefaultFilters && setDefultFilters(staticDefaultFilters)
  }, [staticDefaultFilters])
  //

  return {
    defaultFilters,
    handleResetDefaultFilters: generateAndSetDefaultFilters,
  }
}

export { useDefaultFilters }
