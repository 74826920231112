import { AnyAction } from 'redux'

import { Command } from '@/api/rsocket/command'
import { Sorting } from '@/api/sd/ticketsApi/types'
import { AuthorizationActions } from '@/constants/actions/auth'
import { DashboardActions } from '@/constants/actions/dashboardActions'
import {
  ChartData,
  ChartExtendedData,
  Company,
  Device,
  GetTicketsResponse,
  RefreshTimer,
  RSocket,
  TicketsConfigData,
} from '@/types'

export interface State {
  pieChartData: ChartData
  refreshTimer: RefreshTimer[]
  extendedChartData: ChartExtendedData | null
  companies: Company[]
  devices: Device[]
  filter: {
    company: string
    devices: string[]
    sla: string[]
    status: string
  }
  sorting: Sorting
  config: TicketsConfigData
  tickets: GetTicketsResponse
  pageNo: number
  pageSize: number
  sortDetails: Array<{ [key: string]: 'asc' | 'desc' }>
  isLookingForDevices: boolean
  isLookingForCompanies: boolean
  isLookingForChartData: boolean
  isLookingForChartExtendedData: boolean
  isLookingForTicketsConfig: boolean
  isLookingForTickets: boolean
  error: string | null
  currentWidgetId: string
  rSocketData: RSocket
  rSocketDataError: string | null
  rSocketDataErrorMessage: { [key: string]: string | null }
}

export const initialState: State = {
  pieChartData: {
    total: 0,
    sla: {
      noDeadline: 0,
      noDelayed: 0,
      nearing: 0,
      ok: 0,
      noDeadlinePercents: 0,
      noDelayedPercents: 0,
      nearingPercents: 0,
      okPercents: 0,
    },
    statuses: {
      undefined: 0,
      running: 0,
      submitted: 0,
      created: 0,
      prepared: 0,
      finished: 0,
      suspended: 0,
      planned: 0,
      sentToExecutant: 0,
      generated: 0,
      partiallyGenerated: 0,
      suspendedDueCancelWO: 0,
    },
    refreshTimeout: {
      hours: '0',
      minutes: '0',
    },
  },
  refreshTimer: [
    {
      isShowCountdown: false,
      selectedTimeInterval: 0,
      dateForTimer: 0,
      widgetId: '',
    },
  ],
  extendedChartData: null,
  companies: [],
  devices: [],
  filter: { company: '', devices: [], sla: [], status: '' },
  sorting: null,
  config: {
    formUID: '',
    getConfigURL: '',
    getOutTreeURL: '',
    getOutDetailsURL: '',
    filter: [],
    groups: [],
    outputFields: [],
  },
  tickets: {
    details: undefined,
    groups: null,
    hasGroups: false,
    totalCount: 0,
  },
  pageNo: 1,
  pageSize: 5,
  sortDetails: [],
  isLookingForDevices: false,
  isLookingForCompanies: false,
  isLookingForChartData: false,
  isLookingForChartExtendedData: false,
  isLookingForTicketsConfig: false,
  isLookingForTickets: false,
  error: null,
  currentWidgetId: '',
  rSocketData: {} as RSocket,
  rSocketDataError: null,
  rSocketDataErrorMessage: {},
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case DashboardActions.GetChartDataRequest:
      return {
        ...state,
        isLookingForChartData: true,
      }
    case DashboardActions.GetChartDataResponse:
      return {
        ...state,
        pieChartData: payload,
        isLookingForChartData: false,
      }
    case DashboardActions.GetChartDataResponseFail:
      return {
        ...state,
        error: payload,
        isLookingForChartData: false,
      }
    case DashboardActions.GetChartExtendedDataRequest:
      return {
        ...state,
        isLookingForChartExtendedData: true,
      }
    case DashboardActions.GetChartExtendedDataResponse:
      return {
        ...state,
        extendedChartData: payload,
        isLookingForChartExtendedData: false,
      }
    case DashboardActions.GetChartExtendedDataResponseFail:
      return {
        ...state,
        error: payload,
        isLookingForChartExtendedData: false,
      }
    case DashboardActions.SetRefreshTimout:
      return {
        ...state,
        refreshTimer: [
          ...state.refreshTimer.filter(item => {
            return item.widgetId !== payload.widgetId
          }),
          payload,
        ],
      }
    case DashboardActions.SetCurrentWidgetId:
      return {
        ...state,
        currentWidgetId: payload,
      }
    case DashboardActions.DeleteCompletedTimer:
      return {
        ...state,
        refreshTimer: [
          ...state.refreshTimer.filter(item => {
            return item.widgetId !== payload
          }),
        ],
      }
    case DashboardActions.GetAllDevicesRequest:
      return {
        ...state,
        isLookingForDevices: true,
      }
    case DashboardActions.GetAllDevicesResponse:
      return {
        ...state,
        devices: payload,
        isLookingForDevices: false,
      }
    case DashboardActions.GetAllDevicesResponseFail:
      return {
        ...state,
        error: payload,
        isLookingForDevices: false,
      }
    case DashboardActions.GetAllCompaniesRequest:
      return {
        ...state,
        isLookingForCompanies: true,
      }
    case DashboardActions.GetAllCompaniesResponse:
      return {
        ...state,
        companies: payload,
        isLookingForCompanies: false,
      }
    case DashboardActions.GetAllCompaniesResponseFail:
      return {
        ...state,
        error: payload,
        isLookingForCompanies: false,
      }
    case DashboardActions.SetCompany:
      return {
        ...state,
        filter: {
          ...state.filter,
          company: payload,
        },
      }
    case DashboardActions.SetDevice:
      return {
        ...state,
        filter: {
          ...state.filter,
          devices: payload,
        },
      }
    case DashboardActions.SetInitialDevice:
      return {
        ...state,
        filter: {
          ...state.filter,
          devices: [],
        },
      }
    case DashboardActions.SetStatus:
      return {
        ...state,
        filter: {
          ...state.filter,
          status: payload,
        },
      }
    case DashboardActions.SetPage:
      return {
        ...state,
        pageNo: payload,
      }
    case DashboardActions.SetRowPerPage:
      return {
        ...state,
        pageSize: payload,
      }
    case DashboardActions.SetSorting:
      return {
        ...state,
        sorting: payload,
      }
    case DashboardActions.GetTicketsConfigRequest:
      return { ...state, isLookingForTicketsConfig: true, error: null }
    case DashboardActions.GetTicketsConfigResponse:
      return { ...state, isLookingForTicketsConfig: false, config: payload }
    case DashboardActions.GetTicketsConfigResponseFail:
      return { ...state, isLookingForTicketsConfig: false, error: payload }
    case DashboardActions.GetTicketsRequest:
      return {
        ...state,
        isLookingForTickets: true,
      }
    case DashboardActions.GetTicketsResponse:
      return {
        ...state,
        tickets: payload,
        isLookingForTickets: false,
      }
    case DashboardActions.GetTicketsResponseFail:
      return {
        ...state,
        error: payload,
        isLookingForTickets: false,
      }
    case DashboardActions.SetSocketData: {
      return {
        ...state,
        rSocketData: { ...state.rSocketData, [payload.name]: payload.payload },
      }
    }
    case DashboardActions.SetSocketItemEmpty: {
      return {
        ...state,
        rSocketData: { ...state.rSocketData, [payload]: null },
      }
    }

    case DashboardActions.ClearSocketData:
      return {
        ...state,
        rSocketData: {} as RSocket,
        rSocketDataError: null,
      }

    case DashboardActions.ClearSocketDataByMessage:
      return {
        ...state,
        rSocketDataError: null,
        rSocketData: ((prop: Command, { [prop]: _, ...rest }) => {
          return rest
        })(payload, state.rSocketData) as RSocket,
      }

    case DashboardActions.SetSocketDataError: {
      return {
        ...state,
        rSocketDataError: payload,
      }
    }

    case DashboardActions.SetSocketMessageError: {
      return {
        ...state,
        rSocketDataErrorMessage: { ...state.rSocketDataErrorMessage, ...payload },
      }
    }

    case DashboardActions.ClearSocketDataError:
      return {
        ...state,
        rSocketDataError: null,
        rSocketDataErrorMessage: {},
      }

    default:
      return state
  }
}
