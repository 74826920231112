import { AdmApprovalItem, AdmApprovedItem } from '@/api/cm/swaggerGeneratedApi/index'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class ApproveItemsCMApi {
  public static requestService = getRequestServiceCM()

  public static async get(module: string): Promise<AdmApprovalItem[]> {
    try {
      return await ApproveItemsCMApi.requestService.post('/admin/approve/items', { module })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async set(approvedItems: AdmApprovedItem[]): Promise<void> {
    try {
      return await ApproveItemsCMApi.requestService.post('/admin/approve/doApprove', approvedItems)
    } catch (e) {
      throw new Error(e)
    }
  }
}
