import makeStyles from '@material-ui/core/styles/makeStyles'

import { styledComponentTheme } from '@/theme'

type Props = {
  display?: string
}

export const useClasses = makeStyles(({ spacing, palette }) => ({
  container: {
    display: (props: Props) => props.display,
    flex: 1,
    padding: spacing(0, 0, 5, 0),
  },
  title: {
    whiteSpace: 'nowrap',
    fontSize: styledComponentTheme.fontSizes.medium,
  },
  content: {
    padding: spacing(2.5),
    paddingTop: spacing(1),
  },
  fieldsGrid: {
    marginTop: spacing(1),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  fieldsWrapper: {
    width: '48%',
  },
  radioGroup: {
    justifyContent: 'flex-start',
    marginRight: spacing(2.5),
  },
  radio: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  radioItem: {
    marginRight: spacing(10),
  },
  unitOption: {
    display: 'flex',
    flexDirection: 'column',
  },
  unitOptionName: {
    marginBottom: spacing(0.5),
  },
  unitAddInfo: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: styledComponentTheme.fontSizes.small,
  },
}))
