import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Loader from '@/components/blocks/Loader'
import { ChangePassword as CmChangePassword } from '@/components/pages/cm/ChangePassword'
import { ChooseProduct } from '@/components/pages/common/SignInPage/components/ChooseProduct/component'
import { ChangePassword as VaultV1ChangePassword } from '@/components/pages/vault-v1/ChangePassword'
import { LogoLocalBlock, SignInPageWrapper } from '@/components/wrappers/SignInPage/index'
import { LocalStorageItems } from '@/constants'
import { Modules } from '@/constants/auth'
import { LoginFormValues } from '@/constants/forms/LoginForm'
import { DEFAULT_CULTURES, FlagMapping } from '@/constants/internalization'
import { REACT_APP_CM_STANDALONE, REACT_APP_VAULT_V1_STANDALONE } from '@/constants/moduleMode'
import {
  getAvailableAccountsOptions,
  getAvailableProducts,
  getCurrentProduct,
  getIsInitialisationProcess,
  getIsLoggingIn,
  getIsNeedChangePassword,
  signInRequest,
} from '@/store/auth/index'
import { getCultures, signInRequestCM } from '@/store/cm/auth'
import { actionsNotifications } from '@/store/notifications'
import { getCulturesVaultV1, signInRequestVaultV1 } from '@/store/vault-v1/auth'
import useAppVersion from '@/utils/hooks/atmeye/useAppVersion'

import ChangePassword from './components/ChangePassword'
import LoginForm from './components/LoginForm/index'
import SelectAccountForm from './components/SelectAccountForm/index'

const SignInPage = (): React.ReactElement => {
  const dispatch = useDispatch()
  const availableAccounts = useSelector(getAvailableAccountsOptions)
  const isLoggingIn = useSelector(getIsLoggingIn)
  const availableProducts = useSelector(getAvailableProducts)
  const currentProduct = useSelector(getCurrentProduct)
  const isInitialAuthLoading = useSelector(getIsInitialisationProcess)
  const isNeedChangePassword = useSelector(getIsNeedChangePassword)

  const isCM = REACT_APP_CM_STANDALONE === 'true'
  const isVaultV1 = REACT_APP_VAULT_V1_STANDALONE === 'true'

  const isServerBuildInfoAvailable = !isCM && !isVaultV1
  const buildInfo = useAppVersion(isServerBuildInfoAvailable)

  const [allLangs, setAllLangs] = useState<
    { label: string; value: string; code: string }[] | undefined
  >()

  const getLanguage = useCallback(() => {
    const stored =
      sessionStorage.getItem(LocalStorageItems.AllLocales) || JSON.stringify(DEFAULT_CULTURES)

    if (stored) {
      const cultures = JSON.parse(stored)
      const localAllLangs = cultures.map((obj: { displayName: string; id: string }) => {
        return { label: obj.displayName, value: obj.id, code: FlagMapping[obj.id] }
      })

      setAllLangs(localAllLangs)
    }
    isCM && dispatch(getCultures())
    isVaultV1 && dispatch(getCulturesVaultV1())
  }, [])

  useEffect(() => {
    if (isCM || isVaultV1) {
      getLanguage()
    }
  }, [])

  const handleSignIn = useCallback<(values: LoginFormValues) => void>(
    (values): void => {
      if (values?.module === Modules.CashManagement || isCM) {
        const { username, password } = values

        dispatch(signInRequestCM({ username, password }))
        return
      }

      if (values?.module === Modules.VaultV1 || isVaultV1) {
        const { username, password } = values

        dispatch(signInRequestVaultV1({ username, password }))
        return
      }

      dispatch(actionsNotifications.clearNotifications())
      dispatch(signInRequest(values))
    },
    [dispatch],
  )

  const handleModuleChange = useCallback(
    (module: string) => {
      if (module === Modules.CashManagement && !allLangs) {
        getLanguage()

        return
      }

      setAllLangs(undefined)
    },
    [allLangs],
  )

  const logoProducts = useMemo(() => {
    switch (true) {
      case isCM:
        return 'cm'
      case isVaultV1:
        return 'vault-v1'

      default:
        return 'transparent'
    }
  }, [isCM, isVaultV1])

  return (
    <>
      {isInitialAuthLoading ? (
        <Loader />
      ) : (
        <>
          {isNeedChangePassword ? (
            <ChangePassword />
          ) : (
            <SignInPageWrapper>
              {availableAccounts.length > 1 && currentProduct ? (
                <SelectAccountForm />
              ) : availableProducts.length && availableAccounts.length ? (
                <ChooseProduct />
              ) : (
                <>
                  <LogoLocalBlock
                    logoProducts={logoProducts}
                    allLanguages={isCM || isVaultV1 || allLangs ? allLangs : undefined}
                    title="Login"
                    showLocal
                  />
                  <LoginForm
                    onSignIn={handleSignIn}
                    isLoggingIn={isLoggingIn}
                    handleModuleChange={handleModuleChange}
                  />
                </>
              )}
            </SignInPageWrapper>
          )}
        </>
      )}
      <div style={{ position: 'fixed', bottom: 20, right: 20, color: '#fff', fontSize: '12px' }}>
        <b>Client build: </b>
        {buildInfo?.clientBuildInfo}
        <br />
        {isServerBuildInfoAvailable && (
          <>
            <b>Server build: </b>
            {buildInfo?.serverBuildInfo}
          </>
        )}
      </div>

      {isCM && <CmChangePassword />}
      {isVaultV1 && <VaultV1ChangePassword />}
    </>
  )
}

export default SignInPage
