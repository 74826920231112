export enum DrawerActionsActions {
  SetTicketId = '@/SET_TICKET_ID',
  SetIsTicketHasDeletedSuccessful = '@/SET_IS_TICKET_HAS_DELETED_SUCCESSFUL',

  GetTicketsValuesRequest = '@/GET_TICKETS_VALUES_REQUEST',
  GetTicketsValuesResponse = '@/GET_TICKETS_VALUES_RESPONSE',
  GetTicketsValuesResponseFail = '@/GET_TICKETS_VALUES_RESPONSE_FAIL',

  FindUnitsRequest = '@/FIND_UNITS_REQUEST',
  FindUnitsResponse = '@/FIND_UNITS_RESPONSE',
  FindUnitsResponseFail = '@/FIND_UNITS_RESPONSE_FAIL',

  FindMerchantsRequest = '@/FIND_MERCHANTS_REQUEST',
  FindMerchantsResponse = '@/FIND_MERCHANTS_RESPONSE',
  FindMerchantsResponseFail = '@/FIND_MERCHANTS_RESPONSE_FAIL',

  FindRentersRequest = '@/FIND_RENTERS_REQUEST',
  FindRentersResponse = '@/FIND_RENTERS_RESPONSE',
  FindRentersResponseFail = '@/FIND_RENTERS_RESPONSE_FAIL',

  FindCitiesRequest = '@/FIND_CITIES_REQUEST',
  FindCitiesResponse = '@/FIND_CITIES_RESPONSE',
  FindCitiesResponseFail = '@/FIND_CITIES_RESPONSE_FAIL',

  FindUnitsAdvancedRequest = '@/FIND_UNITS_ADVANCED_REQUEST',
  FindUnitsAdvancedResponse = '@/FIND_UNITS_ADVANCED_RESPONSE',
  FindUnitsAdvancedResponseFail = '@/FIND_UNITS_ADVANCED_RESPONSE_FAIL',

  FindUnitsByMerchandAndServiceRequest = '@/FIND_UNITS_ByMerchandAndService_REQUEST',
  FindUnitsByMerchandAndServiceResponse = '@/FIND_UNITS_ByMerchandAndService_RESPONSE',
  FindUnitsByMerchandAndServiceResponseFail = '@/FIND_UNITS_ByMerchandAndService_RESPONSE_FAIL',

  AddTicketRequest = '@/ADD_TICKET_REQUEST',
  AddTicketResponse = '@/ADD_TICKET_RESPONSE',
  AddTicketResponseFail = '@/ADD_TICKET_RESPONSE_FAIL',

  GetServiceCompanyRequest = '@/GET_SERVICE_COMPANY_REQUEST',
  GetServiceCompanyResponse = '@/GET_SERVICE_COMPANY_RESPONSE',
  GetServiceCompanyeFail = '@/GET_SERVICE_COMPANY_FAIL',

  GetAdvancedUnitSearchConfigurationRequest = '@/GET_ADVANCED_UNIT_SEARCH_CONFIGURATION_REQUEST',
  GetAdvancedUnitSearchConfigurationResponce = '@/GET_ADVANCED_UNIT_SEARCH_CONFIGURATION_RESPONCE',
  GetAdvancedUnitSearchConfigurationFail = '@/GET_ADVANCED_UNIT_SEARCH_CONFIGURATION_FAIL',

  GetCreatingPOSFormConfigRequest = '@/GET_CREATING_POS_TICKET_FORM_CONFIG_REQUEST',
  GetCreatingPOSFormConfigResponse = '@/GET_CREATING_POS_TICKET_FORM_CONFIG_RESPONSE',
  GetCreatingPOSFormConfigFail = '@/GET_CREATING_POS_TICKET_FORM_CONFIG_FAIL',

  GetExistingMerchantsRequest = '@/GET_EXISTING_MERCHANTS_REQUEST',
  GetExistingMerchantsResponse = '@/GET_EXISTING_MERCHANTS_RESPONSE',
  GetExistingMerchantsResponseFall = '@/GET_EXISTING_MERCHANTS_RESPONSE_FALL',

  GetCreatingPOSEditFormConfigRequest = '@/GET_CREATING_POS_TICKET_EDIT_FORM_CONFIG_REQUEST',
  GetCreatingPOSEditFormConfigResponse = '@/GET_CREATING_POS_TICKET_EDIT_FORM_CONFIG_RESPONSE',
  GetCreatingPOSEditFormConfigFail = '@/GET_CREATING_POS_TICKET_EDIT_FORM_CONFIG_FAIL',
  ClearPOSEditFormConfig = '@/CLEAR_POS_TICKET_EDIT_FORM_CONFIG',

  GetPOSServiceFormConfigRequest = '@/GET_POS_SERVICE_FORM_CONFIG_REQUEST',
  GetPOSServiceFormConfigResponse = '@/GET_POS_SERVICE_FORM_CONFIG_RESPONSE',
  GetPOSServiceFormConfigFail = '@/GET_POS_SERVICE_FORM_CONFIG_FAIL',

  GetMerchantConfigRequest = '@/GET_MERCHANT_FORM_CONFIG_REQUEST',
  GetMerchantConfigResponse = '@/GET_MERCHANT_FORM_CONFIG_RESPONSE',
  GetMerchantConfigFail = '@/GET_MERCHANT_FORM_CONFIG_FAIL',

  GetPOSServiceDismantlingFormConfigRequest = '@/GET_POS_SERVICE_DISMANTLING_FORM_CONFIG_REQUEST',
  GetPOSServiceDismantlingFormConfigResponse = '@/GET_POS_SERVICE_DISMANTLING_FORM_CONFIG_RESPONSE',
  GetPOSServiceDismantlingFormConfigFail = '@/GET_POS_SERVICE_DISMANTLING_FORM_CONFIG_FAIL',

  GetPOSServiceInstallationFormConfigRequest = '@/GET_POS_SERVICE_INSTALLATION_FORM_CONFIG_REQUEST',
  GetPOSServiceInstallationFormConfigResponse = '@/GET_POS_SERVICE_INSTALLATION_FORM_CONFIG_RESPONSE',
  GetPOSServiceInstallationFormConfigFail = '@/GET_POS_SERVICE_INSTALLATION_FORM_CONFIG_FAIL',

  GetPOSServiceLocationChangeFormConfigRequest = '@/GET_POS_SERVICE_LOCATION_CHANGE_FORM_CONFIG_REQUEST',
  GetPOSServiceLocationChangeFormConfigResponse = '@/GET_POS_SERVICE_LOCATION_CHANGE_FORM_CONFIG_RESPONSE',
  GetPOSServiceLocationChangeFormConfigFail = '@/GET_POS_SERVICE_LOCATION_CHANGE_FORM_CONFIG_FAIL',

  GetPOSServiceParametersUpdateFormConfigRequest = '@/GET_POS_SERVICE_PARAMETERS_UPDATE_FORM_CONFIG_REQUEST',
  GetPOSServiceParametersUpdateFormConfigResponse = '@/GET_POS_SERVICE_PARAMETERS_UPDATE_FORM_CONFIG_RESPONSE',
  GetPOSServiceParametersUpdateFormConfigFail = '@/GET_POS_SERVICE_PARAMETERS_UPDATE_FORM_CONFIG_FAIL',

  GetPOSServiceReplaceFormConfigRequest = '@/GET_POS_SERVICE_REPLACE_FORM_CONFIG_REQUEST',
  GetPOSServiceReplaceFormConfigResponse = '@/GET_POS_SERVICE_REPLACE_FORM_CONFIG_RESPONSE',
  GetPOSServiceReplaceFormConfigFail = '@/GET_POS_SERVICE_REPLACE_FORM_CONFIG_FAIL',

  AddPOSServiceTypeActionRequest = '@/ADD_POS_SERVICE_TYPE_ACTION_REQUEST',
  AddPOSServiceTypeActionResponse = '@/ADD_POS_SERVICE_TYPE_ACTION_RESPONSE',
  AddPOSServiceTypeActionFail = '@/ADD_POS_SERVICE_TYPE_ACTION_FAIL',

  EditPOSServiceTypeActionRequest = '@/Edit_POS_SERVICE_TYPE_ACTION_REQUEST',
  EditPOSServiceTypeActionResponse = '@/Edit_POS_SERVICE_TYPE_ACTION_RESPONSE',
  EditPOSServiceTypeActionFail = '@/Edit_POS_SERVICE_TYPE_ACTION_FAIL',

  SavePosTicketRequest = '@/SAVE_POS_TICKET_REQUEST',
  SavePosTicketResponse = '@/SAVE_POS_TICKET_RESPONSE',
  SetIsSavePOSTicketSuccessfull = '@/setIsSavePOSTicketSuccessfull',
  SavePosTicketFail = '@/SAVE_POS_TICKET_FAIL',
  SavePosTicketSetInitial = '@/SAVE_POS_TICKET_SET_INITIAL',

  DeletePosTicketRequest = '@/Delete_POS_TICKET_REQUEST',
  DeletePosTicketResponse = '@/Delete_POS_TICKET_RESPONSE',
  DeletePosTicketFail = '@/Delete_POS_TICKET_FAIL',

  setInitialAdvancedUnits = '@SET_INITIAL_ADVANCED_UNITS',
  setInitialServiceCompanies = '@SET_INITIAL_SERVICE_COMPANIES',

  GetTicketInfoFormDataRequest = '@/GET_TICKET_INFO_FORM_DATA_REQUEST',
  GetTicketInfoFormDataResponse = '@/GET_TICKET_INFO_FORM_DATA_RESPONSE',
  GetTicketInfoFormDataFail = '@/GET_TICKET_INFO_FORM_DATA_FAIL',

  DeletePosRequestItemRequest = '@/DELETE_POS_REQUEST_ITEM_REQUEST',
  DeletePosRequestItemResponse = '@/DELETE_POS_REQUEST_ITEM_RESPONSE',
  DeletePosRequestItemFail = '@/DELETE_POS_REQUEST_ITEM_FAIL',

  EditPosTicketRequestRequest = '@/EDIT_POS_TICKET_REQUEST_REQUEST',
  EditPosTicketRequestResponse = '@/EDIT_POS_TICKET_REQUEST_RESPONSE',
  EditPosTicketRequestFail = '@/EDIT_POS_TICKET_REQUEST_FAIL',

  SendPosTicketRequestRequest = '@/Send_POS_TICKET_REQUEST_REQUEST',
  SendPosTicketRequestResponse = '@/Send_POS_TICKET_REQUEST_RESPONSE',
  SendPosTicketRequestFail = '@/Send_POS_TICKET_REQUEST_FAIL',
  SetIsTicketSentSuccessfull = '@/SET_IS_TICKET_SENT_SUCCESSFULLY',
  SetIsTicketSentFailed = '@/SET_IS_TICKET_SENT_FAILED',

  PutNotSavedPOSServiceRequest = '@/PutNotSavedPOSServiceRequest',
  PutNotSavedPosServicesArray = '@/PUT_NOT_SAVED_POS_SERVICES_ARRAY',
  ClearNotSavedPOSServiceRequest = '@/ClearNotSavedPOSServiceRequest',
  ResetPOSServicesFromUI = '@/RESET_POS_SERVICES_FROM_UI',
  SetNotSavedPOSServiceRequestEditIndex = '@/SET_NOT_SAVED_POS_SERVICE_REQUEST_EDIT_INDEX',
  EditNotSavedPOSServiceRequestByIndex = '@/EDIT_NOT_SAVED_POS_SERVICE_REQUEST_BY_INDEX',

  SetMCCCodePostTerminalRequest = '@/SET_MCC_CODE_POS_SERVICE_REQUEST_SUCCESS',
  SetTicketInfoFormDataDefault = '@/SET_TICKET_INFORM-DATA-DEFAULT_SD-DRAWER_ACTIONS',
}
