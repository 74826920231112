import {
  CashPointDetailsHeader,
  CashPointDetailsData,
  ExtendedCPDetails,
} from '@/types/cm/monitoring/index'
import { MonCashPointDetails } from '@/api/cm/swaggerGeneratedApi/index'

export const mapCashPointDetailsData = (
  pointDetails: MonCashPointDetails | null | undefined,
): ExtendedCPDetails => {
  if (!pointDetails) {
    return { data: [], headers: [], dataForCharts: [] }
  }
  const { data, headers } = pointDetails
  const formattedHeaders: CashPointDetailsHeader[] =
    headers?.map(header => ({
      field: header.fieldId as string,
      title: header.displayName as string,
    })) || []
  const formattedData: { dataRow: CashPointDetailsData[]; chartDataRow: CashPointDetailsData[] } = {
    dataRow: [],
    chartDataRow: [],
  }
  if (data)
    data.forEach(({ columnsDispValues, columnsValues, uiStatus }) => {
      const dataRow = {} as CashPointDetailsData
      const chartDataRow = {} as CashPointDetailsData
      if (columnsDispValues && columnsValues && headers) {
        for (let i = 0; i < columnsDispValues?.length; i++) {
          const fieldId = headers[i]?.fieldId
          const valueType = headers[i]?.valueType
          if (fieldId && valueType) {
            switch (valueType) {
              case 'date': {
                dataRow[fieldId] = columnsDispValues[i]
                chartDataRow[fieldId] = columnsDispValues[i]
                break
              }
              default: {
                dataRow[fieldId] = columnsDispValues[i]
                chartDataRow[fieldId] = columnsValues[i]
                break
              }
            }
            dataRow.decoration = uiStatus as string
          }
        }
      }
      formattedData.dataRow.push(dataRow)
      formattedData.chartDataRow.push(chartDataRow)
    })

  return {
    headers: formattedHeaders,
    data: formattedData.dataRow,
    dataForCharts: formattedData.chartDataRow,
  }
}
