import makeStyles from '@material-ui/core/styles/makeStyles'
import styled from 'styled-components'

import { styledComponentTheme } from '@/theme/styledComponentTheme'

export const useClasses = makeStyles(({ spacing }) => ({
  root: {
    borderRadius: '3px',
    padding: 0,
    maxHeight: 'calc(100vh - 330px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: 'none',
  },
  table: {
    minWidth: '100%',
    tableLayout: 'fixed',
    overflowX: 'hidden',
    boxShadow: 'none',
  },
  deviceTitle: {
    fontSize: '20px',
    marginBottom: spacing(3),
  },
  deviceName: {
    marginRight: '15px',
  },
  headRow: {
    backgroundColor: '#f8f8f8',
    fontWeight: 600,
  },
  tableCell: {
    wordBreak: 'break-word',
  },
  cellHeader: {
    fontWeight: 'bold',
    background: '#F8F8F8',
  },
  tableRow: {
    '&:nth-child(even)': {
      background: '#f8f8f8',
    },
  },
  title: {
    whiteSpace: 'pre',
  },
  value: {
    wordBreak: 'break-all',
  },
  bold: {
    fontWeight: 'bold',
  },
  deviceHeader: {
    marginBottom: spacing(1),
    fontWeight: 'bold',
    background: '#F8F8F8',
    padding: '10px 20px',
  },
  itemWrapper: {
    display: 'flex',
    padding: '12px 20px !important',
    '&:nth-child(odd)': {
      background: '#F8F8F8',
    },
  },
  list: {
    padding: 0,
  },
  button: {
    fontWeight: 'normal',
    background: styledComponentTheme.colors.baseGreen,
    color: 'white',
    border: 0,
    marginTop: spacing(2),
    marginBottom: 0,
    padding: '10px 20px',
    '&:hover': {
      background: styledComponentTheme.colors.baseGreen,
      color: 'white',
      border: 0,
    },
  },
  closeButton: {
    top: spacing(1),
    right: spacing(1),
    '& [class*="makeStyles-icon"]': {
      color: '#041517',
    },
  },
}))

export const ContentWrapper = styled.div`
  background-color: #ffffff;
  padding: 30px;
`
