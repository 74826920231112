import { ListItem, withStyles } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { styledComponentTheme } from '@/theme'

export const useClasses = makeStyles(({ spacing }) => ({
  modalHeader: {
    fontSize: '20px',
    lineHeight: '23px',
    marginBottom: 20,
  },
  modalSubheader: {
    marginBottom: spacing(3),
    color: styledComponentTheme.colors.paleGrey,
  },
  paper: {
    padding: spacing(4),
  },
  button: {
    height: '40px',
    paddingLeft: 0,
    borderRadius: '3px',
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  reportsControlsWrapper: {
    paddingLeft: spacing(4),
  },

  list: {
    paddingTop: 0,
  },

  reportList: {
    minHeight: '300px',
  },
  reportListWithoutPreview: {
    marginBottom: spacing(3),
    boxShadow: `0px 2px 1px -2px rgba(0,0,0,0.2),
    0px 1px 1px -1px rgba(0,0,0,0.14), 
    0px 1px 3px 0px rgba(0,0,0,0.12)`,
  },

  pdfViewerWrapper: {
    minHeight: '60vh',
    maxHeight: 'calc(60vh + 1px)',
    height: '100%',
    border: '1px solid #cad5d7',
    overflow: 'auto',
    borderRadius: '3px',
    marginBottom: spacing(2),
  },
  loaderWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  conditionsWithoutPreview: {
    minHeight: '20vh',
  },

  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiButton-endIcon': {
      marginLeft: 'auto',
    },
  },
  disabled: {
    filter: 'grayscale(1)',
    pointerEvents: 'none',
    opacity: 0.8,
  },

  invisible: {
    opacity: 0,
  },

  hideReportPreview: {
    display: 'none',
  },
  loadingOverlay: {
    opacity: 1,
  },
}))

export const useListClasses = makeStyles(() => ({
  root: {
    margin: 0,
    borderLeft: '4px solid transparent',
    '&:nth-child(2n+1)': {
      backgroundColor: '#F9F9F9',
    },
    '&:hover': {
      backgroundColor: 'rgb(234, 247, 249)',
    },
  },
  selected: {
    '&.Mui-selected': {
      backgroundColor: 'rgb(234, 247, 249)',
      borderColor: 'rgb(37, 173, 191)',
    },
  },
}))

export const StyledListItem = withStyles({
  root: {
    '&:nth-child(2n+1)': {
      backgroundColor: '#F9F9F9',
    },
    '&:hover': {
      backgroundColor: 'rgb(234, 247, 249)',
    },
  },
})(ListItem)
