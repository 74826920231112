import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  radioGroup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },

  yesNoAllWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  yesNoAllRadioGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  asterisk: {
    color: 'red',
    display: 'inline',
  },

  title: {
    fontWeight: 'bold',
    marginBottom: spacing(1),
  },

  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  radioGroupVertical: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  customRadioGroup: {
    display: 'flex',
    justifyContent: 'flex-start',
  },

  radioGroupTitle: {
    paddingRight: '5px',
  },

  disabled: {
    pointerEvents: 'none',
    opacity: '0.7',
  },
}))
