export const chartsWithoutParentheses = [
  '!',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '=',
  '№',
  '?',
  '`',
  '~',
  '+',
  ';',
  '|',
  '{',
  '}',
  '<',
  '>',
]

export const charts = [...chartsWithoutParentheses, '(', ')', '[', ']']

export const disabledSpecialChars = [':', '\\', ...charts]

export const hyphenNumbersAndLetters = [...disabledSpecialChars, '/', '.', ',', '"', "'"]

export const onlyNumbersAndLettersAndDots = [...disabledSpecialChars, '/', '-', '_', ',', '"', "'"]

export const onlyNumbersAndLetters = [...onlyNumbersAndLettersAndDots, '.']

export const onlyNumbersAndDots = [
  ...disabledSpecialChars,
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  '/',
  '-',
  '_',
  ',',
  '"',
  "'",
]

export const onlyNumbers = [...onlyNumbersAndDots, '.']

export const textInputSymbols = [...disabledSpecialChars, ',', '"', "'"]

// RegExp for the handle symbols in fields ---------------------------------------------------

export const numberWithDecimalExp = /[^0-9.,\s]+/g
export const numberExp = /[^0-9]+/g
export const withoutSpecialSymbolsExp = /[!@#$%^&*+|\\~`/{}"'<>?=[\],.№:;]+/g
export const withoutSpecialSymbolsWithDotExp = /[!@#$%^&*+|\\~`/{}"'<>?=[\],№:;-]+/g
export const withoutSpecialSymbolsWithDotExpAsOnBack = /[^\p{L}0-9. ]/gu
export const timeExp = /^([01][0-9]|2[0-3]):[0-5][0-9]$/

export const withoutSpaces = [' ']
