import { createSelector } from 'reselect'
import { AppState } from '@/store/reducers'
import * as fromDrawerActions from '../reducer'
import { initialState } from '../reducer'

export const getDrawerActionsState = (state: AppState): fromDrawerActions.State =>
  state.drawerActions || initialState

export const getCreatePosTicketsModalState = createSelector(
  getDrawerActionsState,
  state => state.createPosTicketsModalIsOpen,
)

export const getDocumentInformationModalState = createSelector(
  getDrawerActionsState,
  state => state.documentInformationModalIsOpen,
)
