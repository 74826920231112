import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getState = (state: AppState): fromReducer.State => {
  return state.cmAdmin?.appConfig.automatedOperations
}

export const getAutomatedOperations = createSelector(getState, state => state)
export const getAutomatedOperationsData = createSelector(getState, state => state.data)
