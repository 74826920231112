import React, { ReactElement, useState, useCallback, useEffect, ChangeEvent } from 'react'
import Grid from '@material-ui/core/Grid'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import Header from './components/Header'
import Row from './components/Row'
import Loader from '@/components/blocks/Loader'

import { useClasses } from './styles'
import { Props } from './types'

const EditableTable = ({
  isLoading,
  headers,
  data,
  collapsible,
  extraColumns,
  checkboxColumnsNames,
  collapseOptions,
  selectRowsOptions,
  deleteRowsOptions,
  changeRow,
  addNewRow,
  onRowClick,
  disableAction,
}: Props): ReactElement => {
  const [allCollapsed, setAllCollapsed] = useState<boolean>(false)
  const [countOfCollapsibleRows, setCountOfCollapsibleRows] = useState<number>(0)
  const [countOfOpenedRows, setCountOfOpenedRows] = useState<number>(0)

  const classes = useClasses()

  const handleCollapseAll = useCallback(() => {
    setAllCollapsed(collapsed => !collapsed)
  }, [])

  const handleChangeCountOfOpenedRows = useCallback((action: 'increase' | 'decrease') => {
    setCountOfOpenedRows(count => (action === 'increase' ? count + 1 : count - 1))
  }, [])

  useEffect(() => {
    if (data.length) {
      setCountOfCollapsibleRows(data.length)
    }
  }, [data])

  useEffect(() => {
    if (countOfCollapsibleRows !== 0 && countOfOpenedRows === countOfCollapsibleRows) {
      return setAllCollapsed(true)
    }
    if (allCollapsed && countOfOpenedRows === 0) {
      return setAllCollapsed(false)
    }
  }, [countOfOpenedRows, countOfCollapsibleRows])

  return (
    <Grid>
      <TableContainer className={classes.tableContainer}>
        {isLoading && (
          <div className={classes.loaderWrapper}>
            <Loader />
          </div>
        )}
        <Table stickyHeader>
          <Header
            headers={headers}
            collapsible={collapsible}
            extraColumns={extraColumns}
            onCollapseAll={collapseOptions?.collapseAll ? handleCollapseAll : undefined}
          />
          <TableBody>
            {data.map(row => (
              <Row
                key={row.id}
                headers={headers}
                data={row}
                collapsible={collapsible}
                extraColumns={extraColumns}
                checkboxColumnsNames={checkboxColumnsNames}
                collapseOptions={collapseOptions}
                allCollapsed={allCollapsed}
                selectRowsOptions={selectRowsOptions}
                changeRow={changeRow}
                deleteRowsOptions={deleteRowsOptions}
                onChangeCountOfOpenedRows={handleChangeCountOfOpenedRows}
                onRowClick={onRowClick && onRowClick(row)}
                disableAction={disableAction}
              />
            ))}
            {addNewRow?.newRows.map(row => (
              <Row
                key={row.id}
                headers={headers}
                data={row}
                collapsible={collapsible}
                extraColumns={extraColumns}
                checkboxColumnsNames={checkboxColumnsNames}
                collapseOptions={collapseOptions}
                allCollapsed={allCollapsed}
                selectRowsOptions={selectRowsOptions}
                deleteRowsOptions={deleteRowsOptions}
                changeRow={changeRow}
                addNewRow={addNewRow}
                newRow
                onChangeCountOfOpenedRows={handleChangeCountOfOpenedRows}
                onRowClick={onRowClick && onRowClick(row)}
                disableAction={disableAction}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

export default EditableTable
