import React, { MouseEvent, useCallback, useState } from 'react'
import { Props } from './types'
import { useClasses } from './styles'
import clsx from 'clsx'
import { ExpandMore, Image } from '@material-ui/icons'
import Box from '@material-ui/core/Box'
import { formatIfDate } from '@/components/controls/AppTable/utils/formatIfDate'
import Checkbox from '@material-ui/core/Checkbox'

import Tooltip from '@/components/blocks/Tooltip'

function handleImage(this: any, e: any) {
  e.stopPropagation()
  console.log(e)
}

const TableBodyCellContent = ({
  value,
  groupBy,
  closed,
  withTooltip,
}: Props): React.ReactElement => {
  const classes = useClasses()

  if (value && typeof value === 'object') {
    if (value.backgroundColor || value.color) {
      return (
        <Box display="flex" alignItems="center">
          {value.isGroupHeader && (
            <ExpandMore
              className={classes.expandIcon}
              transform={closed ? 'rotate(-90)' : undefined}
            />
          )}
          <div
            className={clsx({
              [classes.cellWrapper]: value.backgroundColor || value.color,
              [classes.cellWrapperWithBackground]: value.backgroundColor,
              [classes.nonHeaderShift]: groupBy && !value.isGroupHeader,
              [classes.groupHeader]: value.isGroupHeader,
            })}
            style={value.backgroundColor ? { backgroundColor: value.backgroundColor } : {}}
          >
            {value.color && (
              <div className={classes.cellColor} style={{ backgroundColor: value.color }} />
            )}
            {value.component || <span style={value.styles}>{formatIfDate(value.value)}</span>}
          </div>
        </Box>
      )
    }

    if (value.icon) {
      if (value.icon === 'action') {
        return (
          <Box display="flex" alignItems="center">
            <div
              // onClick={handleClick}
              className={clsx({
                [classes.iconWrapper]: value.icon,
              })}
            >
              <Image onClick={handleImage} color="action" />
            </div>
          </Box>
        )
      } else {
        return (
          <Box display="flex" alignItems="center">
            <div
              className={clsx({
                [classes.iconWrapper]: value.icon,
              })}
            >
              <Image onClick={handleImage} color="disabled" />
            </div>
          </Box>
        )
      }
    }

    return (
      <Box display="flex" alignItems="center">
        {value.isGroupHeader && (
          <ExpandMore
            className={classes.expandIcon}
            transform={closed ? 'rotate(-90)' : undefined}
          />
        )}
        <span
          className={clsx({
            [classes.nonHeaderShift]: groupBy && !value.isGroupHeader,
            [classes.groupHeader]: value.isGroupHeader,
          })}
          style={value.styles}
        >
          {value.component || value.value}
        </span>
      </Box>
    )
  }

  if (typeof value === 'boolean') {
    return (
      <span>
        <Checkbox checked={value} />
      </span>
    )
  }

  return withTooltip ? (
    <Tooltip title={value}>
      <span
        className={clsx({
          [classes.nonHeaderShift]: groupBy,

          [classes.withTooltip]: withTooltip,
          [classes.contentItemTextWithTooltip]: withTooltip,
        })}
      >
        {value}
      </span>
    </Tooltip>
  ) : (
    <span
      className={clsx({
        [classes.nonHeaderShift]: groupBy,
      })}
    >
      {value}
    </span>
  )
}

export default TableBodyCellContent
