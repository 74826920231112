import { createSelector, Selector } from 'reselect'

import { getInvalidedCassetteIds } from '@/components/pages/cm/CitPlanningPage/components/panels/DetailsPanel/components/SingleWorkOrder/components/WorkOrder/Footer/components/CassetteErrorHelpTooltip/helpers'
import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getPlanningState = (state: AppState): fromReducer.State => state.cmCitPlanning

export const getCitPlnCashPoints = createSelector(getPlanningState, state => state.cashPoints)
export const getCitPlnCashPointsTotal = createSelector(getCitPlnCashPoints, state => state.total)
export const getCitPlnFilter = createSelector(getCitPlnCashPoints, state => state.filter)
export const getCitPlnReports = createSelector(getCitPlnCashPoints, state => state.reports)
export const getCitPlnSelectedCP = createSelector(
  getCitPlnCashPoints,
  state => state.selectedCashPoint,
)

export const getCitPlnWorkOrders = createSelector(getPlanningState, state => state.workOrders)
export const getCitPlnWorkOrdersTotal = createSelector(getCitPlnWorkOrders, state => state.total)
export const getCitPlnWorkOrdersFilter = createSelector(getCitPlnWorkOrders, state => state.filter)
export const getCitPlnWorkOrdersSort = createSelector(getCitPlnWorkOrders, state => ({
  sortColumn: state.sortColumn,
  sortOrder: state.sortOrder,
}))
export const getCitPlnWorkOrdersPaging = createSelector(getCitPlnWorkOrders, state => state.paging)
export const getCitPlnWorkOrdersFormattedPaging = createSelector(
  getCitPlnWorkOrdersPaging,
  state => {
    const { page, pageSize } = state || {}

    return {
      page,
      pageSize: pageSize === -1 ? 'All' : pageSize,
    }
  },
)

export const getCitPlnCheckedTeams = createSelector(getPlanningState, state => state.teamsChecked)
export const getCitPlnWorkOrderItems = createSelector(
  getPlanningState,
  state => state.workOrder.data,
)
export const getSelectedWorkOrder = createSelector(
  getCitPlnWorkOrders,
  state => state.selectedWorkOrder,
)

export const getCitPlnWorkOrdersReports = createSelector(
  getCitPlnWorkOrders,
  state => state.reports,
)

export const getCitPlnDetailsSelectedTab = createSelector(
  getPlanningState,
  state => state.detailsSelectedTab,
)

export const getCitPlnSelectedOrderType = createSelector(
  getPlanningState,
  state => state.selectedOrderType,
)

export const getCitPlnUnloadOrder = createSelector(getPlanningState, state => state.unloadOrder)
export const getCitPlnRepairOrder = createSelector(getPlanningState, state => state.repairOrder)

export const getCitPlnCheckedPoints = (
  key: 'Load' | 'Repair' | 'Unload' | 'None',
): Selector<AppState, Set<string>> =>
  createSelector(getPlanningState, state => {
    const { checkedLoads, checkedUnloads, checkedRepairs } = state.cashPoints
    switch (key) {
      case 'Load':
        return checkedLoads
      case 'Repair':
        return checkedRepairs
      case 'Unload':
        return checkedUnloads

      default:
        return new Set('')
    }
  })

export const getCitPlnIsCheckedPoint = (
  key: 'Load' | 'Repair' | 'Unload' | 'None',
  sessionId: string,
): Selector<AppState, boolean> =>
  createSelector(getPlanningState, state => {
    const { checkedLoads, checkedUnloads, checkedRepairs } = state.cashPoints
    switch (key) {
      case 'Load':
        return checkedLoads.has(sessionId)
      case 'Repair':
        return checkedRepairs.has(sessionId)
      case 'Unload':
        return checkedUnloads.has(sessionId)

      default:
        return false
    }
  })

export const getCitPlnTeamsModalOpenState = createSelector(
  getPlanningState,
  ({ teamsModalState }) => teamsModalState,
)

export const getCitTeams = createSelector(getPlanningState, state => state.teams)
export const getCitTeamsData = createSelector(getCitTeams, state => state.data)
export const getCitPlnSelectedTeam = createSelector(getCitTeams, state => state.selected)
export const getCitPlnTeamsFilter = createSelector(getCitTeams, state => state.filter)
export const getCitPlnTeamsReports = createSelector(getCitTeams, state => state.reports)
export const getCitTeamsSort = createSelector(getCitTeams, state => ({
  sortColumn: state.sortColumn,
  sortOrder: state.sortOrder,
}))
export const getIsSelected = (teamId: string): Selector<AppState, boolean> =>
  createSelector(getCitTeams, ({ selected }) => teamId === selected?.id)

export const getCitPlnModalOpenState = createSelector(
  getPlanningState,
  ({
    declineModalOpenType,
    isCalculateModalOpen,
    isDeclineNoteModalOpen,
    isDocumentModalOpen,
    isFiltersModalOpen,
    isSettingsModalOpen,
    isUnloadConfirmationModalOpen,
    isUpperLimitsModalOpen,
    isWorkOrdersDocumentModalOpen,
    isWorkOrdersFiltersModalOpen,
  }) => ({
    declineModalOpenType,
    isCalculateModalOpen,
    isDeclineNoteModalOpen,
    isDocumentModalOpen,
    isFiltersModalOpen,
    isSettingsModalOpen,
    isUnloadConfirmationModalOpen,
    isUpperLimitsModalOpen,
    isWorkOrdersDocumentModalOpen,
    isWorkOrdersFiltersModalOpen,
  }),
)

export const getCitPlnCashPointProperties = createSelector(
  getCitPlnCashPoints,
  state => state.properties,
)

export const getCitPlnWorkOrder = createSelector(getPlanningState, state => state.workOrder)
export const getCitPlnSelectedWorkOrder = createSelector(
  getCitPlnWorkOrder,
  state => state.selectedWorkOrder,
)
export const getCitPlnEditedCassettes = createSelector(
  getCitPlnWorkOrder,
  state => state.editedCassettes,
)

export const getCitPlnWorkOrderFilter = createSelector(getCitPlnWorkOrder, state => state.filter)

export const getCitPlnWOisAllOrders = createSelector(getCitPlnWorkOrder, state => state.isAllOrders)

export const getCitPlnWOReports = createSelector(getCitPlnWorkOrder, state => state.reports)
export const getCitPlnWOReportsModal = createSelector(
  getCitPlnWorkOrder,
  state => state.workOrderReportsModal,
)

export const getCitPlnWorkOrderState = createSelector(getPlanningState, state => {
  const { isAllOrders, data: woCassettes, selectedWorkOrder } = state.workOrder

  if (woCassettes.cassettes?.length) {
    const { orderStatus: orderStatusWo } = selectedWorkOrder || {}
    const { cassettes } = woCassettes

    return (
      orderStatusWo === 'SentToExecute' ||
      orderStatusWo === 'CashDelivery' ||
      (isAllOrders &&
        cassettes.some(
          cassette =>
            cassette.orderStatus === 'SentToExecute' || cassette.orderStatus === 'CashDelivery',
        ))
    )
  }
  return false
})

// Cassette Sets
export const getCitCassetteTypes = createSelector(getPlanningState, state => state.cassetteTypes)
export const getCitCassetteSets = createSelector(getPlanningState, state => state.cassetteSets)
export const getCitSelectedCassetteSet = createSelector(
  getCitCassetteSets,
  state => state.selectedCassetteSet,
)
export const getCitIsUpdatingCassetteSet = createSelector(
  getCitCassetteSets,
  state => state.isUpdating,
)
export const getCitCassetteSetsSort = createSelector(getCitCassetteSets, state => ({
  sortColumn: state.sortColumn,
  sortOrder: state.sortOrder,
}))
export const getCitCassetteSetsNames = createSelector(getPlanningState, state =>
  state.cassetteSets.data.map(cassette => cassette.name),
)

interface GetCassetteValidationProps {
  cassetteNr?: number
  orderId?: string
}

export const getCitAllCassettesValidation = createSelector(getPlanningState, state => {
  return state.workOrder.cassetteValidation
})
export const getCitIsCassettesErrors = (filteredValidation: string[]) =>
  createSelector(getPlanningState, state => {
    const { cassetteValidation, data } = state.workOrder

    return filteredValidation.some(
      workOrderId => !!getInvalidedCassetteIds({ cassetteValidation, data, workOrderId }).length,
    )
  })

export const getCitCassetteValidation = ({ cassetteNr, orderId }: GetCassetteValidationProps) =>
  createSelector(getPlanningState, state => {
    const { cassetteValidation } = state.workOrder

    const cassette = cassetteValidation[orderId || '']

    return cassetteNr && cassette ? cassette[cassetteNr] : undefined
  })

export const getCitWOApplyButtonDisabled = createSelector(getPlanningState, state => {
  const { cassetteValidation, selectedWorkOrder } = state.workOrder

  const validation = cassetteValidation[selectedWorkOrder?.sessionId || '']

  if (validation) {
    const cassetteNrs = selectedWorkOrder?.cassetteEntries
      ?.filter(cassette => cassette.currency?.name !== 'MIX')
      .map(cassette => cassette.cassetteNr)

    return cassetteNrs?.every(cassetteNr => validation[Number(cassetteNr)]?.isValid || false)
  }

  return false
})
