import { ComFilterTreeNode } from '@/api/cm/swaggerGeneratedApi'

import { FoldersWithCollapseProps, ParseElementPathProps } from './types'

export const countSubLeafs = (root: ComFilterTreeNode): number => {
  let count = root.leafs?.length || 0
  if (root.folders) {
    root.folders.forEach(node => {
      count += countSubLeafs(node)
    })
  }
  return count
}

export const parseElementPath = ({
  folders,
  path,
  index = 0,
  prevResult = [],
}: ParseElementPathProps): string[] => {
  const result = prevResult
  if (path.length === index) {
    return result
  }

  const foundedElement = folders.find(folder => folder.name === path[index])

  if (foundedElement?.id && foundedElement?.folders) {
    result.push(foundedElement.id)

    return parseElementPath({
      folders: foundedElement.folders,
      path,
      index: ++index,
      prevResult: result,
    })
  }
  return result
}

export const appFoldersWithCollapse = ({ folders }: FoldersWithCollapseProps): string[] => {
  if (Array.isArray(folders)) {
    return folders.reduce((done: string[], current) => {
      const hasFolders = !!current.folders?.length
      const hasLeafs = !!current.leafs?.length

      if (current?.id) {
        if (!hasFolders && !hasLeafs) {
          return done
        }
        if (!hasFolders && hasLeafs) {
          return done.concat([current.id])
        } else {
          const elem = appFoldersWithCollapse({ folders: current?.folders || [] })
          return done.concat([current.id, ...elem])
        }
      }
      return []
    }, [])
  } else {
    return folders
  }
}
