import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  subButton: {
    padding: spacing(2, 0),
  },
  devider: {
    height: 1,
    backgroundColor: palette.grey[100],
    width: 682,
    position: 'absolute',
    top: spacing(11.5),
    left: `-${spacing(3)}px`,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: spacing(2.5),
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: spacing(2),
  },
  companyDevider: {
    height: 1,
    backgroundColor: palette.grey[100],
    width: 682,
    left: `-${spacing(3)}px`,
  },
}))
