export enum AtmeyeAlarmsByDeviceActions {
  ChangeSelectedFilters = '@/ALARMS_BY_DEVICE/CHANGE_SELECTED_FILTERS',
  ChangeGrouping = '@/ALARMS_BY_DEVICE/CHANGE_GROUPING',
  ChangeSorting = '@/ALARMS_BY_DEVICE/CHANGE_SORTING',
  SetRowPerPage = '@/ALARMS_BY_DEVICE/SET_ROW_PER_PAGE',
  SetPage = '@/ALARMS_BY_DEVICE/SET_PAGE',

  DeleteFilterTemplateResponse = '@/ALARMS_BY_DEVICE/DELETE_FILTER_TEMPLATE_RESPONSE',

  GetFiltersTemplatesRequest = '@/ALARMS_BY_DEVICE/GET_FILTERS_TEMPLATES_REQUEST',
  SetGroupTemplates = '@/ALARMS_BY_DEVICE/SET_GROUP_TEMPLATES',
  SetSelectedGroupingTemplate = '@/ALARMS_BY_DEVICE/SET_SELECTED_GROUPING_TEMPLATE',

  GetTransactionsConfigRequest = '@/ALARMS_BY_DEVICE/GET_ALARMS_BY_DEVICE_CONFIG_REQUEST',
  GetTransactionsRequest = '@/ALARMS_BY_DEVICE/GET_ALARMS_BY_DEVICE_REQUEST',
  GetTransactionsResponse = '@/ALARMS_BY_DEVICE/GET_ALARMS_BY_DEVICE_RESPONSE',
  GetGroupDetailsRequest = '@/ALARMS_BY_DEVICE/GET_GROUP_DETAILS_REQUEST',
  SetParentGroups = '@/ALARMS_BY_DEVICE/SET_PARENT_GROUPS',
  SetCurrentFilterTemplate = '@/ALARMS_BY_DEVICE/SET_CURRENT_FILTER_TEMPLATE',
  GetMedia = '@/ALARMS_BY_DEVICE/GET_MEDIA',
  SetMedia = '@/ALARMS_BY_DEVICE/SET_MEDIA',
  ClearCurrentMedia = '@/ALARMS_BY_DEVICE/CLEAR_CURRENT_MEDIA',
  SetCurrentMedia = '@/ALARMS_BY_DEVICE/SET_CURRENT_MEDIA',
  SetMediaErrorMessage = '@/ALARMS_BY_DEVICE/SET_MEDIA_ERROR_MESSAGE',
  SetSdFilterTemplateResponse = '@/ALARMS_BY_DEVICE/SET_FILTER_TEMPLATE_RESPONSE',
  EditSdGroupTemplateFromResponse = '@/ALARMS_BY_DEVICE/EDIT_SD_GROUP_TEMPLATE_FROM_RESPONSE',
  CreateSdFilterTemplateResponse = '@/ALARMS_BY_DEVICE/CREATE_SD_FILTER_TEMPLATE_RESPONSE',
  EditTableCellRequest = '@/ALARMS_BY_DEVICE/EDIT_TABLE_CELL_REQUEST',
  EditTableCellGrouping = '@/ALARMS_BY_DEVICE/EDIT_TABLE_CELL_REQUEST_GROUPING',
  SaveTableCell = '@/ALARMS_BY_DEVICE/SAVE_TABLE_CELL',
  SaveTableCellGrouping = '@/ALARMS_BY_DEVICE/SAVE_TABLE_CELL_GROUPING',
  GetDataPagination = '@/ALARMS_BY_DEVICE/GET_DATA_PAGINATION',
  SetDataPagination = '@/ALARMS_BY_DEVICE/SET_DATA_PAGINATION',

  CollectFilterTemplateFields = '@/ALARMS_BY_DEVICE/COLLECT_FILTER_TEMPLATE_FIELDS',
  GetTicketsConfigResponse = '@/ALARMS_BY_DEVICE/GET_TICKETS_CONFIG_RESPONSE',
  UpdateSdFilterTemplateResponse = '@/ALARMS_BY_DEVICE/UPDATE_SD_FILTER_TEMPLATE_RESPONSE',
  ReactionToAlarm = '@/ALARMS_BY_DEVICE/REACTION_TO_ALARMS',
  SetReactionDateToAlarm = '@/ALARMS_BY_DEVICE/SET_REACTION_DATE_TO_ALARM',

  ClearAlarmsFlatDataByDevice = '@/TRANSACTIONS_BY_DEVICE/CLEAR_ALARMS_DATA_BY_DEVICE',
  UpdateTableRow = '@/TRANSACTIONS_BY_DEVICE/UPDATE_TABLE_ROW',

  SetFilterModalState = '@/ALARMS_BY_DEVICE/SET_FILTER_MODAL_STATE',
  GetTicketResponseFail = '@/ALARMS_BY_DEVICE/GET_TICKETS_RESPONSE_FAIL',
  IsSubmitFiltersButtonClicked = '@/ALARMS_BY_DEVICE/IS_SUBMIT_FILTERS_BUTTON_CLICKED',
}
