import React from 'react'

import GlobalStyle from '@/theme/GlobalStyle'
import { materialTheme, productsTheme, styledComponentTheme } from '@/theme'
import { DefaultTheme, ThemeProvider as StyledThemeProvider } from 'styled-components'
import { ThemeProvider } from '@material-ui/core/styles'
import { Props, ThemeName } from './types'

const themes: Record<string, DefaultTheme> = {
  materialTheme,
  productsTheme,
}

const getTheme = (themeName: ThemeName): any => {
  return themes[themeName]
}

export const ThemeContext = React.createContext(getTheme(ThemeName.ProductsTheme))

const ThemeProviderWrapper = ({ children }: Props): React.ReactElement => {
  const [themeName, setThemeName] = React.useState<ThemeName>(ThemeName.MaterialTheme)

  const theme = getTheme(themeName)

  return (
    <ThemeContext.Provider value={setThemeName}>
      <ThemeProvider theme={theme}>
        <StyledThemeProvider theme={styledComponentTheme}>
          <>
            <GlobalStyle />
            {children}
          </>
        </StyledThemeProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

export default ThemeProviderWrapper
