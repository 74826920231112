export const COLUMNS = [
  {
    fieldName: 'trackerNetworkStatus',
    fieldLabel: 'atmeye.columns.title.trackerNetworkStatus',
    sortField: 'trackerNetworkStatus',
  },
  {
    fieldName: 'trackerPower',
    fieldLabel: 'atmeye.columns.title.trackerPower',
    sortField: 'trackerPower',
  },
  {
    fieldName: 'isTrackerGpsValid',
    fieldLabel: 'atmeye.columns.title.isTrackerGpsValid',
    sortField: 'isTrackerGpsValid',
  },
  {
    fieldName: 'coordinates',
    fieldLabel: 'atmeye.columns.title.coordinates',
    sortField: 'coordinates',
  },
  {
    fieldName: 'traceRoute',
    fieldLabel: 'atmeye.columns.title.traceRoute',
    sortField: 'traceRoute',
  },
  {
    fieldName: 'operator',
    fieldLabel: 'atmeye.columns.title.operator',
    sortField: 'operator',
  },
  // {
  //   fieldName: 'isDeviceTimeSynchronized',
  //   fieldLabel: 'atmeye.columns.title.isDeviceTimeSynchronized',
  //   sortField: 'isDeviceTimeSynchronized',
  // },

  {
    fieldName: 'deviceModel',
    fieldLabel: 'atmeye.columns.title.deviceModel',
    sortField: 'deviceModel',
  },
  {
    fieldName: 'deviceName',
    fieldLabel: 'atmeye.columns.title.deviceName',
    sortField: 'deviceName',
  },
  {
    fieldName: 'city',
    fieldLabel: 'atmeye.columns.title.city',
    sortField: 'city',
  },
  {
    fieldName: 'address',
    fieldLabel: 'atmeye.columns.title.address',
    sortField: 'address',
  },
  {
    fieldName: 'connectivityStatus',
    fieldLabel: 'atmeye.columns.title.connectivityStatus',
    sortField: 'connectivityStatus',
  },
  {
    fieldName: 'coreStatus',
    fieldLabel: 'atmeye.columns.title.coreStatus',
    sortField: 'coreStatus',
  },

  {
    fieldName: 'licenseStatus',
    fieldLabel: 'atmeye.columns.title.licenseStatus',
    sortField: 'licenseStatus',
  },
  {
    fieldName: 'lastHourTechnicalEventsCount',
    fieldLabel: 'atmeye.columns.title.lastHourTechnicalEventsCount',
    sortField: 'lastHourTechnicalEventsCount',
  },
  {
    fieldName: 'lastHourSecurityAlarmsCount',
    fieldLabel: 'atmeye.columns.title.lastHourSecurityAlarmsCount',
    sortField: 'lastHourSecurityAlarmsCount',
  },
  {
    fieldName: 'lastHourTransactionsCount',
    fieldLabel: 'atmeye.columns.title.lastHourTransactionsCount',
    sortField: 'lastHourTransactionsCount',
  },

  {
    fieldName: 'country',
    fieldLabel: 'atmeye.columns.title.country',
    sortField: 'country',
  },
  {
    fieldName: 'serialNumber',
    fieldLabel: 'atmeye.columns.title.serialNumber',
    sortField: 'serialNumber',
  },
  {
    fieldName: 'vendorCompany',
    fieldLabel: 'atmeye.columns.title.vendorCompany',
    sortField: 'vendorCompany',
  },
  {
    fieldName: 'deviceType',
    fieldLabel: 'atmeye.columns.title.deviceType',
    sortField: 'deviceType',
  },
  {
    fieldName: 'lastActivityDateTime',
    fieldLabel: 'atmeye.columns.title.lastActivityDateTime',
    sortField: 'lastActivityDateTime',
  },

  {
    fieldName: 'aggregatedStatus',
    fieldLabel: 'atmeye.columns.title.aggregatedStatus',
    sortField: 'aggregatedStatus',
  },
]
