import { useState } from 'react'
import { BackupValues, BackupValue } from './types'

export const useBackupValues = () => {
  const [backupValues, setBackupValues] = useState<BackupValues | {}>({})

  const setBackupValue = (key: string, value: BackupValue): void => {
    setBackupValues({ ...backupValues, [key]: value })
  }

  return { backupValues, setBackupValue, setBackupValues }
}
