import { Button, Grid, Paper, Typography } from '@material-ui/core'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ErrorBoundary } from '@/components/wrappers/ErrorBoundary'
import { getMultipleErrors, setMultipleErrorsInitial } from '@/store/common/errors'
import { useTranslate } from '@/utils/internalization'

import CloseButton from '../../CloseButton'
import { DraggableDialog } from '../DraggableDialog'
import { EmptyPanel } from '../EmptyPanel'
import { useClasses } from './styles'

export const MultipleErrorsModal: FC = () => {
  const classes = useClasses()
  const translate = useTranslate()
  const dispatch = useDispatch()

  const { errorMessage, messageArray } = useSelector(getMultipleErrors)

  const [currentErrorIndex, setCurrentErrorIndex] = useState<number | null>(null)

  useEffect(() => {
    if (messageArray?.[0]) {
      setCurrentErrorIndex(0)
    }
  }, [messageArray])

  const onClose = (): void => {
    dispatch(setMultipleErrorsInitial())
  }

  const handleNavigate = (step: 'next' | 'prev') => (): void => {
    setCurrentErrorIndex(prevIndex =>
      step === 'next' ? (prevIndex || 0) + 1 : (prevIndex || 0) - 1,
    )
  }

  return (
    <DraggableDialog
      open
      id="multipleErrorsModal"
      maxWidth={false}
      fullWidth
      onClose={onClose}
      classes={{ paper: classes.modal }}
    >
      <CloseButton onClick={onClose} absolute />
      <Paper className={classes.paper}>
        <Grid item md={12}>
          <Typography variant="h3" className={classes.modalHeader}>
            {errorMessage || translate('translate#cm.ErrorWindowGenericError')}
          </Typography>
        </Grid>
        <Grid item md={12}>
          <ErrorBoundary>
            <div>
              {currentErrorIndex !== null ? (
                <div className={classes.message}>
                  <Typography variant="body1">{messageArray[currentErrorIndex]}</Typography>
                </div>
              ) : (
                <EmptyPanel />
              )}

              <div className={classes.footer}>
                {currentErrorIndex !== null && (
                  <div className={classes.navigate}>
                    <Button
                      className={classes.navigateButton}
                      variant="contained"
                      disabled={currentErrorIndex === 0}
                      onClick={handleNavigate('prev')}
                    >
                      <NavigateBeforeIcon className={classes.navigateIcon} />
                    </Button>
                    <Button
                      style={{ rotate: '180deg' }}
                      className={classes.navigateButton}
                      variant="contained"
                      disabled={currentErrorIndex === messageArray.length - 1}
                      onClick={handleNavigate('next')}
                    >
                      <NavigateBeforeIcon className={classes.navigateIcon} />
                    </Button>
                  </div>
                )}
                <Button variant="outlined" className={classes.closeButton} onClick={onClose}>
                  {translate('translate#cm.ButtonClose')}
                </Button>
              </div>
            </div>
          </ErrorBoundary>
        </Grid>
      </Paper>
    </DraggableDialog>
  )
}
