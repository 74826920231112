import { AnyAction } from 'redux'

import { AdmImportForecastSetting } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants/actions'

export type State = {
  data: AdmImportForecastSetting[]

  isLoaded: boolean
  isLoading: boolean
}

export const initialState: State = {
  data: [],
  isLoaded: false,
  isLoading: false,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case CMAppConfigActions.AdmSaveForecastSettingsRequest:
    case CMAppConfigActions.AdmGetForecastSettingsRequest:
      return {
        ...state,
        isLoading: true,
      }
    case CMAppConfigActions.AdmGetForecastSettingsResponse:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: payload,
      }
    case CMAppConfigActions.AdmSaveForecastSettingsFail:
    case CMAppConfigActions.AdmGetForecastSettingsFail:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      }
    default:
      return state
  }
}
