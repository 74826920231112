import { getUsersSettings } from '@/helpers/localStorage'

export const useTableWithBorder = (): boolean => {
  const { pathname } = window.location
  const [, baseUrl] = pathname.split('/')

  const { allUsersSettings, userData } = getUsersSettings(baseUrl)

  const currentUserSettings = allUsersSettings[userData?.login || '']

  return currentUserSettings?.isTablesWithBorder || false
}
