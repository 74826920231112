import { DayOffItem, DutySchedule, QualificationLevel, SalaryItem } from './types'

export const getDeletedDayoffsIds = (
  initialDayoffs: DayOffItem[],
  currentDayoffs: DayOffItem[],
): number[] => {
  const deletedDayoffsIds: number[] = []

  initialDayoffs.forEach((dayoff: DayOffItem) => {
    const found = currentDayoffs.some(
      el => el.companyPersonnelDayOffId === dayoff.companyPersonnelDayOffId,
    )
    if (!found && dayoff.companyPersonnelDayOffId)
      deletedDayoffsIds.push(dayoff.companyPersonnelDayOffId)
  })

  return deletedDayoffsIds
}

export const getDeletedSalaryIds = (
  initialSalaries: SalaryItem[],
  currentSalaries: SalaryItem[],
): number[] => {
  const deletedSalariesIds: number[] = []

  initialSalaries.forEach((salary: SalaryItem) => {
    const found = currentSalaries.some(
      el => el.companyPersonnelSalaryId === salary.companyPersonnelSalaryId,
    )
    if (!found && salary.companyPersonnelSalaryId)
      deletedSalariesIds.push(salary.companyPersonnelSalaryId)
  })

  return deletedSalariesIds
}

export const getDeletedQualificationIds = (
  initialQualifications: QualificationLevel[],
  currentQualifications: QualificationLevel[],
): number[] => {
  const deletedQualificationsIds: number[] = []

  initialQualifications.forEach((qualification: QualificationLevel) => {
    const found = currentQualifications.some(
      el => el.companyPersonnelQLevId === qualification.companyPersonnelQLevId,
    )
    if (!found && qualification.companyPersonnelQLevId)
      deletedQualificationsIds.push(qualification.companyPersonnelQLevId)
  })

  return deletedQualificationsIds
}

export const getDeletedDutyScheduleIds = (
  initialDutySchedules: DutySchedule[],
  currentDutySchedules: DutySchedule[],
): number[] => {
  const deletedDutyScheduleIds: number[] = []

  initialDutySchedules.forEach((dutySchedule: DutySchedule) => {
    const found = currentDutySchedules.some(
      el => el.companyPersonnelDutyScheduleId1 === dutySchedule.companyPersonnelDutyScheduleId1,
    )
    if (!found && dutySchedule.companyPersonnelDutyScheduleId1)
      deletedDutyScheduleIds.push(dutySchedule.companyPersonnelDutyScheduleId1)

    if (!found && dutySchedule.companyPersonnelDutyScheduleId2)
      deletedDutyScheduleIds.push(dutySchedule.companyPersonnelDutyScheduleId2)
  })

  return deletedDutyScheduleIds
}
