import { AnyAction } from 'redux'

import { AdmValuable, ValuableLanguage } from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants/actions'

export type State = {
  languages: ValuableLanguage[]

  data: AdmValuable[]
  total: number
  isLoading: boolean
  isLoaded: boolean
  error: string | null

  sortColumn?: string
  sortOrder?: 'asc' | 'desc'

  selected: AdmValuable | null
}

export const initialState: State = {
  languages: [],

  data: [],
  total: 0,
  isLoading: false,
  isLoaded: false,
  error: null,

  sortColumn: undefined,
  sortOrder: undefined,

  selected: null,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case VaultV1Actions.AdmGetValuablesRequest:
      return {
        ...state,
        isLoading: true,
      }
    case VaultV1Actions.AdmGetValuablesResponse:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: payload || [],
        selected: !state.selected ? payload[0] || null : state.selected,
        total: (payload || []).length || 0,
      }

    case VaultV1Actions.AdmGetValuablesFail:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: payload,
      }

    case VaultV1Actions.AdmAddValuableRequest:
    case VaultV1Actions.AdmUpdateValuableRequest:
    case VaultV1Actions.AdmDeleteValuableRequest:
      return {
        ...state,
        isLoading: true,
      }

    case VaultV1Actions.AdmAddValuableFail:
    case VaultV1Actions.AdmUpdateValuableFail:
    case VaultV1Actions.AdmDeleteValuableFail:
      return {
        ...state,
        isLoading: false,
      }

    case VaultV1Actions.AdmSetSelectedValuable:
      return {
        ...state,
        selected: payload || null,
      }

    case VaultV1Actions.AdmSortValuables:
      return {
        ...state,
        ...payload,
      }

    case VaultV1Actions.AdmGetValuablesLanguagesResponse:
      return {
        ...state,
        languages: payload || [],
      }

    case VaultV1Actions.SignOut:
      return initialState
    default:
      return state
  }
}
