import { AnyAction } from 'redux'

import { UsrChangePasswordRequest } from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants/actions/index'
import { DataToLS } from '@/types/cm/localStorage'

export const setUserCM = (payload: any): AnyAction => ({
  type: CMActions.SetUserCM,
  payload,
})

export const signInRequestCM = (values: { username: string; password: string }): AnyAction => ({
  type: CMActions.SignInRequest,
  payload: values,
})

export const signInResponseCM = (displayName: string): AnyAction => ({
  type: CMActions.SignInResponse,
  payload: displayName,
})

export const signInResponseFailCM = (error: string): AnyAction => ({
  type: CMActions.SignInResponseFail,
  payload: error,
})

export const signOutCM = (withLogout?: boolean): AnyAction => ({
  type: CMActions.SignOut,
  payload: withLogout,
})

export const getCultures = (): AnyAction => ({
  type: CMActions.GetCultures,
})

export const setServerCulture = (culture?: string): AnyAction => ({
  type: CMActions.SetServerCulture,
  culture,
})

export const changePasswordRequest = (payload: UsrChangePasswordRequest): AnyAction => ({
  type: CMActions.ChangePasswordRequest,
  payload,
})

export const changePasswordResponse = (payload: any): AnyAction => ({
  type: CMActions.ChangePasswordResponse,
  payload,
})

export const changePasswordResponseFail = (error: string): AnyAction => ({
  type: CMActions.ChangePasswordResponseFail,
  payload: error,
})

export const setChangePasswordModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.SetChangePasswordModalOpen,
  payload: isOpen,
})

export const checkIsLoginCM = (): AnyAction => ({
  type: CMActions.CheckIsLogin,
})

export const setDataToLS = (payload: DataToLS): AnyAction => ({
  type: CMActions.SetDataToLS,
  payload,
})
