import React, { useState, useCallback, ChangeEvent } from 'react'
import { debounce } from 'lodash-es'
import { FormattedMessage } from 'react-intl'
import { PopUpService } from '@/utils/services/popUpService'
import { useClasses } from './styles'
import { Props } from './types'

const InputPagination = ({
  totalPages,
  rightPagin,
  paginInputCenter,
  onChangePage,
  disabled,
}: Props): React.ReactElement => {
  const [value, setValue] = useState<string>('')

  const classes = useClasses({ rightPagin, paginInputCenter })

  const handleJumpPage = useCallback(
    debounce((page: number) => {
      if (page === 0 || page > totalPages) {
        PopUpService.showGlobalPopUp('The required page does not exist', 'warning')
        return setValue('')
      }

      onChangePage('', page)
    }, 1000),
    [totalPages, onChangePage],
  )

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.match('^[0-9]+$') || !event.target.value) {
        setValue(event.target.value)
        handleJumpPage(parseInt(event.target.value))
      }
    },
    [handleJumpPage],
  )

  return (
    <div className={classes.wrapperInput}>
      <div className={classes.container}>
        <span>
          <FormattedMessage id="title.goToPage" defaultMessage="Go to page" />
        </span>
      </div>
      <div>
        <input
          type="text"
          value={value}
          className={classes.input}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default InputPagination
