import makeStyles from '@material-ui/core/styles/makeStyles'
import { styledComponentTheme } from '@/theme'
import { Theme } from '@material-ui/core/styles'

export const useClasses = makeStyles<Theme>(({ spacing }) => ({
  loaderWrapper: {
    height: spacing(5),
    width: spacing(5),
    margin: 'auto',
  },

  headerTitle: {
    fontWeight: 'bold',
    fontSize: styledComponentTheme.fontSizes.small,
    padding: spacing(1, 2),
    width: '100%',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },

  tableWrap: {
    alignItems: 'baseline',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
  },

  tableCell: {
    padding: spacing(0),
    width: `100%`,
  },

  contentItem: {
    display: 'block',
    padding: spacing(1.5, 2),
    overflow: 'hidden',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    width: 'inherit',
    height: spacing(8),
  },
}))
