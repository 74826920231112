export enum companiesActions {
  GetCompanyTypeRequest = '@/GET_COMPANY_TYPE_REQUEST',
  GetCompanyTypeResponse = '@/GET_COMPANY_TYPE_RESPONSE',
  GetCompanyTypeFail = '@/GET_COMPANY_TYPE_FAIL',

  GetCompanyRequest = '@/GET_COMPANY_REQUEST',
  GetCompanyResponse = '@/GET_COMPANY_RESPONSE',

  UpdateCompanyRequest = '@/UPDATE_COMPANY_REQUEST',
  UpdateCompanyResponse = '@/UPDATE_COMPANY_RESPONSE',
  UpdateCompanyResponseFail = '@/UPDATE_COMPANY_RESPONSE_FAIL',

  AddCompanyRequest = '@/ADD_COMPANY_REQUEST',
  AddCompanyResponse = '@/ADD_COMPANY_RESPONSE',
  AddCompanyResponseFail = '@/ADD_COMPANY_RESPONSE_FAIL',

  DeleteCompanyRequest = '@/DELETE_COMPANY_REQUEST',
  DeleteCompanyResponse = '@/DELETE_COMPANY_RESPONSE',
  DeleteCompanyFail = '@/DELETE_COMPANY_RESPONSE_FAIL',

  SetSelectedCompany = '@/SET_SELECTED_COMPANY',
  UnsetSelectedCompany = '@/UNSET_SELECTED_COMPANY',

  UnsetNewCompanyId = '@/UNSET_NEWCOMPANYID',

  SetSelectedDepartment = '@/SET_SELECTED_DEPARTMENT',
  UnsetSelectedDepartment = '@/UNSET_SELECTED_DEPARTMENT',

  SetSelectedPersonnel = '@/SET_SELECTED_PERSONNEL',
  UnsetSelectedPersonnel = '@/UNSET_SELECTED_PERSONNEL',

  GetDepartmentRequest = '@/GET_DEPARTMENT_REQUEST',
  GetDepartmentResponse = '@/GET_DEPARTMENT_RESPONSE',

  UpdateDepartmentRequest = '@/UPDATE_DEPARTMENT_REQUEST',

  AddDepartmentRequest = '@/ADD_DEPARTMENT_REQUEST',
  AddDepartmentResponse = '@/ADD_DEPARTMENT_RESPONSE',
  AddDepartmentResponseFail = '@/ADD_DEPARTMENT_RESPONSE_FAIL',

  DeleteDepartmentRequest = '@/DELETE_DEPARTMENT_REQUEST',
  DeleteDepartmentResponse = '@/DELETE_DEPARTMENT_RESPONSE',
  DeleteDepartmentFail = '@/DELETE_DEPARTMENT_RESPONSE_FAIL',

  GetPersonnelRequest = '@/GET_PERSONNEL_REQUEST',
  GetPersonnelResponse = '@/GET_PERSONNEL_RESPONSE',

  UpdatePersonnelRequest = '@/UPDATE_PERSONNEL_REQUEST',
  UpdatePersonnelResponse = '@/UPDATE_PERSONNEL_RESPONSE',
  UpdatePersonnelResponseFail = '@/UPDATE_PERSONNEL_RESPONSE_FAIL',

  AddPersonnelRequest = '@/ADD_PERSONNEL_REQUEST',
  AddPersonnelResponse = '@/ADD_PERSONNEL_RESPONSE',
  AddPersonnelResponseFail = '@/ADD_PERSONNEL_RESPONSE_FAIL',

  DeletePersonnelRequest = '@/DELETE_PERSONNEL_REQUEST',
  DeletePersonnelResponse = '@/DELETE_PERSONNEL_RESPONSE',
  DeletePersonnelFail = '@/DELETE_PERSONNEL_RESPONSE_FAIL',

  GetPersonnelSalariesRequest = '@/GET_PERSONNEL_SALARIES_REQUEST',
  GetPersonnelSalariesResponse = '@/GET_PERSONNEL_SALARIES_RESPONSE',

  GetPersonnelRolesHistoryRequest = '@/GET_PERSONNEL_ROLES_HISTORY_REQUEST',
  GetPersonnelRolesHistoryResponse = '@/GET_PERSONNEL_ROLES_HISTORY_RESPONSE',

  GetPersonnelQualificationLevelsRequest = '@/GET_PERSONNEL_QUALIFICATION_LEVELS_REQUEST',
  GetPersonnelQualificationLevelsResponse = '@/GET_PERSONNEL_QUALIFICATION_LEVELS_RESPONSE',

  GetPersonnelAddressRequest = '@/GET_PERSONNEL_ADDRESS_REQUEST',
  GetPersonnelAddressResponse = '@/GET_PERSONNEL_ADDRESS_RESPONSE',

  GetCompanyTreeBySearchFragmentRequest = '@/GET_COMPANYTREE_BY_FRAGMENT_REQUEST',
  GetCompanyTreeBySearchFragmentResponse = '@/GET_COMPANYTREE_BY_FRAGMENT_RESPONSE',

  GetCompanyTreeByCompanyIdRequest = '@/GET_COMPANYTREE_BY_COMPANYID_REQUEST',
  GetCompanyTreeByCompanyIdResponse = '@/GET_COMPANYTREE_BY_COMPANYID_RESPONSE',

  ClearCompanyTree = '@/CLEAR_COMPANYTREE',

  GetRolesRequest = '@/GET_INITIAL_ROLES_REQUEST',
  GetRolesResponse = '@/GET_INITIAL_ROLES_RESPONSE',

  GetWorkAreasRequest = '@/GET_WORK_AREAS_REQUEST',
  GetWorkAreasResponse = '@/GET_WORK_AREAS_RESPONSE',

  GetQualificationLevelsLookupRequest = '@/GET_QUALIFICATION_LEVELS_LOOKUP_REQUEST',
  GetQualificationLevelsLookupResponse = '@/GET_QUALIFICATION_LEVELS_LOOKUP_RESPONSE',

  GetCompaniesRequest = '@/GET_COMPANIES_REQUEST',
  GetCompaniesResponse = '@/GET_COMPANIES_RESPONSE',

  setInitialAdvancedUnits = '@SET_INITIAL_ADVANCED_UNITS',
  setInitialServiceCompanies = '@SET_INITIAL_SERVICE_COMPANIES',

  FindUnitsRequest = '@/FIND_UNITS_REQUEST',
  FindUnitsResponse = '@/FIND_UNITS_RESPONSE',
  FindUnitsResponseFail = '@/FIND_UNITS_RESPONSE_FAIL',

  GetServiceCompanyRequest = '@/GET_SERVICE_COMPANY_REQUEST',
  GetServiceCompanyResponse = '@/GET_SERVICE_COMPANY_RESPONSE',
  GetServiceCompanyeFail = '@/GET_SERVICE_COMPANY_FAIL',

  // Forms-configs actions
  GetCreateFormConfigRequest = '@/COMPANIES_GET_CREATE_FORM_CONFIG_REQUEST',
  GetCreateFormConfigResponse = '@/COMPANIES_GET_CREATE_FORM_CONFIG_RESPONSE',
  GetEditFormConfigRequest = '@/COMPANIES_GET_EDIT_FORM_CONFIG_REQUEST',
  GetEditFormConfigResponse = '@/COMPANIES_GET_EDIT_FORM_CONFIG_RESPONSE',
  GetFormConfigResponseFail = '@/COMPANIES_GET_FORM_CONFIG_RESPONSE_FAIL',

  GetDepartmentCreateFormConfigRequest = '@/COMPANIES_GET_DEPARTMENT_CREATE_FORM_CONFIG_REQUEST',
  GetDepartmentCreateFormConfigResponse = '@/COMPANIES_GET_DEPARTMENT_CREATE_FORM_CONFIG_RESPONSE',
  GetDepartmentEditFormConfigRequest = '@/COMPANIES_GET_DEPARTMENT_EDIT_FORM_CONFIG_REQUEST',
  GetDepartmentEditFormConfigResponse = '@/COMPANIES_GET_DEPARTMENT_EDIT_FORM_CONFIG_RESPONSE',
  GetDepartmentFormConfigResponseFail = '@/COMPANIES_GET_DEPARTMENT_FORM_CONFIG_RESPONSE_FAIL',

  // Import Companies -> Column mapping filter actions start
  ImportRequest = '@/COMPANIES_IMPORT_COMPANIES_REQUEST',
  ImportResponse = '@/COMPANIES_IMPORT_COMPANIES_RESPONSE',
  ImportResponseFail = '@/COMPANIES_IMPORT_COMPANIES_RESPONSE_FAIL',

  SetCurrentFilterTemplate = '@/COMPANIES_IMPORT_SET_CURRENT_FITLER_TEMPLATE',
  SetFilterTemplateResponse = '@/COMPANIES_IMPORT_SET_FILTER_TEMPLATE_RESPONSE',

  CreateFilterTemplateRequest = '@/COMPANIES_IMPORT_CREATE_FILTER_TEMPLATE_REQUEST',
  CreateFilterTemplateResponse = '@/COMPANIES_IMPORT_CREATE_FILTER_TEMPLATE_RESPONSE',

  UpdateFilterTemplateRequest = '@/COMPANIES_IMPORT_UPDATE_FILTER_TEMPLATE_REQUEST',
  UpdateFilterTemplateResponse = '@/COMPANIES_IMPORT_UPDATE_FILTER_TEMPLATE_RESPONSE',

  DeleteFilterTemplateRequest = '@/COMPANIES_IMPORT_DELETE_FILTER_TEMPLATE_REQUEST',
  DeleteFilterTemplateResponse = '@/COMPANIES_IMPORT_DELETE_FILTER_TEMPLATE_RESPONSE',

  GetFiltersTemplatesRequest = '@/COMPANIES_IMPORT_GET_FILTERS_TEMPLATES_REQUEST',
  GetFiltersTemplatesResponse = '@/COMPANIES_IMPORT_GET_FILTERS_TEMPLATES_RESPONSE',
  GetFiltersTemplatesResponseFail = '@/COMPANIES_IMPORT_GET_FILTERS_TEMPLATES_RESPONSE_FAIL',
  // Import Companies -> Column mapping filter actions end

  // Import Add to Security Networks Companies -> Column mapping filter actions start
  AddToSecurityNetworksRequest = '@/COMPANIES_IMPORT_COMPANIES_ADD_TO_SECURITY_NETWORKS_REQUEST',
  AddToSecurityNetworksResponse = '@/COMPANIES_IMPORT_COMPANIES_ADD_TO_SECURITY_NETWORKS_RESPONSE',
  AddToSecurityNetworksResponseFail = '@/COMPANIES_IMPORT_COMPANIES_ADD_TO_SECURITY_NETWORKS_RESPONSE_FAIL',
}
