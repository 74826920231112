import queryString from 'query-string'

import {
  CassetteBarcodeResponse,
  CassetteDetails,
  CassettesButtonsStatuses,
  CreateCassetteRequest,
  EditCassetteRequest,
  LoadCassetteRequest,
  UnloadCassetteRequest,
  UnloadCassetteToCashierRequest,
  VaultCassette,
  VaultCassettesRequest,
  VaultCassettesResponse,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { Paging, Sort } from '@/types/cm'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

interface GetAllProps {
  sort: Sort
  paging: Paging
  filter: VaultCassettesRequest
}
export class CassettesApi {
  private static requestService = getRequestServiceVaultV1()

  public static async getAll(props: GetAllProps): Promise<VaultCassettesResponse> {
    const { filter, paging, sort } = props

    try {
      const url = queryString.stringifyUrl(
        {
          url: '/cassettes',
          query: {
            pageSize: `${paging.pageSize}`,
            page: `${paging.page}`,
            sortColumn: sort.sortColumn,
            sortOrder: sort.sortOrder,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await CassettesApi.requestService.post(url, filter)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async getSingle(guid: string): Promise<VaultCassette> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/cassettes/cassette',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )
      return await CassettesApi.requestService.get(url)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async add(payload: CreateCassetteRequest): Promise<string> {
    try {
      return await CassettesApi.requestService.post('/cassettes/add', payload)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async edit(payload: EditCassetteRequest): Promise<string> {
    try {
      return await CassettesApi.requestService.put('/cassettes/edit', payload)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async delete(guid: string): Promise<string> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/cassettes/delete',
          query: {
            guid,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )

      return await CassettesApi.requestService.delete(url)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async load(payload: LoadCassetteRequest): Promise<void> {
    // eslint-disable-next-line no-useless-catch
    try {
      return await CassettesApi.requestService.post('/cassettes/load', payload)
    } catch (error) {
      throw error
    }
  }

  public static async unload(payload: UnloadCassetteRequest): Promise<void> {
    try {
      return await CassettesApi.requestService.post('/cassettes/unload', payload)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async unloadToCashier(payload: UnloadCassetteToCashierRequest): Promise<void> {
    try {
      return await CassettesApi.requestService.post('/cassettes/unloadToCashier', payload)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async editCassette(payload: LoadCassetteRequest): Promise<void> {
    try {
      return await CassettesApi.requestService.put('/cassettes/load/edit', payload)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async buttonsStatuses(guid: string): Promise<CassettesButtonsStatuses> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/cassettes/buttons/statuses',
          query: {
            guid,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )

      return await CassettesApi.requestService.get(url)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async barcode(barcode: string): Promise<CassetteBarcodeResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/cassettes/barcode',
          query: {
            barcode,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )

      return await CassettesApi.requestService.get(url)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async details(guid: string): Promise<CassetteDetails> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/cassettes/details',
          query: {
            guid,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )

      return await CassettesApi.requestService.get(url)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }
}
