/* eslint-disable max-len */
import AdministrationIcon from '@/assets/atmeye/icons/administration_icon.svg'
import DevicesIcon from '@/assets/atmeye/icons/devices.svg'
import HelpIcon from '@/assets/atmeye/icons/help_icon.svg'
import ReportsIcon from '@/assets/atmeye/icons/reports_icon.svg'
import SecurityAlarmsIcon from '@/assets/atmeye/icons/security_alarms.svg'
import TechnicalEventsIcon from '@/assets/atmeye/icons/technical_events.svg'
import TransactionsIcon from '@/assets/atmeye/icons/transactions.svg'
import { Permission } from '@/components/NPMPakage/components/wrappers/types'
import { AppNavigationRoutes, ATMEYE_BLOCK_PREFIX } from '@/constants'

import { AtmeyeModules } from '../../../api/atmeye/common/types'

const DEVICES = [
  {
    path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.devices.root}`,
    label: 'translate#atmeye.title.devices',
    Icon: DevicesIcon,
    permission: Permission.Open_devices_page,
  },
]

const RFM = [
  {
    label: 'translate#atmeye.title.rfm',
    Icon: ReportsIcon,
    permission: Permission.RFM,
    subItems: [
      {
        path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.rfm.root}${AppNavigationRoutes.ATMeye.rfm.singleDevice}`,
        label: 'translate#atmeye.title.rfm.singleDevice',
        permission: Permission.RFM_single_device,
      },
      {
        path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.rfm.root}${AppNavigationRoutes.ATMeye.rfm.multipleDevices}`,
        label: 'translate#atmeye.title.rfm.multipleDevices',
        permission: Permission.RFM_multiple_devices,
      },
      {
        path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.rfm.root}${AppNavigationRoutes.ATMeye.rfm.operationsLog}`,
        label: 'translate#atmeye.title.rfm.operationsLog',
        permission: Permission.RFM_operation_log,
      },
      {
        path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.rfm.root}${AppNavigationRoutes.ATMeye.rfm.synchronization}`,
        label: 'translate#atmeye.title.rfm.synchronization',
        permission: Permission.RFM_transaction_history,
      },
    ],
  },
]

export default ({ technicalEventsCount, alarmsCount, availableModules }) => {
  let items = []

  if (availableModules?.DEVICES && availableModules?.[AtmeyeModules.STATUSES]) {
    items = [...items, ...DEVICES]

    if (availableModules?.CAMERAS && availableModules?.[AtmeyeModules.TRANSACTIONS]) {
      items.push({
        path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.transactions.root}`,
        label: 'translate#atmeye.title.transactions',
        Icon: TransactionsIcon,
        permission: Permission.Transactions,
      })

      if (availableModules?.[AtmeyeModules.SECURITY_ALARMS]) {
        items.push({
          path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.securityAlarms.root}`,
          label: 'translate#atmeye.title.securityAlarms',
          Icon: SecurityAlarmsIcon,
          badgeCounter: alarmsCount,
          permission: Permission.Alarms,
        })
      }

      if (availableModules?.[AtmeyeModules.TECHNICAL_EVENTS]) {
        items.push({
          path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.technicalEvents.root}`,
          label: 'translate#atmeye.title.technicalEvents',
          Icon: TechnicalEventsIcon,
          badgeCounter: technicalEventsCount,
          permission: Permission.Technical_Events,
        })
      }

      if (availableModules?.REPORTS) {
        items = [
          ...items,
          {
            path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.reports.root}`,
            label: 'translate#atmeye.title.reports',
            Icon: ReportsIcon,
            permission: Permission.Reports,
          },
        ]
      }
    }

    if (availableModules?.RFM) {
      items = [...items, ...RFM]
    }
  }

  let adminSubItems = []
  if (availableModules?.[AtmeyeModules.LOG_INSPECTOR]) {
    adminSubItems.push({
      path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.administration.root}${AppNavigationRoutes.ATMeye.administration.logsInspector}`,
      label: 'translate#atmeye.title.administration.logsInspector',
      permission: Permission.Licenses,
    })
  }

  adminSubItems = [
    ...adminSubItems,
    {
      path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.administration.root}${AppNavigationRoutes.ATMeye.administration.healthMonitor}`,
      label: 'translate#atmeye.title.administration.healthMonitor',
      permission: Permission.Licenses,
    },
    {
      path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.administration.root}${AppNavigationRoutes.ATMeye.administration.licenceList}`,
      label: 'translate#atmeye.title.administration.licenseList',
      permission: Permission.Licenses,
    },
    {
      path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.administration.root}${AppNavigationRoutes.ATMeye.administration.blackList}`,
      label: 'translate#atmeye.title.administration.blackList',
      permission: Permission.Blacklist,
    },
    {
      path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.administration.root}${AppNavigationRoutes.ATMeye.administration.deviceSettings}`,
      label: 'translate#atmeye.title.administration.deviceSettings',
      permission: Permission.Device_Settings,
    },
    {
      path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.administration.root}${AppNavigationRoutes.ATMeye.administration.centralSettings}`,
      label: 'translate#atmeye.title.administration.centralSettings',
      permission: Permission.Central_settings,
    },
    {
      path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.administration.root}${AppNavigationRoutes.ATMeye.administration.eventDictionary}`,
      label: 'translate#atmeye.title.administration.eventDictionary',
      permission: Permission.Events_dictionary,
    },
  ]

  if (availableModules?.[AtmeyeModules.CAMERAS]) {
    adminSubItems.push({
      path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.administration.root}${AppNavigationRoutes.ATMeye.administration.multipleSnapshots}`,
      label: 'translate#atmeye.title.administration.multipleSnapshots',
      permission: Permission.Multiple_snapshot,
    })
  }

  if (availableModules?.[AtmeyeModules.EXTERNAL_NOTIFICATIONS]) {
    adminSubItems.push({
      path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.administration.root}${AppNavigationRoutes.ATMeye.administration.externalNotifications}`,
      label: 'translate#atmeye.title.administration.externalNotifications',
    })
  }

  items.push({
    label: 'translate#atmeye.title.administration',
    Icon: AdministrationIcon,
    permission: Permission.Administration,
    subItems: [
      ...adminSubItems,
      {
        path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.administration.root}${AppNavigationRoutes.ATMeye.administration.options}`,
        label: 'translate#atmeye.title.administration.options',
        permission: Permission.Options,
      },
    ],
  })

  items.push({
    path: `${ATMEYE_BLOCK_PREFIX}${AppNavigationRoutes.ATMeye.help.root}`,
    label: 'translate#atmeye.title.help',
    Icon: HelpIcon,
    permission: Permission.Help,
  })

  return items
}
