import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { StrategyLocalStorage } from '@/components/NPMPakage/components/tableComponents/UITable/strategy'
import { SortingTypes } from '@/components/NPMPakage/hooks/useSorting/types'
import { LocalStorageItems } from '@/constants'

const useSorting = (defaultSorting: SortingTypes, sortingLSKey?: LocalStorageItems) => {
  const isInitialMount = useRef(true)
  const location = useLocation<{ sortingObject: SortingTypes }>()
  const sortingState = location.state?.sortingObject

  const [sorting, setSorting] = useState<SortingTypes>(() => {
    const savedSorting = sortingLSKey && StrategyLocalStorage.getData(sortingLSKey)
    return savedSorting || defaultSorting
  })

  const changeSorting = (sortingKey: string): void => {
    setSorting(sort => {
      const newSorting: SortingTypes = {
        fieldName: sortingKey,
        sortOrder: sortingKey === sort.fieldName && sort.sortOrder === 'asc' ? 'desc' : 'asc',
      }

      sortingLSKey && StrategyLocalStorage.setData(sortingLSKey, newSorting)

      return newSorting
    })
  }

  useEffect(() => {
    if (!isInitialMount.current) {
      setSorting(sortingState)
      window.history.replaceState(undefined, '')
    }
  }, [sortingState])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    }
  }, [])

  return { sorting, changeSorting }
}

export { useSorting }
