import makeStyles from '@material-ui/core/styles/makeStyles'

import { styledComponentTheme } from '@/theme'
import colors from '@/theme/colors'

export const useClasses = makeStyles(() => ({
  modalWrapper: {
    display: 'grid',
    gridTemplateRows: 'max-content 1fr max-content',
    overflow: 'hidden',
    position: 'relative',
    padding: '30px',
    width: '100%',
    minWidth: '300px',
    minHeight: '300px',
  },

  modalTitle: {
    fontSize: '20px',
    marginBottom: '10px',
    marginRight: '50px',
  },

  closeButton: {
    top: '10px',
    right: '10px',

    '& [class*="makeStyles-icon"]': {
      color: colors.products.black,
    },
  },

  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '24px',
  },

  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    marginRight: '24px',
    flex: 1,
  },

  arrowButton: {
    border: '1px solid',
    borderColor: styledComponentTheme.colors.baseGreen,
    width: '40px',
    height: '40px',
    padding: '0',
    minWidth: 'auto',
    marginRight: '16px',

    '& .MuiButton-startIcon': {
      margin: '0',
    },
  },

  subTitles: {
    fontWeight: 'bold',
    marginBottom: '8px',
    minWidth: '118px',
  },

  text: {
    margin: '0 20px 8px 0',
    wordBreak: 'break-all',
    whiteSpace: 'break-spaces',
  },

  wrapper: {
    height: '100%',
    display: 'flex',
    marginBottom: '16px',
    overflow: 'auto',
  },

  contentWrapper: {
    display: 'flex',
    marginBottom: '16px',
  },

  content: {
    display: 'flex',

    '& div:first-child': {
      marginRight: '20px',
    },
  },

  modalFooter: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 'auto',
  },

  buttonFooter: {
    fontWeight: 'normal',
    border: '1px solid',
    height: '38px',
    marginRight: '16px',
    marginTop: '16px',
    background: styledComponentTheme.colors.baseGreen,
    color: 'white',
    borderColor: styledComponentTheme.colors.baseGreen,
    '&:hover': {
      color: styledComponentTheme.colors.baseGreen,
    },

    '&:disabled': {
      background: styledComponentTheme.colors.backgroundDark,
      border: 'none',
    },
  },
}))
