import React from 'react'
import { useSelector } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import { Props } from './types'
import { preventDefault } from '@/utils/functions'
import Box from '@material-ui/core/Box'
import { getMerchants } from '@/store/sd/drawerActions/selectors/newPOS'
import ServiceTypeForm from './components/ServiceTypeForm/index'
import CloseButton from '@/components/blocks/CloseButton/index'
import Test from 'react-test-attributes'
import { getTestId } from '@/utils'

const ServiceTypeModal = ({
  open,
  title,
  handleClose,
  handleSubmit,
  editRow,
  testIdPrefix,
  merchantId,
  serviceTypeId,
  withTerminalIdsField,
  withSingleTerminalSelecting,
  merchantFullName,
  selectedTerminalIds,
}: Props): React.ReactElement => {
  const idPrefix = `${testIdPrefix}-${serviceTypeId}`
  const testId = getTestId(idPrefix)
  const merchants = useSelector(getMerchants)
  const getMerchValue = (mId: string) =>
    merchants.find((m: { name: string; value: string }) => m.value === mId) || ''
  const merchValue = getMerchValue(merchantId.toString())

  return (
    <>
      <Test id={testId(0)}>
        <Dialog
          onDragOver={preventDefault}
          onDrop={preventDefault}
          maxWidth="lg"
          transitionDuration={0}
          fullWidth
          open={open}
          onClose={handleClose}
          disableBackdropClick
        >
          <Test id={testId(1)}>
            <CloseButton onClick={handleClose} absolute />
          </Test>
          <Test id={testId(2)}>
            <Box display="flex" flexDirection="column" overflow="auto">
              {/* unmount to clean values */}
              {open && (
                <ServiceTypeForm
                  title={title}
                  editRow={editRow}
                  merchantId={merchantId}
                  merchantValue={(merchValue && merchValue.name) || ''}
                  handleSubmit={handleSubmit}
                  handleClose={handleClose}
                  serviceTypeId={serviceTypeId}
                  withTerminalIdsField={withTerminalIdsField}
                  withSingleTerminalSelecting={withSingleTerminalSelecting}
                  merchantFullName={merchantFullName}
                  selectedTerminalIds={selectedTerminalIds}
                />
              )}
            </Box>
          </Test>
        </Dialog>
      </Test>
    </>
  )
}

export default ServiceTypeModal
