import { makeStyles, Theme } from '@material-ui/core/styles'

export const useClasses = makeStyles<Theme>((theme: Theme): {} => ({
  root: {
    color: theme.palette.text.secondary,

    '&:focus > $content, &$selected > $content': {
      fontWeight: 'bold',
    },

    '& .MuiTreeItem-label': {
      background: 'none',
    },
  },

  content: {
    minWidth: theme.spacing(24),
    width: '100%',
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,

    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },

  group: {
    marginLeft: theme.spacing(3),

    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },

  label: {
    fontWeight: 'inherit',
    color: 'inherit',
    width: '100%',
  },

  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
    width: '100%',
    wordWrap: 'break-word',
    gap: '7px',
  },

  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
    width: theme.spacing(3),
    wordWrap: 'inherit',
    marginRight: theme.spacing(2),
  },
}))
