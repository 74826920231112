// core
import { FormikValues } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { useEffect, useState } from 'react'

// types
import {
  CommonErrorsTypes,
  ErrorsFieldsTypes,
  ResponseErrorTypes,
} from '@/types/common/ErrorResponseTypes'
// hooks
import { useTranslate } from '@/utils/internalization'

type FormikErrorsHelpers = Pick<FormikHelpers<FormikValues>, 'setErrors' | 'setFieldError'>

type Props<T> = {
  error: ResponseErrorTypes | null
  formActions?: FormikErrorsHelpers
}

type ErrorsMessagesParseTypes = {
  fieldErrorsForm: FormikValues
  globalErrors: FormikValues
}

export const useErrorMessage = <T>({ error, formActions }: Props<T>) => {
  const [errorMessages, setErrorMessages] = useState<Array<string>>([])
  const translate = useTranslate()

  const generateLocaliseErrorMessage = (
    errorKey: string,
    errorMessage: string,
    localiseOption?: string,
  ): string => {
    const localeMessage = translate(`translate#${errorKey}`, { localiseOption })
    return localeMessage === errorKey ? errorMessage : localeMessage
  }

  const generateTranslateErrorsFormAndGlobalMessage = (
    errorMessage: string,
    errorKey: string,
    fieldErrorsData: (CommonErrorsTypes & ErrorsFieldsTypes) | null,
  ): string => {
    if (fieldErrorsData?.params && !!fieldErrorsData.params?.length) {
      const localiseOption = fieldErrorsData.params.reduce((acc, item) => {
        if (!item.isLocaliseKey) {
          acc = item.key
        }
        return acc
      }, '' as string)

      return generateLocaliseErrorMessage(errorKey, errorMessage, localiseOption)
    } else {
      return generateLocaliseErrorMessage(errorKey, errorMessage)
    }
  }

  useEffect(() => {
    !error && errorMessages.length && setErrorMessages([])
  }, [error, errorMessages])

  useEffect(() => {
    if (error) {
      if (!error.errorMessage?.length) {
        return setErrorMessages(['Unknown error please try again later'])
      }

      if (error.fieldErrors !== null) {
        const fieldKeys = Object.keys(error.fieldErrors)
        const errorsForm = fieldKeys.reduce(
          (acc, item) => {
            if (!acc.fieldErrorsForm[item]) {
              acc.fieldErrorsForm[item] = error.fieldErrors?.[item].map(el => el.localiseKey)[0]
              acc.globalErrors[item] = error.fieldErrors?.[item].map(el =>
                generateTranslateErrorsFormAndGlobalMessage(error.errorMessage, el.localiseKey, el),
              )[0]
            }
            return acc
          },
          { fieldErrorsForm: {}, globalErrors: {} } as ErrorsMessagesParseTypes,
        )
        setErrorMessages(Object.values(errorsForm.globalErrors))
        return formActions?.setErrors(errorsForm.fieldErrorsForm)
      }
      if (error.globalErrors && error.globalErrors?.length) {
        setErrorMessages(
          error.globalErrors?.map(e =>
            generateLocaliseErrorMessage(e.localiseKey, error.errorMessage),
          ),
        )
      } else {
        setErrorMessages([error.errorMessage])
      }
    }
  }, [error])

  return { errorMessages, setErrorMessages }
}
