export enum RepairNormsTableField {
  ProblemArea = 'problemArea',
  DefectOrWork = 'defectOrWork',
  QualificationLevel = 'qualificationLevel',
  Norm = 'norm',
  FromDate = 'fromDate',
  ToDate = 'toDate',
}

export interface RepairNorms {
  id: string
  [RepairNormsTableField.ProblemArea]: string
  [RepairNormsTableField.DefectOrWork]: string
  [RepairNormsTableField.QualificationLevel]: string
  [RepairNormsTableField.Norm]: string
  [RepairNormsTableField.FromDate]: string
  [RepairNormsTableField.ToDate]: string
}

export interface RepairNormDate {
  id: string
  date: Date
}
