export enum AtmeyeBlackList {
  ChangeSelectedFilters = '@/BLACK_LIST/CHANGE_SELECTED_FILTERS',
  ChangeGrouping = '@/BLACK_LIST/CHANGE_GROUPING',
  ChangeSorting = '@/BLACK_LIST/CHANGE_SORTING',
  SetRowPerPage = '@/BLACK_LIST/SET_ROW_PER_PAGE',
  SetPage = '@/BLACK_LIST/SET_PAGE',

  SetSelectedGroupingTemplate = '@/BLACK_LIST/SET_SELECTED_GROUPING_TEMPLATE',

  SetGroupDetailsResponse = '@/BLACK_LIST/SET_GROUP_DETAILS_RESPONSE',
  SetGroupDetailsResponseEmpty = '@/BLACK_LIST/SET_GROUP_DETAILS_RESPONSE_EMPTY',

  DeleteFilterTemplateRequest = '@/BLACK_LIST/DELETE_FILTER_TEMPLATE_REQUEST',
  DeleteFilterTemplateResponse = '@/BLACK_LIST/DELETE_FILTER_TEMPLATE_RESPONSE',

  GetGroupingTemplatesRequest = '@/BLACK_LIST/GET_GROUPING_TEMPLATES_REQUEST',

  GetFiltersTemplatesRequest = '@/BLACK_LIST/GET_FILTERS_TEMPLATES_REQUEST',

  SaveGroupingTemplateRequest = '@/BLACK_LIST/SAVE_GROUPING_TEMPLATE_REQUEST',

  CreateFilterTemplateRequest = '@/BLACK_LIST/CREATE_FILTER_TEMPLATE_REQUEST',
  EditGroupTemplateRequest = '@/BLACK_LIST/EDIT_GROUP_TEMPLATE_REQUEST',
  DeleteGroupTemplateRequest = '@/BLACK_LIST/DELETE_GROUP_TEMPLATE_REQUEST',

  UpdateFilterTemplateRequest = '@/BLACK_LIST/UPDATE_FILTER_TEMPLATE_REQUEST',
  SetGroupTemplates = '@/BLACK_LIST/SET_GROUP_TEMPLATES',

  GetExportRequest = '@/BLACK_LIST/GET_EXPORT_REQUEST',
  GetExportResponse = '@/GET_EXPORT_RESPONSE',
  GetExportResponseFail = '@/GET_EXPORT_RESPONSE_FAIL',

  GetExportGroupedRequest = '@/BLACK_LIST/GET_EXPORT_GROUPED_REQUEST',
  GetExportGroupedResponse = '@/GET_EXPORT_GROUPED_RESPONSE',
  GetExportGroupedResponseFail = '@/GET_EXPORT_GROUPED_RESPONSE_FAIL',

  GetTransactionsConfigRequest = '@/BLACK_LIST/GET_TRANSACTIONS_CONFIG_REQUEST',
  GetTransactionsRequest = '@/BLACK_LIST/GET_TRANSACTIONS_REQUEST',
  GetTransactionsResponse = '@/BLACK_LIST/GET_TRANSACTIONS_RESPONSE',
  GetGroupDetailsRequest = '@/BLACK_LIST/GET_GROUP_DETAILS_REQUEST',
  GetGroupDetailsResponse = '@/BLACK_LIST/GET_GROUP_DETAILS_RESPONSE',
  SetParentGroups = '@/BLACK_LIST/SET_PARENT_GROUPS',
  SetCurrentFilterTemplate = '@/BLACK_LIST/SET_CURRENT_FILTER_TEMPLATE',
  SetSdFilterTemplateResponse = '@/BLACK_LIST/SET_FILTER_TEMPLATE_RESPONSE',

  CreateSdFilterTemplateResponse = '@/BLACK_LIST/CREATE_SD_FILTER_TEMPLATE_RESPONSE',
  EditSdGroupTemplateFromResponse = '@/BLACK_LIST/EDIT_SD_GROUP_TEMPLATE_FROM_RESPONSE',

  UpdateSdFilterTemplateResponse = '@/BLACK_LIST/UPDATE_SD_FILTER_TEMPLATE_RESPONSE',

  EditTableCellRequest = '@/BLACK_LIST/EDIT_TABLE_CELL_REQUEST',
  EditTableCellGrouping = '@/BLACK_LIST/EDIT_TABLE_CELL_REQUEST_GROUPING',
  SaveTableCell = '@/BLACK_LIST/SAVE_TABLE_CELL',
  SaveTableCellGrouping = '@/BLACK_LIST/SAVE_TABLE_CELL_GROUPING',
  SetDataPagination = '@/BLACK_LIST/SET_DATA_PAGINATION',
  SetDataPaginationRequest = '@/BLACK_LIST/SET_DATA_PAGINATION_REQUEST',
  CollectFilterTemplateFields = '@/BLACK_LIST/COLLECT_FILTER_TEMPLATE_FIELDS',
  GetTicketsConfigResponse = '@/BLACK_LIST/GET_TICKETS_CONFIG_RESPONSE',
  ClearGroupedTicketDetailsData = '@/BLACK_LIST/CLEAR_GROUPED_DETAILS_DATA',
  SelectRow = '@/BLACK_LIST/SELECT_ROW',
  CreateCard = '@/BLACK_LIST/CREATE_CARD',
  RemoveCard = '@/BLACK_LIST/REMOVE_CARD',
  EditCard = '@/BLACK_LIST/EDIT_CARD',
  SetModalState = '@/BLACK_LIST/SET_MODAL_STATE',
  SetModalStateError = '@/BLACK_LIST/SET_MODAL_STATE_ERROR',
  SetModalStateFetching = '@/BLACK_LIST/SET_MODAL_STATE_FETCHING',
}
