import MomentUtils from '@date-io/moment'
import { common } from '@material-ui/core/colors'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import {
  KeyboardDateTimePicker,
  KeyboardDateTimePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import clsx from 'clsx'
import { FieldProps } from 'formik'
import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import { DATE_TIME_SEC_FORMAT, DEFAULT_DATE_FORMAT } from '@/constants/time'
import { generateErrorText } from '@/helpers/generateErrorText'
import colors from '@/theme/colors'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import {
  FormikDateTimePickerFieldProps,
  FormikDateTimePickerProps,
  FormikDateTimePickerRangeProps,
} from './types'

const toolbarTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      dark: colors.primaryDark,
      contrastText: common.white,
    },
    secondary: {
      main: colors.secondary,
      contrastText: common.white,
    },
    // contrastThreshold: 0,
  },
})

export const DateTimeInput = ({
  error,
  helperText,
  className,
  variant,
  format,
  onChange,
  disableInvalidDates = true,
  ...props
}: KeyboardDateTimePickerProps & { disableInvalidDates?: boolean }): React.ReactElement => {
  const classes = useClasses()

  const defaultDateTimeFormat = `${DEFAULT_DATE_FORMAT}, hh:mm a`

  const formatForMask = useMemo(() => moment().format(format || defaultDateTimeFormat), [format])
  const defaultFormat = useMemo(() => format || defaultDateTimeFormat, [format])

  const mask = useMemo(() => {
    return formatForMask && formatForMask.replace(/[A-Za-z]/g, '_').replace(/[0-9]/g, '_')
  }, [formatForMask])

  const handleChange = useCallback((momentDate, date): void => {
    if (!disableInvalidDates || (momentDate && momentDate.isValid())) {
      onChange(momentDate, date)
    }
  }, [])

  return (
    <ThemeProvider theme={toolbarTheme}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <KeyboardDateTimePicker
          {...props}
          size="small"
          variant={variant || 'inline'}
          margin="normal"
          className={clsx(classes.input, className, { [classes.inputWithError]: error })}
          InputLabelProps={{
            className: clsx(classes.label, { [classes.errorLabel]: error }),
            shrink: true,
          }}
          InputAdornmentProps={{
            className: classes.icon,
          }}
          error={error}
          inputVariant="outlined"
          placeholder={props.placeholder}
          mask={mask}
          format={defaultFormat}
          disabled={props.disabled}
          onChange={handleChange}
          helperText={
            helperText ? (
              <div className={classes.errorHelperText}>
                <FormattedMessage id={`form.validationWarning.${helperText}`} />
              </div>
            ) : null
          }
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export const FormikDateTimePicker = ({
  field,
  form,
  meta,
  label,
  className,
  format,
  isRange,
  index,
  withoutCurrentDate = false,
  disabled,
  disablePast = false,
  disableFutureDates = true,
  maxDate,
  minDate,
  ...other
}: FormikDateTimePickerProps): React.ReactElement => {
  const value = useMemo(() => {
    if (withoutCurrentDate && index !== undefined && (!field.value || !field.value[index])) {
      return null
    }

    if (isRange && index !== undefined) return field.value[index]

    return field.value
  }, [field.value])

  const handleDateChange = useCallback(
    (momentDate, date): void => {
      if (date && !date.includes('_')) {
        if (isRange && index !== undefined) {
          const newValue = [...field.value]

          newValue[index] = momentDate.isValid()
            ? momentDate.format(DATE_TIME_SEC_FORMAT)
            : moment().format(DATE_TIME_SEC_FORMAT)
          form.setFieldValue(field.name, newValue, false)
        } else {
          form.setFieldValue(
            field.name,
            momentDate.isValid()
              ? momentDate.format(DATE_TIME_SEC_FORMAT)
              : moment().format(DATE_TIME_SEC_FORMAT),
            false,
          )
        }
      }
    },
    [form.setFieldValue, field.value],
  )

  return (
    <DateTimeInput
      label={label}
      className={className}
      disablePast={disablePast}
      name={field.name}
      value={value}
      onChange={handleDateChange}
      format={format}
      onBlur={() => {
        form.getFieldHelpers(field.name).setTouched(true)
      }}
      helperText={generateErrorText(form.errors, field.name, meta.touched)}
      error={!!form.errors[field.name] && meta.touched}
      {...other}
      InputLabelProps={{ shrink: true }}
      disabled={disabled}
      maxDate={maxDate || (disableFutureDates ? new Date() : undefined)}
      minDate={minDate}
    />
  )
}

export const createDateTimePickerRangeField = ({
  title,
  labelDate,
  format,
  titleClass,
  wrapperDateClass,
  widthClass,
  withoutCurrentDate,
  disabled,
  placeholder,
}: FormikDateTimePickerRangeProps) => (formikProps: FieldProps): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  return (
    <div className={classes.range}>
      {title && <span className={titleClass}>{title}</span>}
      <div className={wrapperDateClass}>
        <FormikDateTimePicker
          {...formikProps}
          index={0}
          isRange
          format={format}
          label={labelDate ? labelDate.begin : translate('translate#title.from')}
          className={clsx(classes.mr, widthClass)}
          maxDate={formikProps.field.value[1] && new Date(formikProps.field.value[1])}
          withoutCurrentDate={withoutCurrentDate}
          disabled={disabled}
          placeholder={placeholder}
        />
        <FormikDateTimePicker
          {...formikProps}
          index={1}
          isRange
          format={format}
          label={labelDate ? labelDate.end : translate('translate#title.to')}
          className={widthClass}
          disablePast
          minDate={
            formikProps.field.value &&
            formikProps.field.value[0] &&
            new Date(formikProps.field.value[0])
          }
          initialFocusedDate={
            formikProps.field.value &&
            formikProps.field.value[0] &&
            new Date(formikProps.field.value[0])
          }
          withoutCurrentDate={withoutCurrentDate}
          disabled={disabled}
          placeholder={placeholder}
        />
      </div>
    </div>
  )
}

export const createDateTimePickerField = ({
  ampm,
  label,
  format,
  widthClass,
  wrapperDateClass,
  disabled,
  required,
  placeholder,
  withoutCurrentDate,
  maxDate,
  minDate,
  disablePast,
  disableFutureDates,
}: FormikDateTimePickerFieldProps) => (formikProps: FieldProps): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  return (
    <div className={wrapperDateClass}>
      <FormikDateTimePicker
        {...formikProps}
        ampm={ampm}
        index={0}
        format={format}
        label={label || translate('translate#title.from')}
        placeholder={placeholder}
        className={clsx(classes.mr, widthClass)}
        disabled={disabled}
        required={required}
        withoutCurrentDate={withoutCurrentDate}
        maxDate={maxDate}
        minDate={minDate}
        disablePast={disablePast}
        disableFutureDates={disableFutureDates}
      />
    </div>
  )
}
