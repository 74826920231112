import { AnyAction } from 'redux'

import {
  AdmAccessRulesRole,
  AdmComUser,
  AdmComVault,
  AdmModule,
  AdmUserModulePermissions,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants/actions'

export interface PermissionsFilter {
  vaultName?: string
  userName?: string
}

export type State = {
  selectedPanel: string

  accessRules: {
    data: AdmAccessRulesRole[]
    total: number
    isLoading: boolean
    isLoaded: boolean
    sortColumn?: string
    sortOrder?: 'asc' | 'desc'
    selected: AdmAccessRulesRole | null

    modules: {
      base: AdmModule[]
      admin: AdmModule[]
      isLoading: boolean
      isLoaded: boolean
      error: string | null
    }

    permissions: {
      filter: PermissionsFilter
      filterModal: boolean
      isConfirmModalOpen: boolean

      vaults: {
        data: AdmComVault[]
        isLoading: boolean
        isLoaded: boolean
        sortColumn?: string
        sortOrder?: 'asc' | 'desc'
        selected: AdmComVault | null
      }

      users: {
        data: AdmComUser[]
        isLoading: boolean
        isLoaded: boolean
        sortColumn?: string
        sortOrder?: 'asc' | 'desc'
        selected: AdmComUser | null
      }

      userPermissions: {
        data: AdmUserModulePermissions[]
        updatedData: AdmUserModulePermissions[]
        isLoading: boolean
        isLoaded: boolean
      }
    }
  }
}

export const initialState: State = {
  selectedPanel: '',

  accessRules: {
    data: [],
    total: 0,
    isLoading: false,
    isLoaded: false,
    sortColumn: undefined,
    sortOrder: undefined,
    selected: null,

    modules: {
      base: [],
      admin: [],

      isLoading: false,
      isLoaded: false,
      error: null,
    },

    permissions: {
      filter: {
        vaultName: '',
        userName: '',
      },
      filterModal: false,
      isConfirmModalOpen: false,

      vaults: {
        data: [],
        isLoading: false,
        isLoaded: false,
        sortColumn: undefined,
        sortOrder: undefined,
        selected: null,
      },

      users: {
        data: [],
        isLoading: false,
        isLoaded: false,
        sortColumn: undefined,
        sortOrder: undefined,
        selected: null,
      },

      userPermissions: {
        data: [],
        updatedData: [],
        isLoading: false,
        isLoaded: false,
      },
    },
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case VaultV1Actions.AdmSetSelectedAccessRulesPanel:
      return {
        ...state,
        selectedPanel: payload,
      }

    case VaultV1Actions.AdmGetAccessRulesRequest:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          isLoading: true,
        },
      }

    case VaultV1Actions.AdmGetAccessRulesResponse:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          isLoading: false,
          isLoaded: true,
          data: payload,
          total: (payload || []).length,
          selected: payload[0] || null,
        },
      }
    case VaultV1Actions.AdmGetAccessRulesFail:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          isLoaded: true,
          isLoading: false,
        },
      }

    case VaultV1Actions.AdmSetSortAccessRules:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          isLoading: true,
          ...payload,
        },
      }

    case VaultV1Actions.AdmSetSelectedAccessRule:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          selected: payload,
        },
      }

    // Modules
    case VaultV1Actions.AdmSetAccessRulesModulesRequest:
    case VaultV1Actions.AdmGetAccessRulesModulesRequest:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          modules: {
            ...state.accessRules.modules,
            isLoading: true,
          },
        },
      }
    case VaultV1Actions.AdmGetAccessRulesModulesResponse:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          modules: {
            ...state.accessRules.modules,
            isLoading: false,
            isLoaded: true,
            base: payload.modules,
            admin: payload.adminModules,
          },
        },
      }

    case VaultV1Actions.AdmSetAccessRulesModulesFail:
    case VaultV1Actions.AdmGetAccessRulesModulesFail:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          modules: {
            ...state.accessRules.modules,
            isLoading: false,
            isLoaded: true,
            error: payload,
          },
        },
      }

    // Permissions
    case VaultV1Actions.AdmSetAccessRulesPermissionsFilter:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          permissions: {
            ...state.accessRules.permissions,
            filter: {
              ...state.accessRules.permissions.filter,
              ...payload,
            },
          },
        },
      }
    case VaultV1Actions.AdmOpenAccessRulesPermissionsFilter:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          permissions: {
            ...state.accessRules.permissions,
            filterModal: payload,
          },
        },
      }

    // Vaults
    case VaultV1Actions.AdmGetAccessRulesVaultsRequest:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          permissions: {
            ...state.accessRules.permissions,
            vaults: {
              ...state.accessRules.permissions.vaults,
              isLoading: true,
            },
          },
        },
      }
    case VaultV1Actions.AdmGetAccessRulesVaultsResponse:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          permissions: {
            ...state.accessRules.permissions,
            vaults: {
              ...state.accessRules.permissions.vaults,
              isLoading: false,
              data: payload,
              isLoaded: true,
            },
          },
        },
      }
    case VaultV1Actions.AdmGetAccessRulesVaultsFail:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          permissions: {
            ...state.accessRules.permissions,
            vaults: {
              ...state.accessRules.permissions.vaults,
              isLoading: false,
              isLoaded: true,
            },
          },
        },
      }
    case VaultV1Actions.AdmSetAccessRulesSelectedVault:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          permissions: {
            ...state.accessRules.permissions,
            vaults: {
              ...state.accessRules.permissions.vaults,
              selected: payload.selected,
            },
            users: {
              ...state.accessRules.permissions.users,
              selected: payload.isClearSelectedUser
                ? null
                : state.accessRules.permissions.users.selected,
            },
          },
        },
      }

    case VaultV1Actions.AdmSetSortAccessRulesVaults:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          permissions: {
            ...state.accessRules.permissions,
            vaults: {
              ...state.accessRules.permissions.vaults,
              isLoading: true,
              ...payload,
            },
          },
        },
      }

    case VaultV1Actions.AdmSetSortAccessRulesUsers:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          permissions: {
            ...state.accessRules.permissions,
            users: {
              ...state.accessRules.permissions.users,
              isLoading: true,
              ...payload,
            },
          },
        },
      }

    // Users
    case VaultV1Actions.AdmGetAccessRulesUsersRequest:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          permissions: {
            ...state.accessRules.permissions,
            users: {
              ...state.accessRules.permissions.users,
              isLoading: true,
            },
          },
        },
      }
    case VaultV1Actions.AdmGetAccessRulesUsersResponse:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          permissions: {
            ...state.accessRules.permissions,
            users: {
              ...state.accessRules.permissions.users,
              isLoading: false,
              data: payload,
              isLoaded: true,
            },
          },
        },
      }
    case VaultV1Actions.AdmGetAccessRulesUsersFail:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          permissions: {
            ...state.accessRules.permissions,
            users: {
              ...state.accessRules.permissions.users,
              isLoading: false,
              isLoaded: true,
            },
          },
        },
      }
    case VaultV1Actions.AdmSetAccessRulesSelectedUser:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          permissions: {
            ...state.accessRules.permissions,
            users: {
              ...state.accessRules.permissions.users,
              selected: payload,
            },
          },
        },
      }

    // Permissions
    case VaultV1Actions.AdmGetAccessRulesPermissionsRequest:
    case VaultV1Actions.AdmSetAccessRulesPermissionsRequest:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          permissions: {
            ...state.accessRules.permissions,
            userPermissions: {
              ...state.accessRules.permissions.userPermissions,
              isLoading: true,
            },
          },
        },
      }
    case VaultV1Actions.AdmGetAccessRulesPermissionsResponse:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          permissions: {
            ...state.accessRules.permissions,
            userPermissions: {
              ...state.accessRules.permissions.userPermissions,
              isLoading: false,
              data: payload,
              updatedData: payload,
              isLoaded: true,
            },
          },
        },
      }
    case VaultV1Actions.AdmGetAccessRulesPermissionsFail:
    case VaultV1Actions.AdmSetAccessRulesPermissionsFail:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          permissions: {
            ...state.accessRules.permissions,
            userPermissions: {
              ...state.accessRules.permissions.userPermissions,
              isLoading: false,
              isLoaded: true,
            },
          },
        },
      }

    case VaultV1Actions.AdmUpdateAccessRulesPermissions: {
      const { updatedData } = state.accessRules.permissions.userPermissions

      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          permissions: {
            ...state.accessRules.permissions,
            userPermissions: {
              ...state.accessRules.permissions.userPermissions,
              updatedData: updatedData.map(permission =>
                permission.module === payload.module ? payload : permission,
              ),
            },
          },
        },
      }
    }

    case VaultV1Actions.AdmClearAccessRulesPermissions:
      return {
        ...state,
        accessRules: {
          ...state.accessRules,
          permissions: {
            ...state.accessRules.permissions,
            userPermissions: {
              ...state.accessRules.permissions.userPermissions,
              updatedData: state.accessRules.permissions.userPermissions.data,
            },
          },
        },
      }

    case VaultV1Actions.SignOut:
      return initialState
    default:
      return state
  }
}
