import { ResponseErrorTypes } from '@/types/common/ErrorResponseTypes'
import {
  GetSummaryObjectsRequestTypes,
  RequestMergeUpdateSecurityObjects,
  SecurityNetworksObjectMergeTypes,
  SecurityNetworksObjectsSummary,
} from '@/types/usm/securityObjectsMerge/securityObjectsMerge'
import { ActionFormTypes } from '@/types/usm/commoTypes/actionFormTypes'

export const actionSecurityNetworksMergeUSM = {
  startFetchingSecurityObjectsForUsers: () =>
    ({
      type: '@/START_FETCHING_SECURITY_OBJECTS_FOR_USERS_USM',
    } as const),
  stopFetchingSecurityObjectsForUsers: () =>
    ({
      type: '@/STOP_FETCHING_SECURITY_OBJECTS_FOR_USERS_USM',
    } as const),
  fillSecurityObjectsForUserResponse: (payload: Array<SecurityNetworksObjectMergeTypes>) =>
    ({
      type: '@/FILL_SECURITY_OBJECTS_FOR_USERS_RESPONSE_USM',
      payload,
    } as const),
  setSecurityObjectsForUserResponseFall: (payload: ResponseErrorTypes) =>
    ({
      type: '@/SET_SECURITY_OBJECTS_FOR_USERS_RESPONSE_FALL_USM',
      payload,
    } as const),

  getSecurityObjectsForUserAsync: () =>
    ({
      type: '@/GET_SECURITY_OBJECTS_FOR_USER_ASYNC_USM',
    } as const),

  startFetchingSummaryObjects: () =>
    ({
      type: '@/START_FETCHING_SECURITY_OBJECTS_SUMMARY_USM',
    } as const),
  stopetchingSummaryObjects: () =>
    ({
      type: '@/STOP_FETCHING_SECURITY_OBJECTS_SUMMARY_USM',
    } as const),
  fillSecurityObjectsSummaryResponse: (payload: Array<SecurityNetworksObjectsSummary>) =>
    ({
      type: '@/FILL_SECURITY_OBJECTS_SUMMARY_FOR_USERS_RESPONSE_USM',
      payload,
    } as const),

  getSecurityObjectsSummaryForUserAsync: <T>(
    payload: GetSummaryObjectsRequestTypes,
    actions?: ActionFormTypes<T>,
  ) =>
    ({
      type: '@/GET_SECURITY_OBJECTS_SUMMARY_FOR_USER_ASYNC_USM',
      payload,
      actions,
    } as const),

  filterSecurityObjectsSummaryMerge: (payload: string) =>
    ({
      type: '@/FILTER_SECURITY_OBJECTS_SUMMARY_MERGE_TO_USERS_USM',
      payload,
    } as const),

  startFetchingUpdateSummaryObjects: () =>
    ({
      type: '@/START_FETCHING_UPDATE_SECURITY_OBJECTS_SUMMARY_MERGE_USM',
    } as const),
  stopFetchingUpdateSummaryObjects: () =>
    ({
      type: '@/STOP_FETCHING_UPDATE_SECURITY_OBJECTS_SUMMARY_MERGE_USM',
    } as const),
  fillUpdateSecurityObjectsSummaryResponse: (payload: void) =>
    ({
      type: '@/FILL_UPDATE_SECURITY_OBJECTS_SUMMARY_TO_USER_RESPONSE_USM',
      payload,
    } as const),

  updateSecurityObjectsSummaryForUserAsync: <T>(
    payload: RequestMergeUpdateSecurityObjects,
    actions: ActionFormTypes<T>,
  ) =>
    ({
      type: '@/UPDATE_SECURITY_OBJECTS_SUMMARY_TO_USER_ASYNC_USM',
      payload,
      actions,
    } as const),

  changeSelectedUsers: () =>
    ({
      type: '@/CHANGE_SELECTED_USERS_UPDATE_SECURITY_OBJECTS_USM',
    } as const),

  setClearErrorSecurityNetworksObjectsMerge: () =>
    ({
      type: '@/SET_CLEAR_ERROR_SECURITY_OBJECTS_MERGE_USM',
    } as const),

  setClearWarningMessageMerge: () =>
    ({
      type: '@/SET_CLEAR_WARNING_MESSAGE_SECURITY_OBJECTS_MERGE_USM',
    } as const),
}
