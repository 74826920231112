import React, { FC } from 'react'

import { useFormatNumber } from '@/utils/internalization'
import { statusColors } from '@/constants/cashManagement'

import { Status } from '@/components/blocks/cm'

import { Props } from './types'
import { useClasses } from './styles'

export const ErrorCell: FC<Props> = React.memo(({ value }: Props) => {
  const classes = useClasses()
  const { formatNumber } = useFormatNumber()

  return value ? (
    <Status
      {...statusColors.red}
      value={formatNumber(value)}
      styles={{ status: classes.errorStatus }}
    />
  ) : null
})
