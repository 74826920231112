import React, { useCallback, Fragment, useMemo } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { createTextInputField } from '@/components/controls/TextInput/index'
import { Field } from 'formik'
import Button from '@/components/controls/Button/index'
import Queue from '@material-ui/icons/Queue'
import Close from '@material-ui/icons/Close'
import { useClasses } from './styles'
import { Props } from './types'
import ErrorIcon from '@material-ui/icons/Error'

import { FormattedMessage } from 'react-intl'
import { useTranslate } from '@/utils/internalization'
import { WorkingHoursBlockError } from '@/components/wrappers/AppNavigation/components/DrawerActions/components/modals/ServiceTypeModal/components/ServiceTypeForm/types'

export const WEEK_DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]
export const WEEKDAY_FIELD_NAME_PREFIX = 'posweekday'
export const FROM_HOUR_POSTFIX = 'fromhour'
export const FROM_MIN_POSTFIX = 'frommin'
export const TO_HOUR_POSTFIX = 'tohour'
export const TO_MIN_POSTFIX = 'tomin'

export const WorkingHoursGroup = ({
  formValues,
  setFormikValues,
  formErrors,
}: Props): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  const TimeInput = (isHoursField = false) =>
    createTextInputField({
      label: translate(`translate#title.${isHoursField ? 'hours' : 'min'}`),
      shrink: true,
      type: 'number',
      margin: 'none',
      displayError: false,
      disabledKeys: ['e', 'E', '.', ',', '+', '-'],
      inputProps: { min: '0', max: isHoursField ? '23' : '59', step: '1' },
    })

  const workingHoursFields = useMemo(
    (): { [key: string]: string | number } =>
      WEEK_DAYS.reduce((accum, day, index) => {
        const fromHourKey = `${WEEKDAY_FIELD_NAME_PREFIX}${index + 1}${FROM_HOUR_POSTFIX}`
        const fromMinKey = `${WEEKDAY_FIELD_NAME_PREFIX}${index + 1}${FROM_MIN_POSTFIX}`
        const toHourKey = `${WEEKDAY_FIELD_NAME_PREFIX}${index + 1}${TO_HOUR_POSTFIX}`
        const toMinKey = `${WEEKDAY_FIELD_NAME_PREFIX}${index + 1}${TO_MIN_POSTFIX}`

        return {
          ...accum,
          [fromHourKey]: formValues[fromHourKey],
          [fromMinKey]: formValues[fromMinKey],
          [toHourKey]: formValues[toHourKey],
          [toMinKey]: formValues[toMinKey],
        }
      }, {}),
    [formValues],
  )

  const handleClearHours = useCallback(() => {
    setFormikValues({
      ...formValues,
      ...Object.keys(workingHoursFields).reduce(
        (accum, fieldKey) => ({ ...accum, [fieldKey]: '' }),
        {},
      ),
    })
  }, [formValues, workingHoursFields])

  const filledRows = useMemo(() => {
    const filledRowsIds: number[] = []

    WEEK_DAYS.forEach((day, index) => {
      const filteredByDayNumberKeys = Object.keys(workingHoursFields).filter(key =>
        key.includes(`${index + 1}`),
      )

      if (
        filteredByDayNumberKeys.length &&
        filteredByDayNumberKeys.every(
          key => workingHoursFields[key] === 0 || workingHoursFields[key],
        )
      ) {
        filledRowsIds.push(index + 1)
      }
    })

    return filledRowsIds
  }, [workingHoursFields])

  const handleCopyHours = useCallback(() => {
    const weekdayId = `${filledRows[0]}`

    const filledWorkingHours = Object.keys(workingHoursFields).reduce((accum, key) => {
      let fieldValue

      if (key.includes(FROM_HOUR_POSTFIX)) {
        fieldValue =
          workingHoursFields[`${WEEKDAY_FIELD_NAME_PREFIX}${weekdayId}${FROM_HOUR_POSTFIX}`]
      }
      if (key.includes(FROM_MIN_POSTFIX)) {
        fieldValue =
          workingHoursFields[`${WEEKDAY_FIELD_NAME_PREFIX}${weekdayId}${FROM_MIN_POSTFIX}`]
      }
      if (key.includes(TO_HOUR_POSTFIX)) {
        fieldValue =
          workingHoursFields[`${WEEKDAY_FIELD_NAME_PREFIX}${weekdayId}${TO_HOUR_POSTFIX}`]
      }
      if (key.includes(TO_MIN_POSTFIX)) {
        fieldValue = workingHoursFields[`${WEEKDAY_FIELD_NAME_PREFIX}${weekdayId}${TO_MIN_POSTFIX}`]
      }

      return {
        ...accum,
        [key]: fieldValue,
      }
    }, {})

    setFormikValues({
      ...formValues,
      ...filledWorkingHours,
    })
  }, [filledRows, formValues, workingHoursFields])

  const errors = useMemo((): Array<string | WorkingHoursBlockError | undefined> => {
    return Object.keys(formErrors)
      .filter(key => key.includes(WEEKDAY_FIELD_NAME_PREFIX))
      .map(errKey => formErrors[errKey])
  }, [formErrors])

  return (
    <Grid container spacing={1}>
      <Grid item md={2}>
        <Typography variant="body2">
          <FormattedMessage id="title.day" defaultMessage="Day" />{' '}
        </Typography>
      </Grid>
      <Grid item md={4}>
        <Typography variant="body2">
          <FormattedMessage id="title.from" defaultMessage="From" />{' '}
        </Typography>
      </Grid>
      <Grid item md={4}>
        <Typography variant="body2">
          <FormattedMessage id="title.to" defaultMessage="To" />{' '}
        </Typography>
      </Grid>
      <Grid item md={2} />
      {WEEK_DAYS.map((day, index) => {
        return (
          <Fragment key={day}>
            <Grid item md={2}>
              <Typography variant="body1">{translate(`translate#title.${day}`)}</Typography>
            </Grid>
            <Grid item md={2}>
              <Field name={`${WEEKDAY_FIELD_NAME_PREFIX}${index + 1}${FROM_HOUR_POSTFIX}`}>
                {TimeInput(true)}
              </Field>
            </Grid>
            <Grid item md={2}>
              <Field name={`${WEEKDAY_FIELD_NAME_PREFIX}${index + 1}${FROM_MIN_POSTFIX}`}>
                {TimeInput()}
              </Field>
            </Grid>
            <Grid item md={2}>
              <Field name={`${WEEKDAY_FIELD_NAME_PREFIX}${index + 1}${TO_HOUR_POSTFIX}`}>
                {TimeInput(true)}
              </Field>
            </Grid>
            <Grid item md={2}>
              <Field name={`${WEEKDAY_FIELD_NAME_PREFIX}${index + 1}${TO_MIN_POSTFIX}`}>
                {TimeInput()}
              </Field>
            </Grid>
            <Grid item md={2} />
          </Fragment>
        )
      })}
      {errors.length > 0 &&
        errors.map((err, idx) => (
          <Fragment key={idx}>
            {err && (
              <Grid item md={12} className={classes.errorLabel}>
                <ErrorIcon />
                <span>
                  {typeof err === 'string' ? (
                    <FormattedMessage id={`form.validationWarning.${err}`} />
                  ) : (
                    <FormattedMessage
                      id={`form.validationWarning.${err.key}`}
                      values={{
                        fromValue: err.fromValue,
                        toValue: err.toValue,
                      }}
                    />
                  )}
                </span>
              </Grid>
            )}
          </Fragment>
        ))}
      <Grid item>
        <Button onClick={handleClearHours} variant="outlined" borderless startIcon={<Close />}>
          <FormattedMessage id="action.clearWorkingHours" defaultMessage="Clear working hours" />
        </Button>
      </Grid>
      {filledRows.length === 1 && errors.length === 0 && (
        <Grid item>
          <Button onClick={handleCopyHours} variant="outlined" borderless startIcon={<Queue />}>
            <FormattedMessage id="action.copyWorkingHours" defaultMessage="Copy working hours" />
          </Button>
        </Grid>
      )}
    </Grid>
  )
}
