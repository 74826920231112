export enum AtmeyeLicenseListActions {
  GetLicenseListRequest = '@/LICENSE_LIST/GET_LICENSE_LIST_REQUEST',
  GetLicenseListResponse = '@/LICENSE_LIST/GET_LICENSE_LIST_RESPONSE',
  GetLicenseListResponseFail = '@/LICENSE_LIST/GET_LICENSE_LIST_RESPONSE_FAIL',

  GetLicenseFilterListRequest = '@/LICENSE_LIST/GET_LICENSE_FILTER_LIST_REQUEST',
  GetLicenseFilterListResponse = '@/LICENSE_LIST/GET_LICENSE_FILTER_LIST_RESPONSE',
  GetLicenseFilterListResponseFail = '@/LICENSE_LIST/GET_LICENSE_FILTER_LIST_RESPONSE_FAIL',

  ChangeSelectedFilters = '@/LICENSE_LIST/CHANGE_SELECTED_FILTERS',
  ClearSelectedFilters = '@/LICENSE_LIST/CLEAR_SELECTED_FILTERS',

  GetLicenseListExport = '@/LICENSE_LIST/GET_LICENSE_LIST_EXPORT',
  GetExportResponse = '@/GET_EXPORT_RESPONSE',
  GetExportResponseFail = '@/GET_EXPORT_RESPONSE_FAIL',

  ChangeSorting = '@/LICENSE_LIST/CHANGE_SORTING',
  ChangeSortingDirection = '@/LICENSE_LIST/CHANGE_SORTING_DIRECTION',

  GetFiltersTemplatesRequest = '@/LICENSE_LIST/GET_FILTERS_TEMPLATES_REQUEST',
  GetFiltersTemplatesResponse = '@/LICENSE_LIST/GET_FILTERS_TEMPLATES_RESPONSE',

  CreateFilterTemplateRequest = '@/LICENSE_LIST/CREATE_FILTER_TEMPLATE_REQUEST',
  CreateSdFilterTemplateResponse = '@/LICENSE_LIST/CREATE_SD_FILTER_TEMPLATE_RESPONSE',

  SetCurrentFilterTemplate = '@/LICENSE_LIST/SET_CURRENT_FILTER_TEMPLATE',

  UpdateFilterTemplateRequest = '@/LICENSE_LIST/UPDATE_FILTER_TEMPLATE_REQUEST',
  UpdateLicenseListFilterTemplateResponse = '@/LICENSE_LIST/UPDATE_SD_FILTER_TEMPLATE_RESPONSE',

  DeleteFilterTemplateRequest = '@/LICENSE_LIST/DELETE_FILTER_TEMPLATE_REQUEST',
  DeleteFilterTemplateResponse = '@/LICENSE_LIST/DELETE_FILTER_TEMPLATE_RESPONSE',
}
