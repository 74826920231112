import { createSelector } from 'reselect'
import { AppState } from '@/store/reducers'
import * as fromReducer from './reducer'

export const getCMSharedState = (state: AppState): fromReducer.State => state.cmShared

export const getShrCassettes = createSelector(getCMSharedState, state => state.cassettes)

export const getShrCassettesEditor = createSelector(getCMSharedState, state => state.cassetteEditor)

export const getShrDeclineNoteState = createSelector(
  getCMSharedState,
  state => state.declineNoteState,
)
