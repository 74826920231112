import * as H from 'history'
import _ from 'lodash'
import {
  DependencyList,
  EffectCallback,
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Payload } from 'rsocket-types'

import { Command } from '@/api/rsocket/command'
import { MessageService } from '@/api/rsocket/messageService'
import { notificationConfig } from '@/constants/atmeye/notificationConfig'
import { getCurrentAccount } from '@/store/auth'
import { setSocketData, setSocketDataError, setSocketMessageError } from '@/store/dashboard'
import { actionSetNotification, actionsNotifications } from '@/store/notifications'
import { useTranslate } from '@/utils/internalization'

export const useWindowSize = (): number[] => {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    const updateSize = (): void => {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return (): void => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

export const useClickOutside = (
  ref: RefObject<HTMLDivElement | HTMLButtonElement>,
  callback: () => void,
  restrictRef?: RefObject<HTMLDivElement | HTMLButtonElement>,
): void => {
  const handleClick = (e: any): void => {
    if (
      ref.current &&
      !ref.current.contains(e.target) &&
      !restrictRef?.current?.contains(e.target)
    ) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return (): void => {
      document.removeEventListener('click', handleClick)
    }
  })
}

export const usePreviousValue = (value: any): any => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

export const useScrollPosition = (): Array<any> => {
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const ref = useRef<number>(0)
  const dispatch = useDispatch()

  const setPosition = _.debounce((position: number) => {
    if (ref.current && ref.current > 0 && position === 0) {
      setScrollPosition(position)
    }

    if (position > 0) {
      ref.current = position
      setScrollPosition(position)
    }
  }, 200)

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>): void => {
    setPosition(event.currentTarget.scrollLeft)
  }

  return [scrollPosition, handleScroll]
}

export const useSearch = (requestFunction: Function, searchByLUNO?: string) => {
  const dispatch = useDispatch()
  const anyDebounce = _.debounce((value: any): void => {
    const fragment = value?.fragment || value

    if (fragment.length >= 3) {
      dispatch(requestFunction(value, searchByLUNO === '1'))
    }
  }, 1000)

  const handleChange = useCallback(anyDebounce, [dispatch, searchByLUNO])

  return [handleChange]
}

export const useCheckUnsavedChanges = (
  disableLeave: boolean,
  setAllowToLeave?: (allowToLeave: boolean) => void,
): { allowLeave: boolean } => {
  const [allowLeave, setAllowLeave] = useState<boolean>(true)

  useEffect(() => {
    if (disableLeave) {
      setAllowLeave(false)
      if (setAllowToLeave) setAllowToLeave(false)
      window.onbeforeunload = (): boolean => true
    } else {
      setAllowLeave(true)
      if (setAllowToLeave) setAllowToLeave(true)
      window.onbeforeunload = null
    }

    return (): void => {
      window.onbeforeunload = null
    }
  }, [disableLeave])

  return { allowLeave }
}

export const useSocketContext = (token: string | null): any => {
  const [error, setError] = useState(false)
  const [connected, setConnected] = useState(false)
  const translate = useTranslate()
  const currentAccount = useSelector(getCurrentAccount)
  const dispatch = useDispatch()
  const _service: MutableRefObject<MessageService | undefined> = useRef<MessageService>()

  const handleResponseMessage = (error: any, message: any): void => {
    setError(error)
    if (message.payload.errorMessage) {
      dispatch(setSocketMessageError({ [message.name]: message.payload.errorMessage }))
      dispatch(setSocketDataError(translate('translate#cm.ErrorWindowGenericError')))
      return
    }
    dispatch(setSocketData(message))
    if (message?.name === Command.ATM_EYE_NOTIFICATION) {
      const notificationType = message?.payload?.notificationType as keyof typeof notificationConfig

      dispatch(
        actionSetNotification.setNotification({
          deviceId: message?.payload?.deviceId,
          eventDate: message?.payload?.eventDate,
          deviceName: message?.payload?.deviceName,
          eventName: message?.payload?.eventTypeName,
          eventId: message?.payload?.eventId,
          container: notificationConfig[notificationType],
          eventTypeId: message?.payload.eventTypeId,
        }),
      )
    }
  }

  useEffect(() => {
    if (token) {
      _service.current = MessageService.getInstance()

      _service.current.setConnectionParams({
        userId: String(currentAccount?.userId),
        token: `Bearer ${token}`,
        responder: {
          fireAndForget(payload: Payload<any, any>): void {
            handleResponseMessage(null, JSON.parse(payload.data.toString()))
          },
        },
        onError: () => {
          setError(false)
          setConnected(true)
        },
      })
    }
    return (): void => {
      if (_service.current) {
        _service.current.close()
        _service.current = undefined
      }
    }
  }, [])

  useEffect(() => {
    if (_service.current && !connected) {
      _service.current.connect({
        onSuccess: () => setConnected(true),
      })
    }
  }, [_service, error, connected])

  return { error, connected, _service: _service.current }
}

export const useDidUpdateEffect = (effect: EffectCallback, dependencies?: DependencyList): void => {
  const didMountRef = useRef<boolean>(false)

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true
    } else {
      return effect()
    }
  }, dependencies)
}

export const useGetParamFromURLState = <T>() => {
  const location: H.Location<T | undefined> = useLocation()
  if (location.state) {
    window.history.replaceState(undefined, location.pathname)
  }

  return { location }
}
