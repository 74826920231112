interface GroupedProductGroup {
  groupValue: string
  groupDisplay: string
  displayValueType: 'DATE' | 'STRING'
}

export interface GroupedProduct {
  groupUID: string
  groupLevelNo: number
  group: GroupedProductGroup
  childCount: number
  childGroups: GroupedProduct[]
  parentGroups: GroupedProductGroup | null
  details: null
  totalDetailsCount: number
}

export interface Products {
  totalCount: number
  hasGroups: boolean
  groups: GroupedProduct[] | null
  details:
    | {
        pageCount: number
        outputPageNo: number
        items: Array<{
          item: Record<string, string | null>
          childItems: Record<string, string | null>[]
        }>
      }
    | null
    | undefined
}

export enum ProductFieldName {
  ActualTillDate = 'actualTillDate',
  ComplexityLevelId = 'complexityLevelId',
  ComplexityLevelName = 'complexityLevelName',
  Functionality = 'functionality',
  IsRepairable = 'isRepairable',
  ListPrice = 'listPrice',
  NettoWeightKg = 'nettoWeightKg',
  ProductAccountingGroupId = 'productAccountingGroupId',
  ProductAccountingGroupName = 'productAccountingGroupName',
  ProductCategoryId = 'productCategoryId',
  ProductCategoryName = 'productCategoryName',
  ProductCode = 'productCode',
  ProductGroupId = 'productGroupId',
  ProductGroupName = 'productGroupName',
  ProductId = 'productId',
  ProductName = 'productName',
  ProductSparePartGroupId = 'productSparePartGroupId',
  ProductSparePartGroupName = 'productSparePartGroupName',
  QuantityKeyId = 'quantityKeyId',
  QuantityKeyName = 'quantityKeyName',
  StatWnr = 'statWnr',
  VendorArticleNo = 'vendorArticleNo',
  VendorCompanyId = 'vendorCompanyId',
  VendorCompanyName = 'vendorCompanyName',
  VendorCountryId = 'vendorCountryId',
  VendorCountryName = 'vendorCountryName',
  VendorRepairPrice = 'vendorRepairPrice',
  Height = 'height',
  Length = 'length',
  Width = 'width',
}

export interface Product {
  [ProductFieldName.ActualTillDate]: string | null
  [ProductFieldName.ComplexityLevelId]: number | null
  [ProductFieldName.Functionality]: [] | null
  [ProductFieldName.Height]: number | null
  [ProductFieldName.Width]: number | null
  [ProductFieldName.IsRepairable]: boolean
  [ProductFieldName.Length]: number | null
  [ProductFieldName.ListPrice]: number | null
  [ProductFieldName.NettoWeightKg]: number | null
  [ProductFieldName.ProductAccountingGroupId]: number | null
  [ProductFieldName.ProductCategoryId]: number | null
  [ProductFieldName.ProductCode]: string
  [ProductFieldName.ProductGroupId]: number | null
  [ProductFieldName.ProductId]: number | null
  [ProductFieldName.ProductName]: string
  [ProductFieldName.ProductSparePartGroupId]: number | null
  [ProductFieldName.QuantityKeyId]: number | null
  [ProductFieldName.StatWnr]: string | null
  [ProductFieldName.VendorArticleNo]: string | null
  [ProductFieldName.VendorCompanyId]: number | null
  [ProductFieldName.VendorCountryId]: number | null
  [ProductFieldName.VendorRepairPrice]: number | null
}

export interface AlternativeProduct {
  id: number | null
  productCode: string
  productId: number
  productName: string
  productSpGroup: string
  vendorCompany: string
  vendorCountry: string
}

export type ProductSpecification = {
  mainProductId: number
  maxQuantity: number
  minQuantity: number
  partProductCode: string
  partProductGroup: string
  partProductGroupId: number
  partProductId: number
  partProductName: string
  partSellGroup: string
  partSellGroupId: number
  partVendorCompany: string
  partVendorCompanyIdt: number
  partVendorCountry: string
  partVendorCountryId: number
  productCode: string
  productName: string
  productSpGroup: string
  productSpGroupId: number
  productSpecificationId: number | null
}

export type AddUpdateProductSpecification = {
  maxQuantity: number
  minQuantity: number
  partProductId: number
  productSpecificationId: number | null
}
