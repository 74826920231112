import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'
import { TypeOfTickets } from '@/types'

import * as fromDevices from './reducer'

export const getTicketsState = (state: AppState): fromDevices.State => state.devices

export const getSelectedGroupingTemplate = createSelector(
  getTicketsState,
  state => state.selectedGroupingTemplate,
)

export const getTicketsData = createSelector(getTicketsState, state => {
  if (state.typeOfTickets) {
    switch (state.data[state.typeOfTickets as TypeOfTickets].details) {
      case undefined:
        return undefined
      case null:
        return []
      default:
        return state.data[state.typeOfTickets as TypeOfTickets].details?.items
    }
  }
})

export const getCurrentFilterTemplate = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.filter[state.typeOfTickets as TypeOfTickets].selectedTemplate
    : fromDevices.initialState.filter.allTickets.selectedTemplate,
)

export const getFilterTemplateFields = createSelector(
  getTicketsState,
  state => state.filterTemplateFields,
)

export const getExportedFile = createSelector(getTicketsState, state => state.downloadFile)

export const isDowloadingFile = createSelector(getTicketsState, state => state.isDownloadingFile)

export const getGroupDetailsData = createSelector(getTicketsState, state => {
  if (state.typeOfTickets && state.groupDetails) {
    const page = state.page[state.typeOfTickets as TypeOfTickets] || 1
    const rowsPerPage = Number(state.pageSizeForGroupingTickets) || 10

    return state.groupDetails.slice(0, page * rowsPerPage)
  }
})

export const getGroupDetailsCount = createSelector(getTicketsState, state =>
  state.typeOfTickets ? state.groupDetails?.length : 0,
)

export const getGroupedTicketsData = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.data[state.typeOfTickets as TypeOfTickets].groups
    : fromDevices.initialState.data.allTickets.groups,
)

export const isFetchingGroupDetails = createSelector(
  getTicketsState,
  state => state.isFetchingGroupDetails,
)

export const getTicketsTotalCount = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.data[state.typeOfTickets as TypeOfTickets].totalCount
    : fromDevices.initialState.data.allTickets.totalCount,
)

export const getTicketsPageCount = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.data[state.typeOfTickets as TypeOfTickets].details?.pageCount
    : fromDevices.initialState.data.allTickets.details?.pageCount,
)

export const getTicketsRowsPerPage = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.rowsPerPage[state.typeOfTickets as TypeOfTickets]
    : fromDevices.initialState.rowsPerPage.allTickets,
)

export const getTicketsPage = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.page[state.typeOfTickets as TypeOfTickets]
    : fromDevices.initialState.page.allTickets,
)

export const getSelectedFilters = createSelector(getTicketsState, state => {
  return state.typeOfTickets
    ? state.filter[state.typeOfTickets as TypeOfTickets].selectedFilters
    : fromDevices.initialState.filter.allTickets.selectedFilters
})

export const isFiltersSelected = createSelector(getTicketsState, state => {
  return !Object.values(state.filter[state.typeOfTickets as TypeOfTickets].selectedFilters).every(
    (filter: string | null) => filter === null || filter === '',
  )
})

export const getFiltersTemplates = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.filter[state.typeOfTickets as TypeOfTickets].filterTemplates
    : fromDevices.initialState.filter.allTickets.filterTemplates,
)

export const isFetchingFilterTemplates = createSelector(
  getTicketsState,
  state => state.isFetchingFilterTemplates,
)

export const isFetchingTickets = createSelector(getTicketsState, state => state.isFetchingTickets)

export const getGroupingTemplates = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.grouping[state.typeOfTickets as TypeOfTickets].groupingsTemplates
    : fromDevices.initialState.grouping.allTickets.groupingsTemplates,
)

export const getGrouping = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.grouping[state.typeOfTickets as TypeOfTickets].selectedGroupings
    : fromDevices.initialState.grouping.allTickets.selectedGroupings,
)

export const isFetchingGroupTemplates = createSelector(
  getTicketsState,
  state => state.isFetchingGroupTemplates,
)

export const getTicketsConfigData = createSelector(
  getTicketsState,
  state => state.config[state.typeOfTickets],
)

export const getIsFetchingTicketsConfig = createSelector(
  getTicketsState,
  state => state.isFetchingTicketsConfig,
)

export const getTicketSorting = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.sorting[state.typeOfTickets as TypeOfTickets]
    : fromDevices.initialState.sorting.allTickets,
)

export const getStringFilter = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.stringFitler[state.typeOfTickets as TypeOfTickets]
    : fromDevices.initialState.stringFitler.allTickets,
)

export const hasGroupedTickets = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.grouping[state.typeOfTickets as TypeOfTickets].selectedGroupings?.length !== 0
    : false,
)
