import { createSelector } from 'reselect'
import { AppState } from '@/store/reducers'
import * as fromWindowsEditActions from './reducer'
import { initialState } from './reducer'

export const getWindowsEditActionsState = (state: AppState): fromWindowsEditActions.State =>
  state.windowsEditActions || initialState

export const isToggleMoveSplitPaneable = createSelector(
  getWindowsEditActionsState,
  state => state.toggleMoveSplitPaneable,
)

export const getSplitPaneHeight = createSelector(
  getWindowsEditActionsState,
  state => state.splitPaneHeightPosition,
)
export const getSplitPaneWidth = createSelector(
  getWindowsEditActionsState,
  state => state.splitPaneWidthPosition,
)

export const getWindowProperty = createSelector(
  getWindowsEditActionsState,
  state => state.windowPropertiesIsOpen,
)

export const getDrawerState = createSelector(
  getWindowsEditActionsState,
  state => state.drawerInOpen,
)
