import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, putResolve, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { CashOrdersApi } from '@/api/vault-v1/cashOrdersApi'
import { VaultV1CommonApi } from '@/api/vault-v1/commonApi'
import { Order, OrdersResponse } from '@/api/vault-v1/swaggerGeneratedApi'
import { StageRequestParams } from '@/components/pages/vault-v1/CashOrdersPage/components/panels/OrderStages/types'
import { VaultV1Actions } from '@/constants'
import { VAULT_V1_INTEGRATIONS } from '@/constants/vault-v1/integrations'
import { AppState } from '@/store/reducers'
import { hotKeysBinder } from '@/utils/hotKeysBinder'

import { getIsIntegrationEnable, setComVaultV1Error, setGlobalFilter } from '../common'
import { getOrdStageReportsRequest } from '.'
import {
  addOrdCashOrderFail,
  addOrdCashOrderResponse,
  editOrdCashOrderFail,
  editOrdCashOrderResponse,
  getOrdBagSealFail,
  getOrdBagSealResponse,
  getOrdDenominationsFail,
  getOrdDenominationsRequest,
  getOrdDenominationsResponse,
  getOrdDestinationsFail,
  getOrdDestinationsResponse,
  getOrdersFail,
  getOrdersRequest,
  getOrdersResponse,
  getOrdersStagesFail,
  getOrdersStagesResponse,
  getOrdExpDenominationsRequest,
  getOrdExpDestinationsRequest,
  getOrdFilterCurrencyResponse,
  getOrdFilterObjectIdResponse,
  getOrdFilterStatusResponse,
  getOrdFilterTeamResponse,
  getOrdFilterTypeResponse,
  getOrdReportFail,
  getOrdReportResponse,
  getOrdReportsFail,
  getOrdReportsResponse,
  getOrdSourcesFail,
  getOrdSourcesRequest,
  getOrdSourcesResponse,
  getOrdStageIntegrationFail,
  getOrdStageIntegrationRequest,
  getOrdStageIntegrationResponse,
  getOrdStageReportFail,
  getOrdStageReportResponse,
  getOrdStageReportsFail,
  getOrdStageReportsResponse,
  getOrdValuablesFail,
  getOrdValuablesResponse,
  getOrdWorkOrderFail,
  getOrdWorkOrderResponse,
  getSingleVaultFail,
  getSingleVaultRequest,
  getSingleVaultResponse,
  handleApproveAllOrdFail,
  openOrdDocumentsModal,
  openOrdFiltersModal,
  refreshOrdOrders,
  resetOrdOrdersPaging,
  retryOrdStageIntegrationFail,
  retryOrdStageIntegrationResponse,
  setOrdBagSealFail,
  setOrdBagSealResponse,
  setOrdCashierIdResponse,
  setOrderStageFail,
  setOrderStageResponse,
  setOrdFilter,
  setOrdSelectedOrder,
  setOrdWorkOrderFail,
  setOrdWorkOrderModalOpen,
  setOrdWorkOrderResponse,
} from './actions'
import {
  getExportWorkOrders,
  getGroupedWorkOrders,
  getOrdDestinations as getOrdDestinationsSelect,
  getOrdOrders,
  getOrdOrdersStages,
  getOrdSelectedOrder,
  getOrdSources as getOrdSourcesSelect,
  getRetryStageId,
} from './selectors'

function* getVaults(): SagaIterator {
  try {
    const { selectedOrder, newOrderId } = yield select(getOrdOrders)
    const globalFilter = yield select((state: AppState) => state?.vaultV1Common.globalFilter)
    const filter = yield select((state: AppState) => state?.vaultV1CashOrders.orders.filter)
    const sort = yield select((state: AppState) => ({
      sortColumn: state.vaultV1CashOrders.orders.sortColumn,
      sortOrder: state.vaultV1CashOrders.orders.sortOrder,
    }))
    const paging = yield select((state: AppState) => {
      const { page, pageSize } = state.vaultV1CashOrders.orders.paging
      return {
        page,
        pageSize: pageSize === -1 ? 'All' : pageSize,
      }
    })

    const response: OrdersResponse = yield call(
      CashOrdersApi.getOrders,
      paging,
      { globalFilter, filter },
      sort.sortColumn,
      sort.sortOrder,
    )

    yield put(getOrdersResponse(response))

    if (response) {
      if (selectedOrder) {
        if (newOrderId) {
          const newSelectedCashPoint = response.orders.find(
            (cashPoint: Order) => cashPoint.id === newOrderId,
          )
          if (newSelectedCashPoint) {
            yield putResolve(setOrdSelectedOrder(newSelectedCashPoint))
            return
          }
        }
        const updatedSelectedCashPoint = response.orders.find(
          (cashPoint: Order) => cashPoint.id === selectedOrder.id,
        )
        if (updatedSelectedCashPoint) {
          yield putResolve(setOrdSelectedOrder(updatedSelectedCashPoint))
        } else {
          yield putResolve(setOrdSelectedOrder(null))
        }
      }
    }
  } catch (error) {
    yield put(getOrdersFail(error.message))
  }
}

function* getSingleVault({ payload: id }: AnyAction): SagaIterator {
  try {
    const { selectedOrder } = yield select(getOrdOrders)
    if (selectedOrder.id || id) {
      const order: Order = yield call(CashOrdersApi.getSingleVault, id || selectedOrder.id)

      yield put(getSingleVaultResponse(order))
    }
  } catch (error) {
    yield put(getSingleVaultFail(error.message))
  }
}

function* handleFilterSubmit({ payload }: AnyAction): SagaIterator {
  yield put(setGlobalFilter(payload.globalFilter))
  yield put(setOrdFilter(payload.filter))
  yield put(resetOrdOrdersPaging())
  yield put(setOrdSelectedOrder(null))

  yield put(getOrdersRequest())
}

function* approveAll(): SagaIterator {
  try {
    const globalFilter = yield select((state: AppState) => state?.vaultV1Common.globalFilter)
    const filter = yield select((state: AppState) => state?.vaultV1CashOrders.orders.filter)

    yield call(CashOrdersApi.approveAll, { globalFilter, filter })

    yield put(getOrdersRequest())
  } catch (error) {
    yield put(handleApproveAllOrdFail())
  }
}

function* getOrderStages(): SagaIterator {
  try {
    const { selectedOrder } = yield select(getOrdOrders)

    if (selectedOrder.id) {
      const response = yield call(CashOrdersApi.getOrderStages, selectedOrder.id)

      yield put(getOrdersStagesResponse(response))
    } else {
      yield put(getOrdersStagesFail())
    }
  } catch (error) {
    yield put(getOrdersStagesFail(error.message))
  }
}

interface PartialState {
  data: Order[]
  selectedOrder: Order | null
}

function* setOrderStages({ payload }: AnyAction): SagaIterator {
  const { isCashierIdModalOpen } = yield select(getOrdOrdersStages)
  try {
    const { url, requestBody }: StageRequestParams = payload
    const {
      paging: { pageSize },
    } = yield select(getOrdOrders)
    const { data, selectedOrder }: PartialState = yield select(getOrdOrders)

    if (url && requestBody && selectedOrder) {
      const sameWorkOrdersIds = data
        .filter(
          order =>
            order.workOrderId === selectedOrder.workOrderId && selectedOrder.workOrderId !== '',
        )
        .map(order => order.id || '')

      yield call(CashOrdersApi.setOrderStages, url, requestBody)

      if (sameWorkOrdersIds.length) {
        if (sameWorkOrdersIds.length < pageSize / 2 && sameWorkOrdersIds.length < 100) {
          for (const orderId of sameWorkOrdersIds) {
            yield put(getSingleVaultRequest(orderId))
          }
        } else {
          yield put(getOrdersRequest())
        }
      } else {
        yield put(getSingleVaultRequest())
      }

      yield put(setOrderStageResponse())

      if (isCashierIdModalOpen) {
        yield put(setOrdCashierIdResponse(true))
      }
    }
  } catch (error) {
    if (isCashierIdModalOpen) {
      yield put(setOrdCashierIdResponse(false))
    }
    yield put(setOrderStageFail(error.message))
  }
}

function* getOrdReports(): SagaIterator {
  try {
    const filter = yield select((state: AppState) => state.vaultV1CashOrders.orders.filter)
    const globalFilter = yield select((state: AppState) => state.vaultV1Common.globalFilter)

    const requestBody = {
      globalFilter: globalFilter,
      ordersFilter: filter,
    }

    const response = yield call(CashOrdersApi.getReports, requestBody)
    yield put(getOrdReportsResponse(response))
  } catch (e) {
    yield put(getOrdReportsFail(e?.message))
  }
}

function* getOrdReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(VaultV1CommonApi.getReport, payload)
      yield put(getOrdReportResponse(response))
    } else {
      yield put(getOrdReportResponse(null))
    }
  } catch (e) {
    yield put(getOrdReportFail(e?.message))
  }
}

function* getOrdStageReports(): SagaIterator {
  try {
    const { selectedOrder } = yield select(getOrdOrders)
    if (selectedOrder) {
      yield put(getOrdStageReportsRequest())
      const requestBody = {
        orderId: selectedOrder?.id,
      }

      const response = yield call(CashOrdersApi.getStageReports, requestBody)
      yield put(getOrdStageReportsResponse(response))
    }
  } catch (e) {
    yield put(getOrdStageReportsFail(e?.message))
  }
}

function* getOrdStageReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(VaultV1CommonApi.getReport, payload)

      yield put(getOrdStageReportResponse(response))
    } else {
      yield put(getOrdStageReportResponse(null))
    }
  } catch (e) {
    yield put(getOrdStageReportFail(e?.message))
  }
}

function* getOrdDestinations(): SagaIterator {
  try {
    const response = yield call(CashOrdersApi.getOrdDestinations)

    if (response) {
      yield putResolve(getOrdDestinationsResponse(response))
      yield put(getOrdSourcesRequest())
    }
  } catch (e) {
    yield put(getOrdDestinationsFail())
  }
}

function* getOrdSources(): SagaIterator {
  try {
    const { selected: selectedDestinations } = yield select(getOrdDestinationsSelect)
    const response = yield call(CashOrdersApi.getOrdSources, selectedDestinations)

    if (response) {
      yield putResolve(getOrdSourcesResponse(response))
      yield put(getOrdDenominationsRequest())
    }
  } catch (e) {
    yield put(getOrdSourcesFail())
  }
}

function* getOrdDenominations(): SagaIterator {
  try {
    const { selected: selectedDestinations } = yield select(getOrdDestinationsSelect)
    const { selected: selectedSours } = yield select(getOrdSourcesSelect)

    const response = yield call(
      CashOrdersApi.getOrdDenominations,
      selectedDestinations,
      selectedSours,
    )
    yield put(getOrdDenominationsResponse(response))
  } catch (e) {
    yield put(getOrdDenominationsFail())
  }
}

function* getOrdExpSources(): SagaIterator {
  try {
    const response = yield call(CashOrdersApi.getOrdExpSources)

    if (response) {
      yield putResolve(getOrdSourcesResponse(response))
      yield put(getOrdExpDestinationsRequest())
    }
  } catch (e) {
    yield put(getOrdSourcesFail())
  }
}

function* getOrdExpDestinations(): SagaIterator {
  try {
    const { selected: selectedSours } = yield select(getOrdSourcesSelect)

    const response = yield call(CashOrdersApi.getOrdExpDestinations, selectedSours)

    if (response) {
      yield putResolve(getOrdDestinationsResponse(response))
      yield put(getOrdExpDenominationsRequest())
    }
  } catch (e) {
    yield put(getOrdDestinationsFail())
  }
}

function* getOrdExpDenominations(): SagaIterator {
  try {
    const { selected: selectedDestinations } = yield select(getOrdDestinationsSelect)
    const { selected: selectedSours } = yield select(getOrdSourcesSelect)

    const response = yield call(
      CashOrdersApi.getOrdDenominations,
      selectedSours,
      selectedDestinations,
    )
    yield put(getOrdDenominationsResponse(response))
  } catch (e) {
    yield put(getOrdDenominationsFail())
  }
}

function* addNewCashOrder({ payload }: AnyAction): SagaIterator {
  try {
    const newOrderId = yield call(CashOrdersApi.addNewCashOrder, payload)

    yield put(addOrdCashOrderResponse(newOrderId))
    yield put(getOrdersRequest())
  } catch (e) {
    yield put(addOrdCashOrderFail(e?.message))
  }
}

function* editNewCashOrder({ payload }: AnyAction): SagaIterator {
  try {
    yield call(CashOrdersApi.editNewCashOrder, payload)

    yield put(editOrdCashOrderResponse())
    yield put(getSingleVaultRequest())
  } catch (e) {
    yield put(editOrdCashOrderFail(e?.message))
  }
}

function* getFilterCurrency(): SagaIterator {
  try {
    const response = yield call(CashOrdersApi.getFilterCurrency)

    yield put(getOrdFilterCurrencyResponse(response))
  } catch (e) {
    yield put(setComVaultV1Error(e.message))
  }
}

function* getFilterObjectId(): SagaIterator {
  try {
    const response = yield call(VaultV1CommonApi.getVaults)

    yield put(getOrdFilterObjectIdResponse(response))
  } catch (e) {
    yield put(setComVaultV1Error(e.message))
  }
}

function* getFilterTeam(): SagaIterator {
  try {
    const response = yield call(CashOrdersApi.getFilterTeam)

    yield put(getOrdFilterTeamResponse(response))
  } catch (e) {
    yield put(setComVaultV1Error(e.message))
  }
}

function* getFilterType(): SagaIterator {
  try {
    const response = yield call(VaultV1CommonApi.getEnums, 'eOrderType')

    if (response) {
      yield put(getOrdFilterTypeResponse(response))
    }
  } catch (e) {
    yield put(setComVaultV1Error(e.message))
  }
}

function* getFilterStatus(): SagaIterator {
  try {
    const response = yield call(VaultV1CommonApi.getEnums, 'eOrderStatus')

    if (response) {
      yield put(getOrdFilterStatusResponse(response))
    }
  } catch (e) {
    yield put(setComVaultV1Error(e.message))
  }
}

function* getBagSeals(): SagaIterator {
  try {
    const { selectedOrder } = yield select(getOrdOrders)

    const response = yield call(CashOrdersApi.getBagSeals, selectedOrder.id)

    yield put(getOrdBagSealResponse(response))
  } catch (e) {
    yield put(getOrdBagSealFail(e.message))
  }
}

function* setBagSeals({ payload }: AnyAction): SagaIterator {
  try {
    const { url, requestBody } = payload

    if (url && requestBody) {
      yield call(CashOrdersApi.setBagSeals, url, requestBody)

      yield put(setOrdBagSealResponse())
      yield put(getSingleVaultRequest())
    }
  } catch (error) {
    yield put(setOrdBagSealFail(error.message))
  }
}

function* getValuables(): SagaIterator {
  try {
    const response = yield call(CashOrdersApi.getValuables)

    yield put(getOrdValuablesResponse(response))
  } catch (e) {
    yield put(getOrdValuablesFail(e.message))
  }
}

function* getWorkOrders(): SagaIterator {
  try {
    const globalFilter = yield select((state: AppState) => state?.vaultV1Common.globalFilter)
    const filter = yield select((state: AppState) => state?.vaultV1CashOrders.orders.filter)

    const response = yield call(CashOrdersApi.getWorkOrders, { globalFilter, filter })

    yield put(getOrdWorkOrderResponse(response))
  } catch (e) {
    yield put(getOrdWorkOrderFail(e.message))
  }
}

function* setWorkOrders({ payload }: AnyAction): SagaIterator {
  try {
    const { url, requestBody }: StageRequestParams = payload
    const {
      total,
      paging: { pageSize },
    } = yield select(getOrdOrders)
    const { checkedGropedWO } = yield select(getGroupedWorkOrders)
    const { checkedExportWO } = yield select(getExportWorkOrders)

    if (url && requestBody) {
      const ordersIds = [
        ...Object.keys(checkedGropedWO || {}),
        ...Object.keys(checkedExportWO || {}),
      ]
      if (url && requestBody) {
        const request = {
          ...requestBody,
          orderIds: ordersIds,
        }

        yield call(CashOrdersApi.setWorkOrders, url, request)

        if (ordersIds.length < (pageSize === -1 ? total : pageSize) / 2 && ordersIds.length < 100) {
          for (const orderId of ordersIds) {
            yield put(getSingleVaultRequest(orderId))
          }
        } else {
          yield put(getOrdersRequest())
        }

        yield put(setOrdWorkOrderResponse())
        yield put(setOrdWorkOrderModalOpen({ isOpen: false }))
      }
    }
  } catch (error) {
    yield put(setOrdWorkOrderFail(error.message))
  }
}

function* getIntegrationStage(): SagaIterator {
  try {
    const selectedOrder = yield select(getOrdSelectedOrder)
    const isIntegrationEnabled = yield select(getIsIntegrationEnable(VAULT_V1_INTEGRATIONS.Abs))

    if (selectedOrder?.id && isIntegrationEnabled) {
      const response = yield call(CashOrdersApi.getIntegrationStage, selectedOrder.id)

      yield put(getOrdStageIntegrationResponse(response))
    }
  } catch (e) {
    yield put(getOrdStageIntegrationFail(e.message))
  }
}

function* retryIntegrationStage(): SagaIterator {
  try {
    const selectedOrder = yield select(getOrdSelectedOrder)
    const retryStageId = yield select(getRetryStageId)

    if (selectedOrder?.id) {
      yield call(CashOrdersApi.retryIntegrationStage, {
        guid: selectedOrder.id,
        orderStatusId: retryStageId,
      })

      yield put(retryOrdStageIntegrationResponse())
    }
  } catch (e) {
    yield put(retryOrdStageIntegrationFail(e.message))
  } finally {
    yield put(getOrdStageIntegrationRequest())
  }
}

function* handleKeyPress({ payload }: AnyAction): SagaIterator {
  try {
    const {
      selectedOrder: selected,
      data,
      isFiltersModalOpen,
      isDocumentModalOpen,
      isColumnModalOpen,
      isCashOrderModal: { isOpen: isCashOrderModal },
      isExportOrderModal: { isOpen: isExportOrderModal },
    } = yield select(getOrdOrders)

    hotKeysBinder<Order>({
      key: payload,
      data,
      selected,
      isOpenModalsList: [
        isFiltersModalOpen,
        isDocumentModalOpen,
        isColumnModalOpen,
        isCashOrderModal,
        isExportOrderModal,
      ],
      setSelectedAction: setOrdSelectedOrder,
      openFilterAction: openOrdFiltersModal,
      openPrintAction: openOrdDocumentsModal,
      onRefreshAction: refreshOrdOrders,
    })
  } catch (error) {
    yield put(setComVaultV1Error(error))
  }
}

export default function*(): Generator {
  yield takeLatest(
    [
      VaultV1Actions.OrdGetOrdersRequest,
      VaultV1Actions.OrdRefreshOrders,
      VaultV1Actions.OrdSetOrdersPaging,
      VaultV1Actions.OrdSortOrdersTable,
    ],
    getVaults,
  )

  yield takeEvery(VaultV1Actions.OrdGetSingleVaultRequest, getSingleVault)
  yield takeLatest(VaultV1Actions.OrdSubmitFilterRequest, handleFilterSubmit)
  yield takeLatest(
    [VaultV1Actions.OrdSetSelectedOrder, VaultV1Actions.OrdGetSingleVaultResponse],
    getOrderStages,
  )
  yield takeLatest(
    [VaultV1Actions.OrdSetOrdersStagesRequest, VaultV1Actions.SetOrdCashierIdRequest],
    setOrderStages,
  )

  yield takeLatest(VaultV1Actions.GetOrdReportsRequest, getOrdReports)
  yield takeLatest(VaultV1Actions.GetOrdReportRequest, getOrdReport)

  yield takeLatest(VaultV1Actions.OrdSetSelectedOrder, getOrdStageReports)
  yield takeLatest(VaultV1Actions.GetOrdStageReportRequest, getOrdStageReport)

  yield takeLatest(VaultV1Actions.GetOrdDestinationsRequest, getOrdDestinations)
  yield takeLatest(VaultV1Actions.GetOrdSourcesRequest, getOrdSources)
  yield takeLatest(VaultV1Actions.GetOrdDenominationsRequest, getOrdDenominations)

  yield takeLatest(VaultV1Actions.GetOrdExpSourcesRequest, getOrdExpSources)
  yield takeLatest(VaultV1Actions.GetOrdExpDestinationsRequest, getOrdExpDestinations)
  yield takeLatest(VaultV1Actions.GetOrdExpDenominationsRequest, getOrdExpDenominations)

  yield takeLatest(VaultV1Actions.AddOrdCashOrderRequest, addNewCashOrder)
  yield takeLatest(VaultV1Actions.EditOrdCashOrderRequest, editNewCashOrder)

  yield takeLatest(VaultV1Actions.OrdGetFilterCurrencyRequest, getFilterCurrency)
  yield takeLatest(VaultV1Actions.OrdGetFilterObjectIdRequest, getFilterObjectId)
  yield takeLatest(VaultV1Actions.OrdGetFilterTeamRequest, getFilterTeam)
  yield takeLatest(VaultV1Actions.OrdGetFilterTypeRequest, getFilterType)
  yield takeLatest(VaultV1Actions.OrdGetFilterStatusRequest, getFilterStatus)

  yield takeLatest(VaultV1Actions.GetOrdBagSealRequest, getBagSeals)
  yield takeLatest(VaultV1Actions.SetOrdBagSealRequest, setBagSeals)

  yield takeLatest(VaultV1Actions.GetOrdValuablesRequest, getValuables)

  yield takeLatest(VaultV1Actions.GetOrdWorkOrderRequest, getWorkOrders)
  yield takeLatest(VaultV1Actions.SetOrdWorkOrderRequest, setWorkOrders)

  yield takeLatest(
    [
      VaultV1Actions.OrdSetSelectedOrder,
      VaultV1Actions.OrdGetSingleVaultResponse,
      VaultV1Actions.GetOrdStageIntegrationRequest,
    ],
    getIntegrationStage,
  )
  yield takeLatest(VaultV1Actions.RetryOrdStageIntegrationRequest, retryIntegrationStage)

  yield takeLatest(VaultV1Actions.OrdApproveAllRequest, approveAll)

  yield takeLatest(VaultV1Actions.OrdHandleKeyPress, handleKeyPress)
}
