import { AnyAction } from 'redux'

import { AdmCurrency } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants/actions'

export type State = {
  data: AdmCurrency[]

  isLoading: boolean
  isLoaded: boolean

  sortColumn?: string
  sortOrder?: string
}

export const initialState: State = {
  data: [],
  isLoading: false,
  isLoaded: false,
  sortColumn: undefined,
  sortOrder: undefined,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    // get
    case CMAppConfigActions.AdmGetCurrenciesRequest:
    case CMAppConfigActions.AdmAddCurrencyRequest:
    case CMAppConfigActions.AdmUpdateCurrencyRequest:
    case CMAppConfigActions.AdmDeleteCurrencyRequest:
    case CMAppConfigActions.AdmSetCurrencyAsBaseRequest:
      return {
        ...state,
        isLoading: true,
      }

    case CMAppConfigActions.AdmGetCurrenciesResponse:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: payload,
      }
    case CMAppConfigActions.AdmGetCurrenciesFail:
    case CMAppConfigActions.AdmAddCurrencyFail:
    case CMAppConfigActions.AdmUpdateCurrencyFail:
    case CMAppConfigActions.AdmDeleteCurrencyFail:
    case CMAppConfigActions.AdmSetCurrencyAsBaseFail:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      }

    // sort
    case CMAppConfigActions.AdmSortCurrencies:
      return {
        ...state,
        ...payload,
        isLoading: true,
      }
    default:
      return state
  }
}
