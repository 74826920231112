import makeStyles from '@material-ui/core/styles/makeStyles'

const color = '#25ADBF'
const redColor = '#FF7362'
const lightRedColor = '#FDEFED'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  collapseButton: {
    padding: spacing(0.25),
  },
  column: {
    height: spacing(5),
    padding: 0,
  },
  collapsibleColumn: {
    textAlign: 'center',
  },
  arrowIcon: {
    color: palette.grey[200],
    transition: 'transform 0.1s',
  },
  checked: {
    color: `${color} !important`,
  },
  collapsed: {
    transform: 'rotate(90deg)',
  },
  colorize: {
    '&:nth-child(even)': {
      backgroundColor: '#F9F9F9',
    },
  },
  clickableRow: {
    cursor: 'pointer',
    transition: 'all 0.1s',
  },
  changedRow: {
    boxShadow: `inset 4px 0px 0px 0px #FEDA59`,
    backgroundColor: '#FFFCEF !important',
  },
  deletedRow: {
    boxShadow: `inset 4px 0px 0px 0px ${redColor}`,
    backgroundColor: `${lightRedColor} !important`,
  },
  selectedRow: {
    boxShadow: `inset 4px 0px 0px 0px ${color}`,
    backgroundColor: '#EAF7F9 !important',
  },
}))
