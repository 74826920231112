import { FormikValues } from 'formik'

import { AdmAppSetting } from '@/api/cm/swaggerGeneratedApi'
import { DynamicFieldType } from '@/constants/cm/dynamicFieldTypes'

export const validate = (fields: AdmAppSetting[]) => (
  values: FormikValues,
): Record<string, string> => {
  const errors: Record<string, string> = {}

  fields.forEach((field: AdmAppSetting) => {
    const parsedFieldKey = field.id ? `${field.id}` : ''

    if (field.valueType === DynamicFieldType.Integer) {
      if (Number(values[parsedFieldKey]) > Number(field.maxValue)) {
        errors[parsedFieldKey] = 'MustBeMoreThan'
      }

      if (Number(values[parsedFieldKey]) < Number(field.minValue)) {
        errors[parsedFieldKey] = 'MustBeLessThan'
      }
    }
  })

  return errors
}
