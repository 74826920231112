export enum DeviceSettings {
  GetDeviceDefaultSettingsRequest = '@/SETTINGS/GET_DEFAULT_SETTINGS_REQUEST',
  GetDeviceDefaultSettingsResponse = '@/SETTINGS/GET_DEFAULT_SETTINGS_RESPONSE',
  GetDeviceDefaultSettingsResponseFail = '@/SETTINGS/GET_DEFAULT_SETTINGS_RESPONSE_FAIL',
}

export enum CentralSettings {
  StartFetchingDefaultCentralSettings = '@/CENTRAL_SETTINGS/START_LOADING_DEFAULT_CENTRAL_SETTINGS',
  StopFetchingDefaultCentralSettings = '@/CENTRAL_SETTINGS/STOP_LOADING_DEFAULT_CENTRAL_SETTINGS',
  getDefaultCentralSettingsAsync = '@/CENTRAL_SETTINGS/GET_DEFAULT_CENTRAL_SETTINGS_ASYNC',
  setDefaultCentralSettingsSuccess = '@/CENTRAL_SETTINGS/SET_DEFAULT_CENTRAL_SETTINGS_SUCCESS',
  setDefaultCentralSettingsFall = '@/CENTRAL_SETTINGS/SET_DEFAULT_CENTRAL_SETTINGS_FALL',
  setDefaultState = '@/CENTRAL_SETTINGS/SET_DEFAULT_CENTRAL_SETTINGS_STATE',
}

export enum Notifications {
  EnqueueSnackbar = '@/NOTIFICATIONS/ENQUEUE_SNACKBAR',
  RemoveSnackbar = '@/NOTIFICATIONS/REMOVE_SNACKBAR',
  RemoveAllNotifications = '@/NOTIFICATIONS/REMOVE_ALL_NOTIFICATIONS',

  deleteAlarms = '@/NOTIFICATIONS/DELETE_ALARMS',
  deleteEvents = '@/NOTIFICATIONS/DELETE_EVENTS',
  ClearNotifications = '@/NOTIFICATIONS/CLEAR_NOTIFICATIONS',

  closeSecurityAlarmAsync = '@/NOTIFICATIONS/CLOSE_SECURITY_ALARM',
  blockAlarms = '@/NOTIFICATIONS/BLOCK_ALARMS',
  unblockAlarms = '@/NOTIFICATIONS/UNBLOCK_ALARMS',

  closeTechnicalEventAsync = '@/NOTIFICATIONS/CLOSE_TECHNICAL_EVENT',
  blockEvents = '@/NOTIFICATIONS/BLOCK_EVENT',
  unblockEvents = '@/NOTIFICATIONS/UNBLOCK_EVENT',
  setATMeyeNotification = '@/NOTIFICATIONS/SE_ATMYEY_NOTIFICATION',

  changeSettings = '@/NOTIFICATIONS/CHANGE_SETTINGS',
  changeAllSettings = '@/NOTIFICATIONS/CHANGE_ALL_SETTINGS',

  DecrementAlarmsEventCount = '@/NOTIFICATIONS/DECREMENT_ALARMS_COUNT',
  FillAlarmsEventCount = '@/NOTIFICATIONS/FILL_ALARMS_COUNT',
  FillInitialSettings = '@/NOTIFICATIONS/FILL_INITIAL_SETTINGS',
}
