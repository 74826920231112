import { combineReducers } from 'redux'

import * as common from './common/reducer'
import * as networks from './networks/reducer'
import * as objects from './objects/reducer'
import * as userNetworks from './userNetworks/reducer'

export interface State {
  common: common.State
  objects: objects.State
  networks: networks.State
  userNetworks: userNetworks.State
}

export const reducer = combineReducers({
  common: common.reducer,
  objects: objects.reducer,
  networks: networks.reducer,
  userNetworks: userNetworks.reducer,
})
