import { createMultipleSelectField } from '@/components/controls/MultipleSelect/component'

interface Args {
  label: string
  placeholder: string
  disabled?: boolean
  options: {
    name: string
    value: string
  }[]
  required?: boolean
  className?: string
  parameterName: string
}

export const MultipleSelect = ({
  label,
  placeholder,
  disabled,
  options,
  required,
  className,
  parameterName,
}: Args) =>
  createMultipleSelectField({
    disabled,
    required,
    fullWidth: true,
    label,
    shrink: true,
    parameterName,
    className,
    placeholder,
    options,
  })
