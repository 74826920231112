import { makeStyles } from '@material-ui/core/styles'

import { styledComponentTheme } from '@/theme'
import colors from '@/theme/colors'

export const useClasses = makeStyles(() => ({
  tableWrapper: {
    height: '100%',
    overflow: 'auto',
  },
  tableContainer: {
    overflowX: 'auto',
    height: '100%',
  },

  noDataBanner: {
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%)',
    color: colors.grey300,
    fontSize: styledComponentTheme.fontSizes.medium,
    textAlign: 'center',
    backgroundColor: 'transparent',
    position: 'absolute',
  },
  shadow: {
    boxShadow: `0px 2px 1px -2px rgba(0,0,0,0.2),
       0px 1px 1px -1px rgba(0,0,0,0.14), 
       0px 1px 3px 0px rgba(0,0,0,0.12)`,
  },
}))
