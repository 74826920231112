import makeStyles from '@material-ui/core/styles/makeStyles'
import { styledComponentTheme } from '@/theme'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  radio: {
    color: palette.text.primary,
  },
  root: {
    color: palette.grey[100],
  },
  label: {
    fontSize: styledComponentTheme.fontSizes.small,
  },
}))
