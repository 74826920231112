import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'
import { TypeOfTickets } from '@/types'

import * as fromTickets from './reducer'

export const getTicketsState = (state: AppState): fromTickets.State => state.tickets

export const getTicketsData = createSelector(getTicketsState, state => {
  if (state.typeOfTickets) {
    switch (state.data[state.typeOfTickets as TypeOfTickets].details) {
      case undefined:
        return undefined
      case null:
        return []
      default:
        return state.data[state.typeOfTickets as TypeOfTickets].details?.items
    }
  }
})

export const getCurrentFilterTemplate = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.filter[state.typeOfTickets as TypeOfTickets].selectedTemplate
    : fromTickets.initialState.filter.allTickets.selectedTemplate,
)

export const getFilterTemplateFields = createSelector(
  getTicketsState,
  state => state.filterTemplateFields,
)

export const getGroupDetailsData = createSelector(getTicketsState, state => {
  if (state.typeOfTickets && state.groupDetails) {
    const page = state.page[state.typeOfTickets as TypeOfTickets] || 1
    const rowsPerPage = Number(state.pageSizeForGroupingTickets) || 10

    return state.groupDetails.slice(0, page * rowsPerPage)
  }
})

export const getGroupDetailsCount = createSelector(getTicketsState, state => {
  let result = 0
  if (state.typeOfTickets && state.groupDetails) {
    const countChildren = (obj: any): number => {
      return obj.childItems ? obj.childItems.length : 0
    }
    result =
      state.groupDetails?.length + state.groupDetails.reduce((acc, c) => acc + countChildren(c), 0)
  }
  return result
})

export const getGroupedTicketsData = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.data[state.typeOfTickets as TypeOfTickets].groups
    : fromTickets.initialState.data.allTickets.groups,
)

export const isFetchingGroupDetails = createSelector(
  getTicketsState,
  state => state.isFetchingGroupDetails,
)

export const getTicketsTotalCount = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.data[state.typeOfTickets as TypeOfTickets].totalCount
    : fromTickets.initialState.data.allTickets.totalCount,
)

export const getTicketsPageCount = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.data[state.typeOfTickets as TypeOfTickets].details?.pageCount
    : fromTickets.initialState.data.allTickets.details?.pageCount,
)

export const getTicketsRowsPerPage = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.rowsPerPage[state.typeOfTickets as TypeOfTickets]
    : fromTickets.initialState.rowsPerPage.allTickets,
)

export const getGroupedTicketsRowsPerPage = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.pageSizeForGroupingTickets
    : fromTickets.initialState.pageSizeForGroupingTickets,
)

export const getTicketsPage = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.page[state.typeOfTickets as TypeOfTickets]
    : fromTickets.initialState.page.allTickets,
)

export const getPredefinedFilters = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.filter[state.typeOfTickets as TypeOfTickets].predefinedFilters
    : fromTickets.initialState.filter.allTickets.predefinedFilters,
)

export const getSelectedFilters = createSelector(getTicketsState, state => {
  return state.typeOfTickets
    ? state.filter[state.typeOfTickets as TypeOfTickets].selectedFilters
    : fromTickets.initialState.filter.allTickets.selectedFilters
})

export const isFiltersSelected = createSelector(getTicketsState, state => {
  return !Object.values(state.filter[state.typeOfTickets as TypeOfTickets].selectedFilters).every(
    (filter: string | null) => filter === null || filter === '',
  )
})

export const getFiltersTemplates = createSelector(getTicketsState, state =>
  state && state.typeOfTickets
    ? state.filter[state.typeOfTickets as TypeOfTickets].filterTemplates
    : fromTickets.initialState.filter.allTickets.filterTemplates,
)

export const isFetchingFilterTemplates = createSelector(
  getTicketsState,
  state => state.isFetchingFilterTemplates,
)

export const isFetchingTickets = createSelector(getTicketsState, state => state.isFetchingTickets)

export const getGroupingTemplates = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.grouping[state.typeOfTickets as TypeOfTickets].groupingsTemplates
    : fromTickets.initialState.grouping.allTickets.groupingsTemplates,
)

export const getGrouping = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.grouping[state.typeOfTickets as TypeOfTickets].selectedGroupings
    : fromTickets.initialState.grouping.allTickets.selectedGroupings,
)

export const isFetchingGroupTemplates = createSelector(
  getTicketsState,
  state => state.isFetchingGroupTemplates,
)

export const getTicketsConfigData = createSelector(
  getTicketsState,
  state => state.config[state.typeOfTickets],
)

export const getIsFetchingTicketsConfig = createSelector(
  getTicketsState,
  state => state.isFetchingTicketsConfig,
)

export const isFetchingTicketDetails = createSelector(
  getTicketsState,
  state => state.isFetchingTicketDetails,
)

export const getTicketDetails = createSelector(getTicketsState, state => state.ticketDetails)

export const getTicketId = createSelector(getTicketsState, state => state.ticketDetails)

export const getTicketSorting = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.sorting[state.typeOfTickets as TypeOfTickets]
    : fromTickets.initialState.sorting.allTickets,
)

export const getStringFilter = createSelector(getTicketsState, state =>
  state.typeOfTickets
    ? state.stringFitler[state.typeOfTickets as TypeOfTickets]
    : fromTickets.initialState.stringFitler.allTickets,
)

export const hasGroupedTickets = createSelector(getTicketsState, state =>
  state.typeOfTickets ? state.data[state.typeOfTickets as TypeOfTickets].hasGroups : false,
)
