import moment from 'moment'

export type TableFilterDate = { dateFrom: string; dateTo: string }

export const setMonDetailsInitialDate = (): TableFilterDate => {
  return {
    dateFrom: moment()
      .subtract(1, 'month')
      .startOf('day')
      .format(),
    dateTo: moment().format(),
  }
}
