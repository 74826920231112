import clsx from 'clsx'
import React, { FC, useMemo } from 'react'

import { useTableWithBorder } from '@/components/blocks/cm/CMTable/hooks'

import { CMTableCell, useCommonClasses } from '../../../commonStyles'
import { Props } from './types'

export const TableCell: FC<Props> = React.memo((props: Props) => {
  const {
    isFixed,
    isFixedLast,
    isSelected,
    cellClasses,
    isTwiceCell,
    isNumber,
    isRightSpace,
    children,
    className,
    isCellNonClickable,
    onClick,
    ...rest
  } = props

  const commonClasses = useCommonClasses()

  const isBorder = useTableWithBorder()

  const localClassName = useMemo(
    () =>
      clsx(cellClasses, {
        [commonClasses.fixed]: isFixed,
        [commonClasses.fixedLast]: isFixedLast,
        [commonClasses.rightSpace]: isRightSpace && !isBorder,
        [commonClasses.rightAlign]: isNumber,
        [commonClasses.selectedCell]: isSelected,
        [commonClasses.clickableCell]: !!onClick,
        [commonClasses.isCellNonClickable]: isCellNonClickable,
      }),
    [
      commonClasses,
      cellClasses,
      isFixed,
      isFixedLast,
      isSelected,
      isRightSpace,
      isNumber,
      isBorder,
      onClick,
    ],
  )

  return !isTwiceCell ? (
    <CMTableCell {...rest} className={clsx(localClassName, className)} onClick={onClick}>
      {children}
    </CMTableCell>
  ) : (
    <>{children}</>
  )
})
