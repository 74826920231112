import { Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Test from 'react-test-attributes'

import { GetTicketsBodyFilterItem } from '@/api/sd/ticketsApi/types'
import CloseButton from '@/components/blocks/CloseButton/index'
import { TextInput } from '@/components/controls/TextInput/component'
import { detectTicketTabLocation } from '@/components/pages/sd/helpers/sdHelpers'
import { useDebounceInput } from '@/components/pages/sd/TicketsPage/helpers/hooks/useDebounceInput'
import { gettingTickets } from '@/helpers/tickets'
import {
  createSdFilterTemplateRequest,
  getFiltersTemplates,
  getFilterTemplateFields,
} from '@/store/sd/tickets/index'
import { getTicketsConfigData } from '@/store/sd/tickets/selectors'
import { getTestId } from '@/utils'
import { useTranslate } from '@/utils/internalization'
import { PopUpService } from '@/utils/services/popUpService'

import { Props } from './types'

const SaveFilterModal = ({
  classes,
  currentFilters,
  testIdPrefix,
  handleClose,
  setSavedTemplate,
}: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const templates = useSelector(getFiltersTemplates)
  const { filter } = useSelector(getTicketsConfigData)
  const filterFields = useSelector(getFilterTemplateFields)
  const translate = useTranslate()
  const location = useLocation()
  const { inputValue, inputErrors, handleChangeInputValue } = useDebounceInput('')
  const idPrefix = `${testIdPrefix}-save-filter-modal`
  const testId = getTestId(idPrefix)

  const handleSaveTemplate = useCallback((): void => {
    if (templates.find((temp: any) => temp.name === inputValue)) {
      PopUpService.showGlobalPopUp(`translate#title.sameTemplateName`, 'warning')
    } else if (currentFilters) {
      let filtersBody = gettingTickets.getFilters(currentFilters, filter)
      // TODO: QAP-1117
      // 1. duplicate this filtersBody.map in editTemplate function
      // 2. parse filter fields on template selection
      filtersBody = filtersBody.map((f: GetTicketsBodyFilterItem) => {
        if (filterFields[f.parameterName] && f.parameterValue) {
          f.parameterValue[0].name = filterFields[f.parameterName].displayName
          return f
        }
        return f
      })

      dispatch(
        createSdFilterTemplateRequest({
          filters: filtersBody,
          name: inputValue,
          windowType: detectTicketTabLocation(location),
          translate,
        }),
      )

      setSavedTemplate(inputValue)
      handleClose()
    }
  }, [dispatch, handleClose, currentFilters, inputValue])

  return (
    <>
      <Test id={testId(0)}>
        <CloseButton absolute className={classes.closeButton} onClick={handleClose} />
      </Test>
      <Test id={testId(1)}>
        <Typography className={classes.subtitle}>
          <FormattedMessage
            id="title.saveCustomFilterTemplate"
            defaultMessage="Save custom template"
          />
        </Typography>
      </Test>
      <Test id={testId(2)}>
        <TextInput
          value={inputValue}
          className={classes.input}
          onChange={handleChangeInputValue}
          autoFocus
          label={translate('translate#title.enterTemplateName')}
        />
      </Test>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
          <Test id={testId(3)}>
            <Button
              onClick={handleSaveTemplate}
              className={classes.button}
              variant="contained"
              disabled={!!inputErrors || !inputValue.length}
            >
              <FormattedMessage id="action.save" defaultMessage="Save" />
            </Button>
          </Test>
        </Grid>
      </Grid>
    </>
  )
}

export default SaveFilterModal
