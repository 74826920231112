import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromCompaniesCompanies from './reducer'

export const getCompaniesCompaniesState = (state: AppState): fromCompaniesCompanies.State =>
  state.companiesCompanies

export const getGlobalErrorCompanies = createSelector(
  getCompaniesCompaniesState,
  state => state.error,
)
export const getCompany = createSelector(getCompaniesCompaniesState, state => {
  return state.currentCompany
})

export const getDepartment = createSelector(getCompaniesCompaniesState, state => {
  return state.currentDepartment
})

export const getPersonnel = createSelector(getCompaniesCompaniesState, state => {
  return state.currentPersonnel
})

export const getSelectedCompany = createSelector(getCompaniesCompaniesState, state => {
  return state.selectedCompany
})

export const getSelectedDepartment = createSelector(getCompaniesCompaniesState, state => {
  return state.selectedDepartment
})

export const getSelectedPersonnel = createSelector(getCompaniesCompaniesState, state => {
  return state.selectedPersonnel
})

export const getRoles = createSelector(getCompaniesCompaniesState, state => {
  return state.roles
})

export const getWorkAreas = createSelector(getCompaniesCompaniesState, state => {
  return state.workAreas
})

export const getQualificationLevelsLookup = createSelector(getCompaniesCompaniesState, state => {
  return state.qualificationLevelsLookup
})

export const getSalaries = createSelector(getCompaniesCompaniesState, state => {
  return state.salaries
})

export const getPersonnelAddress = createSelector(getCompaniesCompaniesState, state => {
  return state.personnelAddress
})

export const getPersonnelRolesHistory = createSelector(getCompaniesCompaniesState, state => {
  return state.personnelRolesHistory
})

export const getPersonnelQualificationLevels = createSelector(getCompaniesCompaniesState, state => {
  return state.personnelQualificationLevels
})

export const getIsFetchingCompanies = createSelector(
  getCompaniesCompaniesState,
  state => state.isFetchingCompanies,
)

export const getCompaniesTree = createSelector(getCompaniesCompaniesState, state => {
  return state.companyTree
})

// export const getIsDeletingCompany = createSelector(
//   getCompaniesCompaniesState,
//   state => state.isDeletingCompany,
// )

// export const getIsCompanyDeletedSuccessful = createSelector(
//   getCompaniesCompaniesState,
//   state => state.isCompanyHasDeletedSuccessful,
// )

export const getDeletedTreeItemTimestamp = createSelector(
  getCompaniesCompaniesState,
  state => state.deletedTreeItemTimestamp,
)

export const getNewPersonnelId = createSelector(
  getCompaniesCompaniesState,
  state => state.newPersonnelId,
)

export const getNewCompanyId = createSelector(
  getCompaniesCompaniesState,
  state => state.newCompanyId,
)

export const getIsAddingToSecurityNetworks = createSelector(getCompaniesCompaniesState, state => {
  return state.isAddingToSecurityNetworks
})

export const getIsAddedToSecurityNetworks = createSelector(getCompaniesCompaniesState, state => {
  return state.isAddedToSecurityNetworks
})
