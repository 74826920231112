import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  dialog: {
    height: 193,
    width: 775,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkmark: {
    marginBottom: spacing(1),
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'block',
    strokeWidth: 2,
    stroke: '#00C562',
    strokeMiterlimit: 10,
    margin: '10% auto',
    boxShadow: 'inset 0px 0px 0px #7ac142',
    animation: '$fill .4s ease-in-out .4s forwards, $scale .3s ease-in-out .9s both',
  },
  checkmarkCircle: {
    strokeDasharray: 166,
    strokeDashoffset: 166,
    strokeWidth: 2,
    strokeMiterlimit: 10,
    stroke: '#00C562',
    fill: 'none',
    animation: '$stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards',
  },
  checkmarkCheck: {
    transformOrigin: '50% 50%',
    strokeDasharray: 48,
    strokeDashoffset: 48,
    animation: '$stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards',
  },
  '@keyframes stroke': {
    '100%': {
      strokeDashoffset: 0,
    },
  },
  '@keyframes scale': {
    '0%, 100%': {
      transform: 'none',
    },
    '50%': {
      transform: 'scale3d(1.1, 1.1, 1)',
    },
  },
  '@keyframes fill': {
    '100%': {
      boxShadow: 'inset 0px 0px 0px 30px #ffffff',
    },
  },
}))
