import { Button, ButtonProps, IconButton, IconButtonProps } from '@material-ui/core'
import { Settings, Tune } from '@material-ui/icons/'
import clsx from 'clsx'
import React from 'react'

import PrintIcon from '@/assets/cashManagement/icons/print_icon.svg'
import RefreshIcon from '@/assets/cashManagement/icons/refresh_icon.svg'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'

export const FilterButton: React.FC<ButtonProps> = React.memo((props: ButtonProps) => {
  const { className, ...restProps } = props

  const classes = useClasses()
  const translate = useTranslate()

  return (
    <Button
      className={clsx(classes.button, className)}
      variant="outlined"
      startIcon={<Tune />}
      {...restProps}
    >
      {translate('translate#cm.Filter')}
    </Button>
  )
})

export const SettingsButton: React.FC<ButtonProps> = React.memo((props: ButtonProps) => {
  const { className, ...restProps } = props

  const classes = useClasses()
  const translate = useTranslate()

  return (
    <Button
      className={clsx(classes.button, className)}
      variant="outlined"
      startIcon={<Settings />}
      {...restProps}
    >
      {translate('translate#cm.Settings')}
    </Button>
  )
})

export const RefreshButton: React.FC<IconButtonProps> = React.memo((props: IconButtonProps) => {
  const { className, ...restProps } = props

  const classes = useClasses()

  return (
    <IconButton className={clsx(classes.iconButton, className)} {...restProps}>
      <img src={RefreshIcon} />
    </IconButton>
  )
})

export const PrintButton: React.FC<IconButtonProps> = React.memo((props: IconButtonProps) => {
  const { className, ...restProps } = props

  const classes = useClasses()

  return (
    <IconButton className={clsx(classes.iconButton, className)} {...restProps}>
      <img src={PrintIcon} />
    </IconButton>
  )
})
