import { createSelector } from 'reselect'

import { AdvancedSearchFields } from '@/constants/forms/AdvancedSearchForm'
import { AppState } from '@/store/reducers'

import * as fromCompaniesDrawerActions from '../reducer'
import { initialState } from '../reducer'

export const getDrawerActionsState = (state: AppState): fromCompaniesDrawerActions.State => {
  return state.companiesDrawerActions || initialState
}

export const getServiceCompanies = createSelector(getDrawerActionsState, state => {
  return state.serviceCompanies.map(company => ({ name: company.name, value: `${company.id}` }))
})

export const getCompanyTypes = createSelector(getDrawerActionsState, state => {
  return state.companyTypes.map(company => ({ name: company.name, value: `${company.id}` }))
})

export const getUnits = createSelector(getDrawerActionsState, state => {
  return state.units.map(({ id, name, fullAddress }) => ({
    value: `${id}`,
    name,
    address: fullAddress,
  }))
})

export const isLookingForUnits = createSelector(
  getDrawerActionsState,
  state => state.isLookingForUnits,
)

export const isCreatingTicket = createSelector(getDrawerActionsState, state => state.isAddingTicket)

export const getMappedUnitsAdvanced = createSelector(getDrawerActionsState, state =>
  state.unitsAdvanced.map(
    ({
      productName,
      serviceRegion,
      deviceState,
      deviceType,
      terminalUid,
      fullAddress,
      luno,
      merchant,
      renterCompany,
    }) => ({
      [AdvancedSearchFields.DeviceType]: deviceType,
      [AdvancedSearchFields.TerminalId]: terminalUid,
      [AdvancedSearchFields.LUNO]: luno,
      [AdvancedSearchFields.Model]: productName,
      [AdvancedSearchFields.Address]: fullAddress,
      [AdvancedSearchFields.Merchant]: merchant,
      [AdvancedSearchFields.Renter]: renterCompany,
      [AdvancedSearchFields.ServiceRegion]: serviceRegion,
      [AdvancedSearchFields.DeviceState]: deviceState,
    }),
  ),
)

export const getUnitsAdvanced = createSelector(getDrawerActionsState, state => state.unitsAdvanced)

export const getUnitsAdvancedSearchFormParameters = createSelector(
  getDrawerActionsState,
  state => state.unitAdvancedSearchFormConfig,
)

export const getIsLookingUnitsAdvancedSearchConfig = createSelector(
  getDrawerActionsState,
  state => state.isLookingForUnitsAdvancedSearchConfig,
)

export const isShoeEmptyAdvancedSearchResults = createSelector(
  getDrawerActionsState,
  state => state.emptyAdvancedSearchShowMessage,
)
