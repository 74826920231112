import {
  AppNavigationRoutes,
  CASH_MANAGEMENT_BLOCK_PREFIX,
  VAULT_V1_BLOCK_PREFIX,
  VAULT_BLOCK_PREFIX,
} from '@/constants/paths'
import { store } from '@/App'
import { REACT_APP_CM_STANDALONE, REACT_APP_VAULT_V1_STANDALONE } from '@/constants/moduleMode'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { getComIntegrationsRequest } from '@/store/cm/common'
import { checkIsLoginVaultV1 } from '@/store/vault-v1/auth'
import { checkIsLogin } from '@/store/auth/actions'
import { checkIsLoginCM } from '@/store/cm/auth'

const moduleCheckIsLogin = ({ pathname }: { pathname: string }): (() => void) => {
  const isCM = pathname.includes(CASH_MANAGEMENT_BLOCK_PREFIX) && REACT_APP_CM_STANDALONE === 'true'
  const isVaultV1 =
    pathname === VAULT_V1_BLOCK_PREFIX ||
    (pathname.includes(`${VAULT_V1_BLOCK_PREFIX}/`) && REACT_APP_VAULT_V1_STANDALONE === 'true')

  switch (true) {
    case isCM:
      return (): void => {
        store.dispatch(checkIsLoginCM())
        store.dispatch(getComIntegrationsRequest())
      }

    case isVaultV1:
      return (): void => {
        store.dispatch(checkIsLoginVaultV1())
      }

    default:
      return (): void => {
        store.dispatch(checkIsLogin())
      }
  }
}

const conditionForCheckIsLogin = ({ pathname }: { pathname: string }): boolean => {
  const hasToken =
    sessionStorage.getItem(LocalStorageItems.AccessToken) ||
    localStorage.getItem(LocalStorageItems.AccessTokenCM) ||
    localStorage.getItem(LocalStorageItems.AccessTokenVaultV1)

  const isSignInPage = pathname === AppNavigationRoutes.SignInPage
  const isChangePasswordPage = pathname === AppNavigationRoutes.ChangePassword
  const isVault = pathname === VAULT_BLOCK_PREFIX || pathname.includes(`${VAULT_BLOCK_PREFIX}/`)

  return !(isSignInPage || isChangePasswordPage) && !!hasToken && !isVault
}

export { moduleCheckIsLogin, conditionForCheckIsLogin }
