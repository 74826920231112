import {
  ShrCasseteExtProp,
  ShrModCassetes,
  ShrPriorityRequest,
  ShrDeclineNote,
  ShrSettingsResponse,
} from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants/actions'
import { AnyAction } from 'redux'

export const getShrCassettesPropsRequest = (guid: string): AnyAction => ({
  type: CMActions.ShrGetCassettesPropsRequest,
  payload: guid,
})

export const getShrCassettesPropsResponse = (payload: {
  data: ShrCasseteExtProp[]
  editInOrdersModuleEnabled: boolean
}): AnyAction => ({
  type: CMActions.ShrGetCassettesPropsResponse,
  payload,
})

export const getShrCassettesPropsResponseFail = (error: string): AnyAction => ({
  type: CMActions.ShrGetCassettesPropsResponseFail,
  payload: error,
})

export const setShrCassettesPropsRequest = (
  guid: string,
  requestBody: ShrModCassetes,
): AnyAction => ({
  type: CMActions.ShrSetCassettesPropsRequest,
  payload: { guid, requestBody },
})

export const setShrCassettesPropsResponse = (payload: {
  data: ShrCasseteExtProp[]
  editInOrdersModuleEnabled: boolean
}): AnyAction => ({
  type: CMActions.ShrSetCassettesPropsResponse,
  payload,
})

export const setShrCassettesPropsResponseFail = (error: string): AnyAction => ({
  type: CMActions.ShrSetCassettesPropsResponseFail,
  payload: error,
})

export const setShrEditCassettesPropsModalOpen = (isOpen: boolean): AnyAction => ({
  type: CMActions.ShrSetEditCassettesPropsModalOpen,
  payload: isOpen,
})

export const setShrCashPointPriority = (priority: ShrPriorityRequest): AnyAction => ({
  type: CMActions.ShrSetCashPointPriority,
  payload: priority,
})

export const getShrSettingsRequest = (): AnyAction => ({
  type: CMActions.ShrGetSettingsRequest,
})

export const getShrSettingsResponse = (payload: ShrSettingsResponse): AnyAction => ({
  type: CMActions.ShrGetSettingsResponse,
  payload,
})

export const getShrSettingsResponseFail = (error: string): AnyAction => ({
  type: CMActions.ShrGetSettingsResponseFail,
  payload: error,
})

export const setShrDeclineNoteState = (state: {
  isModalOpen?: boolean
  isLoading?: boolean
  error?: string | null
  data?: ShrDeclineNote | null
}): AnyAction => ({
  type: CMActions.ShrSetDeclineNoteState,
  payload: state,
})
