export const initialConfig = {
  formUID: '',
  getConfigURL: '',
  getOutTreeURL: '',
  getOutDetailsURL: '',
  filter: [],
  groups: [],
  outputFields: [],
}

export const initialFilter = {
  predefinedFilters: {
    availableSortingFields: [],
    deviceTypeItems: [],
    receiveDateItems: {
      end: null,
      start: null,
    },
    statusItems: [],
    serviceTypeItems: [],
  },
  selectedTemplate: '',
  selectedFilters: {},
  filterTemplates: [],
}

export const initialGrouping = {
  predefinedGroupings: {
    items: [],
  },
  selectedGroupings: [],
  groupingsTemplates: [],
}
