export type CurrentDeviceTypes = {
  deviceId: number
  deviceName: string

  latitude?: string
  longitude?: string
}

export enum TABS {
  DEVICES = 0,
  MAP = 1,
}
