import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getState = (state: AppState): fromReducer.State => {
  return state.cmAdmin?.appConfig.uploadLogo
}

export const getLogo = createSelector(getState, state => state?.logo)
export const getApprovingLogo = createSelector(getState, state => state.approvingLogo)
