import { AnyAction } from 'redux'
import { WindowsEditActions } from '@/constants/actions'

export interface State {
  windowPropertiesIsOpen: boolean
  toggleMoveSplitPaneable: boolean
  splitPaneHeightPosition: number
  splitPaneWidthPosition: number
  drawerInOpen: boolean
  contentWrapperWidth: number
}

export const initialState: State = {
  windowPropertiesIsOpen: true,
  toggleMoveSplitPaneable: false,
  splitPaneHeightPosition: 230,
  splitPaneWidthPosition: window.innerWidth / 3,
  drawerInOpen: false,
  contentWrapperWidth: 0,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case WindowsEditActions.ToggleMoveSplitPaneable:
      return { ...state, toggleMoveSplitPaneable: payload }
    case WindowsEditActions.SetSplitPaneHeightPosition:
      return { ...state, splitPaneHeightPosition: payload }
    case WindowsEditActions.SetSplitPaneWidhtPosition:
      return { ...state, splitPaneWidthPosition: payload }
    case WindowsEditActions.OpenPropertiesWindow:
      return { ...state, windowPropertiesIsOpen: true }
    case WindowsEditActions.ClosePropertiesWindow:
      return { ...state, windowPropertiesIsOpen: false }
    case WindowsEditActions.ToggleDrawer:
      return { ...state, drawerInOpen: !state.drawerInOpen }
    case WindowsEditActions.SetContentWrapperWidth:
      return { ...state, contentWrapperWidth: payload }
    default:
      return state
  }
}
