import { GroupNodes } from '@/components/NPMPakage/components/ObjectsTree/types'
import { ResponseError } from '@/types'
import { requestService } from '@/utils/services/request'

import { TableListReponse } from '../commonTypes'
import { GetTransactionsGroupsRequest, TransactionEvent, TransactionsFilter } from './types'

export class TransactionsApi {
  public static async getTransactionsList({
    sort,
    size,
    page,
    ...payload
  }: {
    filter: TransactionsFilter
    groupingFilters?: TransactionsFilter[]

    size: number
    page: number
    sort: string
  }): Promise<TableListReponse> {
    try {
      return await requestService.post(
        `/atmeye/api/v1/events/transaction/list?sort=${sort}&size=${size}&page=${page - 1}`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getTransactionEvents({
    sort,
    deviceId,
    processNumber,
    filter,
    ...payload
  }: any): Promise<TransactionEvent[]> {
    try {
      return await requestService.post(
        `/atmeye/api/v1/events/transaction/${deviceId}/${processNumber}/events?sort=${sort}`,
        filter,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getTransactionsGroups(
    payload: GetTransactionsGroupsRequest,
  ): Promise<{ groupNodes: GroupNodes }> {
    try {
      return await requestService.post(`/atmeye/api/v1/events/transaction/groups`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getExportData(
    {
      sort,
      ...payload
    }: {
      filter: TransactionsFilter
      config: {
        format: string
        appliedFilters: {
          filterLabel: string
          filterValue: string
        }[]
        selectedOutputFields: any
      }
      sort?: string
    },
    buffer?: boolean,
  ): Promise<any | ResponseError> {
    try {
      return await requestService.post(
        `/atmeye/api/v1/events/transaction/ungrouped-export?sort=${sort}`,
        payload,
        true,
        buffer && { responseType: 'arraybuffer' },
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getExportGroupedData(
    {
      sort,
      ...payload
    }: {
      exportedGroupsData: {
        groupsInfo: any
        nodeFilter: any
      }
      config: {
        format: string
        appliedFilters: {
          filterLabel: string
          filterValue: string
        }[]
        selectedOutputFields: any
      }
      sort?: string
    },
    buffer?: boolean,
  ): Promise<any | ResponseError> {
    try {
      return await requestService.post(
        `/atmeye/api/v1/events/transaction/grouped-export?sort=${sort}`,
        payload,
        true,
        buffer && { responseType: 'arraybuffer' },
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async updateTransactionNote({
    eventId,
    value,
  }: {
    eventId: string
    value: string
  }): Promise<void | ResponseError> {
    try {
      return await requestService.patch(
        `/atmeye/api/v1/events/transaction/note?eventId=${eventId}&value=${value}`,
        {},
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async createTransactionReport({
    deviceId,
    processNumber,
    transactionId,
  }: {
    deviceId: string
    processNumber: string
    transactionId: string
  }): Promise<any | ResponseError> {
    try {
      return await requestService.post(
        `/events/${deviceId}/${processNumber}/transaction-report?transactionId=${transactionId}`,
        {},
      )
    } catch (e) {
      throw new Error(e)
    }
  }
}
