import { AnyAction } from 'redux'

import { AdmCurrency } from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants/actions'

export type State = {
  isShowUsedOnly: boolean

  data: AdmCurrency[]
  total: number
  isLoading: boolean
  isLoaded: boolean
  error: string | null

  sortColumn?: string
  sortOrder?: 'asc' | 'desc'

  selected: AdmCurrency | null
}

export const initialState: State = {
  isShowUsedOnly: false,

  data: [],
  total: 0,
  isLoading: false,
  isLoaded: false,
  error: null,

  sortColumn: undefined,
  sortOrder: undefined,

  selected: null,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case VaultV1Actions.AdmGetCurrenciesRequest:
      return {
        ...state,
        isLoading: true,
      }
    case VaultV1Actions.AdmGetCurrenciesResponse:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: payload || [],
        selected: !state.selected ? payload[0] || null : state.selected,
        total: (payload || []).length || 0,
      }

    case VaultV1Actions.AdmGetCurrenciesFail:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: payload,
      }

    case VaultV1Actions.AdmAddCurrencyRequest:
    case VaultV1Actions.AdmUpdateCurrencyRequest:
    case VaultV1Actions.AdmDeleteCurrencyRequest:
    case VaultV1Actions.AdmSetCurrencyBaseRequest:
      return {
        ...state,
        isLoading: true,
      }

    case VaultV1Actions.AdmAddCurrencyFail:
    case VaultV1Actions.AdmUpdateCurrencyFail:
    case VaultV1Actions.AdmDeleteCurrencyFail:
    case VaultV1Actions.AdmSetCurrencyBaseFail:
      return {
        ...state,
        isLoading: false,
      }

    case VaultV1Actions.AdmSetSelectedCurrency:
      return {
        ...state,
        selected: payload || null,
      }

    case VaultV1Actions.AdmSortCurrencies:
      return {
        ...state,
        ...payload,
      }

    case VaultV1Actions.AdmSetCurrenciesOnlyBase:
      return {
        ...state,
        isShowUsedOnly: payload,
      }

    case VaultV1Actions.SignOut:
      return initialState
    default:
      return state
  }
}
