import { FormikValues } from 'formik'

import { TableGroup } from '@/components/pages/sd/TicketsPage/components/GroupingModal/types'

export type TypeOfTickets = 'allTickets' | 'myTickets' | 'historyTickets'
export type TypeOfTicketsSearchForm = 'allTickets' | 'myTickets' | 'historyTickets'

export type StringFilter = { [key: string]: string }

export enum TicketFields {
  Identifier = 'no',
  CustomerRequestNumber = 'customerRequestNumber',
  ReceiveDate = 'receiveDate',
  Status = 'status',
  DeviceType = 'deviceType',
  LUNO = 'luno',
  Address = 'address',
  POSName = 'posName',
  SlaDeadline = 'slaDeadline',
  Model = 'model',
  SuspendedUntil = 'suspendedUntil',
  SuspendedReasonId = 'suspendedReasonId',
  SuspendedReason = 'suspendedReason',
  SuspendedNotes = 'suspendedNotes',
  Description = 'description',
  WorkStart = 'workStart',
  WorkEnd = 'workEnd',
  ServiceItems = 'serviceItems',
  CloseNotes = 'closeNotes',
  Files = 'files',
  Merchant = 'merchant',
  ServiceType = 'serviceType',
  LUNOTerminalID = 'luno/TerminalId',
  SlaDeadlineDate = 'slaDeadlineDate',
}

export interface TicketFile {
  name: string
  url: string
  size: string
}

export interface Ticket {
  [TicketFields.Identifier]: string
  [TicketFields.ReceiveDate]: string
  [TicketFields.Status]: string
  [TicketFields.DeviceType]: string
  [TicketFields.LUNO]: string
  [TicketFields.Address]: string
  [TicketFields.POSName]: string
  [TicketFields.SlaDeadline]: string
}

export interface TicketDetails {
  customerRequestNumber: string
  description: string
  id: string
  notes: string
  slaDeadline: string
  status: string
  suspendedNotes: string
  suspendedReason: string
  suspendedUntil: string
  ticketNumber: string
}

export interface TicketDevice {
  address: string
  deviceType: string
  equipmentId: string
  id: string
  luno: string
  merchant: string
  notes: string
  posName: string
  receiveDate: string
  services: string
}

export interface TicketJobDetails {
  id: string
  serviceItem: string[]
  workEnd: string
  workStart: string
}

export interface Device {
  id: string
  name: string
}

export interface Company {
  id: string
  name: string
}

export interface Merchant {
  id: string
  name: string
}

export interface Renter {
  id: string
  name: string
}

export interface City {
  id: string
  name: string
}

export interface GroupingTemplate {
  id: string
  name: string
  grouping: TableGroup[]
}
export enum WindowType {
  AllTickets = 'ALL_TICKETS',
  MyTickets = 'MY_TICKETS',
  HistoryTickets = 'HISTORY_TICKETS',
}
export interface GroupTemplate {
  windowType: WindowType
  name: string
  config?: any[]
}

export interface FilterTemplate {
  id: string
  name: string
  filter: FormikValues
}

export interface TreeTableItemData {
  notes: string | null
  docnr: string | null
  serviceregion: string | null
  devicetype: string | null
  targetcompany: string | null
  sladeadlinestateid: string | null
  referencedocnr: string | null
  sladeadlinestate: string | null
  systate: string | null
  slaalertdate: string | null
  documentid: string | null
  docstatus: string | null
  originaldocnr: string | null
  documentformid: string | null
  fulladdress: string | null
  groupdocnr: string | null
  sladeadline: string | null
  completed: string | null
  priority: string | null
  isusercreated: string | null
  devcountry: string | null
  enddate: string | null
  targetcompanyid: string | null
  terminaluid: string | null
  receivedate: string | null
  devcity: string | null
  luno: string | null
  serviceforclient: string | null
  devicename: string | null
  docstatusid: string | null
}

export interface TreeTableItem {
  item: TreeTableItemData
  childItems: TreeTableItemData[]
}
export type ApiOrderBy = 'COUNT' | 'NAME'
export type ApiOrderType = 'ASC' | 'DESC'
export interface ApiGroupTemplate {
  groupingKey: string
  orderBy: ApiOrderBy
  orderType: ApiOrderType
}

export interface ParentGroup {
  groupUID: string
  groupValue: string
  valueType: string
}

export interface ApiFilterTemplate {
  id: string
  name: string
}
