import React, { FC, ReactElement } from 'react'
import clsx from 'clsx'
import Paper from '@material-ui/core/Paper'

import { useClasses } from './styles'
import { Props } from './types'

const PaperWrapper: FC<Props> = ({ children, classes: customClasses }): ReactElement => {
  const classes = useClasses()

  return <Paper className={clsx(classes.wrapper, customClasses?.paper)}>{children}</Paper>
}

export default PaperWrapper
