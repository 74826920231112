import { useCallback, useContext, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { MultipleSnapshotsApi } from '@/api/atmeye/multipleSnapshots'
import { Command } from '@/api/rsocket/command'
import { DeviceActiveCamerasData } from '@/components/pages/atmeye/Administration/MiltipleSnapshots/components/Tabs/types'
import { UseSnapshotsTypes } from '@/components/pages/atmeye/Devices/Components/CurrentDevice/Tabs/ActionsTab/hooks/useSnapshots/useSnapshotsTypes'
import { SocketContext } from '@/context'
import { clearSocketDataByMessage, getSocketData } from '@/store/dashboard'
import { useTranslate } from '@/utils/internalization'

import { SCREENSHOT_CAMERA_NUMBER } from '../../MakeSnapshotModal/component'

const useSnapshots = ({
  currentDevice,
  socketCommand,
  handleClose,
  refreshTime,
}: UseSnapshotsTypes) => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const { _service, connected } = useContext(SocketContext)
  const dataCams: DeviceActiveCamerasData<number> = useSelector(
    getSocketData(Command.GET_ACTIVE_CAMS),
    shallowEqual,
  )
  const dataSnapshot: any = useSelector(getSocketData(socketCommand), shallowEqual)
  const [image, setImage] = useState('')
  const [isLoadingCams, setIsLoadingCams] = useState(false)
  const [isLoadingImage, setIsLoadingImage] = useState(false)
  const [currentCameraNumber, setCurrentCameraNumber] = useState(0)
  const [noResponse, setNoResponse] = useState(false)
  const [error, setError] = useState<null | string>(null)
  const [timeoutId, setTimeoutId] = useState(0)

  const handleCloseModal = useCallback(() => {
    handleClose()
    setError(null)
    setImage('')
    dispatch(clearSocketDataByMessage(socketCommand))
    dispatch(clearSocketDataByMessage(Command.GET_ACTIVE_CAMS))
    socketCommand === Command.START_VIDEO &&
      _service.send(
        {
          cameraNumber: currentCameraNumber,
        },
        currentDevice?.deviceName,
        Command.STOP_VIDEO,
      )
    setCurrentCameraNumber(0)
  }, [error, handleClose, setError, image, setImage, dispatch, _service, setCurrentCameraNumber])

  const handleGetImage = useCallback(
    (item: number) => (): void => {
      setCurrentCameraNumber(prevState => {
        socketCommand === Command.START_VIDEO &&
          prevState &&
          _service.send(
            {
              cameraNumber: prevState,
            },
            currentDevice?.deviceName,
            Command.STOP_VIDEO,
          )
        return item
      })
      setError(null)
      setNoResponse(false)

      if (item !== SCREENSHOT_CAMERA_NUMBER || !image) {
        setIsLoadingImage(true)
      }

      window.clearTimeout(timeoutId)
      const timerId = window.setTimeout(() => {
        setIsLoadingImage(false)
        setError(translate('translate#atmeye.snapshotModal.imageNotLoaded'))
      }, 10000)
      setTimeoutId(timerId)

      if (connected) {
        _service.send(
          {
            cameraNumber: item,
          },
          currentDevice?.deviceName,
          socketCommand,
        )
      }
    },
    [_service, currentDevice, setCurrentCameraNumber, setNoResponse, connected, timeoutId, image],
  )

  const fetchPhoto = useCallback(async () => {
    try {
      if (!dataSnapshot.photoId) {
        throw translate('translate#atmeye.snapshotModal.imageNotLoaded')
      }

      const res = await MultipleSnapshotsApi.getSingleSnapshot(dataSnapshot.photoId)
      setImage(res)
    } catch (e) {
      setNoResponse(true)
      setError(e.toString())
    } finally {
      setIsLoadingImage(false)
      window.clearTimeout(timeoutId)
    }
  }, [dataSnapshot, setIsLoadingImage, setImage, refreshTime])

  useEffect(() => {
    let timeoutId: any = null

    if (image && refreshTime) {
      timeoutId = setTimeout(() => {
        handleGetImage(dataSnapshot.cameraNumber)()
      }, refreshTime)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [image])

  useEffect(() => {
    if (currentCameraNumber === dataSnapshot?.cameraNumber) {
      fetchPhoto()
    } else {
      dispatch(clearSocketDataByMessage(socketCommand))
      setImage('')
    }
  }, [dataSnapshot, currentCameraNumber, setImage, dispatch])

  useEffect(() => {
    if (connected) {
      setIsLoadingCams(true)
      _service.send({}, currentDevice?.deviceName, Command.GET_ACTIVE_CAMS)
    }
  }, [_service, currentDevice, setIsLoadingCams, connected])

  useEffect(() => {
    if (dataCams) {
      setIsLoadingCams(false)
    }
  }, [setIsLoadingCams, dataCams])

  return {
    handleGetImage,
    image,
    currentCameraNumber,
    noResponse,
    dataCams,
    setIsLoadingCams,
    isLoadingImage,
    isLoadingCams,
    error,
    setError,
    handleCloseModal,
  }
}

export { useSnapshots }
