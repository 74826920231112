import makeStyles from '@material-ui/core/styles/makeStyles'

export type Props = {
  overflow?: string
  fieldWidth?: number
}
export const useClasses = makeStyles(({ spacing, mixins }) => ({
  root: ({ fieldWidth }: Props) => ({
    width: `${fieldWidth}px`,
  }),
  marginContainer: {
    top: -20,
  },
  select: {
    width: '100%',
    margin: spacing(2, 0),
  },
  noOptions: {
    padding: spacing(2, 3),
  },
  showingOnly: {
    padding: spacing(0.5, 2, 0.5, 3.5),
    fontSize: '0.8rem',
    backgroundColor: '#fffbe5',
    color: '#474747',
  },
  showingOnlyText: {
    display: 'flex',
  },
  loader: {
    flex: 1,
  },
  dataLoader: {
    width: spacing(4),
    height: spacing(4),

    '&:after': {
      content: '',
      borderWidth: '3px',
    },
  },
  placeholderOption: {
    color: '#999',
  },
  normalOption: {
    color: 'black',
    textTransform: 'inherit',
  },
  headerItem: {
    opacity: 1,
  },
  headerText: {
    textTransform: 'capitalize',
    marginBottom: 0,
  },
  disabled: {
    opacity: 0.6,
    pointerEvents: 'none',
  },

  buttonWrapper: {
    display: 'flex',
    ...mixins.gutters(),
    marginTop: spacing(1),
  },
  button: {
    flex: 1,
  },
  cancelButton: {
    color: '#50a8ae',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: '1px solid rgba(80, 168, 174, 0.5)',
    marginRight: spacing(1),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menuList: ({ fieldWidth }: Props) => ({
    maxWidth: `${fieldWidth}px`,
    maxHeight: spacing(40),
    overflow: 'auto',
  }),
  menuListItem: {
    maxHeight: spacing(35),
    overflow: (props: Props) => props.overflow,
  },
  blockSelectAllWrapper: {
    padding: spacing(0, 2),
    fontSize: spacing(1.75),
    cursor: 'pointer',
  },

  selectItem: {
    padding: spacing(0.5, 2, 0.5, 3.5),
    cursor: 'pointer',
  },

  fakeCheckbox: {
    display: 'inline-block',
    width: '1.2rem',
    height: '1.2rem',
    border: '2px solid #c4c4c4',
    verticalAlign: 'middle',
    borderRadius: '2px',
    position: 'relative',
    marginRight: '0.5rem',

    '&:before': {
      content: "''",
      display: 'inline-block',
      width: '0.25rem',
      height: '0.5rem',
      borderRight: '2px solid #333',
      borderBottom: '2px solid #333',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: `translate(-50%, -50%) rotate(45deg) scale(0)`,
      transition: 'all 0.2s',
    },
  },

  fakeCheckboxChecked: {
    display: 'inline-block',
    width: '1.2rem',
    height: '1.2rem',
    border: '2px solid #50a8ae',
    backgroundColor: '#50a8ae',
    verticalAlign: 'middle',
    borderRadius: '2px',
    position: 'relative',
    marginRight: '0.5rem',

    '&:before': {
      content: "''",
      display: 'inline-block',
      width: '0.25rem',
      height: '0.5rem',
      backgroundColor: 'none',
      borderColor: 'none',
      borderRight: '2px solid #fff',
      borderBottom: '2px solid #fff',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: `translate(-50%, -60%) rotate(45deg) scale(1)`,
      // transition: 'all 0.2s',
    },
  },
}))
