import makeStyles from '@material-ui/core/styles/makeStyles'

import { styledComponentTheme } from '@/theme'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  root: {
    boxShadow: '0px 0px 15px rgba(37, 173, 191, 0.05)',
    borderRadius: '3px',
    padding: 0,
    marginTop: spacing(2.5),
  },
  table: {
    height: '100%',
    maxHeight: 'calc(100vh - 280px)',
  },
  title: {
    fontSize: styledComponentTheme.fontSizes.small,

    '&::first-child': {
      paddingLeft: spacing(10),
    },
  },
  activeFilterIcon: {
    '&::after': {
      content: '""',
      position: 'absolute',
      right: spacing(0.5),
      top: spacing(1),
      width: '20%',
      height: '20%',
      borderRadius: '50%',
      backgroundColor: palette.primary.main,
    },
  },
  loaderWrapper: {
    height: spacing(25),
    width: spacing(5),
    margin: 'auto',
  },
  cell: {
    borderBottom: 0,
  },
  noDataCell: {
    backgroundColor: '#ecf2f2',
    borderBottom: 0,
  },
}))
