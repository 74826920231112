import { TableGroup } from '@/components/pages/sd/TicketsPage/components/GroupingModal/types'
import { ApiGroupTemplate, ApiOrderBy, ApiOrderType } from '@/types'
import { WindowType } from '@/types/sd/tickets/ticket'

export const detectTicketTabLocation = (location: { pathname: string }): WindowType => {
  if (location.pathname.indexOf('all') !== -1) return WindowType.AllTickets
  else if (location.pathname.indexOf('history') !== -1) return WindowType.HistoryTickets
  return WindowType.MyTickets
}

type orderBy = 'name' | 'count'
type order = 'asc' | 'desc'

export const groupTemplateApiResponseToUi = (response: ApiGroupTemplate[]): TableGroup[] =>
  response.map((element: ApiGroupTemplate) => ({
    key: element.groupingKey,
    orderBy: element.orderBy.toLowerCase() as orderBy,
    order: element.orderType.toLowerCase() as order,
  }))

export const groupTemplateUiToApiView = (templateConfig: TableGroup[]): ApiGroupTemplate[] =>
  templateConfig.map((element: TableGroup) => ({
    groupingKey: element.key,
    orderBy: element.orderBy.toUpperCase() as ApiOrderBy,
    orderType: element.order.toUpperCase() as ApiOrderType,
  }))
