import { AnyAction } from 'redux'

import {
  ComCMReport,
  OrdDocStage,
  OrdOrder,
  OrdOrdersFilter,
  PoliceDriver,
  PoliceOrderStatusResponse,
} from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants'
import { ordOrdersTableId as tableId } from '@/constants/cm/cmTableIds'
import { getTableConfig } from '@/helpers/tableLocalStorage'
import { getCurrentDateString } from '@/utils/functions'

export type State = {
  isFiltersModalOpen: boolean
  isSettingsModalOpen: boolean
  isLoadingModalOpen: boolean
  isModifyModalOpen: boolean
  isDocumentsModalOpen: boolean
  isDeclineModalOpen: boolean
  orders: {
    selectedOrder: OrdOrder | null
    checkedOrders: { [key: string]: OrdOrder }
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined
    data: OrdOrder[]
    total: number
    isPoliceIntegrationEnabled: boolean
    isLoading: boolean
    isLoaded: boolean
    error: string | null
    filter: OrdOrdersFilter
    paging: {
      page: number
      pageSize: number
    }
    reports: {
      errorMessage?: string
      isLoading: boolean
      list: ComCMReport[]
      selectedReport: {
        isLoading: boolean
        errorMessage?: string
        bytes?: Blob | null
      }
    }
    stages: {
      orderDocStages: OrdDocStage[]
      isLoading: boolean
      selectedStage: OrdDocStage | null
    }
  }
  drivers: {
    data: PoliceDriver[]
    isLoading: boolean
    error: string | null
  }
  opsStatuses: {
    data: PoliceOrderStatusResponse[]
    isLoading: boolean
    error: string | null
  }
  operations: {
    isLoading: boolean
    error: string | null
  }
}

const initialState: State = {
  isFiltersModalOpen: false,
  isModifyModalOpen: false,
  isSettingsModalOpen: false,
  isLoadingModalOpen: false,
  isDocumentsModalOpen: false,
  isDeclineModalOpen: false,
  orders: {
    selectedOrder: null,
    checkedOrders: {},
    sortColumn: undefined,
    sortOrder: undefined,
    data: [],
    total: 0,
    isPoliceIntegrationEnabled: false,
    isLoading: false,
    isLoaded: false,
    error: null,
    filter: {
      from: getCurrentDateString(),
      to: undefined,
      state: undefined,
      replenType: undefined,
      createdByUid: undefined,
      cashierUid: undefined,
      routes: [],
    },
    paging: {
      page: 1,
      pageSize: getTableConfig({ tableId })?.rowPerPage || 50,
    },
    reports: {
      errorMessage: '',
      isLoading: false,
      list: [],
      selectedReport: {
        isLoading: false,
        errorMessage: '',
        bytes: undefined,
      },
    },
    stages: {
      orderDocStages: [],
      isLoading: false,
      selectedStage: null,
    },
  },
  drivers: {
    data: [],
    isLoading: false,
    error: null,
  },
  opsStatuses: {
    data: [],
    isLoading: false,
    error: null,
  },
  operations: {
    isLoading: false,
    error: null,
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case CMActions.SetGlobalFilter:
      return {
        ...state,
        orders: {
          ...state.orders,
          paging: {
            ...state.orders.paging,
            page: 1,
          },
        },
      }
    case CMActions.OrdSetDeclineModalOpen:
      return {
        ...state,
        isDeclineModalOpen: payload,
      }
    case CMActions.OrdCheckSelectedOrders: {
      const res: State['orders']['checkedOrders'] = {}
      const allOrders = payload.length > 0 ? payload : state.orders.data
      for (const order of allOrders) {
        if (order?.id) {
          res[order.id] = order
        }
      }
      return {
        ...state,
        orders: {
          ...state.orders,
          checkedOrders: res,
        },
      }
    }
    case CMActions.OrdGetOrdersRequest:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: true,
          error: null,
        },
      }
    case CMActions.OrdGetOrdersResponse:
      return {
        ...state,
        orders: {
          ...state.orders,
          data: payload?.data,
          total: payload?.total,
          isPoliceIntegrationEnabled: payload?.isPoliceIntegrationEnaled,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      }
    case CMActions.OrdGetOrdersResponseFail:
      return {
        ...state,
        orders: {
          ...state.orders,
          data: [],
          total: 0,
          isLoading: false,
          isLoaded: true,
          error: payload,
        },
      }
    case CMActions.OrdSetLoadingModalOpen:
      return {
        ...state,
        isLoadingModalOpen: payload,
      }
    case CMActions.OrdSetDocumentsModalOpen:
      return {
        ...state,
        isDocumentsModalOpen: payload,
      }
    case CMActions.OrdSetSettingsModalOpen:
      return {
        ...state,
        isSettingsModalOpen: payload,
      }
    case CMActions.OrdSetFiltersModalOpen:
      return {
        ...state,
        isFiltersModalOpen: payload,
      }
    case CMActions.OrdSetOrdersPaging:
      return {
        ...state,
        orders: {
          ...state.orders,
          paging: {
            ...state.orders.paging,
            ...payload,
          },
        },
      }
    case CMActions.OrdResetOrdersPage:
      return {
        ...state,
        orders: {
          ...state.orders,
          paging: {
            ...state.orders.paging,
            page: initialState?.orders?.paging?.page || 1,
          },
        },
      }
    case CMActions.OrdSetOrdersFilter:
      return {
        ...state,
        orders: {
          ...state.orders,
          filter: {
            ...state?.orders?.filter,
            ...payload,
          },
        },
      }
    case CMActions.OrdGetReportsRequest:
      return {
        ...state,
        orders: {
          ...state.orders,
          reports: {
            ...state.orders.reports,
            isLoading: true,
            list: [],
            errorMessage: '',
          },
        },
      }

    case CMActions.OrdGetReportsResponse:
      return {
        ...state,
        orders: {
          ...state.orders,
          reports: {
            ...state.orders.reports,
            isLoading: false,
            list: payload,
            errorMessage: '',
          },
        },
      }
    case CMActions.OrdGetReportsResponseFail:
      return {
        ...state,
        orders: {
          ...state.orders,
          reports: {
            ...state.orders.reports,
            isLoading: false,
            list: [],
            errorMessage: payload,
          },
        },
      }
    case CMActions.OrdGetReportRequest:
      return {
        ...state,
        orders: {
          ...state.orders,
          reports: {
            ...state.orders.reports,
            selectedReport: {
              ...state.orders.reports.selectedReport,
              isLoading: true,
              errorMessage: '',
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.OrdGetReportResponse:
      return {
        ...state,
        orders: {
          ...state.orders,
          reports: {
            ...state.orders.reports,
            selectedReport: {
              ...state.orders.reports.selectedReport,
              isLoading: false,
              errorMessage: '',
              bytes: payload,
            },
          },
        },
      }
    case CMActions.OrdGetReportResponseFail:
      return {
        ...state,
        orders: {
          ...state.orders,
          reports: {
            ...state.orders.reports,
            selectedReport: {
              ...state.orders.reports.selectedReport,
              isLoading: false,
              errorMessage: payload,
              bytes: undefined,
            },
          },
        },
      }
    case CMActions.OrdSetSelectedOrder:
      return {
        ...state,
        orders: {
          ...state.orders,
          selectedOrder: payload,
        },
      }
    case CMActions.OrdToggleOrder: {
      const newOrders = { ...state.orders.checkedOrders }
      const foundOrder = newOrders[payload?.id]
      if (!foundOrder) {
        newOrders[payload?.id] = payload
      } else {
        delete newOrders[payload?.id]
      }
      return {
        ...state,
        orders: {
          ...state?.orders,
          checkedOrders: newOrders,
        },
      }
    }
    case CMActions.OrdToggleAllOrders: {
      const checked = payload
        ? state.orders.data
            .filter(
              el => el?.id !== null && el.state !== 'Declined' && el.state !== 'SentToExecute',
            )
            .reduce((a, x) => ({ ...a, [x.id]: x }), {})
        : {}
      return {
        ...state,
        orders: {
          ...state.orders,
          checkedOrders: checked,
        },
      }
    }

    case CMActions.RefreshOrders:
    case CMActions.OrdApproveOrders:
    case CMActions.OrdDeclineOrders:
    case CMActions.OrdSendToExecuteOrders:
    case CMActions.OrdUngroupOrdersRequest:
    case CMActions.OrdGroupOrdersRequest:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: true,
        },
      }

    case CMActions.OrdDeclineOrdersResponseFail:
    case CMActions.OrdSendToExecuteOrdersResponseFail:
    case CMActions.OrdApproveOrdersResponseFail:
    case CMActions.OrdRefreshOrderResponseFail:
    case CMActions.OrdUngroupOrdersResponseFail:
    case CMActions.OrdGroupOrdersResponseFail:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
        },
      }

    case CMActions.SortOrdTable:
      return {
        ...state,
        orders: {
          ...state.orders,
          ...payload,
          isLoading: true,
        },
      }
    case CMActions.OrdRefreshOrderResponse: {
      const newData = [...state.orders.data]
      const newCheckedOrders = { ...state.orders.checkedOrders }

      if (newCheckedOrders[payload.id]) {
        newCheckedOrders[payload.id] = payload
      }

      const refreshedOrderIndex = newData.findIndex(el => el?.id === payload?.id)
      if (~refreshedOrderIndex) {
        newData[refreshedOrderIndex] = { ...payload }
        return {
          ...state,
          orders: {
            ...state.orders,
            data: newData,
            isLoading: false,
            selectedOrder:
              payload.id === state.orders.selectedOrder?.id ? payload : state.orders.selectedOrder,
            checkedOrders: newCheckedOrders,
          },
        }
      }
      return state
    }
    case CMActions.OrdGetStagesDocRequest:
      return {
        ...state,
        orders: {
          ...state.orders,
          stages: {
            ...state.orders.stages,
            isLoading: true,
            selectedStage: null,
          },
        },
      }
    case CMActions.OrdGetStagesDocResponse:
      return {
        ...state,
        orders: {
          ...state.orders,
          stages: {
            ...state.orders.stages,
            orderDocStages: payload,
            isLoading: false,
          },
        },
      }

    case CMActions.OrdSetSelectedSessionDoc:
      return {
        ...state,
        orders: {
          ...state.orders,
          stages: {
            ...state.orders.stages,
            selectedStage: payload,
          },
        },
      }
    case CMActions.OrdOperationsRequest:
      return {
        ...state,
        operations: {
          ...state.operations,
          isLoading: true,
        },
      }
    case CMActions.OrdOperationsResponse:
      return {
        ...state,
        operations: {
          ...state.operations,
          isLoading: false,
        },
      }
    case CMActions.OrdOperationsFail:
      return {
        ...state,
        operations: {
          ...state.operations,
          isLoading: false,
          error: payload,
        },
      }

    case CMActions.OrdGetCashPointDriversRequest:
      return {
        ...state,
        drivers: {
          ...state.drivers,
          isLoading: true,
        },
      }
    case CMActions.OrdGetCashPointDriversResponse:
      return {
        ...state,
        drivers: {
          ...state.drivers,
          isLoading: false,
          data: payload,
        },
      }
    case CMActions.OrdGetCashPointDriversFail:
      return {
        ...state,
        drivers: {
          ...state.drivers,
          isLoading: false,
          error: payload,
        },
      }

    case CMActions.OrdGetSentOpsStatusesRequest:
      return {
        ...state,
        opsStatuses: {
          ...state.opsStatuses,
          isLoading: true,
        },
      }
    case CMActions.OrdGetSentOpsStatusesResponse:
      return {
        ...state,
        opsStatuses: {
          ...state.opsStatuses,
          isLoading: false,
          data: payload,
        },
      }
    case CMActions.OrdGetSentOpsStatusesFail:
      return {
        ...state,
        opsStatuses: {
          ...state.opsStatuses,
          isLoading: false,
          error: payload,
        },
      }
    case CMActions.SignOut:
      return initialState
    default:
      return state
  }
}
