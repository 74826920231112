import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  loader: {
    display: 'flex',
    width: spacing(8),
    height: spacing(8),
    position: 'absolute',
    left: '50%',
    top: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1px',
    transform: 'translateX(-50%) translateY(-50%)',
    zIndex: 9,
    '&::after': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '100%',
      margin: '1px',
      borderRadius: '50%',
      border: `${spacing(0.5)}px solid ${palette.primary.main}`,
      borderColor: `${palette.primary.main} transparent ${palette.primary.main} transparent`,
      animation: `$lds-dual-ring 1.2s linear infinite`,
    },
  },
  inlineLoader: {
    display: 'inline-flex',
    width: '100%',
    minWidth: spacing(2),
    minHeight: spacing(2),
    height: '100%',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '12.5%',
      top: '12.5%',
      width: '75%',
      height: '75%',
      borderRadius: '50%',
      border: `${spacing(0.25)}px solid ${palette.primary.main}`,
      borderColor: `${palette.primary.main} transparent ${palette.primary.main} transparent`,
      animation: `$lds-dual-ring 1.2s linear infinite`,
    },
  },
  '@keyframes lds-dual-ring': {
    '0%': {
      transform: 'rotate(0deg) scale(1)',
    },
    '50%': {
      transform: 'rotate(180deg) scale(0.75)',
    },
    '100%': {
      transform: 'rotate(360deg) scale(1)',
    },
  },
}))
