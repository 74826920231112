import { Field as FormikField } from 'formik'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import { createSelectField } from '@/components/controls/Select'
import { useDidUpdateEffect } from '@/utils/hooks/hooks'

import { Props } from './types'

const LookupsList = ({
  fieldName,
  fieldTitle,
  field,
  isDisabled,
  onCall,
  onSelect,
  setFormikFieldValue,
  defaultValue,
  isDependsOn,
  dependsOn = [],
  className,
}: Props): ReactElement => {
  const initialValue =
    defaultValue && defaultValue.name && defaultValue.value
      ? {
          name: String(defaultValue.name),
          value: String(defaultValue.value),
        }
      : null

  const [dataIsLoading, setDataIsLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<{ item: any; value: string; name: string }[]>([])

  const getOptions = async (fragment: string): Promise<any> => {
    try {
      setDataIsLoading(true)

      const options: { item: any; value: string; name: string }[] = await onCall(fragment)

      setOptions(options)

      setDataIsLoading(false)
    } catch (error) {
      throw new Error(error)
    }
  }
  const handleOpen = () => (): void => {
    getOptions('')
  }

  useEffect(() => {
    if (!options.find(item => String(item.value) === initialValue?.value)) setOptions([])
  }, [defaultValue?.value])

  useDidUpdateEffect(() => {
    if (isDependsOn) {
      setFormikFieldValue(fieldName, '')
      setFormikFieldValue(fieldTitle, '')
      setOptions([])
    }
  }, dependsOn)

  const Field = useMemo(() => {
    return createSelectField({
      isLoading: dataIsLoading,
      label: field.options?.label,
      placeholder: field.options?.placeholder,
      options: options.length ? options : initialValue ? [initialValue] : [],
      shrink: true,
      withoutCheckerForSingleOption: true,
      withAutofillForSingleOption: true,
      disabled: isDisabled,
      required: field.options?.required,
      onOpen: handleOpen(),
      onChange: event => {
        const item = options.find(item => String(item.value) === String(event.target.value))
        if (onSelect) onSelect(item)
        else {
          setFormikFieldValue(
            fieldName,
            item ? (field.options.isNumber ? Number(item.value) : item.value) : '',
          )
          setFormikFieldValue(fieldTitle, item ? item.name : '')
        }
      },
      className: className,
    })
  }, [...dependsOn, defaultValue, initialValue, options, dataIsLoading, isDisabled])

  return <FormikField name={fieldName}>{Field}</FormikField>
}

export default LookupsList
