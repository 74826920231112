import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import { actionsATMEyeDashboard } from '@/store/atmeye/dashboard'
import { AddNewSubPageTypes, AddNewSubscriptionPageTypes } from '@/types/atmeye/dashboardTypes'
import { ActionFormTypes } from '@/types/usm/commoTypes/actionFormTypes'

type Props<T> = (payload: AddNewSubPageTypes, actions: ActionFormTypes<T>) => void

const useSubPageModal = <T>(actionCreatorPageAsync: Props<T>) => {
  const [showSubPageModal, setShowPageModal] = useState<null | 'Add new page' | 'Edit page'>(null)
  const dispatch = useDispatch()

  const handleClearSubPageErrors = useCallback(() => {
    dispatch(actionsATMEyeDashboard.clearSubPageErrors())
  }, [])

  const handleSubPageModal = useCallback(
    (value: null | 'Add new page' | 'Edit page'): (() => void) => (): void => {
      setShowPageModal(value)

      handleClearSubPageErrors()
    },
    [showSubPageModal],
  )

  const handleSave = useCallback(
    (payload: AddNewSubPageTypes | AddNewSubscriptionPageTypes): (() => void) => (): void => {
      actionCreatorPageAsync(payload as AddNewSubPageTypes, {
        handleClose: handleSubPageModal(null),
      })
    },
    [showSubPageModal],
  )

  return { showSubPageModal, handleSubPageModal, handleSave }
}

export { useSubPageModal }
