import queryString from 'query-string'

import { AdmPlnTeam, AdmPlnTeamGroup, AdmPlnUser, ComUuidValue } from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class AdmTeamsApi {
  private static requestService = getRequestServiceCM()

  public static async get(sort: Sort): Promise<AdmPlnTeamGroup[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/planning/teams',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await AdmTeamsApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async add(team: AdmPlnTeam): Promise<void> {
    try {
      return await AdmTeamsApi.requestService.post('/admin/planning/teams/add', team)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(team: AdmPlnTeam): Promise<void> {
    try {
      return await AdmTeamsApi.requestService.put('/admin/planning/teams/update', team)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(id: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/planning/teams/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await AdmTeamsApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getUsers(guid: string): Promise<AdmPlnUser[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/planning/teams/users',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )

      return await AdmTeamsApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getRoles(guid: string): Promise<ComUuidValue[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/planning/teams/roles',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )

      return await AdmTeamsApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getVehicles(guid: string): Promise<ComUuidValue[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/planning/teams/vehicles',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )

      return await AdmTeamsApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
