import { Badge } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import { ExpandMore } from '@material-ui/icons'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Test from 'react-test-attributes'
import { setDirectSelectScriptOpen } from 'store/srm/directSelectScript'
import { setNotificationServiceRequestEvent } from 'store/srm/notifyServiceRequestsEvent'

import DraggableBookmark from '@/components/blocks/DraggableBookmark/component'
import AccessControl from '@/components/controls/auth/AccessControl'
import { AppNavigationRoutes, isMenuItemDisabled, SRM_BLOCK_PREFIX } from '@/constants'
import { ATMEYE_BLOCK_PREFIX } from '@/constants/paths'
import { getPermissions } from '@/store/auth/selectors'
import { setLog, setRecordingStatus } from '@/store/srm/logActions/actions'
import { getisLogSet, getRecordingStatus } from '@/store/srm/logActions/selectors'
import { getTestId } from '@/utils'
import { useTranslate } from '@/utils/internalization'
import { isRouteCurrent } from '@/utils/path'
import { PopUpService } from '@/utils/services/popUpService'

import { MenuItemIcon } from './styles'
import { Props } from './types'

const StyledListItem = withStyles(
  {
    root: {
      padding: '4px 0',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  { name: 'ListItemButton' },
)(ListItem)

const DrawerMenuItem = ({
  label,
  subItems,
  innerPages = [],
  path,
  pathname,
  classes,
  activeFor,
  handleAddTab,
  setOpenCloseModal,
  Icon,
  hideSubItems = false,
  actions,
  badgeCounter,
}: Props): React.ReactElement => {
  const translate = useTranslate()
  const dispatch = useDispatch()

  const isCurrentSubRoute = isRouteCurrent(
    pathname,
    [
      ...subItems.map(item => item.path),
      ...innerPages.map(page => page.path),
      ...(activeFor || []),
      ...(path ? [path] : []),
    ],
    true,
  )
  const [showSubItems, setShowSubItems] = useState(isCurrentSubRoute)
  const [isRecordingLogDotShown, setIsRecordingLogDotShown] = useState<boolean>(false)
  const isLogSet = useSelector(getisLogSet)
  const recordingStatus = useSelector(getRecordingStatus)
  const permissions = useSelector(getPermissions)

  const handleSetIsRecordingLogDotShown = (state: boolean): void => {
    setIsRecordingLogDotShown(state)
  }
  const handleToggleSubItems = useCallback((): void => {
    if (setOpenCloseModal && !pathname.includes(ATMEYE_BLOCK_PREFIX)) {
      setOpenCloseModal()
    }
    setShowSubItems(prevState => !prevState)
  }, [])

  const handleSetLogIconActive = (): void => {
    handleSetIsRecordingLogDotShown(true)
    PopUpService.showGlobalPopUp(`translate#title.logRecordStarted`, 'warning')
    dispatch(setRecordingStatus('inProgress'))
    const recordingTimeOut = window.setTimeout(() => {
      handleSetIsRecordingLogDotShown(false)
      dispatch(setLog(false))
      dispatch(setRecordingStatus('none'))
      PopUpService.showGlobalPopUp(`translate#title.sentSuccessfully`, 'success')
      clearTimeout(recordingTimeOut)
    }, 3600000)
  }

  const handleOpenNotificationServiceRequestEvent = useCallback(() => {
    dispatch(setNotificationServiceRequestEvent(true))
  }, [])

  const handleOpenDirectSelectScript = useCallback(() => {
    dispatch(setDirectSelectScriptOpen(true))
  }, [])

  const handleClickItem = (path: string, label: string): void => {
    !isLogSet && path === `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.Log}` && dispatch(setLog(true))

    if (path === `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.NotifyService}`) {
      handleOpenNotificationServiceRequestEvent()
      return
    }
    if (path === `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.SelectScriptPage}`) {
      handleOpenDirectSelectScript()
      return
    }
    handleAddTab(path, label)()
  }

  useEffect((): void => {
    isLogSet &&
    path === `${SRM_BLOCK_PREFIX}${AppNavigationRoutes.Log}` &&
    recordingStatus === 'none'
      ? handleSetLogIconActive()
      : handleSetIsRecordingLogDotShown(false)
  }, [isLogSet])

  useEffect((): void => {
    // do not handle common tabs adding for atmeye module
    if (pathname.includes(ATMEYE_BLOCK_PREFIX)) {
      return
    }

    if (isCurrentSubRoute) {
      if (subItems.length) {
        const foundSubItem = subItems.find(item => isRouteCurrent(pathname, item.path))
        if (foundSubItem) {
          handleAddTab(foundSubItem.path, foundSubItem.label)()
        }
      } else if (innerPages.length) {
        const foundInnerPage = innerPages.find(page => isRouteCurrent(pathname, page.path))

        if (foundInnerPage) {
          handleAddTab(foundInnerPage.path, foundInnerPage.label)()
        }
      } else {
        if (path) {
          handleAddTab(path, label)()
        }
      }
    }
    // eslint-disable-next-line
  }, [])
  const testId = getTestId('drawer-menu-item')

  const isSubItemActive = useCallback(
    (subPath: string) => {
      return pathname.includes(ATMEYE_BLOCK_PREFIX)
        ? pathname.startsWith(subPath)
        : isRouteCurrent(pathname, [subPath])
    },
    [pathname],
  )

  return (
    <Test id={testId(0, `${label}`)}>
      <div onClick={actions && actions[label]}>
        <Test id={testId(1, `${label}`)}>
          <div
            className={clsx(
              {
                [classes.menuSoleItem]: !subItems.length || !showSubItems || hideSubItems,
                [classes.menuSoleItemActive]:
                  isCurrentSubRoute && (!subItems.length || !showSubItems || hideSubItems),
                [classes.menuItemActive]: isCurrentSubRoute,
              },
              classes.menuItem,
            )}
          >
            <DraggableBookmark
              draggingClassName={classes.draggingItem}
              label={label}
              disabled={!path}
              path={path || ''}
              type="drawerMenu"
            >
              <Test id={testId(2, `${label}`)}>
                <StyledListItem
                  disabled={!subItems.length && isMenuItemDisabled(path, permissions)}
                  className={classes.listItem}
                  onClick={
                    !subItems.length && path
                      ? (): void => handleClickItem(path, label)
                      : handleToggleSubItems
                  }
                  button
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <Box display="flex" alignItems="center">
                      <Test id={testId(4, `${label}`)}>
                        {Icon ? <MenuItemIcon src={Icon} /> : <MenuIcon />}
                      </Test>
                      {isRecordingLogDotShown && (
                        <div className={classes.menuItemRecordingLogDot} />
                      )}
                    </Box>
                  </ListItemIcon>
                  <ListItemText>
                    <div
                      title={translate(label)}
                      className={
                        typeof badgeCounter !== 'number'
                          ? classes.menuItemTitle
                          : classes.menuItemBadge
                      }
                    >
                      {typeof badgeCounter === 'number' && (
                        <Box style={{ height: '32px', display: 'flex', alignItems: 'center' }}>
                          <Badge
                            max={99}
                            classes={{
                              badge: classes.badgeElement,
                            }}
                            badgeContent={badgeCounter}
                            color="primary"
                          >
                            {translate(label)}
                          </Badge>
                        </Box>
                      )}
                      {typeof badgeCounter !== 'number' && <>{translate(label)}</>}
                    </div>
                    {!hideSubItems && (
                      <Test id={testId(3, `${label}`)}>
                        <ExpandMore
                          className={clsx(
                            classes.subItemsIcon,
                            { [classes.subItemsIconHidden]: !subItems.length },
                            { [classes.subItemsIconRemoved]: hideSubItems },
                            { [classes.rotateSubItemsIcon]: showSubItems },
                          )}
                        />
                      </Test>
                    )}
                  </ListItemText>
                </StyledListItem>
              </Test>
            </DraggableBookmark>
          </div>
        </Test>
        {showSubItems &&
          !hideSubItems &&
          subItems.map(
            ({ label: subLabel, path: subPath, icon: subIcon, allowedPermissions }, index) => (
              <Test id={testId(5, `${index}-${subLabel}`)} key={`subTab${subLabel}`}>
                <AccessControl
                  allowedPermissions={allowedPermissions}
                  renderNoAccess={(): React.ReactElement => <></>}
                >
                  <div
                    className={clsx(classes.menuItem, classes.menuSubItem, {
                      [classes.menuSubItemActive]: isSubItemActive(subPath),
                    })}
                  >
                    <DraggableBookmark
                      draggingClassName={classes.draggingItem}
                      label={subLabel}
                      path={subPath}
                      type="drawerMenu"
                    >
                      <Test id={testId(6, `${index}-${subLabel}`)}>
                        <StyledListItem
                          disabled={isMenuItemDisabled(subPath, permissions)}
                          onClick={
                            setOpenCloseModal && pathname.includes(ATMEYE_BLOCK_PREFIX) && !subPath
                              ? setOpenCloseModal
                              : handleAddTab(subPath, subLabel)
                          }
                          button
                        >
                          <ListItemText>
                            <div
                              title={translate(subLabel)}
                              className={clsx(classes.menuItemTitle, classes.menuSubItemTitle)}
                              style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                            >
                              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {translate(subLabel)}
                              </span>

                              {subIcon && <MenuItemIcon src={subIcon} />}
                            </div>
                          </ListItemText>
                        </StyledListItem>
                      </Test>
                    </DraggableBookmark>
                  </div>
                </AccessControl>
              </Test>
            ),
          )}
      </div>
    </Test>
  )
}

export default DrawerMenuItem
