import { makeStyles } from '@material-ui/core'

export const useClasses = makeStyles(() => ({
  button: {
    height: 40,
    fontWeight: 'normal',
    padding: '0 20px',
    margin: 0,
  },

  loader: {
    width: '22px',
    height: '22px',

    '&:after': {
      content: '',
      borderWidth: '3px',
      border: `2px solid #fff`,
      borderColor: `#fff transparent  #fff transparent`,
    },
  },
}))
