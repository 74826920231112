import { AnyAction } from 'redux'

import { AdmDenomination, AdmDenominationCurrency } from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants/actions'

export type State = {
  data: AdmDenomination[]
  total: number
  isLoading: boolean
  isLoaded: boolean
  error: string | null

  sortColumn?: string
  sortOrder?: 'asc' | 'desc'

  selected: AdmDenomination | null

  currencies: AdmDenominationCurrency[]
}

export const initialState: State = {
  data: [],
  total: 0,
  isLoading: false,
  isLoaded: false,
  error: null,

  sortColumn: undefined,
  sortOrder: undefined,

  selected: null,

  currencies: [],
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case VaultV1Actions.AdmGetDenominationsRequest:
      return {
        ...state,
        isLoading: true,
      }
    case VaultV1Actions.AdmGetDenominationsResponse:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: payload || [],
        selected: !state.selected ? payload[0] || null : state.selected,
        total: (payload || []).length || 0,
      }

    case VaultV1Actions.AdmGetDenominationsFail:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: payload,
      }

    case VaultV1Actions.AdmAddDenominationRequest:
    case VaultV1Actions.AdmUpdateDenominationRequest:
    case VaultV1Actions.AdmDeleteDenominationRequest:
      return {
        ...state,
        isLoading: true,
      }

    case VaultV1Actions.AdmAddDenominationFail:
    case VaultV1Actions.AdmUpdateDenominationFail:
    case VaultV1Actions.AdmDeleteDenominationFail:
      return {
        ...state,
        isLoading: false,
      }

    case VaultV1Actions.AdmSetSelectedDenomination:
      return {
        ...state,
        selected: payload || null,
      }

    case VaultV1Actions.AdmSortDenominations:
      return {
        ...state,
        ...payload,
      }

    case VaultV1Actions.AdmGetDenominationCurrenciesResponse:
      return {
        ...state,
        currencies: payload || [],
      }

    case VaultV1Actions.SignOut:
      return initialState
    default:
      return state
  }
}
