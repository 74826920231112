let show: (text: string, type?: 'success' | 'info' | 'warning' | 'error', data?: string[]) => void

const setPopUpShow = (
  fn: (text: string, type?: 'success' | 'info' | 'warning' | 'error') => void,
): void => {
  show = fn
}

const showGlobalPopUp = (
  text: string,
  type?: 'success' | 'info' | 'warning' | 'error',
  data?: string[],
): void => {
  show(text, type, data)
}

export const PopUpService = {
  setPopUpShow,
  showGlobalPopUp,
}
