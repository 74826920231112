import React, { ReactElement, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import AppSwitch from '@/components/controls/AppSwitch'
import { useTranslate } from '@/utils/internalization'
import {
  toggleNotifications,
  activateNotifications,
  disableNotifications,
} from '@/store/srm/notifications/actions'
import { getNotificationsStatus } from '@/store/srm/notifications/selectors'

import { useClasses } from './styles'
import { Props } from './types'

const NotificationsHeader = ({ notificationsCount }: Props): ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const notificationsStatus = useSelector(getNotificationsStatus)

  const handleNotificationsToggle = useCallback(() => dispatch(toggleNotifications()), [dispatch])

  useEffect(() => {
    const status = sessionStorage.getItem('notificationStatus')

    if (!!status === true) {
      dispatch(activateNotifications())
    } else if (!!status === false) {
      dispatch(disableNotifications())
    }
  }, [dispatch])

  useEffect(() => {
    sessionStorage.setItem('notificationStatus', notificationsStatus ? 'true' : '')
  }, [notificationsStatus])

  return (
    <div className={classes.notificationHeader}>
      <div className={classes.notificationHeaderTitle}>
        <Typography variant="h6">
          {translate('translate#title.notifications')} ({notificationsCount})
        </Typography>
      </div>
      <div className={classes.notificationHeaderBtnDisable}>
        <AppSwitch active={notificationsStatus} handleChange={handleNotificationsToggle} withText />
      </div>
    </div>
  )
}

export default NotificationsHeader
