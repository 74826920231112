import makeStyles from '@material-ui/core/styles/makeStyles'

import { styledComponentTheme } from '@/theme'

export const useClasses = makeStyles(({ mixins, spacing }) => ({
  headerCell: {
    background: '#f8f8f8',
    padding: spacing(1, 2),
    borderBottom: 0,
    width: 'auto',
  },
  title: {
    fontSize: styledComponentTheme.fontSizes.small,
  },
  tableCell: {
    whiteSpace: 'nowrap',
    '&.MuiTableCell-head.MuiTableCell-root': {},
  },
  thead: {
    background: '#f8f8f8',
  },
  tableHeader: {
    display: 'table-row',
  },
}))
