import queryString from 'query-string'

import {
  BalCassetteModifyRequest,
  BalCassetteRemoveRequest,
  BalGetBalancingCassettesResponse,
  BalGetBalancingInfoResponse,
  BalSetUnloadedBalancingRequest,
  BalTotalsResponse,
  BS2CashOrderStatus,
  BS2CashPointEvent,
  CitBalanceMainReportRequest,
  CitBalanceReportsRequest,
  CitBalCashPointGetAccountsResponse,
  CitBalCashPointsRequest,
  CitBalCashPointsResponse,
  CitBalGetAssetItemRequest,
  CitBalSetCashierRequest,
  CitBalTotalRemaindersResponse,
  ComCashPointAccount,
  ComCMReport,
  ComTotals,
  ComUuidValue,
  IcsCashPointCassette,
  IcsEventBase,
  IcsMissedReplenishmentResponse,
  VaultCitBalTransactionRequest,
} from '@/api/cm/swaggerGeneratedApi'
import { APIConfiguration, LocalStorageItems } from '@/constants'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class CitBalancingApi {
  private static requestService = getRequestServiceCM()

  public static async getCashPoints(
    requestBody: CitBalCashPointsRequest,
    paging: { page: number; pageSize: number },
    sortColumn?: string | undefined,
    sortOrder?: 'asc' | 'desc' | undefined,
  ): Promise<CitBalCashPointsResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'cit/balancing/points',
          query: {
            pageSize: `${paging.pageSize}`,
            page: `${paging.page}`,
            sortColumn,
            sortOrder,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await CitBalancingApi.requestService.post(url, requestBody)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async getBalancingInfo(id: string): Promise<BalGetBalancingInfoResponse> {
    try {
      return await CitBalancingApi.requestService.post(`cit/balancing/getBalancingInfo`, { id })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getBalancingCassettes(id: string): Promise<BalGetBalancingCassettesResponse> {
    try {
      return await CitBalancingApi.requestService.post(`cit/balancing/getBalancingCassettes`, {
        id,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setUnloadedBalancing(payload: BalSetUnloadedBalancingRequest): Promise<void> {
    try {
      await CitBalancingApi.requestService.post(`cit/balancing/setUnloadedBalancing`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async calcUnloadedTotals(
    payload: BalSetUnloadedBalancingRequest,
  ): Promise<BalTotalsResponse> {
    try {
      return await CitBalancingApi.requestService.post(
        `cit/balancing/calculateUnloadedTotals`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getEvents({
    requestBody,
    paging,
    sort,
  }: {
    requestBody: { dateFrom: string; cashPointId: string }
    paging: { page: number; pageSize: number }
    sort?: { sortColumn?: string | undefined; sortOrder?: 'asc' | 'desc' | undefined }
  }): Promise<IcsMissedReplenishmentResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/cit/balancing/closeNoData',
          query: { ...paging, ...sort },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await CitBalancingApi.requestService.post(url, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async applyEvent(requestBody: {
    event: IcsEventBase
    cashOrderId: string
  }): Promise<void> {
    try {
      return await CitBalancingApi.requestService.post(
        '/cit/balancing/closeNoData/apply',
        requestBody,
      )
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async closeCashOrder(requestBody: {
    sessionId: string
    orderStatus: BS2CashOrderStatus
  }): Promise<void> {
    try {
      return await CitBalancingApi.requestService.post('/cit/balancing/closeCashOrder', requestBody)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async getAccounts(id: string): Promise<CitBalCashPointGetAccountsResponse> {
    try {
      const url = queryString.stringifyUrl({
        url: '/cit/balancing/getAccounts',
        query: { id },
      })

      return await CitBalancingApi.requestService.get(url)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async updateAccounts(requestBody: {
    cpId: string
    accounts: ComCashPointAccount[]
  }): Promise<void> {
    try {
      return await CitBalancingApi.requestService.post('/cit/balancing/setAccounts', requestBody)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async getReports(payload: CitBalanceMainReportRequest): Promise<ComCMReport[]> {
    try {
      return await CitBalancingApi.requestService.post('cit/balancing/main/reports', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashPointReports(
    payload: CitBalanceReportsRequest,
  ): Promise<ComCMReport[]> {
    try {
      return await CitBalancingApi.requestService.post('cit/balancing/reports', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getAssetItemReport(
    payload: CitBalGetAssetItemRequest,
  ): Promise<Blob | undefined> {
    try {
      const tokenCM = localStorage.getItem(LocalStorageItems.AccessTokenCM) || ''

      const response = await fetch(
        `${APIConfiguration.CM_API_BASE_URL}/cit/balancing/reports/assetItemReport`,
        {
          body: JSON.stringify(payload),
          headers: { Authorization: `Bearer ${tokenCM}` },
          method: 'POST',
        },
      )

      if (response.ok) {
        return await response.blob()
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getAddEvents({
    eventType,
    orderId,
  }: {
    orderId: string
    eventType: string
  }): Promise<void> {
    try {
      return await CitBalancingApi.requestService.post(
        '/cit/balancing/closeNoData/newEventCassettes',
        { eventType, orderId },
      )
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async updateAddEvents(requestBody: {
    cassettes: IcsCashPointCassette[]
    eventType: BS2CashPointEvent
  }): Promise<ComTotals> {
    try {
      return await CitBalancingApi.requestService.post(
        '/cit/balancing/closeNoData/totals',
        requestBody,
      )
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async createAddEvent(requestBody: {
    cassettes: IcsCashPointCassette[]
    date: string
    eventType: BS2CashPointEvent
    cashOrderId: string
  }): Promise<void> {
    try {
      return await CitBalancingApi.requestService.post(
        '/cit/balancing/closeNoData/createEvent',
        requestBody,
      )
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async assignCashier(payload: CitBalSetCashierRequest): Promise<void> {
    try {
      return await CitBalancingApi.requestService.post('cit/balancing/setCashier', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashiers(): Promise<ComUuidValue> {
    try {
      return await CitBalancingApi.requestService.get('cit/balancing/getCashiers')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async modifyCassette(payload: BalCassetteModifyRequest): Promise<void> {
    try {
      await CitBalancingApi.requestService.put(`cit/balancing/modifyCassette`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async removeCassette(payload: BalCassetteRemoveRequest): Promise<void> {
    try {
      await CitBalancingApi.requestService.delete(`cit/balancing/removeCassette`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getVaultTransactionTotals(
    payload: VaultCitBalTransactionRequest,
  ): Promise<CitBalTotalRemaindersResponse> {
    try {
      return await CitBalancingApi.requestService.post(
        `cit/balancing/vaultTransaction/totals`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setVaultTransaction(
    payload: VaultCitBalTransactionRequest,
  ): Promise<string[]> {
    try {
      return await CitBalancingApi.requestService.post(`cit/balancing/vaultTransaction`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setRevertTransaction(payload: string): Promise<void> {
    try {
      await CitBalancingApi.requestService.post(`cit/balancing/revertTransaction`, {
        coid: payload,
      })
    } catch (e) {
      throw new Error(e)
    }
  }
}
