import queryString from 'query-string'

import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

import {
  ComCMReport,
  PlasticCardsReportsRequest,
  PlasticCardsWorkOrdersResponse,
} from '../swaggerGeneratedApi'
import { GetWorkOrders } from './types'

export class PlasticCardWOApi {
  private static requestService = getRequestServiceCM()

  public static async getAll({
    requestBody,
    sort,
  }: GetWorkOrders): Promise<PlasticCardsWorkOrdersResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/plasticCards/workOrders',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await PlasticCardWOApi.requestService.post(url, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async create(orderIds: string[]): Promise<void> {
    try {
      return await PlasticCardWOApi.requestService.post('/plasticCards/createWorkOrder', {
        orderIds,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async decline(orderId: string, reason: string): Promise<void> {
    try {
      return await PlasticCardWOApi.requestService.post('/plasticCards/declineOrder', {
        orderId,
        reason,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async delete(guid: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/plasticCards/deleteWorkOrder',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )

      return await PlasticCardWOApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async complete(guid: string, isAll: boolean): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/plasticCards/completeOrder',
          query: { guid, isAll },
        },
        { skipNull: true, encode: false },
      )

      return await PlasticCardWOApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getReports(payload: PlasticCardsReportsRequest): Promise<ComCMReport[]> {
    try {
      return await PlasticCardWOApi.requestService.post('/plasticCards/reports', payload)
    } catch (e) {
      throw new Error(e)
    }
  }
}
