import { intersectionWith } from 'lodash-es'
import React, { ReactElement, useMemo } from 'react'

import { MapMarkerATMeye } from '@/components/pages/atmeye/Devices/Components/Markers/MapMarkerATMeye'
import { SingleMarker } from '@/components/pages/atmeye/Devices/Components/Markers/SingleMarker'

import { MarkerContent } from '../MarkerContent'
import { Props } from './types'

export const MarkerCluster = ({
  isCanOpenModalMarker,
  points,
  mapPointsInfo,
  handleSetCurrentDeviceId,
  currentDevice,
  onMouseOver,
}: Props): ReactElement => {
  const clusterMarkerInfos = useMemo(
    () =>
      intersectionWith(mapPointsInfo, points, (firstArray, secondArray) => {
        return +firstArray.latitude === secondArray.lat && +firstArray.longitude === secondArray.lng
      }),
    [points, mapPointsInfo],
  )

  const devicesMapObjets: {
    name: string
    id: string
    type: 'SECURITY_ALARM' | 'TECHNICAL_EVENT' | 'COMMON_EVENT' | 'ALL_EVENTS'
  }[] = useMemo(() => {
    return clusterMarkerInfos.map(item => ({
      id: item.deviceId,
      name: item.deviceName,
      type:
        item.alarmCount && item.technicalEventCount
          ? 'ALL_EVENTS'
          : item.alarmCount
          ? 'SECURITY_ALARM'
          : item.technicalEventCount
          ? 'TECHNICAL_EVENT'
          : 'COMMON_EVENT',
    }))
  }, [clusterMarkerInfos])

  const techEventsCount = useMemo(() => {
    return devicesMapObjets.filter(
      item => item.type === 'ALL_EVENTS' || item.type === 'TECHNICAL_EVENT',
    ).length
  }, [devicesMapObjets])

  const alarmsCount = useMemo(() => {
    return devicesMapObjets.filter(
      item => item.type === 'ALL_EVENTS' || item.type === 'SECURITY_ALARM',
    ).length
  }, [devicesMapObjets])

  const transactionsClusterCount = useMemo(() => {
    return mapPointsInfo.reduce((acc, curr) => {
      return acc + curr.transactionsCount
    }, 0)
  }, [mapPointsInfo])

  const markerInfo = useMemo(() => {
    return devicesMapObjets.reduce(
      (acc, curr) => {
        const { type } = curr
        return {
          eventCount:
            acc.eventCount + (type === 'TECHNICAL_EVENT' || type === 'ALL_EVENTS' ? 1 : 0),
          alarmCount: acc.eventCount + (type === 'SECURITY_ALARM' || type === 'ALL_EVENTS' ? 1 : 0),
        }
      },
      {
        eventCount: 0,
        alarmCount: 0,
      },
    )
  }, [devicesMapObjets])

  const [clusterMarker] = clusterMarkerInfos

  if (!clusterMarker) {
    return <></>
  }

  const {
    deviceName,
    deviceModel,
    alarmCount,
    country,
    deviceId,
    technicalEventCount,
    address,
    deviceType,
    transactionsCount,
  } = clusterMarker

  return (
    <MapMarkerATMeye
      onMouseOver={onMouseOver}
      text={`${clusterMarkerInfos.length}`}
      clusterMarkerInfo={clusterMarkerInfos}
      markerDetails={
        isCanOpenModalMarker ? (
          <>
            {clusterMarkerInfos.length === 1 && isCanOpenModalMarker && (
              <SingleMarker
                country={country}
                deviceId={deviceId}
                technicalEventCount={technicalEventCount}
                alarmCount={alarmCount}
                address={address}
                deviceModel={deviceModel}
                city={clusterMarker?.city || null}
                deviceName={deviceName}
                deviceType={deviceType}
                handleSetCurrentDeviceId={handleSetCurrentDeviceId}
                transactionsCount={transactionsCount}
              />
            )}
            {!(clusterMarkerInfos.length === 1) && isCanOpenModalMarker && (
              <MarkerContent
                techEventsCount={techEventsCount}
                securityAlarmsCount={alarmsCount}
                total={devicesMapObjets.length}
                devices={devicesMapObjets}
                onSetCurrentDeviceId={handleSetCurrentDeviceId}
                currentDevice={currentDevice}
                transactionsCount={transactionsClusterCount}
              />
            )}
          </>
        ) : null
      }
    />
  )
}
