import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  wrapper: {
    padding: spacing(2),
    paddingLeft: spacing(6),
    width: spacing(60),
  },
  closeButton: {
    top: spacing(2),
    right: spacing(2),
  },
  yesButton: {
    minWidth: spacing(15),
  },
  buttonWrapper: {
    marginTop: spacing(2),
    justifyContent: 'center',
  },
  noButton: {
    minWidth: spacing(15),
    color: palette.error.main,
    backgroundColor: palette.error.light,
    '&:hover': {
      backgroundColor: palette.error.light,
    },
  },
  message: {
    paddingLeft: spacing(0),
    paddingBottom: spacing(1),
  },
  subMessage: {},
}))
