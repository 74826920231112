export interface Currency {
  currency: string
  currencycode: string
  currencyid: number
  exratetobasecur: number
  fractionalpart: string
}

export type AttachedFile = {
  [key: string]: any
  bodyCount: number
  createDate: Date
  fileName: string
  modifyDate: Date
  objectEntryId: number
  objectEnum: string
  objectSysFileId: number
  sysKey: string
}

export type AddAttachedFile = {
  body: string
  createDate: string
  fileName: string
  modifyDate: string
  objectEntryId: number | null
  objectEnum: string
  sysKey: string
}

export enum AttachedFilesObjectType {
  PRODUCT_FILES = 'PRODUCT_FILES',
}

export enum AttachedFilesSysKey {
  PRODUCTS = 'Products',
}

export interface LookupsCommonRequest {
  module: string
  fragment: string
  pageNo: number
  pageSize: number
}

export interface CountriesLookupsRequest {
  countryId: number
  stateId: number
  regionId: number
  selectId: number
  fragment: string
  pageNo: number
  pageSize: number
}

export interface ProductsVendorLookupsRequest {
  vendorId: number
  levels: string[]
  fragment: string
  pageNo: number
  pageSize: number
}

export interface CitiesLookupsRequest {
  cityId: number
  selectId: number
  fragment: string
  pageNo: number
  pageSize: number
}

export interface LookupsDepartmentsRequest {
  companyId: number
  forbiddenId: number
  fragment: string
  pageNo: number
  pageSize: number
}

export interface LookupsFiscalCashRegister {
  serviceRequestId: number
  fragment: string
  pageNo: number
  pageSize: number
}

export interface LookupsResponse {
  values: Array<{
    id: string
    name: string
  }>
}

export interface ReportIntervalsLookupResponse {
  from: string
  id: string
  label: string
  name: string
  to: string
}

export interface AdmDeviceTypeResponse {
  values: Array<{
    id: string
    name: string
    isDevice: boolean | null
    isHardware: boolean | null
  }>
}

export interface CityStateRegion {
  city: string
  cityId: number
  region: string | null
  regionId: number | null
  state: string | null
  stateId: number | null
}

export interface ProductVendor {
  id: string
  name: number
  code: string | null
  vendorCompanyId: number | null
  vendorCompanyName: string | null
}

export interface StateRegion {
  region: string
  regionId: number
  state: string | null
  stateId: number | null
}

export interface WorkShift {
  companyWorkShift: string
  companyWorkShiftCode: string | null
  companyWorkShiftId: number
  fromTimeH: number // | null
  fromTimeM: number // | null
  toTimeH: number // | null
  toTimeM: number // | null
  fromTimeH2: number | null
  fromTimeM2: number | null
  toTimeH2: number | null
  toTimeM2: number | null
  totWorkTimeH: number | null
  totWorkTimeM: number | null
}

export interface Product {
  id: string
  code: string
  name: string
}

export interface ProductVendorResponse {
  values: Array<ProductVendor>
}

export interface CityStateRegionResponse {
  values: Array<CityStateRegion>
}

export interface StateRegionResponse {
  values: Array<StateRegion>
}

export interface WorkShiftResponse {
  values: Array<WorkShift>
}

export interface ProductResponse {
  values: Array<Product>
}
