import makeStyles from '@material-ui/core/styles/makeStyles'
import { styledComponentTheme } from '@/theme/styledComponentTheme'

export const useClasses = makeStyles(({ palette, spacing }) => ({
  sortButton: {
    padding: 0,
    margin: spacing(0.25, 0),
    minWidth: 0,
    color: '#cad5d7',
  },
  sortButtonActive: {
    color: 'white',
    backgroundColor: styledComponentTheme.colors.baseGreen,
    '&:hover': {
      backgroundColor: styledComponentTheme.colors.baseGreen,
    },
    '&:active': {
      backgroundColor: styledComponentTheme.colors.baseGreen,
    },
  },
  disabled: {
    opacity: 0.6,
    '& $sortButton': {
      backgroundColor: '#f9f9f9',
    },
  },
  mr: {
    marginRight: spacing(1),
  },
}))
