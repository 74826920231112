export const exportFile = (exportedFile: any) => {
  const arrayBufferView = new Uint8Array(exportedFile.data)
  const urlCreator = window.URL || window.webkitURL

  if (exportedFile.headers['content-type'] === 'application/pdf') {
    const blob = new Blob([arrayBufferView], { type: 'application/pdf;charset=utf-8' })
    const downloadUrl = urlCreator.createObjectURL(blob)
    window.saveAs(downloadUrl, 'report.pdf')
  }
  if (exportedFile.headers['content-type'] === 'application/vnd.ms-excel') {
    const blob = new Blob([arrayBufferView], {
      type: 'application/vnd.ms-excel;charset=utf-8',
    })
    const downloadUrl = urlCreator.createObjectURL(blob)
    window.saveAs(downloadUrl, 'report.xls')
  }
}
