import { Dialog, DialogContent, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { useParams } from 'react-router-dom'

import CloseButton from '@/components/blocks/CloseButton'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import { TabWrapperTypes } from './types'

const TabModalWrapper: FC<TabWrapperTypes> = React.memo(
  ({ open, onClose, ComponentJSX }: TabWrapperTypes) => {
    const classes = useClasses()
    const translate = useTranslate()
    const { id } = useParams()

    return (
      <Dialog
        disableBackdropClick
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
      >
        <div className={classes.dialogActions}>
          {id && (
            <Typography className={classes.title}>
              {`${id} ${translate('translate#atmeye.admin.settings')}`}
            </Typography>
          )}
          <CloseButton onClick={onClose} className={classes.closeButton} />
        </div>
        <DialogContent style={{ height: '88vh' }}>{ComponentJSX}</DialogContent>
      </Dialog>
    )
  },
)

export { TabModalWrapper }
