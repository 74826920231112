import queryString from 'query-string'

import { AdmEmployee } from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { Sort } from '@/types/cm'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

export class EmployeeAdminApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getAll(sort: Sort, id: string): Promise<AdmEmployee[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/employees',
          query: { id, ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await EmployeeAdminApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(payload: AdmEmployee): Promise<void> {
    try {
      return await EmployeeAdminApi.requestService.put('/admin/employees/update', payload)
    } catch (error) {
      throw Error(error)
    }
  }
}
