import { LocalStorageItems } from '@/constants/localStorageItems'

export const hasValidToken = async (): Promise<boolean> => {
  const token = sessionStorage.getItem(LocalStorageItems.AccessToken)
  const userId = sessionStorage.getItem(LocalStorageItems.UserID)
  return !!(token && userId)
}

export const hasValidTokenCM = async (): Promise<boolean> => {
  const token = localStorage.getItem(LocalStorageItems.AccessTokenCM)
  return !!token
}

export const hasValidTokenVaultV1 = async (): Promise<boolean> => {
  const token = localStorage.getItem(LocalStorageItems.AccessTokenVaultV1)
  return !!token
}
