import React from 'react'

import { useSubscription } from '@/components/blocks/ATMeye/modals/pageModal/hooks/useSubscription'
import { Select } from '@/components/controls/Select'
import { useTranslate } from '@/utils/internalization'

const PublishedPageSelect = ({
  value,
  onChange,
}: {
  value: string
  onChange: (e: any) => void
}) => {
  const translate = useTranslate()

  const { publishSubPages, fetchingPublishedPages } = useSubscription()

  return (
    <div>
      <Select
        isLoading={fetchingPublishedPages}
        label={translate('translate#atmeye.dashboard.enterPageName')}
        placeholder={translate('translate#atmeye.dashboard.enterPageName')}
        fullWidth
        value={value}
        options={publishSubPages || []}
        onChange={onChange}
      />
    </div>
  )
}

export { PublishedPageSelect }
