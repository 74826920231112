import { LocalStorageItems } from '@/constants'

export const COLUMNS = [
  //  {
  //    fieldName: 'deviceId',
  //    fieldLabel: 'atmeye.columns.title.deviceId',
  //    sortField: 'deviceId',
  //  },
  {
    fieldName: 'deviceName',
    fieldLabel: 'atmeye.columns.title.deviceName',
    sortField: 'deviceName',
  },
  {
    fieldName: 'clientId',
    fieldLabel: 'atmeye.columns.title.clientId',
    sortField: 'clientId',
  },
  {
    fieldName: 'processNumber',
    fieldLabel: 'atmeye.columns.title.processNumber',
    sortField: 'processNumber',
  },
  {
    fieldName: 'transactionId',
    fieldLabel: 'atmeye.columns.title.transactionId',
    sortField: 'transactionId',
  },

  {
    fieldName: 'eventTypeName',
    fieldLabel: 'atmeye.columns.title.eventTypeName',
    sortField: 'eventTypeName',
  },
  {
    fieldName: 'cameraNumber',
    fieldLabel: 'atmeye.columns.title.cameraNumber',
    sortField: 'cameraNumber',
  },

  {
    fieldName: 'atmDate',
    fieldLabel: 'atmeye.columns.title.atmDate',
    sortField: 'atmDate',
  },
  {
    fieldName: 'localDate',
    fieldLabel: 'atmeye.columns.title.localDate',
    sortField: 'localDate',
  },
  {
    fieldName: 'note',
    fieldLabel: 'atmeye.columns.title.note',
    sortField: 'note',
  },
  {
    fieldName: 'filePath',
    fieldLabel: 'atmeye.columns.title.filePath',
    sortField: 'filePath',
  },
  {
    fieldName: 'more',
    fieldLabel: 'atmeye.columns.title.more',
    sortField: 'more',
  },
]
