import queryString from 'query-string'

import {
  AdmNetworkNode,
  AdmNetworkNodeBase,
  AdmNodeObject,
  AdmObjectsSimpleFilter,
  ComFilterTreeNode,
} from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

interface Node {
  guid: string
  parentId?: string
}

interface GetObjectsProps {
  filter: AdmObjectsSimpleFilter
  query: Omit<Node & Sort, 'guid'>
}

export class NetworksApi {
  private static requestService = getRequestServiceCM()

  public static async getAll(): Promise<ComFilterTreeNode> {
    try {
      return await NetworksApi.requestService.get('/admin/networks/nodes')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getSingle(payload: Node): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/networks/node',
          query: { ...payload },
        },
        { skipNull: true, encode: false },
      )

      return await NetworksApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(payload: AdmNetworkNodeBase): Promise<void> {
    try {
      return await NetworksApi.requestService.post('/admin/networks/nodes/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(payload: AdmNetworkNode): Promise<void> {
    try {
      return await NetworksApi.requestService.put('/admin/networks/nodes/update', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(payload: Node): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/networks/nodes/delete',
          query: { ...payload },
        },
        { skipNull: true, encode: false },
      )

      return await NetworksApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getObjects({ query, filter }: GetObjectsProps): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/networks/objects',
          query: { ...query },
        },
        { skipNull: true, encode: false },
      )

      return await NetworksApi.requestService.post(url, filter)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async addObjects(payload: AdmNodeObject): Promise<void> {
    try {
      return await NetworksApi.requestService.post('/admin/networks/objects/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async deleteObject(payload: Node): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/networks/objects/delete',
          query: { ...payload },
        },
        { skipNull: true, encode: false },
      )

      return await NetworksApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
