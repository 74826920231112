import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import React, { useCallback, useState } from 'react'
import Helmet from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
// import { history } from '@/Router'
import { useHistory } from 'react-router'
import Test from 'react-test-attributes'

import FlagIcon from '@/components/blocks/FlagIcon'
import { SelectIcon } from '@/components/blocks/SelectIcon'
import { SetLocaleParams } from '@/components/blocks/SetLocale/types'
import { commonLanguages } from '@/internalization'
import { activeLocale, setLocale } from '@/store/internalization'
import { getTestId } from '@/utils'

import { useClasses } from './styles'

const SetLocale = ({
  initialLanguageCode = undefined,
  allLanguages = commonLanguages,
  serverNotifier = undefined,
  isLoginModal = false,
  ...rest
}: SetLocaleParams): React.ReactElement => {
  const [open, setOpen] = useState(false)
  const stateLang = useSelector(activeLocale)
  const active = initialLanguageCode || stateLang
  const classes = useClasses()
  const dispatch = useDispatch()
  const testId = getTestId('set-locale')
  const history = useHistory()

  const handleClose = useCallback((): void => {
    setOpen(false)
  }, [])

  const handleOpen = useCallback((): void => {
    setOpen(true)
  }, [])

  const handleChange = useCallback(
    (value: React.ChangeEvent<{ name?: string; value: any }>): void => {
      dispatch(setLocale({ locale: value.target.value, isLoginModal }))
      if (serverNotifier !== undefined) {
        serverNotifier(value.target.value)
        return
      }
      history.go(0)
    },
    [],
  )

  return (
    <>
      <Helmet htmlAttributes={{ lang: active }} />
      <Test id={testId(0)}>
        <Select
          className={classes.select}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={active}
          IconComponent={SelectIcon}
          onChange={handleChange}
          defaultValue={active}
          {...rest}
        >
          {allLanguages.map(({ label, value, code }) => (
            <MenuItem key={value} value={value}>
              <div className={classes.menuItemWrapper}>
                <div className={classes.iconWrapper}>
                  <FlagIcon code={code} size="lg" className={classes.size} />
                </div>
                <span className={classes.label}>{label}</span>
              </div>
            </MenuItem>
          ))}
        </Select>
      </Test>
    </>
  )
}

export default SetLocale
