export const HotKeysAction = {
  PREV_ROW: 'PREV_ROW',
  NEXT_ROW: 'NEXT_ROW',
  TOGGLE_CHECKBOX: 'TOGGLE_CHECKBOX',
  OPEN_FILTER: 'OPEN_FILTER',
  OPEN_PRINT_DIALOG: 'OPEN_PRINT_DIALOG',
  REFRESH: 'REFRESH',
  SELECT_ROW: 'SELECT_ROW',
}

export const hotKeysMap = {
  [HotKeysAction.PREV_ROW]: 'ArrowUp',
  [HotKeysAction.NEXT_ROW]: 'ArrowDown',
  [HotKeysAction.TOGGLE_CHECKBOX]: 'Space',
  [HotKeysAction.OPEN_FILTER]: ['f', 'F'],
  [HotKeysAction.OPEN_PRINT_DIALOG]: ['p', 'P'],
  [HotKeysAction.REFRESH]: ['r', 'R'],
  [HotKeysAction.SELECT_ROW]: 'Enter',
}
