import { AnyAction } from 'redux'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { DropDownInputsApi } from '@/api/common/dropDownInputsApi'
import { CompaniesApi } from '@/api/companies/companiesApi'
import { ServiceCompany } from '@/api/companies/companiesApi/types'
import { companiesActions } from '@/constants/actions'
import { Unit } from '@/types'
import { PopUpService } from '@/utils/services/popUpService'

import {
  findUnitsResponse,
  findUnitsResponseFail,
  getCompanyTypesFail,
  getCompanyTypesResponse,
} from './actions'

function* findUnits({ payload: { str, byLuno } }: AnyAction): Generator<unknown, void, Unit[]> {
  try {
    const response = yield call(DropDownInputsApi.findDevices, str, byLuno)
    yield put(findUnitsResponse(response))
  } catch (error) {
    yield put(findUnitsResponseFail(error.message))
  }
}

function* findCompanyTypes({
  payload: { deviceId },
}: AnyAction): Generator<unknown, void, ServiceCompany[]> {
  try {
    const response = yield call(CompaniesApi.getCompanyTypes, deviceId)

    yield put(getCompanyTypesResponse(response))
  } catch (error) {
    yield put(getCompanyTypesFail(error.message))
  }
}

export default function*(): Generator {
  yield takeLatest(companiesActions.FindUnitsRequest, findUnits)
  yield takeLatest(companiesActions.GetCompanyTypeRequest, findCompanyTypes)
}
