import { FC, ReactElement } from 'react'
import { AnyAction } from 'redux'

import { GetTicketsBodyFilterItem } from '@/api/sd/ticketsApi/types'
import { SortingTypes } from '@/components/NPMPakage/hooks/useSorting/types'
import { AppState } from '@/store/reducers'
import { Pagination } from '@/types'
import { ParentTypesItem, Row } from '@/types/atmeye/TableTypes'

export type ColumnsDeviceTypes = {
  fieldName: string
  fieldLabel: ReactElement | string
  sortField?: string
}

export type Props = {
  isCheckboxTable?: boolean
  isHeaderCheckboxSelected?: boolean
  isHeaderCheckboxDisabled?: boolean
  isFetching?: boolean
  isCutRow?: boolean
  isFlatTable?: boolean
  isLoadingPagination?: boolean
  isAlwaysWithControls?: boolean
  isOnlyDirectionPagination?: boolean
  keyLabel?: string

  className?: string
  sorting?: SortingTypes
  multiSelectedRows?: Array<number | string>
  rowIdKey?: string
  hiddenSortIcons?: Array<string>
  selectedRowId: number | string | null
  columns: Array<ColumnsDeviceTypes>
  data: Array<any>
  pagination?: Pagination
  controls?: ReactElement | null
  iconCellFields?: Array<string>
  CellJSX: FC<CellJSXPropsComponent>
  collapse?: string

  setCollapse?: (collapse: string) => void
  setColumns?: (newData: Array<ColumnsDeviceTypes>) => void
  onRowClick?: (data: Row, isCtrlKey?: boolean, index?: number) => void
  changePageSize?: (size: number) => void
  changeCurrentPage?: (page: number) => void
  handleDoubleClickRow?: (row: any) => void
  onSort?: any
  onHeaderCheckboxClick?: () => void
  getUpdateRequest?: UpdateRequestType
  updateData?: () => void
}

export type useParamRequestTypesTypes = {
  filter: Array<GetTicketsBodyFilterItem>
  sortDetails: Array<{ fieldName: string; sortOrder: string }>
  formUID: string
  getOutDetailsURL: string
  parentGroups?: null | Array<any>
  multipleParentGroups?: null | Array<ParentTypesItem>
  pageNo: number
  pageSize: number
}

export type useParamTypes = Omit<useParamRequestTypesTypes, 'pageNo' | 'pageSize'>

export type UpdateRequestType = {
  clearAction?: (...ars: any) => AnyAction
  updateAction?: (...args: any) => AnyAction
  getMediaSelector?: (state: AppState) => any
  getUpdateTableRequest?: (...ars: any) => any
  reactionToAlarms?: (...ars: any) => any
  editTableCellRequest?: (...ars: any) => AnyAction
  setChangeTableData?: (...ars: any) => AnyAction
  saveTableCell?: (...ars: any) => any
  mutateQueryTable?: (...ars: any) => any
  refreshTable?: () => void
}

export type CellJSXPropsComponent = {
  fieldName: string
  collapse?: string
  index: number
  parentIndex?: number
  indexColumn: number

  row: any
  item: any
  iconCellFields?: Array<string>
  children?: never

  getUpdateRequest?: UpdateRequestType
  setCollapse?: (collapse: string) => void
  updateData?: () => void
  sorting?: SortingTypes
}

export const defaultSortHiddenIcons = {
  common: ['technical_events_count', 'alarms_count'],
  transactions: ['camera_number', 'event_type_name'],
  operationLogs: ['days', 'status', 'devices', 'affectedObjects', 'resultFilesIds'],
  devices: ['transactions_count'],
  externalNotifications: ['terminalId', 'recipients', 'eventType'],
}
