import { MouseEvent, useState } from 'react'

const useColumnsMenu = () => {
  const [columnsMenuAnchorEl, setColumnsMenuAnchorEl] = useState<
    null | (EventTarget & HTMLButtonElement) | (EventTarget & HTMLDivElement)
  >(null)

  return { columnsMenuAnchorEl, setColumnsMenuAnchorEl }
}

export { useColumnsMenu }
