import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  input: {
    backgroundColor: 'white',
    margin: spacing(2, 0),
  },

  noMargin: {
    margin: 0,
  },

  noMarginWithLabel: {
    margin: 0,
    marginTop: spacing(1),
  },

  inputWithError: {
    marginBottom: 0,
  },

  hidden: {
    display: 'none',
  },

  labelDisabled: {
    display: 'inline',
    opacity: 0.6,
  },

  fontInput: {
    fontFamily: 'Noto Sans, sans-serif ',
  },

  errorLabel: {
    height: spacing(2.5),
    fontSize: spacing(1.5),
    paddingLeft: spacing(2),
    color: palette.error.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  label: {
    transform: 'translate(14px, -50%) scale(0.75) !important',
  },
}))
