export enum AtmeyeDeviceSettingsActions {
  GetDevicesRequest = '@/DEVICE_SETTINGS/GET_DEVICES_REQUEST',
  ChangeSelectedFilters = '@/DEVICE_SETTINGS/CHANGE_SELECTED_FILTERS',
  GetFiltersTemplatesRequest = '@/DEVICE_SETTINGS/GET_FILTERS_TEMPLATES_REQUEST',
  SetFilterTemplatesResponse = '@/DEVICE_SETTINGS/SET_FILTER_TEMPLATE_RESPONSE',
  CreateFilterTemplateRequest = '@/DEVICE_SETTINGS/CREATE_FILTER_TEMPLATE_REQUEST',
  UpdateFilterTemplateRequest = '@/DEVICE_SETTINGS/UPDATE_FILTER_TEMPLATE_REQUEST',
  DeleteFilterTemplateRequest = '@/DEVICE_SETTINGS/DELETE_FILTER_TEMPLATE_REQUEST',
  GetGroupsTemplatesRequest = '@/DEVICE_SETTINGS/GET_GROUPS_TEMPLATE_REQUEST',
  EditGroupTemplateRequest = '@/DEVICE_SETTINGS/EDIT_GROUP_TEMPLATE_REQUEST',
  DeleteGroupTemplateRequest = '@/DEVICE_SETTINGS/DELETE_GROUP_TEMPLATE_REQUEST',
  SaveGroupingTemplateRequest = '@/DEVICE_SETTINGS/SAVE_GROUPING_TEMPLATE_REQUEST',
  GetDevicesConfigRequest = '@/DEVICE_SETTINGS/GET_DEVICES_CONFIG_REQUEST',
  GetDevicesConfigResponse = '@/DEVICE_SETTINGS/GET_DEVICES_CONFIG_RESPONSE',
  SetDataPagination = '@/DEVICE_SETTINGS/SET_DATA_PAGINATION',
  ChangeGrouping = '@/DEVICE_SETTINGS/CHANGE_GROUPING',
  GetTicketResponse = '@/DEVICE_SETTINGS/GET_TICKET_RESPONSE',
  GetTicketResponseFail = '@/DEVICE_SETTINGS/GET_TICKET_RESPONSE_FAIL',
  ChangeSorting = '@/DEVICE_SETTINGS/CHANGE_SORTING',
  SetPage = '@/DEVICE_SETTINGS/SET_PAGE',
  SetSelectedGroupingTemplate = '@/DEVICE_SETTINGS/SET_SELECTED_GROUPING_TEMPLATE',
}
