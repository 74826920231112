import queryString from 'query-string'

import {
  AdmCashLoadCalculationMethod,
  AdmCurrencyAndDenomination,
  AdmObjectBase,
  AdmObjectsFilter,
  AdmObjectsModel,
  AdmObjectsReportsRequest,
  AdmObjectsResponse,
  AdmObjectsVendor,
  CitBalCashPointGetAccountsResponse,
  ComCashPointAccount,
  ComCMReport,
  ComCurrency,
  ComObjectCassetteBase,
} from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

interface GetProps {
  paging: { page: number; pageSize: number }
  sort: Sort
  requestBody: AdmObjectsFilter
}

interface UpdateAccounts {
  cpId: string
  accounts: ComCashPointAccount[]
}

export class ObjectsApi {
  private static requestService = getRequestServiceCM()

  public static async get({ paging, sort, requestBody }: GetProps): Promise<AdmObjectsResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/objects',
          query: {
            pageSize: `${paging.pageSize}`,
            page: `${paging.page}`,
            ...sort,
          },
        },
        { skipNull: true, encode: false },
      )
      return await ObjectsApi.requestService.post(url, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async add(object: AdmObjectBase): Promise<void> {
    try {
      return await ObjectsApi.requestService.post('/admin/objects/add', object)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async update(object: AdmObjectBase): Promise<void> {
    try {
      return await ObjectsApi.requestService.put('/admin/objects/update', object)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async delete(guid: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/objects/delete',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )
      return await ObjectsApi.requestService.delete(url)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async createFirstSession(guid: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/objects/createFirstSession',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )
      return await ObjectsApi.requestService.post(url, {})
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async getReports(payload: AdmObjectsReportsRequest): Promise<ComCMReport[]> {
    try {
      return await ObjectsApi.requestService.post('/admin/objects/reports', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getAccounts(id: string): Promise<CitBalCashPointGetAccountsResponse> {
    try {
      const url = queryString.stringifyUrl({
        url: '/admin/objects/getAccounts',
        query: { id },
      })

      return await ObjectsApi.requestService.get(url)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async updateAccounts(requestBody: UpdateAccounts): Promise<void> {
    try {
      return await ObjectsApi.requestService.post('/admin/objects/setAccounts', requestBody)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async getCassettesConfig(guid: string): Promise<ComObjectCassetteBase[]> {
    try {
      return await ObjectsApi.requestService.get(`/admin/object/cassettes`, { guid })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setCassettesConfig(
    guid: string,
    cassettes: ComObjectCassetteBase,
  ): Promise<void> {
    try {
      return await ObjectsApi.requestService.post(`/admin/object/modifyCassettes?guid=${guid}`, {
        cassetes: cassettes,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getDenomination(payload: { objectId: string }): Promise<ComCurrency[]> {
    try {
      return await ObjectsApi.requestService.post(
        '/admin/kiosks/currenciesAndDenominations',
        payload,
      )
    } catch (error) {
      throw Error(error)
    }
  }

  public static async setDenomination(requestBody: AdmCurrencyAndDenomination): Promise<void> {
    try {
      return await ObjectsApi.requestService.put(
        '/admin/kiosks/currenciesAndDenominations/update',
        requestBody,
      )
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getCalcMethod(cashPointId: string): Promise<AdmCashLoadCalculationMethod[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/cashLoadSumCalculationMethod',
          query: { cashPointId },
        },
        { skipNull: true, encode: false },
      )

      return await ObjectsApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async setCalcMethod(methods: AdmCashLoadCalculationMethod[]): Promise<void> {
    try {
      return await ObjectsApi.requestService.put(
        '/admin/cashLoadSumCalculationMethod/update',
        methods,
      )
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getVendors(): Promise<AdmObjectsVendor[]> {
    try {
      return await ObjectsApi.requestService.get('/admin/objects/vendors')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getModels(): Promise<AdmObjectsModel[]> {
    try {
      return await ObjectsApi.requestService.get('/admin/objects/models')
    } catch (e) {
      throw new Error(e)
    }
  }
}
