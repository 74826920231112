import { Popover } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { isEqual } from 'lodash-es'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { GroupingTemplate } from '@/api/atmeye/blackListApi/types'
import Edit from '@/assets/sd/edit.svg'
import Save from '@/assets/sd/save.svg'
import CloseButton from '@/components/blocks/CloseButton'
import Button from '@/components/controls/Button'
import { Select } from '@/components/controls/Select/component'
import TemplateModal from '@/components/NPMPakage/components/modals/TemplateModal'
import { useTranslate } from '@/utils/internalization'

import { GroupRow } from './components/GroupRow/component'
import { TableGroup } from './components/SortBlock/types'
import { useGrouping } from './hooks/useGrouping'
import { useGroupingTemplates } from './hooks/useGroupingTemplates'
import { useClasses } from './styles'
import { MODAL_TYPE, Props } from './types'

const createEmptyGroups = (length: number): Array<undefined> => new Array(length).fill(undefined)
const GroupingModal = React.memo(
  ({
    open,
    defaultGrouping,
    appliedGrouping,
    appliedGroupingTemplate,
    groupsList = [],
    groupingLevel = 4,
    groupingTemplatesType,
    handleApplyGroupingTemplate,
    onApply,
    onClose,
    onClear,
  }: Props): React.ReactElement => {
    const classes = useClasses()
    const translate = useTranslate()

    const {
      isClearButtonDisabled,
      isApplyButtonDisabled,

      handleGroupChange,
      handleGroupClear: handleGroupClearr,
      getFieldOptions,
      groups,
      handleSetGroups,
    } = useGrouping({
      defaultGrouping,
      appliedGrouping,
      groupsList,
    })

    const onlySelectedGroups = groups.filter((group: TableGroup) => !!group)

    const {
      selectedTemplate,
      templatesOptions,
      isLoadingTemplates,
      handleSaveTemplate,
      handleEditCurrentTemplate,
      handleDeleteTemplate,
      onChangeSelectedTemplate,
      modalType,
      anchorEl,
      showModal,
      handleOpenPopover,
      handleClosePopover,
      templates,
    } = useGroupingTemplates({
      groups: onlySelectedGroups,
      setGroups: handleSetGroups,
      // groupingLevel,
      appliedGroupingTemplate,
      groupingTemplatesType,
      defaultGrouping,
    })

    const isTemplateUnsavedChanges = !isEqual(onlySelectedGroups, selectedTemplate?.json)

    const handleGroupApply = (): void => {
      const template = isTemplateUnsavedChanges ? null : selectedTemplate
      handleApplyGroupingTemplate(template)
      onApply(groups)
      onClose()
    }

    const onModalClose = (): void => {
      const appliedTplUpdatedGrouping = templates.find(
        ({ id }: GroupingTemplate) => id === appliedGroupingTemplate?.id,
      )?.json
      const shouldResetTemplate =
        (appliedTplUpdatedGrouping && !isEqual(appliedGrouping, appliedTplUpdatedGrouping)) ||
        !appliedTplUpdatedGrouping

      if (shouldResetTemplate) {
        handleApplyGroupingTemplate(null)
      }

      onClose()
    }

    const handleGroupClear = (): void => {
      onChangeSelectedTemplate('')
      handleGroupClearr()
    }

    return (
      <Dialog maxWidth={false} open={open} keepMounted onClose={onModalClose} disableBackdropClick>
        <div className={classes.wrapper}>
          <CloseButton absolute className={classes.closeButton} onClick={onModalClose} />

          {modalType === MODAL_TYPE.MAIN && (
            <>
              <Typography className={classes.header} variant="h5">
                <FormattedMessage id="table.header.button.grouping" defaultMessage="Grouping" />
              </Typography>

              <>
                {[...groups, ...createEmptyGroups(groupingLevel - groups.length)].map(
                  (group: TableGroup, index: number) => (
                    <GroupRow
                      key={group?.groupingField || index}
                      disabled={index !== 0 && !groups[index - 1] && !groups?.[index]}
                      label={translate(
                        `translate#title.${index > 0 ? 'thenGroupingBy' : 'groupingBy'}`,
                      )}
                      group={group}
                      groupsOptions={getFieldOptions(index)}
                      setGroup={handleGroupChange(index)}
                      hideEmptyItem={index === 0 && !!defaultGrouping?.length}
                    />
                  ),
                )}

                <Typography variant="h5" className={classes.savedGroupings}>
                  <FormattedMessage id="title.savedGroupings" defaultMessage="Saved Groupings" />
                </Typography>

                <Select
                  isLoading={isLoadingTemplates}
                  className={classes.selectButton}
                  label={translate('translate#title.template')}
                  value={selectedTemplate?.id || ''}
                  options={templatesOptions}
                  onChange={e => {
                    onChangeSelectedTemplate(e.target.value)
                  }}
                  neverDisable
                  withoutCheckerForSingleOption
                />

                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item>
                    <Button
                      width="xs"
                      disabled={!selectedTemplate}
                      className={classes.iconButton}
                      onClick={(): void => showModal(MODAL_TYPE.EDIT)}
                      variant="outlined"
                    >
                      <img src={Edit} />
                    </Button>

                    <span>
                      <Button
                        disabled={isClearButtonDisabled}
                        width="xs"
                        className={classes.iconButton}
                        style={{
                          backgroundColor:
                            !selectedTemplate || !isTemplateUnsavedChanges ? '' : 'moccasin',
                        }}
                        onClick={handleOpenPopover}
                        variant="outlined"
                      >
                        <img src={Save} />
                      </Button>

                      <Popover
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        classes={{
                          paper: classes.popoverPaper,
                        }}
                        onClose={handleClosePopover}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <Button
                          className={classes.popoverButton}
                          onClick={(): void => {
                            selectedTemplate
                              ? handleEditCurrentTemplate(selectedTemplate.name)()
                              : showModal(MODAL_TYPE.SAVE)
                          }}
                        >
                          {translate('translate#table.header.button.save')}
                        </Button>

                        <Button
                          className={classes.popoverButton}
                          onClick={(): void => showModal(MODAL_TYPE.SAVE)}
                        >
                          {translate('translate#table.header.button.saveAs')}
                        </Button>
                      </Popover>
                    </span>
                  </Grid>

                  <Grid item>
                    <Button
                      disabled={isClearButtonDisabled}
                      className={classes.clearButton}
                      onClick={handleGroupClear}
                      variant="contained"
                    >
                      {translate(
                        defaultGrouping?.length
                          ? 'translate#atmeye.action.default'
                          : 'translate#action.clear',
                      )}
                    </Button>

                    <Button
                      disabled={isApplyButtonDisabled}
                      className={classes.applyButton}
                      onClick={handleGroupApply}
                      variant="contained"
                    >
                      <FormattedMessage id="action.apply" defaultMessage="Apply" />
                    </Button>
                  </Grid>
                </Grid>
              </>
            </>
          )}

          {(modalType === MODAL_TYPE.EDIT || modalType === MODAL_TYPE.SAVE) && (
            <TemplateModal
              templateName={selectedTemplate?.name || ''}
              modalType={modalType}
              onEditTemplate={
                modalType === MODAL_TYPE.EDIT ? handleEditCurrentTemplate : handleSaveTemplate
              }
              onClose={(): void => showModal(MODAL_TYPE.MAIN)}
              onDeleteTemplate={handleDeleteTemplate}
            />
          )}
        </div>
      </Dialog>
    )
  },
)

export { GroupingModal }
