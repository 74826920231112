import faker from 'faker'
import { GroupingTemplate, TicketFields } from '@/types'

const generateGroupingTemplate = (): GroupingTemplate => ({
  id: faker.random.uuid(),
  name: faker.random.word(),
  grouping: [
    {
      key: faker.helpers.randomize([
        TicketFields.Identifier,
        TicketFields.Status,
        TicketFields.DeviceType,
      ]),
      orderBy: faker.helpers.randomize(['name', 'count']),
      order: faker.helpers.randomize(['asc', 'desc']),
    },
    {
      key: faker.helpers.randomize([
        TicketFields.Identifier,
        TicketFields.Status,
        TicketFields.DeviceType,
      ]),
      orderBy: faker.helpers.randomize(['name', 'count']),
      order: faker.helpers.randomize(['asc', 'desc']),
    },
  ],
})

const groupings: GroupingTemplate[] = [
  generateGroupingTemplate(),
  generateGroupingTemplate(),
  generateGroupingTemplate(),
  generateGroupingTemplate(),
  generateGroupingTemplate(),
  generateGroupingTemplate(),
]

export default {
  groupings,
}
