import { LocalStorageItems } from '@/constants'

import { Strategy } from './types'

interface StrategyContextI<T> {
  getData: (key: string) => void
  setData: (key: string, value?: T) => void
  removeData: (key: LocalStorageItems) => void
}

export class StrategyContext<T> implements StrategyContextI<T> {
  private strategy: Strategy

  constructor(strategy: Strategy) {
    this.strategy = strategy
  }

  getData(key: string): any {
    return this.strategy.get(key)
  }

  setData(key: string, value?: T): any {
    return this.strategy.set(key, value)
  }

  removeData(key: LocalStorageItems): void {
    this.strategy.remove(key)
  }
}
