import makeStyles from '@material-ui/core/styles/makeStyles'
interface Props {
  width?: string
}

export const useClasses = makeStyles(({ spacing }) => ({
  title: {
    fontWeight: 'bold',
    marginBottom: spacing(1),
  },
  checkbox: {
    padding: 0,
    paddingRight: spacing(1),
  },
  row: {
    marginBottom: spacing(0.5),
  },
  asterisk: {
    color: 'red',
    display: 'inline',
    marginLeft: spacing(0.2),
  },

  widthBlock: {
    width: (props: Props) => props.width,
  },

  wrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))
