import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    gap: '8px',
    marginRight: '10px',
  },

  card: {
    width: '100%',
    backgroundColor: (props: { color: string }) => props.color,
    padding: '8px 12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },

  cardTypo: {
    color: 'white',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    cursor: 'pointer',
  },

  deviceName: {
    marginRight: '12px',
  },

  eventName: {
    fontWeight: 400,
  },

  icon: {
    zIndex: 1000,
    color: 'white',
    cursor: 'pointer',
    fontSize: '12px',
    position: 'absolute',
  },

  crossIcon: {
    right: 8,
    top: 8,
  },

  arrowIcon: {
    right: 8,
    bottom: 8,
  },
})
