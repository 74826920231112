export enum GrantedOperations {
  REQUEST_VIEW = 'REQUEST_VIEW',
  REQUEST_INSERT = 'REQUEST_INSERT',
  REQUEST_UPDATE = 'REQUEST_UPDATE',
  REQUEST_PARTIAL_UPDATE = 'REQUEST_PARTIAL_UPDATE',
  REQUEST_DELETE = 'REQUEST_DELETE',
  REQUEST_SEND = 'REQUEST_SEND',
  REQUEST_GENERATE = 'REQUEST_GENERATE',
  REQUEST_REJECT = 'REQUEST_REJECT',
  REQUEST_CLOSE = 'REQUEST_CLOSE',
  REQUEST_INSERT_ITEM = 'REQUEST_INSERT_ITEM',
  REQUEST_UPDATE_ITEM = 'REQUEST_UPDATE_ITEM',
  REQUEST_DELETE_ITEM = 'REQUEST_DELETE_ITEM',
}
