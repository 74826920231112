import { createStyles, makeStyles } from '@material-ui/core/styles'

import { styledComponentTheme } from '@/theme'
export const useClasses = makeStyles(({ palette, spacing }) =>
  createStyles({
    headerCellWrapper: {
      fontWeight: 'bold',
    },
    widthOfCellDetailsTickets: {
      width: '55%',
    },
    headerCellWrapperSort: {
      flexDirection: 'row-reverse',
    },
    headerCellContent: {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    cellText: {
      whiteSpace: 'nowrap',
    },
    cellWrap: {
      wordBreak: 'break-word',
      whiteSpace: 'initial',
    },
    sortIcon: {
      visibility: 'hidden',
    },
    disabledSortIcon: {
      '& svg': {
        display: 'none',
      },
    },
    activeFilterIcon: {
      '&::after': {
        content: '""',
        position: 'absolute',
        right: spacing(0.5),
        top: spacing(1),
        width: '20%',
        height: '20%',
        borderRadius: '50%',
        backgroundColor: palette.primary.main,
      },
    },
    checkboxCell: {
      width: spacing(10),
    },
    cellPsr: {
      minWidth: '92px',
      maxWidth: '92px',
      padding: '5px',
    },
    slaItemLegendCircle: {
      position: 'absolute',
      left: '-14px',
      width: '8px',
      height: '8px',
      display: 'block',
      borderRadius: '50%',
    },
    withTooltip: {
      overflow: 'hidden',
      fontSize: styledComponentTheme.fontSizes.small,
    },
    contentItemTextWithTooltip: {
      display: 'block',
    },
  }),
)
