import { Grid, TableContainer, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import GoogleMapReact from 'google-map-react'
import moment from 'moment'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { Device } from '@/api/atmeye/devicesApi/types'
import { TeltonikaApi } from '@/api/atmeye/teltonikaApi'
import { Point } from '@/api/atmeye/teltonikaApi/types'
import CloseButton from '@/components/blocks/CloseButton'
import Loader from '@/components/blocks/Loader'
import { Button } from '@/components/controls/Button/component'
import { DateTimeInput } from '@/components/controls/DateTimeInput'
import { INITIAL_LOCALE } from '@/constants'
import { ATMEYE_MAP_CONSTANTS, MAP_CONSTANTS } from '@/constants/map'
import { DEFAULT_DATE_TIME_FORMAT } from '@/constants/time'
import { activeLocale } from '@/store/internalization'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'

// todo change marker
const PointMarker: FC<{ lat: number; lng: number; style?: Record<string, string | number> }> = ({
  style,
}) => (
  <div
    style={{
      width: 20,
      height: 20,
      backgroundColor: 'blue',
      borderRadius: '50%',
      marginLeft: '50%',
      marginTop: '50%',
      ...style,
    }}
  />
)

const TraceRouteModal = ({
  onClose,
  currentDevice,
}: {
  onClose: () => void
  currentDevice: Device | null
}): ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  const locale = useSelector(activeLocale)

  const [route, setRoute] = useState<Point[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'd'))
  const [toDate, setToDate] = useState(moment())
  const [zoom, setZoom] = useState(MAP_CONSTANTS.defaultZoom)

  const getRoute = async () => {
    if (toDate && fromDate && currentDevice?.deviceId) {
      setIsLoading(true)
      const data = await TeltonikaApi.getTeltonikaDeviceRoute({
        deviceId: currentDevice?.deviceId,
        deviceDateTimeFilter: {
          greaterThanOrEqual: moment(fromDate, DEFAULT_DATE_TIME_FORMAT).format(),
          lessThanOrEqual: moment(toDate, DEFAULT_DATE_TIME_FORMAT).format(),
        },
      })

      setRoute(data?.points)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getRoute()
  }, [toDate, fromDate])

  useEffect(() => {
    if (toDate && moment(toDate).isBefore(fromDate)) {
      setToDate(moment(fromDate))
    }
  }, [fromDate])

  const hslMultipler = 70 / (route?.length || 1)

  return (
    <Dialog maxWidth="xl" onClose={onClose} disableBackdropClick open>
      <CloseButton absolute className={classes.closeButton} onClick={onClose} />

      {isLoading && <Loader />}

      <div className={classes.wrapper}>
        <Grid className={classes.buttonWrapper} container justify="flex-end">
          <Grid container>
            <Grid item md={6}>
              <Typography className={classes.modalHeader} variant="h5">
                <FormattedMessage id="atmeye.title.traceRoute" />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <TableContainer className={classes.tableWrapper}>
          <Grid spacing={2} className={classes.gridContainer} container>
            <Grid item md={12}>
              <Typography className={classes.header} variant="body1">
                <FormattedMessage id="atmeye.title.selectPeriod" />
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <DateTimeInput
                ampm={false}
                format={DEFAULT_DATE_TIME_FORMAT}
                className={classes.field}
                label={translate('translate#title.from')}
                onChange={date => {
                  setFromDate(date)
                }}
                value={fromDate}
                disableFuture
              />
            </Grid>

            <Grid item xs={6}>
              <DateTimeInput
                ampm={false}
                format={DEFAULT_DATE_TIME_FORMAT}
                className={classes.field}
                onChange={date => {
                  setToDate(date)
                }}
                label={translate('translate#title.to')}
                disabled={!fromDate}
                value={toDate}
                minDate={fromDate || undefined}
                disableFuture
              />
            </Grid>
          </Grid>

          <div className={classes.mapWrapper}>
            <GoogleMapReact
              resetBoundsOnResize
              yesIWantToUseGoogleMapApiInternals
              bootstrapURLKeys={{
                key: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
                language: locale || INITIAL_LOCALE,
                region: locale || INITIAL_LOCALE,
              }}
              defaultZoom={zoom}
              defaultCenter={{
                lat: +(currentDevice?.latitude || 0),
                lng: +(currentDevice?.longitude || 0),
              }}
              options={MAP_CONSTANTS.mapOptions}
              onChange={val => {
                setZoom(val.zoom)
              }}
            >
              {route.map(({ latitude, longitude }, i) => {
                const style =
                  i === 0
                    ? {
                        backgroundColor: `hsl(${190 + i * hslMultipler}deg 100% 50%)`,
                        zIndex: 999,
                        border: '2px solid black',
                      }
                    : { backgroundColor: `hsl(${190 + i * hslMultipler}deg 100% 50%)` }

                return (
                  <PointMarker
                    key={`${latitude}${longitude}${i}`}
                    lat={latitude}
                    lng={longitude}
                    style={style as Record<string, string | number>}
                  />
                )
              })}
            </GoogleMapReact>
          </div>
        </TableContainer>

        <div className={classes.modalFooter}>
          <Button
            variant="outlined"
            color="default"
            className={classes.cancelBtn}
            onClick={onClose}
          >
            {translate('translate#action.close')}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default TraceRouteModal
