import { makeStyles } from '@material-ui/core'

export const useClasses = makeStyles({
  paper: {
    padding: 20,
    userSelect: 'none',
    pointerEvents: 'none',
    cursor: 'wait',
    textAlign: 'center',
  },

  title: {
    marginBottom: '16px',
  },
})
