import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  subhead: {
    fontSize: '18px',
  },
  description: {
    fontSize: '14px',
    color: '#929A9B',
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '40px',
  },
  content: {
    fontSize: '14px',
    textAlign: 'right',
  },
  button: {
    flex: '100',
  },
}))
