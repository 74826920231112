import React, { ReactElement } from 'react'
import clsx from 'clsx'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { useClasses } from './styles'
import { Props } from './types'

const RadioButton = ({
  value,
  label,
  className: radioClass,
  disabled,
  checked,
  isItemChecked,
  onChange,
  classPadding,
}: Props): ReactElement => {
  const classes = useClasses()

  return (
    <FormControlLabel
      value={value}
      control={<Radio color="primary" classes={{ root: classes.root }} className={classes.radio} />}
      label={label}
      className={clsx(classes.radio, radioClass)}
      classes={{ label: classes.label, root: classPadding }}
      disabled={disabled}
      checked={checked || isItemChecked}
      onChange={onChange}
    />
  )
}

export default RadioButton
