export enum UserInformationColumns {
  Id = 'userId',
  Company = 'company',
  UserName = 'userName',
  UserGroup = 'userGroup',
  Email = 'email',
  ExpireDate = 'expireDate',
  Disabled = 'disabled',
  UserGroupId = 'userGroupId',
  CompanyId = 'companyId',
  CompanyPersonnelId = 'companyPersonnelId',
  AllowAutoProlong = 'allowAutoProlong',
  RoleId = 'roleId',
  RoleName = 'roleName',
}

export type UserInformationTableTypes = {
  [UserInformationColumns.Id]: string
  [UserInformationColumns.Company]: string
  [UserInformationColumns.CompanyId]: string
  [UserInformationColumns.UserName]: string
  [UserInformationColumns.UserGroup]: string
  [UserInformationColumns.UserGroupId]: string
  [UserInformationColumns.Email]: string
  [UserInformationColumns.ExpireDate]: string
  [UserInformationColumns.Disabled]: boolean
  [UserInformationColumns.CompanyPersonnelId]: number | null
  [UserInformationColumns.AllowAutoProlong]: boolean
  [UserInformationColumns.RoleId]: string
  [UserInformationColumns.RoleName]: string
}

export const headersUserInformationTable = [
  {
    name: UserInformationColumns.Company,
    label: UserInformationColumns.Company,
  },
  // {
  //   name: UserInformationColumns.UserName,
  //   label: UserInformationColumns.UserName,
  // },
  {
    name: UserInformationColumns.UserGroup,
    label: UserInformationColumns.UserGroup,
  },
  {
    name: UserInformationColumns.Email,
    label: UserInformationColumns.Email,
  },
  {
    name: UserInformationColumns.ExpireDate,
    label: UserInformationColumns.ExpireDate,
  },
  {
    name: UserInformationColumns.Disabled,
    label: UserInformationColumns.Disabled,
  },
  {
    name: UserInformationColumns.AllowAutoProlong,
    label: UserInformationColumns.AllowAutoProlong,
  },
]
