import moment from 'moment'

import { FilterField } from '@/api/atmeye/commonTypes'
import { Option } from '@/components/controls/MultipleSelectAtemeye/types'

export const dateRangeConfig = ([dateFrom, dateTo]: string[]): FilterField =>
  dateFrom || dateTo
    ? {
        greaterThanOrEqual: dateFrom ? moment(dateFrom).format('YYYY-MM-DD') : undefined,
        lessThanOrEqual: dateTo ? moment(dateTo).format('YYYY-MM-DD') : undefined,
      }
    : undefined

export const dateTimeRangeConfig = ([dateFrom, dateTo]: string[]): FilterField =>
  dateFrom || dateTo
    ? {
        greaterThanOrEqual: dateFrom ? moment(dateFrom).format() : undefined,
        lessThanOrEqual: dateTo ? moment(dateTo).format() : undefined,
      }
    : undefined

export const selectConfig = (selected: string): FilterField =>
  selected
    ? {
        equals: selected,
      }
    : undefined

export const multiSelectConfig = (selected: Option[]): FilterField =>
  selected.length
    ? {
        in: selected?.map(option => option?.valueId),
      }
    : undefined

export const transformFilters = (
  filters: Record<string, any>,
  config?: Record<string, (...props: any) => FilterField>,
): Record<string, FilterField> => {
  const res = Object.keys(filters).reduce((transformedFilter, filterField: string) => {
    if (!filters[filterField]) return transformedFilter

    const fieldConfig = config?.[filterField]

    if (fieldConfig) {
      transformedFilter[filterField] = fieldConfig(filters[filterField])
    } else {
      transformedFilter[filterField] = { contains: filters[filterField] }
    }

    return transformedFilter
  }, {} as Record<string, FilterField>)

  return res
}
