import makeStyles from '@material-ui/core/styles/makeStyles'

import colors from '@/theme/colors'

type Props = {
  overflow?: string
  fieldWidth?: number
}
export const useClasses = makeStyles(({ spacing, mixins }) => ({
  root: ({ fieldWidth }: Props) => ({
    width: `${fieldWidth}px`,
  }),
  marginContainer: {
    top: -20,
  },
  select: {
    width: '100%',
    margin: spacing(2, 0),
  },
  noOptions: {
    padding: spacing(2, 3),
  },
  loader: {
    flex: 1,
  },
  placeholderOption: {
    color: '#999',
  },
  normalOption: {
    color: 'black',
    textTransform: 'inherit',
  },
  additionalOption: {
    color: colors.grey200,
    textTransform: 'inherit',
  },
  headerItem: {
    opacity: 1,
  },
  headerText: {
    textTransform: 'capitalize',
    marginBottom: 0,
  },
  disabled: {
    opacity: 0.6,
    pointerEvents: 'none',
  },

  buttonWrapper: {
    display: 'flex',
    ...mixins.gutters(),
    marginTop: spacing(1),
  },
  button: {
    flex: 1,
  },
  cancelButton: {
    color: '#50a8ae',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: '1px solid rgba(80, 168, 174, 0.5)',
    marginRight: spacing(1),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menuList: ({ fieldWidth }: Props) => ({
    maxWidth: `${fieldWidth}px`,
    maxHeight: spacing(40),
    overflow: 'auto',
  }),
  menuListItem: {
    maxHeight: spacing(35),
    overflow: (props: Props) => props.overflow,
  },
  blockSelectAllWrapper: {
    padding: spacing(1.3, 2),
    fontSize: spacing(2),
    cursor: 'pointer',
  },
}))
