import React, { ReactElement, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import FolderIcon from '@material-ui/icons/Folder'
import Collapse from '@/components/blocks/Collapse'
import InfinityScrollTable from '@/components/controls/InfinityScrollTable'
import {
  getGroupDetailsRequest,
  setParentGroups as setParentGroupsToState,
  isFetchingGroupDetails,
  getGroupDetailsData,
  getTicketsPage,
  setTicketsPage,
  clearGroupedTicketDetailsData,
  getGroupDetailsCount,
} from '@/store/sd/tickets'
import { TICKET_STATUSES } from '@/constants/ticketStatuses'
import { useClasses, StyledTableRow } from './styles'
import { Props } from './types'
import { ParentGroup } from '@/types'
import { withStyles } from '@material-ui/core/styles'
import FolderImg from '@/assets/sd/folder.svg'
import ListItem from '@material-ui/core/ListItem'
import FolderIconSvg from '@/components/controls/Icon'

const FolderRow = ({
  data,
  headers,
  nesting,
  openedFolder,
  order,
  orderBy,
  parents = [],
  paddingLeft,
  filter,
  tableHeaderOptions,
  onSetOpenedFolder,
  ticketClick,
  posTicketClick,
  incomingHeaders,
}: Props): ReactElement => {
  const isLoading = useSelector(isFetchingGroupDetails)
  const groupDetails = useSelector(getGroupDetailsData)
  const groupDetailsCount = useSelector(getGroupDetailsCount)
  const page = useSelector(getTicketsPage)

  const [folderIsOpen, setFolderIsOpen] = useState<boolean>(false)
  const [displayedData, setDisplayedData] = useState<any>([])
  const [itemsData, setItemsData] = useState<any>([])
  const [parentGroups, setParentGroups] = useState<ParentGroup[]>([])

  const classes = useClasses({ nesting: nesting + 2 })
  const dispatch = useDispatch()

  const handleFolderOpen = useCallback(() => {
    setItemsData([])

    if (folderIsOpen) {
      setFolderIsOpen(false)
    }

    if (!folderIsOpen) {
      setFolderIsOpen(true)
      onSetOpenedFolder(data.group.groupValue, nesting)

      if (!data.childGroups) {
        dispatch(clearGroupedTicketDetailsData())
        dispatch(setParentGroupsToState(parentGroups))
        dispatch(getGroupDetailsRequest())
      }
    }
  }, [folderIsOpen, parentGroups, onSetOpenedFolder, dispatch])

  const loadMore = useCallback(() => {
    dispatch(setTicketsPage(page + 1))
    dispatch(getGroupDetailsRequest())
  }, [page, dispatch])

  useEffect(() => {
    if (!groupDetails) {
      return setItemsData([])
    }

    if (!data.childGroups && groupDetails && groupDetails.length) {
      return setItemsData(groupDetails)
    }
  }, [data, groupDetails])

  useEffect(() => {
    if (data.childGroups) setDisplayedData(data.childGroups)
  }, [data])

  useEffect(() => {
    if (!data.childGroups) {
      return setParentGroups([
        ...parents,
        {
          groupUID: data.groupUID,
          groupValue: data.group.groupValue,
          valueType: data.group.displayValueType,
        },
      ])
    }

    if (data.childGroups) {
      setParentGroups([
        ...parents,
        {
          groupUID: data.groupUID,
          groupValue: data.group.groupValue,
          valueType: data.group.displayValueType,
        },
      ])
    }
  }, [data])

  useEffect(() => {
    if (openedFolder.value !== data.group.groupValue && openedFolder.nesting === nesting) {
      setFolderIsOpen(false)
    }
  }, [data, openedFolder, nesting])

  const getTicketColor = (): string => {
    const ticketGroup = data.group.groupDisplay.toLowerCase()
    const ticketProps = TICKET_STATUSES[ticketGroup]

    return ticketProps ? ticketProps.color : TICKET_STATUSES.undefined.color
  }
  return (
    <>
      <StyledTableRow>
        <TableCell
          key={headers[0]}
          align="left"
          className={classes.cell}
          style={{ paddingLeft: `${paddingLeft}px` }}
        >
          <div className={classes.wrapper}>
            <IconButton size="small" onClick={handleFolderOpen} className={classes.arrowIcon}>
              {folderIsOpen ? (
                <KeyboardArrowDownIcon className={classes.iconSize} />
              ) : (
                <KeyboardArrowRightIcon className={classes.iconSize} />
              )}
            </IconButton>
            {parentGroups[0] && parentGroups[0].groupUID === 'docstatus' ? (
              <FolderIcon
                className={clsx(classes.folderIcon, classes.iconSize)}
                style={{ color: getTicketColor() }}
              />
            ) : (
              <FolderIconSvg
                className={clsx(classes.folderIcon, classes.iconSize)}
                src={FolderImg}
                alt="folder"
              />
            )}
            <Typography variant="body2">{data.group.groupDisplay}</Typography>
            &nbsp;
            {data.totalDetailsCount && (
              <Typography variant="body2">({data.totalDetailsCount})</Typography>
            )}
          </div>
        </TableCell>
      </StyledTableRow>
      <Collapse isOpen={folderIsOpen}>
        {data.childGroups ? (
          displayedData.map((item: any) => {
            return (
              <FolderRow
                key={`${item.group.groupValue}`}
                data={item}
                headers={headers}
                order={order}
                orderBy={orderBy}
                parents={parentGroups}
                paddingLeft={paddingLeft && paddingLeft + 15}
                filter={filter}
                openedFolder={openedFolder}
                nesting={item.groupLevelNo}
                onSetOpenedFolder={onSetOpenedFolder}
                posTicketClick={posTicketClick}
                incomingHeaders={incomingHeaders}
                ticketClick={ticketClick}
                tableHeaderOptions={tableHeaderOptions}
              />
            )
          })
        ) : (
          <InfinityScrollTable
            items={itemsData}
            headers={headers}
            isLoading={isLoading}
            nesting={nesting}
            order={order}
            orderBy={orderBy}
            filter={filter}
            tableHeaderOptions={tableHeaderOptions}
            totalItemsCount={data.totalDetailsCount}
            currentItemsCount={groupDetailsCount}
            incomingHeaders={incomingHeaders}
            loadMore={loadMore}
            ticketClick={ticketClick}
            posTicketClick={posTicketClick}
            disableRowClick={false}
          />
        )}
      </Collapse>
    </>
  )
}

export default FolderRow
