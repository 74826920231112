import Typography from '@material-ui/core/Typography'
import React, { ReactElement, SyntheticEvent, useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import FiltersRow from '@/components/blocks/FiltersRow'
import { TableFilter } from '@/components/controls/AppTable/types'
import { TableGroup } from '@/components/pages/sd/TicketsPage/components/GroupingModal/types'
import TableHeader from '@/components/pages/sd/TicketsPage/components/TableHeader'
import { TICKET_PAGE_TICKET_TABLE } from '@/components/pages/sd/TicketsPage/components/TicketsTable/component'
import { LocalStorageItems } from '@/constants/localStorageItems'
import { getCurrentAccount } from '@/store/auth/selectors'
import { activeLocale } from '@/store/internalization/selectors'
import { setHistoryTicketsFiltersModalState } from '@/store/sd/modals'
import {
  changeSelectedFilters,
  changeSorting,
  clearGroupedTicketDetailsData,
  getFilterTemplateFields,
  getGroupDetailsRequest,
  getSelectedFilters,
  getTicketsConfigData,
  getTicketsPage,
  getTicketsPageCount,
  getTicketsRequest,
  getTicketsRowsPerPage,
  getTicketsTotalCount,
  isFiltersSelected,
  setCurrentFilterTemplate,
  setTicketsPage,
} from '@/store/sd/tickets'
import { TableSettings } from '@/types/common/localStorageData'
import { getTableSettings, setTableSettings } from '@/utils/localStorage'

import TreeTable from './components/TreeTable'
import { useClasses } from './styles'
import { Props } from './types'

const GroupedTicketsTable = ({
  isLoading,
  data,
  ticketsConfig,
  typeOfTickets,
  idPrefix,
  selectedFilters,
  testId,
  handleSetFiltersModal,
  handleSetGrouping,
  ticketClick,
  posTicketClick,
  lazyLoadingPage,
}: Props): ReactElement => {
  // const tableId = `${TICKET_PAGE_TICKET_TABLE}/${typeOfTickets}`
  const tableId = TICKET_PAGE_TICKET_TABLE
  const REQUEST_BODY_LS_KEY = 'ticketsConfig'

  const account = useSelector(getCurrentAccount)
  const localizing = useSelector(activeLocale)

  const [columnsOrderTemplate, setColumnsOrderTemplate] = useState<string[]>([])
  const [columnsOrder, setColumnsOrder] = useState<string[]>([])
  const [columns, setColumns] = useState<string[]>([])
  const [filter, setFilter] = useState<any>(selectedFilters || {})
  const isFilters = useSelector(isFiltersSelected)
  const ticketsTotalCount = useSelector(getTicketsTotalCount)
  const [columnsMenuAnchorEl, setColumnsMenuAnchorEl] = useState<
    null | (EventTarget & HTMLButtonElement)
  >(null)
  const filtersSelected = useSelector(isFiltersSelected)
  const selectedFilter = useSelector(getSelectedFilters)
  const TicketsConfigData = useSelector(getTicketsConfigData)

  const classes = useClasses()
  const dispatch = useDispatch()

  const setTableSettingsToLocalStorage = useCallback(
    (newColumns: string[], newColumnsTemplate: string[]) => {
      if (account) {
        const savedSettings = getTableSettings(
          LocalStorageItems.UserSettings,
          account.userId.toString(),
          tableId,
        )

        const settings: TableSettings = {
          [tableId]: {
            ...(savedSettings && { ...savedSettings }),
            localizing,
            columns: newColumns.map(column =>
              ticketsConfig.outputFields.findIndex(({ fieldLabel }) => column === fieldLabel),
            ),
            columnsTemplate: newColumnsTemplate.map(column =>
              ticketsConfig.outputFields.findIndex(({ fieldLabel }) => column === fieldLabel),
            ),
          },
        }

        setTableSettings(LocalStorageItems.UserSettings, account.userId.toString(), settings)
      }
    },
    [tableId, account, localizing],
  )

  const handleTableParamsChange = useCallback(
    (
      param: 'filter' | 'sort' | 'pagination' | 'group',
      value:
        | TableFilter
        | { [key: string]: 'asc' | 'desc' }
        | null
        | { rowsAmount: number; position: number }
        | TableGroup[],
    ): void => {
      if (param === 'sort') {
        dispatch(clearGroupedTicketDetailsData())
        dispatch(changeSorting(value as { [key: string]: 'asc' | 'desc' } | null))
        dispatch(getGroupDetailsRequest())
      }
      if (param === 'filter' && value) {
        dispatch(changeSelectedFilters(value))
      }
    },
    [dispatch],
  )

  const handleShowColumnsMenu = useCallback((event: SyntheticEvent<HTMLButtonElement>): void => {
    setColumnsMenuAnchorEl(event.currentTarget)
  }, [])

  const handleCloseViewSettings = useCallback((): void => {
    setColumnsMenuAnchorEl(null)
  }, [])

  const handleColumnsChange = useCallback((fields: string[]): void => {
    setColumns(fields)
  }, [])

  const onFilterChange = useCallback((filter: TableFilter) => {
    setFilter(filter)
  }, [])

  return (
    <TreeTable
      isLoading={isLoading}
      tableId={tableId}
      columnsOrderTemplate={columnsOrderTemplate}
      columns={columns}
      columnsOrder={columnsOrder}
      incomingHeaders={ticketsConfig.outputFields}
      data={data}
      ticketsTotalCount={ticketsTotalCount}
      filter={filter}
      tableHeader={
        <div className={classes.tableHeader}>
          <Typography variant="h5" className={classes.tableTitle}>
            <FormattedMessage id={`title.${typeOfTickets}`} />
          </Typography>
          <TableHeader
            testId={testId}
            handleSetGrouping={handleSetGrouping}
            handleSetFiltersModal={handleSetFiltersModal}
            handleShowColumnsMenu={handleShowColumnsMenu}
            idPrefix={idPrefix}
            columns={columnsOrderTemplate}
            keys={columns}
            columnsMenuAnchorEl={columnsMenuAnchorEl}
            setTableSettingsToLocalStorage={setTableSettingsToLocalStorage}
            handleColumnsChange={handleColumnsChange}
            handleCloseViewSettings={handleCloseViewSettings}
            lazyLoadingPage={lazyLoadingPage}
          />
          {isFilters && (
            <FiltersRow
              tableId={`${tableId}/${typeOfTickets}`}
              typeOfTickets={typeOfTickets}
              changeSelectedFilters={changeSelectedFilters}
              getSelectedFilters={selectedFilter}
              isFiltersSelected={filtersSelected}
              getTicketsConfigData={TicketsConfigData}
              getTicketsRequest={getTicketsRequest}
              setTicketsPage={setTicketsPage}
              setHistoryTicketsFiltersModalState={setHistoryTicketsFiltersModalState}
              getFilterTemplateFields={getFilterTemplateFields}
              getRequestBodyLsKey={REQUEST_BODY_LS_KEY}
              setCurrentFilterTemplate={setCurrentFilterTemplate}
              groupTableId={tableId}
            />
          )}
        </div>
      }
      setTableSettingsToLocalStorage={setTableSettingsToLocalStorage}
      setColumnsOrderTemplate={setColumnsOrderTemplate}
      setColumnsOrder={setColumnsOrder}
      setColumns={setColumns}
      onFilterChange={onFilterChange}
      handleTableParamsChange={handleTableParamsChange}
      ticketClick={ticketClick}
      posTicketClick={posTicketClick}
    />
  )
}

export default GroupedTicketsTable
