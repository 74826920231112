import { AdmImportForecastSetting } from '@/api/cm/swaggerGeneratedApi'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class ForecastApi {
  private static requestService = getRequestServiceCM()

  public static async get(): Promise<AdmImportForecastSetting[]> {
    try {
      return await ForecastApi.requestService.get('/admin/importForecastSettings')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async save(payload: AdmImportForecastSetting[]): Promise<void> {
    try {
      return await ForecastApi.requestService.put('/admin/importForecastSettings/save', payload)
    } catch (error) {
      throw Error(error)
    }
  }
}
