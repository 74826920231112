import { RfmApi } from '@/api/atmeye/rfm'
import { APIConfiguration } from '@/constants'

export const downloadMultipleFile = (downloadLink: string) => {
  const url = `${APIConfiguration.API_BASE_URL}${downloadLink}`
  const iframe = document.createElement('iframe')
  iframe.setAttribute('sandbox', 'allow-downloads allow-scripts')
  iframe.src = url
  iframe.setAttribute('style', 'display: none')

  document.body.appendChild(iframe)
}

export const handleDownload = async (fileId: string) => {
  try {
    const fileURL = await RfmApi.getFileURL(fileId)
    const url = `${APIConfiguration.API_BASE_URL}${fileURL}`

    const a = document.createElement('a')

    a.href = url
    a.download = ''
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } catch (error) {
    throw new Error(error)
  }
}
