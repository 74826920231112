import makeStyles from '@material-ui/core/styles/makeStyles'

import { styledComponentTheme } from '@/theme/styledComponentTheme'

export const useClasses = makeStyles(({ spacing }) => ({
  wrapper: {
    padding: spacing(4),
    overflow: 'auto',
  },

  closeButton: {
    top: spacing(2),
    right: spacing(2),

    '& [class*="makeStyles-icon"]': {
      color: '#041517',
    },
  },

  header: {
    marginBottom: spacing(2.5),
    fontWeight: 'normal',
  },

  iconButton: {
    width: '40px',
    height: '40px',
    marginRight: '10px',

    '&[disabled]': {
      filter: 'grayscale(100%)',
      borderColor: '#929A9B',
    },
  },

  applyButton: {
    background: styledComponentTheme.colors.baseGreen,
    padding: '7px 20px',
    marginLeft: spacing(1),
    fontWeight: 'normal',

    '&[disabled]': {
      background: 'transparent',
      border: '1px solid #929A9B',
      color: '#929A9B',
    },
  },

  clearButton: {
    background: 'white',
    border: '1px solid',
    borderColor: styledComponentTheme.colors.baseGreen,
    color: styledComponentTheme.colors.baseGreen,
    padding: '7px 20px',
    marginLeft: spacing(1),
    fontWeight: 'normal',

    '&[disabled]': {
      background: 'transparent',
      border: '1px solid #929A9B',
      color: '#929A9B',
    },

    '&:hover': {
      background: 'transparent',
    },
  },

  selectButton: {
    '& [class*="makeStyles-icon"]': {
      color: '#041517',
      fontSize: '1.4rem',
    },

    '& .MuiSelect-select': {
      paddingTop: '14px',
      paddingBottom: '14px',
      fontSize: '14px',
    },
  },

  savedGroupings: {
    marginTop: spacing(2.5),
    fontWeight: 'normal',
  },

  popoverPaper: {
    marginLeft: spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },

  popoverButton: {
    justifyContent: 'flex-start',
    fontWeight: 'normal',
    padding: '6px 20px',
  },

  loaderWrapper: {
    minWidth: '364px',
    minHeight: '488px',
  },
}))
