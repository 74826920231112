import React, { useEffect, Fragment } from 'react'
import { Props } from './types'
import SubTitle from '@/components/wrappers/AppNavigation/components/DrawerActions/components/SubTitle/index'
import { useClasses } from './styles'
import { WorkingHoursGroup } from '@/components/wrappers/AppNavigation/components/DrawerActions/components/modals/fields/WorkingHoursGroup'
import { Grid } from '@material-ui/core'
import DynamicField from './components/DynamicField/component'
import { getDisabledFieldNames } from './helpers'
import { useTranslate } from '@/utils/internalization'

const DynamicFieldsGroupWrapper = ({
  item,
  formValues,
  formErrors,
  setFormikValues,
  setFormikFieldValue,
  setFieldTouched,
  parentColCount,
  merchantId,
}: Props): React.ReactElement => {
  const { type, outOrderNo } = item
  const classes = useClasses()
  const translate = useTranslate()

  // this useEffect clears "actual address" fields of "new merchant form" if sameAddress checkbox pressed
  useEffect(() => {
    const possiblyBlocked = getDisabledFieldNames(formValues)
    if (
      Object.keys(formValues).some(
        (key: string) => possiblyBlocked?.includes(key) && formValues[key] !== '',
      )
    ) {
      const result = possiblyBlocked?.reduce((accum: any, current: string) => {
        accum[current] = ''
        return accum
      }, {})
      setFormikValues({ ...formValues, ...result })
    }
  }, [formValues])

  if (outOrderNo === 10000) {
    return <></>
  }

  if (type === 'GROUP') {
    return (
      <DynamicFieldsGroupWrapper
        key={item.outOrderNo}
        item={item.outputElement}
        formErrors={formErrors}
        formValues={formValues}
        setFormikValues={setFormikValues}
        setFieldTouched={setFieldTouched}
        setFormikFieldValue={setFormikFieldValue}
        parentColCount={parentColCount}
      />
    )
  }

  const isWorkingHoursGroup = item.groupID === 175344

  // Temporary translate. Must be fixed in future.
  // item.title
  const temporaryItemTitle = item.groupID === 2 ? translate(`translate#label.info`) : item.title

  // Merchant fields
  const merchantIDField = 3283
  const merchantIDAmexField = 3288

  return (
    <Grid
      key={item.outOrderNo}
      item
      style={{
        gridColumn: `auto /
          span ${item.occupyColumnCount}`,
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      {item.title && (
        <SubTitle text={temporaryItemTitle} wrapperClasses={classes.subtitleWrapper} />
      )}
      {isWorkingHoursGroup && (
        <WorkingHoursGroup
          setFormikValues={setFormikValues}
          formValues={formValues}
          formErrors={formErrors}
        />
      )}
      {!isWorkingHoursGroup && (
        <Grid
          container
          alignItems="flex-start"
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${item.outColumnCount}, 1fr)`,
            columnGap: 16,
          }}
        >
          {item.outObjects.map((it: any) => (
            <Fragment key={it.outOrderNo}>
              {it.type === 'GROUP' ? (
                <DynamicFieldsGroupWrapper
                  item={it.outputElement}
                  formErrors={formErrors}
                  formValues={formValues}
                  setFormikValues={setFormikValues}
                  setFormikFieldValue={setFormikFieldValue}
                  parentColCount={item.outColumnCount}
                  setFieldTouched={setFieldTouched}
                  merchantId={merchantId}
                />
              ) : (
                <>
                  {it.outputElement.attributeName !== 'posserviceid' &&
                    it.outputElement.visible &&
                    it.outputElement.attributeId !== merchantIDAmexField &&
                    it.outputElement.attributeId !== merchantIDField && (
                      <Grid
                        item
                        key={it.outputElement.attributeId}
                        style={{
                          gridColumn: `auto /
                    span ${it.outputElement.occupyColCount}`,
                        }}
                      >
                        <DynamicField
                          param={it.outputElement}
                          formValues={formValues}
                          setFieldTouched={setFieldTouched}
                          setFormikFieldValue={setFormikFieldValue}
                          disabledFieldNames={getDisabledFieldNames(formValues)}
                        />
                      </Grid>
                    )}
                </>
              )}
            </Fragment>
          ))}
        </Grid>
      )}
    </Grid>
  )
}

export default DynamicFieldsGroupWrapper
