import { TextField } from '@material-ui/core'
import { AutocompleteRenderInputParams } from '@material-ui/lab'
import Autocomplete from '@material-ui/lab/Autocomplete'
import clsx from 'clsx'
import React, { FC, ReactElement, useMemo } from 'react'

import { SelectIcon } from '@/components/blocks/SelectIcon'
import { useTranslate } from '@/utils/internalization'

import Loader from '../../Loader'
import { useClasses } from './styles'
import { CustomCompleteProps, Option } from './types'

const getOptionLabel = (option: Option): string => option.name || ''
const DropDownIcon = <SelectIcon />

export const AutocompleteCM: FC<CustomCompleteProps> = React.memo((props: CustomCompleteProps) => {
  const {
    options,
    value,
    onChange,
    label,
    required = false,
    className,
    isLoading = false,
    disabled = false,
    filterOptions,
  } = props

  const classes = useClasses()
  const translate = useTranslate()

  const noneOption = {
    name: translate('translate#title.none'),
    value: 'none',
  }

  const SelectLoader = <Loader className={classes.loader} inline />

  const autoValue = useMemo(
    () => (disabled && !value ? noneOption : options.find(option => option.value === value)),
    [options, value, disabled],
  )

  const autoOptions = useMemo(() => (disabled && !value ? [noneOption] : options), [
    disabled,
    value,
    options,
  ])

  return (
    <Autocomplete
      fullWidth
      className={clsx(classes.filed, className)}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      options={autoOptions}
      disabled={disabled}
      loading={isLoading}
      filterOptions={filterOptions}
      value={autoValue || ({} as Option)}
      popupIcon={isLoading ? SelectLoader : DropDownIcon}
      renderInput={(params: AutocompleteRenderInputParams): ReactElement => (
        <TextField
          {...params}
          required={required}
          label={label}
          variant="outlined"
          InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
          disabled={disabled}
        />
      )}
    />
  )
})
