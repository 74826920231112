import {
  AnlChart,
  AnlChartsRequest,
  AnlChartsResponse,
  AnlReportsRequest,
  ComOnDemandDataEntry,
} from '@/api/cm/swaggerGeneratedApi'
import { store } from '@/App'
import { APIConfiguration, AppNavigationRoutes, LocalStorageItems } from '@/constants'
import { history } from '@/Router'
import { signOutCM } from '@/store/cm/auth'
import { PopUpService } from '@/utils/services/popUpService'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

import { ComCMReport } from '../swaggerGeneratedApi'

export class AnalysisApi {
  private static requestService = getRequestServiceCM()

  public static async getReports(payload: AnlReportsRequest): Promise<ComCMReport[]> {
    try {
      return await AnalysisApi.requestService.post('/analytics/reports', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCharts(payload: AnlChartsRequest): Promise<AnlChartsResponse> {
    try {
      return await AnalysisApi.requestService.post('/analytics/charts', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getChart(payload: ComOnDemandDataEntry): Promise<AnlChart | null> {
    try {
      const { url, postBody, reportMethod } = payload
      const tokenCM = localStorage.getItem(LocalStorageItems.AccessTokenCM) || ''

      let method
      switch (reportMethod) {
        case 'report_get':
          method = 'GET'
          break
        case 'report_post':
          method = 'POST'
          break
        default:
          throw new Error('Report method is not specified')
      }

      const response = await fetch(`${APIConfiguration.CM_API_BASE_URL}/${url}`, {
        body: postBody,
        headers: {
          Authorization: `Bearer ${tokenCM}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
        method,
      })
      const parsedResponse = await response.json()

      if (response?.ok) {
        return parsedResponse
      }

      if (response.status === 401) {
        store.dispatch(signOutCM())
        history.push(AppNavigationRoutes.SignInPage)
        PopUpService.showGlobalPopUp(`translate#response.error.unauthorized`, 'error')
      }

      PopUpService.showGlobalPopUp(parsedResponse, 'error')

      return null
    } catch (e) {
      throw new Error(e)
    }
  }
}
