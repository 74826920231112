import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { FormikProps } from 'formik'
import { debounce } from 'lodash-es'
import React, { ReactElement, useState } from 'react'

import { CommonApi } from '@/api/common/commonApi'
import { LookupsResponse, ProductVendor } from '@/api/common/commonApi/types'
import { FieldTypeName } from '@/components/controls/GeneratedField/types'
import Lookups from '@/components/controls/Lookups'
import LookupsSearch from '@/components/controls/LookupsSearch'
import { TextInput } from '@/components/controls/TextInput'
import { hyphenNumbersAndLetters } from '@/constants/disabledSymbols'
import { Hardware } from '@/types/adm/device/api'
import { CreateHardwareFormValues } from '@/types/adm/device/local'
import { HardwareFieldName as FieldName } from '@/types/adm/device/names'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'

const HardwareInformation = ({
  values,
  setFieldValue,
  ...props
}: FormikProps<CreateHardwareFormValues>): ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  const [isDependsEnabled, setIsDependsEnabled] = useState<boolean>(true)
  const enableDepends = debounce(() => {
    setIsDependsEnabled(true)
  }, 1000)

  return (
    <>
      <Grid>
        <Typography variant="h6">{translate('translate#adm.hardwareInformation')}</Typography>
      </Grid>
      <Grid className={classes.subtitle}>
        <Typography variant="body2">
          {translate('translate#adm.pleaseEnterHardwareInformation')}
        </Typography>
      </Grid>
      <Grid container direction="column">
        <Grid className={classes.field}>
          <LookupsSearch
            isDisabled={false}
            defaultValue={{
              value: values.hardwareId,
              name: values.hardwareName,
            }}
            field={{
              options: {
                label: translate('translate#adm.hardware.select.label'),
                placeholder: translate('translate#adm.hardware.select.placeholder'),
                required: true,
              },
            }}
            setFormikFieldValue={setFieldValue}
            fieldName={FieldName.HardwareId}
            fieldTitle={FieldName.HardwareName}
            dependsOn={[values.vendorId]}
            onCall={async (
              value: string,
            ): Promise<{ item: any; value: string; name: string }[]> => {
              const response = await CommonApi.getHardwareFree(value)
              return response.map(item => ({
                item: item,
                value: item.hardwareId.toString(),
                name: `${item.serialNumber} ${item.model.modelName}`,
              }))
            }}
            isDependsOn={isDependsEnabled}
            onSelect={(item: { item: Hardware }): void => {
              setIsDependsEnabled(false)

              if (item) {
                const ha = item.item
                setFieldValue(FieldName.HardwareId, ha.hardwareId.toString())
                setFieldValue(FieldName.HardwareName, `${ha.serialNumber} ${ha.model.modelName}`)
                setFieldValue(FieldName.SerialNumber, ha.serialNumber)
                setFieldValue(FieldName.VendorId, ha.vendor.vendorId?.toString())
                setFieldValue(FieldName.VendorName, ha.vendor.vendorName)
                setFieldValue(FieldName.ModelId, ha.model.modelId?.toString())
                setFieldValue(FieldName.ModelName, ha.model.modelName)
                setFieldValue(FieldName.HardwareTypeId, ha.type.deviceTypeId?.toString())
                setFieldValue(FieldName.HardwareTypeName, ha.type.deviceTypeName)
                setFieldValue(FieldName.HardwareDescription, ha.description)
              } else {
                setFieldValue(FieldName.HardwareId, '')
                setFieldValue(FieldName.HardwareName, '')
                setFieldValue(FieldName.SerialNumber, '')
                setFieldValue(FieldName.VendorId, '')
                setFieldValue(FieldName.VendorName, '')
                setFieldValue(FieldName.ModelId, '')
                setFieldValue(FieldName.ModelName, '')
                setFieldValue(FieldName.HardwareTypeId, '')
                setFieldValue(FieldName.HardwareTypeName, '')
                setFieldValue(FieldName.HardwareDescription, '')
              }

              enableDepends()
            }}
          />
        </Grid>
        <Grid className={classes.field}>
          <TextInput
            disabled={values.hardwareId !== ''}
            label={translate('translate#adm.serialNumber')}
            name={FieldName.SerialNumber}
            value={values.serialNumber}
            onChange={props.handleChange}
            required
            fullWidth
            disabledKeys={hyphenNumbersAndLetters}
          />
        </Grid>
        <Grid className={classes.field}>
          <LookupsSearch
            isDisabled={values.hardwareId !== ''}
            defaultValue={{
              value: values.modelId,
              name: values.modelName,
            }}
            field={{
              options: {
                label: translate('translate#adm.model'),
                placeholder: translate('translate#adm.model'),
                required: true,
              },
            }}
            setFormikFieldValue={setFieldValue}
            fieldName={FieldName.ModelId}
            fieldTitle={FieldName.ModelName}
            isDependsOn={false}
            onCall={async (
              value: string,
            ): Promise<{ item: any; value: string; name: string }[]> => {
              const response = await CommonApi.getLookupsProductsByQualification({
                vendorId: Number(values.vendorId),
                levels: ['MODEL'],
                fragment: value,
                pageNo: 0,
                pageSize: 100,
              })
              return response.values.map(item => ({
                item: item,
                value: item.id,
                name: `${item.name} ${item.code}${
                  item.vendorCompanyName ? ' ' + item.vendorCompanyName : ''
                }`,
              }))
            }}
            onSelect={(item: { item: ProductVendor }): void => {
              setIsDependsEnabled(false)
              setFieldValue(FieldName.ModelId, item ? item.item.id : '')
              setFieldValue(FieldName.ModelName, item ? item.item.name : '')
              if (item) {
                setFieldValue(FieldName.VendorId, item.item.vendorCompanyId)
                setFieldValue(FieldName.VendorName, item.item.vendorCompanyName)
              }
              enableDepends()
            }}
          />
        </Grid>
        <Grid className={classes.field}>
          <Lookups
            isDisabled={values.hardwareId !== ''}
            defaultValue={{
              value: values.vendorId,
              name: values.vendorName,
            }}
            setFormikFieldValue={setFieldValue}
            field={{
              type: FieldTypeName.Search,
              options: {
                label: translate('translate#adm.vendor'),
                placeholder: translate('translate#adm.vendor'),
                required: false,
                isNumber: false,
              },
            }}
            fieldName={FieldName.VendorId}
            fieldTitle={FieldName.VendorName}
            isDependsOn={false}
            onCall={(value: string): Promise<LookupsResponse> => {
              return CommonApi.getLookupsValues({
                lookupKey: 'ALLOWED_COMPANY_VENDOR',
                module: 'ADM',
                fragment: value,
                pageNo: 0,
                pageSize: 100,
              })
            }}
          />
        </Grid>

        <Grid className={classes.field}>
          <Lookups
            isDisabled={values.hardwareId !== ''}
            defaultValue={{
              value: values.hardwareTypeId,
              name: values.hardwareTypeName,
            }}
            setFormikFieldValue={setFieldValue}
            field={{
              type: FieldTypeName.List,
              options: {
                label: translate('translate#adm.hardwareType'),
                placeholder: translate('translate#adm.hardwareType'),
                required: true,
                isNumber: false,
              },
            }}
            fieldName={FieldName.HardwareTypeId}
            fieldTitle={FieldName.HardwareTypeName}
            isDependsOn
            onCall={(value: string): Promise<LookupsResponse> => {
              return CommonApi.getLookupsValues({
                lookupKey: 'ADM_HARDWARE_TYPE',
                module: 'ADM',
                fragment: value,
                pageNo: 0,
                pageSize: 100,
              })
            }}
          />
        </Grid>
        <Grid className={classes.field}>
          <TextInput
            disabled={values.hardwareId !== ''}
            label={translate('translate#adm.description')}
            name={FieldName.HardwareDescription}
            value={values.hardwareDescription}
            onChange={props.handleChange}
            fullWidth
            multiline
            maxSymbols={300}
            rows={5}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default HardwareInformation
