import { AnyAction } from 'redux'

import { AdmAppSetting } from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants/actions'

// AppConfig
export const getAdmAppConfigRequest = (): AnyAction => ({
  type: VaultV1Actions.AdmGetAppConfigRequest,
})
export const getAdmAppConfigResponse = (payload: AdmAppSetting[]): AnyAction => ({
  type: VaultV1Actions.AdmGetAppConfigResponse,
  payload,
})
export const getAdmAppConfigFail = (): AnyAction => ({
  type: VaultV1Actions.AdmGetAppConfigFail,
})

export const setAdmAppConfigRequest = (payload: AdmAppSetting[]): AnyAction => ({
  type: VaultV1Actions.AdmSetAppConfigRequest,
  payload,
})
export const setAdmAppConfigResponse = (): AnyAction => ({
  type: VaultV1Actions.AdmSetAppConfigResponse,
})
export const setAdmAppConfigFail = (): AnyAction => ({
  type: VaultV1Actions.AdmSetAppConfigFail,
})

// Logo
export const getAdmLogoRequest = (): AnyAction => ({
  type: VaultV1Actions.AdmGetLogoRequest,
})
export const getAdmLogoResponse = (payload: string): AnyAction => ({
  type: VaultV1Actions.AdmGetLogoResponse,
  payload,
})
export const getAdmLogoFail = (): AnyAction => ({
  type: VaultV1Actions.AdmGetLogoFail,
})
