import React, { useState, useCallback } from 'react'
import { preventDefault } from '@/utils/functions'
import Dialog from '@material-ui/core/Dialog/Dialog'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { FormattedMessage } from 'react-intl'
import { useTranslate } from '@/utils/internalization'
import { useClasses } from './styles'
import { Button } from '@material-ui/core'
import { Select } from '@/components/controls/Select'
import CloseButton from '@/components/blocks/CloseButton'
import Test from 'react-test-attributes'
import { getTestId } from '@/utils'

import { Props } from './types'

const getTimeSelectOptions = () => {
  let initName = 0
  let initValue = 0

  return (optionsSize: number, step: number): Array<{ name: string; value: string }> =>
    [...Array(optionsSize)].map((option, index) => {
      if (index === 0) {
        return { name: `0`, value: `0` }
      }

      initName += step
      initValue += step

      return { name: `${initName}`, value: `${initValue}` }
    })
}

const minutesOptions = getTimeSelectOptions()(60, 1)
const hoursOptions = getTimeSelectOptions()(24, 1)

const Widget = ({
  open,
  handleClose,
  hours,
  minutes,
  testIdPrefix,
  handleHoursChange,
  handleMinutesChange,
  handleSetTimerClick,
}: Props): React.ReactElement => {
  const translate = useTranslate()
  const classes = useClasses()
  const idPrefix = `${testIdPrefix}-refresh-timeout-modal`
  const testId = getTestId(idPrefix)

  const handleSetTimer = (): void => {
    handleSetTimerClick()
    handleClose()
  }

  return (
    <Test id={testId(0)}>
      <Dialog
        onDragOver={preventDefault}
        onDrop={preventDefault}
        maxWidth="xs"
        fullWidth
        open={open}
        keepMounted
        onClose={handleClose}
        disableBackdropClick
      >
        <CloseButton onClick={handleClose} absolute />
        <Test id={testId(1)}>
          <div className={classes.wrapper}>
            <Box display="flex" flexDirection="column" className={classes.box}>
              <Test id={testId(2)}>
                <Grid container justify="flex-start">
                  <div className={classes.title}>
                    {' '}
                    <FormattedMessage
                      id="title.setAutorefresh"
                      defaultMessage="Set refresh timeout"
                    />
                  </div>
                </Grid>
              </Test>
              <Test id={testId(3)}>
                <div className={classes.controls}>
                  <Grid container justify="center">
                    <Test id={testId(4)}>
                      <Grid item>
                        <Select
                          placeholder="Select hour"
                          label={translate('translate#title.hours')}
                          hideEmptyItem
                          options={hoursOptions}
                          value={hours}
                          onChange={handleHoursChange}
                          className={classes.select}
                        />
                      </Grid>
                    </Test>
                    <Test id={testId(5)}>
                      <Grid item>
                        <Select
                          placeholder="Select minutes"
                          label={translate('translate#title.minutes')}
                          hideEmptyItem
                          options={minutesOptions}
                          value={minutes}
                          onChange={handleMinutesChange}
                          className={classes.select}
                        />
                      </Grid>
                    </Test>
                  </Grid>
                </div>
              </Test>
              <Grid container justify="flex-end">
                <Test id={testId(6)}>
                  <Grid item>
                    <Test id={testId(7)}>
                      <Button
                        className={classes.button}
                        onClick={handleSetTimer}
                        disabled={!+minutes && !+hours}
                        variant="contained"
                      >
                        <FormattedMessage
                          id="title.setAutorefresh"
                          defaultMessage="Set autorefresh"
                        />
                      </Button>
                    </Test>
                  </Grid>
                </Test>
              </Grid>
            </Box>
          </div>
        </Test>
      </Dialog>
    </Test>
  )
}

export default Widget
