import makeStyles from '@material-ui/core/styles/makeStyles'

type Props = {
  display?: string
}

export const useClasses = makeStyles(({ mixins, spacing }) => ({
  root: {
    display: (props: Props) => props.display,
    flex: 1,
    flexDirection: 'column',
    overflow: 'auto',
    paddingBottom: spacing(1),
    ...mixins.gutters(),
    background: '#f8f8f8',
  },
}))
