export const deepCopyArrayOrObject = (inObject: any) => {
  if (typeof inObject !== 'object' || inObject === null) {
    return inObject
  }

  const outObject: typeof inObject = Array.isArray(inObject) ? [] : {}
  let key

  for (key in inObject) {
    const value = inObject[key]
    outObject[key] = deepCopyArrayOrObject(value)
  }

  return outObject
}

export const getFilteredTreeView = <T>(
  treeMenu: T,
  value: string,
  namesOptions?: {
    itemName?: string
    valueName?: string
    allItems?: boolean
    disableItems?: string[]
  },
  fullLine?: boolean,
) => {
  const newArray = deepCopyArrayOrObject(treeMenu)
  const matcher = new RegExp(`${value}`, 'g')

  if (!newArray) {
    return []
  } else {
    return newArray.filter(function findName(r: any) {
      if (namesOptions?.allItems) {
        if (
          Object.keys(r).some((columnKey: any) => {
            if (
              Object.keys(r[columnKey]).length &&
              typeof r[columnKey][namesOptions.valueName || 'value'] === 'string'
            ) {
              return (
                r[columnKey][namesOptions.valueName || 'value']
                  .toLocaleLowerCase()
                  .indexOf(value) !== -1
              )
            } else if (typeof r[columnKey] === 'string') {
              return r[columnKey].toLocaleLowerCase().indexOf(value) !== -1
            }
          })
        ) {
          return true
        }
      } else {
        if (r.subscriptionName) {
          if (r.subscriptionName.toLocaleLowerCase().indexOf(value) !== -1) return true
        }

        if (fullLine && r[namesOptions?.itemName || 'name'].toLocaleLowerCase().includes(value)) {
          return true
        }

        if (r[namesOptions?.itemName || 'name'].toLocaleLowerCase().match(matcher)) return true
      }

      if (r.children) {
        return (r.children = r.children.filter(findName)).length
      }
    })
  }
}
