import queryString from 'query-string'

import { APIConfiguration } from '@/constants'
import { Sort } from '@/types/cm'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

import {
  AdmAccessRulesRolePermissions,
  AdmComUser,
  AdmComVault,
  AdmUserPermissions,
} from '../swaggerGeneratedApi'

interface GetUsersParameters {
  id: string
  userName?: string
}

interface GetPermissionsParameters {
  userId: string
  vaultId: string
}

export class PermissionsAdminApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getVaults(sort: Sort, vaultName?: string): Promise<AdmComVault[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/accessRules/permissions/vaults',
          query: { vaultName, ...sort },
        },
        { skipNull: true, encode: false },
      )
      return await PermissionsAdminApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getUsers(sort: Sort, parameters: GetUsersParameters): Promise<AdmComUser[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/accessRules/permissions/users',
          query: { ...parameters, ...sort },
        },
        { skipNull: true, encode: false },
      )
      return await PermissionsAdminApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getPermissions(
    parameters: GetPermissionsParameters,
  ): Promise<AdmUserPermissions> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/accessRules/permissions',
          query: { ...parameters },
        },
        { skipNull: true, encode: false },
      )
      return await PermissionsAdminApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setPermissions(
    guid: string,
    body: AdmUserPermissions,
  ): Promise<AdmUserPermissions> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/accessRules/permissions/update',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )
      return await PermissionsAdminApi.requestService.put(url, body)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getRolePermissions(guid: string): Promise<AdmAccessRulesRolePermissions> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/accessRules/role/permissions',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )
      return await PermissionsAdminApi.requestService.get(url)
    } catch (error) {
      throw new Error(String(error as Error))
    }
  }

  public static async setRolePermissions(payload: AdmAccessRulesRolePermissions): Promise<void> {
    try {
      return await PermissionsAdminApi.requestService.put(
        '/admin/accessRules/role/permissions/update',
        payload,
      )
    } catch (error) {
      throw new Error(String(error as Error))
    }
  }
}
