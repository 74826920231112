import { ReactNode } from 'react'
import { FormikValues } from 'formik'
import { LookupFieldConfig } from '@/types/common/lookupConfig'
import { RadioGroupObjectValues } from '@/components/controls/RadioGroup/types'

export type BackupValue = { value: string; name: string }
export type BackupValues = { [key: string]: BackupValue }
export interface BackupOptions {
  backupValues: BackupValues
  setBackupValue: (key: string, value: BackupValue) => void
}

export enum FieldTypeName {
  Text = 'TEXT',
  List = 'LIST',
  MultiSelect = 'MULTISELECT',
  Search = 'SEARCH_LIST',
  Checkbox = 'CHECKBOX',
  Date = 'DATE',
  RadioGroup = 'RADIO_GROUP',
}

type FieldType =
  | FieldTypeName.Text
  | FieldTypeName.List
  | FieldTypeName.MultiSelect
  | FieldTypeName.Search
  | FieldTypeName.Checkbox
  | FieldTypeName.Date
  | FieldTypeName.RadioGroup

export interface Props {
  field: {
    type: FieldType
    options?: {
      textInput?: {
        label: string
        placeholder: string
        required?: boolean
        disableKeys?: string[]
      }
      selectAndDropDownInput?: {
        label: string
        placeholder: string
        required?: boolean
        requestDelay?: number
        disabled?: boolean
      }
      datePicker?: {
        disableFutureDates: boolean
        label: string
        required?: boolean
        Icon?: ReactNode
      }
      checkbox?: {
        label: string
        required?: boolean
      }
      radioGroup?: {
        options: RadioGroupObjectValues[]
        label?: string
        required?: boolean
      }
    }
  }
  fieldName: string
  formikValues: FormikValues
  setFormikFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  lookupFieldConfig?: LookupFieldConfig
  formikPath?: string
  isLoading?: boolean
  defaultValue?: { value: string; name: string }
  backupOptions?: BackupOptions
}

export interface DependentField {
  parameterName: string
  parameterValue: Array<{
    id: number | null
    value: string | null
  }>
  valueType: string
}
