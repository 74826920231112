import queryString from 'query-string'

import {
  AdmUser,
  AdmUserBase,
  AdmUserFilter,
  AdmUserReportsRequest,
  ComVaultReport,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { Paging, Sort } from '@/types/cm'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

export class UsersAdminApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getUsers(
    sort: Sort,
    paging: Paging,
    filter?: AdmUserFilter,
  ): Promise<AdmUser[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/users',
          query: { ...sort, ...paging },
        },
        { skipNull: true, encode: false },
      )

      return await UsersAdminApi.requestService.post(url, filter || {})
    } catch (error) {
      throw Error(error)
    }
  }

  public static async addUser(payload: AdmUserBase): Promise<void> {
    try {
      return await UsersAdminApi.requestService.post('/admin/users/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async updateUser(payload: AdmUser): Promise<void> {
    try {
      return await UsersAdminApi.requestService.put('/admin/users/update', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async deleteUser(id: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/users/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await UsersAdminApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getReports(payload: AdmUserReportsRequest): Promise<ComVaultReport[]> {
    try {
      return await UsersAdminApi.requestService.post('/admin/users/reports', payload)
    } catch (e) {
      throw new Error(e)
    }
  }
}
