export enum WorkItemsTableColumn {
  Executors = 'executors',
  Work = 'work',
  ProblemArea = 'problemArea',
  Notes = 'notes',
  SlIsClosed = 'slIsClosed',
  WorkStarted = 'workStarted',
  WorkFinished = 'workFinished',
  DelayReason = 'delayReason',
  Duration = 'duration',
  DurationOnTrip = 'durationOnTrip',
}

export const workItemsTableData = []

export enum ServiceItemsTableColumn {
  Type = 'type',
  Closed = 'closed',
  ProblemArea = 'problemArea',
  SLADeadline = 'slaDeadline',
  CloseTime = 'closeTime',
  Reason = 'reason',
}

export const serviceItemsTableData = [
  {
    [ServiceItemsTableColumn.Type]: 'W SW Config',
    [ServiceItemsTableColumn.Closed]: 'Yes',
    [ServiceItemsTableColumn.ProblemArea]: 'Display',
    [ServiceItemsTableColumn.SLADeadline]: '',
    [ServiceItemsTableColumn.CloseTime]: 'Aug 18 2020 13:49',
    [ServiceItemsTableColumn.Reason]: 'Some reason',
  },
  {
    [ServiceItemsTableColumn.Type]: 'W SW Config',
    [ServiceItemsTableColumn.Closed]: 'No',
    [ServiceItemsTableColumn.ProblemArea]: 'ATM',
    [ServiceItemsTableColumn.SLADeadline]: '',
    [ServiceItemsTableColumn.CloseTime]: 'Aug 20 2020 15:19',
    [ServiceItemsTableColumn.Reason]: 'Some reason',
  },
]
