import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'

import { styledComponentTheme } from '@/theme'
import colors from '@/theme/colors'

export const useClasses = makeStyles(({ spacing }) => ({
  paper: {
    height: 'auto',
    padding: spacing(3),
    paddingTop: spacing(3),
    paddingBottom: spacing(2),
    display: 'grid',
    gridTemplateRows: 'max-content 1fr max-content',
    overflow: 'auto',
  },
  modalHeaderTitle: {
    display: 'flex',
    fontSize: styledComponentTheme.fontSizes.big,
    lineHeight: '23px',
  },

  subTitle: {
    fontSize: styledComponentTheme.fontSizes.normal,
    marginLeft: '10px',
    color: colors.cmTable.text,
    lineHeight: '16px',
  },
  button: {
    height: 40,
    fontWeight: 'normal',
    padding: '0 20px',
    margin: 0,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  },
}))

export const ActionButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 10px;
  align-items: flex-end;
  margin-left: auto;
  justify-content: flex-end;
`
