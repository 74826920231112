import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, putResolve, select, takeLatest } from 'redux-saga/effects'

import { ValuablesAdminApi } from '@/api/vault-v1/adminApi'
import { AdmValuable } from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants'
import { setComVaultV1Error } from '@/store/vault-v1/common'

import {
  addAdmValuableFail,
  addAdmValuableResponse,
  deleteAdmValuableFail,
  getAdmValuablesFail,
  getAdmValuablesLanguagesResponse,
  getAdmValuablesRequest,
  getAdmValuablesResponse,
  getVaultsAdmSelectedValuable,
  getVaultsAdmValuablesSort,
  setAdmValuableSelected,
  updateAdmValuableFail,
  updateAdmValuableResponse,
} from '.'

// [START] Valuables --------------------------------------
function* getValuables(): SagaIterator {
  try {
    const sort = yield select(getVaultsAdmValuablesSort)
    const selected = yield select(getVaultsAdmSelectedValuable)

    const valuables: AdmValuable[] = yield call(ValuablesAdminApi.getAll, sort)

    yield put(getAdmValuablesResponse(valuables))

    if (valuables && selected) {
      const updatedValuable = valuables.find((valuable: AdmValuable) => valuable.id === selected.id)

      if (updatedValuable) {
        yield putResolve(setAdmValuableSelected(updatedValuable))
      } else {
        yield putResolve(setAdmValuableSelected(null))
      }
    }
  } catch (error) {
    yield put(getAdmValuablesFail())
  }
}

function* addValuable({ payload }: AnyAction): SagaIterator {
  try {
    yield call(ValuablesAdminApi.add, payload)

    yield put(addAdmValuableResponse())
    yield put(getAdmValuablesRequest())
  } catch (error) {
    yield put(addAdmValuableFail())
  }
}

function* updateValuable({ payload }: AnyAction): SagaIterator {
  try {
    yield call(ValuablesAdminApi.update, payload)

    yield put(updateAdmValuableResponse())
    yield put(getAdmValuablesRequest())
  } catch (error) {
    yield put(updateAdmValuableFail())
  }
}

function* deleteValuable(): SagaIterator {
  try {
    const selected = yield select(getVaultsAdmSelectedValuable)

    yield call(ValuablesAdminApi.delete, selected.id)

    yield put(getAdmValuablesRequest())
  } catch (error) {
    yield put(deleteAdmValuableFail())
  }
}

function* getValuablesLanguages(): SagaIterator {
  try {
    const languages = yield call(ValuablesAdminApi.getLanguages)

    yield put(getAdmValuablesLanguagesResponse(languages))
  } catch (error) {
    yield put(setComVaultV1Error(error))
  }
}
// [END] Valuables --------------------------------------

export default function*(): Generator {
  yield takeLatest(
    [VaultV1Actions.AdmGetValuablesRequest, VaultV1Actions.AdmSortValuables],
    getValuables,
  )

  yield takeLatest(VaultV1Actions.AdmAddValuableRequest, addValuable)
  yield takeLatest(VaultV1Actions.AdmUpdateValuableRequest, updateValuable)
  yield takeLatest(VaultV1Actions.AdmDeleteValuableRequest, deleteValuable)
  yield takeLatest(VaultV1Actions.AdmGetValuablesLanguagesRequest, getValuablesLanguages)
}
