import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromVaultV1AppConfig from './reducer'

export const getState = (state: AppState): fromVaultV1AppConfig.State => {
  return state.vaultV1Admin.appConfig.currencies
}

export const getVaultsAdmCurrencies = createSelector(getState, state => state)
export const getVaultsAdmCurrenciesTotal = createSelector(getState, state => state.total)
export const getVaultsAdmSelectedCurrency = createSelector(getState, state => state.selected)

export const getVaultsAdmCurrenciesSort = createSelector(getState, state => ({
  sortColumn: state.sortColumn,
  sortOrder: state.sortOrder,
}))

export const getVaultsAdmCurrenciesOnlyBase = createSelector(
  getState,
  state => state.isShowUsedOnly,
)
