import { AnyAction } from 'redux'

import { AdmAppSetting } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants/actions'

export type State = {
  data: AdmAppSetting[]
  isLoading: boolean
  isLoaded: boolean
  error: string | null
}

export const initialState: State = {
  data: [],
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    // get
    case CMAppConfigActions.AdmUpdateApplicationConfigRequest:
    case CMAppConfigActions.AdmGetApplicationConfigRequest:
      return {
        ...state,
        isLoading: true,
      }

    case CMAppConfigActions.AdmGetApplicationConfigResponse:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: payload,
      }
    case CMAppConfigActions.AdmUpdateApplicationConfigFail:
    case CMAppConfigActions.AdmGetApplicationConfigFail:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: payload,
      }

    default:
      return state
  }
}
