import { ResponseErrorTypes } from '@/types/common/ErrorResponseTypes'
import { ActionFormTypes } from '@/types/usm/commoTypes/actionFormTypes'
import {
  CreateNewUSerGroupTypes,
  ObjectPermissionsResponse,
  UpdateUserGroupsRequestTypes,
  userGroupAndPermissionsType,
  UserGroupType,
} from '@/types/usm/userGroup/userGroup'

export const actionUserGroupUSM = {
  // getting-user-group
  startFetchingAllUserGroups: () =>
    ({
      type: '@/START_FETCHING_GET_ALL_USER_GROUP_USM',
    } as const),
  stopFetchingAllUserGroups: () =>
    ({
      type: '@/STOP_FETCHING_GET_ALL_USER_GROUP_USM',
    } as const),
  setAllUserGroupResponse: (payload: Array<UserGroupType>) =>
    ({
      type: '@/SET_ALL_USER_GROUP_RESPONSE_USM',
      payload,
    } as const),
  setAllUserGroupResponseFall: (error: ResponseErrorTypes) =>
    ({
      type: '@/SET_ALL_USER_GROUP_RESPONSE_FALL_USM',
      payload: error,
    } as const),

  getAllUserGroupsAsync: () =>
    ({
      type: '@/GET_ALL_USER_GROUP_ASYNC_USM',
    } as const),

  // add-new-user-group
  startFetchingAddGroup: () =>
    ({
      type: '@/START_FETCHING_ADD_GROUP_USM',
    } as const),
  stopFetchingAddGroup: () =>
    ({
      type: '@/STOP_FETCHING_ADD_GROUP_USM',
    } as const),
  setAddGroupResponse: (payload: userGroupAndPermissionsType) =>
    ({
      type: '@/SET_ADD_GROUP_RESPONSE_USM',
      payload,
    } as const),
  setAddGroupResponseFall: (error: ResponseErrorTypes) =>
    ({
      type: '@/SET_ADD_GROUP_RESPONSE_FALL_USM',
      payload: error,
    } as const),

  addGroupAsync: <T>(payload: CreateNewUSerGroupTypes, actions: ActionFormTypes<T>) =>
    ({
      type: '@/ADD_GROUP_ASYNC_USM',
      payload,
      actions,
    } as const),

  // update-user-group
  startFetchingUpdateUserGroups: () =>
    ({
      type: '@/START_FETCHING_UPDATE_USER_GROUPS_USM',
    } as const),

  stopFetchingUpdateUserGroups: () =>
    ({
      type: '@/STOP_FETCHING_UPDATE_USER_GROUPS_USM',
    } as const),

  fillUpdateUserGroupsResponse: (payload: Array<userGroupAndPermissionsType>) =>
    ({
      type: '@/FILL_UPDATE_USER_GROUPS_RESPONSE_USM',
      payload,
    } as const),

  fillUpdateUserGroupsResponseFall: (payload: ResponseErrorTypes) =>
    ({
      type: '@/FILL_UPDATE_USER_GROUPS_RESPONSE_FALL_USM',
      payload,
    } as const),

  getUpdateUserGroupsAsync: (payload: Array<UpdateUserGroupsRequestTypes>) =>
    ({
      type: '@/GET_UPDATE_USER_GROUPS_ASYNC_USM',
      payload,
    } as const),

  // get-current-user-group
  startFetchingGetCurrentGroup: () =>
    ({
      type: '@/START_FETCHING_GET_CURRENT__USER_GROUP_USM',
    } as const),
  stopFetchingGetCurrentGroup: () =>
    ({
      type: '@/STOP_FETCHING_GET_CURRENT__USER_GROUP_USM',
    } as const),
  setCurrentGroupResponse: (payload: userGroupAndPermissionsType) =>
    ({
      type: '@/SET_CURRENT_USER_GROUP_RESPONSE_USM',
      payload,
    } as const),
  setCurrentGroupResponseFall: (error: ResponseErrorTypes) =>
    ({
      type: '@/SET_CURRENT_USER_GROUP_RESPONSE_FALL_USM',
      payload: error,
    } as const),

  getCurrentGroupByIdAsync: (payload: number) =>
    ({
      type: '@/GET_CURRENT_USER_GROUP_ASYNC_USM',
      payload,
    } as const),

  // delete-user-group
  startFetchingDeleteUserGroup: () =>
    ({
      type: '@/START_FETCHING_DELETE_USER_GROUP_BY_ID_USM',
    } as const),
  stopFetchingDeleteUserGroup: () =>
    ({
      type: '@/STOP_FETCHING_DELETE_USER_GROUP_BY_ID_USM',
    } as const),
  fillDeleteUserGroupResponse: (payload: number) =>
    ({
      type: '@/FILL_DELETE_USER_GROUP_BY_ID_RESPONSE_USM',
      payload,
    } as const),
  setDeleteUserGroupResponseFall: (payload: ResponseErrorTypes) =>
    ({
      type: '@/SET_DELETE_USER_GROUP_BY_ID_RESPONSE__FALL_USM',
      payload,
    } as const),
  deleteUserGroupByIdAsync: (payload: number) =>
    ({
      type: '@/DELETE_USER_GROUP_BY_ID_ASYNC',
      payload,
    } as const),
  // Object-permissions
  // object-permission-tree
  startFetchingObjectTree: () =>
    ({
      type: '@/START_FETCHING_OBJECT_TREE_PERMISSIONS_USM',
    } as const),
  stopFetchingObjectPermissionsTree: () =>
    ({
      type: '@/STOP_FETCHING_OBJECT_TREE_PERMISSIONS_USM',
    } as const),
  setObjectPermissionsTreeResponse: (payload: Array<ObjectPermissionsResponse>) =>
    ({
      type: '@/SET_OBJECT_TREE_PERMISSIONS_RESPONSE_USM',
      payload,
    } as const),
  setObjectPermissionsTreeFall: (error: ResponseErrorTypes) =>
    ({
      type: '@/SET_OBJECT_TREE_PERMISSIONS_RESPONSE_FALL_USM',
      payload: error,
    } as const),
  getObjectPermissionsTreeAsync: () =>
    ({
      type: '@/OBJECT_TREE_PERMISSIONS_ASYNC_USM',
    } as const),
  setFilterUerGroupList: (payload: number) =>
    ({
      type: '@/SET_FILTER_USER_GROUP_LIST_USM',
      payload,
    } as const),
  // clear-error
  setClearErrorUserGroup: () =>
    ({
      type: '@/SET_CLEAR_ERROR_USERS_GROUP_USM',
    } as const),
  clearAllSelectedCurrentUserGroups: () =>
    ({
      type: '@/CLEAR_ALL_SELECTED_USER_GROUP_ASYNC_USM',
    } as const),
}
