import queryString from 'query-string'

import {
  ComVaultReport,
  eMonRemainderType,
  MonBagEntry,
  MonCashLimit,
  MonCashLimitBase,
  MonCashLimitsResponse,
  MonCassetteEntry,
  MonCassettesNominalDetailEntry,
  MonChartsFilter,
  MonEntryDetails,
  MonOperationEntry,
  MonRemaindersResponse,
  MonReportsRequest,
  MonUnloadCashierToVaultRequest,
  MonValuableRemainder,
  MonVault,
  MonVaultsRequest,
  MonVaultsResponse,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

import { CashPointDetails, mapForecast } from './helpers'
import {
  GetCashiersProps,
  GetCurrentValuablesRemaindersProps,
  MonOperationDetailsFilter,
  SetCurrentValuablesRemaindersProps,
} from './types'

export class MonitoringApi {
  private static requestService = getRequestServiceVaultV1(APIConfiguration.VAULT_V1_API_BASE_URL)

  public static async getVaultDetails(entryId: string): Promise<MonEntryDetails[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'monitoring/vault/details',
          query: { entryId },
        },
        { skipNull: true, encode: false },
      )
      return await MonitoringApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getVaults(
    paging: { page: number; pageSize: number },
    requestBody: MonVaultsRequest,
    sortColumn?: string | undefined,
    sortOrder?: 'asc' | 'desc' | undefined,
  ): Promise<MonVaultsResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/monitoring/vaults',
          query: {
            pageSize: `${paging.pageSize}`,
            page: `${paging.page}`,
            sortColumn,
            sortOrder,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await MonitoringApi.requestService.post(url, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getSingleVault(id: string, sortOrder?: 'asc' | 'desc'): Promise<MonVault> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'monitoring/vault',
          query: {
            guid: id,
            sortOrder,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await MonitoringApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashiers(payload: GetCashiersProps): Promise<MonVault[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'monitoring/vault/cashiers',
          query: { ...payload },
        },
        { skipNull: true, encode: false },
      )
      return await MonitoringApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getBags(vaultId: string): Promise<MonBagEntry[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'monitoring/vault/bags',
          query: { vaultId },
        },
        { skipNull: true, encode: false },
      )

      return await MonitoringApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getBagsDetails(vaultId: string): Promise<MonCassettesNominalDetailEntry[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'monitoring/vault/bags/details',
          query: { vaultId },
        },
        { skipNull: true, encode: false },
      )

      return await MonitoringApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCassettes(vaultId: string): Promise<MonCassetteEntry[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'monitoring/vault/cassettes',
          query: { vaultId },
        },
        { skipNull: true, encode: false },
      )

      return await MonitoringApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCassettesDetails(
    vaultId: string,
  ): Promise<MonCassettesNominalDetailEntry[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: 'monitoring/vault/cassettes/details',
          query: { vaultId },
        },
        { skipNull: true, encode: false },
      )

      return await MonitoringApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getOperationsDetails(
    payload: MonOperationDetailsFilter,
  ): Promise<MonOperationEntry[]> {
    try {
      const { currencyId, vaultId, filter, sort } = payload

      const url = queryString.stringifyUrl(
        {
          url: 'monitoring/vault/operations',
          query: { vaultId, currencyId, ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await MonitoringApi.requestService.post(url, filter)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getReports(payload: MonReportsRequest): Promise<ComVaultReport[]> {
    try {
      return await MonitoringApi.requestService.post('/monitoring/reports', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCurrentRemainders(
    id: string,
    remainderType: eMonRemainderType,
  ): Promise<MonRemaindersResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/monitoring/currentRemainders',
          query: { id, remainderType },
        },
        { skipNull: true, encode: false },
      )

      return await MonitoringApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setCurrentRemainders(
    id: string,
    remainders: MonRemaindersResponse,
    remainderType: eMonRemainderType,
  ): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/monitoring/currentRemainders/edit',
          query: { id, remainderType },
        },
        {
          skipNull: true,
          encode: false,
        },
      )

      return await MonitoringApi.requestService.put(url, remainders)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCurrentValuablesRemainders(
    props: GetCurrentValuablesRemaindersProps,
  ): Promise<MonValuableRemainder[]> {
    const { id, sessionDate } = props

    try {
      const url = queryString.stringifyUrl(
        {
          url: '/monitoring/currentValuablesRemainders',
          query: { guid: id, sessionDate },
        },
        {
          skipNull: true,
          encode: false,
        },
      )

      return await MonitoringApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setCurrentValuablesRemainders(
    props: SetCurrentValuablesRemaindersProps,
  ): Promise<void> {
    const { id, sessionDate, remainders } = props

    try {
      const url = queryString.stringifyUrl(
        {
          url: '/monitoring/currentValuablesRemainders/edit',
          query: { guid: id, sessionDate },
        },
        { skipNull: true, encode: false },
      )

      return await MonitoringApi.requestService.put(url, remainders)
    } catch (e) {
      throw new Error(e)
    }
  }

  // Mon Start Day
  public static async getStartDay(id: string): Promise<MonRemaindersResponse> {
    try {
      return await MonitoringApi.requestService.get(`/monitoring/dayStart?id=${id}`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setStartDay(id: string): Promise<void> {
    try {
      return await MonitoringApi.requestService.post(`/monitoring/dayStart/apply?id=${id}`, {})
    } catch (e) {
      throw new Error(e)
    }
  }

  // Working Time End
  public static async getWorkingTimeEnd(id: string): Promise<MonRemaindersResponse> {
    try {
      return await MonitoringApi.requestService.get(`/monitoring/dayEnd?id=${id}`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setWorkingTimeEnd(id: string): Promise<void> {
    try {
      return await MonitoringApi.requestService.post(`/monitoring/dayEnd/apply?id=${id}`, {})
    } catch (e) {
      throw new Error(e)
    }
  }

  // Worn Out Cash
  public static async getWornOutCash(id: string): Promise<MonRemaindersResponse> {
    try {
      return await MonitoringApi.requestService.get(`/monitoring/wornOutCash?id=${id}`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setWornOutCash(id: string, remainders: MonRemaindersResponse): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/monitoring/wornOutCash/edit',
          query: { id },
        },
        {
          skipNull: true,
          encode: false,
        },
      )

      return await MonitoringApi.requestService.put(url, remainders)
    } catch (e) {
      throw new Error(e)
    }
  }

  // Close Night Depository
  public static async getCloseNightDepository(id: string): Promise<MonRemaindersResponse> {
    try {
      return await MonitoringApi.requestService.get(`/monitoring/nightDeposit?id=${id}`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setCloseNightDepository(id: string): Promise<void> {
    try {
      return await MonitoringApi.requestService.post(`/monitoring/nightDeposit/apply?id=${id}`, {})
    } catch (e) {
      throw new Error(e)
    }
  }

  // Cash Limits
  public static async getCashLimits(id: string): Promise<MonCashLimitsResponse> {
    try {
      return await MonitoringApi.requestService.get(`/monitoring/cashLimits?id=${id}`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async addCashLimits(limit: MonCashLimitBase): Promise<void> {
    try {
      return await MonitoringApi.requestService.post('/monitoring/cashLimits/add', limit)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async editCashLimits(limit: MonCashLimit): Promise<void> {
    try {
      return await MonitoringApi.requestService.put('/monitoring/cashLimits/update', limit)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async updateAbsRemainders(): Promise<void> {
    try {
      return await MonitoringApi.requestService.get('/monitoring/updateAbsRemainders')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCharts(monFilter: MonChartsFilter): Promise<CashPointDetails> {
    try {
      const forecast = await MonitoringApi.requestService.post('/monitoring/vaultDetails', {
        monFilter,
      })

      return mapForecast(forecast)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async revertBalance(payload: MonUnloadCashierToVaultRequest): Promise<void> {
    try {
      return await MonitoringApi.requestService.post('/monitoring/cashier/unloadToVault', payload)
    } catch (e) {
      throw new Error(e)
    }
  }
}
