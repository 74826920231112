import { createStyles, makeStyles } from '@material-ui/core/styles'
import colors from '@/theme/colors'

export const useClasses = makeStyles(({ spacing, shadows }) =>
  createStyles({
    table: {
      flex: 1,
      overflow: 'auto',
      tableLayout: 'fixed',
    },
    tableNonFixed: {
      tableLayout: 'inherit',
    },
    actionCell: {
      width: spacing(15),
    },
    loaderWrapper: {
      height: spacing(25),
      width: spacing(5),
      margin: 'auto',
    },
    emptyRowDivider: {
      height: 0,
      padding: 0,
    },
    tableRowClickable: {
      cursor: 'pointer',
    },
    tableRowHighlight: {
      boxShadow: shadows[3],
    },
    root: {
      '& span': {
        fontWeight: 600,
      },
    },
    dotMenu: {
      width: spacing(7),
    },
    activeRow: {
      backgroundColor: colors.grey50,
    },
    subHeaderRow: {
      '& span': {
        fontWeight: 600,
        fontSize: 12,
      },
    },
  }),
)
