import { AnyAction } from 'redux'

import {
  AdmVault,
  AdmVaultAccount,
  AdmVaultLimit,
  AdmVaultsFilter,
  AdmVaultSumCalculationMethod,
  SelectorItem,
} from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants/actions'
import { SelectableItem } from '@/types'

export type State = {
  objects: {
    filter: AdmVaultsFilter
    total: number
    data: AdmVault[]
    isLoading: boolean
    isLoaded: boolean
    selectedObject: AdmVault | null
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined
    paging: {
      page: number
      pageSize: number
    }

    modals: {
      isChangingLoading: boolean
      isAddEditModal: boolean
      isLimitModal: boolean
    }

    vaultsToAssign: {
      data: SelectorItem[]
      isLoading: boolean
    }

    vaultsTypes: {
      data: SelectableItem[]
      isLoading: boolean
    }
  }

  limits: {
    filter: { branchId?: string; vaultId?: string }
    vaultId?: string
    total: number
    data: AdmVaultLimit[]
    isLoading: boolean
    isLoaded: boolean
    selectedLimit: AdmVaultLimit | null
    sortColumn: string | undefined
    sortOrder: 'asc' | 'desc' | undefined
  }

  accounts: {
    vault: string
    data: AdmVaultAccount[]
    isLoading: boolean
    isLoaded: boolean
    selected: AdmVaultAccount | null
  }

  calculationMethods: {
    data: AdmVaultSumCalculationMethod[]
    isLoading: boolean
    isLoaded: boolean
  }
}

const initialState: State = {
  objects: {
    filter: {
      address: undefined,
      citId: undefined,
      objectName: undefined,
    },
    total: 0,
    data: [],
    isLoaded: false,
    isLoading: false,
    selectedObject: null,
    sortColumn: undefined,
    sortOrder: undefined,
    paging: {
      page: 1,
      pageSize: 50,
    },

    modals: {
      isChangingLoading: false,
      isAddEditModal: false,
      isLimitModal: false,
    },

    vaultsToAssign: {
      data: [],
      isLoading: false,
    },

    vaultsTypes: {
      data: [],
      isLoading: false,
    },
  },

  limits: {
    filter: {
      branchId: undefined,
      vaultId: undefined,
    },
    vaultId: undefined,
    total: 0,
    data: [],
    isLoading: false,
    isLoaded: false,
    selectedLimit: null,
    sortColumn: undefined,
    sortOrder: undefined,
  },

  accounts: {
    vault: '',
    data: [],
    isLoading: false,
    isLoaded: false,
    selected: null,
  },

  calculationMethods: {
    data: [],
    isLoading: false,
    isLoaded: false,
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    // [START] OBJECTS ---------------------------------------------------
    case VaultV1Actions.AdmSetObjectsFilter:
      return {
        ...state,
        objects: {
          ...state.objects,
          filter: payload,
          isLoading: true,
        },
      }

    case VaultV1Actions.AdmGetObjectsRequest:
    case VaultV1Actions.AdmDeleteObjectRequest:
      return {
        ...state,
        objects: {
          ...state.objects,
          isLoading: true,
        },
      }
    case VaultV1Actions.AdmGetObjectsResponse:
      return {
        ...state,
        objects: {
          ...state.objects,
          isLoading: false,
          isLoaded: true,
          data: payload.vaults,
          total: payload.total,
          selectedObject: state.objects.selectedObject || payload.vaults[0],
        },
      }
    case VaultV1Actions.AdmGetObjectsFail:
    case VaultV1Actions.AdmDeleteObjectFail:
    case VaultV1Actions.AdmAddObjectFail:
    case VaultV1Actions.AdmEditObjectFail:
      return {
        ...state,
        objects: {
          ...state.objects,
          isLoading: false,
          isLoaded: true,
        },
      }
    case VaultV1Actions.AdmSetObjectsPaging:
      return {
        ...state,
        objects: {
          ...state.objects,
          isLoading: true,
          paging: {
            ...state.objects.paging,
            ...payload,
          },
        },
      }
    case VaultV1Actions.AdmResetObjectsPaging:
      return {
        ...state,
        objects: {
          ...state.objects,
          isLoading: true,
          paging: {
            ...state.objects.paging,
            page: initialState.objects.paging.page || 1,
          },
        },
      }
    case VaultV1Actions.AdmSetSelectedObject:
      return {
        ...state,
        objects: {
          ...state.objects,
          selectedObject: payload,
        },
      }

    case VaultV1Actions.AdmSortObjects:
      return {
        ...state,
        objects: {
          ...state.objects,
          ...payload,
          isLoading: true,
        },
      }
    case VaultV1Actions.AdmAddObjectRequest:
    case VaultV1Actions.AdmEditObjectRequest:
      return {
        ...state,
        objects: {
          ...state.objects,
          isLoading: true,
        },
      }

    case VaultV1Actions.AdmDeleteObjectResponse:
      return {
        ...state,
        objects: {
          ...state.objects,
          selectedObject: null,
        },
      }

    case VaultV1Actions.AdmGetObjectRequest:
      return {
        ...state,
        objects: {
          ...state.objects,
          isLoading: true,
        },
      }
    case VaultV1Actions.AdmGetObjectResponse: {
      const newData = [...state.objects.data]
      const refreshedObjIndex = newData.findIndex(el => el.id === payload.id)

      if (~refreshedObjIndex) {
        newData[refreshedObjIndex] = payload

        return {
          ...state,
          objects: {
            ...state.objects,
            data: newData,
            selectedObject: payload,
            isLoading: false,
          },
        }
      }
      return state
    }
    case VaultV1Actions.AdmGetObjectFail:
      return {
        ...state,
        objects: {
          ...state.objects,
          isLoading: false,
        },
      }

    case VaultV1Actions.AdmGetVaultsToAssignRequest:
      return {
        ...state,
        objects: {
          ...state.objects,
          vaultsToAssign: {
            ...state.objects.vaultsToAssign,
            isLoading: true,
          },
        },
      }
    case VaultV1Actions.AdmClearVaultsToAssign:
    case VaultV1Actions.AdmGetVaultsToAssignResponse:
      return {
        ...state,
        objects: {
          ...state.objects,
          vaultsToAssign: {
            ...state.objects.vaultsToAssign,
            data: payload,
            isLoading: false,
          },
        },
      }

    case VaultV1Actions.AdmGetVaultsTypesRequest:
      return {
        ...state,
        objects: {
          ...state.objects,
          vaultsTypes: {
            ...state.objects.vaultsTypes,
            isLoading: true,
          },
        },
      }
    case VaultV1Actions.AdmGetVaultsTypesResponse:
    case VaultV1Actions.AdmClearVaultsTypes:
      return {
        ...state,
        objects: {
          ...state.objects,
          vaultsTypes: {
            ...state.objects.vaultsTypes,
            data: payload,
            isLoading: false,
          },
        },
      }

    // [END] OBJECTS -----------------------------------------------------

    // [START] LIMITS ----------------------------------------------------
    case VaultV1Actions.AdmGetLimitsRequest:
    case VaultV1Actions.AdmAddLimitRequest:
    case VaultV1Actions.AdmEditLimitRequest:
      return {
        ...state,
        limits: {
          ...state.limits,
          isLoading: true,
        },
      }

    case VaultV1Actions.AdmGetLimitsFail:
    case VaultV1Actions.AdmAddLimitFail:
    case VaultV1Actions.AdmEditLimitFail:
      return {
        ...state,
        limits: {
          ...state.limits,
          isLoading: false,
        },
      }

    case VaultV1Actions.AdmGetLimitsResponse:
      return {
        ...state,
        limits: {
          ...state.limits,
          isLoading: false,
          isLoaded: true,
          data: payload,
          total: (payload || []).length,
        },
      }

    case VaultV1Actions.AdmSetSelectedLimit:
      return {
        ...state,
        limits: {
          ...state.limits,
          selectedLimit: payload,
        },
      }
    case VaultV1Actions.AdmSortLimits:
      return {
        ...state,
        limits: {
          ...state.limits,
          ...payload,
          isLoading: true,
        },
      }
    case VaultV1Actions.AdmObjOpenLimitModal:
      return {
        ...state,
        objects: {
          ...state.objects,
          modals: {
            ...state.objects.modals,
            isLimitModal: payload.isOpen,
          },
        },
        limits: {
          ...state.limits,
          ...payload,
          vaultId: payload.vaultId,
        },
      }
    // [END] LIMITS ------------------------------------------------------

    // [START] ACCOUNTS ----------------------------------------------------
    case VaultV1Actions.AdmGetAccountsRequest:
    case VaultV1Actions.AdmAddAccountRequest:
    case VaultV1Actions.AdmEditAccountRequest:
    case VaultV1Actions.AdmDeleteAccountRequest:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          isLoading: true,
        },
      }
    case VaultV1Actions.AdmGetAccountsResponse: {
      return {
        ...state,
        accounts: {
          ...state.accounts,
          isLoading: false,
          isLoaded: true,
          data: payload.accounts || [],
        },
      }
    }
    case VaultV1Actions.AdmGetAccountsFail:
    case VaultV1Actions.AdmAddAccountFail:
    case VaultV1Actions.AdmEditAccountFail:
    case VaultV1Actions.AdmDeleteAccountFail:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          isLoading: false,
          isLoaded: true,
        },
      }
    case VaultV1Actions.AdmSetSelectedAccount:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          selected: payload || null,
        },
      }
    // [END] ACCOUNTS -----------------------------------------------------

    // [START] CALCULATION METHODS ------------------------------------------------------------
    case VaultV1Actions.AdmGetCalculationMethodsRequest:
    case VaultV1Actions.AdmAddCalculationMethodsRequest:
    case VaultV1Actions.AdmDeleteCalculationMethodsRequest:
      return {
        ...state,
        calculationMethods: {
          ...state.calculationMethods,
          isLoading: true,
        },
      }
    case VaultV1Actions.AdmGetCalculationMethodsResponse:
      return {
        ...state,
        calculationMethods: {
          ...state.calculationMethods,
          isLoading: false,
          isLoaded: true,
          data: payload,
        },
      }
    case VaultV1Actions.AdmGetCalculationMethodsFail:
    case VaultV1Actions.AdmAddCalculationMethodsFail:
    case VaultV1Actions.AdmDeleteCalculationMethodsFail:
      return {
        ...state,
        calculationMethods: {
          ...state.calculationMethods,
          isLoading: false,
          isLoaded: true,
        },
      }
    // [END] CALCULATION METHODS --------------------------------------------------------------

    case VaultV1Actions.SignOut:
      return initialState
    default:
      return state
  }
}
