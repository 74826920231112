import { Tooltip } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import _ from 'lodash'
import React, { ReactElement, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import Test from 'react-test-attributes'

import Columns from '@/assets/sd/columns.svg'
import Download from '@/assets/sd/download.svg'
import Filters from '@/assets/sd/filters.svg'
import Grouping from '@/assets/sd/grouping.svg'
import Button from '@/components/controls/Button'
import { Select } from '@/components/controls/Select/component'
import ColumnsMenu from '@/components/pages/sd/TicketsPage/components/ColumnsMenu/component'
import { downloadRows } from '@/components/pages/sd/TicketsPage/components/TableHeader/data'
import { useSelectDownloadPageCount } from '@/components/pages/sd/TicketsPage/helpers/hooks/useSelectChange'
import { ATMEYE_BLOCK_PREFIX, SD_BLOCK_PREFIX, USM_BLOCK_PREFIX } from '@/constants/paths'
import { getGrouping, getSelectedFilters } from '@/store/sd/tickets/selectors'
import { translate } from '@/utils/internalization'

import { useClasses } from './styles'
import { Props } from './types'

const TableHeader = ({
  testId,
  handleSetGrouping,
  handleSetFiltersModal,
  handleShowColumnsMenu,
  idPrefix,
  columns,
  keys,
  columnsMenuAnchorEl,
  setTableSettingsToLocalStorage,
  handleColumnsChange,
  handleCloseViewSettings,
  lazyLoadingPage,
}: Props): ReactElement => {
  const classes = useClasses()
  const selectedFilters = useSelector(getSelectedFilters)
  const grouping = useSelector(getGrouping)

  const { currentPage, handlePageSizeChange } = useSelectDownloadPageCount('10')
  const validate = useCallback(() => {
    const newKeys = keys.slice().sort()
    const newColumns = columns.slice().sort()

    return !_.isEqual(newKeys, newColumns)
  }, [keys, columns])

  return (
    <Test id={testId(1)}>
      <Grid
        className={classes.initialWidth}
        container
        alignItems="center"
        justifyContent="flex-end"
        direction="row"
        spacing={2}
      >
        {lazyLoadingPage && (
          <>
            <Grid item>
              <FormattedMessage id="title.rowsPerGroup" defaultMessage="Rows per group:" />
            </Grid>
            <Grid item>
              <Select
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                options={downloadRows}
                hideEmptyItem
                value={currentPage}
                onChange={handlePageSizeChange}
                IconComponent={ArrowDropDownIcon}
                classes={{ rootFormControl: classes.quantityRoot, icon: classes.iconSelect }}
              />
            </Grid>
          </>
        )}

        <Test id={testId(2)}>
          <Grid item>
            <Tooltip
              placement="top"
              classes={{ tooltip: classes.toolTip }}
              title={translate('translate#table.header.button.grouping')}
            >
              <div>
                <Button
                  className={classes.tableHeaderButton}
                  onClick={handleSetGrouping(true)}
                  startIcon={<img src={Grouping} />}
                  activeButtonMark={!_.isEmpty(grouping)}
                />
              </div>
            </Tooltip>
          </Grid>
        </Test>
        <Test id={testId(3)}>
          <Grid item>
            <Tooltip
              placement="top"
              classes={{ tooltip: classes.toolTip }}
              title={translate('translate#table.header.button.filter')}
            >
              <div>
                <Button
                  className={classes.tableHeaderButton}
                  onClick={handleSetFiltersModal(true)}
                  startIcon={<img src={Filters} />}
                  activeButtonMark={!_.isEmpty(selectedFilters)}
                />
              </div>
            </Tooltip>
          </Grid>
        </Test>
        <Test id={testId(4)}>
          <Grid item>
            <Tooltip
              placement="top"
              classes={{ tooltip: classes.toolTip }}
              title={translate('translate#table.header.button.columns')}
            >
              <div>
                <Button
                  className={classes.tableHeaderButton}
                  startIcon={<img src={Columns} />}
                  onClick={handleShowColumnsMenu}
                  activeButtonMark={validate()}
                />
              </div>
            </Tooltip>
            <Test id={testId(7)}>
              <ColumnsMenu
                testIdPrefix={idPrefix}
                fields={columns}
                currentFields={keys}
                anchorEl={columnsMenuAnchorEl}
                setTableSettingsToLocalStorage={setTableSettingsToLocalStorage}
                handleSave={handleColumnsChange}
                handleClose={handleCloseViewSettings}
              />
            </Test>
          </Grid>
        </Test>
        {window.location.pathname.includes(ATMEYE_BLOCK_PREFIX) && (
          <Test id={testId(4)}>
            <Grid item>
              <Tooltip
                placement="top"
                classes={{ tooltip: classes.toolTip }}
                title={translate('translate#table.header.button.save')}
              >
                <div>
                  <a
                    href="https://file-examples-com.github.io/uploads/2017/02/file_example_XLS_10.xls"
                    download
                  >
                    <Button
                      className={classes.tableHeaderButton}
                      startIcon={<img src={Download} />}
                    />
                  </a>
                </div>
              </Tooltip>
            </Grid>
          </Test>
        )}
      </Grid>
    </Test>
  )
}

export default TableHeader
