import 'moment/locale/en-gb'
import 'moment/locale/ru'
import 'moment/locale/lt'
import 'moment/locale/lv'
import 'moment/locale/et'

import {
  AZERBAIJAN,
  ENGLISH,
  ESTONIAN,
  GEORGIAN,
  KAZAKHSTAN,
  KYRGYZSTAN,
  LATVIAN,
  LITHUANIAN,
  MOLDOVA,
  RUSSIAN,
  UZBEKISTAN,
} from '@/constants'

export const commonLanguages = [
  { label: 'EN', value: ENGLISH, code: 'gb' },
  { label: 'RU', value: RUSSIAN, code: 'ru' },
  { label: 'LT', value: LITHUANIAN, code: 'lt' },
  { label: 'LV', value: LATVIAN, code: 'lv' },
  { label: 'ET', value: ESTONIAN, code: 'ee' },
]

export const atmeyeLanguages = [
  ...commonLanguages,
  { label: 'KA', value: GEORGIAN, code: 'ge' },
  { label: 'KZ', value: KAZAKHSTAN, code: 'kz' },
  { label: 'KG', value: KYRGYZSTAN, code: 'kg' },
  { label: 'UZ', value: UZBEKISTAN, code: 'uz' },
  { label: 'MD', value: MOLDOVA, code: 'md' },
  { label: 'AZ', value: AZERBAIJAN, code: 'az' },
]
