import { AnyAction } from 'redux'

import { AdmValuable, AdmValuableBase, ValuableLanguage } from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions } from '@/constants/actions'
import { Sort } from '@/types/cm'

// valuables languages
export const getAdmValuablesLanguagesRequest = (): AnyAction => ({
  type: VaultV1Actions.AdmGetValuablesLanguagesRequest,
})
export const getAdmValuablesLanguagesResponse = (payload: ValuableLanguage[]): AnyAction => ({
  type: VaultV1Actions.AdmGetValuablesLanguagesResponse,
  payload,
})

// get App
export const getAdmValuablesRequest = (): AnyAction => ({
  type: VaultV1Actions.AdmGetValuablesRequest,
})
export const getAdmValuablesResponse = (payload: AdmValuable[]): AnyAction => ({
  type: VaultV1Actions.AdmGetValuablesResponse,
  payload,
})
export const getAdmValuablesFail = (): AnyAction => ({
  type: VaultV1Actions.AdmGetValuablesFail,
})

// add
export const addAdmValuableRequest = (payload: AdmValuableBase): AnyAction => ({
  type: VaultV1Actions.AdmAddValuableRequest,
  payload,
})
export const addAdmValuableResponse = (): AnyAction => ({
  type: VaultV1Actions.AdmAddValuableResponse,
})
export const addAdmValuableFail = (): AnyAction => ({
  type: VaultV1Actions.AdmAddValuableFail,
})

// update
export const updateAdmValuableRequest = (payload: AdmValuable): AnyAction => ({
  type: VaultV1Actions.AdmUpdateValuableRequest,
  payload,
})
export const updateAdmValuableResponse = (): AnyAction => ({
  type: VaultV1Actions.AdmUpdateValuableResponse,
})
export const updateAdmValuableFail = (): AnyAction => ({
  type: VaultV1Actions.AdmUpdateValuableFail,
})

// delete
export const deleteAdmValuableRequest = (): AnyAction => ({
  type: VaultV1Actions.AdmDeleteValuableRequest,
})
export const deleteAdmValuableFail = (): AnyAction => ({
  type: VaultV1Actions.AdmDeleteValuableFail,
})

export const setAdmValuableSelected = (payload: AdmValuable | null): AnyAction => ({
  type: VaultV1Actions.AdmSetSelectedValuable,
  payload,
})

export const sortAdmValuables = (payload: Sort): AnyAction => ({
  type: VaultV1Actions.AdmSortValuables,
  payload,
})
