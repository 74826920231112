export enum UserTypes {
  Collector = 'Collector',
  Cashier = 'Cashier',
  SubVault = 'SubVault',
}

export enum VaultTypes {
  Vault = 'Vault',
  CashProvider = 'CashProvider',
  CorporateClient = 'CorporateClient',
  BankBranch = 'BankBranch',
  Cashier = 'Cashier',
  CentralBank = 'CentralBank',
  CommercialBank = 'CommercialBank',
  SubVault = 'SubVault',
}

export enum OrderType {
  Cash = 'Cash',
  Valuables = 'Valuables',
  Cassettes = 'Cassettes',
  Bags = 'Bags',
  CashDeskBalance = 'CashDeskBalance',
}
