import { AppState } from '@/store/reducers'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'

const checkPermissions = (
  allUserPermissions: { [key: string]: boolean },
  allowedPermissions: string[],
): boolean => {
  if (allowedPermissions.length === 0) {
    return true
  }

  const userPermissions = _.pickBy(allUserPermissions, function(value, key) {
    return value
  })

  return Object.keys(userPermissions).some((permission: any) => {
    return allowedPermissions.includes(permission)
  })
}

const AccessControl = ({
  allUserPermissions,
  allowedPermissions,
  children,
  renderNoAccess,
}: any): React.ReactElement => {
  const permitted = checkPermissions(allUserPermissions, allowedPermissions)

  if (permitted) {
    return children
  }
  return renderNoAccess()
}

AccessControl.defaultProps = {
  allowedPermissions: [],
  allUserPermissions: {},
  renderNoAccess: () => null,
}

// Compose AccessControl component with redux
export default connect((state: AppState) => ({
  allUserPermissions: state.auth.currentAccount !== null && state.auth.userPermissions,
}))(AccessControl)
