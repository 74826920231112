export enum CriticTermsField {
  Id = 'id',
  Company = 'company',
  CriticTermName = 'criticTermName',
  RequestCategory = 'requestCategory',
  ControlType = 'controlType',
  TermPeriod = 'termPeriod',
  QuantityFrom = 'quantityFrom',
  QuantityTo = 'quantityTo',
  Priority = 'priority',
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  PeriodByBusiness = 'periodByBusiness',
}
export interface BaseTerm {
  [CriticTermsField.Company]: string
  [CriticTermsField.CriticTermName]: string
  [CriticTermsField.RequestCategory]: string
  [CriticTermsField.ControlType]: string
  [CriticTermsField.QuantityFrom]: string
  [CriticTermsField.QuantityTo]: string
  [CriticTermsField.Priority]: string
  [CriticTermsField.DateFrom]: string
  [CriticTermsField.DateTo]: string
  [CriticTermsField.PeriodByBusiness]: string
}

export interface CriticTerm extends BaseTerm {
  [CriticTermsField.Id]: string | number
  [CriticTermsField.TermPeriod]: string
}

export interface CriticTermDate {
  id: string | number
  date: string
}
