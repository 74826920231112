import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React, { useEffect } from 'react'
import { isIE } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import CloseButton from '@/components/blocks/CloseButton'
import AppTable from '@/components/controls/AppTable'
import { getTicketDetailsRequest } from '@/store/sd/tickets/actions'
import { getTicketDetails, isFetchingTicketDetails } from '@/store/sd/tickets/selectors'
import { TicketFields } from '@/types'
import { useTranslate } from '@/utils/internalization'

import RenderFiles from './components/RenderFiles'
import { useClasses } from './styles'
import { Props } from './types'

const TicketDetailModal = ({ open, ticketId, handleClose }: Props): React.ReactElement => {
  const ticketDetails = useSelector(getTicketDetails)
  const isLoading = useSelector(isFetchingTicketDetails)

  const dispatch = useDispatch()
  const translate = useTranslate()
  const props = { display: isIE ? 'block' : 'flex' }
  const classes = useClasses(props)

  useEffect((): void => {
    if (ticketId) {
      dispatch(getTicketDetailsRequest(ticketId))
    }
  }, [dispatch, ticketId])

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      keepMounted
      onClose={handleClose}
      disableBackdropClick
    >
      <CloseButton absolute onClick={handleClose} />
      <div className={classes.wrapper}>
        <div className={classes.title}>
          <Typography variant="h5">
            <FormattedMessage id="title.ticketDetails" defaultMessage="Ticket details" /> -{' '}
            <span className={classes.ticketNumber}>
              {ticketDetails.ticketInfo.ticketNumber || 'Ticked ID'}
            </span>
            {ticketDetails.ticketInfo.customerRequestNumber
              ? ` ${translate('translate#title.customerRequestNumber')} - ${
                  ticketDetails.ticketInfo.customerRequestNumber
                }`
              : ''}
          </Typography>
        </div>
        <Grid className={classes.grid} spacing={2} container>
          <Grid item md={12}>
            <AppTable
              columnDisplayOrder={[
                TicketFields.LUNO,
                TicketFields.ReceiveDate,
                TicketFields.Address,
                TicketFields.DeviceType,
                TicketFields.Model,
              ]}
              tableClasses={{
                paper: classes.tableWrap,
              }}
              isLoading={isLoading}
              data={[ticketDetails.device]}
            />
          </Grid>
          <Grid item md={12}>
            <AppTable
              columnDisplayOrder={[
                TicketFields.Status,
                TicketFields.SlaDeadlineDate,
                TicketFields.SuspendedUntil,
                TicketFields.SuspendedReason,
                TicketFields.SuspendedNotes,
              ]}
              tableClasses={{
                paper: classes.tableWrap,
              }}
              isLoading={isLoading}
              data={[ticketDetails.slaDetails]}
            />
          </Grid>
          <Grid item md={12}>
            <Box fontWeight="fontWeightBold">
              <Typography className={classes.sectionTitle} variant="h6">
                <FormattedMessage id="title.description" defaultMessage="Description" />
              </Typography>
            </Box>
          </Grid>
          <Grid item md={12}>
            {ticketDetails.ticketInfo.description}
          </Grid>
          <Grid item md={12}>
            <Box fontWeight="fontWeightBold">
              <Typography className={classes.sectionTitle} variant="h6">
                <FormattedMessage id="title.job.details" defaultMessage="Job Details" />
              </Typography>
            </Box>
          </Grid>
          <Grid item md={12}>
            <AppTable
              columnDisplayOrder={[
                TicketFields.WorkStart,
                TicketFields.WorkEnd,
                TicketFields.ServiceItems,
                TicketFields.CloseNotes,
              ]}
              isLoading={isLoading}
              data={ticketDetails.jobDetails ? [ticketDetails.jobDetails] : []}
              tableClasses={{
                cell: classes.cell,
              }}
            />
          </Grid>
          <Grid item md={12}>
            {ticketDetails.attachedFiles?.map(file => (
              <RenderFiles
                key={file.id}
                fileInfo={file}
                ticketId={ticketId || ticketDetails?.ticketInfo?.ticketId || ''}
              />
            ))}
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

export default TicketDetailModal
