import makeStyles from '@material-ui/core/styles/makeStyles'

const PADDING_TITLE_AND_BUTTONS_HEIGHT = 244

interface Props {
  filtersIsVisible: boolean
  modalHeight: number | null
}

export const useClasses = makeStyles(({ spacing }) => ({
  subTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'normal',
    marginBottom: spacing(1),
  },
  tableContainerClassName: {
    maxHeight: ({ filtersIsVisible, modalHeight }: Props) =>
      !filtersIsVisible && modalHeight
        ? modalHeight - PADDING_TITLE_AND_BUTTONS_HEIGHT
        : spacing(40),
  },
  headColumn: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
}))
