import {
  AdmLicenseFilter,
  AdmLicenseInfo,
  ComOnDemandDataEntry,
} from '@/api/cm/swaggerGeneratedApi'
import { APIConfiguration, LocalStorageItems } from '@/constants'
import { PopUpService } from '@/utils/services/popUpService'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class LicenseAdminApi {
  private static requestService = getRequestServiceCM()

  public static async get(): Promise<AdmLicenseInfo> {
    try {
      return await LicenseAdminApi.requestService.get('/admin/license')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getReports(filter: AdmLicenseFilter): Promise<ComOnDemandDataEntry[]> {
    try {
      return await LicenseAdminApi.requestService.post('/admin/license/reports', { filter })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async upload(file: File): Promise<void> {
    try {
      await LicenseAdminApi.requestService.postComplete('/admin/license/upload', file)
    } catch (error) {}
  }

  public static async export(): Promise<Blob | undefined> {
    try {
      const tokenCM = localStorage.getItem(LocalStorageItems.AccessTokenCM) || ''

      const response = await fetch(`${APIConfiguration.CM_API_BASE_URL}/admin/license/export`, {
        headers: { Authorization: `Bearer ${tokenCM}` },
        method: 'POST',
      })

      if (response?.ok) {
        return await response.blob()
      } else {
        PopUpService.showGlobalPopUp('translate#error.CantExportLicense', 'error')
      }
    } catch (error) {
      PopUpService.showGlobalPopUp(JSON.parse((error as Error).message).message, 'error')
    }
  }
}
