import { ConditionallyCell, ConditionallyCellResult } from '@/components/blocks/cm/CMTable/types'

import { Sorter } from './types'

export const conditionallyRenderCell = ({
  key,
  value,
}: ConditionallyCell<Sorter>): ConditionallyCellResult => {
  switch (key) {
    default:
      return value
  }
}
