import { Field } from 'formik'
import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import { TicketsApi } from '@/api/sd/ticketsApi'
import { Option } from '@/components/blocks/ATMeye/modals/addWidgetModal/types'
import { createDatePickerRangeField } from '@/components/controls/DateInput'
import {
  createDateTimePickerRangeField,
  createDateTimePickerRangeFieldTransactions,
} from '@/components/controls/DateTimePickerTransactions'
import { createMultipleSelectAtmeye } from '@/components/controls/MultipleSelectAtemeye'
import { createSelectField } from '@/components/controls/Select'
import { createTextInputField } from '@/components/controls/TextInput'
import {
  FilterDisplayName,
  GenerateFormFieldsType,
  ValueTypes,
} from '@/components/pages/atmeye/Administration/LicenseList/ATMCoreLicenses/components/FiltersModalLicence/GenerateFormFields/types'

type FieldValue = Array<{
  isSelected: boolean
  valueId: string
  valueLabels: Array<string>
}>

const GenerateFormFields: FC<GenerateFormFieldsType> = ({
  disableFutureDates,
  formik: { setFieldValue, values },
  field: {
    label,
    required,
    fieldType,
    placeholder,
    disabled,
    options,
    fieldName,
    type,
    allowValidation,
    valueType = ValueTypes.Datetime,
  },
  adminSettings,
}: GenerateFormFieldsType): ReactElement => {
  const [defaultFieldsValue, setDefaultFieldsValue] = useState<FieldValue>([])

  const handleApply = useCallback(
    (selectedIds: Array<string>, options: Array<Option>): void => {
      const selectedOptions = selectedIds.reduce((acc, cur) => {
        const option = options.find(item => item.valueId === cur)

        if (option) {
          const labels = option?.valueLabels.join(', ') || ''

          acc.push({
            valueId: option.valueId,
            valueLabels: labels,
            isSelected: false,
          })
        }

        return acc
      }, [] as Array<{ valueId: string; valueLabels: string; isSelected: boolean }>)

      setFieldValue(fieldName, selectedOptions)
    },
    [fieldName, setFieldValue],
  )

  const loadFilterModalData = useCallback(
    async selectedFilters => {
      try {
        const currentField = selectedFilters[fieldName]

        const payload: Array<{
          entityType: string
          pageNumber: number
          pageSize: number
          searchFragment: string
          selectedIds: Array<number>
        }> = []

        const fieldEntityType = options?.multiselect?.dropdownListEntityType

        if (fieldEntityType && Array.isArray(currentField) && currentField.length > 0) {
          payload.push({
            entityType: fieldEntityType,
            pageNumber: 1,
            pageSize: 1,
            searchFragment: '',
            selectedIds: currentField.map((value: any) => Number(value?.valueId)),
          })
        }

        if (payload.length > 0) {
          const fieldsData = await TicketsApi.getDropdownListsData(payload)
          setDefaultFieldsValue(fieldsData[0].dropdownListElements)
        }
      } catch (error) {
        console.log(error)
      }
    },
    [fieldName, options],
  )

  useEffect(() => {
    loadFilterModalData(values)
  }, [loadFilterModalData, values])

  useEffect(() => {
    loadFilterModalData(values)
  }, [loadFilterModalData, values])

  const renderDateInput = useMemo(() => {
    return {
      [ValueTypes.Datetime]: allowValidation
        ? createDateTimePickerRangeFieldTransactions({
            required,
            isAtemeyFilterModal: true,
            title: label,
            disableFutureDates,
            params: adminSettings,
          })
        : createDateTimePickerRangeField({
            required,
            isAtemeyFilterModal: true,
            title: label,
            disableFutureDates,
            params: adminSettings,
          }),
      [ValueTypes.Date]: createDatePickerRangeField({
        isAtemeyFilterModal: true,
        required: true,
        title: label,
        disableFutureDates,
      }),
    } as Record<any, any>
  }, [required, label, disableFutureDates, adminSettings])

  const fieldConfig: { [key: string]: any } = useMemo(
    () => ({
      [FilterDisplayName.Select]: createSelectField({
        disabled,
        placeholder,
        label,
        options: options?.optionsSelect || [],
        fullWidth: true,
        renderValue: (selectValues: any): string => {
          if (!selectValues?.length) {
            return placeholder
          }
          return options?.optionsSelect?.find(({ value }) => value === selectValues)?.name || ''
        },
        hideEmptyItem: options?.hideEmptyItem,
      }),
      [FilterDisplayName.MULTISELECT]: createMultipleSelectAtmeye({
        label,
        fieldName,
        isDisabled: disabled,
        defaultValues: defaultFieldsValue,
        dropdownListEntityType: options?.multiselect?.dropdownListEntityType,
        placeholder,
        value:
          values[fieldName]?.length &&
          fieldType === FilterDisplayName.MULTISELECT &&
          values[fieldName][0] !== null
            ? values[fieldName]?.map((el: any) => el?.valueId)
            : [],

        onApply: handleApply,
      }),
      [FilterDisplayName.Range]: renderDateInput[valueType],

      [FilterDisplayName.Text]: createTextInputField({
        label,
        fullWidth: true,
        pattern: options?.textInput?.pattern,
        placeholder,
        clearButton: true,
        InputLabelProps: {
          shrink: true,
        },
        required: required,
        type,
      }),
    }),
    [
      label,
      fieldName,
      values,
      defaultFieldsValue,
      disabled,
      options,
      placeholder,
      fieldType,
      handleApply,
      required,
    ],
  )

  return <Field name={fieldName}>{fieldConfig[fieldType]}</Field>
}

export { GenerateFormFields }
