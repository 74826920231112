import queryString from 'query-string'

import { AdmRole, AdmRoleBase, AdmSpecificRole, ComRole } from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

interface AssignRole {
  roleId: string
  users: AdmSpecificRole[]
}

export class RolesApi {
  private static requestService = getRequestServiceCM()

  public static async get(sort: Sort): Promise<AdmRole[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/roles',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await RolesApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getBase(): Promise<ComRole[]> {
    try {
      return await RolesApi.requestService.get('admin/baseRoles')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getUsers(id: string): Promise<AdmSpecificRole[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/role/users',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await RolesApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async assign({ roleId, users }: AssignRole): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/role/users/assign',
          query: { id: roleId },
        },
        { skipNull: true, encode: false },
      )

      return await RolesApi.requestService.patch(url, users)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(payload: AdmRoleBase): Promise<void> {
    try {
      return await RolesApi.requestService.post('/admin/roles/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(payload: AdmRole): Promise<void> {
    try {
      return await RolesApi.requestService.put('/admin/roles/update', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(id: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/roles/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await RolesApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
