import { createSelector } from 'reselect'
import { AppState } from '@/store/reducers'
import * as fromReducer from './reducer'

export const getBalancingState = (state: AppState): fromReducer.State => state.cmBalancing

export const getBalCashPoints = createSelector(getBalancingState, state => state.cashPoints)

export const getBalCashPointsFilter = createSelector(
  getBalancingState,
  state => state.cashPoints.filter,
)

export const getBalModalState = createSelector(
  getBalancingState,
  ({ isFiltersModalOpen, isDocumentModalOpen, isSettingsModalOpen, isCashBalancingModalOpen }) => ({
    isFiltersModalOpen,
    isDocumentModalOpen,
    isSettingsModalOpen,
    isCashBalancingModalOpen,
  }),
)

export const getSelectedCashPoint = createSelector(
  getBalancingState,
  state => state.cashPoints.selectedCashPoint,
)

export const getSortersType = createSelector(getBalancingState, state => state.sortersType)
export const getSortersKey = createSelector(getBalancingState, state => state.sortersKey)
