export const isRouteCurrent = (
  locationPathName: string,
  routePathName: string | string[],
  includeSubRoutes = false,
): boolean => {
  if (!includeSubRoutes) {
    return !Array.isArray(routePathName)
      ? locationPathName === routePathName
      : routePathName.includes(locationPathName)
  }

  return !Array.isArray(routePathName)
    ? locationPathName.indexOf(routePathName) > -1
    : !!routePathName.find(path => locationPathName.indexOf(path) > -1)
}
