import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { withResizeDetector } from 'react-resize-detector'

import { TransactionsApi } from '@/api/atmeye/translactionsApi'
import { TransactionsTableCell } from '@/components/NPMPakage/components/tableComponents/rows/TransactionsTableCell/TransactionsTableCell'
import { TableHeader } from '@/components/NPMPakage/components/tableComponents/TableHeader'
import { UITable } from '@/components/NPMPakage/components/tableComponents/UITable/UITable'
import { defaultFiltersConfig } from '@/components/NPMPakage/hooks/defaultFilters/types'
import { LocalStorageItems } from '@/constants'
import { formikTransactionsValidate } from '@/helpers/atmeye/filtersValidations'
import { useDefaultFilters } from '@/utils/hooks/atmeye/useDefaultFilters'
import { useTranslate } from '@/utils/internalization'

import ActiveFilters from '../../../NPMPakage/components/ActiveFilters/component'
import { FiltersModalTransactions } from '../../../NPMPakage/components/modals/FiltersModal/FiltersModal'
import { GroupingModal } from '../../../NPMPakage/components/modals/GroupingModal'
import { ObjectsTree } from '../../../NPMPakage/components/ObjectsTree'
import { useSelectedGrouping } from '../../../NPMPakage/hooks/useSelectedGrouping'
import { StyledTableWrapper } from '../Devices/Components/MapPageWrapper/style'
import { LsPageTableKeys } from '../Events/hooks/types'
import { useTransactions } from './hooks/useTransactions'
// import ActiveFilters from './ActiveFilters/component'
// import { ObjectsTree } from './ObjectsTree'
// import { useSelectedGrouping } from './hooks/useSelectedGrouping'
// import { GroupingModal } from './GroupingModal'
import { PageWrapper, StyledPane, StyledSplitPane, useClasses } from './styles'

const TRANSACTION_GROUPS = [
  'deviceName',
  'eventTypeName',
  'eventMediaTypeName',
  'atmProcessDate',
  'localProcessDate',
]

const defaultLsKeys = {
  lsKeyGrouping: LocalStorageItems.TransactionsGrouping,
  lsKeyPagination: LocalStorageItems.TransactionsPagination,
  lsKeyColumns: LocalStorageItems.TransactionsColumns,
  lsKeyFilters: LocalStorageItems.TransactionsFilters,
  lsKeyFiltersTemplates: LocalStorageItems.TransactionsFilterTemplate,
  lsKeySorting: LocalStorageItems.TransactionsSort,
}

const TransactionsPage = ({
  initialHiddenColumns,
  title,
  requiredFilters,
  lsKeys = defaultLsKeys,
  filtersConfig = defaultFiltersConfig.transactions,
  width,
}: {
  title?: string
  initialHiddenColumns?: string[]
  requiredFilters?: Record<string, any>
  lsKeys?: LsPageTableKeys
  filtersConfig?: Record<string, any>
  width: number
}): React.ReactElement => {
  const { defaultFilters, handleResetDefaultFilters } = useDefaultFilters(filtersConfig)

  const {
    showGroupingModal,
    selectedGrouping,
    selectedGroupingTemplate,

    handleSetSelectedGrouping,
    handleClearGrouping,
    handleSetSelectedGroupingTemplate,

    handleSetShowGroupingModal,

    selectedGroupingNodes,
    setSelectedGroupingNodes,
  } = useSelectedGrouping({ localStorageKey: lsKeys.lsKeyGrouping })

  const {
    isLoading,
    isGroupsLoading,
    hasGrouping,

    sorting: { changeSorting, sorting },

    tableData,
    groupedData,
    pagination,
    refreshData,
    setTableData,

    controlsState,
    fields,
    filters: {
      selectedFilters,
      handleSubmit,
      handleSetFiltersModal,
      showFiltersModal,
      handleClearFilters,
      handleDeleteFilter,
      selectedFiltersTemplate,
      handleSetSelectedFilterTemplate,
    },
    headerColumns: {
      setColumns,
      columnsMenuAnchorEl,
      columns,
      setColumnsMenuAnchorEl,
      handleChangeColumns,
      allFields,
      currentFields,
    },
    exportTableData,
    filterView,
    getFilters,
    collapse,
    setCollapse,
  } = useTransactions({
    selectedGrouping,
    selectedGroupingNodes,
    setSelectedGroupingNodes,
    initialHiddenColumns,
    requiredFilters,
    lsKeys,
    defaultFilters,
    handleResetDefaultFilters,
    filtersConfig,
  })

  const classes = useClasses()
  const translate = useTranslate()

  const HeaderTitleJSX = (
    <Typography variant="h5" className={classes.tableTitle}>
      {title ?? translate('translate#atmeye.title.transactions')}
    </Typography>
  )

  return (
    <div className={classes.root}>
      <PageWrapper>
        <StyledSplitPane
          minSize={width / 8}
          maxSize={width / 1.5}
          split="vertical"
          defaultSize="15%"
          paneStyle={!hasGrouping ? { width: '100%' } : {}}
        >
          {hasGrouping && (
            <StyledPane>
              <div className={classes.treeWrapper}>
                {groupedData?.length ? (
                  <div style={{ width: '200px', height: '500px' }}>
                    <ObjectsTree
                      tree={groupedData || []}
                      isFetching={isGroupsLoading}
                      onChange={setSelectedGroupingNodes}
                      selectedGroupingNodes={selectedGroupingNodes}
                      title={translate('translate#atmeye.title.transactions')}
                    />
                  </div>
                ) : (
                  <Box className={classes.noTreeData}>
                    <Typography>{translate('translate#atmeye.title.emptyGroupsData')}</Typography>
                  </Box>
                )}
              </div>
            </StyledPane>
          )}

          <StyledPane>
            <StyledTableWrapper>
              <TableHeader
                columnsData={{
                  columnsMenuAnchorEl,
                  columns,
                  currentFields,
                  allFields,
                  onChangeColumns: handleChangeColumns,
                  setColumnsMenuAnchorEl,
                }}
                refreshData={refreshData}
                onClickFiltersButton={handleSetFiltersModal}
                tabsHeaderJSX={HeaderTitleJSX}
                selectedFilters={selectedFilters}
                onClickGroupingButton={handleSetShowGroupingModal}
                hasGroups={!!selectedGrouping.filter((group: any) => !!group)?.length}
                exportTableData={exportTableData}
                controlsState={controlsState}
              />

              <ActiveFilters
                filterView={filterView}
                onDeleteFilter={handleDeleteFilter}
                onClearFilters={handleClearFilters}
                requiredFilters={requiredFilters}
                // clearActiveFiltersBtnTitle={clearActiveFiltersBtnTitle}
                // config={config?.filter}
                // selectedFilters={selectedFilters}
              />

              <UITable
                isOnlyDirectionPagination
                hiddenSortIcons={['eventTypeName', 'cameraNumber', 'more']}
                rowIdKey="transactionId"
                isAlwaysWithControls
                className={classes.tableRoot}
                isFetching={isLoading}
                keyLabel="sortField"
                columns={columns}
                data={tableData}
                CellJSX={TransactionsTableCell}
                setColumns={setColumns}
                sorting={sorting}
                collapse={collapse}
                setCollapse={setCollapse}
                onSort={changeSorting}
                changePageSize={pagination.changeSize}
                changeCurrentPage={pagination.changePage}
                selectedRowId={null}
                pagination={pagination.paginationData}
                getUpdateRequest={{
                  mutateQueryTable: setTableData,
                  getUpdateTableRequest: ({ processNumber, deviceId, transactionId, filter }) => {
                    return TransactionsApi.getTransactionEvents({
                      processNumber,
                      deviceId,
                      transactionId,
                      sort: `${sorting?.fieldName},${sorting?.sortOrder}`,
                      filter: { ...getFilters(), ...filter },
                    })
                  },
                }}
              />
            </StyledTableWrapper>
          </StyledPane>
        </StyledSplitPane>
      </PageWrapper>

      {showFiltersModal && (
        <FiltersModalTransactions
          filterTemplatesType="transactionsFilterTemplates"
          formData={{
            initialValues: selectedFilters,
            onSubmit: handleSubmit,
            enableReinitialize: true,
          }}
          defaultValues={defaultFilters}
          open={showFiltersModal}
          handleClose={handleSetFiltersModal}
          filterFields={fields}
          selectedFilters={selectedFilters}
          selectedFiltersTemplate={selectedFiltersTemplate}
          handleApplyFilterTemplate={handleSetSelectedFilterTemplate}
          requiredFilters={requiredFilters}
          formikValidate={formikTransactionsValidate}
        />
      )}

      {showGroupingModal && (
        <GroupingModal
          groupingTemplatesType="transactionsGroupingTemplates"
          open={showGroupingModal}
          appliedGrouping={selectedGrouping}
          appliedGroupingTemplate={selectedGroupingTemplate}
          // defaultGrouping={[]}
          onApply={handleSetSelectedGrouping}
          onClose={handleSetShowGroupingModal}
          handleApplyGroupingTemplate={handleSetSelectedGroupingTemplate}
          groupsList={TRANSACTION_GROUPS}
          groupingLevel={4}
        />
      )}
    </div>
  )
}

export default withResizeDetector(TransactionsPage)
