import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'
import { useClasses } from './styles'
import clsx from 'clsx'
import { Props } from './types'

const CloseButton = ({
  absolute = false,
  className,
  ...props
}: IconButtonProps & Props): React.ReactElement => {
  const classes = useClasses()
  return (
    <IconButton
      size="small"
      className={clsx(classes.closeButton, { [classes.closeButtonAbsolute]: absolute }, className)}
      {...props}
    >
      <CloseIcon className={classes.icon} />
    </IconButton>
  )
}

export default React.memo(CloseButton)
