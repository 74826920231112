import HelpIcon from '@/assets/sd/help.svg'
import MapIcon from '@/assets/sd/map.svg'
import DashboardIcon from '@/assets/sd/my_dashboard.svg'
import ReportsIcon from '@/assets/sd/reports.svg'
import TicketsIcon from '@/assets/sd/tickets.svg'
import { AppNavigationRoutes, SD_BLOCK_PREFIX } from '@/constants'

export default [
  {
    path: `${SD_BLOCK_PREFIX}${AppNavigationRoutes.DashboardPage}`,
    label: 'translate#title.myDashboard',
    activeFor: [`${SD_BLOCK_PREFIX}${AppNavigationRoutes.DashboardPage}`],
    innerPages: [
      {
        path: `${SD_BLOCK_PREFIX}${AppNavigationRoutes.TicketPage}`,
        label: 'ticket',
      },
    ],
    allowedPermissions: ['SRV_ClWEB_SearchRequests_Open_ALL'],
    Icon: DashboardIcon,
  },
  {
    label: 'translate#title.tickets',
    Icon: TicketsIcon,
    allowedPermissions: [
      'SRV_ClWEB_SearchRequests_Open_ALL',
      'SRV_ClWEB_SearchRequests_Open_MY',
      'SRV_ClWEB_SearchRequests',
    ],
    subItems: [
      {
        path: `${SD_BLOCK_PREFIX}${AppNavigationRoutes.AllTicketsPage}`,
        label: 'translate#title.allTickets',
        allowedPermissions: ['SRV_ClWEB_SearchRequests_Open_ALL'],
      },
      {
        path: `${SD_BLOCK_PREFIX}${AppNavigationRoutes.TicketsPage}`,
        label: 'translate#title.myTickets',
        allowedPermissions: ['SRV_ClWEB_SearchRequests_Open_MY'],
      },
      {
        path: `${SD_BLOCK_PREFIX}${AppNavigationRoutes.HistoryTicketsPage}`,
        label: 'translate#title.historyTickets',
        allowedPermissions: ['SRV_ClWEB_SearchRequests'],
      },
    ],
  },
  {
    label: 'translate#title.reports',
    Icon: ReportsIcon,
    allowedPermissions: ['ReportMetaGadget'],
    subItems: [
      {
        path: `${SD_BLOCK_PREFIX}${AppNavigationRoutes.AllReportsPage}`,
        label: 'translate#title.allReports',
        allowedPermissions: ['ReportMetaGadget'],
      },
      {
        path: `${SD_BLOCK_PREFIX}${AppNavigationRoutes.ReportsPage}`,
        label: 'translate#title.myReports',
        allowedPermissions: ['ReportMetaGadget'],
      },
      {
        path: `${SD_BLOCK_PREFIX}${AppNavigationRoutes.ReportsLazyPage}`,
        label: 'translate#title.lazyReports',
        allowedPermissions: ['ReportMetaGadget'],
      },
    ],
  },
  {
    path: `${SD_BLOCK_PREFIX}${AppNavigationRoutes.JasperReportsPage}`,
    label: 'translate#title.reports',
    activeFor: [`${SD_BLOCK_PREFIX}${AppNavigationRoutes.JasperReportsPage}`],
    allowedPermissions: ['AE_Reports_In_Navigation'],
    Icon: ReportsIcon,
  },
  {
    path: `${SD_BLOCK_PREFIX}${AppNavigationRoutes.MapPage}`,
    label: 'translate#title.map',
    activeFor: [`${SD_BLOCK_PREFIX}${AppNavigationRoutes.MapPage}`],
    innerPages: [
      {
        path: `${SD_BLOCK_PREFIX}${AppNavigationRoutes.MapDetailsPage}`,
        label: 'translate#title.details',
      },
    ],
    allowedPermissions: ['SRV_ClWEB_Map_SearchDevice'],
    Icon: MapIcon,
  },
  {
    path: `${SD_BLOCK_PREFIX}${AppNavigationRoutes.HelpPage}`,
    label: 'translate#title.help',
    activeFor: [`${SD_BLOCK_PREFIX}${AppNavigationRoutes.HelpPage}`],
    Icon: HelpIcon,
  },
]
