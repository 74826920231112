import styled from 'styled-components'
import { makeStyles } from '@material-ui/core'

export const useClasses = makeStyles(({ spacing }) => ({
  dialog: {
    position: 'relative',
    maxWidth: 'fit-content',
    minWidth: spacing(60),
  },
  paper: {
    padding: spacing(4),
    display: 'grid',
    gridTemplateRows: '50px 1fr',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
  modalHeader: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginRight: spacing(1),
    padding: 0,
  },
  titleWrap: {
    width: '100%',
    paddingRight: '20px',
  },

  button: {
    height: 40,
    fontWeight: 'normal',
    padding: '0 10px',
    margin: 0,
    whiteSpace: 'nowrap',
    fontSize: '13px',
  },

  fieldRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
    marginBottom: '10px',
    fontSize: '16px',
  },

  filedName: {
    color: '#929A9B',
  },

  filedValue: {
    fontWeight: 'bold',
  },
}))

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, max-content);
  align-items: center;
  grid-column-gap: 8px;
  justify-content: flex-end;
  padding: 0px;
`
