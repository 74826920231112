import { AnyAction } from 'redux'
import { LogActions } from '@/constants/actions'

export const setLog = (payload: boolean): AnyAction => ({
  type: LogActions.SetLog,
  payload,
})

export const setRecordingStatus = (
  payload: 'none' | 'inProgress' | 'sentSuccessfully',
): AnyAction => ({
  type: LogActions.SetRecordingStatus,
  payload,
})
