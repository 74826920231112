import { isEqual } from 'lodash'
import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromVaultV1Monitoring from './reducer'

export const getVaultsMonState = (state: AppState): fromVaultV1Monitoring.State => {
  return state.vaultV1Monitoring
}

export const getMonVaults = createSelector(getVaultsMonState, state => {
  return state.vaults
})

export const getMonVaultParent = createSelector(getMonVaults, state => state.parentSelectedVault)
export const getMonVaultParentId = createSelector(
  getMonVaults,
  state => state.parentSelectedVault?.vaultId,
)

export const getMonVaultsData = createSelector(getMonVaults, state => state.data)
export const getMonSelectedVaults = createSelector(getMonVaults, state => state.selectedVault)

export const getMonVaultsFilter = createSelector(getMonVaults, state => state.filter)
export const getMonVaultsPaging = createSelector(getMonVaults, state => state.paging)
export const getMonVaultsTotal = createSelector(getMonVaults, state => state.total)
export const getMonVaultsSort = createSelector(getMonVaults, ({ sortColumn, sortOrder }) => ({
  sortColumn,
  sortOrder,
}))

export const getMonVaultsModals = createSelector(getVaultsMonState, state => {
  return state.modals
})

export const getMonVaultsDetails = createSelector(getVaultsMonState, state => {
  return state.vaultDetails
})

export const getMonVaultsDetailsModals = createSelector(getMonVaultsDetails, state => ({
  isFilterOpen: state.isFilterOpen,
}))

export const getMonVaultsDetailsFilter = createSelector(getMonVaultsDetails, state => {
  return state.filter
})

export const getMonDetailsSelectedTab = createSelector(getMonVaultsDetails, state => {
  return state.selectedTab
})

export const getMonForecast = createSelector(getVaultsMonState, state => {
  return state.forecast
})

export const getMonVaultVaultOperationDetailsFilter = createSelector(
  getMonVaultsDetails,
  state => state.operationsDetailsFilter,
)
export const getMonVaultVaultOperationDetailsSort = createSelector(
  getMonVaultsDetails,
  ({ sortColumn, sortOrder }) => ({
    sortColumn,
    sortOrder,
  }),
)

// Cashier
export const getMonVaultCashiers = createSelector(getVaultsMonState, state => state.cashiers)
export const getMonVaultCashierSelectedTab = createSelector(
  getMonVaultCashiers,
  state => state.selectedTab,
)
export const getMonVaultSelectedCashier = createSelector(
  getMonVaultCashiers,
  state => state.selected,
)
export const getMonVaultSelectedCashierParent = createSelector(
  getMonVaultCashiers,
  state => state.parentSelected,
)
export const getMonVaultCashiersFilter = createSelector(getMonVaultCashiers, state => state.filter)

// Cashier Details
export const getMonVaultCashierDetails = createSelector(getVaultsMonState, state => {
  return state.cashierDetails
})
export const getMonVaultCashierDetailsTab = createSelector(getMonVaultCashierDetails, state => {
  return state.selectedTab
})
export const getMonVaultCashierOperationDetailsFilter = createSelector(
  getMonVaultCashierDetails,
  state => state.filter,
)

export const getMonVaultCashierOperationDetailsSort = createSelector(
  getMonVaultCashierDetails,
  ({ sortColumn, sortOrder }) => ({
    sortColumn,
    sortOrder,
  }),
)

export const getMonCurrentRemainders = createSelector(
  getVaultsMonState,
  ({ monCurrentRemainders }) => monCurrentRemainders,
)
export const getMonCurrentRemaindersData = createSelector(
  getMonCurrentRemainders,
  ({ data }) => data,
)
export const getMonCurrentRemaindersLoaded = createSelector(
  getMonCurrentRemainders,
  ({ isLoaded }) => isLoaded,
)
export const getMonCurrentRemaindersLoading = createSelector(
  getMonCurrentRemainders,
  ({ isLoading }) => isLoading,
)

export const getMonCurrentRemaindersToUpdate = createSelector(
  getMonCurrentRemainders,
  state => state.updatedCurrentRemainders,
)

export const isEqualCurrentRemainderCurrencies = createSelector(getMonCurrentRemainders, state => {
  const initialCurrencies = state.data
  const updatedCurrencies = state.updatedCurrentRemainders

  return isEqual(initialCurrencies, updatedCurrencies)
})

export const getMonCurrentValuablesRemainders = createSelector(
  getVaultsMonState,
  ({ monCurrentValuablesRemainders }) => monCurrentValuablesRemainders,
)
export const getMonCurrentValuablesRemaindersData = createSelector(
  getMonCurrentValuablesRemainders,
  ({ data }) => data,
)
export const getMonCurrentValuablesRemaindersLoaded = createSelector(
  getMonCurrentValuablesRemainders,
  ({ isLoaded }) => isLoaded,
)
export const getMonCurrentValuablesRemaindersLoading = createSelector(
  getMonCurrentValuablesRemainders,
  ({ isLoading }) => isLoading,
)
export const getMonCurrentValuablesRemaindersUpdated = createSelector(
  getMonCurrentValuablesRemainders,
  state => state.updated,
)
export const isEqualCurrentValuablesRemainders = createSelector(
  getMonCurrentValuablesRemainders,
  state => {
    const initialCurrencies = state.data
    const updatedCurrencies = state.updated

    return isEqual(initialCurrencies, updatedCurrencies)
  },
)

export const getMonStartDay = createSelector(getVaultsMonState, state => {
  return state.monStartDay
})
export const getWorkingTimeEnd = createSelector(getVaultsMonState, state => {
  return state.monWorkingTimeEnd
})
export const getWornOutCash = createSelector(getVaultsMonState, state => {
  return state.monWornOutCash
})
export const getCloseNightDepository = createSelector(getVaultsMonState, state => {
  return state.monCloseNightDepository
})
export const getCashLimits = createSelector(getVaultsMonState, state => {
  return state.monCashLimits
})

export const getCashLimitsData = createSelector(getVaultsMonState, state => {
  return {
    vaultId: state.monCashLimits.data?.vaultId,
    vaultType: state.monCashLimits.data?.vaultType,
    vaultName: state.monCashLimits.data?.vaultName,
    setDate: state.monCashLimits.data?.setDate,
    setBy: state.monCashLimits.data?.setBy,
    limits: state.monCashLimits.data?.limits,
    currencies: state.monCashLimits.data?.currencies,
  }
})

export const getMonActionLoading = createSelector(getVaultsMonState, state => {
  const { isCurrentRemainderUpdateLoading } = state.monCurrentRemainders
  const { isStartDayUpdateLoading } = state.monStartDay
  const { isWorkingTimeEndUpdateLoading } = state.monWorkingTimeEnd
  const { isWornOutCashUpdateLoading } = state.monWornOutCash
  const { isCloseNightDepositoryLoading } = state.monCloseNightDepository
  const { isCashLimitsLoading } = state.monCashLimits
  const { isAbsRemaindersUpdating } = state
  const { isUpdating } = state.monCurrentValuablesRemainders

  return (
    isCurrentRemainderUpdateLoading ||
    isStartDayUpdateLoading ||
    isWorkingTimeEndUpdateLoading ||
    isWornOutCashUpdateLoading ||
    isCloseNightDepositoryLoading ||
    isCashLimitsLoading ||
    isAbsRemaindersUpdating ||
    isUpdating
  )
})

export const getMonAbsRemaindersUpdating = createSelector(getVaultsMonState, state => {
  return state.isAbsRemaindersUpdating
})
