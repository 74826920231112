import makeStyles from '@material-ui/core/styles/makeStyles'
import { isIE } from 'react-device-detect'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  input: {
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    paddingTop: spacing(4),
    paddingBottom: spacing(4),
  },
  wrapper: {
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
  },

  tableWrapper: {
    marginBottom: spacing(2),
    marginTop: spacing(2),
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: isIE ? 'auto' : 1,
    overflow: 'auto',
    marginTop: spacing(2),
  },
  field: {
    '&:nth-of-type(2n + 1)': {
      paddingRight: spacing(2),
    },
    '&:nth-of-type(2n)': {
      paddingLeft: spacing(2),
    },
  },
  buttons: {
    marginTop: spacing(2),
  },
  asterisk: {
    color: 'red',
    display: 'inline',
  },

  deleteButton: {
    color: palette.error.main,
    border: palette.error.main,
    fontWeight: 'bold',
    backgroundColor: palette.error.light,
    marginRight: spacing(1),
    '&:hover': {
      backgroundColor: '#deb6b0',
      border: palette.error.main,
    },
  },
  grid: {
    flex: 1,
    overflow: 'auto',
  },
  loaderWrapper: {
    height: spacing(4),
    width: '100%',
    margin: `${spacing(5)}px auto`,
    marginRight: spacing(3),
  },
  loader: {
    height: spacing(4),
    width: spacing(4),
    margin: 'auto',
    marginRight: spacing(1),
  },
  editButton: {
    '&:hover': {
      backgroundColor: '#f1ecec',
    },
  },
  confirmContent: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  confirmMessage: {
    paddingLeft: 0,
  },
  confirmButtons: {
    justifyContent: 'flex-end',
    paddingRight: 0,
    paddingTop: spacing(3.5),
  },
  confirmNoButton: {
    background: 'none',
    color: palette.primary.main,

    '&:hover': {
      background: 'none',
    },
  },
}))
