import { GroupNode } from '../../types'

export const selectAllChild = (node: GroupNode, acc: GroupNode[] = []): GroupNode[] => {
  acc.push(node)

  if (node.childNodes.length) {
    node.childNodes.forEach(node => {
      selectAllChild(node, acc)
    })
  }

  return acc
}

export const addParentNodes = (parentNode: GroupNode): GroupNode => {
  const childNodes = parentNode.childNodes.map(childNode => {
    const newChildNode = addParentNodes({ ...childNode, parentNode })
    return { ...newChildNode, parentNode }
  })

  return { ...parentNode, childNodes }
}

export const updateParentSelectState = (node: GroupNode, selected: GroupNode[]): GroupNode[] => {
  const currentNodeId = node.nodeId
  const isSelected = !!selected.find(({ nodeId }) => nodeId === currentNodeId)

  const isAnyChildSelected = node.childNodes.some(({ nodeId }) => {
    return !!selected.find(group => {
      return group.nodeId === nodeId
    })
  })
  const isAllChildsSelected =
    node.childNodes.length === 0 ||
    (isAnyChildSelected &&
      node.childNodes.every(({ nodeId }) => {
        return !!selected.find(group => {
          return group.nodeId === nodeId
        })
      }))

  if (!isSelected && isAllChildsSelected) {
    const newSelected = [...selected, node]
    return node.parentNode ? updateParentSelectState(node.parentNode, newSelected) : newSelected
  }

  if (isSelected && !isAllChildsSelected) {
    const newSelected = selected.filter(({ nodeId }) => nodeId !== currentNodeId)
    return node.parentNode ? updateParentSelectState(node.parentNode, newSelected) : newSelected
  }

  return node.parentNode ? updateParentSelectState(node.parentNode, selected) : selected
}

export const getParentTree = (node: GroupNode, parents: GroupNode[] = []): GroupNode[] => {
  const parentsCloned = [...parents]

  if (node.parentNode) {
    parentsCloned.push(node.parentNode)
    getParentTree(node.parentNode, parentsCloned)
  }

  return parentsCloned
}

export const checkIsAnyChildSelected = (node: GroupNode, selected: GroupNode[]): boolean => {
  return node.childNodes.some(childNode => {
    return (
      !!selected.find(group => {
        return group.nodeId === childNode.nodeId
      }) ||
      (childNode?.childNodes?.length && checkIsAnyChildSelected(childNode, selected))
    )
  })
}

export const findNode = (searchNode: GroupNode, nodeList: GroupNode[]): GroupNode | undefined => {
  return nodeList.find(node => {
    if (searchNode.nodeId === node.nodeId) return node

    return findNode(searchNode, node.childNodes)
  })
}

export const createNodesMap = (nodeList: GroupNode[]): Record<string, GroupNode> => {
  return nodeList.reduce((acc, node) => {
    if (node.childNodes.length) {
      acc = { ...acc, ...createNodesMap(node.childNodes) }
    }
    acc[node.nodeId] = node
    return acc
  }, {} as Record<string, GroupNode>)
}
