import { AnyAction } from 'redux'

import { CMAppConfigActions } from '@/constants/actions/cmActions'

// get
export const getAdmLogoRequest = (): AnyAction => ({
  type: CMAppConfigActions.AdmGetLogoRequest,
})
export const getAdmLogoResponse = (image: string): AnyAction => ({
  type: CMAppConfigActions.AdmGetLogoResponse,
  payload: image,
})
export const getAdmLogoFail = (): AnyAction => ({
  type: CMAppConfigActions.AdmGetLogoFail,
})

// set
export const getAdmApprovingLogoRequest = (): AnyAction => ({
  type: CMAppConfigActions.AdmGetApprovingLogoRequest,
})
export const getAdmApprovingLogoResponse = (image: string): AnyAction => ({
  type: CMAppConfigActions.AdmGetApprovingLogoResponse,
  payload: image,
})
export const getAdmApprovingLogoFail = (error: string): AnyAction => ({
  type: CMAppConfigActions.AdmGetApprovingLogoFail,
  payload: error,
})

// approving
export const setAdmApprovingLogoRequest = (isApproving: boolean): AnyAction => ({
  type: CMAppConfigActions.AdmSetApprovingLogoRequest,
  payload: isApproving,
})
