const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

export const convertingFileSize = (bytes: number, decimals = 2, multiplier = 1000): string => {
  if (bytes === 0) return '0 B'

  const index = Math.floor(Math.log(bytes) / Math.log(multiplier))
  return `${parseFloat((bytes / Math.pow(multiplier, index)).toFixed(decimals))}  ${sizes[index]}`
}

export const convertingSizeFormat = (bytes: number): string => {
  return bytes.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
}

export const convertingBytesToKb = (bytes: number): number => {
  return bytes / 1024
}

export const convertingKbToBytes = (kb: number): number => {
  return kb * 1024
}

export const convertingMbToBytes = (mb: number): number => {
  const oneMbBytes = 1048576

  return mb * oneMbBytes
}
