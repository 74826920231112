import { Menu } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { Close } from '@material-ui/icons'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { isIE } from 'react-device-detect'
import Test from 'react-test-attributes'

import { getTestId } from '@/utils'
import { translate } from '@/utils/internalization'

import { useClasses } from './styles'
import { Props } from './types'

const ContextMenuBookmarks = ({
  bookmarkEl,
  disableRipple = false,
  limitMenuSize = true,
  handleClose,
  children,
  handleAddTab,
  hideBookmarks,
  handleRemoveBookmark,
  ...menuProps
}: Props): React.ReactElement => {
  const props = { display: isIE ? 'block' : 'flex' }
  const classes = useClasses(props)

  useEffect((): void => {
    if (!bookmarkEl) {
    }
  }, [bookmarkEl])

  const testId = getTestId('context-menu-bookmarks')

  return (
    <Test id={testId(0)}>
      <Menu
        anchorEl={bookmarkEl}
        classes={{
          paper: classes.menuPaper,
          list: classes.menuList,
        }}
        getContentAnchorEl={null}
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        elevation={3}
        open={Boolean(bookmarkEl)}
        onClose={handleClose}
        {...menuProps}
      >
        <div className={classes.menuSpacing}>
          <div
            className={clsx(classes.scrollAble, {
              [classes.limitedHeight]: limitMenuSize,
            })}
          >
            {hideBookmarks.map(({ label, path }) => (
              <MenuItem onClick={handleAddTab(path, label, false)} key={label}>
                <div className={classes.bookmark}>
                  <Typography
                    variant="caption"
                    className={classes.bookmarkText}
                    onClick={handleAddTab(path, label, false)}
                  >
                    {translate(label)}
                  </Typography>
                  <IconButton
                    size="small"
                    className={classes.bookmarkIcon}
                    onClick={handleRemoveBookmark(path)}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                </div>
              </MenuItem>
            ))}
          </div>
          <Test id={testId(2)}>
            <div>{children}</div>
          </Test>
        </div>
      </Menu>
    </Test>
  )
}

export default ContextMenuBookmarks
