import { Tab, Tabs } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import { Alert } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import SnapshotIcon from '@/assets/atmeye/icons/snapshot.svg'
import DownloadIcon from '@/assets/sd/download.svg'
import CloseButton from '@/components/blocks/CloseButton'
import Loader from '@/components/blocks/Loader'
import Button from '@/components/controls/Button'
import { Select } from '@/components/controls/Select/component'
import { useSnapshots } from '@/components/pages/atmeye/Devices/Components/CurrentDevice/Tabs/ActionsTab/hooks/useSnapshots/useSnapshots'
import { useTranslate } from '@/utils/internalization'

import { ContentWrapper, useClasses } from './styles'
import { Props } from './types'

export const SCREENSHOT_CAMERA_NUMBER = 17
const timerOptions = ['0', '5000', '15000', '30000', '45000', '60000'].map(value => ({
  value,
  name: `${+value / 1000} sec.`,
}))

const MakeSnapshotModal = React.memo(
  ({
    open,
    handleClose,
    currentDevice,
    socketCommand,
    id,
    defaultMessage,
    isScreenshotModal,
    downloadBtnText,
  }: Props): React.ReactElement => {
    const classes = useClasses()
    const translate = useTranslate()
    const [refreshTime, setRefreshTime] = useState('0')
    const {
      currentCameraNumber,
      image,
      handleGetImage,
      noResponse,
      dataCams,
      isLoadingImage,
      error,
      handleCloseModal,
    } = useSnapshots({ socketCommand, currentDevice, handleClose, refreshTime: +refreshTime })

    useEffect(() => {
      isScreenshotModal && open && handleGetImage(SCREENSHOT_CAMERA_NUMBER)()
    }, [isScreenshotModal, open, refreshTime])

    const isShowDownloadButton =
      !noResponse && currentDevice?.deviceName && currentCameraNumber && image

    const loaderJSX = (
      <Grid container justify="center">
        <Loader className={classes.loader} />
      </Grid>
    )

    const errorJSX = error && (
      <div className={classes.error}>
        <Alert variant="outlined" severity="error">
          {error}
        </Alert>
      </div>
    )

    const imageJSX =
      (isLoadingImage && loaderJSX) ||
      (image && (
        <div className={classes.cameraImage}>
          <img className={classes.img} src={`data:image/jpg;base64, ${image}`} />
        </div>
      ))
    return (
      <Dialog
        maxWidth={false}
        open={open}
        keepMounted
        onClose={handleCloseModal}
        disableBackdropClick
        classes={{
          paper: classes.dialog,
        }}
      >
        <ContentWrapper>
          <CloseButton absolute className={classes.closeButton} onClick={handleCloseModal} />

          <div className={classes.deviceTitle}>
            <FormattedMessage id={id} defaultMessage={defaultMessage} />
          </div>

          {dataCams?.activeCameras?.length || isScreenshotModal ? (
            <div className={classes.tabContentWrapper}>
              <div className={classes.tabBlock}>
                {isScreenshotModal ? (
                  <Select
                    className={classes.selectInput}
                    label={translate('translate#atmeye.devices.screenshotTimer.label')}
                    placeholder={translate('translate#atmeye.devices.screenshotTimer.label')}
                    fullWidth
                    required
                    value={refreshTime}
                    options={timerOptions}
                    onChange={e => {
                      setRefreshTime(`${e.target.value}`)
                    }}
                  />
                ) : (
                  <Tabs
                    orientation="vertical"
                    value={currentCameraNumber}
                    className={classes.tabsWrap}
                    TabIndicatorProps={{ className: classes.indicator }}
                  >
                    <Tab
                      classes={{ root: classes.noTab }}
                      label={translate('translate#atmeye.title.camera')}
                    />

                    {dataCams.activeCameras.map(item => (
                      <Tab
                        key={item}
                        value={item}
                        classes={{ selected: classes.selectedTab, root: classes.tab }}
                        label={translate('translate#atmeye.title.camera') + ' ' + item}
                        onClick={handleGetImage(item)}
                      />
                    ))}
                  </Tabs>
                )}

                {!!currentCameraNumber && isShowDownloadButton && (
                  <a
                    className={classes.download}
                    download={
                      currentDevice?.deviceName + ' camera ' + currentCameraNumber + ' snapshot.jpg'
                    }
                    href={noResponse ? 'javascript:void(0)' : `data:image/jpg;base64, ${image}`}
                  >
                    <Button
                      className={classes.button}
                      color="primary"
                      variant="contained"
                      startIcon={<img src={DownloadIcon} />}
                    >
                      {downloadBtnText}
                    </Button>
                  </a>
                )}
              </div>

              <div className={classes.wrapper}>
                {!currentCameraNumber && (
                  <div className={classes.cameraChoose}>
                    <img className={classes.grayscale} src={SnapshotIcon} />
                    {translate('translate#atmeye.devices.chooseCamera')}
                  </div>
                )}
                {imageJSX || errorJSX}
              </div>
            </div>
          ) : (
            <FormattedMessage id="title.noCameraDetected" defaultMessage="Camera not detected" />
          )}
        </ContentWrapper>
      </Dialog>
    )
  },
)

export default MakeSnapshotModal
