import { FormikValues } from 'formik'

import { ParametersItems } from '@/types/sd/reports/reports'

export interface Props {
  params: ParametersItems
  formikValues: FormikValues
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export enum FilterTypeDisplayName {
  TEXT = 0,
  LIST = 1,
  RADIOBUTTON = 2,
  CHECKBOX = 3,
}
