import Dialog from '@material-ui/core/Dialog'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import React, { ReactElement } from 'react'

import { useClasses } from './styles'
import { Props } from './types'

const Modal: React.FC<Props> = ({
  isOpen,
  onClose,
  hidden,
  className,
  children,
  fullWidth,
}): ReactElement => {
  const classes = useClasses()

  return (
    <Dialog
      transitionDuration={0}
      open={isOpen}
      onClose={onClose}
      classes={{ root: classes.dialog }}
      hidden={hidden}
      fullWidth={fullWidth}
    >
      <Fade in={isOpen}>
        <Paper className={className}>{children}</Paper>
      </Fade>
    </Dialog>
  )
}

export default Modal
