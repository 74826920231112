import { FormikProps, FormikValues } from 'formik'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Test from 'react-test-attributes'

import { BusinessEntityKey, CompanyTreeElement } from '@/api/companies/companiesApi/types'
import Loader from '@/components/blocks/Loader'
import MultiStepForm from '@/components/blocks/MultiStepForm'
import { BackupOptions } from '@/components/controls/GeneratedField/types'
import {
  AddNewCompanyFormFields,
  AddNewCompanyFormValues,
  InitialFormValues,
} from '@/constants/forms/AddNewCompanyForm'
import {
  addCompanyRequest,
  getCompanyRequest,
  getRolesRequest,
  updateCompanyRequest,
} from '@/store/companies/companies/actions'
import { getCompany, getRoles } from '@/store/companies/companies/selectors'
import { Step } from '@/types/common/multiStepForm'
import { getTestId } from '@/utils/getTestId'
import { useTranslate } from '@/utils/internalization'

import { AddNewCompanyFormStepAddress } from './components/AddNewCompanyFormStepAddress/index'
import { AddNewCompanyFormStepGeneralInformation } from './components/AddNewCompanyFormStepGeneralInformation/index'
import { AddNewCompanyFormStepRoles } from './components/AddNewCompanyFormStepRoles/index'
import { AddNewCompanyFormStepWorkingHours } from './components/AddNewCompanyFormStepWorkingHours/index'
import { useClasses } from './styles'
import { Props } from './types'
import { getInitialValues } from './utils/initial-values'
import { validate } from './utils/validate'

const AddNewCompanyForm = ({
  itemID,
  parentID,
  treeElement,
  open,
  hideCancel = false,
  handleClose,
}: Props): React.ReactElement => {
  const classes = useClasses()
  const dispatch = useDispatch()
  const translate = useTranslate()
  // const [searchOpen, setSearchOpen] = useState(false)
  const idPrefix = 'companies-add-new-company-form'
  const testId = getTestId(idPrefix)
  const [errors, setErrors] = useState<any[]>([])
  const isEditForm = itemID != null

  const company = useSelector(getCompany)
  const roles = useSelector(getRoles)

  let steps: Step[] = []
  // let finishEnableValues: any = []

  if (isEditForm) {
    steps = [
      {
        title: 'translate#companies.title.addNewCompany.generalInformation',
        content: (
          props: FormikProps<AddNewCompanyFormValues>,
          generatedFieldBackupValues: BackupOptions,
        ): React.ReactElement => (
          <AddNewCompanyFormStepGeneralInformation
            backupOptions={generatedFieldBackupValues}
            {...props}
          />
        ),
        // nextEnableValues: true,
        nextEnableValues: [
          // AddNewCompanyFormFields.ServiceCompanyId,
          {
            companyGeneralInfo: [
              AddNewCompanyFormFields.CompanyTypeId,
              AddNewCompanyFormFields.CompanyName,
              AddNewCompanyFormFields.CompanyShortName,
            ],
          },
        ],
        // nextEnableValues: [`companyGeneralInfo[${AddNewCompanyFormFields.CompanyName}]`], // [AddNewCompanyFormFields.CompanyName],
        // specificNextDisableProp: {
        //   fieldName: AddNewCompanyFormFields.FileType,
        //   equal: 'excel',
        // },
        // hideFinishButton: true,
      },
      {
        title: 'translate#companies.title.addNewCompany.address',
        content: (
          props: FormikProps<AddNewCompanyFormValues>,
          generatedFieldBackupValues: BackupOptions,
        ): React.ReactElement => (
          <AddNewCompanyFormStepAddress backupOptions={generatedFieldBackupValues} {...props} />
        ),
        checkNextStepValidation: (values: FormikValues): boolean => {
          if (values.address[AddNewCompanyFormFields.CountryId]) {
            if (values.address[AddNewCompanyFormFields.StreetId]) {
              if (
                values.address[AddNewCompanyFormFields.HouseNumber] &&
                !_.isEmpty(values.address[AddNewCompanyFormFields.HouseNumber])
              ) {
                return true
              } else {
                return false
              }
            }

            return true
          }
          return false
        },
        nextEnableValues: [],
        // nextEnableValues: true,
        // nextEnableValues: [
        //   {
        //     address: [AddNewCompanyFormFields.CountryId],
        //   },
        // ],
        // nextEnableValues: [AddNewCompanyFormFields.Address],
        // hideFinishButton: true,
      },
      {
        title: 'translate#companies.title.addNewCompany.workingHours',
        content: (props: FormikProps<AddNewCompanyFormValues>): React.ReactElement => (
          <AddNewCompanyFormStepWorkingHours {...props} />
        ),
        backIsDisable: false,
        // nextEnableValues: true,
        nextEnableValues: [AddNewCompanyFormFields.WorkingHours],
        // hideFinishButton: true,
      },
      {
        title: 'translate#companies.title.addNewCompany.roles',
        content: (props: FormikProps<AddNewCompanyFormValues>): React.ReactElement => (
          <AddNewCompanyFormStepRoles {...props} />
        ),
        backIsDisable: false,
        nextEnableValues: false,
        // hideFinishButton: true,
      },
    ]
  } else {
    steps = [
      {
        title: 'translate#companies.title.addNewCompany.generalInformation',
        content: (
          props: FormikProps<AddNewCompanyFormValues>,
          generatedFieldBackupValues: BackupOptions,
        ): React.ReactElement => (
          <AddNewCompanyFormStepGeneralInformation
            backupOptions={generatedFieldBackupValues}
            {...props}
          />
        ),
        nextEnableValues: [
          {
            companyGeneralInfo: [
              AddNewCompanyFormFields.CompanyTypeId,
              AddNewCompanyFormFields.CompanyName,
              AddNewCompanyFormFields.CompanyShortName,
            ],
          },
        ],
        hideFinishButton: true,
      },
      {
        title: 'translate#companies.title.addNewCompany.address',
        content: (
          props: FormikProps<AddNewCompanyFormValues>,
          generatedFieldBackupValues: BackupOptions,
        ): React.ReactElement => (
          <AddNewCompanyFormStepAddress backupOptions={generatedFieldBackupValues} {...props} />
        ),
        checkNextStepValidation: (values: FormikValues): boolean => {
          if (values.address[AddNewCompanyFormFields.CountryId]) {
            if (values.address[AddNewCompanyFormFields.StreetId]) {
              if (
                values.address[AddNewCompanyFormFields.HouseNumber] &&
                !_.isEmpty(values.address[AddNewCompanyFormFields.HouseNumber])
              ) {
                return true
              } else {
                return false
              }
            }

            return true
          }
          return false
        },
        nextEnableValues: [],
        // nextEnableValues: [
        //   {
        //     address: [AddNewCompanyFormFields.CountryId],
        //   },
        // ],
        hideFinishButton: true,
      },
      {
        title: 'translate#companies.title.addNewCompany.workingHours',
        content: (props: FormikProps<AddNewCompanyFormValues>): React.ReactElement => (
          <AddNewCompanyFormStepWorkingHours {...props} />
        ),
        backIsDisable: false,
        nextEnableValues: [AddNewCompanyFormFields.WorkingHours],
        hideFinishButton: true,
      },
      {
        title: 'translate#companies.title.addNewCompany.roles',
        content: (props: FormikProps<AddNewCompanyFormValues>): React.ReactElement => (
          <AddNewCompanyFormStepRoles {...props} />
        ),
        backIsDisable: false,
        nextEnableValues: false,
      },
    ]
  }

  const finishEnableValues = [
    {
      companyGeneralInfo: [
        AddNewCompanyFormFields.CompanyName,
        AddNewCompanyFormFields.CompanyShortName,
        AddNewCompanyFormFields.CompanyTypeId,
      ],
      address: [AddNewCompanyFormFields.CountryId],
    },
    // [AddNewCompanyFormFields.WorkingHours]
    // `companyGeneralInfo[${AddNewCompanyFormFields.CompanyName}]`,
    // [AddNewCompanyFormFields.CompanyName],
    // [AddNewCompanyFormFields.RegistrationNumber],
    // [AddNewCompanyFormFields.CompanyShortName]
  ]

  useEffect(() => {
    dispatch(getRolesRequest(BusinessEntityKey.COMPANY))
  }, [])

  useEffect((): void => {
    if (itemID) {
      dispatch(getCompanyRequest(itemID))
    } else {
    }
  }, [dispatch])

  const getParentCompanyID = (
    treeElement: CompanyTreeElement | null | undefined,
  ): number | null => {
    if (treeElement) {
      return treeElement.itemId
    }
    return null
  }

  const getParentCompanyName = (
    treeElement: CompanyTreeElement | null | undefined,
  ): string | null => {
    if (treeElement) {
      return treeElement.itemDisplayName
    }
    return null
  }

  const handleSubmit = useCallback(
    (values: any) => {
      if (isEditForm) {
        // dispatch(updateCompanyRequest(values))

        dispatch(
          updateCompanyRequest({
            companyData: values,
            translate,
          }),
        )
      } else {
        dispatch(
          addCompanyRequest({
            companyData: values,
            translate,
            handleSuccess: handleClose,
          }),
        )
        // handleClose()
      }
    },
    [dispatch, handleClose],
  )

  const initialValues: InitialFormValues = getInitialValues(
    isEditForm,
    getParentCompanyID(treeElement), // parentID,
    getParentCompanyName(treeElement),
    roles,
    itemID ? (company as Record<string, any>) : undefined,
  )

  return (
    <Test id={testId(0)}>
      {itemID && !company ? (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      ) : (
        <MultiStepForm
          steps={steps}
          initialValues={initialValues}
          validate={validate}
          finishEnableValues={finishEnableValues}
          onClose={handleClose}
          handleSubmit={handleSubmit}
          showHeaderButtons
          hideCancel={hideCancel}
          enableReinitialize
          finishButtonTitle={isEditForm ? 'action.save' : null}
          validateOnMount
          showIcon
          headerButtonsActive={!isEditForm}
        />
      )}
    </Test>
  )
}

export default AddNewCompanyForm
