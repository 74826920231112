import {
  BufferEncoders,
  encodeCompositeMetadata,
  encodeRoute,
  MESSAGE_RSOCKET_AUTHENTICATION,
  MESSAGE_RSOCKET_COMPOSITE_METADATA,
  MESSAGE_RSOCKET_ROUTING,
  RSocketClient,
} from 'rsocket-core'
import { ReactiveSocket } from 'rsocket-types'
import RSocketWebSocketClient from 'rsocket-websocket-client'

import { Command } from '@/api/rsocket/command'
import { Responder } from '@/api/rsocket/responder'
import { APIConfiguration, INITIAL_LOCALE, ISOLanguagesCodes, LocalStorageItems } from '@/constants'

const JSON_METADATA = 'application/vnd.spring.rsocket.metadata+json'

interface ClientFactoryParams {
  userId: string
  token: string
  responder: Responder
  onError?: () => void
}

interface OnConnectParams {
  onSuccess?: () => void
  onError?: () => void
}

const clientFactory: (params: ClientFactoryParams) => RSocketClient<Buffer, Buffer> = ({
  userId,
  token,
  responder,
}) => {
  const locale = localStorage.getItem(LocalStorageItems.Locale)

  return new RSocketClient<Buffer, Buffer>({
    setup: {
      keepAlive: 40000,
      lifetime: 600000,
      dataMimeType: 'application/json',
      metadataMimeType: MESSAGE_RSOCKET_COMPOSITE_METADATA.string,
      payload: {
        metadata: encodeCompositeMetadata([
          [
            JSON_METADATA,
            Buffer.from(
              JSON.stringify({
                locale: ISOLanguagesCodes[locale || INITIAL_LOCALE],
                userId: parseInt(userId),
              }),
            ),
          ],
          [MESSAGE_RSOCKET_AUTHENTICATION, Buffer.from(token)],
        ]),
      },
    },
    transport: new RSocketWebSocketClient(
      { url: APIConfiguration.WSS_SOCKET_API_BASE_URL },
      BufferEncoders,
    ),
    responder,
  })
}

export class MessageService {
  private static instance: MessageService | null = null

  public static getInstance(): MessageService {
    if (!MessageService.instance) {
      MessageService.instance = new MessageService(clientFactory)
    }

    return MessageService.instance
  }

  private closed = false
  private socket: ReactiveSocket<Buffer, Buffer> | null = null
  private clientFactory: (params: ClientFactoryParams) => RSocketClient<Buffer, Buffer>
  private clientFactoryParams: ClientFactoryParams | null = null

  public constructor(
    clientFactory: (params: ClientFactoryParams) => RSocketClient<Buffer, Buffer>,
  ) {
    this.clientFactory = clientFactory
  }

  public connect(params?: OnConnectParams): void {
    if (!this.clientFactoryParams) {
      throw new Error(
        'Client factory params is not defined. Please call setConnectionParams method first',
      )
    }

    this.clientFactory(this.clientFactoryParams)
      .connect()
      .then(
        socket => {
          this.socket = socket
          params?.onSuccess && params.onSuccess()
          socket.connectionStatus().subscribe(event => {
            if (event.kind !== 'CONNECTED') {
              this.socket = null
              if (this.closed) {
                this.closed = false
              } else {
                this.connect(params)
              }
            }

            if (event.kind === 'ERROR') {
              if (this.clientFactoryParams && this.clientFactoryParams.onError) {
                this.clientFactoryParams.onError()
              }
              MessageService.instance = null
            }
          })
        },
        error => {
          params?.onError && params.onError()
          this.connect(params)
          return error
        },
      )
  }

  public setConnectionParams(params: ClientFactoryParams): void {
    this.clientFactoryParams = params
  }

  public sendFireAndForget(data: object, url: string): void {
    const locale = localStorage.getItem(LocalStorageItems.Locale)

    if (this.socket && this.clientFactoryParams) {
      this.socket.fireAndForget({
        data: Buffer.from(JSON.stringify(data)),
        metadata: encodeCompositeMetadata([
          [MESSAGE_RSOCKET_ROUTING, encodeRoute(`/api/v1/${url}`)],
          [
            JSON_METADATA,
            Buffer.from(
              JSON.stringify({
                locale: ISOLanguagesCodes[locale || INITIAL_LOCALE],
                userId: Number(this.clientFactoryParams.userId),
              }),
            ),
          ],
          [MESSAGE_RSOCKET_AUTHENTICATION, Buffer.from(this.clientFactoryParams.token)],
        ]),
      })
    }
  }

  public send(data: object, deviceName: string, command: Command): void {
    const url = `device/${deviceName}/${command}`
    this.sendFireAndForget(data, url)
  }

  public close(): void {
    if (this.socket) {
      this.closed = true
      this.socket.close()
    }
    MessageService.instance = null
  }
}
