import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  wrapper: {
    width: '100%',
    position: 'absolute',
    bottom: spacing(2),
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: spacing(0, 2),
  },
  row: {
    width: '100%',
    fontSize: 10,
    color: '#ffffff',
    flexWrap: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))
