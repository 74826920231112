import { Dialog, DialogContent } from '@material-ui/core'
import React, { ReactElement } from 'react'

import { useClasses } from './styles'
import { Props } from './types'

export const CustomTooltip = (props: Props): ReactElement => {
  const {
    isOpen,
    content,

    onClose,
  } = props
  const classes = useClasses(props)

  return (
    <Dialog
      hideBackdrop
      disablePortal
      open={isOpen}
      maxWidth="md"
      classes={{ paper: classes.paper }}
      style={{ position: 'absolute' }}
      onClose={onClose}
    >
      <DialogContent classes={{ root: classes.content }}>{content}</DialogContent>
    </Dialog>
  )
}
