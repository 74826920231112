import { WorkingHour } from '@/api/companies/companiesApi/types'

export interface Props {
  formikFieldKey: string
  data: WorkingHour[]
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export enum WeekDays {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}
