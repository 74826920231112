import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getState = (state: AppState): fromReducer.State => {
  return state.vaultV1Admin.appConfig.denominations
}

export const getVaultsAdmDenominations = createSelector(getState, state => state)
export const getVaultsAdmDenominationsTotal = createSelector(getState, state => state.total)
export const getVaultsAdmSelectedDenomination = createSelector(getState, state => state.selected)
export const getVaultsAdmDenominationCurrencies = createSelector(
  getState,
  state => state.currencies,
)

export const getVaultsAdmDenominationsSort = createSelector(getState, state => ({
  sortColumn: state.sortColumn,
  sortOrder: state.sortOrder,
}))
