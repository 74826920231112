import React, { useCallback, useContext, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { Command } from '@/api/rsocket/command'
import GlobalConfirmModal from '@/components/blocks/LogoutConfirmModal/component'
import { SocketContext } from '@/context'
import { getSocketData } from '@/store/dashboard'
import { useTranslate } from '@/utils/internalization'
import { PopUpService } from '@/utils/services/popUpService'

import { Props } from './types'

const RestartCoreModal = ({
  open,
  handleClose,
  setIsCoreEnabled,
  currentDevice,
}: Props): React.ReactElement => {
  const translate = useTranslate()
  const { _service, connected } = useContext(SocketContext)
  const data: any = useSelector(getSocketData(Command.CONTROL_CORE_RESTART), shallowEqual)
  const [coreRestart, setCoreRestart] = useState<boolean>(false)

  useEffect(() => {
    if (data && data.status === 'OK' && coreRestart) {
      handleClose()
      setIsCoreEnabled(true)
      PopUpService.showGlobalPopUp('translate#title.restartCoreSuccess', 'success')
      setCoreRestart(false)
    } else if (data && data.status !== 'OK' && coreRestart) {
      handleClose()
      setIsCoreEnabled(true)
      PopUpService.showGlobalPopUp(
        translate('translate#title.restartCoreFail') + ': ' + data.status,
        'error',
      )
      setCoreRestart(false)
    }
  }, [connected, data, coreRestart, handleClose, translate])

  const handleRestartCore = useCallback((): void => {
    if (connected) {
      _service.send({}, currentDevice?.deviceName, Command.CONTROL_CORE_RESTART)
      setCoreRestart(true)
      setIsCoreEnabled(false)
    }
  }, [_service, currentDevice, connected])

  return (
    <GlobalConfirmModal
      disableBackdropClick
      open={open}
      isLoading={coreRestart}
      onClose={handleClose}
      message={translate('translate#title.restartCore')}
      subMessage={translate('translate#title.confirmCoreRestartAction')}
      onConfirm={handleRestartCore}
    />
  )
}

export default RestartCoreModal
