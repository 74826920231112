import { Saga, Task } from '@redux-saga/types'
import { applyMiddleware, combineReducers, createStore, Reducer, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'

import { AuthorizationActions, INITIAL_LOCALE } from '@/constants'
import defaultTranslation from '@/internalization/defaultTranslations/en.json'

import reducers from './reducers'
import rootSaga from './rootSaga'

type AsyncReducers = { [key: string]: Reducer }
type InjectedStore = Store & {
  asyncReducers: AsyncReducers
  injectReducer: (key: string, asyncReducer: Reducer<any, any>) => void
  injectSaga: (key: string, saga: Saga) => void
}

const sagaMiddleware = createSagaMiddleware()

let store: Store

const appReducer = combineReducers({ ...reducers })

const appAsyncReducer = (asyncReducers: AsyncReducers): Reducer => {
  return combineReducers({
    ...reducers,
    ...asyncReducers,
  })
}
const createReducer = (asyncReducers: AsyncReducers): Reducer => (state: any, action: any) => {
  if (action.type === AuthorizationActions.ResetReducers) {
    return appAsyncReducer(asyncReducers)({ intl: state.intl }, action)
  }

  return appAsyncReducer(asyncReducers)(state, action)
}

const rootReducer = (state: any, action: any) => {
  if (action.type === AuthorizationActions.ResetReducers) {
    return appReducer({ intl: state.intl } as any, action)
  }

  return appReducer(state, action)
}

export const getStore = (): Store => {
  if (!store) {
    store = createStore(
      rootReducer, // combineReducers({ ...reducers }),
      { intl: { locale: INITIAL_LOCALE, messages: defaultTranslation } },
      composeWithDevTools(applyMiddleware(sagaMiddleware)),
    )
  }

  return store
}

function createSagaInjector(
  runSaga: (saga: Saga) => Task,
  rootSaga: Saga,
): (key: string, saga: Saga) => void {
  const injectedSagas = new Map()
  const isInjected = (key: string): boolean => injectedSagas.has(key)
  const injectSaga = (key: string, saga: Saga): void => {
    if (isInjected(key)) {
      return
    }

    const task = runSaga(saga)
    // append extra saga
    injectedSagas.set(key, task)
  }

  injectSaga('root', rootSaga)

  return injectSaga
}

export function configureStore(): InjectedStore {
  const store: InjectedStore = {
    ...getStore(),
    asyncReducers: {},
    injectReducer: (key: string, asyncReducer: Reducer) => {
      store.asyncReducers[key] = asyncReducer
      store.replaceReducer(createReducer(store.asyncReducers))
    },
    injectSaga: createSagaInjector(sagaMiddleware.run, rootSaga),
  }
  return store
}
