import { GroupNode } from '@/components/NPMPakage/components/ObjectsTree/types'
import { ResponseError } from '@/types'
import { requestService } from '@/utils/services/request'

import { TableListReponse } from '../commonTypes'
import {
  ExportSnapshotPayload,
  GetMultipleSnapshotsGroupsRequest,
  MultipleSnapshot,
  MultipleSnapshotsFilter,
  SnapshotsTableData,
} from './types'

export class MultipleSnapshotsApi {
  public static async getMultipleSnapshotsList({
    sort,
    size,
    page,
    ...payload
  }: {
    filter: MultipleSnapshotsFilter
    groupingFilters?: MultipleSnapshotsFilter[]

    size: number
    page: number
    sort: string
  }): Promise<TableListReponse<MultipleSnapshot>> {
    try {
      return await requestService.post(
        `/atmeye/api/v1/multiple-snapshots-history/list?sort=${sort}&size=${size}&page=${page - 1}`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getMultipleSnapshotsGroups(
    payload: GetMultipleSnapshotsGroupsRequest,
  ): Promise<{ groupNodes: GroupNode[] }> {
    try {
      return await requestService.post(`/atmeye/api/v1/multiple-snapshots-history/groups`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getExportData(
    {
      sort,
      ...payload
    }: {
      filter: MultipleSnapshotsFilter
      config: {
        format: string
        appliedFilters: {
          filterLabel: string
          filterValue: string
        }[]
        selectedOutputFields: any
      }
      sort?: string
    },
    buffer?: boolean,
  ): Promise<any | ResponseError> {
    try {
      return await requestService.post(
        `/atmeye/api/v1/multiple-snapshots-history/ungrouped-export?sort=${sort}`,
        payload,
        true,
        buffer && { responseType: 'arraybuffer' },
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getExportGroupedData(
    {
      sort,
      ...payload
    }: {
      exportedGroupsData: {
        groupsInfo: any
        nodeFilter: any
      }
      config: {
        format: string
        appliedFilters: {
          filterLabel: string
          filterValue: string
        }[]
        selectedOutputFields: any
      }
      sort?: string
    },
    buffer?: boolean,
  ): Promise<any | ResponseError> {
    try {
      return await requestService.post(
        `/atmeye/api/v1/multiple-snapshots-history/grouped-export?sort=${sort}`,
        payload,
        true,
        buffer && { responseType: 'arraybuffer' },
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getPhoto(fileId: number): Promise<any | ResponseError> {
    try {
      return await requestService.get(`/api/v1/files/image/${fileId}`, {})
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getSingleSnapshot(fileId: number): Promise<any | ResponseError> {
    try {
      return await requestService.get(`/api/v1/files/image/${fileId}`)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  public static async saveMultipleSnapshot(
    multipleSnapshotId: number,
  ): Promise<any | ResponseError> {
    try {
      return await requestService.post(`/atmeye/api/v1/multiple-snapshots-history`, {
        multipleSnapshotId,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async removeCard(payload: any): Promise<any> {
    const pairs = payload.map((id: string) => {
      return 'snapshotsIds=' + encodeURIComponent(id)
    })
    await requestService.delete(`/atmeye/api/v1/multiple-snapshots-history?${pairs.join('&')}`)
  }

  public static async getSnapshotTableDataUnsaved(
    data: any,
  ): Promise<SnapshotsTableData | ResponseError> {
    try {
      return requestService.post('/atmeye/api/v1/multiple-snapshots/unsaved', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getSnapshotTableDataSaved(
    data: any,
  ): Promise<SnapshotsTableData | ResponseError> {
    try {
      return requestService.post('/atmeye/api/v1/multiple-snapshots/saved', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getExportDataSaved(
    payload: ExportSnapshotPayload,
    buffer?: boolean,
  ): Promise<any | ResponseError> {
    try {
      return await requestService.post(
        `/atmeye/api/v1/multiple-snapshots/saved-export`,
        payload,
        true,
        buffer && { responseType: 'arraybuffer' },
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getExportDataUnsaved(
    payload: ExportSnapshotPayload,
    buffer?: boolean,
  ): Promise<any | ResponseError> {
    try {
      return await requestService.post(
        `/atmeye/api/v1/multiple-snapshots/unsaved-export`,
        payload,
        true,
        buffer && { responseType: 'arraybuffer' },
      )
    } catch (e) {
      throw new Error(e)
    }
  }
}
