import { AppBar } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Tab from '@material-ui/core/Tab'
import { Close, Update } from '@material-ui/icons'
import clsx from 'clsx'
import React, { useCallback, useMemo } from 'react'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'
import { useDispatch } from 'react-redux'
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom'
import Test from 'react-test-attributes'

import { LocalStorageItems } from '@/constants'
import { DroppableIds } from '@/constants/droppableIds'
import {
  ADM_BLOCK_PREFIX,
  AppNavigationRoutes,
  ATMEYE_BLOCK_PREFIX,
  CASH_MANAGEMENT_BLOCK_PREFIX,
  cmBaseUrl,
  COMPANIES_BLOCK_PREFIX,
  DICTIONARY_BLOCK_PREFIX,
  PRODUCTS_BLOCK_PREFIX,
  SD_BLOCK_PREFIX,
  USM_BLOCK_PREFIX,
  VAULT_V1_BLOCK_PREFIX,
  vaultV1BaseUrl,
} from '@/constants/paths'
import { getParsedLocalStorageItem, getUserDataLocalStorage } from '@/helpers/localStorage'
import { setCurrentTab } from '@/store/common/portal'
import { getTestId } from '@/utils'
import { dummyFunction } from '@/utils/functions'
import { translate } from '@/utils/internalization'
import { isRouteCurrent } from '@/utils/path'

import { useClasses } from './styles'
import { Props } from './types'

const AppTabBar = ({
  children,
  location: { pathname },
  items,
  headerRight,
  onTabClose,
  className,
}: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const location = useLocation()
  const classes = useClasses({
    isProducts: !!pathname.includes(PRODUCTS_BLOCK_PREFIX),
    isDictionaries: !!pathname.includes(DICTIONARY_BLOCK_PREFIX),
  })
  const isAtmeye = pathname.includes(ATMEYE_BLOCK_PREFIX)
  const isUSM = pathname.includes(USM_BLOCK_PREFIX)
  const isDictionary = pathname.includes(DICTIONARY_BLOCK_PREFIX)
  const isCM = pathname.includes(CASH_MANAGEMENT_BLOCK_PREFIX)
  const isCompanies = pathname.includes(COMPANIES_BLOCK_PREFIX)
  const isProducts = pathname.includes(PRODUCTS_BLOCK_PREFIX)
  const isADM = pathname.includes(ADM_BLOCK_PREFIX)
  const isVaultV1 =
    pathname === VAULT_V1_BLOCK_PREFIX || pathname.includes(`${VAULT_V1_BLOCK_PREFIX}/`)
  const isSDMap = pathname.includes(`${SD_BLOCK_PREFIX}${AppNavigationRoutes.MapPage}`)
  const moduleBeginPrefix = `/${
    location.pathname.split('/').length >= 1 ? location.pathname.split('/')[1] : ''
  }`

  const baseUrl = useMemo(() => {
    switch (true) {
      case isCM:
        return cmBaseUrl

      case isVaultV1:
        return vaultV1BaseUrl

      default:
        return cmBaseUrl
    }
  }, [isCM, isVaultV1, cmBaseUrl, vaultV1BaseUrl])

  const { login } = getUserDataLocalStorage(baseUrl) || {}
  const allUsersSettings = getParsedLocalStorageItem(baseUrl)(LocalStorageItems.UsersSettings) || {}
  const isAppBarVisible = allUsersSettings[login || '']?.isAppBarVisible
  const isAppBarHidden = (isCM || isVaultV1) && !isAppBarVisible

  const handleCloseTab = useCallback(
    (path: string): (() => void) => (): void => {
      if (onTabClose) {
        onTabClose(path)
      }
    },
    [onTabClose],
  )

  const handleReloadTab = useCallback(
    (pathname: string): (() => void) => (): void => {
      window.location.assign(pathname)
    },
    [],
  )

  const testId = getTestId('app-tab-bar')

  return (
    <Test id={testId(0)}>
      <div
        className={clsx(classes.wrapper, {
          [classes.appBarHidden]: isAppBarHidden,
        })}
      >
        {!(
          isAtmeye ||
          isUSM ||
          isDictionary ||
          pathname === SD_BLOCK_PREFIX ||
          isAppBarHidden ||
          isCompanies ||
          isProducts ||
          isSDMap
        ) && (
          <AppBar className={clsx(classes.appBar, className)} position="relative">
            <Droppable droppableId={DroppableIds.tabs} direction="horizontal">
              {(provided: DroppableProvided): React.ReactElement => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={classes.tabBarWrapper}
                >
                  {items &&
                    items
                      .filter(m => m.path.startsWith(moduleBeginPrefix))
                      .map(({ label, path }, i) => (
                        <Draggable
                          key={path}
                          draggableId={`tab${JSON.stringify({ path, label })}`}
                          index={i}
                        >
                          {(
                            { innerRef, draggableProps, dragHandleProps }: DraggableProvided,
                            { isDragging }: DraggableStateSnapshot,
                          ): React.ReactElement => {
                            const isCurrent = isRouteCurrent(pathname, path)

                            // Portal active elements above everything
                            return (isDragging ? createPortal : dummyFunction)(
                              <div
                                ref={innerRef}
                                {...draggableProps}
                                {...dragHandleProps}
                                style={{
                                  ...draggableProps.style,
                                  ...(isDragging ? { borderWidth: 0 } : {}),
                                }}
                                className={classes.tabItem}
                              >
                                <Link
                                  draggable={false}
                                  className={classes.tabWrapper}
                                  to={path}
                                  onClick={e => dispatch(setCurrentTab(location.pathname, path))}
                                >
                                  <Tab
                                    component="div"
                                    disableRipple
                                    className={clsx(classes.tab, {
                                      [classes.tabActive]: isCurrent,
                                    })}
                                    classes={{ wrapper: classes.tabLabel }}
                                    label={translate(label)}
                                    value={i}
                                  />
                                </Link>
                                <div
                                  className={clsx(classes.icons, {
                                    [classes.tabActive]: isCurrent,
                                    [classes.hiddenIcons]: !isCurrent || isDragging,
                                  })}
                                >
                                  {/* <IconButton
                                onClick={handleReloadTab(path)}
                                color={isCurrent ? 'inherit' : undefined}
                                size="small"
                              >
                                <Update fontSize="small" />
                              </IconButton> */}
                                  <IconButton
                                    onClick={handleCloseTab(path)}
                                    color={isCurrent ? 'inherit' : undefined}
                                    size="small"
                                  >
                                    <Close fontSize="small" />
                                  </IconButton>
                                </div>
                              </div>,
                              document.body,
                            )
                          }}
                        </Draggable>
                      ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            {headerRight}
          </AppBar>
        )}
        {children}
      </div>
    </Test>
  )
}

export default withRouter<Props, React.FC<Props>>(AppTabBar)
