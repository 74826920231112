import { createSelector } from 'reselect'
import { AppState } from '@/store/reducers'
import * as fromVaultCashOrders from './reducer'

export const getCashOrdersState = (state: AppState): fromVaultCashOrders.State =>
  state.vaultCashOrders

export const getCashBagsList = createSelector(getCashOrdersState, state => ({
  cashBagsListLoading: state.cashBagsListLoading,
  cashBagsList: state.cashBagsList,
}))

export const getCashOrderDocumentTypes = createSelector(
  getCashOrdersState,
  state => state.selectedCashOrderDocumentTypes,
)

export const getCashOrderDocument = createSelector(
  getCashOrdersState,
  state => state.selectedCashOrderDocument,
)

export const getCashOrdersData = createSelector(getCashOrdersState, state => state.cashOrders)

export const getAvailableVaults = createSelector(getCashOrdersState, state => state.vaults)

export const isCashOrdersDataLoading = createSelector(
  getCashOrdersState,
  state => state.isFetchingCashOrders,
)

export const getSelectedCashOrderBags = createSelector(
  getCashOrdersState,
  state => state.selectedCashOrder?.bags,
)

export const getSelectedCashOrderBag = createSelector(
  getCashOrdersState,
  state => state.selectedCashOrderBag,
)

export const getSelectedCashOrder = createSelector(
  getCashOrdersState,
  state => state.selectedCashOrder,
)

export const getOrdersPanelLayout = createSelector(
  getCashOrdersState,
  state => state?.ordersPanelLayout,
)

export const getSelectedFilters = createSelector(
  getCashOrdersState,
  state => state.filter.selectedFilters,
)

export const getOrderEditStatus = createSelector(getCashOrdersState, state => state.orderEditStatus)

export const getCashOrdersPaging = createSelector(getCashOrdersState, state => state.paging)

export const getCurrencies = createSelector(getCashOrdersState, state => ({
  currencies: state.currencies,
  isLoading: state.currenciesLoading,
}))

export const getCreateOrderFormState = createSelector(
  getCashOrdersState,
  state => state.createOrderForm,
)

export const getCashOrderTypes = createSelector(getCashOrdersState, state => state.cashOrderTypes)

export const getCashOrderStatuses = createSelector(
  getCashOrdersState,
  state => state.cashOrderStatuses,
)
