import React, { ReactElement } from 'react'
import Toolbar from '@material-ui/core/Toolbar'

import { useClasses } from './styles'

const TableToolbar: React.FC = ({ children }): ReactElement => {
  const classes = useClasses()

  return <Toolbar className={classes.toolbar}>{children}</Toolbar>
}

export default TableToolbar
