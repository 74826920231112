import {
  TableFilterTypes,
  TableBodyCell,
  TableBodyCellContent,
  TableBodyCellExtends,
} from '@/components/controls/AppTable/types'
import { getValueOfCell } from '@/components/controls/AppTable/utils/getValueOfCell'
import { getUniqueValuesFromRow } from './getUniqueValuesFromRow'
import { checkValueAgainstFilter } from '@/components/controls/AppTable/utils/checkValueAgainstFilter'

export const groupRowsByField = (
  uniqueValues: TableBodyCellContent[],
  data: TableBodyCell[],
  groupBy: string,
  firstColumn: string,
  filter: TableFilterTypes,
): TableBodyCell[] => {
  if (!uniqueValues.length) {
    uniqueValues = getUniqueValuesFromRow([groupBy], data, true)[groupBy]
  }
  return uniqueValues
    .reduce((accum: TableBodyCell[], value): TableBodyCellExtends<typeof uniqueValues> => {
      const currentKey = getValueOfCell(value)
      const ownRows = data.filter(row => {
        const rowKeyValue = row[groupBy]
        if (Array.isArray(rowKeyValue)) {
          return rowKeyValue.some((val: TableBodyCellContent) => getValueOfCell(val) === currentKey)
        } else {
          return getValueOfCell(row[groupBy]) === currentKey
        }
      })
      return [
        ...accum,
        {
          [firstColumn]: {
            ...(typeof value !== 'object' ? { value: value } : value),
            isGroupHeader: true,
          },
        },
        ...ownRows,
      ]
    }, [])
    .filter(row => {
      const rowKeyValue = getValueOfCell(row[groupBy])
      return filter && (Array.isArray(filter) ? filter.length : true)
        ? checkValueAgainstFilter(rowKeyValue, filter)
        : true
    })
}
