import { AdmFilterTreeNode } from '@/api/cm/swaggerGeneratedApi'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class CommonAdmApi {
  private static requestService = getRequestServiceCM()

  public static async getFilterNodes(): Promise<AdmFilterTreeNode[]> {
    try {
      return await CommonAdmApi.requestService.get('/admin/filterNodes')
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getFilterObjects(): Promise<AdmFilterTreeNode[]> {
    try {
      return await CommonAdmApi.requestService.get('/admin/filterObjects')
    } catch (error) {
      throw Error(error)
    }
  }
}
