import { ResponseError } from '@/types'
import { requestService } from '@/utils/services/request'

import { FindDevicesResponse } from './types'

export class DropDownInputsApi {
  public static async findDevices(
    searchFragment: string,
    byLuno: boolean,
  ): Promise<FindDevicesResponse | ResponseError> {
    try {
      const response = await requestService.post('/tickets/api/v1/device/units', {
        byLuno,
        searchFragment,
      })

      return response.units
    } catch (e) {
      throw new Error(e)
    }
  }
}
