import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { RoundBanknotesApi } from '@/api/cm/adminApi'
import { CMAppConfigActions } from '@/constants'

import {
  getAdmRoundingSettingFail,
  getAdmRoundingSettingRequest,
  getAdmRoundingSettingResponse,
  updateAdmRoundingSettingFail,
} from './actions'

function* getRoundingSettings(): SagaIterator {
  try {
    const response = yield call(RoundBanknotesApi.get)

    yield put(getAdmRoundingSettingResponse(response))
  } catch (error) {
    yield put(getAdmRoundingSettingFail(error))
  }
}

function* updateRoundingSettings({ payload }: AnyAction): SagaIterator {
  try {
    yield call(RoundBanknotesApi.update, payload)

    yield put(getAdmRoundingSettingRequest())
  } catch (error) {
    yield put(updateAdmRoundingSettingFail(error))
  }
}

export default function*(): Generator {
  yield takeLatest(CMAppConfigActions.AdmGetRoundingSettingsRequest, getRoundingSettings)
  yield takeLatest(CMAppConfigActions.AdmUpdateRoundingSettingsRequest, updateRoundingSettings)
}
