import { createSelector } from 'reselect'
import { AppState } from '@/store/reducers'
import * as fromSecurityNetworksObjectsMerge from './reducer'
import { initialState } from './reducer'
import 'moment/min/locales.min'

const getSecurityNetworksObjectsMergeState = (
  state: AppState,
): fromSecurityNetworksObjectsMerge.State => state.usmSecurityNetworksObjectsMerge || initialState

export const getCommonSecurityObjectsMerge = createSelector(
  getSecurityNetworksObjectsMergeState,
  state =>
    state.commonSecurityNetworksObjectsForUserList.map(el => ({
      // for table key
      id: el.typeId,
      ...el,
    })),
)

export const getIsFetchingCommonSecurityObjectsMerge = createSelector(
  getSecurityNetworksObjectsMergeState,
  state => state.isFetchingSecurityNetworksObjects,
)

export const getIsFetchingCommonSecurityObjectsSummaryMerge = createSelector(
  getSecurityNetworksObjectsMergeState,
  state => state.isFetchingGetSummaryObjects,
)

export const getSecurityObjectsSummaryMerge = createSelector(
  getSecurityNetworksObjectsMergeState,
  state =>
    state.summaryObjectsList.map(o => ({
      // for key in table render
      id: o.typeId,
      ...o,
    })),
)

export const getGlobalErrorSecurityObjectsMerge = createSelector(
  getSecurityNetworksObjectsMergeState,
  state => state.error,
)
