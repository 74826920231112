import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getState = (state: AppState): fromReducer.State => {
  return state.cmAdmin?.appConfig.currencies
}

export const getCurrencies = createSelector(getState, state => state)
export const getCurrenciesData = createSelector(getState, state => state.data)

export const getCurrenciesSort = createSelector(getState, state => ({
  sortColumn: state.sortColumn,
  sortOrder: state.sortOrder,
}))
