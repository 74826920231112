/* eslint-disable */
import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle<any>`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
  
  @font-face {
    font-family: 'TBCSailec';
    font-weight: 800;
    font-display: swap;
    src: local('TBCSailec'), url(/fonts/TBCSailec-Black.woff2) format('woff2'), url(/fonts/TBCSailec-Black.ttf) format("truetype");
  }

  @font-face {
    font-family: 'TBCSailec';
    font-weight: 700;
    font-display: swap;
    src: local('TBCSailec'), url(/fonts/TBCSailec-Bold.woff2) format('woff2'), url(/fonts/TBCSailec-Bold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'TBCSailec';
    font-weight: 300;
    font-display: swap;
    src: local('TBCSailec'), url(/fonts/TBCSailec-Light.woff2) format('woff2');
  }

  @font-face {
    font-family: 'TBCSailec';
    font-weight: 500;
    font-display: swap;
    src: local('TBCSailec'), url(/fonts/TBCSailec-Medium.woff2) format('woff2');
  }

  @font-face {
    font-family: 'TBCSailec';
    font-weight: 400;
    font-display: swap;
    src: local('TBCSailec'), url(/TBCSailec-Regular.ttf) format('truetype');
  }

  html[lang="ka"] *{
      font-family: TBCSailec, Roboto, 'Helvetica Neue', sans-serif !important; 
      font-feature-settings: 'case' on;
  }

  * {
    position: relative;
    box-sizing: border-box;
  }
  
  *::-webkit-scrollbar-track {
    border-radius: ${props => props.theme.unit.single};
    background-color: transparent;
  }
  
  *::-webkit-scrollbar-thumb {
    border: none !important;
    border-radius: ${props => props.theme.unit.single};
    background-color: ${props => props.theme.colors.scrollbar};
  }
  
  *::-webkit-scrollbar-corner {
    background-color: transparent;
    border-color: transparent;
  }
  *::-webkit-scrollbar {
    width: ${props => props.theme.unit.single};
    height: ${props => props.theme.unit.single};
    background-color: transparent;
  }

  html[module="cm"] *::-webkit-scrollbar {
    height: 16px;
  }
  html[module="vault-v1"] *::-webkit-scrollbar {
    height: 16px;
  }
  html[module="vault-v1"] .MuiButton-contained.Mui-disabled,
  html[module="cm"] .MuiButton-contained.Mui-disabled {
    color: rgb(0 0 0 / 60%);
    background-color: rgba(0, 0, 0, 0.12);
    border: none;
  }
  html[module="vault-v1"] .MuiButton-root.Mui-disabled,
  html[module="cm"] .MuiButton-root.Mui-disabled {
    color: rgb(0 0 0 / 60%);
    background-color: rgba(0, 0, 0, 0.12);
    border: none;
  }

  html,
  #root {
    height: 100%;
  }
  
  body {
  height: 100%;
    margin: 0;
    background-color: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.font};
    font-weight: ${props => props.theme.fontWeights.normal};
    font-family: ${props => props.theme.fontFamily};
  }

  main {
    min-height: 100%;
    width: 100%;
    padding-left: ${props => props.theme.unit.double};
    padding-right: ${props => props.theme.unit.double};
    padding-top: ${props => props.theme.unit.double};
    padding-bottom: ${props => props.theme.unit.double};
  }
  
  //override global material styles
  .MuiButton-root {
    text-transform: none!important;
  }

  .splitter-layout > .layout-splitter {
    flex: 0 0 auto;
    width: 10px!important;
    height: 100%;
    cursor: col-resize;
    transition: 0.2s;
    background-color: #ebebebc9!important;
  }
  
  .splitter-layout .layout-splitter:hover {
    background-color: #ccccccad!important;
  }
  
  .splitter-layout.layout-changing {
    cursor: col-resize;
  }
  
  .splitter-layout.layout-changing > .layout-splitter {
    background-color: #ebebebc9!important;
  }
  
  .splitter-layout.splitter-layout-vertical {
    flex-direction: column;
  }
  
  .splitter-layout.splitter-layout-vertical.layout-changing {
    cursor: row-resize;
  }
  
  .splitter-layout.splitter-layout-vertical > .layout-splitter {
    width: 100%!important;
    height: 10px!important;
    cursor: row-resize;
  }

  .splitter-layout .layout-pane {
    overflow: hidden!important;
  }

`
