import { TicketActions, CashOrderActions } from '@/constants'
import { AnyAction } from 'redux'
import capitalize from 'lodash/capitalize'
import { SingleTicket } from '@/api/sd/ticketsApi/types'
import moment from 'moment'
import {
  CashOrder,
  CashOrdersSelectedFilters,
  CashOrderFields,
  CashOrdersFilters,
  Currency,
  Valuable,
} from '@/types'
import {
  GetCashOrdersResponse,
  CashOrderStage,
  CashOrderDetail,
  ValuableOrderDetail,
  CreateOrderFormValuable,
  Vault,
} from '@/api/vault/cashOrdersApi/types'

export interface State {
  filter: {
    selectedFilters: CashOrdersFilters
  }
  paging: {
    currentPage: number
    hasNext: boolean
    hasPrevious: boolean
    pageSize: number
    totalOrders: number
    totalPages: number
  }
  createOrderForm: {
    orderLoads: any
    orderLoadsLoading: boolean
    recieverVaults: Vault[]
    recieverVaultsLoading: boolean
    transmitterVaults: Vault[]
    transmitterVaultsLoading: boolean
    valuables: Valuable[]
    valuablesLoading: boolean
    bagNumberValidation: { isCurrentBagNumberValid: boolean; localBagId: number | string }
  }
  cashBagsList: any[]
  cashBagsListLoading: boolean
  currencies: Array<Currency>
  currenciesLoading: boolean
  cashOrders: CashOrder[]
  cashOrderStatuses: any[]
  cashOrderTypes: any[]
  isFetchingCashOrders: boolean
  selectedCashOrderDocumentTypes: any[]
  ordersPanelLayout: 'horizontal' | 'vertical'
  vaults: any[]
  selectedCashOrderDocument: any
  orderEditStatus: null | boolean
  selectedCashOrder: {
    bags: any[] // todo
    bagsLoading: boolean
    cashOrderDate: number
    coid: string
    comment: string | null
    commentsLoading: boolean
    details: (CashOrderDetail | ValuableOrderDetail)[]
    detailsLoading: boolean
    id: string
    numberOfBags: number | string
    orderType: string
    receiver: string
    stages: CashOrderStage[]
    stagesLoading: boolean
    stamp: string
    orderStatusId: number
    transmitter: string
    type: string
  } | null
  selectedCashOrderBag: any | null
}

export const initSelectedFilters: CashOrdersFilters = {
  coid: '',
  currencyID: null,
  fromVaultID: '',
  isOrderDescending: null,
  objectIDs: null,
  orderByProperty: null,
  orderDescending: true,
  orderedByID: '',
  orderStatusID: '',
  orderTypeID: '',
  requiredFields: null,
  stamp: '',
  teamID: '',
  toVaultID: '',
  workOrder: '',
  orderToDate: moment()
    .endOf('date')
    .valueOf(),
  orderFromDate: moment()
    .startOf('date')
    .valueOf(),
  toDate: moment()
    .endOf('date')
    .valueOf(),
  fromDate: moment()
    .startOf('date')
    .valueOf(),
}

export const initSelectedPaging = {
  currentPage: 0,
  hasNext: false,
  hasPrevious: false,
  pageSize: 20,
  totalOrders: 0,
  totalPages: 1,
}

const initialState: State = {
  filter: {
    selectedFilters: initSelectedFilters,
  },
  paging: {
    ...initSelectedPaging,
  },
  createOrderForm: {
    orderLoads: null,
    orderLoadsLoading: false,
    recieverVaults: [],
    recieverVaultsLoading: false,
    transmitterVaults: [],
    transmitterVaultsLoading: false,
    valuables: [],
    valuablesLoading: false,
    bagNumberValidation: { isCurrentBagNumberValid: true, localBagId: '' },
  },
  selectedCashOrder: null,
  cashBagsList: [],
  cashBagsListLoading: false,
  selectedCashOrderBag: null,
  currencies: [],
  currenciesLoading: false,
  selectedCashOrderDocumentTypes: [],
  selectedCashOrderDocument: null,
  cashOrders: [],
  orderEditStatus: null,
  cashOrderStatuses: [],
  vaults: [],
  cashOrderTypes: [],
  isFetchingCashOrders: false,
  ordersPanelLayout: 'horizontal',
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case CashOrderActions.SetOrderEditStatus:
      return { ...state, orderEditStatus: payload }
    case CashOrderActions.GetCashOrderDocumentResponse:
      return {
        ...state,
        selectedCashOrderDocument: payload,
      }
    case CashOrderActions.GetCashOrderDocumentTypesResponse:
      return {
        ...state,
        selectedCashOrderDocumentTypes: payload,
      }
    case CashOrderActions.GetAvailableVaultsResponse:
      return {
        ...state,
        vaults: payload,
      }
    case CashOrderActions.ValidateCashBagNumberRequest:
      return {
        ...state,
        createOrderForm: {
          ...state.createOrderForm,
          bagNumberValidation: {
            localBagId: payload.localBagId,
            isCurrentBagNumberValid: true,
          },
        },
      }
    case CashOrderActions.ValidateCashBagNumberResponse:
      return {
        ...state,
        createOrderForm: {
          ...state.createOrderForm,
          bagNumberValidation: {
            ...state.createOrderForm.bagNumberValidation,
            isCurrentBagNumberValid: payload,
          },
        },
      }
    case CashOrderActions.GetExistingCashBagsRequest:
      return {
        ...state,
        cashBagsListLoading: true,
      }
    case CashOrderActions.GetExistingCashBagsResponse:
      return {
        ...state,
        cashBagsListLoading: false,
        cashBagsList: payload,
      }
    case CashOrderActions.GetRecieverVaultsRequest:
      return {
        ...state,
        createOrderForm: {
          ...state.createOrderForm,
          recieverVaultsLoading: true,
        },
      }
    case CashOrderActions.GetRecieverVaultsResponse:
      return {
        ...state,
        createOrderForm: {
          ...state.createOrderForm,
          recieverVaultsLoading: false,
          recieverVaults: payload,
        },
      }
    case CashOrderActions.GetTransmitterVaultsRequest:
      return {
        ...state,
        createOrderForm: {
          ...state.createOrderForm,
          transmitterVaultsLoading: true,
        },
      }
    case CashOrderActions.GetTransmitterVaultsResponse:
      return {
        ...state,
        createOrderForm: {
          ...state.createOrderForm,
          transmitterVaultsLoading: false,
          transmitterVaults: payload,
        },
      }
    case CashOrderActions.GetOrderLoadsRequest:
      return {
        ...state,
        createOrderForm: {
          ...state.createOrderForm,
          orderLoadsLoading: true,
        },
      }
    case CashOrderActions.GetOrderLoadsResponse:
      return {
        ...state,
        createOrderForm: {
          ...state.createOrderForm,
          orderLoadsLoading: false,
          orderLoads: payload,
        },
      }
    case CashOrderActions.GetCreateOrderFormValuablesRequest:
      return {
        ...state,
        createOrderForm: {
          ...state.createOrderForm,
          valuablesLoading: true,
        },
      }
    case CashOrderActions.GetCreateOrderFormValuablesResponse:
      return {
        ...state,
        createOrderForm: {
          ...state.createOrderForm,
          valuablesLoading: false,
          valuables: payload,
        },
      }
    case CashOrderActions.GetCashOrdersRequest:
      return {
        ...state,
        isFetchingCashOrders: true,
      }
    case CashOrderActions.GetCashOrdersResponse:
      return {
        ...state,
        cashOrders: payload,
        isFetchingCashOrders: false,
      }
    case CashOrderActions.GetCashOrdersResponseFail:
      return {
        ...state,
        isFetchingCashOrders: false,
      }
    case CashOrderActions.SetPanelLayout:
      return {
        ...state,
        ordersPanelLayout: payload,
      }
    case CashOrderActions.SetSelectedCashOrder: {
      return {
        ...state,
        selectedCashOrder: payload
          ? {
              id: payload.vaultCashOrderId,
              coid: payload.cashOrderID,
              type: payload.type,
              comment: payload.comments,
              stages: [],
              stamp: payload.stamp,
              details: [],
              orderStatusId: payload.orderStatusId,
              numberOfBags: payload.numberOfBags,
              bags: payload.bags || [],
              transmitter: payload['fromVault/CorporateClient'],
              receiver: payload['toVault/CorporateClient'],
              cashOrderDate: payload.cashOrderDate,
              orderType: payload.orderType,
              bagsLoading: false,
              commentsLoading: false,
              stagesLoading: false,
              detailsLoading: false,
            }
          : null,
      }
    }

    case CashOrderActions.SetSelectedCashOrderBag: {
      return {
        ...state,
        selectedCashOrderBag: payload,
      }
    }

    case CashOrderActions.GetCashOrderDetailsRequest:
      return {
        ...state,
        selectedCashOrder: state.selectedCashOrder
          ? {
              ...state.selectedCashOrder,
              detailsLoading: true,
            }
          : null,
      }
    case CashOrderActions.GetCashOrderDetailsResponse:
      return {
        ...state,
        selectedCashOrder: state.selectedCashOrder
          ? {
              ...state.selectedCashOrder,
              details: payload,
              detailsLoading: false,
            }
          : null,
      }

    case CashOrderActions.GetCashOrderBagsRequest:
      return {
        ...state,
        selectedCashOrder: state.selectedCashOrder
          ? {
              ...state.selectedCashOrder,
              bagsLoading: true,
            }
          : null,
      }

    case CashOrderActions.GetCashOrderStatusesResponse:
      return {
        ...state,
        cashOrderStatuses: payload,
      }

    case CashOrderActions.GetCashOrderTypesResponse:
      return {
        ...state,
        cashOrderTypes: payload,
      }

    case CashOrderActions.GetCashOrderBagsResponse:
      return {
        ...state,
        selectedCashOrder: state.selectedCashOrder
          ? {
              ...state.selectedCashOrder,
              bags: payload,
              bagsLoading: false,
            }
          : null,
      }

    case CashOrderActions.GetCashOrderCommentsRequest:
      return {
        ...state,
        selectedCashOrder: state.selectedCashOrder
          ? {
              ...state.selectedCashOrder,
              commentsLoading: true,
            }
          : null,
      }
    case CashOrderActions.GetCashOrderCommentsResponse:
      return {
        ...state,
        selectedCashOrder: state.selectedCashOrder
          ? {
              ...state.selectedCashOrder,
              comment: payload,
              commentsLoading: false,
            }
          : null,
      }
    case CashOrderActions.GetCashOrderStagesRequest:
      return {
        ...state,
        selectedCashOrder: state.selectedCashOrder
          ? {
              ...state.selectedCashOrder,
              stagesLoading: true,
            }
          : null,
      }
    case CashOrderActions.GetCashOrderStagesResponse:
      return {
        ...state,
        selectedCashOrder: state.selectedCashOrder
          ? {
              ...state.selectedCashOrder,
              stages: payload.map((stage: any) => ({
                ...stage,
                stage: stage.stage
                  .split('_')
                  .map((word: string) => capitalize(word))
                  .join(''),
              })),
              stagesLoading: false,
            }
          : null,
      }
    case CashOrderActions.ChangeSelectedFilters:
      return {
        ...state,
        filter: {
          ...state.filter,
          selectedFilters: payload,
        },
      }
    case CashOrderActions.ChangePagingResponse:
      return {
        ...state,
        paging: {
          ...state.paging,
          ...payload,
        },
      }
    case CashOrderActions.GetCurrenciesRequest:
      return {
        ...state,
        currenciesLoading: true,
      }
    case CashOrderActions.GetCurrenciesResponse:
      return {
        ...state,
        currencies: payload,
        currenciesLoading: false,
      }
    case CashOrderActions.GetCurrenciesResponseFail:
      return {
        ...state,
        currenciesLoading: false,
      }
    default:
      return state
  }
}
