import { makeStyles } from '@material-ui/core/styles'

import colors from '@/theme/colors'

export const useClasses = makeStyles(() => ({
  groupTitleRow: {
    background: colors.cmTable.lightGray,
    height: '28px',
  },
  emptyCell: {
    width: '100%',
  },

  rowTitle: {
    paddingBottom: 0,
    paddingTop: 0,
    '&:first-of-type': {
      paddingLeft: '30px',
      fontWeight: 'bold',
    },
  },
}))
