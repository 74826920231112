import { ResponseErrorTypes } from '@/types/common/ErrorResponseTypes'
import { ActionFormTypes } from '@/types/usm/commoTypes/actionFormTypes'
import { LookupConfigCreateUserTypes } from '@/types/usm/lookupTypesConfigUSM/lookupTYpesUSM'
import {
  AddNewUserInfoPayload,
  EditUserInfoTypePayload,
  PersonalCardTypes,
  UpdatePersonalCardTypes,
  UserInformationTypes,
} from '@/types/usm/personalInformation/personalInformationTypes'

export const actionsUsersUSM = {
  // personal Card
  startFetchingPersonalCard: () =>
    ({
      type: '@/START_FETCHING_PERSONAL_CARD',
    } as const),
  getPersonalCardResponse: (payload: PersonalCardTypes) =>
    ({
      type: '@/GET_PERSONAL_CARD_RESPONSE',
      payload,
    } as const),
  getPersonalCardResponseFall: (error: ResponseErrorTypes) =>
    ({
      type: '@/GET_PERSONAL_CARD_RESPONSE_FALL',
      payload: error,
    } as const),
  stopFetchingPersonalCard: () =>
    ({
      type: '@/STOP_FETCHING_PERSONAL_CARD',
    } as const),
  getPersonalCardAsync: (id: string) =>
    ({
      type: '@/GET_PERSONAL_CARD_ASYNC',
      payload: id,
    } as const),
  // ADD new User
  startAddNewUser: () =>
    ({
      type: '@/START_ADD_NEW_USER_USM',
    } as const),
  stopAddNewUser: () =>
    ({
      type: '@/STOP_ADD_NEW_USER_USM',
    } as const),
  getAddUserResponse: (payload: PersonalCardTypes) =>
    ({
      type: '@/GET_ADD_USER_RESPONSE_USM',
      payload,
    } as const),
  getAddUserResponseFall: (payload: ResponseErrorTypes) =>
    ({
      type: '@/GET_ADD_USER_RESPONSE_FALL_USM',
      payload,
    } as const),
  addNewUserAsync: <T>(payload: any, actions: ActionFormTypes<T>) =>
    ({
      type: '@/ADD_NEW_USER_ASYNC_USM',
      payload,
      actions,
    } as const),
  cleanErrors: <T>() =>
    ({
      type: '@/CLEAN_ERRORS_USER_ASYNC_USM',
    } as const),

  // update-personal-card

  startFetchingUpdatePersonalCard: () =>
    ({
      type: '@/START_FETCHING_UPDATE_PERSONAL_CARD_USM',
    } as const),
  stopFetchingUpdatePersonalCard: () =>
    ({
      type: '@/STOP_FETCHING_UPDATE_PERSONAL_CARD_USM',
    } as const),
  setUpdatePersonalCardResponse: (payload: UpdatePersonalCardTypes) =>
    ({
      type: '@/SET_UPDATE_PERSONAL_CARD_RESPONSE_USM',
      payload,
    } as const),

  setUpdatePersonalCardResponseFall: (payload: ResponseErrorTypes) =>
    ({
      type: '@/SET_UPDATE_PERSONAL_CARD_RESPONSE_FALL_USM',
      payload,
    } as const),

  updatePersonalCardAsync: (payload: UpdatePersonalCardTypes) =>
    ({
      type: '@/UPDATE_PERSONAL_CARD_ASYNC_USM',
      payload,
    } as const),

  startFetchingLookupConfigCreateUser: () =>
    ({
      type: '@/START_FETCHING_LOOKUP_CONFIG_CREATE_USER_USM',
    } as const),
  stopFetchingLookupConfigCreateUser: () =>
    ({
      type: '@/STOP_FETCHING_LOOKUP_CONFIG_CREATE_USER_USM',
    } as const),
  setLookupConfigCreateUserResponse: (payload: Array<LookupConfigCreateUserTypes>) =>
    ({
      type: '@/SET_LOOKUP_CONFIG_CREATE_USER__RESPONSE_USM',
      payload,
    } as const),
  setLookupConfigCreateUserResponseFall: (payload: ResponseErrorTypes) =>
    ({
      type: '@/SET_LOOKUP_CONFIG_CREATE_USER__RESPONSE_FALL_USM',
      payload,
    } as const),

  getLookupConfigCreateUserAsync: () =>
    ({
      type: '@/LOOKUP_CONFIG_CREATE_USER_ASYNC_USM',
    } as const),

  getLookupConfigEditUserAsync: () =>
    ({
      type: '@/LOOKUP_CONFIG_EDIT_USER_ASYNC_USM',
    } as const),

  // change user
  changeUserAsync: <T>(payload: EditUserInfoTypePayload, actions: ActionFormTypes<T>) =>
    ({
      type: '@/EDIT_USER_ASYNC_USM',
      payload,
      actions,
    } as const),
  startChangeUser: () =>
    ({
      type: '@/START_CHANGE_USER_USM',
    } as const),
  stopChangeUser: () =>
    ({
      type: '@/STOP_CHANGE_USER_USM',
    } as const),
  getChangeUserResponse: (payload: UserInformationTypes) =>
    ({
      type: '@/GET_CHANGE_USER_RESPONSE_USM',
      payload,
    } as const),
  getChangeUserResponseFall: (error: ResponseErrorTypes) =>
    ({
      type: '@/GET_CHANGE_USER_RESPONSE_FALL_USM',
      payload: error,
    } as const),

  // add-user-to-user-info-block

  startFetchingAddUser: () =>
    ({
      type: '@/START_FETCHING_ADD_USER_USM',
    } as const),
  stopFetchingAddUser: () =>
    ({
      type: '@/STOP_FETCHING_ADD_USER_USM',
    } as const),
  setAddUserResponse: (payload: UserInformationTypes) =>
    ({
      type: '@/SET_ADD_USER_RESPONSE_USM',
      payload,
    } as const),
  setAddUserResponseFall: (payload: ResponseErrorTypes) =>
    ({
      type: '@/SET_ADD_USER_RESPONSE_FAIL_USM',
      payload,
    } as const),

  AddUserInfoTableAsync: <T>(payload: AddNewUserInfoPayload, formActions: ActionFormTypes<T>) =>
    ({
      type: '@/ADD_USER_INFO_ASYNC_USM',
      payload,
      formActions,
    } as const),
  // delete-ser-user-info-block

  startFetchingDeleteUser: () =>
    ({
      type: '@/START_FETCHING_DELETE_USER_USER_INFO_USM',
    } as const),
  stopFetchingDeleteUser: () =>
    ({
      type: '@/STOP_FETCHING_DELETE_USER_USER_INFO_USM',
    } as const),
  setDeleteUserResponse: (payload: Array<number>) =>
    ({
      type: '@/SET_DELETE_USER_INFO_RESPONSE_USM',
      payload,
    } as const),
  setDeleteUserResponseFall: (error: ResponseErrorTypes) =>
    ({
      type: '@/SET_DELETE_USER_INFO_RESPONSE_FAIL_USM',
      payload: error,
    } as const),

  deleteUserInfoTableAsync: (payload?: Array<number>) =>
    ({
      type: '@/DELETE_USER_INFO_ASYNC_USM',
      payload,
    } as const),

  setClearError: () =>
    ({
      type: '@/SET_CLEAR_ERROR_USERS_USM',
    } as const),
}
