import makeStyles from '@material-ui/core/styles/makeStyles'
import { Block } from '@material-ui/icons'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  select: {
    backgroundColor: 'transparent',
    '&::before': {
      display: 'none',
    },
  },
  label: {
    padding: spacing(0, 1),
  },
  menuItemWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    width: '1.3rem',
    height: '1.3rem',
    overflow: 'hidden',
    borderRadius: '50%',
  },
  size: {
    display: 'block',
    width: '2.6rem',
    height: '100%',
    transform: 'translateX(-25%)',
  },
}))
