import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useClasses = makeStyles(({ palette }) =>
  createStyles({
    closeIcon: {
      padding: 0,
      color: palette.grey[200],
    },
  }),
)
