import { fork } from 'redux-saga/effects'

import automatedOperations from './automatedOperations/saga'
import banks from './banks/saga'
import cassetteTypes from './cassetteTypes/saga'
import config from './config/saga'
import currencies from './currencies/saga'
import eventNotifications from './eventNotifications/saga'
import forecast from './forecast/saga'
import realTimeMonitoring from './realTimeMonitoring/saga'
import roundBanknotes from './roundBanknotes/saga'
import uploadLogo from './uploadLogo/saga'

export default function*(): Generator {
  yield fork(uploadLogo)
  yield fork(realTimeMonitoring)
  yield fork(roundBanknotes)
  yield fork(eventNotifications)
  yield fork(automatedOperations)
  yield fork(banks)
  yield fork(cassetteTypes)
  yield fork(currencies)
  yield fork(forecast)
  yield fork(config)
}
