import React, { ReactElement, useMemo } from 'react'
import { List } from '@material-ui/core'
import { mapNodes, MappedNodesProps } from './helpers'
import ObjectNode from './ObjectNode'
import { Props, ObjectsTreeContextProps } from './types'

export const ObjectsTreeContext = React.createContext<ObjectsTreeContextProps>({
  checkboxesTree: true,
  checkedIds: [],
})

const ObjectsTree = ({
  nodes,
  checkedIds,
  setCheckedIds,
  openedByDefault = false,
}: Props): ReactElement => {
  const checkboxesTree = !!(checkedIds && setCheckedIds)

  const mappedNodes: MappedNodesProps[] = useMemo(
    () =>
      (nodes?.folders || []).map(folder =>
        mapNodes({ node: folder, showCheckbox: !!setCheckedIds }),
      ),
    [nodes, setCheckedIds],
  )

  return (
    <ObjectsTreeContext.Provider value={{ checkboxesTree, checkedIds, setCheckedIds }}>
      <List disablePadding dense>
        {mappedNodes?.map(node => (
          <ObjectNode
            key={node.value}
            level={1}
            node={node}
            openedByDefault={openedByDefault}
            checkboxTree={checkboxesTree}
          />
        ))}
      </List>
    </ObjectsTreeContext.Provider>
  )
}

export default React.memo(ObjectsTree)
