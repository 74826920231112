import { ValidationService } from '@/utils/services/validationService'
import { CreatePOSRequestFormFields } from '@/constants/forms/createPosRequestForms/CreatePOSRequestForm'
import { ValidationErrors } from '@/constants/forms/validationErrors'
import { NewMerchantFormFields } from '@/constants/forms/NewMerchantForm'
import { FormikValues } from 'formik'

export const validate = (openNewMerchantForm: boolean, fieldParameters: any) => (
  values: FormikValues,
): FormikValues => {
  const errors: FormikValues = {}

  const checkDependencies = (attributeName: string): boolean => {
    const result = fieldParameters[attributeName].dependedToSetOnlyOneFrom.some(
      (dep: string) => !!values[dep].length,
    )
    return result
  }

  if (!ValidationService.isRequired(values[CreatePOSRequestFormFields.CustomerRequestNumber])) {
    errors[CreatePOSRequestFormFields.CustomerRequestNumber] = ValidationErrors.Required
  }
  if (
    ValidationService.isMoreThan50Symbols(values[CreatePOSRequestFormFields.CustomerRequestNumber])
  ) {
    errors[CreatePOSRequestFormFields.CustomerRequestNumber] = ValidationErrors.MoreThan50Symbols
  }

  if (!ValidationService.isRequired(values[CreatePOSRequestFormFields.ServiceCompany])) {
    errors[CreatePOSRequestFormFields.ServiceCompany] = ValidationErrors.Required
  }
  if (!openNewMerchantForm) {
    if (!ValidationService.isRequired(values[CreatePOSRequestFormFields.Merchant])) {
      errors[CreatePOSRequestFormFields.Merchant] = ValidationErrors.Required
    }
  }
  const sameAddressArray: string[] = [
    NewMerchantFormFields.ActualAddressCountry,
    NewMerchantFormFields.ActualAddressCity,
    NewMerchantFormFields.ActualAddressNewCity,
    NewMerchantFormFields.ActualAddressStreet,
  ]
  if (openNewMerchantForm) {
    Object.keys(fieldParameters).forEach((param: any) => {
      if (
        param === CreatePOSRequestFormFields.CustomerRequestNumber ||
        param === CreatePOSRequestFormFields.ServiceCompany
      )
        return

      if (openNewMerchantForm && values.sameaslegaladdress && sameAddressArray.includes(param))
        return

      if (!ValidationService.isRequired(values[param]) && fieldParameters[param].required) {
        errors[param] = ValidationErrors.Required
      }
      // Validate for Phone number field. Temporary solution. Must be fixed.
      if (!ValidationService.isRequired(values[NewMerchantFormFields.PhoneNumber])) {
        errors[NewMerchantFormFields.PhoneNumber] = ValidationErrors.Required
      }

      // validate email field
      if (
        param === CreatePOSRequestFormFields.Email &&
        values[param] &&
        !ValidationService.isValidEmail(values[param])
      ) {
        errors[param] = ValidationErrors.EmailIsNotValid
      }

      // validate company code field
      if (
        param === CreatePOSRequestFormFields.CompanyCode &&
        !ValidationService.isValidNumber(values[param])
      ) {
        errors[param] = ValidationErrors.AllowedOnlyNumbers
      }

      if (
        fieldParameters[param].dependedToSetOnlyOneFrom &&
        fieldParameters[param].dependedToSetOnlyOneFrom.length &&
        checkDependencies(param)
      ) {
        delete errors[param]
      }
    })
  }

  return errors
}
