export interface Props {
  width: number
  height: number
}

export enum ModalsNames {
  SettingsModal = `settingsModal`,
  DocumentModal = 'documentModal',
  FiltersModal = 'filtersModal',
}

export enum BagModalNames {
  AddBag = 'addBag',
  EditBag = 'editBag',
}

export enum BagStatuses {
  None = 'none',
  Empty = 'empty',
  OnBalance = 'onBalance',
}
