import React from 'react'

import { store } from '@/App'

export const ProductsRoutes = React.lazy(async () => {
  // TODO: change sd to products after create products redux
  const {
    default: { reducer: productsDrawerActionsReducer, saga: productsDrawerActionsSaga },
  } = await import('@/store/sd/drawerActions')
  store.injectReducer('drawerActions', productsDrawerActionsReducer)
  store.injectSaga('drawerActions', productsDrawerActionsSaga)

  const {
    default: { reducer: reducerTickets, saga: sagaTickets },
  } = await import('@/store/sd/tickets')

  store.injectReducer('tickets', reducerTickets)
  store.injectSaga('tickets', sagaTickets)

  const {
    default: { reducer: reducerProducts, saga: sagaProducts },
  } = await import('@/store/products')

  store.injectReducer('products', reducerProducts)
  store.injectSaga('products', sagaProducts)

  const {
    default: { reducer: reducerCommonSearchForm, saga: sagaCommonSearchForm },
  } = await import('@/store/common/searchForm')

  store.injectReducer('commonSearchForm', reducerCommonSearchForm)
  store.injectSaga('commonSearchForm', sagaCommonSearchForm)

  const {
    default: { reducer: productsImportReducer, saga: productsImportSaga },
  } = await import('@/store/products/productsImport')
  store.injectReducer('productsImport', productsImportReducer)
  store.injectSaga('productsImport', productsImportSaga)

  return import('@/components/routers/products')
})
