import { Box, Divider, Grid, Typography } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import BS2Logo from '@/assets/vault/icons/bs2_logo.svg'
import CloseButton from '@/components/blocks/CloseButton'
import { DraggableDialog } from '@/components/blocks/cm'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'
import { DialogProps } from './types'

export const AboutModal: React.FC<DialogProps> = (props: DialogProps) => {
  const { open, handleClose, buildInfo } = props

  const classes = useClasses()
  const translate = useTranslate()

  const buildText = useMemo(
    () => [
      {
        id: 1,
        name: translate('translate#cm.ClientBuildInfo'),
        value: buildInfo?.clientBuildInfo,
      },
      {
        id: 2,
        name: translate('translate#cm.ServerBuildInfo'),
        value: buildInfo?.serverBuildInfo,
      },
    ],
    [buildInfo],
  )

  const currentYear = new Date().getFullYear()

  return (
    <DraggableDialog
      id="aboutModal"
      maxWidth={false}
      fullWidth
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.modal }}
    >
      <CloseButton onClick={handleClose} absolute />
      <Paper className={classes.paper}>
        <Grid item md={12}>
          <Typography variant="h3" className={classes.modalHeader}>
            {translate('translate#cm.AboutPageTitle')}
          </Typography>
        </Grid>
        <Box className={classes.horizontalWrap}>
          {buildInfo && (
            <Box className={classes.buildColumn}>
              {buildText.map(item => (
                <div key={item.id} className={classes.group}>
                  <Typography variant="body1" className={classes.groupTitle}>
                    {item.name}:
                  </Typography>
                  <Typography variant="h3" className={classes.bold}>
                    {item.value}
                  </Typography>
                </div>
              ))}
            </Box>
          )}

          <img src={BS2Logo} />
        </Box>
        <Divider className={classes.margin} />
        <Box>
          <Typography className={clsx(classes.groupTitle, classes.copyright)}>
            <FormattedMessage id="title.Copyright" defaultMessage="© Copyright" />
          </Typography>
          <Typography className={clsx(classes.groupTitle, classes.mt, classes.copyright)}>
            <FormattedMessage
              id="title.penkiKontinentaiFullName"
              defaultMessage={`${currentYear} UAB "Penki kontinentai" All rights reserved. UAB "PENKI KONTINENTAI TECHNOLOGIJOS`}
              values={{
                currentYear,
              }}
            />
          </Typography>
          <Typography className={clsx(classes.groupTitle, classes.mt, classes.copyright)}>
            <FormattedMessage
              id="title.penkiKontinentaiAddress"
              defaultMessage="2 Kareiviu., LT-08248, Vilnius, Lithuania"
            />
          </Typography>
        </Box>
      </Paper>
    </DraggableDialog>
  )
}
