import queryString from 'query-string'

import { AdmCurrency } from '@/api/cm/swaggerGeneratedApi'
import { Sort } from '@/types/cm'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

interface UpdateProps {
  name: string
  shortName: string
  address: string
  id: string
}

export class CurrenciesApi {
  private static requestService = getRequestServiceCM()

  public static async get(sort: Sort): Promise<AdmCurrency[]> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/currencies',
          query: { ...sort },
        },
        { skipNull: true, encode: false },
      )

      return await CurrenciesApi.requestService.get(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async add(payload: { name: string }): Promise<void> {
    try {
      return await CurrenciesApi.requestService.post('/admin/currencies/add', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async update(payload: UpdateProps): Promise<void> {
    try {
      return await CurrenciesApi.requestService.put('/admin/currencies/update', payload)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async delete(id: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/currencies/delete',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await CurrenciesApi.requestService.delete(url)
    } catch (error) {
      throw Error(error)
    }
  }

  public static async setBase(id: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/currency/base',
          query: { id },
        },
        { skipNull: true, encode: false },
      )

      return await CurrenciesApi.requestService.patch(url)
    } catch (error) {
      throw Error(error)
    }
  }
}
