/* eslint-disable */
import React, { ReactElement, useCallback, useEffect } from 'react'
import { TestAttributesConfig } from 'react-test-attributes'
import 'moment'
import { IntlProvider } from 'react-intl-redux'
import { Provider } from 'react-redux'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import { INITIAL_LOCALE, ALLOWED_LOCALES } from '@/constants'
import { REACT_APP_CM_STANDALONE } from '@/constants/moduleMode'
import { LocalStorageItems } from '@/constants/localStorageItems'
import ThemeProviderWrapper from '@/components/wrappers/ThemeProvider'
import GlobalPopUp from '@/components/blocks/GlobalPopUp'
import Router from '@/Router'
import { configureStore } from '@/store'
import { setLocale } from '@/store/internalization'
import { PopUpService } from '@/utils/services/popUpService'
import { moduleCheckIsLogin, conditionForCheckIsLogin } from '@/helpers/checkIsLogin'
import { history } from './Router'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { queryClient } from '@/constants/queryClient'
import { missedKeys } from './constants/endpoints'
/* eslint-enable */

export const store = configureStore()

const onIntlError = (e: string): void => {
  console.warn(e)
  missedKeys[e.match(/(?<=").+?(?="(,| f))/)?.[0] || ''] = ''
}

const checkLogin = (pathname: string): void => {
  if (conditionForCheckIsLogin({ pathname })) {
    const actionFunc = moduleCheckIsLogin({ pathname })

    actionFunc()
  }
}

const App = (): ReactElement => {
  useEffect(() => {
    const { pathname } = window.location

    checkLogin(pathname)

    const historyListener = history.listen(({ pathname }) => {
      checkLogin(pathname)
    })

    let locale
    const localeFromLocalStorage =
      REACT_APP_CM_STANDALONE && process.env.NODE_ENV !== 'development'
        ? localStorage.getItem(LocalStorageItems.Locale)
        : sessionStorage.getItem(LocalStorageItems.Locale)

    const isLogged = store.getState().auth.currentAccount
    const loginModalLocale = !isLogged && localStorage.getItem(LocalStorageItems.LogInLocale)

    if (localeFromLocalStorage) {
      locale = localeFromLocalStorage
    } else {
      locale = INITIAL_LOCALE
      // define locale by user locale
      // const [userLocale] = getUserLocale().split('-')
      // locale = userLocale
    }

    if (loginModalLocale) {
      locale = loginModalLocale
    }

    if (ALLOWED_LOCALES.includes(locale)) {
      store.dispatch(setLocale({ locale, isLoginModal: !isLogged }))
    }

    return (): void => historyListener()
  }, [])

  // @todo refactor this (not needed calls)
  const initializeGlobalPopUp = useCallback((ref): void => {
    if (ref) {
      PopUpService.setPopUpShow(ref.show)
    }
  }, [])

  return (
    <Provider store={store}>
      <IntlProvider locale={INITIAL_LOCALE} onError={onIntlError}>
        <ThemeProviderWrapper>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <TestAttributesConfig value={{ enableInProductionMode: true }}>
              <GlobalPopUp ref={initializeGlobalPopUp} />
              <QueryClientProvider client={queryClient}>
                <Router />
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </TestAttributesConfig>
          </MuiPickersUtilsProvider>
        </ThemeProviderWrapper>
      </IntlProvider>
    </Provider>
  )
}

export default App
