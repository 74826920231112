export interface SalaryItem {
  companyPersonnelSalaryId: number | null
  fromDate: string
  toDate: string | null
  monthSalary: number
}

export interface DayOffItem {
  companyPersonnelDayOffId: number | null
  companyPersonnelId: number | null
  dayOffTypeId: number | null
  dayOffTypeName?: string
  fromDate: string
  toDate: string | null
}

export interface DutySchedule {
  dutyDate: string
  companyPersonnelDutyScheduleId2: number | null
  fromTimeH2: number | null
  fromTimeM2: number | null
  toTimeH2: number | null
  toTimeM2: number | null
  companyPersonnelDutyScheduleId1: number | null
  fromTimeH1: number | null
  fromTimeM1: number | null
  toTimeH1: number | null
  toTimeM1: number | null
  companyWorkShiftId: number | null
  companyWorkShiftName: string | null
  totWorkTimeH: number | null
  totWorkTimeM: number | null

  checked: boolean
  needToAdd: boolean
}

export interface DutyScheduleRequestItem {
  dutyDate: string
  fromTimeH2: number | null
  fromTimeM2: number | null
  toTimeH2: number | null
  toTimeM2: number | null
  fromTimeH1: number | null
  fromTimeM1: number | null
  toTimeH1: number | null
  toTimeM1: number | null
  companyWorkShiftId: number | null
}

export interface QualificationLevelRequest {
  companyPersonnelQLevId: number | null
  workAreaId: number
  qualificationLevelId: number
}
export interface QualificationLevel {
  companyPersonnelQLevId: number | null
  workAreaId: number
  workAreaName: string
  qualificationLevelId: number
  qualificationLevelName: string
}
export interface PersonnelRoleHistoryResponse {
  companyPersonnelInRoleHistId: number
  departmentId?: number | null
  departmentName?: string | null
  fromDate: string | null
  toDate?: string | null
  flag: number
  roles: Role[]
}

// company types start

export interface CompanyTreeElement {
  itemDisplayName: string
  itemFunctionalTypeId: number
  itemId: number
  nodeId: number
  parentCompanyId: number | null
  parentDepartmentId: number | null
  parentNodeId: number
  parentCompany: string
  parentDepartment: string
}

export interface CompanyType {
  id: number
  name: string
}

export interface Address {
  address: string
  cityId: number
  cityRegionId: number
  countryId: number
  flatNumber: string
  houseNumber: string
  location: string
  regionId: number
  stateId: number
  streetId: number
}

interface CompanyGeneralInfo {
  companyName: string
  companyShortName: string
  companyTypeId: number | null
  countryId: number | null
  email: string
  homePageUrl: string
  notes: string
  parentCompanyId: number | null
  phoneNumber: string
  registrationNumber: string
  timeZoneCode: string | null
  vatCode: string
}

export interface Role {
  id: number
  isChecked: boolean
  value: string
  orderNr: number | null
}

export interface TimePeriod {
  workingHourId: number | null
  fromTime: string
  toTime: string
}

export interface WorkingHour {
  dayOfWeek: string
  timePeriods: TimePeriod[]
}

export interface WorkingHours {
  workTimeMonday: string
  workTimeTuesday: string
  workTimeWednesday: string
  workTimeThursday: string
  workTimeFriday: string
  workTimeSaturday: string
  workTimeSunday: string
}

export interface WorkingHourRequest {
  dayOfWeek: string
  fromTime: string
  toTime: string
  workingHourId: number | null
}

export interface CompanyRequest {
  companyGeneralInfo: CompanyGeneralInfo
  roles: Role[]
  userIds: number[]
}

export interface CreateCompanyRequestBody {
  companyGeneralInfo: CompanyGeneralInfo
  address: Address
  roles: Role[]
  userIds: number[]
  workingHours: WorkingHour[]
}

export interface CreateCompanyResponse {
  address: AddressResponse
  companyGeneralInfo: CompanyGeneralInfoResponse
  roles: Role[]
  workingHours: WorkingHour[]
}

export interface AddressResponse {
  address: string
  addressId: number
  cityId: number
  cityName: string
  cityRegionId: number
  cityRegionName: string
  countryId: number
  countryName: string
  flatNumber: string
  houseNumber: string
  location: string
  regionId: number
  regionName: string
  stateId: number
  stateName: string
  streetId: number
  streetName: string
}

export interface CompanyGeneralInfoResponse {
  companyId: number
  companyName: string
  companyShortName: string
  companyTypeId: number
  companyTypeName: string
  email: string
  homePageUrl: string
  notes: string
  parentCompanyId: number
  parentCompanyName: string
  phoneNumber: string
  registrationNumber: string
  timeZoneCode: string
  timeZoneName: string
  vatCode: string
}

export enum BusinessEntityKey {
  COMPANY = 'COMPANY',
  COMPANY_PERSONNEL = 'COMPANY_PERSONNEL',
  DEPARTMENT = 'DEPARTMENT',
}

// company types end

export interface AddressImport {
  cityName: string
  cityRegionName: string
  countryName: string
  flatNumber: string
  houseNumber: string
  location: string
  regionName: string
  stateName: string
  streetName: string
}

export interface CompanyGeneralInfoImport {
  companyName: string
  companyShortName: string
  companyTypeName: string
  email: string
  homePageUrl: string
  notes: string
  parentCompanyName: string
  phoneNumber: string
  registrationNumber: string
  timeZoneName: string
  vatCode: string
}
export interface ImportCompanyRequestData {
  address: AddressImport
  companyGeneralInfo: CompanyGeneralInfoImport
  roles: Role[]
  rowNumber: number
  workingHours: WorkingHours
}

// department types start

export interface DepartmentGeneralInfo {
  companyId: number
  departmentCode: string
  departmentName: string
  parentDepartmentId: number
  timeZoneCode: string
}

export interface DepartmentRequest {
  departmentGeneralInfo: DepartmentGeneralInfo
  roles: Role[]
}
export interface CreateDepartmentRequestBody {
  address: Address
  departmentGeneralInfo: DepartmentGeneralInfo
  roles: Role[]
  workingHours: WorkingHour[]
}

export interface DepartmentGeneralInfoResponse {
  companyId: number
  companyName: string
  departmentCode: string
  departmentId: number
  departmentName: string
  parentDepartmentId: number
  parentDepartmentName: string
  timeZoneCode: string
  timeZoneName: string
  title: string
}

export interface CreateDepartmentResponse {
  address: AddressResponse
  departmentGeneralInfo: DepartmentGeneralInfoResponse
  roles: Role[]
  workingHours: WorkingHour[]
}

// department types end

// personnel types start

export interface PersonnelGeneralInfo {
  companyId: number
  departmentId: number
  email: string
  employeeId: string
  jobPostId: number
  phoneNumber: string
  timeZoneCode: string
}

interface Person {
  firstName: string
  gender: string
  lastName: string
  middleName: string
  personId: number
  photo: string
}

export interface PersonnelRequest {
  person: Person
  personnelGeneralInfo: PersonnelGeneralInfo
  roles: Role[]
}
export interface CreatePersonnelRequestBody {
  address: Address
  person: Person
  personnelGeneralInfo: PersonnelGeneralInfo
  roles: Role[]
}

export interface PersonnelGeneralInfoResponse {
  personnelId: number
  companyId: number
  companyName: string
  departmentId: number
  departmentName: string
  jobPostId: number
  jobPostName: string
  employeeId: string
  timeZoneCode: string
  timeZoneName: string
  email: string
  phoneNumber: string
}

export interface PersonResponse {
  personId: number
  firstName: string
  middleName: string
  lastName: string
  gender: string
  photo: string
}
export interface CreatePersonnelResponse {
  address: AddressResponse
  person: PersonResponse
  personnelGeneralInfo: PersonnelGeneralInfoResponse
  roles: Role[]
  workingHours: WorkingHour[]
  dayoffs: DayOffItem[]
  dutySchedule: DutySchedule[]
}

// personnel types end

// form config types start

export interface EmptyValue {
  display: string
  value: string
}

export interface LookupValue {
  display: string
  value: string
}

export interface FieldLookupInfo {
  allowRecursiveCallsBySearchFragment: boolean
  defaultValue: string
  dependedOn: string[]
  displayFieldName: string
  emptyValue: EmptyValue
  enableMode: string
  entityName: string
  getValuesURL: string
  lookupValues: LookupValue[]
  returnedListSize: number
  valueFieldName: string
  valueFieldType: string
}

export interface FieldLookupConfigInfo {
  fieldLookupInfo: FieldLookupInfo
  fieldName: string
}

export interface LookupResult {
  id: number
  name: string
}

// form config types end

export interface ServiceCompany {
  id: number
  name: string
}

export interface Country {
  id: number
  name: string
}

export interface ServiceCompaniesResponse {
  serviceCompanies: ServiceCompany[]
}
