import queryString from 'query-string'

import {
  AdmPlnTeamGroup,
  PlasticCard,
  PlasticCardResponse,
  PlasticCardsAssignTeamRequest,
} from '@/api/cm/swaggerGeneratedApi'
import { APIConfiguration } from '@/constants'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

import { GetPlasticCards } from './types'

export class PlasticCardApi {
  private static requestService = getRequestServiceCM()

  public static async getAll({
    paging,
    requestBody,
    sort,
  }: GetPlasticCards): Promise<PlasticCardResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/plasticCards/points',
          query: { ...paging, ...sort },
        },
        { skipNull: true, encode: false },
      )
      return await PlasticCardApi.requestService.post(url, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getSingle(guid: string): Promise<PlasticCard> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/plasticCards/point',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )
      return await PlasticCardApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getTeam(): Promise<AdmPlnTeamGroup[]> {
    try {
      return await PlasticCardApi.requestService.get('/plasticCards/teams')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async assignTeam(payload: PlasticCardsAssignTeamRequest): Promise<void> {
    try {
      return await PlasticCardApi.requestService.post('/plasticCards/assignTeam', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async resetTeam(guid: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/plasticCards/resetTeam',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )
      return await PlasticCardApi.requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }
}
