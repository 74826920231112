import React from 'react'
import { AppNavigationRoutes, VAULT_BLOCK_PREFIX } from '@/constants'
import { DesktopWindows } from '@material-ui/icons'
import MonitoringIcon from '@/assets/vault/icons/monitoring_icon.svg'
import CashOrdersIcon from '@/assets/vault/icons/cash_orders_icon.svg'
import CashBagsIcon from '@/assets/vault/icons/cash_bags_icon.svg'
import NightDepositoryIcon from '@/assets/vault/icons/night_depository_icon.svg'
import EventJournalIcon from '@/assets/vault/icons/event_journal_icon.svg'
import TeamsIcon from '@/assets/vault/icons/teams_icon.svg'
import { SvgIcon } from '@material-ui/core'

export default [
  // {
  //   path: `${VAULT_BLOCK_PREFIX}${AppNavigationRoutes.MonitoringPage}`,
  //   label: 'translate#title.monitoring',
  //   Icon: MonitoringIcon,
  // },
  {
    path: `${VAULT_BLOCK_PREFIX}${AppNavigationRoutes.CashOrdersPage}`,
    label: 'translate#title.cashOrders',
    Icon: CashOrdersIcon,
  },
  {
    path: `${VAULT_BLOCK_PREFIX}${AppNavigationRoutes.BagsPage}`,
    label: 'translate#vault.cashBags',
    Icon: CashBagsIcon,
  },
  // {
  //   path: `${VAULT_BLOCK_PREFIX}${AppNavigationRoutes.NightDepositoryPage}`,
  //   label: 'translate#title.nightDepository',
  //   Icon: NightDepositoryIcon,
  // },
  // {
  //   path: `${VAULT_BLOCK_PREFIX}${AppNavigationRoutes.EventJournalPage}`,
  //   label: 'translate#title.eventJournal',
  //   Icon: EventJournalIcon,
  // },
  {
    path: `${VAULT_BLOCK_PREFIX}${AppNavigationRoutes.TeamsPage}`,
    label: 'translate#vault.teams',
    Icon: TeamsIcon,
  },
]
