import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles({
  root: {
    '& .MuiPaper-root': {
      maxWidth: 660,
      width: '100%',
    },
  },

  wrapper: {
    width: '100%',
    position: 'relative',
    padding: 30,
  },

  closingWindow: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    cursor: 'pointer',
  },

  headerTitle: {
    margin: '0',
    fontSize: '20px',
    fontStyle: 'normal',
    color: '#041517',
  },

  radioGroup: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    marginTop: 11,
  },

  checkBoxes: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',

    '& .MuiTypography-root': {
      fontSize: 14,
      color: '#50a8ae',
    },
  },

  modalFooter: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 20,

    '& .MuiButton-root': {
      margin: 0,
      fontWeight: 400,
      padding: '7px 20px',
      height: 40,
    },

    '& .MuiButton-startIcon': {
      marginRight: 0,
      marginLeft: -20,
    },
  },

  cancelBtn: {
    color: '#929A9B',
  },

  loaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    background: 'rgba(255, 255, 255, 0.7)',
  },
})
