import { getRequestServiceVaultV1 } from '@/utils/services/requestServiceVaultV1'

import {
  CashFlowDenominationEntry,
  CashFlowDetailsRequest,
  CashFlowEntriesResponse,
  CashFlowRequest,
  CashFlowValuableEntry,
  eCashFlowCategoryType,
  eCashFlowClientType,
  MonOperationEntry,
} from '../swaggerGeneratedApi'

export interface CashFlowCurrencyTotals {
  id: string
  categoryType: eCashFlowCategoryType | null
  clientType: eCashFlowClientType
  currencyTotals: Record<string, number>
}

type CashFlowPeriod = 'startOfPeriodRemainders' | 'endOfPeriodRemaindersSystem'
export interface ParsedCashFlowResponse {
  row: CashFlowPeriod
  currencies: CashFlowCurrencyTotals[]
}

export class CashFlowApi {
  private static requestService = getRequestServiceVaultV1()

  public static async getAll(props: CashFlowRequest): Promise<ParsedCashFlowResponse[]> {
    try {
      if (!props.filter.vaultId) {
        return []
      }

      const cashFlows: CashFlowEntriesResponse = await CashFlowApi.requestService.post(
        '/cashFlow/operations',
        props,
      )

      return Object.keys(cashFlows || {}).reduce(
        (acc, key) => [
          ...acc,
          {
            row: key as CashFlowPeriod,
            currencies: (cashFlows[key as CashFlowPeriod] || []).map(flow => ({
              id: `${flow.categoryType !== 'None' ? flow.categoryType : null}-${flow.clientType}`,
              categoryType: flow.categoryType !== 'None' ? flow.categoryType : null,
              clientType: flow.clientType,
              currencyTotals: (flow.currencyTotals || []).reduce(
                (acc, item) =>
                  item.currencyCode ? { ...acc, [item.currencyCode]: item.totalSum || 0 } : acc,
                {} as Record<string, number>,
              ),
            })),
          } as ParsedCashFlowResponse,
        ],
        [] as ParsedCashFlowResponse[],
      )
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async getDenominationsDetails(
    props: Partial<CashFlowDetailsRequest>,
  ): Promise<CashFlowDenominationEntry[]> {
    try {
      return await CashFlowApi.requestService.post('/cashFlow/details/denominations', props)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async getValuablesDetails(
    props: Partial<CashFlowDetailsRequest>,
  ): Promise<CashFlowValuableEntry[]> {
    try {
      return await CashFlowApi.requestService.post('/cashFlow/details/valuables', props)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }

  public static async getOperationDetails(
    props: Partial<CashFlowDetailsRequest>,
  ): Promise<MonOperationEntry[]> {
    try {
      return await CashFlowApi.requestService.post('/cashFlow/details/operations', props)
    } catch (error) {
      throw Error(String(error as Error))
    }
  }
}
