export enum ActionsDashboard {
  StartFetchingHeaderWidgetData = '@/ATMEYE_DASHBOARD/START_FETCHING_WIDGET_HEADER_DATA',
  StopFetchingHeaderWidgetData = '@/ATMEYE_DASHBOARD/STOP_FETCHING_WIDGET_HEADER_DATA',
  FillHeaderWidgetDataSuccess = '@/ATMEYE_DASHBOARD/FILL_HEADER_DATA_SUCCESS',
  FillHeaderWidgetDataError = '@/ATMEYE_DASHBOARD/FILL_HEADER_DATA_ERROR',
  GetHeaderWidgetDataAsync = '@/ATMEYE_DASHBOARD/GET_HEADER_DATA_ASYNC',
  GetWidgetFilterDataAsync = '@/ATMEYE_DASHBOARD/GET_WIDGET_FILTER_DATA_ASYNC',

  StartFetchingAllSubpages = '@/ATMEYE_DASHBOARD/START_FETCHING_ALL_SUB_PAGES',
  StopFetchingAllSubpages = '@/ATMEYE_DASHBOARD/STOP_FETCHING_ALL_SUB_PAGES',
  FillAllSubPagesSuccess = '@/ATMEYE_DASHBOARD/FILL_ALL_SUB_PAGES_SUCCESS',
  FillAllSubPagesError = '@/ATMEYE_DASHBOARD/FILL_ALL_SUB_PAGES_ERROR',
  GetAllSubpagesAsync = '@/ATMEYE_DASHBOARD/GET_ALL_SUB_PAGES_ASYNC',

  FillAddNewSubPageSuccess = '@/ATMEYE_DASHBOARD/FILL_ADD_NEW_SUB_PAGE_SUCCESS',
  GetAddNewSubpagesAsync = '@/ATMEYE_DASHBOARD/GET_ADD_NEW_SUB_PAGE___ASYNC',

  FillDeleteSubPageSuccess = '@/ATMEYE_DASHBOARD/FILL_DELETE_SUB_PAGE_SUCCESS',
  GetDeleteSubpagesAsync = '@/ATMEYE_DASHBOARD/GET_DELETE_SUB_PAGE__ASYNC',

  FillEditSubPageSuccess = '@/ATMEYE_DASHBOARD/FILL_EDIT_SUB_PAGE_SUCCESS',
  GetEditSubpagesAsync = '@/ATMEYE_DASHBOARD/GET_EDIT_SUB_PAGE__ASYNC',

  FillEditMySubpageSuccess = '@/ATMEYE_DASHBOARD/FILL_EDIT_SUB_PAGE_SUCCESS',
  GetEditMySubpageAsync = '@/ATMEYE_DASHBOARD/GET_EDIT_MY_SUB_PAGE__ASYNC',

  FillSubPageCloneSuccess = '@/ATMEYE_DASHBOARD/FILL__SUB_PAGE_CLONE_SUCCESS',
  GetCreateSubPageCloneAsync = '@/ATMEYE_DASHBOARD/GET_CREATE_SUB_PAGE_CLONE_ASYNC',
  CloseClonedPageModal = '@/ATMEYE_DASHBOARD/CLOSE_CLONED_PAGE_MODAL',

  StartFetchingFullSubPagesData = '@/ATMEYE_DASHBOARD/START_FETCHING_FULL_SUB_PAGES_DATA',
  StopFetchingFullSubPagesData = '@/ATMEYE_DASHBOARD/STOP_FETCHING_FULL_SUB_PAGES_DATA',
  FillFullSubPagesDataSuccess = '@/ATMEYE_DASHBOARD/FILL_FULL_SUB_PAGES_DATA_SUCCESS',
  GetFullSubPagesDataAsync = '@/ATMEYE_DASHBOARD/GET_FULL_SUB_PAGES_DATA_ASYNC',
  FillFullSubPagesDataError = '@/ATMEYE_DASHBOARD/FILL_FULL_SUB_PAGES_DATA_ERROR',
  ClearSubPageErrors = '@/ATMEYE_DASHBOARD/CLEAR_SUB_PAGE_ERRORS',
  AllowCreationOfNewPages = '@/ATMEYE_DASHBOARD/ALLOW_CREATION_OF_NEW_PAGES',

  startFetchingPageWidgets = '@/ATMEYE_DASHBOARD/START_FETCHING_PAGE_WIDGETS',
  stopFetchingPageWidgets = '@/ATMEYE_DASHBOARD/STOP_FETCHING_PAGE_WIDGETS',

  FillFullSubPagesWidgetsSuccess = '@/ATMEYE_DASHBOARD/FILL_SUBPAGES_WIDGET_DATA_SUCCESS',

  GetCurrentSubPageDataAsync = '@/ATMEYE_DASHBOARD/GET_CURRENT_SUB_PAGE_DATAASYNC',

  StartFetchingWidgetFilterData = '@/ATMEYE_DASHBOARD/START_FETCHING_WIDGET_FILTER_DATA',
  StopFetchingWidgetFilterData = '@/ATMEYE_DASHBOARD/STOP_FETCHING_WIDGET_FILTER_DATA',
  FillAllSuccessWidgetFilterData = '@/ATMEYE_DASHBOARD/FILL_ALL_WIDGET_FILTER_DATA_SUCCESS',

  GetUnSubScribeSubPageAsync = '@/ATMEYE_DASHBOARD/UNSUBSCRIBE_SUB_PAGE_ASYNC',

  GetDeleteDashboardWidget = '@/ATMEYE_DASHBOARD/GET_DELETE_DASHBOARD_WIDGET',
  StartFetchingDeleteDashboardWidget = '@/ATMEYE_DASHBOARD/START_FETCHING_DELETE_DASHBOARD_WIDGET',
  StopFetchingDeleteDashboardWidget = '@/ATMEYE_DASHBOARD/STOP_FETCHING_DELETE_DASHBOARD_WIDGET',
  FillAllSuccessDeleteDashboardWidget = '@/ATMEYE_DASHBOARD/FILL_ALL_WIDGET_DELETE_DASHBOARD_WIDGET',

  ClearStateFilterDataWidget = '@/ATMEYE_DASHBOARD/CLEAR_STATE_FILTER_DATA_WIDGET',

  FillAllSuccessEditFilterData = '@/ATMEYE_DASHBOARD/FILL_ALL_EDIT_FILTER_DATA',
  FillAllSuccessCreateFilterData = '@/ATMEYE_DASHBOARD/FILL_ALL_CREATE_FILTER_DATA',

  getDevicesCountWidget = '@/ATMEYE_DASHBOARD/GET_DEVICE_COUNT_WIDGETS',
  getEventsCountWidget = '@/ATMEYE_DASHBOARD/GET_EVENT_COUNT_WIDGETS',

  editDevicesCountWidget = '@/ATMEYE_DASHBOARD/EDIT_DEVICE_COUNT_WIDGETS',
  editEventsCountWidget = '@/ATMEYE_DASHBOARD/EDIT_EVENT_COUNT_WIDGETS',
  resetWidgetConfig = '@/ATMEYE_DASHBOARD/RESET_WIDGET_CONFIG',

  StartFetchingViewFilterData = '@/ATMEYE_DASHBOARD/START_FETCHING_VIEW_FILTER_DATA',
  StopFetchingViewFilterData = '@/ATMEYE_DASHBOARD/STOP_FETCHING_VIEW_FILTER_DATA',

  FillAllSuccessViewData = '@/ATMEYE_DASHBOARD/FILL_ALL_VIEW_DATA',
}
