import { createTheme } from '@material-ui/core/styles'

import colors from './colors'
import units from './units'

export const materialTheme = createTheme({
  palette: {
    primary: {
      light: colors.primaryLight,
      main: colors.primary,
      dark: colors.primaryDark,
    },
    secondary: {
      main: colors.secondary,
      light: colors.secondaryLight,
    },
    text: {
      primary: colors.text,
      secondary: colors.text,
      disabled: colors.textDisabled,
      hint: colors.textLight,
    },
    grey: {
      50: colors.grey50,
      100: colors.grey100,
      200: colors.grey200,
      300: colors.grey300,
      400: colors.grey400,
    },
    error: {
      main: colors.danger,
      light: colors.dangerLight,
    },
    background: {
      default: colors.background,
    },
  },
  typography: {
    h5: {
      fontWeight: 'bold',
      marginBottom: units.single,
    },
    h6: {
      fontWeight: 'bold',
      fontSize: units.single * 2.25,
    },
  },
  props: {
    MuiButton: {
      color: 'primary',
      disableElevation: true,
    },
    MuiRadio: {
      color: 'primary',
    },
    MuiCheckbox: {
      color: 'primary',
    },
    MuiGrid: {
      alignItems: 'center',
    },
    MuiTextField: {
      size: 'small',
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    MuiButton: {
      root: {
        fontWeight: 'bold',
        transition: '0.2s',
      },
      containedPrimary: {
        color: 'white',
      },
      contained: {
        // '&:hover': {
        //   filter: 'drop-shadow(0px 4px 4px #00000036)',
        // },
        margin: '8px 0', // space for shadow
      },
      // outlined: {
      //   '&:hover': {
      //     filter: 'drop-shadow(0px 4px 4px #00000064)',
      //   },
      // },
    },
    MuiFormLabel: {
      root: {
        color: colors.textLight,
      },
      asterisk: {
        color: 'red',
      },
    },
    MuiCheckbox: {
      root: {
        color: '#c4c4c4',
      },
    },
    MuiGrid: {
      item: {
        maxWidth: '100%',
      },
    },
    MuiIcon: {
      root: {
        display: 'inline-flex',
        alignItems: 'center',
      },
    },
    MuiTableCell: {
      stickyHeader: {
        left: 'auto',
      },
    },
  },
  spacing: units.single,
})
