import { styledComponentTheme } from '@/theme'
import makeStyles from '@material-ui/core/styles/makeStyles'

export const useClasses = makeStyles(({ spacing }) => ({
  contentItem: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '135px',
  },
  contentItemText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline',
    fontSize: styledComponentTheme.fontSizes.small,
  },
  contentItemTextWithTooltip: {
    display: 'block',
  },
  withTooltip: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: styledComponentTheme.fontSizes.small,
  },
  tooltip: {
    fontSize: styledComponentTheme.fontSizes.small,
    maxWidth: spacing(40),
    padding: spacing(1),
  },

  tooltipWrap: {
    boxShadow: '0px 4px 12px 2px #bdbdbd',
  },
}))
