import React, { FC } from 'react'
import { useFormatNumber } from '@/utils/internalization'

import { Props } from './types'

export const NumberCell: FC<Props> = React.memo((props: Props) => {
  const { value, minimumFractionDigits } = props

  const { formatNumber } = useFormatNumber()

  return value || value === 0 ? <>{formatNumber(value || 0, false, minimumFractionDigits)}</> : null
})
