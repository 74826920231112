import { FormikValues } from 'formik'

import { ResponseError, SavePosTicketRequest } from '@/types'
import { requestService } from '@/utils/services/request'

import {
  AddServiceTypeActionResponse,
  CreatingPOSTicketFormConfig,
  EditingPOSTicketFormConfig,
  IexistingMerchants,
  Option,
  POSServiceFormConfig,
  POSServiceLookupAggregatorResponse,
  SaveTicketResponse,
  TicketInfoFormData,
} from './types'

export class PosApi {
  public static async getPosTicketConfigFormData(): Promise<
    CreatingPOSTicketFormConfig | ResponseError
  > {
    try {
      return await requestService.get('/tickets/api/v1/pos-form-data/initial')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getEditConfigFormData(
    ticketId: number,
  ): Promise<EditingPOSTicketFormConfig | ResponseError> {
    try {
      return await requestService.get('/tickets/api/v1/pos-form-data/edit-ticket', { ticketId })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getPOSServiceFormConfig(
    serviceTypeId: string,
    issuerCompanyId: number,
  ): Promise<POSServiceFormConfig | ResponseError> {
    try {
      return await requestService.get(
        `/form-config/api/v1/pos/service-type/config/${serviceTypeId}`,
        {
          issuerCompanyId,
        },
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getMerchantFormConfig(
    issuerCompanyId: number,
  ): Promise<POSServiceFormConfig | ResponseError> {
    try {
      return await requestService.get('/form-config/api/v1/pos/merchant/config', {
        issuerCompanyId,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async aggregateLookup(
    attributeId: number,
    attributeName: string,
  ): Promise<POSServiceLookupAggregatorResponse | ResponseError> {
    try {
      const response = await requestService.post('/tickets/api/v1/lookup/aggregate', {
        lookupAttributes: [
          {
            attributeId,
            attributeName,
          },
        ],
      })

      return response.lookupResult
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async lookupAttribute({
    attributeId,
    dependencies,
    isText,
    dependenciesAdditional,
  }: {
    attributeId: number
    dependencies: string[]
    isText: boolean
    dependenciesAdditional: string[]
  }): Promise<Option[] | ResponseError> {
    try {
      return await requestService.get('/tickets/api/v1/lookup', {
        attributeId,
        dependencies,
        dependenciesAdditional,
        isText,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async validateCustomerRequestNumber(
    customerRequestNumber: string,
  ): Promise<unknown | ResponseError> {
    try {
      return await requestService.get('/tickets/api/v1/pos/customer-request-number/validate', {
        customerRequestNumber,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async savePosTicketRequest(
    data: SavePosTicketRequest,
  ): Promise<SaveTicketResponse | ResponseError> {
    try {
      return await requestService.post('/tickets/api/v1/pos/save', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async addServiceTypeAction(
    documentId: number,
    serviceTypeConfig: FormikValues,
  ): Promise<AddServiceTypeActionResponse | ResponseError> {
    try {
      return await requestService.post('/tickets/api/v1/pos/item/service-type-action', {
        documentId,
        serviceTypeConfig,
      })
    } catch (error) {
      throw Error(error)
    }
  }

  public static async editPOSRequestItem(
    requestItemId: number,
    documentId: number,
    serviceTypeConfig: FormikValues,
  ): Promise<AddServiceTypeActionResponse | ResponseError> {
    try {
      return await requestService.patch(`/tickets/api/v1/pos/item/${requestItemId}`, {
        documentId,
        serviceTypeConfig,
      })
    } catch (error) {
      throw Error(error)
    }
  }

  public static async sendPOSTicket(documentId: number): Promise<unknown | ResponseError> {
    try {
      return await requestService.post('/tickets/api/v1/pos/send', {
        documentId,
      })
    } catch (error) {
      throw Error(error)
    }
  }

  public static async getTicketInfoFormDataRequest(
    ticketId: number,
  ): Promise<TicketInfoFormData | ResponseError> {
    try {
      return await requestService.get('/tickets/api/v1/pos-form-data/ticket-info', {
        ticketId,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async deletePOSTicketRequest(ticketId: number): Promise<unknown | ResponseError> {
    try {
      return await requestService.delete(`/tickets/api/v1/pos/${ticketId}`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async deletePosRequestItem(
    documentItemId: number,
  ): Promise<TicketInfoFormData | ResponseError> {
    try {
      return await requestService.delete(`/tickets/api/v1/pos/item/${documentItemId}`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async editPosTicketRequest(
    ticketId: number,
    data: FormikValues,
  ): Promise<TicketInfoFormData | ResponseError> {
    try {
      return await requestService.patch(`/tickets/api/v1/pos/${ticketId}`, data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getPosExistingMerchantList(
    fragment: string,
  ): Promise<IexistingMerchants | ResponseError> {
    try {
      return await requestService.get(
        `/tickets/api/v1/lookup/existing-merchant?searchFragment=${fragment}`,
      )
    } catch (e) {
      throw Reflect.construct(Error, [e])
    }
  }
}
