import queryString from 'query-string'

import { GroupNodes } from '@/components/NPMPakage/components/ObjectsTree/types'
import { CardData } from '@/components/pages/atmeye/Administration/BlackList2/BlacklistModal/EditForm/types'
import { ResponseError, TicketsConfigResponse } from '@/types'
import { requestService } from '@/utils/services/request'

import { FilterField, GroupInfo } from '../commonTypes'
import { BlackListFilter, GetBlackListGroupsRequest, TableListReponse, Template } from './types'

export class BlackListApi {
  public static async getCards({
    sort,
    size,
    page,
    ...payload
  }: {
    filter: BlackListFilter
    groupingFilters?: BlackListFilter[]

    size: number
    page: number
    sort: string
  }): Promise<TableListReponse> {
    try {
      return await requestService.post(
        `/atmeye/api/v1/black-list-cards/list?sort=${sort}&size=${size}&page=${page - 1}`,
        payload,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getListFromJsonStorage<T>(payload: {
    type?: string
    name?: string
    sort?: string
    size?: number
    page?: number
  }): Promise<TableListReponse<T>> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/core-common/api/v1/db-storage-json/list',
          query: { ...payload, page: payload.page || 0, size: payload.size || 100 },
        },
        { skipNull: true },
      )

      return await requestService.get(url)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async createJsonStorageItem<T>(payload: {
    json: T
    name: string
    type: string
  }): Promise<Template<T>> {
    try {
      const { json, name, type } = payload
      const url = queryString.stringifyUrl(
        {
          url: '/core-common/api/v1/db-storage-json',
          query: { name, type, forUser: true },
        },
        { skipNull: true },
      )

      return await requestService.post(url, json)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async editJsonStorageItem<T>(payload: {
    json: T
    type: string
    name: string
    id: number
  }): Promise<Template<T>> {
    try {
      const { id, name, type, json } = payload
      return await requestService.put(
        `/core-common/api/v1/db-storage-json/${id}?type=${type}&name=${name}&forUser=true`,
        json,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async deleteJsonStorageItem(id: number): Promise<void> {
    try {
      return await requestService.delete(`/core-common/api/v1/db-storage-json/${id}`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCardsGroups(
    payload: GetBlackListGroupsRequest,
  ): Promise<{ groupNodes: GroupNodes }> {
    try {
      return await requestService.post(`/atmeye/api/v1/black-list-cards/groups`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCardData(cardId: string | number): Promise<CardData> {
    try {
      return await requestService.get(`/atmeye/api/v1/black-list-cards/${cardId}`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async createCard(data: any): Promise<TicketsConfigResponse | ResponseError> {
    try {
      return requestService.post('/atmeye/api/v1/black-list-cards', data)
    } catch (e) {
      throw new Error(e.errorMessage)
    }
  }

  public static editCard = async (
    data: any,
    cardId: string | number,
  ): Promise<TicketsConfigResponse | ResponseError> => {
    try {
      return requestService.put(`/atmeye/api/v1/black-list-cards/${cardId}`, data)
    } catch (e) {
      throw new Error(e.errorMessage)
    }
  }

  public static async removeCard(payload: any): Promise<any> {
    const pairs = payload.map((id: string) => {
      return 'cardIds=' + encodeURIComponent(id)
    })
    await requestService.delete(`/atmeye/api/v1/black-list-cards?${pairs.join('&')}`)
  }

  public static async getExportData<F = Record<string, FilterField>>(
    {
      sort,
      ...payload
    }: {
      filter: F
      config: {
        format: string
        appliedFilters: {
          filterLabel: string
          filterValue: string
        }[]
        selectedOutputFields: Record<string, GroupInfo>
      }
      sort?: string
    },
    buffer?: boolean,
  ): Promise<any | ResponseError> {
    try {
      return await requestService.post(
        `/atmeye/api/v1/black-list-cards/ungrouped-export?sort=${sort}`,
        payload,
        true,
        buffer && { responseType: 'arraybuffer' },
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getExportGroupedData<F = Record<string, FilterField>>(
    {
      sort,
      ...payload
    }: {
      groupsTreeRequest: {
        filter: F
        groupingFilters: F[]
        group: Record<string, GroupInfo>
      }
      config: {
        format: string
        appliedFilters: {
          filterLabel: string
          filterValue: string
        }[]
        selectedOutputFields: Record<string, string>
      }
      sort?: string
    },
    buffer?: boolean,
  ): Promise<any | ResponseError> {
    try {
      return await requestService.post(
        `/atmeye/api/v1/black-list-cards/grouped-export?sort=${sort}`,
        payload,
        true,
        buffer && { responseType: 'arraybuffer' },
      )
    } catch (e) {
      throw new Error(e)
    }
  }
}
