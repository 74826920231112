import { Grid, Typography } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import React from 'react'

import CloseButton from '@/components/blocks/CloseButton'
import { useTranslate } from '@/utils/internalization'

import { DraggableDialog } from '../DraggableDialog'
import ChangePasswordForm from './components/ChangePasswordForm/index'
import { useClasses } from './styles'
import { DialogProps } from './types'

const ChangePasswordModal = ({
  open,
  isLoading = false,
  error = null,
  handleClose,
  handleChangePassword,
}: DialogProps): React.ReactElement => {
  const classes = useClasses()
  const translate = useTranslate()

  const onClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown'): void => {
    if (reason && reason === 'backdropClick') return

    handleClose && handleClose()
  }

  return (
    <DraggableDialog
      id="changePasswordModal"
      maxWidth="xs"
      fullWidth
      open={open}
      keepMounted
      onClose={onClose}
    >
      {handleClose && <CloseButton onClick={handleClose} absolute />}
      <Paper className={classes.paper}>
        <Grid item md={12}>
          <Typography variant="h3" className={classes.modalHeader}>
            {translate('translate#cm.ChangePassword')}
          </Typography>
        </Grid>
        <ChangePasswordForm
          onChangePassword={handleChangePassword}
          isLoading={isLoading}
          error={error}
        />
      </Paper>
    </DraggableDialog>
  )
}

export default ChangePasswordModal
