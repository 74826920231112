import queryString from 'query-string'

import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

import {
  BalAssignCashierRequest,
  BalCashPoint,
  BalCassetteModifyRequest,
  BalCassetteRemoveRequest,
  BalGetBalancingCassettesResponse,
  BalGetBalancingInfoResponse,
  BalReportsRequest,
  BalSetNotLoadedCashOrderBalancingRequest,
  BalSetUnloadedBalancingRequest,
  BalTotalsResponse,
  ComCMReport,
} from '../swaggerGeneratedApi'
import {
  BalCashPointsRequest,
  BalCashPointsResponse,
  BalReportBalancingRequest,
} from './../swaggerGeneratedApi/index'

export class BalancingApi {
  private static requestService = getRequestServiceCM()

  public static async getCashPoints(
    paging: { page: number; pageSize: number },
    requestBody: BalCashPointsRequest,
    sortColumn?: string | undefined,
    sortOrder?: 'asc' | 'desc' | undefined,
  ): Promise<BalCashPointsResponse> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/balancing/points',
          query: {
            pageSize: `${paging.pageSize}`,
            page: `${paging.page}`,
            sortColumn,
            sortOrder,
          },
        },
        {
          skipNull: true,
          encode: false,
        },
      )
      return await BalancingApi.requestService.post(url, requestBody)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async assignCashier(payload: BalAssignCashierRequest): Promise<void> {
    try {
      return await BalancingApi.requestService.post('/balancing/assignCashier', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashPoint(entryId: number): Promise<BalCashPoint> {
    try {
      return await BalancingApi.requestService.get(`/balancing/point?entryId=${entryId}`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getReports(payload: BalReportsRequest): Promise<ComCMReport[]> {
    try {
      return await BalancingApi.requestService.post('/balancing/reports', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getCashPointReports(
    payload: BalReportBalancingRequest,
  ): Promise<ComCMReport[]> {
    try {
      return await BalancingApi.requestService.post('/balancing/balancingReports', payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getBalancingInfo(id: string): Promise<BalGetBalancingInfoResponse> {
    try {
      return await BalancingApi.requestService.post(`/balancing/getBalancingInfo`, { id })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getBalancingCassettes(id: string): Promise<BalGetBalancingCassettesResponse> {
    try {
      return await BalancingApi.requestService.post(`/balancing/getBalancingCassettes`, { id })
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setUnloadedBalancing(payload: BalSetUnloadedBalancingRequest): Promise<void> {
    try {
      await BalancingApi.requestService.post(`/balancing/setUnloadedBalancing`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async setNotLoadedBalancing(
    payload: BalSetNotLoadedCashOrderBalancingRequest,
  ): Promise<void> {
    try {
      await BalancingApi.requestService.post(`/balancing/setNotLoadedBalancing`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async calcUnloadedTotals(
    payload: BalSetUnloadedBalancingRequest,
  ): Promise<BalTotalsResponse> {
    try {
      return await BalancingApi.requestService.post(`/balancing/calculateUnloadedTotals`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async calcNotLoadedTotals(
    payload: BalSetNotLoadedCashOrderBalancingRequest,
  ): Promise<BalTotalsResponse> {
    try {
      return await BalancingApi.requestService.post(`/balancing/calculateNotLoadedTotals`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async modifyCassette(payload: BalCassetteModifyRequest): Promise<void> {
    try {
      await BalancingApi.requestService.put(`/balancing/modifyCassette`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async removeCassette(payload: BalCassetteRemoveRequest): Promise<void> {
    try {
      await BalancingApi.requestService.delete(`/balancing/removeCassette`, payload)
    } catch (e) {
      throw new Error(e)
    }
  }
}
