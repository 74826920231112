import { AnyAction } from 'redux'

// import { CompanyItem } from '@/types/companies/companies'
import {
  AddressResponse,
  CompanyTreeElement,
  CreateCompanyResponse,
  CreateDepartmentResponse,
  CreatePersonnelResponse,
  LookupResult,
  PersonnelRoleHistoryResponse,
  QualificationLevel,
  Role,
  SalaryItem,
} from '@/api/companies/companiesApi/types'
import { companiesActions } from '@/constants/actions/companiesActions'

export interface State {
  currentCompany: CreateCompanyResponse | null
  currentDepartment: CreateDepartmentResponse | null
  currentPersonnel: CreatePersonnelResponse | null
  isFetchingCompanies: boolean
  companyTree: CompanyTreeElement[]
  companies: any[]
  roles: Role[]
  workAreas: LookupResult[]
  qualificationLevelsLookup: LookupResult[]
  salaries: SalaryItem[]
  personnelRolesHistory: PersonnelRoleHistoryResponse[]
  personnelQualificationLevels: QualificationLevel[]
  personnelAddress: AddressResponse | null
  // isDeletingCompany: boolean
  // isCompanyHasDeletedSuccessful: boolean
  deletedTreeItemTimestamp: number | null
  newPersonnelId: number | null
  newCompanyId: number | null
  selectedCompany: CompanyTreeElement | null
  selectedDepartment: CompanyTreeElement | null
  selectedPersonnel: CompanyTreeElement | null
  isAddingToSecurityNetworks: boolean
  isAddedToSecurityNetworks: boolean
  // teams: Team[]
  // users: User[]
  // vehicles: any[]
  error: string | null
}

const initialState: State = {
  currentCompany: null,
  currentDepartment: null,
  currentPersonnel: null,
  isFetchingCompanies: false,
  companyTree: [],
  companies: [],
  roles: [],
  qualificationLevelsLookup: [],
  salaries: [],
  workAreas: [],
  personnelRolesHistory: [],
  personnelQualificationLevels: [],
  personnelAddress: null,
  // isDeletingCompany: false,
  // isCompanyHasDeletedSuccessful: false,
  deletedTreeItemTimestamp: null,
  newPersonnelId: null,
  newCompanyId: null,
  selectedCompany: null,
  selectedDepartment: null,
  selectedPersonnel: null,
  isAddingToSecurityNetworks: false,
  isAddedToSecurityNetworks: false,
  // teams: [],
  // users: [],
  // vehicles: [],
  error: null,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case companiesActions.AddCompanyRequest:
      return {
        ...state,
        isFetchingCompanies: false,
        isAddingToSecurityNetworks: false,
        isAddedToSecurityNetworks: false,
        error: null,
      }
    case companiesActions.AddCompanyResponse:
      return {
        ...state,
        newCompanyId: payload,
        isFetchingCompanies: false,
      }
    case companiesActions.AddCompanyResponseFail:
      return {
        ...state,
        isFetchingCompanies: false,
        error: payload,
      }
    case companiesActions.UpdateCompanyRequest:
      return {
        ...state,
        isFetchingCompanies: false,
        error: null,
      }
    case companiesActions.UpdateCompanyResponse:
      return {
        ...state,
        isFetchingCompanies: false,
      }
    case companiesActions.UpdateCompanyResponseFail:
      return {
        ...state,
        isFetchingCompanies: false,
        error: payload,
      }
    case companiesActions.DeleteCompanyRequest:
      return {
        ...state,
        error: null,
      }
    case companiesActions.DeleteCompanyResponse:
      return {
        ...state,
        deletedTreeItemTimestamp: Date.now(),
      }
    case companiesActions.DeleteCompanyFail:
      return {
        ...state,
        error: payload,
      }
    case companiesActions.DeleteDepartmentRequest:
      return {
        ...state,
        error: null,
      }
    case companiesActions.DeleteDepartmentResponse:
      return {
        ...state,
        deletedTreeItemTimestamp: Date.now(),
      }
    case companiesActions.DeleteDepartmentFail:
      return {
        ...state,
        error: payload,
      }
    case companiesActions.AddPersonnelRequest:
      return {
        ...state,
        error: null,
      }
    case companiesActions.AddPersonnelResponse:
      return {
        ...state,
        newPersonnelId: payload,
      }
    case companiesActions.AddPersonnelResponseFail:
      return {
        ...state,
        error: payload,
      }
    case companiesActions.UpdatePersonnelRequest:
      return {
        ...state,
        error: null,
      }
    case companiesActions.UpdatePersonnelResponse:
      return {
        ...state,
        currentPersonnel: {
          ...state.currentPersonnel,
          dayoffs: payload.personnelData.dayoffs,
        } as CreatePersonnelResponse,
        salaries: payload.personnelData.salary,
        personnelQualificationLevels: payload.personnelData.qualifications,
      }
    case companiesActions.UpdatePersonnelResponseFail:
      return {
        ...state,
        error: payload,
      }
    case companiesActions.DeletePersonnelRequest:
      return {
        ...state,
        error: null,
      }
    case companiesActions.DeletePersonnelResponse:
      return {
        ...state,
        deletedTreeItemTimestamp: Date.now(),
      }
    case companiesActions.DeletePersonnelFail:
      return {
        ...state,
        error: payload,
      }
    case companiesActions.GetCompanyTreeBySearchFragmentRequest:
      return {
        ...state,
        isFetchingCompanies: true,
      }
    case companiesActions.GetCompanyTreeBySearchFragmentResponse:
      return {
        ...state,
        isFetchingCompanies: false,
        companyTree: payload,
      }
    case companiesActions.GetCompanyTreeByCompanyIdRequest:
      return {
        ...state,
        isFetchingCompanies: true,
      }
    case companiesActions.GetCompanyTreeByCompanyIdResponse:
      return {
        ...state,
        isFetchingCompanies: false,
        companyTree: payload,
      }
    case companiesActions.ClearCompanyTree:
      return {
        ...state,
        isFetchingCompanies: false,
        companyTree: payload,
      }
    case companiesActions.GetPersonnelSalariesResponse:
      return {
        ...state,
        isFetchingCompanies: false,
        salaries: payload,
      }
    case companiesActions.GetPersonnelRolesHistoryResponse:
      return {
        ...state,
        isFetchingCompanies: false,
        personnelRolesHistory: payload,
      }
    case companiesActions.GetPersonnelQualificationLevelsResponse:
      return {
        ...state,
        isFetchingCompanies: false,
        personnelQualificationLevels: payload,
      }
    case companiesActions.GetPersonnelAddressResponse:
      return {
        ...state,
        isFetchingCompanies: false,
        personnelAddress: payload,
      }
    case companiesActions.GetRolesResponse:
      return {
        ...state,
        isFetchingCompanies: false,
        roles: payload,
      }
    case companiesActions.GetWorkAreasResponse:
      return {
        ...state,
        isFetchingCompanies: false,
        workAreas: payload,
      }
    case companiesActions.GetQualificationLevelsLookupResponse:
      return {
        ...state,
        isFetchingCompanies: false,
        qualificationLevelsLookup: payload,
      }
    case companiesActions.GetCompanyResponse:
      return {
        ...state,
        isFetchingCompanies: false,
        currentCompany: payload,
      }
    case companiesActions.GetDepartmentResponse:
      return {
        ...state,
        isFetchingCompanies: false,
        currentDepartment: payload,
      }

    case companiesActions.GetPersonnelResponse:
      return {
        ...state,
        isFetchingCompanies: false,
        currentPersonnel: payload,
      }
    case companiesActions.SetSelectedCompany:
      return {
        ...state,
        isFetchingCompanies: false,
        selectedCompany: payload,
      }
    case companiesActions.UnsetSelectedCompany:
      return {
        ...state,
        isFetchingCompanies: false,
        selectedCompany: null,
        currentCompany: null,
      }
    case companiesActions.UnsetNewCompanyId:
      return {
        ...state,
        isFetchingCompanies: false,
        newCompanyId: null,
      }
    case companiesActions.SetSelectedDepartment:
      return {
        ...state,
        isFetchingCompanies: false,
        selectedDepartment: payload,
      }
    case companiesActions.UnsetSelectedDepartment:
      return {
        ...state,
        isFetchingCompanies: false,
        selectedDepartment: null,
        currentDepartment: null,
      }
    case companiesActions.SetSelectedPersonnel:
      return {
        ...state,
        isFetchingCompanies: false,
        selectedPersonnel: payload,
      }
    case companiesActions.UnsetSelectedPersonnel:
      return {
        ...state,
        isFetchingCompanies: false,
        selectedPersonnel: null,
        currentPersonnel: null,
      }
    case companiesActions.GetCompaniesRequest:
      return {
        ...state,
        companies: payload,
        isFetchingCompanies: true,
      }
    case companiesActions.GetCompaniesResponse:
      return {
        ...state,
        isFetchingCompanies: false,
        companies: payload,
      }
    case companiesActions.AddToSecurityNetworksRequest:
      return {
        ...state,
        isAddingToSecurityNetworks: true,
      }
    case companiesActions.AddToSecurityNetworksResponse:
      return {
        ...state,
        isAddingToSecurityNetworks: false,
        isAddedToSecurityNetworks: true,
        newCompanyId: null,
      }
    case companiesActions.AddToSecurityNetworksResponseFail:
      return {
        ...state,
        isAddingToSecurityNetworks: false,
        error: payload,
      }
    default:
      return state
  }
}
