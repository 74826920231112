import ADMIcon from '@/assets/logosModules/adm.svg'
import ATMeyeIcon from '@/assets/logosModules/atmeye.svg'
import CompaniesIcon from '@/assets/logosModules/companies.svg'
import DictionariesIcon from '@/assets/logosModules/dictionaries.svg'
import ProductsIcon from '@/assets/logosModules/products.svg'
import SDWeb from '@/assets/logosModules/servicedesk.svg'
import SRMIcon from '@/assets/logosModules/srm.svg'
import USMIcon from '@/assets/logosModules/usm.svg'
import {
  ADM_BLOCK_PREFIX,
  AppNavigationRoutes,
  ATMEYE_BLOCK_PREFIX,
  CASH_MANAGEMENT_BLOCK_PREFIX,
  COMPANIES_BLOCK_PREFIX,
  DICTIONARY_BLOCK_PREFIX,
  PRODUCTS_BLOCK_PREFIX,
  SD_BLOCK_PREFIX,
  SRM_BLOCK_PREFIX,
  USM_BLOCK_PREFIX,
  VAULT_BLOCK_PREFIX,
} from '@/constants/paths'

export enum UserRoles {
  Client = 'Client',
  Service = 'Service',
  Administrator = 'Administrator',
  Cashier = 'Cashier',
}

export const Modules = {
  CashManagement: 'cashManagement',
  Other: 'other',
  Vault: 'vault',
  VaultV1: 'vault-v1',
  // TODO CHECK
  USM: 'USM',
  Companies: 'companies',
}

type Product = {
  name: string
  value: string
}

type PathForProducts = {
  [key: string]: Product[]
}

export const patchForProducts: PathForProducts = {
  SRV_ServDesk_Prod: [{ name: 'IQ Service Desk', value: SD_BLOCK_PREFIX }],
  CM: [{ name: 'Cash management', value: CASH_MANAGEMENT_BLOCK_PREFIX }],
  VAULT: [{ name: 'Vault', value: VAULT_BLOCK_PREFIX }],
  AE_ATMeye_Prod: [{ name: 'IQ ATMeye', value: ATMEYE_BLOCK_PREFIX }],
}

export const availableModules: any = {
  ClWEB_Perspective: {
    id: 11,
    path: `${SD_BLOCK_PREFIX}${AppNavigationRoutes.SDMainPage}`,
    name: 'ServiceDesk.iQ',
    icon: SDWeb,
  },
  USM_Perspective: { id: 9, path: USM_BLOCK_PREFIX, name: 'USM.iQ', icon: USMIcon },
  Company_Perspective: {
    id: 8,
    path: COMPANIES_BLOCK_PREFIX,
    name: 'Companies.iQ',
    icon: CompaniesIcon,
  },
  ADM_Perspective: { id: 10, path: ADM_BLOCK_PREFIX, name: 'ADM.iQ', icon: ADMIcon },
  SRM_Perspective: { id: 20, path: SRM_BLOCK_PREFIX, name: 'SRM.iQ', icon: SRMIcon },
  Product_Perspective: {
    id: 6,
    path: PRODUCTS_BLOCK_PREFIX,
    name: 'Products.iQ',
    icon: ProductsIcon,
  },
  Dictionary_Perspective: {
    id: 7,
    path: DICTIONARY_BLOCK_PREFIX,
    name: 'Dictionaries.IQ',
    icon: DictionariesIcon,
  },

  ATMeye_Perspective: {
    id: 12,
    path: ATMEYE_BLOCK_PREFIX,
    name: 'Atmeye.iQ',
    icon: ATMeyeIcon,
  },
}
