import { useCallback, useEffect, useRef, useState } from 'react'

import { GroupingTemplate } from '@/api/atmeye/blackListApi/types'
import { StrategyLocalStorage } from '@/components/NPMPakage/components/tableComponents/UITable/strategy'
import { LocalStorageItems } from '@/constants'

import { TableGroup } from '../components/modals/GroupingModal/components/SortBlock/types'
import { GroupNodes } from '../components/ObjectsTree/types'

const useSelectedGrouping = ({
  localStorageKey,
  initialValues = [],
}: {
  localStorageKey: LocalStorageItems
  initialValues?: TableGroup[]
}) => {
  const isInitialMount = useRef(true)
  const groupingLs = StrategyLocalStorage.getData(localStorageKey)

  const [showGroupingModal, setShowGroupingModal] = useState(false)

  const [selectedGrouping, setSelectedGrouping] = useState<TableGroup[]>(
    groupingLs?.selectedGrouping || initialValues,
  )
  const [
    selectedGroupingTemplate,
    handleSetSelectedGroupingTemplate,
  ] = useState<GroupingTemplate | null>(groupingLs?.selectedGroupingTemplate || null)

  const [selectedGroupingNodes, setSelectedGroupingNodes] = useState<GroupNodes>(
    groupingLs?.selectedGroupingNodes || [],
  )

  const handleSetShowGroupingModal = useCallback((): void => setShowGroupingModal(open => !open), [
    setShowGroupingModal,
  ])

  const handleClearGrouping = (): void => {
    setSelectedGrouping(initialValues)
  }

  useEffect(() => {
    StrategyLocalStorage.setData(localStorageKey, {
      selectedGrouping,
      selectedGroupingTemplate,
    })

    if (!isInitialMount.current && selectedGroupingNodes.length) {
      setSelectedGroupingNodes([])
    }
  }, [selectedGrouping, selectedGroupingTemplate])

  useEffect(() => {
    StrategyLocalStorage.setData(localStorageKey, {
      selectedGrouping,
      selectedGroupingTemplate,
      selectedGroupingNodes: selectedGroupingNodes.map(({ nodeId }) => ({ nodeId })),
    })
  }, [selectedGroupingNodes])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    }
  }, [])

  return {
    selectedGrouping,
    handleSetSelectedGrouping: (groping: TableGroup[]): void => {
      setSelectedGrouping(groping)
      setSelectedGroupingNodes([])
    },
    handleClearGrouping,

    selectedGroupingTemplate,
    handleSetSelectedGroupingTemplate,

    showGroupingModal,
    handleSetShowGroupingModal,

    selectedGroupingNodes,
    setSelectedGroupingNodes,
  }
}

export { useSelectedGrouping }
