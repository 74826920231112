import { AnyAction } from 'redux'

import { AdmUser, AdmUserNetworkTreeNode } from '@/api/vault-v1/swaggerGeneratedApi'
import {
  getAllCheckedIds,
  getAllChildIds,
  getOneLevel,
} from '@/components/pages/vault-v1/AdminPage/panels/Objects/UserNetworks/NetworkNode/helpers'
import { SelectedNodes } from '@/components/pages/vault-v1/AdminPage/panels/Objects/UserNetworks/NetworkNode/types'
import { VaultV1Actions } from '@/constants/actions'
import { usersWithNetworksVaultsTableId as tableId } from '@/constants/vault-v1/tableIds'
import { getTableConfig } from '@/helpers/tableLocalStorage'

export type State = {
  users: {
    searchUserName: string
    data: AdmUser[]
    total: number
    isLoaded: boolean
    isLoading: boolean
    sortColumn?: string
    sortOrder?: string
    selected: AdmUser | null

    paging: {
      page: number
      pageSize: number
    }
  }

  networks: {
    data: AdmUserNetworkTreeNode
    isLoaded: boolean
    isLoading: boolean
    changedNetworks: SelectedNodes
    oneLevelNetworks: Omit<AdmUserNetworkTreeNode, 'folders'>[]
    checkedIds: number[]
  }
}

export const initialState: State = {
  users: {
    searchUserName: '',
    data: [],
    total: 0,
    isLoading: false,
    isLoaded: false,
    sortColumn: undefined,
    sortOrder: undefined,
    selected: null,

    paging: {
      page: 1,
      pageSize: getTableConfig({ tableId })?.rowPerPage || 50,
    },
  },

  networks: {
    data: {},
    isLoading: false,
    isLoaded: false,

    changedNetworks: {},
    oneLevelNetworks: [],
    checkedIds: [],
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    // [START] USER NETWORKS -----------------------------------------------------
    case VaultV1Actions.AdmSetSearchUserName:
      return {
        ...state,
        users: {
          ...state.users,
          searchUserName: payload,
        },
      }
    // Users
    case VaultV1Actions.AdmSetSelectedNetworkUser:
      return {
        ...state,
        users: {
          ...state.users,
          selected: payload,
        },
      }
    case VaultV1Actions.AdmSortNetworkUsers:
      return {
        ...state,
        users: {
          ...state.users,
          ...payload,
          isLoading: true,
        },
      }

    case VaultV1Actions.AdmGetNetworkUsersRequest:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: true,
        },
      }
    case VaultV1Actions.AdmGetNetworkUsersFail:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: false,
          isLoaded: true,
        },
      }

    case VaultV1Actions.AdmGetNetworkUsersResponse:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: false,
          isLoaded: true,
          data: payload.users,
          total: payload.total,
        },
      }

    case VaultV1Actions.AdmSetNetworkUsersPaging:
      return {
        ...state,
        users: {
          ...state.users,
          selected: null,
          isLoading: true,
          paging: {
            ...state.users.paging,
            ...payload,
          },
        },
      }

    case VaultV1Actions.AdmSetUserNetworkNodesRequest:
    case VaultV1Actions.AdmGetUserNetworkNodesRequest:
      return {
        ...state,
        networks: {
          ...state.networks,
          isLoading: true,
        },
      }
    case VaultV1Actions.AdmGetUserNetworkNodesResponse: {
      const oneLevelNetworks = getOneLevel(payload?.folders || [])
      const checkedIds = getAllCheckedIds(payload?.folders || [])

      return {
        ...state,
        networks: {
          ...state.networks,
          isLoading: false,
          isLoaded: true,
          data: payload,
          oneLevelNetworks,
          changedNetworks: initialState.networks.changedNetworks,
          checkedIds: checkedIds,
        },
      }
    }

    case VaultV1Actions.AdmSetChangedNetworks: {
      const { folders, isSelected, id } = payload.node as AdmUserNetworkTreeNode
      const { parentNodeIds, checked } = payload as { parentNodeIds: number[]; checked: boolean }

      const newNetworks = { ...state.networks.changedNetworks }
      const { oneLevelNetworks } = state.networks

      const childrenNodeIds = getAllChildIds(folders || [])

      const localCheckedIds = new Set([...state.networks.checkedIds])

      const networksId = Number(id)
      const isExist = localCheckedIds.has(networksId)

      if (isExist) {
        localCheckedIds.delete(networksId)
        childrenNodeIds.forEach(childId => {
          localCheckedIds.delete(childId)
        })
      } else {
        localCheckedIds.add(networksId)

        childrenNodeIds.forEach(childId => {
          localCheckedIds.add(childId)
        })
        parentNodeIds.forEach(childId => {
          localCheckedIds.add(childId)
        })
      }

      if (id) {
        if (checked) {
          newNetworks[id] = {
            id,
            isSelected: true,
          }

          parentNodeIds.forEach(parentNodeId => {
            newNetworks[parentNodeId] = {
              id: parentNodeId,
              isSelected: true,
            }
          })
          childrenNodeIds.forEach(nodeId => {
            newNetworks[nodeId] = {
              id: nodeId,
              isSelected: true,
            }
          })
        } else {
          newNetworks[id] = {
            id,
            isSelected: false,
          }

          childrenNodeIds.forEach(nodeId => {
            const node = oneLevelNetworks.find(network => network.id === nodeId)

            if (node?.isSelected || newNetworks[nodeId]?.isSelected) {
              newNetworks[nodeId] = {
                id: nodeId,
                isSelected: false,
              }
            } else {
              delete newNetworks[nodeId]
            }
          })
        }

        if (isSelected === checked) {
          delete newNetworks[id]
        }
      }

      return {
        ...state,
        networks: {
          ...state.networks,
          isLoading: false,
          isLoaded: true,
          changedNetworks: newNetworks,
          checkedIds: Array.from(localCheckedIds),
        },
      }
    }
    // [END] USER NETWORKS ------------------------------------------------------

    case VaultV1Actions.SignOut:
      return initialState
    default:
      return state
  }
}
