import { ButtonBase, Checkbox, MenuItem, Typography } from '@material-ui/core'
import { Cached, ExitToApp, ExpandLess, ExpandMore, Lock } from '@material-ui/icons'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import React, { ReactElement, SyntheticEvent, useCallback, useState } from 'react'
import { isIE } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { AboutModal } from '@/components/blocks/cm'
import ChangePasswordModal from '@/components/blocks/cm/ChangePasswordModal'
import GlobalConfirmModal from '@/components/blocks/LogoutConfirmModal/component'
import ContextMenu from '@/components/controls/ContextMenu/component'
import {
  AppNavigationRoutes,
  emptyUserSettingsConfig,
  LocalStorageItems,
  vaultV1BaseUrl,
} from '@/constants'
import {
  getParsedLocalStorageItem,
  getUserDataLocalStorage,
  setDataToLocalStorage,
} from '@/helpers/localStorage'
import {
  changePasswordRequest,
  getChangePasswordState,
  setChangePasswordModalOpen,
  signOutVaultV1,
} from '@/store/vault-v1/auth'
import { getAboutModalOpen, getBuildInfoVaultV1, setAboutModalOpen } from '@/store/vault-v1/common'
import { ChangePasswordFormValuesCM } from '@/types'
import { useTranslate } from '@/utils/internalization'

import { useClasses } from './styles'

const setLocalStorageWithBaseUrl = setDataToLocalStorage(vaultV1BaseUrl)
const getParsedLocalStorageItemBaseUrl = getParsedLocalStorageItem(vaultV1BaseUrl)

const UserMenuVaultV1 = (): ReactElement => {
  const classes = useClasses()
  const history = useHistory()
  const dispatch = useDispatch()
  const translate = useTranslate()

  const [anchorEl, setAnchorEl] = useState<null | (EventTarget & HTMLButtonElement)>(null)
  const [isShowLogoutConfirmModal, setIsShowLogoutConfirmModal] = useState(false)

  const buildInfo = useSelector(getBuildInfoVaultV1)

  const { isModalOpen: isChangePasswordModalOpen, isLoading, error } = useSelector(
    getChangePasswordState,
  )
  const { isModalOpen: isAboutModalOpen } = useSelector(getAboutModalOpen)

  const allUsersSettings = getParsedLocalStorageItemBaseUrl(LocalStorageItems.UsersSettings) || {}
  const { displayName, login } = getUserDataLocalStorage(vaultV1BaseUrl) || {}
  const { isAppBarVisible, isObjectsTreeVisible, isReportsPreview, isTablesWithBorder } =
    allUsersSettings[login] || {}

  const handleChangePasswordModal = useCallback(() => {
    dispatch(setChangePasswordModalOpen(!isChangePasswordModalOpen))
  }, [isChangePasswordModalOpen])

  const handleAboutModal = useCallback(() => {
    dispatch(setAboutModalOpen(!isAboutModalOpen))
  }, [isAboutModalOpen])

  const handleClick = useCallback((event: SyntheticEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback((): void => {
    setAnchorEl(null)
  }, [])

  const handleSignOut = useCallback((): void => {
    handleClose()
    dispatch(signOutVaultV1(true))
    history.push(AppNavigationRoutes.SignInPage)
  }, [dispatch])

  const handleOpenCloseLogoutConfirmModal = useCallback((): void => {
    setIsShowLogoutConfirmModal(isShowLogoutConfirmModal => !isShowLogoutConfirmModal)
  }, [setIsShowLogoutConfirmModal])

  const updateLocalStorageByKey = (key: string): void => {
    const allUsersSettings = getParsedLocalStorageItemBaseUrl(LocalStorageItems.UsersSettings) || {}
    const currentUserSettings = allUsersSettings[login]

    if (currentUserSettings) {
      const dataToChange = {
        [login]: {
          ...currentUserSettings,
          [key]: !currentUserSettings[key],
        },
      }

      setLocalStorageWithBaseUrl(LocalStorageItems.UsersSettings, JSON.stringify(dataToChange))
    }
  }

  const handleClearUserSettings = useCallback((): void => {
    const initialUserSettings = {
      [login]: emptyUserSettingsConfig,
    }

    setLocalStorageWithBaseUrl(LocalStorageItems.UsersSettings, JSON.stringify(initialUserSettings))

    window.location.reload()
  }, [getParsedLocalStorageItem, login])

  const handleChangePassword = useCallback<(values: ChangePasswordFormValuesCM) => void>(
    (values): void => {
      dispatch(changePasswordRequest(values))
    },
    [dispatch],
  )

  const handleToggleAppBar = useCallback((): void => {
    updateLocalStorageByKey('isAppBarVisible')
    window.location.reload()
  }, [])

  const handleToggleObjectsTree = useCallback((): void => {
    updateLocalStorageByKey('isObjectsTreeVisible')
    window.location.reload()
  }, [])

  const handleToggleTablesBorder = useCallback((): void => {
    updateLocalStorageByKey('isTablesWithBorder')
    window.location.reload()
  }, [])

  const handleReportPreview = useCallback((): void => {
    updateLocalStorageByKey('isReportsPreview')
    handleClose()
  }, [])

  const userMenu = [
    {
      id: '1',
      content: (
        <>
          <ExitToApp className={classes.icon} />
          <span className={classes.label}>
            <FormattedMessage id="title.logout" defaultMessage="Logout" />
          </span>
        </>
      ),
      handleClick: handleOpenCloseLogoutConfirmModal,
    },
    {
      id: '2',
      content: (
        <>
          <Lock className={classes.icon} />
          <span className={classes.label}>
            <FormattedMessage id="cm.ChangePassword" defaultMessage="Change password" />
          </span>
        </>
      ),
      handleClick: handleChangePasswordModal,
    },
    {
      id: '3',
      content: (
        <>
          <Cached className={classes.icon} />
          <span className={classes.label}>
            <FormattedMessage id="cm.ClearSettings" defaultMessage="Clear Settings" />
          </span>
        </>
      ),
      handleClick: handleClearUserSettings,
    },
    {
      id: '3',
      content: (
        <>
          <Checkbox className={classes.checkbox} checked={isAppBarVisible} />
          <span className={classes.label}>
            <FormattedMessage id="cm.ShowTabs" defaultMessage="Show Tabs" />
          </span>
        </>
      ),
      handleClick: handleToggleAppBar,
    },
    {
      id: '4',
      content: (
        <>
          <Checkbox className={classes.checkbox} checked={isObjectsTreeVisible} />
          <span className={classes.label}>
            <FormattedMessage id="cm.ObjectsTree" defaultMessage="Objects Tree" />
          </span>
        </>
      ),
      handleClick: handleToggleObjectsTree,
    },
    {
      id: '5',
      content: (
        <>
          <Checkbox className={classes.checkbox} checked={isReportsPreview} />
          <span className={classes.label}>
            <FormattedMessage id="cm.ReportsPreview" defaultMessage="Reports Preview" />
          </span>
        </>
      ),
      handleClick: handleReportPreview,
    },
    {
      id: '6',
      content: (
        <>
          <Checkbox className={classes.checkbox} checked={isTablesWithBorder} />
          <span className={classes.label}>
            <FormattedMessage id="title.TableGrid" defaultMessage="Grid for tables" />
          </span>
        </>
      ),
      handleClick: handleToggleTablesBorder,
    },
    {
      id: '7',
      content: (
        <>
          <HelpOutlineIcon className={classes.icon} />
          <span className={classes.label}>
            <FormattedMessage id="cm.AboutPageTitle" defaultMessage="About" />
          </span>
        </>
      ),
      handleClick: handleAboutModal,
    },
  ]

  return (
    <>
      <div>
        <ButtonBase className={classes.button} onClick={handleClick}>
          <Typography className={classes.userName}>{displayName || 'DevicePage User'}</Typography>
          {!anchorEl ? (
            <ExpandMore className={classes.icon} />
          ) : (
            <ExpandLess className={classes.icon} />
          )}
        </ButtonBase>
        <ContextMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          limitMenuSize={false}
          classes={
            isIE
              ? {
                  list: classes.list,
                  paper: classes.menuPaper,
                }
              : undefined
          }
          options={!isIE ? userMenu : []}
        >
          {isIE ? (
            <>
              {userMenu.map(item => (
                <MenuItem
                  key={item.id}
                  disableRipple
                  className={classes.item}
                  onClick={item.handleClick}
                >
                  {item.content}
                </MenuItem>
              ))}
            </>
          ) : (
            undefined
          )}
        </ContextMenu>
        <GlobalConfirmModal
          open={isShowLogoutConfirmModal}
          onClose={handleOpenCloseLogoutConfirmModal}
          onConfirm={handleSignOut}
          message={translate('translate#title.areYouSureYouWantLogout')}
          disableBackdropClick
        />
      </div>
      {isChangePasswordModalOpen && (
        <ChangePasswordModal
          open={isChangePasswordModalOpen}
          handleClose={handleChangePasswordModal}
          handleChangePassword={handleChangePassword}
          isLoading={isLoading}
          error={error}
        />
      )}
      {isAboutModalOpen && (
        <AboutModal
          open={isAboutModalOpen}
          handleClose={handleAboutModal}
          buildInfo={buildInfo || {}}
        />
      )}
    </>
  )
}

export default UserMenuVaultV1
