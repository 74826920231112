import moment from 'moment'
import { AnyAction } from 'redux'

import { ParsedCashFlowResponse } from '@/api/vault-v1/cashFlowApi'
import { CashFlowEntry, CashFlowFilter } from '@/api/vault-v1/swaggerGeneratedApi'
import { VaultV1Actions, VaultV1CashFlowActions } from '@/constants'

export type State = {
  modals: {
    filtersModal: boolean
    isLoadingModal: boolean
  }

  cashFlow: {
    data: ParsedCashFlowResponse[]
    isLoading: boolean
    isLoaded: boolean

    errorMessage?: string

    selected: CashFlowEntry | null
    selectedCurrency: string

    filter: CashFlowFilter
  }
}

export const initialState: State = {
  modals: {
    filtersModal: false,
    isLoadingModal: false,
  },

  cashFlow: {
    data: [],
    isLoading: false,
    isLoaded: false,

    errorMessage: '',

    selected: null,
    selectedCurrency: '',

    filter: {
      date: moment()
        .subtract(1, 'month')
        .startOf('day')
        .format(),
      vaultId: undefined,
    },
  },
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    case VaultV1CashFlowActions.GetCashFlowsRequest:
      return { ...state, cashFlow: { ...state.cashFlow, isLoading: true } }
    case VaultV1CashFlowActions.GetCashFlowsResponse: {
      return {
        ...state,
        cashFlow: {
          ...state.cashFlow,
          data: payload,
          isLoading: false,
          isLoaded: true,
        },
      }
    }
    case VaultV1CashFlowActions.GetCashFlowsFail:
      return {
        ...state,
        cashFlow: { ...state.cashFlow, isLoading: false, errorMessage: payload },
      }

    case VaultV1CashFlowActions.SetSelectedCashFlow: {
      const substrings = payload?.id.split('-') || []
      const selectedCurrency = substrings[substrings.length - 1]

      return {
        ...state,
        cashFlow: {
          ...state.cashFlow,
          selected: payload,
          selectedCurrency: selectedCurrency || '',
        },
      }
    }

    // Modal
    case VaultV1CashFlowActions.SetModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.type]: payload.isOpen,
        },
      }

    // Filter
    case VaultV1CashFlowActions.FlwSetFilter:
      return {
        ...state,
        cashFlow: {
          ...state.cashFlow,
          selected: null,
          selectedCurrency: '',
          isLoading: true,

          filter: {
            ...state.cashFlow.filter,
            ...payload,
          },
        },
      }

    case VaultV1Actions.SignOut:
      return initialState

    default:
      return state
  }
}
