import { Field as FormikField } from 'formik'
import { debounce } from 'lodash-es'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import { createDropDownInputField } from '@/components/controls/DropDownInput'
import { itemListData } from '@/components/pages/srm/TicketsPage/modals/NewOrderModals/ItemList/testOptions'
import { useDidUpdateEffect } from '@/utils/hooks/hooks'

import { Props } from './types'

const LookupsSearch = ({
  fieldTitle,
  fieldName,
  field,
  setFormikFieldValue,
  isDisabled,
  onCall,
  onSelect,
  defaultValue,
  isDependsOn,
  dependsOn = [],
  className,
}: Props): ReactElement => {
  const initialValue =
    defaultValue && defaultValue.name && defaultValue.value
      ? {
          name: String(defaultValue.name),
          value: String(defaultValue.value),
        }
      : null

  const [dataIsLoading, setDataIsLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<{ item: any; value: string; name: string }[]>([])

  const getOptions = async (fragment: string): Promise<any> => {
    try {
      setDataIsLoading(true)

      const options: {
        item: any
        value: string
        name: string
      }[] = await onCall(fragment)

      setOptions(options)

      setDataIsLoading(false)
    } catch (error) {
      throw new Error(error)
    }
  }

  const handleSearch = useCallback(
    debounce((value: string) => {
      if (value.length >= 3) {
        getOptions(value)
      }
    }, 300),
    dependsOn,
  )

  useEffect(() => {
    if (!options.find(item => String(item.value) === initialValue?.value)) setOptions([])
  }, [defaultValue?.value])

  useDidUpdateEffect(() => {
    if (isDependsOn) {
      setFormikFieldValue(fieldName, '')
      setFormikFieldValue(fieldTitle, '')
      setOptions([])
    }
  }, dependsOn)

  const Field = useMemo(() => {
    return createDropDownInputField({
      isLoading: dataIsLoading,
      label: field.options?.label,
      placeholder: field.options?.placeholder,
      options: options.length ? options : initialValue ? [initialValue] : [],
      shrink: true,
      timer: field.options?.requestDelay,
      filtering: false,
      neverDisable: false,
      disabled: isDisabled,
      required: field.options?.required,
      onTextChange: handleSearch,
      withoutSingleOption: true,
      onChange: (value: string) => {
        const item = options.find(item => String(item.value) === String(value))
        if (onSelect) onSelect(item)
        else {
          setFormikFieldValue(fieldName, item ? item.value : '')
          setFormikFieldValue(fieldTitle, item ? item.name : '')
        }
      },
      className: className,
    })
  }, [...dependsOn, defaultValue, initialValue, options, dataIsLoading, isDisabled])

  return <FormikField name={fieldName}>{Field}</FormikField>
}

export default LookupsSearch
