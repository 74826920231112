import makeStyles from '@material-ui/core/styles/makeStyles'
import { styledComponentTheme } from '@/theme'

export const useClasses = makeStyles(({ spacing, palette }) => ({
  cellWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  iconWrapper: {
    display: 'block',
    alignItems: 'center',
  },
  cellWrapperWithBackground: {
    padding: spacing(0.25, 1),
    borderRadius: spacing(1.5),
  },
  cellWithFullBackground: {
    display: 'block',
    width: '100%',
    height: '100%',
  },
  cellColor: {
    display: 'inline',
    width: spacing(1),
    height: spacing(1),
    borderRadius: '50%',
    marginRight: spacing(1),
  },
  cellIcon: {
    display: 'block',
    width: spacing(3),
    height: spacing(3),
    borderRadius: '50%',
  },
  groupHeader: {
    marginLeft: spacing(0.5),
    color: palette.primary.main,
  },
  nonHeaderShift: {
    marginLeft: spacing(6),
  },
  expandIcon: {
    color: palette.grey[200],
    transition: '0.2s',
  },
  cell: {
    whiteSpace: 'nowrap',
  },

  withTooltip: {
    overflow: 'hidden',
    fontSize: styledComponentTheme.fontSizes.small,
  },
  contentItemTextWithTooltip: {
    display: 'block',
  },
}))
