import React, { useEffect } from 'react'
import { Props } from './types'
import { preventDefault } from '@/utils/functions'
import Dialog from '@material-ui/core/Dialog/Dialog'
import Box from '@material-ui/core/Box'
import CloseButton from '@/components/blocks/CloseButton'
import ReportModalForm from '@/components/pages/sd/reports/components/ReportsModal/components/ReportModalForm/component'
import clsx from 'clsx'
import Test from 'react-test-attributes'
import { getTestId } from '@/utils'
import { useClasses } from './styles'
import { useDispatch } from 'react-redux'
import { actions } from '@/store/sd/reports'

const ReportsModal: React.FC<Props> = ({
  testIdPrefix,
  open,
  handleClose,
  idReport,
  nameReport,
  handleSubmit,
  // reportById,
  uidReport,
  config,
  hideClearButton,
}): React.ReactElement => {
  const classes = useClasses()
  const dispatch = useDispatch()

  const idPrefix = `${testIdPrefix}-reports-modal`
  const testId = getTestId(idPrefix)

  useEffect(() => {
    if (!open) {
      dispatch(actions.setInitReportsConfig())
    }
  }, [open])

  return (
    <Test id={testId(0)}>
      <Dialog
        onDragOver={preventDefault}
        onDrop={preventDefault}
        maxWidth="lg"
        fullWidth
        open={open}
        keepMounted
        onClose={handleClose}
        classes={{
          paper: clsx(classes.dialog),
        }}
        disableBackdropClick
        transitionDuration={0}
      >
        <Test id={testId(1)}>
          <CloseButton onClick={handleClose} absolute />
        </Test>
        <Test id={testId(2)}>
          <Box display="flex" flexDirection="column" overflow="auto">
            {open && (
              <ReportModalForm
                reportName={nameReport}
                handleSubmit={handleSubmit}
                uidReport={uidReport}
                config={config}
                hideClearButton={hideClearButton}
              />
            )}
          </Box>
        </Test>
      </Dialog>
    </Test>
  )
}

export default ReportsModal
