export enum CashOrderFields {
  Identifier = 'id',
  Date = 'date',
  CashOrderDate = 'cashOrderDate',
  Comment = 'comment',
  OrderType = 'orderType',
  OrderedBy = 'orderedBy',
  OrderStatus = 'orderStatus',
  OrderStatusId = 'orderStatusId',
  CashOrderID = 'cashOrderID',
  WorkOrderID = 'workOrderID',
  FromVaultOrCorporateClient = 'fromVault/CorporateClient',
  ToVaultOrCorporateClient = 'toVault/CorporateClient',
  CurrencyOrValuables = 'currency/valuables',
  TotalSumOrComment = 'totalSum/comment',
  Team = 'team',
  Stamp = 'stamp',
  Transport = 'transport',
  NameSurname = 'nameSurname',
  PassData = 'passData',
  VaultCashOrderId = 'vaultCashOrderId',
  NumberOfBags = 'numberOfBags',
}

export enum CashOrderStageFields {
  CanGoToThisStage = 'canGoToThisStage',
  Id = 'stageID',
  IsCurrent = 'thisCurrentStage',
  Name = 'stage',
  StageExecutedBy = 'stageExecutedBy',
  StagePassedDateTme = 'stagePassedDateTme',
}

export enum CashOrderDetailFields {
  Amount = 'sum',
  Currency = 'currency',
  Denomination = 'denomination',
  Quantity = 'quantity',
}

export enum ValuableOrderDetailFields {
  Id = 'nr',
  ValuableType = 'valuableType',
  Comment = 'comments',
  Quantity = 'quantity',
}

export interface CashOrder {
  [CashOrderFields.CashOrderDate]: string | null | undefined
  [CashOrderFields.CashOrderID]: string | null | undefined
  [CashOrderFields.Comment]: string | null | undefined
  [CashOrderFields.CurrencyOrValuables]: string | null | undefined
  [CashOrderFields.Date]: string | number | null | undefined
  [CashOrderFields.FromVaultOrCorporateClient]: string | null | undefined
  [CashOrderFields.Identifier]: string
  [CashOrderFields.NameSurname]: string | null | undefined
  [CashOrderFields.NumberOfBags]: string | number | null | undefined
  [CashOrderFields.OrderedBy]: string | null | undefined
  [CashOrderFields.OrderStatus]: string | null | undefined
  [CashOrderFields.OrderStatusId]: number | null | undefined
  [CashOrderFields.OrderType]: string | null | undefined
  [CashOrderFields.PassData]: string | null | undefined
  [CashOrderFields.Stamp]: string | null | undefined
  [CashOrderFields.Team]: string | null | undefined
  [CashOrderFields.TotalSumOrComment]: string | null | undefined
  [CashOrderFields.ToVaultOrCorporateClient]: string | null | undefined
  [CashOrderFields.Transport]: string | null | undefined
  [CashOrderFields.VaultCashOrderId]: string | null | undefined
  [CashOrderFields.WorkOrderID]: string | null | undefined
}

export interface CashOrderStatus {
  cashOrderStatusID: number
  cashOrderStatus: string
}

export interface CashOrderType {
  cashOrderTypeID: number
  cashOrderType: string
}

export interface Currency {
  currencyID: number
  currencyName: string
  currencyDetails: Array<{
    quantity: number
    denomination: number
    sum: number | string
  }>
  tableData?: {
    // temp
    id: number
  }
}

export interface Valuable {
  quantity: number
  valuableType: string
  valuableTypeID: number
  tableData?: {
    // temp
    id: number
  }
}

export interface Timestamp {
  date: number
  day: number
  hours: number
  minutes: number
  month: number
  nanos: number
  seconds: number
  time: number
  timezoneOffset: number
  year: number
}

export interface AppUser {
  [key: string]: string | number | boolean | Timestamp
}
