import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { AutomatedOperationsApi } from '@/api/cm/adminApi'
import { CMAppConfigActions } from '@/constants'

import {
  getAutoOperationsFail,
  getAutoOperationsRequest,
  getAutoOperationsResponse,
  updateAutoOperationsFail,
} from './actions'
import { getAutomatedOperationsData } from './selectors'

function* getAutoOperations(): SagaIterator {
  try {
    const response = yield call(AutomatedOperationsApi.get)
    yield put(getAutoOperationsResponse(response))
  } catch (error) {
    yield put(getAutoOperationsFail())
  }
}

function* updateAutoOperations(): SagaIterator {
  try {
    const data = yield select(getAutomatedOperationsData)

    yield call(AutomatedOperationsApi.update, data)

    yield put(getAutoOperationsRequest())
  } catch (error) {
    yield put(updateAutoOperationsFail())
  }
}

export default function*(): Generator {
  yield takeLatest(CMAppConfigActions.AdmGetAutoOperationsRequest, getAutoOperations)
  yield takeLatest(CMAppConfigActions.AdmUpdateAutoOperationsRequest, updateAutoOperations)
}
