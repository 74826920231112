import MaterialButton from '@material-ui/core/Button'
import clsx from 'clsx'
import React from 'react'

import { LightTooltip, useClasses } from './styles'
import { Props } from './types'

const ConditionalButtonTooltipWrapper = ({ condition, wrapper, children }: any) =>
  condition ? wrapper(children) : children

export const Button = ({
  isTooltipAlwaysVisible,
  light = false,
  fullWidth = false,
  borderless = false,
  disableRipple = true,
  width,
  className,
  indentation,
  tooltipTitle = '',
  activeButtonMark,
  palette,
  ...props
}: Props): React.ReactElement => {
  const classes = useClasses()

  const button = (
    <MaterialButton
      disableRipple={disableRipple}
      className={clsx(
        {
          [classes.light]: light,
          [classes.fullWidth]: fullWidth,
          [classes.borderless]: borderless,
          [classes.noWidth]: width === 'xs',
          [classes.widthSmall]: width === 'sm',
          [classes.widthMedium]: width === 'md',
          [classes.widthLarge]: width === 'lg',
          [classes.indentation]: indentation === '0',
          [classes.activeMark]: activeButtonMark,
          [classes.primaryOutlined]: props.variant === 'outlined' && palette === 'primary',
          [classes.primaryContained]: props.variant === 'contained' && palette === 'primary',
          [classes.dangerOutlined]: props.variant === 'outlined' && palette === 'danger',
          [classes.dangerContained]: props.variant === 'contained' && palette === 'danger',
          [classes.secondaryOutlined]: props.variant === 'outlined' && palette === 'secondary',
          [classes.secondaryContained]: props.variant === 'contained' && palette === 'secondary',
          [classes.successOutlined]: props.variant === 'outlined' && palette === 'success',
          [classes.successContained]: props.variant === 'contained' && palette === 'success',
        },
        className,
      )}
      {...props}
    />
  )

  return (
    <ConditionalButtonTooltipWrapper
      condition={tooltipTitle.length > 0}
      wrapper={(children: any) => (
        <LightTooltip title={tooltipTitle} arrow placement="top">
          {children}
        </LightTooltip>
      )}
    >
      {isTooltipAlwaysVisible ? <span>{button}</span> : button}
    </ConditionalButtonTooltipWrapper>
  )
}
