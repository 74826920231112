import { makeStyles, Theme } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'

interface StyleProps {
  indent?: number
}

export const useClasses = makeStyles<Theme, StyleProps>(() => ({
  checkbox: ({ indent }: StyleProps): CreateCSSProperties<StyleProps> => ({
    padding: '4px 9px',
    paddingLeft: 0,

    marginLeft: `${32 * (indent || 0)}px`,
  }),

  field: {
    width: '300px',
    marginTop: '4px',
    marginBottom: '8px',
  },

  radioWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    flexDirection: 'column',
    marginTop: '8px',
  },

  radioWrapperPadding: {
    paddingLeft: '3px',
  },

  radioItem: {
    margin: 0,

    '& > :first-child': {
      padding: '4px 9px',
    },
  },

  indent: ({ indent }: StyleProps): CreateCSSProperties<StyleProps> => ({
    marginLeft: `${32 * (indent || 0)}px`,
  }),
}))
