import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { BalancingApi } from '@/api/cm/balancingApi/index'
import { CMCommonApi } from '@/api/cm/commonApi'
import {
  BalCashPoint,
  BalGetBalancingCassettesResponse,
  BalNotLoadedCassettesCashOrder,
  BalUnloadedCassettesSession,
} from '@/api/cm/swaggerGeneratedApi'
import { balanceCalculate } from '@/components/blocks/cm/CashBalancingModal/components/UnloadedCassettes/components/CashOutTable/helper'
import { CMActions } from '@/constants'
import {
  getBalNotLoadedCassettes,
  getBalSelectedCassette,
  getBalSelectedUnloadedCassettes,
} from '@/store/cm/common'
import { AppState } from '@/store/reducers'
import { hotKeysBinder } from '@/utils/hotKeysBinder'

import { setComCMError, setGlobalFilter } from '../common'
import {
  modifyBalCassettesFail,
  modifyBalCassettesResponse,
  removeBalCassetteFail,
  removeBalCassetteResponse,
  setBalSelectedUnloadedCassettes,
} from '.'
import {
  applyBalNotLoadedCassettesFail,
  applyBalNotLoadedCassettesResponse,
  applyBalUnloadedCassettesFail,
  applyBalUnloadedCassettesResponse,
  assignBalCashierResponse,
  calcBalNotLoadedTotalsFail,
  calcBalNotLoadedTotalsResponse,
  calcBalUnloadedTotalsFail,
  calcBalUnloadedTotalsResponse,
  checkBalAllCashPoints,
  clearBalAllCashPoints,
  getBalCashPointReportResponse,
  getBalCashPointReportResponseFail,
  getBalCashPointReportsResponse,
  getBalCashPointReportsResponseFail,
  getBalCashPointsRequest,
  getBalCashPointsResponse,
  getBalCashPointsResponseFail,
  getBalCassettesFail,
  getBalCassettesRequest,
  getBalCassettesResponse,
  getBalInfoFail,
  getBalInfoRequest,
  getBalInfoResponse,
  getBalReportResponse,
  getBalReportResponseFail,
  getBalReportsResponse,
  getBalReportsResponseFail,
  refreshBalancing,
  resetBalCashPointsPage,
  setBalCashBalancingModalOpen,
  setBalCashPointsFilter,
  setBalCashPointsPagingResponse,
  setBalDocumentModalOpen,
  setBalFiltersModalOpen,
  setBalSelectedCashPoint,
  setBalUnloadedSelectedCassette,
  toggleBalCashPoint,
} from './actions'
import { getBalCashPoints, getSelectedCashPoint } from './selectors'

function* getCashPoints(): any {
  try {
    const paging = yield select((state: AppState) => {
      const { page, pageSize } = state?.cmBalancing?.cashPoints?.paging
      return {
        page,
        pageSize: pageSize === -1 ? 'All' : pageSize,
      }
    })
    const { sortColumn, sortOrder, ...filters } = yield select((state: AppState) => {
      const {
        cmBalancing: {
          cashPoints: { sortColumn, sortOrder, filter },
        },
        cmCommon: { globalFilter },
      } = state
      return {
        sortColumn,
        sortOrder,
        filter,
        globalFilter,
      }
    })
    const { globalFilter, filter } = filters

    const response = yield call(
      BalancingApi.getCashPoints,
      paging,
      { globalFilter, filter },
      sortColumn,
      sortOrder,
    )
    yield put(getBalCashPointsResponse(response))

    const { selectedCashPoint, checkedCashPoints } = yield select(getBalCashPoints)

    if (response) {
      if (selectedCashPoint) {
        const updatedSelectedCashPoint = response.data.find(
          (cashPoint: BalCashPoint) => cashPoint.id === selectedCashPoint.id,
        )

        if (updatedSelectedCashPoint) {
          yield put(setBalSelectedCashPoint(updatedSelectedCashPoint))
        }
      }

      if (Object.keys(checkedCashPoints).length) {
        const newCheckedCashPoints = { ...checkedCashPoints }

        Object.keys(checkedCashPoints).map(key => {
          const elementInResponse = response.data.find(
            (cashPoint: BalCashPoint) => cashPoint.id === checkedCashPoints[key].id,
          )
          if (elementInResponse) {
            newCheckedCashPoints[elementInResponse.id] = elementInResponse
          }
        })

        yield put(
          checkBalAllCashPoints(
            Object.keys(newCheckedCashPoints).map(key => newCheckedCashPoints[key]),
          ),
        )
      }
    }

    return response
  } catch (error) {
    yield put(getBalCashPointsResponseFail(error))
  }
}

function* handleCashPointsPagingChange({ payload }: AnyAction): SagaIterator {
  yield put(setBalCashPointsPagingResponse(payload))
  yield put(getBalCashPointsRequest())
}

function* setCashPointsFilter({ payload }: AnyAction): any {
  try {
    yield put(setGlobalFilter(payload.globalFilter))
    yield put(setBalCashPointsFilter(payload.balFilter))
  } catch (e) {}
}

function* handleCashPointsFilterSubmit(action: AnyAction): SagaIterator {
  yield setCashPointsFilter(action)
  yield put(resetBalCashPointsPage())
  yield put(setBalSelectedCashPoint(null))
  yield put(clearBalAllCashPoints())
  yield put(getBalCashPointsRequest())
}

function* getReports(): SagaIterator {
  try {
    const {
      cmBalancing: {
        cashPoints: { checkedCashPoints, selectedCashPoint, filter },
      },
      cmCommon: { globalFilter },
    } = yield select((state: AppState) => state)
    const pointsFilter = {
      ids: Object.keys(checkedCashPoints) || [],
      selectedId: selectedCashPoint?.id || '',
      globalFilter: globalFilter,
      filter: filter,
    }
    const response = yield call(BalancingApi.getReports, pointsFilter)
    yield put(getBalReportsResponse(response))
  } catch (e) {
    yield put(getBalReportsResponseFail(e?.message))
  }
}

function* getReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(CMCommonApi.getReport, payload)
      yield put(getBalReportResponse(response))
    } else {
      yield put(getBalReportResponse(null))
    }
  } catch (e) {
    yield put(getBalReportResponseFail(e?.message))
  }
}

function* getCashPointReports({ payload }: AnyAction): SagaIterator {
  try {
    const response = yield call(BalancingApi.getCashPointReports, payload)
    yield put(getBalCashPointReportsResponse(response))
  } catch (e) {
    yield put(getBalCashPointReportsResponseFail(e?.message))
  }
}

function* getCashPointReport({ payload }: AnyAction): SagaIterator {
  try {
    if (payload) {
      const response = yield call(CMCommonApi.getReport, payload)
      yield put(getBalCashPointReportResponse(response))
    } else {
      yield put(getBalCashPointReportResponse(null))
    }
  } catch (e) {
    yield put(getBalCashPointReportResponseFail(e?.message))
  }
}

function* assignCashier({ payload }: AnyAction): any {
  try {
    yield call(BalancingApi.assignCashier, payload)
    yield put(assignBalCashierResponse())
    yield put(clearBalAllCashPoints())
  } catch (error) {
    yield put(setComCMError(error))
  }
}

function* handleKeyPress({ payload }: AnyAction): SagaIterator {
  try {
    const {
      isFiltersModalOpen,
      isDocumentModalOpen,
      isSettingsModalOpen,
      selectedCashPoint: selected,
      data,
    } = yield select((state: AppState) => state.cmBalancing.cashPoints)
    const { isCashBalancingModalOpen } = yield select((state: AppState) => state.cmBalancing)
    const {
      isEditCassettesModalOpen,
      declineNoteState: { isModalOpen: isDeclineNoteModalOpen },
    } = yield select((state: AppState) => state.cmShared)

    hotKeysBinder<BalCashPoint>({
      key: payload,
      data,
      selected,
      isOpenModalsList: [
        isFiltersModalOpen,
        isDocumentModalOpen,
        isSettingsModalOpen,
        isEditCassettesModalOpen,
        isDeclineNoteModalOpen,
        isCashBalancingModalOpen,
      ],
      setSelectedAction: setBalSelectedCashPoint,
      onToggleAction: toggleBalCashPoint,
      openFilterAction: setBalFiltersModalOpen,
      openPrintAction: setBalDocumentModalOpen,
      onRefreshAction: refreshBalancing,
      onEnterAction: setBalCashBalancingModalOpen,
    })
  } catch (error) {
    yield put(setComCMError(error))
  }
}

function* getBalancingInfo({ payload }: AnyAction): SagaIterator {
  try {
    const response = yield call(BalancingApi.getBalancingInfo, payload)

    yield put(getBalInfoResponse(response))
  } catch (error) {
    yield put(getBalInfoFail(error))
  }
}

function* getBalancingCassettesSaga({ payload }: AnyAction): SagaIterator {
  try {
    const unloadedCassettes = yield select(getBalSelectedUnloadedCassettes)
    const selectedCassette = yield select(getBalSelectedCassette)

    const response: BalGetBalancingCassettesResponse = yield call(
      BalancingApi.getBalancingCassettes,
      payload,
    )

    yield put(getBalCassettesResponse(response))
    if (unloadedCassettes.sessionId) {
      const cassette = response.unloadedCassettes?.cassetteData?.find(
        cassette => cassette.sessionId === unloadedCassettes.sessionId,
      )

      if (cassette) {
        const cassettesWithNewBalance = balanceCalculate({ unloadedCassette: cassette })

        yield put(setBalSelectedUnloadedCassettes(cassettesWithNewBalance))

        if (selectedCassette) {
          const foundedSelected = cassettesWithNewBalance.unloadedCassettes?.find(
            unloadedCassette => unloadedCassette.id === selectedCassette.id,
          )

          if (foundedSelected) {
            yield put(setBalUnloadedSelectedCassette(foundedSelected))
          }
        } else {
          yield put(setBalUnloadedSelectedCassette(null))
        }
      }
    }
  } catch (error) {
    yield put(getBalCassettesFail(error))
  }
}

function* applyBalUnloadedCassettes(): SagaIterator {
  try {
    const selectedUnloadedCassettes: BalUnloadedCassettesSession = yield select(
      getBalSelectedUnloadedCassettes,
    )

    const { selectedCashPoint } = yield select(getBalCashPoints)

    const requestBody = {
      sessionId: selectedCashPoint.sessionId,
      selectedSessionId: selectedUnloadedCassettes.sessionId,
      balancedCassettes: selectedUnloadedCassettes.unloadedCassettes?.map(
        ({ id, cashIn, deposit, dispensed, remaining, reject, retract }) => {
          return { cassetteId: id, cashIn, deposit, dispensed, remaining, reject, retract }
        },
      ),
    }

    yield call(BalancingApi.setUnloadedBalancing, requestBody)

    yield put(getBalInfoRequest(selectedCashPoint.id))
    yield put(getBalCassettesRequest(selectedCashPoint.id))
    yield put(applyBalUnloadedCassettesResponse())
    yield put(getBalCashPointsRequest())
  } catch (error) {
    yield put(applyBalUnloadedCassettesFail(error))
  }
}

function* applyBalNotLoadedCassettes(): SagaIterator {
  try {
    const selectedNotLoadedCassettes: BalNotLoadedCassettesCashOrder = yield select(
      getBalNotLoadedCassettes,
    )

    const requestBody = {
      cashOrderId: selectedNotLoadedCassettes.cashOrderId,
      cassettes: selectedNotLoadedCassettes.notLoadedCassetes?.map(({ id, cashBack }) => {
        return { cassetteId: id, cashBack }
      }),
    }

    yield call(BalancingApi.setNotLoadedBalancing, requestBody)

    const { selectedCashPoint } = yield select(getBalCashPoints)

    yield put(getBalInfoRequest(selectedCashPoint.id))
    yield put(getBalCassettesRequest(selectedCashPoint.id))
    yield put(applyBalNotLoadedCassettesResponse())
    yield put(getBalCashPointsRequest())
  } catch (error) {
    yield put(applyBalNotLoadedCassettesFail(error))
  }
}

function* calculateUnloadedTotals(): SagaIterator {
  try {
    const selectedCashPoint: BalCashPoint = yield select(getSelectedCashPoint)
    const selectedUnloadedCassettes: BalUnloadedCassettesSession = yield select(
      getBalSelectedUnloadedCassettes,
    )

    const requestBody = {
      entryId: selectedCashPoint?.id,
      sessionId: selectedUnloadedCassettes.sessionId,
      balancedCassettes: selectedUnloadedCassettes.unloadedCassettes?.map(
        ({ id, cashIn, deposit, dispensed, remaining, reject, retract }) => {
          return { cassetteId: id, cashIn, deposit, dispensed, remaining, reject, retract }
        },
      ),
    }

    const response = yield call(BalancingApi.calcUnloadedTotals, requestBody)

    if (response) {
      yield put(calcBalUnloadedTotalsResponse(response))
    }
  } catch (error) {
    yield put(calcBalUnloadedTotalsFail(error))
  }
}

function* calculateNotLoadedTotals(): SagaIterator {
  try {
    const selectedCashPoint: BalCashPoint = yield select(getSelectedCashPoint)
    const selectedNotLoadedCassettes: BalNotLoadedCassettesCashOrder = yield select(
      getBalNotLoadedCassettes,
    )

    const requestBody = {
      entryId: selectedCashPoint?.id,
      cashOrderId: selectedNotLoadedCassettes.cashOrderId,
      cassettes: selectedNotLoadedCassettes.notLoadedCassetes?.map(({ id, cashBack }) => {
        return { cassetteId: id, cashBack }
      }),
    }

    const response = yield call(BalancingApi.calcNotLoadedTotals, requestBody)

    if (response) {
      yield put(calcBalNotLoadedTotalsResponse(response))
    }
  } catch (error) {
    yield put(calcBalNotLoadedTotalsFail(error))
  }
}

function* modifyCassette({ payload }: AnyAction): SagaIterator {
  try {
    const { selectedCashPoint } = yield select(getBalCashPoints)

    if (payload?.sessionId && selectedCashPoint?.id) {
      yield call(BalancingApi.modifyCassette, payload)

      yield put(modifyBalCassettesResponse())
      yield put(getBalCassettesRequest(selectedCashPoint.id))
    }
  } catch (error) {
    yield put(modifyBalCassettesFail(error))
  }
}

function* removeCassette({ payload }: AnyAction): SagaIterator {
  try {
    const { selectedCashPoint } = yield select(getBalCashPoints)

    if (payload?.sessionId && selectedCashPoint?.id) {
      yield call(BalancingApi.removeCassette, payload)

      yield put(removeBalCassetteResponse())
      yield put(getBalCassettesRequest(selectedCashPoint.id))
    }
  } catch (error) {
    yield put(removeBalCassetteFail(error))
  }
}

export default function*(): Generator {
  yield takeLatest(
    [
      CMActions.BalGetCashPointsRequest,
      CMActions.BalSortTable,
      CMActions.BalRefreshCashPointsPanel,
      CMActions.BalAssignCashierResponse,
    ],
    getCashPoints,
  )
  yield takeLatest(CMActions.BalSetCashPointsPagingRequest, handleCashPointsPagingChange)
  yield takeLatest(CMActions.BalFilterSubmitRequest, handleCashPointsFilterSubmit)
  yield takeLatest(CMActions.BalGetReportsRequest, getReports)
  yield takeLatest(CMActions.BalGetReportRequest, getReport)
  yield takeLatest(CMActions.BalGetCashPointReportsRequest, getCashPointReports)
  yield takeLatest(CMActions.BalGetCashPointReportRequest, getCashPointReport)
  yield takeLatest(CMActions.BalAssignCashierRequest, assignCashier)

  yield takeLatest(CMActions.BalHandleKeyPress, handleKeyPress)

  yield takeLatest(CMActions.BalGetInfoRequest, getBalancingInfo)
  yield takeLatest(CMActions.BalGetCassettesRequest, getBalancingCassettesSaga)
  yield takeLatest(CMActions.BalApplyBalUnloadedCassettesRequest, applyBalUnloadedCassettes)
  yield takeLatest(CMActions.BalApplyBalNotLoadedCassettesRequest, applyBalNotLoadedCassettes)

  yield takeLatest(CMActions.BalSetSelectedUnloadedCassettes, calculateUnloadedTotals)
  yield takeLatest(CMActions.BalSetSelectedNotLoadedCassettes, calculateNotLoadedTotals)

  yield takeLatest(CMActions.BalModifyCassettesRequest, modifyCassette)
  yield takeLatest(CMActions.BalRemoveCassetteRequest, removeCassette)
}
