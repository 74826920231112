import { debounce, isEqual } from 'lodash-es'
import { ChangeEvent, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { TicketsApi } from '@/api/sd/ticketsApi'
import { useScroll } from '@/utils/hooks/useScroll'

import { DefaultValue, Option } from '../types'

const sortAlphabeticallyOptions = ({ options }: { options: Array<Option> }): Array<Option> => {
  if (options.length === 0) {
    return options
  }

  return options.sort((a: Option, b: Option) =>
    a.valueLabels.join('').localeCompare(b.valueLabels.join('')),
  )
}

export const useMultiSelect = ({
  fieldName,
  dropdownListEntityType,
  defaultValues,
  selectedValues,
  dependsOnIds,
  setFieldValue,
  onApply,
}: {
  dependsOnIds?: string[]
  fieldName?: string
  dropdownListEntityType?: string
  defaultValues: Array<DefaultValue>

  selectedValues: Array<string>

  setFieldValue?: (fieldName: string, selectedIds: Array<string>) => void
  onApply?: (selectedIds: Array<string>, options: Array<Option>) => void
}) => {
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isDisplaySelected, setIsDisplaySelected] = useState(false)

  const inputLabel = useRef() as RefObject<HTMLLabelElement>
  const [labelWidth, setLabelWidth] = useState(0)

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined)
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string | undefined>(undefined)
  const debouncedSearch = useCallback(debounce(setDebouncedSearchValue, 500), [
    setDebouncedSearchValue,
  ])

  const [totalPages, setTotalPages] = useState(1)

  const [options, setOptions] = useState<Option[]>(defaultValues)
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([])

  const [selectedIds, setSelectedIds] = useState<Array<string>>(selectedValues as string[])

  useEffect(() => {
    !options.length && setOptions(defaultValues)
  }, [defaultValues])

  useEffect(() => {
    if (Array.isArray(selectedValues) && !isEqual(selectedValues, selectedIds)) {
      setSelectedIds(selectedValues)
    }
  }, [selectedValues])

  const handleUpdateOptions = useCallback((newValues: Array<Option>, clearOptions: boolean) => {
    setOptions(options => {
      const newOptions = clearOptions
        ? newValues
        : [
            ...options,
            ...newValues.filter(
              (item: Option) => !options.find(option => option.valueId === item.valueId),
            ),
          ]

      const sortedOptions = sortAlphabeticallyOptions({
        options: newOptions,
      })

      return sortedOptions
    })
  }, [])

  const loadData = useCallback(
    async ({ searchFragment = '', pageNumber = 1, pageSize = 100, selectedIds = [] }) => {
      setIsDataLoading(true)

      const response = await TicketsApi.getDropdownListsData([
        {
          entityType: dropdownListEntityType,
          dependsOnIds,
          pageNumber,
          pageSize,
          searchFragment,
          selectedIds,
        },
      ])

      const [multiSelectData] = response

      const newOptions = multiSelectData.dropdownListElements
      handleUpdateOptions(newOptions, !!dependsOnIds?.length && pageNumber === 1)

      if (!searchFragment) {
        const totalPages = multiSelectData.totalPagesCount
        setTotalPages(totalPages)
      }

      setIsDataLoading(false)
    },
    [dropdownListEntityType, dependsOnIds, handleUpdateOptions],
  )

  const handleClearSearchValue = useCallback(() => {
    setSearchValue(undefined)
  }, [])

  const handleOpen = useCallback((): void => {
    setIsOpen(true)

    loadData({})
  }, [loadData])

  const handleClose = useCallback(
    (event): void => {
      event.stopPropagation()

      if (Array.isArray(selectedValues)) {
        setSelectedIds(selectedValues)
      }

      handleClearSearchValue()
      setIsOpen(false)
    },
    [handleClearSearchValue, selectedValues],
  )

  const handleApply = useCallback(
    (event): void => {
      event.stopPropagation()

      if (Array.isArray(selectedIds)) {
        if (onApply) {
          onApply(selectedIds, options)
        } else if (setFieldValue && fieldName) {
          setFieldValue(fieldName, selectedIds)
        }

        handleClearSearchValue()
        setIsOpen(false)
      }
    },
    [handleClearSearchValue, fieldName, selectedIds, setFieldValue],
  )

  const [parentRef, setParentRef] = useState(null)
  const [childRef, setChildRef] = useState(null)

  const handleScroll = useCallback((page, limit) => {
    loadData({ value: debouncedSearchValue, pageSize: limit, pageNumber: page })
  }, [])

  useScroll({
    parentRef: parentRef,
    childRef: childRef,
    loading: isDataLoading,
    listLength: filteredOptions.length,
    limitSize: 100,
    pageLimit: totalPages,
    callback: handleScroll,
  })

  const handleSetParentRef = useCallback(element => {
    setParentRef(element)
  }, [])

  const handleSetChildRef = useCallback(element => {
    setChildRef(element)
  }, [])

  useEffect(() => {
    if (inputLabel && inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth)
    }
  }, [inputLabel])

  useEffect(() => {
    debouncedSearch(searchValue)
  }, [debouncedSearch, searchValue])

  useEffect(() => {
    if (debouncedSearchValue !== undefined && debouncedSearchValue !== '') {
      loadData({ searchFragment: debouncedSearchValue })
    }
  }, [debouncedSearchValue, loadData])

  useEffect(() => {
    const newOptions = searchValue
      ? options.filter(option => {
          const optionLabels = option.valueLabels.join('').toLowerCase()

          return optionLabels.includes(searchValue.toLowerCase())
        })
      : options
    const filteredOptions = isDisplaySelected
      ? newOptions.filter(option => selectedIds.includes(option.valueId))
      : newOptions

    setFilteredOptions(filteredOptions)
  }, [isDisplaySelected, options, searchValue, selectedIds])

  useEffect(() => {
    const selectedFilteredOptions = filteredOptions.filter(option =>
      selectedIds.includes(option.valueId),
    )

    if (selectedFilteredOptions.length === 0 && isDisplaySelected) {
      setIsDisplaySelected(false)
    }
  }, [filteredOptions, isDisplaySelected, selectedIds, selectedIds.length])

  const isShowDisplaySelectedCheckbox = useMemo(() => selectedIds.length > 0, [selectedIds])
  const isAllItemsSelected = useMemo(
    () =>
      selectedIds.length !== 0 &&
      filteredOptions.length !== 0 &&
      filteredOptions.every(option => selectedIds.includes(option.valueId)),
    [filteredOptions, selectedIds],
  )

  const handleClickMenuItem = useCallback(
    (valueId: string): (() => void) => (): void => {
      setSelectedIds(selectedIds =>
        selectedIds.includes(valueId)
          ? selectedIds.filter(val => val !== valueId)
          : [...selectedIds, valueId],
      )
    },
    [],
  )

  const handleSearch = useCallback((event: ChangeEvent<{ value: any }>) => {
    return setSearchValue(event.target.value)
  }, [])

  const handleSelectAll = useCallback(() => {
    setSelectedIds(prevSelectedIds => {
      if (isAllItemsSelected) {
        return prevSelectedIds.filter(id => !filteredOptions.find(option => option.valueId === id))
      } else {
        const newSelectedIds = filteredOptions.reduce((acc, cur) => {
          const isSelectedId = prevSelectedIds.includes(cur.valueId)

          if (!isSelectedId) {
            acc.push(cur.valueId)
          }

          return acc
        }, [] as Array<string>)

        return [...prevSelectedIds, ...newSelectedIds]
      }
    })
  }, [filteredOptions, isAllItemsSelected])

  const handleDisplaySelected = useCallback(() => {
    setFilteredOptions(filteredOptions => {
      if (isDisplaySelected) {
        return searchValue
          ? options.filter(option => {
              const optionLabels = option.valueLabels.join('').toLowerCase()

              return optionLabels.includes(searchValue.toLowerCase())
            })
          : options
      }

      return filteredOptions.filter(option => selectedIds.includes(option.valueId))
    })

    setIsDisplaySelected(state => !state)
  }, [isDisplaySelected, options, searchValue, selectedIds])

  return {
    isDataLoading,
    isOpen,
    isDisplaySelected,
    isShowDisplaySelectedCheckbox,
    isAllItemsSelected,

    labelWidth,
    inputLabel,

    searchValue,

    options,
    filteredOptions,
    selectedIds,

    handleOpen,
    handleClose,
    handleApply,
    handleSetParentRef,
    handleSetChildRef,
    handleClickMenuItem,
    handleSearch,
    handleSelectAll,
    handleDisplaySelected,
  } as const
}
