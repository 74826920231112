import React, { ReactElement, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FormikValues } from 'formik'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CloseButton from '@/components/blocks/CloseButton'
import SuccessDialog from '@/components/blocks/SuccessDialog'
import ProductInformation from './components/ProductInformation'

import { addNewProductRequest, setClearErrors } from '@/store/products/actions'
import { getIsFetchingAddedProduct, getError, getCurrentProduct } from '@/store/products/selectors'
import { AppNavigationRoutes, PRODUCTS_BLOCK_PREFIX } from '@/constants/paths'

import { useTranslate } from '@/utils/internalization'
import { useClasses } from './styles'
import { Props } from './types'
import { Product } from '@/types/products/products'
import { DATE_FORMAT } from '@/constants/time'
import moment from 'moment'
import { Container } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const AddNewProduct = ({ open, onClose }: Props): ReactElement => {
  const isLoadingAddedProduct = useSelector(getIsFetchingAddedProduct)
  const currentProduct: any = useSelector(getCurrentProduct)
  const error = useSelector(getError)

  const [success, setSuccess] = useState<boolean>(false)

  const classes = useClasses()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const history = useHistory()

  const handleSubmit = useCallback(
    (values: FormikValues) => {
      const formattedDate = moment(new Date(values.actualTillDate)).format(DATE_FORMAT)
      dispatch(
        addNewProductRequest({
          actualTillDate: values.actualTillDate !== null ? formattedDate : values.actualTillDate,
          complexityLevelId: parseInt(values.complexityLevelId),
          functionality: values.functionality,
          height: values.height,
          isRepairable: values.isRepairable,
          length: values.length,
          listPrice: values.listPrice,
          nettoWeightKg: values.nettoWeightKg,
          productAccountingGroupId: values.productAccountingGroupId,
          productCategoryId: values.productCategoryId,
          productCode: values.productCode,
          productGroupId: values.productGroupId,
          productId: values.productId,
          productName: values.productName,
          productSparePartGroupId: values.productSparePartGroupId,
          quantityKeyId: values.quantityKeyId,
          statWnr: values.statWnr,
          vendorArticleNo: values.vendorArticleNo,
          vendorCompanyId: values.vendorCompanyId,
          vendorCountryId: values.vendorCountryId,
          vendorRepairPrice: values.vendorRepairPrice,
          width: values.width,
        }),
      )
      setSuccess(true)
    },
    [dispatch],
  )

  const handleClose = useCallback(() => {
    onClose()
    dispatch(setClearErrors())
  }, [onClose])

  const handleRedirect = () => {
    history.push(
      `${PRODUCTS_BLOCK_PREFIX}${AppNavigationRoutes.ProductPage}/${currentProduct?.productId}`,
    )
    onClose()
  }

  return (
    <>
      <Dialog
        open={open}
        hidden={success && !isLoadingAddedProduct && !error}
        classes={{ paper: classes.dialog }}
      >
        <CloseButton onClick={handleClose} absolute />
        <Grid className={classes.formWrapper}>
          <Container className={classes.container}>
            <Typography variant="h5">
              <FormattedMessage id="prod.newProductCard" defaultMessage="New product card" />
            </Typography>
            <ProductInformation
              onSubmit={handleSubmit}
              onCancel={handleClose}
              isLoading={isLoadingAddedProduct}
              defaultValues={{}}
            />
          </Container>
        </Grid>
      </Dialog>
      <SuccessDialog
        title={translate('translate#prod.productHasBeenSuccessfullyCreated')}
        open={success && !isLoadingAddedProduct && !error}
        onClose={handleRedirect}
      />
    </>
  )
}

export default AddNewProduct
