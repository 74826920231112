/* eslint-disable @typescript-eslint/camelcase */
import moment from 'moment'

import {
  BS2CashInStatus,
  BS2CashOrderStatus,
  BS2CashPlanStatus,
  BS2CashStatus,
  BS2CassetteStatus,
  BS2ParameterStatus,
  BS2ReplenishmentStatus,
  BS2ReplenishmentType,
  BS2ServiceState,
  BS2SessionStage,
  ePoliceOrderStatus,
} from '@/api/cm/swaggerGeneratedApi'
import { Options, StatusesProps, StatusPlates } from '@/types/cm'

export const zeroTime = moment('00:00', 'hh:mm')

export const rowsPerPageOptions = [10, 20, 50, 100, 600, { label: 'All', value: -1 }]

export const DAYS_TIME_SPAN = 14

export const VISIBLE_ENABLED = 'visible_enabled'
export const VISIBLE_NOT_ENABLED = 'visible_not_enabled'
export const NOT_VISIBLE = 'not_visible'

export const GREEN_STATUS = { bkgColor: '#50FF00', color: '#50FF00' }
export const YELLOW_STATUS = { bkgColor: '#E6E600', color: '#E6E600' }
export const RED_STATUS = { bkgColor: '#FF0000', color: '#FF0000' }

export const statusColors = {
  green: { background: '#50FF00', color: '#50FF00' },
  yellow: { background: '#E6E600', color: '#E6E600' },
  red: { background: '#FF0000', color: '#FF0000' },
  blue: { background: '#5050C8', color: '#5050C8' },
  lightBlue: { background: '#416FA6', color: '#416FA6' },
  gray: { background: '#898989', color: '#898989' },
  lightPurple: { background: '#842EDA', color: '#842EDA' },
  purple: { background: '#9573bd', color: '#9573bd' },
  white: { background: '#fff', color: '#fff' },
  orange: { background: '#FF9737', color: '#FF9737' },
  sand: { background: '#D6AB6B', color: '#D6AB6B' },
  lightRed: { background: 'rgb(251 222 222)', color: 'rgb(251 222 222)' },
}

export const SENT_TO_EXECUTE = 'SentToExecute'

export const KIOSK = 'Kiosk'

export const HotKeysAction = {
  PREV_ROW: 'PREV_ROW',
  NEXT_ROW: 'NEXT_ROW',
  TOGGLE_CHECKBOX: 'TOGGLE_CHECKBOX',
  OPEN_FILTER: 'OPEN_FILTER',
  OPEN_PRINT_DIALOG: 'OPEN_PRINT_DIALOG',
  REFRESH: 'REFRESH',
  SELECT_ROW: 'SELECT_ROW',
  MODIFY: 'MODIFY',
}

export const REPLENISHMENT_STATUSES: StatusPlates<BS2ReplenishmentStatus> = {
  ClosedByUser: {
    value: 'ClosedByUser',
    translateId: 'cm.ClosedByUser',
    ...statusColors.yellow,
  },
  Waiting: {
    value: 'Waiting',
    translateId: 'cm.Waiting',
    ...statusColors.blue,
  },
  PerformedNormally: {
    value: 'PerformedNormally',
    translateId: 'cm.PerformedNormally',
    ...statusColors.blue,
  },
  IncorrectReplenishment: {
    value: 'IncorrectReplenishment',
    translateId: 'cm.IncorrectReplenishment',
    ...statusColors.red,
  },
  DoesNotMatchCashOrder: {
    value: 'DoesNotMatchCashOrder',
    translateId: 'cm.DoesNotMatchCashOrder',
    ...statusColors.red,
  },
  None: {
    value: 'None',
    translateId: 'cm.None',
    ...statusColors.gray,
  },
}

export const PARAMETER_STATUSES: StatusPlates<BS2ParameterStatus> = {
  None: {
    value: 'None',
    translateId: 'cm.None',
    ...statusColors.gray,
  },
  Calculated: {
    value: 'Calculated',
    ...statusColors.blue,
    translateId: 'cm.Calculated',
  },
  Modified: {
    value: 'Modified',
    translateId: 'cm.Modified',
    ...statusColors.yellow,
  },
  Optimized: {
    value: 'Optimized',
    translateId: 'cm.Optimized',
    ...statusColors.green,
  },
  NotOptimized: {
    value: 'NotOptimized',
    translateId: 'cm.NotOptimized',
    ...statusColors.red,
  },
  Truncated: {
    value: 'Truncated',
    translateId: 'cm.Truncated',
    ...statusColors.red,
  },
}

export const REPLENISHMENT_TYPES: Options<BS2ReplenishmentType> = {
  None: {
    value: 'Undefined',
    translateId: 'cm.planType.Undefined',
  },
  Plan: {
    value: 'Plan',
    translateId: 'cm.planType.Plan',
  },
  Extra: {
    value: 'Extra',
    translateId: 'cm.planType.Extra',
  },
  Loading: {
    value: 'Loading',
    translateId: 'cm.planType.Loading',
  },
  Unloading: {
    value: 'Unloading',
    translateId: 'cm.planType.Unloading',
  },
}

export const PLANNING_STATUSES: StatusPlates<BS2CashPlanStatus> = {
  Declined: {
    value: 'Declined',
    translateId: 'cm.Declined',
    ...statusColors.red,
  },
  SentToExecute: {
    value: 'SentToExecute',
    translateId: 'cm.SentToExecute',
    ...statusColors.green,
  },
  Accepted: {
    value: 'Accepted',
    translateId: 'cm.Accepted',
    ...statusColors.yellow,
  },
  Calculated: {
    value: 'Calculated',
    translateId: 'cm.Calculated',
    ...statusColors.blue,
  },
  Modified: {
    value: 'Modified',
    translateId: 'cm.Modified',
    ...statusColors.blue,
  },
  None: {
    value: 'None',
    translateId: 'cm.None',
    ...statusColors.gray,
  },
  Undefined: {
    value: 'Undefined',
    translateId: 'cm.Undefined',
    ...statusColors.gray,
  },
  Prepared: {
    value: 'Prepared',
    translateId: 'cm.Prepared',
    ...statusColors.gray,
  },
  Executed: {
    value: 'Executed',
    translateId: 'cm.Executed',
    ...statusColors.gray,
  },
}

export const ORDER_STATUSES: StatusPlates<BS2CashOrderStatus> = {
  None: { value: 'None', translateId: 'cm.None', ...statusColors.gray },
  New: { value: 'New', translateId: 'cm.New', ...statusColors.blue },
  Approved: {
    value: 'Approved',
    translateId: 'cm.Approved',
    ...statusColors.yellow,
  },
  CashDelivery: {
    value: 'CashDelivery',
    translateId: 'cm.CashDelivery',
    ...statusColors.purple,
  },
  Declined: {
    value: 'Declined',
    translateId: 'cm.Declined',
    ...statusColors.red,
  },
  SentToExecute: {
    value: 'SentToExecute',
    translateId: 'cm.SentToExecute',
    ...statusColors.green,
  },
  Closed: {
    value: 'Closed',
    translateId: 'cm.Closed',
    ...statusColors.red,
  },
  DeclinedGroup: {
    value: 'DeclinedGroup',
    translateId: 'cm.GroupDeclined',
    ...statusColors.red,
  },
  NotCompleted: {
    value: 'NotCompleted',
    translateId: 'cm.NotCompleted',
    ...statusColors.gray,
  },
  Closed_NotCompleted: {
    value: 'Closed_NotCompleted',
    translateId: 'cm.ClosedUncompleted',
    ...statusColors.red,
  },
  NotCompleted_Unloaded: {
    value: 'NotCompleted_Unloaded',
    translateId: 'cm.NotCompletedUnloaded',
    ...statusColors.gray,
  },
  Closed_NotCompleted_Unloaded: {
    value: 'Closed_NotCompleted_Unloaded',
    translateId: 'cm.ClosedNotCompletedUnloaded',
    ...statusColors.red,
  },
  NoAgentData: {
    value: 'NoAgentData',
    ...statusColors.blue,
    translateId: 'cm.NoAgentData',
  },
  Closed_NoAgentData: {
    value: 'Closed_NoAgentData',
    translateId: 'cm.ClosedNoAgentData',
    ...statusColors.red,
  },
  Balanced: {
    value: 'Balanced',
    ...statusColors.green,
    translateId: 'cm.Balanced',
  },
  NotBalanced: {
    value: 'NotBalanced',
    translateId: 'cm.NotBalanced',
    ...statusColors.red,
  },
}

export const SESSION_STAGES: StatusPlates<BS2SessionStage> = {
  CashOrderGeneration: {
    value: 'CashOrderGeneration',
    translateId: 'cm.StageCashOrderGeneration',
    ...statusColors.red,
  },
  CashOrderAcceptance: {
    value: 'CashOrderAcceptance',
    translateId: 'cm.StageCashOrderAcceptance',
    ...statusColors.green,
  },
  CassettesPreparation: {
    value: 'CassettesPreparation',
    ...statusColors.yellow,
    translateId: 'cm.StageCassettesPreparation',
  },
  CashDelivery: {
    value: 'CashDelivery',
    ...statusColors.yellow,
    translateId: 'cm.StageCashDelivery',
  },
  Replenishment: {
    value: 'Replenishment',
    translateId: 'cm.StageReplenishment',
    ...statusColors.green,
  },
  CassettesExtraction: {
    value: 'CassettesExtraction',
    translateId: 'cm.StageCassettesExtraction',
    ...statusColors.gray,
  },
  CashAcceptanceInVault: {
    value: 'CashAcceptanceInVault',
    translateId: 'cm.StageCashAcceptanceInVault',
    ...statusColors.green,
  },
  None: { value: 'None', ...statusColors.gray, translateId: 'cm.None' },
}

export const UNTIL_EMPTYING: StatusesProps = {
  Normal: { value: 'Normal', ...statusColors.green },
  Critical: { value: 'Critical', ...statusColors.red },
  Warning: { value: 'Warning', ...statusColors.yellow },
  Neutral: { value: 'Neutral', ...statusColors.gray },
}

export const CASH_IN_STATUSES: StatusPlates<BS2CashInStatus> = {
  Normal: {
    value: 'Normal',
    translateId: 'cm.NormalStatus',
    ...statusColors.green,
  },
  UpperLimit: {
    value: 'UpperLimit',
    translateId: 'cm.UpperLimit',
    ...statusColors.blue,
  },
  None: { value: 'None', translateId: 'cm.None', ...statusColors.gray },
  Undefined: {
    value: 'Undefined',
    translateId: 'cm.Undefined',
    ...statusColors.gray,
  },
}

export const CASH_STATUSES: StatusPlates<BS2CashStatus> = {
  Normal: { value: 'Normal', translateId: 'cm.Normal', ...statusColors.green },
  SpecialPeriod: {
    value: 'SpecialPeriod',
    translateId: 'cm.SpecialPeriod',
    ...statusColors.red,
  },
  LowLimit: {
    value: 'LowLimit',
    translateId: 'cm.LowLimit',
    ...statusColors.yellow,
  },
  UpperLimit: {
    value: 'UpperLimit',
    translateId: 'cm.UpperLimit',
    ...statusColors.blue,
  },
  None: { value: 'None', translateId: 'cm.None', ...statusColors.gray },
  Undefined: {
    value: 'Undefined',
    color: '#898989',
    background: '#898989',
    translateId: 'cm.Undefined',
  },
  NoMoney: { value: 'NoMoney', translateId: 'cm.NoMoney', ...statusColors.gray },
  Unloaded: {
    value: 'Unloaded',
    translateId: 'cm.Unloaded',
    ...statusColors.blue,
  },

  MaxReplenishmentPeriod: {
    value: 'MaxReplenishmentPeriod',
    ...statusColors.blue,
  },
}

export const SERVICE_STATUSES: StatusPlates<BS2ServiceState> = {
  None: { value: 'None', translateId: 'cm.None', ...statusColors.gray },
  InService: {
    value: 'InService',
    translateId: 'cm.InService',
    ...statusColors.green,
  },
  Problematic: {
    value: 'Problematic',
    translateId: 'cm.Problematic',
    ...statusColors.yellow,
  },
  NoData: { value: 'NoData', translateId: 'cm.NoData', ...statusColors.gray },

  ServiceMode: {
    value: 'ServiceMode',
    translateId: 'cm.ServiceMode',
    ...statusColors.yellow,
  },
  OutOfService: {
    value: 'OutOfService',
    translateId: 'cm.OutOfService',
    ...statusColors.yellow,
  },
}

export const CASSETTES_STATES_STATUSES: StatusPlates<BS2CassetteStatus> = {
  OK: { value: 'OK', translateId: 'cm.CassetteStatusOK', ...statusColors.green },
  Full: {
    value: 'Full',
    translateId: 'cm.CassetteStatusFull',
    ...statusColors.lightBlue,
  },
  High: {
    value: 'High',
    translateId: 'cm.CassetteStatusHigh',
    ...statusColors.blue,
  },
  Low: {
    value: 'Low',
    translateId: 'cm.CassetteStatusLow',
    ...statusColors.orange,
  },
  Empty: {
    value: 'Empty',
    translateId: 'cm.CassetteStatusEmpty',
    ...statusColors.orange,
  },
  Missing: {
    value: 'Missing',
    translateId: 'cm.CassetteStatusMissing',
    ...statusColors.lightPurple,
  },
  Error: {
    value: 'Error',
    translateId: 'cm.CassetteStatusError',
    ...statusColors.red,
  },
  NotActive: {
    value: 'NotActive',
    translateId: 'cm.CassetteStatusNotActive',
    ...statusColors.red,
  },
  NoRef: {
    value: 'NoRef',
    ...statusColors.sand,
    translateId: 'cm.CassetteStatusNoRef',
  },
}

export const POLICE_STATUSES: StatusPlates<ePoliceOrderStatus> = {
  sent: {
    value: 'sent',
    translateId: 'cm.PoliceOpStatusSent',
    ...statusColors.green,
  },
  not_sent: {
    value: 'not_sent',
    translateId: 'cm.PoliceOpStatusNotSent',
    ...statusColors.white,
  },
  error: {
    value: 'error',
    translateId: 'cm.PoliceOpStatusError',
    ...statusColors.red,
  },
}
