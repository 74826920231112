export enum SearchServiceRequestActions {
  SetSearchServiceRequestsData = '@/SET_SEARCH_SERVICE_REQUEST_DATA',
  SetSearchServiceRequestClosed = '@/SET_SEARCH_SERVICE_REQUEST_CLOSED',
  SetSearchServiceWorksData = '@/SET_SEARCH_SERVICE_WORKS_DATA',
  SetSearchServiceWorksClosed = '@/SET_SEARCH_SERVICE_WORKS_CLOSED',
  SetSearchServiceDeviceData = '@/SET_SEARCH_SERVICE_DEVICE_DATA',
  SetSearchServiceDeviceClosed = '@/SET_SEARCH_SERVICE_DEVICE_CLOSED',
  SetSearchPlannedWorksData = '@/SET_SEARCH_PLANNED_WORKS_DATA',
  SetSearchPlannedWorksClosed = '@/SET_SEARCH_PLANNED_WORKS_CLOSED',
  SetSearchConfigurationData = '@/SET_SEARCH_CONFIGURATION_DATA',
  SetSearchConfigurationClosed = '@/SET_SEARCH_CONFIGURATION_CLOSED',
  SetDeviceDefectsStatisticsData = '@/SET_DEVICE_DEFECTS_STATISTICS_DATA',
  SetDeviceDefectsStatisticsClosed = '@/SET_DEVICE_DEFECTS_STATISTICS_CLOSED',
  SetServiceWorksStatisticsData = '@/SET_SERVICE_WORKS_STATISTICS_DATA',
  SetServiceWorksStatisticsClosed = '@/SET_SERVICE_WORKS_STATISTICS_CLOSED',
}
