import queryString from 'query-string'

import {
  AdmWorkingHoursBase,
  AdmWorkingHoursResponse,
  ComAdminBranchAndObjectFilter,
} from '@/api/cm/swaggerGeneratedApi'
import { getRequestServiceCM } from '@/utils/services/requestServiceCM'

export class WorkingHoursApi {
  private static requestService = getRequestServiceCM()

  public static async get(filter: ComAdminBranchAndObjectFilter): Promise<AdmWorkingHoursResponse> {
    try {
      return await WorkingHoursApi.requestService.post('/admin/workingHours', filter)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async update(workingHours: AdmWorkingHoursBase[], guid: string): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/workingHours/update',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )

      return await WorkingHoursApi.requestService.put(url, workingHours)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async getCollectionDays(
    filter: ComAdminBranchAndObjectFilter,
  ): Promise<AdmWorkingHoursBase> {
    try {
      return await WorkingHoursApi.requestService.post('/admin/workingHoursCollectionDays', filter)
    } catch (error) {
      throw new Error(error)
    }
  }

  public static async updateCollectionDays(
    specialDays: AdmWorkingHoursBase[],
    guid: string,
  ): Promise<void> {
    try {
      const url = queryString.stringifyUrl(
        {
          url: '/admin/workingHoursCollectionDays/update',
          query: { guid },
        },
        { skipNull: true, encode: false },
      )
      return await WorkingHoursApi.requestService.put(url, specialDays)
    } catch (error) {
      throw new Error(error)
    }
  }
}
