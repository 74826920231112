import { AnyAction } from 'redux'

import {
  AdmPlnTeamGroup,
  ComCMReport,
  PlasticCard,
  PlasticCardFilter,
  PlasticCardOrder,
  PlasticCardRequest,
  PlasticCardResponse,
  PlasticCardsWorkOrder,
  PlasticCardsWorkOrdersRequest,
} from '@/api/cm/swaggerGeneratedApi'
import { CMActions } from '@/constants/actions'
import { KeyPressActionName, Paging, Sort } from '@/types/cm'

export const getPlasticCardsRequest = (): AnyAction => ({
  type: CMActions.PlcGetPlasticCardsRequest,
})
export const getPlasticCardsResponse = (payload: PlasticCardResponse): AnyAction => ({
  type: CMActions.PlcGetPlasticCardsResponse,
  payload,
})
export const getPlasticCardsFail = (error?: string): AnyAction => ({
  type: CMActions.PlcGetPlasticCardsFail,
  payload: error,
})

export const getPlasticCardRequest = (id?: string): AnyAction => ({
  type: CMActions.PlcGetSinglePlasticCardRequest,
  payload: id,
})
export const getPlasticCardResponse = (payload: PlasticCard): AnyAction => ({
  type: CMActions.PlcGetSinglePlasticCardResponse,
  payload,
})
export const getPlasticCardFail = (error?: string): AnyAction => ({
  type: CMActions.PlcGetSinglePlasticCardFail,
  payload: error,
})

// Filter
export const setPlasticCardFilter = (payload: PlasticCardFilter): AnyAction => ({
  type: CMActions.PlcSetPlasticCardFilter,
  payload,
})
export const submitPlcFilter = (payload: PlasticCardRequest): AnyAction => ({
  type: CMActions.PlcSubmitPlasticCardFilter,
  payload,
})

// Paging
export const setPlasticCardPaging = (payload: Paging): AnyAction => ({
  type: CMActions.PlcSetPlasticCardPaging,
  payload,
})
export const resetPlcOrdersPaging = (): AnyAction => ({
  type: CMActions.PlcResetPaging,
})

export const setSelectedPlasticCard = (payload: PlasticCard | null): AnyAction => ({
  type: CMActions.PlcSetSelectedPlasticCard,
  payload,
})

export const sortPlasticCard = (payload: Sort): AnyAction => ({
  type: CMActions.PlcSetPlasticCardSort,
  payload,
})

// Checked
export const checkPlasticCard = (payload: PlasticCard): AnyAction => ({
  type: CMActions.PlcCheckPlasticCard,
  payload,
})
export const checkAllPlasticCard = (): AnyAction => ({
  type: CMActions.PlcCheckAllPlasticCard,
})
export const clearAllPlasticCard = (): AnyAction => ({
  type: CMActions.PlcClearAllPlasticCard,
})

export const handleKeyPress = (actionName: KeyPressActionName): AnyAction => ({
  type: CMActions.PlcHandleKeyPress,
  payload: actionName,
})
// Modals
export const openPlcColumnsSettingsModal = (isOpen: boolean): AnyAction => ({
  type: CMActions.PlcOpenSettingsModal,
  payload: isOpen,
})
export const openPlcFilterModal = (isOpen: boolean): AnyAction => ({
  type: CMActions.PlcOpenFilterModal,
  payload: isOpen,
})

// Teams
export const setPlcTeamRequest = (cpId: string, teamId: string): AnyAction => ({
  type: CMActions.PlcSetTeamRequest,
  payload: { cpId, teamId },
})
export const setPlcTeamFail = (): AnyAction => ({
  type: CMActions.PlcSetTeamFail,
})

export const resetPlcTeamRequest = (cpId: string, cardOrderId: string): AnyAction => ({
  type: CMActions.PlcResetTeamRequest,
  payload: { cpId, cardOrderId },
})
export const resetPlcTeamFail = (): AnyAction => ({
  type: CMActions.PlcResetTeamFail,
})

// WorKOrders
export const getPlcWorkOrdersRequest = (): AnyAction => ({
  type: CMActions.PlcGetWorkOrdersRequest,
})
export const getPlcWorkOrdersResponse = (payload: PlasticCardsWorkOrder[]): AnyAction => ({
  type: CMActions.PlcGetWorkOrdersResponse,
  payload,
})
export const getPlcWorkOrdersFail = (error?: string): AnyAction => ({
  type: CMActions.PlcGetWorkOrdersFail,
  payload: error,
})

export const createPlcWorkOrderRequest = (): AnyAction => ({
  type: CMActions.PlcCreateWorkOrderRequest,
})
export const createPlcWorkOrderResponse = (): AnyAction => ({
  type: CMActions.PlcCreateWorkOrderResponse,
})
export const createPlcWorkOrderFail = (): AnyAction => ({
  type: CMActions.PlcCreateWorkOrderFail,
})

export const declinePlcWorkOrderRequest = (reason?: string): AnyAction => ({
  type: CMActions.PlcDeclineWorkOrderRequest,
  payload: reason,
})
export const declinePlcWorkOrderResponse = (): AnyAction => ({
  type: CMActions.PlcDeclineWorkOrderResponse,
})
export const declinePlcWorkOrderFail = (): AnyAction => ({
  type: CMActions.PlcDeclineWorkOrderFail,
})

export const completePlcWorkOrderRequest = (isAll?: boolean): AnyAction => ({
  type: CMActions.PlcCompleteWorkOrderRequest,
  payload: isAll,
})
export const completePlcWorkOrderResponse = (): AnyAction => ({
  type: CMActions.PlcCompleteWorkOrderResponse,
})
export const completePlcWorkOrderFail = (): AnyAction => ({
  type: CMActions.PlcCompleteWorkOrderFail,
})

export const deletePlcWorkOrderRequest = (guid?: string): AnyAction => ({
  type: CMActions.PlcDeleteWorkOrderRequest,
  payload: guid,
})
export const deletePlcWorkOrderResponse = (): AnyAction => ({
  type: CMActions.PlcDeleteWorkOrderResponse,
})
export const deletePlcWorkOrderFail = (): AnyAction => ({
  type: CMActions.PlcDeleteWorkOrderFail,
})

export const setPlcWorkOrdersFilter = (filter: PlasticCardsWorkOrdersRequest): AnyAction => ({
  type: CMActions.PlcSetWorkOrdersFilter,
  payload: filter,
})

export const setPlcSelectedWorkOrder = (filter: PlasticCardOrder | null): AnyAction => ({
  type: CMActions.PlcSetSelectedWorkOrder,
  payload: filter,
})

export const sortPlcWorkOrders = (payload: Sort): AnyAction => ({
  type: CMActions.PlcSetWorkOrderSort,
  payload,
})

// Reports
export const getPlcWorkOrderReportsRequest = (): AnyAction => ({
  type: CMActions.GetPlcWorkOrderReportsRequest,
})
export const getPlcWorkOrderReportsResponse = (payload: ComCMReport[]): AnyAction => ({
  type: CMActions.GetPlcWorkOrderReportsResponse,
  payload,
})
export const getPlcWorkOrderReportsFail = (message?: string): AnyAction => ({
  type: CMActions.GetPlcWorkOrderReportsFail,
  payload: message,
})

export const getPlcWorkOrderReportRequest = (payload?: ComCMReport): AnyAction => ({
  type: CMActions.GetPlcWorkOrderReportRequest,
  payload,
})
export const getPlcWorkOrderReportResponse = (payload: string | null): AnyAction => ({
  type: CMActions.GetPlcWorkOrderReportResponse,
  payload,
})
export const getPlcWorkOrderReportFail = (message?: string): AnyAction => ({
  type: CMActions.GetPlcWorkOrderReportFail,
  payload: message,
})

// teams

export const getPlcTeamsRequest = (): AnyAction => ({
  type: CMActions.GetPlcTeamsRequest,
})
export const getPlcTeamsResponse = (payload: AdmPlnTeamGroup[]): AnyAction => ({
  type: CMActions.GetPlcTeamsResponse,
  payload,
})
