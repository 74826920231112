import makeStyles from '@material-ui/core/styles/makeStyles'
import { styledComponentTheme } from '@/theme'

export const useClasses = makeStyles(() => ({
  cell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-o-text-overflow': 'ellipsis',
  },
  cellPadding: {
    padding: '5px',
  },
  contentItem: {
    overflow: 'hidden',
  },
  contentItemText: {
    fontSize: styledComponentTheme.fontSizes.small,

    overflow: 'hidden',
    display: 'inline',
  },
  contentItemTextWithTooltip: {
    display: 'block',
  },
}))
