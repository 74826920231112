import {
  AddAttachedFile,
  AdmDeviceTypeResponse,
  AttachedFile,
  AttachedFilesObjectType,
  CitiesLookupsRequest,
  CityStateRegionResponse,
  CountriesLookupsRequest,
  Currency,
  LookupsCommonRequest,
  LookupsDepartmentsRequest,
  LookupsFiscalCashRegister,
  LookupsResponse,
  ProductResponse,
  ProductsVendorLookupsRequest,
  ProductVendorResponse,
  ReportIntervalsLookupResponse,
  StateRegionResponse,
  WorkShiftResponse,
} from '@/api/common/commonApi/types'
import { ResponseError } from '@/types'
import { Hardware } from '@/types/adm/device/api'
import { requestService } from '@/utils/services/request'

export class CommonApi {
  public static async getBaseCurrency(): Promise<Currency | ResponseError> {
    try {
      const response = await requestService.get(`/core-common/api/v1/currency/base`)

      return response
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getAttachedFiles(
    attachedFilesObjectType: AttachedFilesObjectType,
    entryId: number,
  ): Promise<AttachedFile[] | ResponseError> {
    try {
      const response = await requestService.get(
        `/core-common/api/v1/files/${attachedFilesObjectType}/${entryId}`,
      )

      return response
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getAttachedFileAsString(fileId: number): Promise<any | ResponseError> {
    try {
      return await requestService.get(`/core-common/api/v1/files/${fileId}`, {}, {})
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async addAttachedFile(attachedFile: AddAttachedFile): Promise<any | ResponseError> {
    try {
      return await requestService.post('/core-common/api/v1/files', attachedFile)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async removeAttachedFile(fileId: number): Promise<{} | ResponseError> {
    try {
      return await requestService.delete(`/core-common/api/v1/files/${fileId}`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupsValues(data: Partial<any>): Promise<LookupsResponse> {
    try {
      return requestService.get('/api/v1/lookups/lookups', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupsDepartments(
    data: Partial<LookupsDepartmentsRequest>,
  ): Promise<LookupsResponse> {
    try {
      return requestService.get('/api/v1/lookups/lookups/departments', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupsCity(
    data: Partial<CountriesLookupsRequest>,
  ): Promise<LookupsResponse> {
    try {
      return requestService.get('/api/v1/lookups/lookups/city', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupsCityStateRegion(
    data: Partial<CountriesLookupsRequest>,
  ): Promise<CityStateRegionResponse> {
    try {
      return requestService.get('/api/v1/lookups/lookups/city-state-region', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getAdmDeviceType(data: Partial<any>): Promise<AdmDeviceTypeResponse> {
    try {
      return requestService.get('/api/v1/lookups/lookups/adm/device/type', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getAdmCompanyByRoles(data: Partial<any>): Promise<LookupsResponse> {
    try {
      return requestService.get('/api/v1/lookups/lookups/roles/companies/adm', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupsProductsByQualification(
    data: Partial<ProductsVendorLookupsRequest>,
  ): Promise<ProductVendorResponse> {
    try {
      return requestService.get('/api/v1/lookups/lookups/complexity/products', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getHardwareFree(fragment: string): Promise<Array<Hardware>> {
    try {
      return requestService.get(`/adm/api/v1/hardware/not-installed/${fragment}`)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupsProducts(
    data: Partial<LookupsCommonRequest>,
  ): Promise<ProductResponse> {
    try {
      return requestService.get('/api/v1/lookups/lookups/products', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupsWorkShift(
    data: Partial<LookupsCommonRequest>,
  ): Promise<WorkShiftResponse> {
    try {
      return requestService.get('/api/v1/lookups/lookups/work-shift', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupsState(
    data: Partial<CountriesLookupsRequest>,
  ): Promise<LookupsResponse> {
    try {
      return requestService.get('/api/v1/lookups/lookups/state', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupsRegion(
    data: Partial<CountriesLookupsRequest>,
  ): Promise<StateRegionResponse> {
    try {
      return requestService.get('/api/v1/lookups/lookups/region', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupsStreet(
    data: Partial<CitiesLookupsRequest>,
  ): Promise<LookupsResponse> {
    try {
      return requestService.get('/api/v1/lookups/lookups/street', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupsCityRegion(
    data: Partial<CitiesLookupsRequest>,
  ): Promise<LookupsResponse> {
    try {
      return requestService.get('/api/v1/lookups/lookups/city/region', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupsReportIntervals(): Promise<ReportIntervalsLookupResponse[]> {
    try {
      return await requestService.get('/reports/api/v1/lookups/report-intervals')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupsCashRegisterPOSSN(
    data: Partial<LookupsFiscalCashRegister>,
  ): Promise<LookupsResponse> {
    try {
      return requestService.get(
        '/api/v1/lookups/lookups/fiscal/cashregister-pos-serialnumber',
        data,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupsCashRegisterSAMNumber(
    data: Partial<LookupsFiscalCashRegister>,
  ): Promise<LookupsResponse> {
    try {
      return requestService.get('/api/v1/lookups/lookups/fiscal/cashregister-sam-number', data)
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getLookupsCashRegisterServiceRequestNumber(
    data: Partial<LookupsFiscalCashRegister>,
  ): Promise<LookupsResponse> {
    try {
      return requestService.get(
        '/api/v1/lookups/lookups/fiscal/cashregister-servicerequest-number',
        data,
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  public static async getServerVersion(): Promise<string> {
    try {
      return requestService.get('/api/v1/server-version')
    } catch (e) {
      throw new Error(e)
    }
  }

  public static getClientVersion = async () => {
    if (process.env.NODE_ENV !== 'production') return 'Development build'

    const response = await fetch(`${process.env.PUBLIC_URL}/buildinfo.txt`)

    if (response?.ok) {
      return await response.text()
    }
  }
}
