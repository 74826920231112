import { Dialog, Paper, PaperProps } from '@material-ui/core'
import React, { FC, useCallback, useContext } from 'react'
import Draggable from 'react-draggable'

import { DialogContext } from './context'
import { DraggableDialogProps } from './types'
import { useClasses } from './styles'

const PaperComponent: FC<PaperProps> = React.memo((props: PaperProps) => {
  const { id } = useContext(DialogContext)

  const dialogId = `#draggable-dialog-title-${id || 'handle'}`

  return (
    <Draggable handle={dialogId} cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
})

export const DraggableDialog: FC<DraggableDialogProps> = React.memo(
  (props: DraggableDialogProps) => {
    const { children, handleApply, ...rest } = props

    const classes = useClasses()

    const dialogId = `draggable-dialog-title-${rest.id || 'handle'}`

    const handleKeyPress = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (handleApply) {
          if (event.key === 'Enter') {
            handleApply()
          }
        }
      },
      [handleApply],
    )

    return (
      <DialogContext.Provider value={{ id: rest.id }}>
        <Dialog
          {...rest}
          PaperComponent={PaperComponent}
          onKeyPress={handleKeyPress}
          aria-labelledby={dialogId}
        >
          <div id={dialogId} className={classes.draggableHandle} />
          {children}
        </Dialog>
      </DialogContext.Provider>
    )
  },
)
