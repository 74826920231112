import { AnyAction } from 'redux'

import { AdmBank } from '@/api/cm/swaggerGeneratedApi'
import { CMAppConfigActions } from '@/constants/actions'

export type State = {
  data: AdmBank[]

  selected: AdmBank | null

  isLoaded: boolean
  isLoading: boolean

  sortColumn?: string
  sortOrder?: string
}

export const initialState: State = {
  data: [],

  selected: null,

  isLoaded: false,
  isLoading: false,

  sortOrder: undefined,
  sortColumn: undefined,
}

export const reducer = (state: State = initialState, { type, payload }: AnyAction): State => {
  switch (type) {
    // get
    case CMAppConfigActions.AdmGetBanksRequest:
    case CMAppConfigActions.AdmAddNewBankRequest:
    case CMAppConfigActions.AdmUpdateBankRequest:
    case CMAppConfigActions.AdmDeleteBankRequest:
      return {
        ...state,
        isLoading: true,
      }
    case CMAppConfigActions.AdmGetBanksResponse:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: payload,
      }

    case CMAppConfigActions.AdmGetBanksFail:
    case CMAppConfigActions.AdmAddNewBankFail:
    case CMAppConfigActions.AdmUpdateBankFail:
    case CMAppConfigActions.AdmDeleteBankFail:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      }

    // sort
    case CMAppConfigActions.AdmSortBanks:
      return {
        ...state,
        ...payload,
        isLoading: true,
      }

    // selected
    case CMAppConfigActions.AdmSetSelectedBank:
      return {
        ...state,
        selected: payload,
      }

    default:
      return state
  }
}
