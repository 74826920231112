import makeStyles from '@material-ui/core/styles/makeStyles'
import styled from 'styled-components'

import { styledComponentTheme } from '@/theme/styledComponentTheme'

export const useClasses = makeStyles(({ spacing }) => ({
  noTab: {
    display: 'none',
  },
  grayscale: {
    filter: 'grayscale(1)',
    marginBottom: spacing(1),
  },
  cameraChoose: {
    display: 'flex',
    width: '100%',
    background: '#F8F8F8',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '450px',
  },
  dialog: {
    minWidth: '860px',
  },
  cameraImage: {
    width: '100%',
  },
  img: {
    maxWidth: '100%',
  },
  deviceTitle: {
    fontSize: '20px',
    marginBottom: spacing(3),
  },
  deviceName: {
    color: '#929A9B',
  },
  deviceHeader: {
    marginBottom: spacing(1),
    fontWeight: 'bold',
    background: '#F8F8F8',
    padding: '10px 20px',
  },
  button: {
    background: 'transparent',
    fontWeight: 'normal',
    color: styledComponentTheme.colors.baseGreen,
    border: '1px solid',
    marginBottom: '0',
    whiteSpace: 'nowrap',
    '&:hover': {
      background: styledComponentTheme.colors.baseGreen,
      color: 'white',
      borderColor: styledComponentTheme.colors.baseGreen,
      '& .MuiButton-startIcon': {
        filter: 'brightness(0) invert(1)',
      },
    },
    '& .MuiButton-startIcon': {
      marginLeft: '-16px',
      marginRight: '0',
      width: '50px',
      justifyContent: 'center',
    },
  },
  download: {
    marginTop: 'auto',
    textDecoration: 'none',
  },
  closeButton: {
    top: spacing(1),
    right: spacing(1),
    '& [class*="makeStyles-icon"]': {
      color: '#041517',
    },
  },

  tabContentWrapper: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  root: {
    margin: '0 24px',
    width: '100%',
  },
  tabWrapper: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  tab: {
    textTransform: 'none',
    zIndex: 2,
    minWidth: 'auto',
    maxWidth: 'none',
    minHeight: '28px',
    padding: '12px 20px',
    opacity: '1',
    '& .MuiTab-wrapper': {
      alignItems: 'flex-start',
    },
    '&:nth-child(even)': {
      background: '#F9F9F9',
    },
    '&:last-child': {
      marginBottom: '30px',
    },
  },
  tabBlock: {
    display: 'flex',
    background: 'white',
    marginRight: '30px',
    flexDirection: 'column',
  },
  selectInput: {
    minWidth: '220px',
  },
  indicator: {
    backgroundColor: styledComponentTheme.colors.baseGreen,
    borderRadius: '4px',
    left: 0,
    zIndex: 2,
    width: '4px',
  },
  selectedTab: {
    backgroundColor: '#EAF7F9 !important',
  },
  tabWrap: {
    width: '100%',
    overflow: 'auto',
    padding: '10px',
    background: '#FFFFFF',
  },
  tableTitle: {
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
    fontSize: '20px',
    marginBottom: '20px',
  },
  tabsWrap: {
    minHeight: 'initial',
    minWidth: '220px',
    '& .MuiTabs-flexContainerVertical': {
      height: '100%',
    },
  },
  bodyWrap: {
    boxShadow: '0px 0px 15px rgba(37, 173, 191, 0.05)',
    borderRadius: '3px',
    padding: '20px',
    background: 'white',
  },
  footerWrap: {
    float: 'right',
    marginTop: '12px',
  },
  cancelButton: {
    fontWeight: 500,
    color: '#929A9B',
    borderColor: '#929A9B',
    marginRight: '16px',
    padding: '8px 18px',
    '&:hover': {
      borderColor: '#929A9B',
    },
  },
  searchButton: {
    fontWeight: 500,
    padding: '8px 18px',
  },
  loader: {
    top: 'auto',
    left: 'auto',
    position: 'unset',
    transform: 'none',
  },

  error: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
    gap: spacing(2),
  },
}))

export const ContentWrapper = styled.div`
  background-color: #ffffff;
  padding: 30px;
`
