import { makeStyles } from '@material-ui/core/styles'

import { styledComponentTheme } from '@/theme'
import colors from '@/theme/colors'

export const useClasses = makeStyles(() => ({
  focused: {
    '&:focus-within': {
      boxShadow: `inset 0px 0px 0px 4px ${styledComponentTheme.colors.baseGreen}`,
    },
  },
  selectedRow: {
    boxShadow: `inset 4px 0px 0px 0px ${styledComponentTheme.colors.baseGreen}`,
    backgroundColor: `${colors.cmTable.primaryLight} !important`,
  },

  clickableRow: {
    cursor: 'pointer',
  },

  row: {
    height: '28px',
  },

  tableRow: {
    backgroundColor: 'white',
    height: '28px',
    '&:nth-of-type(even)': {
      backgroundColor: colors.backgroundHeaderMenu,
    },
  },

  withoutBorderBottom: {
    borderBottom: 'none',
  },

  lastElementBorder: {
    '&:nth-last-of-type(2)': {
      borderRight: `1px solid ${colors.cmTable.border}`,
    },
  },

  collapseRow: {
    backgroundColor: 'white',
  },
}))
