import { Button, Paper, Typography } from '@material-ui/core'
import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { generateMessage, useSocketData } from '@/api/cm/socket/sortersIntegration'
import { MessageType } from '@/api/cm/socket/types'
import CloseButton from '@/components/blocks/CloseButton'
import { DraggableDialog } from '@/components/blocks/cm'
import { CMSocketContext } from '@/context'
import { useTranslate } from '@/utils/internalization'

import { CMTable, usePrepareTableConfig } from '../CMTable'
import { conditionallyRenderCell } from './columnRenderConfig'
import { ActionsWrapper, useClasses } from './styles'
import { headerConfig } from './tableConfig'
import { Props, Sorter } from './types'

const CashSortersModal = ({ isOpen, handleClose }: Props): ReactElement => {
  const translate = useTranslate()
  const classes = useClasses()

  const tableConfig = usePrepareTableConfig({
    rowId: 'SorterId',
    headerConfig,
  })

  const [selected, setSelected] = useState<Sorter | null>(null)

  const { socket, isConnected } = useContext(CMSocketContext)
  const { Sorters: sorters } = useSocketData(MessageType.GetAvailableSortersRequest) || {}

  useEffect(() => {
    if (socket && isConnected) {
      const message = generateMessage({
        typeKey: MessageType.GetAvailableSortersRequest,
      })
      socket.send(JSON.stringify(message))
    }
  }, [])

  useEffect(() => {
    if (sorters && sorters?.length) {
      if (!selected) {
        setSelected(sorters[0])
      } else {
        setSelected(sorters.find((sorter: Sorter) => sorter?.SorterId === selected?.SorterId))
      }
    }
  }, [sorters])

  const handleRowClick = useCallback(
    (row: Sorter) => {
      setSelected(row)
    },
    [setSelected],
  )

  const conditionallyRenderCellMemo = useMemo(() => conditionallyRenderCell, [])

  const handleApply = useCallback(() => {
    if (socket && isConnected && selected?.SorterId) {
      const message = generateMessage({
        typeKey: MessageType.TakeSorterRequest,
        payload: JSON.stringify({ SorterId: selected.SorterId }),
      })
      socket.send(JSON.stringify(message))
    }
  }, [socket, isConnected, selected])

  return (
    <>
      <DraggableDialog
        maxWidth="xl"
        fullWidth
        open={isOpen}
        keepMounted
        onClose={handleClose}
        classes={{ paper: classes.dialog }}
      >
        <CloseButton onClick={handleClose} absolute />
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <Typography className={classes.title}>
              {translate('translate#cm.CashSorters')}
            </Typography>
            <Typography className={classes.subTitle}>
              {translate('translate#cm.Objects')} ({(sorters || []).length})
            </Typography>
          </div>
          <CMTable
            {...tableConfig}
            data={sorters || []}
            conditionallyRenderCell={conditionallyRenderCellMemo}
            onRowClick={handleRowClick}
            selectedRowId={selected?.SorterId}
          />
          <ActionsWrapper>
            <Button fullWidth variant="outlined" onClick={handleClose} className={classes.button}>
              {translate('translate#cm.ButtonCancel')}
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={handleApply}
              className={classes.button}
              disabled={!selected || !!selected.User}
            >
              {translate('translate#cm.ButtonApply')}
            </Button>
          </ActionsWrapper>
        </Paper>
      </DraggableDialog>
    </>
  )
}

export default CashSortersModal
