import { createSelector } from 'reselect'

import { AppState } from '@/store/reducers'

import * as fromReducer from './reducer'

export const getState = (state: AppState): fromReducer.State => {
  return state.cmAdmin?.appConfig.config
}

export const getAdmAppConfig = createSelector(getState, state => state)
